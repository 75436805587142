import { safeParseInt } from '@graneet/business-logic';

// -- Journal --
export interface AccountingJournalEditionBlockForm {
  journalSales: string | undefined | null;

  journalPurchases: string | undefined | null;

  journalBank: string | undefined | null;
}

// -- Purchases --
export type AccountingAccountPurchasesNameFieldName = `accountPurchasesName_${string}`;

export const getAccountingAccountPurchasesNameFieldName = (id: string): AccountingAccountPurchasesNameFieldName =>
  `accountPurchasesName_${id}`;

export const isAccountingAccountPurchasesNameFieldName = (
  fieldName: string,
): fieldName is AccountingAccountPurchasesNameFieldName => fieldName.startsWith('accountPurchasesName_');

export const getIdFromAccountingAccountPurchasesNameFieldName = (fieldName: AccountingAccountPurchasesNameFieldName) =>
  fieldName.split('_')[1];

export type AccountingAccountPurchasesFieldName = `accountPurchases_${number | string}`;

export const getAccountingAccountPurchasesFieldName = (id: number | string): AccountingAccountPurchasesFieldName =>
  `accountPurchases_${id}`;

export const isAccountingAccountPurchasesFieldName = (
  fieldName: string,
): fieldName is AccountingAccountPurchasesFieldName => fieldName.startsWith('accountPurchases_');

export const getIdFromAccountingAccountPurchasesFieldName = (fieldName: AccountingAccountPurchasesFieldName) => {
  //  @[ff: ACCOUNTING_BREAKDOWN_PURCHASES_ACCOUNT] TODO DELETE
  if (/^-?\d+$/.test(fieldName.split('_')[1])) {
    return safeParseInt(fieldName.split('_')[1], 10);
  }
  return fieldName.split('_')[1];
};

export interface AccountingPurchasesEditionBlockForm {
  [accountPurchasesName: AccountingAccountPurchasesNameFieldName]: string | undefined | null;

  [accountPurchases: AccountingAccountPurchasesFieldName]: string | undefined | null;

  accountSupplier: string | null | undefined;
}

// -- Banks --

// --- Bank journals ---
export type AccountingConfigBankLabelFieldName = `accountingConfigBankLabel_${string}`;
export type AccountingConfigBankJournalFieldName = `accountingConfigBankJournal_${string}`;

export const getAccountingConfigBankLabelFieldName = (id: string): AccountingConfigBankLabelFieldName =>
  `accountingConfigBankLabel_${id}`;

export const isAccountingConfigBankLabelFieldName = (
  fieldName: string,
): fieldName is AccountingConfigBankLabelFieldName => fieldName.startsWith('accountingConfigBankLabel_');

export const getAccountingConfigBankJournalFieldName = (id: string): AccountingConfigBankJournalFieldName =>
  `accountingConfigBankJournal_${id}`;

export const isAccountingConfigBankJournalFieldName = (
  fieldName: string,
): fieldName is AccountingConfigBankJournalFieldName => fieldName.startsWith('accountingConfigBankJournal_');

export const getIdFromAccountingConfigBankLabelFieldName = (fieldName: AccountingConfigBankLabelFieldName) =>
  fieldName.split('_')[1];

export const getIdFromAccountingConfigBankJournalFieldName = (fieldName: AccountingConfigBankJournalFieldName) =>
  fieldName.split('_')[1];

export interface AccountingConfigBankEditionBlockForm {
  [bankLabel: AccountingConfigBankLabelFieldName]: string | undefined | null;
  [bankJournal: AccountingConfigBankJournalFieldName]: string | undefined | null;
}

export interface AccountingConfigBankAccountEditionBlockForm {
  [bankAccountAccount: AccountingConfigBankAccountAccountFieldName]: string | undefined | null;
}

// --- Bank accounts ---

export type AccountingConfigBankAccountAccountFieldName = `accountingConfigBankAccountAccount_${string}`;

export const getAccountingConfigBankAccountAccountFieldName = (
  id: string,
): AccountingConfigBankAccountAccountFieldName => `accountingConfigBankAccountAccount_${id}`;

export const isAccountingConfigBankAccountAccountFieldName = (
  fieldName: string,
): fieldName is AccountingConfigBankAccountAccountFieldName =>
  fieldName.startsWith('accountingConfigBankAccountAccount_');

export const getIdFromAccountingConfigBankAccountAccountFieldName = (
  fieldName: AccountingConfigBankAccountAccountFieldName,
) => fieldName.split('_')[1];

// -- Sales --
export type AccountingVatAccountWorkFieldName = `vatAccountWork_${string}`;

export const isAccountingVatAccountWorkFieldName = (
  fieldName: string,
): fieldName is AccountingVatAccountWorkFieldName => fieldName.startsWith('vatAccountWork_');

export const getAccountingVatAccountWorkFieldName = (vatId: string): AccountingVatAccountWorkFieldName =>
  `vatAccountWork_${vatId}`;

export const getVatIdFromFieldName = (fieldName: AccountingVatAccountWorkFieldName) => fieldName.split('_')[1];

export interface AccountingSalesEditionBlockForm {
  splitSalesWorkAsBases: boolean | undefined | null;

  accountSalesDownPayment: string | undefined | null;

  accountSalesHoldback: string | undefined | null;

  accountSalesPriceRevision: string | undefined | null;

  accountSalesDirectPayment: string | undefined | null;

  accountSalesWork: string | undefined | null;

  accountSalesWorkReversalOfLiability: string | undefined | null;

  accountSalesOtherDeduction: string | undefined | null;

  accountSalesProrataAccount: string | undefined | null;

  splitProrataWithVAT: boolean | undefined;

  useClientCodeWithHoldback: boolean | undefined;

  accountClient: string | undefined | null;

  [accountingWorkVatFieldName: AccountingVatAccountWorkFieldName]: string | undefined | null;
}

// -- Bank --
// TODO remove
export interface AccountingBankEditionBlockForm {
  accountBankSettlementWire: string | undefined | null;
}

// -- Vat --
export type AccountingVatAccount =
  | 'accountSales'
  | 'accountDownPayment'
  | 'accountPurchases'
  | 'accountSalesCollected'
  | 'accountSalesPending'
  | 'accountPurchasesDeductible'
  | 'accountPurchasesPending';

export type AccountingVatFieldName<T extends AccountingVatAccount> = `vatAccount_${string}_${T}`;

export const getAccountingVatFieldName = <T extends AccountingVatAccount>(
  vatId: string,
  accountingVatType: T,
): AccountingVatFieldName<T> => `vatAccount_${vatId}_${accountingVatType}`;

export const isAccountingVatAccountPurchasesFieldName = (
  fieldName: string,
): fieldName is AccountingVatFieldName<AccountingVatAccount> => fieldName.startsWith('vatAccount_');

export const getVatIdAndTypeFromFieldName = (fieldName: AccountingVatFieldName<AccountingVatAccount>) => ({
  id: fieldName.split('_')[1],
  vatType: fieldName.split('_')[2] as AccountingVatAccount,
});

export interface AccountingVatEditionBlockForm {
  [vatAccountAccountSales: AccountingVatFieldName<'accountSales'>]: string | undefined | null;

  [vatAccountAccountDownPayment: AccountingVatFieldName<'accountDownPayment'>]: string | undefined | null;

  [vatAccountAccountPurchases: AccountingVatFieldName<'accountPurchases'>]: string | undefined | null;

  [vatAccountAccountSalesCollected: AccountingVatFieldName<'accountSalesCollected'>]: string | undefined | null;

  [vatAccountAccountSalesPending: AccountingVatFieldName<'accountSalesPending'>]: string | undefined | null;

  [vatAccountAccountPurchasesDeductible: AccountingVatFieldName<'accountPurchasesDeductible'>]:
    | string
    | undefined
    | null;

  [vatAccountAccountPurchasesPending: AccountingVatFieldName<'accountPurchasesPending'>]: string | undefined | null;
}

// -- Client --

export type AccountingClientAuxiliaryAccountFieldName = `clientAuxiliaryAccount_${number}`;

export const getAccountingClientAuxiliaryAccountFieldName = (id: number): AccountingClientAuxiliaryAccountFieldName =>
  `clientAuxiliaryAccount_${id}`;

export const isAccountingClientAuxiliaryAccountFieldName = (
  fieldName: string,
): fieldName is AccountingClientAuxiliaryAccountFieldName => fieldName.startsWith('clientAuxiliaryAccount_');

export const getIdAccountingClientAuxiliaryAccountFieldName = (fieldName: string) =>
  safeParseInt(fieldName.split('_')[1], 10);

export interface AccountingClientEditionBlockForm {
  [clientAuxiliaryAccount: AccountingClientAuxiliaryAccountFieldName]: string | null | undefined;
}

// -- Supplier --

export type AccountingSupplierAuxiliaryAccountFieldName = `supplierAuxiliaryAccount_${number}`;

export const getAccountingSupplierAuxiliaryAccountFieldName = (
  id: number,
): AccountingSupplierAuxiliaryAccountFieldName => `supplierAuxiliaryAccount_${id}`;

export const isAccountingSupplierAuxiliaryAccountFieldName = (
  fieldName: string,
): fieldName is AccountingSupplierAuxiliaryAccountFieldName => fieldName.startsWith('supplierAuxiliaryAccount_');

export const getIdAccountingSupplierAuxiliaryAccountFieldName = (fieldName: string) =>
  safeParseInt(fieldName.split('_')[1], 10);

export interface AccountingSupplierEditionBlockForm {
  [supplierAuxiliaryAccount: AccountingSupplierAuxiliaryAccountFieldName]: string | null | undefined;
}
