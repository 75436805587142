import type { FC } from 'react';
import { BadgeJob, DeepTable, formatNumber, PriceAdvanced, RichText, roundFloatingTo, Tree } from '@graneet/lib-ui';
import { Box, GridItem, useTheme } from '@chakra-ui/react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { isNumberFinite } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { useQuoteExportLot, useQuoteExportLotComputedValue } from '../../hooks/useQuoteExportTree';
import type { QuoteExportForm } from '../../forms/quote-export-edition.wizard';
import { EXPORT_COLUMNS } from '../../constants/quote-export.constant';
import { QuoteExportNoteRow } from '../QuoteExportNoteRow';

import { QuoteExportJobsWarningBadge } from './QuoteExportJobsWarningBadge';
import { QuoteExportLotCheckbox } from './QuoteExportLotCheckbox';

import { useStore } from 'store/store';

const CHEVRON_ICON_PROPS = {
  color: 'gray.300',
  _hover: { color: 'greenBrand.light' },
  boxSize: 35,
};

interface QuoteExportDeepTableLotLineProps {
  id: number;

  depth: number;
}

export const WorkForceQuantityCell: FC<{ id: number; isOptional: boolean | undefined }> = ({ id, isOptional }) => {
  const lotComputedValue = useQuoteExportLotComputedValue(id);

  if (!lotComputedValue) {
    return null;
  }

  const workForceQuantity = isOptional ? lotComputedValue.optionaWorkForceQuantity : lotComputedValue.workForceQuantity;
  return (
    <DeepTable.Cell right>
      {isNumberFinite(workForceQuantity) && `${formatNumber(roundFloatingTo(workForceQuantity, 2))} h`}
    </DeepTable.Cell>
  );
};

export const DisbursementExVATCell: FC<{ id: number; isOptional: boolean | undefined }> = ({ id, isOptional }) => {
  const lotComputedValue = useQuoteExportLotComputedValue(id);

  if (!lotComputedValue) {
    return null;
  }

  return (
    <DeepTable.Cell right>
      <PriceAdvanced
        amount={isOptional ? lotComputedValue.optionalDisbursementExVAT : lotComputedValue.disbursementExVAT}
      />
    </DeepTable.Cell>
  );
};

export const AmountExVATCell: FC<{ id: number; isOptional: boolean | undefined }> = ({ id, isOptional }) => {
  const lotComputedValue = useQuoteExportLotComputedValue(id);

  if (!lotComputedValue) {
    return null;
  }

  return (
    <DeepTable.Cell right>
      <PriceAdvanced amount={isOptional ? lotComputedValue.optionalAmountExVAT : lotComputedValue.amountExVAT} />
    </DeepTable.Cell>
  );
};

export const QuoteExportDeepTableLotLine: FC<QuoteExportDeepTableLotLineProps> = ({ id, depth }) => {
  const theme = useTheme();
  const { t } = useTranslation(['quote']);

  const {
    node: lot,
    state: { isExpanded },
  } = useQuoteExportLot(id);

  const autoNumberingIsActivated = useStore((state) => state.autoNumberingIsActivated);
  const autoNumber = useStore((state) => state.autoNumberingTable[`lot-${id}`]);
  const isOptional = useStore((state) => state.optionalLotsTable[lot.id.toString()]);

  const form = useFormContext<QuoteExportForm>();
  const { exportItems: columns } = useOnChangeValues(form, ['exportItems']);

  if (!columns) {
    return null;
  }

  const descriptionCellSpan =
    1 +
    (columns[EXPORT_COLUMNS.UNIT] ? 1 : 0) +
    (columns[EXPORT_COLUMNS.QUANTITY] ? 1 : 0) +
    (columns[EXPORT_COLUMNS.UNIT_DISBURSEMENT_EX_VAT] ? 1 : 0);

  const backgroundColor = lot.depth > 1 ? 'gray.150' : 'gray.200';
  const borderColor = theme.colors.gray['300'];

  return (
    <>
      <DeepTable.Row
        offset={(lot.depth - 1) * 0.5}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        leftContent={
          <Box display="flex" flexDirection="row" marginTop={isOptional ? 3 : 0} alignItems="center">
            <QuoteExportLotCheckbox lotId={id} />
            <Tree.ToggleButton nodeId={id} {...CHEVRON_ICON_PROPS} />
          </Box>
        }
        position="relative"
      >
        {isOptional ? (
          <BadgeJob backgroundColor="purple.200" textColor="purple.700" label={t('quote:option.title')} />
        ) : null}
        {!!columns[EXPORT_COLUMNS.REF_CODE] && (
          <DeepTable.Cell>{autoNumberingIsActivated ? autoNumber?.substring(2) : lot.code}</DeepTable.Cell>
        )}

        <GridItem colSpan={descriptionCellSpan}>
          <DeepTable.Cell align="center" justify="space-between">
            <RichText value={lot.description} />
            {!isExpanded && <QuoteExportJobsWarningBadge ml={2} lotId={id} />}
          </DeepTable.Cell>
        </GridItem>

        {!!columns[EXPORT_COLUMNS.DISBURSEMENT_EX_VAT] && <DisbursementExVATCell id={id} isOptional={isOptional} />}

        {!!columns[EXPORT_COLUMNS.JOB_MARGIN] && <DeepTable.Cell />}

        {!!columns[EXPORT_COLUMNS.UNIT_PRICE_EX_VAT] && <DeepTable.Cell />}

        {!!columns[EXPORT_COLUMNS.JOB_VAT] && <DeepTable.Cell />}

        {!!columns[EXPORT_COLUMNS.WORK_FORCE] && <WorkForceQuantityCell id={id} isOptional={isOptional} />}

        {!!columns[EXPORT_COLUMNS.AMOUNT_EX_VAT] && <AmountExVATCell id={id} isOptional={isOptional} />}
      </DeepTable.Row>

      {!!lot.note && <QuoteExportNoteRow depth={depth} parentId={id} parentType="lot" isOptional={isOptional} />}
    </>
  );
};
