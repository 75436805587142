import type { FC, ReactNode } from 'react';
import type { TypographyProps } from '@chakra-ui/react';
import { Flex, HStack, Text } from '@chakra-ui/react';

export interface MetricWithDescriptionProps {
  description: string;

  color?: string;

  children: ReactNode;

  fontSize?: TypographyProps['fontSize'];
}

export const MetricWithDescription: FC<MetricWithDescriptionProps> = ({
  children,
  description,
  color = 'gray.600',
  fontSize = '5xl',
}) => (
  <Flex flexDirection="column">
    <HStack fontSize={fontSize} color={color}>
      <>{children}</>
    </HStack>

    <Text fontSize="sm" color="gray.700">
      {description}
    </Text>
  </Flex>
);
