import { Nav } from '@graneet/lib-ui';
import { FEATURE_FLAGS, PERMISSION } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { usePaletteStore } from '../../hooks/usePalette';

import { NavBarCompanySelector } from './NavBarCompanySelector';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const NavBar = () => {
  const { t } = useTranslation(['global']);

  const hasAccessDashboardPermission = usePermissions([PERMISSION.ACCESS_DASHBOARDS]);

  const hasDisplayQuotesPermission = usePermissions([PERMISSION.DISPLAY_QUOTES]);
  const hasQuoteV2FeatureFlag = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);

  const canSeeStatementModule = usePermissions([PERMISSION.ACCESS_STATEMENTS]);

  const canSeeOrderModule = usePermissions([PERMISSION.ACCESS_ORDER]);
  const canSeeSupplierInvoiceModule = usePermissions([PERMISSION.ACCESS_SUPPLIER_INVOICE]);
  const hasUpdateSupplierInvoicesPermission = usePermissions([PERMISSION.UPDATE_SUPPLIER_INVOICE_PAYMENT]);
  const canSeeCashOutFlowTab = canSeeSupplierInvoiceModule && hasUpdateSupplierInvoicesPermission;

  const hasAccessTimeTrackingPermission = usePermissions([PERMISSION.ACCESS_TIME_TRACKING]);

  const hasDisplayAccountingPermission = usePermissions([PERMISSION.DISPLAY_ACCOUNTING]);

  const hasBankingAccountConnection = useFeatureFlag(FEATURE_FLAGS.BANKING_ACCOUNT_CONNECTION);
  const hasAccessToBankingStats = usePermissions([PERMISSION.ACCESS_BALANCE_BANKING]);
  const hasAccessToBankingTransaction = usePermissions([PERMISSION.ACCESS_TRANSACTION_BANKING]);
  const hasAccessToBankingPaymentResource = usePermissions([PERMISSION.ACCESS_PAYMENT_RESOURCE_BANKING]);

  const openPalette = usePaletteStore((state) => state.paletteOpen);

  return (
    <Nav
      header={{
        content: NavBarCompanySelector,
      }}
      items={[
        {
          to: '#',
          icon: 'ri-search-2-line',
          name: t('global:nav.search'),
          onClick: openPalette,
          shortcut: '⌘ K',
        },
        hasAccessDashboardPermission && {
          to: '/',
          icon: 'ri-pie-chart-line',
          name: t('global:nav.dashboard'),
        },

        // Contacts
        {
          name: t('global:nav.contacts'),
          items: [
            {
              to: '/contacts/contacts',
              icon: 'ri-user-3-line',
              name: t('global:nav.contacts'),
            },
            {
              to: '/contacts/clients',
              icon: 'ri-building-4-line',
              name: t('global:nav.clients'),
            },
            {
              to: '/contacts/suppliers',
              icon: 'ri-truck-line',
              name: t('global:nav.suppliers'),
            },
          ],
        },

        // Quotes
        hasDisplayQuotesPermission && {
          name: t('global:nav.quotes'),
          items: [
            {
              to: '/opportunities/library',
              icon: 'ri-archive-stack-line',
              name: t('global:nav.library'),
            },
            hasQuoteV2FeatureFlag
              ? {
                  to: '/opportunities/quotation',
                  icon: 'ri-calculator-line',
                  name: t('global:nav.quotes'),
                }
              : {
                  to: '/opportunities/quotes',
                  icon: 'ri-calculator-line',
                  name: t('global:nav.quotes'),
                },
          ],
        },

        // Projects
        {
          name: t('global:nav.projects'),
          items: [
            {
              to: '/projects',
              icon: 'ri-building-2-line',
              name: t('global:nav.projects'),
            },
          ],
        },

        // Sales
        canSeeStatementModule && {
          name: t('global:nav.sales'),
          items: [
            {
              to: '/sales/statements',
              icon: 'ri-file-text-line',
              name: t('global:nav.salesInvoices'),
            },
            {
              to: '/sales/bills',
              icon: 'ri-money-euro-circle-line',
              name: t('global:nav.bills'),
            },
            {
              to: '/sales/holdbacks',
              icon: 'ri-lock-line',
              name: t('global:nav.holdbacks'),
            },
          ],
        },

        // Purchases
        (canSeeOrderModule || canSeeSupplierInvoiceModule) && {
          name: t('global:nav.purchases'),
          items: [
            canSeeOrderModule && {
              to: '/purchases/orders',
              icon: 'ri-shopping-cart-line',
              name: t('global:nav.orders'),
            },
            canSeeSupplierInvoiceModule && {
              to: '/purchases/supplier-invoices',
              icon: 'ri-receipt-line',
              name: t('global:nav.supplierInvoices'),
            },
            canSeeCashOutFlowTab && {
              to: '/purchases/cash-outflows',
              icon: 'ri-arrow-right-up-line',
              name: t('global:nav.cashOutFlow'),
            },
          ],
        },

        // Banking
        hasBankingAccountConnection &&
          (hasAccessToBankingStats || hasAccessToBankingTransaction) && {
            name: t('global:nav.bank'),
            isNew: true,
            items: [
              hasAccessToBankingStats && {
                to: '/banking/overview',
                icon: 'ri-bar-chart-box-line',
                name: t('global:nav.bankOverview'),
              },
              hasAccessToBankingTransaction && {
                to: '/banking/transactions',
                icon: 'ri-arrow-up-down-line',
                name: t('global:nav.bankTransactions'),
              },
              hasAccessToBankingPaymentResource && {
                to: '/banking/payment-resources',
                icon: 'ri-exchange-dollar-line',
                name: t('global:nav.bankingPaymentResources'),
              },
            ],
          },

        // TimeTracking
        hasAccessTimeTrackingPermission && {
          name: t('global:nav.planning'),
          items: [
            {
              to: '/time-tracking',
              icon: 'ri-calendar-event-fill',
              name: t('global:nav.timeTracking'),
            },
          ],
        },

        // Accounting
        hasDisplayAccountingPermission && {
          name: t('global:nav.accounting'),
          items: [
            {
              to: '/accounting',
              icon: 'ri-ball-pen-line',
              name: t('global:nav.accounting'),
            },
          ],
        },
      ]}
    />
  );
};
