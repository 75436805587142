import { HStack, Text, VStack, Box } from '@chakra-ui/react';
import { PDF_DENSITY } from '@graneet/business-logic';
import { SimpleHelpIcon, Tooltip, SegmentedInputGroupField } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { PDFSettingsFormValues } from '../constants/pdf-settings.constant';
import { PDF_SETTINGS_FIELDS } from '../constants/pdf-settings.constant';

export const PdfDensitySettings: FC = () => {
  const { t } = useTranslation(['pdfSettings']);

  return (
    <VStack spacing={2} align="start">
      <HStack spacing={2}>
        <Text color="black" fontSize="lg">
          {t('pdfSettings:settings.density.title')}
        </Text>
        <Tooltip label={t('pdfSettings:settings.density.tooltip')} placement="right-end">
          <Box>
            <SimpleHelpIcon boxSize={5} />
          </Box>
        </Tooltip>
      </HStack>

      <SegmentedInputGroupField<PDFSettingsFormValues>
        name={PDF_SETTINGS_FIELDS.DENSITY}
        options={Object.values(PDF_DENSITY).map((type) => ({
          optionLabel: t(`pdfSettings:settings.density.options.${type}`),
          optionValue: type,
        }))}
      />
    </VStack>
  );
};
