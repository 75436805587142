import type { FC } from 'react';
import { DeepTable, RichText } from '@graneet/lib-ui';
import { Box, useTheme } from '@chakra-ui/react';

import { getProgressStatementDeepTableOffset } from '../../services/progress-statement-tree.util';

interface ProgressStatementCommentItemRowProps {
  children: string;

  depth: number;
}

export const ProgressStatementCommentItemRow: FC<ProgressStatementCommentItemRowProps> = ({ children, depth }) => {
  const { colors } = useTheme();
  const borderColor = colors.gray['200'];

  return (
    <DeepTable.Row
      offset={getProgressStatementDeepTableOffset(depth)}
      backgroundColor="white"
      borderColor={borderColor}
    >
      <DeepTable.Cell color="gray.500" gridColumn="span 7" whiteSpace="pre-line" wordBreak="break-word">
        <Box ml={4}>
          <RichText value={children} variant="light" />
        </Box>
      </DeepTable.Cell>
      <DeepTable.Cell variant="shadow" />
      <DeepTable.Cell />
    </DeepTable.Row>
  );
};
