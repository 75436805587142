import { Card, ConfirmDeletionModal } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';
import { useDisclosure } from '@chakra-ui/react';

import { ContactUpsertModal } from '../modals/ContactUpsertModal';
import { ContactAvailableProjectModal } from '../modals/ContactAvailableProjectModal';
import { ContactAddOrAssociateButton } from '../ContactAddOrAssociateButton';

import { ContactCardDetails } from './ContactCardDetails';

import {
  useContactAssociatedToProject,
  useContactDelete,
  useContactDissociateFromProject,
  useContactRoles,
  useContactsAssociateToProject,
} from 'features/contact/services/contact.api';

interface ContactCardProjectProps {
  projectId: number;

  onContactUpdate?: () => void;
}

export const ContactCardProject: FC<ContactCardProjectProps> = ({ projectId, onContactUpdate }) => {
  const upsertModal = useDisclosure();
  const deletionModal = useDisclosure();
  const associationModal = useDisclosure();

  const projectContacts = useContactAssociatedToProject(projectId);
  const contactRoles = useContactRoles();

  const contactDeleteMutation = useContactDelete();
  const contactsAssociateToProjectMutation = useContactsAssociateToProject();
  const useContactDissociateFromProjectMutation = useContactDissociateFromProject();

  const { t } = useTranslation(['global', 'contacts']);

  const [contactUpsert, setContactUpsert] = useState<IContact | null>(null);
  const [contactToDelete, setContactToDelete] = useState<IContact | null>(null);

  const reloadContacts = useCallback(() => {
    onContactUpdate?.();
  }, [onContactUpdate]);

  const onUpsert = useCallback(
    (contact?: IContact) => {
      if (contact) {
        setContactUpsert(contact);
      } else {
        setContactUpsert(null);
      }
      upsertModal.onOpen();
    },
    [upsertModal],
  );

  const onDissociate = useCallback(
    async (contactId: string) => {
      await useContactDissociateFromProjectMutation.mutateAsync({ contactId, projectId });
      reloadContacts();
    },
    [projectId, reloadContacts, useContactDissociateFromProjectMutation],
  );

  const onDelete = useCallback(
    async (contact: IContact) => {
      if (contact) {
        setContactToDelete(contact);
      } else {
        setContactToDelete(null);
      }
      deletionModal.onOpen();
    },
    [deletionModal],
  );

  const onContactDeletion = useCallback(async () => {
    if (contactToDelete) {
      await contactDeleteMutation.mutateAsync(contactToDelete);

      reloadContacts();
      deletionModal.onClose();
    }
  }, [contactToDelete, contactDeleteMutation, reloadContacts, deletionModal]);

  const onUpsertSuccess = useCallback(
    async (contact: IContact, data: { isDefaultEmailRecipient?: boolean }) => {
      if (data.isDefaultEmailRecipient !== undefined) {
        await contactsAssociateToProjectMutation.mutateAsync({
          id: projectId,
          dto: {
            contacts: [
              {
                id: contact.id,
                isDefaultEmailRecipient: data.isDefaultEmailRecipient,
              },
            ],
          },
        });
      }

      reloadContacts();
    },
    [contactsAssociateToProjectMutation, projectId, reloadContacts],
  );

  const onDefaultEmailRecipientChange = useCallback(
    async (contactId: string, isDefaultEmailRecipient: boolean) => {
      await contactsAssociateToProjectMutation.mutateAsync({
        id: projectId,
        dto: {
          contacts: [{ id: contactId, isDefaultEmailRecipient }],
        },
      });

      reloadContacts();
    },
    [contactsAssociateToProjectMutation, projectId, reloadContacts],
  );

  return (
    <Card
      w="100%"
      title={t('contacts:card.title')}
      topRightContent={<ContactAddOrAssociateButton onAssociate={associationModal.onOpen} onCreate={onUpsert} />}
    >
      <ContactCardDetails
        contacts={projectContacts.data}
        isContactDefaultEmailRecipient={(contact) => contact.isProjectDefaultEmailRecipient}
        onUpsert={onUpsert}
        onDelete={onDelete}
        onDissociate={onDissociate}
        onDefaultEmailRecipientChange={onDefaultEmailRecipientChange}
        emptyState={t('contacts:emptyState.project')}
      />
      <ContactUpsertModal
        isOpen={upsertModal.isOpen}
        onSuccess={onUpsertSuccess}
        onClose={upsertModal.onClose}
        contact={contactUpsert}
        roles={contactRoles.data}
        hasIsDefaultEmailRecipient
      />
      <ConfirmDeletionModal
        isOpen={deletionModal.isOpen}
        onClose={deletionModal.onClose}
        title={t('contacts:deletionModal.title')}
        action={t('contacts:deletionModal.action')}
        description={t('contacts:deletionModal.description')}
        onDeleted={onContactDeletion}
      />
      <ContactAvailableProjectModal
        isOpen={associationModal.isOpen}
        onClose={associationModal.onClose}
        title={t('contacts:availableModal.title')}
        onSuccess={reloadContacts}
        projectId={projectId}
      />
    </Card>
  );
};
