import { useCallback } from 'react';
import type { FC, ReactElement, ChangeEvent, ReactNode } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box,
  VStack,
  Text,
  Flex,
  Checkbox,
  Portal,
} from '@chakra-ui/react';
import type { PopoverProps, IconProps } from '@chakra-ui/react';

import { EllipsisText } from '../../EllipsisText/EllipsisText';
import type { GraneetButtonProps } from '../Button/Button';
import { GraneetButton } from '../Button/Button';

type Item = {
  name: string;
  label: string;
  value: boolean;
  disabled?: boolean;
  additionalContent?: ReactNode;
  items?: Item[];
};

type GroupProps = {
  label?: string;
  items: Item[];
};

export type SettingsMenuProps = {
  groups: GroupProps[];
  onChange: (name: string, value: boolean) => void;
  isEditable?: boolean;
  icon: ReactElement<IconProps> | string;
  variant?: GraneetButtonProps['variant'];
  size: GraneetButtonProps['size'];
  placement: PopoverProps['placement'];
  tooltip?: string;
};

const Group: FC<{
  title?: string;
  items: Item[];
  isEditable?: boolean;
  showDivider?: boolean;
  level: number;
  onChange: (name: string) => (e: ChangeEvent<HTMLInputElement>) => void;
}> = ({ title, items, isEditable, showDivider, level, onChange }) => (
  <Flex direction="column" w="100%">
    {title && (
      <Text color="baseSecondary" fontSize="sm" mb={2}>
        {title}
      </Text>
    )}

    {items.map((item) => {
      const isChecked = item.items ? item.items.every((i) => i.value) : item.value;
      const isIndeterminate = item.items ? item.items.some((i) => i.value) && !isChecked : false;

      const isSubItem = !item.items;

      return (
        <Box key={item.name} px={level === 0 ? '0.375rem' : 0} width="100%" ml={level === 0 ? 0 : 2}>
          <Checkbox
            colorScheme="greenBrand"
            disabled={item.disabled || !isEditable}
            isChecked={isChecked}
            isIndeterminate={isIndeterminate}
            onChange={onChange(item.name)}
            py="0.25rem"
            w="100%"
            _hover={{ background: 'greenBrand.backgroundNeutralSubtle', borderRadius: 'md' }}
          >
            <Flex gap={3}>
              <EllipsisText
                color={item.items ? 'baseSecondary' : 'primaryLight'}
                fontWeight={item.items ? 600 : 500}
                fontSize={isSubItem ? '0.875rem' : 'sm'}
              >
                {item.label}
              </EllipsisText>
              {item.additionalContent}
            </Flex>
          </Checkbox>
          {item.items && <Group items={item.items} isEditable={isEditable} onChange={onChange} level={level + 1} />}
        </Box>
      );
    })}
    {showDivider && <Box borderBottom="0.063rem solid rgba(0, 19, 58, 0.10)" mt={2} mb="0.188rem" width="100%" />}
  </Flex>
);

const Groups: FC<{
  groups: GroupProps[];
  isEditable?: boolean;
  onChange: (name: string) => (e: ChangeEvent<HTMLInputElement>) => void;
}> = ({ groups, isEditable, onChange }) => (
  <VStack width="100%" spacing={2} alignItems="flex-start">
    {groups.map((group, i) => (
      <Group
        key={group.label ?? i}
        title={group.label}
        items={group.items}
        isEditable={isEditable}
        onChange={onChange}
        showDivider={i < groups.length - 1 && !group.label}
        level={0}
      />
    ))}
  </VStack>
);

export const SettingsMenu: FC<SettingsMenuProps> = ({
  groups,
  onChange,
  isEditable,
  icon,
  variant,
  size,
  tooltip,
  placement = 'bottom-end',
}) => {
  const handleOnChange = useCallback(
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      if (!isEditable) return;
      onChange(name, e.target.checked);
    },
    [onChange, isEditable],
  );

  return (
    <Popover placement={placement} isLazy>
      <PopoverTrigger>
        <GraneetButton size={size} variant={variant} tooltip={tooltip}>
          {typeof icon === 'string' ? <i className={icon} /> : icon}
        </GraneetButton>
      </PopoverTrigger>

      <Portal>
        <PopoverContent
          minW="15rem"
          w="fix-content"
          maxH="80vh"
          overflowY="auto"
          rootProps={{
            zIndex: 1401,
          }}
        >
          <PopoverBody>
            <Groups groups={groups} onChange={handleOnChange} isEditable={isEditable} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
