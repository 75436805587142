import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import type { QuoteNodeObject } from '@org/quotation-lib';
import { Flex } from '@chakra-ui/react';
import type { ImageListAndUploadHandle } from '@graneet/lib-ui';
import { ImageListAndUpload } from '@graneet/lib-ui';

import { useQuoteFileAttach } from 'features/quotation/quote-common/hooks/useQuoteFileAttach';
import { useQuoteFileDelete } from 'features/quotation/quote-common/hooks/useQuoteFileDelete';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useStore } from 'store/store';
import { updateQuoteImageUploadRef } from 'features/quotation/quote-common/store/quoteUpdateZustand';

export const ImageCell = (params: CustomCellRendererProps<QuoteNodeObject>) => {
  const { data, api } = params;
  const nodeId = data?.id;
  const content = data?.content;
  const type = content?.type;

  const { quote } = useQuote();
  const quoteImageUploadRefs = useStore((state) => state.quoteImageUploadRefs);
  const quoteFileAttach = useQuoteFileAttach();
  const quoteFileDelete = useQuoteFileDelete();
  const { executeCommand } = useCommands();

  const quoteRowHeight = useStore((state) => state.quoteRowHeight);

  const imageRef = useRef<ImageListAndUploadHandle>(null);

  const images = useMemo(() => {
    if (!content || (content.type !== 'QuoteBasicItem' && content.type !== 'QuoteOptionalItem') || !content.files) {
      return [];
    }

    return content.files.filter((file) => file.name !== null && file.url !== null) as unknown as {
      name: string;
      url: string;
      id: string;
      size: 'sm' | 'md' | 'lg';
    }[];
  }, [content]);

  const handleFileDelete = useCallback(
    async (file: { name?: string; url: string; id: string }) => {
      if (!nodeId) {
        return;
      }

      quoteFileDelete(file, nodeId);
    },
    [nodeId, quoteFileDelete],
  );

  const handleFileUpload = useCallback(
    async (
      files: {
        id: string;
        file: File;
      }[],
    ) => {
      if (!nodeId) {
        return;
      }

      executeCommand(quoteFileAttach(files, nodeId), quote);
    },
    [executeCommand, nodeId, quote, quoteFileAttach],
  );

  const handleImageClick = useCallback(() => {
    api.stopEditing();
  }, [api]);

  useEffect(() => {
    if (quoteImageUploadRefs && content?.id) {
      quoteImageUploadRefs.set(content.id, imageRef);
      updateQuoteImageUploadRef(quoteImageUploadRefs);
    }
  }, [content, quoteImageUploadRefs]);

  if (type !== 'QuoteBasicItem' && type !== 'QuoteOptionalItem') {
    return null;
  }

  return (
    <Flex alignItems="center" onClick={handleImageClick}>
      <ImageListAndUpload
        noPreview
        isEditable
        ref={imageRef}
        images={images}
        onImageDelete={handleFileDelete}
        onImageUpload={handleFileUpload}
        showAddFile
        size={quoteRowHeight}
      />
    </Flex>
  );
};
