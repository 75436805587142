import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AmountSummary, Card, Table } from '@graneet/lib-ui';
import { PERMISSION, type SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';
import { Box } from '@chakra-ui/react';

import { SupplierInvoicePaymentRow } from './SupplierInvoicePaymentRow';

import { usePermissions } from 'features/role/hooks/usePermissions';

export const SupplierInvoicePaymentsCard: FC<{
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}> = ({ supplierInvoice }) => {
  const { t } = useTranslation(['supplierInvoices']);

  const hasPaySupplierInvoicePermission = usePermissions([PERMISSION.PAY_SUPPLIER_INVOICE]);

  const templateColumns = useMemo(
    () => ['2fr', '2fr', '2fr', hasPaySupplierInvoicePermission ? '1fr' : null].filter(Boolean) as string[],
    [hasPaySupplierInvoicePermission],
  );

  if (supplierInvoice.supplierInvoicePayments.length === 0) {
    return null;
  }

  const totalPaidIncVAT = supplierInvoice.amountIncVAT - supplierInvoice.remainingToBePaidIncVAT;

  return (
    <Card title={t('supplierInvoices:cards.paymentsCard.title')}>
      <Box borderRadius="6px" boxShadow="subtle" overflow="hidden">
        <Table templateColumns={templateColumns} noCard>
          <Table.Header>
            <Table.Cell>{t('supplierInvoices:paymentFields.type')}</Table.Cell>
            <Table.Cell center>{t('supplierInvoices:paymentFields.paidAt')}</Table.Cell>
            <Table.Cell center>{t('supplierInvoices:paymentFields.totalIncVAT')}</Table.Cell>
            {hasPaySupplierInvoicePermission && <Table.Cell right />}
          </Table.Header>

          {supplierInvoice.supplierInvoicePayments.map((supplierInvoicePayment) => (
            <SupplierInvoicePaymentRow
              key={supplierInvoicePayment.id}
              supplierInvoice={supplierInvoice}
              supplierInvoicePayment={supplierInvoicePayment}
              displayPaymentActions={hasPaySupplierInvoicePermission}
            />
          ))}
        </Table>
      </Box>

      <AmountSummary>
        <AmountSummary.Item
          light
          label={t('supplierInvoices:cards.paymentsCard.totalPaidIncVAT')}
          amount={totalPaidIncVAT}
        />
        <AmountSummary.Item
          light
          label={t('supplierInvoices:cards.paymentsCard.remainingToBePaidIncVAT')}
          amount={supplierInvoice.remainingToBePaidIncVAT}
          isAmountColored
          hasAmountEqualToZeroWarning
        />
      </AmountSummary>
    </Card>
  );
};
