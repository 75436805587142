import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { IClient, IClientResponseDTO } from '@graneet/business-logic';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuote } from './useQuote';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { updateQuoteClient } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import {
  useQuoteSetClientRequestsStore,
  StatusEnum,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteUpdateClient() {
  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const { quoteProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    (client: IClient | IClientResponseDTO | null): boolean => {
      try {
        quote.updateProperties({ clientId: client ? client.id : null });
        quoteSetToStore(quote);
        updateQuoteClient(client);

        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
        quoteProxyApi
          .updateQuoteAsync({
            clientId: client ? client.id : null,
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteError, quoteProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
