import { Modal } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Form, useForm } from 'graneet-form';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import type { IUser } from '@graneet/business-logic';

import { UserBadgeGroupField } from 'features/user/components/UserBadgeGroupField';

interface FormValues {
  accountManagersIds: number[];
}

export interface AccountManagerAssociateModalProps {
  modal: UseDisclosureReturn;

  associatedAccountManagerUsers: Pick<IUser, 'id'>[];

  onAccountManagersUpdated(newAccountManagers: number[]): Promise<void>;

  description: string;

  title: string;

  actionLabel: string;
}

export const AccountManagerAssociateModal: FC<AccountManagerAssociateModalProps> = ({
  modal,
  associatedAccountManagerUsers,
  onAccountManagersUpdated,
  description,
  title,
  actionLabel,
}) => {
  const form = useForm<FormValues>();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (modal.isOpen) {
      const accountManagerUserIds = associatedAccountManagerUsers.map((accountManagerUser) => accountManagerUser.id);
      form.setFormValues({ accountManagersIds: accountManagerUserIds });
    }
  }, [associatedAccountManagerUsers, form, modal.isOpen]);

  const handleAccountManagersAssociated = useCallback(async () => {
    setIsLoading(true);
    const { accountManagersIds } = form.getFormValues();
    setIsLoading(false);
    modal.onClose();
    await onAccountManagersUpdated(accountManagersIds!);
  }, [form, modal, onAccountManagersUpdated]);

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={title}>
      <Text mb={3}>{description}</Text>

      <Form form={form}>
        <UserBadgeGroupField<FormValues> name="accountManagersIds" />

        <Modal.Close isDisabled={isLoading} />

        <Modal.PrimaryButton onClick={handleAccountManagersAssociated} isLoading={isLoading}>
          {actionLabel}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
