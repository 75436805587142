import type { FC } from 'react';
import { Onboarding, Title, FluidGrid, SimpleEmailIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';

import { EmailTemplateCardWithActions } from 'features/email-template/components/EmailTemplateCardWithActions/EmailTemplateCardWithActions';
import { EmailTemplateQuotaBar } from 'features/email-template/components/EmailTemplateQuotaBar';
import { EmailTemplateCreateButton } from 'features/email-template/components/EmailTemplateCreateButton';
import { useEmailTemplates } from 'features/email-template/services/email-template.api';

export const EmailTemplateTab: FC = () => {
  const data = useEmailTemplates();

  const { t } = useTranslation(['global', 'mailing']);

  return (
    <>
      <Title mb={3}>{t('global:settingsNav.company.mailsTab.emailTemplates')}</Title>

      {data.data.length === 0 && (
        <Onboarding
          icon={<SimpleEmailIcon boxSize={45} />}
          message={t('mailing:emailTemplate.noEmailTemplate')}
          action={<EmailTemplateCreateButton emailTemplates={data.data} />}
        />
      )}

      {data.data.length > 0 && (
        <>
          <Flex w="100%" justifyContent="space-between">
            <Box w="100%" mr="10rem">
              <EmailTemplateQuotaBar emailTemplates={data.data} />
            </Box>

            <Box w="fit-content">
              <EmailTemplateCreateButton emailTemplates={data.data} />
            </Box>
          </Flex>

          <FluidGrid minSizeItem="25rem" gap={4} mt={6}>
            {data.data.map((emailTemplate) => (
              <FluidGrid.Item key={emailTemplate.id} h="16rem">
                <EmailTemplateCardWithActions emailTemplates={data.data} emailTemplate={emailTemplate} />
              </FluidGrid.Item>
            ))}
          </FluidGrid>
        </>
      )}
    </>
  );
};
