import { useEffect, useState } from 'react';
import { uniq } from 'lodash-es';

import type { TimeSlotCreateForm } from 'features/time-tracking/components/modals/TimeSlotCreateModal/TimeSlotCreateModalForm';

export const useIsTimeslotOvertimesValid = (formValues: Partial<TimeSlotCreateForm>, isOff?: boolean) => {
  const [isTimeslotOvertimesValid, setIsTimeslotOvertimesValid] = useState(true);

  useEffect(() => {
    const timeslotOvertimeTypeIds = Object.keys(formValues).filter((key) => key.includes(`timeslotOvertimeTypeId`));

    const groupedByIds = timeslotOvertimeTypeIds.reduce<Record<string, string[]>>((acc, id) => {
      const [, date] = id.split('.');
      acc[date] = acc[date] || [];
      const value = formValues[id as keyof TimeSlotCreateForm];
      if (value) {
        acc[date].push(value as string);
      }
      return acc;
    }, {});

    if (isOff) {
      setIsTimeslotOvertimesValid(true);
    }

    setIsTimeslotOvertimesValid(Object.values(groupedByIds).every((group) => group.length === uniq(group).length));
  }, [formValues, isOff]);

  return isTimeslotOvertimesValid;
};
