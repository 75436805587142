import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Box, Flex, HStack } from '@chakra-ui/react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { PercentageFieldProps } from '@graneet/lib-ui';
import { CurrencyField, PercentageField, RadioGroupField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ValueOf } from '@graneet/business-logic';
import { DOWN_PAYMENT_TYPES } from '@graneet/business-logic';

import { Rule } from 'features/form/rules/Rule';

interface OptionProps {
  type: ValueOf<typeof DOWN_PAYMENT_TYPES>;

  button: ReactNode;

  label: ReactNode;

  maxAmount: number;
}

export interface DownPaymentRadioGroupForm {
  type: ValueOf<typeof DOWN_PAYMENT_TYPES>;

  percentage?: number | null;

  amount?: number;
}

const Option: FC<OptionProps> = ({ type, button, label, maxAmount }) => {
  const form = useFormContext<DownPaymentRadioGroupForm>();
  const { type: currentType } = useOnChangeValues(form, ['type']);

  const [scale, setScale] = useState<number | undefined>(3);
  const onFocus = () => setScale(undefined);
  const onBlur = () => setScale(3);

  const isDisabled = currentType !== type;
  const fieldProps: Partial<PercentageFieldProps<Record<string, number>>> = {
    variant: 'outline',
    isDisabled,
    min: 0,
    children: !isDisabled && <Rule.IsRequired />,
  };
  const Field =
    type === DOWN_PAYMENT_TYPES.PERCENTAGE ? (
      <PercentageField<DownPaymentRadioGroupForm>
        max={100}
        scale={scale}
        onFocus={onFocus}
        onBlur={onBlur}
        {...fieldProps}
        name="percentage"
      />
    ) : (
      <CurrencyField<DownPaymentRadioGroupForm> max={maxAmount} {...fieldProps} name="amount" />
    );

  return (
    <HStack justify="space-between" w="32rem">
      <Flex alignItems="center">
        {button}
        {label}
      </Flex>
      <Box w="10rem">{Field}</Box>
    </HStack>
  );
};

interface DownPaymentTypeRadioGroupProps {
  maxAmount: number;
}

export const DownPaymentTypeRadioGroup: FC<DownPaymentTypeRadioGroupProps> = ({ maxAmount }) => {
  const { t } = useTranslation(['downPayment']);

  return (
    <RadioGroupField<DownPaymentRadioGroupForm> name="type">
      {Object.values(DOWN_PAYMENT_TYPES).map((type) => (
        <RadioGroupField.Option key={type} value={type} label={t(`downPayment:fields.types.${type}`)}>
          {(button: ReactNode, label: ReactNode) => (
            <Option type={type} label={label} button={button} maxAmount={maxAmount} />
          )}
        </RadioGroupField.Option>
      ))}
    </RadioGroupField>
  );
};
