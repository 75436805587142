import { Flex, Image } from '@chakra-ui/react';
import type { FC } from 'react';
import type { IBankingConnection } from '@graneet/business-logic';

import { getEnvValue } from 'config/env';

const SIZE = {
  md: 4,
  lg: 6,
};

interface BankingBankLogoProps {
  bankingConnection: Pick<IBankingConnection, 'bankUuid'>;
  size?: 'md' | 'lg';
}

export const BankingBankLogo: FC<BankingBankLogoProps> = ({ bankingConnection, size = 'md' }) => (
  <Flex boxSize={SIZE[size]} minW={SIZE[size]} minH={SIZE[size]} justifyContent="center" alignItems="center">
    <Image
      src={`${getEnvValue('REACT_APP_POWENS_DOMAIN')}/logos/${bankingConnection.bankUuid}-thumbnail.webp`}
      fallback={<i className="ri-bank-line" />}
    />
  </Flex>
);
