import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';

export type QuoteCommandHistorySlice = {
  commandHistory: ICommand[];
  historyPointer: number;
  historyHasBeenReset: boolean;
};

const initialSlice: QuoteCommandHistorySlice = {
  commandHistory: [],
  historyPointer: -1,
  historyHasBeenReset: false,
};

export const createQuoteCommandSlice = () => initialSlice;
