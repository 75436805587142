import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { duplicateComponent } from '../services/quote-component.api';
import { useComponentContext } from '../contexts/component.context';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

export const useDuplicateComponent = () => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);
  const { handleComponentUpdate, startAnotherUpdate } = useComponentContext();

  return useCallback(
    async (componentId: number) => {
      startAnotherUpdate();

      const [err, response] = await duplicateComponent(componentId);
      if (err) {
        toast.error(t('global:words.c.error'), t('quote:errors.errorComponent', { email: SUPPORT_EMAIL }));
        return;
      }

      handleComponentUpdate(response);
    },
    [handleComponentUpdate, startAnotherUpdate, t, toast],
  );
};
