import type { ItemProps } from '@graneet/lib-ui';
import {
  ComputeQuoteCustomDiscountAmountUseCase,
  ComputeQuoteSalesDiscountAmountUseCase,
  ContentTypeGuard,
  QUOTE_CUSTOM_DISCOUNT_TYPE,
  QUOTE_SALES_DISCOUNT_TYPE,
} from '@org/quotation-lib';
import type { QuoteCustomDiscount, QuoteNode, QuoteSalesDiscount } from '@org/quotation-lib';
import type { Big } from 'big.js';

export const isComputableMargin = (flatCostAmount: Big | null, amountExVAT: Big | null): boolean => {
  if (flatCostAmount === null || amountExVAT === null) {
    return true;
  }

  return !((flatCostAmount.eq(0) && !amountExVAT.eq(0)) || (!flatCostAmount.eq(0) && amountExVAT.eq(0)));
};

export const isAmountExVATEditable = (discount: QuoteSalesDiscount | null, flatCostAmount: Big | null): boolean => {
  if (!flatCostAmount) {
    return false;
  }

  // TODO: ignore if the amount is not a quote root loot.
  if (
    discount &&
    discount.getType() === QUOTE_SALES_DISCOUNT_TYPE.PERCENTAGE &&
    discount.getPercentageOrThrow().eq(100)
  ) {
    return false;
  }
  return !flatCostAmount.eq(0);
};

export const formatDiscount = (
  saleDiscount: QuoteSalesDiscount,
  totalAmountExVat: Big | null,
): {
  id: string;
  type: QUOTE_SALES_DISCOUNT_TYPE;
  percentage: string | undefined;
  amount: string;
} => ({
  id: saleDiscount.getId(),
  type: saleDiscount.getType(),
  percentage:
    saleDiscount.getType() === QUOTE_SALES_DISCOUNT_TYPE.PERCENTAGE
      ? saleDiscount.getPercentageOrThrow().neg().toString()
      : undefined,
  amount: ComputeQuoteSalesDiscountAmountUseCase.execute(saleDiscount, totalAmountExVat).toString(),
});

export const formatCustomDiscount = (
  customDiscount: QuoteCustomDiscount,
  totalAmountExVat: Big | null,
): {
  id: string;
  name: string;
  amount: string;
  percentage: string | null;
  type: QUOTE_CUSTOM_DISCOUNT_TYPE;
  vatRate: string;
} => ({
  id: customDiscount.getId(),
  name: customDiscount.getNameOrThrow(),
  type: customDiscount.getType(),
  percentage:
    customDiscount.getType() === QUOTE_CUSTOM_DISCOUNT_TYPE.PERCENTAGE
      ? customDiscount.getPercentageOrThrow().toString()
      : null,
  vatRate: customDiscount.getVatRateOrThrow().toString(),
  amount: ComputeQuoteCustomDiscountAmountUseCase.execute(customDiscount, totalAmountExVat).toString(),
});

export const getNodeSelectList = (
  nodes: QuoteNode[],
  isAutomaticIndexActivated: boolean,
  items: ItemProps[],
  getPlainTextValue: (v: string | null) => string | null,
): void => {
  nodes.forEach((node) => {
    const content = node.getContent();
    const index = isAutomaticIndexActivated ? content.getAutomaticIndex() : content.getManualIndex();
    const denomination = getPlainTextValue(content.getDenomination());
    const name = denomination ? `${index} - ${denomination}` : index;
    if (ContentTypeGuard.isQuoteLot(content)) {
      const children: ItemProps[] = [];
      getNodeSelectList(
        Array.from(node.getChildren().values()),
        isAutomaticIndexActivated,
        children,
        getPlainTextValue,
      );
      items.push({
        name: index ? name : denomination,
        value: node.getId(),
        items: children,
      });
    }
  });
};
