import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { IOrderResponseDTO } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { Card } from '@graneet/lib-ui';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useOrderAvailableTags, useOrderEditTags } from 'features/order/services/order.api';

interface OrderTagsCardProps {
  order: IOrderResponseDTO;
}

export const OrderTagsCard: FC<OrderTagsCardProps> = ({ order }) => {
  const { t } = useTranslation(['global']);
  const hasPermission = usePermissions([PERMISSION.CREATE_ORDER]);

  const orderAvailableTags = useOrderAvailableTags();

  const orderEditTagsMutation = useOrderEditTags();

  const onTagsSaved = useCallback(
    (newTags: string[]) => orderEditTagsMutation.mutateAsync({ id: order.id, dto: { tags: newTags } }),
    [order.id, orderEditTagsMutation],
  );

  if (!order.tags && !hasPermission) {
    return null;
  }

  return (
    <Card title={t('global:words.privateTags')}>
      <TagsEditionBlock
        placeholder={t('global:changeTags.placeholder')}
        tags={order.tags}
        suggestedTags={orderAvailableTags.data}
        onTagsEdited={onTagsSaved}
        isEditable={hasPermission}
      />
    </Card>
  );
};
