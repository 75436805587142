import { deburr } from 'lodash-es';

import type { KeysMatching } from './type.util';

export const capitalizeString = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getPotentialStringifyObject = <T extends object>(str: string): [true, T] | [false, string] => {
  try {
    const parsedStr = JSON.parse(str);

    // Because for exemple JSON.parse(1) do not throw an error, we ensure that the parsedStr is an object
    if (typeof parsedStr !== 'object') {
      throw new Error();
    }
    return [true, parsedStr];
  } catch {
    return [false, str];
  }
};

export const basicSearchCompare = (haystack: string, needle: string): boolean => {
  const normalize = (str: string) => str.toLowerCase();
  return !!haystack && !!needle && normalize(haystack).includes(normalize(needle));
};

export const filterOnlyContaining = <T extends Record<any, any>>(
  array: T[],
  propertyToSearchIn: KeysMatching<T, string | null | undefined>[],
  search: string | null,
): T[] =>
  array.filter((obj) => {
    if (search === '' || search === null) {
      return true;
    }
    return propertyToSearchIn.some((property) =>
      deburr(obj[property]).toLowerCase().includes(deburr(search).toLowerCase()),
    );
  });
