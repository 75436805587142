import { useFormContext, useHiddenField, useOnChangeValues } from 'graneet-form';
import { useCallback, useEffect } from 'react';

import { isNumberFinite } from '../../utils';

type InternalField<T extends string> = `raw_${T}`;
type InternalForm<T extends string> = Record<T | InternalField<T>, number | undefined>;

export const useNegativeField = <T extends string>(name: T, onBlur?: () => void) => {
  const fieldName: InternalField<T> = `raw_${name}`;

  const form = useFormContext<InternalForm<T>>();
  const negativeHiddenField = useHiddenField(form, name);
  const { [fieldName]: value } = useOnChangeValues(form, [fieldName]);

  // Bind initial value to currency field
  useEffect(() => {
    const formValues: Partial<InternalForm<T>> = {};
    formValues[fieldName] = negativeHiddenField.value;
    form.setFormValues(formValues);
  }, [form, name, negativeHiddenField, fieldName]);

  // Bind CurrencyField changes to negative amount stored in hidden field
  const handleBlur = useCallback(() => {
    if (isNumberFinite(value)) {
      // Math.abs is a hotfix. We should refactor this to better handle negative value
      negativeHiddenField.setValue(-Math.abs(value));
      onBlur?.();
    } else {
      // Reset field
      negativeHiddenField.setValue(value);
      onBlur?.();
      // Use previous value
      const formValues: Partial<InternalForm<T>> = {};
      formValues[fieldName] = negativeHiddenField.value;
      form.setFormValues(formValues);
    }
  }, [fieldName, form, negativeHiddenField, onBlur, value]);

  return {
    handleBlur,
    negativeHiddenField,
    fieldName,
  };
};
