import type {
  IOnboarding,
  IOnboardingExistingUserValidationDTO,
  IOnboardingValidationDTO,
  OnboardingCheckEmailResponseDTO,
  OnboardingUploadLogoResponseDTO,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { apiNew } from 'features/api/services/apiNew.service';

const ONBOARDING_PATH = '/onboarding';

const onboardingFactoryKey = createQueryKeys('onboarding', {
  getById: (id: string) => ({
    queryKey: [ONBOARDING_PATH, id],
    queryFn: () => apiNew.get<never, IOnboarding>(`${ONBOARDING_PATH}/${id}`),
  }),
});

export function useOnboarding(id: string) {
  return useSuspenseQuery(onboardingFactoryKey.getById(id));
}

export function useValidateOnboarding() {
  const toast = useToast();

  const { t } = useTranslation(['global']);

  return useMutation({
    mutationFn: (params: { id: string; dto: IOnboardingValidationDTO }) =>
      apiNew.patch<IOnboardingValidationDTO, never>(`${ONBOARDING_PATH}/${params.id}/validate`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useValidateExistingUserOnboarding() {
  const toast = useToast();

  const { t } = useTranslation(['global']);

  return useMutation({
    mutationFn: (params: { id: string; dto: IOnboardingExistingUserValidationDTO }) =>
      apiNew.patch<IOnboardingExistingUserValidationDTO, never>(
        `${ONBOARDING_PATH}/${params.id}/validate-existing-user`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useSaveLogoForOnboarding() {
  return useMutation({
    mutationFn: (params: { id: string; dto: { logo: File } }) =>
      apiNew.postWithMultiPart<{ logo: File }, OnboardingUploadLogoResponseDTO>(
        `${ONBOARDING_PATH}/${params.id}/logo`,
        params.dto,
      ),
  });
}

export function useIsEmailAvailable() {
  return useMutation({
    mutationFn: (params: { id: string; dto: { email: string } }) =>
      apiNew.get<{ email: string }, OnboardingCheckEmailResponseDTO>(
        `${ONBOARDING_PATH}/${params.id}/email/availability`,
        params.dto,
      ),
  });
}
