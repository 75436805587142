import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { ActionMenu, CurrencyField, EllipsisText, PercentageField, Table, useCurrency } from '@graneet/lib-ui';
import { Box, Circle } from '@chakra-ui/react';
import { useFormContext, useOnBlurValues } from 'graneet-form';
import type { IProject, RequiredByKeys } from '@graneet/business-logic';
import { divideFloating, multiplyFloating, roundFloating } from '@graneet/business-logic';

import type {
  EditSupplierInvoiceForm,
  ProjectAmountExVATFieldName,
  ProjectDistributionFieldName,
} from '../../../forms/edit-supplier-invoice.form';
import {
  buildProjectAmountExVATFieldName,
  buildProjectDistributionFieldName,
} from '../../../forms/edit-supplier-invoice.form';
import {
  RuleIsProjectsAmountsAmInferiorToAmountExVAT,
  useProjectsAmountsContext,
} from '../../../forms/RuleIsProjectsAmountsAmInferiorToAmountExVAT';

import { Rule } from 'features/form/rules/Rule';
import { getProjectColor } from 'features/project/services/project-colors.util';

/*
  Compute project distribution stored in a field from the supplier invoice amountExVAT and the project amount exc. VAT
 */
const computeProjectDistributionFieldValue = (
  amountExVAT: number | undefined,
  projectAmountExVAT: number | undefined,
) => (projectAmountExVAT && amountExVAT ? multiplyFloating(divideFloating(projectAmountExVAT, amountExVAT), 100) : 0);

/*
  Compute project amount exc. VAT stored in a field from the supplier invoice amountExVAT and the project distribution
 */

interface SupplierInvoiceProjectsAssociationRowProps {
  project: RequiredByKeys<IProject, 'primaryClient'>;
}

export const SupplierInvoiceProjectsAssociationRow: FC<SupplierInvoiceProjectsAssociationRowProps> = ({ project }) => {
  const distributionFieldName = buildProjectDistributionFieldName(project);
  const projectAmountExVATFieldName = buildProjectAmountExVATFieldName(project);

  const form = useFormContext<EditSupplierInvoiceForm>();
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const computeProjectAmountFieldValue = useCallback(
    (amountExVAT: number | undefined, projectDistribution: number | undefined) => {
      if (!amountExVAT || !projectDistribution) {
        return 0;
      }

      const result = divideFloating(
        roundFloating(multiplyFloating(mapAmountToNumber(amountExVAT), projectDistribution)),
        100,
      );

      return mapNumberToAmount(roundFloating(result));
    },
    [mapAmountToNumber, mapNumberToAmount],
  );

  /*
    When `amountExVAT` changes, distribution field is update to be equal to rawProjectAmountExVAT / amountExVAT
   */
  const { amountExVAT } = useOnBlurValues(form, ['amountExVAT']);

  useEffect(() => {
    const { [distributionFieldName]: projectDistribution } = form.getFormValues();

    const newForm: Partial<EditSupplierInvoiceForm> = {};
    newForm[projectAmountExVATFieldName] = computeProjectAmountFieldValue(amountExVAT, projectDistribution);
    form.setFormValues(newForm);
  }, [amountExVAT, computeProjectAmountFieldValue, distributionFieldName, form, projectAmountExVATFieldName]);

  /*
    Store current input focused
   */
  const focusedInputRef = useRef<null | ProjectDistributionFieldName | ProjectAmountExVATFieldName>(null);
  const onInputFocus = useCallback(
    (inputName: ProjectDistributionFieldName | ProjectAmountExVATFieldName) => () => {
      focusedInputRef.current = inputName;
    },
    [],
  );
  const onInputBlur = useCallback(() => {
    focusedInputRef.current = null;
  }, []);

  /*
    If the field is focused, when project amount exc. VAT is changed, update project distribution
   */
  const onProjectAmountExVATChange = useCallback(() => {
    if (focusedInputRef.current !== projectAmountExVATFieldName) return;

    const { [projectAmountExVATFieldName]: projectAmountExVAT } = form.getFormValues();
    const newForm: Partial<EditSupplierInvoiceForm> = {};
    newForm[distributionFieldName] = computeProjectDistributionFieldValue(amountExVAT, projectAmountExVAT);
    form.setFormValues(newForm);
  }, [amountExVAT, distributionFieldName, form, projectAmountExVATFieldName]);

  /*
    If the field is focused, when project distribution is changed, project amount exc. VAT
   */
  const onProjectDistributionChange = useCallback(() => {
    if (focusedInputRef.current !== distributionFieldName) return;

    const { [distributionFieldName]: projectDistribution } = form.getFormValues();
    const newForm: Partial<EditSupplierInvoiceForm> = {};
    newForm[projectAmountExVATFieldName] = computeProjectAmountFieldValue(amountExVAT, projectDistribution);
    form.setFormValues(newForm);
  }, [amountExVAT, computeProjectAmountFieldValue, distributionFieldName, form, projectAmountExVATFieldName]);

  /*
    Handle row deletion
   */
  const deleteRow = useCallback(() => {
    const { associatedProjects } = form.getFormValues();
    form.setFormValues({
      associatedProjects: (associatedProjects || []).filter((associatedProject) => associatedProject.id !== project.id),
    });
  }, [form, project.id]);

  const { isValid } = useProjectsAmountsContext();
  const validationStyle = isValid ? {} : { inputProps: { borderColor: 'red.500' } };

  return (
    <Table.Row>
      <Table.Cell>
        <Circle bg={project.color ? getProjectColor(project.color) : 'gray.500'} />
      </Table.Cell>

      <Table.Cell
        // HACK: Avoid ellipsis to not working
        overflow="auto"
      >
        <EllipsisText>{project.name}</EllipsisText>
      </Table.Cell>

      <Table.Cell>
        <EllipsisText>{project.refCode}</EllipsisText>
      </Table.Cell>

      <Table.Cell>
        <EllipsisText>{project.primaryClient.enterpriseName}</EllipsisText>
      </Table.Cell>

      <Table.Cell>
        <Box maxW="5.5rem">
          <PercentageField<EditSupplierInvoiceForm>
            name={distributionFieldName}
            onChange={onProjectDistributionChange}
            onFocus={onInputFocus(distributionFieldName)}
            onBlur={onInputBlur}
            max={10000}
            {...validationStyle}
          >
            <Rule.IsRequired />
            <RuleIsProjectsAmountsAmInferiorToAmountExVAT />
          </PercentageField>
        </Box>
      </Table.Cell>

      <Table.Cell>
        <Box maxW="7rem">
          <CurrencyField<EditSupplierInvoiceForm>
            onChange={onProjectAmountExVATChange}
            name={projectAmountExVATFieldName}
            onFocus={onInputFocus(projectAmountExVATFieldName)}
            onBlur={onInputBlur}
            {...validationStyle}
          >
            <Rule.IsRequired />
            <RuleIsProjectsAmountsAmInferiorToAmountExVAT />
          </CurrencyField>
        </Box>
      </Table.Cell>

      <Table.Cell right>
        <ActionMenu>
          <ActionMenu.Delete onClick={deleteRow} />
        </ActionMenu>
      </Table.Cell>
    </Table.Row>
  );
};
