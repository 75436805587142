import type { ReactNode, MouseEventHandler, FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { useTableContext } from '../contexts/TableContext';
import { RowContext } from '../contexts/RowContext';

import type { HeaderCellProps } from './Cell/Cell.type';

const baseRowStyles = (isDisabled: boolean, to: string | undefined, onClick?: MouseEventHandler<HTMLDivElement>) => ({
  _groupHover: !isDisabled && (to || onClick) ? { bg: 'gray.50', textDecoration: 'none' } : undefined,
  px: 2,
  _first: { paddingLeft: 4 },
  _last: { paddingRight: 4 },
  borderBottom: '1px',
  borderColor: 'gray.200',
  fontWeight: '400',
  fontSize: '14px',
  textColor: isDisabled ? 'gray.300' : 'primaryLight',
  cursor: !isDisabled && (to || onClick) ? 'pointer' : undefined,
});

export interface RowProps extends HeaderCellProps {
  children: ReactNode;

  to?: string;

  onClick?: MouseEventHandler<HTMLDivElement>;

  displayDuringLoading?: boolean;

  isDisabled?: boolean;
}

/*
  for a table to work with a single grid (and not one per row), we need to have a parent component
  for each row with the display property set to "contents".
  Doc: "Elements with this property don't produce a specific box by themselves.
  They are replaced by their pseudo-box and their child boxes."
  As a result, the line styles (hover styles, borders) are passed down to each child cell.
*/
export const Row: FC<RowProps> = ({
  children,
  to,
  onClick,
  displayDuringLoading = false,
  isDisabled = false,
  ...styleProps
}) => {
  const history = useHistory();

  const handleOnClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (to) {
        history.push(to);
      }
      if (onClick) {
        // prevent double check
        e.preventDefault();
        onClick(e);
      }
    },
    [history, onClick, to],
  );

  const rowContext = useMemo(
    () => ({ ...baseRowStyles(isDisabled, to, onClick), ...styleProps }),
    [isDisabled, onClick, styleProps, to],
  );

  const tableContext = useTableContext();

  useEffect(() => {
    tableContext.registerRow();
    return () => tableContext.unRegisterRow();
  }, [tableContext]);

  if (tableContext.isLoading && !displayDuringLoading) {
    return null;
  }

  return (
    <RowContext.Provider value={rowContext}>
      <Box
        display="contents"
        onClick={isDisabled ? undefined : handleOnClick}
        _hover={{ textDecoration: 'none' }}
        role="group"
      >
        {children}
      </Box>
    </RowContext.Provider>
  );
};
