import { SUPPORT_EMAIL, QUOTE_STATUS } from '@graneet/business-logic';
import type { IPdf } from '@graneet/business-logic';
import type { Action } from '@graneet/lib-ui';
import {
  SimpleViewIcon,
  SimpleDownloadIcon,
  SimpleEmailIcon,
  SimpleEditIcon,
  SimpleSettingsIcon,
  SimpleDuplicateIcon,
  SimpleDeleteIcon,
  SimpleSearchIcon,
} from '@graneet/lib-ui';
import { t } from 'i18next';
import { QuoteStatus } from '@org/graneet-bff-client';

import type { QuotationActionsParams, QuoteActionsParams } from './quote.actions.type';

import { getPDFActionProps } from 'features/common/services/common.actions.util';

export function getPreviewPDFAction({ onViewPdf }: QuoteActionsParams | QuotationActionsParams, pdf: IPdf): Action {
  return {
    label: t('global:pdf.viewPDF'),
    icon: <SimpleViewIcon />,
    ...getPDFActionProps(pdf),
    onClick: onViewPdf,
  };
}

export function getDownloadPDFAction(
  { onDownloadPdf }: QuoteActionsParams | QuotationActionsParams,
  pdf: IPdf,
): Action {
  return {
    label: t('global:pdf.downloadPDF'),
    icon: <SimpleDownloadIcon />,
    ...getPDFActionProps(pdf),
    onClick: onDownloadPdf,
  };
}

export function getOpenMailingAction(
  { onOpenMailing }: QuoteActionsParams | QuotationActionsParams,
  pdf: IPdf,
): Action {
  return {
    label: t('mailing:modal.send'),
    icon: <SimpleEmailIcon />,
    ...getPDFActionProps(pdf),
    onClick: onOpenMailing,
  };
}

export function getEditAction({ hasCreatePermission, onEdit }: QuoteActionsParams | QuotationActionsParams): Action {
  return {
    label: t('quote:actions.modify'),
    icon: <SimpleEditIcon />,
    tooltip: !hasCreatePermission ? t('global:errors.disabledAction') : undefined,
    isDisabled: !hasCreatePermission,
    onClick: onEdit,
  };
}

export function getExportAction({ onGenerateExport }: QuoteActionsParams | QuotationActionsParams): Action {
  return {
    label: t('quote:export.action'),
    icon: <SimpleSettingsIcon />,
    onClick: onGenerateExport,
  };
}

export function getDuplicateAction({
  onDuplicate,
  hasCreatePermission,
}: QuoteActionsParams | QuotationActionsParams): Action {
  return {
    label: t('global:words.c.duplicate'),
    icon: <SimpleDuplicateIcon />,
    tooltip: !hasCreatePermission ? t('global:errors.disabledAction') : undefined,
    isDisabled: !hasCreatePermission,
    onClick: onDuplicate,
  };
}

export function getDeleteAction({
  quote,
  onDelete,
  hasCreatePermission,
}: QuoteActionsParams | QuotationActionsParams): Action {
  const tooltip = (() => {
    if (!hasCreatePermission) {
      return t('global:errors.disabledAction');
    }
    if (quote.status === QUOTE_STATUS.ACCEPTED) {
      return t('quote:cards.delete.tooltip.errorAccepted', { email: SUPPORT_EMAIL });
    }
    if (quote.status === QUOTE_STATUS.IMPORTING) {
      return t('quote:cards.delete.tooltip.errorImporting');
    }
    return undefined;
  })();

  const isDisabled =
    'settledAt' in quote
      ? !hasCreatePermission || [QuoteStatus.Importing, QuoteStatus.Accepted].includes(quote.status)
      : !hasCreatePermission || [QUOTE_STATUS.IMPORTING, QUOTE_STATUS.ACCEPTED].includes(quote.status);

  return {
    label: t('global:words.c.delete'),
    icon: <SimpleDeleteIcon />,
    warning: true,
    tooltip,
    isDisabled,
    onClick: onDelete,
  };
}

export function getOpenDetailAction({ onOpenDetail }: QuoteActionsParams | QuotationActionsParams): Action {
  return {
    label: t('quote:actions.openDetail'),
    icon: <SimpleSearchIcon />,
    onClick: onOpenDetail,
  };
}
