import { SUPPLIER_TYPE } from '@graneet/business-logic';
import type { BadgeProps, ButtonColorScheme } from '@graneet/lib-ui';
import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

export const SUPPLIER_COLORS: Record<SUPPLIER_TYPE, ButtonColorScheme> = {
  [SUPPLIER_TYPE.SUPPLIER]: 'purple',
  [SUPPLIER_TYPE.SUB_CONTRACTOR]: 'blue',
};

interface SupplierTypeBadgeProps extends Partial<BadgeProps> {
  type: SUPPLIER_TYPE;
}

export const SupplierTypeBadge = ({ type, ...props }: SupplierTypeBadgeProps) => {
  const { t } = useTranslation(['supplier']);
  const colorScheme = SUPPLIER_COLORS[type];

  return (
    <Badge showDot colorScheme={colorScheme} {...props}>
      {t(`supplier:types.${type}`)}
    </Badge>
  );
};
