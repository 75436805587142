import { Collapse, Flex, Grid, GridItem, HStack, Skeleton, Text } from '@chakra-ui/react';

import type { PaginatedResponse } from '../../PaginatedData';
import { isFilter, usePaginatedDataContext } from '../../PaginatedData';
import { useCurrency } from '../../Currency';
import { useTableContext } from '../contexts/TableContext';

export interface TableInformationProps<PaginatedType extends PaginatedResponse<object>> {
  sums: Partial<Record<keyof PaginatedType['sums'], { label: string; type: 'number' | 'currency' }>>;

  countLabel(count: number): string;
}

type Unpacked<T> = T extends (infer U)[] ? U : T;

export const TableInformation = <PaginatedType extends PaginatedResponse<object>>({
  sums,
  countLabel,
}: TableInformationProps<PaginatedType>): JSX.Element | null => {
  const paginatedDataContext = usePaginatedDataContext<Unpacked<PaginatedType['data']>, keyof PaginatedType['sums']>();

  const { formatAsAmount } = useCurrency();

  /*
   * Display information only if there is search or an active filter
   */
  const displayInformation =
    !!paginatedDataContext.searchState.searchTerm ||
    !![...paginatedDataContext.storage.entries()].filter(([v]) => isFilter(v)).length;

  const tableContext = useTableContext();

  return (
    <GridItem colSpan={tableContext.numberOfColumns}>
      <Collapse in={displayInformation}>
        <Flex
          bg="gray.100"
          h="2.5rem"
          justifyContent="space-between"
          alignItems="center"
          px={4}
          textColor="gray.700"
          fontSize="sm"
        >
          {paginatedDataContext.loading ? (
            <Grid w="100%" templateColumns="20rem auto 20rem">
              <Skeleton h="0.625rem" gridColumn={1} />
              <Skeleton h="0.625rem" gridColumn={3} />
            </Grid>
          ) : (
            <>
              <Flex>
                <Text fontWeight={600}>{paginatedDataContext.totalCount}</Text>
                <Text>&nbsp;{countLabel(paginatedDataContext.totalCount || 0)}</Text>
              </Flex>

              <HStack>
                {Object.entries<{ label: string; type: 'number' | 'currency' }>(sums as any).map(
                  ([key, { label, type }], index) => {
                    const value = paginatedDataContext.sums[key as keyof PaginatedType['sums']];
                    const formattedValue = type === 'currency' ? formatAsAmount(value) : value;

                    return (
                      <Flex key={key}>
                        <Text>∑ {label} :&nbsp;</Text>
                        <Text fontWeight={600}>{formattedValue}</Text>
                        {index < Object.keys(sums).length - 1 && (
                          <Text color="gray.600" pl={2}>
                            |
                          </Text>
                        )}
                      </Flex>
                    );
                  },
                )}
              </HStack>
            </>
          )}
        </Flex>
      </Collapse>
    </GridItem>
  );
};
