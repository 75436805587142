import {
  type Action,
  type DocumentStatusCardAction,
  SimpleDeleteIcon,
  SimpleViewIcon,
  SimpleDownloadIcon,
  SimpleEmailIcon,
  SimpleEditIcon,
  SimpleDuplicateIcon,
  Badge,
} from '@graneet/lib-ui';
import { ORDER_STATUS } from '@graneet/business-logic';
import { t } from 'i18next';

import type { OrderActionsParams } from './order.actions.type';

import { getPDFActionProps } from 'features/common/services/common.actions.util';

export function getChangeStatusAction({
  onSetOrderStatus,
  hasCreatePermission,
}: OrderActionsParams): DocumentStatusCardAction | undefined {
  if (!hasCreatePermission) {
    return undefined;
  }

  return {
    type: 'primaryDropdown',
    dropDownLabel: t('orders:fields.markAs'),
    options: [
      {
        label: <Badge>{t(`orders:statuses.${ORDER_STATUS.PROCESSED}`)}</Badge>,
        onClick: () => onSetOrderStatus(ORDER_STATUS.PROCESSED),
      },
      {
        label: <Badge colorScheme="yellow">{t(`orders:statuses.${ORDER_STATUS.PARTIALLY_DELIVERED}`)}</Badge>,
        onClick: () => onSetOrderStatus(ORDER_STATUS.PARTIALLY_DELIVERED),
      },
      {
        label: <Badge colorScheme="green">{t(`orders:statuses.${ORDER_STATUS.DELIVERED}`)}</Badge>,
        onClick: () => onSetOrderStatus(ORDER_STATUS.DELIVERED),
      },
    ],
  };
}

export function getDeleteAction({ hasCreatePermission, canDelete, onDelete }: OrderActionsParams): Action {
  const isDisabled = !hasCreatePermission || !canDelete;

  const tooltip = (() => {
    if (!hasCreatePermission) {
      return t('global:errors.disabledAction');
    }

    if (!canDelete) {
      return t('orders:delete.tooltip');
    }

    return undefined;
  })();

  return {
    label: t('global:words.c.delete'),
    warning: true,
    icon: <SimpleDeleteIcon />,
    isDisabled,
    tooltip,
    onClick: onDelete,
  };
}

export function getPreviewPDFAction({ onViewPdf, pdf }: OrderActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('global:pdf.viewPDF'),
    icon: <SimpleViewIcon />,
    ...getPDFActionProps(pdf),
    onClick: onViewPdf,
  };
}

export function getDownloadPDFAction({ onDownloadPdf, pdf }: OrderActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('global:pdf.downloadPDF'),
    icon: <SimpleDownloadIcon />,
    ...getPDFActionProps(pdf),
    onClick: onDownloadPdf,
  };
}

export function getOpenMailingAction({ onOpenMailing, pdf }: OrderActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('mailing:modal.send'),
    icon: <SimpleEmailIcon />,
    ...getPDFActionProps(pdf),
    onClick: onOpenMailing,
  };
}

export function getEditAction({ onEdit, hasCreatePermission }: OrderActionsParams): Action | undefined {
  return {
    label: t('global:words.c.update'),
    icon: <SimpleEditIcon />,
    isDisabled: !hasCreatePermission,
    tooltip: !hasCreatePermission ? t('global:errors.disabledAction') : undefined,
    onClick: onEdit,
  };
}

export function getDuplicateAction({ onDuplicate, hasCreatePermission }: OrderActionsParams): Action | undefined {
  return {
    label: t('global:words.c.duplicate'),
    icon: <SimpleDuplicateIcon />,
    isDisabled: !hasCreatePermission,
    tooltip: !hasCreatePermission ? t('global:errors.disabledAction') : undefined,
    onClick: onDuplicate,
  };
}
