import { useTranslation } from 'react-i18next';
import { PERMISSION } from '@graneet/business-logic';
import { useCallback, type FC } from 'react';
import type { QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';
import { Card } from '@graneet/lib-ui';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useQuotationApi } from 'features/quotation/services/quote.api';

interface QuoteCommentCardProps {
  quote: QuoteWithoutRelationsDTO;
}

export const QuoteTagsCard: FC<QuoteCommentCardProps> = ({ quote }) => {
  const { t } = useTranslation(['global']);
  const hasCreateQuotesPermission = usePermissions([PERMISSION.CREATE_QUOTES]);

  const { useQuotePartialUpdate, useGetQuoteAvailableTags } = useQuotationApi();

  const { data: availableTags } = useGetQuoteAvailableTags();
  const quotePartialUpdateMutation = useQuotePartialUpdate();

  const onTagsSaved = useCallback(
    async (newTags: string[]) => quotePartialUpdateMutation.mutateAsync({ quoteId: quote.id, tags: newTags }),
    [quote.id, quotePartialUpdateMutation],
  );

  if (!quote.tags && !hasCreateQuotesPermission) {
    return null;
  }

  return (
    <Card title={t('global:words.privateTags')}>
      <TagsEditionBlock
        placeholder={t('global:changeTags.placeholder')}
        tags={quote.tags}
        suggestedTags={availableTags.tags ?? []}
        onTagsEdited={onTagsSaved}
        isEditable={hasCreateQuotesPermission}
      />
    </Card>
  );
};
