import { ConversionStatus } from '@org/graneet-bff-client';
import { CONVERSION_STATUS } from '@org/quotation-lib';

export const mapFileConversionStatusToEntity = (conversionStatus: ConversionStatus) => {
  switch (conversionStatus) {
    case ConversionStatus.Converted:
      return CONVERSION_STATUS.CONVERTED;
    case ConversionStatus.Converting:
      return CONVERSION_STATUS.CONVERTING;
    case ConversionStatus.Error:
      return CONVERSION_STATUS.ERROR;
    case ConversionStatus.NotConverted:
      return CONVERSION_STATUS.NOT_CONVERTED;
    default:
      throw new Error('Conversion status of the file cant be mapped');
  }
};
