import { PAYMENT_ACTION_VALUES, PAYMENT_SATE_VALUES, type IBankingPaymentResource } from '@graneet/business-logic';
import { useCallback, type FC } from 'react';
import { Text, HStack } from '@chakra-ui/react';
import type { ButtonColorScheme } from '@graneet/lib-ui';
import { Badge, Tooltip } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

export const BANKING_PAYMENT_RESOURCE_STATUS_COLOR: Record<PAYMENT_SATE_VALUES, ButtonColorScheme> = {
  [PAYMENT_SATE_VALUES.created]: 'gray',
  [PAYMENT_SATE_VALUES.validating]: 'yellow',
  [PAYMENT_SATE_VALUES.pending]: 'orange',
  [PAYMENT_SATE_VALUES.rejected]: 'red',
  [PAYMENT_SATE_VALUES.done]: 'green',
  [PAYMENT_SATE_VALUES.accepted]: 'green',
  [PAYMENT_SATE_VALUES.expired]: 'gray',
  [PAYMENT_SATE_VALUES.partial]: 'gray',
};

interface BankingPaymentResourceStatusBadgeProps {
  bankingPaymentResource: Pick<IBankingPaymentResource, 'state' | 'errorCode' | 'action' | 'validateUri'>;
}

export const BankingPaymentResourceStatusBadge: FC<BankingPaymentResourceStatusBadgeProps> = ({
  bankingPaymentResource,
}) => {
  const { t } = useTranslation(['banking']);

  const handleOnClick = useCallback(() => {
    if (bankingPaymentResource.action === PAYMENT_ACTION_VALUES.redirect && bankingPaymentResource.validateUri) {
      window.open(bankingPaymentResource.validateUri, '_blank');
    }
  }, [bankingPaymentResource.action, bankingPaymentResource.validateUri]);

  if (bankingPaymentResource.state === null) {
    return null;
  }

  return (
    <Badge colorScheme={BANKING_PAYMENT_RESOURCE_STATUS_COLOR[bankingPaymentResource.state]} onClick={handleOnClick}>
      <HStack>
        {bankingPaymentResource.errorCode && (
          <Tooltip label={t(`banking:bankingPaymentResource.errorCode.${bankingPaymentResource.errorCode}`)}>
            <Text fontSize="small" lineHeight="1rem" color="red">
              <i className="ri-error-warning-line" />
            </Text>
          </Tooltip>
        )}
        <Text>{t(`banking:bankingPaymentResource.status.${bankingPaymentResource.state}`)}</Text>
      </HStack>
    </Badge>
  );
};
