export interface FormulafieldTranslations {
  genericError: string;
  impossibleCalculationError: string;
  formulaPlaceholder: string;
  commentPlaceholder: string;
}
export const formulaFieldTranslations: FormulafieldTranslations = {
  genericError: 'Expression incorrecte',
  impossibleCalculationError: 'Calcul impossible',
  formulaPlaceholder: 'ex : (5+2)*2+...',
  commentPlaceholder: 'Rentrez ici vos commentaires...',
};

export const configureDefaultLabels = (translation: FormulafieldTranslations) => {
  Object.assign(formulaFieldTranslations, translation);
};
