import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { t } from 'i18next';

import { getDeleteAction, getDuplicateAction, getEditAction, getExportAction } from './quote.actions.util';
import type { QuotationActionsParams, QuoteActionsParams } from './quote.actions.type';

export function getQuoteDraftActions(
  params: QuoteActionsParams | QuotationActionsParams,
  full = true,
): DocumentStatusCardAction[] {
  const { isCompleteButtonLoading, hasCreatePermission, onCompleteQuote } = params;
  const actions: DocumentStatusCardAction[] = [];

  // Mark as complete
  actions.push({
    type: 'primary',
    label: t('quote:actions.complete'),
    isLoading: isCompleteButtonLoading,
    tooltip: !hasCreatePermission ? t('global:errors.disabledAction') : undefined,
    isDisabled: !hasCreatePermission,
    onClick: onCompleteQuote,
  });

  if (full) {
    // Edit
    actions.push({
      type: 'secondary',
      ...getEditAction(params),
    });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: [...(full ? [getExportAction(params)] : []), getDuplicateAction(params), getDeleteAction(params)],
  });

  return actions;
}
