import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Modal } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { AccountingAccountsNeeds } from '@graneet/business-logic';

import type { AccountingMissingCodeFormValues } from 'features/accounting/components/AccountingMissingCodeForm';
import { AccountingMissingCodeForm } from 'features/accounting/components/AccountingMissingCodeForm';
import { QueryWrapper } from 'features/api/components/QueryWrapper';

interface ExportModalMissingCodeStepProps {
  accountingAccountsNeeds: AccountingAccountsNeeds;

  onClose(): void;

  onSubmit(formValues: AccountingMissingCodeFormValues): Promise<void>;
}

export const ExportModalMissingCodeStep: FC<ExportModalMissingCodeStepProps> = ({
  accountingAccountsNeeds,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation(['global', 'accounting']);

  const form = useForm<AccountingMissingCodeFormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    await onSubmit(form.getFormValues());
    setIsLoading(false);
  }, [form, onSubmit]);

  return (
    <Form form={form}>
      <QueryWrapper>
        <AccountingMissingCodeForm accountingAccountsNeeds={accountingAccountsNeeds} />

        <Modal.SecondaryButton onClick={onClose} isDisabled={isLoading}>
          {t('global:words.c.cancel')}
        </Modal.SecondaryButton>

        <Modal.PrimaryButton isDisabled={!isFormValid} isLoading={isLoading} onClick={handleSubmit}>
          {t('accounting:exportModal.cta')}
        </Modal.PrimaryButton>
      </QueryWrapper>
    </Form>
  );
};
