import { useTranslation } from 'react-i18next';
import { Form, useForm, useFormContext } from 'graneet-form';
import { Button, formatVatRateToNumber, PercentageField, useValuesContext } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FEATURE_FLAGS, isNumberFinite } from '@graneet/business-logic';

import { getOrderItemFieldName } from '../../../forms/order-edit-wizard';
import type { OrderEditForm } from '../../../forms/order-edit-wizard';
import type { OrderTree } from '../../../hooks/useOrderTree';

import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';

interface BatchForm {
  type?: number;

  vat?: number;
}

interface OrderItemBatchActionsProps {
  tree: OrderTree;
}

export const OrderItemBatchActions: FC<OrderItemBatchActionsProps> = ({ tree }) => {
  const { t } = useTranslation(['orders']);

  const form = useFormContext<OrderEditForm>();

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  const batchForm = useForm<BatchForm>();

  const { getValues, resetValues } = useValuesContext<string>();

  const values = getValues();

  const applyBatchUpdates = useCallback(() => {
    const formValues = batchForm.getFormValues();

    const newFormValues: Partial<OrderEditForm> = {};
    values.forEach((row) => {
      if (formValues.type) {
        newFormValues[getOrderItemFieldName(row, 'typeId')] = formValues.type;
        tree.updateLeafData(row, {
          typeId: formValues.type,
        });
      }
      if (isNumberFinite(formValues.vat)) {
        newFormValues[getOrderItemFieldName(row, 'vatRate')] = formValues.vat;
        tree.updateLeafData(row, {
          vatRate: isNumberFinite(formValues.vat) ? formatVatRateToNumber(formValues.vat) : null,
        });
      }
    });

    form.setFormValues(newFormValues);
    batchForm.resetForm();
    resetValues();
  }, [batchForm, form, resetValues, tree, values]);

  if (values.length === 0) return null;

  return (
    <Form form={batchForm}>
      <Flex direction="row" gap={3} alignItems="center" mb={2}>
        <Box w="15rem">
          <ComponentTypeField<BatchForm>
            name="type"
            material
            isRequired
            placeholder={t('orders:fields.orderItems.disbursement')}
          />
        </Box>

        <Box w="15rem">
          {hasStandardVATRates ? (
            <AccountingVATField<BatchForm>
              name="vat"
              valueScale={100}
              placeholder={t('orders:fields.orderItems.vatRate')}
            />
          ) : (
            <PercentageField<BatchForm> name="vat" placeholder={t('orders:fields.orderItems.vatRate')} />
          )}
        </Box>

        <Button onClick={applyBatchUpdates}>
          {t('orders:orderItemModal.updateSelectedItems', { count: values.length })}
        </Button>
      </Flex>
    </Form>
  );
};
