import { useCallback } from 'react';
import Big from 'big.js';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteLotUpdateDefaultVatRate() {
  const { quoteLotsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, newDefaultVatRate: string): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
          const quoteNode = quote.getTree().getNodeOrThrow(nodeId);

          this.oldValue = quoteLot.getDefaultVatRate();
          quoteLot.updateDefaultVatRate(new Big(newDefaultVatRate));

          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteLotsProxyApi
            .updateQuoteLot(quoteLot.getId(), {
              timestamp,
              clientRequestId,
              prevSiblingId: quoteNode.getPrevSibling()?.getId() || null,
              nextSiblingId: quoteNode.getNextSibling()?.getId() || null,
              nodeId: quoteNode.getId(),
              defaultVatRate: newDefaultVatRate,
              quoteLotId: quoteLot.getId(),
              quoteId: quote.getId(),
              // TODO: improve this in case of a root lot with parent id
              parentNodeId: quoteNode.getParent()?.getId() || '',
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
          const quoteNode = quote.getTree().getNodeOrThrow(nodeId);

          quoteLot.updateDefaultVatRate(this.oldValue);

          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteLotsProxyApi
            .updateQuoteLot(quoteLot.getId(), {
              timestamp,
              clientRequestId,
              prevSiblingId: quoteNode.getPrevSibling()?.getId() || null,
              nextSiblingId: quoteNode.getNextSibling()?.getId() || null,
              nodeId: quoteNode.getId(),
              defaultVatRate: this.oldValue,
              quoteLotId: quoteLot.getId(),
              quoteId: quote.getId(),
              // TODO: improve this in case of a root lot with parent id
              parentNodeId: quoteNode.getParent()?.getId() || '',
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteLotsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
