export function formatImportAmount(rawAmount: string) {
  const separatorsStudy = rawAmount.match(/(,|\.)?(?:[^,.]*)(,|\.)(?:[^,.]*)$/);
  let decimalSeparator = '.';
  if (separatorsStudy) {
    if (separatorsStudy[2] && separatorsStudy[1] !== separatorsStudy[2]) {
      [, , decimalSeparator] = separatorsStudy;
    }
  }

  return rawAmount.replace(new RegExp(`[^-0-9${decimalSeparator}]`, 'g'), '').replace(decimalSeparator, '.');
}
