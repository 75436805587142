import { useDisclosure } from '@chakra-ui/react';
import { Card } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback } from 'react';
import type { IClient, IClientResponseDTO } from '@graneet/business-logic';

import { ClientUpsertModal } from '../modals/ClientUpsertModal';
import { ClientSwitchModal } from '../modals/ClientSwitchModal';

import { ClientCardDetails, ClientCardTypeEnum } from './ClientCardDetails';

interface ClientCardProps {
  type: ClientCardTypeEnum | null;
  client: IClientResponseDTO | IClient;
  onClientUpdate?: (clientId: number | null) => Promise<IClient | null | IClientResponseDTO>;
  onClientEdited?: () => void;
  onClientCardButtonClicked?: () => void;
  isButtonDisable?: boolean;
}

export const ClientCard: FC<ClientCardProps> = ({
  client,
  type,
  onClientEdited,
  onClientCardButtonClicked,
  onClientUpdate,
  isButtonDisable,
}) => {
  const { t } = useTranslation(['global', 'clients', 'project']);

  const clientEditionModal = useDisclosure();
  const clientSwitchModal = useDisclosure();

  const handleClientEdition = useCallback(() => {
    if (onClientEdited) {
      onClientEdited();
    }
    clientEditionModal.onClose();
  }, [clientEditionModal, onClientEdited]);

  return (
    <Card padding="inherit" alignSelf="stretch">
      <ClientCardDetails
        client={client}
        type={type}
        onClientCardButtonClicked={
          onClientCardButtonClicked ||
          (type === ClientCardTypeEnum.SWITCH ? clientSwitchModal.onOpen : clientEditionModal.onOpen)
        }
        isButtonDisable={isButtonDisable}
      >
        {type === ClientCardTypeEnum.EDIT && (
          <ClientUpsertModal
            {...clientEditionModal}
            client={client}
            onSuccess={handleClientEdition}
            title={t('clients:editClient')}
            description={t('clients:clientModalEditor.editionDescription')}
            submitButtonLabel={t('global:words.c.update')}
          />
        )}
        {type === ClientCardTypeEnum.SWITCH && onClientUpdate && (
          <ClientSwitchModal
            {...clientSwitchModal}
            client={client}
            isMultipleSelection={false}
            title={t('project:flow.client.selectModal.title')}
            description={t('project:flow.client.selectModal.description')}
            onUpdate={onClientUpdate}
          />
        )}
      </ClientCardDetails>
    </Card>
  );
};
