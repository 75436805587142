import type { AnyRecord, FieldValues } from 'graneet-form';
import { Field } from 'graneet-form';
import type { ReactNode } from 'react';
import { useCallback } from 'react';

import type { KeysMatching } from '../../../utils';
import { MultipleFileUpload } from '../../MultipleFileUpload';
import type { FileWithOptions, MultipleFileUploadProps } from '../../MultipleFileUpload';

type FormulaFieldValue = FileWithOptions[];

interface MultipleFileUploadFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, FormulaFieldValue> = KeysMatching<T, FormulaFieldValue>,
> extends Pick<MultipleFileUploadProps, 'onFileOpen' | 'checkBeforeUpload'> {
  name: K;

  onChange?(e: T[K] | undefined): void;

  children?: ReactNode;

  data?: AnyRecord;
}

export const MultipleFileUploadField = <
  T extends FieldValues,
  K extends KeysMatching<T, FormulaFieldValue> = KeysMatching<T, FormulaFieldValue>,
>({
  name,
  onChange,
  children,
  onFileOpen,
  checkBeforeUpload,
  data,
}: MultipleFileUploadFieldProps<T, K>) => {
  const render = useCallback(
    ({
      value,
      onChange: onChangeForm,
      onFocus: onFocusForm,
      onBlur: onBlurForm,
    }: {
      value: T[K] | undefined;
      onFocus(): void;
      onBlur(): void;
      onChange(e: T[K] | undefined): void;
    }) => {
      const handleChange = (v: FileWithOptions[]) => {
        onFocusForm();
        onChange?.(v as any);
        onChangeForm(v as any);
        onBlurForm();
      };

      const onDelete = (index: string) => {
        handleChange(((value as any[]) || []).filter((_, i) => i !== parseInt(index, 10)));
      };

      return (
        <MultipleFileUpload
          files={value || []}
          onChange={handleChange}
          onDelete={onDelete}
          onFileOpen={onFileOpen}
          checkBeforeUpload={checkBeforeUpload}
        />
      );
    },
    [checkBeforeUpload, onChange, onFileOpen],
  );

  return (
    <Field<T, K> name={name} render={render} data={data}>
      {children}
    </Field>
  );
};
