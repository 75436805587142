import { Box, Flex, HStack } from '@chakra-ui/react';
import { TreeIndicator } from '@graneet/lib-ui';
import type { QuoteComponentObject, QuoteNodeObject } from '@org/quotation-lib';
import type { FC } from 'react';
import type { GridApi } from '@ag-grid-community/core';

import { Item } from './Item';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const Component: FC<{
  item: QuoteComponentObject;
  parentId: string | null;
  level: number;
  nodeId: string;
  api: GridApi<QuoteNodeObject>;
}> = ({ item, parentId, level, nodeId, api }) => {
  const { quote } = useQuote();
  const node = parentId ? quote.getTree().getNodeOrNull(parentId) : null;
  const isLastChild = node?.getLastChild()?.getContent().getId() === item.id;
  const isParentLastChild = node?.getParent()?.getLastChild()?.getId() === node?.getId();

  return (
    <HStack width="100%" gap={0} height="100%" alignItems="flex-start" justifyItems="flex-start">
      {node?.getContent().getType() === 'QuoteSubItem' && (
        <Flex alignSelf="stretch">{isParentLastChild ? <Box width="1.25rem" /> : <TreeIndicator type="simple" />}</Flex>
      )}
      <Flex alignSelf="stretch" pl={`${(level - 2) * 0.313}rem`}>
        <TreeIndicator type={isLastChild ? 'end' : 'intermediate'} top={isLastChild ? undefined : '5px'} />
      </Flex>
      <Item
        nodeId={nodeId}
        level={0}
        componentTypeId={item.componentTypeId}
        type={item.type}
        item={item}
        api={api}
        isExpanded={false}
      />
    </HStack>
  );
};
