import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';
import type { FC } from 'react';

const VALIDATE_IS_TRUE = (value: FieldValue) => value === true;

interface IsTrueProps {
  message?: string;
}

export const IsTrue: FC<IsTrueProps> = ({ message }) => (
  <Rule message={message || ''} validationFn={VALIDATE_IS_TRUE} />
);
