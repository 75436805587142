import { useLayoutEffect, useMemo } from 'react';
import {
  ListingLayout,
  Filters,
  usePaginatedData,
  PaginatedDataProvider,
  AsyncTableData,
  SimpleQuoteIcon,
  SimpleAlertIcon,
  generateColorFromString,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { PERMISSION, QUOTE_STATUS } from '@graneet/business-logic';
import { Flex } from '@chakra-ui/react';

import { QUOTE_STATUS_COLOR, QUOTE_FIELDS } from 'features/quote/constants/quotes.constant';
import { QuoteActionsButtons } from 'features/quote/components/buttons/QuoteActionsButtons';
import { QuotesTable } from 'features/quote/components/tables/QuotesTable';
import { getAvailableQuoteTags, getQuotesPaginated } from 'features/quote/services/quote.api';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { formatUserDisplayName } from 'features/user/services/user.util';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useData } from 'features/api/hooks/useData';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';
import { useAppContext } from 'features/app/contexts/AppContext';
import { useDisplayedUsers } from 'features/user/services/user.api';

const SCREEN_PERMISSIONS = [PERMISSION.VIEW_ALL_QUOTES];

export const ViewQuotesScreen = () => {
  const { t } = useTranslation(['global', 'quote', 'accountManager']);
  const { updateHeaderTitle } = useHeaderContext();
  const { currentUser } = useAppContext();

  const hasPermissions = usePermissions(SCREEN_PERMISSIONS);
  const forcedFilters = useMemo(
    () =>
      hasPermissions
        ? undefined
        : {
            [QUOTE_FIELDS.ACCOUNT_MANAGERS]: [currentUser!.id],
          },
    [currentUser, hasPermissions],
  );

  const data = usePaginatedData(getQuotesPaginated, forcedFilters, forcedFilters);

  const { data: paginatedQuotes, fetch: fetchQuotes } = data;

  const { data: tags } = useData(useDataGetter(getAvailableQuoteTags));

  const quoteActions = useMemo(() => <QuoteActionsButtons onImportCompleted={fetchQuotes} />, [fetchQuotes]);

  const availableStatuses = useMemo(
    () =>
      (Object.keys(QUOTE_STATUS) as QUOTE_STATUS[]).map((status) => ({
        value: QUOTE_STATUS[status],
        colorScheme: QUOTE_STATUS_COLOR[status],
        label: t(`quote:statuses.${status}`),
      })),
    [t],
  );

  const users = useDisplayedUsers();

  const availableUsers = useMemo(
    () =>
      users.data.map<{ value: string; label: string }>((user) => ({
        value: user.id.toString(),
        label: formatUserDisplayName(user),
      })),
    [users],
  );

  const availableTags = useMemo(
    () =>
      (tags || []).map<{ value: string; label: string }>((tag) => ({
        value: tag,
        label: tag,
        badgeColor: generateColorFromString(tag),
      })),
    [tags],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.quotes'), []);
  }, [t, updateHeaderTitle]);

  return (
    <PaginatedDataProvider data={data}>
      <Filters>
        <ListingLayout h="auto" p={8}>
          <ListingLayout.Search placeholder={t('quote:actions.search')} />
          <Flex gap={2} alignItems="center">
            <ListingLayout.FilterButton>
              <Filters.CheckBox
                name={QUOTE_FIELDS.STATUS}
                availableValues={availableStatuses}
                label={t('quote:filters.status')}
              />
              <Filters.MultiSelectFilters
                name={QUOTE_FIELDS.TAGS}
                availableValues={availableTags}
                label={t('global:words.tags')}
                noValueMessage={t('global:tags.noLabel')}
                placeholder={t('global:words.c.select')}
              />
              {hasPermissions && (
                <Filters.MultiSelectFilters
                  name={QUOTE_FIELDS.ACCOUNT_MANAGERS}
                  availableValues={availableUsers}
                  label={t('accountManager:title')}
                  placeholder={t('global:words.c.select')}
                />
              )}
              <Filters.DateSelect name={QUOTE_FIELDS.DATE} label={t('quote:filters.date')} />
            </ListingLayout.FilterButton>
          </Flex>
          <ListingLayout.Actions>{quoteActions}</ListingLayout.Actions>
          <ListingLayout.FiltersApplied
            filters={{
              [QUOTE_FIELDS.STATUS]: {
                label: t('quote:filters.status'),
                type: 'multi',
                availableValues: availableStatuses,
              },
              [QUOTE_FIELDS.ACCOUNT_MANAGERS]: {
                label: t('accountManager:title'),
                type: 'multi',
                availableValues: availableUsers,
              },
              [QUOTE_FIELDS.TAGS]: {
                label: t('global:words.tags'),
                type: 'multi',
                availableValues: availableTags,
              },
              [QUOTE_FIELDS.DATE]: {
                label: t('quote:filters.date'),
                type: 'date',
              },
            }}
          />
          <ListingLayout.Content>
            <AsyncTableData
              errorState={{
                message: t('quote:errors.listingError', { email: SUPPORT_EMAIL }),
                icon: <SimpleAlertIcon boxSize={45} />,
              }}
              emptyState={{
                icon: <SimpleQuoteIcon boxSize={45} />,
                children: t('quote:errors.noQuote'),
                action: quoteActions,
              }}
            >
              <QuotesTable quotes={paginatedQuotes || []} showClientColumn showAccountManagers hasPagination />
            </AsyncTableData>
          </ListingLayout.Content>
        </ListingLayout>
      </Filters>
    </PaginatedDataProvider>
  );
};
