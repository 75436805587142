import type { FC, ReactNode } from 'react';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { Box, useTheme } from '@chakra-ui/react';
import type { MultiValue, Size } from 'chakra-react-select';
import { CreatableSelect, Select } from 'chakra-react-select';

import type { FormGroupProps } from '../FormGroup/FormGroup';
import { FormGroup } from '../FormGroup/FormGroup';
import type { BadgeProps } from '../Badge/Badge';

import {
  AutocompleteBadge,
  autocompleteComponents,
  autocompleteComponentsOutlined,
  getAutocompleteComponentsStyle,
} from './AutocompleteComponents';
import type { OptionType } from './Autocomplete.util';
import { normalizeOptionValue } from './Autocomplete.util';
import { AutocompleteProvider } from './AutocompleteContext';

export interface MultiAutocompleteProps<Option extends OptionType, RenderProps>
  extends Omit<FormGroupProps, 'onChange'> {
  name?: string;
  label?: ReactNode;
  size?: Size;
  placeholder?: string | undefined;
  options: (Option & RenderProps)[];
  closeMenuOnSelect?: boolean;
  value: Array<Option['value']>;
  onChange: (value: Array<Option['value']>) => void;
  render?: FC<{ data: Option & RenderProps }>;
  shouldDisplayError?: boolean;
  hasBeenBlurred?: boolean;
  allowCreateble?: boolean;
  formatValue?: (value: Option) => Option;
  variant?: 'classic' | 'outlined' | 'flushed';
  createLabel?: string;
}

const CHAKRA_STYLE = {
  placeholder: (base: any) => ({
    ...base,
    color: 'gray.600',
  }),
};

export const MultiAutocomplete = <Option extends OptionType, RenderProps = Omit<BadgeProps, 'children'>>({
  name,
  label,
  size = 'sm',
  placeholder,
  options = [],
  closeMenuOnSelect = false,
  value,
  onChange,
  render,
  errorMessage,
  shouldDisplayError,
  allowCreateble,
  isReadOnly,
  formatValue: formatValueProp,
  variant = 'classic',
  createLabel,
  ...otherProps
}: MultiAutocompleteProps<Option, RenderProps>) => {
  const formatValue = useCallback((v: Option) => (formatValueProp ? formatValueProp(v) : v), [formatValueProp]);

  const { colors } = useTheme();
  const [formatedOptions, setFormatedOptions] = useState((options ?? []).map((o) => formatValue(o)));
  const [addedOptions, setAddedOptions] = useState<Option[]>([]);
  const [selectedValue, setSelectedValues] = useState(
    (value ?? []).map((o) =>
      normalizeOptionValue(
        (options ?? []).map((option) => formatValue(option)),
        o,
      ),
    ),
  );

  useEffect(() => {
    setSelectedValues((value ?? []).map((o) => normalizeOptionValue([...formatedOptions, ...addedOptions], o)!));
  }, [value, addedOptions, formatedOptions]);

  useEffect(() => {
    setFormatedOptions((options ?? []).map((o) => formatValue(o)));
  }, [options, formatValue]);

  const handleChange = useCallback(
    (option: MultiValue<Option & { __isNew__?: boolean }>) => {
      // eslint-disable-next-line no-underscore-dangle
      const newOptions = option.filter((o) => o.__isNew__).map((o) => formatValue(o));
      setAddedOptions(newOptions);
      onChange(option.map(({ value: optionValue }) => optionValue));
    },
    [onChange, formatValue],
  );

  const [hasBeenBlurred, setHasBeenBlurred] = useState(false);
  const handleBlur = useCallback(() => {
    setHasBeenBlurred(true);
  }, []);

  const formatCreateLabel = useMemo(
    () => (createLabel ? (inputValue: string) => `${createLabel} "${inputValue}"` : undefined),
    [createLabel],
  );

  return (
    <AutocompleteProvider value={render || AutocompleteBadge}>
      <FormGroup
        label={label}
        showError={shouldDisplayError && hasBeenBlurred}
        isFocused={false}
        errorMessage={errorMessage}
        {...otherProps}
      >
        <Box borderWidth="1px" borderColor="gray.200" borderRadius="8px" pr={2} w="100%">
          {allowCreateble ? (
            <CreatableSelect<Option, true>
              name={name}
              size={size}
              isClearable
              isDisabled={isReadOnly}
              isMulti
              placeholder={placeholder}
              value={selectedValue as any}
              options={[...formatedOptions, ...addedOptions]}
              onChange={handleChange}
              onBlur={handleBlur}
              noOptionsMessage={() => null}
              menuPortalTarget={document.body}
              closeMenuOnSelect={closeMenuOnSelect}
              variant={variant}
              styles={getAutocompleteComponentsStyle(colors)}
              chakraStyles={CHAKRA_STYLE}
              components={variant === 'outlined' ? autocompleteComponentsOutlined : autocompleteComponents}
              formatCreateLabel={formatCreateLabel}
            />
          ) : (
            <Select<Option, true>
              name={name}
              size={size}
              isClearable
              isDisabled={isReadOnly}
              isMulti
              placeholder={placeholder}
              value={selectedValue as any}
              options={formatedOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              noOptionsMessage={() => null}
              menuPortalTarget={document.body}
              closeMenuOnSelect={closeMenuOnSelect}
              variant={variant}
              styles={getAutocompleteComponentsStyle(colors)}
              chakraStyles={CHAKRA_STYLE}
              components={variant === 'outlined' ? autocompleteComponentsOutlined : autocompleteComponents}
            />
          )}
        </Box>
      </FormGroup>
    </AutocompleteProvider>
  );
};
