import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { AmountSummary, useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { VAT_TYPE, computeOrderAmounts, isNumberFinite } from '@graneet/business-logic';

import { useOrderLot, useOrderLotComputedValue, useOrderTreeContext } from '../../../hooks/useOrderTree';
import type { OrderEditForm } from '../../../forms/order-edit-wizard';
import { useOrderDirectPaymentInfosContext } from '../../../contexts/OrderDirectPaymentInfosContext';

import { OrderLotVatRateModal } from './OrderLotVatRateModal';
import { OrderAddItemButton } from './OrderAddItemButton';

export const OrderDeepTableFooter: FC = () => {
  const { mapNumberToAmount } = useCurrency();

  const { t } = useTranslation(['global', 'orders']);

  const form = useFormContext<OrderEditForm>();
  const { hasUnitPrices, vatType = VAT_TYPE.NORMAL } = useOnChangeValues(form, ['hasUnitPrices', 'vatType']);

  const tree = useOrderTreeContext();
  const rootLotId = useMemo(() => tree.getInitialTree().rootNodeId, [tree]);

  // /!\ Usage of useOrderLot is here to force amounts update on every item changes
  useOrderLot(rootLotId);

  const displayedItems = tree.getDisplayedCurrentTree().leaves;
  const computedValuesOfDisplayedLeaves = Object.values(tree.getComputedValues().leaves).filter(
    (item) => displayedItems[item.id],
  );
  const { amountExVAT, vatDistribution, amountIncVAT } = computeOrderAmounts(
    {
      hasUnitPrices: true,
      vatRate: null,
      amountExVAT: null,
      vatType,
    },
    computedValuesOfDisplayedLeaves,
  );

  const { numberOfItems, numberOfIncompleteItems } = useOrderLotComputedValue(rootLotId) || {};

  // Avoid infinite rerender with stringify.
  const vatDistributionAsString = useMemo(() => JSON.stringify(vatDistribution), [vatDistribution]);

  useEffect(() => {
    const newFormValues = {
      ...(numberOfItems !== 0 &&
        hasUnitPrices && {
          amountExVAT: isNumberFinite(amountExVAT) ? mapNumberToAmount(amountExVAT) : amountExVAT,
        }),
      vatDistribution: JSON.parse(vatDistributionAsString),
      numberOfItems,
      numberOfIncompleteItems,
    };
    form.setFormValues(newFormValues);
  }, [
    amountExVAT,
    form,
    mapNumberToAmount,
    vatDistributionAsString,
    numberOfItems,
    numberOfIncompleteItems,
    hasUnitPrices,
  ]);

  const modalGlobalVATRateEdit = useDisclosure();
  const { areVatRatesEditable } = useOrderDirectPaymentInfosContext();
  const vatRateDropdownItems = useMemo(() => {
    const items = [];

    if (areVatRatesEditable && vatType === VAT_TYPE.NORMAL) {
      items.push({
        onClick: modalGlobalVATRateEdit.onOpen,
        label: t('global:applyGlobalVAT'),
      });

      items.push({
        onClick: () => form.setFormValues({ vatType: VAT_TYPE.REVERSE_CHARGE }),
        label: t('orders:actions.applyReversalOfLiability'),
      });

      items.push({
        onClick: () => form.setFormValues({ vatType: VAT_TYPE.INTRA_COMMUNITY }),
        label: t('orders:actions.applyIntraCommunity'),
      });
    }

    if (areVatRatesEditable && vatType !== VAT_TYPE.NORMAL) {
      items.push({
        onClick: () => form.setFormValues({ vatType: VAT_TYPE.NORMAL }),
        label:
          vatType === VAT_TYPE.REVERSE_CHARGE
            ? t('orders:actions.removeReversalOfLiability')
            : t('orders:actions.removeIntraCommunity'),
      });
    }
    return items;
  }, [areVatRatesEditable, form, modalGlobalVATRateEdit.onOpen, t, vatType]);

  return (
    <>
      <Box mt={3}>
        <OrderAddItemButton direction="row" />
      </Box>

      <OrderLotVatRateModal
        orderLotId={rootLotId}
        onClose={modalGlobalVATRateEdit.onClose}
        isOpen={modalGlobalVATRateEdit.isOpen}
      />

      {hasUnitPrices && (
        <Flex justifyContent="flex-end" alignItems="center">
          <AmountSummary mt={0}>
            <AmountSummary.Item
              important
              label={t('orders:orderItemModal.amountSummary.amountExVAT')}
              amount={amountExVAT}
            />
            <AmountSummary.VATDistribution
              vatDistribution={vatDistribution}
              dropdownItems={vatRateDropdownItems}
              vatType={vatType}
            />
            <AmountSummary.Item
              important
              label={t('orders:orderItemModal.amountSummary.amountIncVAT')}
              amount={amountIncVAT}
            />
          </AmountSummary>
        </Flex>
      )}
    </>
  );
};
