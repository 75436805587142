import type { FieldValues } from 'graneet-form';
import { useWizard, WizardContext, Placeholder } from 'graneet-form';
import type { FlexProps } from '@chakra-ui/react';
import { Portal, Flex } from '@chakra-ui/react';
import type { ReactNode, RefObject } from 'react';

import { Header } from './Header';
import { Footer, FOOTER_HEIGHT, PLACEMENT } from './Footer';
import { WizardStep } from './WizardStep';
import type { WizardLabels } from './types/WizardLabels';
import { InternalWizardContextProvider, useInternalWizard } from './hooks/useInternalWizardContext';

export interface WizardProps<WizardValues extends Record<string, FieldValues>> extends FlexProps {
  /**
   * List of steps
   */
  children: ReactNode;

  /**
   * Function run when use quit the wizard
   */
  onQuit?: () => void | Promise<void>;

  /**
   * Function run at the last step
   */
  onFinish(wizardValues: WizardValues): void;

  /**
   * Title display by the header
   */
  headerTitle?: string;

  /**
   * Labels of texts displayed
   */
  labels: WizardLabels;

  scrollerRef?: RefObject<HTMLDivElement | null> | ((ref: HTMLDivElement | null) => void);
}

/**
 * Wizard to handle multiple steps
 * @example
 * ```
 * <Wizard
 *   headerTitle="Title"
 *   onQuit={() => console.log('Close')}
 *   onFinish={onFinish}
 *   labels={labels}
 * >
 *   <Wizard.Step
 *     name={MY_PAGE_STEPS.FIRST_PAGE}
 *     onNext={(stepValues) => api.saveStepOne(stepValues).then(() => true)}
 *   >
 *     <StepOne />
 *   </Wizard.Step>
 *   <Wizard.Step name={MY_PAGE_STEPS.SECOND_PAGE}>
 *     <StepTwo />
 *   <Wizard.Step />
 * </Wizard>
 * ```
 */
const WizardComponent = <WizardValues extends Record<string, FieldValues>>({
  children,
  onQuit = () => {},
  onFinish,
  headerTitle,
  labels,
  scrollerRef,
  ...props
}: WizardProps<WizardValues>) => {
  const wizard = useWizard<WizardValues>(onFinish, onQuit);
  const { hasNoFooter } = wizard;

  const internalWizard = useInternalWizard();

  return (
    <Portal>
      <InternalWizardContextProvider value={internalWizard}>
        <Flex direction="column" height="100%" width="100%" position="absolute" top={0} right={0}>
          <WizardContext.Provider value={wizard}>
            <Header title={headerTitle} onQuit={onQuit} />
            <Flex
              ref={scrollerRef}
              flex={1}
              direction="column"
              bg="gray.100"
              overflow="auto"
              px={5}
              marginTop={16}
              {...props}
            >
              {children}
            </Flex>
            {!hasNoFooter && <Footer<WizardValues> labels={labels} />}
          </WizardContext.Provider>
        </Flex>
      </InternalWizardContextProvider>
    </Portal>
  );
};

export const Wizard = Object.assign(WizardComponent, {
  Step: WizardStep,
  Placeholder,
  PLACEMENT,
  FOOTER_HEIGHT,
});
