export enum PENALTY_TYPE {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export type PenaltyTypeFieldName = `penalty_${string}_type`;
export type PenaltyDenominationFieldName = `penalty_${string}_denomination`;
export type PenaltyAmountIncVatFieldName = `penalty_${string}_amountIncVAT`;

export type PenaltyForm = {
  [penaltyType: PenaltyTypeFieldName]: PENALTY_TYPE;
  [penaltyDenomination: PenaltyDenominationFieldName]: string;
  [penaltyAmountIncVat: PenaltyAmountIncVatFieldName]: number;
};

export const getPenaltyTypeFieldName = (fieldId: string): PenaltyTypeFieldName => `penalty_${fieldId}_type`;

export const getPenaltyDenominationFieldName = (fieldId: string): PenaltyDenominationFieldName =>
  `penalty_${fieldId}_denomination`;

export const getPenaltyAmountIncVatFieldName = (fieldId: string): PenaltyAmountIncVatFieldName =>
  `penalty_${fieldId}_amountIncVAT`;

export const getPenaltyFieldName = (fieldsIds: string[]) => {
  const array: (PenaltyTypeFieldName | PenaltyDenominationFieldName | PenaltyAmountIncVatFieldName)[] = [];

  fieldsIds.forEach((fieldId) => {
    array.push(getPenaltyTypeFieldName(fieldId));
    array.push(getPenaltyDenominationFieldName(fieldId));
    array.push(getPenaltyAmountIncVatFieldName(fieldId));
  });

  return array;
};

export const getPenaltyRowId = (
  fieldName: PenaltyTypeFieldName | PenaltyDenominationFieldName | PenaltyAmountIncVatFieldName,
): string => {
  const array = fieldName.split('_');

  if (array.length !== 3) {
    throw new Error('Invalid penalty field name');
  }

  return fieldName.split('_')[1];
};

export const getPenaltyInputType = (
  fieldName: PenaltyTypeFieldName | PenaltyDenominationFieldName | PenaltyAmountIncVatFieldName,
): string => {
  const array = fieldName.split('_');

  if (array.length !== 3) {
    throw new Error('Invalid penalty field name');
  }

  return fieldName.split('_')[2];
};
