import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICompanyMarginsResponseDTO, IQuoteLotSellsheetAmountDTO } from '@graneet/business-logic';
import { groupExistingComponentsByType } from '@graneet/business-logic';

import * as lotApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useEditLotAmountSellSheet = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (lotId: number, dto: IQuoteLotSellsheetAmountDTO, companyComponentTypes: ICompanyMarginsResponseDTO) => {
      if (!dto) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        return true;
      }

      const [err, result] = await lotApi.updateLotAmountSellSheet(lotId, dto);

      if (err) {
        toast.error(t('quote:lotEdition.lotSalesSheetDrawer.errors.amountUpdateFailed'));
        return true;
      }

      toast.success(t('quote:lotEdition.lotSalesSheetDrawer.successes.amountUpdated'));

      updateDataLocally(result);

      const { components } = result;

      return groupExistingComponentsByType(Object.values(components), companyComponentTypes);
    },
    [toast, t, updateDataLocally],
  );
};
