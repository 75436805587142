import type { Quote } from '@org/quotation-lib';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { QUOTE_ACTION_UNDO_REDO_MODE } from '@graneet/business-logic';
import { TRACKING_EVENT_ENUM } from '@graneet/business-logic';

import type { ICommand } from './types/ICommand';

import {
  quoteCommandHistoryHasBeenResetSetToStore,
  quoteCommandHistoryHasBeenResetGetFromStore,
} from 'features/quotation/quote-common/store/quoteCommandHistoryHasBeenResetStore';
import { quoteCommandHistoryPointerGetFromStore } from 'features/quotation/quote-common/store/quoteCommandHistoryPointerGetFromStore';
import { quoteCommandHistorySetToStore } from 'features/quotation/quote-common/store/quoteCommandHistorySetToStore';
import { quoteCommandHistoryGetFromStore } from 'features/quotation/quote-common/store/quoteCommandHistoryGetFromStore';
import { quoteCommandHistoryPointerSetToStore } from 'features/quotation/quote-common/store/quoteCommandHistoryPointerSetToStore';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';

const MAX_COMMAND_HISTORY = 20;

export const useCommands = () => {
  const toast = useToast();
  const segmentAnalytics = useSegmentAnalytics();
  const { t } = useTranslation(['quote']);

  const executeCommand = (command: ICommand, quote: Quote) => {
    command.execute(quote);
    const commandHistory = quoteCommandHistoryGetFromStore();
    const commandHistoryPointer = quoteCommandHistoryPointerGetFromStore();

    const newCommandHistoryPointer = commandHistoryPointer + 1;
    let newCommandHistory = [...commandHistory.slice(0, newCommandHistoryPointer), command];

    if (newCommandHistory.length > MAX_COMMAND_HISTORY) {
      newCommandHistory = newCommandHistory.slice(newCommandHistory.length - MAX_COMMAND_HISTORY);
    }

    if (quoteCommandHistoryHasBeenResetGetFromStore()) {
      quoteCommandHistoryHasBeenResetSetToStore(false);
    }
    quoteCommandHistorySetToStore(newCommandHistory);
    quoteCommandHistoryPointerSetToStore(newCommandHistoryPointer);
    return true;
  };

  /**
   * @param mode this for the tracking event
   */
  const undoCommand = (quote: Quote, mode: QUOTE_ACTION_UNDO_REDO_MODE) => {
    const commandHistory = quoteCommandHistoryGetFromStore();
    const commandHistoryPointer = quoteCommandHistoryPointerGetFromStore();

    if (quoteCommandHistoryHasBeenResetGetFromStore()) {
      toast.warning(t('quote:quotation.unavailableUndoRedo.description'));
    }

    if (commandHistoryPointer < 0) return;

    const lastCommand = commandHistory[commandHistoryPointer];
    if (!lastCommand) return;

    const newCommandHistoryPointer = commandHistoryPointer - 1;

    quoteCommandHistoryPointerSetToStore(newCommandHistoryPointer);
    lastCommand.undo(quote);

    segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.QUOTE_ACTION_UNDO_REDO, { type: 'undo', mode });
  };

  /**
   * @param type this for the tracking event
   */
  const redoCommand = (quote: Quote, mode: QUOTE_ACTION_UNDO_REDO_MODE) => {
    const commandHistory = quoteCommandHistoryGetFromStore();
    const commandHistoryPointer = quoteCommandHistoryPointerGetFromStore();

    if (quoteCommandHistoryHasBeenResetGetFromStore()) {
      toast.warning(t('quote:quotation.unavailableUndoRedo.description'));
    }

    if (commandHistoryPointer >= commandHistory.length - 1) return;

    const newCommandHistoryPointer = commandHistoryPointer + 1;
    const nextCommand = commandHistory[newCommandHistoryPointer];

    if (!nextCommand) return;

    quoteCommandHistoryPointerSetToStore(newCommandHistoryPointer);
    nextCommand.execute(quote);

    segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.QUOTE_ACTION_UNDO_REDO, { type: 'redo', mode });
  };

  const resetCommand = () => {
    quoteCommandHistoryHasBeenResetSetToStore(true);
    quoteCommandHistorySetToStore([]);
    quoteCommandHistoryPointerSetToStore(-1);
    return true;
  };

  return { executeCommand, undoCommand, redoCommand, resetCommand };
};
