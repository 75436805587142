import type { FC } from 'react';
import { useCallback } from 'react';
import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';

import { useIsEmailAvailable } from '../services/user.api';

interface IsUserEmailAvailableProps {
  userId?: number;
}

export const IsUserEmailAvailable: FC<IsUserEmailAvailableProps> = ({ userId }) => {
  const { t } = useTranslation(['user']);

  const isEmailAvailableMutation = useIsEmailAvailable();

  const testEmail = useCallback(
    async (value: FieldValue) => {
      if (!value || value.length === 0) {
        return true;
      }

      const response = await isEmailAvailableMutation.mutateAsync({ email: value, userId });

      return response.isEmailAvailable;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEmailAvailableMutation.mutateAsync, userId],
  );

  return <Rule message={t('user:userInformation.emailAlreadyUsed')} validationFn={testEmail} isDebounced />;
};
