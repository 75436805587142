import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import type { DropTargetMonitor } from 'react-dnd';

import type { DropEffect } from '../constants';

export interface SegmentProps<Item extends Record<any, unknown>> {
  name?: string;

  weight: number;

  effect: DropEffect;

  canDrop?(item: Item, targetMonitor: DropTargetMonitor<Item>): boolean;

  onDrop?(item: Item, targetMonitor: DropTargetMonitor<Item>): void;
}

export interface ISegmentContext<Item extends Record<any, unknown>> {
  addSegment(id: string, newSegment: SegmentProps<Item>): void;

  removeSegment(id: string): void;

  getSegment(): Array<SegmentProps<Item>>;
}

export const SegmentContext = createContext<ISegmentContext<Record<any, unknown>>>({
  addSegment() {},
  removeSegment() {},
  getSegment() {
    return [];
  },
});

export const useSegment = <Item extends Record<any, unknown>>(): ISegmentContext<Item> => {
  const segmentRef = useRef(new Map<string, SegmentProps<Item>>());

  const addSegment = useCallback((id: string, newSegment: SegmentProps<Item>) => {
    segmentRef.current.set(id, newSegment);
  }, []);

  const removeSegment = useCallback((id: string) => {
    segmentRef.current.delete(id);
  }, []);

  const getSegment = useCallback((): Array<SegmentProps<Item>> => [...segmentRef.current.values()], []);

  return useMemo<ISegmentContext<Item>>(
    () => ({
      addSegment,
      removeSegment,
      getSegment,
    }),
    [addSegment, getSegment, removeSegment],
  );
};

export const useSegmentContext = <Item extends Record<any, unknown>>(): ISegmentContext<Item> =>
  useContext(SegmentContext);
