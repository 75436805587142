import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { TableCellProps, TableColumnHeaderProps } from '@chakra-ui/react';
import {
  Box,
  CircularProgress,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  Text,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import type { ValueAnimationTransition } from 'framer-motion';
import { AnimatePresence, useAnimate, usePresence } from 'framer-motion';

import { SimpleSearchIcon } from '../../../Icons/v2/SimpleSearchIcon';
import { useDebounce } from '../../../../hooks/useDebounce';
import { Modal } from '../../../Modal';
import { QuotationInput } from '../../Input/Input';
import { quotationCardTranslations } from '../../Sellsheet/Cards/translations';
import { useCurrency } from '../../../Currency';
import { EllipsisText } from '../../../EllipsisText';

type Project = {
  id: number;
  name: string;
  refCode: string | null;
  clientName?: string;
  amountExVAT: number;
  address: {
    address: string | null;
    postalCode: string | null;
    city: string | null;
    country: string | null;
  };
};

type AssociateProjectModalProps = {
  title: string;
  placeholder: string;
  cta: {
    label: string;
    onClick: (project: Project) => void;
  };
  onSearch: (search: string) => Promise<Project[] | null>;
  isOpen: boolean;
  onClose: () => void;
};

const transition: ValueAnimationTransition = {
  type: 'spring',
  duration: 0.8,
};

const LoadingOverlay = () => {
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(scope.current, { opacity: 1 }, transition);
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(scope.current, { opacity: 0 }, transition);
        safeToRemove();
      };

      exitAnimation();
    }
  }, [animate, isPresent, safeToRemove, scope]);

  return (
    <Box
      ref={scope}
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      opacity={0}
      background="rgba(255, 255, 255, 0.80)"
      zIndex="100"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size="5rem" color="gray.600" isIndeterminate />
    </Box>
  );
};

const TableHeader: FC<TableColumnHeaderProps> = ({ children, ...props }) => (
  <Th color="#6C737F" textTransform="none" fontSize="0.813rem" {...props}>
    <EllipsisText>{children}</EllipsisText>
  </Th>
);

const TableCell: FC<TableCellProps> = ({ children, ...props }) => (
  <Td color="#1F2A37" fontSize="0.813rem" fontWeight={500} {...props}>
    <EllipsisText>{children}</EllipsisText>
  </Td>
);

export const AssociateProjectModal: FC<AssociateProjectModalProps> = ({
  title,
  cta,
  isOpen,
  placeholder,
  onSearch,
  onClose,
}) => {
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Project[] | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project>();

  const handleSearch = useDebounce(async (searchValue: string) => {
    const result = await onSearch(searchValue);
    if (result) {
      setSearchResults(result);
    }
    setLoading(false);
  }, 500);

  const handleClearSearch = useCallback(() => {
    setSearch('');
    setSelectedProject(undefined);
    handleSearch('');
  }, [handleSearch]);

  useEffect(() => {
    setLoading(true);
    handleSearch('');
  }, [handleSearch]);

  const handleSearchChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value;
      setSelectedProject(undefined);
      if (searchValue === '') {
        handleClearSearch();
      } else {
        setSearch(searchValue);
        setLoading(true);
        handleSearch(searchValue);
      }
    },
    [handleClearSearch, handleSearch],
  );

  const handleOnClose = useCallback(() => {
    handleClearSearch();
    onClose();
  }, [handleClearSearch, onClose]);

  const handleRowClick = useCallback(
    (project: Project) => () => {
      setSelectedProject(project);
    },
    [],
  );

  const handleCTAClick = useCallback(() => {
    if (selectedProject) {
      cta.onClick(selectedProject);
      handleOnClose();
    }
  }, [cta, selectedProject, handleOnClose]);

  const { formatAsAmount } = useCurrency();

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} title={title} size="5xl">
      <VStack spacing="0.75rem" alignItems="flex-start" width="100%">
        <Box width="50%">
          <QuotationInput
            onChange={handleSearchChange}
            placeholder={placeholder}
            rightIcon={<SimpleSearchIcon />}
            value={search}
          />
        </Box>
        <TableContainer
          background="white"
          borderRadius="0.375rem"
          border="1px solid"
          borderColor="gray.200"
          width="100%"
          maxHeight="400px"
          overflowY="auto"
          position="relative"
        >
          <AnimatePresence>{loading && <LoadingOverlay />}</AnimatePresence>
          <RadioGroup value={selectedProject?.id?.toString()}>
            <Table>
              <Thead>
                <Tr>
                  <TableHeader width="1rem" />
                  <TableHeader>{quotationCardTranslations.associateProjectModal.code}</TableHeader>
                  <TableHeader>{quotationCardTranslations.associateProjectModal.name}</TableHeader>
                  <TableHeader>{quotationCardTranslations.associateProjectModal.client}</TableHeader>
                  <TableHeader width="5rem">{quotationCardTranslations.associateProjectModal.amountExVAT}</TableHeader>
                </Tr>
              </Thead>
              <Tbody>
                {(searchResults !== null ? searchResults : [])?.map((project) => (
                  <Tr onClick={handleRowClick(project)} cursor="pointer" key={project.id}>
                    <TableCell>
                      <Radio value={project.id.toString()} />
                    </TableCell>
                    <TableCell>{project.refCode}</TableCell>
                    <TableCell>{project.name}</TableCell>
                    <TableCell>{project.clientName}</TableCell>
                    <TableCell>{formatAsAmount(project.amountExVAT)}</TableCell>
                  </Tr>
                ))}
                {searchResults?.length === 0 && (
                  <Tr>
                    <Td colSpan={5}>
                      <HStack width="100%" alignItems="center" height="2.375rem" justifyContent="center">
                        <SimpleSearchIcon color="#1F2A37" />
                        <Text color="#1F2A37" fontSize="0.813rem" fontWeight={500}>
                          {quotationCardTranslations.global.noResults}
                        </Text>
                      </HStack>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </RadioGroup>
        </TableContainer>
      </VStack>
      <Modal.Close onClick={handleOnClose} />
      <Modal.PrimaryButton onClick={handleCTAClick} isDisabled={!selectedProject}>
        {cta.label}
      </Modal.PrimaryButton>
    </Modal>
  );
};
