import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const HistoryIcon: FC<IconProps> = ({ color = 'gray.700', ...rest }) => (
  <Icon viewBox="0 0 16 16" color={color} {...rest}>
    <path
      d="M11.7668 4.22669C10.8001 3.26002 9.46681 2.66002 7.99348 2.66002C5.04681 2.66002 2.66014 5.04669 2.66014 7.99335C2.66014 10.9334 5.04681 13.3267 7.99348 13.3267C10.4201 13.3267 12.4735 11.7 13.1135 9.47335C13.2135 9.11335 13.5801 8.91335 13.9335 9.01335C14.2868 9.11335 14.4868 9.48002 14.3868 9.83335C13.5801 12.6134 11.0201 14.6467 7.98014 14.6467C4.29348 14.6467 1.31348 11.66 1.31348 7.98002C1.31348 4.29335 4.29348 1.31335 7.98014 1.31335C9.82014 1.31335 11.4868 2.06002 12.6935 3.26669C13.1135 3.68669 13.5735 4.21335 13.9735 4.70669V2.64002C13.9735 2.26669 14.2668 1.97335 14.6401 1.97335C15.0068 1.97335 15.3068 2.26669 15.3068 2.64002V6.64002C15.3068 7.00669 15.0068 7.30669 14.6401 7.30669H10.6401C10.2668 7.30669 9.97348 7.00669 9.97348 6.64002C9.97348 6.26669 10.2668 5.97335 10.6401 5.97335H13.2801C12.8201 5.40002 12.2468 4.70669 11.7335 4.20002L11.7668 4.22669Z"
      fill="currentColor"
    />
  </Icon>
);
