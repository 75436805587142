export enum TIME_SLOT_FIELDS {
  WORKER_IDS = 'workerIds',
  PROJECT_ID = 'projectId',
  DATE = 'date',
  HOURLY_PRICE_EX_VAT = 'hourlyPriceExVAT',
  NOTE = 'note',
  WORKFORCE_COMPONENT_TYPE_ID = 'workforceComponentTypeId',
  MEAL_EXPENSE = 'mealExpense',
  KM_EXPENSE = 'kmExpense',
  ABSENCE_TYPE = 'absenceType',
}
