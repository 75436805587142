import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatPercentage,
  Card,
  LabeledData,
  TwoColumns,
  Button,
  SimpleAddIcon,
  SimpleEditIcon,
} from '@graneet/lib-ui';
import type { ISubProject } from '@graneet/business-logic';
import {
  FEATURE_FLAGS,
  isSubProjectBillableAsProgressStatement,
  isSubProjectInDirectBilling,
  PERMISSION,
} from '@graneet/business-logic';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { FeatureFlag } from 'features/feature-flag/components/FeatureFlag';

interface SubProjectSettingsCardProps {
  subProject: ISubProject;

  onEditSubProjectParams(): void;
}
export const SubProjectSettingsCard: FC<SubProjectSettingsCardProps> = ({ subProject, onEditSubProjectParams }) => {
  const { t } = useTranslation(['project', 'global']);
  const createProjectsButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_PROJECTS]);

  const isBillable = isSubProjectBillableAsProgressStatement(subProject, {
    ignoreReversalOfLiability: true,
  });

  if (isSubProjectInDirectBilling(subProject)) {
    return <Card title={t('project:cards.settings')}>{t('project:settingsCard.directBilling')}</Card>;
  }

  if (isBillable) {
    return (
      <FeatureFlag feature={FEATURE_FLAGS.PROJECT_GLOBAL_PARAMETERS}>
        <FeatureFlag.OnActive>
          <Card
            title={t('project:cards.settings')}
            topRightContent={
              <Button
                variant="outline"
                onClick={onEditSubProjectParams}
                leftIcon={<SimpleEditIcon />}
                {...createProjectsButtonDisabledProps()}
              >
                {t('global:words.c.update')}
              </Button>
            }
          >
            <TwoColumns>
              {subProject.type && (
                <LabeledData label={t('project:fields.type')} data={t(`project:types.${subProject.type}`)} />
              )}
              <LabeledData
                label={t('project:fields.priceRevision')}
                data={subProject?.hasPriceRevision ? t('global:words.c.yes') : t('global:words.c.no')}
              />
            </TwoColumns>
            <TwoColumns mt={4}>
              {(subProject.deductions || []).map(({ id, name, percentage }) => (
                <LabeledData key={id} label={name} data={formatPercentage(percentage)} />
              ))}
            </TwoColumns>
          </Card>
        </FeatureFlag.OnActive>
        <FeatureFlag.OnFallback>
          <Card title={t('project:cards.settings')}>
            <TwoColumns>
              {subProject.type && (
                <LabeledData label={t('project:fields.type')} data={t(`project:types.${subProject.type}`)} />
              )}
              <LabeledData
                label={t('project:fields.hasReversalOfLiability')}
                data={subProject.hasReversalOfLiability ? t('global:words.c.yes') : t('global:words.c.no')}
              />
            </TwoColumns>
            <TwoColumns mt={4}>
              <LabeledData
                label={t('project:fields.priceRevision')}
                data={subProject?.hasPriceRevision ? t('global:words.c.yes') : t('global:words.c.no')}
              />
              {(subProject.deductions || []).map(({ id, name, percentage }) => (
                <LabeledData key={id} label={name} data={formatPercentage(percentage)} />
              ))}
            </TwoColumns>
          </Card>
        </FeatureFlag.OnFallback>
      </FeatureFlag>
    );
  }

  return (
    <Card title={t('project:cards.settings')}>
      <Button
        h="5rem"
        leftIcon={<SimpleAddIcon />}
        variant="outline"
        onClick={onEditSubProjectParams}
        {...createProjectsButtonDisabledProps()}
      >
        {t('project:settingsCard.cta')}
      </Button>
    </Card>
  );
};
