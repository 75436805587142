export const MAXIMUM_FRACTIONAL_PART_LENGTH = 10;

// Method using IEEE 754 standard for rounding
// https://en.wikipedia.org/wiki/Rounding#Round_half_away_from_zero
export const roundFloating = (x: number): number => Math.sign(x) * Math.round(Math.abs(x));

// Method to handle issues with floating calculations
export const multiplyFloating = (x: number, y: number): number => {
  function fl(z: number): number {
    // Length of fractional part
    const parts = z.toString().split('.');
    return parts.length < 2 ? 0 : parts[1].length;
  }
  const f = 10 ** (fl(x) + fl(y));
  return roundFloating(x * y * f) / f;
};

export const divideFloating = (x: number, y: number, fractionalPartLength = MAXIMUM_FRACTIONAL_PART_LENGTH): number => {
  function fl(z: number): number {
    // Length of fractional part
    const parts = z.toString().split('.');
    return parts.length < 2 ? 0 : parts[1].length;
  }

  const maxFractionalLength = Math.max(fl(x), fl(y), fractionalPartLength);
  return parseFloat((x / y).toFixed(maxFractionalLength));
};

export const ceilFloating = (x: number, fractionalPartLength = MAXIMUM_FRACTIONAL_PART_LENGTH): number => {
  const factor = 10 ** fractionalPartLength;
  return divideFloating(Math.ceil(multiplyFloating(x, factor)), factor);
};

export const floorFloating = (x: number, fractionalPartLength = MAXIMUM_FRACTIONAL_PART_LENGTH): number => {
  const factor = 10 ** fractionalPartLength;
  return divideFloating(Math.floor(multiplyFloating(x, factor)), factor);
};

export const roundFloatingTo = (x: number, fractionalPartLength = MAXIMUM_FRACTIONAL_PART_LENGTH): number => {
  const factor = 10 ** fractionalPartLength;
  return divideFloating(Math.round(multiplyFloating(x, factor)), factor);
};
