import type { KeysMatching } from '@graneet/lib-ui';
import { SingleSelectField } from '@graneet/lib-ui';
import { useCallback, useMemo } from 'react';
import type { IAddress, IProject, ISubProject, RequiredByKeys } from '@graneet/business-logic';
import type { FlexProps } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { FieldValues } from 'graneet-form';
import { useFormContext, HiddenField } from 'graneet-form';

import { ProjectFieldOption } from './ProjectFieldOption';

import { Rule } from 'features/form/rules/Rule';

type ProjectValue = number | undefined;

export type OrderProjectField = {
  projectAddress: IAddress;
};

export interface ProjectFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, ProjectValue> = KeysMatching<T, ProjectValue>,
> extends FlexProps {
  /**
   * The field name
   */
  name: K;
  isRequired?: boolean;
  isDisabled?: boolean;
  projects: RequiredByKeys<IProject, 'address'>[];
  selectedSubProject?: ISubProject;
  onProjectSelected?(projectId: number | undefined): void;
  loading?: boolean;
}

export const ProjectField = <
  T extends OrderProjectField,
  K extends KeysMatching<T, ProjectValue> = KeysMatching<T, ProjectValue>,
>({
  loading,
  name,
  isRequired = false,
  isDisabled = false,
  projects,
  selectedSubProject, // only used to display the subproject's name
  onProjectSelected,
  ...otherProps
}: ProjectFieldProps<T, K>) => {
  const { t } = useTranslation(['project', 'global']);

  const form = useFormContext<T>();
  const onChange = useCallback(
    (props: { value: ProjectValue } | null) => {
      if (props?.value) {
        const selectedProject = projects.find((project) => project.id === props.value);
        form.setFormValues({ projectAddress: selectedProject?.address } as T);
      }
      onProjectSelected?.(props?.value);
    },
    [form, onProjectSelected, projects],
  );

  const options = useMemo(
    () =>
      projects.map((project) => ({
        label: <ProjectFieldOption project={project} subProject={selectedSubProject} />,
        value: project.id,
        searchTerm: `${project.name} ${project.refCode} ${project.primaryClient?.enterpriseName ?? ''}`,
      })),
    [projects, selectedSubProject],
  );

  if (projects.length === 0) {
    return (
      <>
        <HiddenField<T, K> name={name}>{isRequired && <Rule.IsRequired />}</HiddenField>
        <Text color="gray.500">{loading ? t('global:loading') : t('project:projectField.noProjectExists')}</Text>
      </>
    );
  }

  return (
    <Flex {...otherProps}>
      {/* Used to pass project address information to form */}
      <HiddenField<T> name="projectAddress" />

      <SingleSelectField<T>
        name={name}
        isRequired={isRequired}
        placeholder={t('project:projectField.placeholder')}
        options={options as any}
        onChange={onChange as any}
        isDisabled={isDisabled}
      >
        {isRequired && <Rule.IsRequired />}
      </SingleSelectField>
    </Flex>
  );
};
