import type {
  IBill,
  IMailingSendWithSenderAndRecipientDTO,
  IReminder,
  IReminderBulkSendDTO,
  IReminderCreationDTO,
  IReminderUpdateDTO,
  IReminderUpdateDefaultSenderDTO,
  IReminderWithRelations,
} from '@graneet/business-logic';
import type { FileWithOptions } from '@graneet/lib-ui';
import { useToast } from '@graneet/lib-ui';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { invalidAllBillsQueries } from 'features/bill/services/bill.api';
import { userKeyFactory } from 'features/user/services/user.api';
import { apiNew } from 'features/api/services/apiNew.service';
import { dtoToFormData } from 'features/api/services/api.util';

const REMINDER_PATH = '/reminders';

const reminderKeyFactory = createQueryKeys('reminders', {
  get: () => ({
    queryKey: [REMINDER_PATH],
    queryFn: () => apiNew.get<never, IReminderWithRelations[]>(REMINDER_PATH),
  }),
});

export function useReminders() {
  return useSuspenseQuery(reminderKeyFactory.get());
}

export function useCreateReminder() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IReminderCreationDTO) => apiNew.post<IReminderCreationDTO, IReminder>(REMINDER_PATH, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(reminderKeyFactory.get());
      toast.success(t('global:reminders.toasts.create', { reminderName: response.name }));
    },
  });
}

export function useUpdateReminder() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: string; dto: IReminderUpdateDTO }) =>
      apiNew.patch<IReminderUpdateDTO, IReminder>(`${REMINDER_PATH}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(reminderKeyFactory.get());
      toast.success(t('global:reminders.toasts.update', { reminderName: response.name }));
    },
  });
}

export function useDeleteReminder() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (reminder: IReminder) => apiNew.delete<never, void>(`${REMINDER_PATH}/${reminder.id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, reminder) => {
      await queryClient.invalidateQueries(reminderKeyFactory.get());
      toast.success(t('global:reminders.toasts.delete', { reminderName: reminder.name }));
    },
  });
}

export function useSendReminder() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: {
      dto: IMailingSendWithSenderAndRecipientDTO;
      files: FileWithOptions[];
      billId: IBill['id'];
    }) => {
      const formData = dtoToFormData(params.dto);
      params.files.slice(1).forEach((attachment: FileWithOptions) => {
        formData.append('files', attachment.file as File, attachment.file.name);
      });
      return apiNew.postWithMultiPart<FormData, void>(`${REMINDER_PATH}/send`, formData, {
        params: { billId: params.billId },
      });
    },
    onSuccess: async () => {
      await invalidAllBillsQueries(queryClient);
    },
  });
}

export function useBulkRemindBills() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IReminderBulkSendDTO) =>
      apiNew.post<IReminderBulkSendDTO, void>(`${REMINDER_PATH}/send-bulk`, dto),
    onSuccess: async () => {
      await invalidAllBillsQueries(queryClient);
    },
  });
}

export function useUpdateDefaultSender() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IReminderUpdateDefaultSenderDTO) =>
      apiNew.patch<IReminderUpdateDefaultSenderDTO, void>(`${REMINDER_PATH}/update-default-sender`, dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:reminders.settingsCard.senderUpdateError'));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
      toast.success(t('global:words.c.success'), t('global:reminders.settingsCard.senderUpdateSuccess'));
    },
  });
}
