import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

export const DeleteInCircleIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="#F33725"
      d="M8 13.76A5.768 5.768 0 0 1 2.24 8 5.768 5.768 0 0 1 8 2.24 5.768 5.768 0 0 1 13.76 8 5.768 5.768 0 0 1 8 13.76ZM8 .8A7.193 7.193 0 0 0 .8 8c0 3.982 3.22 7.2 7.2 7.2 3.982 0 7.2-3.218 7.2-7.2C15.2 4.02 11.983.8 8 .8Zm1.866 4.32L8 6.987 6.136 5.12 5.121 6.136l1.865 1.865L5.12 9.866l1.015 1.015 1.865-1.865 1.865 1.865 1.015-1.015L9.016 8l1.865-1.865-1.015-1.015Z"
    />
  </Icon>
);
