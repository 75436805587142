import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import type { BoxProps, TooltipProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

import { Tooltip } from '../Tooltip';

export interface EllipsisTextProps extends BoxProps {
  children?: ReactNode;
  tooltipProps?: Omit<TooltipProps, 'children'>;
}

export const EllipsisText: FC<EllipsisTextProps> = ({ children, tooltipProps, ...props }) => {
  const [hasOverflowingChildren, setHasOverflowingChildren] = useState(false);

  const handleRef = useCallback(
    (el: HTMLElement | null) => {
      setHasOverflowingChildren(el ? el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth : false);
    },
    [setHasOverflowingChildren],
  );

  return (
    <Tooltip {...tooltipProps} label={children} isDisabled={!hasOverflowingChildren}>
      <Box ref={handleRef} {...props} textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
        {children}
      </Box>
    </Tooltip>
  );
};
