import type { FC } from 'react';
import { Fragment, useCallback, useState } from 'react';
import type { DateRangeValue } from '@graneet/lib-ui';
import { DateRangeField, ModalSubtitle, RequiredText, Button, SimpleDeleteIcon, SimpleAddIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { HStack, VStack } from '@chakra-ui/react';
import { uniqueId } from 'lodash-es';

import { TIME_SLOT_FIELDS } from 'features/time-tracking/constants/time-slot.constant';
import { Rule } from 'features/form/rules/Rule';

export const PREFIX_SLOT = `${TIME_SLOT_FIELDS.DATE}.`;

interface TimeSlotFieldProps {
  isOff: boolean;
  firstId: string;
}

export const TimeSlotField: FC<TimeSlotFieldProps> = ({ isOff, firstId }) => {
  const { t } = useTranslation(['timeTracking']);
  const [timeSlots, setTimeSlots] = useState(new Set([firstId]));

  const addTimeSlots = useCallback(() => {
    const id = uniqueId(PREFIX_SLOT);
    setTimeSlots((prev) => new Set(prev).add(id));
  }, [setTimeSlots]);

  const removeTimeSlots = useCallback(
    (id: string) => () => {
      setTimeSlots((prev) => {
        const next = new Set(prev);
        next.delete(id);
        return next;
      });
    },
    [setTimeSlots],
  );

  return (
    <>
      <RequiredText>
        <ModalSubtitle>
          {t(`timeTracking:createTimeSlotModal.fields.${!isOff ? 'workingDates' : 'offDates'}`)}
        </ModalSubtitle>
      </RequiredText>

      <VStack align="flex-start">
        {[...timeSlots].map((key, index) => (
          <Fragment key={key}>
            <HStack spacing={2}>
              <DateRangeField<Record<string, DateRangeValue>>
                mb={0}
                name={key}
                label={t('timeTracking:createTimeSlotModal.fields.dateLabel')}
              >
                <Rule.IsValidDateRange />
              </DateRangeField>
              {index !== 0 && (
                <Button variant="outline" onClick={removeTimeSlots(key)}>
                  <SimpleDeleteIcon />
                </Button>
              )}
              {index === [...timeSlots].length - 1 && (
                <Button variant="outline" colorScheme="gray" onClick={addTimeSlots}>
                  <SimpleAddIcon />
                </Button>
              )}
            </HStack>
          </Fragment>
        ))}
      </VStack>
    </>
  );
};
