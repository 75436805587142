import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { IconButtonProps } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/react';

import { SimpleChevronBottomIcon } from '../Icons';

export interface CollapseButtonProps extends Omit<IconButtonProps, 'onChange' | 'onClick' | 'aria-label'> {
  onChange(nextValue: boolean): void;
}

export const CollapseButton: FC<CollapseButtonProps> = ({ onChange, ...props }) => {
  const [collapsed, setCollapsed] = useState(true);

  const onClick = () => {
    setCollapsed((wasCollapsed) => !wasCollapsed);
  };

  useEffect(() => {
    onChange(collapsed);
  }, [collapsed, onChange]);

  return (
    <IconButton
      m={1}
      bg="none"
      fontSize={25}
      icon={
        collapsed ? (
          <SimpleChevronBottomIcon boxSize={4} />
        ) : (
          <SimpleChevronBottomIcon transform="rotate(-180deg)" boxSize={4} />
        )
      }
      color="gray.500"
      {...props}
      onClick={onClick}
      aria-label={collapsed ? 'Open' : 'Close'}
    />
  );
};
