import type { ReactElement } from 'react';

import { SimpleSettingsIcon } from '../../Icons';
import { configureDefaultLabels, vatTranslations } from '../VATDistributionTranslations';
import { formatPercentage, multiplyFloating, sortVATDistribution } from '../../../utils';
import { ActionMenu } from '../../ActionMenu/ActionMenu';

import { Item } from './Item';

export interface VATDistributionProps<VatDistribution extends { amount: number; vatRate: number; label?: string }> {
  vatType?: 'NORMAL' | 'REVERSE_CHARGE' | 'INTRA_COMMUNITY';
  vatDistribution?: VatDistribution[] | null;
  dropdownItems?: { label: string; icon?: ReactElement; onClick?(): void }[];
  left?: boolean;
  displayNewVersion?: boolean;
  withCustomDiscountsLabel?: boolean;
}

export const VATDistribution = <VatDistribution extends { amount: number; vatRate: number; label?: string }>({
  vatType = 'NORMAL',
  vatDistribution,
  dropdownItems,
  left = false,
  displayNewVersion = false,
  withCustomDiscountsLabel = false,
}: VATDistributionProps<VatDistribution>) => {
  const distribution = sortVATDistribution(vatDistribution || []);
  const rightContent =
    dropdownItems && dropdownItems.length > 0 ? (
      <ActionMenu placement="top-start">
        {dropdownItems.map((item) => (
          <ActionMenu.Action
            key={`item_${item.label}`}
            label={item.label}
            icon={item.icon || <SimpleSettingsIcon />}
            onClick={item.onClick}
          />
        ))}
      </ActionMenu>
    ) : null;

  return (
    <>
      {vatType === 'REVERSE_CHARGE' && (
        <Item
          light
          sign
          left={left}
          label={vatTranslations.vatWithReverseLiability}
          amount={0}
          rightContent={rightContent}
        />
      )}

      {vatType === 'INTRA_COMMUNITY' && (
        <Item
          light
          sign
          left={left}
          label={vatTranslations.vatWithIntraCommunity}
          amount={0}
          rightContent={rightContent}
        />
      )}

      {(vatType === 'NORMAL' || displayNewVersion) &&
        distribution.length > 0 &&
        distribution.map(({ amount, vatRate }, index) => {
          const vatRateFormatted = formatPercentage(multiplyFloating(vatRate, 100));
          return (
            <Item
              key={`vat_${vatRate}`}
              light
              sign
              left={left}
              label={
                withCustomDiscountsLabel
                  ? vatTranslations.customDiscountVat(vatRateFormatted)
                  : vatTranslations.vat(vatRateFormatted)
              }
              amount={amount}
              rightContent={index === 0 && rightContent}
            />
          );
        })}

      {vatType === 'NORMAL' && distribution.length === 0 && (
        <Item light sign left={left} label={vatTranslations.globalVat} amount={0} rightContent={rightContent} />
      )}
    </>
  );
};

VATDistribution.configureDefaultLabels = configureDefaultLabels;
