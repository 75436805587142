import { Card, LabeledData, Date, Price, TwoColumns } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICredit } from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';
import type { FC } from 'react';

import { InvoiceNumberLabeledData } from 'features/statement-ledger/components/InvoiceNumberLabeledData';

interface CreditInformationCardProps {
  credit: ICredit;
}

export const CreditInformationCard: FC<CreditInformationCardProps> = ({ credit }) => {
  const { t } = useTranslation(['credit', 'invoice']);

  return (
    <Card title={t('credit:titles.information')}>
      <TwoColumns>
        <LabeledData label={t('invoice:fields.billingDate')} data={<Date>{credit.creditDate}</Date>} />
        <LabeledData label={t('credit:fields.completionDate')} data={<Date>{credit.completionDate}</Date>} />
        <LabeledData label={t('credit:fields.amountExVAT')} data={<Price amount={credit.amountExVAT} />} />
        <InvoiceNumberLabeledData statement={credit} statementType={STATEMENT_TYPES.CREDIT} />
      </TwoColumns>
    </Card>
  );
};
