import { SegmentedInputGroupField, SimpleHelpIcon, Tooltip } from '@graneet/lib-ui';
import { Box, Flex, Image, VStack, Text, Center } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useMemo } from 'react';
import { PDF_COMPANY_LOT_INFORMATION, PDF_TEMPLATES_TYPES } from '@graneet/business-logic';

import type { PDFSettingsFormValues } from '../constants/pdf-settings.constant';

interface PdfLotAmountsSettingsProps {
  pdfTemplateType: PDF_TEMPLATES_TYPES;
}

export const PdfLotAmountsSettings: FC<PdfLotAmountsSettingsProps> = ({ pdfTemplateType }) => {
  const { t } = useTranslation(['pdfSettings']);

  const options = useMemo(() => {
    if (pdfTemplateType === PDF_TEMPLATES_TYPES.QUOTE) {
      return [
        {
          optionLabel: t('pdfSettings:settings.lotInformation.options.hide'),
          optionValue: PDF_COMPANY_LOT_INFORMATION.HIDE,
        },
        {
          optionLabel: t('pdfSettings:settings.lotInformation.options.fullQuote'),
          optionValue: PDF_COMPANY_LOT_INFORMATION.FULL,
        },
      ];
    }

    return [
      {
        optionLabel: t('pdfSettings:settings.lotInformation.options.hide'),
        optionValue: PDF_COMPANY_LOT_INFORMATION.HIDE,
      },
      {
        optionLabel: t('pdfSettings:settings.lotInformation.options.onlyAmount'),
        optionValue: PDF_COMPANY_LOT_INFORMATION.ONLY_AMOUNT,
      },
      {
        optionLabel: t('pdfSettings:settings.lotInformation.options.full'),
        optionValue: PDF_COMPANY_LOT_INFORMATION.FULL,
      },
    ];
  }, [pdfTemplateType, t]);

  return (
    <VStack spacing={2} align="start">
      <Flex color="black" fontSize="lg" gap={2}>
        <Text>{t('pdfSettings:settings.lotInformation.title')}</Text>
        <Tooltip
          label={
            <>
              <Trans
                t={t}
                i18nKey={`pdfSettings:settings.lotInformation.${pdfTemplateType === PDF_TEMPLATES_TYPES.QUOTE ? 'tooltipQuote' : 'tooltipStatement'}`}
              />
              <Center>
                <Image
                  mt={4}
                  src={t(
                    `pdfSettings:settings.lotInformation.${pdfTemplateType === PDF_TEMPLATES_TYPES.QUOTE ? 'tooltipImageUrlQuote' : 'tooltipImageUrl'}`,
                  )}
                />
              </Center>
            </>
          }
          placement="right-end"
        >
          <Box>
            <SimpleHelpIcon boxSize={5} />
          </Box>
        </Tooltip>
      </Flex>

      <SegmentedInputGroupField<PDFSettingsFormValues> name="lotInformation" options={options} />
    </VStack>
  );
};
