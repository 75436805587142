import type { FC, ReactNode } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { Button, SimpleRepeatIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IPaymentMethodResponseDTO } from '@graneet/business-logic';

import { PaymentMethodSelectModal } from './modals/PaymentMethodSelectModal';
import { PaymentMethodEditionCard } from './PaymentMethodEditionCard';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface PaymentMethodEditionAndSelectionCardProps {
  paymentMethod: IPaymentMethodResponseDTO;

  paymentMethods: IPaymentMethodResponseDTO[];

  onPaymentMethodUpdated?: (paymentMethod: IPaymentMethodResponseDTO) => Promise<void> | void;

  selectNewPaymentMethodLabel?: ReactNode;

  onNewPaymentMethodSelected: (newPaymentMethod: IPaymentMethodResponseDTO) => void | Promise<void>;

  selectPaymentMethodModalDescription?: string;

  hasUpdatePermission?: boolean;
  displayUpdateButton?: boolean;
}

export const PaymentMethodEditionAndSelectionCard: FC<PaymentMethodEditionAndSelectionCardProps> = ({
  paymentMethod,
  paymentMethods,
  onPaymentMethodUpdated,
  selectNewPaymentMethodLabel,
  onNewPaymentMethodSelected,
  selectPaymentMethodModalDescription,
  hasUpdatePermission = true,
  displayUpdateButton = true,
}) => {
  const { t } = useTranslation(['paymentMethods']);
  const paymentMethodModal = useDisclosure();
  const updateButtonDisabledProps = useDisabledButtonProps(undefined, hasUpdatePermission);

  if (!paymentMethods) {
    return null;
  }

  return (
    <Box>
      <PaymentMethodEditionCard
        paymentMethod={paymentMethod}
        hasUpdatePermission={hasUpdatePermission}
        onPaymentMethodUpdated={onPaymentMethodUpdated}
        displayUpdateButton={displayUpdateButton}
      />

      {selectNewPaymentMethodLabel && (
        <Button
          variant="outline"
          mt={5}
          onClick={paymentMethodModal.onOpen}
          leftIcon={<SimpleRepeatIcon />}
          {...updateButtonDisabledProps()}
        >
          {selectNewPaymentMethodLabel}
        </Button>
      )}

      <PaymentMethodSelectModal
        onClose={paymentMethodModal.onClose}
        isOpen={paymentMethodModal.isOpen}
        paymentMethods={paymentMethods}
        selectedPaymentMethod={paymentMethod}
        title={t('paymentMethods:modal.select.title')}
        description={selectPaymentMethodModalDescription}
        onSelected={onNewPaymentMethodSelected}
      />
    </Box>
  );
};
