import { useCallback, useState } from 'react';
import {
  Table,
  ActionMenu,
  TextField,
  NumberField,
  CurrencyField,
  PriceAdvanced,
  useCurrency,
  FormulaField,
} from '@graneet/lib-ui';
import { object, bool } from 'prop-types';
import { isQuoteComponentCompleted } from '@graneet/business-logic';

import { useDuplicateComponent } from '../hooks/useDuplicateComponent';
import { useDeleteComponent } from '../hooks/useDeleteComponent';

import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { useStore } from 'store/store';
import { COMPONENT_FIELDS } from 'features/quote-component/services/quote-component.util';
import { QUOTE_INPUT_STYLE } from 'features/quote/constants/style.constant';
import { BUILD_COMPONENT_FORM_FIELD } from 'features/quote-job/forms/quote-job-edit-modal.form';
import { useSendClickRoundedQuantityButtonEvent } from 'features/analytic/hooks/useSendClickRoundedQuantityButtonEvent';

const getStyleForInput = (hasFocus) => (hasFocus ? QUOTE_INPUT_STYLE.FOCUS : QUOTE_INPUT_STYLE.BLURRED);

export const QuoteComponentEditionRow = ({ component, isLastRow, isReadOnly, isHiddenCost }) => {
  const { mapAmountToNumber } = useCurrency();

  const deleteComponent = useDeleteComponent();
  const duplicateComponent = useDuplicateComponent();

  const isCompleted = isQuoteComponentCompleted(component);

  const [hasFocus, setHasFocus] = useState(false);
  const inputProps = getStyleForInput(hasFocus);

  const { componentType, id: componentId } = component;
  const componentTypeUnit = componentType?.unit;

  const hasHiddenCosts = useStore((state) => state.hasHiddenCosts);

  const onDelete = useCallback(async () => {
    await deleteComponent(componentId);
  }, [componentId, deleteComponent]);

  const onDuplicate = useCallback(async () => {
    await duplicateComponent(componentId);
  }, [componentId, duplicateComponent]);

  const handleOnFieldFocus = useCallback(() => setHasFocus(true), []);
  const handleOnFieldBlur = useCallback(() => setHasFocus(false), []);

  const componentFieldData = useCallback((key) => ({ key, componentId }), [componentId]);

  const priceData = useCallback(
    (componentFields) => ({
      key: componentFields,
      componentId,
      mapValue: (v) => (Number.isFinite(v) ? mapAmountToNumber(v) : null),
    }),
    [componentId, mapAmountToNumber],
  );

  const sendClickRoundEvent = useSendClickRoundedQuantityButtonEvent();

  return (
    <Table.Row backgroundColor={isCompleted ? 'white' : 'yellow.100'} border={isLastRow && 0}>
      <Table.Cell center>
        <ComponentTypeField
          name={BUILD_COMPONENT_FORM_FIELD(componentId, COMPONENT_FIELDS.COMPONENT_TYPE_ID)}
          data={componentFieldData(COMPONENT_FIELDS.COMPONENT_TYPE_ID)}
          {...inputProps}
          onBlur={handleOnFieldBlur}
          onFocus={handleOnFieldFocus}
          isDisabled={isReadOnly}
          workforce
          material
        />
      </Table.Cell>

      <Table.Cell>
        <TextField
          name={BUILD_COMPONENT_FORM_FIELD(componentId, COMPONENT_FIELDS.CODE)}
          data={componentFieldData(COMPONENT_FIELDS.CODE)}
          inputProps={{ ...inputProps, ...QUOTE_INPUT_STYLE.CENTER }}
          onBlur={handleOnFieldBlur}
          onFocus={handleOnFieldFocus}
          noBorder
          isReadOnly={isReadOnly}
        />
      </Table.Cell>
      <Table.Cell>
        <TextField
          name={BUILD_COMPONENT_FORM_FIELD(componentId, COMPONENT_FIELDS.NAME)}
          data={componentFieldData(COMPONENT_FIELDS.NAME)}
          inputProps={inputProps}
          noBorder
          onBlur={handleOnFieldBlur}
          onFocus={handleOnFieldFocus}
          isReadOnly={isReadOnly}
        />
      </Table.Cell>
      <Table.Cell>
        <TextField
          name={BUILD_COMPONENT_FORM_FIELD(componentId, COMPONENT_FIELDS.UNIT)}
          data={componentFieldData(COMPONENT_FIELDS.UNIT)}
          inputProps={{ ...inputProps, ...QUOTE_INPUT_STYLE.CENTER }}
          noBorder
          isDisabled={!!componentTypeUnit || isReadOnly}
        />
      </Table.Cell>
      <Table.Cell>
        <FormulaField
          name={BUILD_COMPONENT_FORM_FIELD(componentId, COMPONENT_FIELDS.QUANTITY)}
          data={componentFieldData(COMPONENT_FIELDS.QUANTITY)}
          inputProps={{ ...inputProps, ...QUOTE_INPUT_STYLE.CENTER }}
          onBlur={handleOnFieldBlur}
          onFocus={handleOnFieldFocus}
          isReadOnly={isReadOnly}
          noBorder
          sendClickRoundEvent={sendClickRoundEvent}
        />
      </Table.Cell>
      <Table.Cell>
        <CurrencyField
          name={BUILD_COMPONENT_FORM_FIELD(componentId, COMPONENT_FIELDS.UNIT_DISBURSEMENT_EX_VAT)}
          data={priceData(COMPONENT_FIELDS.UNIT_DISBURSEMENT_EX_VAT)}
          inputProps={{ ...inputProps, ...QUOTE_INPUT_STYLE.RIGHT }}
          noBorder
          onBlur={handleOnFieldBlur}
          onFocus={handleOnFieldFocus}
          isReadOnly={isReadOnly}
        />
      </Table.Cell>
      <Table.Cell right>
        <PriceAdvanced amount={component.disbursementExVAT} fontSize="md" />
      </Table.Cell>
      {!isHiddenCost ? (
        <Table.Cell>
          <NumberField
            name={BUILD_COMPONENT_FORM_FIELD(componentId, COMPONENT_FIELDS.TOTAL_MARGIN)}
            data={componentFieldData(COMPONENT_FIELDS.TOTAL_MARGIN)}
            inputProps={{ ...inputProps, ...QUOTE_INPUT_STYLE.CENTER }}
            scale={3}
            noBorder
            onBlur={handleOnFieldBlur}
            onFocus={handleOnFieldFocus}
            isReadOnly={isReadOnly}
          />
        </Table.Cell>
      ) : null}
      {!isHiddenCost ? (
        <Table.Cell right>
          {hasHiddenCosts ? '-' : <PriceAdvanced amount={component.amountExVAT} fontSize="md" />}
        </Table.Cell>
      ) : null}

      <Table.Cell>
        {!isReadOnly && (
          <ActionMenu>
            <ActionMenu.Duplicate onClick={onDuplicate} />
            <ActionMenu.Delete onClick={onDelete} />
          </ActionMenu>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

QuoteComponentEditionRow.propTypes = {
  component: object.isRequired,
  isLastRow: bool,
  isReadOnly: bool,
  isHiddenCost: bool,
};

QuoteComponentEditionRow.defaultProps = {
  isLastRow: false,
  isReadOnly: false,
  isHiddenCost: false,
};
