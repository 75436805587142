import type { FC } from 'react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Chapters, Container, Section } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, useForm } from 'graneet-form';
import { Text, Flex, Spinner } from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type {
  AccountingJournalEditionBlockForm,
  AccountingPurchasesEditionBlockForm,
  AccountingSalesEditionBlockForm,
  AccountingBankEditionBlockForm,
  AccountingVatEditionBlockForm,
  AccountingConfigBankEditionBlockForm,
} from 'features/accounting/forms/accounting.form';
import {
  getAccountingAccountPurchasesNameFieldName,
  getAccountingAccountPurchasesFieldName,
  getAccountingVatFieldName,
  getAccountingVatAccountWorkFieldName,
  getAccountingConfigBankJournalFieldName,
  getAccountingConfigBankLabelFieldName,
} from 'features/accounting/forms/accounting.form';
import { populateDTOFromAccountingConfigForm } from 'features/accounting/forms/accounting-form.util';
import { useAccountingConfigs, useUpdateAccountingConfig } from 'features/accounting/services/accounting-config.api';
import { AccountingJournalEditionBlock } from 'features/accounting/components/blocks/AccountingJournalEditionBlock';
import { AccountingPurchasesEditionBlock } from 'features/accounting/components/blocks/AccountingPurchasesEditionBlock/AccountingPurchasesEditionBlock';
import { AccountingSalesEditionBlock } from 'features/accounting/components/blocks/AccountingSalesEditionBlock/AccountingSalesEditionBlock';
import { AccountingBankEditionBlock } from 'features/accounting/components/blocks/AccountingBankEditionBlock';
import { AccountingVatEditionBlock } from 'features/accounting/components/blocks/AccountingVatEditionBlock';
import { AccountingBanksEditionBlock } from 'features/accounting/components/blocks/AccountingBanksEditionBlock/AccountingBanksEditionBlock';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { AccountingBankAccountsEditionBlock } from 'features/accounting/components/blocks/AccountingBankAccountsEditionBlock/AccountingBankAccountsEditionBlock';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

type FormValues = AccountingJournalEditionBlockForm &
  AccountingSalesEditionBlockForm &
  AccountingPurchasesEditionBlockForm &
  AccountingVatEditionBlockForm &
  AccountingBankEditionBlockForm;

export const EditAccountingConfigScreen: FC = () => {
  const { t } = useTranslation(['accounting', 'global']);
  const { updateHeaderTitle } = useHeaderContext();

  const accountingConfigsQuery = useAccountingConfigs();
  const updateAccountingConfigMutation = useUpdateAccountingConfig();

  const hasNewAccountingPaymentMethods = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_PAYMENT_METHODS);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<FormValues>({
    onUpdateAfterBlur: async (name, value) => {
      setIsLoading(true);
      const dto = populateDTOFromAccountingConfigForm({ [name]: value } as any);
      await updateAccountingConfigMutation.mutateAsync(dto).finally(() => {
        setIsLoading(false);
      });
    },
  });

  useLayoutEffect(() => {
    updateHeaderTitle(t('accounting:tabs.accountingConfig'), []);
  }, [t, updateHeaderTitle]);

  useEffect(() => {
    if (accountingConfigsQuery.data) {
      const accountingConfig = accountingConfigsQuery.data;

      const purchasesAccountForm: Partial<AccountingPurchasesEditionBlockForm> = {};
      accountingConfig.componentTypes.forEach((componentType) => {
        purchasesAccountForm[getAccountingAccountPurchasesFieldName(componentType.id)] = componentType.accountPurchases;
      });
      accountingConfig.purchasesAccounts.forEach((purchasesAccount) => {
        purchasesAccountForm[getAccountingAccountPurchasesFieldName(purchasesAccount.id)] = purchasesAccount.account;
        purchasesAccountForm[getAccountingAccountPurchasesNameFieldName(purchasesAccount.id)] = purchasesAccount.name;
      });

      const bankForm: Partial<AccountingConfigBankEditionBlockForm> = {};

      (accountingConfig.accountingConfigBanks ?? []).forEach((bank) => {
        bankForm[getAccountingConfigBankLabelFieldName(bank.id)] = bank.label;
        bankForm[getAccountingConfigBankJournalFieldName(bank.id)] = bank.journal;
      });

      const vatsForm: Partial<AccountingVatEditionBlockForm & AccountingSalesEditionBlockForm> = {};
      accountingConfig.vats.forEach((vat) => {
        vatsForm[getAccountingVatAccountWorkFieldName(vat.id)] = vat.accountWork;

        vatsForm[getAccountingVatFieldName(vat.id, 'accountSales')] = vat.accountSales;
        vatsForm[getAccountingVatFieldName(vat.id, 'accountDownPayment')] = vat.accountDownPayment;
        vatsForm[getAccountingVatFieldName(vat.id, 'accountPurchases')] = vat.accountPurchases;

        vatsForm[getAccountingVatFieldName(vat.id, 'accountSalesCollected')] = vat.accountSalesCollected;
        vatsForm[getAccountingVatFieldName(vat.id, 'accountSalesPending')] = vat.accountSalesPending;
        vatsForm[getAccountingVatFieldName(vat.id, 'accountPurchasesDeductible')] = vat.accountPurchasesDeductible;
        vatsForm[getAccountingVatFieldName(vat.id, 'accountPurchasesPending')] = vat.accountPurchasesPending;
      });

      form.setFormValues({
        journalSales: accountingConfig.journalSales,
        journalBank: accountingConfig.journalBank,
        journalPurchases: accountingConfig.journalPurchases,
        splitSalesWorkAsBases: accountingConfig.splitSalesWorkAsBases,
        accountSalesDownPayment: accountingConfig.accountSalesDownPayment,
        accountSalesHoldback: accountingConfig.accountSalesHoldback,
        accountSalesPriceRevision: accountingConfig.accountSalesPriceRevision,
        accountSalesDirectPayment: accountingConfig.accountSalesDirectPayment,
        accountSalesWork: accountingConfig.accountSalesWork,
        accountSalesWorkReversalOfLiability: accountingConfig.accountSalesWorkReversalOfLiability,
        accountSalesOtherDeduction: accountingConfig.accountSalesOtherDeduction,
        accountSalesProrataAccount: accountingConfig.accountSalesProrataAccount,
        splitProrataWithVAT: accountingConfig.splitProrataWithVAT || undefined,
        useClientCodeWithHoldback: accountingConfig.useClientCodeWithHoldback || undefined,
        accountClient: accountingConfig.accountClient,
        accountSupplier: accountingConfig.accountSupplier,
        accountBankSettlementWire: accountingConfig.accountBankSettlementWire,
        ...purchasesAccountForm,
        ...bankForm,
        ...vatsForm,
      });
    }
  }, [accountingConfigsQuery.data, form]);

  return (
    <Container>
      <Form form={form}>
        <Section
          title={
            <Flex alignItems="center" gap={3}>
              <Text>{t('accounting:parameters.title')}</Text>
              {isLoading && <Spinner flexGrow={0} />}
            </Flex>
          }
          description={t('accounting:parameters.description')}
        >
          <Chapters>
            <Chapters.Chapter title={t('accounting:parameters.sections.journals')} id="journal">
              <AccountingJournalEditionBlock />
            </Chapters.Chapter>

            {hasNewAccountingPaymentMethods && ( // @[ff: accounting-payment-methods]
              <>
                <Chapters.Chapter title={t('accounting:parameters.sections.banks')} id="banks">
                  <AccountingBanksEditionBlock
                    accountingConfigBanks={accountingConfigsQuery.data?.accountingConfigBanks ?? []}
                  />
                </Chapters.Chapter>

                <Chapters.Chapter title={t('accounting:parameters.sections.bankAccounts')} id="bankAccounts">
                  <AccountingBankAccountsEditionBlock
                    defaultAccountingConfigBankAccount={
                      accountingConfigsQuery.data?.defaultAccountingConfigBankAccount ?? undefined
                    }
                    accountingConfigBanks={accountingConfigsQuery.data?.accountingConfigBanks ?? []}
                  />
                </Chapters.Chapter>
              </>
            )}

            <Chapters.Chapter title={t('accounting:parameters.sections.sales')} id="sales">
              <AccountingSalesEditionBlock
                vats={accountingConfigsQuery.data?.vats || []}
                clients={accountingConfigsQuery.data?.clients || []}
              />
            </Chapters.Chapter>

            <Chapters.Chapter title={t('accounting:parameters.sections.purchases')} id="purchases">
              <AccountingPurchasesEditionBlock
                suppliers={accountingConfigsQuery.data?.suppliers || []}
                purchasesAccounts={accountingConfigsQuery.data?.purchasesAccounts || []}
              />
            </Chapters.Chapter>

            {!hasNewAccountingPaymentMethods && ( // @[ff: accounting-payment-methods] remove AccountingBankEditionBlock
              <Chapters.Chapter title={t('accounting:parameters.sections.bank')} id="bank">
                <AccountingBankEditionBlock />
              </Chapters.Chapter>
            )}

            <Chapters.Chapter title={t('accounting:parameters.sections.vat')} id="vat">
              <AccountingVatEditionBlock vats={accountingConfigsQuery.data?.vats || []} />
            </Chapters.Chapter>
          </Chapters>
        </Section>
      </Form>
    </Container>
  );
};
