import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Flex, Stack } from '@chakra-ui/react';
import {
  capitalizeString,
  IconAdvanced,
  SimpleHelpIcon,
  SingleSelectField,
  Steps,
  TeamBuildingIcon,
  TextField,
  Title,
} from '@graneet/lib-ui';
import { Form, useStepForm } from 'graneet-form';
import type { IOnboarding } from '@graneet/business-logic';
import dayjs from 'dayjs';

import { Rule } from 'features/form/rules/Rule';
import type {
  EditOnboardingLegalInformationForm,
  EditOnboardingWizard,
} from 'features/onboarding/forms/EditOnboardingWizard';

interface EditOnboardingLegalInformationStepProps {
  onboarding: IOnboarding;
}

export const EditOnboardingLegalInformationStep: FC<EditOnboardingLegalInformationStepProps> = ({ onboarding }) => {
  const { t } = useTranslation(['global', 'onboarding']);

  const { form, initFormValues } = useStepForm<EditOnboardingWizard, 'legalInformation'>();

  useEffect(() => {
    initFormValues({
      companyNumber: onboarding.company?.companyNumber,
      name: onboarding.company?.name,
      corporateName: onboarding.company?.corporateName,
      legalForm: onboarding.company?.legalForm,
      rcsNumber: onboarding.company?.rcsNumber,
      nafCode: onboarding.company?.nafCode,
      vatNumber: onboarding.company?.vatNumber,
      startDateFiscalYear: onboarding.company?.startDateFiscalYear?.toString() as `${number}` | undefined,
      address: onboarding.company?.headquartersAddress?.address,
      postalCode: onboarding.company?.headquartersAddress?.postalCode,
      city: onboarding.company?.headquartersAddress?.city,
      country: onboarding.company?.headquartersAddress?.country,
    });
  }, [initFormValues, onboarding]);

  const options = dayjs.months().map((month, index) => ({
    label: capitalizeString(month),
    value: `${index + 1}`,
  }));

  return (
    <Form form={form} style={{ width: '100%', height: '100%' }}>
      <Stack gap={6} boxSize="100%" justifyContent="center" alignItems="center">
        <Flex alignItems="baseline" gap={3}>
          <Title fontFamily="Alliance">{t('onboarding:steps.legalInformation.title')}</Title>
          <IconAdvanced icon={<SimpleHelpIcon m={0} />} tooltip={t('onboarding:steps.legalInformation.tooltip')} />
        </Flex>

        <Flex gap={3} w="40rem">
          <TextField<EditOnboardingLegalInformationForm>
            name="companyNumber"
            label={t('onboarding:fields.companyNumber')}
            textAlign="left"
          >
            <Rule.IsRequired />
          </TextField>
        </Flex>

        <Flex w="40rem">
          <Box w="50%" mr={2}>
            <TextField<EditOnboardingLegalInformationForm>
              name="name"
              label={t('onboarding:fields.name')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>

          <Box w="50%" ml={2}>
            <TextField<EditOnboardingLegalInformationForm>
              name="corporateName"
              label={t('onboarding:fields.corporateName')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>
        </Flex>

        <Flex w="40rem">
          <Box w="50%" mr={2}>
            <TextField<EditOnboardingLegalInformationForm>
              name="legalForm"
              label={t('onboarding:fields.legalForm')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>

          <Box w="50%" ml={2}>
            <TextField<EditOnboardingLegalInformationForm>
              name="rcsNumber"
              label={t('onboarding:fields.rcsNumber')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>
        </Flex>

        <Flex w="40rem">
          <Box w="50%" mr={2}>
            <TextField<EditOnboardingLegalInformationForm>
              name="nafCode"
              label={t('onboarding:fields.nafCode')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>

          <Box w="50%" ml={2}>
            <TextField<EditOnboardingLegalInformationForm>
              name="vatNumber"
              label={t('onboarding:fields.vatNumber')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>
        </Flex>

        <Flex w="40rem">
          <SingleSelectField<EditOnboardingLegalInformationForm>
            name="startDateFiscalYear"
            options={options}
            label={t('onboarding:fields.startDateFiscalYear')}
            isClearable={false}
          >
            <Rule.IsRequired />
          </SingleSelectField>
        </Flex>

        <Flex w="40rem">
          <TextField<EditOnboardingLegalInformationForm>
            name="address"
            label={t('onboarding:fields.address')}
            textAlign="left"
          >
            <Rule.IsRequired />
          </TextField>
        </Flex>

        <Flex w="40rem">
          <Box w="33%" mr={2}>
            <TextField<EditOnboardingLegalInformationForm>
              name="postalCode"
              label={t('onboarding:fields.postalCode')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>

          <Box w="34%" mx={2}>
            <TextField<EditOnboardingLegalInformationForm>
              name="city"
              label={t('onboarding:fields.city')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>

          <Box w="33%" ml={2}>
            <TextField<EditOnboardingLegalInformationForm>
              name="country"
              label={t('onboarding:fields.country')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>
        </Flex>

        <Flex w="40rem">
          <Steps.NextStepButton w="100%">{t('global:words.c.next')}</Steps.NextStepButton>
        </Flex>
      </Stack>

      <Steps.Placeholder placement="bottom">
        <Center>
          <TeamBuildingIcon boxSize="15rem" />
        </Center>
      </Steps.Placeholder>
    </Form>
  );
};
