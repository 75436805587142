import type { RefObject } from 'react';
import { useLayoutEffect, useState } from 'react';

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

/**
 * useIntersection is a custom React hook that allows you to determine if a specific element is within the viewport or not using the Intersection Observer API.
 *
 * @param {RefObject<Element | null>} elementRef - The reference to the element that you want to observe for intersection.
 * @param {number} [threshold=0] - The threshold at which the intersection should be calculated. A number between 0 and 1.
 * @param {Element|null} [root=null] - The element that is used as the viewport for checking visibility of the target.
 * @param {string} [rootMargin='0%'] - The margin around the root element to use when calculating intersections.
 *
 * @returns {boolean} - Returns true if the observed element is intersecting with the viewport, otherwise returns false.
 */
export function useIntersection(
  elementRef: RefObject<Element | null>,
  { threshold = 0, root = null, rootMargin = '0%' }: Args = {},
): boolean {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useLayoutEffect(() => {
    const node = elementRef.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || !node) return () => {};

    const observer = new IntersectionObserver(
      ([e]): void => {
        setIsIntersecting(e.isIntersecting);
      },
      { threshold, root, rootMargin },
    );

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, root, rootMargin, threshold]);

  return isIntersecting;
}
