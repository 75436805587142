import type { KeysMatching, SingleSelectFieldProps } from '@graneet/lib-ui';
import { SingleSelectField, formatNumberToVatRate, formatPercentage } from '@graneet/lib-ui';
import type { FieldValues } from 'graneet-form';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useCallback, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { VAT_TYPE } from '@graneet/business-logic';

import { useAccountingStandardVATRates } from '../services/accounting-config.api';

interface AccountingVATFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, any> = KeysMatching<T, any>,
> extends Omit<SingleSelectFieldProps<T, K>, 'name' | 'options'> {
  name: K;
  valueScale?: 1 | 100;
}

export const AccountingVATField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, any> = KeysMatching<T, any>,
>({
  name,
  valueScale = 1,
  ...props
}: AccountingVATFieldProps<T, K>) => {
  const vats = useAccountingStandardVATRates();

  const scaleValue = useCallback((rate: number) => rate * valueScale, [valueScale]);

  const form = useFormContext<any>();

  const { [name]: fieldValue } = useOnChangeValues(form, [name]);

  if (fieldValue && !vats.data.some((vat) => scaleValue(vat.rate) === fieldValue)) {
    form.setFormValues({
      [name]: null,
    });
  }

  const options = useMemo(
    () =>
      vats.data
        .filter((vat) => vat.type === VAT_TYPE.NORMAL)
        .map((vat) => ({
          label: <Box>{formatPercentage(formatNumberToVatRate(vat.rate))}</Box>,
          value: scaleValue(vat.rate),
        })),
    [scaleValue, vats.data],
  );

  return (
    <SingleSelectField<T, K>
      name={name}
      {...props}
      isClearable={false}
      options={options as any}
      displayMode="compact"
    />
  );
};
