import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ChapterCollapse } from '@graneet/lib-ui';
import type { IQuote } from '@graneet/business-logic';
import { isQuoteInformationEditable } from '@graneet/business-logic';

import { patchQuote } from '../../services/quote.api';

import { TextEditionCard } from 'features/common/components/TextEditionCard';

interface QuoteNoteCardProps {
  quote: IQuote;
  isReadOnly: boolean;
}

export const QuoteNoteCard: FC<QuoteNoteCardProps> = ({ quote, isReadOnly }) => {
  const { t } = useTranslation(['quote']);
  const isEditable = isQuoteInformationEditable(quote);

  const updateNote = useCallback((note: string) => patchQuote(quote.id, { note }), [quote]);

  return (
    <ChapterCollapse
      title={t('quote:cards.note.title')}
      description={<Trans t={t} i18nKey="quote:cards.note.description" />}
    >
      <TextEditionCard
        title={t('quote:cards.note.title')}
        text={quote.note}
        onTextEdited={updateNote}
        errorLabel={t('quote:cards.note.error')}
        successLabel={t('quote:cards.note.success')}
        placeholder={t('quote:cards.note.placeholder')}
        isEditable={isEditable && !isReadOnly}
      />
    </ChapterCollapse>
  );
};
