import type { FC } from 'react';
import { useCallback } from 'react';
import { Card, DataTable, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type { AccountingJournalEditionBlockForm } from '../../forms/accounting.form';

import { Rule } from 'features/form/rules/Rule';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface AccountingJournalEditionBlockProps {
  mustDisplayFields?: Record<keyof AccountingJournalEditionBlockForm, boolean>;

  haveRequiredFields?: boolean;
}

export const AccountingJournalEditionBlock: FC<AccountingJournalEditionBlockProps> = ({
  mustDisplayFields,
  haveRequiredFields = false,
}) => {
  const { t } = useTranslation(['accounting']);

  const hasNewAccountingPaymentMethods = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_PAYMENT_METHODS);

  const mustDisplayField = useCallback(
    (fieldName: keyof AccountingJournalEditionBlockForm) => {
      if (!mustDisplayFields) {
        return true;
      }

      return !!mustDisplayFields[fieldName];
    },
    [mustDisplayFields],
  );

  return (
    <Stack>
      <Text fontSize="lg" color="gray.800">
        {t('accounting:parameters.sections.journals')}
      </Text>

      <Card>
        <DataTable numberOfColumns={1}>
          {mustDisplayField('journalPurchases') && (
            <DataTable.Row label={t('accounting:fields.journalPurchases')}>
              <DataTable.Cell>
                <TextField<AccountingJournalEditionBlockForm>
                  name="journalPurchases"
                  placeholder={t('accounting:parameters.configPlaceholder')}
                >
                  {haveRequiredFields && <Rule.IsRequired />}
                </TextField>
              </DataTable.Cell>
            </DataTable.Row>
          )}

          {mustDisplayField('journalSales') && (
            <DataTable.Row label={t('accounting:fields.journalSales')}>
              <DataTable.Cell>
                <TextField<AccountingJournalEditionBlockForm>
                  name="journalSales"
                  placeholder={t('accounting:parameters.configPlaceholder')}
                >
                  {haveRequiredFields && <Rule.IsRequired />}
                </TextField>
              </DataTable.Cell>
            </DataTable.Row>
          )}

          {/* @[ff: accounting-payment-methods] remove this whole block after ff enabled */}
          {!hasNewAccountingPaymentMethods && mustDisplayField('journalBank') && (
            <DataTable.Row label={t('accounting:fields.journalBank')}>
              <DataTable.Cell>
                <TextField<AccountingJournalEditionBlockForm>
                  name="journalBank"
                  placeholder={t('accounting:parameters.configPlaceholder')}
                >
                  {haveRequiredFields && <Rule.IsRequired />}
                </TextField>
              </DataTable.Cell>
            </DataTable.Row>
          )}
        </DataTable>
      </Card>
    </Stack>
  );
};
