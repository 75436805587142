import type { FC } from 'react';
import { useMemo } from 'react';
import { DataTable, Date, Link } from '@graneet/lib-ui';
import type { IBill, IClient } from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import { getStatementPath } from '../../statement/services/statement.util';

import { useReminders } from 'features/reminder/services/reminder.api';

interface BillInformationCardProps {
  bill: IBill;

  client: IClient;
}

export const BillInformationBlock: FC<BillInformationCardProps> = ({ bill, client }) => {
  const { t } = useTranslation(['bill']);

  const reminders = useReminders();

  const entity = bill?.progressStatement || bill?.invoice || bill?.downPayment || bill?.holdback;

  const relatedEntityType = useMemo(() => {
    if (bill?.progressStatement) return STATEMENT_TYPES.PROGRESS_STATEMENT;
    if (bill?.invoice) return STATEMENT_TYPES.INVOICE;
    if (bill?.downPayment) return STATEMENT_TYPES.DOWN_PAYMENT;
    if (bill?.holdback) return STATEMENT_TYPES.HOLDBACK;

    return undefined;
  }, [bill]);

  const statementUrl = useMemo(() => {
    if (relatedEntityType === STATEMENT_TYPES.HOLDBACK && entity) {
      return `/sales/holdbacks/${entity.id}`;
    }
    if (relatedEntityType && entity) {
      return `/sales/statements/${getStatementPath(relatedEntityType)}/${entity.id}`;
    }
    return '/sales/statements';
  }, [entity, relatedEntityType]);

  const remindersByDaysAfterDeadline = reminders.data.sort((a, b) => a.daysAfterDeadline - b.daysAfterDeadline);
  const reminderIndex = remindersByDaysAfterDeadline.findIndex(
    (reminder) => bill.billToRemind?.reminderId === reminder.id,
  );
  const currentReminder = remindersByDaysAfterDeadline[reminderIndex];
  const nextReminder = reminderIndex !== -1 ? remindersByDaysAfterDeadline[reminderIndex + 1] : null;

  return (
    <Box fontSize="sm">
      <DataTable numberOfColumns={1} density="sm">
        <DataTable.Row label={t('bill:fields.invoiceNumber')}>
          <DataTable.Cell>{bill.invoiceNumber}</DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('bill:fields.statement.name')}>
          <DataTable.Cell>
            <Link to={statementUrl}>{bill.name}</Link>
          </DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('bill:fields.project.name')}>
          <DataTable.Cell>
            {bill.project && <Link to={`/projects/${bill.project.id}`}>{bill.project.name}</Link>}
          </DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('bill:fields.client.name')}>
          <DataTable.Cell>
            {client && <Link to={`/projects/${client.id}`}>{client.enterpriseName}</Link>}
          </DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('bill:fields.billingDate')}>
          <DataTable.Cell>
            <Date>{bill.billingDate}</Date>
          </DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('bill:fields.paymentDeadline')}>
          <DataTable.Cell>
            <Date>{bill.paymentDeadline}</Date>
          </DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('bill:fields.reminder')}>
          <DataTable.Cell>{currentReminder?.name}</DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('bill:fields.nextReminder')}>
          <DataTable.Cell>{nextReminder?.name}</DataTable.Cell>
        </DataTable.Row>
      </DataTable>
    </Box>
  );
};
