import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleCheckCircleBlackIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8Z"
      fill="#1F2A37"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8689 4.75915C12.2386 5.05486 12.2985 5.59426 12.0027 5.96392L7.43136 11.6781C7.14215 12.0397 6.61813 12.1063 6.24776 11.8284L3.96205 10.1142C3.58334 9.83014 3.50659 9.29289 3.79062 8.91418C4.07465 8.53547 4.61191 8.45872 4.99062 8.74275L6.61171 9.95857L10.6642 4.89301C10.9599 4.52336 11.4993 4.46342 11.8689 4.75915Z"
      fill="white"
    />
  </Icon>
);
