import type { FC } from 'react';
import type { FlexboxProps, BoxProps } from '@chakra-ui/react';
import { Flex, Box } from '@chakra-ui/react';

type ButtonProps = FlexboxProps & BoxProps;

export const Button: FC<ButtonProps> = ({ ...checkboxProps }) => (
  <Flex
    w="20px"
    h="20px"
    background="white"
    borderRadius="10px"
    borderWidth="2px"
    _checked={{ borderColor: 'greenBrand.light' }}
    borderColor="gray.500"
    mr={2}
    align="center"
    justify="center"
    {...checkboxProps}
  >
    <Box
      w="10px"
      h="10px"
      background="white"
      borderRadius="10px"
      _checked={{ background: 'greenBrand.light' }}
      {...checkboxProps}
    />
  </Flex>
);
