import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import type { CardProps } from '@graneet/lib-ui';
import { Button, Card, GotoLink, SimpleEditIcon } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import type { IPaymentMethodResponseDTO } from '@graneet/business-logic';

import { PaymentMethodEditModal } from './modals/PaymentMethodEditModal/PaymentMethodEditModal';
import { PaymentMethodDetail } from './PaymentMethodDetail';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface PaymentMethodEditionCardProps extends Omit<CardProps, 'children'> {
  paymentMethod: IPaymentMethodResponseDTO;

  hasUpdatePermission: boolean;

  onPaymentMethodUpdated?: (paymentMethod: IPaymentMethodResponseDTO) => Promise<void> | void;

  displayUpdateButton?: boolean;
}

export const PaymentMethodEditionCard: FC<PaymentMethodEditionCardProps> = ({
  paymentMethod,
  hasUpdatePermission,
  onPaymentMethodUpdated,
  displayUpdateButton = true,
  ...rest
}) => {
  const updateModal = useDisclosure();
  const { t } = useTranslation(['paymentMethods', 'global']);
  const updateButtonDisabledProps = useDisabledButtonProps(undefined, hasUpdatePermission);

  const onUpdated = useCallback(
    async (paymentMethodUpdated: IPaymentMethodResponseDTO) => {
      await onPaymentMethodUpdated?.(paymentMethodUpdated);
      updateModal.onClose();
    },
    [updateModal, onPaymentMethodUpdated],
  );

  return (
    <>
      <Card
        title={paymentMethod?.name}
        {...rest}
        topRightContent={
          displayUpdateButton ? (
            <Button
              variant="outline"
              onClick={updateModal.onOpen}
              leftIcon={<SimpleEditIcon />}
              {...updateButtonDisabledProps()}
            >
              {t('global:words.c.update')}
            </Button>
          ) : (
            <GotoLink
              to="/settings/company/company/payment-methods"
              label={t('paymentMethods:cta.goToLink')}
              style={{
                // Override style to align link properly
                alignSelf: 'auto',
              }}
            />
          )
        }
      >
        <PaymentMethodDetail mt={3} paymentMethod={paymentMethod} />
      </Card>
      <PaymentMethodEditModal
        isOpen={updateModal.isOpen}
        onClose={updateModal.onClose}
        onUpdated={onUpdated}
        paymentMethod={paymentMethod}
      />
    </>
  );
};
