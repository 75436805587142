import { Flex, Text } from '@chakra-ui/react';
import type { ISupplier } from '@graneet/business-logic';
import { Card, GotoLink } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SupplierFieldOption } from 'features/supplier/components/SupplierFieldOption';

interface SupplierInvoiceCardProps {
  supplier: ISupplier | undefined | null;
}

export const CashOutflowSupplierInvoiceCard: FC<SupplierInvoiceCardProps> = ({ supplier }) => {
  const { t } = useTranslation(['cashOutflow']);

  if (!supplier) return null;

  return (
    <Card
      title={
        <Flex mb={3}>
          <Text mr={4}>{t('cashOutflow:cards.supplierCard.title')}</Text>
          <GotoLink to={`/contacts/suppliers/${supplier.id}`} label={t('cashOutflow:cards.supplierCard.goTo')} />
        </Flex>
      }
    >
      <SupplierFieldOption supplier={supplier} />
    </Card>
  );
};
