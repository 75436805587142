import { DocIcon, SingleSelect } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useMemo, useCallback } from 'react';
import type { IEmailTemplate } from '@graneet/business-logic';
import { mergeLexicalToLexical } from '@graneet/business-logic';
import { useFormContext } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';

import type { MailingFormValues } from './mailing.form';

interface MailingModalEmailTemplateMenuProps {
  emailTemplates: IEmailTemplate[];

  defaultBody: string;
}

export const MailingModalEmailTemplateMenu: FC<MailingModalEmailTemplateMenuProps> = ({
  emailTemplates,
  defaultBody,
}) => {
  const { t } = useTranslation(['mailing']);

  const formContext = useFormContext<MailingFormValues>();

  const onOptionSelected = useCallback(
    (selectionOption: { value: string } | null) => {
      const id = selectionOption?.value;

      if (!id) return;

      const selectedEmailTemplate = emailTemplates.find((emailTemplate) => emailTemplate.id === id)!;

      const formValues = formContext.getFormValues();

      formContext.setFormValues({
        subject: selectedEmailTemplate.subject,
        body: formValues.body
          ? JSON.stringify(
              mergeLexicalToLexical(
                JSON.parse(formValues.body),
                mergeLexicalToLexical(
                  JSON.parse(selectedEmailTemplate.template),
                  defaultBody ? JSON.parse(defaultBody) : undefined,
                ),
              ),
            )
          : selectedEmailTemplate.template,
      });
    },
    [defaultBody, emailTemplates, formContext],
  );

  const options = useMemo(
    () =>
      emailTemplates.map((emailTemplate) => ({
        value: emailTemplate.id,
        label: emailTemplate.title,
      })),
    [emailTemplates],
  );

  if (emailTemplates.length === 0) {
    return null;
  }

  return (
    <Flex w="13rem">
      <SingleSelect
        isSearchable={false}
        isClearable={false}
        onChange={onOptionSelected}
        placeholder={
          <Flex alignItems="center" gap={3}>
            <DocIcon />
            <Text>{t('mailing:modal.emailTemplates')}</Text>
          </Flex>
        }
        options={options}
      />
    </Flex>
  );
};
