import type { FC, ReactNode } from 'react';
import { Fragment, useMemo } from 'react';
import { DeepTable, useCurrency } from '@graneet/lib-ui';
import { Box } from '@chakra-ui/react';
import { useFormContext, useOnBlurValues } from 'graneet-form';
import { CUMULATIVE_INPUT_TYPE } from '@graneet/business-logic';

import { useProgressStatementContract } from '../../hooks/useProgressStatementTree';
import type { ContractId, LotId } from '../../services/progress-statement-tree.util';
import { isContractId } from '../../services/progress-statement-tree.util';
import { useProgressStatementContext } from '../../contexts/ProgressStatementContext';
import type { ProgressStatementEditItemForm } from '../../forms/progress-statement-edit-item-form';
import { getItemCumulativeFieldName } from '../../forms/progress-statement-edit-item-form';

import { ProgressStatementDiscountRow } from './ProgressStatementDiscountRow';
import { ProgressStatementCustomDiscountRow } from './ProgressStatementCustomDiscountRow';

interface ProgressStatementLotWrapperProps {
  id: LotId | ContractId;

  children: ReactNode;
}

type ProgressStatementContractWrapperProps = Omit<ProgressStatementLotWrapperProps, 'id'> & {
  id: ContractId;
};

const ProgressStatementContractWrapper: FC<ProgressStatementContractWrapperProps> = ({ id, children }) => {
  const {
    node: contract,
    state: { isExpanded },
  } = useProgressStatementContract(id);

  const { mapAmountToNumber } = useCurrency();
  const { getItemsIdsOfContainer, indexedItems } = useProgressStatementContext();
  const form = useFormContext<ProgressStatementEditItemForm>();

  const itemsIds = useMemo(
    () => getItemsIdsOfContainer(contract.container.id),
    [contract.container.id, getItemsIdsOfContainer],
  );
  const contractFieldsNames = useMemo(
    () => itemsIds.map((itemId) => getItemCumulativeFieldName(itemId, CUMULATIVE_INPUT_TYPE.AMOUNT)),
    [itemsIds],
  );

  const values = useOnBlurValues(form, [...contractFieldsNames]);

  const itemLines = useMemo(
    () =>
      itemsIds.map((itemId) => ({
        cumulativeAmountExVAT: mapAmountToNumber(
          values[getItemCumulativeFieldName(itemId, CUMULATIVE_INPUT_TYPE.AMOUNT)] || 0,
        ),
        item: indexedItems[itemId],
      })),
    [indexedItems, itemsIds, mapAmountToNumber, values],
  );

  return (
    <Box>
      {children}

      <Box display={isExpanded ? 'block' : 'none'} ml={2}>
        {contract.discount && (
          <>
            <DeepTable.Separator depth={1} colSpan={9} />
            <ProgressStatementDiscountRow contract={contract} itemLines={itemLines} />
          </>
        )}

        {contract.customDiscounts?.map((customDiscount) => (
          <Fragment key={customDiscount.id}>
            <DeepTable.Separator depth={1} colSpan={9} />
            <ProgressStatementCustomDiscountRow
              contract={contract}
              customDiscount={customDiscount}
              itemLines={itemLines}
            />
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export const ProgressStatementLotOrContractWrapper: FC<ProgressStatementLotWrapperProps> = ({ id, children }) => {
  if (!isContractId(id)) {
    return <Box ml={2}>{children}</Box>;
  }
  return <ProgressStatementContractWrapper id={id}>{children}</ProgressStatementContractWrapper>;
};
