import { forwardRef, useCallback, useImperativeHandle, type PropsWithChildren } from 'react';
import type { StackProps } from '@chakra-ui/react';
import { Stack, Flex, Tabs, TabList, Tab, TabPanels, TabPanel, TabIndicator } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { GraneetIconButton as IconButton } from '../IconButton/IconButton';

export type QuotationDrawerProps = PropsWithChildren<StackProps> & {
  onCloseSidebar: () => void;
  onOpenSidebar: () => void;
  isOpen: boolean;
  data: Array<{ label: string | JSX.Element; content: JSX.Element }>;
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
};

export type DrawerHandle = {
  changeTab: (index: number) => void;
};

export const QuotationDrawer = forwardRef<DrawerHandle, QuotationDrawerProps>(
  ({ isOpen, onCloseSidebar, data, children, onOpenSidebar, tabIndex, setTabIndex, ...props }, ref) => {
    const handleTabsChange = useCallback(
      (i: number) => {
        setTabIndex(i);
      },
      [setTabIndex],
    );

    useImperativeHandle(ref, () => ({
      changeTab: (i: number) => {
        setTabIndex(i);
      },
    }));

    return (
      <Stack
        width={isOpen ? '21.5rem' : '0'}
        height={isOpen ? '100%' : '0'}
        backgroundColor="#F9FAFB"
        gap={8}
        borderLeft="1px solid var(--Base-Opacity-Grey-200, rgba(0, 19, 58, 0.10))"
        as={motion.div}
        overflow="hidden"
        transition="width 0.25s ease-in-out"
        {...props}
      >
        <Tabs
          paddingX={2}
          index={tabIndex}
          colorScheme="blue"
          size="sm"
          variant="unstyled"
          height="100%"
          onChange={handleTabsChange}
        >
          <TabList borderBottom="1px solid var(--Base-Opacity-Grey-200, rgba(0, 19, 58, 0.10))" py={0}>
            {data.map((tab, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Tab paddingX={2} _selected={{ color: 'greenBrand.light' }} key={`${index}Tab`}>
                {tab.label}
              </Tab>
            ))}
            <Flex justifyContent="flex-end" flex={1} alignItems="center" paddingY={2} paddingRight={1}>
              <IconButton size="lg" icon="ri-layout-right-line" onClick={onCloseSidebar} color="ghost" />
            </Flex>
          </TabList>

          <TabIndicator paddingX={2} mt="-1.5px" height="0.125rem" bg="greenBrand.light" borderRadius="0.063rem" />

          <TabPanels
            style={{
              overflowY: 'auto',
              height: 'calc(100% - 73px)',
            }}
          >
            {data.map((tab, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TabPanel paddingX={2} key={`${index}TabPanel`} height="100%">
                {tab.content}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        {children}
      </Stack>
    );
  },
);
