import { Box, Flex, HStack } from '@chakra-ui/react';
import { TreeIndicator } from '@graneet/lib-ui';
import type { QUOTE_ITEM_TYPE_OF_PRICING, QuoteNodeObject, QuoteSubItemObject } from '@org/quotation-lib';
import type { FC } from 'react';
import type { GridApi } from '@ag-grid-community/core';

import { Item } from './Item';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const SubItem: FC<{
  item: QuoteSubItemObject;
  isExpanded: boolean;
  hasChildren?: boolean;
  level: number;
  nodeId: string;
  parentId: string | null;
  api: GridApi<QuoteNodeObject>;
  typeOfPricing: QUOTE_ITEM_TYPE_OF_PRICING;
}> = ({ item, hasChildren, level, nodeId, parentId, api, typeOfPricing, isExpanded }) => {
  const { quote } = useQuote();
  const node = parentId ? quote.getTree().getNodeOrNull(parentId) : null;
  const isLastChild = node?.getLastChild()?.getContent().getId() === item.id;

  return (
    <HStack width="100%" gap={0} height="100%" alignItems="flex-start" justifyItems="flex-start">
      <Flex alignSelf="stretch">
        <TreeIndicator type={isLastChild ? 'end' : 'intermediate'} top={isLastChild ? undefined : '5px'} />
      </Flex>
      <Box pl={`${(level - 1) * 0.313}rem`}>
        <Item
          item={item}
          isExpanded={isExpanded}
          level={0}
          type={item.type}
          hasChildren={hasChildren}
          nodeId={nodeId}
          api={api}
          typeOfPricing={typeOfPricing}
        />
      </Box>
    </HStack>
  );
};
