import type { PropsWithChildren } from 'react';
import { useMemo, useRef, useState } from 'react';

import { RichSelectContext } from './RichSelectContext.context';

export type RichSelectProviderProps<Value extends string | string[] | Record<string, number | string>> =
  PropsWithChildren<{
    isActive?: boolean;
    isDisabled?: boolean;
    hasError?: boolean;
    isOpen?: boolean;
    onOpen(): void;
    onClose(): void;
    onFocus(): void;
    onOptionSelected(option: Value): void;
    size: 'sm' | 'md';
    border: boolean;
  }>;

export const RichSelectProvider = <Value extends string | string[] | Record<string, number | string>>({
  isActive = false,
  isDisabled = false,
  hasError = false,
  isOpen = false,
  onOpen,
  onClose,
  onFocus,
  onOptionSelected,
  children,
  size,
  border,
}: RichSelectProviderProps<Value>) => {
  const [menuSize, setMenuSize] = useState<string | number>('10rem');
  const buttonRef = useRef<HTMLDivElement>(null);

  const value = useMemo(
    () => ({
      isDisabled,
      hasError,
      isOpen,
      onOpen,
      onClose,
      onFocus,
      menuSize,
      setMenuSize,
      isActive,
      onOptionSelected,
      buttonRef,
      size,
      border,
    }),
    [isDisabled, hasError, isOpen, onOpen, onClose, onFocus, menuSize, isActive, onOptionSelected, size, border],
  );

  return <RichSelectContext.Provider value={value}>{children}</RichSelectContext.Provider>;
};
