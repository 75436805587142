import { useCallback, useEffect } from 'react';
import { QuoteStatus } from '@graneet/business-logic';
import { Quote } from '@org/quotation-lib';
import { useParams } from 'react-router-dom';
import { QuoteSettingDrawerSelected, type QuoteSettingDTO } from '@org/graneet-bff-client';

import {
  updateQuoteClient,
  updateQuoteContacts,
  updateQuoteEditable,
  updateQuoteProject,
} from '../store/quoteUpdateZustand';
import { quoteComposeToQuoteObjectMapper } from '../mappers/quoteComposeToQuoteObjectMapper';

import { useQuoteCanBeLoad } from './useQuoteCanBeLoad';
import { useQuoteSetToStore } from './useQuoteSetToStore';
import { useQuoteSettingsSetToStore } from './useQuoteSettingsSetToStore';
import { useQuoteResetStore } from './useQuoteResetStore';
import { useQuoteIsRequestInConflictSetToStore } from './useQuoteIsRequestInConflictSetToStore';
import { useQuotationProxyApis } from './useQuoteProxyApis';

import { useUserSettings } from 'features/user/services/user.api';
import { useData } from 'features/api/hooks/useData';
import { useStore } from 'store/store';
import { useAppContext } from 'features/app/contexts/AppContext';

export const useFetchQuote = () => {
  const { quoteId } = useParams<{ quoteId: string }>();
  const { quoteProxyApi } = useQuotationProxyApis();
  const componentTypesFromStore = useStore((state) => state.quoteComponentTypes);
  const getQuoteComposeById = useCallback(() => quoteProxyApi!.getQuoteComposeById(quoteId), [quoteId, quoteProxyApi]);
  const { data: quoteComposed, fetch: fetchQuote, loading } = useData(getQuoteComposeById);
  const isRequestInConflictSetToStore = useQuoteIsRequestInConflictSetToStore();
  const quoteResetStore = useQuoteResetStore();
  const quoteSettingsSetToStore = useQuoteSettingsSetToStore();
  const quoteSetToStore = useQuoteSetToStore();
  const userSettings = useUserSettings();
  const quoteCanBeLoad = useQuoteCanBeLoad()();
  const { currentUser } = useAppContext();
  const { quoteSettingProxyApi } = useQuotationProxyApis();

  const updateQuoteStore = useCallback(async () => {
    if (quoteComposed) {
      const quoteObject = quoteComposeToQuoteObjectMapper(quoteComposed);
      if (quoteObject.tree) {
        const quote = new Quote(
          { isAutomaticIndexActivated: true, componentTypes: componentTypesFromStore },
          quoteObject,
        );
        quoteSetToStore(quote);
      }
      if (quoteObject.projectId) {
        updateQuoteProject(quoteComposed.project);
      }
      if (quoteObject.clientId) {
        updateQuoteClient(quoteComposed.client);
      }
      if (quoteObject.contacts) {
        updateQuoteContacts(quoteComposed.contacts);
      }

      const quoteSettingFromCurrentUser = quoteComposed.quoteSettings.find(
        (quoteSetting: QuoteSettingDTO) => quoteSetting.userId === currentUser.id,
      );
      if (quoteSettingFromCurrentUser) {
        quoteSettingsSetToStore(userSettings.data, quoteSettingFromCurrentUser);
      } else {
        // Used as a migration system
        // User with no setting that access quote create a new entry with a keys user quote pair
        await quoteSettingProxyApi.createQuoteSetting({
          quoteId: quoteComposed.id,
          drawerSelected: QuoteSettingDrawerSelected.Client,
          isDrawerExpanded: true,
          isAllItemsExpanded: true,
          isComponentsExpanded: true,
          isHiddenCostTabAllItemsExpanded: true,
        });
      }
    }
  }, [
    componentTypesFromStore,
    currentUser.id,
    quoteComposed,
    quoteSetToStore,
    quoteSettingProxyApi,
    quoteSettingsSetToStore,
    userSettings.data,
  ]);

  const refetch = useCallback(async () => {
    await fetchQuote();
    updateQuoteStore();
    isRequestInConflictSetToStore(false);
  }, [fetchQuote, isRequestInConflictSetToStore, updateQuoteStore]);

  useEffect(() => {
    updateQuoteStore();
    if (quoteComposed) {
      if ([QuoteStatus.Importing, QuoteStatus.Draft].includes(quoteComposed?.status)) {
        updateQuoteEditable(true);
      } else {
        updateQuoteEditable(false);
      }
    }

    return () => quoteResetStore();
  }, [quoteComposed, quoteResetStore, quoteSetToStore, updateQuoteStore]);

  return { refetch, loading, quoteComposed, quoteCanBeLoad };
};
