import { Box, CircularProgress, InputGroup } from '@chakra-ui/react';
import type { LEDGER_TYPES } from '@graneet/business-logic';
import type { KeysMatching, TextFieldValue } from '@graneet/lib-ui';
import { SimpleEditIcon, SimpleRepeatIcon, TextField } from '@graneet/lib-ui';
import type { FieldValues } from 'graneet-form';
import { HiddenField, useFormContext, useHiddenField } from 'graneet-form';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getNextLedgerStatePreview } from '../services/ledger.api';

import { useData } from 'features/api/hooks/useData';

interface LedgerFieldProps<FieldName extends string, HiddenFieldName extends string> {
  name: FieldName;
  label: string;
  isAutoGenerationDisabled?: boolean;
  isRequired?: boolean;
  children?: ReactNode;
  ledgerType: LEDGER_TYPES;
  isReadOnly?: boolean;
  isLedgerEnabledHiddenFieldName?: HiddenFieldName;
  projectId?: number;
}

export type LedgerFieldFormValues<T extends FieldValues, HiddenFieldName extends string = string> = T &
  Record<HiddenFieldName, string>;

export const LedgerField = <
  T extends FieldValues,
  FieldName extends KeysMatching<T, TextFieldValue> & string = KeysMatching<T, TextFieldValue> & string,
  HiddenFieldName extends string = 'isLedgerEnabled',
>({
  label,
  name,
  children,
  isAutoGenerationDisabled,
  isRequired,
  ledgerType,
  isReadOnly,
  isLedgerEnabledHiddenFieldName = 'isLedgerEnabled' as HiddenFieldName,
  projectId,
  ...props
}: LedgerFieldProps<FieldName, HiddenFieldName>) => {
  const { t } = useTranslation(['ledger']);

  const [autoMode, setAutoMode] = useState<boolean>(true);
  const form = useFormContext<LedgerFieldFormValues<T, HiddenFieldName>>();
  const isLedgerEnabledHiddenField = useHiddenField(form, isLedgerEnabledHiddenFieldName);

  const previewNext = useCallback(
    () => getNextLedgerStatePreview({ type: ledgerType, projectId }),
    [ledgerType, projectId],
  );
  const { data, fetch, loading } = useData(previewNext);

  // If empty string, it means the ledger does not exist or is not enabled.
  const formattedNextNumber = data === null ? null : (data?.formattedNextNumber ?? '');
  const canUseLedger = Boolean(formattedNextNumber) && !isAutoGenerationDisabled;

  const toggleAutoNumbering = useCallback(() => {
    const newValue = !autoMode;
    setAutoMode(newValue);
    form.setFormValues({
      [isLedgerEnabledHiddenFieldName]: newValue,
    } as LedgerFieldFormValues<T, HiddenFieldName>);
  }, [autoMode, form, isLedgerEnabledHiddenFieldName]);

  useEffect(() => {
    if (!canUseLedger) {
      return;
    }

    if (autoMode) {
      form.setFormValues({ [isLedgerEnabledHiddenFieldName]: true } as LedgerFieldFormValues<T, HiddenFieldName>);
    } else {
      // If we exit auto mode, we want to prefill the field with the next number
      fetch();
    }

    form.setFormValues({
      [name]: autoMode ? '' : formattedNextNumber,
    } as LedgerFieldFormValues<T, HiddenFieldName>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoMode, canUseLedger, isLedgerEnabledHiddenFieldName, name]);

  return (
    <>
      <HiddenField {...isLedgerEnabledHiddenField} />
      <InputGroup size="md">
        <TextField<LedgerFieldFormValues<any, HiddenFieldName>>
          data-testid="textField:invoiceNumber"
          name={name}
          label={label}
          isRequired={isRequired}
          isDisabled={canUseLedger && autoMode}
          labelProps={{
            _empty: {
              opacity: 1,
            },
          }}
          inputProps={{
            _disabled: {
              opacity: 1,
              _placeholder: {
                color: 'gray.500',
              },
            },
          }}
          _disabled={{ opacity: 1 }}
          isReadOnly={isReadOnly}
          placeholder={canUseLedger && autoMode ? t('ledger:ledgerField.autoGenerated') : ''}
          rightIcon={
            <Box display="flex" flexDirection="row" alignItems="center">
              {loading && <CircularProgress isIndeterminate size={4} color="gray.800" />}
              {canUseLedger && (
                <Box
                  px={2}
                  _hover={{
                    background: 'gray.100',
                    cursor: 'pointer',
                    borderRadius: '8px',
                  }}
                  onClick={toggleAutoNumbering}
                >
                  {autoMode ? <SimpleEditIcon boxSize={4} /> : <SimpleRepeatIcon boxSize={4} />}
                </Box>
              )}
            </Box>
          }
          {...props}
        >
          {children}
        </TextField>
      </InputGroup>
    </>
  );
};
