import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IUpdateTotalMarginDTO } from '@graneet/business-logic';

import * as lotApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useEditLotMarginSellSheet = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (lotId: number, dto: IUpdateTotalMarginDTO) => {
      if (!dto) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        return;
      }

      const [err, result] = await lotApi.updateLotMarginSellSheet(lotId, dto);

      if (err) {
        toast.error(t('quote:lotEdition.lotSalesSheetDrawer.errors.marginUpdateFailed'));
        return;
      }

      toast.success(t('quote:lotEdition.lotSalesSheetDrawer.successes.marginUpdated'));

      updateDataLocally(result);
    },
    [toast, t, updateDataLocally],
  );
};
