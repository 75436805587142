import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, VStack } from '@chakra-ui/react';
import { Form, useStepForm } from 'graneet-form';
import { Fieldset, TextField } from '@graneet/lib-ui';

import { Rule } from 'features/form/rules/Rule';
import { UserRoleFieldSet } from 'features/user/components/UserRoleFieldSet';
import type {
  UserInvitationFormValues,
  UserInvitationWizardValues,
} from 'features/onboarding/constants/user-invitation.constant';
import { IsInviteeEmailAvailable } from 'features/onboarding/forms/IsInviteeEmailAvailable';

export const InviteUserStep: FC = () => {
  const { t } = useTranslation(['onboarding']);

  const { form } = useStepForm<UserInvitationWizardValues, 'invite-user'>();

  return (
    <Form form={form}>
      <VStack spacing={6} align="stretch">
        <Flex maxWidth="70rem">
          <Fieldset<UserInvitationFormValues>
            handle="user"
            title={t('onboarding:invitation.userInvitationInformation.title')}
            legend={t('onboarding:invitation.userInvitationInformation.legend')}
            validationNames={['email']}
          >
            <TextField<UserInvitationFormValues> name="email" isRequired label={t('onboarding:fields.email')}>
              <Rule.IsEmail />
              <Rule.IsRequired />
              <IsInviteeEmailAvailable />
            </TextField>
          </Fieldset>
        </Flex>

        <UserRoleFieldSet user={null} />
      </VStack>
    </Form>
  );
};
