import type { FC } from 'react';

import { actionTranslations } from '../ActionMenuTranslations';
import { SimpleDeleteIcon } from '../../Icons';

import type { ActionProps } from './Action';
import { Action } from './Action';

export interface ActionDelete extends Omit<ActionProps, 'icon' | 'label' | 'warning'> {
  label?: Pick<ActionProps, 'label'>['label'];
}

export const Delete: FC<ActionDelete> = ({ label, ...otherProps }) => (
  <Action
    icon={<SimpleDeleteIcon stroke="red.500" />}
    label={label || actionTranslations.delete}
    warning
    {...otherProps}
  />
);
