import type { FC } from 'react';
import { Button, DrawersStack, SimpleAddIcon, useDrawer, useDrawersStack } from '@graneet/lib-ui';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnBlurValues } from 'graneet-form';

import type { InvoiceEditionForm } from '../forms/invoice-edition-form';

import { InvoiceAmountsSummary } from './InvoiceAmountsSummary';
import { InvoiceEditDiscountFormModal } from './modals/InvoiceEditDiscountFormModal';
import { InvoiceAddItemsButtons } from './InvoiceAddItmesButtons';

export const InvoiceFooter: FC = () => {
  const { t } = useTranslation(['invoice']);
  const drawersStack = useDrawersStack<'discount'>();
  const drawer = useDrawer('discount', drawersStack);

  const form = useFormContext<InvoiceEditionForm>();
  const { discountType } = useOnBlurValues(form, ['discountType']);

  return (
    <Flex mt={2} justifyContent="space-between">
      <InvoiceAddItemsButtons />
      <Flex direction="column" alignItems="flex-end" gap={2}>
        <Flex direction="column" alignItems="flex-end" gap={2}>
          {!discountType && (
            <Button leftIcon={<SimpleAddIcon />} variant="outline" onClick={drawer.onOpen}>
              {t('invoice:steps.container.addDiscount')}
            </Button>
          )}
          <InvoiceAmountsSummary onOpenDiscount={drawer.onOpen} />
        </Flex>

        <DrawersStack drawersStack={drawersStack}>
          <InvoiceEditDiscountFormModal drawer={drawer} />
        </DrawersStack>
      </Flex>
    </Flex>
  );
};
