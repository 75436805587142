import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepperModal, useToast } from '@graneet/lib-ui';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { QUOTE_STATUS, type IProject } from '@graneet/business-logic';
import type { QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';
import { Quote, QuoteStateMachine } from '@org/quotation-lib';

import { SelectSubProjectStep } from './SelectSubProjectStep';
import { SelectProjectStep } from './SelectProjectStep';
import { SelectSettledDateStep } from './SelectSettledDateStep';
import type { QuoteAcceptModalWizard } from './types';

import { useSubProjectsByProjectQuery } from 'features/sub-project/services/sub-project.api';
import { quoteWithoutRelationShipToQuoteObjectMapper } from 'features/quotation/quote-common/mappers/quoteComposeToQuoteObjectMapper';

type AcceptQuoteModalProps = {
  onAssociateSubProject(projectId: number, subProjectId: string | null, settledDate: string): Promise<void>;
  onCreateProject(settledDate: string): void;
  project: IProject | null; // project will be defined only if we are viewing the modal from the project screen
  modalControls: UseDisclosureReturn;
  quote: QuoteWithoutRelationsDTO;
};

export const AcceptQuoteModal: FC<AcceptQuoteModalProps> = ({
  modalControls,
  onCreateProject,
  onAssociateSubProject,
  project,
  quote,
}) => {
  const { t } = useTranslation(['global', 'quote']);
  const toast = useToast();

  const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>(project?.id);

  const subProjectsOfSelectedProject = useSubProjectsByProjectQuery(selectedProjectId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onFinish = useCallback(
    async (values: QuoteAcceptModalWizard) => {
      // Execute machine transition validation
      const quoteObject = quoteWithoutRelationShipToQuoteObjectMapper(quote);
      const quoteStateMachine = QuoteStateMachine.execute(
        new Quote(
          {
            isAutomaticIndexActivated: quote.isAutomaticIndexActivated,
          },
          { ...quoteObject, projectId: selectedProjectId, settledAt: values.accept.settledDate },
        ),
      );

      quoteStateMachine.send({ type: 'ACCEPT_QUOTE' });
      const transitionSuccess = quoteStateMachine.getSnapshot().value === QUOTE_STATUS.ACCEPTED;
      if (!transitionSuccess) {
        toast.error(t('global:errors.error'));
        return;
      }

      setIsSubmitting(true);
      await onAssociateSubProject(selectedProjectId!, values.subProject?.subProjectId, values.accept.settledDate);
      setIsSubmitting(false);
    },
    [onAssociateSubProject, quote, selectedProjectId, t, toast],
  );

  return (
    <StepperModal<QuoteAcceptModalWizard> {...modalControls} size="4xl" onFinish={onFinish}>
      <StepperModal.Step<QuoteAcceptModalWizard> name="accept" title={t(`quote:acceptQuoteProjectModal.steps.ACCEPT`)}>
        <SelectSettledDateStep isLoading={subProjectsOfSelectedProject.isLoading || isSubmitting} />
      </StepperModal.Step>

      {/* Display this step only if there is no project already selected */}
      {!project && (
        <StepperModal.Step<QuoteAcceptModalWizard>
          name="associate"
          title={t(`quote:acceptQuoteProjectModal.steps.ASSOCIATE`)}
        >
          <SelectProjectStep
            onClose={modalControls.onClose}
            onSelectProject={setSelectedProjectId}
            onCreateProject={onCreateProject}
            isLoading={subProjectsOfSelectedProject.isLoading}
          />
        </StepperModal.Step>
      )}

      {subProjectsOfSelectedProject.data && subProjectsOfSelectedProject.data.length > 0 && (
        <StepperModal.Step<QuoteAcceptModalWizard>
          name="subProject"
          title={t(`quote:acceptQuoteProjectModal.steps.SUB_PROJECT`)}
        >
          <SelectSubProjectStep
            subProjects={subProjectsOfSelectedProject.data}
            quote={quote}
            isSubmitting={isSubmitting}
          />
        </StepperModal.Step>
      )}
    </StepperModal>
  );
};
