import { Card, ConfirmDeletionModal, useToast } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';
import { useDisclosure } from '@chakra-ui/react';

import { ContactUpsertModal } from '../modals/ContactUpsertModal';
import { ContactAvailableSupplierModal } from '../modals/ContactAvailableSupplierModal';
import { ContactAddOrAssociateButton } from '../ContactAddOrAssociateButton';

import { ContactCardDetails } from './ContactCardDetails';

import {
  useContactAssociatedToSupplier,
  useContactDelete,
  useContactDissociateFromSupplier,
  useContactRoles,
  useContactsAssociateToSupplier,
} from 'features/contact/services/contact.api';

interface ContactCardSupplierProps {
  supplierId: number;
}

export const ContactCardSupplier: FC<ContactCardSupplierProps> = ({ supplierId }) => {
  const upsertModal = useDisclosure();
  const deletionModal = useDisclosure();
  const associationModal = useDisclosure();

  const contacts = useContactAssociatedToSupplier(supplierId);
  const contactRoles = useContactRoles();

  const contactDeleteMutation = useContactDelete();
  const contactsAssociateToSupplierMutation = useContactsAssociateToSupplier();
  const contactDissociateFromSupplierMutation = useContactDissociateFromSupplier();

  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const [contactUpsert, setContactUpsert] = useState<IContact | null>(null);
  const [contactToDelete, setContactToDelete] = useState<IContact | null>(null);

  const onUpsert = useCallback(
    (contact?: IContact) => {
      if (contact) {
        setContactUpsert(contact);
      } else {
        setContactUpsert(null);
      }
      upsertModal.onOpen();
    },
    [upsertModal],
  );

  const onDissociate = useCallback(
    async (contactId: string) => {
      await contactDissociateFromSupplierMutation.mutateAsync({ contactId, supplierId });
      toast.success(t('contacts:card.toastDissociateSuccess'));
    },
    [contactDissociateFromSupplierMutation, supplierId, t, toast],
  );

  const onDelete = useCallback(
    async (contact: IContact) => {
      if (contact) {
        setContactToDelete(contact);
      } else {
        setContactToDelete(null);
      }
      deletionModal.onOpen();
    },
    [deletionModal],
  );

  const onWorkerDeletion = useCallback(async () => {
    if (contactToDelete) {
      await contactDeleteMutation.mutateAsync(contactToDelete);
      deletionModal.onClose();
    }
  }, [contactToDelete, contactDeleteMutation, deletionModal]);

  const onUpsertSuccess = useCallback(
    async (contact: IContact) => {
      await contactsAssociateToSupplierMutation.mutateAsync({
        id: supplierId,
        dto: {
          contacts: [{ id: contact.id }],
        },
      });
    },
    [contactsAssociateToSupplierMutation, supplierId],
  );

  return (
    <Card
      title={t('contacts:card.title')}
      topRightContent={<ContactAddOrAssociateButton onAssociate={associationModal.onOpen} onCreate={onUpsert} />}
    >
      <ContactCardDetails
        contacts={contacts.data}
        isContactDefaultEmailRecipient={() => false}
        onUpsert={onUpsert}
        onDelete={onDelete}
        onDissociate={onDissociate}
        emptyState={t('contacts:emptyState.supplier')}
      />
      <ContactUpsertModal
        isOpen={upsertModal.isOpen}
        onSuccess={onUpsertSuccess}
        onClose={upsertModal.onClose}
        contact={contactUpsert}
        roles={contactRoles.data}
        hasIsDefaultEmailRecipient={false}
      />
      <ConfirmDeletionModal
        isOpen={deletionModal.isOpen}
        onClose={deletionModal.onClose}
        title={t('contacts:deletionModal.title')}
        action={t('contacts:deletionModal.action')}
        description={t('contacts:deletionModal.description')}
        onDeleted={onWorkerDeletion}
      />
      <ContactAvailableSupplierModal
        isOpen={associationModal.isOpen}
        onClose={associationModal.onClose}
        title={t('contacts:availableModal.title')}
        supplierId={supplierId}
      />
    </Card>
  );
};
