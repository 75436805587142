import { v4 as uuid } from 'uuid';

import { PROGRESS_STATEMENT_STATUSES } from '../progress-statement/progress-statement.type';
import { formatDateOrEmpty } from '../common/date.util';
import { sumObjects } from '../common/math.util';
import { isNumberFinite } from '../common/number.util';
import type { RequiredByKeys } from '../common/types/object.type';
import {
  computeAmountOfVATBase,
  getTotalVATAmountFromVATDistribution,
  mergeVATDistribution,
} from '../vat/vat-rates.util';
import type { IProject } from '../project/project.type';
import type { IOrder } from '../order/order.type';

import type { ISupplierInvoicePayment } from './supplier-invoice-payment.type';
import type { ISupplierInvoice, SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from './supplier-invoice.type';
import { SUPPLIER_INVOICE_MODE, SUPPLIER_INVOICE_STATUS } from './supplier-invoice.type';
import type { ISupplierInvoiceResponseDTO } from './dtos/supplier-invoice-response.dto';
import type { ISupplierInvoiceOCRResponse } from './ocr-response.type';
import type { ISupplierInvoiceItem } from './supplier-invoice-item.type';

export const formatSupplierInvoiceFileName = (supplierInvoice: ISupplierInvoice): string =>
  [supplierInvoice?.invoiceNumber, supplierInvoice?.supplier?.name, formatDateOrEmpty(supplierInvoice.billingDate)]
    .filter(Boolean)
    .join(' - ')
    .replace(/\//g, '-');

export function computeSupplierInvoiceAmounts(
  supplierInvoice:
    | Pick<ISupplierInvoice, 'vatDistribution' | 'amountExVAT'>
    | { items: Array<Partial<Pick<ISupplierInvoiceItem, 'amountExVAT' | 'vatRate'>>> },
): Pick<ISupplierInvoice, 'vatDistribution' | 'amountExVAT' | 'amountIncVAT' | 'remainingToBePaidIncVAT'> {
  if ('amountExVAT' in supplierInvoice) {
    const amountIncVAT = (supplierInvoice.amountExVAT ?? 0) + sumObjects(supplierInvoice.vatDistribution, 'amount');
    return {
      amountExVAT: supplierInvoice.amountExVAT,
      vatDistribution: supplierInvoice.vatDistribution,
      amountIncVAT,
      remainingToBePaidIncVAT: amountIncVAT,
    };
  }

  const vatDistributionWithoutAmounts: ISupplierInvoice['vatDistribution'] = mergeVATDistribution(
    supplierInvoice.items
      .filter((item) => isNumberFinite(item.vatRate))
      .map((item) => ({
        base: item.amountExVAT ?? 0,
        vatRate: item.vatRate ?? 0,
        amount: 0,
      })),
  );

  const amountExVAT = supplierInvoice.items.reduce((acc, item) => acc + (item.amountExVAT || 0), 0);

  const vatDistribution = vatDistributionWithoutAmounts.map((vatD) => ({
    ...vatD,
    amount: computeAmountOfVATBase(vatD),
  }));
  const vatSum = getTotalVATAmountFromVATDistribution(vatDistribution);
  const amountIncVAT = amountExVAT + vatSum;

  return {
    amountExVAT,
    vatDistribution,
    amountIncVAT,
    remainingToBePaidIncVAT: amountIncVAT,
  };
}

export const computeSupplierInvoiceRemainingToBePaidIncVAT = (
  supplierInvoice: ISupplierInvoice,
  supplierInvoicePayments: ISupplierInvoicePayment[],
): number => supplierInvoice.amountIncVAT - sumObjects(supplierInvoicePayments, 'amountPaidIncVAT');

/**
  Handle payments when supplier invoice amount is negative or not:
  - when the first part is false, payment is opposite sign of amountIncVAT, so it cannot be total payment
  - then, is amountPaidIncVAT is equal or greater than amountIncVAT, payment is total
*/
export const isSupplierInvoicePaymentTotal = (
  supplierInvoicePayment: Pick<ISupplierInvoicePayment, 'amountPaidIncVAT'>,
  supplierInvoice: Pick<ISupplierInvoice, 'amountIncVAT'>,
): boolean =>
  Math.sign(supplierInvoice.amountIncVAT) === Math.sign(supplierInvoicePayment.amountPaidIncVAT) &&
  Math.abs(supplierInvoicePayment.amountPaidIncVAT) >= Math.abs(supplierInvoice.amountIncVAT);

export const getNextStatusesAllowedForBatchActions = (
  currentSuppliersInvoicesStatus: SUPPLIER_INVOICE_STATUS,
  options: { canAddPayment?: boolean } = { canAddPayment: true },
): SUPPLIER_INVOICE_STATUS[] => {
  const nextStatusesAllowed: SUPPLIER_INVOICE_STATUS[] = [];

  if (
    currentSuppliersInvoicesStatus === SUPPLIER_INVOICE_STATUS.TO_PAY ||
    currentSuppliersInvoicesStatus === SUPPLIER_INVOICE_STATUS.TO_VALIDATE
  ) {
    nextStatusesAllowed.push(SUPPLIER_INVOICE_STATUS.TO_PROCESS);
  }
  if (currentSuppliersInvoicesStatus === SUPPLIER_INVOICE_STATUS.TO_PROCESS) {
    nextStatusesAllowed.push(SUPPLIER_INVOICE_STATUS.TO_PAY);
  }

  if ([SUPPLIER_INVOICE_STATUS.TO_PAY, SUPPLIER_INVOICE_STATUS.TO_PROCESS].includes(currentSuppliersInvoicesStatus)) {
    nextStatusesAllowed.push(SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID);
  }

  if (currentSuppliersInvoicesStatus === SUPPLIER_INVOICE_STATUS.TO_PROCESS && options.canAddPayment) {
    nextStatusesAllowed.push(SUPPLIER_INVOICE_STATUS.PAID);
  }

  if (
    [SUPPLIER_INVOICE_STATUS.TO_PAY, SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID].includes(currentSuppliersInvoicesStatus)
  ) {
    nextStatusesAllowed.push(SUPPLIER_INVOICE_STATUS.PAID);
  }

  return nextStatusesAllowed;
};

export const isDirectPaymentSupplierInvoice = (supplierInvoice?: Pick<ISupplierInvoice, 'status'> | null): boolean =>
  supplierInvoice?.status === SUPPLIER_INVOICE_STATUS.DIRECT_PAYMENT;

type SupplierInvoiceCannotBeUpdatedCause = 'accounting' | 'is-direct-payment-with-non-draft-progress-statement';
/**
 * Determines if a supplier invoice can be updated.
 *
 * @param {SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT} supplierInvoice - The supplier invoice to check.
 * @returns {{ ok: boolean; cause: SupplierInvoiceCannotBeUpdatedCause[] }} - An object indicating if the supplier invoice can be updated and the reasons if not.
 */
export const canSupplierInvoiceBeUpdated = (
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT,
): { ok: boolean; causes: SupplierInvoiceCannotBeUpdatedCause[] } => {
  const causes: SupplierInvoiceCannotBeUpdatedCause[] = [];

  /*
    If it has accounting export
   */
  if (supplierInvoice.isAccountingExported) {
    causes.push('accounting');
  }

  /*
    If it's a direct payment and the progress statement is not draft
   */
  if (
    supplierInvoice.progressStatement &&
    isDirectPaymentSupplierInvoice(supplierInvoice) &&
    supplierInvoice.progressStatement?.status !== PROGRESS_STATEMENT_STATUSES.DRAFT
  ) {
    causes.push('is-direct-payment-with-non-draft-progress-statement');
  }

  return {
    ok: causes.length === 0,
    causes,
  };
};

export type SupplierInvoiceCannotBeDeletedCause = 'accounting' | 'is-direct-payment-with-non-draft-progress-statement';
/**
 * Determines if a supplier invoice can be deleted.
 * If it cannot be deleted, it provides the cause(s) of why it cannot be deleted.
 *
 * @param {SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT} supplierInvoice - The supplier invoice to check for deletion eligibility.
 *
 * @returns {object} - An object containing the result of the deletion eligibility and the cause(s) if not eligible.
 *   - ok {boolean} - Indicates if the supplier invoice can be deleted (true) or not (false).
 *   - cause {Array} - An array of string values representing the cause(s) why the supplier invoice cannot be deleted.
 */
export const canSupplierInvoiceBeDeleted = (
  supplierInvoice: Pick<
    ISupplierInvoiceResponseDTO,
    'isAccountingExported' | 'hasPaymentAccountingExported' | 'progressStatement' | 'status'
  >,
): { ok: boolean; causes: SupplierInvoiceCannotBeDeletedCause[] } => {
  const causes: SupplierInvoiceCannotBeDeletedCause[] = [];

  /*
    If it has accounting export or has payement with accounting export
   */
  if (supplierInvoice.isAccountingExported || supplierInvoice.hasPaymentAccountingExported) {
    causes.push('accounting');
  }

  /*
    If it's a direct payment and the progress statement is not draft
   */
  if (
    supplierInvoice.progressStatement &&
    isDirectPaymentSupplierInvoice(supplierInvoice) &&
    supplierInvoice.progressStatement?.status !== PROGRESS_STATEMENT_STATUSES.DRAFT
  ) {
    causes.push('is-direct-payment-with-non-draft-progress-statement');
  }

  return {
    ok: causes.length === 0,
    causes,
  };
};

export const mapSupplierInvoiceOCRResponseToSupplierInvoice = (
  supplierInvoiceOCRResponse: ISupplierInvoiceOCRResponse,
): Partial<{
  invoiceNumber: string;
  billingDate: Date;
  paymentDate: Date;
  vatRate: number;
  amountExVAT: number;
  supplier: { id: number };
  orders: IOrder[] | undefined;
  project: RequiredByKeys<IProject, 'primaryClient'> | undefined;
  items: Array<{
    id: string;
    code: string | undefined;
    description: string | undefined;
    unitAmountExVAT: number | undefined;
    quantity: number | undefined;
    vatRate: number | undefined;
    amountExVAT: number | undefined;
  }>;
}> => ({
  invoiceNumber: supplierInvoiceOCRResponse.invoiceNumber ?? undefined,
  billingDate: supplierInvoiceOCRResponse.billingDate ?? undefined,
  paymentDate: supplierInvoiceOCRResponse.paymentDate ?? undefined,
  vatRate: supplierInvoiceOCRResponse.vatDistribution?.[0]
    ? supplierInvoiceOCRResponse.vatDistribution[0].vatRate
    : undefined,
  amountExVAT: isNumberFinite(supplierInvoiceOCRResponse.amountExVAT)
    ? supplierInvoiceOCRResponse.amountExVAT
    : undefined,
  supplier: supplierInvoiceOCRResponse.exactMatchingSupplierId
    ? {
        id: supplierInvoiceOCRResponse.exactMatchingSupplierId,
      }
    : undefined,
  orders: supplierInvoiceOCRResponse.exactMatchingOrders ? supplierInvoiceOCRResponse.exactMatchingOrders : undefined,
  project: supplierInvoiceOCRResponse.exactMatchingProject
    ? supplierInvoiceOCRResponse.exactMatchingProject
    : undefined,
  items: supplierInvoiceOCRResponse.items.map((item) => ({
    id: uuid(),
    ...item,
  })),
});
export function isSupplierInvoiceIncomplete(
  supplierInvoice: Pick<
    RequiredByKeys<ISupplierInvoice, 'type' | 'supplier' | 'supplierInvoiceItems'>,
    'name' | 'billingDate' | 'amountExVAT' | 'type' | 'supplier' | 'mode' | 'supplierInvoiceItems'
  >,
): boolean {
  // Every supplier invoice must respect this condition
  if (!supplierInvoice.name || !supplierInvoice.billingDate || !supplierInvoice.supplier) {
    return true;
  }

  // Detailed ones must have all they items completed
  if (supplierInvoice.mode === SUPPLIER_INVOICE_MODE.DETAILED) {
    const isItemCompleted = (item: ISupplierInvoiceItem): boolean =>
      !item.description ||
      !isNumberFinite(item.quantity) ||
      !isNumberFinite(item.unitAmountExVAT) ||
      !isNumberFinite(item.amountExVAT) ||
      !isNumberFinite(item.vatRate) ||
      !item.type;
    return supplierInvoice.supplierInvoiceItems.some(isItemCompleted);
  }

  return !isNumberFinite(supplierInvoice.amountExVAT) || !supplierInvoice.type;
}
