import type { IClient, IClientResponseDTO, IContact } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Onboarding, SimpleContactIcon, useToast } from '@graneet/lib-ui';
import { HStack, useDisclosure } from '@chakra-ui/react';
import type { QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';

import {
  ClientCardContactAssociateType,
  ClientInformationsTab,
} from 'screens/contacts/clients/_tabs/information/ClientInformationsTab';
import { ClientCardTypeEnum } from 'features/client/components/cards/ClientCardDetails';
import {
  ClientContactSelectionStepperModal,
  type ClientContactSelectionStepperModalProps,
} from 'features/client/components/modals/ClientExistingAssociateContactStepperModal/ClientContactSelectionStepperModal';
import { ClientContactCreateStepperModal } from 'features/client/components/modals/ClientContactCreateStepperModal/ClientContactCreateStepperModal';
import { getContactAvailableForQuote } from 'features/contact/services/contact.api';
import { useQuotationApi } from 'features/quotation/services/quote.api';

interface ViewQuoteClientInformationTabProps {
  quote: QuoteWithoutRelationsDTO;
}

export const ViewQuoteClientInformationTab: FC<ViewQuoteClientInformationTabProps> = ({ quote }) => {
  const { t } = useTranslation(['quote', 'clients', 'global', 'contacts']);
  const toast = useToast();
  const [currentClient, setCurrentClient] = useState<IClientResponseDTO | IClient | undefined>(undefined);

  const { useGetClientById, useQuotePartialUpdate, lazyGetClientById } = useQuotationApi();

  const { data: client } = useGetClientById(quote.clientId);
  const quotePartialUpdateMutation = useQuotePartialUpdate();

  useEffect(() => {
    if (client) {
      setCurrentClient(client);
    }
  }, [client]);

  const associateModal = useDisclosure();
  const createModal = useDisclosure();

  const handleClientAssociationOnClose = useCallback(async () => {
    associateModal.onClose();
  }, [associateModal]);

  const handleCreateOnClose = useCallback(async () => {
    createModal.onClose();
  }, [createModal]);

  const onClientCreated = useCallback(
    async (clientResponse: IClientResponseDTO) => {
      if (clientResponse) {
        await quotePartialUpdateMutation.mutateAsync({ quoteId: quote.id, clientId: clientResponse.id });
        setCurrentClient(clientResponse);
        toast.success(t(`quote:cards.client.${clientResponse.id ? 'successLink' : 'successUnlink'}`));
      }
    },
    [quote.id, quotePartialUpdateMutation, t, toast],
  );

  const onContactCreated = useCallback(
    async (contact: IContact) => {
      await quotePartialUpdateMutation.mutateAsync({
        quoteId: quote.id,
        contacts: [{ id: contact.id, isDefaultEmailRecipient: false }],
      });
    },
    [quote.id, quotePartialUpdateMutation],
  );

  const updateUniqueClient = useCallback(
    async (clientId: number | null) => {
      await quotePartialUpdateMutation.mutateAsync({ quoteId: quote.id, clientId });
      toast.success(t(`quote:cards.client.${clientId ? 'successLink' : 'successUnlink'}`));
      if (!clientId) {
        return null;
      }
      return lazyGetClientById(clientId);
    },
    [lazyGetClientById, quote.id, quotePartialUpdateMutation, t, toast],
  );

  const updateContactsAssociations = useCallback(async () => {
    await quotePartialUpdateMutation.mutateAsync({ quoteId: quote.id, contacts: [] });
  }, [quote, quotePartialUpdateMutation]);

  const onClientSelected = useCallback(
    async (clientId: number) => {
      const newClient = await updateUniqueClient(clientId);

      if (newClient) {
        setCurrentClient(newClient);
      }

      return newClient;
    },
    [updateUniqueClient],
  );

  const removeClient = useCallback(async () => {
    await updateUniqueClient(null);
    await updateContactsAssociations();
  }, [updateUniqueClient, updateContactsAssociations]);

  const onContactSelected = useCallback(
    async (contacts: Array<{ entity: IContact; isDefaultEmailRecipient: boolean }>) => {
      await quotePartialUpdateMutation.mutateAsync({
        quoteId: quote.id,
        contacts: contacts.map((contact) => ({
          id: contact.entity.id,
          isDefaultEmailRecipient: contact.isDefaultEmailRecipient,
        })),
      });

      if (contacts.length > 1) {
        toast.success(t('contacts:availableModal.toastSuccessManyAssociation', { nbContact: contacts.length }));
      } else {
        toast.success(t('contacts:availableModal.toastSuccessOneAssociation'));
      }
    },
    [quote.id, quotePartialUpdateMutation, t, toast],
  );

  const contactsRetriever = useCallback<ClientContactSelectionStepperModalProps<IContact>['contactsRetriever']>(
    () => [{ quoteId: quote.id }, getContactAvailableForQuote],
    [quote.id],
  );

  if (client && currentClient) {
    return (
      <Container size="md">
        <ClientInformationsTab
          associationType={ClientCardContactAssociateType.QUOTE}
          client={client}
          type={ClientCardTypeEnum.CHANGE}
          onClientCardButtonClicked={removeClient}
          onClientUpdate={updateUniqueClient}
          quoteId={quote.id}
          quoteStatus={quote.status}
        />
      </Container>
    );
  }

  return (
    <>
      <Onboarding
        message={t('quote:completeModal.error.client')}
        icon={<SimpleContactIcon boxSize={45} />}
        mb={8}
        mt={16}
        h="auto"
        action={
          <HStack gap={4}>
            <Button onClick={associateModal.onOpen} variant="outline">
              {t('quote:clientStep.cta.clientExisting')}
            </Button>
            <Button onClick={createModal.onOpen} colorScheme="blue" marginLeft="auto">
              {t('clients:createClient')}
            </Button>
          </HStack>
        }
      />
      <ClientContactSelectionStepperModal
        isOpen={associateModal.isOpen}
        onClose={handleClientAssociationOnClose}
        onClientSelected={onClientSelected}
        onSubmit={onContactSelected}
        contactsRetriever={contactsRetriever}
        isContactDefaultEmailRecipient={(contact) => contact.isDefaultEmailRecipient}
      />
      <ClientContactCreateStepperModal
        isOpen={createModal.isOpen}
        onClose={handleCreateOnClose}
        onClientCreated={onClientCreated}
        onContactCreated={onContactCreated}
      />
    </>
  );
};
