import type {
  IOrderCreationDTO,
  IUpdateOrderStatusDTO,
  ORDER_STATUS,
  IOrderUpdateDTO,
  IOrderResponseDTO,
  OrderPaginatedResponse,
  IOrderWithRelations,
  IUpdateTagsDTO,
  IOrder,
  IOrderUpdateStatusesDTO,
} from '@graneet/business-logic';
import { PAGINATION_PARAMS, SUPPORT_EMAIL, buildOrderFileName } from '@graneet/business-logic';
import type { QueryClient } from '@tanstack/react-query';
import { useQuery, useSuspenseQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { type UsePaginationOptions, usePaginationQuery, useToast } from '@graneet/lib-ui';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMemo } from 'react';

import { apiNew } from 'features/api/services/apiNew.service';
import { invalidateStatementRelatedQueries } from 'features/common/services/invalidate-statement-related-queries.util';
import { dtoToFormData } from 'features/api/services/api.util';

const FACTORY_NAME = 'supplier-invoice';
const ORDERS_PATH = '/orders';

async function invalidateOrderKeyFactory(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [FACTORY_NAME],
  });
}

const orderKeyFactory = createQueryKeys(FACTORY_NAME, {
  get: (queryParams: URLSearchParams) => ({
    queryKey: [ORDERS_PATH, queryParams],
    queryFn: () => apiNew.get<URLSearchParams, OrderPaginatedResponse<false>>(ORDERS_PATH, queryParams),
  }),
  getWithDirectPayments: (queryParams: Record<string, any>) => ({
    queryKey: [ORDERS_PATH, queryParams],
    queryFn: () => apiNew.get<Record<string, any>, OrderPaginatedResponse<true>>(ORDERS_PATH, queryParams),
  }),
  getOne: (id: number) => ({
    queryKey: [ORDERS_PATH, id],
    queryFn: () => apiNew.get<never, IOrderResponseDTO>(`${ORDERS_PATH}/${id}`),
  }),
  getOrderOrUndefined: (id: number | undefined) => ({
    queryKey: [ORDERS_PATH, id],
    queryFn: () => {
      if (id) {
        return apiNew.get<never, IOrderResponseDTO>(`${ORDERS_PATH}/${id}`);
      }
      return Promise.resolve(null);
    },
  }),
  getAvailableTags: () => ({
    queryKey: [ORDERS_PATH],
    queryFn: () => apiNew.get<never, string[]>(`${ORDERS_PATH}/tags`),
  }),
});

// -- Query
export function useOrders(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery((urlSearchParams) => queryClient.fetchQuery(orderKeyFactory.get(urlSearchParams)), options);
}

export function useOrdersWithDirectPayments(options: {
  projectId: number;
  subProjectId: string;
  progressStatementId?: number;
}) {
  const finalOptions = useMemo(
    () => ({
      projectId: options.projectId,
      subProjectId: options.subProjectId,
      progressStatementId: options.progressStatementId,
      isDirectPayment: true,
      [PAGINATION_PARAMS.FULL]: true,
    }),
    [options.progressStatementId, options.projectId, options.subProjectId],
  );
  return useSuspenseQuery(orderKeyFactory.getWithDirectPayments(finalOptions));
}

export function useOrdersQuery(urlSearchParams: URLSearchParams, options?: { enabled?: boolean }) {
  return useQuery({ ...orderKeyFactory.get(urlSearchParams), ...options });
}

export function useOrder(id: number) {
  return useSuspenseQuery(orderKeyFactory.getOne(id));
}

export function useOrderOrUndefined(id: number | undefined) {
  return useSuspenseQuery(orderKeyFactory.getOrderOrUndefined(id));
}

export function useOrderAvailableTags() {
  return useSuspenseQuery(orderKeyFactory.getAvailableTags());
}

// -- Mutation
export function useOrderEditTags() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateTagsDTO }) =>
      apiNew.patch<IUpdateTagsDTO, IOrder>(`${ORDERS_PATH}/${params.id}/tags`, params.dto),
    onError: () => {
      toast.error(t('global:changeTags.error'));
    },
    onSuccess: async () => {
      await invalidateOrderKeyFactory(queryClient);
      toast.success(t('global:changeTags.success'));
    },
  });
}

export function useOrderCreate(type: 'creation' | 'duplication') {
  const { t } = useTranslation(['global', 'orders']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { dto: IOrderCreationDTO; orderFiles?: { file: File; isDisplayInOrderPdf: boolean }[] }) => {
      const formData = dtoToFormData(params.dto);
      params.orderFiles?.forEach((fileWithData, index) => {
        formData.append(buildOrderFileName(index), fileWithData.file);
      });
      const isOrderFilesDisplayedInPDF = (params.orderFiles || []).map(
        (fileWithData) => fileWithData.isDisplayInOrderPdf,
      );
      formData.append('isOrderFilesDisplayedInPDF', JSON.stringify(isOrderFilesDisplayedInPDF));

      return apiNew.postWithMultiPart<FormData, IOrderWithRelations>(ORDERS_PATH, formData);
    },
    onError: () => {
      toast.error(t('global:changeTags.error'));
    },
    onSuccess: async (order) => {
      await invalidateOrderKeyFactory(queryClient);

      if (type === 'creation') {
        toast.success(t(`orders:create.success`, { name: order.name }));
      } else {
        toast.success(t('orders:duplication.toastSuccess', { name: order.name }));
      }
    },
  });
}

export function useOrderUpdate() {
  const { t } = useTranslation(['global', 'orders']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: {
      id: number;
      dto: IOrderUpdateDTO;
      orderFiles?: { file: File; isDisplayInOrderPdf: boolean }[];
    }) => {
      const formData = dtoToFormData(params.dto);
      params.orderFiles?.forEach((fileWithData, index) => {
        formData.append(buildOrderFileName(index), fileWithData.file);
      });
      const isOrderFilesDisplayedInPDF = (params.orderFiles || []).map(
        (fileWithData) => fileWithData.isDisplayInOrderPdf,
      );
      formData.append('isOrderFilesDisplayedInPDF', JSON.stringify(isOrderFilesDisplayedInPDF));

      return apiNew.patchWithMultiPart<FormData, IOrderWithRelations>(`${ORDERS_PATH}/${params.id}`, formData);
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (order) => {
      await invalidateOrderKeyFactory(queryClient);
      toast.success(t('orders:update.success', { name: order.name }));
    },
  });
}

export function useOrderDelete() {
  const { t } = useTranslation(['orders']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, void>(`${ORDERS_PATH}/${id}`),
    onError: () => {
      toast.error(t('orders:delete.error'));
    },
    onSuccess: async () => {
      await invalidateOrderKeyFactory(queryClient);
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('orders:delete.success'));
    },
  });
}

export function useOrderUpdateStatus() {
  const { t } = useTranslation(['global', 'orders']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateOrderStatusDTO }) =>
      apiNew.patch<IUpdateOrderStatusDTO, void>(`${ORDERS_PATH}/${params.id}/status`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('orders:toasts.statusUpdate'));
    },
  });
}

export function useOrdersUpdateStatuses() {
  const { t } = useTranslation(['global', 'orders']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { dto: IOrderUpdateStatusesDTO; status: ORDER_STATUS }) =>
      apiNew.patch<IOrderUpdateStatusesDTO, void>(`${ORDERS_PATH}/status/${params.status}`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, params) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(
        t('orders:updateAtStatus.toast', {
          /*
           * Hacky solution to display plural if `hasAllCheckboxesSelected` is selected
           */
          count: params.dto.hasAllSelected ? 2 : (params.dto.selectedItems?.length ?? 0),
        }),
      );
    },
  });
}
