type UmamiEvent = {
  feature: string;
  fromZone: string;
  action: string;
  entity: string;
};

declare global {
  interface Window {
    umami: {
      track: (event: string) => void;
    };
  }
}

export const umamiTrackEvent = (params: UmamiEvent) => {
  window.umami?.track(`[${params.feature}]-[${params.action}]-[${params.entity}]-[${params.fromZone}]`);
};
