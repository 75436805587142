import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { PERMISSION } from '@graneet/business-logic';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { DisplayNotFoundScreen } from '../DisplayNotFoundScreen';

import { ViewQuoteScreen } from './quotation/ViewQuoteScreen';
import { ViewQuoteDetailsScreen } from './quotation/details/ViewQuoteDetailsScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { ViewQuoteEditScreen } from 'screens/opportunities/quotation/ViewQuoteEditScreen';
import { Loading } from 'features/common/components/Loading';
import { Error } from 'features/common/components/Error';

export const QuotationRouter = () => {
  const { path } = useRouteMatch();

  const hasDisplayQuotesPermission = usePermissions([PERMISSION.DISPLAY_QUOTES]);

  if (!hasDisplayQuotesPermission) {
    return null;
  }

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <ViewQuoteScreen />
      </Route>

      <Route path={`${path}/:quoteId/edit`} exact>
        <QueryWrapper>
          <ViewQuoteEditScreen />
        </QueryWrapper>
      </Route>

      <Route path={`${path}/:quoteId`}>
        <Suspense fallback={<Loading />}>
          <ErrorBoundary fallback={<Error />}>
            <ViewQuoteDetailsScreen />
          </ErrorBoundary>
        </Suspense>
      </Route>

      <Route path="*">
        <DisplayNotFoundScreen />
      </Route>
    </Switch>
  );
};
