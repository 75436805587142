import type { QuoteNodeObject } from '@org/quotation-lib';
import type { ColDef } from '@ag-grid-community/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash-es';

import { QuoteAGGridType } from '../../constants/agGridConstants';

import { useAmountColumnDef } from './useAmountColumnDef';
import { useFlatCostAmountColumnDef } from './useFlatCostAmountColumnDef';
import { useQuantityColumnDef } from './useQuantityColumnDef';
import { useTotalMarginColumnDef } from './useTotalMarginColumnDef';
import { useUnitAmountColumnDef } from './useUnitAmountColumnDef';
import { useUnitColumnDef } from './useUnitColumnDef';
import { useUnitFlatCostAmountColumnDef } from './useUnitFlatCostAmountColumnDef';
import { useVatRateColumnDef } from './useVatRateColumnDef';
import { useWorkingDurationColumnDef } from './useWorkingDurationColumnDef';
import { useDenominationColumnDef } from './useDenominationColumnDef';
import { useActionColumnDef } from './useActionColumnDef';
import { useCodeColumnDef } from './useCodeColumnDef';
import { useLeftActionColumnDef } from './useLeftActionColumnDef';
import { useEmptyColumnDef } from './useEmptyColumnDef';
import { useRefCode } from './useRefCode';
import { useShareOfHiddenCostAmountColumnDef } from './useHiddenCostAmountColumnDef';
import { useCostAmountColumnDef } from './useCostAmountColumnDef';
import { useVatRateWithReversalLiabilityColumnDef } from './useVatRateWithReversalLiabilityColumnDef';
import { useImageColumnDef } from './useImageColumnDef';

import {
  defaultQuoteHiddenCostColumns,
  defaultQuoteRootColumns,
} from 'features/quotation/quote-common/store/quoteSettingSliceZustant';
import { useStore } from 'store/store';
import { useQuoteSettingsGetFromStoreOrThrow } from 'features/quotation/quote-common/hooks/useQuoteSettingsGetFromStore';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useColumnDefs(type: QuoteAGGridType) {
  const { t } = useTranslation(['quote']);
  const { quote } = useQuote();
  const { quoteRootColumns, quoteHiddenCostColumns } = useQuoteSettingsGetFromStoreOrThrow();
  const isEditable = useStore((state) => state.quoteEditable);
  const componentTypes = useStore((state) => state.quoteComponentTypes);

  const workforceComponentTypeIds = useMemo(
    () => componentTypes.filter((componentType) => componentType.isWorkforce).map((componentType) => componentType.id),
    [componentTypes],
  );

  const refCodeDef = useRefCode(isEditable);
  const codeColumnDef = useCodeColumnDef(type, quote.getIsAutomaticIndexActivated(), isEditable);
  const amountColumnDef = useAmountColumnDef(isEditable);
  const flatCostAmountColumnDef = useFlatCostAmountColumnDef();
  const quantityColumnDef = useQuantityColumnDef(isEditable);
  const totalMarginColumnDef = useTotalMarginColumnDef(isEditable);
  const unitAmountColumnDef = useUnitAmountColumnDef(isEditable);
  const unitColumnDef = useUnitColumnDef(isEditable, workforceComponentTypeIds, componentTypes);
  const unitFlatCostAmountColumnDef = useUnitFlatCostAmountColumnDef(isEditable);
  const shareOfHiddenCostAmountColumnDef = useShareOfHiddenCostAmountColumnDef();
  const costAmountColumnDef = useCostAmountColumnDef();
  const vatRateColumnDef = useVatRateColumnDef(isEditable);
  const vatRateWithReversalLiabilityColumnDef = useVatRateWithReversalLiabilityColumnDef();
  const workingDurationColumnDef = useWorkingDurationColumnDef();
  const denominationColumnDef = useDenominationColumnDef(isEditable);
  const actionColumnDef = useActionColumnDef(type);
  const leftActionColumnDef = useLeftActionColumnDef(type);
  const emptyCellDef = useEmptyColumnDef();
  const imageColumnDef = useImageColumnDef(isEditable);

  const getColumnDef = useCallback(
    (name: string) => {
      let column = (type === QuoteAGGridType.ROOT ? quoteRootColumns : quoteHiddenCostColumns).find(
        (col) => col.name === name,
      );
      if (!column) {
        column = (type === QuoteAGGridType.ROOT ? defaultQuoteRootColumns : defaultQuoteHiddenCostColumns).find(
          (col) => col.name === 'denomination',
        );
      }
      if (!column) {
        throw new Error(`Column ${name} not found`);
      }
      return {
        width: column.width,
        label: t(`quote:quotation.columns.${name}` as any),
        enabled: column.enabled,
        index: column.index,
      };
    },
    [quoteRootColumns, quoteHiddenCostColumns, type, t],
  );

  return useMemo(
    /**
     * The order of array is important, it will be used to define the order of columns in the grid
     */
    (): ColDef<QuoteNodeObject>[] => {
      const code = getColumnDef('code');
      const denomination = getColumnDef('denomination');
      const refCode = getColumnDef('refCode');
      const quantity = getColumnDef('quantity');
      const unit = getColumnDef('unit');
      const unitFlatCostAmount = getColumnDef('unitFlatCostAmount');
      const flatCostAmount = getColumnDef('flatCostAmount');
      const shareOfHiddenCostAmount =
        type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('shareOfHiddenCostAmount');
      const costAmount = getColumnDef('costAmount');
      const totalMargin = type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('totalMargin');
      const unitAmount = type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('unitAmount');
      const vatRate = type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('vatRate');
      const workingDuration = getColumnDef('workingDuration');
      const amount = type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('amount');
      const image = getColumnDef('image');

      if (type === QuoteAGGridType.HIDDEN_COST) {
        const sortedColumns = sortBy(
          [
            ...(refCode.enabled ? [refCodeDef(refCode.width, refCode.label, refCode.index)] : []),
            ...(denomination.enabled
              ? [denominationColumnDef(denomination.width, denomination.label, denomination.index)]
              : []),
            ...(quantity.enabled ? [quantityColumnDef(quantity.width, quantity.label, quantity.index)] : []),
            ...(unit.enabled ? [unitColumnDef(unit.width, unit.label, unit.index)] : []),
            ...(unitFlatCostAmount.enabled
              ? [
                  unitFlatCostAmountColumnDef(
                    unitFlatCostAmount.width,
                    unitFlatCostAmount.label,
                    unitFlatCostAmount.index,
                  ),
                ]
              : []),
            ...(flatCostAmount.enabled
              ? [flatCostAmountColumnDef(flatCostAmount.width, flatCostAmount.label, flatCostAmount.index)]
              : []),
            ...(workingDuration.enabled
              ? [workingDurationColumnDef(workingDuration.width, workingDuration.label, workingDuration.index)]
              : []),
          ],
          'index',
        );

        return [leftActionColumnDef(), ...sortedColumns, emptyCellDef(), actionColumnDef()];
      }

      if (type === QuoteAGGridType.ROOT) {
        const sortedColumns = sortBy(
          [
            ...(refCode.enabled ? [refCodeDef(refCode.width, refCode.label, refCode.index)] : []),
            ...(denomination.enabled
              ? [denominationColumnDef(denomination.width, denomination.label, denomination.index)]
              : []),
            ...(quantity.enabled ? [quantityColumnDef(quantity.width, quantity.label, quantity.index)] : []),
            ...(unit.enabled ? [unitColumnDef(unit.width, unit.label, unit.index)] : []),
            ...(unitFlatCostAmount.enabled
              ? [
                  unitFlatCostAmountColumnDef(
                    unitFlatCostAmount.width,
                    unitFlatCostAmount.label,
                    unitFlatCostAmount.index,
                  ),
                ]
              : []),
            ...(flatCostAmount.enabled
              ? [flatCostAmountColumnDef(flatCostAmount.width, flatCostAmount.label, flatCostAmount.index)]
              : []),
            ...(shareOfHiddenCostAmount!.enabled
              ? [
                  shareOfHiddenCostAmountColumnDef(
                    shareOfHiddenCostAmount!.width,
                    shareOfHiddenCostAmount!.label,
                    shareOfHiddenCostAmount!.index,
                  ),
                ]
              : []),
            ...(costAmount.enabled ? [costAmountColumnDef(costAmount.width, costAmount.label, costAmount.index)] : []),
            ...(totalMargin!.enabled
              ? [totalMarginColumnDef(totalMargin!.width, totalMargin!.label, totalMargin!.index)]
              : []),
            ...(unitAmount!.enabled
              ? [unitAmountColumnDef(unitAmount!.width, unitAmount!.label, unitAmount!.index)]
              : []),
            ...(vatRate!.enabled
              ? [
                  quote.getHasReversalOfLiability()
                    ? vatRateWithReversalLiabilityColumnDef(vatRate!.width, vatRate!.label, vatRate!.index)
                    : vatRateColumnDef(vatRate!.width, vatRate!.label, vatRate!.index),
                ]
              : []),
            ...(workingDuration.enabled
              ? [workingDurationColumnDef(workingDuration.width, workingDuration.label, workingDuration.index)]
              : []),
            ...(amount!.enabled ? [amountColumnDef(amount!.width, amount!.label, amount!.index)] : []),
            ...(image.enabled ? [imageColumnDef(image.width, image.label, image.index)] : []),
          ],
          'index',
        );

        return [
          leftActionColumnDef(),
          ...(code.enabled ? [codeColumnDef(code.width, code.label, code.index)] : []),
          ...sortedColumns,
          emptyCellDef(),
          actionColumnDef(),
        ];
      }

      return [];
    },
    [
      getColumnDef,
      type,
      refCodeDef,
      denominationColumnDef,
      quantityColumnDef,
      unitColumnDef,
      unitFlatCostAmountColumnDef,
      flatCostAmountColumnDef,
      workingDurationColumnDef,
      imageColumnDef,
      leftActionColumnDef,
      emptyCellDef,
      actionColumnDef,
      shareOfHiddenCostAmountColumnDef,
      costAmountColumnDef,
      totalMarginColumnDef,
      unitAmountColumnDef,
      quote,
      vatRateWithReversalLiabilityColumnDef,
      vatRateColumnDef,
      amountColumnDef,
      codeColumnDef,
    ],
  );
}
