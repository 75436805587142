import { Quote, QuoteUnflattenObject } from '@org/quotation-lib';
import { useCallback } from 'react';

import { useStore } from 'store/store';
import { useCompanyMargins } from 'features/company/services/company.api';

export function useQuoteGetFromStoreOrThrow() {
  const quoteObjectFlat = useStore((state) => state.quote);
  const quoteSalesDiscount = useStore((state) => state.quoteSalesDiscount);
  const quoteCustomDiscounts = useStore((state) => state.quoteCustomDiscounts);
  const quoteTreeObjectFlat = useStore((state) => state.quoteTree);
  const quoteNodeObjectFlat = useStore((state) => state.quoteNode);
  const quoteNodeHiddenCostObjectFlat = useStore((state) => state.quoteNodeHiddenCost);
  const companyTypesWithMargins = useCompanyMargins().data.componentTypesMargins;

  return useCallback((): Quote => {
    if (!quoteObjectFlat || !quoteTreeObjectFlat || !quoteNodeObjectFlat) {
      throw new Error('Quote not found in store');
    }

    const quoteNode = Array.from([...quoteNodeObjectFlat.values(), ...(quoteNodeHiddenCostObjectFlat?.values() || [])]);

    const quoteObject = QuoteUnflattenObject.execute({
      quote: quoteObjectFlat,
      quoteNode,
      quoteTree: quoteTreeObjectFlat,
      quoteCustomDiscount: [...(quoteCustomDiscounts?.values() || [])],
      quoteSalesDiscount,
    });

    return new Quote(
      {
        isAutomaticIndexActivated: quoteObject.isAutomaticIndexActivated,
        componentTypes: companyTypesWithMargins.map(({ id, componentType, ...rest }) => ({
          ...componentType,
          componentTypeMargin: [{ margin: { ...rest } }],
        })),
      },
      quoteObject,
    );
  }, [
    companyTypesWithMargins,
    quoteCustomDiscounts,
    quoteNodeHiddenCostObjectFlat,
    quoteNodeObjectFlat,
    quoteObjectFlat,
    quoteSalesDiscount,
    quoteTreeObjectFlat,
  ]);
}
