import type { FC } from 'react';
import { useMemo } from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Card, DateField, TextField } from '@graneet/lib-ui';
import { FEATURE_FLAGS, isDirectPaymentSupplierInvoice, SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';

import { useSupplierInvoiceContext } from '../../hooks/UseSupplierInvoiceContext';
import { SupplierInvoiceAmountsField } from '../SupplierInvoiceAmountsField';
import type { EditSupplierInvoiceForm } from '../../forms/edit-supplier-invoice.form';

import { Rule } from 'features/form/rules/Rule';
import { useGetValidationStepsSupplierInvoice } from 'features/validation-step/services/validation-step-supplier-invoice.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { SupplierInvoiceCalloutDuplicate } from 'features/supplier-invoice/components/SupplierInvoiceCalloutDuplicate';

export const SupplierInvoiceInformationEditCard: FC = () => {
  const hasSupplierInvoiceImportImprovementFeatureFlag = useFeatureFlag(
    FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_IMPORT_IMPROVEMENT,
  );
  const { t } = useTranslation(['supplierInvoices']);

  const { supplierInvoice, hasOCRBeenDone } = useSupplierInvoiceContext();

  const isDirectPayment = isDirectPaymentSupplierInvoice(supplierInvoice);

  const { ordersSupplierInvoices, amountPreviousDirectPaymentsExVAT } = supplierInvoice || {};

  const orderAmountToBeBilledExVAT = useMemo(() => {
    if (
      supplierInvoice &&
      isDirectPaymentSupplierInvoice(supplierInvoice) &&
      ordersSupplierInvoices?.length === 1 &&
      amountPreviousDirectPaymentsExVAT !== undefined
    ) {
      const { amountExVAT: orderAmountExVAT } = ordersSupplierInvoices[0].order;

      return (orderAmountExVAT || 0) - amountPreviousDirectPaymentsExVAT;
    }

    return undefined;
  }, [amountPreviousDirectPaymentsExVAT, ordersSupplierInvoices, supplierInvoice]);

  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);
  const orderedValidationSteps = useGetValidationStepsSupplierInvoice();

  let canUpdateAmount;
  if (!supplierInvoice || supplierInvoice?.status === SUPPLIER_INVOICE_STATUS.TO_PROCESS) {
    canUpdateAmount = true;
  } else if (hasWorkflowValidationFeatureFlag && orderedValidationSteps.data.length > 0) {
    // @[ff: workflow-validation]
    canUpdateAmount = orderedValidationSteps.data[0].validationCondition.value > (supplierInvoice?.amountExVAT ?? 0);
  } else {
    canUpdateAmount = true;
  }

  // @[ff: workflow-validation]
  const maxAmountAllowed =
    hasWorkflowValidationFeatureFlag &&
    supplierInvoice !== null &&
    supplierInvoice.status !== SUPPLIER_INVOICE_STATUS.TO_PROCESS &&
    orderedValidationSteps.data.length &&
    orderedValidationSteps.data[0].validationCondition.value;

  return (
    <Card title={t('supplierInvoices:cards.informationsCard.title')}>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <TextField<EditSupplierInvoiceForm>
          name="name"
          label={t('supplierInvoices:cards.informationsCard.name')}
          forceDisplayError={hasOCRBeenDone}
          isRequired
        >
          <Rule.IsRequired />
        </TextField>

        <TextField<EditSupplierInvoiceForm>
          name="invoiceNumber"
          label={t('supplierInvoices:cards.informationsCard.invoiceNumber')}
          forceDisplayError={hasOCRBeenDone}
          isRequired
        >
          <Rule.IsRequired />
        </TextField>

        <DateField<EditSupplierInvoiceForm>
          name="billingDate"
          label={t('supplierInvoices:cards.informationsCard.billingDate')}
          width="100%"
          forceDisplayError={hasOCRBeenDone}
          isRequired
        >
          <Rule.IsRequired />
        </DateField>

        <DateField<EditSupplierInvoiceForm>
          name="paymentDate"
          label={t('supplierInvoices:cards.informationsCard.paymentDate')}
          width="100%"
        />

        <GridItem />
      </Grid>

      {/* @[ff: ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_IMPORT_IMPROVEMENT] */}
      <Box
        {...(supplierInvoice?.hasDuplicate && hasSupplierInvoiceImportImprovementFeatureFlag ? { my: 6 } : { mt: 6 })}
      >
        <SupplierInvoiceAmountsField
          forceFormError={hasOCRBeenDone}
          isDirectPayment={isDirectPayment}
          orderAmountToBeBilledExVAT={orderAmountToBeBilledExVAT}
          withScrollToAlertMessage={false}
          canUpdateAmount={canUpdateAmount}
          maxAmountAllowed={maxAmountAllowed}
        />
      </Box>

      {/* @[ff: ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_IMPORT_IMPROVEMENT] */}
      {hasSupplierInvoiceImportImprovementFeatureFlag &&
        supplierInvoice?.hasDuplicate &&
        supplierInvoice?.invoiceNumber && (
          <Box mb={3}>
            <SupplierInvoiceCalloutDuplicate invoiceNumber={supplierInvoice.invoiceNumber} />
          </Box>
        )}
    </Card>
  );
};
