import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { IProject } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { Card } from '@graneet/lib-ui';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useProjectAvailableTags, useProjectUpdateTags } from 'features/project/services/project.api';

interface ProjectTagsCardProps {
  project: IProject;
}

export const ProjectTagsCard: FC<ProjectTagsCardProps> = ({ project }) => {
  const { t } = useTranslation(['global']);
  const hasPermission = usePermissions([PERMISSION.CREATE_PROJECTS]);

  const tags = useProjectAvailableTags();

  const projectUpdateTagsMutation = useProjectUpdateTags();

  const onTagsSaved = useCallback(
    (newTags: string[]) => projectUpdateTagsMutation.mutateAsync({ id: project.id, dto: { tags: newTags } }),
    [project.id, projectUpdateTagsMutation],
  );

  if (!project.tags && !hasPermission) {
    return null;
  }

  return (
    <Card title={t('global:words.privateTags')}>
      <TagsEditionBlock
        placeholder={t('global:changeTags.placeholder')}
        tags={project.tags}
        suggestedTags={tags.data}
        onTagsEdited={onTagsSaved}
        isEditable={hasPermission}
      />
    </Card>
  );
};
