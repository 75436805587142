import type { IProgressStatementDeduction } from '../progress-statement/progress-statement-deduction.type';
import type { ISubProject } from '../sub-project/sub-project.type';

export enum DEDUCTION_TYPE {
  PRORATA = 'PRORATA',
  OTHER = 'OTHER',
}

export interface IDeduction {
  id: number;

  name: string;

  type: DEDUCTION_TYPE | null;

  percentage: number;

  isActive: boolean;

  createdAt: Date;

  updatedAt: Date;

  subProject?: ISubProject;

  progressStatementDeductions: IProgressStatementDeduction[];

  vatRate: number | null;

  isBasedOnAmountIncVAT: boolean;
}
