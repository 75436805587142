import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const DocumentCancelledIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 64 76" {...props}>
    <svg width="64" height="76" viewBox="0 0 64 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1175_4090)">
        <rect x="12" y="10" width="40" height="56" rx="2" fill="white" />
      </g>
      <rect x="17" y="15" width="9" height="3" rx="1" fill="#FFBBB4" />
      <rect x="32" y="20" width="15" height="6" rx="1" fill="#FFBBB4" />
      <rect x="33" y="55" width="14" height="6" rx="1" fill="#FFBBB4" />
      <rect x="17" y="55" width="14" height="6" rx="1" fill="#FFBBB4" />
      <rect x="17" y="28" width="30" height="6" rx="1" fill="#FFBBB4" />
      <rect x="17" y="38" width="30" height="2" rx="1" fill="#FFBBB4" />
      <rect x="17" y="42" width="30" height="2" rx="1" fill="#FFBBB4" />
      <rect x="17" y="46" width="30" height="2" rx="1" fill="#FFBBB4" />
      <line x1="17.2349" y1="55.357" x2="45.5176" y2="21.651" stroke="#F76255" strokeWidth="2" />
      <defs>
        <filter
          id="filter0_d_1175_4090"
          x="2"
          y="0"
          width="60"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1175_4090" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1175_4090" result="shape" />
        </filter>
      </defs>
    </svg>
  </Icon>
);
