import { CheckboxField, ModalSubtitle, SimpleHelpIcon, SwitchField, Tooltip } from '@graneet/lib-ui';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS } from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useCallback } from 'react';

import type { RoleFormFields } from '../../constants/role.constant';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const RoleEditModalQuote = () => {
  const { t } = useTranslation(['user']);

  const form = useFormContext<RoleFormFields>();
  const { displayQuotes } = useOnChangeValues(form, ['displayQuotes']);

  const onChange = useCallback(
    (newValue: boolean) => {
      form.setFormValues({
        createQuotes: newValue,
        acceptQuotesAndCreateProjects: newValue,
        updateLibrary: newValue,
        updateContracts: newValue,
      });
    },
    [form],
  );

  const hasUserPermissionsFeatureFlag = useFeatureFlag(FEATURE_FLAGS.USER_PERMISSIONS);
  if (!hasUserPermissionsFeatureFlag) {
    return null;
  }

  const canEditSubRole = !!displayQuotes;

  return (
    <Stack direction="column" gap={0}>
      <ModalSubtitle mb={0}>
        <SwitchField<RoleFormFields>
          name="displayQuotes"
          onChange={onChange as any}
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.DISPLAY_QUOTES')}</Text>
              <Tooltip label={t('user:role.DISPLAY_QUOTES.tooltip')} placement="right-end">
                <Box>
                  <SimpleHelpIcon boxSize={5} />
                </Box>
              </Tooltip>
            </HStack>
          }
          checkedValue
          uncheckedValue={false}
        />
      </ModalSubtitle>

      <Stack direction="column" gap={1} pl={3}>
        <CheckboxField<RoleFormFields>
          name="createQuotes"
          label={t('user:permissions.CREATE_QUOTES')}
          isDisabled={!canEditSubRole}
        />

        <CheckboxField<RoleFormFields>
          name="acceptQuotesAndCreateProjects"
          label={t('user:permissions.ACCEPT_QUOTES_AND_CREATE_PROJECTS')}
          isDisabled={!canEditSubRole}
        />

        <CheckboxField<RoleFormFields>
          name="updateLibrary"
          label={t('user:permissions.UPDATE_LIBRARY')}
          isDisabled={!canEditSubRole}
        />

        <CheckboxField<RoleFormFields>
          name="updateContracts"
          label={
            <HStack spacing={2} alignItems="center">
              <Text>{t('user:permissions.UPDATE_CONTRACTS')}</Text>
              <Tooltip label={t('user:role.UPDATE_CONTRACTS.tooltip')} placement="right-end">
                <Box display="flex" alignItems="center">
                  <SimpleHelpIcon boxSize={4} />
                </Box>
              </Tooltip>
            </HStack>
          }
          isDisabled={!canEditSubRole}
        />
      </Stack>
    </Stack>
  );
};
