import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import type {
  IClient,
  IContact,
  IContract,
  IPdf,
  IProject,
  IProjectWithRelations,
  IQuoteUpdateStatusesDTO,
  IUser,
  ProjectPaginatedResponse,
  RequiredByKeys,
} from '@graneet/business-logic';
import { isNil } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useLocalStorage, useToast } from '@graneet/lib-ui';
import type { QuoteDuplicateDTO, QuoteUpdateDTO, QuoteUpdateStatusDTO } from '@org/graneet-bff-client';

import { useQuotationProxyApis } from '../quote-common/hooks/useQuoteProxyApis';

import { apiNew } from 'features/api/services/apiNew.service';
import { getEnvValue } from 'config/env';

const PROJECTS_PATH = '/projects';
const CLIENTS_PATH = '/clients';
const CONTRACT_PATH = '/contracts';
const PDF_PATH = '/pdfs';
const CONTACT_PATH = '/contacts';
const USER_PATH = '/users';

export const useQuotationApi = () => {
  const quoteProxy = useQuotationProxyApis().quoteProxyApi;
  const messagingProxy = useQuotationProxyApis().messagingProxyApi;
  const queryClient = useQueryClient();

  // We cannot add quoteProxy to the dependencies array because it will break query with Tanstack
  const quotes = createQueryKeys('quotation-quotes', {
    one: (id: string) => ({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['quotes', id],
      queryFn: () => quoteProxy!.getQuoteById(id),
    }),

    versions: (id: string) => ({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['quotes', id, 'versions'],
      queryFn: () => quoteProxy!.getQuoteVersionsById(id),
    }),

    availableTags: () => ({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['quotes', 'available-tags'],
      queryFn: () => quoteProxy!.getAvailableTags(),
    }),

    composed: (id: string) => ({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['quotes', id, 'composed'],
      queryFn: () => quoteProxy!.getQuoteComposeById(id),
    }),

    rootLot: (id: string) => ({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['quotes', id, 'composed'],
      queryFn: () => quoteProxy!.getRootLotById(id),
    }),

    incompleteQuoteAssetsCount: (id: string) => ({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['quotes', id, 'is-completed'],
      queryFn: () => quoteProxy!.getIncompleteQuoteAssetsCount(id),
    }),

    stats: () => ({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['quotes', 'stats'],
      queryFn: () => quoteProxy!.getStats(),
    }),

    pdf: (id: string) => ({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['quotes', id, 'pdf'],
      queryFn: () => quoteProxy!.generateQuoteDraftPDF(id),
    }),
  });

  const projects = createQueryKeys('quotation-projects', {
    one: (id?: number | null) => ({
      queryKey: ['projects', id ?? undefined],
      queryFn: () =>
        !isNil(id) ? apiNew.get<never, IProjectWithRelations>(`${PROJECTS_PATH}/${id}`) : Promise.resolve(null),
    }),
    search: (search: string) => ({
      queryKey: ['projects', 'search', search],
      queryFn: () => apiNew.get<never, ProjectPaginatedResponse>(`${PROJECTS_PATH}?status=ON_GOING&_search=${search}`),
    }),
    basicInformation: (id?: number | null) => ({
      queryKey: ['projects', id ?? undefined],
      queryFn: () =>
        !isNil(id)
          ? apiNew.get<never, RequiredByKeys<IProject, 'address'>>(`${PROJECTS_PATH}/${id}/information`)
          : Promise.resolve(null),
    }),
  });

  const pdfs = createQueryKeys('quotation-pdfs', {
    byIds: (ids: number[]) => ({
      queryKey: ['pdfs', ids],
      queryFn: () => apiNew.post<{ ids: number[] }, IPdf[]>(`${PDF_PATH}/get-by-ids`, { ids }),
    }),
  });

  const contracts = createQueryKeys('quotation-contracts', {
    one: (id: string) => ({
      queryKey: ['contracts', id],
      queryFn: () => apiNew.get<never, IContract | null>(`${CONTRACT_PATH}/quotation/${id}`),
    }),
  });

  const clients = createQueryKeys('quotation-clients', {
    one: (id?: number | null) => ({
      queryKey: ['clients', id ?? undefined],
      queryFn: () => (!isNil(id) ? apiNew.get<never, IClient>(`${CLIENTS_PATH}/${id}`) : Promise.resolve(null)),
    }),
  });

  const contacts = createQueryKeys('quotation-contacts', {
    byIds: (ids?: string[] | null) => ({
      queryKey: ['contacts', ids ?? undefined],
      queryFn: () =>
        !isNil(ids)
          ? apiNew.post<
              {
                ids: string[];
              },
              IContact[]
            >(`${CONTACT_PATH}/find-by-ids`, {
              ids,
            })
          : Promise.resolve([]),
    }),

    // TODO: Implement this endpoint (associated to client and not linked to quote)
    available: (id: string, clientId: number) => ({
      queryKey: ['contacts', id, 'available'],
      queryFn: () => apiNew.get<never, IContact[]>(`${CONTACT_PATH}/client/${clientId}`),
    }),
  });

  const users = createQueryKeys('quotation-users', {
    byIds: (ids?: number[] | null) => ({
      queryKey: ['users', ids ?? undefined],
      queryFn: () =>
        !isNil(ids)
          ? apiNew.post<
              {
                ids: number[];
              },
              IUser[]
            >(`${USER_PATH}/find-by-ids`, {
              ids,
            })
          : Promise.resolve([]),
    }),
  });

  const useGetQuoteById = (id: string) => useSuspenseQuery(quotes.one(id));
  const useQuoteStats = () => useSuspenseQuery(quotes.stats());
  const useGetComposedById = (id: string) => useSuspenseQuery(quotes.composed(id));
  const useGetRootLot = (id: string) => useSuspenseQuery(quotes.rootLot(id));
  const useGetIncompleteQuoteAssetsCount = (id: string) => useSuspenseQuery(quotes.incompleteQuoteAssetsCount(id));
  const useGetQuoteAvailableTags = () => useSuspenseQuery(quotes.availableTags());
  const useLazyGetQuoteById = (id: string) => useQuery({ ...quotes.one(id), enabled: false });
  const useGetQuoteVersionsById = (id: string) => useSuspenseQuery(quotes.versions(id));
  const refetchQuoteById = (id: string) => queryClient.invalidateQueries(quotes.one(id));

  const useGetProjectById = (id?: number | null) => useSuspenseQuery(projects.one(id));
  const useGetProjectInformationById = (id?: number | null) => useSuspenseQuery(projects.basicInformation(id));
  const lazyGetProjectById = (id?: number | null) => queryClient.fetchQuery(projects.one(id));
  const lazySearchProjects = (search: string) => queryClient.fetchQuery(projects.search(search));

  const lazyGetContactsByIds = (ids: string[]) => queryClient.fetchQuery(contacts.byIds(ids));
  const useGetContactsByIds = (ids?: string[] | null) => useSuspenseQuery(contacts.byIds(ids));
  const useGetContactsAvailable = (id: string, clientId: number) => useSuspenseQuery(contacts.available(id, clientId));

  const useGetClientById = (id?: number | null) => useSuspenseQuery(clients.one(id));
  const lazyGetClientById = (id?: number | null) => queryClient.fetchQuery(clients.one(id));
  const refetchClientById = (id: number) => queryClient.invalidateQueries(clients.one(id));

  const useGetContractByQuoteId = (id: string) => useSuspenseQuery(contracts.one(id));

  const useGetPdfsByIds = (ids: number[]) => useSuspenseQuery(pdfs.byIds(ids));

  const useGetUsersByIds = (ids?: number[] | null) => useSuspenseQuery(users.byIds(ids));

  const refetchPdfsByIds = (ids: number[]) => queryClient.invalidateQueries(pdfs.byIds(ids));
  const refetchIncompleteQuoteAssetsCount = (id: string) =>
    queryClient.invalidateQueries(quotes.incompleteQuoteAssetsCount(id));
  const lazyGetIncompleteQuoteAssetsCount = (id: string) =>
    queryClient.fetchQuery(quotes.incompleteQuoteAssetsCount(id));
  const lazyGetQuoteById = (id: string) => queryClient.fetchQuery(quotes.one(id));

  const useQuoteDuplicate = () => {
    const { t } = useTranslation(['global', 'quote']);
    const toast = useToast();

    return useMutation({
      mutationFn: ({ quoteId, ...dto }: QuoteDuplicateDTO & { quoteId: string }) =>
        quoteProxy!.duplicateQuoteById(quoteId, dto),
      onSuccess: (data) => {
        queryClient.invalidateQueries(quotes.one(data.id));
      },
      onError: () => {
        toast.error(t('global:errors.error'));
      },
    });
  };

  const useAskFormation = () => {
    const { t } = useTranslation(['global', 'quote']);
    const toast = useToast();
    const [alreadyAskedForFormation, saveAlreadyAskedForFormation] = useLocalStorage(
      'alreadyAskedForFormation',
      'false',
    );

    const env = getEnvValue('REACT_APP_ENV', true);

    const message = `${env === 'prod' ? '' : `[${env}] - `}${t('global:help.askFormationContent')}`;
    const conversationId = 'C0768QG7H6G';

    return useMutation({
      mutationFn: () => {
        if (alreadyAskedForFormation === 'true') {
          return Promise.resolve('ALREADY_ASKED');
        }
        return messagingProxy!.sendMessage({ conversationId, text: message });
      },
      onSuccess: (data) => {
        if (data === 'ALREADY_ASKED') {
          toast.info(t('global:help.alreadyAskedFormation'));
          return;
        }
        toast.success(t('global:help.askFormationSuccess'));
        saveAlreadyAskedForFormation('true');
      },
      onError: () => {
        toast.error(t('global:errors.error'));
      },
    });
  };

  const useQuoteRestore = () => {
    const { t } = useTranslation(['global', 'quote']);
    const toast = useToast();

    return useMutation({
      mutationFn: (quoteId: string) => quoteProxy!.restoreQuoteById(quoteId),
      onSuccess: (data) => {
        queryClient.invalidateQueries(quotes.one(data.id));
      },
      onError: () => {
        toast.error(t('global:errors.error'));
      },
    });
  };

  const useQuoteChangeStatusToDraft = () => {
    const { t } = useTranslation(['global', 'quote']);
    const toast = useToast();

    return useMutation({
      mutationFn: (quoteId: string) => quoteProxy!.changeQuoteStatusToDraft(quoteId),
      onSuccess: (data) => {
        queryClient.invalidateQueries(quotes.one(data.id));
      },
      onError: () => {
        toast.error(t('global:errors.error'));
      },
    });
  };

  const useQuoteChangeStatus = () => {
    const { t } = useTranslation(['global', 'quote']);
    const toast = useToast();

    return useMutation({
      mutationFn: ({ quoteId, ...dto }: QuoteUpdateStatusDTO & { quoteId: string }) =>
        quoteProxy!.changeQuoteStatus(quoteId, dto),
      onSuccess: (data) => {
        queryClient.invalidateQueries(quotes.one(data.id));
      },
      onError: () => {
        toast.error(t('global:errors.error'));
      },
    });
  };

  const useQuotePartialUpdate = () => {
    const { t } = useTranslation(['global', 'quote']);
    const toast = useToast();

    return useMutation({
      mutationFn: ({ quoteId, ...dto }: QuoteUpdateDTO & { quoteId: string }) =>
        quoteProxy!.updatePartialQuote(quoteId, dto),
      onSuccess: (data) => {
        queryClient.invalidateQueries(quotes.one(data.id));
      },
      onError: () => {
        toast.error(t('global:errors.error'));
      },
    });
  };

  const useQuoteDelete = () => {
    const { t } = useTranslation(['global', 'quote']);
    const toast = useToast();

    return useMutation({
      mutationFn: (quoteId: string) => quoteProxy!.deleteQuote(quoteId),
      onSuccess: () => {},
      onError: () => {
        toast.error(t('global:errors.error'));
      },
    });
  };

  const useGetQuoteDraftPdf = () => {
    const { t } = useTranslation(['quote']);
    const toast = useToast();

    return useMutation({
      mutationFn: (quoteId: string) => quoteProxy!.generateQuoteDraftPDF(quoteId),
      onSuccess: () => {},
      onError: () => {
        toast.error(t('quote:creationForm.generatePreview.error'));
      },
    });
  };

  return {
    useAskFormation,
    useGetQuoteById,
    useGetProjectById,
    useGetProjectInformationById,
    useGetClientById,
    useQuoteDuplicate,
    useQuoteRestore,
    useQuoteChangeStatusToDraft,
    useGetQuoteVersionsById,
    useGetQuoteDraftPdf,
    useGetContractByQuoteId,
    useGetPdfsByIds,
    refetchPdfsByIds,
    useQuoteDelete,
    useQuoteChangeStatus,
    useQuotePartialUpdate,
    useLazyGetQuoteById,
    lazyGetProjectById,
    lazyGetContactsByIds,
    useGetContactsByIds,
    useGetQuoteAvailableTags,
    useGetComposedById,
    queryClient,
    refetchQuoteById,
    useGetRootLot,
    useGetIncompleteQuoteAssetsCount,
    lazyGetClientById,
    useGetContactsAvailable,
    useGetUsersByIds,
    useQuoteStats,
    refetchIncompleteQuoteAssetsCount,
    lazyGetIncompleteQuoteAssetsCount,
    refetchClientById,
    lazyGetQuoteById,
    lazySearchProjects,
  };
};

//
// TODO: Migrate to TanStack (used by Export Monolith and not in BFF)
export function useQuotationBatchUpdateLost() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'orders']);

  return useMutation({
    mutationFn: (dto: IQuoteUpdateStatusesDTO) =>
      apiNew.patch<IQuoteUpdateStatusesDTO, void>('/quotation/status/lost', dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, dto) => {
      // TODO Invalidate cache
      toast.success(
        t('orders:updateAtStatus.toast', { count: dto.hasAllSelected ? 2 : (dto.selectedItems?.length ?? 0) }),
      );
    },
  });
}
