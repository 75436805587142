import { ContentTypeGuard } from '@org/quotation-lib';

import type { QuoteWsMessage } from '../../quote-common/interfaces/quoteWsMessage';

import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';

export const createQuoteBasicItemDeletedCallback = (message: QuoteWsMessage) => {
  const quote = quoteGetFromStore();
  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();

  if (quote) {
    const hasClientRequest = quoteClientRequestsFromStore.find(
      (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
    );
    if (hasClientRequest === undefined) {
      const node = quote.getTree().getNodeOrThrow(message.data.id);
      const content = node.getContent();
      if (ContentTypeGuard.isQuoteBasicItem(content)) {
        content.deleteQuoteItem();
        quoteSetToStore(quote);
      }
    } else {
      quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
    }
  }
};
