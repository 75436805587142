import { StepperModal } from '@graneet/lib-ui';
import { useFormStatus, useStepForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

import type { ContractCreateWizard } from '../ContractCreateWizard';

import { useContactRoles } from 'features/contact/services/contact.api';
import { ContactUpsertForm } from 'features/contact/components/forms/ContactUpsertForm';

interface ContactCreateStepProps {
  hasIsDefaultEmailRecipient: boolean;
}

export const ContactCreateStep: FC<ContactCreateStepProps> = ({ hasIsDefaultEmailRecipient }) => {
  const { form } = useStepForm<ContractCreateWizard, 'create-contact'>({
    defaultValues: {
      isDefaultEmailRecipient: true,
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);
  const { t } = useTranslation(['global', 'contacts']);

  const contactRoles = useContactRoles();

  return (
    <>
      <ContactUpsertForm
        roles={contactRoles.data}
        form={form}
        hasIsDefaultEmailRecipient={hasIsDefaultEmailRecipient}
      />
      <StepperModal.CloseButton>{t('contacts:modal.client.buttons.cancel')}</StepperModal.CloseButton>

      <StepperModal.PrimaryButton isDisabled={!isFormValid}>
        {t('contacts:modal.client.buttons.next')}
      </StepperModal.PrimaryButton>
    </>
  );
};
