import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import type { AgGridReactProps, CustomCellRendererProps } from '@ag-grid-community/react';
import { AgGridReact } from '@ag-grid-community/react';
import type { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

import { useChakraColors } from '../../hooks/index';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import './simple-table.css';

const ROW_HEIGHT = 54;

const DEFAULT_MODULES = [ClientSideRowModelModule];

export const DEFAULT_COL_DEF = {
  suppressKeyboardEvent: () => true,
  editable: false,
  sortable: false,
  resizable: true,
  minWidth: 100,
  lockPinned: true,
  cellStyle: {
    alignContent: 'center',
  },
} as const;

const SIMPLE_TABLE_DEFAULT_COL_DEF = {
  ...DEFAULT_COL_DEF,
  resizable: false,
  flex: 1,
  lockPosition: true,
} as const;

const AUTO_SIZE_STRATEGY = {
  type: 'fitGridWidth',
} as const;

export interface ColDefEnforced<TData = unknown, TValue = unknown>
  extends Omit<ColDef<TData, TValue>, 'cellRenderer' | 'field'> {
  cellRenderer?: (props: Omit<CustomCellRendererProps<TData, TValue>, 'value'>) => ReactNode;

  /**
   * WARNING: field type is simplified compared to original ag grid issue because it's causing huge TS performance issue
   * due to infinite recursive logic in the ag grid type.
   */
  field: keyof TData;
}

export interface SimpleTableProps<TData = unknown>
  extends Omit<AgGridReactProps<TData>, 'columnDefs' | 'autoSizeStrategy'> {
  /**
   * Provide a custom gridId for this instance of the grid.
   * Value will be set on the root DOM node using the attribute grid-id as well as being accessible via the gridApi.getGridId() method.
   * This is the key used to store user preference (like column resize or order) in the local storage.
   */
  gridId: string;

  /**
   * Array of Column / Column Group definitions.
   */
  columnDefs: (ColDefEnforced<TData> | ColGroupDef)[];

  autoSizeStrategy?: AgGridReactProps<TData>['autoSizeStrategy'] | 'none';
}

export const SimpleTable = <TData = unknown,>({
  gridId,
  rowSelection,
  columnDefs: columnDefsProp,
  modules: modulesFromProps,
  onRowClicked,
  autoSizeStrategy = AUTO_SIZE_STRATEGY,
  ...otherProps
}: SimpleTableProps<TData>) => {
  const { gray200, gray50 } = useChakraColors({ gray200: 'gray.200', gray50: 'gray.50' });

  const columnDefs = useMemo(
    () =>
      [
        ...(rowSelection
          ? [
              {
                checkboxSelection: true,
                headerCheckboxSelection: otherProps.defaultColDef?.headerCheckboxSelection ?? true,
                maxWidth: 50,
                resizable: false,
                suppressMovable: true,
                lockPosition: 'left',
                showDisabledCheckboxes: true,
              },
            ]
          : []),
        ...(columnDefsProp ?? []),
      ] as ColDef<TData>[],
    [columnDefsProp, otherProps.defaultColDef?.headerCheckboxSelection, rowSelection],
  );

  const modules = useMemo(() => [...DEFAULT_MODULES, ...(modulesFromProps ?? [])], [modulesFromProps]);

  const getRowStyle = useCallback<NonNullable<AgGridReactProps<TData>['getRowStyle']>>(
    (row) => {
      if (!row.node.selectable) {
        return { background: gray50, cursor: 'not-allowed' };
      }
      return undefined;
    },
    [gray50],
  );

  return (
    <div
      className="ag-theme-quartz simple-table"
      style={{ width: '100%', height: '100%', borderTop: `1px solid ${gray200}` }}
    >
      <AgGridReact
        gridId={gridId}
        defaultColDef={{ ...SIMPLE_TABLE_DEFAULT_COL_DEF, ...otherProps.defaultColDef }}
        rowHeight={ROW_HEIGHT}
        autoSizeStrategy={autoSizeStrategy === 'none' ? undefined : autoSizeStrategy}
        columnDefs={columnDefs}
        rowSelection={rowSelection}
        modules={modules}
        onRowClicked={onRowClicked}
        suppressRowHoverHighlight={!onRowClicked}
        headerHeight={40}
        suppressRowClickSelection={!!onRowClicked}
        rowMultiSelectWithClick
        suppressCellFocus
        suppressRowVirtualisation
        suppressColumnVirtualisation
        suppressRowTransform
        domLayout="autoHeight"
        tooltipShowDelay={300}
        getRowStyle={getRowStyle}
        {...otherProps}
      />
    </div>
  );
};
