import type { FC } from 'react';
import { useCallback } from 'react';
import { ConfirmDeletionModal } from '@graneet/lib-ui';
import type { IReminder } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { useDeleteReminder } from '../services/reminder.api';

interface ReminderDeleteModalProps {
  reminder: IReminder;

  isOpen: boolean;

  onClose: () => void;
}

export const ReminderDeleteModal: FC<ReminderDeleteModalProps> = ({ reminder, isOpen, onClose }) => {
  const { t } = useTranslation(['global']);

  const deleteReminderMutation = useDeleteReminder();

  const handleDeletion = useCallback(async () => {
    await deleteReminderMutation.mutateAsync(reminder);
  }, [deleteReminderMutation, reminder]);

  return (
    <ConfirmDeletionModal
      title={t('global:reminders.deleteModal.title')}
      action={t('global:words.c.delete')}
      isOpen={isOpen}
      onClose={onClose}
      onDeleted={handleDeletion}
    />
  );
};
