import type { FC } from 'react';
import { AsyncTableData, Section } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IProject, SubProjectWithContracts } from '@graneet/business-logic';

import { SubProjectTables } from 'features/sub-project/components/SubProjectTable/SubProjectTables';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { ProjectAmountSummary } from 'features/project/components/ProjectAmountSummary';

interface ViewContractsStepProps {
  project: IProject;

  subProjects: SubProjectWithContracts[];
}

export const ViewContractsStep: FC<ViewContractsStepProps> = ({ project, subProjects }) => {
  const { t } = useTranslation(['project', 'statement']);

  return (
    <Section
      title={t('project:finalStatement.contracts.title')}
      description={t('project:finalStatement.contracts.description')}
    >
      <AsyncTableData errorState={{ message: t('statement:listingError', { email: SUPPORT_EMAIL }) }}>
        <SubProjectTables subProjects={subProjects} />

        <ProjectAmountSummary project={project} subProjects={subProjects} />
      </AsyncTableData>
    </Section>
  );
};
