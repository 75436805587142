import type { ProgressProps, ThemeTypings } from '@chakra-ui/react';
import { Progress } from '@chakra-ui/react';

export interface DocumentStatusCardProgressProps extends ProgressProps {
  chakraColor: ThemeTypings['colorSchemes'];
}

export const DocumentStatusCardProgress = ({ chakraColor, ...rest }: DocumentStatusCardProgressProps) => (
  <Progress
    w="100%"
    my={1}
    borderRadius={3}
    colorScheme={chakraColor}
    background={`${chakraColor}.200`}
    size="xs"
    {...rest}
  />
);
