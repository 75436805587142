import type { FC, ReactElement, ReactNode } from 'react';
import type { ListItemProps, IconProps } from '@chakra-ui/react';
import { ListItem, Box } from '@chakra-ui/react';

export interface ItemProps extends ListItemProps {
  label: string;

  icon?: ReactElement<IconProps>;

  children?: ReactNode;
}

export const Item: FC<ItemProps> = ({ label, icon, children, ...props }) => (
  <ListItem display="flex" my={2} {...props}>
    <Box mr={3}>{icon}</Box>

    <Box>
      <span>{label}</span>

      {children && <Box mt={2}>{children}</Box>}
    </Box>
  </ListItem>
);
