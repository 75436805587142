import { HStack, Text, VStack } from '@chakra-ui/react';
import type { ILedger, IProject, LEDGER_TYPES } from '@graneet/business-logic';
import { generateValuesAndState } from '@graneet/business-logic';
import { Callout, IconAdvanced, Modal, NumberField, SimpleHelpIcon } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus, useOnChangeValues } from 'graneet-form';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LedgerNumberPreview } from '../LedgerNumberPreview';

import { useAppContext } from 'features/app/contexts/AppContext';
import { Rule } from 'features/form/rules/Rule';
import { useUpdateLedgerCounter } from 'features/ledger/services/ledger.api';

type UpdateExpertLedgerModalProps = {
  isOpen: boolean;
  onClose(): void;
  ledger: ILedger;
  ledgerType: LEDGER_TYPES;
};

const Label = ({ value, helpText }: { value: string; helpText?: string }) => (
  <HStack pt={3} alignItems="center">
    <Text fontSize={16} fontWeight="semibold">
      {value}
    </Text>
    {helpText && <IconAdvanced icon={<SimpleHelpIcon />} tooltip={helpText} />}
  </HStack>
);

type ExpertLedgerEditModalFormValues = {
  counterNextValue: number;
};

export const UpdateExpertLedgerModal: FC<UpdateExpertLedgerModalProps> = ({ ledger, ledgerType, isOpen, onClose }) => {
  const form = useForm<ExpertLedgerEditModalFormValues>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { t } = useTranslation(['project', 'ledger', 'global']);
  // Used to avoid updating the ledger counter if it hasn't changed,
  const [counterValueBeforeEdit, setCounterValueBeforeEdit] = useState(0);
  const { currentUser } = useAppContext();

  const { counterNextValue } = useOnChangeValues(form, ['counterNextValue']);

  const updateLedgerCounterMutation = useUpdateLedgerCounter();

  const numberPreview = useMemo(() => {
    // Used for attribute generator sample values
    const sampleProject = {
      name: t('project:fields.name'),
      refCode: t('project:fields.code'),
    } as IProject;

    const { formattedNumber } = generateValuesAndState(
      ledger,
      { company: currentUser.company, referenceDate: new Date(), project: sampleProject },
      (counterNextValue || 0) - 1,
    );
    return formattedNumber;
  }, [counterNextValue, currentUser?.company, ledger, t]);

  useEffect(() => {
    const { rawCounterValue } = generateValuesAndState(ledger, { referenceDate: new Date() });
    const nextValue = rawCounterValue || 0;
    form.setFormValues({ counterNextValue: nextValue });
    setCounterValueBeforeEdit(nextValue);
  }, [form, ledger]);

  const handleSubmit = useCallback(async () => {
    // Compare the ledger state before edit. If it's the same, we don't update it.
    const valueHasChanged = counterValueBeforeEdit !== counterNextValue;

    if (!valueHasChanged || !counterNextValue) {
      onClose();
      return;
    }

    updateLedgerCounterMutation.mutate(
      { currentCounter: counterNextValue - 1, type: ledgerType },
      {
        onSuccess: onClose,
      },
    );
  }, [counterNextValue, counterValueBeforeEdit, ledgerType, onClose, updateLedgerCounterMutation]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`ledger:settings.type.${ledgerType}`)}
      size="xl"
      scrollBehavior="inside"
    >
      <Form form={form}>
        <VStack alignItems="start" gap={0}>
          <Label value={t('ledger:settings.editModal.sections.preview')} />

          <LedgerNumberPreview value={numberPreview} />

          <Callout width="100%">{t('ledger:settings.editModal.editExpertLedgerCallout')}</Callout>

          <Label
            value={t('ledger:settings.editModal.sections.counter')}
            helpText={t('ledger:settings.editModal.hints.counter')}
          />
          <NumberField<ExpertLedgerEditModalFormValues>
            type="number"
            label={t('ledger:settings.editModal.sections.counterNextValue')}
            textAlign="left"
            name="counterNextValue"
            min={1}
          >
            <Rule.IsRequired />
            <Rule.IsHigherOrEqualThan amount={1} message={t('ledger:settings.editModal.errors.invalidCounter')} />
          </NumberField>
        </VStack>
        <Modal.Close />

        <Modal.PrimaryButton
          isLoading={updateLedgerCounterMutation.isPending}
          isDisabled={!isFormValid}
          onClick={handleSubmit}
        >
          {ledger?.isEnabled
            ? t('ledger:settings.editModal.buttons.saveLedger')
            : t('ledger:settings.editModal.buttons.createLedger')}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
