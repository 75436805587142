import type { FC } from 'react';
import { useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Container } from '@graneet/lib-ui';
import { Flex } from '@chakra-ui/react';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { SupplierDeleteSection } from 'features/supplier/components/SupplierDeleteSection';
import {
  useSupplier,
  useSupplierDelete,
  useSupplierEdit,
  useSupplierEditTags,
  useSuppliersTags,
} from 'features/supplier/services/supplier.api';
import { SupplierCard } from 'features/supplier/components/cards/SupplierCard';
import { ContactCardSupplier } from 'features/contact/components/cards/ContactCardSupplier';
import { TextEditionCard } from 'features/common/components/TextEditionCard';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewSupplierScreen: FC = () => {
  const { t } = useTranslation(['global', 'supplier']);
  const history = useHistory();
  const { updateHeaderTitle } = useHeaderContext();

  const { supplierId } = useParams<{
    supplierId: string;
  }>();

  const supplier = useSupplier(parseInt(supplierId, 10));
  const tags = useSuppliersTags();

  const supplierEditTagsMutation = useSupplierEditTags();
  const supplierDeleteMutation = useSupplierDelete();
  const supplierEditMutation = useSupplierEdit();

  const onTagsSaved = useCallback(
    (newTags: string[]) =>
      supplierEditTagsMutation.mutateAsync({
        id: Number(supplierId),
        dto: { tags: newTags },
      }),
    [supplierEditTagsMutation, supplierId],
  );

  const onDeleted = useCallback(async () => {
    await supplierDeleteMutation.mutateAsync(parseInt(supplierId, 10), {
      onSuccess: () => {
        history.push('/contacts/suppliers');
      },
    });
  }, [history, supplierId, supplierDeleteMutation]);

  const updateNote = useCallback(
    async (note: string) =>
      supplierEditMutation.mutateAsync({
        id: supplier.data.id,
        dto: {
          name: supplier.data.name,
          type: supplier.data.type,
          hasBillingAddress: supplier.data.hasBillingAddress,
          billingAddress: supplier.data.billingAddress ?? undefined,
          note,
        },
      }),
    [supplier, supplierEditMutation],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(supplier.data.name ?? '', [
      {
        name: t('global:nav.suppliers'),
        link: '/contacts/suppliers',
      },
    ]);
  }, [supplier.data, t, updateHeaderTitle]);

  return (
    <Container size="md">
      <Flex direction="column" p={2} gap={4}>
        <SupplierCard supplier={supplier.data} />

        <ContactCardSupplier supplierId={supplier.data.id} />

        <TextEditionCard
          title={t('supplier:fields.note')}
          errorLabel={t('supplier:changeNote.error')}
          successLabel={t('supplier:changeNote.success')}
          placeholder={t('supplier:changeNote.placeholder')}
          text={supplier.data.note}
          onTextEdited={updateNote}
          isEditable
        />

        <Card title={t('global:words.privateTags')}>
          <TagsEditionBlock
            placeholder={t('global:changeTags.placeholder')}
            tags={supplier.data.tags}
            suggestedTags={tags.data}
            onTagsEdited={onTagsSaved}
            isEditable
          />
        </Card>

        <SupplierDeleteSection onDeleted={onDeleted} supplier={supplier.data} />
      </Flex>
    </Container>
  );
};
