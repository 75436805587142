import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { PaginatedResponse, PaginatedTableProps, PaginationQuery } from '@graneet/lib-ui';
import { Link, formatDataOrEmpty, ActionMenu, PaginatedTable } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';
import { Box } from '@chakra-ui/react';

import { ContactDetails } from '../ContactDetails';

interface ContactTableProps {
  contacts: PaginationQuery<PaginatedResponse<IContact>>;
  onContactToEditSelected: (contact: IContact) => void;
  onContactToDeleteSelected: (contact: IContact) => void;
}

const LastNameCell = ({ data }: { data?: IContact }) =>
  data ? <ContactDetails contact={data} isContactDefaultEmailRecipient={() => false} /> : null;

const LinkToCell = ({ data }: { data?: IContact }) =>
  data ? (
    <>
      {data.client && (
        <Link fontSize="sm" to={`/contacts/clients/${data.client.id}`}>
          {data.client.enterpriseName}
        </Link>
      )}
      {data.supplier && (
        <Link fontSize="sm" to={`/contacts/suppliers/${data.supplier.id}`}>
          {data.supplier.name}
        </Link>
      )}
    </>
  ) : null;

export const ContactTable: FC<ContactTableProps> = ({
  contacts,
  onContactToEditSelected,
  onContactToDeleteSelected,
}) => {
  const { t } = useTranslation(['contacts']);

  const MenuCell = useCallback(
    ({ data }: { data?: IContact }) =>
      data ? (
        <Box>
          <ActionMenu>
            <ActionMenu.Edit onClick={() => onContactToEditSelected(data)} />
            <ActionMenu.Delete onClick={() => onContactToDeleteSelected(data)} />
          </ActionMenu>
        </Box>
      ) : null,
    [onContactToDeleteSelected, onContactToEditSelected],
  );

  const columnDefs = useMemo<PaginatedTableProps<PaginatedResponse<IContact>>['columnDefs']>(
    () => [
      {
        field: 'lastName',
        headerName: t('contacts:fields.lastName'),
        width: 400,
        cellRenderer: LastNameCell,
        sortable: true,
      },
      {
        field: 'email',
        headerName: t('contacts:fields.email'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.email),
        sortable: true,
      },
      {
        field: 'phoneNumber',
        headerName: t('contacts:fields.phoneNumber'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.phoneNumber),
        sortable: true,
      },
      {
        field: 'linkedTo' as any,
        headerName: t('contacts:fields.linkedTo'),
        cellRenderer: LinkToCell,
      },
      {
        field: 'menu' as any,
        headerName: '',
        resizable: false,
        suppressMovable: true,
        width: 20,
        cellRenderer: MenuCell,
      },
    ],
    [MenuCell, t],
  );

  return (
    <PaginatedTable
      gridId="contact"
      rowHeight={82}
      pagination={contacts}
      columnDefs={columnDefs}
      zeroState={{
        icon: <i className="ri-user-line ri-4x" />,
        label: t('contacts:noContacts'),
      }}
      emptyState={{
        label: t('contacts:noResult'),
      }}
    />
  );
};
