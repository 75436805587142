import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { DrawerApi } from '@graneet/lib-ui';
import { useCurrency } from '@graneet/lib-ui';
import { Form, useForm } from 'graneet-form';
import type { IDiscountCreationDTO } from '@graneet/business-logic';
import { DISCOUNT_TYPES, isNumberFinite } from '@graneet/business-logic';

import { DiscountEditDrawer } from 'features/discount/components/DiscountEditDrawer';
import type { DiscountEditionForm } from 'features/discount/forms/discount-edition-form';
import { useCreateQuoteDiscount } from 'features/quote-discount/hooks/useCreateQuoteDiscount';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useQuote } from 'features/quote/hooks/useQuote';

interface QuoteDiscountEditDrawerProps {
  drawer: DrawerApi<string>;
}
export const QuoteDiscountEditDrawer: FC<QuoteDiscountEditDrawerProps> = ({ drawer }) => {
  const { t } = useTranslation(['quote']);
  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();

  const { startAnotherUpdate } = useQuoteEditContext();
  const quote = useQuote();

  const updateQuoteDiscount = useCreateQuoteDiscount();

  const form = useForm<DiscountEditionForm>();
  const { setFormValues, getFormValues } = form;

  useEffect(() => {
    if (quote?.discount) {
      const { type: discountType, percentage: discountPercentage, amountExVAT } = quote.discount;

      // Restore discount data
      setFormValues({
        discountType,
        discountPercentage: isNumberFinite(discountPercentage) ? discountPercentage : undefined,
        discountAmountExVAT: isNumberFinite(amountExVAT) ? mapNumberToAmount(amountExVAT) : undefined,
      });
    } else {
      // Reset
      setFormValues({
        discountType: DISCOUNT_TYPES.PERCENTAGE,
        discountPercentage: undefined,
        discountAmountExVAT: undefined,
      });
    }
  }, [quote, setFormValues, getFormValues, mapNumberToAmount]);

  const onSubmit = useCallback(async () => {
    if (quote?.id) {
      const { discountType, discountAmountExVAT, discountPercentage } = form.getFormValues();

      const body: IDiscountCreationDTO =
        discountType === DISCOUNT_TYPES.PERCENTAGE
          ? {
              type: discountType!,
              percentage: discountPercentage!,
              amountExVAT: null,
            }
          : {
              type: DISCOUNT_TYPES.AMOUNT,
              percentage: null,
              amountExVAT: mapAmountToNumber(discountAmountExVAT!),
            };

      startAnotherUpdate();
      await updateQuoteDiscount(body);
    }
    drawer.onClose();
  }, [quote, drawer, form, startAnotherUpdate, mapAmountToNumber, updateQuoteDiscount]);

  const totalBeforeDiscountExVAT = quote ? quote.amountWithoutDiscountExVAT : 0;

  return (
    <Form form={form}>
      <DiscountEditDrawer
        description={t('quote:discounts.drawers.discount.description')}
        totalBeforeDiscountExVAT={totalBeforeDiscountExVAT}
        drawer={drawer}
        title={t('quote:discounts.drawers.discount.title')}
        onSubmit={onSubmit}
        submitLabel={t('quote:discounts.drawers.discount.cta')}
      />
    </Form>
  );
};
