import type { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ViewCashOutflowScreen } from './ViewCashOutFlowScreen';
import { ViewCashOutflowsScreen } from './ViewCashOutFlowsScreen';

import { DisplayNotFoundScreen } from 'screens/DisplayNotFoundScreen';

export const CashOutflowRouter: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/:id`}>
        <ViewCashOutflowScreen />
      </Route>
      <Route exact path={path}>
        <ViewCashOutflowsScreen />
      </Route>
      <Route path="*" component={DisplayNotFoundScreen} />
    </Switch>
  );
};
