import type { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

const COLOR_LABEL_STYLES = {
  color: 'greenBrand.light',
};

const EMPHASIS_LABEL_STYLES = {
  fontWeight: 600,
};

interface QuotePreviewLabelCardProps {
  variant: 'color' | 'emphasis';

  number?: string | number;

  isActivated?: string;

  translationKey: 'quote:settings.validityDuration.previewLabel' | 'quote:settings.autoNumbering.previewLabel';
}

export const QuotePreviewLabelCard: FC<QuotePreviewLabelCardProps> = ({
  variant,
  number,
  isActivated,
  translationKey,
}) => {
  const { t } = useTranslation(['quote']);

  let numberStyles;
  if (variant === 'color') {
    numberStyles = COLOR_LABEL_STYLES;
  }

  if (variant === 'emphasis') {
    numberStyles = EMPHASIS_LABEL_STYLES;
  }

  return (
    <Flex>
      <Text>
        <Trans t={t} i18nKey={translationKey} values={{ number, isActivated }}>
          description_start
          <Text as="span" {...numberStyles}>
            displayed_data
          </Text>
          description_end
        </Trans>
      </Text>
    </Flex>
  );
};
