import type { FC } from 'react';
import { LabeledData } from '@graneet/lib-ui';
import type {
  ICredit,
  IDownPayment,
  IInvoice,
  IProgressStatement,
  STATEMENT_SALES_TYPES,
} from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { useHasLedger } from '../hooks/useHasLedger';

interface InvoiceNumberLabeledDataProps {
  statement: IDownPayment | ICredit | IInvoice | IProgressStatement;
  statementType: STATEMENT_SALES_TYPES;
}

/**
 * Return the invoice number label formatted depending on the ledger
 */
export const InvoiceNumberLabeledData: FC<InvoiceNumberLabeledDataProps> = ({ statement, statementType }) => {
  const hasLedger = useHasLedger();
  const { t } = useTranslation(['statement']);
  const { invoiceNumber } = statement;

  const shouldDisplayLedgerPlaceholder = hasLedger && !invoiceNumber;
  const props = shouldDisplayLedgerPlaceholder
    ? {
        data: t('statement:statementLedger.placeholder'),
        variant: 'light' as const,
        tooltip: t(`statement:statementLedger.tooltip.${statementType}`),
      }
    : {
        data: invoiceNumber,
      };

  return <LabeledData label={t('statement:fields.invoiceNumber')} {...props} />;
};
