import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import {
  IconAdvanced,
  useCurrency,
  Button,
  DrawersStack,
  Header,
  Card,
  AlertMessage,
  Spinner,
  useToast,
  SimpleHelpIcon,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Collapse, HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import {
  groupExistingComponentsByType,
  roundFloating,
  multiplyFloating,
  divideFloating,
  getDiscountAmountExVAT,
  computeAmountExVATWithDisbursementAndTotalMargin,
  getAmountWithoutDiscountFromAmountExVAT,
  getAmountWithoutCustomDiscountsFromAmountExVAT,
  getSumCustomDiscountsAmountExVAT,
} from '@graneet/business-logic';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { object } from 'prop-types';

import { MarginEditionByComponentsTypesTable } from 'features/margin/components/MarginEditionByComponentsTypesTable';
import {
  QUOTE_TOTAL_FIELDS,
  MARGIN_ENDPOINT_TYPE,
  AMOUNT_ENDPOINT_TYPE,
  QUOTE_TOTAL_MARGIN_FIELD,
  INCLUDE_COMPONENT,
} from 'features/quote/constants/quotes.constant';
import { useLot } from 'features/quote-lot/hooks/useLot';
import { MARGIN_FIELD_NAME } from 'features/margin/services/margin.constants';
import {
  BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD,
  BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD,
  getMarginComputedValueFromFieldName,
} from 'features/margin/services/margins.utils';
import { useQuoteSellSheetMarginPreview } from 'features/quote-sellsheet/hooks/useQuoteSellSheetMarginPreview';
import { useQuoteSellSheetMarginUpdate } from 'features/quote-sellsheet/hooks/useQuoteSellSheetMarginUpdate';
import { useQuoteSellSheetComponentTypeUpdate } from 'features/quote-sellsheet/hooks/useQuoteSellSheetComponentTypeUpdate';
import { useQuoteSellSheetAmountUpdate } from 'features/quote-sellsheet/hooks/useQuoteSellSheetAmountUpdate';
import { useQuoteSellSheetAmountPreview } from 'features/quote-sellsheet/hooks/useQuoteSellSheetAmountPreview';
import { useQuoteSellSheetComponentTypePreview } from 'features/quote-sellsheet/hooks/useQuoteSellSheetComponentTypePreview';
import { QuoteSalesSheetTotalsAndDiscount } from 'features/quote-discount/components/QuoteSalesSheetTotalsAndDiscount';
import { QuoteDiscountEditDrawer } from 'features/quote-discount/components/modals/QuoteDiscountEditDrawer';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';
import { useQuote } from 'features/quote/hooks/useQuote';
import { QuoteSalesSheetMarginMultiplication } from 'features/quote/components/multiplications/QuoteSalesSheetMarginMultiplication';
import { QuoteSalesSheetSellingMultiplication } from 'features/quote/components/multiplications/QuoteSalesSheetSellingMultiplication';
import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useCompanyMargins } from 'features/company/services/company.api';

const CARD_TITLE_STYLE = {
  color: 'gray.800',
  fontWeight: '600',
};

const COLLAPSE_BUTTON_HOVER_STYLE = {
  color: 'greenBrand.light',
  backgroundColor: 'transparent',
};

const COLLAPSE_BUTTON_STYLE = {
  color: 'gray.800',
  fontWeight: '600',
  transition: 'all 0.3s ease 0s',
};

const ICON_STYLE = {
  boxSize: 6,
};

export const EditQuoteSellSheetDrawer = ({ drawerSellSheet, drawerSellSheetDiscount, drawersStackQuote }) => {
  const { t } = useTranslation(['quote']);
  const { mapNumberToAmount, mapAmountToNumber, formatAsAmount } = useCurrency();

  const form = useForm();
  const { isValid: isFormValid } = useFormStatus(form);
  const { setFormValues, getFormValues } = form;

  const componentsMarginForm = useForm();
  const { setFormValues: setComponentsMarginFormValues, getFormValues: getComponentsMarginFormValues } =
    componentsMarginForm;

  const { isOpen, onToggle } = useDisclosure();

  const { getCurrentQuoteData, useRootLotId } = useQuoteEditContext();
  const { isReadOnlyView } = useQuoteDisplayContext();

  const rootLotId = useRootLotId();
  const rootLot = useLot(rootLotId);

  const previewQuoteMarginSellSheet = useQuoteSellSheetMarginPreview();
  const updateQuoteMarginSellSheet = useQuoteSellSheetMarginUpdate();
  const updateComponentTypeMarginSellSheet = useQuoteSellSheetComponentTypeUpdate();
  const updateQuoteSellSheetAmount = useQuoteSellSheetAmountUpdate();
  const previewQuoteAmountSellSheet = useQuoteSellSheetAmountPreview();

  const resetQuoteComponentTypeMarginSellSheet = useQuoteSellSheetComponentTypePreview();

  const quote = useQuote();
  const [marginComputedValue, setMarginComputedValue] = useState();
  const [endpointTypeToCall, setEndpointTypeToCall] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [displayPreviewButton, setDisplayPreviewButton] = useState(false);
  const [isEditionDisabled, setIsEditionDisabled] = useState(false);
  const [currentEditField, setCurrentEditField] = useState();
  const [lastEditedFieldValue, setLastEditedFieldValue] = useState();
  const [componentsTypes, setComponentsTypes] = useState();
  const [totalAmountIsUnreachable, setTotalAmountIsUnreachable] = useState(false);
  const [roundedAmountExVAT, setRoundedAmountExVAT] = useState(null);
  const focusFieldRef = useRef();
  const isOnInit = useRef(false);
  const toast = useToast();

  const companyMargins = useCompanyMargins();

  const {
    [MARGIN_FIELD_NAME.TOTAL_MARGIN]: totalMarginLive,
    [QUOTE_TOTAL_FIELDS.DISBURSEMENT_EX_VAT]: disbursementExVATLive,
  } = getFormValues();

  const calcAmountExVAT = useCallback(
    (margin) => {
      const disbursementExVATAmount = mapAmountToNumber(disbursementExVATLive);
      const hiddenCostAmountExVAT = rootLot?.lot?.hiddenCostAmountExVAT ?? 0;
      return computeAmountExVATWithDisbursementAndTotalMargin(disbursementExVATAmount + hiddenCostAmountExVAT, margin);
    },
    [disbursementExVATLive, mapAmountToNumber, rootLot?.lot?.hiddenCostAmountExVAT],
  );

  // Get all components and group by component type
  useEffect(() => {
    const { components, jobs } = getCurrentQuoteData();

    if (components) {
      const componentTypesGrouped = groupExistingComponentsByType(
        Object.values(components),
        companyMargins.data,
        Object.values(jobs),
      );
      setComponentsTypes(componentTypesGrouped);
    }
  }, [getCurrentQuoteData, quote, currentEditField, companyMargins.data]);

  const handleSetComponentsMarginFormValues = useCallback(() => {
    if (isOnInit.current) {
      const { components } = getCurrentQuoteData();
      if (componentsTypes && components) {
        componentsTypes.map((component) =>
          setComponentsMarginFormValues({
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]:
              component.overheadCosts,
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
              component.profitMargin,
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
              component.totalMargin,
          }),
        );
      }
    } else {
      isOnInit.current = true;
    }
  }, [componentsTypes, getCurrentQuoteData, setComponentsMarginFormValues]);

  const handleSetMarginFormValues = useCallback(() => {
    if (!rootLot) return;
    if (focusFieldRef.current) return;

    const {
      disbursementExVAT,
      margin: { overheadCosts, profitMargin, totalMargin: initialTotalMargin, computed },
    } = rootLot.lot;
    const { amountExVAT } = quote;

    setMarginComputedValue(computed);

    setFormValues({
      [MARGIN_FIELD_NAME.OVERHEAD_COSTS]: overheadCosts,
      [MARGIN_FIELD_NAME.PROFIT_MARGIN]: profitMargin,
      [MARGIN_FIELD_NAME.TOTAL_MARGIN]: initialTotalMargin,
      [QUOTE_TOTAL_FIELDS.DISBURSEMENT_EX_VAT]: mapNumberToAmount(disbursementExVAT),
      [QUOTE_TOTAL_MARGIN_FIELD]: initialTotalMargin,
      [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT]: mapNumberToAmount(amountExVAT),
    });
  }, [rootLot, quote, mapNumberToAmount, setFormValues]);

  useEffect(() => {
    handleSetComponentsMarginFormValues();
    handleSetMarginFormValues();
  }, [handleSetComponentsMarginFormValues, handleSetMarginFormValues]);

  const refreshData = useCallback(() => {
    // If data not ready, do nothing
    if (!Number.isFinite(disbursementExVATLive) || !totalMarginLive) return;

    let amountExVAT = calcAmountExVAT(totalMarginLive);

    const amountWithDiscountExVAT = getAmountWithoutCustomDiscountsFromAmountExVAT(
      quote?.customDiscounts || [],
      amountExVAT,
    );
    let amountWithoutDiscountExVAT;

    try {
      amountWithoutDiscountExVAT = getAmountWithoutDiscountFromAmountExVAT(quote?.discount, amountWithDiscountExVAT);
    } catch {
      // Cannot compute amountWithoutDiscountExVAT due to a 100% discount
      amountWithoutDiscountExVAT = amountExVAT;
      amountExVAT = 0;
    }

    const discountAmountExVAT = -getDiscountAmountExVAT(quote?.discount, amountWithoutDiscountExVAT);
    const customDiscountAmountExVAT = getSumCustomDiscountsAmountExVAT(
      quote?.customDiscounts ?? [],
      amountWithoutDiscountExVAT,
    );
    setDiscountAmount(discountAmountExVAT + customDiscountAmountExVAT);

    if (Object.values(MARGIN_FIELD_NAME).includes(focusFieldRef.current)) {
      // Sync quote fields when margins fields are updated
      setFormValues({
        [QUOTE_TOTAL_MARGIN_FIELD]: totalMarginLive,
        [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT]: mapNumberToAmount(amountExVAT),
      });

      setEndpointTypeToCall(MARGIN_ENDPOINT_TYPE.MARGIN);
    }

    if (QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT === focusFieldRef.current) {
      const { [MARGIN_FIELD_NAME.OVERHEAD_COSTS]: overheadCosts, [QUOTE_TOTAL_MARGIN_FIELD]: quoteMargin } =
        getFormValues();
      // If data not ready, do nothing
      if (!overheadCosts || !quoteMargin) return;

      const profitMargin = divideFloating(quoteMargin, overheadCosts);
      // Sync margins when margin of quote is updated
      setFormValues({
        [MARGIN_FIELD_NAME.PROFIT_MARGIN]: profitMargin,
        [MARGIN_FIELD_NAME.TOTAL_MARGIN]: quoteMargin,
      });
      setEndpointTypeToCall(MARGIN_ENDPOINT_TYPE.AMOUNT_EX_VAT);
    }
  }, [
    calcAmountExVAT,
    disbursementExVATLive,
    getFormValues,
    mapNumberToAmount,
    quote?.customDiscounts,
    quote?.discount,
    setFormValues,
    totalMarginLive,
  ]);

  const handleUpdate = useCallback(async () => {
    setIsLoading(true);

    // When the price is base on margin (so amount ex VAT is computed from margin) call margin update endpoint
    if (endpointTypeToCall === MARGIN_ENDPOINT_TYPE.MARGIN) {
      const {
        [MARGIN_FIELD_NAME.OVERHEAD_COSTS]: overheadCosts,
        [MARGIN_FIELD_NAME.PROFIT_MARGIN]: profitMargin,
        [MARGIN_FIELD_NAME.TOTAL_MARGIN]: newTotalMargin,
      } = getFormValues();

      const dto = {
        overheadCosts,
        profitMargin,
        totalMargin: newTotalMargin,
        computed: getMarginComputedValueFromFieldName(marginComputedValue),
      };

      await updateQuoteMarginSellSheet(quote.id, dto);
    }

    if (endpointTypeToCall === MARGIN_ENDPOINT_TYPE.AMOUNT_EX_VAT) {
      const { [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT]: amountExVAT } = getFormValues();

      const dto = {
        amountExVAT: mapAmountToNumber(amountExVAT),
      };

      await updateQuoteSellSheetAmount(quote.id, dto);
    }

    if (endpointTypeToCall === MARGIN_ENDPOINT_TYPE.MARGIN_COMPONENT_TYPE) {
      const { 1: componentTypeId } = currentEditField.split('-');

      const {
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]:
          overheadCostsComponentType,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
          profitMarginComponentType,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
          totalMarginComponentType,
        [BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(componentTypeId)]: computedComponentType,
      } = getComponentsMarginFormValues();

      if (
        !overheadCostsComponentType ||
        !profitMarginComponentType ||
        !totalMarginComponentType ||
        !computedComponentType
      ) {
        setIsLoading(false);
        return;
      }

      const componentDto = {
        overheadCosts: overheadCostsComponentType,
        profitMargin: profitMarginComponentType,
        totalMargin: totalMarginComponentType,
        computed: computedComponentType,
      };

      await updateComponentTypeMarginSellSheet(quote?.id, componentTypeId, componentDto, companyMargins.data);
    }

    setIsLoading(false);
    setIsDisabledButton(true);
    setIsEditionDisabled(false);
    setEndpointTypeToCall(undefined);
    focusFieldRef.current = undefined;

    setCurrentEditField();
  }, [
    endpointTypeToCall,
    quote,
    marginComputedValue,
    currentEditField,
    companyMargins.data,
    getFormValues,
    getComponentsMarginFormValues,
    mapAmountToNumber,
    updateQuoteMarginSellSheet,
    updateComponentTypeMarginSellSheet,
    updateQuoteSellSheetAmount,
  ]);

  const handleBackToInitialState = useCallback(async () => {
    if (currentEditField) {
      if (currentEditField.includes(INCLUDE_COMPONENT)) {
        setIsLoadingSpinner(true);
        const { 1: componentTypeId, 2: fieldName } = currentEditField.split('-');
        const {
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.PROFIT_MARGIN)]: profitMargin,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]: overheadCosts,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.TOTAL_MARGIN)]: totalMargin,
          [BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(componentTypeId)]: valueComputed,
        } = getComponentsMarginFormValues();

        const dto = {
          overheadCosts,
          profitMargin,
          totalMargin,
          computed: valueComputed,
        };

        switch (fieldName) {
          case MARGIN_FIELD_NAME.PROFIT_MARGIN:
            dto.profitMargin = lastEditedFieldValue;
            break;
          case MARGIN_FIELD_NAME.OVERHEAD_COSTS:
            dto.overheadCosts = lastEditedFieldValue;
            break;
          case MARGIN_FIELD_NAME.TOTAL_MARGIN:
            dto.totalMargin = lastEditedFieldValue;
            break;
          default:
            break;
        }

        await resetQuoteComponentTypeMarginSellSheet(quote.id, componentTypeId, dto);
        setIsLoadingSpinner(false);
      } else {
        focusFieldRef.current = undefined;
        setFormValues({ [`${currentEditField}`]: lastEditedFieldValue });
        handleSetMarginFormValues();
        handleSetComponentsMarginFormValues();
      }
    }
    setDisplayPreviewButton(false);
    setIsEditionDisabled(false);
    setIsDisabledButton(true);
    setCurrentEditField();
    setLastEditedFieldValue();
  }, [
    currentEditField,
    lastEditedFieldValue,
    quote,
    resetQuoteComponentTypeMarginSellSheet,
    getComponentsMarginFormValues,
    setFormValues,
    handleSetMarginFormValues,
    handleSetComponentsMarginFormValues,
  ]);

  // -- onChange functions
  const onFocus = useCallback(
    (field, value) => () => {
      focusFieldRef.current = field;
      if (currentEditField !== field) {
        setLastEditedFieldValue(value);
      }
    },
    [currentEditField],
  );

  const onBlur = useCallback(
    (endpointType = endpointTypeToCall) =>
      async () => {
        if (!currentEditField) return;

        setIsEditionDisabled(true);
        setDisplayPreviewButton(false);
        setIsLoadingSpinner(true);

        const {
          [MARGIN_FIELD_NAME.OVERHEAD_COSTS]: overheadCosts,
          [MARGIN_FIELD_NAME.PROFIT_MARGIN]: profitMargin,
          [MARGIN_FIELD_NAME.TOTAL_MARGIN]: newTotalMargin,
          [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT]: newAmountExVAT,
        } = getFormValues();

        let previewResults = [];

        if (!overheadCosts || !profitMargin || !newTotalMargin || !newAmountExVAT) {
          toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
          // Timeout to prevent double click after preview
          await handleBackToInitialState();
          setTimeout(() => {
            setIsLoadingSpinner(false);
            setIsDisabledButton(true);
          }, 200);
          return;
        }

        if (endpointType === MARGIN_ENDPOINT_TYPE.MARGIN) {
          const dto = {
            overheadCosts,
            profitMargin,
            totalMargin: newTotalMargin,
            computed: getMarginComputedValueFromFieldName(marginComputedValue),
          };

          previewResults = await previewQuoteMarginSellSheet(quote.id, dto, companyMargins.data);
        }

        if (endpointType === AMOUNT_ENDPOINT_TYPE.TOTAL_EX_VAT) {
          const dto = {
            amountExVAT: mapAmountToNumber(newAmountExVAT),
          };

          previewResults = await previewQuoteAmountSellSheet(
            quote.id,
            dto,
            companyMargins.data,
            (isUnreachable, amountExVAT) => {
              setRoundedAmountExVAT(amountExVAT);
              setTotalAmountIsUnreachable(isUnreachable);
            },
          );
        }

        if (!Array.isArray(previewResults)) {
          await handleBackToInitialState();
          previewResults = [];
        }

        previewResults.map((previewResult) => {
          const {
            componentType,
            disbursementExVAT,
            quantity,
            overheadCosts: overheadCostsComponentType,
            profitMargin: profitMarginComponentType,
            totalMargin: totalMarginComponentType,
          } = previewResult;

          const currentComponent = componentsTypes.find((component) => component.id === componentType.id);

          if (currentComponent) {
            currentComponent.quantity = disbursementExVAT;
            currentComponent.disbursementExVAT = quantity;
          }

          return setComponentsMarginFormValues({
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentType.id, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]:
              overheadCostsComponentType,
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
              profitMarginComponentType,
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
              totalMarginComponentType,
          });
        });

        refreshData();

        // Timeout to prevent double click after preview
        setTimeout(() => {
          setIsLoadingSpinner(false);
          setIsDisabledButton(false);
        }, 200);
      },
    [
      currentEditField,
      getFormValues,
      endpointTypeToCall,
      refreshData,
      toast,
      t,
      handleBackToInitialState,
      marginComputedValue,
      previewQuoteMarginSellSheet,
      quote?.id,
      companyMargins.data,
      mapAmountToNumber,
      previewQuoteAmountSellSheet,
      componentsTypes,
      setComponentsMarginFormValues,
    ],
  );

  const calcLiveComponentMargin = (editFieldName, editFieldValue, component) => {
    let { overheadCosts, profitMargin, totalMargin } = component;

    if (editFieldName === MARGIN_FIELD_NAME.OVERHEAD_COSTS) {
      overheadCosts = editFieldValue;
    }

    if (editFieldName === MARGIN_FIELD_NAME.PROFIT_MARGIN) {
      profitMargin = editFieldValue;
    }

    totalMargin = multiplyFloating(roundFloating(overheadCosts), roundFloating(profitMargin));
    if (editFieldName === MARGIN_FIELD_NAME.TOTAL_MARGIN) {
      totalMargin = editFieldValue;
      profitMargin = divideFloating(totalMargin, overheadCosts);
    }

    return { overheadCosts, profitMargin, totalMargin };
  };

  const updateLiveQuoteSellSheetAmount = useCallback(
    (editFieldValue, editFieldName, totalMargin) => {
      let amountExVAT = editFieldValue;

      if (editFieldName !== QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT) {
        amountExVAT = calcAmountExVAT(totalMargin);
      }

      setFormValues({
        [QUOTE_TOTAL_MARGIN_FIELD]: totalMargin,
        [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT]: amountExVAT,
      });
    },
    [calcAmountExVAT, setFormValues],
  );

  const onChange = useCallback(
    (editFieldName, editFieldValue) => {
      if (editFieldName && editFieldValue) {
        isOnInit.current = false;
        setDisplayPreviewButton(true);
        setCurrentEditField(editFieldName);
        if (componentsTypes) {
          const componentsTotalMargin = componentsTypes.map((component) => {
            const { overheadCosts, profitMargin, totalMargin } = calcLiveComponentMargin(
              editFieldName,
              editFieldValue,
              component,
            );
            setComponentsMarginFormValues({
              [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]:
                overheadCosts,
              [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
                profitMargin,
              [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
                totalMargin,
            });
            return totalMargin;
          });
          if (new Set(componentsTotalMargin).size !== componentsTotalMargin.length) {
            updateLiveQuoteSellSheetAmount(editFieldValue, editFieldName, componentsTotalMargin[0]);
          }
        }
      }
    },
    [componentsTypes, updateLiveQuoteSellSheetAmount, setComponentsMarginFormValues],
  );

  const primaryDrawerAction = useMemo(() => {
    if (isReadOnlyView) return <Button onClick={drawerSellSheet.onClose}>{t('global:words.c.close')}</Button>;

    if (displayPreviewButton) return <Button>{t('quote:salesSheetDrawer.cta.previewChanges')}</Button>;

    return (
      <Button onClick={handleUpdate} isDisabled={!isFormValid || isDisabledButton} isLoading={isLoading}>
        {t('quote:salesSheetDrawer.cta.applyChanges')}
      </Button>
    );
  }, [
    displayPreviewButton,
    drawerSellSheet.onClose,
    handleUpdate,
    isDisabledButton,
    isFormValid,
    isLoading,
    isReadOnlyView,
    t,
  ]);

  return (
    <>
      <DrawersStack.Drawer
        title={t('quote:jobsStep.salesSheetDrawer.title')}
        drawer={drawerSellSheet}
        onOverlayClick={handleBackToInitialState}
        footer={
          <Flex justify={currentEditField ? 'space-between' : 'flex-end'} w="100vw">
            {currentEditField && (
              <Button
                colorScheme="red"
                variant="outline"
                onClick={handleBackToInitialState}
                isDisabled={!isFormValid || isDisabledButton}
                isLoading={isLoading}
              >
                {t('quote:salesSheetDrawer.cta.resetChanges')}
              </Button>
            )}
            {primaryDrawerAction}
          </Flex>
        }
      >
        <QueryWrapper>
          {isLoadingSpinner && (
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                backgroundColor: 'rgba(255,255,255,0.5)',
                height: '100vh',
                width: '100vw',
                maxWidth: 'var(--chakra-sizes-4xl)',
                top: 0,
                right: 0,
                zIndex: 'var(--chakra-zIndices-popover)',
              }}
            >
              <Spinner flexGrow={1} justify="center" my={8} />
            </Box>
          )}

          {!isReadOnlyView && <Text mb={4}>{t('quote:jobsStep.salesSheetDrawer.description')}</Text>}

          {isEditionDisabled && <AlertMessage mb={4}>{t('quote:jobsStep.salesSheetDrawer.alertMessage')}</AlertMessage>}

          <Form form={form}>
            <Card variant="light-gray" noShadow>
              <Header px={0} height="auto" backgroundColor="gray.100" sx={CARD_TITLE_STYLE}>
                <Flex mt={0} mb={0} height="auto" alignItems="center">
                  <Text>{t('margin:quote.description')}</Text>

                  {(quote?.discount || (quote?.customDiscounts && !!quote?.customDiscounts.length)) && (
                    <IconAdvanced
                      icon={<SimpleHelpIcon ml={3} />}
                      tooltip={t('quote:jobsStep.salesSheetDrawer.discountMarginAmountHint', {
                        amount: formatAsAmount(discountAmount),
                      })}
                    />
                  )}
                </Flex>
              </Header>

              <QuoteSalesSheetMarginMultiplication
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                setCurrentEditField={setCurrentEditField}
                setMarginComputedValue={setMarginComputedValue}
                marginComputedValue={marginComputedValue}
                currentEditField={currentEditField}
                isEditionDisabled={isEditionDisabled || isReadOnlyView}
              />

              <Box>
                <HStack spacing={2} onClick={onToggle} cursor="pointer" role="group">
                  <Text sx={COLLAPSE_BUTTON_STYLE} _groupHover={COLLAPSE_BUTTON_HOVER_STYLE}>
                    {t('quote:jobsStep.salesSheetDrawer.collapseTitle')}
                  </Text>
                  <IconButton
                    aria-label="Toggle"
                    bg="none"
                    size="md"
                    _focus={{ border: 'none' }}
                    _groupHover={COLLAPSE_BUTTON_HOVER_STYLE}
                    icon={isOpen ? <ChevronUpIcon {...ICON_STYLE} /> : <ChevronDownIcon {...ICON_STYLE} />}
                  />
                </HStack>

                <Collapse in={isOpen} animateOpacity>
                  <Box mt={3} bgColor="gray.100">
                    <Form form={componentsMarginForm}>
                      <MarginEditionByComponentsTypesTable
                        lotId={quote?.id}
                        componentTypes={componentsTypes}
                        currentEditField={currentEditField}
                        setCurrentEditField={setCurrentEditField}
                        setLastEditedFieldValue={setLastEditedFieldValue}
                        setIsLoadingSpinner={setIsLoadingSpinner}
                        setIsDisabledButton={setIsDisabledButton}
                        setEndpointTypeToCall={setEndpointTypeToCall}
                        isAdditionalInfoDisplayed
                        handleBackToInitialState={handleBackToInitialState}
                        isEditionDisabled={isEditionDisabled || isReadOnlyView}
                        setIsEditionDisabled={setIsEditionDisabled}
                        setDisplayPreviewButton={setDisplayPreviewButton}
                      />
                    </Form>
                  </Box>
                </Collapse>
              </Box>
            </Card>

            <Card variant="light-gray" my={4} noShadow>
              <Header color="gray.800" px={0} height="auto" backgroundColor="gray.100" sx={CARD_TITLE_STYLE}>
                {t('quote:jobsStep.salesSheetDrawer.sellingPrice')}
              </Header>

              <QuoteSalesSheetSellingMultiplication
                onFocus={onFocus}
                onBlur={() => onBlur(AMOUNT_ENDPOINT_TYPE.TOTAL_EX_VAT)}
                onChange={onChange}
                currentEditField={currentEditField}
                isEditionDisabled={isEditionDisabled || isReadOnlyView}
                quote={quote}
                type="quote"
              />
            </Card>

            {totalAmountIsUnreachable && (
              <AlertMessage backgroundColor="orange.100" iconColor="orange.500" my={4}>
                {t('quote:warning.updatingWarning')}{' '}
                <span style={{ fontWeight: 'bold' }}>{formatAsAmount(roundedAmountExVAT)}</span>.
              </AlertMessage>
            )}

            <QuoteSalesSheetTotalsAndDiscount
              isEditionDisabled={isEditionDisabled || isReadOnlyView}
              onSalesSheetDrawerOpen={drawerSellSheetDiscount.onOpen}
              drawersStack={drawersStackQuote}
              roundedAmountExVAT={roundedAmountExVAT}
            />
          </Form>
        </QueryWrapper>
      </DrawersStack.Drawer>

      <QuoteDiscountEditDrawer drawer={drawerSellSheetDiscount} />
    </>
  );
};

EditQuoteSellSheetDrawer.propTypes = {
  drawerSellSheet: object.isRequired,
  drawerSellSheetDiscount: object.isRequired,
  drawersStackQuote: object.isRequired,
};
