import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const CheckFilledIcon: FC<IconProps> = ({ color = 'green.600', ...rest }) => (
  <Icon viewBox="0 0 16 16" color={color} {...rest} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99967 1.5835C4.45301 1.5835 1.58301 4.4535 1.58301 8.00016C1.58301 11.541 4.45301 14.4168 7.99967 14.4168C11.5405 14.4168 14.4163 11.541 14.4163 8.00016C14.4163 4.4535 11.5405 1.5835 7.99967 1.5835ZM11.033 6.6585C11.2605 6.42516 11.2605 6.05766 11.033 5.83016C10.7997 5.59683 10.4322 5.59683 10.2047 5.83016L7.11301 8.916L5.77134 7.57433C5.53801 7.341 5.17051 7.341 4.94301 7.57433C4.70967 7.80183 4.70967 8.16933 4.94301 8.39683L6.69301 10.1468C6.92051 10.3743 7.28801 10.3743 7.51551 10.1468L11.0155 6.64683L11.033 6.6585Z"
      fill="currentColor"
    />
    <path
      d="M13.8337 7.99935C13.8337 11.221 11.222 13.8327 8.00033 13.8327C4.77866 13.8327 2.16699 11.221 2.16699 7.99935C2.16699 4.77769 4.77866 2.16602 8.00033 2.16602C11.222 2.16602 13.8337 4.77769 13.8337 7.99935Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
