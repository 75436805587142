import type dayjs from 'dayjs';
import { createContext, useContext } from 'react';

export type WeekData = {
  days: dayjs.Dayjs[];
  week: number;
};

export interface IMonthlyTimeTableContext {
  numberOfDays?: number;
  showWeekEnd?: boolean;
  startDate?: Date;
  currentDate?: dayjs.Dayjs;
  hoveredWeek: number | null;
  onHoverWeek: (week: number | null) => void;
  onWeekClick: (date: Date) => void;
  weeksList: Record<string, WeekData>;
}

export const MonthlyTimeTableContext = createContext<IMonthlyTimeTableContext>({
  numberOfDays: undefined,
  showWeekEnd: true,
  startDate: new Date(),
  currentDate: undefined,
  hoveredWeek: null,
  onHoverWeek: () => {},
  onWeekClick: () => {},
  weeksList: {},
});

export const useMonthlyTimeTableContext = () => useContext(MonthlyTimeTableContext);
