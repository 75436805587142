import { useMemo } from 'react';
import { Box, Flex, Text, Circle, useDisclosure } from '@chakra-ui/react';
import type { IBudgetUsage, IProject, ITimeTrackingProjectStatsResponse } from '@graneet/business-logic';
import { isNumberFinite, getPercentage } from '@graneet/business-logic';
import { ActionMenu, Card, divideFloating, formatPercentage, Table, Tooltip, useChakraColors } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';

import { ComponentTypeBadge } from 'features/component-type/components/ComponentTypeBadge';
import { BudgetEditModal } from 'features/budget/components/BudgetEditModal';

const formatMinutesToHours = (nbMinutes: number) => divideFloating(nbMinutes, 60, 2);

const TimeBar = ({
  estimatedProjectWorkTime,
  estimatedApprovedWorkTime,
  estimatedToApprovedWorkTime,
  hasLegend = false,
}: {
  estimatedProjectWorkTime: number;
  estimatedApprovedWorkTime: number;
  estimatedToApprovedWorkTime: number;
  hasLegend?: boolean;
}) => {
  const { t } = useTranslation(['timeTracking']);

  const { red500, blue500 } = useChakraColors({ red500: 'red.500', blue500: 'greenBrand.light' });

  const barProps = useMemo(() => {
    if (estimatedApprovedWorkTime > estimatedProjectWorkTime) {
      return {
        approvedWorkTime: {
          percentage: 100,
          bg: 'red.500',
        },
        toApproveWorkTime: {
          percentage: 0,
        },
        leftWorkTime: {
          percentage: 0,
        },
      };
    }

    const maxTime = Math.max(estimatedProjectWorkTime, estimatedApprovedWorkTime + estimatedToApprovedWorkTime);

    const approvedWorkTimePercentage = getPercentage(estimatedApprovedWorkTime, maxTime);

    const toApproveWorkTimePercentage = getPercentage(estimatedToApprovedWorkTime, maxTime);
    const leftWorkTimePercentage = 100 - toApproveWorkTimePercentage - approvedWorkTimePercentage;

    const toApproveWorkTimeColor =
      estimatedApprovedWorkTime + estimatedToApprovedWorkTime > estimatedProjectWorkTime ? red500 : blue500;

    return {
      approvedWorkTime: {
        percentage: approvedWorkTimePercentage,
        bg: estimatedApprovedWorkTime > estimatedProjectWorkTime ? 'red.500' : 'greenBrand.light',
      },
      toApproveWorkTime: {
        percentage: toApproveWorkTimePercentage,
        bg: `linear-gradient(135deg, ${toApproveWorkTimeColor} 25%, transparent 25%, transparent 50%, ${toApproveWorkTimeColor} 50%, ${toApproveWorkTimeColor} 75%, transparent 75%)`,
        backgroundSize: '1rem 1rem',
      },
      leftWorkTime: {
        percentage: leftWorkTimePercentage,
        bg: 'gray.200',
      },
    };
  }, [blue500, estimatedApprovedWorkTime, estimatedProjectWorkTime, estimatedToApprovedWorkTime, red500]);

  const approvedLabel = t('timeTracking:projectDetailsCard.legend.approved', {
    count: formatMinutesToHours(estimatedApprovedWorkTime),
  });

  const toApproveLabel = t('timeTracking:projectDetailsCard.legend.toApprove', {
    count: formatMinutesToHours(estimatedToApprovedWorkTime),
  });

  const toDoLabel = t('timeTracking:projectDetailsCard.legend.toDo', {
    count: formatMinutesToHours(
      Math.max(estimatedProjectWorkTime - estimatedToApprovedWorkTime - estimatedApprovedWorkTime, 0),
    ),
  });

  return (
    <Flex w="100%" gap={2} direction="column">
      <Flex w="100%" h="1.5rem" gap={1}>
        <Tooltip label={approvedLabel}>
          <Box rounded="sm" w={`${barProps.approvedWorkTime.percentage}%`} {...barProps.approvedWorkTime} />
        </Tooltip>

        <Tooltip label={toApproveLabel}>
          <Box rounded="sm" w={`${barProps.toApproveWorkTime.percentage}%`} {...barProps.toApproveWorkTime} />
        </Tooltip>

        <Tooltip label={toDoLabel}>
          <Box rounded="sm" w={`${barProps.leftWorkTime.percentage}%`} {...barProps.leftWorkTime} />
        </Tooltip>
      </Flex>

      {hasLegend && (
        <Flex gap={4}>
          <Flex gap={2} alignItems="center">
            <Circle {...barProps.approvedWorkTime} size="1rem" />
            <Text>{approvedLabel}</Text>
          </Flex>

          <Flex gap={2} alignItems="center">
            <Circle {...barProps.toApproveWorkTime} size="1rem" />
            <Text>{toApproveLabel}</Text>
          </Flex>

          <Flex gap={2} alignItems="center">
            <Circle {...barProps.leftWorkTime} size="1rem" />
            <Text>{toDoLabel}</Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const TimeTrackingByComponentTypeRow = ({
  componentType,
  budgets,
  project,
  onUpdate,
}: {
  componentType: ITimeTrackingProjectStatsResponse['componentTypes'][number];
  budgets: IBudgetUsage[];
  project: IProject;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation(['timeTracking']);

  const modal = useDisclosure();

  const leftWorkTime = Math.max(
    0,
    (componentType.estimatedProjectWorkTime ?? 0) -
      (componentType.estimatedApprovedWorkTime ?? 0) -
      (componentType.estimatedToApprovedWorkTime ?? 0),
  );

  const warningColorOnWorkTime =
    (componentType.estimatedProjectWorkTime ?? 0) <
    (componentType.estimatedApprovedWorkTime ?? 0) + (componentType.estimatedToApprovedWorkTime ?? 0)
      ? 'red.500'
      : undefined;

  return (
    <Table.Row>
      <Table.Cell>
        <Box w="12rem">
          <ComponentTypeBadge type={componentType.componentType} />
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Text>
          {isNumberFinite(componentType.estimatedQuoteWorkTime)
            ? t('timeTracking:projectDetailsCard.h', {
                count: formatMinutesToHours(componentType.estimatedQuoteWorkTime),
              })
            : '-'}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text>
          {isNumberFinite(componentType.estimatedProjectWorkTime)
            ? t('timeTracking:projectDetailsCard.h', {
                count: formatMinutesToHours(componentType.estimatedProjectWorkTime),
              })
            : '-'}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text color={warningColorOnWorkTime}>
          {isNumberFinite(componentType.estimatedApprovedWorkTime)
            ? t('timeTracking:projectDetailsCard.h', {
                count: formatMinutesToHours(componentType.estimatedApprovedWorkTime),
              })
            : '-'}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text color={warningColorOnWorkTime}>
          {isNumberFinite(componentType.estimatedToApprovedWorkTime)
            ? t('timeTracking:projectDetailsCard.h', {
                count: formatMinutesToHours(componentType.estimatedToApprovedWorkTime),
              })
            : '-'}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text color={leftWorkTime === 0 ? 'gray.500' : undefined}>
          {t('timeTracking:projectDetailsCard.h', {
            count: formatMinutesToHours(leftWorkTime),
          })}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <ActionMenu>
          <ActionMenu.Edit onClick={modal.onOpen} />
        </ActionMenu>

        <BudgetEditModal
          key={budgets.find((budget) => budget.typeId === componentType.componentType.id)?.id}
          isOpen={modal.isOpen}
          onClose={modal.onClose}
          onSuccess={onUpdate}
          project={project}
          budgetUsage={budgets.find((budget) => budget.typeId === componentType.componentType.id)!}
          disabledTypes={[]}
        />
      </Table.Cell>
    </Table.Row>
  );
};

interface TimeTrackingProgressCardProps {
  timeTrackingProjectStats: ITimeTrackingProjectStatsResponse;

  budgets: IBudgetUsage[];

  project: IProject;

  onUpdate: () => void;
}

export const TimeTrackingProgressCard = ({
  timeTrackingProjectStats,
  budgets,
  project,
  onUpdate,
}: TimeTrackingProgressCardProps) => {
  const { t } = useTranslation(['timeTracking']);

  const consumedPercentage = useMemo(
    () =>
      getPercentage(
        timeTrackingProjectStats.estimatedApprovedWorkTime,
        timeTrackingProjectStats.estimatedProjectWorkTime,
      ),
    [timeTrackingProjectStats],
  );

  return (
    <Card title={t('timeTracking:projectDetailsCard.title')}>
      <Flex gap={2} direction="column">
        <Text>
          <Trans
            t={t}
            i18nKey="timeTracking:projectDetailsCard.estimatedSpentDescription"
            values={{
              percentage: consumedPercentage ? formatPercentage(consumedPercentage) : '- %',
              estimatedHours: divideFloating(timeTrackingProjectStats.estimatedProjectWorkTime, 60, 2),
            }}
          >
            <Box as="a" color="greenBrand.light" fontWeight="semibold" />
            <Box as="a" color="greenBrand.light" fontWeight="semibold" />
          </Trans>
        </Text>

        <TimeBar
          estimatedProjectWorkTime={timeTrackingProjectStats.estimatedProjectWorkTime}
          estimatedToApprovedWorkTime={timeTrackingProjectStats.estimatedToApprovedWorkTime}
          estimatedApprovedWorkTime={timeTrackingProjectStats.estimatedApprovedWorkTime}
          hasLegend
        />

        <Box borderRadius="6px" boxShadow="subtle" overflow="hidden">
          <Table templateColumns={['auto', '5rem', '5rem', '5rem', '5rem', '5rem', '3rem']} noCard>
            <Table.Header>
              <Table.Cell>{t('timeTracking:projectDetailsCard.componentType')}</Table.Cell>
              <Table.Cell>{t('timeTracking:projectDetailsCard.quoted')}</Table.Cell>
              <Table.Cell>{t('timeTracking:projectDetailsCard.budgeted')}</Table.Cell>
              <Table.Cell>{t('timeTracking:projectDetailsCard.approved')}</Table.Cell>
              <Table.Cell>{t('timeTracking:projectDetailsCard.toApproved')}</Table.Cell>
              <Table.Cell>{t('timeTracking:projectDetailsCard.left')}</Table.Cell>
              <Table.Cell />
            </Table.Header>

            {timeTrackingProjectStats.componentTypes.map((componentType) => (
              <TimeTrackingByComponentTypeRow
                key={componentType.componentType.id}
                componentType={componentType}
                budgets={budgets}
                project={project}
                onUpdate={onUpdate}
              />
            ))}
          </Table>
        </Box>
      </Flex>
    </Card>
  );
};
