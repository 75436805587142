import { useLayoutEffect, type FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { OrdersListing } from 'features/order/components/tables/OrdersListing';
import { useOrders } from 'features/order/services/order.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ListOrdersScreen: FC = () => {
  const { t } = useTranslation(['global', 'orders']);

  const { updateHeaderTitle } = useHeaderContext();
  const { createRedirectionWithFilters } = useFiltersQuery();

  const isExportEnabled = useFeatureFlag(FEATURE_FLAGS.EXPORT_TABLES);

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.orders'), []);
  }, [t, updateHeaderTitle]);

  const orders = useOrders();

  const onRowClicked = useCallback(
    (id: number) => {
      createRedirectionWithFilters(`/purchases/orders/${id}`)();
    },
    [createRedirectionWithFilters],
  );

  return (
    <OrdersListing gridId="order" orders={orders} canExportEntities={isExportEnabled} onRowClicked={onRowClicked} />
  );
};
