import { useTranslation } from 'react-i18next';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, type FC } from 'react';
import { Form, useForm } from 'graneet-form';
import { Card, CurrencyField, useCurrency } from '@graneet/lib-ui';

import { useAppContext } from 'features/app/contexts/AppContext';
import { useUpdateCompanyDefaultMealExpense } from 'features/company/services/company.api';

interface MealForm {
  defaultMealExpense: number;
}

export const MealExpenseCard: FC = () => {
  const { t } = useTranslation(['planning']);
  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();

  const { currentUser } = useAppContext();
  const { company } = currentUser;

  const updateCompanyDefaultMealExpenseMutation = useUpdateCompanyDefaultMealExpense();

  const onUpdateAfterBlur = useCallback(
    async (name: keyof MealForm, value?: MealForm[typeof name]) => {
      updateCompanyDefaultMealExpenseMutation.mutate({ defaultMealExpense: mapAmountToNumber(value ?? 0) });
    },
    [updateCompanyDefaultMealExpenseMutation, mapAmountToNumber],
  );

  const formOptions = useMemo(() => ({ onUpdateAfterBlur }), [onUpdateAfterBlur]);
  const mealForm = useForm<MealForm>(formOptions);

  useEffect(() => {
    if (company?.defaultMealExpense) {
      mealForm.setFormValues({
        defaultMealExpense: mapNumberToAmount(company?.defaultMealExpense),
      });
    }
  }, [company, mealForm, mapNumberToAmount]);

  return (
    <Card title={t('planning:mealExpenseCard.title')}>
      <Form form={mealForm}>
        <HStack>
          <Box flex={1}>
            <Text color="black" fontWeight={500}>
              {t('planning:mealExpenseCard.description')}
            </Text>
            <Text fontSize="xs">{t('planning:mealExpenseCard.note')}</Text>
          </Box>
          <Box>
            <CurrencyField<MealForm>
              label={t('planning:mealExpenseCard.amount')}
              name="defaultMealExpense"
              min={0}
              inputProps={{ textAlign: 'right' }}
            />
          </Box>
        </HStack>
      </Form>
    </Card>
  );
};
