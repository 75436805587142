import type { IAccountingExport } from '../accounting/accounting-export.type';
import type { IBill } from '../bill/bill.type';
import type { IClient } from '../client/client.type';
import type { DEADLINE_TYPE } from '../common/deadline-type.constant';
import type { RequiredByKeys } from '../common/types/object.type';
import type { ICompany } from '../company/company.type';
import type { IContainer } from '../container/container.type';
import type { ICredit } from '../credit/credit.type';
import type { IDiscount } from '../discount/discount.type';
import type { IPaymentMethod } from '../payment-method/payment-method.type';
import type { IPdf } from '../pdf/pdf.type';
import type { IProject } from '../project/project.type';
import type { IVatDiscountedBases } from '../vat/vat-bases.type';
import type { IAccountingConfigSalesAccount } from '../accounting/accounting-config-sales-account.type';

export enum INVOICE_STATUSES {
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export interface IInvoice {
  id: number;

  groupId: number;

  historyFamilyId: string;

  subGroupId: number;

  name: string;

  invoiceNumber: string | null;

  purchaseOrderNumber: string | null;

  isArchived: boolean;

  billingDate: Date;

  completionDate: Date | null;

  hasReversalOfLiability: boolean;

  amountWithoutDiscountExVAT: number;

  amountExVAT: number;

  amountIncVAT: number;

  deadlineType: DEADLINE_TYPE;

  paymentTerm: number;

  note: string;

  vatDistribution: IVatDiscountedBases;

  status: INVOICE_STATUSES;

  paymentMethod?: IPaymentMethod;

  company?: ICompany;

  client?: IClient;

  container?: IContainer;

  project?: IProject | null;

  discount?: IDiscount | null;

  bill?: IBill | null;

  pdf?: IPdf | null;

  credit?: ICredit | null;

  accountingExport?: IAccountingExport | null;

  tags: string[];

  accountingConfigSalesAccount?: IAccountingConfigSalesAccount | null;

  createdAt: Date;

  updatedAt: Date;
}

export type IInvoiceWithRelations = RequiredByKeys<
  IInvoice,
  'paymentMethod' | 'company' | 'client' | 'container' | 'project' | 'discount' | 'bill' | 'pdf' | 'credit'
>;
