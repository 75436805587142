import type { FC } from 'react';
import { useMemo } from 'react';
import { ActionMenu, GotoLink, PriceAdvanced, Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IOrder } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { isString } from 'lodash-es';

import { usePermissions } from 'features/role/hooks/usePermissions';

interface OrdersListProps {
  orders: IOrder[];

  onDeleteOrder(order: IOrder): void;

  isDirectPaymentSupplierInvoice?: boolean;
}

export const OrdersList: FC<OrdersListProps> = ({ orders, onDeleteOrder, isDirectPaymentSupplierInvoice = false }) => {
  const { t } = useTranslation(['supplierInvoices', 'global']);

  const hasCreateSupplierInvoicePermission = usePermissions([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  const templateColumns = useMemo(
    () =>
      [
        'auto',
        'auto',
        'auto',
        hasCreateSupplierInvoicePermission && !isDirectPaymentSupplierInvoice ? 'auto' : null,
      ].filter(isString),
    [hasCreateSupplierInvoicePermission, isDirectPaymentSupplierInvoice],
  );

  if (orders.length === 0) {
    return null;
  }

  return (
    <Table noShadow templateColumns={templateColumns}>
      <Table.Header>
        <Table.Cell>{t('supplierInvoices:associate.name')}</Table.Cell>
        <Table.Cell>{t('supplierInvoices:associate.supplier')}</Table.Cell>
        <Table.Cell right>{t('supplierInvoices:associate.amount')}</Table.Cell>
        {hasCreateSupplierInvoicePermission && !isDirectPaymentSupplierInvoice && <Table.Cell right />}
      </Table.Header>

      {orders.map((order) => (
        <Table.Row key={order.id}>
          <Table.Cell>
            <GotoLink fontSize="sm" to={`/purchases/orders/${order.id}`} label={order.name} />
          </Table.Cell>
          <Table.Cell>{order.supplier?.name}</Table.Cell>
          <Table.Cell right>
            <PriceAdvanced amount={order.amountExVAT} />
          </Table.Cell>

          {hasCreateSupplierInvoicePermission && !isDirectPaymentSupplierInvoice && (
            <Table.Cell right>
              <ActionMenu>
                <ActionMenu.Delete label={t('global:deleteAssociation')} onClick={() => onDeleteOrder(order)} />
              </ActionMenu>
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table>
  );
};
