import type { FC, PropsWithChildren, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useContext } from 'react';
import { Auth0Context } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useLocalStorage } from '@graneet/lib-ui';

import { Loading } from '../../common/components/Loading';
import { setUpProductTour } from '../services/product-tour.util';
import { setAxiosAuthToken } from '../services/app.util';
import { useStore } from '../../../store/store';

import { getEnvValue } from 'config/env';
import { ExternalApi } from 'config/externalApi';

interface AuthProviderProps extends PropsWithChildren {}

export function useAuth() {
  const auth0Context = useContext(Auth0Context);

  return useMemo(
    () => ({
      isAuthenticated: auth0Context.isAuthenticated,
      handleRedirectCallback: auth0Context.handleRedirectCallback,
      loginWithRedirect: auth0Context.loginWithRedirect,
      getAccessTokenSilently: auth0Context.getAccessTokenSilently,
      logout: async () => {
        await auth0Context.logout({
          clientId: getEnvValue('REACT_APP_AUTH0_CLIENT_ID'),
          logoutParams: {
            returnTo: getEnvValue('REACT_APP_AUTH0_RETURN_TO'),
          },
        });
      },
    }),
    [auth0Context],
  );
}

const InternalAuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const history = useHistory();

  const auth = useAuth();

  const [initialRoute, saveInitialRoute] = useLocalStorage('initialRoute', '');

  const setToken = useStore((selector) => selector.setToken);

  const startAuthentication = useCallback(async () => {
    // User is not authenticated with Auth0
    if (!auth.isAuthenticated) {
      try {
        // Maybe the callback URL has to be parsed
        await auth.handleRedirectCallback();
        history.push(initialRoute);
        saveInitialRoute(initialRoute);
      } catch (e) {
        // The URL doesn't contain callback data
        saveInitialRoute(`${history.location.pathname}${history.location.search}`);
        auth.loginWithRedirect();
      }
    }

    const token = await auth.getAccessTokenSilently();
    setUpProductTour();
    setAxiosAuthToken(token);

    setToken(token);
    ExternalApi.getExternalApi(token);
  }, [auth, history, initialRoute, saveInitialRoute, setToken]);

  /**
   * Kick-off authentication when app loads
   */
  useEffect(() => {
    startAuthentication();
    // Do not list `startAuthentication` as dependencies to prevent infinite login
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!auth.isAuthenticated) {
    return <Loading />;
  }

  return children;
};

/**
 * A login provider wrapper component which acts as dummy placeholder in E2E mode
 */
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => (
  <InternalAuthProvider>{children}</InternalAuthProvider>
);
