import { Trans, useTranslation } from 'react-i18next';
import { Onboarding, SimpleLockIcon } from '@graneet/lib-ui';
import type { FC } from 'react';

export const ViewAccessForbiddenScreen: FC = () => {
  const { t } = useTranslation(['global']);

  return (
    <Onboarding message={<Trans t={t} i18nKey="global:errors.forbidden" />} icon={<SimpleLockIcon boxSize={45} />} />
  );
};
