import { useTranslation } from 'react-i18next';
import { HStack, Text, VStack, Box } from '@chakra-ui/react';
import { SwitchField, SimpleHelpIcon, Tooltip } from '@graneet/lib-ui';
import type { FC } from 'react';

import type { PDFSettingsFormValues } from '../constants/pdf-settings.constant';
import { PDF_SETTINGS_FIELDS } from '../constants/pdf-settings.constant';

export const PdfContactsSettings: FC = () => {
  const { t } = useTranslation(['pdfSettings']);

  return (
    <VStack spacing={2} align="start">
      <SwitchField<PDFSettingsFormValues>
        name={PDF_SETTINGS_FIELDS.DISPLAY_CONTACTS}
        checkedValue
        uncheckedValue={false}
        label={
          <HStack spacing={2}>
            <Text color="black" fontSize="sm">
              {t('pdfSettings:settings.displayQuoteContacts.title')}
            </Text>
            <Tooltip label={t('pdfSettings:settings.displayQuoteContacts.tooltip')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          </HStack>
        }
      />
    </VStack>
  );
};
