import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { IAccountingDataResponseDTO } from '@graneet/business-logic';
import { Box, useDisclosure, Flex } from '@chakra-ui/react';
import { Button, Input, Modal, SimpleAlertIcon, SimpleArrowUpRightIcon } from '@graneet/lib-ui';
import { Form, HiddenField, useForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { differenceBy } from 'lodash-es';

import type { AccountingClientEditionBlockForm } from '../../../forms/accounting.form';
import { getAccountingClientAuxiliaryAccountFieldName } from '../../../forms/accounting.form';
import { AccountingClientEditionBlock } from '../AccountingClientEditionBlock';
import { populateDTOFromAccountingConfigForm } from '../../../forms/accounting-form.util';
import { useUpdateAccountingConfig } from '../../../services/accounting-config.api';

import { filterOnlyContaining } from 'features/common/services/common.util';

const AccountingClientEditionModal: FC<{
  isOpen: boolean;
  onClose(): void;
  clients: IAccountingDataResponseDTO['clients'];
}> = ({ isOpen, onClose, clients }) => {
  const { t } = useTranslation(['accounting', 'global']);

  const updateAccountingConfigMutation = useUpdateAccountingConfig();

  const form = useForm<AccountingClientEditionBlockForm>();
  useEffect(() => {
    const formValues: Partial<AccountingClientEditionBlockForm> = {};
    clients.forEach((client) => {
      formValues[getAccountingClientAuxiliaryAccountFieldName(client.id)] = client.auxiliaryAccount;
    });

    form.setFormValues(formValues);
  }, [clients, form]);

  const [search, setSearch] = useState('');
  const filteredClients = useMemo(
    () => filterOnlyContaining(clients, ['code', 'enterpriseName', 'auxiliaryAccount'], search),
    [clients, search],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const formValues = form.getFormValues();

    await updateAccountingConfigMutation.mutateAsync(populateDTOFromAccountingConfigForm(formValues));
    setIsSubmitting(false);

    onClose();
  }, [form, onClose, updateAccountingConfigMutation]);

  const filteredOutClients = useMemo(
    () => differenceBy(clients, filteredClients, (c) => c.id).map((c) => c.id),
    [filteredClients, clients],
  );

  return (
    <Form form={form}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t('accounting:clientEditionModal.title')}
        size="3xl"
        scrollBehavior="inside"
      >
        <Box mb={4}>
          <Input
            w={['100%', '100%', '20rem']}
            placeholder={t('global:words.c.research')}
            onChange={setSearch}
            value={search}
          />
        </Box>

        {/* If the client is not displayed, render a hidden field to not lose the value */}
        <AccountingClientEditionBlock clients={filteredClients} />

        {filteredOutClients.map((clientId) => (
          <HiddenField<AccountingClientEditionBlockForm>
            key={clientId}
            name={getAccountingClientAuxiliaryAccountFieldName(clientId)}
          />
        ))}

        <Modal.SecondaryButton onClick={onClose} isDisabled={isSubmitting}>
          {t('global:words.c.back')}
        </Modal.SecondaryButton>

        <Modal.PrimaryButton onClick={onSubmit} isLoading={isSubmitting}>
          {t('accounting:clientEditionModal.cta')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};

interface AccountingClientEditionButtonProps {
  clients: IAccountingDataResponseDTO['clients'];
}

export const AccountingClientEditionButton: FC<AccountingClientEditionButtonProps> = ({ clients }) => {
  const { t } = useTranslation(['accounting']);

  const modal = useDisclosure();

  const clientsWithMissingAuxiliaryAccount = useMemo(
    () => clients.filter((client) => !client.auxiliaryAccount),
    [clients],
  );

  const numberOfMissingCodes = clientsWithMissingAuxiliaryAccount.length;

  if (numberOfMissingCodes === 0) {
    return null;
  }

  return (
    <Flex w="20rem" justifyContent="center">
      <Button
        variant="outline"
        colorScheme="gray"
        size="sm"
        onClick={modal.onOpen}
        leftIcon={<SimpleAlertIcon stroke="yellow.500" />}
        rightIcon={<SimpleArrowUpRightIcon />}
      >
        {t('accounting:missingClientAuxiliaryCode', { count: numberOfMissingCodes })}
      </Button>

      <AccountingClientEditionModal
        onClose={modal.onClose}
        isOpen={modal.isOpen}
        clients={clientsWithMissingAuxiliaryAccount}
      />
    </Flex>
  );
};
