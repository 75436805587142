import dayjs from 'dayjs';
import { GridItem, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback } from 'react';

interface TimeTableHeaderDayProps {
  day: dayjs.Dayjs;
  onHover: (week: number | null) => void;
  week: number;
}

export const MonthlyTimeTableHeaderDay: FC<TimeTableHeaderDayProps> = ({ day, onHover, week }) => {
  const isWeekEnd = [0, 6].includes(day.day());
  const isToday = dayjs().isSame(day, 'day');

  const handleHovered = useCallback(
    (isIn: boolean) => () => {
      onHover(isIn ? week : null);
    },
    [onHover, week],
  );

  return (
    <GridItem
      flex={1}
      py={3}
      alignItems="center"
      justifyContent="center"
      {...{
        ...(isWeekEnd ? { background: 'gray.50' } : { background: 'white' }),
      }}
      onMouseEnter={handleHovered(true)}
      onMouseLeave={handleHovered(false)}
    >
      <Text align="center" color="gray.700" {...(isToday ? { fontWeight: 700 } : { fontWeight: 600 })}>
        {day.format('D')}
      </Text>
    </GridItem>
  );
};
