import type { FC } from 'react';
import { memo } from 'react';
import { Box, GridItem, Skeleton, VisuallyHidden } from '@chakra-ui/react';
import { HiddenField } from 'graneet-form';
import { CUMULATIVE_INPUT_TYPE } from '@graneet/business-logic';
import { DeepTable } from '@graneet/lib-ui';

import { useProgressStatementItem } from '../../hooks/useProgressStatementTree';
import { getItemCumulativeFieldName } from '../../forms/progress-statement-edit-item-form';
import { getProgressStatementDeepTableOffset } from '../../services/progress-statement-tree.util';

interface ProgressStatementDeepTableVirtualizedItemLineProps {
  id: number;

  depth: number;
}

export const ProgressStatementDeepTableVirtualizedItemLine: FC<ProgressStatementDeepTableVirtualizedItemLineProps> =
  memo(({ id, depth }) => {
    const { leaf: item } = useProgressStatementItem(id);

    if (item.isOptional) {
      return null;
    }

    const cumulativeProgressPercentageField = getItemCumulativeFieldName(id, CUMULATIVE_INPUT_TYPE.PERCENTAGE);
    const cumulativeProgressQuantityField = getItemCumulativeFieldName(id, CUMULATIVE_INPUT_TYPE.QUANTITY);
    const cumulativeAmountField = getItemCumulativeFieldName(id, CUMULATIVE_INPUT_TYPE.AMOUNT);

    return (
      <Box ml={2}>
        <DeepTable.Row
          data-testid="row:progressStatement.item"
          color="gray.600"
          background="white"
          offset={getProgressStatementDeepTableOffset(depth)}
        >
          {/* With search, you can go to an item */}
          <VisuallyHidden>{item.code}</VisuallyHidden>
          <VisuallyHidden>{item.description}</VisuallyHidden>

          <HiddenField name={cumulativeProgressPercentageField} />
          <HiddenField name={cumulativeProgressQuantityField} />
          <HiddenField name={cumulativeAmountField} />

          <GridItem colSpan={9}>
            <DeepTable.Cell flex={1}>
              <Skeleton w="100%" h="1rem" />
            </DeepTable.Cell>
          </GridItem>
        </DeepTable.Row>
      </Box>
    );
  });
