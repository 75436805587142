import { useCallback, useMemo } from 'react';
import type { KeyboardEvent } from 'react';

const KEY_LEFT = 'ArrowLeft';
const KEY_UP = 'ArrowUp';
const KEY_RIGHT = 'ArrowRight';
const KEY_DOWN = 'ArrowDown';
const KEY_PAGE_UP = 'PageUp';
const KEY_PAGE_DOWN = 'PageDown';
const KEY_PAGE_HOME = 'Home';
const KEY_PAGE_END = 'End';
const KEY_ENTER = 'Enter';

interface UseKeyboard {
  isNavigationKey: (key: string) => boolean;
  isEnterKey: (key: string) => boolean;
  handleKeyDown: (handleSave: (v: string) => void) => (e: KeyboardEvent<HTMLInputElement>) => void;
}

export const useKeyboard = (): UseKeyboard => {
  const isNavigationKey = useCallback(
    (key: string) =>
      key === KEY_LEFT ||
      key === KEY_RIGHT ||
      key === KEY_UP ||
      key === KEY_DOWN ||
      key === KEY_PAGE_DOWN ||
      key === KEY_PAGE_UP ||
      key === KEY_PAGE_HOME ||
      key === KEY_ENTER ||
      key === KEY_PAGE_END,
    [],
  );

  const isEnterKey = useCallback((key: string) => key === KEY_ENTER, []);

  const handleKeyDown = useCallback(
    (handleSave: (v: string) => void) => (e: KeyboardEvent<HTMLInputElement>) => {
      const { key } = e;

      if (isNavigationKey(key)) {
        // this stops the grid from receiving the event and executing keyboard navigation
        e.stopPropagation();
      }
      if (isEnterKey(key)) {
        handleSave(e.currentTarget.value);
      }
    },
    [isNavigationKey, isEnterKey],
  );

  return useMemo(
    () => ({
      isNavigationKey,
      isEnterKey,
      handleKeyDown,
    }),
    [isNavigationKey, isEnterKey, handleKeyDown],
  );
};
