import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PERMISSION } from '@graneet/business-logic';

import type { ImportSpreadsheetField } from 'features/import/components/SpreadsheetImport';
import { usePermissions } from 'features/role/hooks/usePermissions';

export type ImportClientField =
  | 'code'
  | 'enterpriseName'
  | 'siren'
  | 'address'
  | 'postalCode'
  | 'city'
  | 'country'
  | 'auxiliaryAccount';

export const useImportClientFields = (): ImportSpreadsheetField<ImportClientField>[] => {
  const { t } = useTranslation(['clients', 'global']);
  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  return useMemo<ImportSpreadsheetField<ImportClientField>[]>(() => {
    const fields: ImportSpreadsheetField<ImportClientField>[] = [
      {
        label: t('clients:fields.code'),
        key: 'code',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Code'], // Column name in template
        example: t('clients:import.example.code'),
      },
      {
        label: t('clients:fields.enterpriseName'),
        key: 'enterpriseName',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Dénomination*'],
        example: t('clients:import.example.enterpriseName'),
        validations: [
          {
            rule: 'required',
            errorMessage: t('global:spreadsheetImport.common.errorMandatory'),
          },
        ],
      },
      {
        label: t('clients:fields.siren'),
        key: 'siren',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['SIREN'],
        example: t('clients:import.example.siren'),
      },
      {
        label: t('global:address.label'),
        key: 'address',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Adresse*'],
        example: t('clients:import.example.address'),
        validations: [
          {
            rule: 'required',
            errorMessage: t('global:spreadsheetImport.common.errorMandatory'),
          },
        ],
      },
      {
        label: t('global:address.postalCode'),
        key: 'postalCode',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Code postal'],
        example: t('clients:import.example.postalCode'),
      },
      {
        label: t('global:address.city'),
        key: 'city',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Ville'],
        example: t('clients:import.example.city'),
      },
      {
        label: t('global:address.country'),
        key: 'country',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Pays'],
        example: t('clients:import.example.country'),
      },
    ];

    if (hasUpdateAccountingPermission) {
      fields.push({
        label: t('clients:fields.auxiliaryAccount'),
        key: 'auxiliaryAccount',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Code comptable auxiliaire'],
        example: t('clients:import.example.auxiliaryAccount'),
      });
    }

    return fields;
  }, [t, hasUpdateAccountingPermission]);
};
