import {
  QuoteSalesDiscountType,
  type QuoteSalesDiscountUpdateDTO,
  type QuoteSalesDiscountCreateDTO,
} from '@org/graneet-bff-client';
import { QUOTE_SALES_DISCOUNT_TYPE, type QuoteSalesDiscount } from '@org/quotation-lib';

export const mapSalesDiscountType = (value: QUOTE_SALES_DISCOUNT_TYPE) => {
  switch (value) {
    case QUOTE_SALES_DISCOUNT_TYPE.AMOUNT:
      return QuoteSalesDiscountType.Amount;
    case QUOTE_SALES_DISCOUNT_TYPE.PERCENTAGE:
      return QuoteSalesDiscountType.Percentage;
    default:
      throw new Error('margin enum type is not matching');
  }
};

export const mapQuoteSalesDiscountToQuoteSalesDiscountUpdateDTO = (
  quoteSalesDiscount: QuoteSalesDiscount,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteSalesDiscountUpdateDTO => {
  const quoteSaleDiscountObject = quoteSalesDiscount.export();
  return {
    quoteId,
    timestamp,
    clientRequestId,
    amount: quoteSaleDiscountObject.amount,
    percentage: quoteSaleDiscountObject.percentage,
    type: mapSalesDiscountType(quoteSaleDiscountObject.type),
  };
};

export const mapQuoteSalesDiscountToQuoteSalesDiscountCreateDTO = (
  quoteSalesDiscount: QuoteSalesDiscount,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteSalesDiscountCreateDTO => {
  const quoteSaleDiscountObject = quoteSalesDiscount.export();
  return {
    quoteSalesDiscountId: quoteSalesDiscount.getId(),
    quoteId,
    timestamp,
    clientRequestId,
    amount: quoteSaleDiscountObject.amount,
    percentage: quoteSaleDiscountObject.percentage,
    type: mapSalesDiscountType(quoteSaleDiscountObject.type),
  };
};
