import { useCallback } from 'react';
import type { Quote } from '@org/quotation-lib';
import { QuoteNodeCreateChildUseCase } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { mapQuoteNodeToUpsertSubItemDTO } from 'features/quotation/quote-common/mappers/quoteSubItemMapper';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { updateQuoteNewlyCreatedNodeId } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteSubItemCreate() {
  const { quoteSubItemsProxyApi, quoteNodeDeletesProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (
      parentId: string | undefined,
      position?: { prevSiblingId: string | null; nextSiblingId: string | null },
    ): ICommand => {
      if (!parentId) {
        throw new Error('parentId is required');
      }

      return {
        execute(quote: Quote) {
          try {
            const quoteNode = quote.getTree().getNodeOrThrow(parentId);
            let prevSiblingId = null;
            let nextSiblingId = null;

            if (position) {
              prevSiblingId = position.prevSiblingId;
              nextSiblingId = position.nextSiblingId;
            } else {
              prevSiblingId = quoteNode.getLastChild()?.getId() ?? null;
            }

            const quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteSubItem(
              quoteNode,
              prevSiblingId,
              nextSiblingId,
              this.oldValue && this.oldValue.nodeId ? this.oldValue.nodeId : undefined,
              this.oldValue && this.oldValue.contentId ? this.oldValue.contentId : undefined,
            );
            this.oldValue = {
              nodeId: quoteNodeCreated?.getId(),
              contentId: quoteNodeCreated?.getContent().getId(),
            };
            if (!quoteNodeCreated) {
              throw new Error('Failed to create node');
            }

            // TODO: Handle both root and hidden cost rows
            updateQuoteNewlyCreatedNodeId(quoteNodeCreated?.getId() ?? null);
            quoteSetToStore(quote);

            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteSubItemsProxyApi
              .createQuoteSubItem(
                mapQuoteNodeToUpsertSubItemDTO(quoteNodeCreated, quote.getId(), clientRequestId, timestamp),
              )
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));

            return true;
          } catch (e: any) {
            return quoteError(e.message);
          }
        },
        undo(quote: Quote) {
          try {
            const quoteSubItem = quote.getTree().getQuoteSubItemOrThrow(this.oldValue.nodeId);
            quoteSubItem.deleteQuoteItem();
            quoteSetToStore(quote);

            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteNodeDeletesProxyApi
              .deleteQuoteNode({
                quoteId: quote.getId(),
                timestamp,
                clientRequestId,
                nodeId: this.oldValue.nodeId,
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
            return true;
          } catch (e: any) {
            return quoteError(e.message);
          }
        },
      };
    },
    [quoteError, quoteNodeDeletesProxyApi, quoteSetClientRequestToStore, quoteSetToStore, quoteSubItemsProxyApi],
  );
}
