import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleFlipForwardIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" strokeWidth="2" fill="none" stroke="#334e68" {...props}>
    <path
      d="M21 9H7.5C5.01472 9 3 11.0147 3 13.5C3 15.9853 5.01472 18 7.5 18H12M21 9L17 5M21 9L17 13"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
