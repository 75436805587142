import type { FC } from 'react';
import { useCallback, useRef, useEffect, useMemo } from 'react';
import { ActionMenu, Tooltip, SimpleDownloadIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IContractListingResponseDTO } from '@graneet/business-logic';
import { PDF_STATUSES, PERMISSION } from '@graneet/business-logic';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface ContractActionsProps {
  isDeletable?: boolean;
  onEdit(contractId: number): void;
  onDelete(): void;
  onPDFDownload(): void;
  contract: IContractListingResponseDTO;
}

export const ContractActions: FC<ContractActionsProps> = ({
  isDeletable = false,
  onEdit,
  onDelete,
  onPDFDownload,
  contract,
}) => {
  const { t } = useTranslation(['contracts', 'global']);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const updateButtonDisabledProps = useDisabledButtonProps([PERMISSION.UPDATE_CONTRACTS]);
  const deleteButtonDisabledProps = useDisabledButtonProps([PERMISSION.UPDATE_CONTRACTS]);

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  const deleteButtonProps = useMemo(() => deleteButtonDisabledProps(), [deleteButtonDisabledProps]);

  const handleEdit = useCallback(() => {
    onEdit(contract.id);
  }, [contract.id, onEdit]);

  const tooOldTooltip =
    !contract.quote && !contract.quoteUUID
      ? {
          tooltip: t('contracts:tooltips.impossibleToUpdateBecauseTooOld'),
          doNotAllowUpdates: true,
        }
      : {
          tooltip: undefined,
          doNotAllowUpdates: false,
        };

  const updateButtonDisabled = updateButtonDisabledProps();

  return (
    <ActionMenu>
      <ActionMenu.Edit
        onClick={handleEdit}
        label={t('contracts:actions.edit')}
        isDisabled={tooOldTooltip.doNotAllowUpdates || updateButtonDisabled.isDisabled}
        tooltip={tooOldTooltip.tooltip || updateButtonDisabled.tooltip}
      />

      {contract.lastPdf?.status === PDF_STATUSES.GENERATED && (
        <ActionMenu.Action
          onClick={onPDFDownload}
          label={t('contracts:actions.downloadPdf')}
          icon={<SimpleDownloadIcon />}
        />
      )}

      {contract.lastPdf?.status === PDF_STATUSES.GENERATING && (
        <ActionMenu.Action
          label={t('contracts:actions.downloadPdf')}
          tooltip={t('contracts:tooltips.pdfNotReady')}
          icon={<SimpleDownloadIcon />}
          isDisabled
        />
      )}

      {contract.lastPdf?.status === PDF_STATUSES.ERROR && (
        <ActionMenu.Action
          label={t('contracts:actions.downloadPdf')}
          tooltip={t('global:pdf.pdfError')}
          icon={<SimpleDownloadIcon />}
          isDisabled
        />
      )}

      <ActionMenu.Separator />

      <Tooltip shouldWrapChildren placement="bottom-start" isDisabled={tooOldTooltip.doNotAllowUpdates || isDeletable}>
        <ActionMenu.Delete
          onClick={onDelete}
          label={t('contracts:actions.delete')}
          tooltip={tooOldTooltip.tooltip || deleteButtonProps.tooltip}
          isDisabled={tooOldTooltip.doNotAllowUpdates || !isDeletable || deleteButtonProps.isDisabled}
        />
      </Tooltip>
    </ActionMenu>
  );
};
