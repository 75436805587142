import { createQuoteLotAddedCallback } from './createQuoteLotAddedCallback';
import { createQuoteLotComponentTypeUpdatedCallback } from './createQuoteLotComponentTypeUpdatedCallback';
import { createQuoteLotDeletedCallback } from './createQuoteLotDeletedCallback';
import { createQuoteLotTransformChildCallback } from './createQuoteLotTransformChildCallback';
import { createQuoteLotUpdatedCallback } from './createQuoteLotUpdatedCallback';
import { createQuoteLotUpdatedMarginCallback } from './createQuoteLotUpdatedMarginCallback';

import type {
  QuoteLotComponentType,
  QuoteLotUpdateMarginWsMessage,
  QuoteWsMessage,
} from 'features/quotation/quote-common/interfaces/quoteWsMessage';

export const quoteLotCallbacks = (quoteId: String) => [
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteLot:added',
    callback: (message: QuoteWsMessage) => createQuoteLotAddedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteLot:deleted',
    callback: (message: QuoteWsMessage) => createQuoteLotDeletedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteLot:updated',
    callback: (message: QuoteWsMessage) => createQuoteLotUpdatedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteLot:transformed',
    callback: (message: QuoteWsMessage & { oldType: string; newType: string }) =>
      createQuoteLotTransformChildCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: `QuoteLotComponentType:updated`,
    callback: (message: QuoteLotComponentType) => createQuoteLotComponentTypeUpdatedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'quote-lot-update-margin',
    callback: (message: QuoteLotUpdateMarginWsMessage) => createQuoteLotUpdatedMarginCallback(message),
  },
];
