export interface MultipleFileUploadTranslations {
  attachment: string;
  attachments: string;
  drop: string;
  lang: string;
}
export const multipleFileUploadTranslations: MultipleFileUploadTranslations = {
  attachment: 'pièce jointe',
  attachments: 'pièces jointes',
  drop: 'Déposer',
  lang: 'fr',
};

export const configureDefaultLabels = (translation: MultipleFileUploadTranslations) => {
  Object.assign(multipleFileUploadTranslations, translation);
};
