import { createContext, useContext, useMemo } from 'react';

import { multiplyFloating, divideFloating, isNumberFinite } from '../../utils';

export type Currency = {
  code: string;
  locale: string;
  precision: number;
};

const CurrencyContext = createContext<Currency>({
  code: '',
  locale: '',
  precision: 0,
});

export const CurrencyProvider = CurrencyContext.Provider;

type UseCurrencyOptions = {
  forceSign?: boolean;
  withoutPrecision?: boolean;
  compact?: boolean;
};

export type UseCurrencyApi = {
  formatAsAmount(value: number, options?: UseCurrencyOptions): string;
  formatAsAmountOrEmpty(value: number | undefined, options?: UseCurrencyOptions): string;
  formatAsGraphAmount(value: number): string;
  mapAmountToNumber(value: string | number): number;
  mapNumberToAmount(value: number): number;
  mapStringNumberToAmount(value: string): string;
  formatAsStringAmount(value: string, options?: UseCurrencyOptions): string;
  mapAmountToStringNumber(value: string): string;

  defaultFormatter: (options?: UseCurrencyOptions) => Intl.NumberFormat;
  intFormatter: (options?: UseCurrencyOptions) => Intl.NumberFormat;
  formatter: (options?: Intl.NumberFormatOptions) => Intl.NumberFormat;

  symbol: string;
  currency: Currency;
};

const buildCurrencyFormatterGenerator =
  (code?: string, locale?: string) =>
  (precision: number) =>
  (options: UseCurrencyOptions = {}) =>
    Intl.NumberFormat(locale, {
      style: 'currency',
      currency: code,
      minimumFractionDigits: options.withoutPrecision === true ? 0 : precision,
      maximumFractionDigits: options.withoutPrecision === true ? 0 : precision,
      signDisplay: options.forceSign === true ? 'always' : 'auto',
      notation: options.compact === true ? 'compact' : 'standard',
    });

export const SIGN_EMPTY_AMOUNT = '-';
const USE_CURRENCY_DEFAULT_RETURNS: UseCurrencyApi = {
  formatAsAmount: () => '',
  formatAsAmountOrEmpty: () => SIGN_EMPTY_AMOUNT,
  formatAsGraphAmount: () => '',
  mapAmountToNumber: () => 0,
  mapNumberToAmount: () => 0,
  mapStringNumberToAmount: () => '',
  formatAsStringAmount: () => '',
  mapAmountToStringNumber: () => '',

  defaultFormatter: () => new Intl.NumberFormat(),
  intFormatter: () => new Intl.NumberFormat(),
  formatter: () => new Intl.NumberFormat(),

  symbol: '',
  currency: {
    code: '',
    locale: '',
    precision: 0,
  },
};

export const useCurrency = (): UseCurrencyApi => {
  const currency = useContext(CurrencyContext);

  return useMemo<UseCurrencyApi>(() => {
    if (!currency) {
      return USE_CURRENCY_DEFAULT_RETURNS;
    }

    const { code, locale, precision } = currency;

    const generateCurrencyFormatter = buildCurrencyFormatterGenerator(code, locale);
    const defaultFormatter = generateCurrencyFormatter(precision);
    const intFormatter = generateCurrencyFormatter(0);
    const formatter = (options?: Intl.NumberFormatOptions | undefined) => Intl.NumberFormat(locale, options);

    // !warning: this opperation is duplicated in packages/business-logic/currency/currency.utils.ts
    const factor = 10 ** precision;

    // Formatting functions

    /**
     * @example
     * 177399 => 1773.99
     * !warning: this function is duplicated in packages/business-logic/currency/currency.utils.ts
     */
    const mapNumberToAmount = (number: number): number => divideFloating(number, factor);

    /**
     * @example
     * '177399' => '1773.99'
     * !warning: this function is duplicated in packages/business-logic/currency/currency.utils.ts
     */
    const mapStringNumberToAmount = (stringNumber: string): string =>
      divideFloating(Number(stringNumber), factor).toString();

    /**
     * @example
     *  1773.99  => 177399
     * '1773.99' => 177399
     * !warning: this function is duplicated in packages/business-logic/currency/currency.utils.ts
     */
    const mapAmountToNumber = (numberOrStringAmount: string | number): number =>
      multiplyFloating(
        isNumberFinite(numberOrStringAmount) ? numberOrStringAmount : Number(numberOrStringAmount),
        factor,
      );

    /**
     * @example
     * '1773.99' => '177399'
     */
    const mapAmountToStringNumber = (numberOrStringAmount: string): string =>
      multiplyFloating(
        isNumberFinite(numberOrStringAmount) ? numberOrStringAmount : Number(numberOrStringAmount),
        factor,
      ).toString();

    /**
     * @example
     * 656576 => '6565.76€'
     * 656500    => '6565.00€'
     */
    const formatAsAmount = (amountAsNumber: number, options?: UseCurrencyOptions): string =>
      defaultFormatter(options).format(mapNumberToAmount(amountAsNumber));

    /**
     * @example
     * '656576' => '6565.76€'
     * '656500'    => '6565.00€'
     */
    const formatAsStringAmount = (amountAsString: string, options?: UseCurrencyOptions): string =>
      defaultFormatter(options)
        .format(mapNumberToAmount(Number(amountAsString)))
        .toString();
    /**
     * @example
     * 6565.76   => '6565.76€'
     * undefined => '-'
     * 0         => '-'
     */
    const formatAsAmountOrEmpty = (amount: number | undefined, options?: UseCurrencyOptions): string =>
      !amount ? SIGN_EMPTY_AMOUNT : formatAsAmount(amount, options);

    /**
     * @example
     * 16765 => '17k €'
     * 16765 => '17k CFA'
     * 16765 => '$17k'
     */
    const formatAsGraphAmount = (fullAmount: number): string =>
      intFormatter({ compact: true })
        .formatToParts(mapNumberToAmount(fullAmount))
        .reduce((str, part, i) => {
          switch (part.type) {
            case 'currency':
              return `${str}${i === 0 ? '' : ' '}${part.value}`;
            case 'literal':
              return str;
            default:
              return `${str}${part.value}`;
          }
        }, '');

    /**
     * @example
     * code = 'UNKNOWN' => '?'
     * code = 'EUR'     => '€'
     * code = 'XOF'     => 'CFA'
     */
    const symbol: string = intFormatter()
      .formatToParts(0)
      .reduce((symbolMemory, part) => {
        switch (part.type) {
          case 'currency':
            return part.value;
          default:
            return symbolMemory;
        }
      }, '?');

    return {
      // For displaying amounts
      formatAsAmount,
      formatAsAmountOrEmpty,
      formatAsGraphAmount,
      formatAsStringAmount,

      // For converting (ddb stored amount <=> displayed amount)
      mapAmountToNumber,
      mapNumberToAmount,
      mapStringNumberToAmount,
      mapAmountToStringNumber,

      // For deriving formats from currency
      defaultFormatter,
      intFormatter,
      formatter,

      currency,
      symbol,
    };
  }, [currency]);
};
