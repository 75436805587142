/**
 * Declaring our current flags:
 * - Add a value here whenever you start a new work/feature
 * - Remove it once the feature is fully validated in prod
 * For each key/value pair:
 * - The value must match that of the feature flag saved in
 *   database
 * - The key must be the value formatted in UPPER_SNAKE_CASE
 *
 * @example
 * ```
 * export const FEATURE_FLAGS = {
 *   DISPLAY_SOMETHING: 'display-something',
 *   CUSTOMER_ONBOARDING_FLOW: 'customer-onboarding-flow',
 * }
 * ```
 */
export enum FEATURE_FLAGS {
  // -- Permanent feature flags
  /**
   * Display banner to see the current environment
   */
  DISPLAY_ENV_BANNER = 'display-env-banner',

  /**
   * Allow user to have user permissions
   */
  USER_PERMISSIONS = 'user-permissions',

  /**
   * Allow user to send planning by SMS
   */
  PLANNING_SEND = 'planning-send',

  /**
   * Allow user to have bill reminders
   */
  BILL_EMAIL_REMIND = 'bill-email-remind',

  /**
   * Allow user to import by email
   */
  IMPORT_BY_EMAIL = 'import-by-email',

  /**
   * Allow user to handle banking accounts
   */
  // @[ff: banking-account-connection] Temporary code have this comment
  BANKING_ACCOUNT_CONNECTION = 'banking-account-connection',

  // -- Temporary feature flags

  // @[ff: quote-v2]
  QUOTE_V2 = 'quote-v2',

  // @[ff: supplier-invoice-batch-import]
  SUPPLIER_INVOICE_BATCH_IMPORT = 'supplier-invoice-batch-import',

  // @[ff: accounting-standards]
  ACCOUNTING_STANDARDS = 'accounting-standards',

  // @[ff: workflow-validation]
  WORKFLOW_VALIDATION = 'workflow-validation',
  // @[ff: workflow-validation-improvement]
  WORKFLOW_VALIDATION_IMPROVEMENT = 'workflow-validation-improvement',
  // @[ff: workflow-validation-access]
  WORKFLOW_VALIDATION_ACCESS = 'workflow-validation-access',

  // @[ff: quote-supplysheet]
  QUOTE_SUPPLYSHEET = 'quote-supplysheet',

  // @[ff: quote-autosuggest]
  QUOTE_AUTOSUGGEST = 'quote-autosuggest',

  // @[ff: export-tables]
  EXPORT_TABLES = 'export-tables',

  // @[ff: export-tables]
  EXPORT_QUOTES_TABLE = 'export-quotes-table',

  // Accounting breakdown
  // @[ff: ACCOUNTING_BREAKDOWN_PURCHASES_ACCOUNT]
  ACCOUNTING_BREAKDOWN_PURCHASES_ACCOUNT = 'accounting-breakdown::purchases-account',
  ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_LINES = 'accounting-breakdown::supplier-invoice-lines',
  ACCOUNTING_BREAKDOWN_IMPORT_BY_EMAIL = 'accounting-breakdown::import-by-email',
  // @[ff: ACCOUNTING_BREAKDOWN_ORDER_ITEM_COMPONENT_TYPE]
  ACCOUNTING_BREAKDOWN_ORDER_ITEM_COMPONENT_TYPE = 'accounting-breakdown::order-item-component-type',
  // @[ff: ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_IMPORT_IMPROVEMENT]
  ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_IMPORT_IMPROVEMENT = 'accounting-breakdown::supplier-invoice-import-improvement',

  // @[ff: accounting-payment-methods]
  ACCOUNTING_PAYMENT_METHODS = 'accounting-payment-methods',

  // @[ff: quote-disable-v1]
  QUOTE_DISABLE_V1 = 'quote-disable-v1',

  // @[ff: accounting-standard-vat-rates]
  ACCOUNTING_STANDARD_VAT_RATES = 'accounting-standard-vat-rates',

  // @[ff: quotation-undo-redo]
  QUOTATION_UNDO_REDO = 'quotation-undo-redo',

  // @[ff: project-global-parameters]
  PROJECT_GLOBAL_PARAMETERS = 'project_global_parameters',

  // @[ff: project-financial-summary]
  PROJECT_FINANCIAL_SUMMARY = 'project-financial-summary',

  // @[ff: directory-system-project]
  DIRECTORY_SYSTEM_PROJECT = 'directory-system-project',

  // @[ff: kyc-validation]
  KYC_VALIDATION = 'kyc-validation',

  // @[ff: virality]
  VIRALITY = 'virality',
}
