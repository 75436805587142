import type { IProjectFinancialSummary } from '@graneet/business-logic';
import type { FC } from 'react';
import { formatPercentage } from '@graneet/lib-ui';

import { DisplayCellWithIndicator } from './DisplayCellWithIndicator';

export const ProjectTableMarginOnInvoicedAmountsPercentageCell: FC<{ data?: IProjectFinancialSummary }> = ({
  data,
}) => (
  <DisplayCellWithIndicator value={data?.marginOnInvoicedAmountsPercentage ?? 0}>
    {formatPercentage(data?.marginOnInvoicedAmountsPercentage ?? 0, {
      maximumFractionDigits: 2,
    })}
  </DisplayCellWithIndicator>
);
