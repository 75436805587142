import type { FC } from 'react';
import { useContext } from 'react';
import { Box } from '@chakra-ui/react';

import { SortingContext } from '../../contexts/SortingContext.context';
import { SORTING_DIRECTIONS } from '../../../PaginatedData/constants';

import { RawCell } from './RawCell';
import type { HeaderCellProps } from './Cell.type';

const SORTED_STYLES = {
  color: 'greenBrand.medium',
  fontWeight: 500,
};

const SORTABLE_STYLES = {
  cursor: 'pointer',
  _hover: {
    color: 'greenBrand.medium',
  },
};

export const HeaderCell: FC<HeaderCellProps> = ({ children, sortBy, ...props }) => {
  const { onSorting, currentSorting } = useContext(SortingContext);

  const isSortable = !!onSorting && !!sortBy;
  const isSorted = !!sortBy && currentSorting && currentSorting.order === sortBy;

  const handleSorting = () =>
    isSortable &&
    onSorting(
      sortBy,
      (() => {
        if (!currentSorting || currentSorting.order !== sortBy) {
          return SORTING_DIRECTIONS.ASC;
        }

        if (currentSorting.direction === SORTING_DIRECTIONS.ASC) {
          return SORTING_DIRECTIONS.DESC;
        }

        return null;
      })(),
    );

  return (
    <RawCell
      background="gray.100"
      onClick={handleSorting}
      {...props}
      {...(isSorted && SORTED_STYLES)}
      {...(isSortable && SORTABLE_STYLES)}
    >
      {children}
      {isSorted && (
        <Box ml="0.375rem">
          <i
            className={
              (currentSorting && currentSorting.direction) === SORTING_DIRECTIONS.DESC ? 'ri-sort-desc' : 'ri-sort-asc'
            }
          />
        </Box>
      )}
    </RawCell>
  );
};
