import type { FlexProps } from '@chakra-ui/react';
import type { IHistoryDTO, IHistoryExtended, HISTORY_ENTITY_TYPE } from '@graneet/business-logic';
import { Card, Tabs, type RichTextConfiguration } from '@graneet/lib-ui';
import { groupBy } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from '../services/history.api';
import { ActiveTab, entityTabsConfig } from '../services/history.service';

import { HistoryTimeline } from './HistoryTimeline';

import { useEmail } from 'features/history/contexts/EmailContext';
import type { FileItemProps } from 'features/file/components/FilesCard/components/FileItem';

export type IArchiveDetailsExtended = IHistoryExtended<'archive'>['actionDetails'];

interface HistoryProps {
  id: number | string;
  entity: HISTORY_ENTITY_TYPE;
  emailTemplateRichTextConfiguration: RichTextConfiguration;
  cardPosition?: FlexProps & { noShadow?: boolean };
  addActionsProps?: (archiveDetails: IArchiveDetailsExtended) => Pick<FileItemProps, 'rightContent'> | null;
}

export const HistoryCard = ({
  id,
  entity,
  emailTemplateRichTextConfiguration,
  cardPosition,
  addActionsProps,
}: HistoryProps) => {
  const { t } = useTranslation(['global']);
  const { emailSent, resetEmailSent } = useEmail();

  const history = useHistory(entity, String(id));

  useEffect(() => {
    if (emailSent) {
      (async () => {
        await history.refetch();
      })();
      resetEmailSent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.refetch, emailSent, resetEmailSent]);

  const groupedActionData = useMemo(() => groupBy(history.data, 'actionType'), [history.data]);

  const renderHistoryTimeline = useCallback(
    (historyData: IHistoryDTO) => (
      <HistoryTimeline
        history={historyData || []}
        emailTemplateRichTextConfiguration={emailTemplateRichTextConfiguration}
        entity={entity}
        addActionsProps={addActionsProps}
      />
    ),
    [emailTemplateRichTextConfiguration, entity, addActionsProps],
  );

  return (
    <Card width="100%" {...cardPosition}>
      <Tabs
        data={[
          ...entityTabsConfig[entity].activeTabs.map((tab) => {
            const dataToDisplay = tab !== ActiveTab.ALL_ACTIVITY ? groupedActionData[tab] : history.data;
            const count = tab !== ActiveTab.ALL_ACTIVITY ? groupedActionData[tab]?.length : history.data.length;
            return {
              id: tab,
              title: t(`global:words.${tab}`, { count: count || 0 }),
              content: dataToDisplay ? renderHistoryTimeline(dataToDisplay) : null,
            };
          }),
        ]}
      />
    </Card>
  );
};
