import { Text } from '@chakra-ui/react';
import { getLocalDateFormat, isDate } from '@graneet/lib-ui';
import dayjs from 'dayjs';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { QuoteEditInformationForm } from '../forms/quote-edit-wizard';

export const QuoteEndValidityDate: FC = () => {
  const form = useFormContext<QuoteEditInformationForm>();
  const { date, validityDuration } = useOnChangeValues(form, ['date', 'validityDuration']);
  const { t } = useTranslation(['quote']);

  if (!validityDuration || !Number.isFinite(validityDuration) || !date || !isDate(date)) {
    return null;
  }

  const endDate = dayjs(date).add(validityDuration, 'day').format(getLocalDateFormat());
  return (
    <Trans t={t} i18nKey="quote:informationStep.validityDuration.endDate" values={{ endDate }}>
      description_start
      <Text as="span" color="greenBrand.light" fontWeight={600}>
        displayed_data
      </Text>
    </Trans>
  );
};
