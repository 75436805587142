import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleBookIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      d="M14 12.6667V10.6667C14 10.2933 13.7 10 13.3333 10H4.66666C3.19333 10 1.99999 11.1933 1.99999 12.6667C1.99999 13.0333 2.29333 13.3333 2.66666 13.3333C3.03333 13.3333 3.33333 13.0333 3.33333 12.6667C3.33333 11.9267 3.92666 11.3333 4.66666 11.3333H13.3333L12.6667 10.6667V12.6667C12.6667 13.0333 12.96 13.3333 13.3333 13.3333C13.7 13.3333 14 13.0333 14 12.6667ZM5.86666 15.3333H11.2C12.2667 15.3333 12.52 15.3067 12.9067 15.1133C13.28 14.92 13.5867 14.6133 13.78 14.2333C13.9733 13.84 13.9933 13.5867 13.9933 12.52V3.45333C13.9933 2.38 13.9667 2.12667 13.7733 1.74C13.58 1.36 13.2733 1.05333 12.8933 0.859999C12.5 0.659999 12.2467 0.639999 11.18 0.639999H5.84666C4.30666 0.639999 3.94666 0.666666 3.43333 0.926666C2.92666 1.18 2.51999 1.58667 2.26666 2.08667C1.99999 2.59333 1.97333 2.96 1.97333 4.49333V11.4267C1.97333 12.96 1.99999 13.32 2.25999 13.8333C2.51333 14.3333 2.91999 14.74 3.41999 14.9933C3.92666 15.2533 4.29333 15.28 5.82666 15.28L5.86666 15.3333ZM5.86666 14C4.59999 14 4.29333 13.9733 4.05999 13.8533C3.80666 13.72 3.59999 13.52 3.47333 13.2667C3.34666 13.0267 3.32666 12.7267 3.32666 11.46V4.52667C3.32666 3.26 3.34666 2.95333 3.46666 2.72C3.59333 2.46667 3.79333 2.26 4.04666 2.13333C4.27999 2.00667 4.57999 1.98667 5.84666 1.98667H11.18C11.9733 1.98667 12.1667 2 12.28 2.05333C12.4 2.11333 12.5067 2.21333 12.5667 2.34C12.62 2.44667 12.6333 2.64 12.6333 3.44V12.5067C12.6333 13.3 12.6133 13.4933 12.56 13.6067C12.4933 13.7267 12.3933 13.8333 12.2667 13.8933C12.1533 13.9467 11.96 13.96 11.16 13.96H5.82666L5.86666 14Z"
      fill="#627D98"
    />
  </Icon>
);
