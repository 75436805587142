import type { FC, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { SubProjectExtendedContract } from '@graneet/business-logic';
import { Input, Modal, Price, RadioGroup, Table, Tooltip } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import { filterOnlyContaining } from 'features/common/services/common.util';

const RowTooltip: FC<{ error?: string; children: ReactNode }> = ({ error, children }) => {
  if (error) {
    return (
      <Tooltip label={error} shouldWrapChildren>
        {children}
      </Tooltip>
    );
  }

  return children;
};

interface ProgressStatementRedirectContractTableProps {
  contracts: SubProjectExtendedContract[];

  isOpen: boolean;

  onClose(): void;

  onSubmit(contractId: number): Promise<void>;
}

export const ProgressStatementRedirectContractModal: FC<ProgressStatementRedirectContractTableProps> = ({
  contracts,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation(['progressStatement', 'global', 'contracts']);

  const [contractId, setContractId] = useState<number | null>(null);
  const [search, setSearch] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (!contractId) {
      return;
    }
    setIsLoading(true);
    await onSubmit(contractId);
    setIsLoading(false);
  }, [contractId, onSubmit]);

  const filteredContracts = useMemo(() => {
    if (search === '') {
      return contracts;
    }

    return filterOnlyContaining(contracts, ['name'], search);
  }, [contracts, search]);

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose} title={t('progressStatement:editContractButton.modal.title')}>
      <Box mb={2}>
        <Input
          w={['100%', '100%', '20rem']}
          placeholder={t('global:words.c.research')}
          onChange={setSearch}
          value={search}
        />
      </Box>

      <Table templateColumns={['3rem', '10rem', 'auto', '10rem']}>
        <Table.Header>
          <Table.Cell />

          <Table.Cell>{t('contracts:fields.contractCode')}</Table.Cell>

          <Table.Cell>{t('contracts:fields.contractName')}</Table.Cell>

          <Table.Cell>{t('contracts:fields.amountExVAT')}</Table.Cell>
        </Table.Header>

        {filteredContracts.map((contract) => {
          const onClick = () => setContractId(contract.id);

          const isDisabled = !contract.areAmountsEditable;

          return (
            <Table.Row key={contract.id} onClick={onClick} isDisabled={isDisabled}>
              <Table.Cell>
                <RowTooltip
                  error={isDisabled ? t('progressStatement:editContractButton.cannotUpdateTooltip') : undefined}
                >
                  <RadioGroup.Option isChecked={contractId === contract.id} value="" />
                </RowTooltip>
              </Table.Cell>

              <Table.Cell>
                <RowTooltip
                  error={isDisabled ? t('progressStatement:editContractButton.cannotUpdateTooltip') : undefined}
                >
                  {contract.code}
                </RowTooltip>
              </Table.Cell>

              <Table.Cell>
                <RowTooltip
                  error={isDisabled ? t('progressStatement:editContractButton.cannotUpdateTooltip') : undefined}
                >
                  {contract.name}
                </RowTooltip>
              </Table.Cell>

              <Table.Cell>
                <RowTooltip
                  error={isDisabled ? t('progressStatement:editContractButton.cannotUpdateTooltip') : undefined}
                >
                  <Price amount={contract.totalAmountExVAT} />
                </RowTooltip>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table>

      <Modal.Close isDisabled={isLoading} />
      <Modal.PrimaryButton isDisabled={!contractId} onClick={handleSubmit} isLoading={isLoading}>
        {t('progressStatement:editContractButton.modal.submitCta')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
