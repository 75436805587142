import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import type { IQuoteLot } from '@graneet/business-logic';

import { useQuoteEditContext } from '../../quote/hooks/useQuoteEditContext';
import { setOptionalLot } from '../services/quote-lot.api';

export const useSetLotOptional = (lot: IQuoteLot) => {
  const toast = useToast();
  const { t } = useTranslation(['global']);
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (isOptional: boolean) => {
      const [err, quoteInfos] = await setOptionalLot(lot.id, isOptional);

      if (err) {
        toast.error(t('global:errors.error'));
        return;
      }

      updateDataLocally(quoteInfos);
    },
    [lot.id, t, toast, updateDataLocally],
  );
};
