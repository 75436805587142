import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import {
  Modal,
  MultiAutocompleteField,
  CurrencyField,
  ModalSubtitle,
  RequiredText,
  TextAreaField,
  useCurrency,
  divideFloating,
  formatDateToString,
  RadioGroup,
  useToast,
  SingleSelectField,
} from '@graneet/lib-ui';
import { Form, useForm, useFormStatus, useOnBlurValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { uniqueId } from 'lodash-es';
import { ABSENCE_TYPES, PERMISSION } from '@graneet/business-logic';

import { TIME_SLOT_FIELDS } from '../../../constants/time-slot.constant';
import { createTimeSlots } from '../../../services/time-slot.api';
import { divideRangeToDays, extractTimeslotOvertimes } from '../../../services/time-tracking.util';
import { PREFIX_SLOT, TimeSlotField } from '../../fields/TimeSlotField';
import { useTimeTrackingContext } from '../../../context/TimeTrackingContext';

import { WorkingTimeForm } from './WorkingTimeForm';
import { getAbsenceTypeFieldName, getHour, SAME_WORKING_HOURS } from './TimeSlotCreateModalForm';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { ProjectField } from 'features/project/components/ProjectField/ProjectField';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { Rule } from 'features/form/rules/Rule';
import { useAppContext } from 'features/app/contexts/AppContext';
import { useIsTimeslotOvertimesValid } from 'features/time-tracking/hooks/useIsTimeslotOvertimesValid';
import { useOvertimeTypes } from 'features/overtime/services/overtime-type.api';

const TT_OFF_VALUE = 'off';
const TT_PRESENT_VALUE = 'present';

export const TimeSlotCreateModal = () => {
  const overtimeTypes = useOvertimeTypes();
  const { t } = useTranslation(['global', 'timeTracking']);
  const {
    state: {
      defaultNbHours,
      createSlot: slot,
      filters: { groupBy },
      projects,
      workers,
      defaultStartHour,
      defaultEndHour,
    },
    modalCreateSlot: { isOpen, onClose },
  } = useTimeTrackingContext();

  const { currentUser } = useAppContext();
  const defaultMealExpense = currentUser?.company?.defaultMealExpense;

  const toast = useToast();
  const form = useForm();
  const { getFormValues, setFormValues } = form;
  const { isValid: isFormValid } = useFormStatus(form);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();
  const [isLoading, setIsLoading] = useState(false);
  const [showProject, setShowProject] = useState(true);
  const [showWorkforceComponentType, setShowWorkforceComponentType] = useState(true);
  const [showHourlyPrice, setShowHourlyPrice] = useState(true);
  const firstId = useMemo(() => uniqueId(PREFIX_SLOT), []);
  const [isOff, setIsOff] = useState(false);
  const canAccessWorkerHourlyPrice = usePermissions([PERMISSION.ACCESS_WORKER_HOURLY_PRICE]);

  const watchedFormValues = useOnBlurValues(form, undefined);

  const isTimeslotOvertimesValid = useIsTimeslotOvertimesValid(watchedFormValues, isOff);

  useEffect(() => {
    setFormValues({
      [TIME_SLOT_FIELDS.MEAL_EXPENSE]: mapNumberToAmount(defaultMealExpense ?? 0),
    });
  }, [setFormValues, mapNumberToAmount, defaultMealExpense]);

  const workersOptions = useMemo(
    () =>
      workers.map(({ id, firstName, lastName }) => ({
        label: `${firstName} ${lastName}`,
        value: id,
        badgeColor: 'white',
      })),
    [workers],
  );

  useEffect(() => {
    if (slot) {
      const values = {
        [firstId]: { startDate: new Date(slot.date), endDate: new Date(slot.date) },
      };
      if (groupBy === 'worker') {
        const workerInit = workers.find((worker) => worker.id === parseInt(slot.id, 10));
        const projectInit = slot.subEntityId
          ? projects.find((project) => project.id === parseInt(slot.subEntityId, 10))
          : undefined;
        if (workerInit) {
          if (canAccessWorkerHourlyPrice) {
            values[TIME_SLOT_FIELDS.HOURLY_PRICE_EX_VAT] = mapNumberToAmount(workerInit.hourlyPriceExVAT);
          }
          values[TIME_SLOT_FIELDS.WORKER_IDS] = [workerInit.id];
          values[TIME_SLOT_FIELDS.WORKFORCE_COMPONENT_TYPE_ID] = workerInit.componentType?.id;
        }
        if (projectInit) {
          values[TIME_SLOT_FIELDS.PROJECT_ID] = projectInit.id;
          values[TIME_SLOT_FIELDS.KM_EXPENSE] = mapNumberToAmount(projectInit.defaultKmExpense ?? 0);
        }
      } else if (groupBy === 'project') {
        const workerInit = slot.subEntityId
          ? workers.find((worker) => worker.id === parseInt(slot.subEntityId, 10))
          : undefined;
        const projectInit = projects.find((project) => project.id === parseInt(slot.id, 10));

        values[TIME_SLOT_FIELDS.KM_EXPENSE] = mapNumberToAmount(projectInit.defaultKmExpense ?? 0);

        if (projectInit) {
          values[TIME_SLOT_FIELDS.PROJECT_ID] = projectInit.id;
        }
        if (workerInit) {
          values[TIME_SLOT_FIELDS.WORKER_IDS] = [workerInit.id];
          values[TIME_SLOT_FIELDS.WORKFORCE_COMPONENT_TYPE_ID] = workerInit.componentType?.id;
          if (canAccessWorkerHourlyPrice) {
            values[TIME_SLOT_FIELDS.HOURLY_PRICE_EX_VAT] = mapNumberToAmount(workerInit.hourlyPriceExVAT);
          }
        }
      }
      if (slot.isOff) {
        setShowProject(false);
        setShowWorkforceComponentType(false);
        values.presence = TT_OFF_VALUE;
      } else {
        setShowProject(true);
        setShowWorkforceComponentType(true);
        if (canAccessWorkerHourlyPrice) {
          setShowHourlyPrice(true);
        }
        values.presence = TT_PRESENT_VALUE;
      }
      setFormValues({
        ...values,
        [TIME_SLOT_FIELDS.MEAL_EXPENSE]: mapNumberToAmount(defaultMealExpense ?? 0),
      });
    } else {
      setFormValues({
        presence: TT_PRESENT_VALUE,
      });
    }
  }, [
    firstId,
    setFormValues,
    slot,
    groupBy,
    workers,
    projects,
    mapNumberToAmount,
    setShowProject,
    setShowWorkforceComponentType,
    canAccessWorkerHourlyPrice,
    defaultMealExpense,
  ]);

  const onPresenceChanged = useCallback(
    (value) => {
      if (value === TT_OFF_VALUE) {
        setShowProject(false);
        setShowWorkforceComponentType(false);
        setIsOff(true);

        setFormValues({
          [TIME_SLOT_FIELDS.HOURLY_PRICE_EX_VAT]: 0,
          [TIME_SLOT_FIELDS.KM_EXPENSE]: 0,
          [TIME_SLOT_FIELDS.PROJECT_ID]: undefined,
        });
      } else {
        const { [TIME_SLOT_FIELDS.WORKER_IDS]: workerIds } = getFormValues();
        if (workerIds && workerIds.length && workerIds.length === 1) {
          const workerInit = workerIds ? workers.find((worker) => worker.id === workerIds[0]) : undefined;
          if (workerInit) {
            setFormValues({
              [TIME_SLOT_FIELDS.HOURLY_PRICE_EX_VAT]: mapNumberToAmount(workerInit.hourlyPriceExVAT),
            });
          }
        }
        setIsOff(false);
        setShowProject(true);
        setShowWorkforceComponentType(true);
      }
    },
    [setFormValues, getFormValues, workers, mapNumberToAmount],
  );

  const onWorkerChange = useCallback(
    (value) => {
      const isSingleSelection = value.length === 1;
      setShowHourlyPrice(isSingleSelection);
      setShowWorkforceComponentType(isSingleSelection);

      if (isSingleSelection) {
        if (canAccessWorkerHourlyPrice) {
          const selectedWorker = workers.find((worker) => worker.id === value[0]);
          setFormValues({
            [TIME_SLOT_FIELDS.HOURLY_PRICE_EX_VAT]: divideFloating(selectedWorker.hourlyPriceExVAT, 100),
            ...(selectedWorker.componentType && { workforceComponentTypeId: selectedWorker.componentType.id }),
          });
        }
      }
    },
    [canAccessWorkerHourlyPrice, setFormValues, workers],
  );

  const onProjectChange = useCallback(
    async (projectId) => {
      const selectedProject = projects.find((project) => project.id === projectId);

      if (!selectedProject) {
        setFormValues({
          [TIME_SLOT_FIELDS.KM_EXPENSE]: 0,
        });

        return;
      }

      setFormValues({
        [TIME_SLOT_FIELDS.KM_EXPENSE]: mapNumberToAmount(selectedProject.defaultKmExpense ?? 0),
      });
    },
    [setFormValues, mapNumberToAmount, projects],
  );

  const handleOnClose = useCallback(() => {
    form.resetForm();
    setFormValues({
      [TIME_SLOT_FIELDS.MEAL_EXPENSE]: mapNumberToAmount(defaultMealExpense ?? 0),
      presence: TT_PRESENT_VALUE,
    });
    setIsOff(false);
    setShowProject(true);
    setShowWorkforceComponentType(true);
    onClose();
  }, [defaultMealExpense, form, mapNumberToAmount, onClose, setFormValues]);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    const {
      [TIME_SLOT_FIELDS.HOURLY_PRICE_EX_VAT]: hourlyPriceExVAT,
      [TIME_SLOT_FIELDS.NOTE]: note,
      [TIME_SLOT_FIELDS.PROJECT_ID]: projectId,
      [TIME_SLOT_FIELDS.WORKER_IDS]: workerIds,
      [TIME_SLOT_FIELDS.WORKFORCE_COMPONENT_TYPE_ID]: workforceComponentTypeId,
      [TIME_SLOT_FIELDS.KM_EXPENSE]: kmExpense,
      [TIME_SLOT_FIELDS.MEAL_EXPENSE]: mealExpense,
      [TIME_SLOT_FIELDS.ABSENCE_TYPE]: absenceType,
      ...formValues
    } = getFormValues();

    let workforceComponentTypes = [];

    if (workerIds.length === 1) {
      const selectedWorker = workers.find((worker) => worker.id === workerIds[0]);
      workforceComponentTypes = [
        { workerId: workerIds[0], componentTypeId: workforceComponentTypeId || selectedWorker.componentType?.id },
      ];
    } else if (workerIds.length > 1) {
      workforceComponentTypes = workers
        .filter((worker) => workerIds.includes(worker.id))
        .map((w) => ({ workerId: w.id, componentTypeId: w.componentType?.id }));
    }

    const dates = [];
    let slotsDays = [];

    Object.entries(formValues).forEach((date) => {
      if (date[0].includes(PREFIX_SLOT)) {
        dates.push(date[1]);
      }
    });

    dates.forEach((date) => {
      slotsDays = slotsDays.concat(divideRangeToDays(date));
    });

    const slots = slotsDays.map((day) => {
      const formattedDay = formatDateToString(day);
      let startHour;
      let endHour;
      let timeslotOvertimes;
      let absenceDuration;
      if (formValues.workingHourType === SAME_WORKING_HOURS && slotsDays.length > 0) {
        const firstDayFormatted = formatDateToString(slotsDays[0]);
        startHour = getHour(formValues[`startHour.${firstDayFormatted}`]);
        endHour = getHour(formValues[`endHour.${firstDayFormatted}`]);
        timeslotOvertimes = extractTimeslotOvertimes(firstDayFormatted, formValues, overtimeTypes.data);
        absenceDuration = formValues[getAbsenceTypeFieldName(firstDayFormatted)];
      } else {
        startHour = getHour(formValues[`startHour.${formattedDay}`]);
        endHour = getHour(formValues[`endHour.${formattedDay}`]);
        timeslotOvertimes = extractTimeslotOvertimes(formattedDay, formValues, overtimeTypes.data);
        absenceDuration = formValues[getAbsenceTypeFieldName(formattedDay)];
      }

      return {
        date: formatDateToString(day),
        startHour,
        endHour,
        timeslotOvertimes,
        absenceDuration,
      };
    });

    const dto = {
      workerIds,
      note,
      projectId,
      hourlyPriceExVAT: mapAmountToNumber(hourlyPriceExVAT ?? 0),
      slots,
      workforceComponentTypes,
      mealExpense: mapAmountToNumber(mealExpense ?? 0),
      kmExpense: mapAmountToNumber(kmExpense ?? 0),
      absenceType,
    };

    const [err] = await createTimeSlots(dto);
    setIsLoading(false);

    if (err) {
      toast.error(
        t('global:words.c.error'),
        t('timeTracking:createTimeSlotModal.toastError', { email: SUPPORT_EMAIL }),
      );
      setIsLoading(false);
      return;
    }

    handleOnClose();
  }, [getFormValues, mapAmountToNumber, handleOnClose, workers, overtimeTypes.data, toast, t]);

  return (
    <Form form={form}>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={handleOnClose}
        title={t('timeTracking:createTimeSlotModal.title')}
        scrollBehavior="inside"
        size="3xl"
      >
        <VStack align="flex-start" spacing={9} my={3}>
          <Box w="100%">
            <RequiredText>
              <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.workers')}</ModalSubtitle>
            </RequiredText>
            <RadioGroup
              direction="row"
              mb={5}
              name="presence"
              value={showProject ? TT_PRESENT_VALUE : TT_OFF_VALUE}
              onChange={onPresenceChanged}
            >
              <RadioGroup.Option
                label={t('timeTracking:createTimeSlotModal.fields.present')}
                key={TT_PRESENT_VALUE}
                value={TT_PRESENT_VALUE}
              />
              <RadioGroup.Option
                label={t('timeTracking:createTimeSlotModal.fields.off')}
                key={TT_OFF_VALUE}
                value={TT_OFF_VALUE}
              />
            </RadioGroup>
            <MultiAutocompleteField
              w="100%"
              options={workersOptions}
              name={TIME_SLOT_FIELDS.WORKER_IDS}
              placeholder={t('timeTracking:createTimeSlotModal.fields.workersPlaceholder')}
              onChange={onWorkerChange}
              variant="outlined"
            >
              <Rule.IsRequired />
              <Rule.IsNotEmptyArray />
            </MultiAutocompleteField>
          </Box>

          {showWorkforceComponentType && (
            <ComponentTypeField
              label={t('global:batiprixEditConfigModal.componentTypeStep.workforceDisbursementType')}
              name="workforceComponentTypeId"
              workforce
              isRequired
            >
              <Rule.IsRequired />
            </ComponentTypeField>
          )}

          {showProject && (
            <Box w="100%">
              <RequiredText>
                <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.project')}</ModalSubtitle>
              </RequiredText>
              <ProjectField
                isRequired={showProject}
                name={TIME_SLOT_FIELDS.PROJECT_ID}
                projects={projects}
                onProjectSelected={onProjectChange}
              />
            </Box>
          )}

          <Box>
            <TimeSlotField isOff={!showProject} firstId={firstId} />
          </Box>

          <WorkingTimeForm
            isOff={isOff}
            showProject={showProject}
            overtimeTypes={overtimeTypes.data}
            defaultStartHour={defaultStartHour}
            defaultEndHour={defaultEndHour}
            defaultNbHours={defaultNbHours}
          />

          {showProject && canAccessWorkerHourlyPrice && (
            <HStack spacing={4} w="full">
              {showHourlyPrice && (
                <Box flex={1}>
                  <RequiredText>
                    <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.hourlyPriceExVAT')}</ModalSubtitle>
                  </RequiredText>
                  <CurrencyField min={0} isRequired name={TIME_SLOT_FIELDS.HOURLY_PRICE_EX_VAT}>
                    <Rule.IsRequired />
                  </CurrencyField>
                </Box>
              )}
              <Box flex={1}>
                <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.mealExpense')}</ModalSubtitle>
                <CurrencyField min={0} name={TIME_SLOT_FIELDS.MEAL_EXPENSE} />
              </Box>

              <Box flex={1}>
                <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.kmExpense')}</ModalSubtitle>
                <CurrencyField min={0} name={TIME_SLOT_FIELDS.KM_EXPENSE} />
              </Box>
            </HStack>
          )}

          {isOff && (
            <Box w="100%">
              <RequiredText>
                <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.absenceType')}</ModalSubtitle>
              </RequiredText>

              <SingleSelectField
                name={TIME_SLOT_FIELDS.ABSENCE_TYPE}
                options={Object.values(ABSENCE_TYPES).map((absenceType) => ({
                  label: t(`timeTracking:createTimeSlotModal.fields.absenceMotif.${absenceType}`),
                  value: absenceType,
                }))}
              >
                <Rule.IsRequired />
              </SingleSelectField>
            </Box>
          )}

          <Box w="100%">
            <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.note')}</ModalSubtitle>
            <TextAreaField
              w="100%"
              name={TIME_SLOT_FIELDS.NOTE}
              inputProps={{ textAlign: 'left', fontWeight: 'normal' }}
            />
          </Box>
        </VStack>

        <Modal.Close />

        <Modal.PrimaryButton
          onClick={onSubmit}
          isLoading={isLoading}
          isDisabled={!isFormValid || !isTimeslotOvertimesValid}
        >
          {t('global:words.c.validate')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
