import type { ITimeTableRange } from '@graneet/lib-ui';
import { MonthlyTimeTable } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback } from 'react';

import { TimeTrackingMonthlyRow } from './TimeTrackingMonthlyRow';

import { useTimeTrackingContext } from 'features/time-tracking/context/TimeTrackingContext';

interface TimeTrackingMonthlyProps {
  aggregatedRanges: Record<string, Record<string, ITimeTableRange[]>>;
}

export const TimeTrackingMonthly: FC<TimeTrackingMonthlyProps> = ({ aggregatedRanges }) => {
  const {
    state: { filters, numberOfDays },
    weekData,
    handleDateChange,
    handleZoomWeek,
    refetch,
    fetch,
  } = useTimeTrackingContext();
  const { date, groupBy } = filters;

  const onColorChanged = useCallback(() => {
    fetch(filters);
    refetch();
  }, [fetch, filters, refetch]);

  return (
    <MonthlyTimeTable
      startDate={date}
      onNextMonth={handleDateChange}
      onPreviousMonth={handleDateChange}
      showWeekEnd={numberOfDays === 7}
      onWeekClick={handleZoomWeek}
    >
      {((groupBy === 'project' ? weekData?.projects : weekData?.workers) ?? []).map((row) => {
        const rangesGroupedBy = Object.values(aggregatedRanges[row.id] ?? {});

        return (
          <TimeTrackingMonthlyRow
            key={row.id}
            ranges={rangesGroupedBy}
            row={row}
            groupBy={groupBy}
            onColorChanged={onColorChanged}
          />
        );
      })}
    </MonthlyTimeTable>
  );
};
