import type { IComponentType } from '../component-type/component-type.type';
import type { ISupplier } from '../supplier/supplier.type';
import type { IVatBases } from '../vat/vat-bases.type';
import type { IFile } from '../file/file.type';
import type { ICompany } from '../company/company.type';
import type { IOrderSupplierInvoice } from '../order-supplier-invoice/order-supplier-invoice.type';
import type { IProgressStatement } from '../progress-statement/progress-statement.type';
import type { IAccountingExport } from '../accounting/accounting-export.type';
import type { RequiredByKeys } from '../common/types';
import type { IValidationStep } from '../validation-step/validation-step.type';
import type { IAccountingConfigPurchasesAccount } from '../accounting/accounting-config-purchases-account.entity';
import type { VAT_TYPE } from '../vat/constant/vat-type.constant';

import type { ISupplierInvoicePayment } from './supplier-invoice-payment.type';
import type { ISupplierInvoiceFile } from './supplier-invoice-file.type';
import type { ISupplierInvoiceProject } from './supplier-invoice-project.type';
import type { ISupplierInvoiceResponseDTO } from './dtos';
import type { ISupplierInvoiceItem } from './supplier-invoice-item.type';

export enum SUPPLIER_INVOICE_STATUS {
  TO_PROCESS = 'TO_PROCESS',
  TO_VALIDATE = 'TO_VALIDATE',
  TO_PAY = 'TO_PAY',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID = 'PAID',
  DIRECT_PAYMENT = 'DIRECT_PAYMENT',
}

export enum SUPPLIER_INVOICE_INFORMATION {
  DUPLICATE_INVOICE = 'DUPLICATE_INVOICE',
  MISSING_INFORMATION = 'MISSING_INFORMATION',
}

export enum SUPPLIER_INVOICE_MODE {
  CONDENSED = 'CONDENSED',
  DETAILED = 'DETAILED',
}

export interface ISupplierInvoice {
  id: number;

  name: string | null;

  historyFamilyId: string;

  invoiceNumber: string | null;

  billingDate: Date | null;

  paymentDate: Date | null;

  status: SUPPLIER_INVOICE_STATUS;

  comment: string | null;

  mode: SUPPLIER_INVOICE_MODE;

  amountExVAT: number | null;

  vatDistribution: IVatBases;

  vatType: VAT_TYPE;

  amountIncVAT: number;

  remainingToBePaidIncVAT: number;

  type?: IComponentType | null;

  invoiceFile?: IFile | null;

  supplier?: ISupplier | null;

  supplierInvoiceProjects?: ISupplierInvoiceProject[];

  supplierInvoiceFiles?: ISupplierInvoiceFile[];

  supplierInvoicePayments?: ISupplierInvoicePayment[];

  ordersSupplierInvoices?: IOrderSupplierInvoice[];

  progressStatement?: IProgressStatement | null;

  company?: ICompany;

  createdAt: Date;

  updatedAt: Date;

  isDisplayInProgressStatementPdf: boolean;

  lateDays?: number | null;

  amountOnProjectsExVAT?: number;

  accountingExport?: IAccountingExport | null;

  tags: string[];

  currentValidationStep?: IValidationStep | null;

  accountingConfigPurchasesAccount?: IAccountingConfigPurchasesAccount | null;

  supplierInvoiceItems?: ISupplierInvoiceItem[];

  information?: SUPPLIER_INVOICE_INFORMATION[];
}

export type ISupplierInvoiceWithRelations = RequiredByKeys<
  ISupplierInvoice,
  | 'type'
  | 'invoiceFile'
  | 'supplier'
  | 'supplierInvoiceProjects'
  | 'supplierInvoiceFiles'
  | 'supplierInvoicePayments'
  | 'ordersSupplierInvoices'
  | 'progressStatement'
  | 'company'
  | 'accountingExport'
  | 'accountingConfigPurchasesAccount'
  | 'currentValidationStep'
  | 'supplierInvoiceItems'
>;

export interface SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT extends ISupplierInvoiceResponseDTO {
  amountPreviousDirectPaymentsExVAT?: number;
  hasDuplicate?: boolean;
}
export type ISupplierInProgressStatementPdf = Pick<ISupplierInvoice, 'id' | 'isDisplayInProgressStatementPdf'>;
