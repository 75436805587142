export interface ListingLayoutTranslations {
  filters: {
    cta: string;
    removeAll: string;
    modal: {
      title: string;
      cta: string;
    };
  };
}

export const listingLayoutTranslations: ListingLayoutTranslations = {
  filters: {
    cta: 'Filtres',
    removeAll: 'Remove all',
    modal: {
      title: 'Filtres',
      cta: 'Filtrer',
    },
  },
};

export const configureDefaultLabels = (translation: ListingLayoutTranslations) => {
  Object.assign(listingLayoutTranslations, translation);
};
