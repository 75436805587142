export const MARGIN_COMPONENT_INPUT_STYLE = {
  DISABLED: {
    textAlign: 'center',
    width: '50%',
    margin: 'auto',
    borderColor: 'transparent',
  },
  FOCUS: {
    color: 'gray.700',
    textAlign: 'center',
    width: '50%',
    margin: 'auto',
    borderColor: 'gray.500',
  },
  EDITION: {
    textAlign: 'center',
    width: '50%',
    margin: 'auto',
    borderColor: 'greenBrand.light',
  },
  BLURRED: {
    color: 'gray.700',
    textAlign: 'center',
    width: '50%',
    margin: 'auto',
    _groupHover: {
      borderColor: 'gray.500',
    },
  },
};
