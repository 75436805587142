import { useTranslation } from 'react-i18next';
import { Button, Modal, TextField } from '@graneet/lib-ui';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { useCallback } from 'react';
import { Form, useForm, useFormStatus } from 'graneet-form';

import { useCreateAccountingConfigPurchasesAccount } from '../../../services/accounting-config.api';

import { Rule } from 'features/form/rules/Rule';

interface FormValues {
  name: string;

  account: string;
}

const FORM_ID = 'AccountingAddPurchasesAccountButton';

export const AccountingAddPurchasesAccountButton = () => {
  const { t } = useTranslation(['accounting', 'global']);

  const form = useForm<FormValues>();
  const { isValid } = useFormStatus(form);

  const modal = useDisclosure({
    onClose() {
      form.resetForm();
    },
  });

  const createAccountingConfigPurchasesAccountMutation = useCreateAccountingConfigPurchasesAccount();

  const onCreate = useCallback(
    async (formValues: FormValues) => {
      await createAccountingConfigPurchasesAccountMutation.mutateAsync({
        name: formValues.name,
        account: formValues.account,
      });
      modal.onClose();
    },
    [createAccountingConfigPurchasesAccountMutation, modal],
  );

  return (
    <>
      <Button variant="outline" onClick={modal.onOpen}>
        {t('accounting:cta.addAccount')}
      </Button>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t('accounting:createPurchasesAccountModal.title')}>
        <Form form={form} id={FORM_ID} onSubmit={form.handleSubmit(onCreate)}>
          <Flex direction="column" gap={6}>
            <TextField<FormValues> name="name" label={t('accounting:fields.accountPurchasesName')} isRequired>
              <Rule.IsRequired />
            </TextField>

            <TextField<FormValues> name="account" label={t('accounting:fields.accountPurchasesAccount')} isRequired>
              <Rule.IsRequired />
            </TextField>
          </Flex>

          <Modal.CloseButton isDisabled={createAccountingConfigPurchasesAccountMutation.isPending} />
          <Modal.PrimaryButton
            isDisabled={!isValid}
            isLoading={createAccountingConfigPurchasesAccountMutation.isPending}
            type="submit"
            form={FORM_ID}
          >
            {t('global:words.c.create')}
          </Modal.PrimaryButton>
        </Form>
      </Modal>
    </>
  );
};
