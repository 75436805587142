import type { FC } from 'react';
import { useState, useMemo, useCallback } from 'react';
import type { MultiValue, Props } from 'chakra-react-select';
import { Select } from 'chakra-react-select';
import type { FormControlProps } from '@chakra-ui/react';
import { FormControl, FormErrorMessage, Box } from '@chakra-ui/react';
import { flatMap } from 'lodash-es';

import { basicSearchCompare } from '../../utils';

import type {
  EmailAutocompleteContactOption,
  EmailAutocompleteContact,
  GroupedAutocompleteContacts,
} from './EmailAutocomplete.type';
import { MultiValueLabel } from './components/MultiValueLabel';
import { OptionComponent } from './components/OptionComponent';
import { GroupHeading } from './components/GroupHeading';
import { configureDefaultLabels, emailAutocompleteTranslations } from './configureDefaultLabels';

const EmptyComponent = () => <></>;

export const basicContactSearch = (
  contact: { firstName?: string | null; lastName?: string | null; email: string },
  needle: string,
) =>
  basicSearchCompare((contact.firstName ?? '') + (contact.lastName ?? ''), needle) ||
  basicSearchCompare(contact.email, needle);

export interface EmailAutocompleteProps extends Omit<FormControlProps, 'onChange'> {
  groupedContacts: GroupedAutocompleteContacts[];
  onChange?: (value: EmailAutocompleteContact[]) => void | Promise<void>;
  value: EmailAutocompleteContact[];
  onFocus?: () => void;
  onBlur?: () => void;
  shouldDisplayError?: boolean;
  canAddEntry?: boolean;
  errorMessage?: string;
  selectProps?: Props<EmailAutocompleteContactOption, true>;
}

const EmailAutocompleteComponent: FC<EmailAutocompleteProps> = ({
  groupedContacts,
  value,
  shouldDisplayError,
  errorMessage,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  selectProps = {},
  canAddEntry = true,
  ...rest
}) => {
  const [textInputValue, setTextInputValue] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(
    (values: MultiValue<EmailAutocompleteContactOption>) => {
      onChange(values.map((selectedValue) => selectedValue.contact));
    },
    [onChange],
  );

  // Whether the user has started typing something in the autocomplete input
  const isSearching = useMemo(() => !!textInputValue, [textInputValue]);

  // If the user is typing something in the autocomplete and it does not match any result,
  // switch to "manually type a custom address" mode (displayed as a custom option)
  const shouldDisplayCustomOption = useMemo(
    () =>
      isSearching &&
      canAddEntry &&
      !flatMap(groupedContacts, 'options').some((contact) => basicContactSearch(contact, textInputValue)),
    [canAddEntry, groupedContacts, isSearching, textInputValue],
  );

  const groupedOptionsByCompany = useMemo(() => {
    /* we don't want a heading in the select here */
    if (shouldDisplayCustomOption) {
      return [
        {
          label: undefined,
          options: [
            {
              label: textInputValue,
              value: textInputValue,
              contact: {
                id: 'NEW_ID',
                email: textInputValue,
              },
              __isCustom: true,
            },
          ],
        },
      ];
    }

    return groupedContacts.map(({ label, options }) => ({
      label,
      options: options
        .filter(({ hideWhenNotSearching }) => !hideWhenNotSearching || isSearching)
        .map((contact) => ({
          id: contact.id,
          label: [contact.firstName, contact.lastName].filter(Boolean).join(' '),
          value: contact.email,
          isDisabled: !contact.email,
          contact,
          __isCustom: false,
        })),
    }));
  }, [shouldDisplayCustomOption, groupedContacts, textInputValue, isSearching]);

  const filterOption = useCallback(
    ({ data }: { data: EmailAutocompleteContactOption }) =>
      !textInputValue || basicContactSearch(data.contact, textInputValue),
    [textInputValue],
  );

  const selectedValues = useMemo(
    () =>
      value.map<EmailAutocompleteContactOption>((contact) => ({
        label: [contact.firstName, contact.lastName].filter(Boolean).join(' ') || contact.email,
        value: contact.email,
        contact,
        __isCustom: false,
      })),
    [value],
  );

  return (
    <FormControl isInvalid={shouldDisplayError} {...rest}>
      <Box
        borderWidth="1px"
        borderColor="gray.200"
        background="white"
        borderRadius="8px"
        height="32px"
        outline={isOpen ? 'none' : undefined}
        boxShadow={isOpen ? '0px 2px 5px -2px rgba(0, 0, 0, 0.06),0px 0px 0px 1px rgba(0, 0, 0, 0.05)' : undefined}
        fontSize="sm"
        px={2}
      >
        <Select<EmailAutocompleteContactOption, true>
          {...selectProps}
          isMulti
          options={groupedOptionsByCompany}
          variant="unstyled"
          components={{
            GroupHeading,
            ClearIndicator: EmptyComponent,
            MultiValueLabel,
            Option: OptionComponent,
          }}
          filterOption={filterOption}
          inputValue={textInputValue}
          onInputChange={setTextInputValue}
          value={selectedValues}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onMenuClose={() => setIsOpen(false)}
          onMenuOpen={() => setIsOpen(true)}
          noOptionsMessage={() => emailAutocompleteTranslations.noMoreSuggestions}
          placeholder={emailAutocompleteTranslations.placeholder}
        />
      </Box>
      {shouldDisplayError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

export const EmailAutocomplete = Object.assign(EmailAutocompleteComponent, { configureDefaultLabels });
