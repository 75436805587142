import { useCallback } from 'react';
import type { Quote } from '@org/quotation-lib';
import { CheckType, MARGIN_TYPE } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { mapQuoteOptionalItemToUpdateUnitFlastCostAmount } from 'features/quotation/quote-common/mappers/quoteOptionalItemMapper';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteOptionalItemUpdateUnitFlatCostAmount() {
  const { quoteOptionalItemsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, newUnitFlatCostAmount: string): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(nodeId);
          const quoteNode = quoteOptionalItem.getNodeOrThrow();
          this.oldValue = quoteOptionalItem.getUnitFlatCostAmount();

          quoteOptionalItem.updateUnitFlatCostAmount(CheckType.bigOrNull(newUnitFlatCostAmount), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteOptionalItemsProxyApi
            .updateQuoteOptionalItem(
              quoteOptionalItem.getId(),
              mapQuoteOptionalItemToUpdateUnitFlastCostAmount(quoteNode, quote.getId(), clientRequestId, timestamp),
            )
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(nodeId);
          const quoteNode = quoteOptionalItem.getNodeOrThrow();

          quoteOptionalItem.updateUnitFlatCostAmount(this.oldValue, {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteOptionalItemsProxyApi
            .updateQuoteOptionalItem(
              quoteOptionalItem.getId(),
              mapQuoteOptionalItemToUpdateUnitFlastCostAmount(quoteNode, quote.getId(), clientRequestId, timestamp),
            )
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteOptionalItemsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
