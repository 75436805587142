import type { PropsWithChildren, ReactNode } from 'react';
import { useCallback } from 'react';
import type { TooltipProps, XAxisProps, YAxisProps } from 'recharts';
import { BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { CustomTooltip } from '../CustomTooltip';
import { useFormatGraphAxis } from '../../hooks/graphs.util';
import { useChakraColors } from '../../../../../hooks/useChakraColors/useChakraColors';
import { Card } from '../../../../Card/Card';

export interface CustomBarChartProps<T extends object> {
  data: T[];

  xAxis: Omit<XAxisProps, 'dataKey' | 'tickFormatter' | 'fontSize' | 'stroke'> & {
    dataKey: Extract<keyof T, string>;
    typeFormat: 'money' | 'date' | 'string';
  };

  yAxis: Omit<YAxisProps, 'dataKey' | 'tickFormatter' | 'fontSize' | 'stroke'> & {
    typeFormat: 'money' | 'date' | 'string';
  };

  tooltip?: (props: TooltipProps<any, any> & { value: T }) => ReactNode;

  barStyle: {
    size: number;
    gap: number;
    categoryGap: number;
  };

  legends: Partial<Record<keyof T, string>>;
}

export const CustomBarChart = <T extends object>({
  data,
  xAxis,
  yAxis,
  tooltip,
  barStyle: { size, gap, categoryGap },
  legends,
  children,
}: PropsWithChildren<CustomBarChartProps<T>>) => {
  const { formatGraphAxis } = useFormatGraphAxis();

  const { gray } = useChakraColors({ gray: 'greenBrand.baseTertiary' });

  const tooltipComp = useCallback(
    (props: TooltipProps<any, any>) => {
      const value = props.payload?.[0]?.payload;

      if (!value) {
        return null;
      }

      return (
        <Card fontSize="xs">
          {tooltip ? tooltip({ ...props, value }) : <CustomTooltip legends={legends} active payload={props.payload} />}
        </Card>
      );
    },
    [legends, tooltip],
  );

  return (
    <ResponsiveContainer>
      <BarChart data={data} barSize={size} barGap={gap} barCategoryGap={categoryGap}>
        <CartesianGrid strokeDasharray="3" vertical={false} />
        <XAxis
          {...xAxis}
          tickFormatter={(v) => formatGraphAxis(v, xAxis.typeFormat)}
          stroke="0"
          tick={{ fill: gray, fontSize: 12, fontStyle: 600 }}
        />
        <YAxis
          {...yAxis}
          scale="linear"
          tickFormatter={(v) => formatGraphAxis(v, yAxis.typeFormat)}
          stroke="0"
          domain={[0, (dataMax: number) => dataMax * 1.2]}
          tick={{ fill: gray, fontSize: 12, fontStyle: 600 }}
        />
        <Tooltip cursor={false} wrapperStyle={{ outline: 'unset' }} content={tooltipComp} />
        {children}
      </BarChart>
    </ResponsiveContainer>
  );
};
