import type { CSSProperties, FC } from 'react';
import type { InitialConfigType } from '@lexical/react/LexicalComposer';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import type { SerializedEditorState } from 'lexical';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { ListItemNode, ListNode } from '@lexical/list';
import { isNil } from 'lodash-es';
import { Text } from '@chakra-ui/react';
import { HeadingNode } from '@lexical/rich-text';
import { AutoLinkNode, LinkNode } from '@lexical/link';

import { createInjectedVariableNodes, getPotentialStringifyObject, getRichTextPluginOptions } from '../../utils';
import { RICH_TEXT_CSS_VAR_DARK, RICH_TEXT_CSS_VAR_LIGHT } from '../../constants';
import { VariableNode } from '../RichTextInput/plugins/VariablePlugin';
import type { RichTextConfiguration, RichTextVariableConfiguration } from '../RichTextInput';
import '../../utils/richtext.css';

/*
 /!\/!\ This component is similar to a RichText in client-pdf. Please change both at the same time /!\/!\
 */

export interface RichTextProps {
  /**
   * Value displayed. It can be a simple string or a Lexical JSON object as string
   */
  value: string | null | undefined;

  /**
   * Variant will influe on colors used.
   * @default "dark"
   */
  variant?: 'light' | 'dark';

  label?: string;

  configuration?: RichTextConfiguration;
}

const getConditionalNodes = (richTextVariableConfiguration?: RichTextVariableConfiguration) => {
  const nodes = [];
  if (richTextVariableConfiguration?.injectValueInLabel) {
    nodes.push(...createInjectedVariableNodes(richTextVariableConfiguration));
  }
  return nodes;
};

export const RichText: FC<RichTextProps> = ({ value, variant = 'dark', label, configuration = [] }) => {
  const { theme, variablePluginOptions } = getRichTextPluginOptions(configuration);

  const initialConfig: InitialConfigType = {
    namespace: 'RichText',
    onError(error): void {
      console.warn(error);
      throw new Error(JSON.stringify(error));
    },
    editorState(editor): void {
      if (isNil(value) || value === '') {
        return;
      }

      const [isObject, objectState] = getPotentialStringifyObject<SerializedEditorState>(value);
      if (isObject) {
        if (objectState.root.children.length === 0) {
          return;
        }
        const state = editor.parseEditorState(objectState);
        editor.setEditorState(state);
      } else {
        if (objectState === null || objectState === '') {
          return;
        }
        const root = $getRoot();
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode(objectState);
        paragraphNode.append(textNode);
        root.append(paragraphNode);
      }
    },
    nodes: [
      ...getConditionalNodes(variablePluginOptions),
      ListNode,
      ListItemNode,
      VariableNode,
      LinkNode,
      AutoLinkNode,
      HeadingNode,
    ],
    editable: false,
    theme,
  };

  const style: CSSProperties = variant === 'dark' ? RICH_TEXT_CSS_VAR_DARK : RICH_TEXT_CSS_VAR_LIGHT;

  return (
    <>
      {label && (
        <Text fontSize="xs" color="gray.600" mb={1}>
          {label}
        </Text>
      )}

      <LexicalComposer key={value} initialConfig={initialConfig}>
        <RichTextPlugin
          contentEditable={<ContentEditable style={style} />}
          placeholder={<div />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ListPlugin />
      </LexicalComposer>
    </>
  );
};
