import type { FC } from 'react';
import { useState, useMemo, useRef, useCallback, useLayoutEffect } from 'react';
import { Button, type PaginatedRenderActionsProps, useChakraColors, useHotkeys } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { BILL_STATUSES, FEATURE_FLAGS, type IBill, type IBillListingResponseDTO } from '@graneet/business-logic';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Grid, Skeleton, useDisclosure } from '@chakra-ui/react';
import dayjs from 'dayjs';
import type { GridApi, GridReadyEvent } from '@ag-grid-community/core';

import { BillListing } from 'features/bill/components/BillListing';
import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { BillReminderUpsellCard } from 'features/bill/components/BillReminderUpsellCard';
import { BillStatsCard } from 'features/bill/components/BillStatsCard';
import { BillDrawerDetail } from 'features/bill/components/BillDrawerDetail';
import { BillBatchActions } from 'features/bill/components/BillBatchActions';
import { useBills, useBillsStats } from 'features/bill/services/bill.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';
import { useReminders } from 'features/reminder/services/reminder.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { BillPayModal } from 'features/bill/components/modals/BillPayModal';

const GRID_ID = 'bill';

export const ViewBillsScreen: FC = () => {
  const { t } = useTranslation(['bill', 'global']);

  const { hoverColor } = useChakraColors({ hoverColor: 'gray.50' });

  const history = useHistory();
  const location = useLocation();

  const bills = useBills();
  const billsStats = useBillsStats();
  const reminders = useReminders();

  const hasReminderFF = useFeatureFlag(FEATURE_FLAGS.BILL_EMAIL_REMIND);

  const { updateHeaderTitle } = useHeaderContext();

  useLayoutEffect(() => {
    updateHeaderTitle(t('bill:myBills'), []);
  }, [t, updateHeaderTitle]);

  const onRowClicked = useCallback(
    (id: number) => {
      const search = new URLSearchParams(location.search);
      search.set('focusedId', id.toString());

      history.replace({
        pathname: location.pathname,
        search: search.toString(),
      });
    },
    [history, location.pathname, location.search],
  );

  const removeFocusedId = useCallback(() => {
    const search = new URLSearchParams(location.search);
    search.delete('focusedId');

    history.replace({
      pathname: location.pathname,
      search: search.toString(),
    });
  }, [history, location.pathname, location.search]);

  const rawFocusedId = new URLSearchParams(location.search).get('focusedId');
  const focusedId = rawFocusedId ? parseInt(rawFocusedId, 10) : undefined;

  const tableRef = useRef<null | GridApi>(null);
  const onGridReady = useCallback((event: GridReadyEvent) => {
    tableRef.current = event.api;
  }, []);

  const { hasNextRow, hasPreviousRow, focusRow } = useMemo(() => {
    const renderedNodes = tableRef.current?.getRenderedNodes() ?? [];
    const currentIndex = renderedNodes.findIndex((node) => node.data?.id === focusedId);

    const canFocusNextRow = renderedNodes.length + 1 > currentIndex;
    const canFocusPreviousRow = currentIndex > 0;

    return {
      hasNextRow: canFocusNextRow,
      hasPreviousRow: canFocusPreviousRow,
      focusRow: (offset: -1 | 1) => {
        if (offset === -1 && hasPreviousRow) {
          onRowClicked(renderedNodes[currentIndex - 1].data.id);
        }
        if (offset === 1 && hasNextRow) {
          onRowClicked(renderedNodes[currentIndex + 1].data.id);
        }
      },
    };
  }, [focusedId, onRowClicked]);

  useHotkeys(
    ['up', 'down'],
    (event) => {
      if (!focusedId) {
        return;
      }
      focusRow(event.key === 'ArrowUp' ? -1 : 1);
    },
    [focusedId, focusRow],
  );

  const [billEditedInModal, setBillEditedInModal] = useState<undefined | IBill>(undefined);
  const billModal = useDisclosure({
    onClose() {
      setBillEditedInModal(undefined);
    },
  });
  const openBillModal = billModal.onOpen;

  const renderActions = useCallback(
    (props: PaginatedRenderActionsProps<IBillListingResponseDTO>) => (
      <QueryWrapper loadingFallback={<Skeleton w="40rem" h="100%" />}>
        <BillBatchActions onUpdate={bills.refetch} {...props} />
      </QueryWrapper>
    ),
    [bills.refetch],
  );

  return (
    <Box p={8} h="100%">
      <BillDrawerDetail
        isOpen={!!focusedId}
        onClose={removeFocusedId}
        id={focusedId}
        onUpdate={bills.refetch}
        onChangeFocusClicked={focusRow}
      />

      <BillListing
        gridId={GRID_ID}
        pagination={bills}
        topContent={
          <Grid gap={4} templateColumns="repeat(4, minmax(0, 15rem))" gridAutoFlow="column" width="fit-content">
            <BillStatsCard
              information={billsStats.data.thirtyDaysIncomingRemainingToBePaid}
              title={{
                text: t('bill:stats.thirtyDaysIncomingRemainingToBePaid.title'),
              }}
              onClick={() => {
                bills.pagination.updateFilters({
                  status: [BILL_STATUSES.AWAITING_PAYMENT, BILL_STATUSES.PARTIALLY_PAID],
                  paymentDeadline: {
                    startDate: dayjs().toDate(),
                    endDate: dayjs().add(30, 'day').toDate(),
                  },
                });
              }}
            />

            <BillStatsCard
              information={billsStats.data.remainingToBePaid}
              title={{
                text: t('bill:stats.remainingToBePaid.title'),
              }}
              onClick={() => {
                bills.pagination.updateFilters({
                  status: [BILL_STATUSES.AWAITING_PAYMENT, BILL_STATUSES.PARTIALLY_PAID],
                });
              }}
            />

            <BillStatsCard
              information={billsStats.data.lateRemainingToBePaid}
              title={{
                text: t('bill:stats.lateRemainingToBePaid.title'),
                color: 'orange.500',
              }}
              onClick={() => {
                bills.pagination.updateFilters({
                  status: [BILL_STATUSES.AWAITING_PAYMENT, BILL_STATUSES.PARTIALLY_PAID],
                  lateDays: [
                    {
                      min: 0,
                    },
                  ],
                });
              }}
            />

            {hasReminderFF ? (
              <BillStatsCard
                information={billsStats.data.toRemindRemainingToBePaid}
                title={{
                  text: t('bill:stats.toRemindRemainingToBePaid.title'),
                  color: 'red.500',
                }}
                onClick={() => {
                  tableRef.current?.resetColumnState();
                  tableRef.current?.applyColumnState({
                    state: [{ colId: 'billToRemind', sort: 'asc' }],
                  });
                  bills.pagination.updateFilters({
                    billToRemind: reminders.data.map((reminder) => reminder.id),
                  });
                }}
              />
            ) : (
              <BillReminderUpsellCard />
            )}
          </Grid>
        }
        // eslint-disable-next-line react/no-unstable-nested-components
        rowAction={(props) =>
          props.data ? (
            <>
              <Button
                onClick={() => {
                  openBillModal();
                  setBillEditedInModal(props.data);
                }}
                variant="outline"
              >
                <i className="ri-hand-coin-line ri-lg" />
              </Button>
            </>
          ) : null
        }
        tableProps={{
          onGridReady,
          getRowStyle: (props) =>
            props.data?.id === focusedId
              ? {
                  background: hoverColor,
                }
              : undefined,
          onRowClicked: (event) => {
            if (event.data?.id && event.api.getFocusedCell()?.column.getId() !== 'pay') {
              onRowClicked(event.data?.id);
            }
          },
          rowSelection: 'multiple',
          selectionComponentProps: {
            allSelectedLabel: (totalNumberOfItems) => t('bill:banner.allSelectedLabel', { count: totalNumberOfItems }),
            selectLabel: (totalNumberOfItems) => t('bill:banner.selection', { count: totalNumberOfItems }),
            renderActions,
          },
          countLabel: (count) => t('bill:bill', { count }),
          sums: {
            remainingToBePaidIncVAT: {
              type: 'currency',
              label: t('bill:fields.totalToBePaidIncVAT'),
            },
          },
        }}
        customLayout={{
          invoiceNumber: { hide: false },
          name: { hide: false },
          project: { hide: false },
          client: { hide: false },
          billingDate: { hide: true },
          paymentDeadline: { hide: false },
          lateDays: { hide: true },
          billToRemind: { hide: false },
          remainingToBePaidIncVAT: { hide: false },
          status: { hide: false },
        }}
      />

      {billEditedInModal && <BillPayModal bill={billEditedInModal} modal={billModal} onSuccess={bills.refetch} />}
    </Box>
  );
};
