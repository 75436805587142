import type { IRowDragItem } from '@ag-grid-community/core';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store/store';

export const useRowDragText = () => {
  const editorRef = useStore((state) => state.quoteRichTextEditorRef);
  const { t } = useTranslation(['quote']);

  const getPlainText = useCallback(
    (v: string | null | undefined) =>
      editorRef?.current?.getPlainText ? (editorRef?.current?.getPlainText(v) ?? null) : null,
    [editorRef],
  );

  return useCallback(
    (params: IRowDragItem) => {
      const denomination = getPlainText(params.rowNode?.data.content.denomination);
      if (denomination) {
        return denomination;
      }
      switch (params.rowNode?.data.content.type) {
        case 'QuoteLot':
          return t('quote:words.lot');
        case 'QuoteComponent':
          return t('quote:words.component');
        case 'QuoteBasicItem':
        case 'QuoteHiddenCostItem':
        case 'QuoteOptionalItem':
          return t('quote:words.item');
        case 'QuoteSubItem':
          return t('quote:words.subItem');
        default:
          return '';
      }
    },
    [getPlainText, t],
  );
};
