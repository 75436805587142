import { useEffect, useState } from 'react';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useMaxLotDepth = () => {
  const [maxLotDepth, setMaxLotDepth] = useState(0);
  const { listenToMaxLotDepth } = useQuoteEditContext();

  useEffect(() => listenToMaxLotDepth(setMaxLotDepth), [listenToMaxLotDepth]);

  return maxLotDepth;
};
