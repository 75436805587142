import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FormControl, HStack, VStack } from '@chakra-ui/react';

import { SellsheetCard } from '../Card';
import { Label } from '../../../Label/Label';
import { QuotationInput } from '../../../Input/Input';
import { quotationCardTranslations } from '../translations';

type InformationCardProps = {
  name: string | null;
  refCode: string | null;
  isEditable: boolean;
  title: string;
  onChange: (key: string, value: string) => void;
};

export const InformationCard: FC<InformationCardProps> = ({ name, refCode, isEditable, title, onChange }) => {
  const handleOnChange = useCallback(
    (key: 'name' | 'refCode') => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(key, e.target.value);
    },
    [onChange],
  );

  const [inputNameValue, setNameInputValue] = useState<string | null>(name);

  const handleOnNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNameInputValue(e.target.value);
  }, []);

  useEffect(() => {
    setNameInputValue(name);
  }, [name]);

  return (
    <SellsheetCard title={title}>
      <VStack width="100%" spacing={2} alignItems="flex-start">
        <HStack width="100%" spacing={2} alignItems="center">
          <Label width="45%" mb={0}>
            {quotationCardTranslations.information.name}
          </Label>
          <FormControl width="55%">
            <QuotationInput
              value={inputNameValue ?? ''}
              onBlur={handleOnChange('name')}
              onChange={handleOnNameChange}
              isDisabled={!isEditable}
            />
          </FormControl>
        </HStack>
        <HStack width="100%" spacing={2} alignItems="center">
          <Label width="45%" mb={0}>
            {quotationCardTranslations.information.refCode}
          </Label>
          <FormControl width="55%">
            <QuotationInput defaultValue={refCode ?? ''} onBlur={handleOnChange('refCode')} isDisabled={!isEditable} />
          </FormControl>
        </HStack>
      </VStack>
    </SellsheetCard>
  );
};
