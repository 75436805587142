import type { FC, ReactElement, ReactNode } from 'react';
import type { FormControlProps } from '@chakra-ui/react';
import { FormControl, FormLabel, FormErrorMessage, FormHelperText, Stack } from '@chakra-ui/react';

import { SimpleAlertIcon } from '../Icons';

export interface FormGroupProps extends Omit<FormControlProps, 'label'> {
  children?: ReactNode;
  errorMessage?: ReactNode;
  helper?: ReactNode;
  id?: string;
  isRequired?: boolean;
  label?: ReactNode;
  showError?: boolean;
  isFocused?: boolean;
  leftContent?: ReactElement;
  isReadOnly?: boolean;
  placeholder?: ReactNode;
}

export const FormGroup: FC<FormGroupProps> = ({
  children = null,
  errorMessage = '',
  helper,
  id = '',
  isRequired = false,
  label,
  showError = false,
  isFocused = false,
  leftContent,
  isReadOnly = false,
  ...props
}) => (
  <FormControl isInvalid={showError} isRequired={!isReadOnly && isRequired} isReadOnly={isReadOnly} {...props}>
    <Stack spacing={0} direction="row">
      {!!label && (
        <FormLabel
          htmlFor={id}
          color={!isReadOnly && isFocused ? 'greenBrand.light' : 'gray.600'}
          fontSize="0.75rem"
          mb={1}
          transitionDuration="0.2s"
          fontFamily="heading" // like labels on normal fields
        >
          {label}
        </FormLabel>
      )}

      {leftContent}
    </Stack>

    {!!helper && (
      <FormHelperText mt="0" mb="3">
        {helper}
      </FormHelperText>
    )}

    {children}

    <FormErrorMessage id={`${id}-error`}>
      <SimpleAlertIcon mr={1} stroke="red.500" />
      {errorMessage}
    </FormErrorMessage>
  </FormControl>
);
