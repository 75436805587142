import type { ExportEntity, ExportResponseDTO, IExportDTO } from '@graneet/business-logic';
import { useMutation } from '@tanstack/react-query';

import { apiNew } from 'features/api/services/apiNew.service';

const EXPORT_PATH = '/exports';

export function useExportGenerate<Entity extends ExportEntity>() {
  return useMutation({
    mutationFn: (data: IExportDTO<Entity>) =>
      apiNew.post<IExportDTO<Entity>, ExportResponseDTO>(`${EXPORT_PATH}`, data),
  });
}
