import type { ISubProjectWithInformation } from '@graneet/business-logic';
import type { TFunction } from 'i18next';

export const getDownPaymentCreationState = (
  subProjectWithInformation: ISubProjectWithInformation,
  t: TFunction<['global', 'downPayment']>,
  hasMultipleSubProjects: boolean,
): { canCreate: boolean; tooltip: string | undefined } => {
  /**
   * You cannot have multiple down payment
   */
  if (subProjectWithInformation.downPayment) {
    return {
      canCreate: false,
      tooltip: t('downPayment:tooltips.alreadyHaveDownPayment'),
    };
  }

  /**
   * You cannot create a down payment when there is a validated progress statement
   */
  if (subProjectWithInformation.hasValidatedAtLeastOneProgressStatement) {
    return {
      canCreate: false,
      tooltip: hasMultipleSubProjects
        ? t('downPayment:tooltips.hasValidatedProgressStatementWithSubProject')
        : t('downPayment:tooltips.hasValidatedProgressStatement'),
    };
  }

  return {
    canCreate: true,
    tooltip: undefined,
  };
};
