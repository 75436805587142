import type { FC, MutableRefObject } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { ModalProps } from '@graneet/lib-ui';
import { CheckOutlineIcon, CircleIconLegacy, List, Modal, SwitchField, useCurrency, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
import type { IContractUpdateDTO, IHoldback } from '@graneet/business-logic';
import { computeHoldbackAmountIncVAT, FEATURE_FLAGS } from '@graneet/business-logic';
import { Form, useForm } from 'graneet-form';
import { useHistory, useLocation } from 'react-router-dom';

import { getContractPdfName } from '../../services/contract.util';

import { useStore } from 'store/store';
import { getDownloadUrl } from 'features/pdf/services/pdf.api';
import { downloadFile } from 'features/file/services/file.util';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import { useUpdateContract } from 'features/contract/services/contract.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useContractUpdate as useContractUpdateQuotation } from 'features/contract/services/external/contract-external.api';
import { FeatureFlag } from 'features/feature-flag/components/FeatureFlag';

interface FormValues {
  shouldDownloadPDF?: boolean;
}

interface ContractApplyChangesModalProps extends Omit<ModalProps, 'children'> {
  quoteUUID?: string | null;

  projectId: number;

  contractId: number;

  contractUpdatedRef: MutableRefObject<IContractUpdateDTO | undefined>;

  hasFinalStatement: boolean;

  holdback: IHoldback | undefined | null;

  subProjectTotalAmount: number | undefined;
}

export const ContractApplyChangesModal: FC<ContractApplyChangesModalProps> = ({
  onClose,
  isOpen,
  quoteUUID,
  projectId,
  contractId,
  hasFinalStatement,
  contractUpdatedRef,
  holdback,
  subProjectTotalAmount,
  ...props
}) => {
  const { t } = useTranslation(['global', 'contracts']);
  const toast = useToast();
  const { formatAsAmount } = useCurrency();

  const history = useHistory();
  const location = useLocation<{ redirectUrl?: string }>();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<FormValues>();

  const autoNumberingIsActivated = useStore((state) => state.autoNumberingIsActivated);

  const hasQuoteV2FeatureFlag = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);
  const contractUpdateQuotationMutation = useContractUpdateQuotation();

  const updateContractMutation = useUpdateContract();

  const newHoldbackAmountIncVat = useMemo(() => {
    if (holdback && holdback.hasPaymentGuarantee && subProjectTotalAmount) {
      return computeHoldbackAmountIncVAT(
        {
          amountIncVAT: subProjectTotalAmount,
        },
        holdback.holdbackPercentage,
      );
    }

    return null;
  }, [holdback, subProjectTotalAmount]);

  const handleSave = useCallback(async () => {
    const contractUpdated = contractUpdatedRef.current;

    if (!contractUpdated) {
      throw new Error('Implementation error, form values are not given to the modal.');
    }

    let pdfId = null;
    setIsLoading(true);

    if (hasQuoteV2FeatureFlag && quoteUUID) {
      const contractPdfDTO = await contractUpdateQuotationMutation.mutateAsync({
        id: contractId,
        quoteUUID,
        contractUpdateDTO: contractUpdated,
      });
      pdfId = contractPdfDTO.pdfId;
    } else {
      const contractUpdateDTO = await updateContractMutation.mutateAsync({
        id: contractId,
        dto: {
          ...contractUpdated,
          isAutomaticNumberingActivated: autoNumberingIsActivated,
        },
      });
      pdfId = contractUpdateDTO?.pdfId;
      setIsLoading(false);
    }

    if (!pdfId) {
      throw new Error('Implementation error, pdfId is not given to the modal.');
    }

    const redirectToListing = () => {
      onClose();
      toast.success(t('contracts:saveContractUpdateModal.success', { name: contractUpdated.name }));
      history.push(location.state?.redirectUrl || `/projects/${projectId}/contracts`);
    };

    const onPdfUpdated = () => {
      setIsLoading(false);

      // Browser download
      const downloadUrl = getDownloadUrl(pdfId, getContractPdfName(contractUpdated, new Date()));
      downloadFile(downloadUrl!);

      redirectToListing();
    };

    const { shouldDownloadPDF } = form.getFormValues();
    if (pdfId && shouldDownloadPDF) {
      subscribeToPDFUpdates({ apiId: pdfId }, onPdfUpdated);
    } else {
      redirectToListing();
    }
  }, [
    contractUpdatedRef,
    hasQuoteV2FeatureFlag,
    quoteUUID,
    form,
    contractUpdateQuotationMutation,
    contractId,
    updateContractMutation,
    autoNumberingIsActivated,
    onClose,
    toast,
    t,
    history,
    location.state?.redirectUrl,
    projectId,
  ]);

  return (
    <Form form={form}>
      <Modal title={t('contracts:saveContractUpdateModal.title')} onClose={onClose} isOpen={isOpen} {...props}>
        <Stack spacing={4}>
          <Text>{t('contracts:saveContractUpdateModal.description')}</Text>

          <List pl={4}>
            <List.Item
              icon={<CheckOutlineIcon boxSize={4} />}
              label={t('contracts:saveContractUpdateModal.list.progressStatement')}
            />
            {hasFinalStatement && (
              <List.Item
                icon={<CircleIconLegacy boxSize={4} />}
                label={t('contracts:saveContractUpdateModal.list.finalStatement')}
              />
            )}
            <List.Item icon={<CheckOutlineIcon boxSize={4} />} label={t('contracts:saveContractUpdateModal.list.pdf')}>
              <SwitchField<FormValues>
                name="shouldDownloadPDF"
                label={t('contracts:saveContractUpdateModal.pdfFieldLabel')}
                uncheckedValue={false}
                checkedValue
              />
            </List.Item>
            <FeatureFlag feature={FEATURE_FLAGS.PROJECT_GLOBAL_PARAMETERS}>
              <FeatureFlag.OnActive>
                {holdback && holdback.hasPaymentGuarantee && newHoldbackAmountIncVat && (
                  <List.Item
                    icon={<CircleIconLegacy boxSize={4} />}
                    label={t('contracts:saveContractUpdateModal.list.holdbackHasPaymentGuarantee', {
                      paymentGuaranteeAmount: formatAsAmount(holdback?.paymentGuaranteeAmount!),
                      holdbackAmountIncVAT: formatAsAmount(newHoldbackAmountIncVat),
                    })}
                  />
                )}
              </FeatureFlag.OnActive>
            </FeatureFlag>
          </List>
        </Stack>

        <Modal.Close isDisabled={isLoading} />
        <Modal.PrimaryButton onClick={handleSave} isLoading={isLoading}>
          {t('contracts:saveContractUpdateModal.cta')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
