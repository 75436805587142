import type { FC } from 'react';
import { useCallback } from 'react';
import { MultiAutocompleteField, generateColorFromString } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, useForm } from 'graneet-form';

interface FormValues {
  tags: string[];
}

export interface TagsEditionBlockProps {
  label?: string;
  tags?: string[] | null;
  suggestedTags?: string[] | null;
  onTagsEdited: (newValue: FormValues['tags']) => unknown;
  placeholder: string;
  isEditable?: boolean;
}

type MultipleOption = { value: string; label: string; badgeColor?: string };

export const TagsEditionBlock: FC<TagsEditionBlockProps> = ({
  label,
  tags,
  suggestedTags,
  onTagsEdited,
  isEditable,
}) => {
  const { t } = useTranslation(['global']);

  const onChange = useCallback(
    async (textRawValue: FormValues['tags']) => {
      await onTagsEdited(textRawValue.map((v) => v.trim()));
    },
    [onTagsEdited],
  );

  const form = useForm<FormValues>({
    defaultValues: {
      tags: tags ?? [],
    },
  });

  return (
    <Form form={form}>
      <MultiAutocompleteField<FormValues, MultipleOption>
        label={label}
        cursor="text"
        placeholder={t('global:tags.placeholder')}
        createLabel={t('global:tags.create')}
        allowCreateble
        name="tags"
        variant="outlined"
        width="100%"
        onChange={onChange}
        isReadOnly={!isEditable}
        formatValue={(v) => ({ ...v, badgeColor: generateColorFromString(v.label) })}
        options={
          suggestedTags?.map((tag) => ({ value: tag, label: tag, badgeColor: generateColorFromString(tag) })) || []
        }
      />
    </Form>
  );
};
