import { HStack, Radio, VStack, Text, Flex, Box, Checkbox } from '@chakra-ui/react';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { Modal } from '../../../Modal';
import { SimplePdfIcon } from '../../../Icons/v2/SimplePdfIcon';
import { SimpleExcelIcon } from '../../../Icons/v2/SimpleExcelIcon';
import { Label } from '../../Label/Label';
import { EllipsisText } from '../../../EllipsisText';
import { SimpleLightBulbIcon } from '../../../Icons/v2';
import { Callout } from '../../../Callout/Callout';

type ExportType = 'pdf' | 'excel';

type Item = {
  name: string;
  label: string;
  value: boolean;
  isDisabled?: boolean;
  items?: Item[];
};

type ExportModalProps = {
  title: string;
  selectedLabel: string;
  isExporting: boolean;
  cta: {
    label: string;
    onClick: (items: Item[], exportType: ExportType) => void;
  };
  items: Item[];
  isOpen: boolean;
  onClose: () => void;
  calloutText: string;
};

const ExportTypeCard: FC<{ exportType: ExportType; onClick: () => void; isSelected: boolean }> = ({
  exportType,
  isSelected,
  onClick,
}) => (
  <HStack
    width="100%"
    px="0.75rem"
    borderRadius="0.5rem"
    gap="0.5rem"
    background="white"
    border="1px solid"
    borderColor="gray.200"
    cursor="pointer"
    onClick={onClick}
  >
    <Flex width="2rem" alignItems="center" justifyContent="center">
      <Radio size="sm" value={exportType} isChecked={isSelected} />
    </Flex>
    <VStack alignItems="flex-start" gap="0.15rem">
      <Text fontSize="0.875rem" fontWeight={600} color="#384250">
        {exportType === 'excel' ? 'Microsoft Excel' : 'PDF'}
      </Text>
      <Text fontSize="0.75rem" color="#4D5761">
        (.{exportType === 'excel' ? 'xlsx' : 'pdf'})
      </Text>
    </VStack>
    <Flex ml="auto" alignItems="center" justifyContent="center">
      {exportType === 'pdf' ? <SimplePdfIcon boxSize="2rem" /> : <SimpleExcelIcon boxSize="2rem" />}
    </Flex>
  </HStack>
);

const ItemGroup: FC<{
  items: Item[];
  onChange: (name: string) => (e: ChangeEvent<HTMLInputElement>) => void;
  level: number;
}> = ({ items, level, onChange }) => (
  <VStack width="100%" spacing="2px" alignItems="flex-start">
    {items.map((item) => {
      const allChecked = item.items ? item.items.every((i) => i.value) : item.value;
      const isIndeterminate = item.items ? item.items.some((i) => i.value) && !allChecked : false;
      return (
        <Box px={level === 0 ? '0.375rem' : 0} width="100%" key={`item-${item.name}`}>
          <Checkbox
            key={item.name}
            disabled={item.isDisabled}
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            onChange={onChange(item.name)}
            width="100%"
            size="sm"
            overflow="auto"
            borderRadius="0.375rem"
            px="0.875rem"
            pl={`${level * 1 + 0.875}rem`}
            py="0.25rem"
            _hover={{ background: 'rgba(0, 19, 58, 0.05)' }}
          >
            <EllipsisText
              fontSize="0.75rem"
              color={item.items ? '#6C737F' : '#4D5761'}
              fontWeight={item.items ? 600 : 500}
              textTransform={item.items ? 'uppercase' : 'none'}
            >
              {item.label}
            </EllipsisText>
          </Checkbox>
          {item.items && <ItemGroup items={item.items} onChange={onChange} level={level + 1} />}
        </Box>
      );
    })}
  </VStack>
);

const updateSubItems = (items: Item[], name: string, value: boolean): Item[] =>
  items.map((item) => {
    if (item.isDisabled) {
      return item;
    }
    return { ...item, value, items: item.items ? updateSubItems(item.items, name, value) : item.items };
  });

const updateItems = (items: Item[], name: string, value: boolean): Item[] =>
  items.map((item) => {
    if (item.name === name) {
      return { ...item, value, items: item.items ? updateSubItems(item.items, name, value) : item.items };
    }
    return { ...item, items: item.items ? updateItems(item.items, name, value) : item.items };
  });

const numberOfSelectedItems = (items: Item[]): number =>
  items.reduce((acc, item) => {
    if (item.items) {
      return acc + numberOfSelectedItems(item.items);
    }
    return item.value ? acc + 1 : acc;
  }, 0);

const flattenSelectedItems = (itemsList: Item[]): Item[] =>
  itemsList.reduce((acc, { items, ...item }) => {
    if (items) {
      return [...acc, ...flattenSelectedItems(items)];
    }
    return [...acc, item];
  }, [] as Item[]);

export const ExportModal: FC<ExportModalProps> = ({
  title,
  cta,
  isOpen,
  items,
  isExporting,
  selectedLabel,
  onClose,
  calloutText,
}) => {
  const [exportType, setExportType] = useState<ExportType>('pdf');
  const [selectedItems, setSelectedItems] = useState<Item[]>(items);

  const handleCTAClick = useCallback(() => {
    if (selectedItems) {
      cta.onClick(flattenSelectedItems(selectedItems), exportType);
    }
  }, [cta, selectedItems, exportType]);

  const handleExportTypeClick = useCallback(
    (type: ExportType) => () => {
      setExportType(type);
    },
    [],
  );

  const nbSelectedItems = useMemo(() => numberOfSelectedItems(selectedItems), [selectedItems]);

  const handleItemChange = useCallback(
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedItems(updateItems(selectedItems, name, e.target.checked));
    },
    [selectedItems],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="xl">
      <VStack gap="0.75rem">
        <VStack width="100%" alignItems="flex-start">
          <Label>Format</Label>
          <HStack width="100%">
            <ExportTypeCard exportType="pdf" onClick={handleExportTypeClick('pdf')} isSelected={exportType === 'pdf'} />
            <ExportTypeCard
              exportType="excel"
              onClick={handleExportTypeClick('excel')}
              isSelected={exportType === 'excel'}
            />
          </HStack>
        </VStack>
        <VStack
          width="100%"
          alignItems="flex-start"
          borderRadius="0.375rem"
          background="white"
          border="1px solid"
          borderColor="gray.200"
        >
          <HStack width="100%" p="0.5rem" borderBottom="1px solid" borderColor="gray.200">
            <Text fontSize="0.813rem" color="#6C737F">
              {nbSelectedItems} {selectedLabel}
            </Text>
          </HStack>
          <Box maxHeight="18.75rem" overflowY="auto" width="100%" p="0.375rem">
            <ItemGroup items={selectedItems} onChange={handleItemChange} level={0} />
          </Box>
        </VStack>
        <Callout colorScheme="blueLight" icon={<SimpleLightBulbIcon boxSize={5} color="blue.500" />}>
          <Text fontSize="0.813rem">{calloutText}</Text>
        </Callout>
      </VStack>
      <Modal.Close onClick={onClose} />
      <Modal.PrimaryButton
        onClick={handleCTAClick}
        isDisabled={selectedItems.length === 0 || isExporting}
        isLoading={isExporting}
      >
        {cta.label}
      </Modal.PrimaryButton>
    </Modal>
  );
};
