import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { createComponent } from '../services/quote-component.api';
import { useComponentContext } from '../contexts/component.context';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useCreateComponent = () => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);
  const { handleComponentUpdate, jobId, startAnotherUpdate } = useComponentContext();
  const { emitLotUpdate, findLotIdFromJobIdInRelations } = useQuoteEditContext();

  return useCallback(async () => {
    if (!jobId) {
      throw new Error('jobId is undefined');
    }

    startAnotherUpdate();

    const [err, response] = await createComponent(jobId);
    if (err) {
      toast.error(t('global:words.c.error'), t('quote:errors.errorComponent', { email: SUPPORT_EMAIL }));
      return;
    }

    handleComponentUpdate(response, true);

    const currentLot = findLotIdFromJobIdInRelations(jobId);

    if (currentLot) {
      emitLotUpdate(currentLot);
    }
  }, [handleComponentUpdate, jobId, startAnotherUpdate, t, toast, emitLotUpdate, findLotIdFromJobIdInRelations]);
};
