import type { DEADLINE_TYPE } from '../common/deadline-type.constant';
import type { ISupplierInvoiceProject } from '../supplier-invoice/supplier-invoice-project.type';
import type { ITimeSlot } from '../time-tracking/time-slot.type';
import type { IBudget } from '../budget/budget.type';
import type { IOrder } from '../order/order.type';
import type { PaginatedResponse } from '../pagination/paginated-response.type';
import type { IAddress } from '../address/address.type';
import type { GRANEET_COLORS } from '../common/constants/colors.constant';
import type { IClient } from '../client/client.type';
import type { IPaymentMethod } from '../payment-method/payment-method.type';
import type { IContract } from '../contract/contract.type';
import type { IDownPayment } from '../down-payment/down-payment.type';
import type { IProgressStatement } from '../progress-statement/progress-statement.type';
import type { IBill } from '../bill/bill.type';
import type { IQuote } from '../quote/quote.type';
import type { ICompany } from '../company/company.type';
import type { IFinalStatement } from '../final-statement/final-statement.type';
import type { IInvoice } from '../invoice/invoice.type';
import type { IFile } from '../file/file.type';
import type { ISubProject } from '../sub-project/sub-project.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IContactProject } from '../contact/contact-project.entity';
import type { ISupplierInvoiceItem } from '../supplier-invoice/supplier-invoice-item.type';

import type { IProjectAccountManager } from './project-account-manager.type';
import type { ISecondaryContactProject } from './secondary-contact-project.type';
import type { IProjectFinancialSummary } from './project-financial-summary.type';

export enum PROJECT_STATUSES {
  IMPORTING = 'IMPORTING',
  ON_GOING = 'ON_GOING',
  ENDED = 'ENDED',
  ARCHIVED = 'ARCHIVED',
}

export enum PRICE_REVISION_METHOD {
  WEIGHTED_VAT = 'WEIGHTED_VAT', // Old method
  PRORATA_VAT = 'PRORATA_VAT', // New method
}

export interface IProject {
  id: number;

  name: string;

  status: PROJECT_STATUSES;

  refCode: string | null;

  address?: IAddress;

  progressStatementCreationDayLimit: number | null;

  paymentTerm: number;

  deadlineType: DEADLINE_TYPE;

  startDate: string | null;

  endDate: string | null;

  comment: string | null;

  defaultKmExpense: number;

  note: string;

  amountExVAT: number;

  amountIncVAT: number;

  priceRevisionMethod: PRICE_REVISION_METHOD;

  cumulativeProgressPercentage: number;

  amountToBeBilledExVAT: number;

  amountReceivedIncVAT: number;

  amountInvoicedExVAT: number;

  amountInvoicedIncVAT: number;

  amountInvoicedReceivedIncVAT: number;

  purchaseOrderNumber: string | null;

  color: GRANEET_COLORS | null;

  tags: string[];

  alobeesId: string | null;

  alobeesUpdatedAt: string | null;

  importComment: string | null;

  createdAt: Date;

  updatedAt: Date;

  primaryClient?: IClient;

  paymentMethod?: IPaymentMethod;

  contracts?: IContract[];

  downPayments?: IDownPayment[];

  progressStatements?: IProgressStatement[];

  bills?: IBill[];

  quotes?: IQuote[];

  company?: ICompany;

  secondaryContactProjects?: ISecondaryContactProject[];

  finalStatements?: IFinalStatement[];

  invoices?: IInvoice[];

  projectAccountManagers?: IProjectAccountManager[];

  acceptanceReport?: IFile | null;

  timeSlots?: ITimeSlot[];

  supplierInvoiceProjects?: ISupplierInvoiceProject[];

  supplierInvoiceItems?: ISupplierInvoiceItem[];

  budgets?: IBudget[];

  orders?: IOrder[];

  subProjects?: ISubProject[];

  contactProjects?: IContactProject[];

  importFile?: IFile | null;

  projectFinancialSummary?: IProjectFinancialSummary | null;
}

export type ProjectPaginatedResponse = PaginatedResponse<
  RequiredByKeys<IProject, 'projectAccountManagers' | 'address' | 'paymentMethod' | 'primaryClient'>,
  'amountExVAT' | 'amountToBeBilledExVAT'
>;

export type IProjectWithRelations = RequiredByKeys<
  IProject,
  | 'address'
  | 'paymentMethod'
  | 'primaryClient'
  | 'acceptanceReport'
  | 'projectAccountManagers'
  | 'company'
  | 'importFile'
>;
