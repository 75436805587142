import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Input, Modal } from '@graneet/lib-ui';
import type {
  IProject,
  ISubProjectByProjectListingResponseDTO,
  ISubProjectWithInformation,
} from '@graneet/business-logic';
import { SUB_PROJECT_BILLING_TYPE } from '@graneet/business-logic';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { getProgressStatementCreationState } from '../../services/progress-statement.util';
import { SubProjectSelectionTable } from '../../../sub-project/components/SubProjectSelectionTable';

import { filterOnlyContaining } from 'features/common/services/common.util';
import { SubProjectSelectBillingTypeModal } from 'features/sub-project/components/SubProjectSelectBillingTypeModal';

interface ProgressStatementCreateOnSubProjectModalProps {
  onClose(): void;

  isOpen: boolean;

  subProjects: ISubProjectByProjectListingResponseDTO;

  project: IProject;
}

export const ProgressStatementCreateOnSubProjectModal: FC<ProgressStatementCreateOnSubProjectModalProps> = ({
  onClose,
  isOpen,
  subProjects,
  project,
}) => {
  const { t } = useTranslation(['progressStatement', 'project', 'global']);

  const history = useHistory();

  const modal = useDisclosure();

  const [search, setSearch] = useState('');
  const filteredSubProjects = useMemo(
    () => filterOnlyContaining(subProjects || [], ['name'], search),
    [search, subProjects],
  );

  const [selectedSubProject, setSelectedSubProject] = useState<ISubProjectWithInformation | null>(null);

  const onSubProjectSelect = useCallback((newSubProject: ISubProjectWithInformation | null) => {
    setSelectedSubProject(newSubProject);
  }, []);

  const getRowState = useCallback(
    (subProject: ISubProjectWithInformation) => {
      const { canCreate, tooltip } = getProgressStatementCreationState(subProject, t, true);

      return { isDisabled: !canCreate, tooltipMessage: tooltip };
    },
    [t],
  );

  const onClick = useCallback(() => {
    if (selectedSubProject?.billingType === null) {
      modal.onOpen();
      return;
    }

    if (selectedSubProject?.billingType === SUB_PROJECT_BILLING_TYPE.DIRECT) {
      history.push(
        `/projects/${project.id}/statements/sub-projects/${selectedSubProject.id}/progress-statements/direct/create`,
      );
      return;
    }

    history.push(
      `/projects/${project.id}/statements/sub-projects/${selectedSubProject?.id}/progress-statements/create`,
    );
  }, [history, modal, project.id, selectedSubProject?.billingType, selectedSubProject?.id]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        title={t('progressStatement:modalCreateProgressStatement.title')}
        size="3xl"
      >
        <Box mb={2}>
          <Input
            w={['100%', '100%', '20rem']}
            placeholder={t('global:words.c.research')}
            onChange={setSearch}
            value={search}
          />
        </Box>

        <SubProjectSelectionTable
          subProjects={filteredSubProjects}
          onSubProjectSelect={onSubProjectSelect}
          getRowState={getRowState}
        />

        <Modal.Close />

        <Modal.PrimaryButton onClick={onClick} isDisabled={!selectedSubProject}>
          {t('progressStatement:modalCreateProgressStatement.cta')}
        </Modal.PrimaryButton>
      </Modal>

      {selectedSubProject && (
        <SubProjectSelectBillingTypeModal
          isOpen={modal.isOpen}
          onClose={modal.onClose}
          isDirectDisabled={selectedSubProject.hasDirectPaymentOrders}
          project={project}
          subProject={selectedSubProject}
        />
      )}
    </>
  );
};
