import { Grid, GridItem, Box } from '@chakra-ui/react';
import { FieldGroupLabel, TextField, TwoColumns, Tooltip, SimpleHelpIcon } from '@graneet/lib-ui';
import { useFormContext } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useEffect } from 'react';
import type { ICompanyResponseDTO } from '@graneet/business-logic';
import { isHeadquartersSameAsBillingAddress } from '@graneet/business-logic';

import type { CompanyInformationFormValues } from '../constants/company.constant';

import { Rule } from 'features/form/rules/Rule';
import { AddressesWithBillingFields } from 'features/common/components/fields/AddressesWithBillingFields';

interface CompanyInformationFieldsProps {
  company?: ICompanyResponseDTO;
  isEdit?: boolean;
}

export const CompanyInformationFields: FC<CompanyInformationFieldsProps> = ({ company, isEdit = false }) => {
  const { t } = useTranslation(['company']);
  const form = useFormContext<CompanyInformationFormValues>();
  const { setFormValues } = form;

  useEffect(() => {
    if (!company) {
      return;
    }

    const isSameAddressAsBillingAddress = isHeadquartersSameAsBillingAddress(company);
    const { headquartersAddress } = company;

    const initialFormValues: Partial<CompanyInformationFormValues> = {
      name: company.name,
      corporateName: company.corporateName,
      legalForm: company.legalForm,
      rcsNumber: company.rcsNumber,
      companyNumber: company.companyNumber,
      nafCode: company.nafCode,
      vatNumber: company.vatNumber,
      email: company.email,
      phoneNumber: company.phoneNumber,
      hasNoBillingAddress: isSameAddressAsBillingAddress,

      // Headquarters Address
      address: headquartersAddress.address,
      city: headquartersAddress.city,
      postalCode: headquartersAddress.postalCode,
      country: headquartersAddress.country,

      ...(!isSameAddressAsBillingAddress && {
        // Billing address
        billingAddressAddress: company.billingAddress.address,
        billingAddressCity: company.billingAddress.city,
        billingAddressPostalCode: company.billingAddress.postalCode,
        billingAddressCountry: company.billingAddress.country,
      }),
    };

    setFormValues(initialFormValues);
  }, [setFormValues, company]);

  return (
    <Grid gap={6}>
      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
        <TextField<CompanyInformationFormValues>
          name="name"
          label={t('company:information.fields.name')}
          isReadOnly={!isEdit}
          rightIcon={
            <Tooltip label={t('company:editCompanyModal.tooltips.name')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          }
          isRequired
        >
          <Rule.IsRequired />
        </TextField>

        <TextField<CompanyInformationFormValues>
          name="corporateName"
          label={t('company:information.fields.corporateName')}
          isReadOnly={!isEdit}
          rightIcon={
            <Tooltip label={t('company:editCompanyModal.tooltips.corporateName')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          }
          isRequired
        >
          <Rule.IsRequired />
        </TextField>

        <TextField<CompanyInformationFormValues>
          name="legalForm"
          label={t('company:information.fields.legalForm')}
          isReadOnly={!isEdit}
          rightIcon={
            <Tooltip label={t('company:editCompanyModal.tooltips.legalForm')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          }
          isRequired
        >
          <Rule.IsRequired />
        </TextField>

        <GridItem />

        <TextField<CompanyInformationFormValues>
          name="rcsNumber"
          label={t('company:information.fields.rcsNumber')}
          isReadOnly={!isEdit}
          rightIcon={
            <Tooltip label={t('company:editCompanyModal.tooltips.rcsNumber')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          }
          isRequired
        >
          <Rule.IsRequired />
        </TextField>

        <TextField<CompanyInformationFormValues>
          name="companyNumber"
          label={t('company:information.fields.companyNumber')}
          isReadOnly={!isEdit}
          rightIcon={
            <Tooltip label={t('company:editCompanyModal.tooltips.companyNumber')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          }
          isRequired
        >
          <Rule.IsRequired />
        </TextField>

        <TextField<CompanyInformationFormValues>
          name="nafCode"
          label={t('company:information.fields.nafCode')}
          isReadOnly={!isEdit}
          rightIcon={
            <Tooltip label={t('company:editCompanyModal.tooltips.nafCode')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          }
          isRequired
        >
          <Rule.IsRequired />
        </TextField>

        <TextField<CompanyInformationFormValues>
          name="vatNumber"
          label={t('company:information.fields.vatNumber')}
          isReadOnly={!isEdit}
          rightIcon={
            <Tooltip label={t('company:editCompanyModal.tooltips.vatNumber')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          }
          isRequired
        >
          <Rule.IsRequired />
        </TextField>
      </Grid>

      <AddressesWithBillingFields isReadOnly={!isEdit} />

      <Grid gap={2}>
        <FieldGroupLabel>{t('company:information.contact')}</FieldGroupLabel>
        <TwoColumns>
          <Grid templateColumns="1fr 13rem" gap={6}>
            <TextField<CompanyInformationFormValues>
              name="email"
              label={t('company:information.fields.email')}
              isReadOnly={!isEdit}
            >
              <Rule.IsEmail />
            </TextField>
            <TextField<CompanyInformationFormValues>
              name="phoneNumber"
              label={t('company:information.fields.phoneNumber')}
              isReadOnly={!isEdit}
            />
          </Grid>
        </TwoColumns>
      </Grid>
    </Grid>
  );
};
