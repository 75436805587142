import { useCallback } from 'react';
import { QuoteFlattenObject, type QuoteObject } from '@org/quotation-lib';
import { omitBy, isUndefined } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useToast } from '@graneet/lib-ui';

import { useQuote } from './useQuote';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { updateQuote } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import {
  useQuoteSetClientRequestsStore,
  StatusEnum,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteUpdate() {
  const { quote } = useQuote();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();

  const { t } = useTranslation(['global', 'quote']);
  const { quoteProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();
  const toast = useToast();

  return useCallback(
    (object: Partial<QuoteObject>, displayToast = true): boolean => {
      try {
        quote.updateProperties(object);

        const quoteFlatObject = QuoteFlattenObject.execute(quote.export());
        updateQuote(quoteFlatObject.quote);

        const filterObject = omitBy(object, isUndefined);
        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });

        quoteProxyApi
          .updateQuoteAsync({
            ...filterObject,
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        if (displayToast) {
          toast.success(t('quote:toast.success'));
        }
        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteError, quoteProxyApi, quoteSetClientRequestToStore, t, toast],
  );
}
