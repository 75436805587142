import type { QuoteNodeDTO } from '@org/graneet-bff-client';
import type { QuoteNodeObject } from '@org/quotation-lib';

import { mapMarginObjectToMarginDTO } from './quoteMarginObjectToMarginDTO';
import { mapQuantityFormulaObjectToQuantityFormulaDTO } from './quoteQuantityFormulaObjectToQuantityFormulaDTO';
import { mapQuoteFileObjectToFileDTO } from './quoteFileObjectToFileDTO';

export function mapQuoteNodeObjectToQuoteNodeDTO(quoteNode: QuoteNodeObject): QuoteNodeDTO {
  const marginDTO = mapMarginObjectToMarginDTO(quoteNode.content.margin);

  if (marginDTO === undefined) {
    throw new Error('marginDTO is required');
  }

  if (quoteNode.content.type === 'QuoteLot') {
    return {
      ...quoteNode,
      content: {
        ...quoteNode.content,
        margin: marginDTO,
      },
    };
  }

  const quantityFormulaDTO = mapQuantityFormulaObjectToQuantityFormulaDTO(quoteNode.content.quantityFormula);

  if (quoteNode.content.type === 'QuoteComponent') {
    return {
      ...quoteNode,
      content: {
        ...quoteNode.content,
        type: quoteNode.content.type,
        margin: marginDTO,
        quantityFormula: quantityFormulaDTO,
        vatRate: null,
      },
    };
  }

  const filesDTO = quoteNode.content.files.map((file) => mapQuoteFileObjectToFileDTO(file));

  return {
    ...quoteNode,
    content: {
      ...quoteNode.content,
      margin: marginDTO,
      quantityFormula: quantityFormulaDTO,
      files: filesDTO,
    },
  };
}
