import type { FC } from 'react';
import { Card, AmountSummary } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { VAT_TYPE, type IDownPayment, type ISubProject } from '@graneet/business-logic';

interface DirectProgressStatementSummaryCardProps {
  subProject: ISubProject;
  downPayment?: IDownPayment | null;
}

export const DirectProgressStatementSummaryCard: FC<DirectProgressStatementSummaryCardProps> = ({
  subProject,
  downPayment,
}) => {
  const { t } = useTranslation(['directProgressStatement', 'progressStatement']);

  return (
    <Card pb={8} w="100%">
      <AmountSummary>
        <AmountSummary.Highlight
          left
          label={t('directProgressStatement:form.information.amountExVAT')}
          amount={subProject.amountExVAT}
        />
        <AmountSummary.VATDistribution
          left
          vatType={subProject.hasReversalOfLiability ? VAT_TYPE.REVERSE_CHARGE : VAT_TYPE.NORMAL}
          vatDistribution={subProject.contractsVATDistribution}
          withCustomDiscountsLabel={subProject.hasReversalOfLiability && !!subProject.contractsVATDistribution?.length}
        />
        <AmountSummary.Highlight
          left
          label={
            downPayment
              ? t('directProgressStatement:form.information.amountIncVAT')
              : t('progressStatement:isCreating.validationStep.netBillIncVAT')
          }
          amount={subProject.amountIncVAT}
        />
        {downPayment && (
          <>
            <AmountSummary.Item
              left
              sign
              negative
              light
              label={t('progressStatement:isCreating.validationStep.downPaymentAmortizationIncVAT')}
              amount={downPayment.amountIncVAT}
            />
            <AmountSummary.Highlight
              left
              label={t('progressStatement:isCreating.validationStep.netBillIncVAT')}
              amount={subProject.amountIncVAT - downPayment.amountIncVAT}
            />
          </>
        )}
      </AmountSummary>
    </Card>
  );
};
