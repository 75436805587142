import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleLibraryIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props} fill="none">
    <g clipPath="url(#clip0_159_2576)">
      <path
        d="M14.1334 14.6667C14.32 14.6667 14.4134 14.6667 14.4847 14.6303C14.5474 14.5984 14.5984 14.5474 14.6304 14.4847C14.6667 14.4133 14.6667 14.32 14.6667 14.1333V7.2C14.6667 7.01333 14.6667 6.92 14.6304 6.84866C14.5984 6.78593 14.5474 6.73493 14.4847 6.703C14.4134 6.66666 14.32 6.66666 14.1334 6.66666H12.5334C12.3467 6.66666 12.2534 6.66666 12.182 6.703C12.1193 6.73493 12.0683 6.78593 12.0364 6.84866C12 6.92 12 7.01333 12 7.2V8.8C12 8.98666 12 9.08 11.9637 9.15133C11.9318 9.21406 11.8808 9.26506 11.818 9.297C11.7467 9.33333 11.6534 9.33333 11.4667 9.33333H9.86669C9.68002 9.33333 9.58669 9.33333 9.51535 9.36966C9.45262 9.4016 9.40162 9.4526 9.36969 9.51533C9.33335 9.58666 9.33335 9.68 9.33335 9.86666V11.4667C9.33335 11.6533 9.33335 11.7467 9.29702 11.818C9.26509 11.8807 9.21409 11.9317 9.15135 11.9637C9.08002 12 8.98669 12 8.80002 12H7.20002C7.01335 12 6.92002 12 6.84869 12.0363C6.78595 12.0683 6.73495 12.1193 6.70302 12.182C6.66669 12.2533 6.66669 12.3467 6.66669 12.5333V14.1333C6.66669 14.32 6.66669 14.4133 6.70302 14.4847C6.73495 14.5474 6.78595 14.5984 6.84869 14.6303C6.92002 14.6667 7.01335 14.6667 7.20002 14.6667H14.1334Z"
        stroke="#627D98"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66669 4.53333C6.66669 4.34665 6.66669 4.25331 6.70302 4.182C6.73495 4.11928 6.78595 4.06829 6.84869 4.03633C6.92002 4 7.01335 4 7.20002 4H8.80002C8.98669 4 9.08002 4 9.15135 4.03633C9.21409 4.06829 9.26509 4.11928 9.29702 4.182C9.33335 4.25331 9.33335 4.34665 9.33335 4.53333V6.13333C9.33335 6.32002 9.33335 6.41336 9.29702 6.48467C9.26509 6.54739 9.21409 6.59838 9.15135 6.63033C9.08002 6.66667 8.98669 6.66667 8.80002 6.66667H7.20002C7.01335 6.66667 6.92002 6.66667 6.84869 6.63033C6.78595 6.59838 6.73495 6.54739 6.70302 6.48467C6.66669 6.41336 6.66669 6.32002 6.66669 6.13333V4.53333Z"
        stroke="#627D98"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8.53333C2 8.34667 2 8.25333 2.03633 8.182C2.06829 8.11927 2.11928 8.06827 2.182 8.03633C2.25331 8 2.34665 8 2.53333 8H4.13333C4.32002 8 4.41336 8 4.48467 8.03633C4.54739 8.06827 4.59838 8.11927 4.63033 8.182C4.66667 8.25333 4.66667 8.34667 4.66667 8.53333V10.1333C4.66667 10.32 4.66667 10.4133 4.63033 10.4847C4.59838 10.5474 4.54739 10.5984 4.48467 10.6303C4.41336 10.6667 4.32002 10.6667 4.13333 10.6667H2.53333C2.34665 10.6667 2.25331 10.6667 2.182 10.6303C2.11928 10.5984 2.06829 10.5474 2.03633 10.4847C2 10.4133 2 10.32 2 10.1333V8.53333Z"
        stroke="#627D98"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33331 1.86667C1.33331 1.67998 1.33331 1.58664 1.36965 1.51534C1.4016 1.45262 1.45259 1.40162 1.51531 1.36967C1.58662 1.33334 1.67996 1.33334 1.86665 1.33334H3.46665C3.65333 1.33334 3.74667 1.33334 3.81798 1.36967C3.8807 1.40162 3.93169 1.45262 3.96365 1.51534C3.99998 1.58664 3.99998 1.67998 3.99998 1.86667V3.46667C3.99998 3.65336 3.99998 3.7467 3.96365 3.818C3.93169 3.88072 3.8807 3.93172 3.81798 3.96367C3.74667 4 3.65333 4 3.46665 4H1.86665C1.67996 4 1.58662 4 1.51531 3.96367C1.45259 3.93172 1.4016 3.88072 1.36965 3.818C1.33331 3.7467 1.33331 3.65336 1.33331 3.46667V1.86667Z"
        stroke="#627D98"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_159_2576">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
