import { useCallback } from 'react';

import type { QuoteWsMessage } from '../../quote-common/interfaces/quoteWsMessage';
import { useQuote } from '../hooks/useQuote';

import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import { quoteIsRequestInConflictSetToStore } from 'features/quotation/quote-common/store/quoteIsRequestInConflictSetToStore';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';

export const useCreateQuoteRevisionRejectedCallback = () => {
  const { reloadQuote } = useQuote();

  return useCallback(
    (message: QuoteWsMessage) => {
      const quote = quoteGetFromStore();
      const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();

      if (quote) {
        const hasClientRequest = quoteClientRequestsFromStore.find(
          (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
        );
        if (hasClientRequest) {
          quoteIsRequestInConflictSetToStore(true);
          quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest);
        }
      }

      reloadQuote();
    },
    [reloadQuote],
  );
};
