import type { FC } from 'react';
import { useCallback } from 'react';
import { Modal, useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import type { ILibraryComponent, ILibraryComponentWithRelations } from '@graneet/business-logic';

import type { LibraryComponentFieldsForm } from '../LibraryComponentFields';
import { LibraryComponentFields } from '../LibraryComponentFields';
import { useLibraryComponentCreate } from '../../services/library-component.api';

import { QueryWrapper } from 'features/api/components/QueryWrapper';

const FORM_ID = 'library-component-duplicate';

interface LibraryComponentDuplicateModalProps {
  sourceLibraryComponent: ILibraryComponentWithRelations;

  modalControls: UseDisclosureReturn;

  onLibraryComponentDuplicated(libraryComponentOnDuplicated: ILibraryComponent): void | Promise<void>;
}

export const LibraryComponentDuplicateModal: FC<LibraryComponentDuplicateModalProps> = ({
  sourceLibraryComponent,
  modalControls,
  onLibraryComponentDuplicated,
}) => {
  const { t } = useTranslation(['global', 'library']);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const libraryComponentCreateMutation = useLibraryComponentCreate('duplication');

  const form = useForm<LibraryComponentFieldsForm>({
    defaultValues: {
      ...sourceLibraryComponent,
      refCode: sourceLibraryComponent.refCode,
      componentTypeId: sourceLibraryComponent.componentType.id,
      unitDisbursementExVAT: mapNumberToAmount(sourceLibraryComponent.unitDisbursementExVAT),
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const handleSubmit = useCallback(
    async (formValues: LibraryComponentFieldsForm) => {
      const { unitDisbursementExVAT, refCode, componentTypeId, description, unit } = formValues;

      libraryComponentCreateMutation.mutate(
        {
          componentTypeId: componentTypeId!,
          refCode: refCode!,
          description: description!,
          unit: unit!,
          unitDisbursementExVAT: mapAmountToNumber(unitDisbursementExVAT!),
        },
        {
          onSuccess: (component) => {
            modalControls.onClose();
            onLibraryComponentDuplicated(component);
          },
        },
      );
    },
    [libraryComponentCreateMutation, mapAmountToNumber, modalControls, onLibraryComponentDuplicated],
  );

  return (
    <Modal {...modalControls} title={t('library:duplicateLibraryComponentModal.title')} size="5xl" autoFocus={false}>
      <QueryWrapper>
        <Form form={form} onSubmit={form.handleSubmit(handleSubmit)} id={FORM_ID}>
          <Text mb={4}>{t('library:duplicateLibraryComponentModal.description')}</Text>
          <LibraryComponentFields />

          <Modal.Close isDisabled={libraryComponentCreateMutation.isPending} />

          <Modal.PrimaryButton
            isLoading={libraryComponentCreateMutation.isPending}
            isDisabled={!isFormValid}
            type="submit"
            form={FORM_ID}
          >
            {t('library:duplicateLibraryComponentModal.cta')}
          </Modal.PrimaryButton>
        </Form>
      </QueryWrapper>
    </Modal>
  );
};
