import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import {
  PriceAdvanced,
  SimpleBuildingIcon,
  type PaginatedTableProps,
  ListingLayout,
  PaginatedTable,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IProject, ProjectPaginatedResponse, RequiredByKeys } from '@graneet/business-logic';
import { PROJECT_STATUSES } from '@graneet/business-logic';

import { useProjects } from '../../services/project.api';

const FORCED_FILTERS = {
  status: [PROJECT_STATUSES.ON_GOING],
};

interface AssociateProjectBoxProps {
  description: ReactNode;

  onSelectProject: (project: RequiredByKeys<IProject, 'address'>) => void;
}

export const AssociateProjectBox: FC<AssociateProjectBoxProps> = ({ onSelectProject, description }) => {
  const { t } = useTranslation(['project']);

  const projects = useProjects({
    storageStrategy: 'state',
    forcedFilters: FORCED_FILTERS,
  });

  const columnDefs = useMemo<PaginatedTableProps<ProjectPaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'refCode',
        headerName: t('project:fields.code'),
        sortable: true,
      },
      {
        field: 'name',
        headerName: t('project:fields.name'),
        sortable: true,
      },
      {
        field: 'primaryClient',
        headerName: t('project:fields.client'),
        sortable: true,
        valueFormatter: (v) => v.data?.primaryClient.enterpriseName ?? '',
      },
      {
        field: 'amountExVAT',
        headerName: t('project:fields.amountExVAT'),
        sortable: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) => (props.data ? <PriceAdvanced amount={props.data.amountExVAT} /> : null),
      },
    ],
    [t],
  );

  const onRowSelected = useCallback<NonNullable<PaginatedTableProps<ProjectPaginatedResponse>['onRowSelected']>>(
    (event) => {
      onSelectProject(event.api.getSelectedRows()[0]);
    },
    [onSelectProject],
  );

  return (
    <Box width="100%" h="40rem">
      <Box mb={3}>{description}</Box>

      <ListingLayout
        pagination={projects}
        search={{
          placeholder: t('project:search'),
        }}
        content={
          <PaginatedTable
            gridId="project-associtate"
            rowSelection="single"
            onRowSelected={onRowSelected}
            pagination={projects}
            columnDefs={columnDefs}
            zeroState={{
              icon: <SimpleBuildingIcon backgroundColor="gray.100" boxSize={45} />,
              label: t('project:noProject'),
            }}
            emptyState={{
              label: t('project:noResult'),
            }}
            defaultColDef={{
              resizable: false,
            }}
          />
        }
      />
    </Box>
  );
};
