import type { FC } from 'react';
import { useEffect, useCallback, useState } from 'react';
import { Box, Flex, Wrap, WrapItem } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import type { ImageListItem } from '@graneet/lib-ui';
import { Steps } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IOnboardingExistingUserValidationDTO, PERSONNA } from '@graneet/business-logic';

import { EditOnboardingUserProfileStep } from './_steps/EditOnboardingUserProfileStep';
import { EditOnboardingLegalInformationStep } from './_steps/EditOnboardingLegalInformationStep';
import { EditOnboardingAdditionalInformationStep } from './_steps/EditOnboardingAdditionalInformationStep';
import { EditOnboardingCongratulationScreen } from './_steps/EditOnboardingCongratulationScreen';

import { setAxiosAuthToken } from 'features/app/services/app.util';
import { useCurrentUserQuery } from 'features/user/services/user.api';
import type { EditOnboardingWizard } from 'features/onboarding/forms/EditOnboardingWizard';
import stars from 'features/onboarding/components/OnboardingSlogan/stars.svg';
import {
  useOnboarding,
  useValidateExistingUserOnboarding,
  useValidateOnboarding,
} from 'features/onboarding/services/onboarding.api';
import { OnboardingSlogan } from 'features/onboarding/components/OnboardingSlogan/OnboardingSlogan';
import { OnboardingSignIn } from 'features/onboarding/components/OnboardingSignIn';

export const StartOnboardingScreen: FC = () => {
  const { t } = useTranslation(['global', 'onboarding']);

  const { onboardingId } = useParams<{ onboardingId: string }>();

  const onboarding = useOnboarding(onboardingId);

  const validateOnboardingMutation = useValidateOnboarding();
  const validateExistingUserOnboardingMutation = useValidateExistingUserOnboarding();

  const [userInformation, setUserInformation] = useState<
    | null
    | {
        email: string;
        password: string;
      }
    | { firstName?: string; lastName?: string; phoneNumber?: string; personna?: PERSONNA; email?: string }
  >();
  const onSignIn = useCallback(async (formValues: { email: string; password: string }) => {
    setUserInformation(formValues);
  }, []);

  const [mustFetchCurrentUser, setMustFetchCurrentUser] = useState(false);
  const currentUser = useCurrentUserQuery({ enabled: mustFetchCurrentUser });
  useEffect(() => {
    if (!currentUser.data) {
      return;
    }
    setUserInformation({
      firstName: currentUser.data.user.firstName,
      lastName: currentUser.data.user.lastName,
      phoneNumber: currentUser.data.user.phone || undefined,
      personna: currentUser.data.user.personna || undefined,
      email: currentUser.data.user.email || undefined,
    });
  }, [currentUser.data]);

  const onLogIn = useCallback(async (token: string) => {
    setAxiosAuthToken(token);
    setMustFetchCurrentUser(true);
  }, []);

  const [displayCongratulationScreen, setDisplayCongratulationScreen] = useState(false);

  const onFinish = useCallback(
    async (stepsValues: EditOnboardingWizard) => {
      const email = userInformation && 'email' in userInformation ? userInformation.email! : '';

      const dto: IOnboardingExistingUserValidationDTO = {
        company: {
          name: stepsValues.legalInformation.name,
          headquartersAddress: {
            address: stepsValues.legalInformation.address,
            postalCode: stepsValues.legalInformation.postalCode,
            city: stepsValues.legalInformation.city,
            country: stepsValues.legalInformation.country,
          },
          corporateName: stepsValues.legalInformation.corporateName,
          legalForm: stepsValues.legalInformation.legalForm,
          companyNumber: stepsValues.legalInformation.companyNumber,
          vatNumber: stepsValues.legalInformation.vatNumber,
          rcsNumber: stepsValues.legalInformation.rcsNumber,
          nafCode: stepsValues.legalInformation.nafCode,
          phoneNumber: '',
          email,
        },
        user: {
          phone: stepsValues.userProfile.phoneNumber,
          firstName: stepsValues.userProfile.firstName,
          lastName: stepsValues.userProfile.lastName,
          personna: stepsValues.userProfile.personna,
        },
        paymentMethod: {
          name: stepsValues.additionalInformation.paymentMethodName,
          iban: stepsValues.additionalInformation.iban,
          bic: stepsValues.additionalInformation.bic,
        },
      };

      if (userInformation && 'password' in userInformation) {
        await validateOnboardingMutation.mutateAsync({
          id: onboarding.data.id,
          dto: {
            ...dto,
            user: {
              ...dto.user,
              email,
              password: userInformation.password,
            },
          },
        });
      } else {
        await validateExistingUserOnboardingMutation.mutateAsync({ id: onboarding.data.id, dto });
      }

      setDisplayCongratulationScreen(true);
    },
    [onboarding.data.id, userInformation, validateExistingUserOnboardingMutation, validateOnboardingMutation],
  );

  const logoState = useState<ImageListItem[]>([]);

  if (displayCongratulationScreen) {
    return (
      <EditOnboardingCongratulationScreen
        isUserLoggedFromAnExistingAccount={!!userInformation && !('password' in userInformation)}
      />
    );
  }

  if (userInformation) {
    return (
      <Steps<EditOnboardingWizard> onQuit={() => {}} onFinish={onFinish}>
        <Steps.Step<EditOnboardingWizard> name="userProfile" title={t('onboarding:steps.userProfile.title')}>
          <EditOnboardingUserProfileStep userInformation={'firstName' in userInformation ? userInformation : null} />
        </Steps.Step>

        <Steps.Step<EditOnboardingWizard> name="legalInformation" title={t('onboarding:steps.legalInformation.title')}>
          <EditOnboardingLegalInformationStep onboarding={onboarding.data} />
        </Steps.Step>

        <Steps.Step<EditOnboardingWizard>
          name="additionalInformation"
          title={t('onboarding:steps.additionalInformation.title')}
        >
          <EditOnboardingAdditionalInformationStep onboarding={onboarding.data} logoState={logoState} />
        </Steps.Step>
      </Steps>
    );
  }

  return (
    <Box w="100vw" h="100vh">
      <Wrap w="100vw" spacing={0}>
        <WrapItem flexGrow={1} minW="30rem" bg="backgroundLight" h="100vh">
          <Flex
            boxSize="100%"
            backgroundImage={`url(${stars})`}
            backgroundPosition="center 100%"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            alignItems="center"
          >
            <OnboardingSlogan />
          </Flex>
        </WrapItem>

        <WrapItem flexGrow={1} minW="30rem" bg="white" h="100vh" alignItems="center">
          <OnboardingSignIn onSignIn={onSignIn} onLogIn={onLogIn} onboarding={onboarding.data} />
        </WrapItem>
      </Wrap>
    </Box>
  );
};
