import type { IVatDistribution } from '../vat/vat-distribution.type';
import { calculatePaymentVatDistribution } from '../payment/payment.util';

import type { ISupplierInvoicePayment, ISupplierInvoicePaymentExtended } from './supplier-invoice-payment.type';
import { isSupplierInvoiceIncomplete } from './supplier-invoice.util';
import type { ISupplierInvoice, ISupplierInvoiceWithRelations } from './supplier-invoice.type';

type SupplierInvoicePaymentCannotBeCreatedCause = 'missing-information';

export const canSupplierInvoicePaymentBeAdded = (
  supplierInvoice: ISupplierInvoiceWithRelations,
): { ok: boolean; causes: SupplierInvoicePaymentCannotBeCreatedCause[] } => {
  const causes: SupplierInvoicePaymentCannotBeCreatedCause[] = [];

  if (isSupplierInvoiceIncomplete(supplierInvoice)) {
    causes.push('missing-information');
  }

  return {
    causes,
    ok: causes.length === 0,
  };
};

type SupplierInvoicePaymentCannotBeUpdatedCause = 'accounting';

/**
 * Determines whether a supplier invoice payment can be updated.
 *
 * @param {ISupplierInvoicePaymentExtended} supplierInvoicePayment - The supplier invoice payment to check.
 * @returns {Object} - An object containing the result of the check.
 *                    - The 'ok' property indicates whether the payment can be updated.
 *                    - The 'cause' property provides a list of reasons why the payment cannot be updated.
 */
export const canSupplierInvoicePaymentBeUpdated = (
  supplierInvoicePayment: ISupplierInvoicePaymentExtended,
): { ok: boolean; causes: SupplierInvoicePaymentCannotBeUpdatedCause[] } => {
  const causes: SupplierInvoicePaymentCannotBeUpdatedCause[] = [];

  /*
    If it has accounting export
   */
  if (supplierInvoicePayment.isAccountingExported) {
    causes.push('accounting');
  }

  return {
    causes,
    ok: causes.length === 0,
  };
};

type SupplierInvoicePaymentCannotBeDeletedCause = 'accounting';
/**
 * Determines if a supplier invoice payment can be deleted.
 *
 * @param {ISupplierInvoicePaymentExtended} supplierInvoicePayment - The supplier invoice payment object to check.
 * @returns {{ ok: boolean; cause: SupplierInvoicePaymentCannotBeDeletedCause[] }} - An object indicating if the payment can be deleted and the cause if it cannot be deleted.
 */
export const canSupplierInvoicePaymentBeDeleted = (
  supplierInvoicePayment: ISupplierInvoicePaymentExtended,
): { ok: boolean; causes: SupplierInvoicePaymentCannotBeDeletedCause[] } => {
  const causes: SupplierInvoicePaymentCannotBeUpdatedCause[] = [];

  /*
    If it has accounting export
   */
  if (supplierInvoicePayment.isAccountingExported) {
    causes.push('accounting');
  }

  return {
    causes,
    ok: causes.length === 0,
  };
};

export const calculateSupplierInvoicePaymentVatDistribution = (
  amountPaidIncVAT: number,
  otherPayments: ISupplierInvoicePayment[],
  supplierInvoice: Pick<ISupplierInvoice, 'amountIncVAT' | 'vatDistribution'>,
): IVatDistribution =>
  calculatePaymentVatDistribution(
    amountPaidIncVAT,
    otherPayments,
    supplierInvoice.amountIncVAT,
    supplierInvoice.vatDistribution,
  );
