import { Grid } from '@chakra-ui/react';
import { Form, useStepForm } from 'graneet-form';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDirectPaymentContext } from 'features/direct-payment/contexts/DirectPaymentContext';
import { DirectPaymentOrderList } from 'features/direct-payment/components/DirectPaymentOrderList';
import { DirectPaymentSupplierInvoice } from 'features/direct-payment/components/DirectPaymentSupplierInvoice';
import type { ProgressStatementEditWizard } from 'features/progress-statement/forms/progress-statement-edit-wizard';
import type { ProgressStatementDirectPaymentForm } from 'features/progress-statement/forms/progress-statement-direct-payment-form';

export const EditProgressStatementDirectPaymentsStep: FC = () => {
  const { form, initFormValues } = useStepForm<ProgressStatementEditWizard, 'directPayments'>();
  const { progressStatementId } = useParams<{ progressStatementId: string }>();
  const { directPaymentOrders } = useDirectPaymentContext();

  useEffect(() => {
    const initialOrdersSupplierInvoices = directPaymentOrders.reduce<
      ProgressStatementDirectPaymentForm['ordersSupplierInvoices']
    >((acc, { ordersSupplierInvoices, id: orderId }) => {
      const supplierInvoiceAssociated = ordersSupplierInvoices?.find(
        ({ supplierInvoice }) => supplierInvoice.progressStatement?.id === +progressStatementId,
      )?.supplierInvoice;

      if (!supplierInvoiceAssociated) {
        return acc;
      }

      return [
        ...acc,
        {
          orderId,
          supplierInvoiceId: supplierInvoiceAssociated.id,
          isDisplayInProgressStatementPdf: supplierInvoiceAssociated.isDisplayInProgressStatementPdf,
        },
      ];
    }, []);

    initFormValues({
      ordersSupplierInvoices: initialOrdersSupplierInvoices,
    });
  }, [form, initFormValues, directPaymentOrders, progressStatementId]);

  return (
    <Form form={form} style={{ height: '100%' }}>
      <Grid templateColumns="400px 1fr" height="100%" mx={-5}>
        <DirectPaymentOrderList />
        <DirectPaymentSupplierInvoice />
      </Grid>
    </Form>
  );
};
