import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import type { PERMISSION } from '@graneet/business-logic';

import { usePermissions } from '../hooks/usePermissions';

const PermissionContext = createContext<PERMISSION[]>([]);

interface PermissionsProps {
  permissions: PERMISSION[];
}

/**
 * @example
 * ```
 * <MyComponent>
 *
 *   <Permissions permissions={['admin']}>
 *
 *     <Permissions.OnAccess>
 *       <AdminFeature />
 *     </Permissions.OnAccess>
 *
 *     <Permissions.OnForbidden>
 *       <UserFeature />
 *     </Permissions.OnForbidden>
 *
 *   </Permissions>
 *
 * </MyComponent>
 * ```
 */
const PermissionsProvider = ({ permissions: requiredPermissions, children }: PropsWithChildren<PermissionsProps>) => {
  const [permissions, setPermissions] = useState(() => requiredPermissions);

  useEffect(() => {
    setPermissions(() => requiredPermissions);
    // join transforms the non-primitive value (permissions) to primitive value. By that, memo will return
    // same reference if permissions have not changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredPermissions?.join()]);

  return <PermissionContext.Provider value={permissions}>{children}</PermissionContext.Provider>;
};

const OnAccess: FC<PropsWithChildren> = ({ children }) => {
  const permissions = useContext(PermissionContext);
  const hasPermissions = usePermissions(permissions);

  return hasPermissions ? <>{children}</> : null;
};

const OnForbidden: FC<PropsWithChildren> = ({ children }) => {
  const permissions = useContext(PermissionContext);
  const hasPermissions = usePermissions(permissions);

  return !hasPermissions ? <>{children}</> : null;
};

export const Permissions = Object.assign(PermissionsProvider, {
  OnAccess,
  OnForbidden,
});
