import type { FC } from 'react';
import { useCallback, useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import type {
  IContactWithProjectInfos,
  IGroupedContact,
  IProgressStatement,
  IProjectWithRelations,
} from '@graneet/business-logic';

import { ProgressStatementDetail } from 'features/progress-statement/components/ProgressStatementDetail';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import {
  useNextProgressStatement,
  useProgressStatement,
  useProgressStatementsForSubProjects,
} from 'features/progress-statement/services/progress-statement.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

interface ViewProgressStatementsDetailsScreenProps {
  project: IProjectWithRelations;

  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
}

export const ViewProgressStatementsDetailsScreen: FC<ViewProgressStatementsDetailsScreenProps> = ({
  project,
  projectContacts,
}) => {
  const { t } = useTranslation(['progressStatement', 'statement']);
  const { title, breadCrumb, updateHeaderTitle } = useHeaderContext();
  const { pathname } = useLocation();
  const { params } = useRouteMatch<{ progressStatementId: string; projectId: string }>();
  const { progressStatementId, projectId } = params;
  const history = useHistory();
  const toast = useToast();
  const { createRedirectionWithSavedFilters } = useFiltersQuery();
  const formatUrl = (ps: IProgressStatement) => `/projects/${project.id}/statements/progress-statements/${ps.id}`;

  const progressStatement = useProgressStatement(+progressStatementId);
  const nextProgressStatement = useNextProgressStatement(+progressStatementId);
  const progressStatements = useProgressStatementsForSubProjects(progressStatement.data.subProject?.id);

  // When the user click on back button, he should return to the correct page
  const goBackToListing = useCallback(() => {
    createRedirectionWithSavedFilters(`/projects/${project.id}/statements`)();
  }, [createRedirectionWithSavedFilters, project]);

  const handleDeleteProgressStatement = useCallback(() => {
    // Reload project to get up-to-date billing information
    goBackToListing();
  }, [goBackToListing]);

  const goToCancelProgressStatement = useCallback(
    (createDraft: boolean) =>
      history.push(`/projects/${project.id}/statements/progress-statements/${progressStatementId}/cancel`, {
        cancelledInvoiceNumber: progressStatement.data.invoiceNumber,
        createDraft,
      }),
    [history, progressStatement.data, progressStatementId, project.id],
  );

  // When the ps cannot be loaded or if the project isn't the same as the one selected
  // An error is displayed and the user is redirected back to the list
  useEffect(() => {
    const isPSInAnotherProject = progressStatement.data.project.id !== project.id;
    if (isPSInAnotherProject) {
      toast.error(t('progressStatement:errors.progressStatementNotLoaded'));
      goBackToListing();
    }
  }, [goBackToListing, t, toast, progressStatement.data, project]);

  useLayoutEffect(() => {
    updateHeaderTitle(progressStatement.data.name ?? '', [
      ...breadCrumb,
      {
        name: title ?? '',
        link: `/projects/${projectId}`,
      },
      {
        name: t('statement:title'),
        link: `/projects/${projectId}/statements`,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, progressStatement.data.name, updateHeaderTitle, pathname]);

  return (
    <ProgressStatementDetail
      progressStatement={progressStatement.data}
      nextProgressStatement={nextProgressStatement.data ?? undefined}
      progressStatements={progressStatements.data.data}
      formatUrl={formatUrl}
      onGoBack={goBackToListing}
      onDeleted={handleDeleteProgressStatement}
      onCancelConfirmed={goToCancelProgressStatement}
      project={project}
      projectContacts={projectContacts}
    />
  );
};
