import type dayjs from 'dayjs';

export interface StepperItemTranslations {
  date: (t: dayjs.Dayjs) => string;
}

export const stepperItemTranslations: StepperItemTranslations = {
  date: () => '',
};

export const configureDefaultLabels = (translation: StepperItemTranslations) => {
  Object.assign(stepperItemTranslations, translation);
};
