import { TRACKING_EVENT_ENUM } from '@graneet/business-logic';
import { useCallback } from 'react';

import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';

export const useAutoSuggestSelectedEvent = () => {
  const segmentAnalytics = useSegmentAnalytics();

  return useCallback(
    async (type: string) => {
      if (segmentAnalytics) {
        await segmentAnalytics.sendEvent(TRACKING_EVENT_ENUM.AUTOSUGGEST_SELECTED, {
          type,
        });
      }
    },
    [segmentAnalytics],
  );
};
