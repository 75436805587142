import { QuoteStatus } from '@graneet/business-logic';
import type { ButtonColorScheme } from '@graneet/lib-ui';

export const QUOTE_STATUS_COLOR: Record<QuoteStatus, ButtonColorScheme> = {
  [QuoteStatus.Importing]: 'purple',
  [QuoteStatus.Draft]: 'gray',
  [QuoteStatus.Completed]: 'blue',
  [QuoteStatus.Accepted]: 'green',
  [QuoteStatus.Refused]: 'orange',
  [QuoteStatus.Lost]: 'orange',
  // TODO: This status is not used in the app
  [QuoteStatus.Archived]: 'white',
} as const;
