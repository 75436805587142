import type { FC, ReactNode } from 'react';
import type { StyleProps } from '@chakra-ui/react';
import { VStack, useTheme } from '@chakra-ui/react';

import { SimpleFileAttachmentIcon } from '../Icons';
import { EllipsisText } from '../EllipsisText';

import { configureDefaultLabels, fileNameChipTranslations } from './configureDefaultLabels';

export type FileType = {
  id: string;
  name: string;
  extensions: string[];
  icon: (props: any) => ReactNode;
  hoverColor: string;
};

export type FileItem = {
  id: string;
  url: string;
  name: string;
  size?: string;
  extension?: string;
};

const getFileTypes = (colors: any): FileType[] => [
  {
    id: 'pdf',
    name: fileNameChipTranslations.fileTypes.pdf,
    extensions: ['pdf'],
    icon: (iconProps) => <SimpleFileAttachmentIcon {...iconProps} />,
    hoverColor: colors.red[500],
  },
  {
    id: 'spreadSheet',
    name: fileNameChipTranslations.fileTypes.spreadSheet,
    extensions: ['csv', 'xls', 'xlsx', 'ods'],
    // TODO: Update Icon with new svg when available from design team
    icon: (iconProps) => <SimpleFileAttachmentIcon {...iconProps} />,
    hoverColor: colors.green[700],
  },
  {
    id: 'image',
    name: fileNameChipTranslations.fileTypes.image,
    extensions: ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp', 'tiff'],
    icon: (iconProps) => <SimpleFileAttachmentIcon {...iconProps} />,
    hoverColor: colors.blue[500],
  },
  {
    id: 'other',
    name: fileNameChipTranslations.fileTypes.other,
    extensions: ['*'],
    icon: (iconProps) => <SimpleFileAttachmentIcon {...iconProps} />,
    hoverColor: colors.blue[500],
  },
];

export const byteValueNumberFormatter = (lang: string) =>
  new Intl.NumberFormat(lang, {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
  });

export interface FileNameDetailsProps {
  file: FileItem;
  showDetails?: boolean;
  width?: StyleProps['width'];
}

const FileNameDetailsComponent: FC<FileNameDetailsProps> = ({ file, showDetails, width }) => {
  const { colors } = useTheme();
  const availableDetails = file.size || file.extension;
  const defaultIconProps = {
    mr: 2.5,
    boxSize: showDetails && availableDetails ? 8 : 4,
    stroke: 'gray.200',
  };

  let fileTypeName = '';
  let fileIconNode: ReactNode = (
    <SimpleFileAttachmentIcon
      {...defaultIconProps}
      sx={{
        '.file-box:hover &': {
          stroke: `${colors.blue[500]}`,
        },
      }}
    />
  );

  if (file.extension) {
    const fileTypes = getFileTypes(colors);
    const fileType =
      fileTypes.find((type) => type.extensions.includes(file.extension as string)) ||
      fileTypes.find((type) => type.id === 'other');

    if (fileType) {
      fileTypeName = fileType.name;

      const iconProps = {
        ...defaultIconProps,
        sx: {
          '.file-box:hover &': {
            stroke: fileType.hoverColor,
          },
        },
      };

      fileIconNode = fileType.icon(iconProps);
    }
  }

  return (
    <>
      {fileIconNode}
      <VStack
        align="start"
        spacing={0}
        maxW={width ? `calc(${width} - 5rem)` : '15rem'}
        flex="1 1 auto"
        transition="color 0.2s"
        _hover={{ color: 'gray.800' }}
      >
        <EllipsisText fontSize="xs" fontWeight="600" maxW={width ? `calc(${width} - 5rem)` : '10rem'}>
          {file.name}
        </EllipsisText>
        {showDetails && (
          <EllipsisText fontSize="xs" fontWeight="400" maxW={width ? `calc(${width} - 5rem)` : '10rem'}>
            {fileTypeName && file.size ? (
              <>
                {fileTypeName} &middot;{' '}
                {byteValueNumberFormatter(fileNameChipTranslations.lang).format(parseInt(file.size, 10))}
              </>
            ) : (
              availableDetails && (
                <>
                  {fileTypeName && <span>{fileTypeName}</span>}
                  {file.size && (
                    <span>
                      {byteValueNumberFormatter(fileNameChipTranslations.lang).format(parseInt(file.size, 10))}
                    </span>
                  )}
                </>
              )
            )}
          </EllipsisText>
        )}
      </VStack>
    </>
  );
};

export const FileNameDetails = Object.assign(FileNameDetailsComponent, {
  configureDefaultLabels,
});
