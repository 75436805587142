import type { ICompany, ICompanyTimeTrackingSettingsDTO } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { apiNew } from 'features/api/services/apiNew.service';

const TIME_TRACKING_SETTING_PATH = '/companies/time-tracking-settings';

const timeTrackingSettingsFactoryKey = createQueryKeys('time-tracking-settings', {
  get: () => ({
    queryKey: [TIME_TRACKING_SETTING_PATH],
    queryFn: () =>
      apiNew.get<
        never,
        Pick<ICompany, 'ttNbMinutesWorkingDay' | 'ttShowWeekends' | 'ttStartHour' | 'ttEndHour' | 'ttApprovalMode'>
      >(TIME_TRACKING_SETTING_PATH),
  }),
});

export function useTimeTrackingSettings() {
  return useSuspenseQuery(timeTrackingSettingsFactoryKey.get());
}

export function useUpdateTimeTrackingSettings() {
  const toast = useToast();
  const { t } = useTranslation(['timeTracking']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ICompanyTimeTrackingSettingsDTO) =>
      apiNew.patch<ICompanyTimeTrackingSettingsDTO, ICompany>(TIME_TRACKING_SETTING_PATH, dto),
    onSuccess: async () => {
      toast.success(t('timeTracking:settings.timeDefaultsCard.savedSettings'));
      await queryClient.invalidateQueries(timeTrackingSettingsFactoryKey.get());
    },
    onError: () => {
      toast.error(t('timeTracking:settings.timeDefaultsCard.errorSaving'));
    },
  });
}
