import type { EditorState } from 'lexical';

export function isLexicalEditorEmpty(editor: EditorState) {
  const currentState = editor.toJSON();

  return (
    editor.isEmpty() ||
    (currentState.root.children?.length === 1 &&
      currentState.root.children[0].type === 'paragraph' &&
      'children' in currentState.root.children[0] &&
      (currentState.root.children[0].children as []).length === 0)
  );
}
