import { Flex, Text } from '@chakra-ui/react';
import { DiscountIcon, useCurrency } from '@graneet/lib-ui';
import type { ICustomDiscount, IDiscount } from '@graneet/business-logic';
import { sumObjects } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

interface QuoteCustomDiscountLabelProps {
  discount: IDiscount;
  customDiscounts: ICustomDiscount[];
  discountAmount: number;
}

export const QuoteCustomDiscountLabel: FC<QuoteCustomDiscountLabelProps> = ({
  discount,
  discountAmount,
  customDiscounts,
}) => {
  const { t } = useTranslation(['quote']);
  const { formatAsAmount } = useCurrency();

  const amountLabel = t(
    discount ? 'quote:discounts.labelWithDiscountAndCustomDiscount' : 'quote:discounts.labelWithCustomDiscountOnly',
    {
      amount: formatAsAmount(sumObjects(customDiscounts, 'amountExVAT') - discountAmount),
    },
  );

  return (
    <Flex p={2} align="center">
      <DiscountIcon mr={2} />
      <Text color="green.700" fontWeight={600}>
        {amountLabel}
      </Text>
    </Flex>
  );
};
