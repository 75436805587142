import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Badge, DocumentStatusCard, formatDateOrEmpty, DocumentStatusCardActions } from '@graneet/lib-ui';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type {
  IProgressStatement,
  IDownPayment,
  IProgressStatementResponseDTO,
  IProjectWithRelations,
  IProgressStatementVersion,
  IGroupedContact,
  IContactWithProjectInfos,
} from '@graneet/business-logic';
import {
  getEmailVariableData,
  canCompleteDirectProgressStatement,
  PERMISSION,
  PROGRESS_STATEMENT_STATUSES,
  STATEMENT_TYPES,
} from '@graneet/business-logic';
import dayjs from 'dayjs';

import { useProgressStatementChangeStatus } from '../../../services/progress-statement.api';

import { getProjectDefaultEmailRecipient } from 'features/contact/services/contact.util';
import type { MailingModalProps } from 'features/mailing/components/MailingModal/MailingModal';
import { sendMail } from 'features/mailing/services/mailing.api';
import { MailingModal } from 'features/mailing/components/MailingModal/MailingModal';
import { CreditConfirmModal } from 'features/credit/components/modals/CreditConfirmModal';
import { StatementEditInvoiceNumberModal } from 'features/statement-ledger/components/StatementEditInvoiceNumberModal';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useHasLedger } from 'features/statement-ledger/hooks/useHasLedger';
import { ForceDraftProgressStatementModal } from 'features/progress-statement/modals/ForceDraftProgressStatementModal';
import {
  getDirectProgressStatementActions,
  getDirectProgressStatementStatusCardIcon,
} from 'features/progress-statement/services/direct-progress-statement.actions';
import { getDownloadUrl, getPreviewUrl } from 'features/pdf/services/pdf.api';
import { formatFileName } from 'features/file/services/file.util';
import { PROGRESS_STATEMENT_STATUS_COLORS } from 'features/progress-statement/services/progress-statement.constant';
import { UpdateStatementBillingDateModal } from 'features/statement/components/UpdateStatementBillingDateModal';
import { getPublicLinkFromProgressStatement } from 'features/progress-statement/services/external-progress-statement.util';

interface DirectProgressStatementStatusCardProps {
  formatUrl(progressStatement: Pick<IProgressStatement, 'id'>): string;
  progressStatement: IProgressStatementResponseDTO;
  downPayment?: IDownPayment | null;
  project: IProjectWithRelations;
  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
  onCancelConfirmed(createDraft: boolean): void;
  currentVersion: IProgressStatementVersion | undefined;
  openDeletionModal(): void;
}

export const DirectProgressStatementStatusCard: FC<DirectProgressStatementStatusCardProps> = ({
  formatUrl,
  progressStatement,
  project,
  projectContacts,
  downPayment,
  onCancelConfirmed,
  currentVersion,
  openDeletionModal,
}) => {
  const invoiceNumberModal = useDisclosure();
  const confirmCancelModal = useDisclosure();
  const forceDraftModal = useDisclosure();

  const { t } = useTranslation([
    'global',
    'directProgressStatement',
    'quote',
    'invoice',
    'progressStatement',
    'mailing',
  ]);
  const history = useHistory();
  const hasLedger = useHasLedger();
  const updateStatementBillingDateModal = useDisclosure();

  const hasCreatePermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);
  const hasUpdatePermission = usePermissions([PERMISSION.UPDATE_STATUS_STATEMENTS]);
  const hasForceDraftPermission = usePermissions([PERMISSION.FORCE_DRAFT_STATEMENTS]);

  const progressStatementChangeStatusMutation = useProgressStatementChangeStatus('direct');

  const projectId = progressStatement.project.id;

  const goToEditDirectProgressStatement = useCallback(
    (progressStatementToEdit: IProgressStatement) => {
      //  in the case of drafting a completed direct PS, the id provided as argument of the callback
      //  will be different from the one of the completed PS
      history.push(
        `/projects/${projectId}/statements/sub-projects/${progressStatementToEdit.subProject?.id}/progress-statements/direct/${progressStatementToEdit.id}/edit`,
        {
          referrerUrl: formatUrl(progressStatementToEdit),
        },
      );
    },
    [formatUrl, history, projectId],
  );

  const completeProgressStatement = useCallback(
    async (billingDate?: string) => {
      await progressStatementChangeStatusMutation.mutateAsync({
        id: progressStatement.id,
        dto: {
          status: PROGRESS_STATEMENT_STATUSES.COMPLETED,
          billingDate,
        },
      });
    },
    [progressStatement.id, progressStatementChangeStatusMutation],
  );

  const onCompletedButtonClicked = useCallback(async () => {
    if (!hasLedger && !progressStatement.invoiceNumber) {
      invoiceNumberModal.onOpen();
    }

    if (
      hasLedger &&
      !progressStatement.invoiceNumber &&
      dayjs(progressStatement.billingDate).isBefore(dayjs(new Date()), 'day')
    ) {
      updateStatementBillingDateModal.onOpen();
      return;
    }

    await completeProgressStatement();
  }, [
    hasLedger,
    progressStatement.invoiceNumber,
    progressStatement.billingDate,
    completeProgressStatement,
    invoiceNumberModal,
    updateStatementBillingDateModal,
  ]);

  const onEditButtonClicked = useCallback(
    () => goToEditDirectProgressStatement(progressStatement),
    [goToEditDirectProgressStatement, progressStatement],
  );

  const updateInvoiceNumber = useCallback(
    (invoiceNumber: string) =>
      progressStatementChangeStatusMutation.mutateAsync({
        id: progressStatement.id,
        dto: {
          invoiceNumber,
          status: PROGRESS_STATEMENT_STATUSES.COMPLETED,
        },
      }),
    [progressStatement.id, progressStatementChangeStatusMutation],
  );

  /**
   * Mailing
   */
  const mailingModal = useDisclosure();

  const statusCardIcon = useMemo(
    () => getDirectProgressStatementStatusCardIcon(progressStatement.status),
    [progressStatement.status],
  );

  const onViewPdf = useCallback(() => {
    if (currentVersion?.pdf) {
      window.open(getPreviewUrl(currentVersion.pdf.apiId));
    }
  }, [currentVersion]);

  const onDownloadPdf = useCallback(() => {
    if (currentVersion?.pdf) {
      window.open(
        getDownloadUrl(
          currentVersion.pdf.apiId,
          formatFileName(currentVersion.name, currentVersion.billingDate, currentVersion.invoiceNumber),
        ),
      );
    }
  }, [currentVersion]);

  const onMailSubmit = useCallback<MailingModalProps['onSubmit']>(
    (data) => sendMail(data.dto, data.attachments, data.mode),
    [],
  );

  return (
    <>
      <Card title={t('directProgressStatement:detail.statusTitle')} flex={1}>
        <DocumentStatusCard
          mb={4}
          icon={statusCardIcon}
          title={progressStatement.name}
          subtitle={t('quote:fields.creationDate', {
            date: formatDateOrEmpty(progressStatement.createdAt, 'LL'),
          })}
          badgeComponent={
            <Badge colorScheme={PROGRESS_STATEMENT_STATUS_COLORS[progressStatement.status]}>
              {t(`progressStatement:statuses.${progressStatement.status}`)}
            </Badge>
          }
        />

        <DocumentStatusCardActions
          actions={getDirectProgressStatementActions({
            progressStatement,
            pdf: currentVersion?.pdf,

            isCompleteButtonLoading: progressStatementChangeStatusMutation.isPending,
            canCompleteDirectProgressStatement: () =>
              canCompleteDirectProgressStatement(progressStatement, project, downPayment),

            hasCreatePermission,
            hasUpdatePermission,
            hasForceDraftPermission,

            onViewPdf,
            onDownloadPdf,
            onEdit: onEditButtonClicked,
            onOpenMailing: mailingModal.onOpen,
            onSetStatusToCompleted: onCompletedButtonClicked,
            onCancelWithCredit: confirmCancelModal.onOpen,
            onCancelWithoutCredit: forceDraftModal.onOpen,
            onDelete: openDeletionModal,
          })}
        />
      </Card>

      <ForceDraftProgressStatementModal
        modal={forceDraftModal}
        progressStatement={progressStatement}
        onConfirmed={goToEditDirectProgressStatement}
      />

      <CreditConfirmModal modal={confirmCancelModal} onConfirmed={onCancelConfirmed} />

      <UpdateStatementBillingDateModal
        {...updateStatementBillingDateModal}
        currentBillingDate={progressStatement.billingDate}
        validateStatement={completeProgressStatement}
      />

      <StatementEditInvoiceNumberModal
        {...invoiceNumberModal}
        title={t('invoice:modals.complete.title')}
        completeLabel={t('invoice:actions.complete')}
        statementType={STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT}
        statementId={progressStatement.id}
        updateInvoiceNumber={updateInvoiceNumber}
      />

      {currentVersion?.pdf && (
        <MailingModal
          key={JSON.stringify(currentVersion.pdf)} // Refresh modal when pdf is updated
          title={t('mailing:modal.title.progressStatement')}
          isOpen={mailingModal.isOpen}
          onClose={mailingModal.onClose}
          onSubmit={onMailSubmit}
          variableData={getEmailVariableData('progressStatement', {
            progressStatement,
            client: project.primaryClient,
            project,
            publicLink: getPublicLinkFromProgressStatement(progressStatement),
          })}
          defaultMailContacts={getProjectDefaultEmailRecipient(projectContacts)}
        />
      )}
    </>
  );
};
