import { HStack } from '@chakra-ui/react';
import type { GraneetBadgeColor } from '@graneet/lib-ui';
import { BadgeDropdown } from '@graneet/lib-ui';
import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStore } from 'store/store';

export const ComponentTypeEditorCell: FC<CustomCellEditorProps<QuoteComponentsSupplyObject>> = ({
  data,
  onValueChange,
  stopEditing,
}) => {
  const componentTypes = useStore((state) => state.quoteComponentTypes);
  const { t } = useTranslation(['quote']);
  const [selectedComponent, setSelectedComponent] = useState<string>(data?.componentType?.id?.toString() ?? '');

  const handleComponentChange = useCallback(
    (v: string) => {
      onValueChange(v);
      setSelectedComponent(v);
      stopEditing();
    },
    [onValueChange, stopEditing],
  );

  const availableComponents = useMemo(
    () => [
      {
        value: '',
        label: t('quote:quotation.global.componentTypePlaceholder'),
        color: 'gray' as GraneetBadgeColor,
      },
      ...componentTypes.map((component) => ({
        value: component.id.toString(),
        label: component.name,
        color: component.color,
      })),
    ],
    [componentTypes, t],
  );

  return (
    <HStack width="100%" height="100%" alignItems="flex-start" padding={2}>
      <BadgeDropdown
        selectedValue={selectedComponent}
        items={availableComponents}
        placeholder={t('quote:quotation.global.componentTypePlaceholder')}
        onChange={handleComponentChange}
      />
    </HStack>
  );
};
