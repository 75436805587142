import type { ValueOf } from '@graneet/business-logic';

import type { EXPORT_COLUMNS, EXPORT_FILE_TYPES } from '../constants/quote-export.constant';

type IsJobSelectedFieldName = `job-${number}`;
export const generateIsJobSelectedExportFieldName = (jobId: number): IsJobSelectedFieldName => `job-${jobId}`;

export const isJobSelectedFieldName = (fieldName: string): fieldName is IsJobSelectedFieldName =>
  fieldName.startsWith('job-');

export const getJobIdFromIsJobSelectedFieldName = (fieldName: IsJobSelectedFieldName) =>
  parseInt(fieldName.split('-')[1], 10);

type IsLotSelectedFieldName = `lot-${number}`;
export const generateIsLotSelectedExportFieldName = (lotId: number): IsLotSelectedFieldName => `lot-${lotId}`;

export const isLotSelectedFieldName = (fieldName: string): fieldName is IsLotSelectedFieldName =>
  fieldName.startsWith('lot-');

export const getLotIdFromIsJobSelectedFieldName = (fieldName: IsLotSelectedFieldName) =>
  parseInt(fieldName.split('-')[1], 10);

export type LotCheckboxPartialValue = 'partial';

type JobImagesExportFieldName = `images-job-${number}`;
export const generateImagesExportFieldName = (jobId: number): JobImagesExportFieldName => `images-job-${jobId}`;

type IsNoteSelectedExportFieldName<Type extends 'job' | 'lot'> = `note-${Type}-${number}`;
export const generateIsNoteSelectedExportFieldName = <Type extends 'job' | 'lot'>(
  itemId: number,
  itemType: Type,
): IsNoteSelectedExportFieldName<Type> => `note-${itemType}-${itemId}`;

export interface QuoteExportForm {
  note?: string | null;

  exportItems?: Record<ValueOf<typeof EXPORT_COLUMNS>, boolean | undefined>;

  fileType: EXPORT_FILE_TYPES;

  hasReversalOfLiability: boolean;

  [isJobSelectedFieldName: IsJobSelectedFieldName]: boolean;

  [isLotSelectedFieldName: IsLotSelectedFieldName]: boolean | LotCheckboxPartialValue;

  [jobImagesExportFieldName: JobImagesExportFieldName]: boolean;

  [isJobNoteSelected: IsNoteSelectedExportFieldName<'job'>]: boolean;

  [isLotNoteSelected: IsNoteSelectedExportFieldName<'lot'>]: boolean;
}

export type QuoteExportEditionWizard = {
  export: QuoteExportForm;
};
