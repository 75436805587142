import { useEffect, useState } from 'react';

import type { QuoteLotData } from '../types/QuoteLotData';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useLot = (lotId: number) => {
  const { listenToLot } = useQuoteEditContext();
  const [lot, setLot] = useState<null | QuoteLotData>(null);

  useEffect(() => listenToLot(lotId, setLot), [listenToLot, lotId]);

  return lot;
};
