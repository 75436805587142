import { ContentTypeGuard, MARGIN_TYPE, QuoteQuantityFormula } from '@org/quotation-lib';
import Big from 'big.js';
import isEqual from 'lodash-es/isEqual';

import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';
import type { QuoteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';

export const createQuoteHiddenCostItemUpdatedCallback = (message: QuoteWsMessage) => {
  const quote = quoteGetFromStore();

  if (!quote) {
    return;
  }

  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();
  const hasClientRequest = quoteClientRequestsFromStore.find(
    (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
  );

  if (hasClientRequest) {
    quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
    return;
  }

  const node = quote.getTree().getNodeOrThrow(message.data.id);
  const quoteHiddenCostItem = node.getContent();

  if (
    !ContentTypeGuard.isQuoteHiddenCostItem(quoteHiddenCostItem) ||
    message.data.content.type !== 'QuoteHiddenCostItem'
  ) {
    return;
  }

  if (
    node.getParentOrThrow().getId() !== message.data.parentId ||
    (node.getPrevSibling()?.getId() || null) !== message.data.prevSiblingId ||
    (node.getNextSibling()?.getId() || null) !== message.data.nextSiblingId
  ) {
    if (!message.data.parentId) {
      throw new Error('Cannot move root node');
    }
    node.move(message.data.parentId, message.data.prevSiblingId, message.data.nextSiblingId);
  }

  if (
    message.data.content.quantityFormula &&
    !isEqual(quoteHiddenCostItem.getQuantityFormula()?.export(), message.data.content.quantityFormula)
  ) {
    const quantityFormula = new QuoteQuantityFormula(message.data.content.quantityFormula);
    quoteHiddenCostItem.updateQuantityFormula(quantityFormula, {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });
  }

  if (
    message.data.content.unitFlatCostAmount &&
    quoteHiddenCostItem.getUnitFlatCostAmount()?.toString() !== message.data.content.unitFlatCostAmount
  ) {
    quoteHiddenCostItem.updateUnitFlatCostAmount(new Big(message.data.content.unitFlatCostAmount), {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });
  }

  if (message.data.content.vatRate && quoteHiddenCostItem.getVatRate()?.toString() !== message.data.content.vatRate) {
    quoteHiddenCostItem.updateVatRate(new Big(message.data.content.vatRate));
  }

  quoteHiddenCostItem.updateProperties(message.data.content);
  quoteSetToStore(quote);
};
