import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICompanyMarginsResponseDTO, IUpdateTotalMarginDTO } from '@graneet/business-logic';
import { groupExistingComponentsByType } from '@graneet/business-logic';

import * as sellSheetApi from '../services/quote-lot.api';

export const usePreviewLotMarginSellSheet = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);

  return useCallback(
    async (lotId: number, totalMargin: IUpdateTotalMarginDTO, companyComponentTypes: ICompanyMarginsResponseDTO) => {
      if (!totalMargin) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        return true;
      }

      const [err, result] = await sellSheetApi.previewLotMarginSellSheet(lotId, totalMargin);

      if (err) {
        toast.error(t('quote:errors.updatingError'));
        return true;
      }

      const { components } = result;

      return groupExistingComponentsByType(Object.values(components), companyComponentTypes);
    },
    [t, toast],
  );
};
