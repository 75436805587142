import type { FC, ReactNode } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

import { Card } from '../Card/Card';

import type { OptionProps } from './Option';
import { Option } from './Option';

export interface CardOptionProps extends OptionProps {
  children?: ReactNode;
}

export const CardOption: FC<CardOptionProps> = ({ children = null, label, ...rest }) => (
  <Option {...rest} w="100%">
    {(radioButton: ReactNode) => (
      <Card w="100%">
        <HStack align="flex-start" spacing={1} mt={1}>
          <Box w={6} mt={1}>
            {radioButton}
          </Box>
          <Box flexGrow={1}>
            <Text fontSize="lg" color="gray.800" fontFamily="heading" mb={1}>
              {label}
            </Text>
            <Box w="100%">{children}</Box>
          </Box>
        </HStack>
      </Card>
    )}
  </Option>
);
