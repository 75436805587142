import type { IComponentTypesListingDTO, IVat, QUOTE_ROW_HEIGHT } from '@graneet/business-logic';
import type { DrawerHandle, ImageListAndUploadHandle, RichTextGetterHandler } from '@graneet/lib-ui';
import type {
  QuoteComposeDTOClient,
  QuoteComposeDTOProject,
  QuoteContactDTO,
  QuoteSettingDTO,
} from '@org/graneet-bff-client';
import type {
  QuoteFlatObject,
  QuoteTreeFlatObject,
  QuoteNodeObject,
  QuoteSalesDiscountObject,
  QuoteCustomDiscountObject,
} from '@org/quotation-lib';
import type { AgGridReact } from '@ag-grid-community/react';
import type { RefObject } from 'react';

import type { QuoteSetting } from './quoteSettingSliceZustant';

import { useStore } from 'store/store';

export function updateQuote(quote: QuoteFlatObject) {
  useStore.setState(() => ({
    quote,
  }));
}

export function updateQuoteTree(quoteTree: QuoteTreeFlatObject) {
  useStore.setState(() => ({
    quoteTree,
  }));
}

export function updateQuoteNode(quoteNode: QuoteNodeObject[]) {
  useStore.setState(() => ({
    quoteNode,
  }));
}

export function updateQuoteNodeHiddenCost(quoteNodeHiddenCost: QuoteNodeObject[]) {
  useStore.setState(() => ({
    quoteNodeHiddenCost,
  }));
}

export function updateQuoteNbSelectedRows(nb: number) {
  useStore.setState(() => ({
    quoteNbSelectedRows: nb,
  }));
}

export function updateQuoteSelectedRows(rows: Map<string, QuoteNodeObject>) {
  useStore.setState(() => ({
    quoteSelectedRows: rows,
  }));
}

export function updateQuoteHiddenCostNbSelectedRows(nb: number) {
  useStore.setState(() => ({
    quoteHiddenCostNbSelectedRows: nb,
  }));
}

export function updateQuoteHiddenCostSelectedRows(rows: Map<string, QuoteNodeObject>) {
  useStore.setState(() => ({
    quoteHiddenCostSelectedRows: rows,
  }));
}

export function updateQuoteGridRef(ref: RefObject<AgGridReact<QuoteNodeObject>>) {
  useStore.setState(() => ({
    quoteGridRef: ref,
  }));
}

export function updateQuoteHiddenCostGridRef(ref: RefObject<AgGridReact<QuoteNodeObject>>) {
  useStore.setState(() => ({
    quoteHiddenCostGridRef: ref,
  }));
}

export function updateQuoteSalesDiscount(quoteSalesDiscount: QuoteSalesDiscountObject | null) {
  useStore.setState(() => ({
    quoteSalesDiscount,
  }));
}
export function updateQuoteCustomDiscount(quoteCustomDiscounts: QuoteCustomDiscountObject[]) {
  useStore.setState(() => ({
    quoteCustomDiscounts,
  }));
}

export function updateQuoteComponentTypes(components: IComponentTypesListingDTO[]) {
  useStore.setState(() => ({
    quoteComponentTypes: components,
  }));
}

export function updateQuoteImageUploadRef(quoteImageUploadRefs: Map<string, RefObject<ImageListAndUploadHandle>>) {
  useStore.setState(() => ({
    quoteImageUploadRefs,
  }));
}

export function updateQuoteExpandedRows(quoteExpandedRows: string[]) {
  useStore.setState(() => ({
    quoteExpandedRows,
  }));
}

export const updateQuoteAreAllRowsCollapsed = (quoteAreAllRowsCollapsed: boolean) => {
  useStore.setState(() => ({
    quoteAreAllRowsCollapsed,
  }));
};

export function appendQuoteExpandedRow(quoteExpandedRow: string) {
  useStore.setState((state) => ({
    quoteExpandedRows: [...state.quoteExpandedRows, quoteExpandedRow],
  }));
}

export function removeQuoteExpandedRow(quoteExpandedRow: string) {
  useStore.setState((state) => ({
    quoteExpandedRows: state.quoteExpandedRows.filter((row) => row !== quoteExpandedRow),
  }));
}

export function updateQuoteCollapsedRows(quoteCollapsedRows: string[]) {
  useStore.setState(() => ({
    quoteCollapsedRows,
  }));
}

export function appendQuoteCollapsedRow(quoteCollapsedRow: string) {
  useStore.setState((state) => ({
    quoteCollapsedRows: [...state.quoteCollapsedRows, quoteCollapsedRow],
  }));
}

export function removeQuoteCollapsedRow(quoteCollapsedRow: string) {
  useStore.setState((state) => ({
    quoteCollapsedRows: state.quoteCollapsedRows.filter((row) => row !== quoteCollapsedRow),
  }));
}

export function updateQuoteEditable(quoteEditable: boolean) {
  useStore.setState(() => ({
    quoteEditable,
  }));
}

export function updateQuoteProject(quoteProject: QuoteComposeDTOProject | null) {
  useStore.setState(() => ({
    quoteProject,
  }));
}

export function updateQuoteStandardVATRates(quoteStandardVATRates: IVat[]) {
  useStore.setState(() => ({
    quoteStandardVATRates,
  }));
}

export function updateQuoteClient(quoteClient: QuoteComposeDTOClient | null) {
  useStore.setState(() => ({
    quoteClient,
  }));
}

export function updateQuoteContacts(quoteContacts: QuoteContactDTO[] | null) {
  useStore.setState(() => ({
    quoteContacts,
  }));
}

export function updateQuoteRichTextEditorRef(quoteRichTextEditorRef: RefObject<RichTextGetterHandler> | null) {
  useStore.setState(() => ({
    quoteRichTextEditorRef,
  }));
}

export function updateQuoteDrawerRichTextEditorRef(quoteRichTextEditorRef: RefObject<RichTextGetterHandler> | null) {
  useStore.setState(() => ({
    quoteDrawerRichTextEditorRef: quoteRichTextEditorRef,
  }));
}

export function updateQuoteSummaryBarItemDisplayed(quoteSummaryBarItemDisplayedJSON: string) {
  useStore.setState(() => ({
    quoteSummaryBarItemDisplayed: JSON.parse(quoteSummaryBarItemDisplayedJSON),
  }));
}

export function updateQuoteRootColumns(quoteRootColumnsJSON: string) {
  useStore.setState(() => ({
    quoteRootColumns: JSON.parse(quoteRootColumnsJSON),
  }));
}

export function updateQuoteSetting(quoteSetting: QuoteSettingDTO | QuoteSetting) {
  useStore.setState(() => ({
    quoteSetting: {
      drawerSelected: quoteSetting.drawerSelected,
      isAllItemsExpanded: quoteSetting.isAllItemsExpanded,
      isComponentsExpanded: quoteSetting.isComponentsExpanded,
      isDrawerExpanded: quoteSetting.isDrawerExpanded,
      isHiddenCostTabAllItemsExpanded: quoteSetting.isHiddenCostTabAllItemsExpanded,
    },
  }));
}

export function updateQuoteHiddenCostColumns(quoteHiddenCostColumnsJSON: string) {
  useStore.setState(() => ({
    quoteHiddenCostColumns: JSON.parse(quoteHiddenCostColumnsJSON),
  }));
}

export function updateQuoteRowHeight(quoteRowHeight: QUOTE_ROW_HEIGHT) {
  useStore.setState(() => ({
    quoteRowHeight,
  }));
}

export function updateQuoteRootSelectedRows(selectedRows: any[]) {
  useStore.setState(() => ({
    selectedRows,
  }));
}

export function updateQuoteImportItemModalIsOpen(quoteImportItemDrawerIsOpen: boolean) {
  useStore.setState(() => ({
    quoteImportItemDrawerIsOpen,
  }));
}

export function updateQuoteImportItemTargetParentId(quoteImportItemTargetParentId: string | null) {
  useStore.setState(() => ({
    quoteImportItemTargetParentId,
  }));
}

export function updateQuoteImportComponentDrawerIsOpen(quoteImportComponentDrawerIsOpen: boolean) {
  useStore.setState(() => ({
    quoteImportComponentDrawerIsOpen,
  }));
}

export function updateQuoteImportComponentTargetParentId(quoteImportComponentTargetParentId: string | null) {
  useStore.setState(() => ({
    quoteImportComponentTargetParentId,
  }));
}

export const updateQuoteNewlyCreatedNodeId = (quoteNewlyCreatedNodeId: string | null) => {
  useStore.setState(() => ({
    quoteNewlyCreatedNodeId,
  }));
};

export const updateQuoteMembers = (quoteMembers: string[]) => {
  useStore.setState(() => ({
    quoteMembers,
  }));
};

export const updateQuoteNotesModal = (quoteNotesModal: {
  open: boolean;
  note: string | null;
  nodeId: string | null;
}) => {
  useStore.setState(() => ({
    quoteNotesModal,
  }));
};

export const updateQuoteLotDefaultVatRateModal = (quoteLotDefaultVatRateModal: {
  isOpen: boolean;
  nodeId: string | null;
}) => {
  useStore.setState(() => ({
    quoteLotDefaultVatRateModal,
  }));
};

export function updateQuoteBatiprixModalIsOpen(quoteBatiprixModalIsOpen: boolean) {
  useStore.setState(() => ({
    quoteBatiprixModalIsOpen,
  }));
}

export function updateQuoteBatiprixModalParentId(quoteBatiprixModalParentId: string | null) {
  useStore.setState(() => ({
    quoteBatiprixModalParentId,
  }));
}

export function updateQuoteDrawerRef(quoteDrawerRef: RefObject<DrawerHandle> | null) {
  useStore.setState(() => ({
    quoteDrawerRef,
  }));
}

export function updateQuoteAmountIsUnreachable(quoteAmountIsUnreachable: boolean) {
  useStore.setState(() => ({
    quoteAmountIsUnreachable,
  }));
}
