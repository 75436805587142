import type { IQuoteJob, IQuoteLot } from '@graneet/business-logic';
import type { FormulaFieldValue } from '@graneet/lib-ui';

type QuoteLotKeys = keyof Pick<IQuoteLot, 'code' | 'note' | 'description'>;

export type QuoteLotFormName<T extends QuoteLotKeys> = `lot-${number}-${T}`;

export const generateLotFieldName =
  (lotId: number) =>
  <T extends QuoteLotKeys>(key: T): QuoteLotFormName<T> =>
    `lot-${lotId}-${key}`;

export const isFieldNameOfLot = (name: keyof QuoteEditLotStepForm): name is QuoteLotFormName<QuoteLotKeys> =>
  name.split('-')[0] === 'lot';

type QuoteJobKeys =
  | keyof Pick<
      IQuoteJob,
      'code' | 'note' | 'description' | 'unit' | 'quantity' | 'vatRate' | 'unitPriceExVAT' | 'unitDisbursementExVAT'
    >
  | 'totalMargin';

export type QuoteJobFormName<T extends QuoteJobKeys> = `job-${number}-${T}`;

export const generateJobFieldName =
  (jobId: number) =>
  <T extends QuoteJobKeys>(key: T): QuoteJobFormName<T> =>
    `job-${jobId}-${key}`;

export const isFieldNameOfJob = (name: keyof QuoteEditLotStepForm): name is QuoteJobFormName<QuoteJobKeys> =>
  name.split('-')[0] === 'job';

export interface QuoteEditLotStepForm {
  // Quote Lot
  [quoteLotCode: QuoteLotFormName<'code'>]: string | null | undefined;

  [quoteLotNote: QuoteLotFormName<'note'>]: string | null | undefined;

  [quoteLotDescription: QuoteLotFormName<'description'>]: string | null | undefined;

  // Quote Job
  [quoteJobCode: QuoteJobFormName<'code'>]: string | null | undefined;

  [quoteJobNote: QuoteJobFormName<'note'>]: string | null | undefined;

  [quoteJobDescription: QuoteJobFormName<'description'>]: string | null | undefined;

  [quoteJobUnit: QuoteJobFormName<'unit'>]: string | null | undefined;

  [quoteJobQuantity: QuoteJobFormName<'quantity'>]: number | null | undefined | FormulaFieldValue;

  [quoteJobVatRate: QuoteJobFormName<'vatRate'>]: number | null | undefined;

  [quoteJobUnitPriceExVAT: QuoteJobFormName<'unitPriceExVAT'>]: number | null | undefined;

  [quoteJobUnitDisbursementExVAT: QuoteJobFormName<'unitDisbursementExVAT'>]: number | null | undefined;

  [quoteJobTotalMargin: QuoteJobFormName<'totalMargin'>]: number | null | undefined;
}

export interface QuoteEditSellSheetForm {
  disbursementExVAT: number;
  totalMargin: number;
  amountExVAT: number;
}
