import type { FC } from 'react';

import type { ContractId, LotId } from '../../services/progress-statement-tree.util';
import { isContractId } from '../../services/progress-statement-tree.util';

import { ProgressStatementDeepTableContractLine } from './ProgressStatementDeepTableContractLine';
import { ProgressStatementDeepTableLotLine } from './ProgressStatementDeepTableLotLine';

interface ProgressStatementDeepTableContractOrLotLineProps {
  id: LotId | ContractId;

  depth: number;

  isUpdatable?: boolean;
}

export const ProgressStatementDeepTableContractOrLotLine: FC<ProgressStatementDeepTableContractOrLotLineProps> = ({
  id,
  depth,
  isUpdatable = true,
}) => {
  if (isContractId(id)) {
    return <ProgressStatementDeepTableContractLine id={id} isUpdatable={isUpdatable} />;
  }

  return <ProgressStatementDeepTableLotLine id={id} depth={depth} isUpdatable={isUpdatable} />;
};
