import type { FC } from 'react';
import { Grid } from '@chakra-ui/react';
import { CheckboxField } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';

import { AddressFields } from 'features/common/components/fields/AddressFields';
import type { AddressForm } from 'features/common/components/fields/AddressFields';

export const BILLING_ADDRESS_PREFIX = 'billingAddress';

export interface AddressesWithBillingFieldsForm extends AddressForm {
  billingAddressAddress: string;

  billingAddressPostalCode?: string;

  billingAddressCity?: string;

  billingAddressCountry?: string;

  hasNoBillingAddress?: boolean;
}

interface ClientAdressWithBillingFieldsProps {
  isReadOnly?: boolean;
}

/**
 * Internally use AddressesWithBillingFieldsForm form
 */
export const ClientAddressWithBillingFields: FC<ClientAdressWithBillingFieldsProps> = ({ isReadOnly }) => {
  const { t } = useTranslation(['global']);

  const form = useFormContext<AddressesWithBillingFieldsForm>();
  const { hasNoBillingAddress: hasNoBillingAddressLive } = useOnChangeValues(form, ['hasNoBillingAddress']);

  return (
    <>
      <Grid gap={2}>
        <AddressFields isReadOnly={isReadOnly} twoLines customAdressFieldLabel={t('global:address.address')} />

        <CheckboxField<AddressesWithBillingFieldsForm>
          label={t('global:address.billingAddressDescription')}
          name="hasNoBillingAddress"
          isDisabled={isReadOnly}
        />
      </Grid>

      {!hasNoBillingAddressLive && (
        <Grid gap={2}>
          <AddressFields
            prefix={BILLING_ADDRESS_PREFIX}
            isReadOnly={isReadOnly}
            twoLines
            customAdressFieldLabel={t('global:address.billingAddress')}
          />
        </Grid>
      )}
    </>
  );
};
