import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';

interface InvoiceConfirmExitCreationModalProps {
  isOpen: boolean;

  onClose(): void;

  onConfirmed(): void;
}

export const InvoiceConfirmExitCreationModal: FC<InvoiceConfirmExitCreationModalProps> = ({
  isOpen,
  onClose,
  onConfirmed,
}) => {
  const { t } = useTranslation(['invoice', 'global']);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('invoice:modals.quit.title')}>
      {t('invoice:modals.quit.description')}

      <Modal.SecondaryButton onClick={onClose}>{t('global:words.c.cancel')}</Modal.SecondaryButton>

      <Modal.PrimaryButton onClick={onConfirmed}>{t('global:words.c.confirm')}</Modal.PrimaryButton>
    </Modal>
  );
};
