import type { FC } from 'react';
import { Box, CircularProgress } from '@chakra-ui/react';

import { configureDefaultLabel, savingSpinnerTranslations } from './configureDefaultLabel';

export interface SavingSpinnerProps {
  isSaving: boolean;
}

const SavingSpinnerComponent: FC<SavingSpinnerProps> = ({ isSaving }) => {
  if (!isSaving) return null;

  return (
    <Box flex={1} textAlign="right" fontWeight={0} whiteSpace="nowrap">
      <CircularProgress mr={3} size={6} isIndeterminate color="greenBrand.light" />
      {savingSpinnerTranslations.saving}
    </Box>
  );
};

export const SavingSpinner = Object.assign(SavingSpinnerComponent, {
  configureDefaultLabel,
});
