import type { FC } from 'react';
import { useCallback } from 'react';
import {
  Card,
  Button,
  TextField,
  DateField,
  Section,
  IconAdvanced,
  SimpleHelpIcon,
  SimpleAddIcon,
  SimpleDeleteIcon,
  sleep,
} from '@graneet/lib-ui';
import { Flex, useDisclosure, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { IInvoice, IProject, IProjectWithRelations } from '@graneet/business-logic';
import { PERMISSION, STATEMENT_TYPES } from '@graneet/business-logic';

import type { InvoiceEditionInformationForm } from '../forms/invoice-edition-information-form';

import { RuleIsInvoiceNumberAvailable } from 'features/statement/rules/RuleIsInvoiceNumberAvailable';
import { InvoiceNumberField } from 'features/statement-ledger/components/InvoiceNumberField';
import { AssociateProjectModal } from 'features/project/components/modals/AssociateProjectModal';
import { ProjectWithPaymentSummaryCard } from 'features/project/components/cards/ProjectWithPaymentSummaryCard';
import { Rule } from 'features/form/rules/Rule';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useHasLedger } from 'features/statement-ledger/hooks/useHasLedger';

interface InvoiceFieldsSectionProps {
  onProjectLinkChanged(newProject: IProject | null): void;

  project: IProjectWithRelations | null;

  invoice: IInvoice | null;
}

export const InvoiceFieldsSection: FC<InvoiceFieldsSectionProps> = ({ project, invoice, onProjectLinkChanged }) => {
  const { t } = useTranslation(['invoice', 'statement']);

  const hasCreateStatementsPermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const hasLedger = useHasLedger();

  const { id: invoiceId, invoiceNumber } = invoice || {};

  const handleUnlink = useCallback(() => {
    onProjectLinkChanged(null);
  }, [onProjectLinkChanged]);

  const handleLink = useCallback(
    async (newProject: IProject | null) => {
      onClose();
      await sleep(400); // Chakra bug: without it, the modal stay open
      onProjectLinkChanged(newProject);
    },
    [onClose, onProjectLinkChanged],
  );

  return (
    <Section
      title={t('invoice:steps.information.title')}
      description={
        hasLedger ? t('invoice:steps.information.descriptionWithLedger') : t('invoice:steps.information.description')
      }
    >
      <Card w="80%">
        <Flex justifyContent="space-between">
          <TextField<InvoiceEditionInformationForm>
            data-testid="textField:invoice.create"
            name="name"
            label={t('invoice:fields.name')}
            rightIcon={<IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('invoice:tooltips.name')} />}
            width="30%"
            isRequired
          >
            <Rule.IsRequired />
          </TextField>

          <TextField<InvoiceEditionInformationForm>
            name="purchaseOrderNumber"
            label={t('invoice:fields.purchaseOrderNumber')}
            width="20%"
          />

          <Flex width="auto">
            <DateField<InvoiceEditionInformationForm>
              name="billingDate"
              label={t('invoice:fields.billingDate')}
              isRequired
            >
              <Rule.IsRequired />
            </DateField>
          </Flex>

          <Flex w="20%">
            <InvoiceNumberField<InvoiceEditionInformationForm>
              label={t('invoice:fields.invoiceNumber')}
              name="invoiceNumber"
              type={STATEMENT_TYPES.INVOICE}
              invoiceNumber={invoiceNumber}
            >
              <RuleIsInvoiceNumberAvailable statementId={invoiceId} type={STATEMENT_TYPES.INVOICE} />
            </InvoiceNumberField>
          </Flex>
        </Flex>
      </Card>

      {project && <ProjectWithPaymentSummaryCard project={project} w="80%" mt={4} />}

      {hasCreateStatementsPermission && (
        <>
          <Box mt={2}>
            {!project ? (
              <Button leftIcon={<SimpleAddIcon />} variant="outline" onClick={onOpen}>
                {t('statement:actions.associateProject')}
              </Button>
            ) : (
              <Button leftIcon={<SimpleDeleteIcon />} variant="outline" onClick={handleUnlink}>
                {t('statement:actions.deleteProjectAssociation')}
              </Button>
            )}
          </Box>

          <AssociateProjectModal
            title={t('statement:associateProject.title')}
            description={t('statement:associateProject.description')}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleLink}
          />
        </>
      )}
    </Section>
  );
};
