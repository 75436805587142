import type { IOrderResponseDTO } from '@graneet/business-logic';
import { List, SimpleCheckCircleIcon, SimpleDeleteIcon } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

type OrderCheckListDeletionProps = {
  order: IOrderResponseDTO;
};

export const OrderCheckListDeletion: FC<OrderCheckListDeletionProps> = ({ order }) => {
  const { t } = useTranslation(['orders']);

  return (
    <>
      <List pl={4}>
        {!!order.orderFiles?.length && (
          <List.Item color="gray.600" icon={<SimpleDeleteIcon boxSize={4} />} label={t('orders:delete.deleteFiles')} />
        )}

        <List.Item
          color="gray.600"
          icon={<SimpleDeleteIcon boxSize={4} />}
          label={t('orders:delete.deleteSupplierInvoice')}
        />

        <List.Item
          color="gray.600"
          icon={<SimpleCheckCircleIcon boxSize={4} />}
          label={t('orders:delete.updateProgressStatementPDF')}
        />
      </List>
    </>
  );
};
