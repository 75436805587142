import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { getEnvValue } from 'config/env';
import { bff } from 'features/api/services/bff.service';

const helpscout = createQueryKeys('helpscout', {
  searchArticles: (collectionId: string, categoryId: string, search: string) => ({
    queryKey: ['articles', categoryId, search, collectionId],
    queryFn: () =>
      bff.get<
        {
          categoryId: string;
          query: string;
        },
        any[]
      >(`helpscout/collections/${collectionId}/search`, {
        categoryId,
        query: search,
      }),
  }),
});

export function useHelpScoutGetArticles(categoryId: string, search: string) {
  return useSuspenseQuery(helpscout.searchArticles(getEnvValue('REACT_APP_HELPSCOUT_ID', true), categoryId, search));
}

export function useLazyHelScoutArticles() {
  const queryClient = useQueryClient();

  return useCallback(
    (search: string) =>
      queryClient.fetchQuery(helpscout.searchArticles(getEnvValue('REACT_APP_HELPSCOUT_ID', true), '', search)),
    [queryClient],
  );
}
