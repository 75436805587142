import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { usePlaceholderContext } from './PlaceholderContext';

export interface PlaceholderPlaceholderProps {
  name: string;
}

export const PlaceholderValue: FC<PlaceholderPlaceholderProps> = ({ name }) => {
  const { initializeSetter, removeSetter } = usePlaceholderContext();
  const [placeholder, setPlaceholder] = useState<ReactNode>(null);

  useEffect(() => {
    initializeSetter(name, setPlaceholder);
    return () => removeSetter(name);
  }, [initializeSetter, name, removeSetter]);

  return <>{placeholder}</>;
};
