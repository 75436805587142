import { useCallback, useMemo, useState } from 'react';

import type { PaginatedStorage } from './PaginatedStorage';

export const useStatePaginationStorage = (defaultFilters: Record<any, any> = {}): PaginatedStorage => {
  const [query, setQuery] = useState(new URLSearchParams(Object.entries(defaultFilters)));

  const resetFilters = useCallback(() => {
    setQuery(new URLSearchParams());
  }, []);

  const [render, setRender] = useState(0);
  const updateFilters = useCallback((urlSearchParams: URLSearchParams) => {
    setQuery(urlSearchParams);
    setRender((c) => c + 1);
  }, []);

  return useMemo(
    () => ({
      storage: query,
      updateFilters,
      resetFilters,
      render,
    }),
    [query, resetFilters, updateFilters, render],
  );
};
