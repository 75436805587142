import type { ReactNode } from 'react';
import { createContext } from 'react';
import type {
  IDiscountCreationDTO,
  IQuote,
  IQuoteComponent,
  IQuoteInfosResponse,
  IQuoteJob,
  IQuoteLot,
  IQuoteUpdateResponse,
} from '@graneet/business-logic';
import type { FormContextApi } from 'graneet-form';

import type { QuoteEditLotStepForm } from '../forms/quote-edit-lot-step.form';
import type { QuoteLotData } from '../../quote-lot/types/QuoteLotData';
import type { QuoteData } from '../types/QuoteData';

export interface IQuoteEditContext {
  startAnotherUpdate(): void;

  displayNeedsReload(message: ReactNode): void;

  getQuoteId(): number | null;

  setQuoteData(quoteInfosResponse: IQuoteInfosResponse): void;

  getCurrentQuoteData(): QuoteData;

  getQuoteAsFormValues(): QuoteEditLotStepForm;

  emitQuoteUpdate(updatedQuote: IQuote): void;

  listenToQuote(listener: (quote: IQuote) => void): () => void;

  updateDataLocally(quoteInfos: Omit<IQuoteUpdateResponse, 'rootLotId'>): void;

  getRootLotId(): number | undefined;

  hasRootLotId(): boolean;

  editQuoteDiscount(data: IDiscountCreationDTO): Promise<void>;

  deleteQuoteDiscount(): Promise<void>;

  onQuoteSavingChange(handler: (newValue: boolean) => void): void;

  setQuoteForm(form: FormContextApi<QuoteEditLotStepForm>): void;

  useRootLotId(): number | undefined;

  listenToLot(id: number, listener: (newValue: QuoteLotData) => void): () => void;

  emitLotUpdate(id: number): void;

  listenToMaxLotDepth(listener: (newValue: number) => void): void;

  emitMaxLotDepthUpdate(): void;

  findLotIdFromJobIdInRelations(quoteJobId: number): number | null;

  findOwnerLotIdFromLotIdInRelations(quoteLotId: number): number | null;

  findLastLotIdInLot(quoteLotId: number): number | null;

  findRootLotLastSublotId(): number | null;

  findNumberOfIncompleteJobsInLot(quoteLotId: number): number;

  isLotBeforeOtherLot(quoteLotId: number, nextQuoteLotId: number): boolean;

  isLotDescendantOfOtherLot(quoteLotId: number, otherLotId: number): boolean;

  hasLotSubLots(quoteLotId: number): boolean;

  hasLotJobs(quoteLotId: number): boolean;

  deleteLotAndItsSubLotsLocally(quoteLotId: number): void;

  insertLotsAfterOtherLotLocally(
    responseLots: Record<number, IQuoteLot>,
    quoteLotId: number,
    previousQuoteLotId?: number,
  ): void;

  moveLotBeforeOtherLotLocally(
    quoteLotId: number,
    nextQuoteLotId: number,
  ): { parentLotId: number; previousLotId: number | null };

  moveLotAfterOtherLotLocally(
    quoteLotId: number,
    previousQuoteLotId?: number,
    newParentQuoteLotId?: number,
  ): { parentLotId: number; previousLotId: number | null };

  setLotIsExpanded(quoteLotId: number, isExpanded: boolean): void;

  forEachDescendingLot(quoteLotId: number, callback: (quoteLot: IQuoteLot) => void): void;

  getJob(quoteJobId: number): IQuoteJob;

  showJobMoveToast(options: { isError: boolean }): void;

  insertJobsAfterJobInLotInRelations(
    responseJobs: Record<number, IQuoteJob>,
    lotId: number,
    previousJobId?: number,
  ): void;

  isJobAfterOtherJob(sourceQuoteJobId: number, otherQuoteJobId: number): boolean;

  isJobBeforeOtherJob(sourceQuoteJobId: number, otherQuoteJobId: number): boolean;

  emitJobUpdate(quoteJobId: number): void;

  listenToJob(quoteJobId: number, listener: (newValue: IQuoteJob) => void): () => void;

  moveJobBeforeOtherJobLocally(
    sourceQuoteJobId: number,
    targetNextQuoteJobId: number,
  ): {
    previousJobId: number | null;
    parentLotId: number;
  } | null;

  moveJobAfterOtherJobLocally(
    sourceQuoteJobId: number,
    targetPreviousQuoteJobId: number,
  ): {
    previousJobId: number;
    parentLotId: number;
  } | null;

  moveJobInsideLotLocally(
    sourceQuoteJobId: number,
    targetQuoteLotId: number,
    isParentHiddenCost?: boolean,
  ): {
    parentLotId: number;
    previousJobId: number | null;
  } | null;

  deleteComponentLocally(quoteComponentIdToDelete: number): void;

  listenToComponents(listener: (newValue: Record<number, IQuoteComponent>) => void): void;
}

export const QuoteEditContext = createContext<IQuoteEditContext>({
  // Global
  startAnotherUpdate: () => {
    throw new Error('startAnotherUpdate');
  },
  displayNeedsReload: () => {
    throw new Error('displayNeedsReload');
  },

  // Quote
  getQuoteId: () => {
    throw new Error('getQuoteId');
  },
  setQuoteData: () => {
    throw new Error('setQuoteData');
  },
  getCurrentQuoteData: () => {
    throw new Error('getCurrentQuoteData');
  },
  getQuoteAsFormValues: () => {
    throw new Error('getQuoteAsFormValues');
  },
  emitQuoteUpdate: () => {
    throw new Error('emitQuoteUpdate');
  },
  listenToQuote: () => {
    throw new Error('listenToQuote');
  },

  updateDataLocally: () => {
    throw new Error('updateDataLocally');
  },
  getRootLotId: () => {
    throw new Error('getRootLotId');
  },
  hasRootLotId: () => {
    throw new Error('hasRootLotId');
  },
  editQuoteDiscount: () => {
    throw new Error('editQuoteDiscount');
  },
  deleteQuoteDiscount: () => {
    throw new Error('deleteQuoteDiscount');
  },
  onQuoteSavingChange: () => {
    throw new Error('onQuoteSavingChange');
  },
  setQuoteForm: () => {
    throw new Error('setQuoteForm');
  },
  useRootLotId: () => {
    throw new Error('useRootLotId');
  },

  // Lot
  listenToLot: () => {
    throw new Error('listenToLot');
  },
  emitLotUpdate: () => {
    throw new Error('emitLotUpdate');
  },
  listenToMaxLotDepth: () => {
    throw new Error('listenToMaxLotDepth');
  },
  emitMaxLotDepthUpdate: () => {
    throw new Error('emitMaxLotDepthUpdate');
  },
  findLotIdFromJobIdInRelations: () => {
    throw new Error('findLotIdFromJobIdInRelations');
  },
  findOwnerLotIdFromLotIdInRelations: () => {
    throw new Error('findOwnerLotIdFromLotIdInRelations');
  },
  findLastLotIdInLot: () => {
    throw new Error('findLastLotIdInLot');
  },
  findRootLotLastSublotId: () => {
    throw new Error('findRootLotLastSublotId');
  },
  findNumberOfIncompleteJobsInLot: () => {
    throw new Error('findNumberOfIncompleteJobsInLot');
  },
  isLotBeforeOtherLot: () => {
    throw new Error('isLotBeforeOtherLot');
  },
  isLotDescendantOfOtherLot: () => {
    throw new Error('isLotDescendantOfOtherLot');
  },
  hasLotSubLots: () => {
    throw new Error('hasLotSubLots');
  },
  hasLotJobs: () => {
    throw new Error('hasLotJobs');
  },
  deleteLotAndItsSubLotsLocally: () => {
    throw new Error('deleteLotAndItsSubLotsLocally');
  },
  insertLotsAfterOtherLotLocally: () => {
    throw new Error('insertLotsAfterOtherLotLocally');
  },
  moveLotBeforeOtherLotLocally: () => {
    throw new Error('moveLotBeforeOtherLotLocally');
  },
  moveLotAfterOtherLotLocally: () => {
    throw new Error('moveLotAfterOtherLotLocally');
  },
  setLotIsExpanded: () => {
    throw new Error('setLotIsExpanded');
  },
  forEachDescendingLot: () => {
    throw new Error('forEachDescendingLot');
  },

  // Job
  getJob: () => {
    throw new Error('getJob');
  },
  showJobMoveToast: () => {
    throw new Error('showJobMoveToast');
  },
  insertJobsAfterJobInLotInRelations: () => {
    throw new Error('insertJobsAfterJobInLotInRelations');
  },
  isJobAfterOtherJob: () => {
    throw new Error('isJobAfterOtherJob');
  },
  isJobBeforeOtherJob: () => {
    throw new Error('isJobBeforeOtherJob');
  },

  // jobs
  emitJobUpdate: () => {
    throw new Error('emitJobUpdate');
  },
  listenToJob: () => {
    throw new Error('listenToJob');
  },
  moveJobBeforeOtherJobLocally: () => {
    throw new Error('moveJobBeforeOtherJobLocally');
  },
  moveJobAfterOtherJobLocally: () => {
    throw new Error('moveJobAfterOtherJobLocally');
  },
  moveJobInsideLotLocally: () => {
    throw new Error('moveJobInsideLotLocally');
  },

  // Component
  deleteComponentLocally: () => {
    throw new Error('deleteComponentLocally');
  },
  listenToComponents: () => {
    throw new Error('listenToComponents');
  },
});
