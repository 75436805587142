import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import * as jobsApi from '../services/quote-job.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Adds a job to the root lot
 */
export const useDeleteJob = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const {
    displayNeedsReload,
    findLotIdFromJobIdInRelations,
    updateDataLocally,
    getCurrentQuoteData,
    deleteComponentLocally,
  } = useQuoteEditContext();

  return useCallback(
    async (jobId: number) => {
      const { jobs, relations } = getCurrentQuoteData();

      const {
        [jobId]: { components },
      } = jobs;

      // Call api to delete the job
      const [err, quoteInfos] = await jobsApi.deleteJob(jobId);
      const errorMessage = t('quote:jobsStep.errors.deletingJobError');

      // Return if it failed and notify user
      if (err) {
        toast.error(errorMessage);
        return;
      }

      // Quit the job could not be found locally
      if (!jobs[jobId]) {
        displayNeedsReload(errorMessage);
        return;
      }
      delete jobs[jobId];

      // Quit the job's lot could not be found locally
      const ownerLotId = findLotIdFromJobIdInRelations(jobId)!;
      const ownerLotRelations = relations[ownerLotId];
      if (!ownerLotRelations) {
        displayNeedsReload(errorMessage);
        return;
      }

      // Quit if the job could not be found in the lots
      const jobsOfLot = ownerLotRelations.jobs;
      const jobPosition = jobsOfLot.indexOf(jobId);
      if (jobPosition < 0) {
        displayNeedsReload(errorMessage);
        return;
      }

      // Delete job from local relations
      ownerLotRelations.jobs.splice(jobPosition, 1);

      // Delete components owned by this job too
      components.forEach((component) => deleteComponentLocally(component.id));

      // Update interface
      updateDataLocally(quoteInfos);
    },
    [
      toast,
      t,
      findLotIdFromJobIdInRelations,
      updateDataLocally,
      displayNeedsReload,
      getCurrentQuoteData,
      deleteComponentLocally,
    ],
  );
};
