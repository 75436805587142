import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { Modal, useCurrency } from '@graneet/lib-ui';

import { LibraryJobFields } from '../LibraryJobFields';
import { useLibraryJobCreate } from '../../services/library-job.api';
import type { LibraryJobFieldsForm } from '../../types/library-job.type';

const FORM_ID = 'library_job_create';

type LibraryJobDeleteModalProps = {
  modal: UseDisclosureReturn;
  onLibraryJobAdded(): void;
};

export const LibraryJobAddModal: FC<LibraryJobDeleteModalProps> = ({ modal, onLibraryJobAdded }) => {
  const { t } = useTranslation(['global', 'library']);
  const { mapAmountToNumber } = useCurrency();
  const form = useForm<LibraryJobFieldsForm>();
  const { isValid: isFormValid } = useFormStatus(form);

  const libraryJobCreateMutation = useLibraryJobCreate();

  const { isOpen, onClose } = modal;

  const onSubmit = useCallback(
    async (formValues: LibraryJobFieldsForm) => {
      libraryJobCreateMutation.mutate(
        {
          refCode: formValues.refCode || null,
          description: formValues.description,
          unit: formValues.unit,
          unitDisbursementExVAT: mapAmountToNumber(formValues.unitDisbursementExVAT),
        },
        {
          onSuccess: () => {
            onClose();
            onLibraryJobAdded();
          },
        },
      );
    },
    [libraryJobCreateMutation, mapAmountToNumber, onClose, onLibraryJobAdded],
  );

  // Reset value when modal is closed
  useEffect(() => {
    form.resetForm();
  }, [isOpen, form]);

  return (
    <Modal title={t('library:addLibraryJobModal.title')} size="5xl" isOpen={isOpen} onClose={onClose}>
      <Form form={form} id={FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
        <Text mb={4}>{t('library:addLibraryJobModal.description')}</Text>
        <LibraryJobFields />
      </Form>

      <Modal.Close isDisabled={libraryJobCreateMutation.isPending} />

      <Modal.PrimaryButton
        isLoading={libraryJobCreateMutation.isPending}
        isDisabled={!isFormValid}
        type="submit"
        form={FORM_ID}
      >
        {t('library:addLibraryJobModal.cta')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
