import { useMemo } from 'react';
import { getPercentage, type IOrderWithDirectPaymentAmounts } from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { Flex } from '@chakra-ui/react';
import { CustomTooltipChart, LabeledData, Price, Progress, Tooltip } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import type { ProgressStatementDirectPaymentForm } from '../../progress-statement/forms/progress-statement-direct-payment-form';
import { useDirectPaymentContext } from '../contexts/DirectPaymentContext';

interface DirectPaymentItemProps {
  order: IOrderWithDirectPaymentAmounts;
}

export const DirectPaymentOrderPanel = ({ order }: DirectPaymentItemProps) => {
  const { t } = useTranslation(['project']);
  const form = useFormContext<ProgressStatementDirectPaymentForm>();
  const { ordersSupplierInvoices } = useOnChangeValues(form, ['ordersSupplierInvoices']);
  const { selectedOrder, supplierInvoices } = useDirectPaymentContext();

  const displayedSupplierInvoice = useMemo(() => {
    const supplierInvoiceId = ordersSupplierInvoices?.find(
      ({ orderId }) => orderId === selectedOrder?.id,
    )?.supplierInvoiceId;

    return supplierInvoiceId ? supplierInvoices[supplierInvoiceId] : undefined;
  }, [ordersSupplierInvoices, selectedOrder?.id, supplierInvoices]);

  const consumedAmount = order.amountPreviousDirectPaymentsExVAT + (displayedSupplierInvoice?.amountExVAT || 0);
  const consumedPercentage = getPercentage(consumedAmount, order.amountExVAT || 0);

  return (
    <Flex direction="row" justifyContent="space-between" w="100%" alignItems="start">
      <LabeledData label={t('project:directPayments.orders.amount')} data={<Price amount={order.amountExVAT} />} />
      <LabeledData
        label={t('project:directPayments.orders.consumed')}
        data={
          <Tooltip
            bg="none"
            shadow="none"
            label={
              <CustomTooltipChart
                legends={{
                  consumed: t('project:directPayments.orders.consumed'),
                  rest: t('project:directPayments.orders.rest'),
                }}
                active
                payload={[
                  { name: 'consumed', value: consumedAmount, color: 'green.100' },
                  { name: 'rest', value: (order.amountExVAT || 0) - consumedAmount, color: 'gray.100' },
                ]}
              />
            }
          >
            <Progress value={consumedPercentage} colors={{ progression: 'green.100', number: 'gray.700' }} w="150px" />
          </Tooltip>
        }
      />
    </Flex>
  );
};
