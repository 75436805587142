type ActionTranslations = 'update' | 'duplicate' | 'delete';

export const actionTranslations: Record<ActionTranslations, string> = {
  update: '',
  delete: '',
  duplicate: '',
};

export const configureDefaultLabels = (translations: Record<ActionTranslations, string>) => {
  Object.assign(actionTranslations, translations);
};
