import { HStack } from '@chakra-ui/react';
import type { MultiLevelMenuHandle } from '@graneet/lib-ui';
import { GraneetIconButton, MultiLevelMenu, SimpleDotsIcon } from '@graneet/lib-ui';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import type { MouseEvent } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { useContextMenu } from 'features/quotation/ag-grid-quote/hooks/context-menu/useContextMenu';
import { useStore } from 'store/store';

export const ActionCell = (params: CustomCellRendererProps<QuoteNodeObject>) => {
  const isEditable = useStore((store) => store.quoteEditable);
  const contextMenuRef = useRef<MultiLevelMenuHandle>(null);

  const contextMenuDef = useContextMenu();

  const { data } = params;

  const items = useMemo(() => {
    if (!data) {
      return [];
    }

    return contextMenuDef(() => {}, data);
  }, [data, contextMenuDef]);

  const handleMenuClick = useCallback((e?: MouseEvent) => {
    if (e) {
      contextMenuRef.current!.open(e?.clientX, e?.clientY);
    }
  }, []);

  return isEditable ? (
    <>
      <HStack width="100%" justifyContent="center" fontSize="0.813rem" pt="5px">
        <GraneetIconButton color="ghost" icon={<SimpleDotsIcon />} onClick={handleMenuClick} />
      </HStack>
      <MultiLevelMenu items={items} ref={contextMenuRef} id={uuid()} />
    </>
  ) : null;
};
