import { HStack, Text } from '@chakra-ui/react';
import {
  MaskEnum,
  QuotationInput,
  QuotationMaskedInput,
  Tooltip,
  useCurrency,
  useInputMaskInfos,
} from '@graneet/lib-ui';
import { QUOTE_STATUS, type QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import isNil from 'lodash-es/isNil';
import isEmpty from 'lodash-es/isEmpty';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Warning } from '../Warning';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const AmountCell = memo(
  (
    params: CustomCellRendererProps<QuoteNodeObject> & {
      mandatory?: boolean;
      couldBeCalculated?: boolean;
    },
  ) => {
    const { mask } = useInputMaskInfos(MaskEnum.CURRENCY, {});

    const { quote } = useQuote();

    const { mapStringNumberToAmount, formatAsStringAmount } = useCurrency();
    const { t } = useTranslation(['quote']);

    const { getValue, data, column, node } = params;

    const isEditable = params.column?.isCellEditable(node);

    const value: string | undefined = useMemo(() => {
      if (getValue) {
        return getValue();
      }
      return undefined;
    }, [getValue]);

    const emptyCb = useCallback(() => {}, []);

    const color = useMemo(() => {
      if (data?.content.type === 'QuoteSubItem' || data?.content.type === 'QuoteComponent') {
        return '#6C737F';
      }
      return '#1F2A37';
    }, [data]);

    if (
      data?.content.type === 'QuoteLot' &&
      ['unitFlatCostAmount', 'flatCostAmount', 'unitAmount'].includes(column?.getColId() ?? '')
    ) {
      return null;
    }

    if (
      !isEditable &&
      quote?.getStatus() === QUOTE_STATUS.DRAFT &&
      value &&
      value !== '-' &&
      params.couldBeCalculated
    ) {
      return (
        <HStack justifyContent="flex-end" py="8px">
          <HStack
            borderRadius="6px"
            border="1px solid"
            borderColor="greenBrand.borderDefault"
            background="#F2F2F0"
            color={color}
            fontSize="12px"
            width="auto"
            height="20px"
            px="4px"
          >
            <Tooltip label={t('quote:words.calculatedField')} placement="left">
              <Text>
                <i className="ri-box-2-line" />
              </Text>
            </Tooltip>
            <Text>{formatAsStringAmount(value)}</Text>
          </HStack>
        </HStack>
      );
    }

    if (
      data?.content.type !== 'QuoteLot' &&
      data?.content.type !== 'QuoteBasicItem' &&
      ['shareOfHiddenCostAmount'].includes(column?.getColId() ?? '')
    ) {
      return (
        <HStack width="100%" justifyContent="center" background="transparent" py="8px">
          <QuotationInput
            isReadOnly
            color={color}
            textAlign="right"
            value={value}
            onChange={emptyCb}
            onBlur={emptyCb}
            onKeyDownCapture={emptyCb}
            noStyle
          />
        </HStack>
      );
    }

    if (isEditable && params.mandatory && (isNil(value) || isEmpty(value))) {
      return <Warning align="flex-end" />;
    }

    return (
      <HStack width="100%" justifyContent="center" background="transparent" py="8px">
        <QuotationMaskedInput
          isReadOnly
          color={color}
          textAlign="right"
          value={mapStringNumberToAmount(value!)}
          onChange={emptyCb}
          onBlur={emptyCb}
          onKeyDownCapture={emptyCb}
          mask={mask}
          placeholder="-"
          noStyle
        />
      </HStack>
    );
  },
  (prevProps, nextProps) => {
    if (!prevProps && !nextProps) {
      return true;
    }
    if (!prevProps || !nextProps) {
      return false;
    }
    const oldValue = prevProps.getValue ? prevProps.getValue() : undefined;
    const newValue = nextProps.getValue ? nextProps.getValue() : undefined;
    return oldValue === newValue;
  },
);
