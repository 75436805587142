import type {
  IUserInvitationWithRelations,
  IUserInvitationValidationDTO,
  IUserInvitation,
  IUserInvitationDTO,
  IUserInvitationEmailAvailabilityResponseDTO,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { apiNew } from 'features/api/services/apiNew.service';

const USER_INVITATION_PATH = '/user-invitations';

const userInvitationFactoryKey = createQueryKeys('user-invitations', {
  get: () => ({
    queryKey: [USER_INVITATION_PATH],
    queryFn: () => apiNew.get<never, IUserInvitationWithRelations[]>(USER_INVITATION_PATH),
  }),
  getById: (id: string) => ({
    queryKey: [USER_INVITATION_PATH, id],
    queryFn: () => apiNew.get<never, IUserInvitation>(`${USER_INVITATION_PATH}/${id}`),
  }),
});

export function useUserInvitations() {
  return useSuspenseQuery(userInvitationFactoryKey.get());
}

export function useUserInvitation(id: string) {
  return useSuspenseQuery(userInvitationFactoryKey.getById(id));
}

export function useIsEmailAvailable() {
  return useMutation({
    mutationFn: (dto: { email: string }) =>
      apiNew.get<{ email: string }, IUserInvitationEmailAvailabilityResponseDTO>(
        `${USER_INVITATION_PATH}/email/availability`,
        dto,
      ),
  });
}

export function useCreateUserInvitation() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'onboarding']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IUserInvitationDTO) => apiNew.post<IUserInvitationDTO>(USER_INVITATION_PATH, dto),
    onSuccess: async () => {
      toast.success(t('onboarding:invitation.toast.invite.success'));
      await queryClient.invalidateQueries(userInvitationFactoryKey.get());
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('onboarding:invitation.toast.invite.error'));
    },
  });
}

export function useDeleteUserInvitation() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'onboarding']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => apiNew.delete(`${USER_INVITATION_PATH}/${id}`),
    onSuccess: async (_, id) => {
      toast.success(t('global:words.c.success'), t('onboarding:invitation.toast.delete.success'));
      await queryClient.invalidateQueries(userInvitationFactoryKey.get());
      await queryClient.invalidateQueries(userInvitationFactoryKey.getById(id));
    },
    onError: () => {
      toast.error(t('global:errors.error', t('onboarding:invitation.toast.delete.error')));
    },
  });
}

export function useResendUserInvitation() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'onboarding']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => apiNew.patch(`${USER_INVITATION_PATH}/${id}/resend`),
    onSuccess: async (_, id) => {
      toast.success(t('global:words.c.success'), t('onboarding:invitation.toast.resend.success'));
      await queryClient.invalidateQueries(userInvitationFactoryKey.get());
      await queryClient.invalidateQueries(userInvitationFactoryKey.getById(id));
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('onboarding:invitation.toast.resend.error'));
    },
  });
}

export function useValidateUserInvitation() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'onboarding']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: string; dto: IUserInvitationValidationDTO }) =>
      apiNew.patch<IUserInvitationValidationDTO>(`${USER_INVITATION_PATH}/${params.id}/validate`, params.dto),
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries(userInvitationFactoryKey.get());
      await queryClient.invalidateQueries(userInvitationFactoryKey.getById(params.id));
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}
