import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type {
  IContractResponseDTO,
  ILotResponseDTO,
  IProgressStatement,
  IProgressStatementWithDirectPayments,
  ISubProject,
} from '@graneet/business-logic';
import { CUMULATIVE_INPUT_TYPE } from '@graneet/business-logic';
import { DeepTable, Section, Tree, useCurrency, useDeepTable, Wizard } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, useStepForm, useWizardContext } from 'graneet-form';
import { useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

import {
  initialProgressStatementDataValues,
  mapContractToInitialTree,
} from 'features/progress-statement/services/progress-statement.util';
import { ProgressStatementFooter } from 'features/progress-statement/components/ProgressStatementFooter/ProgressStatementFooter';
import { ProgressStatementTotalsSummary } from 'features/progress-statement/components/ProgressStatementTotalsSummary';
import { ProgressStatementRedirectToContractEditionButton } from 'features/progress-statement/components/ProgressStatementRedirectToContractEdititonButton/ProgressStatementRedirectToContractEdititonButton';
import type { ProgressStatementTreeContext } from 'features/progress-statement/hooks/useProgressStatementTree';
import { useProgressStatementTree } from 'features/progress-statement/hooks/useProgressStatementTree';
import { ProgressStatementDeepTableHeader } from 'features/progress-statement/components/ProgressStatementDeepTableHeader';
import { ProgressStatementDeepTableContractOrLotLine } from 'features/progress-statement/components/ProgressStatementDeepTableContractOrLotLine/ProgressStatementDeepTableContractOrLotLine';
import { ProgressStatementDeepTableItemLine } from 'features/progress-statement/components/ProgressStatementDeepTableItemLine/ProgressStatementDeepTableItemLine';
import { ProgressStatementDeepTableVirtualizedItemLine } from 'features/progress-statement/components/ProgressStatementDeepTableVirtualizedItemLine/ProgressStatementDeepTableVirtualizedItemLine';
import { ProgressStatementDeepTableSeparator } from 'features/progress-statement/components/ProgressStatementDeepTableSeparator';
import { ProgressStatementLotOrContractWrapper } from 'features/progress-statement/components/ProgressStatementLotOrContractWrapper/ProgressStatementLotOrContractWrapper';
import type { ProgressStatementEditWizard } from 'features/progress-statement/forms/progress-statement-edit-wizard';
import { useProgressStatementContext } from 'features/progress-statement/contexts/ProgressStatementContext';
import type { ProgressStatementEditItemForm } from 'features/progress-statement/forms/progress-statement-edit-item-form';
import {
  getCustomDiscountCumulativeFieldName,
  getCustomDiscountModeLineFieldName,
  getDiscountCumulativeFieldName,
  getDiscountModeLineFieldName,
  getItemCumulativeFieldName,
} from 'features/progress-statement/forms/progress-statement-edit-item-form';

interface EditProgressStatementItemsStepProps {
  contracts: IContractResponseDTO[];

  previousProgressStatement: IProgressStatement | undefined;

  currentProgressStatement: IProgressStatementWithDirectPayments | null;

  subProject: ISubProject;
}

const EditProgressStatementItemsStepInternal: FC<
  Pick<EditProgressStatementItemsStepProps, 'previousProgressStatement' | 'subProject'> & {
    tree: ProgressStatementTreeContext;
  }
> = ({ previousProgressStatement, subProject, tree }) => {
  const Footer = useCallback<FC>(
    () => (
      <Box mt={4}>
        <ProgressStatementFooter subProject={subProject} previousProgressStatement={previousProgressStatement} />
      </Box>
    ),
    [previousProgressStatement, subProject],
  );

  return (
    <Tree
      tree={tree}
      headerComponent={ProgressStatementDeepTableHeader}
      nodeComponent={ProgressStatementDeepTableContractOrLotLine}
      leafComponent={ProgressStatementDeepTableItemLine}
      virtualizedLeafComponent={ProgressStatementDeepTableVirtualizedItemLine}
      separatorComponent={ProgressStatementDeepTableSeparator}
      nodeWrapperComponent={ProgressStatementLotOrContractWrapper}
      footerComponent={Footer}
    />
  );
};

export const EditProgressStatementItemsStep: FC<EditProgressStatementItemsStepProps> = ({
  contracts,
  previousProgressStatement,
  currentProgressStatement,
  subProject,
}) => {
  const { t } = useTranslation(['progressStatement']);

  // Max depth in all contracts' tree
  const maxRelativeDepthInLot = (lot: ILotResponseDTO): number =>
    1 + Math.max(0, ...lot.subLots.map(maxRelativeDepthInLot));
  const maxDepth = Math.max(
    0,
    ...contracts.map((contract) => Math.max(0, ...contract.container.lots.map(maxRelativeDepthInLot))),
  );

  const deepTable = useDeepTable({
    templateColumns: '6.5rem minmax(13rem,1fr) 7rem 7rem 7rem 7rem 11rem 12.5rem 9rem',
    leftContentWidth: 0.5 * maxDepth + 3,
  });

  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();

  const wizardContext = useWizardContext<ProgressStatementEditWizard>();
  const itemsStepFormValues = wizardContext.getValuesOfStep('items');

  const { progressStatementId } = useParams<{ progressStatementId?: string }>();
  const isCreation = !progressStatementId;

  const { previousItemsCumulativeValues } = useProgressStatementContext();

  const initialProgressStatementValues = useMemo(
    () =>
      initialProgressStatementDataValues(
        contracts,
        currentProgressStatement,
        previousProgressStatement,
        previousItemsCumulativeValues,
        itemsStepFormValues,
        mapAmountToNumber,
      ),
    [
      contracts,
      currentProgressStatement,
      previousItemsCumulativeValues,
      previousProgressStatement,
      itemsStepFormValues,
      mapAmountToNumber,
    ],
  );
  const initialValue = useMemo(
    () => mapContractToInitialTree(contracts, initialProgressStatementValues),
    [contracts, initialProgressStatementValues],
  );
  const tree = useProgressStatementTree(initialValue);

  const { form } = useStepForm<ProgressStatementEditWizard, 'items'>({
    defaultValues: (() => {
      const itemsDefaultValues = Object.keys(initialProgressStatementValues.itemValues).reduce<
        Partial<ProgressStatementEditItemForm>
      >((acc, itemIsAsString) => {
        const itemId = itemIsAsString as unknown as number;
        acc[getItemCumulativeFieldName(itemId, CUMULATIVE_INPUT_TYPE.PERCENTAGE)] =
          initialProgressStatementValues.itemValues[itemId].cumulativeProgressPercentage;
        acc[getItemCumulativeFieldName(itemId, CUMULATIVE_INPUT_TYPE.QUANTITY)] =
          initialProgressStatementValues.itemValues[itemId].cumulativeProgressQuantity;
        acc[getItemCumulativeFieldName(itemId, CUMULATIVE_INPUT_TYPE.AMOUNT)] = mapNumberToAmount(
          initialProgressStatementValues.itemValues[itemId].cumulativeAmountExVAT,
        );

        return acc;
      }, {});

      const discountLinesDefaultValues = Object.keys(initialProgressStatementValues.discountValues).reduce<
        Partial<ProgressStatementEditItemForm>
      >((acc, contractIsAsString) => {
        const contractId = contractIsAsString as unknown as number;
        acc[getDiscountModeLineFieldName(contractId)] =
          initialProgressStatementValues.discountValues[contractId].discountMode;
        acc[getDiscountCumulativeFieldName(contractId, CUMULATIVE_INPUT_TYPE.PERCENTAGE)] =
          initialProgressStatementValues.discountValues[contractId].discountCumulativePercentage;
        acc[getDiscountCumulativeFieldName(contractId, CUMULATIVE_INPUT_TYPE.AMOUNT)] = mapNumberToAmount(
          initialProgressStatementValues.discountValues[contractId].discountCumulativeAmount,
        );
        return acc;
      }, {});

      const customDiscountDefaultValues = Object.keys(initialProgressStatementValues.customDiscountValues).reduce<
        Partial<ProgressStatementEditItemForm>
      >((acc, customDiscountId) => {
        acc[getCustomDiscountModeLineFieldName(customDiscountId)] =
          initialProgressStatementValues.customDiscountValues[customDiscountId].customDiscountMode;
        acc[getCustomDiscountCumulativeFieldName(customDiscountId, CUMULATIVE_INPUT_TYPE.PERCENTAGE)] =
          initialProgressStatementValues.customDiscountValues[customDiscountId].customDiscountCumulativePercentage;
        acc[getCustomDiscountCumulativeFieldName(customDiscountId, CUMULATIVE_INPUT_TYPE.AMOUNT)] = mapNumberToAmount(
          initialProgressStatementValues.customDiscountValues[customDiscountId].customDiscountCumulativeAmount,
        );

        return acc;
      }, {});

      const cumulativeInputType = isCreation
        ? previousProgressStatement?.cumulativeInputType
        : currentProgressStatement?.cumulativeInputType;
      return {
        ...itemsDefaultValues,
        ...discountLinesDefaultValues,
        ...customDiscountDefaultValues,
        cumulativeInputType: cumulativeInputType || CUMULATIVE_INPUT_TYPE.PERCENTAGE,
      };
    })(),
  });

  return (
    <Form form={form}>
      <ProgressStatementRedirectToContractEditionButton
        subProject={subProject}
        contracts={contracts}
        currentProgressStatement={currentProgressStatement}
      />

      <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_RIGHT}>
        <ProgressStatementTotalsSummary
          subProject={subProject}
          previousProgressStatement={previousProgressStatement}
          form={form}
        />
      </Wizard.Placeholder>

      <Section title={t('progressStatement:isCreating.generateProgressStatement')} mb={0}>
        <DeepTable deepTable={deepTable} noCard gridProps={{ gap: 0 }} mt="-3rem">
          <EditProgressStatementItemsStepInternal
            tree={tree}
            previousProgressStatement={previousProgressStatement}
            subProject={subProject}
          />
        </DeepTable>
      </Section>
    </Form>
  );
};
