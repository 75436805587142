import type { FormContextApi } from 'graneet-form';
import { useOnChangeValues } from 'graneet-form';
import type { IAccountingInvoicesCountDTOResponseDTO } from '@graneet/business-logic';

import { useAccountingExportCountInvoicesPerJournal } from '../../../services/accounting-export.api';

import type { AccountingExportGenerateSelectionStepFormValues } from './AccountingExportGenerateSelectionStepFormValues';

export type UseNumberOfInvoicesPerJournal =
  | { isLoading: false; data: undefined }
  | { isLoading: true; data: undefined }
  | { isLoading: false; data: IAccountingInvoicesCountDTOResponseDTO };

export const useNumberOfInvoicesPerJournal = (
  form: FormContextApi<AccountingExportGenerateSelectionStepFormValues>,
): UseNumberOfInvoicesPerJournal => {
  const { fromDate, toDate } = useOnChangeValues(form, ['fromDate', 'toDate']);

  return useAccountingExportCountInvoicesPerJournal(
    fromDate && toDate
      ? {
          entity: undefined,
          selectedItems: undefined,
          fromDate,
          toDate,
          filters: undefined,
          search: undefined,
        }
      : undefined,
  );
};
