import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { isRequired } from './validation.util';

const VALIDATE_REQUIRED = (value: FieldValue) =>
  isRequired(value) && dayjs(value.startDate).isValid() && dayjs(value.endDate).isValid();

export const IsValidDateRange = () => {
  const { t } = useTranslation(['global']);

  return <Rule message={t('global:form.errors.required')} validationFn={VALIDATE_REQUIRED} />;
};
