import { Callout, Container } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VStack, Text } from '@chakra-ui/react';

import { CompanyKYCFields } from 'features/company/components/CompanyKYCFields';
import { useCompanyKYCValidation } from 'features/company/services/company.api';

export const KYCValidationTab: FC = () => {
  const { t } = useTranslation(['global', 'company']);
  const { data } = useCompanyKYCValidation();

  const isKYCValidated = data.kycValidatedAt !== null;
  const isKYCSubmitted = data.kycSubmittedAt !== null;

  return (
    <Container size="md">
      <VStack spacing={6} width="100%" alignItems="stretch">
        {isKYCValidated && (
          <Callout icon={<i className="ri-verified-badge-fill" />} colorScheme="green">
            <VStack width="100%" alignItems="flex-start">
              <Text fontWeight={600}>{t('company:kyc.status.validated')}</Text>
              <Text>{t('company:kyc.status.validated_description')}</Text>
            </VStack>
          </Callout>
        )}
        {isKYCSubmitted && !isKYCValidated && (
          <Callout icon={<i className="ri-phone-find-line" />} colorScheme="blueLight">
            <VStack width="100%" alignItems="flex-start">
              <Text fontWeight={600}>{t('company:kyc.status.submitted')}</Text>
              <Text>{t('company:kyc.status.submitted_description')}</Text>
            </VStack>
          </Callout>
        )}
        {!isKYCSubmitted && !isKYCValidated && (
          <Callout icon={<i className="ri-secure-payment-fill" />} colorScheme="gray">
            <VStack width="100%" alignItems="flex-start">
              <Text fontWeight={600}>{t('company:kyc.status.needed')}</Text>
              <Text>{t('company:kyc.status.needed_description')}</Text>
            </VStack>
          </Callout>
        )}
        <CompanyKYCFields
          kycOrgName={data.kycOrgName}
          kycAddress={data.kycAddress}
          kycIdentification={data.kycIdentification}
          kycOrgLegalStatus={data.kycOrgLegalStatus}
          kycSchemeName={data.kycSchemeName}
          canEdit={!isKYCSubmitted || isKYCValidated}
        />
      </VStack>
    </Container>
  );
};
