import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, HStack, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import { CheckboxField, DownloadPdfIcon, DownloadSpreadsheetIcon, FileIcon, Modal, useToast } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import { Form, HiddenField, useForm, useOnChangeValues } from 'graneet-form';
import type { IAccountingInvoicesCountDTOResponseDTO } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import type { UseQueryResult } from '@tanstack/react-query';

import { usePermissions } from 'features/role/hooks/usePermissions';

interface FormValues {
  exportPDF?: boolean;
  exportXLS?: boolean;
  exportAccounting?: boolean;
  exportAccountingFEC?: boolean;
  exportAccountingXLSX?: boolean;
}

interface ExportModalSelectionStepProps {
  allowAccountingExport?: boolean;
  allowPDFExport?: boolean;
  allowXLSExport?: boolean;
  countData: UseQueryResult<IAccountingInvoicesCountDTOResponseDTO, Error>;

  onGenerate(configuration: {
    exportPDF: boolean;
    exportXLS: boolean;
    exportAccounting: boolean;
    exportAccountingFEC: boolean;
    exportAccountingXLSX: boolean;
  }): Promise<void>;
}

export const ExportModalSelectionStep: FC<ExportModalSelectionStepProps> = ({
  countData,
  allowAccountingExport = true,
  allowPDFExport = true,
  allowXLSExport = true,
  onGenerate,
}) => {
  const { t } = useTranslation(['global', 'accounting']);
  const toast = useToast();

  const form = useForm<FormValues>();

  const hasAccessAccountingPermission = usePermissions([PERMISSION.DISPLAY_ACCOUNTING]);
  const canGenerateAccountingExport = usePermissions([PERMISSION.GENERATE_ACCOUNTING]);

  const accountingCount = useMemo(() => {
    if (countData.isError || countData.isPending) {
      return {
        exportable: 0,
        total: 0,
      };
    }

    return {
      exportable:
        countData.data.journalSales.exportable +
        countData.data.journalPurchases.exportable +
        countData.data.journalBank.exportable,
      total:
        countData.data.journalSales.total + countData.data.journalPurchases.total + countData.data.journalBank.total,
    };
  }, [countData]);

  useEffect(() => {
    if (countData.isError) {
      toast.error(t('global:errors.error'));
    }
  }, [countData.isError, t, toast]);

  useEffect(() => {
    if (allowXLSExport && !allowPDFExport) {
      form.setFormValues({ exportXLS: true });
    }
  }, [form, allowXLSExport, allowPDFExport]);

  const [isLoading, setIsLoading] = useState(false);

  const handleExport = useCallback(async () => {
    const values = form.getFormValues();

    setIsLoading(true);
    await onGenerate({
      exportXLS: !!values.exportXLS,
      exportPDF: !!values.exportPDF,
      exportAccounting: !!values.exportAccounting,
      exportAccountingXLSX: !!values.exportAccountingXLSX,
      exportAccountingFEC: !!values.exportAccountingFEC,
    });
    setIsLoading(false);
  }, [form, onGenerate]);

  useEffect(() => {
    if (allowXLSExport && !allowPDFExport && !allowAccountingExport) {
      handleExport();
    }
  }, [allowXLSExport, allowPDFExport, allowAccountingExport, handleExport]);

  const formValues = useOnChangeValues(form, ['exportAccounting', 'exportPDF', 'exportXLS']);
  const hasSelectedValues = !!Object.values(formValues).find((v) => v);

  const isAccountingExportSelected = !!formValues.exportAccounting;

  const { exportAccountingXLSX, exportAccountingFEC } = useOnChangeValues(form, [
    'exportAccountingXLSX',
    'exportAccountingFEC',
  ]);
  const hasOneTypeSelectionOnAccountingExportValid = isAccountingExportSelected
    ? !!exportAccountingXLSX || !!exportAccountingFEC
    : true;

  return (
    <Form form={form}>
      <VStack>
        {allowXLSExport ? (
          <CheckboxField<FormValues>
            name="exportXLS"
            label={
              <HStack spacing={2}>
                <Text>{t('global:generateExportModal.actions.exportSpreadsheet')}</Text>
                <DownloadSpreadsheetIcon boxSize={5} />
              </HStack>
            }
          />
        ) : (
          <HiddenField<FormValues> name="exportXLS" />
        )}

        {allowPDFExport ? (
          <CheckboxField<FormValues>
            name="exportPDF"
            label={
              <HStack spacing={2}>
                <Text>{t('global:generateExportModal.actions.exportPDF')}</Text>
                <DownloadPdfIcon boxSize={5} />
              </HStack>
            }
          />
        ) : (
          <HiddenField<FormValues> name="exportPDF" />
        )}

        {allowAccountingExport && canGenerateAccountingExport && hasAccessAccountingPermission && (
          <>
            <CheckboxField<FormValues>
              name="exportAccounting"
              isDisabled={accountingCount.exportable === 0}
              label={
                <HStack spacing={2}>
                  <Flex alignItems="center">
                    <Trans
                      t={t}
                      count={accountingCount.exportable}
                      i18nKey={
                        countData.isLoading || countData.isError
                          ? 'global:generateExportModal.actions.exportAccounting'
                          : 'global:generateExportModal.actions.exportAccountingWithCount'
                      }
                      values={{
                        totalInvoices: accountingCount.total,
                      }}
                    >
                      {countData.isLoading ? (
                        <Spinner size="sm" color="gray.600" ml={2} />
                      ) : (
                        <Text color="gray.600" pl={2} />
                      )}
                    </Trans>
                  </Flex>
                </HStack>
              }
            />

            <Stack gap={3} pl={8} w="100%">
              <CheckboxField<FormValues>
                name="exportAccountingFEC"
                isDisabled={!isAccountingExportSelected}
                label={
                  <Flex alignItems="center">
                    <Trans t={t} i18nKey="accounting:exportModal.fec">
                      <FileIcon mx={2} />

                      <Text color="gray.600" />
                    </Trans>
                  </Flex>
                }
              />
              <CheckboxField<FormValues>
                name="exportAccountingXLSX"
                isDisabled={!isAccountingExportSelected}
                label={
                  <Flex alignItems="center">
                    {t('accounting:exportModal.excel')}
                    <DownloadSpreadsheetIcon ml={2} />
                  </Flex>
                }
              />
            </Stack>
          </>
        )}
      </VStack>

      <Modal.Close isDisabled={isLoading} />

      <Modal.PrimaryButton
        isLoading={isLoading}
        onClick={handleExport}
        isDisabled={!hasSelectedValues || !hasOneTypeSelectionOnAccountingExportValid}
      >
        {t('global:generateExportModal.actions.export')}
      </Modal.PrimaryButton>
    </Form>
  );
};
