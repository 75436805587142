import type { IClient, IProjectWithRelations } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, useToast } from '@graneet/lib-ui';

import { ClientCardTypeEnum } from 'features/client/components/cards/ClientCardDetails';
import { useProjectUpdate } from 'features/project/services/project.api';
import { useContactDissociateAllFromProjectLinkedToClient } from 'features/contact/services/contact.api';
import { useClientAssociateToProject, useClientDeassociateToProject } from 'features/client/services/client.api';
import {
  ClientCardContactAssociateType,
  ClientInformationsTab,
} from 'screens/contacts/clients/_tabs/information/ClientInformationsTab';

interface ViewProjectClientSubTabProps {
  client: IClient;
  project: IProjectWithRelations;
}

export const ViewProjectClientSubTab: FC<ViewProjectClientSubTabProps> = ({ client, project }) => {
  const { t } = useTranslation(['project']);
  const toast = useToast();

  const clientAssociateToProjectMutation = useClientAssociateToProject();
  const clientDeassociateToProjectMutation = useClientDeassociateToProject();
  const projectUpdateMutation = useProjectUpdate();
  const contactDissociateAllFromProjectLinkedToClient = useContactDissociateAllFromProjectLinkedToClient();

  const updateClient = useCallback(
    async (clientId: number | null) => {
      const response = await projectUpdateMutation.mutateAsync({
        id: project.id,
        dto: {
          primaryClientId: clientId ?? undefined,
        },
      });

      await contactDissociateAllFromProjectLinkedToClient.mutateAsync({
        projectId: project.id,
        clientId: client.id,
      });
      toast.success(t(`project:flow.client.toast.${clientId ? 'successLink' : 'successUnlink'}`));
      return response.primaryClient;
    },
    [projectUpdateMutation, project.id, contactDissociateAllFromProjectLinkedToClient, client.id, toast, t],
  );

  const updateSecondaryClient = useCallback(
    async (clientId: number) =>
      clientAssociateToProjectMutation.mutateAsync({
        clientIds: [clientId],
        projectId: project.id,
      }),
    [clientAssociateToProjectMutation, project.id],
  );

  const removeSecondaryClient = useCallback(
    async (clientId: number) => {
      await clientDeassociateToProjectMutation.mutateAsync({ clientId, projectId: project.id });
    },
    [clientDeassociateToProjectMutation, project.id],
  );

  return (
    <Container size="md">
      <ClientInformationsTab
        projectId={project.id}
        associationType={ClientCardContactAssociateType.PROJECT}
        type={ClientCardTypeEnum.SWITCH}
        client={client}
        onClientUpdate={updateClient}
        updateSecondaryClient={updateSecondaryClient}
        removeSecondaryClient={removeSecondaryClient}
      />
    </Container>
  );
};
