import type { FC } from 'react';
import { useCallback } from 'react';
import { Modal } from '@graneet/lib-ui';
import type { IBudgetUsage } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';

import { useBudgetDelete } from '../services/budget.api';

export interface BudgetDeleteModalProps {
  modal: UseDisclosureReturn;

  budgetUsage: IBudgetUsage | null;
}

export const BudgetDeleteModal: FC<BudgetDeleteModalProps> = ({ modal, budgetUsage }) => {
  const { t } = useTranslation(['global', 'project']);

  const budgetDeleteMutation = useBudgetDelete();

  const handleSubmit = useCallback(async () => {
    if (!budgetUsage) return;

    budgetDeleteMutation.mutate(budgetUsage.id, {
      onSuccess: () => {
        modal.onClose();
      },
    });
  }, [budgetDeleteMutation, budgetUsage, modal]);

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      title={t('project:budget.delete.title')}
      size="2xl"
      scrollBehavior="inside"
    >
      {t('project:budget.delete.description')}

      <Modal.Close />
      <Modal.PrimaryButton colorScheme="red" isLoading={budgetDeleteMutation.isPending} onClick={handleSubmit}>
        {t('project:budget.delete.submit')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
