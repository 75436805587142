import { useTranslation } from 'react-i18next';
import type { IProject } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { useToast } from '@graneet/lib-ui';

import { useProjectUpdate } from '../../services/project.api';

import { TextEditionCard } from 'features/common/components/TextEditionCard';
import { usePermissions } from 'features/role/hooks/usePermissions';

interface ProjectCommentCardProps {
  project: IProject;
}

export const ProjectCommentCard: FC<ProjectCommentCardProps> = ({ project }) => {
  const { t } = useTranslation(['project', 'global']);
  const toast = useToast();

  const hasCreateProjectsPermission = usePermissions([PERMISSION.CREATE_PROJECTS]);

  const projectUpdateMutation = useProjectUpdate();

  const updateComment = async (comment: string | null) => {
    const data = await projectUpdateMutation.mutateAsync({ id: project.id, dto: { comment } });
    toast.success(t(t('global:words.c.success'), t('project:changeComment.success')));
    return data;
  };

  if (!project.comment && !hasCreateProjectsPermission) {
    return <Box />;
  }

  return (
    <TextEditionCard
      title={t('project:fields.comment')}
      errorLabel={t('project:changeComment.error')}
      successLabel={t('project:changeComment.success')}
      placeholder={t('project:changeComment.placeholder')}
      text={project.comment}
      onTextEdited={updateComment}
      isEditable={hasCreateProjectsPermission}
    />
  );
};
