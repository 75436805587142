import type { AxiosError } from 'axios';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import type { Response } from '@graneet/lib-ui';

import { isAnyErrorForbidden, redirectToForbiddenScreen } from './useDataGetter';

// -- DataState
interface DataStateLoading {
  error: null;
  loading: true;
  data: null;
  filteredData: null;
}

interface DataStateError {
  error: AxiosError;
  loading: false;
  data: null;
  filteredData: null;
}

interface DataStateFetched<T = unknown> {
  error: null;
  loading: false;
  data: T;
  filteredData: T;
}

export type DataState<T = any> = DataStateLoading | DataStateError | DataStateFetched<T>;

// -- Reducer
const INITIAL_STATE: DataState = {
  error: null,
  loading: true,
  data: null,
  filteredData: null,
};

enum ACTIONS {
  FETCHED = 'FETCHED',
  FETCHING = 'FETCHING',
  FILTERED = 'FILTERED',
  ERROR = 'ERROR',
}

interface DataAction {
  type: ACTIONS;
  action?: Partial<DataState>;
}

const reducer = (state: DataState, { type, action }: DataAction): DataState => {
  switch (type) {
    case ACTIONS.FETCHING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ACTIONS.FETCHED:
      return {
        ...state,
        ...action,
        loading: false,
      };
    case ACTIONS.ERROR:
      return {
        ...state,
        error: action?.error || null,
        loading: false,
      };
    case ACTIONS.FILTERED:
      return {
        ...state,
        filteredData: action?.filteredData,
      };
    default:
      return state;
  }
};

export interface UseDataOption<T> {
  /**
   * Should redirect on forbidden
   * @default true
   */
  redirectOnForbidden?: boolean;
  /**
   * Should load data on render
   * @default true
   */
  autoLoad?: boolean;

  /**
   * Function apply to data returned by the API, it will modify `data`
   */
  formatFunc?(response: T): T;
  /**
   * Function for filtering apply to data returned by the API, the result will be stored in `filteredData` and will
   * not impact `data`
   */
  filterFunc?(response: T): T;
}

/**
 * Custom hook to fetch data and transform it thanks to two specific functions.
 * 1. To format data, it is useful to sort data for instance
 * 2. To filter data
 *
 * @param getterFunc Function to retrieve data
 * @param options - Options for fetching
 *
 * @returns an objet following attributes
 * - data : resolved data of the first function
 * - error : axios returned an error
 * - loading : axios request is pending
 * - fetch : function which can be used to re-fetch data
 *
 * @example
 *  ```
 *  const getData = useDataGetter(getProjects, company.id);
 *  const { data, error, loading, fetch } = useData(getData, sortProjects, filterData);
 *  ...
 *  <button onClick={fetch}>Refresh</button>
 *  ```
 */
export const useData = <T>(
  getterFunc: (p?: any) => Promise<Response<T>>,
  options?: UseDataOption<T>,
): DataState<T> & { fetch(...params: unknown[]): Promise<void> } => {
  const {
    redirectOnForbidden: inRedirectOnForbidden,
    formatFunc: formatFuncProp,
    filterFunc: filterFuncProp,
    autoLoad = true,
  } = options || {};

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const history = useHistory();

  const { current: formatFunc } = useRef(formatFuncProp);
  const { current: filterFunc } = useRef(filterFuncProp);
  const redirectOnForbidden = typeof inRedirectOnForbidden === 'boolean' ? inRedirectOnForbidden : true;

  const fetch = useCallback(
    async (...params: unknown[]) => {
      dispatch({ type: ACTIONS.FETCHING });
      const [error, rawData] = await getterFunc(...params);

      if (error) {
        if (isAnyErrorForbidden([error]) && redirectOnForbidden) {
          redirectToForbiddenScreen(history);
        } else {
          dispatch({ type: ACTIONS.ERROR, action: { error } });
        }
      } else {
        const data = formatFunc ? formatFunc(rawData) : rawData;
        const filteredData = filterFunc ? filterFunc(data) : data;

        dispatch({
          type: ACTIONS.FETCHED,
          action: {
            data,
            filteredData,
            error: null,
          },
        });
      }
    },
    // Do not put filterFunc to avoid re-fetch when filterFunc is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getterFunc, formatFunc],
  );

  useEffect(() => {
    if (filterFunc) {
      const filteredData = filterFunc(state.data);
      dispatch({ type: ACTIONS.FILTERED, action: { filteredData } });
    }
  }, [state.data, filterFunc]);

  useEffect(() => {
    if (autoLoad) {
      fetch();
    }
  }, [fetch, autoLoad]);

  return {
    ...state,
    fetch,
  };
};
