import type { FieldValues, AnyRecord } from 'graneet-form';
import { Field, VALIDATION_OUTCOME, composeEventHandlers } from 'graneet-form';
import type { FC, JSXElementConstructor, ReactElement } from 'react';

import type { KeysMatching } from '../../../utils/type.util';
import type { InputProps } from '../../Input';
import { Input } from '../../Input';
import { AutoResizeTextarea, AutoResizeTextareaTooltip } from '../../AutoResizeTextarea/AutoResizeTextarea';

export type TextAreaFieldValue = string | undefined | null;

export interface TextAreaFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, TextAreaFieldValue> = KeysMatching<T, TextAreaFieldValue>,
> extends Omit<InputProps, 'onChange' | 'name' | 'as'> {
  name: K;

  data?: AnyRecord;

  hideErrorMessage?: boolean;

  onChange?(value: T[K]): void | Promise<void>;

  icon?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;

  maxRows?: number;
}

export const TextAreaField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, TextAreaFieldValue> = KeysMatching<T, TextAreaFieldValue>,
>({
  name,
  data,
  children,
  onBlur,
  onChange,
  onFocus,
  ...rest
}: TextAreaFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    data={data}
    render={(properties, state) => {
      const { value, onChange: onChangeForm, onBlur: onBlurForm, onFocus: onFocusForm } = properties;
      const { validationStatus } = state;
      const shouldDisplayError = validationStatus.status === VALIDATION_OUTCOME.INVALID;

      return (
        <Input
          {...rest}
          errorMessage={validationStatus.message}
          shouldDisplayError={shouldDisplayError}
          value={value || ''}
          inputProps={{
            minHeight: '32px !important',
            px: 2,
            pb: 0,
            pt: '4px !important',
          }}
          onChange={composeEventHandlers(onChange, onChangeForm)}
          onBlur={composeEventHandlers(onBlur, onBlurForm)}
          onFocus={composeEventHandlers(onFocus, onFocusForm)}
          as={(rest.icon ? AutoResizeTextareaTooltip : AutoResizeTextarea) as FC<any>}
          multiline
        />
      );
    }}
  >
    {children}
  </Field>
);
