import type { FC } from 'react';

import { SimpleDuplicateIcon } from '../../Icons';
import { actionTranslations } from '../ActionMenuTranslations';

import type { ActionProps } from './Action';
import { Action } from './Action';

export const Duplicate: FC<Omit<ActionProps, 'icon' | 'label'>> = (props) => (
  <Action icon={<SimpleDuplicateIcon />} label={actionTranslations.duplicate} {...props} />
);
