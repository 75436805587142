import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useCurrency,
  Button,
  DrawersStack,
  Header,
  Card,
  AlertMessage,
  Spinner,
  useToast,
  RichText,
  Badge,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Collapse, HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import { Form, useForm, useFormStatus, useOnChangeValues } from 'graneet-form';
import {
  groupExistingComponentsByType,
  divideFloating,
  computeAmountExVATWithDisbursementAndTotalMargin,
} from '@graneet/business-logic';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { object } from 'prop-types';

import { useStore } from 'store/store';
import { MARGIN_FIELD_NAME } from 'features/margin/services/margin.constants';
import { MarginEditionByComponentsTypesTable } from 'features/margin/components/MarginEditionByComponentsTypesTable';
import {
  QUOTE_TOTAL_FIELDS,
  MARGIN_ENDPOINT_TYPE,
  QUOTE_TOTAL_MARGIN_FIELD,
  INCLUDE_COMPONENT,
} from 'features/quote/constants/quotes.constant';
import {
  BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD,
  BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD,
} from 'features/margin/services/margins.utils';
import { useLot } from 'features/quote-lot/hooks/useLot';
import { usePreviewLotMarginSellSheet } from 'features/quote-lot/hooks/usePreviewLotMarginSellSheet';
import { useEditLotMarginSellSheet } from 'features/quote-lot/hooks/useEditLotMarginSellSheet';
import { usePreviewLotAmountSellSheet } from 'features/quote-lot/hooks/usePreviewLotAmountSellSheet';
import { useEditLotAmountSellSheet } from 'features/quote-lot/hooks/useEditLotAmountSellSheet';
import { useEditLotComponentTypeMarginSellSheet } from 'features/quote-lot/hooks/useEditLotComponentTypeMarginSellSheet';
import { usePreviewLotComponentTypeMarginSellSheet } from 'features/quote-lot/hooks/usePreviewLotComponentTypeMarginSellSheet';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useQuote } from 'features/quote/hooks/useQuote';
import { QuoteSalesSheetMarginMultiplication } from 'features/quote/components/multiplications/QuoteSalesSheetMarginMultiplication';
import { QuoteSalesSheetSellingMultiplication } from 'features/quote/components/multiplications/QuoteSalesSheetSellingMultiplication';
import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';
import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useCompanyMargins } from 'features/company/services/company.api';

const CARD_TITLE_STYLE = {
  color: 'gray.800',
  fontWeight: '600',
};

const COLLAPSE_BUTTON_HOVER_STYLE = {
  color: 'greenBrand.light',
  backgroundColor: 'transparent',
};

const COLLAPSE_BUTTON_STYLE = {
  color: 'gray.800',
  fontWeight: '600',
  transition: 'all 0.3s ease 0s',
};

const ICON_STYLE = {
  boxSize: 6,
};

export const EditQuoteLotDrawer = ({ drawerLot }) => {
  const { t } = useTranslation(['quote', 'margin']);
  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();

  const { lotIdToUpdate, isReadOnlyView } = useQuoteDisplayContext();
  const wrapperLot = useLot(lotIdToUpdate);

  const isOptional = useStore((state) => state.optionalLotsTable[lotIdToUpdate?.toString()]);

  const form = useForm();
  const { isValid: isFormValid } = useFormStatus(form);
  const { setFormValues, getFormValues } = form;

  const componentsMarginForm = useForm();
  const { setFormValues: setComponentsMarginFormValues, getFormValues: getComponentsMarginFormValues } =
    componentsMarginForm;

  const { isOpen, onToggle } = useDisclosure();

  const { getCurrentQuoteData, forEachDescendingLot } = useQuoteEditContext();

  const previewLotMarginSellSheet = usePreviewLotMarginSellSheet();
  const updateLotMarginSellSheet = useEditLotMarginSellSheet();
  const previewLotAmountSellSheet = usePreviewLotAmountSellSheet();
  const updateLotAmountSellSheet = useEditLotAmountSellSheet();
  const updateLotComponentTypeMarginSellSheet = useEditLotComponentTypeMarginSellSheet();
  const resetLotComponentTypeMarginSellSheet = usePreviewLotComponentTypeMarginSellSheet();

  const quote = useQuote();
  const { components, jobs, relations } = getCurrentQuoteData();
  const [marginComputedValue, setMarginComputedValue] = useState();
  const [endpointTypeToCall, setEndpointTypeToCall] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(true);
  const [isEditionDisabled, setIsEditionDisabled] = useState(false);
  const [displayPreviewButton, setDisplayPreviewButton] = useState(false);
  const [currentEditField, setCurrentEditField] = useState();
  const [lastEditedFieldValue, setLastEditedFieldValue] = useState();
  const [componentsTypes, setComponentsTypes] = useState();
  const focusFieldRef = useRef();

  const companyMargins = useCompanyMargins();

  const toast = useToast();

  // Get all components and group by component type
  useEffect(() => {
    if (!wrapperLot) return;

    // Get all jobs in the sub tree
    const jobsInTree = [];
    forEachDescendingLot(wrapperLot.lot.id, (lot) => {
      const jobsIds = relations[lot.id].jobs;
      jobsIds.forEach((jobId) => {
        jobsInTree.push(jobs[jobId]);
      });
    });

    if (components) {
      const componentTypesGrouped = groupExistingComponentsByType(
        Object.values(components),
        companyMargins.data,
        jobsInTree,
      );
      setComponentsTypes(componentTypesGrouped);
    }
  }, [components, wrapperLot, quote, currentEditField, companyMargins.data, jobs, forEachDescendingLot, relations]);

  const handleSetComponentsMarginFormValues = useCallback(() => {
    if (componentsTypes) {
      componentsTypes.map((component) =>
        setComponentsMarginFormValues({
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]:
            component.overheadCosts,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
            component.profitMargin,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
            component.totalMargin,
        }),
      );
    }
  }, [componentsTypes, setComponentsMarginFormValues]);

  const handleSetMarginFormValues = useCallback(() => {
    if (!wrapperLot) return;

    const { amountExVAT, optionalAmountExVAT, disbursementExVAT, optionalDisbursementExVAT, margin } = wrapperLot.lot;

    const { overheadCosts, profitMargin, totalMargin: initialTotalMargin, computed } = margin;

    const optionalinitialTotalMargin = divideFloating(optionalAmountExVAT, optionalDisbursementExVAT);
    const optionalProfitMargin = divideFloating(optionalinitialTotalMargin, overheadCosts);

    setMarginComputedValue(computed);

    setFormValues({
      [MARGIN_FIELD_NAME.OVERHEAD_COSTS]: overheadCosts,
      [MARGIN_FIELD_NAME.PROFIT_MARGIN]: isOptional ? optionalProfitMargin : profitMargin,
      [MARGIN_FIELD_NAME.TOTAL_MARGIN]: isOptional ? optionalinitialTotalMargin : initialTotalMargin,
      [QUOTE_TOTAL_FIELDS.DISBURSEMENT_EX_VAT]: isOptional
        ? mapNumberToAmount(optionalDisbursementExVAT)
        : mapNumberToAmount(disbursementExVAT),
      [QUOTE_TOTAL_MARGIN_FIELD]: isOptional ? optionalinitialTotalMargin : initialTotalMargin,
      [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT]: isOptional
        ? mapNumberToAmount(optionalAmountExVAT)
        : mapNumberToAmount(amountExVAT),
    });
  }, [wrapperLot, setFormValues, isOptional, mapNumberToAmount]);

  useEffect(() => {
    handleSetComponentsMarginFormValues();
    handleSetMarginFormValues();
  }, [handleSetComponentsMarginFormValues, handleSetMarginFormValues]);

  const computePreview = useCallback(() => {
    const {
      [QUOTE_TOTAL_FIELDS.DISBURSEMENT_EX_VAT]: disbursementExVAT,
      [MARGIN_FIELD_NAME.TOTAL_MARGIN]: totalMargin,
    } = getFormValues();

    // If data not ready, do nothing
    if (!Number.isFinite(disbursementExVAT) || !totalMargin) {
      return;
    }

    const disbursementExVATAmount = mapAmountToNumber(disbursementExVAT);
    const amountExVAT = computeAmountExVATWithDisbursementAndTotalMargin(disbursementExVATAmount, totalMargin);

    // If user is not updating margin, do nothing
    if (!Object.values(MARGIN_FIELD_NAME).includes(focusFieldRef.current)) {
      return;
    }

    // Sync quote fields when margins fields are updated
    setFormValues({
      [QUOTE_TOTAL_MARGIN_FIELD]: totalMargin,
      [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT]: mapNumberToAmount(amountExVAT),
    });

    setEndpointTypeToCall(MARGIN_ENDPOINT_TYPE.MARGIN);
  }, [getFormValues, mapAmountToNumber, mapNumberToAmount, setFormValues]);

  useEffect(() => {
    const { [MARGIN_FIELD_NAME.OVERHEAD_COSTS]: overheadCosts, [QUOTE_TOTAL_MARGIN_FIELD]: quoteMargin } =
      getFormValues();
    // If data not ready, do nothing
    if (!overheadCosts || !quoteMargin) return;

    // If user is not updating total amount, do nothing
    if (QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT !== focusFieldRef.current) return;

    const profitMargin = divideFloating(quoteMargin, overheadCosts);
    // Sync margins when margin of quote is updated
    setFormValues({
      [MARGIN_FIELD_NAME.PROFIT_MARGIN]: profitMargin,
      [MARGIN_FIELD_NAME.TOTAL_MARGIN]: quoteMargin,
    });
    setEndpointTypeToCall(MARGIN_ENDPOINT_TYPE.AMOUNT_EX_VAT);
  }, [currentEditField, getFormValues, setFormValues]);

  const handleUpdate = useCallback(async () => {
    setIsLoading(true);

    const {
      lot: { id: lotId },
    } = wrapperLot;

    // When the price is base on margin (so amount ex VAT is computed from margin) call margin update endpoint
    if (endpointTypeToCall === MARGIN_ENDPOINT_TYPE.MARGIN) {
      const { [MARGIN_FIELD_NAME.TOTAL_MARGIN]: newTotalMargin } = getFormValues();

      const dto = {
        totalMargin: newTotalMargin,
      };

      await updateLotMarginSellSheet(lotId, dto);
    }

    if (endpointTypeToCall === MARGIN_ENDPOINT_TYPE.AMOUNT_EX_VAT) {
      const { [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT]: amountExVAT } = getFormValues();

      const dto = {
        amountExVAT: mapAmountToNumber(amountExVAT),
        isOptional,
      };

      await updateLotAmountSellSheet(lotId, dto, companyMargins.data);
    }

    if (endpointTypeToCall === MARGIN_ENDPOINT_TYPE.MARGIN_COMPONENT_TYPE) {
      const { 1: componentTypeId } = currentEditField.split('-');

      const {
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]:
          overheadCostsComponentType,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
          profitMarginComponentType,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
          totalMarginComponentType,
        [BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(componentTypeId)]: computedComponentType,
      } = getComponentsMarginFormValues();

      if (
        !overheadCostsComponentType ||
        !profitMarginComponentType ||
        !totalMarginComponentType ||
        !computedComponentType
      ) {
        setIsLoading(false);
        return;
      }

      const componentDto = {
        overheadCosts: overheadCostsComponentType,
        profitMargin: profitMarginComponentType,
        totalMargin: totalMarginComponentType,
        computed: computedComponentType,
      };

      await updateLotComponentTypeMarginSellSheet(lotId, componentTypeId, componentDto, companyMargins.data);
    }
    setIsLoading(false);
    setIsDisabledButton(true);
    setIsEditionDisabled(false);
    setEndpointTypeToCall(undefined);
    focusFieldRef.current = undefined;

    setCurrentEditField();
    setLastEditedFieldValue();
  }, [
    wrapperLot,
    endpointTypeToCall,
    getFormValues,
    updateLotMarginSellSheet,
    mapAmountToNumber,
    isOptional,
    updateLotAmountSellSheet,
    companyMargins.data,
    currentEditField,
    getComponentsMarginFormValues,
    updateLotComponentTypeMarginSellSheet,
  ]);

  const handleBackToInitialState = useCallback(async () => {
    if (currentEditField) {
      if (currentEditField.includes(INCLUDE_COMPONENT)) {
        setIsLoadingSpinner(true);
        const { 1: componentTypeId, 2: fieldName } = currentEditField.split('-');
        const {
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.PROFIT_MARGIN)]: profitMargin,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]: overheadCosts,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.TOTAL_MARGIN)]: totalMargin,
          [BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(componentTypeId)]: valueComputed,
        } = getComponentsMarginFormValues();

        const dto = {
          overheadCosts,
          profitMargin,
          totalMargin,
          computed: valueComputed,
        };

        switch (fieldName) {
          case MARGIN_FIELD_NAME.PROFIT_MARGIN:
            dto.profitMargin = lastEditedFieldValue;
            break;
          case MARGIN_FIELD_NAME.OVERHEAD_COSTS:
            dto.overheadCosts = lastEditedFieldValue;
            break;
          case MARGIN_FIELD_NAME.TOTAL_MARGIN:
            dto.totalMargin = lastEditedFieldValue;
            break;
          default:
            break;
        }

        await resetLotComponentTypeMarginSellSheet(wrapperLot.lot.id, componentTypeId, dto, companyMargins.data);
        setIsLoadingSpinner(false);
      } else {
        setFormValues({ [`${currentEditField}`]: lastEditedFieldValue });
        handleSetMarginFormValues();
        handleSetComponentsMarginFormValues();
      }
    }
    setDisplayPreviewButton(false);
    setIsEditionDisabled(false);
    setIsDisabledButton(true);
    setCurrentEditField();
    setLastEditedFieldValue();
  }, [
    currentEditField,
    lastEditedFieldValue,
    wrapperLot,
    companyMargins.data,
    getComponentsMarginFormValues,
    resetLotComponentTypeMarginSellSheet,
    setFormValues,
    handleSetMarginFormValues,
    handleSetComponentsMarginFormValues,
  ]);

  // -- onChange functions
  const onFocus = useCallback(
    (field, value) => () => {
      focusFieldRef.current = field;
      if (currentEditField !== field) {
        setLastEditedFieldValue(value);
      }
    },
    [currentEditField],
  );

  const onBlur = useCallback(
    () => async () => {
      if (!currentEditField) return;

      setIsEditionDisabled(true);
      setDisplayPreviewButton(false);
      setIsLoadingSpinner(true);

      const {
        lot: { id: lotId },
      } = wrapperLot;
      const {
        [MARGIN_FIELD_NAME.OVERHEAD_COSTS]: overheadCosts,
        [MARGIN_FIELD_NAME.PROFIT_MARGIN]: profitMargin,
        [MARGIN_FIELD_NAME.TOTAL_MARGIN]: newTotalMargin,
        [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT]: newAmountExVAT,
      } = getFormValues();
      let previewResults = [];

      if (!overheadCosts || !profitMargin || !newTotalMargin || !newAmountExVAT) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        // Timeout to prevent double click after preview
        await handleBackToInitialState();
        setTimeout(() => {
          setIsLoadingSpinner(false);
          setIsDisabledButton(true);
        }, 500);
        return;
      }

      if (currentEditField === MARGIN_FIELD_NAME.TOTAL_MARGIN) {
        const dto = {
          totalMargin: newTotalMargin,
        };

        previewResults = await previewLotMarginSellSheet(lotId, dto, companyMargins.data);
      }

      if (currentEditField === QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT) {
        const dto = {
          amountExVAT: mapAmountToNumber(newAmountExVAT),
          isOptional,
        };

        previewResults = await previewLotAmountSellSheet(lotId, dto, companyMargins.data);
      }

      if (!Array.isArray(previewResults)) {
        await handleBackToInitialState();
        previewResults = [];
      }

      previewResults.map((previewResult) => {
        const {
          componentType,
          disbursementExVAT,
          optionalDisbursementExVAT,
          quantity,
          overheadCosts: overheadCostsComponentType,
          profitMargin: profitMarginComponentType,
          totalMargin: totalMarginComponentType,
        } = previewResult;

        const currentComponent = componentsTypes.find((component) => component.id === componentType.id);

        if (currentComponent) {
          currentComponent.quantity = isOptional ? optionalDisbursementExVAT : disbursementExVAT;
          currentComponent.disbursementExVAT = quantity;
        }

        return setComponentsMarginFormValues({
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentType.id, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]:
            overheadCostsComponentType,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
            profitMarginComponentType,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
            totalMarginComponentType,
        });
      });
      // Timeout to prevent double click after preview
      setTimeout(() => {
        setIsLoadingSpinner(false);
        setIsDisabledButton(false);
      }, 500);
    },
    [
      currentEditField,
      wrapperLot,
      getFormValues,
      toast,
      t,
      handleBackToInitialState,
      previewLotMarginSellSheet,
      companyMargins.data,
      mapAmountToNumber,
      isOptional,
      previewLotAmountSellSheet,
      componentsTypes,
      setComponentsMarginFormValues,
    ],
  );

  const onChange = useCallback(
    // the method takes 2 parameters from component, cause the methode is shared with the EditQuoteSellSheetDrawer.onChange(editFieldName, editFieldValue)
    (editFieldName) => {
      setDisplayPreviewButton(true);
      setCurrentEditField(editFieldName);
    },
    [],
  );

  const formValues = useOnChangeValues(form, undefined);

  useEffect(() => {
    computePreview();
  }, [formValues, computePreview]);

  const primaryDrawerAction = useMemo(() => {
    if (isReadOnlyView) return <Button onClick={drawerLot.onClose}>{t('global:words.c.close')}</Button>;

    if (displayPreviewButton) return <Button>{t('quote:salesSheetDrawer.cta.previewChanges')}</Button>;

    return (
      <Button onClick={handleUpdate} isDisabled={!isFormValid || isDisabledButton} isLoading={isLoading}>
        {t('quote:salesSheetDrawer.cta.applyChanges')}
      </Button>
    );
  }, [
    displayPreviewButton,
    drawerLot.onClose,
    handleUpdate,
    isDisabledButton,
    isFormValid,
    isLoading,
    isReadOnlyView,
    t,
  ]);

  return (
    <DrawersStack.Drawer
      title={
        <Box display="flex" flexDirection="row" width="full" height="fit-content" gap="3" alignItems="center">
          {isOptional ? (
            <Badge
              color="purple.700"
              backgroundColor="purple.200"
              borderColor="purple.200"
              fontWeight="bold"
              fontSize="0.8rem"
              height="6"
            >
              {t('quote:lotEdition.lotSalesSheetDrawer.option')}
            </Badge>
          ) : null}
          <Text>{t('quote:lotEdition.lotSalesSheetDrawer.title')}</Text>

          <RichText key={wrapperLot?.lot?.description} value={wrapperLot?.lot?.description} />
        </Box>
      }
      drawer={drawerLot}
      onOverlayClick={handleBackToInitialState}
      footer={
        <Flex justify={currentEditField ? 'space-between' : 'flex-end'} w="100vw">
          {currentEditField && (
            <Button
              colorScheme="red"
              variant="outline"
              onClick={handleBackToInitialState}
              isDisabled={!isFormValid || isDisabledButton}
              isLoading={isLoading}
            >
              {t('quote:salesSheetDrawer.cta.resetChanges')}
            </Button>
          )}
          {primaryDrawerAction}
        </Flex>
      }
    >
      <QueryWrapper>
        {isLoadingSpinner && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              backgroundColor: 'rgba(255,255,255,0.5)',
              height: '100vh',
              width: '100vw',
              maxWidth: 'var(--chakra-sizes-4xl)',
              top: 0,
              right: 0,
              zIndex: 'var(--chakra-zIndices-popover)',
            }}
          >
            <Spinner flexGrow={1} justify="center" my={8} />
          </Box>
        )}
        {!isReadOnlyView && <Text mb={4}>{t('quote:lotEdition.lotSalesSheetDrawer.description')}</Text>}

        {isEditionDisabled && (
          <AlertMessage mb={4}>{t('quote:lotEdition.lotSalesSheetDrawer.alertMessage')}</AlertMessage>
        )}

        <Form form={form}>
          <Card variant="light-gray" noShadow>
            <Header px={0} height="auto" backgroundColor="gray.100" sx={CARD_TITLE_STYLE}>
              <Flex mt={0} mb={0} height="auto" alignItems="center">
                <Text>{t('margin:quote.description')}</Text>
              </Flex>
            </Header>

            <QuoteSalesSheetMarginMultiplication
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              setCurrentEditField={setCurrentEditField}
              setMarginComputedValue={setMarginComputedValue}
              marginComputedValue={marginComputedValue}
              currentEditField={currentEditField}
              isEditionDisabled={isEditionDisabled || isReadOnlyView}
            />

            <Box>
              <HStack spacing={2} onClick={onToggle} cursor="pointer" role="group">
                <Text sx={COLLAPSE_BUTTON_STYLE} _groupHover={COLLAPSE_BUTTON_HOVER_STYLE}>
                  {t('quote:jobsStep.salesSheetDrawer.collapseTitle')}
                </Text>
                <IconButton
                  aria-label="Toggle"
                  bg="none"
                  size="md"
                  _focus={{ border: 'none' }}
                  _groupHover={COLLAPSE_BUTTON_HOVER_STYLE}
                  icon={isOpen ? <ChevronUpIcon {...ICON_STYLE} /> : <ChevronDownIcon {...ICON_STYLE} />}
                />
              </HStack>

              <Collapse in={isOpen} animateOpacity>
                <Box mt={3} bgColor="gray.100">
                  <Form form={componentsMarginForm}>
                    <MarginEditionByComponentsTypesTable
                      lotId={wrapperLot?.lot.id}
                      componentTypes={componentsTypes}
                      currentEditField={currentEditField}
                      setComponentsMarginFormValues={setComponentsMarginFormValues}
                      setCurrentEditField={setCurrentEditField}
                      setLastEditedFieldValue={setLastEditedFieldValue}
                      setIsLoadingSpinner={setIsLoadingSpinner}
                      setIsDisabledButton={setIsDisabledButton}
                      setEndpointTypeToCall={setEndpointTypeToCall}
                      isAdditionalInfoDisplayed
                      handleBackToInitialState={handleBackToInitialState}
                      isEditionDisabled={isEditionDisabled || isReadOnlyView}
                      setIsEditionDisabled={setIsEditionDisabled}
                      setDisplayPreviewButton={setDisplayPreviewButton}
                      isUpdateLot
                    />
                  </Form>
                </Box>
              </Collapse>
            </Box>
          </Card>

          <Card variant="light-gray" my={4} noShadow>
            <Header color="gray.800" px={0} height="auto" backgroundColor="gray.100" sx={CARD_TITLE_STYLE}>
              {t('quote:lotEdition.lotSalesSheetDrawer.sellingPrice')}
            </Header>

            <QuoteSalesSheetSellingMultiplication
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              currentEditField={currentEditField}
              isEditionDisabled={isEditionDisabled || isReadOnlyView}
              quote={quote}
              type="quote-lot"
            />
          </Card>
        </Form>
      </QueryWrapper>
    </DrawersStack.Drawer>
  );
};

EditQuoteLotDrawer.propTypes = {
  drawerLot: object.isRequired,
};
