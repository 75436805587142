import type { FC } from 'react';
import { Center, Flex, Stack, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { OnboardingIcon, Underline } from '@graneet/lib-ui';

export const OnboardingSlogan: FC = () => {
  const { t } = useTranslation(['onboarding']);

  return (
    <Stack w="100%" gap={4}>
      <Flex justifyContent="center">
        <Text fontWeight={600} fontSize="1.5rem">
          <Trans t={t} i18nKey="onboarding:slogan">
            <Underline color="yellow.400">display_content</Underline>
          </Trans>
        </Text>
      </Flex>

      <Center>
        <OnboardingIcon w="40rem" h="auto" />
      </Center>
    </Stack>
  );
};
