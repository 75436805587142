import type { FC } from 'react';
import type {
  IFinalStatementConditionsResponseDTO,
  IFinalStatementsVersionsDTO,
  IProject,
  ISubProject,
} from '@graneet/business-logic';
import { isProjectActive, PERMISSION, SUB_PROJECT_BILLING_TYPE } from '@graneet/business-logic';
import { Button } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';

import { CloseProjectModal } from './modals/CloseProjectModal';

import { usePermissions } from 'features/role/hooks/usePermissions';

interface ProjectCloseButtonProps {
  project: IProject;

  subProjects: ISubProject[];

  finalStatements: IFinalStatementsVersionsDTO | null;

  finalStatementCheckDTO: IFinalStatementConditionsResponseDTO;
}

export const ProjectCloseButton: FC<ProjectCloseButtonProps> = ({
  project,
  subProjects,
  finalStatements,
  finalStatementCheckDTO,
}) => {
  const { t } = useTranslation(['project']);

  const modal = useDisclosure();

  const hasProgressBilling = subProjects.some(
    (subProject) => subProject.billingType === SUB_PROJECT_BILLING_TYPE.PROGRESS,
  );

  const canCloseOutProject = !hasProgressBilling
    ? // On direct billing, we can create a final statement when billing has started when the project is ongoing
      isProjectActive(project) && project.cumulativeProgressPercentage !== 0
    : // On progress billing, we can create a final statement there is a final statement, and we can still create a final statement
      // i.e. billing is fully done when the project is ongoing
      isProjectActive(project) && finalStatements?.current && finalStatementCheckDTO.isFinalStatementAvailable;
  const hasPermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);

  if (!canCloseOutProject || !hasPermission) {
    return null;
  }

  return (
    <>
      <CloseProjectModal
        project={project}
        hasProgressBilling={hasProgressBilling}
        isOpen={modal.isOpen}
        onClose={modal.onClose}
      />

      <Button variant="outline" onClick={modal.onOpen}>
        {t('project:actions.close')}
      </Button>
    </>
  );
};
