import type { QuoteAddressApi, QuoteAddressUpdateDTO } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteAddressProxyApi {
  private static quoteAddressProxyApi: QuoteAddressProxyApi;

  private readonly quoteAddressApi: QuoteAddressApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteAddressApi = externalApi.getQuoteAddressApi();
  }

  public static getQuoteAddressProxyApi(externalApi: ExternalApi): QuoteAddressProxyApi {
    if (!this.quoteAddressProxyApi) {
      QuoteAddressProxyApi.quoteAddressProxyApi = new QuoteAddressProxyApi(externalApi);
    }
    return QuoteAddressProxyApi.quoteAddressProxyApi;
  }

  public async updateQuoteAddress(quoteAddressUpdateDTO: QuoteAddressUpdateDTO) {
    return proxyHelper(async () => this.quoteAddressApi.updateQuoteAddress(quoteAddressUpdateDTO));
  }
}
