import { Box, useTheme } from '@chakra-ui/react';

export const Cursor = () => {
  const { colors } = useTheme();
  const color = colors.blue[500];

  return (
    <Box h={0} position="relative" zIndex={10} pointerEvents="none">
      <Box
        bg={color}
        height="2px"
        shadow={`0 0 3px 0 ${color}`}
        position="absolute"
        top="50%"
        left={0}
        transform="translateY(-50%)"
        w="100%"
      />
    </Box>
  );
};
