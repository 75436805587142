import type { FC } from 'react';
import { useCallback } from 'react';
import { ActionMenu, BatiprixIcon, Button, GraneetIcon, SimpleAddIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { THIRD_PARTY } from '@graneet/business-logic';

import { useQuoteDisplayContext } from '../../hooks/useQuoteDisplayContext';

import { useThirdParty } from 'features/third-party/hooks/useThirdParty';

interface QuoteImportJobInLotProps {
  quoteLotId: number;

  variant?: 'default' | 'link';
}

export const QuoteImportJobInLotButton: FC<QuoteImportJobInLotProps> = ({ quoteLotId, variant = 'default' }) => {
  const { t } = useTranslation(['quote']);

  const hasBatiprix = useThirdParty(THIRD_PARTY.BATIPRIX);

  const { openLibraryJobImportDrawer, openBatiprixImportDrawer } = useQuoteDisplayContext();

  const onImportLibraryJob = useCallback(() => {
    openLibraryJobImportDrawer(quoteLotId);
  }, [openLibraryJobImportDrawer, quoteLotId]);

  const onImportBatiprix = useCallback(() => {
    openBatiprixImportDrawer(quoteLotId);
  }, [openBatiprixImportDrawer, quoteLotId]);

  const defaultButton = (
    <Button onClick={onImportLibraryJob} w="100%">
      {t('quote:jobsStep.importExistingJob')}
    </Button>
  );

  const linkButton = (
    <Button variant="outline" leftIcon={<SimpleAddIcon />} onClick={onImportLibraryJob}>
      {t('quote:jobsStep.importExistingJob')}
    </Button>
  );

  if (!hasBatiprix) {
    return variant === 'default' ? defaultButton : linkButton;
  }

  return (
    <ActionMenu
      offset={[10, 0]}
      w="100%"
      buttonProps={{
        leftIcon: null,
        width: 'full',
        variant: variant === 'default' ? 'primary' : 'secondary',
        children: t('quote:jobsStep.importExistingJob'),
      }}
    >
      <ActionMenu.Action label={t('quote:jobImportMenu.library')} icon={<GraneetIcon />} onClick={onImportLibraryJob} />
      <ActionMenu.Action label={t('quote:jobImportMenu.batiprix')} icon={<BatiprixIcon />} onClick={onImportBatiprix} />
    </ActionMenu>
  );
};
