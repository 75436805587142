import type { IContract } from '../contract/contract.type';
import type { IProject } from '../project/project.type';
import type { PDF_TEMPLATES } from '../pdf/pdf.type';
import type { PROGRESS_STATEMENT_SUMMARY_TEMPLATES } from '../progress-statement/progress-statement.constant';
import type { IHoldback } from '../holdback/holdback.type';
import type { IDeduction } from '../deduction/deduction.type';
import type { IProgressStatement } from '../progress-statement/progress-statement.type';
import type { ICredit } from '../credit/credit.type';
import type { IDownPayment } from '../down-payment/down-payment.type';
import type { IOrder } from '../order/order.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IVatBases, IVatDiscountedBases } from '../vat/vat-bases.type';
import type { IVatDistribution } from '../vat/vat-distribution.type';
import type { VAT_METHOD } from '../vat/vat-method.type';

export enum SUB_PROJECT_TYPES {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum SUB_PROJECT_BILLING_TYPE {
  PROGRESS = 'PROGRESS',
  DIRECT = 'DIRECT',
}

export const SUB_PROJECT_BILLING_TYPE_FILTERABLE: {
  readonly UNDETERMINED: 'UNDETERMINED';
  readonly PROGRESS: SUB_PROJECT_BILLING_TYPE.PROGRESS;
  readonly DIRECT: SUB_PROJECT_BILLING_TYPE.DIRECT;
} = {
  ...SUB_PROJECT_BILLING_TYPE,
  UNDETERMINED: 'UNDETERMINED',
} as const;

export interface ISubProject {
  id: string;

  name: string;

  note: string | null;

  hasPriceRevision: boolean | null;

  type: SUB_PROJECT_TYPES | null;

  progressStatementTemplate: PDF_TEMPLATES;

  summaryTemplate: PROGRESS_STATEMENT_SUMMARY_TEMPLATES;

  billingType: SUB_PROJECT_BILLING_TYPE | null;

  vatMethod: VAT_METHOD;

  amountExVAT: number;

  amountIncVAT: number;

  cumulativeProgressPercentage: number;

  amountToBeBilledExVAT: number;

  contractsVATDistribution: IVatDiscountedBases | IVatBases | IVatDistribution | null;

  hasReversalOfLiability: boolean;

  // the relation is required, but field is optional if fetch without relations
  project?: IProject;

  contracts?: IContract[];

  progressStatements?: IProgressStatement[];

  credits?: ICredit[];

  downPayments?: IDownPayment[];

  holdback?: IHoldback | null;

  deductions?: IDeduction[];

  directPaymentOrders?: IOrder[];

  createdAt: Date;

  updatedAt: Date;
}

export type ISubProjectWithRelations = RequiredByKeys<
  ISubProject,
  'project' | 'holdback' | 'deductions' | 'progressStatements'
>;
