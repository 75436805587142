import type { FC } from 'react';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { FEATURE_FLAGS, PERMISSION, PROJECT_STATUSES } from '@graneet/business-logic';
import { Placeholder, Tabs } from '@graneet/lib-ui';

import { DisplayNotFoundScreen } from '../DisplayNotFoundScreen';
import { ViewOrderDetailScreen } from '../purchases/orders/ViewOrderDetailScreen';

import { ViewProjectDownPaymentDetailScreen } from './_tabs/statements/down-payments/ViewProjectDownPaymentDetailScreen';
import { ViewStatementsScreen } from './_tabs/statements/ViewStatementsScreen';
import { ViewProgressStatementsDetailsScreen } from './_tabs/statements/progress-statements/ViewProgressStatementsDetailsScreen';
import { ViewProjectInvoiceDetailScreen } from './_tabs/statements/invoices/ViewProjectInvoiceDetailScreen';
import { ViewCreditDetailScreen } from './_tabs/statements/credits/ViewCreditDetailScreen';
import { ViewContractsScreen } from './_tabs/contracts/ViewContractsScreen';
import { ViewTimeTrackingScreen } from './_tabs/time-tracking/ViewTimeTrackingScreen';
import { ViewSubProjectScreen } from './_tabs/sub-projects/ViewSubProjectScreen';
import { ViewProjectClientSubTab } from './_tabs/information/ViewProjectClientSubTab';
import { ViewProjectSummarySubTab } from './_tabs/information/ViewProjectSummarySubTab';
import { ViewProjectBudgetsSubTab } from './_tabs/information/ViewProjectBudgetsSubTab';
import { ViewProjectInformationSubTab } from './_tabs/information/ViewProjectInformationSubTab';
import { ViewOrdersScreen } from './_tabs/purchases/orders/ViewOrdersScreen';
import { ProjetSupplierInvoicesRouter } from './_tabs/purchases/supplier-invoices/ProjetSupplierInvoicesRouter';
import { ViewDocumentsScreen } from './_tabs/documents/ViewDocumentsScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useContactAssociatedToProject } from 'features/contact/services/contact.api';
import { PROJECT_PLACEHOLDER_KEY } from 'features/project/constants/project.constant';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { ProjectStatusBadge } from 'features/project/components/ProjectStatusBadge';
import { useProject } from 'features/project/services/project.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const ViewProjectDetailScreen: FC = () => {
  const { t } = useTranslation(['project', 'global']);
  const { updateHeaderTitle } = useHeaderContext();

  const canSeeQuoteModule = usePermissions([PERMISSION.DISPLAY_QUOTES]);
  const canSeeSupplierInvoiceModule = usePermissions([PERMISSION.ACCESS_SUPPLIER_INVOICE]);
  const canSeeOrderModule = usePermissions([PERMISSION.ACCESS_ORDER]);
  const canAccessTimeTrackingModule = usePermissions([PERMISSION.ACCESS_TIME_TRACKING]);
  const canSeeStatementModule = usePermissions([PERMISSION.ACCESS_STATEMENTS]);

  const hasDirectorySystemProjectFeatureFlag = useFeatureFlag(FEATURE_FLAGS.DIRECTORY_SYSTEM_PROJECT);

  const { pathname } = useLocation();
  const { projectId: projectIdRaw } = useParams<{ projectId: string }>();
  const { path } = useRouteMatch();

  const project = useProject(Number(projectIdRaw));
  const projectContacts = useContactAssociatedToProject(Number(projectIdRaw));

  useLayoutEffect(() => {
    updateHeaderTitle(
      project.data.name ?? '',
      [
        {
          link: '/projects',
          name: t('global:nav.projects'),
        },
      ],
      <ProjectStatusBadge status={project.data.status ?? PROJECT_STATUSES.ON_GOING} />,
    );
  }, [t, updateHeaderTitle, pathname, project.data.name, project.data.status]);

  return (
    <>
      <Tabs
        hasInternalPadding
        fallbackPath="./information"
        errorComponent={<DisplayNotFoundScreen />}
        data={[
          {
            id: 'information',
            title: t('project:tabs.information'),
            path: './information',
            content: (
              <Tabs
                variant="badge"
                fallbackPath="./summary"
                errorComponent={<DisplayNotFoundScreen />}
                topRightContent={<Placeholder.Value name={PROJECT_PLACEHOLDER_KEY.INFORMATION_BUTTON} />}
                data={[
                  {
                    id: 'summary',
                    title: t('project:tabs.summary'),
                    path: './summary',
                    content: (
                      <QueryWrapper>
                        <ViewProjectSummarySubTab project={project.data} />
                      </QueryWrapper>
                    ),
                  },
                  {
                    id: 'budgets',
                    title: t('project:tabs.budgets'),
                    path: './budgets',
                    content: (
                      <QueryWrapper>
                        <ViewProjectBudgetsSubTab project={project.data} />
                      </QueryWrapper>
                    ),
                  },
                  {
                    id: 'details',
                    title: t('project:tabs.details'),
                    path: './details',
                    content: (
                      <QueryWrapper>
                        <ViewProjectInformationSubTab project={project.data} />
                      </QueryWrapper>
                    ),
                  },
                  {
                    id: 'client',
                    title: t('project:tabs.client'),
                    path: './client',
                    content: (
                      <QueryWrapper>
                        <ViewProjectClientSubTab project={project.data} client={project.data.primaryClient} />
                      </QueryWrapper>
                    ),
                  },
                ]}
              />
            ),
          },
          ...(canSeeQuoteModule
            ? [
                {
                  id: 'quotes',
                  title: t('project:tabs.quotes'),
                  path: './contracts',
                  content: (
                    <Switch>
                      <Route exact path={`${path}/contracts/sub-projects/:subProjectId`}>
                        <QueryWrapper>
                          <ViewSubProjectScreen />
                        </QueryWrapper>
                      </Route>

                      <Route exact path={`${path}/contracts`}>
                        <QueryWrapper>
                          <ViewContractsScreen project={project.data} />
                        </QueryWrapper>
                      </Route>
                    </Switch>
                  ),
                },
              ]
            : []),
          ...(canSeeStatementModule
            ? [
                {
                  id: 'sales',
                  title: t('project:tabs.sales'),
                  path: './statements',
                  content: (
                    <Switch>
                      <Route exact path={`${path}/statements`}>
                        <QueryWrapper>
                          <ViewStatementsScreen project={project.data} />
                        </QueryWrapper>
                      </Route>

                      <Route exact path={`${path}/statements/progress-statements/:progressStatementId`}>
                        <QueryWrapper>
                          <ViewProgressStatementsDetailsScreen
                            project={project.data}
                            projectContacts={projectContacts.data}
                          />
                        </QueryWrapper>
                      </Route>

                      <Route exact path={`${path}/statements/invoices/:invoiceId`}>
                        <QueryWrapper>
                          <ViewProjectInvoiceDetailScreen projectContacts={projectContacts.data} />
                        </QueryWrapper>
                      </Route>

                      <Route exact path={`${path}/statements/down-payments/:downPaymentId`}>
                        <QueryWrapper>
                          <ViewProjectDownPaymentDetailScreen
                            project={project.data}
                            projectContacts={projectContacts.data}
                          />
                        </QueryWrapper>
                      </Route>

                      <Route exact path={`${path}/statements/credits/:creditId`}>
                        <QueryWrapper>
                          <ViewCreditDetailScreen project={project.data} projectContacts={projectContacts.data} />
                        </QueryWrapper>
                      </Route>

                      <Route>
                        <Redirect to={`/projects/${project.data.id}/statements`} />
                      </Route>
                    </Switch>
                  ),
                },
              ]
            : []),
          ...(canSeeOrderModule || canSeeSupplierInvoiceModule
            ? [
                {
                  id: 'purchases',
                  title: t('project:tabs.purchases'),
                  path: './purchases',
                  content: (
                    <Tabs
                      variant="badge"
                      fallbackPath={canSeeOrderModule ? './orders' : './supplier-invoices'}
                      data={[
                        ...(canSeeOrderModule
                          ? [
                              {
                                id: 'orders',
                                title: t('project:tabs.orders'),
                                path: './orders',
                                content: (
                                  <Switch>
                                    <Route exact path={`${path}/purchases/orders`}>
                                      <QueryWrapper>
                                        <ViewOrdersScreen />
                                      </QueryWrapper>
                                    </Route>

                                    <Route exact path={`${path}/purchases/orders/:id`}>
                                      <QueryWrapper>
                                        <ViewOrderDetailScreen />
                                      </QueryWrapper>
                                    </Route>
                                  </Switch>
                                ),
                              },
                            ]
                          : []),
                        ...(canSeeSupplierInvoiceModule
                          ? [
                              {
                                id: 'supplierInvoices',
                                title: t('project:tabs.supplierInvoices'),
                                path: './supplier-invoices',
                                content: (
                                  <QueryWrapper>
                                    <ProjetSupplierInvoicesRouter project={project.data} />
                                  </QueryWrapper>
                                ),
                              },
                            ]
                          : []),
                      ]}
                    />
                  ),
                },
              ]
            : []),
          ...(canAccessTimeTrackingModule
            ? [
                {
                  id: 'timeTracking',
                  title: t('project:tabs.timeTracking'),
                  path: './time-tracking',
                  exact: true,
                  content: (
                    <QueryWrapper>
                      <ViewTimeTrackingScreen project={project.data} />
                    </QueryWrapper>
                  ),
                },
              ]
            : []),
          ...(hasDirectorySystemProjectFeatureFlag
            ? [
                {
                  id: 'documents',
                  title: t('project:tabs.documents'),
                  path: './documents',
                  exact: true,
                  content: <ViewDocumentsScreen />,
                },
              ]
            : []),
        ]}
      />
    </>
  );
};
