import type { FC } from 'react';

import type { GraneetButtonProps as ButtonProps } from '../../Button/Button';
import { GraneetButton as Button } from '../../Button/Button';

import { PopUpMenuItem, type PopUpMenuItemProps } from './PopUpMenuItem';

export type Mix = Omit<PopUpMenuItemProps, 'color'> & ButtonProps;

export interface PopUpButtonItemProps extends Mix {
  text: string;
}

export const PopUpButtonItem: FC<PopUpButtonItemProps> = ({ text, ...props }) => (
  <PopUpMenuItem>
    <Button {...props}>{text}</Button>
  </PopUpMenuItem>
);
