import { HStack } from '@chakra-ui/react';
import { QuotationInput, type MaskType, QuotationMaskedInput, useInputMaskInfos } from '@graneet/lib-ui';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { memo, useCallback, useMemo } from 'react';
import isNil from 'lodash-es/isNil';
import isEmpty from 'lodash-es/isEmpty';

import { Warning } from '../Warning';

export const TextCell = memo(
  (
    params: CustomCellRendererProps<QuoteNodeObject> & {
      mask?: MaskType;
      unit?: string;
      textAlign?: 'left' | 'right';
      indent?: boolean;
      mandatory?: boolean;
    },
  ) => {
    const { getValue, data, column, node, mask, textAlign, unit, indent } = params;
    const { placeholder, mask: imask } = useInputMaskInfos(mask ?? 'unit', {
      unit,
    });

    const isEditable = params.column?.isCellEditable(node);

    const value: number | undefined = useMemo(() => {
      if (getValue) {
        return getValue() ?? '';
      }
      return '';
    }, [getValue]);

    const emptyCb = useCallback(() => {}, []);

    const color = useMemo(() => {
      if (data?.content.type === 'QuoteSubItem' || data?.content.type === 'QuoteComponent') {
        return '#6C737F';
      }
      return '#1F2A37';
    }, [data]);

    if (
      (data?.content.type === 'QuoteLot' ||
        data?.content.type === 'QuoteComponent' ||
        data?.content.type === 'QuoteSubItem') &&
      column?.getColId() === 'vatRate'
    ) {
      return null;
    }

    if (
      isEditable &&
      params.mandatory &&
      (isNil(value) || isEmpty(value)) &&
      !(['QuoteComponent', 'QuoteSubItem'].includes(data?.content.type ?? '') && column?.getColId() === 'unit')
    ) {
      return <Warning align={textAlign === 'left' ? 'flex-start' : 'flex-end'} />;
    }

    return (
      <HStack
        width="100%"
        justifyContent="center"
        background="transparent"
        pl={indent ? `${node.level * 0.313}rem` : 2}
        pr={2}
        py="8px"
      >
        {mask ? (
          <QuotationMaskedInput
            isReadOnly
            color={color}
            textAlign={textAlign}
            value={value}
            onChange={emptyCb}
            onBlur={emptyCb}
            onKeyDownCapture={emptyCb}
            mask={imask}
            placeholder={placeholder}
            noStyle
          />
        ) : (
          <QuotationInput
            isReadOnly
            color={color}
            textAlign={textAlign}
            value={value}
            onChange={emptyCb}
            onBlur={emptyCb}
            onKeyDownCapture={emptyCb}
            noStyle
          />
        )}
      </HStack>
    );
  },
  (prevProps, nextProps) => {
    if (!prevProps && !nextProps) {
      return true;
    }
    if (!prevProps || !nextProps) {
      return false;
    }
    const oldValue = prevProps.getValue ? prevProps.getValue() : undefined;
    const newValue = nextProps.getValue ? nextProps.getValue() : undefined;
    return oldValue === newValue;
  },
);
