import type {
  IContractInfosResponseDTO,
  IContractResponseDTO,
  IContractUpdateDTO,
  IContractUpdateResponseDTO,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { CONTRACT_FACTORY_NAME } from './contract.factory-name';

import { invalidateStatementRelatedQueries } from 'features/common/services/invalidate-statement-related-queries.util';
import { apiNew } from 'features/api/services/apiNew.service';

const CONTRACTS_PATH = '/contracts';

export const contractKeyFactory = createQueryKeys(CONTRACT_FACTORY_NAME, {
  get: (id: number) => ({
    queryKey: [CONTRACTS_PATH, id],
    queryFn: () => apiNew.get<never, IContractInfosResponseDTO>(`${CONTRACTS_PATH}/${id}`),
  }),
  getContractsBySubProject: (subProjectId: string) => ({
    queryKey: [CONTRACTS_PATH, 'subprojects', subProjectId],
    queryFn: () => apiNew.get<{ subProjectId: string }, IContractResponseDTO[]>(CONTRACTS_PATH, { subProjectId }),
  }),
});

export function useContractsBySubProject(subProjectId: string) {
  return useSuspenseQuery(contractKeyFactory.getContractsBySubProject(subProjectId));
}

export function useContract(id: number) {
  return useSuspenseQuery({
    ...contractKeyFactory.get(id),
    gcTime: 0, // To avoid issue with initialTree
    // Because of caching, a first tree render is done with old value, when new data is loaded, tree is not re-rendered
    // Setting 0 to gcTime avoids caching mecanism
  });
}

export function useUpdateContract() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IContractUpdateDTO }) =>
      apiNew.patch<IContractUpdateDTO, IContractUpdateResponseDTO>(
        `${CONTRACTS_PATH}/${params.id}/container`,
        params.dto,
      ),
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useDeleteContract() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'contracts']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, void>(`${CONTRACTS_PATH}/${id}`),
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('contracts:deleteModal.success'));
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}
