import type { FC } from 'react';
import { useCallback } from 'react';
import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';
import { isNumberFinite } from '@graneet/lib-ui';

interface IsHigherOrEqualThanProps {
  amount: number;

  message: string;
}

export const IsHigherOrEqualThan: FC<IsHigherOrEqualThanProps> = ({ amount, message }) => {
  const validationFn = useCallback(
    (value: FieldValue): boolean => {
      if (!isNumberFinite(value)) {
        return true;
      }

      return value >= amount;
    },
    [amount],
  );

  return <Rule message={message} validationFn={validationFn} />;
};
