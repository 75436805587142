import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';

dayjs.extend(localizedFormat);
dayjs.extend(localeData);

export const changeDayJSLocale = (newLocale: string | ILocale) => {
  dayjs.locale(newLocale);
};
