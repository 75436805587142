import type { IInitialTree, LeafWithRelations, TreeContextApi } from '@graneet/lib-ui';
import { useLeaf, useNode, useTree, useTreeContext, useNodeComputedValue } from '@graneet/lib-ui';
import type { IContractResponseDTO, IItemResponseDTO, ILotResponseDTO } from '@graneet/business-logic';
import { useMemo } from 'react';

import type { ContractId, LotId } from '../services/progress-statement-tree.util';

export type ILotTree = Omit<ILotResponseDTO, 'id'> & { id: LotId; parentNodeId: LotId | ContractId | null };
export type IContractTree = Omit<IContractResponseDTO, 'id'> & { id: ContractId; parentNodeId: LotId };
export type IItemTree = IItemResponseDTO & { cumulativeAmountExVAT: number; progressPercentage: number };
export type InitialProgressStatementTree = IInitialTree<ILotTree | IContractTree, IItemTree>;

export interface LotTreeComputedValue {
  cumulativeAmountExVAT: number;

  itemProgressPercentages: Set<number>;
}

export interface ItemTreeComputedValue {}

export type ProgressStatementTreeContext = TreeContextApi<
  ILotTree | IContractTree,
  IItemTree,
  LotTreeComputedValue,
  ItemTreeComputedValue
>;

export const useProgressStatementTree = (initialTree: InitialProgressStatementTree): ProgressStatementTreeContext =>
  useTree<ILotTree | IContractTree, IItemTree, LotTreeComputedValue, ItemTreeComputedValue>(
    initialTree,
    useMemo(
      () => ({
        computeLeafComputedValue: () => ({}),
        computeNodeComputedValue: (lotOrContract, treeContext) => {
          const currentTree = treeContext.getCurrentTree();
          const computedValues = treeContext.getComputedValues();

          const relations = currentTree.relations[lotOrContract.id];

          // cumulativeAmountExVAT
          const subLotAmounts = relations.nodes.reduce(
            (acc, subLotId) => acc + computedValues.nodes[subLotId].cumulativeAmountExVAT,
            0,
          );
          const itemAmounts = relations.leaves.reduce(
            (acc, itemId) =>
              currentTree.leaves[itemId].isOptional ? acc : acc + currentTree.leaves[itemId].cumulativeAmountExVAT,
            0,
          );
          const cumulativeAmountExVAT = subLotAmounts + itemAmounts;

          // itemProgressPercentages
          const itemProgressPercentages = new Set<number>();
          currentTree.relations[lotOrContract.id].leaves.forEach((itemId) => {
            itemProgressPercentages.add(currentTree.leaves[itemId].progressPercentage);
          });
          const lotIds = relations.nodes;
          lotIds.forEach((subLotId) => {
            computedValues.nodes[subLotId].itemProgressPercentages.forEach((progressPercentage) => {
              itemProgressPercentages.add(progressPercentage);
            });
          });

          return {
            cumulativeAmountExVAT,
            itemProgressPercentages,
          };
        },
      }),
      [],
    ),
    useMemo(
      () => ({
        defaultNodeExpandedState:
          Object.keys(initialTree.nodes).length + Object.keys(initialTree.leaves).length > 250 ? 'close' : 'open',
      }),
      [initialTree],
    ),
  );

export const useProgressStatementTreeContext = () =>
  useTreeContext<ILotTree | IContractTree, IItemTree, LotTreeComputedValue, ItemTreeComputedValue>();

export const useProgressStatementLot = (id: LotId) => useNode<ILotTree, IItemTree>(id);
export const useProgressStatementContract = (id: ContractId) => useNode<IContractTree, never>(id);

export type ProgressStatementTreeItem = LeafWithRelations<ILotTree | IContractTree, IItemTree>;
export const useProgressStatementItem = (id: number) => useLeaf<ILotTree | IContractTree, IItemTree>(id);

export const useProgressStatementLotOrContainerComputedValue = (id: LotId | ContractId) =>
  useNodeComputedValue<ILotTree | IContractTree, LotTreeComputedValue>(id);
