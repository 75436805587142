import type { ReactElement } from 'react';
import type { BadgeGroupFieldProps, KeysMatching } from '@graneet/lib-ui';
import { BadgeGroupField } from '@graneet/lib-ui';
import type { FieldValues } from 'graneet-form';

import { formatUserDisplayName } from '../services/user.util';
import { useDisplayedUsers } from '../services/user.api';

type UserBadgeGroupFieldValue = number[] | undefined | null;

interface UserBadgeGroupFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, UserBadgeGroupFieldValue> = KeysMatching<T, UserBadgeGroupFieldValue>,
> extends Omit<BadgeGroupFieldProps<T, K>, 'children'> {
  name: K;
}

export const UserBadgeGroupField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, UserBadgeGroupFieldValue> = KeysMatching<T, UserBadgeGroupFieldValue>,
>({
  name,
  ...otherProps
}: UserBadgeGroupFieldProps<T, K>): ReactElement | null => {
  const users = useDisplayedUsers();

  return (
    <BadgeGroupField<T, K> name={name} {...otherProps}>
      {users.data.map((user) => (
        <BadgeGroupField.Option<number> key={user.id} value={user.id} label={formatUserDisplayName(user)} />
      ))}
    </BadgeGroupField>
  );
};
