import type { QuoteHiddenCostItemUpsertDTO } from '@org/graneet-bff-client';
import type { QuoteNode } from '@org/quotation-lib';

import { mapQuantityFormulaObjectToQuantityFormulaDTO } from './quoteQuantityFormulaObjectToQuantityFormulaDTO';
import { mapMarginObjectToMarginDTO } from './quoteMarginObjectToMarginDTO';
import { genericUpdateItemMapper } from './quoteItemsGenericMapper';

export const mapQuoteHiddenCostItemToUpdateQuantityFormula = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteHiddenCostItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteHiddenCostItem' && quoteNodeObject.parentId) {
    return {
      quantityFormula: mapQuantityFormulaObjectToQuantityFormulaDTO(content.quantityFormula),
      quoteHiddenCostItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content is not QuoteHiddenCostItem');
};

export const mapQuoteHiddenCostItemToUpdateUnitFlastCostAmount = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteHiddenCostItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteHiddenCostItem' && quoteNodeObject.parentId) {
    return {
      unitFlatCostAmount: content.unitFlatCostAmount,
      quoteHiddenCostItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteHiddenCostItem');
};

export const mapQuoteNodeToUpsertHiddenCostItemDTO = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteHiddenCostItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteHiddenCostItem' && quoteNodeObject.parentId) {
    return {
      denomination: content.denomination,
      manualIndex: content.manualIndex,
      refCode: content.refCode,
      note: content.note,
      unit: content.unit,
      vatRate: content.vatRate,
      amount: content.amount,
      unitFlatCostAmount: content.unitFlatCostAmount,
      unitAmount: content.unitAmount,
      flatCostAmount: content.flatCostAmount,
      quantityFormula: mapQuantityFormulaObjectToQuantityFormulaDTO(content.quantityFormula),
      margin: mapMarginObjectToMarginDTO(content.margin),
      quoteHiddenCostItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content is not QuoteHiddenCostItem');
};
