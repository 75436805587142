import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { Identifiable } from '../types/Identifiable';

export type TreeComponentsContextApi<Node extends Identifiable, Leaf extends Identifiable> = {
  nodeWrapperComponent: FC<{ id: Node['id']; depth: number; children: ReactNode }> | undefined;

  nodeComponent: FC<{ id: Node['id']; depth: number }>;

  leafComponent: FC<{ id: Leaf['id']; depth: number }>;

  virtualizedLeafComponent?: FC<{ id: Leaf['id']; depth: number }>;

  separatorComponent: FC;
};

export const TreeComponentsContext = createContext<
  TreeComponentsContextApi<
    {
      id: number | string;
      parentNodeId: number | string;
      previousNodeId: number | string | null;
    },
    {
      id: number | string;
      parentNodeId: number | string;
      previousLeafId: number | string | null;
    }
  >
>({
  nodeWrapperComponent: () => null,
  nodeComponent: () => null,
  leafComponent: () => null,
  virtualizedLeafComponent: undefined,
  separatorComponent: () => null,
});

export const useTreeComponentsContext = () => useContext(TreeComponentsContext);
