import { GraneetButton as Button, useCurrency, useLocalStorage } from '@graneet/lib-ui';
import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';
import type { AgGridReact } from '@ag-grid-community/react';
import type { FC, RefObject } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TRACKING_EVENT_ENUM } from '@graneet/business-logic';
import dayjs from 'dayjs';
import { HStack } from '@chakra-ui/react';

import { useStore } from 'store/store';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';

interface ComponentsSupplyDrawerActionProps {
  gridRef: RefObject<AgGridReact<QuoteComponentsSupplyObject>>;
  quoteName: string | null;
  rowsSelected: unknown[];
}

export const ComponentsSupplyDrawerActions: FC<ComponentsSupplyDrawerActionProps> = ({
  gridRef,
  quoteName,
  rowsSelected,
}) => {
  const { t } = useTranslation(['quote']);
  const { mapStringNumberToAmount } = useCurrency();

  const editorRef = useStore((state) => state.quoteDrawerRichTextEditorRef);
  const segmentAnalytics = useSegmentAnalytics();

  const getPlainText = useCallback(
    (v: string | null | undefined) =>
      editorRef?.current?.getPlainText ? (editorRef?.current?.getPlainText(v) ?? null) : null,
    [editorRef],
  );

  const [, persistInfos] = useLocalStorage<unknown>('importedComponents', '');

  const handleCreateOrderFromComponents = useCallback(() => {
    // Tracking event for creating order from quote components supply sheet
    segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.ORDER_ITEMS_IMPORT, { type: 'quote-components-from-quote' });

    if (gridRef.current) {
      persistInfos(rowsSelected);
      window.open(`/purchases/orders/create`);
    }
  }, [gridRef, persistInfos, rowsSelected, segmentAnalytics]);

  const handleClickExport = useCallback(() => {
    // Tracking event for exporting components supply to excel
    segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.SUPPLY_SHEET_EXPORTED, null);
    gridRef.current?.api.exportDataAsExcel({
      onlySelected: true,
      fileName: `${t('quote:quotation.modals.supply.title')}-${quoteName}-${dayjs().format('DD/MM/YYYY')}`,
      processCellCallback(params) {
        let { value } = params;
        if (params.column.getColId() === 'denomination') {
          value = getPlainText(params.value);
        }
        if (params.column.getColId() === 'unitFlatCostAmount' || params.column.getColId() === 'flatCostAmount') {
          value = mapStringNumberToAmount(value);
        }

        if (!value) {
          value = '-';
        }
        return value;
      },
    });
  }, [getPlainText, gridRef, quoteName, segmentAnalytics, mapStringNumberToAmount, t]);

  return (
    <HStack gap={2}>
      <Button variant="primary" isDisabled={rowsSelected.length === 0} onClick={handleCreateOrderFromComponents}>
        {t('quote:quotation.modals.supply.buttons.order')}
      </Button>
      <Button variant="primary" onClick={handleClickExport} isDisabled={rowsSelected.length === 0}>
        {t('quote:quotation.modals.supply.buttons.excelExport')}
      </Button>
    </HStack>
  );
};
