import { useTranslation } from 'react-i18next';
import type { IQuote } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { useCallback, type FC } from 'react';
import { Card } from '@graneet/lib-ui';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { getAvailableQuoteTags, patchQuoteTags } from 'features/quote/services/quote.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useData } from 'features/api/hooks/useData';
import { useDataGetter } from 'features/api/hooks/useDataGetter';

interface QuoteCommentCardProps {
  quote: IQuote;
}

export const QuoteTagsCard: FC<QuoteCommentCardProps> = ({ quote }) => {
  const { t } = useTranslation(['global']);
  const hasCreateQuotesPermission = usePermissions([PERMISSION.CREATE_QUOTES]);
  const { data: tags } = useData(useDataGetter(getAvailableQuoteTags));

  const onTagsSaved = useCallback((newTags: string[]) => patchQuoteTags(quote.id, { tags: newTags }), [quote.id]);

  if (!quote.tags && !hasCreateQuotesPermission) {
    return null;
  }

  return (
    <Card title={t('global:words.privateTags')}>
      <TagsEditionBlock
        placeholder={t('global:changeTags.placeholder')}
        tags={quote.tags}
        suggestedTags={tags ?? []}
        onTagsEdited={onTagsSaved}
        isEditable={hasCreateQuotesPermission}
      />
    </Card>
  );
};
