import type { FC } from 'react';
import { useCallback } from 'react';
import type { IProject } from '@graneet/business-logic';
import { PERMISSION, isProjectClosed } from '@graneet/business-logic';
import { Button, Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Text, useDisclosure } from '@chakra-ui/react';

import { useProjectReopen } from '../services/project.api';

import { usePermissions } from 'features/role/hooks/usePermissions';

interface ProjectReopenButtonProps {
  project: IProject;
}

export const ProjectReopenButton: FC<ProjectReopenButtonProps> = ({ project }) => {
  const { t } = useTranslation(['project']);
  const modal = useDisclosure();
  const hasPermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);

  const projectReopenMutation = useProjectReopen();

  const handleSubmit = useCallback(() => {
    projectReopenMutation.mutate(project, {
      onSuccess: () => {
        modal.onClose();
      },
    });
  }, [modal, project, projectReopenMutation]);

  if (!isProjectClosed(project) || !hasPermission) {
    return null;
  }

  return (
    <>
      <Modal size="3xl" isOpen={modal.isOpen} onClose={modal.onClose} autoFocus={false}>
        <Text mt="4">{t('project:summary.reopen.modal.information')}</Text>

        <Modal.SecondaryButton onClick={modal.onClose}>{t('project:summary.close.modal.back')}</Modal.SecondaryButton>

        <Modal.PrimaryButton isLoading={projectReopenMutation.isPending} onClick={handleSubmit}>
          {t('project:actions.reopen')}
        </Modal.PrimaryButton>
      </Modal>

      <Button variant="outline" onClick={modal.onOpen}>
        {t('project:actions.reopen')}
      </Button>
    </>
  );
};
