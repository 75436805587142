import type { FC } from 'react';
import { useCallback } from 'react';
import { Stack, VStack, useDisclosure } from '@chakra-ui/react';
import type {
  IProgressStatement,
  IProgressStatementResponseDTO,
  IProjectWithRelations,
  IContactWithProjectInfos,
  IGroupedContact,
} from '@graneet/business-logic';
import {
  getEmailVariableData,
  isProgressStatementCancelled,
  filterOutCancelledProgressStatements,
  STATEMENT_TYPES,
  isSubProjectInDirectBilling,
  HISTORY_ENTITY_TYPE,
} from '@graneet/business-logic';
import { TwoColumns, ConfirmDeletionModal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { useProgressStatementDelete, useProgressStatementVersions } from '../services/progress-statement.api';
import { getPublicLinkFromProgressStatement } from '../services/external-progress-statement.util';

import { ProgressStatementTagsCard } from './cards/ProgressStatementTagsCard';
import { DirectProgressStatementStatusCard } from './cards/DirectProgressStatementStatusCard/DirectProgressStatementStatusCard';
import { ProgressStatementStatusCard } from './cards/ProgressStatementStatusCard';
import { ProgressStatementDetailCard } from './cards/ProgressStatementDetailCard';

import { ProjectAssociationCard } from 'features/project/components/cards/ProjectAssociationCard';
import { CreditLinkedStatementCard } from 'features/credit/components/cards/CreditLinkedStatementCard';
import { PaymentMethodDetailCard } from 'features/payment-method/components/PaymentMethodDetailCard';
import { ClientCard } from 'features/client/components/cards/ClientCard';
import { useEmailTemplateRichTextConfiguration } from 'features/email-template/hooks/useEmailTemplateRichTextConfiguration';
import { EmailProvider } from 'features/history/contexts/EmailContext';
import { HistoryCard } from 'features/history/components/HistoryCard';
import { useSubProjectDownPayment } from 'features/sub-project/services/sub-project.api';

interface ProgressStatementDetailProps {
  progressStatement: IProgressStatementResponseDTO;
  progressStatements: IProgressStatement[];
  project: IProjectWithRelations;
  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
  nextProgressStatement?: IProgressStatement;
  formatUrl(progressStatement: IProgressStatement): string;
  onGoBack(): void;
  onDeleted(): void;
  onCancelConfirmed(createDraft: boolean): void;
  displayAssociatedProject?: boolean;
  displayPaymentTerms?: boolean;
  displayPrimaryClient?: boolean;
}

export const ProgressStatementDetail: FC<ProgressStatementDetailProps> = ({
  progressStatement,
  progressStatements,
  project,
  projectContacts,
  nextProgressStatement,
  formatUrl,
  onDeleted,
  onCancelConfirmed,
  displayAssociatedProject = false,
  displayPaymentTerms = false,
  displayPrimaryClient = false,
}) => {
  const { t } = useTranslation(['global', 'statement']);

  const emailTemplateRichTextConfiguration = useEmailTemplateRichTextConfiguration(
    getEmailVariableData('progressStatement', {
      progressStatement,
      client: project.primaryClient,
      project,
      publicLink: getPublicLinkFromProgressStatement(progressStatement),
    }),
  );

  const downPayment = useSubProjectDownPayment(progressStatement.subProject?.id ?? null);
  const progressStatementVersions = useProgressStatementVersions(progressStatement.id);

  const progressStatementDeleteMutation = useProgressStatementDelete();

  const deletionModal = useDisclosure();

  const isDirectProgressStatement = isSubProjectInDirectBilling(progressStatement.subProject);

  const deleteProgressStatement = useCallback(async () => {
    await progressStatementDeleteMutation.mutateAsync(progressStatement, {
      onSuccess: onDeleted,
    });
  }, [onDeleted, progressStatement, progressStatementDeleteMutation]);

  const shouldDisplayCreditCard = isProgressStatementCancelled(progressStatement) && !!progressStatement.credit;

  return (
    <EmailProvider>
      <TwoColumns>
        <VStack alignItems="stretch" justifyContent="stretch" spacing={4}>
          {isDirectProgressStatement ? (
            <DirectProgressStatementStatusCard
              formatUrl={formatUrl}
              progressStatement={progressStatement}
              project={project}
              projectContacts={projectContacts}
              downPayment={downPayment.data}
              onCancelConfirmed={onCancelConfirmed}
              currentVersion={progressStatementVersions.data.current}
              openDeletionModal={deletionModal.onOpen}
            />
          ) : (
            <ProgressStatementStatusCard
              formatUrl={formatUrl}
              progressStatement={progressStatement}
              currentVersion={progressStatementVersions.data.current}
              project={project}
              projectContacts={projectContacts}
              notCancelledProgressStatements={filterOutCancelledProgressStatements(progressStatements)}
              downPayment={downPayment.data}
              nextProgressStatement={nextProgressStatement}
              onCancelConfirmed={onCancelConfirmed}
              openDeletionModal={deletionModal.onOpen}
            />
          )}
          {shouldDisplayCreditCard && (
            <CreditLinkedStatementCard
              statementType={STATEMENT_TYPES.CREDIT}
              statement={progressStatement.credit!}
              projectId={project.id}
            />
          )}
        </VStack>
        <ProgressStatementDetailCard progressStatement={progressStatement} subProject={progressStatement.subProject} />
      </TwoColumns>

      <HistoryCard
        id={progressStatement.id}
        entity={HISTORY_ENTITY_TYPE.PROGRESS_STATEMENT}
        emailTemplateRichTextConfiguration={emailTemplateRichTextConfiguration}
        cardPosition={{ mt: 4 }}
      />

      {(displayPrimaryClient || displayAssociatedProject || displayPaymentTerms) && (
        <TwoColumns mt={4}>
          {displayPrimaryClient && (
            <>
              <Stack gap={4}>
                <ClientCard client={project.primaryClient} type={null} />
                <ProgressStatementTagsCard progressStatement={progressStatement} />
              </Stack>
              <Stack spacing={4}>
                {displayPaymentTerms && (
                  <PaymentMethodDetailCard
                    paymentMethod={project.paymentMethod}
                    paymentTerm={project.paymentTerm}
                    deadlineType={project.deadlineType}
                  />
                )}
                {displayAssociatedProject && (
                  <ProjectAssociationCard project={project} subProject={progressStatement.subProject} />
                )}
              </Stack>
            </>
          )}

          {!displayPrimaryClient && (
            <>
              {displayAssociatedProject && (
                <ProjectAssociationCard project={project} subProject={progressStatement.subProject} />
              )}
              {displayPaymentTerms && (
                <PaymentMethodDetailCard
                  paymentMethod={project.paymentMethod}
                  paymentTerm={project.paymentTerm}
                  deadlineType={project.deadlineType}
                />
              )}
            </>
          )}
        </TwoColumns>
      )}

      <ConfirmDeletionModal
        title={t('statement:delete.title')}
        action={t('statement:delete.action')}
        description={t('statement:delete.confirmationMessage')}
        onDeleted={deleteProgressStatement}
        {...deletionModal}
      />
    </EmailProvider>
  );
};
