import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@chakra-ui/react';
import type { IOrderResponseDTO } from '@graneet/business-logic';
import { Card, LabeledData, Price } from '@graneet/lib-ui';
import { isNil } from 'lodash-es';

import { OrderVATRatesLabel } from '../OrderVATRatesLabel';

type OrderAmountCardProps = {
  order: IOrderResponseDTO;
};

export const OrderAmountCard: FC<OrderAmountCardProps> = ({ order }) => {
  const { t } = useTranslation(['orders']);

  const { amountExVAT, amountIncVAT, vatDistribution, vatType } = order;

  return (
    <Card title={t('orders:orderAmountCard.title')}>
      <Grid templateColumns={{ base: '1fr', xl: 'repeat(3, 1fr)' }} gap={6} pb={6}>
        <LabeledData label={t('orders:informationsCard.amountExVAT')} size="md" data={<Price amount={amountExVAT} />} />
        <OrderVATRatesLabel vatDistribution={vatDistribution} vatType={vatType} />
        <LabeledData
          label={t('orders:informationsCard.amountIncVAT')}
          size="md"
          data={<Price amount={isNil(amountExVAT) || vatDistribution.length === 0 ? null : amountIncVAT} />}
        />
      </Grid>
    </Card>
  );
};
