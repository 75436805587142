import type { FC } from 'react';
import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { IPaymentMethodResponseDTO } from '@graneet/business-logic';

import { PaymentMethodEditionCard } from './PaymentMethodEditionCard';

interface PaymentMethodCardListProps extends BoxProps {
  paymentMethods: IPaymentMethodResponseDTO[];

  hasUpdatePermission: boolean;

  onPaymentMethodUpdated: () => void;
}

export const PaymentMethodCardList: FC<PaymentMethodCardListProps> = ({
  paymentMethods,
  hasUpdatePermission,
  onPaymentMethodUpdated,
  ...props
}) => (
  <Box {...props}>
    {paymentMethods.map((paymentMethod, i) => (
      <PaymentMethodEditionCard
        key={paymentMethod.id}
        paymentMethod={paymentMethod}
        hasUpdatePermission={hasUpdatePermission}
        mt={i === 0 ? 0 : 6}
        onPaymentMethodUpdated={onPaymentMethodUpdated}
      />
    ))}
  </Box>
);
