import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const PuzzleIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 25 16" {...props}>
    <svg width="25" height="16" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
      <g id="puzzle">
        <g>
          <path
            fill="#DCF4FF"
            d="m9.60716,1.60706l0,-1.60707l-5.33339,0l0,5.33337l-1.60708,0c-1.47278,0 -2.66668,1.1939 -2.66668,2.66669l12.27385,1.41281l12.2738,-1.41281c0,-1.47279 -1.1939,-2.66669 -2.6667,-2.66669l-1.6071,0l0,-5.33337l-5.3333,0l0,1.60707c0,1.47279 -1.1939,2.66669 -2.6667,2.66669c-1.4728,0 -2.6667,-1.1939 -2.6667,-2.66669z"
          />
        </g>
        <g>
          <path
            fill="#DCF4FF"
            d="m2.69015,10.55646l1.60708,0l0,5.33337l5.33339,0l0,-1.60708c0,-1.47278 1.1939,-2.66668 2.6667,-2.66668c1.4728,0 2.6667,1.1939 2.6667,2.66668l0,1.60708l5.3333,0l0,-5.33337l1.6071,0c1.4728,0 2.6667,-1.1939 2.6667,-2.66669l-24.54765,0c0,1.47279 1.1939,2.66669 2.66668,2.66669z"
          />
        </g>
      </g>
    </svg>
  </Icon>
);
