import { PROGRESS_STATEMENT_STATUSES } from '@graneet/business-logic';
import type { DocumentStatusCardAction, DocumentStatusCardIconValue } from '@graneet/lib-ui';
import { compact } from 'lodash-es';

import {
  getCancelAction,
  getCompleteAction,
  getDeleteAction,
  getDownloadPDFAction,
  getEditAction,
  getOpenMailingAction,
  getPreviewPDFAction,
} from './direct-progress-statement.actions.util';
import type { DirectProgressStatementActionsParams } from './direct-progress-statement.actions.type';

function getDirectProgressStatementDraftActions(
  params: DirectProgressStatementActionsParams,
): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];
  const previewPDFAction = getPreviewPDFAction(params);

  actions.push({ type: 'primary', ...getCompleteAction(params) });

  if (previewPDFAction) {
    actions.push({ type: 'secondary', ...previewPDFAction });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([
      getEditAction(params),
      getOpenMailingAction(params),
      getDownloadPDFAction(params),
      getDeleteAction(params),
    ]),
  });

  return actions;
}

function getDirectProgressStatementCompletedActions(
  params: DirectProgressStatementActionsParams,
): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];
  const viewPdfAction = getPreviewPDFAction(params);

  if (viewPdfAction) {
    actions.push({ type: 'secondary', ...viewPdfAction });
  }

  actions.push({ type: 'secondary', ...getCancelAction(params, true) });

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([getCancelAction(params, false), getOpenMailingAction(params), getDownloadPDFAction(params)]),
  });

  return actions;
}

function getDirectProgressStatementCancelledActions(
  params: DirectProgressStatementActionsParams,
): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];
  const openMailingAction = getOpenMailingAction(params);
  const viewPdfAction = getPreviewPDFAction(params);

  if (viewPdfAction) {
    actions.push({ type: 'secondary', ...viewPdfAction });
  }

  if (openMailingAction) {
    actions.push({ type: 'secondary', ...openMailingAction });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([getDownloadPDFAction(params)]),
  });

  return actions;
}

export function getDirectProgressStatementActions(
  params: DirectProgressStatementActionsParams,
): DocumentStatusCardAction[] {
  const { progressStatement } = params;

  switch (progressStatement.status) {
    case PROGRESS_STATEMENT_STATUSES.DRAFT:
      return getDirectProgressStatementDraftActions(params);
    case PROGRESS_STATEMENT_STATUSES.COMPLETED:
      return getDirectProgressStatementCompletedActions(params);
    case PROGRESS_STATEMENT_STATUSES.CANCELLED:
      return getDirectProgressStatementCancelledActions(params);
    case PROGRESS_STATEMENT_STATUSES.VALIDATED:
    case PROGRESS_STATEMENT_STATUSES.ACCEPTED:
      throw new Error('Not implemented for direct progress statement');
    default:
      progressStatement.status satisfies never;
      throw new Error('Unknown status');
  }
}

export function getDirectProgressStatementStatusCardIcon(
  status: PROGRESS_STATEMENT_STATUSES,
): DocumentStatusCardIconValue {
  switch (status) {
    case PROGRESS_STATEMENT_STATUSES.DRAFT:
      return 'proforma';
    case PROGRESS_STATEMENT_STATUSES.COMPLETED:
      return 'completed';
    case PROGRESS_STATEMENT_STATUSES.CANCELLED:
      return 'cancelled';
    case PROGRESS_STATEMENT_STATUSES.VALIDATED:
    case PROGRESS_STATEMENT_STATUSES.ACCEPTED:
      throw new Error('Not implemented for direct progress statement');
    default:
      status satisfies never;
      throw new Error('Unknown status');
  }
}
