import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleAddRowIcon: FC<IconProps> = (props) => (
  <Icon width="14" height="14" viewBox="0 0 14 14" fill="none" strokeWidth="1.25" stroke="#6C737F" {...props}>
    <path
      d="M10.3833 6.83333C11.0367 6.83333 11.3635 6.83333 11.613 6.70617C11.8325 6.59432 12.011 6.41585 12.1228 6.19632C12.25 5.94676 12.25 5.62006 12.25 4.96667V4.61667C12.25 3.96327 12.25 3.63657 12.1228 3.38701C12.011 3.16749 11.8325 2.98901 11.613 2.87716C11.3635 2.75 11.0367 2.75 10.3833 2.75H3.61667C2.96327 2.75 2.63657 2.75 2.38701 2.87716C2.16749 2.98901 1.98901 3.16749 1.87716 3.38701C1.75 3.63657 1.75 3.96327 1.75 4.61667V4.96667C1.75 5.62006 1.75 5.94676 1.87716 6.19632C1.98901 6.41585 2.16749 6.59432 2.38701 6.70617C2.63657 6.83333 2.96327 6.83333 3.61667 6.83333H10.3833Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7 12.666V9.16602M5.25 10.916H8.75" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);
