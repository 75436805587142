import { useTranslation } from 'react-i18next';
import type { IQuote } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import type { FC } from 'react';

import { TextEditionCard } from 'features/common/components/TextEditionCard';
import { patchQuoteComment } from 'features/quote/services/quote.api';
import { usePermissions } from 'features/role/hooks/usePermissions';

interface QuoteCommentCardProps {
  quote: IQuote;
  reloadQuote: () => void;
}

export const QuoteCommentCard: FC<QuoteCommentCardProps> = ({ quote, reloadQuote }) => {
  const { t } = useTranslation(['quote']);
  const hasCreateQuotesPermission = usePermissions([PERMISSION.CREATE_QUOTES]);

  const onCommentSaved = async (comment: string) => {
    const data = await patchQuoteComment(quote.id, { comment });
    reloadQuote();
    return data;
  };

  if (!quote.comment && !hasCreateQuotesPermission) {
    return null;
  }

  return (
    <TextEditionCard
      title={t('quote:fields.comment')}
      errorLabel={t('quote:changeComment.error')}
      successLabel={t('quote:changeComment.success')}
      placeholder={t('quote:changeComment.placeholder')}
      text={quote.comment}
      onTextEdited={onCommentSaved}
      isEditable={hasCreateQuotesPermission}
    />
  );
};
