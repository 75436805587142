import type { FC } from 'react';
import type { FlexProps, StyleProps } from '@chakra-ui/react';
import { Flex, IconButton } from '@chakra-ui/react';

import { useChakraColors } from '../../hooks';
import { SimpleCloseIcon } from '../Icons';

import type { FileItem } from './FileNameDetails';
import { FileNameDetails } from './FileNameDetails';

export interface FileNameChipProps extends FlexProps {
  file: FileItem;
  readOnly: boolean;
  bgColor?: string;
  showDetails?: boolean;
  showCloseButtonOnHover?: boolean;
  width?: StyleProps['width'];
  onOpen?: (id: string) => void;
  onDelete?: (id: string) => void;
}

export const FileNameChip: FC<FileNameChipProps> = ({
  file,
  readOnly = false,
  showDetails = false,
  showCloseButtonOnHover = false,
  bgColor,
  width,
  onDelete,
  onOpen,
  ...props
}) => {
  const { borderGray, textGray } = useChakraColors({
    borderGray: 'gray.200',
    textGray: 'gray.600',
  });

  return (
    <Flex
      py={2}
      px={3}
      {...(width ? { width } : { maxW: '15rem' })}
      cursor="pointer"
      display="inline-flex"
      justifyContent="left"
      alignItems="center"
      borderRadius={4}
      bg={bgColor || 'white'}
      color={textGray}
      border={`1px solid ${borderGray}`}
      transitionDuration="0.2s"
      className="file-box"
      onClick={() => {
        if (onOpen) {
          onOpen(file.id);
        }
      }}
      {...props}
    >
      <FileNameDetails file={file} showDetails={showDetails} width={width} />

      {!readOnly && (
        <IconButton
          icon={<SimpleCloseIcon boxSize={2} stroke={textGray} _hover={{ stroke: 'greenBrand.light' }} />}
          fontSize={20}
          variant="clear"
          height="auto"
          aria-label="Remove"
          alignSelf="flex-start"
          justifySelf="flex-end"
          visibility={showCloseButtonOnHover ? 'hidden' : 'visible'}
          ml={2}
          minW={0}
          onClick={(e) => {
            e.stopPropagation();
            if (onDelete) {
              onDelete(file.id);
            }
          }}
          sx={{
            '.file-box:hover &': {
              visibility: 'visible',
            },
          }}
        />
      )}
    </Flex>
  );
};
