import type { FC } from 'react';
import { Fieldset } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import type { ProjectCreateForm } from '../../forms/project-create-wizard';

import { UserBadgeGroupField } from 'features/user/components/UserBadgeGroupField';

export const ProjectAccountManagersFieldset: FC = () => {
  const { t } = useTranslation(['project']);

  return (
    <Fieldset<ProjectCreateForm>
      handle="account-manager"
      title={t('project:flow.accountManager.title')}
      legend={t('project:flow.accountManager.legend')}
      validationNames={[]}
    >
      <UserBadgeGroupField<ProjectCreateForm> name="projectAccountManagers" />
    </Fieldset>
  );
};
