import type { FC, ReactNode } from 'react';
import type { GridItemProps } from '@chakra-ui/react';
import { Grid, GridItem } from '@chakra-ui/react';

import { PriceAdvanced } from '../../PriceAdvanced';

export interface HighlightProps extends Omit<GridItemProps, 'left'> {
  label: string;
  amount?: number;
  left?: boolean;
  sign?: boolean;
  negative?: boolean;
  rightContent?: ReactNode;
}

export const Highlight: FC<HighlightProps> = ({ label, amount, left, sign, negative, rightContent, ...props }) => {
  const styleProps = {
    ...props,
  };

  return (
    <GridItem colSpan={3} bg="gray.100" color="black" fontWeight="600" pl={2} {...styleProps}>
      <Grid templateColumns="1fr minmax(5rem, auto) max-content" gap={2} {...props}>
        <GridItem color="black" fontWeight="600" {...styleProps}>
          {label}
        </GridItem>

        <PriceAdvanced
          amount={amount}
          display="flex"
          color="black"
          fontWeight="600"
          pl={4}
          justifyContent="flex-end"
          alignItems="center"
          textAlign="right"
          negative={negative}
          sign={sign}
          {...styleProps}
        />

        <GridItem pr={rightContent ? 4 : 2} {...styleProps}>
          {rightContent}
        </GridItem>
      </Grid>
    </GridItem>
  );
};
