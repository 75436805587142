import type { FC } from 'react';
import { useEffect } from 'react';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { SimpleHelpIcon, TextField, Tooltip } from '@graneet/lib-ui';
import type { FormContextApi } from 'graneet-form';
import { Form } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IClient, IClientResponseDTO } from '@graneet/business-logic';
import { FEATURE_FLAGS, LEDGER_TYPES, PERMISSION } from '@graneet/business-logic';

import { ClientAddressWithBillingFields } from './ClientAddressesWithBillingFields';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { Rule } from 'features/form/rules/Rule';
import { LedgerField } from 'features/ledger/components/LedgerField';
import type { AddressesWithBillingFieldsForm } from 'features/common/components/fields/AddressesWithBillingFields';

export interface ClientUpsertFormValues extends AddressesWithBillingFieldsForm {
  enterpriseName: string;

  code?: string | null;

  auxiliaryAccount?: string | null;

  isLedgerEnabled: boolean;

  siren?: string | null;

  vatNumber?: string | null;
}

interface ClientUpsertFormProps {
  client?: IClient | IClientResponseDTO | null;

  form: FormContextApi<ClientUpsertFormValues>;
}

export const ClientUpsertForm: FC<ClientUpsertFormProps> = ({ client, form }) => {
  const { t } = useTranslation(['clients']);
  const { setFormValues, resetForm } = form;

  const isEdit = !!client;

  const hasAccountingStandards = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARDS);
  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  useEffect(() => {
    if (client) {
      setFormValues({
        ...client,
        address: client.address!.address,
        city: client.address!.city,
        postalCode: client.address!.postalCode,
        country: client.address!.country,
        hasNoBillingAddress: !client.hasBillingAddress,
        billingAddressAddress: client.billingAddress?.address,
        billingAddressPostalCode: client.billingAddress?.postalCode,
        billingAddressCity: client.billingAddress?.city,
        billingAddressCountry: client.billingAddress?.country,
        auxiliaryAccount: client.auxiliaryAccount,
      });
    }
  }, [client, setFormValues]);

  useEffect(() => {
    if (!client) {
      resetForm();
    }
  }, [client, resetForm]);

  return (
    <Box w="full" paddingBottom={4}>
      <Form form={form}>
        <Grid gap={4}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4} w="full">
            <GridItem colSpan={2}>
              <TextField<ClientUpsertFormValues>
                name="enterpriseName"
                label={t('clients:fields.enterpriseName')}
                isRequired
              >
                <Rule.IsRequired />
              </TextField>
            </GridItem>

            <LedgerField<ClientUpsertFormValues, 'code'>
              name="code"
              isAutoGenerationDisabled={isEdit}
              ledgerType={LEDGER_TYPES.CLIENT}
              label={t('clients:fields.code')}
            />

            <Tooltip
              label={t('clients:tooltips.noPermissionForAuxiliaryAccountUpdate')}
              isDisabled={hasUpdateAccountingPermission}
              shouldWrapChildren
            >
              <TextField<ClientUpsertFormValues>
                name="auxiliaryAccount"
                label={t('clients:fields.auxiliaryAccount')}
                isReadOnly={!hasUpdateAccountingPermission}
                rightIcon={
                  hasAccountingStandards ? (
                    <Tooltip label={t('clients:tooltips.auxiliaryCode')} placement="right-end" shouldWrapChildren>
                      <SimpleHelpIcon boxSize={5} />
                    </Tooltip>
                  ) : undefined
                }
              />
            </Tooltip>

            <TextField<ClientUpsertFormValues> name="siren" label={t('clients:fields.siren')} />

            <TextField<ClientUpsertFormValues> name="vatNumber" label={t('clients:fields.vatNumber')} />
          </Grid>

          <ClientAddressWithBillingFields />
        </Grid>
      </Form>
    </Box>
  );
};
