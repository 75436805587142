import { useDisclosure } from '@chakra-ui/react';
import type { IProject, ITimeSlot, ITimeTrackingResponse, IWorker } from '@graneet/business-logic';
import type { TimeTableMode } from '@graneet/lib-ui';
import { useCallback, useState, useEffect, useMemo } from 'react';

import {
  initialState,
  timeTrackingReducer,
  type ReducerAction,
  type TimeTrackingReducerState,
} from '../services/time-tracking.reducer';
import { deleteSlots, getSlots } from '../services/time-slot.api';
import type { ITimeTrackingContext } from '../context/TimeTrackingContext';
import { useTimeTrackingSettings } from '../services/time-tracking-settings.api';

import { usePersistedReducer } from 'features/common/hooks/usePersistedReducer';
import { useProjectsWithoutPagination } from 'features/project/services/project.api';
import { useWorkersWithoutPagination } from 'features/worker/services/worker.api';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { useData } from 'features/api/hooks/useData';

export const TIME_TRACKING_PERSISTED_STORAGE_KEY = 'timeTrackingState';

export const useTimeTracking = (): ITimeTrackingContext => {
  const { state, dispatch } = usePersistedReducer<TimeTrackingReducerState, ReducerAction>(
    timeTrackingReducer,
    initialState,
    TIME_TRACKING_PERSISTED_STORAGE_KEY,
    '/time-tracking/',
    {
      subKey: 'filters',
    },
  );
  const [weekData, setWeekData] = useState<ITimeTrackingResponse<IProject | IWorker> | null>(null);
  const [mode, setMode] = useState<TimeTableMode>('default');

  // - Fetching data
  const workersWithDeleted = useWorkersWithoutPagination();
  const projects = useProjectsWithoutPagination();

  const timeTrackingSettings = useTimeTrackingSettings();

  const { data: slotData, fetch } = useData(useDataGetter(getSlots, state.filters), { autoLoad: false });

  const workers = useMemo(
    () => workersWithDeleted.data.data.filter((worker) => !worker.deletedAt),
    [workersWithDeleted.data.data],
  );

  const modalCreateSlot = useDisclosure({
    onClose: () => {
      dispatch({ type: 'createSlot', value: null });
      dispatch({ type: 'loading', value: true });
      fetch(state.filters);
    },
  });
  const modalEditSlot = useDisclosure({
    onClose: () => {
      dispatch({ type: 'loading', value: true });
      fetch(state.filters);
    },
  });

  useEffect(() => {
    dispatch({
      type: 'settingsData',
      value: {
        numberOfDays: timeTrackingSettings.data.ttShowWeekends ? 7 : 5,
        defaultNbHours: timeTrackingSettings.data.ttNbMinutesWorkingDay ?? 0,
        defaultStartHour: timeTrackingSettings.data.ttStartHour ?? '',
        defaultEndHour: timeTrackingSettings.data.ttEndHour ?? '',
      },
    });
  }, [timeTrackingSettings.data, dispatch]);

  useEffect(() => {
    setWeekData(slotData);
    dispatch({ type: 'loading', value: false });
  }, [slotData, setWeekData, dispatch]);

  useEffect(() => {
    if (projects.data.data && workersWithDeleted.data.data) {
      dispatch({
        type: 'listData',
        value: {
          projects: projects.data.data,
          workers: workers || [],
          workersWithDeleted: workersWithDeleted.data.data || [],
        },
      });
    }
  }, [projects.data.data, workers, dispatch, workersWithDeleted.data.data]);

  useEffect(() => {
    dispatch({ type: 'loading', value: true });
    fetch(state.filters);
  }, [fetch, state.filters, dispatch]);

  const handleEditSlot = useCallback(
    (slot: Partial<ITimeSlot>) => {
      dispatch({ type: 'editSlot', value: slot });
      modalEditSlot.onOpen();
    },
    [modalEditSlot, dispatch],
  );
  const handleGroupByChange = useCallback(
    (value: 'worker' | 'project') => dispatch({ type: 'changeGroupBy', value }),
    [dispatch],
  );
  const handleViewChange = useCallback(
    (value: 'week' | 'month') => dispatch({ type: 'changeView', value }),
    [dispatch],
  );
  const handleCreateSlot = useCallback(
    (id: number, subEntityId: number | string, isOff: boolean, date: Date) => {
      dispatch({
        type: 'createSlot',
        value: {
          id,
          date,
          subEntityId,
          isOff: false,
        },
      });
      modalCreateSlot.onOpen();
    },
    [modalCreateSlot, dispatch],
  );
  const handleSearchChange = useCallback((value?: string) => dispatch({ type: 'setQuery', value }), [dispatch]);
  const handleDateChange = useCallback(
    (value: Date | string) => {
      dispatch({ type: 'setDate', value: typeof value === 'string' ? new Date(value) : value });
    },
    [dispatch],
  );
  const handleDeleteSlots = useCallback(
    async (slots: ITimeSlot[]) => {
      await deleteSlots(slots.map(({ id }) => id));
      dispatch({ type: 'loading', value: true });
      fetch(state.filters);
    },
    [fetch, state.filters, dispatch],
  );

  const handleZoomWeek = useCallback(
    (date: Date) => {
      dispatch({ type: 'zoomWeek', value: date });
    },
    [dispatch],
  );

  const { refetch: refetchProjects } = projects;
  const { refetch: refetchWorkers } = workersWithDeleted;
  const refetch = useCallback(async () => {
    await refetchProjects();
    await refetchWorkers();
  }, [refetchProjects, refetchWorkers]);

  return useMemo(
    () => ({
      handleDeleteSlots,
      handleDateChange,
      handleSearchChange,
      handleCreateSlot,
      handleViewChange,
      handleGroupByChange,
      handleEditSlot,
      handleZoomWeek,
      refetch,
      setWeekData,
      dispatch,
      fetch,
      modalCreateSlot,
      modalEditSlot,
      settingsData: timeTrackingSettings.data,
      weekData,
      workers,
      projects: projects.data,
      slotData,
      state,
      mode,
      updateMode: setMode,
    }),
    [
      dispatch,
      fetch,
      handleCreateSlot,
      handleDateChange,
      handleDeleteSlots,
      handleEditSlot,
      handleGroupByChange,
      handleSearchChange,
      handleViewChange,
      handleZoomWeek,
      modalCreateSlot,
      modalEditSlot,
      mode,
      projects.data,
      refetch,
      slotData,
      state,
      timeTrackingSettings.data,
      weekData,
      workers,
    ],
  );
};
