import { useEffect, useRef } from 'react';

/**
 * Returns undefined until called with value that
 * is NOT undefined
 *
 * @example
 * ```
 * useFirstDefinedValueOf(undefined) // undefined
 * useFirstDefinedValueOf(undefined) // undefined
 * useFirstDefinedValueOf(10)        // 10
 * useFirstDefinedValueOf(999)       // 10
 * useFirstDefinedValueOf(undefined) // 10
 * ```
 */
export const useFirstDefinedValue = <T>(value: T) => {
  const ref = useRef(value);
  useEffect(() => {
    if (ref.current) {
      return;
    }
    ref.current = value;
  }, [value]);

  return ref.current ?? value;
};
