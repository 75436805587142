import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { compact } from 'lodash-es';

import type { InvoiceActionsParams } from './invoice.actions.type';
import {
  getCompleteAction,
  getDeleteAction,
  getDownloadPDFAction,
  getEditAction,
  getOpenMailingAction,
  getPreviewPDFAction,
} from './invoice.actions.util';

export function getInvoiceDraftActions(params: InvoiceActionsParams): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  const editAction = getEditAction(params);

  actions.push({ type: 'primary', ...getCompleteAction(params) });

  if (editAction) {
    actions.push({
      type: 'secondary',
      ...editAction,
    });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([
      getPreviewPDFAction(params),
      getOpenMailingAction(params),
      getDownloadPDFAction(params),
      getDeleteAction(params),
    ]),
  });

  return actions;
}
