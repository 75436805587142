import type { FC } from 'react';
import { useCallback } from 'react';
import { AmountSummary, Modal, FinanceIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useDisclosure, Text } from '@chakra-ui/react';
import { VAT_TYPE } from '@graneet/business-logic';

import { useEditQuoteReversalOfLiability } from '../../../hooks/useEditQuoteReversalOfLiability';
import { useQuoteDisplayContext } from '../../../hooks/useQuoteDisplayContext';
import { useQuote } from '../../../hooks/useQuote';

import { QuoteTotalsDiscount } from 'features/quote-discount/components/QuoteTotalsDiscount';
import { EditQuoteLotDefaultVatRateModal } from 'features/quote-lot/components/EditQuoteLotDefaultVatRateModal';
import { QuoteTotalsCustomDiscount } from 'features/quote-discount/components/QuoteTotalsCustomDiscount';
import type { QuoteLotData } from 'features/quote-lot/types/QuoteLotData';

interface QuoteTotalsProps {
  rootLot: QuoteLotData & { id: number };
}

export const QuoteTotals: FC<QuoteTotalsProps> = ({ rootLot }) => {
  const { t } = useTranslation(['quote', 'customDiscount', 'global']);

  const editQuoteReversalOfLiability = useEditQuoteReversalOfLiability();
  const { isReadOnlyView } = useQuoteDisplayContext();

  const quote = useQuote();
  const modalDisableReversalOfLiability = useDisclosure();
  const modalEditQuoteDefaultVatRate = useDisclosure();

  const amountExVAT = quote?.amountExVAT || 0;
  const amountIncVAT = quote?.amountIncVAT || 0;
  const amountWithoutDiscountExVAT = quote?.amountWithoutDiscountExVAT || 0;
  const amountWithDiscountExVAT = quote?.amountWithDiscountExVAT || 0;
  const discount = quote?.discount || null;

  const switchReversalOfLiability = useCallback(async () => {
    await editQuoteReversalOfLiability(!quote?.hasReversalOfLiability);
    modalDisableReversalOfLiability.onClose();
  }, [editQuoteReversalOfLiability, modalDisableReversalOfLiability, quote?.hasReversalOfLiability]);

  if (!rootLot?.jobs.length && !rootLot?.subLots.length) {
    return null;
  }

  return (
    <>
      <AmountSummary mt={2}>
        {discount && (
          <QuoteTotalsDiscount
            amountWithoutDiscountExVAT={amountWithoutDiscountExVAT}
            discount={discount}
            noActions={isReadOnlyView}
          />
        )}

        {!!quote?.customDiscounts?.length && (
          <AmountSummary.Item
            label={t(
              discount ? 'quote:fields.amountWithDiscountExVAT' : 'customDiscount:totalExVATBeforeCustomDiscountLabel',
            )}
            amount={amountWithDiscountExVAT}
            important
          />
        )}

        {(quote?.customDiscounts || []).map((customDiscount) => (
          <QuoteTotalsCustomDiscount
            quoteId={quote?.id}
            customDiscount={customDiscount}
            amountWithDiscountExVAT={amountWithDiscountExVAT}
            key={customDiscount.id}
            noActions={isReadOnlyView}
          />
        ))}

        <AmountSummary.Item label={t('quote:fields.totalPriceExVAT')} amount={amountExVAT} important />

        <AmountSummary.VATDistribution
          vatType={quote?.hasReversalOfLiability ? VAT_TYPE.REVERSE_CHARGE : VAT_TYPE.NORMAL}
          {...(!isReadOnlyView && {
            dropdownItems: quote?.hasReversalOfLiability
              ? [
                  {
                    label: t('quote:reversalOfLiability.disable'),
                    onClick: modalDisableReversalOfLiability.onOpen,
                  },
                ]
              : [
                  {
                    label: t('global:applyGlobalVAT'),
                    onClick: modalEditQuoteDefaultVatRate.onOpen,
                  },
                  {
                    label: t('quote:reversalOfLiability.enable'),
                    icon: <FinanceIcon />,
                    onClick: switchReversalOfLiability,
                  },
                ],
          })}
          vatDistribution={quote?.vatDistribution || []}
          withCustomDiscountsLabel={!!quote?.hasReversalOfLiability && !!quote?.customDiscounts?.length}
        />

        <AmountSummary.Item label={t('quote:fields.totalPriceIncVAT')} amount={amountIncVAT} important />
      </AmountSummary>

      <Modal {...modalDisableReversalOfLiability}>
        <Text mt={4}>{t('quote:reversalOfLiability.disableModal.description')}</Text>

        <Modal.Close />

        <Modal.PrimaryButton onClick={switchReversalOfLiability}>{t('global:words.c.continue')}</Modal.PrimaryButton>
      </Modal>

      <EditQuoteLotDefaultVatRateModal
        title={t('quote:defaultVatRateModal.quote.title')}
        description={t('quote:defaultVatRateModal.quote.description')}
        fieldDescription={t('quote:defaultVatRateModal.quote.fieldDescription')}
        modal={modalEditQuoteDefaultVatRate}
        quoteLotId={rootLot.lot.id}
      />
    </>
  );
};
