import type { MutableRefObject, ReactElement, ReactNode, ReactPortal } from 'react';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import type { CommandListenerPriority, LexicalEditor, TextNode } from 'lexical';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  createCommand,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
  KEY_TAB_COMMAND,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';

/*
  This file is largely inspired by https://github.com/facebook/lexical/blob/e2becddc18a72e9f28d8595f038c92b5a75114e0/packages/lexical-react/src/shared/LexicalMenu.ts
*/
export const SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND = createCommand<{
  index: number;
  option: MenuOption;
}>('SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND');

export type MenuTextMatch = {
  leadOffset: number;
  matchingString: string;
  replaceableString: string;
};

export type MenuResolution = {
  match?: MenuTextMatch;
  getRect: () => DOMRect;
};

export class MenuOption {
  key: string;

  ref?: MutableRefObject<HTMLElement | null>;

  isFocusable: boolean;

  constructor(key: string, isFocusable: boolean) {
    this.key = key;
    this.ref = { current: null };
    this.isFocusable = isFocusable;
    this.setRefElement = this.setRefElement.bind(this);
  }

  setRefElement(element: HTMLElement | null) {
    this.ref = { current: element };
  }
}

export type MenuRenderFn<TOption extends MenuOption> = (
  anchorElementRef: MutableRefObject<HTMLElement | null>,
  itemProps: {
    selectedIndex: number | null;
    selectOptionAndCleanUp: (option: TOption) => void;
    setHighlightedIndex: (index: number) => void;
    options: Array<TOption>;
  },
  matchingString: string | null,
) => ReactPortal | ReactElement | null;

/**
 * Walk backwards along user input and forward through entity title to try
 * and replace more of the user's text with entity.
 */
function getFullMatchOffset(documentText: string, entryText: string, offset: number): number {
  let triggerOffset = offset;
  for (let i = triggerOffset; i <= entryText.length; i += 1) {
    if (documentText.substr(-i) === entryText.substr(0, i)) {
      triggerOffset = i;
    }
  }
  return triggerOffset;
}

/**
 * Split Lexical TextNode and return a new TextNode only containing matched text.
 * Common use cases include: removing the node, replacing with a new node.
 */
const $splitNodeContainingQuery = (match: MenuTextMatch): TextNode | null => {
  const selection = $getSelection();
  if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
    return null;
  }
  const { anchor } = selection;
  if (anchor.type !== 'text') {
    return null;
  }
  const anchorNode = anchor.getNode();
  if (!anchorNode.isSimpleText()) {
    return null;
  }
  const selectionOffset = anchor.offset;
  const textContent = anchorNode.getTextContent().slice(0, selectionOffset);
  // eslint-disable-next-line react/destructuring-assignment
  const characterOffset = match.replaceableString.length;
  // eslint-disable-next-line react/destructuring-assignment
  const queryOffset = getFullMatchOffset(textContent, match.matchingString, characterOffset);
  const startOffset = selectionOffset - queryOffset;
  if (startOffset < 0) {
    return null;
  }
  let newNode;
  if (startOffset === 0) {
    [newNode] = anchorNode.splitText(selectionOffset);
  } else {
    [, newNode] = anchorNode.splitText(startOffset, selectionOffset);
  }

  return newNode;
};

const scrollIntoViewIfNeeded = (target: HTMLElement) => {
  const typeaheadContainerNode = document.getElementById('typeahead-menu');
  if (!typeaheadContainerNode) return;

  const typeaheadRect = typeaheadContainerNode.getBoundingClientRect();

  if (typeaheadRect.top + typeaheadRect.height > window.innerHeight) {
    typeaheadContainerNode.scrollIntoView({
      block: 'center',
    });
  }

  if (typeaheadRect.top < 0) {
    typeaheadContainerNode.scrollIntoView({
      block: 'center',
    });
  }

  target.scrollIntoView({ block: 'nearest' });
};

export function VariableMenu<TOption extends MenuOption>({
  close,
  editor,
  anchorElementRef,
  resolution,
  options,
  menuRenderFn,
  onSelectOption,
  shouldSplitNodeWithQuery = false,
  commandPriority = COMMAND_PRIORITY_LOW,
}: {
  close: () => void;
  editor: LexicalEditor;
  anchorElementRef: MutableRefObject<HTMLElement>;
  resolution: MenuResolution;
  options: Array<TOption>;
  shouldSplitNodeWithQuery?: boolean;
  menuRenderFn: MenuRenderFn<TOption>;
  onSelectOption: (
    option: TOption,
    textNodeContainingQuery: TextNode | null,
    closeMenu: () => void,
    matchingString: string,
  ) => void;
  commandPriority?: CommandListenerPriority;
}): ReactNode | null {
  const [selectedIndex, setHighlightedIndex] = useState<null | number>(null);

  const matchingString = resolution.match && resolution.match.matchingString;

  useEffect(() => {
    setHighlightedIndex(0);
  }, [matchingString]);

  const selectOptionAndCleanUp = useCallback(
    (selectedEntry: TOption) => {
      editor.update(() => {
        const textNodeContainingQuery =
          resolution.match != null && shouldSplitNodeWithQuery ? $splitNodeContainingQuery(resolution.match) : null;

        onSelectOption(
          selectedEntry,
          textNodeContainingQuery,
          close,
          resolution.match ? resolution.match.matchingString : '',
        );
      });
    },
    [editor, shouldSplitNodeWithQuery, resolution.match, onSelectOption, close],
  );

  const updateSelectedIndex = useCallback(
    (index: number) => {
      const rootElem = editor.getRootElement();
      if (rootElem !== null) {
        rootElem.setAttribute('aria-activedescendant', `typeahead-item-${index}`);
        setHighlightedIndex(index);
      }
    },
    [editor],
  );

  useEffect(
    () => () => {
      const rootElem = editor.getRootElement();
      if (rootElem !== null) {
        rootElem.removeAttribute('aria-activedescendant');
      }
    },
    [editor],
  );

  useLayoutEffect(() => {
    if (options === null) {
      setHighlightedIndex(null);
    } else if (selectedIndex === null) {
      updateSelectedIndex(0);
    }
  }, [options, selectedIndex, updateSelectedIndex]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand(
          SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND,
          ({ option }) => {
            if (option.ref && option.ref.current != null) {
              scrollIntoViewIfNeeded(option.ref.current);
              return true;
            }

            return false;
          },
          commandPriority,
        ),
      ),
    [editor, updateSelectedIndex, commandPriority],
  );

  const focusableOptions = useMemo(() => options.filter((option) => option.isFocusable), [options]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerCommand<KeyboardEvent>(
          KEY_ARROW_DOWN_COMMAND,
          (event) => {
            if (focusableOptions.length && selectedIndex !== null) {
              const newSelectedIndex = selectedIndex !== focusableOptions.length - 1 ? selectedIndex + 1 : 0;

              updateSelectedIndex(newSelectedIndex);
              const option = focusableOptions[newSelectedIndex];
              if (option.ref != null && option.ref.current) {
                editor.dispatchCommand(SCROLL_TYPEAHEAD_OPTION_INTO_VIEW_COMMAND, {
                  index: newSelectedIndex,
                  option,
                });
              }
              event.preventDefault();
              event.stopImmediatePropagation();
            }
            return true;
          },
          commandPriority,
        ),
        editor.registerCommand<KeyboardEvent>(
          KEY_ARROW_UP_COMMAND,
          (event) => {
            if (focusableOptions.length && selectedIndex !== null) {
              const newSelectedIndex = selectedIndex !== 0 ? selectedIndex - 1 : focusableOptions.length - 1;
              updateSelectedIndex(newSelectedIndex);
              const option = focusableOptions[newSelectedIndex];
              if (option.ref != null && option.ref.current) {
                scrollIntoViewIfNeeded(option.ref.current);
              }
              event.preventDefault();
              event.stopImmediatePropagation();
            }
            return true;
          },
          commandPriority,
        ),
        editor.registerCommand<KeyboardEvent>(
          KEY_ESCAPE_COMMAND,
          (event) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            close();
            return true;
          },
          commandPriority,
        ),
        editor.registerCommand<KeyboardEvent>(
          KEY_TAB_COMMAND,
          (event) => {
            if (selectedIndex === null || focusableOptions[selectedIndex] == null) {
              return false;
            }
            event.preventDefault();
            event.stopImmediatePropagation();
            selectOptionAndCleanUp(focusableOptions[selectedIndex]);
            return true;
          },
          commandPriority,
        ),
        editor.registerCommand(
          KEY_ENTER_COMMAND,
          (event: KeyboardEvent | null) => {
            if (selectedIndex === null || focusableOptions[selectedIndex] == null) {
              return false;
            }
            if (event !== null) {
              event.preventDefault();
              event.stopImmediatePropagation();
            }
            selectOptionAndCleanUp(focusableOptions[selectedIndex]);
            return true;
          },
          commandPriority,
        ),
      ),
    [selectOptionAndCleanUp, close, editor, selectedIndex, updateSelectedIndex, commandPriority, focusableOptions],
  );

  const listItemProps = useMemo(
    () => ({
      options,
      selectOptionAndCleanUp,
      selectedIndex,
      setHighlightedIndex,
    }),
    [selectOptionAndCleanUp, selectedIndex, options],
  );

  return menuRenderFn(anchorElementRef, listItemProps, resolution.match ? resolution.match.matchingString : '');
}
