import { Flex } from '@chakra-ui/react';

import { Badge } from '../Badge/Badge';
import { SimpleThunderIcon } from '../Icons';

interface BadgeAutoProps {
  onClick?: () => void;
  isActivated: boolean;
}

export const BadgeAutoNumbering = ({ onClick, isActivated }: BadgeAutoProps) => (
  <Badge colorScheme="gray" px={2} highlightOnHover onClick={onClick} cursor="pointer">
    <Flex flexDirection="row" gap="2" userSelect="none" alignItems="center">
      <SimpleThunderIcon
        boxSize={3}
        stroke={isActivated ? 'yellow.500' : 'gray.300'}
        fill={isActivated ? 'yellow.500' : 'gray.300'}
        _hover={{
          background: 'none',
          stroke: 'yellow.500',
        }}
      />
      Auto
    </Flex>
  </Badge>
);
