import type { FC, ReactNode } from 'react';
import { useMemo, useCallback } from 'react';
import type { RenderActionsProps } from '@graneet/lib-ui';
import { SingleSelect } from '@graneet/lib-ui';
import { FILTERING_PARAMS, ORDER_STATUS } from '@graneet/business-logic';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import { OrderStatusBadge } from '../badges/OrderStatusBadge';

import { useOrdersUpdateStatuses } from 'features/order/services/order.api';

export interface OrderBatchStatusActionsProps extends RenderActionsProps<{ id: number; status: ORDER_STATUS }> {
  onStatusesChanged(): void;
}

export const OrderBatchStatusActions: FC<OrderBatchStatusActionsProps> = ({
  selectedItems,
  resetSelectedCheckboxes,
  hasAllCheckboxesSelected,
  currentFilters,
  onStatusesChanged,
}) => {
  const { t } = useTranslation(['orders', 'global']);

  const ordersUpdateStatusesMutate = useOrdersUpdateStatuses();

  const onOptionSelected = useCallback(
    async (nextStatus: { value: ORDER_STATUS; label: ReactNode } | null) => {
      if (!nextStatus) {
        return;
      }

      await ordersUpdateStatusesMutate.mutateAsync({
        dto: {
          filters: qs.parse(currentFilters.toString()),
          selectedItems: selectedItems.map((item) => ({ id: item.id })),
          hasAllSelected: hasAllCheckboxesSelected,
          search: currentFilters.get(FILTERING_PARAMS.SEARCH) || undefined,
        },
        status: nextStatus.value,
      });
      resetSelectedCheckboxes();
      onStatusesChanged();
    },
    [
      currentFilters,
      hasAllCheckboxesSelected,
      onStatusesChanged,
      ordersUpdateStatusesMutate,
      resetSelectedCheckboxes,
      selectedItems,
    ],
  );

  const options = useMemo(
    () => [
      { value: ORDER_STATUS.PROCESSED, label: <OrderStatusBadge status={ORDER_STATUS.PROCESSED} /> },
      {
        value: ORDER_STATUS.PARTIALLY_DELIVERED,
        label: <OrderStatusBadge status={ORDER_STATUS.PARTIALLY_DELIVERED} />,
      },
      { value: ORDER_STATUS.DELIVERED, label: <OrderStatusBadge status={ORDER_STATUS.DELIVERED} /> },
    ],
    [],
  );

  return (
    <Box width="9rem">
      <SingleSelect<{ value: ORDER_STATUS; label: ReactNode }>
        onChange={onOptionSelected}
        placeholder={t('global:words.c.status')}
        isDisabled={ordersUpdateStatusesMutate.isPending}
        options={options}
        isClearable={false}
        isSearchable={false}
        menuPlacement="top"
        isLoading={ordersUpdateStatusesMutate.isPending}
      />
    </Box>
  );
};
