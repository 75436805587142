/* eslint @typescript-eslint/no-use-before-define: 0 */
/* eslint no-underscore-dangle: 0 */
import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  NodeKey,
  SerializedTextNode,
  Spread,
  TextModeType,
  LexicalEditor,
} from 'lexical';
import { $applyNodeReplacement, TextNode } from 'lexical';

export type SerializedVariableNode = Spread<
  {
    text: string;
    value: string;
  },
  SerializedTextNode
>;

function convertVariableElement(domNode: HTMLElement): DOMConversionOutput | null {
  const { textContent } = domNode;

  if (textContent !== null) {
    const node = $createVariableNode(
      {
        text: textContent,
        value: textContent,
      },
      undefined,
    );
    return {
      node,
    };
  }

  return null;
}

export class VariableNode extends TextNode {
  __value: string;

  constructor(
    variable: {
      text: string;
      value: string;
    },
    key?: NodeKey,
  ) {
    super(variable.text, key);
    this.__text = variable.text;
    this.__value = variable.value;
  }

  static getType(): string {
    return 'variable';
  }

  static clone(node: VariableNode): VariableNode {
    return new VariableNode(
      {
        text: node.__text,
        value: node.__value,
      },
      node.__key,
    );
  }

  static importJSON(serializedNode: SerializedVariableNode): VariableNode {
    const node = $createVariableNode(
      {
        text: serializedNode.text,
        value: serializedNode.value,
      },
      {
        format: serializedNode.format,
        detail: serializedNode.detail,
        mode: serializedNode.mode,
        style: serializedNode.style,
      },
    );
    node.setTextContent(serializedNode.text);
    return node;
  }

  exportJSON(): SerializedVariableNode {
    return {
      ...super.exportJSON(),
      text: this.__text,
      value: this.__value,
      type: 'variable',
      version: 1,
    };
  }

  createDOM(config: EditorConfig, editor?: LexicalEditor, options = { useValue: false }): HTMLElement {
    const { injectValueInLabel } = config.theme.variables.options;

    // Hack to inject whatever value you want in the node through lexical config context
    const optionText = config.theme.variables.options.optionsText[this.__value];

    if (optionText) {
      this.__text = options.useValue ? optionText.value : optionText.label;
    } else {
      this.__text = 'INVALID';
    }
    const dom = super.createDOM(config, editor);

    const hasError = injectValueInLabel || !optionText;
    dom.className = hasError ? config.theme.variables.badgeError : config.theme.variables.badge;

    if (hasError) {
      dom.setAttribute('id', 'data-lexical-variable-error');
    }

    if (!options.useValue) {
      dom.setAttribute('data-lexical-variable', 'true');
    }

    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-variable', 'true');
    element.textContent = `{{${this.__value}}}`;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-variable')) {
          return null;
        }
        return {
          conversion: convertVariableElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }

  isToken() {
    return false;
  }
}

export function $createVariableNode(
  variable: { text: string; value: string },
  options:
    | {
        format: number;
        detail: number;
        mode: TextModeType;
        style: string;
      }
    | undefined,
): VariableNode {
  const variableNode = new VariableNode(variable);
  variableNode.setMode('segmented').toggleDirectionless();

  if (options) {
    variableNode.setFormat(options.format);
    variableNode.setDetail(options.detail);
    variableNode.setMode(options.mode);
    variableNode.setStyle(options.style);
  }

  return $applyNodeReplacement(variableNode);
}
