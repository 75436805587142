import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { FieldValues } from 'graneet-form';

interface InternalWizardContextApi<
  WizardValues extends Record<string, FieldValues>,
  Step extends keyof WizardValues = keyof WizardValues,
> {
  displaySecondaryButtons: Record<Step, boolean>;
  setDisplaySecondaryButton(stepName: Step, displaySecondaryButton: boolean): void;
}

export const InternalWizardContext = createContext<InternalWizardContextApi<any, any>>({
  displaySecondaryButtons: {},
  setDisplaySecondaryButton: () => {},
});

export const InternalWizardContextProvider = InternalWizardContext.Provider;

export const useInternalWizard = <
  WizardValues extends Record<string, FieldValues>,
  Step extends keyof WizardValues = keyof WizardValues,
>(): InternalWizardContextApi<WizardValues, Step> => {
  const [displaySecondaryButtons, setIsDisplaySecondaryButtons] = useState<Record<Step, boolean>>(
    {} as Record<Step, boolean>,
  );

  const setDisplaySecondaryButton = useCallback((stepName: Step, displayButton: boolean) => {
    setIsDisplaySecondaryButtons((prev) => ({
      ...prev,
      [stepName]: displayButton,
    }));
  }, []);

  return useMemo(
    () => ({
      displaySecondaryButtons,
      setDisplaySecondaryButton,
    }),
    [displaySecondaryButtons, setDisplaySecondaryButton],
  );
};

export const useInternalWizardContext = () => useContext(InternalWizardContext);
