import type { IProjectFinancialSummary } from '@graneet/business-logic';
import { formatPercentage } from '@graneet/lib-ui';
import type { FC } from 'react';

export const ProjectTableTheoreticalDisbursementExVATMarginPercentageCell: FC<{ data?: IProjectFinancialSummary }> = ({
  data,
}) =>
  formatPercentage(data?.theoreticalDisbursementExVATMarginPercentage ?? 0, {
    maximumFractionDigits: 2,
  });
