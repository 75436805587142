import type {
  QuoteComposeDTO,
  QuoteCreateDTO,
  QuoteDuplicateDTO,
  QuoteLotDTO,
  QuotePaginatedDTO,
  QuoteStatus,
  QuoteTagsDTO,
  QuoteUpdateAsyncDTO,
  QuoteUpdateDTO,
  QuoteUpdateStatusDTO,
  QuoteVersionsDTO,
  QuoteWithoutRelationsDTO,
  QuotesApi,
  StatsDTO,
} from '@org/graneet-bff-client';
import type { Response } from '@graneet/lib-ui';
import { DEFAULT_PAGE, DEFAULT_SIZE, FILTERING_PARAMS, PAGINATION_PARAMS, safeParseInt } from '@graneet/business-logic';

import { proxyHelper, proxyHelperTanStack } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';
import { QUOTE_FIELDS } from 'features/quote/constants/quotes.constant';

export class QuoteProxyApi {
  private static quoteProxyApi: QuoteProxyApi;

  private readonly quotesApi: QuotesApi;

  private constructor(externalApi: ExternalApi) {
    this.quotesApi = externalApi.getQuotesApi();
  }

  public static getQuoteProxyApi(externalApi: ExternalApi): QuoteProxyApi {
    if (!this.quoteProxyApi) {
      QuoteProxyApi.quoteProxyApi = new QuoteProxyApi(externalApi);
    }
    return QuoteProxyApi.quoteProxyApi;
  }

  public async createQuote(quoteCreateDTO: QuoteCreateDTO) {
    return proxyHelper(() => this.quotesApi.createQuote(quoteCreateDTO));
  }

  public async deleteQuote(quoteId: string) {
    return (await this.quotesApi.deleteQuote(quoteId)).data;
  }

  public async updateQuote(quoteId: string, quoteUpdateDTO: QuoteUpdateDTO) {
    return proxyHelper(() => this.quotesApi.updateQuote(quoteId, quoteUpdateDTO));
  }

  public async updateQuoteAsync(quoteUpdateAsyncDTO: QuoteUpdateAsyncDTO) {
    return proxyHelper(() => this.quotesApi.updateQuoteAsync(quoteUpdateAsyncDTO));
  }

  public async getQuoteById(quoteId: string): Promise<QuoteWithoutRelationsDTO> {
    return proxyHelperTanStack(() => this.quotesApi.getQuoteById(quoteId));
  }

  public async duplicateQuoteById(quoteId: string, dto: QuoteDuplicateDTO): Promise<QuoteWithoutRelationsDTO> {
    return proxyHelperTanStack(() => this.quotesApi.duplicateQuoteById(quoteId, dto));
  }

  public async restoreQuoteById(quoteId: string): Promise<QuoteWithoutRelationsDTO> {
    return proxyHelperTanStack(() => this.quotesApi.restoreQuoteById(quoteId));
  }

  public async changeQuoteStatusToDraft(quoteId: string): Promise<QuoteWithoutRelationsDTO> {
    return proxyHelperTanStack(() => this.quotesApi.changeQuoteStatusToDraft(quoteId));
  }

  public async updatePartialQuote(quoteId: string, quoteUpdateDTO: QuoteUpdateDTO): Promise<QuoteWithoutRelationsDTO> {
    return proxyHelperTanStack(() => this.quotesApi.updateQuote(quoteId, quoteUpdateDTO));
  }

  public async changeQuoteStatus(quoteId: string, dto: QuoteUpdateStatusDTO): Promise<QuoteWithoutRelationsDTO> {
    return proxyHelperTanStack(() => this.quotesApi.changeQuoteStatus(quoteId, dto));
  }

  public async getQuotesPaginatedByCompanyId(params?: URLSearchParams): Promise<Response<QuotePaginatedDTO>> {
    const page = params?.get(PAGINATION_PARAMS.PAGE);
    const offsetV1 = params?.get(PAGINATION_PARAMS.OFFSET_V1);
    const offsetV2 = params?.get(PAGINATION_PARAMS.OFFSET_V2);
    const incremental = params?.get(PAGINATION_PARAMS.INCREMENTAL) ?? 'false';
    const size = params?.get(PAGINATION_PARAMS.SIZE);
    const full = params?.get(PAGINATION_PARAMS.FULL);

    const order = params?.get(FILTERING_PARAMS.ORDER);
    const projectId = params?.get('_projectId');
    const clientId = params?.get('_clientId');
    const direction = params?.get(FILTERING_PARAMS.DIRECTION);
    const search = params?.get(FILTERING_PARAMS.SEARCH);
    const status = params?.getAll(QUOTE_FIELDS.STATUS) as QuoteStatus[];
    const startDate = params?.get('createdAt[startDate]');
    const endDate = params?.get('createdAt[endDate]');
    const clients = params?.getAll('clients').map((value) => Number(value)) ?? [];
    const accountsManagers = params?.getAll('quoteAccountManagers').map((value) => Number(value)) ?? [];
    const tags = params?.getAll('tags') ?? [];
    const v1QuoteIsIgnored = params?.get('v1QuoteIsIgnored') ?? 'false';

    const filteredParams = {
      page: page ? safeParseInt(page, 10) : DEFAULT_PAGE,
      size: size ? safeParseInt(size, 10) : DEFAULT_SIZE,
      offsetV1: offsetV1 ? safeParseInt(offsetV1, 10) : 0,
      offsetV2: offsetV2 ? safeParseInt(offsetV2, 10) : 0,
      incremental,
      ...(clientId && { clientId: safeParseInt(clientId, 10) }),
      ...(projectId && { projectId: safeParseInt(projectId, 10) }),
      ...(full && { full }),
      ...(order && { order }),
      ...(direction && { direction }),
      ...(search && { search }),
      ...(status && { status }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(clients && { clients }),
      ...(accountsManagers && { accountsManagers }),
      ...(tags && { tags }),
      ...(v1QuoteIsIgnored && { v1QuoteIsIgnored }),
    };

    return proxyHelper(() =>
      this.quotesApi.getQuotesPaginatedByCompanyId(
        filteredParams.full,
        filteredParams.v1QuoteIsIgnored,
        filteredParams.incremental,
        filteredParams.page,
        filteredParams.offsetV2,
        filteredParams.offsetV1,
        filteredParams.size,
        filteredParams.order,
        filteredParams.direction,
        filteredParams.search,
        filteredParams.projectId,
        filteredParams.clientId,
        filteredParams.status as QuoteStatus[],
        filteredParams.startDate,
        filteredParams.endDate,
        filteredParams.accountsManagers,
        filteredParams.clients,
        filteredParams.tags,
      ),
    );
  }

  public async getQuoteComposeById(quoteId: string): Promise<Response<QuoteComposeDTO>> {
    return proxyHelper(() => this.quotesApi.quoteComposed(quoteId));
  }

  public async getComposeById(quoteId: string): Promise<QuoteComposeDTO> {
    return proxyHelperTanStack(() => this.quotesApi.quoteComposed(quoteId));
  }

  public async getRootLotById(quoteId: string): Promise<QuoteLotDTO> {
    return proxyHelperTanStack(() => this.quotesApi.getQuoteRootLot(quoteId));
  }

  public async getIncompleteQuoteAssetsCount(quoteId: string): Promise<number> {
    return proxyHelperTanStack(() => this.quotesApi.getIncompleteQuoteAssetsCount(quoteId));
  }

  public async getQuoteVersionsById(quoteId: string): Promise<QuoteVersionsDTO> {
    return proxyHelperTanStack(() => this.quotesApi.getQuoteVersionsById(quoteId));
  }

  public async getAvailableTagsByCompanyId(): Promise<Response<QuoteTagsDTO>> {
    return proxyHelper(() => this.quotesApi.getAvailableTagsByCompanyId());
  }

  public async getAvailableTags(): Promise<QuoteTagsDTO> {
    return proxyHelperTanStack(() => this.quotesApi.getAvailableTagsByCompanyId());
  }

  public async getStats(): Promise<StatsDTO> {
    return proxyHelperTanStack(() => this.quotesApi.getCompanyStats());
  }

  public async generateQuoteDraftPDF(quoteUUID: string) {
    return proxyHelperTanStack(async () => this.quotesApi.generatePdfDraft(quoteUUID));
  }
}
