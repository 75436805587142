import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  quoteClientRequestsSetToStore,
  StatusEnum,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteNodeMove() {
  const { quoteNodeMovesProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteError = useQuoteError();

  return useCallback(
    (
      nodeId: string,
      targetParentId: string,
      newPrevSiblingId: string | null,
      newNextSiblingId: string | null,
    ): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteNode = quote.getTree().getNodeOrThrow(nodeId);
          const prevSibling = quoteNode.getPrevSibling();
          const nextSibling = quoteNode.getNextSibling();
          this.oldValue = {
            targetParentId: quoteNode.getParent()?.getId(),
            newPrevSiblingId: prevSibling ? prevSibling.getId() : null,
            newNextSiblingId: nextSibling ? nextSibling.getId() : null,
          };
          quoteNode.move(targetParentId, newPrevSiblingId, newNextSiblingId);
          quoteSetToStore(quote);
          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteNodeMovesProxyApi
            .moveQuoteNode({
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              currentNodeId: nodeId,
              targetParentNodeId: targetParentId,
              newPrevSiblingId: newPrevSiblingId ?? null,
              newNextSiblingId: newNextSiblingId ?? null,
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (err: any) {
          return quoteError(err.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteNode = quote.getTree().getNodeOrThrow(nodeId);
          quoteNode.move(this.oldValue.targetParentId, this.oldValue.newPrevSiblingId, this.oldValue.newNextSiblingId);
          quoteSetToStore(quote);
          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteNodeMovesProxyApi
            .moveQuoteNode({
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              currentNodeId: nodeId,
              targetParentNodeId: this.oldValue.targetParentId,
              newPrevSiblingId: this.oldValue.newPrevSiblingId,
              newNextSiblingId: this.oldValue.newNextSiblingId,
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (err: any) {
          return quoteError(err.message);
        }
      },
    }),
    [quoteError, quoteNodeMovesProxyApi, quoteSetToStore],
  );
}
