import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, useDisclosure } from '@chakra-ui/react';
import { Button, useToast } from '@graneet/lib-ui';
import type { IProject } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { useHistory } from 'react-router-dom';

import { CreateQuoteModal } from '../modals/CreateQuoteModal';
// TODO: Remove when script make is ready for import V2
// import { CreateQuoteFromImportModal } from '../modals/CreateQuoteFromImportModal/CreateQuoteFromImportModal';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useAppContext } from 'features/app/contexts/AppContext';
import { createQuote } from 'features/quote/services/quote.api';
import { CreateQuoteFromImportModal } from 'features/quote/components/modals/CreateQuoteFromImportModal/CreateQuoteFromImportModal';

interface QuoteActionsButtonsProps {
  onImportCompleted(): void;

  isDisabled?: boolean;

  project?: IProject;

  spacing?: number;
}

export const QuoteActionsButtons: FC<QuoteActionsButtonsProps> = ({
  onImportCompleted,
  isDisabled = false,
  project,
  spacing = 6,
}) => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);
  const history = useHistory();
  const { currentUser: user } = useAppContext();

  const createQuotesButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_QUOTES]);

  const importModal = useDisclosure();
  const createModal = useDisclosure();
  const quoteProxy = useQuotationProxyApis().quoteProxyApi;

  const handleQuoteCreation = useCallback(
    async (values: { name: string; version: number }) => {
      if (user) {
        if (values.version === 2) {
          const [err, createdQuote] = await quoteProxy!.createQuote({
            name: values.name,
            companyId: user.company.id,
            projectId: project?.id,
          });

          if (err) {
            toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
            return;
          }

          toast.success(t('global:words.c.success'), t('quote:creationForm.toastSuccess'));
          history.push(`/opportunities/quotation/${createdQuote.id}/edit`, {
            creation: true,
            project,
          });
        } else {
          const [err, createdQuote] = await createQuote({
            name: values.name,
            projectId: project?.id,
          });

          if (err) {
            toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
            return;
          }

          toast.success(t('global:words.c.success'), t('quote:creationForm.toastSuccess'));
          history.push(`/opportunities/quotes/${createdQuote.id}/edit`, {
            creation: true,
            project,
          });
        }
      }
    },
    [history, project, quoteProxy, t, toast, user],
  );

  return (
    <>
      <HStack spacing={spacing}>
        <Button
          onClick={importModal.onOpen}
          variant="outline"
          colorScheme="blue"
          {...createQuotesButtonDisabledProps(isDisabled)}
        >
          {t('quote:actions.import')}
        </Button>

        <Button onClick={createModal.onOpen} colorScheme="blue" {...createQuotesButtonDisabledProps(isDisabled)}>
          {t('quote:actions.create')}
        </Button>
      </HStack>

      <CreateQuoteFromImportModal
        isOpen={importModal.isOpen}
        onClose={importModal.onClose}
        onImportCompleted={onImportCompleted}
        projectId={project?.id}
        onQuoteCreated={handleQuoteCreation}
      />

      <CreateQuoteModal
        title={t('quote:creationForm.creationTitle')}
        description={t('quote:creationForm.description')}
        action={t('quote:creationForm.submit')}
        isOpen={createModal.isOpen}
        onClose={createModal.onClose}
        onSubmit={handleQuoteCreation}
      />
    </>
  );
};
