import { ConfirmDeletionModal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

interface SupplierInvoiceRevertStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: () => void;
}

export const SupplierInvoiceRevertStatusModal: FC<SupplierInvoiceRevertStatusModalProps> = ({
  isOpen,
  onClose,
  onConfirmation,
}) => {
  const { t } = useTranslation(['validationStep']);

  return (
    <ConfirmDeletionModal
      title={t('validationStep:supplierInvoice.revertStatusModal.title')}
      action={t('validationStep:supplierInvoice.revertStatusModal.action')}
      additionalContent={t('validationStep:supplierInvoice.revertStatusModal.content')}
      isOpen={isOpen}
      onClose={onClose}
      onDeleted={onConfirmation}
    />
  );
};
