import groupBy from 'lodash.groupby';

import type { IProgressStatement } from '../progress-statement/progress-statement.type';
import { PROGRESS_STATEMENT_STATUSES } from '../progress-statement/progress-statement.type';
import { divideFloating, multiplyFloating, roundFloating, sumObjects } from '../common/math.util';
import type { IDownPayment } from '../down-payment/down-payment.type';
import type { IOrderWithDirectPaymentAmounts } from '../order/order.type';
import type { ISubProject } from '../sub-project/sub-project.type';
import { SUB_PROJECT_BILLING_TYPE } from '../sub-project/sub-project.type';
import { getSubProjectWeightedVAT } from '../sub-project/sub-project.util';
import type { IProject } from '../project/project.type';
import { PRICE_REVISION_METHOD } from '../project/project.type';
import { splitBiggestObjectAndOthers } from '../common/object.util';
import type { IVatDiscountedBaseExtended } from '../vat/vat-component-extended.type';
import { mergeVATDistribution, sortVATDistribution } from '../vat/vat-rates.util';
import { computeProjectVATDistribution } from '../project/project.util';
import type { IVatComponent } from '../vat/vat-component.type';

import type { IFinalStatementConditionsResponseDTO } from './dtos/final-statement-conditions-response.dto';

export const computeFinalTotalPriceRevisionIncVAT = (progressStatementList: IProgressStatement[]): number => {
  const progressStatementIndexedBySubProject = groupBy(
    progressStatementList,
    (progressStatement) => progressStatement.subProject?.id,
  );

  return Object.values(progressStatementIndexedBySubProject).reduce((acc, progressStatements) => {
    const totalExVAT = sumObjects(progressStatements, 'priceRevisionDefinitiveExVAT');

    const { subProject } = progressStatements[0];
    if (!subProject) {
      throw new Error('Sub project relation is missing');
    }

    const subProjectPriceRevisionIncVAT = subProject.hasReversalOfLiability
      ? totalExVAT
      : roundFloating(multiplyFloating(totalExVAT, 1 + getSubProjectWeightedVAT(subProject)));

    return acc + subProjectPriceRevisionIncVAT;
  }, 0);
};

export const needsFinalStatementToCloseProject = (subProjects: ISubProject[]): boolean =>
  subProjects.some((subProject) => subProject.billingType === SUB_PROJECT_BILLING_TYPE.PROGRESS);

export const getFinalStatementConditions = (
  subProject: ISubProject,
  lastProgressStatement: IProgressStatement | null,
  downPayment: IDownPayment | null,
  directPaymentOrders?: IOrderWithDirectPaymentAmounts[],
): IFinalStatementConditionsResponseDTO => {
  const isProjectTotallyBilled = subProject.amountToBeBilledExVAT === 0;

  const isDownPaymentTotallyAmortized = !downPayment || downPayment.amountIncVATAmortized === downPayment.amountIncVAT;

  const areAllDirectPaymentOrderTotallyInvoiced =
    directPaymentOrders?.every(
      ({ amountPreviousDirectPaymentsExVAT: totalInvoicedAmountExVAT, amountExVAT: orderAmountExVAT }) =>
        totalInvoicedAmountExVAT === orderAmountExVAT,
    ) ?? true;

  const isLastProgressStatementAccepted = lastProgressStatement
    ? [PROGRESS_STATEMENT_STATUSES.ACCEPTED, PROGRESS_STATEMENT_STATUSES.COMPLETED].includes(
        lastProgressStatement.status,
      )
    : true;

  const isFinalStatementAvailable =
    isProjectTotallyBilled &&
    isDownPaymentTotallyAmortized &&
    isLastProgressStatementAccepted &&
    areAllDirectPaymentOrderTotallyInvoiced;

  return {
    isProjectTotallyBilled,
    isDownPaymentTotallyAmortized,
    directPaymentOrdersCount: directPaymentOrders?.length ?? 0,
    isLastProgressStatementAccepted,
    areAllDirectPaymentOrderTotallyInvoiced,
    isFinalStatementAvailable,
  };
};

// Get amounts exc. and inc. VAT, with VAT distribution
export const getFinalStatementAmounts = (
  project: IProject,
  subProjects: ISubProject[],
  progressStatements: IProgressStatement[],
): {
  amountExVAT: number;
  amountIncVAT: number;
  vatDistribution: (IVatComponent & { amountPriceRevision?: number; basePriceRevision?: number })[];
  finalTotalPriceRevisionExVAT: number;
  finalTotalPriceRevisionIncVAT: number;
} => {
  const finalTotalPriceRevisionExVAT = sumObjects(progressStatements, 'priceRevisionDefinitiveExVAT');
  let finalTotalPriceRevisionIncVAT: number;
  let { amountExVAT, amountIncVAT } = project;
  let vatDistribution: {
    vatRate: number;
    amount: number;
    basePriceRevision?: number;
    amountPriceRevision?: number;
  }[];

  if (project.priceRevisionMethod === PRICE_REVISION_METHOD.PRORATA_VAT) {
    const progressStatementVatDistributions = progressStatements.map((progressStatement) => {
      const vatDistributionWithCalculatedBases = progressStatement.vatDistribution.map((vatObject) => {
        const { vatRate, amount } = vatObject;
        const { discountedBase } = vatObject as IVatDiscountedBaseExtended;

        // In case discountedBase isn't available we have to calculate it
        // We could improve that calculating price revision amount deeper in the vat distribution calculation
        const simulatedBase = vatRate ? divideFloating(amount, vatRate) : 0;
        return {
          amount,
          vatRate,
          calculatedBase: Number.isFinite(discountedBase) ? discountedBase : simulatedBase,
        };
      });

      const { biggest, others } = splitBiggestObjectAndOthers(vatDistributionWithCalculatedBases, 'calculatedBase');
      const vatDistributionWithPriceRevision = others.map((vatObject) => {
        const { calculatedBase, vatRate, amount } = vatObject;
        const ratio =
          progressStatement.nonCumulativeProgressAmountExVAT !== 0
            ? divideFloating(calculatedBase, progressStatement.nonCumulativeProgressAmountExVAT || 0)
            : 0;
        const basePriceRevision = roundFloating(
          multiplyFloating(progressStatement.priceRevisionDefinitiveExVAT ?? 0, ratio),
        );
        const amountPriceRevision = roundFloating(multiplyFloating(basePriceRevision, vatRate));

        return {
          vatRate,
          amount,
          basePriceRevision,
          amountPriceRevision,
        };
      });
      if (biggest) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { vatRate, amount } = biggest;
        const restPriceRevision =
          (progressStatement.priceRevisionDefinitiveExVAT ?? 0) -
          sumObjects(vatDistributionWithPriceRevision, 'basePriceRevision');
        const amountPriceRevision = roundFloating(multiplyFloating(restPriceRevision, vatRate));
        vatDistributionWithPriceRevision.push({
          vatRate,
          amount,
          basePriceRevision: restPriceRevision,
          amountPriceRevision,
        });
      }

      return vatDistributionWithPriceRevision;
    });

    finalTotalPriceRevisionIncVAT = progressStatementVatDistributions.reduce(
      (acc, vatDistributionProgressStatement) =>
        acc +
        vatDistributionProgressStatement.reduce(
          (subAcc, vatObject) => subAcc + vatObject.basePriceRevision + vatObject.amountPriceRevision,
          0,
        ),
      0,
    );

    amountExVAT += finalTotalPriceRevisionExVAT;
    amountIncVAT += finalTotalPriceRevisionIncVAT;
    vatDistribution = mergeVATDistribution(progressStatementVatDistributions.flat());
  } else {
    finalTotalPriceRevisionIncVAT = computeFinalTotalPriceRevisionIncVAT(progressStatements);
    vatDistribution = computeProjectVATDistribution(subProjects);
  }

  return {
    amountExVAT,
    amountIncVAT,
    vatDistribution: sortVATDistribution(vatDistribution),
    finalTotalPriceRevisionExVAT,
    finalTotalPriceRevisionIncVAT,
  };
};
