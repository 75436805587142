import { Stack, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { Price } from '@graneet/lib-ui';

interface ProjectSummaryStatsCardProps {
  topStat: { label: string; stat: number | null };

  bottomStat: { label: string; stat: ReactNode };
}

export const ProjectSummaryStats: FC<ProjectSummaryStatsCardProps> = ({ topStat, bottomStat }) => (
  <Stack w="100%">
    <Stat>
      <StatLabel>{topStat.label}</StatLabel>
      <StatNumber>
        <Price amount={topStat.stat} />
      </StatNumber>
    </Stat>

    <Stat>
      <StatLabel>{bottomStat.label}</StatLabel>
      <StatNumber>{bottomStat.stat}</StatNumber>
    </Stat>
  </Stack>
);
