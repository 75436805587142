import type { FC } from 'react';
import { useLayoutEffect, startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { LexicalEditor, RangeSelection, TextNode } from 'lexical';
import { COMMAND_PRIORITY_LOW, KEY_BACKSPACE_COMMAND, $getSelection, $isRangeSelection, $isTextNode } from 'lexical';
import { compact } from 'lodash-es';
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { $isHeadingNode } from '@lexical/rich-text';
import { $isLinkNode } from '@lexical/link';
import { $getNearestNodeOfType } from '@lexical/utils';

import type { RichTextVariableConfiguration } from '../../constants';
import { isLexicalEditorEmpty } from '../../utils/rich-text.util';

import type { MenuResolution } from './components/VariableMenu';
import { VariableMenu } from './components/VariableMenu';
import { $createVariableNode, VariableNode } from './VariableNode';
import { useMenuAnchorRef } from './hooks/useMenuAnchorRef';
import { VariableDropDown } from './components/VariableDropDown';
import { VariableOption } from './classes/VariableOption';
import { VariableHeaderOption } from './classes/VariableHeaderOption';
import { VariableErrorTooltip } from './components/VariableErrorTooltip';
import { ReplacedVariableNode } from './ReplacedVariableNode';

const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = `\\b[A-Z][^\\s${PUNCTUATION}]`;

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION,
};

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const TRIGGERS = ['#'].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = `[^${TRIGGERS}${PUNC}\\s]`;

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  `(?:` +
  `\\.[ |$]|` + // E.g. "r. " in "Mr. Smith"
  ` |` + // E.g. " " in "Josh Duck"
  `[${PUNC}]|` + // E.g. "-' in "Salier-Hellendag"
  `)`;

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  `(^|\\s|\\()([${TRIGGERS}]((?:${VALID_CHARS}${VALID_JOINS}){0,${LENGTH_LIMIT}}))$`,
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  `(^|\\s|\\()([${TRIGGERS}]((?:${VALID_CHARS}){0,${ALIAS_LENGTH_LIMIT}}))$`,
);

function checkForAtSignVariables(text: string, minMatchLength: number) {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getTextUpToAnchor(selection: RangeSelection): string | null {
  const { anchor } = selection;
  if (anchor.type !== 'text') {
    return null;
  }
  const anchorNode = anchor.getNode();
  if (!anchorNode.isSimpleText()) {
    return null;
  }
  const anchorOffset = anchor.offset;
  return anchorNode.getTextContent().slice(0, anchorOffset);
}

function getQueryTextForSearch(editor: LexicalEditor): string | null {
  let text = null;
  editor.getEditorState().read(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }
    text = getTextUpToAnchor(selection);
  });
  return text;
}

function isSelectionOnEntityBoundary(editor: LexicalEditor, offset: number): boolean {
  if (offset !== 0) {
    return false;
  }
  return editor.getEditorState().read(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const { anchor } = selection;
      const anchorNode = anchor.getNode();
      const prevSibling = anchorNode.getPreviousSibling();
      return $isTextNode(prevSibling) && prevSibling.isTextEntity();
    }
    return false;
  });
}

function tryToPositionRange(leadOffset: number, range: Range, editorWindow: Window): boolean {
  const domSelection = editorWindow.getSelection();
  if (domSelection === null || !domSelection.isCollapsed) {
    return false;
  }
  const { anchorNode } = domSelection;
  const startOffset = leadOffset;
  const endOffset = domSelection.anchorOffset;

  if (anchorNode == null || endOffset == null) {
    return false;
  }

  try {
    range.setStart(anchorNode, startOffset);
    range.setEnd(anchorNode, endOffset);
  } catch (error) {
    return false;
  }

  return true;
}

interface VariablePluginProps {
  configuration: RichTextVariableConfiguration;

  onChange: (state: string) => void;
}

export const VariablePlugin: FC<VariablePluginProps> = ({ configuration, onChange }) => {
  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);

  const [resolution, setResolution] = useState<MenuResolution | null>(null);
  const anchorElementRef = useMenuAnchorRef(resolution, setResolution);

  const optionsWithClasses = useMemo(
    () =>
      compact(
        configuration.options.map((option) => {
          switch (option.type) {
            case 'header':
              return new VariableHeaderOption(option.text);
            case 'option':
              if (
                !queryString ||
                configuration.optionsText[option.value].label.toLowerCase().includes(queryString?.toLowerCase())
              ) {
                return new VariableOption(configuration.optionsText[option.value].label, option.value);
              }
              return null;
            default:
              throw new Error('Not implemented');
          }
        }),
      ),
    [configuration.options, configuration.optionsText, queryString],
  );

  /**
   * Handle an edge case on initial render where node after replacement do not trigger a new update
   */
  useLayoutEffect(() => {
    if (configuration.injectValueInLabel) {
      return editor.registerMutationListener(ReplacedVariableNode, () => {
        editor.update(() => {
          const editorState = editor.getEditorState();
          const isEmpty = isLexicalEditorEmpty(editorState);
          onChange(isEmpty ? '' : JSON.stringify(editorState.toJSON()));
        });
      });
    }
    return () => {};
  }, [configuration.injectValueInLabel, editor, onChange]);

  const onSelectOption = useCallback(
    (selectedOption: VariableHeaderOption | VariableOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        if (!(selectedOption instanceof VariableOption)) {
          throw new Error('selectedOption must be a VariableOption class');
        }

        // eslint-disable-next-line no-underscore-dangle
        const optionText = editor._config.theme.variables.options.optionsText[selectedOption.value];
        const text =
          // eslint-disable-next-line no-underscore-dangle
          editor._config.theme.variables.options.injectValueInLabel && optionText.value
            ? optionText.value
            : optionText.label;
        const variableNode = $createVariableNode(
          {
            text,
            value: selectedOption.value,
          },
          nodeToReplace
            ? {
                format: nodeToReplace.getFormat(),
                detail: nodeToReplace.getDetail(),
                mode: nodeToReplace.getMode(),
                style: nodeToReplace.getStyle(),
              }
            : undefined,
        );
        if (nodeToReplace) {
          nodeToReplace.replace(variableNode);
        }
        variableNode.select();
        closeMenu();
      });
    },
    [editor],
  );

  const closeTypeahead = useCallback(() => {
    setResolution(null);
  }, []);

  useEffect(() => {
    const updateListener = () => {
      editor.getEditorState().read(() => {
        // eslint-disable-next-line no-underscore-dangle
        const editorWindow = editor._window || window;
        const range = editorWindow.document.createRange();
        const selection = $getSelection();
        const text = getQueryTextForSearch(editor);

        // Disallow variables in header and link
        let hasParentHeader = false;
        selection
          ?.getNodes()[0]
          .getParents()
          .forEach((parentNode) => {
            if ($isHeadingNode(parentNode) || $isLinkNode(parentNode)) {
              hasParentHeader = true;
            }
          });
        if (hasParentHeader) {
          return;
        }

        if (!$isRangeSelection(selection) || !selection.isCollapsed() || text === null || range === null) {
          closeTypeahead();
          return;
        }

        const match = checkForAtSignVariables(text, 0);
        setQueryString(match ? match.matchingString : null);

        if (match !== null && !isSelectionOnEntityBoundary(editor, match.leadOffset)) {
          const isRangePositioned = tryToPositionRange(match.leadOffset, range, editorWindow);
          if (isRangePositioned !== null) {
            startTransition(() =>
              setResolution({
                getRect: () => range.getBoundingClientRect(),
                match,
              }),
            );
            return;
          }
        }
        closeTypeahead();
      });
    };

    const removeUpdateListener = editor.registerUpdateListener(updateListener);

    return () => {
      removeUpdateListener();
    };
  }, [closeTypeahead, editor]);

  useEffect(
    () =>
      editor.registerCommand<KeyboardEvent>(
        KEY_BACKSPACE_COMMAND,
        (event) => {
          const selection = $getSelection();

          /*
           * When variable as multiple names, backspace deletes only one word at a time.
           * This is a workaround to delete the whole variable in this case
           */
          if ($isRangeSelection(selection)) {
            const selectedVariableNode = $getNearestNodeOfType(selection.anchor.getNode(), VariableNode as any);
            if (selectedVariableNode) {
              selectedVariableNode.remove();
              event.preventDefault();
              event.stopImmediatePropagation();
            }
          }

          return true;
        },
        COMMAND_PRIORITY_LOW,
      ),
    [editor],
  );

  const [hoveredVariable, setHoveredVariable] = useState<EventTarget | null>(null);

  return (
    <>
      <NodeEventPlugin
        nodeType={VariableNode}
        eventType="click"
        eventListener={(e) => {
          function focusTextContent(element: EventTarget | null) {
            // Create a range and select the text content
            const range = document.createRange();
            range.selectNodeContents(element as any);

            // Create a selection and add the range to it
            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(range);
          }

          focusTextContent(e.target);
        }}
      />

      <NodeEventPlugin
        nodeType={VariableNode}
        eventType="mouseenter"
        eventListener={(e) => {
          setHoveredVariable(e.target);
        }}
      />
      <NodeEventPlugin
        nodeType={VariableNode}
        eventType="mouseleave"
        eventListener={() => {
          setHoveredVariable(null);
        }}
      />

      <VariableErrorTooltip target={hoveredVariable} configuration={configuration} />

      {resolution !== null && editor !== null && (
        <VariableMenu<VariableHeaderOption | VariableOption>
          close={closeTypeahead}
          resolution={resolution}
          editor={editor}
          anchorElementRef={anchorElementRef}
          options={optionsWithClasses}
          menuRenderFn={VariableDropDown}
          shouldSplitNodeWithQuery
          onSelectOption={onSelectOption}
        />
      )}
    </>
  );
};
