import type {
  AccountingExportPaginatedResponse,
  AccountingExportResponseDTO,
  IAccountingExport,
  IAccountingCreateExportDTO,
  IAccountingDownloadExportDTO,
  IAccountingInvoicesCountDTOResponseDTO,
  IAccountingInvoicesCountDTO,
  IAccountingCheckDTO,
  AccountingAccountsNeeds,
  ExportEntity,
} from '@graneet/business-logic';
import type { QueryClient } from '@tanstack/react-query';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { type UsePaginationOptions, usePaginationQuery, useToast } from '@graneet/lib-ui';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { apiNew } from 'features/api/services/apiNew.service';

const FACTORY_NAME = 'accounting-export';
const ACCOUNTING_PATH = '/accounting-exports';

async function invalidateAccountingExportQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [FACTORY_NAME],
  });
}

const accountingExportKeyFactory = createQueryKeys(FACTORY_NAME, {
  get: (queryParams: URLSearchParams) => ({
    queryKey: [ACCOUNTING_PATH, queryParams],
    queryFn: () => apiNew.get<URLSearchParams, AccountingExportPaginatedResponse>(ACCOUNTING_PATH, queryParams),
  }),
  getCountInvoicesPerJournal: (dto?: IAccountingInvoicesCountDTO) => ({
    queryKey: [ACCOUNTING_PATH, dto],
    queryFn: () => {
      if (!dto) {
        return Promise.resolve<IAccountingInvoicesCountDTOResponseDTO>({
          journalSales: {
            exportable: 0,
            total: 0,
          },
          journalPurchases: {
            exportable: 0,
            total: 0,
          },
          journalBank: {
            exportable: 0,
            total: 0,
          },
        });
      }

      return apiNew.post<IAccountingInvoicesCountDTO, IAccountingInvoicesCountDTOResponseDTO>(
        `${ACCOUNTING_PATH}/count`,
        dto,
      );
    },
  }),
});

export function useAccountingExports(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) => queryClient.fetchQuery(accountingExportKeyFactory.get(urlSearchParams)),
    options,
  );
}

export function useAccountingExportCountInvoicesPerJournal<Entity extends ExportEntity = ExportEntity>(
  dto: IAccountingInvoicesCountDTO<Entity> | undefined,
) {
  return useQuery(accountingExportKeyFactory.getCountInvoicesPerJournal(dto));
}

export function useAccountingExportCreate() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IAccountingCreateExportDTO) =>
      apiNew.post<IAccountingCreateExportDTO, IAccountingExport>(ACCOUNTING_PATH, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateAccountingExportQueries(queryClient);
    },
  });
}

export function useAccountingExportGenerate() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IAccountingDownloadExportDTO) =>
      apiNew.post<IAccountingDownloadExportDTO, AccountingExportResponseDTO>(`${ACCOUNTING_PATH}/export`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateAccountingExportQueries(queryClient);
    },
  });
}

export function useAccountingExportCheckNeeds() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IAccountingCheckDTO) =>
      apiNew.post<IAccountingCheckDTO, AccountingAccountsNeeds>(`${ACCOUNTING_PATH}/check`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateAccountingExportQueries(queryClient);
    },
  });
}

export function useAccountingExportCancel() {
  const { t } = useTranslation(['global', 'accounting']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => apiNew.patch<never, IAccountingExport>(`${ACCOUNTING_PATH}/${id}/cancel`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateAccountingExportQueries(queryClient);
      toast.success(t('accounting:toasts.successCancel'));
    },
  });
}
