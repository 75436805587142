import { GRANEET_CONTEXT, GRANEET_CONTEXT_HEADER } from '@graneet/business-logic';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import * as Sentry from '@sentry/react';

export const logAxiosErrors = (error: AxiosError) => {
  if (error.response) {
    return `Response error ${error.response} ${error.status}`;
  }
  if (error.request) {
    return `Request failed ${error.request} ${error.status}`;
  }
  return `Unknown error ${error} ${error.status}`;
};

const addMultiPartContentType = (axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'method'>) => ({
  ...(axiosConfig || {}),
  headers: {
    ...(axiosConfig?.headers || {}),
    'Content-Type': 'multipart/form-data',
  },
});

enum AXIOS_METHODS {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

class ApiService {
  private async request<T = unknown, R = unknown>(
    method: AXIOS_METHODS,
    path: string,
    dataOrParams?: T,
    axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'method'>,
  ): Promise<R> {
    try {
      const config: AxiosRequestConfig = {
        url: path,
        method,
        ...(axiosConfig || {}),
      };

      config.headers = {
        ...config.headers,
        [GRANEET_CONTEXT_HEADER]: GRANEET_CONTEXT.CLIENT,
      };

      if ([AXIOS_METHODS.POST, AXIOS_METHODS.PUT, AXIOS_METHODS.PATCH, AXIOS_METHODS.DELETE].includes(method)) {
        // current method is `post`, `put`, `delete` or `patch`
        config.data = dataOrParams;
      } else {
        // with other methods, we'll use query parameters
        config.params = dataOrParams;
      }
      const result = await axios.request(config);
      return result && result.data;
    } catch (error) {
      console.error(
        `An error occurred while requesting to ${path} with verb ${method} and data or params`,
        dataOrParams,
      );
      Sentry.captureException(error, {
        extra: {
          method,
          path,
          dataOrParams,
          axiosConfig,
        },
      });

      const err = logAxiosErrors(error as AxiosError);
      console.error(err);
      throw new Error(err);
    }
  }

  async get<T = unknown, R = unknown>(path: string, queryParams?: T): Promise<R> {
    return this.request(AXIOS_METHODS.GET, path, queryParams);
  }

  async post<T = unknown, R = unknown>(
    path: string,
    data?: T,
    axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'method'>,
  ): Promise<R> {
    return this.request(AXIOS_METHODS.POST, path, data, axiosConfig);
  }

  async postWithMultiPart<T = unknown, R = unknown>(
    path: string,
    data?: T,
    axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'method'>,
  ): Promise<R> {
    return this.request(AXIOS_METHODS.POST, path, data, addMultiPartContentType(axiosConfig));
  }

  async put<T = unknown, R = unknown>(path: string, data: T): Promise<R> {
    return this.request(AXIOS_METHODS.PUT, path, data);
  }

  async putWithMultiPart<T = unknown, R = unknown>(
    path: string,
    data: T,
    axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'method'>,
  ): Promise<R> {
    return this.request(AXIOS_METHODS.PUT, path, data, addMultiPartContentType(axiosConfig));
  }

  async patch<T = unknown, R = unknown>(
    path: string,
    data?: T,
    axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'method'>,
  ): Promise<R> {
    return this.request(AXIOS_METHODS.PATCH, path, data, axiosConfig);
  }

  async patchWithMultiPart<T = unknown, R = unknown>(
    path: string,
    data: T,
    axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'method'>,
  ): Promise<R> {
    return this.request(AXIOS_METHODS.PATCH, path, data, addMultiPartContentType(axiosConfig));
  }

  async delete<T = unknown, R = unknown>(path: string, queryParams?: T): Promise<R> {
    return this.request(AXIOS_METHODS.DELETE, path, queryParams);
  }
}

const apiNew = new ApiService();
export { apiNew };
