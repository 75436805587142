import { NavigationModal, useCurrency, ModalSubtitle, useToast, formatNumberToVatRate } from '@graneet/lib-ui';
import { Box, HStack } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { func, number } from 'prop-types';

import { EditJobMargin } from '../EditQuoteJobRow/EditJobMargin';
import { QUOTE_JOB_KEY } from '../../services/quote-job.util';
import { isComponentFieldName } from '../../forms/quote-job-edit-modal.form';
import { EditQuoteJobDisbursement } from '../EditQuoteJobRow/EditQuoteJobDisbursement';

import { JobEditionModalFields } from './JobEditionModalFields';
import { JobEditionModalInformationSummary } from './JobEditionModalInformationSummary';

import { useComponentTypes } from 'features/component-type/services/component-type.api';
import { useComponentContext } from 'features/quote-component/contexts/component.context';
import { useEditComponent } from 'features/quote-component/hooks/useEditComponent';
import { QuoteComponentEditionTable } from 'features/quote-component/components/QuoteComponentEditionTable';
import { fetchJobComponents } from 'features/quote-component/services/quote-component.api';
import { COMPONENT_FIELDS } from 'features/quote-component/services/quote-component.util';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useQuoteReversalOfLiability } from 'features/quote/hooks/useQuote';
import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';
import { useEditJob } from 'features/quote-job/hooks/useEditJob';
import { useEditJobMargin } from 'features/quote-job/hooks/useEditJobMargin';
import { useEditJobUnitPrice } from 'features/quote-job/hooks/useEditJobUnitPrice';
import { useEditJobComponentMargin } from 'features/quote-job/hooks/useEditJobComponentMargin';
import { useAutoNumberingQuoteRow } from 'features/quote/hooks/useAutoNumberingQuoteRow';
import { useJob } from 'features/quote-job/hooks/useJob';

export const QuoteJobEditView = ({ jobId, onClose }) => {
  const { t } = useTranslation(['quote', 'global']);
  const toast = useToast();
  const componentTypes = useComponentTypes();

  const [hasErrorComponentMarginUpdate, setHasErrorComponentMarginUpdate] = useState(false);
  const [hasErrorJobMarginUpdate, setHasErrorJobMarginUpdate] = useState(false);

  const { setComponents, startAnotherUpdate, setForm } = useComponentContext();

  const { mapNumberToAmount } = useCurrency();
  const { getJob, getCurrentQuoteData } = useQuoteEditContext();
  const hasReversalOfLiability = useQuoteReversalOfLiability();
  const { isReadOnlyView } = useQuoteDisplayContext();

  // Callbacks for jobs
  const editJob = useEditJob();
  const editJobMargin = useEditJobMargin({ updateComponentContext: true });
  const editJobUnitPrice = useEditJobUnitPrice({ updateComponentContext: true });

  // Callbacks for components
  const editComponent = useEditComponent();
  const editJobComponentMargin = useEditJobComponentMargin();

  const onUpdateAfterBlur = useCallback(
    async (name, rawValue, data, { setFormValues }) => {
      const { componentId, mapValue, key } = data;

      const value = mapValue ? mapValue(rawValue) : rawValue;

      const handleComponentUpdate = async () => {
        if (!key) return;

        const dto = { [key]: value };
        // If the component type is updated, we might force the unit to match component type unit
        if (key === COMPONENT_FIELDS.COMPONENT_TYPE_ID) {
          const componentType = componentTypes.data.find((type) => type.id === value);
          if (componentType?.unit) {
            dto.unit = componentType.unit;
          }
        }
        if (key === COMPONENT_FIELDS.QUANTITY) {
          const { value: quantity, content: quantityFormula } = value;
          dto.quantity = quantity;
          dto.quantityFormula = quantityFormula;
        }

        switch (key) {
          case COMPONENT_FIELDS.TOTAL_MARGIN: {
            const hasBeenUpdated = await editJobComponentMargin(jobId, componentId, dto);

            if (!hasBeenUpdated) setHasErrorComponentMarginUpdate(true);
            break;
          }
          default:
            await editComponent(componentId, dto);
            break;
        }
      };

      const handleJobUpdate = async () => {
        const apiCall = async (fieldName, newValue) => {
          switch (name) {
            case QUOTE_JOB_KEY.TOTAL_MARGIN: {
              const jobMarginHasBeenUpdated = await editJobMargin(jobId, newValue);

              if (!jobMarginHasBeenUpdated) setHasErrorJobMarginUpdate(true);
              break;
            }
            case QUOTE_JOB_KEY.UNIT_PRICE_EX_VAT: {
              await editJobUnitPrice(jobId, newValue);
              break;
            }
            default:
              if (fieldName === QUOTE_JOB_KEY.QUANTITY) {
                const { value: quantity, content: quantityFormula } = newValue;
                await editJob(jobId, { quantity, quantityFormula });
              } else {
                await editJob(jobId, { [fieldName]: newValue });
              }

              // when user tries to remove a vat rate, fill vat rate field with value returned by the backend (it's it
              // quote lot parent default vat rate)
              if (fieldName === QUOTE_JOB_KEY.VAT_RATE && !Number.isFinite(value)) {
                setFormValues({
                  vatRate: formatNumberToVatRate(getCurrentQuoteData().jobs[jobId].vatRate),
                });
              }
              break;
          }
        };

        const hasError = await apiCall(name, value);

        if (hasError) {
          // On error, change reset values to their previous state
          const {
            margin: { totalMargin },
            unitDisbursementExVAT,
            unitPriceExVAT,
          } = getJob(jobId);
          const newUnitDisbursementExVAT = Number.isFinite(unitDisbursementExVAT)
            ? mapNumberToAmount(unitDisbursementExVAT)
            : null;

          const newUnitPriceExVAT = Number.isFinite(unitPriceExVAT) ? mapNumberToAmount(unitPriceExVAT) : null;

          setFormValues({
            totalMargin,
            unitDisbursementExVAT: newUnitDisbursementExVAT,
            unitPriceExVAT: newUnitPriceExVAT,
          });
        }
      };

      startAnotherUpdate();

      if (isComponentFieldName(name)) {
        await handleComponentUpdate();
      } else {
        await handleJobUpdate();
      }
    },
    [
      startAnotherUpdate,
      componentTypes.data,
      editComponent,
      editJobComponentMargin,
      jobId,
      editJobMargin,
      editJobUnitPrice,
      editJob,
      getCurrentQuoteData,
      getJob,
      mapNumberToAmount,
    ],
  );

  const formOptions = useMemo(() => ({ onUpdateAfterBlur }), [onUpdateAfterBlur]);
  const form = useForm(formOptions); // generic is `QuoteJobEditModalForm`
  const { isValid: isFormValid } = useFormStatus(form);
  const { setFormValues } = form;

  useEffect(() => {
    setForm(form);
  }, [setForm, form]);

  const autoCode = useAutoNumberingQuoteRow({
    id: jobId,
    type: 'job',
  });

  useEffect(() => {
    const { isFinite } = Number;
    const {
      jobs: { [jobId]: job },
    } = getCurrentQuoteData();

    if (job) {
      const unitDisbursementExVAT = isFinite(job.unitDisbursementExVAT)
        ? mapNumberToAmount(job.unitDisbursementExVAT)
        : null;
      // unitPriceExVAT must be null if unitDisbursementExVAT is undefined to keep <Multiplication/> component pristine
      const unitPriceExVAT =
        isFinite(unitDisbursementExVAT) && isFinite(job.unitPriceExVAT) ? mapNumberToAmount(job.unitPriceExVAT) : null;

      const initialData = {
        code: job.code,
        autoCode,
        description: job.description,
        unit: job.unit,
        quantity: isFinite(job.quantity) ? job.quantity : null,
        vatRate: isFinite(job.vatRate) ? formatNumberToVatRate(job.vatRate) : null,
        totalMargin: isFinite(job.margin.totalMargin) ? job.margin.totalMargin : null,
        unitDisbursementExVAT,
        unitPriceExVAT,
      };

      initialData.quantity = {
        value: job.quantity,
        content: job.quantityFormula,
      };

      setFormValues(initialData);
      setHasErrorJobMarginUpdate(false);
    }
  }, [jobId, hasErrorJobMarginUpdate, getCurrentQuoteData, mapNumberToAmount, setFormValues, autoCode]);

  useEffect(() => {
    if (!jobId) return;
    const {
      jobs: { [jobId]: job },
    } = getCurrentQuoteData();

    const fetchComponents = async () => {
      const [err, response] = await fetchJobComponents(jobId);

      if (err) {
        toast.error(t('global:errors.error'));
        return;
      }
      setComponents(response.components);
      setHasErrorComponentMarginUpdate(false);
    };

    if (job.components.length === 0) {
      setComponents([]);
      return;
    }
    // Defer components fetching to improve render performance
    setTimeout(async () => {
      await fetchComponents();
    }, 200);
  }, [
    jobId,
    toast,
    hasErrorComponentMarginUpdate,
    t,
    setComponents,
    setFormValues,
    mapNumberToAmount,
    getCurrentQuoteData,
  ]);

  const job = useJob(jobId);

  if (!job) {
    return null;
  }

  return (
    <Box overflow="hidden">
      <Form form={form}>
        <ModalSubtitle>{t('quote:jobEdition.information')}</ModalSubtitle>
        <JobEditionModalFields hasReversalOfLiability={hasReversalOfLiability} isReadOnly={isReadOnlyView} />

        <HStack mt={6} spacing={8} align="stretch">
          <Box display="flex" flex={12} backgroundColor="gray.100" p={3} justifyItems="center" flexDirection="column">
            {job.isHiddenCost ? (
              <EditQuoteJobDisbursement jobId={jobId} isReadOnly={isReadOnlyView} />
            ) : (
              <EditJobMargin jobId={jobId} isReadOnly={isReadOnlyView} />
            )}
          </Box>
          <Box flex={7} px={2} backgroundColor="gray.100">
            <JobEditionModalInformationSummary jobId={jobId} />
          </Box>
        </HStack>

        <QuoteComponentEditionTable isReadOnly={isReadOnlyView} isHiddenCost={job.isHiddenCost} />

        {!isReadOnlyView && (
          <NavigationModal.SecondaryButton onClick={onClose} px={4}>
            {t('quote:jobEdition.cancel')}
          </NavigationModal.SecondaryButton>
        )}
        <NavigationModal.PrimaryButton isDisabled={!isFormValid} onClick={onClose} px={4}>
          {t(isReadOnlyView ? 'global:words.c.close' : 'quote:jobEdition.submit')}
        </NavigationModal.PrimaryButton>
      </Form>
    </Box>
  );
};

QuoteJobEditView.propTypes = {
  jobId: number.isRequired,
  onClose: func.isRequired,
};
