import { Box, CircularProgress } from '@chakra-ui/react';
import type { ChangeEvent } from 'react';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { SimpleAddIcon } from '../../Icons/v2/SimpleAddIcon';

import type { ImageItem, ImageListProps } from './types';
import { convertBase64 } from './utils';
import { useGetImageBoxSize } from './useGetImageBoxSize';

export type AddImageHandle = {
  addImage: () => void;
};

export const AddImage = forwardRef<
  AddImageHandle,
  Pick<ImageListProps, 'onImageUpload' | 'noPreview' | 'showAddFile' | 'size'> & {
    onFilesAdded: (images: ImageItem[]) => void;
    isDisplayed: boolean;
  }
>(({ onImageUpload, onFilesAdded, isDisplayed, noPreview, showAddFile, size }, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddFileClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  useImperativeHandle(ref, () => ({
    addImage: () => {
      handleAddFileClick();
    },
  }));

  const uploadImages = useCallback(
    async (
      files: {
        id: string;
        file: File;
      }[],
    ) => {
      if (!onImageUpload) return;
      await onImageUpload(files);
    },
    [onImageUpload],
  );

  const handleFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (isLoading) return;

      const { files } = e.target;
      const images: ImageItem[] = [];
      if (files && files.length > 0) {
        setIsLoading(true);
        // eslint-disable-next-line no-plusplus
        const newFiles = await Promise.all(
          Array.from(files).map(async (file) => {
            const id = uuid();
            // eslint-disable-next-line no-await-in-loop
            const url = (await convertBase64(file)) ?? '';
            images.push({
              url,
              id,
              isLoading: true,
              size,
            });
            return {
              id,
              file,
            };
          }),
        );
        setIsLoading(false);
        if (!noPreview) {
          onFilesAdded(images);
        }
        await uploadImages(newFiles);
        if (!noPreview) {
          onFilesAdded(images.map((img) => ({ ...img, isLoading: false })));
        }
        inputRef.current!.value = '';
      }
    },
    [isLoading, noPreview, uploadImages, size, onFilesAdded],
  );

  const boxSize = useGetImageBoxSize(size);

  return isDisplayed ? (
    <Box
      boxSize={boxSize}
      borderRadius="0.5rem"
      border="1px dashed #E5E7EB"
      display={showAddFile ? 'flex' : 'none'}
      justifyContent="center"
      alignItems="center"
      background="white"
      position="relative"
      onClick={handleAddFileClick}
      _hover={{
        cursor: 'pointer',
        background: 'rgba(0, 21, 63, 0.05)',
      }}
      transition="background 0.3s"
    >
      <Box display="none">
        <input
          type="file"
          onChange={handleFileChange}
          ref={inputRef}
          value=""
          multiple
          accept="image/jpeg,image/png,image/svg+xml"
        />
      </Box>
      <Box
        position="absolute"
        borderRadius="0.5rem"
        top="0"
        left="0"
        right="0"
        bottom="0"
        background="rgba(0, 0, 0, 0.40)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        opacity={isLoading ? 1 : 0}
        transition="opacity 0.3s"
      >
        {isLoading && <CircularProgress isIndeterminate color="white" size="1.5rem" />}
      </Box>
      <SimpleAddIcon stroke="#D2D6DB" />
    </Box>
  ) : (
    <Box display="none">
      <input
        type="file"
        onChange={handleFileChange}
        ref={inputRef}
        value=""
        multiple
        accept="image/jpeg,image/png,image/svg+xml"
      />
    </Box>
  );
});
