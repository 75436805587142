import { useCallback, useEffect } from 'react';
import { useToast } from '@graneet/lib-ui';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useQuoteNodeCanBeMoved() {
  const { quote } = useQuote();
  const toast = useToast();

  useEffect(() => {}, [quote]);

  return useCallback(
    (
      nodeId: string,
      targetParentId: string,
      newPrevSiblingId: string | null,
      newNextSiblingId: string | null,
    ): boolean => {
      try {
        const quoteNode = quote.getTree().getNodeOrThrow(nodeId);
        const canBeMoved = quoteNode.canBeMoved(targetParentId, newPrevSiblingId, newNextSiblingId);
        return canBeMoved;
      } catch (err: any) {
        toast.error(err.message);
        return false;
      }
    },
    [quote, toast],
  );
}
