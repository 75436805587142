import type { ISupplierInvoice, RequiredByKeys } from '@graneet/business-logic';
import { SUPPLIER_INVOICE_MODE } from '@graneet/business-logic';
import type { FC } from 'react';
import { uniqBy } from 'lodash-es';
import { formatDataOrEmpty, Tooltip } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Text } from '@chakra-ui/react';

import { ComponentTypeBadge } from 'features/component-type/components/ComponentTypeBadge';

interface ISupplierInvoiceComponentTypeBadgeProps {
  supplierInvoice: RequiredByKeys<ISupplierInvoice, 'supplierInvoiceItems'>;
}

export const SupplierInvoiceComponentTypeBadge: FC<ISupplierInvoiceComponentTypeBadgeProps> = ({ supplierInvoice }) => {
  const { t } = useTranslation(['supplierInvoices']);
  if (
    supplierInvoice.mode === SUPPLIER_INVOICE_MODE.DETAILED &&
    (supplierInvoice.supplierInvoiceItems || []).length > 0
  ) {
    const types = uniqBy(
      supplierInvoice.supplierInvoiceItems
        .map((item) => item.type)
        .filter((type) => type !== undefined && type !== null),
      (type) => type?.id,
    );

    if (types.length === 1) {
      return <ComponentTypeBadge type={types[0]} />;
    }
    if (types.length === 0) {
      return formatDataOrEmpty(null);
    }

    const label = (
      <List>
        {types.map((type) => (
          <ListItem display="flex" key={type.id}>
            {type.name}
          </ListItem>
        ))}
      </List>
    );

    return (
      <Tooltip label={label} placement="right">
        <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" color="gray.400">
          {t('supplierInvoices:type.multi')}
        </Text>
      </Tooltip>
    );
  }

  return supplierInvoice.type ? <ComponentTypeBadge type={supplierInvoice.type} /> : formatDataOrEmpty(null);
};
