import type { FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import type { ModalProps as ChakraModalProps } from '@chakra-ui/react';
import {
  Modal as ModalChakra,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalCloseButton as ChakraModalCloseButton,
} from '@chakra-ui/react';

import { ScrollProvider } from '../../hooks';

import { PrimaryButton } from './PrimaryButton';
import { SecondaryButton } from './SecondaryButton';
import { CloseButton } from './CloseButton';
import { configureDefaultLabels } from './configureDefaultLabels';
import { Footer } from './Footer';
import { BUTTON_TYPE, useModalButtons } from './useModalButtons';
import { ModalPrimaryButton } from './ModalPrimaryButton';
import { ModalSecondaryButton } from './ModalSecondaryButton';
import { ModalCloseButton } from './ModalCloseButton';
import { ModalButtonsProvider } from './ModalButtonsContext';

export interface ModalProps extends ChakraModalProps {
  children: ReactNode;
  title?: string | ReactNode;
  size?: string;
  fullHeight?: boolean;
  noFooter?: boolean;
  maxHeight?: string;
  footerContent?: ReactNode;
}

export const ModalComponent: FC<ModalProps> = (props) => {
  const {
    children,
    onClose,
    title = '',
    isOpen = false,
    size = 'xl',
    fullHeight = false,
    isCentered = true,
    noFooter = false,
    maxHeight = undefined,
    footerContent,
    ...otherProps
  } = props;

  // Ref of ModalBody is transmitted to Infinite scroll because we have to get component size
  // HACK: dirty fix for bad infinite scroll behaviour (#2412)
  const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);

  const buttonsContext = useModalButtons();

  const {
    buttons: {
      [BUTTON_TYPE.PRIMARY]: primaryButtonsProps,
      [BUTTON_TYPE.SECONDARY]: secondaryButtonsProps,
      [BUTTON_TYPE.CLOSE]: closeButtonsProps,
    },
  } = buttonsContext;

  const secondaryButtons =
    secondaryButtonsProps &&
    secondaryButtonsProps.map((secondaryButtonProps, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <SecondaryButton {...secondaryButtonProps} key={index} />
    ));

  const [closeButtonProps] = closeButtonsProps || [];
  const closeButton = closeButtonProps && [<CloseButton {...closeButtonProps} onClick={onClose} />];

  const leftButtons = closeButton || secondaryButtons;

  const primaryButtons = useMemo(
    () =>
      !!primaryButtonsProps &&
      primaryButtonsProps.map((primaryButtonProps, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PrimaryButton {...primaryButtonProps} key={index} />
      )),
    [primaryButtonsProps],
  );

  return (
    <ModalChakra isOpen={isOpen} onClose={onClose} size={size} isCentered={isCentered} {...otherProps}>
      <ModalButtonsProvider value={buttonsContext}>
        <ScrollProvider value={bodyRef}>
          <ModalOverlay backdropFilter="blur(2px)" />
          <ModalContent
            alignSelf={fullHeight ? 'stretch' : undefined}
            maxHeight={maxHeight || undefined}
            borderRadius="12px"
            border="1px solid var(--Base-Solid-Grey-Grey-200, #E5E7EB)"
            background="white"
            boxShadow="0px 4px 6px -2px rgba(13, 18, 28, 0.08), 0px 12px 16px -4px rgba(13, 18, 28, 0.14), 0px 0px 0px 2px #FFF inset"
          >
            {title && (
              <ModalHeader fontSize="1.125rem" fontWeight="regular" color="primaryLight">
                {title}
              </ModalHeader>
            )}
            <ModalBody ref={setBodyRef} background="white" borderRadius="12px">
              {children}
            </ModalBody>
            {!noFooter && (
              <Footer secondaryButtons={leftButtons} primaryButtons={primaryButtons} footerContent={footerContent} />
            )}
          </ModalContent>
        </ScrollProvider>
      </ModalButtonsProvider>
    </ModalChakra>
  );
};

export const Modal = Object.assign(ModalComponent, {
  PrimaryButton: ModalPrimaryButton,
  SecondaryButton: ModalSecondaryButton,
  Close: ChakraModalCloseButton,
  CloseButton: ModalCloseButton,
  configureDefaultLabels,
});
