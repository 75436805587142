import type { IOvertime, IOvertimeCreateDto, IOvertimeUpdateDto } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { apiNew } from 'features/api/services/apiNew.service';

const OVERTIME_TYPES_PATH = '/overtimes';

const overtimesFactoryKey = createQueryKeys('overtimes', {
  get: () => ({
    queryKey: [OVERTIME_TYPES_PATH],
    queryFn: () => apiNew.get<never, IOvertime[]>(OVERTIME_TYPES_PATH),
  }),
});

export function useOvertimeTypes() {
  return useSuspenseQuery(overtimesFactoryKey.get());
}

export function useCreateOvertimeType() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'planning']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IOvertimeCreateDto) => apiNew.post<IOvertimeCreateDto, IOvertime>(OVERTIME_TYPES_PATH, dto),
    onSuccess: async () => {
      toast.success(t('planning:overtimeCard.addModal.overtimeTypeCreated'));
      await queryClient.invalidateQueries(overtimesFactoryKey.get());
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('planning:overtimeCard.addModal.overtimeTypeAddError'));
    },
  });
}

export function useUpdateOvertimeType() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'planning']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: string; dto: IOvertimeCreateDto }) =>
      apiNew.patch<IOvertimeUpdateDto, IOvertime>(`${OVERTIME_TYPES_PATH}/${params.id}`, params.dto),
    onSuccess: async () => {
      toast.success(t('planning:overtimeCard.editModal.overtimeTypeEdited'));
      await queryClient.invalidateQueries(overtimesFactoryKey.get());
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('planning:overtimeCard.editModal.overtimeTypeEditError'));
    },
  });
}

export function useDeleteOvertimeType() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'planning']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => apiNew.delete<never, void>(`${OVERTIME_TYPES_PATH}/${id}`),
    onSuccess: async () => {
      toast.success(t('planning:overtimeCard.deleteModal.overtimeTypeDeletionSuccess'));
      await queryClient.invalidateQueries(overtimesFactoryKey.get());
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('planning:overtimeCard.deleteModal.overtimeTypeDeletionError'));
    },
  });
}
