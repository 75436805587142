import type { IQuoteComponent } from '@graneet/business-logic';
import type { FormulaFieldValue } from '@graneet/lib-ui';

type QuoteComponentKeys =
  | keyof Pick<IQuoteComponent, 'code' | 'name' | 'unit' | 'quantity' | 'unitDisbursementExVAT'>
  | 'componentTypeId'
  | 'totalMargin';

type QuoteComponentFieldName<T extends QuoteComponentKeys> = `component-${number}-${T}`;

export const BUILD_COMPONENT_FORM_FIELD = <T extends QuoteComponentKeys>(
  componentId: number,
  field: T,
): QuoteComponentFieldName<T> => `component-${componentId}-${field}`;

export const isComponentFieldName = (fieldName: keyof QuoteJobEditModalForm) => /^component-/.test(fieldName);

export interface QuoteJobEditModalForm {
  [quoteComponentComponentTypeId: QuoteComponentFieldName<'componentTypeId'>]: number;

  [quoteComponentCode: QuoteComponentFieldName<'code'>]: string | null | undefined;

  [quoteComponentName: QuoteComponentFieldName<'name'>]: string | null | undefined;

  [quoteComponentUnit: QuoteComponentFieldName<'unit'>]: string | null | undefined;

  [quoteComponentQuantity: QuoteComponentFieldName<'quantity'>]: number | null | undefined | FormulaFieldValue;

  [quoteComponentUnitDisbursementExVAT: QuoteComponentFieldName<'unitDisbursementExVAT'>]: number | null | undefined;

  [quoteComponentTotalMargin: QuoteComponentFieldName<'totalMargin'>]: number | null | undefined;

  // Complete with field in quote job modal
}
