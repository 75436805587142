import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { compact } from 'lodash-es';

import type { DownPaymentActionsParams } from './down-payment.actions.type';
import {
  getCancelAction,
  getDownloadPDFAction,
  getEditAction,
  getOpenMailingAction,
  getPreviewPDFAction,
} from './down-payment.actions.util';

export function getDownPaymentCompletedActions(params: DownPaymentActionsParams): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  const openMailingAction = getOpenMailingAction(params);
  const cancelWithCreditAction = getCancelAction(params, true);

  if (cancelWithCreditAction) {
    actions.push({
      type: 'secondary',
      ...cancelWithCreditAction,
    });
  }

  if (openMailingAction) {
    actions.push({
      type: 'secondary',
      ...openMailingAction,
    });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([
      getEditAction(params),
      getPreviewPDFAction(params),
      getCancelAction(params, false),
      getOpenMailingAction(params),
      getDownloadPDFAction(params),
    ]),
  });

  return actions;
}
