import type { SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';
import type {} from '@graneet/business-logic';

import type { AGColDef } from '../../types';
import { ImageCell } from '../../components/columns/ImageCell/ImageCell';

export function useImageColumnDef(isEditable: boolean) {
  const suppressKeyboardEvent = useCallback((params: SuppressKeyboardEventParams) => {
    const { key } = params.event;

    if (params.editing) {
      return false;
    }

    return ![
      'PageUp',
      'PageDown',
      'Tab',
      'ArrowLeft',
      'ArrowUp',
      'ArrowRight',
      'ArrowDown',
      'Enter',
      'Backspace',
    ].includes(key);
  }, []);

  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      cellRenderer: ImageCell,
      cellRendererParams: {
        isEditable,
      },
      colId: 'image',
      headerName: label,
      index,
      sortable: false,
      suppressAutoSize: true,
      suppressKeyboardEvent,
      suppressMovable: false,
      suppressSizeToFit: true,

      width,
      minWidth: 125,
    }),
    [isEditable, suppressKeyboardEvent],
  );
}
