import type { FlexProps, IconProps } from '@chakra-ui/react';

export const RICH_TEXT_ICON_PROPS = (isSelected: boolean): IconProps & FlexProps => ({
  boxSize: 6,
  px: 1,
  cursor: 'pointer',
  borderRadius: 5,
  color: isSelected ? 'greenBrand.light' : 'gray.800',
  _hover: { background: 'gray.100' },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
