import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, GridItem, Stack, Text } from '@chakra-ui/react';
import { Card, DataTable, EllipsisText, TextField } from '@graneet/lib-ui';
import type { ISupplier } from '@graneet/business-logic';

import type { AccountingSupplierEditionBlockForm } from '../../forms/accounting.form';
import { getAccountingSupplierAuxiliaryAccountFieldName } from '../../forms/accounting.form';

import { Rule } from 'features/form/rules/Rule';

interface AccountingSupplierEditionBlockProps {
  suppliers: Pick<ISupplier, 'id' | 'code' | 'name'>[];

  haveRequiredFields?: boolean;
}

export const AccountingSupplierEditionBlock: FC<AccountingSupplierEditionBlockProps> = ({
  suppliers,
  haveRequiredFields = false,
}) => {
  const { t } = useTranslation(['accounting']);

  return (
    <Stack>
      <Text fontSize="lg" color="gray.800">
        {t('accounting:parameters.sections.supplier')}
      </Text>

      <Card>
        <DataTable numberOfColumns={1}>
          {suppliers.map((supplier) => {
            const fieldName = getAccountingSupplierAuxiliaryAccountFieldName(supplier.id);

            return (
              <DataTable.Row
                key={supplier.id}
                label={
                  <Grid templateColumns="6rem auto">
                    <GridItem>
                      <EllipsisText color="gray.600">{supplier.code}</EllipsisText>
                    </GridItem>

                    <GridItem>
                      <EllipsisText>{supplier.name}</EllipsisText>
                    </GridItem>
                  </Grid>
                }
              >
                <DataTable.Cell>
                  <TextField<AccountingSupplierEditionBlockForm>
                    name={fieldName}
                    placeholder={t('accounting:parameters.configPlaceholder')}
                  >
                    {haveRequiredFields && <Rule.IsRequired />}
                  </TextField>
                </DataTable.Cell>
              </DataTable.Row>
            );
          })}
        </DataTable>
      </Card>
    </Stack>
  );
};
