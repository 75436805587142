import type { ISupplierInvoice } from '@graneet/business-logic';
import type { FC } from 'react';
import { DocumentStatusCard, formatDateOrEmpty, useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { getSupplierInvoiceStatusCardIcon } from './cards/SupplierInvoiceStatusCard/supplier-invoice.actions';
import { SupplierInvoiceLateDaysBadge } from './badges/SupplierInvoiceLateDaysBadge';
import { SupplierInvoiceStatusBadge } from './badges/SupplierInvoicesStatusBadge';

interface SupplierInvoiceDocumentStatusCardProps {
  supplierInvoice: ISupplierInvoice;
}

export const SupplierInvoiceDocumentStatusCard: FC<SupplierInvoiceDocumentStatusCardProps> = ({ supplierInvoice }) => {
  const { t } = useTranslation(['supplierInvoices']);

  const { formatAsAmount } = useCurrency();

  return (
    <DocumentStatusCard
      icon={getSupplierInvoiceStatusCardIcon(supplierInvoice.status)}
      title={supplierInvoice.name ?? ''}
      subtitle={formatAsAmount(supplierInvoice.amountIncVAT)}
      footer={t('supplierInvoices:fields.createdAt', { date: formatDateOrEmpty(supplierInvoice.createdAt, 'LL') })}
      bottomContent={<SupplierInvoiceLateDaysBadge supplierInvoice={supplierInvoice} />}
      badgeComponent={<SupplierInvoiceStatusBadge status={supplierInvoice.status} />}
    />
  );
};
