import { useMemo } from 'react';
import {
  TextField,
  PercentageField,
  formatVatRateToNumber,
  RichTextField,
  FormulaField,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Grid } from '@chakra-ui/react';
import { bool } from 'prop-types';

import { useStore } from 'store/store';
import { useSendClickRoundedQuantityButtonEvent } from 'features/analytic/hooks/useSendClickRoundedQuantityButtonEvent';

export const JobEditionModalFields = ({ hasReversalOfLiability, isReadOnly }) => {
  const { t } = useTranslation(['quote']);

  const vatRateData = useMemo(() => ({ mapValue: (v) => (Number.isFinite(v) ? formatVatRateToNumber(v) : null) }), []);

  const sendClickRoundEvent = useSendClickRoundedQuantityButtonEvent();

  const autoNumberingIsActivated = useStore((state) => state.autoNumberingIsActivated);

  return (
    <Grid templateColumns="1fr 5fr 1fr 1fr 1fr" gap={4}>
      {autoNumberingIsActivated ? (
        <TextField name="autoCode" label={t('quote:fields.code')} isReadOnly />
      ) : (
        <TextField name="code" label={t('quote:fields.code')} isReadOnly={isReadOnly} />
      )}
      <RichTextField
        name="description"
        label={t('quote:fields.description')}
        isDisabled={isReadOnly}
        configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
      />
      <TextField name="unit" label={t('quote:fields.unit')} isReadOnly={isReadOnly} />
      <FormulaField
        name="quantity"
        label={t('quote:fields.quantity')}
        isReadOnly={isReadOnly}
        sendClickRoundEvent={sendClickRoundEvent}
      />
      {hasReversalOfLiability ? null : (
        <PercentageField name="vatRate" data={vatRateData} label={t('quote:fields.vat')} isReadOnly={isReadOnly} />
      )}
    </Grid>
  );
};

JobEditionModalFields.propTypes = {
  hasReversalOfLiability: bool,
  isReadOnly: bool,
};

JobEditionModalFields.defaultProps = {
  hasReversalOfLiability: false,
  isReadOnly: false,
};
