import type { FC } from 'react';
import { useCallback } from 'react';
import { Modal, useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import type { ILibraryComponent } from '@graneet/business-logic';

import { useLibraryComponentCreate } from '../../services/library-component.api';
import type { LibraryComponentFieldsForm } from '../LibraryComponentFields';
import { LibraryComponentFields } from '../LibraryComponentFields';

import { QueryWrapper } from 'features/api/components/QueryWrapper';

const FORM_ID = 'library-component-create';

interface LibraryComponentAddModalProps {
  modalControls: UseDisclosureReturn;

  onComponentCreated(libraryComponentOnAdded: ILibraryComponent): void | Promise<void>;
}

export const LibraryComponentAddModal: FC<LibraryComponentAddModalProps> = ({ modalControls, onComponentCreated }) => {
  const { t } = useTranslation(['global', 'library']);
  const { mapAmountToNumber } = useCurrency();

  const form = useForm<LibraryComponentFieldsForm>();
  const { isValid: isFormValid } = useFormStatus(form);

  const libraryComponentCreateMutation = useLibraryComponentCreate('creation');

  const onSubmit = useCallback(
    async (formValues: LibraryComponentFieldsForm) => {
      const { unitDisbursementExVAT, ...rest } = formValues;

      libraryComponentCreateMutation.mutate(
        {
          ...rest,
          unitDisbursementExVAT: mapAmountToNumber(unitDisbursementExVAT!),
        },
        {
          onSuccess: (component) => {
            modalControls.onClose();
            onComponentCreated(component);
          },
        },
      );
    },
    [libraryComponentCreateMutation, mapAmountToNumber, modalControls, onComponentCreated],
  );

  return (
    <Modal {...modalControls} title={t('library:addLibraryComponentModal.title')} size="5xl" autoFocus={false}>
      <QueryWrapper>
        <Form form={form} id={FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
          <Text mb={4}>{t('library:addLibraryComponentModal.description')}</Text>
          <LibraryComponentFields />

          <Modal.Close isDisabled={libraryComponentCreateMutation.isPending} />

          <Modal.PrimaryButton
            isLoading={libraryComponentCreateMutation.isPending}
            isDisabled={!isFormValid}
            form={FORM_ID}
            type="submit"
          >
            {t('library:addLibraryComponentModal.cta')}
          </Modal.PrimaryButton>
        </Form>
      </QueryWrapper>
    </Modal>
  );
};
