import type { FC } from 'react';
import { AlertMessage, Modal, List, getChecklistIcon } from '@graneet/lib-ui';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { IFinalStatementConditionsResponseDTO } from '@graneet/business-logic';

interface FinalStatementCheckModalProps {
  onClose(): void;
  isOpen: boolean;
  finalStatementCheckDTO: IFinalStatementConditionsResponseDTO;
  projectName: string;
}

export const FinalStatementCheckModal: FC<FinalStatementCheckModalProps> = ({
  onClose,
  isOpen,
  finalStatementCheckDTO,
  projectName,
}) => {
  const {
    isProjectTotallyBilled,
    isDownPaymentTotallyAmortized,
    isLastProgressStatementAccepted,
    areAllDirectPaymentOrderTotallyInvoiced,
    directPaymentOrdersCount,
  } = finalStatementCheckDTO;

  const { t } = useTranslation(['project', 'global']);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" title={t('project:finalStatementModal.title')}>
      <AlertMessage type="error">{t('project:finalStatementModal.alertMessage')}</AlertMessage>
      <Text mt={6}>{t('project:finalStatementModal.messageInfo', { name: projectName })}</Text>

      <List my={2}>
        <List.Item
          icon={getChecklistIcon(isProjectTotallyBilled)}
          label={
            isProjectTotallyBilled
              ? t('project:finalStatementModal.totallyBilled.success')
              : t('project:finalStatementModal.totallyBilled.error')
          }
        />
        <List.Item
          icon={getChecklistIcon(isDownPaymentTotallyAmortized)}
          label={
            isDownPaymentTotallyAmortized
              ? t('project:finalStatementModal.downPaymentTotallyAmortized.success')
              : t('project:finalStatementModal.downPaymentTotallyAmortized.error')
          }
        />

        {directPaymentOrdersCount > 0 && (
          <List.Item
            icon={getChecklistIcon(areAllDirectPaymentOrderTotallyInvoiced)}
            label={
              areAllDirectPaymentOrderTotallyInvoiced
                ? t('project:finalStatementModal.areAllDirectPaymentOrderTotallyInvoiced.success')
                : t('project:finalStatementModal.areAllDirectPaymentOrderTotallyInvoiced.error')
            }
          />
        )}

        <List.Item
          icon={getChecklistIcon(isLastProgressStatementAccepted)}
          label={
            isLastProgressStatementAccepted
              ? t('project:finalStatementModal.lastProgressStatementAccepted.success')
              : t('project:finalStatementModal.lastProgressStatementAccepted.error')
          }
        />
      </List>

      <Modal.PrimaryButton onClick={onClose}>{t('global:words.c.back')}</Modal.PrimaryButton>
    </Modal>
  );
};
