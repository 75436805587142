import { Flex, Stack, Text } from '@chakra-ui/react';
import type { IQuote, IQuoteLot } from '@graneet/business-logic';
import { Card, Multiplication, useCurrency } from '@graneet/lib-ui';
import { Form, useForm } from 'graneet-form';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { QUOTE_TOTAL_FIELDS, QUOTE_TOTAL_MARGIN_FIELD } from 'features/quote/constants/quotes.constant';

interface FormValues {
  disbursement: number;
  totalMargin: number;
  total: number;
}

interface QuoteMarginCardProps {
  rootLot: IQuoteLot;
  quote: IQuote;
}

export const QuoteMarginCard: FC<QuoteMarginCardProps> = ({ rootLot, quote }) => {
  const { t } = useTranslation(['quote', 'margin']);
  const form = useForm<FormValues>();
  const { formatAsAmount, mapNumberToAmount } = useCurrency();

  useEffect(() => {
    form.setFormValues({
      disbursement: mapNumberToAmount(rootLot.disbursementExVAT),
      totalMargin: rootLot.margin.totalMargin,
      total: mapNumberToAmount(quote.amountExVAT),
    });
  }, [rootLot, quote, form, mapNumberToAmount]);

  const profit = useMemo(() => quote.amountExVAT - rootLot.disbursementExVAT, [quote, rootLot]);

  return (
    <Card pt={5}>
      <Form form={form}>
        <Multiplication>
          <Multiplication.Field
            name="disbursement"
            label={t('quote:jobsStep.fields.totalDisbursementExVAT')}
            computedName={QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT}
            isCurrency
            isDisabled
          />

          <Multiplication.Field
            name="totalMargin"
            label={t('margin:fields.totalMargin')}
            computedName={QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT}
            isDisabled
            scale={3}
            min={0}
          />

          <Multiplication.Field
            name="total"
            label={t('quote:fields.priceExVAT')}
            computedName={QUOTE_TOTAL_MARGIN_FIELD}
            isDisabled
            isCurrency
            tooltipProps={{
              p: 0,
            }}
            tooltip={
              <Card>
                <Stack textColor="gray.800">
                  <Flex justifyContent="space-between" alignItems="center" w="15rem">
                    <Text textColor="gray.900" fontWeight="500" whiteSpace="nowrap">
                      {t('margin:fields.profitExVAT')}
                    </Text>
                    <Text textColor="gray.900" fontWeight="600" whiteSpace="nowrap">
                      {formatAsAmount(profit)}
                    </Text>
                  </Flex>
                </Stack>
              </Card>
            }
          />
        </Multiplication>
      </Form>
    </Card>
  );
};
