import type {
  IDownPayment,
  ISubProject,
  ISubProjectParametersUpdateDTO,
  ISubProjectUpdateDTO,
  ISubProjectGetByProjectDTO,
  IDownPaymentDTO,
  ISubProjectByProjectListingResponseDTO,
  IDownPaymentWithRelations,
  ISubProjectHasBillingStartedResponseDTO,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { SUB_PROJECT_FACTORY_NAME } from './sub-project.factory-name';

import { apiNew } from 'features/api/services/apiNew.service';

const SUB_PROJECT_PATH_API = '/sub-projects';

const subProjectKeyFactory = createQueryKeys(SUB_PROJECT_FACTORY_NAME, {
  getById: (subProjectId: string) => ({
    queryKey: [SUB_PROJECT_PATH_API, 'subProjects', subProjectId],
    queryFn: () => apiNew.get<ISubProjectParametersUpdateDTO, ISubProject>(`${SUB_PROJECT_PATH_API}/${subProjectId}`),
  }),
  getSubProjectHasBillingStarted: (subProjectId: string) => ({
    queryKey: [SUB_PROJECT_PATH_API, 'subProject-has-billing-started', subProjectId],
    queryFn: () =>
      apiNew.get<never, ISubProjectHasBillingStartedResponseDTO>(
        `${SUB_PROJECT_PATH_API}/${subProjectId}/has-billing-started`,
      ),
  }),
  getAllByProjectId: (projectId: number) => ({
    queryKey: [SUB_PROJECT_PATH_API, 'projects', projectId],
    queryFn: () =>
      apiNew.get<ISubProjectGetByProjectDTO, ISubProjectByProjectListingResponseDTO>(SUB_PROJECT_PATH_API, {
        projectId,
      }),
  }),
  getSubProjectDownPayment: (subProjectId: string | null) => ({
    queryKey: [SUB_PROJECT_PATH_API, subProjectId ?? 'null', 'down-payment'],
    queryFn: () =>
      subProjectId === null
        ? Promise.resolve(null)
        : apiNew.get<never, IDownPayment | null>(`${SUB_PROJECT_PATH_API}/${subProjectId}/down-payment/last`),
  }),
});

export function useSubProject(subProjectId: string) {
  return useSuspenseQuery(subProjectKeyFactory.getById(subProjectId));
}

export function useSubProjectHasBillingStarted(subProjectId: string) {
  return useSuspenseQuery(subProjectKeyFactory.getSubProjectHasBillingStarted(subProjectId));
}

export function useSubProjectsByProject(projectId: number) {
  return useSuspenseQuery(subProjectKeyFactory.getAllByProjectId(projectId));
}

export function useSubProjectsByProjectQuery(projectId?: number) {
  return useQuery({
    ...subProjectKeyFactory.getAllByProjectId(projectId!),
    enabled: !!projectId,
  });
}

export function useSubProjectsByProjectLazy() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  return useMutation({
    mutationFn: (projectId: number) =>
      apiNew.get<ISubProjectGetByProjectDTO, ISubProjectByProjectListingResponseDTO>(SUB_PROJECT_PATH_API, {
        projectId,
      }),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useUpdateSubProject() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: string; dto: ISubProjectUpdateDTO }) =>
      apiNew.patch<ISubProjectUpdateDTO, ISubProject>(`${SUB_PROJECT_PATH_API}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: (response) => {
      if (response.project?.id) {
        queryClient.invalidateQueries(subProjectKeyFactory.getAllByProjectId(response.project?.id));
      }

      queryClient.invalidateQueries(subProjectKeyFactory.getById(response.id));
    },
  });
}

export function useUpdateSubProjectBillingParams() {
  const { t } = useTranslation(['global', 'project']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: string; dto: ISubProjectParametersUpdateDTO }) =>
      apiNew.patch<ISubProjectParametersUpdateDTO, ISubProject>(
        `${SUB_PROJECT_PATH_API}/${params.id}/parameters`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (response) => {
      if (response.project?.id) {
        await queryClient.invalidateQueries(subProjectKeyFactory.getAllByProjectId(response.project?.id));
      }
      await queryClient.invalidateQueries(subProjectKeyFactory.getById(response.id));

      toast.success(t('project:parameters.toasts.save.success', { projectName: response.name }));
    },
  });
}

export function useSubProjectDownPayment(subProjectId: string | null) {
  return useSuspenseQuery(subProjectKeyFactory.getSubProjectDownPayment(subProjectId));
}

export function useCreateOrUpdateSubProjectDownPayment() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: string; dto: IDownPaymentDTO }) =>
      apiNew.put<IDownPaymentDTO, IDownPaymentWithRelations>(
        `${SUB_PROJECT_PATH_API}/${params.id}/down-payment`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(subProjectKeyFactory.getById(params.id));
      queryClient.invalidateQueries(subProjectKeyFactory.getSubProjectDownPayment(params.id));
    },
  });
}
