// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LexicalJSON = Record<string, any>;
export type LexicalNode = { children?: LexicalNode[]; type: string; value?: string; text?: string };
export type LexicalNodeOrRootNode = LexicalNode | { root: LexicalNode };

const LEXICAL_ROOT_PROPS = { direction: 'ltr', format: '', indent: 0, type: 'root', version: 1 };
const LEXICAL_PARAGRAPH_NODE_PROPS = { direction: 'ltr', format: 'left', indent: 0, type: 'paragraph', version: 1 };
const LEXICAL_TEXT_NODE_PROPS = { detail: 0, format: 0, mode: 'normal', style: '', type: 'text', version: 1 };

const plainTextLineToLexicalLine = (line: string): LexicalJSON => ({
  children: [{ ...LEXICAL_TEXT_NODE_PROPS, text: line }],
  ...LEXICAL_PARAGRAPH_NODE_PROPS,
});

/**
 * @description prepend some plain text to an existing lexical body.
 * @param text the text to prepend, separated by newline characters
 * @param lexical the existing lexical body
 */
export function mergeTextToLexical(text: string, lexical: LexicalJSON = {}): LexicalJSON {
  return {
    root: {
      ...LEXICAL_ROOT_PROPS,
      // Convert newline-separated text to lexical format
      children: text
        .split('\n')
        .map(plainTextLineToLexicalLine)
        // Merge with existing lexical data
        .concat(lexical?.root?.children ?? []),
    },
  };
}

export function lexicalToPlaintext(node: LexicalNodeOrRootNode): string {
  if ('root' in node) {
    return lexicalToPlaintext(node.root);
  }
  let res = '';
  if (node.text) {
    res += node.text;
  }
  res += (node.children ?? []).map(lexicalToPlaintext).join('');
  return res;
}

export function mergeLexicalToLexical(
  text: LexicalJSON,
  lexical?: LexicalJSON,
  separators: LexicalJSON[] = [LEXICAL_PARAGRAPH_NODE_PROPS],
): LexicalJSON {
  if (!lexical) {
    return text;
  }

  return {
    ...text,
    root: {
      ...text.root,
      children: [...text.root.children, ...separators, ...lexical.root.children],
    },
  };
}

export const buildLexicalRootFromText = (text: string): LexicalJSON => ({
  root: {
    ...LEXICAL_ROOT_PROPS,
    children: text.split('\n').map(plainTextLineToLexicalLine),
  },
});
