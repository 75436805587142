import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import type { IPowensPaymentCreateDTO } from '@graneet/business-logic';

import { apiNew } from 'features/api/services/apiNew.service';

const POWENS_PATH = '/powens';

export function usePowensGetUrlConnection() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  return useMutation({
    mutationFn: () => apiNew.get<never, string>(`${POWENS_PATH}/authentication/webview`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function usePowensGetUrlManage() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  return useMutation({
    mutationFn: (connectionId: string) => apiNew.get<never, string>(`${POWENS_PATH}/manage/webview/${connectionId}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function usePowensCreatePayments() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  return useMutation({
    mutationFn: (dto: IPowensPaymentCreateDTO) =>
      apiNew.post<IPowensPaymentCreateDTO, void>(`${POWENS_PATH}/init/payments`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}
