import type { ExportEntity } from '../export/export-entity.type';
import type { GRANEET_PDF_COLORS } from '../common/constants/colors.constant';
import type { PDF_TEMPLATES_TYPES } from '../pdf/pdf.type';
import type { LEDGER_TYPES } from '../ledger/ledger.type';
import type { ThirdParties } from '../third-party/third-party.type';
import type { ACCOUNTING_JOURNAL } from '../accounting/accounting.constant';
import type { EmailTemplateType } from '../email-template/email-template.type';
import type { EMAIL_ENTITY_TYPE } from '../mailing/email.type';
import type { SUPPLIER_INVOICE_MODE } from '../supplier-invoice/supplier-invoice.type';
import type { QUOTE_ITEM_TYPE_OF_PRICING } from '../quote/quotation.type';

export const TRACKING_TEMP_KEY_PREFIX = 'tracking:';

export const TRACKING_USER_TEMP_KEY_PREFIX: 'tracking:company:' = `${TRACKING_TEMP_KEY_PREFIX}company:`;

export enum TRACKING_EVENT_ENUM {
  SALE_PAYMENT_CREATED = 'salePayment:created',
  BUDGET_CREATED = 'budget:created',
  BUDGET_EDITED = 'budget:edited',
  CUSTOM_DISCOUNT_CREATED = 'customDiscount:created',
  CUSTOM_DISCOUNT_ACCEPTED = 'customDiscount:accepted',
  CUSTOM_DISCOUNT_ADVANCED = 'customDiscount:advanced',
  DEDUCTION_CREATED = 'deduction:created',
  DEDUCTION_ACCEPTED = 'deduction:accepted',
  TIMESLOT_CREATED = 'timeSlot:created',
  TIMESLOT_EDITED = 'timeSlot:edited',
  PROJECT_CREATED = 'project:created',
  SALE_INVOICE_CREATED = 'saleInvoice:created',
  SALE_INVOICE_EDITED = 'saleInvoice:edited',
  LIBRARY_CREATED = 'library:created',
  LIBRARY_EDITED = 'library:edited',
  ORDER_CREATED = 'order:created',
  ORDER_EDITED = 'order:edited',
  SUPPLIER_INVOICE_CREATED = 'supplierInvoice:created',
  SUPPLIER_INVOICE_EDITED = 'supplierInvoice:edited',
  SUPPLIER_INVOICE_PAYEMENT_CREATED = 'supplierInvoicePayment:created',
  QUOTE_CREATED = 'quote:created',
  QUOTE_EDITED = 'quote:edited',
  QUOTE_IMPORTED = 'quote:imported',
  QUOTE_RESTORED = 'quote:restored',
  QUOTE_PDF_GENERATED = 'quotePdf:generated',
  QUOTE_JOB_IMPORT = 'quoteJob:import',
  QUOTE_COMPONENT_IMPORT = 'quoteComponent:import',
  QUOTE_ACTION_UNDO_REDO = 'quoteAction:undoRedo',
  QUOTA_REACHED = 'quota:reached',
  LEDGER_ENABLED = 'automaticNumbering:activated',
  LEDGER_DISABLED = 'automaticNumbering:deactivated',
  THIRD_PARTY_ENABLED = 'integration:activated',
  THIRD_PARTY_DISABLED = 'integration:deactivated',
  OPTION_CREATED = 'option:created',
  OPTION_VALIDATED = 'option:validated',
  OPTION_ACCEPTED = 'option:accepted',
  HIDDEN_COST_CREATED = 'hiddenCost:created',
  HIDDEN_COST_ACCEPTED = 'hiddenCost:accepted',
  ORDER_ITEMS_IMPORT = 'orderItems:import',
  QUOTE_ACCEPTED = 'quote:accepted',
  QUANTITY_CALCULATED = 'quantity:calculated',
  QUANTITY_ROUNDED = 'quantity:rounded',
  SIGNUP_STARTED = 'signup:started',
  SIGNUP_COMPLETED = 'signup:completed',
  TABLE_EXPORT_GENERATED = 'tableExport:generated',
  ACCOUNTING_EXPORT_GENERATED = 'accountingExport:generated',
  PENALTY_CREATED = 'penalty:created',
  CONTACT_CREATED = 'contact:created',
  CONTACT_ASSOCIATED = 'contact:associated',
  EMAIL_SENT = 'email:sent',
  EMAIL_TEMPLATE_CREATED = 'emailTemplate:created',
  EMAIL_TEMPLATE_EDITED = 'emailTemplate:edited',
  EMAIL_REMINDER_SENT = 'emailReminder:sent',
  EMAIL_REMINDER_PLAN_CREATED = 'emailReminder:planCreated',
  EMAIL_REMINDER_PLAN_EDITED = 'emailReminder:planEdited',
  EMAIL_REMINDER_PLAN_DELETED = 'emailReminder:planDeleted',
  BATCH_IMPORTED = 'batch:imported',
  PDF_COLOR_CHANGED = 'pdfColor:changed',
  HISTORY_ATTACHMENT_DOWNLOAD = 'history:attachmentDownload',
  TAG_ADDED = 'tag:addded',
  PLANNING_COMPONENT_TYPE_CREATED = 'planning:componentTypeCreated',
  PLANNING_TIMESLOT_COMPONENT_TYPE_UPDATED = 'planning:timeSlotComponentTypeUpdated',
  PLANNING_TIMESLOT_APPROVED = 'planning:timeSlotApproved',
  PLANNING_WORKER_CREATED = 'planning:workerCreated',
  PLANNING_WORKER_UPDATED = 'planning:workerUpdated',
  PLANNING_DEFAULT_MEAL_EXPENSE_UPDATED = 'planning:defaultMealExpenseUpdated',
  PLANNING_OVERTIME_TYPE_CREATED = 'planning:overtimeTypeCreated',
  PLANNING_OVERTIME_TYPE_UPDATED = 'planning:overtimeTypeUpdated',
  PLANNING_KM_EXPENSE_ADDED = 'planning:kmExpenseAdded',
  PLANNING_KM_EXPENSE_UPDATED = 'planning:kmExpenseUpdated',
  PLANNING_TIMESLOT_SHARED = 'planning:timeSlotShared',
  AUTOSUGGEST_SELECTED = 'autosuggest:selected',
  STATUS_BULKUPDATED = 'status:bulkupdated',
  SUPPLIER_INVOICE_WORKFLOW_VALIDATION = 'supplierInvoice:workflow:validation',
  WORKFLOW_CREATION = 'workflow:creation',
  SUPPLY_SHEET_EDITED = 'supplySheet:edited',
  SUPPLY_SHEET_EXPORTED = 'supplySheet:exported',
  PROJECT_PARAMETER_UPDATED = 'projectParameter:updated',
  FIXED_PRICE_CALCULATION = 'fixedprice:calculation',
  EXTERNAL_PROGRESS_STATEMENT_VIEWED = 'externalProgressStatement:viewed',
  EXTERNAL_PROGRESS_STATEMENT_APPROVED = 'externalProgressStatement:approved',
  EXTERNAL_PROGRESS_STATEMENT_REJECTED = 'externalProgressStatement:rejected',
}

export type ONBOARDING_TYPE = 'user' | 'company';
export type SALE_PAYMENT_TYPE = 'total' | 'partial';
export type SALE_PAYMENT_FROM = 'transaction-suggested-bill' | 'transaction-associate-bill' | 'manual';

export type SALE_INVOICE_TYPE =
  | 'progress-statement'
  | 'direct-progress-statement'
  | 'credit'
  | 'down-payment'
  | 'invoice';
export type LIBRARY_TYPE = 'job' | 'component';
export type QUOTE_TYPE = 'import' | 'manual' | 'duplication';
export type QUOTA_REACHED_TYPE = 'ocr' | 'quote-import' | 'email-template';
export type QUOTE_JOB_IMPORT_TYPE = 'batiprix' | 'library';
export type QUOTE_ACTION_UNDO_REDO_TYPE = 'undo' | 'redo';
export type QUOTE_ACTION_UNDO_REDO_MODE = 'keyboard' | 'click';
export type ORDER_ITEMS_IMPORT_TYPE =
  | 'library-job'
  | 'library-component'
  | 'quote-components-from-quote'
  | 'quote-components-from-order'
  | 'quote-job';
export type SUB_PROJECT_CREATED_TYPE = 'new-project' | 'new-sub-project' | 'existing-sub-project';
export type PENALTY_TYPE = 'progress-statement' | 'final-statement';
export type CONTACT_TYPE = 'supplier' | 'client' | 'quote' | 'project';
export type BATCH_IMPORT_TYPE = 'contact' | 'client' | 'supplier' | 'library-job' | 'library-component';
export type COMPONENT_TYPE_TYPE = 'workforce' | 'material';
export type VALIDATION_TYPE = 'batch-validation' | 'single-validation';
export type SUPPLY_SHEET_EDITED_TYPE = 'supply-sheet' | 'inline';
export type SUPPLIER_INVOICE_PAYMENT_FROM =
  | 'transaction-suggested-supplier-invoice'
  | 'transaction-associate-supplier-invoice'
  | 'transaction-create-supplier-invoice'
  | 'manual';
export interface TRACKING_EVENT {
  [TRACKING_EVENT_ENUM.STATUS_BULKUPDATED]: {
    entityType: 'QUOTE' | 'ORDER';
  };
  [TRACKING_EVENT_ENUM.AUTOSUGGEST_SELECTED]: {
    type: string;
  };
  [TRACKING_EVENT_ENUM.SUPPLIER_INVOICE_WORKFLOW_VALIDATION]: {
    type: VALIDATION_TYPE;
    isLastValidator: boolean | '';
  };
  [TRACKING_EVENT_ENUM.WORKFLOW_CREATION]: null;
  [TRACKING_EVENT_ENUM.SALE_PAYMENT_CREATED]: {
    type: SALE_PAYMENT_TYPE;
    from: SALE_PAYMENT_FROM;
  };
  [TRACKING_EVENT_ENUM.BUDGET_CREATED]: null;
  [TRACKING_EVENT_ENUM.PDF_COLOR_CHANGED]: {
    template: PDF_TEMPLATES_TYPES;
    color: GRANEET_PDF_COLORS;
  };
  [TRACKING_EVENT_ENUM.BUDGET_EDITED]: null;
  [TRACKING_EVENT_ENUM.TIMESLOT_CREATED]: null;
  [TRACKING_EVENT_ENUM.TIMESLOT_EDITED]: null;
  [TRACKING_EVENT_ENUM.PROJECT_CREATED]: null;

  [TRACKING_EVENT_ENUM.SALE_INVOICE_CREATED]: {
    type: SALE_INVOICE_TYPE;
  };
  [TRACKING_EVENT_ENUM.TAG_ADDED]: {
    entity: string;
  };
  [TRACKING_EVENT_ENUM.SALE_INVOICE_EDITED]: {
    type: SALE_INVOICE_TYPE;
  };
  [TRACKING_EVENT_ENUM.LIBRARY_CREATED]: {
    type: LIBRARY_TYPE;
  };
  [TRACKING_EVENT_ENUM.LIBRARY_EDITED]: {
    type: LIBRARY_TYPE;
  };
  [TRACKING_EVENT_ENUM.ORDER_CREATED]: null;
  [TRACKING_EVENT_ENUM.ORDER_EDITED]: null;
  [TRACKING_EVENT_ENUM.SUPPLIER_INVOICE_CREATED]: {
    mode: 'manual' | 'import' | 'mail';
    supplierInvoiceMode: SUPPLIER_INVOICE_MODE;
  };
  [TRACKING_EVENT_ENUM.SUPPLIER_INVOICE_EDITED]: {
    supplierInvoiceMode: SUPPLIER_INVOICE_MODE;
  };
  [TRACKING_EVENT_ENUM.SUPPLIER_INVOICE_PAYEMENT_CREATED]: {
    from: SUPPLIER_INVOICE_PAYMENT_FROM;
  };
  [TRACKING_EVENT_ENUM.QUOTE_EDITED]: null;
  [TRACKING_EVENT_ENUM.QUOTE_IMPORTED]: null;
  [TRACKING_EVENT_ENUM.QUOTE_RESTORED]: null;
  [TRACKING_EVENT_ENUM.QUOTE_PDF_GENERATED]: null;
  [TRACKING_EVENT_ENUM.QUOTE_CREATED]: {
    type: QUOTE_TYPE;
  };
  [TRACKING_EVENT_ENUM.QUOTE_JOB_IMPORT]: {
    type: QUOTE_JOB_IMPORT_TYPE;
  };
  [TRACKING_EVENT_ENUM.QUOTE_COMPONENT_IMPORT]: null;
  [TRACKING_EVENT_ENUM.QUOTE_ACTION_UNDO_REDO]: {
    type: QUOTE_ACTION_UNDO_REDO_TYPE;
    mode: QUOTE_ACTION_UNDO_REDO_MODE;
  };
  [TRACKING_EVENT_ENUM.QUOTA_REACHED]: {
    type: QUOTA_REACHED_TYPE;
  };
  [TRACKING_EVENT_ENUM.LEDGER_ENABLED]: {
    type: LEDGER_TYPES;
  };
  [TRACKING_EVENT_ENUM.LEDGER_DISABLED]: {
    type: LEDGER_TYPES;
  };
  [TRACKING_EVENT_ENUM.THIRD_PARTY_ENABLED]: {
    type: ThirdParties;
  };
  [TRACKING_EVENT_ENUM.THIRD_PARTY_DISABLED]: {
    type: ThirdParties;
  };
  [TRACKING_EVENT_ENUM.OPTION_ACCEPTED]: null;
  [TRACKING_EVENT_ENUM.OPTION_CREATED]: null;
  [TRACKING_EVENT_ENUM.OPTION_VALIDATED]: null;

  [TRACKING_EVENT_ENUM.HIDDEN_COST_CREATED]: null;
  [TRACKING_EVENT_ENUM.HIDDEN_COST_ACCEPTED]: null;

  [TRACKING_EVENT_ENUM.ORDER_ITEMS_IMPORT]: {
    type: ORDER_ITEMS_IMPORT_TYPE;
  };
  [TRACKING_EVENT_ENUM.QUOTE_ACCEPTED]: {
    type: SUB_PROJECT_CREATED_TYPE;
  };

  [TRACKING_EVENT_ENUM.CUSTOM_DISCOUNT_CREATED]: null;
  [TRACKING_EVENT_ENUM.CUSTOM_DISCOUNT_ACCEPTED]: null;
  [TRACKING_EVENT_ENUM.CUSTOM_DISCOUNT_ADVANCED]: null;
  [TRACKING_EVENT_ENUM.DEDUCTION_CREATED]: null;
  [TRACKING_EVENT_ENUM.DEDUCTION_ACCEPTED]: null;

  [TRACKING_EVENT_ENUM.QUANTITY_CALCULATED]: null;
  [TRACKING_EVENT_ENUM.QUANTITY_ROUNDED]: {
    type: string;
  };
  [TRACKING_EVENT_ENUM.SIGNUP_STARTED]: {
    type: ONBOARDING_TYPE;
  };
  [TRACKING_EVENT_ENUM.SIGNUP_COMPLETED]: {
    type: ONBOARDING_TYPE;
  };
  [TRACKING_EVENT_ENUM.TABLE_EXPORT_GENERATED]: {
    exportType: ExportEntity;
    formatType: ('excel' | 'pdf')[];
  };
  [TRACKING_EVENT_ENUM.ACCOUNTING_EXPORT_GENERATED]: {
    journalType: ACCOUNTING_JOURNAL;
    formatType: ('excel' | 'pdf' | 'fec')[];
  };
  [TRACKING_EVENT_ENUM.PENALTY_CREATED]: {
    type: PENALTY_TYPE;
  };
  [TRACKING_EVENT_ENUM.CONTACT_CREATED]: null;
  [TRACKING_EVENT_ENUM.CONTACT_ASSOCIATED]: {
    type: CONTACT_TYPE;
  };

  [TRACKING_EVENT_ENUM.EMAIL_SENT]: {
    type: EMAIL_ENTITY_TYPE;
    mode: 'manual' | 'template' | 'manual-reminder';
  };

  [TRACKING_EVENT_ENUM.EMAIL_TEMPLATE_CREATED]: {
    type: EmailTemplateType;
  };
  [TRACKING_EVENT_ENUM.EMAIL_TEMPLATE_EDITED]: {
    type: EmailTemplateType;
  };
  [TRACKING_EVENT_ENUM.BATCH_IMPORTED]: {
    type: BATCH_IMPORT_TYPE;
  };
  [TRACKING_EVENT_ENUM.HISTORY_ATTACHMENT_DOWNLOAD]: null;
  [TRACKING_EVENT_ENUM.PLANNING_COMPONENT_TYPE_CREATED]: {
    type: COMPONENT_TYPE_TYPE;
  };
  [TRACKING_EVENT_ENUM.PLANNING_TIMESLOT_COMPONENT_TYPE_UPDATED]: null;
  [TRACKING_EVENT_ENUM.PLANNING_TIMESLOT_APPROVED]: null;
  [TRACKING_EVENT_ENUM.PLANNING_WORKER_CREATED]: {
    workforce: string;
  };
  [TRACKING_EVENT_ENUM.PLANNING_WORKER_UPDATED]: {
    workforce: string;
  };

  [TRACKING_EVENT_ENUM.PLANNING_DEFAULT_MEAL_EXPENSE_UPDATED]: null;
  [TRACKING_EVENT_ENUM.PLANNING_OVERTIME_TYPE_CREATED]: null;
  [TRACKING_EVENT_ENUM.PLANNING_OVERTIME_TYPE_UPDATED]: null;
  [TRACKING_EVENT_ENUM.PLANNING_KM_EXPENSE_ADDED]: null;
  [TRACKING_EVENT_ENUM.PLANNING_KM_EXPENSE_UPDATED]: null;
  [TRACKING_EVENT_ENUM.PLANNING_TIMESLOT_SHARED]: null;
  [TRACKING_EVENT_ENUM.EMAIL_REMINDER_SENT]: {
    mode: 'manual' | 'auto' | 'bulk';
    success: boolean;

    // Ne sert à rien pour l'instant mais prévoyance pour des relances sur d'autres entités comme le devis
    type: 'SalesInvoice'; // | ...
  };
  [TRACKING_EVENT_ENUM.EMAIL_REMINDER_PLAN_CREATED]: null;
  [TRACKING_EVENT_ENUM.EMAIL_REMINDER_PLAN_EDITED]: null;
  [TRACKING_EVENT_ENUM.EMAIL_REMINDER_PLAN_DELETED]: null;
  [TRACKING_EVENT_ENUM.SUPPLY_SHEET_EDITED]: {
    type: SUPPLY_SHEET_EDITED_TYPE;
  };
  [TRACKING_EVENT_ENUM.SUPPLY_SHEET_EXPORTED]: null;
  [TRACKING_EVENT_ENUM.PROJECT_PARAMETER_UPDATED]: {
    projectType: boolean;
    pdfTemplate: boolean;
    holdback: boolean;
    holdbackPaymentGuarantee: boolean;
    deduction: boolean;
    customDiscount: boolean;
    priceRevision: boolean;
    hasBillingStarted: boolean;
  };
  [TRACKING_EVENT_ENUM.FIXED_PRICE_CALCULATION]: {
    type: QUOTE_ITEM_TYPE_OF_PRICING;
  };
  [TRACKING_EVENT_ENUM.EXTERNAL_PROGRESS_STATEMENT_VIEWED]: null;
  [TRACKING_EVENT_ENUM.EXTERNAL_PROGRESS_STATEMENT_APPROVED]: null;
  [TRACKING_EVENT_ENUM.EXTERNAL_PROGRESS_STATEMENT_REJECTED]: null;
}
