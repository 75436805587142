import type { ChangeEventHandler, FC } from 'react';
import { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import { chunk } from 'lodash-es';
import { Box } from '@chakra-ui/react';
import { v4 } from 'uuid';

import { File as FileComponent } from '../File/File';
import { sleep } from '../../utils/time.util';
import { Button } from '../Button/Button';

import { configureDefaultLabels, fileListTranslations } from './configureDefaultLabel';
import { FileListIcon } from './FileListIcon';

export type FileWithStatus = {
  file: File;
  status: 'toUpload' | 'awaiting' | 'uploading' | 'uploaded' | 'error' | 'tooBig';
};

const DEFAULT_BATCH_LENGTH = 10;
const DEFAULT_MAX_FILE_SIZE = 10;

export function useFileList(options: {
  onSubmit: (filesWithStatus: File[]) => Promise<unknown>;
  onError?: () => void;
  onSuccess?: () => void;
  /**
   * @default 10
   */
  batchLength?: number;
  /**
   * Max File size in mo
   * @default 10_000_000
   */
  maxFileSize?: number;
}) {
  const [files, setFiles] = useState<Array<FileWithStatus>>([]);

  const isUploading = useMemo(() => files.some((file) => ['awaiting', 'uploading'].includes(file.status)), [files]);

  const fileHasBeenSentRef = useRef(false);
  const isUploaded = useMemo(() => {
    const allFilesAreUploaded = files.every((file) => ['uploaded', 'error'].includes(file.status));
    return allFilesAreUploaded && fileHasBeenSentRef.current;
  }, [files]);

  const canSendFiles = useMemo(
    () => files.length > 0 && files.every((file) => ['toUpload', 'tooBig'].includes(file.status)),
    [files],
  );

  const sendFiles = useCallback(async () => {
    let hasSomeErrors = false;

    const filterTooBig = (filesToFilter: Array<FileWithStatus>) =>
      filesToFilter.filter((file) => file.status !== 'tooBig');

    if (!canSendFiles) {
      return;
    }
    setFiles((previousState) =>
      filterTooBig(previousState).map((file) => ({
        file: file.file,
        status: 'awaiting',
      })),
    );

    const batchLength = options.batchLength ?? DEFAULT_BATCH_LENGTH;
    let i = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const filesToSend of chunk(filterTooBig(files), batchLength)) {
      const lowRange = i * batchLength;
      const highRange = (i + 1) * batchLength - 1;

      setFiles((previousState) =>
        previousState.map((file, index) => {
          if (index >= lowRange && index <= highRange) {
            return {
              file: file.file,
              status: 'uploading',
            };
          }

          return file;
        }),
      );

      // Add a small delay to avoid api spamming
      // eslint-disable-next-line no-await-in-loop
      await sleep(100);

      let lastCallHasError = false;
      try {
        // eslint-disable-next-line no-await-in-loop
        await options.onSubmit(filesToSend.map((file) => file.file));
      } catch {
        hasSomeErrors = true;
        lastCallHasError = true;
      }

      setFiles((previousState) =>
        previousState.map((file, index) => {
          if (index >= lowRange && index <= highRange) {
            return {
              file: file.file,
              status: lastCallHasError ? ('error' as const) : ('uploaded' as const),
            };
          }

          return file;
        }),
      );

      i += 1;
    }

    fileHasBeenSentRef.current = true;

    if (hasSomeErrors && options.onError) {
      options.onError();
    } else if (!hasSomeErrors && options.onSuccess) {
      options.onSuccess();
    }
  }, [canSendFiles, files, options]);

  const addFiles = useCallback(
    (newFiles: File[]) => {
      const addedFiles = newFiles.map<FileWithStatus>((file) => {
        let status: FileWithStatus['status'];

        const maxFileSize = (options.maxFileSize ?? DEFAULT_MAX_FILE_SIZE) * 1024 * 1024;
        if (file.size >= maxFileSize) {
          status = 'tooBig';
        } else {
          status = 'toUpload';
        }

        return {
          file,
          status,
        };
      });

      setFiles((currentState) => [...currentState, ...addedFiles]);
    },
    [options.maxFileSize],
  );

  return useMemo(
    () => ({
      files,
      addFiles,
      canSendFiles,
      sendFiles,
      isUploading,
      isUploaded,
    }),
    [addFiles, canSendFiles, files, isUploaded, isUploading, sendFiles],
  );
}

type UseFileList = ReturnType<typeof useFileList>;

interface FileListProps {
  fileList: UseFileList;

  accept?: string;

  openOnMount?: boolean;
}

export const FileListComponent: FC<FileListProps> = ({ fileList, accept, openOnMount }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const id = useMemo(v4, []);

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.target.files) {
        fileList.addFiles([...e.target.files]);
      }
    },
    [fileList],
  );

  useEffect(() => {
    if (openOnMount) {
      inputRef.current?.click();
    }
  }, [openOnMount]);

  return (
    <Box>
      <label htmlFor={id}>
        <Button isDisabled={fileList.isUploading || fileList.isUploaded} as="span">
          {fileListTranslations.selectFiles}
        </Button>

        <input
          ref={inputRef}
          id={id}
          onChange={onChange}
          type="file"
          multiple
          style={{ display: 'none' }}
          accept={accept}
        />
      </label>

      {fileList.files.map((file, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} _first={{ mt: 3 }}>
          <FileComponent name={file.file.name} actions={<FileListIcon status={file.status} />} />
        </Box>
      ))}
    </Box>
  );
};

export const FileList = Object.assign(FileListComponent, {
  configureDefaultLabels,
});
