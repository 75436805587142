import { useCallback } from 'react';
import type { FC } from 'react';

import { SimpleEqualIcon } from '../../../../Icons/v2';
import { useCurrency } from '../../../../Currency';
import { CardLineEnum, GenericCard, MaskEnum } from '../GenericCard';
import { quotationCardTranslations } from '../translations';

export type BaseSellSheetAmountCardProps = {
  isEditable: boolean;
  disbursement: string;
  profitMargin: string;
  overheadCosts: string;
  marginBenefit: string;
  totalAmountExVAT: string;
  hiddenCostAmount: string | null;
  onTotalAmountChange: (amount: string) => void;
};

export const SellSheetAmountCard: FC<BaseSellSheetAmountCardProps> = ({
  disbursement,
  totalAmountExVAT,
  profitMargin,
  overheadCosts,
  marginBenefit,
  onTotalAmountChange,
  hiddenCostAmount,
  isEditable,
}) => {
  const { mapAmountToStringNumber, mapStringNumberToAmount, formatAsStringAmount } = useCurrency();

  const handleInputChange = useCallback(
    (e: string) => {
      if (!isEditable) return;
      onTotalAmountChange(mapAmountToStringNumber(e));
    },
    [onTotalAmountChange, mapAmountToStringNumber, isEditable],
  );

  return (
    <GenericCard
      title={quotationCardTranslations.amount.title}
      groups={[
        {
          items: [
            {
              type: CardLineEnum.ITEM,
              name: 'disbursement',
              label: quotationCardTranslations.amount.disbursement,
              mask: MaskEnum.CURRENCY,
              value: mapStringNumberToAmount(disbursement),
              isDisabled: false,
              isEditable: false,
              onBlur: undefined,
            },
            ...(hiddenCostAmount
              ? [
                  {
                    type: CardLineEnum.ITEM as const,
                    name: 'hiddenCost',
                    label: quotationCardTranslations.amount.hiddenCost,
                    mask: MaskEnum.CURRENCY,
                    value: mapStringNumberToAmount(hiddenCostAmount),
                    isDisabled: false as const,
                    isEditable: false as const,
                    onBlur: undefined,
                  },
                ]
              : []),
            {
              type: CardLineEnum.ITEM,
              name: 'overheadCosts',
              label: quotationCardTranslations.margin.overheadCosts,
              mask: MaskEnum.CURRENCY,
              value: mapStringNumberToAmount(overheadCosts),
              isDisabled: false,
              isEditable: false,
              onBlur: undefined,
            },
            {
              type: CardLineEnum.ITEM,
              name: 'profitMargin',
              label: quotationCardTranslations.margin.profit,
              mask: MaskEnum.CURRENCY,
              value: mapStringNumberToAmount(profitMargin),
              isDisabled: false,
              isEditable: false,
              onBlur: undefined,
            },
            {
              type: CardLineEnum.CALLOUT,
              label: quotationCardTranslations.amount.marginBenefit,
              value: formatAsStringAmount(marginBenefit),
              color: 'purple',
            },
          ],
        },
        {
          items: [
            {
              type: CardLineEnum.ITEM,
              name: 'totalAmountExVAT',
              label: quotationCardTranslations.amount.totalAmountExVAT,
              icon: <SimpleEqualIcon />,
              mask: MaskEnum.CURRENCY,
              value: mapStringNumberToAmount(totalAmountExVAT),
              isDisabled: false,
              ...(isEditable
                ? { onBlur: handleInputChange, isEditable: true }
                : { isEditable: false, onBlur: undefined }),
              isBold: true,
            },
          ],
        },
      ]}
    />
  );
};
