export type ContractId = `contract_${number}`;
export const generateContractId = (id: number): ContractId => `contract_${id}`;
export const getIdFromContractId = (contractId: ContractId) => parseInt(contractId.split('_')[1], 10);

export type LotId = `lot_${number}`;
export const generateLotId = (id: number): LotId => `lot_${id}`;
export const getIdFromLotId = (lotId: LotId) => parseInt(lotId.split('_')[1], 10);

export const getProgressStatementDeepTableOffset = (depth: number): number => 0.5 * (depth - 1);

export const isContractId = (contractId: ContractId | LotId): contractId is ContractId =>
  contractId.split('_')[0] === 'contract';
