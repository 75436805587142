import type { IPdf } from '@graneet/business-logic';
import { PDF_STATUSES, SUPPORT_EMAIL } from '@graneet/business-logic';
import type { Action } from '@graneet/lib-ui';
import { t } from 'i18next';

export function getPDFActionProps(pdf: IPdf): Pick<Action, 'tooltip' | 'warning' | 'isLoading' | 'isDisabled'> {
  const isGenerating = pdf.status === PDF_STATUSES.GENERATING;
  const error = pdf.status === PDF_STATUSES.ERROR;
  const convertingError = pdf.status === PDF_STATUSES.ERROR_CONVERTING;

  const tooltip = (() => {
    if (error) {
      return t('global:pdf.pdfError', { email: SUPPORT_EMAIL });
    }
    if (convertingError) {
      return t('global:pdf.pdfErrorConverting', { email: SUPPORT_EMAIL });
    }
    if (isGenerating) {
      return t('global:pdf.pdfNotReady');
    }
    return undefined;
  })();

  return {
    tooltip,
    warning: error || convertingError,
    isLoading: isGenerating,
    isDisabled: error || convertingError,
  };
}
