import type { AnyRecord, FieldValues } from 'graneet-form';
import { composeEventHandlers, VALIDATION_OUTCOME, Field } from 'graneet-form';
import type { ReactNode } from 'react';
import type { OnChangeValue } from 'chakra-react-select';

import type { KeysMatching } from '../../../utils/type.util';
import type { SingleSelectProps } from '../../SingleSelect/SingleSelect';
import { SingleSelect } from '../../SingleSelect/SingleSelect';
import { sleep } from '../../../utils/index';

export type SingleSelectFieldType = any | null | undefined;

export interface SingleSelectFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, SingleSelectFieldType> = KeysMatching<T, SingleSelectFieldType>,
> extends Omit<
    SingleSelectProps<SingleSelectFieldType>,
    'onChange' | 'onMenuClose' | 'errorMessage' | 'isInvalid' | 'options' | 'value'
  > {
  name: K;

  children?: ReactNode;

  options: { value: T[K]; label: ReactNode; searchTerm?: string }[];

  data?: AnyRecord;

  onFocus?: () => void;

  onBlur?: () => void;

  onChange?: (value: { value: T[K] }) => void;
}

export const SingleSelectField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, SingleSelectFieldType> = KeysMatching<T, SingleSelectFieldType>,
>({
  name,
  children,
  options,
  data,
  onFocus,
  onBlur,
  onChange,
  ...otherProps
}: SingleSelectFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    data={data}
    render={(fieldProps, fieldState) => {
      const { onBlur: onBlurForm, onChange: onChangeForm, onFocus: onFocusForm, value } = fieldProps;
      const { validationStatus } = fieldState;
      const shouldDisplayError = validationStatus.status === VALIDATION_OUTCOME.INVALID;

      const handleBlur = () => {
        sleep(0).then(() => {
          composeEventHandlers(onBlur, onBlurForm)();
        }); // React select single fire blur before onChange
      };

      const handleChange = (e: OnChangeValue<T[K], false>) => {
        onChangeForm(e?.value);
      };

      return (
        <SingleSelect<T[K]>
          onChange={composeEventHandlers(onChange, handleChange as any) as any}
          onMenuOpen={composeEventHandlers(onFocus, onFocusForm)}
          onMenuClose={handleBlur}
          errorMessage={validationStatus.message}
          isInvalid={shouldDisplayError}
          options={options as any}
          value={options?.find((o) => (typeof o === 'object' && o && 'value' in o ? o.value === value : false)) as any}
          {...otherProps}
        />
      );
    }}
  >
    {children}
  </Field>
);
