import type { FC } from 'react';
import { useMemo } from 'react';
import { Skeleton } from '@chakra-ui/react';

import { Cell } from './Cell';
import { Row as TableRow } from './Row';

export interface SkeletonRowProps {
  numberOfColumns: number;
}

export const SkeletonRow: FC<SkeletonRowProps> = ({ numberOfColumns }) => {
  const cells = useMemo(
    () =>
      new Array(numberOfColumns).fill(null).map((val, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Cell key={`skeleton_${index}`}>
          <Skeleton h="0.625rem" w="100%" my={2} />
        </Cell>
      )),
    [numberOfColumns],
  );

  return <TableRow displayDuringLoading>{cells}</TableRow>;
};
