import { divideFloating } from '@graneet/lib-ui';
import type { IWorker, ITimeSlot, ProjectPaginatedResponse } from '@graneet/business-logic';

export interface TimeTrackingReducerState {
  loading: boolean;
  showWeekends: boolean;
  defaultNbHours?: number;
  numberOfDays: number;
  defaultStartHour: string;
  defaultEndHour: string;
  createSlot: (Partial<ITimeSlot> & { isOff: boolean; subEntityId: string | number }) | null;
  editingSlot: Partial<ITimeSlot> | null;
  workers: IWorker[];
  workersWithDeleted: IWorker[];
  projects: ProjectPaginatedResponse['data'];
  filters: {
    date: Date;
    groupBy: 'worker' | 'project';
    query?: string;
    view: 'week' | 'month';
  };
}

type ReducerActionType =
  | 'settingsData'
  | 'listData'
  | 'editSlot'
  | 'createSlot'
  | 'changeView'
  | 'setQuery'
  | 'prefillProject'
  | 'setDate'
  | 'changeGroupBy'
  | 'zoomWeek'
  | 'loading';

interface IReducerAction {
  value: any;
  type: ReducerActionType;
}

interface ReducerSettingsData extends IReducerAction {
  value: Pick<TimeTrackingReducerState, 'numberOfDays' | 'defaultNbHours' | 'defaultStartHour' | 'defaultEndHour'> & {
    defaultNbHours: number;
  };
  type: 'settingsData';
}

interface ReducerListData extends IReducerAction {
  value: Pick<TimeTrackingReducerState, 'projects' | 'workers' | 'workersWithDeleted'>;
  type: 'listData';
}

interface ReducerEditSlot extends IReducerAction {
  value: Pick<TimeTrackingReducerState, 'editingSlot'>['editingSlot'];
  type: 'editSlot';
}

interface ReducerCreateSlot extends IReducerAction {
  value: Pick<TimeTrackingReducerState, 'createSlot'>['createSlot'];
  type: 'createSlot';
}

interface ReducerChangeView extends IReducerAction {
  value: Pick<TimeTrackingReducerState, 'filters'>['filters']['view'];
  type: 'changeView';
}

interface ReducerChangeGroupBy extends IReducerAction {
  value: Pick<TimeTrackingReducerState, 'filters'>['filters']['groupBy'];
  type: 'changeGroupBy';
}

interface ReducerSetQuery extends IReducerAction {
  value: Pick<TimeTrackingReducerState, 'filters'>['filters']['query'];
  type: 'setQuery';
}

interface ReducerPrefillProject extends IReducerAction {
  value: Pick<TimeTrackingReducerState, 'filters'>['filters']['query'] & string;
  type: 'prefillProject';
}

interface ReducerSetDate extends IReducerAction {
  value: Pick<TimeTrackingReducerState, 'filters'>['filters']['date'];
  type: 'setDate';
}

interface ReducerZoomWeek extends IReducerAction {
  value: Pick<TimeTrackingReducerState, 'filters'>['filters']['date'];
  type: 'zoomWeek';
}

interface ReducerLoading extends IReducerAction {
  value: boolean;
  type: 'loading';
}

export type ReducerAction =
  | ReducerSettingsData
  | ReducerListData
  | ReducerEditSlot
  | ReducerCreateSlot
  | ReducerChangeView
  | ReducerChangeGroupBy
  | ReducerSetQuery
  | ReducerPrefillProject
  | ReducerSetDate
  | ReducerZoomWeek
  | ReducerLoading;

export const timeTrackingReducer = (
  state: TimeTrackingReducerState,
  action: ReducerAction,
): TimeTrackingReducerState => {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: action.value,
      };
    case 'settingsData':
      return {
        ...state,
        numberOfDays: action.value.numberOfDays,
        defaultNbHours: divideFloating(action.value.defaultNbHours, 60),
        defaultStartHour: action.value.defaultStartHour,
        defaultEndHour: action.value.defaultEndHour,
      };
    case 'listData':
      return {
        ...state,
        projects: action.value.projects,
        workers: action.value.workers,
        workersWithDeleted: action.value.workersWithDeleted,
      };
    case 'editSlot':
      return {
        ...state,
        editingSlot: action.value,
      };
    case 'createSlot':
      return {
        ...state,
        createSlot: action.value,
      };
    case 'changeView':
      return {
        ...state,
        filters: {
          ...state.filters,
          view: action.value,
        },
      };
    case 'changeGroupBy':
      return {
        ...state,
        filters: {
          ...state.filters,
          groupBy: action.value,
        },
      };
    case 'setQuery':
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.value,
        },
      };
    case 'prefillProject':
      return {
        ...state,
        filters: {
          ...state.filters,
          groupBy: 'project',
          query: action.value,
        },
      };
    case 'setDate':
      return {
        ...state,
        filters: {
          ...state.filters,
          date: action.value,
        },
      };
    case 'zoomWeek':
      return {
        ...state,
        filters: {
          ...state.filters,
          view: 'week',
          date: action.value,
        },
      };
    default:
      throw new Error();
  }
};

export const initialState: TimeTrackingReducerState = {
  loading: false,
  showWeekends: false,
  defaultNbHours: undefined,
  numberOfDays: 5,
  editingSlot: null,
  createSlot: null,
  defaultStartHour: '',
  defaultEndHour: '',
  workers: [],
  workersWithDeleted: [],
  projects: [],
  filters: {
    date: new Date(),
    groupBy: 'worker',
    query: undefined,
    view: 'week',
  },
};
