import type { DrawersStackContextApi } from '@graneet/lib-ui';
import { AmountSummary, useCurrency, Card, Header } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import {
  computeAmountExVATWithDisbursementAndTotalMargin,
  getAmountWithoutDiscountFromAmountExVAT,
  getAmountWithoutCustomDiscountsFromAmountExVAT,
} from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { FC } from 'react';

import { QuoteTotalsCustomDiscount } from './QuoteTotalsCustomDiscount';
import { QuoteTotalsDiscount } from './QuoteTotalsDiscount';

import { useQuote } from 'features/quote/hooks/useQuote';
import type { QuoteEditSellSheetForm } from 'features/quote/forms/quote-edit-lot-step.form';
import { QuoteAddDiscountButtons } from 'features/quote/components/buttons/QuoteAddDiscountButtons';

const CARD_TITLE_STYLE = {
  fontWeight: '600',
};

interface QuoteSalesSheetTotalsAndDiscountProps {
  onSalesSheetDrawerOpen: () => void;
  isEditionDisabled: boolean;
  drawersStack: DrawersStackContextApi<string>;
  roundedAmountExVAT: number | null;
}

export const QuoteSalesSheetTotalsAndDiscount: FC<QuoteSalesSheetTotalsAndDiscountProps> = ({
  isEditionDisabled = false,
  drawersStack,
  roundedAmountExVAT,
}) => {
  const { t } = useTranslation(['customDiscount', 'quote']);
  const { mapAmountToNumber } = useCurrency();
  const form = useFormContext<QuoteEditSellSheetForm>();
  const values = useOnChangeValues(form, undefined);

  const quote = useQuote();
  const discount = quote?.discount;
  const customDiscounts = quote?.customDiscounts || [];

  const amountExVAT = values.amountExVAT ? mapAmountToNumber(values.amountExVAT) : 0;

  const amountWithDiscountExVAT = getAmountWithoutCustomDiscountsFromAmountExVAT(customDiscounts, amountExVAT);

  let amountWithoutDiscountExVAT;
  try {
    amountWithoutDiscountExVAT = getAmountWithoutDiscountFromAmountExVAT(discount, amountWithDiscountExVAT);
  } catch {
    // Cannot compute amountWithoutDiscountExVAT due to a 100% discount
    const disbursementExVAT = values.disbursementExVAT ? mapAmountToNumber(values.disbursementExVAT) : 0;
    amountWithoutDiscountExVAT = computeAmountExVATWithDisbursementAndTotalMargin(
      disbursementExVAT,
      values.totalMargin || 0,
    );
  }

  return (
    <>
      {(discount || customDiscounts.length > 0) && (
        <Card variant="light-gray" noShadow>
          <Header color="gray.800" px={0} height="auto" backgroundColor="gray.100" sx={CARD_TITLE_STYLE}>
            {t('quote:jobsStep.salesSheetModal.discount')}
          </Header>

          <AmountSummary>
            {discount && (
              <QuoteTotalsDiscount
                discount={discount}
                drawersStack={drawersStack}
                amountWithoutDiscountExVAT={amountWithoutDiscountExVAT}
                noActions={isEditionDisabled}
                isLight
                left
              />
            )}

            {!!customDiscounts?.length && (
              <AmountSummary.Item
                label={t(
                  discount
                    ? 'quote:fields.amountWithDiscountExVAT'
                    : 'customDiscount:totalExVATBeforeCustomDiscountLabel',
                )}
                amount={amountWithDiscountExVAT}
                pl={0}
                left
                darkGrey
              />
            )}

            {customDiscounts.map((customDiscount) => (
              <QuoteTotalsCustomDiscount
                quoteId={quote?.id}
                drawersStack={drawersStack}
                customDiscount={customDiscount}
                amountWithDiscountExVAT={amountWithDiscountExVAT}
                key={customDiscount.id}
                left
              />
            ))}

            <AmountSummary.Item
              label={t('quote:fields.totalPriceExVAT')}
              amount={roundedAmountExVAT !== null ? roundedAmountExVAT : amountExVAT}
              pl={0}
              darkGrey
              left
            />
          </AmountSummary>
        </Card>
      )}

      {!isEditionDisabled && <QuoteAddDiscountButtons drawersStack={drawersStack} />}
    </>
  );
};
