import { HStack, Skeleton } from '@chakra-ui/react';
import { SellsheetCard } from '@graneet/lib-ui';

export const QuoteLoadingCard = () => (
  <SellsheetCard>
    <Skeleton borderRadius="5px" height="60px" width="100%" startColor="gray.50" endColor="gray.300" />
    <HStack width="100%">
      <Skeleton borderRadius="8px" height="32px" width="120px" startColor="gray.50" endColor="gray.300" />
      <Skeleton borderRadius="8px" height="32px" width="32px" startColor="gray.50" endColor="gray.300" />
    </HStack>
  </SellsheetCard>
);
