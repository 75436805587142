import { useCallback, type FC } from 'react';
import { Card, DataTable, SimpleDeleteIcon, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Grid, GridItem, IconButton, Stack, Text } from '@chakra-ui/react';
import type { IAccountingConfigBank } from '@graneet/business-logic';

import { AccoutingAddBankButton } from './AccountingAddBankButton';

import {
  useAccountingConfigCanDeleteBank,
  useDeleteAccountingConfigBank,
} from 'features/accounting/services/accounting-config.api';
import type {
  AccountingConfigBankEditionBlockForm,
  AccountingConfigBankJournalFieldName,
} from 'features/accounting/forms/accounting.form';
import {
  getAccountingConfigBankJournalFieldName,
  getAccountingConfigBankLabelFieldName,
} from 'features/accounting/forms/accounting.form';

interface AccountingBankEditionBlockProps {
  accountingConfigBanks: IAccountingConfigBank[];
  mustDisplayFields?: AccountingConfigBankJournalFieldName[];
  haveRequiredFields?: boolean;
  hideAddButton?: boolean;
}

const Row: FC<{
  accountingConfigBank: IAccountingConfigBank;
}> = ({ accountingConfigBank }) => {
  const canDelete = useAccountingConfigCanDeleteBank(accountingConfigBank.id);

  const name = getAccountingConfigBankJournalFieldName(accountingConfigBank.id);

  const deleteAccountingConfigBank = useDeleteAccountingConfigBank();

  const onDelete = useCallback(() => {
    deleteAccountingConfigBank.mutate(accountingConfigBank.id);
  }, [accountingConfigBank.id, deleteAccountingConfigBank]);

  return (
    <DataTable.Row
      label={
        <TextField<AccountingConfigBankEditionBlockForm>
          name={getAccountingConfigBankLabelFieldName(accountingConfigBank.id)}
        />
      }
    >
      <DataTable.Cell>
        <Grid templateColumns="10fr auto">
          <GridItem>
            <TextField<AccountingConfigBankEditionBlockForm> name={name} />
          </GridItem>

          <GridItem>
            <IconButton
              aria-label="delete"
              variant="clear"
              icon={<SimpleDeleteIcon />}
              height="auto"
              isDisabled={!canDelete.data.canDelete}
              onClick={onDelete}
            />
          </GridItem>
        </Grid>
      </DataTable.Cell>
    </DataTable.Row>
  );
};

export const AccountingBanksEditionBlock: FC<AccountingBankEditionBlockProps> = ({ accountingConfigBanks }) => {
  const { t } = useTranslation(['accounting']);

  return (
    <Stack>
      <Text fontSize="lg" color="gray.800">
        {t('accounting:parameters.sections.banks')}
      </Text>

      <Card>
        <DataTable numberOfColumns={1}>
          {accountingConfigBanks.map((accountingConfigBank) => (
            <Row key={accountingConfigBank.id} accountingConfigBank={accountingConfigBank} />
          ))}
        </DataTable>
        <AccoutingAddBankButton mt={4} />
      </Card>
    </Stack>
  );
};
