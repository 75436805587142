import type { FC, PropsWithChildren } from 'react';
import { Flex, Text } from '@chakra-ui/react';

export type RequiredTextProps = PropsWithChildren<{}>;

export const RequiredText: FC<RequiredTextProps> = ({ children, ...props }) => (
  <Flex direction="row" {...props}>
    {children}
    <Text color="red.500" ml={1}>
      *
    </Text>
  </Flex>
);
