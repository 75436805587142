import type { ReactNode } from 'react';
import { cloneElement } from 'react';
import { composeEventHandlers, Field } from 'graneet-form';
import type { AnyRecord, FieldValues } from 'graneet-form';

import type { KeysMatching } from '../../../utils/type.util';
import { useInputMaskInfos } from '../../Input/MaskedInput/useInputMaskInfos';
import { MaskEnum } from '../Sellsheet/Cards/GenericCard';
import type { MaskOptions } from '../../Input/MaskedInput/mask.type';

import type { QuotationMaskedInputProps } from './MaskedInput';
import { QuotationMaskedInput } from './MaskedInput';

type IntegerFieldValue = string | null | undefined;

export interface QuotationMaskedFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, IntegerFieldValue> = KeysMatching<T, IntegerFieldValue>,
> extends Omit<QuotationMaskedInputProps, 'name' | 'mask'> {
  name: K;
  data?: AnyRecord;
  mask?: MaskEnum;
  onChange?(value: T[K]): void | Promise<void>;
  onBlur?(value: T[K]): void | Promise<void>;
  isBold?: boolean;
  isEditable?: boolean;
  options?: MaskOptions;
  children?: ReactNode;
}

export const QuotationMaskedField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, IntegerFieldValue> = KeysMatching<T, IntegerFieldValue>,
>({
  name,
  isBold,
  onChange,
  onBlur,
  isEditable,
  isDisabled,
  icon,
  mask,
  data,
  options,
  children,
  ...rest
}: QuotationMaskedFieldProps<T, K>) => {
  const { placeholder, mask: imask } = useInputMaskInfos(mask ?? MaskEnum.DECIMAL, options);

  const iconElement = icon
    ? cloneElement(icon, {
        boxSize: '0.7rem',
        stroke: '#6C737F',
      })
    : undefined;

  return (
    <Field<T, K>
      name={name}
      data={data}
      render={({ value, onChange: onChangeForm, onBlur: onBlurForm, onFocus: onFocusForm }) => (
        <QuotationMaskedInput
          placeholder={placeholder}
          mask={imask}
          name={name as string}
          {...rest}
          value={value}
          isReadOnly={!isEditable}
          isDisabled={isDisabled}
          onChange={composeEventHandlers(onChange, onChangeForm) as any}
          onBlur={composeEventHandlers(onBlur, onBlurForm) as any}
          onFocus={onFocusForm}
          isRequired
          textAlign="right"
          icon={iconElement}
          fontWeight={isBold ? 600 : 500}
          color="#1F2A37"
        />
      )}
    >
      {children}
    </Field>
  );
};
