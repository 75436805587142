import { useCallback, useEffect, type FC } from 'react';
import type { QuoteCustomDiscountObject } from '@org/quotation-lib';
import { QUOTE_CUSTOM_DISCOUNT_TYPE } from '@org/quotation-lib';
import Big from 'big.js';
import { Form, useForm } from 'graneet-form';
import { useCurrency } from '@graneet/lib-ui';
import { t } from 'i18next';
import { isNil } from 'lodash-es';

import { formatCustomDiscount } from '../../quote/utils/quote-utils';
import { useQuoteCustomDiscountUpdate } from '../hooks/useQuoteCustomDiscountUpdate';
import { useQuoteCustomDiscountCreate } from '../hooks/useQuoteCustomDiscountCreate';

import {
  POSITIVE_MULTIPLIER,
  type QuotationCustomDiscountEditionForm,
  type NegativeMultiplier,
  type PositiveMultiplier,
  NEGATIVE_MULTIPLIER,
} from 'features/custom-discount/forms/custom-discount-edition-form';
import { AddCustomDiscountModal } from 'features/custom-discount/components/modals/AddCustomDiscountModal';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

interface UpsertQuotationCustomDiscountModalProps {
  customDiscountId: string | null;
  hasInitialValues: boolean;
  isOpen: boolean;
  onClose(): void;
}

export const UpsertQuotationCustomDiscountModal: FC<UpsertQuotationCustomDiscountModalProps> = ({
  customDiscountId,
  hasInitialValues,
  isOpen,
  onClose,
}) => {
  const form = useForm<QuotationCustomDiscountEditionForm>();
  const { getFormValues, setFormValues } = form;
  const { quote } = useQuote();

  const quoteCustomDiscountUpdate = useQuoteCustomDiscountUpdate();
  const quoteCustomDiscountCreate = useQuoteCustomDiscountCreate();

  const { mapAmountToStringNumber, mapNumberToAmount } = useCurrency();

  const saleDiscount = quote.getSalesDiscount();

  useEffect(() => {
    if (customDiscountId) {
      // Case : Edit an existing custom discount
      const customDiscounts = quote.getCustomDiscounts();
      const customDiscount = customDiscounts.get(customDiscountId);

      if (!customDiscount) {
        throw new Error(`Custom discount with id ${customDiscountId} not found`);
      }

      const formattedCustomDiscount = formatCustomDiscount(customDiscount, quote.getAmountAfterSaleDiscountExVat());
      let multiplier: PositiveMultiplier | NegativeMultiplier = POSITIVE_MULTIPLIER;

      if (formattedCustomDiscount?.amount) {
        multiplier = Big(formattedCustomDiscount.amount).gt(0) ? POSITIVE_MULTIPLIER : NEGATIVE_MULTIPLIER;
      }

      if (formattedCustomDiscount?.percentage) {
        multiplier = Big(formattedCustomDiscount.percentage).gt(0) ? POSITIVE_MULTIPLIER : NEGATIVE_MULTIPLIER;
      }

      form.resetForm();
      form.setFormValues({
        customDiscountMultiplier: multiplier,
        customDiscountName: formattedCustomDiscount.name,
        customDiscountType: formattedCustomDiscount.type,
        customDiscountVatRate: Number(formattedCustomDiscount.vatRate),
        customDiscountAmountExVAT: formattedCustomDiscount.amount
          ? mapNumberToAmount(Big(formattedCustomDiscount.amount).mul(multiplier).toNumber())
          : undefined,
        customDiscountPercentage: formattedCustomDiscount?.percentage
          ? Big(formattedCustomDiscount?.percentage).mul(multiplier).toNumber()
          : undefined,
      });
    } else if (hasInitialValues) {
      // Case : Create a new Pro rata (predefined values)
      setFormValues({
        customDiscountName: t('customDiscount:defaultValues.name'),
        customDiscountMultiplier: POSITIVE_MULTIPLIER,
        customDiscountVatRate: 20,
        customDiscountType: QUOTE_CUSTOM_DISCOUNT_TYPE.PERCENTAGE,
        customDiscountAmountExVAT: undefined,
        customDiscountPercentage: undefined,
      });
    } else {
      // Case : Create a new custom discount (no predefined values)
      setFormValues({
        customDiscountType: QUOTE_CUSTOM_DISCOUNT_TYPE.PERCENTAGE,
        customDiscountMultiplier: POSITIVE_MULTIPLIER,
        customDiscountAmountExVAT: undefined,
        customDiscountPercentage: undefined,
        customDiscountVatRate: undefined,
        customDiscountName: undefined,
      });
    }
  }, [setFormValues, saleDiscount, quote, mapNumberToAmount, customDiscountId, hasInitialValues, form]);

  const handleQuoteCustomsDiscountSubmit = useCallback(async () => {
    const {
      customDiscountAmountExVAT,
      customDiscountPercentage,
      customDiscountType,
      customDiscountName,
      customDiscountVatRate,
      customDiscountMultiplier,
    } = getFormValues();

    const customDiscountDto: Partial<QuoteCustomDiscountObject> = {
      name: customDiscountName!,
      vatRate: Big(customDiscountVatRate!).toString(),
      type: customDiscountType!,
      percentage: customDiscountPercentage
        ? Big(customDiscountPercentage)
            .mul(customDiscountMultiplier || POSITIVE_MULTIPLIER)
            .toString()
        : null,
      amount: !isNil(customDiscountAmountExVAT)
        ? mapAmountToStringNumber(
            Big(customDiscountAmountExVAT)
              .mul(customDiscountMultiplier || POSITIVE_MULTIPLIER)
              .toString(),
          )
        : null,
    };

    if (customDiscountId) {
      quoteCustomDiscountUpdate({
        id: customDiscountId,
        ...customDiscountDto,
      });
    } else {
      quoteCustomDiscountCreate(customDiscountDto);
    }
  }, [customDiscountId, getFormValues, mapAmountToStringNumber, quoteCustomDiscountCreate, quoteCustomDiscountUpdate]);

  return (
    <Form form={form}>
      <AddCustomDiscountModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleQuoteCustomsDiscountSubmit}
        hasDiscount={!!saleDiscount}
        totalAfterSaleDiscountExVAT={quote.getAmountAfterSaleDiscountExVat() ?? Big(0)}
      />
    </Form>
  );
};
