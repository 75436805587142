import type { FC } from 'react';
import { useCallback, useState } from 'react';
import type { DrawerApi, DrawersStackContextApi } from '@graneet/lib-ui';
import { BatiprixIcon, Button, DrawersStack } from '@graneet/lib-ui';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { AxiosError } from 'axios';
import { BATIPRIX_SESSION_TYPE, THIRD_PARTY } from '@graneet/business-logic';

import { getBatiprixIframe, useBatiprixToken } from '../services/batiprix.api';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useThirdParty } from 'features/third-party/hooks/useThirdParty';

interface BatiprixImportDrawerProps {
  drawersStack: DrawersStackContextApi<string>;
  drawer: DrawerApi<string>;
  onSubmit: (batiprixInfos: { sessionId: string; token: string }) => Promise<[AxiosError<unknown, any>]>;
  onDataLoaded: (data: { sessionId: string; token: string }) => void;
  quoteId: number;
}

const BatiprixImportDrawerStack: FC<BatiprixImportDrawerProps> = ({ quoteId, onDataLoaded }) => {
  const batiprixToken = useBatiprixToken({ batiprixSessionType: BATIPRIX_SESSION_TYPE.QUOTE, typeId: quoteId });
  onDataLoaded(batiprixToken.data);

  const url = getBatiprixIframe(batiprixToken.data.sessionId, batiprixToken.data.token);

  return <iframe title="batiprix" height="100%" width="100%" src={url} />;
};

export const BatiprixImportDrawer: FC<BatiprixImportDrawerProps> = ({ drawersStack, drawer, onSubmit, quoteId }) => {
  const { t } = useTranslation(['global']);

  const [tokens, setTokens] = useState<{ sessionId: string; token: string } | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = useCallback(async () => {
    if (!tokens) {
      return;
    }

    setIsLoading(true);
    const [error] = await onSubmit({ sessionId: tokens.sessionId, token: tokens.token });
    setIsLoading(false);

    if (!error) {
      drawer.onClose();
    }
  }, [onSubmit, tokens, drawer]);

  const hasBatiprix = useThirdParty(THIRD_PARTY.BATIPRIX);
  if (!hasBatiprix) {
    return null;
  }

  return (
    <DrawersStack drawersStack={drawersStack}>
      <DrawersStack.Drawer
        title={
          <Flex alignItems="center">
            <BatiprixIcon />
            <Text ml={2}>{t('global:batiprixDrawer.title')}</Text>
          </Flex>
        }
        drawer={drawer}
        footer={
          <Flex justify="flex-end" w="100%">
            <Button onClick={handleOnSubmit} isLoading={isLoading}>
              {t('global:batiprixDrawer.cta')}
            </Button>
          </Flex>
        }
        size="2xl"
      >
        <QueryWrapper>
          <BatiprixImportDrawerStack
            drawersStack={drawersStack}
            drawer={drawer}
            onDataLoaded={setTokens}
            onSubmit={onSubmit}
            quoteId={quoteId}
          />
        </QueryWrapper>
      </DrawersStack.Drawer>
    </DrawersStack>
  );
};
