import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import * as jobsApi from '../services/quote-job.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Upload files of job.
 */
export const useUploadJobFiles = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (jobId: number, files: File[]) => {
      if (files.length > 0) {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append('quoteJobFiles', file, encodeURIComponent(file.name));
        });

        const [err, result] = await jobsApi.uploadJobFiles(jobId, formData);
        if (err) {
          toast.error(t('quote:jobsStep.errors.uploadJobFileError'));
          return;
        }
        updateDataLocally(result);
      }
    },
    [updateDataLocally, toast, t],
  );
};
