import { ContentTypeGuard, type QuoteNodeObject } from '@org/quotation-lib';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { MenuData, MenuElement, MenuGroup } from '@graneet/lib-ui';
import { v4 as uuid } from 'uuid';
import { BatiprixIcon, SimpleAddFolderIcon, SimpleSquarePlusIcon } from '@graneet/lib-ui';
import { THIRD_PARTY } from '@graneet/business-logic';

import { useQuoteBasicItemCreate } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemCreate';
import { useQuoteBasicItemDelete } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemDelete';
import { useQuoteBasicItemTransformToHiddenCost } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemTransformToHiddenCost';
import { useQuoteBasicItemTransformToOptional } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemTransformToOptional';
import { useQuoteAddImage } from 'features/quotation/quote-common/hooks/useQuoteAddImage';
import { useQuoteComponentCreate } from 'features/quotation/quote-component/hooks/useQuoteComponentCreate';
import { useQuoteComponentDelete } from 'features/quotation/quote-component/hooks/useQuoteComponentDelete';
import { useQuoteHiddenCostItemDelete } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemDelete';
import { useQuoteHiddenCostItemTransformToBasic } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemTransformToBasic';
import { useQuoteLotCreate } from 'features/quotation/quote-lot/hooks/useQuoteLotCreate';
import { useQuoteLotDelete } from 'features/quotation/quote-lot/hooks/useQuoteLotDelete';
import { useQuoteLotTransformToBasic } from 'features/quotation/quote-lot/hooks/useQuoteLotTransformToBasic';
import { useQuoteLotTransformToOptional } from 'features/quotation/quote-lot/hooks/useQuoteLotTransformToOptional';
import { useQuoteNodeDuplicate } from 'features/quotation/quote-node/hooks/useQuoteNodeDuplicate';
import { useQuoteOptionalItemCreate } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemCreate';
import { useQuoteOptionalItemDelete } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemDelete';
import { useQuoteOptionalItemTransformToBasic } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemTransformToBasic';
import { useQuoteSubItemCreate } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemCreate';
import { useQuoteSubItemDelete } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemDelete';
import { useQuoteLibrarySaveComponent } from 'features/quotation/quote-library/hooks/useQuoteLibrarySaveComponent';
import { useQuoteLibrarySaveItem } from 'features/quotation/quote-library/hooks/useQuoteLibrarySaveItems';
import { useQuoteLibraryCanExport } from 'features/quotation/quote-library/hooks/useQuoteLibraryCanExport';
import {
  updateQuoteBatiprixModalIsOpen,
  updateQuoteBatiprixModalParentId,
  updateQuoteImportComponentDrawerIsOpen,
  updateQuoteImportComponentTargetParentId,
  updateQuoteImportItemModalIsOpen,
  updateQuoteImportItemTargetParentId,
  updateQuoteLotDefaultVatRateModal,
  updateQuoteNotesModal,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useQuoteLibraryCanImportComponent } from 'features/quotation/quote-library/hooks/useQuoteLibraryCanImportComponent';
import { useQuoteBasicItemUpdateNote } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateNote';
import { useQuoteOptionalItemUpdateNote } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateNote';
import { useQuoteHiddenCostItemUpdateNote } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemUpdateNote';
import { useThirdParty } from 'features/third-party/hooks/useThirdParty';
import { useQuoteLibraryCanImportSubItem } from 'features/quotation/quote-library/hooks/useQuoteLibraryCanImportSubItems';
import { useQuoteLotUpdateNote } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateNote';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { umamiTrackEvent } from 'features/app/hooks/umami/umamiTrackEvent';

export const useContextMenu = () => {
  const { t } = useTranslation(['quote', 'global']);

  const { quote } = useQuote();

  const quoteLibrarySaveItem = useQuoteLibrarySaveItem();
  const quoteLibraryCanExport = useQuoteLibraryCanExport();
  const quoteLibraryCanImportComponent = useQuoteLibraryCanImportComponent();
  const quoteLibraryCanImportSubItem = useQuoteLibraryCanImportSubItem();

  const quoteBasicItemCreate = useQuoteBasicItemCreate();
  const quoteLotCreate = useQuoteLotCreate();
  const quoteLotDelete = useQuoteLotDelete();
  const quoteLotDeleteNote = useQuoteLotUpdateNote();
  const quoteLotTransformToBasic = useQuoteLotTransformToBasic();
  const quoteLotTransformToOptional = useQuoteLotTransformToOptional();
  const quoteOptionalItemCreate = useQuoteOptionalItemCreate();
  const quoteNodeDuplicate = useQuoteNodeDuplicate();

  const quoteBasicItemDelete = useQuoteBasicItemDelete();
  const quoteBasicItemDeleteNote = useQuoteBasicItemUpdateNote();
  const quoteBasicItemTransformToHiddenCost = useQuoteBasicItemTransformToHiddenCost();
  const quoteBasicItemTransformToOptional = useQuoteBasicItemTransformToOptional();
  const quoteComponentCreate = useQuoteComponentCreate();
  const quoteSubItemCreate = useQuoteSubItemCreate();
  const quoteAddImage = useQuoteAddImage();

  const quoteComponentDelete = useQuoteComponentDelete();

  const quoteLibrarySaveComponent = useQuoteLibrarySaveComponent();

  const quoteHiddenCostItemTransformToBasic = useQuoteHiddenCostItemTransformToBasic();
  const quoteHiddenCostItemDelete = useQuoteHiddenCostItemDelete();
  const quoteHiddenCostItemDeleteNote = useQuoteHiddenCostItemUpdateNote();

  const quoteOptionalItemDelete = useQuoteOptionalItemDelete();
  const quoteOptionalItemDeleteNote = useQuoteOptionalItemUpdateNote();
  const quoteOptionalItemTransformToBasic = useQuoteOptionalItemTransformToBasic();

  const quoteSubItemDelete = useQuoteSubItemDelete();

  const { executeCommand } = useCommands();

  const handleUpdateNote = useCallback((nodeId: string, note: string | null) => {
    updateQuoteNotesModal({ open: true, note, nodeId });
  }, []);

  const handleUpdateDefaultVatRate = useCallback((nodeId: string) => {
    updateQuoteLotDefaultVatRateModal({ isOpen: true, nodeId });
  }, []);

  const hasBatiprix = useThirdParty(THIRD_PARTY.BATIPRIX);

  return useCallback(
    (onClose: () => void, data: QuoteNodeObject): MenuElement[] => {
      if (!quote) {
        throw new Error('Quote not found');
      }

      const node = quote.getTree().getNodeOrNull(data.id);
      const nodeContent = node?.getContent();

      const addComponent: MenuData = {
        type: 'item' as const,
        id: uuid(),
        name: t('quote:quotation.actionMenu.addComponent'),
        onClick: () => {},
        items: [
          {
            type: 'item' as const,
            id: uuid(),
            icon: <SimpleSquarePlusIcon width="1rem" height="1rem" />,
            name: t('quote:quotation.actionMenu.new'),
            onClick: () => {
              executeCommand(quoteComponentCreate(data.id), quote);
              onClose();
            },
          },
          {
            type: 'item' as const,
            id: uuid(),
            icon: <SimpleAddFolderIcon width="1rem" height="1rem" />,
            disabled: !quoteLibraryCanImportComponent(data.id),
            name: t('quote:quotation.actionMenu.priceLibrary'),
            onClick: () => {
              updateQuoteImportComponentDrawerIsOpen(true);
              updateQuoteImportComponentTargetParentId(data.id);
              onClose();
            },
          },
        ],
      };

      const addComponentDisabled: MenuData = {
        type: 'item' as const,
        id: uuid(),
        name: t('quote:quotation.actionMenu.addComponent'),
        tooltip: t('quote:quotation.actionMenu.itemChildDisabled'),
        disabled: true,
        onClick: () => {},
      };

      const subItemItems: (MenuData | MenuGroup)[] = [
        {
          id: uuid(),
          type: 'item' as const,
          icon: <SimpleSquarePlusIcon width="1rem" height="1rem" />,
          name: t('quote:quotation.actionMenu.new'),
          onClick: () => {
            executeCommand(quoteSubItemCreate(data.id), quote);
            onClose();
          },
        },
        {
          id: uuid(),
          type: 'item' as const,
          icon: <SimpleAddFolderIcon width="1rem" height="1rem" />,
          disabled: !quoteLibraryCanImportSubItem(data.id),
          name: t('quote:quotation.actionMenu.priceLibrary'),
          onClick: () => {
            updateQuoteImportItemModalIsOpen(true);
            updateQuoteImportItemTargetParentId(data.id);
            onClose();
          },
        },
      ];

      if (hasBatiprix) {
        subItemItems.push({
          id: uuid(),
          type: 'item' as const,
          icon: <BatiprixIcon width="1rem" height="1rem" />,
          disabled: !quoteLibraryCanImportSubItem(data.id),
          name: t('quote:emptyState.cards.batiprix.subtitle'),
          onClick: () => {
            updateQuoteBatiprixModalIsOpen(true);
            updateQuoteBatiprixModalParentId(data.id);
            onClose();
          },
        });
      }

      const addSubItem: MenuData = {
        type: 'item' as const,
        id: uuid(),
        name: t('quote:quotation.actionMenu.addSubItem'),
        onClick: () => {},
        items: subItemItems,
      };

      const addSubItemDisabled: MenuData = {
        type: 'item' as const,
        id: uuid(),
        name: t('quote:quotation.actionMenu.addSubItem'),
        tooltip: t('quote:quotation.actionMenu.itemChildDisabled'),
        onClick: () => {},
        disabled: true,
      };

      if (!nodeContent) {
        return [];
      }

      if (ContentTypeGuard.isQuoteLot(nodeContent)) {
        const importActions = [
          {
            id: uuid(),
            type: 'item' as const,
            icon: <SimpleAddFolderIcon width="1rem" height="1rem" />,
            name: t('quote:quotation.actionMenu.priceLibrary'),
            onClick: () => {
              updateQuoteImportItemModalIsOpen(true);
              updateQuoteImportItemTargetParentId(data.id);
              onClose();
            },
          },
        ];

        if (hasBatiprix) {
          importActions.push({
            id: uuid(),
            type: 'item' as const,
            icon: <BatiprixIcon width="1rem" height="1rem" />,
            name: t('quote:emptyState.cards.batiprix.subtitle'),
            onClick: () => {
              updateQuoteBatiprixModalIsOpen(true);
              updateQuoteBatiprixModalParentId(data.id);
              onClose();
            },
          });
        }

        return [
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.addSubLot'),
                onClick: () => {
                  executeCommand(quoteLotCreate(data.id), quote);
                  onClose();
                },
              },
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.addItem'),
                onClick: () => {},
                items: [
                  {
                    type: 'item' as const,
                    id: uuid(),
                    icon: <SimpleSquarePlusIcon width="1rem" height="1rem" />,
                    name: t('quote:quotation.actionMenu.new'),
                    onClick: () => {
                      executeCommand(quoteBasicItemCreate(data.id), quote);
                      umamiTrackEvent({
                        feature: 'quotation',
                        fromZone: 'contextMenu',
                        action: 'create',
                        entity: 'basicItem',
                      });
                      onClose();
                    },
                  },
                  ...importActions,
                ],
              },
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.addOptionalItem'),
                onClick: () => {
                  executeCommand(quoteOptionalItemCreate(data.id), quote);
                  onClose();
                },
              },
            ],
          },
          {
            type: 'group' as const,
            id: uuid(),
            items: nodeContent.getIsEveryChildOptional()
              ? [
                  {
                    type: 'item' as const,
                    id: uuid(),
                    name: t('quote:quotation.actionMenu.removeOption'),
                    onClick: () => {
                      executeCommand(quoteLotTransformToBasic(data.id), quote);
                      onClose();
                    },
                  },
                ]
              : [
                  {
                    type: 'item' as const,
                    id: uuid(),
                    name: t('quote:quotation.actionMenu.addOption'),
                    onClick: () => {
                      executeCommand(quoteLotTransformToOptional(data.id), quote);
                      onClose();
                    },
                  },
                ],
          },
          {
            type: 'group' as const,
            id: uuid(),
            items: data.content.note
              ? [
                  {
                    type: 'item' as const,
                    id: uuid(),
                    name: t('quote:quotation.actionMenu.updateNote'),
                    onClick: () => {
                      handleUpdateNote(data.id, data.content.note);
                      onClose();
                    },
                  },
                  {
                    type: 'item' as const,
                    id: uuid(),
                    name: t('quote:quotation.actionMenu.removeNote'),
                    onClick: () => {
                      executeCommand(quoteLotDeleteNote(data.id, null), quote);
                      onClose();
                    },
                  },
                ]
              : [
                  {
                    type: 'item' as const,
                    id: uuid(),
                    name: t('quote:quotation.actionMenu.addNote'),
                    onClick: () => {
                      handleUpdateNote(data.id, data.content.note);
                      onClose();
                    },
                  },
                ],
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('global:applyVAT'),
            onClick: () => {
              handleUpdateDefaultVatRate(data.id);
            },
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.duplicate'),
            onClick: () => {
              executeCommand(quoteNodeDuplicate(data.id), quote);
              onClose();
            },
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.remove'),
            color: 'danger' as const,
            onClick: () => {
              executeCommand(quoteLotDelete(data.id), quote);
              onClose();
            },
          },
        ];
      }

      if (ContentTypeGuard.isQuoteBasicItem(nodeContent)) {
        const childrenType = nodeContent.getChildrenType();

        const items = [];

        switch (childrenType) {
          case 'QuoteSubItem': {
            items.push(addSubItem);
            items.push(addComponentDisabled);
            break;
          }
          case 'QuoteComponent': {
            items.push(addComponent);
            items.push(addSubItemDisabled);
            break;
          }
          default: {
            items.push(addSubItem);
            items.push(addComponent);
          }
        }

        return [
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              ...items,
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.addImages'),
                onClick: () => {
                  quoteAddImage(data.content.id);
                  onClose();
                },
              },
              ...(data.content.note
                ? [
                    {
                      type: 'item' as const,
                      id: uuid(),
                      name: t('quote:quotation.actionMenu.updateNote'),
                      onClick: () => {
                        handleUpdateNote(data.id, data.content.note);
                        onClose();
                      },
                    },
                    {
                      type: 'item' as const,
                      id: uuid(),
                      name: t('quote:quotation.actionMenu.removeNote'),
                      onClick: () => {
                        executeCommand(quoteBasicItemDeleteNote(data.id, null), quote);
                        onClose();
                      },
                    },
                  ]
                : [
                    {
                      type: 'item' as const,
                      id: uuid(),
                      name: t('quote:quotation.actionMenu.addNote'),
                      onClick: () => {
                        handleUpdateNote(data.id, data.content.note);
                        onClose();
                      },
                    },
                  ]),
            ],
          },
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.addOption'),
                onClick: () => {
                  executeCommand(quoteBasicItemTransformToOptional(data.id), quote);
                  onClose();
                },
              },
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.hiddenCost'),
                onClick: () => {
                  executeCommand(quoteBasicItemTransformToHiddenCost(data.id), quote);
                  onClose();
                },
              },
            ],
          },
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.exportToQuoteLibrary'),
                disabled: !quoteLibraryCanExport(data.id),
                tooltip: t('quote:quotation.actionMenu.exportToQuoteLibraryTooltip'),
                onClick: () => {
                  quoteLibrarySaveItem(data.id);
                  onClose();
                },
              },
            ],
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.duplicate'),
            disabled: false,
            tooltip: t('global:soonAvailable'),
            onClick: () => {
              executeCommand(quoteNodeDuplicate(data.id), quote);
              onClose();
            },
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.remove'),
            color: 'danger' as const,
            onClick: () => {
              executeCommand(quoteBasicItemDelete(data.id), quote);
              onClose();
            },
          },
        ];
      }

      if (ContentTypeGuard.isQuoteComponent(nodeContent)) {
        return [
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.exportToQuoteLibrary'),
                disabled: !quoteLibraryCanExport(data.id),
                tooltip: t('quote:quotation.actionMenu.exportToQuoteLibraryTooltip'),
                onClick: () => {
                  quoteLibrarySaveComponent(data.id);
                  onClose();
                },
              },
            ],
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.duplicate'),
            disabled: false,
            tooltip: t('global:soonAvailable'),
            onClick: () => {
              executeCommand(quoteNodeDuplicate(data.id), quote);
              onClose();
            },
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.remove'),
            color: 'danger' as const,
            onClick: () => {
              executeCommand(quoteComponentDelete(data.id), quote);
              onClose();
            },
          },
        ];
      }

      if (ContentTypeGuard.isQuoteHiddenCostItem(nodeContent)) {
        const childrenType = nodeContent.getChildrenType();

        const items = [];

        switch (childrenType) {
          case 'QuoteSubItem': {
            items.push(addSubItem);
            items.push(addComponentDisabled);
            break;
          }
          case 'QuoteComponent': {
            items.push(addComponent);
            items.push(addSubItemDisabled);
            break;
          }
          default: {
            items.push(addSubItem);
            items.push(addComponent);
          }
        }

        return [
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              ...items,
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.addImages'),
                onClick: () => {
                  quoteAddImage(data.content.id);
                  onClose();
                },
              },
              ...(data.content.note
                ? [
                    {
                      type: 'item' as const,
                      id: uuid(),
                      name: t('quote:quotation.actionMenu.updateNote'),
                      onClick: () => {
                        handleUpdateNote(data.id, data.content.note);
                        onClose();
                      },
                    },
                    {
                      type: 'item' as const,
                      id: uuid(),
                      name: t('quote:quotation.actionMenu.removeNote'),
                      onClick: () => {
                        executeCommand(quoteHiddenCostItemDeleteNote(data.id, null), quote);
                        onClose();
                      },
                    },
                  ]
                : [
                    {
                      type: 'item' as const,
                      id: uuid(),
                      name: t('quote:quotation.actionMenu.addNote'),
                      onClick: () => {
                        handleUpdateNote(data.id, data.content.note);
                        onClose();
                      },
                    },
                  ]),
            ],
          },
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.removeHiddenCost'),
                onClick: () => {
                  executeCommand(quoteHiddenCostItemTransformToBasic(data.id), quote);
                  onClose();
                },
              },
            ],
          },
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.exportToQuoteLibrary'),
                disabled: !quoteLibraryCanExport(data.id),
                tooltip: t('quote:quotation.actionMenu.exportToQuoteLibraryTooltip'),
                onClick: () => {
                  quoteLibrarySaveItem(data.id);
                  onClose();
                },
              },
            ],
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.duplicate'),
            disabled: false,
            tooltip: t('global:soonAvailable'),
            onClick: () => {
              executeCommand(quoteNodeDuplicate(data.id), quote);
              onClose();
            },
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.remove'),
            color: 'danger' as const,
            onClick: () => {
              executeCommand(quoteHiddenCostItemDelete(data.id), quote);
              onClose();
            },
          },
        ];
      }

      if (ContentTypeGuard.isQuoteOptionalItem(nodeContent)) {
        const childrenType = nodeContent.getChildrenType();

        const items = [];

        switch (childrenType) {
          case 'QuoteSubItem': {
            items.push(addSubItem);
            items.push(addComponentDisabled);
            break;
          }
          case 'QuoteComponent': {
            items.push(addComponent);
            items.push(addSubItemDisabled);
            break;
          }
          default: {
            items.push(addSubItem);
            items.push(addComponent);
          }
        }

        return [
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              ...items,
              {
                id: uuid(),
                type: 'item' as const,
                name: t('quote:quotation.actionMenu.addImages'),
                onClick: () => {
                  quoteAddImage(data.content.id);
                  onClose();
                },
              },
              ...(data.content.note
                ? [
                    {
                      type: 'item' as const,
                      id: uuid(),
                      name: t('quote:quotation.actionMenu.updateNote'),
                      onClick: () => {
                        handleUpdateNote(data.id, data.content.note);
                        onClose();
                      },
                    },
                    {
                      type: 'item' as const,
                      id: uuid(),
                      name: t('quote:quotation.actionMenu.removeNote'),
                      onClick: () => {
                        executeCommand(quoteOptionalItemDeleteNote(data.id, null), quote);
                        onClose();
                      },
                    },
                  ]
                : [
                    {
                      type: 'item' as const,
                      id: uuid(),
                      name: t('quote:quotation.actionMenu.addNote'),
                      onClick: () => {
                        handleUpdateNote(data.id, data.content.note);
                        onClose();
                      },
                    },
                  ]),
            ],
          },
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              {
                type: 'item' as const,
                id: uuid(),
                disabled: false,
                name: t('quote:quotation.actionMenu.removeOption'),
                onClick: () => {
                  executeCommand(quoteOptionalItemTransformToBasic(data.id), quote);
                  onClose();
                },
              },
            ],
          },
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.exportToQuoteLibrary'),
                disabled: !quoteLibraryCanExport(data.id),
                tooltip: t('quote:quotation.actionMenu.exportToQuoteLibraryTooltip'),
                onClick: () => {
                  quoteLibrarySaveItem(data.id);
                  onClose();
                },
              },
            ],
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.duplicate'),
            disabled: false,
            tooltip: t('global:soonAvailable'),
            onClick: () => {
              executeCommand(quoteNodeDuplicate(data.id), quote);
              onClose();
            },
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.remove'),
            color: 'danger' as const,
            onClick: () => {
              executeCommand(quoteOptionalItemDelete(data.id), quote);
              onClose();
            },
          },
        ];
      }

      if (ContentTypeGuard.isQuoteSubItem(nodeContent)) {
        return [
          {
            type: 'group' as const,
            id: uuid(),
            items: [addComponent],
          },
          {
            type: 'group' as const,
            id: uuid(),
            items: [
              {
                type: 'item' as const,
                id: uuid(),
                name: t('quote:quotation.actionMenu.exportToQuoteLibrary'),
                tooltip: t('quote:quotation.actionMenu.exportToQuoteLibraryTooltip'),
                disabled: !quoteLibraryCanExport(data.id),
                onClick: () => {
                  quoteLibrarySaveItem(data.id);
                  onClose();
                },
              },
            ],
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.duplicate'),
            disabled: false,
            tooltip: t('global:soonAvailable'),
            onClick: () => {
              executeCommand(quoteNodeDuplicate(data.id), quote);
              onClose();
            },
          },
          {
            type: 'item' as const,
            id: uuid(),
            name: t('quote:quotation.actionMenu.remove'),
            color: 'danger' as const,
            onClick: () => {
              executeCommand(quoteSubItemDelete(data.id), quote);
              onClose();
            },
          },
        ];
      }

      return [];
    },
    [
      executeCommand,
      handleUpdateDefaultVatRate,
      handleUpdateNote,
      hasBatiprix,
      quote,
      quoteAddImage,
      quoteBasicItemCreate,
      quoteBasicItemDelete,
      quoteBasicItemDeleteNote,
      quoteBasicItemTransformToHiddenCost,
      quoteBasicItemTransformToOptional,
      quoteComponentCreate,
      quoteComponentDelete,
      quoteHiddenCostItemDelete,
      quoteHiddenCostItemDeleteNote,
      quoteHiddenCostItemTransformToBasic,
      quoteLibraryCanExport,
      quoteLibraryCanImportComponent,
      quoteLibraryCanImportSubItem,
      quoteLibrarySaveComponent,
      quoteLibrarySaveItem,
      quoteLotCreate,
      quoteLotDelete,
      quoteLotDeleteNote,
      quoteLotTransformToBasic,
      quoteLotTransformToOptional,
      quoteNodeDuplicate,
      quoteOptionalItemCreate,
      quoteOptionalItemDelete,
      quoteOptionalItemDeleteNote,
      quoteOptionalItemTransformToBasic,
      quoteSubItemCreate,
      quoteSubItemDelete,
      t,
    ],
  );
};
