import { Card, LabeledData, Date, TwoColumns } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import type { QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';

interface QuoteInformationCardProps {
  quote: QuoteWithoutRelationsDTO;
}

export const QuoteInformationCard: FC<QuoteInformationCardProps> = ({ quote }) => {
  const { t } = useTranslation(['quote']);
  const { refCode, addedAt, validityDuration, startedAt, workDuration } = quote;

  return (
    <Card title={t('quote:cards.information')}>
      <TwoColumns>
        <LabeledData label={t('quote:fields.refCode')} data={refCode} />
        <LabeledData label={t('quote:fields.date')} data={<Date>{addedAt}</Date>} />
        <LabeledData
          label={t('quote:fields.validityDuration')}
          data={Number.isFinite(validityDuration) && t('quote:data.duration', { days: validityDuration })}
        />
        <LabeledData label={t('quote:fields.estimatedStartDate')} data={<Date>{startedAt}</Date>} />
        <LabeledData
          label={t('quote:fields.workDuration')}
          data={Number.isFinite(workDuration) && t('quote:data.duration', { days: workDuration })}
        />
      </TwoColumns>
    </Card>
  );
};
