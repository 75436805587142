import { IS_APPLE } from '../../../../../../utils';
import { toolbarTranslations } from '../../../../configureDefaultLabels';

const RICH_TEXT_SHORTCUT = {
  undo: {
    mac: '⌘Z',
    windows: 'Ctrl+Z',
  },
  redo: {
    mac: 'Maj+⌘Z',
    windows: 'Maj+Ctrl+Z',
  },
  bold: {
    mac: '⌘B',
    windows: 'Ctrl+B',
  },
  italic: {
    mac: '⌘I',
    windows: 'Ctrl+I',
  },
  underline: {
    mac: '⌘U',
    windows: 'Ctrl+U',
  },
  link: {
    mac: '⌘K',
    windows: 'Ctrl+K',
  },
  clearFormatting: {
    mac: '⌘\\',
    windows: 'Ctrl+\\',
  },
} as const;

export const getRichTextShortcutTranslation = (action: keyof typeof RICH_TEXT_SHORTCUT): string => {
  const env = IS_APPLE ? 'mac' : 'windows';

  const translationValue = toolbarTranslations[action];
  const shortcutTrans = `(${RICH_TEXT_SHORTCUT[action][env]})`;

  return `${translationValue} ${shortcutTrans}`;
};
