import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, VStack } from '@chakra-ui/react';
import type { IOrder, IProject } from '@graneet/business-logic';
import {
  isDirectPaymentSupplierInvoice as isDirectPaymentSupplierInvoiceFn,
  MINDEE_MAX_PAGES,
  PERMISSION,
  SUPPLIER_INVOICE_MODE,
} from '@graneet/business-logic';
import { Callout, SimpleAlertIcon, formatNumberToVatRate, useCurrency } from '@graneet/lib-ui';
import { Form, useFormContext, useOnChangeValues, useStepForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { SupplierInvoiceItemsCard } from 'features/supplier-invoice/components/cards/SupplierInvoiceItemsCard';
import { SupplierInvoiceClassificationCard } from 'features/supplier-invoice/components/cards/SupplierInvoiceClassificationCard';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { AssociatedProjectsCard } from 'features/supplier-invoice/components/cards/AssociatedProjectsCard';
import { useSupplierInvoiceContext } from 'features/supplier-invoice/hooks/UseSupplierInvoiceContext';
import { useSupplierInvoiceProgressOCRLazy } from 'features/supplier-invoice/services/supplier-invoice.api';
import {
  mapSupplierInvoiceOCRResponseToFormValues,
  mapSupplierInvoiceToFormValues,
} from 'features/supplier-invoice/services/supplier-invoices.util';
import type {
  EditSupplierInvoiceForm,
  EditSupplierInvoiceScreenFormValues,
} from 'features/supplier-invoice/forms/edit-supplier-invoice.form';
import { SupplierInvoiceReceiptFilesCard } from 'features/supplier-invoice/components/cards/SupplierInvoiceReceiptFilesCard/SupplierInvoiceReceiptFilesCard';
import { SupplierInvoiceCommentCard } from 'features/supplier-invoice/components/cards/SupplierInvoiceCommentCard';
import { UpdatableInvoiceFileCard } from 'features/supplier-invoice/components/cards/UpdatableInvoiceFileCard/UpdatableInvoiceFileCard';
import { SupplierInvoiceInformationEditCard } from 'features/supplier-invoice/components/cards/SupplierInvoiceInformationEditCard';
import { SupplierInvoiceSupplierCard } from 'features/supplier-invoice/components/cards/SupplierInvoiceSupplierCard';
import { SupplierInvoiceProjectsAssociationCard } from 'features/supplier-invoice/components/cards/SupplierInvoiceProjectsAssociationCard/SupplierInvoiceProjectsAssociationCard';
import { AssociatedOrderCreationCard } from 'features/supplier-invoice/components/cards/AssociateOrderCreationCard';
import { useDefaultVatRate } from 'features/common/hooks/useDefaultVATRate';

interface SupplierInvoiceInformationStepProps {
  project: IProject | undefined;
}

const ProjectCard = ({ isDirectPaymentSupplierInvoice }: { isDirectPaymentSupplierInvoice: boolean }) => {
  const form = useFormContext<EditSupplierInvoiceForm>();
  const { mode } = useOnChangeValues(form, ['mode']);

  const { supplierInvoice } = useSupplierInvoiceContext();

  if (mode !== SUPPLIER_INVOICE_MODE.CONDENSED) {
    return null;
  }

  if (isDirectPaymentSupplierInvoice) {
    return <AssociatedProjectsCard supplierInvoice={supplierInvoice} />;
  }

  return <SupplierInvoiceProjectsAssociationCard />;
};

export const SupplierInvoiceInformationStep: FC<SupplierInvoiceInformationStepProps> = ({ project }) => {
  const { t } = useTranslation(['supplierInvoices']);
  const { mapNumberToAmount } = useCurrency();

  const hasPermissionsToSeeOrder = usePermissions([PERMISSION.ACCESS_ORDER]);

  const { form, initFormValues } = useStepForm<EditSupplierInvoiceScreenFormValues, 'information'>();

  const { pdfPageNumber } = useOnChangeValues(form, ['pdfPageNumber']);

  const hasTooManyPages = useMemo(() => (pdfPageNumber || 0) > MINDEE_MAX_PAGES, [pdfPageNumber]);

  const { supplierInvoice, onOCR } = useSupplierInvoiceContext();

  const supplierInvoiceProgressOCRLazy = useSupplierInvoiceProgressOCRLazy();

  // Set the autocomplete search value when the supplier name is provided by OCR
  const [supplierDefaultInputValue, setSupplierDefaultInputValue] = useState<string | undefined>();

  /**
   * Store invoice file. Value is defined only if invoice file has been updated once
   */
  const [invoiceFile, setInvoiceFile] = useState<File | null>(null);
  const onFileChange = useCallback(
    async (file: File, hasOCRCreditLeft: boolean, nbRetry = 0) => {
      setInvoiceFile(file);

      if (!file || !hasOCRCreditLeft) {
        return;
      }

      const { pdfPageNumber: currentPdfPageNumber } = form.getFormValues();

      if (!currentPdfPageNumber && nbRetry < 5) {
        await new Promise<void>((resolve) => {
          setTimeout(resolve, 100);
        });
        await onFileChange(file, hasOCRCreditLeft, nbRetry + 1);
        return;
      }

      const currentHasTooManyPages = (currentPdfPageNumber || 0) > MINDEE_MAX_PAGES;
      if (file && !currentHasTooManyPages && !hasTooManyPages) {
        const ocrDatas = await supplierInvoiceProgressOCRLazy.mutateAsync({ invoiceFile: file });
        const { associatedProjects, associatedOrders } = form.getFormValues();

        if (ocrDatas.ocrSuccess) {
          const initialFormValues = mapSupplierInvoiceOCRResponseToFormValues(
            ocrDatas,
            mapNumberToAmount,
            associatedProjects,
            associatedOrders,
          );
          form.setFormValues(initialFormValues);
          onOCR(ocrDatas?.amountExVAT ?? null);
          setSupplierDefaultInputValue(ocrDatas?.supplierQueryName);
        }
      }
    },
    [form, hasTooManyPages, mapNumberToAmount, onOCR, supplierInvoiceProgressOCRLazy],
  );

  const isDirectPaymentSupplierInvoice = isDirectPaymentSupplierInvoiceFn(supplierInvoice);

  const { state } = useLocation<{ order?: IOrder }>();

  const defaultVatRate = useDefaultVatRate();

  useEffect(() => {
    const initialFormValues = mapSupplierInvoiceToFormValues(supplierInvoice, project, mapNumberToAmount, state?.order);
    initFormValues({ ...initialFormValues, ...(defaultVatRate && { vatRate: formatNumberToVatRate(defaultVatRate) }) });
  }, [initFormValues, mapNumberToAmount, project, supplierInvoice, state?.order, defaultVatRate]);

  return (
    <Form form={form} style={{ height: '100%' }}>
      <Grid h="100%" templateColumns="0.8fr 1fr" gap={6}>
        <Box overflow="auto">
          <UpdatableInvoiceFileCard onFileChange={onFileChange} hasOCR />
        </Box>

        <Box overflow="auto">
          <VStack spacing={6} align="stretch">
            {invoiceFile && hasTooManyPages && (
              <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
                {t('supplierInvoices:invoiceFileField.fileTooManyPages')}
              </Callout>
            )}

            <SupplierInvoiceInformationEditCard />

            <SupplierInvoiceSupplierCard supplierDefaultInputValue={supplierDefaultInputValue} />

            <SupplierInvoiceClassificationCard isDirectPaymentSupplierInvoice={isDirectPaymentSupplierInvoice} />

            <SupplierInvoiceItemsCard />

            <ProjectCard isDirectPaymentSupplierInvoice={isDirectPaymentSupplierInvoice} />

            {hasPermissionsToSeeOrder && <AssociatedOrderCreationCard />}

            <SupplierInvoiceCommentCard<EditSupplierInvoiceForm> name="comment" />

            <SupplierInvoiceReceiptFilesCard />
          </VStack>
        </Box>
      </Grid>
    </Form>
  );
};
