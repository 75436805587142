import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { compact } from 'lodash-es';

import {
  getProgressStatementAcceptAction,
  getDownloadPDFAction,
  getEditAction,
  getOpenMailingAction,
  getPreviewPDFAction,
  getDisplayPublicLinkAction,
} from './progress-statement.actions.util';
import type { ProgressStatementActionsParams } from './progress-statement.actions.type';

export function getProgressStatementValidatedActions(
  params: ProgressStatementActionsParams,
): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];
  const acceptAction = getProgressStatementAcceptAction(params);
  const previewPDFAction = getPreviewPDFAction(params);

  if (acceptAction) {
    actions.push({ type: 'primary', ...acceptAction });
  }

  if (previewPDFAction) {
    actions.push({
      type: 'secondary',
      ...previewPDFAction,
    });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([
      getOpenMailingAction(params),
      getEditAction(params),
      getDownloadPDFAction(params),
      // @[ff: virality]
      params.hasViralityFeature ? getDisplayPublicLinkAction(params) : null,
    ]),
  });

  return compact(actions);
}
