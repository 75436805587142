import { Box, Flex, Wrap, useDisclosure } from '@chakra-ui/react';
import { Form, useForm, useFormContext } from 'graneet-form';
import { useCallback, useMemo, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { IExternalProgressStatementResponseDTO, ILotResponseDTO } from '@graneet/business-logic';
import { CUMULATIVE_INPUT_TYPE, mapNumberToAmount } from '@graneet/business-logic';
import { CloseIcon } from '@chakra-ui/icons';
import { Button, DeepTable, Modal, TextAreaField, Tree, useCurrency, useDeepTable } from '@graneet/lib-ui';

import {
  getItemCommentFieldName,
  getItemCumulativeFieldName,
  type ProgressStatementEditItemForm,
} from 'features/progress-statement/forms/progress-statement-edit-item-form';
import { ProgressStatementExternalFooter } from 'features/progress-statement/components/ProgressStatementExternalFooter';
import {
  ProgressStatementProvider,
  useProgressStatementContext,
} from 'features/progress-statement/contexts/ProgressStatementContext';
import { useProgressStatementTree } from 'features/progress-statement/hooks/useProgressStatementTree';
import {
  initialProgressStatementDataValues,
  mapContractToInitialTree,
} from 'features/progress-statement/services/progress-statement.util';
import { ProgressStatementDeepTableHeader } from 'features/progress-statement/components/ProgressStatementDeepTableHeader';
import { ProgressStatementDeepTableContractOrLotLine } from 'features/progress-statement/components/ProgressStatementDeepTableContractOrLotLine/ProgressStatementDeepTableContractOrLotLine';
import { ProgressStatementDeepTableItemLine } from 'features/progress-statement/components/ProgressStatementDeepTableItemLine/ProgressStatementDeepTableItemLine';
import { ProgressStatementDeepTableVirtualizedItemLine } from 'features/progress-statement/components/ProgressStatementDeepTableVirtualizedItemLine/ProgressStatementDeepTableVirtualizedItemLine';
import { ProgressStatementDeepTableSeparator } from 'features/progress-statement/components/ProgressStatementDeepTableSeparator';
import { ProgressStatementLotOrContractWrapper } from 'features/progress-statement/components/ProgressStatementLotOrContractWrapper/ProgressStatementLotOrContractWrapper';
import { ProgressStatementExternalValidationModal } from 'features/progress-statement/components/ProgressStatementExternalValidationModal';
import { useRejectExternalProgressStatement } from 'features/progress-statement/services/external-progress-statement.api';

interface ProgressStatementExternalRejectionPanelProps {
  externalProgressStatement: IExternalProgressStatementResponseDTO;

  externalId: string;

  onGoToApprove: () => void;
}

const PublicTree: FC<ProgressStatementExternalRejectionPanelProps> = ({ externalProgressStatement }) => {
  const { t } = useTranslation(['global', 'progressStatement']);

  const { mapAmountToNumber } = useCurrency();
  const form = useFormContext<ProgressStatementEditItemForm>();
  const [activeItemId, setActiveItemId] = useState<number | null>(null);
  const modalComment = useDisclosure();
  const { previousItemsCumulativeValues } = useProgressStatementContext();

  const { contracts, progressStatement, previousProgressStatement, lines } = externalProgressStatement;

  // discount lines, custom discount lines and deductions are voluntarlily not displayed
  const initialValues = initialProgressStatementDataValues(
    contracts,
    { ...progressStatement, lines },
    previousProgressStatement,
    previousItemsCumulativeValues,
    undefined,
    mapAmountToNumber,
  );

  const initialValue = useMemo(() => mapContractToInitialTree(contracts, initialValues), [contracts, initialValues]);

  const tree = useProgressStatementTree(initialValue);

  const formModal = useForm<{ comment: string }>();
  const onComment = useCallback(
    (itemId: number) => {
      const values = form.getFormValues();
      formModal.setFormValues({ comment: values[getItemCommentFieldName(itemId)] || '' });
      setActiveItemId(itemId);
      modalComment.onOpen();
    },
    [form, formModal, modalComment],
  );

  const handleComment = useCallback(() => {
    const { comment } = formModal.getFormValues();
    const values: Partial<ProgressStatementEditItemForm> = {};
    values[getItemCommentFieldName(activeItemId!)] = comment;
    form.setFormValues(values);
    modalComment.onClose();
  }, [formModal, activeItemId, form, modalComment]);

  return (
    <>
      <Tree
        tree={tree}
        headerComponent={() => ProgressStatementDeepTableHeader({ isUpdatable: false })}
        nodeComponent={(props) => ProgressStatementDeepTableContractOrLotLine({ ...props, isUpdatable: false })}
        leafComponent={({ id, depth }) =>
          ProgressStatementDeepTableItemLine({ id, depth, onComment, isUpdatable: false })
        }
        virtualizedLeafComponent={ProgressStatementDeepTableVirtualizedItemLine}
        separatorComponent={ProgressStatementDeepTableSeparator}
        nodeWrapperComponent={ProgressStatementLotOrContractWrapper}
      />

      <Modal {...modalComment} title={t('progressStatement:external.rejectionForm.commentLine')}>
        <Form form={formModal}>
          <TextAreaField<{ comment: string }> name="comment" placeholder="..." />
        </Form>

        <Modal.PrimaryButton onClick={handleComment}>{t('global:words.c.validate')}</Modal.PrimaryButton>
      </Modal>
    </>
  );
};

export const ProgressStatementExternalRejectionScreen: FC<ProgressStatementExternalRejectionPanelProps> = ({
  externalProgressStatement,
  externalId,
  onGoToApprove,
}) => {
  const { t } = useTranslation(['global', 'progressStatement']);

  const modalValidation = useDisclosure();

  const form = useForm<ProgressStatementEditItemForm>({
    defaultValues: (() => {
      const lineComments = externalProgressStatement.lines.reduce<Partial<ProgressStatementEditItemForm>>(
        (acc, line) => {
          acc[getItemCommentFieldName(line.item.id)] = line.externalComment;
          acc[getItemCumulativeFieldName(line.item.id, CUMULATIVE_INPUT_TYPE.AMOUNT)] = mapNumberToAmount(
            line.cumulativeAmountExVAT,
            externalProgressStatement.currency.precision,
          );
          return acc;
        },
        {},
      );

      return {
        globalComment: externalProgressStatement.progressStatement.externalComment || '',
        cumulativeInputType: CUMULATIVE_INPUT_TYPE.AMOUNT,
        ...lineComments,
      };
    })(),
  });

  // Max depth in all contracts' tree
  const maxRelativeDepthInLot = (lot: ILotResponseDTO): number =>
    1 + Math.max(0, ...lot.subLots.map(maxRelativeDepthInLot));
  const maxDepth = Math.max(
    0,
    ...externalProgressStatement.contracts.map((contract) =>
      Math.max(0, ...contract.container.lots.map(maxRelativeDepthInLot)),
    ),
  );

  const deepTable = useDeepTable({
    templateColumns: '6.5rem minmax(13rem,1fr) 7rem 7rem 7rem 7rem 11rem 12.5rem 9rem',
    leftContentWidth: 0.5 * maxDepth + 3,
  });

  const rejectExternalProgressStatementMutation = useRejectExternalProgressStatement();
  const onSubmit = useCallback(
    async (validatorForm: { name: string; email: string }) => {
      const formValues = form.getFormValues();

      await rejectExternalProgressStatementMutation.mutateAsync({
        externalId,
        dto: {
          ...validatorForm,
          comment: formValues.globalComment || '',
          lines: externalProgressStatement.lines.map((line) => ({
            itemId: line.item.id,
            comment: formValues[getItemCommentFieldName(line.item.id)] || '',
          })),
        },
      });
    },
    [externalId, externalProgressStatement.lines, form, rejectExternalProgressStatementMutation],
  );

  return (
    <Form form={form} style={{ flex: 1 }}>
      <Flex direction="column" w="100%">
        <Flex flex={1} overflow="auto" direction="column" p={4} gap={4}>
          <Box>
            <TextAreaField<ProgressStatementEditItemForm>
              name="globalComment"
              placeholder={t('progressStatement:external.rejectionForm.globalComment')}
            />
          </Box>
          <Box overflowY="auto" flex={1}>
            <ProgressStatementProvider
              contracts={externalProgressStatement.contracts}
              previousProgressStatement={externalProgressStatement.previousProgressStatement}
            >
              <DeepTable deepTable={deepTable} noCard gridProps={{ gap: 0 }}>
                <PublicTree
                  externalProgressStatement={externalProgressStatement}
                  externalId={externalId}
                  onGoToApprove={onGoToApprove}
                />
              </DeepTable>
            </ProgressStatementProvider>
          </Box>
        </Flex>

        <ProgressStatementExternalFooter>
          <Wrap gap={4} justify="center">
            <Button onClick={onGoToApprove} variant="outline">
              {t('global:words.c.cancel')}
            </Button>

            <Button leftIcon={<CloseIcon />} onClick={modalValidation.onOpen} colorScheme="red">
              {t('progressStatement:external.actions.reject')}
            </Button>
          </Wrap>
        </ProgressStatementExternalFooter>

        <ProgressStatementExternalValidationModal
          isOpen={modalValidation.isOpen}
          onClose={modalValidation.onClose}
          onSubmit={onSubmit}
          isApproval={false}
        />
      </Flex>
    </Form>
  );
};
