import { createContext } from 'react';
import type { SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';

export interface SupplierInvoiceEditContextType {
  /**
   * The supplier invoice to edit
   * If null, it means that we are creating a new supplier invoice
   */
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT | null;

  onOCR: (amountExVAT: number | null) => void;
  hasOCRBeenDone: boolean;
  amountExVATFromOCR: number | null;
}

export const SupplierInvoiceEditContext = createContext<SupplierInvoiceEditContextType>({
  supplierInvoice: null,
  hasOCRBeenDone: false,
  onOCR: () => {},
  amountExVATFromOCR: null,
});
