import type { ReactElement, ReactNode } from 'react';
import { useMemo } from 'react';
import type { StyleProps } from '@chakra-ui/react';
import { Flex, IconButton } from '@chakra-ui/react';

import type { BadgeProps } from '../../Badge/Badge';
import { Badge } from '../../Badge/Badge';
import { SimpleCloseIcon } from '../../Icons';

export interface BadgeFieldOptionProps extends Omit<BadgeProps, 'children'> {
  label?: ReactNode;

  color?: string | StyleProps['color'];

  onRemove?(): void;

  isDisabled?: boolean;
}

export const BadgeFieldOption = ({
  label,
  onRemove,
  isDisabled = false,
  ...props
}: BadgeFieldOptionProps): ReactElement => {
  const action = useMemo(
    () =>
      onRemove && (
        <IconButton
          icon={<SimpleCloseIcon boxSize={2} />}
          fontSize={18}
          variant="clear"
          height="auto"
          aria-label="Remove"
          ml={2}
          minW={0}
          cursor={isDisabled ? 'default' : 'pointer'}
          opacity={isDisabled ? 0.5 : 1}
          onClick={
            isDisabled
              ? () => {}
              : (e) => {
                  e.stopPropagation();
                  onRemove();
                }
          }
        />
      ),
    [isDisabled, onRemove],
  );

  return (
    <Flex alignItems="center" background="inherit">
      <Badge {...props} userSelect="none" action={action}>
        {label}
      </Badge>
    </Flex>
  );
};
