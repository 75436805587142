export const LOAD_MORE_CALLBACK_DELAY = 200;

export const INCREMENTAL_QUERY = '_incremental';
export const PAGE_QUERY = '_page';
export const OFFSET_QUERY = '_offset';
export const OFFSET_V1_QUERY = '_offsetV1';
export const OFFSET_V2_QUERY = '_offsetV2';
export const ORDER_QUERY = '_order';
export const DIRECTION_QUERY = '_direction';
export const SIZE_QUERY = '_size';
export const SEARCH_QUERY = '_search';
export const FULL_QUERY = '_full';

export enum SORTING_DIRECTIONS {
  ASC = 'ASC',
  DESC = 'DESC',
}
