import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(alertAnatomy.keys);

export const customSuccess = definePartsStyle({
  container: {
    borderRadius: '0.5rem',
    border: '1px solid rgba(0, 0, 0, 0.13)',
    background: '#FFF',
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 0px 3px -1px rgba(0, 0, 0, 0.04), 0px 27px 30px -15px rgba(0, 0, 0, 0.13)',
  },
  title: {
    color: '#334E68',
    fontWeight: 600,
    fontFamily: 'Lato',
  },
  description: {
    color: '#627D98',
    fontSize: '0.75rem',
    fontFamily: 'Lato',
    fontWeight: 500,
  },
  icon: {
    color: '#47C799',
    alignItems: 'center',
  },
});

export const customError = definePartsStyle({
  container: {
    borderRadius: '0.5rem',
    border: '1px solid #F33725',
    background: '#F33725',
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 0px 3px -1px rgba(0, 0, 0, 0.04), 0px 27px 30px -15px rgba(0, 0, 0, 0.13)',
  },
  title: {
    color: '#FFF',
    fontSize: '0.875rem',
    fontWeight: 600,
    fontFamily: 'Lato',
  },
  description: {
    color: '#FFE6E3',
    fontSize: '0.75rem',
    fontFamily: 'Lato',
    fontWeight: 500,
  },
  icon: {
    color: '#FFF',
    alignItems: 'center',
  },
});

export const customInfo = definePartsStyle({
  container: {
    borderRadius: '0.5rem',
    border: '1px solid rgba(0, 0, 0, 0.13)',
    background: '#FFF',
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 0px 3px -1px rgba(0, 0, 0, 0.04), 0px 27px 30px -15px rgba(0, 0, 0, 0.13)',
  },
  title: {
    color: '#334E68',
    fontWeight: 600,
    fontFamily: 'Lato',
  },
  description: {
    color: '#627D98',
    fontSize: '0.75rem',
    fontFamily: 'Lato',
    fontWeight: 500,
  },
  icon: {
    color: '#334E68',
    alignItems: 'center',
  },
});

export const customWarning = definePartsStyle({
  container: {
    borderRadius: '0.75rem',
    border: '1px solid #FFAF37',
    background: '#FFEFD7',
    boxShadow:
      '0px 4px 6px -2px rgba(13, 18, 28, 0.08), 0px 12px 16px -4px rgba(13, 18, 28, 0.14), 0px 0px 0px 2px #FFF inset',
  },
  title: {
    color: '#FFAF37',
    fontSize: '0.813rem',
    fontFamily: 'TWKLausanne700',
  },
  description: {
    color: '#FFAF37',
    fontSize: '0.813rem',
    fontFamily: 'TWKLausanne400',
  },
  icon: {
    color: '#FFAF37',
    alignItems: 'center',
  },
});
