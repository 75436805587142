import type { QuoteNodeCluster, QuoteNodeObject } from '@org/quotation-lib';
import { QuoteNodeImportCluster } from '@org/quotation-lib';

import type { QuoteNodesCreateWsMessage } from '../../quote-common/interfaces/quoteWsMessage';

import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import {
  quoteClientRequestsSetToStore,
  StatusEnum,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';

const compareAutomaticIndex = (automaticIndex1: string, automaticIndex2: string) => {
  const v1Components = automaticIndex1.split('.').map(Number);
  const v2Components = automaticIndex2.split('.').map(Number);
  const maxLength = Math.max(v1Components.length, v2Components.length);

  for (let i = 0; i < maxLength; i += 1) {
    const v1 = v1Components[i] || 0;
    const v2 = v2Components[i] || 0;
    if (v1 !== v2) return v1 < v2 ? automaticIndex1 : automaticIndex2;
  }
  return automaticIndex1.length < automaticIndex2.length ? automaticIndex1 : automaticIndex2;
};

// TODO v2: to move into the quotation-lib
const getSmallestAutomaticIndex = (nodeToCopy: QuoteNodeObject[]) => {
  const automaticIndexes = nodeToCopy.map((node) => node.content.automaticIndex);
  return automaticIndexes.reduce(
    (acc: string, cur) => (cur ? compareAutomaticIndex(acc, cur) : acc),
    automaticIndexes[0] || '',
  );
};

export function createQuoteNodesDuplicateCallback(message: QuoteNodesCreateWsMessage) {
  const quote = quoteGetFromStore();

  if (!quote) {
    return;
  }

  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();
  const hasClientRequest = quoteClientRequestsFromStore.find(
    (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
  );

  if (hasClientRequest === undefined) {
    const smallIndex = getSmallestAutomaticIndex(message.data);
    const nodeObject = message.data.find((node) => node.content.automaticIndex === smallIndex);

    if (!nodeObject || !nodeObject.prevSiblingId) {
      return;
    }

    const prevSibling = quote.getTree().getNodeOrThrow(nodeObject.prevSiblingId);

    const parentId = prevSibling.getParentOrThrow().getId();
    const prevSiblingId = prevSibling.getId();
    const nextSiblingId = prevSibling.getNextSibling()?.getId() || null;

    if (!parentId) {
      throw new Error('Cannot duplicate root node');
    }

    const cluster: QuoteNodeCluster = {
      nodeObject,
      childrenObject: message.data.filter((node) => node.content.automaticIndex !== smallIndex),
    };

    QuoteNodeImportCluster.execute(quote, cluster, parentId, prevSiblingId, nextSiblingId);

    quoteSetToStore(quote);
  } else {
    quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, hasClientRequest, message.nbEventPusher);
  }
}
