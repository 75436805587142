import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { t } from 'i18next';

import type { BillActionsType } from './bill.actions.type';

export function getBillLostActions(params: BillActionsType): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  const isDisabled = !params.hasPayBillsPermission;

  actions.push({
    type: 'secondary',
    label: t('bill:detail.makeAPayment'),
    onClick: params.onPay,
    isDisabled,
    tooltip: isDisabled ? t('global:errors.disabledAction') : undefined,
  });

  return actions;
}
