import type { IQuoteInfosResponse, IQuoteLot, ValueOf } from '@graneet/business-logic';
import { getJobWorkForceAmount } from '@graneet/business-logic';
import type { IInitialTree } from '@graneet/lib-ui';

import type { TreeExportQuoteJob } from '../hooks/useQuoteExportTree';
import type { EXPORT_COLUMNS } from '../constants/quote-export.constant';
import { DISPLAYED_EXPORT_COLUMNS } from '../constants/quote-export.constant';

export const mapQuoteInfosToInitialTree = (
  quoteInfos: IQuoteInfosResponse,
): IInitialTree<IQuoteLot, TreeExportQuoteJob> => {
  const nodes: IInitialTree<IQuoteLot, TreeExportQuoteJob>['nodes'] = {};
  Object.values(quoteInfos.lots).forEach((lot) => {
    nodes[lot.id] = { ...lot, parentNodeId: lot.parentLot?.id || null };
  });

  const leaves: IInitialTree<IQuoteLot, TreeExportQuoteJob>['leaves'] = {};
  Object.values(quoteInfos.jobs).forEach((job) => {
    const workForceQuantity = getJobWorkForceAmount(job.components, job.quantity);
    leaves[job.id] = { ...job, parentNodeId: job.lot.id, isSelected: true, workForceQuantity };
  });

  const relations: IInitialTree<IQuoteLot, TreeExportQuoteJob>['relations'] = {};
  Object.entries(quoteInfos.relations).forEach(([key, value]) => {
    relations[key as any] = {
      leaves: value.jobs,
      nodes: value.lots,
    };
  });

  return {
    rootNodeId: quoteInfos.rootLotId,
    nodes,
    leaves,
    relations,
  };
};

export const countSelectedColumns = (
  selectedItems: Record<ValueOf<typeof EXPORT_COLUMNS>, boolean | undefined> | undefined,
) => {
  if (!selectedItems) {
    return 1; // 1 is for the checkbox column
  }
  const nbSelectedColumns = (Object.keys(selectedItems) as ValueOf<typeof DISPLAYED_EXPORT_COLUMNS>[]).filter(
    (key) => !!selectedItems[key] && (Object.values(DISPLAYED_EXPORT_COLUMNS) as any[]).includes(key),
  ).length;

  return nbSelectedColumns + 1; // + 1 is description column
};
