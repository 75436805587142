export type DocumentStatusCardIconValue =
  | 'draft'
  | 'proforma'
  | 'finalized'
  | 'completed'
  | 'cancelled'
  | 'importing'
  | 'onHold'
  | 'partial';

export function mapIconToColor(icon: DocumentStatusCardIconValue) {
  switch (icon) {
    case 'draft':
    case 'proforma':
      return 'gray';
    case 'finalized':
      return 'blue';
    case 'completed':
      return 'green';
    case 'importing':
      return 'purple';
    case 'partial':
      return 'yellow';
    case 'cancelled':
      return 'red';
    case 'onHold':
      return 'orange';
    default:
      icon satisfies never;
      throw new Error('unknown icon');
  }
}
