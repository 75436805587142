import type { Action } from '@graneet/lib-ui';
import { SimpleDeleteIcon, SimpleDownloadIcon, SimpleEditIcon, SimpleEmailIcon, SimpleViewIcon } from '@graneet/lib-ui';
import { t } from 'i18next';
import {
  PROGRESS_STATEMENT_DELETION_ERROR,
  PROGRESS_STATEMENT_STATUSES,
  SUB_PROJECT_BILLING_TYPE,
  getProgressStatementDeletionCheckError,
  isProjectActive,
} from '@graneet/business-logic';

import type { DirectProgressStatementActionsParams } from './direct-progress-statement.actions.type';

import { getPDFActionProps } from 'features/common/services/common.actions.util';

export function getCompleteAction({
  isCompleteButtonLoading,
  hasUpdatePermission,
  canCompleteDirectProgressStatement,
  onSetStatusToCompleted,
}: DirectProgressStatementActionsParams): Action {
  const canComplete = canCompleteDirectProgressStatement();

  const tooltip = (() => {
    if (!hasUpdatePermission) {
      return t('global:errors.disabledAction');
    }
    if (!canComplete) {
      return t('directProgressStatement:workflow.completing.projectNotOngoingOrDownPaymentNotComplete');
    }
    return undefined;
  })();

  return {
    label: t('directProgressStatement:actions.complete'),
    tooltip,
    isDisabled: !canComplete || !hasUpdatePermission,
    isLoading: isCompleteButtonLoading,
    onClick: onSetStatusToCompleted,
  };
}

export function getEditAction({
  progressStatement,
  onEdit,
  hasCreatePermission,
  hasUpdatePermission,
}: DirectProgressStatementActionsParams): Action {
  const hasPermission =
    progressStatement.status === PROGRESS_STATEMENT_STATUSES.DRAFT ? hasCreatePermission : hasUpdatePermission;

  return {
    icon: <SimpleEditIcon />,
    label: t('directProgressStatement:actions.modify'),
    tooltip: !hasPermission ? t('global:errors.disabledAction') : undefined,
    isDisabled: !hasPermission,
    onClick: onEdit,
  };
}

export function getPreviewPDFAction({ onViewPdf, pdf }: DirectProgressStatementActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('global:pdf.viewPDF'),
    icon: <SimpleViewIcon />,
    ...getPDFActionProps(pdf),
    onClick: onViewPdf,
  };
}

export function getDownloadPDFAction({ onDownloadPdf, pdf }: DirectProgressStatementActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('global:pdf.downloadPDF'),
    icon: <SimpleDownloadIcon />,
    ...getPDFActionProps(pdf),
    onClick: onDownloadPdf,
  };
}

export function getOpenMailingAction({ onOpenMailing, pdf }: DirectProgressStatementActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('mailing:modal.send'),
    icon: <SimpleEmailIcon />,
    ...getPDFActionProps(pdf),
    onClick: onOpenMailing,
  };
}

export function getCancelAction(
  {
    progressStatement,
    hasForceDraftPermission,
    hasUpdatePermission,
    onCancelWithCredit,
    onCancelWithoutCredit,
  }: DirectProgressStatementActionsParams,
  withCredit: boolean,
): Action {
  const missingPermission = !hasUpdatePermission || (!withCredit && !hasForceDraftPermission);

  const tooltip = (() => {
    if (missingPermission) {
      return t('global:errors.disabledAction');
    }
    if (progressStatement.hasPaymentAccountingExported) {
      return t('directProgressStatement:workflow.drafting.impossibleToDraftBecauseAccountingExport');
    }
    if (progressStatement.project?.status === 'ENDED') {
      return t('progressStatement:tooltips.impossibleToDraftBecauseProjectIsClosed');
    }
    return undefined;
  })();

  return {
    label: withCredit ? t('credit:actions.create') : t('directProgressStatement:actions.forceDraft'),
    tooltip,
    onClick: withCredit ? onCancelWithCredit : onCancelWithoutCredit,
    isDisabled:
      missingPermission ||
      progressStatement.hasPaymentAccountingExported ||
      !isProjectActive(progressStatement.project!),
    icon: <SimpleEditIcon />,
  };
}

export function getDeleteAction({
  progressStatement,
  onDelete,
  hasCreatePermission,
}: DirectProgressStatementActionsParams): Action | undefined {
  const error = getProgressStatementDeletionCheckError(progressStatement, SUB_PROJECT_BILLING_TYPE.DIRECT, true, false);

  const tooltip = (() => {
    if (!hasCreatePermission) {
      return t('global:errors.disabledAction');
    }
    if (error === PROGRESS_STATEMENT_DELETION_ERROR.INVOICE_NUMBER_IS_SET) {
      return t('statement:delete.errors.invoiceNumberSet');
    }
    return undefined;
  })();

  const isDisabled = !hasCreatePermission || error !== null;

  return {
    label: t('global:words.c.delete'),
    icon: <SimpleDeleteIcon />,
    warning: true,
    tooltip,
    isDisabled,
    onClick: onDelete,
  };
}
