import type { UseDisclosureReturn } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';

import type { DrawersStackContextApi } from './useDrawersStack';
import { useDrawersStackContext } from './useDrawersStack';

export interface DrawerApi<Handle extends string> extends UseDisclosureReturn {
  handle: Handle;
}

// Public
export const useDrawer = <Handle extends string>(
  handle: Handle,
  offsetDrawers?: DrawersStackContextApi<Handle>,
  options?: {
    defaultIsOpen?: boolean;
    onClose?: () => void;
    isOpen?: boolean;
  },
): DrawerApi<Handle> => {
  const offsetDrawersContext = useDrawersStackContext();

  if (!offsetDrawersContext && !offsetDrawers) {
    throw new Error('Cannot call useDrawer if not embedded into OffsetDrawersContext');
  }

  const { pushDrawer, popDrawer } = offsetDrawers ?? offsetDrawersContext;

  const disclosure = useDisclosure({
    onOpen: () => pushDrawer(handle),
    onClose: () => {
      popDrawer(handle);
      options?.onClose?.();
    },
    isOpen: options?.isOpen,
    defaultIsOpen: options?.defaultIsOpen,
  });

  return { ...disclosure, handle };
};
