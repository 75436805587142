import type { FC } from 'react';
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';
import { Spinner } from '@chakra-ui/react';

import type { FileWithStatus } from './FileList';
import { fileListTranslations } from './configureDefaultLabel';

interface FileListIconProps {
  status: FileWithStatus['status'];
}
export const FileListIcon: FC<FileListIconProps> = ({ status }) => {
  if (status === 'toUpload') {
    return null;
  }
  if (status === 'uploading' || status === 'awaiting') {
    return <Spinner />;
  }
  if (status === 'uploaded') {
    return <CheckIcon color="green.500" />;
  }
  if (status === 'error') {
    return <WarningIcon color="red.500" />;
  }
  if (status === 'tooBig') {
    return fileListTranslations.tooBig;
  }

  status satisfies never;
  return null;
};
