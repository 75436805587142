import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';

import { usePlaceholderContext } from './PlaceholderContext';

export interface PlaceholderInitializeProps {
  name: string;
}

export const PlaceholderInject: FC<PropsWithChildren<PlaceholderInitializeProps>> = ({ children, name }) => {
  const { updatePlaceholder, resetPlaceholder } = usePlaceholderContext();

  useEffect(() => {
    updatePlaceholder(name, children);
    return () => resetPlaceholder(name);
  }, [children, name, resetPlaceholder, updatePlaceholder]);

  return null;
};
