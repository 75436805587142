import type { DocumentStatusCardAction } from '@graneet/lib-ui';

import type { SupplierInvoiceActionsType } from './supplier-invoice.actions.type';
import {
  getSupplierInvoiceAddPaymentActions,
  getSupplierInvoiceMenuAction,
  getSupplierInvoiceUpdatePaymentAction,
} from './supplier-invoice.actions.util';

export function getSupplierInvoicePartiallyPaidActions(params: SupplierInvoiceActionsType): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  actions.push(...getSupplierInvoiceAddPaymentActions(params));

  actions.push(getSupplierInvoiceUpdatePaymentAction(params));

  actions.push(getSupplierInvoiceMenuAction(params));

  return actions;
}
