import type { CustomTooltipProps } from '@ag-grid-community/react';
import { Text, VStack } from '@chakra-ui/react';
import type { FC } from 'react';

export const CustomAgGridHeaderTooltip: FC<CustomTooltipProps & { content?: string }> = ({ value, content }) => (
  <VStack
    bg="primaryLight"
    color="white"
    borderRadius="0.625rem"
    boxShadow="0px 0px 13.54px 0px rgba(0, 0, 0, 0.20)"
    px="1rem"
    py="0.625rem"
    maxWidth="10rem"
    gap={2}
  >
    <Text textAlign="center" fontSize="sm" fontWeight="bold">
      {value}
    </Text>
    {content && (
      <Text textAlign="center" fontSize="sm">
        {content}
      </Text>
    )}
  </VStack>
);
