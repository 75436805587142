import type {
  GeneratorConfigObject,
  GeneratorData,
  GeneratorInterface,
  GeneratorStateObject,
  ILedger,
} from '../ledger.type';
import { LEDGER_GENERATORS } from '../ledger.type';

import { GeneratorAttribute } from './attribute.generator';
import { GeneratorCounter } from './counter.generator';
import { GeneratorDate } from './date.generator';
import { GeneratorStatic } from './static.generator';

const GENERATOR_CLASSES = {
  [LEDGER_GENERATORS.DATE]: GeneratorDate,
  [LEDGER_GENERATORS.ATTRIBUTE]: GeneratorAttribute,
  [LEDGER_GENERATORS.COUNTER]: GeneratorCounter,
  [LEDGER_GENERATORS.STATIC]: GeneratorStatic,
};

const GENERATOR_BLOCK_REGEX = /{{([^}}]+)}}/g;

function buildGenerator<T extends LEDGER_GENERATORS>(
  type: T,
  config: ConstructorParameters<(typeof GENERATOR_CLASSES)[T]>[0],
): GeneratorInterface {
  // I am very sad about this `any` but both the config parameter and return type are typesafe.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new GENERATOR_CLASSES[type](config as any);
}

function checkConfig(type: LEDGER_GENERATORS, ...args: [GeneratorConfigObject, string]): boolean {
  return GENERATOR_CLASSES[type].isConfigValid(...args);
}

export const parseConfigAndGetGenerators = (config: GeneratorConfigObject): { [block: string]: GeneratorInterface } => {
  const keys = Object.keys(config);
  return keys.reduce((acc, block) => {
    const { type } = config[block];
    return {
      ...acc,
      [block]: buildGenerator(type, config[block]),
    };
  }, {});
};

export const parseFormatAndGetBlocks = (format: string): string[] => {
  const formatWithoutBlocks = format.replace(GENERATOR_BLOCK_REGEX, '');
  if (formatWithoutBlocks.match(/({{|}})/)) {
    throw new Error('Invalid format : each block must start and end with {{ .. }}');
  }

  const matches = [...format.matchAll(GENERATOR_BLOCK_REGEX)];
  return matches.map((match) => {
    const [, block] = match;
    if (!block.match(/^\w+$/)) {
      throw new Error('Invalid format : block name must be alphanumeric');
    }
    return block;
  });
};

export const validateConfig = (config: GeneratorConfigObject, blocks: string[]): void => {
  const keys = Object.keys(config);
  const keysInBlocks = keys.reduce((acc, key) => acc && blocks.includes(key), true);
  const blocksInKeys = blocks.reduce((acc, block) => acc && keys.includes(block), true);
  if (!keysInBlocks || !blocksInKeys) {
    throw new Error('Each block in format must have a config, and each config must be specified in format');
  }

  blocks.forEach((block) => {
    const { type } = config[block];
    if (!Object.values(LEDGER_GENERATORS).includes(type)) {
      throw new Error(`Invalid type for block "${block}"`);
    }

    if (!checkConfig(type, config, block)) {
      throw new Error(`Invalid config for block "${block}"`);
    }
  });

  const countCounters = blocks.reduce((acc, block) => {
    const { type } = config[block];
    if (type === LEDGER_GENERATORS.COUNTER) {
      return 1 + acc;
    }
    return acc;
  }, 0);
  if (countCounters !== 1) {
    throw new Error('Ledger must contain one counter and only');
  }
};

export const validateState = (
  state: GeneratorStateObject,
  oldState: GeneratorStateObject,
  generators: { [block: string]: GeneratorInterface },
): void => {
  const blocks = Object.keys(generators);
  blocks.forEach((block) => {
    const generator = generators[block];
    if (!generator.isStateValid(state[block], oldState[block])) {
      throw new Error(`Invalid state for block "${block}"`);
    }
  });
};

export const generateValuesAndState = (
  ledger: Pick<ILedger, 'config' | 'format' | 'state'>,
  data: GeneratorData,
  lastCounter?: number,
): {
  blockValues: { [block: string]: string };
  nextStateWithCounter: GeneratorStateObject;
  rawCounterValue: number | null;
  formattedNumber: string;
} => {
  const { format, config, state } = ledger;

  // First step : check args validity
  const blocks = parseFormatAndGetBlocks(format);
  const generators = parseConfigAndGetGenerators(config);

  validateConfig(config, blocks);
  validateState(state, state, generators);

  // Second step : generate values for all blocks except counter
  const counterBlockName = blocks.find((block) => config[block].type === LEDGER_GENERATORS.COUNTER);

  if (counterBlockName === undefined) {
    throw new Error(`Counter block not found in ledger`);
  }

  const otherBlocksName = blocks.filter((block) => block !== counterBlockName);

  // Currently, we do not store a state for generators that are not counters, but we want to leave this door open
  const [blockValues, nextState] = otherBlocksName.reduce<[GeneratorStateObject, GeneratorStateObject]>(
    ([blockValuesAccumulator, stateObject], blockName) => {
      const intermediateState = stateObject;
      const prevBlockState = intermediateState[blockName];

      // We generate the formatted value and state corresponding to the block
      const [blockValue, nextBlockState] = generators[blockName].generateValue(data, prevBlockState);

      // We store the next block state only if it is defined
      if (nextBlockState) {
        intermediateState[blockName] = nextBlockState;
      }
      return [{ ...blockValuesAccumulator, [blockName]: blockValue }, intermediateState];
    },
    [{}, state],
  );

  // Third step : generate counter value and update state
  const prevCounterState = nextState[counterBlockName];

  const [formattedCounterValue, rawCounterValue, nextCounterState] = generators[counterBlockName].generateValue(
    data,
    prevCounterState,
    blockValues,
    lastCounter,
  );

  const nextStateWithCounter = { ...nextState, [counterBlockName]: nextCounterState };
  blockValues[counterBlockName] = formattedCounterValue;

  const formattedNumber = ledger.format.replace(/{{([^}}]+)}}/g, (_, block) => blockValues[block]);

  return { blockValues, nextStateWithCounter, rawCounterValue, formattedNumber };
};
