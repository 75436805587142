import type { ReactElement } from 'react';
import { useEffect, useId } from 'react';

import type { SegmentProps } from './contexts/SegmentContext';
import { useSegmentContext } from './contexts/SegmentContext';

export const Segment = <Item extends Record<any, unknown>>(props: SegmentProps<Item>): ReactElement | null => {
  const { addSegment, removeSegment } = useSegmentContext();
  const id = useId();

  useEffect(() => {
    addSegment(id, props);
    return () => removeSegment(id);
  }, [addSegment, id, props, removeSegment]);

  return null;
};
