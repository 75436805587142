import type { QuoteSubItemPopulateDTO, QuoteSubItemUpsertDTO, QuoteSubItemsApi } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteSubItemsProxyApi {
  private static quoteSubItemsProxyApi: QuoteSubItemsProxyApi;

  private readonly quoteSubItemsApi: QuoteSubItemsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteSubItemsApi = externalApi.getQuoteSubItemsApi();
  }

  public static getQuoteSubItemsProxyApi(externalApi: ExternalApi): QuoteSubItemsProxyApi {
    if (!this.quoteSubItemsProxyApi) {
      QuoteSubItemsProxyApi.quoteSubItemsProxyApi = new QuoteSubItemsProxyApi(externalApi);
    }
    return QuoteSubItemsProxyApi.quoteSubItemsProxyApi;
  }

  public async createQuoteSubItem(quoteSubItemCreateDTO: QuoteSubItemUpsertDTO) {
    return proxyHelper(async () => this.quoteSubItemsApi.createQuoteSubItem(quoteSubItemCreateDTO));
  }

  public async updateQuoteSubItem(quoteSubItemId: string, quoteSubItemUpdateDTO: QuoteSubItemUpsertDTO) {
    return proxyHelper(async () => this.quoteSubItemsApi.updateQuoteSubItem(quoteSubItemId, quoteSubItemUpdateDTO));
  }

  public async populateQuoteSubItemItem(quoteSubItemUpsertManyDTO: QuoteSubItemPopulateDTO) {
    return proxyHelper(async () => this.quoteSubItemsApi.populateQuoteSubItem(quoteSubItemUpsertManyDTO));
  }
}
