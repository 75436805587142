export declare enum VatMethod {
  Bases = 'BASES',
  BasesRounded = 'BASES_ROUNDED',
}

export enum QuoteStatus {
  Draft = 'DRAFT',
  Importing = 'IMPORTING',
  Completed = 'COMPLETED',
  Accepted = 'ACCEPTED',
  Refused = 'REFUSED',
  Lost = 'LOST',
  Archived = 'ARCHIVED',
}

export interface IQuotationContact {
  id: string;
  isDefaultEmailRecipient: boolean;
}

export interface QuoteComposeDTOAddress {
  /**
   * Address
   * @type {string}
   * @memberof QuoteComposeDTOAddress
   */
  address: string | null;
  /**
   * Postal code
   * @type {string}
   * @memberof QuoteComposeDTOAddress
   */
  postalCode: string | null;
  /**
   * City
   * @type {string}
   * @memberof QuoteComposeDTOAddress
   */
  city: string | null;
  /**
   * Country
   * @type {string}
   * @memberof QuoteComposeDTOAddress
   */
  country: string | null;
}

export interface QuoteVatBaseDTO {
  /**
   * The base amount before applying any discount
   * @type {string}
   * @memberof QuoteVatBaseDTO
   */
  base: string;
  /**
   * The total amount including VAT
   * @type {string}
   * @memberof QuoteVatBaseDTO
   */
  amount: string;
  /**
   * The discounted base amount after applying discount
   * @type {string}
   * @memberof QuoteVatBaseDTO
   */
  baseWithDiscounts: string;
  /**
   * The VAT rate applied to the transaction
   * @type {string}
   * @memberof QuoteVatBaseDTO
   */
  vatRate: string;
}

export interface IQuotation {
  /**
   * Unique identifier of the quote
   * @type {string}
   * @memberof IQuotation
   */
  id: string;
  /**
   * Unique identifier of the root node
   * @type {string}
   * @memberof IQuotation
   */
  rootNodeId: string;
  /**
   * Unique identifier of the hidden cost node
   * @type {string}
   * @memberof IQuotation
   */
  hiddenCostNodeId: string;
  /**
   * Name of the quote
   * @type {string}
   * @memberof IQuotation
   */
  name: string;
  /**
   * Group index of the quote
   * @type {number}
   * @memberof IQuotation
   */
  groupIndex: number;
  /**
   *
   * @type {QuoteStatus}
   * @memberof IQuotation
   */
  status: QuoteStatus;

  numberedAmountExVAT?: number | null;
  numberedAmountIncVAT?: number | null;

  margin?: {
    overheadCosts: string;
    profitMargin: string;
    totalMargin: string;
  };
  /**
   * Quote tags
   * @type {Array<string>}
   * @memberof IQuotation
   */
  tags?: Array<string> | null;
  /**
   * Whether display optional lines pdf is enabled
   * @type {boolean}
   * @memberof IQuotation
   */
  displayOptionalLinesPDF: boolean;
  /**
   * Whether display optional annex pdf is enabled
   * @type {boolean}
   * @memberof IQuotation
   */
  displayOptionalAnnexPDF: boolean;
  /**
   * Whether automatic index activation is enabled
   * @type {boolean}
   * @memberof IQuotation
   */
  hasReversalOfLiability: boolean;
  /**
   * Whether automatic index activation is enabled
   * @type {boolean}
   * @memberof IQuotation
   */
  isAutomaticIndexActivated: boolean;
  /**
   *
   * @type {VatMethod}
   * @memberof IQuotation
   */
  vatMethod: VatMethod;
  /**
   * ID of the company
   * @type {number}
   * @memberof IQuotation
   */
  companyId: number;
  /**
   * Unique identifiers of the account managers
   * @type {Array<number>}
   * @memberof IQuotation
   */
  accountManagers: Array<number> | null;
  /**
   * Unique identifiers of the contacts
   * @type {Array<string>}
   * @memberof IQuotation
   */
  contacts: Array<IQuotationContact> | null;
  /**
   *
   * @type {QuoteComposeDTOAddress}
   * @memberof IQuotation
   */
  address: QuoteComposeDTOAddress | null;
  /**
   * List of vat distribution
   * @type {Array<QuoteVatBaseDTO>}
   * @memberof IQuotation
   */
  vatBases: Array<QuoteVatBaseDTO> | null;
  /**
   * Unique identifier of the pdf
   * @type {number}
   * @memberof IQuotation
   */
  pdfId: number | null;
  /**
   * Reference code of the quote
   * @type {string}
   * @memberof IQuotation
   */
  refCode: string | null;
  /**
   * Estimated work duration in days
   * @type {number}
   * @memberof IQuotation
   */
  workDuration: number | null;
  /**
   * Validity duration of the quote in days
   * @type {number}
   * @memberof IQuotation
   */
  validityDuration: number | null;
  /**
   * Additional comment
   * @type {string}
   * @memberof IQuotation
   */
  comment: string | null;
  /**
   * Special mentions on the quote
   * @type {string}
   * @memberof IQuotation
   */
  mentions: string | null;
  /**
   * Additional note on the quote
   * @type {string}
   * @memberof IQuotation
   */
  note: string | null;
  /**
   * Amount excluding VAT
   * @type {string}
   * @memberof IQuotation
   */
  amountExVAT: string | null;
  /**
   * Amount including VAT
   * @type {string}
   * @memberof IQuotation
   */
  amountIncVAT: string | null;
  /**
   * Date when the quote was created
   * @type {string}
   * @memberof IQuotation
   */
  createdAt: string;
  /**
   * Date when the quote was updated
   * @type {string}
   * @memberof IQuotation
   */
  updatedAt: string;
  /**
   * Date when the quote was added
   * @type {string}
   * @memberof IQuotation
   */
  addedAt: string | null;
  /**
   * Date when the quote was settled
   * @type {string}
   * @memberof IQuotation
   */
  settledAt: string | null;
  /**
   * Date when the project is estimated to start
   * @type {string}
   * @memberof IQuotation
   */
  startedAt: string | null;
  /**
   * The project id identifier
   * @type {number}
   * @memberof IQuotation
   */
  projectId: number | null;
  /**
   * The client id identifier
   * @type {number}
   * @memberof IQuotation
   */
  clientId: number | null;
}

export enum QUOTE_ITEM_TYPE_OF_PRICING {
  FLAT_RATE = 'FLAT_RATE',
  UNIT = 'UNIT',
}
