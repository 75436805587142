/**
 * Convert any DTO to a FormData object
 * Should be used to convert DTO to FormData for multipart/form-data requests
 */
export const dtoToFormData = <T extends object>(dto: T): FormData => {
  const formData = new FormData();
  Object.entries(dto).forEach(([key, value]) => {
    if (value !== undefined) {
      // Because of multipart/form-data, we should not add empty (undefined) values else "undefined" string is stored
      formData.set(key, typeof value === 'object' && !(value instanceof File) ? JSON.stringify(value) : value);
    }
  });
  return formData;
};

export function createURLSearchParams(params: Record<string, string | number | boolean>): URLSearchParams {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    searchParams.set(key, String(params[key]));
  });
  return searchParams;
}
