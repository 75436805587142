import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';

import { EMPTY_SIGN } from './format.util';

dayjs.extend(localizedFormat);
dayjs.extend(localeData);

export const STANDARD_DATE_FORMAT = 'L';
export const DATE_FORMAT_API = 'YYYY-MM-DD';

/**
 * Return date format for the user location
 * @example MM/DD/YYYY
 */
export const getLocalDateFormat = (format = STANDARD_DATE_FORMAT) => dayjs().localeData().longDateFormat(format);

export const isDate = (date: dayjs.ConfigType) => {
  const dateFormatted = dayjs(date, DATE_FORMAT_API);
  return dateFormatted.isValid() && dateFormatted.format(DATE_FORMAT_API) === date;
};

export const formatDateFieldValue = (value: dayjs.ConfigType) =>
  // If value is null, empty string of undefined it returns null
  value != null && value !== '' ? value : null;

export const formatDateToString = (date: dayjs.ConfigType) => (date ? dayjs(date).format(DATE_FORMAT_API) : undefined);

export const getCurrentDateAsString = () => dayjs().format(DATE_FORMAT_API);

export const formatDateOrEmpty = (date: dayjs.ConfigType, format = getLocalDateFormat()) =>
  date ? dayjs(date).format(format) : EMPTY_SIGN;
