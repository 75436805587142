import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Multiplication, useCurrency, HiddenCostDisplay } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { divideFloating } from '@graneet/business-logic';
import { bool, number } from 'prop-types';

import { useJob } from '../../hooks/useJob';

import { useStore } from 'store/store';
import { useComponents } from 'features/quote-component/hooks/useComponents';

export const EditJobMargin = ({ jobId, isReadOnly }) => {
  const { t } = useTranslation(['quote', 'margin']);
  const { mapNumberToAmount } = useCurrency();
  const form = useFormContext();

  const components = useComponents();
  const job = useJob(jobId);

  const hasComponents = components?.length > 0;
  const hasHiddenCosts = useStore((state) => state.hasHiddenCosts);

  const { unitDisbursementExVAT } = useOnChangeValues(form, ['unitDisbursementExVAT']);
  const [hasBeenChanged, setHasBeenChanged] = useState(false);
  const [hasBeenBlurred, setHasBeenBlurred] = useState(false);
  const [computedValueOfUnitPrice, setComputedValueOfUnitPrice] = useState('unitDisbursementExVAT');
  const { mapAmountToNumber } = useCurrency();

  useEffect(() => {
    if (!job) return;

    const mapToAmountOrNull = (amount) => (Number.isFinite(amount) ? mapNumberToAmount(amount) : null);

    form.setFormValues({
      totalMargin: job.margin.totalMargin,
      unitDisbursementExVAT: mapToAmountOrNull(job.unitDisbursementExVAT),
      unitPriceExVAT: mapToAmountOrNull(job.unitPriceExVAT),
      hiddenCostPercent: 0,
    });
  }, [form, job, mapNumberToAmount]);
  /*
    On unitPriceExVAT changes:
    - If no unitDisbursementExVAT, computed field is unitDisbursementExVAT
    - If unitDisbursementExVAT is not null, computed field is totalMargin

    Multiplication is not working with onBlurWatching, so we have to trick.

    If there is no changes done by the user on unitDisbursementExVAT, we know that computed field is
    !unitDisbursementExVAT.
    When user start to enter number, until the field blur, we do not change on computedValueOfUnitPrice.
    After user blur the field, we can change value of computedValueOfUnitPrice
  */
  useEffect(() => {
    if (!hasBeenChanged || hasBeenBlurred) {
      setComputedValueOfUnitPrice(unitDisbursementExVAT ? 'totalMargin' : 'unitDisbursementExVAT');
    }
  }, [hasBeenBlurred, hasBeenChanged, unitDisbursementExVAT]);

  const { formatAsAmount } = useCurrency();

  const handleChange = useCallback(() => {
    setHasBeenChanged(true);
  }, []);

  const handleBlur = useCallback(async () => {
    setHasBeenBlurred(true);
  }, []);

  const isUnitPriceDisabled = job?.unitDisbursementExVAT === 0;

  const amountData = useMemo(
    () => ({ mapValue: (v) => (Number.isFinite(v) ? mapAmountToNumber(v) : null) }),
    [mapAmountToNumber],
  );

  useEffect(() => {
    if (!job) return;

    form.setFormValues({
      hiddenCostPercent: 1 + divideFloating(job.hiddenCostAmountExVAT, job.disbursementExVAT),
    });
  }, [form, job]);

  const hiddenCostAmount = useMemo(() => {
    if (!job) {
      return 0;
    }
    if (job.quantity && job.quantity !== 0) {
      return divideFloating(job.hiddenCostAmountExVAT, job.quantity);
    }
    return 0;
  }, [job]);

  if (!job) {
    return null;
  }

  return (
    <Multiplication
      hiddenCost={
        hasHiddenCosts &&
        !job.isOptional && (
          <HiddenCostDisplay
            hiddenCostAmount={formatAsAmount(hiddenCostAmount)}
            toolTipsLabel={t('quote:hiddenCost.title')}
            fieldLabel={t('quote:hiddenCost.title')}
          />
        )
      }
      hiddenCostAmount={mapNumberToAmount(hiddenCostAmount)}
    >
      <Multiplication.Field
        label={t('quote:fields.dryUnitDisbursementExVAT')}
        computedName="unitPriceExVAT"
        name="unitDisbursementExVAT"
        data={amountData}
        isDisabled={hasComponents || isReadOnly}
        onBlur={handleBlur}
        onChange={handleChange}
        isCurrency
      />

      <Multiplication.Field
        label={t('margin:fields.margin')}
        computedName="unitPriceExVAT"
        name="totalMargin"
        min={0}
        scale={3}
        onBlur={handleBlur}
        isDisabled={isReadOnly}
      />

      <Multiplication.Field
        label={t('quote:fields.unitSellingPriceExVAT')}
        computedName={computedValueOfUnitPrice}
        name="unitPriceExVAT"
        data={amountData}
        onBlur={handleBlur}
        onChange={handleChange}
        isDisabled={isUnitPriceDisabled || isReadOnly}
        isCurrency
      />
    </Multiplication>
  );
};

EditJobMargin.propTypes = {
  jobId: number.isRequired,
  isReadOnly: bool,
};

EditJobMargin.defaultProps = {
  isReadOnly: false,
};
