import { useStore } from 'store/store';

export function quoteCommandHistoryHasBeenResetSetToStore(historyHasBeenReset: boolean) {
  useStore.setState({ historyHasBeenReset });
}

export function quoteCommandHistoryHasBeenResetGetFromStore() {
  const { historyHasBeenReset } = useStore.getState();
  return historyHasBeenReset;
}
