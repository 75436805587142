import { useCallback } from 'react';
import Big from 'big.js';
import { useToast } from '@graneet/lib-ui';
import type { QuoteComponentObject } from '@org/quotation-lib';
import { ContentTypeGuard } from '@org/quotation-lib';
import { useTranslation } from 'react-i18next';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { quoteLibrarySavedSetToStore } from 'features/quotation/quote-common/store/quoteLibrarySavedSetToStore';
import { useStore } from 'store/store';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export const useQuoteLibrarySaveItem = () => {
  const { quoteLibraryProxyApi } = useQuotationProxyApis();
  const { quote } = useQuote();
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);
  const editorRef = useStore((state) => state.quoteRichTextEditorRef);
  const quoteError = useQuoteError();

  return useCallback(
    async (nodeId: string) => {
      try {
        const nodeContent = quote.getTree().getNodeOrThrow(nodeId).getContent();
        if (!ContentTypeGuard.isQuoteItem(nodeContent) && !ContentTypeGuard.isQuoteSubItem(nodeContent)) {
          throw new Error('Invalid child content type');
        }
        const itemExport = nodeContent.export();
        const childrenExport: QuoteComponentObject[] = [];

        nodeContent
          .getNodeOrThrow()
          .getChildren()
          .forEach((child) => {
            const childContent = child.getContent();

            if (!ContentTypeGuard.isQuoteComponent(childContent)) {
              throw new Error('Invalid child content type');
            }

            childrenExport.push(childContent.export());
          });

        const { denomination, unit, unitFlatCostAmount, refCode } = itemExport;

        if (!denomination || !unit || !unitFlatCostAmount) {
          throw new Error('Invalid component data');
        }

        const plainDenomination = editorRef?.current?.getPlainText(denomination) ?? '';

        if (childrenExport.length === 0) {
          await quoteLibraryProxyApi?.createBasicItem({
            refCode,
            description: plainDenomination,
            unit,
            unitFlatCost: Big(unitFlatCostAmount).toNumber(),
          });
        } else {
          await quoteLibraryProxyApi?.createBasicItemWithComponents({
            refCode,
            description: plainDenomination,
            unit,
            unitFlatCost: Big(unitFlatCostAmount).toNumber(),
            components: childrenExport.map((component) => {
              if (
                !component.denomination ||
                !component.unit ||
                !component.unitFlatCostAmount ||
                !component.componentTypeId ||
                !component.quantityFormula?.quantity
              ) {
                throw new Error('Invalid component data');
              }

              const plainComponentDenomination = editorRef?.current?.getPlainText(component.denomination) ?? '';
              return {
                quantity: Big(component.quantityFormula.quantity).toNumber(),
                refCode: component.refCode,
                description: plainComponentDenomination,
                unit: component.unit,
                unitFlatCost: Big(component.unitFlatCostAmount).toNumber(),
                componentTypeId: component.componentTypeId,
              };
            }),
          });
        }
        quoteLibrarySavedSetToStore(true);
        toast.success(t('quote:quotation.library.success'));
      } catch (err: any) {
        return quoteError(err.message);
      }

      return true;
    },
    [editorRef, quote, quoteError, quoteLibraryProxyApi, t, toast],
  );
};
