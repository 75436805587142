import type { FC, ReactElement, ReactNode } from 'react';
import { cloneElement, useMemo } from 'react';
import type { MenuItemProps, TooltipProps } from '@chakra-ui/react';
import { Flex, MenuItem, Text } from '@chakra-ui/react';

import { Tooltip } from '../../Tooltip';

const LABEL_STYLES = { fontWeight: 500, fontSize: 'sm', color: 'gray.800', fontFamily: 'Lato' };
const ICON_STYLES = { stroke: 'gray.600', fontSize: 'sm' };
const WARNING_COLOR = { stroke: 'red.500', color: 'red.500' };

export interface ActionProps extends Omit<MenuItemProps, 'icon'> {
  label: string | ReactNode;

  icon?: ReactElement;

  warning?: boolean;

  onClick?: () => void;

  tooltip?: ReactNode;

  tooltipProps?: Omit<TooltipProps, 'children'>;

  disabled?: boolean;
}

export const Action: FC<ActionProps> = ({ label, icon, warning = false, tooltip, tooltipProps, ...props }) => {
  const iconStyles = useMemo(
    () => ({ ...ICON_STYLES, ...(warning ? WARNING_COLOR : {}), ...icon?.props }),
    [icon?.props, warning],
  );

  const content = useMemo(() => {
    const labelStyles = { ...LABEL_STYLES, ...(warning ? WARNING_COLOR : {}) };
    if (tooltip || tooltipProps) {
      return (
        <Tooltip label={tooltip} {...tooltipProps}>
          <Text {...labelStyles}>{label}</Text>
        </Tooltip>
      );
    }
    return <Text {...labelStyles}>{label}</Text>;
  }, [label, tooltip, tooltipProps, warning]);

  return (
    <MenuItem
      as={Flex}
      _hover={{ background: 'gray.150', cursor: 'pointer' }}
      _groupHover={{ color: 'gray.800', textDecoration: 'none' }}
      _disabled={{ opacity: 0.5, cursor: 'not-allowed' }}
      h="2.2rem"
      {...props}
    >
      {icon && cloneElement(icon, { boxSize: 3, mr: 2, ...iconStyles })}

      {content}
    </MenuItem>
  );
};
