import type { FC, PropsWithChildren } from 'react';
import type { MenuListProps } from '@chakra-ui/react';
import { MenuList } from '@chakra-ui/react';

import { MENU_LIST_PROPS } from '../../constants';

import { useRichSelectContext } from './useRichSelectContext.hook';

export const RichSelectMenuItems: FC<PropsWithChildren & MenuListProps> = ({ children, ...otherProps }) => {
  const { isDisabled, menuSize, hasError } = useRichSelectContext();

  if (isDisabled) {
    return null;
  }

  /*
  Usage of w='auto' combined to a menuSize is important: w='auto' is to ensure that
  the size of the menu is equal to the biggest MenuItem size and the menuSize is
  to ensure the menu is the size of the whole field incl. all selected badges
  */
  return (
    <MenuList
      w="auto"
      {...MENU_LIST_PROPS}
      minW={menuSize || 'auto'}
      maxH="20rem"
      overflow="auto"
      borderColor={hasError ? 'red.500' : 'greenBrand.light'}
      {...otherProps}
      borderRadius="8px"
    >
      {children}
    </MenuList>
  );
};
