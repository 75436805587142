import { useCallback } from 'react';
import type { Quote } from '@org/quotation-lib';
import { QuoteNodeCreateChildUseCase } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { mapQuoteNodeToUpsertComponentDTO } from 'features/quotation/quote-common/mappers/quoteComponentMapper';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { updateQuoteNewlyCreatedNodeId } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteComponentCreate() {
  const { quoteComponentsProxyApi, quoteNodeDeletesProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (
      parentId: string | undefined,
      position?: { prevSiblingId: string | null; nextSiblingId: string | null },
    ): ICommand => {
      if (!parentId) {
        throw new Error('parentId is required');
      }

      return {
        execute(quote: Quote) {
          try {
            if (quote) {
              const quoteNode = quote.getTree().getNodeOrThrow(parentId);

              let prevSiblingId = null;
              let nextSiblingId = null;

              if (position) {
                prevSiblingId = position.prevSiblingId;
                nextSiblingId = position.nextSiblingId;
              } else {
                prevSiblingId = quoteNode.getLastChild()?.getId() ?? null;
              }

              const quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteComponent(
                quoteNode,
                prevSiblingId,
                nextSiblingId,
                this.oldValue && this.oldValue.nodeId ? this.oldValue.nodeId : undefined,
                this.oldValue && this.oldValue.contentId ? this.oldValue.contentId : undefined,
              );
              this.oldValue = {
                nodeId: quoteNodeCreated?.getId(),
                contentId: quoteNodeCreated?.getContent().getId(),
              };
              if (!quoteNodeCreated) {
                throw new Error('Failed to create node');
              }

              // TODO: Handle both root and hidden cost rows
              updateQuoteNewlyCreatedNodeId(quoteNodeCreated.getId());
              quoteSetToStore(quote);

              const clientRequestId = uuid();
              const timestamp = new Date().toISOString();
              quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
              quoteComponentsProxyApi
                .createQuoteComponent(
                  mapQuoteNodeToUpsertComponentDTO(quoteNodeCreated, quote.getId(), clientRequestId, timestamp),
                )
                .then(([err]) => {
                  if (err) {
                    quoteError();
                  }
                })
                .catch((err) => quoteError(err.message));
            }
            return true;
          } catch (e: any) {
            return quoteError(e.message);
          }
        },
        undo(quote: Quote) {
          try {
            if (quote) {
              const quoteComponent = quote.getTree().getQuoteComponentOrThrow(this.oldValue.nodeId);
              quoteComponent.deleteQuoteComponent();
              quoteSetToStore(quote);

              const clientRequestId = uuid();
              const timestamp = new Date().toString();
              quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
              quoteNodeDeletesProxyApi
                .deleteQuoteNode({
                  quoteId: quote.getId(),
                  timestamp,
                  clientRequestId,
                  nodeId: this.oldValue.nodeId,
                })
                .then(([err]) => {
                  if (err) {
                    quoteError();
                  }
                })
                .catch((err) => quoteError(err.message));
            }
            return true;
          } catch (e: any) {
            return quoteError(e.message);
          }
        },
      };
    },
    [quoteComponentsProxyApi, quoteError, quoteNodeDeletesProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
