import type { ILibraryJob } from '../library-job/library-job.type';
import type { IComponentType } from '../component-type/component-type.type';
import type { ICurrency } from '../currency/currency.type';
import type { IFeatureFlag } from '../feature-flag/feature-flag.type';
import type { ILedger } from '../ledger/ledger.type';
import type { ILibraryComponent } from '../library-component/library-component.type';
import type { IInvoice } from '../invoice/invoice.type';
import type { IMargin } from '../margin/margin.type';
import type { IPaymentMethod } from '../payment-method/payment-method.type';
import type { IProject } from '../project/project.type';
import type { IQuote } from '../quote/quote.type';
import type { IFile } from '../file/file.type';
import type { ICompanyLabel } from '../company-label/company-label.type';
import type { IWorker } from '../worker/worker.type';
import type { IPdfSettings } from '../pdf-settings/pdf-settings.type';
import type { ISupplier } from '../supplier/supplier.type';
import type { ISupplierInvoice } from '../supplier-invoice/supplier-invoice.type';
import type { ITimeSlot } from '../time-tracking/time-slot.type';
import type { IOrder } from '../order/order.type';
import type { IThirdPartyConfig } from '../third-party/third-party-config.type';
import type { IAccountingConfig } from '../accounting/accounting-config.type';
import type { IUserInvitation } from '../user-invitation/user-invitation.type';
import type { IContact } from '../contact/contact.type';
import type { IEmailTemplate } from '../email-template/email-template.type';
import type { IReminder } from '../reminder/reminder.type';
import type { IVat } from '../accounting/vat.type';
import type { IAddress } from '../address/address.type';
import type { IClient } from '../client/client.type';
import type { IBill } from '../bill/bill.type';
import type { DEADLINE_TYPE } from '../common/deadline-type.constant';
import type { ThirdParties } from '../third-party/third-party.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IOvertime } from '../overtime/overtime.type';
import type { IUser } from '../user/user.type';
import type { IValidationStep } from '../validation-step/validation-step.type';
import type { IAccountingConfigPurchasesAccount } from '../accounting/accounting-config-purchases-account.entity';
import type { IAccountingConfigSalesAccount } from '../accounting/accounting-config-sales-account.type';
import type { IBankingPaymentResource } from '../banking/banking-payment-ressource.type';

export type PlanSubscription = 'month' | 'year';

export enum PRICING_PLAN {
  ESSENTIAL = 'essential',
  ADVANCED = 'advanced',
  EXPERT = 'expert',
  PREMIUM = 'premium',
}

export enum COMPANY_TT_APPROVAL_MODE {
  AUTO = 'auto',
  MANUAL = 'manual',
}

export enum KYC_SCHEME_NAME {
  SIREN = 'siren',
  INCORPORATION_NUMBER = 'incorporation_number',
  EUID = 'euid',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type KycLegalStatus = 'SAS' | 'SASU' | 'SA' | 'SARL' | 'EURL' | (string & {});

export const SUPPORTED_LANGUAGES = ['fr', 'en'] as const;
export const DEFAULT_LANGUAGE: 'fr' = SUPPORTED_LANGUAGES[0];

export type Language = (typeof SUPPORTED_LANGUAGES)[number];
export interface ICompany {
  id: number;

  name: string;

  currency?: ICurrency;

  headquartersAddress?: IAddress;

  corporateName: string;

  billingAddress?: IAddress;

  legalForm: string;

  companyNumber: string;

  vatNumber: string;

  rcsNumber: string;

  nafCode: string;

  phoneNumber: string;

  email: string;

  emailImportId: string;

  defaultMealExpense: number;

  logo?: IFile | null;

  // @[ff: email-reminders]: set not null
  emailRemindersSender?: IUser | null;

  documentFooter: string | null;

  quoteNotes: string;

  orderNotes: string;

  startDateFiscalYear: number;

  offsetFirstFiscalPeriod: number;

  paymentTerm: number;

  termsOfSales: string;

  legalBillingNotice: string;

  quoteValidityDuration: number;

  ttShowWeekends: boolean;

  ttNbMinutesWorkingDay: number;

  ttStartHour: string | null;

  ttEndHour: string | null;

  ttApprovalMode: COMPANY_TT_APPROVAL_MODE;

  stripeCustomer: string | null;

  consumedOCRCredit: number;

  consumedQuoteCredit: number;

  limitOCRCredit: number;

  limitQuoteCredit: number;

  offsetQuoteCredit: number;

  limitProjectImportCredit: number;

  limitDateProjectImportCredit: Date;

  consumedProjectImportCredit: number;

  stripeSubscription: string | null;

  planAnnualPricing: number | null;

  planSubscription: PlanSubscription | null;

  planStartDate: Date;

  planCurrentPeriodStartDate: Date;

  planCurrentPeriodEndDate: Date;

  planPeriodResetDate: Date | null;

  lastPlanUpdateDate: Date | null;

  lang: Language;

  defaultQuoteAutomaticNumbering: boolean;

  emailTemplateCredit: number | null;

  // Bill reminder emails
  isLateBillRemindersEnabled: boolean;
  isToSentBillRemindersEnabled: boolean;
  frequencyWeekday: number;
  lastRemindersSendDate: Date | null;

  // Supplier invoice validation reminder emails
  frequencyWeekdaySupplierInvoiceValidationReminder: number;
  lastSupplierInvoiceValidationRemindersSendDate: Date | null;

  createdAt: Date;

  updatedAt: Date;

  activeFeatureFlags?: IFeatureFlag[];

  projects?: IProject[];

  clients?: IClient[];

  suppliers?: ISupplier[];

  workers?: IWorker[];

  bankingPaymentResource?: IBankingPaymentResource[];

  invoices?: IInvoice[];

  bills?: IBill[];

  quotes?: IQuote[];

  paymentMethods?: IPaymentMethod[];

  deadlineType: DEADLINE_TYPE;

  margin?: IMargin;

  libraryJobs?: ILibraryJob[];

  componentTypes?: IComponentType[];

  libraryComponents?: ILibraryComponent[];

  labels?: ICompanyLabel[];

  supplierInvoices?: ISupplierInvoice[];

  statementPdfSettings?: IPdfSettings;

  quotePdfSettings?: IPdfSettings;

  timeSlots?: ITimeSlot[];

  accountingConfig?: IAccountingConfig | null;

  orders?: IOrder[];

  ledgers?: ILedger[];

  thirdPartyConfigs?: IThirdPartyConfig<ThirdParties>[];

  vatList?: IVat[];

  userInvitation?: IUserInvitation[];

  contacts?: IContact[] | null;

  emailTemplates?: IEmailTemplate[];

  reminders?: IReminder[];

  overtimes?: IOvertime[];

  validationSteps?: IValidationStep[];

  accountingConfigPurchasesAccounts?: IAccountingConfigPurchasesAccount[];

  accountingConfigSalesAccounts?: IAccountingConfigSalesAccount[];

  isActivated: boolean;

  isChurned: boolean;

  pricingPlan: PRICING_PLAN | null;

  kycOrgName: string | null;

  kycOrgLegalStatus: KycLegalStatus | null;

  kycSchemeName: KYC_SCHEME_NAME | null;

  kycIdentification: string | null;

  kycAddress: string | null;

  kycSubmittedAt: Date | null;

  kycValidatedAt: Date | null;
}

export type ICompanyWithRelations = RequiredByKeys<
  ICompany,
  | 'logo'
  | 'currency'
  | 'margin'
  | 'billingAddress'
  | 'headquartersAddress'
  | 'labels'
  | 'statementPdfSettings'
  | 'quotePdfSettings'
>;

export type ICompanyKYCValidation = Pick<
  ICompany,
  | 'kycOrgName'
  | 'kycAddress'
  | 'kycIdentification'
  | 'kycOrgLegalStatus'
  | 'kycSchemeName'
  | 'kycSubmittedAt'
  | 'kycValidatedAt'
>;
