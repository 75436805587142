interface PasswordStrengthResponse {
  isPasswordStrong: boolean;
  rules: {
    hasLowerCase: boolean;
    hasUpperCase: boolean;
    hasNumber: boolean;
    hasSpecialChars: boolean;
    has8CharsOrMore: boolean;
    hasLessThan64Chars: boolean;
  };
}

export const checkPasswordStrength = (password: string): PasswordStrengthResponse => {
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChars = /[^a-zA-Z0-9]/.test(password);
  const has8CharsOrMore = password.length >= 8;
  const hasLessThan64Chars = password.length <= 64;

  return {
    isPasswordStrong:
      hasLowerCase && hasUpperCase && hasNumber && hasSpecialChars && has8CharsOrMore && hasLessThan64Chars,
    rules: {
      hasLowerCase,
      hasUpperCase,
      hasNumber,
      hasSpecialChars,
      has8CharsOrMore,
      hasLessThan64Chars,
    },
  };
};
