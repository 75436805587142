import type { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { EditUserScreen } from './EditUserScreen';
import { InviteUserScreen } from './InviteUserScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { DisplayNotFoundScreen } from 'screens/DisplayNotFoundScreen';

export const UsersRouter: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:userId/edit`}>
        <QueryWrapper>
          <EditUserScreen />
        </QueryWrapper>
      </Route>

      <Route path={`${path}/invite`}>
        <InviteUserScreen />
      </Route>

      <Route path="*">
        <DisplayNotFoundScreen />
      </Route>
    </Switch>
  );
};
