import { Children, isValidElement } from 'react';

const predicateElementOfType = (elt, Type) => isValidElement(elt) && elt.type.name === Type.name;

/**
 * @param children ReactNode
 * @param Type () => ReactNode
 * @returns {{matching: Array<ReactNode>, others: Array<ReactNode>}}
 * @deprecated Do not use this anymore. It has many issue at usage, performance issue. If you think about using this. Please think about passing your data in component props
 */
export const extractChildrenOfType = (children, Type) =>
  Children.toArray(children).reduce(
    ({ matching, others }, child) => {
      if (predicateElementOfType(child, Type)) {
        return { matching: [...matching, child], others };
      }
      return { matching, others: [...others, child] };
    },
    { matching: [], others: [] },
  );
