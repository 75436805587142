import type { AccountingAccountsNeeds, IVat } from '@graneet/business-logic';
import { VAT_TYPE, forEachRecursively } from '@graneet/business-logic';

export function canAccountingExportBeCreated(accountingAccountsNeeds: AccountingAccountsNeeds): boolean {
  let canCreateExports = true;
  forEachRecursively(accountingAccountsNeeds, (v) => {
    if (v === false) {
      canCreateExports = false;
    }
  });

  return canCreateExports;
}

export function sortVatList(a: Pick<IVat, 'type' | 'rate'>, b: Pick<IVat, 'type' | 'rate'>): number {
  // First reverse charge config, then intra community, finally normal vats
  if (a.type === VAT_TYPE.REVERSE_CHARGE) return -1;
  if (b.type === VAT_TYPE.REVERSE_CHARGE) return 1;
  if (a.type === VAT_TYPE.INTRA_COMMUNITY) return -1;
  if (b.type === VAT_TYPE.INTRA_COMMUNITY) return 1;
  return a.rate - b.rate;
}
