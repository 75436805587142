import type {
  IAccountingConfig,
  IAccountingConfigBankAccountCreateOrUpdateDTO,
  IAccountingConfigBankCreateDTO,
  IAccountingConfigPurchasesAccount,
  IAccountingConfigPurchasesAccountCreateDTO,
  IAccountingConfigUpdateDTO,
  IAccountingDataResponseDTO,
  IVat,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

import { apiNew } from 'features/api/services/apiNew.service';

const ACCOUNTING_PATH = '/accounting-configs';

export const accountingConfigs = createQueryKeys('accounting-config', {
  list: () => ({
    queryKey: [ACCOUNTING_PATH],
    queryFn: () => apiNew.get<never, IAccountingDataResponseDTO | undefined>(ACCOUNTING_PATH),
  }),
  canDeleteBank: (bankId: string) => ({
    queryKey: [ACCOUNTING_PATH, bankId, 'can-delete'],
    queryFn: () => apiNew.get<never, { canDelete: boolean }>(`${ACCOUNTING_PATH}/banks/${bankId}/can-delete`),
  }),
  canDeleteBankAccount: (bankAccountId: string) => ({
    queryKey: [ACCOUNTING_PATH, bankAccountId, 'can-delete'],
    queryFn: () =>
      apiNew.get<never, { canDelete: boolean }>(`${ACCOUNTING_PATH}/bank-accounts/${bankAccountId}/can-delete`),
  }),
  getVat: () => ({
    queryKey: [ACCOUNTING_PATH, 'standard-vat-rates'],
    queryFn: () => apiNew.get<never, IVat[]>(`${ACCOUNTING_PATH}/standard-vat-rates`),
  }),
});

export function useAccountingConfigs() {
  return useSuspenseQuery(accountingConfigs.list());
}

export function useAccountingConfigCanDeleteBank(bankId: string) {
  return useSuspenseQuery(accountingConfigs.canDeleteBank(bankId));
}

export function useAccountingConfigCanDeleteBankAccount(bankAccountId: string) {
  return useSuspenseQuery(accountingConfigs.canDeleteBankAccount(bankAccountId));
}

export function useAccountingStandardVATRates() {
  return useSuspenseQuery({
    ...accountingConfigs.getVat(),
    staleTime: 60 * 1000,
  });
}

export function useUpdateAccountingConfig() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (accountingConfigUpdateDTO: IAccountingConfigUpdateDTO) =>
      apiNew.put<IAccountingConfigUpdateDTO, IAccountingConfig>(ACCOUNTING_PATH, accountingConfigUpdateDTO),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:toasts.successSave'));
      await queryClient.invalidateQueries(accountingConfigs.list());
    },
  });
}

export function useCreateAccountingConfigPurchasesAccount() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (accountingConfigPurchasesAccountCreateDTO: IAccountingConfigPurchasesAccountCreateDTO) =>
      apiNew.post<IAccountingConfigPurchasesAccountCreateDTO, IAccountingConfigPurchasesAccount>(
        `${ACCOUNTING_PATH}/purchases-accounts`,
        accountingConfigPurchasesAccountCreateDTO,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:toasts.successSave'));
      await queryClient.invalidateQueries(accountingConfigs.list());
    },
  });
}

export function useCreateOrUpdateAccoutingConfigBankAccount() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IAccountingConfigBankAccountCreateOrUpdateDTO) =>
      apiNew.post<IAccountingConfigBankAccountCreateOrUpdateDTO, { id: string }>(
        `${ACCOUNTING_PATH}/bank-accounts`,
        dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:toasts.successSave'));
      await queryClient.invalidateQueries(accountingConfigs.list());
    },
  });
}

export function useCreateAccoutingConfigBank() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IAccountingConfigBankCreateDTO) =>
      apiNew.post<IAccountingConfigBankCreateDTO, { id: string }>(`${ACCOUNTING_PATH}/banks`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:toasts.successSave'));
      await queryClient.invalidateQueries(accountingConfigs.list());
    },
  });
}

export function useDeleteAccountingConfigPurchasesAccount() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => apiNew.delete<never, never>(`${ACCOUNTING_PATH}/purchases-accounts/${id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:toasts.successDelete'));
      await queryClient.invalidateQueries(accountingConfigs.list());
    },
  });
}

export function useDeleteAccountingConfigBank() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => apiNew.delete<never, never>(`${ACCOUNTING_PATH}/banks/${id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:toasts.successDelete'));
      await queryClient.invalidateQueries(accountingConfigs.list());
    },
  });
}

export function useDeleteAccountingConfigBankAccount() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => apiNew.delete<never, never>(`${ACCOUNTING_PATH}/bank-accounts/${id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:toasts.successDelete'));
      await queryClient.invalidateQueries(accountingConfigs.list());
    },
  });
}
