import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import type { DrawerApi } from '@graneet/lib-ui';
import { Button, DrawersStack, ValuesProvider } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import type { AgGridReact } from '@ag-grid-community/react';
import type { Quote, QuoteComponentsSupplyObject } from '@org/quotation-lib';

import { AgGridQuoteComponentsSupply } from 'features/quotation/ag-grid-quote-components-supply/components/AgGridQuoteComponentsSupply';

interface InternalQuoteComponentSelectDrawerProps {
  drawer: DrawerApi<string>;
  quote?: Quote;
  title: string;
  description: string;
  onSelection: (selection: QuoteComponentsSupplyObject[]) => void;
}

const InternalQuoteComponentSelectDrawer: FC<InternalQuoteComponentSelectDrawerProps> = ({
  drawer,
  quote,
  title,
  description,
  onSelection,
}) => {
  const { t } = useTranslation(['orders']);
  const gridRef = useRef<AgGridReact<QuoteComponentsSupplyObject>>(null);

  const handleOnSubmit = useCallback(() => {
    if (gridRef.current) {
      onSelection(gridRef.current.api.getSelectedRows());
      drawer.onClose();
    }
  }, [drawer, onSelection]);

  return (
    <DrawersStack.Drawer
      title={title}
      drawer={drawer}
      footer={
        <Flex justify="flex-end" w="100%">
          <Button
            onClick={handleOnSubmit}
            isDisabled={!!(gridRef.current && gridRef.current.api.getSelectedRows().length > 0)}
          >
            {t('orders:actions.importComponents')}
          </Button>
        </Flex>
      }
      size="2xl"
    >
      <Text mb={4}>{description}</Text>
      {quote && <AgGridQuoteComponentsSupply quote={quote} gridRef={gridRef} />}
    </DrawersStack.Drawer>
  );
};

export const QuoteComponentSelectDrawer: FC<InternalQuoteComponentSelectDrawerProps> = (props) => (
  <ValuesProvider>
    <InternalQuoteComponentSelectDrawer {...props} />
  </ValuesProvider>
);
