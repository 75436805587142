import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { GridItem } from '@chakra-ui/react';
import dayjs from 'dayjs';

import type { WeekData } from '../../contexts/MonthlyTimeTable.context';
import { useMonthlyTimeTableContext } from '../../contexts/MonthlyTimeTable.context';
import type { IMonthlyTimeTableRange } from '../../types/MonthlyTimeTable.type';
import type { RangeWithNumberOfWorkedHours } from '../MonthlyTimeTableWeekTooltip';
import { MonthlyTimeTableWeekTooltip } from '../MonthlyTimeTableWeekTooltip';
import { divideFloating, multiplyFloating } from '../../../../../utils';

interface MonthlyTimeTableWeekSlotTooltipProps {
  weekData: WeekData;

  columnStartIndex: number;

  ranges: IMonthlyTimeTableRange[][];

  rowSpan: number;
}

export const MonthlyTimeTableWeekPlaceholder: FC<MonthlyTimeTableWeekSlotTooltipProps> = ({
  weekData,
  columnStartIndex,
  ranges,
  rowSpan,
}) => {
  const { onHoverWeek, onWeekClick } = useMonthlyTimeTableContext();

  const { days: weekDays, week: weekNumber } = weekData;

  const handleHovered = useCallback(
    (isIn: boolean) => (week: number) => () => {
      onHoverWeek(isIn ? week : null);
    },
    [onHoverWeek],
  );

  const handleClick = useCallback(
    (date?: Date) => () => {
      if (date) {
        onWeekClick(date);
      }
    },
    [onWeekClick],
  );

  const currentRanges = useMemo(
    () =>
      ranges.reduce<RangeWithNumberOfWorkedHours[]>(
        // For each array of ranges
        (accRange, rangesGroupedBy) => {
          // For range in array of ranges
          const rangesWithNumberOfWorkedDays = rangesGroupedBy.reduce<RangeWithNumberOfWorkedHours[]>(
            (accRangesGroupedBy, range) => {
              // Built array of date of the week WITH weekend. Because the timetable table can be w/o weekend, we cannot use directly `weekDays`
              const weekDaysWithWeekends: dayjs.Dayjs[] = [...weekDays];
              const lastDayInWeekDays = weekDaysWithWeekends[weekDaysWithWeekends.length - 1];

              if (!lastDayInWeekDays) return accRangesGroupedBy;

              if (lastDayInWeekDays?.day() === 5) {
                const saturdayDate = lastDayInWeekDays.clone().add(1, 'day');
                const sundayDate = saturdayDate.clone().add(1, 'day');
                weekDaysWithWeekends.push(saturdayDate, sundayDate);
              }

              // Get days in the current placeholder
              const numberOfWorkedDays = weekDaysWithWeekends.reduce((count, weekDay) => {
                const isDayInRange = weekDay.isBetween(dayjs(range.startDate), dayjs(range.endDate), 'day', '[]');
                return count + (isDayInRange ? 1 : 0);
              }, 0);

              const rangeLength = dayjs(range.endDate).diff(dayjs(range.startDate), 'day') + 1;
              const numberOfWorkedMinutes = divideFloating(
                multiplyFloating(range.nbMinutes, numberOfWorkedDays),
                rangeLength,
              );

              // Return accumulator with the current range if there is at least 1 worked day
              return numberOfWorkedDays === 0
                ? accRangesGroupedBy
                : accRangesGroupedBy.concat({ range, numberOfWorkedMinutes });
            },
            [],
          );

          return accRange.concat(rangesWithNumberOfWorkedDays);
        },
        [],
      ),
    [ranges, weekDays],
  );

  return (
    <MonthlyTimeTableWeekTooltip ranges={currentRanges} weekNumber={weekData.week}>
      <GridItem
        zIndex={3}
        colSpan={weekDays.length}
        rowSpan={rowSpan}
        boxSize="100%"
        opacity={0.5}
        gridRowStart={1}
        gridColumnStart={columnStartIndex - weekDays.length + 1}
        _hover={{ bg: 'gray.200', cursor: 'zoom-in' }}
        onMouseEnter={handleHovered(true)(weekNumber)}
        onMouseLeave={handleHovered(false)(weekNumber)}
        // Hack for local configuration to make sure on zoom to fetch the right data
        onClick={handleClick(weekDays[0]?.startOf('week').add(2, 'day').toDate())}
      />
    </MonthlyTimeTableWeekTooltip>
  );
};
