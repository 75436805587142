import { SimpleGrid, Stack } from '@chakra-ui/react';
import { Form, useForm } from 'graneet-form';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { IProject, SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { compact } from 'lodash-es';

import { usePermissions } from '../../role/hooks/usePermissions';
import { useSupplierInvoiceUpdate, useSupplierInvoiceUpdateNote } from '../services/supplier-invoice.api';
import type { EditSupplierInvoiceForm } from '../forms/edit-supplier-invoice.form';
import { getSupplierInvoiceBeEdited } from '../services/supplier-invoices.util';
import { useDisabledButtonProps } from '../../role/hooks/useDisabledButtonProps';
import type { CommentFormValues } from '../forms/supplier-invoice.form';

import { SupplierInvoiceCommentCard } from './cards/SupplierInvoiceCommentCard';
import { SupplierInvoicePaymentsCard } from './cards/SupplierInvoicePaymentsCard/SupplierInvoicePaymentsCard';
import { AssociatedOrdersCard } from './cards/AssociatedOrdersCard/AssociatedOrdersCard';
import { AssociatedProjectsCard } from './cards/AssociatedProjectsCard';
import { AssociatedSupplierCard } from './cards/AssociatedSupplierCard';
import { SupplierInvoiceInformationsCard } from './cards/SupplierInvoiceInformationsCard/SupplierInvoiceInformationsCard';
import { AssociatedProgressStatementCard } from './cards/AssociatedProgressStatementCard/AssociatedProgressStatementCard';
import type { SupplierInvoiceFilesForm } from './cards/SupplierInvoiceReceiptFilesCard/SupplierInvoiceReceiptFilesCard';
import { SupplierInvoiceReceiptFilesCard } from './cards/SupplierInvoiceReceiptFilesCard/SupplierInvoiceReceiptFilesCard';
import { SupplierInvoiceStatusCard } from './cards/SupplierInvoiceStatusCard/SupplierInvoiceStatusCard';
import { SupplierInvoiceTagsCard } from './cards/SupplierInvoiceTagsCard';

interface SupplierInvoiceDetailsProps {
  project: IProject | undefined;

  onSeeValidationHistory: () => void;

  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}

export const SupplierInvoiceDetails: FC<SupplierInvoiceDetailsProps> = ({
  project,
  onSeeValidationHistory,
  supplierInvoice,
}) => {
  const { t } = useTranslation(['supplierInvoices', 'global']);

  const supplierInvoiceUpdateNoteMutation = useSupplierInvoiceUpdateNote();
  const supplierInvoiceUpdateMutation = useSupplierInvoiceUpdate();

  /**
   * Handle to update the supplier invoice comment
   */
  const onUpdateAfterBlur = useCallback(
    async (_: unknown, newComment: string) => {
      await supplierInvoiceUpdateNoteMutation.mutateAsync({ id: supplierInvoice.id, dto: { note: newComment } });
    },
    [supplierInvoiceUpdateNoteMutation, supplierInvoice.id],
  );
  const commentForm = useForm<CommentFormValues>({
    onUpdateAfterBlur,
    defaultValues: {
      comment: supplierInvoice.comment ?? undefined,
    },
  });

  const supplierInvoiceForm = useForm<EditSupplierInvoiceForm>();

  /**
   * Handle to update the supplier invoice receipts (delete or add)
   */
  const receiptsForm = useForm<SupplierInvoiceFilesForm>({
    defaultValues: {
      initialReceiptFiles: compact(supplierInvoice.supplierInvoiceFiles.map((f) => f.file)),
      receiptFiles: [],
    },
  });

  const onFileDelete = useCallback(
    async (fileId: string) => {
      await supplierInvoiceUpdateMutation.mutateAsync({
        id: supplierInvoice.id,
        dto: {
          deletedReceiptFilesIds: JSON.stringify([fileId]),
          deleteInvoiceFile: 'false',
        },
      });
    },
    [supplierInvoice, supplierInvoiceUpdateMutation],
  );
  const onFileAdded = useCallback(
    async (addedFile: File) => {
      await supplierInvoiceUpdateMutation.mutateAsync({
        id: supplierInvoice.id,
        dto: { deleteInvoiceFile: 'false', deletedReceiptFilesIds: '[]' },
        receiptFiles: [addedFile],
      });
    },
    [supplierInvoice, supplierInvoiceUpdateMutation],
  );

  const permissionsButtonProps = useDisabledButtonProps([PERMISSION.CREATE_SUPPLIER_INVOICE])();
  const canBeEdited = getSupplierInvoiceBeEdited(supplierInvoice, t, { allowDirectPaymentEdition: true });
  const hasPermissionsToSeeOrder = usePermissions([PERMISSION.ACCESS_ORDER]);

  return (
    <SimpleGrid spacing={4} columns={2}>
      <Form form={supplierInvoiceForm}>
        <Stack flex={1} spacing={4}>
          <SupplierInvoiceInformationsCard supplierInvoice={supplierInvoice} />
          <AssociatedSupplierCard supplierInvoice={supplierInvoice} />
          <AssociatedProjectsCard supplierInvoice={supplierInvoice} />
        </Stack>
      </Form>

      <Stack flex={1} spacing={4}>
        <SupplierInvoiceStatusCard
          project={project}
          onSeeValidationHistory={onSeeValidationHistory}
          supplierInvoice={supplierInvoice}
        />
        {hasPermissionsToSeeOrder && <AssociatedOrdersCard supplierInvoice={supplierInvoice} />}
        <AssociatedProgressStatementCard supplierInvoice={supplierInvoice} />
        <Form form={commentForm}>
          <SupplierInvoiceCommentCard<CommentFormValues> name="comment" />
        </Form>
        <SupplierInvoiceTagsCard supplierInvoice={supplierInvoice} />
        <SupplierInvoicePaymentsCard supplierInvoice={supplierInvoice} />
        <Form form={receiptsForm}>
          <SupplierInvoiceReceiptFilesCard
            isDisabled={permissionsButtonProps.isDisabled || canBeEdited.isDisabled}
            tooltip={permissionsButtonProps.tooltip || canBeEdited.tooltip}
            onFileAdded={onFileAdded}
            onFileDelete={onFileDelete}
            needsConfirmationToSubmit
          />
        </Form>
      </Stack>
    </SimpleGrid>
  );
};
