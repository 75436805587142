import type { FC, PropsWithChildren } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { LeafWithRelations } from '@graneet/lib-ui';
import {
  useValuesContext,
  ActionMenu,
  CurrencyField,
  DeepTable,
  DraggableItem,
  DropEffect,
  formatNumberToVatRate,
  formatVatRateToNumber,
  INPUT_IN_DEEP_TABLE_STYLE,
  NumberField,
  PercentageField,
  Price,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
  RichTextField,
  SegmentedDropZone,
  SimpleDuplicateIcon,
  TextField,
  useCurrency,
} from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IOrderItemDTO, IOrderLotDTO } from '@graneet/business-logic';
import { FEATURE_FLAGS, VAT_TYPE, isNumberFinite } from '@graneet/business-logic';
import { Checkbox } from '@chakra-ui/react';

import type { OrderEditForm, OrderItemKeys } from '../../../forms/order-edit-wizard';
import { getOrderItemFieldName } from '../../../forms/order-edit-wizard';
import { useOrderItem, useOrderItemComputedValue, useOrderTreeContext } from '../../../hooks/useOrderTree';
import { useOrderDirectPaymentInfosContext } from '../../../contexts/OrderDirectPaymentInfosContext';
import { ORDER_ENTITY_TYPES } from '../../../constants/orders.constant';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { Rule } from 'features/form/rules/Rule';

const DisplayOnlyWhenHasUnitPrices: FC<PropsWithChildren> = ({ children }) => {
  const form = useFormContext<OrderEditForm>();
  const { hasUnitPrices } = useOnChangeValues(form, ['hasUnitPrices']);

  if (!hasUnitPrices) {
    return <>-</>;
  }

  return <>{children}</>;
};

interface AmountExVATProps {
  id: string;
}

const AmountExVAT: FC<AmountExVATProps> = ({ id }) => {
  const computedValue = useOrderItemComputedValue(id);
  return <Price amount={computedValue?.amountExVAT} />;
};

const VatRateWrapper: FC<PropsWithChildren> = ({ children }) => {
  const form = useFormContext<OrderEditForm>();
  const { vatType } = useOnChangeValues(form, ['vatType']);

  if (vatType !== VAT_TYPE.NORMAL) {
    return <>-</>;
  }

  return <>{children}</>;
};

interface OrderDeepTableItemLineProps {
  id: string;
}

export const OrderDeepTableItemLine: FC<OrderDeepTableItemLineProps> = ({ id }) => {
  const { t } = useTranslation(['global']);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const hasTypeFF = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_ORDER_ITEM_COMPONENT_TYPE);

  const form = useFormContext<OrderEditForm>();
  const { updateLeafData, deleteLeaf, duplicateLeaf, isLeafAfterLeaf, moveLeaf, getCurrentTree } =
    useOrderTreeContext();

  const { hasValue, toggleValue } = useValuesContext<string>();

  const { isComplete } = useOrderItemComputedValue(id) || { isComplete: false };

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  const [isLineFocused, setIsLineFocused] = useState(false);

  const onFocus = useCallback(() => {
    setIsLineFocused(true);
  }, []);

  const onBlur = useCallback(
    (fieldName: OrderItemKeys) => () => {
      const formField = getOrderItemFieldName(id, fieldName);
      const { [formField]: value } = form.getFormValues();

      if (fieldName === 'vatRate') {
        updateLeafData(id, {
          vatRate: isNumberFinite(value) ? formatVatRateToNumber(value) : null,
        });
      } else if (fieldName === 'unitPriceExVAT') {
        updateLeafData(id, {
          unitPriceExVAT: isNumberFinite(value) ? mapAmountToNumber(value) : null,
        });
      } else {
        updateLeafData(id, {
          [fieldName]: value,
        });
      }

      setIsLineFocused(false);
    },
    [form, id, mapAmountToNumber, updateLeafData],
  );

  const onDelete = useCallback(() => {
    deleteLeaf(id);
  }, [deleteLeaf, id]);

  const onDuplicate = useCallback(() => {
    const newId = duplicateLeaf(id);

    const duplicatedItem = getCurrentTree().leaves[id];

    const {
      code,
      description,
      unit,
      quantity,
      unitPriceExVAT: rawUnitPriceExVAT,
      vatRate: rawVatRate,
      typeId,
    } = duplicatedItem;
    const unitPriceExVAT = isNumberFinite(rawUnitPriceExVAT) ? mapNumberToAmount(rawUnitPriceExVAT) : null;

    const vatRate = isNumberFinite(rawVatRate) ? formatNumberToVatRate(rawVatRate) : null;

    const itemFormValues: Partial<OrderEditForm> = {};
    itemFormValues[getOrderItemFieldName(newId, 'code')] = code;
    itemFormValues[getOrderItemFieldName(newId, 'description')] = description;
    itemFormValues[getOrderItemFieldName(newId, 'unit')] = unit;
    itemFormValues[getOrderItemFieldName(newId, 'quantity')] = quantity;
    itemFormValues[getOrderItemFieldName(newId, 'unitPriceExVAT')] = unitPriceExVAT;
    itemFormValues[getOrderItemFieldName(newId, 'vatRate')] = vatRate;
    itemFormValues[getOrderItemFieldName(newId, 'typeId')] = typeId;

    form.setFormValues(itemFormValues);
  }, [duplicateLeaf, form, getCurrentTree, id, mapNumberToAmount]);

  const inputStyle = useMemo(() => INPUT_IN_DEEP_TABLE_STYLE(isLineFocused), [isLineFocused]);

  const { areVatRatesEditable } = useOrderDirectPaymentInfosContext();

  const { leaf: item } = useOrderItem(id);
  const dndItem = useMemo(() => ({ id, parentNodeId: item.parentNodeId }), [id, item.parentNodeId]);

  const canDropOnItemTop = useCallback(
    (itemDrag: LeafWithRelations<IOrderLotDTO, IOrderItemDTO>) => !isLeafAfterLeaf(itemDrag, item),
    [isLeafAfterLeaf, item],
  );

  const canDropOnItemBottom = useCallback(
    (itemDrag: LeafWithRelations<IOrderLotDTO, IOrderItemDTO>) => !isLeafAfterLeaf(item, itemDrag),
    [isLeafAfterLeaf, item],
  );

  const onItemDropBefore = useCallback(
    (newPreviousItem: LeafWithRelations<IOrderLotDTO, IOrderItemDTO>) => {
      moveLeaf(newPreviousItem.id, item.parentNodeId, item.id, 'before');
    },
    [item.id, item.parentNodeId, moveLeaf],
  );

  const onItemDropAfter = useCallback(
    (newNextItem: LeafWithRelations<IOrderLotDTO, IOrderItemDTO>) => {
      moveLeaf(newNextItem.id, item.parentNodeId, item.id, 'after');
    },
    [item.id, item.parentNodeId, moveLeaf],
  );

  return (
    <DraggableItem type={ORDER_ENTITY_TYPES.ITEM} item={dndItem}>
      <SegmentedDropZone id={id} accept={ORDER_ENTITY_TYPES.ITEM}>
        <SegmentedDropZone.Segment
          weight={1}
          effect={DropEffect.CursorTop}
          canDrop={canDropOnItemTop}
          onDrop={onItemDropBefore}
        />

        <SegmentedDropZone.Segment
          weight={1}
          effect={DropEffect.CursorBottom}
          canDrop={canDropOnItemBottom}
          onDrop={onItemDropAfter}
        />
        <DeepTable.Row
          bg={isComplete ? 'white' : 'yellow.100'}
          leftContent={<DraggableItem.Handle color="gray.400" _hover={{ color: 'gray.500' }} />}
        >
          <DeepTable.Cell />

          <DeepTable.Cell>
            <Checkbox
              colorScheme="greenBrand"
              isChecked={hasValue(id)}
              onChange={(e) => {
                toggleValue(id, e.target.checked);
              }}
            />
          </DeepTable.Cell>

          <DeepTable.Cell>
            <TextField<OrderEditForm>
              name={getOrderItemFieldName(id, 'code')}
              onFocus={onFocus}
              onBlur={onBlur('code')}
              inputProps={inputStyle}
              noBorder
              hideErrorMessage
            />
          </DeepTable.Cell>

          <DeepTable.Cell fontSize="md">
            <RichTextField<OrderEditForm>
              name={getOrderItemFieldName(id, 'description')}
              navbarType="inline"
              onFocus={onFocus}
              onBlur={onBlur('description')}
              inputProps={inputStyle}
              configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
              hideErrorMessage
              noBorder
            >
              <Rule.IsRequired />
            </RichTextField>
          </DeepTable.Cell>

          <DeepTable.Cell>
            <TextField<OrderEditForm>
              name={getOrderItemFieldName(id, 'unit')}
              onFocus={onFocus}
              onBlur={onBlur('unit')}
              inputProps={inputStyle}
              hideErrorMessage
              noBorder
            >
              <Rule.IsRequired />
            </TextField>
          </DeepTable.Cell>

          <DeepTable.Cell>
            <NumberField<OrderEditForm>
              name={getOrderItemFieldName(id, 'quantity')}
              onFocus={onFocus}
              onBlur={onBlur('quantity')}
              inputProps={inputStyle}
              hideErrorMessage
              noBorder
            >
              <Rule.IsRequired />
            </NumberField>
          </DeepTable.Cell>

          <DeepTable.Cell>
            <DisplayOnlyWhenHasUnitPrices>
              <CurrencyField<OrderEditForm>
                name={getOrderItemFieldName(id, 'unitPriceExVAT')}
                onFocus={onFocus}
                onBlur={onBlur('unitPriceExVAT')}
                inputProps={inputStyle}
                hideErrorMessage
                noBorder
              />
            </DisplayOnlyWhenHasUnitPrices>
          </DeepTable.Cell>

          <DeepTable.Cell>
            <DisplayOnlyWhenHasUnitPrices>
              <VatRateWrapper>
                {hasStandardVATRates ? (
                  <AccountingVATField<OrderEditForm>
                    name={getOrderItemFieldName(id, 'vatRate')}
                    onFocus={onFocus}
                    onBlur={onBlur('vatRate')}
                    isDisabled={!areVatRatesEditable}
                    valueScale={100}
                  >
                    <Rule.IsRequired />
                  </AccountingVATField>
                ) : (
                  <PercentageField<OrderEditForm>
                    name={getOrderItemFieldName(id, 'vatRate')}
                    onFocus={onFocus}
                    onBlur={onBlur('vatRate')}
                    inputProps={inputStyle}
                    hideErrorMessage
                    isDisabled={!areVatRatesEditable}
                    noBorder
                  />
                )}
              </VatRateWrapper>
            </DisplayOnlyWhenHasUnitPrices>
          </DeepTable.Cell>

          <DeepTable.Cell fontSize="md">
            <DisplayOnlyWhenHasUnitPrices>
              <AmountExVAT id={id} />
            </DisplayOnlyWhenHasUnitPrices>
          </DeepTable.Cell>

          {hasTypeFF && (
            <DeepTable.Cell fontSize="md">
              <DisplayOnlyWhenHasUnitPrices>
                <ComponentTypeField<OrderEditForm>
                  name={getOrderItemFieldName(id, 'typeId')}
                  onFocus={onFocus}
                  onBlur={onBlur('typeId')}
                  material
                  isClearable={false}
                >
                  <Rule.IsRequired />
                </ComponentTypeField>
              </DisplayOnlyWhenHasUnitPrices>
            </DeepTable.Cell>
          )}

          <DeepTable.Cell>
            <ActionMenu>
              <ActionMenu.Action
                label={t('global:words.c.duplicate')}
                icon={<SimpleDuplicateIcon />}
                onClick={onDuplicate}
              />
              <ActionMenu.Delete onClick={onDelete} />
            </ActionMenu>
          </DeepTable.Cell>
        </DeepTable.Row>
      </SegmentedDropZone>
    </DraggableItem>
  );
};
