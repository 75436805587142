import { createContext, useContext } from 'react';
import type { ButtonProps } from '@chakra-ui/react';

import type { RegisteredButton } from './useModalButtons';
import { BUTTON_TYPE } from './useModalButtons';

const THROW_ERROR = () => {
  throw new Error('No ModalButtonsProvider found');
};

interface ModalButtonsContextProps {
  buttons: RegisteredButton;
  registerButton: (type: BUTTON_TYPE, button: ButtonProps) => void;
  unRegisterButton: (type: BUTTON_TYPE, button: ButtonProps) => void;
}

const ModalButtonsContext = createContext<ModalButtonsContextProps>({
  buttons: {
    [BUTTON_TYPE.CLOSE]: [],
    [BUTTON_TYPE.SECONDARY]: [],
    [BUTTON_TYPE.PRIMARY]: [],
  },
  registerButton: THROW_ERROR,
  unRegisterButton: THROW_ERROR,
});

export const useModalButtonsContext = (): ModalButtonsContextProps => useContext(ModalButtonsContext);
export const ModalButtonsProvider = ModalButtonsContext.Provider;
