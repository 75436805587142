import { CheckboxField, DeepTable, RichText } from '@graneet/lib-ui';
import { GridItem, useTheme } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import { useQuoteExportJob, useQuoteExportLot } from '../hooks/useQuoteExportTree';
import type { QuoteExportForm } from '../forms/quote-export-edition.wizard';
import {
  generateIsJobSelectedExportFieldName,
  generateIsLotSelectedExportFieldName,
  generateIsNoteSelectedExportFieldName,
} from '../forms/quote-export-edition.wizard';
import { countSelectedColumns } from '../services/quote-export.util';

interface QuoteExportNoteRowProps {
  depth: number;
  parentId: number;
  parentType: 'lot' | 'job';
  isOptional: boolean | undefined;
}

const Row: FC<{
  depth: number;
  leftContent: ReactNode;
  note: string | null;
  borderLeft: string;
}> = ({ depth, leftContent, note, borderLeft }) => {
  const form = useFormContext<QuoteExportForm>();
  const { exportItems: items } = useOnChangeValues(form, ['exportItems']);
  const nbColumns = useMemo(() => countSelectedColumns(items), [items]);

  return (
    <DeepTable.Row
      backgroundColor="white"
      offset={depth}
      color="gray.600"
      leftContent={leftContent}
      borderLeft={borderLeft}
    >
      <GridItem colSpan={nbColumns}>
        <DeepTable.Cell whiteSpace="pre-wrap">
          <RichText value={note} variant="light" />
        </DeepTable.Cell>
      </GridItem>
    </DeepTable.Row>
  );
};

const LotWrapper: FC<{ id: number; depth: number; isOptional: boolean | undefined }> = ({ id, depth, isOptional }) => {
  const theme = useTheme();
  const { node } = useQuoteExportLot(id);

  const form = useFormContext<QuoteExportForm>();
  const { [generateIsLotSelectedExportFieldName(id)]: isParentLotSelected } = useOnChangeValues(form, [
    generateIsLotSelectedExportFieldName(id),
  ]);
  useEffect(() => {
    if (isParentLotSelected === false) {
      form.setFormValues({
        [generateIsNoteSelectedExportFieldName(id, 'lot')]: false,
      });
    }
  }, [form, id, isParentLotSelected]);

  return (
    <Row
      note={node.note}
      depth={depth}
      leftContent={
        <CheckboxField<QuoteExportForm>
          name={generateIsNoteSelectedExportFieldName(id, 'lot')}
          isDisabled={!isParentLotSelected}
          mr={2}
        />
      }
      borderLeft={isOptional ? `2px solid ${theme.colors.purple[200]}` : ''}
    />
  );
};

const ItemWrapper: FC<{ id: number; depth: number; isOptional: boolean | undefined }> = ({ id, depth, isOptional }) => {
  const theme = useTheme();
  const { leaf } = useQuoteExportJob(id);

  const form = useFormContext<QuoteExportForm>();
  const { [generateIsJobSelectedExportFieldName(id)]: isParentJobSelected } = useOnChangeValues(form, [
    generateIsJobSelectedExportFieldName(id),
  ]);

  useEffect(() => {
    if (isParentJobSelected === false) {
      form.setFormValues({
        [generateIsNoteSelectedExportFieldName(id, 'job')]: false,
      });
    }
  }, [form, id, isParentJobSelected]);

  return (
    <Row
      note={leaf.note}
      depth={depth * 0.5}
      leftContent={
        <CheckboxField<QuoteExportForm>
          name={generateIsNoteSelectedExportFieldName(id, 'job')}
          isDisabled={!isParentJobSelected}
          mr={2}
        />
      }
      borderLeft={isOptional ? `2px solid ${theme.colors.purple[100]}` : ''}
    />
  );
};

export const QuoteExportNoteRow: FC<QuoteExportNoteRowProps> = ({ depth, parentId, parentType, isOptional }) => {
  if (parentType === 'lot') {
    return <LotWrapper depth={depth} id={parentId} isOptional={isOptional} />;
  }

  return <ItemWrapper depth={depth} id={parentId} isOptional={isOptional} />;
};
