import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import type { PaginatedStorage } from '../types/PaginatedStorage';
import type { Filters } from '../types/Filters';

export const useUrlPaginationStorage = (defaultFilters: Filters = {}): PaginatedStorage => {
  const { search, pathname, state } = useLocation();
  const { replace } = useHistory();
  const query = useRef(new URLSearchParams(search));

  useEffect(() => {
    Object.entries(defaultFilters).forEach(([key, value]) => {
      if (!query.current.has(key)) {
        query.current.set(key, String(value));
      }
    });
  }, [defaultFilters]);

  const forcePaginationRender = useCallback(() => {
    replace(`${pathname}?${query.current}`, state);
  }, [pathname, replace, state]);

  return useMemo(
    () => ({
      storage: query.current,
      forcePaginationRender,
    }),
    [forcePaginationRender],
  );
};
