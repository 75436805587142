import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const WorkerIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 300 300" {...props}>
    <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="150" cy="150" r="150" fill="#F0F4F8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.583 67.013C230.213 66.1155 226.544 63.2926 223.495 59.1608C218.6 60.7116 213.972 60.8385 210.718 59.5837C211.616 56.2136 214.438 52.5443 218.57 49.4956C217.02 44.6005 216.893 39.9728 218.147 36.7188C221.518 37.6162 225.187 40.4391 228.236 44.5709C233.131 43.0202 237.758 42.8932 241.012 44.148C240.115 47.5182 237.292 51.1874 233.16 54.2361C234.711 59.1312 234.838 63.7589 233.583 67.013ZM68.3543 88.705C64.7898 90.0085 59.757 89.7825 54.4602 88.0021C51.0646 92.44 47.0185 95.4418 43.3345 96.3543C42.031 92.7898 42.257 87.757 44.0374 82.4602C39.5995 79.0646 36.5977 75.0185 35.6852 71.3345C39.2497 70.031 44.2825 70.257 49.5793 72.0374C52.975 67.5995 57.021 64.5977 60.705 63.6852C62.0085 67.2497 61.7825 72.2825 60.0021 77.5793C64.44 80.975 67.4418 85.021 68.3543 88.705ZM237.009 262.968C234.811 264.4 231.387 265.03 227.543 264.655C225.947 268.171 223.689 270.822 221.35 272.009C219.918 269.811 219.288 266.387 219.663 262.543C216.147 260.947 213.496 258.689 212.309 256.35C214.507 254.918 217.931 254.288 221.775 254.663C223.371 251.147 225.629 248.496 227.968 247.309C229.4 249.507 230.03 252.931 229.655 256.775C233.171 258.371 235.822 260.629 237.009 262.968ZM33.2889 217.386C36.1583 221.275 39.6117 223.931 42.7836 224.776C43.9647 221.713 43.8451 217.358 42.3856 212.751C46.2744 209.881 48.9312 206.428 49.7759 203.256C46.7133 202.075 42.3578 202.195 37.7506 203.654C34.8813 199.765 31.4278 197.109 28.2559 196.264C27.0749 199.326 27.1944 203.682 28.654 208.289C24.7652 211.158 22.1083 214.612 21.2637 217.784C24.3263 218.965 28.6818 218.845 33.2889 217.386Z"
        fill="#83CDFA"
      />
      <path
        d="M211.875 158.438V183.75L231.562 231.562H223.125L195 195L189.375 178.125L199.073 158.438H211.875Z"
        fill="#004E7A"
      />
      <path
        d="M211.875 134.604V158.437L197.812 166.874L180.938 192.187L169.688 231.562H161.25L164.062 186.562L178.515 146.817L208.885 129.604L211.875 134.604Z"
        fill="#0D8CD9"
      />
      <path
        d="M147.351 157.7L152.812 133.125L164.062 127.5L176.379 148.028L208.885 129.604L203.438 107.812L211.875 105L214.688 130.312H223.125L225.938 102.188L220.312 90.9375L200.625 88.125L178.125 90.9375L164.062 96.5625L147.188 107.812L135.938 130.312L138.844 162.281L147.351 157.7Z"
        fill="#D1E4FF"
      />
      <path
        d="M186.562 89.8828V107.812L172.5 113.437L159 99.9375L152.461 104.297L164.062 127.5L176.379 148.028L208.885 129.604L203.438 107.812L192.75 89.1094L186.562 89.8828Z"
        fill="#0D8CD9"
      />
      <path
        d="M231.562 133.126L220.312 138.751L209.062 135.939L198.227 142.189L143.577 173.718L135.938 178.126L138.75 189.376L136.532 190.487C130.293 193.606 123.38 195.137 116.408 194.943C109.435 194.749 102.619 192.837 96.5625 189.376L97.4484 186.275C100.486 175.64 107.564 166.615 117.168 161.128L121.875 158.439L130.312 166.876L138.068 162.7L192.398 133.446L203.438 127.501L206.25 119.064L221.793 108.703L231.562 133.126Z"
        fill="#C78640"
      />
      <path
        d="M231.562 133.126L220.312 138.751L209.062 135.939L198.227 142.189L192.398 133.446L203.437 127.501L206.25 119.064L221.793 108.703L231.562 133.126Z"
        fill="#004E7A"
      />
      <path
        d="M152.812 133.125L138.75 124.688L135.938 130.312L138.844 162.281L147.351 157.7L152.812 133.125Z"
        fill="#FFCEB6"
      />
      <path d="M211.875 105L220.312 90.9375L225.938 102.188L223.125 130.312H214.688L211.875 105Z" fill="#FFCEB6" />
      <path
        d="M161.25 231.562L155.372 222.745C153.764 220.333 151.505 218.424 148.858 217.241C146.211 216.057 143.283 215.647 140.413 216.057H140.413C137.419 216.485 134.366 216.02 131.636 214.72C128.905 213.42 126.619 211.344 125.063 208.751L123.992 206.966C122.605 204.654 120.634 202.748 118.278 201.439L102.472 192.658C100.609 191.623 98.5523 190.987 96.431 190.79C94.3098 190.593 92.1707 190.839 90.1496 191.513L85.7259 192.987C81.8596 194.276 77.6396 193.976 73.9944 192.153L68.4375 189.375V231.562H161.25Z"
        fill="#FFCC4D"
      />
      <path
        d="M178.125 82.5V90.9375C178.125 93.1753 177.236 95.3214 175.654 96.9037C174.071 98.4861 171.925 99.375 169.688 99.375C167.45 99.375 165.304 98.4861 163.721 96.9037C162.139 95.3214 161.25 93.1753 161.25 90.9375V82.5H178.125Z"
        fill="#FFCEB6"
      />
      <path
        d="M178.125 82.5H152.812L158.438 76.875L159.015 75.1431C159.666 73.1904 160.914 71.4921 162.584 70.2886C164.254 69.0851 166.26 68.4375 168.318 68.4375C169.606 68.4375 170.881 68.6912 172.071 69.184C173.261 69.6768 174.342 70.3992 175.253 71.3098C176.163 72.2205 176.886 73.3015 177.379 74.4913C177.871 75.6811 178.125 76.9564 178.125 78.2442V82.5Z"
        fill="#109CF1"
      />
      <path
        d="M135.938 178.125L138.75 189.375L136.532 190.486C130.293 193.605 123.38 195.135 116.408 194.942C109.435 194.748 102.619 192.836 96.5625 189.375L97.4484 186.274C100.486 175.639 107.564 166.614 117.168 161.127L121.875 158.438L130.312 166.875L138.068 162.698L143.577 173.716L135.938 178.125Z"
        fill="#0D8CD9"
      />
    </svg>
  </Icon>
);
