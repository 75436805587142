import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatDateOrEmpty,
  ListingLayout,
  PaginatedTable,
  type PaginatedTableProps,
  PriceAdvanced,
  useChakraColors,
  useHotkeys,
} from '@graneet/lib-ui';
import type { BankingTransactionPaginatedResponse, BankingTransactionStatus } from '@graneet/business-logic';
import { bankingTransactionTypesAvailableForUser, BANKING_TRANSACTION_STATUS } from '@graneet/business-logic';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import type { GridApi, GridReadyEvent } from '@ag-grid-community/core';

import { mapBankingTransactionTypeToDisplayedType } from '../../features/banking/services/banking-transaction.util';
import { BankingConnectionSynchronisationIssueCallout } from '../../features/banking/components/BankingConnectionSynchronisationIssueCallout';

import { useBankingConnections } from 'features/banking/services/banking-connection.api';
import { BankingTransactionDrawer } from 'features/banking/components/BankingTransactionDrawer/BankingTransactionDrawer';
import { BankingBankLogo } from 'features/banking/components/BankingBankLogo';
import { useBankingTransactions } from 'features/banking/services/banking-transaction.api';
import { BankingTransactionStatusBadge } from 'features/banking/components/BankingTransactionStatusBadge';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

const LabelCell = ({ data }: { data: BankingTransactionPaginatedResponse['data'][number] | undefined }) =>
  data ? (
    <Flex gap={2} alignItems="center">
      <BankingBankLogo bankingConnection={data.bankingAccount.bankingConnection} />
      <Text lineHeight="1.25rem">{data.label}</Text>
      <Text fontSize="small" lineHeight="1rem" color="greenBrand.baseSecondary">
        {data.bankingAccount.bankingConnection.name}
      </Text>
    </Flex>
  ) : null;

const StatusCell = ({ data }: { data: BankingTransactionPaginatedResponse['data'][number] | undefined }) =>
  data ? <BankingTransactionStatusBadge bankingTransaction={data} /> : null;

const AmountCell = ({ data }: { data: BankingTransactionPaginatedResponse['data'][number] | undefined }) =>
  data ? <PriceAdvanced amount={data.amount} isPositiveColored /> : null;

export const ViewBankingTransactions = () => {
  const { t } = useTranslation(['global', 'banking']);

  const { hoverColor } = useChakraColors({ hoverColor: 'gray.50' });

  const { updateHeaderTitle } = useHeaderContext();
  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.bankTransactions'), []);
  }, [t, updateHeaderTitle]);

  const history = useHistory();
  const location = useLocation();

  const focusedId = new URLSearchParams(location.search).get('focusedId');

  const bankingTransactions = useBankingTransactions({
    defaultFilters: {
      status: ['TO_RECONCILE'] satisfies BankingTransactionStatus[],
    },
  });
  const bankingConnections = useBankingConnections();

  const columnDefs = useMemo<PaginatedTableProps<BankingTransactionPaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'label',
        headerName: t('banking:bankingTransaction.fields.label'),
        cellRenderer: LabelCell,
        sortable: true,
      },
      {
        field: 'type',
        headerName: t('banking:bankingTransaction.fields.type'),
        valueFormatter: (v) => (v.data?.type ? mapBankingTransactionTypeToDisplayedType(v.data.type, t) : ''),
        sortable: true,
      },
      {
        field: 'date',
        headerName: t('banking:bankingTransaction.fields.date'),
        valueFormatter: (v) => formatDateOrEmpty(v.data?.date),
        sortable: true,
      },
      {
        field: 'status',
        headerName: t('banking:bankingTransaction.fields.status'),
        cellRenderer: StatusCell,
        sortable: true,
      },
      {
        field: 'amount',
        headerName: t('banking:bankingTransaction.fields.amount'),
        cellRenderer: AmountCell,
        sortable: true,
      },
    ],
    [t],
  );

  const onRowClicked = useCallback(
    (id: string) => {
      const search = new URLSearchParams(location.search);
      search.set('focusedId', id.toString());

      history.replace({
        pathname: location.pathname,
        search: search.toString(),
      });
    },
    [history, location.pathname, location.search],
  );

  const removeFocusedId = useCallback(() => {
    const search = new URLSearchParams(location.search);
    search.delete('focusedId');

    history.replace({
      pathname: location.pathname,
      search: search.toString(),
    });
  }, [history, location.pathname, location.search]);

  const tableRef = useRef<null | GridApi>(null);
  const onGridReady = useCallback((event: GridReadyEvent) => {
    tableRef.current = event.api;
  }, []);

  const { hasNextRow, hasPreviousRow, focusRow } = useMemo(() => {
    const renderedNodes = tableRef.current?.getRenderedNodes() ?? [];
    const currentIndex = renderedNodes.findIndex((node) => node.data?.id === focusedId);

    const canFocusNextRow = renderedNodes.length + 1 > currentIndex;
    const canFocusPreviousRow = currentIndex > 0;

    return {
      hasNextRow: canFocusNextRow,
      hasPreviousRow: canFocusPreviousRow,
      focusRow: (offset: -1 | 1) => {
        if (offset === -1 && hasPreviousRow) {
          onRowClicked(renderedNodes[currentIndex - 1].data.id);
        }
        if (offset === 1 && hasNextRow) {
          onRowClicked(renderedNodes[currentIndex + 1].data.id);
        }
      },
    };
  }, [focusedId, onRowClicked]);

  useHotkeys(
    ['up', 'down'],
    (event) => {
      if (!focusedId) {
        return;
      }
      focusRow(event.key === 'ArrowUp' ? -1 : 1);
    },
    [focusedId, focusRow],
  );

  return (
    <>
      <BankingTransactionDrawer
        id={focusedId}
        isOpen={!!focusedId}
        onClose={removeFocusedId}
        onChangeFocusClicked={focusRow}
        onBankingTransactionUpdate={bankingTransactions.refetch}
      />

      <Box mx={8}>
        <BankingConnectionSynchronisationIssueCallout bankingConnections={bankingConnections.data} />
      </Box>

      <ListingLayout
        pagination={bankingTransactions}
        search={{
          placeholder: t('global:words.c.research'),
        }}
        filters={[
          {
            type: 'multi',
            label: t('banking:bankingTransaction.filters.bank.label'),
            placeholder: t('banking:bankingTransaction.filters.bank.placeholder'),
            name: 'bankingAccount',
            availableValues: bankingConnections.data
              .flatMap((bankingConnection) => bankingConnection.bankingAccounts)
              .map((bankingAccount) => ({
                value: bankingAccount.id,
                label: bankingAccount.name,
              })),
          },
          {
            type: 'amount',
            name: 'amount',
            label: t('banking:bankingTransaction.filters.amount.label'),
          },
          {
            type: 'date',
            name: 'date',
            label: t('banking:bankingTransaction.filters.date.label'),
          },
          {
            type: 'checkbox',
            name: 'type',
            label: t('banking:bankingTransaction.filters.type.label'),
            availableValues: bankingTransactionTypesAvailableForUser.map((type) => ({
              value: type,
              label: mapBankingTransactionTypeToDisplayedType(type, t),
            })),
          },
          {
            type: 'checkbox',
            name: 'status',
            label: t('banking:bankingTransaction.fields.status'),
            availableValues: BANKING_TRANSACTION_STATUS.map((status) => ({
              value: status,
              label: t(`banking:bankingTransaction.status.${status}`),
            })),
          },
        ]}
        content={
          <PaginatedTable
            gridId="banking-transaction"
            pagination={bankingTransactions}
            columnDefs={columnDefs}
            zeroState={{
              icon: <></>, // @[ff: banking-account-connection] TODO waiting product specs
              label: '',
            }}
            emptyState={{ label: t('banking:bankingTransaction.emptyStateLabel') }}
            onRowClicked={(event) => {
              if (event.data?.id) {
                onRowClicked(event.data?.id);
              }
            }}
            onGridReady={onGridReady}
            getRowStyle={(props) =>
              props.data?.id === focusedId
                ? {
                    background: hoverColor,
                  }
                : undefined
            }
          />
        }
      />
    </>
  );
};
