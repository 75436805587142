import type {
  GeneratorConfig,
  GeneratorConfigObject,
  GeneratorData,
  GeneratorInterface,
  LEDGER_GENERATORS,
} from '../ledger.type';
import { isNumberFinite } from '../../common/number.util';

export interface GeneratorCounterConfig extends GeneratorConfig<LEDGER_GENERATORS.COUNTER> {
  type: LEDGER_GENERATORS.COUNTER;
  size?: number;
  groupBy?: string[];
}

export type CounterGeneratorState = { [key: string]: number };

export class GeneratorCounter implements GeneratorInterface {
  size?: number;

  groupBy?: string[];

  constructor(config: GeneratorCounterConfig) {
    const { size, groupBy } = config;
    this.size = size;
    this.groupBy = groupBy;
  }

  /**
   * A counter is valid is size is greater or equal than 1 (if specified)
   * And groupBy option must refer to other existing blocks
   */
  public static isConfigValid(config: GeneratorConfigObject, block: string): boolean {
    const { size, groupBy } = config[block] as GeneratorCounterConfig;
    const otherBlocks = Object.keys(config).filter((otherBlock) => otherBlock !== block);

    if (size !== undefined) {
      if (!Number.isFinite(size) || size < 1) {
        return false;
      }
    }

    if (groupBy !== undefined) {
      if (!Array.isArray(groupBy) || groupBy.find((group) => !otherBlocks.includes(group))) {
        return false;
      }
    }

    return true;
  }

  /**
   * When state is updated, each value of counters must be greater or equal than the previous one
   * For more integrity, new but also old state are checked
   */
  public isStateValid(state: CounterGeneratorState, oldState: CounterGeneratorState): boolean {
    if (!GeneratorCounter.isRawStateValid(state) || !GeneratorCounter.isRawStateValid(oldState)) {
      return false;
    }

    return Object.entries(oldState || {}).every(([block, value]) => (state?.[block] || 0) >= value);
  }

  /**
   * Thanks to values of other blocks we create a key using groupBy array
   * The previous value of state is used to increment value and update state
   */
  public generateValue(
    data: GeneratorData,
    state: CounterGeneratorState,
    values: { [block: string]: string },
    lastCounter?: number,
  ): [string, number, CounterGeneratorState] {
    // 1. we build the group key to be stored in the state, depending on other blocks values
    const targetGroupValues = (this.groupBy || []).map((group) => values[group]);
    const targetGroupKey = JSON.stringify(targetGroupValues);

    // 2. we compute the next counter value
    let rawCounterValue;

    if (isNumberFinite(lastCounter)) {
      rawCounterValue = lastCounter + 1;
    } else {
      rawCounterValue = 1 + (state?.[targetGroupKey] || 0);
    }

    // 3. we format the next counter value
    const formattedCounterValue = rawCounterValue.toString().padStart(this.size || 1, '0');
    return [formattedCounterValue, rawCounterValue, { ...state, [targetGroupKey]: rawCounterValue }];
  }

  private static isRawStateValid(state: CounterGeneratorState): boolean {
    if (state === undefined) {
      return true;
    }

    if (
      typeof state !== 'object' ||
      Object.entries(state).find(([block, value]) => !GeneratorCounter.isRawStateValueValid(block, value))
    ) {
      return false;
    }

    return true;
  }

  private static isRawStateValueValid(block?: string, value?: number): boolean {
    return typeof block === 'string' && typeof value === 'number' && value >= 0;
  }
}
