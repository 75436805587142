import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export interface NavbarContextApi {
  isVisible: boolean;
  setIsVisible(newValue: boolean): void;
}
const NavbarContext = createContext<NavbarContextApi>({
  isVisible: true,
  setIsVisible: () => {},
});

export const useNavbarContext = () => useContext(NavbarContext);

export const NavbarProvider = ({ children }: PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(true);

  const context = useMemo<NavbarContextApi>(
    () => ({
      isVisible,
      setIsVisible,
    }),
    [isVisible],
  );

  return <NavbarContext.Provider value={context}>{children}</NavbarContext.Provider>;
};

export const useHideNavbar = () => {
  const { setIsVisible } = useNavbarContext();

  useEffect(() => {
    setIsVisible(false);

    return () => {
      setIsVisible(true);
    };
  }, [setIsVisible]);
};
