import type { FC, ReactElement } from 'react';
import { useCallback } from 'react';
import { AlobeesIcon, Badge, BatiprixIcon, GraneetButton } from '@graneet/lib-ui';
import type { ThirdParties } from '@graneet/business-logic';
import { capitalizeString } from '@graneet/business-logic';
import { useDisclosure } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { IntegrationCard } from '../IntegrationCard';

import { ThirdPartyDisableModal } from './ThirdPartyDisableModal';

import { BatiprixEditConfigModal } from 'features/batiprix/components/BatiprixEditConfigModal/BatiprixEditConfigModal';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

const READONLY_THIRD_PARTIES: ThirdParties[] = ['alobees'];

interface ThirdPartyCardProps {
  thirdPartyName: ThirdParties;

  isActive: boolean;

  isUnderMaintenance: boolean;
}

const THIRD_PARTY_ICON: Record<ThirdParties, ReactElement> = {
  alobees: <AlobeesIcon boxSize="auto" />,
  batiprix: <BatiprixIcon boxSize="auto" />,
  banking: <></> /* todo! */,
};

const ThirdPartyBadge: FC<{ isActive: boolean; isUnderMaintenance: boolean }> = ({ isUnderMaintenance, isActive }) => {
  const { t } = useTranslation(['global']);

  if (isUnderMaintenance) {
    return (
      <Badge showDot colorScheme="orange">
        {t('global:underMaintenance')}
      </Badge>
    );
  }

  if (isActive) {
    return (
      <Badge showDot colorScheme="green">
        {t('global:words.c.enabled')}
      </Badge>
    );
  }

  return (
    <Badge showDot colorScheme="red">
      {t('global:words.c.disabled')}
    </Badge>
  );
};

export const ThirdPartyCard: FC<ThirdPartyCardProps> = ({ thirdPartyName, isActive, isUnderMaintenance }) => {
  const { t } = useTranslation(['global']);

  const disabledModal = useDisclosure();

  const batiprixEditConfigModal = useDisclosure();

  const onThirdPartyUpdate = useCallback(() => {
    switch (thirdPartyName) {
      case 'batiprix':
        batiprixEditConfigModal.onOpen();
        break;
      default:
        break;
    }
  }, [batiprixEditConfigModal, thirdPartyName]);

  const onThirdPartyEnable = useCallback(() => {
    switch (thirdPartyName) {
      case 'batiprix':
        batiprixEditConfigModal.onOpen();
        break;
      default:
        break;
    }
  }, [batiprixEditConfigModal, thirdPartyName]);

  const isEditionAllowed = !READONLY_THIRD_PARTIES.includes(thirdPartyName);
  const buttonTooltip = isEditionAllowed ? undefined : (
    <Trans
      t={t}
      i18nKey="global:thirdParty.disabledThirdPartyTooltip"
      values={{
        thirdParty: capitalizeString(thirdPartyName),
        email: SUPPORT_EMAIL,
      }}
    />
  );

  const secondaryContent = {
    batiprix: t('global:thirdParty.descriptions.batiprix'),
    alobees: t('global:thirdParty.descriptions.alobees'),
    banking: '',
  };

  return (
    <>
      <IntegrationCard
        icon={THIRD_PARTY_ICON[thirdPartyName]}
        title={
          <>
            {capitalizeString(thirdPartyName)}
            <ThirdPartyBadge isUnderMaintenance={isUnderMaintenance} isActive={isActive} />
          </>
        }
        secondaryContent={secondaryContent[thirdPartyName]}
        actions={
          <>
            {isActive && (
              <GraneetButton
                variant="secondary"
                isDisabled={!isEditionAllowed || isUnderMaintenance}
                tooltip={buttonTooltip}
                onClick={onThirdPartyUpdate}
              >
                {t('global:words.c.update')}
              </GraneetButton>
            )}
            {isActive && (
              <GraneetButton
                variant="red"
                isDisabled={!isEditionAllowed || isUnderMaintenance}
                tooltip={buttonTooltip}
                onClick={disabledModal.onOpen}
              >
                {t('global:words.c.disable')}
              </GraneetButton>
            )}

            {!isActive && (
              <GraneetButton
                variant="secondary"
                isDisabled={!isEditionAllowed || isUnderMaintenance}
                tooltip={buttonTooltip}
                onClick={onThirdPartyEnable}
              >
                {t('global:thirdParty.enable')}
              </GraneetButton>
            )}
          </>
        }
      />

      <ThirdPartyDisableModal thirdPartyName={thirdPartyName} modal={disabledModal} />

      {thirdPartyName === 'batiprix' && (
        <BatiprixEditConfigModal modal={batiprixEditConfigModal} isEdition={isActive} />
      )}
    </>
  );
};
