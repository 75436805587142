import type { FC } from 'react';
import { useCallback } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import type { IClient, IClientResponseDTO } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import type { ProjectCreateForm } from '../../forms/project-create-wizard';

import { ClientCardTypeEnum } from 'features/client/components/cards/ClientCardDetails';
import { getClientById } from 'features/client/services/client.api';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { ClientCard } from 'features/client/components/cards/ClientCard';
import { ContactCard } from 'features/contact/components/cards/ContactCard';

interface ProjectClientMultiContactFieldsetProps {
  reloadData: () => Promise<void>;

  type: ClientCardTypeEnum;
}

export const ProjectClientMultiContactFieldset: FC<ProjectClientMultiContactFieldsetProps> = ({ type, reloadData }) => {
  const { t } = useTranslation(['global', 'clients', 'project']);
  const toast = useToast();

  const form = useFormContext<ProjectCreateForm>();
  const { primaryClient } = useOnChangeValues(form, ['primaryClient']);

  const updateUniqueClient = useCallback(
    async (clientId: number | null): Promise<IClient | IClientResponseDTO | null> => {
      if (clientId) {
        const [err, client] = await getClientById(clientId);
        if (err) {
          toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
          return null;
        }

        form.setFormValues({
          primaryClient: client,
        });
        return client;
      }
      form.setFormValues({
        primaryClient: undefined,
      });
      return null;
    },
    [form, t, toast],
  );

  const removeClient = useCallback(async () => {
    await updateUniqueClient(null);
  }, [updateUniqueClient]);

  return (
    <Box>
      {primaryClient && (
        <VStack gap={8}>
          <ClientCard
            client={primaryClient}
            type={type}
            onClientEdited={reloadData}
            onClientCardButtonClicked={type === ClientCardTypeEnum.CHANGE ? removeClient : undefined}
            onClientUpdate={updateUniqueClient}
          />

          <ContactCard clientId={primaryClient.id} />
        </VStack>
      )}
    </Box>
  );
};
