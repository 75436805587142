import type { ILibraryComponent } from '@graneet/business-logic';

export const sortLibraryComponents = <T extends ILibraryComponent>(libraryComponents: T[]): T[] =>
  libraryComponents.sort((s1, s2) => {
    // Sort by description and after on refCode
    if (s1.description < s2.description) return -1;
    if (s1.description > s2.description) return 1;

    if (s1.refCode && s2.refCode) {
      if (s1.refCode < s2.refCode) return -1;
      if (s1.refCode > s2.refCode) return 1;
    }
    // Otherwise, sort by is desc
    return s2.id - s1.id;
  });
