import { Flex, HStack } from '@chakra-ui/react';
import { Button, Input, SimpleSearchIcon } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useTimeTrackingContext } from '../context/TimeTrackingContext';

import { TimeTrackingSendPlanningButton } from './TimeTrackingSendPlanningButton';

export const TimeTrackingHeader: FC = () => {
  const { t } = useTranslation(['timeTracking']);
  const {
    state: {
      filters: { groupBy, query },
    },
    modalCreateSlot: { onOpen },
    handleSearchChange,
  } = useTimeTrackingContext();

  return (
    <HStack w="100%" justifyContent="space-between">
      <Input
        leftIcon={<SimpleSearchIcon />}
        inputProps={{
          width: '20rem',
          borderWidth: '1px',
          borderColor: 'gray.200',
          background: 'white',
          borderRadius: '8px',
          height: '32px',
          fontSize: 'sm',
          _focus: {
            borderColor: 'gray.200',
            outline: 'none',
            boxShadow: '0px 2px 5px -2px rgba(0, 0, 0, 0.06),0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
          },
          _invalid: { borderColor: 'red.500' },
        }}
        onChange={handleSearchChange}
        value={query}
        placeholder={t(`timeTracking:timeTable.searchPlaceholder.${groupBy}`)}
        marginRight="auto"
      />

      <Flex gap={3}>
        <TimeTrackingSendPlanningButton />
        <Button onClick={onOpen}>{t('timeTracking:actions.add')}</Button>
      </Flex>
    </HStack>
  );
};
