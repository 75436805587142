import type { UseDisclosureReturn } from '@chakra-ui/react';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import { ExportModal, useToast } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { QuoteGenerateExportDTO, QuoteGenerateExportOptionsDTO, QuotePdfDTO } from '@org/graneet-bff-client';

import { useQuote } from '../../hooks/useQuote';

import { useStore } from 'store/store';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { downloadFile, formatFileName } from 'features/file/services/file.util';
import { getDownloadUrl } from 'features/pdf/services/pdf.api';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';

type ExportType = 'pdf' | 'excel';

type Item = {
  name: string;
  label: string;
  value: boolean;
};

interface ExportQuoteModalProps {
  modalControls: UseDisclosureReturn;
}

export const ExportQuoteModal: FC<ExportQuoteModalProps> = ({ modalControls }) => {
  const toast = useToast();
  const { t } = useTranslation(['quote', 'global']);
  const [isExporting, setIsExporting] = useState(false);

  const { quote } = useQuote();
  const { selectedRows } = useStore.getState();

  const { quoteExportsProxyApi } = useQuotationProxyApis();

  const handlePDFDownload = useCallback(
    (pdf: QuotePdfDTO) => () => {
      setIsExporting(false);
      const filename = formatFileName(quote?.getName() ?? '', quote?.getAddedAt(), quote?.getRefCode());

      const downloadUrl = getDownloadUrl(pdf.apiId, filename);

      // Browser download
      downloadFile(downloadUrl!, filename);

      toast.success(t('global:words.c.success'), t('quote:export.generateExportModal.success'));
    },
    [quote, t, toast],
  );

  const handleExportModalClose = useCallback(() => {
    setIsExporting(false);
    modalControls.onClose();
  }, [modalControls]);

  const handleExportClick = useCallback(
    (items: Item[], exportType: ExportType) => {
      setIsExporting(true);

      const options: QuoteGenerateExportOptionsDTO = {
        columns: {
          index: false,
          refCode: false,
          unit: false,
          quantity: false,
          unitFlatCostAmount: false,
          flatCostAmount: false,
          margin: false,
          unitAmount: false,
          vatRate: false,
          amountExVat: false,
          workForce: false,
          shareOfHiddenCostAmount: false,
        },
        notes: false,
        infos: false,
        images: false,
        components: false,
      } as any;
      items.forEach((item) => {
        if (item.name in options.columns) {
          options.columns[item.name as keyof typeof options.columns] = item.value;
        }

        if (item.name === 'notes') {
          options.notes = item.value;
        }
        if (item.name === 'infos') {
          options.infos = item.value;
        }
        if (item.name === 'images') {
          options.images = item.value;
        }
        if (item.name === 'components') {
          options.components = item.value;
        }
      });

      if (exportType === 'excel' && quote && quoteExportsProxyApi) {
        const quoteGenerateExportDTO: QuoteGenerateExportDTO = {
          options,
          export: {
            nodes: selectedRows.map((row) => row.id),
            quoteId: quote.getId(),
          },
        };

        quoteExportsProxyApi.quoteExportSpreadsheet(quoteGenerateExportDTO).then((res) => {
          if (res[0]) {
            toast.error(
              t('global:words.c.error'),
              t('quote:export.generateExportModal.error', { email: SUPPORT_EMAIL }),
            );
          } else {
            downloadFile(res[1]);
            toast.success(t('global:words.c.success'), t('quote:export.generateExportModal.success'));
          }
        });

        setIsExporting(false);
      }

      if (exportType === 'pdf' && quote && quoteExportsProxyApi) {
        const quoteGenerateExportDTO: QuoteGenerateExportDTO = {
          options,
          export: {
            nodes: selectedRows.map((row) => row.id),
            quoteId: quote.getId(),
          },
        };

        quoteExportsProxyApi.quoteExportPDF(quoteGenerateExportDTO).then((res) => {
          if (res[0]) {
            toast.error(
              t('global:words.c.error'),
              t('quote:export.generateExportModal.error', { email: SUPPORT_EMAIL }),
            );
          } else {
            const pdf = res[1];
            subscribeToPDFUpdates(pdf, handlePDFDownload(pdf));
          }
        });
      }
    },
    [handlePDFDownload, quote, quoteExportsProxyApi, selectedRows, t, toast],
  );

  const exportItems = useMemo(
    () => [
      {
        label: t('quote:quotation.modals.export.fields.information'),
        name: 'information',
        value: true,
        items: [
          {
            label: t('quote:quotation.modals.export.fields.index'),
            name: 'index',
            value: true,
          },
          {
            label: t('quote:quotation.modals.export.fields.refCode'),
            name: 'refCode',
            value: false,
          },
          {
            label: t('quote:quotation.modals.export.fields.denomination'),
            name: 'denomination',
            isDisabled: true,
            value: true,
          },
          {
            label: t('quote:quotation.modals.export.fields.unit'),
            name: 'unit',
            value: true,
          },
          {
            label: t('quote:quotation.modals.export.fields.quantity'),
            name: 'quantity',
            value: true,
          },
          {
            label: t('quote:quotation.modals.export.fields.components'),
            name: 'components',
            value: false,
          },
          {
            label: t('quote:quotation.modals.export.fields.notes'),
            name: 'notes',
            value: true,
          },
          {
            label: t('quote:quotation.modals.export.fields.images'),
            name: 'images',
            value: true,
          },
          {
            label: t('quote:quotation.modals.export.fields.infos'),
            name: 'infos',
            value: true,
          },
        ],
      },
      {
        label: t('quote:quotation.modals.export.fields.disbursement'),
        name: 'disbursement',
        value: true,
        items: [
          {
            label: t('quote:quotation.modals.export.fields.unitDisbursementExVAT'),
            name: 'unitFlatCostAmount',
            value: false,
          },
          {
            label: t('quote:quotation.modals.export.fields.disbursementExVAT'),
            name: 'flatCostAmount',
            value: false,
          },
          {
            label: t('quote:quotation.modals.export.fields.jobMargin'),
            name: 'margin',
            value: false,
          },
          {
            label: t('quote:quotation.modals.export.fields.workForce'),
            name: 'workForce',
            value: false,
          },
          {
            label: t('quote:quotation.modals.export.fields.shareOfHiddenCostAmount'),
            name: 'shareOfHiddenCostAmount',
            value: false,
          },
        ],
      },
      {
        label: t('quote:quotation.modals.export.fields.sale'),
        name: 'sale',
        value: true,
        items: [
          {
            label: t('quote:quotation.modals.export.fields.unitPriceExVAT'),
            name: 'unitAmount',
            value: true,
          },
          {
            label: t('quote:quotation.modals.export.fields.amountExVAT'),
            name: 'amountExVat',
            value: true,
          },
          {
            label: t('quote:quotation.modals.export.fields.jobVAT'),
            name: 'vatRate',
            value: false,
          },
        ],
      },
    ],
    [t],
  );

  const exportCta = useMemo(
    () => ({
      onClick: handleExportClick,
      label: t('quote:quotation.modals.export.cta'),
    }),
    [handleExportClick, t],
  );

  return (
    <ExportModal
      isOpen={modalControls.isOpen}
      onClose={handleExportModalClose}
      calloutText={t('quote:export.generateExportModal.callout')}
      cta={exportCta}
      items={exportItems}
      isExporting={isExporting}
      title={t('quote:quotation.modals.export.title')}
      selectedLabel={t('quote:quotation.modals.export.selectedLabel')}
    />
  );
};
