import type {
  QuoteCustomDiscountCreateDTO,
  QuoteCustomDiscountDeleteDTO,
  QuoteCustomDiscountUpdateDTO,
  QuoteCustomDiscountsApi,
} from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteCustomDiscountsProxyApi {
  private static quoteCustomDiscountsProxyApi: QuoteCustomDiscountsProxyApi;

  private readonly quoteCustomDiscountsApi: QuoteCustomDiscountsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteCustomDiscountsApi = externalApi.getQuoteCustomDiscountsApi();
  }

  public static getQuoteCustomDiscountProxyApi(externalApi: ExternalApi): QuoteCustomDiscountsProxyApi {
    if (!this.quoteCustomDiscountsProxyApi) {
      QuoteCustomDiscountsProxyApi.quoteCustomDiscountsProxyApi = new QuoteCustomDiscountsProxyApi(externalApi);
    }
    return QuoteCustomDiscountsProxyApi.quoteCustomDiscountsProxyApi;
  }

  public async createQuoteCustomDiscount(quoteCustomDiscountCreateDTO: QuoteCustomDiscountCreateDTO) {
    return proxyHelper(async () =>
      this.quoteCustomDiscountsApi.createQuoteCustomDiscount(quoteCustomDiscountCreateDTO),
    );
  }

  public async deleteQuoteCustomDiscount(quoteCustomDiscountDeleteDTO: QuoteCustomDiscountDeleteDTO) {
    return proxyHelper(async () =>
      this.quoteCustomDiscountsApi.deleteQuoteCustomDiscount(quoteCustomDiscountDeleteDTO),
    );
  }

  public async updateQuoteCustomDiscount(
    customDiscountId: string,
    quoteCustomDiscountUpdateDTO: QuoteCustomDiscountUpdateDTO,
  ) {
    return proxyHelper(async () =>
      this.quoteCustomDiscountsApi.updateQuoteCustomDiscount(customDiscountId, quoteCustomDiscountUpdateDTO),
    );
  }
}
