import type { FC, ReactNode } from 'react';
import { Placeholder as GraneetFormPlaceholder } from 'graneet-form';

export interface PlaceholderProps {
  placement: 'bottom' | 'top';

  children: ReactNode;
}

export const Placeholder: FC<PlaceholderProps> = ({ placement, children }) => (
  <GraneetFormPlaceholder placement={placement}>{children}</GraneetFormPlaceholder>
);
