import { useHistory, useLocation, useParams } from 'react-router-dom';
import type { TreeDataWithRelations, TreeChanges, TreeComputedValues } from '@graneet/lib-ui';
import { Wizard, useCurrency } from '@graneet/lib-ui';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import {
  computeSubProjectAmountsFromContracts,
  DISCOUNT_TYPES,
  type IContractUpdateDTO,
} from '@graneet/business-logic';

import { EditContainerStep } from './_steps/EditContainerStep';

import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { computeContractFromTree, mapCurrentTreeToContractUpdateDTO } from 'features/contract/services/contract.util';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import type { ContractEditionWizardForm } from 'features/contract/forms/contract-edition-wizard.form';
import type {
  ContractItemComputedValue,
  ContractLotComputedValue,
  IContractInfosItemWithUUID,
  IContractInfosLotWithUUID,
} from 'features/contract/types/contract.type';
import { useContract } from 'features/contract/services/contract.api';
import { ContractApplyChangesModal } from 'features/contract/components/modals/ContractApplyChangesModal';

export const EditContractScreen = () => {
  useHideNavbar();

  const { t } = useTranslation(['global', 'contracts']);
  const { mapAmountToNumber } = useCurrency();

  const [subProjectTotalAmount, setSubProjectTotalAmount] = useState<number | undefined>(undefined);

  const history = useHistory();
  const location = useLocation<{ redirectUrl?: string }>();
  const { contractId: rawContractId, projectId: rawProjectId } = useParams<{ contractId: string; projectId: string }>();
  const projectId = parseInt(rawProjectId, 10);
  const contractId = parseInt(rawContractId, 10);

  const applyChangesModal = useDisclosure();

  const contractInfos = useContract(contractId);

  const getCurrentTreeRef = useRef<() => TreeDataWithRelations<IContractInfosLotWithUUID, IContractInfosItemWithUUID>>(
    () => ({}) as any,
  );
  const getTreeChangesRef = useRef<() => TreeChanges<IContractInfosLotWithUUID, IContractInfosItemWithUUID>>(() => ({
    nodes: {
      updated: [],
      created: [],
      deleted: [],
    },
    leaves: {
      updated: [],
      created: [],
      deleted: [],
    },
  }));

  const getComputedValuesRef = useRef<
    () => TreeComputedValues<
      IContractInfosLotWithUUID,
      IContractInfosItemWithUUID,
      ContractLotComputedValue,
      ContractItemComputedValue
    >
  >(() => ({}) as any);

  const labels = useWizardLabels({
    quit: t('global:words.c.quit'),
    save: t('contracts:wizard.validateUpdates'),
  });

  const onQuit = useCallback(() => {
    history.push(location.state?.redirectUrl || `/projects/${projectId}/contracts`);
  }, [history, location.state?.redirectUrl, projectId]);

  const contractUpdatedRef = useRef<IContractUpdateDTO>();

  const onFinish = useCallback(
    async (form: ContractEditionWizardForm) => {
      const {
        name,
        code,
        receptionDate,
        discountAmountExVAT,
        hasReversalOfLiability,
        hasDiscountBeenUpdated,
        customDiscounts,
      } = form.editContainer;

      const partialContract = {
        name,
        code,
        receptionDate,
        discount: {
          amountExVAT: discountAmountExVAT && mapAmountToNumber(discountAmountExVAT),
        },
        customDiscounts,
        isAutomaticNumberingActivated: contractInfos.data.isAutomaticNumberingActivated || false,
      };

      const tree = getCurrentTreeRef.current?.();
      const computedValues = getComputedValuesRef.current?.();

      const contractAmounts = computeContractFromTree(
        computedValues.nodes[tree.rootNodeId]?.totalExVAT || 0,
        tree,
        computedValues.leaves,
        contractInfos.data,
        contractInfos.data.subProject,
        {
          amountExVAT: partialContract.discount.amountExVAT,
          type: DISCOUNT_TYPES.AMOUNT,
          percentage: null,
        },
        customDiscounts,
      );

      const otherContracts = contractInfos.data.contracts.filter(({ id }) => id !== contractInfos.data.contract.id);

      const { amountIncVAT: newProjectAmountIncVAT } = computeSubProjectAmountsFromContracts(
        [contractAmounts, ...otherContracts],
        contractInfos.data.subProject,
      );

      setSubProjectTotalAmount(newProjectAmountIncVAT);

      contractUpdatedRef.current = mapCurrentTreeToContractUpdateDTO(
        getTreeChangesRef.current?.(),
        partialContract,
        hasReversalOfLiability,
        hasDiscountBeenUpdated,
      );

      applyChangesModal.onOpen();
    },
    [applyChangesModal, contractInfos, mapAmountToNumber],
  );
  const headerTitle = t('contracts:wizard.title', { name: contractInfos.data.contract.name });

  return (
    <>
      <Wizard<ContractEditionWizardForm> onQuit={onQuit} onFinish={onFinish} headerTitle={headerTitle} labels={labels}>
        <Wizard.Step<ContractEditionWizardForm> name="editContainer">
          <EditContainerStep
            contractInfos={contractInfos.data}
            getCurrentTreeRef={getCurrentTreeRef}
            getTreeChangesRef={getTreeChangesRef}
            getComputedValuesRef={getComputedValuesRef}
          />
        </Wizard.Step>
      </Wizard>
      <ContractApplyChangesModal
        onClose={applyChangesModal.onClose}
        isOpen={applyChangesModal.isOpen}
        contractId={contractId}
        projectId={projectId}
        quoteUUID={contractInfos.data.contract.quoteUUID}
        contractUpdatedRef={contractUpdatedRef}
        hasFinalStatement={contractInfos.data.hasFinalStatement}
        holdback={contractInfos.data.subProject.holdback}
        subProjectTotalAmount={subProjectTotalAmount}
      />
    </>
  );
};
