import { useCallback } from 'react';
import type { Quote } from '@org/quotation-lib';
import { QuoteNodeCreateChildUseCase } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { mapQuoteNodeToUpsertLotDTO } from 'features/quotation/quote-common/mappers/quoteLotMapper';
import { updateQuoteNewlyCreatedNodeId } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import {
  useQuoteSetClientRequestsStore,
  StatusEnum,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteLotCreate() {
  const { quoteLotsProxyApi, quoteNodeDeletesProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (
      parentId: string | null | undefined,
      position?: { prevSiblingId: string | null; nextSiblingId: string | null },
    ): ICommand => {
      if (parentId === undefined) {
        throw new Error('parentId or null is required');
      }

      return {
        execute(quote: Quote) {
          try {
            const tree = quote.getTree();
            const parentNode = parentId === null ? tree.getRootNode() : tree.getNodeOrThrow(parentId);

            let prevSiblingId = null;
            let nextSiblingId = null;

            if (position) {
              prevSiblingId = position.prevSiblingId;
              nextSiblingId = position.nextSiblingId;
            } else {
              prevSiblingId = parentNode.getLastChild()?.getId() ?? null;
            }

            const quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteLot(
              parentNode,
              prevSiblingId,
              nextSiblingId,
              this.oldValue && this.oldValue.nodeId ? this.oldValue.nodeId : undefined,
              this.oldValue && this.oldValue.contentId ? this.oldValue.contentId : undefined,
            );
            this.oldValue = {
              nodeId: quoteNodeCreated?.getId(),
              contentId: quoteNodeCreated?.getContent().getId(),
            };
            if (!quoteNodeCreated) {
              throw new Error('Failed to create node');
            }

            updateQuoteNewlyCreatedNodeId(quoteNodeCreated?.getId() ?? null);
            quoteSetToStore(quote);

            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteLotsProxyApi
              .createQuoteLot(mapQuoteNodeToUpsertLotDTO(quoteNodeCreated, quote.getId(), clientRequestId, timestamp))
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));

            return true;
          } catch (e: any) {
            return quoteError(e.message);
          }
        },
        undo(quote: Quote) {
          try {
            const quoteLot = quote.getTree().getQuoteLotOrThrow(this.oldValue.nodeId);
            quoteLot.deleteQuoteLot();
            quoteSetToStore(quote);

            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteNodeDeletesProxyApi
              .deleteQuoteNode({
                quoteId: quote.getId(),
                timestamp,
                clientRequestId,
                nodeId: this.oldValue.nodeId,
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));

            return true;
          } catch (e: any) {
            return quoteError(e.message);
          }
        },
      };
    },
    [quoteError, quoteLotsProxyApi, quoteNodeDeletesProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
