import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import type { GraneetBadgeColor } from '@graneet/lib-ui';
import { GraneetBadge, OptionBadge, RichText, GraneetIconButton, Tooltip, TreeIndicator } from '@graneet/lib-ui';
import type { QuoteFileObject, QuoteNodeObject } from '@org/quotation-lib';
import { QUOTE_ITEM_TYPE_OF_PRICING } from '@org/quotation-lib';
import type { AbstractQuoteItemObject } from '@org/quotation-lib/src/abstract-quote-item/entities/abstract-quote-item.object';
import { useMemo, type FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { GridApi } from '@ag-grid-community/core';
import isNil from 'lodash-es/isNil';
import isEmpty from 'lodash-es/isEmpty';

import { Warning } from '../Warning';

import { useDenominationHeight } from './useDenominationHeight';

import { useStore } from 'store/store';
import { useQuoteBasicItemUpdatePricingType } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdatePricingType';
import { useQuoteOptionalItemUpdatePricingType } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdatePricingType';
import { useQuoteHiddenCostItemUpdatePricingType } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemUpdatePricingType';
import { useQuoteSubItemUpdatePricingType } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdatePricingType';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

const colors: GraneetBadgeColor[] = [
  'red',
  'blue',
  'cyan',
  'yellow',
  'green',
  'white',
  'gray',
  'indigo',
  'pink',
  'teal',
  'warm_gray',
  'orange',
  'greenBrand',
  'purple',
];

export const Item: FC<{
  item: {
    id: string;
    denomination: string | null;
    note?: string | null;
    files?: QuoteFileObject[];
  };
  isExpanded: boolean;
  componentTypeId?: number | null;
  type: AbstractQuoteItemObject['type'] | 'QuoteComponent';
  level: number;
  hasChildren?: boolean;
  nodeId: string;
  api: GridApi<QuoteNodeObject>;
  typeOfPricing?: QUOTE_ITEM_TYPE_OF_PRICING;
}> = ({ item, level, hasChildren, type, componentTypeId, nodeId, typeOfPricing, isExpanded }) => {
  const { t } = useTranslation(['quote']);

  const quoteBasicItemUpdatePricingType = useQuoteBasicItemUpdatePricingType();
  const quoteOptionalItemUpdatePricingType = useQuoteOptionalItemUpdatePricingType();
  const quoteHiddenCostItemUpdatePricingType = useQuoteHiddenCostItemUpdatePricingType();
  const quoteSubItemUpdatePricingType = useQuoteSubItemUpdatePricingType();
  const { quote } = useQuote();
  const { executeCommand } = useCommands();

  const componentTypes = useStore((state) => state.quoteComponentTypes);

  const editorRef = useStore((state) => state.quoteRichTextEditorRef);

  const quoteRowHeight = useStore((state) => state.quoteRowHeight);
  const denominationHeight = useDenominationHeight();

  const getPlainText = useCallback(
    (v: string | null | undefined) =>
      editorRef?.current?.getPlainText ? (editorRef?.current?.getPlainText(v) ?? null) : null,
    [editorRef],
  );

  const itemComponent = useMemo(() => {
    if (!componentTypeId) {
      return null;
    }
    const componentType = componentTypes.find((component) => component.id === componentTypeId);

    return componentType
      ? {
          ...componentType,
          color: (colors.includes(componentType.color) ? componentType.color : 'gray') as GraneetBadgeColor,
        }
      : null;
  }, [componentTypes, componentTypeId]);

  const color = useMemo(() => {
    if (type === 'QuoteSubItem' || type === 'QuoteComponent') {
      return '#6C737F';
    }
    return '#1F2A37';
  }, [type]);

  const plainTextValue = getPlainText(item.denomination);

  const displayWarning = useMemo(() => {
    if (type === 'QuoteComponent') {
      return false;
    }
    return isNil(plainTextValue) || isEmpty(plainTextValue);
  }, [plainTextValue, type]);

  const handleTypeOfPricingChange = useCallback(() => {
    if (!typeOfPricing) {
      return;
    }

    if (
      type !== 'QuoteBasicItem' &&
      type !== 'QuoteOptionalItem' &&
      type !== 'QuoteHiddenCostItem' &&
      type !== 'QuoteSubItem'
    ) {
      return;
    }

    const newType =
      typeOfPricing === QUOTE_ITEM_TYPE_OF_PRICING.UNIT
        ? QUOTE_ITEM_TYPE_OF_PRICING.FLAT_RATE
        : QUOTE_ITEM_TYPE_OF_PRICING.UNIT;

    if (type === 'QuoteBasicItem') {
      executeCommand(quoteBasicItemUpdatePricingType(nodeId, newType), quote);
    }
    if (type === 'QuoteOptionalItem') {
      executeCommand(quoteOptionalItemUpdatePricingType(nodeId, newType), quote);
    }
    if (type === 'QuoteHiddenCostItem') {
      executeCommand(quoteHiddenCostItemUpdatePricingType(nodeId, newType), quote);
    }
    if (type === 'QuoteSubItem') {
      executeCommand(quoteSubItemUpdatePricingType(nodeId, newType), quote);
    }
  }, [
    executeCommand,
    nodeId,
    quote,
    quoteBasicItemUpdatePricingType,
    quoteHiddenCostItemUpdatePricingType,
    quoteOptionalItemUpdatePricingType,
    quoteSubItemUpdatePricingType,
    type,
    typeOfPricing,
  ]);

  const linkedIcon = useMemo(() => {
    if (!hasChildren) {
      return null;
    }
    return typeOfPricing === QUOTE_ITEM_TYPE_OF_PRICING.UNIT ? (
      <Box ml={-2.5} mt="7px">
        <Tooltip label={<Trans t={t} i18nKey="quote:quotation.global.unitPricing" />} shouldWrapChildren>
          <GraneetIconButton size="sm" color="ghost" icon="ri-link-m" onClick={handleTypeOfPricingChange} />
        </Tooltip>
      </Box>
    ) : (
      <Box ml={-2.5} mt="7px">
        <Tooltip label={<Trans t={t} i18nKey="quote:quotation.global.flatRatePricing" />} shouldWrapChildren>
          <GraneetIconButton size="sm" color="ghost" icon="ri-link-unlink-m" onClick={handleTypeOfPricingChange} />
        </Tooltip>
      </Box>
    );
  }, [handleTypeOfPricingChange, hasChildren, typeOfPricing, t]);

  return (
    <Flex flexDirection="row" width="100%" height="100%">
      <Flex flexDirection="column" height="200px">
        {linkedIcon}
        {hasChildren && isExpanded && <Flex height="200px">{hasChildren && <TreeIndicator type="simple" />}</Flex>}
      </Flex>
      <VStack
        height="100%"
        justifyContent="center"
        alignItems="flex-start"
        pr={2}
        pl={`${level * 0.313}rem`}
        gap="12px"
        width="100%"
      >
        <HStack fontSize="14px" color={color} width="100%" alignItems="flex-start">
          {type === 'QuoteOptionalItem' && (
            <Flex mx={2} mt="12px">
              <OptionBadge>{t('quote:option.title')}</OptionBadge>
            </Flex>
          )}
          <Box>
            {displayWarning ? (
              <Warning align="flex-start" />
            ) : (
              <Flex gap="12px" height={denominationHeight} overflow="hidden" mt="12px">
                <RichText value={item.denomination ?? ''} />
                {quoteRowHeight === 'sm' && item.note && <i className="ri-sticky-note-line" />}
              </Flex>
            )}
          </Box>
          {itemComponent && (
            <Flex ml="auto" mt="9px">
              <Box maxWidth="200px">
                <GraneetBadge color={itemComponent.color}>{itemComponent.name}</GraneetBadge>
              </Box>
            </Flex>
          )}
        </HStack>
        {quoteRowHeight !== 'sm' && item.note && (
          <HStack height={quoteRowHeight === 'md' ? '15px' : '30px'} gap={0} overflow="hidden">
            <Flex alignSelf="stretch" alignItems="flex-start" pb="0.5rem" color="#6C737F" fontSize="0.75rem">
              <RichText value={item.note} />
            </Flex>
          </HStack>
        )}
      </VStack>
    </Flex>
  );
};
