import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { isEmail } from '@graneet/business-logic';

import { isRequired } from './validation.util';

const VALIDATE_EMAIL = (value: unknown) => !isRequired(value) || isEmail(value);

export const IsEmail = () => {
  const { t } = useTranslation(['global']);

  return <Rule message={t('global:form.errors.invalidEmail')} validationFn={VALIDATE_EMAIL} />;
};
