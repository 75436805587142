import type { IPaymentMethod, IPaymentMethodResponseDTO } from '@graneet/business-logic';

/**
 * Default payment method first, then descending
 * ids so the more recent payment methods come
 * first
 */
export const sortPaymentMethods = <T extends IPaymentMethodResponseDTO | IPaymentMethod>(paymentMethods: T[]): T[] =>
  paymentMethods.sort((left, right) => {
    if (left.isDefault) {
      return -1;
    }
    if (right.isDefault) {
      return 1;
    }
    return right.id - left.id;
  });
