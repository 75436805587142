import { createContext, useContext } from 'react';
import type { UseRadioGroupReturn } from '@chakra-ui/react';

interface IRadioGroupContext {
  getRadioProps: UseRadioGroupReturn['getRadioProps'];

  isDisabled?: boolean;
}
export const RadioGroupContext = createContext<IRadioGroupContext>({
  getRadioProps() {
    return {};
  },

  isDisabled: false,
});

export const useRadioGroupContext = () => useContext(RadioGroupContext);

export const RadioGroupContextProvider = RadioGroupContext.Provider;
