import { Box, Center, HStack } from '@chakra-ui/react';
import type { IProject } from '@graneet/business-logic';
import { MAX_TIMELINE_MONTHS } from '@graneet/business-logic';
import {
  Button,
  capitalizeString,
  Card,
  CustomTooltipChart,
  GroupedBarChart,
  Onboarding,
  SimpleArrowForwardIcon,
  SimpleChevronLeftIcon,
  SimpleDashboardIcon,
} from '@graneet/lib-ui';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { TooltipProps } from 'recharts';
import { ErrorBoundary } from 'react-error-boundary';
import { useIsFetching } from '@tanstack/react-query';

import { dashboard, useDashboardTimelineStats } from '../services/dashboard.api';

import { Error } from 'features/common/components/Error';

export type DashboardTimelineCardProps = {
  /**
   * Number of months to display in the timeline.
   * Defaults to 12.
   */
  months?: number;

  /**
   * Filter datas for a specific project ID
   * If not provided, display stats for all projects which user has access
   * @default undefined
   */
  project?: IProject;

  /**
   * Number of months to increment/decrement the timeline when clicking on the arrows
   * @default 3
   */
  monthsIncrement?: number;
};

const FIELD_SHOULD_BE_DETAILED = 'purchaseAmountExVAT';

const DashboardTimelineCardInternal: FC<DashboardTimelineCardProps & { slicePosition: number }> = ({
  months = 12,
  project,
  monthsIncrement = 6,
  slicePosition,
}) => {
  const { t } = useTranslation(['dashboard']);
  const history = useHistory();

  const dashboardTimelineStatsQuery = useDashboardTimelineStats({ projectId: project?.id });
  const dashboardTimelineStats = dashboardTimelineStatsQuery.data;

  /**
   * Format data for the graph
   */
  const displayData = useMemo(
    () =>
      dashboardTimelineStats.timelineItems.slice(
        dashboardTimelineStats.timelineItems.length - slicePosition * monthsIncrement - months,
        dashboardTimelineStats.timelineItems.length - slicePosition * monthsIncrement,
      ),
    [dashboardTimelineStats, slicePosition, months, monthsIncrement],
  );

  /**
   * Register workforces amount for each month to display it in the tooltip
   */
  const detailedAmounts = useMemo(() => {
    const amounts: Record<string, { workforceAmountExVAT: number; supplierInvoicesAmountExVAT: number }> = {};
    dashboardTimelineStats.timelineItems?.forEach(
      ({ firstDayOfMonth, workforceAmountExVAT, supplierInvoicesAmountExVAT }) => {
        amounts[dayjs(firstDayOfMonth).toDate().toISOString()] = {
          workforceAmountExVAT,
          supplierInvoicesAmountExVAT,
        };
      },
    );
    return amounts;
  }, [dashboardTimelineStats]);

  /**
   * Legends for the graph
   */
  const legends = useMemo(
    () => ({
      saleAmountExVAT: t('dashboard:timelineGraph.saleAmountExVAT'),
      purchaseAmountExVAT: t('dashboard:timelineGraph.purchaseAmountExVAT'),
      supplierInvoices: t('dashboard:timelineGraph.supplierInvoices'),
      workforce: t('dashboard:timelineGraph.workforce'),
    }),
    [t],
  );

  /**
   * Processing the tooltip content
   */
  const tooltip = useCallback(
    ({ payload, active, label }: TooltipProps<number | string, string>) => {
      const processedPayload: Array<{ name?: string; value?: number | string; textColor?: string }> = [
        ...(payload || []),
      ];
      if (payload?.find(({ dataKey }) => dataKey === FIELD_SHOULD_BE_DETAILED)) {
        processedPayload.push({
          name: 'supplierInvoices',
          value: detailedAmounts[label].supplierInvoicesAmountExVAT,
          textColor: 'gray.500',
        });
        processedPayload.push({
          name: 'workforce',
          value: detailedAmounts[label].workforceAmountExVAT,
          textColor: 'gray.500',
        });
      }
      return (
        <CustomTooltipChart
          payload={processedPayload}
          active={active}
          legends={legends}
          label={capitalizeString(
            dayjs(label).toDate().toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
            }),
          )}
        />
      );
    },
    [detailedAmounts, legends],
  );

  /**
   * When user click on the "Create supplier invoice" button in the empty state
   */
  const handleCreateSupplierInvoice = useCallback(() => {
    if (project) {
      history.push(`/projects/${project.id}/purchases/supplier-invoices/create`);
    } else {
      history.push('/purchases/supplier-invoices/create');
    }
  }, [history, project]);

  /**
   * When user click on the "Create invoice" button in the empty state
   */
  const handleCreateStatement = useCallback(() => {
    history.push(`/projects/${project?.id}/statements`);
  }, [history, project?.id]);

  if (dashboardTimelineStats.isEmpty) {
    return (
      <Onboarding
        icon={
          <Box
            p={2}
            boxSize={36}
            borderRadius="50%"
            bg="gray.100"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SimpleDashboardIcon boxSize={45} />
          </Box>
        }
        action={
          <HStack gap={6}>
            <Button
              variant="ghost"
              colorScheme="gray"
              leftIcon={<SimpleArrowForwardIcon />}
              onClick={handleCreateStatement}
            >
              {t('dashboard:timelineGraph.createInvoice')}
            </Button>
            <Button
              variant="ghost"
              colorScheme="gray"
              leftIcon={<SimpleArrowForwardIcon />}
              onClick={handleCreateSupplierInvoice}
            >
              {t('dashboard:timelineGraph.createSupplierInvoice')}
            </Button>
          </HStack>
        }
      >
        <Center fontSize="sm" mb={6}>
          {t('dashboard:noDataAvailable')}
        </Center>
      </Onboarding>
    );
  }

  return (
    <Box h="25rem">
      <GroupedBarChart
        data={displayData}
        columns={[
          {
            name: 'saleAmountExVAT',
            color: 'green.300',
          },
          {
            name: 'purchaseAmountExVAT',
            color: 'red.200',
          },
        ]}
        xAxis={{
          dataKey: 'firstDayOfMonth',
          typeFormat: 'date',
        }}
        yAxis={{
          typeFormat: 'money',
        }}
        tooltip={tooltip}
        legends={legends}
      />
    </Box>
  );
};

export const DashboardTimelineCard: FC<DashboardTimelineCardProps> = ({
  months = 12,
  project,
  monthsIncrement = 6,
}) => {
  const { t } = useTranslation(['dashboard']);

  const isFetching = useIsFetching(dashboard.timelineStats({ projectId: project?.id }));

  const [slicePosition, setSlicePosition] = useState(0);

  /**
   * Increment/decrement the timeline period
   * @param incrementFactor -1 to decrement, 1 to increment
   */
  const changeTimelinePeriod = useCallback(
    (incrementFactor: -1 | 1) => {
      setSlicePosition((prev) => prev + incrementFactor * -1); // need to invert the factor because we are slicing the array from the end
    },
    [setSlicePosition],
  );

  return (
    <Card
      title={t('dashboard:timelineGraph.invoicedVsSpent')}
      topRightContent={
        isFetching === 0 && (
          <Box>
            <Button
              onClick={() => changeTimelinePeriod(-1)}
              size="xs"
              variant="outline"
              p={1}
              isDisabled={slicePosition === MAX_TIMELINE_MONTHS / (monthsIncrement ?? 0) - 2}
            >
              <SimpleChevronLeftIcon />
            </Button>
            <Button
              onClick={() => changeTimelinePeriod(1)}
              size="xs"
              variant="outline"
              p={1}
              ml={1}
              isDisabled={slicePosition === 0}
            >
              <SimpleChevronLeftIcon transform="rotate(180deg)" />
            </Button>
          </Box>
        )
      }
    >
      <ErrorBoundary fallback={<Error />}>
        <DashboardTimelineCardInternal
          slicePosition={slicePosition}
          monthsIncrement={monthsIncrement}
          months={months}
          project={project}
        />
      </ErrorBoundary>
    </Card>
  );
};
