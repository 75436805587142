import type {
  ILibraryJob,
  ILibraryJobComponent,
  ILibraryJobComponentUpdateDTO,
  ILibraryJobCreationDTO,
  ILibraryJobDuplicateDTO,
  ILibraryJobLibraryComponentsImportDTO,
  ILibraryJobResponseDTO,
  ILibraryJobUpdateDTO,
  ILibraryJobWithRelations,
  ILibraryJobsImportDTO,
  ILibraryComponent,
} from '@graneet/business-logic';
import type { PaginatedResponse, UsePaginationOptions } from '@graneet/lib-ui';
import { useToast, usePaginationQuery } from '@graneet/lib-ui';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { type QueryClient, useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { apiNew } from 'features/api/services/apiNew.service';

export const LIBRARY_JOB_FACTORY_NAME = 'library-job';

async function invalidAllLibraryJobQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [LIBRARY_JOB_FACTORY_NAME],
  });
}

const LIBRARY_JOB_PATH = '/library-jobs';
const LIBRARY_JOB_COMPONENT_PATH = '/library-job-components';

const libraryJobKeyFactory = createQueryKeys(LIBRARY_JOB_FACTORY_NAME, {
  get: (queryParams: URLSearchParams) => ({
    queryKey: [LIBRARY_JOB_PATH, queryParams],
    queryFn: () =>
      apiNew.get<URLSearchParams, PaginatedResponse<ILibraryJobResponseDTO>>(
        `${LIBRARY_JOB_PATH}/paginated`,
        queryParams,
      ),
  }),
  getOne: (id: number) => ({
    queryKey: [LIBRARY_JOB_PATH, id],
    queryFn: () => apiNew.get<never, ILibraryJobResponseDTO>(`${LIBRARY_JOB_PATH}/${id}`),
  }),
  getOneComponents: (id: number) => ({
    queryKey: [LIBRARY_JOB_COMPONENT_PATH, id],
    queryFn: () => apiNew.get<never, ILibraryJobComponent[]>(`${LIBRARY_JOB_COMPONENT_PATH}/library-job/${id}`),
  }),
});

// -- Query
export function useLibraryJobs(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) => queryClient.fetchQuery(libraryJobKeyFactory.get(urlSearchParams)),
    options,
  );
}

export function useLibraryJobsQuery(
  queryParams: URLSearchParams,
  options?: {
    enabled: boolean;
  },
) {
  return useQuery({
    ...libraryJobKeyFactory.get(queryParams),
    ...options,
  });
}

export function useLibraryJob(id: number) {
  return useSuspenseQuery(libraryJobKeyFactory.getOne(id));
}

export function useLibraryJobComponents(id: number) {
  return useSuspenseQuery(libraryJobKeyFactory.getOneComponents(id));
}

// -- Mutation
export function useLibraryJobCreate() {
  const { t } = useTranslation(['global', 'library']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ILibraryJobCreationDTO) =>
      apiNew.post<ILibraryJobCreationDTO, ILibraryJob>(LIBRARY_JOB_PATH, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('library:success.createJob'));
      await invalidAllLibraryJobQueries(queryClient);
    },
  });
}

export function useLibraryJobImportBatch() {
  const { t } = useTranslation(['library']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ILibraryJobsImportDTO) =>
      apiNew.post<ILibraryJobsImportDTO, ILibraryJob[]>(`${LIBRARY_JOB_PATH}/batch`, dto),
    onError: () => {
      toast.error(t('library:errors.importJobs'));
    },
    onSuccess: async () => {
      toast.success(t('library:success.importJobs'));
      await invalidAllLibraryJobQueries(queryClient);
    },
  });
}

export function useLibraryJobDuplicate() {
  const { t } = useTranslation(['global', 'library']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { duplicatedId: number; dto: ILibraryJobDuplicateDTO }) =>
      apiNew.post<ILibraryJobDuplicateDTO, ILibraryJob>(
        `${LIBRARY_JOB_PATH}/${params.duplicatedId}/duplicate`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('library:success.duplicateJob'));
      await invalidAllLibraryJobQueries(queryClient);
    },
  });
}

export function useLibraryJobUpdate() {
  const { t } = useTranslation(['global', 'library']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: ILibraryJobUpdateDTO }) =>
      apiNew.patch<ILibraryJobUpdateDTO, ILibraryJob>(`${LIBRARY_JOB_PATH}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidAllLibraryJobQueries(queryClient);
    },
  });
}

export function useLibraryJobDelete() {
  const { t } = useTranslation(['global', 'library']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, ILibraryJob>(`${LIBRARY_JOB_PATH}/${id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('library:success.removeJob'));
      await invalidAllLibraryJobQueries(queryClient);
    },
  });
}

export function useLibraryJobAddFiles() {
  const { t } = useTranslation(['global', 'library']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; files: File[] }) => {
      const formData = new FormData();
      params.files.forEach((file) => {
        formData.append('libraryJobFiles', file, encodeURIComponent(file.name));
      });

      return apiNew.putWithMultiPart<FormData, ILibraryJobWithRelations>(
        `${LIBRARY_JOB_PATH}/${params.id}/files`,
        formData,
      );
    },
    onError: () => {
      toast.error(t('library:errors.uploadJobFileError'));
    },
    onSuccess: async () => {
      await invalidAllLibraryJobQueries(queryClient);
    },
  });
}

export function useLibraryJobDeleteFiles() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; fileId: string | number }) =>
      apiNew.delete<never, void>(`${LIBRARY_JOB_PATH}/${params.id}/file/${params.fileId}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidAllLibraryJobQueries(queryClient);
    },
  });
}

export function useLibraryJobImportComponents() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: ILibraryJobLibraryComponentsImportDTO }) =>
      apiNew.post<ILibraryJobLibraryComponentsImportDTO, ILibraryJobWithRelations>(
        `${LIBRARY_JOB_PATH}/${params.id}/import`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidAllLibraryJobQueries(queryClient);
    },
  });
}

export function useLibraryJobUpdateComponents() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; componentId: number; dto: ILibraryJobComponentUpdateDTO }) =>
      apiNew.patch<ILibraryJobComponentUpdateDTO, ILibraryJobComponent>(
        `${LIBRARY_JOB_PATH}/${params.id}/library-components/${params.componentId}`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidAllLibraryJobQueries(queryClient);
    },
  });
}

export function useLibraryJobDeleteComponents() {
  const { t } = useTranslation(['global', 'library']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; component: ILibraryComponent }) =>
      apiNew.delete<never, ILibraryJobComponent>(
        `${LIBRARY_JOB_PATH}/${params.id}/library-components/${params.component.id}`,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, params) => {
      await invalidAllLibraryJobQueries(queryClient);
      toast.success(t('library:success.removeLinkJobComponent', { componentName: params.component.description }));
    },
  });
}
