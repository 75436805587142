import type { IProject, IProjectFinancialSummary } from '@graneet/business-logic';
import { EllipsisText } from '@graneet/lib-ui';
import { Box } from '@chakra-ui/react';
import type { FC } from 'react';

import { TagsBadges } from 'features/common/components/TagsBadges';

// @[ff: project-financial-summary]
export const ProjectTableNameCell: FC<{ data?: IProjectFinancialSummary | IProject }> = ({ data }) => (
  <Box>
    <EllipsisText>{data?.name}</EllipsisText>
    {data?.tags && data.tags.length > 0 && (
      <Box mb={2}>
        <TagsBadges tags={data?.tags} />
      </Box>
    )}
  </Box>
);
