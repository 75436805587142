import { useParams } from 'react-router-dom';
import { type FC, useState } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { CurrencyProvider } from '@graneet/lib-ui';

import { ProgressStatementExternalRejectionScreen } from './ProgressStatementExternalRejectionScreen';
import { ProgressStatementExternalApproveScreen } from './ProgressStatementExternalApproveScreen';

import { ProgressStatementExternalHeader } from 'features/progress-statement/components/ProgressStatementExternalHeader';
import { ProgressStatementExternalInitialModal } from 'features/progress-statement/components/ProgressStatementExternalInitialModal';
import { useExternalProgressStatement } from 'features/progress-statement/services/external-progress-statement.api';

export const ProgressStatementExternalScreen: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const externalProgressStatement = useExternalProgressStatement(externalId);

  const modalInitial = useDisclosure({
    defaultIsOpen:
      externalProgressStatement.data && !externalProgressStatement.data.progressStatement.externalValidation,
  });

  const [currentScreen, setCurrentScreen] = useState<'approve' | 'reject'>('approve');

  return (
    <CurrencyProvider value={externalProgressStatement.data.currency}>
      <ProgressStatementExternalInitialModal
        isOpen={modalInitial.isOpen}
        onClose={modalInitial.onClose}
        company={externalProgressStatement.data.company}
        project={externalProgressStatement.data.project}
      />

      <Flex h="100vh" w="100vw" backgroundColor="gray.50" direction="column">
        <ProgressStatementExternalHeader
          progressStatement={externalProgressStatement.data.progressStatement}
          company={externalProgressStatement.data.company}
        />

        <Flex flex={1} h={0} alignItems="stretch">
          {currentScreen === 'approve' && (
            <ProgressStatementExternalApproveScreen
              externalProgressStatement={externalProgressStatement.data}
              externalId={externalId}
              onGoToReject={() => setCurrentScreen('reject')}
            />
          )}
          {currentScreen === 'reject' && (
            <ProgressStatementExternalRejectionScreen
              externalProgressStatement={externalProgressStatement.data}
              externalId={externalId}
              onGoToApprove={() => setCurrentScreen('approve')}
            />
          )}
        </Flex>
      </Flex>
    </CurrencyProvider>
  );
};
