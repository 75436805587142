import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Grid, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { Button, Card, EMPTY_SIGN, Input, LabeledData, Modal, RadioGroup, SimpleEditIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICompanyPaymentTermsUpdateDTO } from '@graneet/business-logic';
import { DEADLINE_TYPE } from '@graneet/business-logic';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface PaymentDeadlineCardProps {
  paymentInfos: { deadlineType: DEADLINE_TYPE; paymentTerm: number } | null;

  onPaymentInfosUpdate(paymentInfos: ICompanyPaymentTermsUpdateDTO): Promise<unknown>;

  onPaymentInfosUpdated?(): Promise<void> | void;

  modalTitle?: string;

  hasUpdatePermission?: boolean;
}

export const PaymentDeadlineCard: FC<PaymentDeadlineCardProps> = ({
  paymentInfos,
  onPaymentInfosUpdate,
  onPaymentInfosUpdated,
  modalTitle,
  hasUpdatePermission = true,
}) => {
  const { t } = useTranslation(['paymentMethods', 'global']);
  const deadlineModal = useDisclosure();
  const paymentDeadlineUpdateButtonDisabledProps = useDisabledButtonProps(undefined, hasUpdatePermission);

  const [deadlineType, setDeadlineType] = useState<DEADLINE_TYPE | undefined>();
  const [paymentTerm, setPaymentTerm] = useState<number | undefined>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!paymentInfos) return;
    if (!deadlineType && paymentInfos.deadlineType !== deadlineType) {
      setDeadlineType(paymentInfos.deadlineType);
    }
    if (!Number.isFinite(paymentTerm) && paymentInfos.paymentTerm !== paymentTerm) {
      setPaymentTerm(paymentInfos.paymentTerm);
    }
  }, [deadlineType, paymentInfos, paymentTerm]);

  const hasDeadlineChanged = useCallback(
    () => paymentInfos?.deadlineType !== deadlineType || paymentInfos?.paymentTerm !== paymentTerm,
    [deadlineType, paymentInfos?.deadlineType, paymentInfos?.paymentTerm, paymentTerm],
  );

  const onSaveClicked = useCallback(async () => {
    if (hasDeadlineChanged()) {
      setIsSaving(true);
      await onPaymentInfosUpdate({ deadlineType, paymentTerm });
      setIsSaving(false);
      onPaymentInfosUpdated?.();
    }
    deadlineModal.onClose();
  }, [hasDeadlineChanged, deadlineModal, onPaymentInfosUpdate, deadlineType, paymentTerm, onPaymentInfosUpdated]);

  const updatePaymentTerm = useCallback((value: DEADLINE_TYPE) => {
    const newPaymentTerm = parseInt(value, 10);
    setPaymentTerm(Number.isFinite(newPaymentTerm) ? newPaymentTerm : 0);
  }, []);

  return (
    <Card
      title={t('paymentMethods:paymentDeadline')}
      topRightContent={
        <Button
          variant="outline"
          leftIcon={<SimpleEditIcon />}
          onClick={deadlineModal.onOpen}
          {...paymentDeadlineUpdateButtonDisabledProps(!paymentInfos)}
        >
          {t('global:words.c.update')}
        </Button>
      }
    >
      <Grid mt={3} alignItems="flex-start" templateColumns="repeat(2, 1fr)" gap={6}>
        <LabeledData
          label={t('paymentMethods:field.paymentTerm')}
          data={paymentInfos ? t('global:duration.days', { duration: paymentInfos.paymentTerm }) : EMPTY_SIGN}
        />
        <LabeledData
          label={t('paymentMethods:field.deadlineType')}
          data={paymentInfos ? t(`paymentMethods:deadline.type.${paymentInfos.deadlineType}`) : EMPTY_SIGN}
        />
      </Grid>

      <Modal autoFocus={false} title={modalTitle || t('paymentMethods:deadline.modal.update.title')} {...deadlineModal}>
        <Stack spacing={4}>
          <HStack alignItems="flex-end" w="12rem">
            <Input label={t('paymentMethods:field.paymentTerm')} value={paymentTerm} onChange={updatePaymentTerm} />
            <Text>{t('global:words.day_plural')}</Text>
          </HStack>

          <LabeledData
            label={t('paymentMethods:deadline.label')}
            data={
              <RadioGroup
                name="deadlineType"
                mt={2}
                value={deadlineType}
                onChange={setDeadlineType as (value: string) => void}
              >
                <RadioGroup.Option
                  value={DEADLINE_TYPE.AFTER_TERM}
                  label={t(`paymentMethods:deadline.type.${DEADLINE_TYPE.AFTER_TERM}`)}
                />
                <RadioGroup.Option
                  value={DEADLINE_TYPE.END_OF_MONTH}
                  label={t(`paymentMethods:deadline.type.${DEADLINE_TYPE.END_OF_MONTH}`)}
                />
              </RadioGroup>
            }
          />
        </Stack>

        <Modal.Close />

        <Modal.PrimaryButton isLoading={isSaving} onClick={onSaveClicked}>
          {t('global:words.c.save')}
        </Modal.PrimaryButton>
      </Modal>
    </Card>
  );
};
