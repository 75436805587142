import { Box, VStack, Text, type IconProps, HStack } from '@chakra-ui/react';
import { cloneElement, useState, type FC, type ReactElement } from 'react';

import { BatiprixIcon } from '../../Icons/BatiprixIcon';
import { SimpleAddFolderIcon } from '../../Icons/v2/SimpleAddFolderIcon';
import { SimpleDownloadIcon } from '../../Icons/v2/SimpleDownloadIcon';

import { emptyStateTranslations, configureDefaultLabels } from './EmptyStateTranslations';

export type EmptyStateColor = 'blue' | 'green' | 'purple' | 'white';

export interface EmptyStateProps {
  color?: EmptyStateColor;
  disabled?: boolean;
  icon: ReactElement<IconProps>;
  shortcut?: string;
  onClick?: () => void;
  title?: string;
  subtitle?: string;
  description?: string;
}

const COLORS_CONFIG = {
  blue: {
    border: '1px solid #B0E2FF',
    background: '#EDF8FF',
    color: '#109CF1',
    hoverBackground: '#DCF4FF',
  },
  green: {
    border: '1px solid #D3E8E2',
    background: '#F0FBF8',
    color: '#11949C',
    hoverBackground: '#EBF5F2',
  },
  purple: {
    border: '1px solid #E9D7FE',
    background: '#F9F5FF',
    color: '#9E77ED',
    hoverBackground: '#F4EBFF',
  },
  white: {
    border: '1px solid rgba(0, 19, 58, 0.10)',
    background: '#FFF',
    color: '#6C737F',
    hoverBackground: '#F9FAFB',
  },
} as const;

const EmptyStateCardComponent: FC<EmptyStateProps> = ({
  color = 'white',
  icon,
  disabled = false,
  title,
  subtitle,
  description,
  shortcut,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const { border, background, color: iconColor, hoverBackground } = COLORS_CONFIG[color];

  const iconElement = cloneElement(icon, { boxSize: '1rem', stroke: iconColor });
  const iconBackgroundElement = cloneElement(icon, {
    boxSize: '6rem',
    stroke: iconColor,
    transform: isHovered ? 'rotate(30.353deg)' : 'rotate(30.353deg)',
    opacity: 0.1,
  });

  return (
    <Box
      // eslint-disable-next-line no-nested-ternary
      cursor={disabled ? 'not-allowed' : onClick ? 'pointer' : undefined}
      borderRadius="0.375rem"
      border={border}
      background={background}
      color="#1F2A37"
      minWidth="15.625rem"
      width="17.313rem"
      overflow="hidden"
      _hover={{
        background: hoverBackground,
      }}
      transitionProperty="background"
      transitionDuration="0.3s"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      p="0.75rem"
    >
      <VStack gap="0.5rem" position="relative" alignItems="start">
        <HStack gap="0.75rem" width="100%">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="0.75rem"
            backgroundColor="#FFF"
            height="2.25rem"
            width="2.25rem"
            boxShadow="0px 2px 4px 0px rgba(17, 24, 28, 0.12), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px rgba(17, 24, 28, 0.08)"
          >
            {iconElement}
          </Box>
          {title && (
            <Text fontSize="0.813rem" fontWeight={500} color="#1F2A37" textOverflow="ellipsis">
              {title}
            </Text>
          )}
          {shortcut && (
            <Text fontSize="0.688rem" color="#9DA4AE" ml="auto" fontWeight={500}>
              {shortcut}
            </Text>
          )}
        </HStack>
        {subtitle && (
          <Text fontSize="0.813rem" fontWeight={500}>
            {subtitle}
          </Text>
        )}
        {description && (
          <Text fontSize="0.75rem" color="#4D5761" fontWeight={400}>
            {description}
          </Text>
        )}
        <Box
          position="absolute"
          right="-1.4rem"
          bottom="-2rem"
          opacity={isHovered ? 1 : 0}
          transitionProperty="all"
          transitionDuration="0.2s"
          transform={isHovered ? 'scale(1.2)' : 'scale(1)'}
        >
          {iconBackgroundElement}
        </Box>
      </VStack>
    </Box>
  );
};

export const EmptyStateCard = Object.assign(EmptyStateCardComponent, {
  configureDefaultLabels,
});

export const ImportFromQuoteEmptyState: FC<Pick<EmptyStateProps, 'onClick' | 'disabled'>> = ({ onClick, disabled }) => (
  <EmptyStateCard
    onClick={onClick}
    disabled={disabled}
    icon={<SimpleDownloadIcon />}
    subtitle={emptyStateTranslations.quote.subtitle}
    description={emptyStateTranslations.quote.description}
    color="purple"
  />
);

export const ImportFromBatiprixEmptyState: FC<Pick<EmptyStateProps, 'onClick' | 'disabled'>> = ({
  onClick,
  disabled,
}) => (
  <EmptyStateCard
    onClick={onClick}
    disabled={disabled}
    icon={<BatiprixIcon />}
    subtitle={emptyStateTranslations.batiprix.subtitle}
    description={emptyStateTranslations.batiprix.description}
    color="green"
  />
);

export const ImportFromLibraryEmptyState: FC<Pick<EmptyStateProps, 'onClick' | 'disabled'>> = ({
  onClick,
  disabled,
}) => (
  <EmptyStateCard
    onClick={onClick}
    disabled={disabled}
    icon={<SimpleAddFolderIcon />}
    subtitle={emptyStateTranslations.library.subtitle}
    description={emptyStateTranslations.library.description}
    color="blue"
  />
);
