import type { FC } from 'react';
import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';

import { usePaginationFilters } from '../hooks/filters-from-url.hook';
import { SortingContextProvider } from '../contexts/SortingContext.context';
import { usePaginatedDataContext } from '../../PaginatedData/hooks';
import { RowContext } from '../contexts/RowContext';

import type { CellProps, CellVariant, HeaderCellProps } from './Cell/Cell.type';

const baseHeaderStyles = {
  borderBottom: '1px',
  borderColor: '#E5E7E9',
  fontWeight: '400',
  color: 'greenBrand.lightText',
  fontFamily: 'heading',
  fontSize: '14px',
  px: 2,
  _first: { paddingLeft: 4 },
  _last: { paddingRight: 4 },
};

export const Header: FC<HeaderCellProps> = ({ children, ...styleProps }) => {
  const paginatedDataContext = usePaginatedDataContext();

  const currentSorting = usePaginationFilters(paginatedDataContext ? paginatedDataContext.storage : null);

  const sortingContext = useMemo(
    () => ({
      onSorting: paginatedDataContext ? paginatedDataContext.updateSorting : undefined,
      currentSorting,
    }),
    [paginatedDataContext, currentSorting],
  );

  const rowContext = useMemo<CellProps<CellVariant>>(
    () => ({ ...baseHeaderStyles, ...styleProps, variant: 'header' }),
    [styleProps],
  );

  return (
    <SortingContextProvider value={sortingContext}>
      <RowContext.Provider value={rowContext}>
        <Box display="contents">{children}</Box>
      </RowContext.Provider>
    </SortingContextProvider>
  );
};
