import type { ReactNode } from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { DraggableItem, DropEffect, SegmentedDropZone } from '@graneet/lib-ui';
import { Box } from '@chakra-ui/react';

import { useContractLot, useContractTreeContext } from '../hooks/tree.hooks';
import { CONTRACT_ENTITY_TYPES } from '../constants/contracts.constant';
import type { ContractDnDItem } from '../types/contract-dnd.type';
import { TreeDataPreCalculation } from '../../common/services/treeDataPreCalculation/treeDataPreCalculation.util';

import { useStore } from 'store/store';
import { useMemoOnTime } from 'features/common/hooks/useMemoOnTime';

interface ContractLotWrapperProps {
  id: string | number;

  depth: number;

  children: ReactNode;
}

export const ContractLotWrapper = memo<ContractLotWrapperProps>(({ id, depth, children }) => {
  const memoOnTime = useMemoOnTime(1000);

  const {
    state: { isDeleted },
  } = useContractLot(id);

  const { getDisplayedCurrentTree, moveNode, isNodeAfterNode, isNodeDescendingChildrenOfNode, getCurrentTree } =
    useContractTreeContext();

  const dndItem = useMemo<ContractDnDItem>(() => ({ id }), [id]);
  const rootLot = useMemo(() => getDisplayedCurrentTree().rootNodeId, [getDisplayedCurrentTree]);
  const isRootLot = useMemo(() => id === rootLot, [id, rootLot]);

  const canDropOnLotAfter = useCallback(
    (lotDrag: ContractDnDItem) => {
      const isNodeBefore = memoOnTime(`isNodeAfterNode-${id}-${lotDrag.id}`, () => isNodeAfterNode(id, lotDrag.id));
      const isNodeChildren = memoOnTime(`isNodeDescendingChildrenOfNode-${lotDrag.id}-${id}`, () =>
        isNodeDescendingChildrenOfNode(id, lotDrag.id),
      );
      return !isNodeBefore && !isNodeChildren;
    },
    [id, isNodeAfterNode, isNodeDescendingChildrenOfNode, memoOnTime],
  );

  const onLotDropAfter = useCallback(
    (newNextLot: ContractDnDItem) => {
      const { parentNodeId } = getDisplayedCurrentTree().nodes[id];
      moveNode(newNextLot.id, parentNodeId as string | number, id, 'after');
    },
    [getDisplayedCurrentTree, id, moveNode],
  );

  const autoNumberingSetTable = useStore((state) => state.autoNumberingSetTable);
  const optionalLotsSetTable = useStore((state) => state.optionalLotsSetTable);
  const optionalLotsSetTableWithoutJob = useStore((state) => state.optionalLotsSetTableWithoutJob);
  const contractDepthSetMaxDepth = useStore((state) => state.contractDepthSetMaxDepth);

  useEffect(() => {
    const currentTree = getCurrentTree();

    const treeDataPreCalculation = new TreeDataPreCalculation(currentTree.relations, currentTree.leaves);
    autoNumberingSetTable(treeDataPreCalculation.autoNumberingTable);
    optionalLotsSetTable(treeDataPreCalculation.optionalLotsTable);
    optionalLotsSetTableWithoutJob(treeDataPreCalculation.optionalLotsWithoutJobInTheseChildren);
    contractDepthSetMaxDepth(treeDataPreCalculation.maxDepth);
  }, [
    getCurrentTree,
    autoNumberingSetTable,
    rootLot,
    optionalLotsSetTable,
    optionalLotsSetTableWithoutJob,
    contractDepthSetMaxDepth,
  ]);

  return (
    <Box ml={depth === 1 ? 0 : 2}>
      <DraggableItem type={CONTRACT_ENTITY_TYPES.LOT} item={dndItem} disabled={isRootLot || isDeleted}>
        <SegmentedDropZone id={id} accept={CONTRACT_ENTITY_TYPES.LOT}>
          <SegmentedDropZone.Segment
            weight={1}
            effect={DropEffect.CursorBottom}
            canDrop={canDropOnLotAfter}
            onDrop={onLotDropAfter}
          />

          {children}
        </SegmentedDropZone>
      </DraggableItem>
    </Box>
  );
});
