import type { SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useCurrency } from '@graneet/lib-ui';

import type { BankingCreatePaymentModalProps } from '../../banking/components/BankingCreatePaymentModal';
import { BankingCreatePaymentModal } from '../../banking/components/BankingCreatePaymentModal';
import { useSupplierInvoiceAddPayment } from '../services/supplier-invoice.api';

import { SupplierInvoiceDocumentStatusCard } from './SupplierInvoiceDocumentStatusCard';

interface SupplierInvoicePaymentModalProps {
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;

  isOpen: boolean;

  onClose: () => void;
}

export const SupplierInvoicePaymentModal: FC<SupplierInvoicePaymentModalProps> = ({
  supplierInvoice,
  onClose,
  isOpen,
}) => {
  const { mapAmountToNumber } = useCurrency();
  // const currentUser = useCurrentUser();

  // const powensCreatePaymentsMutation = usePowensCreatePayments();
  const supplierInvoiceAddPaymentMutation = useSupplierInvoiceAddPayment();

  const onSubmit = useCallback<BankingCreatePaymentModalProps['onSubmit']>(
    async (formValues) => {
      const amount = mapAmountToNumber(formValues.amount);
      // TODO @[ff: banking-account-connection] dirty mock to public presentation
      /* await powensCreatePaymentsMutation.mutateAsync({
        iban: formValues.recipientIban,
        powensPaymentData: {
          connector_uuid: '338178e6-3d01-564f-9a7b-52ca442459bf',
          payer_identity: {
            kind: PowensIdentityKind.CORPORATE,
            org_name: currentUser.data.user.company.name,
            org_legal_status: currentUser.data.user.company.kycOrgLegalStatus!,
            org_hq_address: currentUser.data.user.company.kycAddress!,
            scheme_name: PowensIdentitySchemeName.INCORPORATION_NUMBER,
            identification: currentUser.data.user.company.kycIdentification!,
          },
          instructions: [
            {
              amount: formValues.amount,
              currency: 'EUR',
              label: formValues.label,
              execution_date_type: PowensPaymentExecutionDateType.INSTANT,
              execution_date: new Date(),
              beneficiary: {
                scheme_name: PowensPaymentAccountSchemeName.IBAN,
                identification: formValues.recipientIban,
                label: 'ché pa',
              },
            },
          ],
        },
      }); */

      await supplierInvoiceAddPaymentMutation.mutateAsync({
        supplierInvoiceId: supplierInvoice.id,
        dto: {
          amountPaidIncVAT: amount,
          paidAt: formValues.date,
          accountingConfigBankAccountId: undefined,
        },
      });
    },
    [mapAmountToNumber, supplierInvoice.id, supplierInvoiceAddPaymentMutation],
  );

  return (
    <BankingCreatePaymentModal
      isOpen={isOpen}
      onClose={onClose}
      editedEntityPreview={<SupplierInvoiceDocumentStatusCard supplierInvoice={supplierInvoice} />}
      onSubmit={onSubmit}
      defaultValues={{
        amount: supplierInvoice.remainingToBePaidIncVAT,
        recipientIban: supplierInvoice.supplier?.iban ?? '',
        recipientName: supplierInvoice.supplier?.name ?? '',
      }}
    />
  );
};
