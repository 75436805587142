import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import type { FlexProps, StyleProps } from '@chakra-ui/react';
import { Flex, Box } from '@chakra-ui/react';

import { EllipsisText } from '../EllipsisText';
import { LABELS_COLORS } from '../../utils';

export const COLORS_CONFIG = {
  white: {
    bg: 'white',
    bgDark: 'gray.200',
    bgDarker: 'gray.500',
    colorDark: 'gray.900',
    text: 'primaryLight',
    ghost: 'gray.200',
    highlightColor: 'gray.500',
    borderColor: 'gray.200',
  },
  blue: {
    bg: 'blue.100',
    bgDark: 'blue.500',
    bgDarker: 'blue.800',
    colorDark: 'white',
    text: 'primaryLight',
    ghost: 'blue.100',
    highlightColor: 'blue.600',
    borderColor: 'transparent',
  },
  purple: {
    bg: 'purple.100',
    bgDark: 'purple.200',
    bgDarker: 'purple.500',
    colorDark: 'purple.900',
    text: 'primaryLight',
    ghost: 'purple.600',
    highlightColor: 'purple.900',
    borderColor: 'transparent',
  },
  yellow: {
    bg: 'yellow.100',
    bgDark: 'yellow.200',
    bgDarker: 'yellow.500',
    colorDark: 'yellow.900',
    text: 'primaryLight',
    ghost: 'yellow.600',
    highlightColor: 'yellow.800',
    borderColor: 'transparent',
  },
  green: {
    bg: 'green.100',
    bgDark: 'green.200',
    bgDarker: 'green.500',
    colorDark: 'green.900',
    text: 'primaryLight',
    ghost: 'green.600',
    highlightColor: 'green.900',
    borderColor: 'transparent',
  },
  gray: {
    bg: 'gray.100',
    bgDark: 'gray.200',
    bgDarker: 'gray.500',
    colorDark: 'gray.900',
    text: 'primaryLight',
    ghost: 'gray.300',
    highlightColor: 'gray.800',
    borderColor: 'transparent',
  },
  red: {
    bg: 'red.100',
    bgDark: 'red.200',
    bgDarker: 'red.500',
    colorDark: 'red.900',
    text: 'primaryLight',
    ghost: 'red.100',
    highlightColor: 'red.600',
    borderColor: 'transparent',
  },
  pink: {
    bg: 'pink.100',
    bgDark: 'pink.200',
    bgDarker: 'pink.500',
    colorDark: 'pink.900',
    text: 'primaryLight',
    ghost: 'pink.600',
    highlightColor: 'pink.600',
    borderColor: 'transparent',
  },
  teal: {
    bg: 'teal.100',
    bgDark: 'teal.200',
    bgDarker: 'teal.500',
    colorDark: 'teal.900',
    text: 'primaryLight',
    ghost: 'teal.600',
    highlightColor: 'teal.600',
    borderColor: 'transparent',
  },
  warm_gray: {
    bg: 'gray.200',
    bgDark: 'gray.200',
    bgDarker: 'gray.500',
    colorDark: 'gray.900',
    text: 'primaryLight',
    ghost: 'gray.500',
    highlightColor: 'gray.500',
    borderColor: 'transparent',
  },
  cyan: {
    bg: 'cyan.100',
    bgDark: 'cyan.200',
    bgDarker: 'cyan.500',
    colorDark: 'cyan.900',
    text: 'primaryLight',
    ghost: 'cyan.600',
    highlightColor: 'cyan.600',
    borderColor: 'transparent',
  },
  orange: {
    bg: 'orange.100',
    bgDark: 'orange.200',
    bgDarker: 'orange.500',
    colorDark: 'orange.900',
    text: 'primaryLight',
    ghost: 'orange.600',
    highlightColor: 'orange.600',
    borderColor: 'transparent',
  },
  indigo: {
    bg: 'indigo.100',
    bgDark: 'indigo.500',
    bgDarker: 'indigo.700',
    colorDark: 'white',
    text: 'primaryLight',
    ghost: 'indigo.600',
    highlightColor: 'indigo.600',
    borderColor: 'transparent',
  },
  greenBrand: {
    bg: 'greenBrand.light',
    bgDark: 'greenBrand.medium',
    bgDarker: 'greenBrand.dark',
    colorDark: 'greenBrand.dark',
    text: 'white',
    ghost: 'greenBrand.medium',
    highlightColor: 'greenBrand.medium',
    borderColor: 'transparent',
  },
};

export type ButtonColorScheme = keyof typeof COLORS_CONFIG;

export type BadgeTheme = 'ghost' | 'dark' | 'darker';

export interface BadgeProps extends FlexProps {
  children: ReactNode;
  colorScheme?: ButtonColorScheme;
  color?: string | StyleProps['color'];
  theme?: BadgeTheme;
  action?: ReactNode;
  highlightOnHover?: boolean;
  showDot?: boolean;
}

export const Badge: FC<BadgeProps> = ({
  colorScheme,
  color,
  children,
  theme = undefined,
  action = undefined,
  highlightOnHover = false,
  showDot = false,
  ...props
}) => {
  const colorFromScheme = useMemo(
    () =>
      colorScheme
        ? COLORS_CONFIG[colorScheme]
        : {
            bg: 'blue.100',
            bgDark: 'blue.200',
            bgDarker: 'blue.700',
            colorDark: 'blue.900',
            text: 'blue.800',
            ghost: 'blue.300',
            highlightColor: 'blue.800',
          },
    [colorScheme],
  );

  const [badgeColor, bgColor, borderColor] = useMemo(() => {
    if (color) {
      const { border, text } = LABELS_COLORS[color as keyof typeof LABELS_COLORS];
      if (showDot) return ['primaryLight', text, border];
      return [text, color, border];
    }
    switch (theme) {
      case 'ghost':
        return [
          colorFromScheme.highlightColor,
          'transparent',
          'borderColor' in colorFromScheme ? (colorFromScheme.borderColor as string) : colorFromScheme.ghost,
        ];
      case 'dark':
        return [
          colorFromScheme.colorDark,
          colorFromScheme.bgDark,
          'borderColor' in colorFromScheme ? (colorFromScheme.borderColor as string) : colorFromScheme.bgDark,
        ];
      case 'darker':
        return [
          colorFromScheme.bg,
          colorFromScheme.bgDarker,
          'borderColor' in colorFromScheme ? (colorFromScheme.borderColor as string) : colorFromScheme.bgDarker,
        ];
      default:
        return [
          colorFromScheme.text,
          showDot ? colorFromScheme.bgDarker : colorFromScheme.bg,
          'borderColor' in colorFromScheme ? (colorFromScheme.borderColor as string) : colorFromScheme.bg,
        ];
    }
  }, [colorFromScheme, color, theme, showDot]);

  const borderProps = {
    border: '1px solid',
    borderColor: showDot ? 'gray.200' : borderColor,
  };

  const borderHoverProps = {
    border: '1px solid',
    borderColor: highlightOnHover ? colorFromScheme.highlightColor || colorFromScheme.bg : undefined,
  };

  return (
    <Flex
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={14}
      color={badgeColor}
      bg={showDot ? 'white' : bgColor}
      transitionDuration="0.2s"
      height={5}
      py={0}
      px={2}
      fontSize="0.75rem"
      gap={2}
      {...props}
      minW={5}
      width="auto"
      maxWidth="100%"
      {...borderProps}
      {...(highlightOnHover && { _hover: showDot ? undefined : borderHoverProps })}
    >
      {showDot && (
        <Box fontSize="0.5rem" color={bgColor}>
          <i className="ri-circle-fill" />
        </Box>
      )}
      <EllipsisText>{children}</EllipsisText>
      {action}
    </Flex>
  );
};
