import type { QuoteNodeImportClusterDTO, QuoteNodeImportClustersDTO, QuoteNodesApi } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteNodesProxyApi {
  private static quoteNodesProxyApi: QuoteNodesProxyApi;

  private readonly quoteNodesApi: QuoteNodesApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteNodesApi = externalApi.getQuoteNodesApi();
  }

  public static getQuoteNodesProxyApi(externalApi: ExternalApi): QuoteNodesProxyApi {
    if (!this.quoteNodesProxyApi) {
      QuoteNodesProxyApi.quoteNodesProxyApi = new QuoteNodesProxyApi(externalApi);
    }

    return QuoteNodesProxyApi.quoteNodesProxyApi;
  }

  public async importCluster(quoteNodeImportClusterDTO: QuoteNodeImportClusterDTO) {
    return proxyHelper(async () => this.quoteNodesApi.importCluster(quoteNodeImportClusterDTO));
  }

  public async importClusters(quoteNodeImportClustersDTO: QuoteNodeImportClustersDTO) {
    return proxyHelper(async () => this.quoteNodesApi.importClusters(quoteNodeImportClustersDTO));
  }
}
