import { QUOTE_ROW_HEIGHT } from '@graneet/business-logic';
import { ButtonList } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { useEditUserSettings } from 'features/user/services/user.api';
import { useStore } from 'store/store';

export const QuoteToolBarRowHeightButton = () => {
  const { t } = useTranslation(['quote']);

  const quoteRowHeightStore = useStore((state) => state.quoteRowHeight);
  const editUserSettingsMutation = useEditUserSettings(false);

  const handleRowHeight = async (quoteRowHeight: QUOTE_ROW_HEIGHT) => {
    useStore.setState(() => ({
      quoteRowHeight,
    }));
    await editUserSettingsMutation.mutateAsync({ quoteRowHeight });
  };

  return (
    <ButtonList
      variant="ghost"
      label={t('quote:quotation.toolbar.vue.title')}
      leftIcon={<i className="ri-layout-row-line" />}
      ctaList={[
        {
          rightIcon: quoteRowHeightStore === QUOTE_ROW_HEIGHT.Small ? 'ri-check-line' : '',
          label: t(`quote:quotation.toolbar.vue.${QUOTE_ROW_HEIGHT.Small}`),
          color: 'greenBrand',
          onClick: () => handleRowHeight(QUOTE_ROW_HEIGHT.Small),
        },
        {
          rightIcon: quoteRowHeightStore === QUOTE_ROW_HEIGHT.Medium ? 'ri-check-line' : '',
          label: t(`quote:quotation.toolbar.vue.${QUOTE_ROW_HEIGHT.Medium}`),
          color: 'greenBrand',
          onClick: () => handleRowHeight(QUOTE_ROW_HEIGHT.Medium),
        },
        {
          rightIcon: quoteRowHeightStore === QUOTE_ROW_HEIGHT.Large ? 'ri-check-line' : '',
          label: t(`quote:quotation.toolbar.vue.${QUOTE_ROW_HEIGHT.Large}`),
          color: 'greenBrand',
          onClick: () => handleRowHeight(QUOTE_ROW_HEIGHT.Large),
        },
      ]}
    />
  );
};
