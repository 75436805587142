import type { ILibraryComponent, ILibraryJob } from '@graneet/business-logic';
import type { AbstractQuoteItemObject, QuoteComponent, QuoteNode, QuoteSubItem } from '@org/quotation-lib';
import {
  CheckType,
  ContentTypeGuard,
  MARGIN_TYPE,
  QuoteNodeCreateChildUseCase,
  QuoteQuantityFormula,
} from '@org/quotation-lib';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import Big from 'big.js';

import {
  mapQuoteComponentToUpdateUnitFlatCostAmount,
  mapQuoteNodeToUpsertComponentDTO,
} from '../mappers/quoteComponentMapper';
import { genericUpdateItemMapper } from '../mappers/quoteItemsGenericMapper';
import { mapQuoteNodeToUpsertSubItemDTO } from '../mappers/quoteSubItemMapper';
import { mapQuoteNodeToUpsertBasicItemDTO } from '../mappers/quoteBasicItemMapper';
import { mapQuoteNodeToUpsertOptionalItemDTO } from '../mappers/quoteOptionalItemMapper';
import { mapQuoteNodeToUpsertHiddenCostItemDTO } from '../mappers/quoteHiddenCostItemMapper';

import { useQuoteError } from './useQuoteError';

import { useQuoteSetToStore } from 'features/quotation/quote-common/hooks/useQuoteSetToStore';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import type { QuoteItem } from 'features/quotation/ag-grid-quote/components/columns/DenominationCell/Editor/AutoComplete/AutoCompleteResults';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

type LibraryJob = Omit<ILibraryJob, 'createdAt' | 'updatedAt'>;
type LibraryComponent = Omit<ILibraryComponent, 'createdAt' | 'updatedAt'>;

type SelectedItem = {
  item: QuoteItem | LibraryJob | LibraryComponent;
  type: 'quote' | 'libraryItem' | 'libraryComponent';
};

const getDenomination = (selectedItem: SelectedItem): string | null => {
  switch (selectedItem.type) {
    case 'quote': {
      return (selectedItem.item as QuoteItem).denomination ?? '';
    }
    case 'libraryItem': {
      return (selectedItem.item as LibraryJob).description;
    }
    case 'libraryComponent': {
      return (selectedItem.item as LibraryComponent).description;
    }
    default: {
      return null;
    }
  }
};

const getNote = (selectedItem: SelectedItem): string | null => {
  switch (selectedItem.type) {
    case 'quote': {
      return (selectedItem.item as QuoteItem).note ?? '';
    }
    case 'libraryItem': {
      return (selectedItem.item as LibraryJob).note;
    }
    default: {
      return null;
    }
  }
};

const getComponentTypeId = (selectedItem: SelectedItem): number | null => {
  switch (selectedItem.type) {
    case 'quote': {
      return (selectedItem.item as QuoteItem).componentTypeId ?? null;
    }
    case 'libraryComponent': {
      return (selectedItem.item as LibraryComponent).componentType?.id ?? null;
    }
    default: {
      return null;
    }
  }
};

const getUnitFlatCostAmount = (selectedItem: SelectedItem): string | null => {
  switch (selectedItem.type) {
    case 'quote': {
      return (selectedItem.item as QuoteItem).unitFlatCostAmount ?? null;
    }
    case 'libraryItem': {
      return (selectedItem.item as LibraryJob).unitDisbursementExVAT.toString();
    }
    case 'libraryComponent': {
      return (selectedItem.item as LibraryComponent).unitDisbursementExVAT.toString();
    }
    default: {
      return null;
    }
  }
};

export function useQuoteAutoCompleteSelectedItem() {
  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const {
    quoteComponentsProxyApi,
    quoteSubItemsProxyApi,
    quoteBasicItemsProxyApi,
    quoteOptionalItemsProxyApi,
    quoteHiddenCostItemsProxyApi,
  } = useQuotationProxyApis();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  const handleComponents = useCallback(
    (
      { quantity, libraryComponent }: { quantity: number | null; libraryComponent: ILibraryComponent },
      parentNode: QuoteNode,
    ) => {
      const lastChildId = parentNode.getLastChild()?.getId() ?? null;
      const quoteComponent = QuoteNodeCreateChildUseCase.createQuoteComponent(parentNode, lastChildId, null);
      if (!quoteComponent) {
        throw new Error('QuoteComponent is not created');
      }

      const componentContent = quoteComponent.getContent();

      if (!ContentTypeGuard.isQuoteComponent(componentContent)) {
        throw new Error('Content is not a QuoteComponent');
      }

      componentContent.updateProperties({
        refCode: libraryComponent.refCode,
        denomination: libraryComponent.description,
        unit: libraryComponent.unit,
      });

      componentContent.updateComponentTypeId(libraryComponent.componentType?.id ?? null, {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });

      componentContent.updateUnitFlatCostAmount(Big(libraryComponent.unitDisbursementExVAT), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });

      componentContent.updateQuantityFormula(
        new QuoteQuantityFormula({
          quantity: quantity?.toString() || null,
        }),
        {
          spreadUp: true,
          impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
        },
      );

      return quoteComponent;
    },
    [],
  );

  const handleQuoteItemComponents = useCallback((component: QuoteComponent, parentNode: QuoteNode) => {
    const lastChildId = parentNode.getLastChild()?.getId() ?? null;
    const quoteComponent = QuoteNodeCreateChildUseCase.createQuoteComponent(parentNode, lastChildId, null);
    if (!quoteComponent) {
      throw new Error('QuoteComponent is not created');
    }

    const componentContent = quoteComponent.getContent();

    if (!ContentTypeGuard.isQuoteComponent(componentContent)) {
      throw new Error('Content is not a QuoteComponent');
    }

    componentContent.updateProperties({
      refCode: component.getRefCode(),
      denomination: component.getDenomination(),
      unit: component.getUnit(),
    });

    componentContent.updateComponentTypeId(component.getComponentTypeId(), {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });

    componentContent.updateUnitFlatCostAmount(component.getUnitFlatCostAmount(), {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });
    componentContent.updateQuantityFormula(component.getQuantityFormula(), {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });

    return quoteComponent;
  }, []);

  const handleQuoteItemSubItems = useCallback(
    (subItem: QuoteSubItem, subItemNode: QuoteNode, parentNode: QuoteNode) => {
      const components: QuoteNode[] = [];

      const quoteSubItem = QuoteNodeCreateChildUseCase.createQuoteSubItem(parentNode);
      if (!quoteSubItem) {
        throw new Error('QuoteSubItem is not created');
      }

      const subItemContent = quoteSubItem.getContent();
      if (!ContentTypeGuard.isQuoteSubItem(subItemContent)) {
        throw new Error('Content is not a QuoteSubItem');
      }

      subItemContent.updateProperties({
        refCode: subItem.getRefCode(),
        denomination: subItem.getDenomination(),
        unit: subItem.getUnit(),
      });

      subItemContent.updateUnitFlatCostAmount(subItem.getUnitFlatCostAmount(), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });
      subItemContent.updateQuantityFormula(subItem.getQuantityFormula(), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });
      const subItemCompoents = subItemNode.getChildren();
      if (subItemCompoents) {
        subItemCompoents.forEach((child) => {
          const childContent = child.getContent();
          if (ContentTypeGuard.isQuoteComponent(childContent)) {
            const quoteComponent = handleQuoteItemComponents(childContent, quoteSubItem);
            components.push(quoteComponent);
          }
        });
      }

      return { quoteSubItem, components };
    },
    [handleQuoteItemComponents],
  );

  const handleSelectedComponent = useCallback(
    (selectedItem: SelectedItem, nodeId: string) => {
      const quoteComponent = quote.getTree().getQuoteComponentOrThrow(nodeId);
      const quoteNode = quoteComponent.getNodeOrThrow();
      const componentTypeId = getComponentTypeId(selectedItem);
      const newProperties = {
        denomination: getDenomination(selectedItem),
        refCode: selectedItem.item.refCode,
        unit: selectedItem.item.unit,
        note: getNote(selectedItem),
        componentTypeId: componentTypeId ? parseInt(componentTypeId.toString(), 10) : null,
      };
      quoteComponent.updateProperties(newProperties);

      quoteComponent.updateComponentTypeId(newProperties.componentTypeId, {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });

      quoteComponent.updateUnitFlatCostAmount(CheckType.bigOrNull(getUnitFlatCostAmount(selectedItem)), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });

      quoteSetToStore(quote);

      if (quoteComponentsProxyApi) {
        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
        quoteComponentsProxyApi
          .updateQuoteComponent(quoteComponent.getId(), {
            ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
            ...newProperties,
            ...mapQuoteComponentToUpdateUnitFlatCostAmount(quoteNode, quote.getId(), clientRequestId, timestamp),
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));
      }
    },
    [quote, quoteComponentsProxyApi, quoteError, quoteSetClientRequestToStore, quoteSetToStore],
  );

  const handleSelectedSubItem = useCallback(
    (selectedItem: SelectedItem, nodeId: string) => {
      const quoteSubItem = quote.getTree().getQuoteSubItemOrThrow(nodeId);
      const quoteNode = quoteSubItem.getNodeOrThrow();
      const components: QuoteNode[] = [];

      const newProperties = {
        denomination: getDenomination(selectedItem),
        refCode: selectedItem.item.refCode,
        unit: selectedItem.item.unit,
        note: getNote(selectedItem),
      };
      quoteSubItem.updateProperties(newProperties);
      quoteSubItem.updateUnitFlatCostAmount(CheckType.bigOrNull(getUnitFlatCostAmount(selectedItem)), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });

      if (selectedItem.type === 'libraryItem') {
        if ((selectedItem.item as LibraryJob).libraryJobComponents?.length) {
          (selectedItem.item as LibraryJob).libraryJobComponents?.forEach((libraryJobComponent) => {
            const quoteComponent = handleComponents(libraryJobComponent, quoteNode);
            components.push(quoteComponent);
          });
        }
      }
      if (selectedItem.type === 'quote') {
        const existingNode = quoteNode.getTree().getNodeOrNull((selectedItem.item as QuoteItem).nodeId);
        if (existingNode) {
          const children = existingNode.getChildren();
          if (children) {
            children.forEach((child) => {
              const childContent = child.getContent();
              if (ContentTypeGuard.isQuoteComponent(childContent)) {
                const quoteComponent = handleQuoteItemComponents(childContent, quoteNode);
                components.push(quoteComponent);
              }
            });
          }
        }
      }

      quoteSetToStore(quote);
      if (quoteSubItemsProxyApi) {
        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });

        quoteSubItemsProxyApi
          .populateQuoteSubItemItem({
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
            QuoteSubItem: mapQuoteNodeToUpsertSubItemDTO(quoteNode, quote.getId(), clientRequestId, timestamp),
            QuoteComponents: components.map((component) =>
              mapQuoteNodeToUpsertComponentDTO(component, quote.getId(), clientRequestId, timestamp),
            ),
            prevSiblingId: quoteNode.getPrevSibling()?.getId() ?? null,
            nextSiblingId: quoteNode.getNextSibling()?.getId() ?? null,
            parentNodeId: quoteNode.getParent()?.getId()!,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));
      }
    },
    [
      handleComponents,
      handleQuoteItemComponents,
      quote,
      quoteError,
      quoteSetClientRequestToStore,
      quoteSetToStore,
      quoteSubItemsProxyApi,
    ],
  );

  const handleSelectedBasicItem = useCallback(
    (selectedItem: SelectedItem, nodeId: string) => {
      const quoteBasicItem = quote.getTree().getQuoteBasicItemOrThrow(nodeId);
      const quoteNode = quoteBasicItem.getNodeOrThrow();
      const components: QuoteNode[] = [];
      const subItems: QuoteNode[] = [];

      const newProperties = {
        denomination: getDenomination(selectedItem),
        refCode: selectedItem.item.refCode,
        unit: selectedItem.item.unit,
        note: getNote(selectedItem),
      };
      quoteBasicItem.updateProperties(newProperties);
      quoteBasicItem.updateUnitFlatCostAmount(CheckType.bigOrNull(getUnitFlatCostAmount(selectedItem)), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });

      if (selectedItem.type === 'libraryItem') {
        if ((selectedItem.item as LibraryJob).libraryJobComponents?.length) {
          (selectedItem.item as LibraryJob).libraryJobComponents?.forEach((libraryJobComponent) => {
            const quoteComponent = handleComponents(libraryJobComponent, quoteNode);
            components.push(quoteComponent);
          });
        }
      }
      if (selectedItem.type === 'quote') {
        const existingNode = quoteNode.getTree().getNodeOrNull((selectedItem.item as QuoteItem).nodeId);
        if (existingNode) {
          const children = existingNode.getChildren();
          if (children) {
            children.forEach((child) => {
              const childContent = child.getContent();
              if (ContentTypeGuard.isQuoteComponent(childContent)) {
                const quoteComponent = handleQuoteItemComponents(childContent, quoteNode);
                components.push(quoteComponent);
              }
              if (ContentTypeGuard.isQuoteSubItem(childContent)) {
                const { quoteSubItem, components: subItemComponents } = handleQuoteItemSubItems(
                  childContent,
                  child,
                  quoteNode,
                );
                subItems.push(quoteSubItem);
                components.push(...subItemComponents);
              }
            });
          }
        }
      }

      quoteSetToStore(quote);
      if (quoteBasicItemsProxyApi) {
        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
        quoteBasicItemsProxyApi
          .populateQuoteBasicItem({
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
            QuoteBasicItem: mapQuoteNodeToUpsertBasicItemDTO(quoteNode, quote.getId(), clientRequestId, timestamp),
            QuoteSubItems: subItems.map((subItem) =>
              mapQuoteNodeToUpsertSubItemDTO(subItem, quote.getId(), clientRequestId, timestamp),
            ),
            QuoteComponents: components.map((component) =>
              mapQuoteNodeToUpsertComponentDTO(component, quote.getId(), clientRequestId, timestamp),
            ),
            prevSiblingId: quoteNode.getPrevSibling()?.getId() ?? null,
            nextSiblingId: quoteNode.getNextSibling()?.getId() ?? null,
            parentNodeId: quoteNode.getParent()?.getId()!,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));
      }
    },
    [
      handleComponents,
      handleQuoteItemComponents,
      handleQuoteItemSubItems,
      quote,
      quoteBasicItemsProxyApi,
      quoteError,
      quoteSetClientRequestToStore,
      quoteSetToStore,
    ],
  );

  const handleSelectedOptionalItem = useCallback(
    (selectedItem: SelectedItem, nodeId: string) => {
      const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(nodeId);
      const quoteNode = quoteOptionalItem.getNodeOrThrow();
      const components: QuoteNode[] = [];
      const subItems: QuoteNode[] = [];

      const newProperties = {
        denomination: getDenomination(selectedItem),
        refCode: selectedItem.item.refCode,
        unit: selectedItem.item.unit,
        note: getNote(selectedItem),
      };
      quoteOptionalItem.updateProperties(newProperties);
      quoteOptionalItem.updateUnitFlatCostAmount(CheckType.bigOrNull(getUnitFlatCostAmount(selectedItem)), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });

      if (selectedItem.type === 'libraryItem') {
        if ((selectedItem.item as LibraryJob).libraryJobComponents?.length) {
          (selectedItem.item as LibraryJob).libraryJobComponents?.forEach((libraryJobComponent) => {
            const quoteComponent = handleComponents(libraryJobComponent, quoteNode);
            components.push(quoteComponent);
          });
        }
      }
      if (selectedItem.type === 'quote') {
        const existingNode = quoteNode.getTree().getNodeOrNull((selectedItem.item as QuoteItem).nodeId);
        if (existingNode) {
          const children = existingNode.getChildren();
          if (children) {
            children.forEach((child) => {
              const childContent = child.getContent();
              if (ContentTypeGuard.isQuoteComponent(childContent)) {
                const quoteComponent = handleQuoteItemComponents(childContent, quoteNode);
                components.push(quoteComponent);
              }
              if (ContentTypeGuard.isQuoteSubItem(childContent)) {
                const { quoteSubItem, components: subItemComponents } = handleQuoteItemSubItems(
                  childContent,
                  child,
                  quoteNode,
                );
                subItems.push(quoteSubItem);
                components.push(...subItemComponents);
              }
            });
          }
        }
      }

      quoteSetToStore(quote);
      if (quoteOptionalItemsProxyApi) {
        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });

        quoteOptionalItemsProxyApi
          .populateQuoteOptionalItem({
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
            QuoteOptionalItem: mapQuoteNodeToUpsertOptionalItemDTO(
              quoteNode,
              quote.getId(),
              clientRequestId,
              timestamp,
            ),
            QuoteSubItems: subItems.map((subItem) =>
              mapQuoteNodeToUpsertSubItemDTO(subItem, quote.getId(), clientRequestId, timestamp),
            ),
            QuoteComponents: components.map((component) =>
              mapQuoteNodeToUpsertComponentDTO(component, quote.getId(), clientRequestId, timestamp),
            ),
            prevSiblingId: quoteNode.getPrevSibling()?.getId() ?? null,
            nextSiblingId: quoteNode.getNextSibling()?.getId() ?? null,
            parentNodeId: quoteNode.getParent()?.getId()!,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));
      }
    },
    [
      handleComponents,
      handleQuoteItemComponents,
      handleQuoteItemSubItems,
      quote,
      quoteError,
      quoteOptionalItemsProxyApi,
      quoteSetClientRequestToStore,
      quoteSetToStore,
    ],
  );

  const handleSelectedHiddenCostItem = useCallback(
    (selectedItem: SelectedItem, nodeId: string) => {
      const quoteHiddenCostItem = quote.getTree().getQuoteHiddenCostOrThrow(nodeId);
      const quoteNode = quoteHiddenCostItem.getNodeOrThrow();
      const components: QuoteNode[] = [];
      const subItems: QuoteNode[] = [];

      const newProperties = {
        denomination: getDenomination(selectedItem),
        refCode: selectedItem.item.refCode,
        unit: selectedItem.item.unit,
        note: getNote(selectedItem),
      };
      quoteHiddenCostItem.updateProperties(newProperties);
      quoteHiddenCostItem.updateUnitFlatCostAmount(CheckType.bigOrNull(getUnitFlatCostAmount(selectedItem)), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });

      if (selectedItem.type === 'libraryItem') {
        if ((selectedItem.item as LibraryJob).libraryJobComponents?.length) {
          (selectedItem.item as LibraryJob).libraryJobComponents?.forEach((libraryJobComponent) => {
            const quoteComponent = handleComponents(libraryJobComponent, quoteNode);
            components.push(quoteComponent);
          });
        }
      }
      if (selectedItem.type === 'quote') {
        const existingNode = quoteNode.getTree().getNodeOrNull((selectedItem.item as QuoteItem).nodeId);
        if (existingNode) {
          const children = existingNode.getChildren();
          if (children) {
            children.forEach((child) => {
              const childContent = child.getContent();
              if (ContentTypeGuard.isQuoteComponent(childContent)) {
                const quoteComponent = handleQuoteItemComponents(childContent, quoteNode);
                components.push(quoteComponent);
              }
              if (ContentTypeGuard.isQuoteSubItem(childContent)) {
                const { quoteSubItem, components: subItemComponents } = handleQuoteItemSubItems(
                  childContent,
                  child,
                  quoteNode,
                );
                subItems.push(quoteSubItem);
                components.push(...subItemComponents);
              }
            });
          }
        }
      }

      quoteSetToStore(quote);
      if (quoteHiddenCostItemsProxyApi) {
        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });

        quoteHiddenCostItemsProxyApi
          .populateQuoteHiddenCostItem({
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
            QuoteHiddenCost: mapQuoteNodeToUpsertHiddenCostItemDTO(
              quoteNode,
              quote.getId(),
              clientRequestId,
              timestamp,
            ),
            QuoteSubItems: subItems.map((subItem) =>
              mapQuoteNodeToUpsertSubItemDTO(subItem, quote.getId(), clientRequestId, timestamp),
            ),
            QuoteComponents: components.map((component) =>
              mapQuoteNodeToUpsertComponentDTO(component, quote.getId(), clientRequestId, timestamp),
            ),
            prevSiblingId: quoteNode.getPrevSibling()?.getId() ?? null,
            nextSiblingId: quoteNode.getNextSibling()?.getId() ?? null,
            parentNodeId: quoteNode.getParent()?.getId()!,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));
      }
    },
    [
      handleComponents,
      handleQuoteItemComponents,
      handleQuoteItemSubItems,
      quote,
      quoteError,
      quoteHiddenCostItemsProxyApi,
      quoteSetClientRequestToStore,
      quoteSetToStore,
    ],
  );

  return useCallback(
    async (nodeId: string, type: AbstractQuoteItemObject['type'] | 'QuoteComponent', selectedItem: SelectedItem) => {
      try {
        switch (type) {
          case 'QuoteComponent': {
            handleSelectedComponent(selectedItem, nodeId);
            break;
          }
          case 'QuoteSubItem': {
            handleSelectedSubItem(selectedItem, nodeId);
            break;
          }
          case 'QuoteBasicItem': {
            handleSelectedBasicItem(selectedItem, nodeId);
            break;
          }
          case 'QuoteOptionalItem': {
            handleSelectedOptionalItem(selectedItem, nodeId);
            break;
          }
          case 'QuoteHiddenCostItem': {
            handleSelectedHiddenCostItem(selectedItem, nodeId);
            break;
          }
          default: {
            break;
          }
        }

        quoteSetToStore(quote);
        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [
      handleSelectedBasicItem,
      handleSelectedComponent,
      handleSelectedHiddenCostItem,
      handleSelectedOptionalItem,
      handleSelectedSubItem,
      quote,
      quoteError,
      quoteSetToStore,
    ],
  );
}
