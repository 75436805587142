import { useCallback } from 'react';

import { TextCell } from '../../components/columns/TextCell/TextCell';
import { TextEditorCell } from '../../components/columns/TextCell/TextEditorCell';
import type { AGColDef } from '../../types';
import { EmptyCell } from '../../components/EmptyCell';

export function useVatRateWithReversalLiabilityColumnDef() {
  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'vatRate',
      width,
      index,
      headerName: label,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressMovable: false,
      editable: false,
      cellClass: EmptyCell,
      sortable: false,
      cellRendererParams: {
        textAlign: 'right',
      },
      cellRenderer: TextCell,
      cellEditor: TextEditorCell,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      valueGetter: (_params) => '-',
    }),
    [],
  );
}
