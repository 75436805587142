import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { IBankingTransactionWithRelations } from '@graneet/business-logic';

import { useBankingTransactionAvailableTags, useBankingTransactionUpdate } from '../services/banking-transaction.api';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';

interface BankingTransactionTagsProps {
  bankingTransaction: IBankingTransactionWithRelations;
}

export const BankingTransactionTags: FC<BankingTransactionTagsProps> = ({ bankingTransaction }) => {
  const { t } = useTranslation(['global']);

  const tags = useBankingTransactionAvailableTags();

  const bankingTransactionUpdateMutation = useBankingTransactionUpdate();

  const onTagsSaved = useCallback(
    (newTags: string[]) =>
      bankingTransactionUpdateMutation.mutateAsync({ id: bankingTransaction.id, dto: { tags: newTags } }),
    [bankingTransaction.id, bankingTransactionUpdateMutation],
  );

  return (
    <TagsEditionBlock
      label={t('global:words.tags')}
      placeholder={t('global:changeTags.placeholder')}
      tags={bankingTransaction.tags}
      suggestedTags={tags.data}
      onTagsEdited={onTagsSaved}
      isEditable
    />
  );
};
