import { useEffect, useMemo } from 'react';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { Card, Button, Header, Multiplication, Container, SimpleEditIcon } from '@graneet/lib-ui';
import { Form, useForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';

import { MARGIN_FIELD_NAME } from 'features/margin/services/margin.constants';
import { useCompanyMargins } from 'features/company/services/company.api';
import { EditCompanyMarginModal } from 'features/margin/components/modals/EditCompanyMarginModal';
import { BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD } from 'features/margin/services/margins.utils';
import { MarginEditionByComponentsTypesTable } from 'features/margin/components/MarginEditionByComponentsTypesTable';

const CARD_SUBTITLE_STYLE = {
  fontWeight: '500',
};

export const DisbursementAnalysisTab = () => {
  const { t } = useTranslation(['margin', 'global']);
  const marginForm = useForm();
  const componentsMarginForm = useForm();
  const { setFormValues: setMarginFormValues } = marginForm;
  const { setFormValues: setComponentsMarginFormValues } = componentsMarginForm;
  const {
    onOpen: onCompanyMarginModalOpen,
    onClose: onCompanyMarginModalClose,
    isOpen: isCompanyMarginModalOpen,
  } = useDisclosure();

  const companyMargins = useCompanyMargins();
  const components = useMemo(
    () => companyMargins.data.componentTypesMargins,
    [companyMargins.data.componentTypesMargins],
  );

  useEffect(() => {
    if (companyMargins.data) {
      setMarginFormValues(companyMargins.data.quoteMargin);
    }
  }, [companyMargins.data, setMarginFormValues]);

  useEffect(() => {
    if (components) {
      components.map((component) => {
        setComponentsMarginFormValues({
          componentTypeId: component.componentType.id,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]:
            component.overheadCosts,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
            component.profitMargin,
          [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
            component.totalMargin,
        });
        return true;
      });
    }
  }, [components, setComponentsMarginFormValues]);

  return (
    <Container size="sm">
      <EditCompanyMarginModal
        isOpen={isCompanyMarginModalOpen}
        onClose={onCompanyMarginModalClose}
        margin={companyMargins.data.quoteMargin}
        marginComponentsTypes={components}
      />

      <Box maxW="40rem">
        <Card
          title={t('margin:company.title')}
          pt={2}
          topRightContent={
            <Button variant="outline" onClick={onCompanyMarginModalOpen} leftIcon={<SimpleEditIcon />}>
              {t('global:words.c.update')}
            </Button>
          }
        >
          <Text>{t('margin:company.description')}</Text>

          <Header color="gray.800" px={0} sx={CARD_SUBTITLE_STYLE}>
            {t('margin:company.subtitle')}
          </Header>
          <Form form={marginForm}>
            <Multiplication backgroundColor="white">
              <Multiplication.Field
                name={MARGIN_FIELD_NAME.OVERHEAD_COSTS}
                label={t('margin:fields.overheadCosts')}
                computedName={MARGIN_FIELD_NAME.TOTAL_MARGIN}
                isDisabled
                scale={3}
              />
              <Multiplication.Field
                name={MARGIN_FIELD_NAME.PROFIT_MARGIN}
                label={t('margin:fields.profitMargin')}
                computedName={MARGIN_FIELD_NAME.TOTAL_MARGIN}
                isDisabled
                scale={3}
              />
              <Multiplication.Field
                name={MARGIN_FIELD_NAME.TOTAL_MARGIN}
                label={t('margin:fields.totalMargin')}
                computedName={MARGIN_FIELD_NAME.PROFIT_MARGIN}
                isDisabled
                scale={3}
              />
            </Multiplication>
          </Form>

          <Header color="gray.800" px={0} sx={CARD_SUBTITLE_STYLE}>
            {t('margin:componentTypes.title')}
          </Header>
          <Form form={componentsMarginForm}>
            <MarginEditionByComponentsTypesTable
              componentTypes={components}
              isMarginComponentDataLoading={false}
              isEditionDisabled
            />
          </Form>
        </Card>
      </Box>
    </Container>
  );
};
