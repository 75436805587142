import type { CSSProperties, FC, FocusEventHandler, MouseEventHandler } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Popover, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import { Parser } from 'expr-eval';

import type { InputProps } from '../../../Input';
import { Input } from '../../../Input';
import { CalculatorIcon } from '../../../Icons/CalculatorIcon';
import { Tooltip } from '../../../Tooltip';
import { formulaFieldTranslations } from '../configureDefaultLabel';

import { FormulaContent } from './FormulaContent';

const DEFAULT_ICON_STYLES: CSSProperties = {
  cursor: 'pointer',
  color: 'gray.300',
  position: 'absolute',
  right: 0,
  height: '28px',
  width: '28px',
  boxSizing: 'border-box',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '50%',
  marginTop: '-14px',
};

interface CalculFormulaResult {
  value: number | null;
  formulaValue: number | null;
  roundedFactor: number;
}

interface FormulaContentValue {
  formula: string;
  comment: string;
  roundFactor: number;
}

export type FormulaFieldValue =
  | {
      value: number | undefined | null;
      content: FormulaContentValue | undefined | null;
    }
  | undefined
  | null;

interface FormulaFieldInternalProps {
  errorMessage?: string;
  inputProps?: InputProps;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  label?: string;
  shouldDisplayError?: boolean;
  value: FormulaFieldValue | undefined;
  onBlur(): void;
  onChange(value: FormulaFieldValue | undefined): void;
  onFocus(): void;
  sendClickRoundEvent(type: string): void;
}

export const FormulaFieldInternal: FC<FormulaFieldInternalProps> = (props) => {
  const {
    isDisabled,
    isReadOnly,
    shouldDisplayError,
    value: defaultValue,
    onChange,
    onBlur,
    onFocus,
    sendClickRoundEvent,
    ...otherProps
  } = props;

  const [comment, setComment] = useState<string | null>(null);
  const [formula, setFormula] = useState<string | null>(null);
  const [formulaError, setError] = useState<string | null>(null);
  const [formulaValue, setFormulaValue] = useState<number | null>(null);
  const [hasBeenBlurred, setHasBeenBlurred] = useState(false);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [roundFactor, setRoundFactor] = useState<number>(0);

  const formulaRef = useRef<HTMLInputElement>(null);
  const isFocusRef = useRef(false);

  const { onClose, onOpen } = useDisclosure();

  /*
   * Styles
   */

  const defaultIconStyles = useMemo(
    () => ({
      ...DEFAULT_ICON_STYLES,
      ...(otherProps.label
        ? {
            marginTop: '-8px',
          }
        : {}),
    }),
    [otherProps.label],
  );

  const iconStyles = useMemo(
    () => ({
      default: {
        ...defaultIconStyles,
        color: 'greenBrand.light',
      },
      hover: {
        ...defaultIconStyles,
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.08)',
        color: 'greenBrand.light',
      },
      focus: {
        ...defaultIconStyles,
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.08)',
        color: 'black',
      },
      hidden: {
        display: 'none',
      },
    }),
    [defaultIconStyles],
  );

  const iconStyle = useMemo(() => {
    if (isOpen) {
      return iconStyles.focus;
    }
    if (isFocused && ((formula && formula !== '') || !isReadOnly)) {
      return iconStyles.default;
    }
    if ((formula ?? '') !== '') {
      return defaultIconStyles;
    }
    return iconStyles.hidden;
  }, [
    isOpen,
    isFocused,
    formula,
    iconStyles.hidden,
    iconStyles.focus,
    iconStyles.default,
    defaultIconStyles,
    isReadOnly,
  ]);

  /*
   * Utils
   */

  const countDecimals = (num: number) => {
    if (Number.isNaN(num) || Math.floor(num) === num) {
      return 0;
    }

    return num.toString().split('.')[1].length || 0;
  };

  const isNumberOrEmpty = (input: string) => {
    if (input === '') {
      return true;
    }
    const regex = /^-?\d*([.,]\d*)?$/;
    return regex.test(input);
  };

  /*
   * Handlers
   */

  const handleOnChangeInput = (input: string) => {
    if (!isNumberOrEmpty(input)) {
      return;
    }
    setInputValue(input);
    setFormulaValue(null);
    setFormula(null);
    setRoundFactor(0);
    setComment(null);
  };

  const handleOutSideClick = useCallback<FocusEventHandler<HTMLDivElement>>(() => {
    if (onBlur && isFocused) {
      onBlur();
    }
    setIsFocused(false);
  }, [isFocused, onBlur]);

  const handleOnFocus = useCallback(() => {
    if (onFocus) {
      onFocus();
    }
    isFocusRef.current = true;
    setIsFocused(true);
  }, [onFocus]);

  const preventDefaultOnMouseDown = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleOnBlur = useCallback(() => {
    isFocusRef.current = false;
    if (inputValue !== defaultValue?.value?.toString() || comment !== defaultValue?.content?.comment) {
      onChange({
        value: parseFloat(inputValue?.replaceAll(',', '.') ?? ''),
        content: {
          formula: formula ?? '',
          roundFactor: formula && roundFactor ? roundFactor : 0,
          comment: formula && comment ? comment : '',
        },
      });
    }
    onBlur();
    setHasBeenBlurred(true);
  }, [
    comment,
    defaultValue?.content?.comment,
    defaultValue?.value,
    formula,
    inputValue,
    onBlur,
    onChange,
    roundFactor,
  ]);

  const handleCommentChange = (input: string) => {
    setComment(input);
  };

  const handleRound = useCallback(
    (value: number, factor: number) => {
      if (factor < 0 || value === null || countDecimals(value) - factor < 0) {
        sendClickRoundEvent('has-not-rounded');
        return null;
      }

      sendClickRoundEvent('has-rounded');
      return parseFloat(value.toFixed(countDecimals(value) - factor));
    },
    [sendClickRoundEvent],
  );

  const handleCalculFormula = useCallback(
    (newFormula: string, newRoundedFactor: number) => {
      setError(null);

      if (newFormula === '') {
        return null;
      }

      const result: CalculFormulaResult = {
        value: null,
        formulaValue: null,
        roundedFactor: newRoundedFactor,
      };

      try {
        const expr = Parser.parse(newFormula.replaceAll(',', '.'));
        result.formulaValue = parseFloat(expr.evaluate());
      } catch {
        setError(formulaFieldTranslations.genericError);
        return null;
      }

      result.value = result.formulaValue;

      if (newRoundedFactor !== 0) {
        result.value = handleRound(result.formulaValue, newRoundedFactor);
      }

      if (newRoundedFactor === 0 && countDecimals(result.formulaValue) > 2) {
        result.roundedFactor = newRoundedFactor - 2 + countDecimals(result.formulaValue);
        result.value = handleRound(result.formulaValue, result.roundedFactor);
      }

      if (result.formulaValue === null || !Number.isFinite(result.formulaValue)) {
        setError(formulaFieldTranslations.impossibleCalculationError);
        return null;
      }

      return result;
    },
    [handleRound],
  );

  const handleRoundButton = useCallback(
    (factor: number) => {
      const newRoundFactor = (roundFactor ?? 0) + factor;
      const newValue = handleRound(formulaValue ?? 0, newRoundFactor);

      if (newValue === null) {
        return;
      }

      if (newValue.toString() !== inputValue && !Number.isNaN(newValue)) {
        setInputValue(newValue.toString());
        setRoundFactor(newRoundFactor);
      }
    },
    [formulaValue, handleRound, inputValue, roundFactor],
  );

  const handleFormulaChange = useCallback(
    (newFormula: string) => {
      const values = handleCalculFormula(newFormula, 0);
      setFormula(newFormula);
      setInputValue(values?.value && !Number.isNaN(values?.value) ? values.value.toString() : null);
      setFormulaValue(values?.formulaValue ?? null);
      setRoundFactor(values?.roundedFactor ?? 0);
    },
    [handleCalculFormula],
  );

  const handleOnOpen = useCallback(() => {
    setIsOpen(true);
    formulaRef.current?.focus();
  }, [formulaRef]);

  const handleOnClose = useCallback(() => {
    setIsOpen(false);
    handleOnFocus();
    handleOnBlur();
  }, [handleOnFocus, handleOnBlur]);

  /*
   * Effects
   */

  useEffect(() => {
    if (!isFocused) {
      onClose();
    }
  }, [isFocused, onClose]);

  useEffect(() => {
    const result = handleCalculFormula(defaultValue?.content?.formula ?? '', defaultValue?.content?.roundFactor ?? 0);

    setInputValue(
      defaultValue?.value !== null && defaultValue?.value !== undefined && !Number.isNaN(defaultValue.value)
        ? defaultValue.value.toString()
        : null,
    );
    setFormulaValue(result?.formulaValue ?? null);
    setFormula(defaultValue?.content?.formula ?? null);
    setRoundFactor(defaultValue?.content?.roundFactor ?? 0);
    setComment(defaultValue?.content?.comment ?? null);
  }, [defaultValue, handleCalculFormula]);

  /*
   * Render
   */

  const content = useMemo(
    () => (
      <FormulaContent
        ref={formulaRef}
        onFormulaChange={handleFormulaChange}
        onCommentChange={handleCommentChange}
        onRoundLeft={() => handleRoundButton(1)}
        onRoundRight={() => handleRoundButton(-1)}
        onClose={() => formulaRef.current?.blur()}
        formula={defaultValue && formula === null ? defaultValue.content?.formula?.toString() : formula}
        comment={comment}
        error={formulaError}
        isReadOnly={isReadOnly || isDisabled}
      />
    ),
    [handleFormulaChange, defaultValue, formula, comment, formulaError, isReadOnly, isDisabled, handleRoundButton],
  );

  return (
    <Box position="relative" onBlur={handleOutSideClick}>
      <Input
        shouldDisplayError={hasBeenBlurred && shouldDisplayError}
        value={inputValue?.replaceAll('.', ',') ?? ''}
        onChange={handleOnChangeInput}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        isReadOnly={isReadOnly}
        isDisabled={isDisabled}
        paddingRight="25px"
        {...otherProps}
      />
      {isReadOnly || isDisabled ? (
        <Tooltip label={content} bg="gray.100" placement="bottom-end" paddingY="10px">
          <Box sx={iconStyle} onMouseDown={preventDefaultOnMouseDown} onClick={handleOnOpen}>
            <CalculatorIcon />
          </Box>
        </Tooltip>
      ) : (
        <Popover
          closeOnBlur
          onOpen={onOpen}
          onClose={handleOnClose}
          gutter={3}
          placement="bottom-end"
          initialFocusRef={formulaRef}
        >
          <PopoverTrigger>
            <Box sx={iconStyle} onMouseDown={preventDefaultOnMouseDown} onClick={handleOnOpen}>
              <CalculatorIcon />
            </Box>
          </PopoverTrigger>
          <PopoverContent w="auto" userSelect="none" p="8px" bg="gray.100">
            {content}
          </PopoverContent>
        </Popover>
      )}
    </Box>
  );
};
