import { Card, LabeledData, Date, Price, TwoColumns, GotoLink } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IDownPayment } from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';
import type { FC } from 'react';
import { Flex, GridItem } from '@chakra-ui/react';

import { InvoiceNumberLabeledData } from 'features/statement-ledger/components/InvoiceNumberLabeledData';
import { BillStatusBadge } from 'features/bill/components/BillStatusBadge';

interface DownPaymentInformationCardProps {
  downPayment: IDownPayment;
}

export const DownPaymentInformationCard: FC<DownPaymentInformationCardProps> = ({ downPayment }) => {
  const { t } = useTranslation(['downPayment', 'statement']);

  const { bill } = downPayment;

  return (
    <Card
      title={
        <Flex>
          {t('downPayment:titles.information')}
          {bill && <GotoLink ml={2} to={`/sales/bills?focusedId=${bill.id}`} label={t('statement:goTo.bill')} />}
        </Flex>
      }
    >
      <TwoColumns>
        <GridItem colSpan={2}>
          <LabeledData label={t('downPayment:fields.name')} data={downPayment.name} />
        </GridItem>

        <LabeledData label={t('downPayment:fields.billingDate')} data={<Date>{downPayment.billingDate}</Date>} />
        <LabeledData label={t('downPayment:fields.completionDate')} data={<Date>{downPayment.completionDate}</Date>} />
        <LabeledData label={t('downPayment:fields.amountExVAT')} data={<Price amount={downPayment.amountExVAT} />} />
        {downPayment.isInvoiced && (
          <InvoiceNumberLabeledData statement={downPayment} statementType={STATEMENT_TYPES.DOWN_PAYMENT} />
        )}
        <LabeledData
          label={t('downPayment:fields.billStatus')}
          data={downPayment.bill && <BillStatusBadge status={downPayment.bill.status} />}
        />
      </TwoColumns>
    </Card>
  );
};
