import type { ChangeEventHandler, FC, FocusEventHandler, ReactElement } from 'react';
import { cloneElement, forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import type { InputProps, ResponsiveValue, TextProps } from '@chakra-ui/react';
import {
  Input as InputChakra,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useMergeRefs,
  HStack,
  Box,
} from '@chakra-ui/react';
import type { FieldValue } from 'graneet-form';

import type { FormGroupProps } from '../../FormGroup/FormGroup';
import { FormGroup } from '../../FormGroup/FormGroup';
import { IconAdvanced } from '../../IconAdvanced';
import { SimpleCalendarIcon } from '../../Icons';

const INPUT_ELEMENT_COLOR = 'gray.300';
const ICON_FOCUSED_COLOR = 'greenBrand.light';

export function getInputStyle(options: { noBorder: boolean; size: ResponsiveValue<string>; isReadOnly: boolean }) {
  return {
    borderColor: options.noBorder ? 'gray.200' : 'gray.200',
    background: options.noBorder ? 'transparent' : 'inherit',
    // eslint-disable-next-line no-nested-ternary
    borderRadius: options.noBorder ? 0 : options.size === 'sm' ? '0.375rem' : '8px',
    // eslint-disable-next-line no-nested-ternary
    borderWidth: options.noBorder ? '2px' : options.isReadOnly ? 0 : '1px',
    borderTop: options.noBorder ? 'none' : undefined,
    borderLeft: options.noBorder ? 'none' : undefined,
    borderRight: options.noBorder ? 'none' : undefined,
    borderBottom: options.noBorder ? 'none' : undefined,
    height: '1.75rem',
    width: '100%',
    px: 2,
    fontSize: options.size === 'sm' ? '0.75rem' : 'sm',
    _hover: {
      borderColor: 'gray.300',
    },
    _focus: {
      borderColor: 'greenBrand.light',
      borderWidth: options.noBorder ? '0px' : '1px',
      outline: 'none',
      outlineOffset: 'none',
      boxShadow: '0px 0px 0px 0px',
    },
    _readOnly: {
      borderWidth: '0px',
      background: 'transparent',
    },
    _disabled: {
      borderWidth: options.noBorder ? '0px' : '1px',
      borderColor: 'gray.150',
      background: 'transparent',
      cursor: 'not-allowed',
    },
    _invalid: {
      borderColor: options.noBorder ? undefined : 'red.500',
      color: 'red.500',
      borderWidth: '1px',
    },
    _active: {
      borderColor: 'greenBrand.light',
    },
  };
}

export interface DefaultInputProps
  extends Omit<
    FormGroupProps,
    'label' | 'isFocused' | 'showError' | 'value' | 'onChange' | 'defaultValue' | 'scale' | 'placeholder'
  > {
  label?: string;

  placeholder?: string;

  isRequired?: boolean;

  isDisabled?: boolean;

  isFocused?: boolean;

  noBorder?: boolean;

  value?: FieldValue | null;

  onChange?: (value: FieldValue | null) => void | Promise<void>;

  onBlur?: FocusEventHandler<HTMLInputElement>;

  onFocus?: FocusEventHandler<HTMLInputElement>;

  errorMessage?: string;

  shouldDisplayError?: boolean;

  forceDisplayError?: boolean;

  rightLabel?: string;

  leftIcon?: ReactElement;

  rightIcon?: ReactElement;

  inputProps?: InputProps;

  isCalendar?: boolean;

  as?: FC<InputProps>;

  'data-testid'?: string;

  labelProps?: TextProps;
}

export const DefaultInput = forwardRef<HTMLInputElement, DefaultInputProps>(
  (
    {
      label = '',
      placeholder,
      isRequired,
      isDisabled,
      isReadOnly = false,
      noBorder = false,
      value,
      onChange = () => {},
      onBlur = () => {},
      onFocus = () => {},
      errorMessage,
      shouldDisplayError,
      rightLabel,
      leftIcon: LeftIcon,
      rightIcon: RightIcon,
      inputProps = {},
      isCalendar,
      as: Element = InputChakra,
      'data-testid': testId,
      labelProps,
      forceDisplayError,
      size = 'md',
      ...otherProps
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasBeenBlurred, setHasBeenBlurred] = useState(false);

    // We want to show the error even if the field has not been blurred yet
    const showError = !!shouldDisplayError && (forceDisplayError || hasBeenBlurred);

    const inputRef = useRef<HTMLInputElement>(null);
    const inputRefs = useMergeRefs<HTMLInputElement>(ref, inputRef);

    const formGroupProps = useMemo<FormGroupProps>(
      () => ({
        label,
        isRequired,
        isDisabled,
        errorMessage,
        ...labelProps,
        ...otherProps,
        isFocused,
        showError,
      }),
      [label, isRequired, isDisabled, errorMessage, labelProps, otherProps, isFocused, showError],
    );

    const handleOnBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
      (e) => {
        onBlur(e);
        setIsFocused(false);
        setHasBeenBlurred(true);
      },
      [onBlur, setIsFocused],
    );

    const handleOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        onChange(e.target.value);
      },
      [onChange],
    );

    const handleOnFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
      (e) => {
        onFocus(e);
        setIsFocused(true);
      },
      [onFocus, setIsFocused],
    );

    const elementControlsProps = useMemo<InputProps>(
      () =>
        ref
          ? { ref: inputRefs, value: value as any, onChange: handleOnChange }
          : { ref: inputRef, value: value as any, onChange: handleOnChange },
      [ref, inputRefs, value, handleOnChange],
    );

    const hasInputRightElement = rightLabel || RightIcon || isCalendar;

    const calendarIconProps = useMemo(
      () => ({
        icon: (
          <SimpleCalendarIcon
            onClick={() => (isDisabled ? undefined : inputRef.current?.focus())}
            stroke={isFocused ? ICON_FOCUSED_COLOR : INPUT_ELEMENT_COLOR}
            _hover={isDisabled ? { cursor: 'not-allowed' } : { stroke: ICON_FOCUSED_COLOR, cursor: 'pointer' }}
            boxSize={size === 'sm' ? 3 : 5}
          />
        ),
      }),
      [isDisabled, isFocused, size],
    );

    return (
      <FormGroup data-testid={testId} {...formGroupProps} isFocused={isFocused} isReadOnly={isReadOnly}>
        <InputGroup alignItems="center">
          {LeftIcon && (
            <InputLeftElement width="auto" left="8px" height="28px" alignItems="center">
              {cloneElement(LeftIcon, { color: INPUT_ELEMENT_COLOR })}
            </InputLeftElement>
          )}

          <Element
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            placeholder={placeholder}
            variant="flushed"
            {...getInputStyle({
              noBorder,
              size,
              isReadOnly,
            })}
            {...inputProps}
            {...elementControlsProps}
            opacity={isDisabled ? 0.75 : 1}
            pl={LeftIcon ? 8 : undefined}
          />

          {!isReadOnly && hasInputRightElement && (
            <InputRightElement width="auto" right="8px" height="28px" alignItems="center">
              <HStack alignItems="center" spacing={2}>
                {rightLabel && <Text color={INPUT_ELEMENT_COLOR}>{rightLabel}</Text>}

                {isCalendar && (
                  <Box alignItems="center" height="100%" display="flex">
                    <IconAdvanced {...calendarIconProps} />
                  </Box>
                )}

                {RightIcon && (
                  <Box alignItems="center" height="100%" display="flex">
                    {cloneElement(RightIcon, { color: INPUT_ELEMENT_COLOR })}
                  </Box>
                )}
              </HStack>
            </InputRightElement>
          )}
        </InputGroup>
      </FormGroup>
    );
  },
);
