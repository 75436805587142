import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { KeysMatching } from '@graneet/lib-ui';
import { SingleSelectField } from '@graneet/lib-ui';
import type { FieldValues } from 'graneet-form';

import { useAccountingConfigs } from '../services/accounting-config.api';

type Value = string | undefined | null;

interface AccountingConfigBanksAccountFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, Value> = KeysMatching<T, Value>,
> {
  name: K;

  label?: string;

  children?: ReactNode;
}

export const AccountingConfigBanksAccountField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, Value> = KeysMatching<T, Value>,
>({
  name,
  label,
  children,
}: AccountingConfigBanksAccountFieldProps<T, K>) => {
  const accountingConfig = useAccountingConfigs();
  const options = useMemo(
    () =>
      (accountingConfig.data?.accountingConfigBanks ?? [])
        .sort((a, b) => a.label?.localeCompare(b.label ?? '') ?? -1)
        .map((banksAccount) => ({
          label: `${banksAccount.label} - ${banksAccount.journal}`,
          value: banksAccount.id,
        })),
    [accountingConfig.data],
  );

  return (
    <SingleSelectField<T> name={name} options={options as any} label={label}>
      {children}
    </SingleSelectField>
  );
};
