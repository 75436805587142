import type { FC } from 'react';
import { useMemo } from 'react';
import { DeepTable } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import { countSelectedColumns } from '../services/quote-export.util';
import type { QuoteExportForm } from '../forms/quote-export-edition.wizard';

export const QuoteExportDeepTableSeparator: FC = () => {
  const form = useFormContext<QuoteExportForm>();
  const { exportItems } = useOnChangeValues(form, ['exportItems']);
  const nbColumns = useMemo(() => countSelectedColumns(exportItems), [exportItems]);

  return <DeepTable.Separator noLeftContent depth={0} colSpan={nbColumns} />;
};
