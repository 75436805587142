import type {
  IInvoiceResponseDTO,
  IInvoiceChangeStatusToCompleteDTO,
  ICreditCreationDTO,
  IInvoice,
  IInvoiceCreationDTO,
  IInvoiceProjectLinkDTO,
  IInvoiceUpdateDTO,
  IInvoiceVersionsDTO,
  IInvoiceWithRelations,
  IUpdateTagsDTO,
  IProject,
} from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

import { INVOICE_FACTORY_NAME } from './invoice.factory-name';

import { invalidateStatementRelatedQueries } from 'features/common/services/invalidate-statement-related-queries.util';
import { apiNew } from 'features/api/services/apiNew.service';
import { usePdfVersionsTanStackQuery } from 'features/pdf/hooks/usePdfVersions';

const INVOICE_PATH = '/invoices';

export const invoicesFactory = createQueryKeys(INVOICE_FACTORY_NAME, {
  get: (id: IInvoice['id']) => ({
    queryKey: [INVOICE_PATH, id],
    queryFn: () => apiNew.get<never, IInvoiceResponseDTO>(`${INVOICE_PATH}/${id}`),
    contextQueries: {
      getExtended: {
        queryKey: ['extended', INVOICE_PATH, id],
        queryFn: () => apiNew.get<never, IInvoiceWithRelations>(`${INVOICE_PATH}/${id}/extended`),
      },
      getVersions: {
        queryKey: ['versions', INVOICE_PATH, id],
        queryFn: () => apiNew.get<never, IInvoiceVersionsDTO>(`${INVOICE_PATH}/${id}/versions`),
      },
    },
  }),
});

/**
 * Get invoice by id
 */
export function useInvoiceById(id: IInvoice['id']) {
  return useSuspenseQuery(invoicesFactory.get(id));
}

/**
 * Get invoice by id
 */
export function useInvoiceByIdQuery(id: IInvoice['id'] | undefined) {
  return useQuery({
    ...invoicesFactory.get(id ?? 0),
    enabled: !!id,
  });
}

/**
 * Get invoice by id with extended infos
 */
export function useInvoiceQuery(id: IInvoice['id'] | undefined) {
  return useQuery({
    // eslint-disable-next-line no-underscore-dangle
    ...invoicesFactory.get(id!)._ctx.getExtended,
    enabled: !!id,
  });
}

/**
 * Get invoice versions
 * @param id Invoice id
 */
export function useInvoiceVersions(id: IInvoice['id']) {
  // eslint-disable-next-line no-underscore-dangle
  const data = useSuspenseQuery(invoicesFactory.get(id)._ctx.getVersions);
  usePdfVersionsTanStackQuery(data.data, data.refetch);

  return data;
}

export function useEditInvoiceTags() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (options: { id: IInvoice['id']; dto: IUpdateTagsDTO }) =>
      apiNew.patch<IUpdateTagsDTO, IInvoice>(`${INVOICE_PATH}/${options.id}/tags`, options.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:words.c.success'), t('global:changeTags.success'));
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useCreateInvoice() {
  const { t } = useTranslation(['invoice']);
  const toast = useToast();

  return useMutation({
    mutationFn: (invoiceCreationDTO: IInvoiceCreationDTO) =>
      apiNew.post<IInvoiceCreationDTO, IInvoiceWithRelations>(INVOICE_PATH, invoiceCreationDTO),
    onError: () => {
      toast.error(t('invoice:errors.creationFail'));
    },
  });
}

export function useDeleteInvoice() {
  const { t } = useTranslation(['global', 'statement']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: IInvoice['id']) => apiNew.delete<never, void>(`${INVOICE_PATH}/${id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('statement:delete.success'));
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useEditInvoice() {
  const { t } = useTranslation(['invoice']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (options: { id: IInvoice['id']; dto: IInvoiceUpdateDTO }) =>
      apiNew.patch<IInvoiceUpdateDTO, IInvoiceWithRelations>(`${INVOICE_PATH}/${options.id}`, options.dto),
    onError: () => {
      toast.error(t('invoice:errors.updateFail'));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useCancelInvoice(context: { cancelledInvoiceNumber: string | undefined }) {
  const { t } = useTranslation(['global', 'credit']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (options: { id: IInvoice['id']; dto: ICreditCreationDTO }) =>
      apiNew.patch<ICreditCreationDTO, IInvoice>(`${INVOICE_PATH}/${options.id}/cancel`, options.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('credit:toast.create.error'));
    },
    onSuccess: async (invoice) => {
      toast.success(
        t(`credit:toast.create.success.${STATEMENT_TYPES.INVOICE}`, {
          invoiceNumber: invoice?.credit?.invoiceNumber,
          cancelledInvoiceNumber: context.cancelledInvoiceNumber,
        }),
      );
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useForceDraftInvoice() {
  const { t } = useTranslation(['global', 'invoice']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: IInvoice['id']) => apiNew.patch<never, IInvoice>(`${INVOICE_PATH}/${id}/status/force-draft`),
    onError: () => {
      toast.error(t('global:words.c.error'));
    },
    onSuccess: async (invoice) => {
      toast.success(
        t('invoice:toasts.draftedWithoutCredit', {
          invoiceNumber: invoice.invoiceNumber,
        }),
      );
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useChangeInvoiceStatusToCompleted(context: { hasLedger: boolean }) {
  const { t } = useTranslation(['global', 'invoice']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (options: { id: IInvoice['id']; dto?: IInvoiceChangeStatusToCompleteDTO }) =>
      apiNew.patch<IInvoiceChangeStatusToCompleteDTO, IInvoice>(
        `${INVOICE_PATH}/${options.id}/status/completed`,
        options.dto,
      ),
    onError: () => {
      toast.error(t('global:words.c.error'), t('invoice:errors.completionFail'));
    },
    onSuccess: async (invoice) => {
      toast.success(
        t(context.hasLedger ? 'invoice:success.completionWithLedger' : 'invoice:success.completion', {
          invoiceNumber: invoice.invoiceNumber,
        }),
      );
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useLinkInvoiceToProject() {
  const { t } = useTranslation(['statement']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (options: { id: IInvoice['id']; projectId: IProject['id'] }) =>
      apiNew.patch<IInvoiceProjectLinkDTO, IInvoice>(`${INVOICE_PATH}/${options.id}/project-link`, {
        projectId: options.projectId,
      }),
    onError: () => {
      toast.error(t('statement:associateProject.errorLink'));
    },
    onSuccess: async () => {
      toast.success(t('statement:associateProject.successLink'));
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useUnlinkInvoiceToProject() {
  const { t } = useTranslation(['statement']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (options: { id: IInvoice['id'] }) =>
      apiNew.delete<never, IInvoice>(`${INVOICE_PATH}/${options.id}/project-link`),
    onError: () => {
      toast.error(t('statement:associateProject.errorUnlink'));
    },
    onSuccess: async () => {
      toast.success(t('statement:associateProject.successUnlink'));
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}
