/* eslint-disable react/destructuring-assignment */
import type {
  CSSObjectWithLabel,
  GroupBase,
  MenuListProps,
  MultiValueGenericProps,
  OptionProps,
  SingleValueProps,
} from 'chakra-react-select';
import { components } from 'chakra-react-select';
import { Box, HStack } from '@chakra-ui/react';

import type { BadgeProps } from '../Badge/Badge';
import { Badge, COLORS_CONFIG } from '../Badge/Badge';
import { LABELS_COLORS, isObject } from '../../utils';
import { SimpleChevronBottomIcon } from '../Icons';

import { useAutocompleteContext } from './AutocompleteContext';
import type { OptionType } from './Autocomplete.util';

export type AutocompleteOptionProps<Option extends OptionType, OtherProps> = {
  data: Option & OtherProps;
};

export const AutocompleteBadge = <Option extends OptionType>({
  data: { color, badgeColor, label, value, ...otherDataProps },
}: AutocompleteOptionProps<Option, Omit<BadgeProps, 'children'>>) => {
  if (label && isObject(value)) {
    throw new Error(
      'Value cannot be an object if label is not defined or if Autocomplete do not have a render callback.',
    );
  }

  return (
    <Badge showDot colorScheme={color ?? 'gray'} color={badgeColor} {...otherDataProps}>
      {/* Fallback is to handle new value typed by the user */}
      {label ?? (value as Exclude<OptionType['value'], object>)}
    </Badge>
  );
};

const SingleValue = <Option extends OptionType>({ children, ...props }: SingleValueProps<Option>) => {
  const renderOption = useAutocompleteContext();

  return (
    <Box p={2}>
      <components.SingleValue {...props}>{renderOption(props)}</components.SingleValue>
    </Box>
  );
};

const MultiValueLabelOutlined = (props: MultiValueGenericProps) => {
  if (!props.data.badgeColor) {
    const colorScheme = (props.data.colorScheme as string) ?? 'gray';
    const { bgDarker } = COLORS_CONFIG[colorScheme as keyof typeof COLORS_CONFIG];
    return (
      <Box py={2} fontWeight={500} color="#4A5568" backgroundColor="transparent" borderRadius="14px !important">
        <HStack gap={2}>
          <Box fontSize="0.5rem" color={bgDarker}>
            <i className="ri-circle-fill" />
          </Box>
          <components.MultiValueLabel {...props} />
        </HStack>
      </Box>
    );
  }

  const { text } = LABELS_COLORS[props.data.badgeColor as keyof typeof LABELS_COLORS];

  return (
    <Box py={2} fontWeight={500} color="primaryLight" backgroundColor="transparent" borderRadius="14px !important">
      <HStack gap={2}>
        <Box fontSize="0.5rem" color={text}>
          <i className="ri-circle-fill" />
        </Box>
        <components.MultiValueLabel {...props} />
      </HStack>
    </Box>
  );
};

const MultiValueContainerOutlined = (props: MultiValueGenericProps) => {
  if (props.data.badgeColor) {
    const { text } = LABELS_COLORS[props.data.badgeColor as keyof typeof LABELS_COLORS];

    return (
      <Box
        {...props}
        display="flex"
        alignItems="center"
        height="22px"
        borderWidth={1}
        color={text}
        borderColor="gray.200"
        backgroundColor="white !important"
        borderRadius="14px !important"
      />
    );
  }
  return (
    <Box
      height="22px"
      {...props}
      display="flex"
      alignItems="center"
      border="1px solid"
      borderColor="gray.200"
      backgroundColor="white !important"
      borderRadius="14px !important"
    />
  );
};

const MultiValueLabel = (props: MultiValueGenericProps) => (
  <Box py={2} fontWeight={600} pl={2} backgroundColor={props.data.badgeColor}>
    <components.MultiValueLabel {...props} />
  </Box>
);

const MultiValueContainer = (props: MultiValueGenericProps) =>
  props.data.badgeColor ? (
    <Box
      {...props}
      height="22px"
      display="flex"
      alignItems="center"
      borderColor={`${props.data.badgeColor} !important`}
      borderWidth={1}
      backgroundColor={`${props.data.badgeColor} !important`}
    />
  ) : (
    <Box height="22px" {...props} display="flex" alignItems="center" />
  );

const Option = <Option extends OptionType>({ data, ...props }: OptionProps<Option>) => {
  const renderOption = useAutocompleteContext();

  return (
    <HStack>
      <components.Option data={data} {...props}>
        {renderOption({ data })}
      </components.Option>
    </HStack>
  );
};

const MenuList = <Option extends OptionType>(props: MenuListProps<Option, boolean, GroupBase<Option>>) => (
  <Box
    borderRadius="8px"
    boxShadow="0px 27px 30px -15px rgba(0, 0, 0, 0.13), 0px 0px 3px -1px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
    bg="white"
  >
    <components.MenuList {...props}>{props.children}</components.MenuList>
  </Box>
);

const DropdownIndicator = () => (
  <SimpleChevronBottomIcon
    boxSize={3}
    color="gray.200"
    cursor="pointer"
    border="0 solid red"
    mt="10px"
    alignSelf="flex-start"
  />
);

const ClearIndicator = () => null;

export const autocompleteComponents = {
  Option,
  SingleValue,
  DropdownIndicator,
  ClearIndicator,
  MultiValueLabel,
  MultiValueContainer,
  MenuList,
};

export const autocompleteComponentsOutlined = {
  Option,
  SingleValue,
  DropdownIndicator,
  ClearIndicator,
  MenuList,
  MultiValueLabel: MultiValueLabelOutlined,
  MultiValueContainer: MultiValueContainerOutlined,
};

export const getAutocompleteComponentsStyle = (colors: Record<any, any>): any => ({
  multiValueLabel: (base: CSSObjectWithLabel) => ({
    ...base,
    padding: '5px',
    backgroundColor: colors.blue[100],
  }),
  multiValue: (base: CSSObjectWithLabel) => ({
    ...base,
    background: colors.blue[100],
  }),
  option: (base: CSSObjectWithLabel, state: OptionProps<any, boolean>) => ({
    ...base,
    '&:hover': {
      background: colors.gray[50],
    },
    ...(state.isSelected
      ? {
          background: colors.gray[100],
        }
      : {}),
    ...(state.isFocused
      ? {
          background: colors.gray[50],
        }
      : {}),
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({ ...provided, zIndex: 2000, borderRadius: '8px', minWidth: '16rem' }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: colors.blue[100],
  }),
});
