import type { FC } from 'react';
import { Flex, IconButton, Box } from '@chakra-ui/react';
import { usePlaceholder } from 'graneet-form';

import { EllipsisText } from '../EllipsisText';
import { SimpleCloseIcon } from '../Icons';

import { PLACEMENT } from './Footer';

export interface HeaderProps {
  title?: string;

  onQuit?: () => void | Promise<void>;
}

export const Header: FC<HeaderProps> = ({ title, onQuit }) => {
  const { placeholderContent } = usePlaceholder();

  return (
    <Flex
      backgroundColor="white"
      height={16}
      top="0"
      position="fixed"
      right="0"
      left="0"
      px={6}
      flexShrink={0}
      alignItems="center"
      justifyContent="space-between"
      borderTopLeftRadius={5}
      borderTopRightRadius={5}
      boxShadow="sm"
      zIndex={23}
    >
      <EllipsisText as="h1" fontSize="lg" fontWeight="bold" fontFamily="heading">
        {title}
      </EllipsisText>

      <Flex pl={2}>{placeholderContent && placeholderContent[PLACEMENT.HEADER_LEFT]}</Flex>

      <Box flex={1} flexGrow={1} />

      {placeholderContent && <Flex flexShrink={0}>{placeholderContent[PLACEMENT.HEADER_RIGHT]}</Flex>}

      {onQuit && (
        <Box ml={4}>
          <IconButton
            _hover={{
              bg: 'gray.150',
              borderRadius: '8px',
            }}
            aria-label="Close"
            bg="none"
            icon={<SimpleCloseIcon />}
            onClick={onQuit}
          />
        </Box>
      )}
    </Flex>
  );
};
