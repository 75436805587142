import type { ReactNode } from 'react';
import type { FieldValues } from 'graneet-form';
import { HiddenField, useFormContext, useHiddenField } from 'graneet-form';
import type { KeysMatching, LabeledDataProps, TextFieldValue } from '@graneet/lib-ui';
import { LabeledData, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { STATEMENT_SALES_TYPES } from '@graneet/business-logic';

import { useHasLedger } from '../hooks/useHasLedger';

interface InvoiceNumberFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, TextFieldValue> = KeysMatching<T, TextFieldValue>,
> {
  name: K;
  label: string;
  isRequired?: boolean;
  invoiceNumber?: string | null;
  children: ReactNode;
  type: STATEMENT_SALES_TYPES;
}

export const InvoiceNumberField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, TextFieldValue> = KeysMatching<T, TextFieldValue>,
>({
  name,
  label,
  children,
  isRequired,
  invoiceNumber,
  type,
  ...props
}: InvoiceNumberFieldProps<T, K>): JSX.Element => {
  const { t } = useTranslation(['statement']);
  const hasLedger = useHasLedger();

  const form = useFormContext<T>();
  const invoiceNumberHiddenField = useHiddenField(form, name);

  if (hasLedger) {
    const labeledDataProps: Omit<LabeledDataProps, 'label'> = invoiceNumber
      ? {
          data: invoiceNumber,
        }
      : {
          data: t('statement:statementLedger.placeholder'),
          variant: 'light',
          tooltip: t(`statement:statementLedger.tooltip.${type}`),
        };

    return (
      <>
        <HiddenField<T, K> {...invoiceNumberHiddenField} />
        <LabeledData label={label} {...labeledDataProps} />
      </>
    );
  }
  return (
    <TextField<T, K> data-testid="textField:invoiceNumber" name={name} label={label} isRequired={isRequired} {...props}>
      {children}
    </TextField>
  );
};
