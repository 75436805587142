import { GridItem } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { useContext } from 'react';

import { DataTableHeaderContext, dataTableHeaderStyle } from './DataTableHeader';

export interface DataTableCellProps {
  children?: ReactNode;
}

export const DataTableCell: FC<DataTableCellProps> = ({ children }) => {
  const dataTableHeaderContext = useContext(DataTableHeaderContext);

  return (
    <GridItem
      {...(dataTableHeaderContext.isInHeader ? dataTableHeaderStyle : {})}
      px={4}
      alignItems="baseline"
      textColor="primaryLight"
    >
      {children}
    </GridItem>
  );
};
