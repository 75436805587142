import { Switch, Route, useRouteMatch } from 'react-router-dom';
import type { FC } from 'react';

import { DisplayNotFoundScreen } from '../../DisplayNotFoundScreen';

import { ViewClientsScreen } from './ViewClientsScreen';
import { ViewClientScreen } from './ViewClientScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';

export const ClientsRouter: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ViewClientsScreen />
      </Route>

      <Route path={`${path}/:clientId`}>
        <QueryWrapper>
          <ViewClientScreen />
        </QueryWrapper>
      </Route>

      <Route path="*">
        <DisplayNotFoundScreen />
      </Route>
    </Switch>
  );
};
