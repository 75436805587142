import type { Context } from 'react';
import { createContext, useContext } from 'react';

interface IBadgeGroupContext<T extends unknown> {
  onBlur(): void;
  onChange(newValue: T): void;
  onFocus(): void;
  fieldValues: T[] | null | undefined;
}

export const BadgeGroupContext = createContext<IBadgeGroupContext<unknown>>({
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  fieldValues: [],
});

export const useBadgeGroupContext = <T extends unknown>() =>
  useContext<IBadgeGroupContext<T>>(BadgeGroupContext as Context<IBadgeGroupContext<T>>);
