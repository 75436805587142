import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleContactIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props} fill="none">
    <path
      d="M14 14C14 12.7467 13.98 12.4533 13.8533 12.0267C13.5267 10.96 12.6933 10.1267 11.6267 9.8C11.2 9.66667 10.9 9.65333 9.65333 9.65333H6.31999C5.06666 9.65333 4.77333 9.66667 4.34666 9.79333C3.27999 10.1133 2.44666 10.9467 2.11999 12.0133C1.98666 12.4333 1.97333 12.7333 1.97333 13.98C1.97333 14.3467 2.26666 14.6467 2.63999 14.6467C3.00666 14.6467 3.30666 14.3467 3.30666 13.98C3.30666 12.9 3.31999 12.6333 3.38666 12.3933C3.57999 11.7533 4.07999 11.2533 4.71333 11.0533C4.94666 10.98 5.21333 10.9667 6.29333 10.9667H9.62666C10.7 10.9667 10.9667 10.98 11.2067 11.0467C11.84 11.24 12.34 11.74 12.5333 12.38C12.6 12.6133 12.6133 12.88 12.6133 13.96C12.6133 14.3267 12.9067 14.6267 13.28 14.6267C13.6467 14.6267 13.9467 14.3267 13.9467 13.96L14 14ZM10.3333 5C10.3333 6.28666 9.28666 7.33333 7.99999 7.33333C6.70666 7.33333 5.66666 6.28666 5.66666 5C5.66666 3.70667 6.70666 2.66667 7.99999 2.66667C9.28666 2.66667 10.3333 3.70667 10.3333 5ZM11.6667 5C11.6667 2.97333 10.02 1.33333 7.99999 1.33333C5.97333 1.33333 4.33333 2.97333 4.33333 5C4.33333 7.02 5.97333 8.66667 7.99999 8.66667C10.02 8.66667 11.6667 7.02 11.6667 5Z"
      fill="#627D98"
    />
  </Icon>
);
