import { createQuoteCustomDiscountDeletedCallback } from './createQuoteCustomDiscountDeletedCallback';
import { createQuoteCustomDiscountUpsertedCallback } from './createQuoteCustomDiscountUpsertedCallback';

import type {
  QuoteCustomDiscountWsMessage,
  QuoteSalesDiscountsWsMessage,
} from 'features/quotation/quote-common/interfaces/quoteWsMessage';

export const quoteCustomDiscountCallbacks = (quoteId: String) => [
  {
    channel: `private-quote-${quoteId}`,
    event: 'customDiscount:updated',
    callback: (message: QuoteCustomDiscountWsMessage) => createQuoteCustomDiscountUpsertedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'customDiscount:added',
    callback: (message: QuoteCustomDiscountWsMessage) => createQuoteCustomDiscountUpsertedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'customDiscount:deleted',
    callback: (message: QuoteSalesDiscountsWsMessage) => createQuoteCustomDiscountDeletedCallback(message),
  },
];
