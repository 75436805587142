import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const HeartIcon: FC<IconProps> = ({ color = '#DA1E0C', ...otherProps }) => (
  <Icon viewBox="0 0 14 13" color={color} {...otherProps}>
    <path
      fill="currentColor"
      d="M6.99844 12.6016L6.04144 11.7383C2.64244 8.68439 0.398438 6.66369 0.398438 4.19829C0.398438 2.17758 1.99564 0.601562 4.02844 0.601562C5.17684 0.601562 6.27904 1.13126 6.99844 1.96178C7.71784 1.13126 8.82004 0.601562 9.96844 0.601562C12.0012 0.601562 13.5984 2.17758 13.5984 4.19829C13.5984 6.66369 11.3544 8.68439 7.95544 11.7383L6.99844 12.6016Z"
    />
  </Icon>
);
