import { Legend, Bar } from 'recharts';
import type { StyleProps } from '@chakra-ui/react';
import { Fragment } from 'react';

import { useChakraColors } from '../../../hooks';
import type { CustomBarChartProps } from '../common/components/CustomBarChart/CustomBarChart';
import { CustomBarChart } from '../common/components/CustomBarChart/CustomBarChart';
import { CustomLegendInline } from '../common';

const Y_AXIS_WIDTH = 50;

export interface GroupedBarChartProps<T extends object> extends Omit<CustomBarChartProps<T>, 'barStyle'> {
  columns: Array<{
    name: Extract<keyof T, string>;
    color: StyleProps['color'];
    stackId?: string;
    isHatched?: boolean;
  }>;
}

export const GroupedBarChart = <T extends object>({
  data,
  xAxis,
  yAxis,
  tooltip,
  legends,
  columns,
}: GroupedBarChartProps<T>) => {
  const colorsToUse = useChakraColors(
    columns.reduce<Record<string, StyleProps['color']>>((acc, column) => {
      acc[column.name] = column.color;
      return acc;
    }, {}),
  );

  return (
    <CustomBarChart<T>
      data={data}
      xAxis={xAxis}
      yAxis={yAxis}
      tooltip={tooltip}
      barStyle={{
        size: 21,
        gap: 8,
        categoryGap: 32,
      }}
      legends={legends}
    >
      {columns.map((column) => {
        const id = `${column.name}-hachurePattern`;

        return (
          <Fragment key={column.name}>
            <defs>
              <pattern id={id} patternUnits="userSpaceOnUse" width="10" height="10">
                <rect width="10" height="10" fill={colorsToUse[column.name]} />
                <path d="M0 10 L10 0" stroke="white" strokeWidth="2" />
              </pattern>
            </defs>
            <Bar
              dataKey={column.name}
              fill={column.isHatched ? `url(#${id})` : colorsToUse[column.name]}
              radius={[4, 4, 0, 0]}
              stackId={column.stackId}
            />
            ;
          </Fragment>
        );
      })}

      <Legend
        content={<CustomLegendInline<T> legends={legends} />}
        wrapperStyle={{
          // To center the element according to the cratesian grid, removing the Y axis width
          marginLeft: Y_AXIS_WIDTH,
          width: `calc(100% - ${Y_AXIS_WIDTH * 2}px`,
        }}
      />
    </CustomBarChart>
  );
};
