import { useTranslation } from 'react-i18next';
import { Button, Flex, FormControl, FormLabel, HStack } from '@chakra-ui/react';
import { ModalSubtitle, SingleSelectField, UnitField } from '@graneet/lib-ui';
import { THIRD_PARTY } from '@graneet/business-logic';

import type { TimeSlotCreateForm } from './TimeSlotCreateModalForm';
import { getTimeslotOvertimesHourFieldName, getTimeslotOvertimesTypeIdFieldName } from './TimeSlotCreateModalForm';
import type { ISelectedTimeslotOvertime } from './WorkingTimeForm';

import { useThirdParty } from 'features/third-party/hooks/useThirdParty';

interface WorkingHoursBreakDownProps {
  selectedTimeslotOvertimes: ISelectedTimeslotOvertime;
  day: string;
  selectableOvertimeTypes: any;
  addOvertime: (_day: string) => void;
}

export const WorkingHoursBreakDown = ({
  selectedTimeslotOvertimes,
  day,
  selectableOvertimeTypes,
  addOvertime,
}: WorkingHoursBreakDownProps) => {
  const hasAlobees = useThirdParty(THIRD_PARTY.ALOBEES);
  const { t } = useTranslation(['global', 'timeTracking']);
  return (
    <FormControl>
      <ModalSubtitle pl="1px" mt={4}>
        {t('timeTracking:createTimeSlotModal.fields.workingHoursBreakDown')}
      </ModalSubtitle>
      {selectedTimeslotOvertimes.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <HStack key={index + day} mb={2}>
          <Flex direction="column" w="6rem">
            {!index && (
              <FormLabel pl="1" fontSize="xs" lineHeight="1.25rem" fontWeight="600" color="gray.600">
                {t('timeTracking:createTimeSlotModal.fields.billed')}
              </FormLabel>
            )}
            <UnitField<TimeSlotCreateForm>
              name={getTimeslotOvertimesHourFieldName(day, index)}
              min={0}
              isRequired
              unit={t('timeTracking:createTimeSlotModal.fields.hours')}
            />
          </Flex>

          <Flex direction="column" w="100%">
            {!index && (
              <FormLabel pl="1" fontSize="xs" lineHeight="1.25rem" fontWeight="600" color="gray.600">
                {t('timeTracking:createTimeSlotModal.fields.hourTypes')}
              </FormLabel>
            )}

            <SingleSelectField<TimeSlotCreateForm>
              name={getTimeslotOvertimesTypeIdFieldName(day, index)}
              options={selectableOvertimeTypes}
              isClearable={false}
            />
          </Flex>
        </HStack>
      ))}

      {!hasAlobees && selectedTimeslotOvertimes.length < selectableOvertimeTypes.length && (
        <Button variant="ghost" color="greenBrand.light" marginTop={2} onClick={() => addOvertime(day)}>
          + {t('timeTracking:createTimeSlotModal.fields.addHourType')}
        </Button>
      )}
    </FormControl>
  );
};
