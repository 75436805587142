import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleExcelIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 18 18" strokeWidth="2" {...props}>
    <g clipPath="url(#clip0_4179_492539)">
      <path
        d="M17.25 1.5H6.375C6.17609 1.5 5.98532 1.57902 5.84467 1.71967C5.70402 1.86032 5.625 2.05109 5.625 2.25V3.375H7.125V3H10.875V5.535H10.125V5.625V6.9825H10.875V9.5175H10.125V10.965H10.875V13.5H9.81C9.61368 13.8401 9.33178 14.1228 8.99232 14.3202C8.65286 14.5175 8.26766 14.6226 7.875 14.625H5.625V15.75C5.625 15.9489 5.70402 16.1397 5.84467 16.2803C5.98532 16.421 6.17609 16.5 6.375 16.5H17.25C17.4489 16.5 17.6397 16.421 17.7803 16.2803C17.921 16.1397 18 15.9489 18 15.75V2.25C18 2.05109 17.921 1.86032 17.7803 1.71967C17.6397 1.57902 17.4489 1.5 17.25 1.5ZM16.5 13.5H12.75V10.965H16.5V13.5ZM16.5 9.5175H12.75V6.9825H16.5V9.5175ZM16.5 5.535H12.75V3H16.5V5.535Z"
        fill="#007440"
      />
      <path
        d="M7.125 13.5H7.875C8.17337 13.5 8.45952 13.3815 8.6705 13.1705C8.88147 12.9595 9 12.6734 9 12.375V5.625C9 5.32663 8.88147 5.04048 8.6705 4.8295C8.45952 4.61853 8.17337 4.5 7.875 4.5H1.125C0.826631 4.5 0.540483 4.61853 0.329505 4.8295C0.118526 5.04048 0 5.32663 0 5.625L0 12.375C0 12.6734 0.118526 12.9595 0.329505 13.1705C0.540483 13.3815 0.826631 13.5 1.125 13.5H7.125ZM3.525 6.375L4.5 7.935L5.475 6.375H6.8025L5.16 9L6.8025 11.625H5.475L4.5 10.065L3.525 11.625H2.1975L3.84 9L2.1975 6.375H3.525Z"
        fill="#007440"
      />
    </g>
    <defs>
      <clipPath id="clip0_4179_492539">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
