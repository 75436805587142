import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { uniqBy } from 'lodash-es';

import { SingleImage } from './SingleImage';
import type { ImageItem, ImageListProps } from './types';
import type { AddImageHandle } from './AddImage';
import { AddImage } from './AddImage';
import { CarrouselModal } from './CarouselModal';

export type ImageListAndUploadHandle = {
  addImage: () => void;
};

export const ImageListAndUpload = forwardRef<ImageListAndUploadHandle, ImageListProps>(
  ({ images, hideWhenEmpty = false, onImageDelete, onImageUpload, noPreview, isEditable, showAddFile, size }, ref) => {
    const [imageRecord, setImageRecord] = useState<Record<string, ImageItem>>({});
    const [selectedImage, setSelectedImage] = useState<ImageItem | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const inputRef = useRef<AddImageHandle>(null);

    const handleOnFilesAdded = useCallback(
      (imgs: ImageItem[]) => {
        const newRecord = {
          ...imageRecord,
        };
        imgs.forEach((img) => {
          if (newRecord[img.id]) {
            newRecord[img.id] = {
              ...newRecord[img.id],
              ...img,
            };
          } else {
            newRecord[img.id] = img;
          }
        });
        setImageRecord(newRecord);
      },
      [imageRecord],
    );

    const onImageClick = useCallback(
      (image: ImageItem) => {
        setSelectedImage(image);
        onOpen();
      },
      [onOpen],
    );

    useImperativeHandle(ref, () => ({
      addImage: () => {
        if (isEditable) {
          inputRef.current?.addImage();
        }
      },
    }));

    const allImages = useMemo(() => uniqBy([...images, ...Object.values(imageRecord)], 'id'), [images, imageRecord]);

    return (
      <Flex width="100%" height="100%" gap="4px">
        {((hideWhenEmpty && allImages.length > 0) || !hideWhenEmpty) && (
          <Flex flexGrow={1} gap="4px" overflow="hidden" paddingY="4px">
            {allImages.map((image) => (
              <SingleImage
                key={image.id}
                image={image}
                onImageClick={onImageClick}
                onImageDelete={onImageDelete}
                isEditable={isEditable}
                size={size}
              />
            ))}
            <CarrouselModal images={allImages} onClose={onClose} isOpen={isOpen} selectedImage={selectedImage} />
          </Flex>
        )}
        {isEditable && (
          <Box paddingY="4px">
            <AddImage
              noPreview={noPreview}
              onImageUpload={onImageUpload}
              onFilesAdded={handleOnFilesAdded}
              isDisplayed={!hideWhenEmpty || (hideWhenEmpty && allImages.length > 0)}
              ref={inputRef}
              showAddFile={showAddFile}
              size={size}
            />
          </Box>
        )}
      </Flex>
    );
  },
);
