import type {
  QuoteHiddenCostItemPopulateDTO,
  QuoteHiddenCostItemTransformDTO,
  QuoteHiddenCostItemUpsertDTO,
  QuoteHiddenCostItemsApi,
} from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteHiddenCostItemsProxyApi {
  private static quoteHiddenCostItemsProxyApi: QuoteHiddenCostItemsProxyApi;

  private readonly quoteHiddenCostItemsApi: QuoteHiddenCostItemsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteHiddenCostItemsApi = externalApi.getQuoteHiddenCostItemsApi();
  }

  public static getQuoteHiddenCostItemsProxyApi(externalApi: ExternalApi): QuoteHiddenCostItemsProxyApi {
    if (!this.quoteHiddenCostItemsProxyApi) {
      QuoteHiddenCostItemsProxyApi.quoteHiddenCostItemsProxyApi = new QuoteHiddenCostItemsProxyApi(externalApi);
    }
    return QuoteHiddenCostItemsProxyApi.quoteHiddenCostItemsProxyApi;
  }

  public async createQuoteHiddenCostItem(quoteHiddenCostItemCreateDTO: QuoteHiddenCostItemUpsertDTO) {
    return proxyHelper(async () =>
      this.quoteHiddenCostItemsApi.createQuoteHiddenCostItem(quoteHiddenCostItemCreateDTO),
    );
  }

  public async updateQuoteHiddenCostItem(
    quoteHiddenCostItemId: string,
    quoteHiddenCostItemUpdateDTO: QuoteHiddenCostItemUpsertDTO,
  ) {
    return proxyHelper(async () =>
      this.quoteHiddenCostItemsApi.updateQuoteHiddenCostItem(quoteHiddenCostItemId, quoteHiddenCostItemUpdateDTO),
    );
  }

  public async transformQuoteHiddenCostItem(
    quoteHiddenCostItemId: string,
    quoteHiddenCostItemTransformDTO: QuoteHiddenCostItemTransformDTO,
  ) {
    return proxyHelper(async () =>
      this.quoteHiddenCostItemsApi.transformQuoteHiddenCostItem(quoteHiddenCostItemId, quoteHiddenCostItemTransformDTO),
    );
  }

  public async populateQuoteHiddenCostItem(quoteHiddenCostItemUpsertManyDTO: QuoteHiddenCostItemPopulateDTO) {
    return proxyHelper(async () =>
      this.quoteHiddenCostItemsApi.populateQuoteHiddenCostItem(quoteHiddenCostItemUpsertManyDTO),
    );
  }
}
