import type { FC } from 'react';
import type { GridProps } from '@chakra-ui/react';
import { Grid } from '@chakra-ui/react';

import { Item } from './components/Item';
import { VATDistribution } from './components/VATDistribution';
import { Separator } from './components/Separator';
import { Highlight } from './components/Highlight';

const AmountSummaryComponent: FC<GridProps> = ({ children, ...props }) => (
  <Grid templateColumns="1fr minmax(5rem, auto) max-content" gap={2} mt={5} {...props}>
    {children}
  </Grid>
);

export const AmountSummary = Object.assign(AmountSummaryComponent, {
  Item,
  VATDistribution,
  Separator,
  Highlight,
});
