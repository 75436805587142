import type { FC } from 'react';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import { ActionMenu, Button, ListingLayout, useCurrency } from '@graneet/lib-ui';
import type { ILibraryComponentWithRelations } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { keyBy } from 'lodash-es';

import {
  useLibraryComponentImport,
  useLibraryComponents,
} from 'features/library-component/services/library-component.api';
import { useComponentTypes } from 'features/component-type/services/component-type.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { TEMPLATE_URL } from 'features/import/constants/import.constant';
import { formatImportAmount } from 'features/import/utils/import.util';
import type { ImportLibraryComponentField } from 'features/library-component/hooks/useImportLibraryComponentFields';
import { useImportLibraryComponentFields } from 'features/library-component/hooks/useImportLibraryComponentFields';
import { LibraryComponentTable } from 'features/library-component/components/LibraryComponentTable';
import type { ImportSpreadsheetRowHook, ImportSpreadsheetSubmit } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImport } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImportMenu } from 'features/import/components/SpreadsheetImportMenu';
import { SpreadsheetImportingModal } from 'features/import/components/SpreadsheetImportingModal';
import { LibraryComponentEditModal } from 'features/library-component/components/modals/LibraryComponentEditModal';
import { LibraryComponentDuplicateModal } from 'features/library-component/components/modals/LibraryComponentDuplicateModal';
import { LibraryComponentDeleteModal } from 'features/library-component/components/modals/LibraryComponentDeleteModal';
import { LibraryComponentAddModal } from 'features/library-component/components/modals/LibraryComponentAddModal';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

const EDIT_PERMISSIONS = [PERMISSION.UPDATE_LIBRARY];

export const ViewLibraryComponentsScreen: FC = () => {
  const { t } = useTranslation(['library', 'statement', 'global']);
  const { updateHeaderTitle } = useHeaderContext();
  const { mapAmountToNumber } = useCurrency();

  const componentTypes = useComponentTypes();
  const libraryComponents = useLibraryComponents();

  const libraryComponentImportMutation = useLibraryComponentImport();

  const importFields = useImportLibraryComponentFields(componentTypes.data);

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.library'));
  }, [t, updateHeaderTitle]);

  const { isOpen: isImportOpen, onClose: onCloseImport, onOpen: onOpenImport } = useDisclosure();
  const importingModalControls = useDisclosure();

  const indexedComponentTypes = useMemo(
    () => keyBy(componentTypes.data, (componentType) => componentType.id.toString()),
    [componentTypes.data],
  );

  const importRowHook = useCallback<ImportSpreadsheetRowHook<ImportLibraryComponentField>>(
    (rowData, addError) => {
      const transformedData = { ...rowData };
      if (rowData.unit_price !== undefined) {
        transformedData.unit_price = formatImportAmount(rowData.unit_price.toString());
      }

      // Warn if unit is different from the component type
      let displayWarning = false;
      if (rowData.type) {
        const componentType = indexedComponentTypes[rowData.type.toString()];
        if (componentType && componentType.unit !== null && componentType.unit !== rowData.unit) {
          displayWarning = true;
          addError('unit', {
            message: t('library:libraryComponent.import.warnings.unit', { unit: componentType.unit }),
            level: 'warning',
          });
        }
      }

      // Unit is mandatory
      // "required" validation is not used because warning is not overriding
      if (!rowData.unit && !displayWarning) {
        addError('unit', {
          message: t('global:spreadsheetImport.common.errorMandatory'),
          level: 'error',
        });
      }

      return transformedData;
    },
    [indexedComponentTypes, t],
  );

  const onSubmitImport = useCallback<ImportSpreadsheetSubmit<ImportLibraryComponentField>>(
    async (importData) => {
      importingModalControls.onOpen();

      const dto = importData.validData.map((row) => {
        const componentType = indexedComponentTypes[row.type!.toString()];
        return {
          componentTypeId: parseInt(row.type!.toString(), 10),
          refCode: row.code?.toString() || null,
          description: row.name!.toString(),
          unit: componentType?.unit === null ? row.unit!.toString() : componentType?.unit,
          unitDisbursementExVAT: mapAmountToNumber(parseFloat(row.unit_price!.toString())),
        };
      });
      libraryComponentImportMutation.mutate(
        { components: dto },
        {
          onSuccess: () => {
            importingModalControls.onClose();
          },
        },
      );
    },
    [importingModalControls, libraryComponentImportMutation, indexedComponentTypes, mapAmountToNumber],
  );

  const hasUpdateLibraryPermission = usePermissions(EDIT_PERMISSIONS);
  const updateLibraryButtonDisabledProps = useDisabledButtonProps(EDIT_PERMISSIONS);

  const [libraryComponent, setLibraryComponent] = useState<ILibraryComponentWithRelations | null>(null);
  const creationModalControls = useDisclosure();
  const removeModalControls = useDisclosure({
    onClose() {
      setLibraryComponent(null);
    },
  });
  const duplicateModalControls = useDisclosure();
  const modifyModalControls = useDisclosure({
    onClose() {
      setLibraryComponent(null);
    },
  });

  const handleActionModify = useCallback(
    (libraryComponentToModify: ILibraryComponentWithRelations) => () => {
      setLibraryComponent(libraryComponentToModify);
      modifyModalControls.onOpen();
    },
    [modifyModalControls],
  );

  const handleActionDuplicate = useCallback(
    (libraryComponentToDuplicate: ILibraryComponentWithRelations) => () => {
      setLibraryComponent(libraryComponentToDuplicate);
      duplicateModalControls.onOpen();
    },
    [duplicateModalControls],
  );

  const handleActionRemove = useCallback(
    (libraryComponentToRemove: ILibraryComponentWithRelations) => () => {
      setLibraryComponent(libraryComponentToRemove);
      removeModalControls.onOpen();
    },
    [removeModalControls],
  );

  const availableTypes = useMemo(
    () =>
      componentTypes.data.map((type) => ({
        value: type.id.toString(),
        colorScheme: type.color,
        label: type.name,
        variant: 'rounded',
      })),
    [componentTypes.data],
  );

  const renderActionsMenu = useCallback(
    (lc: ILibraryComponentWithRelations) => (
      <ActionMenu>
        <ActionMenu.Edit onClick={handleActionModify(lc)} />
        <ActionMenu.Duplicate onClick={handleActionDuplicate(lc)} />
        <ActionMenu.Delete onClick={handleActionRemove(lc)} />
      </ActionMenu>
    ),
    [handleActionModify, handleActionDuplicate, handleActionRemove],
  );

  return (
    <>
      <ListingLayout
        pagination={libraryComponents}
        search={{
          placeholder: t('library:actions.searchLibraryComponent'),
        }}
        filters={[
          {
            type: 'multi',
            name: 'componentType',
            availableValues: availableTypes,
            label: t('statement:fields.type'),
            placeholder: t('global:words.c.select'),
          },
        ]}
        actions={
          <>
            <Button onClick={creationModalControls.onOpen} {...updateLibraryButtonDisabledProps()}>
              {t('library:actions.addLibraryComponent')}
            </Button>
            {!updateLibraryButtonDisabledProps().isDisabled && (
              <SpreadsheetImportMenu
                label={t('library:actions.importLibraryComponents')}
                templateUrl={TEMPLATE_URL.LIBRARY_COMPONENTS}
                onOpen={onOpenImport}
              />
            )}
          </>
        }
        content={
          <LibraryComponentTable
            gridId="library-component"
            libraryComponents={libraryComponents}
            renderActionsMenu={hasUpdateLibraryPermission ? renderActionsMenu : undefined}
            isResizable
          />
        }
      />

      {isImportOpen && (
        <SpreadsheetImport
          onClose={onCloseImport}
          onSubmit={onSubmitImport}
          fields={importFields}
          rowHook={importRowHook}
        />
      )}
      <SpreadsheetImportingModal {...importingModalControls} />
      <LibraryComponentAddModal
        key={`${creationModalControls.isOpen}`}
        onComponentCreated={libraryComponents.refetch}
        modalControls={creationModalControls}
      />
      {libraryComponent && (
        <>
          <LibraryComponentEditModal
            modalControls={modifyModalControls}
            libraryComponentToModify={libraryComponent}
            onLibraryComponentUpdated={libraryComponents.refetch}
          />

          <LibraryComponentDuplicateModal
            key={libraryComponent.id}
            sourceLibraryComponent={libraryComponent}
            modalControls={duplicateModalControls}
            onLibraryComponentDuplicated={libraryComponents.refetch}
          />

          <LibraryComponentDeleteModal
            libraryComponent={libraryComponent}
            modalControls={removeModalControls}
            onLibraryComponentRemoved={libraryComponents.refetch}
          />
        </>
      )}
    </>
  );
};
