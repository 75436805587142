import { useTranslation } from 'react-i18next';
import { Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { ActionMenu, Card, Section, UpsellCard, useCurrency } from '@graneet/lib-ui';
import { FEATURE_FLAGS, PAGINATION_PARAMS } from '@graneet/business-logic';
import type { IUserPaginated, IValidationStepWithRelations } from '@graneet/business-logic';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Form, useForm } from 'graneet-form';
import { uniq } from 'lodash-es';

import { useGetValidationStepsSupplierInvoice } from '../services/validation-step-supplier-invoice.api';
import { ValidationStepSupplierInvoiceDeleteModal } from '../modals/ValidationStepSupplierInvoiceDeleteModal';
import { ValidationStepSupplierInvoiceUpsertModal } from '../modals/ValidationStepSupplierInvoiceUpsertModal';

import { ValidationStepCard } from './ValidationStepCard';
import { AddValidationStepButton } from './AddValidationStepButton';
import { ValidationStepListEmptyState } from './ValidationStepListEmptyState';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useUpdateCompanySupplierInvoiceValidationReminders } from 'features/company/services/company.api';
import type { FormValuesUserToRemind } from 'features/mailing/components/UserToRemindTable';
import {
  buildUserFieldName,
  getUserIdFromFieldName,
  isUserFieldName,
  UserToRemindTable,
} from 'features/mailing/components/UserToRemindTable';
import { useAppContext } from 'features/app/contexts/AppContext';
import { useUsersPaginated } from 'features/user/services/user.api';
import { createURLSearchParams } from 'features/api/services/api.util';

interface ValidationStepSupplierInvoiceCardProps {
  step: IValidationStepWithRelations;
  index: number;
  isLast: boolean;
  alreadyUsedAmounts: number[];
}

const ValidationStepSupplierInvoiceCard: FC<ValidationStepSupplierInvoiceCardProps> = ({
  step,
  index,
  isLast,
  alreadyUsedAmounts,
}) => {
  const upsertModal = useDisclosure();
  const deleteModal = useDisclosure();
  const { t } = useTranslation(['validationStep']);
  const { formatAsAmount } = useCurrency();

  const renderCondition = useMemo(
    () => (
      <>
        <Text>{t('validationStep:supplierInvoice.validationStepCondition')}</Text>
        <Text textColor="gray.800" fontWeight={600}>
          {formatAsAmount(step.validationCondition.value)}
        </Text>
      </>
    ),
    [formatAsAmount, step.validationCondition.value, t],
  );

  const renderActions = useMemo(
    () => (
      <ActionMenu>
        <ActionMenu.Edit onClick={upsertModal.onOpen} />
        <ActionMenu.Delete onClick={deleteModal.onOpen} />
      </ActionMenu>
    ),
    [deleteModal.onOpen, upsertModal.onOpen],
  );

  return (
    <>
      <ValidationStepSupplierInvoiceDeleteModal step={step} isOpen={deleteModal.isOpen} onClose={deleteModal.onClose} />
      <ValidationStepSupplierInvoiceUpsertModal
        step={step}
        isOpen={upsertModal.isOpen}
        onClose={upsertModal.onClose}
        alreadyUsedAmounts={alreadyUsedAmounts}
      />
      <ValidationStepCard
        index={index}
        isLast={isLast}
        renderCondition={renderCondition}
        renderActions={renderActions}
        validators={step.validators.map(({ validator }) => validator)}
        areAccountManagersIncluded={step.areAccountManagersIncluded}
      />
    </>
  );
};

interface FormValuesWorkflow extends FormValuesUserToRemind {}

export const ValidationStepSupplierInvoiceList = () => {
  // @[ff: workflow-validation]
  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);
  const { t } = useTranslation(['validationStep', 'mailing']);
  const { currentUser } = useAppContext();

  // TODO @Victor
  const { data } = useUsersPaginated(
    createURLSearchParams({
      [PAGINATION_PARAMS.FULL]: true,
      includeProjectManagers: true,
    }),
  );

  const upsertModal = useDisclosure();
  const { mapNumberToAmount } = useCurrency();

  const validationSteps = useGetValidationStepsSupplierInvoice();
  const updateCompanySupplierInvoiceValidationRemindersMutation = useUpdateCompanySupplierInvoiceValidationReminders();

  const defaultValues: Partial<FormValuesWorkflow> = {
    frequencyWeekday: currentUser?.company?.frequencyWeekdaySupplierInvoiceValidationReminder?.toString() || '0',
  };
  data.data.forEach((user: IUserPaginated) => {
    if (user.sendSupplierInvoiceValidationReminder) {
      defaultValues[buildUserFieldName(user)] = true;
    }
  });

  const form = useForm<FormValuesWorkflow>({
    defaultValues,
    onUpdateAfterBlur: async () => {
      const { frequencyWeekday, ...userIdsValues } = form.getFormValues();

      const userIds = Object.keys(userIdsValues).reduce(
        (result, fieldName) => {
          if (isUserFieldName(fieldName)) {
            const userId = getUserIdFromFieldName(fieldName);
            if (form.getFormValues()[fieldName]) {
              result.userIdsToSendReminder.push(userId);
            } else if (form.getFormValues()[fieldName] === false) {
              result.userIdsToDeleteReminder.push(userId);
            }
          }
          return result;
        },
        { userIdsToSendReminder: [], userIdsToDeleteReminder: [] } as {
          userIdsToSendReminder: number[];
          userIdsToDeleteReminder: number[];
        },
      );

      await updateCompanySupplierInvoiceValidationRemindersMutation.mutateAsync({
        ...userIds,
        frequencyWeekdaySupplierInvoiceValidationReminder: parseInt(frequencyWeekday || '0', 10),
      });
    },
  });

  const validatorIds = validationSteps.data.flatMap(({ validators }) =>
    validators.map(({ validator }) => validator.id),
  );

  const filteredUsers = useMemo(() => {
    let accountManagerValidators: IUserPaginated[] = [];
    const areAccountManagersIncluded = validationSteps.data.some((step) => step.areAccountManagersIncluded);
    if (areAccountManagersIncluded) {
      accountManagerValidators = data.data.filter(({ projectAccountManagers }) => projectAccountManagers?.length);
    }
    return uniq([...data.data.filter(({ id }) => validatorIds.includes(id)), ...accountManagerValidators]);
  }, [data.data, validationSteps.data, validatorIds]);

  return (
    <Stack spacing={6}>
      <ValidationStepSupplierInvoiceUpsertModal
        isOpen={upsertModal.isOpen}
        onClose={upsertModal.onClose}
        alreadyUsedAmounts={validationSteps.data.map(({ validationCondition }) =>
          mapNumberToAmount(validationCondition.value),
        )}
      />

      {!hasWorkflowValidationFeatureFlag && <UpsellCard description={t('validationStep:supplierInvoice.upsell')} />}

      {validationSteps.data.length === 0 && hasWorkflowValidationFeatureFlag && (
        <ValidationStepListEmptyState
          openModal={upsertModal.onOpen}
          emptyStateDescription={t('validationStep:supplierInvoice.emptyStateDescription')}
        />
      )}
      {validationSteps.data.length > 0 && hasWorkflowValidationFeatureFlag && (
        <>
          <VStack alignItems="inherit">
            {validationSteps.data.map((validationStep, index) => (
              <ValidationStepSupplierInvoiceCard
                step={validationStep}
                index={index}
                key={validationStep.id}
                isLast={index + 1 === validationSteps.data.length}
                alreadyUsedAmounts={validationSteps.data.map(({ validationCondition }) =>
                  mapNumberToAmount(validationCondition.value),
                )}
              />
            ))}
            <AddValidationStepButton openModal={upsertModal.onOpen} mt={6} />
          </VStack>
        </>
      )}
      {hasWorkflowValidationFeatureFlag && (
        <Form form={form}>
          <Section title={t('mailing:remindersCard.isLateBillRemindersEnabledCheckboxLabel')}>
            <Card>
              <Text fontSize="sm" color="gray.600">
                {t('validationStep:supplierInvoice.remindersCardDescription')}
              </Text>

              <UserToRemindTable users={filteredUsers} />
            </Card>
          </Section>
        </Form>
      )}
    </Stack>
  );
};
