import type { FC, PropsWithChildren } from 'react';
import { useEffect, useContext, useCallback, createContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import type { IUserResponseDTO } from '@graneet/business-logic';
import { ENV } from '@graneet/business-logic';
import { useQueryClient } from '@tanstack/react-query';

import { useAuth } from '../components/AuthProvider';
import { useVitally } from '../hooks/useVitally';
import { useStore } from '../../../store/store';

import { useCurrentUser, userKeyFactory } from 'features/user/services/user.api';
import { useOnUserCompanyChanged } from 'features/company/hooks/useOnUserCompanyChanged';
import { getEnvValue } from 'config/env';
import { changeDayJSLocale } from 'config/dayjs';
import i18n from 'locales/i18next';
import { ExternalApi } from 'config/externalApi';

interface IAppContext {
  signOut(): void;
  authToken: string | null;
  currentUser: IUserResponseDTO;
  externalApi: ExternalApi | null;
  isFullScreen: boolean;
  setIsFullScreen(isFullScreen: boolean): void;

  updateCurrentUser(): Promise<void>;
}

export const AppContext = createContext<IAppContext>({
  signOut: () => {},
  authToken: null,
  currentUser: {} as IUserResponseDTO,
  externalApi: null,

  isFullScreen: false,
  setIsFullScreen: () => {},
  updateCurrentUser: () => Promise.resolve(),
});

const ENV_NAMES: Record<ENV, string> = {
  [ENV.PROD]: 'Prod',
  [ENV.LOCAL]: 'Local',
  [ENV.STAGING]: 'Recette',
  [ENV.PREPROD]: 'Preprod',
  [ENV.E2E]: 'Test E2E',
  [ENV.TEST]: 'Test',
  [ENV.SQUADA]: 'Squad A',
  [ENV.SQUADB]: 'Squad B',
};

export const useAppContext = () => useContext(AppContext);

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['global']);
  const toast = useToast();
  const history = useHistory();

  const queryClient = useQueryClient();

  const [isFullScreen, setIsFullScreen] = useState(false);

  const auth = useAuth();

  const currentUser = useCurrentUser();

  useVitally();

  /*
    When language change, update translation, and document title
   */
  useEffect(() => {
    const updateLang = async () => {
      const companyLanguage = currentUser.data.user.company.lang;
      if (companyLanguage) {
        changeDayJSLocale(companyLanguage);
        await i18n.changeLanguage(companyLanguage);
        const env = getEnvValue('REACT_APP_ENV');
        document.title = i18n.t('global:title');
        if (env !== ENV.PROD) {
          document.title = `${ENV_NAMES[env as ENV]} - ${i18n.t('global:title')} - ${env}`;
        }
      }
    };

    updateLang();
  }, [currentUser.data.user.company.lang]);

  /*
    Configure token
   */
  const token = useStore((selector) => selector.token);
  const externalApi = useMemo(() => {
    if (!token) {
      return null;
    }
    return ExternalApi.getExternalApi(token);
  }, [token]);

  const updateCurrentUser = useCallback(async () => {
    await queryClient.invalidateQueries(userKeyFactory.getCurrent());
  }, [queryClient]);

  /*
    Whenever the user switches a company, redirect to home page and force-reload the current user and the company
   */
  useOnUserCompanyChanged(currentUser.data.user, async (companyName: string) => {
    history.replace('/'); // Redirect to dashboard
    await queryClient.resetQueries(); // Reset all cached queries
    toast.success(
      t('global:auth.switchCompany', {
        companyName,
      }),
    );
  });

  const context = useMemo(
    () => ({
      currentUser: currentUser.data.user,

      signOut: auth.logout,
      updateCurrentUser,

      authToken: token,
      externalApi,

      // TODO Victor: Is it similar to usage of useHideNavbar or what ?
      isFullScreen,
      setIsFullScreen,
    }),
    [currentUser.data.user, auth.logout, updateCurrentUser, token, externalApi, isFullScreen],
  );

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
