import type {
  BillPaginatedResponse,
  IBill,
  IBillReminderInfosDTO,
  IBillReminderInfosResponse,
  IBillMarkAsRemindedDTO,
  IBillResponseDTO,
  IBillUpdateDTO,
  IBillUpdateStatusesDTO,
  IBillUpdateStatusesToPaidDTO,
  BillStatsDTO,
} from '@graneet/business-logic';
import { FEATURE_FLAGS, SUPPORT_EMAIL } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import type { UsePaginationOptions } from '@graneet/lib-ui';
import { useToast, usePaginationQuery } from '@graneet/lib-ui';
import type { QueryClient } from '@tanstack/react-query';
import { useQuery, useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useInvoiceByIdQuery } from '../../invoice/services/invoice.api';
import { useProjectOrUndefined } from '../../project/services/project.api';

import { apiNew } from 'features/api/services/apiNew.service';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

const BILL_PATH = '/bills';

export const billKeyFactory = createQueryKeys('bills', {
  get: (queryParams?: URLSearchParams | { _full: boolean }) => ({
    queryKey: [BILL_PATH, queryParams],
    queryFn: () => apiNew.get<URLSearchParams | { _full: boolean }, BillPaginatedResponse>(BILL_PATH, queryParams),
  }),
  getStats: () => ({
    queryKey: [BILL_PATH, 'stats'],
    queryFn: () => apiNew.get<URLSearchParams, BillStatsDTO>(`${BILL_PATH}/stats`),
  }),
  getOne: (id: number) => ({
    queryKey: [BILL_PATH, id],
    queryFn: () => apiNew.get<never, IBillResponseDTO>(`${BILL_PATH}/${id}`),
  }),
  getOneOrNull: (id?: number) => ({
    queryKey: [BILL_PATH, id],
    queryFn: () => {
      if (!id) {
        return Promise.resolve(null);
      }
      return apiNew.get<never, IBillResponseDTO>(`${BILL_PATH}/${id}`);
    },
  }),
  getReminderInfos: (params: { hasBillEmailRemindFF: boolean; dto: IBillReminderInfosDTO }) => ({
    queryKey: [BILL_PATH, 'reminder-infos', params],
    queryFn: () => {
      if (params.hasBillEmailRemindFF) {
        return apiNew.post<IBillReminderInfosDTO, IBillReminderInfosResponse>(
          `${BILL_PATH}/reminder-infos`,
          params.dto,
        );
      }

      return Promise.resolve<IBillReminderInfosResponse>({
        areAllRemindable: false,
        projectsWithoutDefaultEmailRecipient: [],
      });
    },
  }),
});

export async function invalidAllBillsQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [BILL_PATH],
    exact: false,
  });
}

export function useBills(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery((urlSearchParams) => queryClient.fetchQuery(billKeyFactory.get(urlSearchParams)), options);
}

export function useBillsQuery(
  urlSearchParams?: URLSearchParams,
  options?: {
    enabled: boolean;
  },
) {
  return useQuery({ ...billKeyFactory.get(urlSearchParams), ...options });
}

export function useBillsWithoutPagination(urlSearchParams?: Record<any, any>) {
  return useSuspenseQuery(billKeyFactory.get({ _full: true, ...urlSearchParams }));
}

export function useBill(id: number) {
  return useSuspenseQuery(billKeyFactory.getOne(id));
}

export function useBillClient(id: number | undefined) {
  const bill = useSuspenseQuery(billKeyFactory.getOneOrNull(id));
  const invoice = useInvoiceByIdQuery(bill.data?.invoice?.id);
  const project = useProjectOrUndefined(bill.data?.project?.id);

  return invoice.data?.client ?? project.data?.primaryClient ?? null;
}

export function useBillsStats() {
  return useSuspenseQuery(billKeyFactory.getStats());
}

export function useBillReminderInfos(dto: IBillReminderInfosDTO) {
  const hasBillEmailRemindFF = useFeatureFlag(FEATURE_FLAGS.BILL_EMAIL_REMIND);

  return useSuspenseQuery(billKeyFactory.getReminderInfos({ dto, hasBillEmailRemindFF }));
}

export function useMarkBillsAsReminded() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'bill']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IBillMarkAsRemindedDTO) =>
      apiNew.patch<IBillMarkAsRemindedDTO, void>(`${BILL_PATH}/mark-as-reminded`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, dto) => {
      await invalidAllBillsQueries(queryClient);
      toast.success(t('bill:toasts.successMarkAsReminded', { count: dto.selectedItems?.length }));
    },
  });
}

export function useMarkBillAsLost() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'bill']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.patch<never, IBill>(`${BILL_PATH}/${id}/lost`),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidAllBillsQueries(queryClient);
      toast.success(t('global:words.c.success'), t('bill:lostModal.toastSuccess'));
    },
  });
}

export function useBillUpdate() {
  const queryClient = useQueryClient();

  const toast = useToast();
  const { t } = useTranslation(['bill']);

  return useMutation({
    mutationFn: (params: { id: number; dto: IBillUpdateDTO }) =>
      apiNew.patch<IBillUpdateDTO, IBill>(`${BILL_PATH}/${params.id}`, params.dto),
    onSuccess: async () => {
      toast.success(t('bill:changeComment.success'));
      await invalidAllBillsQueries(queryClient);
    },
    onError: () => {
      toast.error(t('bill:changeComment.error'));
    },
  });
}

export function useBillStatusesToLost() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IBillUpdateStatusesDTO) =>
      apiNew.patch<IBillUpdateStatusesDTO, void>(`${BILL_PATH}/status/lost`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidAllBillsQueries(queryClient);
    },
  });
}

export function useBillStatusesToPaid() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IBillUpdateStatusesToPaidDTO) =>
      apiNew.patch<IBillUpdateStatusesToPaidDTO, void>(`${BILL_PATH}/status/paid`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidAllBillsQueries(queryClient);
    },
  });
}
