import { Box, Flex, Grid } from '@chakra-ui/react';
import type { Payload } from 'recharts/types/component/DefaultLegendContent';

import { EllipsisText } from '../../../../EllipsisText';

// TODO: Type it better
type CustomLegendProps<T extends object> = {
  legends: Partial<Record<keyof T, string>>;
  payload?: (Payload & { dataKey: string })[];
};

const CustomLegendBody = <T extends object>({
  legends,
  payload,
  getOrderValue,
}: CustomLegendProps<T> & { getOrderValue?: (index: number) => number }) => (
  <>
    {payload &&
      payload.map(({ dataKey, color }, index) => (
        <Flex
          key={dataKey}
          mt={4}
          alignItems="center"
          justifyContent="flex-start"
          order={getOrderValue && getOrderValue(index)}
        >
          <Box boxSize="14px" mr={2}>
            <svg viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
              <circle cx="7" cy="7" r="7" fill={color} />
            </svg>
          </Box>

          <Flex justifyContent="space-between">
            <EllipsisText maxWidth="15rem" fontSize="xs" color="gray.800">
              {legends[dataKey as keyof T]}
            </EllipsisText>
          </Flex>
        </Flex>
      ))}
  </>
);

export const CustomLegendInline = <T extends object>({ legends, payload }: CustomLegendProps<T>) => (
  <Flex width="90%" justifyContent="space-between">
    <CustomLegendBody legends={legends} payload={payload} />
  </Flex>
);

export const CustomLegendGrid = <T extends object>({ legends, payload }: CustomLegendProps<T>) => {
  // order is used to correctly order the legends in two distinct columns
  // first & second div at left, third and fourth at the right
  // without order first & third would be on the left and second & fourth div would be on the right
  const getOrderValue = (index: number): number => (index % 2 === 0 ? 0 : 1);

  return (
    <Grid gridTemplateColumns="auto auto" columnGap={2}>
      <CustomLegendBody legends={legends} payload={payload} getOrderValue={getOrderValue} />
    </Grid>
  );
};
