import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { IQuoteInfosResponse } from '@graneet/business-logic';
import { keyBy } from 'lodash-es';

import { getQuoteInfos } from '../services/quote.api';

import { getComponentsTypes } from 'features/quote-lot/services/quote-lot.api';
import { HTTP_STATUS, redirectToForbiddenScreen } from 'features/api/hooks/useDataGetter';

type LoadingState = [false, null, true];
type ErrorState = [true, null, false];
type LoadedState = [false, IQuoteInfosResponse, false];

export const useQuoteInfos = (quoteId: number) => {
  const history = useHistory();

  const hasBeenLoadedOnceRef = useRef(false);

  const [quoteInfos, setQuoteInfos] = useState<LoadingState | ErrorState | LoadedState>([false, null, true]);

  useEffect(() => {
    if (hasBeenLoadedOnceRef.current) {
      return;
    }
    const effect = async () => {
      const [error, infos] = await getQuoteInfos(quoteId);
      if (error) {
        if (error.response?.status === HTTP_STATUS.FORBIDDEN) {
          redirectToForbiddenScreen(history);
          return;
        }
        setQuoteInfos([true, null, false]);
        return;
      }

      const [errorComponents, components] = await getComponentsTypes(infos.rootLotId);

      if (errorComponents) {
        if (errorComponents.response?.status === HTTP_STATUS.FORBIDDEN) {
          redirectToForbiddenScreen(history);
          return;
        }
        setQuoteInfos([true, null, false]);
        return;
      }

      const formattedComponents = keyBy(components, 'id');

      hasBeenLoadedOnceRef.current = true;
      setQuoteInfos([false, { ...infos, components: formattedComponents }, false]);
    };
    effect();
  }, [history, quoteId]);

  return quoteInfos;
};
