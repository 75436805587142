import type { FC } from 'react';
import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';
import type { IProgressStatement } from '@graneet/business-logic';
import { PROGRESS_STATEMENT_STATUSES } from '@graneet/business-logic';

import { useProgressStatementChangeStatus } from '../services/progress-statement.api';

interface CreateNewProgressStatementInDraftModalProps {
  progressStatement: IProgressStatement;
  nextProgressStatement?: IProgressStatement;
  isOpen: boolean;
  onClose(): void;
  onSuccess(ps: IProgressStatement): void;
}

export const CreateNewProgressStatementInDraftModal: FC<CreateNewProgressStatementInDraftModalProps> = ({
  progressStatement,
  nextProgressStatement,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation(['global', 'progressStatement']);

  const progressStatementChangeStatusMutation = useProgressStatementChangeStatus('progress');

  const handleClick = useCallback(async () => {
    progressStatementChangeStatusMutation.mutate(
      {
        id: progressStatement.id,
        dto: { status: PROGRESS_STATEMENT_STATUSES.DRAFT },
      },
      {
        onSuccess: (ps) => {
          onClose();
          onSuccess(ps);
        },
      },
    );
  }, [onClose, onSuccess, progressStatement.id, progressStatementChangeStatusMutation]);

  const isValidated = progressStatement.status === PROGRESS_STATEMENT_STATUSES.VALIDATED;
  const isNextDraft = nextProgressStatement && nextProgressStatement.status === PROGRESS_STATEMENT_STATUSES.VALIDATED;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('progressStatement:workflow.updating.title')}>
      {t(`progressStatement:workflow.updating.${isValidated ? 'fromValidated' : 'fromAccepted'}`)}
      {isNextDraft && (
        <Box mt={2}>
          {t('progressStatement:workflow.updating.archiveNextDraft', { name: nextProgressStatement.name })}
        </Box>
      )}

      <Modal.SecondaryButton onClick={onClose}>{t('global:words.c.cancel')}</Modal.SecondaryButton>

      <Modal.PrimaryButton isLoading={progressStatementChangeStatusMutation.isPending} onClick={handleClick}>
        {t('progressStatement:actions.edit')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
