import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import type { NodeWithRelations } from '@graneet/lib-ui';
import {
  Tooltip,
  useCurrency,
  DeepTable,
  PriceAdvanced,
  PercentageField,
  DiscountIcon,
  IconAdvanced,
  SwitchField,
  CurrencyField,
  NegativeCurrencyField,
  formatPercentage,
  SimpleHelpIcon,
} from '@graneet/lib-ui';
import { Box, Flex, FormControl, FormLabel, GridItem, HStack, Text } from '@chakra-ui/react';
import {
  getDiscountAmountExVAT,
  DISCOUNT_TYPES,
  PROGRESS_STATEMENT_DISCOUNT_MODES,
  CUMULATIVE_INPUT_TYPE,
  getFloatingPercentage,
  getAmountPercentage,
  type IItemResponseDTO,
  isNumberFinite,
} from '@graneet/business-logic';
import { useFormContext, useOnBlurValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from '@chakra-ui/icons';

import { useProgressStatementContext } from '../../contexts/ProgressStatementContext';
import type { ProgressStatementEditItemForm } from '../../forms/progress-statement-edit-item-form';
import {
  getKeyForDiscountCumulativeInput,
  getDiscountCumulativeFieldName,
  getDiscountModeLineFieldName,
} from '../../forms/progress-statement-edit-item-form';
import { getIdFromContractId } from '../../services/progress-statement-tree.util';
import type { IContractTree } from '../../hooks/useProgressStatementTree';

import { ProgressStatementTotalDiscountLabel } from './ProgressStatementTotalDiscountLabel';

const INPUT_STYLE = {
  outline: 'none',
  border: 0,
  textAlign: 'center',
  autoComplete: 'off',
} as const;

const FORM_LABEL_STYLE = {
  fontWeight: '600',
  fontSize: '0.82rem',
  display: 'flex',
  my: 1,
};

interface ProgressStatementDiscountRowProps {
  contract: NodeWithRelations<IContractTree>;

  itemLines: {
    cumulativeAmountExVAT: number;
    item: IItemResponseDTO;
  }[];
}

const ProgressStatementDiscountCumulativeField: FC<ProgressStatementDiscountRowProps> = ({ contract }) => {
  const contractId = getIdFromContractId(contract.id);

  const amountFieldName = getDiscountCumulativeFieldName(contractId, CUMULATIVE_INPUT_TYPE.AMOUNT);
  const percentageFieldName = getDiscountCumulativeFieldName(contractId, CUMULATIVE_INPUT_TYPE.PERCENTAGE);

  const { t } = useTranslation(['progressStatement']);

  const form = useFormContext<ProgressStatementEditItemForm>();
  const { getFormValues, setFormValues } = form;
  const { mapNumberToAmount } = useCurrency();
  const { previousDiscountsCumulativeValues } = useProgressStatementContext();
  const previousDiscountCumulativeValues = previousDiscountsCumulativeValues[contractId];

  const modeField = getDiscountModeLineFieldName(contractId);
  const {
    cumulativeInputType,
    [modeField]: mode,
    [amountFieldName]: currentCumulativeAmountExVAT,
  } = useOnBlurValues(form, ['cumulativeInputType', modeField, amountFieldName]);

  const discountAmount = getDiscountAmountExVAT(contract.discount, contract.totalAmountWithoutDiscountExVAT);

  const handleBlur = useCallback(
    (type: CUMULATIVE_INPUT_TYPE) => () => {
      const fieldName = getDiscountCumulativeFieldName(contractId, type);
      const discountCumulativeAmountField = getDiscountCumulativeFieldName(contractId, CUMULATIVE_INPUT_TYPE.AMOUNT);
      const discountCumulativeProgressPercentageField = getDiscountCumulativeFieldName(
        contractId,
        CUMULATIVE_INPUT_TYPE.PERCENTAGE,
      );

      let { [fieldName]: value } = getFormValues();

      const newFormValues: Partial<ProgressStatementEditItemForm> = {};

      // Value reset
      if (!isNumberFinite(value)) {
        const previousValue = previousDiscountCumulativeValues?.[getKeyForDiscountCumulativeInput(type)] || 0;

        value = cumulativeInputType === CUMULATIVE_INPUT_TYPE.AMOUNT ? mapNumberToAmount(previousValue) : previousValue;

        newFormValues[fieldName] = value;
      }

      const newCumulativeAmount =
        type === CUMULATIVE_INPUT_TYPE.AMOUNT
          ? value // Use input value
          : newFormValues[discountCumulativeAmountField]; // Use current amount

      if (type === CUMULATIVE_INPUT_TYPE.PERCENTAGE) {
        newFormValues[discountCumulativeAmountField] = mapNumberToAmount(getAmountPercentage(discountAmount, value));
      }
      if (type === CUMULATIVE_INPUT_TYPE.AMOUNT) {
        newFormValues[discountCumulativeProgressPercentageField] = getFloatingPercentage(
          newCumulativeAmount || 0,
          mapNumberToAmount(discountAmount),
        );
      }

      setFormValues(newFormValues);
    },
    [
      contractId,
      cumulativeInputType,
      discountAmount,
      getFormValues,
      mapNumberToAmount,
      previousDiscountCumulativeValues,
      setFormValues,
    ],
  );

  const maxAmount = Math.abs(discountAmount);
  const AbsoluteCurrencyField =
    discountAmount < 0
      ? NegativeCurrencyField<ProgressStatementEditItemForm>
      : CurrencyField<ProgressStatementEditItemForm>;

  return (
    <Flex alignItems="center">
      <Box
        textAlign="center"
        borderBottom="2px"
        borderColor={mode === PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO ? 'gray.200' : 'greenBrand.light'}
        ml={mode === PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO ? 7 : 0}
        w="5rem"
      >
        <Box display={cumulativeInputType === CUMULATIVE_INPUT_TYPE.AMOUNT ? 'none' : undefined}>
          <PercentageField<ProgressStatementEditItemForm>
            name={percentageFieldName}
            inputProps={INPUT_STYLE}
            scale={mode === PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO ? 3 : undefined}
            isDisabled={mode === PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO}
            onBlur={handleBlur(CUMULATIVE_INPUT_TYPE.PERCENTAGE)}
            isRequired
          />
        </Box>
        <Box display={cumulativeInputType !== CUMULATIVE_INPUT_TYPE.AMOUNT ? 'none' : undefined}>
          <AbsoluteCurrencyField
            name={amountFieldName}
            min={0}
            max={mapNumberToAmount(maxAmount)}
            inputProps={INPUT_STYLE}
            isDisabled={mode === PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO}
            onBlur={handleBlur(CUMULATIVE_INPUT_TYPE.AMOUNT)}
            forceNegative={discountAmount > 0}
            isRequired
          />
        </Box>
      </Box>
      {mapNumberToAmount(previousDiscountCumulativeValues?.cumulativeAmountExVAT ?? 0) >
        (currentCumulativeAmountExVAT ?? 0) && (
        <Tooltip label={t('progressStatement:tooltips.newPercentageSmallerThanPreviousProgressStatement')}>
          <WarningIcon boxSize={5} color="yellow.500" />
        </Tooltip>
      )}
    </Flex>
  );
};

export const ProgressStatementDiscountRow: FC<ProgressStatementDiscountRowProps> = ({ contract, itemLines }) => {
  const { t } = useTranslation(['discount', 'progressStatement']);
  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();

  const { previousDiscountsCumulativeValues, getContractDiscountPercentageSuggestion } = useProgressStatementContext();

  const contractId = getIdFromContractId(contract.id);
  const discountCumulativeAmountField = getDiscountCumulativeFieldName(contractId, CUMULATIVE_INPUT_TYPE.AMOUNT);
  const discountCumulativeProgressPercentageField = getDiscountCumulativeFieldName(
    contractId,
    CUMULATIVE_INPUT_TYPE.PERCENTAGE,
  );
  const modeField = getDiscountModeLineFieldName(contractId);
  const { discount, totalAmountWithoutDiscountExVAT } = contract;
  const discountAmountExVAT = getDiscountAmountExVAT(discount, totalAmountWithoutDiscountExVAT);

  const previousDiscountCumulativeValues = previousDiscountsCumulativeValues[contractId];

  const form = useFormContext<ProgressStatementEditItemForm>();
  const { getFormValues, setFormValues } = form;
  const values = useOnBlurValues(form, ['cumulativeInputType', modeField]);
  const { [modeField]: mode, cumulativeInputType } = values;

  // Watcher that compute the suggested discount cumulative percentage in 'AUTO' discount line mode
  // and stores it into the percentage field
  useEffect(() => {
    const { [modeField]: currentMode } = values;

    if (currentMode === PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO) {
      const newPercentage = getContractDiscountPercentageSuggestion(contractId, itemLines);

      const newValue: Partial<ProgressStatementEditItemForm> = {};
      newValue[discountCumulativeProgressPercentageField] = newPercentage;
      newValue[discountCumulativeAmountField] = mapNumberToAmount(
        getAmountPercentage(discountAmountExVAT, newPercentage),
      );
      setFormValues(newValue);
    }
  }, [
    contract,
    getFormValues,
    modeField,
    discountCumulativeProgressPercentageField,
    setFormValues,
    getContractDiscountPercentageSuggestion,
    values,
    discountCumulativeAmountField,
    mapNumberToAmount,
    mapAmountToNumber,
    discountAmountExVAT,
    contractId,
    itemLines,
  ]);

  const discountLabel =
    discount?.type === DISCOUNT_TYPES.PERCENTAGE
      ? t('discount:labelWithPercentage', {
          percentage: formatPercentage(-(discount?.percentage || 0)),
        })
      : t('discount:label');

  return (
    <DeepTable.Row color="gray.600" background="white" borderColor="gray.200" noLeftSpacing h="53px" offset={0.5}>
      <HStack align="center" w="50rem" spacing={12}>
        <Flex align="center" w="22rem">
          <DiscountIcon mr={2} />
          <Text color="green.700" fontWeight="600" fontSize="sm">
            {discountLabel}
          </Text>
        </Flex>

        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor={modeField}
            cursor={mode === PROGRESS_STATEMENT_DISCOUNT_MODES.MANUAL ? 'default' : 'pointer'}
            color={mode === PROGRESS_STATEMENT_DISCOUNT_MODES.MANUAL ? 'greenBrand.light' : 'gray.500'}
            textAlign="right"
            justifyContent="flex-end"
            flex="initial"
            {...FORM_LABEL_STYLE}
          >
            {t('progressStatement:discount.mode.manual')}
          </FormLabel>
          <Box>
            <SwitchField<ProgressStatementEditItemForm>
              name={modeField}
              id={modeField}
              mt={1}
              colorScheme="blue"
              uncheckedValue={PROGRESS_STATEMENT_DISCOUNT_MODES.MANUAL}
              checkedValue={PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO}
            />
          </Box>
          <FormLabel
            htmlFor={modeField}
            cursor={mode === PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO ? 'default' : 'pointer'}
            color={mode === PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO ? 'greenBrand.light' : 'gray.500'}
            ml={3}
            textAlign="left"
            flex={1}
            {...FORM_LABEL_STYLE}
          >
            {t('progressStatement:discount.mode.auto')}
          </FormLabel>
        </FormControl>
      </HStack>

      <GridItem colSpan={4} />

      <DeepTable.Cell right>
        <PriceAdvanced amount={-discountAmountExVAT} />
      </DeepTable.Cell>

      <DeepTable.Cell center>
        {cumulativeInputType === CUMULATIVE_INPUT_TYPE.AMOUNT ? (
          <PriceAdvanced amount={-(previousDiscountCumulativeValues?.cumulativeAmountExVAT || 0)} />
        ) : (
          formatPercentage(previousDiscountCumulativeValues?.cumulativeProgressPercentage || 0)
        )}
      </DeepTable.Cell>

      <DeepTable.Cell variant="shadow" internalPaddingX={0} center>
        <Flex alignItems="center">
          <ProgressStatementDiscountCumulativeField contract={contract} itemLines={itemLines} />

          {mode === PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO && (
            <IconAdvanced
              icon={<SimpleHelpIcon ml={2} mr={0} mb={0} />}
              tooltip={t('progressStatement:discount.autoHint')}
            />
          )}
        </Flex>
      </DeepTable.Cell>

      <DeepTable.Cell right>
        <ProgressStatementTotalDiscountLabel contract={contract} />
      </DeepTable.Cell>
    </DeepTable.Row>
  );
};
