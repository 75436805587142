import { PAYMENT_TYPES } from '../payment/payment.type';

import type { IBill } from './bill.type';
import { BILL_STATUSES } from './bill.type';

export const isBillNotFullyPayed = (bill?: Pick<IBill, 'status'>): boolean =>
  !!bill && bill.status !== BILL_STATUSES.PAID;

export const isBillLost = (bill: Pick<IBill, 'status'>): boolean => bill.status === BILL_STATUSES.LOST;

export const isBillLosable = (bill: Pick<IBill, 'status' | 'lateDays'>): boolean =>
  !!(isBillNotFullyPayed(bill) && bill.lateDays && bill.lateDays >= 0 && !isBillLost(bill));

export const isBillOnOverpayment = (totalToBePaidIncVAT: number, remainingToBePaidIncVAT: number): boolean => {
  // If negative bill, there is an overpayment if the remaining amount is more than 0
  if (totalToBePaidIncVAT < 0 && remainingToBePaidIncVAT > 0) {
    return true;
  }
  // If positive bill, there is an overpayment if the remaining amount is less than 0
  if (totalToBePaidIncVAT > 0 && remainingToBePaidIncVAT < 0) {
    return true;
  }
  return false;
};

/**
 * Return the minimum payment amount payable for a bill
 */
export const getBillMinAmountToBePaidIncVAT = (bill: IBill): number | undefined => {
  if (bill.totalToBePaidIncVAT < 0) {
    return undefined;
  }
  return 0;
};

/**
 * Return the maximum payment amount payable for a bill
 */
export const getBillMaxAmountToBePaidIncVAT = (bill: IBill): number | undefined => {
  if (bill.totalToBePaidIncVAT < 0) {
    return 0;
  }
  return undefined;
};

/**
 * Return bill status after the payment is done. The bill car be PAID, PARTIALLY_PAID OR AWAITING_PAYMENT.
 */
export const getStatusAfterPayment = (
  bill: IBill,
  amountPaidIncVAT: number,
  isPaymentDeletion = false,
): BILL_STATUSES => {
  const remainingToBePaidIncVAT = bill.remainingToBePaidIncVAT - amountPaidIncVAT;

  if (isBillOnOverpayment(bill.totalToBePaidIncVAT, remainingToBePaidIncVAT) || remainingToBePaidIncVAT === 0) {
    return BILL_STATUSES.PAID;
  }

  if (isPaymentDeletion && remainingToBePaidIncVAT === bill.totalToBePaidIncVAT) {
    return BILL_STATUSES.AWAITING_PAYMENT;
  }

  return BILL_STATUSES.PARTIALLY_PAID;
};

/**
 * Return payment type from a bill. The type car be TOTAL or PARTIAL.
 */
export const getPaymentTypeFromBill = (bill: IBill, amountPaidIncVAT: number): PAYMENT_TYPES =>
  amountPaidIncVAT >= bill.totalToBePaidIncVAT ? PAYMENT_TYPES.TOTAL : PAYMENT_TYPES.PARTIAL;

export const getNextBillStatusesAllowedForBatchActions = (
  currentSuppliersInvoicesStatus: BILL_STATUSES,
  isPaymentLate: boolean,
): BILL_STATUSES[] => {
  const nextStatusesAllowed: BILL_STATUSES[] = [];

  if (currentSuppliersInvoicesStatus === BILL_STATUSES.PARTIALLY_PAID) {
    nextStatusesAllowed.push(BILL_STATUSES.PAID);
  }
  if (currentSuppliersInvoicesStatus === BILL_STATUSES.AWAITING_PAYMENT) {
    nextStatusesAllowed.push(BILL_STATUSES.PAID);
  }
  if (currentSuppliersInvoicesStatus === BILL_STATUSES.AWAITING_PAYMENT && isPaymentLate) {
    nextStatusesAllowed.push(BILL_STATUSES.LOST);
  }
  if (currentSuppliersInvoicesStatus === BILL_STATUSES.PARTIALLY_PAID && isPaymentLate) {
    nextStatusesAllowed.push(BILL_STATUSES.LOST);
  }

  return nextStatusesAllowed;
};
