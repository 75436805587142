import type { FC } from 'react';
import type { IProject, ISubProjectByProjectListingResponseDTO } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Button } from '@graneet/lib-ui';
import { useDisclosure } from '@chakra-ui/react';

import { getDownPaymentCreationState } from '../../services/down-payment.util';

import { DownPaymentCreateOnSubProjectModal } from './DownPaymentCreateOnSubProjectModal';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';

interface DownPaymentCreateButtonProps {
  subProjects: ISubProjectByProjectListingResponseDTO;

  project: IProject;
}

export const DownPaymentCreateButton: FC<DownPaymentCreateButtonProps> = ({ subProjects, project }) => {
  const { t } = useTranslation(['global', 'downPayment'] as const);

  const { createRedirectionWithFilters } = useFiltersQuery();

  const selectSubProjectModal = useDisclosure();

  const createStatementsButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_STATEMENTS]);
  const permissionsProps = createStatementsButtonDisabledProps();

  /**
   * You cannot create a down payment if there is no subproject
   */
  if (subProjects.length === 0) {
    return (
      <Button
        variant="outline"
        tooltip={t('downPayment:tooltips.noContract')}
        tooltipProps={{ placement: 'left' }}
        isDisabled
      >
        {t('downPayment:actions.create')}
      </Button>
    );
  }

  if (subProjects.length === 1) {
    const [subProject] = subProjects;
    const downPaymentState = getDownPaymentCreationState(subProject, t, false);

    const onClick = createRedirectionWithFilters(
      `/projects/${project.id}/statements/sub-projects/${subProject.id}/down-payments/edit`,
      { projectId: project.id, subProjectId: subProject.id },
    );

    if (subProjects[0].downPayment) {
      return (
        <Button
          variant="outline"
          isDisabled
          tooltip={t('downPayment:tooltips.alreadyHaveDownPayment')}
          tooltipProps={{ placement: 'left' }}
        >
          {t('downPayment:actions.create')}
        </Button>
      );
    }

    return (
      <Button
        variant="outline"
        tooltip={downPaymentState.tooltip || permissionsProps.tooltip}
        tooltipProps={{ placement: 'left' }}
        isDisabled={!downPaymentState.canCreate || permissionsProps.isDisabled}
        onClick={onClick}
      >
        {t('downPayment:actions.create')}
      </Button>
    );
  }

  return (
    <>
      <DownPaymentCreateOnSubProjectModal
        subProjects={subProjects}
        onClose={selectSubProjectModal.onClose}
        isOpen={selectSubProjectModal.isOpen}
        project={project}
      />

      <Button
        variant="outline"
        onClick={selectSubProjectModal.onOpen}
        tooltip={permissionsProps.tooltip}
        tooltipProps={{ placement: 'left' }}
        isDisabled={permissionsProps.isDisabled}
      >
        {t('downPayment:actions.create')}
      </Button>
    </>
  );
};
