import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import {
  Button,
  Card,
  formatPercentage,
  GotoLink,
  LabeledData,
  Price,
  SimpleEditIcon,
  TwoColumns,
} from '@graneet/lib-ui';
import { PERMISSION, type IHoldback } from '@graneet/business-logic';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface HoldbackCardNewProps {
  holdback: IHoldback;

  onEditSubProjectParams(): void;
}

// TODO: @[ff: project-global-parameters] rename to HoldbackCard
export const HoldbackCardNew: FC<HoldbackCardNewProps> = ({ holdback, onEditSubProjectParams }) => {
  const { id, holdbackAmountIncVAT, holdbackPercentage, paymentGuaranteeAmount, hasPaymentGuarantee, bankName } =
    holdback;

  const createProjectsButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_PROJECTS]);

  const { t } = useTranslation(['global', 'holdback']);

  return (
    <Card
      title={
        <Flex>
          <Text mr={4}>{t('holdback:holdback')}</Text>
          <GotoLink to={`/sales/holdbacks/${id}`} label={t('holdback:goToLink')} />
        </Flex>
      }
      topRightContent={
        <Button
          variant="outline"
          onClick={onEditSubProjectParams}
          leftIcon={<SimpleEditIcon />}
          {...createProjectsButtonDisabledProps()}
        >
          {t('global:words.c.update')}
        </Button>
      }
    >
      <TwoColumns>
        <LabeledData label={t('holdback:fields.holdbackPercentage')} data={formatPercentage(holdbackPercentage)} />
        <LabeledData label={t('holdback:fields.holdbackAmountIncVAT')} data={<Price amount={holdbackAmountIncVAT} />} />
      </TwoColumns>
      <TwoColumns mt={4}>
        {hasPaymentGuarantee ? (
          <>
            <LabeledData
              label={t('holdback:fields.paymentGuarantee')}
              data={<Price amount={paymentGuaranteeAmount} />}
            />
            <LabeledData label={t('holdback:fields.bank')} data={hasPaymentGuarantee ? bankName : '-'} />
          </>
        ) : (
          <LabeledData label={t('holdback:fields.paymentGuarantee')} data={t('global:words.c.no')} />
        )}
      </TwoColumns>
    </Card>
  );
};
