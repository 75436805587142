import { Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';

import { SimpleTreeIndicatorIcon } from '../../Icons/v2/SimpleTreeIndicatorIcon';

type TreeIndicatorProps = {
  type: 'simple' | 'intermediate' | 'end';
  top?: string;
};

export const TreeIndicator: FC<TreeIndicatorProps> = ({ type, top = '0' }) => (
  <Flex
    width="1.25rem"
    minHeight="2.75rem"
    color="#e9ebed"
    alignItems="center"
    alignSelf="stretch"
    justifyContent="center"
    position="relative"
  >
    {type !== 'end' && <Box background="#e9ebed" width="2px" position="absolute" top={0} bottom={0} left={0} />}
    {type !== 'simple' && (
      <SimpleTreeIndicatorIcon position="absolute" left="-9px" top={top} width="1.25rem" height="2.75rem" />
    )}
  </Flex>
);
