import { Flex } from '@chakra-ui/react';
import type { GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { useState, useEffect, useCallback, useRef } from 'react';
import { RichTextGetter, type RichTextGetterHandler } from '@graneet/lib-ui';

import { SelectionMenu } from '../menu/SelectionMenu';

import { AgGridQuoteHiddenCostRoot } from 'features/quotation/ag-grid-quote/components/AgGridQuoteHiddenCostRoot';
import { AgGridQuoteRoot } from 'features/quotation/ag-grid-quote/components/AgGridQuoteRoot';
import { updateQuoteRichTextEditorRef } from 'features/quotation/quote-common/store/quoteUpdateZustand';

export const QuoteEditTable = () => {
  const [quoteHiddenCostRootApi, setQuoteHiddenCostRootApi] = useState<GridApi | null>(null);
  const [quoteRoot, setQuoteRoot] = useState<GridApi | null>(null);

  const editorRef = useRef<RichTextGetterHandler>(null);

  useEffect(() => {
    updateQuoteRichTextEditorRef(editorRef);
  }, []);

  const addGridDropZoneQuoteRoot = useCallback(() => {
    const dropApi = quoteHiddenCostRootApi;
    const dropZone = dropApi!.getRowDropZoneParams();

    quoteRoot?.addRowDropZone(dropZone);
  }, [quoteHiddenCostRootApi, quoteRoot]);

  const onGridQuoteRootReady = (params: GridReadyEvent) => {
    setQuoteRoot(params.api);
  };

  const addGridDropZoneQuoteHiddenCostRoot = useCallback(() => {
    const dropApi = quoteRoot;
    const dropZone = dropApi!.getRowDropZoneParams();

    quoteHiddenCostRootApi?.addRowDropZone(dropZone);
  }, [quoteHiddenCostRootApi, quoteRoot]);

  const onGridQuoteHiddenCostRootReady = (params: GridReadyEvent) => {
    setQuoteHiddenCostRootApi(params.api);
  };

  useEffect(() => {
    if (quoteHiddenCostRootApi && quoteRoot) {
      addGridDropZoneQuoteRoot();
      addGridDropZoneQuoteHiddenCostRoot();
    }
  }, [addGridDropZoneQuoteHiddenCostRoot, addGridDropZoneQuoteRoot, quoteHiddenCostRootApi, quoteRoot]);

  return (
    <Flex direction="column" height="100%" width="100%">
      <SelectionMenu />
      <AgGridQuoteHiddenCostRoot onGridReady={onGridQuoteHiddenCostRootReady} />
      <AgGridQuoteRoot onGridReady={onGridQuoteRootReady} />
      <RichTextGetter ref={editorRef} />
    </Flex>
  );
};
