import type { XAxisProps, YAxisProps, AreaProps } from 'recharts';
import {
  ResponsiveContainer,
  XAxis,
  Area,
  AreaChart as AreaChartRecharts,
  YAxis,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import type { TooltipProps } from 'recharts/types/component/Tooltip';
import type { ReactNode } from 'react';
import { useCallback } from 'react';

import { Card } from '../../Card/Card';
import { useFormatGraphAxis } from '../common/hooks/graphs.util';
import { useChakraColors } from '../../../hooks/index';

export interface AreaChartProps<T extends object> {
  data: T[];

  xAxis: Omit<XAxisProps, 'dataKey' | 'tickFormatter' | 'fontSize' | 'stroke'> & {
    dataKey: Extract<keyof T, string>;
    typeFormat: 'money' | 'date';
  };

  yAxis: Omit<YAxisProps, 'dataKey' | 'tickFormatter' | 'fontSize' | 'stroke'> & {
    dataKey: Extract<keyof T, string>;
    typeFormat: 'money' | 'date';
  };

  areas: Array<Omit<AreaProps, 'dataKey'> & { dataKey: Extract<keyof T, string> }>;

  tooltip?: (props: TooltipProps<any, any> & { value: T }) => ReactNode;
}

export const AreaChart = <T extends object>({ data, xAxis, yAxis, areas, tooltip }: AreaChartProps<T>) => {
  const { formatGraphAxis } = useFormatGraphAxis();

  const { blue, gray } = useChakraColors({ blue: 'blue.500', gray: 'greenBrand.baseTertiary' });

  const tooltipComp = useCallback(
    (props: TooltipProps<any, any>) => {
      const value = props.payload?.[0]?.payload;

      if (!tooltip || !value) {
        return null;
      }

      return tooltip && <Card fontSize="xs">{tooltip({ ...props, value })}</Card>;
    },
    [tooltip],
  );

  return (
    <ResponsiveContainer>
      <AreaChartRecharts data={data}>
        <defs>
          <linearGradient id="defaultFill" x1="0" y1="0" x2="0" y2="1">
            <stop offset="16%" stopColor={blue} stopOpacity={0.3} />
            <stop offset="95%" stopColor={blue} stopOpacity={0} />
          </linearGradient>
        </defs>

        <XAxis
          {...xAxis}
          tickFormatter={(v) => formatGraphAxis(v, xAxis.typeFormat)}
          stroke="0"
          tick={{ fill: gray, fontSize: 12, fontStyle: 600 }}
        />
        <YAxis
          {...yAxis}
          scale="linear"
          tickFormatter={(v) => formatGraphAxis(v, yAxis.typeFormat)}
          stroke="0"
          tick={{ fill: gray, fontSize: 12, fontStyle: 600 }}
        />
        {areas.map((area, index) => (
          <Area
            // eslint-disable-next-line react/no-array-index-key
            key={`${area.dataKey}-${index}`}
            fill="url(#defaultFill)"
            strokeWidth={2}
            stroke={blue}
            // eslint-disable-next-line react/no-unstable-nested-components
            dot={(props) => (
              <circle cx={props.cx} cy={props.cy} r={2} stroke={props.stroke} fill={props.stroke} strokeWidth={2} />
            )}
            {...area}
            ref={null}
          />
        ))}
        {tooltip && <Tooltip content={tooltipComp} />}
        <CartesianGrid strokeDasharray="3" vertical={false} />
      </AreaChartRecharts>
    </ResponsiveContainer>
  );
};
