/* eslint-disable react/no-array-index-key */
import { Text, VStack, HStack } from '@chakra-ui/react';
import { IS_APPLE, Modal } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { QUOTATION_SHORTCUTS } from 'features/quotation/quote-hot-key/hooks/useQuoteHotKeys';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const getShortcut = (shortcut: string) => {
  if (IS_APPLE) {
    return shortcut.replace('alt', '⌥').replace('shift', '⇧').replace('ctrl', '⌃').replace('mod', '⌘').split('+');
  }
  return shortcut
    .replace('mod', 'windows')
    .replace('shift', '⇧')
    .replace('ctrl', 'Ctrl')
    .replace('alt', 'Alt')
    .split('+');
};

const ShortCut: FC<{
  shortCuts: string[];
  description: string;
}> = ({ shortCuts, description }) => {
  const getKeyboardElement = useCallback((value: string) => {
    if (value === 'windows') {
      return <i className="ri-windows-fill" />;
    }
    if (value === 'tab') {
      return <>TAB</>;
    }
    if (value === 'enter') {
      return (
        <>
          <i className="ri-corner-down-left-line" />
        </>
      );
    }
    return value.toUpperCase();
  }, []);

  return (
    <HStack width="100%" alignItems="center" justifyContent="space-between" gap={2}>
      <Text fontSize="14px" color="primaryLight" fontWeight={400}>
        {description}
      </Text>
      <HStack
        borderRadius="0.5rem"
        background="white"
        color="#9DA4AE"
        fontSize="12px"
        fontWeight={500}
        justifyContent="flex-end"
        fontFamily="Inter"
        width="100px"
        gap="0.25rem"
      >
        {shortCuts.map((value, index) => (
          <HStack
            px="4px"
            py={0}
            minW="20px"
            justifyContent="center"
            background="#F2F2F0"
            borderRadius="4px"
            height="20px"
            fontSize="12px"
            fontWeight={600}
            color="rgba(6, 6, 0, 0.43)"
          >
            <Text key={index}>{getKeyboardElement(value)}</Text>
          </HStack>
        ))}
      </HStack>
    </HStack>
  );
};

const ShortCuts: FC<{
  title: string;
  shortCuts: { shortCut: string; name: string }[];
}> = ({ title, shortCuts }) => {
  const { t } = useTranslation(['quote']);
  return (
    <VStack gap="12px" width="100%" alignItems="flex-start">
      <Text
        fontSize="12px"
        fontWeight={600}
        color="rgba(6, 6, 0, 0.43)"
        textTransform="uppercase"
        fontStyle="normal"
        mb="4px"
      >
        {title}
      </Text>
      {shortCuts.map((shortCut) => {
        const sc = getShortcut(shortCut.shortCut);
        const tradKey = shortCut.name;
        return (
          <ShortCut
            key={shortCut.shortCut}
            shortCuts={sc}
            description={t(`quote:quotation.shortCuts.${tradKey}` as any)}
          />
        );
      })}
    </VStack>
  );
};

export const ShortCutsModal: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation(['quote', 'global']);
  const hasQuotationUndoRedo = useFeatureFlag(FEATURE_FLAGS.QUOTATION_UNDO_REDO);

  const navigationShortCuts = useMemo((): { shortCut: string; name: string }[] => {
    if (hasQuotationUndoRedo) {
      return [
        {
          shortCut: ['←', '→', '↑', '↓'].join('+'),
          name: 'keyboardArrows',
        },
        {
          shortCut: ['tab'].join('+'),
          name: 'keyboardTab',
        },
        {
          shortCut: ['↵'].join('+'),
          name: 'keyboardEnter',
        },
        QUOTATION_SHORTCUTS.QUIT,
        QUOTATION_SHORTCUTS.UNDO,
        QUOTATION_SHORTCUTS.REDO,
      ];
    }
    return [
      {
        shortCut: ['←', '→', '↑', '↓'].join('+'),
        name: 'keyboardArrows',
      },
      {
        shortCut: ['tab'].join('+'),
        name: 'keyboardTab',
      },
      {
        shortCut: ['↵'].join('+'),
        name: 'keyboardEnter',
      },
      QUOTATION_SHORTCUTS.QUIT,
    ];
  }, [hasQuotationUndoRedo]);

  const insertShortCuts = useMemo(
    (): { shortCut: string; name: string }[] => [
      QUOTATION_SHORTCUTS.QUOTE_BASIC_ITEM,
      QUOTATION_SHORTCUTS.QUOTE_LOT,
      QUOTATION_SHORTCUTS.BATIPRIX_IMPORT,
      QUOTATION_SHORTCUTS.LIBRARY_IMPORT,
    ],
    [],
  );

  const displayShortCuts = useMemo(
    (): { shortCut: string; name: string }[] => [
      QUOTATION_SHORTCUTS.COLLAPSE_ALL,
      QUOTATION_SHORTCUTS.COLLAPSE_ALL_COMPONENTS,
      QUOTATION_SHORTCUTS.EXPAND_ALL,
      QUOTATION_SHORTCUTS.EXPAND_ALL_COMPONENTS,
      QUOTATION_SHORTCUTS.TOGGLE_AUTO_NUMBERING,
    ],
    [],
  );

  const actionsShortCuts = useMemo(
    (): { shortCut: string; name: string }[] => [
      QUOTATION_SHORTCUTS.ADD_NOTE,
      QUOTATION_SHORTCUTS.ADD_IMAGE,
      QUOTATION_SHORTCUTS.REMOVE_LINE,
      QUOTATION_SHORTCUTS.HIDDEN_COST,
      QUOTATION_SHORTCUTS.OPTION,
    ],
    [],
  );

  const drawerShortCuts = useMemo(
    (): { shortCut: string; name: string }[] => [
      QUOTATION_SHORTCUTS.TOGGLE_DRAWER,
      QUOTATION_SHORTCUTS.DRAWER_SELLSHEET,
      QUOTATION_SHORTCUTS.DRAWER_CLIENT,
      QUOTATION_SHORTCUTS.DRAWER_PROJECT,
      QUOTATION_SHORTCUTS.DRAWER_QUOTE,
      QUOTATION_SHORTCUTS.DRAWER_HELP,
    ],
    [],
  );

  const exportShortCuts = useMemo(
    (): { shortCut: string; name: string }[] => [
      QUOTATION_SHORTCUTS.EXPORT,
      QUOTATION_SHORTCUTS.PDF_PREVIEW,
      QUOTATION_SHORTCUTS.SUPPLY_SHEET,
    ],
    [],
  );

  return (
    <Modal noFooter isOpen={isOpen} onClose={onClose} size="5xl" title={t('quote:quotation.shortCuts.title')}>
      <HStack gap="2rem" width="100%" alignItems="flex-start" px={0} py="1rem">
        <VStack gap="2rem" width="100%" alignItems="flex-start">
          <ShortCuts title={t('quote:quotation.shortCuts.insertTitle')} shortCuts={insertShortCuts} />
          <ShortCuts title={t('quote:quotation.shortCuts.displayTitle')} shortCuts={displayShortCuts} />
          <ShortCuts title={t('quote:quotation.shortCuts.navigationTitle')} shortCuts={navigationShortCuts} />
        </VStack>
        <VStack gap="2rem" width="100%" alignItems="flex-start">
          <ShortCuts title={t('quote:quotation.shortCuts.actionTitle')} shortCuts={actionsShortCuts} />
          <ShortCuts title={t('quote:quotation.shortCuts.drawerTitle')} shortCuts={drawerShortCuts} />
          <ShortCuts title={t('quote:quotation.shortCuts.exportTitle')} shortCuts={exportShortCuts} />
        </VStack>
      </HStack>
      <Modal.Close onClick={onClose} />
    </Modal>
  );
};
