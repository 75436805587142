import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GridItem, Text } from '@chakra-ui/react';
import { Badge, DeepTable } from '@graneet/lib-ui';

import { useContractLot, useContractLotComputedValue } from '../../hooks/tree.hooks';

interface ContractLotIncompleteItemsCounterProps {
  id: number | string;
}

export const ContractLotIncompleteItemsCounter: FC<ContractLotIncompleteItemsCounterProps> = ({ id }) => {
  const { t } = useTranslation(['contracts']);

  const {
    state: { isExpanded, isDeleted: isLotDeleted },
  } = useContractLot(id);
  const nodeComputedValue = useContractLotComputedValue(id);

  if (!nodeComputedValue) return null;

  return (
    <GridItem colSpan={2}>
      <DeepTable.Cell right>
        {!isExpanded && !isLotDeleted && nodeComputedValue.numberOfIncompleteItems > 0 && (
          <Badge colorScheme="yellow">
            <Text>{t('contracts:incompleteItems', { count: nodeComputedValue.numberOfIncompleteItems })}</Text>
          </Badge>
        )}
      </DeepTable.Cell>
    </GridItem>
  );
};
