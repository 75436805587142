import type { FC, ReactElement, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { AnyRecord, FieldValues } from 'graneet-form';
import { composeEventHandlers, Field, VALIDATION_OUTCOME } from 'graneet-form';
import { Box } from '@chakra-ui/react';

import type { KeysMatching } from '../../../utils';
import type { RichTextInputProps, RichTextVariableConfiguration } from '../../RichTextInput';
import { RichTextInput } from '../../RichTextInput';
import { RichText } from '../../RichText';

export type RichTextInputPropsValue = string | undefined | null;

interface RichTextFieldInternalProps extends Omit<RichTextInputProps<unknown>, 'initialValue' | 'onChange'> {
  value: string | undefined;

  onFocus(): void;

  onBlur(): void;

  onChange(value: string | undefined): void;

  isDisabled?: boolean;

  variant?: 'light' | 'dark';

  noBorder?: boolean;
}

const RichTextFieldInternal: FC<RichTextFieldInternalProps> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  isDisabled,
  shouldDisplayError,
  configuration,
  noBorder,
  ...otherProps
}) => {
  const initialValueRef = useRef<string>(value || '');
  const [key, setKey] = useState(0);

  const latestValueRef = useRef<RichTextInputPropsValue>(initialValueRef.current);

  const [hasBeenBlurred, setHasBeenBlurred] = useState(false);

  const handleOnBlur = useCallback(() => {
    onBlur();
    setHasBeenBlurred(true);
  }, [onBlur]);

  const handleOnChange = useCallback(
    (v: string) => {
      latestValueRef.current = v;
      onChange(v);
    },
    [onChange],
  );

  useEffect(() => {
    initialValueRef.current = value || '';

    /*
     * If the latest value is different from the latest value known (only update in onChange), the update comes from
     * a form.setFormValues. In this case, we must force a new render
     */
    if (latestValueRef.current !== value) {
      setKey((currentKey) => currentKey + 1);
      latestValueRef.current = value;
    }
  }, [value]);

  if (isDisabled) {
    return (
      <Box color="gray.700">
        <RichText
          key={key}
          value={value}
          configuration={configuration as [RichTextVariableConfiguration]}
          {...otherProps}
        />
      </Box>
    );
  }

  return (
    <RichTextInput
      key={key}
      initialValue={initialValueRef.current}
      onChange={handleOnChange}
      onFocus={onFocus}
      onBlur={handleOnBlur}
      shouldDisplayError={hasBeenBlurred && shouldDisplayError}
      configuration={configuration}
      inputProps={{ noBorder }}
      {...otherProps}
    />
  );
};

export interface RichTextFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, RichTextInputPropsValue> = KeysMatching<T, RichTextInputPropsValue>,
> extends Omit<RichTextFieldInternalProps, 'onFocus' | 'onChange' | 'onBlur' | 'value'> {
  name: K;

  placeholder?: ReactNode;

  children?: ReactNode;

  data?: AnyRecord;

  onBlur?(): void;

  onChange?(): void;

  onFocus?(): void;

  noBorder?: boolean;

  hideErrorMessage?: boolean;
}

export const RichTextField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, RichTextInputPropsValue> = KeysMatching<T, RichTextInputPropsValue>,
>({
  name,
  children,
  data,
  onChange,
  onFocus,
  onBlur,
  hideErrorMessage = false,
  ...othersProps
}: RichTextFieldProps<T, K>): ReactElement => {
  const render = useCallback(
    (
      {
        value,
        onChange: onChangeForm,
        onFocus: onFocusForm,
        onBlur: onBlurForm,
      }: {
        value: T[K] | undefined;
        onFocus(): void;
        onBlur(): void;
        onChange(e: T[K] | undefined): void;
      },
      {
        validationStatus,
      }: {
        validationStatus: {
          status: VALIDATION_OUTCOME;
          message: string | undefined;
        };
      },
    ) => {
      const shouldDisplayError = validationStatus.status === VALIDATION_OUTCOME.INVALID;

      return (
        <RichTextFieldInternal
          value={value}
          onChange={composeEventHandlers(onChange, onChangeForm as any)}
          onBlur={composeEventHandlers(onBlur, onBlurForm as any)}
          onFocus={composeEventHandlers(onFocus, onFocusForm as any)}
          errorMessage={validationStatus.message}
          shouldDisplayError={shouldDisplayError && !hideErrorMessage}
          {...othersProps}
        />
      );
    },
    [hideErrorMessage, onBlur, onChange, onFocus, othersProps],
  );

  return (
    <Field<T, K> name={name} render={render} data={data}>
      {children}
    </Field>
  );
};
