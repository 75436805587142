type TimeTableApproveFieldName = `approve-${number}`;

export function getTimeTableApproveFieldName(id: number): TimeTableApproveFieldName {
  return `approve-${id}`;
}

export function getIdFromTimeTableApproveFieldName(fieldName: TimeTableApproveFieldName): number {
  return Number(fieldName.replace('approve-', ''));
}

export interface TimeTableFormValues {
  [timeTableApproveFieldName: TimeTableApproveFieldName]: boolean;
}
