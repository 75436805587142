import type { FC } from 'react';
import { Date, PriceAdvanced, Table, formatDataOrEmpty } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IUser, QuotePaginatedResponse } from '@graneet/business-logic';
import type { QuoteWithClientAndAccountManagersDTO } from '@org/graneet-bff-client';
import { Checkbox } from '@chakra-ui/react';

import { QuoteStatusBadge } from '../../../quote-common/components/badges/QuoteStatusBadge';
import { QUOTE_FIELDS } from '../../../quote-common/constants/quote.constant';

import { TagsBadges } from 'features/common/components/TagsBadges';
import { UserAvatars } from 'features/user/components/UserAvatars';

const TEMPLATE_COLUMNS = (showClientColumn: boolean, showAccountManagers: boolean) =>
  [
    '3rem',
    'minmax(auto, 11rem)',
    'minmax(11rem, 2fr)',
    '8rem',
    showClientColumn ? 'minmax(8rem, 1fr)' : null,
    '8rem',
    '8rem',
    showAccountManagers ? '5rem' : null,
  ].filter(Boolean) as string[];

export type QuoteTable = QuoteWithClientAndAccountManagersDTO & {
  quoteAccountManagers?: {
    user: IUser;
  }[];
};

interface QuotesTableProps {
  quotes: QuoteTable[] | null;

  showClientColumn: boolean;

  showAccountManagers: boolean;

  hasPagination: boolean;

  handleSelectQuote: (quote: QuoteTable) => () => void;

  hasValue: (componentId: QuoteTable) => boolean;
}

export const QuoteSelectTable: FC<QuotesTableProps> = ({
  quotes,
  showClientColumn,
  showAccountManagers,
  hasPagination,
  handleSelectQuote,
  hasValue,
}) => {
  const { t } = useTranslation(['quote', 'accountManager']);

  return (
    <Table
      templateColumns={TEMPLATE_COLUMNS(showClientColumn, showAccountManagers)}
      noResultLabel={t('quote:errors.noResult')}
    >
      {hasPagination && (
        <Table.Information<QuotePaginatedResponse>
          countLabel={(count) => t('quote:quote', { count })}
          sums={{
            amountExVAT: {
              label: t('quote:fields.amountExVAT'),
              type: 'currency',
            },
          }}
        />
      )}

      <Table.Header>
        <Table.Cell />
        <Table.Cell sortBy={hasPagination ? QUOTE_FIELDS.REF_CODE : undefined}>{t('quote:fields.refCode')}</Table.Cell>

        <Table.Cell sortBy={hasPagination ? QUOTE_FIELDS.NAME : undefined}>{t('quote:fields.name')}</Table.Cell>

        <Table.Cell sortBy={hasPagination ? QUOTE_FIELDS.ADDED_AT : undefined}>{t('quote:fields.date')}</Table.Cell>

        {showClientColumn && <Table.Cell>{t('quote:fields.client')}</Table.Cell>}

        <Table.Cell right sortBy={hasPagination ? QUOTE_FIELDS.AMOUNT_EX_VAT : undefined}>
          {t('quote:fields.amountExVAT')}
        </Table.Cell>

        <Table.Cell center sortBy={hasPagination ? QUOTE_FIELDS.STATUS : undefined}>
          {t('quote:fields.status')}
        </Table.Cell>
        {showAccountManagers && <Table.Cell center>{t('accountManager:title')}</Table.Cell>}
      </Table.Header>

      {(quotes || []).map((quote: QuoteTable) => (
        <Table.Row key={quote.id} onClick={handleSelectQuote(quote)}>
          <Table.Cell>
            <Checkbox isChecked={hasValue(quote)} colorScheme="greenBrand" />
          </Table.Cell>
          <Table.Cell variant="ellipsis">{formatDataOrEmpty(quote.refCode)}</Table.Cell>

          <Table.Cell variant="ellipsis">
            {quote.name}
            <TagsBadges tags={quote.tags ?? []} />
          </Table.Cell>

          <Table.Cell>
            <Date>{quote.addedAt}</Date>
          </Table.Cell>

          {showClientColumn && (
            <Table.Cell variant="ellipsis">{formatDataOrEmpty(quote.client?.enterpriseName)}</Table.Cell>
          )}

          <Table.Cell right>
            <PriceAdvanced amount={parseFloat(quote.amountExVAT ?? '0')} />
          </Table.Cell>

          <Table.Cell center>
            <QuoteStatusBadge quote={quote} />
          </Table.Cell>

          {showAccountManagers && (
            <>
              {quote.quoteAccountManagers ? (
                <Table.Cell center>
                  <UserAvatars users={(quote.quoteAccountManagers ?? []).map((am) => am.user)} />
                </Table.Cell>
              ) : (
                <Table.Cell center>
                  <UserAvatars users={quote.accountManagers ?? []} />
                </Table.Cell>
              )}
            </>
          )}
        </Table.Row>
      ))}
    </Table>
  );
};
