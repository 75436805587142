import type { FC, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { Form, useForm } from 'graneet-form';

import { configureDefaultLabels } from '../configureDefaultLabels';
import { DATE_FORMAT_API } from '../../../utils';

import type { TimeTableMode } from './contexts';
import { TimeTableContext } from './contexts';
import { TimeTableHeader } from './components/TimeTableHeader';
import { TimeTableRow } from './components/TimeTableRow';
import type { ABSENCE_DURATION, ITimeTableSlot } from './types';
import type { TimeTableFormValues } from './types/time-table.form';
import { TimeTableApproveToast } from './components/TimeTableApproveToast';

dayjs.extend(weekday);

export interface TimeTableProps {
  numberOfDays: number;
  startDate: Date;
  onDelete(slots: ITimeTableSlot[]): void;
  onEdit(slot: ITimeTableSlot, segmentDate: Date): void;
  onCreate(id: string | number, subEntityId: string | number | undefined, isOff: boolean, date: Date): void;
  onApprove(ids: number[]): void | Promise<void>;
  onNextWeek(date: string | Date): void;
  onPreviousWeek(date: string | Date): void;
  mode: TimeTableMode;
  absenceDurationPossibleValues: Record<ABSENCE_DURATION, number>;
  children: ReactNode;
}

const TimeTableComponent: FC<TimeTableProps> = ({
  numberOfDays,
  startDate,
  onDelete,
  onEdit,
  onCreate,
  onApprove,
  onNextWeek,
  onPreviousWeek,
  mode,
  absenceDurationPossibleValues,
  children,
}) => {
  const form = useForm<TimeTableFormValues>();

  const [currentStartOfWeek, setCurrentStartOfWeek] = useState(dayjs(startDate).startOf('week'));

  const previousWeek = useCallback(() => {
    const date = currentStartOfWeek.subtract(1, 'week');

    setCurrentStartOfWeek(date);
    onPreviousWeek(date.format(DATE_FORMAT_API));
  }, [currentStartOfWeek, onPreviousWeek]);

  const nextWeek = useCallback(() => {
    const date = currentStartOfWeek.add(1, 'week');

    setCurrentStartOfWeek(date);
    onNextWeek(date.format(DATE_FORMAT_API));
  }, [currentStartOfWeek, onNextWeek]);

  const value = useMemo(
    () => ({
      onDelete,
      onEdit,
      onCreate,
      numberOfDays,
      currentStartOfWeek: currentStartOfWeek.toDate(),
      startDate,
      mode,
      absenceDurationPossibleValues,
    }),
    [absenceDurationPossibleValues, onDelete, onEdit, onCreate, numberOfDays, currentStartOfWeek, startDate, mode],
  );

  return (
    <TimeTableContext.Provider value={value}>
      <Form form={form}>
        <Box bg="white">
          <Flex direction="row" justifyContent="flex-end" alignItems="center" />

          <Divider />

          <TimeTableHeader
            onPreviousWeek={previousWeek}
            onNextWeek={nextWeek}
            currentStartOfWeek={currentStartOfWeek}
          />

          {children}
          <Divider />
        </Box>

        {mode === 'approve' && <TimeTableApproveToast onApprove={onApprove} />}
      </Form>
    </TimeTableContext.Provider>
  );
};

export const TimeTable = Object.assign(TimeTableComponent, {
  Row: TimeTableRow,
  configureDefaultLabels,
});
