import type { QuoteBasicItemUpsertDTO } from '@org/graneet-bff-client';
import type { QuoteNode } from '@org/quotation-lib';

import { mapQuantityFormulaObjectToQuantityFormulaDTO } from './quoteQuantityFormulaObjectToQuantityFormulaDTO';
import { mapMarginObjectToMarginDTO } from './quoteMarginObjectToMarginDTO';
import { genericUpdateItemMapper } from './quoteItemsGenericMapper';
import { mapQuoteFileObjectToFileDTO } from './quoteFileObjectToFileDTO';

export const mapQuoteBasicItemToUpdateQuantityFormula = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteBasicItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteBasicItem' && quoteNodeObject.parentId) {
    return {
      quantityFormula: mapQuantityFormulaObjectToQuantityFormulaDTO(content.quantityFormula),
      quoteBasicItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content is not QuoteBasicItem');
};

export const mapQuoteBasicItemToUpdateTotalMargin = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteBasicItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteBasicItem' && quoteNodeObject.parentId) {
    return {
      margin: mapMarginObjectToMarginDTO(content.margin),
      quoteBasicItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteBasicItem');
};

export const mapQuoteBasicItemToUpdateUnitAmount = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteBasicItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteBasicItem' && quoteNodeObject.parentId) {
    return {
      unitAmount: content.unitAmount,
      quoteBasicItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteBasicItem');
};

export const mapQuoteBasicItemToUpdateUnitFlatCostAmount = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteBasicItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteBasicItem' && quoteNodeObject.parentId) {
    return {
      unitFlatCostAmount: content.unitFlatCostAmount,
      quoteBasicItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteBasicItem');
};

export const mapQuoteBasicItemToUpdateVatRate = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteBasicItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteBasicItem' && quoteNodeObject.parentId) {
    return {
      vatRate: content.vatRate,
      quoteBasicItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteBasicItem');
};

export const mapQuoteNodeToUpsertBasicItemDTO = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteBasicItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteBasicItem' && quoteNodeObject.parentId) {
    return {
      denomination: content.denomination,
      refCode: content.refCode,
      manualIndex: content.manualIndex,
      note: content.note,
      unit: content.unit,
      vatRate: content.vatRate,
      amount: content.amount,
      unitFlatCostAmount: content.unitFlatCostAmount,
      unitAmount: content.unitAmount,
      flatCostAmount: content.flatCostAmount,
      quantityFormula: mapQuantityFormulaObjectToQuantityFormulaDTO(content.quantityFormula),
      margin: mapMarginObjectToMarginDTO(content.margin),
      quoteBasicItemId: content.id,
      files: content.files.map((file) => mapQuoteFileObjectToFileDTO(file)),
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content is not QuoteBasicItem');
};
