import type { ReactElement, ReactNode } from 'react';
import { useState, useMemo, forwardRef, useCallback } from 'react';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import type { StyleProps } from '@chakra-ui/react';
import {
  Box,
  Tabs as ChakraTabs,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  useTab,
  Button,
  Flex,
} from '@chakra-ui/react';

import { Badge } from '../Badge/Badge';

const computedFromTo = (to: string, from: string): string => {
  const isRelative = !to.startsWith('/');
  const separator = from.endsWith('/') ? '' : '/';
  const toRelative = to.replace(/^\.\//, '');
  return isRelative ? `${from}${separator}${toRelative}` : to;
};

type Variant = 'default' | 'badge';

interface CustomTabProps {
  children: ReactNode;
  variant?: Variant;
  isDisabled?: boolean;
}

const CustomTab = forwardRef<HTMLButtonElement, CustomTabProps>((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = tabProps['aria-selected'];

  return (
    <Button
      {...tabProps}
      _selected={{ color: 'greenBrand.light' }}
      _hover={{ color: 'greenBrand.light' }}
      _focus={{ boxShadow: 'none' }}
      color="greenBrand.lightText"
      cursor={isSelected ? 'default' : 'pointer'}
      px={props.variant === 'badge' ? 0 : 2}
      h="100%"
      whiteSpace="wrap"
      minW="inherit"
      py="5px"
    >
      {props.variant === 'badge' ? (
        <Badge
          colorScheme={isSelected ? 'greenBrand' : 'white'}
          highlightOnHover={!isSelected}
          height="28px"
          borderRadius={28}
          fontSize="sm"
        >
          <Text _firstLetter={{ textTransform: 'uppercase' }} fontSize="xs" fontWeight="semibold" lineHeight={5}>
            {tabProps.children}
          </Text>
        </Badge>
      ) : (
        <Text _firstLetter={{ textTransform: 'uppercase' }} fontSize="sm" fontWeight={400}>
          {tabProps.children}
        </Text>
      )}
    </Button>
  );
});

type TabScreen<T> = { id: string; title: ReactNode; exact?: boolean; content: ReactNode | null } & T;

interface TabsPropsWithRouting<PartialScreen extends { path: string }> {
  fallbackPath: PartialScreen['path'];
  errorComponent?: ReactElement;
  data: Array<TabScreen<PartialScreen>>;
  variant?: Variant;
  topRightContent?: ReactNode;
  hasInternalPadding?: boolean;
  onChange?: (focusedTab: { id: string }) => void;
}

interface TabsProps {
  data: Array<{
    id: string;
    title: ReactNode;
    content: ReactNode;
  }>;
  variant?: Variant;
  topRightContent?: ReactNode;
  hasInternalPadding?: boolean;
  onChange?: (focusedTab: { id: string }) => void;
}

export const Tabs = <const PartialScreen extends { path: string }>({
  data,
  variant = 'default',
  hasInternalPadding,
  topRightContent,
  onChange,
  ...props
}: TabsPropsWithRouting<PartialScreen> | TabsProps) => {
  const { path: parentPath, url: parentUrl } = useRouteMatch();
  const { pathname } = useLocation();

  const computedPath = useCallback((path: string) => computedFromTo(path, parentPath), [parentPath]);
  const computedUrl = useCallback((url: string) => computedFromTo(url, parentUrl), [parentUrl]);

  const [tabIndex, setTabIndex] = useState(() => {
    let defaultIndex = 0;
    data.forEach((d, index) => {
      if (!('path' in d)) {
        return;
      }

      const isSelected =
        'exact' in d ? pathname === computedUrl(d.path as string) : pathname.startsWith(computedUrl(d.path as string));
      if (isSelected) {
        defaultIndex = index;
      }
    });

    return defaultIndex;
  });
  const handleChange = useCallback(
    (index: number) => {
      setTabIndex(index);
      onChange?.(data[index]);
    },
    [data, onChange],
  );

  const style = useMemo(
    (): {
      [key in Variant]: {
        tabList: StyleProps;
        tabPanels: StyleProps;
      };
    } => ({
      default: {
        tabList: {
          pl: hasInternalPadding ? 8 : undefined,
          bg: 'white',
          minHeight: '2.5rem',
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'gray.200',
        },
        tabPanels: { p: hasInternalPadding ? 8 : 2 },
      },
      badge: {
        tabList: {
          px: 8,
          mb: 8,
          mt: '-28px',
          position: 'absolute',
          bg: 'white',
          left: 0,
          right: 0,
          py: 3,
        },
        tabPanels: { pt: '40px' },
      },
    }),
    [hasInternalPadding],
  );
  const hasPaths = !!data.find((tab) => 'path' in tab);

  return (
    <ChakraTabs
      index={tabIndex}
      onChange={handleChange}
      variant={variant !== 'default' ? 'unstyled' : 'line'}
      position="inherit"
      isLazy={hasPaths}
      flex={1}
      display="flex"
      flexDirection="column"
    >
      <Box>
        <TabList {...style[variant].tabList} flexShrink={0} overflow="auto" gap={4}>
          {data.map((tab) => (
            <CustomTab
              isDisabled={!tab.content}
              {...('path' in tab ? { to: computedUrl(tab.path), as: Link } : { as: 'span' })}
              key={tab.id}
              variant={variant}
            >
              {tab.title}
            </CustomTab>
          ))}
        </TabList>

        {topRightContent && (
          <Flex position="absolute" right={0}>
            {topRightContent}
          </Flex>
        )}
        {variant !== 'badge' && <TabIndicator mt="-2px" height="2px" bg="greenBrand.light" borderRadius="1px" />}
      </Box>

      <TabPanels {...style[variant].tabPanels} flex={1} display="flex" flexDirection="column">
        {!hasPaths &&
          data.map((tab) => (
            <TabPanel key={tab.id} px={0} display="flex" flexDirection="column">
              {tab.content}
            </TabPanel>
          ))}
        {hasPaths && (
          <Switch>
            {data.map(
              (tab) =>
                'path' in tab && (
                  <Route key={tab.id} exact={tab.exact} path={computedPath(tab.path)}>
                    {tab.content}
                  </Route>
                ),
            )}
            {'fallbackPath' in props && (
              <Route exact path={parentPath}>
                <Redirect to={computedUrl(props.fallbackPath)} />
              </Route>
            )}
            {'errorComponent' in props && <Route path="*">{props.errorComponent}</Route>}
          </Switch>
        )}
      </TabPanels>
    </ChakraTabs>
  );
};
