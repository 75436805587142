import type { FC, MouseEvent, ReactNode } from 'react';
import { useCallback } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { SimpleInternalLinkIcon } from '../Icons';
import { EllipsisText } from '../EllipsisText';

export interface GotoLinkProps extends FlexProps {
  to: string;
  label: ReactNode;
  target?: '_blank';
  style?: object;
}

export const GotoLink: FC<GotoLinkProps> = ({
  to,
  label,
  // CSS hack because alignSelf chakra prop doesn't work
  style = { alignSelf: 'center' },
  color = 'gray.600',
  target,
  ...otherProps
}) => {
  const handleOnClick = useCallback((e: MouseEvent<HTMLElement>) => e.stopPropagation(), []);
  return (
    <Link to={to} target={target} role="group" style={style} onClick={handleOnClick}>
      <Flex
        fontSize="md"
        fontFamily="body"
        _hover={{
          textDecoration: 'underline',
        }}
        align="center"
        color={color}
        {...otherProps}
      >
        <EllipsisText ml={1} color={color}>
          {label}
        </EllipsisText>
        <SimpleInternalLinkIcon stroke={color} ml={2} w={3} h={3} />
      </Flex>
    </Link>
  );
};

export const SimpleLink: FC<GotoLinkProps> = ({
  to,
  label,
  // CSS hack because alignSelf chakra prop doesn't work
  style,
}) => {
  const handleOnClick = useCallback((e: MouseEvent<HTMLElement>) => e.stopPropagation(), []);
  return (
    <Link to={to} role="group" style={style} onClick={handleOnClick}>
      <Text
        _hover={{
          color: 'greenBrand.light',
          textDecoration: 'underline',
        }}
      >
        {label}
      </Text>
    </Link>
  );
};
