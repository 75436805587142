import { StepperModal } from '@graneet/lib-ui';
import type { IContact } from '@graneet/business-logic';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { Form, useFormStatus, useStepForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { Box, Text, VStack } from '@chakra-ui/react';

import type { ContactUpsertWizard } from '../ContactUpsertWizard';

import {
  CLIENT_OR_SUPPLIER_ASSOCIATION,
  ClientOrSupplierAssociationForm,
} from 'features/contact/components/forms/ClientOrSupplierAssociationForm';

export interface ContactAssociateStepProps {
  contact: IContact | null;
}

export const ContactAssociateStep: FC<ContactAssociateStepProps> = ({ contact }) => {
  const { t } = useTranslation(['contacts']);

  const { form: clientOrSupplierAssociateForm } = useStepForm<ContactUpsertWizard, 'associate-contact'>();
  const { isValid } = useFormStatus(clientOrSupplierAssociateForm);

  // Init form values
  useEffect(() => {
    if (contact?.client) {
      clientOrSupplierAssociateForm.setFormValues({
        association: {
          type: CLIENT_OR_SUPPLIER_ASSOCIATION.CLIENT,
          id: contact.client.id,
        },
      });
    }
    if (contact?.supplier) {
      clientOrSupplierAssociateForm.setFormValues({
        association: {
          type: CLIENT_OR_SUPPLIER_ASSOCIATION.SUPPLIER,
          id: contact.supplier.id,
        },
      });
    }
  }, [clientOrSupplierAssociateForm, contact]);

  const ctaTranslation = useMemo(() => {
    if (contact?.client || contact?.supplier) {
      return 'contacts:modal.associate.buttons.nextDissociate';
    }
    return 'contacts:modal.associate.buttons.next';
  }, [contact]);

  if (!contact) {
    return null;
  }

  return (
    <>
      <VStack my={4} w="100%" align="flex-start">
        <Text mb={2}>{t('contacts:modal.associate.content')}</Text>
        <Box w="full">
          <Form form={clientOrSupplierAssociateForm}>
            <ClientOrSupplierAssociationForm
              searchClients
              searchSuppliers
              isRequired
              showBadge
              placeholder={t('contacts:fields.associationPlaceholder')}
            />
          </Form>
        </Box>
      </VStack>

      <StepperModal.CloseButton>{t('contacts:modal.associate.buttons.cancel')}</StepperModal.CloseButton>
      <StepperModal.PrimaryButton isDisabled={!isValid}>{t(ctaTranslation)}</StepperModal.PrimaryButton>
    </>
  );
};
