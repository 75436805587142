import type {
  ILedger,
  ILedgerCounterUpdateInputDTO,
  ILedgerCounterUpdateOutputDTO,
  ILedgerCreateOrUpdateDTO,
  ILedgerPreviewNextOutputDTO,
  ILedgerWithNextNumber,
  LEDGER_TYPES,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { userKeyFactory } from 'features/user/services/user.api';
import { apiNew } from 'features/api/services/apiNew.service';
import { api } from 'features/api/services/api.service';

const LEDGER_PATH = '/ledgers';

export type LedgerPreviewResponse = {
  type: LEDGER_TYPES;
  referenceDate?: string;
  projectId?: number;
  lastCounter?: number;
};

const ledgerKeyFactory = createQueryKeys('ledgers', {
  get: () => ({
    queryKey: [LEDGER_PATH],
    queryFn: () => apiNew.get<never, ILedgerWithNextNumber[]>(LEDGER_PATH),
  }),
});

export function useLedgers() {
  return useSuspenseQuery(ledgerKeyFactory.get());
}

export function useCreateOrUpdateLedger() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ILedgerCreateOrUpdateDTO) => apiNew.put<ILedgerCreateOrUpdateDTO, ILedger>(LEDGER_PATH, dto),
    onSuccess: async () => {
      toast.success(t('global:toasts.successSave'));

      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
      await queryClient.invalidateQueries(ledgerKeyFactory.get());
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useUpdateLedgerCounter() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ILedgerCounterUpdateInputDTO) =>
      apiNew.patch<ILedgerCounterUpdateInputDTO, ILedgerCounterUpdateOutputDTO>(`${LEDGER_PATH}/counter`, dto),
    onSuccess: async () => {
      toast.success(t('global:toasts.successSave'));
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
      await queryClient.invalidateQueries(ledgerKeyFactory.get());
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export const getNextLedgerStatePreview = (queryParams: LedgerPreviewResponse) =>
  api.get<LedgerPreviewResponse, ILedgerPreviewNextOutputDTO>(`${LEDGER_PATH}/preview-next`, queryParams);
