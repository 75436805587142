import { Card, MetricWithDescription, Price, GotoLink } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ClientStatementsCardProps {
  amount: number;
  link: string;
}

export const ClientStatementsCard: FC<ClientStatementsCardProps> = ({ amount, link }) => {
  const { t } = useTranslation(['clients']);

  return (
    <Card
      title={t('clients:statementsCard.title')}
      topRightContent={<GotoLink style={{}} to={link} label={t('clients:statementsCard.goToLink')} />}
    >
      <MetricWithDescription description={t('clients:statementsCard.description')} color="greenBrand.light">
        <Price amount={amount} />
      </MetricWithDescription>
    </Card>
  );
};
