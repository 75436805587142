import type {
  QuoteLotTransformDTO,
  QuoteLotUpdateByComponentTypeDTO,
  QuoteLotUpsertDTO,
  QuoteLotsApi,
} from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteLotsProxyApi {
  private static quoteLotsProxyApi: QuoteLotsProxyApi;

  private readonly quoteLotsApi: QuoteLotsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteLotsApi = externalApi.getQuoteLotsApi();
  }

  public static getQuoteLotsProxyApi(externalApi: ExternalApi): QuoteLotsProxyApi {
    if (!this.quoteLotsProxyApi) {
      QuoteLotsProxyApi.quoteLotsProxyApi = new QuoteLotsProxyApi(externalApi);
    }
    return QuoteLotsProxyApi.quoteLotsProxyApi;
  }

  public async createQuoteLot(quoteLotCreateDTO: QuoteLotUpsertDTO) {
    return proxyHelper(async () => this.quoteLotsApi.createQuoteLot(quoteLotCreateDTO));
  }

  public async updateQuoteLot(quoteLotId: string, quoteLotUpdateDTO: QuoteLotUpsertDTO) {
    return proxyHelper(async () => this.quoteLotsApi.updateQuoteLot(quoteLotId, quoteLotUpdateDTO));
  }

  public async transformQuoteLotChild(quoteLotId: string, quoteLotTransformDTO: QuoteLotTransformDTO) {
    return proxyHelper(async () => this.quoteLotsApi.transformQuoteLotChild(quoteLotId, quoteLotTransformDTO));
  }

  public async updateByComponentType(
    quoteLotId: string,
    componentTypeId: number,
    quoteLotUpdateByComponentTypeDTO: QuoteLotUpdateByComponentTypeDTO,
  ) {
    return proxyHelper(async () =>
      this.quoteLotsApi.updateByComponentType(quoteLotId, componentTypeId, quoteLotUpdateByComponentTypeDTO),
    );
  }
}
