import type { FC } from 'react';
import { useCallback } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { EllipsisText, TwoColumns } from '@graneet/lib-ui';
import { Flex } from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { useSubProject } from 'features/sub-project/services/sub-project.api';
import { SubProjectEditInformation } from 'features/sub-project/components/cards/SubProjectEditInformation';
import { HoldbackCard } from 'features/holdback/components/HoldbackCard';
import { SubProjectSettingsCard } from 'features/sub-project/components/cards/SubProjectSettingsCard';
import { HoldbackCardNew } from 'features/holdback/components/HoldbackCardNew';
import { FeatureFlag } from 'features/feature-flag/components/FeatureFlag';

export const ViewSubProjectScreen: FC = () => {
  const { subProjectId, projectId } = useParams<{ subProjectId: string; projectId: string }>();

  const { url } = useRouteMatch();

  const subProject = useSubProject(subProjectId);

  const history = useHistory();

  const goToProjectParameters = useCallback(() => {
    history.push(`/projects/${projectId}/sub-projects/${subProject.data.id}/parameters`, {
      nextUrl: url,
      referrerUrl: url,
    });
  }, [history, projectId, subProject.data.id, url]);

  return (
    <>
      <Flex flexDirection="row" alignItems="center" mb={6}>
        <EllipsisText fontWeight="bold" fontSize={22} mr={4}>
          {subProject.data.name}
        </EllipsisText>
      </Flex>

      <TwoColumns>
        <SubProjectEditInformation subProject={subProject.data} />

        <SubProjectSettingsCard subProject={subProject.data} onEditSubProjectParams={goToProjectParameters} />

        <FeatureFlag feature={FEATURE_FLAGS.PROJECT_GLOBAL_PARAMETERS}>
          <FeatureFlag.OnActive>
            {subProject.data.holdback && (
              <HoldbackCardNew holdback={subProject.data.holdback} onEditSubProjectParams={goToProjectParameters} />
            )}
          </FeatureFlag.OnActive>
          <FeatureFlag.OnFallback>
            {subProject.data.holdback && <HoldbackCard holdback={subProject.data.holdback} />}
          </FeatureFlag.OnFallback>
        </FeatureFlag>
      </TwoColumns>
    </>
  );
};
