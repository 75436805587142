import type { CSSProperties } from 'react';

export const RICH_TEXT_CSS_VAR_LIGHT = {
  '--yellow': '#FFD644',
  '--orange': '#F39035',
  '--red': '#DA4033',
  '--pink': '#E73D90',
  '--purple': '#6E24F5',
  '--blue': '#109CF1',
  '--cyan': '#3AE7E1',
  '--green': '#30D47B',
  '--warn-gray': '#857F72',
} as CSSProperties;

export const RICH_TEXT_CSS_VAR_DARK = {
  '--yellow': '#EFBA00',
  '--orange': '#E36D00',
  '--red': '#BB0E00',
  '--pink': '#AD0055',
  '--purple': '#46047B',
  '--blue': '#004E7A',
  '--cyan': '#099AA4',
  '--green': '#106A3B',
  '--warn-gray': '#423D33',
} as CSSProperties;
