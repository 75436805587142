import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AmountSummary, isNumberFinite, useCurrency } from '@graneet/lib-ui';
import type { IProjectSubProjectWithStatementsListingDTO } from '@graneet/business-logic';
import { PROGRESS_STATEMENT_STATUSES, sumObjects } from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { flatMap } from 'lodash-es';

import type { FinalStatementPriceRevisionForm } from '../forms/final-statement-edition.wizard';
import { getPriceRevisionFieldName } from '../forms/final-statement-edition.wizard';

interface FinalStatementTotalAmountProps {
  subProjects: IProjectSubProjectWithStatementsListingDTO['subProjects'];
}

export const FinalStatementTotalAmount: FC<FinalStatementTotalAmountProps> = ({ subProjects }) => {
  const { t } = useTranslation(['project']);
  const { mapAmountToNumber } = useCurrency();

  const form = useFormContext<FinalStatementPriceRevisionForm>();
  const values = useOnChangeValues(form, undefined);

  const total = useMemo(() => {
    const progressStatements = flatMap(
      subProjects.filter((subProject) => subProject.hasPriceRevision),
      (subProject) => subProject.progressStatements,
    ).filter((progressStatement) => progressStatement.status !== PROGRESS_STATEMENT_STATUSES.CANCELLED);

    const totalPriceRevision = sumObjects(progressStatements, 'priceRevisionExVAT');
    const sumDefinitive = progressStatements.reduce((acc, ps) => {
      const name = getPriceRevisionFieldName(ps);
      const value = Number(values[name]);
      // Value may be NaN in the case of canceled progress statements which are filtered away from display
      if (isNumberFinite(value)) {
        return acc + value;
      }
      return acc;
    }, 0);

    return mapAmountToNumber(sumDefinitive) - totalPriceRevision;
  }, [mapAmountToNumber, subProjects, values]);

  return (
    <AmountSummary>
      <AmountSummary.Item
        sign
        important
        isAmountColored
        label={t('project:finalStatement.priceRevision.total')}
        amount={total}
      />
    </AmountSummary>
  );
};
