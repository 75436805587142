import type { ReactNode, FC } from 'react';
import { useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { useScrollContext } from '../../hooks';

const SCROLL_THRESHOLD = 100;

export interface InfiniteScrollerProps {
  children: ReactNode;

  loadMore(): void | Promise<void>;

  hasMore: boolean;
}

export const InfiniteScroller: FC<InfiniteScrollerProps> = ({ children, loadMore, hasMore }) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const scrollRef = useScrollContext();

  const getScrollParent = useCallback(() => scrollRef, [scrollRef]);

  const handleLoadMore = useCallback(async () => {
    if (!hasMore || isFetching) return;

    setIsFetching(true);
    await loadMore();
    setIsFetching(false);
  }, [hasMore, isFetching, loadMore]);

  return (
    <InfiniteScroll
      style={{ width: '100%' }}
      pageStart={0}
      initialLoad={false}
      // the threshold in px at which we trigger the loadMore callback
      threshold={SCROLL_THRESHOLD}
      getScrollParent={getScrollParent}
      useWindow={false}
      loadMore={handleLoadMore}
      // Infinite scroll is not updated properly in some cases, so it's handled in handleLoadMore
      hasMore
    >
      {children}
    </InfiniteScroll>
  );
};
