import type { FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Flex, ModalBody, ModalHeader } from '@chakra-ui/react';

import { BUTTON_TYPE, useModalButtons } from '../Modal/useModalButtons';
import { SecondaryButton } from '../Modal/SecondaryButton';
import { CloseButton } from '../Modal/CloseButton';
import { PrimaryButton } from '../Modal/PrimaryButton';
import { ModalButtonsProvider } from '../Modal/ModalButtonsContext';
import { Footer } from '../Modal/Footer';
import { ScrollProvider } from '../../hooks';
import { SimpleArrowBackwardIcon } from '../Icons';

import { useNavigationModalContext } from './NavigationModalContext';

export interface ModalViewProps {
  path?: string;
  title?: string;
  children: ReactNode;
}

interface InternalViewProps {
  title: string | null;
  onClose(): void;
  modalView: ReactNode;
}

const InternalView: FC<InternalViewProps> = ({ modalView, onClose, title = null }) => {
  // Cast as any since History type does not include an index, but react router dom does
  const history = useHistory() as any;
  // Ref of ModalBody is transmitted to Infinite scroll because we have to get component size
  // HACK: dirty fix for bad infinite scroll behaviour (#2412)
  const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);

  const buttonsContext = useModalButtons();

  const {
    buttons: {
      [BUTTON_TYPE.PRIMARY]: primaryButtonsProps,
      [BUTTON_TYPE.SECONDARY]: secondaryButtonsProps,
      [BUTTON_TYPE.CLOSE]: closeButtonsProps,
    },
  } = buttonsContext;

  const secondaryButtons =
    secondaryButtonsProps &&
    secondaryButtonsProps.map((secondaryButtonProps) => <SecondaryButton {...secondaryButtonProps} />);

  const [closeButtonProps] = closeButtonsProps || [];
  const closeButton = closeButtonProps && <CloseButton {...closeButtonProps} onClick={onClose} />;

  const leftButtons = closeButton || secondaryButtons;

  const primaryButtons = useMemo(
    () =>
      !!primaryButtonsProps &&
      primaryButtonsProps.map((primaryButtonProps, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PrimaryButton {...primaryButtonProps} key={index} />
      )),
    [primaryButtonsProps],
  );

  const canGoBack = history.index !== 0;

  return (
    <ModalButtonsProvider value={buttonsContext}>
      <ScrollProvider value={bodyRef}>
        <ModalHeader>
          {canGoBack ? (
            <Flex alignItems="center">
              <SimpleArrowBackwardIcon
                boxSize={7}
                onClick={history.goBack}
                _hover={{ stroke: 'greenBrand.light' }}
                cursor="pointer"
                stroke="gray.600"
                mr={2}
              />
              {title}
            </Flex>
          ) : (
            title
          )}
        </ModalHeader>

        <ModalBody ref={setBodyRef}>{modalView}</ModalBody>

        <Footer secondaryButtons={leftButtons} primaryButtons={primaryButtons} />
      </ScrollProvider>
    </ModalButtonsProvider>
  );
};

export const ModalView: FC<ModalViewProps> = ({ path = '/', title = null, children }) => {
  const location = useLocation();

  const navigationModalContext = useNavigationModalContext();

  return (
    <Switch location={location}>
      <Route exact path={path} key={path}>
        <InternalView modalView={children} onClose={navigationModalContext.onClose} title={title} />
      </Route>
    </Switch>
  );
};
