import { isNumberFinite } from '@graneet/business-logic';
import { useCurrency } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import Big from 'big.js';
import { ComputeQuoteCustomDiscountAmountUseCase, QuoteCustomDiscount } from '@org/quotation-lib';

import type { QuotationCustomDiscountEditionForm } from '../forms/custom-discount-edition-form';
import { POSITIVE_MULTIPLIER } from '../forms/custom-discount-edition-form';

export const useCustomDiscountTotal = (amountAfterSaleDiscountExVAT: Big | null): Big => {
  const { mapAmountToStringNumber } = useCurrency();

  const form = useFormContext<QuotationCustomDiscountEditionForm>();
  const { customDiscountType, customDiscountPercentage, customDiscountAmountExVAT, customDiscountMultiplier } =
    useOnChangeValues(form, [
      'customDiscountType',
      'customDiscountPercentage',
      'customDiscountAmountExVAT',
      'customDiscountMultiplier',
    ]);

  if (
    amountAfterSaleDiscountExVAT === null ||
    (customDiscountPercentage === undefined && customDiscountAmountExVAT === undefined)
  ) {
    return Big(0);
  }

  const formCustomDiscount = {
    type: customDiscountType,
    percentage: isNumberFinite(customDiscountPercentage)
      ? Big(customDiscountPercentage)
          .mul(customDiscountMultiplier || POSITIVE_MULTIPLIER)
          .toString()
      : '0',
    amount: isNumberFinite(customDiscountAmountExVAT)
      ? mapAmountToStringNumber(
          Big(customDiscountAmountExVAT)
            .mul(customDiscountMultiplier || POSITIVE_MULTIPLIER)
            .toString(),
        )
      : '0',
  };

  const customDiscount = new QuoteCustomDiscount(formCustomDiscount);

  return amountAfterSaleDiscountExVAT.add(
    ComputeQuoteCustomDiscountAmountUseCase.execute(customDiscount, amountAfterSaleDiscountExVAT),
  );
};
