import type { FC } from 'react';
import type { IAccountingExport } from '@graneet/business-logic';
import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

interface AccountingExportBadgeProps {
  accountingExport: Pick<IAccountingExport, 'isActive'>;
}

export const AccountingExportBadge: FC<AccountingExportBadgeProps> = ({ accountingExport }) => {
  const { t } = useTranslation(['accounting']);
  if (accountingExport.isActive) {
    return <Badge colorScheme="green">{t('accounting:statuses.exported')}</Badge>;
  }

  return <Badge colorScheme="red">{t('accounting:statuses.cancelled')}</Badge>;
};
