export enum ErrorIconOption {
  Unknown = 'unknown',
  Worker = 'worker',
}

type ErrorIcon = ErrorIconOption.Unknown | ErrorIconOption.Worker;

export interface ErrorInfo {
  /**
   * Use this to determine which error screen to show
   * @default 'unknown'
   */
  icon?: ErrorIcon;

  /**
   * Key of a translation string to show as the error message
   * Use this for translated error messages, otherwise use `message`. do not use both
   * @default 'global:errors.pageNotLoaded'
   */
  translationKey?: string;

  /**
   * Params to pass to the translation string (second argument of `t` function)
   * only used when `translationKey` is defined
   * @default undefined
   */
  translationParams?: Record<string, string>;

  /**
   * Untranslated message to show as the error message
   * Use this for non-translated error messages, otherwise use `translationKey`. do not use both
   * @default undefined
   */
  message?: string;
}
