import { useCallback } from 'react';
import Big from 'big.js';
import type { SuppressKeyboardEventParams } from '@ag-grid-community/core';

import { AmountCell } from '../../components/columns/AmountCell/AmountCell';
import type { AGColDef } from '../../types';

export function useCostAmountColumnDef() {
  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'costAmount',
      width,
      index,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      headerName: label,
      editable: false,
      sortable: false,
      suppressMovable: false,
      cellStyle: { textAlign: 'right' },
      cellClass: 'no-focus read-only',
      cellRenderer: AmountCell,
      valueGetter: (params) => {
        switch (params.data?.content.type) {
          case 'QuoteBasicItem': {
            const shareOfHiddenCostAmount = Big(params.data?.content.shareOfHiddenCostAmount ?? 0);
            const flatCostAmount = Big(params.data?.content.flatCostAmount ?? 0);
            return flatCostAmount.plus(shareOfHiddenCostAmount).toString();
          }
          case 'QuoteSubItem':
          case 'QuoteComponent':
          case 'QuoteOptionalItem':
          case 'QuoteHiddenCostItem': {
            return Big(params.data?.content.flatCostAmount ?? 0).toString();
          }
          case 'QuoteLot': {
            const shareOfHiddenCostAmount = Big(params.data?.content.shareOfHiddenCostAmount ?? 0);
            const flatCostAmount = Big(
              (params.data?.content.isEveryChildOptional
                ? params.data?.content.optionalFlatCostAmount
                : params.data?.content.flatCostAmount) ?? 0,
            );
            return flatCostAmount.plus(shareOfHiddenCostAmount).toString();
          }
          default:
            return '-';
        }
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [],
  );
}
