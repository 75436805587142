import type { FC } from 'react';
import { useCallback } from 'react';
import type { IProjectSubProjectWithContractsListingDTO, SubProjectExtendedContract } from '@graneet/business-logic';
import { Button, Date, DeepTable, GotoLink, PriceAdvanced, SimpleSearchIcon, useDeepTable } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { compact } from 'lodash-es';

interface SubProjectTableProps {
  subProject: IProjectSubProjectWithContractsListingDTO['subProjects'][0];

  displayHeader: boolean;

  dropDownRender?: FC<{ numberOfContracts: number; contract: SubProjectExtendedContract }>;

  onSubProjectEdit?(subProjectId: string): void;

  displayGoToQuoteLink: boolean;
}

export const SubProjectTable: FC<SubProjectTableProps> = ({
  subProject,
  displayHeader,
  dropDownRender: DropDownRender,
  onSubProjectEdit,
  displayGoToQuoteLink,
}) => {
  const { t } = useTranslation(['contracts', 'global']);

  const templateColumns = compact(['8rem', 'minmax(15rem, auto)', '8rem', '8rem', DropDownRender ? '5rem' : '0']).join(
    ' ',
  );

  const deepTable = useDeepTable({
    templateColumns,
    leftContentWidth: 0,
  });

  const handleSubProjectEdit = useCallback(() => {
    onSubProjectEdit?.(subProject.id);
  }, [onSubProjectEdit, subProject.id]);

  return (
    <DeepTable deepTable={deepTable}>
      {displayHeader && (
        <DeepTable.Header>
          <DeepTable.Cell>{t('contracts:reference')}</DeepTable.Cell>

          <DeepTable.Cell> {t('contracts:fields.contractName')}</DeepTable.Cell>

          <DeepTable.Cell>{t('contracts:receptionDate')}</DeepTable.Cell>

          <DeepTable.Cell>{t('contracts:totalAmountExVAT')}</DeepTable.Cell>

          <DeepTable.Cell />
        </DeepTable.Header>
      )}

      {/* Sub project row */}
      <DeepTable.Row bg="gray.150" textColor="gray.900">
        <DeepTable.Cell gridColumn="span 3">{subProject.name}</DeepTable.Cell>

        <DeepTable.Cell>
          <PriceAdvanced amount={subProject.amountExVAT} />
        </DeepTable.Cell>

        <DeepTable.Cell ml={-2}>
          {onSubProjectEdit && (
            <Button variant="outline" color="gray.600" leftIcon={<SimpleSearchIcon />} onClick={handleSubProjectEdit}>
              {t('global:words.c.details')}
            </Button>
          )}
        </DeepTable.Cell>
      </DeepTable.Row>

      {/* Contract rows */}
      {subProject.contracts.map((contract) => (
        <DeepTable.Row key={contract.id}>
          <DeepTable.Cell variant="ellipsis">{contract.code}</DeepTable.Cell>

          <DeepTable.Cell>
            {displayGoToQuoteLink ? (
              <GotoLink
                to={
                  contract.quote
                    ? `/opportunities/quotes/${contract.quote.id}/summary`
                    : `/opportunities/quotation/${contract.quoteUUID}/summary`
                }
                label={contract.name}
                whiteSpace="nowrap"
                fontSize="sm"
                color="gray.400"
              />
            ) : (
              <Text>{contract.name}</Text>
            )}
          </DeepTable.Cell>

          <DeepTable.Cell>
            <Date>{contract.receptionDate}</Date>
          </DeepTable.Cell>

          <DeepTable.Cell>
            <PriceAdvanced amount={contract.totalAmountExVAT} />
          </DeepTable.Cell>

          <DeepTable.Cell center>
            {DropDownRender && <DropDownRender numberOfContracts={subProject.contracts.length} contract={contract} />}
          </DeepTable.Cell>
        </DeepTable.Row>
      ))}
    </DeepTable>
  );
};
