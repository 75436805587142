import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@graneet/lib-ui';
import type { IProjectSubProjectWithStatementsListingDTO } from '@graneet/business-logic';
import { PROGRESS_STATEMENT_STATUSES } from '@graneet/business-logic';
import { Box, Stack } from '@chakra-ui/react';

import { FinalStatementPriceRevisionRow } from './FinalStatementPriceRevisionRow';

interface FinalStatementPriceRevisionTableProps {
  subProjects: IProjectSubProjectWithStatementsListingDTO['subProjects'];
}

export const FinalStatementPriceRevisionTable: FC<FinalStatementPriceRevisionTableProps> = ({ subProjects }) => {
  const { t } = useTranslation(['project']);

  return (
    <Stack spacing={4}>
      {subProjects
        .filter((subProject) => subProject.hasPriceRevision)
        .map((subProject, index) => (
          <Box key={subProject.id} borderRadius="6px" boxShadow="subtle" overflow="hidden">
            <Table
              key={subProject.id}
              templateColumns={['minmax(auto, 10rem)', 'minmax(15rem, 1fr)', '10rem', '10rem', '12rem', '6rem']}
              bg="white"
            >
              {index === 0 && (
                <Table.Header>
                  <Table.Header>
                    <Table.Cell>{t('project:finalStatement.priceRevision.fields.number')}</Table.Cell>

                    <Table.Cell>{t('project:finalStatement.priceRevision.fields.progressStatementName')}</Table.Cell>

                    <Table.Cell right>
                      {t('project:finalStatement.priceRevision.fields.nonCumulativeAmountExVAT')}
                    </Table.Cell>

                    <Table.Cell right>
                      {t('project:finalStatement.priceRevision.fields.priceRevisionTemporaryExVAT')}
                    </Table.Cell>

                    <Table.Cell center>
                      {t('project:finalStatement.priceRevision.fields.priceRevisionFinalExVAT')}
                    </Table.Cell>

                    <Table.Cell right>
                      {t('project:finalStatement.priceRevision.fields.priceRevisionDeltaExVAT')}
                    </Table.Cell>
                  </Table.Header>
                </Table.Header>
              )}

              {/* Sub project row */}
              {subProjects.length > 1 && (
                <Table.Row bg="gray.200" textColor="gray.900">
                  <Table.Cell gridColumn="span 6">{subProject.name}</Table.Cell>
                </Table.Row>
              )}

              {subProject.progressStatements
                .filter((progressStatement) => progressStatement.status !== PROGRESS_STATEMENT_STATUSES.CANCELLED)
                .map((progressStatement) => (
                  <FinalStatementPriceRevisionRow key={progressStatement.id} progressStatement={progressStatement} />
                ))}
            </Table>
          </Box>
        ))}
    </Stack>
  );
};
