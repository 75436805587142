import { Box, GridItem, useDisclosure, VStack } from '@chakra-ui/react';
import { ActionMenu, Badge, CogIcon, TwoColumns, useToast } from '@graneet/lib-ui';
import type { QuoteArchiveDetails, IPdf, IQuoteUpdateDTO } from '@graneet/business-logic';
import { PERMISSION, EMAIL_ENTITY_TYPE, isPdfBeingGenerated, HISTORY_ENTITY_TYPE } from '@graneet/business-logic';
import type { FC } from 'react';
import { useRef, useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import type { QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';
import { QuoteStatus } from '@org/graneet-bff-client';

import { QuoteProjectUpdateCallout } from 'features/quote/components/QuoteProjectUpdateCallout';
import { EmailProvider } from 'features/history/contexts/EmailContext';
import { HistoryByFamilyCard } from 'features/history/components/HistoryFamilyCard';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import { useEmailTemplateRichTextConfiguration } from 'features/email-template/hooks/useEmailTemplateRichTextConfiguration';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { CreateQuoteModal } from 'features/quote/components/modals/CreateQuoteModal';
import { RestoreQuoteModal } from 'features/quote/components/modals/RestoreQuoteModal';
import { useQuotationApi } from 'features/quotation/services/quote.api';
import { QuoteStatusCard } from 'features/quotation/quote/components/cards/QuoteStatusCard';
import { QuoteInformationCard } from 'features/quotation/quote/components/cards/QuoteInformationCard';
import { QuoteMarginCard } from 'features/quotation/quote/components/cards/QuoteMarginCard';
import { QuoteTagsCard } from 'features/quotation/quote/components/cards/QuoteTagsCard';
import { QuoteCommentCard } from 'features/quotation/quote/components/cards/QuoteCommentCard';

type IQuoteArchiveDetailsExtended = Omit<QuoteArchiveDetails, 'pdfId'> & { pdf: IPdf | null };

interface ViewQuoteSummaryTabProps {
  quote: QuoteWithoutRelationsDTO;
}

export const ViewQuoteSummaryTab: FC<ViewQuoteSummaryTabProps> = ({ quote }) => {
  const {
    useGetClientById,
    useGetProjectInformationById,
    useGetQuoteVersionsById,
    useQuoteDuplicate,
    refetchPdfsByIds,
    useQuoteRestore,
    useGetContractByQuoteId,
    useGetPdfsByIds,
    useGetContactsByIds,
    refetchQuoteById,
  } = useQuotationApi();

  const restoreQuoteMutation = useQuoteRestore();
  const duplicateQuoteMutation = useQuoteDuplicate();

  const history = useHistory();
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);
  const selectedQuoteIdRef = useRef<string>();
  const hasPermissions = usePermissions([PERMISSION.CREATE_QUOTES]);
  const duplicationModalControls = useDisclosure();
  const onRestoreModalControls = useDisclosure();

  const { data: client } = useGetClientById(quote.clientId);
  const { data: project } = useGetProjectInformationById(quote?.projectId);
  const { data: archivedQuotes } = useGetQuoteVersionsById(quote.id);
  const { data: contract } = useGetContractByQuoteId(quote.id);
  const { data: contacts } = useGetContactsByIds(
    quote.contacts ? quote.contacts.map((contact) => contact.id) : undefined,
  );
  const pdfIds = useMemo(
    () => [
      ...(archivedQuotes?.versions?.filter((q) => q.pdfId).map((q) => q.pdfId!) ?? []),
      ...(quote.pdfId ? [quote.pdfId] : []),
    ],
    [archivedQuotes?.versions, quote.pdfId],
  );

  const { data: pdfs } = useGetPdfsByIds(pdfIds);

  const quoteContacts = useMemo(() => {
    const quoteContactsMap = new Map<string, boolean>();
    if (quote.contacts) {
      quote.contacts.forEach((contact) => {
        quoteContactsMap.set(contact.id, contact.isDefaultEmailRecipient);
      });
    }
    if (!contacts) {
      return [];
    }

    return contacts.map((contact) => ({
      ...contact,
      isQuoteDefaultEmailRecipient: quoteContactsMap.get(contact.id) ?? false,
    }));
  }, [contacts, quote.contacts]);

  const onReloadQuote = useCallback(() => {
    refetchQuoteById(quote.id);
  }, [quote.id, refetchQuoteById]);

  const onPDFGenerated = useCallback(() => {
    refetchPdfsByIds(pdfIds);
  }, [pdfIds, refetchPdfsByIds]);

  const versions = useMemo(() => {
    if (!quote) {
      return {
        current: undefined,
        archived: [],
      };
    }

    const contractPdfs = (contract?.contractPdfs || [])
      .sort(
        (contractPdfA, contractPdfB) =>
          new Date(contractPdfB.createdAt).getTime() - new Date(contractPdfA.createdAt).getTime(),
      )
      .map(({ pdf, isCurrent }) => ({
        quoteId: undefined,
        name: contract!.name,
        createdAt: pdf.createdAt,
        pdf,
        isArchived: !isCurrent,
        date: quote.addedAt ? new Date(quote.addedAt) : null,
        refCode: contract!.code,
        isContractVersion: true,
      }));

    const quotePdfBuilder = (quoteToBuild: QuoteWithoutRelationsDTO) => ({
      quoteId: quoteToBuild.id,
      name: quoteToBuild.name,
      createdAt: new Date(quoteToBuild.createdAt),
      pdf: pdfs?.find((pdf) => pdf.id === quoteToBuild.pdfId)!,
      // If there is an associated contract, all quoteToBuild pdf version are archived
      isArchived: contract ? true : quoteToBuild.status === QuoteStatus.Archived,
      date: quoteToBuild.addedAt ? new Date(quoteToBuild.addedAt) : null,
      refCode: quoteToBuild.refCode,
      isContractVersion: false,
    });

    const quotePdfs = [quotePdfBuilder(quote), ...(archivedQuotes?.versions.map(quotePdfBuilder) ?? [])];
    const [currentVersion, ...archivedVersions] = [...contractPdfs, ...quotePdfs];

    return {
      current: currentVersion,
      archived: archivedVersions,
    };
  }, [archivedQuotes?.versions, contract, pdfs, quote]);

  useEffect(() => {
    const events: EventSource[] = [];
    const allVersions = [...(versions?.archived || [])];
    if (versions?.current) {
      allVersions.push(versions?.current);
    }

    allVersions.forEach((version) => {
      const pdf = version?.pdf;
      if (pdf && isPdfBeingGenerated(pdf)) {
        events.push(subscribeToPDFUpdates(pdf, () => onReloadQuote()));
      }
    });

    return () => {
      events.forEach((event) => {
        event.close();
      });
    };
  }, [onReloadQuote, versions]);

  const emailTemplateRichTextConfiguration = useEmailTemplateRichTextConfiguration({
    entityType: EMAIL_ENTITY_TYPE.QUOTATION,
    entityId: quote.id,
    type: 'quotation',
    quote,
    project,
    client: client!,
  });

  const hasPdfUpdatedFromProject = !!versions.current?.isContractVersion;

  const redirectToQuoteDetail = useCallback(
    (quoteId: string, currentQuoteId: string) => () => {
      history.push(`/opportunities/quotation/${quoteId}/edit`, { currentQuoteVersionId: currentQuoteId });
    },
    [history],
  );

  const onQuoteDuplication = useCallback(
    async (values: IQuoteUpdateDTO) => {
      if (selectedQuoteIdRef.current) {
        const newQuote = await duplicateQuoteMutation.mutateAsync({
          quoteId: quote.id,
          name: values.name,
          hasReversalOfLiability: values.hasReversalOfLiability,
          addedAt: values.date,
          startedAt: values.estimatedStartDate,
          refCode: values.refCode,
          workDuration: values.workDuration,
          validityDuration: values.validityDuration,
          comment: values.comment,
          mentions: values.mentions,
          note: values.note,
          projectAddress: values.projectAddress
            ? {
                address: values.projectAddress.address ?? null,
                city: values.projectAddress.city ?? null,
                postalCode: values.projectAddress.postalCode ?? null,
                country: values.projectAddress.country ?? null,
              }
            : undefined,
        });
        toast.success(t('global:words.c.success'), t('quote:creationForm.duplication.toastSuccess'));

        history.push(`/opportunities/quotation/${newQuote.id}`);
      }
    },
    [duplicateQuoteMutation, history, quote.id, t, toast],
  );

  const onQuoteRestore = useCallback(async () => {
    if (!selectedQuoteIdRef.current || (quote.status !== QuoteStatus.Draft && quote.status !== QuoteStatus.Completed)) {
      return;
    }
    const data = await restoreQuoteMutation.mutateAsync(selectedQuoteIdRef.current);
    toast.success(t('global:words.c.success'), t('quote:restore.toastSuccess'));

    if (selectedQuoteIdRef.current !== data.id) {
      history.replace(`/opportunities/quotation/${data.id}`);
    }
  }, [history, quote.status, restoreQuoteMutation, t, toast]);

  const addQuoteActionsProps = useCallback(
    (pdfVersion: IQuoteArchiveDetailsExtended) => {
      if (!hasPermissions || !pdfVersion) {
        return null;
      }

      if (pdfVersion.isContractVersion) {
        return {
          // eslint-disable-next-line react/no-unstable-nested-components
          rightContent: () => <Box w={6} />,
          additionalContent: (
            <Badge showDot colorScheme="gray">
              {t('quote:versions.updateDuringProject')}
            </Badge>
          ),
        };
      }

      const onDuplicate = () => {
        selectedQuoteIdRef.current = String(pdfVersion.quoteId);
        duplicationModalControls.onOpen();
      };

      const onRestore = () => {
        selectedQuoteIdRef.current = String(pdfVersion.quoteId);

        if (quote.status === QuoteStatus.Draft) {
          onRestoreModalControls.onOpen();
          return;
        }
        (async () => onQuoteRestore())();
      };

      return {
        // eslint-disable-next-line react/no-unstable-nested-components
        rightContent: () => (
          <ActionMenu>
            <ActionMenu.Action
              icon={<SearchIcon />}
              label={t('quote:versions.viewQuotationDetail')}
              onClick={redirectToQuoteDetail(String(pdfVersion.quoteId!), quote.id)}
            />

            <ActionMenu.Action
              icon={<CogIcon />}
              label={t('quote:export.action')}
              onClick={redirectToQuoteDetail(String(pdfVersion.quoteId!), quote.id)}
            />
            <ActionMenu.Restore
              label={t('quote:restore.action')}
              tooltip={
                !(quote.status === QuoteStatus.Completed || quote.status === QuoteStatus.Draft)
                  ? t('quote:restore.tooltip')
                  : undefined
              }
              onClick={onRestore}
              disabled={!(quote.status === QuoteStatus.Completed || quote.status === QuoteStatus.Draft)}
            />
            <ActionMenu.Duplicate onClick={onDuplicate} />
          </ActionMenu>
        ),
      };
    },
    [
      duplicationModalControls,
      hasPermissions,
      quote.id,
      onQuoteRestore,
      onRestoreModalControls,
      quote.status,
      redirectToQuoteDetail,
      t,
    ],
  );

  return (
    <EmailProvider>
      <CreateQuoteModal
        title={t('quote:creationForm.duplication.title')}
        description={t('quote:creationForm.duplication.description')}
        action={t('quote:creationForm.duplication.submit')}
        onSubmit={onQuoteDuplication}
        {...duplicationModalControls}
      />
      <RestoreQuoteModal
        {...onRestoreModalControls}
        title={t('quote:restore.modal.title')}
        description={t('quote:restore.modal.description')}
        action={t('quote:restore.modal.action')}
        onSubmit={onQuoteRestore}
      />

      {hasPdfUpdatedFromProject && (
        <Box mb={6}>
          <QuoteProjectUpdateCallout />
        </Box>
      )}

      <TwoColumns>
        <QuoteStatusCard
          quote={quote}
          client={client}
          project={project}
          pdf={pdfs?.find((pdf) => pdf.id === quote.pdfId)}
          currentVersion={versions.current ?? undefined}
          reloadQuote={onReloadQuote}
          reloadPdf={onPDFGenerated}
          quoteContacts={quoteContacts}
        />
        <QuoteInformationCard quote={quote} />
      </TwoColumns>

      <TwoColumns mt={4}>
        <GridItem>
          <VStack gap={4}>
            <GridItem width="100%">
              <QuoteMarginCard quote={quote} />
            </GridItem>
            <GridItem width="100%">
              <QuoteTagsCard quote={quote} />
            </GridItem>
          </VStack>
        </GridItem>
        <GridItem>
          <QuoteCommentCard quote={quote} />
        </GridItem>
      </TwoColumns>

      {quote.historyFamilyId && (
        <HistoryByFamilyCard
          familyId={quote.historyFamilyId}
          entityType={HISTORY_ENTITY_TYPE.QUOTE}
          emailTemplateRichTextConfiguration={emailTemplateRichTextConfiguration}
          cardPosition={{ mt: 4 }}
          addActionsProps={(archiveDetails) => addQuoteActionsProps(archiveDetails as IQuoteArchiveDetailsExtended)}
        />
      )}
    </EmailProvider>
  );
};
