import type { PaginatedResponse, UsePaginationOptions } from '@graneet/lib-ui';
import { usePaginationQuery, useToast } from '@graneet/lib-ui';
import type { IWorker, IWorkerDTO } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import type { QueryClient } from '@tanstack/react-query';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { apiNew } from 'features/api/services/apiNew.service';

const WORKER_PATH = '/workers';

const WORKER_KEY = 'workers';

const workerFactoryKey = createQueryKeys(WORKER_KEY, {
  get: (queryParams: Partial<URLSearchParams> & { _full?: boolean; includesDeletedWorker?: boolean }) => ({
    queryKey: [WORKER_PATH, queryParams],
    queryFn: () => apiNew.get<Partial<URLSearchParams>, PaginatedResponse<IWorker>>(WORKER_PATH, queryParams),
  }),
  getRoles: () => ({
    queryKey: [WORKER_PATH],
    queryFn: () => apiNew.get<never, string[]>(`${WORKER_PATH}/roles`),
  }),
});

async function invalidAllWorkersQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [WORKER_KEY],
    exact: false,
  });
}

export function useWorkers(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) => queryClient.fetchQuery(workerFactoryKey.get(urlSearchParams)),
    options,
  );
}

export function useWorkersWithoutPagination() {
  return useSuspenseQuery(
    workerFactoryKey.get({
      _full: true,
    }),
  );
}

export function useWorkersRoles() {
  return useSuspenseQuery(workerFactoryKey.getRoles());
}

export function useWorkerCreate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'worker']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IWorkerDTO) => apiNew.post<IWorkerDTO, IWorker>(WORKER_PATH, dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('worker:creationModal.toastError'));
    },
    onSuccess: async (response) => {
      await invalidAllWorkersQueries(queryClient);
      toast.success(
        t('global:words.c.success'),
        t('worker:creationModal.toastSuccess', {
          firstName: response.firstName,
          lastName: response.lastName,
        }),
      );
    },
  });
}

export function useWorkerUpdate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'worker']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IWorkerDTO }) =>
      apiNew.patch<IWorkerDTO, IWorker>(`${WORKER_PATH}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('worker:editionModal.toastError'));
    },
    onSuccess: async (response) => {
      await invalidAllWorkersQueries(queryClient);
      toast.success(
        t('global:words.c.success'),
        t('worker:editionModal.toastSuccess', {
          firstName: response.firstName,
          lastName: response.lastName,
        }),
      );
    },
  });
}

export function useWorkerDelete() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'worker']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (worker: IWorker) => apiNew.delete<never, void>(`${WORKER_PATH}/${worker.id}`),
    onError: () => {
      toast.error(t('global:words.c.error'), t('worker:deletionModal.toastError'));
    },
    onSuccess: async (_, worker) => {
      invalidAllWorkersQueries(queryClient);
      toast.success(
        t('worker:deletionModal.toastSuccess', {
          firstName: worker.firstName,
          lastName: worker.lastName,
        }),
      );
    },
  });
}
