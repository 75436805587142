import { Box, HStack, VStack } from '@chakra-ui/react';
import { RICH_TEXT_BLOCK_TOOLBAR_PRESET, RichTextInput } from '@graneet/lib-ui';
import type { QuoteLotObject, QuoteNodeObject } from '@org/quotation-lib';
import type { GridApi } from '@ag-grid-community/core';
import { useCallback, type FC, type KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

export const LotEditor: FC<{
  quoteLot: QuoteLotObject;
  onChange: (v: string) => void;
  columnWidth: number;
  api: GridApi<QuoteNodeObject>;
}> = ({ quoteLot, columnWidth, api, onChange }) => {
  const { t } = useTranslation(['quote']);
  const handleOnChange = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || (e.key === 'Enter' && !e.shiftKey)) {
        e.stopPropagation();
        e.preventDefault();
        api.stopEditing();
        api.tabToNextCell();
        const nextCell = api.getFocusedCell();
        if (nextCell) {
          api.startEditingCell({
            colKey: nextCell.column,
            rowIndex: nextCell.rowIndex,
          });
        }
      }
    },
    [api],
  );

  return (
    <VStack height="100%" justifyContent="center" alignItems="flex-start" p={2} gap={2} width="100%">
      <HStack width="100%">
        <Box flex="1" minWidth={columnWidth}>
          <RichTextInput
            autoFocus
            initialValue={quoteLot.denomination ?? ''}
            onChange={handleOnChange}
            configuration={RICH_TEXT_BLOCK_TOOLBAR_PRESET}
            navbarType="inline"
            shouldDisplayError={false}
            disableTabIndentation
            placeholder={t('quote:quotation.global.denominationPlaceholder')}
            useShiftEnter
            inputProps={{
              minHeight: '40px',
              className: 'denomination-editor',
              onKeyDown: handleKeyDown,
            }}
          />
        </Box>
      </HStack>
    </VStack>
  );
};
