import { BILL_STATUSES } from '../bill/bill.type';
import { divideFloating, multiplyFloating, roundFloating, sumObjects } from '../common/math.util';
import type { IVatDistribution } from '../vat/vat-distribution.type';

import type { IPaymentWithRelations } from './payment.type';

type PaymentCannotBeUpdatedCause = 'accounting' | 'associated-bill-lost';

/**
 * Check if a payment can be updated.
 *
 * @param {IPaymentWithRelations} payment - The payment object to be checked.
 * @returns {{ok: boolean; causes: PaymentCannotBeUpdatedCause[]}} - An object containing if the payment can be updated and the reasons why.
 */
export const canPaymentBeUpdated = (
  payment: IPaymentWithRelations,
): { ok: boolean; causes: PaymentCannotBeUpdatedCause[] } => {
  const causes: PaymentCannotBeUpdatedCause[] = [];

  /*
    If it's associated to an accounting export
   */
  if (payment.accountingExport) {
    causes.push('accounting');
  }

  /*
    If it's associated to a lost bill
   */
  if (payment.bill.status === BILL_STATUSES.LOST) {
    causes.push('associated-bill-lost');
  }

  return {
    ok: causes.length === 0,
    causes,
  };
};

type PaymentCannotBeDeletedCause = 'accounting';

/**
 * Determines whether a payment can be deleted or not.
 *
 * @param {IPaymentWithRelations} payment - The payment to check.
 * @return {{ok: boolean, causes: PaymentCannotBeDeletedCause[]}} - Object containing information about whether the payment can be deleted and the cause(s) if not.
 */
export const canPaymentBeDeleted = (
  payment: IPaymentWithRelations,
): { ok: boolean; causes: PaymentCannotBeDeletedCause[] } => {
  const causes: PaymentCannotBeDeletedCause[] = [];

  /*
    If have an accounting export
   */
  if (payment.accountingExport) {
    causes.push('accounting');
  }

  return {
    ok: causes.length === 0,
    causes,
  };
};

export const calculatePaymentVatDistribution = (
  amountPaidIncVAT: number,
  otherPayments: {
    amountPaidIncVAT: number;
    vatDistribution: IVatDistribution | null;
  }[],
  totalAmountIncVAT: number,
  vatDistribution: IVatDistribution,
): IVatDistribution => {
  const cumulativeAmountPaid = amountPaidIncVAT + sumObjects(otherPayments, 'amountPaidIncVAT');
  const previousVatAmountsIndexed = otherPayments.reduce<Record<number, number>>((acc, payment) => {
    (payment.vatDistribution || []).forEach((vatObject) => {
      if (acc[vatObject.vatRate] === undefined) {
        acc[vatObject.vatRate] = 0;
      }

      acc[vatObject.vatRate] += vatObject.amount;
    });

    return acc;
  }, {});

  return vatDistribution.map((vatObject) => {
    const cumulativeAmount = roundFloating(
      divideFloating(multiplyFloating(cumulativeAmountPaid, vatObject.amount), totalAmountIncVAT),
    );
    const previousCumulativeAmount = previousVatAmountsIndexed[vatObject.vatRate] || 0;
    const nonCumulativeAmount = cumulativeAmount - previousCumulativeAmount;

    return {
      vatRate: vatObject.vatRate,
      amount: nonCumulativeAmount,
    };
  });
};
