import type { ReactNode } from 'react';
import { forwardRef, useCallback, useMemo } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Flex, useTheme, Stack, VStack, Center, Box } from '@chakra-ui/react';

export type SelectableCardProps = FlexProps & {
  children?: ReactNode;
  title?: ReactNode;
  isSelected?: boolean;
  onSelected?: () => void;
  topRightChildren?: ReactNode;
  isDisabled?: boolean;
  onClick?: () => void;
};

export const SelectableCardComponent = forwardRef<HTMLInputElement, SelectableCardProps>(
  (
    {
      children = null,
      title = null,
      isSelected = false,
      onSelected = null,
      topRightChildren,
      isDisabled = false,
      onClick = null,
      ...otherProps
    },
    ref,
  ) => {
    const {
      colors: { blue },
    } = useTheme();

    const hoverStyle = useMemo(
      () =>
        isDisabled
          ? {}
          : {
              border: `0.105rem solid ${blue[500]}`,
              p: '0.125rem', // This is to avoid move of card content when card have a 0.25rem border
              boxShadow: 'blue',
            },
      [isDisabled, blue],
    );

    const borderStyle = useMemo(
      () =>
        isSelected
          ? {
              border: `0.10rem solid ${blue[500]}`,
              boxShadow: 'blue',
            }
          : {},
      [blue, isSelected],
    );

    const handleClick = useCallback(() => {
      if (isDisabled) {
        return;
      }
      if (onSelected) {
        onSelected();
        return;
      }
      onClick?.();
    }, [isDisabled, onClick, onSelected]);

    return (
      <Flex
        backgroundColor="white"
        position="relative"
        boxShadow="0px 2px 5px -2px rgba(0, 0, 0, 0.06),0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
        borderRadius="8px"
        color="gray.600"
        border="0.15rem solid transparent"
        transitionDuration="0.2s"
        _hover={hoverStyle}
        onClick={handleClick}
        textColor="gray.800"
        ref={ref}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        {...borderStyle}
        {...otherProps}
      >
        <Stack direction="column" boxSize="100%" p={4}>
          {topRightChildren && (
            <Box position="absolute" top={2} right={2} px={1}>
              {topRightChildren}
            </Box>
          )}

          <Flex boxSize="100%">
            <VStack flex={1} spacing={3} alignItems="stretch">
              {title && (
                <Center
                  flexGrow={children ? 0 : 1}
                  fontFamily="heading"
                  fontSize="2xl"
                  fontWeight="500"
                  wordBreak="break-word"
                  textAlign="center"
                >
                  {title}
                </Center>
              )}

              {children && <Center flex={1}>{children}</Center>}
            </VStack>
          </Flex>
        </Stack>
      </Flex>
    );
  },
);
