import { useToast } from '@graneet/lib-ui';
import { useCallback, useMemo } from 'react';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const useQuoteError = () => {
  const toast = useToast();
  const { reloadQuote } = useQuote();
  const { t } = useTranslation(['global', 'quote']);

  const genericError = useMemo(
    () => ({
      title: t('global:words.c.error'),
      description: t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }),
    }),
    [t],
  );

  return useCallback(
    (err?: string): false => {
      if (err) {
        toast.error(err);
      } else {
        toast.error(genericError.title, genericError.description);
      }

      reloadQuote();
      return false;
    },
    [genericError, reloadQuote, toast],
  );
};
