import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { IClient, IClientResponseDTO } from '@graneet/business-logic';
import { Card } from '@graneet/lib-ui';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { useClientEditTags, useClientsTags } from 'features/client/services/client.api';

interface ClientTagsCardProps {
  client: IClientResponseDTO | IClient;
}

export const ClientTagsCard: FC<ClientTagsCardProps> = ({ client }) => {
  const { t } = useTranslation(['global']);

  const clientsTags = useClientsTags();
  const clientEditTagsMutation = useClientEditTags();

  const onTagsSaved = useCallback(
    (newTags: string[]) => clientEditTagsMutation.mutateAsync({ id: client.id, dto: { tags: newTags } }),
    [client.id, clientEditTagsMutation],
  );

  return (
    <Card title={t('global:words.privateTags')}>
      <TagsEditionBlock
        placeholder={t('global:changeTags.placeholder')}
        tags={client.tags}
        suggestedTags={clientsTags.data}
        onTagsEdited={onTagsSaved}
        isEditable
      />
    </Card>
  );
};
