import { useParams } from 'react-router-dom';
import { HiddenField, Rule, useFormContext, useHiddenField, useOnChangeValues } from 'graneet-form';
import { useEffect } from 'react';
import type { IOrderWithDirectPaymentAmounts } from '@graneet/business-logic';
import { checkDirectPaymentAssociation } from '@graneet/business-logic';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import type { ProgressStatementDirectPaymentForm } from '../../progress-statement/forms/progress-statement-direct-payment-form';
import { useDirectPaymentContext } from '../contexts/DirectPaymentContext';
import { OrderComponentTypeBadge } from '../../order/components/OrderComponentTypeBadge';

const VALIDATION_FN = (value: boolean) => value;

interface DirectPaymentItemProps {
  order: IOrderWithDirectPaymentAmounts;
}

export const DirectPaymentOrderButton = ({ order }: DirectPaymentItemProps) => {
  const { progressStatementId } = useParams<{ progressStatementId: string }>();

  const form = useFormContext<ProgressStatementDirectPaymentForm>();
  const { ordersSupplierInvoices } = useOnChangeValues(form, ['ordersSupplierInvoices']);

  const isDirectPaymentsValidField = useHiddenField(form, `isDirectPaymentsValidField_${order?.id}`);

  const { supplierInvoices } = useDirectPaymentContext();

  useEffect(() => {
    const isDirectPaymentsValid = () => {
      const associatedSupplierInvoice = ordersSupplierInvoices?.find(
        ({ orderId }) => orderId === order.id,
      )?.supplierInvoiceId;

      return associatedSupplierInvoice && order && order?.project?.id
        ? checkDirectPaymentAssociation(
            supplierInvoices[associatedSupplierInvoice],
            order,
            order?.project?.id,
            +progressStatementId,
          )
        : true;
    };

    form.setFormValues({
      [`isDirectPaymentsValidField_${order?.id}`]: isDirectPaymentsValid(),
    });
  }, [form, order, order.id, ordersSupplierInvoices, progressStatementId, supplierInvoices]);

  return (
    <>
      <HiddenField {...isDirectPaymentsValidField}>
        <Rule validationFn={VALIDATION_FN} message="" />
      </HiddenField>

      <Flex direction="row" justifyContent="space-between" w="100%" alignItems="start">
        <Box textAlign="left">
          <Heading size="xs">{order.supplier?.name}</Heading>
          <Text fontSize="xs" color="gray.800" mb={1}>
            {order.name}
            {!!order.orderNumber && ` - ${order.orderNumber}`}
          </Text>
        </Box>
        <OrderComponentTypeBadge order={order} />
      </Flex>
    </>
  );
};
