import type { ChangeEvent, DragEvent, FC } from 'react';
import { useCallback, useRef, useState } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';

import type { FileItem } from '../FileNameChip';
import { FileNameChip, byteValueNumberFormatter } from '../FileNameChip';
import { useChakraColors } from '../../hooks';

import { configureDefaultLabels, multipleFileUploadTranslations } from './configureDefaultLabels';

const getFileExtension = (fileName: string) => {
  const parts = fileName.split('.');
  return parts.length > 1 ? parts[parts.length - 1] : '';
};

const getTotalSize = (files: FileWithOptions[]) =>
  files.reduce((total, fileWithOptions) => {
    if (fileWithOptions.customFile) {
      const file = fileWithOptions.file as FileItem;
      return total + (file.size ? parseInt(file.size, 10) : 0);
    }
    return total + (fileWithOptions.file as File).size;
  }, 0);

export type FileWithOptions = {
  file: File | FileItem;
  readOnly: boolean;
  customFile?: boolean;
  grayedOut?: boolean;
};

export interface MultipleFileUploadProps {
  files: FileWithOptions[];
  onChange?: (files: FileWithOptions[]) => void;
  onFileOpen?: (id: string) => void;
  onDelete?: (id: string) => void;
  checkBeforeUpload?: (newFiles: File[]) => boolean;
  canUpload?: boolean;
}

const MultipleFileUploadComponent: FC<MultipleFileUploadProps> = ({
  files,
  onChange,
  onFileOpen,
  onDelete,
  checkBeforeUpload,
  canUpload = true,
}) => {
  const { blue, borderGray, textGray } = useChakraColors({
    blue: 'greenBrand.light',
    borderGray: 'gray.200',
    textGray: 'gray.600',
  });

  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAddFiles = useCallback(
    (newlyAddedFiles: File[] | FileList) => {
      if (!newlyAddedFiles) return;
      if (onChange) {
        const fileExists = (file: File) => files.some((f) => f.file.name === file.name && f.file.size === file.size);

        const newFilesWithOptions = Array.from(newlyAddedFiles)
          .filter((file) => !fileExists(file))
          .map((file) => ({ file, readOnly: false, customFile: false }));

        if (newFilesWithOptions.length > 0) {
          const allFiles = newFilesWithOptions.map((fwo) => fwo.file);
          if (!checkBeforeUpload || checkBeforeUpload(allFiles as File[])) {
            onChange([...files, ...newFilesWithOptions]);
          }
        }
      }
    },
    [files, onChange, checkBeforeUpload],
  );

  const handleDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragOver(false);
      if (event.dataTransfer.files) {
        handleAddFiles(event.dataTransfer.files);
      }
    },
    [handleAddFiles],
  );

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      if (input.files) {
        handleAddFiles(input.files);
        input.value = '';
      }
    },
    [handleAddFiles],
  );

  const openFileDialog = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  }, []);

  return (
    <>
      <HStack mb={2}>
        <Text fontSize={14} fontWeight="semibold">
          {files.length}{' '}
          {files.length > 1 ? multipleFileUploadTranslations.attachments : multipleFileUploadTranslations.attachment}
        </Text>
        <Text fontSize="sm">
          {byteValueNumberFormatter(multipleFileUploadTranslations.lang).format(getTotalSize(files))}
        </Text>
      </HStack>
      <Flex wrap="wrap">
        {files.map((f, index) => (
          <Box mr={2} mb={2} display="inline-flex" key={f.file.name}>
            <FileNameChip
              file={
                f.customFile
                  ? {
                      id: (f.file as FileItem).id,
                      name: (f.file as FileItem).name,
                      url: (f.file as FileItem).url,
                      size: (f.file as FileItem).size,
                      extension: (f.file as FileItem).extension,
                    }
                  : {
                      id: index.toString(),
                      name: (f.file as File).name,
                      url: URL.createObjectURL(f.file as File),
                      size: (f.file as File).size.toString(),
                      extension: getFileExtension((f.file as File).name),
                    }
              }
              width="13rem"
              readOnly={f.readOnly}
              bgColor={f.grayedOut ? 'gray.100' : 'white'}
              showDetails
              showCloseButtonOnHover
              onOpen={onFileOpen}
              onDelete={onDelete}
            />
          </Box>
        ))}
        {canUpload && (
          <Flex
            py={2}
            px={3}
            mb={2}
            maxW="15rem"
            cursor="pointer"
            display="inline-flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={4}
            color={textGray}
            border={`1px solid ${borderGray}`}
            _hover={{
              color: blue,
            }}
            mr={4}
            width={100}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={openFileDialog}
            bg={isDragOver ? 'gray.200' : 'white'}
          >
            {isDragOver ? multipleFileUploadTranslations.drop : '+'}
            <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} multiple />
          </Flex>
        )}
      </Flex>
    </>
  );
};

export const MultipleFileUpload = Object.assign(MultipleFileUploadComponent, {
  configureDefaultLabels,
});
