import type { FC } from 'react';
import { Box, Portal } from '@chakra-ui/react';

import { Tooltip } from '../../../../Tooltip';
import { toolbarTranslations } from '../../../configureDefaultLabels';
import type { RichTextVariableConfiguration } from '../../../constants';

interface VariableErrorTooltipProps {
  target: EventTarget | null;

  configuration: RichTextVariableConfiguration;
}

export const VariableErrorTooltip: FC<VariableErrorTooltipProps> = ({ target, configuration }) => {
  if (!target || !('getBoundingClientRect' in target) || typeof target.getBoundingClientRect !== 'function') {
    return null;
  }

  if (
    'getAttribute' in target &&
    typeof target.getAttribute === 'function' &&
    target?.getAttribute('data-lexical-variable') !== 'true'
  ) {
    return null;
  }

  // Only display when data are injected
  if (!configuration.injectValueInLabel) {
    return null;
  }

  const boundingClientRect = target.getBoundingClientRect();
  const top = `${boundingClientRect.top}px`;

  /*
    Compute the target center position on the x-axis
   */
  const left = `${boundingClientRect.left + boundingClientRect.width / 2}px`;

  return (
    <Portal>
      <Tooltip label={toolbarTranslations.variableNotFound} placement="top" isOpen>
        {/* width and height are equals to 0 to avoid interactivity problems */}
        <Box width={0} height={0} position="absolute" top={top} left={left} />
      </Tooltip>
    </Portal>
  );
};
