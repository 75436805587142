import { Flex, Text } from '@chakra-ui/react';
import { MailIcon } from '@graneet/lib-ui';
import React from 'react';
import type { EmailDetails } from '@graneet/business-logic';

export interface ButtonAccordionEmailProps {
  item: EmailDetails;
}

export const ButtonAccordionEmail = ({ item }: ButtonAccordionEmailProps) => (
  <Flex w="100%" textAlign="start" alignItems="center" gap={3}>
    <MailIcon boxSize={5} />
    <Text fontWeight="semibold" color="gray.800" fontSize="sm" lineHeight={5}>
      {item.emailSubject}
    </Text>
  </Flex>
);
