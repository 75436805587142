import { useCallback, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { IProgressStatement } from '@graneet/business-logic';
import { getNextProgressStatement } from '@graneet/business-logic';

import { useContactAssociatedToProject } from 'features/contact/services/contact.api';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { ProgressStatementDetail } from 'features/progress-statement/components/ProgressStatementDetail';
import {
  useProgressStatement,
  useProgressStatementsForSubProjects,
} from 'features/progress-statement/services/progress-statement.api';
import { useProject } from 'features/project/services/project.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

const formatUrl = (ps: IProgressStatement) => `/sales/statements/progress-statements/${ps.id}`;

export const ViewProgressStatementDetailScreen = () => {
  const { progressStatementId } = useParams<{ progressStatementId: string }>();
  const { t } = useTranslation(['statement', 'global']);

  const { createRedirectionWithSavedFilters } = useFiltersQuery();
  const { updateHeaderTitle } = useHeaderContext();

  // Load data
  const progressStatement = useProgressStatement(+progressStatementId);
  const project = useProject(progressStatement.data.project.id);
  const projectContacts = useContactAssociatedToProject(progressStatement.data.project?.id);
  const progressStatements = useProgressStatementsForSubProjects(progressStatement.data?.subProject?.id);

  const goBackToListing = createRedirectionWithSavedFilters('/sales/statements', { replace: true });

  const goToCancelStatement = useCallback(
    (createDraft: boolean) =>
      createRedirectionWithSavedFilters(`/sales/statements/progress-statements/${progressStatementId}/cancel`, {
        cancelledInvoiceNumber: progressStatement.data.invoiceNumber,
        createDraft,
      })(),
    [createRedirectionWithSavedFilters, progressStatement.data.invoiceNumber, progressStatementId],
  );
  useLayoutEffect(() => {
    updateHeaderTitle(progressStatement.data.name, [
      {
        name: t('statement:title'),
        link: '/sales/statements',
      },
    ]);
  }, [t, progressStatement.data.name, updateHeaderTitle]);

  const nextProgressStatement = getNextProgressStatement(progressStatement.data, progressStatements.data.data);

  return (
    <ProgressStatementDetail
      progressStatement={progressStatement.data}
      progressStatements={progressStatements.data.data}
      project={project.data}
      projectContacts={projectContacts.data}
      nextProgressStatement={nextProgressStatement}
      formatUrl={formatUrl}
      onGoBack={goBackToListing}
      onDeleted={goBackToListing}
      onCancelConfirmed={goToCancelStatement}
      displayAssociatedProject
      displayPaymentTerms
      displayPrimaryClient
    />
  );
};
