import type {
  IValidationStep,
  IValidationStepWithRelations,
  IValidationStepSupplierInvoiceCreateDto,
  IValidationStepSupplierInvoiceUpdateDto,
  IFormattedValidationStepHistory,
  ISupplierInvoice,
  IValidationStepUpdateResponseDto,
  IValidationStepDeleteResponseDto,
  ISupplierInvoiceUpdateStatusesDTO,
} from '@graneet/business-logic';
import { useToast } from '@graneet/lib-ui';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { invalidateSupplierInvoiceQueries } from '../../supplier-invoice/services/supplier-invoice.api';

import { apiNew } from 'features/api/services/apiNew.service';

const VALIDATION_STEPS_SUPPLIER_INVOICE_PATH = '/validation-steps/supplier-invoice';
const VALIDATION_STEPS_SUPPLIER_INVOICE_HISTORY_PATH = '/supplier-invoices/validation-step';

export const validationStepsKeys = createQueryKeys('validationStepSupplierInvoice', {
  validationStepSupplierInvoiceHistory: (id: number | undefined) => ({
    queryKey: [VALIDATION_STEPS_SUPPLIER_INVOICE_HISTORY_PATH, id],
    queryFn: () => {
      if (id) {
        return apiNew.get<unknown, IFormattedValidationStepHistory[]>(
          `${VALIDATION_STEPS_SUPPLIER_INVOICE_HISTORY_PATH}/${id}`,
        );
      }
      return Promise.resolve(null);
    },
  }),
  validationStepSupplierInvoice: {
    queryFn: () =>
      apiNew.get<unknown, IValidationStepWithRelations<'supplierInvoice'>[]>(VALIDATION_STEPS_SUPPLIER_INVOICE_PATH),
    queryKey: [VALIDATION_STEPS_SUPPLIER_INVOICE_PATH],
  },
});

function useGetValidationStepSupplierInvoiceHistory(id: number | undefined) {
  return useSuspenseQuery({
    ...validationStepsKeys.validationStepSupplierInvoiceHistory(id),
  });
}

function useUpdateValidationStepSupplierInvoiceHistory() {
  const { t } = useTranslation(['global', 'validationStep']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) =>
      apiNew.patch<number, ISupplierInvoice>(`${VALIDATION_STEPS_SUPPLIER_INVOICE_HISTORY_PATH}/${id}`),
    onError: () => toast.error(t('global:errors.error')),
    onSuccess: (data: ISupplierInvoice) => {
      queryClient.invalidateQueries(validationStepsKeys.validationStepSupplierInvoiceHistory(data.id));
      invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('validationStep:toasts.update'));
    },
  });
}

function useUpdateValidationStepSupplierInvoiceBatchHistory() {
  const { t } = useTranslation(['global', 'validationStep']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ISupplierInvoiceUpdateStatusesDTO) =>
      apiNew.patch<ISupplierInvoiceUpdateStatusesDTO, number[]>('/supplier-invoices/validation-steps/validate', dto),
    onError: () => toast.error(t('global:errors.error')),
    onSuccess: (ids: number[]) => {
      ids.forEach((id) => {
        queryClient.invalidateQueries(validationStepsKeys.validationStepSupplierInvoiceHistory(id));
      });
      invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('validationStep:toasts.update'));
    },
  });
}

function useGetValidationStepsSupplierInvoice() {
  return useSuspenseQuery(validationStepsKeys.validationStepSupplierInvoice);
}

function useUpdateValidationStepSupplierInvoice() {
  const { t } = useTranslation(['global', 'validationStep']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, dto }: { id: string; dto: IValidationStepSupplierInvoiceUpdateDto }) =>
      apiNew.patch<IValidationStepSupplierInvoiceUpdateDto, IValidationStepUpdateResponseDto>(
        `${VALIDATION_STEPS_SUPPLIER_INVOICE_PATH}/${id}`,
        dto,
      ),
    onError: () => toast.error(t('global:errors.error')),
    onSuccess: () => {
      queryClient.invalidateQueries(validationStepsKeys.validationStepSupplierInvoice);
      invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('validationStep:toasts.update'));
    },
  });
}

function useCreateValidationStepSupplierInvoice() {
  const { t } = useTranslation(['global', 'validationStep']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IValidationStepSupplierInvoiceCreateDto) =>
      apiNew.post<IValidationStepSupplierInvoiceCreateDto, IValidationStep<'supplierInvoice'>>(
        `${VALIDATION_STEPS_SUPPLIER_INVOICE_PATH}`,
        dto,
      ),
    onError: () => toast.error(t('global:errors.error')),
    onSuccess: () => {
      queryClient.invalidateQueries(validationStepsKeys.validationStepSupplierInvoice);
      invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('validationStep:toasts.create'));
    },
  });
}

function useDeleteValidationStepSupplierInvoice() {
  const { t } = useTranslation(['global', 'validationStep']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (stepId: string) =>
      apiNew.delete<never, IValidationStepDeleteResponseDto>(`${VALIDATION_STEPS_SUPPLIER_INVOICE_PATH}/${stepId}`),
    onError: () => toast.error(t('global:errors.error')),
    onSuccess: () => {
      invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('validationStep:toasts.delete'));
    },
  });
}

export {
  useGetValidationStepsSupplierInvoice,
  useUpdateValidationStepSupplierInvoice,
  useCreateValidationStepSupplierInvoice,
  useDeleteValidationStepSupplierInvoice,
  useGetValidationStepSupplierInvoiceHistory,
  useUpdateValidationStepSupplierInvoiceHistory,
  useUpdateValidationStepSupplierInvoiceBatchHistory,
};
