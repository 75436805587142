import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import type { ISubProject } from '@graneet/business-logic';
import {
  Button,
  Card,
  LabeledData,
  Modal,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
  RichText,
  RichTextField,
  SimpleEditIcon,
  TextField,
  TwoColumns,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { GridItem, Stack, useDisclosure } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';

import { useUpdateSubProject } from '../../services/sub-project.api';

import { Rule } from 'features/form/rules/Rule';

interface FormValues {
  name: string;

  note: string | null;
}
interface SubProjectEditInformationModalProps {
  subproject: ISubProject;
  isOpen: boolean;
  onClose(): void;
}
const SubProjectEditInformationModal: FC<SubProjectEditInformationModalProps> = ({ subproject, isOpen, onClose }) => {
  const { t } = useTranslation(['global', 'subProject']);

  const form = useForm<FormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  const updateSubProjectMutation = useUpdateSubProject();

  const onSubmit = useCallback(() => {
    const formValues = form.getFormValues();

    updateSubProjectMutation.mutate(
      {
        id: subproject.id,
        dto: {
          name: formValues.name!,
          note: formValues.note || null,
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  }, [form, onClose, subproject.id, updateSubProjectMutation]);

  useEffect(() => {
    if (isOpen) {
      form.setFormValues({
        name: subproject.name,
        note: subproject.note,
      });
    }
  }, [form, isOpen, subproject.name, subproject.note]);

  return (
    <Form form={form}>
      <Modal isOpen={isOpen} onClose={onClose} title={t('subProject:informationCard.title')} size="2xl">
        <Stack spacing={4}>
          <TextField<FormValues> name="name" isRequired label={t('subProject:fields.name')}>
            <Rule.IsRequired />
          </TextField>

          <RichTextField<FormValues>
            name="note"
            navbarType="inline"
            label={t('subProject:fields.note')}
            configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
          />
        </Stack>

        <Modal.Close isDisabled={updateSubProjectMutation.isPending} />
        <Modal.PrimaryButton
          onClick={onSubmit}
          isDisabled={!isFormValid}
          isLoading={updateSubProjectMutation.isPending}
        >
          {t('global:words.c.validate')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};

interface SubProjectEditInformationProps {
  subProject: ISubProject;
}

export const SubProjectEditInformation: FC<SubProjectEditInformationProps> = ({ subProject }) => {
  const { t } = useTranslation(['subProject', 'global']);

  const modal = useDisclosure();

  return (
    <Card
      title={t('subProject:informationCard.title')}
      topRightContent={
        <Button variant="outline" onClick={modal.onOpen} leftIcon={<SimpleEditIcon />}>
          {t('global:words.c.update')}
        </Button>
      }
    >
      <TwoColumns>
        <GridItem colSpan={2}>
          <LabeledData label={t('subProject:fields.name')} data={subProject.name} />
        </GridItem>
        <LabeledData
          label={t('subProject:fields.note')}
          data={
            <RichText
              // Force recreation of a new component of a rich text when note is changed
              key={subProject.note}
              value={subProject.note}
            />
          }
        />
      </TwoColumns>

      <SubProjectEditInformationModal onClose={modal.onClose} isOpen={modal.isOpen} subproject={subProject} />
    </Card>
  );
};
