import type { FC } from 'react';
import { useState } from 'react';
import { Modal, ValuesProvider, useValuesContext, useToast, usePaginatedData, STORAGE_STRATEGY } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { ContactAvailableDataProviderForm } from '../forms/ContactAvailableDataProviderForm';

import { getContactAvailableForClient, useContactsAssociateToClient } from 'features/contact/services/contact.api';

interface ContactAvailableClientModalProps {
  isOpen: boolean;

  onClose(): void;

  onSuccess(): void;

  title: string;

  clientId: number;
}

export const RawContactAvailableClientModal: FC<ContactAvailableClientModalProps> = ({
  isOpen,
  onClose,
  title,
  onSuccess,
  clientId,
}) => {
  const { t } = useTranslation(['contacts', 'global']);
  const toast = useToast();

  const { resetValues, getValues, numberOfValues } = useValuesContext<string>();
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const contactsAssociateToClientMutation = useContactsAssociateToClient();

  const resetAll = () => {
    setIsAllChecked(false);
    resetValues();
  };

  const handleOnClose = () => {
    resetAll();
    onClose();
  };

  const handleSubmit = async () => {
    const contactsToAssociate = getValues();
    await contactsAssociateToClientMutation.mutateAsync({
      id: clientId,
      dto: {
        contacts: contactsToAssociate.map((contactId) => ({
          id: contactId,
          isDefaultEmailRecipient: false,
        })),
      },
    });
    if (contactsToAssociate.length > 1) {
      toast.success(
        t('contacts:availableModal.toastSuccessManyAssociation', { nbContact: contactsToAssociate.length }),
      );
    } else {
      toast.success(t('contacts:availableModal.toastSuccessOneAssociation'));
    }

    onSuccess();
    onClose();
    resetAll();
  };

  const data = usePaginatedData(getContactAvailableForClient, undefined, undefined, STORAGE_STRATEGY.STATE);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} title={title} size="3xl" scrollBehavior="inside" maxHeight="33rem">
      <ContactAvailableDataProviderForm
        data={data}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        isContactDefaultEmailRecipient={(contact) => contact.isDefaultEmailRecipient}
      />
      <Modal.Close />
      <Modal.PrimaryButton isDisabled={numberOfValues() === 0} onClick={handleSubmit}>
        {numberOfValues() <= 1 && t('contacts:availableModal.buttons.associate')}
        {numberOfValues() > 1 && t('contacts:availableModal.buttons.associateMulti')}
      </Modal.PrimaryButton>
    </Modal>
  );
};

export const ContactAvailableClientModal: FC<ContactAvailableClientModalProps> = (props) => (
  <ValuesProvider>
    <RawContactAvailableClientModal {...props} />
  </ValuesProvider>
);
