import { VStack, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AddressCard, AssociateProjectModal, SellSheetHeader } from '@graneet/lib-ui';
import { useMemo } from 'react';
import type { QuoteComposeDTOProject } from '@org/graneet-bff-client';

import { useStore } from 'store/store';
import { useQuoteAddressUpdate } from 'features/quotation/quote-address/hooks/useQuoteAddressUpdate';
import { useQuoteUpdateProject } from 'features/quotation/quote/hooks/useQuoteUpdateProject';
import { useQuotationApi } from 'features/quotation/services/quote.api';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const QuoteProject = () => {
  const { t } = useTranslation(['quote', 'global', 'project']);
  const quoteUpdateProject = useQuoteUpdateProject();
  const { quote } = useQuote();
  const quoteProject = useStore((state) => state.quoteProject);
  const quoteClient = useStore((state) => state.quoteClient);
  const quoteAddressUpdate = useQuoteAddressUpdate();
  const isEditable = useStore((store) => store.quoteEditable);
  const { lazySearchProjects } = useQuotationApi();

  const associateProjectModal = useDisclosure();

  const hasProject = useMemo(() => quote.getProjectId() !== null, [quote]);

  return (
    <>
      <VStack spacing={3} align="stretch">
        <SellSheetHeader
          {...(hasProject && { title: quoteProject?.name })}
          subtitle={hasProject ? quoteProject?.refCode : t('quote:drawer.project.noProject')}
          isActive={hasProject}
          type="project"
          actions={
            isEditable
              ? [
                  {
                    label: hasProject ? t('global:words.c.dissociate') : t('quote:drawer.project.associateProject'),
                    onClick: () => (hasProject ? quoteUpdateProject(null) : associateProjectModal.onOpen()),
                  },
                ]
              : undefined
          }
        />

        <AddressCard
          title={t('quote:informationStep.address.title')}
          data={quote.getAddress()?.export()}
          isEditable={isEditable && !hasProject}
          onAddressChange={quoteAddressUpdate}
          possibleAddress={quoteClient?.address}
          sameAddressLabel={t('quote:informationStep.address.sameAsClient')}
        />
      </VStack>
      {isEditable && (
        <AssociateProjectModal
          key={`${associateProjectModal.isOpen}`}
          isOpen={associateProjectModal.isOpen}
          title={t('quote:drawer.project.associateProject')}
          placeholder={t('project:search')}
          cta={{
            label: t('quote:drawer.project.associateToQuote'),
            onClick: (project: QuoteComposeDTOProject) => quoteUpdateProject(project),
          }}
          onSearch={async (search: string) => {
            const results = await lazySearchProjects(search);
            return results.data;
          }}
          onClose={associateProjectModal.onClose}
        />
      )}
    </>
  );
};
