import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { Box } from '@chakra-ui/react';

import { Modal } from '../Modal';

export interface ConfirmDeletionModalProps {
  title: string;
  description?: string;
  action?: string;
  isOpen: boolean;
  tooltip?: string;
  isActionDisabled?: boolean;
  additionalContent?: ReactNode;
  isCentered?: boolean;
  onClose: () => void;
  onDeleted: () => void | Promise<void>;
}

export const ConfirmDeletionModal: FC<ConfirmDeletionModalProps> = ({
  title,
  description,
  action,
  isOpen,
  isActionDisabled,
  tooltip,
  onClose,
  onDeleted,
  isCentered = true,
  additionalContent,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmation = useCallback(async () => {
    setIsLoading(true);
    await onDeleted();
    setIsLoading(false);
  }, [onDeleted]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="2xl" isCentered={isCentered}>
      {description && <Box mb={5}>{description}</Box>}

      {additionalContent}

      <Modal.CloseButton />

      {action && (
        <Modal.PrimaryButton
          isLoading={isLoading}
          onClick={handleConfirmation}
          colorScheme="red"
          tooltip={tooltip}
          isDisabled={isActionDisabled}
        >
          {action}
        </Modal.PrimaryButton>
      )}
    </Modal>
  );
};
