import type { FC, MouseEventHandler, ReactElement, ReactNode } from 'react';
import { cloneElement } from 'react';
import type { FlexProps, BoxProps, IconProps } from '@chakra-ui/react';
import { Flex, Text, Box, Center } from '@chakra-ui/react';

import type { ButtonProps } from '../Button/Button';
import { Button } from '../Button/Button';

export interface OnboardingProps extends FlexProps {
  message?: ReactNode;

  action?: ReactNode | MouseEventHandler<HTMLButtonElement>;

  actionMessage?: string;

  actionProps?: ButtonProps;

  icon?: ReactElement<IconProps>;

  actionBoxProps?: BoxProps;
}

export const Onboarding: FC<OnboardingProps> = ({
  message = null,
  action = null,
  actionMessage,
  actionProps,
  icon,
  children,
  width = '100%',
  actionBoxProps,
  ...props
}) => {
  const actionElement =
    typeof action === 'function' ? (
      <Button onClick={action} colorScheme="blue" {...actionProps}>
        {actionMessage}
      </Button>
    ) : (
      <Center>{action}</Center>
    );

  return (
    <Flex data-testid="onboarding" justify="center" align="center" flexGrow={1} boxSize="100%" {...props}>
      <Flex direction="column" align="center">
        {icon && (
          <Box
            borderRadius="100%"
            background="white"
            p={4}
            mb={6}
            display="flex"
            alignItems="center"
            justifyContent="center"
            boxShadow="sm"
            border="1px solid"
            borderColor="gray.150"
          >
            {cloneElement(icon, { boxSize: 30, ...icon.props })}
          </Box>
        )}
        {!children && (
          <Text data-testid="label:onboarding.title" width={width} fontSize="md" mb="3" textAlign="center">
            {message}
          </Text>
        )}

        {children && (
          <Box data-testid="label:onboarding.title" width={width} fontSize="md" mb="3" textAlign="center">
            {children}
          </Box>
        )}

        <Box width={width} mt={6} align="center" {...actionBoxProps}>
          {actionElement}
        </Box>
      </Flex>
    </Flex>
  );
};
