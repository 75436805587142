import { HStack } from '@chakra-ui/react';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { FocusEvent } from 'react';
import { memo, useState, useCallback, useEffect, useRef } from 'react';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import type { MaskedInputHandle } from '@graneet/lib-ui';
import { MaskEnum, QuotationMaskedInput, useCurrency, useInputMaskInfos } from '@graneet/lib-ui';

import { useKeyboard } from 'features/quotation/ag-grid-quote/hooks/keyboard/useKeyboard';

export const AmountEditorCell = memo((params: CustomCellEditorProps<QuoteNodeObject>) => {
  const { value, data, column, node, onValueChange, stopEditing } = params;
  const { mapAmountToStringNumber, mapStringNumberToAmount } = useCurrency();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const { placeholder, mask } = useInputMaskInfos(MaskEnum.CURRENCY, {});

  const ref = useRef<MaskedInputHandle>(null);

  const { handleKeyDown } = useKeyboard();
  const [inputValue, setInputValue] = useState(mapStringNumberToAmount(value));

  const handleOnChange = useCallback(
    (v: string) => {
      setInputValue(v);
      onValueChange(mapAmountToStringNumber(v));
    },
    [mapAmountToStringNumber, onValueChange],
  );

  const handleSave = useCallback(
    (v: string) => {
      onValueChange(mapAmountToStringNumber(v));
      stopEditing();
    },
    [mapAmountToStringNumber, onValueChange, stopEditing],
  );

  const handleOnBlur = useCallback(
    (e: string | FocusEvent<HTMLInputElement>) => {
      const newValue = typeof e === 'string' ? e : e.target.value;
      handleSave(newValue);
    },
    [handleSave],
  );

  const handleOnFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  }, []);

  const handleSubmit = useCallback(() => {
    const v = ref.current?.getUnmaskedValue();
    handleSave(v);
  }, [handleSave]);

  useEffect(() => {
    inputRef?.focus();
  }, [inputRef]);

  if (data?.content.type === 'QuoteLot' && column?.getColId() === 'vatRate') {
    return null;
  }

  if (node.footer) {
    return null;
  }

  return (
    <HStack width="100%" justifyContent="center" background="transparent" px={2} py="8px">
      <QuotationMaskedInput
        textAlign="right"
        value={inputValue}
        inputRef={setInputRef}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onKeyDownCapture={handleKeyDown(handleSubmit)}
        mask={mask}
        placeholder={placeholder}
        ref={ref}
        noStyle
      />
    </HStack>
  );
});
