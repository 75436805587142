import type { FieldValues, AnyRecord } from 'graneet-form';
import { Field, VALIDATION_OUTCOME, composeEventHandlers } from 'graneet-form';
import type { ReactNode } from 'react';
import { useCallback } from 'react';

import type { KeysMatching } from '../../../utils/type.util';
import type { InputProps } from '../../Input';

import type { FormulaFieldValue } from './components/FormulaFieldInternal';
import { FormulaFieldInternal } from './components/FormulaFieldInternal';

export interface FormulaFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, FormulaFieldValue> = KeysMatching<T, FormulaFieldValue>,
> extends Omit<InputProps, 'onChange'> {
  children?: ReactNode;
  data?: AnyRecord;
  hideErrorMessage?: boolean;
  inputProps?: InputProps;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  name: K;
  onBlur?(): void;
  onChange?(e: T[K] | undefined): void;
  onFocus?(): void;
  sendClickRoundEvent(type: string): void;
}

export const FormulaField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, FormulaFieldValue> = KeysMatching<T, FormulaFieldValue>,
>({
  name,
  data,
  children = null,
  onBlur,
  onChange,
  onFocus,
  hideErrorMessage = false,
  sendClickRoundEvent,
  ...rest
}: FormulaFieldProps<T, K>) => {
  const render = useCallback(
    (
      {
        value,
        onChange: onChangeForm,
        onFocus: onFocusForm,
        onBlur: onBlurForm,
      }: {
        value: T[K] | undefined;
        onFocus(): void;
        onBlur(): void;
        onChange(e: T[K] | undefined): void;
      },
      {
        validationStatus,
      }: {
        validationStatus: {
          status: VALIDATION_OUTCOME;
          message: string | undefined;
        };
      },
    ) => {
      const shouldDisplayError = validationStatus.status === VALIDATION_OUTCOME.INVALID;

      return (
        <FormulaFieldInternal
          value={value}
          onChange={composeEventHandlers(onChange, onChangeForm as any)}
          onBlur={composeEventHandlers(onBlur, onBlurForm as any)}
          onFocus={composeEventHandlers(onFocus, onFocusForm as any)}
          errorMessage={validationStatus.message}
          shouldDisplayError={shouldDisplayError && !hideErrorMessage}
          sendClickRoundEvent={sendClickRoundEvent}
          {...rest}
        />
      );
    },
    [hideErrorMessage, onBlur, onChange, onFocus, rest, sendClickRoundEvent],
  );

  return (
    <Field<T, K> name={name} render={render} data={data}>
      {children}
    </Field>
  );
};
