import type { FC } from 'react';
import type { GridProps } from '@chakra-ui/react';
import { Grid, GridItem } from '@chakra-ui/react';

interface FluidGridComponentProps extends Omit<GridProps, 'gridTemplateColumns'> {
  minSizeItem: string;
}

export const FluidGridComponent: FC<FluidGridComponentProps> = ({ minSizeItem, ...props }) => (
  <Grid gridTemplateColumns={`repeat(auto-fill, minmax(min(${minSizeItem}, 100%), 1fr))`} {...props} />
);

export const FluidGrid = Object.assign(FluidGridComponent, {
  Item: GridItem,
});
