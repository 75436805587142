import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import type {
  IContractInfosItem,
  IContractInfosResponseDTO,
  IContractInfosLot,
  Raw,
  IDiscount,
  IItemWithUUID,
  ILotWithUUID,
  ICustomDiscount,
} from '@graneet/business-logic';
import {
  multiplyFloating,
  areSubProjectAmountsValidAfterContractEdition,
  computeTotalExVAT,
  CUSTOM_DISCOUNT_TYPES,
} from '@graneet/business-logic';
import type { LeafWithRelations, TreeContextApi } from '@graneet/lib-ui';
import { useCurrency } from '@graneet/lib-ui';
import { cloneDeep } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'graneet-form';

import { computeContractDiscount, computeContractFromTree } from '../services/contract.util';
import type {
  ContractItemComputedValue,
  ContractLotComputedValue,
  IContractInfosItemWithUUID,
  IContractInfosLotWithUUID,
} from '../types/contract.type';
import type { ContractEditionForm } from '../forms/contract-edition.form';

export type UpdateStatus = { canUpdate: boolean; errorMessage: string | undefined };
export const UPDATE_STATUS_SUCCESS: UpdateStatus = { canUpdate: true, errorMessage: undefined };

export interface IContractRuleContext {
  contractInfos: IContractInfosResponseDTO | null;

  /**
   * Check if discount have been invoiced or not
   */
  canDiscountBeUpdatedOrDeleted(): boolean;

  /**
   * Check if custom discount have been invoiced or not
   */
  canCustomDiscountBeUpdatedOrDeleted(customDiscountId: string): boolean;

  canGlobalVATRateBeUpdated(): boolean;

  canItemBeUpdated(
    previousItemState: IContractInfosItem,
    item: Pick<IItemWithUUID, 'id' | 'quantity' | 'vatRate' | 'unitPrice'>,
  ): UpdateStatus;

  canItemBeDuplicated(duplicatedItemId: IItemWithUUID['id']): UpdateStatus;

  canLotBeDuplicated(duplicatedLotId: ILotWithUUID['id']): UpdateStatus;

  canItemBeDeleted(itemId: IItemWithUUID['id']): UpdateStatus;

  canLotBeDeleted(lotId: ILotWithUUID['id']): UpdateStatus;

  canDiscountBeUpdated(discount: Raw<IDiscount> | undefined): UpdateStatus;

  canDiscountBeDeleted(): UpdateStatus;

  canCustomDiscountBeUpdated: (
    customDiscounts: Raw<ICustomDiscount>[],
    currentCustomDiscountName: string,
    isDeletion?: boolean,
  ) => UpdateStatus;
}

const THROW_ERROR = () => {
  throw new Error('No ContractRuleContext found');
};

export const ContractRuleContext = createContext<IContractRuleContext>({
  contractInfos: null,
  canDiscountBeUpdatedOrDeleted: THROW_ERROR,
  canCustomDiscountBeUpdatedOrDeleted: THROW_ERROR,
  canGlobalVATRateBeUpdated: THROW_ERROR,
  canItemBeUpdated: THROW_ERROR,
  canItemBeDuplicated: THROW_ERROR,
  canLotBeDuplicated: THROW_ERROR,
  canItemBeDeleted: THROW_ERROR,
  canLotBeDeleted: THROW_ERROR,
  canDiscountBeUpdated: THROW_ERROR,
  canDiscountBeDeleted: THROW_ERROR,
  canCustomDiscountBeUpdated: THROW_ERROR,
});

export const useContractRuleContext = () => useContext(ContractRuleContext);

export interface ContractRuleContextProviderProps {
  contractInfos: IContractInfosResponseDTO;

  tree: TreeContextApi<
    IContractInfosLotWithUUID,
    IContractInfosItemWithUUID,
    ContractLotComputedValue,
    ContractItemComputedValue
  >;
}

export const ContractRuleContextProvider = ({
  children,
  contractInfos,
  tree,
}: PropsWithChildren<ContractRuleContextProviderProps>) => {
  const { t } = useTranslation(['contracts']);
  const { formatAsAmount, mapAmountToNumber } = useCurrency();
  const form = useFormContext<ContractEditionForm>();

  const minimumProjectAmount = useMemo<number>(
    () => contractInfos.projectComputedAmounts.downPaymentAmountIncVAT,
    [contractInfos.projectComputedAmounts],
  );

  const otherContracts = useMemo(
    () => contractInfos.contracts.filter(({ id }) => id !== contractInfos.contract.id),
    [contractInfos.contract.id, contractInfos.contracts],
  );

  const getCurrentDiscount = useCallback((): Raw<IDiscount> | undefined => {
    const { discountAmountExVAT: rawDiscountAmountExVAT } = form.getFormValues();

    return computeContractDiscount(rawDiscountAmountExVAT, mapAmountToNumber);
  }, [form, mapAmountToNumber]);

  const getCurrentCustomDiscounts = useCallback((): Raw<ICustomDiscount>[] => {
    const { customDiscounts: rawCustomDiscounts } = form.getFormValues();

    if (!rawCustomDiscounts) return [];

    const customDiscounts: Raw<ICustomDiscount>[] = [];

    rawCustomDiscounts.forEach((rawCustomDiscount) => {
      if (rawCustomDiscount.amountExVAT !== null) {
        customDiscounts.push({
          type: CUSTOM_DISCOUNT_TYPES.AMOUNT,
          amountExVAT: rawCustomDiscount.amountExVAT,
          percentage: null,
          name: rawCustomDiscount.name,
          vatRate: rawCustomDiscount.vatRate,
        });
      }
    });

    return customDiscounts;
  }, [form]);

  /*
     Recompute project amounts to determine if changes are valid
     This will current tree value and apply changes given in parameters to it. Lot are irrelevant for this computation.
     So we just need to give items and discount update.

     If you want to test an item update, you will need to pass to newItems the new item state and pass
     to removedItems item id
   */
  const isFutureTreeValid = useCallback(
    (
      newItems: Array<Pick<IItemWithUUID, 'id' | 'quantity' | 'unitPrice' | 'vatRate'>>,
      removedItems: IItemWithUUID['id'][],
      currentDiscount: Raw<IDiscount> | undefined,
      currentCustomDiscounts: Raw<ICustomDiscount>[] = [],
    ): boolean => {
      const { subProject, projectComputedAmounts, rootLotId } = contractInfos;

      const currentTree = cloneDeep(tree.getCurrentTree());
      const computedValues = cloneDeep(tree.getComputedValues());

      // Store totalExVAT difference between current tree and new tree to compute later new contract amount
      let differenceTotalExVAT = 0;

      // Remove items given from the tree
      removedItems.forEach((id) => {
        differenceTotalExVAT -= computedValues.leaves[id].totalExVAT;
        delete currentTree.leaves[id];
      });

      // Add items given in parameters to the tree and recompute leaves computed values
      newItems.forEach((newItem) => {
        currentTree.leaves[newItem.id] = newItem as LeafWithRelations<IContractInfosLot, IContractInfosItem>;
        const totalExVAT = computeTotalExVAT(newItem);
        differenceTotalExVAT += totalExVAT;

        computedValues.leaves[newItem.id] = {
          totalExVAT,
          // Following properties are not used, we can attribute arbitrary values to them
          isComplete: false,
          totalExVATDifference: 0,
        };
      });

      const newTotalAmountWithoutDiscountExVAT = computedValues.nodes[rootLotId].totalExVAT + differenceTotalExVAT;

      const contractAmounts = computeContractFromTree(
        newTotalAmountWithoutDiscountExVAT,
        currentTree,
        computedValues.leaves,
        contractInfos,
        subProject,
        currentDiscount,
        currentCustomDiscounts,
      );

      return areSubProjectAmountsValidAfterContractEdition(contractInfos.subProject, projectComputedAmounts, [
        contractAmounts,
        ...otherContracts,
      ]);
    },
    [contractInfos, otherContracts, tree],
  );

  const canDiscountBeUpdatedOrDeleted = useCallback(
    (): boolean => !contractInfos.isDiscountInvoiced,
    [contractInfos.isDiscountInvoiced],
  );

  const canCustomDiscountBeUpdatedOrDeleted = useCallback(
    (customDiscountId: string): boolean => {
      if (contractInfos.customDiscountsInvoiced.isInvoiced) {
        const customDiscountIsInvoiced = contractInfos.customDiscountsInvoiced[customDiscountId].isInvoiced;
        return !customDiscountIsInvoiced;
      }
      // 4328 in case of customDiscountsInvoiced for more and less value, we don't need to show update or deleted if the invoice is validated
      if (contractInfos.customDiscountsInvoiced && contractInfos.customDiscountsInvoiced[customDiscountId]) {
        const customDiscountIsInvoiced = contractInfos.customDiscountsInvoiced[customDiscountId].isInvoiced;
        return !customDiscountIsInvoiced;
      }
      return true;
    },
    [contractInfos],
  );

  const canGlobalVATRateBeUpdated = useCallback(
    (): boolean =>
      // You cannot change global VAT rate if there is a discount with invoicing started
      !contractInfos.isDiscountInvoiced,
    [contractInfos.isDiscountInvoiced],
  );

  const canItemBeUpdated = useCallback(
    (
      previousItemState: IContractInfosItem,
      item: Pick<IItemWithUUID, 'id' | 'quantity' | 'vatRate' | 'unitPrice'>,
    ): UpdateStatus => {
      const totalExVAT = multiplyFloating(item.unitPrice, item.quantity);
      if (
        previousItemState.invoicedAmountExVAT !== 0 &&
        (!(Math.sign(totalExVAT) === Math.sign(previousItemState.invoicedAmountExVAT)) ||
          Math.abs(totalExVAT) < Math.abs(previousItemState.invoicedAmountExVAT))
      ) {
        return {
          canUpdate: false,
          errorMessage: t('contracts:toasts.cannotUpdateUnitPrice'),
        };
      }

      if (!isFutureTreeValid([item], [item.id], getCurrentDiscount())) {
        return {
          canUpdate: false,
          errorMessage: t('contracts:toasts.contractIsInvalid', { amount: formatAsAmount(minimumProjectAmount) }),
        };
      }

      return UPDATE_STATUS_SUCCESS;
    },
    [formatAsAmount, getCurrentDiscount, isFutureTreeValid, minimumProjectAmount, t],
  );

  const canItemBeDuplicated = useCallback(
    (duplicatedItemId: IItemWithUUID['id']): UpdateStatus => {
      const duplicatedItem = tree.getDisplayedCurrentTree().leaves[duplicatedItemId];
      const isValid = isFutureTreeValid([duplicatedItem], [], getCurrentDiscount(), getCurrentCustomDiscounts());

      return isValid
        ? UPDATE_STATUS_SUCCESS
        : {
            canUpdate: false,
            errorMessage: t('contracts:toasts.contractIsInvalid', { amount: formatAsAmount(minimumProjectAmount) }),
          };
    },
    [formatAsAmount, getCurrentCustomDiscounts, getCurrentDiscount, isFutureTreeValid, minimumProjectAmount, t, tree],
  );

  const canLotBeDuplicated = useCallback(
    (duplicatedLotId: ILotWithUUID['id']) => {
      const currentTree = tree.getDisplayedCurrentTree();
      const duplicatedItems = tree
        .findDescendingChildrenOfNode(duplicatedLotId)
        .leaves.map((id) => currentTree.leaves[id]);

      const isValid = isFutureTreeValid(duplicatedItems, [], getCurrentDiscount(), getCurrentCustomDiscounts());

      return isValid
        ? UPDATE_STATUS_SUCCESS
        : {
            canUpdate: false,
            errorMessage: t('contracts:toasts.contractIsInvalid', { amount: formatAsAmount(minimumProjectAmount) }),
          };
    },
    [formatAsAmount, getCurrentCustomDiscounts, getCurrentDiscount, isFutureTreeValid, minimumProjectAmount, t, tree],
  );

  const canItemBeDeleted = useCallback(
    (itemId: IItemWithUUID['id']): UpdateStatus => {
      const isValid = isFutureTreeValid([], [itemId], getCurrentDiscount(), getCurrentCustomDiscounts());

      return isValid
        ? UPDATE_STATUS_SUCCESS
        : {
            canUpdate: false,
            errorMessage: t('contracts:toasts.contractIsInvalid', { amount: formatAsAmount(minimumProjectAmount) }),
          };
    },
    [formatAsAmount, getCurrentCustomDiscounts, getCurrentDiscount, isFutureTreeValid, minimumProjectAmount, t],
  );

  const canLotBeDeleted = useCallback(
    (lotId: ILotWithUUID['id']): UpdateStatus => {
      const itemsIds = tree.findDescendingChildrenOfNode(lotId).leaves;

      const isValid = isFutureTreeValid([], itemsIds, getCurrentDiscount(), getCurrentCustomDiscounts());

      return isValid
        ? UPDATE_STATUS_SUCCESS
        : {
            canUpdate: false,
            errorMessage: t('contracts:toasts.contractIsInvalid', { amount: formatAsAmount(minimumProjectAmount) }),
          };
    },
    [formatAsAmount, getCurrentCustomDiscounts, getCurrentDiscount, isFutureTreeValid, minimumProjectAmount, t, tree],
  );

  const canDiscountBeUpdated = useCallback(
    (discount: Raw<IDiscount> | undefined): UpdateStatus => {
      const isValid = isFutureTreeValid([], [], discount, getCurrentCustomDiscounts());

      return isValid
        ? UPDATE_STATUS_SUCCESS
        : {
            canUpdate: false,
            errorMessage: t('contracts:toasts.cannotUpdateDiscount'),
          };
    },
    [getCurrentCustomDiscounts, isFutureTreeValid, t],
  );

  const canDiscountBeDeleted = useCallback((): UpdateStatus => {
    const { canUpdate } = canDiscountBeUpdated(undefined);

    return canUpdate
      ? UPDATE_STATUS_SUCCESS
      : {
          canUpdate: false,
          errorMessage: t('contracts:toasts.cannotDeleteDiscount'),
        };
  }, [canDiscountBeUpdated, t]);

  const canCustomDiscountBeUpdated = useCallback(
    (
      customDiscounts: Raw<ICustomDiscount>[],
      currentCustomDiscountName: string,
      isDeletion?: boolean,
    ): UpdateStatus => {
      const isValid = isFutureTreeValid([], [], getCurrentDiscount(), customDiscounts);

      return isValid
        ? UPDATE_STATUS_SUCCESS
        : {
            canUpdate: false,
            errorMessage: t(
              isDeletion
                ? 'contracts:toasts.cannotDeleteCustomDiscount'
                : 'contracts:toasts.cannotUpdateCustomDiscount',
              { name: currentCustomDiscountName },
            ),
          };
    },
    [getCurrentDiscount, isFutureTreeValid, t],
  );

  const contractContext = useMemo<IContractRuleContext>(
    () => ({
      contractInfos,
      canDiscountBeUpdatedOrDeleted,
      canCustomDiscountBeUpdatedOrDeleted,
      canGlobalVATRateBeUpdated,
      canItemBeUpdated,
      canItemBeDuplicated,
      canLotBeDuplicated,
      canItemBeDeleted,
      canLotBeDeleted,
      canDiscountBeUpdated,
      canDiscountBeDeleted,
      canCustomDiscountBeUpdated,
    }),
    [
      contractInfos,
      canDiscountBeUpdatedOrDeleted,
      canCustomDiscountBeUpdatedOrDeleted,
      canGlobalVATRateBeUpdated,
      canItemBeDuplicated,
      canItemBeUpdated,
      canLotBeDuplicated,
      canItemBeDeleted,
      canLotBeDeleted,
      canDiscountBeUpdated,
      canDiscountBeDeleted,
      canCustomDiscountBeUpdated,
    ],
  );

  return <ContractRuleContext.Provider value={contractContext}>{children}</ContractRuleContext.Provider>;
};
