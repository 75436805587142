import dayjs from 'dayjs';

import type { IQuote } from '../quote/quote.type';
import type { IQuotation } from '../quote/quotation.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IProject } from '../project/project.type';
import type { IClient } from '../client/client.type';
import type { IOrder } from '../order/order.type';
import type { ISupplier } from '../supplier/supplier.type';
import type {
  EmailTemplateAllOrderVariable,
  EmailTemplateAllQuoteVariable,
  EmailTemplateAllStatementVariable,
  EmailTemplateClientVariable,
  EmailTemplateProjectVariable,
  EmailTemplateSupplierVariable,
} from '../email-template/email-template.type';
import { formatDateOrEmpty } from '../common/date.util';
import { isNumberFinite } from '../common/number.util';
import type { ICredit } from '../credit/credit.type';
import type { IDownPayment } from '../down-payment/down-payment.type';
import type { IInvoice } from '../invoice/invoice.type';
import type { IProgressStatementWithPaymentDeadline } from '../progress-statement/progress-statement.type';
import type { IBill } from '../bill/bill.type';
import type { LexicalNode, LexicalNodeOrRootNode } from '../common/lexical.util';

import { EMAIL_ENTITY_TYPE } from './email.type';

function formatAddress(
  address: {
    address?: string;
    city?: string;
    postalCode?: string;
    country?: string;
  },
  isOneLine: boolean,
): string {
  const separator = isOneLine ? ', ' : '\n';
  const postalCodePart = address.postalCode && `${address.postalCode} `;
  const cityFullPart = (address.postalCode || address.city) && `${separator}${postalCodePart}${address.city}`;
  const countryPart = address.country && `, ${address.country}`;

  return `${address.address}${cityFullPart}${countryPart}`;
}

interface EmailTemplateQuoteData {
  entityType: EMAIL_ENTITY_TYPE.QUOTE;

  entityId: string;

  type: 'quote';

  quote: IQuote;

  project: RequiredByKeys<IProject, 'address'> | null;

  client: IClient;
}

interface EmailTemplateQuotationData {
  entityType: EMAIL_ENTITY_TYPE.QUOTATION;

  entityId: string;

  type: 'quotation';

  quote: IQuotation;

  project: RequiredByKeys<IProject, 'address'> | null;

  client: IClient;
}

interface EmailTemplateStatementData {
  entityType: EMAIL_ENTITY_TYPE;

  entityId: string;

  type: 'statement';

  statement: {
    name: string;
    refCode: string | null;
    billingDate: Date | null;
    paymentDeadline: Date | null;
    amountExVAT: number;
    amountIncVAT: number;
    totalToBePaidIncVAT: number | null;
    remainingToBePaidIncVAT: number | null;
    externalProgressStatementLink: string | null;
  };

  project: RequiredByKeys<IProject, 'address'> | null;

  client: IClient;
}

interface EmailTemplateOrderData {
  entityType: EMAIL_ENTITY_TYPE.ORDER;

  entityId: string;

  type: 'order';

  order: Pick<
    IOrder,
    'name' | 'orderNumber' | 'orderDate' | 'deliveryDate' | 'amountExVAT' | 'amountIncVAT' | 'deliveryAddress'
  >;

  project: RequiredByKeys<IProject, 'address'> | null;

  supplier: ISupplier;
}

export type EmailTemplateData =
  | EmailTemplateQuoteData
  | EmailTemplateOrderData
  | EmailTemplateStatementData
  | EmailTemplateQuotationData;

function getProjectVariable(
  data: RequiredByKeys<IProject, 'address'> | null,
): Record<EmailTemplateProjectVariable, string | null> {
  return {
    'project:address': data?.address ? formatAddress(data.address, true) : null,
    'project:startDate': data?.startDate ? formatDateOrEmpty(data.startDate) : null,
    'project:endDate': data?.startDate ? formatDateOrEmpty(data.endDate) : null,
    'project:name': data?.name || null,
    'project:purchaseOrderNumber': data?.purchaseOrderNumber || null,
    'project:refCode': data?.refCode || null,
  };
}

function getClientVariable(data: IClient): Record<EmailTemplateClientVariable, string | null> {
  return {
    'client:enterpriseName': data?.enterpriseName || null,
  };
}

function getSupplierVariable(data: ISupplier | undefined): Record<EmailTemplateSupplierVariable, string | null> {
  return {
    'supplier:name': data?.name || null,
  };
}

function getQuoteVariable(
  data: EmailTemplateQuoteData,
  formatAsAmount: (amount: number) => string,
): Record<EmailTemplateAllQuoteVariable, string | null> {
  return {
    'quote:name': data.quote.name,
    'quote:refCode': data.quote.refCode,
    'quote:date': data.quote.date ? formatDateOrEmpty(data.quote.date) : null,
    'quote:endDate': data.quote.date
      ? formatDateOrEmpty(dayjs(data.quote.date).add(data.quote.validityDuration || 0, 'd'))
      : null,
    'quote:amountExVAT': formatAsAmount(data.quote.amountExVAT),
    'quote:amountIncVAT': formatAsAmount(data.quote.amountIncVAT),
    'quote:validityDuration': isNumberFinite(data.quote.validityDuration) ? `${data.quote.validityDuration}` : null,
    ...getProjectVariable(data.project),
    ...getClientVariable(data.client),
  };
}

function getQuotationVariable(
  data: EmailTemplateQuotationData,
  formatAsAmount: (amount: string) => string,
): Record<EmailTemplateAllQuoteVariable, string | null> {
  return {
    'quote:name': data.quote.name,
    'quote:refCode': data.quote.refCode,
    'quote:date': data.quote.startedAt ? formatDateOrEmpty(data.quote.startedAt) : null,
    'quote:endDate': data.quote.startedAt
      ? formatDateOrEmpty(dayjs(data.quote.startedAt).add(data.quote.validityDuration || 0, 'd'))
      : null,
    'quote:amountExVAT': data.quote.amountExVAT ? formatAsAmount(data.quote.amountExVAT) : null,
    'quote:amountIncVAT': data.quote.amountIncVAT ? formatAsAmount(data.quote.amountIncVAT) : null,
    'quote:validityDuration': isNumberFinite(data.quote.validityDuration) ? `${data.quote.validityDuration}` : null,
    ...getProjectVariable(data.project),
    ...getClientVariable(data.client),
  };
}

function getStatementVariable(
  data: EmailTemplateStatementData,
  formatAsAmount: (amount: number) => string,
): Record<EmailTemplateAllStatementVariable, string | null> {
  return {
    'statement:name': data.statement.name,
    'statement:refCode': data.statement.refCode,
    'statement:billingDate': data.statement.billingDate ? formatDateOrEmpty(data.statement.billingDate) : null,
    'statement:paymentDeadline': data.statement.paymentDeadline
      ? formatDateOrEmpty(data.statement.paymentDeadline)
      : null,
    'statement:amountExVAT': formatAsAmount(data.statement.amountExVAT),
    'statement:amountIncVAT': formatAsAmount(data.statement.amountIncVAT),
    'statement:totalToBePaidIncVAT': isNumberFinite(data.statement.totalToBePaidIncVAT)
      ? formatAsAmount(data.statement.totalToBePaidIncVAT)
      : null,
    'statement:remainingToBePaidIncVAT': isNumberFinite(data.statement.remainingToBePaidIncVAT)
      ? formatAsAmount(data.statement.remainingToBePaidIncVAT)
      : null,
    'statement:externalProgressStatementLink': data.statement.externalProgressStatementLink,
    ...getProjectVariable(data.project),
    ...getClientVariable(data.client),
  };
}

function getOrderVariable(
  data: EmailTemplateOrderData,
  formatAsAmount: (amount: number) => string,
): Record<EmailTemplateAllOrderVariable, string | null> {
  return {
    'order:name': data.order.name,
    'order:orderNumber': data.order.orderNumber,
    'order:orderDate': formatDateOrEmpty(data.order.orderDate),
    'order:deliveryDate': data.order.deliveryDate ? formatDateOrEmpty(data.order.deliveryDate) : null,
    'order:amountExVAT': isNumberFinite(data.order.amountExVAT) ? formatAsAmount(data.order.amountExVAT) : null,
    'order:amountIncVAT': isNumberFinite(data.order.amountExVAT) ? formatAsAmount(data.order.amountIncVAT) : null,
    'order:deliveryAddress': data.order.deliveryAddress ? formatAddress(data.order.deliveryAddress, true) : null,
    ...getProjectVariable(data.project),
    ...getSupplierVariable(data.supplier),
  };
}

export function getEmailTemplateVariables(
  data: EmailTemplateData,
  formatAsAmount: ((amount: number) => string) | ((amount: string) => string),
): Record<string, string | null> {
  switch (data.type) {
    case 'quote':
      return getQuoteVariable(data, formatAsAmount as (amount: number) => string);
    case 'quotation':
      return getQuotationVariable(data, formatAsAmount as (amount: string) => string);
    case 'order':
      return getOrderVariable(data, formatAsAmount as (amount: number) => string);
    case 'statement':
      return getStatementVariable(data, formatAsAmount as (amount: number) => string);
    default:
      return {};
    // throw new Error('Not implemented');
  }
}

interface EmailVariablesMap {
  credit: {
    credit: ICredit;
    client: IClient;
    project: RequiredByKeys<IProject, 'address'> | null;
  };

  downPayment: {
    downPayment: IDownPayment;
    project: RequiredByKeys<IProject, 'address'> | null;
    client: IClient;
  };

  invoice: {
    invoice: IInvoice;
    project: RequiredByKeys<IProject, 'address'> | null;
    client: IClient;
  };

  progressStatement: {
    progressStatement: IProgressStatementWithPaymentDeadline;
    project: RequiredByKeys<IProject, 'address'> | null;
    client: IClient;
    publicLink: string | null;
  };

  order: {
    order: Pick<
      IOrder,
      'id' | 'name' | 'orderNumber' | 'orderDate' | 'deliveryDate' | 'amountExVAT' | 'amountIncVAT' | 'deliveryAddress'
    >;
    project: RequiredByKeys<IProject, 'address'> | null;
    supplier: ISupplier;
  };

  quote: {
    quote: IQuote;
    project: RequiredByKeys<IProject, 'address'> | null;
    client: IClient;
  };

  quotation: {
    quote: IQuotation;
    project: RequiredByKeys<IProject, 'address'> | null;
    client: IClient;
  };

  bill: {
    bill: IBill;
    project: RequiredByKeys<IProject, 'address' | 'primaryClient'> | null;
    client: IClient;
    publicLink: string | null;
  };
}
export function getEmailVariableData<T extends keyof EmailVariablesMap>(
  entity: T,
  data: EmailVariablesMap[T],
): EmailTemplateData {
  switch (entity) {
    case 'credit':
      // TODO delete after ts 5.4
      // eslint-disable-next-line no-case-declarations
      const creditData = data as EmailVariablesMap['credit'];
      return {
        entityType: EMAIL_ENTITY_TYPE.CREDIT,
        entityId: creditData.credit.id.toString(),
        type: 'statement',
        statement: {
          name: creditData.credit.name,
          refCode: creditData.credit.invoiceNumber,
          billingDate: creditData.credit.creditDate,
          paymentDeadline: null,
          amountExVAT: creditData.credit.amountExVAT,
          amountIncVAT: creditData.credit.amountIncVAT,
          totalToBePaidIncVAT: null,
          remainingToBePaidIncVAT: null,
          externalProgressStatementLink: null,
        },
        client: creditData.client,
        project: creditData.project || null,
      };
    case 'downPayment':
      // TODO delete after ts 5.4
      // eslint-disable-next-line no-case-declarations
      const downPaymentData = data as EmailVariablesMap['downPayment'];
      return {
        entityType: EMAIL_ENTITY_TYPE.DOWN_PAYMENT,
        entityId: downPaymentData.downPayment.id.toString(),
        type: 'statement',
        statement: {
          name: downPaymentData.downPayment.name,
          refCode: downPaymentData.downPayment.invoiceNumber,
          billingDate: downPaymentData.downPayment.billingDate,
          paymentDeadline: downPaymentData.downPayment.bill?.paymentDeadline || null,
          amountExVAT: downPaymentData.downPayment.amountExVAT,
          amountIncVAT: downPaymentData.downPayment.amountIncVAT,
          totalToBePaidIncVAT: downPaymentData.downPayment.bill?.totalToBePaidIncVAT ?? null,
          remainingToBePaidIncVAT: downPaymentData.downPayment.bill?.remainingToBePaidIncVAT ?? null,
          externalProgressStatementLink: null,
        },
        project: downPaymentData.project,
        client: downPaymentData.client,
      };
    case 'invoice':
      // TODO delete after ts 5.4
      // eslint-disable-next-line no-case-declarations
      const invoiceData = data as EmailVariablesMap['invoice'];
      return {
        entityType: EMAIL_ENTITY_TYPE.INVOICE,
        entityId: invoiceData.invoice.id.toString(),
        type: 'statement',
        statement: {
          name: invoiceData.invoice.name,
          refCode: invoiceData.invoice.invoiceNumber,
          billingDate: invoiceData.invoice.billingDate,
          paymentDeadline: invoiceData.invoice.bill?.paymentDeadline || null,
          amountExVAT: invoiceData.invoice.amountExVAT,
          amountIncVAT: invoiceData.invoice.amountIncVAT,
          totalToBePaidIncVAT: invoiceData.invoice.bill?.totalToBePaidIncVAT ?? null,
          remainingToBePaidIncVAT: invoiceData.invoice.bill?.remainingToBePaidIncVAT ?? null,
          externalProgressStatementLink: null,
        },
        project: (invoiceData.project as RequiredByKeys<IProject, 'address'>) || null,
        client: invoiceData.client,
      };
    case 'progressStatement':
      // TODO delete after ts 5.4
      // eslint-disable-next-line no-case-declarations
      const progressStatementData = data as EmailVariablesMap['progressStatement'];
      return {
        entityType: EMAIL_ENTITY_TYPE.PROGRESS_STATEMENT,
        entityId: progressStatementData.progressStatement.id.toString(),
        type: 'statement',
        statement: {
          name: progressStatementData.progressStatement.name,
          refCode: progressStatementData.progressStatement.invoiceNumber,
          billingDate: progressStatementData.progressStatement.billingDate,
          amountExVAT: progressStatementData.progressStatement.amountExVAT,
          amountIncVAT: progressStatementData.progressStatement.amountIncVAT,
          paymentDeadline: progressStatementData.progressStatement.paymentDeadline ?? null,
          totalToBePaidIncVAT: progressStatementData.progressStatement.bill?.totalToBePaidIncVAT ?? null,
          remainingToBePaidIncVAT: progressStatementData.progressStatement.bill?.remainingToBePaidIncVAT ?? null,
          externalProgressStatementLink: progressStatementData.publicLink,
        },
        client: progressStatementData.client,
        project: progressStatementData.project,
      };
    case 'bill': {
      const billData = data as EmailVariablesMap['bill'];

      if (billData.bill.progressStatement) {
        if (!billData.project) {
          throw new Error('Project must be joined if "progressStatement" is specified');
        }
        return getEmailVariableData('progressStatement', {
          progressStatement: {
            ...billData.bill.progressStatement,
            bill: billData.bill,
            paymentDeadline: billData.bill.paymentDeadline,
          },
          project: billData.project,
          client: billData.client,
          publicLink: billData.publicLink,
        });
      }
      if (billData.bill.invoice) {
        return getEmailVariableData('invoice', {
          invoice: { ...billData.bill.invoice, bill: billData.bill },
          project: billData.project,
          client: billData.client,
        });
      }
      if (billData.bill.downPayment) {
        if (!billData.project) {
          throw new Error('Project must be joined if "progressStatement" is specified');
        }
        return getEmailVariableData('downPayment', {
          downPayment: { ...billData.bill.downPayment, bill: billData.bill },
          project: billData.project,
          client: billData.client,
        });
      }

      return {} as EmailTemplateData;
      // throw new Error('Bill is not linked to a statement');
    }
    case 'order':
      // TODO delete after ts 5.4
      // eslint-disable-next-line no-case-declarations
      const orderData = data as EmailVariablesMap['order'];

      return {
        entityType: EMAIL_ENTITY_TYPE.ORDER,
        entityId: orderData.order.id.toString(),
        type: 'order',
        ...orderData,
      };
    case 'quote':
      // TODO delete after ts 5.4
      // eslint-disable-next-line no-case-declarations
      const quoteData = data as EmailVariablesMap['quote'];

      return {
        entityType: EMAIL_ENTITY_TYPE.QUOTE,
        entityId: quoteData.quote.id.toString(),
        type: 'quote',
        ...quoteData,
      };
    case 'quotation':
      // TODO delete after ts 5.4
      // eslint-disable-next-line no-case-declarations
      const quotationData = data as EmailVariablesMap['quotation'];

      return {
        entityType: EMAIL_ENTITY_TYPE.QUOTATION,
        entityId: quotationData.quote.id,
        type: 'quotation',
        ...quotationData,
      };
    default:
      throw new Error('Not implemented');
  }
}

export class MissingTemplateVariableError extends Error {}

export function substituteHTMLVariables(templateHTML: string, values: Record<string, string | null>): string {
  return templateHTML.replace(/\{\{(.*?)\}\}/g, (_, variableName: string) => {
    const variableValue = values[variableName] as string;
    if (variableValue === undefined || variableValue === null) {
      throw new MissingTemplateVariableError(`Missing variable ${variableName}`);
    }
    return variableValue;
  });
}

export function substituteLexicalVariables(
  template: string,
  values: Record<string, string | null>,
): LexicalNodeOrRootNode {
  const parsed = JSON.parse(template);
  const replaceNode = (node: LexicalNode): LexicalNode => {
    if (node.type === 'variable') {
      const variableValue = values[node.value as string];
      if (variableValue === undefined || variableValue === null) {
        throw new MissingTemplateVariableError(`Missing variable ${node.value}`);
      }
      return { ...node, type: 'replaced_variable', text: variableValue };
    }
    return { ...node, ...(node.children && { children: (node.children ?? []).map(replaceNode) }) };
  };
  const replaced = replaceNode(parsed.root);
  return { ...parsed, root: replaced };
}
