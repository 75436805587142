import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const RoundLeftIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 33 33" {...props}>
    <path
      d="M15.8873 9.5C17.264 9.54191 18.2384 10.0552 18.842 11.0295C19.4351 12.0143 19.7316 13.2296 19.7104 14.6858C19.721 16.3096 19.3927 17.5982 18.7679 18.5306C18.1113 19.4525 17.1264 19.9449 15.8131 19.9763C14.4787 19.9344 13.515 19.4525 12.9007 18.4677C12.2865 17.4934 11.9794 16.2572 11.9794 14.7382C11.9794 13.2191 12.2971 11.9724 12.9431 11.0086C13.5679 10.0238 14.5317 9.54191 15.8873 9.5ZM15.8555 11.2076C15.3895 11.2076 15.0188 11.4905 14.7435 12.0772C14.4681 12.6429 14.3411 13.5543 14.3411 14.7382C14.3305 15.9429 14.4681 16.8334 14.7329 17.3991C15.0083 17.9858 15.3895 18.2687 15.8767 18.2687C16.851 18.2687 17.3488 17.0848 17.3593 14.7382C17.3593 12.4019 16.8616 11.2286 15.8555 11.2076Z"
      fill="currentColor"
    />
    <path
      d="M10.027 17.6668C11.0032 17.6668 11.0032 18.643 11.0032 18.643C11.0032 18.643 11.0032 19.6192 10.027 19.6192C9.05078 19.6192 9.05078 18.643 9.05078 18.643C9.05078 18.643 9.05078 17.6668 10.027 17.6668Z"
      fill="currentColor"
    />
    <path
      d="M17.9616 22.5475L19.9139 20.5951V22.0594H24.7949V23.0356H19.9139V24.4999L17.9616 22.5475Z"
      fill="currentColor"
    />
  </Icon>
);
