import {
  Card,
  Badge,
  DocumentStatusCard,
  formatDateOrEmpty,
  DocumentStatusCardActions,
  ConfirmDeletionModal,
} from '@graneet/lib-ui';
import { getEmailVariableData, PERMISSION, STATEMENT_TYPES, PDF_STATUSES } from '@graneet/business-logic';
import type {
  IContactWithProjectInfos,
  IGroupedContact,
  IInvoiceResponseDTO,
  IInvoiceVersion,
  IProject,
  RequiredByKeys,
} from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { ForceDraftInvoiceModal } from '../../modals/ForceDraftInvoiceModal';

import { getInvoiceActions, getInvoiceStatusCardIcon } from './invoice.actions';

import { INVOICE_STATUS_COLORS } from 'features/invoice/constants/invoice.constant';
import { useChangeInvoiceStatusToCompleted, useDeleteInvoice } from 'features/invoice/services/invoice.api';
import { getProjectDefaultEmailRecipient } from 'features/contact/services/contact.util';
import type { MailingModalProps } from 'features/mailing/components/MailingModal/MailingModal';
import { sendMail } from 'features/mailing/services/mailing.api';
import { MailingModal } from 'features/mailing/components/MailingModal/MailingModal';
import { useHasLedger } from 'features/statement-ledger/hooks/useHasLedger';
import { StatementEditInvoiceNumberModal } from 'features/statement-ledger/components/StatementEditInvoiceNumberModal';
import { CreditConfirmModal } from 'features/credit/components/modals/CreditConfirmModal';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import { getDownloadUrl, getPreviewUrl } from 'features/pdf/services/pdf.api';
import { formatFileName } from 'features/file/services/file.util';
import { UpdateStatementBillingDateModal } from 'features/statement/components/UpdateStatementBillingDateModal';

interface InvoiceStatusCardProps {
  invoice: IInvoiceResponseDTO;
  currentVersion: IInvoiceVersion | undefined;
  onDeleted: () => void;
  onCancelConfirmed: (isCreateDraftChecked: boolean) => void;
  projectId?: number | null;
  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
}

export const InvoiceStatusCard: FC<InvoiceStatusCardProps> = ({
  invoice,
  currentVersion,
  onCancelConfirmed,
  projectId,
  projectContacts,
  onDeleted,
}) => {
  const { t } = useTranslation(['mailing', 'invoice', 'credit', 'global', 'statement']);

  const [isPdfGenerated, setIsPdfGenerated] = useState(invoice.pdf?.status === PDF_STATUSES.GENERATED);

  const mailingModal = useDisclosure();
  const invoiceNumberModal = useDisclosure();
  const newCreditModal = useDisclosure();
  const forceDraftModal = useDisclosure();
  const deletionModal = useDisclosure();
  const updateStatementBillingDateModal = useDisclosure();

  const hasLedger = useHasLedger();
  const history = useHistory();
  const [isCompleting, setIsCompleting] = useState(false);

  const hasCreatePermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);
  const hasUpdatePermission = usePermissions([PERMISSION.UPDATE_STATUS_STATEMENTS]);
  const hasForceDraftPermission = usePermissions([PERMISSION.FORCE_DRAFT_STATEMENTS]);

  const deleteInvoiceMutation = useDeleteInvoice();
  const changeInvoiceStatusToCompletedMutation = useChangeInvoiceStatusToCompleted({ hasLedger });

  const goToEdit = useCallback(
    (invoiceId: number) => {
      history.push(`/sales/statements/invoices/${invoiceId}/edit`, { projectId });
    },
    [history, projectId],
  );

  const handleDelete = useCallback(async () => {
    await deleteInvoiceMutation.mutateAsync(invoice.id);
    onDeleted();
  }, [deleteInvoiceMutation, invoice.id, onDeleted]);

  const handleOnEdit = useCallback(() => {
    goToEdit(invoice.id);
  }, [goToEdit, invoice.id]);

  const completeInvoice = useCallback(async () => {
    setIsCompleting(true);
    await changeInvoiceStatusToCompletedMutation.mutateAsync({ id: invoice.id });
    setIsCompleting(false);
  }, [changeInvoiceStatusToCompletedMutation, invoice.id]);

  const onSetStatusToCompleted = useCallback(async () => {
    if (!hasLedger && !invoice.invoiceNumber) {
      invoiceNumberModal.onOpen();

      return;
    }

    if (hasLedger && !invoice.invoiceNumber && dayjs(invoice.billingDate).isBefore(dayjs(new Date()), 'day')) {
      updateStatementBillingDateModal.onOpen();
      return;
    }

    await completeInvoice();
  }, [
    hasLedger,
    invoice.invoiceNumber,
    invoice.billingDate,
    completeInvoice,
    invoiceNumberModal,
    updateStatementBillingDateModal,
  ]);

  const updateInvoiceNumber = useCallback(
    (invoiceNumber: string) =>
      changeInvoiceStatusToCompletedMutation.mutateAsync({ id: invoice.id, dto: { invoiceNumber } }),
    [changeInvoiceStatusToCompletedMutation, invoice.id],
  );

  useEffect(() => {
    if (invoice?.pdf?.status === PDF_STATUSES.GENERATING) {
      subscribeToPDFUpdates({ apiId: invoice.pdf!.apiId }, (pdfStatus) => {
        setIsPdfGenerated(pdfStatus === PDF_STATUSES.GENERATED);
      });
    }
  }, [invoice]);

  const onViewPdf = useCallback(() => {
    if (currentVersion?.pdf) {
      window.open(getPreviewUrl(currentVersion.pdf.apiId));
    }
  }, [currentVersion]);

  const onDownloadPdf = useCallback(() => {
    if (currentVersion?.pdf) {
      window.open(
        getDownloadUrl(
          currentVersion.pdf.apiId,
          formatFileName(currentVersion.name || '', currentVersion.billingDate, currentVersion.invoiceNumber),
        ),
      );
    }
  }, [currentVersion]);

  const onMailSubmit = useCallback<MailingModalProps['onSubmit']>(
    (data) => sendMail(data.dto, data.attachments, data.mode),
    [],
  );

  return (
    <Card title={t('invoice:cards.state')} flex={1}>
      <DocumentStatusCard
        mb={4}
        title={invoice.name}
        subtitle={t('invoice:fields.createdAt', { date: formatDateOrEmpty(invoice.createdAt, 'LL') })}
        icon={getInvoiceStatusCardIcon(invoice.status)}
        badgeComponent={
          <Badge colorScheme={INVOICE_STATUS_COLORS[invoice.status]}>{t(`invoice:statuses.${invoice.status}`)}</Badge>
        }
      />

      <DocumentStatusCardActions
        actions={getInvoiceActions({
          invoice,
          pdf: currentVersion?.pdf ?? undefined,

          isCompleteButtonLoading: isCompleting,

          hasCreatePermission,
          hasUpdatePermission,
          hasForceDraftPermission,

          onEdit: handleOnEdit,
          onSetStatusToCompleted,
          onCancelWithCredit: newCreditModal.onOpen,
          onCancelWithoutCredit: forceDraftModal.onOpen,
          onDelete: deletionModal.onOpen,
          onViewPdf,
          onDownloadPdf,
          onOpenMailing: mailingModal.onOpen,
        })}
      />

      <CreditConfirmModal modal={newCreditModal} onConfirmed={onCancelConfirmed} />

      <ForceDraftInvoiceModal modal={forceDraftModal} invoiceId={invoice.id} onConfirmed={goToEdit} />

      <StatementEditInvoiceNumberModal
        {...invoiceNumberModal}
        title={t('invoice:modals.complete.title')}
        completeLabel={t('invoice:actions.complete')}
        statementType={STATEMENT_TYPES.INVOICE}
        statementId={invoice.id}
        updateInvoiceNumber={updateInvoiceNumber}
      />

      <UpdateStatementBillingDateModal
        {...updateStatementBillingDateModal}
        currentBillingDate={invoice.billingDate}
        validateStatement={completeInvoice}
      />

      {invoice.pdf && isPdfGenerated && (
        <MailingModal
          title={t('mailing:modal.title.invoice')}
          isOpen={mailingModal.isOpen}
          onClose={mailingModal.onClose}
          onSubmit={onMailSubmit}
          variableData={getEmailVariableData('invoice', {
            invoice,
            project: (invoice.project as RequiredByKeys<IProject, 'address'>) || null,
            client: invoice.client,
          })}
          defaultMailContacts={getProjectDefaultEmailRecipient(projectContacts)}
        />
      )}

      <ConfirmDeletionModal
        title={t('statement:delete.title')}
        action={t('statement:delete.action')}
        description={t('statement:delete.confirmationMessage')}
        onDeleted={handleDelete}
        {...deletionModal}
      />
    </Card>
  );
};
