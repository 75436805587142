import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

import * as quoteApi from '../services/quote.api';

import { useQuoteEditContext } from './useQuoteEditContext';

export const useEditQuoteReversalOfLiability = () => {
  const { t } = useTranslation(['quote']);
  const toast = useToast();

  const { getQuoteId, emitQuoteUpdate } = useQuoteEditContext();

  return useCallback(
    async (hasReversalOfLiability: boolean) => {
      const [err, quote] = await quoteApi.patchQuote(getQuoteId()!, { hasReversalOfLiability });

      if (err) {
        toast.error(t('quote:errors.updatingError'));
        return;
      }

      emitQuoteUpdate(quote);
    },
    [getQuoteId, emitQuoteUpdate, toast, t],
  );
};
