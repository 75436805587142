import { Flex, Text } from '@chakra-ui/react';
import type { ISupplierListingDTO } from '@graneet/business-logic';
import { SUPPLIER_TYPE } from '@graneet/business-logic';
import { Button, SimpleEditIcon } from '@graneet/lib-ui';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { SupplierCardLine } from './SupplierCardLine';

interface SupplierCardDetailsProps {
  supplier: ISupplierListingDTO;
  onSupplierCardButtonClicked?: () => void;
  children: ReactNode;
}

export const SupplierCardDetails: FC<SupplierCardDetailsProps> = ({
  supplier,
  onSupplierCardButtonClicked,
  children,
}) => {
  const { t } = useTranslation(['supplier']);

  return (
    <>
      {children}
      <Flex p={4} borderBottomColor="gray.200" borderBottomWidth={1}>
        <Text textColor="gray.800" fontWeight="500" fontSize="18px" alignItems="center">
          {t(
            supplier.type === SUPPLIER_TYPE.SUPPLIER
              ? 'supplier:card.titleSupplier'
              : 'supplier:card.titleSubContractor',
          )}
        </Text>

        <Flex marginLeft="auto">
          <Button
            data-testid="button:client.edit"
            variant="outline"
            leftIcon={<SimpleEditIcon />}
            onClick={onSupplierCardButtonClicked}
          >
            {t('supplier:modal.edit.editButton')}
          </Button>
        </Flex>
      </Flex>
      <Flex p={4}>
        <SupplierCardLine supplier={supplier} />
      </Flex>
    </>
  );
};
