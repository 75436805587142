import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrency, Wizard, useToast, ScrollProvider, Container } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import type { IProgressStatement } from '@graneet/business-logic';
import {
  isProjectActive,
  PROGRESS_STATEMENT_STATUSES,
  isNumberFinite,
  computeAutoNumberingAndOptionalLotFromRootLot,
  isSubProjectInDirectBilling,
  isSubProjectBillableAsProgressStatement,
} from '@graneet/business-logic';

import { EditProgressStatementItemsStep } from './_steps/items/EditProgressStatementItemsStep';
import { EditProgressStatementPriceRevisionStep } from './_steps/price-revision/EditProgressStatementPriceRevisionStep';
import { EditProgressStatementDirectPaymentsStep } from './_steps/direct-payments/EditProgressStatementDirectPaymentsStep';
import { EditProgressStatementDownPaymentStep } from './_steps/down-payment/EditProgressStatementDownPaymentStep';
import { EditProgressStatementInformationStep } from './_steps/information/EditProgressStatementInformationStep';
import { EditProgressStatementSummaryStep } from './_steps/summary/EditProgressStatementSummaryStep';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { useContractsBySubProject } from 'features/contract/services/contract.api';
import { useOrdersWithDirectPayments } from 'features/order/services/order.api';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { DirectPaymentContextProvider } from 'features/direct-payment/contexts/DirectPaymentContext';
import { OptionalLotsTableStatus, useStore } from 'store/store';
import { useSubProject, useSubProjectDownPayment } from 'features/sub-project/services/sub-project.api';
import { Beacon } from 'features/beacon/components/Beacon';
import {
  usePreviousProgressStatementBySubProject,
  useProgressStatementCreate,
  useProgressStatementExtended,
  useProgressStatementUpdate,
} from 'features/progress-statement/services/progress-statement.api';
import type { ProgressStatementEditWizard } from 'features/progress-statement/forms/progress-statement-edit-wizard';
import {
  formatProgressStatementWizardTitle,
  getProgressStatementsLinesAndContractDiscountDTO,
} from 'features/progress-statement/services/progress-statement.util';
import { ProgressStatementProvider } from 'features/progress-statement/contexts/ProgressStatementContext';
import { useProject } from 'features/project/services/project.api';

export const EditProgressStatementScreen: FC = () => {
  const history = useHistory();
  const { state = {} } = useLocation<{
    referrerUrl?: string;
    url?: string;
  }>();
  const {
    projectId: stringProjectId,
    subProjectId,
    progressStatementId: stringProgressStatementId,
  } = useParams<{
    projectId: string;
    subProjectId: string;
    progressStatementId?: string;
  }>();
  const projectId = parseInt(stringProjectId, 10);
  const progressStatementId = stringProgressStatementId ? parseInt(stringProgressStatementId, 10) : undefined;

  // - Computed values

  const isEditing = !!progressStatementId;

  // - UI hooks

  useHideNavbar();

  const { t } = useTranslation(['progressStatement']);
  const { mapAmountToNumber } = useCurrency();
  const { createRedirectionWithSavedFilters } = useFiltersQuery();
  const toast = useToast();
  const optionalLotsSetTableStatus = useStore((stateStore) => stateStore.optionalLotsSetTableStatus);

  const wizardLabels = useWizardLabels({
    save: isEditing
      ? t('progressStatement:isCreating.editionProgressStatement')
      : t('progressStatement:isCreating.creationProgressStatement'),
  });

  const project = useProject(projectId);
  const downPayment = useSubProjectDownPayment(subProjectId);
  const subProject = useSubProject(subProjectId);
  const contracts = useContractsBySubProject(subProjectId);
  const previousProgressStatementRaw = usePreviousProgressStatementBySubProject({
    subProjectId,
    progressStatementId,
  });
  const currentProgressStatement = useProgressStatementExtended(progressStatementId);
  const directPaymentOrders = useOrdersWithDirectPayments({
    projectId,
    subProjectId,
    progressStatementId: isNumberFinite(progressStatementId) ? progressStatementId : undefined,
  });

  const progressStatementCreateMutation = useProgressStatementCreate();
  const progressStatementUpdateMutation = useProgressStatementUpdate();

  // '' is received when there is no previous statement
  const previousProgressStatement = !previousProgressStatementRaw.data ? undefined : previousProgressStatementRaw.data;

  const { contractsWithAutoNumbering, hasOptionalItems } = useMemo(() => {
    let hasContractsOptionalItems = false;
    const mappedContracts =
      contracts.data.map((contract) => {
        const computeContractHasOptionalItems = {
          hasOptionalItems: false,
        };
        computeAutoNumberingAndOptionalLotFromRootLot(
          contract.container,
          contract.isAutomaticNumberingActivated,
          computeContractHasOptionalItems,
        );
        hasContractsOptionalItems = hasContractsOptionalItems || computeContractHasOptionalItems.hasOptionalItems;
        return contract;
      }) || null;
    return {
      contractsWithAutoNumbering: mappedContracts,
      hasOptionalItems: hasContractsOptionalItems,
    };
  }, [contracts.data]);

  if (!hasOptionalItems) {
    optionalLotsSetTableStatus(OptionalLotsTableStatus.HAS_NO_OPTION);
  } else {
    optionalLotsSetTableStatus(OptionalLotsTableStatus.HAS_ONE_OR_MORE_OPTIONS);
  }

  // - Handling navigation

  /**
   * Go first to project params screen if params are not set yet
   */
  useEffect(() => {
    if (!project.data) return;
    if (!isSubProjectBillableAsProgressStatement(subProject.data, { ignoreReversalOfLiability: true })) {
      const { referrerUrl } = state;
      history.replace(`/projects/${project.data.id}/sub-projects/${subProject.data.id}/parameters`, {
        // Once project params are saved, return to ps edition/creation
        nextUrl: isEditing
          ? `/projects/${project.data.id}/statements/sub-projects/${subProject.data.id}/progress-statements/${progressStatementId}/edit`
          : `/projects/${project.data.id}/statements/sub-projects/${subProject.data.id}/progress-statements/create`,
        // Pass referer onto next screen so if we press back
        referrerUrl,
      });
    }
  }, [history, isEditing, progressStatementId, project, state, subProject]);

  /**
   * Toast and redirect if progress statement data could not be loaded properly or
   * project billing type is wrong
   */
  useEffect(() => {
    const { status, isArchived, project: projectData } = currentProgressStatement.data ?? {};
    if (!projectData) return;
    if (!isArchived && projectData.id === projectId) return;
    if (status === PROGRESS_STATEMENT_STATUSES.DRAFT) return;

    toast.error(t('progressStatement:errors.progressStatementNotLoaded'));
    history.replace(`/projects/${projectId}/statements`);
  }, [currentProgressStatement.data, history, projectId, t, toast]);

  /**
   * Where to go when we press back button for first step
   */
  const handleGoBack = useCallback(() => {
    if (state.referrerUrl) {
      return createRedirectionWithSavedFilters(state.referrerUrl, { replace: true })();
    }
    if (!isEditing) {
      return createRedirectionWithSavedFilters(`/projects/${projectId}/statements`, { replace: true })();
    }
    return createRedirectionWithSavedFilters(
      `/projects/${projectId}/statements/progress-statements/${progressStatementId}`,
      { replace: true },
    )();
  }, [state, isEditing, createRedirectionWithSavedFilters, projectId, progressStatementId]);

  // - Submitting
  const handleSubmit = useCallback(
    async (wizardValues: ProgressStatementEditWizard) => {
      const {
        items: itemsValues,
        directPayments: directPaymentsValues,
        information: informationValues,
        validation: validationValues,
      } = wizardValues;

      const { name, invoiceNumber, billingDate, note, displayOptionsAnnexPDF } = informationValues;
      const ordersSupplierInvoices = directPaymentsValues?.ordersSupplierInvoices;
      const { cumulativeInputType } = itemsValues;

      const {
        progressStatementsLinesDTO: lines,
        contractDiscountLinesDTO: discountLines,
        contractCustomDiscountLinesDTO: customDiscountLines,
      } = getProgressStatementsLinesAndContractDiscountDTO(itemsValues, mapAmountToNumber);

      let progressStatement: IProgressStatement;
      if (isEditing) {
        progressStatement = await progressStatementUpdateMutation.mutateAsync({
          id: progressStatementId,
          dto: {
            name,
            invoiceNumber,
            billingDate,
            note,
            lines,
            discountLines,
            customDiscountLines,
            displayOptionsAnnexPDF,
            priceRevisionExVAT:
              (subProject.data.hasPriceRevision && mapAmountToNumber(wizardValues.priceRevision?.priceRevision)) || 0,
            downPaymentAmortizationAmount:
              (downPayment.data && mapAmountToNumber(wizardValues.downPayment?.downPaymentAmortizationAmount)) || 0,
            cumulativeInputType,
            ordersSupplierInvoices: ordersSupplierInvoices || [],
            penalties: validationValues.penalties,
          },
        });
      } else {
        progressStatement = await progressStatementCreateMutation.mutateAsync({
          name,
          invoiceNumber,
          billingDate,
          note,
          lines,
          discountLines,
          customDiscountLines,
          displayOptionsAnnexPDF,
          priceRevisionExVAT:
            (subProject.data.hasPriceRevision && mapAmountToNumber(wizardValues.priceRevision?.priceRevision)) || 0,
          downPaymentAmortizationAmount:
            (downPayment.data && mapAmountToNumber(wizardValues.downPayment?.downPaymentAmortizationAmount)) || 0,
          cumulativeInputType,
          ordersSupplierInvoices,
          subProjectId,
          penalties: validationValues.penalties,
        });
      }

      history.replace(state?.url || `/projects/${projectId}/statements/progress-statements/${progressStatement.id}`);
    },
    [
      mapAmountToNumber,
      isEditing,
      history,
      state?.url,
      projectId,
      progressStatementUpdateMutation,
      progressStatementId,
      subProject.data.hasPriceRevision,
      downPayment.data,
      progressStatementCreateMutation,
      subProjectId,
    ],
  );

  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  /**
   * Redirect user to project page if he tries to access to the creation page with URL
   */
  if (!isProjectActive(project.data)) {
    history.replace(`/projects/${projectId}`);
    return null;
  }

  if (isSubProjectInDirectBilling(subProject.data)) {
    history.replace(`/projects/${project.data.id}/statements`);
    toast.error(t('progressStatement:isCreating.errors.billingType'));
    return null;
  }

  const headerTitle = t(
    isEditing ? 'progressStatement:isCreating.titleUpdate' : 'progressStatement:isCreating.titleCreation',
    { label: formatProgressStatementWizardTitle(project.data, currentProgressStatement.data) },
  );

  return (
    <Wizard<ProgressStatementEditWizard>
      headerTitle={headerTitle}
      onQuit={handleGoBack}
      onFinish={handleSubmit}
      labels={wizardLabels}
      scrollerRef={setRef}
    >
      <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_HELP}>
        <Beacon top="-4rem" right="0.6rem" />
      </Wizard.Placeholder>
      <Wizard.Step<ProgressStatementEditWizard> name="items" displaySecondaryButton={false}>
        <ScrollProvider value={ref}>
          <ProgressStatementProvider
            contracts={contractsWithAutoNumbering}
            previousProgressStatement={previousProgressStatement}
          >
            <EditProgressStatementItemsStep
              contracts={contractsWithAutoNumbering}
              previousProgressStatement={previousProgressStatement}
              currentProgressStatement={currentProgressStatement.data}
              subProject={subProject.data}
            />
          </ProgressStatementProvider>
        </ScrollProvider>
      </Wizard.Step>

      {subProject.data.hasPriceRevision && (
        <Wizard.Step<ProgressStatementEditWizard> name="priceRevision">
          <EditProgressStatementPriceRevisionStep
            previousProgressStatement={previousProgressStatement}
            currentProgressStatement={currentProgressStatement.data}
          />
        </Wizard.Step>
      )}

      {!!directPaymentOrders.data.data.length && (
        <DirectPaymentContextProvider
          directPaymentOrders={directPaymentOrders.data.data}
          currentProgressStatement={currentProgressStatement.data}
          contracts={contractsWithAutoNumbering}
          previousProgressStatement={previousProgressStatement}
        >
          <Wizard.Step<ProgressStatementEditWizard> name="directPayments" p={0} maxH="100%">
            <QueryWrapper>
              <EditProgressStatementDirectPaymentsStep />
            </QueryWrapper>
          </Wizard.Step>
        </DirectPaymentContextProvider>
      )}

      {downPayment.data && (
        <Wizard.Step<ProgressStatementEditWizard> name="downPayment">
          <EditProgressStatementDownPaymentStep
            subProject={subProject.data}
            currentProgressStatement={currentProgressStatement.data}
            downPayment={downPayment.data}
          />
        </Wizard.Step>
      )}

      <Wizard.Step<ProgressStatementEditWizard> name="information">
        <Container size="md">
          <EditProgressStatementInformationStep
            project={project.data}
            currentProgressStatement={currentProgressStatement.data}
            previousProgressStatement={previousProgressStatement}
          />
        </Container>
      </Wizard.Step>

      <Wizard.Step<ProgressStatementEditWizard> name="validation">
        <QueryWrapper>
          <EditProgressStatementSummaryStep
            subProject={subProject.data}
            project={project.data}
            currentProgressStatement={currentProgressStatement.data}
            downPayment={downPayment.data ?? undefined}
          />
        </QueryWrapper>
      </Wizard.Step>
    </Wizard>
  );
};
