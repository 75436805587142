import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateField, getCurrentDateAsString, StepperModal } from '@graneet/lib-ui';
import { Form, useStepForm } from 'graneet-form';
import { Box, Text } from '@chakra-ui/react';

import type { QuoteAcceptModalWizard } from './types';

import { Rule } from 'features/form/rules/Rule';

interface SelectSettledDateStepProps {
  isLoading: boolean;
}

export const SelectSettledDateStep: FC<SelectSettledDateStepProps> = ({ isLoading }) => {
  const { form, initFormValues } = useStepForm<QuoteAcceptModalWizard, 'accept'>();
  const { t } = useTranslation(['global', 'quote']);

  useEffect(() => {
    initFormValues({
      settledDate: getCurrentDateAsString(),
    });
  }, [initFormValues]);

  return (
    <Box h="10rem">
      <Form form={form}>
        <Text>{t('quote:acceptQuoteProjectModal.descriptionFirstStep')}</Text>

        <Box w="10rem" mt={6}>
          <DateField<QuoteAcceptModalWizard['accept']>
            name="settledDate"
            label={t('quote:fields.settledDate')}
            isRequired
          >
            <Rule.IsRequired />
          </DateField>
        </Box>
      </Form>

      <StepperModal.CloseButton isDisabled={isLoading} />

      <StepperModal.PrimaryButton isLoading={isLoading}>{t('global:words.c.confirm')}</StepperModal.PrimaryButton>
    </Box>
  );
};
