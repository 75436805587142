import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { DrawersStack, GraneetButton, useDrawer, useDrawersStack } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import type { IBillListingResponseDTO, ISupplierInvoice } from '@graneet/business-logic';

import {
  useBankingTransactionLinkBill,
  useBankingTransactionLinkSupplierInvoice,
  useBankingTransactionUnlinkBill,
  useBankingTransactionUnlinkSupplierInvoice,
} from '../../services/banking-transaction.api';
import { EditSupplierInvoiceScreen } from '../../../../screens/purchases/supplier-invoices/EditSupplierInvoiceScreen';

import { BankingTransactionDetailDrawer } from './BankingTransactionDetailDrawer';
import { BankingTransactionAssociateSupplierInvoiceDrawer } from './BankingTransactionAssociateSupplierInvoiceDrawer';
import { BankingTransactionAssociateBillDrawer } from './BankingTransactionAssociateBillDrawer';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { HotKeysDrawerNavigationHeader } from 'features/common/components/HotKeysDrawerNavigationHeader';

const DETAIL_BLOCK_W = '25rem';

interface BankingTransactionDrawerProps {
  id: string | null;

  isOpen: boolean;

  onClose: () => void;

  onChangeFocusClicked: (offset: -1 | 1) => void;

  onBankingTransactionUpdate: () => void;
}

export const BankingTransactionDrawer: FC<BankingTransactionDrawerProps> = ({
  id,
  isOpen,
  onClose,
  onChangeFocusClicked,
  onBankingTransactionUpdate,
}) => {
  const { t } = useTranslation(['banking']);

  const bankingTransactionLinkBillMutation = useBankingTransactionLinkBill();
  const bankingTransactionLinkSupplierInvoiceMutation = useBankingTransactionLinkSupplierInvoice();
  const bankingTransactionUnlinkBillMutation = useBankingTransactionUnlinkBill();
  const bankingTransactionUnlinkSupplierInvoiceMutation = useBankingTransactionUnlinkSupplierInvoice();

  const [displayedData, setDisplayedData] = useState<
    null | 'supplier-invoice-table' | 'supplier-invoice-create' | 'bill-table'
  >(null);
  const drawersStack = useDrawersStack<'detail'>();
  const drawerDetail = useDrawer('detail', drawersStack, {
    isOpen,
    onClose,
  });

  const [selectedBillRows, setSelectedBillRows] = useState<IBillListingResponseDTO[]>([]);
  const [selectedSupplierInvoiceRows, setSelectedSupplierInvoiceRows] = useState<ISupplierInvoice[]>([]);

  const drawer = useMemo(
    () => ({
      ...drawerDetail,
      isOpen: drawerDetail.isOpen && displayedData !== 'supplier-invoice-create',
      // When invoice selection is opened, one click on close must only close the displayed table
      onClose:
        displayedData === null
          ? drawerDetail.onClose
          : () => {
              setDisplayedData(null);
              setSelectedBillRows([]);
              setSelectedSupplierInvoiceRows([]);
            },
    }),
    [displayedData, drawerDetail],
  );

  const onAssociation = useCallback(async () => {
    if (!id || !displayedData) {
      return;
    }

    if (displayedData === 'supplier-invoice-table') {
      await bankingTransactionLinkSupplierInvoiceMutation.mutateAsync({
        id,
        dto: {
          supplierInvoiceId: selectedSupplierInvoiceRows[0].id,
        },
        from: 'transaction-associate-supplier-invoice',
      });
    } else if (displayedData === 'bill-table') {
      await bankingTransactionLinkBillMutation.mutateAsync({
        id,
        dto: {
          billId: selectedBillRows[0].id,
        },
      });
    }

    setSelectedBillRows([]);
    setSelectedSupplierInvoiceRows([]);
    setDisplayedData(null);
    onBankingTransactionUpdate();
  }, [
    bankingTransactionLinkBillMutation,
    bankingTransactionLinkSupplierInvoiceMutation,
    displayedData,
    id,
    onBankingTransactionUpdate,
    selectedBillRows,
    selectedSupplierInvoiceRows,
  ]);

  const onDisassociateBillFromTransaction = useCallback(
    (billId: number) => {
      if (!id) return;

      bankingTransactionUnlinkBillMutation.mutate({
        id,
        billId,
      });
      onBankingTransactionUpdate();
    },
    [bankingTransactionUnlinkBillMutation, id, onBankingTransactionUpdate],
  );

  const onDisassociateSupplierInvoiceFromTransaction = useCallback(
    (supplierInvoiceId: number) => {
      if (!id) return;

      bankingTransactionUnlinkSupplierInvoiceMutation.mutate({
        id,
        supplierInvoiceId,
      });
      onBankingTransactionUpdate();
    },
    [bankingTransactionUnlinkSupplierInvoiceMutation, id, onBankingTransactionUpdate],
  );

  return (
    <>
      <DrawersStack drawersStack={drawersStack}>
        <DrawersStack.Drawer
          title={
            displayedData ? (
              t('banking:bankingTransaction.associateDrawer.title')
            ) : (
              <HotKeysDrawerNavigationHeader onChangeFocusClicked={onChangeFocusClicked} />
            )
          }
          footer={
            displayedData ? (
              <GraneetButton
                onClick={onAssociation}
                isDisabled={selectedBillRows.length === 0 && selectedSupplierInvoiceRows.length === 0}
                variant="primary"
                isLoading={
                  bankingTransactionLinkBillMutation.isPending ||
                  bankingTransactionLinkSupplierInvoiceMutation.isPending
                }
              >
                {t('banking:actions.linkInvoiceToTransaction')}
              </GraneetButton>
            ) : undefined
          }
          drawer={drawer}
          size="fit-content"
        >
          <Flex boxSize="100%" gap={5}>
            <QueryWrapper>
              {id && (
                <Box w={DETAIL_BLOCK_W} overflowY="auto">
                  <BankingTransactionDetailDrawer
                    id={id}
                    onAssociateSupplierInvoiceToTransaction={() => setDisplayedData('supplier-invoice-table')}
                    onDisassociateSupplierInvoiceFromTransaction={onDisassociateSupplierInvoiceFromTransaction}
                    onAssociateBillToTransaction={() => setDisplayedData('bill-table')}
                    onDisassociateBillFromTransaction={onDisassociateBillFromTransaction}
                    onCreateSupplierInvoiceToTransaction={() => {
                      setDisplayedData('supplier-invoice-create');
                    }}
                    onBankingTransactionUpdate={onBankingTransactionUpdate}
                    allowUpdates={!!displayedData}
                  />
                </Box>
              )}

              <Box w={displayedData ? `calc(100vw - ${DETAIL_BLOCK_W})` : 0} h="100%">
                {displayedData === 'supplier-invoice-table' && (
                  <QueryWrapper>
                    {id && (
                      <BankingTransactionAssociateSupplierInvoiceDrawer
                        id={id}
                        onRowSelected={setSelectedSupplierInvoiceRows}
                      />
                    )}
                  </QueryWrapper>
                )}

                {displayedData === 'bill-table' && id && (
                  <QueryWrapper>
                    <BankingTransactionAssociateBillDrawer id={id} onRowSelected={setSelectedBillRows} />
                  </QueryWrapper>
                )}
              </Box>
            </QueryWrapper>
          </Flex>
        </DrawersStack.Drawer>
      </DrawersStack>

      {displayedData === 'supplier-invoice-create' && id && (
        <EditSupplierInvoiceScreen
          context="create"
          onQuit={() => {
            setDisplayedData(null);
          }}
          onCreatedOrEdited={async (supplierInvoice) => {
            await bankingTransactionLinkSupplierInvoiceMutation.mutateAsync({
              id,
              dto: {
                supplierInvoiceId: supplierInvoice.id,
              },
              from: 'transaction-create-supplier-invoice',
            });
            setDisplayedData(null);
          }}
        />
      )}
    </>
  );
};
