export interface FiltersTranslations {
  checkedValue: string;
  uncheckedValue: string;
  minAmountPlaceholder: string;
  maxAmountPlaceholder: string;
  startDatePlaceholder: string;
  endDatePlaceholder: string;
}

export const filtersTranslations: FiltersTranslations = {
  checkedValue: 'Yes',
  uncheckedValue: 'No',
  minAmountPlaceholder: 'Min',
  maxAmountPlaceholder: 'Max',
  startDatePlaceholder: 'From',
  endDatePlaceholder: 'To',
};

export const configureDefaultLabels = (translations: FiltersTranslations) => {
  Object.assign(filtersTranslations, translations);
};
