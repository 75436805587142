import { Box } from '@chakra-ui/react';

export interface AddressProps {
  oneLine?: boolean;

  address: {
    address?: string;

    city?: string;

    postalCode?: string;

    country?: string;
  };
}

export function formatAddress(address: AddressProps['address'], isOneLine: boolean) {
  const separator = isOneLine ? ', ' : '\n';
  const postalCodePart = address.postalCode && `${address.postalCode} `;
  const cityFullPart = (address.postalCode || address.city) && `${separator}${postalCodePart}${address.city}`;
  const countryPart = address.country && `, ${address.country}`;

  return `${address.address}${cityFullPart}${countryPart}`;
}

export const Address = ({ oneLine = false, address: addressObject }: AddressProps) => (
  <Box whiteSpace={!oneLine ? 'pre-line' : undefined}>{formatAddress(addressObject, oneLine)}</Box>
);
