import type { FC } from 'react';
import { useCallback } from 'react';
import type { IProject } from '@graneet/business-logic';
import { FEATURE_FLAGS } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { useSupplierInvoices } from 'features/supplier-invoice/services/supplier-invoice.api';
import { SupplierInvoiceBatchImportButton } from 'features/supplier-invoice/components/SupplierInvoiceBatchImportButton/SupplierInvoiceBatchImportButton';
import { SupplierInvoicesCreateButton } from 'features/supplier-invoice/components/SupplierInvoicesCreateButton';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { SupplierInvoiceListing } from 'features/supplier-invoice/components/SupplierInvoiceListing/SupplierInvoiceListing';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';

export type ViewSupplierInvoicesScreenProps = {
  project: IProject;
};

export const ViewSupplierInvoicesScreen: FC<ViewSupplierInvoicesScreenProps> = ({ project }) => {
  const { t } = useTranslation(['supplierInvoices']);

  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);

  const { createRedirectionWithFilters } = useFiltersQuery();
  const onRowClicked = useCallback(
    (id: number) => {
      createRedirectionWithFilters(`/projects/${project.id}/purchases/supplier-invoices/${id}`, {
        projectId: project.id,
      })();
    },
    [createRedirectionWithFilters, project],
  );

  const supplierInvoices = useSupplierInvoices({
    forcedFilters: {
      projectId: project.id,
    },
  });

  return (
    <SupplierInvoiceListing
      gridId="supplier-invoice-project"
      pagination={supplierInvoices}
      project={project}
      actions={
        <>
          <SupplierInvoiceBatchImportButton onImported={supplierInvoices.refetch} project={project} />
          <SupplierInvoicesCreateButton project={project} />
        </>
      }
      tableProps={{
        countLabel: (count) => t('supplierInvoices:supplierInvoice', { count }),
        sums: {
          amountOnProjectsExVAT: {
            label: t('supplierInvoices:fields.amountExVAT'),
            type: 'currency',
          },
        },
        onRowClicked: (event) => {
          if (event.data && event.api.getFocusedCell()?.column.getId() !== 'approve') {
            onRowClicked(event.data.id);
          }
        },
      }}
      customLayout={{
        invoiceNumber: {
          hide: false,
        },
        type: {
          hide: false,
        },
        name: {
          hide: false,
        },
        billingDate: {
          hide: false,
        },
        supplier: {
          hide: false,
        },
        paymentDate: {
          hide: false,
        },
        amountExVAT: {
          hide: false,
        },
        approve: {
          hide: !hasWorkflowValidationFeatureFlag,
        },
        status: { hide: false },
        hasMissingInformation: {
          hide: false,
        },
      }}
    />
  );
};
