import { v4 } from 'uuid';

import { MenuOption } from '../components/VariableMenu';

export class VariableHeaderOption extends MenuOption {
  text: string;

  constructor(text: string) {
    super(v4(), false);

    this.text = text;
  }
}
