import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ABSENCE_DURATION_POSSIBLE_VALUES } from '@graneet/business-logic';

import { useTimeTrackingContext } from '../../context/TimeTrackingContext';
import { aggregateTimeSlotsRanges } from '../../services/time-tracking.util';

import { TimeTrackingMonthly } from './TimeTrackingMonthly';
import { TimeTrackingWeekly } from './TimeTrackingWeekly';

export const TimeTrackingTable: FC = () => {
  const { t } = useTranslation(['timeTracking']);
  const {
    state: {
      filters: { groupBy, view },
    },
    weekData,
  } = useTimeTrackingContext();

  const aggregatedRanges = useMemo(
    () =>
      aggregateTimeSlotsRanges(
        weekData?.slots ?? {},
        groupBy,
        ABSENCE_DURATION_POSSIBLE_VALUES,
        t('timeTracking:timeTable.away'),
      ),
    [groupBy, t, weekData?.slots],
  );

  return view === 'week' ? (
    <TimeTrackingWeekly
      aggregatedRanges={aggregatedRanges}
      absenceDurationPossibleValues={ABSENCE_DURATION_POSSIBLE_VALUES}
    />
  ) : (
    <TimeTrackingMonthly aggregatedRanges={aggregatedRanges} />
  );
};
