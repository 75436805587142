import type { PaginatedTableProps, PaginatedResponse, SettingsMenuProps } from '@graneet/lib-ui';
import { SettingsMenu, usePaginatedTableSettingsContext } from '@graneet/lib-ui';
import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Unpacked<TData> = TData extends PaginatedResponse<infer U> ? U : never;

type GroupedDataItem<TData> =
  | {
      label: string;
      items: Array<{
        key: keyof TData;
        isDisabled?: boolean;
      }>;
    }
  | {
      key: keyof TData;
      isDisabled?: boolean;
      additionalContent?: ReactNode;
    };

type GroupedData<TData> = {
  label?: string;
  items: Array<GroupedDataItem<TData> | false>;
};

export interface PaginatedTableSettingsProps<TData extends PaginatedResponse<object>> {
  gridId: string;
  columnDefs: PaginatedTableProps<TData>['columnDefs'];
  groupedData: GroupedData<Unpacked<TData>>;
}

export const PaginatedTableSettings = <TData extends PaginatedResponse<object>>({
  gridId,
  columnDefs,
  groupedData,
}: PaginatedTableSettingsProps<TData>) => {
  const { t } = useTranslation(['global']);

  const { settings, updateSettings } = usePaginatedTableSettingsContext<Unpacked<TData>>();

  const groups = useMemo((): SettingsMenuProps['groups'] => {
    const columnDefWithField = columnDefs.filter((col) => 'field' in col); // Filter group columns

    const getItem = (item: GroupedDataItem<Unpacked<TData>>): SettingsMenuProps['groups'][number]['items'][number] => {
      if ('items' in item) {
        return {
          name: item.label ?? '',
          value: true,
          label: item.label ?? '',
          items: item.items.map(getItem),
        };
      }

      const columnDef = columnDefWithField.find((v) => v.field === item.key);

      if (!columnDef) {
        throw new Error('unknown column');
      }

      return {
        name: columnDef.field.toString(),
        label: columnDef.headerName ?? '',
        value: !(settings[gridId]?.find((v) => v.field === columnDef.field)?.hide ?? columnDef.hide),
        // If the column is hide in the user settings but the checkbox is disabled (i.e., the column cannot be hidden),
        // we allow the user to display the column, then the column will remain visible.
        disabled:
          (settings[gridId]?.find((v) => v.field === columnDef.field)?.hide && item.isDisabled
            ? false
            : item.isDisabled) ?? false,
        additionalContent: item.additionalContent,
      };
    };

    return [
      {
        label: groupedData.label,
        items: groupedData.items.filter((v) => v !== false).map(getItem),
      },
    ];
  }, [columnDefs, gridId, groupedData.items, groupedData.label, settings]);

  const onChange = useCallback(
    (name: string, checked: boolean) => {
      const tableSettings = settings[gridId] ?? columnDefs;

      let namesToUpdate: string[];
      if (columnDefs.find((colDef) => 'field' in colDef && colDef.field === name)) {
        namesToUpdate = [name];
      } else {
        const group = groupedData.items
          .filter((v) => v !== false)
          .find((item) => 'items' in item && item.label === name);
        namesToUpdate =
          group && 'items' in group
            ? group.items
                // If the column is hide in the user settings but the checkbox is disabled (i.e., the column cannot be hidden),
                // we allow the user to display the column, then the column will remain visible.
                .filter((i) =>
                  settings[gridId]?.find((v) => v.field === i.key)?.hide && i.isDisabled ? true : !i.isDisabled,
                )
                .map((i) => i.key.toString())
            : [];
      }

      const copy = [...tableSettings];
      namesToUpdate.forEach((value) => {
        const columnDefWithField = columnDefs.filter((col) => 'field' in col);
        const updateIndex = tableSettings.findIndex((col) => col.field === value);
        const updateIndexInitialData = columnDefWithField.findIndex((col) => col.field === value);
        if (updateIndex !== -1) {
          copy[updateIndex].hide = !checked;
        } else if (updateIndex === -1 && updateIndexInitialData !== -1) {
          const newColumn = {
            field: columnDefWithField[updateIndexInitialData].field,
            width: columnDefWithField[updateIndexInitialData]?.width ?? 100,
            hide: !checked,
          };
          copy.splice(updateIndexInitialData + 1, 0, newColumn);
        }
      });
      updateSettings(gridId, copy);
    },
    [columnDefs, gridId, groupedData.items, settings, updateSettings],
  );

  return (
    <SettingsMenu
      isEditable
      variant="ghost"
      icon={
        <>
          <i className="ri-layout-column-line" /> {t('global:table.columnsSettings')}
        </>
      }
      placement="bottom-end"
      groups={groups}
      onChange={onChange}
      size="sm"
    />
  );
};
