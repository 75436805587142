import type { FC } from 'react';
import { useCallback } from 'react';
import type { ISupplierInvoice } from '@graneet/business-logic';
import { formatSupplierInvoiceFileName } from '@graneet/business-logic';
import {
  ActionMenu,
  Card,
  Date,
  File,
  LabeledData,
  Price,
  SimpleDownloadIcon,
  SimpleViewIcon,
  TwoColumns,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { GridItem } from '@chakra-ui/react';

import { formatFileDownloadUrl, formatFilePreviewUrl } from 'features/file/services/file.util';

interface CashOutflowInformationCardProps {
  supplierInvoice: ISupplierInvoice;
}

export const CashOutflowInformationCard: FC<CashOutflowInformationCardProps> = ({ supplierInvoice }) => {
  const invoiceFileName = supplierInvoice ? formatSupplierInvoiceFileName(supplierInvoice) : '';

  const { t } = useTranslation(['global', 'cashOutflow']);
  const onFileView = useCallback(() => {
    if (!supplierInvoice.invoiceFile) return;
    window.open(formatFilePreviewUrl(supplierInvoice.invoiceFile.id));
  }, [supplierInvoice.invoiceFile]);

  const onFileDownload = useCallback(() => {
    if (!supplierInvoice.invoiceFile || !invoiceFileName) return;
    const downloadUrl = formatFileDownloadUrl(supplierInvoice.invoiceFile.id, invoiceFileName);
    window.open(downloadUrl);
  }, [supplierInvoice.invoiceFile, invoiceFileName]);

  return (
    <Card title={t('cashOutflow:cards.informationsCard.title')}>
      <TwoColumns>
        <LabeledData
          label={t('cashOutflow:cards.informationsCard.invoiceNumber')}
          data={supplierInvoice.invoiceNumber}
        />
        <LabeledData
          label={t('cashOutflow:cards.informationsCard.billingDate')}
          data={<Date>{supplierInvoice.billingDate}</Date>}
        />

        <LabeledData
          label={t('cashOutflow:cards.informationsCard.netToBeBilledIncVAT')}
          data={<Price amount={supplierInvoice.amountIncVAT} />}
        />

        {supplierInvoice.invoiceFile && (
          <GridItem colSpan={2}>
            <LabeledData
              label={t('cashOutflow:fields.invoiceFile')}
              data={
                <File
                  name={invoiceFileName}
                  actions={
                    <ActionMenu>
                      <ActionMenu.Action icon={<SimpleViewIcon />} label={t('global:pdf.view')} onClick={onFileView} />
                      <ActionMenu.Action
                        icon={<SimpleDownloadIcon />}
                        label={t('global:pdf.download')}
                        onClick={onFileDownload}
                      />
                    </ActionMenu>
                  }
                />
              }
            />
          </GridItem>
        )}
      </TwoColumns>
    </Card>
  );
};
