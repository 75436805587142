import { roundFloating, divideFloating, multiplyFloating, sumObjects, getAmountPercentage } from '../common/math.util';
import type { IContract } from '../contract/contract.type';
import type { IContractResponseDTO } from '../contract/dtos/contract-response.dto';
import type { ISubProject } from '../sub-project/sub-project.type';
import type { IVatDistribution } from '../vat/vat-distribution.type';
import type { IVatDiscountedBases } from '../vat/vat-bases.type';
import { calculateVATDistributionRatio } from '../vat/vat-rates.util';
import { VAT_METHOD } from '../vat/vat-method.type';
import { getContractsVATDistributionWithDiscountedBases } from '../contract/contract.util';
import { splitBiggestObjectAndOthers } from '../common/object.util';

import { DOWN_PAYMENT_STATUSES, DOWN_PAYMENT_TYPES } from './down-payment.type';
import type { IDownPayment, PartialDownPayment, PartialDownPaymentAmountsAndDistribution } from './down-payment.type';
import type { IDownPaymentResponse } from './dtos';

export const isDownPaymentDraft = (downPayment?: Pick<IDownPayment, 'status'>): boolean =>
  !!downPayment && downPayment.status === DOWN_PAYMENT_STATUSES.DRAFT;

export const isDownPaymentCancelled = (downPayment?: IDownPayment): boolean =>
  !!downPayment && downPayment.status === DOWN_PAYMENT_STATUSES.CANCELLED;

export const isDownPaymentCompleted = (downPayment?: IDownPayment): boolean =>
  !!downPayment && downPayment.status === DOWN_PAYMENT_STATUSES.COMPLETED;

export const isDownPaymentProForma = (downPayment: Pick<IDownPayment, 'status' | 'isInvoiced'>): boolean =>
  isDownPaymentDraft(downPayment) && !!downPayment.isInvoiced;

export const isDownPaymentMatchingInvoiceNumberRequirement = (downPayment: IDownPayment): boolean =>
  downPayment.isInvoiced ? !!downPayment.invoiceNumber : true;

/*
 * Calculate down payment's amount ex. vat from project amount
 */
export const getDownPaymentAmountExVAT = (downPayment: PartialDownPayment, subProject: ISubProject): number => {
  const { amountExVAT, percentage, type } = downPayment;
  if (type === DOWN_PAYMENT_TYPES.AMOUNT) {
    return amountExVAT ?? 0;
  }

  return getAmountPercentage(subProject.amountExVAT, percentage);
};

/*
 * Calculate down payment's vat distribution
 * VAT bases are not used in this method
 */
export const getDownPaymentVATDistributionUsingSumsMethod = (
  downPayment: PartialDownPayment,
  subProject: ISubProject,
): IVatDistribution => {
  const downPaymentAmountExVAT = getDownPaymentAmountExVAT(downPayment, subProject);
  const ratio = divideFloating(downPaymentAmountExVAT, subProject.amountExVAT);

  return (subProject.contractsVATDistribution || []).map(({ vatRate, amount }) => ({
    vatRate,
    amount: roundFloating(multiplyFloating(amount, ratio)),
  }));
};

/*
 * Calculate down payment's vat distribution from project
 * VAT bases are used here, however we have to recalculate discounted bases
 */
export const getDownPaymentVATDistributionUsingBasesMethod = (
  downPayment: PartialDownPayment,
  subProject: ISubProject,
  contracts: (IContract | IContractResponseDTO)[] = [],
): IVatDistribution => {
  const downPaymentAmountExVAT = getDownPaymentAmountExVAT(downPayment, subProject);
  const ratio = divideFloating(downPaymentAmountExVAT, subProject.amountExVAT);
  const vatDistribution = getContractsVATDistributionWithDiscountedBases(contracts);
  return vatDistribution.map(({ vatRate, discountedBase, base }) => {
    // We calculate not rounded discounted base and rounded amount for each vat rate
    const subDiscountedBase = multiplyFloating(discountedBase, ratio);
    const subAmount = roundFloating(multiplyFloating(subDiscountedBase, vatRate));

    // There is no "base" strictly speaking because there is no down payment amount "without" discount
    // However, it is useful for the customer success team for accounting exports
    const subBase = roundFloating(multiplyFloating(base, ratio));

    return {
      vatRate,
      base: subBase,
      amount: subAmount,
    };
  });
};

/*
 * Calculate down payment's vat distribution from project
 * VAT bases (and more precisely discounted bases) are used here
 */
export const getDownPaymentVATDistributionUsingBasesRoundedMethod = (
  downPayment: PartialDownPayment,
  subProject: ISubProject,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  contracts: (IContract | IContractResponseDTO)[] = [],
): IVatDiscountedBases => {
  const downPaymentAmountExVAT = getDownPaymentAmountExVAT(downPayment, subProject);
  const vatDistribution = (subProject.contractsVATDistribution || []) as IVatDiscountedBases;
  const ratio = divideFloating(downPaymentAmountExVAT, subProject.amountExVAT);

  if (subProject.hasReversalOfLiability) {
    return calculateVATDistributionRatio(vatDistribution, ratio);
  }

  // Firstly, it's necessary to exclude vatObject with biggest amount
  const vatDistributionSplit = splitBiggestObjectAndOthers(vatDistribution, 'base');

  // If the project's vat distribution is empty, down payment's one will be too
  if (vatDistributionSplit.biggest === null) {
    return [];
  }

  // Secondly, we calculate proportionally base for each "small" vatObject
  const smallVATObjects = calculateVATDistributionRatio(vatDistributionSplit.others, ratio);

  // Thirdly, the biggest vatObject has to be calculated
  // It's necessary to do that in order to be sure that the sum of discounted bases will be equal to down payment's amount
  const biggestVATRate = vatDistributionSplit.biggest.vatRate;
  const smallBasesTotal = sumObjects(smallVATObjects, 'discountedBase');
  const biggestDiscountedBase = downPaymentAmountExVAT - smallBasesTotal;
  const biggestAmount = roundFloating(multiplyFloating(biggestDiscountedBase, biggestVATRate));

  // There is no down payment amount "without" discount, so it's not necessary to calculate rest as we do for discounted base
  const biggestBase = roundFloating(multiplyFloating(vatDistributionSplit.biggest.base, ratio));

  const biggestVATObject = {
    vatRate: biggestVATRate,
    base: biggestBase,
    discountedBase: biggestDiscountedBase,
    amount: biggestAmount,
  };

  return [...smallVATObjects, biggestVATObject];
};

/*
 * Get all down payment amounts
 */
export const getDownPaymentAmounts = (
  downPayment: PartialDownPayment,
  subProject: ISubProject,
  contracts: (IContract | IContractResponseDTO)[],
): PartialDownPaymentAmountsAndDistribution => {
  const amountExVAT = getDownPaymentAmountExVAT(downPayment, subProject);

  let computeVATMethod;
  if (subProject.vatMethod === VAT_METHOD.SUMS) {
    computeVATMethod = getDownPaymentVATDistributionUsingSumsMethod;
  } else if (subProject.vatMethod === VAT_METHOD.BASES) {
    computeVATMethod = getDownPaymentVATDistributionUsingBasesMethod;
  } else {
    computeVATMethod = getDownPaymentVATDistributionUsingBasesRoundedMethod;
  }

  // third arg "contracts" is only used in BASES method
  const vatDistribution = computeVATMethod(downPayment, subProject, contracts);
  const vatAmount = sumObjects(vatDistribution, 'amount');
  const amountIncVAT = amountExVAT + vatAmount;

  return {
    amountExVAT,
    amountIncVAT,
    vatDistribution,
  };
};

/*
 * Compute the non-cumulative amount to amortize on a down payment based on project progress
 */
export const getDownPaymentProrataAmortization = ({
  cumulativeSubProjectAmountExVAT,
  totalSubProjectAmountExVAT,
  downPaymentAmountIncVAT,
  downPaymentAmountIncVATAmortized,
}: {
  cumulativeSubProjectAmountExVAT: number;
  totalSubProjectAmountExVAT: number;
  downPaymentAmountIncVAT: number;
  downPaymentAmountIncVATAmortized: number;
}): number => {
  const floatingProgressPercentage = divideFloating(cumulativeSubProjectAmountExVAT, totalSubProjectAmountExVAT);
  const cumulativeDownPaymentAmount = roundFloating(
    multiplyFloating(floatingProgressPercentage, downPaymentAmountIncVAT),
  );

  // if the theoretical amount to amortize is negative, we return zero
  return Math.max(cumulativeDownPaymentAmount - downPaymentAmountIncVATAmortized, 0);
};

type DownPaymentCannotBeCancelCause = 'accounting' | 'accounting-payment';
export const canCancelDownPayment = (
  downPayment: IDownPaymentResponse,
): { ok: boolean; causes: DownPaymentCannotBeCancelCause[] } => {
  const result = { ok: true, causes: [] as DownPaymentCannotBeCancelCause[] };
  // @[ff: accounting-standards] remove accounting export
  if (downPayment.accountingExport !== null) {
    result.ok = false;
    result.causes.push('accounting');
  }
  if (downPayment.hasPaymentAccountingExported) {
    result.ok = false;
    result.causes.push('accounting-payment');
  }
  return result;
};
