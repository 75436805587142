import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { IFile } from '@graneet/business-logic';
import { ActionMenu, SimpleDownloadIcon, SimpleViewIcon } from '@graneet/lib-ui';

import { formatFilePreviewUrl, formatFileDownloadUrl, mapPdfToFileItemProps } from 'features/file/services/file.util';
import { FilesCard } from 'features/file/components/FilesCard/FilesCard';

const mapAcceptanceReportToFileItemProps = (acceptanceReportVersion: IFile) => ({
  ...mapPdfToFileItemProps(),
  id: acceptanceReportVersion.id,
  archived: false,
  isGenerated: true,
  previewLink: formatFilePreviewUrl(acceptanceReportVersion.id),
  downloadLink: formatFileDownloadUrl(acceptanceReportVersion.id),
  name: acceptanceReportVersion.name,
});

interface ProjectAcceptanceReportCardProps {
  acceptanceReport: IFile;

  variant?: 'default' | 'compact';
}

export const ProjectAcceptanceReportCard: FC<ProjectAcceptanceReportCardProps> = ({ acceptanceReport, variant }) => {
  const { t } = useTranslation(['global', 'project']);

  const Menu = useCallback(
    ({ previewLink, downloadLink }: { previewLink?: string; downloadLink?: string }) => (
      <ActionMenu>
        <ActionMenu.Action
          icon={<SimpleViewIcon />}
          onClick={() => window.open(previewLink)}
          label={t('global:pdf.view')}
        />
        <ActionMenu.Action
          icon={<SimpleDownloadIcon />}
          onClick={() => window.open(downloadLink)}
          label={t('global:pdf.download')}
        />
      </ActionMenu>
    ),
    [t],
  );

  return (
    <FilesCard title={t('project:acceptanceReport.title')}>
      <FilesCard.FileItem
        {...mapAcceptanceReportToFileItemProps(acceptanceReport)}
        variant={variant}
        rightContent={Menu}
      />
    </FilesCard>
  );
};
