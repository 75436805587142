import type {
  IAccountingConfigUpdateBankAccountDTO,
  IAccountingConfigUpdateBankDTO,
  IAccountingConfigUpdateClientDTO,
  IAccountingConfigUpdateComponentTypeDTO,
  IAccountingConfigUpdateDTO,
  IAccountingConfigUpdatePurchasesDTO,
  IAccountingConfigUpdateSupplierDTO,
  IAccountingConfigUpdateVatDTO,
} from '@graneet/business-logic';
import { isNumberFinite } from '@graneet/business-logic';

import type {
  AccountingJournalEditionBlockForm,
  AccountingPurchasesEditionBlockForm,
  AccountingSalesEditionBlockForm,
  AccountingBankEditionBlockForm,
  AccountingVatEditionBlockForm,
  AccountingClientEditionBlockForm,
  AccountingSupplierEditionBlockForm,
  AccountingConfigBankEditionBlockForm,
  AccountingConfigBankAccountEditionBlockForm,
} from './accounting.form';
import {
  isAccountingAccountPurchasesNameFieldName,
  getIdFromAccountingAccountPurchasesNameFieldName,
  getIdFromAccountingAccountPurchasesFieldName,
  getVatIdFromFieldName,
  isAccountingAccountPurchasesFieldName,
  isAccountingVatAccountWorkFieldName,
  isAccountingVatAccountPurchasesFieldName,
  getVatIdAndTypeFromFieldName,
  isAccountingClientAuxiliaryAccountFieldName,
  getIdAccountingClientAuxiliaryAccountFieldName,
  isAccountingSupplierAuxiliaryAccountFieldName,
  getIdAccountingSupplierAuxiliaryAccountFieldName,
  isAccountingConfigBankLabelFieldName,
  getIdFromAccountingConfigBankLabelFieldName,
  isAccountingConfigBankJournalFieldName,
  getIdFromAccountingConfigBankJournalFieldName,
  isAccountingConfigBankAccountAccountFieldName,
  getIdFromAccountingConfigBankAccountAccountFieldName,
} from './accounting.form';

type FormValues = AccountingJournalEditionBlockForm &
  AccountingSalesEditionBlockForm &
  AccountingPurchasesEditionBlockForm &
  AccountingVatEditionBlockForm &
  AccountingBankEditionBlockForm &
  AccountingConfigBankEditionBlockForm &
  AccountingConfigBankAccountEditionBlockForm &
  AccountingClientEditionBlockForm &
  AccountingSupplierEditionBlockForm;

export const populateDTOFromAccountingConfigForm = (form: Partial<FormValues>): IAccountingConfigUpdateDTO => {
  const dto: IAccountingConfigUpdateDTO = {};

  const componentTypesAccounts = new Map<number, IAccountingConfigUpdateComponentTypeDTO>();
  const purchasesAccounts = new Map<string, IAccountingConfigUpdatePurchasesDTO>();
  const accountingConfigBanks = new Map<string, IAccountingConfigUpdateBankDTO>();
  const accountingConfigBankAccounts = new Map<string, IAccountingConfigUpdateBankAccountDTO>();
  const vatListAccounts = new Map<string, IAccountingConfigUpdateVatDTO>();
  const clientAuxiliaryAccounts = new Set<IAccountingConfigUpdateClientDTO>();
  const supplierAuxiliaryAccounts = new Set<IAccountingConfigUpdateSupplierDTO>();

  Object.keys(form).forEach((name) => {
    if (isAccountingAccountPurchasesFieldName(name)) {
      const id = getIdFromAccountingAccountPurchasesFieldName(name);

      if (isNumberFinite(id)) {
        const existingEntry = componentTypesAccounts.get(id);

        componentTypesAccounts.set(id, {
          ...existingEntry,
          id,
          accountPurchases: form[name] || null,
        });
      } else {
        const existingEntry = purchasesAccounts.get(id);

        purchasesAccounts.set(id, {
          id,
          name: existingEntry?.name ?? undefined,
          account: form[name] ?? null,
        });
      }
    } else if (isAccountingAccountPurchasesNameFieldName(name)) {
      const id = getIdFromAccountingAccountPurchasesNameFieldName(name);

      const existingEntry = purchasesAccounts.get(id);
      purchasesAccounts.set(id, {
        id,
        name: form[name] ?? '',
        account: existingEntry?.account ?? undefined,
      });
    } else if (isAccountingVatAccountPurchasesFieldName(name)) {
      const { vatType, id } = getVatIdAndTypeFromFieldName(name);
      const existingEntry = vatListAccounts.get(id);

      vatListAccounts.set(id, {
        ...existingEntry,
        id,
        [vatType]: form[name] || null,
      });
    } else if (isAccountingVatAccountWorkFieldName(name)) {
      const id = getVatIdFromFieldName(name);
      const existingEntry = vatListAccounts.get(id);

      vatListAccounts.set(id, {
        ...existingEntry,
        id,
        accountWork: form[name] || (null as any),
      });
    } else if (isAccountingClientAuxiliaryAccountFieldName(name)) {
      const id = getIdAccountingClientAuxiliaryAccountFieldName(name);

      clientAuxiliaryAccounts.add({
        id,
        auxiliaryAccount: form[name] || (null as any),
      });
    } else if (isAccountingSupplierAuxiliaryAccountFieldName(name)) {
      const id = getIdAccountingSupplierAuxiliaryAccountFieldName(name);

      supplierAuxiliaryAccounts.add({
        id,
        auxiliaryAccount: form[name] || null,
      });
    } else if (isAccountingConfigBankLabelFieldName(name)) {
      const id = getIdFromAccountingConfigBankLabelFieldName(name);
      const existingEntry = accountingConfigBanks.get(id);

      accountingConfigBanks.set(id, {
        ...existingEntry,
        id,
        label: form[name] || (null as any),
      });
    } else if (isAccountingConfigBankJournalFieldName(name)) {
      const id = getIdFromAccountingConfigBankJournalFieldName(name);
      const existingEntry = accountingConfigBanks.get(id);

      accountingConfigBanks.set(id, {
        ...existingEntry,
        id,
        journal: form[name] || (null as any),
      });
    } else if (isAccountingConfigBankAccountAccountFieldName(name)) {
      const id = getIdFromAccountingConfigBankAccountAccountFieldName(name);
      const existingEntry = accountingConfigBankAccounts.get(id);

      accountingConfigBankAccounts.set(id, {
        ...existingEntry,
        id,
        account: form[name] || (null as any),
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      dto[name as keyof IAccountingConfigUpdateDTO] = form[name as any] as any;
    }
  });

  return {
    ...dto,
    vatListAccounts: [...vatListAccounts.values()],
    componentTypesAccounts: [...componentTypesAccounts.values()],
    clientAuxiliaryAccounts: [...clientAuxiliaryAccounts],
    supplierAuxiliaryAccounts: [...supplierAuxiliaryAccounts],
    purchasesAccounts: [...purchasesAccounts.values()],
    accountingConfigBanks: [...accountingConfigBanks.values()],
    accountingConfigBankAccounts: [...accountingConfigBankAccounts.values()],
  };
};
