import { createContext, useContext } from 'react';
import type { IQuoteComponent, IQuoteComponentResponseDTO } from '@graneet/business-logic';
import type { FormContextApi } from 'graneet-form';

import type { QuoteJobEditModalForm } from '../../quote-job/forms/quote-job-edit-modal.form';

export interface IQuoteComponentContext {
  jobId: undefined | number;

  startAnotherUpdate(): void;

  listenToComponents(listener: (newValue: IQuoteComponent[] | null) => void): () => void;

  setComponents(newComponents: IQuoteComponent[]): void;

  handleComponentUpdate(response: IQuoteComponentResponseDTO, updateJob?: boolean): unknown;

  setForm(form: FormContextApi<QuoteJobEditModalForm>): void;

  listenIsComponentsUpdating(listener: (newValue: boolean) => void): void;
}

const THROW_ERROR = (fnName: keyof IQuoteComponentContext) => () => {
  throw new Error(`No ComponentContext found with call of ${fnName}`);
};

export const ComponentContext = createContext<IQuoteComponentContext>({
  jobId: undefined,
  startAnotherUpdate: THROW_ERROR('startAnotherUpdate'),
  listenToComponents: THROW_ERROR('listenToComponents'),
  setComponents: THROW_ERROR('setComponents'),
  handleComponentUpdate: THROW_ERROR('handleComponentUpdate'),
  setForm: THROW_ERROR('setForm'),
  listenIsComponentsUpdating: THROW_ERROR('listenIsComponentsUpdating'),
});

export const useComponentContext = () => useContext(ComponentContext);
