import { Tabs } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';

import { DisplayNotFoundScreen } from '../../DisplayNotFoundScreen';

import { MailsSettingsTab } from './_tabs/MailsSettingsTab';

import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewUserSettings = () => {
  const { t } = useTranslation(['global']);

  const { updateHeaderTitle } = useHeaderContext();

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.userSettings'), []);
  }, [t, updateHeaderTitle]);

  return (
    <>
      <Tabs
        hasInternalPadding
        fallbackPath="./mails"
        data={[
          {
            id: 'mails',
            title: t('global:settingsNav.user.mails'),
            exact: true,
            path: './mails',
            content: <MailsSettingsTab />,
          },
        ]}
        errorComponent={<DisplayNotFoundScreen />}
      />
    </>
  );
};
