export interface UpsellTranslations {
  compareOffers: string;
  compareOffersLink: string;

  learnMore: string;
  learnMoreLink: string;
}
export const upsellTranslations: UpsellTranslations = {
  compareOffers: 'Compare Offers',
  compareOffersLink: 'https://www.graneet.com/fr/tarifs',

  learnMore: 'Learn More',
  learnMoreLink: 'mailto:email@example.com',
};

export const configureDefaultLabels = (translation: UpsellTranslations) => {
  Object.assign(upsellTranslations, translation);
};
