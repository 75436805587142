import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const ComponentImportIcon: FC<IconProps> = ({ ...otherProps }) => (
  <Icon viewBox="0 0 17 17" {...otherProps}>
    <svg width="17" height="17" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.857 11H10.9999V13.8571H9.57129V11H6.71415V9.57141H9.57129V6.71427H10.9999V9.57141H13.857V11ZM14.5713 4.57141H5.99986C5.62098 4.57141 5.25762 4.72192 4.98971 4.98983C4.7218 5.25774 4.57129 5.6211 4.57129 5.99998V14.5714C4.57129 14.9503 4.7218 15.3137 4.98971 15.5816C5.25762 15.8495 5.62098 16 5.99986 16H14.5713C14.9502 16 15.3135 15.8495 15.5814 15.5816C15.8494 15.3137 15.9999 14.9503 15.9999 14.5714V5.99998C15.9999 5.6211 15.8494 5.25774 15.5814 4.98983C15.3135 4.72192 14.9502 4.57141 14.5713 4.57141Z"
        fill="#BCCCDC"
      />
      <path
        d="M13.7139 3.71434L13.7139 2.28577L3.71387 2.28577C3.33499 2.28577 2.97162 2.43628 2.70371 2.70418C2.43581 2.97209 2.2853 3.33546 2.2853 3.71434L2.2853 13.7143L3.71387 13.7143L3.71387 3.71434L13.7139 3.71434Z"
        fill="#BCCCDC"
      />
      <path
        d="M11.4287 1.42857L11.4287 0L1.42871 -4.37114e-07C1.04983 -4.53675e-07 0.686468 0.150509 0.418558 0.418418C0.150649 0.686327 0.000139324 1.04969 0.000139308 1.42857L0.000138871 11.4286L1.42871 11.4286L1.42871 1.42857L11.4287 1.42857Z"
        fill="#BCCCDC"
      />
    </svg>
  </Icon>
);
