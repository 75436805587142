import type { FC } from 'react';
import { useEffect } from 'react';
import type { IInvoice } from '@graneet/business-logic';
import { isNumberFinite } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Form, HiddenField, useHiddenField, useStepForm } from 'graneet-form';
import {
  DeepTable,
  DragAndDrop,
  formatNumberToVatRate,
  Section,
  Tree,
  useCurrency,
  useDeepTable,
} from '@graneet/lib-ui';

import { InvoiceDeepTableHeader } from 'features/invoice/components/InvoiceDeepTableHeader';
import { InvoiceDeepTableLotLine } from 'features/invoice/components/InvoiceDeepTableLotLine';
import { InvoiceDeepTableItemLine } from 'features/invoice/components/InvoiceDeepTableItemLine/InvoiceDeepTableItemLine';
import { InvoiceDeepTableSeparator } from 'features/invoice/components/InvoiceDeepTableSeparator';
import { InvoiceFooter } from 'features/invoice/components/InvoiceFooter';
import type { InvoiceEditionForm } from 'features/invoice/forms/invoice-edition-form';
import { getItemFieldName } from 'features/invoice/forms/invoice-edition-form';
import type { InvoiceEditionWizard } from 'features/invoice/forms/invoice-edition-wizard';
import type { ContainerTreeContextApi } from 'features/invoice/hooks/useInvoiceTree';

interface EditInvoiceItemsStepProps {
  invoice: IInvoice | null;

  tree: ContainerTreeContextApi;
}

export const EditInvoiceItemsStep: FC<EditInvoiceItemsStepProps> = ({ invoice, tree }) => {
  const hasReversalOfLiability = invoice?.hasReversalOfLiability ?? false;
  const discount = invoice?.discount;

  const { t } = useTranslation(['invoice']);
  const { mapNumberToAmount } = useCurrency();

  const deepTable = useDeepTable({
    templateColumns: '1rem 6rem minmax(15rem,1fr) repeat(5,6rem) 2rem',
    leftContentWidth: 0, // Because we don't deal with sub-lots, yet!
  });

  const { form, initFormValues } = useStepForm<InvoiceEditionWizard, 'container'>();

  // Initialize hidden fields use in the step
  const hasReversalOfLiabilityHiddenField = useHiddenField(form, 'hasReversalOfLiability');
  const vatMethodHiddenField = useHiddenField(form, 'vatMethod');
  const discountTypeHiddenField = useHiddenField(form, 'discountType');
  const discountPercentageHiddenField = useHiddenField(form, 'discountPercentage');
  const discountAmountExVATHiddenField = useHiddenField(form, 'discountAmountExVAT');

  useEffect(() => {
    const itemsValues = Object.values(tree.getInitialTree().leaves).reduce<Partial<InvoiceEditionForm>>((acc, item) => {
      acc[getItemFieldName(item.id, 'code')] = item.code;
      acc[getItemFieldName(item.id, 'description')] = item.description;
      acc[getItemFieldName(item.id, 'unit')] = item.unit;
      acc[getItemFieldName(item.id, 'quantity')] = item.quantity;
      acc[getItemFieldName(item.id, 'unitPrice')] = isNumberFinite(item.unitPrice)
        ? mapNumberToAmount(item.unitPrice)
        : undefined;
      acc[getItemFieldName(item.id, 'vatRate')] = isNumberFinite(item.vatRate)
        ? formatNumberToVatRate(item.vatRate)
        : undefined;

      return acc;
    }, {});

    initFormValues({
      hasReversalOfLiability,
      discountType: discount?.type,
      discountPercentage: discount?.percentage,
      discountAmountExVAT: isNumberFinite(discount?.amountExVAT) ? mapNumberToAmount(discount!.amountExVAT) : undefined,
      ...itemsValues,
    });
  }, [hasReversalOfLiability, initFormValues, discount, mapNumberToAmount, tree]);

  return (
    <Form form={form}>
      <HiddenField {...hasReversalOfLiabilityHiddenField} />
      <HiddenField {...vatMethodHiddenField} />
      <HiddenField {...discountTypeHiddenField} />
      <HiddenField {...discountPercentageHiddenField} />
      <HiddenField {...discountAmountExVATHiddenField} />

      <Section title={t('invoice:steps.container.title')} description={t('invoice:steps.container.description')}>
        <DragAndDrop>
          <DeepTable deepTable={deepTable} noCard>
            <Tree
              tree={tree}
              headerComponent={InvoiceDeepTableHeader}
              nodeComponent={InvoiceDeepTableLotLine}
              leafComponent={InvoiceDeepTableItemLine}
              separatorComponent={InvoiceDeepTableSeparator}
              footerComponent={InvoiceFooter}
            />
          </DeepTable>
        </DragAndDrop>
      </Section>
    </Form>
  );
};
