import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Circle, Text } from '@chakra-ui/react';
import { Card, formatNumberToVatRate, formatPercentage, GotoLink, Price, Table, useCurrency } from '@graneet/lib-ui';
import {
  isDirectPaymentSupplierInvoice as isDirectPaymentSupplierInvoiceFn,
  type SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT,
} from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import { useProjectAreInUserScope } from 'features/project/services/project.api';
import type { EditSupplierInvoiceForm } from 'features/supplier-invoice/forms/edit-supplier-invoice.form';
import { getProjectColor } from 'features/project/services/project-colors.util';

export const AssociatedProjectsCard: FC<{
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT | null;
}> = ({ supplierInvoice }) => {
  const { t } = useTranslation(['global', 'supplierInvoices']);

  const { mapAmountToNumber } = useCurrency();
  const form = useFormContext<EditSupplierInvoiceForm>();
  const { amountExVAT: rawAmountExVAT } = useOnChangeValues(form, ['amountExVAT']);

  const projects = useProjectAreInUserScope(
    (supplierInvoice?.supplierInvoiceProjects ?? []).map(({ project }) => project?.id!),
  );
  const allowedProjects = useMemo(
    () =>
      Object.entries(projects.data)
        .filter(([, isInScope]) => isInScope)
        .map(([projectId]) => parseInt(projectId, 10)),
    [projects.data],
  );

  if (!supplierInvoice?.supplierInvoiceProjects) {
    return null;
  }
  const { supplierInvoiceProjects } = supplierInvoice;
  // Supplier Invoices that are in direct payment have only one related project
  const isDirectPaymentSupplierInvoice =
    isDirectPaymentSupplierInvoiceFn(supplierInvoice) && supplierInvoice?.supplierInvoiceProjects?.length === 1;

  return (
    <Card title={t('supplierInvoices:cards.projectCard.title')}>
      {supplierInvoiceProjects?.length > 0 ? (
        <Box borderRadius="6px" boxShadow="subtle" overflow="hidden">
          <Table templateColumns={['3rem', 'minmax(4rem, 10rem)', '1fr', '1fr', '3fr']} noCard>
            <Table.Header>
              <Table.Cell />
              <Table.Cell>{t('supplierInvoices:cards.projectCard.name')}</Table.Cell>
              <Table.Cell right>{t('supplierInvoices:cards.projectCard.code')}</Table.Cell>
              <Table.Cell right>{t('supplierInvoices:cards.projectCard.allocation')}</Table.Cell>
              <Table.Cell right>{t('supplierInvoices:cards.projectCard.projectAmountExVAT')}</Table.Cell>
            </Table.Header>

            {supplierInvoiceProjects.map(({ percentage, amountExVAT, project: { id, color, name, refCode } = {} }) => (
              <Table.Row key={id}>
                <Table.Cell>
                  <Circle bg={color ? getProjectColor(color) : 'gray.500'} size="1rem" />
                </Table.Cell>

                <Table.Cell variant="ellipsis">
                  {allowedProjects.includes(id!) ? (
                    <GotoLink to={`/projects/${id}/information/summary`} label={name} />
                  ) : (
                    <Text>{name}</Text>
                  )}
                </Table.Cell>

                <Table.Cell variant="ellipsis" right>
                  {refCode}
                </Table.Cell>

                <Table.Cell right>
                  {formatPercentage(formatNumberToVatRate(percentage), { maximumFractionDigits: 2 })}
                </Table.Cell>

                <Table.Cell right>
                  <Price
                    amount={
                      isDirectPaymentSupplierInvoice && rawAmountExVAT
                        ? mapAmountToNumber(rawAmountExVAT ?? 0)
                        : amountExVAT
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table>
        </Box>
      ) : (
        <Text mt={2}>{t('supplierInvoices:cards.projectCard.noAssociateProject')}</Text>
      )}
    </Card>
  );
};
