import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const RoundRightIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 32 33" {...props}>
    <path
      d="M12.8365 9.5C14.2132 9.54191 15.1876 10.0552 15.7912 11.0295C16.3843 12.0143 16.6808 13.2296 16.6596 14.6858C16.6702 16.3096 16.3419 17.5982 15.7171 18.5306C15.0605 19.4525 14.0756 19.9449 12.7623 19.9763C11.4279 19.9344 10.4642 19.4525 9.84995 18.4677C9.23571 17.4934 8.92858 16.2572 8.92858 14.7381C8.92858 13.2191 9.2463 11.9724 9.89232 11.0086C10.5172 10.0238 11.4809 9.54191 12.8365 9.5ZM12.8047 11.2076C12.3387 11.2076 11.9681 11.4905 11.6927 12.0772C11.4173 12.6429 11.2903 13.5543 11.2903 14.7381C11.2797 15.9429 11.4173 16.8334 11.6821 17.3991C11.9575 17.9858 12.3387 18.2686 12.8259 18.2686C13.8002 18.2686 14.298 17.0848 14.3085 14.7381C14.3085 12.4019 13.8108 11.2286 12.8047 11.2076Z"
      fill="currentColor"
    />
    <path
      d="M21.7431 9.5C23.1198 9.54191 24.0942 10.0552 24.6978 11.0295C25.2909 12.0143 25.5874 13.2296 25.5662 14.6858C25.5768 16.3096 25.2485 17.5982 24.6237 18.5306C23.9671 19.4525 22.9822 19.9448 21.6689 19.9763C20.3345 19.9344 19.3708 19.4525 18.7566 18.4677C18.1423 17.4934 17.8352 16.2572 17.8352 14.7381C17.8352 13.2191 18.1529 11.9724 18.7989 11.0086C19.4238 10.0238 20.3875 9.54191 21.7431 9.5ZM21.7113 11.2076C21.2453 11.2076 20.8747 11.4905 20.5993 12.0772C20.3239 12.6429 20.1969 13.5543 20.1969 14.7381C20.1863 15.9429 20.3239 16.8334 20.5887 17.3991C20.8641 17.9858 21.2453 18.2686 21.7325 18.2686C22.7068 18.2686 23.2046 17.0848 23.2152 14.7381C23.2152 12.4019 22.7174 11.2286 21.7113 11.2076Z"
      fill="currentColor"
    />
    <path
      d="M21.7431 22.5476L19.7907 20.5952V22.0595H14.9097V23.0357H19.7907V24.5L21.7431 22.5476Z"
      fill="currentColor"
    />
    <path
      d="M6.97619 17.6667C7.95239 17.6667 7.95239 18.6429 7.95239 18.6429C7.95239 18.6429 7.95239 19.6191 6.97619 19.6191C6 19.6191 6 18.6429 6 18.6429C6 18.6429 6 17.6667 6.97619 17.6667Z"
      fill="currentColor"
    />
  </Icon>
);
