import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'prop-types';
import { formatNumberToVatRate, formatVatRateToNumber, Modal, PercentageField } from '@graneet/lib-ui';
import { Box, HStack, Text } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { useLot } from '../hooks/useLot';
import { useEditLotDefaultVatRate } from '../hooks/useEditLotDefaultVatRate';

import { Rule } from 'features/form/rules/Rule';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const EditQuoteLotDefaultVatRateModal = ({ modal, title, description, fieldDescription, quoteLotId }) => {
  const { t } = useTranslation(['global']);

  const form = useForm();
  const { isValid: isFormValid } = useFormStatus(form);

  const quoteLot = useLot(quoteLotId);
  const editLotDefaultVatRate = useEditLotDefaultVatRate();

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    form.setFormValues({
      defaultVatRate: Number.isFinite(quoteLot?.lot.defaultVatRate)
        ? formatNumberToVatRate(quoteLot.lot.defaultVatRate)
        : null,
    });
  }, [form, quoteLot?.lot.defaultVatRate]);

  const handleDefaultVatRateUpdate = useCallback(async () => {
    const { defaultVatRate } = form.getFormValues();

    setIsUpdating(true);
    await editLotDefaultVatRate(quoteLotId, formatVatRateToNumber(defaultVatRate));
    setIsUpdating(false);
    modal.onClose();
  }, [editLotDefaultVatRate, form, modal, quoteLotId]);

  return (
    <Form form={form}>
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={title}>
        <Text mb={4}>{description}</Text>

        <HStack spacing={3}>
          <Text>{fieldDescription}</Text>

          <Box w="7rem">
            {hasStandardVATRates ? (
              <AccountingVATField name="defaultVatRate" valueScale={100}>
                <Rule.IsRequired />
              </AccountingVATField>
            ) : (
              <PercentageField name="defaultVatRate" min={0} max={100}>
                <Rule.IsRequired />
              </PercentageField>
            )}
          </Box>
        </HStack>

        <Modal.Close isDisabled={isUpdating} />

        <Modal.PrimaryButton onClick={handleDefaultVatRateUpdate} isLoading={isUpdating} isDisabled={!isFormValid}>
          {t('global:words.c.apply')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};

EditQuoteLotDefaultVatRateModal.propTypes = {
  modal: object.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  fieldDescription: string.isRequired,
  quoteLotId: number,
};

EditQuoteLotDefaultVatRateModal.defaultProps = {
  quoteLotId: null,
};
