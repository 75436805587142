import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { Modal, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import { Rule } from 'features/form/rules/Rule';

interface CreateQuoteModalProps {
  isOpen: boolean;

  onClose(): void;

  onSubmit(information: { name: string }): Promise<void>;

  title: string;

  description: string;

  action: string;
}

interface CreateQuoteForm {
  name: string;
}

export const CreateQuoteModal: FC<CreateQuoteModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  description,
  action,
}) => {
  const { t } = useTranslation(['quote']);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<CreateQuoteForm>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues } = form;

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    const values = getFormValues();
    await onSubmit({ name: values.name! });

    setIsLoading(false);
  }, [getFormValues, onSubmit]);

  return (
    <Form form={form}>
      <Modal isOpen={isOpen} onClose={onClose} title={title} size="2xl">
        <Box mb={5}>{description}</Box>
        <TextField<CreateQuoteForm> name="name" label={t('quote:creationForm.fields.name')} isRequired>
          <Rule.IsRequired />
        </TextField>

        <Modal.Close />

        <Modal.PrimaryButton isLoading={isLoading} isDisabled={!isFormValid} onClick={handleSubmit}>
          {action}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
