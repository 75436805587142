import type { FC } from 'react';
import { useMemo } from 'react';
import { Date, GotoLink, PriceAdvanced, Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IContractListingResponseDTO, SubProjectExtendedContract } from '@graneet/business-logic';
import { Text } from '@chakra-ui/react';

import { CONTRACT_FIELDS } from '../../constants/contracts.constant';

interface ContractTableProps<T extends IContractListingResponseDTO | SubProjectExtendedContract> {
  contracts: T[];

  dropDownRender?: FC<{ numberOfContracts: number; contract: T }>;

  hasPagination?: boolean;

  displayGoToQuoteLink: boolean;
}

export const ContractTable = <T extends IContractListingResponseDTO | SubProjectExtendedContract>({
  contracts,
  hasPagination,
  dropDownRender: DropDownRender,
  displayGoToQuoteLink,
}: ContractTableProps<T>) => {
  const { t } = useTranslation(['contracts']);

  const templateColumns = useMemo(
    () => ['11rem', 'minmax(15rem, auto)', '8rem', '8rem', DropDownRender ? '5rem' : ''].filter((row) => row !== ''),
    [DropDownRender],
  );

  return (
    <Table templateColumns={templateColumns} bg="white">
      <Table.Header>
        <Table.Cell sortBy={hasPagination ? CONTRACT_FIELDS.CODE : undefined}>{t('contracts:reference')}</Table.Cell>
        <Table.Cell sortBy={hasPagination ? CONTRACT_FIELDS.NAME : undefined}>
          {t('contracts:fields.contractName')}
        </Table.Cell>
        <Table.Cell sortBy={hasPagination ? CONTRACT_FIELDS.RECEPTION_DATE : undefined} right>
          {t('contracts:receptionDate')}
        </Table.Cell>
        <Table.Cell sortBy={hasPagination ? CONTRACT_FIELDS.TOTAL_AMOUNT_EX_VAT : undefined} right>
          {t('contracts:totalAmountExVAT')}
        </Table.Cell>
        {DropDownRender && <Table.Cell />}
      </Table.Header>

      {contracts.map((contract) => (
        <Table.Row key={contract.id}>
          <Table.Cell variant="ellipsis">{contract.code || ''}</Table.Cell>

          {contract.quote || contract.quoteUUID ? (
            <Table.Cell>
              {displayGoToQuoteLink ? (
                <GotoLink
                  to={
                    contract.quote
                      ? `/opportunities/quotes/${contract.quote.id}/summary`
                      : `/opportunities/quotation/${contract.quoteUUID}/summary`
                  }
                  label={contract.name}
                  whiteSpace="nowrap"
                  fontSize="sm"
                />
              ) : (
                <Text>{contract.name}</Text>
              )}
            </Table.Cell>
          ) : (
            <Table.Cell variant="ellipsis">{contract.name}</Table.Cell>
          )}

          <Table.Cell right>
            <Date>{contract.receptionDate}</Date>
          </Table.Cell>
          <Table.Cell right>
            <PriceAdvanced amount={contract.totalAmountExVAT} />
          </Table.Cell>
          {DropDownRender && (
            <Table.Cell center>
              <DropDownRender numberOfContracts={contracts.length} contract={contract} />
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table>
  );
};
