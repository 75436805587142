import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import type { FEATURE_FLAGS } from '@graneet/business-logic';

import { useFeatureFlag } from '../hooks/useFeatureFlag';

const FeatureContext = createContext<FEATURE_FLAGS>('' as FEATURE_FLAGS);

// - Components

interface FeatureFlagProps {
  feature: FEATURE_FLAGS;
}

const FeatureFlagProvider = ({ feature, children }: PropsWithChildren<FeatureFlagProps>) => {
  const [flag, setFlag] = useState(() => feature);
  useEffect(() => {
    setFlag(() => feature);
  }, [feature]);

  return <FeatureContext.Provider value={flag}>{children}</FeatureContext.Provider>;
};

const OnActive: FC<PropsWithChildren> = ({ children }) => {
  const flag = useContext(FeatureContext);
  const isFeatureActive = useFeatureFlag(flag);

  return isFeatureActive ? <>{children}</> : null;
};
const OnFallback: FC<PropsWithChildren> = ({ children }) => {
  const flag = useContext(FeatureContext);
  const isFeatureActive = useFeatureFlag(flag);

  return !isFeatureActive ? <>{children}</> : null;
};

/**
 * @example
 * ```
 * <MyComponent>
 *
 *   <Feature feature="something-underway">
 *
 *     <Feature.OnActive>
 *       <MyNewFeature />
 *     </Feature.OnActive>
 *
 *     <Feature.OnFallback>
 *       <MyOldFeature />
 *     </Feature.OnFallback>
 *
 *   </Feature>
 *
 * </MyComponent>
 * ```
 */
export const FeatureFlag = Object.assign(FeatureFlagProvider, {
  OnActive,
  OnFallback,
});
