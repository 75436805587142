import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import type { DrawerHandle } from '@graneet/lib-ui';
import { DrawersStack, QuotationDrawer, Tooltip, useDrawer, useDrawersStack, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { QuoteSettingDrawerSelected, type QuoteComposeDTO } from '@org/graneet-bff-client';

import { useQuotationProxyApis } from './quote-common/hooks/useQuoteProxyApis';
import { useQuoteHotKeys } from './quote-hot-key/hooks/useQuoteHotKeys';

import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { QuoteClientEmptyState } from 'features/quotation/quote/components/drawer/client/QuoteClientEmptyState';
import { QuoteHeader } from 'features/quotation/quote/components/header/QuoteHeader';
import { QuoteEmptyStateCard } from 'features/quotation/quote/components/cards/QuoteEmptyStateCard';
import { useAppContext } from 'features/app/contexts/AppContext';
import { QuoteEditTable } from 'features/quotation/quote/components/tables/QuoteEditTable';
import { Loading } from 'features/common/components/Loading';
import { QuoteDetails } from 'features/quotation/quote/components/drawer/details/QuoteDetails';
import { QuoteSellSheet } from 'features/quotation/quote/components/drawer/sell-sheet/QuoteSellSheet';
import { useComponentTypes } from 'features/component-type/services/component-type.api';
import {
  updateQuoteComponentTypes,
  updateQuoteMembers,
  updateQuoteDrawerRef,
  updateQuoteStandardVATRates,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import type { PusherAdapter } from 'config/pusher/SocketManager/adapters/PusherAdapter';
import { getEnvValue } from 'config/env';
import { useSocketManager } from 'config/pusher/userSocketManager';
import { AttachActions } from 'config/pusher/SocketManager/core/usecases/AttachActions';
import { quoteBasicItemCallbacks } from 'features/quotation/quote-basic-item/callbacks/quoteBasicItemCallbacks';
import { quoteComponentCallbacks } from 'features/quotation/quote-component/callbacks/quoteComponentCallbacks';
import { quoteHiddenCostItemCallbacks } from 'features/quotation/quote-hidden-cost/callbacks/quoteHiddenCostItemCallbacks';
import { quoteLotCallbacks } from 'features/quotation/quote-lot/callbacks/quoteLotCallbacks';
import { quoteOptionalItemCallbacks } from 'features/quotation/quote-optional-item/callbacks/quoteOptionalITemCallbacks';
import { quoteSubItemCallbacks } from 'features/quotation/quote-sub-item/callbacks/quoteSubItemCallbacks';
import { useQuoteCallbacks } from 'features/quotation/quote/callbacks/useQuoteCallbacks';
import { useQuoteIsRequestInConflictFromStore } from 'features/quotation/quote-common/hooks/useQuoteIsRequestInConflictFromStore';
import { RequestConflictQuoteModal } from 'features/quotation/quote/components/modals/RequestConflictQuoteModal';
import { QuoteProject } from 'features/quotation/quote/components/drawer/project/QuoteProject';
import { QuoteClient } from 'features/quotation/quote/components/drawer/client/QuoteClient';
import { quoteNodeCallbacks } from 'features/quotation/quote-node/callbacks/quoteNodeCallbaks';
import { quoteSalesDiscountCallbacks } from 'features/quotation/quote-sales-discount/callbacks/quoteSalesDiscountCallbacks';
import { quoteCustomDiscountCallbacks } from 'features/quotation/quote-custom-discount/callbacks/quoteCustomDiscountCallbacks';
import { useStore } from 'store/store';
import { quoteFileCallbacks } from 'features/quotation/quote-common/callbacks/quoteFileCallbacks';
import { QuoteLibraryItemDrawer } from 'features/quotation/quote-library/components/QuoteLibraryItemDrawer';
import { QuoteLibraryComponentDrawer } from 'features/quotation/quote-library/components/QuoteLibraryComponentDrawer';
import { EditQuoteItemNoteModal } from 'features/quotation/quote/components/modals/EditQuoteItemNote';
import { QuoteBatiprixModal } from 'features/quotation/quote-batiprix/components/QuoteBatiprixModal';
import { QuoteHelp } from 'features/quotation/quote/components/drawer/help/QuoteHelp';
import { Error } from 'features/common/components/Error';
import { EditQuotationLotDefaultVatRateModal } from 'features/quotation/quote/components/modals/EditQuotationLotDefaultVatRateModal';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useAccountingStandardVATRates } from 'features/accounting/services/accounting-config.api';

interface QuotationEditProps {
  quoteComposed: QuoteComposeDTO;
  handleOnClose: () => Promise<void>;
}

export const QuotationEdit = ({ quoteComposed, handleOnClose }: QuotationEditProps) => {
  useHideNavbar();
  const drawerRef = useRef<DrawerHandle>(null);
  const { t } = useTranslation(['quote', 'global']);
  const toast = useToast();
  const { pusherProxyApi } = useQuotationProxyApis();
  const quoteClient = useStore((state) => state.quoteClient);
  const setShouldDisplayBeacon = useStore((state) => state.setShouldDisplayBeacon);
  const quoteSetting = useStore((state) => state.quoteSetting);
  const { quoteSettingProxyApi } = useQuotationProxyApis();

  const { quote } = useQuote();

  const drawerIndex = useMemo(() => {
    switch (quoteSetting.drawerSelected) {
      case QuoteSettingDrawerSelected.Sale: {
        return 0;
      }
      case QuoteSettingDrawerSelected.Details: {
        return 2;
      }
      case QuoteSettingDrawerSelected.Project: {
        return 3;
      }
      case QuoteSettingDrawerSelected.Help: {
        return 4;
      }
      case QuoteSettingDrawerSelected.Client:
      default:
        return 1;
    }
  }, [quoteSetting.drawerSelected]);

  const [tabIndex, setTabIndex] = useState(drawerIndex);

  const quoteLibraryDrawersStack = useDrawersStack<'quote-library'>();
  const quoteLibraryImportItemDrawer = useDrawer('quote-library-import-item-drawer', quoteLibraryDrawersStack);
  const quoteLibraryImportComponentDrawer = useDrawer(
    'quote-library-import-component-drawer',
    quoteLibraryDrawersStack,
  );

  const { setIsFullScreen, authToken } = useAppContext();

  const socketManager = useRef<PusherAdapter | undefined>(
    useSocketManager(
      getEnvValue('REACT_APP_PUSHER_APP_KEY'),
      getEnvValue('REACT_APP_PUSHER_AUTHORIZE_URL'),
      getEnvValue('REACT_APP_AUTH_URL'),
      authToken!,
    ),
  );

  const [isOpen, setIsOpen] = useState(quoteSetting.isDrawerExpanded);

  const isRequestInConflict = useQuoteIsRequestInConflictFromStore()();

  const toggleSidebar = useCallback(async () => {
    await quoteSettingProxyApi.updateQuoteSetting(quote.getId(), { isDrawerExpanded: !isOpen });
    setIsOpen((prev) => !prev);
  }, [isOpen, quote, quoteSettingProxyApi]);

  const {
    isOpen: modalIsOpen,
    onOpen,
    onClose,
  } = useDisclosure({
    onClose: handleOnClose,
  });

  useQuoteHotKeys();

  const componentTypes = useComponentTypes();
  useEffect(() => {
    // TODO do not use store for that but directly React Query
    updateQuoteComponentTypes(componentTypes.data);
  }, [componentTypes.data]);

  const standardVATRates = useAccountingStandardVATRates();

  useEffect(() => {
    updateQuoteStandardVATRates(standardVATRates.data);
  }, [standardVATRates.data]);

  useEffect(() => {
    setIsFullScreen(true);
    setShouldDisplayBeacon(false);
    return () => {
      setIsFullScreen(false);
      setShouldDisplayBeacon(true);
    };
  }, [setIsFullScreen, setShouldDisplayBeacon]);

  useEffect(() => {
    if (isRequestInConflict) {
      onOpen();
    }
  }, [isRequestInConflict, onOpen]);

  const quoteCallbacks = useQuoteCallbacks();

  useEffect(() => {
    if (socketManager.current && quote && pusherProxyApi) {
      const attachActions = new AttachActions(socketManager.current, pusherProxyApi, toast, t('global:errors.reload'));
      const quoteId = quote.getId();

      attachActions.execute([
        ...quoteBasicItemCallbacks(quoteId),
        ...quoteComponentCallbacks(quoteId),
        ...quoteHiddenCostItemCallbacks(quoteId),
        ...quoteLotCallbacks(quoteId),
        ...quoteOptionalItemCallbacks(quoteId),
        ...quoteSubItemCallbacks(quoteId),
        ...quoteCallbacks(quoteId),
        ...quoteNodeCallbacks(quoteId),
        ...quoteSalesDiscountCallbacks(quoteId),
        ...quoteCustomDiscountCallbacks(quoteId),
        ...quoteFileCallbacks(quoteId),
      ]);
    }
  }, [pusherProxyApi, quote, quoteCallbacks, t, toast]);

  useEffect(() => {
    updateQuoteDrawerRef(drawerRef);
  }, []);

  useEffect(() => {
    if (socketManager.current && pusherProxyApi) {
      const actionsManager = new AttachActions(socketManager.current, pusherProxyApi, toast, t('global:errors.reload'));
      actionsManager.presence(quote.getId(), () => {
        const members = actionsManager.getMembers();
        if (members) {
          const quoteMembers: string[] = [];
          members.each((member: { id: string; info: { name: string } }) => {
            quoteMembers.push(member.info.name);
          });
          updateQuoteMembers(quoteMembers);
        }
      });
    }
  }, [pusherProxyApi, quote, t, toast]);

  useMemo(async () => {
    let drawerSelected: QuoteSettingDrawerSelected;
    switch (tabIndex) {
      case 0: {
        drawerSelected = QuoteSettingDrawerSelected.Sale;
        break;
      }
      case 2: {
        drawerSelected = QuoteSettingDrawerSelected.Details;
        break;
      }
      case 3: {
        drawerSelected = QuoteSettingDrawerSelected.Project;
        break;
      }
      case 4: {
        drawerSelected = QuoteSettingDrawerSelected.Help;
        break;
      }
      case 1:
      default:
        drawerSelected = QuoteSettingDrawerSelected.Client;
        break;
    }

    if (quoteSetting.drawerSelected !== drawerSelected) {
      await quoteSettingProxyApi.updateQuoteSetting(quote.getId(), { drawerSelected });
    }
  }, [quote, quoteSetting.drawerSelected, quoteSettingProxyApi, tabIndex]);

  return (
    <Flex backgroundColor="#F9FAFB" height="100%" width="100%" overflow="hidden">
      <Flex
        direction="column"
        h="100%"
        width={`calc(100% - ${isOpen ? '21.5rem' : '0rem'})`}
        gap="0.75rem"
        padding="0.75rem"
        overflow="hidden"
        transition="width 0.25s ease-in-out"
      >
        <QuoteHeader onSideBarOpen={toggleSidebar} isOpen={isOpen} />
        {quote.isQuoteEmpty() ? <QuoteEmptyStateCard /> : <QuoteEditTable />}
      </Flex>
      <QuotationDrawer
        ref={drawerRef}
        isOpen={isOpen}
        onOpenSidebar={toggleSidebar}
        onCloseSidebar={toggleSidebar}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        data={[
          {
            label: t('quote:drawer.sale.tab'),
            content: <QuoteSellSheet quoteId={quoteComposed?.id} />,
          },
          {
            label: t('quote:drawer.client.tab'),
            content: quoteClient?.id ? (
              <QuoteClient key={quoteComposed?.id} />
            ) : (
              <QuoteClientEmptyState key={quoteComposed?.id} />
            ),
          },
          {
            label: t('quote:drawer.details.tab'),
            content: <QuoteDetails key={quoteComposed?.id} />,
          },
          {
            label: t('quote:drawer.project.tab'),
            content: <QuoteProject key={quoteComposed?.id} />,
          },
          {
            label: (
              <Tooltip label={t('quote:drawer.help.tab')} shouldWrapChildren>
                <i className="ri-lifebuoy-line" />
              </Tooltip>
            ),
            content: (
              <Suspense fallback={<Loading />}>
                <ErrorBoundary fallback={<Error />}>
                  <QuoteHelp />
                </ErrorBoundary>
              </Suspense>
            ),
          },
        ]}
      />
      <RequestConflictQuoteModal
        isOpen={modalIsOpen}
        onClose={onClose}
        title={t('quote:quotation.modals.requestConflict.title')}
        description={t('quote:quotation.modals.requestConflict.description')}
        action={t('quote:quotation.modals.requestConflict.action')}
      />
      <DrawersStack drawersStack={quoteLibraryDrawersStack}>
        <QuoteLibraryItemDrawer drawer={quoteLibraryImportItemDrawer} />
        <QuoteLibraryComponentDrawer drawer={quoteLibraryImportComponentDrawer} />
      </DrawersStack>
      <EditQuoteItemNoteModal />
      <EditQuotationLotDefaultVatRateModal />
      <QuoteBatiprixModal />
    </Flex>
  );
};
