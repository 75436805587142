import { useFormContext, useOnBlurValues } from 'graneet-form';
import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
  formatDateToString,
  TimePickerField,
  RadioGroupField,
  RequiredText,
  ModalSubtitle,
  formatDateOrEmpty,
  SingleSelectField,
} from '@graneet/lib-ui';
import type { IOvertime, ITimeSlot } from '@graneet/business-logic';
import { ABSENCE_DURATION } from '@graneet/business-logic';
import { Flex, VStack, Box, Text, FormControl } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { OptionProps } from '@graneet/lib-ui';
import dayjs from 'dayjs';

import type { TimeSlotCreateForm, TimeslotOvertimesTypeIdFieldName } from './TimeSlotCreateModalForm';
import {
  getEndHourFieldName,
  getStartHourFieldName,
  getTimeslotOvertimesTypeIdFieldName,
  getTimeslotOvertimesHourFieldName,
  isTimeslotOvertimeTypeIdFieldNameOnDay,
  isDateFieldName,
  getAbsenceTypeFieldName,
  DIFFERENT_WORKING_HOURS,
  SAME_WORKING_HOURS,
} from './TimeSlotCreateModalForm';
import { WorkingHoursBreakDown } from './WorkingHoursBreakDown';

import { divideRangeToDays } from 'features/time-tracking/services/time-tracking.util';

const RadioOption = (props: OptionProps) => (
  <RadioGroupField.Option {...props}>
    {(button: ReactNode, label: string) => (
      <VStack w="100%" align="left">
        <Flex alignItems="center">
          {button}
          {label}
        </Flex>
      </VStack>
    )}
  </RadioGroupField.Option>
);

export type ISelectedTimeslotOvertime = { title: string; id: string }[];

interface WorkingTimeFormProps {
  isOff?: boolean;
  slot?: Partial<ITimeSlot> | null;
  defaultSelectedTimeslotOvertimes?: Record<string, ISelectedTimeslotOvertime>;
  showProject: boolean;
  overtimeTypes: IOvertime[];
  defaultStartHour?: string;
  defaultEndHour?: string;
  defaultNbHours?: number;
}

export const WorkingTimeForm = ({
  isOff,
  slot,
  defaultSelectedTimeslotOvertimes,
  showProject,
  overtimeTypes,
  defaultStartHour,
  defaultEndHour,
  defaultNbHours,
}: WorkingTimeFormProps) => {
  const { t } = useTranslation(['global', 'timeTracking']);
  const defaultOvertimes = useMemo(
    () => ({
      id: 'null',
      title: t('timeTracking:createTimeSlotModal.fields.regularHours'),
    }),
    [t],
  );
  const globalForm = useFormContext<TimeSlotCreateForm>();
  const formValues = useOnBlurValues(globalForm, undefined);
  const [selectedTimeslotOvertimes, setSelectedTimeslotOvertimes] = useState<Record<string, ISelectedTimeslotOvertime>>(
    defaultSelectedTimeslotOvertimes ?? {},
  );

  const days = useMemo(() => {
    const dateFieldNames = Object.keys(formValues).filter(isDateFieldName);
    const selectedDays = new Set<string>();
    dateFieldNames.forEach((dateKey) => {
      const date = formValues[dateKey];
      if (date) {
        divideRangeToDays(date).forEach((d) => {
          selectedDays.add(formatDateToString(d)!);
        });
      }
    });
    return selectedDays;
  }, [formValues]);

  // Default form values
  const fieldsToFill: Partial<TimeSlotCreateForm> = {};
  days.forEach((day) => {
    if (isOff && formValues[getAbsenceTypeFieldName(day)] === undefined && !slot) {
      fieldsToFill[getAbsenceTypeFieldName(day)] = ABSENCE_DURATION.FULL_DAY;
    } else {
      if (formValues[getStartHourFieldName(day)] === undefined) {
        if (defaultStartHour) {
          fieldsToFill[getStartHourFieldName(day)] = defaultStartHour;
        } else {
          fieldsToFill[getStartHourFieldName(day)] =
            slot && slot.startHour ? dayjs(slot.startHour, 'HH:mm:ss').format('HH:mm') : '';
        }
      }
      if (formValues[getEndHourFieldName(day)] === undefined) {
        if (defaultStartHour) {
          fieldsToFill[getEndHourFieldName(day)] = defaultEndHour;
        } else {
          fieldsToFill[getEndHourFieldName(day)] =
            slot && slot.endHour ? dayjs(slot.endHour, 'HH:mm:ss').format('HH:mm') : '';
        }
      }
      if (!slot) {
        if (formValues[getTimeslotOvertimesHourFieldName(day, 0)] === undefined) {
          fieldsToFill[getTimeslotOvertimesHourFieldName(day, 0)] = defaultNbHours;
        }
        if (formValues[getTimeslotOvertimesTypeIdFieldName(day, 0)] === undefined) {
          fieldsToFill[getTimeslotOvertimesTypeIdFieldName(day, 0)] = 'null';
        }
      }
    }
    if (formValues.workingHourType === undefined) {
      fieldsToFill.workingHourType = SAME_WORKING_HOURS;
    }
  });
  if (Object.keys(fieldsToFill).length > 0) {
    globalForm.setFormValues(fieldsToFill);
  }

  const addOvertime = useCallback(
    (day: string) => {
      const allTimeslotOvertimes = Object.keys(formValues).filter((key) =>
        isTimeslotOvertimeTypeIdFieldNameOnDay(key, day),
      ) as TimeslotOvertimesTypeIdFieldName[];
      const selectedOvertimeIds = allTimeslotOvertimes.map((timeslotOvertimeKey) => formValues[timeslotOvertimeKey]);
      const notPresentOvertimeType = [...overtimeTypes, defaultOvertimes].find(
        (overtimeType) => !selectedOvertimeIds.includes(overtimeType.id),
      );

      if (notPresentOvertimeType) {
        const index = allTimeslotOvertimes.length;
        globalForm.setFormValues({
          ...formValues,
          [getTimeslotOvertimesHourFieldName(day, index)]: 0,
          [getTimeslotOvertimesTypeIdFieldName(day, index)]: notPresentOvertimeType.id,
        });
        setSelectedTimeslotOvertimes({
          ...selectedTimeslotOvertimes,
          [day]: [
            ...(selectedTimeslotOvertimes[day] || [defaultOvertimes]),
            {
              title: notPresentOvertimeType.title,
              id: notPresentOvertimeType.id,
            },
          ],
        });
      }
    },
    [formValues, overtimeTypes, defaultOvertimes, globalForm, selectedTimeslotOvertimes],
  );

  const forceChangeWorkingHourType = useCallback(() => {
    // force because we need 2 clicks to change from differentWorkingHour to sameWorkingHour
    if (formValues.workingHourType === SAME_WORKING_HOURS) {
      globalForm.setFormValues({ workingHourType: DIFFERENT_WORKING_HOURS });
    } else {
      globalForm.setFormValues({ workingHourType: SAME_WORKING_HOURS });
    }
  }, [formValues, globalForm]);

  const selectableOvertimeTypes = useMemo(
    () =>
      (isOff ? [defaultOvertimes] : [defaultOvertimes, ...overtimeTypes]).map((overtime) => ({
        label: overtime.title,
        value: overtime.id,
      })),
    [defaultOvertimes, isOff, overtimeTypes],
  );

  const selectableAbsenceTypes = useMemo(
    () => [
      {
        label: t('timeTracking:createTimeSlotModal.fields.fullDay'),
        value: ABSENCE_DURATION.FULL_DAY,
      },
      {
        label: t('timeTracking:createTimeSlotModal.fields.halfDay'),
        value: ABSENCE_DURATION.HALF_DAY,
      },
    ],
    [t],
  );

  let singleDay;
  if ([...days].length <= 1 || formValues.workingHourType === SAME_WORKING_HOURS) {
    const [day] = [...days];
    singleDay = day;
  } else {
    singleDay = undefined;
  }

  return (
    <Box w="100%">
      {[...days].length ? (
        <RequiredText>
          <ModalSubtitle>
            {t(`timeTracking:createTimeSlotModal.fields.${showProject ? 'workingHours' : 'absenceDurationType'}`)}
          </ModalSubtitle>
        </RequiredText>
      ) : null}
      {[...days].length > 1 && (
        <RadioGroupField<TimeSlotCreateForm> name="workingHourType" mb={2} onClick={forceChangeWorkingHourType}>
          <RadioOption
            value={SAME_WORKING_HOURS}
            label={t(`timeTracking:createTimeSlotModal.fields.${!isOff ? 'sameHoursAllDays' : 'sameAllDays'}`)}
          />
          <RadioOption
            value={DIFFERENT_WORKING_HOURS}
            label={t(
              `timeTracking:createTimeSlotModal.fields.${!isOff ? 'differentHoursDayByDay' : 'differentDayByDay'}`,
            )}
          />
        </RadioGroupField>
      )}
      {singleDay && (
        <Box key={singleDay} backgroundColor={`${isOff ? 'inherit' : 'white'}`} p={`${isOff ? 0 : 4}`} rounded="8">
          {!isOff && (
            <VStack spacing="1rem" align="start">
              <Flex gap="2" w="fit-content">
                <TimePickerField<TimeSlotCreateForm>
                  label={t('timeTracking:createTimeSlotModal.fields.startHour')}
                  name={getStartHourFieldName(singleDay)}
                />
                <TimePickerField<TimeSlotCreateForm>
                  label={t('timeTracking:createTimeSlotModal.fields.endHour')}
                  name={getEndHourFieldName(singleDay)}
                />
              </Flex>
            </VStack>
          )}
          {!isOff ? (
            <WorkingHoursBreakDown
              selectedTimeslotOvertimes={selectedTimeslotOvertimes[singleDay] ?? [defaultOvertimes]}
              day={singleDay}
              selectableOvertimeTypes={selectableOvertimeTypes}
              addOvertime={addOvertime}
            />
          ) : (
            <SingleSelectField<TimeSlotCreateForm>
              name={getAbsenceTypeFieldName(singleDay)}
              options={selectableAbsenceTypes}
              isClearable={false}
            />
          )}
        </Box>
      )}
      {formValues.workingHourType === DIFFERENT_WORKING_HOURS && (
        <VStack backgroundColor="white" p="4" rounded="8" alignItems="start">
          {[...days].map((day) => (
            <Flex key={day} align={`${isOff ? 'middle' : 'start'}`} mb={4} justify="space-between" width="full">
              <Flex align="center" gap={2}>
                <Text width="100%" as="span" fontWeight="semibold" whiteSpace="none">
                  {formatDateOrEmpty(day)}
                </Text>
                {!isOff && <TimePickerField name={getStartHourFieldName(day)} />}
                {!isOff && <TimePickerField name={getEndHourFieldName(day)} />}
              </Flex>
              {!isOff ? (
                <Box minW="50%" borderWidth="1px" borderColor="gray.500" borderRadius="md" p={2}>
                  <WorkingHoursBreakDown
                    selectedTimeslotOvertimes={selectedTimeslotOvertimes[day] ?? [defaultOvertimes]}
                    day={day}
                    selectableOvertimeTypes={selectableOvertimeTypes}
                    addOvertime={addOvertime}
                  />
                </Box>
              ) : (
                <FormControl mx={4}>
                  <SingleSelectField<TimeSlotCreateForm>
                    name={getAbsenceTypeFieldName(day)}
                    options={selectableAbsenceTypes}
                    isClearable={false}
                  />
                </FormControl>
              )}
            </Flex>
          ))}
        </VStack>
      )}
    </Box>
  );
};
