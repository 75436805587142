import dayjs from 'dayjs';

import type { ICompany } from './company.type';

const getFiscalDate = (company: Pick<ICompany, 'startDateFiscalYear'>, originDate: dayjs.Dayjs): dayjs.Dayjs => {
  const fiscalDate = dayjs(originDate)
    .startOf('month')
    .set('month', company.startDateFiscalYear - 1);
  if (originDate.get('month') + 1 < company.startDateFiscalYear) {
    return fiscalDate.subtract(1, 'year');
  }
  return fiscalDate;
};

export const getCurrentFiscalDate = (company: Pick<ICompany, 'startDateFiscalYear'>): dayjs.Dayjs =>
  getFiscalDate(company, dayjs());

export const getOffsetAmount = (company: ICompany): number => {
  if (getCurrentFiscalDate(company).isSame(getFiscalDate(company, dayjs(company.createdAt)), 'month')) {
    return company.offsetFirstFiscalPeriod;
  }
  return 0;
};

/**
 * Billing address field is disabled when it is the same as the Headquarters address
 */
export const isHeadquartersSameAsBillingAddress = (
  company: Pick<ICompany, 'headquartersAddress' | 'billingAddress'>,
): boolean =>
  !!company &&
  !!company.headquartersAddress &&
  !!company.billingAddress &&
  company.headquartersAddress.address === company.billingAddress.address &&
  company.headquartersAddress.city === company.billingAddress.city &&
  company.headquartersAddress.postalCode === company.billingAddress.postalCode &&
  company.headquartersAddress.country === company.billingAddress.country;
