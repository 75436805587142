import type { FC } from 'react';
import type { IProjectSubProjectWithStatementsListingDTO } from '@graneet/business-logic';
import { Box, Stack } from '@chakra-ui/react';
import { Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { FinalStatementStatementTableRow } from './FinalStatementStatementTableRow';

interface FinalStatementStatementTableProps {
  subProjects: IProjectSubProjectWithStatementsListingDTO['subProjects'];
}

export const FinalStatementStatementTable: FC<FinalStatementStatementTableProps> = ({ subProjects }) => {
  const { t } = useTranslation(['progressStatement']);

  return (
    <Stack spacing={4}>
      {subProjects.map((subProject, index) => (
        <Box key={subProject.id} borderRadius="6px" boxShadow="subtle" overflow="hidden">
          <Table
            key={subProject.id}
            templateColumns={['minmax(auto, 10rem)', 'minmax(16rem, 1fr)', '9rem', '10rem', '11rem', '8rem']}
            bg="white"
          >
            {index === 0 && (
              <Table.Header>
                <Table.Cell>{t('progressStatement:fields.num')}</Table.Cell>

                <Table.Cell>{t('progressStatement:fields.name')}</Table.Cell>

                <Table.Cell right>{t('progressStatement:fields.amountExVAT')}</Table.Cell>

                <Table.Cell right>{t('progressStatement:fields.billingDate')}</Table.Cell>

                <Table.Cell>{t('progressStatement:fields.cumulativeProgress')}</Table.Cell>

                <Table.Cell center>{t('progressStatement:fields.status')}</Table.Cell>
              </Table.Header>
            )}

            {/* Sub project row */}
            {subProjects.length > 1 && (
              <Table.Row bg="gray.200" textColor="gray.900">
                <Table.Cell gridColumn="span 6">{subProject.name}</Table.Cell>
              </Table.Row>
            )}

            {subProject.credits.map((credit) => (
              <FinalStatementStatementTableRow key={credit.id} type="credit" statement={credit} />
            ))}

            {subProject.progressStatements.map((progressStatement) => (
              <FinalStatementStatementTableRow
                key={progressStatement.id}
                type="progress-statement"
                statement={progressStatement}
              />
            ))}
          </Table>
        </Box>
      ))}
    </Stack>
  );
};
