import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback } from 'react';
import { datesDiff } from '@graneet/business-logic';

interface IsDateRangeShorterThanProps {
  numberOfDays: number;
}

export const IsDateRangeShorterThan: FC<IsDateRangeShorterThanProps> = ({ numberOfDays }) => {
  const { t } = useTranslation(['global']);

  const validate = useCallback(
    (value: FieldValue) => {
      if (!value || !value.startDate || !value.endDate) {
        return true;
      }

      return numberOfDays > Math.abs(datesDiff(value.startDate, value.endDate));
    },
    [numberOfDays],
  );

  return <Rule message={t('global:form.errors.rangeTooLong', { numberOfDays })} validationFn={validate} />;
};
