import type { FC } from 'react';
import { useCallback } from 'react';
import {
  ActionMenu,
  ComponentImportIcon,
  ValuesProvider,
  formatNumberToVatRate,
  useCurrency,
  useDrawer,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'graneet-form';
import type { ILibraryComponent } from '@graneet/business-logic';

import type { OrderTree } from '../../../hooks/useOrderTree';

import type { OrderEditForm } from 'features/order/forms/order-edit-wizard';
import { getOrderItemFieldName } from 'features/order/forms/order-edit-wizard';
import { LibraryComponentImport } from 'features/library-component/components/LibraryComponentImportDrawer';

interface OrderAddComponentsButtonProps {
  tree: OrderTree;
}

export const InternalLibraryComponentImportIntoOrderDrawer: FC<OrderAddComponentsButtonProps> = ({ tree }) => {
  const { t } = useTranslation(['orders']);

  const form = useFormContext<OrderEditForm>();
  const { mapNumberToAmount } = useCurrency();

  const drawer = useDrawer('import-components-drawer');

  const { createLeaf, getDisplayedCurrentTree, getLastLeafOfNode } = tree;

  const importLibraryComponents = useCallback(
    (libraryComponents: ILibraryComponent[]) => {
      const { rootNodeId, nodes } = getDisplayedCurrentTree();
      const lastItemId = getLastLeafOfNode(rootNodeId);

      const { defaultVatRate } = nodes[rootNodeId];
      const formValues: Partial<OrderEditForm> = {};

      libraryComponents.forEach((lbc) => {
        // add leaf
        const id = createLeaf(rootNodeId, lastItemId, {
          vatRate: defaultVatRate,
          unit: lbc.unit,
          description: lbc.description,
          unitPriceExVAT: lbc.unitDisbursementExVAT,
        });

        // fill
        formValues[getOrderItemFieldName(id, 'vatRate')] = formatNumberToVatRate(defaultVatRate);
        formValues[getOrderItemFieldName(id, 'unit')] = lbc.unit;
        formValues[getOrderItemFieldName(id, 'description')] = lbc.description;
        formValues[getOrderItemFieldName(id, 'unitPriceExVAT')] = mapNumberToAmount(lbc.unitDisbursementExVAT);
      });

      form.setFormValues(formValues);
    },
    [createLeaf, form, getDisplayedCurrentTree, getLastLeafOfNode, mapNumberToAmount],
  );

  return (
    <>
      <ActionMenu.Action
        icon={<ComponentImportIcon />}
        label={t('orders:actions.importComponents')}
        onClick={drawer.onOpen}
      />

      <LibraryComponentImport drawer={drawer} onSubmit={importLibraryComponents} />
    </>
  );
};

export const OrderAddComponentButton: FC<OrderAddComponentsButtonProps> = (props) => (
  <ValuesProvider>
    <InternalLibraryComponentImportIntoOrderDrawer {...props} />
  </ValuesProvider>
);
