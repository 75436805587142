import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback } from 'react';

interface IsNotEmptyArrayProps {
  message?: string;
}

export const IsNotEmptyArray: FC<IsNotEmptyArrayProps> = ({ message }) => {
  const { t } = useTranslation(['global']);

  const validationFn = useCallback(
    (value: FieldValue): boolean => value && Array.isArray(value) && value.length > 0,
    [],
  );

  return <Rule message={message || t('global:form.errors.required')} validationFn={validationFn} />;
};
