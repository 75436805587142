import type { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { AmountSummary, formatNumber } from '@graneet/lib-ui';
import { isNumberFinite } from '@graneet/business-logic';

import { useJob } from '../../hooks/useJob';

interface JobEditionModalInformationSummaryProps {
  jobId: number;
}

export const JobEditionModalInformationSummary: FC<JobEditionModalInformationSummaryProps> = ({ jobId }) => {
  const { t } = useTranslation(['quote']);

  const job = useJob(jobId);

  if (!job) return null;

  const hasQuantityAndUnit = isNumberFinite(job.quantity) && job.unit;

  return (
    <Box display="flex" flexDirection="column" height="full">
      <Flex pt={1}>
        <Trans
          t={t}
          i18nKey="quote:jobEdition.summaryLabel"
          values={{
            content: hasQuantityAndUnit ? `${formatNumber(job.quantity!)} ${job.unit}` : ' - ',
          }}
        >
          label_before
          <Text fontWeight="bold" mx={hasQuantityAndUnit ? 1 : 2}>
            label_content
          </Text>
          label_end
        </Trans>
      </Flex>
      <AmountSummary pb={1}>
        <AmountSummary.Item
          label={t('quote:fields.totalDisbursementExVAT')}
          amount={job.disbursementExVAT}
          fontStyle="italic"
          px={0}
          left
        />
        {!job?.isHiddenCost ? (
          <AmountSummary.Item
            label={t('quote:fields.totalPriceExVAT')}
            amount={job.amountExVAT}
            fontStyle="italic"
            px={0}
            left
          />
        ) : null}
      </AmountSummary>
    </Box>
  );
};
