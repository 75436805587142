import type { FC } from 'react';
import { useMemo } from 'react';
import { CircularProgress, Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { ACCOUNTING_JOURNAL } from '@graneet/business-logic';

import type { UseNumberOfInvoicesPerJournal } from './useNumberOfInvoicesPerJournal';

interface AccountingJournalCheckBoxLabelProps {
  journal: ACCOUNTING_JOURNAL;

  numberOfInvoicesPerJournal: UseNumberOfInvoicesPerJournal;
}

export const AccountingJournalCheckBoxLabel: FC<AccountingJournalCheckBoxLabelProps> = ({
  journal,
  numberOfInvoicesPerJournal,
}) => {
  const { t } = useTranslation(['accounting']);
  const translationKey = useMemo(() => {
    switch (journal) {
      case ACCOUNTING_JOURNAL.BANK:
        return 'bankJournal';
      case ACCOUNTING_JOURNAL.SALES:
        return 'salesJournal';
      case ACCOUNTING_JOURNAL.PURCHASES:
        return 'purchasesJournal';
      default:
        throw new Error('Not implemented');
    }
  }, [journal]);

  const numberOfInvoices = useMemo(() => {
    switch (journal) {
      case ACCOUNTING_JOURNAL.BANK:
        return numberOfInvoicesPerJournal.data?.journalBank;
      case ACCOUNTING_JOURNAL.SALES:
        return numberOfInvoicesPerJournal.data?.journalSales;
      case ACCOUNTING_JOURNAL.PURCHASES:
        return numberOfInvoicesPerJournal.data?.journalPurchases;
      default:
        throw new Error('Not implemented');
    }
  }, [
    journal,
    numberOfInvoicesPerJournal.data?.journalBank,
    numberOfInvoicesPerJournal.data?.journalPurchases,
    numberOfInvoicesPerJournal.data?.journalSales,
  ]);

  if (!numberOfInvoicesPerJournal.isLoading && !numberOfInvoicesPerJournal.data) {
    return t(`accounting:exportModal.${translationKey}`);
  }

  return (
    <Flex alignItems="center">
      <Trans
        t={t}
        i18nKey={`accounting:exportModal.${translationKey}WithCount`}
        values={{ availableInvoice: numberOfInvoices?.exportable, totalInvoices: numberOfInvoices?.total }}
      >
        {numberOfInvoicesPerJournal.isLoading ? (
          <CircularProgress isIndeterminate color="gray.800" size={6} ml={2} />
        ) : (
          <Text color="gray.600" pl={2} />
        )}
      </Trans>
    </Flex>
  );
};
