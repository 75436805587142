export interface ChaptersTranslations {
  tableHeader: string;
}

export const chaptersTranslations: ChaptersTranslations = {
  tableHeader: 'In this page',
};

export const configureDefaultLabels = (translations: ChaptersTranslations) => {
  Object.assign(chaptersTranslations, translations);
};
