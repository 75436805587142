import type { IDownPayment } from '../down-payment/down-payment.type';
import { multiplyFloating, roundFloating, sumObjects } from '../common/math.util';
import type { IContract, SubProjectComputedAmounts } from '../contract/contract.type';
import { getContractsVATDistributionWithDiscountedBases } from '../contract/contract.util';
import type { IVatDiscountedBases } from '../vat/vat-bases.type';
import { getTotalVATAmountFromVATDistribution, getWeightedVAT, mergeVATDistribution } from '../vat/vat-rates.util';
import type { IVatDiscountedBase } from '../vat/vat-base.type';
import type { IVatDistribution } from '../vat/vat-distribution.type';
import { VAT_METHOD } from '../vat/vat-method.type';

import { SUB_PROJECT_BILLING_TYPE } from './sub-project.type';
import type { ISubProject } from './sub-project.type';

export const areSubProjectParamsAllSet = (
  subProject: ISubProject,
  { ignoreReversalOfLiability = false }: { ignoreReversalOfLiability: boolean },
): boolean =>
  !!subProject.type &&
  !!subProject.progressStatementTemplate &&
  subProject.hasPriceRevision !== null &&
  (ignoreReversalOfLiability || typeof subProject?.hasReversalOfLiability === 'boolean');

export const areSubProjectParamsCompatibleWithDirectBilling = (
  subProject: ISubProject,
  { ignoreReversalOfLiability = false }: { ignoreReversalOfLiability: boolean },
): boolean =>
  !subProject.hasPriceRevision &&
  !subProject.holdback &&
  (!subProject.deductions || subProject.deductions.length === 0) &&
  (ignoreReversalOfLiability === true || typeof subProject?.hasReversalOfLiability === 'boolean');

/**
 * Vérifie que le sous chantier est en facturation directe
 */
export const isSubProjectInDirectBilling = (subProject?: ISubProject | null): boolean =>
  !!subProject && subProject.billingType === SUB_PROJECT_BILLING_TYPE.DIRECT;

/**
 * Vérifie que le sous chantier est en facturation par situation
 */
export const isSubProjectInProgressBilling = (subProject?: ISubProject | null): boolean =>
  !!subProject && subProject.billingType === SUB_PROJECT_BILLING_TYPE.PROGRESS;

/**
 * Compute sub project VAT distribution with new vat bases rounded method
 */
export const computeSubProjectVATDistributionWithBasesRoundedMethod = (
  contracts: Array<Pick<IContract, 'vatDistribution' | 'discount' | 'totalAmountWithoutDiscountExVAT'>>,
): IVatDiscountedBases => {
  const contractsVATDistribution = contracts.map(({ vatDistribution }) => vatDistribution).flat(1);
  // We make the assumption that each contract VAT distribution contains the rounded discounted base.
  const mergedVATDistributionWithBases = mergeVATDistribution<IVatDiscountedBase>(
    contractsVATDistribution as IVatDiscountedBases,
  );

  // Computing amount with sum of discounted bases
  return mergedVATDistributionWithBases.map(({ base, discountedBase, vatRate }) => {
    const newAmount = roundFloating(multiplyFloating(discountedBase, vatRate));

    return {
      amount: newAmount, // VAT amount (with discount)
      base, // Base of VAT (without discount)
      discountedBase, // Base of VAT (with discount)
      vatRate,
    };
  });
};

/**
 * Compute sub project VAT distribution with new vat bases method
 * discounted bases are not stored here so we have to calculate them for each contract
 */
export const computeSubProjectVATDistributionWithBasesMethod = (
  contracts: Array<Pick<IContract, 'vatDistribution' | 'discount' | 'totalAmountWithoutDiscountExVAT'>>,
): IVatDistribution => {
  const mergedVATDistributionWithBases = getContractsVATDistributionWithDiscountedBases(contracts);

  // Computing discounted amounts with raw discounted bases
  return mergedVATDistributionWithBases.map(({ base, discountedBase, vatRate }) => {
    const newAmount = roundFloating(multiplyFloating(discountedBase, vatRate));

    return {
      amount: newAmount, // VAT amount (with discount)
      base, // Base of VAT (without discount)
      vatRate,
    };
  });
};

/**
 * Compute sub project VAT distribution with old vat sum method
 */
export const computeSubProjectVATDistributionWithSumsMethod = (
  contracts: Array<Pick<IContract, 'vatDistribution' | 'discount' | 'totalAmountWithoutDiscountExVAT'>>,
): IVatDistribution => {
  const contractsVATDistribution = contracts.map(({ vatDistribution }) => vatDistribution).flat(1);
  return mergeVATDistribution(contractsVATDistribution).map(({ vatRate, amount }) => ({
    vatRate,
    amount,
  }));
};

export const computeSubProjectAmountsFromContracts = (
  contracts: Array<
    Pick<IContract, 'vatDistribution' | 'discount' | 'totalAmountWithoutDiscountExVAT' | 'totalAmountExVAT'>
  >,
  subProject: ISubProject,
): Pick<ISubProject, 'amountExVAT' | 'amountIncVAT' | 'amountToBeBilledExVAT' | 'contractsVATDistribution'> => {
  let computeVATMethod;
  if (subProject.vatMethod === VAT_METHOD.SUMS) {
    computeVATMethod = computeSubProjectVATDistributionWithSumsMethod;
  } else if (subProject.vatMethod === VAT_METHOD.BASES) {
    computeVATMethod = computeSubProjectVATDistributionWithBasesMethod;
  } else {
    computeVATMethod = computeSubProjectVATDistributionWithBasesRoundedMethod;
  }

  const contractsVATDistribution = computeVATMethod(contracts);
  const amountExVAT = sumObjects(contracts, 'totalAmountExVAT');
  const previousAmountExVAT = subProject.amountExVAT;
  const amountToBeBilledExVAT = subProject.amountToBeBilledExVAT + amountExVAT - previousAmountExVAT;
  const amountIncVAT = amountExVAT + getTotalVATAmountFromVATDistribution(contractsVATDistribution);

  return {
    amountExVAT,
    amountIncVAT,
    amountToBeBilledExVAT,
    contractsVATDistribution,
  };
};

/**
 * Compute the amount of the weighted VAT for the sub project
 * Computing: sum(TVA * montantHT) / sum(montantHT) = sum(montantDeTVA) / sum(montantDeTVA / TVA)
 * Attention: Le montant dans la distribution est le montant de la TVA et non le montant HT
 */
export const getSubProjectWeightedVAT = (subProject: ISubProject): number => {
  if (
    !subProject.contractsVATDistribution ||
    subProject.contractsVATDistribution.length === 0 ||
    !subProject.hasPriceRevision
  ) {
    return 0;
  }

  const { contractsVATDistribution: vatComponents } = subProject;

  if (vatComponents.length === 1) {
    return vatComponents[0].vatRate;
  }

  return getWeightedVAT(vatComponents);
};

/**
 * To edit contract, new sub project amount can't be less than the following computation:
 * - Down payment amount is less than the sum of all contracts amounts ex VAT
 */
export const getSubProjectAmountsToCheckContractEditionRules = (
  downPayment: IDownPayment | undefined | null,
): SubProjectComputedAmounts => ({
  downPaymentAmountIncVAT: downPayment?.amountIncVAT ?? 0,
});

/*
  After a contract is edited, the subproject must match the following condition :
   - Down payment amount
     - if the amount is negative, the new contract amount must be less than this amount
     - if the amount is equal to 0, there is no restriction
     - if the amount is positive, the new contract amount must be greater than this amount
 */
export const areSubProjectAmountsValidAfterContractEdition = (
  subProject: ISubProject,
  subProjectComputedAmounts: SubProjectComputedAmounts,
  contracts: Array<
    Pick<IContract, 'vatDistribution' | 'discount' | 'totalAmountWithoutDiscountExVAT' | 'totalAmountExVAT'>
  >,
): boolean => {
  const { downPaymentAmountIncVAT } = subProjectComputedAmounts;

  const { amountIncVAT: newProjectAmountIncVAT } = computeSubProjectAmountsFromContracts(contracts, subProject);

  let isDownPaymentRuleValid = true;
  if (downPaymentAmountIncVAT > 0) {
    isDownPaymentRuleValid = newProjectAmountIncVAT >= downPaymentAmountIncVAT;
  }
  if (downPaymentAmountIncVAT < 0) {
    isDownPaymentRuleValid = newProjectAmountIncVAT <= downPaymentAmountIncVAT;
  }

  return isDownPaymentRuleValid;
};
