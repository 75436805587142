import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, useForm } from 'graneet-form';
import { Box, Flex } from '@chakra-ui/react';
import { Container, SavingSpinner, useCurrency } from '@graneet/lib-ui';

import { LibraryJobComponentsListing } from 'features/library-job/components/LibraryJobComponents/LibraryJobComponents';
import { useDebouncedIsSaving } from 'features/common/hooks/useDebouncedIsLoading';
import {
  useLibraryJob,
  useLibraryJobComponents,
  useLibraryJobUpdate,
  useLibraryJobUpdateComponents,
} from 'features/library-job/services/library-job.api';
import type { LibraryJobFieldsForm } from 'features/library-job/types/library-job.type';
import type { LibraryJobEditCardForm } from 'features/library-job/forms/library-job-component.form';
import { getQuantityFieldName, isQuantityFieldName } from 'features/library-job/forms/library-job-component.form';
import { TextEditionCard } from 'features/common/components/TextEditionCard';
import { LibraryJobEditCard } from 'features/library-job/components/LibraryJobEditCard';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewLibraryJobDetailScreen = () => {
  const { t } = useTranslation(['global', 'library']);
  const { updateHeaderTitle } = useHeaderContext();

  const { libraryJobId: rawLibraryJobId } = useParams<{ libraryJobId: string }>();
  const libraryJobId = parseInt(rawLibraryJobId, 10);
  const { mapNumberToAmount } = useCurrency();
  const { isUpdating, startAnotherUpdate } = useDebouncedIsSaving();

  const libraryJob = useLibraryJob(libraryJobId);
  const libraryJobComponents = useLibraryJobComponents(libraryJobId);

  const libraryJobUpdateMutation = useLibraryJobUpdate();
  const libraryJobUpdateComponentsMutation = useLibraryJobUpdateComponents();

  const form = useForm<LibraryJobFieldsForm>({
    onUpdateAfterBlur: (
      name,
      rawValue,
      fielData: { mapValue?(arg: typeof rawValue): string; libraryComponentId: number },
      { setFormValues },
    ) => {
      const { mapValue, libraryComponentId } = fielData;
      const value = mapValue ? mapValue(rawValue) : rawValue;

      startAnotherUpdate();

      if (isQuantityFieldName(name)) {
        libraryJobUpdateComponentsMutation.mutate(
          {
            id: libraryJobId,
            componentId: libraryComponentId,
            dto: {
              quantity: Number(value!),
            },
          },
          {
            onSuccess: (response) => {
              setFormValues({
                unitDisbursementExVAT: mapNumberToAmount(response.libraryJob.unitDisbursementExVAT),
              });
            },
          },
        );
        return;
      }

      libraryJobUpdateMutation.mutate({ id: libraryJobId, dto: { [name]: value } });
    },

    defaultValues: (() => {
      const libraryComponentsQuantities: Partial<LibraryJobEditCardForm> = {};

      libraryJobComponents.data.forEach((libraryJobComponent) => {
        const quantityFieldName = getQuantityFieldName(libraryJobComponent.libraryComponent.id);
        libraryComponentsQuantities[quantityFieldName] = libraryJobComponent.quantity;
      });

      return {
        ...libraryJob.data,
        unitDisbursementExVAT: mapNumberToAmount(libraryJob.data.unitDisbursementExVAT),
        ...libraryComponentsQuantities,
      };
    })(),
  });

  useEffect(() => {
    updateHeaderTitle(libraryJob.data.description || '', [
      {
        name: t('global:nav.library'),
        link: '/opportunities/library/jobs',
      },
    ]);
  }, [t, libraryJob.data.description, updateHeaderTitle]);

  const handleNotesUpdate = useCallback(
    (note: string) => libraryJobUpdateMutation.mutateAsync({ id: libraryJobId, dto: { note } }),
    [libraryJobId, libraryJobUpdateMutation],
  );

  return (
    <Container>
      <Flex mb={6} alignItems="center">
        <Flex textAlign="left">
          <SavingSpinner isSaving={isUpdating} />
        </Flex>
      </Flex>

      <Form form={form}>
        <LibraryJobEditCard libraryJob={libraryJob.data} onLibraryJobChange={startAnotherUpdate} />
        <Box pt={4}>
          <TextEditionCard
            title={t('library:jobModify.notes.title')}
            errorLabel={t('library:jobModify.notes.errorLabel')}
            successLabel={t('library:jobModify.notes.successLabel')}
            placeholder={t('library:jobModify.notes.placeholder')}
            text={libraryJob.data.note}
            onTextEdited={handleNotesUpdate}
            isEditable
            tooltip={t('library:jobModify.notes.tooltip')}
          />
        </Box>
        <Box pt={8}>
          <LibraryJobComponentsListing
            libraryJob={libraryJob.data}
            libraryJobComponents={libraryJobComponents.data}
            onComponentUpdated={startAnotherUpdate}
          />
        </Box>
      </Form>
    </Container>
  );
};
