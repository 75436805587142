import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Text } from '@chakra-ui/react';
import type { ISupplierInvoice, ISupplierInvoiceListingResponseDTO, RequiredByKeys } from '@graneet/business-logic';
import { SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import { Form, HiddenField, useForm, useFormContext, useHiddenField } from 'graneet-form';

import type { OrderEditForm } from '../forms/order-edit-wizard';

import { SingleSelectFieldPaginated } from 'features/common/components/SingleSelectFieldPaginated';
import { SupplierInvoiceComponentTypeBadge } from 'features/supplier-invoice/components/SupplierInvoiceComponentTypeBadge';
import { useSupplierInvoices } from 'features/supplier-invoice/services/supplier-invoice.api';

interface FormValues {
  supplierInvoice: ISupplierInvoiceListingResponseDTO;
}

const Dash: FC = () => (
  <Text margin="0 1rem 0 1rem" color="gray.500">
    -
  </Text>
);

interface ProjectsComponentProps {
  supplierInvoice: ISupplierInvoice;
}

const ProjectsComponent: FC<ProjectsComponentProps> = ({ supplierInvoice }) => {
  const { t } = useTranslation(['orders']);
  const projectsLength = supplierInvoice.supplierInvoiceProjects?.length || null;

  if (!projectsLength) {
    return null;
  }

  const label =
    projectsLength === 1
      ? supplierInvoice?.supplierInvoiceProjects?.[0]?.project?.name
      : t('orders:associate.multipleProjects');

  return (
    <>
      <Dash />

      <Text fontSize="sm" color="gray.500">
        {label}
      </Text>
    </>
  );
};

interface SupplierInvoiceLineProps {
  supplierInvoice: RequiredByKeys<ISupplierInvoice, 'supplierInvoiceItems'>;
}

const SupplierInvoiceLine: FC<SupplierInvoiceLineProps> = ({ supplierInvoice }) => (
  <Stack direction="row" spacing={2} alignItems="baseline">
    <Box minW="7rem" w="7rem">
      <SupplierInvoiceComponentTypeBadge supplierInvoice={supplierInvoice} />
    </Box>

    <Text fontFamily="heading" color="gray.800">
      {supplierInvoice.name}
    </Text>

    {supplierInvoice.invoiceNumber && (
      <>
        <Dash />

        <Text fontSize="sm" color="gray.500">
          {supplierInvoice.invoiceNumber}
        </Text>
      </>
    )}

    <ProjectsComponent supplierInvoice={supplierInvoice} />
  </Stack>
);

interface SupplierInvoicePickerFieldProps {
  isDisabled?: boolean;
}

export const SupplierInvoicePickerField: FC<SupplierInvoicePickerFieldProps> = ({ isDisabled = false }) => {
  const { t } = useTranslation(['orders', 'global']);

  const form = useFormContext<OrderEditForm>();
  const associatedSupplierInvoicesHiddenField = useHiddenField(form, 'associatedSupplierInvoices');

  const { value: associatedSupplierInvoices, setValue: setAssociatedSupplierInvoices } =
    associatedSupplierInvoicesHiddenField;

  const supplierInvoices = useSupplierInvoices(
    useMemo(
      () => ({
        forcedFilters: {
          /**
           * In an order context we can only associate non-direct payment supplier invoices.
           */
          status: [
            SUPPLIER_INVOICE_STATUS.PAID,
            SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID,
            SUPPLIER_INVOICE_STATUS.TO_PAY,
            SUPPLIER_INVOICE_STATUS.TO_PROCESS,
          ],
        },
        storageStrategy: 'state',
      }),
      [],
    ),
  );

  const mapFunction = useCallback(
    (supplierInvoice: RequiredByKeys<ISupplierInvoice, 'supplierInvoiceItems'>) => ({
      label: <SupplierInvoiceLine supplierInvoice={supplierInvoice} />,
      value: supplierInvoice,
    }),
    [],
  );

  const nestedForm = useForm<FormValues>();

  return (
    <>
      <HiddenField {...associatedSupplierInvoicesHiddenField} />

      <Stack spacing={6}>
        {!isDisabled && <Text>{t('orders:associate.pickOrCreate.body.pick')}</Text>}

        <Form form={nestedForm}>
          <SingleSelectFieldPaginated<ISupplierInvoiceListingResponseDTO, FormValues>
            key={associatedSupplierInvoices?.toString()}
            name="supplierInvoice"
            pagination={supplierInvoices}
            placeholder={t('global:words.c.research')}
            mapFunction={mapFunction}
            isDisabled={isDisabled}
            filterFunction={(supplierInvoice) =>
              !(associatedSupplierInvoices || []).find((i) => i.id === supplierInvoice.id)
            }
            onChange={(selectedSupplierInvoice) => {
              if (selectedSupplierInvoice) {
                setAssociatedSupplierInvoices((associatedSupplierInvoices || []).concat(selectedSupplierInvoice.value));
                nestedForm.resetForm();
              }
            }}
          />
        </Form>
      </Stack>
    </>
  );
};
