import type { FC, ReactNode } from 'react';
import { Grid, useRadioGroup } from '@chakra-ui/react';

import type { FormGroupProps } from '../../FormGroup/FormGroup';
import { FormGroup } from '../../FormGroup/FormGroup';

import { SegmentedInputBox } from './SegmentedInputBox';

export interface SegmentedInputGroupProps extends Omit<FormGroupProps, 'onChange'> {
  name: string;
  options: { optionValue: string; optionLabel: ReactNode; optionTooltip?: ReactNode; isDisabled?: boolean }[];
  defaultValue?: string;
  value?: string;
  onChange: (nextValue: string) => void;
}

export const SegmentedInputGroup: FC<SegmentedInputGroupProps> = ({
  options,
  onChange,
  name,
  value,
  defaultValue,
  ...props
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const group = getRootProps();

  return (
    <FormGroup {...props}>
      <Grid
        {...group}
        templateColumns={`repeat(${options.length}, 1fr)`}
        background="gray.50"
        border="1px solid"
        borderColor="gray.200"
        borderRadius={4}
        width="fit-content"
        height="2rem"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        {options.map(({ optionValue, optionLabel, optionTooltip, isDisabled }) => (
          <SegmentedInputBox
            key={optionValue}
            _getRadioProps={getRadioProps}
            value={optionValue}
            label={optionLabel}
            tooltip={optionTooltip}
            isDisabled={isDisabled}
          />
        ))}
      </Grid>
    </FormGroup>
  );
};
