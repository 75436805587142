import type { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import { useFormContext, useOnBlurValues } from 'graneet-form';
import { PriceAdvanced, Table, Price, NumberField, EMPTY_SIGN, RichText } from '@graneet/lib-ui';
import type { ILibraryComponent, ILibraryComponentWithRelations } from '@graneet/business-logic';
import { multiplyFloating, PERMISSION, throwIfKeyIsUndefined } from '@graneet/business-logic';

import { getQuantityFieldName } from '../../forms/library-job-component.form';

import { useHasQuantity } from './useHasQuantity';

import { ComponentTypeBadge } from 'features/component-type/components/ComponentTypeBadge';
import { usePermissions } from 'features/role/hooks/usePermissions';

interface DisbursementExVATPriceProps {
  libraryComponent: ILibraryComponent & { quantity: number | null };
}

const DisbursementExVATPrice: FC<DisbursementExVATPriceProps> = ({ libraryComponent }) => {
  const quantityFieldName = getQuantityFieldName(libraryComponent.id);
  const form = useFormContext<Record<string, number>>();
  const { [quantityFieldName]: quantity } = useOnBlurValues(form, [quantityFieldName]);
  const disbursementExVAT = multiplyFloating(quantity || 0, libraryComponent.unitDisbursementExVAT);

  return <Price amount={disbursementExVAT} />;
};
interface LibraryComponentRowProps {
  libraryComponent: ILibraryComponent & { quantity: number | null };
  renderActionsMenu?: (libraryComponent: ILibraryComponentWithRelations) => ReactNode;
}

export const LibraryJobComponentsRow: FC<LibraryComponentRowProps> = ({ libraryComponent, renderActionsMenu }) => {
  const hasUpdateLibraryPermission = usePermissions([PERMISSION.UPDATE_LIBRARY]);

  const hasQuantity = useHasQuantity(libraryComponent);
  // Use reverse condition to avoid flickering
  const backgroundColor = !hasQuantity && hasUpdateLibraryPermission ? '#FFF7EB' : 'white';

  const quantityFieldName = getQuantityFieldName(libraryComponent.id);

  throwIfKeyIsUndefined(libraryComponent, 'componentType');

  return (
    <Table.Row key={libraryComponent.id} backgroundColor={backgroundColor}>
      <Table.Cell>
        <Box w="7rem">
          <ComponentTypeBadge type={libraryComponent.componentType} />
        </Box>
      </Table.Cell>

      <Table.Cell variant="ellipsis">{libraryComponent.refCode}</Table.Cell>

      <Table.Cell variant="ellipsis">
        <RichText value={libraryComponent.description} />
      </Table.Cell>

      <Table.Cell variant="ellipsis" right>
        {libraryComponent.unit}
      </Table.Cell>

      <Table.Cell right>
        {hasUpdateLibraryPermission ? (
          <NumberField<Record<string, number>>
            name={quantityFieldName}
            data={{ libraryComponentId: libraryComponent.id }}
            inputProps={{ textAlign: 'right' }}
          />
        ) : (
          libraryComponent.quantity || EMPTY_SIGN
        )}
      </Table.Cell>

      <Table.Cell right>
        <PriceAdvanced amount={libraryComponent.unitDisbursementExVAT} />
      </Table.Cell>

      <Table.Cell right>
        <DisbursementExVATPrice libraryComponent={libraryComponent} />
      </Table.Cell>

      {renderActionsMenu && (
        <Table.Cell>{renderActionsMenu(libraryComponent as ILibraryComponentWithRelations)}</Table.Cell>
      )}
    </Table.Row>
  );
};
