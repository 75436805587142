import { useCallback, useMemo } from 'react';
import type { FieldValues } from 'graneet-form';

import type { KeysMatching } from '../../../utils';
import {
  ceilFloating,
  divideFloating,
  floorFloating,
  isNumberFinite,
  MAXIMUM_FRACTIONAL_PART_LENGTH,
  multiplyFloating,
  roundFloating,
} from '../../../utils';
import type { MaskedNumberFieldProps, RoundableNumberField } from '../MaskedNumberField';
import { MaskedNumberField } from '../MaskedNumberField';

export type UnitFieldName = number | null | undefined;

export interface UnitFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, UnitFieldName> = KeysMatching<T, UnitFieldName>,
> extends Omit<MaskedNumberFieldProps<T, K>, 'name' | 'type'>,
    RoundableNumberField {
  name: K;
  unit: string;
  min?: number;
  max?: number;
  forceNegative?: boolean;
}

export const UnitField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, UnitFieldName> = KeysMatching<T, UnitFieldName>,
>({
  name,
  unit,
  min,
  max,
  scale,
  children,
  forceNegative = false,
  ...rest
}: UnitFieldProps<T, K>) => {
  const fractionalPartLength = scale || MAXIMUM_FRACTIONAL_PART_LENGTH;

  const options = useMemo(() => {
    let minValue = isNumberFinite(min) ? ceilFloating(min, fractionalPartLength) : min;
    let maxValue = isNumberFinite(max) ? floorFloating(max, fractionalPartLength) : max;

    if (forceNegative) {
      minValue = minValue && Math.abs(minValue);
      maxValue = maxValue && Math.abs(maxValue);
    }

    return {
      min: minValue,
      max: maxValue,
      scale: fractionalPartLength,
      unit,
      forceNegative,
    };
  }, [min, fractionalPartLength, max, forceNegative, unit]);

  const formatValue = useCallback(
    (value: T[K] | undefined | null) => {
      // When user write '-' in field, imask returns '-0'
      // so we dont want to parse it to not erase user input for 0
      if (scale === undefined || value === '-0' || value === undefined || value === null) {
        return value;
      }

      const factor = 10 ** fractionalPartLength;
      const valueByScale = multiplyFloating(value, factor);
      return divideFloating(roundFloating(valueByScale), factor) as T[K];
    },
    [scale, fractionalPartLength],
  );

  return (
    <MaskedNumberField<T, K> name={name} options={options} formatValue={formatValue} {...rest} type="unit">
      {children}
    </MaskedNumberField>
  );
};
