import type { FC } from 'react';
import { memo, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useAppContext } from 'features/app/contexts/AppContext';
import { getEnvValue } from 'config/env';

export const SentryAnalytics: FC = memo(() => {
  const { currentUser: user } = useAppContext();
  const { auth0ID } = user || {};

  useEffect(() => {
    if (!getEnvValue('REACT_APP_SENTRY_DSN', false)) {
      return;
    }

    if (auth0ID) {
      Sentry.setUser({ id: user?.id.toString() });
      Sentry.setTag('company', user?.company?.id);
    } else {
      Sentry.setUser({ id: undefined });
    }
  }, [user?.id, auth0ID, user?.company?.id]);

  return null;
});
