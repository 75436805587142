import type { FC, ReactNode } from 'react';
import type { BoxProps } from '@chakra-ui/react';
import { Box, Center, useRadio } from '@chakra-ui/react';

import { Tooltip } from '../../Tooltip';

export interface SegmentedInputBoxProps extends Omit<BoxProps, 'onChange'> {
  value: string | number;
  label: ReactNode;
  tooltip: ReactNode | undefined;
  isDisabled?: boolean;
  _getRadioProps?: Function;
}

export const SegmentedInputBox: FC<SegmentedInputBoxProps> = ({
  value,
  label = '',
  tooltip,
  _getRadioProps = () => {},
  isDisabled,
  ...props
}) => {
  const radio = _getRadioProps({ value });
  const { getInputProps, getRadioProps } = useRadio({
    ...props,
    ...radio,
  });
  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" flex={1} h="100%" p={0.5}>
      <input {...input} disabled={isDisabled} />
      <Tooltip label={tooltip}>
        <Center
          {...checkbox}
          h="100%"
          cursor="pointer"
          textColor="greenBrand.baseSecondary"
          fontWeight={600}
          fontSize="smaller"
          px={2}
          borderRadius={4}
          border="1px solid"
          borderColor="transparent"
          _checked={{
            bg: 'white',
            borderColor: 'gray.200',
            color: 'primaryLight',
          }}
          gap={2}
        >
          {label}
        </Center>
      </Tooltip>
    </Box>
  );
};
