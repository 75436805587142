import type { ILibraryComponentWithRelations, PaginatedResponse } from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import {
  PaginatedTable,
  SimplePuzzleIcon,
  type PaginatedTableProps,
  type PaginationQuery,
  PriceAdvanced,
  RichText,
} from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import type { RowSelectedEvent, IRowNode } from '@ag-grid-community/core';

import { ComponentTypeBadge } from 'features/component-type/components/ComponentTypeBadge';

interface LibraryComponentTableProps {
  gridId: string;

  libraryComponents: PaginationQuery<PaginatedResponse<ILibraryComponentWithRelations>>;

  renderActionsMenu?: (libraryJob: ILibraryComponentWithRelations) => ReactNode;

  isResizable?: boolean;

  onRowSelected?: (libraryJobs: ILibraryComponentWithRelations[]) => void;

  nonSelectableIds?: number[];
}

const ComponentTypeCell = ({ data }: { data?: ILibraryComponentWithRelations }) =>
  data ? <ComponentTypeBadge type={data.componentType} /> : null;

const UnitDisbursementExVATCell = ({ data }: { data?: ILibraryComponentWithRelations }) =>
  data ? <PriceAdvanced amount={data.unitDisbursementExVAT} /> : null;

const ComponentDescriptionCell = ({ data }: { data?: ILibraryComponentWithRelations }) =>
  data ? <RichText value={data.description} /> : null;

export const LibraryComponentTable: FC<LibraryComponentTableProps> = ({
  gridId,
  libraryComponents,
  renderActionsMenu,
  isResizable = false,
  onRowSelected,
  nonSelectableIds,
}) => {
  const { t } = useTranslation(['library']);

  const columnDefs = useMemo<PaginatedTableProps<PaginatedResponse<ILibraryComponentWithRelations>>['columnDefs']>(
    () => [
      {
        field: 'componentType',
        headerName: t('library:libraryComponent.fields.type'),
        cellRenderer: ComponentTypeCell,
        sortable: true,
      },
      {
        field: 'refCode',
        headerName: t('library:libraryComponent.fields.refCode'),
        sortable: true,
      },
      {
        field: 'description',
        headerName: t('library:libraryComponent.fields.description'),
        cellRenderer: ComponentDescriptionCell,
        sortable: true,
      },
      {
        field: 'unit',
        headerName: t('library:libraryComponent.fields.unit'),
        sortable: true,
      },
      {
        field: 'unitDisbursementExVAT',
        headerName: t('library:libraryComponent.fields.unitDisbursementExVAT'),
        cellRenderer: UnitDisbursementExVATCell,
        sortable: true,
      },
      {
        field: 'menu' as any,
        headerName: '',
        cellRenderer: (props) => (props.data ? renderActionsMenu?.(props.data) : null),
        hide: !renderActionsMenu,
        width: 20,
        suppressMovable: true,
        resizable: false,
      },
    ],
    [renderActionsMenu, t],
  );

  const onSelectionChanged = useCallback(
    (event: RowSelectedEvent<ILibraryComponentWithRelations>) => {
      const selectedRows = event.api.getSelectedRows();
      onRowSelected?.(selectedRows);
    },
    [onRowSelected],
  );

  const isRowSelectable = useCallback(
    (event: IRowNode<ILibraryComponentWithRelations>) => {
      if (!nonSelectableIds) {
        return true;
      }
      return !nonSelectableIds.includes(event.data?.id ?? -1);
    },
    [nonSelectableIds],
  );

  return (
    <PaginatedTable
      gridId={gridId}
      columnDefs={columnDefs}
      pagination={libraryComponents}
      onRowSelected={onSelectionChanged}
      rowSelection={onRowSelected ? 'multiple' : undefined}
      isRowSelectable={isRowSelectable}
      defaultColDef={{
        resizable: isResizable,
        headerCheckboxSelection: false,
      }}
      zeroState={{
        icon: <SimplePuzzleIcon boxSize={45} />,
        label: <Trans t={t} i18nKey="library:listing.emptyStateLibrary" values={{ email: SUPPORT_EMAIL }} />,
      }}
      emptyState={{ label: t('library:errors.noResultLibraryComponent') }}
    />
  );
};
