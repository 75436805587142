import type { FC, ReactNode } from 'react';
import { Onboarding, SimpleAlertIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

interface ErrorProps {
  message?: ReactNode;
}

export const Error: FC<ErrorProps> = ({ message }) => {
  const { t } = useTranslation(['global']);

  return (
    <Onboarding icon={<SimpleAlertIcon boxSize={45} stroke="red.700" />} my={6}>
      {message || t('global:errors.pageNotLoaded', { email: SUPPORT_EMAIL })}
    </Onboarding>
  );
};
