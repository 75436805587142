import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { getSupplierInvoiceBeEdited } from '../services/supplier-invoices.util';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface UseSupplierInvoiceEditButtonPropsProps {
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;

  allowDirectPaymentEdition?: boolean;
}

export const useSupplierInvoiceEditButtonProps = ({
  supplierInvoice,
  allowDirectPaymentEdition = false,
}: UseSupplierInvoiceEditButtonPropsProps): { display: boolean; tooltip: string | undefined; isDisabled: boolean } => {
  const { t } = useTranslation(['supplierInvoices', 'global']);

  const getCreateButtonProps = useDisabledButtonProps([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  const canBeEdited = useMemo(
    () => getSupplierInvoiceBeEdited(supplierInvoice, t, { allowDirectPaymentEdition }),
    [allowDirectPaymentEdition, supplierInvoice, t],
  );

  const hasAccessToSupplierInvoiceModule = usePermissions([PERMISSION.ACCESS_SUPPLIER_INVOICE]);
  if (!hasAccessToSupplierInvoiceModule) {
    return {
      display: true,
      tooltip: undefined,
      isDisabled: true,
    };
  }

  if (canBeEdited.isDisabled && !canBeEdited.tooltip) {
    return {
      display: true,
      tooltip: undefined,
      isDisabled: true,
    };
  }

  const createButtonProps = getCreateButtonProps();

  return {
    display: true,
    tooltip: canBeEdited.tooltip || createButtonProps.tooltip,
    isDisabled: canBeEdited.isDisabled || createButtonProps.isDisabled,
  };
};
