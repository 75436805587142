import { useCallback, useState } from 'react';
import { Callout, DATE_FORMAT_API, Modal, SimpleAlertIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Text, VStack } from '@chakra-ui/react';

interface UpdateStatementBillingDateModalProps {
  isOpen: boolean;
  onClose(): void;
  currentBillingDate: Date;
  validateStatement(billingDate?: string): Promise<void>;
}

export const UpdateStatementBillingDateModal = ({
  isOpen,
  onClose,
  currentBillingDate,
  validateStatement,
}: UpdateStatementBillingDateModalProps) => {
  const { t } = useTranslation(['global', 'statement']);

  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = useCallback(async () => {
    const date = dayjs().format(DATE_FORMAT_API);
    setIsLoading(true);
    await validateStatement(date);
    setIsLoading(false);

    onClose();
  }, [onClose, validateStatement]);

  const onCancel = useCallback(async () => {
    await validateStatement();
    onClose();
  }, [onClose, validateStatement]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <VStack>
        <Text>
          {t('statement:updateProgressStatementBillingDateModal.description', {
            billingDate: dayjs(currentBillingDate).format('DD/MM/YYYY'),
            todayDate: dayjs(new Date()).format('DD/MM/YYYY'),
          })}
        </Text>

        <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
          {t('statement:updateProgressStatementBillingDateModal.callout')}
        </Callout>
      </VStack>
      <Modal.SecondaryButton onClick={onCancel}>
        {t('statement:updateProgressStatementBillingDateModal.ctaSecondary', {
          billingDate: dayjs(currentBillingDate).format('DD/MM/YYYY'),
        })}
      </Modal.SecondaryButton>

      <Modal.PrimaryButton isLoading={isLoading} onClick={onConfirm}>
        {t('global:words.c.yes')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
