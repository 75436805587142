import type { FC, PropsWithChildren } from 'react';
import { Box, Collapse, Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { SimpleChevronBottomIcon } from '@graneet/lib-ui';

export const Versions: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['global']);

  const { isOpen: isHistoryOpen, onToggle: toggleVersions } = useDisclosure();

  const isChildrenArrayOfLengthZero = Array.isArray(children) && children.length === 0;
  if (!children || isChildrenArrayOfLengthZero) {
    return null;
  }

  return (
    <>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        onClick={toggleVersions}
        cursor="pointer"
        mt={3}
      >
        <Text color="gray.500" fontWeight="600" flex={1} mr={4}>
          <Trans t={t} i18nKey="global:pdf.oldVersions" />
        </Text>

        <Text color="gray.500" fontWeight="600" mr={3}>
          <Trans t={t} i18nKey={`global:pdf.${isHistoryOpen ? 'hide' : 'show'}`} />
        </Text>

        <IconButton
          icon={isHistoryOpen ? <SimpleChevronBottomIcon transform="rotate(-180deg)" /> : <SimpleChevronBottomIcon />}
          fontSize={25}
          mr={1}
          color="gray.500"
          bg="none"
          aria-label={isHistoryOpen ? 'close' : 'open'}
        />
      </Flex>

      {/*
        We need unmountOnExit here to force the content of Collapse to re-render itself on
        each expand, so that a correct text width can be later accessed by inner EllipsisText
       */}
      <Collapse in={isHistoryOpen} animateOpacity unmountOnExit>
        <Box mt={2}>
          {Array.isArray(children)
            ? children.map((child, i, { length }) => (
                <Box key={child.key || i} borderBottom={i < length - 1 ? '1px solid' : ''} borderColor="gray.100">
                  {child}
                </Box>
              ))
            : children}
        </Box>
      </Collapse>
    </>
  );
};
