import { isDirectPaymentSupplierInvoice } from '@graneet/business-logic';
import { Card } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useSupplierInvoiceContext } from '../../hooks/UseSupplierInvoiceContext';
import type { EditSupplierInvoiceForm } from '../../forms/edit-supplier-invoice.form';

import { SupplierField } from 'features/supplier/components/SupplierField';

type SupplierInvoiceSupplierCardProps = {
  supplierDefaultInputValue?: string;
};

export const SupplierInvoiceSupplierCard: FC<SupplierInvoiceSupplierCardProps> = ({ supplierDefaultInputValue }) => {
  const { t } = useTranslation(['supplierInvoices']);
  const { supplierInvoice } = useSupplierInvoiceContext();

  const isEditable = !isDirectPaymentSupplierInvoice(supplierInvoice);

  return (
    <Card title={t('supplierInvoices:cards.supplierCard.title')} isRequired>
      <SupplierField<EditSupplierInvoiceForm>
        name="supplierId"
        defaultInputValue={supplierDefaultInputValue}
        isEditable={isEditable}
      />
    </Card>
  );
};
