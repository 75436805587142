import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';
import type { PaginatedDataContextApi } from '@graneet/lib-ui';
import {
  ListingLayout,
  AsyncTableData,
  PaginatedDataProvider,
  SimpleAlertIcon,
  SimpleFiltersIcon,
  useValuesContext,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';

import { ContactAssociationTable } from '../tables/ContactAssociationTable';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

interface ContactAvailableDataProviderFormProps<T extends IContact> {
  isAllChecked: boolean;
  setIsAllChecked: Dispatch<SetStateAction<boolean>>;
  data: PaginatedDataContextApi<T>;
  isContactDefaultEmailRecipient: (contact: T) => boolean;
  alreadySelectedContacts?: T[];
}
export const ContactAvailableDataProviderForm = <T extends IContact>({
  isAllChecked,
  setIsAllChecked,
  data,
  alreadySelectedContacts = [],
  isContactDefaultEmailRecipient,
}: ContactAvailableDataProviderFormProps<T>) => {
  const { t } = useTranslation(['contacts']);

  const { data: paginatedContacts } = data;

  const { resetValues, setValues, hasValue, getValues, numberOfValues } = useValuesContext<string>();

  const contactsToShow = useMemo(
    () =>
      (paginatedContacts || []).filter((contact) => {
        const isSelected = alreadySelectedContacts.find((contactToCheck) => contact.id === contactToCheck.id);
        if (isSelected) return null;
        return contact;
      }),
    [alreadySelectedContacts, paginatedContacts],
  );

  const handleCheckAll = useCallback(
    () => () => {
      if (isAllChecked) {
        resetValues();
      } else {
        setValues((paginatedContacts || []).map(({ id }) => id));
      }
      setIsAllChecked(!isAllChecked);
    },
    [isAllChecked, setIsAllChecked, setValues, paginatedContacts, resetValues],
  );

  const onCheck = useCallback(
    (id: string) => () => {
      const currentValues = getValues();
      const checked = hasValue(id);
      if (checked) {
        const index = currentValues.indexOf(id);
        currentValues.splice(index, 1);
        setValues([...currentValues]);
        if (numberOfValues() - 1 === 0) {
          setIsAllChecked(false);
        }
      } else {
        setValues([...currentValues, id]);
        setIsAllChecked(true);
      }
    },
    [getValues, hasValue, numberOfValues, setIsAllChecked, setValues],
  );

  return (
    <PaginatedDataProvider data={data}>
      <ListingLayout
        h="auto"
        search={{ placeholder: t('contacts:search') }}
        content={
          <AsyncTableData
            errorState={{
              message: t('contacts:listingError', { email: SUPPORT_EMAIL }),
              icon: <SimpleAlertIcon boxSize="30" />,
            }}
            emptyState={{
              message: t('contacts:noContacts'),
              icon: <SimpleFiltersIcon boxSize="30" />,
              actionMessage: t('contacts:createNewContact'),
            }}
          >
            <ContactAssociationTable
              contacts={contactsToShow ?? []}
              onCheck={onCheck}
              handleCheckAll={handleCheckAll}
              isAllChecked={isAllChecked}
              isContactDefaultEmailRecipient={isContactDefaultEmailRecipient}
            />
          </AsyncTableData>
        }
      />
    </PaginatedDataProvider>
  );
};
