import type { FC } from 'react';
import { useCallback, useLayoutEffect } from 'react';
import { Container, Tabs } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PERMISSION } from '@graneet/business-logic';
import { Box } from '@chakra-ui/react';

import { DisplayNotFoundScreen } from '../../DisplayNotFoundScreen';

import { ClientSummaryTab } from './_tabs/summary/ClientSummaryTab';
import { ClientCardContactAssociateType, ClientInformationsTab } from './_tabs/information/ClientInformationsTab';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useClient, useClientRemove } from 'features/client/services/client.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { ClientCardTypeEnum } from 'features/client/components/cards/ClientCardDetails';
import { ClientDeletionSection } from 'features/client/components/sections/ClientDeletionSection';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

const SCREEN_PERMISSIONS = [PERMISSION.ACCESS_DASHBOARDS];

export const ViewClientScreen: FC = () => {
  const { t } = useTranslation(['global', 'clients']);
  const { updateHeaderTitle } = useHeaderContext();
  const history = useHistory();

  const hasPermissions = usePermissions(SCREEN_PERMISSIONS);

  const { clientId } = useParams<{
    clientId: string;
  }>();

  const client = useClient(parseInt(clientId, 10));

  const clientRemoveMutation = useClientRemove();

  const handleClientDeleted = useCallback(async () => {
    await clientRemoveMutation.mutateAsync(client.data.id, {
      onSuccess: () => {
        history.push('/contacts/clients');
      },
    });
  }, [clientRemoveMutation, client.data.id, history]);

  useLayoutEffect(() => {
    updateHeaderTitle(client.data.enterpriseName, [
      {
        name: t('global:nav.clients'),
        link: '/contacts/clients',
      },
    ]);
  }, [client.data.enterpriseName, updateHeaderTitle, t]);

  return (
    <>
      <Tabs
        hasInternalPadding
        fallbackPath={hasPermissions ? './summary' : './details'}
        data={[
          ...(hasPermissions
            ? [
                {
                  id: 'summary',
                  title: t('clients:menu.summary'),
                  path: './summary',
                  exact: true,
                  content: (
                    <QueryWrapper>
                      <ClientSummaryTab client={client.data} />
                    </QueryWrapper>
                  ),
                },
              ]
            : []),
          {
            id: 'details',
            title: t('clients:menu.details'),
            path: './details',
            exact: true,
            content: (
              <QueryWrapper>
                <Container size="md">
                  <ClientInformationsTab
                    associationType={ClientCardContactAssociateType.CLIENT}
                    type={ClientCardTypeEnum.EDIT}
                    client={client.data}
                  />
                  <Box mt={6}>
                    <ClientDeletionSection client={client.data} onClientDeleted={handleClientDeleted} />
                  </Box>
                </Container>
              </QueryWrapper>
            ),
          },
        ]}
        errorComponent={<DisplayNotFoundScreen />}
      />
    </>
  );
};
