import type { MutableRefObject } from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import type { IContractInfosResponseDTO } from '@graneet/business-logic';
import type { TreeChanges, TreeComputedValues, TreeDataWithRelations } from '@graneet/lib-ui';
import { DeepTable, DragAndDrop, Tree, useDeepTable } from '@graneet/lib-ui';

import { convertContractInfosToTree } from '../services/contract.util';
import type {
  ContractItemComputedValue,
  ContractLotComputedValue,
  IContractInfosItemWithUUID,
  IContractInfosLotWithUUID,
} from '../types/contract.type';
import { ContractDefaultVatRateProvider } from '../contexts/ContractDefaultVatRate';
import { ContractRuleContextProvider } from '../contexts/ContractRuleContext';
import { useContractTree } from '../hooks/tree.hooks';

import { ContractDeepTableFooter } from './ContractDeepTableFooter/ContractDeepTableFooter';
import { ContractDeepTableHeader } from './ContractDeepTableHeader';
import { ContractLotWrapper } from './ContractLotWrapper';
import { ContractDeepTableLotLine } from './ContractDeepTableLotLine/ContractDeepTableLotLine';
import { ContractDeepTableItemLine } from './ContractDeepTableItemLine/ContractDeepTableItemLine';
import { ContractDeepTableSeparator } from './ContractDeepTableSeparator';

import { useStore } from 'store/store';

interface ContractEditionTableProps {
  contractInfos: IContractInfosResponseDTO;

  getCurrentTreeRef: MutableRefObject<
    () => TreeDataWithRelations<IContractInfosLotWithUUID, IContractInfosItemWithUUID>
  >;

  getComputedValuesRef: MutableRefObject<
    () => TreeComputedValues<
      IContractInfosLotWithUUID,
      IContractInfosItemWithUUID,
      ContractLotComputedValue,
      ContractItemComputedValue
    >
  >;

  getTreeChangesRef: MutableRefObject<() => TreeChanges<IContractInfosLotWithUUID, IContractInfosItemWithUUID>>;
}

export const ContractEditionTable = memo<ContractEditionTableProps>(
  ({ contractInfos, getCurrentTreeRef, getTreeChangesRef, getComputedValuesRef }) => {
    const initialTree = useMemo(() => convertContractInfosToTree(contractInfos), [contractInfos]);

    const tree = useContractTree(initialTree);

    const contractDepthMaxDepth = useStore((state) => state.contractDepthMaxDepth);

    const deepTable = useDeepTable({
      templateColumns: '6rem minmax(15rem, 1fr) 5rem 6rem 8rem 5rem 8rem 7rem 7rem 3rem',
      leftContentWidth: 0.5 * contractDepthMaxDepth + 2.5,
    });

    useEffect(() => {
      // eslint-disable-next-line no-param-reassign
      getCurrentTreeRef.current = tree.getCurrentTree;
      // eslint-disable-next-line no-param-reassign
      getComputedValuesRef.current = tree.getComputedValues;
    }, [getComputedValuesRef, getCurrentTreeRef, tree.getComputedValues, tree.getCurrentTree]);

    useEffect(() => {
      // eslint-disable-next-line no-param-reassign
      getTreeChangesRef.current = tree.getChanges;
    }, [getTreeChangesRef, tree.getChanges]);

    const autoNumberingSetIsActivated = useStore((state) => state.autoNumberingSetIsActivated);

    useEffect(() => {
      autoNumberingSetIsActivated(contractInfos.contract.isAutomaticNumberingActivated);
    }, [contractInfos.contract.isAutomaticNumberingActivated, autoNumberingSetIsActivated]);

    const FooterComponent = useCallback(() => <ContractDeepTableFooter />, []);

    return (
      <DragAndDrop>
        <DeepTable deepTable={deepTable} noCard>
          <ContractDefaultVatRateProvider tree={tree}>
            <ContractRuleContextProvider contractInfos={contractInfos} tree={tree}>
              <Tree
                tree={tree}
                headerComponent={ContractDeepTableHeader}
                nodeWrapperComponent={ContractLotWrapper}
                nodeComponent={ContractDeepTableLotLine}
                leafComponent={ContractDeepTableItemLine}
                separatorComponent={ContractDeepTableSeparator}
                footerComponent={FooterComponent}
              />
            </ContractRuleContextProvider>
          </ContractDefaultVatRateProvider>
        </DeepTable>
      </DragAndDrop>
    );
  },
);
