/* eslint-disable no-bitwise */
import type { MouseEventHandler, FC, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  UNDO_COMMAND,
  REDO_COMMAND,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  $isRootOrShadowRoot,
  $isTextNode,
  FORMAT_ELEMENT_COMMAND,
  $createParagraphNode,
} from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { Box, Flex, HStack, Portal } from '@chakra-ui/react';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { $getSelectionStyleValueForProperty, $patchStyleText, $selectAll, $setBlocksType } from '@lexical/selection';
import { $isHeadingNode, HeadingNode } from '@lexical/rich-text';

import { Tooltip } from '../../../../../Tooltip';
import { toolbarTranslations } from '../../../../configureDefaultLabels';
import type { RichTextBitwise } from '../../../../constants';
import { hasRichTextConfiguration } from '../../../../constants/rich-text-configuration.constant';

import { RICH_TEXT_ICON_PROPS } from './icon-props.constant';
import { getRichTextShortcutTranslation } from './shortcut.util';
import { FloatingLinkEditor, getSelectedNode } from './FloatingLinkEditor';
import { ColorPicker } from './ColorPicker';
import type { BlockType } from './BlockOptions';
import { BlockOptions } from './BlockOptions';

export interface ToolbarProps {
  hasBoxShadow?: boolean;

  richTextBitwise: RichTextBitwise | undefined;

  toolbarContent?: ReactNode[];
}

export const Toolbar: FC<ToolbarProps> = ({ hasBoxShadow = true, richTextBitwise, toolbarContent }) => {
  const [editor] = useLexicalComposerContext();

  const [activeEditor, setActiveEditor] = useState(editor);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isLink, setIsLink] = useState(false);

  const [format, setFormat] = useState<{
    bold: boolean;
    italic: boolean;
    underline: boolean;
    strikethrough: boolean;
    superscript: boolean;
    blockType: BlockType;
    color: string | null;
    fill: string | null;
  }>({
    bold: false,
    italic: false,
    underline: false,
    strikethrough: false,
    superscript: false,
    blockType: null,
    color: null,
    fill: null,
  });

  const onFillColorSelected = useCallback(
    (value: string | null) => {
      activeEditor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const newStyle = { 'background-color': value };
          $patchStyleText(selection, newStyle);
        }
      });
    },
    [activeEditor],
  );

  const onUndoClicked = useCallback(
    (shouldDispatch: boolean) =>
      shouldDispatch
        ? () => {
            editor.dispatchCommand(UNDO_COMMAND, undefined);
          }
        : undefined,
    [editor],
  );

  const onRedoClicked = useCallback(
    (shouldDispatch: boolean) =>
      shouldDispatch
        ? () => {
            editor.dispatchCommand(REDO_COMMAND, undefined);
          }
        : undefined,
    [editor],
  );

  const onBoldClicked = useCallback(() => {
    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
  }, [activeEditor]);

  const onItalicClicked = useCallback(() => {
    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
  }, [activeEditor]);

  const onUnderlineClicked = useCallback(() => {
    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
  }, [activeEditor]);

  const onStrikethroughClicked = useCallback(() => {
    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
  }, [activeEditor]);

  const onSuperscriptClicked = useCallback(() => {
    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript');
  }, [activeEditor]);

  const onBulletListClicked = useCallback(() => {
    if (format.blockType !== 'bullet') {
      activeEditor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      activeEditor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }, [activeEditor, format.blockType]);

  const onNumberedListClicked = useCallback(() => {
    if (format.blockType !== 'number') {
      activeEditor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      activeEditor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }, [activeEditor, format.blockType]);

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $selectAll(selection);
        selection.getNodes().forEach((node) => {
          if ($isTextNode(node)) {
            node.setFormat(0);
            node.setStyle('');
          }
        });
        $setBlocksType(selection, () => $createParagraphNode());
      }
      activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
    });
  }, [activeEditor]);

  const onFontColorSelected = useCallback(
    (value: string | null) => {
      activeEditor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const newStyle = { color: value };
          $patchStyleText(selection, newStyle);
        }
      });
    },
    [activeEditor],
  );

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      let blockType: null | BlockType = null;
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
          blockType = parentList ? parentList.getListType() : element.getListType();
        }
        if ($isHeadingNode(element)) {
          const parentList = $getNearestNodeOfType<HeadingNode>(anchorNode, HeadingNode);
          blockType = parentList?.getTag() || null;
        }
      }

      const color =
        $getSelectionStyleValueForProperty(selection, 'color')
          ?.match(/\((.*)\)/)
          ?.pop() || null;

      const fill =
        $getSelectionStyleValueForProperty(selection, 'background-color')
          ?.match(/\((.*)\)/)
          ?.pop() || null;

      // Update text format
      setFormat({
        bold: selection.hasFormat('bold'),
        italic: selection.hasFormat('italic'),
        underline: selection.hasFormat('underline'),
        strikethrough: selection.hasFormat('strikethrough'),
        superscript: selection.hasFormat('superscript'),
        blockType,
        color,
        fill,
      });

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [activeEditor]);

  /**
   * Keep editor up to date
   */
  useEffect(
    () =>
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          setActiveEditor(newEditor);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    [editor, updateToolbar],
  );

  useEffect(
    () =>
      mergeRegister(
        activeEditor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        activeEditor.registerCommand(
          CAN_UNDO_COMMAND,
          (payload) => {
            setCanUndo(payload);
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        ),
        activeEditor.registerCommand(
          CAN_REDO_COMMAND,
          (payload) => {
            setCanRedo(payload);
            return false;
          },
          COMMAND_PRIORITY_CRITICAL,
        ),
      ),
    [activeEditor, editor, updateToolbar],
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isCtrlOrCmd = event.ctrlKey || event.metaKey;
      if (isCtrlOrCmd && event.key === 'k') {
        event.preventDefault();
        insertLink();
      }
      if (isCtrlOrCmd && event.key === '\\') {
        event.preventDefault();
        clearFormatting();
      }
    },
    [clearFormatting, insertLink],
  );

  useEffect(() => {
    const keyDownListener = (event: KeyboardEvent) => handleKeyDown(event);
    document.addEventListener('keydown', keyDownListener);

    return () => {
      document.removeEventListener('keydown', keyDownListener);
    };
  }, [handleKeyDown]);

  /*
   * Prevent on blur events on color picker click to not trigger the on blur input event.
   */
  const preventDefaultOnMouseDown = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
  }, []);

  return (
    <>
      <Box onMouseDown={preventDefaultOnMouseDown}>
        <Flex
          p={1}
          borderRadius={8}
          boxShadow={hasBoxShadow ? '0px 2px 10px rgba(0, 0, 0, 0.08)' : undefined}
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            {hasRichTextConfiguration(richTextBitwise, 'block') && (
              <BlockOptions editor={editor} blockType={format.blockType} />
            )}

            {hasRichTextConfiguration(richTextBitwise, 'undo-redo') && (
              <Tooltip label={getRichTextShortcutTranslation('undo')} shouldWrapChildren placement="bottom-start">
                <Box
                  {...RICH_TEXT_ICON_PROPS(canUndo)}
                  onClick={onUndoClicked(canUndo)}
                  cursor={canUndo ? 'pointer' : 'not-allowed'}
                >
                  <i className="ri-arrow-go-back-line" />
                </Box>
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'undo-redo') && (
              <Tooltip label={getRichTextShortcutTranslation('redo')} shouldWrapChildren placement="bottom-start">
                <Box
                  {...RICH_TEXT_ICON_PROPS(canRedo)}
                  onClick={onRedoClicked(canRedo)}
                  cursor={canRedo ? 'pointer' : 'not-allowed'}
                >
                  <i className="ri-arrow-go-forward-line" />
                </Box>
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'style') && (
              <Tooltip label={getRichTextShortcutTranslation('bold')} shouldWrapChildren placement="bottom-start">
                <Box {...RICH_TEXT_ICON_PROPS(format.bold)} onClick={onBoldClicked}>
                  <i className="ri-bold" />
                </Box>
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'style') && (
              <Tooltip label={getRichTextShortcutTranslation('italic')} shouldWrapChildren placement="bottom-start">
                <Box {...RICH_TEXT_ICON_PROPS(format.italic)} onClick={onItalicClicked}>
                  <i className="ri-italic" />
                </Box>
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'style') && (
              <Tooltip label={getRichTextShortcutTranslation('underline')} shouldWrapChildren placement="bottom-start">
                <Box {...RICH_TEXT_ICON_PROPS(format.underline)} onClick={onUnderlineClicked}>
                  <i className="ri-underline" />
                </Box>
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'strikethrough') && (
              <Tooltip label={toolbarTranslations.strikethrough} shouldWrapChildren placement="bottom-start">
                <Box {...RICH_TEXT_ICON_PROPS(format.strikethrough)} onClick={onStrikethroughClicked}>
                  <i className="ri-strikethrough" />
                </Box>
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'superscript') && (
              <Tooltip label={toolbarTranslations.superscript} shouldWrapChildren placement="bottom-start">
                <Box {...RICH_TEXT_ICON_PROPS(format.superscript)} onClick={onSuperscriptClicked}>
                  <i className="ri-superscript" />
                </Box>
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'bullet') && (
              <Tooltip label={toolbarTranslations.bulletList} shouldWrapChildren placement="bottom-start">
                <Box {...RICH_TEXT_ICON_PROPS(format.blockType === 'bullet')} onClick={onBulletListClicked}>
                  <i className="ri-list-unordered" />
                </Box>
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'numbered') && (
              <Tooltip label={toolbarTranslations.numberedList} shouldWrapChildren placement="bottom-start">
                <Box {...RICH_TEXT_ICON_PROPS(format.blockType === 'number')} onClick={onNumberedListClicked}>
                  <i className="ri-list-ordered" />
                </Box>
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'color') && (
              <Tooltip label={toolbarTranslations.textColor} shouldWrapChildren placement="bottom-start">
                <ColorPicker currentColor={format.color} onColorChanged={onFontColorSelected} />
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'fill') && (
              <Tooltip label={toolbarTranslations.fillColor} shouldWrapChildren placement="bottom-start">
                <ColorPicker fill currentColor={format.fill} onColorChanged={onFillColorSelected} />
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'link') && (
              <Tooltip label={toolbarTranslations.link} shouldWrapChildren placement="bottom-start">
                <Flex
                  {...RICH_TEXT_ICON_PROPS(isLink)}
                  onClick={insertLink}
                  alignItems="center"
                  justifyContent="center"
                >
                  <i className="ri-link" />
                </Flex>
              </Tooltip>
            )}

            {hasRichTextConfiguration(richTextBitwise, 'clear-formatting') && (
              <Tooltip
                label={getRichTextShortcutTranslation('clearFormatting')}
                shouldWrapChildren
                placement="bottom-start"
              >
                <Box {...RICH_TEXT_ICON_PROPS(false)} onClick={clearFormatting}>
                  <i className="ri-format-clear" />
                </Box>
              </Tooltip>
            )}
          </Flex>

          <HStack>{toolbarContent}</HStack>
        </Flex>
      </Box>

      {isLink && (
        <Portal>
          <FloatingLinkEditor editor={editor} />
        </Portal>
      )}
    </>
  );
};
