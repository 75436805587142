import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { Text, VStack, HStack } from '@chakra-ui/react';
import { Form, HiddenField, useForm, useFormContext, useHiddenField, useOnChangeValues } from 'graneet-form';
import { Modal, ModalSubtitle, RadioGroupField, IconAdvanced, CheckboxField, SimpleHelpIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { EXPORT_FILE_TYPES } from '../../constants/quote-export.constant';
import type { QuoteExportForm } from '../../forms/quote-export-edition.wizard';

import type { DOWNLOAD_FILE_TYPE } from 'features/file/components/FilesCard/components/DownloadFileIcon';
import { DownloadFileIcon } from 'features/file/components/FilesCard/components/DownloadFileIcon';

interface FormValues {
  fileType: EXPORT_FILE_TYPES;
  refCode: boolean;
  description: boolean;
  unit: boolean;
  quantity: boolean;
  unitDisbursementExVAT: boolean;
  disbursementExVAT: boolean;
  jobMargin: boolean;
  unitPriceExVAT: boolean;
  jobVAT: boolean;
  amountExVAT: boolean;
  workForce: boolean;
  components: boolean;
  notes: boolean;
  images: boolean;
  infos: boolean;
}

interface QuoteExportConfigureModalProps {
  onClose(): void;
  isOpen: boolean;
}

export const QuoteExportConfigureModal: FC<QuoteExportConfigureModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation(['quote', 'global']);

  const modalForm = useForm<FormValues>();
  const { fileType: fileTypeField } = useOnChangeValues(modalForm, ['fileType']);

  const quoteExportForm = useFormContext<QuoteExportForm>();
  const fileTypeHiddenField = useHiddenField(quoteExportForm, 'fileType');
  const itemsHiddenField = useHiddenField(quoteExportForm, 'exportItems');
  const { value: itemsValue, setValue: setItemsValue } = itemsHiddenField;

  useEffect(() => {
    if (isOpen && itemsValue) {
      modalForm.setFormValues({
        ...itemsValue,
        fileType: fileTypeHiddenField.value,
        description: true,
      });
    }
  }, [fileTypeHiddenField.value, isOpen, itemsValue, modalForm]);

  const handleConfigValidated = useCallback(() => {
    const { fileType: nextFileTypeValue, ...nextColumnValues } = modalForm.getFormValues();

    if (nextFileTypeValue) {
      fileTypeHiddenField.setValue(nextFileTypeValue);
    }

    setItemsValue({
      refCode: nextColumnValues.refCode,
      unit: nextColumnValues.unit,
      quantity: nextColumnValues.quantity,
      unitDisbursementExVAT: nextColumnValues.unitDisbursementExVAT,
      disbursementExVAT: nextColumnValues.disbursementExVAT,
      jobMargin: nextColumnValues.jobMargin,
      unitPriceExVAT: nextColumnValues.unitPriceExVAT,
      jobVAT: nextColumnValues.jobVAT,
      amountExVAT: nextColumnValues.amountExVAT,
      workForce: nextColumnValues.workForce,
      components: nextColumnValues.components,
      notes: nextColumnValues.notes,
      images: nextColumnValues.images,
      infos: nextColumnValues.infos,
    });

    onClose();
  }, [fileTypeHiddenField, modalForm, onClose, setItemsValue]);

  useEffect(() => {
    if (fileTypeField === EXPORT_FILE_TYPES.SPREADSHEET) {
      modalForm.setFormValues({
        images: false,
        infos: false,
      });
    }
  }, [modalForm, fileTypeField]);

  return (
    <>
      <HiddenField {...itemsHiddenField} />
      <HiddenField {...fileTypeHiddenField} />

      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        title={t('quote:export.configureExportModal.title')}
      >
        <Form form={modalForm}>
          <Text>{t('quote:export.configureExportModal.description')}</Text>

          <ModalSubtitle mt={6} color="gray.600">
            {t('quote:export.configureExportModal.exportType')}
          </ModalSubtitle>

          <RadioGroupField<FormValues> name="fileType">
            {Object.values(EXPORT_FILE_TYPES).map((type) => (
              <RadioGroupField.Option
                key={type}
                value={type}
                label={t(`quote:export.configureExportModal.fileTypes.${type}`)}
                rightIcon={<DownloadFileIcon type={type as unknown as DOWNLOAD_FILE_TYPE} boxSize={4} ml={2} />}
              />
            ))}
          </RadioGroupField>

          <ModalSubtitle mt={6} color="gray.600">
            {t('quote:export.configureExportModal.jobData')}
          </ModalSubtitle>

          <VStack spacing={3} align="flex-start">
            <CheckboxField<FormValues> name="refCode" label={t('quote:export.configureExportModal.fields.refCode')} />
            <CheckboxField<FormValues>
              isDisabled
              name="description"
              label={t('quote:export.configureExportModal.fields.denomination')}
            />
            <CheckboxField<FormValues> name="unit" label={t('quote:export.configureExportModal.fields.unit')} />
            <CheckboxField<FormValues> name="quantity" label={t('quote:export.configureExportModal.fields.quantity')} />
            <CheckboxField<FormValues>
              name="unitDisbursementExVAT"
              label={t('quote:export.configureExportModal.fields.unitDisbursementExVAT')}
            />
            <CheckboxField<FormValues>
              name="disbursementExVAT"
              label={t('quote:export.configureExportModal.fields.disbursementExVAT')}
            />
            <CheckboxField<FormValues>
              name="jobMargin"
              label={t('quote:export.configureExportModal.fields.jobMargin')}
            />
            <CheckboxField<FormValues>
              name="unitPriceExVAT"
              label={t('quote:export.configureExportModal.fields.unitPriceExVAT')}
            />
            <CheckboxField<FormValues> name="jobVAT" label={t('quote:export.configureExportModal.fields.jobVAT')} />
            <CheckboxField<FormValues>
              name="amountExVAT"
              label={t('quote:export.configureExportModal.fields.amountExVAT')}
            />

            <CheckboxField<FormValues>
              name="workForce"
              label={t('quote:export.configureExportModal.fields.workForce')}
            />

            <CheckboxField<FormValues>
              name="components"
              label={t('quote:export.configureExportModal.fields.components')}
            />

            <CheckboxField<FormValues> name="notes" label={t('quote:export.configureExportModal.fields.notes')} />
            <CheckboxField<FormValues>
              isDisabled={fileTypeField !== EXPORT_FILE_TYPES.PDF}
              name="images"
              label={t('quote:export.configureExportModal.fields.images')}
            />
          </VStack>

          <ModalSubtitle mt={6} color="gray.600">
            {t('quote:export.configureExportModal.addInfos')}
          </ModalSubtitle>

          <CheckboxField<FormValues>
            isDisabled={fileTypeField !== EXPORT_FILE_TYPES.PDF}
            name="infos"
            label={
              <HStack spacing={2}>
                <Text>{t('quote:export.configureExportModal.fields.infos')}</Text>
                <IconAdvanced
                  icon={<SimpleHelpIcon />}
                  tooltip={t('quote:export.configureExportModal.fields.infosTooltip')}
                />
              </HStack>
            }
          />

          <Modal.SecondaryButton onClick={onClose}>{t('global:words.c.back')}</Modal.SecondaryButton>

          <Modal.PrimaryButton onClick={handleConfigValidated}>{t('global:words.c.validate')}</Modal.PrimaryButton>
        </Form>
      </Modal>
    </>
  );
};
