import { useCallback } from 'react';
import { QuoteFlattenObject } from '@org/quotation-lib';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useQuote } from './useQuote';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { updateQuote } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import {
  useQuoteSetClientRequestsStore,
  StatusEnum,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteUpdateReversalOfLiability() {
  const { quote } = useQuote();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);
  const { quoteProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    (hasReversalOfLiability: boolean): boolean => {
      try {
        quote.updateHasReversalOfLiability(hasReversalOfLiability);

        const quoteFlatObject = QuoteFlattenObject.execute(quote.export());
        updateQuote(quoteFlatObject.quote);

        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });

        quoteProxyApi
          .updateQuoteAsync({
            hasReversalOfLiability,
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        toast.success(t('quote:toast.success'));

        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteError, quoteProxyApi, quoteSetClientRequestToStore, t, toast],
  );
}
