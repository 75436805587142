import type { FC, PropsWithChildren } from 'react';
import { GridItem } from '@chakra-ui/react';

export const ListingLayoutFilters: FC<PropsWithChildren> = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <GridItem display="flex" overflow="auto" mr={3} mt={3} borderRadius="8px">
      {children}
    </GridItem>
  );
};
