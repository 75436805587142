import { QUOTE_STATUS } from '@graneet/business-logic';
import type { DocumentStatusCardAction, DocumentStatusCardIconValue } from '@graneet/lib-ui';
import { QuoteStatus } from '@org/graneet-bff-client';

import { getQuoteDraftActions } from './quote-draft.actions';
import { getQuoteCompletedActions } from './quote-completed.actions';
import { getQuoteAcceptedActions } from './quote-accepted.actions';
import { getQuoteImportingActions } from './quote-importing.actions';
import type { QuotationActionsParams, QuoteActionsParams } from './quote.actions.type';

export function getQuoteActions(params: QuoteActionsParams): DocumentStatusCardAction[] {
  const { quote } = params;

  switch (quote.status) {
    case QUOTE_STATUS.DRAFT:
      return getQuoteDraftActions(params, true);
    case QUOTE_STATUS.COMPLETED:
      return getQuoteCompletedActions(params, true);
    case QUOTE_STATUS.ACCEPTED:
    case QUOTE_STATUS.LOST:
      return getQuoteAcceptedActions(params, true);
    case QUOTE_STATUS.IMPORTING:
      return getQuoteImportingActions(params, true);
    default:
      quote.status satisfies never;
      throw new Error('Not implemented');
  }
}

export function getQuotationActions(params: QuotationActionsParams, full = true): DocumentStatusCardAction[] {
  const { quote } = params;

  switch (quote.status) {
    case QuoteStatus.Draft:
      return getQuoteDraftActions(params, full);
    case QuoteStatus.Completed:
      return getQuoteCompletedActions(params, full);
    case QuoteStatus.Accepted:
    case QuoteStatus.Refused:
      return getQuoteAcceptedActions(params, full);
    case QuoteStatus.Importing:
      return getQuoteImportingActions(params, full);
    case QuoteStatus.Archived:
      return [];
    default:
      throw new Error('Not implemented');
  }
}

export function getQuoteStatusCardIcon(status: QUOTE_STATUS): DocumentStatusCardIconValue {
  switch (status) {
    case QUOTE_STATUS.ACCEPTED:
      return 'completed';
    case QUOTE_STATUS.COMPLETED:
      return 'finalized';
    case QUOTE_STATUS.DRAFT:
      return 'draft';
    case QUOTE_STATUS.LOST:
      return 'cancelled';
    case QUOTE_STATUS.IMPORTING:
      return 'importing';
    default:
      status satisfies never;
      throw new Error('Unknown status');
  }
}

export function getQuotationStatusCardIcon(status: QuoteStatus): DocumentStatusCardIconValue {
  switch (status) {
    case QuoteStatus.Accepted:
      return 'completed';
    case QuoteStatus.Completed:
      return 'finalized';
    case QuoteStatus.Draft:
      return 'draft';
    case QuoteStatus.Refused:
      return 'cancelled';
    case QuoteStatus.Importing:
      return 'importing';
    case QuoteStatus.Archived:
      return 'completed';
    default:
      throw new Error('Unknown status');
  }
}
