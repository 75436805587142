import type { FC } from 'react';
import { useMemo } from 'react';
import { Button, Card, SimpleRepeatIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { CircularProgress, HStack, VStack } from '@chakra-ui/react';
import type { ISubProject, IProject, RequiredByKeys } from '@graneet/business-logic';

import type { OrderEditForm } from '../../forms/order-edit-wizard';

import type { OrderProjectField } from 'features/project/components/ProjectField/ProjectField';
import { ProjectField } from 'features/project/components/ProjectField/ProjectField';

type ProjectFieldValues = {
  projectId: number;
} & OrderProjectField;

type OrderProjectCardProps = {
  projects: RequiredByKeys<IProject, 'address'>[];
  selectedSubProject?: ISubProject;
  canChangeSubProject: boolean;
  onSubProjectChange(): void;
  onProjectSelected?(projectId: number): void;
  loading: boolean;
};

export const OrderProjectCard: FC<OrderProjectCardProps> = ({
  loading,
  projects,
  selectedSubProject,
  canChangeSubProject,
  onSubProjectChange,
  onProjectSelected,
}) => {
  const { t } = useTranslation(['orders']);

  const form = useFormContext<OrderEditForm>();
  const { isDirectPayment, associatedSupplierInvoices } = useOnChangeValues(form, [
    'isDirectPayment',
    'associatedSupplierInvoices',
  ]);

  /**
   * Disable the project field if order is in direct payement and has supplier invoices associated
   */
  const disableProjectField = useMemo(
    () => isDirectPayment && !!associatedSupplierInvoices?.length,
    [associatedSupplierInvoices?.length, isDirectPayment],
  );

  return (
    <VStack width="100%" align="left">
      <Card w="100%" title={t('orders:projectCard.title')} isRequired={isDirectPayment}>
        <HStack w="100%">
          {loading && <CircularProgress isIndeterminate color="gray.800" size={6} />}
          <ProjectField<ProjectFieldValues>
            w="100%"
            name="projectId"
            isRequired={isDirectPayment}
            projects={projects || []}
            selectedSubProject={selectedSubProject}
            isDisabled={disableProjectField}
            onProjectSelected={onProjectSelected}
            loading={loading}
          />
        </HStack>
      </Card>

      {canChangeSubProject && (
        <Button variant="ghost" leftIcon={<SimpleRepeatIcon />} onClick={onSubProjectChange}>
          {t('orders:projectCard.changeSubProject')}
        </Button>
      )}
    </VStack>
  );
};
