import { ORDER_STATUS } from '@graneet/business-logic';
import type { DocumentStatusCardAction, DocumentStatusCardIconValue } from '@graneet/lib-ui';
import { compact } from 'lodash-es';

import type { OrderActionsParams } from './order.actions.type';
import {
  getChangeStatusAction,
  getDeleteAction,
  getDownloadPDFAction,
  getDuplicateAction,
  getEditAction,
  getOpenMailingAction,
  getPreviewPDFAction,
} from './order.actions.util';

export function getOrderCommonActions(params: OrderActionsParams): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];
  const changeStatusAction = getChangeStatusAction(params);
  const viewPdfAction = getPreviewPDFAction(params);

  if (changeStatusAction) {
    actions.push(changeStatusAction);
  }

  if (viewPdfAction) {
    actions.push({ type: 'secondary', ...viewPdfAction });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([
      getEditAction(params),
      getOpenMailingAction(params),
      getDownloadPDFAction(params),
      getDuplicateAction(params),
      getDeleteAction(params),
    ]),
  });

  return actions;
}

export function getOrderActions(params: OrderActionsParams): DocumentStatusCardAction[] {
  const { status } = params;

  switch (status) {
    case ORDER_STATUS.PROCESSED:
    case ORDER_STATUS.PARTIALLY_DELIVERED:
    case ORDER_STATUS.DELIVERED:
      return getOrderCommonActions(params);
    default:
      status satisfies never;
      throw new Error('Not implemented');
  }
}

export function getOrderStatusCardIcon(status: ORDER_STATUS): DocumentStatusCardIconValue {
  switch (status) {
    case ORDER_STATUS.DELIVERED:
      return 'completed';
    case ORDER_STATUS.PARTIALLY_DELIVERED:
      return 'partial';
    case ORDER_STATUS.PROCESSED:
      return 'finalized';
    default:
      status satisfies never;
      throw new Error('Unknown status');
  }
}
