import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { compact } from 'lodash-es';

import {
  getCancelAction,
  getDisplayPublicLinkAction,
  getDownloadPDFAction,
  getOpenMailingAction,
  getPreviewPDFAction,
} from './progress-statement.actions.util';
import type { ProgressStatementActionsParams } from './progress-statement.actions.type';

export function getProgressStatementAcceptedActions(
  params: ProgressStatementActionsParams,
): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];
  const previewPDFAction = getPreviewPDFAction(params);

  actions.push({ type: 'secondary', ...getCancelAction(params, true) });

  if (previewPDFAction) {
    actions.push({ type: 'secondary', ...previewPDFAction });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([
      getCancelAction(params, false),
      getOpenMailingAction(params),
      getDownloadPDFAction(params),
      // @[ff: virality]
      params.hasViralityFeature ? getDisplayPublicLinkAction(params) : null,
    ]),
  });

  return actions;
}
