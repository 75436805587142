import type { FC } from 'react';
import { useCallback } from 'react';
import { DeepTable, PriceAdvanced, RichText, Tree } from '@graneet/lib-ui';

import { useProgressStatementLot, useProgressStatementTreeContext } from '../../hooks/useProgressStatementTree';
import type { LotId } from '../../services/progress-statement-tree.util';
import { getIdFromLotId, getProgressStatementDeepTableOffset } from '../../services/progress-statement-tree.util';

import { ProgressStatementLotTotalLabel } from './ProgressStatementLotTotalLabel';
import { ProgressStatementCommentLotRow } from './ProgressStatementCommentLotRow';
import { ProgressStatementGlobalProgressForm } from './ProgressStatementGlobalProgressPercentageForm/ProgressStatementGlobalProgressPercentageForm';

interface ProgressStatementDeepTableLotLineProps {
  id: LotId;

  depth: number;

  isUpdatable: boolean;
}

export const ProgressStatementDeepTableLotLine: FC<ProgressStatementDeepTableLotLineProps> = ({
  id,
  depth,
  isUpdatable,
}) => {
  const { node: lot } = useProgressStatementLot(id);

  const { switchNodeIsExpanded } = useProgressStatementTreeContext();
  const closeCollapse = useCallback(() => {
    switchNodeIsExpanded(id);
  }, [id, switchNodeIsExpanded]);

  const isSubLot = depth > 2;

  if (lot.isOptional) {
    return null;
  }

  return (
    <>
      <DeepTable.Row
        backgroundColor={isSubLot ? 'gray.150' : 'gray.200'}
        color={isSubLot ? 'gray.800' : 'black'}
        borderColor="gray.300"
        role="group"
        offset={getProgressStatementDeepTableOffset(depth)}
        leftContent={<Tree.ToggleButton nodeId={id} />}
      >
        <DeepTable.Cell>{lot.code}</DeepTable.Cell>

        <DeepTable.Cell gridColumn="span 4">
          <RichText value={lot.description} />
        </DeepTable.Cell>

        <DeepTable.Cell right>
          <PriceAdvanced amount={lot.totalAmountExVAT} />
        </DeepTable.Cell>

        <DeepTable.Cell />

        <DeepTable.Cell variant="shadow" center>
          {isUpdatable && (
            <ProgressStatementGlobalProgressForm
              id={getIdFromLotId(lot.id)}
              nodeId={id}
              type="lot"
              onPercentageUpdatedWithoutHigherNestedPercentage={closeCollapse}
            />
          )}
        </DeepTable.Cell>

        <DeepTable.Cell right>
          <ProgressStatementLotTotalLabel id={id} />
        </DeepTable.Cell>
      </DeepTable.Row>

      {lot.note && <ProgressStatementCommentLotRow depth={lot.depth}>{lot.note}</ProgressStatementCommentLotRow>}
    </>
  );
};
