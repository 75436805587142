import { useCallback, useMemo } from 'react';
import type { FC } from 'react';

import { SimpleCloseIcon, SimpleEqualIcon } from '../../../../Icons/v2';
import { quotationCardTranslations } from '../translations';
import { CardLineEnum, GenericCard, MaskEnum } from '../GenericCard';

type Margin = {
  overheadCosts: string;
  profitMargin: string;
  totalMargin: string;
};

type CTA = {
  label: string;
  onClick: () => void;
};

type BaseSellsheetMarginCardProps = {
  margin: Margin;
  cta?: CTA;
  isEditable: boolean;
  isEmpty?: boolean;
};

type EditableCardProps = {
  onMarginChange: (margin: Record<string, string>) => void;
} & BaseSellsheetMarginCardProps;

type ReadonlyCardProps = {
  onMarginChange: undefined;
} & BaseSellsheetMarginCardProps;

export type SellsheetMarginCardProps = EditableCardProps | ReadonlyCardProps;

export const SellsheetMarginCard: FC<SellsheetMarginCardProps> = ({
  margin,
  onMarginChange,
  cta,
  isEditable,
  isEmpty,
}) => {
  const handleInputChange = useCallback(
    (e: string, name: string) => {
      if (!isEditable || !onMarginChange) return;
      onMarginChange({
        [name]: e,
      });
    },
    [onMarginChange, isEditable],
  );

  const ctaLine = useMemo(
    () =>
      cta
        ? ({
            type: CardLineEnum.CTA,
            label: cta.label,
            onClick: cta.onClick,
          } as const)
        : null,
    [cta],
  );

  return (
    <GenericCard
      title={quotationCardTranslations.margin.title}
      groups={[
        {
          items: [
            {
              type: CardLineEnum.ITEM,
              name: 'OverheadCosts',
              label: quotationCardTranslations.margin.overheadCosts,
              value: margin.overheadCosts,
              ...(!isEmpty
                ? {
                    mask: MaskEnum.DECIMAL,
                    maskOptions: {
                      scale: 3,
                      min: 0,
                    },
                  }
                : {}),
              isDisabled: !isEditable,
              ...(isEditable
                ? { onBlur: handleInputChange, isEditable: true }
                : { isEditable: false, onBlur: undefined }),
            },
            {
              type: CardLineEnum.ITEM,
              name: 'ProfitMargin',
              label: quotationCardTranslations.margin.profit,
              icon: <SimpleCloseIcon />,
              ...(!isEmpty
                ? {
                    mask: MaskEnum.DECIMAL,
                    maskOptions: {
                      scale: 3,
                      min: 0,
                    },
                  }
                : {}),
              value: margin.profitMargin,
              isDisabled: !isEditable,
              ...(isEditable
                ? { onBlur: handleInputChange, isEditable: true }
                : { isEditable: false, onBlur: undefined }),
            },
            ...(ctaLine ? [ctaLine] : []),
          ],
        },
        {
          items: [
            {
              type: CardLineEnum.ITEM,
              name: 'TotalMargin',
              label: quotationCardTranslations.margin.total,
              icon: <SimpleEqualIcon />,
              ...(!isEmpty
                ? {
                    mask: MaskEnum.DECIMAL,
                    maskOptions: {
                      scale: 3,
                      min: 0,
                    },
                  }
                : {}),
              value: margin.totalMargin,
              isDisabled: !isEditable,
              ...(isEditable
                ? { onBlur: handleInputChange, isEditable: true }
                : { isEditable: false, onBlur: undefined }),
              isBold: true,
            },
          ],
        },
      ]}
    />
  );
};
