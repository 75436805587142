import { MARGIN_TYPE } from '@org/quotation-lib';
import Big from 'big.js';

import type { QuoteLotComponentType } from 'features/quotation/quote-common/interfaces/quoteWsMessage';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';

export const createQuoteLotComponentTypeUpdatedCallback = (message: QuoteLotComponentType) => {
  const quote = quoteGetFromStore();
  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();

  if (quote) {
    const hasClientRequest = quoteClientRequestsFromStore.find(
      (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
    );
    if (hasClientRequest === undefined) {
      const quoteLot = quote.getTree().getQuoteLotOrThrow(message.data.nodeId);
      if (message.data.overheadCosts) {
        quoteLot.updateComponentOverheadCostMarginByComponentType(
          message.data.componentTypeId.toString(),
          Big(message.data.overheadCosts),
        );
      }

      if (message.data.profitMargin) {
        quoteLot.updateComponentProfitMarginByComponentType(
          message.data.componentTypeId.toString(),
          Big(message.data.profitMargin),
        );
      }

      if (message.data.totalMargin) {
        quoteLot.updateComponentTotalMarginByComponentType(
          message.data.componentTypeId.toString(),
          Big(message.data.totalMargin),
          {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          },
        );
      }
      quoteSetToStore(quote);
    } else {
      quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
    }
  }
};
