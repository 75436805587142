import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DeepTable, Section, SimpleSettingsIcon, Tree, useDeepTable, useToast, Wizard } from '@graneet/lib-ui';
import { useHistory } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import { Form, HiddenField, useFormContext, useHiddenField, useOnChangeValues, useStepForm } from 'graneet-form';
import type { IQuoteInfosResponse } from '@graneet/business-logic';

import { TreeDataPreCalculation } from 'features/common/services/treeDataPreCalculation/treeDataPreCalculation.util';
import { Loading } from 'features/common/components/Loading';
import { useData } from 'features/api/hooks/useData';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { getQuoteInfos } from 'features/quote/services/quote.api';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { useQuoteExportTree } from 'features/quote-export/hooks/useQuoteExportTree';
import { mapQuoteInfosToInitialTree } from 'features/quote-export/services/quote-export.util';
import type {
  QuoteExportEditionWizard,
  QuoteExportForm,
} from 'features/quote-export/forms/quote-export-edition.wizard';
import {
  generateImagesExportFieldName,
  generateIsJobSelectedExportFieldName,
  generateIsLotSelectedExportFieldName,
  generateIsNoteSelectedExportFieldName,
} from 'features/quote-export/forms/quote-export-edition.wizard';
import { EXPORT_COLUMNS, EXPORT_FILE_TYPES } from 'features/quote-export/constants/quote-export.constant';
import { useQuoteExportFileTypeStore } from 'features/quote-export/stores/quote-export-file-type.store';
import { useStore } from 'store/store';
import { QuoteExportDeepTableFooter } from 'features/quote-export/components/QuoteExportDeepTableFooter';
import { QuoteExportDeepTableHeader } from 'features/quote-export/components/QuoteExportDeepTableHeader';
import { QuoteExportDeepTableLotLine } from 'features/quote-export/components/QuoteExportDeepTableLotLine/QuoteExportDeepTableLotLine';
import { QuoteExportDeepTableJobLine } from 'features/quote-export/components/QuoteExportDeepTableJobLine/QuoteExportDeepTableJobLine';
import { QuoteExportDeepTableSeparator } from 'features/quote-export/components/QuoteExportDeepTableSeparator';
import { QuoteExportNote } from 'features/quote-export/components/QuoteExportNote';
import { QuoteExportConfigureModal } from 'features/quote-export/components/modals/QuoteExportConfigureModal';
import { QuoteExportDeepTableLotWrapper } from 'features/quote-export/components/QuoteExportDeepTableLotWrapper';

const QuoteExportTree: FC<{ quoteInfos: IQuoteInfosResponse }> = ({ quoteInfos }) => {
  const initialValues = useMemo(() => mapQuoteInfosToInitialTree(quoteInfos), [quoteInfos]);
  const tree = useQuoteExportTree(initialValues);

  const form = useFormContext<QuoteExportForm>();
  const { exportItems: columns } = useOnChangeValues(form, ['exportItems']);
  const templateColumns = useMemo(
    () =>
      columns
        ? [
            columns[EXPORT_COLUMNS.REF_CODE] ? '5rem' : '',
            'minmax(8rem, 1fr)',
            columns[EXPORT_COLUMNS.UNIT] ? '4rem' : '',
            columns[EXPORT_COLUMNS.QUANTITY] ? '5rem' : '',
            columns[EXPORT_COLUMNS.UNIT_DISBURSEMENT_EX_VAT] ? '9rem' : '',
            columns[EXPORT_COLUMNS.DISBURSEMENT_EX_VAT] ? '7rem' : '',
            columns[EXPORT_COLUMNS.JOB_MARGIN] ? '4rem' : '',
            columns[EXPORT_COLUMNS.UNIT_PRICE_EX_VAT] ? '8rem' : '',
            columns[EXPORT_COLUMNS.JOB_VAT] ? '4rem' : '',
            columns[EXPORT_COLUMNS.WORK_FORCE] ? '6rem' : '',
            columns[EXPORT_COLUMNS.AMOUNT_EX_VAT] ? '7rem' : '',
          ].join(' ')
        : '',
    [columns],
  );
  const maxDepth = useMemo(() => Object.values(quoteInfos.lots).reduce((acc, lot) => lot.depth, 0), [quoteInfos.lots]);
  const deepTable = useDeepTable({ templateColumns, leftContentWidth: 0.5 * maxDepth + 2.5 });

  const footerComponent = useCallback(
    () => <QuoteExportDeepTableFooter quote={quoteInfos.quote} />,
    [quoteInfos.quote],
  );

  return (
    <DeepTable deepTable={deepTable} noCard>
      <Tree
        tree={tree}
        headerComponent={QuoteExportDeepTableHeader}
        nodeComponent={QuoteExportDeepTableLotLine}
        leafComponent={QuoteExportDeepTableJobLine}
        separatorComponent={QuoteExportDeepTableSeparator}
        nodeWrapperComponent={QuoteExportDeepTableLotWrapper}
        footerComponent={footerComponent}
      />
    </DeepTable>
  );
};

export const SyncFileType = () => {
  const changeFileType = useQuoteExportFileTypeStore((store) => store.changeFileType);
  const form = useFormContext<QuoteExportForm>();
  const { fileType } = useOnChangeValues(form, ['fileType']);

  useEffect(() => {
    if (fileType) {
      changeFileType(fileType);
    }
  }, [changeFileType, fileType]);

  return null;
};

interface ExportQuoteStepProps {
  quoteId: number;
}

export const ExportQuoteStep: FC<ExportQuoteStepProps> = ({ quoteId }) => {
  const { t } = useTranslation(['quote']);
  const toast = useToast();
  const { replace } = useHistory();

  const configureExportModal = useDisclosure();

  const getData = useDataGetter(getQuoteInfos, quoteId);
  const { data: quoteInfos, error, loading } = useData(getData);

  const autoNumberingSetTable = useStore((state) => state.autoNumberingSetTable);
  const optionalLotsSetTable = useStore((state) => state.optionalLotsSetTable);

  const { form, initFormValues } = useStepForm<QuoteExportEditionWizard, 'export'>();
  const hasReversalOfLiabilityHiddenField = useHiddenField(form, 'hasReversalOfLiability');

  useEffect(() => {
    if (quoteInfos) {
      const treeDataPreCalculation = new TreeDataPreCalculation(quoteInfos.relations, quoteInfos.jobs);
      autoNumberingSetTable(treeDataPreCalculation.autoNumberingTable);
      optionalLotsSetTable(treeDataPreCalculation.optionalLotsTable);
    }

    const formValues: Partial<QuoteExportForm> = {};
    // Check all checkboxes by default
    Object.values(quoteInfos?.jobs || []).forEach((job) => {
      if (!job.isHiddenCost) {
        formValues[generateIsJobSelectedExportFieldName(job.id)] = true;
        formValues[generateImagesExportFieldName(job.id)] = true;
        if (job.note) {
          formValues[generateIsNoteSelectedExportFieldName(job.id, 'job')] = true;
        }
      }
    });
    Object.values(quoteInfos?.lots || []).forEach((lot) => {
      formValues[generateIsLotSelectedExportFieldName(lot.id)] = true;
      if (lot.note) {
        formValues[generateIsNoteSelectedExportFieldName(lot.id, 'lot')] = true;
      }
    });

    initFormValues({
      exportItems: {
        refCode: true,
        unit: true,
        quantity: true,
        unitDisbursementExVAT: false,
        disbursementExVAT: false,
        jobMargin: false,
        unitPriceExVAT: true,
        jobVAT: false,
        amountExVAT: true,
        workForce: false,
        notes: true,
        images: true,
        components: false,
        infos: true,
      },
      fileType: EXPORT_FILE_TYPES.PDF,
      hasReversalOfLiability: quoteInfos?.quote.hasReversalOfLiability,
      ...formValues,
    });
  }, [initFormValues, quoteInfos, autoNumberingSetTable, optionalLotsSetTable]);

  /**
   * Open configuration modal by default after loading
   */
  const [hasModalOpenedAfterLoading, setHasModalOpenedAfterLoading] = useState(false);
  useEffect(() => {
    if (!loading && !hasModalOpenedAfterLoading) {
      configureExportModal.onOpen();
      setHasModalOpenedAfterLoading(true);
    }
  }, [configureExportModal, hasModalOpenedAfterLoading, loading]);

  /**
   * Handle error if quote infos could not be loaded
   */
  if (error) {
    toast.error(t('quote:errors.fetchingInfosError', { email: SUPPORT_EMAIL }));
    replace('/opportunities/quotes');
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Form form={form}>
      <HiddenField {...hasReversalOfLiabilityHiddenField} />

      <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_RIGHT}>
        <Button leftIcon={<SimpleSettingsIcon />} variant="outline" onClick={configureExportModal.onOpen}>
          {t('quote:export.wizard.actions.configure')}
        </Button>
      </Wizard.Placeholder>

      <QuoteExportNote />

      <SyncFileType />

      <Section
        title={t('quote:export.wizard.tableSection.title')}
        description={t('quote:export.wizard.tableSection.description')}
      >
        <QuoteExportTree quoteInfos={quoteInfos} />
      </Section>

      <QuoteExportConfigureModal isOpen={configureExportModal.isOpen} onClose={configureExportModal.onClose} />
    </Form>
  );
};
