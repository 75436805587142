import type { KeysMatching, PaginationQuery, SingleSelectFieldProps } from '@graneet/lib-ui';
import { SingleSelectField } from '@graneet/lib-ui';
import type { FieldValues } from 'graneet-form';
import type { ReactNode } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { PaginatedResponse } from '@graneet/business-logic';

import { useDebounce } from '../hooks/useDebounce';

type Value = any;

export interface SingleSelectFieldPaginatedProps<
  Type extends object,
  T extends FieldValues,
  K extends KeysMatching<T, Value> = KeysMatching<T, Value>,
  TData extends PaginatedResponse<Type> = PaginatedResponse<Type>,
> extends Omit<SingleSelectFieldProps<T, K>, 'options'> {
  mapFunction: (data: Type) => { label: ReactNode; value: Value };

  filterFunction?: (data: Type) => boolean;

  pagination: PaginationQuery<TData>;

  initialSelectedValue?: Type;
}

export const SingleSelectFieldPaginated = <
  Type extends object,
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, Value> = KeysMatching<T, Value>,
  TData extends PaginatedResponse<Type> = PaginatedResponse<Type>,
>({
  pagination,
  mapFunction,
  initialSelectedValue,
  filterFunction = () => true,
  ...otherProps
}: SingleSelectFieldPaginatedProps<Type, T, K, TData>) => {
  const pageRef = useRef(1);
  const searchTermRef = useRef('');

  const [result, setResult] = useState<TData['data'] | null>(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetch = useCallback(
    async (accumulateResults = true) => {
      setLoading(true);

      const { data, metadata } = await pagination.fetchData({
        page: pageRef.current,
        sort: { _search: searchTermRef.current },
      } as any);

      setHasMore(metadata.hasMore);

      setResult((prev) => [
        // Used to accumulate data from different pages
        ...(prev && accumulateResults ? prev : []),
        ...data,
      ]);

      setLoading(false);

      pageRef.current += 1;
    },
    [pagination],
  );

  if (initialSelectedValue && result === null) {
    setResult([initialSelectedValue]);
  }

  const updateSearchTermDebounced = useDebounce(
    (data: string[]) => {
      pageRef.current = 1;
      [searchTermRef.current] = data;
      if (!loading) {
        fetch(false);
      }
    },
    200,
    {
      leading: false,
      trailing: true,
    },
  );

  const onSearchUpdate = useCallback(
    (value: string) => {
      updateSearchTermDebounced(value);
    },
    [updateSearchTermDebounced],
  );

  const onClear = useCallback(() => {
    updateSearchTermDebounced('');
  }, [updateSearchTermDebounced]);

  const onMenuScrollToBottom = useCallback(() => {
    if (!loading && hasMore) {
      fetch(true);
    }
  }, [fetch, hasMore, loading]);

  const options = useMemo(
    () =>
      (result ?? [])
        .filter(filterFunction)
        .map(mapFunction)
        .map((option) => ({
          ...option,
          // HACK: this is used to remove the client side filtering as this is a server responsibility in this component
          searchTerm: searchTermRef.current,
        })),
    [result, mapFunction, filterFunction],
  );

  return (
    <SingleSelectField<T, K>
      options={options as any}
      onMenuOpen={fetch}
      onClear={onClear}
      onSearchUpdate={onSearchUpdate}
      isLoading={loading}
      onMenuScrollToBottom={onMenuScrollToBottom}
      {...otherProps}
    />
  );
};
