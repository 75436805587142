import type { MouseEventHandler } from 'react';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { ListingLayout } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IHoldback } from '@graneet/business-logic';
import { HOLDBACK_STATUSES } from '@graneet/business-logic';

import { useHoldbacks } from 'features/holdback/services/holdback.api';
import { HOLDBACK_STATUSES_COLOR } from 'features/holdback/constants/holdback.constant';
import { HoldbackTable } from 'features/holdback/components/HoldbackTable';
import { HoldbackMarkAsReceivedModal } from 'features/holdback/components/HoldbackMarkAsReceivedModal';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewHoldbacksScreen = () => {
  const { t } = useTranslation(['holdback', 'global']);
  const [selectedHoldback, setSelectedHoldback] = useState<null | IHoldback>(null);
  const receiveModal = useDisclosure();
  const { updateHeaderTitle } = useHeaderContext();

  const holdbacks = useHoldbacks();

  const openModalToReceive = useCallback(
    (holdback: IHoldback): MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        e.stopPropagation();
        setSelectedHoldback(holdback);
        receiveModal.onOpen();
      },
    [receiveModal],
  );

  const availableStatuses = useMemo(
    () =>
      (Object.keys(HOLDBACK_STATUSES) as HOLDBACK_STATUSES[]).map((status) => ({
        value: status,
        colorScheme: HOLDBACK_STATUSES_COLOR[status],
        label: t(`holdback:statuses.${status}`),
      })),
    [t],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(t('holdback:myHoldbacks'), []);
  }, [t, updateHeaderTitle]);

  return (
    <>
      <ListingLayout
        pagination={holdbacks}
        search={{
          placeholder: t('holdback:search'),
        }}
        filters={[
          {
            type: 'checkbox',
            name: 'status',
            label: t('holdback:filters.status'),
            availableValues: availableStatuses,
          },
          {
            type: 'checkbox',
            name: 'hasPaymentGuarantee',
            label: t('holdback:filters.paymentGuarantee'),
            availableValues: [
              { label: t('global:words.c.yes'), value: 'true' },
              { label: t('global:words.c.no'), value: 'false' },
            ],
          },
        ]}
        content={<HoldbackTable holdbacks={holdbacks} openModal={openModalToReceive} />}
      />

      <HoldbackMarkAsReceivedModal holdback={selectedHoldback!} modal={receiveModal} />
    </>
  );
};
