import type { DocumentStatusCardAction } from '@graneet/lib-ui';

import { getDownloadPDFAction, getOpenMailingAction, getPreviewPDFAction } from './credit.actions.util';
import type { CreditActionsParams } from './credit.actions.type';

export function getCreditActions(params: CreditActionsParams): DocumentStatusCardAction[] {
  const { pdf } = params;

  if (!pdf) {
    return [];
  }

  return [
    {
      type: 'secondary',
      ...getPreviewPDFAction(params, pdf),
    },
    {
      type: 'secondary',
      ...getOpenMailingAction(params, pdf),
    },
    {
      type: 'tertiaryDropdown',
      options: [getDownloadPDFAction(params, pdf)],
    },
  ];
}
