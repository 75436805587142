import { useHistory } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import { Badge, Table, ActionMenu, Modal, SimpleRepeatIcon } from '@graneet/lib-ui';
import type { IRole, IUserInvitationWithRelations, IUser, RequiredByKeys } from '@graneet/business-logic';
import { PAGINATION_PARAMS } from '@graneet/business-logic';
import { useQueryClient } from '@tanstack/react-query';

import { isAdminRole } from '../../role/services/role.util';

import { UserRoleBadge } from './UserRoleBadge';

import { useAppContext } from 'features/app/contexts/AppContext';
import { useDeleteUserInvitation, useResendUserInvitation } from 'features/onboarding/services/user-invitation.api';
import { userKeyFactory, useDeleteUser } from 'features/user/services/user.api';
import { createURLSearchParams } from 'features/api/services/api.util';

const getTemplateColumns = (): string[] => ['15rem', '15rem', '1fr', '10rem', '10rem', '4rem'];

interface UserTableProps {
  users: RequiredByKeys<IUser, 'role'>[];

  userInvitations: IUserInvitationWithRelations[];

  isAdmin: boolean;
}

export const UserTable: FC<UserTableProps> = ({ isAdmin, users, userInvitations }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { t } = useTranslation(['global', 'user', 'onboarding']);

  const { currentUser } = useAppContext();

  const selectedUserIdRef = useRef<number | null>(null);

  const deleteUserMutation = useDeleteUser();
  const deleteUserInvitationMutation = useDeleteUserInvitation();
  const resendUserInvitationMutation = useResendUserInvitation();

  const modal = useDisclosure({
    onClose() {
      selectedUserIdRef.current = null;
    },
  });

  const goToEditUser = useCallback(
    (userId: number) => {
      history.push(`/users/${userId}/edit`);
    },
    [history],
  );

  const onOpenDeleteUserModal = useCallback(
    (selectedUserId: number) => {
      selectedUserIdRef.current = selectedUserId;

      modal.onOpen();
    },
    [modal],
  );

  const handleUserDelete = useCallback(async () => {
    if (!selectedUserIdRef.current) return;
    deleteUserMutation.mutate(selectedUserIdRef.current, {
      onSuccess: async () => {
        modal.onClose();
        await queryClient.invalidateQueries(
          userKeyFactory.getUsersPaginated(
            createURLSearchParams({
              [PAGINATION_PARAMS.FULL]: true,
            }),
          ),
        );
      },
    });
  }, [deleteUserMutation, modal, queryClient]);

  const handleResendUserInvitation = useCallback(
    async (userInvitationId: string) => resendUserInvitationMutation.mutateAsync(userInvitationId),
    [resendUserInvitationMutation],
  );

  const handleDeleteUserInvitation = useCallback(
    async (userInvitationId: string) => deleteUserInvitationMutation.mutateAsync(userInvitationId),
    [deleteUserInvitationMutation],
  );

  const displayRole = useCallback(
    (role: IRole) => {
      if (isAdmin) return true;

      return isAdminRole(role);
    },
    [isAdmin],
  );

  if (!currentUser) return null;

  return (
    <>
      <Table templateColumns={getTemplateColumns()} noResultLabel={t('user:noResult')}>
        <Table.Header>
          <Table.Cell>{t('user:fields.lastName')}</Table.Cell>
          <Table.Cell>{t('user:fields.firstName')}</Table.Cell>
          <Table.Cell>{t('user:fields.email')}</Table.Cell>
          <Table.Cell>{t('user:fields.phone')}</Table.Cell>
          <Table.Cell>{t('user:fields.role')}</Table.Cell>
          <Table.Cell />
        </Table.Header>

        {userInvitations?.map((ui) => (
          <Table.Row key={ui.id}>
            <Table.Cell variant="ellipsis">
              <Badge colorScheme="blue">{t('onboarding:invitation.userInvitationInformation.sent')}</Badge>
            </Table.Cell>
            <Table.Cell variant="ellipsis">-</Table.Cell>
            <Table.Cell variant="ellipsis">{ui.email}</Table.Cell>
            <Table.Cell variant="ellipsis">-</Table.Cell>
            <Table.Cell>{displayRole(ui.role) && <UserRoleBadge role={ui.role} />}</Table.Cell>

            <Table.Cell>
              {isAdmin && (
                <ActionMenu>
                  <ActionMenu.Action
                    label={t('global:words.c.resend')}
                    icon={<SimpleRepeatIcon />}
                    onClick={() => {
                      handleResendUserInvitation(ui.id);
                    }}
                  />
                  <ActionMenu.Delete onClick={() => handleDeleteUserInvitation(ui.id)} />
                </ActionMenu>
              )}
            </Table.Cell>
          </Table.Row>
        ))}

        {users?.map(({ id, firstName, lastName, email, phone, role }) => {
          const isCurrentUser = id === currentUser.id;
          return (
            <Table.Row key={id}>
              <Table.Cell variant="ellipsis">{lastName}</Table.Cell>
              <Table.Cell variant="ellipsis">{firstName}</Table.Cell>
              <Table.Cell variant="ellipsis">{email}</Table.Cell>
              <Table.Cell variant="ellipsis">{phone}</Table.Cell>
              <Table.Cell>{displayRole(role) && <UserRoleBadge role={role} />}</Table.Cell>

              <Table.Cell>
                {isAdmin && (
                  <ActionMenu>
                    <ActionMenu.Edit onClick={() => goToEditUser(id)} />
                    {!isCurrentUser && <ActionMenu.Delete onClick={() => onOpenDeleteUserModal(id)} />}
                  </ActionMenu>
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table>

      <Modal title={t('user:deleteModal.title')} onClose={modal.onClose} isOpen={modal.isOpen}>
        <Trans t={t} i18nKey="user:deleteModal.description" />

        <Modal.Close isDisabled={deleteUserMutation.isPending} />

        <Modal.PrimaryButton
          onClick={handleUserDelete}
          isLoading={deleteUserMutation.isPending}
          variant="outline"
          colorScheme="red"
        >
          {t('global:words.c.confirm')}
        </Modal.PrimaryButton>
      </Modal>
    </>
  );
};
