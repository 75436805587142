import type { FC } from 'react';
import { Circle, Flex, Grid, Text } from '@chakra-ui/react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { InputProps } from '@graneet/lib-ui';
import { SingleSelectField, CurrencyField, PercentageField, PriceAdvanced } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { QUOTE_CUSTOM_DISCOUNT_TYPE } from '@org/quotation-lib';

import { Rule } from 'features/form/rules/Rule';
import { useCustomDiscountTotal } from 'features/custom-discount/hooks/useCustomDiscountTotal';
import {
  NEGATIVE_MULTIPLIER,
  POSITIVE_MULTIPLIER,
  type QuotationCustomDiscountEditionForm,
} from 'features/custom-discount/forms/custom-discount-edition-form';

const customDiscountFieldInputProps: InputProps = {
  textAlign: 'right',
  background: 'white',
};

interface CustomDiscountTotalsProps {
  hasDiscount: boolean;
  totalAfterSaleDiscountExVAT: Big;
  onFieldBlur?(): void;
}

export const CustomDiscountTotals: FC<CustomDiscountTotalsProps> = ({
  totalAfterSaleDiscountExVAT,
  hasDiscount,
  onFieldBlur,
}) => {
  const { t } = useTranslation(['customDiscount']);

  const totalExVAT = useCustomDiscountTotal(totalAfterSaleDiscountExVAT);
  const form = useFormContext<QuotationCustomDiscountEditionForm>();
  const { customDiscountType } = useOnChangeValues(form, ['customDiscountType', 'customDiscountMultiplier']);

  return (
    <Flex bg="gray.100" borderRadius="0.5rem" direction="column" px={4} py={2} flexGrow={1}>
      <Grid templateColumns="repeat(2, 1fr)" gap={3}>
        <Text fontWeight={600}>
          {hasDiscount
            ? t('customDiscount:totalExVATAfterDiscountLabel')
            : t('customDiscount:totalExVATBeforeCustomDiscountLabel')}
        </Text>

        <PriceAdvanced textAlign="right" fontWeight={600} amount={totalAfterSaleDiscountExVAT.toNumber()} />

        <SingleSelectField<QuotationCustomDiscountEditionForm>
          name="customDiscountMultiplier"
          options={[
            {
              value: POSITIVE_MULTIPLIER,
              label: (
                <Flex gap={2} alignItems="center">
                  <Circle size="0.725rem" bg="green.500" />
                  {t('customDiscount:fields.customDiscountIncreaseValue')}
                </Flex>
              ),
            },
            {
              value: NEGATIVE_MULTIPLIER,
              label: (
                <Flex gap={2} alignItems="center">
                  <Circle size="0.725rem" bg="red.500" />
                  {t('customDiscount:fields.customDiscountDecreaseValue')}
                </Flex>
              ),
            },
          ]}
          isClearable={false}
          isSearchable={false}
        />
        {customDiscountType === QUOTE_CUSTOM_DISCOUNT_TYPE.AMOUNT && (
          <Flex justifyContent="flex-end">
            <CurrencyField<QuotationCustomDiscountEditionForm>
              name="customDiscountAmountExVAT"
              w="8rem"
              inputProps={customDiscountFieldInputProps}
              onBlur={onFieldBlur}
              min={0}
            >
              <Rule.IsRequired />
            </CurrencyField>
          </Flex>
        )}
        {customDiscountType === QUOTE_CUSTOM_DISCOUNT_TYPE.PERCENTAGE && (
          <Flex justifyContent="flex-end">
            <PercentageField<QuotationCustomDiscountEditionForm>
              name="customDiscountPercentage"
              w="4.5rem"
              inputProps={customDiscountFieldInputProps}
              min={0}
              hideErrorMessage
            >
              <Rule.IsRequired />
            </PercentageField>
          </Flex>
        )}

        <Text fontWeight={600}>{t('customDiscount:totalAmountExVAT')}</Text>

        <PriceAdvanced textAlign="right" fontWeight={600} amount={totalExVAT.toNumber()} />
      </Grid>
    </Flex>
  );
};
