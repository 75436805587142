import { Callout, Button, Modal, TextField, useToast, Container, SimpleChevronBottomIcon } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import type { UseModalProps } from '@chakra-ui/react';
import { Box, Collapse, Text, useDisclosure, VStack } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Form, useForm, useFormStatus } from 'graneet-form';

import { useStripeContext } from 'features/common/contexts/StripeProvider';
import { useAppContext } from 'features/app/contexts/AppContext';
import { Rule } from 'features/form/rules/Rule';
import { Loading } from 'features/common/components/Loading';
import { useCompanyStripeData, useUpdateCompanyStripeData } from 'features/company/services/company.api';

interface SEPADebitModalProps {
  modalProps: UseModalProps;
  setupIntentClientSecret: string;
}

interface FormValues {
  iban: string;

  email: string;

  name: string;
}

const SEPADebitModal: FC<SEPADebitModalProps> = ({ modalProps, setupIntentClientSecret }) => {
  const { t } = useTranslation(['company']);
  const toast = useToast();

  const stripe = useStripeContext();
  const { currentUser } = useAppContext();

  const { isOpen: mandateIsOpen, onToggle: toggleMandate } = useDisclosure();
  const [isLoading, setLoading] = useState(false);

  const form = useForm<FormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  const updateCompanyStripeData = useUpdateCompanyStripeData();

  useEffect(() => {
    form.setFormValues({
      email: currentUser?.email,
      name: `${currentUser?.firstName} ${currentUser?.lastName}`,
    });
  }, [currentUser, form]);

  const saveSEPADebit = useCallback(
    (clientSecret: string) => async () => {
      const { name, email, iban } = form.getFormValues();
      setLoading(true);
      const { error: errorStripe, setupIntent } = await stripe!.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: {
            iban: iban!,
          },
          billing_details: {
            name: name!,
            email: email!,
          },
        },
      });

      if (errorStripe) {
        toast.error(t('company:billing.sepaDebit.errors.saving'));
        setLoading(false);
        return;
      }

      await updateCompanyStripeData.mutateAsync(setupIntent.payment_method as string);

      modalProps.onClose();
      setLoading(false);
    },
    [form, stripe, updateCompanyStripeData, modalProps, toast, t],
  );

  return (
    <Modal {...modalProps} title={t('company:billing.sepaDebit.title')}>
      <Form form={form}>
        <VStack gap={4} alignItems="start">
          <TextField<FormValues>
            name="iban"
            placeholder={t('company:billing.sepaDebit.placeholders.iban')}
            label={t('company:billing.sepaDebit.fields.iban')}
            isRequired
          >
            <Rule.IsRequired />
          </TextField>
          <TextField<FormValues> name="email" label={t('company:billing.sepaDebit.fields.email')} isRequired>
            <Rule.IsRequired />
            <Rule.IsEmail />
          </TextField>
          <TextField<FormValues> name="name" label={t('company:billing.sepaDebit.fields.name')} isRequired>
            <Rule.IsRequired />
          </TextField>

          <Box>
            <Button
              rightIcon={
                mandateIsOpen ? <SimpleChevronBottomIcon transform="rotate(-180deg)" /> : <SimpleChevronBottomIcon />
              }
              variant="outline"
              onClick={toggleMandate}
            >
              {t('company:billing.sepaDebit.mandate.title')}
            </Button>
            <Collapse in={mandateIsOpen} animateOpacity>
              <Box fontSize="80%" color="gray">
                {t('company:billing.sepaDebit.mandate.description')}
              </Box>
            </Collapse>
          </Box>
        </VStack>

        <Modal.Close />
        <Modal.PrimaryButton
          onClick={saveSEPADebit(setupIntentClientSecret)}
          isLoading={isLoading}
          isDisabled={!isFormValid}
        >
          {t('company:billing.sepaDebit.ctas.accept')}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};

export const BillingSettingsTab: FC = () => {
  const { t } = useTranslation(['global', 'company']);
  const stripe = useStripeContext();
  const modalProps = useDisclosure();

  const companyStripeData = useCompanyStripeData();

  if (!stripe) {
    return <Loading />;
  }

  const { hasSEPADebitByDefault, setupIntentClientSecret, ibanLastChars } = companyStripeData.data;

  return (
    <Container size="md">
      <Callout w="60%">
        {hasSEPADebitByDefault && (
          <Trans t={t} i18nKey="company:billing.sepaDebit.descriptions.activated" values={{ ibanLastChars }}>
            text_start
            <Text as="span" color="greenBrand.light" fontWeight={600}>
              iban_last_chars
            </Text>
            text_end
          </Trans>
        )}
        {!hasSEPADebitByDefault && setupIntentClientSecret && (
          <>
            <Text>{t('company:billing.sepaDebit.descriptions.deactivated')}</Text>
            <Button mt={4} onClick={modalProps.onOpen}>
              {t('company:billing.sepaDebit.ctas.activate')}
            </Button>
            <SEPADebitModal modalProps={modalProps} setupIntentClientSecret={setupIntentClientSecret} />
          </>
        )}
      </Callout>
    </Container>
  );
};
