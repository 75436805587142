import { deburr } from 'lodash-es';
import type { KeysMatching } from '@graneet/business-logic';

export const filterOnlyContaining = <T extends Record<any, any>>(
  array: T[],
  propertyToSearchIn: KeysMatching<T, string | null | undefined>[],
  search: string | null,
): T[] =>
  array.filter((obj) => {
    if (search === '' || search === null) {
      return true;
    }
    return propertyToSearchIn.some((property) =>
      deburr(obj[property]).toLowerCase().includes(deburr(search).toLowerCase()),
    );
  });
