import { Quote } from '@org/quotation-lib';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { DrawerApi } from '@graneet/lib-ui';
import { useToast } from '@graneet/lib-ui';

import { useComponentTypes } from 'features/component-type/services/component-type.api';
import { quoteComposeToQuoteObjectMapper } from 'features/quotation/quote-common/mappers/quoteComposeToQuoteObjectMapper';
import type { QuoteTable } from 'features/quotation/quote/components/tables/QuoteSelectTable';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';

export const useSelectQuote = (
  setSelectedQuote: Dispatch<SetStateAction<Quote | undefined>>,
  drawer: DrawerApi<'quote-basic-items-select-drawer' | 'quote-component-select-drawer'>,
) => {
  const { quoteProxyApi } = useQuotationProxyApis();
  const { t } = useTranslation(['orders', 'global']);
  const toast = useToast();

  const componentTypes = useComponentTypes();

  return useCallback(
    async (quoteSelected: QuoteTable) => {
      const [errGetQuoteCompose, quoteComposed] = await quoteProxyApi!.getQuoteComposeById(quoteSelected.id);
      if (errGetQuoteCompose) {
        toast.error(t('global:errors.error'));
      }
      if (quoteComposed) {
        const quoteObject = quoteComposeToQuoteObjectMapper(quoteComposed);
        if (quoteObject.tree) {
          const quote = new Quote(
            { isAutomaticIndexActivated: true, componentTypes: componentTypes.data ?? undefined },
            quoteObject,
          );
          setSelectedQuote(quote);
          drawer.onOpen();
        }
      }
    },
    [componentTypes.data, drawer, quoteProxyApi, setSelectedQuote, t, toast],
  );
};
