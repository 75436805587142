import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, HiddenField, useHiddenField, useStepForm } from 'graneet-form';
import { Flex, HStack, useDisclosure, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import {
  Button,
  Callout,
  Card,
  DateField,
  formatDateToString,
  getCurrentDateAsString,
  IconAdvanced,
  Modal,
  Section,
  SimpleDotsIcon,
  SimpleHelpIcon,
  TextField,
} from '@graneet/lib-ui';
import type { IDownPayment, IProject } from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';

import { Rule } from 'features/form/rules/Rule';
import { RuleIsInvoiceNumberAvailable } from 'features/statement/rules/RuleIsInvoiceNumberAvailable';
import { PaymentMethodSection } from 'features/payment-method/components/PaymentMethodSection';
import { usePaymentMethods } from 'features/payment-method/services/payment-method.api';
import { InvoiceNumberField } from 'features/statement-ledger/components/InvoiceNumberField';
import { useInitialPaymentConditions } from 'features/payment-method/hooks/useInitialPaymentConditions';
import type {
  DownPaymentEditionWizard,
  DownPaymentInformationStepForm,
} from 'features/down-payment/forms/DownPaymentEditionWizard';
import { NoteFieldSection } from 'features/common/components/fields/NoteFieldSection';

const ICON_HOVER_STYLE = { stroke: 'greenBrand.light' };

interface EditDownPaymentInformationStepProps {
  downPayment?: IDownPayment | null;

  project: IProject;
}

export const EditDownPaymentInformationStep: FC<EditDownPaymentInformationStepProps> = ({ downPayment, project }) => {
  const { t } = useTranslation(['downPayment', 'paymentMethods']);
  const { invoiceNumber, id: downPaymentId } = downPayment || {};
  const { form, initFormValues } = useStepForm<DownPaymentEditionWizard, 'information'>();

  const isInvoicedHiddenField = useHiddenField(form, 'isInvoiced');
  const { setValue: setIsInvoiced, value: isInvoiced } = isInvoicedHiddenField;

  const invoicedModalControls = useDisclosure();
  const notInvoicedModalControls = useDisclosure();

  const paymentMethods = usePaymentMethods();
  const paymentConditions = useInitialPaymentConditions({
    paymentMethods: paymentMethods.data,
    project,
    statement: downPayment,
  });

  useEffect(() => {
    if (paymentConditions) {
      initFormValues(paymentConditions);
    }

    if (!downPayment) {
      initFormValues({
        billingDate: getCurrentDateAsString(),
        isInvoiced: true,
      });
      return;
    }

    initFormValues({
      name: downPayment.name,
      billingDate: formatDateToString(downPayment.billingDate),
      invoiceNumber: downPayment.invoiceNumber,
      note: downPayment.note,
      isInvoiced: downPayment.isInvoiced,
    });
  }, [downPayment, initFormValues, project, paymentConditions]);

  const handleInvoicedDownPayment = useCallback(() => {
    setIsInvoiced(true);
    invoicedModalControls.onClose();
  }, [invoicedModalControls, setIsInvoiced]);

  const handleNotInvoicedDownPayment = useCallback(() => {
    setIsInvoiced(false);
    notInvoicedModalControls.onClose();
  }, [notInvoicedModalControls, setIsInvoiced]);

  return (
    <Form form={form}>
      <HiddenField {...isInvoicedHiddenField} />

      <Section
        title={t('downPayment:wizard.steps.information.title')}
        description={t('downPayment:wizard.steps.information.description')}
      >
        <Wrap spacing={3}>
          <WrapItem minW="25rem" flex={3}>
            <Card w="100%">
              <VStack spacing={6} w="100%">
                <HStack w="100%" spacing={16}>
                  <Flex flex={1}>
                    <TextField<DownPaymentInformationStepForm>
                      name="name"
                      label={t('downPayment:fields.name')}
                      rightIcon={<IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('downPayment:tooltips.name')} />}
                      isRequired
                    >
                      <Rule.IsRequired />
                    </TextField>
                  </Flex>

                  <Flex minW="16rem">
                    {isInvoiced && (
                      <Flex alignItems="flex-end">
                        <InvoiceNumberField<DownPaymentInformationStepForm>
                          name="invoiceNumber"
                          label={t('downPayment:fields.invoiceNumber')}
                          type={STATEMENT_TYPES.DOWN_PAYMENT}
                          invoiceNumber={invoiceNumber}
                        >
                          <RuleIsInvoiceNumberAvailable
                            statementId={downPaymentId}
                            type={STATEMENT_TYPES.DOWN_PAYMENT}
                          />
                        </InvoiceNumberField>

                        <Flex pl={4}>
                          <IconAdvanced
                            icon={
                              <SimpleDotsIcon
                                _hover={ICON_HOVER_STYLE}
                                boxSize={6}
                                onClick={notInvoicedModalControls.onOpen}
                              />
                            }
                          />
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                </HStack>

                <Flex w="100%">
                  <DateField<DownPaymentInformationStepForm>
                    name="billingDate"
                    label={t('downPayment:fields.billingDate')}
                    rightIcon={
                      <IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('downPayment:tooltips.billingDate')} />
                    }
                    isRequired
                  >
                    <Rule.IsRequired />
                  </DateField>
                </Flex>
              </VStack>
            </Card>
          </WrapItem>
          <WrapItem minW="25rem" flex={2}>
            {!isInvoiced && (
              <Callout w="100%">
                <Trans t={t} i18nKey="downPayment:callouts.draftNotInvoiced" />
                <Flex>
                  <Button variant="outline" onClick={invoicedModalControls.onOpen}>
                    {t('downPayment:actions.invoice')}
                  </Button>
                </Flex>
              </Callout>
            )}
          </WrapItem>
        </Wrap>
      </Section>
      <NoteFieldSection<DownPaymentInformationStepForm> name="note" />

      <PaymentMethodSection
        paymentMethods={paymentMethods.data}
        description={t('paymentMethods:modal.select.desc.downPayment')}
        w="80%"
      />

      <Modal {...notInvoicedModalControls} title={t('downPayment:modalNotInvoiced.title')}>
        <Trans t={t} i18nKey="downPayment:modalNotInvoiced.description" />

        <Modal.Close />

        <Modal.PrimaryButton onClick={handleNotInvoicedDownPayment}>
          {t('downPayment:actions.noInvoice')}
        </Modal.PrimaryButton>
      </Modal>

      <Modal {...invoicedModalControls} title={t('downPayment:modalInvoiced.title')}>
        {t('downPayment:modalInvoiced.description')}

        <Modal.Close />

        <Modal.PrimaryButton onClick={handleInvoicedDownPayment}>
          {t('downPayment:actions.invoice')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
