import type { CheckboxProps } from '@chakra-ui/react';
import { Checkbox } from '@chakra-ui/react';
import type { FieldValues } from 'graneet-form';
import { composeEventHandlers, Field } from 'graneet-form';
import type { ChangeEvent, ReactNode } from 'react';
import { useCallback } from 'react';

import type { KeysMatching } from '../../../utils';

type CheckboxFieldValue = string | boolean | undefined;
export interface CheckboxFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, CheckboxFieldValue> = KeysMatching<T, CheckboxFieldValue>,
> extends Omit<CheckboxProps, 'name'> {
  name: K;

  label?: ReactNode;
}

export const CheckboxField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, CheckboxFieldValue> = KeysMatching<T, CheckboxFieldValue>,
>({
  name,
  label,
  onChange,
  ...props
}: CheckboxFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    render={(properties) => {
      const { value, onChange: onFormChange, onBlur, onFocus } = properties;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const handleOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          onFocus();
          onFormChange(e.target.checked as T[K]);
          onBlur();
        },
        [onFocus, onFormChange, onBlur],
      );
      const isChecked = !!value;

      return (
        <Checkbox
          isChecked={isChecked}
          w="100%"
          colorScheme="greenBrand"
          {...props}
          onChange={composeEventHandlers(onChange, handleOnChange as any)}
        >
          {label}
        </Checkbox>
      );
    }}
  />
);
