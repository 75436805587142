import type { FC } from 'react';
import { useCallback } from 'react';
import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { isEmail } from '@graneet/business-logic';

import { useIsEmailAvailable } from '../services/onboarding.api';

interface IsEmailAvailableRuleProps {
  onboardingId: string;
}

export const IsEmailAvailableRule: FC<IsEmailAvailableRuleProps> = ({ onboardingId }) => {
  const { t } = useTranslation(['global', 'onboarding']);

  const isEmailAvailableMutation = useIsEmailAvailable();

  const validationFn = useCallback(
    async (value: FieldValue) => {
      if (!isEmail(value)) {
        return false;
      }

      if (typeof value === 'string') {
        const response = await isEmailAvailableMutation.mutateAsync({
          id: onboardingId,
          dto: { email: value },
        });

        return response.isEmailValid;
      }

      throw new Error('Input must only contains string');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEmailAvailableMutation.mutateAsync, onboardingId],
  );

  return <Rule message={t('onboarding:emailNotAvailableRule')} validationFn={validationFn} />;
};
