/* eslint-disable no-bitwise */

export const ConfigurationMap = {
  style: 1 | (1 << 1) | (1 << 3),
  strikethrough: 1 << 2,
  code: 1 << 4,
  subscript: 1 << 5,
  superscript: 1 << 6,
  highlight: 1 << 7,
  block: 1 << 8,
  'undo-redo': (1 << 9) | (1 << 10),
  bullet: 1 << 11,
  numbered: 1 << 12,
  color: 1 << 13,
  fill: 1 << 14,
  link: 1 << 15,
  'clear-formatting': 1 << 16,
  variable: 1 << 17,
  'max-length': 1 << 18,
};

export type ConfigurationValue = keyof typeof ConfigurationMap;

export type RichTextVariableHeaderConfiguration = { type: 'header'; text: string };
export type RichTextVariableOptionConfiguration = { type: 'option'; value: string };

export type RichTextVariableConfiguration = {
  name: Extract<ConfigurationValue, 'variable'>;

  options: Array<RichTextVariableHeaderConfiguration | RichTextVariableOptionConfiguration>;

  optionsText: Record<string, { label: string; value: string | undefined }>;

  /**
   * If true, variable without value has an error style and variables with a value has their value displayed
   */
  injectValueInLabel: boolean;
};
export type RichTextMaxLengthConfiguration = {
  name: Extract<ConfigurationValue, 'max-length'>;
  maxLength: number;
};
export type RichTextConfiguration = Array<
  Exclude<ConfigurationValue, 'variable'> | RichTextVariableConfiguration | RichTextMaxLengthConfiguration
>;

export type RichTextBitwise = number;

export const getRichTextBitwiseFromConfiguration = (
  configuration: RichTextConfiguration,
): RichTextBitwise | undefined => {
  let bitwise: RichTextBitwise | undefined;

  configuration.forEach((c) => {
    if (typeof c === 'string') {
      (bitwise as RichTextBitwise) |= ConfigurationMap[c];
    } else {
      (bitwise as RichTextBitwise) |= ConfigurationMap[c.name];
    }
  });

  return bitwise;
};

export const hasRichTextConfiguration = (
  richTextBitwise: RichTextBitwise | undefined,
  configuration: ConfigurationValue,
) => (richTextBitwise ? !!(richTextBitwise & ConfigurationMap[configuration]) : false);

export const RICH_TEXT_BLOCK_TOOLBAR_PRESET: RichTextConfiguration = [
  'block',
  'style',
  'color',
  'fill',
  'bullet',
  'link',
  'clear-formatting',
];

export const RICH_TEXT_INLINE_TOOLBAR_PRESET: RichTextConfiguration = [
  'undo-redo',
  'style',
  'strikethrough',
  'superscript',
  'bullet',
  'numbered',
  'color',
];
