import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import type { ILotWithUUID } from '@graneet/business-logic';
import { DEFAULT_VAT_RATE } from '@graneet/business-logic';
import type { TreeContextApi } from '@graneet/lib-ui';
import { isNil } from 'lodash-es';

import { TreeDataPreCalculation } from '../../common/services/treeDataPreCalculation/treeDataPreCalculation.util';

import { useStore } from 'store/store';
import type {
  ContractItemComputedValue,
  ContractLotComputedValue,
  IContractInfosItemWithUUID,
  IContractInfosLotWithUUID,
} from 'features/contract/types/contract.type';

const THROW_ERROR = () => {
  throw new Error('No ContractDefaultVatRateContext found');
};

export interface IContractDefaultVatRate {
  getDefaultVATRate(lotId: ILotWithUUID['id']): number;

  setDefaultVATRate(lotId: ILotWithUUID['id'], vatRate: number): void;
}

export const ContractDefaultVatRateContext = createContext<IContractDefaultVatRate>({
  getDefaultVATRate: THROW_ERROR,
  setDefaultVATRate: THROW_ERROR,
});

export const useContractDefaultVatRateContext = () => useContext(ContractDefaultVatRateContext);

export interface ContractDefaultVatRateProviderProps {
  tree: TreeContextApi<
    IContractInfosLotWithUUID,
    IContractInfosItemWithUUID,
    ContractLotComputedValue,
    ContractItemComputedValue
  >;
}

export const ContractDefaultVatRateProvider: FC<PropsWithChildren<ContractDefaultVatRateProviderProps>> = ({
  children,
  tree,
}) => {
  const defaultVatRatesRef = useRef<Record<ILotWithUUID['id'], number>>({});

  const getDefaultVATRate = useCallback(
    (lotId: ILotWithUUID['id']) => defaultVatRatesRef.current[lotId] || DEFAULT_VAT_RATE,
    [],
  );

  const autoNumberingSetTable = useStore((state) => state.autoNumberingSetTable);
  const optionalLotsSetTable = useStore((state) => state.optionalLotsSetTable);
  const optionalLotsSetTableWithoutJob = useStore((state) => state.optionalLotsSetTableWithoutJob);

  const setDefaultVATRate = useCallback(
    (lotId: ILotWithUUID['id'], vatRate: number) => {
      defaultVatRatesRef.current[lotId] = vatRate;

      const childrenLotIds = tree.findDescendingChildrenOfNode(lotId).nodes;

      childrenLotIds.forEach((childLotId) => {
        defaultVatRatesRef.current[childLotId] = vatRate;
      }, []);
    },
    [tree],
  );

  useEffect(() => {
    const initialTree = tree.getInitialTree();

    const treeDataPreCalculation = new TreeDataPreCalculation(initialTree.relations, initialTree.leaves);
    autoNumberingSetTable(treeDataPreCalculation.autoNumberingTable);
    optionalLotsSetTable(treeDataPreCalculation.optionalLotsTable);
    optionalLotsSetTableWithoutJob(treeDataPreCalculation.optionalLotsWithoutJobInTheseChildren);
    const nodesOrderByDepth = tree.findSubNodesOrderByDepthDesc(initialTree.rootNodeId);

    const lotsWithUnknownVATRate: ILotWithUUID['id'][] = [];

    nodesOrderByDepth.forEach((id) => {
      const { leaves: directChildrenItems, nodes: directChildrenLots } = initialTree.relations[id];

      const defaultVatRateFromItems = directChildrenItems.reduce<number | undefined>((acc, itemId) => {
        const item = initialTree.leaves[itemId];
        if (acc === -1) {
          return acc;
        }
        if (acc === undefined) {
          return item.vatRate;
        }

        return acc === item.vatRate ? acc : -1;
      }, undefined);

      const defaultVatRateFromLots = directChildrenLots.reduce<number | undefined>((acc, lotId) => {
        if (acc === -1) {
          return acc;
        }
        if (acc === undefined) {
          return defaultVatRatesRef.current[lotId];
        }

        return acc === defaultVatRatesRef.current[lotId] ? acc : -1;
      }, undefined);

      // If the lots has no children we will determine it default VAT rate later
      // If the lots has no items and all nested lots don't have unknown vat rate
      if (
        (directChildrenItems.length === 0 && directChildrenLots.length === 0) ||
        (directChildrenItems.length === 0 && defaultVatRateFromLots === undefined)
      ) {
        lotsWithUnknownVATRate.push(id);
        return;
      }

      // If there is multiple VAT rates, the default VAT rate is DEFAULT_VAT_RATE
      if (defaultVatRateFromLots === -1 || defaultVatRateFromItems === -1) {
        defaultVatRatesRef.current[id] = DEFAULT_VAT_RATE;
        return;
      }

      // If there is the same VAT rate for all children, the default VAT rate is the only VAT rate
      if (
        (isNil(defaultVatRateFromItems) && defaultVatRateFromLots !== -1 && !!defaultVatRateFromLots) ||
        (isNil(defaultVatRateFromLots) && defaultVatRateFromItems !== -1 && !!defaultVatRateFromItems) ||
        defaultVatRateFromLots === defaultVatRateFromItems
      ) {
        defaultVatRatesRef.current[id] = defaultVatRateFromItems! || defaultVatRateFromLots!;
      }
    });

    lotsWithUnknownVATRate.reverse().forEach((lotId) => {
      const { parentNodeId } = tree.findNodePosition(lotId, { removeDeletedEntities: false });

      if (isNil(parentNodeId)) {
        // if root lot has unknown VAT rate, apply DEFAULT_VAT_RATE
        defaultVatRatesRef.current[lotId] = DEFAULT_VAT_RATE;
        return;
      }

      defaultVatRatesRef.current[lotId] = defaultVatRatesRef.current[parentNodeId];
    });
  }, [autoNumberingSetTable, optionalLotsSetTable, optionalLotsSetTableWithoutJob, tree]);

  const contractContext = useMemo<IContractDefaultVatRate>(
    () => ({
      getDefaultVATRate,
      setDefaultVATRate,
    }),
    [getDefaultVATRate, setDefaultVATRate],
  );

  return (
    <ContractDefaultVatRateContext.Provider value={contractContext}>{children}</ContractDefaultVatRateContext.Provider>
  );
};
