import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
import { Card, DataTable, TextField } from '@graneet/lib-ui';

import type { AccountingBankEditionBlockForm } from '../../forms/accounting.form';

import { Rule } from 'features/form/rules/Rule';

// @[ff: accounting-payment-methods]: delete whole component

interface AccountingBankEditionBlockProps {
  haveRequiredFields?: boolean;

  mustDisplayFields?: Record<keyof AccountingBankEditionBlockForm, boolean>;
}

export const AccountingBankEditionBlock: FC<AccountingBankEditionBlockProps> = ({
  mustDisplayFields,
  haveRequiredFields = false,
}) => {
  const { t } = useTranslation(['accounting']);

  const mustDisplayField = useCallback(
    (fieldName: keyof AccountingBankEditionBlockForm) => {
      if (!mustDisplayFields) {
        return true;
      }

      return !!mustDisplayFields[fieldName];
    },
    [mustDisplayFields],
  );

  return (
    <Stack>
      <Text fontSize="lg" color="gray.800">
        {t('accounting:parameters.sections.bank')}
      </Text>

      <Card>
        <DataTable numberOfColumns={1}>
          {mustDisplayField('accountBankSettlementWire') && (
            <DataTable.Row label={t('accounting:fields.accountBankSettlementWire')}>
              <DataTable.Cell>
                <TextField<AccountingBankEditionBlockForm>
                  name="accountBankSettlementWire"
                  placeholder={t('accounting:parameters.configPlaceholder')}
                >
                  {haveRequiredFields && <Rule.IsRequired />}
                </TextField>
              </DataTable.Cell>
            </DataTable.Row>
          )}
        </DataTable>
      </Card>
    </Stack>
  );
};
