import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { VStack } from '@chakra-ui/react';
import { Form, useStepForm } from 'graneet-form';
import { getCurrentDateAsString } from '@graneet/lib-ui';

import { CreditInformationFieldsSection } from 'features/credit/components/sections/CreditInformationFieldsSection';
import { NoteFieldSection } from 'features/common/components/fields/NoteFieldSection';
import type { CreditEditionInformationStepForm, CreditEditionWizard } from 'features/credit/forms/CreditEditionWizard';

interface EditCreditInformationsStepProps {
  cancelledInvoiceNumber?: string;
}

export const EditCreditInformationsStep: FC<EditCreditInformationsStepProps> = ({ cancelledInvoiceNumber }) => {
  const { form, initFormValues } = useStepForm<CreditEditionWizard, 'information'>();

  const { t } = useTranslation(['credit']);

  useEffect(() => {
    initFormValues({
      creditDate: getCurrentDateAsString(),
      name: t('credit:fields.name.initValue', { cancelledInvoiceNumber }),
    });
  }, [initFormValues, cancelledInvoiceNumber, t]);

  return (
    <Form form={form}>
      <VStack alignItems="flex-start">
        <CreditInformationFieldsSection />
        <NoteFieldSection<CreditEditionInformationStepForm> name="note" />
      </VStack>
    </Form>
  );
};
