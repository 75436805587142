import { useLayoutEffect } from 'react';
import { GridItem, SimpleGrid, Stack } from '@chakra-ui/react';
import { PERMISSION } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { BillAwaitingPaymentAmountCard } from 'features/bill/components/BillAwaitingPaymentAmountCard';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { Permissions } from 'features/role/components/Permissions';
import { DashboardQuoteStatsCard } from 'features/dashboard/components/DashboardQuoteStatsCard';
import { DashboardStatsCard } from 'features/dashboard/components/DashboardStatsCard';
import { DashboardProjectsInvoicingCard } from 'features/dashboard/components/DashboardProjectsInvoicingCard';
import { DashboardTimelineCard } from 'features/dashboard/components/DashboardTimelineCard';
import { useDashboardStats } from 'features/dashboard/services/dashboard.api';
import { SupplierInvoiceAwaitingPaymentAmountCard } from 'features/supplier-invoice/components/cards/SupplierInvoiceAwaitingPaymentAmountCard';
import { useQuotationApi } from 'features/quotation/services/quote.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

const SCREEN_PERMISSIONS = [PERMISSION.ACCESS_DASHBOARDS];

export const ViewDashboardScreen = () => {
  const { t } = useTranslation(['global']);
  const { updateHeaderTitle } = useHeaderContext();
  const hasPermissions = usePermissions(SCREEN_PERMISSIONS);

  const statsQuery = useDashboardStats({ isEnabled: hasPermissions });
  const stats = statsQuery.data;

  const { useQuoteStats } = useQuotationApi();
  const quoteStatsQuery = useQuoteStats();

  const history = useHistory();
  if (!hasPermissions) {
    history.push('/projects');
  }

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.dashboard'), []);
  }, [t, updateHeaderTitle]);

  return (
    <SimpleGrid p={8} spacing={4} columns={2}>
      <Stack flex={1} spacing={4}>
        <Permissions permissions={SCREEN_PERMISSIONS}>
          <Permissions.OnAccess>
            <DashboardStatsCard stats={stats} />
            <DashboardProjectsInvoicingCard stats={stats} />
          </Permissions.OnAccess>
        </Permissions>
      </Stack>

      <Stack flex={1} spacing={4}>
        <Permissions permissions={SCREEN_PERMISSIONS}>
          <Permissions.OnAccess>
            <DashboardQuoteStatsCard stats={stats} quoteStats={quoteStatsQuery.data} />
          </Permissions.OnAccess>
        </Permissions>

        <Permissions permissions={SCREEN_PERMISSIONS}>
          <Permissions.OnAccess>
            <BillAwaitingPaymentAmountCard
              amount={stats.remainingToBePaidIncVAT}
              amountLateIncVAT={stats.amountBillsLateIncVAT}
              amountToPayInLessThanThirtyDaysIncVAT={stats.amountBillsToPayInLessThanThirtyDaysIncVAT}
              amountToPayInMoreThanThirtyDaysIncVAT={stats.amountBillsToPayInMoreThanThirtyDaysIncVAT}
            />

            <SupplierInvoiceAwaitingPaymentAmountCard
              amount={stats.amountSupplierInvoicesNotPaidIncVAT}
              amountLateIncVAT={stats.amountSupplierInvoicesLateIncVAT}
              amountToPayInLessThanThirtyDaysIncVAT={stats.amountSupplierInvoicesToPayInLessThanThirtyDaysIncVAT}
              amountToPayInMoreThanThirtyDaysIncVAT={stats.amountSupplierInvoicesToPayInMoreThanThirtyDaysIncVAT}
              amountWithoutPaymentDateIncVAT={stats.amountSupplierInvoicesWithoutPaymentDateIncVAT}
            />
          </Permissions.OnAccess>
        </Permissions>
      </Stack>

      <Permissions permissions={SCREEN_PERMISSIONS}>
        <Permissions.OnAccess>
          <GridItem colSpan={2}>
            <DashboardTimelineCard />
          </GridItem>
        </Permissions.OnAccess>
      </Permissions>
    </SimpleGrid>
  );
};
