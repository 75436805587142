import { useMemo } from 'react';

import { useCurrency } from '../../../Currency';
import type { MaskInfo, MaskOptions } from '../mask.type';

const MAPPER_RADIX: Record<string, string[]> = {
  // Because `,` is only used in English as a radix, but
  // both commas and points have a meaning we simply cannot
  // map `.` to anything else.
  // 999 + '.' + 99 => 999.99
  // 999 + ',' + 99 => 99,999
  '.': [],
  // Because `,` is only used in English as a radix, but
  // In French, because we don't use comas, if the user
  // types one, we map it to '.' to start decimals
  // 999 + '.' + 99 => 999.99
  // 999 + ',' + 99 => 999.99
  ',': ['.'],
};

const NEGATIVE_SIGN = '-';

export const useCurrencyMask = (options?: MaskOptions): MaskInfo => {
  const { min, max, forceNegative } = options || {};

  const { defaultFormatter, intFormatter, formatAsAmount, currency } = useCurrency();
  /**
   * @example
   * `EUR_FR_FR` => '0.00€'
   * `XOF_FR_FR` => '0CFA'
   */
  const placeholder = formatAsAmount(0);
  /**
   * Generates a mask which dictates to inputs where
   * to insert the numeric value (aka `num`) relative to the
   * localized currency symbol.
   * @example
   * `EUR_FR_FR` => 'num €'
   * `XOF_FR_FR` => 'num CFA'
   */
  const mask = intFormatter()
    .formatToParts(0)
    .reduce((maskMemory, part) => {
      switch (part.type) {
        case 'integer':
          return `${forceNegative ? NEGATIVE_SIGN : ''}${maskMemory}num`;
        default:
          return `${maskMemory}${part.value}`;
      }
    }, '');

  /**
   * Generates a configuration that iMask can process
   * to format the numeric value for the `num` placeholder
   * whose format is defined by `mask` above.
   * We use 1000, so we can deduct the separator character
   * between thousands.
   */
  const maskInfos = defaultFormatter()
    .formatToParts(1000)
    .reduce((infos, part) => {
      switch (part.type) {
        case 'decimal':
          return {
            ...infos,
            radix: part.value,
            mapToRadix: MAPPER_RADIX[part.value],
          };
        case 'group':
          return {
            ...infos,
            thousandsSeparator: part.value,
          };
        default:
          return infos;
      }
    }, {});

  return useMemo<MaskInfo>(
    () => ({
      placeholder,
      mask: [
        {
          mask: '',
        },
        {
          mask,
          lazy: false,
          blocks: {
            num: {
              padFractionalZeros: false,
              scale: currency.precision,
              signed: forceNegative ? false : typeof min === 'undefined' || min === null || min < 0,
              min,
              max,
              ...maskInfos,
              mask: Number,
            },
          },
        },
      ],
      // Disable rule to allow JSON.stringify(maskInfos)
      /* eslint-disable react-hooks/exhaustive-deps */
    }),
    [currency.precision, min, max, mask, placeholder, JSON.stringify(maskInfos)],
  );
};
