import { createQuoteNodesDuplicateCallback } from './createQuoteNodesDuplicateCallback';
import { createQuoteCreateManyCallback } from './createQuoteCreateManyCallback';

import type { QuoteNodesCreateWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';

export const quoteNodeCallbacks = (quoteId: string) => [
  {
    channel: `private-quote-${quoteId}`,
    event: 'node:duplicated',
    callback: (message: QuoteNodesCreateWsMessage) => createQuoteNodesDuplicateCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'node:create-many',
    callback: (message: QuoteNodesCreateWsMessage) => createQuoteCreateManyCallback(message),
  },
];
