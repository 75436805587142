import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SimpleDeleteIcon } from '@graneet/lib-ui';
import type { SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { getSupplierInvoiceBeDeleted } from 'features/supplier-invoice/services/supplier-invoices.util';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface DirectPaymentRemoveButtonProps {
  onClick(): void;

  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}

export const DirectPaymentRemoveButton: FC<DirectPaymentRemoveButtonProps> = ({ onClick, supplierInvoice }) => {
  const { t } = useTranslation(['project', 'supplierInvoices', 'global']);

  const editDirectPaymentSupplierInvoiceDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  const canBeDeleted = useMemo(() => getSupplierInvoiceBeDeleted(supplierInvoice, t), [supplierInvoice, t]);

  const disabledProps = editDirectPaymentSupplierInvoiceDisabledProps();

  const hasAccessToSupplierInvoiceModule = usePermissions([PERMISSION.ACCESS_SUPPLIER_INVOICE]);
  if (!hasAccessToSupplierInvoiceModule) {
    return null;
  }

  return (
    <Button
      variant="outline"
      color="red.500"
      leftIcon={<SimpleDeleteIcon stroke="red.500" />}
      onClick={onClick}
      {...editDirectPaymentSupplierInvoiceDisabledProps()}
      isDisabled={disabledProps.isDisabled || canBeDeleted.isDisabled}
      tooltip={disabledProps.tooltip || canBeDeleted.tooltip}
    >
      {t('project:directPayments.directPaymentSupplierInvoiceDetail.deleteAssociation')}
    </Button>
  );
};
