import { Flex, HStack, Skeleton, SkeletonCircle } from '@chakra-ui/react';

export const QuoteHeaderSkeleton = () => (
  <Flex alignItems="center" gap={2}>
    <Flex flex={1} justifyContent="flex-start" alignItems="center">
      <HStack gap={3} flex={1}>
        <Skeleton borderRadius="8px" height="32px" width="32px" startColor="gray.50" endColor="gray.300" />
        <Skeleton borderRadius="8px" width="300px" height="32px" />
      </HStack>
    </Flex>
    <SkeletonCircle />
    <Flex justifyContent="flex-end" alignItems="center" gap={3}>
      <Skeleton borderRadius="8px" width="85px" height="32px" />
      <Skeleton borderRadius="8px" width="170px" height="32px" />
      <Skeleton borderRadius="8px" width="115px" height="32px" />
      <Skeleton borderRadius="8px" width="115px" height="32px" />
      <Skeleton borderRadius="8px" width="70px" height="32px" />
      <Skeleton borderRadius="8px" width="32px" height="32px" />
    </Flex>
  </Flex>
);
