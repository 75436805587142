import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleFlipBackwardIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" strokeWidth="2" stroke="#334e68" {...props}>
    <path
      d="M3 9H16.5C18.9853 9 21 11.0147 21 13.5C21 15.9853 18.9853 18 16.5 18H12M3 9L7 5M3 9L7 13"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
