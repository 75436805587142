import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import type { Response } from '@graneet/lib-ui';
import {
  Card,
  Tooltip,
  SimpleHelpIcon,
  useToast,
  RichTextField,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, useForm } from 'graneet-form';
import { Box, HStack } from '@chakra-ui/react';

interface FormValues {
  text: string | undefined | null;
}

export interface TextEditionCardProps {
  text?: string | null;
  onTextEdited(newValue: FormValues['text']): Promise<Response<unknown> | object>;
  errorLabel: string;
  successLabel: string;
  title?: string;
  placeholder: string;
  isEditable?: boolean;
  tooltip?: string;
}

export const TextEditionCard: FC<TextEditionCardProps> = ({
  text,
  onTextEdited,
  errorLabel,
  successLabel,
  title,
  placeholder,
  isEditable = true,
  tooltip,
}) => {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const onUpdateAfterBlur = useCallback(
    async (_: keyof FormValues, textRawValue: FormValues['text']) => {
      const response = await onTextEdited(textRawValue);

      if (Array.isArray(response) && response[0]) {
        toast.error(t('global:words.c.error'), errorLabel);
        return;
      }
      toast.success(t('global:words.c.success'), successLabel);
    },
    [errorLabel, successLabel, onTextEdited, toast, t],
  );

  const form = useForm<FormValues>({ onUpdateAfterBlur });
  const { setFormValues } = form;

  useEffect(() => {
    setFormValues({ text });
  }, [text, setFormValues]);

  return (
    <Card
      title={
        title && (
          <HStack>
            <Box>{title}</Box>
            {tooltip && (
              <Tooltip label={tooltip} placement="right-end">
                <Box mb={0} display="flex" alignItems="center">
                  <SimpleHelpIcon boxSize={5} />
                </Box>
              </Tooltip>
            )}
          </HStack>
        )
      }
    >
      <Form form={form}>
        <RichTextField<FormValues>
          inputProps={{ minH: '4rem' }}
          name="text"
          navbarType="inline"
          placeholder={placeholder}
          isDisabled={!isEditable}
          configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
        />
      </Form>
    </Card>
  );
};
