import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import type { FieldValues } from 'graneet-form';
import type { TooltipProps } from '@chakra-ui/react';

import type { CurrencyFieldProps, NumberFieldProps } from '../../Field';
import { CurrencyField, NumberField } from '../../Field';
import type { InputProps } from '../../Input/Input';
import { Tooltip } from '../../Tooltip';

export type MultiplicationCurrencyFieldProps = CurrencyFieldProps<FieldValues> & {
  computedName: string;

  tooltip?: string | ReactNode;

  tooltipProps?: Omit<TooltipProps, 'children'>;

  isCurrency: true;
};

export type MultiplicationNumberFieldProps = NumberFieldProps<FieldValues> & {
  computedName: string;

  tooltip?: string | ReactNode;

  tooltipProps?: Omit<TooltipProps, 'children'>;

  isCurrency?: false;
};

export type MultiplicationFieldWithComputedNameProps =
  | MultiplicationCurrencyFieldProps
  | MultiplicationNumberFieldProps;

export const MultiplicationFieldWithComputedName: FC<MultiplicationFieldWithComputedNameProps> = ({
  computedName,
  isCurrency = false,
  isDisabled = false,
  isReadOnly = false,
  tooltip,
  tooltipProps,
  ...otherProps
}) => {
  const ParentField = isCurrency ? CurrencyField : NumberField;

  const inputProps = useMemo<InputProps>(
    () => ({
      color: 'gray.700',
      fontFamily: 'heading',
      fontSize: 16,
      borderStyle: 'solid',
      backgroundColor: isDisabled || isReadOnly ? 'transparent !important' : 'white !important',
      borderWidth: isDisabled || isReadOnly ? '1px' : '2px',
    }),
    [isDisabled, isReadOnly],
  );

  const labelProps = useMemo(
    () => ({
      fontFamily: 'Open Sans',
      fontSize: 14,
    }),
    [],
  );

  return tooltip ? (
    <Tooltip shouldWrapChildren label={tooltip} {...(tooltipProps ?? {})}>
      <ParentField<any>
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        labelProps={labelProps}
        inputProps={inputProps}
        {...otherProps}
      />
    </Tooltip>
  ) : (
    <ParentField<any>
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      labelProps={labelProps}
      inputProps={inputProps}
      {...otherProps}
    />
  );
};
