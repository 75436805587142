import { useTranslation } from 'react-i18next';
import type { FileItem } from '@graneet/lib-ui';
import { Button, Card, FileNameList, SimpleAddIcon } from '@graneet/lib-ui';
import { Box, useDisclosure, VStack } from '@chakra-ui/react';
import type { ILibraryJobResponseDTO } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { useLibraryJobAddFiles, useLibraryJobDeleteFiles } from '../services/library-job.api';

import { LibraryJobFields } from './LibraryJobFields';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { AddJobImagesModal } from 'features/file/components/AddJobImagesModal';

interface ILibraryJobComponentProps {
  libraryJob: ILibraryJobResponseDTO;

  onLibraryJobChange(): void;
}

export const LibraryJobEditCard: FC<ILibraryJobComponentProps> = ({ libraryJob, onLibraryJobChange }) => {
  const { t } = useTranslation(['global', 'library', 'quote']);
  const hasUpdateLibraryPermission = usePermissions([PERMISSION.UPDATE_LIBRARY]);
  const addImagesModalControls = useDisclosure();
  const { onOpen: openAddImagesModal } = addImagesModalControls;

  const libraryJobAddFilesMutation = useLibraryJobAddFiles();
  const libraryJobDeleteFilesMutation = useLibraryJobDeleteFiles();

  const libraryJobFiles = useMemo(() => (libraryJob?.libraryJobFile || []).map(({ file }) => file), [libraryJob]);

  const itemFiles = useMemo(
    () => (libraryJobFiles || []).filter((file) => file.url !== null).map(({ id, name, url }) => ({ id, name, url })),
    [libraryJobFiles],
  ) as FileItem[];

  const isUnitDisbursementEditable = useMemo(() => !libraryJob?.hasComponents, [libraryJob?.hasComponents]);

  /**
   * upload file of job.
   */
  const handleAddImages = useCallback(
    async (files: File[]) => {
      if (files.length) {
        await libraryJobAddFilesMutation.mutateAsync({ id: libraryJob.id, files });
        onLibraryJobChange();
      }
    },
    [libraryJob.id, libraryJobAddFilesMutation, onLibraryJobChange],
  );

  /**
   * delete file of job.
   */
  const handleDeleteJobFile = useCallback(
    async (fileId: string | number) => {
      if (fileId) {
        await libraryJobDeleteFilesMutation.mutateAsync(
          { id: libraryJob.id, fileId },
          {
            onError: () => {
              // To reset state in the image list component
              throw new Error('Error on deleting job file');
            },
            onSuccess: () => {
              onLibraryJobChange();
            },
          },
        );
      }
      throw new Error('No file Id was provided');
    },
    [libraryJob.id, libraryJobDeleteFilesMutation, onLibraryJobChange],
  );

  return (
    <>
      <Box w="100%">
        <Card title={t('global:words.c.information')}>
          <VStack align="start" spacing={2}>
            <LibraryJobFields
              isDisabled={!hasUpdateLibraryPermission}
              isUnitDisbursementEditable={isUnitDisbursementEditable}
            />
            <Box as="span" display="flex" alignItems="center" flexWrap="wrap">
              <FileNameList files={itemFiles} onFileDelete={handleDeleteJobFile} openCarousel />

              <Button leftIcon={<SimpleAddIcon />} variant="outline" onClick={openAddImagesModal} mt={2}>
                {t('library:actions.addImages')}
              </Button>
            </Box>
          </VStack>
        </Card>
      </Box>
      <AddJobImagesModal
        isOpen={addImagesModalControls.isOpen}
        onClose={addImagesModalControls.onClose}
        handleAddImages={handleAddImages}
        handleDeleteImage={handleDeleteJobFile}
        images={libraryJobFiles}
      />
    </>
  );
};
