import { useMemo } from 'react';
import type { KeysMatching, SingleSelectFieldProps } from '@graneet/lib-ui';
import { SingleSelectField } from '@graneet/lib-ui';
import { SUPPLIER_TYPE } from '@graneet/business-logic';
import type { FieldValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { Circle, Flex } from '@chakra-ui/react';

import { SUPPLIER_COLORS } from './SupplierTypeBadge';

type FieldValue = SUPPLIER_TYPE | undefined;

export interface SupplierTypeFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, FieldValue> = KeysMatching<T, FieldValue>,
> extends Omit<SingleSelectFieldProps<T, K>, 'options' | 'isSearchable'> {}

export const SupplierTypeField = <
  T extends FieldValues,
  K extends KeysMatching<T, FieldValue> = KeysMatching<T, FieldValue>,
>(
  props: SupplierTypeFieldProps<T, K>,
) => {
  const { t } = useTranslation(['supplier']);

  const options = useMemo(
    () =>
      Object.values(SUPPLIER_TYPE).map((type) => ({
        value: type,
        label: (
          <Flex gap={2} alignItems="center">
            <Circle size="0.725rem" bg={SUPPLIER_COLORS[type]} />
            {t(`supplier:types.${type}`)}
          </Flex>
        ),
      })),
    [t],
  );

  return <SingleSelectField<T, K> options={options as any} isSearchable={false} isClearable={false} {...props} />;
};
