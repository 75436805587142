import type { PaginatedResponse } from '../pagination/paginated-response.type';
import type { BILL_STATUSES } from '../bill/bill.type';

export enum STATEMENT_TYPES {
  INVOICE = 'INVOICE',
  PROGRESS_STATEMENT = 'PROGRESS_STATEMENT',
  DIRECT_PROGRESS_STATEMENT = 'DIRECT_PROGRESS_STATEMENT',
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  CREDIT = 'CREDIT',
  HOLDBACK = 'HOLDBACK',
}

export type STATEMENT_SALES_TYPES = Exclude<STATEMENT_TYPES, STATEMENT_TYPES.HOLDBACK>;

export enum STATEMENT_STATUSES {
  DRAFT = 'DRAFT',
  VALIDATED = 'VALIDATED',
  COMPLETED = 'COMPLETED',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
}

export interface IStatement {
  id: number;

  name: string;

  clientName: string;

  invoiceNumber: string;

  purchaseOrderNumber: string;

  companyId: number;

  projectId: number;

  amountExVAT: number;

  status: STATEMENT_STATUSES;

  billingDate: Date;

  type: STATEMENT_TYPES;

  projectName?: string;

  projectCode?: string;

  cumulativeProgressPercentage?: number;

  isArchived: boolean;

  groupId?: number;

  subProjectId: string | null;

  subProjectName: string | null;

  tags: string[];

  billStatus: BILL_STATUSES | null;
}

export type StatementPaginatedResponse = PaginatedResponse<IStatement, 'amountExVAT'>;
