import { useTranslation } from 'react-i18next';
import { Button, Modal, SimpleAddIcon, TextField } from '@graneet/lib-ui';
import type { ButtonProps } from '@chakra-ui/react';
import { VStack, useDisclosure } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Form, useForm } from 'graneet-form';
import type { IAccountingConfigBank } from '@graneet/business-logic';

import { useCreateAccoutingConfigBank } from '../../../services/accounting-config.api';

import { Rule } from 'features/form/rules/Rule';

type FormValues = Pick<IAccountingConfigBank, 'journal' | 'label'>;

export const AccoutingAddBankButton: FC<ButtonProps> = ({ ...other }) => {
  const { t } = useTranslation(['accounting', 'global']);

  const modal = useDisclosure();

  const form = useForm<FormValues>();

  const createAccountingConfigBankMutation = useCreateAccoutingConfigBank();

  const onCreate = useCallback(async () => {
    const formValues = form.getFormValues();

    await createAccountingConfigBankMutation.mutateAsync(
      {
        label: formValues.label!,
        journal: formValues.journal!,
      },
      { onSuccess: () => form.resetForm() },
    );

    setTimeout(modal.onClose, 500);
  }, [createAccountingConfigBankMutation, form, modal]);

  return (
    <>
      <Button variant="outline" onClick={modal.onOpen} leftIcon={<SimpleAddIcon />} {...other}>
        {t('accounting:addBank.title')}
      </Button>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t('accounting:addBank.title')}>
        <Form form={form}>
          <VStack gap={2}>
            <TextField<FormValues> name="label" label={t('accounting:fields.bankLabel')}>
              <Rule.IsRequired />
            </TextField>

            <TextField<FormValues> name="journal" label={t('accounting:fields.bankCode')}>
              <Rule.IsRequired />
            </TextField>

            <Modal.CloseButton isDisabled={createAccountingConfigBankMutation.isPending} />
            <Modal.PrimaryButton onClick={onCreate} isLoading={createAccountingConfigBankMutation.isPending}>
              {t('global:words.c.create')}
            </Modal.PrimaryButton>
          </VStack>
        </Form>
      </Modal>
    </>
  );
};
