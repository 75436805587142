import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleBuildingIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props} fill="none">
    <path
      d="M7.33331 7.33333H4.13331C3.38657 7.33333 3.01321 7.33333 2.72799 7.47867C2.47711 7.60647 2.27313 7.81047 2.14531 8.06133C1.99998 8.34653 1.99998 8.71993 1.99998 9.46667V14M14 14V4.13333C14 3.3866 14 3.01323 13.8546 2.72801C13.7268 2.47713 13.5228 2.27315 13.272 2.14533C12.9868 2 12.6134 2 11.8666 2H9.46665C8.71991 2 8.34651 2 8.06131 2.14533C7.81045 2.27315 7.60645 2.47713 7.47865 2.72801C7.33331 3.01323 7.33331 3.3866 7.33331 4.13333V14M14.6666 14H1.33331M9.66665 4.66667H11.6666M9.66665 7.33333H11.6666M9.66665 10H11.6666"
      stroke="#627D98"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
