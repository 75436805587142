import type { FC, ReactNode } from 'react';
import { Children, cloneElement, createContext, useContext, useMemo } from 'react';
import type { FlexProps, GridItemProps, GridProps } from '@chakra-ui/react';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';

import { Card } from '../Card/Card';
import { Cell } from '../Table';

import { StickyContent } from './StickyContent';

// -- Styles

const BORDER_NONE = { border: 0 };
const BORDER_DEFAULT = {
  borderBottom: '1px solid',
  borderColor: 'gray.200',
};

// -- Context
export interface DeepTableContextApi {
  deepTable: {
    templateColumns: string;
    leftContentWidth: number;
  };
  gridProps: GridProps;
}
const TableCtx = createContext<DeepTableContextApi>({
  deepTable: {
    templateColumns: '',
    leftContentWidth: 0,
  },
  gridProps: {},
});

// -- Hooks

export const useDeepTable = ({ templateColumns, leftContentWidth }: DeepTableContextApi['deepTable']) =>
  useMemo(
    () => ({
      templateColumns,
      leftContentWidth,
    }),
    [templateColumns, leftContentWidth],
  );

// -- Components
export interface DeepTableProps extends FlexProps {
  deepTable: DeepTableContextApi['deepTable'];
  noCard?: boolean;
  gridProps?: DeepTableContextApi['gridProps'];
}

const DeepTableComponent: FC<DeepTableProps> = ({
  deepTable,
  children,
  noCard = false,
  gridProps = {},
  ...otherProps
}) => {
  const context = useMemo(
    () => ({
      deepTable,
      gridProps,
    }),
    [deepTable, gridProps],
  );

  return (
    <TableCtx.Provider value={context}>
      {noCard ? (
        <Flex {...otherProps} overscrollBehavior="contain">
          <Box flex={1}>{children}</Box>
        </Flex>
      ) : (
        <Card p={0} {...otherProps} overscrollBehavior="contain">
          {children}
        </Card>
      )}
    </TableCtx.Provider>
  );
};

export interface DeepTableRowProps extends FlexProps {
  leftContent?: ReactNode;
  offset?: number;
  noBorder?: boolean;
  noLeftSpacing?: boolean;
  noLeftContent?: boolean;
}

/**
 * If the prop noLeftSpacing is specified the leftContent should not be passed
 */
const Row: FC<DeepTableRowProps> = ({
  children,
  leftContent = null,
  offset = 0,
  noBorder = false,
  noLeftSpacing = false,
  noLeftContent = false,
  onClick,
  ...props
}) => {
  const {
    deepTable: { leftContentWidth, templateColumns },
    gridProps,
  } = useContext(TableCtx);

  // The default behavior is to add left spacing to align nesting lots
  // But you can pass noLeftSpacing prop to remove this spacing
  const leftSpacing = noLeftSpacing ? 0 : leftContentWidth - offset;
  const gridTemplateColumns = `${leftSpacing}rem ${templateColumns}`;
  const content = (
    <Grid templateColumns={gridTemplateColumns} px={4} gap={4} flex={1} {...gridProps} data-testid="deepTableRow">
      {!noLeftContent && (
        <Flex h="auto" alignItems="center" justifyContent="flex-start">
          {leftContent}
        </Flex>
      )}

      {children}
    </Grid>
  );

  return (
    <Flex
      {...(noBorder ? BORDER_NONE : BORDER_DEFAULT)}
      onClick={onClick}
      _hover={onClick ? { cursor: 'pointer' } : undefined}
      {...props}
    >
      {Children.map(content, (element) => cloneElement(element, { flex: 1 }))}
    </Flex>
  );
};

const Header: FC<DeepTableRowProps> = ({ children, leftContent: leftContentNode = null, ...props }) => {
  const { deepTable: leftContentWidth } = useContext(TableCtx);

  const leftContent = leftContentNode || (leftContentWidth && <Cell />);

  return (
    <Row
      fontWeight="600"
      color="gray.700"
      fontFamily="heading"
      _last={undefined}
      offset={0}
      leftContent={leftContent}
      {...props}
    >
      {children}
    </Row>
  );
};

export interface SeparatorProps extends DeepTableRowProps {
  depth: number;
  colSpan: GridItemProps['colSpan'];
}

const Separator: FC<SeparatorProps> = ({ depth, colSpan, ...props }) => (
  <Row offset={depth * 0.5} noBorder {...props}>
    <GridItem colSpan={colSpan} h={2} />
  </Row>
);

export const DeepTable = Object.assign(DeepTableComponent, {
  Row,
  Header,
  Cell,
  Separator,
  StickyContent,
});
