import { useCallback } from 'react';
import Big from 'big.js';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { quoteLibrarySavedSetToStore } from 'features/quotation/quote-common/store/quoteLibrarySavedSetToStore';
import { useStore } from 'store/store';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export const useQuoteLibrarySaveComponent = () => {
  const { quoteLibraryProxyApi } = useQuotationProxyApis();
  const { quote } = useQuote();
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);
  const editorRef = useStore((state) => state.quoteRichTextEditorRef);
  const quoteError = useQuoteError();

  return useCallback(
    async (nodeId: string) => {
      try {
        const component = quote.getTree().getQuoteComponentOrThrow(nodeId).export();

        const { denomination, unit, unitFlatCostAmount, componentTypeId, refCode } = component;

        if (!denomination || !unit || !unitFlatCostAmount || !componentTypeId) {
          throw new Error('Invalid component data');
        }

        const plainDenomination = editorRef?.current?.getPlainText(denomination) ?? '';
        await quoteLibraryProxyApi?.createComponent({
          refCode,
          description: plainDenomination,
          unit,
          unitFlatCost: Big(unitFlatCostAmount).toNumber(),
          componentTypeId,
        });
        quoteLibrarySavedSetToStore(true);
        toast.success(t('quote:quotation.library.success'));
      } catch (err: any) {
        return quoteError(err.message);
      }

      return true;
    },
    [editorRef, quote, quoteError, quoteLibraryProxyApi, t, toast],
  );
};
