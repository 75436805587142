import { MarginComputedValue, type QuoteMarginDTO } from '@org/graneet-bff-client';
import { MARGIN_COMPUTED_VALUE, type QuoteMarginObject } from '@org/quotation-lib';

export const mapComputed = (value: MARGIN_COMPUTED_VALUE) => {
  switch (value) {
    case MARGIN_COMPUTED_VALUE.PROFIT:
      return MarginComputedValue.Profit;
    case MARGIN_COMPUTED_VALUE.TOTAL:
      return MarginComputedValue.Total;
    default:
      throw new Error('margin enum type is not matching');
  }
};

export const mapMarginObjectToMarginDTO = (quoteMarginObject: QuoteMarginObject | null): QuoteMarginDTO | undefined => {
  if (quoteMarginObject) {
    return {
      overheadCosts: quoteMarginObject.overheadCosts,
      profitMargin: quoteMarginObject.profitMargin,
      totalMargin: quoteMarginObject.totalMargin,
      computed: mapComputed(quoteMarginObject.computed),
    };
  }
  return undefined;
};
