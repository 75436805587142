import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleHelpIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" fill="#627D98" {...props}>
    <g clipPath="url(#clip0_75_1519)">
      <path d="M6.68664 6.22C6.78664 5.92 6.99331 5.66667 7.26664 5.50667C7.53331 5.34667 7.85331 5.28667 8.16664 5.34C8.47331 5.38667 8.75331 5.55334 8.95997 5.79334C9.15997 6.03334 9.27331 6.33334 9.26664 6.65334C9.25997 6.96667 9.01997 7.29334 8.55997 7.59334C8.37331 7.71334 8.17331 7.82 7.96664 7.91334C7.84664 7.96 7.75331 8 7.71331 8.01334C7.35997 8.12667 7.17331 8.50667 7.28664 8.85334C7.39997 9.2 7.77997 9.38667 8.12664 9.27334C8.42664 9.16667 8.84664 8.98 9.27997 8.69334C10.0666 8.16 10.5733 7.48667 10.5733 6.63334C10.5733 6 10.3466 5.39334 9.94664 4.90667C9.53997 4.42 8.97331 4.1 8.35331 3.99334C7.72664 3.88667 7.09331 4 6.54664 4.32C5.99997 4.63334 5.58664 5.14 5.37997 5.73334C5.25331 6.08 5.43997 6.46 5.78664 6.58C6.13331 6.7 6.51331 6.51334 6.63331 6.16667L6.68664 6.22ZM7.99331 11.9933H7.99997C8.36664 11.9933 8.66664 11.6933 8.66664 11.3267C8.66664 10.9533 8.36664 10.66 7.99997 10.66H7.99331C7.61997 10.66 7.32664 10.9533 7.32664 11.3267C7.32664 11.6933 7.61997 11.9933 7.99331 11.9933ZM13.9933 7.99334C13.9933 11.3067 11.3066 13.9933 7.99331 13.9933C4.67331 13.9933 1.99331 11.3067 1.99331 7.99334C1.99331 4.67334 4.67331 1.99334 7.99331 1.99334C11.3066 1.99334 13.9933 4.67334 13.9933 7.99334ZM15.3266 7.99334C15.3266 3.94 12.04 0.660004 7.99331 0.660004C3.93997 0.660004 0.659973 3.94 0.659973 7.99334C0.659973 12.04 3.93997 15.3267 7.99331 15.3267C12.04 15.3267 15.3266 12.04 15.3266 7.99334Z" />
    </g>
    <defs>
      <clipPath id="clip0_75_1519">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
