import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { IBankingTransactionWithRelations } from '@graneet/business-logic';
import { RICH_TEXT_INLINE_TOOLBAR_PRESET, RichTextField } from '@graneet/lib-ui';
import { Form, useForm } from 'graneet-form';

import { useBankingTransactionUpdate } from '../services/banking-transaction.api';

interface FormValues {
  note: string | undefined | null;
}

interface BankingTransactionCommentProps {
  bankingTransaction: IBankingTransactionWithRelations;
}

export const BankingTransactionComment: FC<BankingTransactionCommentProps> = ({ bankingTransaction }) => {
  const { t } = useTranslation(['banking']);

  const bankingTransactionUpdateMutation = useBankingTransactionUpdate();

  const form = useForm<FormValues>({
    defaultValues: {
      note: bankingTransaction.note,
    },
    onUpdateAfterBlur: (_, value) => {
      bankingTransactionUpdateMutation.mutate({
        id: bankingTransaction.id,
        dto: { note: value ?? '' },
      });
    },
  });

  return (
    <Form form={form}>
      <RichTextField<FormValues>
        name="note"
        label={t('banking:bankingTransaction.fields.note')}
        navbarType="inline"
        inputProps={{
          minH: '5rem',
        }}
        placeholder={t('banking:bankingTransaction.placeholders.note')}
        configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
      />
    </Form>
  );
};
