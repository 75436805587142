import type { Raw } from '../common/entity.type';
import { getCalculatedTotalMargin } from '../margin/margin.util';
import { multiplyFloating, roundFloating, divideFloating } from '../common/math.util';
import type { IMargin } from '../margin/margin.type';
import { MARGIN_COMPUTED_VALUE } from '../margin/margin.type';
import { isNumberFinite } from '../common/number.util';

import type { IQuoteComponent } from './quote-component.type';

export const computeQuoteComponentTotalMargin = (
  quoteComponent: Raw<IQuoteComponent>,
  newAmountExVAT: number,
): number => divideFloating(newAmountExVAT, quoteComponent.disbursementExVAT);

export const computeQuoteComponentMarginFromAmounts = (
  quoteComponent: IQuoteComponent,
  newAmountExVAT: number,
  shouldForceOverheadCostsUpdate: boolean,
): IMargin => {
  const margin =
    quoteComponent.margin ||
    ({
      overheadCosts: 1,
      profitMargin: 1,
      totalMargin: 1,
      computed: MARGIN_COMPUTED_VALUE.TOTAL,
    } as IMargin);

  let { profitMargin, overheadCosts } = margin;

  const totalMargin = computeQuoteComponentTotalMargin(quoteComponent, newAmountExVAT);

  if (shouldForceOverheadCostsUpdate) {
    overheadCosts = divideFloating(totalMargin, margin.profitMargin);
  } else {
    profitMargin = divideFloating(totalMargin, margin.overheadCosts);
  }
  return {
    ...margin,
    overheadCosts,
    profitMargin,
    totalMargin,
    computed: MARGIN_COMPUTED_VALUE.PROFIT,
  };
};

export const isQuoteComponentCompleted = (component: IQuoteComponent): boolean =>
  isNumberFinite(component.quantity) && isNumberFinite(component.unitDisbursementExVAT);

export const computeQuoteComponentUnitAmountExVAT = (
  disbursementExVAT: number | null,
  margin: IMargin,
): number | null =>
  isNumberFinite(disbursementExVAT) ? multiplyFloating(disbursementExVAT, getCalculatedTotalMargin(margin)) : null;

export const computeQuoteComponentAmountExVAT = (disbursementExVAT: number, totalMargin: number): number =>
  isNumberFinite(disbursementExVAT) && isNumberFinite(totalMargin)
    ? roundFloating(multiplyFloating(disbursementExVAT, totalMargin))
    : 0;

export const computeQuoteComponentDisbursementExVAT = (
  quantity: number,
  unitDisbursementExVAT: number | null,
): number =>
  isNumberFinite(quantity) && isNumberFinite(unitDisbursementExVAT)
    ? roundFloating(multiplyFloating(quantity, unitDisbursementExVAT))
    : 0;

export const computeQuoteComponentDataByQuantity = (
  data: number | null,
  quantityInJob: number | null,
): number | null =>
  isNumberFinite(data) && isNumberFinite(quantityInJob) ? multiplyFloating(data, quantityInJob) : null;
