import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleOrderIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props} fill="none">
    <g clipPath="url(#clip0_159_2603)">
      <path
        d="M1.33331 1.33333H2.20409C2.3681 1.33333 2.45011 1.33333 2.5161 1.36349C2.57426 1.39007 2.62355 1.43281 2.65808 1.48663C2.69727 1.54769 2.70887 1.62888 2.73206 1.79124L3.0476 4M3.0476 4L3.74886 9.15426C3.83785 9.80833 3.88235 10.1354 4.03871 10.3815C4.1765 10.5985 4.37403 10.7709 4.60755 10.8783C4.87255 11 5.20261 11 5.86272 11H11.568C12.1963 11 12.5105 11 12.7673 10.8869C12.9936 10.7873 13.1878 10.6265 13.3282 10.4228C13.4872 10.1917 13.546 9.88313 13.6636 9.26586L14.546 4.63313C14.5874 4.41587 14.6081 4.30725 14.5781 4.22233C14.5518 4.14785 14.4999 4.08512 14.4316 4.04535C14.3538 4 14.2433 4 14.0221 4H3.0476ZM6.66665 14C6.66665 14.3682 6.36817 14.6667 5.99998 14.6667C5.63179 14.6667 5.33331 14.3682 5.33331 14C5.33331 13.6318 5.63179 13.3333 5.99998 13.3333C6.36817 13.3333 6.66665 13.6318 6.66665 14ZM12 14C12 14.3682 11.7015 14.6667 11.3333 14.6667C10.9651 14.6667 10.6666 14.3682 10.6666 14C10.6666 13.6318 10.9651 13.3333 11.3333 13.3333C11.7015 13.3333 12 13.6318 12 14Z"
        stroke="#627D98"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_159_2603">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
