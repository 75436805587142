import {
  PDF_STATUSES,
  PROGRESS_STATEMENT_STATUSES,
  STATEMENT_TRANSITION_ERRORS,
  PROGRESS_STATEMENT_TRANSITION_ERRORS,
  getProgressStatementDeletionCheckError,
  PROGRESS_STATEMENT_DELETION_ERROR,
  SUB_PROJECT_BILLING_TYPE,
} from '@graneet/business-logic';
import {
  type Action,
  SimpleViewIcon,
  SimpleDownloadIcon,
  SimpleEmailIcon,
  SimpleEditIcon,
  SimpleDeleteIcon,
  SimpleCloudIcon,
} from '@graneet/lib-ui';
import { t } from 'i18next';

import type { ProgressStatementActionsParams } from './progress-statement.actions.type';

import { getPDFActionProps } from 'features/common/services/common.actions.util';

export function getPreviewPDFAction({
  onViewPdf,
  pdf,
}: Pick<ProgressStatementActionsParams, 'onViewPdf' | 'pdf'>): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('global:pdf.viewPDF'),
    icon: <SimpleViewIcon />,
    ...getPDFActionProps(pdf),
    onClick: onViewPdf,
  };
}

export function getDownloadPDFAction({
  onDownloadPdf,
  pdf,
}: Pick<ProgressStatementActionsParams, 'onDownloadPdf' | 'pdf'>): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('global:pdf.downloadPDF'),
    icon: <SimpleDownloadIcon />,
    ...getPDFActionProps(pdf),
    onClick: onDownloadPdf,
  };
}

export function getDisplayPublicLinkAction({
  onDisplayPublicLink,
  progressStatement,
}: ProgressStatementActionsParams): Action | undefined {
  if (progressStatement.isArchived || progressStatement.isDirect) {
    return undefined;
  }

  return {
    label: t('progressStatement:actions.publicLink'),
    icon: <SimpleCloudIcon />,
    onClick: onDisplayPublicLink,
  };
}

export function getOpenMailingAction({ onOpenMailing, pdf }: ProgressStatementActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('mailing:modal.send'),
    icon: <SimpleEmailIcon />,
    ...getPDFActionProps(pdf),
    onClick: onOpenMailing,
  };
}

export function getEditAction({
  progressStatement,
  getTransitionErrors,
  hasCreatePermission,
  hasUpdatePermission,
  onEdit,
}: ProgressStatementActionsParams): Action | undefined {
  const errors = getTransitionErrors(PROGRESS_STATEMENT_STATUSES.DRAFT, false, undefined);
  const isInDraft = progressStatement.status === PROGRESS_STATEMENT_STATUSES.DRAFT;
  /**
   * create:statements permission is required to edit progress statement (draft only)
   * update-status:statements permission is required to create a new draft version
   */
  const missingPermission = (isInDraft && !hasCreatePermission) || (!isInDraft && !hasUpdatePermission);

  const tooltip = (() => {
    if (missingPermission) {
      return t('global:errors.disabledAction');
    }
    return undefined;
  })();

  if (errors.length !== 0 && tooltip === undefined) {
    // If there are unhandled errors, just don't show the button
    return undefined;
  }

  return {
    icon: <SimpleEditIcon />,
    label: t('progressStatement:actions.edit'),
    tooltip,
    isDisabled: missingPermission,
    onClick: onEdit,
  };
}

export function getDeleteAction({
  progressStatement,
  isLastStatement,
  onDelete,
  hasCreatePermission,
}: ProgressStatementActionsParams): Action | undefined {
  const error = getProgressStatementDeletionCheckError(
    progressStatement,
    SUB_PROJECT_BILLING_TYPE.PROGRESS,
    isLastStatement,
    false,
  );

  const tooltip = (() => {
    if (!hasCreatePermission) {
      return t('global:errors.disabledAction');
    }
    if (error === PROGRESS_STATEMENT_DELETION_ERROR.NOT_LAST) {
      return t('statement:delete.errors.notLastStatement');
    }
    if (error === PROGRESS_STATEMENT_DELETION_ERROR.INVOICE_NUMBER_IS_SET) {
      return t('statement:delete.errors.invoiceNumberSet');
    }
    return undefined;
  })();

  const isDisabled = !hasCreatePermission || error !== null;

  return {
    label: t('global:words.c.delete'),
    icon: <SimpleDeleteIcon />,
    warning: true,
    tooltip,
    isDisabled,
    onClick: onDelete,
  };
}

export function getValidateAction({
  hasUpdatePermission,
  onSetStatusToValidated,
  isValidateButtonLoading,
  getTransitionErrors,
  previousProgressStatementName,
}: ProgressStatementActionsParams): Action {
  const errors = getTransitionErrors(PROGRESS_STATEMENT_STATUSES.VALIDATED, false, undefined);

  const tooltip = (() => {
    if (!hasUpdatePermission) {
      return t('global:errors.disabledAction');
    }
    if (errors.includes(STATEMENT_TRANSITION_ERRORS.INVALID_PREVIOUS_STATUS)) {
      return t('progressStatement:tooltips.incompatibleValidationStatus', {
        progressStatementName: previousProgressStatementName,
      });
    }
    if (errors.includes(PROGRESS_STATEMENT_TRANSITION_ERRORS.UNCOMPLETED_DOWN_PAYMENT)) {
      return t('progressStatement:tooltips.pendingDownPayment');
    }
    if (errors.includes(PROGRESS_STATEMENT_TRANSITION_ERRORS.DIRECT_PAYMENT_INVALID_SUPPLIER_INVOICE)) {
      return t('progressStatement:tooltips.directPaymentInvalidSupplierInvoice');
    }
    return undefined;
  })();

  return {
    label: t('progressStatement:actions.validate'),
    isLoading: isValidateButtonLoading,
    tooltip,
    isDisabled: !hasUpdatePermission || errors.length !== 0,
    onClick: onSetStatusToValidated,
  };
}

export function getProgressStatementAcceptAction({
  pdf,
  hasUpdatePermission,
  onSetStatusToAccepted,
  getTransitionErrors,
}: ProgressStatementActionsParams): Action | undefined {
  const errors = getTransitionErrors(PROGRESS_STATEMENT_STATUSES.ACCEPTED, false, undefined);

  if (errors.length !== 0) {
    return undefined;
  }

  const tooltip = (() => {
    if (!hasUpdatePermission) {
      return t('global:errors.disabledAction');
    }

    // Prevent clicking on accept before the validated PDF is generated
    // which causes a glitch with the popups
    if (pdf?.status === PDF_STATUSES.GENERATING) {
      return t('progressStatement:versions.pdfNotReady');
    }

    return undefined;
  })();

  return {
    label: t('progressStatement:actions.accept'),
    isLoading: pdf?.status === PDF_STATUSES.GENERATING,
    tooltip,
    isDisabled: !hasUpdatePermission,
    onClick: onSetStatusToAccepted,
  };
}

export function getCancelAction(
  {
    hasForceDraftPermission,
    hasUpdatePermission,
    getTransitionErrors,
    onCancelWithCredit,
    onCancelWithoutCredit,
  }: ProgressStatementActionsParams,
  withCredit: boolean,
): Action {
  const errors = getTransitionErrors(PROGRESS_STATEMENT_STATUSES.DRAFT, true, withCredit);
  const missingPermission = !hasUpdatePermission || (!withCredit && !hasForceDraftPermission);

  const tooltip = (() => {
    if (missingPermission) {
      return t('global:errors.disabledAction');
    }
    if (
      errors.includes(STATEMENT_TRANSITION_ERRORS.INVALID_PREVIOUS_STATUS) ||
      errors.includes(PROGRESS_STATEMENT_TRANSITION_ERRORS.DRAFTING_OLD_ACCEPTED)
    ) {
      return t('progressStatement:tooltips.impossibleToDraft');
    }
    if (errors.includes(PROGRESS_STATEMENT_TRANSITION_ERRORS.PROJECT_NON_ONGOING)) {
      return t('progressStatement:tooltips.impossibleToDraftBecauseProjectIsClosed');
    }
    if (errors.includes(PROGRESS_STATEMENT_TRANSITION_ERRORS.HAS_ACCOUNTING_EXPORT)) {
      return withCredit
        ? t('progressStatement:tooltips.impossibleToDraftBecauseAccountingExport')
        : t('progressStatement:tooltips.impossibleToDraftBecauseAccountingExportOrPayment');
    }
    return undefined;
  })();

  return {
    label: withCredit ? t('credit:actions.create') : t('progressStatement:actions.forceDraft'),
    tooltip,
    onClick: withCredit ? onCancelWithCredit : onCancelWithoutCredit,
    isDisabled: errors.length > 0 || missingPermission,
    icon: <SimpleEditIcon />,
  };
}
