import type { IContract } from '../contract/contract.type';
import type { IQuote } from '../quote/quote.type';

export enum CUSTOM_DISCOUNT_TYPES {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export interface ICustomDiscount {
  id: string;

  type: CUSTOM_DISCOUNT_TYPES;

  name: string;

  percentage: number | null;

  amountExVAT: number | null;

  vatRate: number;

  createdAt: Date;

  updatedAt: Date;
}

export interface IQuoteCustomDiscount extends ICustomDiscount {
  quote?: IQuote;
}

export interface IContractCustomDiscount extends ICustomDiscount {
  contract?: IContract;
}
