import type { ChangeEvent, FC } from 'react';
import { useCallback } from 'react';
import { Checkbox, VStack } from '@chakra-ui/react';

import { SellsheetCard } from '../Card';
import { EllipsisText } from '../../../../EllipsisText/EllipsisText';

type Option = {
  label: string;
  name: string;
  defaultChecked: boolean;
};

type OptionsCardProps = {
  options: Option[];
  onChange: (name: string, value: boolean) => void;
  title: string;
  isEditable: boolean;
};

export const OptionsCard: FC<OptionsCardProps> = ({ options, title, isEditable, onChange }) => {
  const handleChange = useCallback(
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      onChange(name, e.target.checked);
    },
    [onChange],
  );

  return (
    <SellsheetCard title={title}>
      <VStack width="100%" spacing={2} alignItems="flex-start">
        {options.map((option) => (
          <Checkbox
            disabled={!isEditable}
            key={option.name}
            defaultChecked={option.defaultChecked}
            onChange={handleChange(option.name)}
            width="100%"
            overflow="auto"
          >
            <EllipsisText fontSize="0.813rem">{option.label}</EllipsisText>
          </Checkbox>
        ))}
      </VStack>
    </SellsheetCard>
  );
};
