import { GRANEET_PDF_COLORS } from '../common/constants/colors.constant';
import {
  PDF_COMPANY_ADDRESS_POSITION,
  PDF_COMPANY_LOT_INFORMATION,
  PDF_DENSITY,
  PDF_FONT,
  PDF_LABELS_POSITION,
  PDF_LOGO_POSITION,
} from '../pdf-settings/pdf-settings.type';

export const PDF_DEFAULT_SETTINGS_FIELDS: {
  LOGO_POSITION: PDF_LOGO_POSITION;
  LOGO_SIZE: number;
  LABELS_SIZE: number;
  LABELS_POSITION: PDF_LABELS_POSITION;
  COMPANY_ADDRESS_POSITION: PDF_COMPANY_ADDRESS_POSITION;
  ACCOUNT_MANAGERS_VISIBILITY: string;
  COVER_PAGE: string;
  DENSITY: PDF_DENSITY;
  HAS_CUMULATIVE_PROGRESS: string;
  FONT: PDF_FONT;
  COLOR: GRANEET_PDF_COLORS;
  LOT_INFORMATION: PDF_COMPANY_LOT_INFORMATION;
  EXTERNAL_PROGRESS_STATEMENT_QR_CODE: string;
} = {
  LOGO_POSITION: PDF_LOGO_POSITION.LEFT,
  LOGO_SIZE: 50,
  LABELS_SIZE: 50,
  LABELS_POSITION: PDF_LABELS_POSITION.NONE,
  COMPANY_ADDRESS_POSITION: PDF_COMPANY_ADDRESS_POSITION.NONE,
  ACCOUNT_MANAGERS_VISIBILITY: 'true',
  COVER_PAGE: 'false',
  DENSITY: PDF_DENSITY.MEDIUM,
  HAS_CUMULATIVE_PROGRESS: 'true',
  FONT: PDF_FONT.OPEN_SANS,
  COLOR: GRANEET_PDF_COLORS.GRAY,
  LOT_INFORMATION: PDF_COMPANY_LOT_INFORMATION.FULL,
  EXTERNAL_PROGRESS_STATEMENT_QR_CODE: 'true',
};
