import { useMemo } from 'react';
import { flatMap, groupBy, sortBy } from 'lodash-es';
import type { IClient, IContact, IGroupedContact, ISupplier } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

export const useGetContactsAvailableForMailing = (
  projectContacts: IGroupedContact[],
  companyContacts: IContact[],
  data: { supplier?: Pick<ISupplier, 'id'>; client?: Pick<IClient, 'id' | 'enterpriseName'> },
) => {
  const { t } = useTranslation(['contacts']);

  return useMemo(() => {
    const mappedContacts = companyContacts.map((c) => {
      /*
       * The results displayed by the autocomplete are not the same whether the user has started typing or not.
       * Until the user starts typing, we narrow down the options according to this flag
       * false = contact is always in the autocomplete dropdown, true = visible only when searching
       */
      const hideWhenNotSearching = (() => {
        // Either the entity is associated to a project, and we only show the rows intersecting with contactsOfProject
        if (projectContacts && projectContacts.length > 0) {
          return !(
            flatMap(projectContacts, 'contacts').some((projectContact) => projectContact.id === c.id) ||
            // If the entity is associated to a supplier AND a project we want to see the suppliers
            // too, but they are not in contactsOfProject.
            data.supplier?.id === c.supplier?.id
          );
        }

        // Or the entity is associated to a client, and we only show the rows associated to the client
        if (data.client) {
          return c.client?.id !== data.client.id;
        }

        // Same for supplier
        if (data.supplier) {
          return c.supplier?.id !== data.supplier.id;
        }

        return false;
      })();

      return {
        id: c.id,
        email: c.email!,
        firstName: c.firstName ?? undefined,
        lastName: c.lastName ?? undefined,
        role: c.role ?? undefined,
        company: c.client?.enterpriseName || (data.supplier && c.supplier?.name) || t('contacts:fields.other'),
        hideWhenNotSearching,
      };
    });
    const contactsByCompany = groupBy(mappedContacts, (c) => c.company);
    const contactsByCompanySortedWithinCompany = Object.entries(contactsByCompany).map(([label, options]) => ({
      label,
      // In each group, options are sorted alphabetically
      options: sortBy(options, ({ lastName }) => lastName?.toLowerCase()),
    }));
    return sortBy(contactsByCompanySortedWithinCompany, [
      // Current company always on top
      ({ label }) => data.client && label !== data.client.enterpriseName,
      // 'Other' always at bottom
      ({ label }) => label === t('contacts:fields.other'),
      // Company names in between are sorted alphabetically
      ({ label }) => label,
    ]);
  }, [companyContacts, data.client, data.supplier, projectContacts, t]);
};
