import { CheckboxField, IconAdvanced, ModalSubtitle, SimpleHelpIcon, SwitchField } from '@graneet/lib-ui';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS } from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useCallback } from 'react';

import type { RoleFormFields } from '../../constants/role.constant';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const RoleEditModalSupplierInvoice = () => {
  const { t } = useTranslation(['user']);
  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);

  const form = useFormContext<RoleFormFields>();
  const { accessSupplierInvoices } = useOnChangeValues(form, ['accessSupplierInvoices']);

  const onChange = useCallback(
    (newValue: boolean) => {
      form.setFormValues({
        createSupplierInvoice: newValue,
        updateSupplierInvoicePayment: newValue,
        paySupplierInvoice: newValue,
      });
    },
    [form],
  );

  const hasUserPermissionsFeatureFlag = useFeatureFlag(FEATURE_FLAGS.USER_PERMISSIONS);
  if (!hasUserPermissionsFeatureFlag) {
    return null;
  }

  const canEditSubRole = !!accessSupplierInvoices;

  return (
    <Stack direction="column" gap={0}>
      <ModalSubtitle mb={0}>
        <SwitchField<RoleFormFields>
          name="accessSupplierInvoices"
          onChange={onChange as any}
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.DISPLAY_SUPPLIER_INVOICES')}</Text>
              <IconAdvanced
                icon={<SimpleHelpIcon boxSize={4} />}
                tooltip={t('user:role.DISPLAY_SUPPLIER_INVOICES.tooltip')}
              />
            </HStack>
          }
          checkedValue
          uncheckedValue={false}
        />
      </ModalSubtitle>

      <Stack direction="column" gap={1} pl={3}>
        <CheckboxField<RoleFormFields>
          name="createSupplierInvoice"
          label={t('user:permissions.CREATE_SUPPLIER_INVOICE')}
          isDisabled={!canEditSubRole}
        />

        <CheckboxField<RoleFormFields>
          name="updateSupplierInvoicePayment"
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.UPDATE_SUPPLIER_INVOICE_PAYMENT')}</Text>
              <IconAdvanced
                icon={<SimpleHelpIcon boxSize={4} />}
                // @[ff: workflow-validation]
                tooltip={
                  hasWorkflowValidationFeatureFlag
                    ? t('user:role.UPDATE_SUPPLIER_INVOICE_PAYMENT.tooltip')
                    : t('user:role.UPDATE_SUPPLIER_INVOICE_PAYMENT_TEMPORARY.tooltip')
                }
              />
            </HStack>
          }
          isDisabled={!canEditSubRole}
        />

        <CheckboxField<RoleFormFields>
          name="paySupplierInvoice"
          label={t('user:permissions.PAY_SUPPLIER_INVOICE')}
          isDisabled={!canEditSubRole}
        />
      </Stack>
    </Stack>
  );
};
