import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import { divideFloating, getAmountPercentage, multiplyFloating, roundFloating } from '../common/math.util';
import type { IProject } from '../project/project.type';
import { isProjectFinished } from '../project/project.util';
import type { ISubProject } from '../sub-project/sub-project.type';
import { SUB_PROJECT_TYPES } from '../sub-project/sub-project.type';
import type { IVatComponentCumulative } from '../vat/vat-component-cumulative.type';
import type { IVatComponentExtended, IVatDiscountedBaseExtended } from '../vat/vat-component-extended.type';
import type { ProgressStatementCumulativeAmounts } from '../progress-statement/progress-statement-compute-data.type';
import type { IProgressStatement } from '../progress-statement/progress-statement.type';

import { HOLDBACK_STATUSES } from './holdback.type';
import type { IHoldback } from './holdback.type';

export const computeHoldbackAmountIncVAT = (
  subProject: Pick<ISubProject, 'amountIncVAT'>,
  holdbackPercentage: number,
): number => getAmountPercentage(subProject.amountIncVAT, holdbackPercentage);

export const getHoldbackStatusFromProject = (project: IProject): HOLDBACK_STATUSES =>
  isProjectFinished(project) ? HOLDBACK_STATUSES.ON_GOING : HOLDBACK_STATUSES.WAITING;

const HOLDBACK_DELAYS: Record<SUB_PROJECT_TYPES, number> = {
  [SUB_PROJECT_TYPES.PUBLIC]: 13,
  [SUB_PROJECT_TYPES.PRIVATE]: 12,
};
export const getHoldbackEndDateFromProject = (project: IProject, subProject: ISubProject): Dayjs | null => {
  if (!isProjectFinished(project)) {
    return null;
  }
  if (!subProject.type) {
    throw new Error('SubProject type is not defined, unhandled workflow.');
  }

  return dayjs(project.endDate).add(HOLDBACK_DELAYS[subProject.type], 'month');
};

export const getHoldbackAmountsWithCumulativeMethod = (
  holdback: IHoldback | undefined | null,
  cumulativeAmount: number,
  nonCumulativeAmount: number,
): {
  cumulativeHoldbackWithPaymentAmount: number;
  nonCumulativeHoldbackWithPaymentAmount: number;
} => {
  if (!holdback || holdback.hasPaymentGuarantee) {
    return {
      cumulativeHoldbackWithPaymentAmount: 0,
      nonCumulativeHoldbackWithPaymentAmount: 0,
    };
  }

  /**
   * Plutôt que de calculer le pourcentage sur le montant non cumulé
   * on fait le calcul comme la différence des montants calculés à partir des montants cumulés courant et précédent.
   * En effet cela permet de s'assurer qu'en faisant la somme des situations par exemple on atteigne bien 100% du montant de la retenue de garantie.
   * À cause des arrondis, la première méthode n'est pas fiable et ne permet pas avec assurance d'avoir ces 100% (en plus ou en moins)
   */
  const { holdbackPercentage } = holdback;
  const previousCumulativeAmount = cumulativeAmount - nonCumulativeAmount;
  const cumulativeHoldbackWithPaymentAmount = getAmountPercentage(cumulativeAmount, holdbackPercentage);
  const previousCumulativeHoldbackWithPaymentAmount = getAmountPercentage(previousCumulativeAmount, holdbackPercentage);

  return {
    cumulativeHoldbackWithPaymentAmount,
    nonCumulativeHoldbackWithPaymentAmount:
      cumulativeHoldbackWithPaymentAmount - previousCumulativeHoldbackWithPaymentAmount,
  };
};

export const getHoldbackName = (projectName: string, subProjectName: string, holdbackLabel: string): string => {
  // Get unique name from project and sub project
  const names = [holdbackLabel, ...new Set([projectName, subProjectName])];

  return names.join(' - ');
};

export const getHoldbackIncPaymentGuaranteeAmountsCumulativeMethod = ({
  holdback,
  cumulativeAmount,
  previousCumulativeHoldbackIncPaymentGuaranteeAmount,
  previousCumulativePaymentGuaranteeAmount,
  totalAmount,
}: {
  holdback: Pick<IHoldback, 'holdbackAmountIncVAT' | 'paymentGuaranteeAmount'> | undefined | null;
  cumulativeAmount: number;
  previousCumulativeHoldbackIncPaymentGuaranteeAmount: number;
  previousCumulativePaymentGuaranteeAmount: number;
  totalAmount: number;
}): {
  cumulativeHoldbackWithPaymentGuaranteeIncVAT: number;
  nonCumulativeHoldbackWithPaymentGuaranteeIncVAT: number;
  nonCumulativePaymentGuaranteeIncVAT: number;
} => {
  if (!holdback) {
    return {
      cumulativeHoldbackWithPaymentGuaranteeIncVAT: 0,
      nonCumulativeHoldbackWithPaymentGuaranteeIncVAT: 0,
      nonCumulativePaymentGuaranteeIncVAT: 0,
    };
  }

  const { holdbackAmountIncVAT, paymentGuaranteeAmount } = holdback;
  // Calculate cumulative amounts for holdback and payment guarantee

  /**
   *          cumulative @ PS
   * ------------------------------------ * holdbackAmountIncVAT
   *        amountIncVAT @ SubProject
   */

  const cumulativeHoldbackWithPaymentAmountIncVAT = multiplyFloating(
    divideFloating(cumulativeAmount, totalAmount),
    holdbackAmountIncVAT,
  );

  /**
   *  MIN(holdbackAmountIncVAT, guaranteeAmountIncVAT)
   */
  const cumulativePaymentGuaranteeAmountIncVAT = Math.min(
    cumulativeHoldbackWithPaymentAmountIncVAT,
    paymentGuaranteeAmount ?? 0,
  );

  const cumulativeHoldbackIncPaymentGuaranteeAmount = roundFloating(
    cumulativeHoldbackWithPaymentAmountIncVAT - cumulativePaymentGuaranteeAmountIncVAT,
  );

  const nonCumulativeHoldbackIncPaymentGuarantee =
    cumulativeHoldbackIncPaymentGuaranteeAmount - previousCumulativeHoldbackIncPaymentGuaranteeAmount;

  const nonCumulativePaymentGuarantee =
    cumulativePaymentGuaranteeAmountIncVAT - previousCumulativePaymentGuaranteeAmount;

  return {
    cumulativeHoldbackWithPaymentGuaranteeIncVAT: cumulativeHoldbackIncPaymentGuaranteeAmount,
    nonCumulativeHoldbackWithPaymentGuaranteeIncVAT: roundFloating(nonCumulativeHoldbackIncPaymentGuarantee),
    nonCumulativePaymentGuaranteeIncVAT: roundFloating(nonCumulativePaymentGuarantee),
  };
};

export const computeHoldbackVatDistributionForProgressStatement = (
  subProject: ISubProject,
  progressStatementCumulativeAmounts: Pick<
    ProgressStatementCumulativeAmounts,
    'cumulativeProgressAmountIncVAT' | 'vatDistribution'
  >,
  holdback: IHoldback | undefined | null,
  previousProgressStatement: IProgressStatement | null | undefined,
): Array<IVatComponentCumulative | IVatComponentExtended | IVatDiscountedBaseExtended> => {
  let vatDistributionWithHoldbackIncPayment: Array<
    IVatComponentCumulative | IVatComponentExtended | IVatDiscountedBaseExtended
  >;

  if (holdback) {
    // Calculate total holdbackIncPayment amount for subproject
    const subprojectHoldbackIncPayment =
      holdback.holdbackAmountIncVAT - Math.min(holdback.holdbackAmountIncVAT, holdback.paymentGuaranteeAmount ?? 0);
    const vatDistributionHoldbackForSubProject: Record<number, number> = {};

    // Calculate VAT amount from holdbackIncPayment amount for each VAT rate
    subProject.contractsVATDistribution?.forEach((vatObject) => {
      vatDistributionHoldbackForSubProject[vatObject.vatRate] = roundFloating(
        multiplyFloating(vatObject.amount, divideFloating(subprojectHoldbackIncPayment, subProject.amountIncVAT)),
      );
    });

    vatDistributionWithHoldbackIncPayment = progressStatementCumulativeAmounts.vatDistribution.map((vatObject) => {
      // Calculate cumulative holdbackIncPayment amount for each VAT rate
      const cumulativeHoldbackWithPaymentAmount = roundFloating(
        multiplyFloating(
          divideFloating(progressStatementCumulativeAmounts.cumulativeProgressAmountIncVAT, subProject.amountIncVAT),
          vatDistributionHoldbackForSubProject[vatObject.vatRate],
        ),
      );

      // Get previous cumulative holdbackIncPayment amount for each VAT rate
      const previousCumulativeHoldbackWithPaymentAmount = previousProgressStatement?.vatDistribution.find(
        (previousVatObject) => previousVatObject.vatRate === vatObject.vatRate,
      )?.cumulativeHoldbackWithPaymentAmount;

      // Calculate holdback amount for each VAT rate
      const holdbackWithPaymentAmount = roundFloating(
        cumulativeHoldbackWithPaymentAmount - (previousCumulativeHoldbackWithPaymentAmount || 0),
      );

      return {
        ...vatObject,
        amountHoldback: holdbackWithPaymentAmount,
        holdbackWithPaymentAmount,
        cumulativeHoldbackWithPaymentAmount,
        amountDownPayment: 0,
        basePriceRevision: 0,
        amountPriceRevision: 0,
      };
    });
  } else {
    vatDistributionWithHoldbackIncPayment = progressStatementCumulativeAmounts.vatDistribution.map((vatObject) => {
      const previousCumulativeHoldbackWithPaymentAmount = previousProgressStatement?.vatDistribution.find(
        (previousVatObject) => previousVatObject.vatRate === vatObject.vatRate,
      )?.cumulativeHoldbackWithPaymentAmount;

      return {
        ...vatObject,
        amountHoldback: 0,
        holdbackWithPaymentAmount: 0,
        cumulativeHoldbackWithPaymentAmount: previousCumulativeHoldbackWithPaymentAmount ?? 0,
        amountDownPayment: 0,
        basePriceRevision: 0,
        amountPriceRevision: 0,
      };
    });
  }
  return vatDistributionWithHoldbackIncPayment;
};
