import type { FC } from 'react';
import { useMemo } from 'react';
import type { RangeModifier } from 'react-day-picker';
import DayPicker from 'react-day-picker';
import type { Modifier } from 'react-day-picker/types/Modifiers';
import type { DayPickerProps } from 'react-day-picker/types/Props';

import 'react-day-picker/lib/style.css';
import './DatePicker.css';
import { useDayPickerLocale } from './day-picker-config';

export interface DateRangePickerProps extends DayPickerProps {
  selectedDays: RangeModifier[];
}

export const DateRangePicker: FC<DateRangePickerProps> = ({ selectedDays, ...otherProps }) => {
  const locale = useDayPickerLocale();

  const [, { from, to }] = selectedDays;
  const modifiers: Partial<Modifier> = useMemo(() => ({ start: from, end: to }), [from, to]);

  return (
    <DayPicker
      className="DateRangePicker"
      numberOfMonths={1}
      months={locale.months}
      weekdaysLong={locale.weekdaysLong}
      weekdaysShort={locale.weekdaysMin}
      firstDayOfWeek={1}
      showOutsideDays
      modifiers={modifiers}
      selectedDays={selectedDays}
      {...otherProps}
    />
  );
};
