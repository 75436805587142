/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  MAXIMUM_FRACTIONAL_PART_LENGTH,
  divideFloating,
  multiplyFloating,
  roundFloating,
  sumObjects,
} from '../common/math.util';
import type { IItem } from '../item/item.type';
import { dispatchDiscountProrata, dispatchDiscountProrataRounded } from '../discount/discount.util';
import type { ICustomDiscount } from '../custom-discount/custom-discount.type';
import { isNumberFinite } from '../common/number.util';
import type { Raw } from '../common/entity.type';
import { isNil } from '../common/object.util';
import type { IProgressStatementLineToSave } from '../progress-statement/progress-statement-line.type';
import type { IProgressStatementDiscountLineToSave } from '../progress-statement/progress-statement-discount-line.type';
import type { IProgressStatementCustomDiscountLineToSave } from '../progress-statement/progress-statement-custom-discount-line.type';

import type { IVatDistribution } from './vat-distribution.type';
import type { IVatCumulativeBases } from './vat-cumulative-base.type';
import type { IVatBases, IVatDiscountedBases } from './vat-bases.type';
import type { IVatBase } from './vat-base.type';
import type { IVatComponent } from './vat-component.type';
import type { IVatComponentCumulative } from './vat-component-cumulative.type';
import type { IVatComponentExtended, IVatDiscountedBaseExtended } from './vat-component-extended.type';

export const DEFAULT_VAT_RATE = 0.2;

export const getAmountIncVAT = (amountExVAT: number, vatRate: number): number =>
  Number.isFinite(amountExVAT) ? roundFloating(multiplyFloating(amountExVAT, 1 + (vatRate || 0))) : 0;

export const getAmountExVAT = (amountIncVAT: number, vatRate: number): number =>
  vatRate !== -1 && Number.isFinite(amountIncVAT) ? roundFloating(divideFloating(amountIncVAT, 1 + (vatRate || 0))) : 0;

/**
 * Calculate the amount excluding VAT from the amount including VAT and the VAT rate
 * if the vatRate is equal to 0, throw an error because it means that the amount is not taxable and could not be calculated from taxes
 */
export const getAmountExVATFromVatValue = (vatRate: number, vatValue: number): number => {
  if (!vatRate) {
    throw new Error('The amount is not taxable and could not be calculated from taxes');
  }
  return roundFloating(divideFloating(vatValue, vatRate));
};

export const getVATObject = (totalExVAT?: number, vatRate?: number, totalIncVAT?: number): IVatBase => {
  if (isNumberFinite(vatRate) && (vatRate < 0 || vatRate > 1)) {
    throw new Error(`Invalid VAT rate: ${vatRate}`);
  }

  if (isNumberFinite(totalExVAT) && isNumberFinite(vatRate)) {
    return {
      vatRate,
      amount: getAmountIncVAT(totalExVAT!, vatRate) - totalExVAT,
      base: totalExVAT,
    };
  }
  if (isNumberFinite(totalIncVAT) && isNumberFinite(vatRate)) {
    return {
      vatRate,
      amount: totalIncVAT - getAmountExVAT(totalIncVAT, vatRate),
      base: getAmountExVAT(totalIncVAT, vatRate),
    };
  }
  if (isNumberFinite(totalExVAT) && isNumberFinite(totalIncVAT)) {
    if (totalExVAT === 0) {
      throw new Error('Cannot calculate VAT rate from 0 totalExVAT');
    }

    // Find the closest VAT rate (with the minimum of decimals) that matches the given totalIncVAT and totalExVAT
    const vatRateNotRounded = divideFloating(totalIncVAT - totalExVAT, totalExVAT);
    let nbDecimals = 0;
    let factor = 1;
    let totalIncVATCalculated;
    let vatRateRounded;

    do {
      vatRateRounded = divideFloating(roundFloating(multiplyFloating(vatRateNotRounded, factor)), factor);
      totalIncVATCalculated = getAmountIncVAT(totalExVAT, vatRateRounded);
      nbDecimals += 1;
      factor *= 10;
    } while (totalIncVATCalculated !== totalIncVAT && nbDecimals <= MAXIMUM_FRACTIONAL_PART_LENGTH);

    const vatRateFinal = totalIncVATCalculated !== totalIncVAT ? vatRateNotRounded : vatRateRounded;

    return {
      vatRate: vatRateFinal,
      amount: totalIncVAT - totalExVAT,
      base: totalExVAT,
    };
  }
  throw new Error(
    'Please provide either amountExVAT and vatRate or amountIncVAT and vatRate or amountExVAT and amountIncVAT',
  );
};

/**
 * Clean VAT components with null bases
 */
export const filterVATBases = (vatDistribution: IVatBases): IVatBases =>
  vatDistribution.filter(({ base }) => base !== 0);

/**
 * Merge array of different vatObject (ex: { vatRate: 0.2, amount: 1000 })
 * It gathers all object with same vatRate into one object
 * ex: INPUT  [{ vatRate: 0.2, amount: 10, base: 50 }, { vatRate: 0.2, amount: 5, base: 25 }, { vatRate: 0.1, amount: 20, base: 200 }]
 * ex: OUTPUT [{ vatRate: 0.2, amount: 15, base: 75 }, { vatRate: 0.1, amount: 20, base: 200 }]
 */
export const mergeVATDistribution = <T extends { vatRate: number }>(vatDistribution: T[]): T[] => {
  const mergedVATDistribution = Object.values(
    vatDistribution.reduce<Record<number, T>>(
      (acc, vatObject) => {
        const { vatRate, ...rest } = vatObject;

        // An entry already exists
        if (acc[vatRate]) {
          const keys = [...Object.keys(rest), ...Object.keys(acc[vatRate])] as (keyof T)[];
          const summableKeys = keys.filter((key, index) => key !== 'vatRate' && keys.indexOf(key) === index);
          return {
            ...acc,
            [vatRate]: summableKeys.reduce((subAcc, key) => {
              const isDefined = Number.isFinite(vatObject[key]) && Number.isFinite(acc[vatRate][key]);
              return {
                ...subAcc,
                [key]: isDefined ? Number(acc[vatRate][key]) + Number(vatObject[key]) : undefined,
              };
            }, acc[vatRate]),
          };
        }

        return {
          ...acc,
          [vatRate]: vatObject,
        };
      },
      {} as Record<number, T>,
    ),
  );

  return mergedVATDistribution;
};

export const getWeightedVAT = (vatDistribution: IVatDistribution): number => {
  const sumAmountVAT = sumObjects(vatDistribution, 'amount');
  const sumAmountExVAT = vatDistribution.reduce(
    (acc, curr) => acc + (curr.vatRate === 0 ? 0 : curr.amount / curr.vatRate),
    0,
  );

  return sumAmountExVAT === 0 ? 0 : sumAmountVAT / sumAmountExVAT;
};

/**
 * Sort vatObject using vatRate
 * The first object is the one with smallest vatRate
 * The last object is the one with largest vatRate
 */
export const sortVATDistribution = <T extends { vatRate: number }[]>(vatObjects: T): T => {
  if (!vatObjects) return [] as unknown as T;
  const clone = [...vatObjects] as T;
  clone.sort((a, b) => a.vatRate - b.vatRate);
  return clone;
};

export const computeAmountOfVATBase = (vatBase: Pick<IVatBase, 'base' | 'vatRate'> | undefined | null): number => {
  if (!vatBase || !isNumberFinite(vatBase.base) || !isNumberFinite(vatBase.vatRate)) return 0;

  return roundFloating(multiplyFloating(vatBase.base, vatBase.vatRate));
};

/**
 * From vatDistribution return the total amount of VAT
 */
export const getTotalVATAmountFromVATDistribution = (vatDistribution: IVatDistribution): number =>
  sumObjects(vatDistribution, 'amount');

/**
 * From VatDistributionCumulative return the total amount of VAT
 */
export const getTotalCumulativeVATAmountFromVATDistributionCumulative = (
  vatDistributionCumulative: Pick<
    IVatComponentCumulative | IVatComponentExtended | IVatDiscountedBaseExtended,
    'cumulativeAmount'
  >[],
): number => sumObjects(vatDistributionCumulative, 'cumulativeAmount');

/**
 * Calcule les taux de TVA après remise commerciale (ancienne méthode)
 */
export const computeVatDistributionDiscountedWithSumsMethod = (
  items: Pick<IItem, 'totalExVAT' | 'vatRate'>[],
  hasReversalOfLiability: boolean,
  discountAmount: number,
  customDiscounts: Raw<ICustomDiscount>[],
): IVatComponent[] => {
  let discountedPrimaryVatDistribution: IVatComponent[] = [];

  const discountedItems = dispatchDiscountProrata(items, discountAmount, 'totalExVAT');
  discountedPrimaryVatDistribution = discountedItems.map(([{ vatRate }, discountedAmount]) => {
    const amount = roundFloating(multiplyFloating(discountedAmount, vatRate));
    return {
      amount, // VAT amount (with discount)
      vatRate,
    };
  });

  const customDiscountedVatDistribution = customDiscounts.map(({ vatRate, amountExVAT }) => ({
    vatRate: isNil(vatRate) ? 0 : vatRate,
    amount: isNil(amountExVAT) || isNil(vatRate) ? 0 : roundFloating(multiplyFloating(amountExVAT, vatRate)),
  }));

  if (hasReversalOfLiability) {
    return mergeVATDistribution(customDiscountedVatDistribution);
  }
  return mergeVATDistribution([...discountedPrimaryVatDistribution, ...customDiscountedVatDistribution]);
};

/**
 * Calcule les taux de TVA après remise commerciale
 */
export const computeVatDistributionDiscountedWithBasesMethod = (
  vatDistribution: IVatBase[],
  hasReversalOfLiability: boolean,
  discountAmount: number,
  customDiscounts: Raw<ICustomDiscount>[],
): IVatBases => {
  const discounted = dispatchDiscountProrata(vatDistribution, discountAmount, 'base');
  const vatPrimaryDiscountedBases = discounted.map(([{ base, vatRate }, discountedBase]) => {
    const amount = roundFloating(multiplyFloating(discountedBase, vatRate));
    return {
      amount, // VAT amount (with discount)
      base, // Base of VAT (without discount)
      vatRate,
    };
  });

  const vatCustomDiscountedBases = customDiscounts.map(({ vatRate, amountExVAT }) => ({
    base: 0,
    vatRate: isNil(vatRate) ? 0 : vatRate,
    amount: computeAmountOfVATBase({ vatRate, base: amountExVAT || 0 }),
  }));

  if (hasReversalOfLiability) {
    return mergeVATDistribution(vatCustomDiscountedBases);
  }
  return mergeVATDistribution([...vatPrimaryDiscountedBases, ...vatCustomDiscountedBases]);
};

/**
 * Calcule les taux de TVA après remise commerciale et les ± values (avec bases remisées arrondies)
 */
export const computeVatDistributionDiscountedWithBasesRoundedMethod = (
  vatDistribution: IVatBase[],
  hasReversalOfLiability: boolean,
  discountAmount: number,
  customDiscounts: Raw<ICustomDiscount>[],
): IVatDiscountedBases => {
  const discounted = dispatchDiscountProrataRounded(vatDistribution, discountAmount, 'base');
  const vatPrimaryDiscountedBases = discounted.map(([{ base, vatRate }, discountedBase]) => {
    const amount = roundFloating(multiplyFloating(discountedBase, vatRate));
    return {
      amount, // VAT amount (with discount)
      base, // Base of VAT (without discount)
      vatRate,
      discountedBase,
    };
  });

  const vatCustomDiscountedBases = customDiscounts.map(({ vatRate, amountExVAT }) => ({
    base: 0,
    discountedBase: isNil(amountExVAT) ? 0 : amountExVAT,
    vatRate: isNil(vatRate) ? 0 : vatRate,
    amount: computeAmountOfVATBase({ vatRate, base: amountExVAT || 0 }),
  }));

  if (hasReversalOfLiability) {
    return mergeVATDistribution(vatCustomDiscountedBases);
  }
  return mergeVATDistribution([...vatPrimaryDiscountedBases, ...vatCustomDiscountedBases]);
};

/**
 * Calcule les taux de TVA cumulés après remise commerciale (ancienne méthode)
 */
export const computeVatDistributionCumulativeDiscountedWithSumsMethod = (
  lines: IProgressStatementLineToSave[],
  discountLine: IProgressStatementDiscountLineToSave | undefined,
  customDiscountLines: IProgressStatementCustomDiscountLineToSave[],
  hasReversalOfLiability: boolean,
): Omit<
  IVatComponentCumulative,
  | 'amountDownPayment'
  | 'amountHoldback'
  | 'basePriceRevision'
  | 'amountPriceRevision'
  | 'holdbackWithPaymentAmount'
  | 'cumulativeHoldbackWithPaymentAmount'
>[] => {
  // 1. Store both current and previous cumulated discount amount
  const discountAmount = discountLine ? discountLine.cumulativeAmountExVAT : 0;
  const previousDiscountAmount = discountLine
    ? discountLine.cumulativeAmountExVAT - discountLine.nonCumulativeAmountExVAT
    : 0;

  // 2. Store previous cumulative amount
  const customDiscountlinesWithPreviousCumulative = customDiscountLines.map(
    ({ cumulativeAmountExVAT, nonCumulativeAmountExVAT, contractCustomDiscount: customDiscount }) => ({
      vatRate: customDiscount?.vatRate,
      cumulativeAmountExVAT,
      previousCumulativeAmountExVAT: cumulativeAmountExVAT - nonCumulativeAmountExVAT,
    }),
  );

  // 3. Store previous cumulative amount
  const linesWithPreviousCumulative = lines.map(
    ({ cumulativeAmountExVAT, nonCumulativeAmountExVAT, item: { vatRate } }) => ({
      vatRate,
      cumulativeAmountExVAT,
      previousCumulativeAmountExVAT: cumulativeAmountExVAT - nonCumulativeAmountExVAT,
    }),
  );

  // 4. Calculate discounted cumulative amount
  const discountedCumulativeLines = dispatchDiscountProrata(
    linesWithPreviousCumulative,
    discountAmount,
    'cumulativeAmountExVAT',
  ).map(([line, discountedCumulative]) => ({ ...line, discountedCumulative }));

  // 5. Calculate previous cumulative amount
  const discountedPreviousCumulativeLines = dispatchDiscountProrata(
    discountedCumulativeLines,
    previousDiscountAmount,
    'previousCumulativeAmountExVAT',
  ).map(([line, discountedPreviousCumulative]) => ({ ...line, discountedPreviousCumulative }));

  // 6. Calculate VAT distribution with discounted cumulative amount
  const discountedPrimaryVatDistribution = discountedPreviousCumulativeLines.map(
    ({ vatRate, discountedCumulative, discountedPreviousCumulative }) => {
      const cumulativeAmount = roundFloating(multiplyFloating(discountedCumulative, vatRate));
      const previousCumulativeAmount = roundFloating(multiplyFloating(discountedPreviousCumulative, vatRate));
      return {
        amount: cumulativeAmount - previousCumulativeAmount,
        cumulativeAmount,
        vatRate,
      };
    },
  );

  // 7. Calculate VAT distribution of custom discounts
  const customDiscountedVatDistribution = customDiscountlinesWithPreviousCumulative.map(
    ({ cumulativeAmountExVAT, previousCumulativeAmountExVAT, vatRate }) => {
      const vatRateValue = isNil(vatRate) ? 0 : vatRate;
      const cumulativeAmount = roundFloating(multiplyFloating(cumulativeAmountExVAT, vatRateValue));
      const previousCumulativeAmount = roundFloating(multiplyFloating(previousCumulativeAmountExVAT, vatRateValue));
      return {
        vatRate: vatRateValue,
        amount: cumulativeAmount - previousCumulativeAmount,
        cumulativeAmount,
      };
    },
  );

  if (hasReversalOfLiability) {
    return mergeVATDistribution(customDiscountedVatDistribution);
  }
  return mergeVATDistribution([...discountedPrimaryVatDistribution, ...customDiscountedVatDistribution]);
};

/**
 * Calcule la distribution de TVA cumulée remisée avec les bases remisées non arrondies
 */
export const computeVatDistributionCumulativeDiscountedWithBasesMethod = (
  vatCumulativeBases: IVatCumulativeBases,
  discountLine: IProgressStatementDiscountLineToSave | undefined,
  customDiscountLines: IProgressStatementCustomDiscountLineToSave[],
): {
  vatPrimaryDiscountedBases: Array<{
    vatRate: number;
    base: number;
    cumulativeBase: number;
    discountedCumulativeBase: number;
    previousDiscountedCumulativeBase: number;
  }>;
  vatCustomDiscountedBases: Omit<
    IVatComponentExtended,
    | 'amountDownPayment'
    | 'amountHoldback'
    | 'basePriceRevision'
    | 'amountPriceRevision'
    | 'holdbackWithPaymentAmount'
    | 'cumulativeHoldbackWithPaymentAmount'
  >[];
} => {
  // 1. Store both current and previous cumulated discount amount
  const discountAmount = discountLine ? discountLine.cumulativeAmountExVAT : 0;
  const previousDiscountAmount = discountLine
    ? discountLine.cumulativeAmountExVAT - discountLine.nonCumulativeAmountExVAT
    : 0;

  // 2. Store previous cumulative amount
  const customDiscountlinesWithPreviousCumulative = customDiscountLines.map(
    ({ cumulativeAmountExVAT, nonCumulativeAmountExVAT, contractCustomDiscount: customDiscount }) => ({
      vatRate: customDiscount?.vatRate,
      cumulativeAmountExVAT,
      previousCumulativeAmountExVAT: cumulativeAmountExVAT - nonCumulativeAmountExVAT,
    }),
  );

  // Return object with discounted cumulative and non-cumulative bases
  // 3. Add previous cumulative base for each vat object
  const cumulativeBasesWithPrevious = vatCumulativeBases.map(({ base, cumulativeBase, vatRate }) => ({
    base,
    cumulativeBase,
    previousCumulativeBase: cumulativeBase - base,
    vatRate,
  }));

  // 4. Calculate discounted cumulative bases
  const withDiscountedCumulativeBases = dispatchDiscountProrata(
    cumulativeBasesWithPrevious,
    discountAmount,
    'cumulativeBase',
  ).map(([item, discountedCumulativeBase]) => ({ ...item, discountedCumulativeBase }));

  // 5. Calculate discounted previous cumulative bases
  const vatPrimaryDiscountedBases = dispatchDiscountProrata(
    withDiscountedCumulativeBases,
    previousDiscountAmount,
    'previousCumulativeBase',
  ).map(([item, previousDiscountedCumulativeBase]) => ({ ...item, previousDiscountedCumulativeBase }));

  // 6. Calculate VAT distribution of custom discounts
  const vatCustomDiscountedBases = customDiscountlinesWithPreviousCumulative.map(
    ({ cumulativeAmountExVAT, previousCumulativeAmountExVAT, vatRate }) => {
      const vatRateValue = isNil(vatRate) ? 0 : vatRate;
      const cumulativeAmount = computeAmountOfVATBase({ base: cumulativeAmountExVAT, vatRate: vatRateValue });
      const previousCumulativeAmount = computeAmountOfVATBase({
        base: previousCumulativeAmountExVAT,
        vatRate: vatRateValue,
      });
      return {
        base: 0,
        cumulativeBase: 0,
        vatRate: vatRateValue,
        amount: cumulativeAmount - previousCumulativeAmount,
        cumulativeAmount,
      };
    },
  );

  return {
    vatPrimaryDiscountedBases,
    vatCustomDiscountedBases,
  };
};

/**
 * Calcule la distribution de TVA cumulée remisée avec les bases remisées arrondies
 */
export const computeVatDistributionCumulativeDiscountedWithBasesRoundedMethod = (
  vatCumulativeBases: IVatCumulativeBases,
  discountLine: IProgressStatementDiscountLineToSave | undefined,
  customDiscountLines: IProgressStatementCustomDiscountLineToSave[],
): {
  vatPrimaryDiscountedBases: Array<{
    vatRate: number;
    base: number;
    cumulativeBase: number;
    discountedCumulativeBase: number;
    previousDiscountedCumulativeBase: number;
  }>;
  vatCustomDiscountedBases: Omit<
    IVatDiscountedBaseExtended,
    | 'amountDownPayment'
    | 'amountHoldback'
    | 'basePriceRevision'
    | 'amountPriceRevision'
    | 'holdbackWithPaymentAmount'
    | 'cumulativeHoldbackWithPaymentAmount'
  >[];
} => {
  // 1. Store both current and previous cumulated discount amount
  const discountAmount = discountLine ? discountLine.cumulativeAmountExVAT : 0;
  const previousDiscountAmount = discountLine
    ? discountLine.cumulativeAmountExVAT - discountLine.nonCumulativeAmountExVAT
    : 0;

  // 2. Store previous cumulative amount
  const customDiscountlinesWithPreviousCumulative = customDiscountLines.map(
    ({ cumulativeAmountExVAT, nonCumulativeAmountExVAT, contractCustomDiscount: customDiscount }) => ({
      vatRate: customDiscount?.vatRate,
      cumulativeAmountExVAT,
      previousCumulativeAmountExVAT: cumulativeAmountExVAT - nonCumulativeAmountExVAT,
    }),
  );

  // Return object with discounted cumulative and non-cumulative bases
  // 3. Add previous cumulative base for each vat object
  const cumulativeBasesWithPrevious = vatCumulativeBases.map(({ base, cumulativeBase, vatRate }) => ({
    base,
    cumulativeBase,
    previousCumulativeBase: cumulativeBase - base,
    vatRate,
  }));

  // 4. Calculate discounted cumulative bases
  const withDiscountedCumulativeBases = dispatchDiscountProrataRounded(
    cumulativeBasesWithPrevious,
    discountAmount,
    'cumulativeBase',
  ).map(([item, discountedCumulativeBase]) => ({ ...item, discountedCumulativeBase }));

  // 5. Calculate discounted previous cumulative bases
  const vatPrimaryDiscountedBases = dispatchDiscountProrataRounded(
    withDiscountedCumulativeBases,
    previousDiscountAmount,
    'previousCumulativeBase',
  ).map(([item, previousDiscountedCumulativeBase]) => ({ ...item, previousDiscountedCumulativeBase }));

  // 6. Calculate VAT distribution of custom discounts
  const vatCustomDiscountedBases = customDiscountlinesWithPreviousCumulative.map(
    ({ cumulativeAmountExVAT, previousCumulativeAmountExVAT, vatRate }) => {
      const vatRateValue = isNil(vatRate) ? 0 : vatRate;
      const cumulativeAmount = computeAmountOfVATBase({ base: cumulativeAmountExVAT, vatRate: vatRateValue });
      const previousCumulativeAmount = computeAmountOfVATBase({
        base: previousCumulativeAmountExVAT,
        vatRate: vatRateValue,
      });
      return {
        base: 0,
        cumulativeBase: 0,
        discountedCumulativeBase: cumulativeAmountExVAT,
        discountedBase: cumulativeAmountExVAT - previousCumulativeAmountExVAT,
        vatRate: vatRateValue,
        amount: cumulativeAmount - previousCumulativeAmount,
        cumulativeAmount,
      };
    },
  );

  return {
    vatPrimaryDiscountedBases,
    vatCustomDiscountedBases,
  };
};

export const calculateVATDistributionRatio = (
  vatDistribution: IVatDiscountedBases,
  ratio: number,
): IVatDiscountedBases =>
  vatDistribution.map(({ vatRate, discountedBase, base }) => {
    // We calculate rounded discounted base and amount for each vat rate
    const subDiscountedBase = roundFloating(multiplyFloating(discountedBase, ratio));
    const subAmount = roundFloating(multiplyFloating(subDiscountedBase, vatRate));
    // There is no "base" strictly speaking because there is no down payment amount "without" discount
    // However, it is useful for the customer success team for accounting exports
    const subBase = roundFloating(multiplyFloating(base, ratio));

    return {
      vatRate,
      base: subBase,
      discountedBase: subDiscountedBase,
      amount: subAmount,
    };
  });
