import { useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';
import type { FC } from 'react';

interface ExportModalNoPermissionStepProps {
  onClose(): void;
}

export const ExportModalNoPermissionStep: FC<ExportModalNoPermissionStepProps> = ({ onClose }) => {
  const { t } = useTranslation(['global', 'accounting']);

  return (
    <>
      {t('accounting:exportModal.descriptionNoPermission')}
      <Modal.PrimaryButton onClick={onClose} variant="outline">
        {t('global:words.c.close')}
      </Modal.PrimaryButton>
    </>
  );
};
