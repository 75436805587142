import type {
  IDashboardTimelineDTO,
  IDashboardTimelineQuery,
  IDashboardResponseDTO,
  IProject,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useSuspenseQuery } from '@tanstack/react-query';

import { apiNew } from 'features/api/services/apiNew.service';

const DASHBOARD_PATH = '/dashboard';

export const dashboard = createQueryKeys('dashboard', {
  stats: (options: { isEnabled?: boolean }) => ({
    queryKey: [DASHBOARD_PATH, 'stats', options],
    queryFn: (): Promise<IDashboardResponseDTO> => {
      if (!options.isEnabled) {
        return Promise.resolve({
          amountBillsLateIncVAT: 0,
          amountBillsToPayInLessThanThirtyDaysIncVAT: 0,
          amountBillsToPayInMoreThanThirtyDaysIncVAT: 0,
          amountExVATAggregatedLastYear: [],
          amountSupplierInvoicesLateIncVAT: 0,
          amountSupplierInvoicesNotPaidIncVAT: 0,
          amountSupplierInvoicesToPayInLessThanThirtyDaysIncVAT: 0,
          amountSupplierInvoicesToPayInMoreThanThirtyDaysIncVAT: 0,
          amountSupplierInvoicesWithoutPaymentDateIncVAT: 0,
          amountToBeBilledExVAT: 0,
          countQuotesAcceptedLastMonth: 0,
          countQuotesCreatedLastMonth: 0,
          invoicedAmountExVAT: 0,
          onGoingProjectCount: 0,
          progressStatementAndInvoicesLastMonthCount: 0,
          remainingToBePaidIncVAT: 0,
        });
      }

      return apiNew.get<never, IDashboardResponseDTO>(`${DASHBOARD_PATH}/stats`);
    },
  }),

  timelineStats: (options: { projectId?: IProject['id'] }) => ({
    queryKey: [DASHBOARD_PATH, 'stats', 'timeline', options],
    queryFn: () =>
      apiNew.get<IDashboardTimelineQuery, IDashboardTimelineDTO>(`${DASHBOARD_PATH}/stats/timeline`, {
        projectId: options.projectId,
      }),
  }),
});

export function useDashboardStats(options: { isEnabled?: boolean }) {
  return useSuspenseQuery(dashboard.stats(options));
}

export function useDashboardTimelineStats(options: { projectId?: IProject['id'] }) {
  return useSuspenseQuery(dashboard.timelineStats(options));
}
