import { Stack } from '@chakra-ui/react';
import { Container } from '@graneet/lib-ui';
import type { FC } from 'react';

import { MealExpenseCard } from 'features/time-tracking/components/MealExpenseCard';
import { OvertimeTypeCard } from 'features/overtime/components/OvertimeTypeCard';
import { TimeDefaultsCard } from 'features/time-tracking/components/TimeDefaultsCard';

export const PayVariablesTab: FC = () => (
  <Container size="sm">
    <Stack spacing={6} maxW="40rem">
      <OvertimeTypeCard />
      <MealExpenseCard />
      <TimeDefaultsCard />
    </Stack>
  </Container>
);
