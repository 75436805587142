import type { HISTORY_ENTITY_TYPE, IHistoryDTO } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useSuspenseQuery } from '@tanstack/react-query';

import { apiNew } from 'features/api/services/apiNew.service';

const HISTORY_PATH = '/history';

const historyFactoryKey = createQueryKeys('history', {
  getByEntity: (params: { entity: HISTORY_ENTITY_TYPE; id: string }) => ({
    queryKey: [HISTORY_PATH, params.entity, params.id],
    queryFn: () => apiNew.get<string, IHistoryDTO>(`${HISTORY_PATH}/${params.entity}/${params.id}`),
  }),
  getByFamilyId: (id: string) => ({
    queryKey: [HISTORY_PATH, id],
    queryFn: () => apiNew.get<string, IHistoryDTO>(`${HISTORY_PATH}/familyId/${id}`),
  }),
});

export function useHistory(entity: HISTORY_ENTITY_TYPE, id: string) {
  return useSuspenseQuery(historyFactoryKey.getByEntity({ entity, id }));
}

export function useHistoryById(id: string) {
  return useSuspenseQuery(historyFactoryKey.getByFamilyId(id));
}
