import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CellClassParams, ColDef } from '@ag-grid-community/core';
import { useMemo } from 'react';

import { QuoteRowIndexCellRenderer } from '../../components/QuoteRowIndexCellRenderer';

export const useAutoGroupColumnDef = () =>
  useMemo(
    (): ColDef<QuoteNodeObject> => ({
      rowDrag: true,
      headerName: '',
      headerCheckboxSelection: false,
      editable: false,
      sortable: false,
      resizable: false,
      width: 40,
      checkboxSelection: false,
      cellClass: (params: CellClassParams) => {
        if (params.node.footer) return 'hidden';
        return 'no-focus dragger';
      },
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: QuoteRowIndexCellRenderer,
      cellRendererParams: {
        suppressDoubleClickExpand: true,
        suppressCount: true,
      },
    }),
    [],
  );
