import { useCallback } from 'react';

import { QuoteAGGridType } from 'features/quotation/ag-grid-quote/constants/agGridConstants';
import { useStore } from 'store/store';
import { useColumnSettings } from 'features/quotation/ag-grid-quote/hooks/column-def/useColumnSettings';

export function useQuoteAddImage() {
  const quoteImageUploadRefs = useStore((state) => state.quoteImageUploadRefs);
  const { isColumnEnabled: isRootColumnEnabled, toggleColumn: toggleRootColumn } = useColumnSettings(
    QuoteAGGridType.ROOT,
  );
  const { isColumnEnabled: isHiddenCostColumnEnabled, toggleColumn: toggleHiddenCostColumn } = useColumnSettings(
    QuoteAGGridType.HIDDEN_COST,
  );

  return useCallback(
    (id?: string) => {
      const isEnabledRoot = isRootColumnEnabled('image');
      const isEnabledHiddenCost = isHiddenCostColumnEnabled('image');

      const isTotalEnabled = isEnabledRoot && isEnabledHiddenCost;

      if (!isEnabledRoot) {
        toggleRootColumn('image', true);
      }
      if (!isEnabledHiddenCost) {
        toggleHiddenCostColumn('image', true);
      }

      if (!isTotalEnabled) {
        // Delay execution to make sure column is displayed
        setTimeout(() => {
          if (id && quoteImageUploadRefs.has(id)) {
            quoteImageUploadRefs.get(id)?.current?.addImage();
          }
        }, 500);
      } else if (id && quoteImageUploadRefs.has(id)) {
        quoteImageUploadRefs.get(id)?.current?.addImage();
      }
    },
    [isRootColumnEnabled, isHiddenCostColumnEnabled, toggleRootColumn, toggleHiddenCostColumn, quoteImageUploadRefs],
  );
}
