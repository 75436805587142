import type { FC } from 'react';
import { useCallback } from 'react';
import {
  BadgeJob,
  CheckboxField,
  DeepTable,
  EMPTY_SIGN,
  formatNumber,
  formatNumberToVatRate,
  formatPercentage,
  PriceAdvanced,
  RichText,
  roundFloatingTo,
} from '@graneet/lib-ui';
import { isNumberFinite } from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import type { QuoteExportForm } from '../../forms/quote-export-edition.wizard';
import { generateIsJobSelectedExportFieldName } from '../../forms/quote-export-edition.wizard';
import { EXPORT_COLUMNS } from '../../constants/quote-export.constant';
import { useQuoteExportJob, useQuoteExportTreeContext } from '../../hooks/useQuoteExportTree';
import { QuoteExportNoteRow } from '../QuoteExportNoteRow';

import { QuoteExportImagesRow } from './QuoteExportImagesRow';
import { QuoteExportJobComponentRow } from './QuoteExportJobComponentRow';

import { useStore } from 'store/store';

interface QuoteExportDeepTableJobLineProps {
  id: number;

  depth: number;
}

export const QuoteExportDeepTableJobLine: FC<QuoteExportDeepTableJobLineProps> = ({ id, depth }) => {
  const { t } = useTranslation(['quote']);
  const { leaf: job } = useQuoteExportJob(id);
  const { updateLeafData } = useQuoteExportTreeContext();

  const autoNumberingIsActivated = useStore((state) => state.autoNumberingIsActivated);
  const autoNumber = useStore((state) => state.autoNumberingTable[`job-${id}`]);

  const form = useFormContext<QuoteExportForm>();
  const { exportItems: columns, hasReversalOfLiability } = useOnChangeValues(form, [
    'exportItems',
    'hasReversalOfLiability',
  ]);

  const onCheckBoxChange = useCallback(() => {
    updateLeafData(id, { isSelected: !job.isSelected });
  }, [id, job.isSelected, updateLeafData]);

  if (job.isHiddenCost) {
    return null;
  }

  if (!columns) {
    return null;
  }

  return (
    <Box ml={depth === 1 ? 0 : 2}>
      <DeepTable.Row
        offset={(depth - 1) * 0.5}
        backgroundColor="white"
        color="gray.600"
        leftContent={
          <CheckboxField<QuoteExportForm>
            onChange={onCheckBoxChange}
            name={generateIsJobSelectedExportFieldName(id)}
            mr={2}
            marginTop={job.isOptional ? 3 : 0}
          />
        }
        position="relative"
      >
        {job.isOptional ? (
          <BadgeJob backgroundColor="purple.100" textColor="purple.700" label={t('quote:option.title')} />
        ) : null}
        {!!columns[EXPORT_COLUMNS.REF_CODE] && (
          <DeepTable.Cell variant="ellipsis">
            {autoNumberingIsActivated ? autoNumber?.substring(2) : job.code}
          </DeepTable.Cell>
        )}

        <DeepTable.Cell>
          <RichText value={job.description} />
        </DeepTable.Cell>

        {!!columns[EXPORT_COLUMNS.UNIT] && <DeepTable.Cell right>{job.unit}</DeepTable.Cell>}

        {!!columns[EXPORT_COLUMNS.QUANTITY] && (
          <DeepTable.Cell right>{isNumberFinite(job.quantity) ? formatNumber(job.quantity) : null}</DeepTable.Cell>
        )}

        {!!columns[EXPORT_COLUMNS.UNIT_DISBURSEMENT_EX_VAT] && (
          <DeepTable.Cell right>
            <PriceAdvanced amount={job.unitDisbursementExVAT} />
          </DeepTable.Cell>
        )}

        {!!columns[EXPORT_COLUMNS.DISBURSEMENT_EX_VAT] && (
          <DeepTable.Cell right>
            <PriceAdvanced amount={job.disbursementExVAT} />
          </DeepTable.Cell>
        )}

        {!!columns[EXPORT_COLUMNS.JOB_MARGIN] && (
          <DeepTable.Cell right>{formatNumber(job.margin?.totalMargin)}</DeepTable.Cell>
        )}

        {!!columns[EXPORT_COLUMNS.UNIT_PRICE_EX_VAT] && (
          <DeepTable.Cell right>
            <PriceAdvanced amount={job.unitPriceExVAT} />
          </DeepTable.Cell>
        )}

        {!!columns[EXPORT_COLUMNS.JOB_VAT] && (
          <DeepTable.Cell right>
            {hasReversalOfLiability ? EMPTY_SIGN : formatPercentage(formatNumberToVatRate(job.vatRate))}
          </DeepTable.Cell>
        )}

        {!!columns[EXPORT_COLUMNS.WORK_FORCE] && (
          <DeepTable.Cell right>
            {isNumberFinite(job.workForceQuantity)
              ? `${formatNumber(roundFloatingTo(job.workForceQuantity, 2))} h`
              : '-'}
          </DeepTable.Cell>
        )}

        {!!columns[EXPORT_COLUMNS.AMOUNT_EX_VAT] && (
          <DeepTable.Cell right>
            <PriceAdvanced amount={job.amountExVAT} />
          </DeepTable.Cell>
        )}
      </DeepTable.Row>

      {(job.quoteJobFile || []).length > 0 && columns[EXPORT_COLUMNS.IMAGES] && (
        <QuoteExportImagesRow
          quoteJobFiles={job.quoteJobFile || []}
          depth={depth}
          parentId={id}
          isOptional={job.isOptional}
        />
      )}

      {columns[EXPORT_COLUMNS.COMPONENTS] &&
        job.components.map((component) => (
          <QuoteExportJobComponentRow
            key={component.id}
            depth={depth}
            parentId={id}
            component={component}
            isOptional={job.isOptional}
          />
        ))}

      {!!job.note && <QuoteExportNoteRow depth={depth} parentId={id} parentType="job" isOptional={job.isOptional} />}
    </Box>
  );
};
