import type { IFinalStatementCreationDTO } from '@graneet/business-logic';

import type { FinalStatementPriceRevisionForm } from '../forms/final-statement-edition.wizard';

export const formatPriceRevisionDTO = (
  values: FinalStatementPriceRevisionForm,
  mapAmountToNumber: (value: string | number) => number,
): IFinalStatementCreationDTO['finalPriceRevisions'] =>
  Object.entries(values || {})
    .filter(([id]) => id.indexOf('priceRevision_') === 0)
    .map(([id, value]) => ({
      amount: mapAmountToNumber(value || 0),
      progressStatementId: Number(id.replace('priceRevision_', '')),
    }));
