import { useCallback } from 'react';
import type { QuoteNodeObject } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';
import { reverse } from 'lodash-es';

import { useQuoteSetToStore } from '../useQuoteSetToStore';
import { useQuotationProxyApis } from '../useQuoteProxyApis';
import { quoteClientRequestsSetToStore, StatusEnum } from '../../store/quoteClientRequestsSetToStore';
import { useQuoteError } from '../useQuoteError';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useQuoteBulkDelete() {
  const { quote } = useQuote();
  const { quoteNodeBulkActionsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteError = useQuoteError();

  return useCallback(
    (rows: QuoteNodeObject[]): boolean => {
      try {
        reverse(rows).forEach((row) => {
          if (row.content.type === 'QuoteBasicItem') {
            const quoteBasicItem = quote.getTree().getQuoteBasicItemOrThrow(row.id);
            quoteBasicItem.deleteQuoteItem();
          } else if (row.content.type === 'QuoteLot') {
            const quoteLot = quote.getTree().getQuoteLotOrThrow(row.id);
            quoteLot.deleteQuoteLot();
          } else if (row.content.type === 'QuoteOptionalItem') {
            const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(row.id);
            quoteOptionalItem.deleteQuoteItem();
          } else if (row.content.type === 'QuoteHiddenCostItem') {
            const quoteHiddenCostItem = quote.getTree().getQuoteHiddenCostOrThrow(row.id);
            quoteHiddenCostItem.deleteQuoteItem();
          } else if (row.content.type === 'QuoteComponent') {
            const quoteComponent = quote.getTree().getQuoteComponentOrThrow(row.id);
            quoteComponent.deleteQuoteComponent();
          } else if (row.content.type === 'QuoteSubItem') {
            const quoteSubItem = quote.getTree().getQuoteSubItemOrThrow(row.id);
            quoteSubItem.deleteQuoteItem();
          }
        });
        quoteSetToStore(quote);
        if (rows.length > 0) {
          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteNodeBulkActionsProxyApi
            .handleBulkDeleteQuoteNode({
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              nodesId: rows.map((row) => row.id),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));
        }
        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteError, quoteNodeBulkActionsProxyApi, quoteSetToStore],
  );
}
