import { Text } from '@chakra-ui/react';

export const getChecklistIcon = (valid: boolean) =>
  valid ? (
    <Text color="green.500">
      <i className="ri-checkbox-circle-line" />
    </Text>
  ) : (
    <Text color="red.500">
      <i className="ri-alert-line" />
    </Text>
  );
