import type { BoxProps } from '@chakra-ui/react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';

import { useChakraColors } from '../../hooks';

import { DocumentStatusCardIcon } from './components/DocumentStatusCardIcon';
import type { DocumentStatusCardIconValue } from './DocumentStatusCard.util';
import { mapIconToColor } from './DocumentStatusCard.util';
import { DocumentStatusCardProgress } from './components/DocumentStatusCardProgress';

export interface DocumentStatusCardProps extends BoxProps {
  icon: DocumentStatusCardIconValue;
  footer?: string | ReactNode;
  bottomContent?: ReactNode;
  badgeComponent?: ReactNode;
  title?: string;
  subtitle?: ReactNode;
  isMinimalist?: boolean;
}

const DocumentStatusCardComponent: FC<DocumentStatusCardProps> = ({
  icon,
  title,
  subtitle,
  badgeComponent,
  footer,
  bottomContent,
  isMinimalist = false,
  ...rest
}) => {
  const chakraColor = useMemo(() => mapIconToColor(icon), [icon]);

  const color = useChakraColors({
    background: `${chakraColor}.50`,
    border: `${chakraColor}.200`,
    text: `${chakraColor}.800`,
  });

  return (
    <Box
      border={`1px solid ${color.border}`}
      borderRadius={5}
      padding=".8rem"
      background={`linear-gradient(94deg, ${color.background} 0%, white 100%)`}
      minHeight={isMinimalist ? undefined : 130}
      display="flex"
      flexDirection="row"
      alignItems="center"
      position="relative"
      {...rest}
    >
      {badgeComponent && (
        <Box
          position="absolute"
          right=".8rem"
          top={isMinimalist ? '50%' : '.8rem'}
          transform={isMinimalist ? 'translateY(-50%)' : undefined}
        >
          {badgeComponent}
        </Box>
      )}

      <HStack height="100%" w="100%">
        <DocumentStatusCardIcon size={isMinimalist ? 35 : 75} icon={icon} />

        <VStack alignItems="flex-start" justifyContent="flex-start" gap={0} w="100%">
          {title && (
            <Text fontWeight={500} fontSize=".9em" color="black">
              {title}
            </Text>
          )}
          {subtitle && (
            <Box fontSize=".9em" color={color.text}>
              {subtitle}
            </Box>
          )}
          {footer && (
            <Box mt={2} w="100%">
              {typeof footer === 'string' ? (
                <Text fontSize=".8em" color="black" lineHeight={1.3}>
                  {footer}
                </Text>
              ) : (
                footer
              )}
            </Box>
          )}

          {bottomContent && (
            <Box mt={2} w="100%">
              {bottomContent}
            </Box>
          )}
        </VStack>
      </HStack>
    </Box>
  );
};

export const DocumentStatusCard = Object.assign(DocumentStatusCardComponent, {
  Progress: DocumentStatusCardProgress,
  Icon: DocumentStatusCardIcon,
});
