import type { FC, ReactNode } from 'react';
import { Box, Flex, HStack, ModalFooter } from '@chakra-ui/react';

interface ModalFooterProps {
  secondaryButtons?: ReactNode;
  primaryButtons?: ReactNode;
  footerContent?: ReactNode;
}

export const Footer: FC<ModalFooterProps> = ({ secondaryButtons, primaryButtons, footerContent }) => (
  <ModalFooter>
    <Flex justify="space-between" w="100%">
      {secondaryButtons && Array.isArray(secondaryButtons) && secondaryButtons.length > 0 && (
        <Box>{secondaryButtons}</Box>
      )}
      <HStack w="fit-content">{footerContent}</HStack>
      {primaryButtons && Array.isArray(primaryButtons) && primaryButtons.length > 0 && <Box>{primaryButtons}</Box>}
    </Flex>
  </ModalFooter>
);
