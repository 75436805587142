import type { FieldValues, StepValidator } from 'graneet-form';
import { Step as GraneetFormStep } from 'graneet-form';
import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { ReactNode } from 'react';

export interface StepProps<WizardValues extends Record<string, FieldValues>, Step extends keyof WizardValues>
  extends BoxProps {
  children: ReactNode;
  name: Step;
  onNext?: StepValidator<WizardValues, Step>;
  title: string;
}

export const Step = <
  WizardValues extends Record<string, FieldValues>,
  Step extends keyof WizardValues = keyof WizardValues,
>({
  children,
  name,
  onNext,
  title,
  ...props
}: StepProps<WizardValues, Step>) => (
  <GraneetFormStep name={name} onNext={onNext} title={title} noFooter>
    <Box flex={1} py={6} {...props}>
      {children}
    </Box>
  </GraneetFormStep>
);
