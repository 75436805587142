import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import type { FC } from 'react';
import { useCallback } from 'react';
import type { IProject, ISupplierInvoice } from '@graneet/business-logic';

import { ViewSupplierInvoiceScreen } from '../../../../purchases/supplier-invoices/ViewSupplierInvoiceScreen';
import { EditSupplierInvoiceScreen } from '../../../../purchases/supplier-invoices/EditSupplierInvoiceScreen';

import { ViewSupplierInvoicesScreen } from './ViewSupplierInvoicesScreen';

import {
  SupplierInvoiceEditContextProvider,
  useSupplierInvoiceGoToListing,
} from 'features/supplier-invoice/contexts/SupplierInvoiceEditContext.provider';

interface PurchasesRouterProps {
  project: IProject;
}

export const ProjetSupplierInvoicesRouter: FC<PurchasesRouterProps> = ({ project }) => {
  const { path } = useRouteMatch();

  const history = useHistory();
  const goToListing = useSupplierInvoiceGoToListing({ project });

  const onCreatedOrEdited = useCallback(
    (supplierInvoice: ISupplierInvoice) => {
      history.push(`/projects/${project.id}/purchases/supplier-invoices/${supplierInvoice.id}`);
    },
    [history, project.id],
  );

  return (
    <Switch>
      <Route exact path={path}>
        <ViewSupplierInvoicesScreen project={project} />
      </Route>

      <Route exact path={`${path}/create`}>
        <SupplierInvoiceEditContextProvider>
          <EditSupplierInvoiceScreen
            context="create"
            project={project}
            onQuit={goToListing}
            onCreatedOrEdited={onCreatedOrEdited}
          />
        </SupplierInvoiceEditContextProvider>
      </Route>

      <Route exact path={`${path}/:supplierInvoiceId/edit`}>
        <SupplierInvoiceEditContextProvider>
          <EditSupplierInvoiceScreen
            context="edit"
            project={project}
            onQuit={(supplierInvoice) => {
              history.push(`/projects/${project.id}/purchases/supplier-invoices/${supplierInvoice?.id}`);
            }}
            onCreatedOrEdited={onCreatedOrEdited}
          />
        </SupplierInvoiceEditContextProvider>
      </Route>

      <Route exact path={`${path}/:supplierInvoiceId`}>
        <ViewSupplierInvoiceScreen project={project} />
      </Route>
    </Switch>
  );
};
