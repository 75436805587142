import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export type ChapterContextProps = { title: string };
interface ChaptersContextApi {
  registerChapter(id: string, props: ChapterContextProps): void;
  unregisterChapter(id: string): void;

  chapters: Record<string, ChapterContextProps>;
}

export const ChaptersContext = createContext<ChaptersContextApi>({
  registerChapter() {
    return () => {};
  },

  unregisterChapter() {},

  chapters: {},
});

export const useChaptersContext = () => useContext(ChaptersContext);

export const ChaptersContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [chapters, setChapters] = useState<Record<string, ChapterContextProps>>({});

  const registerChapter = useCallback((id: string, props: ChapterContextProps) => {
    setChapters((prevValue) => ({
      ...prevValue,
      [id]: props,
    }));
  }, []);

  const unregisterChapter = useCallback((id: string) => {
    setChapters((prevValue) => {
      const cpy = { ...prevValue };
      delete cpy[id];
      return cpy;
    });
  }, []);

  const value = useMemo<ChaptersContextApi>(
    () => ({
      chapters,
      registerChapter,
      unregisterChapter,
    }),
    [chapters, registerChapter, unregisterChapter],
  );

  return <ChaptersContext.Provider value={value}>{children}</ChaptersContext.Provider>;
};
