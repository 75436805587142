import { BuildingIcon, GroupIcon, Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { Box, Checkbox, Text } from '@chakra-ui/react';
import type { IContact, IGroupedContact } from '@graneet/business-logic';
import { ASSOCIATION_TYPE } from '@graneet/business-logic';

import { CONTACT_FIELDS } from '../../constants/contacts.constant';

import { ContactAssociationRow } from './rows/ContactAssociationRow';

interface ContactProjectAssociationTableProps {
  availableContacts: IGroupedContact[];
  onCheck: (id: string) => () => void;
  handleCheckAll: () => () => void;
  isAllChecked: boolean;
  isContactDefaultEmailRecipient: (contact: IContact) => boolean;
}

export const ContactProjectAssociationTable: FC<ContactProjectAssociationTableProps> = ({
  availableContacts = [],
  onCheck,
  handleCheckAll,
  isAllChecked,
  isContactDefaultEmailRecipient,
}) => {
  const { t } = useTranslation(['contacts']);

  return (
    <Table templateColumns={['3rem', 'auto', 'auto']} noResultLabel={t('contacts:noResult')}>
      <Table.Header>
        <Table.Cell>
          <Checkbox colorScheme="greenBrand" onChange={handleCheckAll()} isChecked={isAllChecked} />
        </Table.Cell>
        <Table.Cell sortBy={CONTACT_FIELDS.LAST_NAME}>{t('contacts:fields.lastName')}</Table.Cell>
        <Table.Cell sortBy={CONTACT_FIELDS.EMAIL}>{t('contacts:fields.contactDetails')}</Table.Cell>
      </Table.Header>
      {(availableContacts || []).map((client) => {
        const contactInfos = client.contacts.map((contact) => (
          <ContactAssociationRow
            key={`contact-row-table-${contact.id}`}
            contact={contact}
            onCheck={onCheck}
            isContactDefaultEmailRecipient={isContactDefaultEmailRecipient}
          />
        ));
        return (
          <>
            <Table.Row backgroundColor="gray.100">
              <Table.Cell variant="header">
                {client.type === ASSOCIATION_TYPE.CLIENT && (
                  <Box display="flex" justifyItems="center" alignItems="center">
                    <BuildingIcon width="1.3rem" height="1.3rem" />
                  </Box>
                )}
                {client.type === ASSOCIATION_TYPE.OTHER && (
                  <Box display="flex" justifyItems="center" alignItems="center">
                    <GroupIcon color="gray.800" width="1.3rem" height="1.3rem" />
                  </Box>
                )}
              </Table.Cell>

              <Table.Cell>
                <Text fontWeight="600" textColor="gray.800">
                  {client.type === ASSOCIATION_TYPE.OTHER ? 'Autres' : client.name}
                </Text>
              </Table.Cell>
              <Table.Cell />
            </Table.Row>
            {contactInfos}
          </>
        );
      })}
    </Table>
  );
};
