import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import { useCallback, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';

import { LotEditor } from './LotEditor';
import { ItemEditor } from './ItemEditor';

export const DenominationEditorCell = (params: CustomCellEditorProps<QuoteNodeObject>) => {
  const { data, node, column, value, onValueChange, api } = params;

  const handleChange = useCallback(
    (key: string) => (v: unknown) => {
      onValueChange({ ...value, [key]: v });
    },
    [onValueChange, value],
  );

  const element = useMemo(() => {
    if (!data) {
      return null;
    }
    if (node.footer) {
      return null;
    }
    if (data.content.type === 'QuoteLot') {
      return (
        <LotEditor
          quoteLot={data.content}
          onChange={handleChange('denomination')}
          columnWidth={column.getActualWidth()}
          api={api}
        />
      );
    }
    return (
      <ItemEditor
        columnWidth={column.getActualWidth()}
        nodeId={data.id}
        level={node.level}
        hasChildren={data.childrenId.length > 0}
        type={data.content.type}
        item={data.content}
        componentTypeId={'componentTypeId' in data.content ? data.content.componentTypeId : undefined}
        onChange={handleChange}
        api={api}
      />
    );
  }, [data, node.footer, node.level, handleChange, column, api]);

  return (
    <Flex minWidth="400px" width={column.getActualWidth()} alignItems="center" justifyContent="center">
      {element}
    </Flex>
  );
};
