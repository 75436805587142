import { HStack } from '@chakra-ui/react';
import { CurrencyField, Fieldset } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ProjectCreateForm } from '../../forms/project-create-wizard';

export const ProjectKmExpenseFieldset: FC = () => {
  const { t } = useTranslation(['project']);

  return (
    <Fieldset<ProjectCreateForm>
      handle="defaultKmExpense"
      legend={t('project:flow.defaultKmExpense.legend')}
      title={t('project:flow.defaultKmExpense.title')}
      validationNames={['defaultKmExpense']}
    >
      <HStack>
        <CurrencyField<ProjectCreateForm>
          name="defaultKmExpense"
          label={t('project:flow.defaultKmExpense.amount')}
          maxW={200}
          inputProps={{
            textAlign: 'right',
          }}
        />
      </HStack>
    </Fieldset>
  );
};
