import type { FC } from 'react';
import { useCallback } from 'react';
import { useToken } from '@chakra-ui/react';
import { DeepTable, PriceAdvanced, Tree } from '@graneet/lib-ui';

import { useProgressStatementContract, useProgressStatementTreeContext } from '../../hooks/useProgressStatementTree';
import type { ContractId } from '../../services/progress-statement-tree.util';

import { ProgressStatementContratTotalLabel } from './ProgressStatementContratTotalLabel';
import { ProgressStatementGlobalProgressForm } from './ProgressStatementGlobalProgressPercentageForm/ProgressStatementGlobalProgressPercentageForm';

interface ProgressStatementDeepTableContractLineProps {
  id: ContractId;

  isUpdatable: boolean;
}

export const ProgressStatementDeepTableContractLine: FC<ProgressStatementDeepTableContractLineProps> = ({
  id,
  isUpdatable,
}) => {
  const { node: contract } = useProgressStatementContract(id);
  const { switchNodeIsExpanded } = useProgressStatementTreeContext();
  const closeCollapse = useCallback(() => {
    switchNodeIsExpanded(id);
  }, [id, switchNodeIsExpanded]);

  const [gray400] = useToken('colors', ['gray.400']);

  return (
    <DeepTable.Row
      backgroundColor="gray.300"
      color="black"
      borderColor={gray400}
      fontSize="1rem"
      offset={0}
      role="group"
      leftContent={<Tree.ToggleButton nodeId={id} color="gray.400" />}
    >
      <DeepTable.Cell>{contract.code}</DeepTable.Cell>

      <DeepTable.Cell gridColumn="span 4">{contract.name}</DeepTable.Cell>

      <DeepTable.Cell right>
        <PriceAdvanced amount={contract.totalAmountExVAT} />
      </DeepTable.Cell>

      <DeepTable.Cell />

      <DeepTable.Cell variant="shadow" center>
        {isUpdatable && (
          <ProgressStatementGlobalProgressForm
            id={contract.container.id}
            nodeId={id}
            contract={contract}
            type="container"
            onPercentageUpdatedWithoutHigherNestedPercentage={closeCollapse}
          />
        )}
      </DeepTable.Cell>

      <DeepTable.Cell right>
        <ProgressStatementContratTotalLabel id={id} contract={contract} />
      </DeepTable.Cell>
    </DeepTable.Row>
  );
};
