import type { IProject } from '@graneet/business-logic';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { type KeysMatching, SingleSelectField, type SingleSelectFieldProps } from '@graneet/lib-ui';
import type { FieldValues } from 'graneet-form';

import { useAssociableProjectsWithoutPagination } from '../services/project.api';

import { ProjectFieldOption } from './ProjectField/ProjectFieldOption';

type Value = number | undefined | null;

interface ProjectAssociableFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, Value> = KeysMatching<T, Value>,
> extends Omit<SingleSelectFieldProps<T, K>, 'options' | 'onChange'> {
  excludedProjectIds?: IProject['id'][];

  children?: ReactNode;

  displayFull?: boolean;
}

export const ProjectAssociableField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, Value> = KeysMatching<T, Value>,
>({
  excludedProjectIds,
  children,
  displayFull = true,
  ...props
}: ProjectAssociableFieldProps<T, K>) => {
  const projects = useAssociableProjectsWithoutPagination();

  const projectsWithoutSelected = useMemo(
    () =>
      projects.data.data
        .filter((project) => !(excludedProjectIds ?? []).includes(project.id))
        .map((project) => ({
          project,
          label: <ProjectFieldOption project={project} displayFull={displayFull} />,
          searchTerm: `${project.name} ${project.refCode} ${project.primaryClient.enterpriseName} ${project.address.address} `,
          value: project.id,
        })),
    [displayFull, excludedProjectIds, projects.data.data],
  );

  return (
    <SingleSelectField<T> options={projectsWithoutSelected as any} {...props}>
      {children}
    </SingleSelectField>
  );
};
