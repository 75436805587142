import type { FC } from 'react';
import { useCallback, useState } from 'react';
import type { DrawerApi } from '@graneet/lib-ui';
import { Button, DrawersStack, ListingLayout } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import type { ILibraryComponent, ILibraryComponentWithRelations } from '@graneet/business-logic';
import { TRACKING_EVENT_ENUM } from '@graneet/business-logic';

import { useLibraryComponents } from '../services/library-component.api';
import { useSegmentAnalytics } from '../../analytic/components/SegmentProvider';

import { LibraryComponentTable } from './LibraryComponentTable';

interface LibraryComponentImportProps {
  drawer: DrawerApi<string>;
  onSubmit(selectedLibraryComponent: ILibraryComponent[]): void;
}

export const LibraryComponentImport: FC<LibraryComponentImportProps> = ({ drawer, onSubmit }) => {
  const { t } = useTranslation(['library']);
  const segmentAnalytics = useSegmentAnalytics();

  const libraryComponents = useLibraryComponents({ storageStrategy: 'state' });

  const [selectedRows, setSelectedRows] = useState<ILibraryComponentWithRelations[]>([]);
  const [isImporting, setIsImporting] = useState(false);

  const handleOnSubmit = useCallback(async () => {
    setIsImporting(true);
    onSubmit(selectedRows);
    if (segmentAnalytics) {
      await segmentAnalytics.sendEvent(TRACKING_EVENT_ENUM.ORDER_ITEMS_IMPORT, { type: 'library-component' });
    }

    setIsImporting(false);

    drawer.onClose();
  }, [drawer, onSubmit, segmentAnalytics, selectedRows]);

  const numberOfComponents = selectedRows.length;

  return (
    <DrawersStack.Drawer
      title={t('library:componentDrawer.title')}
      drawer={drawer}
      footer={
        <Flex justify="flex-end" w="100%">
          <Button onClick={handleOnSubmit} isDisabled={numberOfComponents === 0} isLoading={isImporting}>
            {t('library:componentDrawer.cta', { count: numberOfComponents })}
          </Button>
        </Flex>
      }
      size="2xl"
    >
      <Text mb={4}>{t('library:componentDrawer.description')}</Text>

      <ListingLayout
        pagination={libraryComponents}
        search={{
          placeholder: t('library:actions.searchLibraryComponent'),
        }}
        content={
          <LibraryComponentTable
            gridId="library-component-import"
            libraryComponents={libraryComponents}
            onRowSelected={setSelectedRows}
          />
        }
      />
    </DrawersStack.Drawer>
  );
};
