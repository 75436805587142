import type { IInitialTree, TreeContextApi } from '@graneet/lib-ui';
import { useCurrency, useLeaf, useLeafComputedValue, useTree, useTreeContext } from '@graneet/lib-ui';
import type { IItemWithUUID, ILotWithUUID } from '@graneet/business-logic';
import { computeTotalExVAT } from '@graneet/business-logic';
import { useMemo } from 'react';
import { isNil } from 'lodash-es';

const treeOptions = { areDeletionsPermanent: true };

export type ContainerTreeContextApi = TreeContextApi<ILotWithUUID, IItemWithUUID, never, ItemComputedValues>;
export interface ItemComputedValues {
  totalExVAT: number;

  isComplete: boolean;
}

export const useInvoiceTree = (initialTree: IInitialTree<ILotWithUUID, IItemWithUUID>): ContainerTreeContextApi => {
  const { mapAmountToNumber } = useCurrency();

  const computedValuesFns = useMemo(
    () => ({
      computeNodeComputedValue: () => ({}) as never,
      computeLeafComputedValue: (leaf: IItemWithUUID) => ({
        totalExVAT: computeTotalExVAT({
          quantity: leaf.quantity || 0,
          unitPrice: mapAmountToNumber(leaf.unitPrice || 0),
        }),
        isComplete: !!leaf.description && !isNil(leaf.quantity) && !isNil(leaf.unitPrice) && !!leaf.unit,
      }),
    }),
    [mapAmountToNumber],
  );

  return useTree<ILotWithUUID, IItemWithUUID, never, ItemComputedValues>(initialTree, computedValuesFns, treeOptions);
};

export const useInvoiceTreeContext = () => useTreeContext<ILotWithUUID, IItemWithUUID, never, ItemComputedValues>();

export const useInvoiceItemComputedValue = (id: IItemWithUUID['id']) =>
  useLeafComputedValue<IItemWithUUID, ItemComputedValues>(id);

export const useInvoiceItem = (id: IItemWithUUID['id']) => useLeaf<ILotWithUUID, IItemWithUUID>(id);
