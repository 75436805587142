import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { VariantProps } from '@graneet/lib-ui';
import { Table, Badge } from '@graneet/lib-ui';
import type { IBill } from '@graneet/business-logic';
import { isBillLost, isBillNotFullyPayed } from '@graneet/business-logic';

interface BillLateDaysCellProps extends VariantProps<'default'> {
  bill: IBill;
}

export const BillLateDaysCell: FC<BillLateDaysCellProps> = ({ bill, ...props }) => {
  const { t } = useTranslation(['global']);

  if (bill.lateDays && bill.lateDays > 0 && isBillNotFullyPayed(bill) && !isBillLost(bill)) {
    return (
      <Table.Cell center {...props}>
        <Badge
          showDot
          colorScheme="red"
          borderRadius="full"
        >{`${bill.lateDays} ${t('global:words.abreviated_day')}`}</Badge>
      </Table.Cell>
    );
  }

  return <Table.Cell {...props} />;
};
