import { HStack } from '@chakra-ui/react';
import { QuotationInput } from '@graneet/lib-ui';
import type { QuoteComponentsGroupByType } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { memo, useCallback } from 'react';

export const TextCell = memo((params: CustomCellRendererProps<QuoteComponentsGroupByType>) => {
  const { value, node } = params;

  const emptyCb = useCallback(() => {}, []);

  if (node.footer) {
    return null;
  }

  const text = value || '-';

  return (
    <HStack display="inline-flex" height="100%" alignItems="flex-start" background="transparent" p={2}>
      <QuotationInput
        isReadOnly
        color="#1F2A37"
        textAlign="center"
        value={text}
        onChange={emptyCb}
        onBlur={emptyCb}
        onKeyDownCapture={emptyCb}
        noStyle
        w={`${text.length + 2}ch`}
        p={0}
      />
    </HStack>
  );
});
