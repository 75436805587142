import type { BoxProps } from '@chakra-ui/react';

export const ABSOLUTE_COVER: BoxProps = {
  position: 'absolute',
  w: '100%',
  h: '100%',
  top: 0,
  left: 0,
};

export type ImageSize = {
  width: number;
  height: number;
};
