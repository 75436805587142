import type { AnyRecord, FieldValues } from 'graneet-form';
import { Field, VALIDATION_OUTCOME } from 'graneet-form';

import type { MultiAutocompleteProps } from '../../Autocomplete/MultiAutocomplete';
import { MultiAutocomplete } from '../../Autocomplete/MultiAutocomplete';
import type { OptionType } from '../../Autocomplete/Autocomplete.util';
import type { BadgeProps } from '../../Badge/Badge';
import type { KeysMatching } from '../../../utils';

export interface MultiAutocompleteFieldProps<
  T extends FieldValues,
  Option extends OptionType,
  RenderProps = Omit<BadgeProps, 'children'>,
  K extends KeysMatching<T, Array<Option['value']>> = KeysMatching<T, Array<Option['value']>>,
> extends Omit<MultiAutocompleteProps<Option, RenderProps>, 'name' | 'onChange' | 'value'> {
  name: K;

  data?: AnyRecord;

  onChange?: MultiAutocompleteProps<Option, RenderProps>['onChange'];
}

export const MultiAutocompleteField = <
  T extends FieldValues,
  Option extends OptionType,
  RenderProps = Omit<BadgeProps, 'children'>,
  K extends KeysMatching<T, Array<Option['value']>> = KeysMatching<T, Array<Option['value']>>,
>({
  name,
  data,
  children,
  onChange,
  ...otherProps
}: MultiAutocompleteFieldProps<T, Option, RenderProps, K>) => (
  <Field<T, K>
    name={name}
    data={data}
    render={(properties, fieldState) => {
      const { value, onChange: fieldOnChange } = properties;
      const { validationStatus } = fieldState;
      const shouldDisplayError = validationStatus.status === VALIDATION_OUTCOME.INVALID;

      return (
        <MultiAutocomplete<Option, RenderProps>
          {...otherProps}
          value={value as Option['value'][]}
          onChange={(v) => {
            if (onChange) {
              onChange(v);
            }
            fieldOnChange(v as T[K]);
          }}
          shouldDisplayError={shouldDisplayError}
          errorMessage={validationStatus.message}
        />
      );
    }}
  >
    {children}
  </Field>
);
