import type { FC } from 'react';
import { Table, TextField, CurrencyField, SingleSelectField } from '@graneet/lib-ui';
import { useCallback } from 'react';
import { useFormContext, useOnBlurValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { Box, Circle, Flex } from '@chakra-ui/react';

import type { PenaltyForm } from '../forms/penalty.form';
import {
  PENALTY_TYPE,
  getPenaltyAmountIncVatFieldName,
  getPenaltyDenominationFieldName,
  getPenaltyTypeFieldName,
} from '../forms/penalty.form';

import { Rule } from 'features/form/rules/Rule';

interface PenaltyTableRowProps {
  fieldId: string;
  removeField: (fieldId: string) => void;
}

export const PenaltyTableRow: FC<PenaltyTableRowProps> = ({ fieldId, removeField }) => {
  const { t } = useTranslation(['penalty']);

  const typeFieldName = getPenaltyTypeFieldName(fieldId);
  const denominationFieldName = getPenaltyDenominationFieldName(fieldId);
  const amountIncVATFieldName = getPenaltyAmountIncVatFieldName(fieldId);

  const formContext = useFormContext<PenaltyForm>();
  const values = useOnBlurValues(formContext, [typeFieldName, denominationFieldName, amountIncVATFieldName]);
  const isComplete = Object.values(values).every((value) => !!value);

  const onRemove = useCallback(() => {
    removeField(fieldId);
  }, [fieldId, removeField]);

  return (
    <Table.Row backgroundColor={isComplete ? 'transparent' : 'yellow.100'}>
      <Table.Cell>
        <SingleSelectField<PenaltyForm>
          name={typeFieldName}
          options={[
            {
              value: PENALTY_TYPE.POSITIVE,
              label: (
                <Flex gap={2} alignItems="center">
                  <Circle size="0.725rem" bg="green.500" />
                  {t('penalty:drawer.increaseValue')}
                </Flex>
              ),
            },
            {
              value: PENALTY_TYPE.NEGATIVE,
              label: (
                <Flex gap={2} alignItems="center">
                  <Circle size="0.725rem" bg="red.500" />
                  {t('penalty:drawer.decreaseValue')}
                </Flex>
              ),
            },
          ]}
          isSearchable={false}
          isClearable={false}
          isRequired
        >
          <Rule.IsRequired />
        </SingleSelectField>
      </Table.Cell>

      <Table.Cell>
        <TextField<PenaltyForm> name={denominationFieldName} isRequired>
          <Rule.IsRequired />
        </TextField>
      </Table.Cell>

      <Table.Cell>
        <CurrencyField<PenaltyForm> isRequired name={amountIncVATFieldName} min={0}>
          <Rule.IsRequired />
        </CurrencyField>
      </Table.Cell>

      <Table.Cell>
        <Box onClick={onRemove} color="red.500" cursor="pointer">
          <i className="ri-delete-bin-line" />
        </Box>
      </Table.Cell>
    </Table.Row>
  );
};
