import { forwardRef } from 'react';
import DayPicker from 'react-day-picker';
import type { DayPickerProps } from 'react-day-picker/types/Props';

import { useDayPickerLocale } from './day-picker-config';

import 'react-day-picker/lib/style.css';
import './DatePicker.css';

export const DatePicker = forwardRef<DayPicker, DayPickerProps>((props, ref) => {
  const locale = useDayPickerLocale();

  return (
    <DayPicker
      className="DatePicker"
      ref={ref}
      months={locale.months}
      weekdaysLong={locale.weekdaysLong}
      weekdaysShort={locale.weekdaysMin}
      firstDayOfWeek={1}
      showOutsideDays
      {...props}
    />
  );
});
