import type { PaginatedResponse, UsePaginationOptions } from '@graneet/lib-ui';
import { usePaginationQuery, useToast } from '@graneet/lib-ui';
import type {
  ILibraryComponent,
  ILibraryComponentCreationDTO,
  ILibraryComponentUpdateDTO,
  ILibraryComponentWithRelations,
  ILibraryComponentsImportDTO,
} from '@graneet/business-logic';
import type { QueryClient } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { apiNew } from 'features/api/services/apiNew.service';
import { LIBRARY_JOB_FACTORY_NAME } from 'features/library-job/services/library-job.api';

const FACTORY_NAME = 'library-component';
const LIBRARY_COMPONENT_PATH = '/library-components';

const libraryComponentFactoryKey = createQueryKeys(FACTORY_NAME, {
  get: (queryParams: URLSearchParams) => ({
    queryKey: [LIBRARY_COMPONENT_PATH, queryParams],
    queryFn: () =>
      apiNew.get<URLSearchParams, PaginatedResponse<ILibraryComponentWithRelations>>(
        LIBRARY_COMPONENT_PATH,
        queryParams,
      ),
  }),
});

async function invalidAllLibraryComponentQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    predicate: (query) =>
      typeof query.queryKey[0] === 'string' && [FACTORY_NAME, LIBRARY_JOB_FACTORY_NAME].includes(query.queryKey[0]),
    exact: false,
  });
}

export function useLibraryComponents(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) => queryClient.fetchQuery(libraryComponentFactoryKey.get(urlSearchParams)),
    options,
  );
}

export function useLibraryComponentCreate(type: 'creation' | 'duplication') {
  const queryClient = useQueryClient();

  const toast = useToast();
  const { t } = useTranslation(['global', 'library']);

  return useMutation({
    mutationFn: (dto: ILibraryComponentCreationDTO) =>
      apiNew.post<ILibraryComponentCreationDTO, ILibraryComponent>(LIBRARY_COMPONENT_PATH, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (response) => {
      await invalidAllLibraryComponentQueries(queryClient);
      if (type === 'creation') {
        toast.success(t('library:success.createComponent', { componentName: response.description }));
      } else {
        toast.success(t('library:success.duplicateComponent', { componentName: response.description }));
      }
    },
  });
}

export function useLibraryComponentImport() {
  const queryClient = useQueryClient();

  const toast = useToast();
  const { t } = useTranslation(['global', 'library']);

  return useMutation({
    mutationFn: (dto: ILibraryComponentsImportDTO) =>
      apiNew.post<ILibraryComponentsImportDTO, ILibraryComponent[]>(`${LIBRARY_COMPONENT_PATH}/batch`, dto),
    onError: () => {
      toast.error(t('library:errors.importComponents'));
    },
    onSuccess: async () => {
      await invalidAllLibraryComponentQueries(queryClient);
      toast.success(t('library:success.importComponents'));
    },
  });
}

export function useLibraryComponentUpdate() {
  const queryClient = useQueryClient();

  const toast = useToast();
  const { t } = useTranslation(['global', 'library']);

  return useMutation({
    mutationFn: (params: { id: number; dto: ILibraryComponentUpdateDTO }) =>
      apiNew.patch<ILibraryComponentUpdateDTO, ILibraryComponent>(`${LIBRARY_COMPONENT_PATH}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (response) => {
      await invalidAllLibraryComponentQueries(queryClient);
      toast.success(t('library:success.modifyComponent', { componentName: response.description }));
    },
  });
}

export function useLibraryComponentDelete() {
  const queryClient = useQueryClient();

  const toast = useToast();
  const { t } = useTranslation(['global', 'library']);

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, ILibraryComponent>(`${LIBRARY_COMPONENT_PATH}/${id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (response) => {
      await invalidAllLibraryComponentQueries(queryClient);
      toast.success(t('library:success.removeComponent', { componentName: response.description }));
    },
  });
}
