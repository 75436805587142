import type { UseDisclosureReturn } from '@chakra-ui/react';
import type { IDownPayment } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback } from 'react';

import { useForceDraftDownPayment } from 'features/down-payment/services/down-payment.api';
import { StatementForceDraftModal } from 'features/statement/components/StatementForceDraftModal';

interface ForceDraftDownPaymentModalProps {
  modal: UseDisclosureReturn;
  downPayment: IDownPayment;
  onConfirmed(): void;
}

export const ForceDraftDownPaymentModal: FC<ForceDraftDownPaymentModalProps> = ({
  modal,
  downPayment,
  onConfirmed,
}) => {
  const forceDraftDownPaymentMutation = useForceDraftDownPayment();

  const handleClick = useCallback(async () => {
    await forceDraftDownPaymentMutation.mutateAsync(downPayment.id, {
      onSuccess: () => {
        onConfirmed();
      },
    });
  }, [forceDraftDownPaymentMutation, downPayment.id, onConfirmed]);

  return <StatementForceDraftModal modal={modal} onConfirmed={handleClick} />;
};
