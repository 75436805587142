import { ContentTypeGuard, QuoteFile } from '@org/quotation-lib';

import { quoteSetToStore } from '../store/quoteSetToStore';
import { mapFileConversionStatusToEntity } from '../mappers/quoteFileMapper';
import { StatusEnum, quoteClientRequestsSetToStore } from '../store/quoteClientRequestsSetToStore';
import { quoteGetFromStore } from '../store/quoteGetFromStore';

import type { QuoteFileWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';

export const createQuoteFileAttachCallback = (message: QuoteFileWsMessage) => {
  const quote = quoteGetFromStore();
  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();

  if (quote) {
    const hasClientRequest = quoteClientRequestsFromStore.find(
      (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
    );
    if (hasClientRequest === undefined) {
      const node = quote.getTree().getNodeOrThrow(message.data.nodeId);

      message.data.files.map(async (file) => {
        const content = node.getContent();
        if (
          ContentTypeGuard.isQuoteBasicItem(content) ||
          // ContentTypeGuard.isQuoteHiddenCostItem(content) || TODO LATER
          ContentTypeGuard.isQuoteOptionalItem(content) ||
          ContentTypeGuard.isQuoteSubItem(content)
        ) {
          const fileEntity = new QuoteFile({
            id: file.id,
            key: file.key,
            name: file.name,
            mimeType: file.mimeType,
            url: file.url,
            pages: file.pages,
            conversionStatus: mapFileConversionStatusToEntity(file.conversionStatus),
            hasThumbnail: file.hasThumbnail,
            sizeBytes: file.sizeBytes,
          });
          content.addFile(fileEntity);
        }
      });
      quoteSetToStore(quote);
    } else {
      quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
    }
  }
};
