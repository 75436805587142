import type { DrawersStackContextApi } from '@graneet/lib-ui';
import { AmountSummary, ActionMenu, formatPercentage, DrawersStack, useDrawersStack, useDrawer } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IDiscount } from '@graneet/business-logic';
import { getDiscountAmountExVAT, DISCOUNT_TYPES } from '@graneet/business-logic';
import type { FC } from 'react';

import { QuoteDiscountEditDrawer } from './modals/QuoteDiscountEditDrawer';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

interface QuoteTotalsDiscountProps {
  amountWithoutDiscountExVAT: number;

  discount: IDiscount;

  left?: boolean;

  isLight?: boolean;

  noActions?: boolean;

  drawersStack?: DrawersStackContextApi<string>;
}

export const QuoteTotalsDiscount: FC<QuoteTotalsDiscountProps> = ({
  amountWithoutDiscountExVAT,
  discount,
  drawersStack,
  left = false,
  isLight = false,
  noActions = false,
}) => {
  const { t } = useTranslation(['discount', 'quote']);
  const { deleteQuoteDiscount } = useQuoteEditContext();
  const getDrawersStack = useDrawersStack();

  const drawersStackContext = drawersStack ?? getDrawersStack;
  const drawerDiscount = useDrawer('drawer-discount', drawersStackContext);

  const { onOpen: onDiscountDrawerOpen } = drawerDiscount;

  const discountAmountExVAT = getDiscountAmountExVAT(discount, amountWithoutDiscountExVAT);
  const discountLabel =
    discount.type === DISCOUNT_TYPES.PERCENTAGE
      ? t('discount:labelWithPercentage', {
          percentage: formatPercentage(-discount.percentage!),
        })
      : t('quote:discounts.labelWithAmount');

  return (
    <>
      <AmountSummary.Item
        label={t('quote:discounts.amountWithoutDiscountExVAT')}
        amount={amountWithoutDiscountExVAT}
        important={!isLight}
        darkGrey={isLight}
        left={left}
        pl={0}
      />
      <AmountSummary.Item
        label={discountLabel}
        amount={discountAmountExVAT}
        negative
        sign
        light
        left={left}
        pl={0}
        rightContent={
          noActions ? null : (
            <>
              <ActionMenu placement="top-start">
                <ActionMenu.Edit onClick={onDiscountDrawerOpen} />
                <ActionMenu.Delete onClick={deleteQuoteDiscount} />
              </ActionMenu>

              <DrawersStack drawersStack={drawersStackContext}>
                <QuoteDiscountEditDrawer drawer={drawerDiscount} />
              </DrawersStack>
            </>
          )
        }
      />
    </>
  );
};
