import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, LabeledData, TwoColumns, Address, Date, Button, SimpleEditIcon, useCurrency } from '@graneet/lib-ui';
import { Grid, useDisclosure, Box } from '@chakra-ui/react';
import type { IProjectWithRelations } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { getProjectColor } from '../../services/project-colors.util';
import { EditProjectInformationsModal } from '../modals/EditProjectInformationsModal';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface ProjectInformationCardProps {
  project: IProjectWithRelations;
}

export const ProjectInformationCard: FC<ProjectInformationCardProps> = ({ project }) => {
  const { name, refCode, purchaseOrderNumber, startDate, endDate, address, note, color, defaultKmExpense } = project;

  const createProjectsButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_PROJECTS]);
  const updateModal = useDisclosure();
  const { t } = useTranslation(['project', 'global']);

  const { formatAsAmount } = useCurrency();

  const onUpdated = useCallback(() => {
    updateModal.onClose();
  }, [updateModal]);

  return (
    <>
      <Card
        title={t('project:cards.information')}
        topRightContent={
          <Button
            variant="outline"
            onClick={updateModal.onOpen}
            leftIcon={<SimpleEditIcon />}
            {...createProjectsButtonDisabledProps()}
          >
            {t('global:words.c.update')}
          </Button>
        }
      >
        <TwoColumns mt={4} alignContent="space-between">
          <LabeledData label={t('project:fields.name')} data={name} />
          {color && (
            <Box justifySelf="end" alignSelf="center" bg={getProjectColor(color)} boxSize="1.5rem" borderRadius="50%" />
          )}
        </TwoColumns>
        <TwoColumns mt={4}>
          <LabeledData label={t('project:fields.code')} data={refCode} />
          <LabeledData label={t('project:fields.purchaseOrderNumber')} data={purchaseOrderNumber} />
        </TwoColumns>
        <TwoColumns mt={4}>
          <LabeledData label={t('project:fields.startDate')} data={<Date>{startDate}</Date>} />
          <LabeledData label={t('project:fields.endDate')} data={<Date>{endDate}</Date>} />
        </TwoColumns>
        <Grid gap={6}>
          <TwoColumns mt={4}>
            <LabeledData label={t('project:fields.address')} data={<Address address={address} oneLine />} />
            <LabeledData
              label={t('project:fields.defaultKmExpense')}
              data={<Box>{formatAsAmount(defaultKmExpense ?? 0)} </Box>}
            />
          </TwoColumns>

          <LabeledData label={t('project:fields.note')} data={note} />
        </Grid>
      </Card>

      <EditProjectInformationsModal modal={updateModal} onUpdated={onUpdated} project={project} />
    </>
  );
};
