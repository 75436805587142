import type { IconProps } from '@chakra-ui/react';
import { HStack } from '@chakra-ui/react';
import type { FC, PropsWithChildren, ReactElement } from 'react';
import { cloneElement } from 'react';

import { EllipsisText } from '../../EllipsisText';

export type GraneetBadgeColor =
  | 'red'
  | 'blue'
  | 'cyan'
  | 'yellow'
  | 'green'
  | 'white'
  | 'gray'
  | 'indigo'
  | 'pink'
  | 'teal'
  | 'warm_gray'
  | 'orange'
  | 'greenBrand'
  | 'purple';

export interface DefaultGraneetBadgeProps extends PropsWithChildren {
  onClick?: () => void;
  hasHoverEffect?: boolean;
  icon?: ReactElement<IconProps>;
}

const CONFIG_COLORS = {
  red: {
    text: '#AA1608',
    border: '#FFBBB4',
    background: '#FFE6E3',
  },
  cyan: {
    text: '#006DAA',
    border: '#B0E2FF',
    background: '#EDF8FF',
  },
  green: {
    text: '#1A9453',
    border: '#BBF3D5',
    background: '#E0FEEE',
  },
  gray: {
    text: '#486581',
    border: '#D9E2EC',
    background: '#F9FBFF',
  },
  blue: {
    text: '#4055A8',
    border: '#BED0F7',
    background: '#E0E8F9',
  },
  yellow: {
    text: '#B34B00',
    border: '#FFDCAF',
    background: '#FFF1DB',
  },
  white: {
    text: '#9DA4AE',
    border: '#D2D6DB',
    background: '#FFF',
  },
  indigo: {
    background: 'indigo.100',
    text: 'indigo.700',
    border: 'indigo.200',
  },
  pink: {
    background: 'pink.100',
    text: 'pink.700',
    border: 'pink.200',
  },
  teal: {
    background: 'teal.100',
    text: 'teal.700',
    border: 'teal.200',
  },
  warm_gray: {
    background: 'gray.200',
    text: 'gray.800',
    border: 'gray.300',
  },
  orange: {
    background: 'orange.100',
    text: 'orange.700',
    border: 'orange.200',
  },
  purple: {
    background: 'purple.100',
    text: 'purple.700',
    border: 'purple.200',
  },
  greenBrand: {
    background: 'greenBrand.dark',
    text: 'white',
    border: 'transparent',
  },
} as const;

export type GraneetBadgeProps = DefaultGraneetBadgeProps & { color: GraneetBadgeColor };
export const GraneetBadge: FC<GraneetBadgeProps> = ({ children, onClick, color, icon, hasHoverEffect }) => {
  const textColor = CONFIG_COLORS[color].text;
  const borderColor = CONFIG_COLORS[color].border;
  const backgroundColor = CONFIG_COLORS[color].background;
  const hoverBackgroundColor = `linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), ${backgroundColor}`;

  const iconElement = icon ? cloneElement(icon, { boxSize: '0.8rem', stroke: textColor }) : null;
  return (
    <HStack
      cursor={onClick ? 'pointer' : 'default'}
      onClick={onClick}
      borderRadius={14}
      height="1.5rem"
      px="0.25rem"
      alignItems="center"
      gap={1}
      color={textColor}
      border={`0.063rem solid ${borderColor}`}
      background={backgroundColor}
      _hover={
        onClick || hasHoverEffect
          ? {
              background: hoverBackgroundColor,
            }
          : {}
      }
      _focus={{
        background: hoverBackgroundColor,
      }}
      fontSize="0.813rem"
      fontFamily="body"
      fontWeight={500}
      padding={3}
    >
      <EllipsisText>{children}</EllipsisText>
      {iconElement}
    </HStack>
  );
};
