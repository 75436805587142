import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { Box, Center, Flex, Stack } from '@chakra-ui/react';
import { Steps, TextField, Title, SingleSelectField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, useFormContext, useOnChangeValues, useStepForm } from 'graneet-form';
import { PERSONNA } from '@graneet/business-logic';

import type {
  EditOnboardingUserProfileForm,
  EditOnboardingWizard,
} from 'features/onboarding/forms/EditOnboardingWizard';
import { Rule } from 'features/form/rules/Rule';
import { OnboardingPersonna } from 'features/onboarding/components/OnboardingPersonna/OnboardingPersonna';

export const Personna: FC = () => {
  const form = useFormContext<EditOnboardingUserProfileForm>();
  const { personna } = useOnChangeValues(form, ['personna']);

  if (!personna) {
    return null;
  }

  return (
    <Steps.Placeholder placement="bottom">
      <Center>
        <Box boxSize="15rem">
          <OnboardingPersonna personna={personna} />
        </Box>
      </Center>
    </Steps.Placeholder>
  );
};

interface EditOnboardingUserProfileStepProps {
  userInformation: { firstName?: string; lastName?: string; phoneNumber?: string; personna?: PERSONNA } | null;
  lastStep?: boolean;
}

export const EditOnboardingUserProfileStep: FC<EditOnboardingUserProfileStepProps> = ({
  userInformation,
  lastStep,
}) => {
  const { t } = useTranslation(['global', 'onboarding']);

  const { form, initFormValues } = useStepForm<EditOnboardingWizard, 'userProfile'>();

  useEffect(() => {
    if (!userInformation) {
      return;
    }
    initFormValues({
      lastName: userInformation.lastName,
      firstName: userInformation.firstName,
      personna: userInformation.personna,
      phoneNumber: userInformation.phoneNumber,
    });
  }, [initFormValues, userInformation]);

  const options = useMemo(
    () =>
      Object.values(PERSONNA).map((personna) => ({
        label: t(`onboarding:personna.${personna}`),
        value: personna,
      })),
    [t],
  );

  return (
    <Form form={form} style={{ width: '100%', height: '100%' }}>
      <Stack gap={6} boxSize="100%" justifyContent="center" alignItems="center">
        <Title fontFamily="Alliance">{t('onboarding:steps.userProfile.title')}</Title>

        <Flex w="40rem">
          <Box w="50%" mr={2}>
            <TextField<EditOnboardingUserProfileForm>
              name="lastName"
              label={t('onboarding:fields.lastName')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>

          <Box w="50%" ml={2}>
            <TextField<EditOnboardingUserProfileForm>
              name="firstName"
              label={t('onboarding:fields.firstName')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>
        </Flex>

        <Flex gap={3} w="40rem">
          <SingleSelectField<EditOnboardingUserProfileForm>
            name="personna"
            options={options}
            label={t('onboarding:fields.personna')}
            isClearable={false}
          >
            <Rule.IsRequired />
          </SingleSelectField>
        </Flex>

        <Box w="40rem">
          <TextField<EditOnboardingUserProfileForm>
            name="phoneNumber"
            label={t('onboarding:fields.phoneNumber')}
            textAlign="left"
          >
            <Rule.IsRequired />
          </TextField>
        </Box>

        <Steps.NextStepButton w="40rem">
          {lastStep ? t('onboarding:steps.additionalInformation.cta') : t('global:words.c.next')}
        </Steps.NextStepButton>

        <Personna />
      </Stack>
    </Form>
  );
};
