import type { FC } from 'react';
import { PriceAdvanced } from '@graneet/lib-ui';
import type { IStatement } from '@graneet/business-logic';

export interface StatementPriceProps {
  statement: Pick<IStatement, 'amountExVAT'>;

  isCredit: boolean;
}
export const StatementPrice: FC<StatementPriceProps> = ({ statement, isCredit }) => (
  <PriceAdvanced negative={isCredit} amount={statement.amountExVAT} />
);
