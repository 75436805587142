import { useLayoutEffect } from 'react';
import { Tabs } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS, PERMISSION } from '@graneet/business-logic';

import { DisplayNotFoundScreen } from '../../DisplayNotFoundScreen';

import { DisbursementAnalysisTab } from './_tabs/DisbursementAnalysisTab';
import { ComponentTypeTab } from './_tabs/ComponentTypeTab';
import { LedgerSettingsTab } from './_tabs/LedgerSettingsTab';
import { ThirdPartyConfigTab } from './_tabs/ThirdPartyConfigTab';
import { EmailReminderTab } from './_tabs/EmailReminderTab';
import { PayVariablesTab } from './_tabs/PayVariablesTab';
import { CompanySettingsTab } from './_tabs/CompanySettingsTab';
import { PaymentMethodsSettingsTab } from './_tabs/PaymentMethodsSettingsTab';
import { QuoteSettingsTab } from './_tabs/QuoteSettingsTab';
import { BillingSettingsTab } from './_tabs/BillingSettingsTab';
import { PdfTemplatesTabs } from './_tabs/PdfTemplateTabs';
import { ViewUsersSettingsTab } from './_tabs/ViewUsersSettingsTab';
import { EmailTemplateTab } from './_tabs/EmailTemplateTab';
import { WorkflowValidationTab } from './_tabs/WorkflowValidationTab';
import { EmailImportTab } from './_tabs/EmailImportTab';
import { KYCValidationTab } from './_tabs/KYCValidationTab';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useAppContext } from 'features/app/contexts/AppContext';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewCompanySettings = () => {
  const { t } = useTranslation(['global']);

  const { currentUser } = useAppContext();
  const { updateHeaderTitle } = useHeaderContext();
  const { company } = currentUser;

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.settings'), []);
  }, [t, updateHeaderTitle]);

  const canUpdateQuoteParameters = usePermissions([PERMISSION.UPDATE_QUOTE_PARAMETERS]);
  const canUpdateQuoteTypeDisbursementType = usePermissions([PERMISSION.UPDATE_QUOTE_TYPE_DISBURSEMENT_TYPE]);
  const canUpdateCompanyInformations = usePermissions([PERMISSION.UPDATE_COMPANY_INFORMATIONS]);
  const canAccessBillingSettings = usePermissions([PERMISSION.ADMIN]);
  const canAccessLedgerSettings = usePermissions([PERMISSION.UPDATE_LEDGER_PARAMETERS]);
  const canUpdateThirdParties = usePermissions([PERMISSION.UPDATE_THIRD_PARTIES]);
  const canUpdateEmailTemplates = usePermissions([PERMISSION.UPDATE_EMAIL_TEMPLATES]);
  const canAccessWorkerHourlyPrice = usePermissions([PERMISSION.ACCESS_WORKER_HOURLY_PRICE]);
  const canAccessSupplierInvoiceValidationWorkflow = usePermissions([
    PERMISSION.ACCESS_SUPPLIER_INVOICE_VALIDATION_WORKFLOW,
  ]);

  const canUpdateEmailReminders = usePermissions([PERMISSION.UPDATE_EMAIL_REMINDERS]);
  // @[ff: workflow-validation-access]
  const hasWorkflowValidationAccessFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION_ACCESS);
  const hasEmailReminderUpdateAccess = canUpdateEmailReminders;

  const hasEmailImportFeatureFlag = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_IMPORT_BY_EMAIL);
  const hasKYCValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.KYC_VALIDATION);

  return (
    <>
      <Tabs
        hasInternalPadding
        fallbackPath="./company"
        errorComponent={<DisplayNotFoundScreen />}
        data={[
          {
            id: 'company',
            title: t('global:settingsNav.company.company'),
            path: './company',
            content: (
              <Tabs
                fallbackPath="./information"
                variant="badge"
                errorComponent={<DisplayNotFoundScreen />}
                data={[
                  {
                    id: 'information',
                    title: t('global:settingsNav.company.companyTab.information'),
                    exact: true,
                    path: './information',
                    content: <CompanySettingsTab />,
                  },
                  {
                    id: 'paymentMethods',
                    title: t('global:settingsNav.company.companyTab.paymentMethods'),
                    exact: true,
                    path: './payment-methods',
                    content: (
                      <QueryWrapper>
                        <PaymentMethodsSettingsTab />
                      </QueryWrapper>
                    ),
                  },
                  ...(canUpdateQuoteParameters
                    ? [
                        {
                          id: 'quote',
                          title: t('global:settingsNav.company.companyTab.quote'),
                          exact: true,
                          path: './quotes',
                          content: (
                            <QueryWrapper>
                              <QuoteSettingsTab />
                            </QueryWrapper>
                          ),
                        },
                      ]
                    : []),
                  ...(canAccessLedgerSettings
                    ? [
                        {
                          id: 'ledger',
                          title: t('global:settingsNav.company.companyTab.ledger'),
                          exact: true,
                          path: './ledger',
                          content: (
                            <QueryWrapper>
                              <LedgerSettingsTab />
                            </QueryWrapper>
                          ),
                        },
                      ]
                    : []),
                  // @[ff: workflow-validation-access]
                  ...(canAccessSupplierInvoiceValidationWorkflow && hasWorkflowValidationAccessFeatureFlag
                    ? [
                        {
                          id: 'workflow-validation',
                          title: t('global:settingsNav.company.companyTab.workflowValidation'),
                          exact: true,
                          path: './workflow-validation',
                          content: <WorkflowValidationTab />,
                        },
                      ]
                    : []),
                  ...(canAccessBillingSettings && company.stripeCustomer
                    ? [
                        {
                          id: 'billing',
                          title: t('global:settingsNav.company.companyTab.billing'),
                          exact: true,
                          path: './billing',
                          content: (
                            <QueryWrapper>
                              <BillingSettingsTab />
                            </QueryWrapper>
                          ),
                        },
                      ]
                    : []),
                  ...(hasKYCValidationFeatureFlag
                    ? [
                        {
                          id: 'kycValidation',
                          title: t('global:settingsNav.company.companyTab.kycValidation'),
                          exact: true,
                          path: './kyc-validation',
                          content: (
                            <QueryWrapper>
                              <KYCValidationTab />
                            </QueryWrapper>
                          ),
                        },
                      ]
                    : []),
                ]}
              />
            ),
          },
          ...(canUpdateCompanyInformations
            ? [
                {
                  id: 'pdfTemplates',
                  title: t('global:settingsNav.company.pdfTemplates'),
                  exact: true,
                  path: './pdf-models',
                  content: (
                    <QueryWrapper>
                      <PdfTemplatesTabs />
                    </QueryWrapper>
                  ),
                },
              ]
            : []),
          ...(canUpdateQuoteTypeDisbursementType
            ? [
                {
                  id: 'disbursementAnalysis',
                  title: t('global:settingsNav.company.disbursementAnalysis'),
                  path: './disbursement-analysis',
                  content: (
                    <Tabs
                      fallbackPath="./types"
                      variant="badge"
                      errorComponent={<DisplayNotFoundScreen />}
                      data={[
                        {
                          id: 'disbursementType',
                          title: t('global:settingsNav.company.disbursementAnalysisTab.disbursementType'),
                          path: './types',
                          exact: true,
                          content: <ComponentTypeTab />,
                        },
                        {
                          id: 'quoteMargin',
                          title: t('global:settingsNav.company.disbursementAnalysisTab.quoteMargin'),
                          path: './margins',
                          exact: true,
                          content: (
                            <QueryWrapper>
                              <DisbursementAnalysisTab />
                            </QueryWrapper>
                          ),
                        },
                      ]}
                    />
                  ),
                },
              ]
            : []),
          {
            id: 'users',
            title: t('global:settingsNav.company.users'),
            exact: true,
            path: './users',
            content: (
              <QueryWrapper>
                <ViewUsersSettingsTab />
              </QueryWrapper>
            ),
          },
          ...(canUpdateThirdParties
            ? [
                {
                  id: 'thirdParties',
                  title: t('global:settingsNav.company.thirdParties'),
                  exact: true,
                  path: './third-parties',
                  content: (
                    <QueryWrapper>
                      <ThirdPartyConfigTab />
                    </QueryWrapper>
                  ),
                },
              ]
            : []),
          ...(canUpdateEmailTemplates || hasEmailReminderUpdateAccess
            ? [
                {
                  id: 'mails',
                  title: t('global:settingsNav.company.mails'),
                  path: './email',
                  content: (
                    <Tabs
                      fallbackPath={canUpdateEmailTemplates ? './email-templates' : './email-reminders'}
                      variant="badge"
                      data={[
                        ...(canUpdateEmailTemplates
                          ? [
                              {
                                id: 'emailTemplates',
                                title: t('global:settingsNav.company.mailsTab.emailTemplates'),
                                path: './email-templates',
                                exact: true,
                                content: (
                                  <QueryWrapper>
                                    <EmailTemplateTab />
                                  </QueryWrapper>
                                ),
                              },
                            ]
                          : []),
                        ...(hasEmailReminderUpdateAccess
                          ? [
                              {
                                id: 'emailReminders',
                                title: t('global:settingsNav.company.mailsTab.emailReminders'),
                                path: './email-reminders',
                                exact: true,
                                content: (
                                  <QueryWrapper>
                                    <EmailReminderTab />
                                  </QueryWrapper>
                                ),
                              },
                            ]
                          : []),
                        ...(hasEmailImportFeatureFlag
                          ? [
                              {
                                id: 'emailImports',
                                title: t('global:settingsNav.company.mailsTab.emailImports'),
                                path: './email-imports',
                                exact: true,
                                content: (
                                  <QueryWrapper>
                                    <EmailImportTab />
                                  </QueryWrapper>
                                ),
                              },
                            ]
                          : []),
                      ]}
                    />
                  ),
                },
              ]
            : []),
          ...(canAccessWorkerHourlyPrice
            ? [
                {
                  id: 'pay-variables',
                  title: t('global:settingsNav.company.payVariablesTab'),
                  path: './pay-variables',
                  exact: true,
                  content: (
                    <QueryWrapper>
                      <PayVariablesTab />
                    </QueryWrapper>
                  ),
                },
              ]
            : []),
        ]}
      />
    </>
  );
};
