import { Table, useValuesContext } from '@graneet/lib-ui';
import type { IContact } from '@graneet/business-logic';
import { VStack, Text, Checkbox } from '@chakra-ui/react';

import { ContactDetails } from '../../ContactDetails';

interface ContactAssociationRowProps<T extends IContact> {
  contact: T;
  onCheck: (id: string) => () => void;
  isContactDefaultEmailRecipient: (contact: T) => boolean;
}

export const ContactAssociationRow = <T extends IContact>({
  contact,
  onCheck,
  isContactDefaultEmailRecipient,
}: ContactAssociationRowProps<T>) => {
  const { hasValue } = useValuesContext<string>();

  return (
    <Table.Row>
      <Table.Cell>
        <Checkbox colorScheme="greenBrand" onChange={onCheck(contact.id)} isChecked={hasValue(contact.id)} />
      </Table.Cell>
      <Table.Cell>
        <ContactDetails contact={contact} isContactDefaultEmailRecipient={isContactDefaultEmailRecipient} />
      </Table.Cell>
      <Table.Cell>
        <VStack spacing={1} align="flex-start">
          <Text>{contact.email ?? '-'}</Text>
          <Text>{contact.phoneNumber ?? '-'}</Text>
        </VStack>
      </Table.Cell>
    </Table.Row>
  );
};
