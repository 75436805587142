import type { FC, PropsWithChildren } from 'react';
import { VStack, Text, Divider, Box } from '@chakra-ui/react';

const SIZE_CONFIG = {
  sm: '20rem',
  md: '40rem',
  lg: '80rem',
  full: '100%',
} as const;

export interface SellsheetCardProps extends PropsWithChildren {
  title?: string;
  size?: 'sm' | 'md' | 'lg';
}

export const CardDivider: FC = () => (
  <Box position="relative" width="100%">
    <Divider position="absolute" left="-1rem" right="-1rem" width="auto" />
  </Box>
);

export const SellsheetCard: FC<SellsheetCardProps> = ({ children, title, size }) => (
  <VStack
    borderRadius="0.5rem"
    background="white"
    py="0.75rem"
    px="1rem"
    border="1px solid"
    borderColor="gray.200"
    width="100%"
    maxWidth={SIZE_CONFIG[size || 'sm']}
    gap="0.75rem"
    alignItems="flex-start"
  >
    {title && (
      <Text color="#4D5761" fontSize="12px" fontWeight={600} textTransform="uppercase">
        {title}
      </Text>
    )}
    <>{children}</>
  </VStack>
);
