import type { FC } from 'react';
import { AlertMessage, Modal, List, getChecklistIcon } from '@graneet/lib-ui';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { IProgressStatementValidityResponseDTO } from '@graneet/business-logic';

interface StatementInvalidAlertProps {
  onClose(): void;
  isOpen: boolean;
  statementValidationDTO: IProgressStatementValidityResponseDTO;
  onEditingConfirmed(): void;
}

export const StatementInvalidAlert: FC<StatementInvalidAlertProps> = ({
  onClose,
  isOpen,
  statementValidationDTO,
  onEditingConfirmed,
}) => {
  const { isDownPaymentAmortizationValid } = statementValidationDTO;

  const { t } = useTranslation(['progressStatement']);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" title={t('progressStatement:modals.validate.title')}>
      <AlertMessage type="error">{t('progressStatement:modals.validate.alertMessage')}</AlertMessage>
      <Text mt={6}>{t('progressStatement:modals.validate.description')}</Text>

      <List my={2}>
        <List.Item
          icon={getChecklistIcon(isDownPaymentAmortizationValid)}
          label={t('progressStatement:modals.validate.downPaymentAmortization')}
        />
      </List>

      <Modal.PrimaryButton onClick={onEditingConfirmed}>{t('progressStatement:actions.edit')}</Modal.PrimaryButton>
    </Modal>
  );
};
