import type { QuoteContactPaginatedDTO, QuoteContactsApi } from '@org/graneet-bff-client';
import type { Response } from '@graneet/lib-ui';
import { DEFAULT_PAGE, DEFAULT_SIZE, FILTERING_PARAMS, PAGINATION_PARAMS, safeParseInt } from '@graneet/business-logic';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteContactsProxyApi {
  private static quoteContactsProxyApi: QuoteContactsProxyApi;

  private readonly quoteContactsProxyApi: QuoteContactsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteContactsProxyApi = externalApi.getQuoteContactsApi();
  }

  public static getQuoteContactsProxyApi(externalApi: ExternalApi): QuoteContactsProxyApi {
    if (!this.quoteContactsProxyApi) {
      QuoteContactsProxyApi.quoteContactsProxyApi = new QuoteContactsProxyApi(externalApi);
    }
    return QuoteContactsProxyApi.quoteContactsProxyApi;
  }

  public async getAvailableContactsForQuote(
    queryParams: URLSearchParams | undefined,
  ): Promise<Response<QuoteContactPaginatedDTO>> {
    // TODO: enhance pagination
    const quoteId = queryParams?.get('quoteId')!;
    const clientId = safeParseInt(queryParams?.get('clientId')!, 10);

    const page = queryParams?.get(PAGINATION_PARAMS.PAGE);
    const size = queryParams?.get(PAGINATION_PARAMS.SIZE);
    const full = queryParams?.get(PAGINATION_PARAMS.FULL)
      ? queryParams?.get(PAGINATION_PARAMS.FULL) === 'true'
      : undefined;
    const order = queryParams?.get(FILTERING_PARAMS.ORDER);
    const direction = queryParams?.get(FILTERING_PARAMS.DIRECTION);
    const search = queryParams?.get(FILTERING_PARAMS.SEARCH);

    const filteredParams = {
      page: page ? safeParseInt(page, 10) : DEFAULT_PAGE,
      size: size ? safeParseInt(size, 10) : DEFAULT_SIZE,
      ...(full && { full }),
      ...(order && { order }),
      ...(direction && { direction }),
      ...(search && { search }),
    };

    return proxyHelper(() =>
      this.quoteContactsProxyApi.getAvailableContacts(
        quoteId,
        clientId,
        filteredParams.full,
        filteredParams.page,
        filteredParams.size,
        filteredParams.order,
        filteredParams.direction,
        filteredParams.search,
      ),
    );
  }
}
