import type { FlexProps } from '@chakra-ui/react';
import { VStack } from '@chakra-ui/react';
import type { ReactElement, ReactNode } from 'react';
import { forwardRef } from 'react';

import { SimpleCirclePlusIcon } from '../../Icons';
import { GraneetButton as Button } from '../../Quotation/Button/Button';

import { SelectableCardComponent } from './SelectableCardComponent';

export type AddItemCardProps = FlexProps & {
  icon?: ReactElement;
  title?: ReactNode;
  onClick?: () => void;
};

export const AddItemCard = forwardRef<HTMLInputElement, AddItemCardProps>(
  ({ icon = <SimpleCirclePlusIcon />, title, onClick, ...otherProps }, ref) => {
    if (!title) return null;

    return (
      <SelectableCardComponent {...otherProps} onClick={onClick} ref={ref}>
        <VStack justifyContent="center" boxSize="100%" gap={5}>
          <Button variant="ghost" leftIcon={icon}>
            {title}
          </Button>
        </VStack>
      </SelectableCardComponent>
    );
  },
);
