import { Box } from '@chakra-ui/react';
import type { FC } from 'react';

import { Badge } from '../Badge/Badge';

export interface BadgeJobProps {
  label: string;
  textColor: 'purple.700' | 'pink.700';
  backgroundColor: 'purple.100' | 'purple.200' | 'pink.100' | 'pink.200';
}

export const BadgeJob: FC<BadgeJobProps> = ({ backgroundColor, textColor, label }) => (
  <>
    <Badge
      position="absolute"
      padding={2}
      top={0}
      left={0}
      backgroundColor={backgroundColor}
      height="1rem"
      borderRadius={0}
      textColor={textColor}
      borderColor={backgroundColor}
      borderBottomRightRadius={3}
      fontSize="0.7rem"
    >
      {label}
    </Badge>
    <Box position="absolute" top={0} left={0} height="full" width={0.5} backgroundColor={backgroundColor} />
  </>
);
