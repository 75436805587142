import type { FC } from 'react';
import { useCallback } from 'react';
import { AlertMessage, Tabs } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { type EmailTemplateData, getEmailVariableData, HISTORY_ENTITY_TYPE, type IBill } from '@graneet/business-logic';

import { useBill, useBillClient } from '../services/bill.api';
import { useProjectOrUndefined } from '../../project/services/project.api';

import { BillStatusCard } from './BillStatusCard/BillStatusCard';
import { BillInformationBlock } from './BillInformationBlock';
import { BillCommentCard } from './BillCommentCard';
import { BillPaymentsTable } from './BillPaymentsTable/BillPaymentsTable';

import { useSendReminder } from 'features/reminder/services/reminder.api';
import { useContactAssociatedToProject } from 'features/contact/services/contact.api';
import { HistoryCard } from 'features/history/components/HistoryCard';
import { useEmailTemplateRichTextConfiguration } from 'features/email-template/hooks/useEmailTemplateRichTextConfiguration';
import { MailingModal, type MailingModalProps } from 'features/mailing/components/MailingModal/MailingModal';
import { getProjectDefaultEmailRecipient } from 'features/contact/services/contact.util';
import { getPublicLinkFromBill } from 'features/progress-statement/services/external-progress-statement.util';

const BillHistoryCard: FC<{ variableData: EmailTemplateData; bill: IBill }> = ({ variableData, bill }) => {
  const emailTemplateRichTextConfiguration = useEmailTemplateRichTextConfiguration(variableData);

  return (
    <HistoryCard
      id={bill.id}
      entity={HISTORY_ENTITY_TYPE.BILL}
      emailTemplateRichTextConfiguration={emailTemplateRichTextConfiguration}
    />
  );
};

interface BillDetailLayoutProps {
  id: number;
  onUpdate: () => void;
}

export const BillDetailLayout: FC<BillDetailLayoutProps> = ({ id, onUpdate }) => {
  const { t } = useTranslation(['bill', 'global', 'mailing']);

  const bill = useBill(id);
  const client = useBillClient(bill.data.id);
  const project = useProjectOrUndefined(bill.data?.project?.id);
  const projectContacts = useContactAssociatedToProject(bill.data?.project?.id);

  const sendReminderMutation = useSendReminder();

  const remindModal = useDisclosure();

  const onRemindSubmit = useCallback<MailingModalProps['onSubmit']>(
    async (data) => {
      await sendReminderMutation.mutateAsync(
        { dto: data.dto, files: data.attachments, billId: bill.data.id },
        {
          onSuccess: onUpdate,
        },
      );
      bill.refetch();
      return [null, null];
    },
    [bill, onUpdate, sendReminderMutation],
  );

  if (!client) {
    return null;
  }

  const variableData = getEmailVariableData('bill', {
    bill: bill.data,
    project: project.data,
    client,
    publicLink: getPublicLinkFromBill(bill.data),
  });

  return (
    <Flex direction="column" gap={6}>
      {bill.data.lastReminderInstance?.status === 'ASYNC_SEND_ERROR' && (
        <AlertMessage type="error">
          {t(`global:reminders.asyncErrorMessage`, {
            reason: t(
              `global:reminders.asyncErrorReason.${bill.data.lastReminderInstance?.asyncSendErrorReason ?? 'OTHER'}`,
            ),
          })}
        </AlertMessage>
      )}

      <BillStatusCard bill={bill.data} onRemind={remindModal.onOpen} onUpdate={onUpdate} />
      <BillCommentCard bill={bill.data} />

      <Box position="relative">
        <Tabs
          data={[
            {
              id: 'payments',
              title: t('bill:tabs.payments'),
              content: <BillPaymentsTable bill={bill.data} />,
            },
            {
              id: 'information',
              title: t('bill:tabs.information'),
              content: <BillInformationBlock bill={bill.data} client={client} />,
            },
            {
              id: 'history',
              title: t('bill:tabs.history'),
              content: <BillHistoryCard variableData={variableData} bill={bill.data} />,
            },
          ]}
        />
      </Box>

      <MailingModal
        title={t('mailing:modal.title.bill')}
        isOpen={remindModal.isOpen}
        onClose={remindModal.onClose}
        onSubmit={onRemindSubmit}
        variableData={variableData}
        defaultEmailTemplate={bill.data.reminder?.emailTemplate}
        defaultMailContacts={getProjectDefaultEmailRecipient(projectContacts.data ?? [])}
      />
    </Flex>
  );
};
