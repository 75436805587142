import { HStack, Box } from '@chakra-ui/react';
import { Badge, timeTableTranslations, divideFloating, roundFloating, multiplyFloating } from '@graneet/lib-ui';
import type { IWorker } from '@graneet/business-logic';

import { WorkerCard } from 'features/worker/components/WorkerCard';

const TEXT_PROPS = {
  width: '6rem',
};

interface CardWorkerProps {
  nbMinutes: number;
  worker: IWorker;
}

export const TimeTrackingCardWorker = ({ worker, nbMinutes }: CardWorkerProps) => (
  <HStack justifyContent="space-between" py={2} mx={3} width="13rem" fontSize="sm">
    <Box width="12rem">
      <WorkerCard worker={worker} textProps={TEXT_PROPS} />
    </Box>
    <Badge colorScheme="gray">
      {`${divideFloating(roundFloating(multiplyFloating(divideFloating(nbMinutes, 60), 100)), 100)}`.replace('.', ',')}
      {timeTableTranslations.hourLabel}
    </Badge>
  </HStack>
);
