import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const CogIcon: FC<IconProps> = ({ color = 'currentColor', ...otherProps }) => (
  <Icon viewBox="0 0 12 12" {...otherProps} fill={color}>
    <path
      fill={color as string}
      d="M6.00136 8.1C5.42878 8.1 4.87966 7.87875 4.47478 7.48492C4.06991 7.0911 3.84245 6.55695 3.84245 6C3.84245 5.44305 4.06991 4.9089 4.47478 4.51508C4.87966 4.12125 5.42878 3.9 6.00136 3.9C6.57394 3.9 7.12307 4.12125 7.52794 4.51508C7.93282 4.9089 8.16027 5.44305 8.16027 6C8.16027 6.55695 7.93282 7.0911 7.52794 7.48492C7.12307 7.87875 6.57394 8.1 6.00136 8.1ZM10.5844 6.582C10.6091 6.39 10.6276 6.198 10.6276 6C10.6276 5.802 10.6091 5.604 10.5844 5.4L11.8859 4.422C12.0031 4.332 12.034 4.17 11.96 4.038L10.7263 1.962C10.6523 1.83 10.4857 1.776 10.35 1.83L8.81412 2.43C8.49336 2.196 8.16027 1.992 7.77167 1.842L7.54344 0.252C7.51877 0.108 7.38923 0 7.23503 0H4.7677C4.61349 0 4.48396 0.108 4.45928 0.252L4.23105 1.842C3.84245 1.992 3.50936 2.196 3.18861 2.43L1.6527 1.83C1.51699 1.776 1.35045 1.83 1.27643 1.962L0.0427659 4.038C-0.0374223 4.17 -0.000412267 4.332 0.116786 4.422L1.4183 5.4C1.39363 5.604 1.37512 5.802 1.37512 6C1.37512 6.198 1.39363 6.39 1.4183 6.582L0.116786 7.578C-0.000412267 7.668 -0.0374223 7.83 0.0427659 7.962L1.27643 10.038C1.35045 10.17 1.51699 10.218 1.6527 10.17L3.18861 9.564C3.50936 9.804 3.84245 10.008 4.23105 10.158L4.45928 11.748C4.48396 11.892 4.61349 12 4.7677 12H7.23503C7.38923 12 7.51877 11.892 7.54344 11.748L7.77167 10.158C8.16027 10.002 8.49336 9.804 8.81412 9.564L10.35 10.17C10.4857 10.218 10.6523 10.17 10.7263 10.038L11.96 7.962C12.034 7.83 12.0031 7.668 11.8859 7.578L10.5844 6.582Z"
    />
  </Icon>
);
