import { useState } from 'react';
import { NavigationModal, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { number } from 'prop-types';

import { useImportLibraryComponentsIntoQuoteJob } from '../../hooks/useImportLibraryComponentsIntoQuoteJob';

import { ImportLibraryComponentListing } from 'features/library-component/components/ImportLibraryComponentListing';
import { useComponents } from 'features/quote-component/hooks/useComponents';
import { useComponentContext } from 'features/quote-component/contexts/component.context';

export const QuoteJobImportLibraryComponentView = ({ jobId }) => {
  const { t } = useTranslation(['library']);
  const toast = useToast();
  const history = useHistory();
  const { setComponents } = useComponentContext();
  const components = useComponents();

  const importLibraryComponentsInQuoteJob = useImportLibraryComponentsIntoQuoteJob();

  const [isImporting, setIsImporting] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleImporting = async () => {
    setIsImporting(true);

    const libraryComponentsIds = selectedRows.map((libraryComponent) => libraryComponent.id);

    const [err, quoteComponents] = await importLibraryComponentsInQuoteJob(jobId, libraryComponentsIds);
    setIsImporting(false);

    if (!err) {
      setComponents(quoteComponents);

      toast.success(
        t('library:importLibraryComponentToQuoteJobModal.successToast', { count: libraryComponentsIds.length }),
      );
      history.goBack();
    }
  };

  const libraryComponentsToHide = (components || [])
    .map((component) => component.libraryComponent?.id)
    .filter((id) => id);

  return (
    <>
      <Text mb={4}>{t('library:importLibraryComponentToQuoteJobModal.description')}</Text>

      <ImportLibraryComponentListing nonSelectableIds={libraryComponentsToHide} onRowSelected={setSelectedRows} />

      <NavigationModal.PrimaryButton
        isDisabled={selectedRows.length === 0}
        onClick={handleImporting}
        isLoading={isImporting}
      >
        {t('library:importLibraryComponentToQuoteJobModal.cta', { count: selectedRows.length })}
      </NavigationModal.PrimaryButton>
    </>
  );
};

QuoteJobImportLibraryComponentView.propTypes = {
  jobId: number.isRequired,
};
