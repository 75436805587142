import { useTranslation } from 'react-i18next';
import { useToast, Container } from '@graneet/lib-ui';
import { useCallback, useEffect, useMemo } from 'react';
import { Form, useForm } from 'graneet-form';

import type { UserMailsSettingsFormValues } from 'features/user/constants/user.constant';
import { useEditUserSettings, useUserSettings } from 'features/user/services/user.api';
import { UserMailsSettingsFields } from 'features/user/components/UserMailsSettingsFields';
import { UserSignatureSettings } from 'features/user/components/UserSignatureSettings';

export const MailsSettingsTab = () => {
  const { t } = useTranslation(['mailing', 'global']);
  const toast = useToast();

  const userSettings = useUserSettings();

  const editUserSettingsMutation = useEditUserSettings();

  const onUpdateAfterBlur = useCallback(
    (name: keyof UserMailsSettingsFormValues, value?: UserMailsSettingsFormValues[typeof name]) => {
      editUserSettingsMutation.mutate(
        { [name]: value },
        {
          onSuccess: () => {
            toast.success(t(`mailing:settings.configuration.toast.success.${name}`));
          },
        },
      );
    },
    [editUserSettingsMutation, t, toast],
  );

  const formOptions = useMemo(() => ({ onUpdateAfterBlur }), [onUpdateAfterBlur]);
  const userSettingsForm = useForm<UserMailsSettingsFormValues>(formOptions);
  const { setFormValues } = userSettingsForm;

  useEffect(() => {
    setFormValues({
      mailingDefaultSenderFullName: userSettings.data.mailingDefaultSenderFullName,
      mailingReceiveCopy: userSettings.data.mailingReceiveCopy,
      mailingSenderReplyTo: userSettings.data.mailingSenderReplyTo,
      mailingEmailSignatureJSON: userSettings.data.mailingEmailSignatureJSON,
    });
  }, [
    setFormValues,
    userSettings.data.mailingDefaultSenderFullName,
    userSettings.data.mailingEmailSignatureJSON,
    userSettings.data.mailingReceiveCopy,
    userSettings.data.mailingSenderReplyTo,
  ]);

  return (
    <Container size="sm">
      <Form form={userSettingsForm}>
        <UserMailsSettingsFields />
        <UserSignatureSettings />
      </Form>
    </Container>
  );
};
