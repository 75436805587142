import { createQuoteBasicItemAddedCallback } from './createQuoteBasicItemAddedCallback';
import { createQuoteBasicItemDeletedCallback } from './createQuoteBasicItemDeletedCallback';
import { createQuoteBasicItemUpdatedCallback } from './createQuoteBasicItemUpdatedCallback';
import { createQuoteBasicItemTransformCallback } from './createQuoteBasicItemTransformCallback';

import type { QuoteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';

export const quoteBasicItemCallbacks = (quoteId: String) => [
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteBasicItem:added',
    callback: (message: QuoteWsMessage) => createQuoteBasicItemAddedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteBasicItem:deleted',
    callback: (message: QuoteWsMessage) => createQuoteBasicItemDeletedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteBasicItem:updated',
    callback: (message: QuoteWsMessage) => createQuoteBasicItemUpdatedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteBasicItem:transformed',
    callback: (message: QuoteWsMessage & { oldType: string; newType: string }) =>
      createQuoteBasicItemTransformCallback(message),
  },
];
