import type { ICustomDiscount, IItem, ILot } from '@graneet/business-logic';
import type { FormulaFieldValue } from '@graneet/lib-ui';

export type LotKey = keyof Pick<ILot, 'code' | 'description' | 'note'>;
export type LotFieldName<T extends LotKey> = `lot_${string | number}_${T}`;
export const getLotFieldName = <T extends LotKey>(lotId: string | number, key: T): LotFieldName<T> =>
  `lot_${lotId}_${key}`;

export type ItemKey = keyof Pick<
  IItem,
  'note' | 'code' | 'description' | 'unit' | 'vatRate' | 'unitPrice' | 'quantity'
>;
export type ItemFieldName<T extends ItemKey> = `item_${string | number}_${T}`;
export const getItemFieldName = <T extends ItemKey>(itemId: string | number, key: T): ItemFieldName<T> =>
  `item_${itemId}_${key}`;

export interface ContractEditionForm {
  name: string;

  code: string | null;

  receptionDate: string;

  hasReversalOfLiability: boolean | null;

  hasDiscountBeenUpdated: boolean;

  discountAmountExVAT: number | null;

  customDiscounts: Omit<ICustomDiscount, 'createdAt' | 'updatedAt'>[];

  [itemNote: ItemFieldName<'note'>]: string | null | undefined;

  [itemCode: ItemFieldName<'code'>]: string | null | undefined;

  [itemDescription: ItemFieldName<'description'>]: string;

  [itemUnit: ItemFieldName<'unit'>]: string;

  [itemVatRate: ItemFieldName<'vatRate'>]: number;

  [itemUnitPrice: ItemFieldName<'unitPrice'>]: number;

  [itemQuantity: ItemFieldName<'quantity'>]: number | FormulaFieldValue;

  [lotCode: LotFieldName<'code'>]: string | null | undefined;

  [lotDescription: LotFieldName<'description'>]: string;

  [lotNote: LotFieldName<'note'>]: string | null | undefined;
}
