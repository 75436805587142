import type { IconProps } from '@chakra-ui/react';
import { Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import type { FC, ReactElement } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { groupBy } from 'lodash-es';

import { SettingsMenu } from '../SettingsMenu/SettingsMenu';
import { SimpleSettingsLinesIcon } from '../../Icons/v2/SimpleSettingsLinesIcon';

export type SummaryBarItem = {
  name: string;
  label: string;
  value: string;
  isDisplayed: boolean;
  category: string;
};

type SummaryBarProps = {
  items: SummaryBarItem[];
  icon?: ReactElement<IconProps>;
  onChange: (name: string, value: boolean) => void;
  tooltip?: string;
};

const DisplayedItems: FC<{ items: SummaryBarItem[] }> = ({ items }) => (
  <Grid
    templateColumns={`repeat(${items.length}, auto)`}
    gap="1rem"
    alignItems="center"
    width="100%"
    justifyContent="flex-end"
    overflow="hidden"
  >
    {items.map((item) => (
      <GridItem key={item.name}>
        <HStack spacing="0.25rem" height="28px" overflow="hidden">
          <Text color="#6C737F" fontSize="13px" fontWeight={500} style={{ textWrap: 'nowrap' }}>
            {item.label} :
          </Text>
          <Text color="#1F2A37" fontSize="13px" fontWeight={500} style={{ textWrap: 'nowrap' }}>
            {item.value}
          </Text>
        </HStack>
      </GridItem>
    ))}
  </Grid>
);

export const SummaryBar: FC<SummaryBarProps> = ({ items, icon, tooltip, onChange }) => {
  const [itemsDisplayed, setItemsDisplayed] = useState<SummaryBarItem[]>(items);

  const displayedItems = useMemo(() => itemsDisplayed.filter((item) => item.isDisplayed), [itemsDisplayed]);

  const menuItems = useMemo(() => {
    const grouped = groupBy(itemsDisplayed, 'category');
    return Object.keys(grouped).map((category) => ({
      label: category,
      items: grouped[category].map((item) => ({
        name: item.name,
        label: item.label,
        value: item.isDisplayed,
      })),
    }));
  }, [itemsDisplayed]);

  const handleChange = useCallback(
    (name: string, checked: boolean) => {
      onChange(name, checked);
      setItemsDisplayed((currentItems) =>
        currentItems.map((item) => {
          if (item.name === name) {
            return { ...item, isDisplayed: checked };
          }
          return item;
        }),
      );
    },
    [onChange],
  );

  useEffect(() => {
    setItemsDisplayed(items);
  }, [items]);

  return (
    <HStack spacing="1rem" alignItems="center" justifyContent="flex-end" height="28px" width="100%" mr="7px">
      <DisplayedItems items={displayedItems} />
      <SettingsMenu
        isEditable
        tooltip={tooltip}
        icon={icon ?? <SimpleSettingsLinesIcon />}
        placement="top-end"
        groups={menuItems}
        onChange={handleChange}
        size="sm"
      />
    </HStack>
  );
};
