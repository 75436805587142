import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, SingleSelect } from '@graneet/lib-ui';
import { HiddenField, useFormContext, useHiddenField } from 'graneet-form';
import { Stack } from '@chakra-ui/react';
import type { IProject, RequiredByKeys } from '@graneet/business-logic';

import type { EditSupplierInvoiceForm } from '../../../forms/edit-supplier-invoice.form';
import {
  buildProjectAmountExVATFieldName,
  buildProjectDistributionFieldName,
} from '../../../forms/edit-supplier-invoice.form';

import { SupplierInvoiceProjectsAssociationTable } from './SupplierInvoiceProjectsAssociationTable';
import { SupplierInvoiceProjectsAssociationCallout } from './SupplierInvoiceProjectsAssociationCallout';

import { useAssociableProjectsWithoutPagination } from 'features/project/services/project.api';
import { ProjectFieldOption } from 'features/project/components/ProjectField/ProjectFieldOption';
import { ProjectsAmountsProvider } from 'features/supplier-invoice/forms/RuleIsProjectsAmountsAmInferiorToAmountExVAT';

export const SupplierInvoiceProjectsAssociationCard: FC = () => {
  const { t } = useTranslation(['supplierInvoices', 'project']);

  const projects = useAssociableProjectsWithoutPagination();

  /*
    Store selected projects in the form
   */
  const form = useFormContext<EditSupplierInvoiceForm>();
  const associatedProjectHiddenField = useHiddenField(form, 'associatedProjects');
  /*
    When a project is selected in the autocomplete, we add the value to `associatedProjects` field
   */
  const onChange = useCallback(
    (
      prop: {
        value: RequiredByKeys<IProject, 'projectAccountManagers' | 'address' | 'paymentMethod' | 'primaryClient'>;
        label: ReactNode;
      } | null,
    ) => {
      const project = prop?.value;
      if (project) {
        // Initialize new field value (distribution and project amount ex VAT)
        const distributionFieldName = buildProjectDistributionFieldName(project);
        const projectAmountIncVAT = buildProjectAmountExVATFieldName(project);

        // If it's the first project added for association, project takes all supplier invoice amount
        if (associatedProjectHiddenField.value?.length === 0) {
          const { amountExVAT } = form.getFormValues();

          const newForm: Partial<EditSupplierInvoiceForm> = {};
          newForm[distributionFieldName] = 100;
          newForm[projectAmountIncVAT] = amountExVAT || 0;

          form.setFormValues(newForm);
          // Otherwise, initialize fields to 0
        } else {
          const newForm: Partial<EditSupplierInvoiceForm> = {};
          newForm[distributionFieldName] = 0;
          newForm[projectAmountIncVAT] = 0;
          form.setFormValues(newForm);
        }
        const newAssociatedProjectIds = [...associatedProjectHiddenField.value!, project];
        associatedProjectHiddenField.setValue(newAssociatedProjectIds);
      }
    },
    [associatedProjectHiddenField, form],
  );

  /*
    List of visibles projects in the autocomplete without selected project
   */
  const projectsWithoutSelected = useMemo(
    () =>
      (projects.data.data || [])
        .filter((project) => !(associatedProjectHiddenField.value || []).map(({ id }) => id).includes(project.id))
        .map((project) => ({
          label: <ProjectFieldOption project={project} />,
          searchTerm: `${project.name} ${project.refCode} ${project.primaryClient.enterpriseName} ${project.address.address} `,
          value: project,
        })),
    [associatedProjectHiddenField.value, projects],
  );

  return (
    <Card title={t('supplierInvoices:cards.projectCard.title')}>
      <ProjectsAmountsProvider>
        <HiddenField {...associatedProjectHiddenField} />

        <Stack spacing={6}>
          <SingleSelect<{
            value: RequiredByKeys<IProject, 'projectAccountManagers' | 'address' | 'paymentMethod' | 'primaryClient'>;
            label: ReactNode;
          }>
            key={projectsWithoutSelected.toString()} // Reset field on selection
            placeholder={t('project:projectField.placeholder')}
            options={projectsWithoutSelected}
            onChange={onChange}
            value={undefined}
          />

          <SupplierInvoiceProjectsAssociationTable />

          <SupplierInvoiceProjectsAssociationCallout />
        </Stack>
      </ProjectsAmountsProvider>
    </Card>
  );
};
