/* eslint-disable no-nested-ternary */
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ActionMenu,
  BadgeJob,
  CurrencyField,
  DeepTable,
  DraggableItem,
  DropEffect,
  FileNameList,
  formatVatRateToNumber,
  FormulaField,
  NumberField,
  PercentageField,
  PriceAdvanced,
  PuzzleIcon,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
  RichTextField,
  SegmentedDropZone,
  SimpleCirclePlusIcon,
  SimpleDeleteIcon,
  TextField,
  useCounterLoaderContext,
  useCurrency,
} from '@graneet/lib-ui';
import { Box, GridItem, IconButton, Text, useDisclosure, useTheme } from '@chakra-ui/react';
import { FEATURE_FLAGS, isQuoteJobComplete } from '@graneet/business-logic';

import { QUOTE_JOB_KEY } from '../../services/quote-job.util';
import { JobEditionModal } from '../JobEditionModal/JobEditionModal';
import { QuoteEditCodeRow } from '../../../quote/components/codeRow/QuoteEditCodeRow';
import { generateJobFieldName } from '../../../quote/forms/quote-edit-lot-step.form';

import { DeleteQuoteJobModal } from './DeleteQuoteJobModal';
import { QuoteJobEditActions } from './QuoteJobEditActions';

import { AddJobImagesModal } from 'features/file/components/AddJobImagesModal';
import { QUOTE_ITEM_TYPE } from 'features/quote/constants/quotes.constant';
import { useSendClickRoundedQuantityButtonEvent } from 'features/analytic/hooks/useSendClickRoundedQuantityButtonEvent';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';
import { useQuoteReversalOfLiability } from 'features/quote/hooks/useQuote';
import { QUOTE_INPUT_STYLE } from 'features/quote/constants/style.constant';
import { useJob } from 'features/quote-job/hooks/useJob';
import { useMoveJobAfterOtherJob } from 'features/quote-job/hooks/useMoveJobAfterOtherJob';
import { useMoveJobBeforeOtherJob } from 'features/quote-job/hooks/useMoveJobBeforeOtherJob';
import { useEditJob } from 'features/quote-job/hooks/useEditJob';
import { useDeleteJob } from 'features/quote-job/hooks/useDeleteJob';
import { useUploadJobFiles } from 'features/quote-job/hooks/useUploadJobFiles';
import { useDeleteJobFile } from 'features/quote-job/hooks/useDeleteJobFile';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

const { CODE, DESCRIPTION, UNIT, NOTE, TOTAL_MARGIN, QUANTITY, UNIT_DISBURSEMENT_EX_VAT, UNIT_PRICE_EX_VAT, VAT_RATE } =
  QUOTE_JOB_KEY;

const rightAlignedInputWithFocus = {
  ...QUOTE_INPUT_STYLE.RIGHT,
  ...QUOTE_INPUT_STYLE.FOCUS,
};
const rightAlignedBlurredInput = {
  ...QUOTE_INPUT_STYLE.RIGHT,
  ...QUOTE_INPUT_STYLE.BLURRED,
};
const noteInputWithFocus = {
  ...QUOTE_INPUT_STYLE.NOTE,
  ...QUOTE_INPUT_STYLE.FOCUS,
};
const noteBlurredInput = {
  ...QUOTE_INPUT_STYLE.NOTE,
  ...QUOTE_INPUT_STYLE.BLURRED,
};
const getStyleForInput = (hasFocus) => (hasFocus ? QUOTE_INPUT_STYLE.FOCUS : QUOTE_INPUT_STYLE.BLURRED);
const getStyleForNoteInput = (hasFocus) => (hasFocus ? noteInputWithFocus : noteBlurredInput);
const getStyleForRightAlignedInput = (hasFocus) => (hasFocus ? rightAlignedInputWithFocus : rightAlignedBlurredInput);

export const EditQuoteJobRow = memo(({ jobId, onAddJobAfter, depth }) => {
  const { t } = useTranslation(['quote']);
  const theme = useTheme();
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();
  const { startAnotherUpdate, isJobAfterOtherJob, isJobBeforeOtherJob, getJob } = useQuoteEditContext();
  const { displaySaleView, isReadOnlyView } = useQuoteDisplayContext();
  const job = useJob(jobId);
  const hasReversalOfLiability = useQuoteReversalOfLiability();
  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  const confirmDeleteModalControls = useDisclosure();

  const jobEditionModalControls = useDisclosure();
  const { onOpen: openEditionModal } = jobEditionModalControls;

  const addImagesModalControls = useDisclosure();
  const { onOpen: openAddImagesModal } = addImagesModalControls;

  const [hasFocus, setHasFocus] = useState(false);

  const moveJobAfterOtherJob = useMoveJobAfterOtherJob();
  const moveJobBeforeOtherJob = useMoveJobBeforeOtherJob();
  const editJob = useEditJob();
  const deleteJob = useDeleteJob();
  const uploadJobFiles = useUploadJobFiles();
  const deleteJobFile = useDeleteJobFile();

  const nameForKey = generateJobFieldName(jobId);
  const inputProps = getStyleForInput(hasFocus);
  const noteInputStyle = getStyleForNoteInput(hasFocus);
  const rightAlignedInputStyle = getStyleForRightAlignedInput(hasFocus);
  const borderColor = theme.colors.gray['200'];
  const borderColorLight = theme.colors.gray['100'];

  const mapAmountToNumberOrNull = useCallback(
    (amount) => (Number.isFinite(amount) ? mapAmountToNumber(amount) : null),
    [mapAmountToNumber],
  );
  const mapNumberToAmountOrNull = useCallback(
    (amount) => (Number.isFinite(amount) ? mapNumberToAmount(amount) : null),
    [mapNumberToAmount],
  );
  const formatVatRate = useCallback((v) => (Number.isFinite(v) ? formatVatRateToNumber(v) : null), []);

  const fieldData = useCallback((key) => ({ key, jobId }), [jobId]);
  const fieldDataWithMappingToNumber = useCallback(
    (key) => ({
      key,
      jobId,
      mapValue: mapAmountToNumberOrNull,
      mapFieldValue: mapNumberToAmountOrNull,
    }),
    [jobId, mapAmountToNumberOrNull, mapNumberToAmountOrNull],
  );
  const fieldDataWithMappingToVatRate = useMemo(
    () => ({ key: VAT_RATE, jobId, mapValue: formatVatRate }),
    [jobId, formatVatRate],
  );

  // Event handlers
  const handleOnFieldFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const handleOnFieldBlur = useCallback(() => {
    setHasFocus(false);
  }, []);

  const handleOnAddJobAfterThisOne = useCallback(() => {
    onAddJobAfter(jobId);
  }, [onAddJobAfter, jobId]);

  const handleOnDeleteJob = useCallback(async () => {
    startAnotherUpdate();
    await deleteJob(jobId);
  }, [deleteJob, jobId, startAnotherUpdate]);

  const handleUpdateNote = useCallback(
    (note) => async () => {
      startAnotherUpdate();
      await editJob(jobId, { note });
    },
    [editJob, jobId, startAnotherUpdate],
  );

  const handleAddImages = useCallback(
    async (files) => {
      await uploadJobFiles(jobId, files);
    },
    [jobId, uploadJobFiles],
  );

  const handleDeleteImage = useCallback(
    async (fileId) => {
      startAnotherUpdate();
      await deleteJobFile(jobId, fileId);
    },
    [deleteJobFile, jobId, startAnotherUpdate],
  );

  const canDropOnJobTop = useCallback(
    (jobItem) => {
      const jobToDrop = getJob(jobItem.id);
      const jobTop = getJob(jobId);

      if (jobTop.isHiddenCost && jobToDrop.isOptional) {
        return false;
      }

      // If the job to drop is hidden and the job on top is not, we can drop on top
      // This is for the case or you handle a first job of rootLot and you want to drop in top of hidden cost section
      if (jobTop.isHiddenCost !== jobToDrop.isHiddenCost) {
        return true;
      }

      return !isJobAfterOtherJob(jobId, jobItem.id);
    },
    [getJob, isJobAfterOtherJob, jobId],
  );

  const canDropOnJobBottom = useCallback(
    (jobItem) => {
      const jobToDrop = getJob(jobItem.id);
      const jobBottom = getJob(jobId);

      if (jobBottom.isHiddenCost && jobToDrop.isOptional) {
        return false;
      }

      // If the job to drop is hidden and the job on top is not, we can drop on top
      // This is for the case or you handle a first job of rootLot and you want to drop in top of hidden cost section
      if (jobBottom.isHiddenCost !== jobToDrop.isHiddenCost) {
        return true;
      }

      return !isJobBeforeOtherJob(jobId, jobItem.id);
    },
    [getJob, isJobBeforeOtherJob, jobId],
  );

  const handleJobTopDrop = useCallback(
    (isHiddenCost) => (jobItem) => moveJobBeforeOtherJob(jobItem.id, jobId, isHiddenCost),
    [moveJobBeforeOtherJob, jobId],
  );

  const handleJobBottomDrop = useCallback(
    (isHiddenCost) => (jobItem) => moveJobAfterOtherJob(jobItem.id, jobId, isHiddenCost),
    [moveJobAfterOtherJob, jobId],
  );

  const isJobCompleted = useMemo(() => {
    if (!job) {
      return undefined;
    }
    return isQuoteJobComplete(job);
  }, [job]);

  const jobFiles = useMemo(() => (job?.quoteJobFile || []).map(({ file }) => file), [job]);

  const { addLineRendered } = useCounterLoaderContext();
  useEffect(() => {
    addLineRendered(`JOB_${jobId}`);
  }, [addLineRendered, jobId]);

  const sendClickRoundEvent = useSendClickRoundedQuantityButtonEvent();

  if (!job) {
    return null;
  }

  const hasComponents = job.components.length > 0;
  const isUnitPriceFieldEnabled = job.unitDisbursementExVAT === 0;
  const hasNote = job.note !== null;

  return (
    <DraggableItem
      type={QUOTE_ITEM_TYPE.JOB}
      item={{ id: jobId }}
      disabled={isReadOnlyView}
      position="relative"
      key={jobId}
    >
      {job.isOptional ? (
        <BadgeJob backgroundColor="purple.100" textColor="purple.700" label={t('quote:option.title')} />
      ) : null}
      {job.isHiddenCost ? (
        <BadgeJob backgroundColor="pink.100" textColor="pink.700" label={t('quote:hiddenCost.title')} />
      ) : null}
      <SegmentedDropZone id={jobId} accept={QUOTE_ITEM_TYPE.JOB}>
        <SegmentedDropZone.Segment
          weight={1}
          effect={DropEffect.CursorTop}
          canDrop={canDropOnJobTop}
          onDrop={handleJobTopDrop(job.isHiddenCost)}
        />

        <SegmentedDropZone.Segment
          weight={1}
          effect={DropEffect.CursorBottom}
          canDrop={canDropOnJobBottom}
          onDrop={handleJobBottomDrop(job.isHiddenCost)}
        />

        <DeepTable.Row
          offset={depth * 0.5}
          backgroundColor={isJobCompleted ? 'white' : 'yellow.100'}
          borderColor={hasNote ? borderColorLight : borderColor}
          borderLeft={
            isJobCompleted ? `2px solid ${theme.colors.gray['200']}` : `2px solid ${theme.colors.orange['300']}`
          }
          leftContent={
            !isReadOnlyView && (
              <DraggableItem.Handle
                marginTop={job.isOptional || job.isHiddenCost ? 3 : 0}
                color={isJobCompleted ? 'gray.200' : 'orange.300'}
                _hover={{ color: isJobCompleted ? 'gray.500' : 'orange.500' }}
              />
            )
          }
        >
          <DeepTable.Cell>
            {!job.isHiddenCost && (
              <QuoteEditCodeRow
                id={jobId}
                type="job"
                name={nameForKey(CODE)}
                data={fieldData(CODE)}
                onBlur={handleOnFieldBlur}
                onFocus={handleOnFieldFocus}
                inputProps={inputProps}
                noBorder
              />
            )}
          </DeepTable.Cell>
          <DeepTable.Cell>
            <RichTextField
              noBorder
              name={nameForKey(DESCRIPTION)}
              data={fieldData(DESCRIPTION)}
              onBlur={handleOnFieldBlur}
              onFocus={handleOnFieldFocus}
              inputProps={inputProps}
              isDisabled={isReadOnlyView}
              navbarType="inline"
              configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
            />
          </DeepTable.Cell>
          <DeepTable.Cell right>
            <TextField
              name={nameForKey(UNIT)}
              data={fieldData(UNIT)}
              onBlur={handleOnFieldBlur}
              onFocus={handleOnFieldFocus}
              inputProps={rightAlignedInputStyle}
              isReadOnly={isReadOnlyView}
              noBorder
            />
          </DeepTable.Cell>
          <DeepTable.Cell right>
            <FormulaField
              name={nameForKey(QUANTITY)}
              data={fieldData(QUANTITY)}
              onBlur={handleOnFieldBlur}
              onFocus={handleOnFieldFocus}
              inputProps={rightAlignedInputStyle}
              isReadOnly={isReadOnlyView}
              sendClickRoundEvent={sendClickRoundEvent}
              noBorder
            />
          </DeepTable.Cell>

          {displaySaleView ? (
            <>
              <DeepTable.Cell key={nameForKey(TOTAL_MARGIN)} right>
                {job.isHiddenCost ? (
                  '-'
                ) : (
                  <NumberField
                    name={nameForKey(TOTAL_MARGIN)}
                    data={fieldData(TOTAL_MARGIN)}
                    onFocus={handleOnFieldFocus}
                    inputProps={rightAlignedInputStyle}
                    onBlur={handleOnFieldBlur}
                    min={0}
                    scale={3}
                    isReadOnly={isReadOnlyView}
                    noBorder
                  />
                )}
              </DeepTable.Cell>

              <DeepTable.Cell key={`${nameForKey(UNIT_PRICE_EX_VAT)}`} right>
                {job.isHiddenCost ? (
                  '-'
                ) : isUnitPriceFieldEnabled ? (
                  <PriceAdvanced amount={job.unitPriceExVAT} fontSize="md" />
                ) : (
                  <CurrencyField
                    name={nameForKey(UNIT_PRICE_EX_VAT)}
                    onFocus={handleOnFieldFocus}
                    inputProps={rightAlignedInputStyle}
                    data={fieldDataWithMappingToNumber(UNIT_PRICE_EX_VAT)}
                    isReadOnly={isReadOnlyView}
                    noBorder
                  />
                )}
              </DeepTable.Cell>

              <DeepTable.Cell key={nameForKey(VAT_RATE)} right>
                {hasReversalOfLiability || job.isHiddenCost ? (
                  <Text pl={8}>-</Text>
                ) : (
                  <Box ml={6}>
                    {hasStandardVATRates ? (
                      <AccountingVATField
                        name={nameForKey(VAT_RATE)}
                        data={fieldDataWithMappingToVatRate}
                        isReadOnly={isReadOnlyView}
                        valueScale={100}
                      />
                    ) : (
                      <PercentageField
                        name={nameForKey(VAT_RATE)}
                        data={fieldDataWithMappingToVatRate}
                        onFocus={handleOnFieldFocus}
                        onBlur={handleOnFieldBlur}
                        inputProps={rightAlignedInputStyle}
                        min={0}
                        isReadOnly={isReadOnlyView}
                        noBorder
                      />
                    )}
                  </Box>
                )}
              </DeepTable.Cell>
              <DeepTable.Cell right>
                {job.isHiddenCost ? (
                  '-'
                ) : (
                  <PriceAdvanced key={`${jobId}-amountExVAT`} amount={job.amountExVAT} fontSize="md" right />
                )}
              </DeepTable.Cell>
            </>
          ) : (
            <>
              <DeepTable.Cell key={`${nameForKey(UNIT_DISBURSEMENT_EX_VAT)}-field`} right>
                {hasComponents ? (
                  <PriceAdvanced amount={job.unitDisbursementExVAT} fontSize="md" />
                ) : (
                  <CurrencyField
                    name={nameForKey(UNIT_DISBURSEMENT_EX_VAT)}
                    data={fieldDataWithMappingToNumber(UNIT_DISBURSEMENT_EX_VAT)}
                    onBlur={handleOnFieldBlur}
                    onFocus={handleOnFieldFocus}
                    inputProps={rightAlignedInputStyle}
                    isReadOnly={isReadOnlyView}
                    noBorder
                  />
                )}
              </DeepTable.Cell>
              <DeepTable.Cell key={`${jobId}-disbursement`} right>
                <PriceAdvanced amount={job.disbursementExVAT} fontSize="md" />
              </DeepTable.Cell>
            </>
          )}

          <DeepTable.Cell right>
            {hasComponents && <PuzzleIcon boxSize={8} mr={2} />}
            {!isReadOnlyView && (
              <IconButton
                variant="clear"
                icon={<SimpleCirclePlusIcon fontSize={22} stroke="gray.300" _hover={{ stroke: 'greenBrand.light' }} />}
                onClick={handleOnAddJobAfterThisOne}
                mr={2}
                h={7}
              />
            )}

            <QuoteJobEditActions
              job={job}
              onEdit={openEditionModal}
              onAddImages={openAddImagesModal}
              handleUpdateNote={handleUpdateNote}
              onDelete={confirmDeleteModalControls.onOpen}
            />

            <DeleteQuoteJobModal modalControls={confirmDeleteModalControls} onSubmit={handleOnDeleteJob} />

            <JobEditionModal modalControls={jobEditionModalControls} jobId={jobId} />

            <AddJobImagesModal
              isOpen={addImagesModalControls.isOpen}
              onClose={addImagesModalControls.onClose}
              handleAddImages={handleAddImages}
              handleDeleteImage={handleDeleteImage}
              onBlur={handleOnFieldBlur}
              images={jobFiles}
            />
          </DeepTable.Cell>
        </DeepTable.Row>

        {!!jobFiles.length > 0 && (
          <DeepTable.Row offset={depth * 0.5} backgroundColor="gray.50" borderColor={borderColor} noLeftContent>
            <GridItem colSpan={displaySaleView ? 10 : 8}>
              <DeepTable.Cell pt={1} minHeight={0}>
                <Box>
                  <FileNameList
                    files={jobFiles}
                    onFileDelete={handleDeleteImage}
                    openCarousel
                    readOnly={isReadOnlyView}
                  />
                </Box>
              </DeepTable.Cell>
            </GridItem>
          </DeepTable.Row>
        )}

        {job.note !== null && (
          <DeepTable.Row offset={depth * 0.5} backgroundColor="white" borderColor={borderColor}>
            <GridItem colSpan={displaySaleView ? 8 : 6}>
              <DeepTable.Cell>
                <RichTextField
                  noBorder
                  variant="light"
                  name={nameForKey(NOTE)}
                  data={fieldData(NOTE)}
                  onBlur={handleOnFieldBlur}
                  onFocus={handleOnFieldFocus}
                  inputProps={noteInputStyle}
                  isDisabled={isReadOnlyView}
                  navbarType="inline"
                  configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
                />
              </DeepTable.Cell>
            </GridItem>

            {!isReadOnlyView && (
              <DeepTable.Cell right alignItems="flex-start">
                <ActionMenu closeOnBlur closeOnSelect>
                  <ActionMenu.Action
                    icon={<SimpleDeleteIcon />}
                    label={t('quote:jobsStep.deleteNote')}
                    onClick={handleUpdateNote(null)}
                    warning
                  />
                </ActionMenu>
              </DeepTable.Cell>
            )}
          </DeepTable.Row>
        )}
      </SegmentedDropZone>
    </DraggableItem>
  );
});

EditQuoteJobRow.propTypes = {
  jobId: number.isRequired,
  onAddJobAfter: func.isRequired,
  depth: number.isRequired,
};
