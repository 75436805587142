import { type FieldValue, Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IReminder } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback } from 'react';

interface RuleUniqDayAfterDeadlineProps {
  reminders: IReminder[];

  isPositive: boolean;
}
export const RuleUniqDayAfterDeadline: FC<RuleUniqDayAfterDeadlineProps> = ({ reminders, isPositive }) => {
  const { t } = useTranslation(['global']);

  const validationFn = useCallback(
    (value: FieldValue) => {
      if (typeof value === 'number') {
        return !reminders.find((reminder) => reminder.daysAfterDeadline === (isPositive ? value : -value));
      }

      return false;
    },
    [isPositive, reminders],
  );

  return <Rule message={t('global:reminders.invalidDayAfterDeadline')} validationFn={validationFn} />;
};
