import { flatMap } from 'lodash-es';
import type { EmailAutocompleteContact } from '@graneet/lib-ui';
import type { IContactWithProjectInfos, IGroupedContact } from '@graneet/business-logic';

export function getProjectDefaultEmailRecipient(
  projectContacts: IGroupedContact<IContactWithProjectInfos>[],
): EmailAutocompleteContact[] {
  return flatMap(projectContacts.map((groupedContacts) => groupedContacts.contacts))
    .filter((projectContact) => projectContact.isProjectDefaultEmailRecipient && projectContact.email)
    .map((projectContact) => ({
      id: projectContact.id,
      firstName: projectContact.firstName,
      lastName: projectContact.lastName,
      email: projectContact.email!,
      role: projectContact.role ?? undefined,
    }));
}
