import type { FC } from 'react';
import { useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Wizard } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { InviteUserStep } from './_steps/InviteUserStep';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useCreateUserInvitation } from 'features/onboarding/services/user-invitation.api';
import type { UserInvitationWizardValues } from 'features/onboarding/constants/user-invitation.constant';

export const InviteUserScreen: FC = () => {
  useHideNavbar();

  const { t } = useTranslation(['global', 'onboarding']);
  const history = useHistory();

  const modal = useDisclosure();
  const { onClose } = modal;

  const wizardLabels = useWizardLabels({
    save: t('global:words.c.validate'),
  });

  const goBackToUsers = useCallback(() => {
    history.push('/settings/company/users');
  }, [history]);

  const createUserInvitationMutation = useCreateUserInvitation();

  const submitForm = useCallback(
    async (wizardFormValues: UserInvitationWizardValues) => {
      const { role: roleId, ...userInformation } = wizardFormValues['invite-user'];

      await createUserInvitationMutation.mutateAsync(
        {
          ...userInformation,
          roleId,
        },
        {
          onSuccess: () => {
            onClose();
            goBackToUsers();
          },
        },
      );
    },
    [createUserInvitationMutation, onClose, goBackToUsers],
  );

  return (
    <>
      <Wizard<UserInvitationWizardValues>
        headerTitle={t('onboarding:invitation.wizard.titleInviteUser')}
        onQuit={goBackToUsers}
        onFinish={submitForm}
        labels={wizardLabels}
      >
        <Wizard.Step<UserInvitationWizardValues> name="invite-user">
          <QueryWrapper>
            <InviteUserStep />
          </QueryWrapper>
        </Wizard.Step>
      </Wizard>
    </>
  );
};
