import { useDisclosure } from '@chakra-ui/react';
import type { IQuoteJob } from '@graneet/business-logic';
import {
  ActionMenu,
  SimpleAddImageIcon,
  SimpleAddNoteIcon,
  SimpleHiddenCostIcon,
  SimpleOptionIcon,
  SimpleViewIcon,
} from '@graneet/lib-ui';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDuplicateJob } from '../../hooks/useDuplicateJob';
import { useSetQuoteJobOptional } from '../../hooks/useSetQuoteJobOptional';
import { useMoveJobInsideLot } from '../../hooks/useMoveJobInsideLot';
import { useSetQuoteJobHiddenCost } from '../../hooks/useSetQuoteJobHiddenCost';

import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

interface QuoteJobEditActionsProps {
  job: IQuoteJob;
  onEdit(): void;
  onDelete(): void;
  onAddImages(): void;
  handleUpdateNote(note: string): () => Promise<void>;
}

export const QuoteJobEditActions: FC<QuoteJobEditActionsProps> = memo(
  ({ job, onEdit, onDelete, onAddImages, handleUpdateNote }) => {
    const { t } = useTranslation(['quote']);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const { startAnotherUpdate, getRootLotId } = useQuoteEditContext();
    const { isReadOnlyView } = useQuoteDisplayContext();
    const duplicateJob = useDuplicateJob();
    const setJobOptional = useSetQuoteJobOptional(job);
    const moveJobInsideLot = useMoveJobInsideLot();
    const rootLotId = getRootLotId();
    const setQuoteJobHiddenCost = useSetQuoteJobHiddenCost();

    const actionControls = useDisclosure();

    const handleHiddenCost = useCallback(async () => {
      if (!rootLotId) {
        throw new Error('Root lot id is not defined');
      }

      if (job.lot && job.lot.id !== rootLotId) {
        moveJobInsideLot(job.id, rootLotId, !job.isHiddenCost);
        return;
      }

      setQuoteJobHiddenCost(job);
    }, [job, moveJobInsideLot, rootLotId, setQuoteJobHiddenCost]);

    const jobId = job.id;
    const handleOnDuplicateJob = useCallback(async () => {
      startAnotherUpdate();
      await duplicateJob(jobId);
    }, [duplicateJob, jobId, startAnotherUpdate]);

    const hasNote = job.note !== null;

    useEffect(
      () => () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      },
      [],
    );

    if (isReadOnlyView)
      return (
        <ActionMenu {...actionControls}>
          <ActionMenu.Action icon={<SimpleViewIcon />} label={t('quote:jobsStep.viewJobDrawer')} onClick={onEdit} />
        </ActionMenu>
      );

    return (
      <ActionMenu {...actionControls} closeOnBlur closeOnSelect>
        <ActionMenu.Edit onClick={onEdit} />
        {!hasNote && (
          <ActionMenu.Action
            icon={<SimpleAddNoteIcon />}
            label={t('quote:jobsStep.addNote')}
            onClick={handleUpdateNote('')}
          />
        )}
        <ActionMenu.Action icon={<SimpleAddImageIcon />} label={t('quote:jobsStep.addImages')} onClick={onAddImages} />

        <ActionMenu.Action
          isDisabled={job.isHiddenCost}
          icon={<SimpleOptionIcon />}
          label={job.isOptional ? t('quote:option.removeOption') : t('quote:option.addOption')}
          onClick={setJobOptional}
        />
        <ActionMenu.Action
          isDisabled={job.isOptional}
          icon={<SimpleHiddenCostIcon />}
          label={job.isHiddenCost ? t('quote:hiddenCost.removeHiddenCost') : t('quote:hiddenCost.addHiddenCost')}
          onClick={handleHiddenCost}
        />
        <ActionMenu.Duplicate onClick={handleOnDuplicateJob} />
        <ActionMenu.Delete onClick={onDelete} />
      </ActionMenu>
    );
  },
);
