import type { FC, ReactNode } from 'react';
import type { IUser } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Flex, Grid, Text } from '@chakra-ui/react';

import { UserAvatars } from 'features/user/components/UserAvatars';
import { formatUserDisplayName } from 'features/user/services/user.util';

const CARD_HEIGHT = '3.5rem';
const TEMPLATE_COLUMNS = '4rem 1.5fr 1fr 3rem';

interface ValidationStepCardProps {
  index: number;
  isLast: boolean;
  renderCondition: ReactNode;
  renderActions: ReactNode;
  validators: IUser[];
  areAccountManagersIncluded: boolean;
}

export const ValidationStepCard: FC<ValidationStepCardProps> = ({
  index,
  isLast,
  renderCondition,
  renderActions,
  validators,
  areAccountManagersIncluded,
}) => {
  const { t } = useTranslation(['validationStep']);

  return (
    <>
      <Box borderWidth={1} borderColor="gray.150" p={4} borderRadius={4} shadow="sm" minH={CARD_HEIGHT}>
        <Grid gap={3} templateColumns={TEMPLATE_COLUMNS}>
          <Flex alignItems="center" justifyContent="center">
            <Text
              textColor="gray.600"
              fontSize="sm"
              color="white"
              rounded="full"
              h={8}
              w={8}
              backgroundColor="greenBrand.light"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {index + 1}
            </Text>
          </Flex>
          <Flex alignItems="center" gap={1}>
            {renderCondition}
          </Flex>
          <Flex alignItems="start" flexDirection="column" gap={2}>
            {validators.length > 0 && (
              <Flex alignItems="center" gap={2}>
                <UserAvatars users={validators} />
                <Text noOfLines={1}>{validators.map((user) => formatUserDisplayName(user)).join(', ')}</Text>
              </Flex>
            )}
            {areAccountManagersIncluded && (
              <Flex alignItems="center" gap={2}>
                <Avatar size="sm" backgroundColor="gray.300" />
                <Text noOfLines={1}>{t('validationStep:projectManager')}</Text>
              </Flex>
            )}
          </Flex>

          <Flex alignItems="center">{renderActions}</Flex>
        </Grid>
      </Box>
      {!isLast && (
        <Box px={4}>
          <Grid gap={3} templateColumns={TEMPLATE_COLUMNS}>
            <Flex alignItems="center" justifyContent="center">
              <Box borderLeftWidth={2} borderColor="gray.150" pb={6} />
            </Flex>
          </Grid>
        </Box>
      )}
    </>
  );
};
