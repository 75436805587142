import type { FC } from 'react';
import { useCallback } from 'react';
import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { STATEMENT_TYPES } from '@graneet/business-logic';

import { isInvoiceNumberNotAvailable } from '../services/statement.util';

interface RuleIsInvoiceNumberAvailableProps {
  statementId?: number;

  type: STATEMENT_TYPES;
}

export const RuleIsInvoiceNumberAvailable: FC<RuleIsInvoiceNumberAvailableProps> = ({ statementId, type }) => {
  const { t } = useTranslation(['statement']);

  const testInvoiceNumber = useCallback(
    async (value: string) => {
      if (!value || value.length === 0) {
        return true;
      }
      return !(await isInvoiceNumberNotAvailable(value, statementId, type));
    },
    [statementId, type],
  );

  return <Rule message={t('statement:errors.invoiceNumberUsed')} validationFn={testInvoiceNumber} isDebounced />;
};
