import { useCallback, useEffect, useState } from 'react';
import { GraneetButton, useHotkeys } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { GridApi, IRowNode } from '@ag-grid-community/core';
import type { QuoteNodeObject } from '@org/quotation-lib';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import {
  appendQuoteExpandedRow,
  removeQuoteCollapsedRow,
  updateQuoteAreAllRowsCollapsed,
  updateQuoteSetting,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { QUOTATION_SHORTCUTS } from 'features/quotation/quote-hot-key/hooks/useQuoteHotKeys';
import { useStore } from 'store/store';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';

export const QuoteToolBarToggleComponentsLinesButton = ({ api }: { api: GridApi<QuoteNodeObject> }) => {
  const { quote } = useQuote();
  const { t } = useTranslation(['quote']);

  const quoteSetting = useStore((state) => state.quoteSetting);
  const { quoteSettingProxyApi } = useQuotationProxyApis();

  const setIconStatus = useCallback((): boolean => {
    const isNodeAQuoteSubItemWithUnexpandedChildren = (node: IRowNode<QuoteNodeObject>) =>
      node.data?.content.type === 'QuoteSubItem' && !node.expanded && (node.data?.childrenId?.length ?? 0) > 0;

    const isNodeAQuoteItemWithUnexpandedComponentChildren = (node: IRowNode<QuoteNodeObject>) => {
      const isQuoteItem = ['QuoteBasicItem', 'QuoteOptionalItem'].includes(node.data?.content.type ?? '');
      const hasUnexpandedChildren = !node.expanded && (node.data?.childrenId?.length ?? 0) > 0;
      if (isQuoteItem && hasUnexpandedChildren && node.data?.id) {
        const quoteNode = quote.getTree().getNodeOrNull(node.data.id);
        return quoteNode?.getFirstChild()?.getContent().getType() === 'QuoteComponent';
      }
      return false;
    };

    let allComponentsOpen = true;

    api.forEachNode((node) => {
      if (isNodeAQuoteSubItemWithUnexpandedChildren(node) || isNodeAQuoteItemWithUnexpandedComponentChildren(node)) {
        allComponentsOpen = false;
      }
    });
    return allComponentsOpen;
  }, [api, quote]);

  const setRowsApi = useCallback(
    (isComponentsExpanded: boolean) => {
      api.forEachNode((node) => {
        if (['QuoteBasicItem', 'QuoteOptionalItem', 'QuoteSubItem'].includes(node.data?.content.type ?? '')) {
          if ((node.data?.childrenId?.length ?? 0) > 0 && node.data && node.data.id) {
            const quoteNode = quote.getTree().getNodeOrNull(node.data.id);
            if (quoteNode && quoteNode.getFirstChild()?.getContent().getType() === 'QuoteComponent') {
              api.setRowNodeExpanded(node, isComponentsExpanded, false);
              if (node.key) {
                if (isComponentsExpanded && !node.expanded) {
                  appendQuoteExpandedRow(node.key);
                  removeQuoteCollapsedRow(node.key);
                }
              }
            }
          }
        }
      });
    },
    [api, quote],
  );

  const [areAllOpen, setAreAllOpen] = useState(setIconStatus());

  useEffect(() => {
    setIconStatus();
    setRowsApi(quoteSetting.isComponentsExpanded);
    setAreAllOpen(quoteSetting.isComponentsExpanded);
  }, [quoteSetting.isComponentsExpanded, setIconStatus, setRowsApi]);

  const handleExpandAll = () => {
    updateQuoteAreAllRowsCollapsed(false);
    setRowsApi(true);
    const status = setIconStatus();
    setAreAllOpen(status);
    updateQuoteSetting({ ...quoteSetting, isComponentsExpanded: true });
    quoteSettingProxyApi.updateQuoteSetting(quote.getId(), { isComponentsExpanded: true });
  };

  const handleCollapseAll = () => {
    setRowsApi(false);
    const status = setIconStatus();
    setAreAllOpen(status);
    updateQuoteSetting({ ...quoteSetting, isComponentsExpanded: false });
    quoteSettingProxyApi.updateQuoteSetting(quote.getId(), { isComponentsExpanded: false });
  };

  useHotkeys(
    [QUOTATION_SHORTCUTS.COLLAPSE_ALL_COMPONENTS.shortCut],
    () => {
      handleCollapseAll();
    },
    [handleCollapseAll],
  );

  useHotkeys(
    [QUOTATION_SHORTCUTS.EXPAND_ALL_COMPONENTS.shortCut],
    () => {
      handleExpandAll();
    },
    [handleExpandAll],
  );

  return (
    <GraneetButton
      leftIcon={<i className={areAllOpen ? 'ri-box-1-line' : 'ri-box-2-line'} />}
      variant="ghost"
      onClick={areAllOpen ? handleCollapseAll : handleExpandAll}
    >
      {t(
        areAllOpen ? 'quote:quotation.tooltips.collapseAllComponents' : 'quote:quotation.tooltips.expandAllComponents',
      )}
    </GraneetButton>
  );
};
