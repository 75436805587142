import { useCallback } from 'react';

import * as lotsApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useStore } from 'store/store';

export const useMoveLotAfterOtherLot = () => {
  const { showJobMoveToast, moveLotAfterOtherLotLocally, updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (lotId: number, otherLotId: number | undefined, newParentLotId: number | undefined = undefined) => {
      let moveResult;

      try {
        moveResult = moveLotAfterOtherLotLocally(lotId, otherLotId, newParentLotId);
      } catch (err) {
        showJobMoveToast({ isError: true });
        return;
      }

      const { parentLotId, previousLotId } = moveResult;
      const isParentOptional = newParentLotId
        ? useStore.getState().optionalLotsTable[newParentLotId?.toString()] || false
        : false;
      const [err, quoteInfos] = await lotsApi.moveLot(lotId, { parentLotId, previousLotId, isParentOptional });

      if (err) {
        showJobMoveToast({ isError: true });
        return;
      }

      updateDataLocally(quoteInfos);
      showJobMoveToast({ isError: false });
    },
    [moveLotAfterOtherLotLocally, showJobMoveToast, updateDataLocally],
  );
};
