import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Input, Modal } from '@graneet/lib-ui';
import type {
  IProject,
  ISubProjectByProjectListingResponseDTO,
  ISubProjectWithInformation,
} from '@graneet/business-logic';
import { Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { getDownPaymentCreationState } from '../../services/down-payment.util';

import { filterOnlyContaining } from 'features/common/services/common.util';
import { SubProjectSelectionTable } from 'features/sub-project/components/SubProjectSelectionTable';

interface DownPaymentCreateOnSubProjectModalProps {
  onClose(): void;

  isOpen: boolean;

  subProjects: ISubProjectByProjectListingResponseDTO;

  project: IProject;
}

export const DownPaymentCreateOnSubProjectModal: FC<DownPaymentCreateOnSubProjectModalProps> = ({
  onClose,
  isOpen,
  subProjects,
  project,
}) => {
  const { t } = useTranslation(['global', 'downPayment'] as const);
  const history = useHistory();

  const [search, setSearch] = useState('');
  const filteredSubProjects = useMemo(() => {
    if (search === '' || search === null) {
      return subProjects;
    }

    return filterOnlyContaining(subProjects, ['name'], search);
  }, [search, subProjects]);

  const [selectedSubProject, setSelectedSubProject] = useState<ISubProjectWithInformation | null>(null);

  const onSubProjectSelect = useCallback((newSubProject: ISubProjectWithInformation | null) => {
    setSelectedSubProject(newSubProject);
  }, []);

  const getRowState = useCallback(
    (subProject: ISubProjectWithInformation) => {
      const { canCreate, tooltip } = getDownPaymentCreationState(subProject, t, true);

      return { isDisabled: !canCreate, tooltipMessage: tooltip };
    },
    [t],
  );

  const onClick = useCallback(() => {
    if (selectedSubProject) {
      history.push(`/projects/${project.id}/statements/sub-projects/${selectedSubProject.id}/down-payments/edit`);
    }
  }, [history, project.id, selectedSubProject]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        title={t('downPayment:modalCreateDownPayment.title')}
        size="3xl"
      >
        <Box mb={2}>
          <Input
            w={['100%', '100%', '20rem']}
            placeholder={t('global:words.c.research')}
            onChange={setSearch}
            value={search}
          />
        </Box>

        <SubProjectSelectionTable
          subProjects={filteredSubProjects}
          onSubProjectSelect={onSubProjectSelect}
          getRowState={getRowState}
        />

        <Modal.Close />

        <Modal.PrimaryButton onClick={onClick} isDisabled={!selectedSubProject}>
          {t('downPayment:modalCreateDownPayment.cta')}
        </Modal.PrimaryButton>
      </Modal>
    </>
  );
};
