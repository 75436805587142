import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

export const MinusCercle: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3923_4183)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.0013 0.666504C3.95122 0.666504 0.667969 3.94975 0.667969 7.99984C0.667969 12.0499 3.95122 15.3332 8.0013 15.3332C12.0514 15.3332 15.3346 12.0499 15.3346 7.99984C15.3346 3.94975 12.0514 0.666504 8.0013 0.666504ZM5.33464 7.33317C4.96645 7.33317 4.66797 7.63164 4.66797 7.99984C4.66797 8.36804 4.96645 8.6665 5.33464 8.6665H10.668C11.0362 8.6665 11.3346 8.36804 11.3346 7.99984C11.3346 7.63164 11.0362 7.33317 10.668 7.33317H5.33464Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3923_4183">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);
