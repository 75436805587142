import type { FC, ReactNode } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { Button } from '../Button/Button';
import { Section } from '../Section';

import { ConfirmDeletionModal } from './ConfirmDeletionModal';

export interface DeletionSectionProps {
  action: string;
  title: string;
  description: string;
  confirmationMessage?: string;
  onDeleted: () => void;
  isDisabled?: boolean;
  isModalActionDisabled?: boolean;
  modalActionTooltip?: string;
  modalAdditionalContent?: ReactNode;
  isCentered?: boolean;
  tooltip?: string;
}

export const DeletionSection: FC<DeletionSectionProps> = ({
  action,
  title,
  description,
  confirmationMessage,
  isDisabled,
  isModalActionDisabled,
  modalActionTooltip,
  tooltip,
  modalAdditionalContent,
  isCentered = true,
  onDeleted,
}) => {
  const modalControls = useDisclosure();

  return (
    <Section title={title} description={description}>
      <Button
        colorScheme="red"
        variant="outline"
        onClick={modalControls.onOpen}
        isDisabled={isDisabled}
        tooltip={tooltip}
      >
        {action}
      </Button>

      <ConfirmDeletionModal
        title={title}
        action={action}
        description={confirmationMessage}
        onDeleted={onDeleted}
        tooltip={modalActionTooltip}
        isActionDisabled={isModalActionDisabled}
        additionalContent={modalAdditionalContent}
        isCentered={isCentered}
        {...modalControls}
      />
    </Section>
  );
};
