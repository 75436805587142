import { Box, Flex, Text } from '@chakra-ui/react';
import type { DrawerApi } from '@graneet/lib-ui';
import { DrawersStack, Table, Button, useCurrency, AmountSummary, SimpleAddIcon } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus, useOnChangeValues } from 'graneet-form';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import type { IPenaltyUpsertDTO } from '@graneet/business-logic';
import { isEqual } from 'lodash-es';

import type { PenaltyForm } from '../forms/penalty.form';
import {
  PENALTY_TYPE,
  getPenaltyAmountIncVatFieldName,
  getPenaltyDenominationFieldName,
  getPenaltyFieldName,
  getPenaltyInputType,
  getPenaltyRowId,
  getPenaltyTypeFieldName,
} from '../forms/penalty.form';

import { PenaltyTableRow } from './PenaltyTableRow';

import { useSendClickAddPenaltyButtonEvent } from 'features/analytic/hooks/useSendClickAddPenaltyButtonEvent';

export type PenaltyFormValues = Record<string, { denomination?: string; amountIncVAT?: number }>;

export enum DescriptionLabel {
  PROGRESS_STATEMENT = 'progressStatement',
  FINAL_STATEMENT = 'finalStatement',
}
interface PenalyDrawerProps {
  drawer: DrawerApi<string>;
  descriptionLabel: DescriptionLabel;
  existingPenalties: IPenaltyUpsertDTO[];
  onSubmit: (formValues: PenaltyFormValues) => void;
}

export const PenalyDrawer: FC<PenalyDrawerProps> = ({ drawer, existingPenalties, onSubmit, descriptionLabel }) => {
  const [fieldsIds, setFieldsIds] = useState<string[]>([uuid()]);

  const { t } = useTranslation(['penalty']);

  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const form = useForm<PenaltyForm>();
  const { isValid: isFormValid } = useFormStatus(form);
  const fields = useOnChangeValues(form, getPenaltyFieldName(fieldsIds));

  const getFormValues = useCallback(() => {
    const values: PenaltyFormValues = {};

    Object.keys(fields).forEach((key) => {
      const id = getPenaltyRowId(key as keyof PenaltyForm);
      const property = getPenaltyInputType(key as keyof PenaltyForm);
      const value = fields[key as keyof PenaltyForm];

      if (property !== 'denomination' && property !== 'amountIncVAT') {
        return;
      }

      if (!values[id]) {
        values[id] = {};
      }

      if (property === 'denomination' && value) {
        values[id].denomination = value.toString();
      }

      if (property === 'amountIncVAT' && value) {
        const typeKey: keyof PenaltyForm = getPenaltyTypeFieldName(id);
        const isNegative = fields[typeKey] === PENALTY_TYPE.NEGATIVE;
        values[id].amountIncVAT = mapAmountToNumber(value) * (isNegative ? -1 : 1);
      }
    });

    return values;
  }, [fields, mapAmountToNumber]);

  const getValuesFromExistingPenalties = useCallback((array: IPenaltyUpsertDTO[]) => {
    const values: PenaltyFormValues = {};

    array.forEach((penalty) => {
      const { id } = penalty;

      values[id] = {
        denomination: penalty.name,
        amountIncVAT: penalty.amountIncVAT,
      };
    });

    return values;
  }, []);

  const { totalPenalty, formValues } = useMemo(() => {
    const values = getFormValues();

    Object.entries(values).forEach(([key, value]) => {
      if (value.amountIncVAT === undefined || value.denomination === undefined) {
        delete values[key];
      }
    });

    const totalPenaltyAmount = Object.values(values).reduce(
      (acc, curr) => (curr.amountIncVAT ? acc + curr.amountIncVAT : acc),
      0,
    );

    return {
      totalPenalty: totalPenaltyAmount,
      formValues: values,
    };
  }, [getFormValues]);

  const sendClickAddPenaltyEvent = useSendClickAddPenaltyButtonEvent();
  const handleAddField = () => {
    sendClickAddPenaltyEvent(
      descriptionLabel === DescriptionLabel.PROGRESS_STATEMENT ? 'progress-statement' : 'final-statement',
    );
    setFieldsIds((prev) => [...prev, uuid()]);
  };

  const handleRemoveField = (fieldId: string) => {
    if (fieldsIds.length <= 1 && existingPenalties.length) {
      onSubmit({});
      return;
    }

    if (fieldsIds.length <= 1) {
      setFieldsIds([uuid()]);
      drawer.onClose();
      return;
    }

    setFieldsIds((prev) => prev.filter((prevFieldId) => prevFieldId !== fieldId));
  };

  const handleOnSubmit = () => {
    onSubmit(formValues);
  };

  useEffect(() => {
    if (existingPenalties.length) {
      setFieldsIds(existingPenalties.map((penalty) => penalty.id));
      const penaltyForm: Partial<PenaltyForm> = {};

      existingPenalties.forEach((penalty) => {
        const typeKey = getPenaltyTypeFieldName(penalty.id);
        const nameKey = getPenaltyDenominationFieldName(penalty.id);
        const amountKey = getPenaltyAmountIncVatFieldName(penalty.id);

        penaltyForm[typeKey] = penalty.amountIncVAT < 0 ? PENALTY_TYPE.NEGATIVE : PENALTY_TYPE.POSITIVE;
        penaltyForm[nameKey] = penalty.name;
        penaltyForm[amountKey] = mapNumberToAmount(
          penalty.amountIncVAT < 0 ? penalty.amountIncVAT * -1 : penalty.amountIncVAT,
        );
      });

      form.resetForm();
      form.setFormValues(penaltyForm);
    } else {
      setFieldsIds([uuid()]);
    }
  }, [existingPenalties, form, mapNumberToAmount]);

  const [hasBeenHandled, setHasBeenHandled] = useState(false);

  useEffect(() => {
    const valuesArray = getValuesFromExistingPenalties(existingPenalties);
    setHasBeenHandled(!isEqual(formValues, valuesArray));
  }, [existingPenalties, formValues, getValuesFromExistingPenalties]);

  return (
    <Form form={form}>
      <DrawersStack.Drawer
        onOverlayClick={isFormValid && hasBeenHandled ? handleOnSubmit : undefined}
        title={t('penalty:drawer.title')}
        drawer={drawer}
        footer={
          <Flex justify="flex-end" w="100vw">
            <Button onClick={handleOnSubmit} isDisabled={!(isFormValid && hasBeenHandled)}>
              {t('penalty:drawer.savePenalties', { count: fieldsIds.length })}
            </Button>
          </Flex>
        }
      >
        <Text whiteSpace="pre-line">{t(`penalty:drawer.description.${descriptionLabel}`)}</Text>

        <Box borderRadius="6px" boxShadow="subtle" overflow="hidden">
          <Table templateColumns={['1.5fr', '4fr', '1.5fr', '0.5fr']} noShadow overflow="hidden">
            <Table.Header>
              <Table.Cell>{t('penalty:drawer.type')}</Table.Cell>
              <Table.Cell>{t('penalty:drawer.denomination')}</Table.Cell>
              <Table.Cell>
                <Text textAlign="end" width="100%">
                  {t('penalty:drawer.amountIncVAT')}
                </Text>
              </Table.Cell>
              <Table.Cell />
            </Table.Header>

            {fieldsIds.map((fieldId) => (
              <PenaltyTableRow key={fieldId} fieldId={fieldId} removeField={handleRemoveField} />
            ))}
          </Table>
        </Box>
        <Flex flexDirection="row" alignItems="center" width="100%" justifyContent="space-between">
          <Box>
            <Button variant="outline" leftIcon={<SimpleAddIcon />} onClick={handleAddField} mt="1.3rem">
              {t('penalty:drawer.addPenalty')}
            </Button>
          </Box>

          <Box>
            <AmountSummary>
              <AmountSummary.Item label={t('penalty:drawer.totalPenalty')} amount={totalPenalty} important />
            </AmountSummary>
          </Box>
        </Flex>
      </DrawersStack.Drawer>
    </Form>
  );
};
