export enum PERMISSION {
  ADMIN = 'admin',

  /**
   * TODO rename to "access:quotes"
   */
  DISPLAY_QUOTES = 'display:quotes',
  /**
   * If false, only see quotes where the user is associated to.
   */
  VIEW_ALL_QUOTES = 'view-all:quotes',

  ACCESS_DASHBOARDS = 'access:dashboards',

  CREATE_QUOTES = 'create:quotes',
  ACCEPT_QUOTES = 'accept:quotes',

  UPDATE_LIBRARY = 'update:library',

  /**
   * If false, only see projects where the user is associated to.
   */
  VIEW_ALL_PROJECTS = 'view-all:projects',
  CREATE_PROJECTS = 'create:projects',

  ACCESS_STATEMENTS = 'access:statements',
  CREATE_STATEMENTS = 'create:statements',
  UPDATE_STATUS_STATEMENTS = 'update-status:statements',
  FORCE_DRAFT_STATEMENTS = 'force-draft:statements',

  PAY_BILLS = 'pay:bills',

  PAY_HOLDBACKS = 'pay:holdbacks',

  UPDATE_COMPANY_INFORMATIONS = 'update:company-informations',

  UPDATE_QUOTE_PARAMETERS = 'update:quote-parameters',

  UPDATE_CONTRACTS = 'update:contracts',

  ACCESS_SUPPLIER_INVOICE = 'access:supplier-invoice',
  CREATE_SUPPLIER_INVOICE = 'create:supplier-invoice',
  PAY_SUPPLIER_INVOICE = 'pay:supplier-invoice',

  UPDATE_SUPPLIER_INVOICE_PAYMENT = 'update:supplier-invoice-payment',

  ACCESS_ORDER = 'access:order',
  CREATE_ORDER = 'create:order',

  CREATE_BUDGET = 'create:budget',

  ACCESS_TIME_TRACKING = 'access:time-tracking',

  ACCESS_WORKER_HOURLY_PRICE = 'access:worker-hourly-price',

  UPDATE_QUOTE_TYPE_DISBURSEMENT_TYPE = 'update:quote-margin-disbursement-type',

  UPDATE_LEDGER_PARAMETERS = 'update:ledger-parameters',

  UPDATE_THIRD_PARTIES = 'update:third-parties',

  ACCESS_SUPPLIER_INVOICE_VALIDATION_WORKFLOW = 'access:supplier-invoice-validation-workflow',

  /**
   * TODO rename to "access:accounting"
   */
  DISPLAY_ACCOUNTING = 'display:accounting',
  GENERATE_ACCOUNTING = 'generate:accounting',
  UPDATE_ACCOUNTING = 'update:accounting',

  UPDATE_EMAIL_TEMPLATES = 'update:email-templates',

  UPDATE_EMAIL_REMINDERS = 'update:email-reminders',

  ACCESS_BALANCE_BANKING = 'access-balance:banking',
  ACCESS_TRANSACTION_BANKING = 'access-transaction:banking',
  ACCESS_PAYMENT_RESOURCE_BANKING = 'access-payment-resource:banking',
}

export const ADMIN_ROLE = 'admin';

export const PERMISSIONS_WITHOUT_USER_FEATURE_FLAG: PERMISSION[] = [
  PERMISSION.ADMIN,
  PERMISSION.FORCE_DRAFT_STATEMENTS,
];
