import type { ElementRef, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { forwardRef, cloneElement } from 'react';
import type { StyleProps } from '@chakra-ui/react';
import { Flex, HStack } from '@chakra-ui/react';

import { EllipsisText } from '../EllipsisText';

export type RichSelectContentProps = PropsWithChildren<{
  text: ReactNode;
  rightIcon?: ReactElement;
  leftIcon?: ReactElement;
  fontSize: StyleProps['fontSize'];
}>;

export const RichSelectContent = forwardRef<ElementRef<'div'>, RichSelectContentProps>(
  ({ text, rightIcon, leftIcon, children, fontSize = 'sm' }, ref) => {
    const styledLeftIcon = leftIcon
      ? cloneElement(leftIcon, {
          boxSize: '1rem',
          color: 'gray.800',
          mr: 1,
        })
      : undefined;

    const styledRightIcon = rightIcon
      ? cloneElement(rightIcon, {
          boxSize: '1rem',
          color: 'gray.800',
          mr: 2,
        })
      : undefined;

    return (
      <HStack mx={2} spacing={0} w="100%" ref={ref} alignItems="center" height="100%">
        {styledLeftIcon}

        <Flex flexGrow={1} fontSize={fontSize} userSelect="none" width="100%" color="gray.700" overflow="hidden">
          <EllipsisText>{text}</EllipsisText>
        </Flex>

        {styledRightIcon}

        {children}
      </HStack>
    );
  },
);
