import type { FC } from 'react';

import { multiplyFloating, formatPercentage, sortVATDistribution } from '../../../utils';
import type { DropdownItem } from '../types';
import { ActionMenu } from '../../ActionMenu/ActionMenu';
import { SimpleSettingsIcon } from '../../Icons';

import { differenceSummaryTranslations, configureDefaultLabels } from './DifferenceSummaryTranslations';
import { DifferenceSummaryItem } from './DifferenceSummaryItem';

type VatDistribution = {
  initialAmount: number;
  newAmount: number;
  vatRate: number;
};

export interface VATDistributionProps {
  initialHasReversalOfLiability?: boolean | null;
  newHasReversalOfLiability?: boolean | null;
  vatDistribution: VatDistribution[];
  dropdownItems?: DropdownItem[];
  left?: boolean;
  withCustomDiscountsLabel?: boolean;
}

export const VATDistributionComponent: FC<VATDistributionProps> = ({
  vatDistribution,
  initialHasReversalOfLiability = false,
  newHasReversalOfLiability = false,
  dropdownItems,
  left = false,
  withCustomDiscountsLabel = false,
}) => {
  const distribution = sortVATDistribution(vatDistribution || []);
  const rightContent =
    dropdownItems && dropdownItems.length > 0 ? (
      <ActionMenu placement="top-start" iconSize={6}>
        {dropdownItems.map((item) => (
          <ActionMenu.Action
            key={`item_${item.label}`}
            label={item.label}
            icon={item.icon || <SimpleSettingsIcon />}
            onClick={item.onClick}
            warning={item.warning}
          />
        ))}
      </ActionMenu>
    ) : null;

  return (
    <>
      {newHasReversalOfLiability && (
        <DifferenceSummaryItem
          light
          textAlign={left ? 'left' : 'right'}
          label={differenceSummaryTranslations.withReversalOfLiabilityOn}
          initialAmount={
            initialHasReversalOfLiability
              ? 0
              : distribution.reduce((acc, { initialAmount }) => acc + initialAmount, 0) -
                distribution.reduce((acc, { newAmount }) => acc + newAmount, 0)
          }
          newAmount={0}
          negative
          rightContent={rightContent}
          type="reversalOfLiability"
          sign
        />
      )}

      {distribution.length > 0 &&
        distribution.map(({ newAmount, initialAmount, vatRate }, index) => {
          const vatRateFormatted = formatPercentage(multiplyFloating(vatRate, 100));
          return (
            <DifferenceSummaryItem
              key={`vat_${vatRate}`}
              light
              textAlign={left ? 'left' : 'right'}
              label={
                withCustomDiscountsLabel
                  ? differenceSummaryTranslations.customDiscountVat(vatRateFormatted)
                  : differenceSummaryTranslations.vat(vatRateFormatted)
              }
              initialAmount={initialAmount}
              newAmount={newAmount}
              rightContent={index === 0 && !newHasReversalOfLiability && rightContent}
              sign
            />
          );
        })}
    </>
  );
};

export const VATDistribution = Object.assign(VATDistributionComponent, { configureDefaultLabels });
