import type { IBankingTransactionWithRelations, IClient } from '@graneet/business-logic';
import { PAYMENT_TYPES, SUPPLIER_INVOICE_PAYMENT_TYPES } from '@graneet/business-logic';
import type { FC, ReactNode } from 'react';
import { useCallback } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { DocumentStatusCardProps } from '@graneet/lib-ui';
import { Badge, ButtonList, DocumentStatusCard, formatDateOrEmpty, GraneetButton, useCurrency } from '@graneet/lib-ui';

import { useBillClient } from 'features/bill/services/bill.api';

interface BankingTransactionDrawerStatementsProps {
  bankingTransaction: IBankingTransactionWithRelations;
  onAssociateBillToTransaction: () => void;
  onDisassociateBillFromTransaction: (billId: number) => void;
  onAssociateSupplierInvoiceToTransaction: () => void;
  onDisassociateSupplierInvoiceFromTransaction: (supplierInvoiceId: number) => void;
  onCreateSupplierInvoiceToTransaction: () => void;
  hideAssociationButton: boolean;
}

const HOW_TO_URL = 'http://www.google.com';

function mapSupplierInvoicePaymentToDocumentCardProps(
  supplierInvoicePayment: IBankingTransactionWithRelations['supplierInvoicePayments'][number],
  options: { formatAsAmount: (n: number) => string; emitAtLabel: (date: string) => string },
): DocumentStatusCardProps {
  return {
    title: [supplierInvoicePayment.supplierInvoice.invoiceNumber, supplierInvoicePayment.supplierInvoice.name]
      .filter((v) => v !== null)
      .join(' - '),
    icon: supplierInvoicePayment.type === SUPPLIER_INVOICE_PAYMENT_TYPES.TOTAL ? 'completed' : 'partial',
    subtitle: (
      <>
        <Text>{options.formatAsAmount(supplierInvoicePayment.amountPaidIncVAT)}</Text>
        <Text>{supplierInvoicePayment.supplierInvoice.supplier?.name}</Text>
      </>
    ),
    footer: options.emitAtLabel(formatDateOrEmpty(supplierInvoicePayment.paidAt)),
  };
}

function mapPaymentToDocumentCardProps(
  payment: IBankingTransactionWithRelations['payments'][number],
  options: { formatAsAmount: (n: number) => string; emitAtLabel: (date: string) => string; client: IClient | null },
): DocumentStatusCardProps {
  return {
    title: [payment.bill.invoiceNumber, payment.bill.name].filter((v) => v !== null).join(' - '),
    icon: payment.type === PAYMENT_TYPES.TOTAL ? 'completed' : 'partial',
    subtitle: (
      <>
        <Text>{options.formatAsAmount(payment.amountPaidIncVAT)}</Text>
        <Text>{options.client?.enterpriseName}</Text>
      </>
    ),
    footer: options.emitAtLabel(formatDateOrEmpty(payment.paidAt)),
  };
}

const TransactionDoneCard = ({
  type,
  entity,
  onDeleteTransaction,
  isSuggestion = false,
  bottomContent,
}: (
  | { type: 'supplierInvoicePayment'; entity: IBankingTransactionWithRelations['supplierInvoicePayments'][number] }
  | { type: 'payment'; entity: IBankingTransactionWithRelations['payments'][number] }
) & {
  onDeleteTransaction: () => void;
  isSuggestion?: boolean;
  bottomContent?: ReactNode;
}) => {
  const { t } = useTranslation(['global', 'banking']);
  const { formatAsAmount } = useCurrency();

  const client = useBillClient(type === 'payment' ? entity.bill.id : undefined);

  const props =
    type === 'supplierInvoicePayment'
      ? mapSupplierInvoicePaymentToDocumentCardProps(entity, {
          formatAsAmount,
          emitAtLabel: (date) => t('global:emitAt', { date }),
        })
      : mapPaymentToDocumentCardProps(entity, {
          formatAsAmount,
          emitAtLabel: (date) => t('global:emitAt', { date }),
          client,
        });

  const onHowToClick = useCallback(() => {
    window.open(HOW_TO_URL);
  }, []);

  return (
    <Box bg={isSuggestion ? 'blue.100' : undefined} borderRadius="md">
      {isSuggestion && (
        <Flex py={2} px={3} justifyContent="space-between" color="blue.500" fontSize="small">
          <Flex gap={2}>
            <i className="ri-sparkling-fill" />
            <Text color="primaryLight">{t('global:words.c.suggestion')}</Text>
          </Flex>

          <Text onClick={onHowToClick} cursor="pointer">
            {t('banking:actions.howItWorks')}
          </Text>
        </Flex>
      )}

      <Box role="group" position="relative">
        {!isSuggestion && (
          <Flex
            gap={2}
            zIndex={5000}
            position="absolute"
            top={2}
            right={2}
            visibility="hidden"
            _groupHover={{
              visibility: 'visible',
            }}
          >
            <Box cursor="pointer" color="red.500" onClick={onDeleteTransaction}>
              <i className="ri-delete-bin-line ri-lg" />
            </Box>
          </Flex>
        )}

        <DocumentStatusCard
          {...props}
          icon={isSuggestion ? 'finalized' : props.icon}
          borderColor={isSuggestion ? 'blue.500' : undefined}
          isMinimalist
          bottomContent={bottomContent}
        />
      </Box>
    </Box>
  );
};

export const BankingTransactionDrawerStatements: FC<BankingTransactionDrawerStatementsProps> = ({
  bankingTransaction,
  onAssociateBillToTransaction,
  onDisassociateBillFromTransaction,
  onAssociateSupplierInvoiceToTransaction,
  onDisassociateSupplierInvoiceFromTransaction,
  onCreateSupplierInvoiceToTransaction,
  hideAssociationButton,
}) => {
  const { t } = useTranslation(['banking', 'global']);
  const { formatAsAmount } = useCurrency();

  const isIncomingTransaction = bankingTransaction.amount > 0;

  const onDeleteTransaction = useCallback(
    (
      options:
        | {
            type: 'payment';
            billId: number;
          }
        | {
            type: 'supplierInvoicePayment';
            supplierInvoiceId: number;
          },
    ) =>
      () => {
        if (options.type === 'payment') {
          onDisassociateBillFromTransaction(options.billId);
        } else {
          onDisassociateSupplierInvoiceFromTransaction(options.supplierInvoiceId);
        }
      },
    [onDisassociateBillFromTransaction, onDisassociateSupplierInvoiceFromTransaction],
  );

  const onReconcile = useCallback(() => {
    // TODO @[ff: banking-account-connection]
  }, []);

  const onReject = useCallback(() => {
    // TODO @[ff: banking-account-connection]
  }, []);

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex gap={2} alignItems="center">
          <Text fontWeight={600}>{t('banking:bankingTransaction.details.statementsBlock.title')}</Text>
          <Badge colorScheme="gray">
            {bankingTransaction.supplierInvoicePayments.length + bankingTransaction.payments.length}
          </Badge>
        </Flex>

        <Text color="greenBrand.baseSecondary">
          {t('banking:bankingTransaction.details.statementsBlock.leftToReconcile', {
            amount: formatAsAmount(bankingTransaction.remainingAmountToReconcileIncVAT),
          })}
        </Text>
      </Flex>

      {/* TODO @[ff: banking-account-connection] */}
      {bankingTransaction.remainingAmountToReconcileIncVAT !== 0 && (
        <TransactionDoneCard
          key={1}
          type="supplierInvoicePayment"
          entity={
            {
              type: PAYMENT_TYPES.PARTIAL,
              amountPaidIncVAT: 10_00,
              paidAt: new Date(),
              supplierInvoice: {
                invoiceNumber: '19-7T-618',
                name: 'Location Nacelle',
                supplier: {
                  name: 'LOXAM',
                },
              },
            } as any
          }
          onDeleteTransaction={onDeleteTransaction({
            type: 'supplierInvoicePayment',
            supplierInvoiceId: 1,
          })}
          isSuggestion
          bottomContent={
            <Flex gap={2}>
              <GraneetButton w="full" variant="secondary_danger" onClick={onReject}>
                {t('global:words.c.reject')}
              </GraneetButton>

              <GraneetButton w="full" variant="primary" onClick={onReconcile}>
                {t('global:words.c.reconcile')}
              </GraneetButton>
            </Flex>
          }
        />
      )}

      {bankingTransaction.supplierInvoicePayments.map((supplierInvoicePayment) => (
        <TransactionDoneCard
          key={supplierInvoicePayment.id}
          type="supplierInvoicePayment"
          entity={supplierInvoicePayment}
          onDeleteTransaction={onDeleteTransaction({
            type: 'supplierInvoicePayment',
            supplierInvoiceId: supplierInvoicePayment.supplierInvoice.id,
          })}
        />
      ))}

      {bankingTransaction.payments.map((payment) => (
        <TransactionDoneCard
          key={payment.id}
          type="payment"
          entity={payment}
          onDeleteTransaction={onDeleteTransaction({
            type: 'payment',
            billId: payment.bill.id,
          })}
        />
      ))}

      {!hideAssociationButton && (
        <>
          {isIncomingTransaction ? (
            <GraneetButton
              isDisabled={bankingTransaction.remainingAmountToReconcileIncVAT === 0}
              leftIcon="ri-attachment-line"
              onClick={onAssociateBillToTransaction}
            >
              {t('banking:actions.associateInvoiceToTransaction')}
            </GraneetButton>
          ) : (
            <ButtonList
              disabled={bankingTransaction.remainingAmountToReconcileIncVAT === 0}
              label={t('banking:actions.associateInvoiceToTransaction')}
              width="full"
              leftIcon="ri-attachment-line"
              ctaList={[
                {
                  leftIcon: 'ri-search-line',
                  label: t('banking:actions.selectExistingInvoice'),
                  color: 'black',
                  onClick: onAssociateSupplierInvoiceToTransaction,
                },
                {
                  leftIcon: 'ri-attachment-line',
                  label: t('banking:actions.createANewInvoiceForTransaction'),
                  color: 'greenBrand',
                  onClick: onCreateSupplierInvoiceToTransaction,
                },
              ]}
            />
          )}
        </>
      )}
    </>
  );
};
