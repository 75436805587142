import type { useToast } from '@graneet/lib-ui';
import type { Members, PresenceChannel } from 'pusher-js';

import type { IPusherService } from '../ports/IPusherService';
import type { IAction, IPayloadToBig } from '../entities/IAction';

import type { PusherProxyApi } from 'features/quotation/quote-common/proxies/quotePusherProxyApi';

export class AttachActions {
  private pusherService: IPusherService;

  private pusherProxyApi: PusherProxyApi;

  private actions: IAction[] = [];

  private toast: ReturnType<typeof useToast>;

  private errorMessage: string;

  private presenceChannel: PresenceChannel | undefined;

  constructor(
    pusherService: IPusherService,
    pusherProxyApi: PusherProxyApi,
    toast: ReturnType<typeof useToast>,
    errorMessage: string,
  ) {
    this.pusherService = pusherService;
    this.pusherProxyApi = pusherProxyApi;
    this.toast = toast;
    this.errorMessage = errorMessage;
  }

  execute(actions: IAction[]): void {
    if (!this.pusherService) return;
    this.actions = actions;

    actions.forEach((action) => {
      this.pusherService.subscribe([action.channel]);
      const channel = this.pusherService.getChannel(action.channel);
      if (channel && !this.pusherService.isActionExist(action.channel, action.event)) {
        this.pusherService.bind(channel, action.event, action.callback);
        this.pusherService.addActionsToChannel(action.channel, {
          event: action.event,
          channel: action.channel,
          callback: action.callback,
        });
      }
    });

    const channel = this.pusherService.getChannel(actions[0].channel);
    if (channel && !this.pusherService.isActionExist(actions[0].channel, 'payload-to-big')) {
      this.pusherService.bind(channel, 'payload-to-big', this.payloadToBigCallback.bind(this));
      this.pusherService.addActionsToChannel(actions[0].channel, {
        event: 'payload-to-big',
        channel: actions[0].channel,
        callback: this.payloadToBigCallback.bind(this),
      });
    }
  }

  presence(quoteId: string, onMembersUpdate: () => void) {
    this.pusherService.subscribe([`presence-${quoteId}`]);
    this.presenceChannel = this.pusherService.getChannel(`presence-${quoteId}`) as PresenceChannel;
    if (this.presenceChannel) {
      this.presenceChannel.bind('pusher:subscription_succeeded', onMembersUpdate);
      this.presenceChannel.bind('pusher:member_added', onMembersUpdate);
      this.presenceChannel.bind('pusher:member_removed', onMembersUpdate);
    }
  }

  disconnectPresence() {
    if (this.presenceChannel) {
      this.pusherService.getPusher()?.unsubscribe(this.presenceChannel.name);
    }
  }

  getMembers(): Members | undefined {
    return this.presenceChannel?.members;
  }

  private payloadToBigCallback(value: IPayloadToBig) {
    this.actions.forEach(async (action) => {
      if (action.event === value.event) {
        const [err, res] = await this.pusherProxyApi.getPayloadFromCache(value.clientRequestId);
        if (err) {
          this.toast.error(this.errorMessage);
        }
        if (res) {
          action.callback(res);
        }
      }
    });
  }
}
