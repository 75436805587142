import { CheckboxField, IconAdvanced, ModalSubtitle, SimpleHelpIcon } from '@graneet/lib-ui';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type { RoleFormFields } from '../../constants/role.constant';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const RoleEditModalCompanyParameters = () => {
  const { t } = useTranslation(['user']);

  const hasUserPermissionsFeatureFlag = useFeatureFlag(FEATURE_FLAGS.USER_PERMISSIONS);

  // @[ff: workflow-validation]
  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);
  // @[ff: workflow-validation-access]
  const hasWorkflowValidationAccessFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION_ACCESS);

  if (!hasUserPermissionsFeatureFlag) {
    return null;
  }

  return (
    <Stack direction="column" gap={0}>
      <ModalSubtitle mb={2}>{t('user:editRoleModal.sections.companyParameters')}</ModalSubtitle>

      <Stack direction="column" gap={1} pl={3}>
        <CheckboxField<RoleFormFields>
          name="updateCompanyInformation"
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.UPDATE_COMPANY_INFORMATIONS')}</Text>
              <IconAdvanced
                icon={<SimpleHelpIcon boxSize={4} />}
                tooltip={t('user:role.UPDATE_COMPANY_INFORMATIONS.tooltip')}
              />
            </HStack>
          }
        />

        <CheckboxField<RoleFormFields>
          name="updateLedgerParameters"
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.UPDATE_LEDGER_PARAMETERS')}</Text>
            </HStack>
          }
        />

        <CheckboxField<RoleFormFields>
          name="updateQuoteParameters"
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.UPDATE_QUOTE_PARAMETERS')}</Text>
              <IconAdvanced
                icon={<SimpleHelpIcon boxSize={4} />}
                tooltip={t('user:role.UPDATE_QUOTE_PARAMETERS.tooltip')}
              />
            </HStack>
          }
        />

        <CheckboxField<RoleFormFields> name="updateThirdParties" label={t('user:permissions.UPDATE_THIRD_PARTIES')} />

        <CheckboxField<RoleFormFields>
          name="updateQuoteMarginDisbursementType"
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.UPDATE_QUOTE_TYPE_DISBURSEMENT_TYPE')}</Text>
              <IconAdvanced
                icon={<SimpleHelpIcon boxSize={4} />}
                tooltip={t('user:role.UPDATE_QUOTE_TYPE_DISBURSEMENT_TYPE.tooltip')}
              />
            </HStack>
          }
        />

        <CheckboxField<RoleFormFields>
          name="updateEmailTemplates"
          label={t('user:permissions.UPDATE_EMAIL_TEMPLATES')}
        />

        <CheckboxField<RoleFormFields> name="emailReminders" label={t('user:permissions.UPDATE_EMAIL_REMINDERS')} />

        {hasWorkflowValidationFeatureFlag && hasWorkflowValidationAccessFeatureFlag && (
          <CheckboxField<RoleFormFields>
            name="accessSupplierInvoiceValidationWorkflow"
            label={t('user:permissions.ACCESS_SUPPLIER_INVOICE_VALIDATION_WORKFLOW')}
          />
        )}
      </Stack>
    </Stack>
  );
};
