import { Avatar, HStack, VStack, Flex } from '@chakra-ui/react';
import { EllipsisText, formatDataOrEmpty, Tooltip } from '@graneet/lib-ui';
import type { IContact } from '@graneet/business-logic';
import { StarIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { formatUserDisplayName } from 'features/user/services/user.util';

interface ContactDetailsProps<T extends IContact> {
  contact: T;
  isContactDefaultEmailRecipient: (contact: T) => boolean;
}

export const ContactDetails = <T extends IContact>({
  contact,
  isContactDefaultEmailRecipient,
}: ContactDetailsProps<T>) => {
  const { t } = useTranslation(['contacts']);

  return (
    <HStack spacing={3} h="100%">
      <Avatar size="sm" name={formatUserDisplayName(contact)} />

      <VStack spacing={1} align="flex-start" lineHeight="1.5rem">
        <Flex fontWeight="600" color="gray.800" alignItems="center" gap={2}>
          {formatUserDisplayName(contact)}
          {isContactDefaultEmailRecipient(contact) ? (
            <Tooltip label={t('contacts:defaultEmailRecipient')}>
              <StarIcon color="yellow.500" />
            </Tooltip>
          ) : undefined}
        </Flex>
        <EllipsisText>{formatDataOrEmpty(contact.role)}</EllipsisText>
      </VStack>
    </HStack>
  );
};
