import { Card, ConfirmDeletionModal, useToast } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';
import { useDisclosure } from '@chakra-ui/react';

import { ContactUpsertModal } from '../modals/ContactUpsertModal';
import { ContactAvailableQuoteModal } from '../modals/ContactAvailableQuoteModal';
import { ContactAddOrAssociateButton } from '../ContactAddOrAssociateButton';

import { ContactCardDetails } from './ContactCardDetails';

import { Error } from 'features/common/components/Error';
import { Loading } from 'features/common/components/Loading';
import {
  useContactAssociatedToQuote,
  useContactDelete,
  useContactDissociateFromQuote,
  useContactRoles,
  useContactsAssociateToClient,
  useContactsAssociateToQuote,
} from 'features/contact/services/contact.api';
import { useQuotationApi } from 'features/quotation/services/quote.api';

interface ContactCardQuoteProps<T extends number | string> {
  quoteId: T;
  clientId: number;
  onContactUpdate?: () => void;
}

const ContactCardQuoteV1: FC<ContactCardQuoteProps<number>> = ({ quoteId, clientId, onContactUpdate }) => {
  const upsertModal = useDisclosure();
  const deletionModal = useDisclosure();
  const associationModal = useDisclosure();

  const contacts = useContactAssociatedToQuote(quoteId);
  const contactRoles = useContactRoles();

  const contactDeleteMutation = useContactDelete();
  const contactsAssociateToClientMutation = useContactsAssociateToClient();
  const contactsAssociateToQuoteMutation = useContactsAssociateToQuote();
  const useContactDissociateFromQuoteMutation = useContactDissociateFromQuote();

  const { t } = useTranslation(['global', 'contacts']);

  const [contactUpsert, setContactUpsert] = useState<IContact | null>(null);
  const [contactToDelete, setContactToDelete] = useState<IContact | null>(null);

  const reloadContacts = useCallback(() => {
    onContactUpdate?.();
  }, [onContactUpdate]);

  const onUpsert = useCallback(
    (contact?: IContact) => {
      if (contact) {
        setContactUpsert(contact);
      } else {
        setContactUpsert(null);
      }
      upsertModal.onOpen();
    },
    [upsertModal],
  );

  const onDissociate = useCallback(
    async (contactId: string) => {
      await useContactDissociateFromQuoteMutation.mutateAsync({ contactId, quoteId });
      reloadContacts();
    },
    [quoteId, reloadContacts, useContactDissociateFromQuoteMutation],
  );

  const onDelete = useCallback(
    async (contact: IContact) => {
      if (contact) {
        setContactToDelete(contact);
      } else {
        setContactToDelete(null);
      }
      deletionModal.onOpen();
    },
    [deletionModal],
  );

  const onWorkerDeletion = useCallback(async () => {
    if (contactToDelete) {
      await contactDeleteMutation.mutateAsync(contactToDelete);

      reloadContacts();
      deletionModal.onClose();
    }
  }, [contactToDelete, contactDeleteMutation, reloadContacts, deletionModal]);

  const onUpsertSuccess = useCallback(
    async (contact: IContact, data: { isDefaultEmailRecipient?: boolean }) => {
      if (data.isDefaultEmailRecipient !== undefined) {
        await contactsAssociateToClientMutation.mutateAsync({
          id: clientId,
          dto: {
            contacts: [
              {
                id: contact.id,
                isDefaultEmailRecipient: data.isDefaultEmailRecipient,
              },
            ],
          },
        });
        await contactsAssociateToQuoteMutation.mutateAsync({
          id: quoteId,
          dto: {
            contacts: [{ id: contact.id, isDefaultEmailRecipient: data.isDefaultEmailRecipient }],
          },
        });
      }
      reloadContacts();
    },
    [clientId, contactsAssociateToClientMutation, contactsAssociateToQuoteMutation, quoteId, reloadContacts],
  );

  const onDefaultEmailRecipientChange = useCallback(
    async (contactId: string, isDefaultEmailRecipient: boolean) => {
      await contactsAssociateToClientMutation.mutateAsync({
        id: quoteId,
        dto: {
          contacts: [{ id: contactId, isDefaultEmailRecipient }],
        },
      });
      reloadContacts();
    },
    [contactsAssociateToClientMutation, quoteId, reloadContacts],
  );

  return (
    <Card
      w="100%"
      title={t('contacts:card.title')}
      topRightContent={<ContactAddOrAssociateButton onAssociate={associationModal.onOpen} onCreate={onUpsert} />}
    >
      <ContactCardDetails
        contacts={contacts.data}
        isContactDefaultEmailRecipient={(contact) => contact.isQuoteDefaultEmailRecipient}
        onUpsert={onUpsert}
        onDelete={onDelete}
        onDissociate={onDissociate}
        onDefaultEmailRecipientChange={onDefaultEmailRecipientChange}
        emptyState={t('contacts:emptyState.quote')}
      />
      <ContactUpsertModal
        isOpen={upsertModal.isOpen}
        onSuccess={onUpsertSuccess}
        onClose={upsertModal.onClose}
        contact={contactUpsert}
        roles={contactRoles.data}
        hasIsDefaultEmailRecipient
      />
      <ConfirmDeletionModal
        isOpen={deletionModal.isOpen}
        onClose={deletionModal.onClose}
        title={t('contacts:deletionModal.title')}
        action={t('contacts:deletionModal.action')}
        description={t('contacts:deletionModal.description')}
        onDeleted={onWorkerDeletion}
      />
      <ContactAvailableQuoteModal
        isOpen={associationModal.isOpen}
        onClose={associationModal.onClose}
        title={t('contacts:availableModal.title')}
        onSuccess={reloadContacts}
        quoteId={quoteId}
      />
    </Card>
  );
};

const ContactCardQuoteV2: FC<ContactCardQuoteProps<string>> = ({ quoteId, clientId }) => {
  const upsertModal = useDisclosure();
  const deletionModal = useDisclosure();
  const associationModal = useDisclosure();

  const { useGetQuoteById, useGetContactsByIds, useQuotePartialUpdate } = useQuotationApi();
  const { data: quote } = useGetQuoteById(quoteId);
  const contactsData = useGetContactsByIds(
    Array.isArray(quote?.contacts) ? quote.contacts.map(({ id }) => id) : undefined,
  );
  const contactRoles = useContactRoles();

  const quotePartialUpdateMutation = useQuotePartialUpdate();
  const contactDeleteMutation = useContactDelete();
  const contactsAssociateToClientMutation = useContactsAssociateToClient();

  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const [contactUpsert, setContactUpsert] = useState<IContact | null>(null);
  const [contactToDelete, setContactToDelete] = useState<IContact | null>(null);

  const onUpsert = useCallback(
    (contact?: IContact) => {
      if (contact) {
        setContactUpsert(contact);
      } else {
        setContactUpsert(null);
      }
      upsertModal.onOpen();
    },
    [upsertModal],
  );

  const onDissociate = useCallback(
    async (contactId: string) => {
      await quotePartialUpdateMutation.mutateAsync({
        quoteId: quote.id,
        contacts: quote.contacts?.filter(({ id }) => id !== contactId),
      });
      toast.success(t('contacts:card.toastDissociateSuccess'));
    },
    [quote.contacts, quote.id, quotePartialUpdateMutation, t, toast],
  );

  const onDelete = useCallback(
    async (contact: IContact) => {
      if (contact) {
        setContactToDelete(contact);
      } else {
        setContactToDelete(null);
      }
      deletionModal.onOpen();
    },
    [deletionModal],
  );

  const onWorkerDeletion = useCallback(async () => {
    if (contactToDelete) {
      await contactDeleteMutation.mutateAsync(contactToDelete);

      await quotePartialUpdateMutation.mutateAsync({
        quoteId: quote.id,
        contacts: quote.contacts?.filter(({ id }) => id !== contactToDelete.id),
      });

      deletionModal.onClose();
    }
  }, [contactToDelete, contactDeleteMutation, quotePartialUpdateMutation, quote.id, quote.contacts, deletionModal]);

  const onUpsertSuccess = useCallback(
    async (contact: IContact, data: { isDefaultEmailRecipient?: boolean }) => {
      if (data.isDefaultEmailRecipient !== undefined) {
        await contactsAssociateToClientMutation.mutateAsync({
          id: clientId,
          dto: {
            contacts: [
              {
                id: contact.id,
                isDefaultEmailRecipient: data.isDefaultEmailRecipient,
              },
            ],
          },
        });

        let contacts = quote.contacts || [];
        if (data.isDefaultEmailRecipient) {
          contacts = contacts.map((c) => ({ ...c, isDefaultEmailRecipient: false }));
        }

        await quotePartialUpdateMutation.mutateAsync({
          quoteId: quote.id,
          contacts: [
            ...contacts,
            {
              id: contact.id,
              isDefaultEmailRecipient: data.isDefaultEmailRecipient,
            },
          ],
        });
      }
    },
    [clientId, contactsAssociateToClientMutation, quote.contacts, quote.id, quotePartialUpdateMutation],
  );

  const onDefaultEmailRecipientChange = useCallback(
    async (contactId: string, isDefaultEmailRecipient: boolean) => {
      let contacts = quote.contacts || [];
      if (isDefaultEmailRecipient) {
        contacts = contacts.map((c) => ({ ...c, isDefaultEmailRecipient: false }));
      }
      await quotePartialUpdateMutation.mutateAsync({
        quoteId: quote.id,
        contacts: contacts.map((contact) => {
          if (contact.id === contactId) {
            return {
              ...contact,
              isDefaultEmailRecipient,
            };
          }
          return contact;
        }),
      });
    },
    [quote.contacts, quote.id, quotePartialUpdateMutation],
  );

  const isContactDefaultEmailRecipient = useCallback(
    (contact: IContact) => {
      const contactInQuote = quote?.contacts?.find(({ id }) => id === contact.id);
      return contactInQuote?.isDefaultEmailRecipient || false;
    },
    [quote],
  );

  return (
    <Card
      w="100%"
      title={t('contacts:card.title')}
      topRightContent={<ContactAddOrAssociateButton onAssociate={associationModal.onOpen} onCreate={onUpsert} />}
    >
      {contactsData.isLoading && <Loading />}
      {contactsData.isError && <Error />}
      {!contactsData.isLoading && !contactsData.error && (
        <>
          <ContactCardDetails
            contacts={contactsData.data}
            isContactDefaultEmailRecipient={isContactDefaultEmailRecipient}
            onUpsert={onUpsert}
            onDelete={onDelete}
            onDissociate={onDissociate}
            onDefaultEmailRecipientChange={onDefaultEmailRecipientChange}
            emptyState={t('contacts:emptyState.quote')}
          />
          <ContactUpsertModal
            isOpen={upsertModal.isOpen}
            onSuccess={onUpsertSuccess}
            onClose={upsertModal.onClose}
            contact={contactUpsert}
            roles={contactRoles.data}
            hasIsDefaultEmailRecipient
          />
          <ConfirmDeletionModal
            isOpen={deletionModal.isOpen}
            onClose={deletionModal.onClose}
            title={t('contacts:deletionModal.title')}
            action={t('contacts:deletionModal.action')}
            description={t('contacts:deletionModal.description')}
            onDeleted={onWorkerDeletion}
          />
          <ContactAvailableQuoteModal
            isOpen={associationModal.isOpen}
            onClose={associationModal.onClose}
            title={t('contacts:availableModal.title')}
            onSuccess={() => {}}
            quoteId={quoteId}
          />
        </>
      )}
    </Card>
  );
};

export const ContactCardQuote: FC<ContactCardQuoteProps<string | number>> = ({
  quoteId,
  clientId,
  onContactUpdate,
}) => {
  if (typeof quoteId === 'string') {
    return <ContactCardQuoteV2 quoteId={quoteId} clientId={clientId} onContactUpdate={onContactUpdate} />;
  }

  return <ContactCardQuoteV1 quoteId={quoteId} clientId={clientId} onContactUpdate={onContactUpdate} />;
};
