import type { FieldValues } from 'graneet-form';
import { Field } from 'graneet-form';
import type { SwitchProps } from '@chakra-ui/react';
import { Flex, FormControl, FormLabel, HStack, Switch } from '@chakra-ui/react';
import type { ReactNode } from 'react';

import type { KeysMatching } from '../../../utils';

type SwitchFieldValue = string | boolean | number | null | undefined;

const DISABLED_STYLES = {
  opacity: 0.4,
};

export interface SwitchFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, SwitchFieldValue> = KeysMatching<T, SwitchFieldValue>,
> extends Omit<SwitchProps, 'name' | 'value' | 'onChange'> {
  name: K;
  uncheckedValue: T[K];
  checkedValue: T[K];
  label?: string | ReactNode;
  onChange?: (v: T[K]) => void;
}
/**
 * Switch field
 * @example
 * ```
 *  <SwitchField name="foo" checkedValue="day" uncheckedValue="night" />
 * ```
 */
export const SwitchField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, SwitchFieldValue> = KeysMatching<T, SwitchFieldValue>,
>({
  name,
  uncheckedValue,
  checkedValue,
  label,
  isDisabled,
  onChange,
  ...props
}: SwitchFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    render={(properties) => {
      const { value, onChange: onChangeForm, onBlur, onFocus } = properties;

      const handleOnChange = () => {
        const newValue = value === checkedValue ? uncheckedValue : checkedValue;
        onFocus();
        onChange?.(newValue);
        onChangeForm(newValue);
        onBlur();
      };

      const isChecked = value === checkedValue;

      return (
        <FormControl display="flex" alignItems="center">
          <Flex {...(isDisabled && DISABLED_STYLES)} alignItems="center">
            <HStack alignItems="center">
              <Switch
                sze="sm"
                name={String(name)}
                value={value}
                isChecked={isChecked}
                onChange={handleOnChange}
                colorScheme="blue"
                isDisabled={isDisabled}
                {...props}
              />

              {label && (
                <FormLabel h="100%" ml={2} fontWeight={isChecked ? '600' : '500'} fontSize="inherit" mb={0}>
                  {label}
                </FormLabel>
              )}
            </HStack>
          </Flex>
        </FormControl>
      );
    }}
  />
);
