export interface SingleSelectTranslations {
  noResult: string;
  create: (newValue: string) => string;
  loading: string;
}

export const singleSelectTranslations: SingleSelectTranslations = {
  noResult: 'No result',
  create: (newValue) => `Create ${newValue}`,
  loading: 'Loading...',
};

export const configureDefaultLabels = (translations: SingleSelectTranslations) => {
  Object.assign(singleSelectTranslations, translations);
};
