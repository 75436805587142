import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from '@chakra-ui/react';
import { Button, useDrawersStack, useDrawer, useCurrency, SimpleAddIcon } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { isNil } from 'lodash-es';

import { useContractLotComputedValue, useContractTreeContext } from '../../hooks/tree.hooks';
import { NEW_LOT_DATA, NEW_ITEM_DATA } from '../../constants/contracts.constant';
import { useContractDefaultVatRateContext } from '../../contexts/ContractDefaultVatRate';
import type { ContractEditionForm } from '../../forms/contract-edition.form';
import { computeContractDiscount, computeContractTotalAmount } from '../../services/contract.util';

import { ContractSummary } from './ContractSummary/ContractSummary';
import { ContractAddDiscountsMenu } from './ContractSummary/ContractAddDiscountsMenu';

import { useContractRuleContext } from 'features/contract/contexts/ContractRuleContext';

export const ContractDeepTableFooter: FC = () => {
  const { t } = useTranslation(['contracts']);

  const { contractInfos } = useContractRuleContext();

  const initialDiscount = contractInfos?.contract.discount;

  const form = useFormContext<ContractEditionForm>();
  const { mapAmountToNumber } = useCurrency();

  const drawersStack = useDrawersStack();
  const discountDrawer = useDrawer('discount-drawer', drawersStack);

  const {
    createNode: createContractLot,
    createLeaf: createContractItem,
    getDisplayedCurrentTree,
    getLastNodeOfNode,
    getLastLeafOfNode,
    getCurrentTree,
    getInitialTree,
    getComputedValues,
  } = useContractTreeContext();
  const { getDefaultVATRate, setDefaultVATRate } = useContractDefaultVatRateContext();

  // Watching root lot computed value to force a rerender every time contract amount is updated
  useContractLotComputedValue(getInitialTree().rootNodeId);

  const {
    hasReversalOfLiability,
    customDiscounts: currentCustomDiscounts,
    discountAmountExVAT: currentDiscountAmountExVAT,
  } = useOnChangeValues(form, ['hasReversalOfLiability', 'customDiscounts', 'discountAmountExVAT']);

  const currentDiscount = useMemo(
    () => computeContractDiscount(currentDiscountAmountExVAT, mapAmountToNumber),
    [currentDiscountAmountExVAT, mapAmountToNumber],
  );

  const onAddLot = useCallback(() => {
    const { rootNodeId } = getDisplayedCurrentTree();
    const lastLotId = getLastNodeOfNode(rootNodeId);

    const newLotId = createContractLot(rootNodeId, lastLotId, NEW_LOT_DATA);
    setDefaultVATRate(newLotId, getDefaultVATRate(rootNodeId));
  }, [getDisplayedCurrentTree, getLastNodeOfNode, createContractLot, setDefaultVATRate, getDefaultVATRate]);

  const onAddItem = useCallback(() => {
    const { rootNodeId } = getDisplayedCurrentTree();
    const lastItemId = getLastLeafOfNode(rootNodeId);

    const vatRate = getDefaultVATRate(rootNodeId);
    createContractItem(rootNodeId, lastItemId, NEW_ITEM_DATA(vatRate));
  }, [getDisplayedCurrentTree, getLastLeafOfNode, getDefaultVATRate, createContractItem]);

  if (!contractInfos) {
    return null;
  }

  const canDiscountBeAdded = isNil(currentDiscountAmountExVAT) && isNil(initialDiscount);

  const computedContractValues = computeContractTotalAmount(
    getCurrentTree(),
    getComputedValues(),
    contractInfos,
    { hasReversalOfLiability: hasReversalOfLiability ?? false },
    currentDiscount,
    currentCustomDiscounts,
  );

  const { totalAmountWithoutDiscountExVAT, totalAmountWithDiscountExVAT } = computedContractValues;

  return (
    <Box mt={1}>
      <Flex justify="space-between">
        <Box display="flex" gap={2}>
          <Button variant="outline" leftIcon={<SimpleAddIcon />} onClick={onAddLot}>
            {t('contracts:lotEdition.addLot')}
          </Button>

          <Button variant="outline" leftIcon={<SimpleAddIcon />} onClick={onAddItem}>
            {t('contracts:itemEdition.addItem')}
          </Button>
        </Box>

        <ContractAddDiscountsMenu
          totalAmountWithoutDiscountExVAT={totalAmountWithoutDiscountExVAT.newAmount}
          totalAmountWithDiscountExVAT={totalAmountWithDiscountExVAT.newAmount}
          hasDiscount={!canDiscountBeAdded}
        />
      </Flex>

      <ContractSummary
        currentDiscount={currentDiscount}
        discountDrawer={discountDrawer}
        drawersStack={drawersStack}
        computedContractValues={computedContractValues}
      />
    </Box>
  );
};
