import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { KeysMatching, SingleSelectFieldProps } from '@graneet/lib-ui';
import { SingleSelectField } from '@graneet/lib-ui';
import type { FieldValues } from 'graneet-form';
import { Circle, Flex, Text } from '@chakra-ui/react';

import { useComponentTypes } from '../services/component-type.api';

type ComponentTypeFieldFieldValue = number | undefined;

interface ComponentTypeFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, ComponentTypeFieldFieldValue> = KeysMatching<T, ComponentTypeFieldFieldValue>,
  // @ts-ignore
> extends Omit<SingleSelectFieldProps<T, K>, 'options' | 'onChange'> {
  workforce?: boolean;

  material?: boolean;

  children?: ReactNode;

  disabledTypes?: number[];
}

export const ComponentTypeField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, ComponentTypeFieldFieldValue> = KeysMatching<T, ComponentTypeFieldFieldValue>,
>({
  name,
  children,
  workforce = false,
  material = false,
  disabledTypes = [],
  ...props
}: ComponentTypeFieldProps<T, K>): JSX.Element => {
  const componentTypes = useComponentTypes();

  const filteredComponentTypes = useMemo(
    () =>
      componentTypes.data
        .filter((type) => {
          const isNotDisabledType = !disabledTypes.includes(type.id);

          if (isNotDisabledType) {
            if (workforce && material) {
              return true;
            }
            if (workforce) {
              return type.isWorkforce === true;
            }
            if (material) {
              return type.isWorkforce === false;
            }
          }

          return false;
        })
        .map((type) => ({
          value: type.id,
          label: (
            <Flex gap={2} alignItems="center">
              <Circle size="0.725rem" bg={type.color} />
              <Text>{type.name}</Text>
            </Flex>
          ),
          searchTerm: type.name,
        })),
    [workforce, material, componentTypes.data, disabledTypes],
  );

  return (
    <SingleSelectField<T> name={name} options={filteredComponentTypes as any} {...props}>
      {children}
    </SingleSelectField>
  );
};
