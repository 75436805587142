import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
  Card,
  Badge,
  DocumentStatusCard,
  formatDateOrEmpty,
  mapIconToColor,
  DocumentStatusCardActions,
  formatPercentage,
  Callout,
  useToast,
} from '@graneet/lib-ui';
import { Flex, Text, useDisclosure, Link, useClipboard } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import type {
  IProgressStatement,
  IDownPayment,
  IProgressStatementValidityResponseDTO,
  IProgressStatementResponseDTO,
  IProjectWithRelations,
  IProgressStatementVersion,
  IContactWithProjectInfos,
  IGroupedContact,
} from '@graneet/business-logic';
import {
  getEmailVariableData,
  isProgressStatementInDraft,
  getProgressStatementByGroupIdOffset,
  PROGRESS_STATEMENT_STATUSES,
  STATEMENT_TYPES,
  PERMISSION,
  getProgressStatementTransitionErrors,
  isProgressStatementAccepted,
  isProgressStatementProforma,
  FEATURE_FLAGS,
} from '@graneet/business-logic';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

import {
  useProgressStatementChangeStatus,
  useProgressStatementCheckValidity,
} from '../../services/progress-statement.api';
import { AcceptProgressStatementModal } from '../../modals/AcceptProgressStatementModal';
import { CreateNewProgressStatementInDraftModal } from '../../modals/CreateNewProgressStatementInDraftModal';
import { ForceDraftProgressStatementModal } from '../../modals/ForceDraftProgressStatementModal';
import { PROGRESS_STATEMENT_STATUS_COLORS } from '../../services/progress-statement.constant';

import { getProjectDefaultEmailRecipient } from 'features/contact/services/contact.util';
import type { MailingModalProps } from 'features/mailing/components/MailingModal/MailingModal';
import { sendMail } from 'features/mailing/services/mailing.api';
import { MailingModal } from 'features/mailing/components/MailingModal/MailingModal';
import { StatementEditInvoiceNumberModal } from 'features/statement-ledger/components/StatementEditInvoiceNumberModal';
import { StatementInvalidAlert } from 'features/statement/components/StatementInvalidAlert';
import { CreditConfirmModal } from 'features/credit/components/modals/CreditConfirmModal';
import { useHasLedger } from 'features/statement-ledger/hooks/useHasLedger';
import { usePermissions } from 'features/role/hooks/usePermissions';
import {
  getProgressStatementActions,
  getProgressStatementStatusCardIcon,
} from 'features/progress-statement/services/progress-statement.actions';
import { formatFileName } from 'features/file/services/file.util';
import { getDownloadUrl, getPreviewUrl } from 'features/pdf/services/pdf.api';
import { UpdateStatementBillingDateModal } from 'features/statement/components/UpdateStatementBillingDateModal';
import { getEnvValue } from 'config/env';
import { getPublicLinkFromProgressStatement } from 'features/progress-statement/services/external-progress-statement.util';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface ProgressStatementStatusCardProps {
  formatUrl(progressStatement: Pick<IProgressStatement, 'id'>): string;
  progressStatement: IProgressStatementResponseDTO;
  currentVersion: IProgressStatementVersion | undefined;
  project: IProjectWithRelations;
  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
  notCancelledProgressStatements: IProgressStatement[];
  downPayment?: IDownPayment | null;
  nextProgressStatement?: IProgressStatement;
  onCancelConfirmed(createDraft: boolean): void;
  openDeletionModal(): void;
}

export const ProgressStatementStatusCard: FC<ProgressStatementStatusCardProps> = ({
  formatUrl,
  progressStatement,
  currentVersion,
  project,
  projectContacts,
  notCancelledProgressStatements,
  downPayment,
  nextProgressStatement,
  onCancelConfirmed,
  openDeletionModal,
}) => {
  const { t } = useTranslation(['global', 'progressStatement', 'quote', 'mailing']);
  const history = useHistory();
  const hasLedger = useHasLedger();
  const toast = useToast();
  const { onCopy } = useClipboard(
    `${getEnvValue('REACT_APP_AUTH0_RETURN_TO')}external/progress-statements/${progressStatement.externalId}`,
  );

  const hasCreatePermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);
  const hasUpdatePermission = usePermissions([PERMISSION.UPDATE_STATUS_STATEMENTS]);
  const hasForceDraftPermission = usePermissions([PERMISSION.FORCE_DRAFT_STATEMENTS]);

  const hasViralityFeature = useFeatureFlag(FEATURE_FLAGS.VIRALITY);

  const updateStatementBillingDateModal = useDisclosure();
  const invoiceNumberModal = useDisclosure();
  const confirmCancelModal = useDisclosure();
  const forceDraftModal = useDisclosure();
  const validationModal = useDisclosure();
  const editWithNewDraftModal = useDisclosure();
  const acceptModal = useDisclosure();
  const mailingModal = useDisclosure();

  const [validationStatus, setValidationStatus] = useState<IProgressStatementValidityResponseDTO>({
    isDownPaymentAmortizationValid: false,
  });

  const progressStatementChangeStatusMutation = useProgressStatementChangeStatus('progress');
  const progressStatementCheckValidityMutation = useProgressStatementCheckValidity();

  const projectId = progressStatement.project.id;

  const previousProgressStatement = getProgressStatementByGroupIdOffset(
    notCancelledProgressStatements,
    -1,
    progressStatement,
  );

  const handleDisplayPublicLink = useCallback(async () => {
    onCopy();
    toast.info(t('progressStatement:publicLink.copied'));
  }, [onCopy, toast, t]);

  /**
   * Editing invoice number
   */
  const updateInvoiceNumber = useCallback(
    (invoiceNumber: string) =>
      progressStatementChangeStatusMutation.mutateAsync({
        id: progressStatement.id,
        dto: { invoiceNumber, status: PROGRESS_STATEMENT_STATUSES.VALIDATED },
      }),
    [progressStatement.id, progressStatementChangeStatusMutation],
  );

  /**
   * Validating
   */
  const checkProgressStatement = useCallback(async () => {
    const validationResponse = await progressStatementCheckValidityMutation.mutateAsync(progressStatement.id);

    if (!Object.values(validationResponse).every(Boolean)) {
      setValidationStatus(validationResponse);
      validationModal.onOpen();
      return false;
    }

    return true;
  }, [progressStatementCheckValidityMutation, progressStatement.id, validationModal]);

  const validateProgressStatement = useCallback(
    async (billingDate?: string) => {
      const isValid = await checkProgressStatement();
      if (!isValid) {
        return;
      }

      await progressStatementChangeStatusMutation.mutateAsync({
        id: progressStatement.id,
        dto: { status: PROGRESS_STATEMENT_STATUSES.VALIDATED, billingDate },
      });
    },
    [checkProgressStatement, progressStatementChangeStatusMutation, progressStatement.id],
  );

  const onValidateButtonClicked = useCallback(async () => {
    if (!hasLedger && !progressStatement.invoiceNumber) {
      invoiceNumberModal.onOpen();
      return;
    }

    if (
      hasLedger &&
      !progressStatement.invoiceNumber &&
      dayjs(progressStatement.billingDate).isBefore(dayjs(new Date()), 'day')
    ) {
      updateStatementBillingDateModal.onOpen();
      return;
    }

    await validateProgressStatement();
  }, [
    hasLedger,
    progressStatement.invoiceNumber,
    progressStatement.billingDate,
    invoiceNumberModal,
    validateProgressStatement,
    updateStatementBillingDateModal,
  ]);

  /**
   * Editing
   */
  const goToEdit = useCallback(
    (ps: IProgressStatement) => {
      history.push(
        `/projects/${projectId}/statements/sub-projects/${ps.subProject?.id}/progress-statements/${ps.id}/edit`,
        {
          referrerUrl: formatUrl(ps),
        },
      );
    },
    [history, projectId, formatUrl],
  );

  const goToEditCurrentProgressStatement = useCallback(
    () => goToEdit(progressStatement),
    [goToEdit, progressStatement],
  );

  const statusCardIcon = useMemo(
    () => getProgressStatementStatusCardIcon(progressStatement.status),
    [progressStatement.status],
  );

  /*
   * PDF
   */
  const onViewPdf = useCallback(() => {
    if (currentVersion?.pdf) {
      window.open(getPreviewUrl(currentVersion.pdf.apiId));
    }
  }, [currentVersion]);

  const onDownloadPdf = useCallback(() => {
    if (currentVersion?.pdf) {
      window.open(
        getDownloadUrl(
          currentVersion.pdf.apiId,
          formatFileName(currentVersion.name, currentVersion.billingDate, currentVersion.invoiceNumber),
        ),
      );
    }
  }, [currentVersion]);

  const onMailSubmit = useCallback<MailingModalProps['onSubmit']>(
    (data) => sendMail(data.dto, data.attachments, data.mode),
    [],
  );

  const footerText = useMemo(() => {
    if (!currentVersion?.pdf) {
      return isProgressStatementProforma(progressStatement)
        ? t(`progressStatement:versions.invalidProforma`)
        : t(`progressStatement:versions.waiting`);
    }
    return undefined;
  }, [currentVersion?.pdf, progressStatement, t]);

  const isApproved = progressStatement.externalValidation?.isApproved;

  return (
    <Card title={t('progressStatement:titles.state')} flex={1}>
      <DocumentStatusCard
        mb={4}
        icon={statusCardIcon}
        title={progressStatement.name}
        subtitle={t('quote:fields.creationDate', {
          date: formatDateOrEmpty(progressStatement.createdAt, 'LL'),
        })}
        footer={
          <>
            <Text color="black" fontSize=".8em" fontWeight={500}>
              {t('progressStatement:percentageCompletion', {
                value: formatPercentage(progressStatement.cumulativeProgressPercentage),
              })}
            </Text>
            <DocumentStatusCard.Progress
              chakraColor={mapIconToColor(statusCardIcon)}
              value={progressStatement.cumulativeProgressPercentage ?? 0}
            />

            {footerText ? (
              <Text mt={2} color="black" fontSize=".8em">
                {footerText}
              </Text>
            ) : (
              <></>
            )}
          </>
        }
        badgeComponent={
          <Badge colorScheme={PROGRESS_STATEMENT_STATUS_COLORS[progressStatement.status]}>
            {t(`progressStatement:statuses.${progressStatement.status}`)}
          </Badge>
        }
      />

      {progressStatement.externalValidation && (
        <Callout mb={4} colorScheme={isApproved ? 'green' : 'red'} icon={isApproved ? <CheckIcon /> : <CloseIcon />}>
          <Flex direction="column" gap={2}>
            <Text fontSize="sm">
              <Trans
                t={t}
                i18nKey={`progressStatement:externalValidation.${isApproved ? 'approved' : 'rejected'}`}
                values={{
                  name: progressStatement.externalValidation.name,
                  date: formatDateOrEmpty(progressStatement.externalValidation.createdAt),
                }}
              >
                <Link href={`mailto:${progressStatement.externalValidation.email}`}>name</Link>
              </Trans>
            </Text>
            {!isApproved && progressStatement.externalComment && (
              <Text fontSize="sm" color="black" whiteSpace="pre-line">
                {progressStatement.externalComment}
              </Text>
            )}
          </Flex>
        </Callout>
      )}

      <DocumentStatusCardActions
        actions={getProgressStatementActions({
          progressStatement,
          pdf: currentVersion?.pdf,
          isLastStatement: !nextProgressStatement,
          previousProgressStatementName: previousProgressStatement?.name,

          hasViralityFeature,

          getTransitionErrors: (status, isCancelling, withCredit) =>
            getProgressStatementTransitionErrors(status, progressStatement, {
              previousProgressStatement,
              nextProgressStatement,
              downPayment,
              project,
              subProject: progressStatement.subProject,
              ...(isCancelling && {
                latestAcceptedProgressStatement: getProgressStatementByGroupIdOffset(
                  notCancelledProgressStatements.filter(isProgressStatementAccepted),
                  0,
                ),
                hasPaymentAccountingExported: progressStatement.hasPaymentAccountingExported,
                isAccountingExported: progressStatement.isAccountingExported,
                withCredit,
              }),
            }),

          isValidateButtonLoading: progressStatementChangeStatusMutation.isPending,

          hasCreatePermission,
          hasUpdatePermission,
          hasForceDraftPermission,

          onViewPdf,
          onDownloadPdf,
          onEdit: isProgressStatementInDraft(progressStatement)
            ? goToEditCurrentProgressStatement
            : editWithNewDraftModal.onOpen,
          onOpenMailing: mailingModal.onOpen,
          onSetStatusToAccepted: acceptModal.onOpen,
          onSetStatusToValidated: onValidateButtonClicked,
          onCancelWithCredit: confirmCancelModal.onOpen,
          onCancelWithoutCredit: forceDraftModal.onOpen,
          onDelete: openDeletionModal,
          onDisplayPublicLink: handleDisplayPublicLink,
        })}
      />

      <StatementInvalidAlert
        {...validationModal}
        onEditingConfirmed={goToEditCurrentProgressStatement}
        statementValidationDTO={validationStatus}
      />
      <AcceptProgressStatementModal
        progressStatement={progressStatement}
        isOpen={acceptModal.isOpen}
        onClose={acceptModal.onClose}
      />

      <CreateNewProgressStatementInDraftModal
        progressStatement={progressStatement}
        nextProgressStatement={nextProgressStatement}
        isOpen={editWithNewDraftModal.isOpen}
        onClose={editWithNewDraftModal.onClose}
        onSuccess={goToEdit}
      />

      <StatementEditInvoiceNumberModal
        {...invoiceNumberModal}
        title={t('progressStatement:modals.validate.title')}
        completeLabel={t('progressStatement:actions.validate')}
        statementType={STATEMENT_TYPES.PROGRESS_STATEMENT}
        statementId={progressStatement.id}
        updateInvoiceNumber={updateInvoiceNumber}
      />

      <UpdateStatementBillingDateModal
        {...updateStatementBillingDateModal}
        currentBillingDate={progressStatement.billingDate}
        validateStatement={validateProgressStatement}
      />

      <CreditConfirmModal modal={confirmCancelModal} onConfirmed={onCancelConfirmed} />
      <ForceDraftProgressStatementModal
        modal={forceDraftModal}
        progressStatement={progressStatement}
        nextProgressStatement={nextProgressStatement}
        onConfirmed={goToEdit}
      />
      {currentVersion?.pdf && (
        <MailingModal
          key={JSON.stringify(currentVersion.pdf)} // Refresh modal when pdf is updated
          title={t('mailing:modal.title.progressStatement')}
          isOpen={mailingModal.isOpen}
          onClose={mailingModal.onClose}
          onSubmit={onMailSubmit}
          variableData={getEmailVariableData('progressStatement', {
            progressStatement,
            client: project.primaryClient,
            project,
            publicLink: getPublicLinkFromProgressStatement(progressStatement),
          })}
          defaultMailContacts={getProjectDefaultEmailRecipient(projectContacts)}
        />
      )}
    </Card>
  );
};
