import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { useQuotationProxyApis } from '../quote-common/hooks/useQuoteProxyApis';

export const useQuotationAutoCompleteApi = () => {
  const quoteProxy = useQuotationProxyApis().quoteAutoCompleteProxyApi;
  const queryClient = useQueryClient();

  // We cannot add quoteProxy to the dependencies array because it will break query with Tanstack
  const quotesAutoComplete = createQueryKeys('quotation-autocomplete', {
    search: (id: string, search: string, onlyComponents: boolean) => ({
      // eslint-disable-next-line @tanstack/query/exhaustive-deps
      queryKey: ['quotes-autocomplete', id],
      queryFn: () => quoteProxy!.search(id, search, onlyComponents),
    }),
  });

  const autoCompleteItems = useCallback(
    (quoteId: string, search: string, onlyComponents: boolean) =>
      queryClient.fetchQuery(quotesAutoComplete.search(quoteId, search, onlyComponents)),
    [queryClient, quotesAutoComplete],
  );

  return useMemo(
    () => ({
      autoCompleteItems,
    }),
    [autoCompleteItems],
  );
};
