/* eslint @typescript-eslint/no-use-before-define: 0 */
/* eslint no-underscore-dangle: 0 */
import {
  $applyNodeReplacement,
  $getSelection,
  $insertNodes,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  type TextModeType,
  type LexicalEditor,
  TextNode,
} from 'lexical';

import type { SerializedVariableNode } from './VariableNode';
import { VariableNode } from './VariableNode';

function convertReplacedVariableElement(domNode: HTMLElement): DOMConversionOutput | null {
  const { textContent } = domNode;

  if (textContent !== null) {
    const node = $createReplacedVariableNode({
      text: textContent,
      value: textContent,
    });
    return {
      node,
    };
  }

  return null;
}

/*
  Replace VariableNode by a simple text similar to TextNode
 */
export class ReplacedVariableNode extends VariableNode {
  static getType(): string {
    return 'replaced_variable';
  }

  static clone(node: VariableNode): VariableNode {
    return new ReplacedVariableNode(
      {
        text: node.__text,
        value: node.__value,
      },
      node.__key,
    );
  }

  static importJSON(serializedNode: SerializedVariableNode): ReplacedVariableNode {
    const node = $createReplacedVariableNode({
      text: serializedNode.text,
      value: serializedNode.value,
    });
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedVariableNode {
    return {
      ...super.exportJSON(),
      text: this.__text,
      value: this.__value,
      type: 'replaced_variable',
      version: 1,
    };
  }

  createDOM(config: EditorConfig, editor?: LexicalEditor): HTMLElement {
    const dom = super.createDOM(config, editor, { useValue: true });
    dom.className = '';
    dom.removeAttribute('id');
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.textContent = this.__text;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-variable')) {
          return null;
        }
        return {
          conversion: convertReplacedVariableElement,
          priority: 1,
        };
      },
    };
  }
}

export function $createInjectedVariableNode(
  variable: { text: string; value: string },
  options:
    | {
        format: number;
        detail: number;
        mode: TextModeType;
        style: string;
      }
    | undefined,
): VariableNode {
  const variableNode = new ReplacedVariableNode(variable);
  variableNode.setMode('segmented').toggleDirectionless();

  if (options) {
    variableNode.setFormat(options.format);
    variableNode.setDetail(options.detail);
    variableNode.setMode(options.mode);
    variableNode.setStyle(options.style);
  }

  return variableNode;
}

export function $createReplacedVariableNode(variable: { text: string; value: string }): ReplacedVariableNode {
  const replacedVariableNode = new ReplacedVariableNode(variable);
  replacedVariableNode.setMode('segmented').toggleDirectionless();
  return $applyNodeReplacement(replacedVariableNode);
}

export function $injectVariableNode(variable: { value: string }, editor: LexicalEditor) {
  const optionText = editor._config.theme.variables.options.optionsText[variable.value];
  const Node = optionText.value ? ReplacedVariableNode : VariableNode;

  const newNode = new Node({ text: optionText.value || optionText.label, value: variable.value });

  const nodes = $getSelection()?.getNodes();
  const lastNode = nodes ? nodes[nodes.length - 1] : null;

  // Keep the node style
  if (lastNode instanceof TextNode) {
    newNode.setFormat(lastNode.getFormat());
    newNode.setDetail(lastNode.getDetail());
    newNode.setMode(lastNode.getMode());
    newNode.setStyle(lastNode.getStyle());
  }

  $insertNodes([newNode]);
}
