import type { FC, MouseEventHandler } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PaginatedTableProps, PaginationQuery } from '@graneet/lib-ui';
import { formatDataOrEmpty, PaginatedTable, Button, PriceAdvanced, Badge, EMPTY_SIGN } from '@graneet/lib-ui';
import type { HoldbackPaginatedResponse, IHoldback } from '@graneet/business-logic';
import { FEATURE_FLAGS, HOLDBACK_STATUSES, PERMISSION } from '@graneet/business-logic';

import { HOLDBACK_STATUSES_STYLE } from '../constants/holdback.constant';

import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface HoldbackMarkAsReceivedModalProps {
  holdbacks: PaginationQuery<HoldbackPaginatedResponse>;

  openModal(holdback: IHoldback): MouseEventHandler<HTMLButtonElement>;
}

const HoldbackAmountIncVATCell = ({ data }: { data?: IHoldback }) =>
  data ? <PriceAdvanced amount={data.holdbackAmountIncVAT} /> : null;

const holdbackWithPaymentGuaranteeCell = ({ data }: { data?: IHoldback }) =>
  data && data.hasPaymentGuarantee ? <PriceAdvanced amount={data.paymentGuaranteeAmount} /> : '-';

const StatusCell = ({ data }: { data?: IHoldback }) => {
  const { t } = useTranslation(['holdback']);

  return data ? (
    <Badge colorScheme={HOLDBACK_STATUSES_STYLE[data.status].variantColor}>
      {t(`holdback:statuses.${data.status}`)}
    </Badge>
  ) : null;
};

const RemainingDaysCell = ({ data }: { data?: IHoldback }) => {
  const { t } = useTranslation(['global']);

  if (!data?.remainingDays) {
    return EMPTY_SIGN;
  }

  return (
    <Badge showDot colorScheme={HOLDBACK_STATUSES_STYLE[data.status].variantColor}>
      {`${data.remainingDays} ${t('global:words.day', { count: data.remainingDays })}`}
    </Badge>
  );
};

export const HoldbackTable: FC<HoldbackMarkAsReceivedModalProps> = ({ holdbacks, openModal }) => {
  const { t } = useTranslation(['global', 'holdback']);

  const hasAccountingStandards = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARDS);
  const hasCreateHoldbackPermission = usePermissions([PERMISSION.PAY_HOLDBACKS]);
  const hasProjectGlobalParametersFeatureFlag = useFeatureFlag(FEATURE_FLAGS.PROJECT_GLOBAL_PARAMETERS);

  const { createRedirectionWithFilters } = useFiltersQuery();

  const receiveCell = useCallback(
    ({ data }: { data?: IHoldback }) =>
      data && HOLDBACK_STATUSES.TO_BE_RECEIVED === data.status && hasCreateHoldbackPermission ? (
        <Button onClick={openModal(data)} variant="outline">
          {t('holdback:toReceive')}
        </Button>
      ) : null,
    [hasCreateHoldbackPermission, openModal, t],
  );

  const columnDefs = useMemo<PaginatedTableProps<HoldbackPaginatedResponse>['columnDefs']>(() => {
    const columns: PaginatedTableProps<HoldbackPaginatedResponse>['columnDefs'] = [
      {
        field: 'projectRefCode',
        sortable: true,
        headerName: t('holdback:fields.project.refCode'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.subProject?.project?.refCode),
      },
      {
        field: 'projectName',
        sortable: true,
        width: 200,
        headerName: t('holdback:fields.project.name'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.subProject?.project?.name),
      },
      {
        field: 'holdbackAmountIncVAT',
        sortable: true,
        headerName: t('holdback:fields.holdbackAmountIncVAT'),
        cellRenderer: HoldbackAmountIncVATCell,
      },
      {
        field: 'hasPaymentGuarantee',
        sortable: true,
        headerName: t('holdback:fields.paymentGuarantee'),
        ...(hasProjectGlobalParametersFeatureFlag
          ? { cellRenderer: holdbackWithPaymentGuaranteeCell }
          : {
              valueFormatter: (v) => (v.data?.hasPaymentGuarantee ? t('global:words.c.yes') : t('global:words.c.no')),
            }),
      },
      {
        field: 'status',
        sortable: true,
        headerName: t('global:words.c.status'),
        cellRenderer: StatusCell,
      },
      {
        field: 'remainingDays',
        sortable: true,
        headerName: t('holdback:fields.remainingDays'),
        cellRenderer: RemainingDaysCell,
      },
    ];

    // 'hide' attribute isn't working, so we use 'push' and 'spread' to hide
    if (!hasAccountingStandards) {
      columns.push({
        field: 'receive' as any,
        width: 20,
        suppressMovable: true,
        lockPosition: 'right',
        headerName: '',
        cellRenderer: receiveCell,
      });
    }

    return columns;
  }, [hasAccountingStandards, hasProjectGlobalParametersFeatureFlag, receiveCell, t]);

  return (
    <PaginatedTable
      gridId="holdback"
      pagination={holdbacks}
      columnDefs={columnDefs}
      onRowClicked={(e) => {
        createRedirectionWithFilters(`/sales/holdbacks/${e.data?.id}`)();
      }}
      countLabel={(count) => t('holdback:holdback', { count })}
      sums={{
        holdbackAmountIncVAT: {
          label: t('holdback:fields.holdbackAmountIncVAT'),
          type: 'currency',
        },
      }}
      zeroState={{
        icon: <i className="ri-cash-line" />,
        label: t('holdback:noHoldback'),
      }}
      emptyState={{
        label: t('holdback:noResult'),
      }}
    />
  );
};
