import { useMemo, type FC, useCallback } from 'react';
import { Box, Flex, GridItem, Text } from '@chakra-ui/react';
import { useFormContext } from 'graneet-form';
import { every, isObject, uniqWith } from 'lodash-es';
import dayjs from 'dayjs';

import { getLocalDateFormat } from '../../../utils/date.util';
import { usePaginatedDataContext } from '../../PaginatedData/hooks';
import { Badge } from '../../Badge/Badge';
import type { Pagination } from '../../PaginatedTable/Pagination';
import { SEARCH_QUERY } from '../../PaginatedData/constants/filters-and-pagination.constant';
import { useCurrency } from '../../Currency/CurrencyProvider';
import { isNumberFinite } from '../../../utils/number.util';

import { listingLayoutTranslations } from './configureDefaultLabel';

export type FilterApplied = {
  label: string;
  type: 'date' | 'bool' | 'multi' | 'checkbox-range' | 'checkbox' | 'single' | 'amount';
  availableValues?: {
    label: string;
    value: string | { min?: number; max?: number };
  }[];
};
export interface ListingLayoutFiltersAppliedProps {
  filters: Record<string, FilterApplied>;

  pagination?: Pagination;
}

const AppliedFilter: FC<{
  filter: FilterApplied;
  pagination?: Pagination;
  value:
    | string
    | string[]
    | {
        startDate: string | null;
        endDate: string | null;
      }
    | {
        min: number;
        max: number;
      }[]
    | null;
  name: string;
}> = ({ filter, value, name, pagination }) => {
  const paginatedDataContext = usePaginatedDataContext();
  const updateFilters = pagination ? pagination.updateFilters : paginatedDataContext.updateFilters;

  const form = useFormContext();

  const { setFormValues, getFormValues } = form;

  const { formatAsAmount } = useCurrency();

  const handleClear = useCallback(() => {
    updateFilters({ ...getFormValues(), [name]: undefined });
    setFormValues({ [name]: undefined });
  }, [getFormValues, name, setFormValues, updateFilters]);

  const displayValue = useMemo((): string => {
    if (!value) return '';
    if (filter.type === 'amount') {
      if (Array.isArray(value) && typeof value[0] === 'object') {
        if (isNumberFinite(value[0].min) && !value[0].max) return `≥ ${formatAsAmount(value[0].min)}`;
        if (isNumberFinite(value[0].max) && !value[0].min) return `≤ ${formatAsAmount(value[0].max)}`;
        return `${formatAsAmount(value[0].min)} - ${formatAsAmount(value[0].max)}`;
      }
    }

    if (filter.type === 'checkbox') {
      if (Array.isArray(value)) {
        return (
          filter.availableValues
            ?.filter((v) => value.some((val) => JSON.stringify(val) === JSON.stringify(v.value)))
            .map((v) => v.label)
            .join(', ') ?? ''
        );
      }
    }

    if (filter.type === 'checkbox-range') {
      if (Array.isArray(value) && typeof value[0] === 'object') {
        if (isNumberFinite(value[0].min) && !value[0].max) return `≥ ${value[0].min}`;
        if (isNumberFinite(value[0].max) && !value[0].min) return `≤ ${value[0].max}`;
        return `${value[0].min} - ${value[0].max}`;
      }
    }

    if (filter.type === 'multi') {
      if (Array.isArray(value)) {
        return (
          filter.availableValues
            ?.filter((f) =>
              Array.isArray(f.value)
                ? every(f.value ?? [], (fv) => value.includes(fv))
                : value.includes(f.value as any),
            )
            ?.map((f) => f.label)
            .join(', ') ?? ''
        );
      }
      return (
        filter.availableValues?.find((f) => (Array.isArray(f.value) ? every(f.value, value) : f.value === value))
          ?.label || (value as string)
      );
    }
    if (filter.type === 'date' && !Array.isArray(value)) {
      const format = getLocalDateFormat();

      if (isObject(value)) {
        const start = dayjs(value?.startDate).format(format);
        const end = dayjs(value?.endDate).format(format);
        if (value.startDate && !value.endDate) return `≥ ${start}`;
        if (value.endDate && !value.startDate) return `≤ ${end}`;
        return `${value?.startDate} - ${value?.endDate}`;
      }
    }

    return `${value}`;
  }, [filter.availableValues, filter.type, formatAsAmount, value]);

  return (
    <Badge colorScheme="white">
      <Flex gap={1}>
        <Text color="gray.500" fontSize="12px">
          {filter.label} :
        </Text>
        <Text color="primaryLight" fontSize="12px">
          {displayValue}
        </Text>
        <Box cursor="pointer" onClick={handleClear}>
          <i className="ri-close-line" />
        </Box>
      </Flex>
    </Badge>
  );
};

export const ListingLayoutFiltersApplied: FC<ListingLayoutFiltersAppliedProps> = ({ filters, pagination }) => {
  const paginatedDataContext = usePaginatedDataContext();
  const storage = pagination ? new URLSearchParams(pagination?.queryParams) : paginatedDataContext.storage;

  const newFilters = uniqWith(Array.from(storage.keys()), (a, b) => {
    const aKey = a.replace(/\[(.*)\]/, '');
    const bKey = b.replace(/\[(.*)\]/, '');
    return aKey === bKey;
  }).filter((v) => v !== SEARCH_QUERY);

  if (newFilters.length === 0) {
    return null;
  }

  return (
    <GridItem colSpan={3} flex={1}>
      <Flex gap={2} px={3} alignItems="baseline">
        {newFilters.map((key) => {
          const replaceKey = key.replace(/\[(.*)\]/, '');
          const filter = filters[replaceKey];
          if (!filter) return null;
          let value;
          if (filter.type === 'single') {
            value = storage.get(`${replaceKey}`);
          }
          if (filter.type === 'date') {
            value = {
              startDate: storage.get(`${replaceKey}[startDate]`),
              endDate: storage.get(`${replaceKey}[endDate]`),
            };
          }
          if (filter.type === 'multi' || filter.type === 'checkbox') {
            value = storage.getAll(replaceKey);
          }

          if (filter.type === 'amount') {
            const min = storage.get(`${replaceKey}[0][min]`);
            const max = storage.get(`${replaceKey}[0][max]`);

            value = [
              {
                min: min ? parseInt(min, 10) : null,
                max: max ? parseInt(max, 10) : null,
              },
            ];
          }

          if (filter.type === 'checkbox-range') {
            value = [];
            filter.availableValues?.forEach((_, index) => {
              const min = storage.get(`${replaceKey}[${index}][min]`);
              const max = storage.get(`${replaceKey}[${index}][max]`);
              if (min || max) {
                value.push({
                  min: min ? parseInt(min, 10) : null,
                  max: max ? parseInt(max, 10) : null,
                });
              }
            });
          }

          if (!value) return null;

          return <AppliedFilter key={key} filter={filter} value={value} name={replaceKey} pagination={pagination} />;
        })}

        {pagination && (
          <Text
            color="greenBrand.light"
            onClick={pagination.resetFilters}
            cursor="pointer"
            fontWeight={600}
            fontSize="sm"
          >
            {listingLayoutTranslations.filters.removeAll}
          </Text>
        )}
      </Flex>
    </GridItem>
  );
};
