import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useSuspenseQuery } from '@tanstack/react-query';
import type {
  IBankingAccountBalancesStatsDTO,
  IBankingInOutTransactionsStatsDTO,
  IBankingInOutTransactionsStatsDTOResponse,
  IBankingTransactionAccountBalancesStatsResponse,
} from '@graneet/business-logic';

import { apiNew } from 'features/api/services/apiNew.service';

const BANKING_ACCOUNT_PATH = '/banking-accounts';

const bankingAccountKeyFactory = createQueryKeys('banking-account', {
  getAccountBalanceStats: (dto: IBankingAccountBalancesStatsDTO) => ({
    queryKey: [BANKING_ACCOUNT_PATH, dto],
    queryFn: () =>
      apiNew.post<IBankingAccountBalancesStatsDTO, IBankingTransactionAccountBalancesStatsResponse>(
        `${BANKING_ACCOUNT_PATH}/balances-stats`,
        dto,
      ),
  }),
  getInOutTransactionStats: (options: IBankingInOutTransactionsStatsDTO) => ({
    queryKey: [BANKING_ACCOUNT_PATH, options],
    queryFn: () =>
      apiNew.post<IBankingInOutTransactionsStatsDTO, IBankingInOutTransactionsStatsDTOResponse>(
        `${BANKING_ACCOUNT_PATH}/in-out-transactions-stats`,
        options,
      ),
  }),
});

export function useBankingAccountBalanceStats(dto: IBankingAccountBalancesStatsDTO) {
  return useSuspenseQuery(bankingAccountKeyFactory.getAccountBalanceStats(dto));
}

export function useBankingInOutTransactionStats(options: IBankingInOutTransactionsStatsDTO) {
  return useSuspenseQuery(bankingAccountKeyFactory.getInOutTransactionStats(options));
}
