import { GraneetButton } from '@graneet/lib-ui';
import { Box, Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import type { FC } from 'react';

interface HotKeysDrawerNavigationHeaderProps {
  onChangeFocusClicked: (offset: -1 | 1) => void;
}

export const HotKeysDrawerNavigationHeader: FC<HotKeysDrawerNavigationHeaderProps> = ({ onChangeFocusClicked }) => {
  const { t } = useTranslation(['global']);
  return (
    <Flex alignItems="center" justifyContent="center">
      <GraneetButton variant="ghost" onClick={() => onChangeFocusClicked(-1)}>
        <i className="ri-arrow-up-s-line ri-sm" />
      </GraneetButton>
      <GraneetButton variant="ghost" onClick={() => onChangeFocusClicked(1)}>
        <i className="ri-arrow-down-s-line ri-sm" />
      </GraneetButton>

      <Box w="100%" textAlign="center" fontSize="0.75rem" fontWeight={400}>
        <Trans t={t} i18nKey="global:navigation">
          <i className="ri-arrow-up-down-line" />
        </Trans>
      </Box>
    </Flex>
  );
};
