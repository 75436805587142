import type { IQuoteComponent } from '@graneet/business-logic';

export const COMPONENT_FIELDS = {
  COMPONENT_TYPE_ID: 'componentTypeId',
  CODE: 'code',
  NAME: 'name',
  UNIT: 'unit',
  QUANTITY: 'quantity',
  UNIT_DISBURSEMENT_EX_VAT: 'unitDisbursementExVAT',
  AMOUNT_EX_VAT: 'amountExVAT',
  TOTAL_MARGIN: 'totalMargin',
};

export const sortComponents = (components: IQuoteComponent[]) =>
  components
    // copy
    .slice()
    .sort((c1, c2) => {
      const c1Name = c1.componentType?.name || '';
      const c2Name = c2.componentType?.name || '';

      // Group components by componentType name
      return c1Name.localeCompare(c2Name) || c1.id - c2.id;
    });
