import { createContext, useCallback, useContext, useMemo, useState } from 'react';

const THROW_ERROR = () => {
  throw new Error('No DrawersStackContext found');
};

export interface DrawersStackContextApi<Handle extends string> {
  pushDrawer(handle: Handle): void;
  popDrawer(handle: Handle): void;
  getIndex(handle: Handle): number;
  getOffsetIndex(handle: Handle): number;
  getNumberOfActiveDrawers(handle: Handle): number;
}
// Private
export const DrawersStackContext = createContext<DrawersStackContextApi<string>>({
  pushDrawer: THROW_ERROR,
  popDrawer: THROW_ERROR,
  getIndex: THROW_ERROR,
  getOffsetIndex: THROW_ERROR,
  getNumberOfActiveDrawers: THROW_ERROR,
});

// Private
export const useDrawersStackContext = () => useContext(DrawersStackContext);

// Public
export const useDrawersStack = <Handle extends string>() => {
  const [handles, setHandles] = useState<Handle[]>([]);

  const pushDrawer = useCallback(
    (handle: Handle) => {
      if (handles.includes(handle)) {
        throw new Error(`Drawer handle "${handle}" already exist`);
      }
      setHandles((prevHandles) => [...prevHandles, handle]);
    },
    [handles],
  );

  const popDrawer = useCallback(
    (handle: Handle) => {
      setHandles(handles.filter((currentHandle) => currentHandle !== handle));
    },
    [handles],
  );

  const getIndex = useCallback((handle: Handle) => handles.indexOf(handle), [handles]);

  const getOffsetIndex = useCallback((handle: Handle) => handles.length - 1 - getIndex(handle), [handles, getIndex]);

  const getNumberOfActiveDrawers = useCallback(() => handles.length, [handles]);

  return useMemo<DrawersStackContextApi<Handle>>(
    () => ({
      pushDrawer,
      popDrawer,
      getIndex,
      getOffsetIndex,
      getNumberOfActiveDrawers,
    }),
    [pushDrawer, popDrawer, getIndex, getOffsetIndex, getNumberOfActiveDrawers],
  );
};
