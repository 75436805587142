import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

export interface IMultiLevelMenuContext {
  menuStates: Record<number, boolean>;
  subMenuStates: Record<string, boolean>;
  handleMenuStateChange: (level: number, open: boolean, id?: string) => void;
  hasSubMenuOpen: (level: number) => boolean;
  closeSubMenus: (level: number) => void;
  setPlacement: (placement: 'right' | 'left') => void;
  placement?: 'right' | 'left';
  position: { x: number; y: number } | null;
  setPosition: (position: { x: number; y: number } | null) => void;
  setMenuLevelAndIds: (record: Record<number, string[]>) => void;
}

export const MultiLevelMenuContext = createContext<IMultiLevelMenuContext>({
  handleMenuStateChange: () => {},
  hasSubMenuOpen: () => false,
  closeSubMenus: () => {},
  menuStates: {},
  subMenuStates: {},
  setPlacement: () => {},
  placement: 'right',
  position: null,
  setPosition: () => {},
  setMenuLevelAndIds: () => {},
});

export const useMultiLevelMenu = () => useContext(MultiLevelMenuContext);

export const MultiLevelMenuProvider: FC<PropsWithChildren> = ({ children }) => {
  const [menuStates, setMenuStates] = useState<Record<number, boolean>>({});
  const [subMenuStates, setSubMenuStates] = useState<Record<string, boolean>>({});
  const [menuLevelIds, setMenuLevelIds] = useState<Record<number, string[]>>({});
  const [placement, setPlacement] = useState<'right' | 'left'>('right');
  const [position, setPosition] = useState<{ x: number; y: number } | null>(null);

  const maxDepth = useMemo(() => {
    let max = 0;
    Object.keys(menuStates).forEach((key) => {
      const num = parseInt(key, 10);
      if (num > max) max = num;
    });
    return max;
  }, [menuStates]);

  const handleMenuStateChange = useCallback(
    (level: number, open: boolean, id?: string) => {
      setMenuStates((prev) => ({
        ...prev,
        [level]: open,
      }));
      if (!id) return;
      if (open) {
        const sameLevelIds = menuLevelIds[level];
        const newSubMenuStates: Record<string, boolean> = {};
        sameLevelIds.forEach((i) => {
          if (i !== id) newSubMenuStates[i] = false;
          if (i === id) newSubMenuStates[i] = true;
        });
        setSubMenuStates((prev) => ({ ...prev, ...newSubMenuStates, [id]: open }));
      } else {
        setSubMenuStates((prev) => ({ ...prev, [id]: open }));
      }
    },
    [menuLevelIds],
  );

  const hasSubMenuOpen = useCallback(
    (level: number): boolean => {
      // eslint-disable-next-line no-plusplus
      for (let i = level; i <= maxDepth; i++) {
        if (menuStates[i]) return true;
      }
      return false;
    },
    [menuStates, maxDepth],
  );

  const closeSubMenus = useCallback(
    (level: number): void => {
      const newMenusStates: Record<number, boolean> = {};
      const newSubMenuStates: Record<string, boolean> = {};
      // eslint-disable-next-line no-plusplus
      for (let i = level; i <= maxDepth; i++) {
        newMenusStates[i] = false;
        if (menuLevelIds[i]) {
          menuLevelIds[i].forEach((id) => {
            newSubMenuStates[id] = false;
          });
        }
      }
      setMenuStates((prev) => ({ ...prev, ...newMenusStates }));
      setSubMenuStates((prev) => ({ ...prev, ...newSubMenuStates }));
    },
    [maxDepth, menuLevelIds],
  );

  const setMenuLevelAndIds = useCallback((record: Record<number, string[]>) => {
    setMenuLevelIds(record);
  }, []);

  const ctx = useMemo(
    () => ({
      menuStates,
      subMenuStates,
      handleMenuStateChange,
      hasSubMenuOpen,
      closeSubMenus,
      placement,
      setPlacement,
      position,
      setPosition,
      setMenuLevelAndIds,
    }),
    [
      handleMenuStateChange,
      menuStates,
      subMenuStates,
      hasSubMenuOpen,
      closeSubMenus,
      placement,
      setPlacement,
      position,
      setPosition,
      setMenuLevelAndIds,
    ],
  );

  return <MultiLevelMenuContext.Provider value={ctx}>{children}</MultiLevelMenuContext.Provider>;
};
