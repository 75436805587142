import type { FC } from 'react';
import type { EmailTemplateType } from '@graneet/business-logic';
import type { ButtonColorScheme } from '@graneet/lib-ui';
import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

interface EmailTemplateTypeBadgeProps {
  type: EmailTemplateType;
}

const colorMap: Record<EmailTemplateType, ButtonColorScheme> = {
  quote: 'purple',
  statement: 'green',
  order: 'orange',
};

export const EmailTemplateTypeBadge: FC<EmailTemplateTypeBadgeProps> = ({ type }) => {
  const { t } = useTranslation(['mailing']);

  return (
    <Badge showDot colorScheme={colorMap[type]}>
      {t(`mailing:emailTemplateType.${type}`)}
    </Badge>
  );
};
