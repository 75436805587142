import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { Callout, Modal, SimpleAlertIcon, useCurrency } from '@graneet/lib-ui';
import type { ISupplierInvoice } from '@graneet/business-logic';

import { useDirectPaymentContext } from '../../../contexts/DirectPaymentContext';

import { AssociateSupplierInvoiceTable } from './AssociateSupplierInvoiceTable';

type AssociateSupplierInvoiceModalProps = {
  modal: UseDisclosureReturn;

  onSubmit(supplierInvoice: ISupplierInvoice): void;
};

export const AssociateSupplierInvoiceModal: FC<AssociateSupplierInvoiceModalProps> = ({ modal, onSubmit }) => {
  const { t } = useTranslation(['supplierInvoices']);
  const { formatAsAmount } = useCurrency();

  const [selectedSupplierInvoice, setSelectedSupplierInvoice] = useState<null | ISupplierInvoice>(null);
  const onRowSelected = useCallback((supplierInvoice: ISupplierInvoice | null) => {
    setSelectedSupplierInvoice(supplierInvoice);
  }, []);

  const onConfirmClick = useCallback(() => {
    if (selectedSupplierInvoice) {
      onSubmit(selectedSupplierInvoice);
    }
  }, [onSubmit, selectedSupplierInvoice]);

  const { selectedOrder } = useDirectPaymentContext();
  const { amountExVAT: orderAmountExVAT = 0, amountPreviousDirectPaymentsExVAT = 0 } = selectedOrder || {};
  const orderAmountToBeBilledExVAT = (orderAmountExVAT || 0) - amountPreviousDirectPaymentsExVAT;
  const isCalloutDisplayed = (selectedSupplierInvoice?.amountExVAT ?? 0) > orderAmountToBeBilledExVAT;

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      scrollBehavior="inside"
      title={t('supplierInvoices:associateDirectPaymentSupplierInvoice.title')}
      size="6xl"
    >
      <Box mb={3}>{t('supplierInvoices:associateDirectPaymentSupplierInvoice.description')}</Box>

      {isCalloutDisplayed && (
        <Box pb={3}>
          <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
            {t('supplierInvoices:cards.informationsCard.alertOnAmountExVat', {
              orderAmountToBeBilledExVAT: formatAsAmount(orderAmountToBeBilledExVAT),
            })}
          </Callout>
        </Box>
      )}

      <Box h="40rem">
        <AssociateSupplierInvoiceTable onRowSelected={onRowSelected} />
      </Box>

      <Modal.Close />

      <Modal.PrimaryButton isDisabled={!selectedSupplierInvoice} onClick={onConfirmClick}>
        {t('supplierInvoices:associateDirectPaymentSupplierInvoice.confirm')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
