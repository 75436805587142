import type { IInitialTree, TreeContextApi } from '@graneet/lib-ui';
import { useLeaf, useLeafComputedValue, useNode, useNodeComputedValue, useTree, useTreeContext } from '@graneet/lib-ui';
import type { IOrderItemDTO, IOrderLotDTO } from '@graneet/business-logic';
import { FEATURE_FLAGS, computeOrderItemAmountExVAT, isNumberFinite } from '@graneet/business-logic';
import { useMemo } from 'react';
import { isNil } from 'lodash-es';
import type { FormContextApi } from 'graneet-form';

import type { OrderEditForm } from '../forms/order-edit-wizard';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export type OrderLotComputedValue = {
  numberOfItems: number;

  numberOfIncompleteItems: number;
};

export type OrderItemComputedValue = {
  id: string;

  isComplete: boolean;

  amountExVAT: number;

  vatRate: number | null;
};

export type InitialOrderTree = IInitialTree<IOrderLotDTO, IOrderItemDTO>;
export type OrderTree = TreeContextApi<IOrderLotDTO, IOrderItemDTO, OrderLotComputedValue, OrderItemComputedValue>;

export const useOrderTree = (initialTree: InitialOrderTree, form: FormContextApi<OrderEditForm>): OrderTree => {
  const hasTypeFF = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_ORDER_ITEM_COMPONENT_TYPE);

  return useTree(
    initialTree,
    useMemo(
      () => ({
        computeNodeComputedValue: (orderLot, treeContext) => {
          const displayedTree = treeContext.getDisplayedCurrentTree();
          const directChildren = displayedTree.relations[orderLot.id];

          const computedValues = treeContext.getComputedValues();

          const { numberOfIncompleteItems, numberOfItems } = directChildren.leaves.reduce<{
            numberOfIncompleteItems: number;
            numberOfItems: number;
          }>(
            (acc, leaveId) => ({
              numberOfIncompleteItems:
                acc.numberOfIncompleteItems + (computedValues.leaves[leaveId].isComplete ? 0 : 1),
              numberOfItems: acc.numberOfItems + 1,
            }),
            { numberOfIncompleteItems: 0, numberOfItems: 0 },
          );

          return {
            numberOfItems,
            numberOfIncompleteItems,
          };
        },
        computeLeafComputedValue: (orderItemDTO) => {
          const { id, description, quantity, unitPriceExVAT, unit, vatRate, typeId } = orderItemDTO;
          const { hasUnitPrices } = form.getFormValues();

          const isComponentTypeValid = !!typeId || !hasTypeFF;
          const isComplete = hasUnitPrices
            ? !!description &&
              !isNil(quantity) &&
              !isNil(unitPriceExVAT) &&
              !!unit &&
              !isNil(vatRate) &&
              isComponentTypeValid
            : !!description;
          const amountExVAT = computeOrderItemAmountExVAT({
            quantity,
            unitPriceExVAT: unitPriceExVAT || 0,
          });

          return {
            id,
            isComplete,
            amountExVAT,
            vatRate: isNumberFinite(vatRate) ? vatRate : null,
          };
        },
      }),
      [form, hasTypeFF],
    ),
    { areDeletionsPermanent: true },
  );
};

export const useOrderTreeContext = (): OrderTree =>
  useTreeContext<IOrderLotDTO, IOrderItemDTO, OrderLotComputedValue, OrderItemComputedValue>();

export const useOrderLot = (id: string) => useNode<IOrderLotDTO, IOrderItemDTO>(id);

export const useOrderItem = (id: string) => useLeaf<IOrderLotDTO, IOrderItemDTO>(id);

export const useOrderLotComputedValue = (id: string) => useNodeComputedValue<IOrderLotDTO, OrderLotComputedValue>(id);

export const useOrderItemComputedValue = (id: string) =>
  useLeafComputedValue<IOrderItemDTO, OrderItemComputedValue>(id);
