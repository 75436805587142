import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, HStack } from '@chakra-ui/react';
import { CurrencyField, RICH_TEXT_INLINE_TOOLBAR_PRESET, RichTextField, TextField, useCurrency } from '@graneet/lib-ui';

import type { LibraryJobFieldsForm } from '../types/library-job.type';

import { Rule } from 'features/form/rules/Rule';

type LibraryJobFieldsProps = {
  isUnitDisbursementEditable?: boolean;
  isDisabled?: boolean;
};

export const LibraryJobFields: FC<LibraryJobFieldsProps> = ({
  isDisabled = false,
  isUnitDisbursementEditable = true,
}) => {
  const { t } = useTranslation(['library']);
  const { mapAmountToNumber } = useCurrency();

  const currencyFieldData = useMemo(() => ({ mapValue: mapAmountToNumber }), [mapAmountToNumber]);

  return (
    <HStack spacing={6} w="100%">
      <Flex w="10%">
        <TextField<LibraryJobFieldsForm>
          name="refCode"
          label={t('library:libraryJob.fields.refCode')}
          isDisabled={isDisabled}
        />
      </Flex>

      <Flex w="70%">
        <RichTextField<LibraryJobFieldsForm>
          name="description"
          navbarType="inline"
          label={t('library:libraryJob.fields.description')}
          isRequired={!isDisabled}
          isDisabled={isDisabled}
          configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
        >
          <Rule.IsRequired />
        </RichTextField>
      </Flex>

      <Flex w="10%">
        <TextField<LibraryJobFieldsForm>
          name="unit"
          label={t('library:libraryJob.fields.unit')}
          isRequired={!isDisabled}
          isDisabled={isDisabled}
        >
          <Rule.IsRequired />
        </TextField>
      </Flex>

      <Flex w="20%">
        <CurrencyField<LibraryJobFieldsForm>
          name="unitDisbursementExVAT"
          label={t('library:libraryJob.fields.unitDisbursementExVAT')}
          data={currencyFieldData}
          isRequired={!isDisabled}
          isDisabled={isDisabled || !isUnitDisbursementEditable}
        >
          <Rule.IsRequired />
        </CurrencyField>
      </Flex>
    </HStack>
  );
};
