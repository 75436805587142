import { useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import type { PDF_TEMPLATES_TYPES } from '@graneet/business-logic';

interface PdfSettingsConfirmEditModalProps {
  template: PDF_TEMPLATES_TYPES;
  modal: UseDisclosureReturn;
  onConfirmed(): void;
}

export const PdfSettingsConfirmEditModal: FC<PdfSettingsConfirmEditModalProps> = ({ template, modal, onConfirmed }) => {
  const { t } = useTranslation(['pdfSettings', 'global']);

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmation = useCallback(async () => {
    setIsLoading(true);
    await onConfirmed();
    setIsLoading(false);
  }, [onConfirmed]);

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t(`pdfSettings:${template}.confirmModal.title`)}>
      <Text>{t(`pdfSettings:${template}.confirmModal.description`)}</Text>

      <Modal.SecondaryButton onClick={modal.onClose} isDisabled={isLoading}>
        {t('global:words.c.cancel')}
      </Modal.SecondaryButton>

      <Modal.PrimaryButton onClick={handleConfirmation} isLoading={isLoading}>
        {t('pdfSettings:button.confirm')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
