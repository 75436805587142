import type { FC } from 'react';
import { Flex, Grid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { InputProps } from '@graneet/lib-ui';
import { CurrencyField, PercentageField, PriceAdvanced } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { DISCOUNT_TYPES } from '@graneet/business-logic';

import { useDiscountTotalExVAT } from '../hooks/useDiscountTotalExVAT';
import type { DiscountEditionForm } from '../forms/discount-edition-form';

import { Rule } from 'features/form/rules/Rule';

const discountFieldInputProps: InputProps = {
  textAlign: 'right',
};

interface DiscountTotalsProps {
  totalBeforeDiscountExVAT: number;

  onFieldBlur?(): void;
}

export const DiscountTotals: FC<DiscountTotalsProps> = ({ totalBeforeDiscountExVAT, onFieldBlur = () => {} }) => {
  const { t } = useTranslation(['discount']);
  const totalExVAT = useDiscountTotalExVAT(totalBeforeDiscountExVAT);

  const form = useFormContext<DiscountEditionForm>();
  const { discountType } = useOnChangeValues(form, ['discountType']);

  return (
    <Flex bg="gray.100" borderRadius="4" direction="column" px={4} py={2}>
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <Text fontWeight={600} textAlign="right">
          {t('discount:totalExVATBeforeDiscount')}
        </Text>

        <PriceAdvanced textAlign="right" fontWeight={600} amount={totalBeforeDiscountExVAT} />

        {discountType === DISCOUNT_TYPES.AMOUNT && (
          <>
            <Text textAlign="right">{t('discount:amountLabel')}</Text>
            <Flex justifyContent="flex-end">
              <CurrencyField<DiscountEditionForm>
                name="discountAmountExVAT"
                w="8rem"
                inputProps={discountFieldInputProps}
                onBlur={onFieldBlur}
              >
                <Rule.IsRequired />
              </CurrencyField>
            </Flex>
          </>
        )}
        {discountType === DISCOUNT_TYPES.PERCENTAGE && (
          <>
            <Text textAlign="right">{t('discount:percentLabel')}</Text>
            <Flex justifyContent="flex-end">
              <PercentageField<DiscountEditionForm>
                name="discountPercentage"
                w="4.5rem"
                inputProps={discountFieldInputProps}
                min={-100}
                onBlur={onFieldBlur}
              >
                <Rule.IsRequired />
              </PercentageField>
            </Flex>
          </>
        )}

        <Text fontWeight={600} textAlign="right">
          {t('discount:totalExVAT')}
        </Text>

        <PriceAdvanced textAlign="right" fontWeight={600} amount={totalExVAT} />
      </Grid>
    </Flex>
  );
};
