import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import { keyBy } from 'lodash-es';
import type { ILibraryJob } from '@graneet/business-logic';

import * as lotsApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useStore } from 'store/store';

export const useImportLibraryJobInLot = () => {
  const { t } = useTranslation(['quote']);
  const toast = useToast();
  const { updateDataLocally, emitLotUpdate, insertJobsAfterJobInLotInRelations, getCurrentQuoteData } =
    useQuoteEditContext();

  return useCallback(
    async (libraryJobs: ILibraryJob[], lotId: number) => {
      const { relations, rootLotId } = getCurrentQuoteData();
      const jobsOfLot = relations[lotId]?.jobs;
      const previousJobId = jobsOfLot ? jobsOfLot[jobsOfLot.length - 1] : undefined;

      const isParentOptional = useStore.getState().optionalLotsTable[lotId?.toString()] || false;

      if (!rootLotId) {
        return [null];
      }
      const libraryJobsIds = libraryJobs.map((job) => job.id);

      const [err, infos] = await lotsApi.importLibraryJobLot(lotId, {
        libraryJobsIds,
        previousJobId,
        isParentOptional,
      });

      if (err) {
        toast.error(t('quote:jobsStep.errors.importJobsError'));
        return [err];
      }

      insertJobsAfterJobInLotInRelations(infos.jobs, lotId, previousJobId);

      const { jobs } = infos;

      const groupJobsComponents = Object.values(jobs)
        .map(({ components }) => components)
        .flat();

      const newComponent = keyBy(groupJobsComponents, 'id');

      // Notify the matching lot component it needs to re-render
      updateDataLocally({ ...infos, components: newComponent });
      emitLotUpdate(lotId);

      toast.success(t('quote:toasts.libraryJobImportSuccess', { count: Object.values(infos.jobs).length }));

      return [];
    },
    [getCurrentQuoteData, insertJobsAfterJobInLotInRelations, updateDataLocally, emitLotUpdate, toast, t],
  );
};
