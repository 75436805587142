import { HStack } from '@chakra-ui/react';
import type { QuotationFormulaFieldValue } from '@graneet/lib-ui';
import { QuotationFormulaInput } from '@graneet/lib-ui';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const QuantityEditorCell = (params: CustomCellEditorProps<QuoteNodeObject>) => {
  const { value, data, node, onValueChange, stopEditing } = params;

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue] = useState<QuotationFormulaFieldValue | null>(value);

  const color = useMemo(() => {
    if (data?.content.type === 'QuoteSubItem' || data?.content.type === 'QuoteComponent') {
      return '#6C737F';
    }
    return '#1F2A37';
  }, [data]);

  const handleOnChange = useCallback(
    (v: QuotationFormulaFieldValue | null) => {
      onValueChange(v);
    },
    [onValueChange],
  );

  const emptyCb = useCallback(() => {}, []);

  const handleOnBlur = useCallback(
    (v: QuotationFormulaFieldValue | null) => {
      onValueChange(v);
      stopEditing();
    },
    [onValueChange, stopEditing],
  );

  const handleOnFocus = useCallback(() => {
    if (!value.content?.formula) inputRef.current?.select();
  }, [value.content?.formula]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  if (node.footer) {
    return null;
  }

  return (
    <HStack width="100%" justifyContent="center" color={color} fontSize="0.813rem" py="8px">
      <QuotationFormulaInput
        value={inputValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        sendClickRoundEvent={emptyCb}
        ref={inputRef}
      />
    </HStack>
  );
};
