import type { IReminderWithRelations } from '@graneet/business-logic';
import type { FC } from 'react';
import { Fragment, useMemo } from 'react';
import { sortBy } from 'lodash-es';
import { Box, Flex, Grid, GridItem, Stack, useDisclosure } from '@chakra-ui/react';
import { AddIcon, BellIcon } from '@chakra-ui/icons';
import {
  ActionMenu,
  Badge,
  Button,
  Card,
  DocIcon,
  Section,
  SimpleCalendarIcon,
  SimpleThunderIcon,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { ReminderDeleteModal } from './ReminderDeleteModal';
import { ReminderUpsertModal } from './ReminderUpsertModal';

const TEMPLATE_COLUMNS = '4rem 1.5fr 1fr 3rem';
const CARD_HEIGHT = '3.5rem';

const RemainderCard: FC<{
  reminder: IReminderWithRelations;
  reminders: IReminderWithRelations[];
}> = ({ reminder, reminders }) => {
  const { t } = useTranslation(['global']);

  const isNegative = reminder.daysAfterDeadline < 0;

  const day = `${t('global:words.abreviated_day').toUpperCase()} ${isNegative ? '-' : '+'} ${Math.abs(reminder.daysAfterDeadline)}`;

  const deleteModal = useDisclosure();
  const upsertModal = useDisclosure();

  return (
    <Box borderWidth={1} borderColor="gray.150" p={4} borderRadius={4} shadow="sm" minH={CARD_HEIGHT}>
      <Grid gap={3} templateColumns={TEMPLATE_COLUMNS}>
        <Flex alignItems="center" fontSize="sm" textColor="gray.600">
          {day}
        </Flex>

        <Flex alignItems="center" textColor="gray.800" fontWeight={600}>
          {reminder.automaticSend ? (
            <SimpleThunderIcon fill="yellow.500" stroke="yellow.500" mr={3} />
          ) : (
            <BellIcon color="greenBrand.light" mr={3} />
          )}
          {reminder.name}
        </Flex>

        <Flex alignItems="center">
          <Badge showDot colorScheme="gray">
            <Flex alignItems="center">
              <DocIcon boxSize={4} mr={2} />
              {reminder.emailTemplate.title}
            </Flex>
          </Badge>
        </Flex>

        <Flex alignItems="center">
          <ActionMenu>
            <ActionMenu.Edit onClick={upsertModal.onOpen} />
            <ActionMenu.Delete onClick={deleteModal.onOpen} />
          </ActionMenu>
        </Flex>

        <ReminderDeleteModal reminder={reminder} onClose={deleteModal.onClose} isOpen={deleteModal.isOpen} />
        <ReminderUpsertModal
          isOpen={upsertModal.isOpen}
          onClose={upsertModal.onClose}
          reminder={reminder}
          reminders={reminders}
        />
      </Grid>
    </Box>
  );
};

const Separator = () => (
  <Grid pl={9} templateColumns={TEMPLATE_COLUMNS}>
    <GridItem />

    <GridItem boxSize="100%">
      <Flex color="gray.150" h="1rem" borderLeftWidth="2px" />
    </GridItem>
  </Grid>
);

export interface ReminderListProps {
  reminders: IReminderWithRelations[];
}

export const ReminderList: FC<ReminderListProps> = ({ reminders }) => {
  const { t } = useTranslation(['global']);

  const sortedReminders = useMemo(() => {
    const beforeEndDate: IReminderWithRelations[] = [];
    const afterEndDate: IReminderWithRelations[] = [];

    sortBy(reminders, (reminder) => reminder.daysAfterDeadline).forEach((reminder) => {
      if (reminder.daysAfterDeadline < 0) {
        beforeEndDate.push(reminder);
      } else {
        afterEndDate.push(reminder);
      }
    });

    return { beforeEndDate, afterEnDate: afterEndDate };
  }, [reminders]);

  const createModal = useDisclosure();

  return (
    <Section
      title={t('global:reminders.settingsCard.title')}
      description={t('global:reminders.settingsCard.description')}
    >
      <Card>
        <Stack spacing={1}>
          {sortedReminders.beforeEndDate.map((reminder) => (
            <Fragment key={reminder.id}>
              <RemainderCard reminder={reminder} reminders={reminders} />
              <Separator />
            </Fragment>
          ))}

          <Box
            minH={CARD_HEIGHT}
            borderRadius={4}
            bg="linear-gradient(125deg, #e4ebf3 25%, #ffffff 25%, #ffffff 50%, #e4ebf3 50%, #e4ebf3 75%, #ffffff 75%, #ffffff 100%)"
            backgroundSize="6.00px 8.57px"
            p={4}
          >
            <Grid templateColumns={TEMPLATE_COLUMNS} gap={3}>
              <GridItem fontSize="sm" textColor="gray.600">
                {t('global:words.abreviated_day').toUpperCase()}
              </GridItem>

              <Flex alignItems="center" fontWeight={600} color="gray.500">
                <SimpleCalendarIcon mr={2} />
                {t('global:reminders.settingsCard.statementDueDate')}
              </Flex>
            </Grid>
          </Box>

          {sortedReminders.afterEnDate.map((reminder) => (
            <Fragment key={reminder.id}>
              <Separator />
              <RemainderCard reminder={reminder} reminders={reminders} />
            </Fragment>
          ))}
        </Stack>

        <ReminderUpsertModal isOpen={createModal.isOpen} onClose={createModal.onClose} reminders={reminders} />
        <Button mt={3} variant="outline" leftIcon={<AddIcon />} size="sm" onClick={createModal.onOpen}>
          {t('global:reminders.settingsCard.addReminderLevel')}
        </Button>
      </Card>
    </Section>
  );
};
