import { useCallback } from 'react';
import { ContentTypeGuard } from '@org/quotation-lib';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const useQuoteLibraryCanImportComponent = () => {
  const { quote } = useQuote();

  return useCallback(
    (nodeId: string) => {
      const node = quote.getTree().getNodeOrNull(nodeId);
      if (!node) {
        return false;
      }
      const nodeContent = node.getContent();

      if (ContentTypeGuard.isQuoteItem(nodeContent) || ContentTypeGuard.isQuoteSubItem(nodeContent)) {
        if (nodeContent.getChildrenType() === 'QuoteComponent' || nodeContent.getChildrenType() === null) {
          return true;
        }
      }

      return false;
    },
    [quote],
  );
};
