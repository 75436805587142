import type { FC } from 'react';
import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { Accordion, Tooltip, SimpleHelpIcon, SimpleChevronRightIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IOrderWithDirectPaymentAmounts } from '@graneet/business-logic';

import { useDirectPaymentContext } from '../contexts/DirectPaymentContext';

import { DirectPaymentOrderButton } from './DirectPaymentOrderButton';
import { DirectPaymentOrderPanel } from './DirectPaymentOrderPanel';

const ButtonComponent = (order: IOrderWithDirectPaymentAmounts) => <DirectPaymentOrderButton order={order} />;
const PanelComponent = (order: IOrderWithDirectPaymentAmounts) => <DirectPaymentOrderPanel order={order} />;

export const DirectPaymentOrderList: FC = () => {
  const { t } = useTranslation(['project']);
  const { directPaymentOrders, selectedOrderIndex, onOrderSelect } = useDirectPaymentContext();
  const cleanedDirectPaymentOrders = directPaymentOrders.filter((directPaymentOrder) => directPaymentOrder);

  return (
    <Box bg="white" shadow="md" h="100%" overflow="auto">
      <HStack pt={5} pb={3} px={6}>
        <Box fontWeight={500} fontSize="lg" color="black" fontFamily="heading">
          {t('project:directPayments.heading')}
        </Box>

        <Tooltip label={t('project:directPayments.headingHelp')}>
          <Box>
            <SimpleHelpIcon />
          </Box>
        </Tooltip>
      </HStack>

      {cleanedDirectPaymentOrders.length ? (
        <Accordion
          items={cleanedDirectPaymentOrders}
          componentButton={ButtonComponent}
          componentPanel={PanelComponent}
          context={{
            index: selectedOrderIndex,
            onChange: onOrderSelect,
          }}
          icon={<SimpleChevronRightIcon position="absolute" right={3} top={5} />}
          overwriteStyle={{
            accordionButton: { pl: 6, py: 4, pr: 12 },
            accordionPanel: { pb: 4, pl: 6, py: 4, pr: 12 },
            accordionItem: { marginBottom: 4, rounded: 0 },
          }}
          accordionProps={{ overflow: 'auto', pt: 2 }}
        />
      ) : null}
    </Box>
  );
};
