import { SUPPORT_EMAIL } from '@graneet/business-logic';
import type { Quote } from '@org/quotation-lib';
import { ContentTypeGuard, QuoteFile } from '@org/quotation-lib';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { convertBase64 } from '@graneet/lib-ui';
import { v4 as uuid } from 'uuid';

import { useQuoteError } from './useQuoteError';

import { useQuoteSetToStore } from 'features/quotation/quote-common/hooks/useQuoteSetToStore';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  quoteClientRequestsSetToStore,
  StatusEnum,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';

export function useQuoteFileAttach() {
  const quoteSetToStore = useQuoteSetToStore();
  const { t } = useTranslation(['global', 'quote']);

  const { quoteFilesProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    (
      files: {
        id: string;
        file: File;
      }[],
      nodeId: string,
    ): ICommand => ({
      execute(quote: Quote) {
        try {
          const node = quote.getTree().getNodeOrThrow(nodeId);
          this.oldValue = {
            filesId: [] as string[],
            nodeId,
          };
          files.map(async ({ id, file }) => {
            const content = node.getContent();
            if (
              ContentTypeGuard.isQuoteBasicItem(content) ||
              // ContentTypeGuard.isQuoteHiddenCostItem(content) || TODO LATER
              ContentTypeGuard.isQuoteOptionalItem(content) ||
              ContentTypeGuard.isQuoteSubItem(content)
            ) {
              convertBase64(file).then((val) => {
                const fileEntity = new QuoteFile({
                  name: file.name,
                  url: val ?? '',
                  id,
                });
                content.addFile(fileEntity);
                this.oldValue.filesId.push(fileEntity.getId());
                quoteSetToStore(quote);
              });
            }
          });
          if (files.length > 0) {
            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteFilesProxyApi
              .attachFiles(
                quote.getId(),
                timestamp,
                clientRequestId,
                nodeId,
                JSON.stringify(
                  files.map((file) => ({
                    id: file.id,
                    fileName: file.file.name,
                  })),
                ),
                files.map((file) => file.file),
              )
              .then(([err]) => {
                if (err) {
                  if (err.response?.status === 413) {
                    quoteError(t('quote:quotation.errors.attachFiles', { email: SUPPORT_EMAIL }));
                  }
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
          }
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const node = quote.getTree().getNodeOrThrow(this.oldValue.nodeId);
          const content = node.getContent();
          if (
            ContentTypeGuard.isQuoteBasicItem(content) ||
            // ContentTypeGuard.isQuoteHiddenCostItem(content) || TODO LATER
            ContentTypeGuard.isQuoteOptionalItem(content) ||
            ContentTypeGuard.isQuoteSubItem(content)
          ) {
            this.oldValue.filesId.forEach((fileId: string) => {
              content.deleteFile(fileId);
              const clientRequestId = uuid();
              const timestamp = new Date().toISOString();
              quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
              quoteFilesProxyApi
                .deleteFile({
                  nodeId,
                  timestamp,
                  clientRequestId,
                  quoteId: quote.getId(),
                  fileId,
                })
                .then(([err]) => {
                  if (err) {
                    quoteError();
                  }
                })
                .catch((err) => quoteError(err.message));
            });
            quoteSetToStore(quote);
          }
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteFilesProxyApi, quoteSetToStore, t],
  );
}
