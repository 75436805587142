import type { SliderProps } from '@chakra-ui/react';
import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react';
import type { AnyRecord, FieldValues } from 'graneet-form';
import { Field } from 'graneet-form';

import type { KeysMatching } from '../../../utils';

type SliderFieldValue = number | null | undefined;

export interface SliderFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, SliderFieldValue> = KeysMatching<T, SliderFieldValue>,
> extends Omit<SliderProps, 'name' | 'value'> {
  name: K;
  data?: AnyRecord;
  onChange?(value: T[K]): void | Promise<void>;
}

export const SliderField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, SliderFieldValue> = KeysMatching<T, SliderFieldValue>,
>({
  name,
  data,
  children,
  ...rest
}: SliderFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    data={data}
    render={({ value, onChange, onBlur, onFocus }) => (
      <Slider
        {...rest}
        name={String(name)}
        value={value}
        onChange={onChange as (value: number) => void}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        {children}
        <SliderTrack bg="white" borderRadius="8px" height="12px" border="1px solid rgba(0, 0, 0, 0.2)">
          <SliderFilledTrack bg="greenBrand.light" borderWidth="1px" borderColor="greenBrand.base" borderRadius="8px" />
        </SliderTrack>
        <SliderThumb
          boxSize={5}
          border="2px solid rgba(0, 0, 0, 0.2)"
          borderColor="greenBrand.base"
          boxShadow="0px 27px 30px -15px rgba(255, 255, 255, 0.13), 0px 0px 3px -1px rgba(255, 255, 255, 0.04), 0px 0px 0px 1px rgba(255, 255, 255, 0.05)"
        />
      </Slider>
    )}
  />
);
