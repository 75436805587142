import { HStack } from '@chakra-ui/react';
import { QuotationInput, Tooltip, useCurrency } from '@graneet/lib-ui';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const WorkingDurationCell = memo(
  (params: CustomCellRendererProps<QuoteNodeObject>) => {
    const { t } = useTranslation(['quote']);
    const { formatter } = useCurrency();

    const { getValue, data } = params;

    const workForceValue: string | null = useMemo(() => {
      if (getValue) {
        return getValue();
      }
      return null;
    }, [getValue]);

    const optionalWorkForceValue: string | null = useMemo(() => {
      if (data?.content.type === 'QuoteLot') {
        return data?.content.optionalWorkForce ?? null;
      }
      return null;
    }, [data]);

    const color = useMemo(() => {
      if (data?.content.type === 'QuoteSubItem' || data?.content.type === 'QuoteComponent') {
        return '#6C737F';
      }
      return '#1F2A37';
    }, [data]);

    const emptyCb = useCallback(() => {}, []);

    // Display tooltip only if the QuoteLot is not optional and the optional workforce is not 0
    const isTooltipActive =
      data?.content.type === 'QuoteLot' && !data?.content.isEveryChildOptional && optionalWorkForceValue !== '0';

    return (
      <HStack pr={2} width="100%" justifyContent="flex-end" color={color} fontSize="0.813rem" py="8px">
        <Tooltip
          label={t('quote:words.workingDurationCellTooltip', {
            optionalWorkForceValue: `${formatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(optionalWorkForceValue!))} h`,
          })}
          shouldWrapChildren
          placement="top-end"
          isDisabled={!isTooltipActive}
        >
          <QuotationInput
            isReadOnly
            color={color}
            textAlign="right"
            value={
              workForceValue
                ? `${formatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(workForceValue))} h`
                : '-'
            }
            onChange={emptyCb}
            onBlur={emptyCb}
            onKeyDownCapture={emptyCb}
            noStyle
          />
        </Tooltip>
      </HStack>
    );
  },
  (prevProps, nextProps) => {
    if (!prevProps && !nextProps) {
      return true;
    }
    if (!prevProps || !nextProps) {
      return false;
    }
    const oldValue = prevProps.getValue ? prevProps.getValue() : undefined;
    const newValue = nextProps.getValue ? nextProps.getValue() : undefined;
    return oldValue === newValue;
  },
);
