import type { FC } from 'react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { PriceAdvanced, Table, useCurrency, CurrencyField } from '@graneet/lib-ui';
import type { IProgressStatement } from '@graneet/business-logic';

import type { FinalStatementPriceRevisionForm } from '../../forms/final-statement-edition.wizard';
import { getPriceRevisionFieldName } from '../../forms/final-statement-edition.wizard';

interface FinalStatementPriceRevisionRowProps {
  progressStatement: IProgressStatement;
}

const DeltaAmount: FC<FinalStatementPriceRevisionRowProps> = ({ progressStatement }) => {
  const { mapAmountToNumber } = useCurrency();

  const name = getPriceRevisionFieldName(progressStatement);
  const form = useFormContext<FinalStatementPriceRevisionForm>();
  const values = useOnChangeValues(form, [name]);

  const newPriceRevision = mapAmountToNumber(values[name] || 0);
  const delta = newPriceRevision - progressStatement.priceRevisionExVAT;

  return <PriceAdvanced amount={delta} isAmountColored sign />;
};

export const FinalStatementPriceRevisionRow: FC<FinalStatementPriceRevisionRowProps> = ({
  progressStatement,
  ...props
}) => {
  const name = getPriceRevisionFieldName(progressStatement);

  return (
    <Table.Row {...props}>
      <Table.Cell variant="ellipsis">{progressStatement.invoiceNumber}</Table.Cell>
      <Table.Cell variant="ellipsis">{progressStatement.name}</Table.Cell>
      <Table.Cell right>
        <PriceAdvanced amount={progressStatement.nonCumulativeProgressAmountExVAT} />
      </Table.Cell>
      <Table.Cell right>
        <PriceAdvanced amount={progressStatement.priceRevisionExVAT} />
      </Table.Cell>
      <Table.Cell variant="shadow">
        <CurrencyField<FinalStatementPriceRevisionForm> name={name} />
      </Table.Cell>
      <Table.Cell right>
        <DeltaAmount progressStatement={progressStatement} />
      </Table.Cell>
    </Table.Row>
  );
};
