import debounce from 'lodash-es/debounce';
import { useEffect, useMemo, useRef } from 'react';

export type DebouncedCallback = (...args: any) => void;

interface DebounceOptions {
  leading?: boolean;
  trailing?: boolean;
  maxWait?: number;
}

export const useDebounce = (
  callback: DebouncedCallback,
  timer?: number,
  options: DebounceOptions = {
    leading: true,
    trailing: true,
    maxWait: timer,
  },
) => {
  const ref = useRef<DebouncedCallback>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...args: any) => {
      ref.current?.(args);
    };

    return debounce(func, timer ?? 1000, options);
  }, [timer, options]);

  return debouncedCallback;
};
