import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

import { isRequired } from './validation.util';

interface RequiredProps {
  message?: string;
}

export const IsRequired: FC<RequiredProps> = ({ message }) => {
  const { t } = useTranslation(['global']);

  return <Rule message={message || t('global:form.errors.required')} validationFn={isRequired} />;
};
