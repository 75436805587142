import type { FC, ReactNode } from 'react';
import { Fragment } from 'react';
import { Box, Collapse, useDisclosure } from '@chakra-ui/react';
import type { CalloutColorScheme } from '@graneet/lib-ui';
import { Button, Callout, CALLOUT_COLORS, SimpleAlertIcon, SimpleChevronBottomIcon, Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IUser } from '@graneet/business-logic';

interface UserImpactedCalloutProps {
  users?: IUser[];

  colorScheme?: CalloutColorScheme;

  children: ReactNode;
}

export const UserImpactedCallout: FC<UserImpactedCalloutProps> = ({ users, colorScheme = 'yellow', children }) => {
  const { t } = useTranslation(['user']);

  const { isOpen, onToggle } = useDisclosure();

  const color = CALLOUT_COLORS[colorScheme];

  return (
    <Callout colorScheme={colorScheme} icon={<SimpleAlertIcon stroke={color.text} boxSize={5} />} w="100%">
      {children}

      <Box w="100%">
        <Button
          rightIcon={isOpen ? <SimpleChevronBottomIcon transform="rotate(-180deg)" /> : <SimpleChevronBottomIcon />}
          variant="outline"
          onClick={onToggle}
          ml={-4}
          color={color.text}
          _hover={{ color: color.text, bg: color.hover }}
        >
          {isOpen ? t('user:actions.closeCollapse') : t('user:actions.openCollapse')}
        </Button>

        <Collapse in={isOpen} animateOpacity>
          <Box mt={2} w="100%">
            <Table templateColumns={['15rem', 'minmax(10rem, 30rem)']}>
              <Table.Row>
                {users?.map((user) => (
                  <Fragment key={user.id}>
                    <Table.Cell>{user.lastName}</Table.Cell>
                    <Table.Cell>{user.firstName}</Table.Cell>
                  </Fragment>
                ))}
              </Table.Row>
            </Table>
          </Box>
        </Collapse>
      </Box>
    </Callout>
  );
};
