import { HStack, VStack, Text } from '@chakra-ui/react';
import { GRANEET_COLORS } from '@graneet/business-logic';
import { Badge } from '@graneet/lib-ui';
import { useMemo, type FC, useCallback, useRef, useEffect } from 'react';

import { useStore } from 'store/store';

export const ResultLine: FC<{
  denomination: string;
  refCode: string;
  unitDisbursementExVAT: string;
  unit: string;
  componentTypeId?: number | null;
  hasComponent?: boolean;
  isSelected?: boolean;
  searchTerm: string;
  onClick?: () => void;
}> = ({ denomination, refCode, unitDisbursementExVAT, unit, componentTypeId, isSelected, hasComponent, onClick }) => {
  const editorRef = useStore((state) => state.quoteRichTextEditorRef);

  const getPlainText = useCallback(
    (v: string | null | undefined) =>
      editorRef?.current?.getPlainText ? (editorRef?.current?.getPlainText(v) ?? null) : null,
    [editorRef],
  );

  const ref = useRef<HTMLDivElement>(null);
  const componentTypes = useStore((state) => state.quoteComponentTypes);

  const itemComponent = useMemo(() => {
    if (!componentTypeId) {
      return null;
    }
    const componentType = componentTypes.find((component) => component.id === parseInt(componentTypeId.toString(), 10));

    return componentType
      ? {
          ...componentType,
          color: (Object.values(GRANEET_COLORS).includes(componentType.color)
            ? componentType.color
            : 'gray') as GRANEET_COLORS,
        }
      : null;
  }, [componentTypes, componentTypeId]);

  const plainTextDenomination = useMemo(() => getPlainText(denomination), [denomination, getPlainText]);

  useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [isSelected]);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <VStack
      ref={ref}
      gap="0.25rem"
      width="100%"
      px="0.5rem"
      py="6px"
      borderRadius="0.5rem"
      alignItems="flex-start"
      justifyContent="center"
      background={isSelected ? 'greenBrand.backgroundNeutralSubtle' : undefined}
      onClick={handleOnClick}
      cursor={onClick ? 'pointer' : 'default'}
      _hover={{
        background: 'greenBrand.backgroundNeutralSubtle',
      }}
    >
      <HStack gap={0}>
        <Text color="greenBrand.primaryLight" fontSize="14px">
          {plainTextDenomination}
        </Text>
        {hasComponent && (
          <Text color="baseSecondary" mx={2}>
            <i className="ri-box-2-line" />
          </Text>
        )}
      </HStack>
      <HStack fontSize="12px" color="greenBrand.baseSecondary" gap={1}>
        {refCode && (
          <>
            <Text textTransform="uppercase">{refCode}</Text>
            <Text>·</Text>
          </>
        )}
        <Text>{unitDisbursementExVAT}</Text>
        <Text>/</Text>
        <Text>{unit}</Text>
      </HStack>
      {itemComponent && (
        <Badge colorScheme={itemComponent.color} showDot>
          {itemComponent.name}
        </Badge>
      )}
    </VStack>
  );
};
