import { useMemo } from 'react';
import type { IItem } from '@graneet/business-logic';
import {
  computeTotalExVAT,
  computeTotalIncVAT,
  computeContainerAmountExVAT,
  computeContainerAmountsUsingBasesRoundedMethod,
  isNumberFinite,
} from '@graneet/business-logic';
import { divideFloating, useCurrency } from '@graneet/lib-ui';

import type { InvoiceEditionForm } from '../forms/invoice-edition-form';
import { getItemFieldName } from '../forms/invoice-edition-form';

import type { ContainerTreeContextApi } from './useInvoiceTree';

export const useContainerTotals = (values: Partial<InvoiceEditionForm>, tree: ContainerTreeContextApi) => {
  const { mapAmountToNumber } = useCurrency();

  const items = useMemo(() => {
    const currentTree = tree.getCurrentTree();

    return currentTree.relations[currentTree.rootNodeId].leaves.reduce<
      Pick<IItem, 'totalExVAT' | 'isOptional' | 'vatRate'>[]
    >((acc, itemId) => {
      const unitPrice = values[getItemFieldName(itemId, 'unitPrice')];
      const quantity = values[getItemFieldName(itemId, 'quantity')];
      const vatRate = values[getItemFieldName(itemId, 'vatRate')];

      const item = {
        unitPrice: mapAmountToNumber(unitPrice || 0),
        quantity: quantity || 0,
        vatRate: divideFloating(vatRate || 0, 100),
        isOptional: false,
      };
      const itemWithAmounts = {
        ...item,
        totalExVAT: computeTotalExVAT(item),
        totalIncVAT: computeTotalIncVAT(item),
      };

      return [...acc, itemWithAmounts];
    }, []);
  }, [mapAmountToNumber, tree, values]);

  if (!values) {
    return {
      totalBeforeDiscountExVAT: 0,
      totalExVAT: 0,
      discountExVAT: 0,
      totalIncVAT: 0,
      vatDistribution: [],
    };
  }

  const totalBeforeDiscountExVAT = computeContainerAmountExVAT(items);

  const { discountType, discountPercentage, discountAmountExVAT, hasReversalOfLiability } = values;

  const { totalAmountExVAT, totalAmountIncVAT, vatDistribution } = computeContainerAmountsUsingBasesRoundedMethod(
    totalBeforeDiscountExVAT,
    items,
    discountType
      ? {
          type: discountType,
          percentage: discountPercentage || null,
          amountExVAT: isNumberFinite(discountAmountExVAT) ? mapAmountToNumber(discountAmountExVAT) : null,
        }
      : undefined,
    hasReversalOfLiability || false,
  );

  return {
    totalBeforeDiscountExVAT,
    totalExVAT: totalAmountExVAT,
    discountExVAT: totalBeforeDiscountExVAT - totalAmountExVAT,
    totalIncVAT: totalAmountIncVAT,
    vatDistribution,
  };
};
