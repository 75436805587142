import { Flex, Text } from '@chakra-ui/react';
import { DiscountIcon, useCurrency, formatPercentage } from '@graneet/lib-ui';
import type { IDiscount } from '@graneet/business-logic';
import { DISCOUNT_TYPES } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

interface QuoteDiscountLabelProps {
  discount: IDiscount;
  discountAmount: number;
}

export const QuoteDiscountLabel: FC<QuoteDiscountLabelProps> = ({ discount, discountAmount }) => {
  const { t } = useTranslation(['quote']);
  const { formatAsAmount } = useCurrency();

  const amountLabel =
    discount.type === DISCOUNT_TYPES.PERCENTAGE
      ? t('quote:discounts.labelWithPercentageAndAmount', {
          percentage: formatPercentage(-discount.percentage!),
          amount: formatAsAmount(-discountAmount),
        })
      : t('quote:discounts.labelWithAmountApplied', {
          amount: formatAsAmount(-discountAmount),
        });
  return (
    <Flex p={2} align="center">
      <DiscountIcon mr={2} />
      <Text color="green.700" fontWeight={600}>
        {amountLabel}
      </Text>
    </Flex>
  );
};
