import type { FC } from 'react';
import { Button } from '@graneet/lib-ui';
import { useDisclosure } from '@chakra-ui/react';
import type { IProject } from '@graneet/business-logic';
import { FEATURE_FLAGS } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { SupplierInvoiceBatchImportModal } from './SupplierInvoiceBatchImportModal';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface SupplierInvoiceBatchImportButtonProps {
  project?: IProject;

  onImported: () => void;
}

export const SupplierInvoiceBatchImportButton: FC<SupplierInvoiceBatchImportButtonProps> = ({
  project,
  onImported,
}) => {
  const { t } = useTranslation(['supplierInvoices']);
  const modal = useDisclosure();

  const hasFeatureFlag = useFeatureFlag(FEATURE_FLAGS.SUPPLIER_INVOICE_BATCH_IMPORT);
  if (!hasFeatureFlag) {
    return null;
  }

  return (
    <>
      <Button variant="outline" onClick={modal.onOpen}>
        {t('supplierInvoices:supplierInvoiceImportModal.cta')}
      </Button>
      {modal.isOpen && (
        <SupplierInvoiceBatchImportModal
          isOpen={modal.isOpen}
          onClose={modal.onClose}
          onImported={onImported}
          project={project}
        />
      )}
    </>
  );
};
