import type { FC } from 'react';
import { useMemo } from 'react';
import { DeepTable, PriceAdvanced } from '@graneet/lib-ui';

import { useContractLotComputedValue } from '../../hooks/tree.hooks';

import { useStore } from 'store/store';

interface ContractLotAmountsProps {
  id: number | string;
}

export const ContractLotAmounts: FC<ContractLotAmountsProps> = ({ id }) => {
  const nodeComputedValue = useContractLotComputedValue(id);

  const isOptional = useStore((state) => state.optionalLotsTable[id.toString()]);

  const textStyle = useMemo(() => {
    if (nodeComputedValue?.totalExVATDifference) {
      return {
        fontWeight: '700',
      };
    }
    return {
      fontWeight: '500',
    };
  }, [nodeComputedValue?.totalExVATDifference]);

  return (
    <>
      <DeepTable.Cell right>
        <PriceAdvanced amount={nodeComputedValue?.amountInvoiced} fontSize="md" color="gray.500" />
      </DeepTable.Cell>

      <DeepTable.Cell right>
        <PriceAdvanced
          amount={isOptional ? nodeComputedValue?.totalOptionalAmountExVAT : nodeComputedValue?.totalExVAT}
          fontSize="md"
          {...textStyle}
        />
      </DeepTable.Cell>

      <DeepTable.Cell right>
        {!!nodeComputedValue?.totalExVATDifference && (
          <PriceAdvanced
            amount={nodeComputedValue?.totalExVATDifference}
            fontSize="md"
            sign
            isAmountColored
            {...textStyle}
          />
        )}
      </DeepTable.Cell>
    </>
  );
};
