import { Box, Flex, HStack, Stack } from '@chakra-ui/react';
import type { IPenaltyUpsertDTO, IPenaltyFindByProjectIdOutputDTO } from '@graneet/business-logic';
import { sumObjects } from '@graneet/business-logic';
import {
  AmountSummary,
  Button,
  DrawersStack,
  Onboarding,
  Section,
  SimpleAddIcon,
  SimpleHiddenCostIcon,
  SimpleSettingsIcon,
  Table,
  useCurrency,
  useDrawer,
  useDrawersStack,
} from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHiddenField, useStepForm, HiddenField, Form } from 'graneet-form';

import type { PenaltyFormValues } from 'features/penalty/components/PenaltyDrawer';
import { DescriptionLabel, PenalyDrawer } from 'features/penalty/components/PenaltyDrawer';
import type { FinalStatementEditionWizard } from 'features/final-statement/forms/final-statement-edition.wizard';
import { useSendClickAddPenaltyButtonEvent } from 'features/analytic/hooks/useSendClickAddPenaltyButtonEvent';

interface ViewPenaltiesStepProps {
  penalties: IPenaltyFindByProjectIdOutputDTO;
}

export const ViewPenaltiesStep: FC<ViewPenaltiesStepProps> = ({ penalties }) => {
  const { t } = useTranslation(['project', 'penalty']);
  const { formatAsAmount } = useCurrency();
  const drawersStack = useDrawersStack();
  const drawerPenalty = useDrawer('drawer-penalty', drawersStack);
  const { form: finalStatementEditWizardForm, initFormValues } = useStepForm<
    FinalStatementEditionWizard,
    'penalties'
  >();
  const penaltiesHiddenField = useHiddenField(finalStatementEditWizardForm, 'penalties');

  useEffect(() => {
    const penaltiesData: IPenaltyUpsertDTO[] = (penalties.finalStatement.penalties || []).map((penalty) => ({
      id: penalty.id,
      name: penalty.name,
      amountIncVAT: penalty.amountIncVAT,
    }));

    initFormValues({
      penalties: penaltiesData,
    });
  }, [initFormValues, penalties]);

  const sendClickAddPenaltyEvent = useSendClickAddPenaltyButtonEvent();

  const handleClickOnPenaltyButton = useCallback(() => {
    if (penaltiesHiddenField.value?.length === 0) {
      sendClickAddPenaltyEvent('final-statement');
    }
    drawerPenalty.onOpen();
  }, [drawerPenalty, penaltiesHiddenField.value?.length, sendClickAddPenaltyEvent]);

  const progressStatementPenalties = penalties.progressStatement?.penaltiesGroupBySubProjectId || {};
  const progressStatementPenaltiesTotalAmountIncVAT = penalties.progressStatement?.totalAmountIncVAT || 0;

  const handleOnSubmit = async (penaltyFormValues: PenaltyFormValues) => {
    const newPenalties: IPenaltyUpsertDTO[] = Object.entries(penaltyFormValues)
      .filter(([, value]) => value.amountIncVAT !== undefined && value.denomination !== undefined)
      .map(([key, value]) => ({
        id: key,
        name: value.denomination || '',
        amountIncVAT: value.amountIncVAT || 0,
      }));

    penaltiesHiddenField.setValue(newPenalties);
    drawerPenalty.onClose();
  };

  return (
    <>
      <Form form={finalStatementEditWizardForm}>
        <HiddenField {...penaltiesHiddenField} />
        {Object.entries(progressStatementPenalties).length !== 0 && (
          <Section title={t('project:finalStatement.penalty.title')}>
            <Stack spacing={4}>
              {Object.entries(progressStatementPenalties).map(([subProjectId, psPenalties], index) => (
                <Table templateColumns={['minmax(auto, 15rem)', '3fr', '3fr', '1fr']} bg="white">
                  {index === 0 && (
                    <Table.Header>
                      <Table.Cell>{t('project:finalStatement.penalty.fields.number')}</Table.Cell>
                      <Table.Cell>{t('project:finalStatement.penalty.fields.progressStatementName')}</Table.Cell>
                      <Table.Cell>{t('project:finalStatement.penalty.fields.penaltyName')}</Table.Cell>
                      <Table.Cell right>{t('project:finalStatement.penalty.fields.penaltyAmountIncVat')}</Table.Cell>
                    </Table.Header>
                  )}

                  {Object.entries(progressStatementPenalties)?.length > 1 && (
                    <Table.Row key={subProjectId} bg="gray.200" textColor="gray.900">
                      <Table.Cell>{psPenalties[0].subProjectName}</Table.Cell>
                      <Table.Cell />
                      <Table.Cell />
                      <Table.Cell />
                    </Table.Row>
                  )}
                  {psPenalties.map((penalty) => (
                    <Table.Row key={penalty.id}>
                      <Table.Cell variant="ellipsis">{penalty.invoiceNumber}</Table.Cell>
                      <Table.Cell variant="ellipsis">{penalty.progressStatementName}</Table.Cell>
                      <Table.Cell variant="ellipsis">{penalty.name}</Table.Cell>
                      <Table.Cell right>{formatAsAmount(penalty.amountIncVAT)}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table>
              ))}
            </Stack>
            <HStack justifyContent="space-between">
              <Box>
                {!penaltiesHiddenField.value?.length && (
                  <Button variant="outline" leftIcon={<SimpleAddIcon />} onClick={handleClickOnPenaltyButton}>
                    {t('penalty:drawer.addPenalty')}
                  </Button>
                )}
              </Box>

              <Box>
                <AmountSummary>
                  <AmountSummary.Item
                    label={t('project:finalStatement.penalty.totalProgressStatement')}
                    amount={progressStatementPenaltiesTotalAmountIncVAT}
                    important
                  />
                </AmountSummary>
              </Box>
            </HStack>
          </Section>
        )}

        {penaltiesHiddenField.value && penaltiesHiddenField.value.length !== 0 && (
          <Section title="Pénalités DGD">
            <Table templateColumns={['1fr', '1fr']} bg="white">
              <Table.Header>
                <Table.Cell>{t('project:finalStatement.penalty.fields.penaltyName')}</Table.Cell>
                <Table.Cell right>{t('project:finalStatement.penalty.fields.penaltyAmountIncVat')}</Table.Cell>
              </Table.Header>
              {penaltiesHiddenField.value?.map((penalty) => (
                <Table.Row key={penalty.id}>
                  <Table.Cell>{penalty.name}</Table.Cell>
                  <Table.Cell right>{formatAsAmount(penalty.amountIncVAT)}</Table.Cell>
                </Table.Row>
              ))}
            </Table>
            <HStack justifyContent="space-between">
              <Box>
                <Button variant="outline" leftIcon={<SimpleSettingsIcon />} onClick={drawerPenalty.onOpen}>
                  {t('penalty:drawer.handlePenalty')}
                </Button>
              </Box>

              <Box>
                <AmountSummary>
                  <AmountSummary.Item
                    label={t('project:finalStatement.penalty.totalFinalStatement')}
                    amount={sumObjects(penaltiesHiddenField.value || [], 'amountIncVAT')}
                    important
                  />
                </AmountSummary>
              </Box>
            </HStack>
          </Section>
        )}
      </Form>

      {!penaltiesHiddenField.value?.length && Object.entries(progressStatementPenalties).length === 0 && (
        <Flex height="100%" alignItems="center" justifyContent="center">
          <Onboarding
            icon={<SimpleHiddenCostIcon boxSize={45} />}
            message={t('project:finalStatement.penalty.emptyState')}
            actionMessage={t('penalty:drawer.addPenalty')}
            action={drawerPenalty.onOpen}
          />
        </Flex>
      )}
      <DrawersStack drawersStack={drawersStack}>
        <PenalyDrawer
          drawer={drawerPenalty}
          onSubmit={handleOnSubmit}
          existingPenalties={penaltiesHiddenField.value || []}
          descriptionLabel={DescriptionLabel.FINAL_STATEMENT}
        />
      </DrawersStack>
    </>
  );
};
