import { Fragment } from 'react';
import { Box, Flex, Grid } from '@chakra-ui/react';
import type { FieldValues } from 'graneet-form';
import { usePlaceholder, useWizardContext, VALIDATION_OUTCOME } from 'graneet-form';

import { Button } from '../Button/Button';

import type { WizardLabels } from './types';
import { useInternalWizardContext } from './hooks';

export const FOOTER_HEIGHT = '3rem';

export type PlacementKey = 'HEADER_LEFT' | 'HEADER_RIGHT' | 'FOOTER_RIGHT' | 'FOOTER_LEFT';
export const PLACEMENT: Record<string, PlacementKey> = {
  HEADER_LEFT: 'HEADER_LEFT',
  HEADER_RIGHT: 'HEADER_RIGHT',
  FOOTER_LEFT: 'FOOTER_LEFT',
  FOOTER_RIGHT: 'FOOTER_RIGHT',
};

export interface FooterProps {
  labels: WizardLabels;
}

export const Footer = <WizardValues extends Record<string, FieldValues>>({ labels }: FooterProps) => {
  const { steps, currentStep, isFirstStep, isLastStep, isStepReady } = useWizardContext<WizardValues>();
  const { displaySecondaryButtons } = useInternalWizardContext();

  const { handleOnNext, handleOnPrevious, placeholderContent, isLoading, stepStatus } = usePlaceholder();

  const displayIsNotReady = isLoading || !isStepReady || stepStatus === VALIDATION_OUTCOME.UNDETERMINED;
  const formIsInvalid = stepStatus === VALIDATION_OUTCOME.INVALID;

  return (
    <Box
      boxShadow="0px 2px 5px -2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
      zIndex={3}
      h={FOOTER_HEIGHT}
      bg="white"
      borderRadius="0 0 8px 8px"
    >
      <Grid h="100%" templateColumns="1fr auto 1fr" alignItems="center" gap={4}>
        <Flex mx={3} alignItems="flex-start" ml={35}>
          {displaySecondaryButtons[currentStep] && (
            <Button
              size="lg"
              colorScheme="blue"
              variant="outline"
              boxShadow="md"
              isDisabled={displayIsNotReady}
              onClick={handleOnPrevious}
            >
              {isFirstStep ? labels.quit : labels.previous}
            </Button>
          )}

          {placeholderContent && placeholderContent[PLACEMENT.FOOTER_LEFT]}
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          {steps.length > 1 &&
            steps.map((name, i) => (
              <Fragment key={name as string}>
                {i !== 0 && <Box w="40px" h="1px" bg="greenBrand.light" />}
                <Box
                  boxSize="24px"
                  bg={currentStep === name ? 'greenBrand.light' : 'white'}
                  borderRadius="12px"
                  borderWidth={1}
                  borderColor="greenBrand.light"
                />
              </Fragment>
            ))}
        </Flex>

        <Flex justifyContent="flex-end" alignItems="center">
          {placeholderContent && placeholderContent[PLACEMENT.FOOTER_RIGHT]}

          <Button
            ml={4}
            mr={35}
            type="submit"
            size="lg"
            colorScheme="blue"
            variant="solid"
            boxShadow="md"
            onClick={handleOnNext}
            isLoading={displayIsNotReady}
            loadingText={labels.saving}
            isDisabled={formIsInvalid}
          >
            {isLastStep ? labels.save : labels.next}
          </Button>
        </Flex>
      </Grid>
    </Box>
  );
};
