import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import * as jobsApi from '../services/quote-job.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * delete file of job.
 */
export const useDeleteJobFile = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (jobId: number, fileId: string) => {
      if (fileId) {
        const [err, result] = await jobsApi.deleteJobFile(jobId, fileId);
        if (err) {
          toast.error(t('quote:jobsStep.errors.deleteJobFileError'));
          // To reset state in the image list component
          throw new Error('Error on deleting job file');
        }
        return updateDataLocally(result);
      }
      throw new Error('No file Id was provided');
    },
    [updateDataLocally, toast, t],
  );
};
