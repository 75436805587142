import type { FC } from 'react';
import { useCallback } from 'react';
import { Wizard } from '@graneet/lib-ui';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { ValueOf } from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';

import { EditCreditInformationsStep } from './_steps/EditCreditInformationsStep';

import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { useCancelDownPayment } from 'features/down-payment/services/down-payment.api';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useCancelInvoice } from 'features/invoice/services/invoice.api';
import { getStatementType } from 'features/statement/services/statement.util';
import type { STATEMENT_PATHS } from 'features/statement/constants/statement.constant';
import type { CreditEditionWizard } from 'features/credit/forms/CreditEditionWizard';
import { useProgressStatementCancel } from 'features/progress-statement/services/progress-statement.api';

interface CreateCreditScreenProps {
  onStatementCancelled(params: { projectId: number; creditId: number }): void;

  onQuit(params: { type: ValueOf<typeof STATEMENT_PATHS>; statementId: number; projectId: number }): void;
}

export const CreateCreditScreen: FC<CreateCreditScreenProps> = ({ onStatementCancelled, onQuit }) => {
  const { t } = useTranslation(['credit', 'global']);

  const {
    projectId: projectIdRaw,
    type,
    statementId: statementIdRaw,
  } = useParams<{ projectId: string; type: ValueOf<typeof STATEMENT_PATHS>; statementId: string }>();

  const projectId = Number(projectIdRaw);
  const statementId = Number(statementIdRaw);

  const { state: { cancelledInvoiceNumber, createDraft } = {} } = useLocation<
    undefined | { cancelledInvoiceNumber: string; createDraft: boolean }
  >();

  const cancelledStatementType = getStatementType(type);

  useHideNavbar();

  const wizardLabels = useWizardLabels({
    save: t('credit:wizard.actions.validate'),
  });

  const handleQuit = useCallback(() => {
    onQuit({
      type,
      statementId,
      projectId,
    });
  }, [onQuit, projectId, statementId, type]);

  const cancelInvoiceMutation = useCancelInvoice({ cancelledInvoiceNumber });
  const cancelDownPaymentMutation = useCancelDownPayment({ cancelledInvoiceNumber });
  const progressStatementCancelMutation = useProgressStatementCancel({ cancelledInvoiceNumber });

  const onWizardFinished = useCallback(
    async (formValues: CreditEditionWizard) => {
      let credit;

      const dto = {
        createDraft,
        ...formValues.information,
        note: formValues.information.note || undefined,
      };

      if (cancelledStatementType === STATEMENT_TYPES.INVOICE) {
        const response = await cancelInvoiceMutation.mutateAsync({ id: statementId, dto });

        credit = response.credit;
      } else if (
        cancelledStatementType &&
        [STATEMENT_TYPES.PROGRESS_STATEMENT, STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT].includes(
          cancelledStatementType as STATEMENT_TYPES,
        )
      ) {
        const response = await progressStatementCancelMutation.mutateAsync({
          id: statementId,
          dto,
        });

        credit = response.credit;
      } else if (cancelledStatementType === STATEMENT_TYPES.DOWN_PAYMENT) {
        const response = await cancelDownPaymentMutation.mutateAsync({ id: statementId, dto });

        credit = response.credit;
      } else {
        throw new Error('Statement undefined');
      }

      if (credit) {
        onStatementCancelled({
          projectId,
          creditId: credit.id,
        });
      }
    },
    [
      createDraft,
      cancelledStatementType,
      cancelInvoiceMutation,
      statementId,
      progressStatementCancelMutation,
      cancelDownPaymentMutation,
      onStatementCancelled,
      projectId,
    ],
  );

  return (
    <Wizard<CreditEditionWizard>
      headerTitle={t('credit:title', { invoiceNumber: cancelledInvoiceNumber })}
      labels={wizardLabels}
      onQuit={handleQuit}
      onFinish={onWizardFinished}
    >
      <Wizard.Step<CreditEditionWizard> name="information">
        <EditCreditInformationsStep cancelledInvoiceNumber={cancelledInvoiceNumber} />
      </Wizard.Step>
    </Wizard>
  );
};
