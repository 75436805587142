import type { FC } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { Form, HiddenField, useHiddenField, useOnChangeValues, useStepForm } from 'graneet-form';
import type { FieldsetRef } from '@graneet/lib-ui';
import {
  CheckboxField,
  Fieldset,
  PercentageField,
  RadioGroupField,
  TextField,
  Tooltip,
  SimpleHelpIcon,
  SingleSelectField,
  formatNumberToVatRate,
  CurrencyField,
  LabeledData,
  useCurrency,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Grid, HStack, Image, ListItem, Stack, Text, UnorderedList, VStack } from '@chakra-ui/react';
import type { ISubProject } from '@graneet/business-logic';
import {
  computeHoldbackAmountIncVAT,
  DEDUCTION_TYPE,
  DEFAULT_HOLDBACK_PERCENTAGE,
  FEATURE_FLAGS,
  isSubProjectBillableAsProgressStatement,
  PROGRESS_STATEMENT_SUMMARY_TEMPLATES,
  PROGRESS_STATEMENT_TEMPLATES,
  SUB_PROJECT_TYPES,
} from '@graneet/business-logic';

import { CustomHoldbackFieldset } from './CustomHoldbackFieldset';

import { Rule } from 'features/form/rules/Rule';
import {
  getCustomHoldbackNameField,
  getCustomHoldbackPercentageField,
  type ProjectEditParametersForm,
  type ProjectEditParametersWizard,
} from 'features/project/forms/project-edit-parameters-wizard';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { FeatureFlag } from 'features/feature-flag/components/FeatureFlag';

interface EditSubProjectParametersStepProps {
  subProject: ISubProject;
  hasBillingStarted: boolean;
}
export const EditSubProjectParametersStep: FC<EditSubProjectParametersStepProps> = ({
  subProject,
  hasBillingStarted,
}) => {
  const { t } = useTranslation(['project']);

  const { form, initFormValues } = useStepForm<ProjectEditParametersWizard, 'editParams'>();
  const { formatAsAmount, mapNumberToAmount } = useCurrency();

  const hasProjectGlobalParametersActive = useFeatureFlag(FEATURE_FLAGS.PROJECT_GLOBAL_PARAMETERS);

  const { holdbackHasPaymentGuarantee, holdbackPercentage } = useOnChangeValues(form, [
    'holdbackHasPaymentGuarantee',
    'holdbackPercentage',
  ]);

  const prorataFieldRef = useRef<FieldsetRef>(null);

  const priceRevisionField = useHiddenField(form, 'hasPriceRevision');
  const deductionNameField = useHiddenField(form, 'deductionName');
  const holdbackPercentageField = useHiddenField(form, 'holdbackPercentage');

  useEffect(() => {
    if (
      hasProjectGlobalParametersActive &&
      isSubProjectBillableAsProgressStatement(subProject, {
        ignoreReversalOfLiability: true,
      })
    ) {
      const formValues: Partial<ProjectEditParametersForm> = {};
      if (subProject.deductions) {
        subProject.deductions.forEach((deduction) => {
          // There should be only one prorata deduction by sub project
          if (deduction.type === DEDUCTION_TYPE.PRORATA) {
            prorataFieldRef.current?.handleIsChecked();
            formValues.deductionName = deduction.name;
            formValues.deductionVatRate = formatNumberToVatRate(deduction.vatRate!);
            formValues.deductionPercentage = deduction.percentage;
          } else {
            formValues[getCustomHoldbackNameField(deduction.id.toString())] = deduction.name;
            formValues[getCustomHoldbackPercentageField(deduction.id.toString())] = deduction.percentage;
          }
        });
      }

      initFormValues({
        type: subProject.type ?? SUB_PROJECT_TYPES.PRIVATE,
        progressStatementTemplate:
          (subProject.progressStatementTemplate as any as PROGRESS_STATEMENT_TEMPLATES) ??
          PROGRESS_STATEMENT_TEMPLATES.PROGRESS_STATEMENT_CLASSIC,
        summaryTemplate: subProject.summaryTemplate ?? PROGRESS_STATEMENT_SUMMARY_TEMPLATES.NORMAL,
        holdbackHasPaymentGuarantee: subProject.holdback?.hasPaymentGuarantee,
        holdbackPaymentGuaranteeAmount: subProject.holdback?.paymentGuaranteeAmount
          ? mapNumberToAmount(subProject.holdback?.paymentGuaranteeAmount)
          : undefined,
        holdbackPercentage: subProject.holdback?.holdbackPercentage ?? DEFAULT_HOLDBACK_PERCENTAGE,
        holdbackBankName: subProject.holdback?.bankName ?? undefined,
        hasPriceRevision: subProject.hasPriceRevision ?? false,
        ...formValues,
      });
    } else {
      initFormValues({
        hasPriceRevision: false,
        deductionName: t('project:parameters.fields.deduction.defaultValue'),
        holdbackPercentage: DEFAULT_HOLDBACK_PERCENTAGE,
      });
    }
  }, [t, hasProjectGlobalParametersActive, subProject, initFormValues, mapNumberToAmount]);

  const holdbackAmountIncVAT = useMemo(() => {
    if (holdbackPercentage) {
      return computeHoldbackAmountIncVAT(subProject, holdbackPercentage);
    }
    return 0;
  }, [holdbackPercentage, subProject]);

  const hasAccountingStandards = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARDS);
  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  return (
    <Form form={form}>
      <Stack spacing={4} mt={4} mb={10} w={{ xl: '75%' }}>
        <Fieldset
          handle="project-type"
          title={t('project:parameters.fields.type.title')}
          legend={
            <HStack spacing={2} alignItems="center">
              <Text>{t('project:parameters.fields.type.legend')}</Text>
              <Tooltip
                p={4}
                shouldWrapChildren
                size="xl"
                label={
                  <>
                    <Text>{t('project:parameters.tooltips.type.heading')}</Text>
                    <UnorderedList>
                      <ListItem dangerouslySetInnerHTML={{ __html: t('project:parameters.tooltips.type.items.0') }} />
                    </UnorderedList>
                  </>
                }
              >
                <SimpleHelpIcon />
              </Tooltip>
            </HStack>
          }
          validationNames={['type']}
        >
          <RadioGroupField<ProjectEditParametersForm> name="type" rules={<Rule.IsRequired />}>
            {[SUB_PROJECT_TYPES.PRIVATE, SUB_PROJECT_TYPES.PUBLIC].map((key) => (
              <RadioGroupField.Option key={key} value={key} label={t(`project:parameters.fields.type.labels.${key}`)} />
            ))}
          </RadioGroupField>
        </Fieldset>

        <Fieldset
          handle="pdf-template"
          title={t('project:parameters.fields.psPdfModel.title')}
          validationNames={['progressStatementTemplate', 'summaryTemplate']}
          legend={t('project:parameters.fields.psPdfModel.legend')}
        >
          <Grid gap={4} templateColumns="20rem 20rem">
            {hasAccountingStandards && (
              <>
                <Text>
                  <Tooltip
                    maxWidth="35rem"
                    label={<Image src={t('project:parameters.images.summaryTemplatesUrl')} />}
                    placement="right"
                    shouldWrapChildren
                  >
                    {t('project:parameters.fields.psPdfModel.summaryTemplate.title')}
                    <SimpleHelpIcon boxSize={5} ml={2} />
                  </Tooltip>
                </Text>
                <SingleSelectField<ProjectEditParametersForm>
                  name="summaryTemplate"
                  options={[
                    {
                      label: t('project:parameters.fields.psPdfModel.summaryTemplate.normal'),
                      value: PROGRESS_STATEMENT_SUMMARY_TEMPLATES.NORMAL,
                    },
                    {
                      label: t('project:parameters.fields.psPdfModel.summaryTemplate.detailed'),
                      value: PROGRESS_STATEMENT_SUMMARY_TEMPLATES.VAT_COLUMNS,
                    },
                  ]}
                  isClearable={false}
                >
                  <Rule.IsRequired />
                </SingleSelectField>
              </>
            )}

            <Text>
              <Tooltip
                maxWidth="35rem"
                label={<Image src={t('project:parameters.images.pdfModelsUrl')} />}
                placement="right"
                shouldWrapChildren
              >
                {t('project:parameters.fields.psPdfModel.progressTemplate.title')}
                <SimpleHelpIcon boxSize={5} ml={2} />
              </Tooltip>
            </Text>
            <SingleSelectField<ProjectEditParametersForm>
              name="progressStatementTemplate"
              options={[
                {
                  label: t('project:parameters.fields.psPdfModel.progressTemplate.classic'),
                  value: PROGRESS_STATEMENT_TEMPLATES.PROGRESS_STATEMENT_CLASSIC,
                },
                {
                  label: t('project:parameters.fields.psPdfModel.progressTemplate.full'),
                  value: PROGRESS_STATEMENT_TEMPLATES.PROGRESS_STATEMENT_FULL,
                },
              ]}
              isClearable={false}
            >
              <Rule.IsRequired />
            </SingleSelectField>
          </Grid>
        </Fieldset>

        <FeatureFlag feature={FEATURE_FLAGS.PROJECT_GLOBAL_PARAMETERS}>
          <Fieldset
            handle="holdback"
            variant="switch"
            title={t('project:parameters.fields.holdback.title')}
            legend={!hasProjectGlobalParametersActive && t('project:parameters.fields.holdback.legend')}
            validationNames={[]}
            defaultValue={!!subProject.holdback}
            isDisabled={hasBillingStarted}
            onOpen={() => {
              if (!hasProjectGlobalParametersActive) {
                holdbackPercentageField.setValue(DEFAULT_HOLDBACK_PERCENTAGE);
              }
            }}
            onClose={() => {
              if (!hasProjectGlobalParametersActive) {
                holdbackPercentageField.setValue(undefined);
              }
            }}
          >
            <VStack align="stretch" gap={3}>
              <FeatureFlag.OnActive>
                <HStack gap={24} mt={2}>
                  <PercentageField<ProjectEditParametersForm>
                    name="holdbackPercentage"
                    label={t('project:parameters.fields.holdback.labels.holdbackPercentage')}
                    w="50%"
                    isRequired
                    isDisabled={hasBillingStarted}
                  >
                    <Rule.IsRequired />
                  </PercentageField>
                  <LabeledData
                    label={t('project:parameters.fields.holdback.labels.holdbackAmountIncVAT')}
                    data={<Box>{formatAsAmount(holdbackAmountIncVAT ?? 0)} </Box>}
                  />
                </HStack>
              </FeatureFlag.OnActive>
              <CheckboxField<ProjectEditParametersForm>
                name="holdbackHasPaymentGuarantee"
                label={t('project:parameters.fields.holdback.labels.hasGuarantee')}
                isDisabled={hasBillingStarted && !!subProject.holdback?.hasPaymentGuarantee}
                onChange={(e) => {
                  // Set the default value of holdbackPaymentGuaranteeAmount when holdbackHasPaymentGuarantee is true
                  if (e.target.checked) {
                    if (!subProject.holdback?.paymentGuaranteeAmount) {
                      form.setFormValues({
                        holdbackPaymentGuaranteeAmount: mapNumberToAmount(holdbackAmountIncVAT),
                      });
                    }
                  }
                }}
              />
              <Collapse in={holdbackHasPaymentGuarantee} animateOpacity unmountOnExit>
                <HStack gap={24}>
                  <FeatureFlag.OnActive>
                    <CurrencyField<ProjectEditParametersForm>
                      name="holdbackPaymentGuaranteeAmount"
                      label={t('project:parameters.fields.holdback.labels.holdbackPaymentGuaranteeAmount')}
                      w="50%"
                      min={0}
                    >
                      {hasBillingStarted && (
                        <Rule.IsHigherOrEqualThan
                          amount={mapNumberToAmount(subProject.holdback?.paymentGuaranteeAmount ?? 0)}
                          message={t('project:parameters.fields.holdback.paymentGuaranteeAmountValidation', {
                            amount: formatAsAmount(subProject.holdback?.paymentGuaranteeAmount ?? 0),
                          })}
                        />
                      )}
                      <Rule.IsRequired />
                    </CurrencyField>
                  </FeatureFlag.OnActive>
                  <TextField<ProjectEditParametersForm>
                    name="holdbackBankName"
                    label={t('project:parameters.fields.holdback.labels.holdbackBankName')}
                    w="40%"
                    isDisabled={hasBillingStarted && !!subProject.holdback?.hasPaymentGuarantee}
                  />
                </HStack>
              </Collapse>
            </VStack>
            <FeatureFlag.OnFallback>
              <HiddenField {...holdbackPercentageField} />
            </FeatureFlag.OnFallback>
          </Fieldset>
        </FeatureFlag>

        <Fieldset
          variant="switch"
          handle="deduction"
          title={t('project:parameters.fields.deduction.title')}
          legend={t('project:parameters.fields.deduction.legend')}
          validationNames={[]}
          innerRef={prorataFieldRef}
          isDisabled={hasBillingStarted}
        >
          <HiddenField {...deductionNameField} />
          <Grid templateColumns="2fr 1fr 2fr" gap={4}>
            <PercentageField<ProjectEditParametersForm>
              name="deductionPercentage"
              label={t('project:parameters.fields.deduction.labels.percentage')}
              isRequired
              isDisabled={hasBillingStarted}
            >
              <Rule.IsRequired />
            </PercentageField>
            {hasStandardVATRates ? (
              <AccountingVATField<ProjectEditParametersForm>
                name="deductionVatRate"
                label={t('project:parameters.fields.deduction.labels.vat')}
                valueScale={100}
                isRequired
                isDisabled={hasBillingStarted}
              >
                <Rule.IsRequired />
              </AccountingVATField>
            ) : (
              <PercentageField<ProjectEditParametersForm>
                name="deductionVatRate"
                label={t('project:parameters.fields.deduction.labels.vat')}
                w="20%"
                isRequired
                isDisabled={hasBillingStarted}
              >
                <Rule.IsRequired />
              </PercentageField>
            )}
          </Grid>
        </Fieldset>

        <CustomHoldbackFieldset subProject={subProject} hasBillingStarted={hasBillingStarted} />

        <Fieldset
          variant="switch"
          handle="price-revision"
          title={t('project:parameters.fields.priceRevision.title')}
          legend={t('project:parameters.fields.priceRevision.legend')}
          validationNames={[]}
          noCard
          defaultValue={!!subProject.hasPriceRevision}
          isDisabled={hasBillingStarted && !!subProject.hasPriceRevision}
          onOpen={() => priceRevisionField.setValue(true)}
        >
          <HiddenField {...priceRevisionField} />
        </Fieldset>
      </Stack>
    </Form>
  );
};
