import '@ag-grid-community/styles/ag-grid.css';
import { Box, VStack } from '@chakra-ui/react';
import { AgGridReact } from '@ag-grid-community/react';
import type { ChangeEvent, FC, RefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type QuoteBasicItemObject, type Quote, ContentTypeGuard } from '@org/quotation-lib';
import type { SelectionChangedEvent } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import type { RichTextGetterHandler } from '@graneet/lib-ui';
import { QuotationInput, RichTextGetter } from '@graneet/lib-ui';

import { useDefaultColDef } from '../hooks/useDefaultColDef';
import { useBasicItemsSupplyColumnDefs } from '../hooks/useGridBasicItemsSupplyColumnDefs';

import { updateQuoteDrawerRichTextEditorRef } from 'features/quotation/quote-common/store/quoteUpdateZustand';

export interface AgGridQuoteBasicItemsSupplyProps {
  quote: Quote;
  gridRef: RefObject<AgGridReact<QuoteBasicItemObject>>;
  onSelect: (selection: unknown[]) => void;
}

export const AgGridQuoteBasicItemsSupply: FC<AgGridQuoteBasicItemsSupplyProps> = ({ quote, gridRef, onSelect }) => {
  const { t } = useTranslation(['global']);

  const [input, setInput] = useState<string | null>(null);

  const columnDefs = useBasicItemsSupplyColumnDefs();
  const defaultColDef = useDefaultColDef();

  const rowsData = useMemo(() => {
    const basicItems: QuoteBasicItemObject[] = [];
    quote
      .getTree()
      .getNodeStack()
      .forEach((node) => {
        const content = node.getContent();
        if (ContentTypeGuard.isQuoteBasicItem(content)) {
          basicItems.push(content.export());
        }
      });
    return basicItems;
  }, [quote]);

  const onFilterTextBoxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value);
  }, []);

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      onSelect(event.api.getSelectedRows());
    },
    [onSelect],
  );

  const editorRef = useRef<RichTextGetterHandler>(null);

  useEffect(() => {
    updateQuoteDrawerRichTextEditorRef(editorRef);
  }, []);

  return (
    <VStack align="stretch" w="100%" h="100%" gap={4}>
      <Box paddingLeft="1rem" width="300px">
        <QuotationInput
          placeholder={t('global:words.c.research')}
          onChange={onFilterTextBoxChanged}
          icon={<i className="ri-search-line" />}
          pl={30}
        />
      </Box>
      <Box className="ag-theme-quartz quotation component-type" width="100%" height="100%">
        <AgGridReact
          ref={gridRef}
          rowData={rowsData}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowSelection="multiple"
          rowHeight={45}
          headerHeight={44}
          onSelectionChanged={handleSelectionChanged}
          reactiveCustomComponents
          suppressNoRowsOverlay
          suppressContextMenu
          suppressRowClickSelection
          quickFilterText={input ?? ''}
        />
      </Box>
      <RichTextGetter ref={editorRef} />
    </VStack>
  );
};
