import { multiplyFloating, roundFloating, sumObjects } from '../common/math.util';
import type { IVatBases } from '../vat/vat-bases.type';
import { computeAmountOfVATBase } from '../vat/vat-rates.util';
import { isNil } from '../common/object.util';
import { formatDateOrEmpty } from '../common/date.util';
import { VAT_TYPE } from '../vat/constant/vat-type.constant';

import type { IOrder } from './order.type';
import type { IOrderItem } from './order-item.type';

export const computeOrderItemAmountExVAT = (
  orderItem: Partial<Pick<IOrderItem, 'unitPriceExVAT' | 'quantity'>>,
): number => roundFloating(multiplyFloating(orderItem.quantity || 0, orderItem.unitPriceExVAT || 0));

export const computeOrderLotAmountExVAT = (nestedItems: Pick<IOrderItem, 'amountExVAT'>[]): number =>
  sumObjects(nestedItems, 'amountExVAT');

export const computeOrderVatDistributionFromItems = (
  items: Pick<IOrderItem, 'amountExVAT' | 'vatRate'>[],
  vatType: VAT_TYPE,
): IVatBases => {
  if (vatType !== VAT_TYPE.NORMAL) {
    return [];
  }

  const baseOfVatRates = new Map<number, number>();

  items.forEach((item) => {
    const { vatRate } = item;
    if (!isNil(vatRate)) {
      baseOfVatRates.set(vatRate, (baseOfVatRates.get(vatRate) || 0) + item.amountExVAT);
    }
  });

  return [...baseOfVatRates.entries()].map(([vatRate, base]) => ({
    vatRate,
    base,
    amount: computeAmountOfVATBase({ base, vatRate }),
  }));
};

export const computeOrderAmountIncVAT = (amountExVAT: number | null, vatBases: IVatBases, vatType: VAT_TYPE): number =>
  vatType !== VAT_TYPE.NORMAL ? amountExVAT || 0 : (amountExVAT || 0) + sumObjects(vatBases, 'amount');

type OrderAmounts = {
  amountExVAT: number | null;

  vatDistribution: IVatBases;

  amountIncVAT: number;
};

export const computeOrderAmounts = (
  partialOrder: Pick<IOrder, 'hasUnitPrices' | 'amountExVAT' | 'vatType'> & {
    vatRate: number | null;
  },
  items?: Pick<IOrderItem, 'amountExVAT' | 'vatRate'>[],
): OrderAmounts => {
  if (partialOrder.hasUnitPrices && items && items?.length > 0) {
    const amountExVAT = computeOrderLotAmountExVAT(items);
    const vatDistribution = computeOrderVatDistributionFromItems(items, partialOrder.vatType);
    const amountIncVAT = computeOrderAmountIncVAT(amountExVAT, vatDistribution, partialOrder.vatType);

    return {
      amountExVAT,
      vatDistribution,
      amountIncVAT,
    };
  }

  const { vatRate, amountExVAT } = partialOrder;

  const vatDistribution =
    vatRate !== null && vatRate !== undefined
      ? [
          {
            vatRate,
            base: amountExVAT || 0,
            amount: computeAmountOfVATBase({ vatRate, base: amountExVAT || 0 }),
          },
        ]
      : [];
  const amountIncVAT = computeOrderAmountIncVAT(amountExVAT, vatDistribution, partialOrder.vatType);

  return {
    amountExVAT,
    vatDistribution,
    amountIncVAT,
  };
};

export const formatOrderFileName = (order: IOrder): string =>
  [order?.orderNumber, order?.supplier?.name, formatDateOrEmpty(order.orderDate)]
    .filter(Boolean)
    .join(' - ')
    .replace(/\//g, '-');
