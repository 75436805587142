import type { FC, PropsWithChildren } from 'react';
import { useMemo, useCallback } from 'react';
import type { BoxProps } from '@chakra-ui/react';
import { Box, Menu, useDisclosure } from '@chakra-ui/react';

import { RichSelectProvider } from './RichSelectProvider.context';
import { RichSelectMenuButton } from './RichSelectMenuButton';
import { RichSelectMenuItem } from './RichSelectMenuItem';
import { RichSelectMenuItems } from './RichSelectMenuItems';
import { useRichSelectContext } from './useRichSelectContext.hook';
import { RichSelectMenuHeader } from './RichSelectMenuHeader';

const RichSelectMenu: FC<PropsWithChildren<BoxProps> & { autoSelect?: boolean }> = ({
  children,
  autoSelect,
  ...otherProps
}) => {
  const { onOpen, onClose, isActive, isOpen, buttonRef, hasError, border } = useRichSelectContext();

  const isBorderVisible = isActive || isOpen;

  const borderColor = useMemo(() => {
    if (hasError) {
      return 'red.500';
    }
    if (isBorderVisible) {
      return 'greenBrand.light';
    }
    return 'gray.200';
  }, [hasError, isBorderVisible]);

  return (
    <Box
      w="100%"
      bg="inherit"
      _hover={{ bg: 'inherit' }}
      borderWidth={border ? '1px' : 0}
      borderRadius="8px"
      borderColor={borderColor}
      height="1.75rem"
      ref={buttonRef}
      {...otherProps}
    >
      <Menu
        strategy="fixed"
        isLazy
        offset={[9, 6]}
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        autoSelect={autoSelect}
      >
        {children}
      </Menu>
    </Box>
  );
};

export interface RichSelectProps<Value extends string | string[] | Record<string, number | string>>
  extends PropsWithChildren<Omit<BoxProps, 'border'>> {
  onOptionSelected(option: Value): void;

  isDisabled?: boolean;

  isActive?: boolean;

  hasError?: boolean;

  onFocus?(): void;

  onClose?(): void;

  onOpen?: () => void;

  autoSelect?: boolean;

  size?: 'sm' | 'md';

  border?: boolean;
}

export const RichSelectComponent = <Value extends string | string[] | Record<string, number | string>>({
  isDisabled = false,
  isActive = false,
  hasError = false,
  onFocus = () => {},
  onOptionSelected,
  onClose: handleClose,
  onOpen: handleOpenProp,
  children,
  size = 'md',
  border = true,
  ...otherProps
}: RichSelectProps<Value>) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: handleClose,
    onOpen: handleOpenProp,
  });

  const handleOpen = useCallback(() => {
    if (isDisabled) return;
    onOpen();
  }, [isDisabled, onOpen]);

  return (
    <RichSelectProvider
      isDisabled={isDisabled}
      isOpen={isOpen}
      onOpen={handleOpen}
      onClose={onClose}
      onFocus={onFocus}
      isActive={isActive}
      hasError={hasError}
      onOptionSelected={onOptionSelected}
      size={size}
      border={border}
    >
      <RichSelectMenu {...otherProps}>{children}</RichSelectMenu>
    </RichSelectProvider>
  );
};

/**
 * @deprecated Please use Single Select instead
 */
export const RichSelect = Object.assign(RichSelectComponent, {
  Button: RichSelectMenuButton,
  Items: RichSelectMenuItems,
  Item: RichSelectMenuItem,
  Header: RichSelectMenuHeader,
});
