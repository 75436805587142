import type { ReactNode } from 'react';

import type { EllipsisTextProps } from '../EllipsisText';
import { EllipsisText } from '../EllipsisText';

export interface HeaderTitleProps extends EllipsisTextProps {
  children?: ReactNode;
}

export const HeaderTitle = ({ children, ...props }: HeaderTitleProps) => (
  <EllipsisText as="h1" ml={4} fontSize="lg" fontFamily="heading" data-testid="headerTitle" {...props}>
    {children}
  </EllipsisText>
);
