import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Callout,
  CheckboxField,
  IntegerField,
  Modal,
  SimpleThunderIcon,
  SingleSelectField,
  TextField,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IReminder, IReminderWithRelations } from '@graneet/business-logic';
import { Form, HiddenField, useForm, useFormStatus, useOnBlurValues } from 'graneet-form';
import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/react';

import { useCreateReminder, useUpdateReminder } from '../services/reminder.api';
import { RuleUniqDayAfterDeadline } from '../forms/RuleUniqDayAfterDeadline';

import { EmailTemplateCard } from 'features/email-template/components/EmailTemplateCard';
import { useEmailTemplates } from 'features/email-template/services/email-template.api';
import { Rule } from 'features/form/rules/Rule';

interface FormValues {
  name: string;

  daysAfterDeadline: number;

  isDaysAfterDeadlinePositive: `${boolean}`;

  emailTemplateId: string;

  automaticSend: false;
}

interface ReminderUpsertModalProps {
  onClose: () => void;

  isOpen: boolean;

  reminder?: IReminderWithRelations;

  reminders: IReminder[];
}

export const ReminderUpsertModal: FC<ReminderUpsertModalProps> = ({ onClose, isOpen, reminder, reminders }) => {
  const { t } = useTranslation(['global']);

  const emailTemplates = useEmailTemplates({ type: 'statement' });

  const createReminderMutation = useCreateReminder();
  const updateReminderMutation = useUpdateReminder();

  const form = useForm<FormValues>();
  useEffect(() => {
    form.setFormValues({
      name: reminder?.name,
      daysAfterDeadline: reminder ? Math.abs(reminder.daysAfterDeadline) : undefined,
      emailTemplateId: reminder?.emailTemplate.id,
      isDaysAfterDeadlinePositive: reminder ? `${reminder.daysAfterDeadline > 0}` : 'true',
    });
  }, [form, reminder]);
  const { isValid } = useFormStatus(form);

  const isDaysAfterDeadlinePositiveOptions = useMemo(
    () => [
      { label: t('global:reminders.upsertModal.beforeDueDate'), value: 'false' },
      { label: t('global:reminders.upsertModal.afterDueDate'), value: 'true' },
    ],
    [t],
  );

  const emailTemplateOptions = useMemo(
    () =>
      emailTemplates.data.map((emailTemplate) => ({
        label: emailTemplate.title,
        value: emailTemplate.id,
      })),
    [emailTemplates.data],
  );

  const { emailTemplateId: selectedEmailTemplateId, isDaysAfterDeadlinePositive } = useOnBlurValues(form, [
    'emailTemplateId',
    'isDaysAfterDeadlinePositive',
  ]);
  const emailTemplate = emailTemplates.data.find((emailModel) => emailModel.id === selectedEmailTemplateId);

  const onSubmit = useCallback(async () => {
    const formValues = form.getFormValues();

    if (reminder) {
      await updateReminderMutation.mutateAsync({
        id: reminder.id,
        dto: {
          name: formValues.name!,
          emailTemplateId: formValues.emailTemplateId!,
        },
      });
    } else {
      await createReminderMutation.mutateAsync({
        name: formValues.name!,
        daysAfterDeadline:
          formValues.isDaysAfterDeadlinePositive === 'true'
            ? formValues.daysAfterDeadline!
            : -formValues.daysAfterDeadline!,
        emailTemplateId: formValues.emailTemplateId!,
        automaticSend: formValues.automaticSend ?? false,
      });
    }
    onClose();
  }, [createReminderMutation, form, onClose, reminder, updateReminderMutation]);

  return (
    <Modal title={t('global:reminders.upsertModal.title')} onClose={onClose} isOpen={isOpen}>
      <Form form={form}>
        <Stack spacing={6}>
          <TextField<FormValues> name="name" isRequired label={t('global:reminders.fields.name')}>
            <Rule.IsRequired />
          </TextField>

          {reminder ? (
            <>
              <HiddenField<FormValues> name="daysAfterDeadline" />
              <HiddenField<FormValues> name="isDaysAfterDeadlinePositive" />
            </>
          ) : (
            <Flex justifyContent="space-between" alignItems="center">
              <Text color="gray.800" fontWeight={600}>
                {t('global:reminders.upsertModal.addReminders')}
              </Text>
              <HStack>
                <Box w="4rem">
                  <IntegerField<FormValues> name="daysAfterDeadline" min={0}>
                    <RuleUniqDayAfterDeadline
                      reminders={reminders}
                      isPositive={isDaysAfterDeadlinePositive === 'true'}
                    />
                    <Rule.IsRequired />
                  </IntegerField>
                </Box>

                <Text color="gray.600">{t('global:words.day_plural')}</Text>
                <Box w="11rem">
                  <SingleSelectField<FormValues>
                    name="isDaysAfterDeadlinePositive"
                    options={isDaysAfterDeadlinePositiveOptions}
                    isClearable={false}
                  >
                    <Rule.IsRequired />
                  </SingleSelectField>
                </Box>
              </HStack>
            </Flex>
          )}

          {!reminder && (
            <Flex direction="column" gap={2}>
              <CheckboxField<FormValues>
                label={
                  <Flex alignItems="center" gap={2}>
                    <Text>{t('global:reminders.upsertModal.automaticSend')}</Text>
                    <SimpleThunderIcon fill="yellow.500" stroke="yellow.500" />
                  </Flex>
                }
                name="automaticSend"
              >
                <Rule.IsRequired />
              </CheckboxField>
              <Text>{t('global:reminders.upsertModal.automaticSendDescription')}</Text>
              <Callout>{t('global:reminders.upsertModal.automaticSendCallout')}</Callout>
            </Flex>
          )}

          <Flex justifyContent="space-between" alignItems="center">
            <Text color="gray.800" fontWeight={600}>
              {t('global:reminders.upsertModal.associatedEmailModel')}
            </Text>

            <Box w="15rem">
              <SingleSelectField<FormValues> name="emailTemplateId" options={emailTemplateOptions} isClearable={false}>
                <Rule.IsRequired />
              </SingleSelectField>
            </Box>
          </Flex>

          {emailTemplate && <EmailTemplateCard emailTemplate={emailTemplate} />}
        </Stack>
      </Form>

      <Modal.SecondaryButton
        isDisabled={updateReminderMutation.isPending || createReminderMutation.isPending}
        onClick={onClose}
      >
        {t('global:words.c.cancel')}
      </Modal.SecondaryButton>
      <Modal.PrimaryButton
        isDisabled={!isValid}
        isLoading={updateReminderMutation.isPending || createReminderMutation.isPending}
        onClick={onSubmit}
      >
        {t('global:words.c.save')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
