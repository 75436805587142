import { HStack, Stack, Text } from '@chakra-ui/react';
import { Modal, NumberField, TextField } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { IOvertime } from '@graneet/business-logic';

import { useCreateOvertimeType, useUpdateOvertimeType } from '../services/overtime-type.api';

import { Rule } from 'features/form/rules/Rule';

interface FormValues {
  title: string;
  coefficient: number;
}

interface OvertimeTypeEditModalProps {
  onClose(): void;

  isOpen: boolean;

  initialOvertimeType?: IOvertime | null;
}

const FORM_NAME = 'OvertimeTypeEditModal';

export const OvertimeTypeEditModal: FC<OvertimeTypeEditModalProps> = ({ onClose, isOpen, initialOvertimeType }) => {
  const { t } = useTranslation(['global', 'planning']);

  const createOvertimeTypeMutation = useCreateOvertimeType();
  const updateOvertimeTypeMutation = useUpdateOvertimeType();

  const form = useForm<FormValues>({
    defaultValues: {
      title: initialOvertimeType?.title,
      coefficient: initialOvertimeType ? parseFloat(initialOvertimeType.coefficient.toString()) : undefined,
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (initialOvertimeType) {
        await updateOvertimeTypeMutation.mutateAsync({
          id: initialOvertimeType.id,
          dto: formValues,
        });
      } else {
        await createOvertimeTypeMutation.mutateAsync(formValues);
      }

      onClose();
    },
    [initialOvertimeType, onClose, updateOvertimeTypeMutation, createOvertimeTypeMutation],
  );

  return (
    <Modal
      title={
        initialOvertimeType
          ? t('planning:overtimeCard.editModal.editCard')
          : t('planning:overtimeCard.addModal.addCard')
      }
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior="inside"
    >
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)} id={FORM_NAME}>
        <Text mb={6}>{t('planning:overtimeCard.editModal.retroactiveMessage')}</Text>

        <Stack>
          <HStack>
            <TextField<FormValues> name="title" label={t('planning:overtimeCard.fields.name')} isRequired>
              <Rule.IsRequired />
            </TextField>

            <NumberField<FormValues>
              name="coefficient"
              label={t('planning:overtimeCard.fields.coefficient')}
              isRequired
              min={0}
              type="decimal"
            >
              <Rule.IsRequired />
            </NumberField>
          </HStack>
        </Stack>

        <Modal.Close isDisabled={createOvertimeTypeMutation.isPending || updateOvertimeTypeMutation.isPending} />

        <Modal.PrimaryButton
          isLoading={createOvertimeTypeMutation.isPending || updateOvertimeTypeMutation.isPending}
          isDisabled={!isFormValid}
          type="submit"
          form={FORM_NAME}
        >
          {initialOvertimeType ? t('global:words.c.edit') : t('global:words.c.add')}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
