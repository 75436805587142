import type { FC } from 'react';
import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { Table, Card, AmountSummary } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { compact } from 'lodash-es';

import { CashOutflowPaymentRow } from './CashOutFlowPaymentRow';

import { usePermissions } from 'features/role/hooks/usePermissions';

interface CashOutflowPaymentsTableProps {
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}

export const CashOutflowPaymentsTable: FC<CashOutflowPaymentsTableProps> = ({ supplierInvoice }) => {
  const { t } = useTranslation(['cashOutflow']);

  const hasPaySupplierInvoicePermission = usePermissions([PERMISSION.PAY_SUPPLIER_INVOICE]);
  const templateColumns = useMemo(
    () => compact(['2fr', '2fr', '2fr', hasPaySupplierInvoicePermission ? '1fr' : null]),
    [hasPaySupplierInvoicePermission],
  );

  const { supplierInvoicePayments, amountIncVAT, remainingToBePaidIncVAT } = supplierInvoice;

  const totalToBePaidIncVAT = amountIncVAT - remainingToBePaidIncVAT;

  if (!supplierInvoice?.supplierInvoicePayments?.length) {
    return null;
  }

  return (
    <>
      <Card width="100%" title={t('cashOutflow:cards.paymentsCard.title')}>
        <Box borderRadius="6px" boxShadow="subtle" overflow="hidden">
          <Table templateColumns={templateColumns} noCard>
            <Table.Header>
              <Table.Cell>{t('cashOutflow:cards.paymentsCard.paymentFields.type')}</Table.Cell>
              <Table.Cell center>{t('cashOutflow:cards.paymentsCard.paymentFields.paidAt')}</Table.Cell>
              <Table.Cell center>{t('cashOutflow:cards.paymentsCard.paymentFields.totalIncVAT')}</Table.Cell>
              {hasPaySupplierInvoicePermission && <Table.Cell right />}
            </Table.Header>

            {(supplierInvoicePayments || []).map((supplierInvoicePayment) => (
              <CashOutflowPaymentRow
                key={supplierInvoicePayment.id}
                supplierInvoice={supplierInvoice}
                supplierInvoicePayment={supplierInvoicePayment}
              />
            ))}
          </Table>
        </Box>
      </Card>
      <Box>
        <AmountSummary>
          <AmountSummary.Item
            light
            label={t('cashOutflow:cards.paymentsCard.totalPaidIncVAT')}
            amount={totalToBePaidIncVAT}
          />
          <AmountSummary.Item
            warning={remainingToBePaidIncVAT !== 0}
            important
            label={t('cashOutflow:cards.paymentsCard.remainingToBePaidIncVAT')}
            amount={remainingToBePaidIncVAT}
          />
        </AmountSummary>
      </Box>
    </>
  );
};
