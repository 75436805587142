import type {
  IMailingInitialValuesResponseDTO,
  IMailingInitialValuesDTO,
  IMailingSendWithSenderAndRecipientDTO,
  IEmailWithRelations,
} from '@graneet/business-logic';
import type { FileWithOptions } from '@graneet/lib-ui';

import { api } from 'features/api/services/api.service';
import { dtoToFormData } from 'features/api/services/api.util';

const MAILING_PATH = '/mailing';

export const sendMail = (
  dto: IMailingSendWithSenderAndRecipientDTO,
  files: FileWithOptions[],
  mode: 'manual' | 'template',
) => {
  const formData = dtoToFormData(dto);
  files.slice(1).forEach((attachment: FileWithOptions) => {
    formData.append('files', attachment.file as File, attachment.file.name);
  });
  return api.postWithMultiPart<FormData, void>(`${MAILING_PATH}/send`, formData, { params: { mode } });
};

export const getMailingInitialValues = (dto: IMailingInitialValuesDTO) =>
  api.get<IMailingInitialValuesDTO, IMailingInitialValuesResponseDTO>(`${MAILING_PATH}/initial-values`, dto);

export const getEmailWithRelationsById = (emailId: string) =>
  api.get<string, IEmailWithRelations>(`${MAILING_PATH}/${emailId}`);
