import type { FC } from 'react';
import { useCallback } from 'react';
import { Grid, VStack, HStack, useDisclosure } from '@chakra-ui/react';
import type { IClientResponseDTO, IContact } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Onboarding, SimpleContactIcon, useToast, Button, Fieldset } from '@graneet/lib-ui';
import { HiddenField, useFormContext, useHiddenField } from 'graneet-form';

import type { ProjectCreateForm } from '../../forms/project-create-wizard';
import { getContactAssociatedToClientPaginated } from '../../../contact/services/contact.api';

import { ProjectClientMultiContactFieldset } from './ProjectClientMultiContactFieldset';

import { Rule } from 'features/form/rules/Rule';
import type { ClientContactSelectionStepperModalProps } from 'features/client/components/modals/ClientExistingAssociateContactStepperModal/ClientContactSelectionStepperModal';
import { ClientContactSelectionStepperModal } from 'features/client/components/modals/ClientExistingAssociateContactStepperModal/ClientContactSelectionStepperModal';
import { ClientContactCreateStepperModal } from 'features/client/components/modals/ClientContactCreateStepperModal/ClientContactCreateStepperModal';
import { ClientCardTypeEnum } from 'features/client/components/cards/ClientCardDetails';
import { getClientById } from 'features/client/services/client.api';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

interface ProjectClientMultiContactCreateFieldsetProps {
  reloadData: () => Promise<void>;
}

export const ProjectClientMultiContactCreateFieldset: FC<ProjectClientMultiContactCreateFieldsetProps> = ({
  reloadData,
}) => {
  const { t } = useTranslation(['global', 'clients', 'project', 'quote']);
  const toast = useToast();

  const form = useFormContext<ProjectCreateForm>();
  const primaryClientHiddenField = useHiddenField(form, 'primaryClient');

  const {
    onOpen: onAssociateExistingOpen,
    onClose: onAssociateExistingClose,
    isOpen: isAssociateExistingOpen,
  } = useDisclosure();
  const { onOpen: onCreateOpen, onClose: onCreateClose, isOpen: isCreateOpen } = useDisclosure();

  const updateUniqueClient = useCallback(
    async (clientId: number | null) => {
      if (clientId) {
        const [err, response] = await getClientById(clientId);
        if (err) {
          toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
        }
        if (response) {
          form.setFormValues({
            primaryClient: response,
          });
          return response;
        }
      }
      return null;
    },
    [form, t, toast],
  );

  const onClientCreated = useCallback(
    async (clientResponse: IClientResponseDTO) => {
      form.setFormValues({
        primaryClient: clientResponse,
      });
    },
    [form],
  );

  const onClientSelected = useCallback(
    async (clientId: number) => {
      const client = await updateUniqueClient(clientId);
      if (client) {
        form.setFormValues({
          primaryClient: client,
          contacts: [],
        });
      }

      return client;
    },
    [form, updateUniqueClient],
  );

  const onContactCreated = useCallback(
    (contact: IContact, data: { isDefaultEmailRecipient: boolean }) => {
      const formValues = form.getFormValues();
      form.setFormValues({
        contacts: [
          ...(formValues.contacts ?? []),
          { entity: contact, isDefaultEmailRecipient: data.isDefaultEmailRecipient },
        ],
      });
    },
    [form],
  );

  const onContactsSelected = useCallback(
    (contacts: Array<{ entity: IContact; isDefaultEmailRecipient: boolean }>) => {
      form.setFormValues({
        contacts,
      });
    },
    [form],
  );

  const contactsRetriever = useCallback<ClientContactSelectionStepperModalProps<IContact>['contactsRetriever']>(
    (selectedClientId: number) => [{ clientId: selectedClientId }, getContactAssociatedToClientPaginated],
    [],
  );

  return (
    <Fieldset<ProjectCreateForm>
      handle="client"
      title={t('clients:clientInformation')}
      legend={t('project:flow.legend')}
      validationNames={['contacts', 'primaryClient']}
    >
      <HiddenField<ProjectCreateForm> name="contacts" />

      <HiddenField<ProjectCreateForm> {...primaryClientHiddenField}>
        <Rule.IsRequired />
      </HiddenField>

      <Grid>
        {primaryClientHiddenField.value && (
          <ProjectClientMultiContactFieldset reloadData={reloadData} type={ClientCardTypeEnum.CHANGE} />
        )}

        {!primaryClientHiddenField.value && (
          <VStack gap={8}>
            <Onboarding
              message={t('project:flow.client.emptyState')}
              icon={<SimpleContactIcon boxSize={45} />}
              h="auto"
              action={
                <HStack gap={4}>
                  <Button onClick={onAssociateExistingOpen} variant="outline">
                    {t('quote:clientStep.cta.clientExisting')}
                  </Button>
                  <Button onClick={onCreateOpen} colorScheme="blue" marginLeft="auto">
                    {t('clients:createClient')}
                  </Button>
                </HStack>
              }
            />
          </VStack>
        )}

        <ClientContactSelectionStepperModal
          isOpen={isAssociateExistingOpen}
          onClose={onAssociateExistingClose}
          onClientSelected={onClientSelected}
          onSubmit={onContactsSelected}
          contactsRetriever={contactsRetriever}
          isContactDefaultEmailRecipient={(contact) => contact.isDefaultEmailRecipient}
        />
        <ClientContactCreateStepperModal
          isOpen={isCreateOpen}
          onClose={onCreateClose}
          onClientCreated={onClientCreated}
          onContactCreated={onContactCreated}
        />
      </Grid>
    </Fieldset>
  );
};
