import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@chakra-ui/react';
import { Callout, Card, TwoColumns, useToast } from '@graneet/lib-ui';
import { useHistory, useRouteMatch } from 'react-router-dom';
import type {
  ICompanyPaymentTermsUpdateDTO,
  IPaymentMethodResponseDTO,
  IProjectWithRelations,
} from '@graneet/business-logic';
import { FEATURE_FLAGS, PERMISSION } from '@graneet/business-logic';

import { ProjectInformationCard } from 'features/project/components/cards/ProjectInformationsCard';
import { HoldbackCard } from 'features/holdback/components/HoldbackCard';
import { usePaymentMethods } from 'features/payment-method/services/payment-method.api';
import { ProjectDeleteSection } from 'features/project/components/ProjectDeleteSection';
import { PaymentMethodEditionAndSelectionCard } from 'features/payment-method/components/PaymentMethodEditionAndSelectionCard';
import { PaymentDeadlineCard } from 'features/payment/components/PaymentDeadlineCard';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { ProjectSettingsCard } from 'features/project/components/cards/ProjectSettingsCard';
import { useSubProjectsByProject } from 'features/sub-project/services/sub-project.api';
import { ProjectTagsCard } from 'features/project/components/cards/ProjectTagsCard';
import { AccountManagerAssociationCard } from 'features/account-manager/components/AccountManagerAssociationCard/AccountManagerAssociationCard';
import { ClientDetailCard } from 'features/client/components/ClientDetailCard';
import { ClientDetailCardUpdateBilledFooter } from 'features/client/components/ClientDetailCardUpdateBilledFooter';
import { useProjectUpdate, useProjectUpdateAssociateAccountManagers } from 'features/project/services/project.api';
import { HoldbackCardNew } from 'features/holdback/components/HoldbackCardNew';
import { FeatureFlag } from 'features/feature-flag/components/FeatureFlag';

interface ViewProjectInformationSubTabProps {
  project: IProjectWithRelations;
}

export const ViewProjectInformationSubTab: FC<ViewProjectInformationSubTabProps> = ({ project }) => {
  const { t } = useTranslation(['project', 'accountManager', 'paymentMethods', 'holdback', 'statement', 'global']);
  const toast = useToast();
  const history = useHistory();
  const { url } = useRouteMatch();

  const hasCreateProjectsPermission = usePermissions([PERMISSION.CREATE_PROJECTS]);

  const paymentMethods = usePaymentMethods();
  const subProjects = useSubProjectsByProject(project.id);

  const projectUpdateMutation = useProjectUpdate();
  const projectUpdateAssociateAccountManagersMutation = useProjectUpdateAssociateAccountManagers();

  const accountManagerUsers = useMemo(
    () => project.projectAccountManagers.map(({ user }) => user),
    [project.projectAccountManagers],
  );

  // - Deadline
  const updateProjectPaymentDeadline = useCallback(
    async (paymentDeadlineInfos: ICompanyPaymentTermsUpdateDTO) => {
      await projectUpdateMutation.mutateAsync({ id: project.id, dto: paymentDeadlineInfos });
    },
    [project.id, projectUpdateMutation],
  );

  const saveNewProjectPaymentMethod = useCallback(
    async (newPaymentMethod: IPaymentMethodResponseDTO) => {
      await projectUpdateMutation.mutateAsync({
        id: project.id,
        dto: { paymentMethodId: newPaymentMethod.id },
      });
      toast.success(t('project:editPaymentMethodModal.success'));
    },
    [projectUpdateMutation, project.id, toast, t],
  );

  const onPaymentInfosUpdated = useCallback(() => {
    toast.success(t('paymentMethods:deadline.toast.update.success'));
  }, [t, toast]);

  const handleAccountManagersUpdated = useCallback(
    async (accountManagersIds: number[]) => {
      await projectUpdateAssociateAccountManagersMutation.mutateAsync({
        id: project.id,
        dto: { userIds: accountManagersIds },
      });
    },
    [project.id, projectUpdateAssociateAccountManagersMutation],
  );

  // - Navigation
  const goToSubProjectParameters = useCallback(() => {
    history.push(`/projects/${project.id}/sub-projects/${subProjects.data[0].id}/parameters`, {
      nextUrl: url,
      referrerUrl: url,
    });
  }, [history, project.id, subProjects, url]);

  // - UI
  return (
    <>
      <TwoColumns mb={8}>
        {/* Box is a container to avoid the same height */}
        <Box>
          <Grid gap={4}>
            <ProjectInformationCard project={project} />

            <ProjectSettingsCard subProjects={subProjects.data} onEditSubProjectParams={goToSubProjectParameters} />

            <AccountManagerAssociationCard
              associatedAccountManagerUsers={accountManagerUsers}
              onAccountManagersUpdated={handleAccountManagersUpdated}
              emptyStateLabel={t('accountManager:project.emptyState')}
              actionLabel={t('accountManager:cta.associateOneToProject')}
              changeAssociatedLabel={t('accountManager:cta.changeAssociatedProject')}
              modalActionLabel={t('accountManager:cta.associateToProject')}
              modalTitleLabel={t('accountManager:project.associateTitle')}
              modalDescriptionLabel={t('accountManager:project.associateDescription')}
              hasUpdatePermission={hasCreateProjectsPermission}
            />

            <FeatureFlag feature={FEATURE_FLAGS.PROJECT_GLOBAL_PARAMETERS}>
              {subProjects.data.length === 1 && subProjects.data[0].holdback && (
                <>
                  <FeatureFlag.OnActive>
                    <HoldbackCardNew
                      holdback={subProjects.data[0].holdback}
                      onEditSubProjectParams={goToSubProjectParameters}
                    />
                  </FeatureFlag.OnActive>

                  <FeatureFlag.OnFallback>
                    <HoldbackCard holdback={subProjects.data[0].holdback} />
                  </FeatureFlag.OnFallback>
                </>
              )}
            </FeatureFlag>

            {subProjects.data.length > 1 && subProjects.data.some((sp) => sp.holdback) && (
              <Card title={t('holdback:holdback')}>
                <Callout colorScheme="greenBrand">
                  {t('project:parameters.cannotEditHoldbackIfMultipleSubProjects')}
                </Callout>
              </Card>
            )}
          </Grid>
        </Box>

        {/* Box is a container to avoid same height */}
        <Box>
          <Grid gap={4}>
            {project.primaryClient && (
              <Box>
                <ClientDetailCard
                  client={project.primaryClient}
                  title={t('statement:cards.client')}
                  displayGoToLink
                  footer={null}
                />
                <ClientDetailCardUpdateBilledFooter project={project} />
              </Box>
            )}

            <PaymentMethodEditionAndSelectionCard
              paymentMethod={project.paymentMethod}
              paymentMethods={paymentMethods.data}
              selectPaymentMethodModalDescription={t('paymentMethods:modal.select.desc.project')}
              selectNewPaymentMethodLabel={t('project:editPaymentMethodModal.action')}
              onNewPaymentMethodSelected={saveNewProjectPaymentMethod}
              hasUpdatePermission={hasCreateProjectsPermission}
              displayUpdateButton={false}
            />

            <PaymentDeadlineCard
              paymentInfos={project}
              onPaymentInfosUpdate={updateProjectPaymentDeadline}
              onPaymentInfosUpdated={onPaymentInfosUpdated}
              hasUpdatePermission={hasCreateProjectsPermission}
            />

            <ProjectTagsCard project={project} />
          </Grid>
        </Box>
      </TwoColumns>

      <ProjectDeleteSection project={project} />
    </>
  );
};
