import { createMultiStyleConfigHelpers, defineStyle, extendTheme } from '@chakra-ui/react';
import { alertAnatomy, drawerAnatomy, modalAnatomy, statAnatomy } from '@chakra-ui/anatomy';

import { customSuccess, customError, customWarning, customInfo } from '../hooks/useToast/customThemeToast';

import 'remixicon/fonts/remixicon.css';

const { definePartsStyle, defineMultiStyleConfig: defineMultiStyleConfigDrawer } = createMultiStyleConfigHelpers(
  drawerAnatomy.keys,
);
const baseStyleDrawer = definePartsStyle({
  overlay: {
    bg: '#15181A99',
  },
  dialog: {
    borderTopLeftRadius: '0rem',
    borderBottomLeftRadius: '0rem',
  },
});

export const theme = extendTheme({
  fonts: {
    body: `'TWKLausanne400', sans-serif`,
    heading: `'TWKLausanne400', sans-serif`,
    mono: `'TWKLausanne400', monospace`,
  },
  colors: {
    primary: '#1E363B',
    primaryLightOpacity: '#15181A99',
    primaryLight: '#15181A',
    secondary: '',
    backgroundLight: '#F6F2EB', // Beige medium
    backgroundLighter: '#F9F7F1', // Beige
    gray300: { 500: '#BCCCDC' }, // See issue https://github.com/chakra-ui/chakra-ui/issues/569
    black: '#102A43',
    error: {
      background: '#FF4E4E',
      color: '#FFFFFF',
    },
    errorLight: {
      background: '#FFDCDC',
      color: '#FF4E4E',
    },
    success: {
      background: '#47C799',
      color: '#FFFFFF',
    },
    successLight: {
      background: '#DAF4EB',
      color: '#47C799',
    },
    warning: {
      background: '#FFAF37',
      color: '#FFFFFF',
    },
    warningLight: {
      background: '#FFEFD7',
      color: '#FFAF37',
    },
    yellowBrand: {
      base: '#EEE891',
      dark: '#E8E073',
      darker: '#DAD048',
      gradientStart: '#E8E073',
      gradientEnd: '#EEE891',
    },
    greenBrand: {
      base: '#122428',
      medium: '#1E363B',
      dark: '#1E363B',
      light: '#2B5058',
      lighter: '#4E7982',
      border: '#4E798299',
      background: '#C2D6DB99',
      lightText: '#60676B',
      500: '#2B5058',
      borderDefault: '#E0DFDC',
      baseSecondary: 'rgba(0, 1, 1, 0.62)',
      baseTertiary: 'rgba(6, 6, 0, 0.43)',
      backgroundNeutralSubtle: '#F2F2F0',
    },
    blue: {
      50: '#eff6ff',
      100: '#dbeafe',
      200: '#bfdbfe',
      300: '#93c5fd',
      400: '#60a5fa',
      500: '#3b82f6',
      600: '#2563eb',
      700: '#1d4ed8',
      800: '#1e40af',
      900: '#1e3a8a',
      950: '#172554',
    },
    gray: {
      50: '#F9F9F7',
      100: '#F2F2F0',
      150: '#f5f5f4',
      200: '#E0DFDC',
      300: '#C6C5C1',
      400: '#ADADA9',
      500: '#949491',
      600: '#7A7B7A',
      700: '#616262',
      800: '#484A4A',
      900: '#2E3132',
      950: '#15181A',
    },
    orange: {
      50: '#fff7ed',
      100: '#ffedd5',
      200: '#fed7aa',
      300: '#fdba74',
      400: '#fb923c',
      500: '#f97316',
      600: '#ea580c',
      700: '#c2410c',
      800: '#9a3412',
      900: '#7c2d12',
      950: '#431407',
    },
    yellow: {
      50: '#fefce8',
      100: '#fef9c3',
      200: '#fef08a',
      300: '#fde047',
      400: '#facc15',
      500: '#eab308',
      600: '#ca8a04',
      700: '#a16207',
      800: '#854d0e',
      900: '#713f12',
      950: '#422006',
    },
    red: {
      50: '#fef2f2',
      100: '#fee2e2',
      150: '#fecaca',
      200: '#fecaca',
      300: '#fca5a5',
      400: '#f87171',
      500: '#ef4444',
      600: '#dc2626',
      700: '#b91c1c',
      800: '#991b1b',
      900: '#7f1d1d',
      950: '#450a0a',
    },
    green: {
      50: '#f0fdf4',
      100: '#dcfce7',
      200: '#bbf7d0',
      300: '#86efac',
      400: '#4ade80',
      500: '#22c55e',
      600: '#16a34a',
      700: '#15803d',
      800: '#166534',
      900: '#14532d',
      950: '#052e16',
    },
    purple: {
      50: '#faf5ff',
      100: '#f3e8ff',
      200: '#e9d5ff',
      300: '#d8b4fe',
      400: '#a855f7',
      500: '#a855f7',
      600: '#9333ea',
      700: '#7e22ce',
      800: '#6b21a8',
      900: '#581c87',
      950: '#3b0764',
    },
    pink: {
      50: '#fdf2f8',
      100: '#fce7f3',
      200: '#fbcfe8',
      300: '#f9a8d4',
      400: '#f472b6',
      500: '#ec4899',
      600: '#db2777',
      700: '#be185d',
      800: '#9d174d',
      900: '#831843',
      950: '#500724',
    },
    teal: {
      50: '#f0fdfa',
      100: '#ccfbf1',
      200: '#99f6e4',
      300: '#5eead4',
      400: '#2dd4bf',
      500: '#14b8a6',
      600: '#0d9488',
      700: '#0f766e',
      800: '#115e59',
      900: '#134e4a',
      950: '#042f2e',
    },
    cyan: {
      50: '#ecfeff',
      100: '#cffafe',
      200: '#a5f3fc',
      300: '#67e8f9',
      400: '#22d3ee',
      500: '#06b6d4',
      600: '#0891b2',
      700: '#0e7490',
      800: '#155e75',
      900: '#164e63',
      950: '#083344',
    },
    indigo: {
      50: '#eef2ff',
      100: '#e0e7ff',
      200: '#c7d2fe',
      300: '#a5b4fc',
      400: '#818cf8',
      500: '#6366f1',
      600: '#4f46e5',
      700: '#4338ca',
      800: '#3730a3',
      900: '#312e81',
      950: '#1e1b4b',
    },
    rose: {
      50: '#fff1f2',
      100: '#ffe4e6',
      200: '#fecdd3',
      300: '#fda4af',
      400: '#fb7185',
      500: '#f43f5e',
      600: '#e11d48',
      700: '#be123c',
      800: '#9f1239',
      900: '#881337',
      950: '#4c0519',
    },
    fuchsia: {
      50: '#fdf4ff',
      100: '#fae8ff',
      200: '#f5d0fe',
      300: '#f0abfc',
      400: '#e879f9',
      500: '#d946ef',
      600: '#c026d3',
      700: '#a21caf',
      800: '#86198f',
      900: '#701a75',
      950: '#4a044e',
    },
    violet: {
      50: '#f5f3ff',
      100: '#ede9fe',
      200: '#ddd6fe',
      300: '#c4b5fd',
      400: '#a78bfa',
      500: '#8b5cf6',
      600: '#7c3aed',
      700: '#6d28d9',
      800: '#5b21b6',
      900: '#4c1d95',
      950: '#2e1065',
    },
    sky: {
      50: '#f0f9ff',
      100: '#e0f2fe',
      200: '#bae6fd',
      300: '#7dd3fc',
      400: '#38bdf8',
      500: '#0ea5e9',
      600: '#0284c7',
      700: '#0369a1',
      800: '#075985',
      900: '#0c4a6e',
      950: '#082f49',
    },
    emerald: {
      50: '#ecfdf5',
      100: '#d1fae5',
      200: '#a7f3d0',
      300: '#6ee7b7',
      400: '#34d399',
      500: '#10b981',
      600: '#059669',
      700: '#047857',
      800: '#065f46',
      900: '#064e3b',
      950: '#022c22',
    },
    lime: {
      50: '#f7fee7',
      100: '#ecfccb',
      200: '#d9f99d',
      300: '#bef264',
      400: '#a3e635',
      500: '#84cc16',
      600: '#65a30d',
      700: '#4d7c0f',
      800: '#3f6212',
      900: '#365314',
      950: '#1a2e05',
    },
    amber: {
      50: '#fffbeb',
      100: '#fef3c7',
      200: '#fde68a',
      300: '#fcd34d',
      400: '#fbbf24',
      500: '#f59e0b',
      600: '#d97706',
      700: '#b45309',
      800: '#92400e',
      900: '#78350f',
      950: '#451a03',
    },
  },
  breakpoints: {
    sm: '30rem',
    md: '48rem',
    lg: '62rem',
    xl: '80rem',
    '2xl': '90rem',
  },
  shadows: {
    sm: '0 4 4px -4px rgba(21,24,26,0.32)',
    md: '0 0 3px -1px rgba(21,24,26,0.04), 0 16px 24px -6px rgba(21,24,26,0.08)',
    lg: '0px 4px 6px -2px rgba(13, 18, 28, 0.08), 0px 12px 16px -4px rgba(13, 18, 28, 0.14)',

    // TODO DELETE
    blue: '0 0 10px rgba(16, 156, 241, 0.5)',
    'blue-lg': '0 0 10px 5px rgba(16, 156, 241, 0.5)',
    default:
      '0px 0px 0px 1px rgba(228, 235, 243, 1), 0px 2px 7px 0px rgba(16, 42, 67, 0.05), 0px 2px 5px -2px rgba(16, 42, 67, 0.06)',
    subtle: '0px 0px 0px 1px #E5E7E9, 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
  },
  spacings: {
    tooltipOffsetRightInArray: '-1.5rem',
  },
  radii: {
    md: '0.5rem',
  },
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: '4px',
          bg: 'white',
          borderColor: '#D2D6DB',
          borderWidth: '1.25px',
        },
        label: {
          overflow: 'auto',
        },
      },
    },
    Switch: {
      baseStyle: {
        thumb: {
          border: '1px solid rgba(0, 0, 0, 0.2)',
          borderColor: 'gray.500',
          bg: 'gray.300',
          _checked: {
            bg: 'greenBrand.light',
            border: '1px solid #fff',
            borderColor: 'greenBrand.base',
          },
        },
        track: {
          bg: 'white',
          border: '1px solid rgba(0, 0, 0, 0.2)',
          _checked: {
            bg: 'white',
            border: '1px solid rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
    Avatar: {
      baseStyle: {
        container: {
          backgroundColor: 'greenBrand.light',
          color: 'white',
          fontWeight: '600',
          fontSize: '0.75rem',
          fontFamily: 'Lato',
        },
        excessLabel: {
          backgroundColor: 'greenBrand.light',
          color: 'white',
          fontWeight: '600',
          opacity: '50%',
        },
      },
    },
    Drawer: defineMultiStyleConfigDrawer({
      baseStyle: baseStyleDrawer,
      sizes: {
        'fit-content': definePartsStyle({
          header: {
            fontSize: '1.125rem',
            fontWeight: 'regular',
          },
          dialog: defineStyle({
            maxW: 'fit-content',
          }),
        }),
        '2xl': definePartsStyle({
          header: {
            fontSize: '1.125rem',
            fontWeight: 'regular',
          },
          dialog: defineStyle({
            maxW: '86rem',
          }),
        }),
      },
    }),
    Modal: createMultiStyleConfigHelpers(modalAnatomy.keys).defineMultiStyleConfig({
      sizes: {
        '8xl': definePartsStyle({
          dialog: defineStyle({
            maxW: 'calc(100vw - 20rem)',
            minH: 'calc(100vh - 10rem)',
          }),
        }),
      },
    }),
    Menu: {
      parts: ['menu', 'item', 'list'],
      variants: {
        dropdown: {
          menu: {
            py: 0,
            minWidth: '10rem',
          },
          list: {
            minWidth: 'auto',
            borderRadius: '8px',
            py: '5px',
            shadow: 'md',
            // https://github.com/chakra-ui/chakra-ui/issues/3553#issuecomment-843043883
            '&:focus:not([data-focus-visible-added])': {
              shadow: 'md', // default shadow
            },
          },
          item: {
            color: 'gray.600',
            width: 'auto',
            cursor: 'pointer',
            pl: 2,
            pr: 4,
            py: 2,
            _first: {
              borderTopRadius: '0px',
            },
            _notLast: {
              borderBottom: '1px',
              borderBottomColor: 'gray.200',
            },
            _last: {
              borderBottomRadius: '0px',
            },
          },
        },
      },
    },
    Alert: createMultiStyleConfigHelpers(alertAnatomy.keys).defineMultiStyleConfig({
      variants: { customSuccess, customError, customWarning, customInfo },
    }),
    Stat: createMultiStyleConfigHelpers(statAnatomy.keys).defineMultiStyleConfig({
      baseStyle: {
        label: {
          fontSize: '0.875rem',
          fontWeight: 400,
          color: 'greenBrand.baseSecondary',
          lineHeight: '1.25rem',
        },
        number: {
          color: 'primaryLight',
          fontWeight: 400,
          fontSize: '1.125rem',
          lineHeight: '1.625rem',
        },
      },
    }),
  },
});
