import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleClientIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props} fill="none">
    <g clipPath="url(#clip0_159_2559)">
      <path
        d="M2.66666 14.5449C3.06838 14.6667 3.61097 14.6667 4.53331 14.6667H11.4666C12.389 14.6667 12.9316 14.6667 13.3333 14.5449M2.66666 14.5449C2.58053 14.5189 2.50087 14.4872 2.42533 14.4487C2.04901 14.2569 1.74305 13.951 1.5513 13.5747C1.33331 13.1468 1.33331 12.5868 1.33331 11.4667V4.53334C1.33331 3.41323 1.33331 2.85318 1.5513 2.42536C1.74305 2.04903 2.04901 1.74307 2.42533 1.55132C2.85315 1.33334 3.41321 1.33334 4.53331 1.33334H11.4666C12.5868 1.33334 13.1468 1.33334 13.5746 1.55132C13.951 1.74307 14.2569 2.04903 14.4486 2.42536C14.6666 2.85318 14.6666 3.41323 14.6666 4.53334V11.4667C14.6666 12.5868 14.6666 13.1468 14.4486 13.5747C14.2569 13.951 13.951 14.2569 13.5746 14.4487C13.4991 14.4872 13.4194 14.5189 13.3333 14.5449M2.66666 14.5449C2.66688 14.0054 2.67012 13.7199 2.71789 13.4797C2.92831 12.4219 3.75523 11.595 4.81307 11.3846C5.07067 11.3333 5.38044 11.3333 5.99998 11.3333H9.99998C10.6195 11.3333 10.9293 11.3333 11.1869 11.3846C12.2447 11.595 13.0716 12.4219 13.282 13.4797C13.3298 13.7199 13.333 14.0054 13.3333 14.5449M10.6666 6.33334C10.6666 7.80607 9.47271 9 7.99998 9C6.52722 9 5.33331 7.80607 5.33331 6.33334C5.33331 4.86058 6.52722 3.66667 7.99998 3.66667C9.47271 3.66667 10.6666 4.86058 10.6666 6.33334Z"
        stroke="#627D98"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_159_2559">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
