import type { FC, ReactNode } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { APP_FOOTER_PLACEHOLDER, Placeholder } from '../Placeholder/Placeholder';
import { FOOTER_HEIGHT } from '../Wizard/Footer';

import type { Pagination } from './Pagination';

export interface PaginatedRenderActionsProps<Data extends unknown> {
  selectedItems: Data[];

  currentFilters: URLSearchParams;

  resetSelectedCheckboxes(): void;

  hasAllCheckboxesSelected: boolean;
}

export interface PaginatedTableSelectBannerChildrenProps<Data extends unknown> {
  selectedItems: Data[];

  currentFilters: URLSearchParams;

  resetSelectedCheckboxes(): void;

  hasAllCheckboxesSelected: boolean;
}

export interface PaginatedTableSelectBannerProps<Data extends unknown> {
  selectLabel(numberOfSelectedItems: number): string;

  allSelectedLabel(totalNumberOfItems?: number): string;

  children?(props: PaginatedTableSelectBannerChildrenProps<Data>): ReactNode;

  renderActions: FC<PaginatedRenderActionsProps<Data>>;

  selectAll: boolean;

  selectedItems: Data[];

  numberOfRows: number;

  pagination: Pagination;

  resetSelectedCheckboxes(): void;
}

export const PaginatedTableSelectBanner = <Data extends unknown>({
  selectLabel,
  allSelectedLabel,
  children,
  renderActions: RenderActions,
  selectedItems,
  selectAll,
  numberOfRows,
  pagination,
  resetSelectedCheckboxes,
}: PaginatedTableSelectBannerProps<Data>) => {
  const currentFilters = new URLSearchParams(pagination.queryParams);

  return (
    <Placeholder.Inject name={APP_FOOTER_PLACEHOLDER}>
      <Flex
        px={6}
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        h={FOOTER_HEIGHT}
        borderColor="gray.200"
      >
        <Text alignSelf="center" fontSize={14}>
          {selectAll ? allSelectedLabel(numberOfRows) : selectLabel(selectedItems.length)}
        </Text>

        <Flex gap={2} height="1.75rem">
          {children?.({ selectedItems, currentFilters, resetSelectedCheckboxes, hasAllCheckboxesSelected: selectAll })}

          <RenderActions
            selectedItems={selectedItems}
            currentFilters={currentFilters}
            resetSelectedCheckboxes={resetSelectedCheckboxes}
            hasAllCheckboxesSelected={selectAll}
          />
        </Flex>
      </Flex>
    </Placeholder.Inject>
  );
};
