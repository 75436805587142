import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { t } from 'i18next';

import { getDeleteAction } from './quote.actions.util';
import type { QuotationActionsParams, QuoteActionsParams } from './quote.actions.type';

export function getQuoteImportingActions(
  params: QuoteActionsParams | QuotationActionsParams,
  full = true,
): DocumentStatusCardAction[] {
  const { hasCreatePermission, onEdit } = params;
  return [
    ...(full
      ? [
          {
            type: 'secondary' as const,
            label: t('quote:actions.modify'),
            tooltip: !hasCreatePermission ? t('global:errors.disabledAction') : undefined,
            isDisabled: !hasCreatePermission,
            onClick: onEdit,
          },
        ]
      : []),
    {
      type: 'tertiaryDropdown',
      options: [getDeleteAction(params)],
    },
  ];
}
