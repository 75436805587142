import type { IEmailTemplate } from '@graneet/business-logic';
import { isNil } from 'lodash-es';

import { useAppContext } from '../../app/contexts/AppContext';

export const useEmailTemplateQuota = (
  emailTemplates: IEmailTemplate[],
): {
  hasReachQuota: boolean;
  level: 'error' | 'warn' | 'none';
  quota: number | null;
  numberOfEmailTemplates: number;
} => {
  const { currentUser } = useAppContext();

  const numberOfEmailTemplates = emailTemplates.length;
  const emailTemplateCredit = currentUser?.company.emailTemplateCredit || null;

  // Unlimited quota
  if (isNil(emailTemplateCredit)) {
    return {
      hasReachQuota: false,
      level: 'none',
      quota: emailTemplateCredit,
      numberOfEmailTemplates,
    };
  }

  // The current number of email templates is equal or superior to the quota
  if (numberOfEmailTemplates === emailTemplateCredit || numberOfEmailTemplates > emailTemplateCredit) {
    return {
      hasReachQuota: true,
      level: 'error',
      quota: emailTemplateCredit,
      numberOfEmailTemplates,
    };
  }

  // The current number of email templates is close to the quota
  if (
    emailTemplateCredit - numberOfEmailTemplates === 1 || // last credit
    numberOfEmailTemplates / emailTemplateCredit >= 8 / 10 // 80% used
  ) {
    return {
      hasReachQuota: false,
      level: 'warn',
      quota: emailTemplateCredit,
      numberOfEmailTemplates,
    };
  }

  return {
    hasReachQuota: false,
    level: 'none',
    quota: emailTemplateCredit,
    numberOfEmailTemplates,
  };
};
