import type { FC } from 'react';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import type { IContactWithProjectInfos, IGroupedContact, IProjectWithRelations } from '@graneet/business-logic';

import { CreditDetail } from 'features/credit/components/CreditDetail';
import { useCredit } from 'features/credit/services/credit.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

interface ViewCreditDetailScreenProps {
  project: IProjectWithRelations;

  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
}

export const ViewCreditDetailScreen: FC<ViewCreditDetailScreenProps> = ({ project, projectContacts }) => {
  const { creditId } = useParams<{ creditId: string }>();
  const { t } = useTranslation(['statement']);
  const { title, breadCrumb, updateHeaderTitle } = useHeaderContext();
  const { pathname } = useLocation();

  const credit = useCredit(parseInt(creditId, 10));

  useLayoutEffect(() => {
    updateHeaderTitle(credit.data.name ?? '', [
      ...breadCrumb,
      {
        name: title ?? '',
        link: `/projects/${project.id}`,
      },
      {
        name: t('statement:title'),
        link: `/projects/${project.id}/statements`,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, credit.data.name, updateHeaderTitle, pathname]);

  return <CreditDetail credit={credit.data} project={project} projectContacts={projectContacts} />;
};
