// The pdf Settings follow a specific order

import type { GRANEET_PDF_COLORS } from '../common/constants/colors.constant';

// It is according to what position they take on the front interface
export enum PDF_DENSITY {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum PDF_LOGO_POSITION {
  LEFT = 'LEFT',
  CENTERED = 'CENTERED',
  FULL = 'FULL',
}

export enum PDF_LABELS_POSITION {
  NONE = 'NONE',
  HEADER = 'HEADER',
  UNDER_LOGO = 'UNDER_LOGO',
  FOOTER = 'FOOTER',
}

export enum PDF_COMPANY_ADDRESS_POSITION {
  NONE = 'NONE',
  ENVELOPPE_WINDOW = 'ENVELOPPE_WINDOW',
}

export enum PDF_COMPANY_LOT_INFORMATION {
  HIDE = 'hide',
  ONLY_AMOUNT = 'only-amount',
  FULL = 'full',
}

export enum PDF_FONT {
  ARIAL = 'Arial',
  GEORGIA = 'Georgia',
  OPEN_SANS = 'Open Sans',
  ROBOTO = 'Roboto',
  TIMES_NEW_ROMAN = 'Times New Roman',
  VERDANA = 'Verdana',
  CALIBRI = 'Calibri',
}

export interface IPdfSettings {
  id: number;

  density: PDF_DENSITY;

  logoSize: number; // 0-100

  labelsSize: number; // 0-100

  font: PDF_FONT;

  color: GRANEET_PDF_COLORS;

  logoPosition: PDF_LOGO_POSITION;

  labelsPosition: PDF_LABELS_POSITION;

  companyAddressPosition: PDF_COMPANY_ADDRESS_POSITION;

  areAccountManagersVisible: boolean;

  hasFirstPageCover: boolean;

  displayQuoteContacts: boolean;

  lotInformation: PDF_COMPANY_LOT_INFORMATION;

  displayExternalProgressStatementQrCode: boolean;

  createdAt: Date;

  updatedAt: Date;
}
