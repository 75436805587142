import { useCallback } from 'react';
import type { Quote, QuoteComponentsGroupByType } from '@org/quotation-lib';
import { MARGIN_TYPE } from '@org/quotation-lib';
import Big from 'big.js';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import {
  useQuoteSetClientRequestsStore,
  StatusEnum,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';

export function useQuoteLotUpdateTotalMarginByComponentType() {
  const { quoteLotsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, data: QuoteComponentsGroupByType, newValue: string): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
          this.oldValue = {
            totalMargin: Big(data.totalMargin ?? 1),
            data,
          };
          quoteLot.updateComponentTotalMarginByComponentType(data.componentTypeId, Big(newValue), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteLotsProxyApi
            .updateByComponentType(quoteLot.getId(), Number(data.componentTypeId), {
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              totalMargin: newValue,
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          quoteSetToStore(quote);
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
          quoteLot.updateComponentTotalMarginByComponentType(
            this.oldValue.data.componentTypeId,
            this.oldValue.totalMargin,
            {
              spreadUp: true,
              impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
            },
          );

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteLotsProxyApi
            .updateByComponentType(quoteLot.getId(), Number(this.oldValue.data.componentTypeId), {
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              totalMargin: this.oldValue.totalMargin,
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          quoteSetToStore(quote);
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteLotsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
