import { useCallback, type FC } from 'react';
import { Badge, Card, DataTable, SimpleDeleteIcon, SimpleEditIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Grid, GridItem, IconButton, Stack, Text, useDisclosure } from '@chakra-ui/react';
import type { IAccountingConfigBank, IAccountingConfigBankAccount } from '@graneet/business-logic';
import { StarIcon } from '@chakra-ui/icons';

import { AccountingAddBankAccountButton } from './AccountingAddBankAccountButton';
import { AccountingBankAccountCreateOrUpdateModal } from './AccountingBankAccountCreateOrUpdateModal';

import {
  useAccountingConfigCanDeleteBankAccount,
  useDeleteAccountingConfigBankAccount,
} from 'features/accounting/services/accounting-config.api';

interface AccountingBankAccountsEditionBlockProps {
  defaultAccountingConfigBankAccount?: IAccountingConfigBankAccount;
  accountingConfigBanks: IAccountingConfigBank[];
}

const BankAccountRow: FC<{
  accountingConfigBankAccount: IAccountingConfigBankAccount;
  isDefault: boolean;
}> = ({ isDefault, accountingConfigBankAccount }) => {
  const editModal = useDisclosure();

  const canDelete = useAccountingConfigCanDeleteBankAccount(accountingConfigBankAccount.id);

  const deleteAccountingConfigBankAccount = useDeleteAccountingConfigBankAccount();
  const onDelete = useCallback(() => {
    deleteAccountingConfigBankAccount.mutate(accountingConfigBankAccount.id);
  }, [accountingConfigBankAccount.id, deleteAccountingConfigBankAccount]);

  return (
    <>
      <AccountingBankAccountCreateOrUpdateModal
        isOpen={editModal.isOpen}
        onClose={editModal.onClose}
        existingAccountingConfigBankAccount={{ ...accountingConfigBankAccount, isDefault }}
      />
      <DataTable.Row label={accountingConfigBankAccount.label}>
        <DataTable.Cell>
          <Grid templateColumns="10fr auto auto auto">
            <GridItem>
              {accountingConfigBankAccount.account}
              <Badge ml={2} colorScheme="gray">
                {accountingConfigBankAccount.accountingConfigBank?.label}
              </Badge>
            </GridItem>
            <GridItem>{isDefault ? <StarIcon color="yellow.500" mr={3} /> : undefined}</GridItem>
            <GridItem>
              <IconButton
                aria-label="edit"
                variant="clear"
                icon={<SimpleEditIcon />}
                height="auto"
                onClick={editModal.onOpen}
              />
            </GridItem>
            <GridItem>
              <IconButton
                aria-label="delete"
                variant="clear"
                icon={<SimpleDeleteIcon />}
                height="auto"
                isDisabled={!canDelete.data?.canDelete}
                onClick={onDelete}
              />
            </GridItem>
          </Grid>
        </DataTable.Cell>
      </DataTable.Row>
    </>
  );
};

export const AccountingBankAccountsEditionBlock: FC<AccountingBankAccountsEditionBlockProps> = ({
  defaultAccountingConfigBankAccount,
  accountingConfigBanks,
}) => {
  const { t } = useTranslation(['accounting']);

  return (
    <Stack>
      <Text fontSize="lg" color="gray.800">
        {t('accounting:parameters.sections.bankAccounts')}
      </Text>

      <Card>
        <DataTable numberOfColumns={1}>
          {accountingConfigBanks
            .map((bank) =>
              (bank.accountingConfigBankAccounts ?? []).map((account) => (
                <BankAccountRow
                  key={account.id}
                  accountingConfigBankAccount={{ ...account, accountingConfigBank: bank }}
                  isDefault={account.id === defaultAccountingConfigBankAccount?.id}
                />
              )),
            )
            .flat()}
        </DataTable>

        <Box mt={4}>
          <AccountingAddBankAccountButton />
        </Box>
      </Card>
    </Stack>
  );
};
