import type { ValueOf } from '../common/types/object.type';

export const THIRD_PARTY = {
  ALOBEES: 'alobees',
  BATIPRIX: 'batiprix',
  BANKING: 'banking',
} as const;

export type ThirdParties = ValueOf<typeof THIRD_PARTY>;

export interface IThirdParty<Name extends ThirdParties> {
  name: Name;
  isUnderMaintenance: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface BatiprixElement {
  Label: string;
  Unit: {
    Label: string;
  };
  Quantity: number;
  UnitPrice: number;
}

export interface BatiprixJob {
  Label: string;
  Unit: {
    Label: string;
  };
  FlatCost: number;
  SellingPrice: number;
  Elements: BatiprixElement[];
  LaborQuantity: number;
  LaborUnitCost: number;
}
