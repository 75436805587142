import { useCallback, useRef } from 'react';
import { useCurrency, Wizard, useToast } from '@graneet/lib-ui';
import { useDisclosure } from '@chakra-ui/react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { IFinalStatementCreationDTO } from '@graneet/business-logic';
import { flatMap } from 'lodash-es';

import { ViewPenaltiesStep } from './_steps/penalties/ViewPenaltiesStep';
import { ViewContractsStep } from './_steps/contracts/ViewContractsStep';
import { ViewProgressStatementsStep } from './_steps/progress-statements/ViewProgressStatementsStep';
import { ViewPriceRevisionStep } from './_steps/price-revision/ViewPriceRevisionStep';
import { ViewBillsStep } from './_steps/bills/ViewBillsStep';
import { ViewDirectPaymentOrdersStep } from './_steps/direct-payment-orders/ViewDirectPaymentOrdersStep';
import { ViewSummaryStep } from './_steps/summary/ViewSummaryStep';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import {
  useFinalStatementCreate,
  useIsProjectReadyForFinalStatement,
  useProjectLastFinalStatement,
} from 'features/final-statement/services/final-statement.api';
import type { FinalStatementEditionWizard } from 'features/final-statement/forms/final-statement-edition.wizard';
import { usePenaltyByProject } from 'features/penalty/services/penalty.api';
import { Beacon } from 'features/beacon/components/Beacon';
import { FinalStatementQuitModal } from 'features/final-statement/components/modals/FinalStatementQuitModal';
import { formatPriceRevisionDTO } from 'features/final-statement/services/final-statement.util';
import {
  useProject,
  useProjectSubProjectsWithContracts,
  useProjectSubProjectsWithDirectPaymentOrders,
} from 'features/project/services/project.api';

export const CreateFinalStatementScreen = () => {
  const { t } = useTranslation(['project', 'global']);
  const toast = useToast();
  const { mapAmountToNumber } = useCurrency();

  useHideNavbar();

  const history = useHistory();
  const { projectId: projectIdRaw } = useParams<{ projectId: string }>();
  const projectId = parseInt(projectIdRaw, 10);

  const labels = useWizardLabels({ save: t('project:finalStatement.save') });
  const { onOpen: onOpenQuitModal, onClose: onCloseQuitModal, isOpen: isOpenQuitModal } = useDisclosure();

  const redirectUrlOnQuit = useRef<string | undefined>();

  const penalties = usePenaltyByProject(projectId);
  const lastFinalStatement = useProjectLastFinalStatement(projectId);
  const isProjectReadyForFinalStatement = useIsProjectReadyForFinalStatement(projectId);
  const project = useProject(projectId);
  const subProjectsWithContracts = useProjectSubProjectsWithContracts(projectId);
  const subProjectsWithDirectPaymentOrders = useProjectSubProjectsWithDirectPaymentOrders(projectId);

  const finalStatementCreateMutation = useFinalStatementCreate();

  const handleQuit = useCallback(() => {
    if (redirectUrlOnQuit.current) {
      history.push(redirectUrlOnQuit.current);
    }
  }, [history]);

  const openQuitModalWithRedirectTo = useCallback(
    (redirectUrl: string) => () => {
      redirectUrlOnQuit.current = redirectUrl;
      onOpenQuitModal();
    },
    [onOpenQuitModal],
  );

  const handleSubmit = useCallback(
    async (values: FinalStatementEditionWizard) => {
      const priceRevisionsValues = values.priceRevision;
      const finalStatementPenalties = values.penalties;
      const { name, date, note } = values.summary;
      const dto: IFinalStatementCreationDTO = {
        projectId: project.data.id,
        name,
        date,
        note,
        finalPriceRevisions: formatPriceRevisionDTO(priceRevisionsValues, mapAmountToNumber),
        penalties: finalStatementPenalties?.penalties || [],
      };

      await finalStatementCreateMutation.mutateAsync(dto);
      history.push(`/projects/${project.data.id}`);
    },
    [project, mapAmountToNumber, finalStatementCreateMutation, history],
  );

  if (!isProjectReadyForFinalStatement.data.isFinalStatementAvailable) {
    toast.error(t('global:errors.error'));
    history.push(`/projects/${projectId}`);
  }

  /**
   * Checks if any subproject has price revision and sets the value of displayPriceRevisionStep accordingly.
   */
  const displayPriceRevisionStep = subProjectsWithContracts.data.subProjects.some(
    (subProject) => subProject.hasPriceRevision,
  );

  const directPaymentOrders = flatMap(
    subProjectsWithDirectPaymentOrders.data.subProjects,
    (subProject) => subProject.directPaymentOrders,
  );
  const displayDirectPaymentsStep = directPaymentOrders.length > 0;

  return (
    <>
      <FinalStatementQuitModal onClose={onCloseQuitModal} isOpen={isOpenQuitModal} onQuit={handleQuit} />
      <Wizard<FinalStatementEditionWizard>
        headerTitle={t('project:finalStatement.title', { project: project.data.name })}
        onQuit={openQuitModalWithRedirectTo(`/projects/${projectId}`)}
        onFinish={handleSubmit}
        labels={labels}
      >
        <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_HELP}>
          <Beacon top="-4rem" right="0.6rem" />
        </Wizard.Placeholder>
        <Wizard.Step<FinalStatementEditionWizard> name="contracts">
          <ViewContractsStep project={project.data} subProjects={subProjectsWithContracts.data.subProjects} />
        </Wizard.Step>

        <Wizard.Step<FinalStatementEditionWizard> name="progressStatements">
          <QueryWrapper>
            <ViewProgressStatementsStep project={project.data} />
          </QueryWrapper>
        </Wizard.Step>

        {displayPriceRevisionStep && (
          <Wizard.Step<FinalStatementEditionWizard> name="priceRevision">
            <QueryWrapper>
              <ViewPriceRevisionStep project={project.data} />
            </QueryWrapper>
          </Wizard.Step>
        )}

        <Wizard.Step<FinalStatementEditionWizard> name="penalties">
          <ViewPenaltiesStep penalties={penalties.data} />
        </Wizard.Step>

        <Wizard.Step<FinalStatementEditionWizard> name="bills">
          <QueryWrapper>
            <ViewBillsStep project={project.data} />
          </QueryWrapper>
        </Wizard.Step>

        {displayDirectPaymentsStep && (
          <Wizard.Step<FinalStatementEditionWizard> name="directPayments">
            <ViewDirectPaymentOrdersStep subProjects={subProjectsWithDirectPaymentOrders.data.subProjects} />
          </Wizard.Step>
        )}

        <Wizard.Step<FinalStatementEditionWizard> name="summary">
          <ViewSummaryStep
            project={project.data}
            subProjects={subProjectsWithContracts.data.subProjects}
            lastFinalStatement={lastFinalStatement.data}
            directPaymentOrders={directPaymentOrders}
            penalties={penalties.data}
          />
        </Wizard.Step>
      </Wizard>
    </>
  );
};
