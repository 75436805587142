import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IDiscountCreationDTO } from '@graneet/business-logic';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useCreateQuoteDiscount = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);

  const { editQuoteDiscount } = useQuoteEditContext();

  return useCallback(
    async (dto: IDiscountCreationDTO) => {
      if (!dto) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        return true;
      }

      await editQuoteDiscount(dto);

      return false;
    },
    [toast, t, editQuoteDiscount],
  );
};
