import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICustomDiscountCreationDTO } from '@graneet/business-logic';

import { postQuoteCustomDiscount } from 'features/quote/services/quote.api';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Create a custom discount with vat rates
 */

export const useCreateQuoteCustomDiscount = () => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote', 'discount']);

  const { updateDataLocally } = useQuoteEditContext();
  return useCallback(
    async (quoteId: number, dto: ICustomDiscountCreationDTO) => {
      if (!dto) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
      }

      const [err, result] = await postQuoteCustomDiscount(quoteId, dto);
      if (err) {
        toast.error(t('global:words.c.error'), t('discount:toasts.error', { email: SUPPORT_EMAIL }));
        return;
      }

      updateDataLocally(result);
    },
    [updateDataLocally, toast, t],
  );
};
