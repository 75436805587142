import { DeletionSection, List, SimpleDeleteIcon } from '@graneet/lib-ui';
import { Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { IProject } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { useHistory } from 'react-router-dom';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useProjectCanBeDeleted, useProjectDelete } from 'features/project/services/project.api';

const DeleteProjectModalContent: FC = () => {
  const { t } = useTranslation(['project']);

  return (
    <>
      <Text fontSize="lg">{t('project:deleteProjectModal.content.title')}</Text>

      <List pl={4}>
        <List.Item
          color="gray.600"
          icon={<SimpleDeleteIcon boxSize={4} />}
          label={t('project:deleteProjectModal.content.timeSlotsDeletion')}
        />
        <List.Item
          color="gray.600"
          icon={<SimpleDeleteIcon boxSize={4} />}
          label={t('project:deleteProjectModal.content.budgetsDeletion')}
        />
        <List.Item
          color="gray.600"
          icon={<SimpleDeleteIcon boxSize={4} />}
          label={t('project:deleteProjectModal.content.unlink')}
        />
      </List>
      <Text>{t('project:deleteProjectModal.content.confirmation')}</Text>
    </>
  );
};

interface ProjectDeleteSectionProps {
  project: IProject;
}

export const ProjectDeleteSection: FC<ProjectDeleteSectionProps> = ({ project }) => {
  const { t } = useTranslation(['global', 'project']);
  const history = useHistory();

  const hasPermissionCreateProject = usePermissions([PERMISSION.CREATE_PROJECTS]);

  const canBeDeleted = useProjectCanBeDeleted(project.id);

  const projectDeleteMutation = useProjectDelete();

  const onProjectDeleted = useCallback(async () => {
    await projectDeleteMutation.mutateAsync(project);
    history.push('/projects');
  }, [projectDeleteMutation, project, history]);

  // determine if the button should be disabled or not
  const isDisabled = !hasPermissionCreateProject || !canBeDeleted.data.canBeDeleted;
  let buttonTooltip: string | undefined;
  if (!hasPermissionCreateProject) {
    buttonTooltip = t('global:errors.disabledAction');
  } else if (!canBeDeleted.data.canBeDeleted) {
    buttonTooltip = t('project:deleteProjectModal.isDisabledTooltip');
  }

  return (
    <DeletionSection
      title={t('project:deleteProjectModal.title')}
      action={t('project:deleteProjectModal.action')}
      description={t('project:deleteProjectModal.description')}
      isDisabled={isDisabled}
      tooltip={buttonTooltip}
      modalAdditionalContent={<DeleteProjectModalContent />}
      onDeleted={onProjectDeleted}
    />
  );
};
