export type ImageListLabels = {
  add?: string;
  edit?: string;
  drop?: string;
  deleteConfirm?: {
    title: string;
    ok: string;
    ko: string;
  };
};

export enum IMAGE_LIST_ERROR {
  FILE_INVALID = 'FILE_INVALID',
  SIZE_TOO_BIG = 'SIZE_TOO_BIG',
  SIZE_TOO_SMALL = 'SIZE_TOO_SMALL',
  SIZE_INVALID = 'SIZE_INVALID',
  REQUEST_ADD = 'REQUEST_ADDING',
  REQUEST_EDITING = 'REQUEST_EDITING',
  REQUEST_DELETING = 'REQUEST_DELETING',
}

export type OnImageListError = (err: IMAGE_LIST_ERROR) => void;
