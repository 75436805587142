/* eslint react/destructuring-assignment: 0 */
import { Box, Divider, Flex, Portal, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { compact } from 'lodash-es';

import { VariableHeaderOption } from '../classes/VariableHeaderOption';
import type { VariableOption } from '../classes/VariableOption';
import { useChakraColors } from '../../../../../hooks';

import type { MenuRenderFn } from './VariableMenu';

export const VariableDropDown: MenuRenderFn<VariableHeaderOption | VariableOption> = (anchorElementRef, itemProps) => {
  const { options, selectedIndex, setHighlightedIndex, selectOptionAndCleanUp } = itemProps;

  const colors = useChakraColors({ gray100: 'gray.100' });

  const optionsToRender = useMemo(
    () =>
      compact(
        options.map((option, index) => {
          // Option is a header
          if (option instanceof VariableHeaderOption) {
            // Header does not have options
            if (index + 1 >= options.length || options[index + 1] instanceof VariableHeaderOption) {
              return null;
            }

            return { type: 'header', option } as const;
          }

          return { type: 'option', option } as const;
        }),
      ),
    [options],
  );

  if (!anchorElementRef.current || optionsToRender.length === 0) {
    return null;
  }

  return (
    <Portal containerRef={anchorElementRef}>
      <Box
        bg="white"
        borderRadius="4px"
        boxShadow="sm"
        border="1px solid"
        borderColor="gray.100"
        minW="12rem"
        h="auto"
        overflowY="hidden"
        mt={`${anchorElementRef.current.offsetHeight}px`}
        py={2}
      >
        <Box overflowY="scroll" maxH="20rem" fontSize="sm">
          <ul style={{ listStyleType: 'none' }}>
            {optionsToRender.map((optionToRender, index) => {
              if (optionToRender.type === 'header') {
                return (
                  <Box key={optionToRender.option.key}>
                    {index !== 0 && (
                      <li>
                        <Divider color="gray.150" w="100%" />
                      </li>
                    )}
                    <li>
                      <Text py={1} color="gray.700" px={3} userSelect="none">
                        {optionToRender.option.text}
                      </Text>
                    </li>
                  </Box>
                );
              }

              const currentFocusableIndex = options.filter((o) => o.isFocusable).indexOf(optionToRender.option);
              const isSelected = currentFocusableIndex === selectedIndex;

              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <li
                  key={`${optionToRender.option.key}option`}
                  tabIndex={-1}
                  ref={optionToRender.option.setRefElement}
                  role="option"
                  aria-selected={isSelected}
                  onClick={() => {
                    setHighlightedIndex(currentFocusableIndex);
                    selectOptionAndCleanUp(optionToRender.option);
                  }}
                  onMouseEnter={() => {
                    setHighlightedIndex(currentFocusableIndex);
                  }}
                >
                  <Flex
                    alignItems="baseline"
                    px={3}
                    bg={isSelected ? colors.gray100 : undefined}
                    cursor="pointer"
                    py={1.5}
                  >
                    <Text mr={2} color="greenBrand.light">
                      #
                    </Text>
                    <Text color="black">{optionToRender.option.text}</Text>
                  </Flex>
                </li>
              );
            })}
          </ul>
        </Box>
      </Box>
    </Portal>
  );
};
