import { Flex, Grid, Text } from '@chakra-ui/react';
import { useMemo, type FC, useEffect, useCallback } from 'react';
import { HiddenField, useFormContext, useOnChangeValues } from 'graneet-form';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { usePaginatedDataContext } from '../../PaginatedData/hooks/usePaginatedDataContext';
import { DateInput } from '../../Input/DateInput/DateInput';
import { DATE_FORMAT_API } from '../../../utils/date.util';
import { filtersTranslations } from '../configureDefaultLabels';

export interface DateFiltersProps<Name extends string = string> {
  label: string;
  name: Name;
}

export const DateFilters: FC<DateFiltersProps> = ({ label, name }) => {
  const { storage: storagePagination } = usePaginatedDataContext();
  const { search } = useLocation();
  const queryFromURL = useMemo(() => new URLSearchParams(search), [search]);
  const query = storagePagination || queryFromURL;

  const form = useFormContext<{
    [name: string]: { startDate?: Date | null; endDate?: Date | null };
  }>();

  const { [name]: value } = useOnChangeValues(form, [name]);

  const start = value?.startDate ? dayjs(value?.startDate).format(DATE_FORMAT_API) : undefined;
  const end = value?.endDate ? dayjs(value?.endDate).format(DATE_FORMAT_API) : undefined;

  useEffect(() => {
    const startDate = query.get(`${String(name)}[startDate]`);
    const endDate = query.get(`${String(name)}[endDate]`);

    form.setFormValues({
      [name]: {
        startDate: startDate && startDate !== '' ? new Date(startDate) : undefined,
        endDate: endDate && endDate !== '' ? new Date(endDate) : undefined,
      },
    });
  }, [form, label, name, query]);

  const handleStartDateChange = useCallback(
    (startDate: string) => {
      form.setFormValues({
        [name]: {
          ...form.getFormValues()[name],
          startDate: startDate && startDate !== '' ? new Date(startDate) : null,
        },
      });
    },
    [form, name],
  );

  const handleEndDateChange = useCallback(
    (endDate: string) => {
      form.setFormValues({
        [name]: {
          ...form.getFormValues()[name],
          endDate: endDate && endDate !== '' ? new Date(endDate) : null,
        },
      });
    },
    [form, name],
  );

  const disabledStartDays = useMemo(() => {
    if (value?.endDate) {
      return {
        after: value.endDate,
      };
    }
    return undefined;
  }, [value]);

  const disabledEndDays = useMemo(() => {
    if (value?.startDate) {
      return {
        before: value.startDate,
      };
    }
    return undefined;
  }, [value]);

  return (
    <Flex gap={3}>
      <Text w="12rem" fontSize="sm" color="baseSecondary">
        {label}
      </Text>

      <HiddenField name={name} />

      <Grid templateColumns="repeat(2, 50%)" w="100%" gridGap={2}>
        <DateInput
          width="100%"
          value={start}
          onChange={handleStartDateChange}
          placeholder={filtersTranslations.startDatePlaceholder}
          datePickerProps={{
            disabledDays: disabledStartDays,
          }}
        />
        <DateInput
          width="100%"
          value={end}
          onChange={handleEndDateChange}
          placeholder={filtersTranslations.endDatePlaceholder}
          datePickerProps={{
            disabledDays: disabledEndDays,
          }}
        />
      </Grid>
    </Flex>
  );
};
