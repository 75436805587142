import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import type { NodeWithRelations } from '@graneet/lib-ui';
import {
  Tooltip,
  useCurrency,
  DeepTable,
  PriceAdvanced,
  PercentageField,
  IconAdvanced,
  SwitchField,
  CurrencyField,
  NegativeCurrencyField,
  formatPercentage,
  DollarLoadingIcon,
  Price,
  EllipsisText,
  SimpleHelpIcon,
} from '@graneet/lib-ui';
import { Box, Flex, FormControl, FormLabel, GridItem, HStack } from '@chakra-ui/react';
import type { ICustomDiscountEditionDTO, IItemResponseDTO } from '@graneet/business-logic';
import {
  isNumberFinite,
  PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES,
  CUMULATIVE_INPUT_TYPE,
  getFloatingPercentage,
  getAmountPercentage,
  getCustomDiscountAmountExVAT,
} from '@graneet/business-logic';
import { useFormContext, useOnBlurValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from '@chakra-ui/icons';

import { useProgressStatementContext } from '../../contexts/ProgressStatementContext';
import type { ProgressStatementEditItemForm } from '../../forms/progress-statement-edit-item-form';
import {
  getKeyForDiscountCumulativeInput,
  getCustomDiscountCumulativeFieldName,
  getCustomDiscountModeLineFieldName,
} from '../../forms/progress-statement-edit-item-form';

import type { IContractTree } from 'features/progress-statement/hooks/useProgressStatementTree';

const INPUT_STYLE = {
  outline: 'none',
  border: 0,
  textAlign: 'center',
  autoComplete: 'off',
} as const;

const FORM_LABEL_STYLE = {
  fontWeight: '600',
  fontSize: '0.82rem',
  display: 'flex',
  my: 1,
};

interface ProgressStatementCustomDiscountRowProps {
  contract: NodeWithRelations<IContractTree>;
  customDiscount: ICustomDiscountEditionDTO;
  itemLines: {
    cumulativeAmountExVAT: number;
    item: IItemResponseDTO;
  }[];
}

export const ProgressStatementCustomDiscountRow: FC<ProgressStatementCustomDiscountRowProps> = ({
  contract,
  customDiscount,
  itemLines,
}) => {
  const { t } = useTranslation(['progressStatement']);
  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();

  const { previousCustomDiscountsCumulativeValues, getContractCustomDiscountPercentageSuggestion } =
    useProgressStatementContext();

  // Custom discount fields
  const customDiscountModeField = getCustomDiscountModeLineFieldName(customDiscount.id);
  const customDiscountCumulativeAmountField = getCustomDiscountCumulativeFieldName(
    customDiscount.id,
    CUMULATIVE_INPUT_TYPE.AMOUNT,
  );
  const customDiscountCumulativeProgressPercentageField = getCustomDiscountCumulativeFieldName(
    customDiscount.id,
    CUMULATIVE_INPUT_TYPE.PERCENTAGE,
  );

  const { totalAmountWithDiscountExVAT } = contract;
  const customDiscountAmountExVAT = getCustomDiscountAmountExVAT(customDiscount, totalAmountWithDiscountExVAT);
  const previousCustomDiscountCumulativeValues = previousCustomDiscountsCumulativeValues[customDiscount.id];

  const form = useFormContext<ProgressStatementEditItemForm>();
  const { getFormValues, setFormValues } = form;
  const values = useOnBlurValues(form, ['cumulativeInputType', customDiscountModeField]);
  const { [customDiscountModeField]: mode, cumulativeInputType } = values;
  const customDiscountValue = useOnBlurValues(form, [customDiscountCumulativeAmountField]);

  // Watcher that compute the suggested custom discount cumulative percentage in 'AUTO' custom discount line mode
  // and stores it into the percentage field
  useEffect(() => {
    const { [customDiscountModeField]: currentMode } = values;

    if (currentMode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO) {
      const newPercentage = getContractCustomDiscountPercentageSuggestion(customDiscount.id, itemLines);

      const newValue: Partial<ProgressStatementEditItemForm> = {};
      newValue[customDiscountCumulativeProgressPercentageField] = newPercentage;
      newValue[customDiscountCumulativeAmountField] = mapNumberToAmount(
        getAmountPercentage(customDiscountAmountExVAT, newPercentage),
      );
      setFormValues(newValue);
    }
  }, [
    customDiscount,
    customDiscountAmountExVAT,
    customDiscountCumulativeAmountField,
    customDiscountCumulativeProgressPercentageField,
    customDiscountModeField,
    getContractCustomDiscountPercentageSuggestion,
    itemLines,
    mapNumberToAmount,
    setFormValues,
    values,
  ]);

  // Handle blur on cumulative input
  const handleBlur = useCallback(
    (type: CUMULATIVE_INPUT_TYPE) => () => {
      const fieldName = getCustomDiscountCumulativeFieldName(customDiscount.id, type);

      let { [fieldName]: value } = getFormValues();

      const newFormValues: Partial<ProgressStatementEditItemForm> = {};

      // Value reset
      if (!isNumberFinite(value)) {
        const previousValue = previousCustomDiscountCumulativeValues?.[getKeyForDiscountCumulativeInput(type)] || 0;

        value = cumulativeInputType === CUMULATIVE_INPUT_TYPE.AMOUNT ? mapNumberToAmount(previousValue) : previousValue;

        newFormValues[fieldName] = value;
      }

      const newCumulativeAmount =
        type === CUMULATIVE_INPUT_TYPE.AMOUNT
          ? value // Use input value
          : newFormValues[customDiscountCumulativeAmountField]; // Use current amount

      if (type === CUMULATIVE_INPUT_TYPE.PERCENTAGE) {
        newFormValues[customDiscountCumulativeAmountField] = mapNumberToAmount(
          getAmountPercentage(customDiscountAmountExVAT, value),
        );
      }
      if (type === CUMULATIVE_INPUT_TYPE.AMOUNT) {
        newFormValues[customDiscountCumulativeProgressPercentageField] = getFloatingPercentage(
          newCumulativeAmount || 0,
          mapNumberToAmount(customDiscountAmountExVAT),
        );
      }

      setFormValues(newFormValues);
    },
    [
      cumulativeInputType,
      customDiscount.id,
      customDiscountAmountExVAT,
      customDiscountCumulativeAmountField,
      customDiscountCumulativeProgressPercentageField,
      getFormValues,
      mapNumberToAmount,
      previousCustomDiscountCumulativeValues,
      setFormValues,
    ],
  );

  const maxAmount = Math.abs(customDiscountAmountExVAT);
  const AbsoluteCurrencyField =
    customDiscountAmountExVAT < 0
      ? NegativeCurrencyField<ProgressStatementEditItemForm>
      : CurrencyField<ProgressStatementEditItemForm>;

  return (
    <DeepTable.Row color="gray.600" background="white" borderColor="gray.200" noLeftSpacing h="53px" offset={0.5}>
      <HStack align="center" w="50rem" spacing={12}>
        <Flex align="center" w="22rem">
          <DollarLoadingIcon mr={2} color="green.700" />
          <EllipsisText color="green.700" fontWeight="600" fontSize="sm">
            {customDiscount.name}
          </EllipsisText>
        </Flex>

        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor={customDiscountModeField}
            cursor={mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.MANUAL ? 'default' : 'pointer'}
            color={mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.MANUAL ? 'greenBrand.light' : 'gray.500'}
            textAlign="right"
            justifyContent="flex-end"
            flex="initial"
            {...FORM_LABEL_STYLE}
          >
            {t('progressStatement:customDiscount.mode.manual')}
          </FormLabel>
          <Box>
            <SwitchField<ProgressStatementEditItemForm>
              name={customDiscountModeField}
              id={customDiscountModeField}
              mt={1}
              colorScheme="blue"
              uncheckedValue={PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.MANUAL}
              checkedValue={PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO}
            />
          </Box>
          <FormLabel
            htmlFor={customDiscountModeField}
            cursor={mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO ? 'default' : 'pointer'}
            color={mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO ? 'greenBrand.light' : 'gray.500'}
            ml={3}
            textAlign="left"
            flex={1}
            {...FORM_LABEL_STYLE}
          >
            {t('progressStatement:customDiscount.mode.auto')}
          </FormLabel>
        </FormControl>
      </HStack>

      <GridItem colSpan={4} />

      <DeepTable.Cell right>
        <PriceAdvanced amount={customDiscountAmountExVAT} />
      </DeepTable.Cell>

      <DeepTable.Cell center>
        {cumulativeInputType === CUMULATIVE_INPUT_TYPE.AMOUNT ? (
          <PriceAdvanced amount={previousCustomDiscountCumulativeValues?.cumulativeAmountExVAT || 0} />
        ) : (
          formatPercentage(previousCustomDiscountCumulativeValues?.cumulativeProgressPercentage || 0)
        )}
      </DeepTable.Cell>

      <DeepTable.Cell variant="shadow" internalPaddingX={0} center>
        <Flex alignItems="center">
          <Flex alignItems="center">
            <Flex
              textAlign="center"
              borderBottom="2px"
              borderColor={mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO ? 'gray.200' : 'greenBrand.light'}
              ml={mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO ? 7 : 0}
              w="5rem"
            >
              <Box display={cumulativeInputType === CUMULATIVE_INPUT_TYPE.AMOUNT ? 'none' : undefined}>
                <PercentageField<ProgressStatementEditItemForm>
                  name={customDiscountCumulativeProgressPercentageField}
                  min={previousCustomDiscountCumulativeValues?.cumulativeProgressPercentage}
                  inputProps={INPUT_STYLE}
                  scale={mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO ? 3 : undefined}
                  isDisabled={mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO}
                  onBlur={handleBlur(CUMULATIVE_INPUT_TYPE.PERCENTAGE)}
                  isRequired
                />
              </Box>
              <Box display={cumulativeInputType !== CUMULATIVE_INPUT_TYPE.AMOUNT ? 'none' : undefined}>
                <AbsoluteCurrencyField
                  name={customDiscountCumulativeAmountField}
                  min={0}
                  max={mapNumberToAmount(maxAmount)}
                  inputProps={INPUT_STYLE}
                  isDisabled={mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO}
                  onBlur={handleBlur(CUMULATIVE_INPUT_TYPE.AMOUNT)}
                  isRequired
                />
              </Box>
            </Flex>

            {mapNumberToAmount(previousCustomDiscountCumulativeValues?.cumulativeAmountExVAT ?? 0) >
              (customDiscountValue[customDiscountCumulativeAmountField] ?? 0) && (
              <Tooltip label={t('progressStatement:tooltips.newPercentageSmallerThanPreviousProgressStatement')}>
                <WarningIcon boxSize={5} color="yellow.500" />
              </Tooltip>
            )}
          </Flex>

          {mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO && (
            <IconAdvanced
              icon={<SimpleHelpIcon ml={2} mr={0} mb={0} />}
              tooltip={t('progressStatement:customDiscount.autoHint')}
            />
          )}
        </Flex>
      </DeepTable.Cell>

      <DeepTable.Cell right>
        <Price amount={mapAmountToNumber(customDiscountValue[customDiscountCumulativeAmountField] || 0)} />
      </DeepTable.Cell>
    </DeepTable.Row>
  );
};
