import { Route, Switch, useRouteMatch } from 'react-router-dom';
import type { FC } from 'react';
import { PERMISSION } from '@graneet/business-logic';

import { SupplierInvoicesRouter } from './supplier-invoices/SupplierInvoicesRouter';
import { OrdersRouter } from './orders/OrdersRouter';
import { CashOutflowRouter } from './cash-outflow/CashOutFlowRouter';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { QueryWrapper } from 'features/api/components/QueryWrapper';

export const PurchasesRouter: FC = () => {
  const { path } = useRouteMatch();

  const canSeeSupplierInvoiceModule = usePermissions([PERMISSION.ACCESS_SUPPLIER_INVOICE]);

  const canSeeOrderModule = usePermissions([PERMISSION.ACCESS_ORDER]);

  return (
    <Switch>
      {canSeeSupplierInvoiceModule && (
        <Route path={`${path}/cash-outflows`}>
          <CashOutflowRouter />
        </Route>
      )}

      {canSeeSupplierInvoiceModule && (
        <Route path={`${path}/supplier-invoices`}>
          <SupplierInvoicesRouter />
        </Route>
      )}

      {canSeeOrderModule && (
        <Route path={`${path}/orders`}>
          <QueryWrapper>
            <OrdersRouter />
          </QueryWrapper>
        </Route>
      )}
    </Switch>
  );
};
