import type { FC, ReactNode } from 'react';
import { Flex, Stack } from '@chakra-ui/react';

import { Chapter } from './components/Chapter';
import { ChaptersTable } from './components/ChaptersTable';
import { ChaptersContextProvider } from './contexts/ChaptersContext';
import { configureDefaultLabels } from './configureDefaultLabels';

export interface ChaptersProps {
  children: ReactNode;
}

export const ChaptersComponent: FC<ChaptersProps> = ({ children }) => (
  <ChaptersContextProvider>
    <Flex boxSize="100%">
      <Stack gap={6} width="100%">
        {children}
      </Stack>

      <Flex w="18rem" pl={6}>
        <ChaptersTable />
      </Flex>
    </Flex>
  </ChaptersContextProvider>
);

export const Chapters = Object.assign(ChaptersComponent, {
  Chapter,
  configureDefaultLabels,
});
