import type { UseDisclosureReturn } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback } from 'react';

import { useForceDraftInvoice } from 'features/invoice/services/invoice.api';
import { StatementForceDraftModal } from 'features/statement/components/StatementForceDraftModal';

interface ForceDraftInvoiceModalProps {
  modal: UseDisclosureReturn;
  invoiceId: number;
  onConfirmed(invoiceId: number): void;
}

export const ForceDraftInvoiceModal: FC<ForceDraftInvoiceModalProps> = ({ modal, invoiceId, onConfirmed }) => {
  const forceDraftInvoiceMutation = useForceDraftInvoice();

  const handleClick = useCallback(async () => {
    const invoice = await forceDraftInvoiceMutation.mutateAsync(invoiceId);
    onConfirmed(invoice.id);
  }, [forceDraftInvoiceMutation, invoiceId, onConfirmed]);

  return <StatementForceDraftModal modal={modal} onConfirmed={handleClick} />;
};
