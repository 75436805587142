import { useEffect, useState } from 'react';

import { useTreeContext } from '../contexts';
import type { NodeRelations } from '../types/NodeInfos';
import type { Identifiable } from '../types/Identifiable';

export const useNodeRelations = <Node extends Identifiable, Leaf extends Identifiable>(
  nodeId: Node['id'],
): NodeRelations<Node, Leaf> => {
  const { listenToNodeRelations, getNodeRelationsSignal } = useTreeContext<Node, Leaf>();
  const [nodeInfos, setNodeInfos] = useState<NodeRelations<Node, Leaf>>(() => getNodeRelationsSignal(nodeId));

  useEffect(() => listenToNodeRelations(nodeId, setNodeInfos), [listenToNodeRelations, nodeId]);

  return nodeInfos;
};
