import { HOLDBACK_STATUSES } from '@graneet/business-logic';
import type { ButtonColorScheme } from '@graneet/lib-ui';

export const HOLDBACK_STATUSES_COLOR: Record<HOLDBACK_STATUSES, ButtonColorScheme> = {
  [HOLDBACK_STATUSES.WAITING]: 'gray',
  [HOLDBACK_STATUSES.ON_GOING]: 'blue',
  [HOLDBACK_STATUSES.TO_BE_RECEIVED]: 'red',
  [HOLDBACK_STATUSES.PARTIALLY_RECEIVED]: 'yellow',
  [HOLDBACK_STATUSES.RECEIVED]: 'green',
};

export const HOLDBACK_STATUSES_STYLE: Record<HOLDBACK_STATUSES, { variantColor: ButtonColorScheme }> = {
  [HOLDBACK_STATUSES.WAITING]: {
    variantColor: 'gray',
  },
  [HOLDBACK_STATUSES.ON_GOING]: {
    variantColor: 'blue',
  },
  [HOLDBACK_STATUSES.TO_BE_RECEIVED]: {
    variantColor: 'red',
  },
  [HOLDBACK_STATUSES.PARTIALLY_RECEIVED]: {
    variantColor: 'yellow',
  },
  [HOLDBACK_STATUSES.RECEIVED]: {
    variantColor: 'green',
  },
};
