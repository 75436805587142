import type { PusherApi } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class PusherProxyApi {
  private static pusherProxyApi: PusherProxyApi;

  private readonly pusherApi: PusherApi;

  private constructor(externalApi: ExternalApi) {
    this.pusherApi = externalApi.getPusherApi();
  }

  public static getPusherProxyApi(externalApi: ExternalApi): PusherProxyApi {
    if (!this.pusherProxyApi) {
      PusherProxyApi.pusherProxyApi = new PusherProxyApi(externalApi);
    }
    return PusherProxyApi.pusherProxyApi;
  }

  public async getPayloadFromCache(clientRequestId: string) {
    return proxyHelper(async () => this.pusherApi.getPayloadFromCache(clientRequestId));
  }
}
