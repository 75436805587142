import type { FC } from 'react';
import { useEffect } from 'react';
import { useFormContext, useOnBlurValues } from 'graneet-form';
import { Callout, SimpleAlertIcon, useCurrency } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

import type { EditSupplierInvoiceForm } from '../../../forms/edit-supplier-invoice.form';
import { isProjectAmountExVATFieldName } from '../../../forms/edit-supplier-invoice.form';

import { useProjectsAmountsContext } from 'features/supplier-invoice/forms/RuleIsProjectsAmountsAmInferiorToAmountExVAT';

export const SupplierInvoiceProjectsAssociationCallout: FC = () => {
  const { t } = useTranslation(['supplierInvoices']);
  const { formatAsAmount, mapAmountToNumber } = useCurrency();

  const form = useFormContext<EditSupplierInvoiceForm>();
  const formValues = useOnBlurValues(form, undefined);

  const { amountExVAT: rawAmountExVAT } = formValues;
  const amountExVAT = mapAmountToNumber(rawAmountExVAT || 0);

  /*
    Sum of project amounts exc. VAT
   */
  const totalOfProjectsAmountExVAT = Object.keys(formValues).reduce(
    (acc, key) => (isProjectAmountExVATFieldName(key) ? acc + mapAmountToNumber(formValues[key] || 0) : acc),
    0,
  );

  const hasAssociatedProjects = !!formValues.associatedProjects?.length;

  /*
    Represents the rest of supplier invoice amount exc. VAT than it can be split on project.
   */
  const restToSplitExVAT = amountExVAT - totalOfProjectsAmountExVAT;

  const isAmountExVATPositive = amountExVAT > 0;
  /*
    Intuitive case, when `amountExVAT` is positive, if the rest to split on project amount exc. VAT is negative, it
    means we split too much money on projects we will display an error message.
    If `isAmountExVATPositive` is false, we have the reverse condition
   */
  const hasTooMuchMoneySplit = isAmountExVATPositive ? restToSplitExVAT < 0 : restToSplitExVAT > 0;
  /*
    Intuitive case, when `amountExVAT` is positive, if the rest to split on project amount exc. VAT is positive, it
    means we still have money to split on project we will display an warning message.
    If `isAmountExVATPositive` is false, we have the reverse condition
   */
  const hasMoneyNotSplit = isAmountExVATPositive ? restToSplitExVAT > 0 : restToSplitExVAT < 0;
  const { setIsValid } = useProjectsAmountsContext();

  useEffect(() => {
    setIsValid(!hasTooMuchMoneySplit);
  }, [hasTooMuchMoneySplit, setIsValid]);

  if (hasAssociatedProjects && hasTooMuchMoneySplit) {
    return (
      <Callout icon={<SimpleAlertIcon stroke="red.500" />} colorScheme="red">
        <Trans
          t={t}
          i18nKey="supplierInvoices:cards.associatedProjectCard.amountError"
          values={{ amount: formatAsAmount(Math.abs(restToSplitExVAT)) }}
        >
          description_start
          <Text as="span" fontWeight={600}>
            displayed_data
          </Text>
          description_end
        </Trans>
      </Callout>
    );
  }

  if (hasAssociatedProjects && hasMoneyNotSplit) {
    return (
      <Callout icon={<SimpleAlertIcon stroke="yellow.500" />} colorScheme="yellow">
        <Trans
          t={t}
          i18nKey="supplierInvoices:cards.associatedProjectCard.amountWarning"
          values={{ amount: formatAsAmount(restToSplitExVAT) }}
        >
          description_start
          <Text as="span" fontWeight={600}>
            displayed_data
          </Text>
          description_end
        </Trans>
      </Callout>
    );
  }

  /*
    Happen when `restToSplitExVAT` is equal to zero, all supplier invoice amounts exc. VAT amount is split on projects
   */
  return null;
};
