import type { FC } from 'react';
import type { BadgeProps } from '@graneet/lib-ui';
import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { useQuoteExportLotComputedValue } from '../../hooks/useQuoteExportTree';

interface QuoteExportJobsWarningBadgeProps extends Omit<BadgeProps, 'children'> {
  lotId: number;
}

export const QuoteExportJobsWarningBadge: FC<QuoteExportJobsWarningBadgeProps> = ({ lotId, ...props }) => {
  const { t } = useTranslation(['quote']);

  const quoteLotComputedValue = useQuoteExportLotComputedValue(lotId);

  if (!quoteLotComputedValue || quoteLotComputedValue.numberOfUnselectedJobs === 0) return null;

  return (
    <Badge colorScheme="yellow" w="auto" px={2} {...props}>
      {t('quote:export.unselectedBadge', { count: quoteLotComputedValue.numberOfUnselectedJobs })}
    </Badge>
  );
};
