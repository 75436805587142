import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Flex, ModalCloseButton, Spinner, useDisclosure } from '@chakra-ui/react';

import { Modal } from '../Modal/Modal';
import { Carousel } from '../Carousel/Carousel';
import { FileNameChip } from '../FileNameChip/FileNameChip';
import type { FileItem } from '../FileNameChip/FileNameDetails';

export interface FileNameListProps extends FlexProps {
  files: FileItem[];
  readOnly?: boolean;
  openCarousel?: boolean;
  onFileDelete?: (id: string) => Promise<void>;
}

export const FileNameList: FC<FileNameListProps> = ({
  files: originalFiles,
  openCarousel = false,
  readOnly = false,
  onFileDelete,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [files, setFiles] = useState<FileItem[]>(originalFiles);
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    setFiles(originalFiles);
  }, [originalFiles]);

  const onModalOpen = useCallback(
    (id: string) => {
      if (!openCarousel) {
        return;
      }
      const index = files.findIndex((f) => f.id === id);
      setCarouselIndex(index);
      onOpen();
    },
    [onOpen, files, openCarousel],
  );

  /**
   * Removes an file from the state
   */
  const removeFile = useCallback(
    (fileId: string) => {
      setFiles(files.filter(({ id }) => id !== fileId));
    },
    [files],
  );

  const onDelete = useCallback(
    async (id: string) => {
      const filesInitialState = files;
      removeFile(id);
      if (!onFileDelete) return;
      try {
        await onFileDelete(id);
      } catch {
        // In case of fail we conserve the initial state
        setFiles(filesInitialState);
      }
    },
    [files, onFileDelete, removeFile],
  );

  return (
    <>
      {files.length > 0 &&
        files.map((file) => (
          <Flex display="inline-flex" key={file.id} mr={4} mt={2}>
            <FileNameChip file={file} onOpen={onModalOpen} readOnly={readOnly} onDelete={onDelete} />
          </Flex>
        ))}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl" noFooter>
        <Carousel images={files} fallback={<Spinner />} startIndex={carouselIndex} />
        <ModalCloseButton boxSize="10" size="8" />
      </Modal>
    </>
  );
};
