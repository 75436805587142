import type { IBankingTransaction } from '@graneet/business-logic';
import { BANKING_TRANSACTION_POWENS_TYPE_TO_USER_AVAILABLE_TYPES } from '@graneet/business-logic';
import type { TFunction } from 'i18next';

export function mapBankingTransactionTypeToDisplayedType(
  bankingTransaction: IBankingTransaction['type'],
  t: TFunction<['global', 'banking']>,
): string {
  const type = BANKING_TRANSACTION_POWENS_TYPE_TO_USER_AVAILABLE_TYPES[bankingTransaction];
  return t(`banking:bankingTransaction.type.${type}`);
}
