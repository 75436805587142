import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import {
  formatDateToString,
  getCurrentDateAsString,
  IconAdvanced,
  Section,
  SimpleHelpIcon,
  SwitchField,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, useStepForm } from 'graneet-form';
import { Flex, HStack, Image, Text } from '@chakra-ui/react';
import type { IProgressStatement, IProject } from '@graneet/business-logic';
import { SUB_PROJECT_BILLING_TYPE } from '@graneet/business-logic';

import { ProgressStatementEditionCard } from 'features/progress-statement/components/cards/ProgressStatementEditionCard';
import { NoteFieldSection } from 'features/common/components/fields/NoteFieldSection';
import { useHasLedger } from 'features/statement-ledger/hooks/useHasLedger';
import { OptionalLotsTableStatus, useStore } from 'store/store';
import type { ProgressStatementInformationForm } from 'features/progress-statement/forms/progress-statement-information-form';
import type { ProgressStatementEditWizard } from 'features/progress-statement/forms/progress-statement-edit-wizard';

interface EditProgressStatementInformationStepProps {
  project: IProject;

  currentProgressStatement: IProgressStatement | null;

  previousProgressStatement: IProgressStatement | undefined;
}

export const EditProgressStatementInformationStep: FC<EditProgressStatementInformationStepProps> = ({
  project,
  currentProgressStatement,
  previousProgressStatement,
}) => {
  const { t } = useTranslation(['progressStatement']);
  const { form, initFormValues } = useStepForm<ProgressStatementEditWizard, 'information'>();
  const hasLedger = useHasLedger();
  const optionsStatusInContract = useStore((state) => state.optionalLotsTableStatus);

  const hasOptionsInContracts = useMemo(
    () =>
      !!optionsStatusInContract &&
      [OptionalLotsTableStatus.HAS_ONE_OR_MORE_OPTIONS, OptionalLotsTableStatus.HAS_ONLY_OPTIONS].includes(
        optionsStatusInContract,
      ),
    [optionsStatusInContract],
  );

  useEffect(() => {
    const {
      name,
      invoiceNumber: currentInvoiceNumber,
      billingDate,
      note,
      displayOptionsAnnexPDF,
    } = currentProgressStatement || {};
    initFormValues({
      name,
      invoiceNumber: currentInvoiceNumber,
      note,
      billingDate: billingDate ? formatDateToString(billingDate) : getCurrentDateAsString(),
      displayOptionsAnnexPDF:
        displayOptionsAnnexPDF ??
        (previousProgressStatement ? previousProgressStatement.displayOptionsAnnexPDF : false),
    });
  }, [initFormValues, currentProgressStatement, previousProgressStatement]);

  return (
    <Form form={form}>
      <Section
        title={t('progressStatement:isCreating.billingInformation')}
        description={
          hasLedger
            ? t('progressStatement:isCreating.infoWithLedger')
            : t('progressStatement:isCreating.trackingInvoice')
        }
      >
        <Flex w="100%%" mb={5}>
          <ProgressStatementEditionCard
            currentProgressStatement={currentProgressStatement}
            project={project}
            billingType={SUB_PROJECT_BILLING_TYPE.PROGRESS}
          />
        </Flex>
      </Section>
      <NoteFieldSection<ProgressStatementInformationForm> name="note" />
      {hasOptionsInContracts && (
        <SwitchField<ProgressStatementInformationForm>
          name="displayOptionsAnnexPDF"
          checkedValue
          uncheckedValue={false}
          label={
            <HStack spacing={2}>
              <Text color="black" fontSize="lg">
                {t('progressStatement:isCreating.displayOptionsAnnexPDF')}
              </Text>
              <IconAdvanced
                icon={<SimpleHelpIcon />}
                tooltip={
                  <>
                    {t('progressStatement:isCreating.displayOptionsAnnexPDFTooltip')}
                    <Image
                      mt={4}
                      px={4}
                      src={t(`progressStatement:isCreating.displayOptionsAnnexPDFTooltipImageUrl`)}
                    />
                  </>
                }
              />
            </HStack>
          }
        />
      )}
    </Form>
  );
};
