import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { Modal, NumberField } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { Text, HStack, Box } from '@chakra-ui/react';
import type { ICompanyQuoteValidityDurationDTO } from '@graneet/business-logic';

import { useUpdateCompanyQuoteValidityDuration } from 'features/company/services/company.api';
import { Rule } from 'features/form/rules/Rule';

interface FormValues {
  quoteValidityDuration: number;
}

export interface QuoteEditValidityDurationModalProps {
  isOpen: boolean;

  onClose: () => void;

  validityDurationInitialValue?: number;
}

export const QuoteEditValidityDurationModal: FC<QuoteEditValidityDurationModalProps> = ({
  isOpen,
  onClose,
  validityDurationInitialValue,
}) => {
  const { t } = useTranslation(['global', 'quote']);

  const form = useForm<FormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  const updateCompanyQuoteValidityDurationMutation = useUpdateCompanyQuoteValidityDuration();

  const { getFormValues, setFormValues } = form;
  // initialize form
  useEffect(() => {
    setFormValues({
      quoteValidityDuration: validityDurationInitialValue,
    });
  }, [setFormValues, validityDurationInitialValue]);

  // Submit new default validity value
  const handleSubmit = useCallback(async () => {
    const { quoteValidityDuration: currentValue } = getFormValues();

    const dto: ICompanyQuoteValidityDurationDTO = {
      quoteValidityDuration: currentValue!,
    };

    updateCompanyQuoteValidityDurationMutation.mutate(dto, {
      onSuccess: () => {
        onClose();
      },
    });
  }, [getFormValues, onClose, updateCompanyQuoteValidityDurationMutation]);

  return (
    <Form form={form}>
      <Modal isOpen={isOpen} onClose={onClose} title={t('quote:settings.validityDuration.modal.title')} size="2xl">
        <HStack>
          <Text mr={4}>{t('quote:settings.validityDuration.modal.label')}</Text>
          <Box w="6rem">
            <NumberField<FormValues> name="quoteValidityDuration" min={0} scale={0} placeholder="30" type="number">
              <Rule.IsRequired />
            </NumberField>
          </Box>
        </HStack>
        <Modal.Close isDisabled={updateCompanyQuoteValidityDurationMutation.isPending} />
        <Modal.PrimaryButton
          isLoading={updateCompanyQuoteValidityDurationMutation.isPending}
          onClick={handleSubmit}
          isDisabled={!isFormValid}
        >
          {t('global:words.c.validate')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
