import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const QuoteIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 12" {...props}>
    <path
      fill="currentColor"
      d="M0 8.29221H6.4V6.73377H0V8.29221ZM12.8 6.73377V3.61688H11.2V6.73377H8V8.29221H11.2V11.4091H12.8V8.29221H16V6.73377H12.8ZM9.6 0.5H0V2.05844H9.6V0.5ZM9.6 3.61688H0V5.17532H9.6V3.61688Z"
    />
  </Icon>
);
