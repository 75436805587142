import type { FC } from 'react';
import type { GridProps } from '@chakra-ui/react';
import { Grid } from '@chakra-ui/react';

export interface TwoColumnsProps extends GridProps {}

export const TwoColumns: FC<TwoColumnsProps> = ({ children, ...props }) => (
  <Grid templateColumns={{ base: 'minmax(0,1fr)', xl: 'repeat(2, minmax(0,1fr))' }} gap={4} {...props}>
    {children}
  </Grid>
);
