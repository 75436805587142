import { BILL_STATUSES } from '@graneet/business-logic';
import type { DocumentStatusCardAction, DocumentStatusCardIconValue } from '@graneet/lib-ui';

import type { BillActionsType } from './bill.actions.type';
import { getBillToBeReceivedActions } from './bill-awaiting-payment.actions';
import { getBillPartiallyPaidActions } from './bill-partially-paid.actions';
import { getBillLostActions } from './bill-lost.actions';

export function getBillActions(params: BillActionsType): DocumentStatusCardAction[] {
  const { bill } = params;

  switch (bill.status) {
    case BILL_STATUSES.AWAITING_PAYMENT:
      return getBillToBeReceivedActions(params);
    case BILL_STATUSES.PARTIALLY_PAID:
      return getBillPartiallyPaidActions(params);
    case BILL_STATUSES.PAID:
      return [];
    case BILL_STATUSES.LOST:
      return getBillLostActions(params);
    default:
      bill.status satisfies never;
      throw new Error('Unknown status');
  }
}

export function getBillStatusCardIcon(status: BILL_STATUSES): DocumentStatusCardIconValue {
  switch (status) {
    case BILL_STATUSES.AWAITING_PAYMENT:
      return 'draft';
    case BILL_STATUSES.PARTIALLY_PAID:
      return 'partial';
    case BILL_STATUSES.PAID:
      return 'completed';
    case BILL_STATUSES.LOST:
      return 'cancelled';
    default:
      status satisfies never;
      throw new Error('Unknown status');
  }
}
