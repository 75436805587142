import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IQuoteJobUpdateDTO } from '@graneet/business-logic';

import * as jobsApi from '../services/quote-job.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Edit a job.
 */
export const useEditJob = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { updateDataLocally, emitJobUpdate, getCurrentQuoteData } = useQuoteEditContext();

  return useCallback(
    async (jobId: number, partialJob: IQuoteJobUpdateDTO) => {
      const { jobs } = getCurrentQuoteData();
      const originalJob = { ...jobs[jobId] };

      // Because the update has already taken place in the front,
      // we update the context first so the current value does not
      // replace the one our user has entered in the render following
      // the blur event.
      jobs[jobId] = {
        ...originalJob,
        ...partialJob,
      };

      const [err, result] = await jobsApi.updateJob(jobId, partialJob);

      if (err) {
        // Replace a copy of the orignal job
        jobs[jobId] = originalJob;
        toast.error(t('quote:errors.updatingError'));
        emitJobUpdate(jobId);
        return true;
      }

      updateDataLocally(result);

      return false;
    },
    [updateDataLocally, toast, t, emitJobUpdate, getCurrentQuoteData],
  );
};
