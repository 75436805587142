import type { IAddress } from '../address/address.type';
import type { IComponentType } from '../component-type/component-type.type';
import type { IProject } from '../project/project.type';
import type { ISupplier } from '../supplier/supplier.type';
import type { IVatBases } from '../vat/vat-bases.type';
import type { ICompany } from '../company/company.type';
import type { IPdf } from '../pdf/pdf.type';
import type { IOrderSupplierInvoice } from '../order-supplier-invoice/order-supplier-invoice.type';
import type { PaginatedResponse } from '../pagination/paginated-response.type';
import type { ISubProject } from '../sub-project/sub-project.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { VAT_TYPE } from '../vat/constant/vat-type.constant';

import type { IOrderFile } from './order-file.type';
import type { IOrderLot } from './order-lot.type';
import type { IOrderItem } from './order-item.type';

export enum ORDER_STATUS {
  PROCESSED = 'PROCESSED',
  DELIVERED = 'DELIVERED',
  PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED',
}

export interface IOrder {
  id: number;

  name: string;

  historyFamilyId: string;

  company?: ICompany;

  orderNumber: string;

  orderDate: Date;

  orderFiles?: IOrderFile[];

  deliveryDate: Date | null;

  deliveryAddress?: IAddress;

  note: string | null;

  status: ORDER_STATUS;

  comment: string | null;

  amountExVAT: number | null;

  vatDistribution: IVatBases;

  amountIncVAT: number;

  isDirectPayment: boolean;

  hasUnitPrices: boolean;

  vatType: VAT_TYPE;

  isPriceRequest: boolean;

  pdf?: IPdf;

  type?: IComponentType | null;

  supplier?: ISupplier;

  project?: IProject | null;

  /**
   * Sub project is defined if (and only if) isDirectPayment = true.
   */
  subProject?: ISubProject | null;

  tags: string[];

  ordersSupplierInvoices?: IOrderSupplierInvoice[];

  orderLots?: IOrderLot[];

  orderItems?: IOrderItem[];

  createdAt: Date;

  updatedAt: Date;
}

export type IOrderWithRelations = RequiredByKeys<
  IOrder,
  | 'supplier'
  | 'project'
  | 'subProject'
  | 'type'
  | 'deliveryAddress'
  | 'pdf'
  | 'ordersSupplierInvoices'
  | 'orderFiles'
  | 'company'
> & {
  orderItems: RequiredByKeys<IOrderItem, 'type'>[];
};

export interface IOrderWithDirectPaymentAmounts extends IOrderWithRelations {
  /**
   * Sum of supplier invoices attached to this order and to a progress statement with status DRAFT, ACCEPTED, VALIDATED or COMPLETED,
   * Used for percentage calculation of order in progress statement wizard
   */
  amountPreviousDirectPaymentsExVAT: number;
}

type IOrderExtended<IsDirectPayment extends boolean> = IsDirectPayment extends true
  ? IOrderWithDirectPaymentAmounts
  : IOrderWithRelations;

export type OrderPaginatedResponse<IsDirectPayment extends boolean = boolean> = PaginatedResponse<
  IOrderExtended<IsDirectPayment>,
  'amountExVAT'
>;
