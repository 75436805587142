import { HOLDBACK_STATUSES } from '@graneet/business-logic';
import type { DocumentStatusCardAction, DocumentStatusCardIconValue } from '@graneet/lib-ui';

import type { HoldbackActionsType } from './holdback.actions.type';
import { getHoldbackToBeReceivedActions } from './holdback-to-be-received.actions';
import { getHoldbackOnGoingActions } from './holdback-on-going.actions';

export function getHoldbackActions(params: HoldbackActionsType): DocumentStatusCardAction[] {
  const { holdback } = params;

  switch (holdback.status) {
    case HOLDBACK_STATUSES.WAITING:
      return [];
    case HOLDBACK_STATUSES.ON_GOING:
      return getHoldbackOnGoingActions(params);
    case HOLDBACK_STATUSES.TO_BE_RECEIVED:
    case HOLDBACK_STATUSES.PARTIALLY_RECEIVED:
      return getHoldbackToBeReceivedActions(params);
    case HOLDBACK_STATUSES.RECEIVED:
      return [];
    default:
      holdback.status satisfies never;
      throw new Error('Not implemented');
  }
}

export function getHoldbackStatusCardIcon(status: HOLDBACK_STATUSES): DocumentStatusCardIconValue {
  switch (status) {
    case HOLDBACK_STATUSES.WAITING:
      return 'draft';
    case HOLDBACK_STATUSES.ON_GOING:
      return 'finalized';
    case HOLDBACK_STATUSES.TO_BE_RECEIVED:
      return 'cancelled';
    case HOLDBACK_STATUSES.PARTIALLY_RECEIVED:
      return 'partial';
    case HOLDBACK_STATUSES.RECEIVED:
      return 'completed';
    default:
      status satisfies never;
      throw new Error('Unknown status');
  }
}
