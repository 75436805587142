import { Stack, Text, useDisclosure } from '@chakra-ui/react';
import type { IOvertime } from '@graneet/business-logic';
import { ActionMenu, Button, Card, SimpleAddIcon, Table } from '@graneet/lib-ui';
import { useCallback, useRef, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useOvertimeTypes } from '../services/overtime-type.api';

import { OvertimeTypeDeleteModal } from './OvertimeTypeDeleteModal';
import { OvertimeTypeEditModal } from './OvertimeTypeEditModal';

export const OvertimeTypeCard: FC = () => {
  const { t } = useTranslation(['global', 'planning']);
  const overtimeTypes = useOvertimeTypes();

  // Edition
  const editionModal = useDisclosure();
  const [selectedOvertimeType, setSelectedOvertimeType] = useState<IOvertime | null>(null);

  const openEditionModal = useCallback(
    (overtimeType?: IOvertime | null) => {
      setSelectedOvertimeType(overtimeType || null);
      editionModal.onOpen();
    },
    [editionModal],
  );

  const closeEditionModal = useCallback(() => {
    setSelectedOvertimeType(null);
    editionModal.onClose();
  }, [editionModal]);

  // Deletion
  const overtimeTypeToDeleteRef = useRef<IOvertime | null>(null);

  const deletionModal = useDisclosure({
    onClose() {
      overtimeTypeToDeleteRef.current = null;
    },
  });

  const openOvertimeTypeDeletionModal = useCallback(
    (overtimeType: IOvertime) => {
      overtimeTypeToDeleteRef.current = overtimeType;

      deletionModal.onOpen();
    },
    [deletionModal],
  );

  return (
    <Card
      title={t('planning:overtimeCard.title')}
      topRightContent={
        <Button variant="outline" leftIcon={<SimpleAddIcon />} onClick={() => openEditionModal(null)}>
          {t('global:words.c.add')}
        </Button>
      }
    >
      <Stack>
        <Text>{t('planning:overtimeCard.description')}</Text>

        <Table templateColumns={['auto', '5rem', '3rem']} noCard>
          <Table.Header>
            <Table.Cell>{t('planning:overtimeCard.fields.name')}</Table.Cell>
            <Table.Cell center>{t('planning:overtimeCard.fields.coefficient')}</Table.Cell>
            <Table.Cell center />
          </Table.Header>

          {overtimeTypes.data.map((overtimeType) => (
            <Table.Row key={overtimeType.id}>
              <Table.Cell>{overtimeType.title}</Table.Cell>

              <Table.Cell center>{overtimeType.coefficient}</Table.Cell>

              <Table.Cell center>
                <ActionMenu>
                  <ActionMenu.Edit onClick={() => openEditionModal(overtimeType)} />
                  <ActionMenu.Delete onClick={() => openOvertimeTypeDeletionModal(overtimeType)} />
                </ActionMenu>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </Stack>

      <OvertimeTypeEditModal
        key={selectedOvertimeType?.id}
        isOpen={editionModal.isOpen}
        onClose={closeEditionModal}
        initialOvertimeType={selectedOvertimeType}
      />

      {overtimeTypeToDeleteRef.current && (
        <OvertimeTypeDeleteModal modal={deletionModal} overtimeTypeToDelete={overtimeTypeToDeleteRef.current} />
      )}
    </Card>
  );
};
