import dayjs from 'dayjs';
import type { ABSENCE_DURATION, ButtonColorScheme, ITimeTableRange } from '@graneet/lib-ui';
import { multiplyFloating } from '@graneet/lib-ui';
import type {
  IWorker,
  IProject,
  ITimeTrackingResponse,
  ITimeSlotWithRelations,
  IOvertime,
  ITimeslotOvertime,
} from '@graneet/business-logic';

import {
  extractTimeslotOvertimeField,
  isTimeslotOvertimeHourFieldNameOnDay,
  isTimeslotOvertimeTypeIdFieldNameOnDay,
  type TimeslotOvertimesHourFieldName,
  type TimeslotOvertimesTypeIdFieldName,
} from '../components/modals/TimeSlotCreateModal/TimeSlotCreateModalForm';

const buildTimeTableRange = (
  slot: ITimeSlotWithRelations,
  groupBy: 'project' | 'worker',
  awayTranslation: string,
  subKey: 'project' | 'worker',
  absenceDurationPossibleValues: Record<ABSENCE_DURATION, number>,
): ITimeTableRange => ({
  startDate: slot.date,
  endDate: slot.date,
  nbMinutes: slot.nbMinutes,
  absenceDuration: slot.absenceDuration ? absenceDurationPossibleValues[slot.absenceDuration] : 0,
  slots: [slot],
  name:
    groupBy === 'worker' ? (slot.project?.name ?? awayTranslation) : `${slot.worker.firstName} ${slot.worker.lastName}`,
  color: (slot.project?.color ?? 'gray') as ButtonColorScheme,
  id: slot[subKey]?.id!,
  type: groupBy === 'worker' ? 'project' : 'worker',
  associatedEntityId: groupBy === 'worker' ? (slot?.project?.id ?? -1) : slot?.worker.id!,
});

export const aggregateTimeSlotsRanges = (
  entitySlots: Pick<ITimeTrackingResponse<IProject | IWorker>, 'slots'>['slots'],
  groupBy: 'project' | 'worker',
  absenceDurationPossibleValues: Record<ABSENCE_DURATION, number>,
  awayTranslation: string,
) => {
  const subKey = groupBy === 'worker' ? 'project' : 'worker';
  const ranges: Record<string, Record<string, ITimeTableRange[]>> = {};
  Object.entries(entitySlots ?? {}).forEach(([entityId, slots]) => {
    ranges[entityId] = Object.entries(slots)
      .reduce<ITimeSlotWithRelations[][]>((acc, curr) => [...acc, curr[1]], [])
      .flat()
      .reduce<Record<string, ITimeTableRange[]>>((acc, slot) => {
        if (groupBy === 'project' && !slot.project) {
          return acc;
        }
        if (!acc[slot[subKey]?.id ?? 'undefined']?.length) {
          return {
            ...acc,
            [slot[subKey]?.id ?? 'undefined']: [
              buildTimeTableRange(slot, groupBy, awayTranslation, subKey, absenceDurationPossibleValues),
            ],
          };
        }
        const lastRange = acc[slot[subKey]?.id ?? 'undefined'][acc[slot[subKey]?.id ?? 'undefined'].length - 1];
        if (dayjs(lastRange.endDate).add(1, 'day').isSame(slot.date)) {
          // extend range
          acc[slot[subKey]?.id ?? 'undefined'][acc[slot[subKey]?.id ?? 'undefined'].length - 1].endDate = slot.date;
          acc[slot[subKey]?.id ?? 'undefined'][acc[slot[subKey]?.id ?? 'undefined'].length - 1].slots.push(slot);
          acc[slot[subKey]?.id ?? 'undefined'][acc[slot[subKey]?.id ?? 'undefined'].length - 1].nbMinutes +=
            slot.nbMinutes;
          acc[slot[subKey]?.id ?? 'undefined'][acc[slot[subKey]?.id ?? 'undefined'].length - 1].absenceDuration +=
            slot.absenceDuration ? absenceDurationPossibleValues[slot.absenceDuration] : 0;
        } else {
          // new range
          acc[slot[subKey]?.id ?? 'undefined'].push(
            buildTimeTableRange(slot, groupBy, awayTranslation, subKey, absenceDurationPossibleValues),
          );
        }
        return acc;
      }, {});
  });
  return ranges;
};

export const divideRangeToDays = (range: { startDate: string; endDate: string }): dayjs.Dayjs[] => {
  const slotsDays = [];
  const start = dayjs(range.startDate);
  const end = dayjs(range.endDate);
  for (let day = start; day.isBefore(end) || day.isSame(end, 'day'); day = day.add(1, 'day')) {
    slotsDays.push(day);
  }
  return slotsDays;
};

interface GenericFormValues {
  [key: string]:
    | {
        startDate?: Date;
        endDate?: Date;
      }
    | number
    | number[]
    | string
    | undefined;
}

export const extractTimeslotOvertimes = (day: string, formValues: GenericFormValues, overtimeTypes: IOvertime[]) => {
  const timeslotOvertimes = Object.keys(formValues).filter(
    (key) => isTimeslotOvertimeHourFieldNameOnDay(key, day) || isTimeslotOvertimeTypeIdFieldNameOnDay(key, day),
  ) as (TimeslotOvertimesHourFieldName | TimeslotOvertimesTypeIdFieldName)[];

  return timeslotOvertimes.reduce<Omit<ITimeslotOvertime, 'id'>[]>((acc, key) => {
    const { type, index } = extractTimeslotOvertimeField(key);
    acc[index] = acc[index] || { nbMinutes: 0, overtime: null, coefficient: 1 };
    if (type === 'timeslotOvertimeHour') {
      acc[index].nbMinutes = multiplyFloating((formValues[key] as number) ?? 0, 60);
    } else if (type === 'timeslotOvertimeTypeId') {
      const overtime = overtimeTypes.find(({ id }) => id === formValues[key]);
      acc[index].overtime = overtime ?? null;
      acc[index].coefficient = overtime && overtime.coefficient ? Number(overtime.coefficient) : 1;
    }
    return acc;
  }, []);
};
