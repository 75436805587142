import type { IGeneratePdfModelsDTO, IPdfSettingsResponseDTO, PDF_TEMPLATES_TYPES } from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

import { apiNew } from 'features/api/services/apiNew.service';

const PDF_SETTING_PATH = '/pdf-settings';

const pdfSettings = createQueryKeys('pdf-settings', {
  list: () => ({
    queryKey: [PDF_SETTING_PATH],
    queryFn: () => apiNew.get<never, IPdfSettingsResponseDTO>(PDF_SETTING_PATH),
  }),
});

export function usePdfSettings() {
  return useSuspenseQuery(pdfSettings.list());
}

export function useUpdateCompanyPdfSettings() {
  const { t } = useTranslation(['pdfSettings', 'global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IGeneratePdfModelsDTO) =>
      apiNew.put<IGeneratePdfModelsDTO, IPdfSettingsResponseDTO>(PDF_SETTING_PATH, data),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: (_, pdfModel) => {
      toast.success(t(`pdfSettings:${pdfModel.template as PDF_TEMPLATES_TYPES}.toast.success`));
      queryClient.invalidateQueries(pdfSettings.list());
    },
  });
}
