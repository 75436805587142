import { ContentTypeGuard } from '@org/quotation-lib';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import { useQuoteError } from './useQuoteError';

import { useQuoteSetToStore } from 'features/quotation/quote-common/hooks/useQuoteSetToStore';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  quoteClientRequestsSetToStore,
  StatusEnum,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useQuoteFileDelete() {
  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const { quoteFilesProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    async (file: { name?: string; url: string; id: string }, nodeId: string) => {
      try {
        const node = quote.getTree().getNodeOrThrow(nodeId);
        const content = node.getContent();
        if (
          ContentTypeGuard.isQuoteBasicItem(content) ||
          // ContentTypeGuard.isQuoteHiddenCostItem(content) || TODO LATER
          ContentTypeGuard.isQuoteOptionalItem(content) ||
          ContentTypeGuard.isQuoteSubItem(content)
        ) {
          content.deleteFile(file.id);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteFilesProxyApi
            .deleteFile({
              nodeId,
              timestamp,
              clientRequestId,
              quoteId: quote.getId(),
              fileId: file.id,
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          quoteSetToStore(quote);
        }
      } catch (e: any) {
        return quoteError(e.message);
      }

      return true;
    },
    [quote, quoteError, quoteFilesProxyApi, quoteSetToStore],
  );
}
