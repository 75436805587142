import type {
  AccordionButtonProps,
  AccordionItemProps,
  AccordionPanelProps,
  AccordionProps as ChakraAccordionProps,
} from '@chakra-ui/react';
import { Accordion as ChakraAccordion, AccordionItem, AccordionButton, AccordionPanel } from '@chakra-ui/react';
import type { FC, ReactElement } from 'react';
import { useCallback } from 'react';

import { SimpleChevronBottomIcon } from '../Icons';

interface AccordionProps<T> {
  items: T[];
  /** Used to display the accordion header */
  componentButton: FC<T>;
  /** Used to display the accordion body component */
  componentPanel: FC<T>;
  /** Used to create uniq key over the array */
  keySelector?: keyof T;
  /** Used the context props to
   * access the index of the currently opened accordion item and
   * to control the onChange event */
  context?: {
    index: number;
    onChange: (index: number) => void;
  };
  icon?: ReactElement;
  overwriteStyle?: {
    accordion?: Omit<ChakraAccordionProps, 'index' | 'onChange'>;
    accordionButton?: Omit<AccordionButtonProps, 'children' | 'position' | '_hover' | 'cursor'>;
    accordionPanel?: Omit<AccordionPanelProps, 'children'>;
    accordionItem?: Omit<AccordionItemProps, 'border' | 'boxShadow'>;
  };
  accordionProps?: Omit<ChakraAccordionProps, 'index' | 'onChange'>;
}

/** Generic UI Accordion */
export const Accordion = <T,>({
  items,
  componentButton,
  componentPanel,
  keySelector = 'id' as keyof T,
  context,
  accordionProps,
  icon = <SimpleChevronBottomIcon boxSize={4} />,
  overwriteStyle = {
    accordion: {},
    accordionButton: { p: 4 },
    accordionPanel: { pb: 4 },
  },
}: AccordionProps<T>) => {
  const style = {
    accordionButton: overwriteStyle?.accordionButton ? overwriteStyle?.accordionButton : { p: 4 },
    accordionPanel: overwriteStyle?.accordionPanel ? overwriteStyle?.accordionPanel : {},
    accordionItem: overwriteStyle?.accordionItem ? overwriteStyle?.accordionItem : { rounded: 4 },
  };

  const isSelected = useCallback(
    (index: number) => {
      if (context) return context.index === index;
      return false;
    },
    [context],
  );

  return (
    <ChakraAccordion {...context} {...accordionProps}>
      {items.map((item, index) => (
        <AccordionItem
          key={item[keySelector] as string | number}
          border="none"
          {...style.accordionItem}
          boxShadow="default"
        >
          {({ isExpanded }) => (
            <>
              <AccordionButton
                {...style.accordionButton}
                position="relative"
                _hover={{}}
                cursor={!isSelected(index) ? 'pointer' : 'initial'}
              >
                {componentButton(item)}
                {!isSelected(index) && icon}
              </AccordionButton>
              <AccordionPanel {...style.accordionPanel}>{componentPanel(item, isExpanded)}</AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </ChakraAccordion>
  );
};
