import { useCallback, useMemo } from 'react';
import { Form, useForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IMAGE_LIST_ERROR } from '@graneet/lib-ui';
import {
  Button,
  Card,
  Container,
  ImageList,
  Section,
  SimpleEditIcon,
  SimpleHelpIcon,
  Tooltip,
  useToast,
} from '@graneet/lib-ui';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import {
  COMPANY_LABEL_SIZE_MAX,
  COMPANY_LABEL_SIZE_MIN,
  LOGO_SIZE_MAX,
  LOGO_SIZE_MIN,
  PERMISSION,
} from '@graneet/business-logic';
import { compact } from 'lodash-es';

import {
  useAddCompanyLabel,
  useDeleteCompanyLabel,
  useUpdateCompanyLabel,
} from 'features/company/services/company-label.api';
import { CompanyEditInformationModal } from 'features/company/components/CompanyEditInformationModal';
import { useAppContext } from 'features/app/contexts/AppContext';
import { useCompanyUpsertLogo } from 'features/company/services/company.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { CompanyInformationFields } from 'features/company/components/CompanyInformationFields';

export const CompanySettingsTab = () => {
  const { t } = useTranslation(['global', 'company']);
  const toast = useToast();

  const canUpdateCompanyInformations = usePermissions([PERMISSION.UPDATE_COMPANY_INFORMATIONS]);
  const canUpdateCompanyInformationsDisabledProps = useDisabledButtonProps([PERMISSION.UPDATE_COMPANY_INFORMATIONS]);

  const companyInformationForm = useForm();
  // company information card
  const { currentUser } = useAppContext();

  const companyUpsertLogoMutation = useCompanyUpsertLogo();
  const addCompanyLabelMutation = useAddCompanyLabel();
  const updateCompanyLabelMutation = useUpdateCompanyLabel();
  const deleteCompanyLabelMutation = useDeleteCompanyLabel();

  const companyModal = useDisclosure();

  /// LOGO
  const memoizedLogo = useMemo(() => compact([currentUser?.company.logo]), [currentUser]);

  const onLogoAdded = useCallback(
    async (logoFile: File[]) => {
      const response = await companyUpsertLogoMutation.mutateAsync(logoFile[0], {
        onError: () => {
          // The error will be handled by the imageList component
          throw new Error('Bad request error');
        },
      });
      return [response.logo];
    },
    [companyUpsertLogoMutation],
  );

  const onLogoEdited = useCallback(
    async (_: unknown, logoFile: File) => {
      const response = await companyUpsertLogoMutation.mutateAsync(logoFile, {
        onError: () => {
          // The error will be handled by the imageList component
          throw new Error('Bad request error');
        },
      });
      return response.logo;
    },
    [companyUpsertLogoMutation],
  );

  const onLogoEditSuccess = useCallback(() => {
    toast.success(t('company:logo.success'));
  }, [toast, t]);

  const onLogoError = useCallback(
    (err: IMAGE_LIST_ERROR) => {
      toast.error(t(`company:logo.errors.${err}`, { minSize: LOGO_SIZE_MIN }));
    },
    [t, toast],
  );

  /// LABELS
  const onLabelAdded = useCallback(
    async (labelFile: File[]) => {
      const label = await addCompanyLabelMutation.mutateAsync(labelFile[0], {
        onError: () => {
          throw new Error('Bad request error');
        },
      });
      return [label];
    },
    [addCompanyLabelMutation],
  );

  const onLabelEdited = useCallback(
    async (id: string | number, labelFile: File) =>
      updateCompanyLabelMutation.mutateAsync(
        { id: +id, labelFile },
        {
          onError: () => {
            // The error will be handled by the imageList component
            throw new Error('Bad request error');
          },
        },
      ),
    [updateCompanyLabelMutation],
  );

  const onLabelDeleted = useCallback(
    async (labelId: string | number) => deleteCompanyLabelMutation.mutateAsync(+labelId),
    [deleteCompanyLabelMutation],
  );

  const onLabelError = useCallback(
    (err: IMAGE_LIST_ERROR) => {
      toast.error(t(`company:labels.errors.${err}`, { minSize: LOGO_SIZE_MIN, maxSize: LOGO_SIZE_MAX }));
    },
    [t, toast],
  );

  const onLabelSuccess = useCallback(
    (destructive: boolean) => {
      toast.success(t(`company:labels.success.${destructive ? 'destructive' : 'nonDestructive'}`));
    },
    [t, toast],
  );

  return (
    <Container>
      <Section title={t('company:information.title')} description={t('company:information.explanation')}>
        <CompanyEditInformationModal modal={companyModal} />
        <Card
          title={t('company:information.fields.label')}
          topRightContent={
            <Button
              variant="outline"
              onClick={companyModal.onOpen}
              leftIcon={<SimpleEditIcon />}
              {...canUpdateCompanyInformationsDisabledProps(false)}
            >
              {t('global:words.c.update')}
            </Button>
          }
        >
          <Form form={companyInformationForm}>
            <CompanyInformationFields company={currentUser.company} />
          </Form>
        </Card>
      </Section>
      <Section title={t('company:logo.title')} description={t('company:logo.explanation')}>
        <ImageList
          thumbnailSize="large"
          maximumNumImages={1}
          labels={{
            edit: t('company:logo.actions.edit'),
            add: t('company:logo.actions.add'),
            drop: t('company:logo.actions.drop'),
          }}
          minDimension={LOGO_SIZE_MIN}
          images={memoizedLogo}
          onImageEdited={onLogoEdited}
          onImageAdded={onLogoAdded}
          onSuccess={onLogoEditSuccess}
          onError={onLogoError}
          readOnly={!canUpdateCompanyInformations}
        />
      </Section>
      <Section
        title={
          <Flex alignItems="baseline">
            <Text>{t('company:labels.title')}</Text>
            <Tooltip label={t('company:labels.tooltip')} placement="right-end">
              <Box mb={0} ml={2}>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          </Flex>
        }
        description={t('company:labels.explanation')}
      >
        <ImageList
          thumbnailSize="small"
          labels={{
            add: t('company:labels.actions.add'),
            drop: t('company:labels.actions.drop'),
            edit: t('company:labels.actions.edit'),
            deleteConfirm: {
              title: t('company:labels.actions.deleteConfirm.title'),
              ok: t('company:labels.actions.deleteConfirm.ok'),
              ko: t('company:labels.actions.deleteConfirm.ko'),
            },
          }}
          images={currentUser.company.labels}
          minDimension={COMPANY_LABEL_SIZE_MIN}
          maxDimension={COMPANY_LABEL_SIZE_MAX}
          onImageAdded={onLabelAdded}
          onImageEdited={onLabelEdited}
          onImageDeleted={onLabelDeleted}
          onError={onLabelError}
          onSuccess={onLabelSuccess}
          mb={-4}
          readOnly={!canUpdateCompanyInformations}
        />
      </Section>
    </Container>
  );
};
