import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Avatar, Circle, Divider, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import isBetween from 'dayjs/plugin/isBetween';
import { groupBy } from 'lodash-es';

import type { IMonthlyTimeTableRange } from '../types/MonthlyTimeTable.type';
import { timeTableTranslations } from '../../configureDefaultLabels';
import { divideFloating, isNumberFinite, multiplyFloating, roundFloating } from '../../../../utils';

dayjs.extend(isBetween);

interface MonthlyTimeTableWeekPlaceholderTooltipProps {
  /**
   * Ranges used in the tooltip with worker hours
   */
  ranges: RangeWithNumberOfWorkedHours[];

  /**
   * Week number, will variability total label
   * This value cannot be defined along monthNumber
   */
  weekNumber?: number;

  /**
   * Month number, will variability total label
   * This value cannot be defined along weekNumber
   */
  monthNumber?: number;

  children: ReactNode;
}

export type RangeWithNumberOfWorkedHours = {
  range: IMonthlyTimeTableRange;
  numberOfWorkedMinutes: number;
  associatedEntityId?: number;
};

export const MonthlyTimeTableWeekTooltip: FC<MonthlyTimeTableWeekPlaceholderTooltipProps> = ({
  ranges,
  weekNumber,
  monthNumber,
  children,
}) => {
  if (
    (!isNumberFinite(weekNumber) && !isNumberFinite(monthNumber)) ||
    (isNumberFinite(weekNumber) && isNumberFinite(monthNumber))
  ) {
    throw new Error('weekNumber or monthNumber must be defined and you cannot have both initialized at the same time');
  }

  /**
   * Group ranges by their associated entity. We want to have one line per project or by worker
   */
  const rangesGroupByAssociatedEntity = useMemo(
    () =>
      Object.values(
        Object.values(groupBy(ranges, 'associatedEntityId')).reduce<
          Record<
            string,
            {
              name: string;
              color: string;
              type: string;
              numberOfWorkedMinutes: number;
              numberOfAbsenceWorkerDays: number;
            }
          >
        >((acc, groupedRanges) => {
          groupedRanges.forEach((rangeWithWorkedMinutes) => {
            if (acc[rangeWithWorkedMinutes.range.associatedEntityId]) {
              acc[rangeWithWorkedMinutes.range.associatedEntityId].numberOfWorkedMinutes +=
                rangeWithWorkedMinutes.numberOfWorkedMinutes;
              return;
            }
            acc[rangeWithWorkedMinutes.range.associatedEntityId] = {
              name:
                rangeWithWorkedMinutes.range.associatedEntityId === -1
                  ? timeTableTranslations.away
                  : rangeWithWorkedMinutes.range.name,
              color: rangeWithWorkedMinutes.range.color,
              type: rangeWithWorkedMinutes.range.type,
              numberOfWorkedMinutes: rangeWithWorkedMinutes.numberOfWorkedMinutes,
              numberOfAbsenceWorkerDays: rangeWithWorkedMinutes.range.absenceDuration,
            };
          });

          return acc;
        }, {}),
      ),
    [ranges],
  );

  const tooltipContent = useMemo(
    () => (
      <Stack direction="column" p={2} textColor="gray.800">
        {/* Render range lines */}
        {rangesGroupByAssociatedEntity.map((currentRange) => (
          <Flex justifyContent="space-between" alignItems="center" w="100%" key={currentRange.name}>
            <Flex alignItems="center">
              {currentRange.type === 'worker' ? (
                <Avatar name={currentRange.name} size="sm" border="1px" />
              ) : (
                <Circle size={5} bg={`${currentRange.color}.200`} />
              )}
              <Text ml={2} wordBreak="break-word">
                {currentRange.name}
              </Text>
            </Flex>

            <Text textColor="gray.900" fontWeight="500" whiteSpace="nowrap">
              {currentRange.numberOfAbsenceWorkerDays
                ? `${currentRange.numberOfAbsenceWorkerDays} ${timeTableTranslations.dayLabel}`
                : `${divideFloating(
                    roundFloating(multiplyFloating(divideFloating(currentRange.numberOfWorkedMinutes, 60), 100)),
                    100,
                  )
                    .toString()
                    .replace('.', ',')} ${timeTableTranslations.hourLabel}`}
            </Text>
          </Flex>
        ))}

        {/* Render total */}
        {rangesGroupByAssociatedEntity.length > 1 && (
          <>
            <Flex w="100%">
              <Divider color="gray.500" />
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" w="100%" textColor="gray.500">
              {weekNumber && <Text>{timeTableTranslations.totalWorkedInWeek(weekNumber)}</Text>}
              {monthNumber && (
                <Text>{timeTableTranslations.totalWorkedInMonth(dayjs().month(monthNumber).format('MMMM'))}</Text>
              )}
              <Text fontWeight="500" whiteSpace="nowrap">
                {`${divideFloating(
                  roundFloating(
                    multiplyFloating(
                      divideFloating(
                        ranges
                          .filter((range) => range.range.associatedEntityId !== -1)
                          .reduce((sum, range) => sum + range.numberOfWorkedMinutes, 0),
                        60,
                      ),
                      100,
                    ),
                  ),
                  100,
                )}`.replace('.', ',')}
                &nbsp;h
              </Text>
            </Flex>
          </>
        )}
      </Stack>
    ),
    [rangesGroupByAssociatedEntity, ranges, weekNumber, monthNumber],
  );

  if (ranges.length === 0) {
    return <>{children}</>;
  }

  return (
    <Tooltip label={tooltipContent} w="20rem" shadow="0px 2px 10px rgba(0, 0, 0, 0.08)" bg="white">
      {children}
    </Tooltip>
  );
};
