import { ORDER_STATUS } from '@graneet/business-logic';
import type { ButtonColorScheme } from '@graneet/lib-ui';

export const ORDER_STATUS_COLOR: Record<ORDER_STATUS, ButtonColorScheme> = {
  [ORDER_STATUS.PROCESSED]: 'blue',
  [ORDER_STATUS.DELIVERED]: 'green',
  [ORDER_STATUS.PARTIALLY_DELIVERED]: 'yellow',
};

export const ORDER_DIRECT_PAYMENT_COLOR = 'white';

export enum ORDER_ENTITY_TYPES {
  ITEM = 'ORDER_ITEM',
}
