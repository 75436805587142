import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICompanyMarginsResponseDTO, IQuoteSellsheetComponentMarginDTO } from '@graneet/business-logic';

import { updateComponentTypeMarginSellSheet } from '../services/quote-sellsheet.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useQuoteSellSheetComponentTypeUpdate = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (
      quoteId: number,
      componentTypeId: string,
      dto: IQuoteSellsheetComponentMarginDTO,
      companyComponentTypes: ICompanyMarginsResponseDTO,
    ) => {
      if (!dto) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        return true;
      }

      const [err, result] = await updateComponentTypeMarginSellSheet(quoteId, parseInt(componentTypeId, 10), dto);

      const componentTypeMargin = companyComponentTypes.componentTypesMargins.find(
        (ct) => `${ct.componentType.id}` === componentTypeId,
      );

      if (err) {
        toast.error(
          t('quote:errors.marginComponentUpdatedFailed', {
            componentTypeName: componentTypeMargin?.componentType.name,
          }),
        );
        return true;
      }

      toast.success(
        t('quote:successes.marginComponentUpdated', { componentTypeName: componentTypeMargin?.componentType.name }),
      );

      updateDataLocally(result);

      return result;
    },
    [toast, t, updateDataLocally],
  );
};
