import { useCallback } from 'react';
import type { Quote, QuoteNode } from '@org/quotation-lib';
import {
  ContentTypeGuard,
  MARGIN_TYPE,
  QuoteNodeCreateChildUseCase,
  QuoteNodeImportManyCluster,
} from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';
import type { ILibraryComponent } from '@graneet/business-logic';
import Big from 'big.js';
import { cloneDeep } from 'lodash-es';
import type { QuoteNodeClusterImportDTO } from '@org/graneet-bff-client';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { mapQuoteNodeObjectToQuoteNodeDTO } from 'features/quotation/quote-common/mappers/quoteNodeMapper';

export function useQuoteLibraryImportComponent() {
  const quoteError = useQuoteError();
  const quoteSetToStore = useQuoteSetToStore();

  const { quoteNodeProxyApi, quoteNodeBulkActionsProxyApi } = useQuotationProxyApis();

  const handleComponents = useCallback((libraryComponent: ILibraryComponent, parentNode: QuoteNode) => {
    const lastChildId = parentNode.getLastChild()?.getId() ?? null;
    const quoteComponent = QuoteNodeCreateChildUseCase.createQuoteComponent(parentNode, lastChildId, null);

    if (!quoteComponent) {
      throw new Error('QuoteComponent is not created');
    }

    const componentContent = quoteComponent.getContent();

    if (!ContentTypeGuard.isQuoteComponent(componentContent)) {
      throw new Error('Content is not a QuoteComponent');
    }

    componentContent.updateProperties({
      refCode: libraryComponent.refCode,
      denomination: libraryComponent.description,
      unit: libraryComponent.unit,
    });

    if (libraryComponent.componentType) {
      componentContent.updateComponentTypeId(libraryComponent.componentType.id, {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });
    }

    componentContent.updateUnitFlatCostAmount(Big(libraryComponent.unitDisbursementExVAT), {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });

    return quoteComponent;
  }, []);

  return useCallback(
    (libraryComponents: ILibraryComponent[], parentId: string): ICommand => ({
      execute(quote: Quote) {
        try {
          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          const components: QuoteNode[] = [];

          if (!this.oldValue) {
            const tree = quote.getTree();
            const parent = tree.getNodeOrThrow(parentId);

            libraryComponents
              .sort(
                (a: ILibraryComponent, b: ILibraryComponent) =>
                  -(a.componentType?.name || '').localeCompare(b.componentType?.name || '') ||
                  -a.description.localeCompare(b.description),
              )
              .forEach((libraryComponent) => {
                const quoteComponent = handleComponents(libraryComponent, parent);
                components.push(quoteComponent);
              });

            quoteSetToStore(quote);

            const newCluster: QuoteNodeClusterImportDTO[] = components.map((component) => ({
              parentId: component.getParentOrThrow().getId(),
              prevSiblingId: component.getPrevSibling()?.getId() ?? null,
              nextSiblingId: component.getNextSibling()?.getId() ?? null,
              cluster: {
                nodeObject: mapQuoteNodeObjectToQuoteNodeDTO(component.export()),
                childrenObject: Array.from(component.getChildren().values()).map((child) =>
                  mapQuoteNodeObjectToQuoteNodeDTO(child.export()),
                ),
              },
            }));

            quoteNodeProxyApi
              .importClusters({
                quoteId: quote.getId(),
                timestamp,
                clientRequestId,
                clusters: newCluster,
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
            if (components.length > 0) {
              const nodes: QuoteNode[] = [];
              components.forEach((item: QuoteNode) => {
                const node = quote.getTree().getNodeOrThrow(item.getId());
                nodes.push(cloneDeep(node));
              });
              this.oldValue = {
                nodes,
              };
            }
          } else if (this.oldValue.nodes.length > 0) {
            const clusters = this.oldValue.nodes.map((node: QuoteNode) => {
              const parent = node.getParentOrThrow();
              const prevSiblingId = node.getPrevSibling()?.getId();
              const nextSiblingId = node.getNextSibling()?.getId();
              return {
                parentId: parent.getId(),
                prevSiblingId: prevSiblingId ?? null,
                nextSiblingId: nextSiblingId ?? null,
                cluster: {
                  nodeObject: node.export(),
                  childrenObject: Array.from(node.getChildren().values()).map((item) => item.export()),
                },
              };
            });
            QuoteNodeImportManyCluster.execute(quote, clusters);
            quoteSetToStore(quote);
            quoteNodeProxyApi
              .importClusters({
                quoteId: quote.getId(),
                timestamp,
                clientRequestId,
                clusters,
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
          }
          return true;
        } catch (err: any) {
          return quoteError(err.message);
        }
      },
      undo(quote: Quote) {
        this.oldValue.nodes.forEach((node: QuoteNode) => {
          const content = node.getContent();
          if (content.getType() === 'QuoteComponent') {
            const quoteComponent = quote.getTree().getQuoteComponentOrThrow(node.getId());
            quoteComponent.deleteQuoteComponent();
          }
        });
        quoteSetToStore(quote);
        if (this.oldValue.nodes.length > 0) {
          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteNodeBulkActionsProxyApi
            .handleBulkDeleteQuoteNode({
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              nodesId: this.oldValue.nodes.map((row: QuoteNode) => row.getId()),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));
          return true;
        }
        return true;
      },
    }),
    [handleComponents, quoteError, quoteNodeBulkActionsProxyApi, quoteNodeProxyApi, quoteSetToStore],
  );
}
