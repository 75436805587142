import { Box, Flex, Grid, HStack, Switch, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { AmountSummary, Button, Card, Date, LabeledData, SimpleEditIcon, TwoColumns } from '@graneet/lib-ui';
import type { FormContextApi } from 'graneet-form';
import { Form, useForm, useFormContext, useOnChangeValues } from 'graneet-form';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ISupplierInvoice } from '@graneet/business-logic';
import { canSupplierInvoiceBeUpdated, PERMISSION } from '@graneet/business-logic';

import { useDirectPaymentContext } from '../contexts/DirectPaymentContext';
import type { ProgressStatementDirectPaymentForm } from '../../progress-statement/forms/progress-statement-direct-payment-form';
import { useSupplierInvoiceEditButtonProps } from '../../supplier-invoice/components/useSupplierInvoiceEditButton';

import { CreateOrEditDirectPaymentSupplierInvoiceModal } from './modals/CreateOrEditDirectPaymentSupplierInvoiceModal';
import { DirectPaymentRemoveButton } from './DirectPaymentRemoveButton';

import { useSupplierInvoice, useSupplierInvoiceUpdate } from 'features/supplier-invoice/services/supplier-invoice.api';
import { formatFilePreviewUrl } from 'features/file/services/file.util';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import type { UpdatableInvoiceFileCardFormValues } from 'features/supplier-invoice/components/cards/UpdatableInvoiceFileCard/UpdatableInvoiceFileCard';
import { UpdatableInvoiceFileCard } from 'features/supplier-invoice/components/cards/UpdatableInvoiceFileCard/UpdatableInvoiceFileCard';

interface DirectPaymentSupplierInvoiceDetailProps {
  supplierInvoiceId: number;
}
interface InProgressStatementSwitchProps {
  supplierInvoiceId: number;
  form: FormContextApi<ProgressStatementDirectPaymentForm>;
}

const InProgressStatementSwitch: FC<InProgressStatementSwitchProps> = ({ supplierInvoiceId, form }) => {
  const { t } = useTranslation(['supplierInvoices']);

  const { ordersSupplierInvoices } = useOnChangeValues(form, ['ordersSupplierInvoices']);

  const isDisplayInProgressStatementPdf = useMemo(() => {
    const orderSupplierInvoice = (ordersSupplierInvoices || []).find(
      (osi) => osi.supplierInvoiceId === supplierInvoiceId,
    );
    return orderSupplierInvoice?.isDisplayInProgressStatementPdf ?? false;
  }, [ordersSupplierInvoices, supplierInvoiceId]);

  const onChange = useCallback(() => {
    form.setFormValues({
      ordersSupplierInvoices: [
        ...(ordersSupplierInvoices || []).map((orderSupplierInvoice) => ({
          ...orderSupplierInvoice,
          isDisplayInProgressStatementPdf:
            orderSupplierInvoice.supplierInvoiceId === supplierInvoiceId
              ? !orderSupplierInvoice.isDisplayInProgressStatementPdf
              : orderSupplierInvoice.isDisplayInProgressStatementPdf,
        })),
      ],
    });
  }, [form, ordersSupplierInvoices, supplierInvoiceId]);

  return (
    <HStack w="100%" alignItems="flex-start" justifyContent="flex-start" spacing="0.5rem" mt="0.3rem">
      <Switch isChecked={isDisplayInProgressStatementPdf} size="md" onChange={onChange} colorScheme="blue" />;
      <Text>{t('supplierInvoices:fields.attachPdf')}</Text>
    </HStack>
  );
};

export const DirectPaymentSupplierInvoiceDetail: FC<DirectPaymentSupplierInvoiceDetailProps> = ({
  supplierInvoiceId,
}) => {
  const { t } = useTranslation(['global', 'supplierInvoices', 'project']);

  const { selectedOrder, updateSupplierInvoice: updateSupplierInvoiceInContext } = useDirectPaymentContext();

  const supplierInvoice = useSupplierInvoice(supplierInvoiceId);

  const supplierInvoiceUpdateMutation = useSupplierInvoiceUpdate();

  const editSupplierInvoiceModal = useDisclosure();

  /**
   * Update of supplier invoice
   */
  const handleSupplierInvoiceUpdate = useCallback(
    (newSupplierInvoice: ISupplierInvoice) => {
      updateSupplierInvoiceInContext(newSupplierInvoice);
    },
    [updateSupplierInvoiceInContext],
  );

  /**
   * Handle the update of a supplier invoice file (delete or add)
   */
  const handleInvoiceFileChange = useCallback(
    async (invoiceFile?: File) => {
      supplierInvoiceUpdateMutation.mutate(
        {
          id: supplierInvoiceId,
          dto: { deletedReceiptFilesIds: '[]', deleteInvoiceFile: 'true' },
          invoiceFile,
        },
        {
          onSuccess: (si) => {
            updateSupplierInvoiceInContext(si);
          },
        },
      );
    },
    [supplierInvoiceUpdateMutation, supplierInvoiceId, updateSupplierInvoiceInContext],
  );

  /**
   * Callback to handle association deletion
   */
  const form = useFormContext<ProgressStatementDirectPaymentForm>();

  const onAssociationDelete = useCallback(() => {
    const { ordersSupplierInvoices } = form.getFormValues();
    form.setFormValues({
      ordersSupplierInvoices: ordersSupplierInvoices?.filter(({ orderId }) => orderId !== selectedOrder?.id),
    });
  }, [form, selectedOrder?.id]);

  /**
   * Display file preview depending on supplier invoice
   */

  const invoiceFileForm = useForm<UpdatableInvoiceFileCardFormValues>();
  useEffect(() => {
    invoiceFileForm.setFormValues({
      invoiceFile: supplierInvoice.data?.invoiceFile
        ? {
            fileURL: formatFilePreviewUrl(supplierInvoice.data?.invoiceFile.id),
            mimeType: supplierInvoice.data?.invoiceFile.mimeType,
          }
        : undefined,
    });
  }, [invoiceFileForm, supplierInvoice.data?.invoiceFile]);

  // Amount computation
  const { amountExVAT: orderAmountExVAT, amountPreviousDirectPaymentsExVAT = 0 } = selectedOrder ?? {};
  const { amountExVAT = 0 } = supplierInvoice.data ?? {};
  const amountToBeBilledExVAT = (orderAmountExVAT || 0) - amountPreviousDirectPaymentsExVAT - (amountExVAT ?? 0);

  const editDirectPaymentSupplierInvoiceDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  const supplierInvoiceEditButtonProps = useSupplierInvoiceEditButtonProps({
    supplierInvoice: supplierInvoice.data,
    allowDirectPaymentEdition: true,
  });

  return (
    <Box overflow="auto" h="100%">
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between" px={5} pt={3.5}>
        <Text fontSize="xl" color="black" fontWeight={600}>
          {supplierInvoice.data?.name}
        </Text>
      </Flex>

      <Grid h="90%" templateColumns="1.25fr 1fr" gap={4}>
        <Box overflowY="scroll" overflowX="-moz-hidden-unscrollable" py={4} pl={5}>
          <Form form={invoiceFileForm} style={{ height: '100%' }}>
            <UpdatableInvoiceFileCard
              onFileChange={handleInvoiceFileChange}
              subHeaderComponent={<InProgressStatementSwitch form={form} supplierInvoiceId={supplierInvoiceId} />}
              disableCardHeaderButtonProps={editDirectPaymentSupplierInvoiceDisabledProps()}
              isReadOnly={!canSupplierInvoiceBeUpdated(supplierInvoice.data).ok}
            />
          </Form>
        </Box>

        <Box py={4} pr={5}>
          <VStack spacing={4} align="stretch">
            <Card title={t('project:directPayments.directPaymentSupplierInvoiceDetail.title')}>
              <VStack gap={4} width="100%">
                <TwoColumns width="100%">
                  <LabeledData
                    label={t('supplierInvoices:cards.informationsCard.invoiceNumber')}
                    data={supplierInvoice.data.invoiceNumber}
                  />
                  <LabeledData
                    label={t('supplierInvoices:cards.informationsCard.billingDate')}
                    data={<Date>{supplierInvoice.data.billingDate}</Date>}
                  />
                </TwoColumns>
                <HStack width="100%" justifyContent="flex-end">
                  {supplierInvoiceEditButtonProps.display && (
                    <Button
                      tooltip={supplierInvoiceEditButtonProps.tooltip}
                      isDisabled={supplierInvoiceEditButtonProps.isDisabled}
                      onClick={editSupplierInvoiceModal.onOpen}
                      leftIcon={<SimpleEditIcon />}
                    >
                      {t('global:words.c.update')}
                    </Button>
                  )}
                </HStack>
              </VStack>
            </Card>

            <Card>
              {selectedOrder && (
                <AmountSummary mt={0}>
                  <AmountSummary.Item
                    lightGrey
                    label={t('project:directPayments.directPaymentSupplierInvoiceDetail.amounts.orderAmountExVAT')}
                    amount={orderAmountExVAT}
                  />
                  <AmountSummary.Item
                    lightGrey
                    label={t('project:directPayments.directPaymentSupplierInvoiceDetail.amounts.billedAmountExVAT')}
                    amount={amountPreviousDirectPaymentsExVAT}
                  />
                  <AmountSummary.Item
                    label={t('project:directPayments.directPaymentSupplierInvoiceDetail.amounts.invoiceAmountExVAT')}
                    fontWeight={600}
                    amount={amountExVAT}
                  />
                  <AmountSummary.Item
                    lightGrey
                    label={t('project:directPayments.directPaymentSupplierInvoiceDetail.amounts.amountToBeBilledExVAT')}
                    amount={amountToBeBilledExVAT}
                  />
                </AmountSummary>
              )}
            </Card>
          </VStack>

          <VStack alignItems="end" mt={4}>
            <DirectPaymentRemoveButton supplierInvoice={supplierInvoice.data} onClick={onAssociationDelete} />
          </VStack>
        </Box>
      </Grid>

      <CreateOrEditDirectPaymentSupplierInvoiceModal
        modal={editSupplierInvoiceModal}
        supplierInvoice={supplierInvoice.data}
        onSubmitSuccess={handleSupplierInvoiceUpdate}
        isEditContext
      />
    </Box>
  );
};
