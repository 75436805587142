import type { QuoteNodeObject } from '@org/quotation-lib';

export function sortQuoteNodeObjectByAutomaticIndex(data: QuoteNodeObject[]) {
  return data.sort((a, b) => {
    // move items with null to the start
    if (a.content.automaticIndex === null) {
      return -1;
    }

    // move items with null to the start
    if (b.content.automaticIndex === null) {
      return 1;
    }

    return a.content.automaticIndex.localeCompare(b.content.automaticIndex, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
}
