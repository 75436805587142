import type { FC } from 'react';
import type { ButtonProps } from '@chakra-ui/react';

import { Button } from '../Button/Button';

export const PrimaryButton: FC<ButtonProps> = ({ children, ...props }) => (
  <Button type="submit" {...props}>
    {children}
  </Button>
);
