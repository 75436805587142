import type { ChannelsMessagingApi, PostMessageDTO } from '@org/graneet-bff-client';

import { proxyHelperTanStack } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class MessagingProxyApi {
  private static messagingProxyApi: MessagingProxyApi;

  private readonly messagingApi: ChannelsMessagingApi;

  private constructor(externalApi: ExternalApi) {
    this.messagingApi = externalApi.getMessagingApi();
  }

  public static getMessagingProxyApi(externalApi: ExternalApi): MessagingProxyApi {
    if (!this.messagingProxyApi) {
      MessagingProxyApi.messagingProxyApi = new MessagingProxyApi(externalApi);
    }
    return MessagingProxyApi.messagingProxyApi;
  }

  public async sendMessage(dto: PostMessageDTO) {
    return proxyHelperTanStack(() => this.messagingApi.postMessageToChannel(dto));
  }
}
