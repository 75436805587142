import type { FC } from 'react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { Card, GotoLink, Button, SimpleDeleteIcon, SimpleAddIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IProject, RequiredByKeys } from '@graneet/business-logic';

import { AssociateProjectModal } from '../modals/AssociateProjectModal';
import { ProjectFieldOption } from '../ProjectField/ProjectFieldOption';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface Translations {
  linkLabel: string;
  unlinkLabel: string;

  modalTitle: string;
  modalDescription: string;
}

interface AssociatedProjectCardProps {
  onLink(project: IProject | null): Promise<void>;

  onUnlink(): void;

  project?: RequiredByKeys<IProject, 'address'> | null;

  translations: Translations;

  canUpdateLink?: boolean;
}

export const ProjectLinkAndUnlinkCard: FC<AssociatedProjectCardProps> = ({
  onLink,
  onUnlink,
  project,
  translations: { linkLabel, unlinkLabel, modalTitle, modalDescription },
  canUpdateLink = true,
}) => {
  const { t } = useTranslation(['project']);

  const { isOpen: isOpenAssociate, onOpen: onOpenAssociate, onClose: onCloseAssociate } = useDisclosure();
  const updateButtonDisabledProps = useDisabledButtonProps(undefined, canUpdateLink);

  return (
    <Card
      title={
        <Flex alignItems="center">
          <Box mr={4}>{t('project:associateProjectCard.title')}</Box>
          {project && <GotoLink to={`/projects/${project.id}`} label={t('project:associateProjectCard.goTo')} />}
        </Flex>
      }
    >
      {project && (
        <>
          <ProjectFieldOption project={project} />

          {canUpdateLink && (
            <Button
              leftIcon={<SimpleDeleteIcon />}
              variant="outline"
              mt={6}
              onClick={onUnlink}
              {...updateButtonDisabledProps()}
            >
              {unlinkLabel}
            </Button>
          )}
        </>
      )}

      {!project && canUpdateLink && (
        <Flex justifyContent="left" mt={6}>
          <Button
            leftIcon={<SimpleAddIcon />}
            variant="outline"
            onClick={onOpenAssociate}
            {...updateButtonDisabledProps()}
          >
            {linkLabel}
          </Button>
        </Flex>
      )}

      <AssociateProjectModal
        title={modalTitle}
        description={modalDescription}
        isOpen={isOpenAssociate}
        onClose={onCloseAssociate}
        onSubmit={onLink}
      />
    </Card>
  );
};
