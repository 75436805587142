import { t } from 'i18next';
import type { Action, DocumentStatusCardAction } from '@graneet/lib-ui';

import type { BillActionsType } from './bill.actions.type';

export function getBillMarkAsPaidAction(params: BillActionsType): DocumentStatusCardAction {
  const isDisabled = !params.hasPayBillsPermission;

  return {
    type: 'secondary',
    label: t('bill:detail.markAsPaid'),
    onClick: params.onPay,
    isDisabled,
    tooltip: isDisabled ? t('global:errors.disabledAction') : undefined,
  };
}

export function getBillRemindPerMailAction(params: BillActionsType): DocumentStatusCardAction | null {
  if (!params.bill.reminder) {
    return null;
  }

  return {
    type: 'secondary',
    label: t('bill:detail.remindPerMail'),
    onClick: params.onRemind,
  };
}

export function getBillMarkAsRemindedAction(params: BillActionsType): DocumentStatusCardAction | null {
  if (!params.bill.reminder) {
    return null;
  }

  return {
    type: 'secondary',
    label: t('bill:markAsReminded'),
    onClick: params.onMarkAsReminded,
  };
}

export function getBillMarkAsLost(params: BillActionsType): Action & { type: 'secondary' } {
  const isDisabled = !params.hasPayBillsPermission;

  return {
    type: 'secondary',
    label: t('bill:detail.markAsLost'),
    onClick: params.onLost,
    isDisabled,
    tooltip: isDisabled ? t('global:errors.disabledAction') : undefined,
    warning: true,
  };
}
