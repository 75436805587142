import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleAddFolderIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" fill="none" strokeWidth="1.25" stroke="#334e68" {...props}>
    <path
      d="M8.66634 4.66667L7.92267 3.17928C7.70861 2.7512 7.60161 2.53715 7.44194 2.38077C7.30074 2.24249 7.13054 2.13731 6.94374 2.07287C6.73247 2 6.49315 2 6.01453 2H3.46634C2.71961 2 2.34623 2 2.06102 2.14533C1.81013 2.27315 1.60616 2.47713 1.47833 2.72801C1.33301 3.01323 1.33301 3.3866 1.33301 4.13333V4.66667M1.33301 4.66667H11.4663C12.5865 4.66667 13.1465 4.66667 13.5743 4.88465C13.9507 5.0764 14.2566 5.38236 14.4483 5.75869C14.6663 6.18651 14.6663 6.74653 14.6663 7.86667V10.8C14.6663 11.9201 14.6663 12.4801 14.4483 12.908C14.2566 13.2843 13.9507 13.5903 13.5743 13.782C13.1465 14 12.5865 14 11.4663 14H4.53301C3.4129 14 2.85285 14 2.42503 13.782C2.0487 13.5903 1.74274 13.2843 1.55099 12.908C1.33301 12.4801 1.33301 11.9201 1.33301 10.8V4.66667ZM7.99967 11.3333V7.33333M5.99967 9.33333H9.99967"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
