import { useTranslation } from 'react-i18next';
import type { Response } from '@graneet/lib-ui';
import {
  UpsellCard,
  useToast,
  SimpleAlertIcon,
  Callout,
  MultiAutocompleteField,
  Button,
  DateRangeField,
  Modal,
  RequiredText,
  formatDateToString,
} from '@graneet/lib-ui';
import { Flex, useDisclosure, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import type { ITimeSlotActivePlanningResponseDTO, IWorker, PHONE_NUMBER_ERROR } from '@graneet/business-logic';
import { getValidPhoneNumber, FEATURE_FLAGS } from '@graneet/business-logic';
import { Form, HiddenField, useForm, useFormContext, useFormStatus, useOnChangeValues } from 'graneet-form';
import { useCallback, useMemo, useState } from 'react';

import { getActiveWorkers, sharePlanning } from '../services/time-slot.api';

import { useData } from 'features/api/hooks/useData';
import { Rule } from 'features/form/rules/Rule';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { Loading } from 'features/common/components/Loading';
import { Error } from 'features/common/components/Error';

const formatWorkerDenomination = (worker: Pick<IWorker, 'firstName' | 'lastName'>) =>
  `${worker.firstName} ${worker.lastName}`;

interface FormValues {
  dates:
    | {
        startDate: Date | null;
        endDate: Date | null;
      }
    | undefined;

  workerIds: number[];
}

const WorkersError = ({ availableWorkers }: { availableWorkers: ITimeSlotActivePlanningResponseDTO }) => {
  const { t } = useTranslation(['timeTracking']);

  const form = useFormContext<FormValues>();
  const { workerIds } = useOnChangeValues(form, ['workerIds']);

  const workersError = availableWorkers
    .filter((worker) => (workerIds ?? []).includes(worker.id))
    .reduce<Array<ITimeSlotActivePlanningResponseDTO[number] & { error: PHONE_NUMBER_ERROR }>>((acc, worker) => {
      const phoneNumber = getValidPhoneNumber(worker.phoneNumber);

      if (phoneNumber.status === 'ko') {
        acc.push({
          ...worker,
          error: phoneNumber.error,
        });
      }

      return acc;
    }, []);

  if (!workerIds || workerIds.length === 0 || workersError.length === 0) {
    return null;
  }

  return (
    <Callout colorScheme="yellow" icon={<SimpleAlertIcon boxSize={5} stroke="yellow.500" />}>
      <Text>{t('timeTracking:sharePlanningModal.incorrectPhoneNumber')}</Text>
      <UnorderedList>
        {workersError.map((workerError) => (
          <ListItem key={workerError.id}>{formatWorkerDenomination(workerError)}</ListItem>
        ))}
      </UnorderedList>

      <HiddenField name="incorrectPhoneNumber">
        <Rule.IsError />
      </HiddenField>
    </Callout>
  );
};

const WorkersSelector = () => {
  const { t } = useTranslation(['timeTracking']);

  const form = useFormContext<FormValues>();
  const { dates } = useOnChangeValues(form, ['dates']);

  const workersData = useData(
    useCallback((): Promise<Response<ITimeSlotActivePlanningResponseDTO | null>> => {
      if (!dates?.startDate || !dates?.endDate) {
        return Promise.resolve([null, null]);
      }

      return getActiveWorkers({
        startDate: formatDateToString(dates.startDate)!,
        endDate: formatDateToString(dates.endDate)!,
      });
    }, [dates]),
  );

  const workerOptions = useMemo(
    () =>
      (workersData.data || []).map((worker) => ({
        label: formatWorkerDenomination(worker),
        value: worker.id,
        badgeColor: 'white',
      })),
    [workersData.data],
  );

  if (workersData.error) {
    return <Error />;
  }

  if (workersData.loading) {
    return <Loading />;
  }

  return (
    <>
      <RequiredText>
        <Text>{t('timeTracking:sharePlanningModal.sendTo')}</Text>
      </RequiredText>

      <MultiAutocompleteField<FormValues, { value: number; label: string }>
        isDisabled={!workersData.data}
        options={workerOptions}
        name="workerIds"
        variant="outlined"
      >
        <Rule.IsRequired />
        <Rule.IsNotEmptyArray />
      </MultiAutocompleteField>

      <WorkersError availableWorkers={workersData.data || []} />
    </>
  );
};

export const TimeTrackingSendPlanningButton = () => {
  const modal = useDisclosure();
  const toast = useToast();

  const hasPlanningSendFF = useFeatureFlag(FEATURE_FLAGS.PLANNING_SEND);

  const { t } = useTranslation(['global', 'timeTracking', 'planning']);

  const form = useForm<FormValues>();
  const { isValid } = useFormStatus(form);

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = useCallback(async () => {
    const formValues = form.getFormValues();
    setIsLoading(true);
    const [error] = await sharePlanning({
      startDate: formatDateToString(formValues.dates?.startDate)!,
      endDate: formatDateToString(formValues.dates?.endDate)!,
      workerIds: formValues.workerIds ?? [],
    });
    setIsLoading(false);

    if (error) {
      toast.error(t('global:errors.error'));
    }

    modal.onClose();
    form.resetForm();
  }, [form, modal, t, toast]);

  return (
    <>
      <Button onClick={modal.onOpen} variant="outline">
        {t('timeTracking:actions.share')}
      </Button>

      <Modal
        title={t('timeTracking:sharePlanningModal.title')}
        size="2xl"
        isOpen={modal.isOpen}
        onClose={modal.onClose}
      >
        {hasPlanningSendFF && (
          <>
            <Form form={form}>
              <Flex direction="column" gap={4}>
                <Flex direction="column">
                  <RequiredText>
                    <Text>{t('timeTracking:sharePlanningModal.date')}</Text>
                  </RequiredText>
                  <DateRangeField<FormValues> name="dates" label={t('timeTracking:sharePlanningModal.date')}>
                    <Rule.IsValidDateRange />
                    <Rule.IsDateRangeShorterThan numberOfDays={7} />
                  </DateRangeField>
                </Flex>

                <WorkersSelector />
              </Flex>
            </Form>

            <Modal.PrimaryButton onClick={onSubmit} isLoading={isLoading} isDisabled={!isValid}>
              {t('global:words.c.send')}
            </Modal.PrimaryButton>
          </>
        )}

        {!hasPlanningSendFF && <UpsellCard description={t('planning:upperPlanProposition')} />}

        <Modal.CloseButton isDisabled={isLoading} />
      </Modal>
    </>
  );
};
