import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { Quote } from '@org/quotation-lib';

import { useQuoteGetFromStoreOrThrow } from 'features/quotation/quote-common/hooks/useQuoteGetFromStoreOrThrow';

const QuoteContext = createContext<{ quote: Quote; reloadQuote: () => void; getQuote: () => Quote }>({
  quote: null as any,
  reloadQuote: () => {},
  getQuote: () => Quote as any,
});

export const useQuote = (): { quote: Quote; reloadQuote: () => void; getQuote: () => Quote } =>
  useContext(QuoteContext);

export const QuoteProvider = ({
  children,
  refetchQuote,
}: {
  children: ReactNode;
  refetchQuote: () => Promise<void>;
}) => {
  const quote = useQuoteGetFromStoreOrThrow()();

  const reloadQuote = useCallback(() => {
    refetchQuote();
  }, [refetchQuote]);

  const getQuote = useCallback(() => quote, [quote]);

  const value = useMemo(() => ({ quote, reloadQuote, getQuote }), [quote, reloadQuote, getQuote]);

  return <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>;
};
