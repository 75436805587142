import type { FC } from 'react';
import { FEATURE_FLAGS, PERMISSION } from '@graneet/business-logic';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { DisplayNotFoundScreen } from '../DisplayNotFoundScreen';
import { usePermissions } from '../../features/role/hooks/usePermissions';

import { OverviewBanking } from './OverviewBanking';
import { ViewBankingTransactions } from './ViewBankingTransactions';
import { ViewBankingPaymentResources } from './ViewBankingPaymentResources';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { QueryWrapper } from 'features/api/components/QueryWrapper';

export const BankingRouter: FC = () => {
  const { path } = useRouteMatch();

  const hasBankingAccountConnectionFF = useFeatureFlag(FEATURE_FLAGS.BANKING_ACCOUNT_CONNECTION);
  const hasAccessToBankingStats = usePermissions([PERMISSION.ACCESS_BALANCE_BANKING]);
  const hasAccessToBankingTransaction = usePermissions([PERMISSION.ACCESS_TRANSACTION_BANKING]);
  const hasAccessToBankingPaymentResource = usePermissions([PERMISSION.ACCESS_PAYMENT_RESOURCE_BANKING]);

  if (!hasBankingAccountConnectionFF) {
    return null;
  }

  return (
    <QueryWrapper>
      <Switch>
        {hasAccessToBankingStats && (
          <Route path={`${path}/overview`}>
            <OverviewBanking />
          </Route>
        )}

        {hasAccessToBankingTransaction && (
          <Route path={`${path}/transactions`}>
            <ViewBankingTransactions />
          </Route>
        )}

        {hasAccessToBankingPaymentResource && (
          <Route path={`${path}/payment-resources`}>
            <ViewBankingPaymentResources />
          </Route>
        )}

        <Route path="*">
          <DisplayNotFoundScreen />
        </Route>
      </Switch>
    </QueryWrapper>
  );
};
