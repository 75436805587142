import { useRef, type FC, type PropsWithChildren, useEffect } from 'react';
import { Box, HStack, type StackProps } from '@chakra-ui/react';
import type { ValueAnimationTransition } from 'framer-motion';
import { AnimatePresence, useAnimate, usePresence } from 'framer-motion';

import { PopUpMenuItem } from './Actions/PopUpMenuItem';
import { PopUpSumItem } from './Actions/PopUpSumItem';
import { PopUpButtonItem } from './Actions/PopUpButtonItem';

export type PopUpMenuProps = PropsWithChildren<StackProps> & {
  withAnimation?: boolean;
  isOpen?: boolean;
};

const transition: ValueAnimationTransition = {
  type: 'spring',
  duration: 0.8,
};

const InternalPopUpMenuComponent: FC<PopUpMenuProps> = ({ children, isOpen, animation, ...props }) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async () => {
        await animate(scope.current, { top: 5 }, transition);
      };
      enterAnimation();
    } else {
      const exitAnimation = async () => {
        await animate(scope.current, { top: -((menuRef.current?.offsetHeight ?? 10) + 20) }, transition);
        safeToRemove();
      };

      exitAnimation();
    }
  }, [animate, isPresent, safeToRemove, scope]);

  return (
    <Box
      ref={scope}
      position="absolute"
      left="50%"
      transform="translateX(-50%)"
      zIndex={3}
      top={-((menuRef.current?.offsetHeight ?? 10) + 20)}
    >
      <Box
        ref={menuRef}
        boxShadow="0px 0px 0px 2px #FFF inset, 0px 12px 16px -4px rgba(13, 18, 28, 0.14), 0px 4px 6px -2px rgba(13, 18, 28, 0.08)"
        background="white"
        backdropFilter="blur(5px)"
        p="0.75rem"
        borderRadius="0.75rem"
        border="0.031rem solid rgba(13, 18, 28, 0.12)"
      >
        <HStack alignItems="center" gap={2} {...props}>
          {children}
        </HStack>
      </Box>
    </Box>
  );
};

const PopUpMenuComponent: FC<PopUpMenuProps> = ({ withAnimation = false, isOpen, ...props }) => (
  <AnimatePresence>
    {((withAnimation && isOpen) || !withAnimation) && <InternalPopUpMenuComponent {...props} />}
  </AnimatePresence>
);

export const PopUpMenu = Object.assign(PopUpMenuComponent, {
  Item: PopUpMenuItem,
  Button: PopUpButtonItem,
  Sum: PopUpSumItem,
});
