import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { isBillLosable } from '@graneet/business-logic';
import { compact } from 'lodash-es';

import type { BillActionsType } from './bill.actions.type';
import {
  getBillMarkAsLost,
  getBillMarkAsPaidAction,
  getBillRemindPerMailAction,
  getBillMarkAsRemindedAction,
} from './bill.actions.util';

export function getBillPartiallyPaidActions(params: BillActionsType): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  actions.push(getBillMarkAsPaidAction(params));

  const billRemindPerMailAction = getBillRemindPerMailAction(params);
  if (billRemindPerMailAction) {
    actions.push(billRemindPerMailAction);
  }

  const billMarkAsRemindedAction = getBillMarkAsRemindedAction(params);
  if (billMarkAsRemindedAction) {
    actions.push(billMarkAsRemindedAction);
  }

  const billMarkAsLost = isBillLosable(params.bill) ? getBillMarkAsLost(params) : null;
  if (billMarkAsLost) {
    actions.push({
      type: 'tertiaryDropdown',
      options: compact([billMarkAsLost]),
    });
  }

  return actions;
}
