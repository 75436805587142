import type { FC, PropsWithChildren } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';

export const HEADER_SIZE = '3rem';

export const Header: FC<PropsWithChildren<FlexProps>> = ({ children, ...props }) => (
  <Flex
    backgroundColor="white"
    width="100%"
    height={HEADER_SIZE}
    align="center"
    fontWeight="bold"
    px={6}
    flexShrink={0}
    {...props}
  >
    {children}
  </Flex>
);
