import { Box } from '@chakra-ui/react';
import { useLayoutEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ListOrdersScreen } from './ListOrdersScreen';
import { CreateOrEditOrderScreen } from './CreateOrEditOrderScreen';
import { ViewOrderDetailScreen } from './ViewOrderDetailScreen';

import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const OrdersRouter: FC = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation(['global']);
  const { updateHeaderTitle } = useHeaderContext();

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.orders'), []);
  }, [t, updateHeaderTitle]);

  return (
    <Switch>
      <Route exact path={path}>
        <ListOrdersScreen />
      </Route>

      <Route exact path={`${path}/create`}>
        <CreateOrEditOrderScreen />
      </Route>

      <Route path={`${path}/:orderId/edit`}>
        <CreateOrEditOrderScreen />
      </Route>

      <Route path={`${path}/:id`}>
        <Box p={8}>
          <ViewOrderDetailScreen />
        </Box>
      </Route>
    </Switch>
  );
};
