import { Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from '@graneet/lib-ui';

import { LedgerList } from 'features/ledger/components/LedgerList';

export const LedgerSettingsTab: FC = () => {
  const { t } = useTranslation(['ledger']);

  return (
    <Container>
      <Text mb={3}>{t('ledger:settings.headline')}</Text>
      <LedgerList />
    </Container>
  );
};
