import type { IOvertime } from '@graneet/business-logic';
import { Modal } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';

import { useDeleteOvertimeType } from '../services/overtime-type.api';

interface OvertimeTypeEditModalProps {
  overtimeTypeToDelete: IOvertime;

  modal: UseDisclosureReturn;
}

export const OvertimeTypeDeleteModal: FC<OvertimeTypeEditModalProps> = ({ overtimeTypeToDelete, modal }) => {
  const { t } = useTranslation(['global', 'planning']);

  const deleteOvertimeType = useDeleteOvertimeType();

  const onDelete = useCallback(async () => {
    deleteOvertimeType.mutate(overtimeTypeToDelete.id, {
      onSuccess: () => {
        modal.onClose();
      },
    });
  }, [deleteOvertimeType, overtimeTypeToDelete.id, modal]);

  return (
    <Modal title={t('planning:overtimeCard.deleteModal.title')} onClose={modal.onClose} isOpen={modal.isOpen}>
      {t('planning:overtimeCard.deleteModal.description', { title: overtimeTypeToDelete?.title })}

      <Modal.Close isDisabled={deleteOvertimeType.isPending} />

      <Modal.PrimaryButton
        onClick={onDelete}
        isLoading={deleteOvertimeType.isPending}
        variant="outline"
        colorScheme="red"
      >
        {t('global:words.c.confirm')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
