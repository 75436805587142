import type { FC } from 'react';
import { useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import type { IContactWithProjectInfos, IGroupedContact } from '@graneet/business-logic';

import { InvoiceDetail } from 'features/invoice/components/InvoiceDetail/InvoiceDetail';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { useInvoiceById } from 'features/invoice/services/invoice.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

interface ViewProjectInvoiceDetailScreenProps {
  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
}

export const ViewProjectInvoiceDetailScreen: FC<ViewProjectInvoiceDetailScreenProps> = ({ projectContacts }) => {
  const { t } = useTranslation(['statement']);
  const { createRedirectionWithSavedFilters } = useFiltersQuery();
  const { title, breadCrumb, updateHeaderTitle } = useHeaderContext();
  const { pathname } = useLocation();

  const { invoiceId: invoiceIdRaw, projectId: projectIdRaw } = useParams<{ invoiceId: string; projectId: string }>();

  const history = useHistory();

  const invoiceId = Number(invoiceIdRaw);
  const projectId = Number(projectIdRaw);

  // Get current invoice
  const invoice = useInvoiceById(invoiceId);

  // When the user clicks on the back button, he should return to the correct page
  // If a state was used going on this current page, we must use it
  const goBackToListing = createRedirectionWithSavedFilters(`/projects/${projectId}/statements`, {
    replace: true,
  });

  const goToCancelInvoice = useCallback(
    (createDraft: boolean) => {
      history.push(`/projects/${projectId}/statements/invoices/${invoiceId}/cancel`, {
        cancelledInvoiceNumber: invoice.data?.invoiceNumber,
        createDraft,
      });
    },
    [history, invoiceId, projectId, invoice.data.invoiceNumber],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(invoice.data?.name ?? '', [
      ...breadCrumb,
      {
        name: title ?? '',
        link: `/projects/${projectId}`,
      },
      {
        name: t('statement:title'),
        link: `/projects/${projectId}/statements`,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, invoice.data, updateHeaderTitle, pathname]);

  return (
    <InvoiceDetail
      invoice={invoice.data}
      onGoBack={goBackToListing}
      onCancelConfirmed={goToCancelInvoice}
      projectId={invoice.data.project?.id}
      projectContacts={projectContacts}
    />
  );
};
