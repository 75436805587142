import type { QuoteNodeBulkActionsApi, QuoteNodeBulkActionDTO } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteNodeBulkActionsProxyApi {
  private static quoteNodeBulkActionsProxyApi: QuoteNodeBulkActionsProxyApi;

  private readonly quoteNodeBulkActionApi: QuoteNodeBulkActionsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteNodeBulkActionApi = externalApi.getQuoteNodeBulkActionsApi();
  }

  public static getQuoteNodeBulkActionProxyApi(externalApi: ExternalApi): QuoteNodeBulkActionsProxyApi {
    if (!this.quoteNodeBulkActionsProxyApi) {
      QuoteNodeBulkActionsProxyApi.quoteNodeBulkActionsProxyApi = new QuoteNodeBulkActionsProxyApi(externalApi);
    }
    return QuoteNodeBulkActionsProxyApi.quoteNodeBulkActionsProxyApi;
  }

  public async handleBulkDeleteQuoteNode(quoteNodeBulkActionDTO: QuoteNodeBulkActionDTO) {
    return proxyHelper(async () => this.quoteNodeBulkActionApi.handleBulkDeleteQuoteNode(quoteNodeBulkActionDTO));
  }

  public async handleBulkTransformQuoteNodeToBasic(quoteNodeBulkActionDTO: QuoteNodeBulkActionDTO) {
    return proxyHelper(async () =>
      this.quoteNodeBulkActionApi.handleBulkTransformQuoteNodeToBasic(quoteNodeBulkActionDTO),
    );
  }

  public async handleBulkTransformQuoteNodeToHiddenCostItem(quoteNodeBulkActionDTO: QuoteNodeBulkActionDTO) {
    return proxyHelper(async () =>
      this.quoteNodeBulkActionApi.handleBulkTransformQuoteNodeToHiddenCostItem(quoteNodeBulkActionDTO),
    );
  }

  public async handleBulkTransformQuoteNodeToOptionalItem(quoteNodeBulkActionDTO: QuoteNodeBulkActionDTO) {
    return proxyHelper(async () =>
      this.quoteNodeBulkActionApi.handleBulkTransformQuoteNodeToOptionalItem(quoteNodeBulkActionDTO),
    );
  }
}
