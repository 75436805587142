import { ConversionStatus, type FileDBCreateDTO } from '@org/graneet-bff-client';
import { CONVERSION_STATUS, type QuoteFileObject } from '@org/quotation-lib';

export const mapConversionStatusToDTO = (conversionStatus: CONVERSION_STATUS) => {
  switch (conversionStatus) {
    case CONVERSION_STATUS.CONVERTED:
      return ConversionStatus.Converted;
    case CONVERSION_STATUS.CONVERTING:
      return ConversionStatus.Converting;
    case CONVERSION_STATUS.ERROR:
      return ConversionStatus.Error;
    case CONVERSION_STATUS.NOT_CONVERTED:
      return ConversionStatus.NotConverted;
    default:
      throw new Error('Status not handled');
  }
};

export const mapQuoteFileObjectToFileDTO = (quoteFileObject: QuoteFileObject): FileDBCreateDTO => ({
  id: quoteFileObject.id,
  key: quoteFileObject.key || '',
  name: quoteFileObject.name || '',
  mimeType: quoteFileObject.mimeType || '',
  url: quoteFileObject.url || '',
  pages: quoteFileObject.pages,
  hasThumbnail: quoteFileObject.hasThumbnail,
  sizeBytes: quoteFileObject.sizeBytes || '',
  conversionStatus: mapConversionStatusToDTO(quoteFileObject.conversionStatus),
});
