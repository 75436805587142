import type { IAccountingConfigBankAccount } from '../accounting/accounting-config-bank-account.type';
import type { IAccountingExport } from '../accounting/accounting-export.type';
import type { IBankingTransaction } from '../banking/banking-transaction.type';
import type { IBill } from '../bill/bill.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IVatDistribution } from '../vat/vat-distribution.type';

export enum PAYMENT_TYPES {
  TOTAL = 'TOTAL',
  PARTIAL = 'PARTIAL',
}

export interface IPayment {
  id: number;

  type: PAYMENT_TYPES;

  amountPaidIncVAT: number;

  paidAt: Date;

  vatDistribution: IVatDistribution | null;

  createdAt: Date;

  updatedAt: Date;

  bill: IBill;

  invoiceNumber?: number;

  accountingExport?: IAccountingExport | null;

  accountingConfigBankAccount?: IAccountingConfigBankAccount | null; // @[ff: accounting-payment-methods] make not nullable?

  bankingTransaction?: IBankingTransaction | null;
}

export type IPaymentWithRelations = RequiredByKeys<IPayment, 'accountingExport'>;
