import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import {
  Filters,
  ListingLayout,
  PaginatedDataProvider,
  usePaginatedData,
  AsyncTableData,
  FULL_QUERY,
  SimpleStatementIcon,
  SimpleAlertIcon,
} from '@graneet/lib-ui';
import type { IProject, IStatement } from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { getAllStatementsPaginated } from 'features/statement/services/statement.api';
import { getStatementPath } from 'features/statement/services/statement.util';
import { StatementTables } from 'features/statement/components/StatementTables/StatementTables';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import {
  STATEMENT_FILTERS,
  STATEMENT_PAGINATED_STATUSES,
  STATEMENT_PAGINATED_STATUSES_VALUES,
  STATEMENT_PAGINATED_STATUSES_COLORS,
} from 'features/statement/constants/statement.constant';
import { ProgressStatementCreateButton } from 'features/progress-statement/components/ProgressStatementCreateButton/ProgressStatementCreateButton';
import { useSubProjectsByProject } from 'features/sub-project/services/sub-project.api';
import { DownPaymentCreateButton } from 'features/down-payment/components/DownPaymentCreateButton/DownPaymentCreateButton';

interface ViewStatementsScreenProps {
  project: IProject;
}

export const ViewStatementsScreen: FC<ViewStatementsScreenProps> = ({ project }) => {
  const { t } = useTranslation(['statement', 'progressStatement', 'global']);
  const { createRedirectionWithFilters } = useFiltersQuery();
  const projectId = project.id;

  // - Fetching data

  const forcedFilters = useMemo(() => ({ projectId, [FULL_QUERY]: true }), [projectId]);
  const data = usePaginatedData(getAllStatementsPaginated, forcedFilters);
  const { data: paginatedStatements } = data;

  const subProjects = useSubProjectsByProject(project.id);

  // Filters
  const availableFilterStatuses = useMemo(
    () =>
      (Object.keys(STATEMENT_PAGINATED_STATUSES) as STATEMENT_PAGINATED_STATUSES[]).map((status) => ({
        value: STATEMENT_PAGINATED_STATUSES_VALUES[status] as string,
        colorScheme: STATEMENT_PAGINATED_STATUSES_COLORS[status],
        label: t(`statement:statuses.${status}`),
      })),
    [t],
  );

  const availableStatementFilterTypes = useMemo(
    () =>
      (Object.keys(STATEMENT_TYPES) as (keyof typeof STATEMENT_TYPES)[])
        .filter((type) => type !== STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT)
        .map((type) => ({
          value: type,
          colorScheme: 'gray' as const,
          label: t(`statement:types.${type}`),
          theme: 'ghost' as const,
        })),
    [t],
  );

  const availableSubProjectFilterTypes = useMemo(
    () =>
      subProjects.data.map((subProject) => ({
        value: subProject.id,
        label: subProject.name,
        colorScheme: 'gray' as const,
      })),
    [subProjects],
  );

  const onRowClick = useCallback(
    (statement: IStatement) => {
      createRedirectionWithFilters(
        `/projects/${projectId}/statements/${getStatementPath(statement.type)}/${statement.id}`,
      )();
    },
    [createRedirectionWithFilters, projectId],
  );

  const displaySubProject = subProjects.data.length > 1;

  return (
    <PaginatedDataProvider data={data}>
      <Filters>
        <ListingLayout h="auto">
          <ListingLayout.Search placeholder={t('progressStatement:search')} />
          <Flex gap={2} alignItems="center">
            <ListingLayout.FilterButton>
              <Filters.CheckBox
                name={STATEMENT_FILTERS.STATUS}
                availableValues={availableFilterStatuses}
                label={t('statement:fields.status')}
              />
              <Filters.CheckBox
                name={STATEMENT_FILTERS.TYPE}
                availableValues={availableStatementFilterTypes}
                label={t('statement:type')}
              />
              <Filters.DateSelect name={STATEMENT_FILTERS.BILLING_DATE} label={t('statement:filters.billingDate')} />
              {displaySubProject && (
                <Filters.MultiSelectFilters
                  name={STATEMENT_FILTERS.SUB_PROJECT_ID}
                  availableValues={availableSubProjectFilterTypes}
                  label={t('statement:fields.subProject')}
                  placeholder={t('global:words.c.select')}
                />
              )}
            </ListingLayout.FilterButton>
          </Flex>
          <ListingLayout.Actions>
            <Flex flexDirection="row" alignItems="center">
              <Box mr={4}>
                <DownPaymentCreateButton subProjects={subProjects.data} project={project} />
              </Box>

              <ProgressStatementCreateButton subProjects={subProjects.data} project={project} />
            </Flex>
          </ListingLayout.Actions>
          <ListingLayout.FiltersApplied
            filters={{
              [STATEMENT_FILTERS.STATUS]: {
                label: t('statement:fields.status'),
                type: 'multi',
                availableValues: availableFilterStatuses,
              },
              [STATEMENT_FILTERS.TYPE]: {
                label: t('statement:type'),
                type: 'multi',
                availableValues: availableStatementFilterTypes,
              },
              [STATEMENT_FILTERS.SUB_PROJECT_ID]: {
                label: t('statement:fields.subProject'),
                type: 'multi',
                availableValues: availableSubProjectFilterTypes,
              },
              [STATEMENT_FILTERS.BILLING_DATE]: {
                label: t('statement:filters.billingDate'),
                type: 'date',
              },
            }}
          />
          <ListingLayout.Content>
            <AsyncTableData
              errorState={{
                icon: <SimpleAlertIcon boxSize={45} />,
                message: t('statement:listingError'),
              }}
              emptyState={{
                message: t('statement:noStatement'),
                icon: <SimpleStatementIcon boxSize={45} />,
              }}
            >
              <StatementTables
                statements={paginatedStatements || []}
                subProjects={subProjects.data}
                onRowClick={onRowClick}
              />
            </AsyncTableData>
          </ListingLayout.Content>
        </ListingLayout>
      </Filters>
    </PaginatedDataProvider>
  );
};
