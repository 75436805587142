import type { IClient, IClientResponseDTO, IContact, IQuote } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Onboarding, SimpleContactIcon, useToast } from '@graneet/lib-ui';
import { HStack, useDisclosure } from '@chakra-ui/react';

import {
  ClientCardContactAssociateType,
  ClientInformationsTab,
} from 'screens/contacts/clients/_tabs/information/ClientInformationsTab';
import { ClientCardTypeEnum } from 'features/client/components/cards/ClientCardDetails';
import { updateQuoteClient } from 'features/quote/services/quote.api';
import {
  ClientContactSelectionStepperModal,
  type ClientContactSelectionStepperModalProps,
} from 'features/client/components/modals/ClientExistingAssociateContactStepperModal/ClientContactSelectionStepperModal';
import { ClientContactCreateStepperModal } from 'features/client/components/modals/ClientContactCreateStepperModal/ClientContactCreateStepperModal';
import {
  getContactAvailableForQuote,
  useContactDissociateAllFromQuote,
  useContactsAssociateToQuote,
} from 'features/contact/services/contact.api';

interface ViewQuoteClientInformationTabProps {
  reloadQuote: () => void;
  quote: IQuote;
}

export const ViewQuoteClientInformationTab: FC<ViewQuoteClientInformationTabProps> = ({ reloadQuote, quote }) => {
  const { t } = useTranslation(['quote', 'clients', 'global', 'contacts']);
  const toast = useToast();
  const [currentClient, setCurrentClient] = useState<IClientResponseDTO | IClient | undefined>(
    quote.client ? (quote.client as IClientResponseDTO) : undefined,
  );

  const contactsAssociateToQuoteMutation = useContactsAssociateToQuote();
  const contactDissociateAllFromQuoteMutation = useContactDissociateAllFromQuote();

  const associateModal = useDisclosure();
  const createModal = useDisclosure();

  const handleClientAssociationOnClose = useCallback(async () => {
    reloadQuote();
    associateModal.onClose();
  }, [associateModal, reloadQuote]);

  const handleCreateOnClose = useCallback(async () => {
    reloadQuote();
    createModal.onClose();
  }, [createModal, reloadQuote]);

  const onClientCreated = useCallback(
    async (clientResponse: IClientResponseDTO) => {
      if (clientResponse) {
        const [err] = await updateQuoteClient(quote.id, clientResponse.id);
        if (err) {
          toast.error(t('quote:cards.client.error'));
          return;
        }

        setCurrentClient(clientResponse);
        toast.success(t(`quote:cards.client.${clientResponse.id ? 'successLink' : 'successUnlink'}`));
      }
    },
    [quote.id, t, toast],
  );

  const onContactCreated = useCallback(
    async (contact: IContact) => {
      await contactsAssociateToQuoteMutation.mutateAsync({
        id: quote.id,
        dto: {
          contacts: [{ id: contact.id, isDefaultEmailRecipient: false }],
        },
      });
      reloadQuote();
    },
    [contactsAssociateToQuoteMutation, quote.id, reloadQuote],
  );

  const updateUniqueClient = useCallback(
    async (clientId: number | null) => {
      const [err, response] = await updateQuoteClient(quote.id, clientId);
      if (err) {
        toast.error(t('quote:cards.client.error'));
      } else {
        toast.success(t(`quote:cards.client.${clientId ? 'successLink' : 'successUnlink'}`));
        return response.client ?? null;
      }
      return null;
    },
    [quote, t, toast],
  );

  const updateContactsAssociations = useCallback(async () => {
    await contactDissociateAllFromQuoteMutation.mutateAsync({ quoteId: quote.id });
    reloadQuote();
  }, [contactDissociateAllFromQuoteMutation, quote.id, reloadQuote]);

  const onClientSelected = useCallback(
    async (clientId: number) => {
      const client = await updateUniqueClient(clientId);

      if (client) {
        setCurrentClient(client);
      }

      return client;
    },
    [updateUniqueClient],
  );

  const removeClient = useCallback(async () => {
    await updateUniqueClient(null);
    await updateContactsAssociations();
    reloadQuote();
  }, [updateUniqueClient, updateContactsAssociations, reloadQuote]);

  const onContactSelected = useCallback(
    async (contacts: Array<{ entity: IContact; isDefaultEmailRecipient: boolean }>) => {
      await contactsAssociateToQuoteMutation.mutateAsync({
        id: quote.id,
        dto: {
          contacts: contacts.map((contact) => ({
            id: contact.entity.id,
            isDefaultEmailRecipient: contact.isDefaultEmailRecipient,
          })),
        },
      });

      if (contacts.length > 1) {
        toast.success(t('contacts:availableModal.toastSuccessManyAssociation', { nbContact: contacts.length }));
      } else {
        toast.success(t('contacts:availableModal.toastSuccessOneAssociation'));
      }

      reloadQuote();
    },
    [contactsAssociateToQuoteMutation, quote.id, reloadQuote, t, toast],
  );

  const contactsRetriever = useCallback<ClientContactSelectionStepperModalProps<IContact>['contactsRetriever']>(
    () => [{ quoteId: quote.id }, getContactAvailableForQuote],
    [quote.id],
  );

  if (quote.client && currentClient) {
    return (
      <Container size="md">
        <ClientInformationsTab
          associationType={ClientCardContactAssociateType.QUOTE}
          client={quote.client}
          type={ClientCardTypeEnum.CHANGE}
          onClientCardButtonClicked={removeClient}
          onClientUpdate={updateUniqueClient}
          onContactUpdate={reloadQuote}
          quoteId={quote.id}
          quoteStatus={quote.status}
        />
      </Container>
    );
  }

  return (
    <>
      <Onboarding
        message={t('quote:completeModal.error.client')}
        icon={<SimpleContactIcon boxSize={45} />}
        mb={8}
        mt={16}
        h="auto"
        action={
          <HStack gap={4}>
            <Button onClick={associateModal.onOpen} variant="outline">
              {t('quote:clientStep.cta.clientExisting')}
            </Button>
            <Button onClick={createModal.onOpen} colorScheme="blue" marginLeft="auto">
              {t('clients:createClient')}
            </Button>
          </HStack>
        }
      />
      <ClientContactSelectionStepperModal
        isOpen={associateModal.isOpen}
        onClose={handleClientAssociationOnClose}
        onClientSelected={onClientSelected}
        onSubmit={onContactSelected}
        contactsRetriever={contactsRetriever}
        isContactDefaultEmailRecipient={(contact) => contact.isDefaultEmailRecipient}
      />
      <ClientContactCreateStepperModal
        isOpen={createModal.isOpen}
        onClose={handleCreateOnClose}
        onClientCreated={onClientCreated}
        onContactCreated={onContactCreated}
      />
    </>
  );
};
