import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICompanyMarginsResponseDTO, IQuoteSellsheetComponentMarginDTO } from '@graneet/business-logic';
import { groupExistingComponentsByType } from '@graneet/business-logic';

import * as lotApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const usePreviewLotComponentTypeMarginSellSheet = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (
      lotId: number,
      componentTypeId: number,
      dto: IQuoteSellsheetComponentMarginDTO,
      companyComponentTypes: ICompanyMarginsResponseDTO,
    ) => {
      if (!dto) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        return true;
      }

      const [err, result] = await lotApi.previewLotComponentTypeMarginSellSheet(lotId, componentTypeId, dto);

      if (err) {
        toast.error(t('quote:errors.updatingError'));
        return true;
      }

      updateDataLocally(result);

      const { components } = result;

      return groupExistingComponentsByType(Object.values(components), companyComponentTypes);
    },
    [toast, t, updateDataLocally],
  );
};
