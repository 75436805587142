import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Container, Onboarding, STORAGE_STRATEGY, Section, SimpleQuoteIcon, usePaginatedData } from '@graneet/lib-ui';
import type { IProject, IQuote, SubProjectExtendedContract } from '@graneet/business-logic';
import { FEATURE_FLAGS, isProjectFinished, PERMISSION } from '@graneet/business-logic';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import type { Response } from '@graneet/lib-ui';
import type { QuotePaginatedDTO } from '@org/graneet-bff-client';
import { QuoteStatus } from '@org/graneet-bff-client';

import { QuotesTable as QuotesTableV1 } from 'features/quote/components/tables/QuotesTable';
import { QuoteActionsButtons as QuoteActionsButtonsV1 } from 'features/quote/components/buttons/QuoteActionsButtons';
import { useProjectSubProjectsWithContracts } from 'features/project/services/project.api';
import { getQuotesNotAccepted } from 'features/quote/services/quote.api';
import { ProjectAmountSummary } from 'features/project/components/ProjectAmountSummary';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { Error } from 'features/common/components/Error';
import { Loading } from 'features/common/components/Loading';
import { useData } from 'features/api/hooks/useData';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { SubProjectTables } from 'features/sub-project/components/SubProjectTable/SubProjectTables';
import { ContractActions } from 'features/contract/components/ContractTable/ContractActions';
import { ContractDeleteModal } from 'features/contract/components/modals/ContractDeleteModal';
import { getDownloadUrl } from 'features/pdf/services/pdf.api';
import { getContractPdfName } from 'features/contract/services/contract.util';
import { downloadFile } from 'features/file/services/file.util';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { QuoteTable } from 'features/quotation/quote/components/tables/QuoteTable';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useAppContext } from 'features/app/contexts/AppContext';
import { QUOTE_FIELDS } from 'features/quote/constants/quotes.constant';
import { QuoteActionsButtons } from 'features/quotation/quote/components/buttons/QuoteActionsButtons';

interface ContractDropDownProps {
  contract: SubProjectExtendedContract;

  onContractEdited(contractId: number): void;

  onContractDeleted(): void;

  numberOfContracts: number;
}

const ContractDropDown: FC<ContractDropDownProps> = ({
  contract,
  onContractEdited,
  onContractDeleted,
  numberOfContracts,
}) => {
  const deleteContractModal = useDisclosure();

  const onPDFDownload = useCallback(() => {
    if (!contract.lastPdf) {
      return;
    }

    const downloadUrl = getDownloadUrl(
      contract.lastPdf.apiId,
      getContractPdfName(contract, contract.lastPdf.createdAt),
    )!;
    downloadFile(downloadUrl);
  }, [contract]);

  return (
    <>
      <ContractActions
        onEdit={onContractEdited}
        onDelete={deleteContractModal.onOpen}
        onPDFDownload={onPDFDownload}
        contract={contract}
        isDeletable={contract.isDeletable}
      />

      <ContractDeleteModal
        onClose={deleteContractModal.onClose}
        isOpen={deleteContractModal.isOpen}
        onContractDeleted={onContractDeleted}
        contract={contract}
        numberOfContracts={numberOfContracts}
      />
    </>
  );
};

interface ViewContractsScreenProps {
  project: IProject;
}

export const ViewContractsScreen: FC<ViewContractsScreenProps> = ({ project }) => {
  const { t } = useTranslation(['project', 'subProject', 'contracts']);
  const history = useHistory();
  const { currentUser } = useAppContext();

  const isQuoteV2Enabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);

  // Permissions
  const hasUpdateContractsPermission = usePermissions([PERMISSION.UPDATE_CONTRACTS]);
  const hasAllQuotesPermissions = usePermissions([PERMISSION.VIEW_ALL_QUOTES]);

  // @ff quote-v2
  // Associated Quotes

  const emptyGetterV1 = useCallback(() => Promise.resolve<Response<IQuote[]>>([null, []]), []);
  const emptyGetterV2 = useCallback(
    () =>
      Promise.resolve<Response<QuotePaginatedDTO>>([
        null,
        {
          data: [],
          metadata: {
            hasMore: false,
            totalCount: 0,
            unfilteredCount: 0,
            offsetV1: 0,
            offsetV2: 0,
          },
          sums: {},
        },
      ]),
    [],
  );

  const notAcceptedQuotesDataGetter = useDataGetter(
    isQuoteV2Enabled ? emptyGetterV1 : getQuotesNotAccepted,
    project.id,
  );
  const notAcceptedQuotesData = useData(notAcceptedQuotesDataGetter);
  const {
    data: notAcceptedQuotes,
    fetch: fetchQuotes,
    loading: isNotAcceptedQuotesLoading,
    error: hasNotAcceptedQuotesError,
  } = notAcceptedQuotesData;

  const quoteProxy = useQuotationProxyApis().quoteProxyApi;

  const getPaginatedQuotesGetter = useCallback(
    (params: URLSearchParams | undefined) => quoteProxy!.getQuotesPaginatedByCompanyId(params),
    [quoteProxy],
  );
  const forcedFilters = useMemo(
    () => ({
      _full: true,
      _projectId: project.id,
      [QUOTE_FIELDS.STATUS]: Object.values(QuoteStatus).filter(
        (status) => status !== QuoteStatus.Accepted && status !== QuoteStatus.Archived,
      ),
      ...(!hasAllQuotesPermissions && {
        [QUOTE_FIELDS.ACCOUNT_MANAGERS]: [currentUser!.id],
      }),
    }),
    [currentUser, hasAllQuotesPermissions, project.id],
  );

  const data = usePaginatedData(
    isQuoteV2Enabled ? getPaginatedQuotesGetter : emptyGetterV2,
    forcedFilters,
    forcedFilters,
    STORAGE_STRATEGY.STATE,
  );
  const {
    data: paginatedQuotes,
    fetch: fetchQuotesV2,
    loading: isPaginatedQuotesLoading,
    error: hasPaginatedQuotesError,
  } = data;

  const subProjectsWithContracts = useProjectSubProjectsWithContracts(project.id);

  const isLoading = useMemo(() => {
    if (isQuoteV2Enabled) {
      return isPaginatedQuotesLoading;
    }
    return isNotAcceptedQuotesLoading;
  }, [isNotAcceptedQuotesLoading, isPaginatedQuotesLoading, isQuoteV2Enabled]);

  const hasError = useMemo(() => {
    if (isQuoteV2Enabled) {
      return hasPaginatedQuotesError;
    }
    return hasNotAcceptedQuotesError;
  }, [hasNotAcceptedQuotesError, hasPaginatedQuotesError, isQuoteV2Enabled]);

  const quotesLength = useMemo(() => {
    if (isQuoteV2Enabled) {
      return paginatedQuotes?.length || 0;
    }
    return notAcceptedQuotes?.length || 0;
  }, [isQuoteV2Enabled, notAcceptedQuotes?.length, paginatedQuotes?.length]);

  /**
   * Callback actions
   */

  const refetchQuotes = useCallback(() => {
    if (isQuoteV2Enabled) {
      fetchQuotesV2();
    } else {
      fetchQuotes();
    }
  }, [fetchQuotes, fetchQuotesV2, isQuoteV2Enabled]);

  const handleContractDelete = useCallback(() => {
    if (hasUpdateContractsPermission) {
      refetchQuotes();
    }
  }, [hasUpdateContractsPermission, refetchQuotes]);

  const onContractEdited = useCallback(
    (contractId: number) => {
      history.push(`/projects/${project.id}/contracts/${contractId}`);
    },
    [history, project.id],
  );

  /**
   * Memoized components
   */
  const QuoteActions = useMemo(() => {
    const areActionsDisabled = isProjectFinished(project);

    return isQuoteV2Enabled ? (
      <QuoteActionsButtons onImportCompleted={refetchQuotes} project={project} isDisabled={areActionsDisabled} />
    ) : (
      <QuoteActionsButtonsV1 onImportCompleted={refetchQuotes} isDisabled={areActionsDisabled} project={project} />
    );
  }, [refetchQuotes, project, isQuoteV2Enabled]);

  const dropDownRender = useCallback(
    (props: { numberOfContracts: number; contract: SubProjectExtendedContract }) => (
      <ContractDropDown
        numberOfContracts={props.numberOfContracts}
        contract={props.contract}
        onContractDeleted={handleContractDelete}
        onContractEdited={onContractEdited}
      />
    ),
    [handleContractDelete, onContractEdited],
  );

  const handleSubProjectEdit = useCallback(
    (subProjectId: string) => {
      history.push(`/projects/${project.id}/contracts/sub-projects/${subProjectId}`);
    },
    [history, project.id],
  );

  if (isLoading) {
    return <Loading />;
  }

  if (hasError) {
    return <Error />;
  }

  return (
    <Container>
      {quotesLength > 0 && (
        <Section
          title={t('project:quotes.associatedQuotes')}
          topContent={
            <Flex w="100%" justifyContent="flex-end">
              {QuoteActions}
            </Flex>
          }
        >
          <Box borderRadius="6px" boxShadow="subtle" overflow="hidden">
            {isQuoteV2Enabled ? (
              <QuoteTable
                quotes={paginatedQuotes || []}
                showAccountManagers={false}
                showClientColumn={false}
                hasPagination={false}
              />
            ) : (
              <QuotesTableV1 quotes={notAcceptedQuotes || []} />
            )}
          </Box>
        </Section>
      )}

      {subProjectsWithContracts.data.subProjects.length > 0 && (
        <Section
          title={t('project:quotes.acceptedQuotes')}
          description={
            subProjectsWithContracts.data.subProjects.length > 1 && (
              <Trans
                t={t}
                i18nKey="subProject:subProjectSection.description"
                count={subProjectsWithContracts.data.subProjects.length}
              >
                <Text as="span" fontWeight="semibold">
                  displayed_data
                </Text>
              </Trans>
            )
          }
          topContent={
            quotesLength === 0 && (
              <Flex w="100%" justifyContent="flex-end">
                {QuoteActions}
              </Flex>
            )
          }
        >
          <Box borderRadius="6px" boxShadow="subtle" overflow="hidden">
            <SubProjectTables
              subProjects={subProjectsWithContracts.data.subProjects}
              dropDownRender={dropDownRender}
              onSubProjectEdit={handleSubProjectEdit}
              displayGoToQuoteLink
            />
          </Box>
          <ProjectAmountSummary project={project} subProjects={subProjectsWithContracts.data.subProjects} />
        </Section>
      )}

      {notAcceptedQuotes?.length === 0 && subProjectsWithContracts.data.subProjects.length === 0 && (
        <Onboarding
          icon={<SimpleQuoteIcon boxSize={45} />}
          message={t('contracts:noAssociatedContract')}
          action={QuoteActions}
        />
      )}
    </Container>
  );
};
