import type { FC, ReactNode } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { EllipsisText } from '../EllipsisText/EllipsisText';

import { GenericFileIcon } from './GenericFileIcon';

export interface FileProps extends FlexProps {
  name: string;

  createdAtLabel?: string;

  additionalContent?: ReactNode;

  actions: ReactNode;

  isArchived?: boolean;
}

export const File: FC<FileProps> = ({
  name,
  createdAtLabel,
  additionalContent,
  actions,
  isArchived,
  ...otherProps
}) => (
  <Flex my={2} {...otherProps} alignItems="center">
    <GenericFileIcon boxSize={5} mr={6} isArchived={isArchived} />

    {/* Dirty fix for long name */}
    <Flex overflow="hidden">
      <Box w="100%">
        <Flex {...otherProps} direction="row" alignItems="baseline" flex={1}>
          <EllipsisText color="gray.800" fontSize="sm" mr={3}>
            {name}
          </EllipsisText>

          <Text color="gray.500" fontSize="xs" mr={3} whiteSpace="nowrap">
            {createdAtLabel}
          </Text>

          {additionalContent}
        </Flex>
      </Box>
    </Flex>

    <Flex flex={1} justifyContent="flex-end" alignItems="center" mx={4}>
      {actions}
    </Flex>
  </Flex>
);
