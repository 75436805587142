import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { t } from 'i18next';

import type { HoldbackActionsType } from './holdback.actions.type';

export function getHoldbackToBeReceivedActions(
  params: Omit<HoldbackActionsType, 'holdback'>,
): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  const isDisabled = !params.hasPayHoldbackPermission;

  if (!params.hasAccountingStandards) {
    actions.push({
      type: 'primary',
      label: t('holdback:toReceive'),
      onClick: params.onMarkReceived,
      isDisabled,
      tooltip: isDisabled ? t('global:errors.disabledAction') : undefined,
    });
  }

  return actions;
}
