import { useMemo } from 'react';

import type { ImageSize } from './types';

export const useGetImageBoxSize = (size: ImageSize) =>
  useMemo(() => {
    switch (size) {
      case 'sm':
        return '34px';
      case 'md':
      case 'lg':
      default:
        return '44px';
    }
  }, [size]);
