import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Form, HiddenField, useForm, useFormContext, useHiddenField } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';
import type { DeepTableRowProps } from '@graneet/lib-ui';
import {
  ActionMenu,
  DeepTable,
  INPUT_NOTE_DEEP_TABLE_STYLE,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
  RichTextField,
  SimpleRewindIcon,
} from '@graneet/lib-ui';
import { GridItem } from '@chakra-ui/react';

import { useContractItem, useContractTreeContext } from '../../hooks/tree.hooks';
import type { ContractEditionForm } from '../../forms/contract-edition.form';
import { getItemFieldName } from '../../forms/contract-edition.form';

import { getDeepTableOffset } from 'features/contract/services/contract.util';
import { STYLE_CONTRACT_ITEM, STYLE_CONTRACT_ITEM_NOTE_INPUT } from 'features/contract/constants/styles';

interface ContractItemNoteProps {
  id: string | number;

  depth: number;

  onUpdateNote(newNote: undefined | null | string): () => void;

  hasInputFocused: boolean;

  onFocus(): void;

  isLineFilled: boolean;
}

interface NoteForm {
  note?: string | null;
}

export const ContractItemNote: FC<ContractItemNoteProps> = ({
  id,
  depth,
  onUpdateNote,
  hasInputFocused,
  onFocus,
  isLineFilled,
}) => {
  const parentForm = useFormContext<ContractEditionForm>();
  const hiddenField = useHiddenField(parentForm, getItemFieldName(id, 'note'));

  const form = useForm<NoteForm>();

  const {
    leaf: item,
    state: { updatedKeys, isDeleted: isItemDeleted, isCreated: isItemCreated },
  } = useContractItem(id);
  const { restoreLeaf: restoreContractItem, getInitialTree } = useContractTreeContext();

  useEffect(() => {
    form.setFormValues({ note: '' });
  }, [form]);

  useEffect(() => {
    if (item?.note) {
      form.setFormValues({ note: item.note });
    }
  }, [form, hiddenField.value, item]);

  const { t } = useTranslation(['global', 'quote']);

  const isNewNote = !getInitialTree().leaves[id]?.note;
  const isNoteDeleted = (isNil(item?.note) && updatedKeys.includes('note')) || (isItemDeleted && !isNewNote);

  const handleNoteUpdate = useCallback(() => {
    const value = form.getFormValues().note;
    onUpdateNote(value)();
  }, [form, onUpdateNote]);

  const handleNoteRestore = useCallback(() => {
    const initialNoteValue = getInitialTree().leaves[id]?.note;
    onUpdateNote(initialNoteValue)();
    form.setFormValues({ note: initialNoteValue });

    if (isItemDeleted) {
      restoreContractItem(id);
    }
  }, [form, getInitialTree, id, isItemDeleted, onUpdateNote, restoreContractItem]);

  const handleNoteDelete = useCallback(() => {
    const newNoteValue = isNewNote ? undefined : null;
    onUpdateNote(newNoteValue)();

    if (isNewNote) {
      form.setFormValues({ note: newNoteValue });
    }
  }, [form, isNewNote, onUpdateNote]);

  const noteTextStyle = useMemo(
    () => ({
      ...(isNoteDeleted ? STYLE_CONTRACT_ITEM_NOTE_INPUT.IS_DELETED : STYLE_CONTRACT_ITEM_NOTE_INPUT.DEFAULT),
      ...INPUT_NOTE_DEEP_TABLE_STYLE(hasInputFocused),
    }),
    [hasInputFocused, isNoteDeleted],
  );

  const noteRowStyle = useMemo(() => {
    const styleBaseOnState: DeepTableRowProps = {};
    if (!isLineFilled) {
      styleBaseOnState.bg = 'yellow.100';
    } else if (updatedKeys.length > 0 || isItemCreated) {
      styleBaseOnState.bg = 'blue.50';
    }

    return isNoteDeleted ? STYLE_CONTRACT_ITEM.IS_DELETED : { ...STYLE_CONTRACT_ITEM.DEFAULT, ...styleBaseOnState };
  }, [isItemCreated, isLineFilled, isNoteDeleted, updatedKeys]);

  const displayNote = !isNil(item?.note) || isNoteDeleted;
  if (!displayNote) {
    return <HiddenField {...hiddenField} />;
  }

  const canBeRestored = (isNoteDeleted || updatedKeys.includes('note')) && !isNewNote;
  const canBeDeleted = !isNoteDeleted;

  return (
    <DeepTable.Row offset={getDeepTableOffset(depth)} {...noteRowStyle}>
      <GridItem colSpan={8}>
        <DeepTable.Cell>
          <HiddenField {...hiddenField} />

          <Form form={form} style={{ flex: 1 }}>
            <RichTextField<NoteForm>
              name="note"
              navbarType="inline"
              onBlur={handleNoteUpdate}
              onFocus={onFocus}
              inputProps={noteTextStyle}
              isDisabled={isNoteDeleted}
              variant="light"
              configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
            />
          </Form>
        </DeepTable.Cell>
      </GridItem>

      <DeepTable.Cell />

      <DeepTable.Cell alignItems="flex-start">
        <ActionMenu>
          {canBeRestored && (
            <ActionMenu.Action
              onClick={handleNoteRestore}
              label={t('global:words.c.restore')}
              icon={<SimpleRewindIcon />}
            />
          )}

          {canBeDeleted && <ActionMenu.Delete label={t('quote:jobsStep.deleteNote')} onClick={handleNoteDelete} />}
        </ActionMenu>
      </DeepTable.Cell>
    </DeepTable.Row>
  );
};
