import type { FC } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import type { WeekData } from '../../contexts/MonthlyTimeTable.context';

dayjs.extend(isBetween);

interface MonthlyTimeTableWeekSlotProps {
  weekData: WeekData;

  columnStartIndex: number;

  rowIndex: number;
}

export const MonthlyTimeTableMonthGrid: FC<MonthlyTimeTableWeekSlotProps> = ({
  weekData,
  columnStartIndex,
  rowIndex,
}) => {
  const { days: weekDays } = weekData;

  return (
    <GridItem
      flex={1}
      colSpan={weekDays.length}
      boxSize="100%"
      gridRowStart={rowIndex + 1}
      gridColumnStart={columnStartIndex - weekDays.length + 1}
    >
      <Grid alignItems="flex-start" templateColumns={`repeat(${weekDays.length}, 1fr)`} boxSize="100%" gridGap="0 1px">
        {weekDays.map((day) => {
          const isWeekEnd = [0, 6].includes(day.day());

          return (
            <GridItem
              key={`weekday_${day.toISOString()}`}
              flex={1}
              py={3}
              alignItems="center"
              justifyContent="center"
              h="100%"
              background={isWeekEnd ? 'gray.50' : 'white'}
            />
          );
        })}
      </Grid>
    </GridItem>
  );
};
