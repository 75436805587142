import {
  Button,
  ListingLayout,
  generateColorFromString,
  PaginatedTable,
  type PaginatedTableProps,
  Address,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useCallback, type FC, useMemo, useLayoutEffect } from 'react';
import type { IClientResponseDTO, PaginatedResponse } from '@graneet/business-logic';
import { useHistory } from 'react-router-dom';

import { TagsBadges } from 'features/common/components/TagsBadges';
import { useClientImport, useClients, useClientsTags } from 'features/client/services/client.api';
import { ClientContactCreateStepperModal } from 'features/client/components/modals/ClientContactCreateStepperModal/ClientContactCreateStepperModal';
import { TEMPLATE_URL } from 'features/import/constants/import.constant';
import type { ImportClientField } from 'features/client/hooks/useImportClientFields';
import { useImportClientFields } from 'features/client/hooks/useImportClientFields';
import type { ImportSpreadsheetSubmit } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImport } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImportMenu } from 'features/import/components/SpreadsheetImportMenu';
import { SpreadsheetImportingModal } from 'features/import/components/SpreadsheetImportingModal';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewClientsScreen: FC = () => {
  const { t } = useTranslation(['clients', 'global']);
  const { updateHeaderTitle } = useHeaderContext();
  const history = useHistory();

  const createModal = useDisclosure();
  const importModal = useDisclosure();
  const importingModal = useDisclosure();

  const clients = useClients();
  const clientsTags = useClientsTags();

  const clientImportMutation = useClientImport();

  const importFields = useImportClientFields();

  const onSubmitImport = useCallback<ImportSpreadsheetSubmit<ImportClientField>>(
    async (importData) => {
      importingModal.onOpen();

      const dto = importData.validData.map((row) => ({
        code: row.code?.toString() || null,
        enterpriseName: row.enterpriseName!.toString(),
        siren: row.siren?.toString() || null,
        address: {
          address: row.address!.toString(),
          city: row.city?.toString(),
          postalCode: row.postalCode?.toString(),
          country: row.country?.toString(),
        },
        hasBillingAddress: false,
        billingAddress: undefined,
        auxiliaryAccount: row.auxiliaryAccount?.toString(),
      }));
      await clientImportMutation.mutateAsync(
        { clients: dto },
        {
          onSuccess: () => {
            clients.refetch();
            importingModal.onClose();
          },
        },
      );
    },
    [importingModal, clientImportMutation, clients],
  );

  const availableTags = useMemo(
    () =>
      clientsTags.data.map<{ value: string; label: string }>((tag) => ({
        value: tag,
        label: tag,
        badgeColor: generateColorFromString(tag),
      })),
    [clientsTags.data],
  );

  const columnDefs = useMemo<PaginatedTableProps<PaginatedResponse<IClientResponseDTO>>['columnDefs']>(
    () => [
      {
        field: 'code',
        sortable: true,
        headerName: t('clients:fields.code'),
      },

      {
        field: 'enterpriseName',
        sortable: true,
        headerName: t('clients:fields.enterpriseName'),
        autoHeight: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) =>
          props.data ? (
            <>
              {props.data.enterpriseName}
              {props.data.tags.length > 0 && (
                <Box mb={2}>
                  <TagsBadges tags={props.data.tags} />
                </Box>
              )}
            </>
          ) : null,
      },
      {
        field: 'billingAddress',
        sortable: true,
        headerName: t('global:address.billingAddress'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) =>
          props.data ? (
            <Address address={props.data.hasBillingAddress ? props.data.billingAddress! : props.data.address} oneLine />
          ) : null,
      },
    ],
    [t],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.clients'), []);
  }, [t, updateHeaderTitle]);

  return (
    <>
      <ListingLayout
        pagination={clients}
        search={{
          placeholder: t('clients:search'),
        }}
        actions={
          <>
            <Button onClick={createModal.onOpen} colorScheme="blue">
              {t('clients:createClient')}
            </Button>
            <SpreadsheetImportMenu
              label={t('clients:importClients')}
              templateUrl={TEMPLATE_URL.CLIENTS}
              onOpen={importModal.onOpen}
            />
          </>
        }
        filters={[
          {
            type: 'multi',
            name: 'tags',
            availableValues: availableTags,
            label: t('global:words.tags'),
            noValueMessage: t('global:tags.noLabel'),
            placeholder: t('global:words.c.select'),
          },
        ]}
        content={
          <PaginatedTable
            gridId="client"
            pagination={clients}
            columnDefs={columnDefs}
            zeroState={{
              icon: <i className="ri-contacts-book-2-line ri-4x" />,
              label: t('clients:noClients'),
            }}
            emptyState={{
              label: t('clients:noResult'),
            }}
            onRowClicked={(event) => {
              history.push(`/contacts/clients/${event.data?.id}`);
            }}
          />
        }
      />

      {importModal.isOpen && (
        <SpreadsheetImport onClose={importModal.onClose} onSubmit={onSubmitImport} fields={importFields} />
      )}

      <SpreadsheetImportingModal {...importingModal} />

      <ClientContactCreateStepperModal
        isOpen={createModal.isOpen}
        onClose={createModal.onClose}
        onClientCreated={clients.refetch}
        onContactCreated={clients.refetch}
        hasIsDefaultEmailRecipient
      />
    </>
  );
};
