import { Alert, VStack, HStack, Text } from '@chakra-ui/react';
import { AutoClickButton, GraneetButton as Button } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

export const UpdateSimilarComponentToast = ({
  componentsRelatedCount,
  onConfirmation,
  onRejection,
}: {
  componentsRelatedCount: number;
  onConfirmation: () => void;
  onRejection: () => void;
}) => {
  const { t } = useTranslation(['quote']);

  return (
    <Alert
      borderRadius="0.5rem"
      border="1px solid rgba(0, 3, 5, 0.92)"
      background="primaryLight"
      color="white"
      boxShadow="0px 0px 3px -1px rgba(0, 0, 0, 0.04), 0px 16px 24px -6px rgba(0, 0, 0, 0.08)"
    >
      <VStack align="start" spacing={4}>
        <Text fontSize="14px" fontWeight="400" width={400}>
          {t('quote:quotation.modals.updateComponent.title', { count: componentsRelatedCount - 1 })}
        </Text>
        <HStack gap={2} alignSelf="flex-end">
          <Button variant="black_ghost" onClick={onConfirmation}>
            {t('quote:quotation.modals.updateComponent.validate')}
          </Button>
          <AutoClickButton onClick={onRejection} duration={6000} variant="secondary_darker">
            {t('quote:quotation.modals.updateComponent.cancel')}
          </AutoClickButton>
        </HStack>
      </VStack>
    </Alert>
  );
};
