import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

import type { INodeRelation, ITree, TreeDataWithRelations } from '../types';
import type { NodeInfos, NodeRelations } from '../types/NodeInfos';
import type {
  Identifiable,
  NodeParentNodeId,
  LeafPreviousLeafId,
  NodePreviousNodeId,
  NodeWithRelations,
  LeafWithRelations,
} from '../types/Identifiable';
import type { LeafInfos } from '../types/LeafInfos';

const THROW_ERROR = () => {
  throw new Error('useTree must be used within a TreeContextProvider');
};

export interface TreeChanges<Node extends Identifiable, Leaf extends Identifiable> {
  nodes: {
    updated: Array<NodeWithRelations<Node>>;

    deleted: Array<Node['id']>;

    created: Array<NodeWithRelations<Node>>;
  };

  leaves: {
    updated: Array<LeafWithRelations<Node, Leaf>>;

    deleted: Array<Leaf['id']>;

    created: Array<LeafWithRelations<Node, Leaf>>;
  };
}

export interface TreeComputedValues<
  Node extends Identifiable,
  Leaf extends Identifiable,
  NodeComputedValue extends Record<keyof NodeComputedValue, unknown>,
  LeafComputedValue extends Record<keyof LeafComputedValue, unknown>,
> {
  nodes: Record<Node['id'], NodeComputedValue>;

  leaves: Record<Leaf['id'], LeafComputedValue>;
}

export interface TreeContextApi<
  Node extends Identifiable,
  Leaf extends Identifiable,
  NodeComputedValue extends Record<keyof NodeComputedValue, unknown>,
  LeafComputedValue extends Record<keyof LeafComputedValue, unknown>,
> {
  getInitialTree(): ITree<Node, Leaf>;

  getCurrentTree(): TreeDataWithRelations<Node, Leaf>;

  getDisplayedCurrentTree(): TreeDataWithRelations<Node, Leaf>;

  getChanges(): TreeChanges<Node, Leaf>;

  getComputedValues(): TreeComputedValues<Node, Leaf, NodeComputedValue, LeafComputedValue>;

  getNodeSignalInfos(nodeId: Node['id']): NodeInfos<Node>;

  getNodeRelationsSignal(nodeId: Node['id']): NodeRelations<Node, Leaf>;

  getLeafSignalInfos(leafId: Leaf['id']): LeafInfos<Node, Leaf>;

  emitNodeUpdate(nodeId: Node['id']): void;

  listenToNode(nodeId: Node['id'], listener: (leafInfos: NodeInfos<Node>) => void): () => void;

  listenToNodeComputedValue(nodeId: Node['id'], listener: Dispatch<SetStateAction<NodeComputedValue>>): void;

  listenToNodeRelations(nodeId: Node['id'], listener: Dispatch<SetStateAction<NodeRelations<Node, Leaf>>>): () => void;

  emitNodeRelations(nodeId: Node['id']): void;

  emitLeafUpdate(leafId: Leaf['id']): void;

  listenToLeaf(leafId: Leaf['id'], listener: (leafInfos: LeafInfos<Node, Leaf>) => void): () => void;

  listenToLeafComputedValue(leafId: Leaf['id'], listener: Dispatch<SetStateAction<LeafComputedValue>>): void;

  updateNodeData(nodeId: Node['id'], updatesToSave: Partial<Node>): void;

  updateAllChildrenLeavesOfNode(nodeId: Node['id'], updatesToSave: Partial<Leaf>): void;

  deleteNode(nodeId: Node['id']): void;

  updateLeafData(leafId: Leaf['id'], updatesToSave: Partial<Leaf>): void;

  deleteLeaf(leafId: Leaf['id']): void;

  restoreLeaf(leafId: Leaf['id']): void;

  restoreNode(nodeId: Node['id']): void;

  switchNodeIsExpanded(nodeId: Node['id']): void;

  createLeaf(parentNodeId: Node['id'], previousLeafId: LeafPreviousLeafId, leafData: Partial<Omit<Leaf, 'id'>>): string;

  createNode(
    parentNodeId: NodeParentNodeId,
    previousNodeId: NodePreviousNodeId,
    nodeData: Partial<Omit<Node, 'id'>>,
  ): string;

  moveLeaf(
    leafId: Leaf['id'],
    parentNodeId: Node['id'],
    previousLeafId: LeafPreviousLeafId,
    placement: 'before' | 'after',
  ): void;

  moveNode(
    nodeId: Node['id'],
    parentNodeId: Node['id'],
    previousNodeId: NodePreviousNodeId,
    placement: 'before' | 'after',
  ): void;

  duplicateLeaf(leafId: Leaf['id'], propertiesToOverwrite?: Partial<Leaf>): string;

  duplicateNode(
    nodeId: Node['id'],
    propertiesToOverwrite?: {
      node?: Partial<Node>;
      leaf?: Partial<Leaf>;
    },
  ): string;

  isNodeDescendingChildrenOfNode(nodeToSearch: Node['id'], nodeId: Node['id']): boolean;

  isLeafAfterLeaf(leafId: LeafWithRelations<Node, Leaf>, comparedLeaf: LeafWithRelations<Node, Leaf>): boolean;

  getLastLeafOfNode(nodeId: Node['id']): Leaf['id'] | null;

  isNodeAfterNode(node: Node['id'], comparedNode: Node['id']): boolean;

  getLastNodeOfNode(nodeId: Node['id']): Node['id'] | null;

  isNodeDeleted(nodeId: Node['id']): boolean;

  isNodeCreated(nodeId: Node['id']): boolean;

  canNodeBeRestored(nodeId: Node['id']): boolean;

  getUpdatedKeysOfNode(nodeId: Node['id']): Array<keyof Node>;

  isLeafDeleted(leafId: Leaf['id']): boolean;

  isLeafCreated(leafId: Leaf['id']): boolean;

  canLeafBeRestored(leafId: Leaf['id']): boolean;

  getUpdatedKeysOfLeaf(leafId: Leaf['id']): Array<keyof Leaf>;

  findDescendingChildrenOfNode(nodeId: Node['id']): INodeRelation<Node, Leaf>;

  findSubNodesOrderByDepthDesc(nodeId: Node['id']): Node['id'][];

  findNodePathFromNodeToRootNode(nodeId: Node['id']): Node['id'][];

  findNodePosition(
    nodeId: Node['id'],
    options: { removeDeletedEntities: boolean },
  ): {
    parentNodeId: Node['id'] | null;
    previousNodeId: Node['id'] | null;
    index: number;
  };

  findLeafPosition(
    leafId: Leaf['id'],
    options: { removeDeletedEntities: boolean },
  ): {
    parentNodeId: Node['id'];
    previousLeafId: Leaf['id'] | null;
    index: number;
  };

  toggleAllNodes(): void;
}

export const TreeContext = createContext<TreeContextApi<any, any, any, any>>({
  getInitialTree: THROW_ERROR,
  getCurrentTree: THROW_ERROR,
  getDisplayedCurrentTree: THROW_ERROR,
  getChanges: THROW_ERROR,
  getComputedValues: THROW_ERROR,
  getNodeSignalInfos: THROW_ERROR,
  getNodeRelationsSignal: THROW_ERROR,
  getLeafSignalInfos: THROW_ERROR,
  emitNodeUpdate: THROW_ERROR,
  listenToNode: THROW_ERROR,
  listenToNodeComputedValue: THROW_ERROR,
  listenToNodeRelations: THROW_ERROR,
  emitNodeRelations: THROW_ERROR,
  emitLeafUpdate: THROW_ERROR,
  listenToLeaf: THROW_ERROR,
  listenToLeafComputedValue: THROW_ERROR,
  updateNodeData: THROW_ERROR,
  updateAllChildrenLeavesOfNode: THROW_ERROR,
  deleteNode: THROW_ERROR,
  updateLeafData: THROW_ERROR,
  deleteLeaf: THROW_ERROR,
  restoreLeaf: THROW_ERROR,
  restoreNode: THROW_ERROR,
  switchNodeIsExpanded: THROW_ERROR,
  createLeaf: THROW_ERROR,
  createNode: THROW_ERROR,
  moveLeaf: THROW_ERROR,
  moveNode: THROW_ERROR,
  duplicateLeaf: THROW_ERROR,
  duplicateNode: THROW_ERROR,
  isNodeDescendingChildrenOfNode: THROW_ERROR,
  isLeafAfterLeaf: THROW_ERROR,
  getLastLeafOfNode: THROW_ERROR,
  isNodeAfterNode: THROW_ERROR,
  getLastNodeOfNode: THROW_ERROR,
  isNodeDeleted: THROW_ERROR,
  isNodeCreated: THROW_ERROR,
  canNodeBeRestored: THROW_ERROR,
  getUpdatedKeysOfNode: THROW_ERROR,
  isLeafDeleted: THROW_ERROR,
  isLeafCreated: THROW_ERROR,
  canLeafBeRestored: THROW_ERROR,
  getUpdatedKeysOfLeaf: THROW_ERROR,
  findDescendingChildrenOfNode: THROW_ERROR,
  findSubNodesOrderByDepthDesc: THROW_ERROR,
  findNodePathFromNodeToRootNode: THROW_ERROR,
  findNodePosition: THROW_ERROR,
  findLeafPosition: THROW_ERROR,
  toggleAllNodes: THROW_ERROR,
});

export const useTreeContext = <
  Node extends Identifiable,
  Leaf extends Identifiable,
  NodeComputedValue extends Record<keyof NodeComputedValue, unknown> = never,
  LeafComputedValue extends Record<keyof LeafComputedValue, unknown> = never,
>() => useContext<TreeContextApi<Node, Leaf, NodeComputedValue, LeafComputedValue>>(TreeContext);
