import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';

interface FinalStatementQuitModalProps {
  isOpen: boolean;
  onClose(): void;
  onQuit(): void;
}

export const FinalStatementQuitModal: FC<FinalStatementQuitModalProps> = ({ isOpen, onClose, onQuit }) => {
  const { t } = useTranslation(['project', 'global']);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('project:finalStatement.quitModal.title')}>
      {t('project:finalStatement.quitModal.description')}

      <Modal.SecondaryButton onClick={onClose}>{t('global:words.c.cancel')}</Modal.SecondaryButton>

      <Modal.PrimaryButton onClick={onQuit}>{t('project:finalStatement.quitModal.quit')}</Modal.PrimaryButton>
    </Modal>
  );
};
