import { MenuItem } from '@chakra-ui/react';
import type { FC, PropsWithChildren } from 'react';

import { useChakraColors } from '../../hooks';

export const RichSelectMenuHeader: FC<PropsWithChildren> = ({ children }) => {
  const colors = useChakraColors({ gray150: 'gray.150' });

  return (
    <MenuItem
      _hover={{ color: undefined }}
      _focus={{ color: undefined }}
      _active={{ color: undefined }}
      fontSize="sm"
      textColor="gray.700"
      closeOnSelect={false}
      isFocusable={false}
      cursor="default"
      _notFirst={{ borderTop: `1px solid ${colors.gray150}` }}
      pb="3px"
    >
      {children}
    </MenuItem>
  );
};
