import { CheckboxField, DataTable, EmailAutocompleteField, Modal } from '@graneet/lib-ui';
import type { EmailAutocompleteContact } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { buildFullName, type IContact, type IReminderBulkSendDTO } from '@graneet/business-logic';

import {
  useContactAssociatedToProject,
  useContactsAssociateToProject,
  useContactsWithoutPagination,
} from 'features/contact/services/contact.api';
import { Rule } from 'features/form/rules/Rule';
import { useGetContactsAvailableForMailing } from 'features/mailing/hooks/useGetContactsAvailableForMailing';

type ProjectContactFieldName = `contacts-${number}`;
function buildProjectContact(projectId: number): ProjectContactFieldName {
  return `contacts-${projectId}`;
}

function getProjectContact(fieldName: ProjectContactFieldName): number {
  const projectIdStringLike = fieldName.split('-')[1];

  return parseInt(projectIdStringLike, 10);
}

interface FormValues {
  [projectContacts: ProjectContactFieldName]: EmailAutocompleteContact[];

  isDefaultEmailRecipient: boolean;
}

const Row: FC<{ projectId: number; companyContacts: IContact[] }> = ({ projectId, companyContacts }) => {
  const projectContacts = useContactAssociatedToProject(projectId);
  const groupedContacts = useGetContactsAvailableForMailing(projectContacts.data, companyContacts ?? [], {});

  return (
    <Flex w="100%">
      <Box w="100%">
        <Skeleton w="100%" h="2rem" />
      </Box>

      <Box w="100%">
        <EmailAutocompleteField<FormValues>
          name={buildProjectContact(projectId)}
          groupedContacts={groupedContacts}
          selectProps={{
            size: 'sm',
          }}
          canAddEntry={false}
        >
          <Rule.IsNotEmptyArray />
        </EmailAutocompleteField>
      </Box>
    </Flex>
  );
};

interface BillRemindBatchModalProps {
  isOpen: boolean;

  onClose: () => void;

  onSubmit: (defaultEmailRecipientsOverride: IReminderBulkSendDTO['defaultEmailRecipientsOverride']) => void;

  projects: Array<{ id: number; refCode: string | null; name: string }>;
}

export const BillRemindBatchModal: FC<BillRemindBatchModalProps> = ({ isOpen, onClose, onSubmit, projects }) => {
  const { t } = useTranslation(['bill', 'global']);

  const form = useForm<FormValues>();
  const { isValid } = useFormStatus<FormValues>(form);

  const companyContacts = useContactsWithoutPagination();

  const contactsAssociateToProjectMutation = useContactsAssociateToProject();

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = useCallback(async () => {
    // Update projects with wrong issues
    const { isDefaultEmailRecipient, ...projectContacts } = form.getFormValues();

    setIsLoading(true);
    const projectToUpdate = (Object.keys(projectContacts) as Array<ProjectContactFieldName>).map((project) => {
      const values = projectContacts[project] ?? [];

      return {
        id: getProjectContact(project),
        dto: {
          contacts: values.map((value) => ({
            id: value.id,
            isDefaultEmailRecipient: isDefaultEmailRecipient ?? false,
          })),
        },
      };
    });

    await Promise.all(projectToUpdate.map((data) => contactsAssociateToProjectMutation.mutateAsync(data)));
    setIsLoading(false);

    onSubmit(
      Object.entries(projectContacts).map(([projectFieldName, contact]) => ({
        projectId: getProjectContact(projectFieldName as ProjectContactFieldName),
        fullName: buildFullName(contact![0].firstName, contact![0].lastName),
        email: contact![0].email,
      })),
    );
  }, [contactsAssociateToProjectMutation, form, onSubmit]);

  return (
    <Modal title={t('bill:remindBatchModal.title')} size="3xl" isOpen={isOpen} onClose={onClose}>
      <Text>{t('bill:remindBatchModal.description')}</Text>

      <Form form={form}>
        <Flex direction="column" py={3} gap={4}>
          <DataTable numberOfColumns={1}>
            {projects.map((project) => (
              <DataTable.Row
                key={project.id}
                label={
                  <Flex gap={4}>
                    <Text color="gray.600">{project.refCode}</Text>
                    <Text>{project.name}</Text>
                  </Flex>
                }
              >
                <DataTable.Cell>
                  <Row projectId={project.id} companyContacts={companyContacts.data.data} />
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>

          <Box>
            <CheckboxField<FormValues>
              label={t('bill:remindBatchModal.isDefaultEmailRecipient')}
              name="isDefaultEmailRecipient"
            >
              <Rule.IsRequired />
            </CheckboxField>
            <Text color="gray.600">{t('bill:remindBatchModal.isDefaultEmailRecipientDescription')}</Text>
          </Box>
        </Flex>
      </Form>

      <Modal.CloseButton isDisabled={isLoading} />
      <Modal.PrimaryButton isLoading={isLoading} isDisabled={!isValid} onClick={handleSubmit}>
        {t('bill:remindBatchModal.cta')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
