import { useState, useCallback, useEffect } from 'react';
import { SavingSpinner } from '@graneet/lib-ui';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const QuoteUpdateSpinner = () => {
  const [isSaving, setIsSaving] = useState(false);

  const { onQuoteSavingChange } = useQuoteEditContext();
  const handleQuoteSavingChange = useCallback(
    (isSavingIn: boolean) => {
      if (isSavingIn !== isSaving) {
        setIsSaving(isSavingIn);
      }
    },
    [isSaving],
  );

  useEffect(() => onQuoteSavingChange(handleQuoteSavingChange), [onQuoteSavingChange, handleQuoteSavingChange]);

  return <SavingSpinner isSaving={isSaving} />;
};
