import type { STATEMENT_TYPES } from '../statement/statement.type';

export const EXPORT_ENTITY = {
  SUPPLIER_INVOICE: 'SUPPLIER_INVOICE',
  SUPPLIER_INVOICE_PAYMENT: 'SUPPLIER_INVOICE_PAYMENT',
  STATEMENT: 'STATEMENT',
  BILL: 'BILL',
  PROJECT: 'PROJECT',
  // @[ff: project-financial-summary]
  PROJECT_OLD: 'PROJECT_OLD',
  ORDER: 'ORDER',
  QUOTE: 'QUOTE',
} as const;

export type ExportEntity = keyof typeof EXPORT_ENTITY;

export type ExportData = {
  SUPPLIER_INVOICE: { id: number };

  SUPPLIER_INVOICE_PAYMENT: { id: number };

  STATEMENT: { id: number; type: STATEMENT_TYPES };

  BILL: { id: number };

  PROJECT: { id: number };
  // @[ff: project-financial-summary]
  PROJECT_OLD: { id: number };

  ORDER: { id: number };

  QUOTE: { id: number | string };
};
