import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  quoteClientRequestsSetToStore,
  StatusEnum,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteCustomDiscountDelete() {
  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const { quoteCustomDiscountsProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    (customDiscountId: string): boolean => {
      try {
        quote.deleteCustomDiscount(customDiscountId);

        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
        quoteCustomDiscountsProxyApi
          .deleteQuoteCustomDiscount({
            quoteCustomDiscountId: customDiscountId,
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        quoteSetToStore(quote);
        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteCustomDiscountsProxyApi, quoteError, quoteSetToStore],
  );
}
