import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Modal } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { IBill } from '@graneet/business-logic';

import { useMarkBillAsLost } from '../../services/bill.api';

interface MarkBillAsLostModalProps {
  bill: IBill;

  modal: UseDisclosureReturn;

  onSuccess: () => void;
}

export const BillMarkAsLostModal: FC<MarkBillAsLostModalProps> = ({ bill, modal, onSuccess }) => {
  const { t } = useTranslation(['global', 'bill']);

  const markBillAsLostMutation = useMarkBillAsLost();

  const onSubmit = useCallback(async () => {
    markBillAsLostMutation.mutate(bill.id, {
      onSuccess: () => {
        onSuccess();
        modal.onClose();
      },
    });
  }, [bill.id, markBillAsLostMutation, modal, onSuccess]);

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      title={t('bill:lostModal.title', { invoiceNumber: bill.invoiceNumber })}
    >
      {t('bill:lostModal.description')}

      <Modal.Close />

      <Modal.PrimaryButton onClick={onSubmit} isLoading={markBillAsLostMutation.isPending} colorScheme="red">
        {t('words.c.validate')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
