export interface EmailAutocompleteTranslations {
  noMoreSuggestions: string;
  addOption: string;
  placeholder: string;
  missingEmail: string;
}

export const emailAutocompleteTranslations: EmailAutocompleteTranslations = {
  noMoreSuggestions: '',
  addOption: '',
  placeholder: '',
  missingEmail: '',
};

export const configureDefaultLabels = (translation: EmailAutocompleteTranslations) => {
  Object.assign(emailAutocompleteTranslations, translation);
};
