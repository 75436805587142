import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import type { DeepTableRowProps, FormulaFieldValue, InputProps } from '@graneet/lib-ui';
import {
  BadgeJob,
  CurrencyField,
  DeepTable,
  DraggableItem,
  DropEffect,
  formatVatRateToNumber,
  FormulaField,
  INPUT_IN_DEEP_TABLE_STYLE,
  isNumberFinite,
  PriceAdvanced,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
  RichTextField,
  SegmentedDropZone,
  TextField,
  useCounterLoaderContext,
  useCurrency,
  useToast,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'graneet-form';
import type { IContractInfosItem, IItemWithUUID } from '@graneet/business-logic';
import { divideFloating } from '@graneet/business-logic';

import { useContractItem, useContractTreeContext } from '../../hooks/tree.hooks';
import { useContractRuleContext } from '../../contexts/ContractRuleContext';
import type { ContractEditionForm, ItemKey } from '../../forms/contract-edition.form';
import { getItemFieldName } from '../../forms/contract-edition.form';
import type { ContractDnDItem } from '../../types/contract-dnd.type';
import { ContractDeepTableCodeRow } from '../ContractDeepTableCodeRow';
import { TreeDataPreCalculation } from '../../../common/services/treeDataPreCalculation/treeDataPreCalculation.util';
import { useSendClickRoundedQuantityButtonEvent } from '../../../analytic/hooks/useSendClickRoundedQuantityButtonEvent';

import { ContractItemNote } from './ContractItemNote';
import { ContractItemAmounts } from './ContractItemAmounts';
import { ContractItemVATRate } from './ContractItemVATRate';
import { ContractItemActions } from './ContractItemActions';

import { useStore } from 'store/store';
import { Rule } from 'features/form/rules/Rule';
import { getDeepTableOffset, isItemComplete } from 'features/contract/services/contract.util';
import { STYLE_CONTRACT_ITEM, STYLE_CONTRACT_ITEM_INPUT } from 'features/contract/constants/styles';
import { generateFormValuesOfItem } from 'features/contract/services/contract-input.util';
import { CONTRACT_ENTITY_TYPES } from 'features/contract/constants/contracts.constant';

interface ContractDeepTableItemLineProps {
  id: string | number;
  depth: number;
}

export const ContractDeepTableItemLine = memo<ContractDeepTableItemLineProps>(({ id, depth }) => {
  const { mapNumberToAmount, mapAmountToNumber, currency } = useCurrency();

  const toast = useToast();
  const { t } = useTranslation(['quote', 'contracts']);

  const [hasInputFocused, setHasInputFocused] = useState(false);

  const {
    leaf: item,
    state: { isDeleted, updatedKeys, isCreated: isItemCreated },
  } = useContractItem(id);
  const { updateLeafData, getCurrentTree, moveLeaf, isLeafAfterLeaf } = useContractTreeContext();
  const { canItemBeUpdated } = useContractRuleContext();

  const form = useFormContext<ContractEditionForm>();
  const isLineFilled = useMemo(() => isItemComplete(item, false), [item]);

  const handleUpdateNote = useCallback(
    (note: string | null | undefined) => () => {
      updateLeafData(id, { note });
      setHasInputFocused(false);
    },
    [id, updateLeafData],
  );

  const handleFocus = useCallback(() => {
    setHasInputFocused(true);
  }, []);

  const handleBlur = useCallback(
    (key: ItemKey) => () => {
      setHasInputFocused(false);
      const itemWithPreviousState = getCurrentTree().leaves[id];
      const unitPriceFieldKey = getItemFieldName(id, 'unitPrice');
      const quantityFieldKey = getItemFieldName(id, 'quantity');
      const vatRateFieldKey = getItemFieldName(id, 'vatRate');
      const {
        [unitPriceFieldKey]: unitPriceFromForm,
        [quantityFieldKey]: quantityFromForm,
        [vatRateFieldKey]: vatRateFromForm,
      } = form.getFormValues();

      const partialItem: Pick<IItemWithUUID, 'id' | 'vatRate' | 'quantity' | 'quantityFormula' | 'unitPrice'> = {
        id,
        quantity: 0,
        quantityFormula: null,
        vatRate: vatRateFromForm ? formatVatRateToNumber(vatRateFromForm) : 0,
        unitPrice: mapAmountToNumber(unitPriceFromForm || 0),
      };

      partialItem.quantityFormula = (quantityFromForm as FormulaFieldValue)?.content ?? null;
      partialItem.quantity = (quantityFromForm as FormulaFieldValue)?.value ?? 0;

      const throwOnInvalidTotalExVAT = () => {
        const { canUpdate, errorMessage } = canItemBeUpdated(itemWithPreviousState as IContractInfosItem, partialItem);
        if (!canUpdate) {
          throw new Error(errorMessage);
        }
      };

      const throwOnInvalidVatRate = () => {
        if (itemWithPreviousState.invoicedAmountExVAT) {
          throw new Error(t('contracts:toasts.cannotUpdateVatRate'));
        }
      };

      try {
        const fieldName = getItemFieldName(id, key);

        let newValue = form.getFormValues()[fieldName];
        if (key === 'unitPrice') {
          newValue = isNumberFinite(newValue) ? mapAmountToNumber(newValue) : undefined;
        }
        if (key === 'vatRate') {
          newValue = isNumberFinite(newValue) ? divideFloating(newValue, 100) : undefined;
        }

        // If value is not changed, early return
        if (itemWithPreviousState[key] === newValue) {
          return;
        }

        if (['unitPrice', 'quantity', 'vatRate'].includes(key)) {
          throwOnInvalidTotalExVAT();
        }
        if (key === 'vatRate') {
          throwOnInvalidVatRate();
        }

        if (key === 'quantity') {
          updateLeafData(id, {
            quantity: partialItem.quantity,
            quantityFormula: partialItem.quantityFormula,
          });
          return;
        }

        updateLeafData(id, {
          [key]: newValue,
        });
      } catch (error) {
        toast.error((error as Error).message);

        const { unitPrice, quantity, quantityFormula, vatRate } = itemWithPreviousState;

        const formValues: Partial<ContractEditionForm> = {};

        formValues[quantityFieldKey] = {
          value: quantity,
          content: quantityFormula,
        };

        formValues[unitPriceFieldKey] = mapNumberToAmount(unitPrice);
        formValues[vatRateFieldKey] = vatRate || 0;

        form.setFormValues(formValues);
      }
    },
    [canItemBeUpdated, form, getCurrentTree, id, mapAmountToNumber, mapNumberToAmount, t, toast, updateLeafData],
  );

  const itemRowStyle = useMemo<DeepTableRowProps>(() => {
    const styleBaseOnState: DeepTableRowProps = {};
    if (!isLineFilled) {
      styleBaseOnState.bg = 'yellow.100';
    } else if (updatedKeys.length > 0 || isItemCreated) {
      styleBaseOnState.bg = 'blue.50';
    }

    return isDeleted ? STYLE_CONTRACT_ITEM.IS_DELETED : { ...STYLE_CONTRACT_ITEM.DEFAULT, ...styleBaseOnState };
  }, [isDeleted, isItemCreated, isLineFilled, updatedKeys]);

  const inputStyle = useCallback(
    (key: ItemKey, textAlign: 'right' | 'left' = 'left'): InputProps => {
      const hasFieldBeenUpdated = updatedKeys.includes(key) || isItemCreated;

      const itemTextStyle = isDeleted ? STYLE_CONTRACT_ITEM_INPUT.IS_DELETED : STYLE_CONTRACT_ITEM_INPUT.DEFAULT;

      return {
        ...itemTextStyle,
        ...(hasFieldBeenUpdated && STYLE_CONTRACT_ITEM_INPUT.IS_UPDATED),
        ...INPUT_IN_DEEP_TABLE_STYLE(hasInputFocused),
        textAlign,
      };
    },
    [updatedKeys, isItemCreated, isDeleted, hasInputFocused],
  );

  const handleStyle = useMemo(() => {
    if (isDeleted) {
      return {
        color: 'gray.200',
      };
    }
    if (!isLineFilled) {
      return {
        _hover: { color: 'yellow.300' },
        color: 'yellow.400',
      };
    }
    if (updatedKeys.length > 0 || isItemCreated) {
      return {
        _hover: { color: 'blue.300' },
        color: 'blue.400',
      };
    }
    return {
      _hover: { color: 'gray.500' },
      color: 'gray.200',
    };
  }, [isDeleted, isItemCreated, isLineFilled, updatedKeys]);

  useEffect(() => {
    if (item) {
      form.setFormValues(generateFormValuesOfItem(item, currency));
    }
  }, [currency, form, item]);

  const dndItem = useMemo<ContractDnDItem>(() => ({ id, parentNodeId: item?.parentNodeId }), [id, item?.parentNodeId]);

  const canDropOnItemTop = useCallback(
    (itemDrag: ContractDnDItem) => !isLeafAfterLeaf(itemDrag as any, item),
    [isLeafAfterLeaf, item],
  );

  const canDropOnItemBottom = useCallback(
    (itemDrag: ContractDnDItem) => !isLeafAfterLeaf(item, itemDrag as any),
    [isLeafAfterLeaf, item],
  );

  const onItemDropBefore = useCallback(
    (newPreviousItem: ContractDnDItem) => {
      moveLeaf(newPreviousItem.id, item.parentNodeId, item.id, 'before');
    },
    [item?.id, item?.parentNodeId, moveLeaf],
  );

  const onItemDropAfter = useCallback(
    (newNextItem: ContractDnDItem) => {
      moveLeaf(newNextItem.id, item.parentNodeId, item.id, 'after');
    },
    [item?.id, item?.parentNodeId, moveLeaf],
  );

  const { addLineRendered } = useCounterLoaderContext();

  const autoNumberingSetTable = useStore((state) => state.autoNumberingSetTable);
  const optionalLotsSetTable = useStore((state) => state.optionalLotsSetTable);
  const optionalLotsSetTableWithoutJob = useStore((state) => state.optionalLotsSetTableWithoutJob);
  const contractDepthSetMaxDepth = useStore((state) => state.contractDepthSetMaxDepth);

  useEffect(() => {
    const currentTree = getCurrentTree();

    const treeDataPreCalculation = new TreeDataPreCalculation(currentTree.relations, currentTree.leaves);
    autoNumberingSetTable(treeDataPreCalculation.autoNumberingTable);
    optionalLotsSetTable(treeDataPreCalculation.optionalLotsTable);
    optionalLotsSetTableWithoutJob(treeDataPreCalculation.optionalLotsWithoutJobInTheseChildren);
    contractDepthSetMaxDepth(treeDataPreCalculation.maxDepth);
  }, [
    getCurrentTree,
    item,
    autoNumberingSetTable,
    optionalLotsSetTable,
    optionalLotsSetTableWithoutJob,
    contractDepthSetMaxDepth,
  ]);

  useEffect(() => {
    addLineRendered(`ITEM_${id}`);
  }, [addLineRendered, id]);

  const sendClickRoundEvent = useSendClickRoundedQuantityButtonEvent();

  return (
    <Box ml={depth === 1 ? 0 : 2}>
      <DraggableItem type={CONTRACT_ENTITY_TYPES.ITEM} item={dndItem} disabled={isDeleted} position="relative">
        {item.isOptional && (
          <BadgeJob backgroundColor="purple.100" textColor="purple.700" label={t('quote:option.title')} />
        )}

        <SegmentedDropZone id={id} accept={CONTRACT_ENTITY_TYPES.ITEM}>
          <SegmentedDropZone.Segment
            weight={1}
            effect={DropEffect.CursorTop}
            canDrop={canDropOnItemTop}
            onDrop={onItemDropBefore}
          />

          <SegmentedDropZone.Segment
            weight={1}
            effect={DropEffect.CursorBottom}
            canDrop={canDropOnItemBottom}
            onDrop={onItemDropAfter}
          />

          <DeepTable.Row
            offset={getDeepTableOffset(depth)}
            leftContent={
              <DraggableItem.Handle marginTop={item.isOptional ? 3 : 0} {...handleStyle} disabled={isDeleted} />
            }
            {...itemRowStyle}
          >
            <DeepTable.Cell>
              <ContractDeepTableCodeRow
                id={id}
                type="job"
                name={getItemFieldName(id, 'code')}
                input={inputStyle('code')}
                onFocus={handleFocus}
                onBlur={handleBlur('code')}
                isDisabled={isDeleted}
              />
            </DeepTable.Cell>

            <DeepTable.Cell fontSize="md">
              <RichTextField<ContractEditionForm>
                name={getItemFieldName(id, 'description')}
                navbarType="inline"
                inputProps={inputStyle('description')}
                onFocus={handleFocus}
                onBlur={handleBlur('description')}
                hideErrorMessage
                isDisabled={isDeleted}
                configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
              >
                <Rule.IsRequired />
              </RichTextField>
            </DeepTable.Cell>

            <DeepTable.Cell right>
              <TextField<ContractEditionForm>
                name={getItemFieldName(id, 'unit')}
                inputProps={inputStyle('unit', 'right')}
                onFocus={handleFocus}
                onBlur={handleBlur('unit')}
                isDisabled={isDeleted}
                noBorder
                hideErrorMessage
              >
                <Rule.IsRequired />
              </TextField>
            </DeepTable.Cell>

            <DeepTable.Cell right>
              <FormulaField<ContractEditionForm>
                name={getItemFieldName(id, 'quantity') as never}
                inputProps={inputStyle('quantity', 'right')}
                onFocus={handleFocus}
                onBlur={handleBlur('quantity')}
                isDisabled={isDeleted}
                hideErrorMessage
                noBorder
                sendClickRoundEvent={sendClickRoundEvent}
              >
                <Rule.IsRequired />
              </FormulaField>
            </DeepTable.Cell>

            <DeepTable.Cell right>
              <CurrencyField<ContractEditionForm>
                name={getItemFieldName(id, 'unitPrice')}
                inputProps={inputStyle('unitPrice', 'right')}
                onFocus={handleFocus}
                onBlur={handleBlur('unitPrice')}
                isDisabled={isDeleted}
                noBorder
                hideErrorMessage
              >
                <Rule.IsRequired />
              </CurrencyField>
            </DeepTable.Cell>

            <ContractItemVATRate
              id={id}
              inputStyle={inputStyle}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              isDeleted={isDeleted}
            />

            <DeepTable.Cell right>
              <PriceAdvanced amount={item.invoicedAmountExVAT} fontSize="md" color="gray.500" />
            </DeepTable.Cell>

            <ContractItemAmounts id={id} />

            <DeepTable.Cell>
              <ContractItemActions itemId={id} onUpdateNote={handleUpdateNote} />
            </DeepTable.Cell>
          </DeepTable.Row>

          <ContractItemNote
            id={id}
            depth={depth}
            onUpdateNote={handleUpdateNote}
            hasInputFocused={hasInputFocused}
            onFocus={handleFocus}
            isLineFilled={isLineFilled}
          />
        </SegmentedDropZone>
      </DraggableItem>
    </Box>
  );
});
