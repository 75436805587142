/* eslint-disable react/no-array-index-key */
import { Fragment, cloneElement, useCallback, useMemo, useRef } from 'react';
import type { ChangeEvent, FC, ReactElement } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { HStack, VStack, Text, Flex } from '@chakra-ui/react';
import { v4 as uuid } from 'uuid';

import { GraneetButton } from '../../Button/Button';
import type { MaskedInputHandle } from '../../Input/MaskedInput';
import { QuotationMaskedInput } from '../../Input/MaskedInput';
import { useInputMaskInfos } from '../../../Input/MaskedInput/useInputMaskInfos';
import { QuotationInput } from '../../Input/Input';
import { MultiLevelMenu, type MenuElement } from '../../Menu/MultiLevelMenu';
import { SimpleDotsIcon } from '../../../Icons/v2/SimpleDotsIcon';
import { QuotationCallout as Callout } from '../../Callout/Callout';

import { CardDivider, SellsheetCard } from './Card';
import { configureDefaultLabels } from './translations';

export enum CardLineEnum {
  ITEM = 'item',
  CTA = 'cta',
  CALLOUT = 'callout',
  LINK = 'link',
}

export enum MaskEnum {
  DECIMAL = 'decimal',
  PERCENT = 'percentage',
  UNIT = 'unit',
  NUMBER = 'number',
  CURRENCY = 'currency',
}

type Base = {
  type: CardLineEnum;
  actions?: MenuElement[];
};

type BaseItem = {
  type: CardLineEnum.ITEM;
  name: string;
  label: string;
  value: string;
  defaultValue?: string;
  isBold?: boolean;
  isDisabled?: boolean;
  isEditable?: boolean;
  mask?: MaskEnum;
  maskOptions?: Record<string, string | number>;
  icon?: ReactElement<IconProps>;
} & Base;

type EditableItem = {
  onBlur: (v: string, name: string) => void;
} & BaseItem;

type ReadonlyItem = {
  onBlur: undefined;
} & BaseItem;

type CTA = {
  type: CardLineEnum.CTA;
  label: string;
  onClick: () => void;
} & Base;

type Link = {
  type: CardLineEnum.LINK;
  label: string;
  onClick?: () => void;
  icon?: ReactElement<IconProps>;
} & Base;

type CalloutLineProps = {
  type: CardLineEnum.CALLOUT;
  label: string;
  value: string;
  color: 'green' | 'red' | 'blue' | 'purple';
} & Base;

type Group = {
  items: (EditableItem | ReadonlyItem | CTA | CalloutLineProps | Link)[];
};

type GenericCardProps = {
  groups: Group[];
  title: string;
};

const COLORS_CONFIG = {
  green: {
    textColor: 'green.900',
    boldTextColor: 'green.600',
    background: 'green.100',
  },
  red: {
    textColor: 'red.900',
    boldTextColor: 'red.600',
    background: 'red.100',
  },
  blue: {
    textColor: 'blue.900',
    boldTextColor: 'blue.600',
    background: 'blue.100',
  },
  purple: {
    textColor: 'purple.900',
    boldTextColor: 'purple.600',
    background: 'purple.100',
  },
} as const;

export type LineProps = (ReadonlyItem | EditableItem) & { hasActions: boolean };

const Line: FC<LineProps> = ({
  name,
  label,
  value,
  defaultValue,
  actions = [],
  hasActions,
  onBlur,
  isEditable,
  isDisabled,
  isBold = false,
  icon,
  mask,
  maskOptions,
}) => {
  const { placeholder, mask: imask } = useInputMaskInfos(mask ?? MaskEnum.DECIMAL, maskOptions ?? {});

  const ref = useRef<MaskedInputHandle>(null);

  const handleOnBlur = useCallback(
    (v: string | ChangeEvent<HTMLInputElement>) => {
      const parsedValue = !mask
        ? (v as ChangeEvent<HTMLInputElement>).target.value
        : (ref.current?.getUnmaskedValue() as string);
      onBlur?.(parsedValue, name);
    },
    [name, mask, onBlur],
  );

  const iconElement = icon
    ? cloneElement(icon, {
        boxSize: '0.7rem',
        stroke: '#6C737F',
      })
    : undefined;

  return (
    <HStack width="100%" justifyContent="space-between" gap={0}>
      <Text fontWeight={isBold ? 600 : 500} color={isBold ? '#1F2A37' : '#6C737F'} fontSize="0.813rem">
        {label}
      </Text>
      <HStack width="40%" pr={hasActions && actions.length === 0 ? '0.563rem' : undefined} gap={0}>
        {mask ? (
          <QuotationMaskedInput
            mask={imask}
            ref={ref}
            value={value}
            onBlur={handleOnBlur}
            isReadOnly={!isEditable}
            isDisabled={isDisabled}
            placeholder={placeholder}
            isRequired
            textAlign="right"
            icon={iconElement}
            fontWeight={isBold ? 600 : 500}
            color="#1F2A37"
            noStyle={!isEditable}
          />
        ) : (
          <QuotationInput
            defaultValue={defaultValue}
            value={value}
            onBlur={handleOnBlur}
            isReadOnly={!isEditable}
            isDisabled={isDisabled}
            placeholder={placeholder}
            isRequired
            textAlign="right"
            icon={iconElement}
            fontWeight={isBold ? 600 : 500}
            color="#1F2A37"
            noStyle={!isEditable}
          />
        )}
        {actions && actions.length > 0 && (
          <Flex
            w="1.25rem"
            justifySelf="end"
            alignItems="center"
            justifyContent="center"
            mr="-0.625rem"
            _hover={{
              cursor: 'pointer',
              borderRadius: '0.375rem',
              background: 'rgba(0, 21, 63, 0.05)',
            }}
          >
            <MultiLevelMenu items={actions} placement="right" id={uuid()}>
              <Flex w="1.25rem" h="1.25rem" alignItems="center" justifyContent="center">
                <SimpleDotsIcon boxSize="0.8rem" />
              </Flex>
            </MultiLevelMenu>
          </Flex>
        )}
      </HStack>
    </HStack>
  );
};

const CTALine: FC<CTA> = ({ label, onClick }) => (
  <HStack pt="0.25rem" width="100%" pb="0rem">
    <GraneetButton onClick={onClick} width="full">
      {label}
    </GraneetButton>
  </HStack>
);

const CalloutLine: FC<CalloutLineProps> = ({ label, value, color }) => {
  const { textColor, boldTextColor } = COLORS_CONFIG[color];
  return (
    <Callout color={color}>
      <HStack width="100%" alignItems="center" gap={2}>
        <Text fontWeight={500} color={textColor} fontSize="0.75rem">
          {label}
        </Text>
        <Text fontWeight={600} color={boldTextColor} fontSize="0.75rem">
          {value}
        </Text>
      </HStack>
    </Callout>
  );
};

const LinkLine: FC<Link> = ({ label, icon, onClick, actions }) => (
  <HStack pt="0.25rem" pb="0rem">
    {actions && actions.length > 0 ? (
      <MultiLevelMenu items={actions} placement="right" id={uuid()}>
        <GraneetButton onClick={onClick} width="full" leftIcon={icon} variant="ghost">
          {label}
        </GraneetButton>
      </MultiLevelMenu>
    ) : (
      <GraneetButton onClick={onClick} width="full" leftIcon={icon} variant="ghost">
        {label}
      </GraneetButton>
    )}
  </HStack>
);

export const GenericCardContent: FC<GenericCardProps> = ({ groups, title }) => {
  const hasActions = useMemo(() => groups.some((group) => group.items.some((item) => item.actions)), [groups]);
  return (
    <SellsheetCard size="sm" title={title}>
      {groups.map((group, i) => (
        <Fragment key={i}>
          <VStack gap="0.25rem" width="100%" alignItems="flex-start" borderBottomColor="gray.200">
            {group.items.map((item, j) => {
              if (item.type === 'item') {
                return <Line key={`itemLine-${j}`} hasActions={hasActions} {...item} />;
              }
              if (item.type === 'cta') {
                return <CTALine key={`itemLine-${j}`} {...item} />;
              }
              if (item.type === 'callout') {
                return <CalloutLine key={`itemLine-${j}`} {...item} />;
              }
              if (item.type === 'link') {
                return <LinkLine key={`itemLine-${j}`} {...item} />;
              }
              return null;
            })}
          </VStack>
          {i < groups.length - 1 && <CardDivider key={`divider-${i}`} />}
        </Fragment>
      ))}
    </SellsheetCard>
  );
};

export const GenericCard = Object.assign(GenericCardContent, {
  configureDefaultLabels,
});
