import type { FC } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button } from '@graneet/lib-ui';
import type { ICompany, IProject } from '@graneet/business-logic';

import { ExternalMarketingModal } from '../../external/components/ExternalMarketingModal';
import { ExternalPoweredBy } from '../../external/components/ExternalPoweredBy';

interface ProgressStatementExternalInitialModalProps {
  company: Pick<ICompany, 'name'>;

  project: IProject;

  isOpen: boolean;

  onClose: () => void;
}

export const ProgressStatementExternalInitialModal: FC<ProgressStatementExternalInitialModalProps> = ({
  company,
  project,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation(['progressStatement']);

  return (
    <ExternalMarketingModal
      isOpen={isOpen}
      onClose={onClose}
      icon="draft"
      title={t('progressStatement:external.initialModal.title', {
        company: company.name,
        project: project.name,
      })}
    >
      <Text fontSize="sm">{t('progressStatement:external.initialModal.description')}</Text>
      <VStack gap={6}>
        <Button w="50%" onClick={onClose}>
          {t('progressStatement:external.initialModal.view')}
        </Button>

        <ExternalPoweredBy />
      </VStack>
    </ExternalMarketingModal>
  );
};
