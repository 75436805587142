import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@graneet/lib-ui';
import { Text, VStack } from '@chakra-ui/react';

import { AddValidationStepButton } from './AddValidationStepButton';

interface ValidationStepListEmptyStateProps {
  openModal: () => void;
  emptyStateDescription: string;
}

export const ValidationStepListEmptyState: FC<ValidationStepListEmptyStateProps> = ({
  openModal,
  emptyStateDescription,
}) => {
  const { t } = useTranslation(['validationStep']);
  return (
    <Card
      mt={4}
      mb={8}
      h="12rem"
      borderRadius="md"
      variant="light-gray"
      alignItems="center"
      title={t('validationStep:emptyStateTitle')}
    >
      <VStack spacing={4} maxW="20rem">
        <Text textAlign="center">{emptyStateDescription}</Text>
        <AddValidationStepButton openModal={openModal} />
      </VStack>
    </Card>
  );
};
