import { useCallback } from 'react';
import type { FieldRenderProps, FieldValues } from 'graneet-form';
import { Field } from 'graneet-form';

import type { KeysMatching } from '../../../utils';
import type { ColorPickerProps } from '../../ColorPicker/ColorPicker';
import { ColorPicker } from '../../ColorPicker/ColorPicker';

type ColorPickerFieldValue = string | undefined | null;

interface ColorPickerFieldInternalProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, ColorPickerFieldValue> = KeysMatching<T, ColorPickerFieldValue>,
> extends Omit<ColorPickerProps, 'value' | 'onChange'> {
  properties: FieldRenderProps<T, K>;
}

const ColorPickerFieldInternal = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, ColorPickerFieldValue> = KeysMatching<T, ColorPickerFieldValue>,
>({
  properties,
  ...props
}: ColorPickerFieldInternalProps<T, K>) => {
  const { value, onChange, onBlur } = properties;

  const handleOnChange = useCallback(
    (newColor?: T[K]) => {
      onChange(newColor);
      onBlur();
    },
    [onChange, onBlur],
  );

  return <ColorPicker value={value} onChange={handleOnChange} {...props} />;
};

export interface ColorPickerFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, ColorPickerFieldValue> = KeysMatching<T, ColorPickerFieldValue>,
> extends Omit<ColorPickerProps, 'value' | 'onChange' | 'label'> {
  name: K;
  value?: string;
  onChange?: (e: T[K] | undefined) => void;
  label?: string;
}

export const ColorPickerField = <
  T extends FieldValues,
  K extends KeysMatching<T, ColorPickerFieldValue> = KeysMatching<T, ColorPickerFieldValue>,
>({
  name,
  children,
  ...props
}: ColorPickerFieldProps<T, K>) => {
  const render = useCallback(
    (properties: FieldRenderProps<T, K>) => <ColorPickerFieldInternal properties={properties} {...props} />,
    [props],
  );

  return (
    <Field<T, K> name={name} render={render}>
      {children}
    </Field>
  );
};
