import { Box, HStack, SliderMark, Text, VStack } from '@chakra-ui/react';
import { PDF_LOGO_POSITION } from '@graneet/business-logic';
import { SimpleHelpIcon, Tooltip, SegmentedInputGroupField, SliderField } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { PDFSettingsFormValues } from '../constants/pdf-settings.constant';
import { PDF_SETTINGS_FIELDS } from '../constants/pdf-settings.constant';

export const PdfLogoSettings: FC = () => {
  const { t } = useTranslation(['pdfSettings']);
  return (
    <VStack spacing={2.5} align="start">
      <HStack spacing={2} pb={1.5}>
        <Text color="black" fontSize="lg">
          {t('pdfSettings:settings.logoPosition.title')}
        </Text>
        <Tooltip label={t('pdfSettings:settings.logoPosition.tooltip')} placement="right-end">
          <Box>
            <SimpleHelpIcon boxSize={5} />
          </Box>
        </Tooltip>
      </HStack>

      <SegmentedInputGroupField<PDFSettingsFormValues>
        name={PDF_SETTINGS_FIELDS.LOGO_POSITION}
        options={Object.values(PDF_LOGO_POSITION).map((type) => ({
          optionLabel: t(`pdfSettings:settings.logoPosition.options.${type}`),
          optionValue: type,
        }))}
      />

      <Box width="100%" pt={1.5} pb={3.5}>
        <SliderField<PDFSettingsFormValues> name={PDF_SETTINGS_FIELDS.LOGO_SIZE} min={30}>
          <SliderMark value={30} fontSize="sm" mt={2.5}>
            {t('pdfSettings:settings.logoSize.small') as string}
          </SliderMark>
          <SliderMark value={100} fontSize="sm" mt={2.5} ml={-10}>
            {t('pdfSettings:settings.logoSize.big') as string}
          </SliderMark>
        </SliderField>
      </Box>
    </VStack>
  );
};
