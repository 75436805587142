import { DragHandleIcon } from '@chakra-ui/icons';
import type { BoxProps, IconProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { ReactNode, RefObject } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';

export interface DraggableContextApi {
  updateHandleRef(ref: RefObject<HTMLDivElement>): void;
}

const DraggableContext = createContext<DraggableContextApi>({
  updateHandleRef: () => {
    throw new Error('DraggableContext.updateHandleRef(ref) not implemented');
  },
});

export interface DraggableItemProps<Types extends string, Item extends Record<any, unknown>> extends BoxProps {
  type: Types;
  item: Item;
  disabled?: boolean;
  children: ReactNode;
}

export const DraggableItemComponent = <Types extends string, Item extends Record<any, unknown>>({
  type,
  item,
  disabled = false,
  children,
  ...otherProps
}: DraggableItemProps<Types, Item>) => {
  const [handleRef, setHandleRef] = useState<RefObject<HTMLDivElement> | null>(null);

  const [{ isDragging }, drag, preview] = useDrag<Item, unknown, { isDragging: boolean }>(
    () => ({
      type,
      item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => !disabled,
    }),
    [item, disabled],
  );

  const updateHandleRef = useCallback(
    (ref: RefObject<HTMLDivElement>) => {
      if (ref.current !== handleRef) {
        setHandleRef(ref);
      }
    },
    [handleRef],
  );

  const contextValue = useMemo(
    () => ({
      updateHandleRef,
    }),
    [updateHandleRef],
  );

  if (handleRef) {
    drag(handleRef);
  }

  return (
    <DraggableContext.Provider value={contextValue}>
      <Box
        ref={handleRef ? preview : drag}
        opacity={isDragging ? 0.25 : 1}
        // eslint-disable-next-line no-nested-ternary
        cursor={handleRef || disabled ? 'auto' : isDragging ? 'grabbing' : 'grab'}
        {...otherProps}
      >
        {children}
      </Box>
    </DraggableContext.Provider>
  );
};

export interface HandleProps extends IconProps {
  disabled?: boolean;
}

const Handle = ({ disabled = false, ...props }: HandleProps) => {
  const handleRef = useRef<HTMLDivElement>(null);

  const { updateHandleRef } = useContext(DraggableContext);

  useEffect(() => {
    updateHandleRef(handleRef);
  }, [updateHandleRef]);

  return (
    <Box ref={handleRef} cursor={disabled ? 'not-allowed' : 'grab'} opacity={disabled ? 0.4 : 1}>
      <DragHandleIcon boxSize={4} {...props} />
    </Box>
  );
};

export const DraggableItem = Object.assign(DraggableItemComponent, { Handle });
