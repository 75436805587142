import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const DocumentImportIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 64 76" {...props}>
    <svg width="64" height="76" viewBox="0 0 64 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1175_4112)">
        <rect x="12" y="10" width="40" height="56" rx="2" fill="white" />
      </g>
      <rect x="17" y="38" width="30" height="2" rx="1" fill="#CFB5FF" />
      <rect
        x="17"
        y="42"
        width="30"
        height="2"
        rx="1"
        stroke="#AB85FB"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeDasharray="0.5 1"
      />
      <rect
        x="17"
        y="46"
        width="30"
        height="2"
        rx="1"
        stroke="#AB85FB"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeDasharray="0.5 1"
      />
      <rect x="17" y="15" width="9" height="3" rx="1" fill="#CFB5FF" />
      <rect x="32" y="20" width="15" height="6" rx="1" fill="#CFB5FF" />
      <rect
        x="33"
        y="55"
        width="14"
        height="6"
        rx="1"
        stroke="#AB85FB"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeDasharray="0.5 1"
      />
      <rect
        x="17"
        y="55"
        width="14"
        height="6"
        rx="1"
        stroke="#AB85FB"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeDasharray="0.5 1"
      />
      <rect x="17" y="28" width="30" height="6" rx="1" fill="#CFB5FF" />
      <mask id="path-12-inside-1_1175_4112" fill="white">
        <path d="M4 37.5H60V53.5H4V37.5Z" />
      </mask>
      <path d="M4 37.5H60V53.5H4V37.5Z" fill="url(#paint0_linear_1175_4112)" />
      <path d="M4 39.5H60V35.5H4V39.5Z" fill="#6E24F5" mask="url(#path-12-inside-1_1175_4112)" />
      <defs>
        <filter
          id="filter0_d_1175_4112"
          x="2"
          y="0"
          width="60"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1175_4112" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1175_4112" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_1175_4112" x1="32" y1="37.5" x2="32" y2="53.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB85FB" />
          <stop offset="1" stopColor="#AB85FB" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </Icon>
);
