import type { FC, ReactElement, ReactNode } from 'react';
import type { StackProps } from '@chakra-ui/react';
import { Text, VStack } from '@chakra-ui/react';

import { GraneetButton as Button } from '../Button/Button';

export type ButtonPropTypes = {
  onClick: () => void;
  title: ReactNode;
  icon?: ReactElement;
};

export type EmptyStateButtonsProps = StackProps & {
  primaryAction?: ButtonPropTypes;
  secondaryAction?: ButtonPropTypes;
};

export const EmptyStateButtons: FC<EmptyStateButtonsProps> = ({ primaryAction, secondaryAction, ...props }) => (
  <VStack {...props}>
    {primaryAction && (
      <Button variant="primary" leftIcon={primaryAction.icon} onClick={primaryAction.onClick}>
        {primaryAction.title && <Text>{primaryAction.title}</Text>}
      </Button>
    )}
    {secondaryAction && (
      <Button leftIcon={secondaryAction.icon} onClick={secondaryAction.onClick}>
        {secondaryAction.title && <Text>{secondaryAction.title}</Text>}
      </Button>
    )}
  </VStack>
);
