import type { Raw } from '../common/entity.type';
import { multiplyFloating, roundFloating } from '../common/math.util';
import type { ICustomDiscount } from '../custom-discount/custom-discount.type';
import { computeContainerAmountWithCustomDiscounts } from '../custom-discount/custom-discount.util';
import type { IDiscount } from '../discount/discount.type';
import { getDiscountAmountExVAT } from '../discount/discount.util';
import type { IItem } from '../item/item.type';
import type { ILot } from '../lot/lot.type';
import type { IVatBase } from '../vat/vat-base.type';
import type { IVatBases, IVatDiscountedBases } from '../vat/vat-bases.type';
import type { IVatDistribution } from '../vat/vat-distribution.type';
import {
  computeVatDistributionDiscountedWithBasesMethod,
  computeVatDistributionDiscountedWithBasesRoundedMethod,
  computeVatDistributionDiscountedWithSumsMethod,
  getTotalVATAmountFromVATDistribution,
  mergeVATDistribution,
} from '../vat/vat-rates.util';

import type { ContainerAmountsWithDistribution, ContainerFlattenRow, IContainer } from './container.type';
import { CONTAINER_FLATTEN_ROW_TYPE } from './container.type';

export const computeContainerAmountExVAT = (items: Pick<IItem, 'totalExVAT' | 'isOptional'>[]): number =>
  items.reduce((acc, cur) => {
    if (cur.isOptional) return acc;
    return acc + cur.totalExVAT;
  }, 0);

export const computeContainerAmountIncVAT = (items: Pick<IItem, 'totalIncVAT' | 'isOptional'>[]): number =>
  items.reduce((acc, cur) => {
    if (cur.isOptional) return acc;
    return acc + cur.totalIncVAT;
  }, 0);

export const getLotItems = (lot: ILot): IItem[] => [
  ...lot.items,
  ...(lot.subLots || []).reduce<IItem[]>((acc, subLot) => {
    acc.push(...getLotItems(subLot));
    return acc;
  }, []),
];

export const getContainerItems = (container: IContainer): IItem[] => {
  const { items, lots } = container;

  return [
    ...items,
    ...(lots || []).reduce<IItem[]>((acc, lot) => {
      acc.push(...getLotItems(lot));
      return acc;
    }, []),
  ];
};

export const getItemsVATDistributionWithBases = (
  items: Pick<IItem, 'totalExVAT' | 'vatRate' | 'isOptional'>[],
): IVatBases => {
  const components: IVatBase[] = [];
  items.forEach(({ totalExVAT, vatRate, isOptional }) => {
    if (!isOptional)
      components.push({
        amount: roundFloating(multiplyFloating(totalExVAT, vatRate)),
        base: totalExVAT,
        vatRate,
      });
  });

  return mergeVATDistribution(components);
};

const addItem = (item: IItem): ContainerFlattenRow => ({
  type: CONTAINER_FLATTEN_ROW_TYPE.ITEM,
  data: item,
});

const getNoteDTO = (note: string): ContainerFlattenRow => ({
  type: CONTAINER_FLATTEN_ROW_TYPE.NOTE,
  data: {
    note,
  },
});

const addItemsWithNotes = (lotItems: IItem[]): ContainerFlattenRow[] =>
  lotItems.reduce<ContainerFlattenRow[]>((acc, item) => {
    const lines = [addItem(item)];
    if (item.note) {
      lines.push(getNoteDTO(item.note));
    }

    return [...acc, ...lines];
  }, []);

const getLotDTO = (lot: ILot): ContainerFlattenRow[] => {
  const lotDTO: ContainerFlattenRow[] = [
    {
      type: CONTAINER_FLATTEN_ROW_TYPE.LOT,
      data: lot,
    },
  ];

  if (lot.note) {
    return [...lotDTO, getNoteDTO(lot.note), ...addItemsWithNotes(lot.items)];
  }

  return [...lotDTO, ...addItemsWithNotes(lot.items)];
};

export const getFlattenContainerRows = (container: IContainer): ContainerFlattenRow[] => {
  const { lots, items } = container;

  const flattenLotsWithNotes = (subLots: ILot[]): ContainerFlattenRow[] =>
    subLots.reduce<ContainerFlattenRow[]>(
      (acc, lot) => [...acc, ...getLotDTO(lot), ...flattenLotsWithNotes(lot.subLots)],
      [],
    );

  return [...addItemsWithNotes(items), ...flattenLotsWithNotes(lots)];
};

export const computeContainerDiscountedAmountExVAT = (
  totalAmountExVATWithoutDiscount: number,
  discount: Raw<IDiscount> | undefined | null,
): { discountAmountExVAT: number; totalAmountWithDiscountExVAT: number } => {
  const discountAmountExVAT = getDiscountAmountExVAT(discount, totalAmountExVATWithoutDiscount);
  const newTotalAmountWithDiscountExVAT = totalAmountExVATWithoutDiscount - discountAmountExVAT;
  return {
    discountAmountExVAT,
    totalAmountWithDiscountExVAT: newTotalAmountWithDiscountExVAT,
  };
};

export const computeContainerAmountIncVATFromVATDistribution = (
  totalAmountExVAT: number,
  vatDistribution: IVatBases | IVatDiscountedBases | IVatDistribution,
): number => totalAmountExVAT + getTotalVATAmountFromVATDistribution(vatDistribution);

export const computeContainerAmountsUsingBasesMethod = (
  totalAmountExVATWithoutDiscount: number,
  items: Pick<IItem, 'totalExVAT' | 'vatRate' | 'isOptional'>[],
  discount: Raw<IDiscount> | undefined | null,
  hasReversalOfLiability: boolean,
  customDiscounts?: Raw<ICustomDiscount>[],
): ContainerAmountsWithDistribution<IVatBases> => {
  const { totalAmountWithDiscountExVAT, discountAmountExVAT } = computeContainerDiscountedAmountExVAT(
    totalAmountExVATWithoutDiscount,
    discount,
  );

  // We dispatch the discount on the different amounts based on their base
  const vatDistributionWithoutDiscount = getItemsVATDistributionWithBases(items);
  const vatDistribution = computeVatDistributionDiscountedWithBasesMethod(
    vatDistributionWithoutDiscount,
    hasReversalOfLiability,
    discountAmountExVAT,
    customDiscounts || [],
  );

  const totalAmountExVAT = computeContainerAmountWithCustomDiscounts(
    totalAmountWithDiscountExVAT,
    customDiscounts || [],
  );

  return {
    totalAmountExVAT,
    totalAmountWithDiscountExVAT,
    totalAmountIncVAT: computeContainerAmountIncVATFromVATDistribution(totalAmountExVAT, vatDistribution),
    vatDistribution,
  };
};

export const computeContainerAmountsUsingBasesRoundedMethod = (
  totalAmountExVATWithoutDiscount: number,
  items: Pick<IItem, 'totalExVAT' | 'vatRate' | 'isOptional'>[],
  discount: Raw<IDiscount> | undefined | null,
  hasReversalOfLiability: boolean,
  customDiscounts?: Raw<ICustomDiscount>[],
): ContainerAmountsWithDistribution<IVatDiscountedBases> => {
  const { totalAmountWithDiscountExVAT, discountAmountExVAT } = computeContainerDiscountedAmountExVAT(
    totalAmountExVATWithoutDiscount,
    discount,
  );

  // We dispatch the discount on the different amounts based on their base and round their bases
  const vatDistributionWithoutDiscount = getItemsVATDistributionWithBases(items);
  const vatDistribution = computeVatDistributionDiscountedWithBasesRoundedMethod(
    vatDistributionWithoutDiscount,
    hasReversalOfLiability,
    discountAmountExVAT,
    customDiscounts || [],
  );

  const totalAmountExVAT = computeContainerAmountWithCustomDiscounts(
    totalAmountWithDiscountExVAT,
    customDiscounts || [],
  );

  return {
    totalAmountExVAT,
    totalAmountWithDiscountExVAT,
    totalAmountIncVAT: computeContainerAmountIncVATFromVATDistribution(totalAmountExVAT, vatDistribution),
    vatDistribution,
  };
};

export const computeContainerAmountsUsingSumsMethod = (
  totalAmountExVATWithoutDiscount: number,
  items: Pick<IItem, 'totalExVAT' | 'vatRate' | 'isOptional'>[],
  discount: Raw<IDiscount> | undefined | null,
  hasReversalOfLiability: boolean,
  customDiscounts?: Raw<ICustomDiscount>[],
): ContainerAmountsWithDistribution<IVatDistribution> => {
  const { totalAmountWithDiscountExVAT, discountAmountExVAT } = computeContainerDiscountedAmountExVAT(
    totalAmountExVATWithoutDiscount,
    discount,
  );

  const nonOptionalItems = items.filter((item) => !item.isOptional);

  // We apply the discount to each amount, and we derive the VAT amounts from discounted amount
  const vatDistribution = computeVatDistributionDiscountedWithSumsMethod(
    nonOptionalItems,
    hasReversalOfLiability,
    discountAmountExVAT,
    customDiscounts || [],
  );

  const totalAmountExVAT = computeContainerAmountWithCustomDiscounts(
    totalAmountWithDiscountExVAT,
    customDiscounts || [],
  );

  return {
    totalAmountExVAT,
    totalAmountWithDiscountExVAT,
    totalAmountIncVAT: computeContainerAmountIncVATFromVATDistribution(totalAmountExVAT, vatDistribution),
    vatDistribution,
  };
};
