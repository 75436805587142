import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import type { DrawerApi, DrawersStackContextApi } from '@graneet/lib-ui';
import { DifferenceSummary, Modal, DrawersStack } from '@graneet/lib-ui';
import type { Raw, IDiscount } from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { keyBy } from 'lodash-es';

import { useContractLotComputedValue, useContractTreeContext } from '../../../hooks/tree.hooks';
import { useContractRuleContext } from '../../../contexts/ContractRuleContext';
import type { ContractEditionForm } from '../../../forms/contract-edition.form';

import { ContractTotalAmount } from './ContractTotalAmount';
import { ContractDiscountAmountSummaryLine } from './ContractDiscountAmountSummaryLine';
import { ContractCustomDiscountAmountSummaryLine } from './ContractCustomDiscountAmountSummaryLine';

import type { IComputedContractValues } from 'features/contract/types/contract.type';
import { ContractLotVatRateModal } from 'features/contract/components/modals/ContractLotVatRateModal';
import { ContractDiscountDrawer } from 'features/contract/components/modals/ContractDiscountDrawer';

export interface ContractSummaryProps {
  discountDrawer: DrawerApi<string>;

  drawersStack: DrawersStackContextApi<string>;

  computedContractValues: IComputedContractValues;

  currentDiscount: Raw<IDiscount> | undefined;
}

export const ContractSummary: FC<ContractSummaryProps> = ({
  discountDrawer,
  drawersStack,
  currentDiscount,
  computedContractValues,
}) => {
  const { t } = useTranslation(['global', 'contracts', 'customDiscount']);

  const modalGlobalVATRateEdit = useDisclosure();
  const modalRemoveReversalOfLiability = useDisclosure();

  const { getInitialTree } = useContractTreeContext();
  const { canGlobalVATRateBeUpdated, contractInfos } = useContractRuleContext();
  const form = useFormContext<ContractEditionForm>();
  const { hasReversalOfLiability, customDiscounts } = useOnChangeValues(form, [
    'customDiscounts',
    'hasReversalOfLiability',
  ]);

  const initialCustomDiscounts = contractInfos?.contract.customDiscounts;

  const customDiscountsList = useMemo(() => {
    const initialCustomDiscountsMap = new Map((initialCustomDiscounts ?? []).map((element) => [element.id, element]));
    const currentCustomDiscountsMap = new Map((customDiscounts ?? []).map((element) => [element.id, element]));
    // This method will allow to keep the same order all while getting the deleted and updated custom discount from the currentCustomDiscountsMap
    const allCustomDiscountMap = new Map([...initialCustomDiscountsMap, ...currentCustomDiscountsMap]);

    return [...allCustomDiscountMap.values()];
  }, [customDiscounts, initialCustomDiscounts]);

  // Watching root lot computed value to force a rerender every time contract amount is updated
  const lotComputedValue = useContractLotComputedValue(getInitialTree().rootNodeId);

  const {
    totalAmountWithoutDiscountExVAT,
    totalAmountWithDiscountExVAT,
    totalAmountExVAT,
    totalAmountIncVAT,
    vatDistribution,
  } = computedContractValues;

  const totalExVATDifference = totalAmountExVAT.newAmount - totalAmountExVAT.initialAmount;

  const vatDistributionWithInitialValues = useMemo(() => {
    const vatDistributionFromOldVATRates = vatDistribution.initialDistribution.map(({ vatRate, amount }) => ({
      initialAmount: amount,
      newAmount: 0,
      vatRate,
    }));

    const vatDistributionFromNewVATRates = vatDistribution.newDistribution.map(({ vatRate, amount }) => ({
      initialAmount:
        vatDistribution.initialDistribution.find(({ vatRate: vatRateInitial }) => vatRateInitial === vatRate)?.amount ??
        0,
      newAmount: amount,
      vatRate,
    }));

    return Object.values({
      ...keyBy(vatDistributionFromOldVATRates, 'vatRate'),
      ...keyBy(vatDistributionFromNewVATRates, 'vatRate'),
    });
  }, [vatDistribution.initialDistribution, vatDistribution.newDistribution]);

  const vatRateDropdownItems = useMemo(() => {
    const canReversalOfLiabilityBeUpdated = contractInfos?.canReversalOfLiabilityBeUpdated;
    const items = [];

    if (!hasReversalOfLiability && canGlobalVATRateBeUpdated() && !lotComputedValue?.hasInvoicedItems) {
      items.push({
        onClick: modalGlobalVATRateEdit.onOpen,
        label: t('global:applyGlobalVAT'),
      });
    }

    // Reversal of liability things
    if (canReversalOfLiabilityBeUpdated && hasReversalOfLiability) {
      items.push({
        onClick: modalRemoveReversalOfLiability.onOpen,
        label: t('contracts:removeReversalOfLiability'),
      });
    }

    if (canReversalOfLiabilityBeUpdated && !hasReversalOfLiability) {
      items.push({
        onClick: () => form.setFormValues({ hasReversalOfLiability: true }),
        label: t('contracts:addReversalOfLiability'),
      });
    }

    return items;
  }, [
    canGlobalVATRateBeUpdated,
    contractInfos,
    form,
    hasReversalOfLiability,
    lotComputedValue?.hasInvoicedItems,
    modalGlobalVATRateEdit.onOpen,
    modalRemoveReversalOfLiability.onOpen,
    t,
  ]);

  const removeReversalOfLiability = useCallback(() => {
    form.setFormValues({
      hasReversalOfLiability: false,
    });
    modalRemoveReversalOfLiability.onClose();
  }, [form, modalRemoveReversalOfLiability]);

  if (!contractInfos) {
    return null;
  }

  return (
    <>
      <Flex justifyContent="flex-end" alignItems="center">
        <Box mt={4}>
          <DifferenceSummary>
            <ContractDiscountAmountSummaryLine
              currentDiscount={currentDiscount}
              totalAmountWithoutDiscountExVAT={totalAmountWithoutDiscountExVAT}
              discountDrawer={discountDrawer}
            />

            {customDiscountsList && customDiscountsList.length > 0 && (
              <>
                <DifferenceSummary.Item
                  label={t(
                    currentDiscount
                      ? 'contracts:totalAmountWithDiscountExVAT'
                      : 'customDiscount:totalExVATBeforeCustomDiscountLabel',
                  )}
                  initialAmount={totalAmountWithDiscountExVAT.initialAmount}
                  newAmount={totalAmountWithDiscountExVAT.newAmount}
                  important
                />

                {customDiscountsList.map((customDiscount) => (
                  <ContractCustomDiscountAmountSummaryLine
                    customDiscount={customDiscount}
                    totalAmountWithDiscountExVAT={totalAmountWithDiscountExVAT}
                  />
                ))}
              </>
            )}

            <DifferenceSummary.Item
              label={t('contracts:totalAmountExVAT')}
              initialAmount={totalAmountExVAT.initialAmount}
              newAmount={totalAmountExVAT.newAmount}
              important
            />

            <DifferenceSummary.VATDistribution
              initialHasReversalOfLiability={contractInfos.subProject.hasReversalOfLiability}
              newHasReversalOfLiability={hasReversalOfLiability}
              vatDistribution={vatDistributionWithInitialValues}
              withCustomDiscountsLabel={!!hasReversalOfLiability && !!customDiscountsList.length}
              dropdownItems={vatRateDropdownItems}
            />

            <DifferenceSummary.Item
              label={t('contracts:totalAmountIncVAT')}
              initialAmount={totalAmountIncVAT.initialAmount}
              newAmount={totalAmountIncVAT.newAmount}
              important
            />
          </DifferenceSummary>
        </Box>
      </Flex>

      {/* Display total amount exc. VAT to the Wizard footer through Placeholder */}
      <ContractTotalAmount totalAmountExVAT={totalAmountExVAT.newAmount} differenceAmountExVAT={totalExVATDifference} />

      <ContractLotVatRateModal id={contractInfos.rootLotId} type="contract" {...modalGlobalVATRateEdit} />

      <Modal isOpen={modalRemoveReversalOfLiability.isOpen} onClose={modalRemoveReversalOfLiability.onClose}>
        <Text mt={4}>{t('contracts:removeReversalOfLiabilityModal.description')}</Text>

        <Modal.Close />

        <Modal.PrimaryButton onClick={removeReversalOfLiability}>{t('global:words.c.validate')}</Modal.PrimaryButton>
      </Modal>

      <DrawersStack drawersStack={drawersStack}>
        <ContractDiscountDrawer
          totalAmountWithoutDiscountExVAT={totalAmountWithoutDiscountExVAT.newAmount}
          drawer={discountDrawer}
        />
      </DrawersStack>
    </>
  );
};
