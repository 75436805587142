import { HStack } from '@chakra-ui/react';
import { QuotationInput } from '@graneet/lib-ui';
import type { QuoteComponentsGroupByType } from '@org/quotation-lib';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import type { ChangeEvent } from 'react';
import { memo, useCallback, useEffect, useRef } from 'react';

export const TextEditorCell = memo((params: CustomCellEditorProps<QuoteComponentsGroupByType>) => {
  const { value, onValueChange, stopEditing } = params;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.select();
    inputRef.current?.focus();
  }, []);

  const handleOnChange = useCallback(
    (e: string | ChangeEvent<HTMLInputElement>) => {
      const newValue = typeof e === 'string' ? e : e.target.value;
      onValueChange(newValue);
    },
    [onValueChange],
  );

  const text = value || '-';

  return (
    <HStack display="inline-flex" height="100%" alignItems="flex-start" background="transparent" p={2}>
      <QuotationInput
        ref={inputRef}
        color="#1F2A37"
        textAlign="center"
        value={text}
        onChange={handleOnChange}
        onBlur={() => stopEditing()}
        noStyle
        p={0}
      />
    </HStack>
  );
});
