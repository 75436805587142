import type {
  ICompanyOCRCreditResponseDTO,
  ICompanyQuoteCreditResponse,
  ICompany,
  ICompanyLegalMentionsUpdateDTO,
  ICompanyMarginsResponseDTO,
  ICompanyPaymentTermsUpdateDTO,
  ICompanyQuoteValidityDurationDTO,
  ICompanyStripeDataDTO,
  ICompanyStripePaymentMethodDefaultDTO,
  ICompanyUpdateDTO,
  IUpdateMarginsDTO,
  ICompanyQuoteDefaultAutomaticNumberingDTO,
  ICompanyBillRemindersDTO,
  ICompanyDefaultMealExpenseDTO,
  ICompanySupplierInvoiceValidationRemindersDTO,
  ICompanyKYCValidationDTO,
  ICompanyKYCValidation,
} from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { userKeyFactory } from 'features/user/services/user.api';
import { dtoToFormData } from 'features/api/services/api.util';
import { apiNew } from 'features/api/services/apiNew.service';

const COMPANY_PATH = '/companies';

const companyKeyFactory = createQueryKeys('companies', {
  getUserCompanies: () => ({
    queryKey: [COMPANY_PATH, 'user-companies'],
    queryFn: () => apiNew.get<never, ICompany[]>(COMPANY_PATH),
  }),
  getPaymentTerms: () => ({
    queryKey: [COMPANY_PATH, 'payment-terms'],
    queryFn: () => apiNew.get<never, Pick<ICompany, 'deadlineType' | 'paymentTerm'>>(`${COMPANY_PATH}/payment-terms`),
  }),
  getValidityDuration: () => ({
    queryKey: [COMPANY_PATH, 'quote-validity-duration'],
    queryFn: () =>
      apiNew.get<never, Pick<ICompany, 'quoteValidityDuration'>>(`${COMPANY_PATH}/quote-validity-duration`),
  }),
  getQuoteDefaultAutomaticNumbering: () => ({
    queryKey: [COMPANY_PATH, 'quote-default-automatic-numbering'],
    queryFn: () =>
      apiNew.get<never, ICompanyQuoteDefaultAutomaticNumberingDTO>(`${COMPANY_PATH}/quote-default-automatic-numbering`),
  }),
  getMargins: () => ({
    queryKey: [COMPANY_PATH, 'margins'],
    queryFn: () => apiNew.get<never, ICompanyMarginsResponseDTO>(`${COMPANY_PATH}/margins`),
  }),
  getLegalMentions: () => ({
    queryKey: [COMPANY_PATH, 'legal-mentions'],
    queryFn: () =>
      apiNew.get<
        never,
        Pick<ICompany, 'termsOfSales' | 'legalBillingNotice' | 'documentFooter' | 'quoteNotes' | 'orderNotes'>
      >(`${COMPANY_PATH}/legal-mentions`),
  }),
  getKYCValidation: () => ({
    queryKey: [COMPANY_PATH, 'kyc-validation'],
    queryFn: () => apiNew.get<never, ICompanyKYCValidation>(`${COMPANY_PATH}/kyc-validation`),
  }),
  getStripeData: () => ({
    queryKey: [COMPANY_PATH, 'stripe'],
    queryFn: () => apiNew.get<never, ICompanyStripeDataDTO>(`${COMPANY_PATH}/stripe`),
  }),
  getOCRCredit: () => ({
    queryKey: [COMPANY_PATH, 'ocr-credit'],
    queryFn: () => apiNew.get<never, ICompanyOCRCreditResponseDTO>(`${COMPANY_PATH}/ocr-credit/`),
  }),
  getQuoteCredit: () => ({
    queryKey: [COMPANY_PATH, 'quote-credit'],
    queryFn: () => apiNew.get<never, ICompanyQuoteCreditResponse>(`${COMPANY_PATH}/quote-credit`),
  }),
});

export function useUpdateCompany() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'company']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: ICompanyUpdateDTO }) =>
      apiNew.patch<ICompanyUpdateDTO, ICompany>(`${COMPANY_PATH}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      toast.success(t('global:words.c.success'), t('company:editCompanyModal.toastSuccess'));
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
    },
  });
}

export function useUserCompanies() {
  return useSuspenseQuery(companyKeyFactory.getUserCompanies());
}

// -- Company margins
export function useCompanyMargins() {
  return useSuspenseQuery(companyKeyFactory.getMargins());
}
export function useUpdateCompanyMargins() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'margin']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IUpdateMarginsDTO) =>
      apiNew.patch<IUpdateMarginsDTO, ICompanyMarginsResponseDTO>(`${COMPANY_PATH}/margins`, dto),
    onSuccess: async () => {
      await queryClient.invalidateQueries(companyKeyFactory.getMargins());
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('margin:toast.error'));
    },
  });
}

// -- Company payment terms
export function useCompanyPaymentTerms() {
  return useSuspenseQuery(companyKeyFactory.getPaymentTerms());
}
export function useUpdateCompanyPaymentTerms() {
  const toast = useToast();
  const { t } = useTranslation(['paymentMethods']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ICompanyPaymentTermsUpdateDTO) =>
      apiNew.patch<ICompanyPaymentTermsUpdateDTO, ICompany>(`${COMPANY_PATH}/payment-terms`, dto),
    onSuccess: () => {
      toast.success(t('paymentMethods:deadline.toast.update.defaultSuccess'));
      queryClient.invalidateQueries(companyKeyFactory.getPaymentTerms());
    },
    onError: () => {
      toast.error(t('paymentMethods:deadline.toast.update.error', { email: SUPPORT_EMAIL }));
    },
  });
}

// -- Company legal mentions
export function useCompanyLegalMentions() {
  return useSuspenseQuery(companyKeyFactory.getLegalMentions());
}
export function useUpdateCompanyLegalMentions() {
  const toast = useToast();
  const { t } = useTranslation(['company', 'global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ICompanyLegalMentionsUpdateDTO) =>
      apiNew.patch<ICompanyLegalMentionsUpdateDTO, ICompany>(`${COMPANY_PATH}/legal-mentions`, dto),
    onSuccess: async (_, dto) => {
      toast.success(t(`company:${Object.keys(dto)[0] as keyof ICompanyLegalMentionsUpdateDTO}.toastSuccess`));
      await queryClient.invalidateQueries(companyKeyFactory.getLegalMentions());
    },
    onError: () => {
      toast.error(t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
  });
}

// -- Company stripe
export function useCompanyStripeData() {
  return useSuspenseQuery(companyKeyFactory.getStripeData());
}
export function useUpdateCompanyStripeData() {
  const toast = useToast();
  const { t } = useTranslation(['company']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (paymentMethodId: string) =>
      apiNew.post<ICompanyStripePaymentMethodDefaultDTO, ICompany>(`${COMPANY_PATH}/stripe/payment-method-default`, {
        paymentMethodId,
      }),
    onSuccess: async () => {
      toast.success(t('company:billing.sepaDebit.success'));
      await queryClient.invalidateQueries(companyKeyFactory.getStripeData());
    },
    onError: () => {
      toast.error(t('company:billing.sepaDebit.errors.settingDefault'));
    },
  });
}

// -- Quote validity duration
export function useCompanyQuoteValidityDuration() {
  return useSuspenseQuery(companyKeyFactory.getValidityDuration());
}
export function useUpdateCompanyQuoteValidityDuration() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (quoteValidityDuration: ICompanyQuoteValidityDurationDTO) =>
      apiNew.patch<ICompanyQuoteValidityDurationDTO, ICompany>(
        `${COMPANY_PATH}/quote-validity-duration`,
        quoteValidityDuration,
      ),
    onError: () => {
      toast.error(t('global:words.c.error'), t('quote:settings.validityDuration.toast.error'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries(companyKeyFactory.getValidityDuration());
    },
  });
}

export function useCompanyUpsertLogo() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (logoFile: File) =>
      apiNew.putWithMultiPart<FormData, ICompany>(`${COMPANY_PATH}/logo`, dtoToFormData({ logo: logoFile })),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
    },
  });
}

export function useCompanyOCRCredit() {
  return useSuspenseQuery(companyKeyFactory.getOCRCredit());
}

export function useCompanyQuoteCredit() {
  return useSuspenseQuery(companyKeyFactory.getQuoteCredit());
}

export function useQuoteDefaultAutomaticNumbering() {
  return useSuspenseQuery(companyKeyFactory.getQuoteDefaultAutomaticNumbering());
}
export function useUpdateQuoteDefaultAutomaticNumbering() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (companyQuoteDefaultAutomaticNumberingDTO: ICompanyQuoteDefaultAutomaticNumberingDTO) =>
      apiNew.patch<ICompanyQuoteDefaultAutomaticNumberingDTO, void>(
        `${COMPANY_PATH}/quote-default-automatic-numbering`,
        companyQuoteDefaultAutomaticNumberingDTO,
      ),
    onError: () => {
      toast.error(t('global:words.c.error'));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(companyKeyFactory.getQuoteDefaultAutomaticNumbering());
    },
  });
}

export function useUpdateCompanyBillReminders() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'mailing']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ICompanyBillRemindersDTO) =>
      apiNew.patch<ICompanyBillRemindersDTO, ICompany>(`${COMPANY_PATH}/bill-reminders`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:words.c.success'), t('mailing:remindersCard.successToast'));
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
    },
  });
}

export function useUpdateCompanySupplierInvoiceValidationReminders() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'mailing']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ICompanySupplierInvoiceValidationRemindersDTO) =>
      apiNew.patch<ICompanySupplierInvoiceValidationRemindersDTO, ICompany>(
        `${COMPANY_PATH}/supplier-invoice-validation-reminders`,
        dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:words.c.success'), t('mailing:remindersCard.successToast'));
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
    },
  });
}

export function useUpdateCompanyDefaultMealExpense() {
  const toast = useToast();
  const { t } = useTranslation(['planning']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ICompanyDefaultMealExpenseDTO) =>
      apiNew.patch<ICompanyDefaultMealExpenseDTO, ICompany>(`${COMPANY_PATH}/default-meal-expense`, dto),
    onError: () => {
      toast.error(t('planning:mealExpenseCard.errorSaving'));
    },
    onSuccess: async () => {
      toast.success(t('planning:mealExpenseCard.savedAmount'));
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
    },
  });
}

export function useCompanyKYCValidation() {
  return useSuspenseQuery(companyKeyFactory.getKYCValidation());
}

export const useUpdateCompanyKYCValidation = () => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'company']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ICompanyKYCValidationDTO & { kyc?: File }) => {
      const formData = new FormData();
      formData.append('kycOrgName', dto.kycOrgName);
      formData.append('kycAddress', dto.kycAddress);
      formData.append('kycIdentification', dto.kycIdentification);
      formData.append('kycOrgLegalStatus', dto.kycOrgLegalStatus);
      formData.append('kycSchemeName', dto.kycSchemeName);
      if (dto.kyc) {
        formData.append('kyc', dto.kyc);
      }

      return apiNew.putWithMultiPart<FormData, ICompany>(`${COMPANY_PATH}/kyc`, formData);
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(companyKeyFactory.getKYCValidation());
    },
  });
};
