import { subscribeToUrl } from './sse.api';

export const subscribeToSse = <T>(
  uuid: string,
  options: {
    onMessage(e: T): void;
    onError(): void;
  },
) => {
  const event = subscribeToUrl(uuid);
  event.onmessage = async (e) => {
    if (e.data) {
      event.close();
      options.onMessage(e.data);
    }
  };
  event.onerror = () => {
    options.onError();
    event.close();
  };
  return event;
};
