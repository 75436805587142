import type { FC } from 'react';
import { useCallback } from 'react';
import { Card, DataTable, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Center, GridItem, Stack, Text } from '@chakra-ui/react';
import { FEATURE_FLAGS, VAT_TYPE, type IVat } from '@graneet/business-logic';

import type { AccountingVatEditionBlockForm } from '../../forms/accounting.form';
import { getAccountingVatFieldName } from '../../forms/accounting.form';

import { Rule } from 'features/form/rules/Rule';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { sortVatList } from 'features/accounting/services/accounting-export.util';

interface AccountingVatEditionBlockProps {
  vats: Pick<IVat, 'id' | 'name' | 'rate' | 'type'>[];

  mustDisplayFields?: Record<keyof AccountingVatEditionBlockForm, boolean>;

  haveRequiredFields?: boolean;
}

export const AccountingVatEditionBlock: FC<AccountingVatEditionBlockProps> = ({
  vats,
  mustDisplayFields,
  haveRequiredFields,
}) => {
  const { t } = useTranslation(['accounting']);
  const hasAccountingStandards = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARDS);

  const mustDisplayField = useCallback(
    (fieldName: keyof AccountingVatEditionBlockForm) => {
      if (!mustDisplayFields) {
        return true;
      }

      return !!mustDisplayFields[fieldName];
    },
    [mustDisplayFields],
  );

  return (
    <Stack>
      <Text fontSize="lg" color="gray.800">
        {t('accounting:parameters.sections.vat')}
      </Text>

      <Card>
        <DataTable numberOfColumns={hasAccountingStandards ? 4 : 3}>
          {hasAccountingStandards ? (
            <>
              <DataTable.Header label="">
                <GridItem colSpan={2}>
                  <DataTable.Cell>
                    <Center>{t('accounting:headers.groupSales')}</Center>
                  </DataTable.Cell>
                </GridItem>
                <GridItem colSpan={2}>
                  <DataTable.Cell>
                    <Center>{t('accounting:headers.groupPurchases')}</Center>
                  </DataTable.Cell>
                </GridItem>
              </DataTable.Header>
              <DataTable.Header label={t('accounting:headers.vat')}>
                <DataTable.Cell>{t('accounting:headers.accountSalesCollected')}</DataTable.Cell>
                <DataTable.Cell>{t('accounting:headers.accountSalesPending')}</DataTable.Cell>
                <DataTable.Cell>{t('accounting:headers.accountPurchasesDeductible')}</DataTable.Cell>
                <DataTable.Cell>{t('accounting:headers.accountPurchasesPending')}</DataTable.Cell>
              </DataTable.Header>
            </>
          ) : (
            <DataTable.Header label={t('accounting:headers.vat')}>
              <DataTable.Cell>{t('accounting:headers.accountSales')}</DataTable.Cell>
              <DataTable.Cell>{t('accounting:headers.accountDownPayment')}</DataTable.Cell>
              <DataTable.Cell>{t('accounting:headers.accountPurchases')}</DataTable.Cell>
            </DataTable.Header>
          )}

          {vats.sort(sortVatList).map((vat) => {
            // Do not display special vat when FF isn't activated
            // @[ff: accounting-standards] remove
            if (!hasAccountingStandards && vat.type !== VAT_TYPE.NORMAL) {
              return null;
            }

            const accountSalesFieldName = getAccountingVatFieldName(vat.id, 'accountSales');
            const accountDownPaymentFieldName = getAccountingVatFieldName(vat.id, 'accountDownPayment');
            const accountPurchasesFieldName = getAccountingVatFieldName(vat.id, 'accountPurchases');

            const accountSalesCollectedFieldName = getAccountingVatFieldName(vat.id, 'accountSalesCollected');
            const accountSalesPendingFieldName = getAccountingVatFieldName(vat.id, 'accountSalesPending');
            const accountPurchasesDeductibleFieldName = getAccountingVatFieldName(vat.id, 'accountPurchasesDeductible');
            const accountPurchasesPendingFieldName = getAccountingVatFieldName(vat.id, 'accountPurchasesPending');

            return (
              <DataTable.Row
                key={vat.id}
                label={t(`accounting:fields.vat.${vat.type}`, {
                  name: vat.name,
                })}
              >
                {!hasAccountingStandards && (
                  <>
                    <DataTable.Cell>
                      {mustDisplayField(accountSalesFieldName) && (
                        <TextField<AccountingVatEditionBlockForm>
                          name={accountSalesFieldName}
                          placeholder={t('accounting:parameters.configPlaceholder')}
                        >
                          {haveRequiredFields && <Rule.IsRequired />}
                        </TextField>
                      )}
                    </DataTable.Cell>

                    <DataTable.Cell>
                      {mustDisplayField(accountDownPaymentFieldName) && (
                        <TextField<AccountingVatEditionBlockForm>
                          name={accountDownPaymentFieldName}
                          placeholder={t('accounting:parameters.configPlaceholder')}
                        >
                          {haveRequiredFields && <Rule.IsRequired />}
                        </TextField>
                      )}
                    </DataTable.Cell>

                    <DataTable.Cell>
                      {mustDisplayField(accountPurchasesFieldName) && (
                        <TextField<AccountingVatEditionBlockForm>
                          name={accountPurchasesFieldName}
                          placeholder={t('accounting:parameters.configPlaceholder')}
                        >
                          {haveRequiredFields && <Rule.IsRequired />}
                        </TextField>
                      )}
                    </DataTable.Cell>
                  </>
                )}

                {hasAccountingStandards && (
                  <>
                    {vat.type !== VAT_TYPE.NORMAL ? (
                      <>
                        <DataTable.Cell />
                        <DataTable.Cell />
                      </>
                    ) : (
                      <>
                        <DataTable.Cell>
                          {mustDisplayField(accountSalesCollectedFieldName) && (
                            <TextField<AccountingVatEditionBlockForm>
                              name={accountSalesCollectedFieldName}
                              placeholder={t('accounting:parameters.configPlaceholder')}
                            >
                              {haveRequiredFields && <Rule.IsRequired />}
                            </TextField>
                          )}
                        </DataTable.Cell>

                        <DataTable.Cell>
                          {mustDisplayField(accountSalesPendingFieldName) && (
                            <TextField<AccountingVatEditionBlockForm>
                              name={accountSalesPendingFieldName}
                              placeholder={t('accounting:parameters.configPlaceholder')}
                            >
                              {haveRequiredFields && <Rule.IsRequired />}
                            </TextField>
                          )}
                        </DataTable.Cell>
                      </>
                    )}
                    <DataTable.Cell>
                      {mustDisplayField(accountPurchasesDeductibleFieldName) && (
                        <TextField<AccountingVatEditionBlockForm>
                          name={accountPurchasesDeductibleFieldName}
                          placeholder={t('accounting:parameters.configPlaceholder')}
                        >
                          {haveRequiredFields && <Rule.IsRequired />}
                        </TextField>
                      )}
                    </DataTable.Cell>

                    <DataTable.Cell>
                      {mustDisplayField(accountPurchasesPendingFieldName) && (
                        <TextField<AccountingVatEditionBlockForm>
                          name={accountPurchasesPendingFieldName}
                          placeholder={t('accounting:parameters.configPlaceholder')}
                        >
                          {haveRequiredFields && <Rule.IsRequired />}
                        </TextField>
                      )}
                    </DataTable.Cell>
                  </>
                )}
              </DataTable.Row>
            );
          })}
        </DataTable>
      </Card>
    </Stack>
  );
};
