import type { ReactElement } from 'react';
import { memo, useCallback, useMemo, useState } from 'react';
import { DroppableFileField, PreviewFile, Spinner, UploadOCRFileIcon } from '@graneet/lib-ui';
import { HiddenField, useFormContext, useOnChangeValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { MINDEE_FILE_TYPE } from '@graneet/business-logic';
import { Box, Circle, Flex, Text } from '@chakra-ui/react';

import { UpdatableInvoiceFileCardHeader } from './UpdatableInvoiceFileCardHeader';

import { Rule } from 'features/form/rules/Rule';
import { useCompanyOCRCredit } from 'features/company/services/company.api';
import type { UseDisabledButtonPropsReturn } from 'features/role/hooks/useDisabledButtonProps';

type UpdatableInvoiceFileCardProps = {
  onFileChange(newFile: File | undefined, hasOCRCreditLeft: boolean): Promise<void>;

  withCardHeader?: boolean;

  hasOCR?: boolean;

  subHeaderComponent?: ReactElement;

  disableCardHeaderButtonProps?: UseDisabledButtonPropsReturn;

  isReadOnly?: boolean;
};

export interface UpdatableInvoiceFileCardFormValues {
  invoiceFile?: {
    fileURL: string;
    mimeType: string;
    file?: File;
  };

  pdfPageNumber: number;
}

export const UpdatableInvoiceFileCard = memo<UpdatableInvoiceFileCardProps>(
  ({
    onFileChange,
    withCardHeader = true,
    hasOCR = false,
    subHeaderComponent,
    disableCardHeaderButtonProps,
    isReadOnly = false,
  }) => {
    const { t } = useTranslation(['supplierInvoices']);

    const form = useFormContext<UpdatableInvoiceFileCardFormValues>();
    const { setFormValues } = form;
    const { invoiceFile } = useOnChangeValues(form, ['invoiceFile']);

    const [isLoading, setIsLoading] = useState(false);

    const companyOCRCredit = useCompanyOCRCredit();

    const isOCRCreditAvailable =
      (companyOCRCredit.data.consumedOCRCredit || 0) < (companyOCRCredit.data.limitOCRCredit || 0);

    const handleFileChange = useCallback(
      async (newInvoiceFile?: File) => {
        setIsLoading(true);
        await onFileChange(newInvoiceFile, isOCRCreditAvailable || false);
        setIsLoading(false);

        companyOCRCredit.refetch();

        setFormValues({
          invoiceFile: newInvoiceFile
            ? {
                fileURL: URL.createObjectURL(newInvoiceFile),
                mimeType: newInvoiceFile.type,
                file: newInvoiceFile,
              }
            : undefined,
        });
      },
      [onFileChange, isOCRCreditAvailable, companyOCRCredit, setFormValues],
    );

    const currentState = useMemo(() => {
      if (isLoading) {
        return 'loading';
      }
      if (invoiceFile?.fileURL) {
        return 'hasInvoiceFileInForm';
      }
      return 'default';
    }, [invoiceFile, isLoading]);

    const [scale, setScale] = useState(1);
    const canZoom = scale < 3;
    const canUnZoom = scale > 1;
    const changeScale = useCallback(
      (action: 'zoom' | 'unzoom') => () => {
        if (action === 'zoom' && canZoom) {
          setScale((s) => s + 0.2);
        }
        if (action === 'unzoom' && canUnZoom) {
          setScale((s) => s - 0.2);
        }
      },
      [canUnZoom, canZoom],
    );

    return (
      <UpdatableInvoiceFileCardHeader
        ocrCreditData={companyOCRCredit.data}
        withCardHeader={withCardHeader}
        hasOCR={hasOCR}
        isLoading={isLoading}
        disableButtonProps={disableCardHeaderButtonProps}
        onFileDelete={() => handleFileChange(undefined)}
      >
        {currentState === 'loading' && (
          <>
            <HiddenField<UpdatableInvoiceFileCardFormValues> name="invoiceFile" />
            <Spinner flexGrow={1} justify="center" mx={8}>
              <Text fontWeight={400} color="gray.600" textAlign="center">
                {t('supplierInvoices:invoiceFileField.fileIsBeingProcessed')}
              </Text>
            </Spinner>
          </>
        )}

        {currentState === 'hasInvoiceFileInForm' && (
          <>
            {subHeaderComponent}
            <HiddenField<UpdatableInvoiceFileCardFormValues> name="invoiceFile" />

            <Box flex={1} w="100%" position="relative" role="group">
              <Flex
                gap={3}
                top={0}
                right={0}
                position="absolute"
                zIndex={5}
                opacity={0}
                _groupHover={{ opacity: 1 }}
                transition="visibility 0s, opacity 0.1s linear"
              >
                <Circle
                  size="2rem"
                  bg="primary"
                  color={canUnZoom ? 'white' : 'gray'}
                  onClick={changeScale('unzoom')}
                  cursor="pointer"
                  userSelect="none"
                >
                  -
                </Circle>
                <Circle
                  size="2rem"
                  bg="primary"
                  color={canZoom ? 'white' : 'gray'}
                  onClick={changeScale('zoom')}
                  cursor="pointer"
                  userSelect="none"
                >
                  +
                </Circle>
              </Flex>
              <Box position="absolute" overflow="auto" top={0} bottom={0} left={0} right={0}>
                <Box position="relative">
                  <PreviewFile fileType={invoiceFile!.mimeType} fileUrl={invoiceFile!.fileURL} scale={scale} />
                </Box>
              </Box>
            </Box>
          </>
        )}

        {currentState === 'default' && (
          <>
            <DroppableFileField<any>
              name="invoiceFile"
              primaryLabel={t('supplierInvoices:invoiceFileField.primaryLabel')}
              primaryLabelError={t('supplierInvoices:invoiceFileField.primaryLabelError')}
              secondaryLabel={
                <>
                  {t('supplierInvoices:invoiceFileField.secondaryLabel')}{' '}
                  {isOCRCreditAvailable && (
                    <Text>{t('supplierInvoices:invoiceFileField.fileCanBeProcessedLabel')}</Text>
                  )}
                </>
              }
              fileTooLargeErrorMessage={t('supplierInvoices:invoiceFileField.fileTooLargeErrorMessage')}
              fileFieldDisabled={t('supplierInvoices:invoiceFileField.fileFieldDisabled')}
              accept={MINDEE_FILE_TYPE}
              h="100%"
              defaultIcon={<UploadOCRFileIcon height="12rem" width="auto" />}
              onFileChange={handleFileChange}
              isDisabled={disableCardHeaderButtonProps?.isDisabled || isReadOnly}
            >
              <Rule.IsFileSizeValid />
            </DroppableFileField>
            <HiddenField name="deleteInvoiceFile" />
          </>
        )}
      </UpdatableInvoiceFileCardHeader>
    );
  },
);
