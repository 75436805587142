import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ActionMenu,
  useToast,
  SimpleDotsIcon,
  SimpleCirclePlusIcon,
  SimpleSettingsIcon,
  SimpleRewindIcon,
  SimpleDuplicateIcon,
  SimpleOptionIcon,
  SimpleAddNoteIcon,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useDisclosure } from '@chakra-ui/react';
import { isNil } from 'lodash-es';

import { ContractLotVatRateModal } from '../modals/ContractLotVatRateModal';
import { useContractTreeContext, useContractLot, useContractLotComputedValue } from '../../hooks/tree.hooks';
import { generateFormValuesOfLot } from '../../services/contract-input.util';
import { NEW_LOT_DATA, NEW_ITEM_DATA } from '../../constants/contracts.constant';
import { useContractRuleContext } from '../../contexts/ContractRuleContext';
import { useContractDefaultVatRateContext } from '../../contexts/ContractDefaultVatRate';
import type { ContractEditionForm } from '../../forms/contract-edition.form';

import { useStore } from 'store/store';
import { MENU_DISPLAY_DELAY } from 'features/common/constants/menu.constant';

interface ContractLotActionsProps {
  lotId: number | string;

  onUpdateNote(newNote: string): () => void;
}

export const ContractLotActions: FC<ContractLotActionsProps> = ({ lotId, onUpdateNote }) => {
  const { t } = useTranslation(['contracts', 'global']);
  const toast = useToast();

  const form = useFormContext<ContractEditionForm>();

  const modal = useDisclosure();

  const [displayIcon, setDisplayIcon] = useState(true);
  const actionControls = useDisclosure({
    onClose: () => setDisplayIcon(true),
  });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { canLotBeDuplicated: isContractValidAfterLotDuplication, canLotBeDeleted: isContractValidAfterLotDeletion } =
    useContractRuleContext();
  const {
    node: lot,
    state: {
      isDeleted: isLotDeleted,
      isCreated: isLotCreated,
      canBeRestored: canLotBeRestored,
      canBeDeleted: canLotBeDeleted,
      updatedKeys,
    },
  } = useContractLot(lotId);
  const nodeComputedValue = useContractLotComputedValue(lotId);
  const {
    getCurrentTree,
    getLastLeafOfNode,
    getLastNodeOfNode,
    deleteNode: deleteContractLot,
    restoreNode: restoreContractLot,
    createNode: createContractLot,
    createLeaf: createContractItem,
    updateNodeData: updateContractLot,
    duplicateNode: duplicateContractLot,
    updateAllChildrenLeavesOfNode: updateAllChildrenLeavesOfLot,
    findDescendingChildrenOfNode,
  } = useContractTreeContext();
  const { canGlobalVATRateBeUpdated } = useContractRuleContext();
  const { getDefaultVATRate, setDefaultVATRate } = useContractDefaultVatRateContext();

  const { hasReversalOfLiability } = useOnChangeValues(form, ['hasReversalOfLiability']);

  const onDelete = useCallback(() => {
    try {
      const { errorMessage, canUpdate } = isContractValidAfterLotDeletion(lotId);
      if (!canUpdate) {
        throw new Error(errorMessage);
      }
      deleteContractLot(lotId);
    } catch (error) {
      toast.error((error as Error).message);
    }
  }, [isContractValidAfterLotDeletion, lotId, deleteContractLot, toast]);

  const onRestore = useCallback(() => {
    restoreContractLot(lotId);
    form.setFormValues(generateFormValuesOfLot(getCurrentTree().nodes[lotId]));
  }, [form, lotId, getCurrentTree, restoreContractLot]);

  const onAddSublot = useCallback(() => {
    const lastLotId = getLastNodeOfNode(lotId);
    const newLotId = createContractLot(lotId, lastLotId, NEW_LOT_DATA);
    setDefaultVATRate(newLotId, getDefaultVATRate(lotId));
  }, [getLastNodeOfNode, lotId, createContractLot, setDefaultVATRate, getDefaultVATRate]);

  const onAddItem = useCallback(() => {
    const lastItemId = getLastLeafOfNode(lotId);
    const vatRate = getDefaultVATRate(lotId);
    createContractItem(lotId, lastItemId, NEW_ITEM_DATA(vatRate));
  }, [getLastLeafOfNode, lotId, createContractItem, getDefaultVATRate]);

  const onDuplicateLot = useCallback(() => {
    try {
      const { errorMessage, canUpdate } = isContractValidAfterLotDuplication(lotId);
      if (!canUpdate) {
        throw new Error(errorMessage);
      }
      // Duplicating an optional job will result in an none optional job
      duplicateContractLot(lotId, { leaf: { invoicedAmountExVAT: 0, isOptional: false } });
    } catch (error) {
      toast.error((error as Error).message);
    }
  }, [isContractValidAfterLotDuplication, duplicateContractLot, lotId, toast]);

  const onAddNote = useCallback(() => {
    updateContractLot(lotId, { note: '' });
    onUpdateNote('')();
  }, [lotId, updateContractLot, onUpdateNote]);

  const onAcceptOption = useCallback(() => {
    updateAllChildrenLeavesOfLot(lotId, { isOptional: false });
  }, [lotId, updateAllChildrenLeavesOfLot]);

  const hasInvoicedItems = !!nodeComputedValue?.hasInvoicedItems;
  const canChangeVATRate = !hasInvoicedItems && canGlobalVATRateBeUpdated() && !hasReversalOfLiability;
  const hasNote = !isNil(lot.note) || (isNil(lot.note) && updatedKeys.includes('note'));

  const displayCanRestoreAction = canLotBeRestored && !isLotCreated;
  const displayCanDeleteAction = canLotBeDeleted && !isLotDeleted;

  const handleActionOpen = useCallback(() => {
    setDisplayIcon(false);
    // Introduce a small timeout to avoid breaking display animation
    timeoutRef.current = setTimeout(actionControls.onOpen, MENU_DISPLAY_DELAY);
  }, [actionControls.onOpen]);

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  const isOptional = useStore((state) => state.optionalLotsTable[lotId.toString()]);

  const hasChildren = useMemo(
    () => findDescendingChildrenOfNode(lotId).leaves.length > 0,
    [findDescendingChildrenOfNode, lotId],
  );

  if (displayIcon && !modal.isOpen) {
    return (
      <SimpleDotsIcon
        boxSize={6}
        onClick={handleActionOpen}
        stroke="gray.300"
        _hover={{
          color: 'greenBrand.light',
          cursor: 'pointer',
          background: 'rgba(0, 21, 63, 0.05)',
          borderRadius: '0.375rem',
        }}
      />
    );
  }

  return (
    <>
      <ActionMenu {...actionControls}>
        {isOptional && hasChildren ? (
          <ActionMenu.Action
            icon={<SimpleOptionIcon />}
            label={t('contracts:option.acceptOption')}
            onClick={onAcceptOption}
          />
        ) : null}

        {!hasNote && (
          <ActionMenu.Action
            onClick={onAddNote}
            label={t('contracts:itemEdition.addNote')}
            icon={<SimpleAddNoteIcon />}
          />
        )}

        <ActionMenu.Action
          onClick={onAddSublot}
          label={t('contracts:lotEdition.addSubLot')}
          icon={<SimpleCirclePlusIcon />}
        />

        <ActionMenu.Action
          onClick={onAddItem}
          label={t('contracts:lotEdition.addItem')}
          icon={<SimpleCirclePlusIcon />}
        />

        {canChangeVATRate && (
          <ActionMenu.Action onClick={modal.onOpen} label={t('global:applyVAT')} icon={<SimpleSettingsIcon />} />
        )}

        {displayCanRestoreAction && (
          <ActionMenu.Action onClick={onRestore} label={t('global:words.c.restore')} icon={<SimpleRewindIcon />} />
        )}

        {!isLotDeleted && (
          <ActionMenu.Action
            onClick={onDuplicateLot}
            label={t('global:words.c.duplicate')}
            icon={<SimpleDuplicateIcon />}
          />
        )}

        {displayCanDeleteAction && (
          <ActionMenu.Delete
            tooltipProps={{
              label: t('contracts:lotEdition.error.impossibleToDelete'),
              placement: 'bottom-start',
              isDisabled: !hasInvoicedItems,
            }}
            onClick={onDelete}
            isDisabled={hasInvoicedItems}
          />
        )}
      </ActionMenu>

      <ContractLotVatRateModal id={lotId} type="lot" {...modal} />
    </>
  );
};
