import { createQuoteSalesDiscountDeletedCallback } from './createQuoteSalesDiscountDeletedCallback';
import { createQuoteSalesDiscountUpsertedCallback } from './createQuoteSalesDiscountUpsertedCallback';

import type { QuoteSalesDiscountsWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';

export const quoteSalesDiscountCallbacks = (quoteId: String) => [
  {
    channel: `private-quote-${quoteId}`,
    event: 'salesDiscount:updated',
    callback: (message: QuoteSalesDiscountsWsMessage) => createQuoteSalesDiscountUpsertedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'salesDiscount:added',
    callback: (message: QuoteSalesDiscountsWsMessage) => createQuoteSalesDiscountUpsertedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'salesDiscount:deleted',
    callback: (message: QuoteSalesDiscountsWsMessage) => createQuoteSalesDiscountDeletedCallback(message),
  },
];
