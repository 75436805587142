import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const ErrorIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 300 300" {...props}>
    <circle cx="150" cy="150" r="150" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.0905 72.0198C74.5261 73.3233 69.4932 73.0972 64.1964 71.3169C60.8008 75.7548 56.7548 78.7566 53.0708 79.6691C51.7672 76.1046 51.9933 71.0717 53.7736 65.775C49.3357 62.3793 46.334 58.3333 45.4215 54.6493C48.9859 53.3457 54.0188 53.5718 59.3155 55.3521C62.7112 50.9143 66.7572 47.9125 70.4412 47C71.7448 50.5644 71.5187 55.5973 69.7384 60.8941C74.1762 64.2897 77.178 68.3358 78.0905 72.0198ZM235.745 242.283C233.547 243.715 230.123 244.345 226.28 243.97C224.683 247.486 222.425 250.137 220.086 251.324C218.654 249.126 218.024 245.702 218.399 241.858C214.883 240.262 212.233 238.004 211.045 235.665C213.243 234.233 216.667 233.603 220.511 233.978C222.108 230.462 224.365 227.811 226.704 226.624C228.136 228.822 228.767 232.246 228.391 236.09C231.907 237.686 234.558 239.944 235.745 242.283ZM55.0253 245.701C57.8946 249.59 61.3481 252.246 64.52 253.091C65.701 250.028 65.5815 245.673 64.1219 241.066C68.0107 238.196 70.6675 234.743 71.5122 231.571C68.4496 230.39 64.0941 230.51 59.4869 231.969C56.6176 228.08 53.1642 225.423 49.9923 224.579C48.8112 227.641 48.9307 231.997 50.3903 236.604C46.5015 239.473 43.8447 242.927 43 246.099C46.0626 247.28 50.4181 247.16 55.0253 245.701ZM250.319 108.327C246.949 107.43 243.28 104.607 240.231 100.475C235.336 102.026 230.708 102.153 227.454 100.898C228.352 97.5276 231.174 93.8584 235.306 90.8097C233.755 85.9146 233.628 81.2868 234.883 78.0328C238.253 78.9303 241.923 81.7531 244.971 85.885C249.867 84.3342 254.494 84.2072 257.748 85.4621C256.851 88.8322 254.028 92.5015 249.896 95.5502C251.447 100.445 251.574 105.073 250.319 108.327Z"
      fill="#FA8E84"
    />
    <path
      d="M212.146 121.859C209.234 121.859 206.873 124.22 206.873 127.133V160.523C206.873 163.436 209.234 165.796 212.146 165.796C215.059 165.796 217.42 163.436 217.42 160.523V127.133C217.42 124.22 215.059 121.859 212.146 121.859Z"
      fill="#53B9F6"
    />
    <path
      d="M87.5293 121.859C84.6165 121.859 82.2559 124.22 82.2559 127.133V160.523C82.2559 163.436 84.6165 165.796 87.5293 165.796C90.4407 165.796 92.8027 163.436 92.8027 160.523V127.133C92.8027 124.22 90.4407 121.859 87.5293 121.859Z"
      fill="#B0E2FF"
    />
    <path
      d="M212.084 155.291H202.623C195.357 155.291 189.446 161.202 189.446 168.468V184.844C189.446 192.11 195.357 198.022 202.623 198.022H212.084C219.35 198.022 225.262 192.11 225.262 184.844V168.468C225.262 161.202 219.35 155.291 212.084 155.291Z"
      fill="#0D8CD9"
    />
    <path
      d="M97.0514 155.291H87.5922C80.3261 155.291 74.4141 161.202 74.4141 168.468V184.844C74.4141 192.11 80.3261 198.022 87.5922 198.022H97.0514C104.317 198.022 110.229 192.11 110.229 184.844V168.468C110.229 161.202 104.317 155.291 97.0514 155.291Z"
      fill="#53B9F6"
    />
    <path
      d="M165.504 106.293H134.174C115.211 106.293 99.7852 121.721 99.7852 140.683V211.288C99.7852 223.811 109.972 233.999 122.497 233.999H177.181C189.704 233.999 199.893 223.812 199.893 211.288V140.682C199.893 121.719 184.465 106.293 165.504 106.293Z"
      fill="#0D8CD9"
    />
    <path
      d="M165.503 106.293H149.838V233.999H177.18C189.703 233.999 199.892 223.811 199.892 211.288V140.682C199.892 121.719 184.464 106.293 165.503 106.293Z"
      fill="#004E7A"
    />
    <path
      d="M149.838 66.9277C146.925 66.9277 144.564 69.2898 144.564 72.2012V103.265C144.564 106.178 146.925 108.538 149.838 108.538C152.749 108.538 155.111 106.178 155.111 103.265V72.2012C155.111 69.2898 152.751 66.9277 149.838 66.9277Z"
      fill="#B0E2FF"
    />
    <path
      d="M167.127 116.763V106.036C167.127 98.77 161.215 92.8594 153.948 92.8594H145.727C138.46 92.8594 132.55 98.77 132.55 106.036V116.763H167.127Z"
      fill="#0D8CD9"
    />
    <path
      d="M167.126 116.763V106.036C167.126 98.77 161.214 92.8594 153.948 92.8594H149.838V116.763H167.126Z"
      fill="#004E7A"
    />
    <path
      d="M149.838 54C141.814 54 135.285 60.5286 135.285 68.5528C135.285 76.5769 141.814 83.1042 149.838 83.1042C157.862 83.1042 164.389 76.5769 164.389 68.5528C164.389 60.5286 157.862 54 149.838 54Z"
      fill="#FA8E84"
    />
    <path
      d="M149.838 54V83.1042C157.862 83.1042 164.389 76.5755 164.389 68.5514C164.389 60.5273 157.862 54 149.838 54Z"
      fill="#DA1E0C"
    />
    <path
      d="M176.162 203.694L169.401 201.381C163.094 199.223 156.465 198.145 149.838 198.145C143.209 198.145 136.581 199.223 130.272 201.381L123.512 203.694C120.757 204.637 119.287 207.635 120.229 210.39C121.173 213.146 124.169 214.616 126.926 213.672L133.686 211.36C138.894 209.579 144.365 208.689 149.838 208.689C155.309 208.689 160.781 209.579 165.989 211.36L172.748 213.672C173.314 213.866 173.889 213.958 174.455 213.958C176.648 213.958 178.696 212.581 179.444 210.39C180.388 207.635 178.918 204.636 176.162 203.694Z"
      fill="#83CDFA"
    />
    <path
      d="M176.163 203.694L169.402 201.381C163.095 199.223 156.466 198.145 149.838 198.145L149.837 208.687C155.309 208.687 160.781 209.579 165.988 211.36L172.749 213.672C173.315 213.866 173.89 213.958 174.456 213.958C176.649 213.958 178.697 212.581 179.445 210.39C180.389 207.635 178.919 204.636 176.163 203.694Z"
      fill="#109CF1"
    />
    <path
      d="M176.064 154.498C173.152 154.498 170.791 156.859 170.791 159.771V177.727C170.791 180.64 173.152 183.001 176.064 183.001C178.976 183.001 181.338 180.64 181.338 177.727V159.771C181.338 156.859 178.977 154.498 176.064 154.498Z"
      fill="#4FD2F5"
    />
    <path
      d="M176.064 185.182C173.152 185.182 170.791 187.542 170.791 190.455V192.231C170.791 195.144 173.152 197.504 176.064 197.504C178.976 197.504 181.338 195.144 181.338 192.231V190.455C181.338 187.542 178.977 185.182 176.064 185.182Z"
      fill="#4FD2F5"
    />
    <path
      d="M146.198 160.564C146.198 167.624 140.474 173.348 133.414 173.348C126.354 173.348 120.63 167.624 120.63 160.564C120.63 153.504 126.354 147.781 133.414 147.781C140.474 147.781 146.198 153.504 146.198 160.564Z"
      fill="#FFCB7F"
    />
    <path
      d="M146.197 160.564C146.197 167.624 140.473 173.348 133.413 173.348V147.781C140.473 147.781 146.197 153.504 146.197 160.564Z"
      fill="#FF991D"
    />
    <path
      d="M138.907 160.563C138.907 163.598 136.447 166.058 133.413 166.058C130.38 166.058 127.919 163.598 127.919 160.563C127.919 157.53 130.38 155.07 133.413 155.07C136.447 155.07 138.907 157.53 138.907 160.563Z"
      fill="#83CDFA"
    />
    <path
      d="M138.906 160.563C138.906 163.598 136.447 166.058 133.413 166.058V155.07C136.447 155.07 138.906 157.53 138.906 160.563Z"
      fill="#0D8CD9"
    />
    <path
      d="M179.045 160.564C179.045 167.624 173.323 173.348 166.262 173.348C159.202 173.348 153.479 167.624 153.479 160.564C153.479 153.504 159.202 147.781 166.262 147.781C173.323 147.781 179.045 153.504 179.045 160.564Z"
      fill="#FFCB7F"
    />
    <path
      d="M179.045 160.564C179.045 167.624 173.323 173.348 166.263 173.348V147.781C173.323 147.781 179.045 153.504 179.045 160.564Z"
      fill="#FF991D"
    />
    <path
      d="M171.755 160.563C171.755 163.598 169.296 166.058 166.262 166.058C163.227 166.058 160.768 163.598 160.768 160.563C160.768 157.53 163.227 155.07 166.262 155.07C169.296 155.07 171.755 157.53 171.755 160.563Z"
      fill="#83CDFA"
    />
    <path
      d="M171.756 160.563C171.756 163.598 169.296 166.058 166.263 166.058V155.07C169.296 155.07 171.756 157.53 171.756 160.563Z"
      fill="#0D8CD9"
    />
    <path
      d="M165.503 106.293H134.173C117.707 106.293 103.91 117.926 100.562 133.403H199.114C195.765 117.926 181.967 106.293 165.503 106.293Z"
      fill="#83CDFA"
    />
    <path
      d="M199.114 133.403C195.765 117.926 181.969 106.293 165.503 106.293H149.838V133.403H199.114Z"
      fill="#109CF1"
    />
  </Icon>
);
