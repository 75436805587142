import { HStack, Text, VStack } from '@chakra-ui/react';
import { SingleSelectField } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PDF_FONT } from '@graneet/business-logic';

import type { PDFSettingsFormValues } from '../constants/pdf-settings.constant';
import { PDF_SETTINGS_FIELDS } from '../constants/pdf-settings.constant';

const TypoOption = Object.values(PDF_FONT).map((typo) => ({ label: typo, value: typo }));

export const PdfTypoSettings: FC = () => {
  const { t } = useTranslation(['pdfSettings']);
  return (
    <VStack spacing={2} align="start" w="70%">
      <HStack spacing={2}>
        <Text color="black" fontSize="lg">
          {t('pdfSettings:settings.typo.title')}
        </Text>
      </HStack>

      <SingleSelectField<PDFSettingsFormValues>
        name={PDF_SETTINGS_FIELDS.FONT}
        options={TypoOption}
        isClearable={false}
      />
    </VStack>
  );
};
