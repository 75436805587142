import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, AmountSummary } from '@graneet/lib-ui';
import type { IProgressStatementSummaryResponseDTO } from '@graneet/business-logic';

export interface ProgressSummaryCardProps {
  summary: IProgressStatementSummaryResponseDTO;
}

export const ProgressSummaryCard: FC<ProgressSummaryCardProps> = ({ summary }) => {
  const { t } = useTranslation(['progressStatement']);

  return (
    <Card title={t('progressStatement:isCreating.validationStep.progressSummaryTitle')}>
      <AmountSummary>
        {(summary.previousProgressStatements || []).length > 0 && (
          // in first PS, cumulative amount = non-cumulative amount so this line makes no sense
          <AmountSummary.Highlight
            left
            label={t('progressStatement:isCreating.validationStep.cumulativeProgressExVAT')}
            amount={summary.cumulativeAmountExVAT}
          />
        )}
        {(summary.previousProgressStatements || []).map((progressStatement) => (
          <AmountSummary.Item
            key={progressStatement.id}
            left
            light
            sign
            negative
            label={t('progressStatement:isCreating.validationStep.progressStatementListingExVAT', {
              name: progressStatement.name,
            })}
            amount={progressStatement.nonCumulativeProgressAmountExVAT}
          />
        ))}
        <AmountSummary.Highlight
          left
          label={t('progressStatement:isCreating.validationStep.nonCumulativeAmountExVAT')}
          amount={summary.nonCumulativeProgressAmountExVAT}
        />
      </AmountSummary>
    </Card>
  );
};
