/* eslint no-underscore-dangle: 0 */
import type { RichTextConfiguration, RichTextVariableConfiguration } from '../components';
import {
  $createInjectedVariableNode,
  ReplacedVariableNode,
} from '../components/RichTextInput/plugins/VariablePlugin/ReplacedVariableNode';
import { VariableNode } from '../components/RichTextInput/plugins/VariablePlugin';

export const getRichTextPluginOptions = (configuration: RichTextConfiguration) => {
  const variablePluginOptions = configuration.find(
    (c): c is RichTextVariableConfiguration => typeof c === 'object' && c.name === 'variable',
  );

  const theme = {
    variables: {
      options: variablePluginOptions,
      badge: 'editor-variable',
      badgeError: 'editor-variable-error',
    },
    paragraph: 'editor-paragraph',
    text: {
      bold: 'editor-text-bold',
      italic: 'editor-text-italic',
      underline: 'editor-text-underline',
      strikethrough: 'editor-text-strikethrough',
      subscript: 'editor-text-superscript',
      underlineStrikethrough: 'editor-text-underlineStrikethrough',
    },
    list: {
      listitem: 'editor-list-listitem',
      nested: {
        listitem: 'editor-list-nested-listitem',
      },
      olDepth: [
        'editor-list-olDepth-ol1',
        'editor-list-olDepth-ol2',
        'editor-list-olDepth-ol3',
        'editor-list-olDepth-ol4',
        'editor-list-olDepth-ol5',
      ],
      ul: 'editor-list-ul',
    },
    link: 'editor-link',
    heading: {
      h1: 'editor-heading-h1',
      h2: 'editor-heading-h2',
      h3: 'editor-heading-h3',
    },
  };
  return {
    variablePluginOptions,
    theme,
  };
};

export function createInjectedVariableNodes(richTextVariableConfiguration: RichTextVariableConfiguration) {
  return [
    ReplacedVariableNode,
    {
      replace: VariableNode,
      with(node: VariableNode) {
        const textValue = richTextVariableConfiguration.optionsText[node.__value].value;
        if (textValue) {
          return $createInjectedVariableNode(
            { value: node.__value, text: textValue },
            {
              format: node.getFormat(),
              detail: node.getDetail(),
              mode: node.getMode(),
              style: node.getStyle(),
            },
          );
        }
        return node;
      },
    },
  ];
}
