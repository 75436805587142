import { create } from 'zustand';
import { useCallback, useMemo } from 'react';
import type { QuotePaginatedResponse } from '@graneet/business-logic';
import { FEATURE_FLAGS } from '@graneet/business-logic';
import type { Response } from '@graneet/lib-ui';
import type { QuotePaginatedDTO } from '@org/graneet-bff-client';

import { useClientsQuery } from 'features/client/services/client.api';
import { useProjectsQuery } from 'features/project/services/project.api';
import { useStatementsQuery } from 'features/statement/services/statement.api';
import { useSuppliersQuery } from 'features/supplier/services/supplier.api';
import { useLibraryJobsQuery } from 'features/library-job/services/library-job.api';
import { useBillsQuery } from 'features/bill/services/bill.api';
import { useHoldbacksQuery } from 'features/holdback/services/holdback.api';
import { useOrdersQuery } from 'features/order/services/order.api';
import { useSupplierInvoicesQuery } from 'features/supplier-invoice/services/supplier-invoice.api';
import { getStatementPath } from 'features/statement/services/statement.util';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useData } from 'features/api/hooks/useData';
import { getQuotesPaginated } from 'features/quote/services/quote.api';

export interface SearchResult {
  type:
    | 'quote'
    | 'project'
    | 'client'
    | 'supplier'
    | 'contact'
    | 'jobs'
    | 'statement'
    | 'bill'
    | 'holdback'
    | 'order'
    | 'supplierInvoice';
  link: string;
  name: string;
  id: number | string;
  code?: string | null;
}

export interface PaletteState {
  paletteIsOpen: boolean;
  paletteSearch: string;
  paletteSearchInput: string;
  palettePage: string;
  paletteSearchResults: SearchResult[];
  paletteLoading: boolean;
  paletteSetLoading: (loading: boolean) => void;
  paletteOpen: () => void;
  paletteClose: () => void;
  paletteToggle: (open: boolean) => void;
  paletteSetSearch: (search: string) => void;
  paletteSetSearchInput: (search: string) => void;
  paletteSetPage: (page: string) => void;
  paletteSetSearchResults: (results: SearchResult[]) => void;
}

export const usePaletteStore = create<PaletteState>((set) => ({
  paletteIsOpen: false,
  paletteSearch: '',
  paletteSearchInput: '',
  palettePage: 'root',
  paletteSearchResults: [],
  paletteLoading: false,
  paletteSetLoading: (loading: boolean) => set({ paletteLoading: loading }),
  paletteSetSearchInput: (search: string) => set({ paletteSearchInput: search }),
  paletteSetSearch: (search: string) => set({ paletteSearch: search }),
  paletteSetPage: (page: string) => set({ palettePage: page }),
  paletteOpen: () => set({ paletteIsOpen: true }),
  paletteClose: () => set({ paletteIsOpen: false }),
  paletteToggle: (open: boolean) => set({ paletteIsOpen: open }),
  paletteSetSearchResults: (results: SearchResult[]) => set({ paletteSearchResults: results }),
}));

export const usePalette = () => {
  const size = '5';
  const page = '1';

  const isQuoteV2Enabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);

  const searchQuery = usePaletteStore((state) => state.paletteSearch);
  const isOpen = usePaletteStore((state) => state.paletteIsOpen);

  const quoteProxy = useQuotationProxyApis().quoteProxyApi;

  const getPaginatedQuotesGetter = useCallback(
    (params: URLSearchParams | undefined) => quoteProxy!.getQuotesPaginatedByCompanyId(params),
    [quoteProxy],
  );

  const getQuoteMethod = useMemo(
    () => (isQuoteV2Enabled ? getPaginatedQuotesGetter : getQuotesPaginated),
    [isQuoteV2Enabled, getPaginatedQuotesGetter],
  );

  const getQuotePromise = useCallback(
    () =>
      isOpen
        ? getQuoteMethod(new URLSearchParams({ _search: searchQuery[0], _size: size, _page: page }))
        : Promise.resolve<Response<null>>([null, null]),
    [isOpen, searchQuery, getQuoteMethod],
  );

  // TODO type issue with rollback
  const quotes = useData<unknown>(getQuotePromise);

  const projects = useProjectsQuery({ _search: searchQuery[0], _size: size, _page: page } as any, {
    enabled: isOpen,
  });
  const bills = useBillsQuery({ _search: searchQuery[0], _size: size, _page: page } as any, {
    enabled: isOpen,
  });
  const statements = useStatementsQuery({ _search: searchQuery[0], _size: size, _page: page } as any, {
    enabled: isOpen,
  });
  const suppliers = useSuppliersQuery({ _search: searchQuery[0], _size: size, _page: page } as any, {
    enabled: isOpen,
  });
  const clients = useClientsQuery({ _search: searchQuery[0], _size: size, _page: page } as any, {
    enabled: isOpen,
  });
  const holdbacks = useHoldbacksQuery({ _search: searchQuery[0], _size: size, _page: page } as any, {
    enabled: isOpen,
  });
  const libraryJobs = useLibraryJobsQuery({ _search: searchQuery[0], _size: size, _page: page } as any, {
    enabled: isOpen,
  });
  const supplierInvoices = useSupplierInvoicesQuery({ _search: searchQuery[0], _size: size, _page: page } as any, {
    enabled: isOpen,
  });
  const orders = useOrdersQuery({ _search: searchQuery[0], _size: size, _page: page } as any, {
    enabled: isOpen,
  });

  const data: SearchResult[] = useMemo(() => {
    const results: SearchResult[] = [];
    if (quotes.data) {
      (quotes.data as QuotePaginatedResponse | QuotePaginatedDTO).data.forEach((quote) => {
        results.push({
          type: 'quote',
          link:
            typeof quote.id === 'string' ? `/opportunities/quotation/${quote.id}` : `/opportunities/quotes/${quote.id}`,
          name: quote.name,
          id: quote.id,
          code: quote.refCode,
        });
      });
    }
    if (projects.data) {
      projects.data.data.forEach((project) => {
        results.push({
          type: 'project',
          link: `/projects/${project.id}`,
          name: project.name,
          id: project.id,
          code: project.refCode,
        });
      });
    }
    if (clients.data) {
      clients.data.data.forEach((client) => {
        results.push({
          type: 'client',
          link: `/contacts/clients/${client.id}`,
          name: client.enterpriseName,
          id: client.id,
          code: client.code,
        });
      });
    }
    if (suppliers.data) {
      suppliers.data.data.forEach((supplier) => {
        results.push({
          type: 'supplier',
          link: `/contacts/suppliers/${supplier.id}`,
          name: supplier.name,
          id: supplier.id,
          code: supplier.code,
        });
      });
    }
    if (libraryJobs.data) {
      libraryJobs.data.data.forEach((library) => {
        results.push({
          type: 'jobs',
          link: `/opportunities/library/jobs/${library.id}`,
          name: library.description,
          id: library.id,
          code: library.refCode,
        });
      });
    }
    if (statements.data) {
      statements.data.data.forEach((statement) => {
        results.push({
          type: 'statement',
          link: `/sales/statements/${getStatementPath(statement.type)}/${statement.id}`,
          name: statement.name,
          id: statement.id,
          code: statement.invoiceNumber,
        });
      });
    }
    if (bills.data) {
      bills.data.data.forEach((bill) => {
        results.push({ type: 'bill', link: `/sales/bills/${bill.id}`, name: bill.name, id: bill.id });
      });
    }
    if (holdbacks.data) {
      holdbacks.data.data.forEach((holdback) => {
        results.push({
          type: 'holdback',
          link: `/sales/holdbacks/${holdback.id}`,
          name: holdback.subProject.name,
          id: holdback.id,
        });
      });
    }
    if (orders.data) {
      orders.data.data.forEach((order) => {
        results.push({
          type: 'order',
          link: `/purchases/orders/${order.id}`,
          name: order.name,
          id: order.id,
          code: order.orderNumber,
        });
      });
    }
    if (supplierInvoices.data) {
      supplierInvoices.data.data.forEach((supplierInvoice) => {
        results.push({
          type: 'supplierInvoice',
          link: `/purchases/supplier-invoices/${supplierInvoice.id}`,
          name: supplierInvoice.name ?? '',
          id: supplierInvoice.id,
          code: supplierInvoice.invoiceNumber,
        });
      });
    }
    return results;
  }, [
    quotes,
    projects.data,
    clients.data,
    suppliers.data,
    libraryJobs.data,
    statements.data,
    bills.data,
    holdbacks.data,
    orders.data,
    supplierInvoices.data,
  ]);

  const isLoading =
    quotes.loading ||
    projects.isLoading ||
    clients.isLoading ||
    suppliers.isLoading ||
    libraryJobs.isLoading ||
    statements.isLoading ||
    bills.isLoading ||
    holdbacks.isLoading ||
    orders.isLoading ||
    supplierInvoices.isLoading;

  return useMemo(() => ({ data, isLoading }), [data, isLoading]);
};
