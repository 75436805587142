import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import { Button, SimpleRepeatIcon, useToast } from '@graneet/lib-ui';
import type { IProject } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { ClientSelectionModal } from './modals/ClientSelectionModal';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { useProjectUpdate } from 'features/project/services/project.api';

interface ClientDetailCardUpdateBilledFooterProps {
  project: IProject;
}

export const ClientDetailCardUpdateBilledFooter: FC<ClientDetailCardUpdateBilledFooterProps> = ({ project }) => {
  const { t } = useTranslation(['global', 'project']);
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const createProjectsButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_PROJECTS]);

  const projectUpdateMutation = useProjectUpdate();

  const onSubmit = useCallback(
    async (clientId: number | undefined) => {
      if (!clientId) {
        return;
      }
      await projectUpdateMutation.mutateAsync(
        {
          id: project.id,
          dto: {
            primaryClientId: clientId,
          },
        },
        {
          onSuccess: () => {
            toast.success(t('global:words.c.success'), t('project:editPrimaryClientModal.updatedToast'));
            onClose();
          },
        },
      );
    },
    [projectUpdateMutation, project.id, toast, t, onClose],
  );

  return (
    <>
      <ClientSelectionModal
        isOpen={isOpen}
        onClose={onClose}
        title={t('project:editPrimaryClientModal.title')}
        description={t('project:editPrimaryClientModal.description')}
        successButtonTranslation={t('global:words.c.next')}
        onSubmit={onSubmit}
      />

      <Button mt={5} variant="outline" size="md" onClick={onOpen} {...createProjectsButtonDisabledProps()}>
        <SimpleRepeatIcon mr={2} />
        {t('project:editPrimaryClientModal.updateClientToBill')}
      </Button>
    </>
  );
};
