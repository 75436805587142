/*
  normalize()ing to NFD Unicode normal form decomposes combined graphemes into the combination of simple ones.
  The è of Crème ends up expressed as e +  ̀.
  Using a regex character class to match the U+0300 → U+036F range,
  it is now trivial to globally get rid of the diacritics,
  which the Unicode standard conveniently groups as the Combining Diacritical Marks Unicode block.
*/
export const normalizeStringNFD = (str: string): string => str.normalize('NFD').replace(/\p{Diacritic}/gu, '');

export const EMAIL_REGEX =
  '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export const isEmail = (value: unknown): boolean => {
  const isEmailRegex = new RegExp(EMAIL_REGEX);
  return isEmailRegex.test(String(value).toLowerCase());
};

export const getPotentialStringifyObject = <T extends object>(
  str: string | null,
): [true, T] | [false, string | null] => {
  try {
    const parsedStr = JSON.parse(str || '');

    // Because for exemple JSON.parse(1) do not throw an error, we ensure that the parsedStr is an object
    if (typeof parsedStr !== 'object') {
      throw new Error();
    }
    return [true, parsedStr];
  } catch {
    return [false, str];
  }
};

export const capitalizeString = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export const safeParseInt = (s: string, radix: number): number => {
  const parsedString = parseInt(s, radix);

  if (Number.isNaN(parsedString)) {
    throw new Error('Nan produced');
  }

  return parsedString;
};

export const buildFullName = (firstName: string | null | undefined, lastName: string | null | undefined): string =>
  [firstName, lastName].filter(Boolean).join(' ');

export const encodeString = (str: string): string => btoa(String.fromCodePoint(...new TextEncoder().encode(str)));
export const decodeString = (str: string): string =>
  new TextDecoder().decode(Uint8Array.from(atob(str), (m) => m.codePointAt(0) || 0));

export const isUrlEncoded = (uri: string): boolean => uri !== decodeURIComponent(uri);

export const fullyDecodeUri = (uri: string): string => {
  let decodedUri = uri;
  while (isUrlEncoded(decodedUri)) {
    decodedUri = decodeURIComponent(decodedUri);
  }
  return decodedUri;
};
