import type { FC } from 'react';
import { useMemo } from 'react';
import { Flex, HStack, Text } from '@chakra-ui/react';
import type { ISupplierInvoice } from '@graneet/business-logic';
import { PriceAdvanced, Tooltip } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { processSupplierInvoiceAmount } from 'features/order/services/order.util';

interface AssociatedSupplierInvoicesAmountsProps {
  supplierInvoices: ISupplierInvoice[];
  amountExVAT: number;
  orderProjectId?: number;
}

export const AssociatedSupplierInvoicesAmounts: FC<AssociatedSupplierInvoicesAmountsProps> = ({
  supplierInvoices,
  amountExVAT,
  orderProjectId,
}) => {
  const { t } = useTranslation(['orders']);

  const supplierInvoicesTotal = useMemo(
    () => supplierInvoices.reduce((acc, cur) => acc + (processSupplierInvoiceAmount(cur, orderProjectId) ?? 0), 0),
    [supplierInvoices, orderProjectId],
  );

  const shiftBetweenOrder = useMemo(() => amountExVAT - supplierInvoicesTotal, [amountExVAT, supplierInvoicesTotal]);

  if (supplierInvoices.length === 0) {
    return null;
  }

  return (
    <Flex w="100%" mt="3" flexDir="column" justifyContent="flex-end" alignItems="flex-end">
      {supplierInvoices.length > 1 && (
        <Flex>
          <Text color="gray.700" fontWeight={400} fontSize="sm">
            {t('orders:associate.amountExVAT')} :{' '}
          </Text>
          <PriceAdvanced
            color="black"
            fontWeight={600}
            fontSize="16px"
            amount={supplierInvoicesTotal}
            ml="1rem"
            as="b"
          />
        </Flex>
      )}

      <Flex>
        <Tooltip shouldWrapChildren label={t('orders:associate.shiftTooltip')}>
          <HStack>
            <Text color="gray.700" fontWeight={400} fontSize="sm">
              {t('orders:associate.shiftWithPlanned')} :{' '}
            </Text>
            <PriceAdvanced color="black" fontWeight={600} fontSize="16px" amount={shiftBetweenOrder} ml="1rem" as="b" />
          </HStack>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
