import { PROJECT_STATUSES } from '@graneet/business-logic';
import type { ButtonColorScheme } from '@graneet/lib-ui';

export const PROJECT_FIELDS = {
  STATUS: 'status',
  NAME: 'name',
  REF_CODE: 'refCode',
  AMOUNT_EX_VAT: 'amountExVAT',
  AMOUNT_TO_BE_BILLED_EX_VAT: 'amountToBeBilledExVAT',
  CUMULATIVE_PROGRESS_PERCENTAGE: 'cumulativeProgressPercentage',
  DEADLINE_TYPE: 'deadlineType',
  PAYMENT_TERM: 'paymentTerm',
  PAYMENT_METHOD: 'paymentMethod',
  PURCHASE_ORDER_NUMBER: 'purchaseOrderNumber',
  NOTE: 'note',
  ADDRESS: 'address',
  CLIENT: 'client',
  PRIMARY_CLIENT: 'primaryClient',
  ACCOUNT_MANAGERS: 'projectAccountManagers',
  COLOR: 'color',
  TAGS: 'tags',
};

export const PROJECT_STATUS_COLOR: Record<PROJECT_STATUSES, ButtonColorScheme> = {
  [PROJECT_STATUSES.IMPORTING]: 'purple',
  [PROJECT_STATUSES.ON_GOING]: 'blue',
  [PROJECT_STATUSES.ENDED]: 'green',
  [PROJECT_STATUSES.ARCHIVED]: 'purple',
};

export enum PROJECT_PLACEHOLDER_KEY {
  INFORMATION_BUTTON = 'projectInformationButton',
}
