import type { IAddress, IOrderItem, ISupplierInvoice, IVatBases, VAT_TYPE } from '@graneet/business-logic';

import type { OrderFilesForm } from '../components/cards/OrderFileReceiptFilesCard/OrderFileReceiptFilesCard';

import type { AddressForm } from 'features/common/components/fields/AddressFields';

export type OrderItemKeys =
  | keyof Pick<IOrderItem, 'code' | 'description' | 'unit' | 'quantity' | 'unitPriceExVAT' | 'vatRate'>
  | 'typeId';
export type OrderItemFieldName<T extends OrderItemKeys> = `order_item_${string}_${T}`;
export const getOrderItemFieldName = <T extends OrderItemKeys>(id: string, key: T): OrderItemFieldName<T> =>
  `order_item_${id}_${key}`;

export interface OrderEditForm<HasUnitPrices extends boolean = boolean> extends AddressForm, Partial<OrderFilesForm> {
  name: string;

  orderNumber?: string;

  typeId?: number;

  orderDate: string;

  deliveryDate?: string;

  note?: string;

  projectId?: number;

  subProjectId?: string | null;

  projectAddress?: IAddress;

  isDirectPayment?: boolean;

  associatedSupplierInvoices: ISupplierInvoice[];

  amountExVAT: number | null;

  vatRate: HasUnitPrices extends false ? number | null : null;

  vatDistribution: HasUnitPrices extends true ? IVatBases | null : null;

  supplierId: number;

  comment: string;

  incompleteItemsError: boolean;

  directPaymentError: boolean;

  [itemCode: OrderItemFieldName<'code'>]: string | null | undefined;

  [itemDescription: OrderItemFieldName<'description'>]: string;

  [itemUnit: OrderItemFieldName<'unit'>]: string | null | undefined;

  [itemQuantity: OrderItemFieldName<'quantity'>]: number | null | undefined;

  [itemUnitPriceExVAT: OrderItemFieldName<'unitPriceExVAT'>]: number | null | undefined;

  [vatRateUnit: OrderItemFieldName<'vatRate'>]: number | null | undefined;

  [typeId: OrderItemFieldName<'typeId'>]: number | undefined;

  hasUnitPrices: HasUnitPrices;

  numberOfIncompleteItems?: number;

  numberOfItems?: number;

  vatType: VAT_TYPE;

  isLedgerEnabled?: boolean;

  isPriceRequest: boolean;
}

export type OrderEditWizard = {
  information: OrderEditForm;
};
