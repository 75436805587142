import type { FC } from 'react';
import { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IOrderItemDTO, IOrderLotDTO } from '@graneet/business-logic';
import { FEATURE_FLAGS } from '@graneet/business-logic';
import type { NodeRelations } from '@graneet/lib-ui';
import {
  ValuesProvider,
  useCurrency,
  useLocalStorage,
  Button,
  DeepTable,
  DragAndDrop,
  DrawersStack,
  Onboarding,
  SimpleAddIcon,
  SimpleLibraryIcon,
  Tree,
  useDeepTable,
  useDrawer,
  useDrawersStack,
} from '@graneet/lib-ui';
import { Flex, HStack, Switch, Text } from '@chakra-ui/react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';

import type { OrderTree } from '../../../hooks/useOrderTree';
import type { OrderEditForm } from '../../../forms/order-edit-wizard';
import { populateOrderTreeFromQuoteComponentsSupplyObjects } from '../../../services/order.util';

import { OrderDeepTableHeader } from './OrderDeepTableHeader';
import { OrderDeepTableLotLine } from './OrderDeepTableLotLine';
import { OrderDeepTableSeparator } from './OrderDeepTableSeparator';
import { OrderDeepTableItemLine } from './OrderDeepTableItemLine';
import { OrderDeepTableFooter } from './OrderDeepTableFooter';
import { OrderAddItemButton } from './OrderAddItemButton';
import { OrderItemBatchActions } from './OrderItemBatchActions';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useComponentTypes } from 'features/component-type/services/component-type.api';

interface OrderOpenItemSelectionButtonProps {
  tree: OrderTree;
}

const useDisplayEmptyState = (tree: OrderTree) => {
  const { rootNodeId } = tree.getInitialTree();
  const [rootLotRelations, setRootLotRelations] = useState<NodeRelations<IOrderLotDTO, IOrderItemDTO>>({
    nodes: [],
    leaves: [],
  });

  useEffect(() => tree.listenToNodeRelations(rootNodeId, setRootLotRelations), [rootNodeId, tree]);

  const hasChildren = rootLotRelations.leaves.length !== 0 || rootLotRelations.nodes.length !== 0;
  return !hasChildren;
};

const DrawerContent: FC<OrderOpenItemSelectionButtonProps> = ({ tree }) => {
  const displayEmptyState = useDisplayEmptyState(tree);

  const hasTypeFF = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_ORDER_ITEM_COMPONENT_TYPE);

  const deepTable = useDeepTable({
    templateColumns: `1rem 3rem 6rem minmax(15rem,1fr) repeat(5,6rem) ${hasTypeFF ? '12rem' : ''} 2rem`,
    leftContentWidth: 0, // Because we don't deal with sub-lots
  });

  return (
    <QueryWrapper>
      {displayEmptyState && (
        <Onboarding
          h="30rem"
          icon={<SimpleLibraryIcon color="gray.100" boxSize={45} />}
          action={<OrderAddItemButton tree={tree} direction="column" />}
        />
      )}

      <ValuesProvider>
        <OrderItemBatchActions tree={tree} />

        <Flex display={!displayEmptyState ? 'block' : 'none'}>
          <DeepTable deepTable={deepTable} noCard>
            <DragAndDrop>
              <Tree
                tree={tree}
                headerComponent={OrderDeepTableHeader}
                nodeComponent={OrderDeepTableLotLine}
                leafComponent={OrderDeepTableItemLine}
                separatorComponent={OrderDeepTableSeparator}
                footerComponent={OrderDeepTableFooter}
              />
            </DragAndDrop>
          </DeepTable>
        </Flex>
      </ValuesProvider>
    </QueryWrapper>
  );
};

interface OrderOpenItemSelectionButtonProps {
  tree: OrderTree;
}

export const OrderOpenItemSelectionButton: FC<OrderOpenItemSelectionButtonProps> = ({ tree }) => {
  const { t } = useTranslation(['global', 'orders']);

  const drawersStack = useDrawersStack<'main'>();

  const components = useComponentTypes();

  const { mapNumberToAmount } = useCurrency();

  const [importedComponents, updateImportedComponents] = useLocalStorage<QuoteComponentsSupplyObject[]>(
    'importedComponents',
    [],
  );

  const drawer = useDrawer(
    'main',
    drawersStack,
    useMemo(
      () => ({
        defaultIsOpen: importedComponents.length > 0,
        onClose: () => {
          updateImportedComponents([]);
        },
      }),
      [importedComponents.length, updateImportedComponents],
    ),
  );

  const form = useFormContext<OrderEditForm>();
  const { hasUnitPrices } = useOnChangeValues(form, ['hasUnitPrices']);
  const onToggleValueChanged = useCallback(() => {
    form.setFormValues({
      hasUnitPrices: !hasUnitPrices,
    });

    // Trick: when switch value is updated, condition to know if item is complete change. By doing the following code
    // we recompute all item computed value.
    tree.updateAllChildrenLeavesOfNode(tree.getInitialTree().rootNodeId, {});
  }, [form, hasUnitPrices, tree]);

  const hasRunRef = useRef(false);
  if (!hasRunRef.current) {
    populateOrderTreeFromQuoteComponentsSupplyObjects(
      importedComponents,
      form,
      tree,
      mapNumberToAmount,
      components.data,
    );
    hasRunRef.current = true;
  }

  return (
    <>
      <Button leftIcon={<SimpleAddIcon />} variant="outline" colorScheme="gray" onClick={drawer.onOpen}>
        {t('orders:actions.addItems')}
      </Button>

      <DrawersStack drawersStack={drawersStack}>
        <DrawersStack.Drawer
          title={t('orders:orderItemModal.title')}
          drawer={drawer}
          footer={<Button onClick={drawer.onClose}>{t('global:words.c.validate')}</Button>}
          size="2xl"
        >
          <HStack>
            <Text mb={4} flexGrow={1}>
              {t('orders:orderItemModal.description')}
            </Text>

            <Flex>
              <Text fontSize="sm" fontWeight={hasUnitPrices ? '500' : '600'} minW="4.5rem">
                {t('orders:orderItemModal.withoutUnitPrice')}
              </Text>

              <Switch mx={3} colorScheme="blue" onChange={onToggleValueChanged} defaultChecked={hasUnitPrices} />

              <Text fontSize="sm" fontWeight={hasUnitPrices ? '600' : '500'} minW="4.5rem">
                {t('orders:orderItemModal.withUnitPrice')}
              </Text>
            </Flex>
          </HStack>
          <DrawerContent tree={tree} />
        </DrawersStack.Drawer>
      </DrawersStack>
    </>
  );
};
