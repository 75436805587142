import { useCallback } from 'react';
import type { IUserSettings } from '@graneet/business-logic';
import type { QuoteSettingDTO } from '@org/graneet-bff-client';

import {
  updateQuoteHiddenCostColumns,
  updateQuoteRootColumns,
  updateQuoteRowHeight,
  updateQuoteSetting,
  updateQuoteSummaryBarItemDisplayed,
} from '../store/quoteUpdateZustand';
import type { QuoteColumns } from '../store/quoteSettingSliceZustant';
import {
  defaultQuoteRootColumns,
  defaultQuoteHiddenCostColumns,
  defaultQuoteSummaryBarItemDisplayed,
} from '../store/quoteSettingSliceZustant';

import type { QuoteSummaryDisplayItems } from 'features/quotation/ag-grid-quote/components/QuoteSummaryBar';

export function useQuoteSettingsSetToStore() {
  return useCallback((userSettings: IUserSettings, quoteSetting: QuoteSettingDTO) => {
    if (userSettings.quoteSummaryBarItemDisplayedJSON) {
      const quoteSummaryBarItemDisplayed: QuoteSummaryDisplayItems = JSON.parse(
        userSettings.quoteSummaryBarItemDisplayedJSON,
      );

      defaultQuoteSummaryBarItemDisplayed.forEach((defaultColumn) => {
        if (!quoteSummaryBarItemDisplayed.find((column) => column.name === defaultColumn.name)) {
          quoteSummaryBarItemDisplayed.push(defaultColumn);
        }
      });

      updateQuoteSummaryBarItemDisplayed(JSON.stringify(quoteSummaryBarItemDisplayed));
    }

    if (userSettings.quoteHiddenCostColumnsJSON) {
      const quoteHiddenCostColumns: QuoteColumns = JSON.parse(userSettings.quoteHiddenCostColumnsJSON);

      defaultQuoteHiddenCostColumns.forEach((defaultColumn) => {
        if (!quoteHiddenCostColumns.find((column) => column.name === defaultColumn.name)) {
          quoteHiddenCostColumns.push(defaultColumn);
        }
      });

      updateQuoteHiddenCostColumns(JSON.stringify(quoteHiddenCostColumns));
    }

    if (userSettings.quoteRootColumnsJSON) {
      const quoteRootColumns: QuoteColumns = JSON.parse(userSettings.quoteRootColumnsJSON);

      defaultQuoteRootColumns.forEach((defaultColumn) => {
        if (!quoteRootColumns.find((column) => column.name === defaultColumn.name)) {
          quoteRootColumns.push(defaultColumn);
        }
      });

      updateQuoteRootColumns(JSON.stringify(quoteRootColumns));
    }

    if (userSettings.quoteRowHeight) {
      updateQuoteRowHeight(userSettings.quoteRowHeight);
    }

    updateQuoteSetting(quoteSetting);
  }, []);
}
