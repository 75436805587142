import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ORDER_QUERY, DIRECTION_QUERY } from '../../PaginatedData/constants';

export interface PaginationFilters {
  direction: string | null;

  order: string | null;
}

export const usePaginationFilters = (storagePagination: URLSearchParams | null): PaginationFilters => {
  const { search } = useLocation();
  const queryFromURL = useMemo(() => new URLSearchParams(search), [search]);
  const query = storagePagination || queryFromURL;

  const order = query.get(ORDER_QUERY);
  const direction = query.get(DIRECTION_QUERY);

  return useMemo(() => ({ direction, order }), [direction, order]);
};
