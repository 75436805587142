import { useTranslation } from 'react-i18next';
import { Fieldset } from '@graneet/lib-ui';
import { Checkbox } from '@chakra-ui/react';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import type { ProjectCreateForm } from '../../forms/project-create-wizard';

import { AddressFields } from 'features/common/components/fields/AddressFields';

export const ProjectAddressFieldset: FC = () => {
  const { t } = useTranslation(['project', 'quote']);
  const form = useFormContext<ProjectCreateForm>();
  const { setFormValues } = form;
  const [isSameProjectAddressAsClient, setIsSameProjectAddressAsClient] = useState(false);

  const { primaryClient } = useOnChangeValues(form, ['primaryClient']);

  // Binds client address to project address
  useEffect(() => {
    if (isSameProjectAddressAsClient && primaryClient) {
      setFormValues({
        projectAddressAddress: primaryClient.address.address,
        projectAddressPostalCode: primaryClient.address.postalCode,
        projectAddressCity: primaryClient.address.city,
        projectAddressCountry: primaryClient.address.country,
      });
    }
  }, [isSameProjectAddressAsClient, primaryClient, setFormValues]);

  const toggleSameProjectAddressAsClient = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setIsSameProjectAddressAsClient(evt.target.checked);
    },
    [setIsSameProjectAddressAsClient],
  );

  return (
    <Fieldset<ProjectCreateForm>
      handle="projectAddress"
      title={t('project:flow.address.title')}
      legend={t('project:flow.address.legend')}
      validationNames={['projectAddressAddress']}
    >
      <Checkbox
        data-testid="checkbox:project.addressSameAsCompany"
        colorScheme="greenBrand"
        isChecked={isSameProjectAddressAsClient}
        onChange={toggleSameProjectAddressAsClient}
        mb={4}
      >
        {t('quote:informationStep.address.sameAsClient')}
      </Checkbox>

      <AddressFields
        data-testid="fields:project.address"
        prefix="projectAddress"
        isDisabled={isSameProjectAddressAsClient}
      />
    </Fieldset>
  );
};
