import { useCallback } from 'react';
import { Onboarding, Button, Table, ModalSubtitle, SimpleAddIcon, SimplePuzzleIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Center, HStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { bool } from 'prop-types';

import { useComponents } from '../hooks/useComponents';
import { useCreateComponent } from '../hooks/useCreateComponent';

import { QuoteComponentEditionRow } from './QuoteComponentEditionRow';

import { Loading } from 'features/common/components/Loading';

const templateColumns = ['13rem', '6rem', '1fr', '4rem', '6rem', '7rem', '9rem', '5rem', '8rem', '3rem'];
const templateColumnsHiddenCost = ['13rem', '6rem', '1fr', '4rem', '6rem', '7rem', '9rem', '3rem'];

export const QuoteComponentEditionTable = ({ isReadOnly, isHiddenCost }) => {
  const history = useHistory();
  const { t } = useTranslation(['quote']);

  const components = useComponents();
  const createComponent = useCreateComponent();

  const onImportComponent = useCallback(() => {
    history.push('/import');
  }, [history]);

  if (!components) {
    return <Loading />;
  }

  const actionButtons = !isReadOnly && (
    <HStack>
      <Button leftIcon={<SimpleAddIcon />} variant="outline" onClick={createComponent}>
        {t('quote:jobEdition.createComponent')}
      </Button>

      <Button leftIcon={<SimpleAddIcon />} variant="outline" onClick={onImportComponent}>
        {t('quote:jobEdition.importComponent')}
      </Button>
    </HStack>
  );

  if (components.length === 0) {
    return !isReadOnly ? (
      <>
        <ModalSubtitle mt={8}>{t('quote:jobEdition.componentList')}</ModalSubtitle>
        <Onboarding icon={<SimplePuzzleIcon boxSize={45} mb={0} color="gray.100" />} action={actionButtons} h="auto" />
      </>
    ) : null;
  }

  return (
    <>
      <ModalSubtitle pt={8}>{t('quote:jobEdition.componentList')}</ModalSubtitle>
      <Box>
        <Table templateColumns={isHiddenCost ? templateColumnsHiddenCost : templateColumns} noShadow overflow="visible">
          <Table.Header>
            <Table.Cell>
              {/* Center label align with component type field  */}
              <Center mr={4} w="100%">
                {t('quote:component.fields.componentType')}
              </Center>
            </Table.Cell>
            <Table.Cell center>{t('quote:component.fields.code')}</Table.Cell>
            <Table.Cell>{t('quote:component.fields.name')}</Table.Cell>
            <Table.Cell center>{t('quote:component.fields.unit')}</Table.Cell>
            <Table.Cell center>{t('quote:component.fields.quantity')}</Table.Cell>
            <Table.Cell right>{t('quote:component.fields.unitDisbursementExVAT')}</Table.Cell>
            <Table.Cell right>{t('quote:component.fields.disbursementExVAT')}</Table.Cell>
            {!isHiddenCost ? <Table.Cell center>{t('quote:component.fields.totalMargin')}</Table.Cell> : null}
            {!isHiddenCost ? <Table.Cell right>{t('quote:component.fields.amountExVAT')}</Table.Cell> : null}
            <Table.Cell />
          </Table.Header>
          {(components || []).map((component, index) => (
            <QuoteComponentEditionRow
              key={component.id}
              component={component}
              isLastRow={components.length - 1 === index}
              isReadOnly={isReadOnly}
              isHiddenCost={isHiddenCost}
            />
          ))}
        </Table>
        <Box pt={2} mb={2}>
          {actionButtons}
        </Box>
      </Box>
    </>
  );
};

QuoteComponentEditionTable.propTypes = {
  isReadOnly: bool,
  isHiddenCost: bool,
};

QuoteComponentEditionTable.defaultProps = {
  isReadOnly: false,
  isHiddenCost: false,
};
