import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ViewCompanySettings } from './company/ViewCompanySettings';
import { ViewUserSettings } from './user/ViewUserSettings';

export const SettingsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/company`}>
        <ViewCompanySettings />
      </Route>

      <Route path={`${path}/user`}>
        <ViewUserSettings />
      </Route>
    </Switch>
  );
};
