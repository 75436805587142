import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// English translations
import accountingEn from './en/accounting.json';
import accountManagerEn from './en/accountManager.json';
import billEn from './en/bill.json';
import clientsEn from './en/clients.json';
import companyEn from './en/company.json';
import componentTypeEn from './en/componentType.json';
import containerEn from './en/container.json';
import contractsEn from './en/contracts.json';
import creditEn from './en/credit.json';
import customDiscountEn from './en/customDiscount.json';
import dashboardEn from './en/dashboard.json';
import directProgressStatementEn from './en/directProgressStatement.json';
import discountEn from './en/discount.json';
import downPaymentEn from './en/downPayment.json';
import globalEn from './en/global.json';
import holdbackEn from './en/holdback.json';
import invoiceEn from './en/invoice.json';
import ledgerEn from './en/ledger.json';
import libraryEn from './en/library.json';
import mailingEn from './en/mailing.json';
import marginEn from './en/margin.json';
import ordersEn from './en/orders.json';
import paymentMethodsEn from './en/paymentMethods.json';
import pdfSettingsEn from './en/pdfSettings.json';
import progressStatementEn from './en/progressStatement.json';
import projectEn from './en/project.json';
import quoteEn from './en/quote.json';
import statementEn from './en/statement.json';
import supplierEn from './en/supplier.json';
import supplierInvoicesEn from './en/supplierInvoices.json';
import timeTrackingEn from './en/timeTracking.json';
import userEn from './en/user.json';
import workerEn from './en/worker.json';
import cashOutflowEn from './en/cashOutFlow.json';
import subProjectEn from './en/subProject.json';
import historyEn from './en/history.json';
import planningEn from './en/planning.json';
import validationStepEn from './en/validationStep.json';
import onboardingEn from './en/onboarding.json';
import contactEn from './en/contacts.json';
import penaltyEn from './en/penalty.json';
import bankingEn from './en/banking.json';
// French translations
import accountingFr from './fr/accounting.json';
import accountManagerFr from './fr/accountManager.json';
import billFr from './fr/bill.json';
import clientsFr from './fr/clients.json';
import companyFr from './fr/company.json';
import componentTypeFr from './fr/componentType.json';
import containerFr from './fr/container.json';
import contractsFr from './fr/contracts.json';
import creditFr from './fr/credit.json';
import customDiscountFr from './fr/customDiscount.json';
import dashboardFr from './fr/dashboard.json';
import directProgressStatementFr from './fr/directProgressStatement.json';
import discountFr from './fr/discount.json';
import downPaymentFr from './fr/downPayment.json';
import globalFr from './fr/global.json';
import holdbackFr from './fr/holdback.json';
import invoiceFr from './fr/invoice.json';
import ledgerFr from './fr/ledger.json';
import libraryFr from './fr/library.json';
import mailingFr from './fr/mailing.json';
import marginFr from './fr/margin.json';
import ordersFr from './fr/orders.json';
import paymentMethodsFr from './fr/paymentMethods.json';
import pdfSettingsFr from './fr/pdfSettings.json';
import progressStatementFr from './fr/progressStatement.json';
import projectFr from './fr/project.json';
import quoteFr from './fr/quote.json';
import statementFr from './fr/statement.json';
import supplierFr from './fr/supplier.json';
import supplierInvoicesFr from './fr/supplierInvoices.json';
import timeTrackingFr from './fr/timeTracking.json';
import userFr from './fr/user.json';
import workerFr from './fr/worker.json';
import cashOutflowFr from './fr/cashOutFlow.json';
import subProjectFr from './fr/subProject.json';
import onboardingFr from './fr/onboarding.json';
import contactFr from './fr/contacts.json';
import penaltyFr from './fr/penalty.json';
import historyFr from './fr/history.json';
import planningFr from './fr/planning.json';
import validationStepFr from './fr/validationStep.json';
import bankingFr from './fr/banking.json';

export const defaultNS = 'global';

const frenchTranslations = {
  accounting: accountingFr,
  accountManager: accountManagerFr,
  bill: billFr,
  clients: clientsFr,
  company: companyFr,
  componentType: componentTypeFr,
  container: containerFr,
  contracts: contractsFr,
  credit: creditFr,
  customDiscount: customDiscountFr,
  dashboard: dashboardFr,
  directProgressStatement: directProgressStatementFr,
  discount: discountFr,
  downPayment: downPaymentFr,
  global: globalFr,
  holdback: holdbackFr,
  invoice: invoiceFr,
  ledger: ledgerFr,
  library: libraryFr,
  mailing: mailingFr,
  margin: marginFr,
  orders: ordersFr,
  paymentMethods: paymentMethodsFr,
  pdfSettings: pdfSettingsFr,
  progressStatement: progressStatementFr,
  project: projectFr,
  quote: quoteFr,
  statement: statementFr,
  supplier: supplierFr,
  supplierInvoices: supplierInvoicesFr,
  timeTracking: timeTrackingFr,
  user: userFr,
  worker: workerFr,
  cashOutflow: cashOutflowFr,
  subProject: subProjectFr,
  onboarding: onboardingFr,
  contacts: contactFr,
  penalty: penaltyFr,
  history: historyFr,
  planning: planningFr,
  validationStep: validationStepFr,
  banking: bankingFr,
};

type Translations = typeof frenchTranslations;

export const resources: Record<'fr' | 'en', Translations> = {
  fr: frenchTranslations,
  en: {
    accounting: accountingEn,
    accountManager: accountManagerEn,
    bill: billEn,
    clients: clientsEn,
    company: companyEn,
    componentType: componentTypeEn,
    container: containerEn,
    contracts: contractsEn,
    credit: creditEn,
    customDiscount: customDiscountEn,
    dashboard: dashboardEn,
    directProgressStatement: directProgressStatementEn,
    discount: discountEn,
    downPayment: downPaymentEn,
    global: globalEn,
    holdback: holdbackEn,
    invoice: invoiceEn,
    ledger: ledgerEn,
    library: libraryEn,
    mailing: mailingEn,
    margin: marginEn,
    orders: ordersEn,
    paymentMethods: paymentMethodsEn,
    pdfSettings: pdfSettingsEn,
    progressStatement: progressStatementEn,
    project: projectEn,
    quote: quoteEn,
    statement: statementEn,
    supplier: supplierEn,
    supplierInvoices: supplierInvoicesEn,
    timeTracking: timeTrackingEn,
    user: userEn,
    worker: workerEn,
    cashOutflow: cashOutflowEn,
    subProject: subProjectEn,
    onboarding: onboardingEn,
    contacts: contactEn,
    penalty: penaltyEn,
    history: historyEn,
    planning: planningEn,
    validationStep: validationStepEn,
    banking: bankingEn,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS,
    fallbackNS: defaultNS,
    resources,
    compatibilityJSON: 'v3',
    supportedLngs: ['fr', 'en'],
    nonExplicitSupportedLngs: false,
    fallbackLng: 'fr',
    interpolation: {
      skipOnVariables: false,
      escapeValue: false,
    },
  });

export default i18n;
