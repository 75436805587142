import type { FC } from 'react';
import { useMemo, useCallback, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Badge, SettingsMenu } from '@graneet/lib-ui';

import { BankingConnectionSynchronisationIssueCallout } from '../../features/banking/components/BankingConnectionSynchronisationIssueCallout';

import { BankingInOutTransactionsCard } from 'features/banking/components/BankingInOutTransactionsCard';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';
import { BankingAccountBalanceCard } from 'features/banking/components/BankingAccountBalanceCard';
import { useBankingConnections } from 'features/banking/services/banking-connection.api';

export const OverviewBanking: FC = () => {
  const { t } = useTranslation(['global', 'banking']);

  const bankingConnections = useBankingConnections();

  const [selectedBankingConnectionIds, setSelectedBankingConnectionIds] = useState(
    bankingConnections.data.flatMap((d) => d.bankingAccounts.map((v) => v.id)),
  );

  const onChange = useCallback(
    (name: string, value: boolean) => {
      setSelectedBankingConnectionIds((prevValue) => {
        const bankingConnection = bankingConnections.data.find((v) => v.id === name);

        const nameToHandle = bankingConnection
          ? bankingConnection.bankingAccounts.map((bankingAccount) => bankingAccount.id)
          : [name];

        if (value) {
          return [...prevValue, ...nameToHandle];
        }
        return [...prevValue].filter((v) => !nameToHandle.includes(v));
      });
    },
    [bankingConnections.data],
  );

  const { updateHeaderTitle } = useHeaderContext();
  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.bankOverview'), []);
  }, [t, updateHeaderTitle]);

  const groups = useMemo(
    () => [
      {
        items: bankingConnections.data.map((bankingConnection) => ({
          name: bankingConnection.id,
          label: bankingConnection.name,
          value: true,
          items: bankingConnection.bankingAccounts.map((bankingAccount) => ({
            name: bankingAccount.id,
            label: bankingAccount.name,
            value: selectedBankingConnectionIds.some((id) => id === bankingAccount.id),
          })),
        })),
      },
    ],
    [bankingConnections.data, selectedBankingConnectionIds],
  );

  return (
    <Flex px={8} gap={4} direction="column">
      <BankingConnectionSynchronisationIssueCallout bankingConnections={bankingConnections.data} />

      <Box>
        <SettingsMenu
          isEditable
          variant="secondary"
          icon={
            <Flex gap={2}>
              <i className="ri-filter-3-line" />
              <Text>{t('banking:bankingAccount.name')}</Text>
              <Badge colorScheme="gray">{selectedBankingConnectionIds.length}</Badge>
            </Flex>
          }
          placement="bottom-end"
          groups={groups}
          onChange={onChange}
          size="md"
        />
      </Box>

      <BankingAccountBalanceCard selectedBankingConnectionIds={selectedBankingConnectionIds} />
      <BankingInOutTransactionsCard selectedBankingConnectionIds={selectedBankingConnectionIds} />
    </Flex>
  );
};
