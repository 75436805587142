import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleSearchIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 14 14" stroke="#6C737F" {...props} fill="none">
    <path
      d="M12.25 12.25L10.2084 10.2083M11.6667 6.70833C11.6667 9.44673 9.44673 11.6667 6.70833 11.6667C3.96992 11.6667 1.75 9.44673 1.75 6.70833C1.75 3.96992 3.96992 1.75 6.70833 1.75C9.44673 1.75 11.6667 3.96992 11.6667 6.70833Z"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
