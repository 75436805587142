import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { KeysMatching, SingleSelectFieldProps } from '@graneet/lib-ui';
import { SingleSelectField } from '@graneet/lib-ui';
import type { FieldValues } from 'graneet-form';

import { useAccountingConfigs } from '../services/accounting-config.api';

type Value = string | undefined | null;

interface AccountingConfigPurchasesAccountFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, Value> = KeysMatching<T, Value>,
> extends Omit<SingleSelectFieldProps<T, K>, 'options'> {
  label?: string;

  children?: ReactNode;
}

export const AccountingConfigPurchasesAccountField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, Value> = KeysMatching<T, Value>,
>({
  name,
  label,
  children,
  ...otherProps
}: AccountingConfigPurchasesAccountFieldProps<T, K>) => {
  const accountingConfig = useAccountingConfigs();
  const options = useMemo(
    () =>
      (accountingConfig.data?.purchasesAccounts ?? [])
        .sort((a, b) => a.account?.localeCompare(b.account ?? '') ?? -1)
        .map((purchasesAccount) => ({
          label: [purchasesAccount.account, purchasesAccount.name].filter((v) => v !== null).join(' - '),
          value: purchasesAccount.id,
        })),
    [accountingConfig.data],
  );

  return (
    <SingleSelectField<T, K> name={name} options={options as any} label={label} {...otherProps}>
      {children}
    </SingleSelectField>
  );
};
