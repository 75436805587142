import { useCallback, useMemo, useRef } from 'react';

import type { KeyOfRecord } from '../../utils/type.util';

import { Signal } from './Signal';

type SignalType<T extends Signal<any>> = T extends Signal<infer U> ? U : never;

/**
 * Allows a component to emit and listen to multiple signals
 * each sandboxed by a "key".
 *
 * It becomes really powerful when combined with React context,
 * ref and state apis, offering a fine-tuned control over the
 * rendering of components only when their data actually changes
 *
 * @example
 * ```
 * const { emit, listen } = useSignals();
 *
 * listen('name-update', (name) => {
 *   console.log(`Hi, my name is ${name}`)
 *   setSomeState(name) // Will trigger a render
 * });
 *
 * emit('name-update', 'Rob');
 * // => "Hi, my name is Rob"
 *
 * emit('cat-update', 'Felix');
 * // => Nothing.
 * ```
 */
export const useSignals = <T extends Record<string, Signal<any>>>() => {
  const { current: signals } = useRef<Partial<T>>({});

  const ignore = useCallback(
    <K extends KeyOfRecord<T>, TSignal extends SignalType<T[K]>>(key: K, listener: (newValue: TSignal) => void) => {
      if (signals[key]) {
        signals[key]?.ignore(listener);
      }
    },
    [signals],
  );

  const listen = useCallback(
    <K extends KeyOfRecord<T>, TSignal extends SignalType<T[K]>>(
      key: K,
      listener: (newValue: TSignal) => void,
      messageData?: TSignal,
    ) => {
      let signal = signals[key] as Signal<TSignal>;
      if (!signal) {
        signal = new Signal<TSignal>();
        signals[key] = signal as T[K];
      }
      signal.listen(listener, messageData);
      return () => ignore(key, listener);
    },
    [ignore, signals],
  );

  const emit = useCallback(
    <K extends KeyOfRecord<T>, TSignal extends SignalType<T[K]>>(key: K, messageData: TSignal) => {
      if (signals[key]) {
        signals[key]?.emit(messageData);
      }
    },
    [signals],
  );

  return useMemo(
    () => ({
      listen,
      emit,
    }),
    [emit, listen],
  );
};
