import { Link, Logo } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ExternalPoweredBy: FC = () => {
  const { t } = useTranslation(['global']);

  return (
    <Link target="_blank" to={{ pathname: 'https://www.graneet.com' }} color="gray.400" fontSize="xs">
      {t('global:poweredBy')}
      <Logo color="gray.400" verticalAlign="middle" ml={1} h={3.5} display="inline" w="auto" />
    </Link>
  );
};
