import { forwardRef } from 'react';

import type { DefaultInputProps } from './DefaultInput';
import { DefaultInput } from './DefaultInput';
import { OutlineInput } from './OutlineInput';

export interface InputProps extends DefaultInputProps {
  variant?: 'default' | 'outline';

  isReadOnly?: boolean;

  multiline?: boolean;
}

/*
  Be careful, variant "outline" has less feature than the default input variant: see OutlineInput file.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(({ variant = 'default', ...otherProps }, ref) => {
  if (variant === 'outline') {
    return <OutlineInput {...otherProps} ref={ref} />;
  }

  return <DefaultInput {...otherProps} ref={ref} />;
});
