import { MARGIN_TYPE, QuoteMargin } from '@org/quotation-lib';
import Big from 'big.js';

import type { QuoteLotUpdateMarginWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import {
  quoteClientRequestsSetToStore,
  StatusEnum,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';

export const createQuoteLotUpdatedMarginCallback = (message: QuoteLotUpdateMarginWsMessage) => {
  const quote = quoteGetFromStore();
  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();

  if (!quote) {
    return;
  }

  const hasClientRequest = quoteClientRequestsFromStore.find(
    (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
  );

  if (hasClientRequest !== undefined) {
    quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
    return;
  }

  const { margins, id } = message.data;
  const quoteLot = quote?.getTree().getQuoteLotOrThrow(id);
  const oldMargin = new QuoteMargin(quoteLot.getMargin().export());

  if (!Big(margins.overheadCosts).eq(oldMargin.getOverheadCosts())) {
    const newTotalMargin = new Big(oldMargin.getProfitMargin()).mul(Big(margins.overheadCosts));
    quoteLot.updateTotalMargin(newTotalMargin, {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.OVERHEAD_COSTS,
    });
    quoteSetToStore(quote);
    return;
  }

  if (!Big(margins.profitMargin).eq(oldMargin.getProfitMargin())) {
    const newTotalMargin = new Big(margins.profitMargin).mul(oldMargin.getOverheadCosts());
    quoteLot.updateTotalMargin(newTotalMargin, {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });
    quoteSetToStore(quote);
    return;
  }

  if (!Big(margins.totalMargin).eq(oldMargin.getTotalMargin())) {
    quoteLot.updateTotalMargin(Big(margins.totalMargin), {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });
    quoteSetToStore(quote);
  }
};
