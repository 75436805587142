import type { QuoteExportsApi, QuoteGenerateExportDTO } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteExportsProxyApi {
  private static quoteExportsProxyApi: QuoteExportsProxyApi;

  private readonly quoteExportsApi: QuoteExportsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteExportsApi = externalApi.getQuoteExportsApi();
  }

  public static getQuoteExportsProxyApi(externalApi: ExternalApi): QuoteExportsProxyApi {
    if (!this.quoteExportsProxyApi) {
      QuoteExportsProxyApi.quoteExportsProxyApi = new QuoteExportsProxyApi(externalApi);
    }
    return QuoteExportsProxyApi.quoteExportsProxyApi;
  }

  public async quoteExportSpreadsheet(quoteGenerateExportDTO: QuoteGenerateExportDTO) {
    return proxyHelper(async () => this.quoteExportsApi.quoteExportSpreadsheet(quoteGenerateExportDTO));
  }

  public async quoteExportPDF(quoteGenerateExportDTO: QuoteGenerateExportDTO) {
    return proxyHelper(async () => this.quoteExportsApi.quoteExportPDF(quoteGenerateExportDTO));
  }
}
