import type { IProject, IProjectFinancialSummary } from '@graneet/business-logic';
import type { FC } from 'react';
import { Progress } from '@graneet/lib-ui';
import { isProjectActive } from '@graneet/business-logic';

import { ProjectStatusBadge } from 'features/project/components/ProjectStatusBadge';

// @[ff: project-financial-summary]
export const ProjectTableProgressCell: FC<{ data?: IProjectFinancialSummary | IProject }> = ({ data }) => {
  if (!data) return '';
  return isProjectActive(data) ? (
    <Progress value={data.cumulativeProgressPercentage} />
  ) : (
    <ProjectStatusBadge status={data.status} ml={2} />
  );
};
