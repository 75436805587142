import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { DateField, DroppableFileField, getCurrentDateAsString, Modal } from '@graneet/lib-ui';
import { Text, VStack } from '@chakra-ui/react';
import type { IProject } from '@graneet/business-logic';
import { SUB_PROJECT_BILLING_TYPE } from '@graneet/business-logic';

import { useProjectClose } from '../../services/project.api';

import { Rule } from 'features/form/rules/Rule';

interface CloseProjectModalProps {
  project: IProject;

  hasProgressBilling: boolean;

  isOpen: boolean;

  onClose(): void;
}

interface CloseProjectForm {
  endDate: string;

  acceptanceReport?: File;
}

export const CloseProjectModal: FC<CloseProjectModalProps> = ({ project, hasProgressBilling, isOpen, onClose }) => {
  const { t } = useTranslation(['project']);

  const form = useForm<CloseProjectForm>({
    defaultValues: {
      endDate: getCurrentDateAsString(),
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const projectCloseMutation = useProjectClose();

  const handleSubmit = useCallback(() => {
    const { endDate, acceptanceReport } = form.getFormValues();

    projectCloseMutation.mutate(
      {
        project,
        dto: {
          endDate: endDate!,
          acceptanceReport,
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  }, [form, projectCloseMutation, project, onClose]);

  return (
    <Modal
      size="3xl"
      title={t('project:summary.close.modal.title', { name: project.name })}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
    >
      <Form form={form}>
        <VStack spacing={6} align="flex-start">
          <Text>
            {t(
              `project:summary.close.modal.legend.${
                hasProgressBilling ? SUB_PROJECT_BILLING_TYPE.PROGRESS : SUB_PROJECT_BILLING_TYPE.DIRECT
              }`,
            )}
          </Text>

          <Text>{t('project:summary.close.modal.instruction')}</Text>

          <DateField<CloseProjectForm>
            name="endDate"
            label={t('project:summary.close.modal.endDate')}
            isRequired
            mr={6}
          >
            <Rule.IsRequired />
          </DateField>

          <DroppableFileField<CloseProjectForm>
            label={t('project:summary.close.modal.fileLabel')}
            accept="application/pdf"
            name="acceptanceReport"
          />
        </VStack>
      </Form>

      <Modal.SecondaryButton onClick={onClose}>{t('project:summary.close.modal.back')}</Modal.SecondaryButton>

      <Modal.PrimaryButton isLoading={projectCloseMutation.isPending} isDisabled={!isFormValid} onClick={handleSubmit}>
        {t('project:actions.close')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
