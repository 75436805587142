import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import isIBAN from 'validator/es/lib/isIBAN';

import { isRequired } from './validation.util';

const VALIDATE_IBAN = (value: unknown) => !isRequired(value) || (typeof value === 'string' && isIBAN(value));

export const IsIban = () => {
  const { t } = useTranslation(['global']);

  return <Rule message={t('global:form.errors.invalidIban')} validationFn={VALIDATE_IBAN} />;
};
