import type { FC } from 'react';
import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useContactAssociatedToProject } from 'features/contact/services/contact.api';
import { CreditDetail } from 'features/credit/components/CreditDetail';
import { useCredit } from 'features/credit/services/credit.api';
import { useProjectOrUndefined } from 'features/project/services/project.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewCreditDetailScreen: FC = () => {
  const { creditId } = useParams<{ creditId: string }>();
  const { t } = useTranslation(['statement', 'global', 'credit']);
  const { updateHeaderTitle } = useHeaderContext();

  const credit = useCredit(parseInt(creditId, 10));
  const project = useProjectOrUndefined(credit.data?.project?.id);
  const projectContacts = useContactAssociatedToProject(credit.data?.project?.id);

  useLayoutEffect(() => {
    updateHeaderTitle(credit.data.name ?? '', [
      {
        name: t('statement:title'),
        link: '/sales/statements',
      },
    ]);
  }, [t, credit.data.name, updateHeaderTitle]);

  return (
    <CreditDetail
      credit={credit.data}
      project={project.data}
      projectContacts={projectContacts.data}
      displayAssociatedProject
    />
  );
};
