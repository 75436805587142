import { useCallback, type FC, type ReactElement, type ReactNode } from 'react';
import type { BoxProps } from '@chakra-ui/react';
import { Box, Flex, HStack, MenuButton } from '@chakra-ui/react';

import { SimpleChevronBottomIcon } from '../Icons';

import { RichSelectContent } from './RichSelectContent';
import { useRichSelectContext } from './useRichSelectContext.hook';

export interface RichSelectMenuButtonProps extends BoxProps {
  label: ReactNode;

  children?: ReactElement[] | ReactNode;

  onClick?(): void;

  rightIcon?: ReactElement | null;

  leftIcon?: ReactElement | null;
}

const STYLE = {
  sm: {
    fontSize: 'smaller',
  },
  md: {
    fontSize: 'sm',
  },
};

export const RichSelectMenuButton: FC<RichSelectMenuButtonProps> = ({
  label,
  children,
  onClick,
  rightIcon,
  leftIcon,
  ...otherProps
}) => {
  const { isOpen, isDisabled, hasError, size, onOpen } = useRichSelectContext();

  const opacity = isDisabled ? 0.5 : 1;
  const cursor = isDisabled ? 'not-allowed' : 'pointer';

  const handleOnClick = useCallback(() => {
    onOpen();
  }, [onOpen]);

  return (
    <Box
      h="100%"
      px={1}
      _hover={{ bg: hasError ? undefined : 'gray.50', border: 'none' }}
      cursor={cursor}
      bg={isDisabled ? 'gray.50' : undefined}
      borderRadius="8px"
      {...otherProps}
    >
      <HStack w="100%" opacity={opacity} mr={1} spacing={2} onClick={onClick} height="100%">
        <MenuButton as={Flex} flexDirection="row" flexGrow={1} w="100%" height="100%">
          <RichSelectContent
            text={label}
            leftIcon={leftIcon || undefined}
            rightIcon={
              rightIcon !== null
                ? rightIcon || (
                    <SimpleChevronBottomIcon transform={isOpen ? 'rotate(-180deg)' : 'none'} strokeWidth={1} />
                  )
                : undefined
            }
            fontSize={STYLE[size].fontSize}
          />
        </MenuButton>
        <Box onClick={handleOnClick}>{children}</Box>
      </HStack>
    </Box>
  );
};
