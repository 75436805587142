import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import type { RenderActionsProps } from '@graneet/lib-ui';
import { Badge, SingleSelect, Tooltip, useToast } from '@graneet/lib-ui';
import {
  FEATURE_FLAGS,
  FILTERING_PARAMS,
  getNextStatusesAllowedForBatchActions,
  SUPPLIER_INVOICE_STATUS,
} from '@graneet/business-logic';
import { countBy } from 'lodash-es';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import { SupplierInvoiceUpdateAtToPayStatusModal } from './SupplierInvoiceUpdateAtToPayStatusModal';

import {
  useSupplierInvoiceUpdateStatusesToPay,
  useSupplierInvoiceUpdateStatusesToProcess,
} from 'features/supplier-invoice/services/supplier-invoice.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useGetValidationStepsSupplierInvoice } from 'features/validation-step/services/validation-step-supplier-invoice.api';
import { SupplierInvoiceStatusBadge } from 'features/supplier-invoice/components/badges/SupplierInvoicesStatusBadge';
import { SUPPLIER_INVOICES_STATUS_COLOR } from 'features/supplier-invoice/constants/supplier-invoice.constant';
import type { SelectedItems } from 'features/export/components/ExportButton/ExportButton';

export interface SupplierInvoiceBatchStatusActionsProps extends RenderActionsProps<SelectedItems['SUPPLIER_INVOICE']> {
  onStatusesChanged(): Promise<void> | void;
}

export const SupplierInvoiceBatchStatusActions: FC<SupplierInvoiceBatchStatusActionsProps> = ({
  selectedItems,
  resetSelectedCheckboxes,
  hasAllCheckboxesSelected,
  currentFilters,
  onStatusesChanged,
  totalCount,
}) => {
  const hasHiddenItems = hasAllCheckboxesSelected && (totalCount === undefined || totalCount > selectedItems.length);
  const toast = useToast();
  const { t } = useTranslation(['supplierInvoices', 'global', 'validationStep']);
  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);

  const orderedValidationSteps = useGetValidationStepsSupplierInvoice();

  const { mutateAsync: supplierInvoiceUpdateStatusesToPayMutateAsync, isPending: isToPayMutationPending } =
    useSupplierInvoiceUpdateStatusesToPay();
  const { mutateAsync: supplierInvoiceUpdateStatusesToProcessMutateAsync, isPending: isToProcessMutationPending } =
    useSupplierInvoiceUpdateStatusesToProcess();

  let canAddPayment: boolean;
  if (
    hasWorkflowValidationFeatureFlag &&
    orderedValidationSteps.data.length > 0 &&
    selectedItems.every((item) => item.status === SUPPLIER_INVOICE_STATUS.TO_PROCESS) &&
    !hasHiddenItems
  ) {
    canAddPayment = selectedItems.every(
      (item) => (item.amountExVAT ?? 0) < orderedValidationSteps.data[0].validationCondition.value,
    );
  } else {
    canAddPayment = true;
  }

  const numberOfItemsPerStatus = useMemo(() => countBy(selectedItems, 'status'), [selectedItems]);
  const numberOfStatus = useMemo(() => Object.keys(numberOfItemsPerStatus).length, [numberOfItemsPerStatus]);

  const modal = useDisclosure();

  /**
   * Hacky solution to display plural if `hasAllCheckboxesSelected` is selected
   */
  const labelSupplierInvoiceCounter = hasAllCheckboxesSelected ? 2 : selectedItems.length;

  const onOptionSelected = useCallback(
    async (nextStatus: { value: SUPPLIER_INVOICE_STATUS; label: ReactNode } | null) => {
      if (nextStatus?.value === SUPPLIER_INVOICE_STATUS.PAID) {
        modal.onOpen();
        return;
      }

      const apiCall = () => {
        switch (nextStatus?.value) {
          case SUPPLIER_INVOICE_STATUS.TO_PAY:
            return supplierInvoiceUpdateStatusesToPayMutateAsync;
          case SUPPLIER_INVOICE_STATUS.TO_PROCESS:
            return supplierInvoiceUpdateStatusesToProcessMutateAsync;
          default:
            throw new Error(`Changes to ${nextStatus} is not implemented`);
        }
      };

      const response = await apiCall()({
        filters: qs.parse(currentFilters.toString()),
        selectedItems: selectedItems.map((item) => ({ id: item.id })),
        hasAllSelected: hasAllCheckboxesSelected,
        search: currentFilters.get(FILTERING_PARAMS.SEARCH) || undefined,
      });

      if (response && 'warning' in response && response.warning === 'missing-information') {
        toast.warning(t('supplierInvoices:toasts.partialToPayStatusUpdate'));
      } else {
        toast.success(t('supplierInvoices:updateAtToPayStatusModal.toast', { count: labelSupplierInvoiceCounter }));
      }

      resetSelectedCheckboxes();
      await onStatusesChanged();
    },
    [
      currentFilters,
      hasAllCheckboxesSelected,
      labelSupplierInvoiceCounter,
      modal,
      onStatusesChanged,
      resetSelectedCheckboxes,
      selectedItems,
      supplierInvoiceUpdateStatusesToPayMutateAsync,
      supplierInvoiceUpdateStatusesToProcessMutateAsync,
      t,
      toast,
    ],
  );

  if (numberOfStatus === 0) {
    return null;
  }

  /**
   *  Now, ye know that all statuses are equals and there is at least one selected item, so the first status is the status of all items
   */
  const supplierInvoicesStatus = Object.keys(numberOfItemsPerStatus)[0] as SUPPLIER_INVOICE_STATUS;

  const nextStatusesAllowed = getNextStatusesAllowedForBatchActions(supplierInvoicesStatus);

  const options = (() => {
    const availableOptions: Array<{ value: SUPPLIER_INVOICE_STATUS; label: ReactNode; isDisabled?: boolean }> = [];

    if (nextStatusesAllowed?.includes(SUPPLIER_INVOICE_STATUS.TO_PROCESS)) {
      availableOptions.push({
        value: SUPPLIER_INVOICE_STATUS.TO_PROCESS,
        label: <SupplierInvoiceStatusBadge status={SUPPLIER_INVOICE_STATUS.TO_PROCESS} />,
      });
    }
    if (nextStatusesAllowed?.includes(SUPPLIER_INVOICE_STATUS.TO_PAY)) {
      availableOptions.push({
        value: SUPPLIER_INVOICE_STATUS.TO_PAY,
        label: hasWorkflowValidationFeatureFlag ? (
          <Badge w="auto" minWidth="5.25rem" px={3} colorScheme={SUPPLIER_INVOICES_STATUS_COLOR.TO_PAY}>
            {t('supplierInvoices:statuses.VALIDATING')}
          </Badge>
        ) : (
          <SupplierInvoiceStatusBadge status={SUPPLIER_INVOICE_STATUS.TO_PAY} />
        ),
      });
    }
    if (nextStatusesAllowed?.includes(SUPPLIER_INVOICE_STATUS.PAID)) {
      availableOptions.push({
        value: SUPPLIER_INVOICE_STATUS.PAID,
        label: <SupplierInvoiceStatusBadge status={SUPPLIER_INVOICE_STATUS.PAID} />,
        isDisabled: !canAddPayment,
      });
    }

    return availableOptions;
  })();

  const someItemsHaveMissingInformation = selectedItems.some((item) => item.hasMissingInformation);

  const isSelectStatusDisabled =
    someItemsHaveMissingInformation ||
    !!numberOfItemsPerStatus[SUPPLIER_INVOICE_STATUS.PAID] ||
    !!numberOfItemsPerStatus[SUPPLIER_INVOICE_STATUS.DIRECT_PAYMENT] ||
    numberOfStatus !== 1 ||
    hasHiddenItems;

  let tooltipMessage = '';
  if (numberOfStatus !== 1) {
    tooltipMessage = t('supplierInvoices:banner.tooltipMessages.multipleStatusError');
  } else if (numberOfItemsPerStatus[SUPPLIER_INVOICE_STATUS.PAID]) {
    tooltipMessage = t('supplierInvoices:banner.tooltipMessages.paidStatusError');
  } else if (numberOfItemsPerStatus[SUPPLIER_INVOICE_STATUS.DIRECT_PAYMENT]) {
    tooltipMessage = t('supplierInvoices:banner.tooltipMessages.directPaymentStatusError');
  } else if (someItemsHaveMissingInformation) {
    tooltipMessage = t('supplierInvoices:banner.tooltipMessages.missingInformationError');
  } else if (options.some((option) => option.isDisabled)) {
    tooltipMessage = t(
      'validationStep:supplierInvoice.tooltips.cannotChangeToPayBecauseOneOfIsAboveTheFirstValidationStep',
    );
  }

  const hasMutationPending = isToPayMutationPending || isToProcessMutationPending;

  return (
    <>
      <Tooltip placement="right-start" label={tooltipMessage}>
        <Box width="9rem">
          <SingleSelect<{ value: SUPPLIER_INVOICE_STATUS; label: ReactNode }>
            onChange={onOptionSelected}
            placeholder={t('global:words.c.status')}
            isDisabled={hasMutationPending || isSelectStatusDisabled}
            options={options}
            isClearable={false}
            isSearchable={false}
            menuPlacement="top"
            isLoading={hasMutationPending}
          />
        </Box>
      </Tooltip>

      <SupplierInvoiceUpdateAtToPayStatusModal
        modal={modal}
        selectedItems={selectedItems}
        resetSelectedCheckboxes={resetSelectedCheckboxes}
        hasAllCheckboxesSelected={hasAllCheckboxesSelected}
        currentFilters={currentFilters}
        onStatusesChanged={onStatusesChanged}
      />
    </>
  );
};
