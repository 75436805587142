import type { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { MultiAutocompleteField } from '../../Field/AutocompleteField/MultiAutocompleteField';
import { Tooltip } from '../../Tooltip/Tooltip';

export interface MultiSelectFiltersProps<Name extends string = string> {
  label: string;
  name: Name;
  availableValues: { label: string; value: string }[];
  placeholder?: string;
  noValueMessage?: string;
}

export const MultiSelectFilters: FC<MultiSelectFiltersProps> = ({
  label,
  name,
  availableValues,
  placeholder,
  noValueMessage,
}) => (
  <Flex gap={3}>
    <Text w="12rem" fontSize="sm" color="baseSecondary">
      {label}
    </Text>

    <Tooltip label={availableValues.length === 0 ? noValueMessage : undefined} placement="top">
      <Box w="100%">
        <MultiAutocompleteField
          name={name}
          isDisabled={availableValues.length === 0}
          options={availableValues}
          placeholder={placeholder}
        />
      </Box>
    </Tooltip>
  </Flex>
);
