import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Onboarding, SimpleAlertIcon, Wizard } from '@graneet/lib-ui';
import { useHistory, useParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

import { ExportQuoteStep } from './_steps/ExportQuoteStep';

import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { useData } from 'features/api/hooks/useData';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { EXPORT_FILE_TYPES } from 'features/quote-export/constants/quote-export.constant';
import { getQuoteLastVersion } from 'features/quote/services/quote.api';
import { Loading } from 'features/common/components/Loading';
import { QuoteExportGenerateModal } from 'features/quote-export/components/modals/QuoteExportGenerateModal';
import type {
  QuoteExportEditionWizard,
  QuoteExportForm,
} from 'features/quote-export/forms/quote-export-edition.wizard';
import { useQuoteExportFileTypeStore } from 'features/quote-export/stores/quote-export-file-type.store';
import { Beacon } from 'features/beacon/components/Beacon';

export const ExportQuoteScreen: FC = () => {
  useHideNavbar();
  const { t } = useTranslation(['quote', 'global']);
  const history = useHistory();

  const generateExportModal = useDisclosure();

  const fileType = useQuoteExportFileTypeStore((store) => store.fileType);

  const { quoteId: quoteIdRaw } = useParams<{ quoteId: string }>();
  const quoteId = parseInt(quoteIdRaw, 10);

  const getData = useDataGetter(getQuoteLastVersion, quoteId!);
  const { data: quoteLastVersion, error, loading } = useData(getData);

  const wizardLabels = useWizardLabels({
    save:
      fileType === EXPORT_FILE_TYPES.PDF
        ? t('quote:export.wizard.actions.exportPDF')
        : t('quote:export.wizard.actions.exportSpreadsheet'),
  });

  const onQuit = useCallback(() => {
    // Redirect on last non archived version
    history.push(`/opportunities/quotes/${quoteLastVersion ? quoteLastVersion.id : quoteId}`);
  }, [history, quoteLastVersion, quoteId]);

  const [exportFormValues, setExportFormValues] = useState<null | QuoteExportForm>(null);
  const onFinish = useCallback(
    (wizardValues: QuoteExportEditionWizard) => {
      setExportFormValues(wizardValues.export);
      generateExportModal.onOpen();
    },
    [generateExportModal],
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !quoteId) {
    return <Onboarding icon={<SimpleAlertIcon boxSize={45} />}>{t('global:errors.error')}</Onboarding>;
  }

  return (
    <>
      <Wizard<QuoteExportEditionWizard>
        headerTitle={t('quote:export.wizard.title')}
        labels={wizardLabels}
        onQuit={onQuit}
        onFinish={onFinish}
      >
        <Wizard.Step<QuoteExportEditionWizard> name="export">
          <ExportQuoteStep quoteId={quoteId} />
          <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_HELP}>
            <Beacon top="-4rem" right="0.6rem" />
          </Wizard.Placeholder>
        </Wizard.Step>
      </Wizard>

      <QuoteExportGenerateModal
        modal={generateExportModal}
        quoteId={quoteId}
        quoteLastVersion={quoteLastVersion}
        exportFormValues={exportFormValues}
      />
    </>
  );
};
