import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { IOrderResponseDTO, ISupplierInvoice } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { Button, Card, SimpleAddIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { SupplierInvoiceList } from '../SupplierInvoiceList';
import { AssociatedSupplierInvoicesAmounts } from '../AssociatedSupplierInvoicesAmounts';

import { PickOrCreateSupplierInvoiceModal } from './PickOrCreateSupplierInvoiceModal';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { useOrderUpdate } from 'features/order/services/order.api';

interface AssociatedSupplierInvoicesCardProps {
  order: IOrderResponseDTO;
  forceOpen: boolean;
}

export const AssociatedSupplierInvoicesCard: FC<AssociatedSupplierInvoicesCardProps> = ({ order, forceOpen }) => {
  const supplierInvoices = useMemo(
    () => order.ordersSupplierInvoices?.map(({ supplierInvoice }) => supplierInvoice) || [],
    [order],
  );
  const { t } = useTranslation(['orders']);
  const [isOpen, setIsOpen] = useState(false);
  const [withSkipButton, setSkipButton] = useState(false);

  const orderUpdateMutation = useOrderUpdate();

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (forceOpen) {
      setIsOpen(true);
      setSkipButton(true);
    }
  }, [forceOpen]);

  const onClose = useCallback(async () => {
    setIsOpen(false);
    setSkipButton(false);
  }, []);

  const onDeleteSupplierInvoice = useCallback(
    async (supplierInvoice: ISupplierInvoice) => {
      await orderUpdateMutation.mutateAsync({
        id: order.id,
        dto: { deletedSupplierInvoices: JSON.stringify([supplierInvoice.id]) },
      });
    },
    [order.id, orderUpdateMutation],
  );

  const getAssociateButtonProps = useDisabledButtonProps([PERMISSION.CREATE_ORDER]);

  if (order?.isDirectPayment && supplierInvoices.length === 0) return null;
  return (
    <Card title={t('orders:associate.title')}>
      <SupplierInvoiceList
        supplierInvoices={supplierInvoices}
        order={order}
        onDeleteSupplierInvoice={onDeleteSupplierInvoice}
      />

      {!order?.isDirectPayment && (
        <Button
          variant="outline"
          leftIcon={<SimpleAddIcon />}
          colorScheme="gray"
          onClick={openModal}
          {...getAssociateButtonProps()}
        >
          {t('orders:associate.associateNew')}
        </Button>
      )}

      <PickOrCreateSupplierInvoiceModal
        withSkipButton={withSkipButton}
        order={order}
        isOpen={isOpen}
        onClose={onClose}
      />
      <AssociatedSupplierInvoicesAmounts
        supplierInvoices={supplierInvoices}
        orderProjectId={order.project?.id}
        amountExVAT={order.amountExVAT || 0}
      />
    </Card>
  );
};
