import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { QuoteListenerMap } from '../services/quote.util';
import { BUILD_ROOT_LOT_ID_SIGNAL_KEY } from '../services/quote.util';
import type { QuoteData } from '../types/QuoteData';

/**
 * The purpose of this hook is to share the data store used for quote forms (edit and export).
 * The store and some associated methods are used by the QuoteEditContext and the QuoteExportContext.
 * emit and listen callbacks must be provided by the associated context,
 * otherwise listeners are not added to the right event emitters.
 * */
export const useQuoteData = (
  emit: <K extends keyof QuoteListenerMap>(key: K, value: QuoteListenerMap[K]) => void,
  listen: <K extends keyof QuoteListenerMap>(key: K, listener: (newValue: QuoteListenerMap[K]) => void) => void,
) => {
  /**
   * Quote state which stores the new quote state after each action.
   */
  const quoteDataRef = useRef<QuoteData>({
    /**
     * The quote object with relations
     */
    quote: null as any, // TODO

    /**
     * A dictionary where key is the lot `id` and whose value the lot object
     */
    lots: {},

    /**
     * A dictionary whose key is the job `id` and whose value the job object
     */
    jobs: {},

    /**
     * A dictionary whose key is the component `id` and whose value the component object
     */
    components: {},

    /**
     * An object representing the parent/previous relations between lots and jobs
     *
     * Its structure follows:
     * {
     *   [lot.id]: {       // The id  of some lot
     *     lots: number[], // The ids of its direct sub-lots
     *     jobs: number[], // The ids of its jobs
     *   }
     * }
     */
    relations: {},

    /**
     * The id of the top-most level lot of the quote. Useful to start traversing
     * the relations downwards
     */
    rootLotId: null as any, // TODO

    /**
     * A dictionary which stores locally which lots are expanded.
     * Empty by default, it becomes populated as the user collapses lots.
     *
     * Its structure follows:
     * { [lot.id]: true|false }
     */
    expandedLotsIds: {},
  });

  const findMaxLotDepth = useCallback(
    () => Math.max(...Object.values(quoteDataRef.current?.lots).map((lot) => lot.depth)),
    [quoteDataRef],
  );

  // -- ROOT LOT

  /**
   * Notify a listener when root lot id change.
   */
  const emitRootLotId = useCallback(() => {
    if (!quoteDataRef.current) {
      return;
    }

    const key = BUILD_ROOT_LOT_ID_SIGNAL_KEY();
    emit(key, quoteDataRef.current.rootLotId);
  }, [emit, quoteDataRef]);

  /**
   * Listen to the root lot id changes.
   */
  const listenToRootLotId = useCallback(
    (listener: (newValue: number) => void) => {
      const key = BUILD_ROOT_LOT_ID_SIGNAL_KEY();
      return listen(key, listener);
    },
    [listen],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const useRootLotId = () => {
    const [rootLotId, setRootLotId] = useState<number | undefined>(undefined);
    useEffect(() => listenToRootLotId(setRootLotId), []);
    return rootLotId;
  };

  return useMemo(
    () => ({
      get currentQuoteData() {
        return quoteDataRef.current;
      },
      emitRootLotId,
      listenToRootLotId,
      findMaxLotDepth,
      useRootLotId,
    }),
    [emitRootLotId, findMaxLotDepth, listenToRootLotId, useRootLotId],
  );
};
