import { Grid, VStack } from '@chakra-ui/react';
import type { FC } from 'react';
import type { IClient, IClientResponseDTO, ISecondaryContactProject, IContact } from '@graneet/business-logic';
import { QUOTE_STATUS } from '@graneet/business-logic';
import { QuoteStatus } from '@org/graneet-bff-client';

import { ClientCard } from 'features/client/components/cards/ClientCard';
import { ContactCardClient } from 'features/contact/components/cards/ContactCardClient';
import type { ClientCardTypeEnum } from 'features/client/components/cards/ClientCardDetails';
import { ContactCardQuote } from 'features/contact/components/cards/ContactCardQuote';
import { ContactCardProject } from 'features/contact/components/cards/ContactCardProject';
import { SecondaryClientCard } from 'features/client/components/cards/SecondaryClientCard';
import { ClientTagsCard } from 'features/client/components/cards/ClientTagsCard';

export enum ClientCardContactAssociateType {
  CLIENT = 'CLIENT',
  QUOTE = 'QUOTE',
  PROJECT = 'PROJECT',
}

interface ClientInformationsTabProps {
  client: IClientResponseDTO | IClient;
  onInfosUpdated?: () => void;
  onClientCardButtonClicked?: () => void;
  type: ClientCardTypeEnum;
  onClientUpdate?: (clientId: number | null) => Promise<IClient | IClientResponseDTO | null>;
  onContactUpdate?: () => void;
  associationType: ClientCardContactAssociateType;
  quoteId?: number | string;
  projectId?: number;
  updateSecondaryClient?: (clientId: number) => Promise<ISecondaryContactProject[] | null>;
  removeSecondaryClient?: (clientId: number) => Promise<void>;
  contacts?: IContact[];
  quoteStatus?: QUOTE_STATUS | QuoteStatus;
}

/**
 * TODO Simplify and slit this component into multiple ones @Victor
 */
export const ClientInformationsTab: FC<ClientInformationsTabProps> = ({
  client,
  onInfosUpdated,
  type,
  onClientCardButtonClicked,
  onClientUpdate,
  onContactUpdate,
  associationType,
  quoteId,
  projectId,
  updateSecondaryClient,
  removeSecondaryClient,
  quoteStatus,
}) => {
  // @ff [quote-v2]
  const isButtonDisabled =
    typeof quoteId === 'string'
      ? quoteStatus === QuoteStatus.Accepted || quoteStatus === QuoteStatus.Completed
      : quoteStatus === QUOTE_STATUS.ACCEPTED || quoteStatus === QUOTE_STATUS.COMPLETED;

  return (
    <>
      <Grid>
        <VStack gap={4}>
          <ClientCard
            client={client}
            type={type}
            onClientEdited={onInfosUpdated}
            onClientCardButtonClicked={onClientCardButtonClicked}
            onClientUpdate={onClientUpdate}
            isButtonDisable={isButtonDisabled}
          />
          {associationType === ClientCardContactAssociateType.CLIENT && <ContactCardClient clientId={client.id} />}
          {associationType === ClientCardContactAssociateType.QUOTE && quoteId && (
            <ContactCardQuote clientId={client.id} quoteId={quoteId} onContactUpdate={onContactUpdate} />
          )}
          {associationType === ClientCardContactAssociateType.PROJECT &&
            projectId &&
            updateSecondaryClient &&
            removeSecondaryClient && (
              <>
                <SecondaryClientCard
                  client={client}
                  projectId={projectId}
                  updateSecondaryClient={updateSecondaryClient}
                  removeSecondaryClient={removeSecondaryClient}
                />
                <ContactCardProject
                  key={client.id} // update card when client id is updated
                  projectId={projectId}
                  onContactUpdate={onContactUpdate}
                />
              </>
            )}
          <ClientTagsCard client={client} />
        </VStack>
      </Grid>
    </>
  );
};
