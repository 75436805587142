import type {
  IContractInfosItem,
  IContractInfosLot,
  IContractInfosResponseDTO,
  IContractItemCreateDTO,
  IContractLotCreateDTO,
  IContractUpdateDTO,
  IContratItemsUpdateDTO,
  IContratLotsUpdateDTO,
  IDiscount,
  Raw,
  IContract,
  IItem,
  ISubProject,
  ICustomDiscount,
  IContractCustomDiscount,
} from '@graneet/business-logic';
import {
  computeContainerAmountsUsingBasesMethod,
  computeContainerAmountsUsingSumsMethod,
  computeContainerAmountsUsingBasesRoundedMethod,
  VAT_METHOD,
  DISCOUNT_TYPES,
  CUSTOM_DISCOUNT_TYPES,
} from '@graneet/business-logic';
import type { IInitialTree, ITree, TreeChanges, TreeComputedValues } from '@graneet/lib-ui';
import { isNil } from 'lodash-es';

import type {
  ContractItemComputedValue,
  ContractLotComputedValue,
  IContractInfosItemWithUUID,
  IContractInfosLotWithUUID,
  IComputedContractValues,
} from '../types/contract.type';

import { formatFileName } from 'features/file/services/file.util';

export const getDeepTableOffset = (depth: number): number => 0.5 * (depth - 1);

export const convertContractInfosToTree = (
  contractInfos: IContractInfosResponseDTO,
): IInitialTree<IContractInfosLot, IContractInfosItem> => {
  // Build nodes
  const nodes = Object.values(contractInfos.lots).reduce(
    (acc, lot) => {
      const id = lot.id as IContractInfosLot['id'];

      const parentNodeId = id === contractInfos.rootLotId ? null : lot.parentLot?.id || contractInfos.rootLotId;

      acc[id] = {
        ...lot,
        note: lot.note || null,
        parentNodeId,
      };
      return acc;
    },
    {} as IInitialTree<IContractInfosLot, IContractInfosItem>['nodes'],
  );

  nodes[contractInfos.rootLotId] = {
    id: contractInfos.rootLotId,
    parentNodeId: null,
  } as IContractInfosLot & { parentNodeId: null };

  // Build leaves
  const leaves = Object.values(contractInfos.items).reduce(
    (acc, item) => {
      const id = item.id as IContractInfosItem['id'];

      acc[id] = {
        ...item,
        note: item.note || null,
        // Root items have parentLot equal to undefined. We need to set it to the root lot id.
        parentNodeId: isNil(item.parentLot) ? contractInfos.rootLotId : item.parentLot.id,
      };
      return acc;
    },
    {} as IInitialTree<IContractInfosLot, IContractInfosItem>['leaves'],
  );

  // Build relations
  const relations: ITree<IContractInfosLot, IContractInfosItem>['relations'] = {};
  Object.entries(contractInfos.relations).forEach(([lotIdAsString, lotRelations]) => {
    const lotId = parseInt(lotIdAsString, 10);

    relations[lotId] = {
      nodes: lotRelations.lots,
      leaves: lotRelations.items,
    };
  });

  return {
    nodes,
    leaves,
    relations,
    rootNodeId: contractInfos.rootLotId,
  };
};

export const mapCurrentTreeToContractUpdateDTO = (
  treeChanges: TreeChanges<IContractInfosLotWithUUID, IContractInfosItemWithUUID> | undefined,
  contract: Pick<
    IContractUpdateDTO,
    'receptionDate' | 'code' | 'name' | 'discount' | 'isAutomaticNumberingActivated' | 'customDiscounts'
  >,
  hasReversalOfLiability: boolean | null,
  hasDiscountBeenUpdated: boolean,
): IContractUpdateDTO => {
  const items: IContratItemsUpdateDTO = {
    updated: (treeChanges?.leaves.updated || []).map((item) => ({
      id: item.id as number,
      code: item.code,
      description: item.description,
      unit: item.unit,
      quantity: item.quantity,
      quantityFormula: item.quantityFormula,
      unitPrice: item.unitPrice,
      vatRate: item.vatRate,
      note: item.note,
      isOptional: item.isOptional,
      previousItemId: item.previousLeafId,
      parentLotId: item.parentNodeId,
    })),
    deleted: (treeChanges?.leaves.deleted as number[]) || [],
    created: (treeChanges?.leaves.created || []).map<IContractItemCreateDTO>((item) => ({
      id: item.id,
      code: item.code,
      description: item.description,
      unit: item.unit,
      quantity: item.quantity,
      quantityFormula: item.quantityFormula,
      unitPrice: item.unitPrice,
      vatRate: item.vatRate,
      note: item.note,
      // Contract created items are never optional, so the value is always set to false
      isOptional: false,
      previousItemId: item.previousLeafId,
      parentLotId: item.parentNodeId,
    })),
  };

  const lots: IContratLotsUpdateDTO = {
    updated: (treeChanges?.nodes.updated || []).map((lot) => ({
      id: lot.id as number,
      code: lot.code,
      description: lot.description,
      note: lot.note,
      previousLotId: lot.previousNodeId,
      parentLotId: lot.parentNodeId,
    })),
    deleted: (treeChanges?.nodes.deleted as number[]) || [],
    created: (treeChanges?.nodes.created || []).map<IContractLotCreateDTO>((lot) => ({
      id: lot.id,
      code: lot.code,
      description: lot.description,
      note: lot.note,
      previousLotId: lot.previousNodeId,
      parentLotId: lot.parentNodeId,
    })),
  };

  /*
    3 cases for discount:
     - is defined, discount has been updated
     - is null, user want to remove the discount
     - is undefined, user has not updated the discount
   */
  let contractDiscount: Pick<IDiscount, 'amountExVAT'> | null | undefined;
  if (hasDiscountBeenUpdated) {
    contractDiscount =
      contract.discount && !isNil(contract.discount.amountExVAT)
        ? {
            amountExVAT: contract.discount.amountExVAT,
          }
        : null;
  }

  const contractCustomDiscounts = contract.customDiscounts?.map(
    ({ id, amountExVAT, name, vatRate, percentage, type }) => ({
      id,
      amountExVAT: type === CUSTOM_DISCOUNT_TYPES.AMOUNT ? amountExVAT : null,
      name,
      vatRate,
      percentage,
      type,
    }),
  );

  return {
    discount: contractDiscount,
    customDiscounts: contractCustomDiscounts,
    receptionDate: contract.receptionDate,
    code: contract.code,
    name: contract.name,
    items,
    lots,
    hasReversalOfLiability: hasReversalOfLiability || false,
    isAutomaticNumberingActivated: contract.isAutomaticNumberingActivated,
  };
};

export const isItemComplete = (
  item: Pick<IItem, 'description' | 'unit' | 'quantity' | 'unitPrice' | 'vatRate'>,
  isDeleted: boolean,
): boolean =>
  (!!item.description &&
    !!item.unit &&
    Number.isFinite(item.quantity) &&
    Number.isFinite(item.unitPrice) &&
    Number.isFinite(item.vatRate)) ||
  isDeleted;

export const computeContractFromTree = (
  newTotalAmountWithoutDiscountExVAT: number,
  currentTree: ITree<IContractInfosLotWithUUID, IContractInfosItemWithUUID>,
  leavesComputedValues: TreeComputedValues<
    IContractInfosLotWithUUID,
    IContractInfosItemWithUUID,
    ContractLotComputedValue,
    ContractItemComputedValue
  >['leaves'],
  contractInfos: IContractInfosResponseDTO,
  subProject: Pick<ISubProject, 'hasReversalOfLiability'>,
  discount: Raw<IDiscount> | undefined,
  customDiscounts: Raw<ICustomDiscount>[] = [],
) => {
  const newLeaves: IContractInfosItemWithUUID[] = [];

  Object.values(currentTree.leaves).forEach((newLeaf) => {
    const computedLeafValuesAreIncomplete =
      isNil(newLeaf.vatRate) || isNil(newLeaf.unitPrice) || isNil(newLeaf.quantity);

    if (!computedLeafValuesAreIncomplete) {
      // We need to inject leaves because the total amount ex VAT are used to compute contract and project VAT amounts
      newLeaves.push({ ...newLeaf, ...leavesComputedValues[newLeaf.id] });
    }
  });

  const { vatMethod } = contractInfos.subProject;

  let computeAmountsMethod;
  if (vatMethod === VAT_METHOD.SUMS) {
    computeAmountsMethod = computeContainerAmountsUsingSumsMethod;
  } else if (vatMethod === VAT_METHOD.BASES) {
    computeAmountsMethod = computeContainerAmountsUsingBasesMethod;
  } else {
    computeAmountsMethod = computeContainerAmountsUsingBasesRoundedMethod;
  }

  const subProjectAmounts = computeAmountsMethod(
    newTotalAmountWithoutDiscountExVAT,
    newLeaves,
    discount,
    subProject.hasReversalOfLiability || false,
    customDiscounts,
  );

  return {
    totalAmountWithoutDiscountExVAT: newTotalAmountWithoutDiscountExVAT,
    ...subProjectAmounts,
    customDiscounts,
  };
};

export const computeContractTotalAmount = (
  currentTree: ITree<IContractInfosLotWithUUID, IContractInfosItemWithUUID>,
  computedValues: TreeComputedValues<
    IContractInfosLotWithUUID,
    IContractInfosItemWithUUID,
    ContractLotComputedValue,
    ContractItemComputedValue
  >,
  contractInfos: IContractInfosResponseDTO,
  subProject: Pick<ISubProject, 'hasReversalOfLiability'>,
  discount: Raw<IDiscount> | undefined,
  customDiscounts: Raw<ICustomDiscount>[] = [],
): IComputedContractValues => {
  const {
    totalAmountExVAT: newTotalAmountExVAT,
    totalAmountIncVAT: newTotalAmountIncVAT,
    vatDistribution,
    totalAmountWithDiscountExVAT: newTotalAmountWithDiscountExVAT,
    totalAmountWithoutDiscountExVAT: newTotalAmountWithoutDiscountExVAT,
  } = computeContractFromTree(
    computedValues.nodes[currentTree.rootNodeId]?.totalExVAT || 0,
    currentTree,
    computedValues.leaves,
    contractInfos,
    subProject,
    discount,
    customDiscounts,
  );

  return {
    totalAmountWithoutDiscountExVAT: {
      initialAmount: contractInfos.contract.totalAmountWithoutDiscountExVAT,
      newAmount: newTotalAmountWithoutDiscountExVAT,
    },
    totalAmountWithDiscountExVAT: {
      initialAmount: contractInfos.contract.totalAmountWithDiscountExVAT,
      newAmount: newTotalAmountWithDiscountExVAT,
    },
    totalAmountExVAT: {
      initialAmount: contractInfos.contract.totalAmountExVAT,
      newAmount: newTotalAmountExVAT,
    },
    totalAmountIncVAT: {
      initialAmount: contractInfos.contract.totalAmountIncVAT,
      newAmount: newTotalAmountIncVAT,
    },
    vatDistribution: {
      initialDistribution: contractInfos.contract.vatDistribution,
      newDistribution: vatDistribution,
    },
  };
};

export const computeContractDiscount = (
  rawDiscountAmountExVAT: number | null | undefined,
  mapAmountToNumber: (value: string | number) => number,
) => {
  const currentDiscountAmountExVAT = isNil(rawDiscountAmountExVAT) ? null : mapAmountToNumber(rawDiscountAmountExVAT);

  if (currentDiscountAmountExVAT === null) {
    return undefined;
  }

  return {
    type: DISCOUNT_TYPES.AMOUNT,
    amountExVAT: currentDiscountAmountExVAT,
    percentage: null,
  };
};

export const formatCustomDiscountsToAmountType = (
  rawCustomDiscounts: IContractCustomDiscount[] | undefined,
): Omit<ICustomDiscount, 'createdAt' | 'updatedAt'>[] => {
  if (!rawCustomDiscounts || rawCustomDiscounts.length === 0) return [];

  const customDiscounts: Omit<ICustomDiscount, 'createdAt' | 'updatedAt'>[] = [];

  rawCustomDiscounts.forEach((rawCustomDiscount) => {
    if (rawCustomDiscount.amountExVAT !== null) {
      customDiscounts.push({
        id: rawCustomDiscount.id,
        type: CUSTOM_DISCOUNT_TYPES.AMOUNT,
        amountExVAT: rawCustomDiscount.amountExVAT,
        percentage: null,
        name: rawCustomDiscount.name,
        vatRate: rawCustomDiscount.vatRate,
      });
    }
  });

  return customDiscounts;
};

export const getContractPdfName = (contract: Pick<IContract, 'name' | 'code'>, createdAt: Date): string =>
  formatFileName(contract.name, createdAt, contract.code);
