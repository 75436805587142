import { useCallback } from 'react';
import type { Quote } from '@org/quotation-lib';
import { QuoteNodeCreateChildUseCase } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { mapQuoteNodeToUpsertHiddenCostItemDTO } from 'features/quotation/quote-common/mappers/quoteHiddenCostItemMapper';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { updateQuoteNewlyCreatedNodeId } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteHiddenCostItemCreate() {
  const { quoteHiddenCostItemsProxyApi, quoteNodeDeletesProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (position?: { prevSiblingId: string | null; nextSiblingId: string | null }): ICommand => ({
      execute(quote: Quote) {
        try {
          const tree = quote.getTree();
          const quoteNode = tree.getHiddenCostNode();

          let prevSiblingId = null;
          let nextSiblingId = null;

          if (position) {
            prevSiblingId = position.prevSiblingId;
            nextSiblingId = position.nextSiblingId;
          } else {
            prevSiblingId = quoteNode.getLastChildItem()?.getId() ?? null;
          }

          const quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteHiddenItem(
            quoteNode,
            prevSiblingId,
            nextSiblingId,
            this.oldValue && this.oldValue.nodeId ? this.oldValue.nodeId : undefined,
            this.oldValue && this.oldValue.contentId ? this.oldValue.contentId : undefined,
          );
          this.oldValue = {
            nodeId: quoteNodeCreated?.getId(),
            contentId: quoteNodeCreated?.getContent().getId(),
          };
          if (!quoteNodeCreated) {
            throw new Error('Failed to create node');
          }

          updateQuoteNewlyCreatedNodeId(quoteNodeCreated?.getId() ?? null);
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteHiddenCostItemsProxyApi
            .createQuoteHiddenCostItem(
              mapQuoteNodeToUpsertHiddenCostItemDTO(quoteNodeCreated, quote.getId(), clientRequestId, timestamp),
            )
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteHiddenCostItem = quote.getTree().getQuoteHiddenCostOrThrow(this.oldValue.nodeId);
          quoteHiddenCostItem.deleteQuoteItem();
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteNodeDeletesProxyApi
            .deleteQuoteNode({
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              nodeId: this.oldValue.nodeId,
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteHiddenCostItemsProxyApi, quoteNodeDeletesProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
