import type {
  VatMethod,
  QuoteBasicItemDTO,
  QuoteComponentDTO,
  QuoteComposeDTO,
  QuoteComposeDTOSalesDiscount,
  QuoteComposeDTOSourceFile,
  QuoteComposeDTOTree,
  QuoteCustomDiscountDTO,
  QuoteHiddenCostItemDTO,
  QuoteLotDTO,
  QuoteMarginDTO,
  QuoteNodeDTO,
  QuoteOptionalItemDTO,
  QuoteStatus,
  QuoteWithoutRelationsDTO,
} from '@org/graneet-bff-client';
import type {
  QuoteBasicItemObject,
  QuoteComponentObject,
  QuoteCustomDiscountObject,
  QuoteFileObject,
  QuoteHiddenCostItemObject,
  QuoteLotObject,
  QuoteMarginObject,
  QuoteNodeObject,
  QuoteObject,
  QuoteOptionalItemObject,
  QuoteSalesDiscountObject,
  QuoteSubItemObject,
  QuoteTreeObject,
} from '@org/quotation-lib';
import {
  VAT_METHOD,
  CONVERSION_STATUS,
  QUOTE_STATUS,
  QUOTE_CUSTOM_DISCOUNT_TYPE,
  QUOTE_SALES_DISCOUNT_TYPE,
  MARGIN_COMPUTED_VALUE,
} from '@org/quotation-lib';

function customDiscountMapper(elems: QuoteCustomDiscountDTO[]): QuoteCustomDiscountObject[] {
  return elems.map((elem) => {
    let type: QUOTE_CUSTOM_DISCOUNT_TYPE;

    switch (elem.type) {
      case 'PERCENTAGE':
        type = QUOTE_CUSTOM_DISCOUNT_TYPE.PERCENTAGE;
        break;
      case 'AMOUNT':
        type = QUOTE_CUSTOM_DISCOUNT_TYPE.AMOUNT;
        break;
      default:
        throw new Error('Invalid custom discount type');
    }

    return { ...elem, type };
  });
}

function salesDiscountMapper(elem: QuoteComposeDTOSalesDiscount | null): QuoteSalesDiscountObject | null {
  if (!elem) {
    return null;
  }

  let type: QUOTE_SALES_DISCOUNT_TYPE;

  switch (elem.type) {
    case 'PERCENTAGE':
      type = QUOTE_SALES_DISCOUNT_TYPE.PERCENTAGE;
      break;
    case 'AMOUNT':
      type = QUOTE_SALES_DISCOUNT_TYPE.AMOUNT;
      break;
    default:
      throw new Error('Invalid sales discount type');
  }

  return { ...elem, type };
}

function quoteStatus(status: QuoteStatus): QUOTE_STATUS {
  switch (status) {
    case 'DRAFT':
      return QUOTE_STATUS.DRAFT;
    case 'IMPORTING':
      return QUOTE_STATUS.IMPORTING;
    case 'COMPLETED':
      return QUOTE_STATUS.COMPLETED;
    case 'ACCEPTED':
      return QUOTE_STATUS.ACCEPTED;
    case 'REFUSED':
      return QUOTE_STATUS.REFUSED;
    case 'ARCHIVED':
      return QUOTE_STATUS.ARCHIVED;
    default:
      throw new Error('Invalid quote status');
  }
}

function fileMapper(elem: QuoteComposeDTOSourceFile): QuoteFileObject {
  let conversionStatus: CONVERSION_STATUS;

  switch (elem.conversionStatus) {
    case 'CONVERTED':
      conversionStatus = CONVERSION_STATUS.CONVERTED;
      break;
    case 'NOT_CONVERTED':
      conversionStatus = CONVERSION_STATUS.NOT_CONVERTED;
      break;
    case 'ERROR':
      conversionStatus = CONVERSION_STATUS.ERROR;
      break;
    default:
      throw new Error('Invalid conversion status');
  }

  return {
    ...elem,
    conversionStatus,
  };
}

function marginMapper(elem: QuoteMarginDTO): QuoteMarginObject {
  let computed: MARGIN_COMPUTED_VALUE;

  switch (elem.computed) {
    case 'PROFIT':
      computed = MARGIN_COMPUTED_VALUE.PROFIT;
      break;
    case 'TOTAL':
      computed = MARGIN_COMPUTED_VALUE.TOTAL;
      break;
    default:
      throw new Error('Invalid margin computed value');
  }

  return { ...elem, computed };
}

function nodesMapper(elems: QuoteNodeDTO[]): QuoteNodeObject[] {
  return elems.map((elem) => {
    if (elem.content.type === 'QuoteLot') {
      const content = elem.content as QuoteLotDTO;
      const quoteLotObject: QuoteLotObject = {
        ...content,
        type: 'QuoteLot',
        margin: marginMapper(content.margin),
      };
      return { ...elem, content: quoteLotObject };
    }

    if (elem.content.type === 'QuoteBasicItem') {
      const content = elem.content as QuoteBasicItemDTO;
      const quoteBasicItemObject: QuoteBasicItemObject = {
        ...content,
        type: 'QuoteBasicItem',
        margin: marginMapper(content.margin),
        files: content.files.map(fileMapper),
      };
      return { ...elem, content: quoteBasicItemObject };
    }

    if (elem.content.type === 'QuoteOptionalItem') {
      const content = elem.content as QuoteOptionalItemDTO;
      const quoteHiddenCostItemObject: QuoteOptionalItemObject = {
        ...content,
        type: 'QuoteOptionalItem',
        margin: marginMapper(content.margin),
        files: content.files.map(fileMapper),
      };
      return { ...elem, content: quoteHiddenCostItemObject };
    }

    if (elem.content.type === 'QuoteHiddenCostItem') {
      const content = elem.content as QuoteHiddenCostItemDTO;
      const quoteHiddenCostItemObject: QuoteHiddenCostItemObject = {
        ...content,
        type: 'QuoteHiddenCostItem',
        margin: marginMapper(content.margin),
        files: content.files.map(fileMapper),
      };
      return { ...elem, content: quoteHiddenCostItemObject };
    }

    if (elem.content.type === 'QuoteSubItem') {
      const content = elem.content as QuoteBasicItemDTO;
      const quoteSubItemObject: QuoteSubItemObject = {
        ...content,
        type: 'QuoteSubItem',
        margin: marginMapper(content.margin),
        files: content.files.map(fileMapper),
      };
      return { ...elem, content: quoteSubItemObject };
    }

    if (elem.content.type === 'QuoteComponent') {
      const content = elem.content as QuoteComponentDTO;
      const quoteSubItemObject: QuoteComponentObject = {
        ...content,
        type: 'QuoteComponent',
        margin: marginMapper(content.margin),
      };
      return { ...elem, content: quoteSubItemObject };
    }

    throw new Error('Invalid node content type');
  });
}

function treeMapper(elem: QuoteComposeDTOTree): QuoteTreeObject {
  return {
    ...elem,
    nodes: nodesMapper(elem.nodes),
  };
}

function vatMethodMapper(elem: VatMethod): VAT_METHOD {
  switch (elem) {
    case 'BASES':
      return VAT_METHOD.BASES;
    case 'BASES_ROUNDED':
      return VAT_METHOD.BASES_ROUNDED;
    default:
      throw new Error('Invalid vat method');
  }
}

export function quoteComposeToQuoteObjectMapper(quoteCompose: QuoteComposeDTO): QuoteObject {
  if (!quoteCompose.tree) {
    throw new Error('Invalid tree');
  }

  return {
    ...quoteCompose,
    clientId: quoteCompose.client?.id ?? null,
    projectId: quoteCompose.project?.id ?? null,
    margin: marginMapper(quoteCompose.margin),
    status: quoteStatus(quoteCompose.status),
    sourceFile: quoteCompose.sourceFile ? fileMapper(quoteCompose.sourceFile) : null,
    salesDiscount: salesDiscountMapper(quoteCompose.salesDiscount),
    customDiscounts: customDiscountMapper(quoteCompose.customDiscounts),
    tree: treeMapper(quoteCompose.tree),
    vatMethod: vatMethodMapper(quoteCompose.vatMethod),
    vatBases: quoteCompose.vatBases ?? [],
    displayOptionalLinesPDF: quoteCompose.displayOptionalAnnexPDF,
    displayOptionalAnnexPDF: quoteCompose.displayOptionalAnnexPDF,
    contacts: quoteCompose.contacts,
  };
}

export function quoteWithoutRelationShipToQuoteObjectMapper(quote: QuoteWithoutRelationsDTO): Partial<QuoteObject> {
  return {
    ...quote,
    margin: marginMapper(quote.margin),
    status: quoteStatus(quote.status),
    vatMethod: vatMethodMapper(quote.vatMethod),
    vatBases: quote.vatBases ?? [],
    displayOptionalLinesPDF: quote.displayOptionalAnnexPDF,
    displayOptionalAnnexPDF: quote.displayOptionalAnnexPDF,
  };
}
