import { useCallback, useEffect, type FC } from 'react';
import { QUOTE_SALES_DISCOUNT_TYPE } from '@org/quotation-lib';
import Big from 'big.js';
import { Form, useForm } from 'graneet-form';
import { useCurrency } from '@graneet/lib-ui';

import { formatDiscount } from '../../quote/utils/quote-utils';
import { useQuoteSalesDiscountUpdate } from '../hooks/useQuoteSalesDiscountUpdate';

import { AddSaleDiscountModal } from 'features/discount/components/modals/AddSaleDiscountModal';
import type { SaleDiscountForm } from 'features/discount/components/modals/AddSaleDiscountModal';
import { useQuoteSalesDiscountCreate } from 'features/quotation/quote-sales-discount/hooks/useQuoteSalesDiscountCreate';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

interface UpsertQuotationSaleDiscountModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const UpsertQuotationSaleDiscountModal: FC<UpsertQuotationSaleDiscountModalProps> = ({ isOpen, onClose }) => {
  const form = useForm<SaleDiscountForm>();
  const { getFormValues, setFormValues } = form;
  const { quote } = useQuote();
  const quoteSalesDiscountUpdate = useQuoteSalesDiscountUpdate();
  const quoteSalesDiscountCreate = useQuoteSalesDiscountCreate();
  const { mapAmountToStringNumber, mapStringNumberToAmount } = useCurrency();

  const saleDiscount = quote.getSalesDiscount();

  useEffect(() => {
    if (saleDiscount) {
      const formattedSaleDiscount = formatDiscount(saleDiscount, quote.getAmountBeforeDiscountsExVat());
      setFormValues({
        saleDiscountType: formattedSaleDiscount.type,
        saleDiscountAmountExVAT:
          formattedSaleDiscount.type === QUOTE_SALES_DISCOUNT_TYPE.AMOUNT
            ? mapStringNumberToAmount(formattedSaleDiscount.amount)
            : undefined,
        saleDiscountPercentage:
          formattedSaleDiscount.type === QUOTE_SALES_DISCOUNT_TYPE.PERCENTAGE
            ? formattedSaleDiscount.percentage
            : undefined,
      });
    } else {
      setFormValues({
        saleDiscountType: QUOTE_SALES_DISCOUNT_TYPE.PERCENTAGE,
        saleDiscountAmountExVAT: undefined,
        saleDiscountPercentage: undefined,
      });
    }
  }, [setFormValues, saleDiscount, quote, mapStringNumberToAmount]);

  const handleQuoteSalesDiscountSubmit = useCallback(() => {
    const formValues = getFormValues();
    const salesDiscountUpsert = {
      type: formValues.saleDiscountType,
      amount: formValues.saleDiscountAmountExVAT
        ? mapAmountToStringNumber(Big(formValues.saleDiscountAmountExVAT).neg().toString())
        : undefined,
      percentage: formValues.saleDiscountPercentage
        ? Big(formValues.saleDiscountPercentage).neg().toString()
        : undefined,
    };
    if (saleDiscount) {
      quoteSalesDiscountUpdate({
        id: saleDiscount.getId(),
        ...salesDiscountUpsert,
      });
    } else {
      quoteSalesDiscountCreate({
        ...salesDiscountUpsert,
      });
    }
  }, [getFormValues, mapAmountToStringNumber, quoteSalesDiscountCreate, quoteSalesDiscountUpdate, saleDiscount]);

  return (
    <Form form={form}>
      <AddSaleDiscountModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleQuoteSalesDiscountSubmit}
        totalBeforeSaleDiscountExVAT={quote.getAmountBeforeDiscountsExVat() ?? Big(0)}
      />
    </Form>
  );
};
