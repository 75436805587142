import { useTranslation } from 'react-i18next';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { PDF_COMPANY_ADDRESS_POSITION } from '@graneet/business-logic';
import { SimpleHelpIcon, SwitchField, Tooltip } from '@graneet/lib-ui';
import type { FC } from 'react';

import type { PDFSettingsFormValues } from '../constants/pdf-settings.constant';
import { PDF_SETTINGS_FIELDS } from '../constants/pdf-settings.constant';

export const PdfCompanyAddressSettings: FC = () => {
  const { t } = useTranslation(['pdfSettings']);

  return (
    <VStack spacing={2} align="start">
      <SwitchField<PDFSettingsFormValues>
        name={PDF_SETTINGS_FIELDS.COMPANY_ADDRESS_POSITION}
        checkedValue={PDF_COMPANY_ADDRESS_POSITION.ENVELOPPE_WINDOW}
        uncheckedValue={PDF_COMPANY_ADDRESS_POSITION.NONE}
        label={
          <HStack spacing={2}>
            <Text color="black" fontSize="sm">
              {t('pdfSettings:settings.companyAddressPosition.title')}
            </Text>
            <Tooltip label={t('pdfSettings:settings.companyAddressPosition.tooltip')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          </HStack>
        }
      />
    </VStack>
  );
};
