import { useTranslation } from 'react-i18next';
import { Button, SimpleAddIcon } from '@graneet/lib-ui';
import type { ButtonProps } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import type { FC } from 'react';

import { AccountingBankAccountCreateOrUpdateModal } from './AccountingBankAccountCreateOrUpdateModal';

export const AccountingAddBankAccountButton: FC<ButtonProps> = (props) => {
  const { t } = useTranslation(['accounting', 'global']);

  const modal = useDisclosure();

  return (
    <>
      <Button variant="outline" onClick={modal.onOpen} leftIcon={<SimpleAddIcon />} {...props}>
        {t('accounting:addBankAccount.title')}
      </Button>

      <AccountingBankAccountCreateOrUpdateModal
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        existingAccountingConfigBankAccount={undefined}
      />
    </>
  );
};
