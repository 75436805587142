import type { ChangeEventHandler, DragEventHandler, FC, PropsWithChildren } from 'react';
import { useCallback, useEffect } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Flex, Input } from '@chakra-ui/react';
import type { DropTargetMonitor } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { isNumberFinite } from '../../../utils/number.util';

import type { OnImageListError } from './ImageList.type';
import { IMAGE_LIST_ERROR } from './ImageList.type';
import type { ImageSize } from './ImageList.util';
import { ABSOLUTE_COVER } from './ImageList.util';

const DOMImage = window.Image;
const ACCEPTE_IMAGES_TYPES = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'];

const getImageDimensions = (imageFile: File): Promise<ImageSize> => {
  const img = new DOMImage();
  const objectUrl = URL.createObjectURL(imageFile);
  return new Promise((res, rej) => {
    img.onload = () => {
      if (img !== null) {
        res({ width: img.width, height: img.height });
      } else {
        rej(new Error('Bad image load'));
      }
      URL.revokeObjectURL(objectUrl);
    };
    img.onerror = (err) => {
      rej(err);
      URL.revokeObjectURL(objectUrl);
    };
    img.src = objectUrl;
  });
};

const getSizeError = (
  { width, height }: ImageSize,
  minDimension?: number,
  maxDimension?: number,
): IMAGE_LIST_ERROR.SIZE_TOO_SMALL | IMAGE_LIST_ERROR.SIZE_TOO_BIG | undefined => {
  if (isNumberFinite(minDimension)) {
    if (height < minDimension || width < minDimension) return IMAGE_LIST_ERROR.SIZE_TOO_SMALL;
  }
  if (isNumberFinite(maxDimension)) {
    if (height > maxDimension || width > maxDimension) return IMAGE_LIST_ERROR.SIZE_TOO_BIG;
  }
  return undefined;
};

type SelectImageButtonProps = Omit<FlexProps, 'onError'> &
  PropsWithChildren<{
    minDimension?: number;
    maxDimension?: number;
    maxImageSize?: number;
    onError?: OnImageListError;
    multiple?: boolean;
    onImageDrag?: (value: boolean) => void;
    onNewImageSelected: (file: File[]) => void;
  }>;

export const SelectImageButton: FC<SelectImageButtonProps> = ({
  onNewImageSelected,
  onError,
  minDimension,
  maxDimension,
  maxImageSize,
  children,
  multiple = false,
  onImageDrag,
  ...flexProps
}) => {
  /**
   * @param file
   * @returns a boolean that indicates if image size is valide
   */
  const imageSizeValidor = useCallback(
    async (file: any): Promise<boolean> => {
      if (file.type !== 'image/svg+xml') {
        let dimension;
        try {
          dimension = await getImageDimensions(file);
        } catch (exception) {
          onError?.(IMAGE_LIST_ERROR.FILE_INVALID);
          return false;
        }

        const dimensionError = getSizeError(dimension, minDimension, maxDimension);
        const sizeError = maxImageSize ? file.size > maxImageSize : undefined;
        if (dimensionError || sizeError) {
          onError?.(dimensionError ?? IMAGE_LIST_ERROR.SIZE_INVALID);
          return false;
        }
      }
      return true;
    },
    [maxDimension, maxImageSize, minDimension, onError],
  );

  /**
   * Return valid list of images to upload
   * */
  const validImagesToUpload = async (files: File[], validator: (file: File) => Promise<boolean>): Promise<File[]> => {
    const uploadedFiles: File[] = [];

    await Promise.all(
      [...files].map(async (file) => {
        const isImageSizeValid = await validator(file);
        if (isImageSizeValid) {
          uploadedFiles.push(file);
        }
      }),
    );
    return uploadedFiles;
  };

  /**
   * Listens to images selected by the input
   */
  const onFileChanged = useCallback<DragEventHandler<HTMLInputElement> & ChangeEventHandler<HTMLInputElement>>(
    async (fileChangeEvt) => {
      const files = fileChangeEvt.target && 'files' in fileChangeEvt.target && fileChangeEvt.target.files;

      /**
       * Resetting events (so we can re-upload the same file)
       */
      const resetEvents = () => {
        // eslint-disable-next-line no-param-reassign
        (fileChangeEvt.target as HTMLInputElement).value = '';
      };

      if (!files) return;
      const filesToUpload = await validImagesToUpload([...files], imageSizeValidor);
      onNewImageSelected(filesToUpload);
      resetEvents();
    },
    [onNewImageSelected, imageSizeValidor],
  );

  /**
   * Listens to images dropped
   */
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      async drop(item: { files: File[] }) {
        const isImageValid = async (file: any): Promise<boolean> => {
          const isTypeValid = ACCEPTE_IMAGES_TYPES.includes(file.type);
          if (isTypeValid) return imageSizeValidor(file);
          onError?.(IMAGE_LIST_ERROR.FILE_INVALID);
          return false;
        };
        if (!multiple && item.files.length > 1) {
          return;
        }

        const filesToUpload = await validImagesToUpload(item.files, isImageValid);
        onNewImageSelected(filesToUpload);
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [],
  );

  useEffect(() => {
    if (isOver) {
      onImageDrag?.(true);
    } else {
      onImageDrag?.(false);
    }
  }, [isOver, onImageDrag]);

  return (
    <Flex pos="relative" {...flexProps}>
      {children}
      <Input
        ref={drop}
        multiple={multiple}
        type="file"
        accept={ACCEPTE_IMAGES_TYPES.toString()}
        {...ABSOLUTE_COVER}
        cursor="pointer"
        zIndex={1}
        opacity={0}
        onChange={onFileChanged}
        _after={{
          ...ABSOLUTE_COVER,
          content: '""',
          display: 'block',
        }}
      />
    </Flex>
  );
};
