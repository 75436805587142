import type { FC } from 'react';
import { Card, LabeledData, Price, Date, TwoColumns, GotoLink } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IHoldBackResponseDTO } from '@graneet/business-logic';
import { Flex } from '@chakra-ui/react';

import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';

interface HoldbackDetailCardProps {
  holdback: IHoldBackResponseDTO;
}

export const HoldbackDetailCard: FC<HoldbackDetailCardProps> = ({ holdback }) => {
  const { t } = useTranslation(['global', 'holdback', 'project']);
  const { getTransitionPath } = useFiltersQuery();

  return (
    <Card
      title={
        <Flex>
          {t('holdback:cards.information')}
          {holdback.bill && (
            <GotoLink
              ml={2}
              to={getTransitionPath(`/sales/bills?focusedId=${holdback.bill.id}`).pathname}
              label={t('holdback:goTo.bill')}
            />
          )}
        </Flex>
      }
    >
      <TwoColumns>
        <LabeledData
          label={t('holdback:fields.holdbackAmountIncVAT')}
          data={<Price amount={holdback.holdbackAmountIncVAT} />}
        />
        <LabeledData
          label={t('holdback:fields.paymentGuarantee')}
          data={holdback.hasPaymentGuarantee ? t('global:words.c.yes') : t('global:words.c.no')}
        />
        <LabeledData label={t('holdback:fields.bank')} data={holdback.hasPaymentGuarantee ? holdback.bankName : '-'} />
        <LabeledData label={t('project:fields.startDate')} data={<Date>{holdback.project.startDate}</Date>} />
        <LabeledData label={t('project:fields.endDate')} data={<Date>{holdback.project.endDate}</Date>} />
      </TwoColumns>
    </Card>
  );
};
