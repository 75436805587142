export interface FileNameChipTranslations {
  lang: string;
  fileTypes: {
    pdf: string;
    spreadSheet: string;
    image: string;
    other: string;
  };
}
export const fileNameChipTranslations: FileNameChipTranslations = {
  lang: 'fr',
  fileTypes: {
    pdf: 'PDF',
    spreadSheet: 'Excel',
    image: 'Image',
    other: 'Autre',
  },
};

export const configureDefaultLabels = (translation: FileNameChipTranslations) => {
  Object.assign(fileNameChipTranslations, translation);
};
