import type { GridOptions } from '@ag-grid-community/core';
import { useMemo } from 'react';

export const useGridOption = (): GridOptions =>
  useMemo(
    () => ({
      suppressScrollOnNewData: true,
    }),
    [],
  );
