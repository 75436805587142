import { HStack } from '@chakra-ui/react';
import type { QuoteComponentsGroupByType } from '@org/quotation-lib';
import type { FocusEvent } from 'react';
import { memo, useState, useCallback, useEffect } from 'react';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import { MaskEnum, QuotationMaskedInput, useInputMaskInfos } from '@graneet/lib-ui';

import { useKeyboard } from 'features/quotation/ag-grid-quote/hooks/keyboard/useKeyboard';

export const MarginEditorCell = memo((params: CustomCellEditorProps<QuoteComponentsGroupByType>) => {
  const { value, node, onValueChange, stopEditing } = params;
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const { placeholder, mask } = useInputMaskInfos(MaskEnum.DECIMAL, {
    scale: 3,
    min: 0,
  });

  const { handleKeyDown } = useKeyboard();
  const [inputValue, setInputValue] = useState(value);

  const handleOnChange = useCallback(
    (v: string) => {
      setInputValue(v);
      onValueChange(v);
    },
    [onValueChange],
  );

  const handleSave = useCallback(
    (v: string) => {
      onValueChange(v);
      stopEditing();
    },
    [onValueChange, stopEditing],
  );

  const handleOnBlur = useCallback(
    (e: string | FocusEvent<HTMLInputElement>) => {
      const newValue = typeof e === 'string' ? e : e.target.value;
      handleSave(newValue);
    },
    [handleSave],
  );

  useEffect(() => {
    inputRef?.focus();
  }, [inputRef]);

  if (node.footer) {
    return null;
  }

  return (
    <HStack height="100%" width="100%" justifyContent="center" background="transparent" px={2}>
      <QuotationMaskedInput
        value={inputValue}
        inputRef={setInputRef}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onKeyDownCapture={handleKeyDown(handleSave)}
        mask={mask}
        placeholder={placeholder}
        textAlign="right"
        noStyle
      />
    </HStack>
  );
});
