import type dayjs from 'dayjs';
import { GridItem, Text, VStack } from '@chakra-ui/react';

interface TimeTableHeaderDayProps {
  day: dayjs.Dayjs;
}

export const TimeTableHeaderDay = ({ day }: TimeTableHeaderDayProps) => (
  <GridItem flex={1} py={3}>
    <VStack w="100%" spacing={0}>
      <Text textTransform="capitalize">{day.format('ddd')}</Text>
      <Text fontWeight="600" fontSize="2xl">
        {day.format('D')}
      </Text>
    </VStack>
  </GridItem>
);
