import type { FC } from 'react';
import { Callout, GotoLink, SimpleAlertIcon } from '@graneet/lib-ui';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface SupplierInvoiceCalloutDuplicateProps {
  invoiceNumber: string;
}

export const SupplierInvoiceCalloutDuplicate: FC<SupplierInvoiceCalloutDuplicateProps> = ({ invoiceNumber }) => {
  const { t } = useTranslation(['supplierInvoices']);
  return (
    <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
      <Text fontSize="sm">{t('supplierInvoices:callouts.duplicateInvoiceNumber', { invoiceNumber })}</Text>
      <GotoLink
        fontSize="sm"
        target="_blank"
        to={`/purchases/supplier-invoices/?invoiceNumber=${invoiceNumber}`}
        label={t('supplierInvoices:callouts.seeDuplicate')}
      />
    </Callout>
  );
};
