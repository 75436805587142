import type { IBudget, IBudgetCreationDTO, IBudgetUpdateDTO, IBudgetUsage } from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import { type PaginatedResponse, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { BUDGET_FACTORY_NAME } from './budget.factory-name';

import { apiNew } from 'features/api/services/apiNew.service';
import { invalidateStatementRelatedQueries } from 'features/common/services/invalidate-statement-related-queries.util';

const BUDGET_PATH = '/budgets';
const BUDGET_USAGE_PATH = '/budget-usages';

const budgetKeyFactory = createQueryKeys(BUDGET_FACTORY_NAME, {
  getUsages: (projectId: number) => ({
    queryKey: [BUDGET_USAGE_PATH, projectId],
    queryFn: () => {
      const filters = new URLSearchParams();
      filters.set('projectId', String(projectId));
      filters.set('_full', String(true));
      return apiNew.get<URLSearchParams, PaginatedResponse<IBudgetUsage>>(BUDGET_USAGE_PATH, filters);
    },
  }),
});

export function useBudgetUsages(projectId: number) {
  return useSuspenseQuery(budgetKeyFactory.getUsages(projectId));
}

export function useBudgetDelete() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'project']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, void>(`${BUDGET_PATH}/${id}`),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('global:words.c.success'), t('project:budget.delete.successMessage'));
    },
  });
}

export function useBudgetCreate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'project']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IBudgetCreationDTO) => apiNew.post<IBudgetCreationDTO, IBudget>(BUDGET_PATH, dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('global:words.c.success'), t('project:budget.create.successMessage'));
    },
  });
}

export function useBudgetUpdate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'project']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IBudgetUpdateDTO }) =>
      apiNew.patch<IBudgetUpdateDTO, IBudget>(`${BUDGET_PATH}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('global:words.c.success'), t('project:budget.edit.successMessage'));
    },
  });
}
