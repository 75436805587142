import type { FC } from 'react';
import { useCallback } from 'react';
import type { EmailTemplateType } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { RichSelect, $injectVariableNode, HashtagIcon } from '@graneet/lib-ui';
import { Box, Text } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import {
  getEmailTemplateVariableDropdownOptions,
  getEmailTemplateVariableLabel,
} from '../services/email-template.util';

const TRACKING_ID = 'tracking_rich_text_variable_dropdown';

interface MailingModalEmailVariableMenuProps {
  type: EmailTemplateType;

  hasTrackingId?: boolean;
}

export const EmailVariableRichSelect: FC<MailingModalEmailVariableMenuProps> = ({ type, hasTrackingId = false }) => {
  const { t } = useTranslation(['global', 'mailing']);

  const variables = getEmailTemplateVariableDropdownOptions(type, t);

  const [editor] = useLexicalComposerContext();

  const onOptionSelected = useCallback(
    (value: string) => {
      editor.update(() => {
        $injectVariableNode(
          {
            value,
          },
          editor,
        );
      });
    },
    [editor],
  );

  return (
    <Box w="3.5rem">
      <RichSelect
        id={hasTrackingId ? TRACKING_ID : undefined}
        size="sm"
        onOptionSelected={onOptionSelected}
        autoFocus={false}
        userSelect="none"
        height="30px"
        border={false}
      >
        <RichSelect.Button leftIcon={<HashtagIcon />} label={null} />
        <RichSelect.Items>
          {variables.map((variable) => {
            if (variable.type === 'option') {
              return (
                <RichSelect.Item key={variable.value} value={variable.value}>
                  <Text mr={2} color="greenBrand.light">
                    #
                  </Text>
                  {getEmailTemplateVariableLabel(variable, t)}
                </RichSelect.Item>
              );
            }
            return <RichSelect.Header key={variable.text}>{variable.text}</RichSelect.Header>;
          })}
        </RichSelect.Items>
      </RichSelect>
    </Box>
  );
};
