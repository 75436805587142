import type { FC } from 'react';
import type { IVatBases } from '@graneet/business-logic';
import { VAT_TYPE, isNumberFinite, sortVATDistribution } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { formatNumberToVatRate, formatPercentage, LabeledData, Price, Tooltip } from '@graneet/lib-ui';

interface OrderVATRateLabelProps {
  vatDistribution: IVatBases;

  vatType: VAT_TYPE;
}

export const OrderVATRatesLabel: FC<OrderVATRateLabelProps> = ({ vatDistribution, vatType }) => {
  const { t } = useTranslation(['orders', 'global']);

  if (vatType === VAT_TYPE.REVERSE_CHARGE) {
    return (
      <LabeledData
        label={t('orders:informationsCard.vatDistribution')}
        data={t('global:words.c.reversalOfLiability')}
        variant="light"
        size="md"
      />
    );
  }

  if (vatType === VAT_TYPE.INTRA_COMMUNITY) {
    return (
      <LabeledData
        label={t('orders:informationsCard.vatDistribution')}
        size="md"
        variant="light"
        data={t('global:words.c.intraCommunity')}
      />
    );
  }

  if (vatDistribution.length > 1) {
    return (
      <Tooltip
        label={
          <Stack>
            {sortVATDistribution(vatDistribution || []).map((vatBase) => (
              <Flex justifyContent="space-between" w="10rem">
                <Text>{t('global:VAT', { vatRate: formatPercentage(formatNumberToVatRate(vatBase.vatRate)) })}</Text>
                <Price amount={vatBase.amount} />
              </Flex>
            ))}
          </Stack>
        }
      >
        <LabeledData
          label={t('orders:informationsCard.vatDistribution')}
          size="md"
          variant="light"
          data={t('global:words.multiVAT')}
        />
      </Tooltip>
    );
  }

  const { vatRate } = vatDistribution[0] || {};

  return (
    <LabeledData
      label={t('orders:informationsCard.vatDistribution')}
      size="md"
      data={isNumberFinite(vatRate) ? formatPercentage(formatNumberToVatRate(vatRate)) : null}
    />
  );
};
