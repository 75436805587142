import { useParams } from 'react-router-dom';
import { GotoLink, Container } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Flex, useDisclosure, GridItem, Box, Grid } from '@chakra-ui/react';
import { useLayoutEffect } from 'react';

import { HoldbackStatusCard } from 'features/holdback/components/HoldbackStatusCard/HoldbackStatusCard';
import { ProjectAcceptanceReportCard } from 'features/project/components/ProjectAcceptanceReportCard';
import { useHoldback } from 'features/holdback/services/holdback.api';
import { HoldbackDetailCard } from 'features/holdback/components/HoldbackDetailCard';
import { HoldbackMarkAsReceivedModal } from 'features/holdback/components/HoldbackMarkAsReceivedModal';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewHoldbackDetailScreen = () => {
  const { t } = useTranslation(['global', 'holdback']);
  const { updateHeaderTitle } = useHeaderContext();

  const { holdbackId } = useParams<{ holdbackId: string }>();

  const receiveModal = useDisclosure();

  const holdback = useHoldback(parseInt(holdbackId, 10));

  useLayoutEffect(() => {
    updateHeaderTitle(holdback.data.project.name ?? '', [
      {
        name: t('holdback:myHoldbacks'),
        link: '/sales/holdbacks',
      },
    ]);
  }, [t, holdback.data.project.name, updateHeaderTitle]);

  return (
    <Container size="md">
      <Box p={8}>
        <Flex flexDirection="row" alignItems="center" mb={4}>
          <GotoLink to={`/projects/${holdback.data.project.id}`} label={t('holdback:goTo.project')} />
        </Flex>

        <Grid gap={4}>
          <HoldbackStatusCard holdback={holdback.data} openReceiveModal={receiveModal.onOpen} />
          <HoldbackDetailCard holdback={holdback.data} />

          {holdback.data.project.acceptanceReport && (
            <GridItem colSpan={2}>
              <ProjectAcceptanceReportCard acceptanceReport={holdback.data.project.acceptanceReport} />
            </GridItem>
          )}
        </Grid>
      </Box>

      <HoldbackMarkAsReceivedModal holdback={holdback.data} modal={receiveModal} />
    </Container>
  );
};
