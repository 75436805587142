import { useTranslation } from 'react-i18next';
import { Rule } from 'graneet-form';
import type { FC } from 'react';
import { useEffect, useState, useCallback } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

interface IsRichTextVariablesValidProps {
  message?: string;
}

/**
 * WARNING
 * This is a very hacky technique to see if variables are valid, this component must be placed in toolbarContent of RichText
 * to have access to the lexical context
 */
export const IsRichTextVariablesValid: FC<IsRichTextVariablesValidProps> = ({ message }) => {
  const { t } = useTranslation(['global']);

  const [editor] = useLexicalComposerContext();

  const [isValid, setIsValid] = useState(true);

  useEffect(
    () =>
      editor.registerUpdateListener(() => {
        const firstVariableInError = editor?.getRootElement()?.querySelector('#data-lexical-variable-error');
        setIsValid(!firstVariableInError);
      }),
    [editor],
  );

  const isRichTextVariablesValid = useCallback(() => isValid, [isValid]);

  return <Rule message={message || t('global:form.errors.required')} validationFn={isRichTextVariablesValid} />;
};
