import { Flex } from '@chakra-ui/react';
import type { GraneetBadgeColor } from '@graneet/lib-ui';
import { GraneetBadge } from '@graneet/lib-ui';
import type { QuoteComponentsGroupByType } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useComponentTypes } from 'features/component-type/services/component-type.api';

export const ComponentTypeCell = ({ data }: CustomCellRendererProps<QuoteComponentsGroupByType>) => {
  const componentTypes = useComponentTypes();
  const { t } = useTranslation(['quote']);

  const itemComponent = useMemo(() => {
    if (!data?.componentTypeId) {
      return null;
    }

    const componentType = componentTypes.data.find(
      (component) => component.id.toString() === data.componentTypeId.toString(),
    );

    const colors = [
      'red',
      'blue',
      'cyan',
      'yellow',
      'green',
      'white',
      'gray',
      'orange',
      'indigo',
      'pink',
      'teal',
      'warm_gray',
      'greenBrand',
      'purple',
    ];
    return componentType
      ? {
          ...componentType,
          color: (colors.includes(componentType.color) ? componentType.color : 'gray') as GraneetBadgeColor,
        }
      : null;
  }, [componentTypes, data?.componentTypeId]);

  return (
    <Flex height="100%" alignItems="center" px={2}>
      {itemComponent ? (
        <GraneetBadge color={itemComponent.color}>{itemComponent.name}</GraneetBadge>
      ) : (
        <GraneetBadge color="white">{t('quote:componentType.undefined')}</GraneetBadge>
      )}
    </Flex>
  );
};
