import Big from 'big.js';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { Card, Multiplication, useCurrency } from '@graneet/lib-ui';
import { Form, useForm } from 'graneet-form';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';
import { CheckType, ComputeBigNull } from '@org/quotation-lib';

import { QUOTE_TOTAL_FIELDS, QUOTE_TOTAL_MARGIN_FIELD } from 'features/quote/constants/quotes.constant';
import { useQuotationApi } from 'features/quotation/services/quote.api';

interface FormValues {
  disbursement: number;
  totalMargin: number;
  total: number;
}

interface QuoteMarginCardProps {
  quote: QuoteWithoutRelationsDTO;
}

export const QuoteMarginCard: FC<QuoteMarginCardProps> = ({ quote }) => {
  const { t } = useTranslation(['quote', 'margin']);
  const form = useForm<FormValues>();
  const { useGetRootLot } = useQuotationApi();

  const { data: rootLot } = useGetRootLot(quote.id);
  const { formatAsAmount, mapStringNumberToAmount } = useCurrency();

  const totalDisbursement = useMemo(() => {
    if (!rootLot.flatCostAmount) return null;
    if (!rootLot.shareOfHiddenCostAmount) return rootLot.flatCostAmount;
    return ComputeBigNull.plus(new Big(rootLot.flatCostAmount), new Big(rootLot.shareOfHiddenCostAmount));
  }, [rootLot.flatCostAmount, rootLot.shareOfHiddenCostAmount]);

  useEffect(() => {
    form.setFormValues({
      disbursement: Number(mapStringNumberToAmount(CheckType.stringOrNull(totalDisbursement) ?? '0')),
      totalMargin: Number(rootLot.margin.totalMargin ?? '0'),
      total: Number(mapStringNumberToAmount(quote.amountExVAT ?? '0')),
    });
  }, [rootLot, quote, form, mapStringNumberToAmount, totalDisbursement]);

  const profit = useMemo(
    () => Number(quote.amountExVAT ?? '0') - Number(rootLot.flatCostAmount ?? '0'),
    [quote.amountExVAT, rootLot.flatCostAmount],
  );

  return (
    <Card pt={5}>
      <Form form={form}>
        <Multiplication>
          <Multiplication.Field
            name="disbursement"
            label={t('quote:jobsStep.fields.totalDisbursementExVAT')}
            computedName={QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT}
            isCurrency
            isDisabled
          />

          <Multiplication.Field
            name="totalMargin"
            label={t('margin:fields.totalMargin')}
            computedName={QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT}
            isDisabled
            scale={3}
            min={0}
          />

          <Multiplication.Field
            name="total"
            label={t('quote:fields.priceExVAT')}
            computedName={QUOTE_TOTAL_MARGIN_FIELD}
            isDisabled
            isCurrency
            tooltipProps={{
              p: 0,
            }}
            tooltip={
              <Card>
                <Stack textColor="gray.800">
                  <Flex justifyContent="space-between" alignItems="center" w="15rem">
                    <Text textColor="gray.900" fontWeight="500" whiteSpace="nowrap">
                      {t('margin:fields.profitExVAT')}
                    </Text>
                    <Text textColor="gray.900" fontWeight="600" whiteSpace="nowrap">
                      {formatAsAmount(profit)}
                    </Text>
                  </Flex>
                </Stack>
              </Card>
            }
          />
        </Multiplication>
      </Form>
    </Card>
  );
};
