import type { FC } from 'react';
import { usePlaceholder, useWizardContext, VALIDATION_OUTCOME } from 'graneet-form';

import type { ButtonProps } from '../../Button/Button';
import { Button } from '../../Button/Button';

export interface NextStepButtonProps extends ButtonProps {}

export const NextStepButton: FC<NextStepButtonProps> = ({
  isDisabled: isDisabledProp,
  isLoading: isLoadingProp,
  ...props
}) => {
  const { isStepReady } = useWizardContext();

  const { handleOnNext, isLoading, stepStatus } = usePlaceholder();

  const displayIsNotReady =
    isLoadingProp || isLoading || !isStepReady || stepStatus === VALIDATION_OUTCOME.UNDETERMINED;
  const formIsInvalid = stepStatus === VALIDATION_OUTCOME.INVALID;

  const isDisabled = !!isDisabledProp || formIsInvalid;

  return <Button onClick={handleOnNext} isLoading={displayIsNotReady} isDisabled={isDisabled} {...props} />;
};
