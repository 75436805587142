import { Modal } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback } from 'react';

import { useUpdateCompany } from '../services/company.api';
import type { CompanyInformationFormValues } from '../constants/company.constant';

import { CompanyInformationFields } from './CompanyInformationFields';

import { useAppContext } from 'features/app/contexts/AppContext';

interface CompanyEditInformationModalProps {
  modal: UseDisclosureReturn;
}

export const CompanyEditInformationModal: FC<CompanyEditInformationModalProps> = ({ modal }) => {
  const { t } = useTranslation(['global', 'company']);

  const { currentUser } = useAppContext();
  const companyInitialValue = currentUser.company;

  const form = useForm<CompanyInformationFormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  const updateCompanyMutation = useUpdateCompany();

  // Submit new company information
  const handleSubmit = useCallback(async () => {
    const {
      address,
      city,
      postalCode,
      country,
      billingAddressAddress,
      billingAddressCity,
      billingAddressPostalCode,
      billingAddressCountry,
      hasNoBillingAddress,
      companyNumber,
      corporateName,
      email,
      legalForm,
      nafCode,
      name,
      phoneNumber,
      rcsNumber,
      vatNumber,
    } = form.getFormValues();
    const companyAddress = {
      address: address!,
      city: city!,
      postalCode: postalCode!,
      country: country!,
    };
    const dto = {
      companyNumber: companyNumber!,
      corporateName: corporateName!,
      email: email!,
      legalForm: legalForm!,
      nafCode: nafCode!,
      name: name!,
      phoneNumber: phoneNumber!,
      rcsNumber: rcsNumber!,
      vatNumber: vatNumber!,
      headquartersAddress: companyAddress,
      billingAddress: hasNoBillingAddress
        ? companyAddress
        : {
            address: billingAddressAddress!,
            city: billingAddressCity!,
            postalCode: billingAddressPostalCode!,
            country: billingAddressCountry!,
          },
    };

    updateCompanyMutation.mutate(
      { id: companyInitialValue!.id, dto },
      {
        onSuccess: () => {
          modal.onClose();
        },
      },
    );
  }, [companyInitialValue, form, modal, updateCompanyMutation]);

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      title={t('company:editCompanyModal.title')}
      size="6xl"
      isCentered
      scrollBehavior="inside"
    >
      <Box mb={3}>{t('company:editCompanyModal.description')}</Box>
      <Form form={form}>
        <CompanyInformationFields company={companyInitialValue} isEdit />
      </Form>
      <Modal.Close isDisabled={updateCompanyMutation.isPending} />
      <Modal.PrimaryButton isLoading={updateCompanyMutation.isPending} onClick={handleSubmit} isDisabled={!isFormValid}>
        {t('global:words.c.validate')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
