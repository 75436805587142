export const banishedExtensions: string[] = [
  // Executable Files
  'exe',
  'bat',
  'cmd',
  'sh',
  'php',
  'pl',
  'py',
  'js',
  'rb',
  'jsp',
  'asp',
  'aspx',
  'cgi',
  'dmg',
  'app',
  'pif',
  'application',
  'gadget',
  'msc',
  'jar',
  'vb',
  'ws',
  // DB Files
  'db',
  'sql',
  // Script Files
  'vbs',
  'vbe',
  'jse',
  'wsf',
  'wsh',
  'ps1',
  'psm1',
  'bash',
  // System Files
  'reg',
  'dll',
  'lnk',
  'sys',
  'ini',
  // Document Files with Macros
  'docm',
  'xlsm',
  'pptm',
  // Might contain scripts
  'html',
  'htm',
  'svg',
  'msi',
  'msp',
  'iso',
  'scr',
];
