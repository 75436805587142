import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleOptionIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" strokeWidth="2" stroke="#334e68" {...props}>
    <path
      d="M17 3.5C17 3.96466 17 4.19698 16.9616 4.39018C16.8038 5.18356 16.1836 5.80376 15.3902 5.96157C15.197 6 14.9647 6 14.5 6H3.5C3.03534 6 2.80302 6 2.60982 5.96157C1.81644 5.80376 1.19624 5.18356 1.03843 4.39018C1 4.19698 1 3.96466 1 3.5C1 3.03534 1 2.80302 1.03843 2.60982C1.19624 1.81644 1.81644 1.19624 2.60982 1.03843C2.80302 1 3.03534 1 3.5 1H14.5C14.9647 1 15.197 1 15.3902 1.03843C16.1836 1.19624 16.8038 1.81644 16.9616 2.60982C17 2.80302 17 3.03534 17 3.5ZM17 3.5C17.9319 3.5 18.3978 3.5 18.7654 3.65224C19.2554 3.85523 19.6448 4.24458 19.8478 4.73463C20 5.10218 20 5.56812 20 6.5V6.8C20 7.9201 20 8.48016 19.782 8.90798C19.5903 9.28431 19.2843 9.59027 18.908 9.78201C18.4802 10 17.9201 10 16.8 10H13.2C12.0799 10 11.5198 10 11.092 10.218C10.7157 10.4097 10.4097 10.7157 10.218 11.092C10 11.5198 10 12.0799 10 13.2V14M9.6 21H10.4C10.9601 21 11.2401 21 11.454 20.891C11.6422 20.7951 11.7951 20.6422 11.891 20.454C12 20.2401 12 19.9601 12 19.4V15.6C12 15.0399 12 14.7599 11.891 14.546C11.7951 14.3578 11.6422 14.2049 11.454 14.109C11.2401 14 10.9601 14 10.4 14H9.6C9.03995 14 8.75992 14 8.54601 14.109C8.35785 14.2049 8.20487 14.3578 8.10899 14.546C8 14.7599 8 15.0399 8 15.6V19.4C8 19.9601 8 20.2401 8.10899 20.454C8.20487 20.6422 8.35785 20.7951 8.54601 20.891C8.75992 21 9.03995 21 9.6 21Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
