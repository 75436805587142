import type { IBill } from '../bill/bill.type';
import type { ICredit } from '../credit/credit.type';
import type { IPdf } from '../pdf/pdf.type';
import type { IProject } from '../project/project.type';
import type { ISupplierInvoice } from '../supplier-invoice/supplier-invoice.type';
import type { IOrderWithDirectPaymentAmounts } from '../order/order.type';
import type { ISubProject } from '../sub-project/sub-project.type';
import type { IDownPayment } from '../down-payment/down-payment.type';
import type { IPenalty } from '../penalty/penalty.type';
import type { IAccountingExport } from '../accounting/accounting-export.type';
import type { CUMULATIVE_INPUT_TYPE } from '../common/cumulative-input-type.constant';
import type { IVatDistributionCumulative } from '../vat/vat-distribution-cumulative.type';
import type { IVatDiscountedBasesExtended, IVatDistributionExtended } from '../vat/vat-distribution-extended.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IAccountingConfigSalesAccount } from '../accounting/accounting-config-sales-account.type';
import type { IExternalValidation } from '../external/external-validation.type';

import type { IProgressStatementLine } from './progress-statement-line.type';
import type { IProgressStatementDiscountLine } from './progress-statement-discount-line.type';
import type { IProgressStatementDeduction } from './progress-statement-deduction.type';
import type { IProgressStatementCustomDiscountLine } from './progress-statement-custom-discount-line.type';
import type { IProgressStatementResponseDTO } from './dtos/progress-statement-response.dto';

export enum PROGRESS_STATEMENT_STATUSES {
  DRAFT = 'DRAFT',
  VALIDATED = 'VALIDATED',
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export interface IProgressStatement {
  id: number;

  historyFamilyId: string;

  groupId: number;

  subGroupId: number;

  externalId: string;

  externalComment: string | null;

  name: string;

  invoiceNumber: string | null;

  billingDate: Date;

  downPaymentAmortizationAmount: number;

  priceRevisionExVAT: number;

  priceRevisionIncVAT: number;

  priceRevisionDefinitiveExVAT: number | null;

  status: PROGRESS_STATEMENT_STATUSES;

  isArchived: boolean;

  cumulativeInputType: CUMULATIVE_INPUT_TYPE;

  note: string;

  acceptanceDate: Date | null;

  validationDate: Date | null;

  amountExVAT: number;

  amountIncVAT: number;

  hasReversalOfLiability: boolean;

  holdbackAmount: number;

  // @[ff: project-global-parameters]
  nonCumulativeHoldbackWithPaymentGuaranteeAmountIncVAT: number | null;

  // @[ff: project-global-parameters]
  cumulativeHoldbackWithPaymentGuaranteeAmountIncVAT: number | null;

  // @[ff: project-global-parameters]
  nonCumulativePaymentGuaranteeAmountIncVAT: number | null;

  cumulativeProgressAmountExVAT: number;

  cumulativeProgressAmountIncVAT: number;

  // @[ff: accounting-standards] remove nullable
  nonCumulativeProgressAmountExVAT: number | null;

  // @[ff: accounting-standards] remove nullable
  nonCumulativeProgressAmountIncVAT: number | null;

  cumulativeProgressPercentage: number;

  cumulativeDownPaymentAmortizationAmountIncVAT: number | null;

  netBilledIncVAT: number;

  netToPaidIncVAT: number;

  vatDistribution: IVatDistributionCumulative | IVatDistributionExtended | IVatDiscountedBasesExtended;

  isDirect: boolean;

  displayOptionsAnnexPDF: boolean;

  createdAt: Date;

  updatedAt: Date;

  project?: IProject;

  tags: string[];

  subProject?: ISubProject | null;

  lines?: IProgressStatementLine[];

  discountLines?: IProgressStatementDiscountLine[];

  progressStatementDeductions?: IProgressStatementDeduction[];

  customDiscountLines?: IProgressStatementCustomDiscountLine[];

  supplierInvoices?: ISupplierInvoice[];

  bill?: IBill | null;

  pdf?: IPdf | null;

  credit?: ICredit | null;

  activeDownPayment?: IDownPayment | null;

  penalties?: IPenalty[];

  accountingExport?: IAccountingExport | null;

  accountingConfigSalesAccount?: IAccountingConfigSalesAccount | null;

  externalValidation?: IExternalValidation | null;
}

export interface IProgressStatementWithPaymentDeadline extends IProgressStatement {
  paymentDeadline?: Date | null;
}

export type IProgressStatementWithRelations = RequiredByKeys<
  IProgressStatement,
  | 'credit'
  | 'bill'
  | 'pdf'
  | 'lines'
  | 'discountLines'
  | 'customDiscountLines'
  | 'supplierInvoices'
  | 'penalties'
  | 'project'
  | 'subProject'
>;

export interface IProgressStatementWithDirectPayments extends IProgressStatementWithRelations {
  directPaymentAssociations: { supplierInvoice: ISupplierInvoice; order: IOrderWithDirectPaymentAmounts }[];
}

export type IProgressStatementWithDirectPaymentsAndAccounting = IProgressStatementWithDirectPayments &
  IProgressStatementResponseDTO;

export interface IProgressStatemenWithAccountingExportInfo extends IProgressStatement {
  // Is progress statement exported in accounting ?
  isAccountingExported: boolean;

  // Does the progress statement have any payment exported in accounting ?
  hasPaymentAccountingExported: boolean;
}
