import { Flex, HStack, Text } from '@chakra-ui/react';
import type { IOrder } from '@graneet/business-logic';
import { sumObjects } from '@graneet/business-logic';
import { PriceAdvanced, Tooltip } from '@graneet/lib-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IAssociatedOrdersAmountsProps {
  orders: IOrder[];
  invoiceAmountExVAT: number;
}

export const AssociatedOrdersAmounts = ({ orders, invoiceAmountExVAT }: IAssociatedOrdersAmountsProps) => {
  const { t } = useTranslation(['supplierInvoices', 'orders']);
  const orderTotal = useMemo(() => sumObjects(orders, 'amountExVAT'), [orders]);

  const shiftBetweenOrder = useMemo(() => orderTotal - invoiceAmountExVAT, [invoiceAmountExVAT, orderTotal]);

  return (
    <>
      {orders.length ? (
        <Flex w="100%" mt="3" flexDir="column" justifyContent="flex-end" alignItems="flex-end">
          {orders.length > 1 && (
            <Flex>
              <Text color="gray.700" fontWeight={400} fontSize="sm">
                {t('supplierInvoices:associate.orderTotal')} :{' '}
              </Text>
              <PriceAdvanced color="black" fontWeight={600} fontSize="16px" amount={orderTotal} ml="1rem" as="b" />
            </Flex>
          )}
          <Flex>
            <Tooltip shouldWrapChildren label={t('orders:associate.shiftTooltip')}>
              <HStack>
                <Text color="gray.700" fontWeight={400} fontSize="sm">
                  {t('supplierInvoices:associate.shiftWithPlanned')} :{' '}
                </Text>
                <PriceAdvanced
                  color="black"
                  fontWeight={600}
                  fontSize="16px"
                  amount={shiftBetweenOrder}
                  ml="1rem"
                  as="b"
                />
              </HStack>
            </Tooltip>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};
