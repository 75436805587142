import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleDashboardIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 10 14" {...props}>
    <path
      d="M9 12.3333V5.66667M5 12.3333V1.66667M1 12.3333V8.33333"
      stroke="#627D98"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
