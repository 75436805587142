import { useEffect } from 'react';
import type { IPdf } from '@graneet/business-logic';
import { isPdfBeingGenerated, PDF_STATUSES } from '@graneet/business-logic';
import type { Response } from '@graneet/lib-ui';
import type { AxiosError } from 'axios';

import { subscribeToUpdate } from '../services/pdf.api';

import { useData } from 'features/api/hooks/useData';

export const subscribeToPDFUpdates = (
  pdf: Pick<IPdf, 'apiId'>,
  callback: (status: PDF_STATUSES) => Promise<void> | void,
): EventSource => {
  const event = subscribeToUpdate(pdf.apiId!);
  event.onmessage = async (e) => {
    if ([PDF_STATUSES.GENERATED, PDF_STATUSES.ERROR].includes(e.data)) {
      await callback(e.data);
      event.close();
    }
  };
  event.onerror = () => {
    event.close();
  };
  return event;
};

interface DataStateLoading {
  error: null;
  loading: true;
  versions: null;
}

interface DataStateError {
  error: AxiosError;
  loading: false;
  versions: null;
}

interface DataStateFetched<T = unknown> {
  error: null;
  loading: false;
  versions: T;
}

export type DataState<T = unknown> = DataStateLoading | DataStateError | DataStateFetched<T>;

export function usePdfVersionsTanStackQuery<
  T extends { pdf: Pick<IPdf, 'apiId' | 'status'> | null },
  R extends { archived: Array<T | null>; current: T | null } | null,
>(versions: R, refetch: () => void) {
  useEffect(() => {
    const events: EventSource[] = [];
    const allVersions = [...(versions?.archived || [])];
    if (versions?.current) {
      allVersions.push(versions?.current);
    }

    allVersions.forEach((version) => {
      const pdf = version?.pdf;
      if (pdf && isPdfBeingGenerated(pdf)) {
        events.push(subscribeToPDFUpdates(pdf, refetch));
      }
    });

    return () => {
      events.forEach((event) => {
        event.close();
      });
    };
  }, [refetch, versions]);
}

export const usePdfVersions = <
  T extends { pdf: Pick<IPdf, 'apiId' | 'status'> | null },
  R extends { archived: Array<T | null>; current: T | null } | null,
>(
  getter: () => Promise<Response<R>>,
): DataState<R> & { fetch(...params: unknown[]): Promise<void> } => {
  const { data: versions, error, loading, fetch } = useData(getter);

  useEffect(() => {
    const events: EventSource[] = [];
    const allVersions = [...(versions?.archived || [])];
    if (versions?.current) {
      allVersions.push(versions?.current);
    }

    allVersions.forEach((version) => {
      const pdf = version?.pdf;
      if (pdf && isPdfBeingGenerated(pdf)) {
        events.push(subscribeToPDFUpdates(pdf, () => fetch()));
      }
    });

    return () => {
      events.forEach((event) => {
        event.close();
      });
    };
  }, [fetch, versions]);

  return {
    versions,
    error,
    loading,
    fetch,
  } as any;
};
