import type { FC, ReactElement } from 'react';
import { cloneElement, useCallback, Children } from 'react';
import type { FieldRenderProps, FieldValues } from 'graneet-form';
import { Field } from 'graneet-form';
import type { ButtonProps } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';

import type { KeysMatching } from '../../../utils';

const BUTTON_PROPS = {
  borderWidth: 2,
  borderColor: 'gray.300',
  color: 'gray.800',
};

const BUTTON_ACTIVE_PROPS = {
  borderWidth: 2,
  borderColor: 'greenBrand.light',
  color: 'greenBrand.light',
};

type ValueType = string;

type ButtonFieldOptionProps<TValue extends ValueType> = {
  label: string;
  value: TValue;
  properties?: FieldRenderProps<Record<TValue, TValue>, TValue>;
};

export const ButtonFieldOption: FC<ButtonFieldOptionProps<ValueType> & ButtonProps> = ({
  label,
  value,
  properties = {
    onChange: () => {},
    onBlur: () => {},
  },
  ...props
}) => {
  const { value: valueFromProperties, onChange, onBlur } = properties;

  const isChecked = value === valueFromProperties;

  const handleOnChange = useCallback(() => {
    onChange(value);
    onBlur();
  }, [onChange, onBlur, value]);

  return (
    <Button
      size={props.size || 'sm'}
      variant="outline"
      onClick={handleOnChange}
      {...(isChecked ? BUTTON_ACTIVE_PROPS : BUTTON_PROPS)}
      {...props}
    >
      {label}
    </Button>
  );
};

type ButtonFieldValue = string | undefined;

export interface ButtonFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, ButtonFieldValue> = KeysMatching<T, ButtonFieldValue>,
> extends Omit<ButtonProps, 'name'> {
  name: K;
  children: ReactElement[];
}

export const ButtonField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, ButtonFieldValue> = KeysMatching<T, ButtonFieldValue>,
>({
  name,
  children,
  ...props
}: ButtonFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    render={(properties) => (
      <>
        {Children.map(children, (child) =>
          cloneElement(child, {
            properties,
            ...props,
          }),
        )}
      </>
    )}
  />
);
