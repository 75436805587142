import type {
  IDeleteSlotsDTO,
  IProject,
  ITimeSlot,
  ITimeSlotApproveDTO,
  ITimeSlotActivePlanningResponseDTO,
  ITimeSlotExportDTO,
  ITimeSlotExportResponseDTO,
  ITimeSlotSharePlanningDTO,
  ITimeTrackingDTO,
  ITimeTrackingProjectStatsResponse,
  ITimeTrackingResponse,
  IUpdateTimeTrackingDTO,
  IWorker,
  TimeTrackingSlotsGroupBy,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useSuspenseQuery } from '@tanstack/react-query';

import { ExternalApi } from 'config/externalApi';
import { api } from 'features/api/services/api.service';
import { proxyHelperTanStack } from 'features/api/services/proxyHelper';

const TIME_SLOT_PATH = '/time-slot';

const timeSlotApi = () => ExternalApi.getExternalApi().getTimeSlotApi();

export const timeSlotKeyFactory = createQueryKeys('time-slots', {
  list: (projectId: number) => ({
    queryKey: [TIME_SLOT_PATH, projectId],
    queryFn: () => proxyHelperTanStack(() => timeSlotApi().getProjectStats(projectId)),
  }),
});

// TODO: for retrocompatibility we keep both versions of the same function to remove #0021
export function useTimeTrackingStats(projectId: number) {
  return useSuspenseQuery(timeSlotKeyFactory.list(projectId));
}

type SlotsQuery = { groupBy: TimeTrackingSlotsGroupBy; date?: string | Date; query?: string };
export const getWeekSlots = (data: SlotsQuery) =>
  api.get<SlotsQuery, ITimeTrackingResponse<IProject | IWorker>>(TIME_SLOT_PATH, data);

export const getMonthSlots = (data: SlotsQuery) =>
  api.get<SlotsQuery, ITimeTrackingResponse<IProject | IWorker>>(`${TIME_SLOT_PATH}/monthly`, data);

export const getSlots = (data: SlotsQuery & { view: 'week' | 'month' }) => {
  const { view, ...queryData } = data;
  return view === 'week' ? getWeekSlots(queryData) : getMonthSlots(queryData);
};

export const getTimeTrackingStatsLegacy = (projectId: number, query: string | null) =>
  api.get<{ query: string | null }, ITimeTrackingProjectStatsResponse>(`${TIME_SLOT_PATH}/${projectId}/stats`, {
    query,
  });

export const getActiveWorkers = (payload: { startDate: string; endDate: string }) =>
  api.get<
    {
      startDate: string;
      endDate: string;
    },
    ITimeSlotActivePlanningResponseDTO
  >(`${TIME_SLOT_PATH}/active-workers`, payload);

// -- Mutation
export const createTimeSlots = (timeTrackingDTO: ITimeTrackingDTO) =>
  api.post<ITimeTrackingDTO, ITimeSlot>(TIME_SLOT_PATH, timeTrackingDTO);

export const deleteSlot = (id: number) => api.delete<never, void>(`${TIME_SLOT_PATH}/${id}`);

export const deleteSlots = (ids: IDeleteSlotsDTO['ids']) =>
  api.delete<IDeleteSlotsDTO, void>(`${TIME_SLOT_PATH}`, { ids });

export const updateSlot = (id: number, updateTimeTrackingDTO: IUpdateTimeTrackingDTO) =>
  api.patch<IUpdateTimeTrackingDTO, ITimeSlot>(`${TIME_SLOT_PATH}/${id}`, updateTimeTrackingDTO);

export const generateTimeSlotExport = (exportTimeSlotData: ITimeSlotExportDTO) =>
  api.post<ITimeSlotExportDTO, ITimeSlotExportResponseDTO>(`${TIME_SLOT_PATH}/export`, exportTimeSlotData);

export const approveTimeSlots = (ids: number[]) =>
  api.patch<ITimeSlotApproveDTO, void>(`${TIME_SLOT_PATH}/approve`, { ids });

export const sharePlanning = (dto: ITimeSlotSharePlanningDTO) =>
  api.post<ITimeSlotSharePlanningDTO, void>(`${TIME_SLOT_PATH}/share-planning`, dto);
