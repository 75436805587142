import { useState } from 'react';
import { Modal, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, useForm, useFormStatus, VALIDATION_OUTCOME } from 'graneet-form';
import type {
  ICredit,
  IDownPayment,
  IHoldback,
  IInvoice,
  IProgressStatement,
  STATEMENT_TYPES,
} from '@graneet/business-logic';

import { Rule } from 'features/form/rules/Rule';
import { RuleIsInvoiceNumberAvailable } from 'features/statement/rules/RuleIsInvoiceNumberAvailable';

interface FormValues {
  invoiceNumber: string;
}

type TypeResponseMap = {
  [STATEMENT_TYPES.PROGRESS_STATEMENT]: IProgressStatement;
  [STATEMENT_TYPES.CREDIT]: ICredit;
  [STATEMENT_TYPES.DOWN_PAYMENT]: IDownPayment;
  [STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT]: IProgressStatement;
  [STATEMENT_TYPES.INVOICE]: IInvoice;
  [STATEMENT_TYPES.HOLDBACK]: IHoldback;
};

interface StatementEditInvoiceNumberModalProps<T extends STATEMENT_TYPES> {
  isOpen: boolean;
  onClose(): void;
  updateInvoiceNumber(newInvoiceNumber: string): Promise<TypeResponseMap[T]>;
  completeLabel: string;
  title: string;
  statementId: number;
  statementType: T;
}

export const StatementEditInvoiceNumberModal = <T extends STATEMENT_TYPES>({
  isOpen,
  onClose,
  updateInvoiceNumber,
  completeLabel,
  title,
  statementId,
  statementType,
}: StatementEditInvoiceNumberModalProps<T>) => {
  const { t } = useTranslation(['global', 'invoice']);

  const [isLoading, setIsLoading] = useState(false);
  const validateInvoiceForm = useForm<FormValues>();
  const { isValid: isFormValid, formStatus } = useFormStatus(validateInvoiceForm);
  const { getFormValues } = validateInvoiceForm;

  const onConfirm = async () => {
    setIsLoading(true);

    const { invoiceNumber } = getFormValues();

    await updateInvoiceNumber(invoiceNumber!);
    setIsLoading(false);

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <Form form={validateInvoiceForm}>
        <TextField<FormValues> name="invoiceNumber" label={t('invoice:fields.invoiceNumber')} isRequired>
          <Rule.IsRequired />
          <RuleIsInvoiceNumberAvailable statementId={statementId} type={statementType} />
        </TextField>

        <Modal.SecondaryButton onClick={onClose}>{t('global:words.c.cancel')}</Modal.SecondaryButton>

        <Modal.PrimaryButton
          isLoading={isLoading || formStatus === VALIDATION_OUTCOME.UNDETERMINED}
          isDisabled={!isFormValid}
          onClick={onConfirm}
        >
          {completeLabel}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
