import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useRef } from 'react';

export const useCounterLoader = (listener: Dispatch<SetStateAction<boolean>>) => {
  const rowsRenderedRef = useRef(new Set());
  const isFullyLoadedRef = useRef(false);
  const rowNumberRef = useRef<number>();

  const addLineRendered = useCallback(
    (id: string) => {
      if (isFullyLoadedRef.current) return;
      rowsRenderedRef.current.add(id);

      if (rowNumberRef && rowsRenderedRef.current.size === rowNumberRef.current) {
        isFullyLoadedRef.current = true;
        listener(true);
      }
    },
    [listener],
  );

  const updateRowsCount = useCallback(
    (newRowNumber: number) => {
      rowNumberRef.current = newRowNumber;

      if (rowsRenderedRef.current.size === newRowNumber) {
        isFullyLoadedRef.current = true;
        listener(true);
      }
    },
    [listener],
  );

  return useMemo(
    () => ({
      addLineRendered,
      updateRowsCount,
    }),
    [addLineRendered, updateRowsCount],
  );
};
