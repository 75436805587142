import type { FC } from 'react';
import type { AlertProps, TextProps } from '@chakra-ui/react';
import { Alert, AlertDescription, AlertTitle, Text } from '@chakra-ui/react';

export interface AlertMessageProps extends AlertProps {
  type?: AlertProps['status'];
  iconColor?: string;
}

export const AlertMessage: FC<AlertMessageProps> = ({ type = 'info', children = null, title = '', ...otherProps }) => {
  const propsText: TextProps = {};

  if (type === 'error') {
    propsText.color = 'red.700';
  }

  return (
    <Alert status={type} rounded="md" {...otherProps}>
      <Text {...propsText}>
        <i className="ri-alert-line" />
      </Text>

      <AlertTitle mr={2}>{title}</AlertTitle>

      {children && (
        <AlertDescription>
          <Text {...propsText}>{children}</Text>
        </AlertDescription>
      )}
    </Alert>
  );
};
