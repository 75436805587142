import {
  type DownPaymentArchiveDetails,
  HISTORY_ENTITY_TYPE,
  type InvoiceArchiveDetails,
  type IPdf,
  isDownPaymentProForma,
  isInvoiceProForma,
  isProgressStatementProforma,
  type ProgressStatementArchiveDetails,
  type QuoteArchiveDetails,
} from '@graneet/business-logic';

import type { IArchiveDetailsExtended } from '../components/HistoryCard';

export enum ActiveTab {
  ARCHIVE = 'archive',
  EMAIL = 'email',
  ALL_ACTIVITY = 'allActivity',
}

interface IEntityTabsConfig {
  activeTabs: ActiveTab[];
  translatedGender: 'male' | 'female';
  getArchiveFile: (archive: IArchiveDetailsExtended) => {
    isProforma: boolean;
    date: Date | null;
    invoiceNumber: string | null;
  };
}

export const entityTabsConfig: Record<HISTORY_ENTITY_TYPE, IEntityTabsConfig> = {
  [HISTORY_ENTITY_TYPE.QUOTE]: {
    translatedGender: 'male',
    activeTabs: [ActiveTab.ALL_ACTIVITY, ActiveTab.EMAIL, ActiveTab.ARCHIVE],
    getArchiveFile: (archive) => {
      const specificArchive = archive as Omit<QuoteArchiveDetails, 'pdfId'> & { pdf: IPdf | null };
      return {
        isProforma: false,
        date: specificArchive.date,
        invoiceNumber: specificArchive.refCode,
      };
    },
  },
  [HISTORY_ENTITY_TYPE.INVOICE]: {
    translatedGender: 'female',
    activeTabs: [ActiveTab.ALL_ACTIVITY, ActiveTab.EMAIL, ActiveTab.ARCHIVE],
    getArchiveFile: (archive) => {
      const specificArchive = archive as Omit<InvoiceArchiveDetails, 'pdfId'> & { pdf: IPdf | null };
      return {
        isProforma: specificArchive.statusWhenArchived
          ? isInvoiceProForma({ status: specificArchive.statusWhenArchived })
          : false,
        date: specificArchive.billingDate,
        invoiceNumber: specificArchive.invoiceNumber,
      };
    },
  },
  [HISTORY_ENTITY_TYPE.DOWN_PAYMENT]: {
    translatedGender: 'male',
    activeTabs: [ActiveTab.ALL_ACTIVITY, ActiveTab.EMAIL, ActiveTab.ARCHIVE],
    getArchiveFile: (archive) => {
      const specificArchive = archive as Omit<DownPaymentArchiveDetails, 'pdfId'> & { pdf: IPdf | null };
      return {
        isProforma: specificArchive.statusWhenArchived
          ? isDownPaymentProForma({ ...specificArchive, status: specificArchive.statusWhenArchived })
          : false,
        date: specificArchive.billingDate,
        invoiceNumber: specificArchive.invoiceNumber,
      };
    },
  },
  [HISTORY_ENTITY_TYPE.PROGRESS_STATEMENT]: {
    translatedGender: 'female',
    activeTabs: [ActiveTab.ALL_ACTIVITY, ActiveTab.EMAIL, ActiveTab.ARCHIVE],
    getArchiveFile: (archive) => {
      const specificArchive = archive as Omit<ProgressStatementArchiveDetails, 'pdfId'> & { pdf: IPdf | null };
      return {
        isProforma: specificArchive.statusWhenArchived
          ? isProgressStatementProforma({ status: specificArchive.statusWhenArchived })
          : false,
        date: specificArchive.billingDate,
        invoiceNumber: specificArchive.invoiceNumber,
      };
    },
  },
  [HISTORY_ENTITY_TYPE.ORDER]: {
    translatedGender: 'female',
    activeTabs: [ActiveTab.ALL_ACTIVITY, ActiveTab.EMAIL],
    getArchiveFile: () => ({
      isProforma: false,
      date: null,
      invoiceNumber: null,
    }),
  },
  [HISTORY_ENTITY_TYPE.CREDIT]: {
    translatedGender: 'male',
    activeTabs: [ActiveTab.ALL_ACTIVITY, ActiveTab.EMAIL],
    getArchiveFile: () => ({
      isProforma: false,
      date: null,
      invoiceNumber: null,
    }),
  },
  [HISTORY_ENTITY_TYPE.SUPPLIER_INVOICE]: {
    translatedGender: 'female',
    activeTabs: [],
    getArchiveFile: () => ({
      isProforma: false,
      date: null,
      invoiceNumber: null,
    }),
  },
  [HISTORY_ENTITY_TYPE.BILL]: {
    translatedGender: 'female',
    activeTabs: [ActiveTab.ALL_ACTIVITY],
    getArchiveFile: () => ({
      isProforma: false,
      date: null,
      invoiceNumber: null,
    }),
  },
  [HISTORY_ENTITY_TYPE.HOLDBACK]: {
    translatedGender: 'female',
    activeTabs: [],
    getArchiveFile: () => ({
      isProforma: false,
      date: null,
      invoiceNumber: null,
    }),
  },
};
