import type { FC } from 'react';
import { Avatar, HStack, Text, VStack } from '@chakra-ui/react';
import type { EllipsisTextProps } from '@graneet/lib-ui';
import { EllipsisText, formatDataOrEmpty } from '@graneet/lib-ui';
import type { IWorker } from '@graneet/business-logic';

import { formatUserDisplayName } from '../../user/services/user.util';

interface WorkerCardProps {
  worker: Pick<IWorker, 'firstName' | 'lastName' | 'role'>;
  textProps?: Partial<EllipsisTextProps>;
}

export const WorkerCard: FC<WorkerCardProps> = ({ worker, textProps }) => (
  <HStack spacing={3} py={1}>
    <Avatar size="sm" name={formatUserDisplayName(worker)} />
    <VStack spacing={1} align="flex-start" lineHeight="1.5rem">
      <Text fontWeight="600" color="gray.800">
        {formatUserDisplayName(worker)}
      </Text>
      <EllipsisText {...textProps}>{formatDataOrEmpty(worker.role)}</EllipsisText>
    </VStack>
  </HStack>
);
