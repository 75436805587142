import type { EmailAutocompleteContact } from '@graneet/lib-ui';
import {
  ConfirmDeletionModal,
  DocumentStatusCard,
  DocumentStatusCardActionsList,
  SellsheetCard,
  useToast,
} from '@graneet/lib-ui';
import {
  QUOTE_STATUS,
  QUOTE_TRANSITION_ERROR,
  QUOTE_TRANSITION_EVENT,
  Quote,
  QuoteStateMachine,
} from '@org/quotation-lib';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Text, VStack, useDisclosure, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { IProjectWithRelations, IQuoteUpdateDTO } from '@graneet/business-logic';
import { PDF_STATUSES, PERMISSION, getEmailVariableData } from '@graneet/business-logic';
import { QuoteStatus, type QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';

import {
  AlertQuoteStatusCompletionErrorModal,
  type IQuoteCheckResponseDTO,
} from '../modals/AlertQuoteStatusCompletionErrorModal';
import { ConfirmQuoteAsLostModal } from '../modals/ConfirmQuoteAsLostModal';
import { AcceptQuoteModal } from '../modals/AcceptQuoteModal/AcceptQuoteModal';
import { CreateQuoteModal } from '../modals/CreateQuoteModal';
import { EditQuoteStatusModal } from '../modals/EditQuoteStatusModal';

import { useQuotationApi } from 'features/quotation/services/quote.api';
import { quoteWithoutRelationShipToQuoteObjectMapper } from 'features/quotation/quote-common/mappers/quoteComposeToQuoteObjectMapper';
import { QuoteStatusBadge } from 'features/quotation/quote-common/components/badges/QuoteStatusBadge';
import { getQuotationActions, getQuotationStatusCardIcon } from 'features/quote/services/quote.actions';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import type { MailingModalProps } from 'features/mailing/components/MailingModal/MailingModal';
import { MailingModal } from 'features/mailing/components/MailingModal/MailingModal';
import { sendMail } from 'features/mailing/services/mailing.api';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { useQuoteResetStore } from 'features/quotation/quote-common/hooks/useQuoteResetStore';
import { getDownloadUrl, getPreviewUrl } from 'features/pdf/services/pdf.api';
import { formatFileName } from 'features/file/services/file.util';

export const QuoteSellSheetStatusCard: FC<{ quoteId: string }> = ({ quoteId }) => {
  const { t } = useTranslation(['global', 'quote', 'mailing']);
  const history = useHistory();
  const toast = useToast();
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);
  const hasCreatePermission = usePermissions([PERMISSION.CREATE_QUOTES]);
  const hasAcceptPermission = usePermissions([PERMISSION.ACCEPT_QUOTES]);
  const [isPdfGenerated, setIsPdfGenerated] = useState(false);
  const { createRedirectionWithSavedFilters } = useFiltersQuery();
  const selectedProjectRef = useRef<IProjectWithRelations | undefined>(undefined);
  const settledDateRef = useRef<string>();

  const completeErrorsRef = useRef<IQuoteCheckResponseDTO>({
    areMandatoryFieldsFilled: true,
    incompleteJobsCount: 0,
    isClientAttached: true,
  });

  const {
    useGetProjectById,
    lazyGetProjectById,
    useGetContactsByIds,
    refetchPdfsByIds,
    useGetClientById,
    useQuoteDuplicate,
    useQuoteDelete,
    useQuoteChangeStatus,
    useGetQuoteById,
    refetchQuoteById,
    useGetPdfsByIds,
    useGetContractByQuoteId,
    useGetQuoteVersionsById,
    lazyGetIncompleteQuoteAssetsCount,
    lazyGetQuoteById,
  } = useQuotationApi();
  const duplicateQuoteMutation = useQuoteDuplicate();
  const deleteQuoteMutation = useQuoteDelete();
  const changeStatusMutation = useQuoteChangeStatus();

  // Quote Modals
  const editModal = useDisclosure();
  const completeErrorsModal = useDisclosure();
  const markQuoteAsLostModal = useDisclosure();
  const acceptQuoteProjectModal = useDisclosure();
  const mailingModal = useDisclosure();
  const duplicationModal = useDisclosure();
  const deletionModal = useDisclosure();

  const { data: quote } = useGetQuoteById(quoteId);
  const { data: pdfs } = useGetPdfsByIds(quote && quote.pdfId ? [quote.pdfId] : []);
  const { data: contract } = useGetContractByQuoteId(quoteId);
  const { data: archivedQuotes } = useGetQuoteVersionsById(quote.id);

  const { data: client } = useGetClientById(quote.clientId);
  const { data: contacts } = useGetContactsByIds(
    quote.contacts ? quote.contacts.map((contact) => contact.id) : undefined,
  );
  const { data: project } = useGetProjectById(quote?.projectId);

  const pdf = pdfs?.find((file) => file.id === quote.pdfId);

  useEffect(() => {
    if (pdf?.status === PDF_STATUSES.GENERATING) {
      subscribeToPDFUpdates({ apiId: pdf!.apiId }, (pdfStatus) => {
        refetchPdfsByIds([pdf.id]);
        setIsPdfGenerated(pdfStatus === PDF_STATUSES.GENERATED);
      });
    } else if (pdf?.status === PDF_STATUSES.GENERATED) {
      setIsPdfGenerated(true);
    }
  }, [pdf, refetchPdfsByIds]);

  useEffect(() => {
    if (project && !selectedProjectRef.current) {
      selectedProjectRef.current = project;
    }
  }, [project]);

  const quoteContacts = useMemo(() => {
    const quoteContactsMap = new Map<string, boolean>();
    if (quote.contacts) {
      quote.contacts.forEach((contact) => {
        quoteContactsMap.set(contact.id, contact.isDefaultEmailRecipient);
      });
    }
    if (!contacts) {
      return [];
    }

    return contacts.map((contact) => ({
      ...contact,
      isQuoteDefaultEmailRecipient: quoteContactsMap.get(contact.id) ?? false,
    }));
  }, [contacts, quote.contacts]);

  const quoteStateMachine = useMemo(() => {
    const quoteObject = quoteWithoutRelationShipToQuoteObjectMapper(quote);

    return QuoteStateMachine.execute(
      new Quote(
        {
          isAutomaticIndexActivated: quote.isAutomaticIndexActivated,
        },
        quoteObject,
      ),
      {
        areAllChildrenCompleted: true,
      },
    );
  }, [quote]);

  const state = useMemo<QUOTE_STATUS>(() => quoteStateMachine.getSnapshot().value, [quoteStateMachine]);
  const statusCardIcon = useMemo(() => getQuotationStatusCardIcon(quote.status), [quote.status]);

  const footerText = useMemo(() => {
    if (state === QUOTE_STATUS.IMPORTING) {
      return t('quote:cards.state.breakdownBeingImported');
    }

    return undefined;
  }, [state, t]);

  const onReloadQuote = useCallback(() => {
    refetchQuoteById(quote.id);
  }, [quote.id, refetchQuoteById]);

  const emptyCallback = useCallback(() => {}, []);

  const onCompleteQuote = useCallback(async () => {
    try {
      const liveQuote = await lazyGetQuoteById(quoteId);
      const liveIncompleteAssetsCount = await lazyGetIncompleteQuoteAssetsCount(quoteId);
      setIsStatusUpdating(true);

      const quoteObject = quoteWithoutRelationShipToQuoteObjectMapper(quote);

      const sm = QuoteStateMachine.execute(
        new Quote(
          {
            isAutomaticIndexActivated: quote.isAutomaticIndexActivated,
          },
          quoteObject,
        ),
        {
          areAllChildrenCompleted: true,
        },
      );

      sm.send({ type: QUOTE_TRANSITION_EVENT.COMPLETE_QUOTE });

      if (
        sm.getSnapshot().value === QUOTE_STATUS.COMPLETED &&
        liveIncompleteAssetsCount === 0 &&
        liveQuote.clientId &&
        liveQuote.addedAt &&
        liveQuote.validityDuration
      ) {
        await changeStatusMutation.mutateAsync({
          quoteId: quote.id,
          status: QuoteStatus.Completed,
        });
        toast.success(t('global:words.c.success'), t('quote:completeModal.toastSuccess'));

        onReloadQuote();
      } else {
        let hasError = false;
        const errors = sm.getSnapshot().context.guardErrors;
        if (errors.includes(QUOTE_TRANSITION_ERROR.NO_CLIENT)) {
          if (!liveQuote.clientId) {
            hasError = true;
          }
        }
        if (errors.includes(QUOTE_TRANSITION_ERROR.NO_ADDED_DATE)) {
          if (!liveQuote.addedAt) {
            hasError = true;
          }
        }
        if (errors.includes(QUOTE_TRANSITION_ERROR.NO_VALIDITY_DURATION)) {
          if (!liveQuote.validityDuration) {
            hasError = true;
          }
        }
        if (
          !(liveIncompleteAssetsCount === 0 && liveQuote.clientId && liveQuote.addedAt && liveQuote.validityDuration)
        ) {
          hasError = true;
        }
        if (hasError) {
          completeErrorsRef.current = {
            areMandatoryFieldsFilled: !!liveQuote.addedAt && !!liveQuote.validityDuration,
            incompleteJobsCount: liveIncompleteAssetsCount,
            isClientAttached: !!liveQuote.clientId,
          };
          completeErrorsModal.onOpen();
        } else {
          await changeStatusMutation.mutateAsync({
            quoteId: quote.id,
            status: QuoteStatus.Completed,
          });
          toast.success(t('global:words.c.success'), t('quote:completeModal.toastSuccess'));

          onReloadQuote();
        }
      }
    } finally {
      setIsStatusUpdating(false);
    }
  }, [
    changeStatusMutation,
    completeErrorsModal,
    quote,
    onReloadQuote,
    lazyGetIncompleteQuoteAssetsCount,
    lazyGetQuoteById,
    quoteId,
    t,
    toast,
  ]);

  const onMailSubmit = useCallback<MailingModalProps['onSubmit']>(
    (data) => sendMail(data.dto, data.attachments, data.mode),
    [],
  );

  const defaultMailContacts = useMemo(
    () =>
      quoteContacts
        .filter((quoteContact) => quoteContact.isQuoteDefaultEmailRecipient && quoteContact.email)
        .map((quoteContact) => ({
          firstName: quoteContact.firstName,
          lastName: quoteContact.lastName,
          email: quoteContact.email,
          role: quoteContact.role ?? undefined,
        })) as EmailAutocompleteContact[],
    [quoteContacts],
  );

  const deleteQuote = useCallback(async () => {
    await deleteQuoteMutation.mutateAsync(quote.id);

    toast.success(t('quote:cards.delete.success', { name: quote.name }));
    createRedirectionWithSavedFilters('/opportunities/quotation', { replace: true })();
  }, [deleteQuoteMutation, quote.id, quote.name, toast, t, createRedirectionWithSavedFilters]);

  const duplicateQuote = useCallback(
    async (values: IQuoteUpdateDTO) => {
      const newQuote = await duplicateQuoteMutation.mutateAsync({
        quoteId: quote.id,
        name: values.name,
        hasReversalOfLiability: values.hasReversalOfLiability,
        addedAt: values.date,
        startedAt: values.estimatedStartDate,
        refCode: values.refCode,
        workDuration: values.workDuration,
        validityDuration: values.validityDuration,
        comment: values.comment,
        mentions: values.mentions,
        note: values.note,
        projectAddress: values.projectAddress
          ? {
              address: values.projectAddress.address ?? null,
              city: values.projectAddress.city ?? null,
              postalCode: values.projectAddress.postalCode ?? null,
              country: values.projectAddress.country ?? null,
            }
          : undefined,
      });
      toast.success(t('global:words.c.success'), t('quote:creationForm.duplication.toastSuccess'));

      history.push(`/opportunities/quotation/${newQuote.id}`);
    },
    [duplicateQuoteMutation, history, quote.id, t, toast],
  );

  const handleQuoteInvoiced = useCallback(
    async (projectId: number, subProjectId: string | null | undefined, settledDate: string) => {
      setIsStatusUpdating(true);
      try {
        const associatedProject = await lazyGetProjectById(projectId);
        await changeStatusMutation.mutateAsync({
          quoteId: quote.id,
          status: QuoteStatus.Accepted,
          settledAt: settledDate,
          projectId,
          subProjectId: subProjectId ?? undefined,
        });
        setIsStatusUpdating(false);

        toast.success(
          t('global:words.c.success'),
          t('quote:successes.commandAdded', { name: associatedProject!.name }),
        );
        history.push(`/projects/${projectId}/contracts`);
      } catch {
        setIsStatusUpdating(false);
      }
    },
    [changeStatusMutation, history, lazyGetProjectById, quote.id, t, toast],
  );

  const onAssociateSubProject = useCallback(
    async (projectId: number, subProjectId: string | null, settledDate: string) => {
      try {
        setIsStatusUpdating(true);
        await handleQuoteInvoiced(projectId, subProjectId, settledDate);
      } finally {
        setIsStatusUpdating(false);
        acceptQuoteProjectModal.onClose();
      }
    },
    [acceptQuoteProjectModal, handleQuoteInvoiced],
  );

  const handleProjectCreation = useCallback(
    (settledDate: string) => {
      settledDateRef.current = settledDate;

      history.push('/projects/create', {
        quoteUUID: quote?.id,
        settledDate,
      });
    },
    [history, quote?.id],
  );

  const quoteResetStore = useQuoteResetStore();

  const goToEdit = useCallback(
    (newQuoteId: string) => {
      quoteResetStore();
      history.push(`/opportunities/quotation/${newQuoteId}/edit`, { quoteId: newQuoteId });
    },
    [history, quoteResetStore],
  );

  const onEdit = useCallback(() => {
    if (state === QUOTE_STATUS.IMPORTING || state === QUOTE_STATUS.DRAFT) {
      return;
    }
    editModal.onOpen();
  }, [state, editModal]);

  const versions = useMemo(() => {
    if (!quote) {
      return {
        current: undefined,
        archived: [],
      };
    }

    const contractPdfs = (contract?.contractPdfs || [])
      .sort(
        (contractPdfA, contractPdfB) =>
          new Date(contractPdfB.createdAt).getTime() - new Date(contractPdfA.createdAt).getTime(),
      )
      .map(({ pdf: pdfFile, isCurrent }) => ({
        quoteId: undefined,
        name: contract!.name,
        createdAt: pdfFile.createdAt,
        pdf: pdfFile,
        isArchived: !isCurrent,
        date: quote.addedAt ? new Date(quote.addedAt) : null,
        refCode: contract!.code,
        isContractVersion: true,
      }));

    const quotePdfBuilder = (quoteToBuild: QuoteWithoutRelationsDTO) => ({
      quoteId: quoteToBuild.id,
      name: quoteToBuild.name,
      createdAt: new Date(quoteToBuild.createdAt),
      pdf: pdfs?.find((pdfFile) => pdfFile.id === quoteToBuild.pdfId)!,
      // If there is an associated contract, all quoteToBuild pdf version are archived
      isArchived: contract ? true : quoteToBuild.status === QuoteStatus.Archived,
      date: quoteToBuild.addedAt ? new Date(quoteToBuild.addedAt) : null,
      refCode: quoteToBuild.refCode,
      isContractVersion: false,
    });

    const quotePdfs = [quotePdfBuilder(quote), ...(archivedQuotes?.versions.map(quotePdfBuilder) ?? [])];
    const [currentVersion, ...archivedVersions] = [...contractPdfs, ...quotePdfs];

    return {
      current: currentVersion,
      archived: archivedVersions,
    };
  }, [archivedQuotes?.versions, contract, pdfs, quote]);

  const onViewPdf = useCallback(() => {
    if (versions.current) {
      window.open(getPreviewUrl(versions.current.pdf.apiId));
    }
  }, [versions]);

  const onDownloadPdf = useCallback(() => {
    if (versions.current) {
      window.open(
        getDownloadUrl(
          versions.current.pdf.apiId,
          formatFileName(versions.current.name, versions.current.date, versions.current.refCode),
        ),
      );
    }
  }, [versions]);

  const canOpenMailing = !!pdf && !!client && isPdfGenerated;

  return (
    <Box>
      <SellsheetCard>
        <VStack width="100%" alignItems="stretch">
          <DocumentStatusCard
            icon={statusCardIcon}
            title={t('quote:fields.status')}
            isMinimalist
            footer={
              footerText ? (
                <Text fontSize=".8em" color="black">
                  {footerText}
                </Text>
              ) : undefined
            }
            badgeComponent={<QuoteStatusBadge quote={quote} />}
          />
          <DocumentStatusCardActionsList
            actions={getQuotationActions(
              {
                quote,
                pdf: versions.current?.pdf,
                isCompleteButtonLoading: isStatusUpdating,
                isAcceptButtonLoading: isStatusUpdating,

                hasCreatePermission,
                hasAcceptPermission,

                canOpenMailing,

                onOpenDetail: emptyCallback,
                onCompleteQuote,
                onEdit,
                onMarkAsAccepted: acceptQuoteProjectModal.onOpen,
                onMarkAsLost: markQuoteAsLostModal.onOpen,
                onViewPdf,
                onDownloadPdf,
                onOpenMailing: mailingModal.onOpen,
                onGenerateExport: emptyCallback,
                onDuplicate: duplicationModal.onOpen,
                onDelete: deletionModal.onOpen,
              },
              false,
            )}
          />
        </VStack>
      </SellsheetCard>
      {state === QUOTE_STATUS.COMPLETED && (
        <>
          <ConfirmQuoteAsLostModal modalControls={markQuoteAsLostModal} quote={quote} onMarkedAsLost={onReloadQuote} />
          <AcceptQuoteModal
            project={selectedProjectRef.current ?? null}
            modalControls={acceptQuoteProjectModal}
            onAssociateSubProject={onAssociateSubProject}
            onCreateProject={handleProjectCreation}
            quote={quote}
          />
        </>
      )}

      <AlertQuoteStatusCompletionErrorModal
        modalControls={completeErrorsModal}
        onEdit={completeErrorsModal.onClose}
        quote={quote}
        errors={completeErrorsRef.current}
      />

      <EditQuoteStatusModal modalControls={editModal} onSuccess={goToEdit} quote={quote} />

      {canOpenMailing && !!client && (
        <MailingModal
          title={t('mailing:modal.title.quote')}
          isOpen={mailingModal.isOpen}
          onClose={mailingModal.onClose}
          onSubmit={onMailSubmit}
          variableData={getEmailVariableData('quotation', {
            quote,
            project: project || null,
            client,
          })}
          defaultMailContacts={defaultMailContacts}
        />
      )}

      <CreateQuoteModal
        title={t('quote:creationForm.duplication.title')}
        description={t('quote:creationForm.duplication.description')}
        action={t('quote:creationForm.duplication.submit')}
        onSubmit={duplicateQuote}
        allowedVersions={[2]}
        {...duplicationModal}
      />

      <ConfirmDeletionModal
        action={t('quote:cards.delete.action')}
        title={t('quote:cards.delete.title')}
        description={t('quote:cards.delete.modalConfirm')}
        onDeleted={deleteQuote}
        {...deletionModal}
      />
    </Box>
  );
};
