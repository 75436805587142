import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { ActionMenu, BatiprixIcon, GraneetIcon, NoteIcon, OptionIcon } from '@graneet/lib-ui';
import { PlusSquareIcon, SettingsIcon, ViewIcon } from '@chakra-ui/icons';
import type { IQuoteLot } from '@graneet/business-logic';
import { THIRD_PARTY } from '@graneet/business-logic';

import { useDuplicateLot } from '../hooks/useDuplicateLot';
import { useSetLotOptional } from '../hooks/useSetLotOption';
import { useQuoteDisplayContext } from '../../quote/hooks/useQuoteDisplayContext';
import { useAddLot } from '../hooks/useAddLot';

import { useStore } from 'store/store';
import { useThirdParty } from 'features/third-party/hooks/useThirdParty';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

interface QuoteLotEditActionsProps {
  // TODO define generic type
  wrappedLot: { lot: IQuoteLot };
  onDelete(): void;
  onNoteUpdated(note: string): () => void;
}

export const QuoteEditLotActions: FC<QuoteLotEditActionsProps> = ({ wrappedLot, onDelete, onNoteUpdated }) => {
  const lotId = wrappedLot.lot.id;

  const hasBatiprix = useThirdParty(THIRD_PARTY.BATIPRIX);

  const { t } = useTranslation(['quote', 'global']);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const actionControls = useDisclosure();

  const { startAnotherUpdate } = useQuoteEditContext();
  const {
    openLotUpdateDrawer,
    openLibraryJobImportDrawer,
    openLotDefaultVatRateEditModal,
    isReadOnlyView,
    openBatiprixImportDrawer,
  } = useQuoteDisplayContext();

  const addLot = useAddLot();
  const duplicateLot = useDuplicateLot();

  const hasNote = wrappedLot.lot.note !== null;

  const onEditLotClicked = useCallback(() => {
    openLotUpdateDrawer(lotId);
  }, [lotId, openLotUpdateDrawer]);

  const onEditLotDefaultVatRateClicked = useCallback(() => {
    openLotDefaultVatRateEditModal(lotId);
  }, [lotId, openLotDefaultVatRateEditModal]);

  const onImportLibraryJobClicked = useCallback(async () => {
    openLibraryJobImportDrawer(lotId);
  }, [openLibraryJobImportDrawer, lotId]);

  const onImportBatiprixClicked = useCallback(async () => {
    openBatiprixImportDrawer(lotId);
  }, [openBatiprixImportDrawer, lotId]);

  const onAddLotClicked = useCallback(async () => {
    await addLot(lotId);
    startAnotherUpdate();
  }, [addLot, lotId, startAnotherUpdate]);

  const onDuplicateLotClicked = useCallback(async () => {
    await duplicateLot(lotId);
    startAnotherUpdate();
  }, [duplicateLot, lotId, startAnotherUpdate]);

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  const isOptional = useStore((state) => state.optionalLotsTable[lotId.toString()]);

  const setLotOptional = useSetLotOptional(wrappedLot.lot);
  const tableWithoutJobInTheseChildren = useStore((state) => state.optionalLotsTableWithoutJob[lotId.toString()]);

  const handleOptional = useCallback(async () => {
    await setLotOptional(!isOptional);
  }, [isOptional, setLotOptional]);

  const hasHiddenCosts = useStore((state) => state.hasHiddenCosts);

  const isOptionalDisabled = useMemo(() => !isOptional && hasHiddenCosts, [hasHiddenCosts, isOptional]);

  if (isReadOnlyView)
    return (
      <ActionMenu {...actionControls}>
        <ActionMenu.Action icon={<ViewIcon />} label={t('quote:jobsStep.viewLotDrawer')} onClick={onEditLotClicked} />
      </ActionMenu>
    );

  return (
    <ActionMenu {...actionControls}>
      <ActionMenu.Action
        icon={<GraneetIcon />}
        label={t('quote:jobsStep.importExistingJob')}
        onClick={onImportLibraryJobClicked}
      />

      {hasBatiprix && (
        <ActionMenu.Action
          icon={<BatiprixIcon />}
          label={t('quote:jobsStep.importerFromBatiprix')}
          onClick={onImportBatiprixClicked}
        />
      )}

      <ActionMenu.Edit label={t('quote:jobsStep.editJob')} onClick={onEditLotClicked} />

      <ActionMenu.Action icon={<PlusSquareIcon />} label={t('quote:jobsStep.addSubLot')} onClick={onAddLotClicked} />

      {!hasNote && (
        <ActionMenu.Action icon={<NoteIcon />} label={t('quote:jobsStep.addNote')} onClick={onNoteUpdated('')} />
      )}

      <ActionMenu.Action
        icon={<SettingsIcon />}
        label={t('global:applyVAT')}
        onClick={onEditLotDefaultVatRateClicked}
      />

      {!tableWithoutJobInTheseChildren && (
        <ActionMenu.Action
          icon={<OptionIcon />}
          label={isOptional ? t('quote:option.removeOption') : t('quote:option.addOption')}
          onClick={handleOptional}
          tooltip={isOptionalDisabled && t('quote:option.optionalIsDisabledOnHiddenCosts')}
          isDisabled={isOptionalDisabled}
        />
      )}

      <ActionMenu.Duplicate onClick={onDuplicateLotClicked} />

      <ActionMenu.Delete onClick={onDelete} />
    </ActionMenu>
  );
};
