import type { FC } from 'react';
import { Section, useCurrency } from '@graneet/lib-ui';
import type { SubProjectWithOrders } from '@graneet/business-logic';
import { sumObjects } from '@graneet/business-logic';
import { useTranslation, Trans } from 'react-i18next';
import { flatMap } from 'lodash-es';
import { Box } from '@chakra-ui/react';

import { FinalStatementDirectPaymentTable } from 'features/final-statement/components/FinalStatementDirectPaymentTable/FinalStatementDirectPaymentTable';

type ViewDirectPaymentOrdersStepProps = {
  subProjects: SubProjectWithOrders[];
};

export const ViewDirectPaymentOrdersStep: FC<ViewDirectPaymentOrdersStepProps> = ({ subProjects }) => {
  const { t } = useTranslation(['project']);
  const { formatAsAmount } = useCurrency();

  const directPaymentOrdersAmountIncVAT = sumObjects(
    flatMap(subProjects, (subProject) => subProject.directPaymentOrders),
    'amountIncVAT',
  );

  return (
    <Section
      title={t('project:finalStatement.directPayment.title')}
      description={
        <Trans
          t={t}
          i18nKey="project:finalStatement.directPayment.description"
          values={{ amount: formatAsAmount(directPaymentOrdersAmountIncVAT) }}
        >
          <strong />
        </Trans>
      }
    >
      <Box borderRadius="6px" boxShadow="subtle" overflow="hidden" mt={3}>
        {subProjects.map((subProjectWithOrders) =>
          subProjectWithOrders.directPaymentOrders.map((order) => (
            <FinalStatementDirectPaymentTable key={order.id} subProject={subProjectWithOrders} order={order} />
          )),
        )}
      </Box>
    </Section>
  );
};
