import type { FC } from 'react';
import { useState } from 'react';
import { Modal, ValuesProvider, useValuesContext, useToast, STORAGE_STRATEGY, usePaginatedData } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash-es';

import { ContactAvailableDataProviderForm } from '../forms/ContactAvailableDataProviderForm';

import {
  getContactAssociatedToClientPaginated,
  getContactAvailableForQuote,
  useContactsAssociateToQuote,
} from 'features/contact/services/contact.api';
import { useQuotationApi } from 'features/quotation/services/quote.api';

interface RawContactAvailableQuoteModalProps<T extends string | number> {
  isOpen: boolean;

  onClose(): void;

  onSuccess(): void;

  title: string;

  quoteId: T;
}

const RawContactAvailableQuoteModalV1: FC<RawContactAvailableQuoteModalProps<number>> = ({
  isOpen,
  onClose,
  title,
  onSuccess,
  quoteId,
}) => {
  const { t } = useTranslation(['global', 'contacts']);
  const toast = useToast();

  const { resetValues, getValues, numberOfValues } = useValuesContext<string>();
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const contactsAssociateToQuoteMutation = useContactsAssociateToQuote();

  const resetAll = () => {
    setIsAllChecked(false);
    resetValues();
  };

  const handleOnClose = () => {
    resetAll();
    onClose();
  };

  const handleSubmit = async () => {
    const contactsToAssociate = getValues();
    await contactsAssociateToQuoteMutation.mutateAsync({
      id: quoteId,
      dto: {
        contacts: contactsToAssociate.map((contactId) => ({ id: contactId, isDefaultEmailRecipient: false })),
      },
    });
    if (contactsToAssociate.length > 1) {
      toast.success(
        t('contacts:availableModal.toastSuccessManyAssociation', { nbContact: contactsToAssociate.length }),
      );
      onSuccess();
    } else {
      toast.success(t('contacts:availableModal.toastSuccessOneAssociation'));
      onSuccess();
    }
    onClose();
    resetAll();
  };

  const data = usePaginatedData(getContactAvailableForQuote, { quoteId }, undefined, STORAGE_STRATEGY.STATE);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} title={title} size="3xl" scrollBehavior="inside" maxHeight="33rem">
      <ContactAvailableDataProviderForm
        data={data}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        isContactDefaultEmailRecipient={(contact) => contact.isDefaultEmailRecipient}
      />
      <Modal.Close />
      <Modal.PrimaryButton isDisabled={numberOfValues() === 0} onClick={handleSubmit}>
        {numberOfValues() <= 1 && t('contacts:availableModal.buttons.associate')}
        {numberOfValues() > 1 && t('contacts:availableModal.buttons.associateMulti')}
      </Modal.PrimaryButton>
    </Modal>
  );
};

const RawContactAvailableQuoteModalV2: FC<RawContactAvailableQuoteModalProps<string>> = ({
  isOpen,
  onClose,
  title,
  onSuccess,
  quoteId,
}) => {
  const { t } = useTranslation(['global', 'contacts']);
  const toast = useToast();

  const { useGetQuoteById, useQuotePartialUpdate } = useQuotationApi();

  const quotePartialUpdateMutation = useQuotePartialUpdate();

  const { data: quote } = useGetQuoteById(quoteId);

  const { resetValues, getValues, numberOfValues } = useValuesContext<string>();
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const resetAll = () => {
    setIsAllChecked(false);
    resetValues();
  };

  const handleOnClose = () => {
    resetAll();
    onClose();
  };

  const handleSubmit = async () => {
    const contactsToAssociate = getValues();
    await quotePartialUpdateMutation.mutateAsync({
      quoteId,
      contacts: uniqBy(
        [
          ...(quote?.contacts ?? []),
          ...contactsToAssociate.map((contactId) => ({ id: contactId, isDefaultEmailRecipient: false })),
        ],
        'id',
      ),
    });
    /*
    const [err] = await associateContactsToQuote(quoteId, {
      contacts: contactsToAssociate.map((contactId) => ({ id: contactId, isDefaultEmailRecipient: false })),
    });
    */
    if (contactsToAssociate.length > 1) {
      toast.success(
        t('contacts:availableModal.toastSuccessManyAssociation', { nbContact: contactsToAssociate.length }),
      );
      onSuccess();
    } else {
      toast.success(t('contacts:availableModal.toastSuccessOneAssociation'));
      onSuccess();
    }
    onClose();
    resetAll();
  };

  const data = usePaginatedData(
    getContactAssociatedToClientPaginated,
    { clientId: quote.clientId ?? undefined },
    undefined,
    STORAGE_STRATEGY.STATE,
  );

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} title={title} size="3xl" scrollBehavior="inside" maxHeight="33rem">
      <ContactAvailableDataProviderForm
        data={data}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        isContactDefaultEmailRecipient={(contact) => contact.isDefaultEmailRecipient}
      />
      <Modal.Close />
      <Modal.PrimaryButton isDisabled={numberOfValues() === 0} onClick={handleSubmit}>
        {numberOfValues() <= 1 && t('contacts:availableModal.buttons.associate')}
        {numberOfValues() > 1 && t('contacts:availableModal.buttons.associateMulti')}
      </Modal.PrimaryButton>
    </Modal>
  );
};

export const ContactAvailableQuoteModal: FC<RawContactAvailableQuoteModalProps<string | number>> = ({
  quoteId,
  ...props
}) => (
  <ValuesProvider>
    {typeof quoteId === 'number' ? (
      <RawContactAvailableQuoteModalV1 quoteId={quoteId} {...props} />
    ) : (
      <RawContactAvailableQuoteModalV2 quoteId={quoteId} {...props} />
    )}
  </ValuesProvider>
);
