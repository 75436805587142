import type { FC, ChangeEvent } from 'react';
import { useState, useCallback, useEffect } from 'react';
import { Grid, GridItem, Checkbox } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useOnChangeValues, useFormContext } from 'graneet-form';
import {
  Card,
  DateField,
  FieldGroupLabel,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
  RichTextField,
  TextField,
} from '@graneet/lib-ui';
import { FEATURE_FLAGS, LEDGER_TYPES } from '@graneet/business-logic';

import type { OrderEditForm } from '../../forms/order-edit-wizard';

import { Rule } from 'features/form/rules/Rule';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { AddressFields } from 'features/common/components/fields/AddressFields';
import { LedgerField } from 'features/ledger/components/LedgerField';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

type OrderInformationCardProps = {
  isEdit: boolean;
};

export const OrderInformationCard: FC<OrderInformationCardProps> = ({ isEdit }) => {
  const { t } = useTranslation(['orders', 'project']);
  const [isSameDeliveryAddressAsProject, setIsSameDeliveryAddressAsProject] = useState(false);
  const form = useFormContext<OrderEditForm>();

  const hasTypeFF = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_ORDER_ITEM_COMPONENT_TYPE);

  const {
    projectId,
    projectAddress: projectAddressField,
    numberOfItems,
    hasUnitPrices,
  } = useOnChangeValues(form, ['projectId', 'projectAddress', 'numberOfItems', 'hasUnitPrices']);

  const toggleSameDeliveryAddressAsProject = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsSameDeliveryAddressAsProject(!!event.target.checked);
    },
    [setIsSameDeliveryAddressAsProject],
  );

  // Binds project address to delivery address
  useEffect(() => {
    if (isSameDeliveryAddressAsProject) {
      if (projectAddressField) {
        form.setFormValues({
          address: projectAddressField.address,
          postalCode: projectAddressField.postalCode,
          city: projectAddressField.city,
          country: projectAddressField.country,
        });
      } else {
        setIsSameDeliveryAddressAsProject(false);
      }
    }
  }, [projectAddressField, isSameDeliveryAddressAsProject, form]);

  const isProjectIdExist = projectId !== undefined;
  const isDisabledField = isProjectIdExist && isSameDeliveryAddressAsProject;

  return (
    <Card title={t('orders:informationsCard.title')}>
      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
        <GridItem colSpan={4}>
          <TextField<OrderEditForm> name="name" label={t('orders:informationsCard.name')} isRequired>
            <Rule.IsRequired />
          </TextField>
        </GridItem>

        <GridItem colSpan={2}>
          <LedgerField<OrderEditForm, 'orderNumber'>
            name="orderNumber"
            label={t('orders:informationsCard.orderNumber')}
            ledgerType={LEDGER_TYPES.ORDER}
            isAutoGenerationDisabled={isEdit}
            projectId={projectId}
          />
        </GridItem>
        <GridItem colSpan={2}>
          {hasUnitPrices && numberOfItems && numberOfItems > 0 && hasTypeFF ? null : (
            <ComponentTypeField<OrderEditForm>
              name="typeId"
              label={t('orders:informationsCard.type')}
              material
              isRequired
            >
              <Rule.IsRequired />
            </ComponentTypeField>
          )}
        </GridItem>
        <GridItem colSpan={2}>
          <DateField<OrderEditForm>
            name="orderDate"
            label={t('orders:informationsCard.orderDate')}
            width="100%"
            isRequired
          >
            <Rule.IsRequired />
          </DateField>
        </GridItem>
        <GridItem colSpan={2}>
          <DateField<OrderEditForm>
            name="deliveryDate"
            label={t('orders:informationsCard.deliveryDate')}
            width="100%"
          />
        </GridItem>
        <GridItem colSpan={4}>
          <FieldGroupLabel>{t('orders:informationsCard.deliveryAddress')}</FieldGroupLabel>
          {isProjectIdExist ? (
            <Checkbox
              data-testid="checkbox:project.addressSameAsCompany"
              colorScheme="greenBrand"
              isChecked={isSameDeliveryAddressAsProject}
              onChange={toggleSameDeliveryAddressAsProject}
              mt={4}
            >
              {t('orders:informationsCard.sameAddressAsProject')}
            </Checkbox>
          ) : null}

          <AddressFields twoLines isDisabled={isDisabledField} isRequired={false} mt={4} />
        </GridItem>

        <GridItem colSpan={4}>
          <RichTextField<OrderEditForm>
            name="note"
            navbarType="inline"
            label={t('project:fields.note')}
            placeholder={t('orders:commentCard.placeholder')}
            configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
          />
        </GridItem>
      </Grid>
    </Card>
  );
};
