import { BILL_STATUSES } from '@graneet/business-logic';

export const BILL_FIELDS = {
  INVOICE_NUMBER: 'invoiceNumber',
  NAME: 'name',
  PROJECT: 'project',
  CLIENT: 'client',
  TOTAL_TO_BE_PAID_INC_VAT: 'totalToBePaidIncVAT',
  REMAINING_TO_BE_PAID_INC_VAT: 'remainingToBePaidIncVAT',
  LATE_DAYS: 'lateDays',
  STATUS: 'status',
  PAYMENT_DEADLINE: 'paymentDeadline',
  BILLING_DATE: 'billingDate',
  TAGS: 'tags',
};

export const BILL_LATE_DAYS_RANGES = {
  LESS_THAN_30_DAYS: 'LESS_THAN_30_DAYS',
  BETWEEN_30_AND_60_DAYS: 'BETWEEN_30_AND_60_DAYS',
  BETWEEN_60_AND_90_DAYS: 'BETWEEN_60_AND_90_DAYS',
  MORE_THAN_90_DAYS: 'MORE_THAN_90_DAYS',
};

export const BILL_LATE_DAYS_RANGES_VALUES = {
  [BILL_LATE_DAYS_RANGES.LESS_THAN_30_DAYS]: { max: 30 },
  [BILL_LATE_DAYS_RANGES.BETWEEN_30_AND_60_DAYS]: { min: 30, max: 60 },
  [BILL_LATE_DAYS_RANGES.BETWEEN_60_AND_90_DAYS]: { min: 60, max: 90 },
  [BILL_LATE_DAYS_RANGES.MORE_THAN_90_DAYS]: { min: 90 },
};

export const BILL_STATUSES_STYLE = {
  [BILL_STATUSES.AWAITING_PAYMENT]: {
    variantColor: 'gray',
    priority: 1,
  },
  [`${BILL_STATUSES.AWAITING_PAYMENT}_LATE`]: {
    variantColor: 'gray',
    priority: 1,
  },
  [BILL_STATUSES.PARTIALLY_PAID]: {
    variantColor: 'yellow',
    priority: 2,
  },
  [BILL_STATUSES.PAID]: {
    variantColor: 'green',
    priority: 3,
  },
  [BILL_STATUSES.LOST]: {
    variantColor: 'red',
    priority: 4,
  },
} as const;
