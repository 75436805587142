import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Button, DroppableFileField, Modal, SimpleAddIcon, SwitchField } from '@graneet/lib-ui';
import { Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Form, useForm, useOnChangeValues } from 'graneet-form';

import { Rule } from 'features/form/rules/Rule';
import type { UseDisabledButtonPropsReturn } from 'features/role/hooks/useDisabledButtonProps';

interface OrderFileAddReceiptFileButtonProps {
  buttonPermissionsProps?: UseDisabledButtonPropsReturn;

  onSubmit(data: { file: File; isDisplayInOrderPdf: boolean }): void | Promise<void>;
}

type ReceiptFileForm = { file: File; isDisplayInOrderPdf: boolean };

export const OrderFileAddReceiptFileButton: FC<OrderFileAddReceiptFileButtonProps> = ({
  buttonPermissionsProps,
  onSubmit,
}) => {
  const { t } = useTranslation(['global', 'orders']);

  const [isLoading, setIsLoading] = useState(false);

  const modal = useDisclosure();

  const form = useForm<ReceiptFileForm>();
  const { file } = useOnChangeValues(form, ['file']);

  const handleSubmit = useCallback(async () => {
    const { file: currentFile, isDisplayInOrderPdf } = form.getFormValues();

    setIsLoading(true);
    await onSubmit({
      file: currentFile!,
      isDisplayInOrderPdf: !!isDisplayInOrderPdf,
    });
    setIsLoading(false);

    modal.onClose();
  }, [form, modal, onSubmit]);

  // Reset modal on open
  useEffect(() => {
    if (modal.isOpen) {
      form.resetForm();
    }
  }, [form, modal.isOpen]);

  return (
    <>
      <Button
        variant="outline"
        leftIcon={<SimpleAddIcon />}
        colorScheme="gray"
        onClick={modal.onOpen}
        {...buttonPermissionsProps}
      >
        {t('global:receiptsFileField.add')}
      </Button>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t('orders:addReceiptFileModal.title')}>
        <Form form={form}>
          <Stack spacing={4}>
            <Text>{t('orders:addReceiptFileModal.description')}</Text>

            <SwitchField<ReceiptFileForm>
              name="isDisplayInOrderPdf"
              label={t('orders:addReceiptFileModal.addFileToPDFAnnex')}
              uncheckedValue={false}
              checkedValue
            />

            <DroppableFileField<ReceiptFileForm> name="file" isRequired accept="application/pdf">
              <Rule.IsRequired />
            </DroppableFileField>
          </Stack>
        </Form>
        <Modal.Close isDisabled={isLoading} />

        <Modal.PrimaryButton onClick={handleSubmit} isDisabled={!file} isLoading={isLoading}>
          {t('global:receiptsFileField.add')}
        </Modal.PrimaryButton>
      </Modal>
    </>
  );
};
