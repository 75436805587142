import {
  Avatar,
  AvatarGroup,
  CircularProgress,
  HStack,
  Flex,
  Input,
  useDisclosure,
  Center,
  Divider,
} from '@chakra-ui/react';
import type { ButtonListCta } from '@graneet/lib-ui';
import {
  BatiprixIcon,
  ButtonList,
  DrawersStack,
  GraneetIconButton as IconButton,
  SimpleAddFolderIcon,
  SimpleFlipBackwardIcon,
  SimpleFlipForwardIcon,
  Tooltip,
  useDrawer,
  useDrawersStack,
  useHotkeys,
  useLocalStorage,
} from '@graneet/lib-ui';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import type { IProject } from '@graneet/business-logic';
import { FEATURE_FLAGS, PDF_STATUSES, THIRD_PARTY } from '@graneet/business-logic';
import type { QuotePdfDTO } from '@org/graneet-bff-client';

import { useQuoteUpdate } from '../../hooks/useQuoteUpdate';
import { ExportQuoteModal } from '../modals/ExportQuoteModal';
import { ShortCutsModal, getShortcut } from '../modals/ShortcutsModal';
import { ComponentsSupplyDrawer } from '../modals/ComponentsSupplyDrawer';
import { useQuote } from '../../hooks/useQuote';

import { useQuoteHiddenCostItemCreate } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemCreate';
import { useQuoteBasicItemCreate } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemCreate';
import { useQuoteLotCreate } from 'features/quotation/quote-lot/hooks/useQuoteLotCreate';
import { useQuoteOptionalItemCreate } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemCreate';
import { useStore } from 'store/store';
import {
  updateQuoteBatiprixModalIsOpen,
  updateQuoteImportItemModalIsOpen,
  updateQuoteImportItemTargetParentId,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useThirdParty } from 'features/third-party/hooks/useThirdParty';
import { useAppContext } from 'features/app/contexts/AppContext';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import { getPreviewUrl } from 'features/pdf/services/pdf.api';
import { useQuotationApi } from 'features/quotation/services/quote.api';
import { QUOTATION_SHORTCUTS } from 'features/quotation/quote-hot-key/hooks/useQuoteHotKeys';
import { getEnvValue } from 'config/env';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { quoteCommandHistoryGetFromStore } from 'features/quotation/quote-common/store/quoteCommandHistoryGetFromStore';
import { quoteCommandHistoryPointerGetFromStore } from 'features/quotation/quote-common/store/quoteCommandHistoryPointerGetFromStore';
import { initializeBeacon } from 'features/beacon/components/Beacon';
import { quoteUndoRedoGetFromStore } from 'features/quotation/quote-common/store/quoteUndoRedoGetFromStore';
import { umamiTrackEvent } from 'features/app/hooks/umami/umamiTrackEvent';

interface QuoteHeaderProps {
  isOpen?: boolean;
  onSideBarOpen: () => void;
}

export const QuoteHeader: FC<QuoteHeaderProps> = ({ isOpen, onSideBarOpen }) => {
  const { t } = useTranslation(['quote', 'global']);
  const { currentUser } = useAppContext();
  const { state } = useLocation<{ creation: boolean; project: IProject; currentQuoteVersionId?: string }>();
  const [alreadyAskedForFormation] = useLocalStorage('alreadyAskedForFormation', 'false');

  const isSupplySheetEnabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_SUPPLYSHEET);

  const history = useHistory();
  const { quote } = useQuote();

  const quoteLotCreate = useQuoteLotCreate();
  const quoteBasicItemCreate = useQuoteBasicItemCreate();
  const quoteOptionalItemCreate = useQuoteOptionalItemCreate();
  const quoteHiddenCostItemCreate = useQuoteHiddenCostItemCreate();
  const { useGetQuoteDraftPdf, useAskFormation } = useQuotationApi();

  const commandHistory = quoteCommandHistoryGetFromStore();
  const commandHistoryPointer = quoteCommandHistoryPointerGetFromStore();
  const canUndoRedo = quoteUndoRedoGetFromStore();

  const { executeCommand } = useCommands();

  const getQuoteDraftPdf = useGetQuoteDraftPdf();

  const askFormationMutation = useAskFormation();
  const quoteObjectFlat = useStore((states) => states.quote);
  const isEditable = useStore((store) => store.quoteEditable);
  const members = useStore((store) => store.quoteMembers);
  const quoteDrawerRef = useStore((store) => store.quoteDrawerRef);
  const quoteClientRequestsFromStore = useStore((store) => store.clientRequests);

  const hasBatiprix = useThirdParty(THIRD_PARTY.BATIPRIX);

  const projectId = state?.project?.id;
  const projectIdRef = useRef<number | string | null>(projectId);

  const [name, setName] = useState(quote.getName());
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const drawersStack = useDrawersStack();
  const drawerComponentsSupply = useDrawer('drawer-components-supply', drawersStack);

  const hasQuotationUndoRedo = useFeatureFlag(FEATURE_FLAGS.QUOTATION_UNDO_REDO);

  const REACT_APP_BEACON_ID = getEnvValue('REACT_APP_BEACON_ID');
  const REACT_APP_BEACON_URL = getEnvValue('REACT_APP_BEACON_URL');
  const IS_PRODUCTION = process.env.NODE_ENV === 'production';

  const { undoCommand, redoCommand } = useCommands();

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  useEffect(() => {
    if (quoteObjectFlat) {
      setName(quoteObjectFlat.name);
    }
  }, [quoteObjectFlat]);

  useEffect(() => {
    if (quoteClientRequestsFromStore.length > 0) {
      setIsRequestSent(true);
    }
    if (quoteClientRequestsFromStore.length === 0) {
      setTimeout(() => {
        setIsRequestSent(false);
      }, 500);
    }
  }, [quoteClientRequestsFromStore.length]);

  useEffect(() => {
    if (REACT_APP_BEACON_ID && REACT_APP_BEACON_URL && IS_PRODUCTION) {
      initializeBeacon(window, document, REACT_APP_BEACON_URL.toString());

      if (window.Beacon) {
        window.Beacon('init', REACT_APP_BEACON_ID);
        window.Beacon('config', {
          hideAvatars: true,
          display: {
            style: 'manual',
          },
        });
      }
    }
    return () => {
      window.Beacon?.('destroy');
    };
  }, [IS_PRODUCTION, REACT_APP_BEACON_ID, REACT_APP_BEACON_URL]);

  const quoteUpdate = useQuoteUpdate();
  const onQuit = useCallback(() => {
    if (projectId && projectId === projectIdRef.current) {
      history.push(`/projects/${projectId}/contracts`);
      return;
    }

    if (state?.currentQuoteVersionId) {
      history.push(`/opportunities/quotation/${state?.currentQuoteVersionId}`);
      return;
    }

    history.push(`/opportunities/quotation/${quote.getId()}`);
  }, [history, projectId, quote, state?.currentQuoteVersionId]);

  const updateQuoteName = useCallback(() => {
    if (!isEditable) return;
    quoteUpdate({
      name,
    });
  }, [name, quoteUpdate, isEditable]);

  const handlePDFPreview = useCallback(
    (pdf: QuotePdfDTO) => (pdfStatus: PDF_STATUSES) => {
      if (pdfStatus === PDF_STATUSES.GENERATED && pdf.apiId) {
        const previewUrl = getPreviewUrl(pdf.apiId);

        if (previewUrl) {
          // Browser preview - open in new tab
          window.open(previewUrl);
        }
      }
      setIsLoading(false);
    },
    [],
  );

  const generatePdfFromDraftQuote = useCallback(async () => {
    setIsLoading(true);
    const pdf = await getQuoteDraftPdf.mutateAsync(quote.getId());

    // Listen to server side event and then view the generated PDF file
    subscribeToPDFUpdates(pdf, handlePDFPreview(pdf));
  }, [getQuoteDraftPdf, handlePDFPreview, quote]);

  const exportModalDisclosure = useDisclosure();

  const importCtaList = useMemo(() => {
    const ctaList: ButtonListCta[] = [
      {
        label: t('quote:jobImportMenu.library'),
        color: 'greenBrand',
        onClick: () => {
          updateQuoteImportItemModalIsOpen(true);
          updateQuoteImportItemTargetParentId(null);
        },
        leftIcon: <SimpleAddFolderIcon width="1rem" height="1rem" stroke="#109CF1" />,
        shortCut: getShortcut(QUOTATION_SHORTCUTS.LIBRARY_IMPORT.shortCut).join(' ').toUpperCase(),
      },
    ];

    if (hasBatiprix) {
      ctaList.push({
        label: t('quote:emptyState.cards.batiprix.subtitle'),
        color: 'greenBrand',
        onClick: () => {
          updateQuoteBatiprixModalIsOpen(true);
        },
        leftIcon: <BatiprixIcon width="1rem" height="1rem" />,
        shortCut: getShortcut(QUOTATION_SHORTCUTS.BATIPRIX_IMPORT.shortCut).join(' ').toUpperCase(),
      });
    }

    return ctaList;
  }, [hasBatiprix, t]);

  const handleContactSupport = useCallback(() => {
    window.Beacon?.('toggle');
  }, []);

  const handleShareFeedback = useCallback(() => {
    const firstName = currentUser?.firstName ?? '';
    const lastName = currentUser?.lastName ?? '';
    const companyName = currentUser?.company?.name ?? '';

    const url = `https://airtable.com/app7e20IB5GeI5881/shrSFC7itudamaVyM?prefill_fldfnHenhbOiiU2A3=${firstName}&hide_fldfnHenhbOiiU2A3=true&prefill_fldA3x334sNADPFOF=${lastName}&hide_fldA3x334sNADPFOF=true&prefill_fldXeMSt7DJvmx1ki=${companyName}&hide_fldXeMSt7DJvmx1ki=true`;
    window.open(url, '_blank');
  }, [currentUser]);

  const handleChangeTabDrawer = useCallback(
    (index: number) => {
      if (!isOpen) {
        onSideBarOpen();
      }
      quoteDrawerRef?.current?.changeTab(index);
    },
    [onSideBarOpen, isOpen, quoteDrawerRef],
  );

  const handleSubmitName = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  }, []);

  const handleAskFormation = useCallback(() => {
    askFormationMutation.mutateAsync();
  }, [askFormationMutation]);

  const shortCutsModalDisclosure = useDisclosure();

  useHotkeys(
    [QUOTATION_SHORTCUTS.PDF_PREVIEW.shortCut],
    () => {
      generatePdfFromDraftQuote();
    },
    [generatePdfFromDraftQuote],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.EXPORT.shortCut],
    () => {
      exportModalDisclosure.onOpen();
    },
    [],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.HELP.shortCut],
    () => {
      handleChangeTabDrawer(4);
    },
    [handleChangeTabDrawer],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.DRAWER_HELP.shortCut],
    () => {
      handleChangeTabDrawer(4);
    },
    [handleChangeTabDrawer],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.DRAWER_CLIENT.shortCut],
    () => {
      handleChangeTabDrawer(1);
    },
    [handleChangeTabDrawer],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.DRAWER_SELLSHEET.shortCut],
    () => {
      handleChangeTabDrawer(0);
    },
    [handleChangeTabDrawer],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.DRAWER_QUOTE.shortCut],
    () => {
      handleChangeTabDrawer(2);
    },
    [handleChangeTabDrawer],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.DRAWER_PROJECT.shortCut],
    () => {
      handleChangeTabDrawer(3);
    },
    [handleChangeTabDrawer],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.TOGGLE_DRAWER.shortCut],
    () => {
      onSideBarOpen();
    },
    [onSideBarOpen],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.LIBRARY_IMPORT.shortCut],
    () => {
      updateQuoteImportItemModalIsOpen(true);
      updateQuoteImportItemTargetParentId(null);
    },
    [],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.BATIPRIX_IMPORT.shortCut],
    () => {
      updateQuoteBatiprixModalIsOpen(true);
    },
    [],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.QUIT.shortCut],
    () => {
      onQuit();
    },
    [onQuit],
  );
  useHotkeys(
    [QUOTATION_SHORTCUTS.SUPPLY_SHEET.shortCut],
    () => {
      drawerComponentsSupply.onOpen();
    },
    [onQuit],
  );

  const env = getEnvValue('REACT_APP_ENV');

  return (
    <>
      <Flex alignItems="center" gap={2}>
        <Flex flex={1} justifyContent="flex-start" alignItems="center">
          <HStack gap={3} flex={1}>
            <IconButton size="sm" color="default" icon="ri-arrow-left-s-line" onClick={onQuit} />
            <Input
              placeholder={t('quote:fields.name')}
              variant="unstyled"
              paddingX={2}
              paddingY={0.5}
              onChange={handleChange}
              onBlur={updateQuoteName}
              onKeyDown={handleSubmitName}
              value={name ?? ''}
              readOnly={!isEditable}
              minW="150px"
              width={`${(name ?? '').length * 0.9}ch`}
              _hover={{
                background: isEditable ? 'rgba(0,21,63, 0.0471)' : undefined,
              }}
            />
          </HStack>
        </Flex>
        {
          // TODO: Remove this when the feature is available
        }
        {env === 'local' && isRequestSent && <CircularProgress isIndeterminate size="1rem" color="greenBrand.light" />}
        {members && members.length > 0 && (
          <Flex display="inline-block">
            <Tooltip label={members.join(', ')} placement="top">
              <AvatarGroup max={1} size="sm" spacing="-2">
                {members.map((member) => (
                  <Avatar key={member} name={member} />
                ))}
              </AvatarGroup>
            </Tooltip>
          </Flex>
        )}
        {isEditable && (
          <Flex justifyContent="flex-end" alignItems="center" gap={3}>
            {hasQuotationUndoRedo && (
              <>
                <IconButton
                  size="lg"
                  color="ghost"
                  icon={<SimpleFlipBackwardIcon />}
                  onClick={() => undoCommand(quote, 'click')}
                  isDisabled={!(commandHistory.length > 0 && commandHistoryPointer >= 0 && canUndoRedo)}
                />
                <Center height="20px">
                  <Divider orientation="vertical" borderRadius="0.063rem" width="0.063rem" />
                </Center>
                <IconButton
                  size="lg"
                  color="ghost"
                  icon={<SimpleFlipForwardIcon />}
                  onClick={() => redoCommand(quote, 'click')}
                  isDisabled={
                    !(commandHistory.length > 0 && commandHistoryPointer < commandHistory.length - 1 && canUndoRedo)
                  }
                />
              </>
            )}
            <ButtonList
              variant="primary"
              label={t('quote:header.createMenu.label')}
              rightIcon="ri-arrow-down-s-line"
              ctaList={[
                {
                  label: t('quote:header.createMenu.lot'),
                  color: 'greenBrand',
                  onClick: () => executeCommand(quoteLotCreate(null), quote),
                  shortCut: getShortcut(QUOTATION_SHORTCUTS.QUOTE_LOT.shortCut).join(' ').toUpperCase(),
                },
                {
                  label: t('quote:header.createMenu.basicItem'),
                  color: 'greenBrand',
                  onClick: () => {
                    executeCommand(quoteBasicItemCreate(null), quote);
                    umamiTrackEvent({
                      feature: 'quotation',
                      fromZone: 'header',
                      action: 'create',
                      entity: 'basicItem',
                    });
                  },
                  shortCut: getShortcut(QUOTATION_SHORTCUTS.QUOTE_BASIC_ITEM.shortCut).join(' ').toUpperCase(),
                },
                {
                  label: t('quote:header.createMenu.optionalItem'),
                  color: 'greenBrand',
                  onClick: () => executeCommand(quoteOptionalItemCreate(null), quote),
                  shortCut: getShortcut(QUOTATION_SHORTCUTS.OPTION.shortCut).join(' ').toUpperCase(),
                },
                {
                  label: t('quote:header.createMenu.hiddenCostItem'),
                  color: 'greenBrand',
                  onClick: () => executeCommand(quoteHiddenCostItemCreate(), quote),
                  shortCut: getShortcut(QUOTATION_SHORTCUTS.HIDDEN_COST.shortCut).join(' ').toUpperCase(),
                },
              ]}
            />
            <ButtonList label={t('quote:header.importJobs')} rightIcon="ri-arrow-down-s-line" ctaList={importCtaList} />
            <Tooltip
              label={t('quote:header.viewPDF')}
              shouldWrapChildren
              placement="bottom"
              shortcut={getShortcut(QUOTATION_SHORTCUTS.PDF_PREVIEW.shortCut).join(' ').toUpperCase()}
            >
              <IconButton
                size="md"
                color="ghost"
                icon="ri-file-pdf-2-line"
                onClick={generatePdfFromDraftQuote}
                isDisabled={isLoading}
                isLoading={isLoading}
              />
            </Tooltip>
            <Tooltip
              label={t('quote:header.export')}
              shouldWrapChildren
              placement="bottom"
              shortcut={getShortcut(QUOTATION_SHORTCUTS.EXPORT.shortCut).join(' ').toUpperCase()}
            >
              <IconButton size="md" color="ghost" icon="ri-export-line" onClick={exportModalDisclosure.onOpen} />
            </Tooltip>
            {isSupplySheetEnabled && (
              <Tooltip
                label={t('quote:header.componentsSupply')}
                shouldWrapChildren
                placement="bottom"
                shortcut={getShortcut(QUOTATION_SHORTCUTS.SUPPLY_SHEET.shortCut).join(' ').toUpperCase()}
              >
                <IconButton size="md" color="ghost" icon="ri-receipt-line" onClick={drawerComponentsSupply.onOpen} />
              </Tooltip>
            )}
            <ButtonList
              label={t('global:help.title')}
              rightIcon="ri-arrow-down-s-line"
              ctaList={[
                {
                  label: t('quote:quotation.shortCuts.title'),
                  color: 'greenBrand',
                  onClick: shortCutsModalDisclosure.onOpen,
                },
                {
                  label: t('global:help.helpCenter'),
                  color: 'greenBrand',
                  onClick: () => handleChangeTabDrawer(4),
                },
                {
                  label: t('global:help.askFormation'),
                  color: 'greenBrand',
                  disabled: alreadyAskedForFormation === 'true',
                  tooltip: t('global:help.alreadyAskedFormation'),
                  onClick: handleAskFormation,
                },
                {
                  label: t('global:help.shareFeedback'),
                  color: 'greenBrand',
                  onClick: handleShareFeedback,
                  rightIcon: 'ri-external-link-line',
                },
                {
                  label: t('global:help.contactSupport'),
                  onClick: handleContactSupport,
                  rightIcon: 'ri-external-link-line',
                  color: 'greenBrand',
                },
              ]}
            />
            {!isOpen && <IconButton size="md" color="ghost" icon="ri-layout-right-line" onClick={onSideBarOpen} />}
          </Flex>
        )}
        {!isEditable && (
          <Flex justifyContent="flex-end" alignItems="center" gap={3}>
            <Tooltip label={t('quote:header.export')} shouldWrapChildren placement="left">
              <IconButton size="md" color="default" icon="ri-export-line" onClick={exportModalDisclosure.onOpen} />
            </Tooltip>
            {isSupplySheetEnabled && (
              <Tooltip label={t('quote:header.componentsSupply')} shouldWrapChildren placement="left">
                <IconButton size="md" color="default" icon="ri-receipt-line" onClick={drawerComponentsSupply.onOpen} />
              </Tooltip>
            )}
            <ButtonList
              label={t('global:help.title')}
              rightIcon="ri-arrow-down-s-line"
              ctaList={[
                {
                  label: t('quote:quotation.shortCuts.title'),
                  color: 'greenBrand',
                  onClick: shortCutsModalDisclosure.onOpen,
                },
                {
                  label: t('global:help.helpCenter'),
                  color: 'greenBrand',
                  onClick: () => handleChangeTabDrawer(4),
                },
                {
                  label: t('global:help.askFormation'),
                  color: 'greenBrand',
                  disabled: alreadyAskedForFormation === 'true',
                  tooltip: t('global:help.alreadyAskedFormation'),
                  onClick: handleAskFormation,
                },
                {
                  label: t('global:help.shareFeedback'),
                  color: 'greenBrand',
                  onClick: handleShareFeedback,
                  rightIcon: 'ri-external-link-line',
                },
                {
                  label: t('global:help.contactSupport'),
                  onClick: handleContactSupport,
                  rightIcon: 'ri-external-link-line',
                  color: 'greenBrand',
                },
              ]}
            />
            {!isOpen && <IconButton size="md" color="ghost" icon="ri-layout-right-line" onClick={onSideBarOpen} />}
          </Flex>
        )}
      </Flex>
      <ExportQuoteModal modalControls={exportModalDisclosure} />
      <ShortCutsModal isOpen={shortCutsModalDisclosure.isOpen} onClose={shortCutsModalDisclosure.onClose} />
      <DrawersStack drawersStack={drawersStack}>
        <ComponentsSupplyDrawer drawer={drawerComponentsSupply} />
      </DrawersStack>
    </>
  );
};
