import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import {
  Card,
  CheckboxField,
  CurrencyField,
  DateField,
  FormGroup,
  getCurrentDateAsString,
  GraneetIconButton,
  Modal,
  SegmentedInputGroupField,
  SingleSelectField,
  TextField,
  useCurrency,
} from '@graneet/lib-ui';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Form, useForm, useFormContext, useOnBlurValues, useFormStatus, HiddenField } from 'graneet-form';
import type { IBankingAccount } from '@graneet/business-logic';

import { useBankingConnections } from '../services/banking-connection.api';
import { formatBankingAccountName } from '../services/banking-account.util';

import { BankingBankLogo } from './BankingBankLogo';

import { Rule } from 'features/form/rules/Rule';
import { QueryWrapper } from 'features/api/components/QueryWrapper';

const FORM_ID = 'banking_create_payment';

interface FormValues {
  label: string;

  amount: number;

  bankingAccountId: string;

  recipientName: string;

  recipientIban: string;

  updateRecipientInformation: boolean;

  type: 'classic' | 'instant';

  date: string;
}

export interface BankingCreatePaymentModalProps {
  onClose: () => void;

  isOpen: boolean;

  editedEntityPreview: ReactNode;

  onSubmit: (formValues: FormValues) => Promise<void>;

  defaultValues: Pick<FormValues, 'recipientIban' | 'recipientName' | 'amount'>;
}

const BankingOption = ({
  bankingAccount,
}: {
  bankingAccount: {
    bankingAccount: Pick<IBankingAccount, 'id' | 'name' | 'iban'>;
    bankingConnection: { name: string; bankUuid: string };
  };
}) => {
  const text = `${bankingAccount.bankingConnection.name} - ${formatBankingAccountName(bankingAccount.bankingAccount)}`;

  return (
    <Flex gap={2} alignItems="center">
      <BankingBankLogo bankingConnection={bankingAccount.bankingConnection} />
      <Text>{text}</Text>
    </Flex>
  );
};

const RecipientBlock = ({ defaultValues }: Pick<BankingCreatePaymentModalProps, 'defaultValues'>) => {
  const { t } = useTranslation(['banking']);

  const [isEdition, setIsEdition] = useState(false);

  if (!isEdition) {
    return (
      <FormGroup label={t('banking:bankingPayment.fields.recipient')} isRequired>
        <Card>
          <Flex gap={2} justifyContent="space-between">
            <Flex direction="column">
              <Text fontSize="sm" color="primary">
                {defaultValues.recipientName}
              </Text>
              <Flex fontSize="smaller" color="greenBrand.baseTertiary">
                {defaultValues.recipientIban ? (
                  defaultValues.recipientIban
                ) : (
                  <Flex gap={2} color="orange.500">
                    <i className="ri-error-warning-fill" />
                    {t('banking:alerts.missingIban')}
                  </Flex>
                )}
              </Flex>
            </Flex>
            <GraneetIconButton icon="ri-pencil-line" color="ghost" onClick={() => setIsEdition(true)} />
          </Flex>
        </Card>

        <HiddenField<FormValues> name="recipientName">
          <Rule.IsRequired />
        </HiddenField>
        <HiddenField<FormValues> name="recipientIban">
          <Rule.IsRequired />
          <Rule.IsIban />
        </HiddenField>
        <HiddenField<FormValues> name="updateRecipientInformation">
          <Rule.IsRequired />
        </HiddenField>
      </FormGroup>
    );
  }

  return (
    <>
      <TextField<FormValues> name="recipientName" label={t('banking:bankingPayment.fields.recipientName')} isReadOnly>
        <Rule.IsRequired />
      </TextField>

      <TextField<FormValues>
        name="recipientIban"
        label={t('banking:bankingPayment.fields.recipientIban')}
        placeholder="FR58 0000 0000 0000 0000 0000 000"
        isRequired
      >
        <Rule.IsRequired />
        <Rule.IsIban />
      </TextField>

      <CheckboxField<FormValues>
        name="updateRecipientInformation"
        label={t('banking:bankingCreatePaymentModal.updateRecipientInformation')}
      >
        <Rule.IsRequired />
      </CheckboxField>
    </>
  );
};

const BankingCreatePaymentModalInternal: FC<BankingCreatePaymentModalProps> = ({
  editedEntityPreview,
  defaultValues,
}) => {
  const { t } = useTranslation(['banking']);

  const bankingConnections = useBankingConnections();

  const bankingAccounts = bankingConnections.data.flatMap((bankingConnection) =>
    bankingConnection.bankingAccounts.map((bankingAccount) => ({
      bankingAccount,
      bankingConnection: {
        name: bankingConnection.name,
        bankUuid: bankingConnection.bankUuid,
      },
    })),
  );

  const form = useFormContext<FormValues>();
  const { type } = useOnBlurValues(form, ['type']);
  const isInstant = type === 'instant';

  return (
    <Flex direction="column" gap={3}>
      {editedEntityPreview}

      <TextField<FormValues> name="label" label={t('banking:bankingPayment.fields.label')} isRequired>
        <Rule.IsRequired />
      </TextField>

      <CurrencyField<FormValues> name="amount" label={t('banking:bankingPayment.fields.amount')} isRequired>
        <Rule.IsRequired />
      </CurrencyField>

      <SingleSelectField<FormValues>
        name="bankingAccountId"
        label={t('banking:bankingPayment.fields.bankingAccount')}
        options={bankingAccounts.map((bankingAccount) => ({
          label: <BankingOption bankingAccount={bankingAccount} />,
          value: bankingAccount.bankingAccount.id,
          searchTerm: `${bankingAccount.bankingAccount.name} ${bankingAccount.bankingAccount.iban} ${bankingAccount.bankingConnection.name}`,
        }))}
        isRequired
        isClearable={false}
      >
        <Rule.IsRequired />
      </SingleSelectField>

      <RecipientBlock defaultValues={defaultValues} />

      <Flex alignItems="center">
        <SegmentedInputGroupField<FormValues>
          name="type"
          label={t('banking:bankingPayment.fields.type')}
          isRequired
          options={[
            {
              optionValue: 'classic',
              optionLabel: (
                <>
                  <i className="ri-time-fill" />
                  {t('banking:bankingPayment.type.classic')}
                </>
              ),
            },
            {
              optionValue: 'instant',
              optionLabel: (
                <>
                  <i className="ri-flashlight-fill" />
                  {t('banking:bankingPayment.type.instant')}
                </>
              ),
            },
          ]}
        >
          <Rule.IsRequired />
        </SegmentedInputGroupField>

        <DateField<FormValues>
          name="date"
          label={t('banking:bankingPayment.fields.date')}
          isDisabled={isInstant}
          datePickerProps={{
            disabledDays: {
              before: new Date(),
            },
          }}
        >
          <Rule.IsRequired />
        </DateField>
      </Flex>

      <Flex gap={2} color="greenBrand.baseTertiary" fontSize="smaller">
        <i className="ri-information-line" />
        {isInstant
          ? t('banking:bankingCreatePaymentModal.instantTypeInformation')
          : t('banking:bankingCreatePaymentModal.classicTypeInformation')}
      </Flex>
    </Flex>
  );
};

export const BankingCreatePaymentModal: FC<BankingCreatePaymentModalProps> = ({
  onClose,
  isOpen,
  editedEntityPreview,
  onSubmit,
  defaultValues,
}) => {
  const { t } = useTranslation(['banking']);

  const { mapNumberToAmount } = useCurrency();

  const form = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      amount: mapNumberToAmount(defaultValues.amount),
      date: getCurrentDateAsString(),
      type: 'classic',
      updateRecipientInformation: false,
    },
  });
  const { isValid } = useFormStatus(form);

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      setIsLoading(true);
      await onSubmit(formValues).finally(() => {
        setIsLoading(false);
      });

      onClose();
    },
    [onClose, onSubmit],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('banking:bankingCreatePaymentModal.title')}>
      <Form form={form} id={FORM_ID} onSubmit={form.handleSubmit(handleSubmit)}>
        <QueryWrapper>
          <BankingCreatePaymentModalInternal
            key={`${isOpen}`}
            isOpen={isOpen}
            onClose={onClose}
            editedEntityPreview={editedEntityPreview}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
          />
        </QueryWrapper>

        <Modal.Close />
        <Modal.CloseButton isDisabled={isLoading} />
        <Modal.PrimaryButton type="submit" form={FORM_ID} isLoading={isLoading} isDisabled={!isValid}>
          {t('banking:actions.pay')}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
