import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useCurrency } from '@graneet/lib-ui';
import { Big } from 'big.js';
import { ComputeQuoteSalesDiscountAmountUseCase, QuoteSalesDiscount } from '@org/quotation-lib';

import type { SaleDiscountForm } from '../components/modals/AddSaleDiscountModal';

export const useSaleDiscountTotal = (amountBeforeSaleDiscountExVAT: Big | null): Big => {
  const { mapAmountToStringNumber } = useCurrency();

  const form = useFormContext<SaleDiscountForm>();
  const { saleDiscountType, saleDiscountPercentage, saleDiscountAmountExVAT } = useOnChangeValues(form, [
    'saleDiscountType',
    'saleDiscountPercentage',
    'saleDiscountAmountExVAT',
  ]);

  if (
    amountBeforeSaleDiscountExVAT === null ||
    (saleDiscountPercentage === undefined && saleDiscountAmountExVAT === undefined)
  ) {
    return Big(0);
  }

  const formDiscount = {
    type: saleDiscountType,
    percentage: saleDiscountPercentage || '0',
    amount: saleDiscountAmountExVAT ? mapAmountToStringNumber(saleDiscountAmountExVAT) : '0',
  };

  const saleDiscount = new QuoteSalesDiscount(formDiscount);

  return amountBeforeSaleDiscountExVAT.minus(
    ComputeQuoteSalesDiscountAmountUseCase.execute(saleDiscount, amountBeforeSaleDiscountExVAT),
  );
};
