import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICompanyMarginsResponseDTO, IQuoteSellsheetMarginDTO } from '@graneet/business-logic';
import { groupExistingComponentsByType } from '@graneet/business-logic';

import { previewQuoteMarginSellSheet } from '../services/quote-sellsheet.api';

export const useQuoteSellSheetMarginPreview = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);

  return useCallback(
    async (quoteId: number, dto: IQuoteSellsheetMarginDTO, companyComponentTypes: ICompanyMarginsResponseDTO) => {
      if (!dto) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        return true;
      }

      const [err, result] = await previewQuoteMarginSellSheet(quoteId, dto);

      if (err) {
        toast.error(t('quote:errors.updatingError'));
        return true;
      }

      const { components } = result;

      return groupExistingComponentsByType(Object.values(components), companyComponentTypes);
    },
    [t, toast],
  );
};
