import type { FC } from 'react';
import { useMemo } from 'react';
import { AmountSummary } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IQuote, IQuoteJob, IQuoteLot } from '@graneet/business-logic';
import { VAT_TYPE, computeNewQuoteAmounts } from '@graneet/business-logic';

import { useQuoteExportLotComputedValue, useQuoteExportTreeContext } from '../hooks/useQuoteExportTree';

import { QuoteTotalsCustomDiscount } from 'features/quote-discount/components/QuoteTotalsCustomDiscount';
import { QuoteTotalsDiscount } from 'features/quote-discount/components/QuoteTotalsDiscount';

interface QuoteExportDeepTableFooterProps {
  quote: IQuote;
}

export const QuoteExportDeepTableFooter: FC<QuoteExportDeepTableFooterProps> = ({ quote }) => {
  const { t } = useTranslation(['customDiscount', 'quote']);

  const { getDisplayedCurrentTree } = useQuoteExportTreeContext();

  const rootLot = getDisplayedCurrentTree().rootNodeId;
  const node = useQuoteExportLotComputedValue(rootLot);

  const isQuoteFullySelected = useMemo(
    () => node?.numberOfJobs === 0 || node?.numberOfUnselectedJobs === 0,
    [node?.numberOfJobs, node?.numberOfUnselectedJobs],
  );
  const quoteAmounts = useMemo(() => {
    const items = getDisplayedCurrentTree().leaves;
    const selectedItems = Object.values(items).filter((item) => item.isSelected && !item.isOptional);
    /*
       It is enough to just pass the flat list of jobs to recompute total amounts, we do not need to rebuild the lot structure,
       but we need to provide the rootLotAmountExVAT for computeNewQuoteAmounts method to work
     */
    const flatRootLot = {
      jobs: selectedItems as IQuoteJob[],
      lots: [] as IQuoteLot[],
      amountExVAT: node?.amountExVAT || 0,
    } as IQuoteLot;

    /* We omit the discount and custom discount if the quote is not fully selected */
    const quoteDiscount = isQuoteFullySelected ? quote.discount : null;
    const quoteCustomDiscounts = isQuoteFullySelected ? quote.customDiscounts : [];

    return computeNewQuoteAmounts(quote, flatRootLot, quoteDiscount, quoteCustomDiscounts);
  }, [getDisplayedCurrentTree, isQuoteFullySelected, node, quote]);

  return (
    <AmountSummary mt={6}>
      {isQuoteFullySelected && (
        <>
          {quote.discount && (
            <QuoteTotalsDiscount
              amountWithoutDiscountExVAT={quoteAmounts.amountWithoutDiscountExVAT}
              discount={quote.discount}
              noActions
            />
          )}

          {!!quote?.customDiscounts?.length && (
            <AmountSummary.Item
              label={t(
                quote.discount
                  ? 'quote:fields.amountWithDiscountExVAT'
                  : 'customDiscount:totalExVATBeforeCustomDiscountLabel',
              )}
              amount={quoteAmounts.amountWithDiscountExVAT}
              important
            />
          )}
          {(quote?.customDiscounts || []).map((customDiscount) => (
            <QuoteTotalsCustomDiscount
              quoteId={quote.id}
              customDiscount={customDiscount}
              amountWithDiscountExVAT={quoteAmounts.amountWithDiscountExVAT}
              key={customDiscount.id}
              noActions
            />
          ))}
        </>
      )}
      <AmountSummary.Item label={t('quote:fields.totalPriceExVAT')} amount={quoteAmounts.amountExVAT} important />
      <AmountSummary.VATDistribution
        vatType={quoteAmounts.hasReversalOfLiability ? VAT_TYPE.REVERSE_CHARGE : VAT_TYPE.NORMAL}
        withCustomDiscountsLabel={!!quoteAmounts?.hasReversalOfLiability && !!quote.customDiscounts?.length}
        vatDistribution={quoteAmounts.vatDistribution}
      />
      <AmountSummary.Item label={t('quote:fields.totalPriceIncVAT')} amount={quoteAmounts.amountIncVAT} important />
    </AmountSummary>
  );
};
