import { useTranslation } from 'react-i18next';
import { PERMISSION } from '@graneet/business-logic';
import type { FC } from 'react';
import type { QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';
import type { Response } from '@graneet/lib-ui';

import { TextEditionCard } from 'features/common/components/TextEditionCard';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useQuotationApi } from 'features/quotation/services/quote.api';

interface QuoteCommentCardProps {
  quote: QuoteWithoutRelationsDTO;
}

export const QuoteCommentCard: FC<QuoteCommentCardProps> = ({ quote }) => {
  const { t } = useTranslation(['quote']);
  const hasCreateQuotesPermission = usePermissions([PERMISSION.CREATE_QUOTES]);

  const { useQuotePartialUpdate } = useQuotationApi();
  const quotePartialUpdateMutation = useQuotePartialUpdate();

  const onCommentSaved = async (comment: string) => {
    const res = await quotePartialUpdateMutation.mutateAsync({ quoteId: quote.id, comment });
    return [null, res] as Response<QuoteWithoutRelationsDTO>;
  };

  if (!quote.comment && !hasCreateQuotesPermission) {
    return null;
  }

  return (
    <TextEditionCard
      title={t('quote:fields.comment')}
      errorLabel={t('quote:changeComment.error')}
      successLabel={t('quote:changeComment.success')}
      placeholder={t('quote:changeComment.placeholder')}
      text={quote.comment}
      onTextEdited={onCommentSaved}
      isEditable={hasCreateQuotesPermission}
    />
  );
};
