import type { PaginatedResponse } from '../pagination/paginated-response.type';
import type { ISubProject } from '../sub-project/sub-project.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IBill } from '../bill/bill.type';

export enum HOLDBACK_STATUSES {
  WAITING = 'WAITING', // Project is on going
  ON_GOING = 'ON_GOING', // Project is ended
  TO_BE_RECEIVED = 'TO_BE_RECEIVED', // Bill is created after few months (or manual action)
  PARTIALLY_RECEIVED = 'PARTIALLY_RECEIVED', // Bill partially paid
  RECEIVED = 'RECEIVED', // Bill totally paid
}

export interface IHoldback {
  id: number;

  historyFamilyId: string;

  hasPaymentGuarantee: boolean;

  holdbackPercentage: number;

  bankName: string | null;

  status: HOLDBACK_STATUSES;

  holdbackAmountIncVAT: number;

  holdbackEndDate: Date | null;

  paymentGuaranteeAmount: number | null;

  createdAt: Date;

  updatedAt: Date;

  projectRefCode: string;

  projectName: string;

  remainingDays?: number | null;

  subProject?: ISubProject;

  bill?: IBill | null;
}

export type IHoldbackWithRelations = RequiredByKeys<IHoldback, 'subProject'>;

export type HoldbackPaginatedResponse = PaginatedResponse<IHoldbackWithRelations, 'holdbackAmountIncVAT'>;
