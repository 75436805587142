import { useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Text } from '@chakra-ui/react';
import type { ISupplierInvoice } from '@graneet/business-logic';
import { isDirectPaymentSupplierInvoice as isDirectPaymentSupplierInvoiceFn } from '@graneet/business-logic';

import { SupplierInvoiceCheckListDeletion } from './SupplierInvoiceCheckListDeletion';

interface SupplierInvoiceDeleteModalProps {
  isOpen: boolean;

  onClose: () => void;

  supplierInvoice: ISupplierInvoice;

  onDeleted: () => Promise<void>;
}
export const SupplierInvoiceDeleteModal: FC<SupplierInvoiceDeleteModalProps> = ({
  isOpen,
  onClose,
  supplierInvoice,
  onDeleted,
}) => {
  const { t } = useTranslation(['supplierInvoices']);

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    await onDeleted();
    setIsLoading(false);
  }, [onDeleted]);

  const isDirectPaymentSupplierInvoice = isDirectPaymentSupplierInvoiceFn(supplierInvoice);
  const confirmationMessage = isDirectPaymentSupplierInvoice
    ? t('supplierInvoices:cards.delete.directPaymentConfirmationMessage')
    : t('supplierInvoices:cards.delete.modalConfirm');

  return (
    <Modal title={t('supplierInvoices:cards.delete.title')} isOpen={isOpen} onClose={onClose} size="2xl">
      {confirmationMessage}

      {isDirectPaymentSupplierInvoice && (
        <SupplierInvoiceCheckListDeletion supplierInvoiceFiles={supplierInvoice.supplierInvoiceFiles} />
      )}
      <Text mt={5}>{t('supplierInvoices:cards.delete.confirmationQuestion')}</Text>

      <Modal.Close isDisabled={isLoading} />
      <Modal.PrimaryButton onClick={handleDelete} isLoading={isLoading} colorScheme="red">
        {t('supplierInvoices:cards.delete.action')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
