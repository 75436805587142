import { getDiscountAmountExVAT, isNumberFinite } from '@graneet/business-logic';
import { useCurrency } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import type { DiscountEditionForm } from '../forms/discount-edition-form';

export const useDiscountTotalExVAT = (amountWithoutDiscountExVAT: number | undefined) => {
  const { mapAmountToNumber } = useCurrency();

  const form = useFormContext<DiscountEditionForm>();
  const { discountType, discountPercentage, discountAmountExVAT } = useOnChangeValues(form, [
    'discountType',
    'discountPercentage',
    'discountAmountExVAT',
  ]);

  if (!isNumberFinite(amountWithoutDiscountExVAT) || !discountType) {
    return 0;
  }

  const discount = {
    type: discountType,
    percentage: isNumberFinite(discountPercentage) ? discountPercentage : 0,
    amountExVAT: isNumberFinite(discountAmountExVAT) ? mapAmountToNumber(discountAmountExVAT) : 0,
  };

  return amountWithoutDiscountExVAT - getDiscountAmountExVAT(discount, amountWithoutDiscountExVAT);
};
