import type { FC, ReactNode } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Avatar, Box, Text, VStack } from '@chakra-ui/react';

import { EllipsisText } from '../EllipsisText';

export interface ProfileProps extends FlexProps {
  imgUrl?: string;
  name?: string;
  nameComponent?: ReactNode;
  mail?: string;
  size?: 'sm' | 'md';
}

export const Profile: FC<ProfileProps> = ({ imgUrl = '', name = '', mail = '', nameComponent, size, ...props }) => (
  <Box display="grid" gridTemplateColumns="max-content auto" gridGap="10px" alignItems="center" {...props}>
    <Avatar name={name} src={imgUrl} bg="gray.50" color="greenBrand.light" border="1px" fontSize={size} size={size} />
    <VStack gap={0} minW={0} align="left">
      {nameComponent || <EllipsisText fontSize={size}>{name}</EllipsisText>}
      <Text
        maxWidth="fit-content"
        fontSize="xs"
        color="gray.500"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {mail}
      </Text>
    </VStack>
  </Box>
);
