import { useMemo } from 'react';
import type { IContractInfosLot, IContractInfosItem, ILotWithUUID, IItemWithUUID } from '@graneet/business-logic';
import { roundFloating, multiplyFloating } from '@graneet/business-logic';
import type { ComputedValuesFns, IInitialTree } from '@graneet/lib-ui';
import { useLeaf, useLeafComputedValue, useNode, useNodeComputedValue, useTree, useTreeContext } from '@graneet/lib-ui';

import { isItemComplete } from '../services/contract.util';
import type {
  ContractItemComputedValue,
  ContractLotComputedValue,
  IContractInfosItemWithUUID,
  IContractInfosLotWithUUID,
} from '../types/contract.type';

export const useContractTree = (initialTree: IInitialTree<IContractInfosLot, IContractInfosItem>) => {
  const computedValuesFns = useMemo<
    ComputedValuesFns<
      IContractInfosLotWithUUID,
      IContractInfosItemWithUUID,
      ContractLotComputedValue,
      ContractItemComputedValue
    >
  >(
    () => ({
      computeNodeComputedValue: (node, treeContext) => {
        const displayedTree = treeContext.getDisplayedCurrentTree();

        const directChildren = displayedTree.relations[node.id];

        const computedValues = treeContext.getComputedValues();

        let totalExVAT = 0;
        let totalOptionalAmountExVAT = 0;
        let numberOfIncompleteItems = 0;
        let hasInvoicedItems = false;
        let totalAmountInvoiced = 0;

        directChildren.nodes.forEach((childId) => {
          const nodeInfos = treeContext.getNodeSignalInfos(childId);

          // -- totalExVAT
          totalExVAT += nodeInfos.state.isDeleted ? 0 : computedValues.nodes[childId].totalExVAT;

          // -- totalOptionalAmount
          totalOptionalAmountExVAT += nodeInfos.state.isDeleted
            ? 0
            : computedValues.nodes[childId].totalOptionalAmountExVAT;

          // -- numberOfIncompleteItems
          numberOfIncompleteItems += nodeInfos.state.isDeleted
            ? 0
            : computedValues.nodes[childId].numberOfIncompleteItems;

          // -- hasInvoicedItems
          const { amountInvoiced } = computedValues.nodes[childId];
          hasInvoicedItems = hasInvoicedItems || amountInvoiced !== 0;

          // -- amountInvoiced
          totalAmountInvoiced += amountInvoiced;
        });

        directChildren.leaves.forEach((childId) => {
          const { isComplete } = computedValues.leaves[childId];
          const leafInfos = treeContext.getLeafSignalInfos(childId);

          if (displayedTree.leaves[childId].isOptional) {
            totalOptionalAmountExVAT += leafInfos.state.isDeleted ? 0 : computedValues.leaves[childId].totalExVAT;
          } else {
            totalExVAT += leafInfos.state.isDeleted ? 0 : computedValues.leaves[childId].totalExVAT;
          }

          // -- numberOfIncompleteItems
          numberOfIncompleteItems += isComplete ? 0 : 1;

          // -- hasInvoicedItems
          const { invoicedAmountExVAT } = displayedTree.leaves[childId];
          hasInvoicedItems = hasInvoicedItems || invoicedAmountExVAT !== 0;

          // -- amountInvoiced
          totalAmountInvoiced += invoicedAmountExVAT;
        });

        const totalExVATDifference = treeContext.getNodeSignalInfos(node.id).state.isCreated
          ? totalExVAT
          : totalExVAT - node.totalAmountExVAT;

        return {
          totalExVAT,
          totalExVATDifference,
          numberOfIncompleteItems,
          amountInvoiced: totalAmountInvoiced,
          hasInvoicedItems,
          totalOptionalAmountExVAT,
        };
      },

      computeLeafComputedValue: (leaf, treeContext) => {
        const initalTree = treeContext.getInitialTree();

        const leafInfos = treeContext.getLeafSignalInfos(leaf.id);
        const isLeafDeleted = leafInfos.state.isDeleted;
        const isLeafCreated = leafInfos.state.isCreated;

        // Newly created leaves (items) are never optional so they cannot be accepted
        const isLeafAccepted = !isLeafCreated && initalTree.leaves[leaf.id].isOptional !== leaf.isOptional;
        const totalExVAT = isLeafDeleted ? 0 : roundFloating(multiplyFloating(leaf.unitPrice || 0, leaf.quantity || 0));

        return {
          totalExVAT,
          totalExVATDifference: isLeafAccepted || isLeafCreated ? totalExVAT : totalExVAT - leaf.totalExVAT,
          isComplete: isItemComplete(leaf, isLeafDeleted),
        };
      },
    }),
    [],
  );

  return useTree(initialTree, computedValuesFns);
};

export const useContractTreeContext = () =>
  useTreeContext<
    IContractInfosLotWithUUID,
    IContractInfosItemWithUUID,
    ContractLotComputedValue,
    ContractItemComputedValue
  >();

export const useContractLot = (id: ILotWithUUID['id']) =>
  useNode<IContractInfosLotWithUUID, IContractInfosItemWithUUID>(id);

export const useContractLotComputedValue = (id: ILotWithUUID['id']) =>
  useNodeComputedValue<IContractInfosLotWithUUID, ContractLotComputedValue>(id);

export const useContractItem = (id: IItemWithUUID['id']) =>
  useLeaf<IContractInfosLotWithUUID, IContractInfosItemWithUUID>(id);

export const useContractItemComputedValue = (id: IItemWithUUID['id']) =>
  useLeafComputedValue<IContractInfosItemWithUUID, ContractItemComputedValue>(id);
