import { Box, FormControl, FormLabel, HStack, Switch } from '@chakra-ui/react';
import { Button, SingleSelect } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useTimeTrackingContext } from '../context/TimeTrackingContext';

import { TimeTrackingExportButton } from './TimeTrackingExportButton';

export const TimeTrackingFilters: FC = () => {
  const { t } = useTranslation(['timeTracking']);

  const {
    state: {
      filters: { groupBy, view },
      loading,
    },
    handleGroupByChange,
    handleViewChange,
    updateMode,
    mode,
  } = useTimeTrackingContext();

  return (
    <HStack w="100%" justifyContent="flex-end" mb={5} pr={4}>
      {loading && <Button variant="outline" size="l" isLoading mr="auto" ml={5} />}
      {!loading && <TimeTrackingExportButton />}

      {view === 'week' && (
        <Box>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="approve-view" mb={0}>
              {t('timeTracking:timeTable.approveView')}
            </FormLabel>
            <Switch
              id="approve-view"
              onChange={(e) => {
                updateMode(e.target.checked ? 'approve' : 'default');
              }}
              isChecked={mode === 'approve'}
            />
          </FormControl>
        </Box>
      )}

      <Box width="fit-content">
        <SingleSelect<{ value: 'worker' | 'project'; label: string }>
          placeholder={t(`timeTracking:timeTable.${groupBy}View`)}
          options={[
            { value: 'worker', label: t('timeTracking:timeTable.workerView') },
            { value: 'project', label: t('timeTracking:timeTable.projectView') },
          ]}
          onChange={(option) => {
            if (option) {
              handleGroupByChange(option.value);
            }
          }}
          isSearchable={false}
          isClearable={false}
        />
      </Box>

      <Box width="fit-content">
        <SingleSelect<{ value: 'week' | 'month'; label: string }>
          placeholder={t(`timeTracking:timeTable.${view}View`)}
          options={[
            { value: 'week', label: t('timeTracking:timeTable.weekView') },
            { value: 'month', label: t('timeTracking:timeTable.monthView') },
          ]}
          onChange={(option) => {
            if (option) {
              handleViewChange(option.value);
            }
          }}
          isSearchable={false}
          isClearable={false}
        />
      </Box>
    </HStack>
  );
};
