import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQueryClient } from '@tanstack/react-query';
import type { BankingPaymentResourcePaginatedResponse } from '@graneet/business-logic';
import type { UsePaginationOptions } from '@graneet/lib-ui';
import { usePaginationQuery } from '@graneet/lib-ui';

import { apiNew } from 'features/api/services/apiNew.service';

const BANKING_PAYMENT_RESOURCE_PATH = '/banking-payment-resources';

const bankingPaymentResourceKeyFactory = createQueryKeys('banking-payment-resource', {
  get: (queryParams: URLSearchParams) => ({
    queryKey: [BANKING_PAYMENT_RESOURCE_PATH, queryParams],
    queryFn: () =>
      apiNew.get<URLSearchParams, BankingPaymentResourcePaginatedResponse>(BANKING_PAYMENT_RESOURCE_PATH, queryParams),
  }),
});

export function useBankingPaymentResources(paginationOptions?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (queryParams) => queryClient.fetchQuery(bankingPaymentResourceKeyFactory.get(queryParams)),
    paginationOptions,
  );
}
