import { useCallback, useLayoutEffect, useMemo } from 'react';
import {
  ListingLayout,
  Filters,
  usePaginatedData,
  PaginatedDataProvider,
  AsyncTableData,
  SimpleQuoteIcon,
  SimpleAlertIcon,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS, PERMISSION } from '@graneet/business-logic';
import { QuoteStatus } from '@org/graneet-bff-client';
import { Flex } from '@chakra-ui/react';

import { useDisplayedUsers } from 'features/user/services/user.api';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useAppContext } from 'features/app/contexts/AppContext';
import { useData } from 'features/api/hooks/useData';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { QuoteTable } from 'features/quotation/quote/components/tables/QuoteTable';
import { QUOTE_FIELDS, QUOTE_STATUS_COLOR } from 'features/quotation/quote-common/constants/quote.constant';
import { QuoteActionsButtons } from 'features/quotation/quote/components/buttons/QuoteActionsButtons';
import { formatUserDisplayName } from 'features/user/services/user.util';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

const SCREEN_PERMISSIONS = [PERMISSION.VIEW_ALL_QUOTES];

export const ViewQuoteScreen = () => {
  const { t } = useTranslation(['global', 'quote', 'accountManager']);
  const { currentUser } = useAppContext();
  const { updateHeaderTitle } = useHeaderContext();

  const hasPermissions = usePermissions(SCREEN_PERMISSIONS);
  const forcedFilters = useMemo(
    () =>
      hasPermissions
        ? undefined
        : {
            [QUOTE_FIELDS.ACCOUNT_MANAGERS]: [currentUser.id],
          },
    [currentUser, hasPermissions],
  );

  const quoteProxy = useQuotationProxyApis().quoteProxyApi;

  const getAvailableQuoteTagsGetter = useCallback(() => quoteProxy!.getAvailableTagsByCompanyId(), [quoteProxy]);

  const { data: tagsData } = useData(getAvailableQuoteTagsGetter);

  const getPaginatedQuotesGetter = useCallback(
    (params: URLSearchParams | undefined) => quoteProxy!.getQuotesPaginatedByCompanyId(params),
    [quoteProxy],
  );

  const data = usePaginatedData(getPaginatedQuotesGetter, forcedFilters, forcedFilters);

  const { data: paginatedQuotes, fetch: fetchQuotes } = data;

  const quoteActions = useMemo(() => <QuoteActionsButtons onImportCompleted={fetchQuotes} />, [fetchQuotes]);

  const availableStatuses = useMemo(
    () =>
      Object.values(QuoteStatus).map((status) => ({
        value: status,
        colorScheme: QUOTE_STATUS_COLOR[status],
        label: t(`quote:statuses.${status}`),
      })),
    [t],
  );

  const users = useDisplayedUsers();

  const availableUsers = useMemo(
    () =>
      users.data.map<{ value: string; label: string }>((user) => ({
        value: user.id.toString(),
        label: formatUserDisplayName(user),
      })),
    [users],
  );

  const availableTags = useMemo(
    () => (tagsData?.tags || []).map<{ value: string; label: string }>((tag) => ({ value: tag, label: tag })),
    [tagsData],
  );

  const isExportEnabled = useFeatureFlag(FEATURE_FLAGS.EXPORT_QUOTES_TABLE);

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.quotes'), []);
  }, [t, updateHeaderTitle]);

  return (
    <PaginatedDataProvider data={data}>
      <Filters>
        <ListingLayout h="auto" p={8}>
          <ListingLayout.Search placeholder={t('quote:actions.search')} />
          <Flex gap={2} alignItems="center">
            <ListingLayout.FilterButton>
              <Filters.CheckBox
                name={QUOTE_FIELDS.STATUS}
                availableValues={availableStatuses}
                label={t('quote:filters.status')}
              />
              <Filters.MultiSelectFilters
                name={QUOTE_FIELDS.TAGS}
                availableValues={availableTags}
                label={t('global:words.tags')}
                noValueMessage={t('global:tags.noLabel')}
                placeholder={t('global:words.c.select')}
              />
              {hasPermissions && (
                <Filters.MultiSelectFilters
                  name={QUOTE_FIELDS.ACCOUNT_MANAGERS}
                  availableValues={availableUsers}
                  label={t('accountManager:title')}
                  placeholder={t('global:words.c.select')}
                />
              )}
              <Filters.DateSelect name={QUOTE_FIELDS.DATE} label={t('quote:filters.date')} />
            </ListingLayout.FilterButton>
          </Flex>
          <ListingLayout.Actions>{quoteActions}</ListingLayout.Actions>
          <ListingLayout.FiltersApplied
            filters={{
              [QUOTE_FIELDS.STATUS]: {
                label: t('quote:filters.status'),
                type: 'multi',
                availableValues: availableStatuses,
              },
              [QUOTE_FIELDS.ACCOUNT_MANAGERS]: {
                label: t('accountManager:title'),
                type: 'multi',
                availableValues: availableUsers,
              },
              [QUOTE_FIELDS.TAGS]: {
                label: t('global:words.tags'),
                type: 'multi',
                availableValues: availableTags,
              },
              [QUOTE_FIELDS.DATE]: {
                label: t('quote:filters.date'),
                type: 'date',
              },
            }}
          />
          <ListingLayout.Content>
            <AsyncTableData
              errorState={{
                message: t('quote:errors.listingError', { email: SUPPORT_EMAIL }),
                icon: <SimpleAlertIcon boxSize={45} />,
              }}
              emptyState={{
                icon: <SimpleQuoteIcon boxSize={45} />,
                children: t('quote:errors.noQuote'),
                action: quoteActions,
              }}
            >
              <QuoteTable
                quotes={paginatedQuotes || []}
                showClientColumn
                showAccountManagers
                hasPagination
                displaySelects={isExportEnabled}
                onStatusesChanged={fetchQuotes}
              />
            </AsyncTableData>
          </ListingLayout.Content>
        </ListingLayout>
      </Filters>
    </PaginatedDataProvider>
  );
};
