import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SimpleAddIcon } from '@graneet/lib-ui';
import { Box, Flex } from '@chakra-ui/react';

import { useQuoteDisplayContext } from '../../../hooks/useQuoteDisplayContext';
import { QuoteImportJobInLotButton } from '../../buttons/QuoteImportJobInLotButton';
import { QuoteAddDiscountButtons } from '../../buttons/QuoteAddDiscountButtons';

import { QuoteTotals } from './QuoteTotals';

import type { QuoteLotData } from 'features/quote-lot/types/QuoteLotData';
import { useStore } from 'store/store';

interface EditQuoteTableFooterProps {
  rootLot: QuoteLotData & { id: number };
  handleAddJob(isParentHiddenCost: boolean): void;
  handleAddLot(): void;
}

export const EditQuoteTableFooter: FC<EditQuoteTableFooterProps> = ({ rootLot, handleAddJob, handleAddLot }) => {
  const { t } = useTranslation(['quote']);

  const { isReadOnlyView } = useQuoteDisplayContext();

  const hasHiddenCosts = useStore((state) => state.hasHiddenCosts);

  return (
    <Flex justify="space-between" mt={-6}>
      {!isReadOnlyView && (
        <Box display="flex" gap={2}>
          <Box display="inline-block">
            <QuoteImportJobInLotButton quoteLotId={rootLot.id} />
          </Box>
          <Button variant="outline" leftIcon={<SimpleAddIcon />} onClick={() => handleAddJob(false)}>
            {t('quote:jobsStep.createJob')}
          </Button>

          <Button variant="outline" leftIcon={<SimpleAddIcon />} onClick={handleAddLot}>
            {t('quote:jobsStep.createLot')}
          </Button>
        </Box>
      )}

      <Box ml="auto">
        <Flex justifyContent="flex-end" gap={2}>
          {!isReadOnlyView && !hasHiddenCosts && (
            <Button variant="outline" leftIcon={<SimpleAddIcon />} onClick={() => handleAddJob(true)} mb={2}>
              {t('quote:hiddenCost.addNewHiddenCost')}
            </Button>
          )}
          {!isReadOnlyView && (
            <Flex justifyContent="flex-end">
              <QuoteAddDiscountButtons />
            </Flex>
          )}
        </Flex>

        <QuoteTotals rootLot={rootLot} />
      </Box>
    </Flex>
  );
};
