import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import type { FC } from 'react';

import { SUPPLIER_INVOICES_STATUS_COLOR } from 'features/supplier-invoice/constants/supplier-invoice.constant';

export type SupplierInvoiceStatusBadgeProps = {
  /**
   * The supplier invoice status for wich display the badge
   */
  status: SUPPLIER_INVOICE_STATUS;
};

export const SupplierInvoiceStatusBadge: FC<SupplierInvoiceStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation(['supplierInvoices']);

  return (
    <Badge w="auto" minWidth="5.25rem" px={3} colorScheme={SUPPLIER_INVOICES_STATUS_COLOR[status]}>
      {t(`supplierInvoices:statuses.${status}`)}
    </Badge>
  );
};
