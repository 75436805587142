import type { FC } from 'react';
import { Box } from '@chakra-ui/react';

export interface SeparatorProps {
  line?: boolean;
}

export const Separator: FC<SeparatorProps> = ({ line }) => (
  <Box gridColumn="span 3" py={line ? 2 : 1}>
    {line && <Box h="1px" backgroundColor="gray.500" />}
  </Box>
);
