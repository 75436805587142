/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useReducer } from 'react';
import deepEqual from 'fast-deep-equal/es6';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useLocalStorage } from '@graneet/lib-ui';

import { usePrevious } from './usePrevious';

const removeEmpty = <T extends unknown>(obj: T | T[keyof T]) =>
  Object.fromEntries(Object.entries(obj as object).filter(([_, v]) => v != null));

const isObject = (item: unknown) => item && typeof item === 'object' && !Array.isArray(item);

const mergeDeep = (target: Record<string, any>, source: Record<string, any>) => {
  const output = { ...target };
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};

interface PersistedReducerOptions<State> {
  subKey?: keyof State;
}

export const usePersistedReducer = <State, Action>(
  reducer: (state: State, action: Action) => State,
  initialState: State,
  storageKey: string,
  urlPath: string,
  options: PersistedReducerOptions<State>,
) => {
  const history = useHistory();
  const location = useLocation();
  const [initialRoute, saveInitialRoute] = useLocalStorage('initialRoute', '');

  const init = (): State => {
    try {
      let search;
      if (initialRoute !== '' && initialRoute.startsWith(urlPath)) {
        search = initialRoute.replace(`${urlPath}?`, '');
      } else {
        search = location.search.replace('?', '');
      }
      const parsedState = qs.parse(search);
      if (!parsedState) {
        return initialState;
      }
      if (!options.subKey) {
        return mergeDeep(initialState as Record<string, any>, parsedState) as State;
      }

      const state = parsedState[options.subKey.toString()];

      if (typeof state === 'string' || Array.isArray(state)) {
        return {
          ...initialState,
          [options.subKey]: {
            ...initialState[options.subKey],
            state,
          },
        };
      }

      if (typeof state === 'object' && !Array.isArray(state)) {
        return {
          ...initialState,
          [options.subKey]: {
            ...initialState[options.subKey],
            ...state,
          },
        };
      }

      return initialState;
    } catch (error) {
      return initialState;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState, init);
  const prevState = usePrevious(state);

  useEffect(() => {
    const stateEqual = deepEqual(prevState, state);
    if (!stateEqual) {
      const search = qs.stringify(removeEmpty<State>(options.subKey ? state[options.subKey] : state));
      history.replace({
        pathname: location.pathname,
        search,
      });
    }
    return () => saveInitialRoute('');
  }, [state, prevState, storageKey, history, location.pathname, options.subKey, saveInitialRoute]);

  return { state, dispatch };
};
