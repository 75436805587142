import type { IBankingConnection } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Callout, GraneetButton } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { hasBankingConnectionSynchronisationIssue } from '../services/banking-connection.util';

interface BankingConnectionSynchronisationIssueCalloutProps {
  bankingConnections: Pick<IBankingConnection, 'syncError'>[];
}
export const BankingConnectionSynchronisationIssueCallout: FC<BankingConnectionSynchronisationIssueCalloutProps> = ({
  bankingConnections,
}) => {
  const { t } = useTranslation(['banking']);

  const history = useHistory();

  const displayCallout = hasBankingConnectionSynchronisationIssue(bankingConnections);

  const onSeeBanks = useCallback(() => {
    history.push('/settings/company/third-parties');
  }, [history]);

  if (!displayCallout) {
    return null;
  }

  return (
    <Callout colorScheme="orange" icon={<i className="ri-error-warning-fill" />}>
      <Flex justifyContent="space-between">
        {t('banking:alerts.syncIssue')}

        <GraneetButton onClick={onSeeBanks}>{t('banking:actions.seeBanks')}</GraneetButton>
      </Flex>
    </Callout>
  );
};
