import dayjs from 'dayjs';

import type { ICompany } from '../company/company.type';
import { divideFloating, multiplyFloating, roundFloating, sumObjects } from '../common/math.util';
import type { ISubProject } from '../sub-project/sub-project.type';

import type { IProject } from './project.type';
import { PROJECT_STATUSES } from './project.type';
import type { IProjectFinancialSummary } from './project-financial-summary.type';

/**
 * Montant total reçu TTC de tous les paiements avec l'avance forfaitaire
 * Aussi bien des paiements liés à des situations qu'à des factures simples
 */
export const getProjectTotalAmountReceivedIncVAT = (project: IProject | IProjectFinancialSummary): number =>
  project.amountReceivedIncVAT + project.amountInvoicedReceivedIncVAT;

/**
 * Montant total facturé HT d'un chantier
 * Cela prend en compte aussi bien les montants des situations acceptées que les factures simples associées au chantier
 * Rappel : project.amountExVAT - project.amountToBeBilledExVAT = project.lastProgressStatement.cumulativeProgressAmountExVAT
 */
export const getProjectTotalBilledAmountExVAT = (project: IProject): number =>
  project.amountExVAT - project.amountToBeBilledExVAT + project.amountInvoicedExVAT;

/**
 * Vérifie l'état le status du projet pour connaitre s'il est clos
 */
export const isProjectFinished = (project: IProject): boolean =>
  [PROJECT_STATUSES.ENDED, PROJECT_STATUSES.ARCHIVED].includes(project.status);

/**
 * Vérifie l'état le status du projet pour connaitre s'il est en cours
 */
// @[ff: project-financial-summary]
export const isProjectActive = (project: IProject | IProjectFinancialSummary): boolean =>
  [PROJECT_STATUSES.IMPORTING, PROJECT_STATUSES.ON_GOING].includes(project.status);

export const isProjectClosed = (project: IProject): boolean => project.status === PROJECT_STATUSES.ENDED;

export const computeProjectAmountsFromSubProjects = (
  subProjects: ISubProject[],
): Pick<IProject, 'amountExVAT' | 'amountIncVAT' | 'amountToBeBilledExVAT'> => {
  const amountExVAT = sumObjects(subProjects, 'amountExVAT');
  const amountIncVAT = sumObjects(subProjects, 'amountIncVAT');
  const amountToBeBilledExVAT = sumObjects(subProjects, 'amountToBeBilledExVAT');

  return {
    amountExVAT,
    amountIncVAT,
    amountToBeBilledExVAT,
  };
};

export const computeProjectAmountToBeBilledExVAT = (
  subProjects: Pick<ISubProject, 'amountToBeBilledExVAT'>[],
): number => sumObjects(subProjects, 'amountToBeBilledExVAT');

export const computeProjectCumulativeProgressPercentage = (
  project: Pick<IProject, 'amountExVAT'>,
  subProjects: Pick<ISubProject, 'amountToBeBilledExVAT'>[],
): number => {
  const projectAmountToBeBilledExVAT = computeProjectAmountToBeBilledExVAT(subProjects);

  if (project.amountExVAT === 0) {
    return 0;
  }

  return roundFloating(
    multiplyFloating(divideFloating(project.amountExVAT - projectAmountToBeBilledExVAT, project.amountExVAT), 100),
  );
};

export const computeProjectVATDistribution = (
  subProjects: Pick<ISubProject, 'contractsVATDistribution' | 'hasReversalOfLiability'>[],
): Array<{
  amount: number;
  vatRate: number;
}> => {
  const vatDistributionMap = new Map<number, { amount: number; vatRate: number }>();
  subProjects.forEach((subProject) => {
    (subProject.contractsVATDistribution || []).forEach((distribution) => {
      const oldAmount = vatDistributionMap.get(distribution.vatRate)?.amount || 0;

      vatDistributionMap.set(distribution.vatRate, {
        amount: oldAmount + distribution.amount,
        vatRate: distribution.vatRate,
      });
    });
  });
  return [...vatDistributionMap.values()];
};

export const canProjectBeImported = (
  company: Pick<ICompany, 'limitDateProjectImportCredit' | 'consumedProjectImportCredit' | 'limitProjectImportCredit'>,
): { status: 'ko'; error: 'ENDED_PERIOD' | 'QUOTA_REACHED' } | { status: 'ok' } => {
  if (dayjs(new Date()).isAfter(dayjs(company.limitDateProjectImportCredit))) {
    return {
      status: 'ko',
      error: 'ENDED_PERIOD',
    };
  }

  if (company.consumedProjectImportCredit >= company.limitProjectImportCredit) {
    return {
      status: 'ko',
      error: 'QUOTA_REACHED',
    };
  }

  return {
    status: 'ok',
  };
};
