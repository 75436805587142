import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { InputProps, LeafWithRelations } from '@graneet/lib-ui';
import {
  CurrencyField,
  DeepTable,
  formatVatRateToNumber,
  INPUT_IN_DEEP_TABLE_STYLE,
  NumberField,
  PercentageField,
  Price,
  TextField,
  useCurrency,
  DraggableItem,
  SegmentedDropZone,
  DropEffect,
  RichTextField,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
} from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { FEATURE_FLAGS, type IItemWithUUID, type ILotWithUUID } from '@graneet/business-logic';
import { isNil } from 'lodash-es';

import type { InvoiceEditionForm, ItemKeys } from '../../forms/invoice-edition-form';
import { getItemFieldName } from '../../forms/invoice-edition-form';
import { useInvoiceItem, useInvoiceItemComputedValue, useInvoiceTreeContext } from '../../hooks/useInvoiceTree';

import { InvoiceItemMenu } from './InvoiceItemMenu';

import { Rule } from 'features/form/rules/Rule';
import { CONTRACT_ENTITY_TYPES } from 'features/contract/constants/contracts.constant';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface TotalPriceProps {
  id: string | number;
}
const TotalPrice: FC<TotalPriceProps> = ({ id }) => {
  const { mapNumberToAmount } = useCurrency();
  const computedValue = useInvoiceItemComputedValue(id);

  return <Price amount={mapNumberToAmount(computedValue?.totalExVAT || 0)} />;
};

interface VatRateProps {
  id: string | number;

  onFocus(): void;

  onBlur(): void;

  inputProps: InputProps;
}
const VatRate: FC<VatRateProps> = ({ id, onBlur, onFocus, inputProps }) => {
  const form = useFormContext<InvoiceEditionForm>();
  const { hasReversalOfLiability } = useOnChangeValues(form, ['hasReversalOfLiability']);

  // @[ff: accounting-standard-vat-rates]
  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  if (hasReversalOfLiability) {
    return <>-</>;
  }

  return hasStandardVATRates ? (
    <AccountingVATField<InvoiceEditionForm>
      name={getItemFieldName(id, 'vatRate')}
      onFocus={onFocus}
      onBlur={onBlur}
      valueScale={100}
    >
      <Rule.IsRequired />
    </AccountingVATField>
  ) : (
    <PercentageField<InvoiceEditionForm>
      name={getItemFieldName(id, 'vatRate')}
      onFocus={onFocus}
      onBlur={onBlur}
      noBorder
      inputProps={inputProps}
      min={0}
      hideErrorMessage
    >
      <Rule.IsRequired />
    </PercentageField>
  );
};

interface InvoiceDeepTableItemLineProps {
  id: string | number;
  depth: number;
}
export const InvoiceDeepTableItemLine: FC<InvoiceDeepTableItemLineProps> = ({ id }) => {
  const { mapAmountToNumber } = useCurrency();

  const { updateLeafData, isLeafAfterLeaf, moveLeaf } = useInvoiceTreeContext();
  const form = useFormContext<InvoiceEditionForm>();

  const [hasInputFocused, setHasInputFocused] = useState(false);

  const { leaf: item } = useInvoiceItem(id);
  const { isComplete: isCompleteWithoutVatRate } = useInvoiceItemComputedValue(id) || { isComplete: false };

  const { hasReversalOfLiability } = useOnChangeValues(form, ['hasReversalOfLiability']);
  const hasVatRate = hasReversalOfLiability ? true : !isNil(item.vatRate);
  const isComplete = isCompleteWithoutVatRate && hasVatRate;

  const onFocus = useCallback(() => {
    setHasInputFocused(true);
  }, []);

  const onBlur = useCallback(
    (fieldName: ItemKeys) => () => {
      const formField = getItemFieldName(id, fieldName);
      let value = form.getFormValues()[formField];
      if (fieldName === 'vatRate') {
        value = value ? formatVatRateToNumber(value as number) : value;
      }
      if (fieldName === 'unitPrice') {
        value = value ? mapAmountToNumber(value as number) : value;
      }

      updateLeafData(id, {
        [fieldName]: value,
      });

      setHasInputFocused(false);
    },
    [form, id, mapAmountToNumber, updateLeafData],
  );

  const dndItem = useMemo(() => ({ id, parentNodeId: item?.parentNodeId }), [id, item?.parentNodeId]);

  const canDropOnItemTop = useCallback(
    (itemDrag: LeafWithRelations<ILotWithUUID, IItemWithUUID>) => !isLeafAfterLeaf(itemDrag, item),
    [isLeafAfterLeaf, item],
  );

  const canDropOnItemBottom = useCallback(
    (itemDrag: LeafWithRelations<ILotWithUUID, IItemWithUUID>) => !isLeafAfterLeaf(item, itemDrag),
    [isLeafAfterLeaf, item],
  );

  const onItemDropBefore = useCallback(
    (newPreviousItem: LeafWithRelations<ILotWithUUID, IItemWithUUID>) => {
      moveLeaf(newPreviousItem.id, item.parentNodeId, item.id, 'before');
    },
    [item?.id, item?.parentNodeId, moveLeaf],
  );

  const onItemDropAfter = useCallback(
    (newNextItem: LeafWithRelations<ILotWithUUID, IItemWithUUID>) => {
      moveLeaf(newNextItem.id, item.parentNodeId, item.id, 'after');
    },
    [item?.id, item?.parentNodeId, moveLeaf],
  );

  return (
    <DraggableItem type={CONTRACT_ENTITY_TYPES.ITEM} item={dndItem}>
      <SegmentedDropZone id={id} accept={CONTRACT_ENTITY_TYPES.ITEM}>
        <SegmentedDropZone.Segment
          weight={1}
          effect={DropEffect.CursorTop}
          canDrop={canDropOnItemTop}
          onDrop={onItemDropBefore}
        />

        <SegmentedDropZone.Segment
          weight={1}
          effect={DropEffect.CursorBottom}
          canDrop={canDropOnItemBottom}
          onDrop={onItemDropAfter}
        />

        <DeepTable.Row
          bg={isComplete ? 'white' : 'yellow.100'}
          leftContent={<DraggableItem.Handle color="gray.400" _hover={{ color: 'gray.500' }} />}
        >
          <DeepTable.Cell />

          <DeepTable.Cell>
            <TextField<InvoiceEditionForm>
              name={getItemFieldName(id, 'code')}
              onFocus={onFocus}
              onBlur={onBlur('code')}
              inputProps={INPUT_IN_DEEP_TABLE_STYLE(hasInputFocused)}
              width="60%"
              hideErrorMessage
              noBorder
              data-testid="textField:invoice.row.code"
            />
          </DeepTable.Cell>

          <DeepTable.Cell>
            <RichTextField<InvoiceEditionForm>
              name={getItemFieldName(id, 'description')}
              navbarType="inline"
              onFocus={onFocus}
              onBlur={onBlur('description')}
              inputProps={INPUT_IN_DEEP_TABLE_STYLE(hasInputFocused)}
              hideErrorMessage
              data-testid="textField:invoice.row.name"
              configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
            >
              <Rule.IsRequired />
            </RichTextField>
          </DeepTable.Cell>

          <DeepTable.Cell>
            <TextField<InvoiceEditionForm>
              name={getItemFieldName(id, 'unit')}
              onFocus={onFocus}
              onBlur={onBlur('unit')}
              inputProps={INPUT_IN_DEEP_TABLE_STYLE(hasInputFocused)}
              hideErrorMessage
              noBorder
              data-testid="textField:invoice.row.unit"
            >
              <Rule.IsRequired />
            </TextField>
          </DeepTable.Cell>

          <DeepTable.Cell>
            <NumberField<InvoiceEditionForm>
              name={getItemFieldName(id, 'quantity')}
              onFocus={onFocus}
              onBlur={onBlur('quantity')}
              inputProps={INPUT_IN_DEEP_TABLE_STYLE(hasInputFocused)}
              hideErrorMessage
              noBorder
              data-testid="textField:invoice.row.quantity"
            >
              <Rule.IsRequired />
            </NumberField>
          </DeepTable.Cell>

          <DeepTable.Cell>
            <CurrencyField<InvoiceEditionForm>
              name={getItemFieldName(id, 'unitPrice')}
              onFocus={onFocus}
              onBlur={onBlur('unitPrice')}
              inputProps={INPUT_IN_DEEP_TABLE_STYLE(hasInputFocused)}
              hideErrorMessage
              noBorder
              data-testid="textField:invoice.row.unitPrice"
            >
              <Rule.IsRequired />
            </CurrencyField>
          </DeepTable.Cell>

          <DeepTable.Cell>
            <VatRate
              id={id}
              onFocus={onFocus}
              onBlur={onBlur('vatRate')}
              inputProps={INPUT_IN_DEEP_TABLE_STYLE(hasInputFocused)}
            />
          </DeepTable.Cell>

          <DeepTable.Cell fontSize="md">
            <TotalPrice id={id} />
          </DeepTable.Cell>

          <DeepTable.Cell fontSize="md">
            <InvoiceItemMenu itemId={id} />
          </DeepTable.Cell>
        </DeepTable.Row>
      </SegmentedDropZone>
    </DraggableItem>
  );
};
