export enum QUOTE_ROW_HEIGHT {
  'Medium' = 'md',
  'Small' = 'sm',
  'Large' = 'lg',
}

export interface IUserSettings {
  id: string;

  mailingDefaultSenderFullName: string;

  mailingSenderReplyTo: string;

  /**
   * Mail signature in Lexical JSON format, stored as a string
   */
  mailingEmailSignatureJSON: string | null;

  mailingReceiveCopy: boolean;

  /**
   * Quote summary bar item settings in Lexical JSON format, stored as a string
   */
  quoteSummaryBarItemDisplayedJSON: string | null;

  /**
   * Quote root columns settings in Lexical JSON format, stored as a string
   */
  quoteRootColumnsJSON: string | null;

  /**
   * Quote hidden cost columns settings in Lexical JSON format, stored as a string
   */
  quoteHiddenCostColumnsJSON: string | null;

  /**
   * Tables setting columns in Lexical JSON format, stored as a string
   */
  tablesSettingColumnsJSON: string | null;

  /**
   * Tables setting quote row height
   */
  quoteRowHeight: QUOTE_ROW_HEIGHT;
}
