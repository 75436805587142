import { SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import type { DocumentStatusCardIconValue } from '@graneet/lib-ui';

import type { CashOutFlowActionsType } from './cash-out-flow.actions.type';
import { getCashOutToPayActions } from './cash-out-flow-to-pay.actions';
import { getCashOutFlowPartiallyPaidActions } from './cash-out-flow-partially-paid.actions';

export function getCashOutFlowActions(params: CashOutFlowActionsType) {
  const { supplierInvoice } = params;

  switch (supplierInvoice.status) {
    case SUPPLIER_INVOICE_STATUS.TO_PAY:
      return getCashOutToPayActions(params);
    case SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID:
      return getCashOutFlowPartiallyPaidActions(params);
    case SUPPLIER_INVOICE_STATUS.TO_PROCESS:
    case SUPPLIER_INVOICE_STATUS.DIRECT_PAYMENT:
    case SUPPLIER_INVOICE_STATUS.PAID:
    case SUPPLIER_INVOICE_STATUS.TO_VALIDATE:
      return [];

    default:
      supplierInvoice.status satisfies never;
      throw new Error('Unknown status');
  }
}
export function getCashOutFlowStatusCardIcon(status: SUPPLIER_INVOICE_STATUS): DocumentStatusCardIconValue {
  switch (status) {
    case SUPPLIER_INVOICE_STATUS.TO_PROCESS:
      return 'draft';
    case SUPPLIER_INVOICE_STATUS.TO_PAY:
      return 'finalized';
    case SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID:
      return 'partial';
    case SUPPLIER_INVOICE_STATUS.TO_VALIDATE:
      return 'onHold';
    case SUPPLIER_INVOICE_STATUS.DIRECT_PAYMENT:
    case SUPPLIER_INVOICE_STATUS.PAID:
      return 'completed';

    default:
      status satisfies never;
      throw new Error('Unknown status');
  }
}
