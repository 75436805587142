import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import type { TooltipProps } from 'recharts';
import type { Payload } from 'recharts/types/component/DefaultTooltipContent';
import type { ReactNode } from 'react';
import { Fragment } from 'react';

import { EllipsisText } from '../../../../EllipsisText';
import { useCurrency } from '../../../../Currency';
import { capitalizeString, isNumberFinite } from '../../../../../utils';

type ExtendedPayload<TValue extends string | number, TName extends number | string> = Payload<TValue, TName> & {
  textColor?: string;
  formatValue?: (value: TValue | undefined) => ReactNode;
};

type CustomTooltipProps<T extends object> = Omit<TooltipProps<any, any>, 'payload'> & {
  legends: Partial<Record<keyof T, ReactNode>>;
  payload?: ExtendedPayload<any, any>[];
  hasBullets?: boolean;
};

export const CustomTooltip = <T extends object>({
  active,
  payload,
  label,
  legends,
  hasBullets = true,
}: CustomTooltipProps<T>) => {
  const { formatAsAmount } = useCurrency();
  if (active && payload && payload.length) {
    return (
      <Flex direction="column" gap={4} minW="10rem">
        {label && <Text>{capitalizeString(label)}</Text>}

        <Grid templateColumns="repeat(2, 1fr)" fontSize="smaller" gap={1} w="100%">
          {payload.map((data) => {
            const { color, name, value, textColor, formatValue } = data;
            let formattedValue: ReactNode = value;
            if (formatValue) {
              formattedValue = formatValue(value);
            } else if (isNumberFinite(value)) {
              formattedValue = formatAsAmount(value || 0);
            }

            if (!legends[name as keyof T]) {
              return null;
            }

            return (
              <Fragment key={name}>
                <GridItem>
                  <Flex>
                    {hasBullets && (
                      <Box boxSize="14px" mr={2}>
                        {color && (
                          <svg viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
                            <circle cx="7" cy="7" r="7" fill={color} />
                          </svg>
                        )}
                      </Box>
                    )}
                    <Flex justifyContent="space-between" flex={1}>
                      {name ? (
                        <EllipsisText maxWidth="15rem" fontSize="xs" color={textColor ?? 'gray.900'}>
                          {legends[name as keyof T]}
                        </EllipsisText>
                      ) : null}
                    </Flex>
                  </Flex>
                </GridItem>

                <GridItem textAlign="end">
                  <Text fontSize="sm" color={textColor ?? 'gray.900'}>
                    {formattedValue}
                  </Text>
                </GridItem>
              </Fragment>
            );
          })}
        </Grid>
      </Flex>
    );
  }

  return null;
};
