import { useTranslation } from 'react-i18next';
import { Card, LabeledData, Date, Price, TwoColumns, GotoLink } from '@graneet/lib-ui';
import type { IInvoice } from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';
import type { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { BillStatusBadge } from 'features/bill/components/BillStatusBadge';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { InvoiceNumberLabeledData } from 'features/statement-ledger/components/InvoiceNumberLabeledData';

interface InvoiceInformationCardProps {
  invoice: IInvoice;
}

export const InvoiceInformationCard: FC<InvoiceInformationCardProps> = ({ invoice }) => {
  const { t } = useTranslation(['invoice', 'statement']);
  const { getTransitionPath } = useFiltersQuery();

  return (
    <Card
      title={
        <Flex>
          {t('invoice:cards.information')}
          {invoice.bill && (
            <GotoLink
              ml={2}
              to={getTransitionPath(`/sales/bills?focusedId=${invoice.bill.id}`).pathname}
              label={t('statement:goTo.bill')}
            />
          )}
        </Flex>
      }
    >
      <TwoColumns>
        <LabeledData label={t('invoice:fields.name')} data={invoice.name} />
        <LabeledData label={t('invoice:fields.purchaseOrderNumber')} data={invoice.purchaseOrderNumber} />
        <LabeledData label={t('invoice:fields.billingDate')} data={<Date>{invoice.billingDate}</Date>} />
        <LabeledData label={t('invoice:fields.completionDate')} data={<Date>{invoice.completionDate}</Date>} />
        <LabeledData label={t('invoice:fields.amountExVAT')} data={<Price amount={invoice.amountExVAT} />} />
        <InvoiceNumberLabeledData statement={invoice} statementType={STATEMENT_TYPES.INVOICE} />
        <LabeledData
          label={t('invoice:fields.billStatus')}
          data={invoice.bill && <BillStatusBadge status={invoice.bill.status} />}
        />
      </TwoColumns>
    </Card>
  );
};
