import type { FC } from 'react';
import type { TextProps } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';

export const Label: FC<TextProps> = ({ children, ...props }) => (
  <Text
    color="gray.700"
    fontWeight="400"
    _checked={{
      fontWeight: '600',
    }}
    _disabled={{
      color: 'gray.500',
    }}
    {...props}
  >
    {children}
  </Text>
);
