import { getAmountIncVAT } from '../vat/vat-rates.util';
import { multiplyFloating, roundFloating } from '../common/math.util';

import type { IItem } from './item.type';

export const computeTotalExVAT = (item: Partial<Pick<IItem, 'quantity' | 'unitPrice'>>): number =>
  roundFloating(multiplyFloating(item.quantity || 0, item.unitPrice || 0));

export const computeTotalIncVAT = (item: Pick<IItem, 'quantity' | 'unitPrice' | 'vatRate'>): number =>
  getAmountIncVAT(computeTotalExVAT(item), item.vatRate);
