import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { Card } from '@graneet/lib-ui';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { usePermissions } from 'features/role/hooks/usePermissions';
import {
  useSupplierInvoiceAvailableTags,
  useSupplierInvoiceUpdateTags,
} from 'features/supplier-invoice/services/supplier-invoice.api';

interface SupplierInvoiceTagsCardProps {
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}

export const SupplierInvoiceTagsCard: FC<SupplierInvoiceTagsCardProps> = ({ supplierInvoice }) => {
  const { t } = useTranslation(['global']);
  const hasPermission = usePermissions([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  const tags = useSupplierInvoiceAvailableTags();

  const supplierInvoiceUpdateTagsMutation = useSupplierInvoiceUpdateTags();

  const onTagsSaved = useCallback(
    (newTags: string[]) =>
      supplierInvoiceUpdateTagsMutation.mutateAsync({ id: supplierInvoice.id, dto: { tags: newTags } }),
    [supplierInvoice.id, supplierInvoiceUpdateTagsMutation],
  );

  if (!supplierInvoice.tags && !hasPermission) {
    return null;
  }

  return (
    <Card title={t('global:words.privateTags')}>
      <TagsEditionBlock
        placeholder={t('global:changeTags.placeholder')}
        tags={supplierInvoice.tags}
        suggestedTags={tags.data}
        onTagsEdited={onTagsSaved}
        isEditable={hasPermission}
      />
    </Card>
  );
};
