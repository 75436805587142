import type { FC, ReactElement, ReactNode } from 'react';
import { cloneElement } from 'react';
import { Box, type IconProps, type TooltipProps } from '@chakra-ui/react';

import { Tooltip } from '../Tooltip';

export interface IconAdvancedProps extends Omit<TooltipProps, 'children'> {
  icon: ReactElement<IconProps>;
  tooltip?: ReactNode;
}

export const IconAdvanced: FC<IconAdvancedProps> = ({ icon, tooltip, ...props }) => {
  const margins = {
    marginTop: 'auto',
    marginBottom: 'auto',
  };
  const IconWithProps = cloneElement(icon, {
    boxSize: 5,
    color: 'gray.300',
    ...icon.props,
    ...margins,
  });

  if (tooltip) {
    // We need zIndex to display tooltip on modal: https://github.com/chakra-ui/chakra-ui/issues/1604
    return (
      <Tooltip label={tooltip} placement="right-end" zIndex={1401} {...props}>
        <Box alignItems="center" display="flex">
          {IconWithProps}
        </Box>
      </Tooltip>
    );
  }

  return IconWithProps;
};
