import type { FC } from 'react';
import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingLayout, Button, generateColorFromString } from '@graneet/lib-ui';
import { useDisclosure } from '@chakra-ui/react';
import type { SUPPLIER_TYPE } from '@graneet/business-logic';

import { useSupplierImport, useSuppliers, useSuppliersTags } from 'features/supplier/services/supplier.api';
import { SuppliersTable } from 'features/supplier/components/SupplierTable';
import { SupplierContactCreateStepperModal } from 'features/supplier/components/modals/SupplierContactCreateStepperModal/SupplierContactCreateStepperModal';
import { TEMPLATE_URL } from 'features/import/constants/import.constant';
import type { ImportSupplierField } from 'features/supplier/hooks/useImportSupplierFields';
import { useImportSupplierFields } from 'features/supplier/hooks/useImportSupplierFields';
import type { ImportSpreadsheetSubmit } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImport } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImportMenu } from 'features/import/components/SpreadsheetImportMenu';
import { SpreadsheetImportingModal } from 'features/import/components/SpreadsheetImportingModal';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewSuppliersScreen: FC = () => {
  const { t } = useTranslation(['supplier', 'global']);
  const { updateHeaderTitle } = useHeaderContext();

  const createSupplierModal = useDisclosure();
  const importingModal = useDisclosure();
  const spreadsheetImportModal = useDisclosure();

  const importFields = useImportSupplierFields();

  const suppliers = useSuppliers();
  const suppliersTags = useSuppliersTags();

  const supplierImportMutation = useSupplierImport();

  const onSubmitImport = useCallback<ImportSpreadsheetSubmit<ImportSupplierField>>(
    async (importData) => {
      importingModal.onOpen();

      const dto = importData.validData.map((row) => ({
        type: row.type!.toString() as SUPPLIER_TYPE,
        code: row.code?.toString(),
        name: row.name!.toString(),
        address: {
          address: row.address!.toString(),
          city: row.city?.toString(),
          postalCode: row.postalCode?.toString(),
          country: row.country?.toString(),
        },
        hasBillingAddress: false,
        billingAddress: undefined,
        auxiliaryAccount: row.auxiliaryAccount?.toString(),
      }));

      await supplierImportMutation.mutateAsync(
        { suppliers: dto },
        {
          onSuccess: () => {
            suppliers.refetch();
            importingModal.onClose();
          },
        },
      );
    },
    [importingModal, supplierImportMutation, suppliers],
  );

  const availableTags = useMemo(
    () =>
      suppliersTags.data.map<{ value: string; label: string }>((tag) => ({
        value: tag,
        label: tag,
        badgeColor: generateColorFromString(tag),
      })),
    [suppliersTags.data],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.suppliers'), []);
  }, [t, updateHeaderTitle]);

  return (
    <>
      <ListingLayout
        pagination={suppliers}
        search={{
          placeholder: t('supplier:search'),
        }}
        filters={[
          {
            type: 'multi',
            name: 'tags',
            availableValues: availableTags,
            label: t('global:words.tags'),
            noValueMessage: t('global:tags.noLabel'),
            placeholder: t('global:words.c.select'),
          },
        ]}
        actions={
          <>
            <Button onClick={createSupplierModal.onOpen} colorScheme="blue">
              {t('supplier:createSupplier')}
            </Button>
            <SpreadsheetImportMenu
              label={t('supplier:importSuppliers')}
              templateUrl={TEMPLATE_URL.SUPPLIERS}
              onOpen={spreadsheetImportModal.onOpen}
            />
          </>
        }
        content={<SuppliersTable suppliers={suppliers} />}
      />

      {spreadsheetImportModal.isOpen && (
        <SpreadsheetImport onClose={spreadsheetImportModal.onClose} onSubmit={onSubmitImport} fields={importFields} />
      )}

      <SpreadsheetImportingModal {...importingModal} />

      <SupplierContactCreateStepperModal
        isOpen={createSupplierModal.isOpen}
        onClose={createSupplierModal.onClose}
        onSuccess={suppliers.refetch}
      />
    </>
  );
};
