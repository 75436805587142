import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Text, Stack, Box } from '@chakra-ui/react';
import { CurrencyField, DateField, formatDateToString, Modal, SingleSelectField, useCurrency } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { FEATURE_FLAGS, type ISupplierInvoice, type ISupplierInvoicePayment } from '@graneet/business-logic';

import type { SupplierPaymentFormValues } from '../../forms/supplier-invoice.form';
import { useSupplierInvoiceAddPayment, useSupplierInvoiceUpdatePayment } from '../../services/supplier-invoice.api';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { Rule } from 'features/form/rules/Rule';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useAccountingConfigs } from 'features/accounting/services/accounting-config.api';

interface SupplierInvoiceEditModalProps extends Pick<UseDisclosureReturn, 'isOpen' | 'onClose'> {
  /**
   * If a payment is specified, modal is in edition mode, otherwise, it's in creation mode
   */
  supplierInvoicePayment?: ISupplierInvoicePayment;

  supplierInvoice: ISupplierInvoice;

  onSubmit?: () => void;
}

const creationTranslation = {
  title: 'supplierInvoices:addPaymentModal.creationTitle',
  description: 'supplierInvoices:addPaymentModal.description',
  buttonLabel: 'supplierInvoices:actions.validatePayment',
} as const;
const editionTranslation = {
  title: 'supplierInvoices:addPaymentModal.editionTitle',
  description: 'supplierInvoices:addPaymentModal.description',
  buttonLabel: 'global:saveUpdate',
} as const;

const SupplierInvoicePaymentsEditModalInternal: FC<SupplierInvoiceEditModalProps> = ({
  onClose,
  supplierInvoicePayment,
  supplierInvoice,
  onSubmit,
}) => {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const translations = supplierInvoicePayment ? editionTranslation : creationTranslation;

  const { mapAmountToNumber, formatAsAmount, mapNumberToAmount } = useCurrency();

  const accountingConfigQuery = useAccountingConfigs();

  const supplierInvoiceAddPaymentMutation = useSupplierInvoiceAddPayment();
  const supplierInvoiceUpdatePaymentMutation = useSupplierInvoiceUpdatePayment();

  const form = useForm<SupplierPaymentFormValues>({
    defaultValues: {
      accountingConfigBankAccountId:
        supplierInvoicePayment && supplierInvoicePayment.accountingConfigBankAccount?.id
          ? supplierInvoicePayment.accountingConfigBankAccount?.id
          : accountingConfigQuery?.data?.defaultAccountingConfigBankAccount?.id,
      paidAt: formatDateToString(supplierInvoicePayment ? supplierInvoicePayment.paidAt : new Date()),
      amountPaidIncVAT: mapNumberToAmount(
        supplierInvoicePayment ? supplierInvoicePayment.amountPaidIncVAT : supplierInvoice!.remainingToBePaidIncVAT,
      ),
    },
  });

  const { isValid: isFormValid } = useFormStatus(form);

  // @[ff: accounting-payment-methods]
  const hasNewAccountingPaymentMethods = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_PAYMENT_METHODS);

  const bankAccounts = useMemo(
    () =>
      (accountingConfigQuery.data?.accountingConfigBanks ?? [])
        .map((bank) => bank.accountingConfigBankAccounts ?? [])
        .flat(),
    [accountingConfigQuery.data?.accountingConfigBanks],
  );

  /**
   * if `supplierInvoicePayment` is specified, update the existing payment,
   * otherwise it creates a new payment for the supplier invoice
   */
  const onPaymentCreated = useCallback(async () => {
    const { paidAt, amountPaidIncVAT, accountingConfigBankAccountId } = form.getFormValues();

    if (supplierInvoicePayment) {
      await supplierInvoiceUpdatePaymentMutation.mutateAsync({
        id: supplierInvoicePayment.id,
        dto: {
          paidAt: paidAt!,
          amountPaidIncVAT: mapAmountToNumber(amountPaidIncVAT!),
        },
      });
    } else {
      await supplierInvoiceAddPaymentMutation.mutateAsync({
        supplierInvoiceId: supplierInvoice!.id,
        dto: {
          paidAt: paidAt!,
          amountPaidIncVAT: mapAmountToNumber(amountPaidIncVAT!),
          accountingConfigBankAccountId,
        },
      });
    }
    onSubmit?.();
    onClose();
  }, [
    form,
    supplierInvoicePayment,
    onSubmit,
    onClose,
    supplierInvoiceUpdatePaymentMutation,
    mapAmountToNumber,
    supplierInvoiceAddPaymentMutation,
    supplierInvoice,
  ]);

  const hasMutationPending =
    supplierInvoiceUpdatePaymentMutation.isPending || supplierInvoiceAddPaymentMutation.isPending;

  return (
    <>
      <Stack spacing={6}>
        <Box>
          <Trans
            t={t}
            i18nKey={translations.description}
            values={{ amount: formatAsAmount(supplierInvoice.amountIncVAT) }}
          >
            description_start
            <Text as="span" fontWeight={600}>
              displayed_data
            </Text>
            description_end
          </Trans>
        </Box>

        <Form form={form}>
          <Stack w="100%" spacing={2} justifyContent="space-between" direction="row">
            <CurrencyField<SupplierPaymentFormValues>
              name="amountPaidIncVAT"
              label={t('supplierInvoices:paymentFields.amountPaidIncVAT')}
              isRequired
            >
              <Rule.IsRequired />
            </CurrencyField>

            <DateField<SupplierPaymentFormValues>
              name="paidAt"
              label={t('supplierInvoices:paymentFields.paidAt')}
              isRequired
            >
              <Rule.IsRequired />
            </DateField>
          </Stack>
          {hasNewAccountingPaymentMethods && (
            <Box mt={2}>
              <SingleSelectField<SupplierPaymentFormValues>
                label={t('supplierInvoices:fields.accountingConfigPurchasesAccount')}
                name="accountingConfigBankAccountId"
                isRequired
                options={bankAccounts.map((acc) => ({
                  label: [acc.label, acc.account].filter(Boolean).join(' - '),
                  value: acc.id,
                }))}
              >
                <Rule.IsRequired />
              </SingleSelectField>
            </Box>
          )}
        </Form>
      </Stack>

      <Modal.Close isDisabled={hasMutationPending} />
      <Modal.PrimaryButton onClick={onPaymentCreated} isLoading={hasMutationPending} isDisabled={!isFormValid}>
        {t(translations.buttonLabel)}
      </Modal.PrimaryButton>
    </>
  );
};

export const SupplierInvoicePaymentsEditModal: FC<SupplierInvoiceEditModalProps> = ({
  isOpen,
  onClose,
  supplierInvoicePayment,
  ...otherProps
}) => {
  const { t } = useTranslation(['global', 'supplierInvoices']);

  const translations = supplierInvoicePayment ? editionTranslation : creationTranslation;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t(translations.title)}>
      <QueryWrapper>
        <SupplierInvoicePaymentsEditModalInternal
          isOpen={isOpen}
          onClose={onClose}
          supplierInvoicePayment={supplierInvoicePayment}
          {...otherProps}
        />
      </QueryWrapper>
    </Modal>
  );
};
