import type { BoxProps } from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/react';
import { useMemo } from 'react';

import { DropEffect } from './constants';
import type { SegmentProps } from './contexts/SegmentContext';

/**
 * @param cursor The relative vertical position of the mouse within
 *   a drop zone
 * @param segments The segments of the drop zone
 * @returns The segment matching the position of the mouse
 */
export const findSegment = <Item extends Record<any, unknown>>(
  cursor: number,
  segments: Array<SegmentProps<Item>>,
): SegmentProps<Item> | undefined => {
  const count = segments.length;
  if (count === 1) {
    return segments[0];
  }
  const totalWeight = segments.reduce((accWeight, { weight }) => accWeight + weight, 0);
  const weightedCursor = cursor * totalWeight;
  return segments.reduce<{ result: SegmentProps<Item> | undefined; cumulatedWeight: number }>(
    ({ result, cumulatedWeight }, segment) => {
      if (result) {
        return { result, cumulatedWeight };
      }
      const newCumulatedWeight = cumulatedWeight + segment.weight;
      if (weightedCursor >= cumulatedWeight && weightedCursor <= newCumulatedWeight) {
        return { result: segment, cumulatedWeight };
      }
      return {
        result: undefined,
        cumulatedWeight: newCumulatedWeight,
      };
    },
    { result: undefined, cumulatedWeight: 0 },
  ).result;
};

export interface DropStyles {
  BOX: Record<DropEffect, BoxProps>;
}

export const useDropStyles = (): DropStyles => {
  const { colors } = useTheme();

  return useMemo(
    () => ({
      BOX: {
        [DropEffect.Glow]: {
          position: 'relative',
          zIndex: 10,
          boxShadow: `0 0 8px 2px ${colors.blue[500]}`,
        },

        [DropEffect.None]: {
          boxShadow: undefined,
        },

        [DropEffect.CursorBottom]: {
          boxShadow: undefined,
        },

        [DropEffect.CursorTop]: {},
      },
    }),
    [colors],
  );
};
