import { isNumberFinite } from '../../utils';
import { useCurrency, SIGN_EMPTY_AMOUNT } from '../Currency/CurrencyProvider';

export interface PriceProps {
  amount?: number | null;
  sign?: boolean;
  negative?: boolean;
}

export const Price = ({ amount, sign = false, negative = false }: PriceProps) => {
  const { formatAsAmount } = useCurrency();
  if (!isNumberFinite(amount)) {
    return <>{SIGN_EMPTY_AMOUNT}</>;
  }
  const signedPrice = (negative ? -1 : 1) * amount;
  return <>{formatAsAmount(signedPrice, { forceSign: sign })}</>;
};
