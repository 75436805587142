import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const DollarLoadingIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 20 18" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none">
      <path
        fill="currentColor"
        d="M9.726 12.995v-.953a6.224 6.224 0 0 1-1.129-.119 4.78 4.78 0 0 1-.9-.289v-1.252c.268.13.589.248.962.356.373.104.729.167 1.067.19v-1.47c-.5-.197-.9-.386-1.2-.57-.297-.186-.512-.392-.645-.616-.13-.228-.194-.5-.194-.816 0-.322.085-.6.256-.834a1.82 1.82 0 0 1 .72-.57c.307-.142.661-.23 1.063-.265v-.725h.65v.706a5.4 5.4 0 0 1 1.024.128c.319.07.632.173.939.308l-.446 1.11a5.127 5.127 0 0 0-1.517-.37v1.4c.35.129.684.27 1 .421.316.152.574.345.773.579.2.234.299.54.299.92 0 .47-.173.866-.517 1.185-.342.32-.86.51-1.555.574v.972h-.65Zm.65-2.1c.22-.039.384-.104.488-.195a.471.471 0 0 0 .156-.365.411.411 0 0 0-.07-.237.704.704 0 0 0-.219-.195 2.418 2.418 0 0 0-.355-.19v1.181Zm-.65-2.817V6.963c-.13.02-.24.05-.332.095a.5.5 0 0 0-.209.161.397.397 0 0 0-.07.237c0 .092.02.173.06.242.045.07.111.135.2.195.092.06.209.121.35.185ZM17.437 5.293l-1.758 3.599 3.996-.277-2.238-3.322ZM2.26 12.648l1.758-3.599-3.995.277 2.237 3.322Zm4.025 4.134c2.219.642 5.09.693 7.445-.497 2.385-1.206 4.182-3.654 4.27-7.87l-.694-.014c-.083 3.992-1.761 6.189-3.89 7.264-2.157 1.091-4.837 1.059-6.938.451l-.193.666ZM13.413 1.16c-2.22-.642-5.09-.693-7.445.498-2.385 1.205-4.183 3.654-4.27 7.868l.693.015c.083-3.992 1.762-6.189 3.89-7.264 2.157-1.09 4.837-1.059 6.939-.45l.193-.667Z"
      />
      <path
        stroke="currentColor"
        strokeWidth="1.387"
        d="M6.358 16.451c4.817 1.394 12.35.694 11.144-9.572m-4.209-5.385C8.477.101 1.403 1.18 2.198 11.064"
      />
    </svg>
  </Icon>
);
