import type { FC } from 'react';
import { useCallback } from 'react';
import {
  divideFloating,
  roundFloating,
  multiplyFloating,
  Badge,
  timeTableTranslations,
  EllipsisText,
  ColorPickerField,
} from '@graneet/lib-ui';
import { HStack, VStack, Text } from '@chakra-ui/react';
import type { GRANEET_COLORS } from '@graneet/business-logic';

import { useProjectUpdate } from 'features/project/services/project.api';
import { PROJECT_COLOR_PICKER_COLORS } from 'features/project/constants/project-color.constant';

interface CardProjectProps {
  id: number;
  color?: GRANEET_COLORS | string;
  name: string;
  clientCode?: string | null;
  enterpriseName?: string;
  refCode?: string;
  nbMinutes: number;
  onColorChanged: () => void;
}

export const TimeTrackingCardProject: FC<CardProjectProps> = ({
  id,
  color,
  name,
  refCode,
  nbMinutes,
  onColorChanged,
  clientCode,
  enterpriseName,
}) => {
  const projectUpdateMutation = useProjectUpdate();

  const handleColorChange = useCallback(
    async (newColor: GRANEET_COLORS) => {
      await projectUpdateMutation.mutateAsync({ id, dto: { color: newColor } });
      onColorChanged();
    },
    [projectUpdateMutation, id, onColorChanged],
  );

  return (
    <HStack justifyContent="space-between" py={2} mx={3} maxWidth="13rem" fontSize="sm">
      <HStack spacing={3}>
        <ColorPickerField
          mt={1.5}
          boxSize="1rem"
          name="color"
          colors={PROJECT_COLOR_PICKER_COLORS}
          value={color}
          variant="compact"
          onChange={handleColorChange}
          label=""
          alignSelf="start"
        />
        <VStack spacing={1} align="flex-start" width="7rem">
          <EllipsisText fontWeight="600" color="gray.800" fontSize="sm" width="7rem">
            {name}
          </EllipsisText>
          <Text fontSize="xs" color="gray.500" noOfLines={1}>
            {refCode}
          </Text>
          {(clientCode || enterpriseName) && (
            <Text fontSize="xs" color="gray.500" noOfLines={1}>
              {clientCode}
              {clientCode && enterpriseName ? ' - ' : ''}
              {enterpriseName}
            </Text>
          )}
        </VStack>
      </HStack>
      <Badge colorScheme="gray" justifySelf="flex-end">
        {`${divideFloating(roundFloating(multiplyFloating(divideFloating(nbMinutes, 60), 100)), 100)}`.replace(
          '.',
          ',',
        )}
        {timeTableTranslations.hourLabel}
      </Badge>
    </HStack>
  );
};
