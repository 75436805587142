import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PERMISSION, SUPPLIER_TYPE } from '@graneet/business-logic';

import type { ImportSpreadsheetField } from 'features/import/components/SpreadsheetImport';
import { usePermissions } from 'features/role/hooks/usePermissions';

export type ImportSupplierField =
  | 'type'
  | 'code'
  | 'name'
  | 'address'
  | 'postalCode'
  | 'city'
  | 'country'
  | 'auxiliaryAccount';

export const useImportSupplierFields = (): ImportSpreadsheetField<ImportSupplierField>[] => {
  const { t } = useTranslation(['supplier', 'global']);
  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  return useMemo<ImportSpreadsheetField<ImportSupplierField>[]>(() => {
    const fields: ImportSpreadsheetField<ImportSupplierField>[] = [
      {
        label: t('supplier:fields.type'),
        key: 'type',
        fieldType: {
          type: 'select',
          options: [
            {
              label: t('supplier:types.SUB_CONTRACTOR'),
              value: SUPPLIER_TYPE.SUB_CONTRACTOR,
            },
            {
              label: t('supplier:types.SUPPLIER'),
              value: SUPPLIER_TYPE.SUPPLIER,
            },
          ],
        },
        alternateMatches: ['Type*'], // Column name in template
        example: t('supplier:import.example.type'),
        validations: [
          {
            rule: 'required',
            errorMessage: t('global:spreadsheetImport.common.errorMandatory'),
          },
        ],
      },
      {
        label: t('supplier:fields.code'),
        key: 'code',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Code'],
        example: t('supplier:import.example.code'),
      },
      {
        label: t('supplier:fields.name'),
        key: 'name',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Dénomination*'],
        example: t('supplier:import.example.name'),
        validations: [
          {
            rule: 'required',
            errorMessage: t('global:spreadsheetImport.common.errorMandatory'),
          },
        ],
      },
      {
        label: t('global:address.label'),
        key: 'address',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Adresse*'],
        example: t('supplier:import.example.address'),
        validations: [
          {
            rule: 'required',
            errorMessage: t('global:spreadsheetImport.common.errorMandatory'),
          },
        ],
      },
      {
        label: t('global:address.postalCode'),
        key: 'postalCode',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Code postal'],
        example: t('supplier:import.example.postalCode'),
      },
      {
        label: t('global:address.city'),
        key: 'city',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Ville'],
        example: t('supplier:import.example.city'),
      },
      {
        label: t('global:address.country'),
        key: 'country',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Pays'],
        example: t('supplier:import.example.country'),
      },
    ];

    if (hasUpdateAccountingPermission) {
      fields.push({
        label: t('supplier:fields.auxiliaryAccount'),
        key: 'auxiliaryAccount',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Code comptable auxiliaire'],
        example: t('supplier:import.example.auxiliaryAccount'),
      });
    }

    return fields;
  }, [t, hasUpdateAccountingPermission]);
};
