import { Flex } from '@chakra-ui/react';
import type { IFile } from '@graneet/business-logic';
import type { ImageListItem } from '@graneet/lib-ui';
import { IMAGE_LIST_ERROR, ImageList, Modal, useToast } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type AddJobImagesModalProps = {
  handleAddImages: (file: File[]) => Promise<void>;
  handleDeleteImage: (imageId: string | number) => Promise<void>;
  images?: IFile[];
  isOpen: boolean;
  onClose: () => void;
};

const FILE_SIZE_LIMIT = 10_000_000;

export const AddJobImagesModal: FC<AddJobImagesModalProps> = ({
  images = [],
  handleAddImages,
  handleDeleteImage,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const onImageError = useCallback(
    (error: IMAGE_LIST_ERROR) => {
      if (error === IMAGE_LIST_ERROR.SIZE_INVALID) {
        toast.error(t(`global:importImagesModal.errorSize`));
      }
      if (error === IMAGE_LIST_ERROR.FILE_INVALID) {
        toast.error(t(`global:importImagesModal.errorInvalidFile`));
      }
    },
    [t, toast],
  );

  const [newlyUploadImages, setNewlyUploadImages] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onImagesChange = useCallback((files: ImageListItem[]) => {
    const newlyAddedImages = files.map(({ file }) => file).filter((file): file is File => file !== undefined);
    setNewlyUploadImages(newlyAddedImages);
  }, []);

  const onAddImageClick = useCallback(async () => {
    setIsLoading(true);
    await handleAddImages(newlyUploadImages);
    setIsLoading(false);
    onClose();
  }, [handleAddImages, newlyUploadImages, onClose]);

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('global:importImagesModal.title')}
      size="3xl"
      scrollBehavior="inside"
    >
      <Flex>
        <ImageList
          multiple
          images={images}
          labels={{
            add: t('global:importImagesModal.addLabel'),
            drop: t('global:importImagesModal.dropLabel'),
          }}
          onImagesChange={onImagesChange}
          onImageDeleted={handleDeleteImage}
          onError={onImageError}
          confirmDelete={false}
          maxImageSize={FILE_SIZE_LIMIT}
          thumbnailSize="medium"
          width="100%"
          styleImage={{
            objectFit: 'cover',
          }}
        />
      </Flex>

      <Modal.Close isDisabled={isLoading} />

      <Modal.PrimaryButton onClick={onAddImageClick} isLoading={isLoading}>
        {images.length > 0 ? t('global:words.c.validate') : t('global:importImagesModal.ConfirmAddImages')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
