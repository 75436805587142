import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';
import { QuoteBasicItemTransformUseCase, QuoteHiddenCostTransformUseCase } from '@org/quotation-lib';
import { QuoteBasicItemTransformType, QuoteHiddenCostItemTransformType } from '@org/graneet-bff-client';

import { useQuoteSetToStore } from 'features/quotation/quote-common/hooks/useQuoteSetToStore';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export const useQuoteHiddenCostItemTransformToBasic = () => {
  const { quoteHiddenCostItemsProxyApi, quoteBasicItemsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId?: string): ICommand => {
      if (!nodeId) {
        throw new Error('nodeId is required');
      }

      return {
        execute(quote: Quote) {
          try {
            this.oldValue = nodeId;
            const quoteHiddenCostItem = quote.getTree().getQuoteHiddenCostOrThrow(nodeId);
            QuoteHiddenCostTransformUseCase.transformToQuoteBasicItem(quoteHiddenCostItem);
            quoteSetToStore(quote);

            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteHiddenCostItemsProxyApi
              .transformQuoteHiddenCostItem(quoteHiddenCostItem.getId(), {
                quoteId: quote.getId(),
                timestamp,
                clientRequestId,
                transformType: QuoteHiddenCostItemTransformType.QuoteBasicItem,
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));

            return true;
          } catch (e: any) {
            return quoteError(e.message);
          }
        },
        undo(quote: Quote) {
          try {
            const quoteBasicItem = quote.getTree().getQuoteBasicItemOrThrow(this.oldValue);
            QuoteBasicItemTransformUseCase.transformToHiddenCostItem(quoteBasicItem);
            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteBasicItemsProxyApi
              .transformQuoteBasicItem(quoteBasicItem.getId(), {
                quoteId: quote.getId(),
                timestamp,
                clientRequestId,
                transformType: QuoteBasicItemTransformType.HiddenCostItem,
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));

            quoteSetToStore(quote);
            return true;
          } catch (e: any) {
            return quoteError(e.message);
          }
        },
      };
    },
    [quoteBasicItemsProxyApi, quoteError, quoteHiddenCostItemsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
};
