import { useCallback, useEffect } from 'react';
import { object } from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  Section,
  BadgeAutoNumbering,
  DeepTable,
  DragAndDrop,
  DropZone,
  useDeepTable,
  ChapterCollapse,
  Callout,
} from '@graneet/lib-ui';

import { useMaxLotDepth } from '../hooks/useMaxLotDepth';

import { EditQuoteLot } from './EditQuoteLot';

import { useStore } from 'store/store';
import { QUOTE_ITEM_TYPE } from 'features/quote/constants/quotes.constant';
import { patchQuote } from 'features/quote/services/quote.api';
import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { QuoteTotalMultiplications } from 'features/quote/components/multiplications/QuoteTotalMultiplication';
import { useMoveJobInsideLot } from 'features/quote-job/hooks/useMoveJobInsideLot';
import { useSetQuoteJobHiddenCost } from 'features/quote-job/hooks/useSetQuoteJobHiddenCost';

export const EditQuoteRootLot = ({ rootLot, drawerSellSheet }) => {
  const rootLotId = rootLot?.lot.id;

  const { t } = useTranslation(['quote', 'margin']);
  const { displaySaleView, isReadOnlyView } = useQuoteDisplayContext();
  const maxLotDepth = useMaxLotDepth();

  const hasHiddenCosts = useStore((state) => state.hasHiddenCosts);

  const moveJobInsideLot = useMoveJobInsideLot();

  const { autoNumberingIsActivated, autoNumberingSetIsActivated, autoNumberingIsActivatedByDefault } = useStore(
    (state) => ({
      autoNumberingIsActivatedByDefault: state.autoNumberingIsActivatedByDefault,
      autoNumberingSetIsActivated: state.autoNumberingSetIsActivated,
      autoNumberingIsActivated: state.autoNumberingIsActivated,
    }),
  );
  const quoteData = useQuoteEditContext();
  const { getJob } = useQuoteEditContext();
  const setQuoteJobHiddenCost = useSetQuoteJobHiddenCost();

  useEffect(() => {
    const { quote } = quoteData.getCurrentQuoteData();

    autoNumberingSetIsActivated(quote.isAutomaticNumberingActivated);
  }, [autoNumberingIsActivatedByDefault, quoteData, autoNumberingSetIsActivated]);

  const handleClickAutoNumbering = async () => {
    const { quote } = quoteData.getCurrentQuoteData();

    await patchQuote(quote.id, { isAutomaticNumberingActivated: !autoNumberingIsActivated });
    autoNumberingSetIsActivated(!autoNumberingIsActivated);
  };

  const templateColumns = displaySaleView
    ? '6rem minmax(15rem, 1fr) 5rem 5rem 5rem 8rem 7rem 8rem 8rem'
    : '6rem minmax(15rem, 1fr) 5rem 6rem 8rem 10rem 8rem';

  const deepTable = useDeepTable({
    templateColumns,
    leftContentWidth: 0.5 * maxLotDepth + 2.5,
  });

  const canDropJobInHiddenCostSection = useCallback(
    (jobItem) => {
      const job = getJob(jobItem.id);

      // We cannot drop a job in the hidden cost section if is optional
      return !job.isOptional;
    },
    [getJob],
  );

  const handleDropJobInLot = useCallback(
    (isHiddenCost = false) =>
      async (jobItem) => {
        const job = getJob(jobItem.id);

        if (job.isHiddenCost !== isHiddenCost && job.lot.id === rootLotId) {
          await setQuoteJobHiddenCost(job);
          return;
        }

        if (rootLotId) {
          await moveJobInsideLot(jobItem.id, rootLotId, isHiddenCost);
        }
      },
    [getJob, moveJobInsideLot, rootLotId, setQuoteJobHiddenCost],
  );

  return (
    <DragAndDrop>
      {hasHiddenCosts && (
        <ChapterCollapse
          title={t('quote:hiddenCost.title')}
          defaultIsOpen
          collapseOverflowVisible
          topContent={
            <Flex w="100%" justifyContent="flex-end">
              <Box onClick={drawerSellSheet.onOpen}>
                <QuoteTotalMultiplications />
              </Box>
            </Flex>
          }
        >
          <DeepTable
            deepTable={deepTable}
            noCard /**
             * We need px and mx here as DeepTable crops the drag and drop glow
             * effect because of its overflow auto.
             * px creates the space for the glow to be visible, and mx makes the
             * table flush with its initial horizontal alignment.
             */
            p={2}
            m={-2}
          >
            <DropZone
              id={rootLotId}
              accept={QUOTE_ITEM_TYPE.JOB}
              onDrop={handleDropJobInLot(true)}
              canDrop={canDropJobInHiddenCostSection}
            >
              <DeepTable.Header bg="white" borderTopRadius={4}>
                <DeepTable.Cell />
                <DeepTable.Cell>{t('quote:jobsStep.fields.description')}</DeepTable.Cell>
                <DeepTable.Cell right>{t('quote:jobsStep.fields.unit')}</DeepTable.Cell>
                <DeepTable.Cell right>{t('quote:jobsStep.fields.quantity')}</DeepTable.Cell>

                {displaySaleView ? (
                  <>
                    <DeepTable.Cell right key="margin-header">
                      {t('margin:fields.margin')}
                    </DeepTable.Cell>

                    <DeepTable.Cell key="unitPriceExVAT-header" right>
                      {t('quote:fields.unitPriceExVAT')}
                    </DeepTable.Cell>

                    <DeepTable.Cell key="vat-header" center>
                      {t('quote:fields.vat')}
                    </DeepTable.Cell>

                    <DeepTable.Cell key="priceExVAT-header" right>
                      {t('quote:fields.priceExVAT')}
                    </DeepTable.Cell>
                  </>
                ) : (
                  <>
                    <DeepTable.Cell key="unitDisbursementExVAT-header" right>
                      {t('quote:jobsStep.fields.unitDisbursementExVAT')}
                    </DeepTable.Cell>

                    <DeepTable.Cell key="totalDisbursementExVAT-header" right>
                      {t('quote:jobsStep.fields.totalDisbursementExVAT')}
                    </DeepTable.Cell>
                  </>
                )}

                <DeepTable.Cell center />
              </DeepTable.Header>
            </DropZone>

            <EditQuoteLot lotId={rootLotId} isRootLot displayHiddenCosts />
          </DeepTable>
        </ChapterCollapse>
      )}

      <Section
        title={!isReadOnlyView && t('quote:jobsStep.title')}
        description={isReadOnlyView ? <Callout>{t('quote:jobsStep.viewQuotationDetailsCallout')}</Callout> : null}
        topContent={
          hasHiddenCosts ? null : (
            <Flex w="100%" justifyContent="flex-end">
              <Box onClick={drawerSellSheet.onOpen}>
                <QuoteTotalMultiplications />
              </Box>
            </Flex>
          )
        }
      >
        <DeepTable
          deepTable={deepTable}
          noCard /**
           * We need px and mx here as DeepTable crops the drag and drop glow
           * effect because of its overflow auto.
           * px creates the space for the glow to be visible, and mx makes the
           * table flush with its initial horizontal alignment.
           */
          p={2}
          m={-2}
        >
          <DropZone id={rootLotId} accept={QUOTE_ITEM_TYPE.JOB} onDrop={handleDropJobInLot(false)}>
            <DeepTable.Header bg="white" borderTopRadius={4}>
              <DeepTable.Cell>
                <Flex direction="row" gap="1" alignItems="center">
                  {t('quote:jobsStep.fields.code')}
                  {!isReadOnlyView ? (
                    <BadgeAutoNumbering onClick={handleClickAutoNumbering} isActivated={autoNumberingIsActivated} />
                  ) : null}
                </Flex>
              </DeepTable.Cell>
              <DeepTable.Cell>{t('quote:jobsStep.fields.description')}</DeepTable.Cell>
              <DeepTable.Cell right>{t('quote:jobsStep.fields.unit')}</DeepTable.Cell>
              <DeepTable.Cell right>{t('quote:jobsStep.fields.quantity')}</DeepTable.Cell>

              {displaySaleView ? (
                <>
                  <DeepTable.Cell right key="margin-header">
                    {t('margin:fields.margin')}
                  </DeepTable.Cell>

                  <DeepTable.Cell key="unitPriceExVAT-header" right>
                    {t('quote:fields.unitPriceExVAT')}
                  </DeepTable.Cell>

                  <DeepTable.Cell key="vat-header" center>
                    {t('quote:fields.vat')}
                  </DeepTable.Cell>

                  <DeepTable.Cell key="priceExVAT-header" right>
                    {t('quote:fields.priceExVAT')}
                  </DeepTable.Cell>
                </>
              ) : (
                <>
                  <DeepTable.Cell key="unitDisbursementExVAT-header" right>
                    {t('quote:jobsStep.fields.flatUnitDisbursementExVAT')}
                  </DeepTable.Cell>

                  <DeepTable.Cell key="totalDisbursementExVAT-header" right>
                    {t('quote:jobsStep.fields.flatTotalDisbursementExVAT')}
                  </DeepTable.Cell>
                </>
              )}

              <DeepTable.Cell center />
            </DeepTable.Header>
          </DropZone>

          <EditQuoteLot lotId={rootLotId} isRootLot />
        </DeepTable>
      </Section>
    </DragAndDrop>
  );
};

EditQuoteRootLot.propTypes = {
  rootLot: object.isRequired,
  drawerSellSheet: object.isRequired,
};
