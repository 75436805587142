import type { ReactNode } from 'react';
import { useRef, useCallback, useMemo, useState } from 'react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import type {
  ColumnMovedEvent,
  ColumnResizedEvent,
  GridApi,
  GridReadyEvent,
  IServerSideDatasource,
  SelectionChangedEvent,
  StatusPanelDef,
} from '@ag-grid-community/core';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { Box, Flex, Text } from '@chakra-ui/react';

import type { SimpleTableProps } from '../SimpleTable/SimpleTable';
import { DEFAULT_COL_DEF, SimpleTable } from '../SimpleTable/SimpleTable';
import type { PaginatedResponse } from '../PaginatedData';
import { DIRECTION_QUERY, ORDER_QUERY } from '../PaginatedData';
import { isNumberFinite } from '../../utils';
import { useCurrency } from '../Currency';

import type { PaginationQuery } from './Pagination';
import type { PaginatedTableSelectBannerProps } from './PaginatedTableSelectBanner';
import { PaginatedTableSelectBanner } from './PaginatedTableSelectBanner';
import {
  getDisplayedPaginatedTableConfiguration,
  usePaginatedTableSettingsContext,
} from './PaginatedTableSettingsContext';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';

const DEFAULT_MODULES = [ServerSideRowModelModule, StatusBarModule];

type Unpacked<T> = T extends PaginatedResponse<infer U> ? U : never;

export interface PaginatedTableProps<TData extends PaginatedResponse<object>>
  extends Omit<SimpleTableProps<Unpacked<TData>>, 'rowData' | 'pagination' | 'cacheBlockSize'> {
  pagination: PaginationQuery<TData>;

  sums?: Partial<Record<keyof TData['sums'], { label: string; type: 'number' | 'currency' }>>;

  countLabel?: (count: number) => string;

  zeroState: {
    icon: ReactNode;

    label: ReactNode;
  };

  emptyState: {
    label: string;
  };

  /**
   * (From @Victor)
   *
   * This is not the prettiest handle for the selection, but this was the fastest to implement, so please if you have
   * an idea, you can implement it or say it to other in devs.
   */
  selectionComponentProps?: Pick<
    PaginatedTableSelectBannerProps<Unpacked<TData>>,
    'selectLabel' | 'allSelectedLabel' | 'children' | 'renderActions'
  >;
}

export const PaginatedTable = <TData extends PaginatedResponse<object>>({
  modules: modulesFromProp,
  pagination,
  sums,
  countLabel,
  zeroState,
  onSelectionChanged,
  selectionComponentProps,
  onGridReady,
  emptyState,
  columnDefs,
  gridId,
  onColumnResized,
  onColumnMoved,
  defaultColDef,
  ...otherProps
}: PaginatedTableProps<TData>) => {
  const modules = useMemo(() => [...DEFAULT_MODULES, ...(modulesFromProp ?? [])], [modulesFromProp]);

  const [numberOfRows, setNumberOfRows] = useState<number | null>(null);
  const [sumsData, setSumsData] = useState<Record<any, any>>({});

  const tableApiRef = useRef<GridApi<Unpacked<TData>> | null>(null);

  const { formatAsAmount } = useCurrency();

  const [displayZeroState, setDisplayZeroState] = useState(false);

  const serverSideDatasource = useMemo<IServerSideDatasource>(
    () => ({
      async getRows(params) {
        const from = params.request.startRow ?? 0;
        const to = params.request.endRow ?? 0;

        const sortQuery: Record<string, string> =
          params.request.sortModel[0]?.sort && params.request.sortModel[0]?.colId
            ? {
                [ORDER_QUERY]: params.request.sortModel[0].colId,
                [DIRECTION_QUERY]: params.request.sortModel[0].sort.toUpperCase(),
              }
            : {};

        const page = from / (to - from) + 1;

        try {
          const result = await pagination.fetchData({ page, sort: sortQuery });
          if (result.metadata.totalCount === 0) {
            tableApiRef.current?.showNoRowsOverlay();
          }

          setNumberOfRows(result.metadata.totalCount);
          setSumsData(result.sums);
          params.success({ rowCount: result.metadata.totalCount, rowData: result.data });
          if (result.metadata.unfilteredCount === 0) {
            setDisplayZeroState(true);
          }
        } catch {
          params.fail();
        }
      },
      destroy() {
        tableApiRef.current?.hideOverlay();
      },
    }),
    [pagination],
  );

  const [currentSelection, setCurrentSelection] = useState<{
    selectAll: boolean;
    selectedItems: Array<Unpacked<TData>>;
  }>({
    selectAll: false,
    selectedItems: [],
  });
  const handleSelectionChanged = useCallback(
    (selectionChangedEvent: SelectionChangedEvent<Unpacked<TData>>) => {
      onSelectionChanged?.(selectionChangedEvent);

      // All rows are selected
      const serverSideSelectionState = selectionChangedEvent.api.getServerSideSelectionState();
      if (serverSideSelectionState && 'selectAll' in serverSideSelectionState && serverSideSelectionState.selectAll) {
        setCurrentSelection({
          selectAll: true,
          selectedItems: [],
        });
        return;
      }

      // Some rows are selected
      setCurrentSelection({
        selectAll: false,
        selectedItems: selectionChangedEvent.api.getSelectedRows(),
      });
    },
    [onSelectionChanged],
  );

  const statusBar = useMemo<PaginatedTableProps<TData>['statusBar']>(() => {
    const panels: StatusPanelDef[] = [];

    if (countLabel) {
      panels.push({
        // eslint-disable-next-line react/no-unstable-nested-components
        statusPanel: () => {
          if (!isNumberFinite(numberOfRows)) {
            return null;
          }
          return <Box>{`${numberOfRows} ${countLabel(numberOfRows)}`}</Box>;
        },
      });
    }

    if (sums) {
      Object.entries<{ label: string; type: 'number' | 'currency' }>(sums as any).forEach(
        ([key, { label, type }], index) => {
          const value = sumsData[key as keyof typeof sumsData];
          if (!value) return;
          const formattedValue = type === 'currency' ? formatAsAmount(value) : value;

          panels.push({
            // eslint-disable-next-line react/no-unstable-nested-components
            statusPanel: () => (
              <Flex ml={3}>
                <Text>∑ {label} :&nbsp;</Text>
                <Text fontWeight={600}>{formattedValue}</Text>
                {index < Object.keys(sums).length - 1 && (
                  <Text color="gray.600" pl={2}>
                    |
                  </Text>
                )}
              </Flex>
            ),
          });
        },
      );
    }

    return {
      statusPanels: panels,
    };
  }, [countLabel, formatAsAmount, numberOfRows, sums, sumsData]);

  const handleGridReady = useCallback(
    (event: GridReadyEvent<Unpacked<TData>>) => {
      onGridReady?.(event);
      tableApiRef.current = event.api;
    },
    [onGridReady],
  );

  const resetSelectedCheckboxes = useCallback(() => {
    tableApiRef.current?.deselectAll();
  }, []);

  const { settings, updateSettings } = usePaginatedTableSettingsContext<Unpacked<TData>>();
  const overwriteColumnDefs = useMemo(
    () => getDisplayedPaginatedTableConfiguration(columnDefs, settings[gridId] ?? []),
    [columnDefs, gridId, settings],
  );

  const handleColumnResized = useCallback(
    (event: ColumnResizedEvent<Unpacked<TData>>) => {
      if (event.finished && event.source === 'uiColumnResized') {
        const setting = (event.api.getAllGridColumns() ?? []).map((column) => {
          const colDef = column.getColDef();

          return {
            field: (colDef.field ?? '') as keyof Unpacked<TData>,
            hide: colDef.hide ?? false,
            width: column.getActualWidth(),
          };
        });
        updateSettings(gridId, setting);
      }
      onColumnResized?.(event);
    },
    [gridId, onColumnResized, updateSettings],
  );

  const handleColumnMoved = useCallback(
    (event: ColumnMovedEvent) => {
      if (event.finished) {
        const setting = (event.api.getAllGridColumns() ?? []).map((column) => {
          const colDef = column.getColDef();

          return {
            field: (colDef.field ?? '') as keyof Unpacked<TData>,
            hide: colDef.hide ?? false,
            width: column.getActualWidth(),
          };
        });
        updateSettings(gridId, setting);
      }
      onColumnMoved?.(event);
    },
    [gridId, onColumnMoved, updateSettings],
  );

  const noRowsOverlayComponent = useCallback(
    () => (
      <Flex boxSize="100%" gap={3} direction="column" justifyContent="center" alignItems="center">
        <Flex>
          <i className="ri-search-line ri-4x" />
        </Flex>
        <Flex alignItems="center" gap={3}>
          {emptyState.label}
        </Flex>
      </Flex>
    ),
    [emptyState.label],
  );

  if (displayZeroState) {
    return (
      <Flex boxSize="100%" gap={3} direction="column" justifyContent="center" alignItems="center">
        <Flex>{zeroState.icon}</Flex>
        <Flex alignItems="center" gap={3}>
          {zeroState.label}
        </Flex>
      </Flex>
    );
  }

  return (
    <>
      <SimpleTable
        gridId={gridId}
        columnDefs={overwriteColumnDefs}
        onColumnResized={handleColumnResized}
        onColumnMoved={handleColumnMoved}
        autoSizeStrategy="none"
        onGridReady={handleGridReady}
        rowModelType="serverSide"
        modules={modules}
        reactiveCustomComponents
        blockLoadDebounceMillis={300}
        serverSideDatasource={serverSideDatasource}
        suppressRowVirtualisation={false}
        cacheBlockSize={pagination.pagination.size}
        suppressServerSideFullWidthLoadingRow
        suppressDragLeaveHidesColumns
        statusBar={statusBar}
        onSelectionChanged={handleSelectionChanged}
        noRowsOverlayComponent={noRowsOverlayComponent}
        defaultColDef={{ ...DEFAULT_COL_DEF, ...defaultColDef }}
        domLayout="normal"
        getRowId={(row) => ('id' in row.data ? (row.data?.id as string) : 'ERR')}
        {...otherProps}
      />

      {selectionComponentProps && (currentSelection.selectAll || currentSelection.selectedItems.length > 0) && (
        <PaginatedTableSelectBanner
          allSelectedLabel={selectionComponentProps.allSelectedLabel}
          renderActions={selectionComponentProps.renderActions}
          selectLabel={selectionComponentProps.selectLabel}
          numberOfRows={numberOfRows ?? 0}
          pagination={pagination.pagination}
          selectAll={currentSelection.selectAll}
          selectedItems={currentSelection.selectedItems}
          resetSelectedCheckboxes={resetSelectedCheckboxes}
        >
          {selectionComponentProps.children}
        </PaginatedTableSelectBanner>
      )}
    </>
  );
};
