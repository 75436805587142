// Note
export const STYLE_CONTRACT_NOTE = {
  fontSize: 'md',
  borderBottomColor: 'transparent',
  _hover: { borderBottomColor: 'gray.300' },
  _disabled: { borderBottomColor: 'transparent' },
};

// Lot
export const STYLE_CONTRACT_LOT = (depth: number) => ({
  DEFAULT: {
    bg: depth > 1 ? 'gray.150' : 'gray.200',
    borderBottom: '1px solid',
    borderColor: 'gray.300',
  },
  IS_DELETED: {
    bg: 'red.150',
    borderBottom: '1px solid',
    borderColor: 'gray.300',
  },
});

export const STYLE_CONTRACT_LOT_INPUT = {
  DEFAULT: {},
  IS_DELETED: {
    textDecoration: 'line-through',
    textDecorationColor: 'red.500',
  },
  IS_UPDATED: {
    fontWeight: 600,
  },
};

export const STYLE_CONTRACT_LOT_NOTE = {
  DEFAULT: {
    bg: 'white',
  },
  IS_DELETED: {
    bg: 'red.150',
  },
};

export const STYLE_CONTRACT_LOT_NOTE_INPUT = {
  DEFAULT: STYLE_CONTRACT_NOTE,
  IS_DELETED: {
    ...STYLE_CONTRACT_NOTE,
    textDecoration: 'line-through',
    textDecorationColor: 'red.500',
  },
};

// Item
export const STYLE_CONTRACT_ITEM = {
  DEFAULT: {
    bg: 'white',
    borderLeft: '2px solid',
    borderLeftColor: 'gray.200',
  },
  IS_DELETED: {
    bg: 'red.100',
    borderLeft: '2px solid',
    borderLeftColor: 'gray.200',
  },
};

export const STYLE_CONTRACT_ITEM_INPUT = {
  DEFAULT: {},
  IS_DELETED: {
    textDecoration: 'line-through',
    textDecorationColor: 'red.500',
  },
  IS_UPDATED: {
    _placeholder: {
      fontWeight: '500',
    },
    fontWeight: '700',
  },
};

export const STYLE_CONTRACT_ITEM_NOTE_INPUT = {
  DEFAULT: {
    ...STYLE_CONTRACT_NOTE,
  },
  IS_DELETED: {
    ...STYLE_CONTRACT_NOTE,
    textDecoration: 'line-through',
    textDecorationColor: 'red.500',
  },
};
