import { IsNotOneOf } from './IsNotOneOf';
import { IsRequired } from './IsRequired';
import { IsEmail } from './IsEmail';
import { IsNotZero } from './IsNotZero';
import { IsLowerOrEqualThan } from './IsLowerOrEqualThan';
import { IsHigherOrEqualThan } from './IsHigherOrEqualThan';
import { IsTrue } from './IsTrue';
import { IsFileSizeValid } from './IsFileSizeValid';
import { IsValidDateRange } from './IsValidDateRange';
import { IsNotEmptyArray } from './IsNotEmptyArray';
import { IsValidPassword } from './IsValidPassword';
import { IsRichTextVariablesValid } from './IsRichTextVariablesValid';
import { IsError } from './IsError';
import { IsDateRangeShorterThan } from './IsDateRangeShorterThan';
import { IsIban } from './IsIban';

export const Rule = {
  IsRequired,
  IsTrue,
  IsEmail,
  IsNotZero,
  IsLowerOrEqualThan,
  IsHigherOrEqualThan,
  IsNotOneOf,
  IsFileSizeValid,
  IsValidDateRange,
  IsDateRangeShorterThan,
  IsNotEmptyArray,
  IsValidPassword,
  IsRichTextVariablesValid,
  IsError,
  IsIban,
};
