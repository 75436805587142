import type { FC } from 'react';
import { Text, Badge, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SimpleAlertIcon } from '@graneet/lib-ui';

export const QuoteDiscountMarginErrorComponent: FC = () => {
  const { t } = useTranslation(['quote']);

  return (
    <Badge colorScheme="yellow" w="auto" px={2} py={1} mr={2}>
      <HStack spacing={2}>
        <SimpleAlertIcon stroke="orange.500" boxSize={4} />
        <Text fontSize="md" fontWeight={500}>
          {t('quote:discounts.marginError')}
        </Text>
      </HStack>
    </Badge>
  );
};
