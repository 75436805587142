import type { PropsWithChildren, Provider } from 'react';
import { useMemo } from 'react';

import type { PaginatedDataContextApi } from '../hooks/usePaginatedData';

import { PaginatedDataContext } from './PaginatedDataContext.context';

interface PaginatedDataProviderProps<T extends Record<any, any>> {
  data: PaginatedDataContextApi<T>;

  singlePage?: boolean;
}

export const PaginatedDataProvider = <T extends Record<any, any>>({
  children,
  data,
  singlePage = false,
}: PropsWithChildren<PaginatedDataProviderProps<T>>) => {
  const value = useMemo(
    () => ({
      ...data,
      // Disable sorting and infinite scroll when we limit table results with singlePage prop
      ...(singlePage && {
        hasMore: false,
        updateSorting: () => Promise.resolve(),
      }),
    }),
    [data, singlePage],
  );

  const PaginatedDataContextProvider = PaginatedDataContext.Provider as Provider<PaginatedDataContextApi<T>>;

  return <PaginatedDataContextProvider value={value}>{children}</PaginatedDataContextProvider>;
};
