import type { FC } from 'react';
import { useMemo } from 'react';
import type { IBillListingResponseDTO } from '@graneet/business-logic';
import { BILL_STATUSES } from '@graneet/business-logic';
import { Link } from '@graneet/lib-ui';

import { BillListing } from 'features/bill/components/BillListing';
import { useBills } from 'features/bill/services/bill.api';

const GRID_ID = 'transaction-bill';

interface BankingTransactionAssociateBillDrawerProps {
  id: string;

  onRowSelected: (selectedRows: IBillListingResponseDTO[]) => void;
}

const RowAction = ({ data }: { data: { id: number } | undefined }) => (
  <Link to={`../sales/bills?focusedId=${data?.id}`} isExternal>
    <i className="ri-share-box-line" />
  </Link>
);

export const BankingTransactionAssociateBillDrawer: FC<BankingTransactionAssociateBillDrawerProps> = ({
  onRowSelected,
}) => {
  const bills = useBills(
    useMemo(
      () => ({
        forcedFilters: {
          status: [BILL_STATUSES.PARTIALLY_PAID, BILL_STATUSES.AWAITING_PAYMENT],
        },
        storageStrategy: 'state',
      }),
      [],
    ),
  );

  return (
    <BillListing
      gridId={GRID_ID}
      pagination={bills}
      rowAction={RowAction}
      tableProps={{
        rowSelection: 'single',
        defaultColDef: {
          headerCheckboxSelection: false,
        },
        onRowSelected: (event) => {
          onRowSelected(event.api.getSelectedRows() ?? []);
        },
      }}
      customLayout={{
        invoiceNumber: { hide: false },
        name: { hide: false },
        client: { hide: false },
        paymentDeadline: { hide: false },
        remainingToBePaidIncVAT: { hide: false },
        status: { hide: false },
      }}
    />
  );
};
