import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleCarrouselLeftIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 25" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24.5C18.2907 24.5 23.4508 19.6595 23.9589 13.5H11.6667L13.549 17.0294L11.7843 17.9706L9.11765 12.9706L8.86667 12.5L9.11765 12.0294L11.7843 7.02941L13.549 7.97059L11.6667 11.5H23.9589C23.4508 5.34047 18.2907 0.5 12 0.5C5.37258 0.5 0 5.87258 0 12.5C0 19.1274 5.37258 24.5 12 24.5Z"
      fill="#384250"
    />
  </Icon>
);
