import type { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Card, RICH_TEXT_INLINE_TOOLBAR_PRESET, RichTextField, Section } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import { EXPORT_FILE_TYPES } from '../constants/quote-export.constant';
import type { QuoteExportForm } from '../forms/quote-export-edition.wizard';

export const QuoteExportNote: FC = () => {
  const { t } = useTranslation(['quote']);

  const form = useFormContext<QuoteExportForm>();
  const { fileType } = useOnChangeValues(form, ['fileType']);

  if (fileType === EXPORT_FILE_TYPES.SPREADSHEET) {
    return null;
  }

  return (
    <Box w="80%">
      <Section
        title={t('quote:cards.note.title')}
        description={<Trans t={t} i18nKey="quote:export.wizard.noteSection.description" />}
      >
        <Card>
          <RichTextField<QuoteExportForm>
            name="note"
            navbarType="inline"
            variant="light"
            configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
          />
        </Card>
      </Section>
    </Box>
  );
};
