import type { QuoteNodeObject } from '@org/quotation-lib';
import type { ColDef, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';

import { EmptyCell } from '../../components/EmptyCell';

export function useEmptyColumnDef() {
  return useCallback(
    (): ColDef<QuoteNodeObject> => ({
      colId: 'empty',
      flex: 1,
      resizable: false,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      headerComponent: EmptyCell,
      editable: false,
      sortable: false,
      cellStyle: { textAlign: 'right' },
      cellClass: 'no-focus fake-cell',
      cellRenderer: EmptyCell,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      valueGetter: (_params) => '-',
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [],
  );
}
