export const QUOTE_JOB_KEY = {
  CODE: 'code',
  NOTE: 'note',
  DESCRIPTION: 'description',
  UNIT: 'unit',
  TOTAL_MARGIN: 'totalMargin',
  QUANTITY: 'quantity',
  UNIT_DISBURSEMENT_EX_VAT: 'unitDisbursementExVAT',
  UNIT_PRICE_EX_VAT: 'unitPriceExVAT',
  VAT_RATE: 'vatRate',
};
