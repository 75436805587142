import type { PENALTY_TYPE } from '@graneet/business-logic';
import { TRACKING_EVENT_ENUM } from '@graneet/business-logic';
import { useCallback } from 'react';

import { useSegmentAnalytics } from '../components/SegmentProvider';

export const useSendClickAddPenaltyButtonEvent = () => {
  const segmentAnalytics = useSegmentAnalytics();

  return useCallback(
    async (type: PENALTY_TYPE) => {
      if (segmentAnalytics) {
        await segmentAnalytics.sendEvent(TRACKING_EVENT_ENUM.PENALTY_CREATED, {
          type,
        });
      }
    },
    [segmentAnalytics],
  );
};
