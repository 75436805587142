import type { ABSENCE_DURATION } from '@graneet/business-logic';

export const SAME_WORKING_HOURS = 'sameWorkingHour';
export const DIFFERENT_WORKING_HOURS = 'differentWorkingHour';

export type DateFieldName = `date.${number}`;
export type StartHourFieldName = `startHour.${string}`;
export type EndHourFieldName = `endHour.${string}`;
export type AbsenceDurationFieldName = `absenceDuration.${string}`;

export type TimeslotOvertimesHourFieldName = `timeslotOvertimeHour.${string}.${number}`;
export type TimeslotOvertimesTypeIdFieldName = `timeslotOvertimeTypeId.${string}.${number}`;

export interface TimeSlotCreateForm {
  [date: DateFieldName]: { startDate: string; endDate: string };

  [startDate: StartHourFieldName]: string;

  [endDate: EndHourFieldName]: string;

  [timeslotOvertimeHour: TimeslotOvertimesHourFieldName]: number;

  [timeslotOvertimeTypeId: TimeslotOvertimesTypeIdFieldName]: string;

  [absenceDuration: AbsenceDurationFieldName]: ABSENCE_DURATION;

  workingHourType: string;
}

export function getStartHourFieldName(date: string): StartHourFieldName {
  return `startHour.${date}`;
}

export function getEndHourFieldName(date: string): EndHourFieldName {
  return `endHour.${date}`;
}

export function isDateFieldName(fieldName: string): fieldName is DateFieldName {
  return fieldName.startsWith('date.');
}

export function getTimeslotOvertimesHourFieldName(date: string, index: number): TimeslotOvertimesHourFieldName {
  return `timeslotOvertimeHour.${date}.${index}`;
}

export function isTimeslotOvertimeHourFieldNameOnDay(
  fieldName: string,
  day: string,
): fieldName is TimeslotOvertimesHourFieldName {
  return fieldName.startsWith(`timeslotOvertimeHour.${day}`);
}

export function getTimeslotOvertimesTypeIdFieldName(date: string, index: number): TimeslotOvertimesTypeIdFieldName {
  return `timeslotOvertimeTypeId.${date}.${index}`;
}

export function isTimeslotOvertimeTypeIdFieldNameOnDay(
  fieldName: string,
  day: string,
): fieldName is TimeslotOvertimesTypeIdFieldName {
  return fieldName.startsWith(`timeslotOvertimeTypeId.${day}`);
}

export function getAbsenceTypeFieldName(date: string): AbsenceDurationFieldName {
  return `absenceDuration.${date}`;
}

export function extractTimeslotOvertimeField(key: TimeslotOvertimesHourFieldName | TimeslotOvertimesTypeIdFieldName) {
  const [type, , indexStr] = key.split('.');
  return {
    type,
    index: parseInt(indexStr, 10),
  };
}

export function getHour(hour?: string) {
  return hour === '' ? null : hour;
}
