import { useCallback, useState } from 'react';
import { type PaginatedResponse, type Response, StepperModal } from '@graneet/lib-ui';
import type { IClient, IClientResponseDTO, IContact } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { ClientSelectionStep } from './steps/ClientSelectionStep';
import { ContactSelectionStep } from './steps/ContactSelection';
import type { ContractSelectionWizard } from './ContractSelectionWizard';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import type { ClientOrSupplierAssociateForm } from 'features/contact/components/forms/ClientOrSupplierAssociationForm';

export interface ClientContactSelectionStepperModalProps<T extends IContact> {
  isOpen: boolean;
  onClose: () => void;
  onClientSelected: (clientId: number) => Promise<IClient | IClientResponseDTO | null>;
  onSubmit: (contacts: Array<{ entity: IContact; isDefaultEmailRecipient: boolean }>) => void;
  contactsRetriever: (
    clientId: number,
  ) => [Record<string, string | number>, (queryParams: URLSearchParams) => Promise<Response<PaginatedResponse<T>>>];
  isContactDefaultEmailRecipient: (contact: T) => boolean;
}

export const ClientContactSelectionStepperModal = <T extends IContact>({
  isOpen,
  onClose,
  onClientSelected,
  onSubmit,
  contactsRetriever,
  isContactDefaultEmailRecipient,
}: ClientContactSelectionStepperModalProps<T>) => {
  const { t } = useTranslation(['quote', 'project', 'global']);
  const [clientSelected, setClientSelected] = useState<IClient | IClientResponseDTO>();

  const onClientStepNext = useCallback(
    async (formValues: ClientOrSupplierAssociateForm | undefined) => {
      if (!formValues) {
        return false;
      }

      const client = await onClientSelected(formValues.association.id);

      if (!client) {
        return false;
      }
      setClientSelected(client);
      return true;
    },
    [onClientSelected],
  );

  const handleSubmit = useCallback(
    (wizardValues: ContractSelectionWizard) => {
      const { contacts } = wizardValues['associate-contact-client'];
      onSubmit(contacts);

      onClose();
      setClientSelected(undefined);
    },
    [onClose, onSubmit],
  );

  return (
    <StepperModal isOpen={isOpen} onClose={onClose} onFinish={handleSubmit} size="3xl">
      <StepperModal.Step<ContractSelectionWizard, 'associate-client-quote'>
        name="associate-client-quote"
        title={t('quote:clientStep.stepperClientTitle')}
        onNext={onClientStepNext}
      >
        <QueryWrapper>
          <ClientSelectionStep description={t('quote:clientStep.select')} />
        </QueryWrapper>
      </StepperModal.Step>

      <StepperModal.Step<ContractSelectionWizard, 'associate-contact-client'>
        name="associate-contact-client"
        title={t('quote:clientStep.stepperContactTitle')}
      >
        {clientSelected && (
          <ContactSelectionStep
            onClose={onClose}
            contactsRetriever={contactsRetriever(clientSelected.id)}
            isContactDefaultEmailRecipient={isContactDefaultEmailRecipient}
          />
        )}
      </StepperModal.Step>
    </StepperModal>
  );
};
