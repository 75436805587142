import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Button, Modal, Section } from '@graneet/lib-ui';
import type { ISupplierListingDTO } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';

export interface SupplierDeleteSectionProps {
  onDeleted(): Promise<void>;

  supplier: ISupplierListingDTO;
}

export const SupplierDeleteSection: FC<SupplierDeleteSectionProps> = ({ onDeleted, supplier }) => {
  const { t } = useTranslation(['supplier']);

  const modal = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    await onDeleted();
    setIsLoading(false);

    modal.onClose();
  }, [modal, onDeleted]);

  return (
    <>
      <Section
        title={t('supplier:deleteSupplierSection.title')}
        description={t('supplier:deleteSupplierSection.description')}
      >
        <Button
          onClick={modal.onOpen}
          colorScheme="red"
          variant="outline"
          isDisabled={!!supplier?.isUsed}
          tooltip={supplier?.isUsed ? t('supplier:deleteSupplierSection.isUsed') : undefined}
        >
          {t('supplier:cta.delete')}
        </Button>
      </Section>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t('supplier:deleteSupplierSection.title')}>
        {t('supplier:deleteSupplierSection.modalDescriptionWithContacts')}

        <Modal.Close isDisabled={isLoading} />
        <Modal.PrimaryButton onClick={handleDelete} isLoading={isLoading} colorScheme="red">
          {t('supplier:cta.delete')}
        </Modal.PrimaryButton>
      </Modal>
    </>
  );
};
