import axios from 'axios';
import type dayjs from 'dayjs';
import { type FileItem, type FileWithOptions, formatDateOrEmpty, joinWithHyphens } from '@graneet/lib-ui';
import type { IFile, IMailingInitialValuesResponseDTO, IPdf } from '@graneet/business-logic';
import { isPdfBeingGenerated, normalizeStringNFD, PDF_STATUSES } from '@graneet/business-logic';

import { DOWNLOAD_FILE_TYPE } from '../components/FilesCard/components/DownloadFileIcon';

import { getDownloadUrl, getPreviewUrl } from 'features/pdf/services/pdf.api';

export const downloadFile = (url: string, filename?: string) => {
  const link = window.document.createElement('a');
  link.href = url;
  if (filename) link.setAttribute('download', filename);

  window.document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

const FILE_ROOT = `${axios.defaults.baseURL}/files`;

export const formatFileDownloadUrl = (fileId: string, fileName?: string) => {
  const fileNameParam = fileName ? `?fileName=${encodeURIComponent(fileName)}` : '';

  return `${FILE_ROOT}/${fileId}/download${fileNameParam}`;
};
export const formatFilePreviewUrl = (fileId: string) => `${FILE_ROOT}/${fileId}/preview`;

/*
 * Format a file name with given information the extension is guessed by the navigator. This can be sometime broken
 */
export const formatFileName = (name: string, date: dayjs.ConfigType, invoiceNumber?: string | null) => {
  const formattedDate = date ? formatDateOrEmpty(date).replace(/\//g, '-') : undefined;

  return joinWithHyphens(invoiceNumber, normalizeStringNFD(name), formattedDate);
};

interface FileItemProps {
  type: DOWNLOAD_FILE_TYPE;
  hasError: boolean;
  isGenerating: boolean;
  isGenerated: boolean;
  downloadLink: string | undefined;
  previewLink: string | undefined;
}

export const mapPdfToFileItemProps = (pdf?: IPdf | null, filename = '') => {
  let props: FileItemProps = {
    type: DOWNLOAD_FILE_TYPE.PDF,
    hasError: false,
    isGenerating: false,
    isGenerated: false,
    downloadLink: undefined,
    previewLink: undefined,
  };

  if (pdf) {
    const hasError = pdf.status === PDF_STATUSES.ERROR;
    const isGenerating = isPdfBeingGenerated(pdf);
    const isGenerated = !hasError && !isGenerating;

    const downloadLink = getDownloadUrl(pdf.apiId, filename);
    const previewLink = getPreviewUrl(pdf.apiId);

    props = {
      ...props,
      hasError,
      isGenerating,
      isGenerated,
      downloadLink,
      previewLink,
    };
  }

  return props;
};

export const isIFile = (entity: File | IFile): entity is IFile => 'id' in entity;

export const getFileExtension = (fileName: string) => {
  const parts = fileName.split('.');
  return parts.length > 1 ? parts[parts.length - 1] : '';
};

export const getTotalSize = (files: FileWithOptions[]): number =>
  files.reduce((total, fileWithOptions) => {
    const { file } = fileWithOptions;
    const size = fileWithOptions.customFile ? (file as FileItem).size : (file as File).size;
    return total + (size ? +size : 0);
  }, 0);

export function formatOptionsFileData(
  file: IFile | Pick<IMailingInitialValuesResponseDTO['defaultAttachment'], 'name' | 'sizeBytes'>,
  fileId: string,
  getUrlFunction: (initialFile: IFile) => string | undefined,
) {
  return {
    file: {
      id: fileId,
      name: file.name,
      url: getUrlFunction(file as IFile) || '#',
      extension: getFileExtension(file.name),
      size: file.sizeBytes?.toString() || '0',
    },
    readOnly: true,
    grayedOut: true,
    customFile: true,
  };
}
