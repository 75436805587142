import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Text, Flex, Box, Progress } from '@chakra-ui/react';
import {
  Card,
  Price,
  useCurrency,
  TwoColumns,
  CurrencyField,
  Section,
  formatPercentage,
  SimpleInfoIcon,
} from '@graneet/lib-ui';
import { Form, useStepForm, useWizardContext } from 'graneet-form';
import { Trans, useTranslation } from 'react-i18next';
import type { IDownPayment, IProgressStatement, ISubProject } from '@graneet/business-logic';
import { getPercentage } from '@graneet/business-logic';

import { Rule } from 'features/form/rules/Rule';
import { DownPaymentProrataButton } from 'features/down-payment/components/DownPaymentProrataButton';
import type { ProgressStatementEditWizard } from 'features/progress-statement/forms/progress-statement-edit-wizard';
import type { ProgressStatementDownPaymentForm } from 'features/progress-statement/forms/progress-statement-down-payment-form';

interface EditProgressStatementDownPaymentStepProps {
  subProject: ISubProject;

  currentProgressStatement: IProgressStatement | null;

  downPayment: IDownPayment;
}

export const EditProgressStatementDownPaymentStep: FC<EditProgressStatementDownPaymentStepProps> = ({
  subProject,
  currentProgressStatement,
  downPayment,
}) => {
  const { t } = useTranslation(['progressStatement']);
  const { formatAsAmount, mapNumberToAmount } = useCurrency();

  const { form, initFormValues } = useStepForm<ProgressStatementEditWizard, 'downPayment'>();
  const { currentStep } = useWizardContext<ProgressStatementEditWizard>();
  const [inputRef, setInputRef] = useState<HTMLInputElement | undefined>(undefined);

  useEffect(() => {
    initFormValues({
      downPaymentAmortizationAmount: mapNumberToAmount(currentProgressStatement?.downPaymentAmortizationAmount || 0),
    });

    inputRef?.focus();
  }, [inputRef, currentStep, initFormValues, mapNumberToAmount, currentProgressStatement]);

  // Compute displayed amounts
  const { amountIncVATAmortized, amountIncVAT } = downPayment;
  const downPaymentAmortizationRemainderAmount = amountIncVAT - amountIncVATAmortized;
  const downPaymentAmortizationPercentage = getPercentage(amountIncVATAmortized, amountIncVAT);

  return (
    <Form form={form}>
      <Section
        title={t('progressStatement:isCreating.downPaymentStep.title')}
        description={t('progressStatement:isCreating.downPaymentStep.description')}
      >
        <TwoColumns mb={5}>
          <Card>
            <Flex alignItems="center" mt={3}>
              <SimpleInfoIcon stroke="greenBrand.light" fontSize={22} />
              <Box ml={3}>
                <Text>
                  <Trans
                    t={t}
                    i18nKey="progressStatement:isCreating.downPaymentStep.amortizedPercentage"
                    values={{ percentage: formatPercentage(downPaymentAmortizationPercentage) }}
                  >
                    <Text as="span" color="greenBrand.light" display="inline" />
                  </Trans>
                </Text>
                <Text>
                  <Trans
                    t={t}
                    i18nKey="progressStatement:isCreating.downPaymentStep.remainingAmount"
                    values={{ amount: formatAsAmount(downPaymentAmortizationRemainderAmount) }}
                    as={Text}
                  >
                    <Text as="span" color="greenBrand.light" display="inline" />
                  </Trans>
                </Text>
              </Box>
            </Flex>

            <Box mt={6}>
              <Flex flexDirection="row" justifyContent="space-between">
                <Text>
                  <Price amount={0} />
                </Text>
                <Text>
                  <Price amount={amountIncVAT} />
                </Text>
              </Flex>
              <Progress
                value={downPaymentAmortizationPercentage}
                borderRadius="md"
                h="32px"
                width="100%"
                colorScheme="blue"
              />
              <Flex flexDirection="row" justifyContent="flex-end" alignItems="center">
                <Text my={2} mr={5}>
                  {t('progressStatement:isCreating.downPaymentStep.amortizedAmount')}
                </Text>
                <Price amount={amountIncVATAmortized || 0} />
              </Flex>
            </Box>
          </Card>

          <Card>
            <Text mt={3}>{t('progressStatement:isCreating.downPaymentStep.inputDescription')}</Text>
            <CurrencyField<ProgressStatementDownPaymentForm>
              mt={6}
              name="downPaymentAmortizationAmount"
              label={t('progressStatement:isCreating.downPaymentStep.inputLabel')}
              inputRef={setInputRef}
              min={0}
              w="60%"
              isRequired
            >
              <Rule.IsRequired />
              <Rule.IsLowerOrEqualThan
                amount={mapNumberToAmount(downPaymentAmortizationRemainderAmount)}
                message={t('progressStatement:isCreating.downPaymentStep.error')}
              />
            </CurrencyField>
            <DownPaymentProrataButton subProject={subProject} downPayment={downPayment} />
          </Card>
        </TwoColumns>
      </Section>
    </Form>
  );
};
