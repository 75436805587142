import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import type { IQuoteJob } from '@graneet/business-logic';

import { updateJob } from '../services/quote-job.api';
import { useQuoteEditContext } from '../../quote/hooks/useQuoteEditContext';

export const useSetQuoteJobHiddenCost = () => {
  const toast = useToast();
  const { t } = useTranslation(['global']);
  const { updateDataLocally, emitLotUpdate } = useQuoteEditContext();

  return useCallback(
    async (job: IQuoteJob) => {
      const [err, quoteInfos] = await updateJob(job.id, { isHiddenCost: !job.isHiddenCost });

      if (err) {
        toast.error(t('global:errors.error'));
        return;
      }

      updateDataLocally(quoteInfos);
      emitLotUpdate(quoteInfos.rootLotId);
    },
    [t, toast, updateDataLocally, emitLotUpdate],
  );
};
