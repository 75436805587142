import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import type { FieldValues } from 'graneet-form';

import { ceilFloating, floorFloating, roundFloating } from '../../../utils/math.util';
import { useCurrency } from '../../Currency';
import type { MaskedNumberFieldProps } from '../MaskedNumberField';
import { MaskedNumberField } from '../MaskedNumberField';
import type { KeysMatching } from '../../../utils';
import { isNumberFinite } from '../../../utils';

/**
 * When the field is modified and input is emptied, value is equal to null
 */
export type CurrencyValue = number | null | undefined;

export interface CurrencyFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, CurrencyValue> = KeysMatching<T, CurrencyValue>,
> extends Omit<MaskedNumberFieldProps<T, K>, 'type'> {
  name: K;

  min?: number;

  max?: number;

  hideErrorMessage?: boolean;

  forceNegative?: boolean;

  noBorder?: boolean;

  children?: ReactNode;
}

export const CurrencyField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, CurrencyValue> = KeysMatching<T, CurrencyValue>,
>({
  name,
  min,
  max,
  hideErrorMessage,
  forceNegative = false,
  noBorder,
  children,
  ...rest
}: CurrencyFieldProps<T, K>) => {
  const {
    mapNumberToAmount,
    mapAmountToNumber,
    currency: { precision },
  } = useCurrency();

  const options = useMemo(() => {
    let minValue = isNumberFinite(min) ? ceilFloating(min, precision) : min;
    let maxValue = isNumberFinite(max) ? floorFloating(max, precision) : max;

    if (forceNegative) {
      minValue = isNumberFinite(minValue) ? Math.abs(minValue) : undefined;
      maxValue = isNumberFinite(maxValue) ? Math.abs(maxValue) : undefined;
    }

    return {
      min: minValue,
      max: maxValue,
      forceNegative,
    };
  }, [min, precision, max, forceNegative]);

  const formatValue = useCallback(
    (value: T[K] | undefined) => {
      // When user write '-' in field, imask returns '-0'
      // so we don't want to parse it to not erase user input for 0
      if (!value || value === '-0') return value;

      const valueByScale = mapAmountToNumber(value);
      return mapNumberToAmount(roundFloating(valueByScale));
    },
    [mapAmountToNumber, mapNumberToAmount],
  );

  return (
    <MaskedNumberField<T, K>
      name={name}
      options={options}
      hideErrorMessage={hideErrorMessage}
      formatValue={formatValue as (v: T[K] | undefined) => T[K] | undefined}
      {...rest}
      type="currency"
      noBorder={noBorder}
    >
      {children}
    </MaskedNumberField>
  );
};
