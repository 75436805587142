import type { IAccountingExport } from '../accounting/accounting-export.type';
import type { IBill } from '../bill/bill.type';
import type { DEADLINE_TYPE } from '../common/deadline-type.constant';
import type { RequiredByKeys } from '../common/types/object.type';
import type { ICredit } from '../credit/credit.type';
import type { IPaymentMethod } from '../payment-method/payment-method.type';
import type { IPdf } from '../pdf/pdf.type';
import type { IProgressStatement } from '../progress-statement/progress-statement.type';
import type { IProject } from '../project/project.type';
import type { ISubProject } from '../sub-project/sub-project.type';
import type { IVatBases, IVatDiscountedBases } from '../vat/vat-bases.type';
import type { IVatDistribution } from '../vat/vat-distribution.type';

export enum DOWN_PAYMENT_STATUSES {
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum DOWN_PAYMENT_TYPES {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export interface IDownPayment {
  id: number;

  historyFamilyId: string;

  groupId: number;

  subGroupId: number;

  name: string;

  isInvoiced: boolean;

  invoiceNumber: string | null;

  note: string;

  isArchived: boolean;

  billingDate: Date;

  completionDate: Date | null;

  type: DOWN_PAYMENT_TYPES;

  percentage: number | null;

  amountExVAT: number;

  amountIncVAT: number;

  hasReversalOfLiability: boolean;

  amountIncVATAmortized: number;

  vatDistribution: IVatDistribution | IVatBases | IVatDiscountedBases;

  deadlineType: DEADLINE_TYPE;

  paymentTerm: number;

  status: DOWN_PAYMENT_STATUSES;

  paymentMethod?: IPaymentMethod;

  project?: IProject;

  subProject?: ISubProject | null;

  bill?: IBill | null;

  pdf?: IPdf | null;

  credit?: ICredit | null;

  progressStatements?: IProgressStatement[];

  accountingExport?: IAccountingExport | null;

  tags: string[];

  createdAt: Date;

  updatedAt: Date;
}

export type IDownPaymentWithRelations = RequiredByKeys<
  IDownPayment,
  'subProject' | 'paymentMethod' | 'bill' | 'pdf' | 'credit' | 'project' | 'accountingExport'
>;

export type PartialDownPayment = Pick<Partial<IDownPayment>, 'amountExVAT' | 'percentage' | 'type'>;

export type PartialDownPaymentAmountsAndDistribution = Pick<
  IDownPayment,
  'amountExVAT' | 'amountIncVAT' | 'vatDistribution'
>;
