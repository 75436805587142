import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

export const StarsIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 20 16" {...props}>
    <path
      fill="#FFB946"
      d="m5.085 7.33 4.039-.735a1.945 1.945 0 0 0 1.562-1.547L11.65.034l.97 5.039c.149.776.753 1.385 1.528 1.54l3.582.717-3.718 1.115c-.703.21-1.227.799-1.356 1.521l-1.006 5.631-1-5.597a1.945 1.945 0 0 0-1.402-1.535L5.085 7.33ZM.556 3.423l1.163-.212A1.945 1.945 0 0 0 3.28 1.665L3.591.05l.316 1.64c.15.776.753 1.384 1.529 1.54l.966.193-1.102.33c-.703.212-1.228.8-1.357 1.522l-.351 1.97-.346-1.936a1.945 1.945 0 0 0-1.404-1.535L.556 3.423Z"
    />
    <path
      fill="#FFB946"
      d="m3.209 12.177 1.163-.211a1.945 1.945 0 0 0 1.562-1.547l.31-1.615.316 1.64c.15.776.753 1.385 1.529 1.54l.966.193-1.103.331c-.702.21-1.227.8-1.356 1.522L6.245 16l-.346-1.937a1.945 1.945 0 0 0-1.404-1.535l-1.286-.35Z"
    />
  </Icon>
);
