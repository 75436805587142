import type { FC } from 'react';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { EditorState } from 'lexical';
import { $getSelection, $isRangeSelection, RootNode } from 'lexical';
import { $restoreEditorState } from '@lexical/utils';
import { trimTextContentFromAnchor } from '@lexical/selection';

interface MaxLengthPluginProps {
  maxLength: number;
}

export const MaxLengthPlugin: FC<MaxLengthPluginProps> = ({ maxLength }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    let lastRestoredEditorState: EditorState | null = null;

    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }
      const prevEditorState = editor.getEditorState();
      const prevTextContentSize = prevEditorState.read(() => rootNode.getTextContentSize());

      const contentPerLines = rootNode.getTextContent().split(/\n/g);

      if ((contentPerLines?.length || 0) > maxLength) {
        const textContentSize = rootNode.getTextContentSize();

        const lengthToCut =
          contentPerLines?.reduce((acc, lineContent, index) => {
            if (index > maxLength) {
              return acc;
            }
            return acc + lineContent.length;
          }, 0) || 0;

        const delCount =
          textContentSize -
          lengthToCut + // length cut from the start to the last line before the problematic break line
          maxLength + // /n cut
          -1;
        const { anchor } = selection;

        if (delCount > 0) {
          // Restore the old editor state instead if the last
          // text content was already at the limit.
          if (prevTextContentSize === maxLength && lastRestoredEditorState !== prevEditorState) {
            lastRestoredEditorState = prevEditorState;
            $restoreEditorState(editor, prevEditorState);
          } else {
            trimTextContentFromAnchor(editor, anchor, delCount);
          }
        }
      }
    });
  }, [editor, maxLength]);

  return null;
};
