import type { ICompany } from '../company/company.type';
import type { IProjectAccountManager } from '../project/project-account-manager.type';
import type { IQuoteAccountManager } from '../quote-account-manager/quote-account-manager.type';
import type { IRole } from '../role/role.type';
import type { IThirdPartyConfig } from '../third-party/third-party-config.type';
import type { IToken } from '../token/token.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { PaginatedResponse } from '../pagination/paginated-response.type';
import type { ThirdParties } from '../third-party/third-party.type';
import type { IValidationStepValidator } from '../validation-step/validation-step-validator.type';
import type { IBankingPaymentResource } from '../banking/banking-payment-ressource.type';

import type { IUserCompany } from './user-company.type';
import type { IUserSettings } from './user-settings.type';

export enum PERSONNA {
  ADMINISTRATIVE_ASSISTANT = 'ADMINISTRATIVE_ASSISTANT',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  ACCOUNTANT = 'ACCOUNTANT',
  QUANTITY_SURVEYOR = 'QUANTITY_SURVEYOR',
  EXECUTIVE = 'EXECUTIVE',
}

export interface IUser {
  id: number;

  firstName: string;

  lastName: string;

  email: string;

  phone: string | null;

  auth0ID: string;

  isDisplayed: boolean;

  personna: PERSONNA | null;

  createdAt: Date;

  updatedAt: Date;

  userSettings?: IUserSettings;

  company?: ICompany;

  tokens?: IToken[];

  quoteAccountManagers?: IQuoteAccountManager[];

  projectAccountManagers?: IProjectAccountManager[];

  userCompany?: IUserCompany[];

  role?: IRole;

  thirdPartyConfigs?: IThirdPartyConfig<ThirdParties>[];

  validationStepValidators?: IValidationStepValidator[];

  bankingPaymentResources?: IBankingPaymentResource[];
}

export type IUserWithRelations = RequiredByKeys<IUser, 'company'>;

export type IUserPaginated = RequiredByKeys<
  IUser & { sendBillReminder: boolean; sendSupplierInvoiceValidationReminder: boolean },
  'role'
>;
export type UserPaginatedResponse = PaginatedResponse<IUserPaginated>;
