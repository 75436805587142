import { Card, ConfirmDeletionModal } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';
import { useDisclosure } from '@chakra-ui/react';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import { ContactAvailableModal } from '../modals/ContactAvailableModal';
import { ContactAddOrAssociateButton } from '../ContactAddOrAssociateButton';
import type { ProjectCreateForm } from '../../../project/forms/project-create-wizard';

import { ContactCardDetails } from './ContactCardDetails';

import { useContactDelete, useContactRoles, useContactsAssociateToClient } from 'features/contact/services/contact.api';
import { ContactUpsertModal } from 'features/contact/components/modals/ContactUpsertModal';

interface ContactCardProps {
  clientId: number;
}

export const ContactCard: FC<ContactCardProps> = ({ clientId }) => {
  const { t } = useTranslation(['global', 'contacts']);

  const contactRoles = useContactRoles();

  const contactDeleteMutation = useContactDelete();
  const contactsAssociateToClientMutation = useContactsAssociateToClient();

  const upsertModal = useDisclosure();
  const deleteModal = useDisclosure();
  const associationModal = useDisclosure();

  const [contactUpsert, setContactUpsert] = useState<IContact | null>(null);
  const [contactToDelete, setContactToDelete] = useState<IContact | null>(null);

  const form = useFormContext<ProjectCreateForm>();
  const { contacts } = useOnChangeValues(form, ['contacts']);

  const onUpsert = useCallback(
    (contact?: IContact) => {
      if (contact) {
        setContactUpsert(contact);
      } else {
        setContactUpsert(null);
      }
      upsertModal.onOpen();
    },
    [upsertModal],
  );

  const onDeleted = useCallback(
    async (contact: IContact) => {
      if (contact) {
        setContactToDelete(contact);
      } else {
        setContactToDelete(null);
      }
      deleteModal.onOpen();
    },
    [deleteModal],
  );

  const onDissociate = useCallback(
    async (contactId: string) => {
      form.setFormValues({
        contacts: [...(form.getFormValues().contacts ?? [])].filter((contact) => contact.entity.id !== contactId),
      });
    },
    [form],
  );

  const onContactDeletion = useCallback(async () => {
    if (contactToDelete) {
      await contactDeleteMutation.mutateAsync(contactToDelete);

      form.setFormValues({
        contacts: [...(form.getFormValues().contacts ?? [])].filter(
          (contact) => contact.entity.id !== contactToDelete.id,
        ),
      });
      deleteModal.onClose();
    }
  }, [contactToDelete, contactDeleteMutation, form, deleteModal]);

  const onUpsertSuccess = useCallback(
    async (contact: IContact, data: { isDefaultEmailRecipient?: boolean }) => {
      if (data.isDefaultEmailRecipient !== undefined) {
        await contactsAssociateToClientMutation.mutateAsync({
          id: clientId,
          dto: {
            contacts: [
              {
                id: contact.id,
                isDefaultEmailRecipient: data.isDefaultEmailRecipient,
              },
            ],
          },
        });
      }

      const newContactsList = [...(form.getFormValues().contacts ?? [])];
      const currentIndex = newContactsList.findIndex((c) => c.entity.id === contact.id);
      newContactsList[currentIndex === -1 ? newContactsList.length : currentIndex] = {
        entity: contact,
        isDefaultEmailRecipient:
          data.isDefaultEmailRecipient ?? newContactsList[currentIndex]?.isDefaultEmailRecipient ?? false,
      };
      form.setFormValues({
        contacts: newContactsList,
      });
    },
    [clientId, contactsAssociateToClientMutation, form],
  );

  const onDefaultEmailRecipientChange = useCallback(
    async (contactId: string, isDefaultEmailRecipient: boolean) => {
      const newContactsList = [...(form.getFormValues().contacts ?? [])];
      const currentIndex = newContactsList.findIndex((c) => c.entity.id === contactId);
      newContactsList[currentIndex].isDefaultEmailRecipient = isDefaultEmailRecipient;
      form.setFormValues({
        contacts: newContactsList,
      });
    },
    [form],
  );

  return (
    <Card
      w="100%"
      title={t('contacts:card.title')}
      topRightContent={<ContactAddOrAssociateButton onAssociate={associationModal.onOpen} onCreate={onUpsert} />}
    >
      <ContactCardDetails
        contacts={(contacts || []).map((c) => c.entity)}
        isContactDefaultEmailRecipient={(c) => !!contacts?.find((cc) => cc.entity.id === c.id)?.isDefaultEmailRecipient}
        onUpsert={onUpsert}
        onDelete={onDeleted}
        onDissociate={onDissociate}
        onDefaultEmailRecipientChange={onDefaultEmailRecipientChange}
        emptyState={t('contacts:emptyState.project')}
      />
      <ContactUpsertModal
        isOpen={upsertModal.isOpen}
        onSuccess={onUpsertSuccess}
        onClose={upsertModal.onClose}
        contact={contactUpsert}
        roles={contactRoles.data}
        hasIsDefaultEmailRecipient
      />
      <ConfirmDeletionModal
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        title={t('contacts:deletionModal.title')}
        action={t('contacts:deletionModal.action')}
        description={t('contacts:deletionModal.description')}
        onDeleted={onContactDeletion}
      />
      <ContactAvailableModal
        isOpen={associationModal.isOpen}
        onClose={associationModal.onClose}
        title={t('contacts:availableModal.title')}
        onSuccess={associationModal.onClose}
        onContactAssociated={(newContacts) => {
          form.setFormValues({
            contacts: [
              ...(form.getFormValues().contacts ?? []),
              ...newContacts.map((c) => ({
                entity: c,
                isDefaultEmailRecipient: c.isDefaultEmailRecipient,
              })),
            ],
          });
        }}
        clientId={clientId}
        contactsSelected={(contacts || []).map((c) => c.entity)}
      />
    </Card>
  );
};
