import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import { Card, GotoLink } from '@graneet/lib-ui';
import type { SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';

import { ProgressStatementField } from './ProgressStatementField';

export const AssociatedProgressStatementCard: FC<{
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}> = ({ supplierInvoice }) => {
  const { t } = useTranslation(['supplierInvoices']);

  const { progressStatement } = supplierInvoice;

  return progressStatement ? (
    <Card
      title={
        <Flex mb={3}>
          <Text mr={4}>{t('supplierInvoices:cards.progressStatementCard.title')}</Text>
          <GotoLink
            to={`/projects/${progressStatement.project?.id}/statements/progress-statements/${progressStatement.id}`}
            label={t('supplierInvoices:cards.progressStatementCard.goTo')}
          />
        </Flex>
      }
    >
      <ProgressStatementField progressStatement={progressStatement} />
    </Card>
  ) : null;
};
