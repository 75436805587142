import type {
  IFinalStatement,
  IFinalStatementConditionsResponseDTO,
  IFinalStatementCreationDTO,
  IFinalStatementSummaryDTO,
  IFinalStatementSummaryResponseDTO,
  IFinalStatementsVersionsDTO,
} from '@graneet/business-logic';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { FINAL_STATEMENT_FACTORY_NAME } from './final-statement.factory-name';

import { invalidateStatementRelatedQueries } from 'features/common/services/invalidate-statement-related-queries.util';
import { usePdfVersionsTanStackQuery } from 'features/pdf/hooks/usePdfVersions';
import { apiNew } from 'features/api/services/apiNew.service';

const FINAL_STATEMENT_PATH = '/final-statements';

const finalStatementKeyFactory = createQueryKeys(FINAL_STATEMENT_FACTORY_NAME, {
  getIsProjectIsReadyForFinalStatement: (projectId: number) => ({
    queryKey: [FINAL_STATEMENT_PATH, projectId],
    queryFn: () =>
      apiNew.get<never, IFinalStatementConditionsResponseDTO>(`${FINAL_STATEMENT_PATH}/check/${projectId}`),
  }),
  getLastByProject: (projectId: number) => ({
    queryKey: [FINAL_STATEMENT_PATH, projectId],
    queryFn: () => apiNew.get<never, IFinalStatement | null>(`${FINAL_STATEMENT_PATH}/last/${projectId}`),
  }),
  getVersionsByProject: (projectId: number) => ({
    queryKey: [FINAL_STATEMENT_PATH, projectId],
    queryFn: () => apiNew.get<never, IFinalStatementsVersionsDTO>(`${FINAL_STATEMENT_PATH}/${projectId}`),
  }),
  getSummary: (dto: IFinalStatementSummaryDTO) => ({
    queryKey: [FINAL_STATEMENT_PATH, dto],
    queryFn: () =>
      apiNew.post<IFinalStatementSummaryDTO, IFinalStatementSummaryResponseDTO>(`${FINAL_STATEMENT_PATH}/summary`, dto),
  }),
});

export function useIsProjectReadyForFinalStatement(projectId: number) {
  return useSuspenseQuery(finalStatementKeyFactory.getIsProjectIsReadyForFinalStatement(projectId));
}

export function useProjectLastFinalStatement(projectId: number) {
  return useSuspenseQuery(finalStatementKeyFactory.getLastByProject(projectId));
}

export function useProjectFinalStatementsVersions(projectId: number) {
  const response = useSuspenseQuery(finalStatementKeyFactory.getVersionsByProject(projectId));
  usePdfVersionsTanStackQuery(response.data, response.refetch);

  return response;
}

export function useFinalStatementSummary(dto: IFinalStatementSummaryDTO) {
  return useSuspenseQuery(finalStatementKeyFactory.getSummary(dto));
}

export function useFinalStatementCreate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'project']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IFinalStatementCreationDTO) =>
      apiNew.post<IFinalStatementCreationDTO, IFinalStatement>(FINAL_STATEMENT_PATH, dto),
    onError: () => {
      toast.error(t('project:finalStatement.errors.notSaved'));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('project:finalStatement.success'));
    },
  });
}
