import type { FC } from 'react';
import { useEffect, useCallback, useState } from 'react';
import { Form, useForm } from 'graneet-form';
import { Modal, useCurrency } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import type { ILibraryComponentWithRelations } from '@graneet/business-logic';

import { useLibraryComponentDelete } from '../../services/library-component.api';
import { LibraryComponentFields } from '../LibraryComponentFields';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useLibraryJobs } from 'features/library-job/services/library-job.api';
import { LibraryJobImpactedLibraryComponentCallout } from 'features/library-job/components/LibraryJobImpactedLibraryComponentCallout';

const FORM_ID = 'library-component-delete';

interface FormValues {}

interface LibraryComponentDeleteModalProps {
  libraryComponent: ILibraryComponentWithRelations;

  modalControls: UseDisclosureReturn;

  onLibraryComponentRemoved(): void;
}

export const LibraryComponentDeleteModal: FC<LibraryComponentDeleteModalProps> = ({
  libraryComponent,
  modalControls,
  onLibraryComponentRemoved,
}) => {
  const { t } = useTranslation(['library', 'global']);
  const { mapNumberToAmount } = useCurrency();

  const form = useForm<FormValues>({
    defaultValues: {
      ...libraryComponent,
      componentTypeId: libraryComponent.componentType.id,
      unitDisbursementExVAT: mapNumberToAmount(libraryComponent.unitDisbursementExVAT),
    },
  });

  const libraryJobs = useLibraryJobs({
    forcedFilters: {
      libraryComponentId: libraryComponent.id,
    },
    storageStrategy: 'state',
  });

  const libraryComponentDeleteMutation = useLibraryComponentDelete();

  const handleSubmit = useCallback(() => {
    libraryComponentDeleteMutation.mutate(libraryComponent.id, {
      onSuccess: () => {
        onLibraryComponentRemoved();
        modalControls.onClose();
      },
    });
  }, [libraryComponent.id, libraryComponentDeleteMutation, modalControls, onLibraryComponentRemoved]);

  const [isUsed, setIsUsed] = useState<null | boolean>(null);
  useEffect(() => {
    const getIsUsed = async () => {
      const response = await libraryJobs.fetchData({ page: 0, sort: {} });
      setIsUsed(response.data.length > 0);
    };

    getIsUsed();
  }, [libraryJobs]);
  const isIsUsedLoading = isUsed === null;

  return (
    <Modal title={t('library:removeLibraryComponentModal.title')} size="5xl" autoFocus={false} {...modalControls}>
      <QueryWrapper>
        <Form form={form} onSubmit={form.handleSubmit(handleSubmit)} id={FORM_ID}>
          {!isIsUsedLoading && (
            <>
              <Text mb={4}>{t('library:removeLibraryComponentModal.description')}</Text>
              <LibraryComponentFields readOnly />
            </>
          )}

          {isUsed && (
            <Box mt={5}>
              <LibraryJobImpactedLibraryComponentCallout colorScheme="red" libraryJobs={libraryJobs}>
                <Box>
                  <Trans t={t} i18nKey="library:removeLibraryJobModal.callout">
                    <Text as="span" fontWeight={600}>
                      displayed_data
                    </Text>
                    description_end
                  </Trans>
                </Box>
              </LibraryJobImpactedLibraryComponentCallout>
            </Box>
          )}

          <Modal.Close isDisabled={libraryComponentDeleteMutation.isPending} />

          <Modal.PrimaryButton
            isLoading={libraryComponentDeleteMutation.isPending || isIsUsedLoading}
            colorScheme="red"
            type="submit"
            form={FORM_ID}
          >
            {t('library:removeLibraryComponentModal.cta')}
          </Modal.PrimaryButton>
        </Form>
      </QueryWrapper>
    </Modal>
  );
};
