import { EMAIL_REGEX } from '@graneet/business-logic';

export const TEMPLATE_URL_BASE = 'https://cdn.graneet.fr/templates/';

export const TEMPLATE_URL = {
  LIBRARY_COMPONENTS: 'Graneet Template - Composants.xlsx',
  LIBRARY_JOBS: 'Graneet Template - Ouvrages.xlsx',
  CONTACTS: 'Graneet Template - Contacts.xlsx',
  SUPPLIERS: 'Graneet Template - Fournisseurs.xlsx',
  CLIENTS: 'Graneet Template - Clients.xlsx',
  SIMPLE_QUOTE: 'Graneet Template - Devis Simple.xlsx',
};

export const IMPORT_AMOUNT_REGEX = (precision: number) =>
  precision === 0 ? '^-?\\d+$' : `^-?\\d+(\\.\\d{1,${precision}})?$`;

export const IMPORT_EMAIL_REGEX = (allowEmpty = false) =>
  allowEmpty ? EMAIL_REGEX.replace(/^\^?(.*)\$$/, '^(|$1)$') : EMAIL_REGEX;

export const IMPORT_NUMERIC_REGEX = () => `^-?\\d+(\\.\\d{1,})?$`;
