import type { FC } from 'react';
import { useCallback } from 'react';
import { Flex, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import type { ISupplierInvoice } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { Button, Onboarding } from '@graneet/lib-ui';
import { useFormContext } from 'graneet-form';
import { useTranslation } from 'react-i18next';

import { useDirectPaymentContext } from '../contexts/DirectPaymentContext';
import type { ProgressStatementDirectPaymentForm } from '../../progress-statement/forms/progress-statement-direct-payment-form';

import { CreateOrEditDirectPaymentSupplierInvoiceModal } from './modals/CreateOrEditDirectPaymentSupplierInvoiceModal';
import { AssociateSupplierInvoiceModal } from './modals/AssociateSupplierInvoiceModal/AssociateSupplierInvoiceModal';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

export const DirectPaymentEmptyState: FC = () => {
  const { t } = useTranslation(['project']);
  const { selectedOrder, updateSupplierInvoice } = useDirectPaymentContext();

  const hasAccessToSupplierInvoiceModule = usePermissions([PERMISSION.ACCESS_SUPPLIER_INVOICE]);

  const createSupplierInvoiceModal = useDisclosure();
  const associateSupplierInvoiceModal = useDisclosure();

  const form = useFormContext<ProgressStatementDirectPaymentForm>();
  const onCreationSuccess = useCallback(
    (supplierInvoice: ISupplierInvoice) => {
      const { ordersSupplierInvoices } = form.getFormValues();
      updateSupplierInvoice(supplierInvoice);

      const updatedOrdersSupplierInvoices = ordersSupplierInvoices?.filter(
        ({ orderId }) => orderId !== selectedOrder?.id,
      );
      if (selectedOrder?.id) {
        updatedOrdersSupplierInvoices?.push({
          orderId: selectedOrder.id,
          supplierInvoiceId: supplierInvoice.id,
          isDisplayInProgressStatementPdf: supplierInvoice.isDisplayInProgressStatementPdf,
        });
      }

      form.setFormValues({
        ordersSupplierInvoices: updatedOrdersSupplierInvoices,
      });
    },
    [form, selectedOrder?.id, updateSupplierInvoice],
  );

  const createOrAssociateButtonsDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  return (
    <>
      <Flex p={4}>
        <Onboarding
          icon={<i className="ri-truck-line ri-4x" />}
          message={
            <VStack align="center">
              <>{t('project:directPayments.emptyState.message')}</>
              <strong>
                {selectedOrder?.name}
                {selectedOrder?.orderNumber && ` - ${selectedOrder.orderNumber}`}
              </strong>
            </VStack>
          }
          action={
            hasAccessToSupplierInvoiceModule && (
              <HStack gap={6}>
                <Button
                  variant="outline"
                  onClick={createSupplierInvoiceModal.onOpen}
                  {...createOrAssociateButtonsDisabledProps()}
                >
                  {t('project:directPayments.emptyState.createButton')}
                </Button>
                <Button onClick={associateSupplierInvoiceModal.onOpen} {...createOrAssociateButtonsDisabledProps()}>
                  {t('project:directPayments.emptyState.selectButton')}
                </Button>
              </HStack>
            )
          }
        />
      </Flex>

      <CreateOrEditDirectPaymentSupplierInvoiceModal
        modal={createSupplierInvoiceModal}
        onSubmitSuccess={onCreationSuccess}
      />
      <AssociateSupplierInvoiceModal
        key={`${associateSupplierInvoiceModal.isOpen}`}
        modal={associateSupplierInvoiceModal}
        onSubmit={onCreationSuccess}
      />
    </>
  );
};
