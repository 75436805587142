import { OptionsCard } from '@graneet/lib-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuoteUpdate } from '../../hooks/useQuoteUpdate';
import { useQuote } from '../../hooks/useQuote';

import { useStore } from 'store/store';

export const QuoteDisplayPDFOptionsCard = () => {
  const { t } = useTranslation(['quote']);
  const isEditable = useStore((store) => store.quoteEditable);

  const { quote } = useQuote();
  const quoteUpdate = useQuoteUpdate();

  const handleChange = useCallback(
    (propertyName: string, value: boolean) => {
      quoteUpdate({
        [propertyName]: value,
      });
    },
    [quoteUpdate],
  );

  return (
    <OptionsCard
      title={t('quote:drawer.details.displayPDFOptionsCard.title')}
      isEditable={isEditable}
      onChange={handleChange}
      options={[
        {
          label: t('quote:drawer.details.displayPDFOptionsCard.displayOptionalAnnexPDF'),
          name: 'displayOptionalAnnexPDF',
          defaultChecked: quote.getDisplayOptionalAnnexPDF(),
        },
        {
          label: t('quote:drawer.details.displayPDFOptionsCard.displayOptionalLinesPDF'),
          name: 'displayOptionalLinesPDF',
          defaultChecked: quote.getDisplayOptionalLinesPDF(),
        },
      ]}
    />
  );
};
