import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CellClassParams, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';
import isNil from 'lodash-es/isNil';

import { DenominationCell } from '../../components/columns/DenominationCell/DenominationCell';
import { DenominationEditorCell } from '../../components/columns/DenominationCell/Editor/DenominationEditorCell';
import type { AGColDef } from '../../types';

import { useQuoteBasicItemUpdateDenomination } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateDenomination';
import { useQuoteComponentUpdateDenomination } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateDenomination';
import { useQuoteComponentUpdateType } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateType';
import { useQuoteHiddenCostItemUpdateDenomination } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemUpdateDenomination';
import { useQuoteOptionalItemUpdateDenomination } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateDenomination';
import { useQuoteSubItemUpdateDenomination } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdateDenomination';
import { useQuoteLotUpdateDenomination } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateDenomination';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useDenominationColumnDef(isEditable: boolean) {
  const quoteLotUpdateDenomination = useQuoteLotUpdateDenomination();

  const quoteBasicItemUpdateDenomination = useQuoteBasicItemUpdateDenomination();

  const quoteOptionalItemUpdateDenomination = useQuoteOptionalItemUpdateDenomination();

  const quoteHiddenCostItemUpdateDenomination = useQuoteHiddenCostItemUpdateDenomination();

  const quoteSubItemUpdateDenomination = useQuoteSubItemUpdateDenomination();

  const quoteComponentUpdateDenomination = useQuoteComponentUpdateDenomination();
  const quoteComponentUpdateType = useQuoteComponentUpdateType();
  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'denomination',
      suppressAutoSize: true,
      suppressSizeToFit: true,
      width,
      index,
      resizable: true,
      headerName: label,
      editable: isEditable,
      sortable: false,
      field: 'content.denomination',
      cellClass: (params: CellClassParams) => {
        if (params.node.footer) return 'no-focus';
        return '';
      },
      cellRenderer: DenominationCell,
      cellEditor: DenominationEditorCell,
      cellEditorPopup: true,
      cellEditorPopupPosition: 'over',
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams<QuoteNodeObject>) => {
        const { key } = params.event;
        if ((key === 'Escape' || key === 'Tab') && params.editing) {
          params.api.tabToNextCell();
          const nextCell = params.api.getFocusedCell();
          if (nextCell) {
            params.api.startEditingCell({
              colKey: nextCell.column,
              rowIndex: nextCell.rowIndex,
            });
          }
          return true;
        }
        if (params.editing) {
          return true;
        }
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
      valueSetter: (params) => {
        const { newValue, data } = params;
        const { denomination } = newValue;
        const { componentTypeId } = newValue;
        const { autoSuggestFill } = newValue;

        if (autoSuggestFill === 'true') {
          return true;
        }

        if (
          params.oldValue?.denomination?.toString() === denomination?.toString() &&
          params.oldValue?.componentTypeId?.toString() === componentTypeId?.toString()
        ) {
          return false;
        }

        switch (data.content.type) {
          case 'QuoteLot':
            if (!isNil(denomination)) {
              executeCommand(quoteLotUpdateDenomination(data.id, denomination), quote);
            }
            return true;
          case 'QuoteComponent':
            if (!isNil(denomination) && !isNil(componentTypeId)) {
              executeCommand(
                quoteComponentUpdateDenomination(
                  data.id,
                  denomination,
                  componentTypeId === '' ? null : componentTypeId,
                ),
                quote,
              );
              return true;
            }
            if (!isNil(denomination)) {
              executeCommand(quoteComponentUpdateDenomination(data.id, denomination, undefined), quote);
              return true;
            }
            if (!isNil(componentTypeId)) {
              executeCommand(quoteComponentUpdateType(data.id, componentTypeId === '' ? null : componentTypeId), quote);
              return true;
            }
            return false;
          case 'QuoteBasicItem':
            if (!isNil(denomination)) {
              executeCommand(quoteBasicItemUpdateDenomination(data.id, denomination), quote);
            }
            return true;
          case 'QuoteHiddenCostItem':
            if (!isNil(denomination)) {
              executeCommand(quoteHiddenCostItemUpdateDenomination(data.id, denomination), quote);
            }
            return true;
          case 'QuoteOptionalItem':
            if (!isNil(denomination)) {
              executeCommand(quoteOptionalItemUpdateDenomination(data.id, denomination), quote);
            }
            return true;
          case 'QuoteSubItem':
            if (!isNil(denomination)) {
              executeCommand(quoteSubItemUpdateDenomination(data.id, denomination), quote);
            }
            return true;
          default:
            break;
        }

        return true;
      },
      valueGetter: (params) => ({
        denomination: params?.data?.content.denomination,
      }),
    }),
    [
      executeCommand,
      isEditable,
      quote,
      quoteBasicItemUpdateDenomination,
      quoteComponentUpdateDenomination,
      quoteComponentUpdateType,
      quoteHiddenCostItemUpdateDenomination,
      quoteLotUpdateDenomination,
      quoteOptionalItemUpdateDenomination,
      quoteSubItemUpdateDenomination,
    ],
  );
}
