import type { FC } from 'react';
import { Grid } from '@chakra-ui/react';
import { CheckboxField, FieldGroupLabel } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';

import type { AddressForm } from './AddressFields';
import { AddressFields } from './AddressFields';

export const BILLING_ADDRESS_PREFIX = 'billingAddress';

export interface AddressesWithBillingFieldsForm extends AddressForm {
  billingAddressAddress: string;

  billingAddressPostalCode?: string;

  billingAddressCity?: string;

  billingAddressCountry?: string;

  hasNoBillingAddress?: boolean;
}

interface AddressesWithBillingFieldsProps {
  isReadOnly?: boolean;

  twoLines?: boolean;
}

/**
 * Internally use AddressesWithBillingFieldsForm form
 */
export const AddressesWithBillingFields: FC<AddressesWithBillingFieldsProps> = ({ isReadOnly, twoLines }) => {
  const { t } = useTranslation(['global']);

  const form = useFormContext<AddressesWithBillingFieldsForm>();
  const { hasNoBillingAddress: hasNoBillingAddressLive } = useOnChangeValues(form, ['hasNoBillingAddress']);

  return (
    <>
      <Grid gap={2}>
        <FieldGroupLabel>{t('global:address.address')}</FieldGroupLabel>

        <AddressFields isReadOnly={isReadOnly} twoLines={twoLines} />

        <CheckboxField<AddressesWithBillingFieldsForm>
          label={t('global:address.billingAddressDescription')}
          name="hasNoBillingAddress"
          isDisabled={isReadOnly}
        />
      </Grid>

      {!hasNoBillingAddressLive && (
        <Grid gap={2}>
          <FieldGroupLabel>{t('global:address.billingAddress')}</FieldGroupLabel>

          <AddressFields prefix={BILLING_ADDRESS_PREFIX} isReadOnly={isReadOnly} twoLines={twoLines} />
        </Grid>
      )}
    </>
  );
};
