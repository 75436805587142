import type { FC } from 'react';

import { SimpleEditIcon } from '../../Icons';
import { actionTranslations } from '../ActionMenuTranslations';

import type { ActionProps } from './Action';
import { Action } from './Action';

export interface ActionEdit extends Omit<ActionProps, 'icon' | 'label'> {
  label?: Pick<ActionProps, 'label'>['label'];
}
export const Edit: FC<ActionEdit> = ({ label, ...otherProps }) => (
  <Action icon={<SimpleEditIcon />} {...otherProps} label={label || actionTranslations.update} />
);
