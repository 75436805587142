/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, HStack, VStack, Text } from '@chakra-ui/react';
import type { ILibraryComponent, ILibraryJob } from '@graneet/business-logic';
import { HorizontalLoader, useCurrency } from '@graneet/lib-ui';
import type { AbstractQuoteItemObject, QuoteFileObject } from '@org/quotation-lib';
import type { KeyboardEvent } from 'react';
import { useMemo, useCallback, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ResultLine } from './ResultLine';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export interface AutotCompleteResultsHandler {
  onKeyPress: (key: string, e: KeyboardEvent) => void;
}

export interface QuoteItem {
  id: string;
  nodeId: string;
  denomination: string | null;
  refCode: string | null;
  unit: string | null;
  note: string | null;
  unitFlatCostAmount: string | null;
  files?: QuoteFileObject[];
  type: AbstractQuoteItemObject['type'] | 'QuoteComponent';
  componentTypeId?: number | null;
  key?: string;
}

export type AutoCompleteResult = {
  quote: {
    items: QuoteItem[];
  };
  library: {
    items: Omit<ILibraryJob, 'createdAt' | 'updatedAt'>[];
    components: Omit<ILibraryComponent, 'createdAt' | 'updatedAt'>[];
  };
};

interface AutoCompleteResultsProps {
  isLoading: boolean;
  searchTerm: string;
  results?: AutoCompleteResult;
  onResultSelected: (
    item: QuoteItem | Omit<ILibraryJob, 'createdAt' | 'updatedAt'> | Omit<ILibraryComponent, 'createdAt' | 'updatedAt'>,
    type: 'quote' | 'libraryItem' | 'libraryComponent',
  ) => void;
  onNoResultSelected: () => void;
}

const Loader = () => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return isShown ? (
    <HStack
      width="100px"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top="0"
      left="50%"
      transform="translateX(-50%)"
    >
      <HorizontalLoader />
    </HStack>
  ) : null;
};

export const AutoCompleteResults = forwardRef<AutotCompleteResultsHandler, AutoCompleteResultsProps>(
  ({ isLoading, searchTerm, results, onResultSelected, onNoResultSelected }, ref) => {
    const { t } = useTranslation(['global', 'quote']);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const { formatAsStringAmount, formatAsAmount } = useCurrency();
    const { quote } = useQuote();

    const hasComponents = useCallback(
      (nodeId: string) => {
        if (!quote) return false;
        const node = quote.getTree().getNodeOrNull(nodeId);
        if (!node) return false;
        return node.hasChildren();
      },
      [quote],
    );

    const totalResultsLength = useMemo(
      () =>
        (results?.quote.items.length ?? 0) +
        (results?.library.components.length ?? 0) +
        (results?.library.items.length ?? 0),
      [results],
    );

    const handlePrev = useCallback(() => {
      setSelectedIndex((prevIndex) =>
        prevIndex !== null ? (prevIndex > 0 ? prevIndex - 1 : null) : totalResultsLength - 1,
      );
    }, [totalResultsLength]);

    const handleNext = useCallback(() => {
      setSelectedIndex((prevIndex) =>
        prevIndex !== null ? (prevIndex < totalResultsLength - 1 ? prevIndex + 1 : null) : 0,
      );
    }, [totalResultsLength]);

    const handleSelectedIndex = useCallback(
      (index: number) => {
        const allResults = [
          ...(results?.quote.items ? results.quote.items.map((i) => ({ ...i, type: 'quote' as const })) : []),
          ...(results?.library.items ? results.library.items.map((i) => ({ ...i, type: 'libraryItem' as const })) : []),
          ...(results?.library.components
            ? results.library.components.map((i) => ({ ...i, type: 'libraryComponent' as const }))
            : []),
        ];

        const selectedResult = allResults[index];
        const { type, ...item } = selectedResult;
        onResultSelected(
          item as unknown as
            | QuoteItem
            | Omit<ILibraryJob, 'createdAt' | 'updatedAt'>
            | Omit<ILibraryComponent, 'createdAt' | 'updatedAt'>,
          type,
        );
      },
      [results?.library.components, results?.library.items, results?.quote.items, onResultSelected],
    );

    const handleKeyPress = useCallback(
      (key: string, e: KeyboardEvent) => {
        if (totalResultsLength > 0) {
          e.preventDefault();
          e.stopPropagation();
        }
        if (key === 'ArrowUp') {
          handlePrev();
        } else if (key === 'ArrowDown') {
          handleNext();
        } else if (key === 'Enter') {
          if (selectedIndex !== null) {
            handleSelectedIndex(selectedIndex);
          } else {
            onNoResultSelected();
          }
        }
      },
      [handleNext, handlePrev, handleSelectedIndex, onNoResultSelected, selectedIndex, totalResultsLength],
    );

    useImperativeHandle(ref, () => ({
      onKeyPress: handleKeyPress,
    }));

    const handleOnResultClick = useCallback(
      (index: number) => () => {
        handleSelectedIndex(index);
      },
      [handleSelectedIndex],
    );

    if (isLoading) {
      return (
        <HStack width="100%" alignItems="center" height="2.375rem" justifyContent="center">
          <CircularProgress size="1.25rem" color="gray.500" isIndeterminate />
          <Text color="#1F2A37" fontSize="0.813rem" fontWeight={500}>
            {t('global:palette.loading')}
          </Text>
        </HStack>
      );
    }
    return results && totalResultsLength > 0 ? (
      <VStack width="100%" alignItems="flex-start" justifyContent="center" p="0.25rem" gap={0} position="relative">
        {isLoading && <Loader />}
        {results.quote.items.length > 0 && (
          <>
            <Box p="0.5rem">
              <Text color="greenBrand.baseTertiary" fontSize="11px" fontWeight={600} textTransform="uppercase">
                {t('quote:words.insideQuote')}
              </Text>
            </Box>
            {results.quote.items.map((item, index) => (
              <ResultLine
                key={`item-${item.id}`}
                isSelected={index === selectedIndex}
                denomination={item.denomination ?? ''}
                refCode={item.refCode ?? ''}
                onClick={handleOnResultClick(index)}
                unitDisbursementExVAT={formatAsStringAmount(item.unitFlatCostAmount ?? '')}
                unit={item.unit ?? ''}
                hasComponent={hasComponents(item.nodeId)}
                componentTypeId={item.componentTypeId}
                searchTerm={searchTerm}
              />
            ))}
          </>
        )}
        {(results.library.items.length > 0 || results.library.components.length > 0) && (
          <>
            <Box p="0.5rem">
              <Text color="greenBrand.baseTertiary" fontSize="11px" fontWeight={600} textTransform="uppercase">
                {t('quote:words.fromLibrary')}
              </Text>
            </Box>
            {results.library.items.map((item, index) => (
              <ResultLine
                key={`library-${item.id}`}
                isSelected={index + results.quote.items.length === selectedIndex}
                denomination={item.description ?? ''}
                refCode={item.refCode ?? ''}
                onClick={handleOnResultClick(index + results.quote.items.length)}
                unitDisbursementExVAT={formatAsAmount(item.unitDisbursementExVAT) ?? ''}
                unit={item.unit ?? ''}
                componentTypeId={null}
                hasComponent={(item.libraryJobComponents?.length ?? 0) > 0}
                searchTerm={searchTerm}
              />
            ))}
            {results.library.components.map((item, index) => (
              <ResultLine
                key={`component-${item.id}`}
                isSelected={index + results.quote.items.length + results.library.items.length === selectedIndex}
                denomination={item.description ?? ''}
                refCode={item.refCode ?? ''}
                onClick={handleOnResultClick(index + results.quote.items.length + results.library.items.length)}
                unitDisbursementExVAT={formatAsAmount(item.unitDisbursementExVAT) ?? ''}
                unit={item.unit ?? ''}
                componentTypeId={item.componentType?.id}
                searchTerm={searchTerm}
              />
            ))}
          </>
        )}
      </VStack>
    ) : (
      <HStack width="100%" alignItems="center" height="2.375rem" justifyContent="center">
        <Text>
          <i className="ri-search-2-line" />
        </Text>
        <Text color="#6C737" fontSize="0.813rem" fontWeight={500}>
          {t('global:palette.noResult')}
        </Text>
      </HStack>
    );
  },
);
