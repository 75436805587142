import type { FC } from 'react';
import { Section } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IProject } from '@graneet/business-logic';

import { useProjectSubProjectsWithStatements } from 'features/project/services/project.api';
import { FinalStatementStatementTable } from 'features/final-statement/components/FinalStatementStatementTable/FinalStatementStatementTable';

interface ViewProgressStatementsStepProps {
  project: IProject;
}

export const ViewProgressStatementsStep: FC<ViewProgressStatementsStepProps> = ({ project }) => {
  const { t } = useTranslation(['project']);

  const subProjectsWithStatements = useProjectSubProjectsWithStatements(project.id);

  return (
    <Section
      title={t('project:finalStatement.progressStatements.title')}
      description={t('project:finalStatement.progressStatements.description')}
    >
      <FinalStatementStatementTable subProjects={subProjectsWithStatements.data.subProjects} />
    </Section>
  );
};
