import type { ReactNode, FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { Form, useForm } from 'graneet-form';
import { uniq } from 'lodash-es';

import { GraneetButton } from '../../Quotation/Button/Button';
import { usePaginatedDataContext } from '../../PaginatedData/hooks/usePaginatedDataContext';
import type { Pagination } from '../../PaginatedTable/Pagination';
import { Modal } from '../../Modal/Modal';

import { listingLayoutTranslations } from './configureDefaultLabel';

const FORM_ID = 'filters';

const FilterModal: FC<{ isOpen: boolean; onClose: () => void; pagination?: Pagination; children: ReactNode }> = ({
  isOpen,
  onClose,
  children,
  pagination,
}) => {
  const { updateFilters: updateFiltersFromOldPagination, storage: storageFromOldPagination } =
    usePaginatedDataContext();
  const updateFilters = pagination ? pagination.updateFilters : updateFiltersFromOldPagination;

  const storage = useMemo(
    () => (pagination ? new URLSearchParams(pagination.queryParams) : storageFromOldPagination),
    [pagination, storageFromOldPagination],
  );

  const form = useForm({
    defaultValues: (() => {
      const initialValues: Record<string, any> = {};
      uniq(Array.from(storage.keys())).forEach((key: string) => {
        initialValues[key] = storage.getAll(key);
      });

      return initialValues;
    })(),
  });

  const onSubmit = useCallback(
    (formValues: Record<string, any>) => {
      updateFilters(formValues);
      onClose();
    },
    [onClose, updateFilters],
  );

  return (
    <Modal
      title={listingLayoutTranslations.filters.modal.title}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      scrollBehavior="inside"
    >
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)} id={FORM_ID}>
        <Flex direction="column" gap={5}>
          {children}
        </Flex>

        <Modal.CloseButton />
        <Modal.PrimaryButton type="submit" form={FORM_ID}>
          {listingLayoutTranslations.filters.modal.cta}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};

export const ListingLayoutFilterButton: FC<{ pagination?: Pagination; children: ReactNode }> = ({
  pagination,
  children,
}) => {
  const modal = useDisclosure();

  return (
    <Flex>
      <GraneetButton variant="ghost" size="sm" onClick={modal.onToggle} isActive={modal.isOpen}>
        <i className="ri-filter-3-fill" /> {listingLayoutTranslations.filters.cta}
      </GraneetButton>

      <FilterModal
        key={`${modal.isOpen}`} // Rerender modal on open to refresh form values
        onClose={modal.onClose}
        isOpen={modal.isOpen}
        pagination={pagination}
      >
        {children}
      </FilterModal>
    </Flex>
  );
};
