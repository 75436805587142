import type { FC } from 'react';
import { CUMULATIVE_INPUT_TYPE } from '@graneet/business-logic';
import type { NodeWithRelations } from '@graneet/lib-ui';
import { Price, useCurrency } from '@graneet/lib-ui';
import { useFormContext, useOnBlurValues } from 'graneet-form';

import { getDiscountCumulativeFieldName } from '../../forms/progress-statement-edit-item-form';
import { getIdFromContractId } from '../../services/progress-statement-tree.util';

import type { IContractTree } from 'features/progress-statement/hooks/useProgressStatementTree';

interface ProgressStatementTotalDiscountLabelProps {
  contract: NodeWithRelations<IContractTree>;
}

export const ProgressStatementTotalDiscountLabel: FC<ProgressStatementTotalDiscountLabelProps> = ({ contract }) => {
  const { mapAmountToNumber } = useCurrency();

  const contractId = getIdFromContractId(contract.id);
  const itemKey = getDiscountCumulativeFieldName(contractId, CUMULATIVE_INPUT_TYPE.AMOUNT);
  const form = useFormContext();
  const values = useOnBlurValues(form, [itemKey]);

  const cumulativeDiscountedAmount = values[itemKey] || 0;
  return <Price amount={mapAmountToNumber(-cumulativeDiscountedAmount)} />;
};
