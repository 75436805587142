import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { Quote, QuoteNode, QuoteNodeObject } from '@org/quotation-lib';
import { QuoteNodeImportCluster } from '@org/quotation-lib';
import { cloneDeep } from 'lodash-es';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';
import { mapQuoteNodeObjectToQuoteNodeDTO } from 'features/quotation/quote-common/mappers/quoteNodeMapper';

export function useQuoteSubItemDelete() {
  const { quoteNodeDeletesProxyApi, quoteNodeProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string | undefined): ICommand => {
      if (!nodeId) {
        throw new Error('nodeId is required');
      }
      return {
        execute(quote: Quote) {
          try {
            const quoteSubItem = quote.getTree().getQuoteSubItemOrThrow(nodeId);
            const node = quote.getTree().getNodeOrThrow(nodeId);
            this.oldValue = {
              node: cloneDeep(node),
            };
            quoteSubItem.deleteQuoteItem();
            quoteSetToStore(quote);

            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteNodeDeletesProxyApi
              .deleteQuoteNode({
                quoteId: quote.getId(),
                timestamp,
                clientRequestId,
                nodeId: quoteSubItem.getNodeOrThrow().getId(),
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
            return true;
          } catch (e: any) {
            return quoteError(e.message);
          }
        },
        undo(quote: Quote) {
          try {
            const quoteOldNode: QuoteNode = this.oldValue.node;
            const parent = quoteOldNode.getParentOrThrow();
            if (parent) {
              const parentNode = quote.getTree().getNodeOrThrow(parent.getId());
              const prevSibling = quoteOldNode.getPrevSibling();
              const nextSibling = quoteOldNode.getNextSibling();

              const getChildren = (node: QuoteNode, array: QuoteNodeObject[]): QuoteNodeObject[] => {
                const children = Array.from(node.getChildren().values());
                let newArray: QuoteNodeObject[] = [
                  ...array,
                  ...children.map((nodeToExport: QuoteNode) => nodeToExport.export()),
                ];
                if (children.length > 0) {
                  children.forEach((child: QuoteNode) => {
                    newArray = getChildren(child, newArray);
                  });
                }
                return newArray;
              };
              const children = getChildren(quoteOldNode, []);
              const cluster = {
                nodeObject: quoteOldNode.export(),
                childrenObject: children,
              };
              QuoteNodeImportCluster.execute(
                quote,
                cluster,
                parentNode.getId(),
                prevSibling ? prevSibling.getId() : null,
                nextSibling ? nextSibling.getId() : null,
              );
              const clientRequestId = uuid();
              const timestamp = new Date().toISOString();
              quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
              quoteNodeProxyApi
                .importCluster({
                  quoteId: quote.getId(),
                  timestamp,
                  clientRequestId,
                  parentId: parent.getId(),
                  prevSiblingId: prevSibling?.getId() || null,
                  nextSiblingId: nextSibling?.getId() || null,
                  nodeObject: mapQuoteNodeObjectToQuoteNodeDTO(cluster.nodeObject),
                  childrenObject: cluster.childrenObject.map((child) => mapQuoteNodeObjectToQuoteNodeDTO(child)),
                })
                .then(([err]) => {
                  if (err) {
                    quoteError();
                  }
                })
                .catch((err) => quoteError(err.message));
              quoteSetToStore(quote);
            }
            return true;
          } catch (e: any) {
            return quoteError(e.message);
          }
        },
      };
    },
    [quoteError, quoteNodeDeletesProxyApi, quoteNodeProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
