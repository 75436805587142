import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import type {
  IProject,
  ISupplierInvoiceListingResponseDTO,
  SupplierInvoicePaginatedResponse,
} from '@graneet/business-logic';
import { FEATURE_FLAGS, SUPPLIER_INVOICE_INFORMATION, SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import type { ButtonColorScheme, PaginatedTableProps, PaginationQuery } from '@graneet/lib-ui';
import {
  formatDataOrEmpty,
  formatDateOrEmpty,
  generateColorFromString,
  ListingLayout,
  PaginatedTable,
  PriceAdvanced,
  SimpleStatementIcon,
  Tooltip,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

import { SUPPLIER_INVOICES_STATUS_COLOR } from '../../constants/supplier-invoice.constant';
import { useSupplierInvoiceAvailableTags } from '../../services/supplier-invoice.api';
import { SupplierInvoiceComponentTypeBadge } from '../SupplierInvoiceComponentTypeBadge';
import { SupplierInvoiceProjectsCell } from '../SupplierInvoiceProjectsCell';
import { SupplierInvoiceStatusBadge } from '../badges/SupplierInvoicesStatusBadge';

import { TagsBadges } from 'features/common/components/TagsBadges';
import { UserAvatars } from 'features/user/components/UserAvatars';
import { formatUserDisplayName } from 'features/user/services/user.util';
import { useDisplayedUsers } from 'features/user/services/user.api';
import { useComponentTypes } from 'features/component-type/services/component-type.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

const TypeCell = ({ data }: { data?: ISupplierInvoiceListingResponseDTO }) =>
  data ? <SupplierInvoiceComponentTypeBadge supplierInvoice={data} /> : null;

const NameCell = ({ data }: { data?: ISupplierInvoiceListingResponseDTO }) =>
  data ? (
    <Box mb={2}>
      {formatDataOrEmpty(data.name)}
      <TagsBadges tags={data.tags} />
    </Box>
  ) : null;

const ProjectsCell = ({ data }: { data?: ISupplierInvoiceListingResponseDTO }) =>
  data ? <SupplierInvoiceProjectsCell supplierInvoiceProjects={data.supplierInvoiceProjects!} /> : null;

const StatusCell = ({ data }: { data?: ISupplierInvoiceListingResponseDTO }) =>
  data ? <SupplierInvoiceStatusBadge status={data.status} /> : null;

const ApproveCell = ({ data }: { data?: ISupplierInvoiceListingResponseDTO }) => {
  const { t } = useTranslation(['validationStep']);
  if (!data) {
    return null;
  }

  const validators =
    data.status === SUPPLIER_INVOICE_STATUS.TO_VALIDATE && data.currentValidationStep?.validators?.length
      ? data.currentValidationStep.validators.map(({ validator }) => validator)
      : [];

  return (
    <UserAvatars
      users={[
        ...validators,
        ...(data.currentValidationStep?.areAccountManagersIncluded &&
        data.status === SUPPLIER_INVOICE_STATUS.TO_VALIDATE
          ? [
              {
                id: 0,
                firstName: t('validationStep:projectManager'),
                lastName: '',
              },
            ]
          : []),
      ]}
    />
  );
};

const MissingInformationCell = ({ data }: { data?: ISupplierInvoiceListingResponseDTO }) => {
  const { t } = useTranslation(['supplierInvoices']);

  return data && data.hasMissingInformation ? (
    <Tooltip label={t('supplierInvoices:tooltips.cannotChangeToToPayBecauseMissingInformation')}>
      <WarningIcon color="yellow.500" />
    </Tooltip>
  ) : null;
};

type AvailableColumns =
  | 'invoiceNumber'
  | 'type'
  | 'name'
  | 'billingDate'
  | 'supplier'
  | 'paymentDate'
  | 'supplierInvoiceProjects'
  | 'amountExVAT'
  | 'status'
  | 'approve'
  | 'hasMissingInformation';

interface SupplierInvoiceListingProps {
  gridId: string;

  pagination: PaginationQuery<SupplierInvoicePaginatedResponse>;

  actions?: ReactNode;

  project?: IProject;

  rowAction?: (props: { data: ISupplierInvoiceListingResponseDTO | undefined }) => ReactNode;

  tableProps: Omit<
    PaginatedTableProps<SupplierInvoicePaginatedResponse>,
    'gridId' | 'pagination' | 'columnDefs' | 'zeroState' | 'emptyState'
  >;

  /**
   * Choose and customize displayed columns
   */
  customLayout: Partial<
    Record<
      AvailableColumns,
      {
        hide: boolean;
      }
    >
  >;
}

export const SupplierInvoiceListing: FC<SupplierInvoiceListingProps> = ({
  gridId,
  pagination,
  rowAction,
  actions,
  project,
  tableProps,
  customLayout,
}) => {
  const { t } = useTranslation(['supplierInvoices', 'global', 'validationStep']);

  const tags = useSupplierInvoiceAvailableTags();
  const componentTypes = useComponentTypes();
  const users = useDisplayedUsers();

  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);

  // @[ff: workflow-validation]
  const allStatus = hasWorkflowValidationFeatureFlag
    ? Object.keys(SUPPLIER_INVOICE_STATUS)
    : Object.keys(SUPPLIER_INVOICE_STATUS).filter((status) => status !== SUPPLIER_INVOICE_STATUS.TO_VALIDATE);
  const availableStatuses = useMemo(
    () =>
      (allStatus as Array<keyof typeof SUPPLIER_INVOICE_STATUS>).map((status) => ({
        label: t(`supplierInvoices:statuses.${status}`),
        value: SUPPLIER_INVOICE_STATUS[status],
        colorScheme: SUPPLIER_INVOICES_STATUS_COLOR[status],
      })),
    [allStatus, t],
  );

  const informationOptions = useMemo(
    () => [
      { label: t('supplierInvoices:incompleteInvoices'), value: SUPPLIER_INVOICE_INFORMATION.MISSING_INFORMATION },
      { label: t('supplierInvoices:duplicateInvoices'), value: SUPPLIER_INVOICE_INFORMATION.DUPLICATE_INVOICE },
    ],
    [t],
  );

  const availableTags = useMemo(
    () =>
      tags.data.map<{ value: string; label: string }>((tag) => ({
        value: tag,
        label: tag,
        badgeColor: generateColorFromString(tag),
      })),
    [tags.data],
  );

  const availableTypes = useMemo(
    () =>
      componentTypes.data
        .filter((type) => !type.isWorkforce)
        .map((type) => ({
          value: type.id.toString(),
          colorScheme: type.color,
          label: type.name,
          variant: 'rounded',
        })),
    [componentTypes.data],
  );

  const availableUsers = useMemo(
    () =>
      users.data.map<{ value: string; label: string; colorScheme: ButtonColorScheme }>((user) => ({
        value: user.id.toString(),
        colorScheme: 'gray' as const,
        label: formatUserDisplayName(user),
      })),
    [users],
  );

  const amountExVATCell = useCallback(
    ({ data }: { data?: ISupplierInvoiceListingResponseDTO }) =>
      data ? (
        <PriceAdvanced
          amount={
            project
              ? (data.supplierInvoiceProjects || []).find((sip) => sip.project?.id === project.id)?.amountExVAT || 0
              : data.amountExVAT
          }
        />
      ) : null,
    [project],
  );

  const isColumnIsDisplayed = useCallback(
    (columnName: AvailableColumns) => customLayout[columnName] !== undefined,
    [customLayout],
  );

  const isColumnHidden = useCallback(
    (columnName: AvailableColumns) => {
      // If the column is not in the object, do not display the column
      if (customLayout[columnName] === undefined) {
        return true;
      }

      return customLayout[columnName].hide;
    },
    [customLayout],
  );

  const columnDefs = useMemo<PaginatedTableProps<SupplierInvoicePaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'invoiceNumber',
        headerName: t('supplierInvoices:fields.invoiceNumber'),
        sortable: true,
        hide: isColumnHidden('invoiceNumber'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.invoiceNumber),
      },
      {
        field: 'type',
        headerName: t('supplierInvoices:fields.type'),
        sortable: true,
        hide: isColumnHidden('type'),
        cellRenderer: TypeCell,
      },
      {
        field: 'name',
        headerName: t('supplierInvoices:fields.name'),
        sortable: true,
        cellRenderer: NameCell,
        hide: isColumnHidden('name'),
        autoHeight: true,
      },
      {
        field: 'billingDate',
        headerName: t('supplierInvoices:fields.billingDate'),
        sortable: true,
        hide: isColumnHidden('billingDate'),
        valueFormatter: (v) => formatDateOrEmpty(v.data?.billingDate),
      },
      {
        field: 'supplier',
        headerName: t('supplierInvoices:fields.supplier'),
        sortable: true,
        hide: isColumnHidden('supplier'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.supplier?.name),
      },
      {
        field: 'paymentDate',
        headerName: t('supplierInvoices:fields.paymentDate'),
        hide: isColumnHidden('paymentDate'),
        sortable: true,
        valueFormatter: (v) => formatDateOrEmpty(v.data?.paymentDate),
      },
      {
        field: 'supplierInvoiceProjects',
        headerName: t('supplierInvoices:fields.supplierInvoiceProjects'),
        hide: isColumnHidden('supplierInvoiceProjects'),
        sortable: false,
        cellRenderer: ProjectsCell,
      },
      {
        field: 'amountExVAT',
        headerName: t('supplierInvoices:fields.amountExVAT'),
        sortable: true,
        cellRenderer: amountExVATCell,
      },
      {
        field: 'status',
        headerName: t('supplierInvoices:fields.status'),
        sortable: true,
        hide: isColumnHidden('status'),
        cellRenderer: StatusCell,
      },
      {
        field: 'approve' as any,
        headerName: t('validationStep:approver'),
        hide: isColumnHidden('approve'),
        cellRenderer: ApproveCell,
      },
      {
        field: 'hasMissingInformation',
        headerName: '',
        cellRenderer: MissingInformationCell,
        width: 20,
        resizable: false,
        hide: isColumnHidden('hasMissingInformation'),
      },
      {
        field: 'rowAction' as any,
        headerName: '',
        cellRenderer: rowAction,
        width: 20,
        resizable: false,
        hide: !rowAction,
      },
    ],
    [amountExVATCell, isColumnHidden, t, rowAction],
  );

  return (
    <ListingLayout
      pagination={pagination}
      search={{
        placeholder: t('supplierInvoices:actions.search'),
      }}
      filters={[
        isColumnIsDisplayed('status') && {
          name: 'status',
          type: 'checkbox',
          availableValues: availableStatuses,
          label: t('supplierInvoices:filters.status'),
        },
        isColumnIsDisplayed('hasMissingInformation') && {
          name: 'information',
          type: 'checkbox',
          availableValues: informationOptions,
          label: t('supplierInvoices:cards.informationsCard.title'),
        },
        {
          name: 'tags',
          type: 'multi',
          availableValues: availableTags,
          label: t('global:words.tags'),
          noValueMessage: t('global:tags.noLabel'),
          placeholder: t('global:words.c.select'),
        },
        isColumnIsDisplayed('type') && {
          name: 'type',
          type: 'multi',
          availableValues: availableTypes,
          label: t('supplierInvoices:filters.type'),
          placeholder: t('global:words.c.select'),
        },
        isColumnIsDisplayed('billingDate') && {
          name: 'billingDate',
          type: 'date',
          label: t('supplierInvoices:filters.date'),
        },
        isColumnIsDisplayed('approve') && {
          name: 'currentValidationStep',
          type: 'multi',
          availableValues: availableUsers,
          label: t('validationStep:approver'),
          placeholder: t('global:words.c.select'),
        },
      ]}
      actions={actions}
      content={
        <PaginatedTable
          gridId={gridId}
          pagination={pagination}
          columnDefs={columnDefs}
          zeroState={{
            icon: <SimpleStatementIcon boxSize={45} />,
            label: t('supplierInvoices:errors.noSupplierInvoice'),
          }}
          emptyState={{
            label: t('supplierInvoices:errors.noResult'),
          }}
          {...tableProps}
        />
      }
    />
  );
};
