import { useCallback } from 'react';

import { useStore } from 'store/store';

export function useQuoteCanBeLoad() {
  const quoteObjectFlat = useStore((state) => state.quote);
  const quoteTreeObjectFlat = useStore((state) => state.quoteTree);
  const quoteNodeObjectFlat = useStore((state) => state.quoteNode);

  return useCallback((): Boolean => {
    if (!quoteObjectFlat || !quoteTreeObjectFlat || !quoteNodeObjectFlat) {
      return false;
    }

    return true;
  }, [quoteNodeObjectFlat, quoteObjectFlat, quoteTreeObjectFlat]);
}
