import type { QuoteLotData } from '../types/QuoteLotData';
import type { QuoteData } from '../../quote/types/QuoteData';

export const QUOTE_LOT_KEY = {
  CODE: 'code',
  DESCRIPTION: 'description',
  NOTE: 'note',
};
/**
 * Construct the data which will be sent along
 * a lot-update signal
 */
export const getLotSignalData = (lotId: number, quoteData: QuoteData): QuoteLotData | null => {
  const lot = quoteData.relations[lotId];

  if (!lot) return null;

  return {
    components: quoteData.components, // TODO: improve because all components of quote are provided
    lot: quoteData.lots[lotId],
    isExpanded: quoteData.expandedLotsIds[lotId] ?? true,
    subLots: lot.lots.map((id) => quoteData.lots[id]),
    jobs: lot.jobs.map((id) => quoteData.jobs[id]),
  };
};
