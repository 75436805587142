import { useTranslation } from 'react-i18next';
import { Card, DATE_FORMAT_API, GraneetIconButton, GroupedBarChart, CustomTooltipChart } from '@graneet/lib-ui';
import { Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { useBankingInOutTransactionStats } from '../services/banking-account.api';
import { QueryWrapper } from '../../api/components/QueryWrapper';

const OFFSET_LENGTH = 3;
const GRAPH_LENGTH = 12;

const BankingInOutTransactionsCardInternal = ({
  startDate,
  selectedBankingConnectionIds,
}: {
  startDate: dayjs.Dayjs;
  selectedBankingConnectionIds: string[];
}) => {
  const { t } = useTranslation(['banking']);

  const accountBalances = useBankingInOutTransactionStats(
    useMemo(
      () => ({
        from: startDate.format(DATE_FORMAT_API),
        to: startDate.add(GRAPH_LENGTH, 'month').endOf('month').format(DATE_FORMAT_API),
        accountIds: selectedBankingConnectionIds,
      }),
      [startDate, selectedBankingConnectionIds],
    ),
  );

  return (
    <GroupedBarChart
      data={accountBalances.data.stats}
      columns={[
        {
          name: 'incomingAmount',
          color: 'blue.500',
          stackId: 'incoming',
        },
        {
          name: 'previsionalIncomingAmount',
          color: 'blue.500',
          stackId: 'incoming',
          isHatched: true,
        },
        {
          name: 'outComingAmount',
          color: 'red.300',
          stackId: 'outcoming',
        },
        {
          name: 'previsionalOutComingAmount',
          color: 'red.300',
          stackId: 'outcoming',
          isHatched: true,
        },
      ]}
      xAxis={{
        dataKey: 'date',
        typeFormat: 'date',
      }}
      yAxis={{
        typeFormat: 'money',
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      tooltip={(props) => {
        const isCurrentMonth = dayjs(props.value.date).isSame(dayjs(), 'month');

        return (
          <CustomTooltipChart
            {...props}
            label={dayjs(props.value.date).toDate().toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'long',
            })}
            legends={{
              incomingAmount: t('banking:bankingInOutTransactionsCard.incomingAmount'),
              ...(isCurrentMonth && {
                previsionalIncomingAmount: t('banking:bankingInOutTransactionsCard.previsionalIncomingAmount'),
              }),
              outComingAmount: t('banking:bankingInOutTransactionsCard.outComingAmount'),
              ...(isCurrentMonth && {
                previsionalOutComingAmount: t('banking:bankingInOutTransactionsCard.previsionalOutComingAmount'),
              }),
            }}
          />
        );
      }}
      legends={{
        incomingAmount: t('banking:bankingInOutTransactionsCard.incomingAmount'),
        previsionalIncomingAmount: t('banking:bankingInOutTransactionsCard.previsionalIncomingAmount'),
        outComingAmount: t('banking:bankingInOutTransactionsCard.outComingAmount'),
        previsionalOutComingAmount: t('banking:bankingInOutTransactionsCard.previsionalOutComingAmount'),
      }}
    />
  );
};

interface BankingInOutTransactionsCardProps {
  selectedBankingConnectionIds: string[];
}

export const BankingInOutTransactionsCard: FC<BankingInOutTransactionsCardProps> = ({
  selectedBankingConnectionIds,
}) => {
  const { t } = useTranslation(['banking']);

  const [startDate, setStartDate] = useState(dayjs().add(-GRAPH_LENGTH, 'month').startOf('month'));

  const onOffsetChange = useCallback(
    (offset: -1 | 1) => () => {
      setStartDate((v) => v.add(offset * OFFSET_LENGTH, 'month').startOf('month'));
    },
    [],
  );

  return (
    <Card
      title={t('banking:bankingInOutTransactionsCard.title')}
      subtitle={t('banking:bankingInOutTransactionsCard.subtitle')}
      topRightContent={
        <Flex>
          <GraneetIconButton icon="ri-arrow-left-s-line" color="ghost" onClick={onOffsetChange(-1)} />
          <GraneetIconButton icon="ri-arrow-right-s-line" color="ghost" onClick={onOffsetChange(1)} />
        </Flex>
      }
    >
      <Box h="20rem">
        <QueryWrapper>
          <BankingInOutTransactionsCardInternal
            startDate={startDate}
            selectedBankingConnectionIds={selectedBankingConnectionIds}
          />
        </QueryWrapper>
      </Box>
    </Card>
  );
};
