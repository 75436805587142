import type { FC } from 'react';
import { GridItem, Text, VStack } from '@chakra-ui/react';

import { SimpleSearchIcon } from '../../Icons';

import { RawCell } from './Cell/RawCell';

export interface NoResultRowProps {
  numberOfColumns: number;

  noResultLabel?: string;
}

export const NoResultRow: FC<NoResultRowProps> = ({ numberOfColumns, noResultLabel }) => (
  <GridItem colSpan={numberOfColumns} h="14.75em">
    <RawCell center h="100%">
      <VStack spacing="2em">
        <SimpleSearchIcon boxSize={10} stroke="primary" />
        <Text fontSize="0.950rem">{noResultLabel}</Text>
      </VStack>
    </RawCell>
  </GridItem>
);
