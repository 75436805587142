import { useMemo, type FC } from 'react';
import { v4 as uuid } from 'uuid';

import { FinanceIcon } from '../../../../Icons';
import { SimpleAddIcon, SimpleEqualIcon, SimpleSettingsIcon } from '../../../../Icons/v2';
import { quotationCardTranslations } from '../translations';
import { CardLineEnum, GenericCard, MaskEnum } from '../GenericCard';
import { useCurrency } from '../../../../Currency/CurrencyProvider';

type SellsheetAmountSummaryCardProps = {
  isEditable?: boolean;
  hasReversalOfLiability: boolean;
  salesDiscount?: {
    id: string;
    percentage: string | undefined;
    amount: string;
  };
  onAddSalesDiscountClick: () => void;
  onEditSalesDiscountClick: (id: string) => void;
  onRemoveSalesDiscountClick: (id: string) => void;
  onAddCustomDiscountClick: () => void;
  onEditCustomDiscountClick: (id: string) => void;
  onRemoveCustomDiscountClick: (id: string) => void;
  onAddProrataClick: () => void;
  onEditGlobalVatRate: () => void;
  onSwitchReversalOfLiability: () => void;
  customDiscounts: {
    id: string;
    name: string;
    amount: string;
  }[];
  vatBases: {
    rate: string;
    amount: string;
  }[];
  amountBeforeDiscountsExVat: string;
  amountAfterSaleDiscountExVat: string;
  amountExVat: string;
  amountIncVat: string;
};

export const SellsheetAmountSummaryCard: FC<SellsheetAmountSummaryCardProps> = ({
  salesDiscount,
  customDiscounts,
  vatBases,
  amountExVat,
  amountIncVat,
  hasReversalOfLiability,
  amountBeforeDiscountsExVat,
  amountAfterSaleDiscountExVat,
  isEditable = true,
  onAddCustomDiscountClick,
  onEditSalesDiscountClick,
  onRemoveSalesDiscountClick,
  onAddSalesDiscountClick,
  onEditCustomDiscountClick,
  onRemoveCustomDiscountClick,
  onAddProrataClick,
  onEditGlobalVatRate,
  onSwitchReversalOfLiability,
}) => {
  const { mapStringNumberToAmount } = useCurrency();

  const addDiscountMenu = useMemo(
    () =>
      isEditable
        ? {
            type: CardLineEnum.LINK as const,
            label: quotationCardTranslations.summary.addDiscounts,
            actions: [
              {
                type: 'group' as const,
                id: uuid(),
                items: [
                  {
                    id: uuid(),
                    name: quotationCardTranslations.summary.menuAddDiscount,
                    type: 'item' as const,
                    disabled: !!salesDiscount,
                    tooltip: quotationCardTranslations.summary.menuAddDiscountTooltip,
                    onClick: onAddSalesDiscountClick,
                  },
                ],
              },
              {
                type: 'item' as const,
                id: uuid(),
                name: quotationCardTranslations.summary.menuAddProrata,
                onClick: onAddProrataClick,
              },
              {
                type: 'item' as const,
                id: uuid(),
                name: quotationCardTranslations.summary.menuAddCustomDiscount,
                onClick: onAddCustomDiscountClick,
              },
            ],
            icon: <SimpleAddIcon />,
          }
        : null,
    [onAddCustomDiscountClick, onAddProrataClick, onAddSalesDiscountClick, salesDiscount, isEditable],
  );

  return (
    <GenericCard
      title={quotationCardTranslations.summary.title}
      groups={[
        {
          items: [
            {
              type: CardLineEnum.ITEM,
              name: 'amountBeforeDiscountsExVat',
              label: quotationCardTranslations.summary.totalBeforeDiscounts,
              isBold: true,
              value: mapStringNumberToAmount(amountBeforeDiscountsExVat),
              mask: MaskEnum.CURRENCY,
              isDisabled: false,
              isEditable: false,
              onBlur: undefined,
            },
            ...(salesDiscount
              ? [
                  {
                    type: CardLineEnum.ITEM as const,
                    name: 'salesDiscount',
                    label: salesDiscount.percentage
                      ? `${quotationCardTranslations.summary.totalAfterSaleDiscount} (${salesDiscount.percentage}%)`
                      : quotationCardTranslations.summary.totalAfterSaleDiscount,
                    mask: MaskEnum.CURRENCY as const,
                    value: mapStringNumberToAmount(salesDiscount.amount),
                    isDisabled: false as const,
                    isEditable: false as const,
                    onBlur: undefined,
                    actions: [
                      {
                        type: CardLineEnum.ITEM as const,
                        id: uuid(),
                        name: quotationCardTranslations.summary.menuEdit,
                        onClick: () => onEditSalesDiscountClick(salesDiscount.id),
                      },
                      {
                        type: CardLineEnum.ITEM as const,
                        id: uuid(),
                        name: quotationCardTranslations.summary.menuRemove,
                        color: 'danger' as const,
                        onClick: () => onRemoveSalesDiscountClick(salesDiscount.id),
                      },
                    ],
                  },
                ]
              : []),
            ...(customDiscounts.length === 0 && !!addDiscountMenu ? [addDiscountMenu] : []),
          ],
        },

        ...(customDiscounts.length > 0
          ? [
              {
                items: [
                  ...(salesDiscount
                    ? [
                        {
                          type: CardLineEnum.ITEM as const,
                          name: 'amountAfterSaleDiscountExVat',
                          label: quotationCardTranslations.summary.totalAfterSaleDiscount,
                          icon: <SimpleEqualIcon />,
                          mask: MaskEnum.CURRENCY as const,
                          value: mapStringNumberToAmount(amountAfterSaleDiscountExVat),
                          isDisabled: false as const,
                          isEditable: false as const,
                          onBlur: undefined,
                          isBold: true,
                        },
                      ]
                    : []),
                  ...customDiscounts.map((customDiscount) => ({
                    type: CardLineEnum.ITEM as const,
                    name: customDiscount.name,
                    label: customDiscount.name,
                    mask: MaskEnum.CURRENCY as const,
                    value: mapStringNumberToAmount(customDiscount.amount),
                    isDisabled: false as const,
                    isEditable: false as const,
                    onBlur: undefined,
                    actions: [
                      {
                        type: CardLineEnum.ITEM as const,
                        id: uuid(),
                        name: quotationCardTranslations.summary.menuEdit,
                        onClick: () => onEditCustomDiscountClick(customDiscount.id),
                      },
                      {
                        type: CardLineEnum.ITEM as const,
                        id: uuid(),
                        name: quotationCardTranslations.summary.menuRemove,
                        color: 'danger' as const,
                        onClick: () => onRemoveCustomDiscountClick(customDiscount.id),
                      },
                    ],
                  })),
                  ...(addDiscountMenu ? [addDiscountMenu] : []),
                ],
              },
            ]
          : []),
        {
          items: [
            {
              type: CardLineEnum.ITEM,
              name: 'amountExVat',
              label: quotationCardTranslations.summary.amountExVat,
              isBold: true,
              value: mapStringNumberToAmount(amountExVat),
              mask: MaskEnum.CURRENCY,
              isDisabled: false,
              isEditable: false,
              onBlur: undefined,
            },

            ...vatBases.map((vatBase, key) => ({
              type: CardLineEnum.ITEM as const,
              name: vatBase.rate,
              label: `${hasReversalOfLiability ? quotationCardTranslations.summary.vatCustomDiscount : quotationCardTranslations.summary.vat} (${vatBase.rate}%)`,
              mask: MaskEnum.CURRENCY as const,
              value: mapStringNumberToAmount(vatBase.amount),
              isDisabled: false as const,
              isEditable: false as const,
              actions:
                key === 0 && !hasReversalOfLiability && isEditable
                  ? [
                      {
                        type: CardLineEnum.ITEM as const,
                        id: uuid(),
                        name: quotationCardTranslations.summary.editGlobalVat,
                        icon: <SimpleSettingsIcon />,
                        onClick: onEditGlobalVatRate,
                      },
                      {
                        type: CardLineEnum.ITEM as const,
                        id: uuid(),
                        name: quotationCardTranslations.summary.enableReversalOfLiability,
                        icon: <FinanceIcon />,
                        onClick: onSwitchReversalOfLiability,
                      },
                    ]
                  : [],
              onBlur: undefined,
            })),
            ...(hasReversalOfLiability
              ? [
                  {
                    type: CardLineEnum.ITEM as const,
                    name: 'vatBase.rate',
                    label: quotationCardTranslations.summary.reversalOfLiability,
                    mask: MaskEnum.CURRENCY as const,
                    value: mapStringNumberToAmount('0'),
                    isDisabled: false as const,
                    isEditable: false as const,
                    actions: [
                      {
                        type: CardLineEnum.ITEM as const,
                        id: uuid(),
                        name: quotationCardTranslations.summary.disableReversalOfLiability,
                        icon: <SimpleSettingsIcon />,
                        onClick: onSwitchReversalOfLiability,
                      },
                    ],
                    onBlur: undefined,
                  },
                ]
              : []),
            {
              type: CardLineEnum.ITEM,
              name: 'amountIncVat',
              label: quotationCardTranslations.summary.amountIncVat,
              isBold: true,
              value: mapStringNumberToAmount(amountIncVat),
              mask: MaskEnum.CURRENCY,
              isDisabled: false,
              isEditable: false,
              onBlur: undefined,
            },
          ],
        },
      ]}
    />
  );
};
