import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { Card } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { PlanTypeBadge } from 'features/common/components/PlanTypeBadge';
import { UpsellModal } from 'features/common/components/UpsellModal';

export const BillReminderUpsellCard = () => {
  const { t } = useTranslation(['bill', 'global']);

  const modal = useDisclosure();

  return (
    <>
      <UpsellModal
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        title={t('bill:remindUpsell.modal.title')}
        description={t('bill:remindUpsell.modal.description')}
        advantages={t('bill:remindUpsell.modal.advantages', { returnObjects: true })}
        image={t('bill:remindUpsell.modal.image')}
        minimumPlan="advanced"
      />

      <Card
        variant="light-gray"
        onClick={modal.onOpen}
        _hover={{ cursor: 'pointer', bg: 'gray.50', transition: 'all 0.1s linear' }}
        transition="all 0.1s ease-in-out"
        px={3}
        py={2}
      >
        <Flex alignItems="center">
          <Text mr={2} color="primaryLight">
            {t('bill:remindUpsell.title')}
          </Text>
          <PlanTypeBadge plan="advanced" />
        </Flex>

        <Text fontSize="0.75rem">{t('bill:remindUpsell.description')}</Text>
      </Card>
    </>
  );
};
