import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrency } from '@graneet/lib-ui';

import type { ImportSpreadsheetField, ImportSpreadsheetRowHook } from 'features/import/components/SpreadsheetImport';
import { formatImportAmount } from 'features/import/utils/import.util';
import { IMPORT_AMOUNT_REGEX, IMPORT_NUMERIC_REGEX } from 'features/import/constants/import.constant';

export type ImportQuoteField = 'type' | 'code' | 'name' | 'unit' | 'unit_price' | 'quantity' | 'note';

export const useImportQuoteJobFields = (): ImportSpreadsheetField<ImportQuoteField>[] => {
  const { t } = useTranslation(['global', 'quote']);

  return useMemo<ImportSpreadsheetField<ImportQuoteField>[]>(
    () => [
      {
        label: t('quote:importModal.spreadsheetImport.fields.type'),
        key: 'type',
        fieldType: {
          type: 'select',
          options: [
            {
              label: t('quote:importModal.spreadsheetImport.types.JOB'),
              value: 'JOB',
            },
            {
              label: t('quote:importModal.spreadsheetImport.types.LOT'),
              value: 'LOT',
            },
          ],
        },
        alternateMatches: ['Type'], // Column name in template
        example: ' ',
      },
      {
        label: t('quote:importModal.spreadsheetImport.fields.code'),
        key: 'code',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['N°'],
        example: t('quote:importModal.spreadsheetImport.examples.code'),
      },
      {
        label: t('quote:importModal.spreadsheetImport.fields.name'),
        key: 'name',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Dénomination*'],
        example: t('quote:importModal.spreadsheetImport.examples.name'),
        validations: [
          {
            rule: 'required',
            errorMessage: t('global:spreadsheetImport.common.errorMandatory'),
          },
        ],
      },
      {
        label: t('quote:importModal.spreadsheetImport.fields.unit'),
        key: 'unit',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Unité*'],
        example: t('quote:importModal.spreadsheetImport.examples.unit'),
        // There is not validation because it's handled in row hook
      },
      {
        label: t('quote:importModal.spreadsheetImport.fields.quantity'),
        key: 'quantity',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Quantité*'],
        example: t('quote:importModal.spreadsheetImport.examples.quantity'),
        // There is not validation because it's handled in row hook
      },
      {
        label: t('quote:importModal.spreadsheetImport.fields.unitDisbursementExVAT'),
        key: 'unit_price',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Déboursé unitaire*'],
        example: t('quote:importModal.spreadsheetImport.examples.unitDisbursementExVAT'),
        // There is not validation because it's handled in row hook
      },
      {
        label: t('quote:importModal.spreadsheetImport.fields.note'),
        key: 'note',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Note'],
        example: t('quote:importModal.spreadsheetImport.examples.note'),
      },
    ],
    [t],
  );
};

const specialAttributes: ImportQuoteField[] = ['quantity', 'unit', 'unit_price'];
const allAttributes: ImportQuoteField[] = ['type', 'code', 'name', 'note', ...specialAttributes];

export const useImportQuoteJobRowHook = (): ImportSpreadsheetRowHook<ImportQuoteField> => {
  const { t } = useTranslation(['global']);
  const {
    currency: { precision },
  } = useCurrency();

  return (rowData, addError) => {
    const transformedData = { ...rowData };
    if (rowData.unit_price !== undefined) {
      transformedData.unit_price = formatImportAmount(rowData.unit_price.toString());
    }

    if (rowData.type === '' || rowData.type === undefined) {
      transformedData.type = 'JOB';
    }

    if (transformedData.type === 'LOT') {
      allAttributes.forEach((attribute) => {
        addError(attribute, {
          message: '',
          level: 'info',
        });
      });

      specialAttributes.forEach((specialAttribute) => {
        if (transformedData[specialAttribute]) {
          addError(specialAttribute, {
            message: t('global:spreadsheetImport.common.dataNotImported'),
            level: 'warning',
          });
        }
      });
    } else {
      // special attributes are required
      specialAttributes.forEach((specialAttribute) => {
        if (!transformedData[specialAttribute]) {
          if (!['quantity', 'unit_price'].includes(specialAttribute)) {
            addError(specialAttribute, {
              message: t('global:spreadsheetImport.common.errorMandatory'),
              level: 'error',
            });
          } else {
            addError(specialAttribute, {
              message: '',
              level: 'warning',
            });
          }
        }
      });

      // unit price is an amount
      if (
        transformedData.unit_price !== undefined &&
        !transformedData.unit_price.toString().match(IMPORT_AMOUNT_REGEX(precision))
      ) {
        addError('unit_price', {
          message: t('global:spreadsheetImport.common.errorAmount'),
          level: 'error',
        });
      }

      // quantity is a numeric value
      if (
        transformedData.quantity !== undefined &&
        !transformedData.quantity.toString().match(IMPORT_NUMERIC_REGEX())
      ) {
        addError('quantity', {
          message: t('global:spreadsheetImport.common.errorNumeric'),
          level: 'error',
        });
      }
    }

    return transformedData;
  };
};
