import { useEffect, useRef } from 'react';

import { PusherAdapter } from './SocketManager/adapters/PusherAdapter';

export function useSocketManager(
  appKey: string,
  authorizeUrl: string,
  authUrl: string,
  authToken: string,
): PusherAdapter {
  const pusherAdapterRef = useRef<PusherAdapter>(new PusherAdapter());
  useEffect(() => {
    const socketManager = pusherAdapterRef.current;
    socketManager.connect(appKey, 'eu', authorizeUrl, authUrl, authToken);
    return () => {
      if (socketManager) {
        socketManager.disconnect();
      }
    };
  }, [appKey, authToken, authUrl, authorizeUrl, pusherAdapterRef]);
  return pusherAdapterRef.current;
}
