import type { FC, ReactNode } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Flex, Box, HStack } from '@chakra-ui/react';
import { Title } from '@graneet/lib-ui';

export interface SectionCompanyLegalProps extends Omit<FlexProps, 'title'> {
  title: ReactNode;

  children: ReactNode;

  topContent?: ReactNode;

  description?: ReactNode;
}

export const SectionCompanyLegal: FC<SectionCompanyLegalProps> = ({
  children,
  title,
  topContent,
  description,
  ...props
}) => (
  <Flex direction="column" mb={8} {...props}>
    <HStack spacing={4} alignItems="flex-end">
      <Box flex={1}>
        <Title display="flex">
          {title}
          {topContent && <Flex pl={4}>{topContent}</Flex>}
        </Title>
        {description && <Box mt={1}>{description}</Box>}
      </Box>
    </HStack>
    <Box mt={topContent ? 4 : 3}>{children}</Box>
  </Flex>
);
