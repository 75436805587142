import { QuotationCallout as Callout, SellSheetAmountCard, SimpleAlertIcon, useCurrency } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { CheckType, ComputeBigNull } from '@org/quotation-lib';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

import { useQuote } from '../../hooks/useQuote';

import { useQuoteLotUpdateAmount } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateAmount';
import { useStore } from 'store/store';
import { updateQuoteAmountIsUnreachable } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';

export const QuoteSellSheetLotAmountCard: FC<{ nodeId: string }> = ({ nodeId }) => {
  const isEditable = useStore((store) => store.quoteEditable);

  const quoteLotUpdateAmount = useQuoteLotUpdateAmount();
  const { executeCommand } = useCommands();

  const { t } = useTranslation(['quote']);
  const { formatAsStringAmount } = useCurrency();
  const isAmountUnreachable = useStore((state) => state.quoteAmountIsUnreachable);

  const { quote } = useQuote();
  const quoteTree = quote.getTree();

  const hasHiddenCostItems = quoteTree.getQuoteHasHiddenCostItems();

  const quoteLot = quoteTree.getQuoteLotOrThrow(nodeId);

  const isAmountEditable = useMemo(
    () => isEditable && quoteLot.getFlatCostAmount() !== null && !quoteLot.getFlatCostAmount()?.eq(0),
    [quoteLot, isEditable],
  );

  const totalDisbursement = useMemo(
    () => ComputeBigNull.plus(quoteLot.getFlatCostAmount(), quoteLot.getShareOfHiddenCostAmount()),
    [quoteLot],
  );

  const overheadCosts = useMemo(
    () =>
      ComputeBigNull.minus(
        ComputeBigNull.mul(totalDisbursement, quoteLot.getMargin().getOverheadCosts()),
        totalDisbursement,
      ),
    [quoteLot, totalDisbursement],
  );

  const profitMargin = useMemo(
    () => ComputeBigNull.minus(quoteLot.getAmount(), ComputeBigNull.plus(totalDisbursement, overheadCosts)),
    [overheadCosts, quoteLot, totalDisbursement],
  );

  const shareOfHiddenCostAmount = useMemo(() => quoteLot.getShareOfHiddenCostAmount(), [quoteLot]);

  const quoteLotProfit = useMemo(
    () =>
      ComputeBigNull.minus(
        quoteLot.getAmount(),
        ComputeBigNull.plus(quoteLot.getFlatCostAmount(), quoteLot.getShareOfHiddenCostAmount()),
      ),
    [quoteLot],
  );

  const updateAmount = useCallback(
    (newAmount: string) => {
      executeCommand(quoteLotUpdateAmount(nodeId, newAmount), quote);
      setTimeout(() => updateQuoteAmountIsUnreachable(false), 5000);
    },
    [executeCommand, nodeId, quote, quoteLotUpdateAmount],
  );

  const amountUnreachableLabel = t('quote:drawer.sale.amountUnreachable', {
    amount: formatAsStringAmount(quoteLot.getAmount()?.toString() ?? '0'),
  });

  // TODO: ADD CONSTRAINS TO THE AMOUNT
  return (
    <>
      <SellSheetAmountCard
        isEditable={isAmountEditable}
        disbursement={CheckType.stringOrNull(quoteLot.getFlatCostAmount()) ?? '0'}
        hiddenCostAmount={hasHiddenCostItems ? CheckType.stringOrNull(shareOfHiddenCostAmount) : '0'}
        overheadCosts={CheckType.stringOrNull(overheadCosts) ?? '0'}
        profitMargin={CheckType.stringOrNull(profitMargin) ?? '0'}
        marginBenefit={CheckType.stringOrNull(quoteLotProfit) ?? '0'}
        totalAmountExVAT={CheckType.stringOrNull(quoteLot.getAmount()) ?? '0'}
        onTotalAmountChange={updateAmount}
      />

      {isAmountUnreachable && (
        <Callout color="red" icon={<SimpleAlertIcon />}>
          <Text>{amountUnreachableLabel}</Text>
        </Callout>
      )}
    </>
  );
};
