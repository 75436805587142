import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PriceAdvanced,
  formatDataOrEmpty,
  ListingLayout,
  SimpleStatementIcon,
  PaginatedTable,
  type PaginatedTableProps,
} from '@graneet/lib-ui';
import { Box } from '@chakra-ui/react';
import type {
  IOrderResponseDTO,
  ISupplierInvoiceListingResponseDTO,
  SupplierInvoicePaginatedResponse,
} from '@graneet/business-logic';
import { SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import type { IRowNode } from '@ag-grid-community/core';

import { SupplierInvoiceComponentTypeBadge } from 'features/supplier-invoice/components/SupplierInvoiceComponentTypeBadge';
import { useSupplierInvoices } from 'features/supplier-invoice/services/supplier-invoice.api';
import { SupplierInvoiceProjectsCell } from 'features/supplier-invoice/components/SupplierInvoiceProjectsCell';

interface SupplierInvoicePickerDetailProps {
  order: IOrderResponseDTO;

  onRowSelected: (rows: ISupplierInvoiceListingResponseDTO[]) => void;
}

const TypeCell = ({ data }: { data?: ISupplierInvoiceListingResponseDTO }) =>
  data ? <SupplierInvoiceComponentTypeBadge supplierInvoice={data} /> : null;

const ProjectsCell = ({ data }: { data?: ISupplierInvoiceListingResponseDTO }) =>
  data ? <SupplierInvoiceProjectsCell supplierInvoiceProjects={data.supplierInvoiceProjects || []} /> : null;

const AmountExVATCell = ({ data }: { data?: ISupplierInvoiceListingResponseDTO }) =>
  data ? <PriceAdvanced amount={data.amountExVAT} /> : null;

export const SupplierInvoicePickerDetail: FC<SupplierInvoicePickerDetailProps> = ({ order, onRowSelected }) => {
  const { t } = useTranslation(['orders', 'supplierInvoices']);

  const supplierInvoices = useSupplierInvoices(
    useMemo(
      () => ({
        forcedFilters: {
          status: [
            SUPPLIER_INVOICE_STATUS.PAID,
            SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID,
            SUPPLIER_INVOICE_STATUS.TO_PAY,
            SUPPLIER_INVOICE_STATUS.TO_PROCESS,
          ],
        },
        storageStrategy: 'state',
      }),
      [],
    ),
  );

  const columnDefs = useMemo<PaginatedTableProps<SupplierInvoicePaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'invoiceNumber',
        headerName: t('supplierInvoices:fields.invoiceNumber'),
        sortable: true,
      },
      {
        field: 'type',
        headerName: t('supplierInvoices:fields.type'),
        sortable: true,
        cellRenderer: TypeCell,
      },
      {
        field: 'name',
        headerName: t('supplierInvoices:fields.name'),
        sortable: true,
      },
      {
        field: 'supplier',
        headerName: t('supplierInvoices:fields.supplier'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.supplier?.name) ?? '',
      },
      {
        field: 'supplierInvoiceProjects' as any,
        headerName: t('supplierInvoices:fields.supplierInvoiceProjects'),
        cellRenderer: ProjectsCell,
      },
      {
        field: 'amountExVAT',
        headerName: t('supplierInvoices:fields.amountExVAT'),
        sortable: true,
        cellRenderer: AmountExVATCell,
      },
    ],
    [t],
  );

  const isRowSelectable = useCallback(
    (event: IRowNode<ISupplierInvoiceListingResponseDTO>) => {
      const nonSelectableIds = (order.ordersSupplierInvoices || []).map((i) => i.supplierInvoice.id);

      if (!nonSelectableIds) {
        return true;
      }
      return !nonSelectableIds.includes(event.data?.id ?? -1);
    },
    [order.ordersSupplierInvoices],
  );

  return (
    <Box h="40rem">
      <Box mb={3}>{t('orders:associate.pickOrCreate.body.pick')}</Box>

      <ListingLayout
        pagination={supplierInvoices}
        search={{
          placeholder: t('supplierInvoices:actions.search'),
        }}
        content={
          <>
            <PaginatedTable
              gridId="supplier-invoice-picker"
              columnDefs={columnDefs}
              pagination={supplierInvoices}
              zeroState={{
                icon: <SimpleStatementIcon boxSize={45} />,
                label: t('supplierInvoices:errors.noSupplierInvoice'),
              }}
              emptyState={{
                label: t('supplierInvoices:errors.noResult'),
              }}
              isRowSelectable={isRowSelectable}
              rowSelection="multiple"
              onRowSelected={(event) => {
                onRowSelected(event.api.getSelectedRows() ?? []);
              }}
              defaultColDef={{
                headerCheckboxSelection: false,
              }}
            />
          </>
        }
      />
    </Box>
  );
};
