import { useParams } from 'react-router-dom';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Box, Center, Flex, Wrap, WrapItem } from '@chakra-ui/react';
import { Logo, Steps, TeamBuildingIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IUserInvitationValidationDTO } from '@graneet/business-logic';

import { OnboardingSlogan } from 'features/onboarding/components/OnboardingSlogan/OnboardingSlogan';
import stars from 'features/onboarding/components/OnboardingSlogan/stars.svg';
import { OnboardingSignUp } from 'features/onboarding/components/OnboardingSignUp';
import type { OnboardingSignUpWizard } from 'features/onboarding/forms/OnboardingSignUpWizard';
import { useUserInvitation, useValidateUserInvitation } from 'features/onboarding/services/user-invitation.api';
import { EditOnboardingUserProfileStep } from 'screens/onboarding/_steps/EditOnboardingUserProfileStep';
import { EditOnboardingCongratulationScreen } from 'screens/onboarding/_steps/EditOnboardingCongratulationScreen';

export const UserInvitationsScreen: FC = () => {
  const { t } = useTranslation(['global', 'onboarding']);

  const { userInvitationId } = useParams<{ userInvitationId: string }>();

  const userInvitation = useUserInvitation(userInvitationId);

  const [displayCongratulationScreen, setDisplayCongratulationScreen] = useState(false);

  const [userCredentials, setUserCredentials] = useState<null | {
    email: string;
    password: string;
  }>();

  const validateUserInvitationMutation = useValidateUserInvitation();

  const onFinish = useCallback(
    async (stepsValues: OnboardingSignUpWizard) => {
      const dto: IUserInvitationValidationDTO = {
        firstName: stepsValues.userProfile.firstName,
        lastName: stepsValues.userProfile.lastName,
        personna: stepsValues.userProfile.personna,
        phone: stepsValues.userProfile.phoneNumber,
        password: userCredentials!.password,
      };

      await validateUserInvitationMutation.mutateAsync({ id: userInvitationId, dto });
      setDisplayCongratulationScreen(true);
    },
    [userCredentials, validateUserInvitationMutation, userInvitationId],
  );

  const onSignUp = useCallback(async (userCredentialsFormValues: { email: string; password: string }) => {
    setUserCredentials(userCredentialsFormValues);
  }, []);

  if (displayCongratulationScreen) {
    return <EditOnboardingCongratulationScreen isUserLoggedFromAnExistingAccount={false} />;
  }
  if (userCredentials) {
    return (
      <Steps<OnboardingSignUpWizard> onQuit={() => {}} onFinish={onFinish}>
        <Steps.Placeholder placement="top">
          <Flex
            boxSize="100%"
            backgroundImage={`url(${stars})`}
            backgroundPosition="center 100%"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            justifyContent="center"
            paddingTop="3rem"
          >
            <Logo display="inline-block" h="1.8rem" w="auto" />
          </Flex>
        </Steps.Placeholder>

        <Steps.Placeholder placement="bottom">
          <Center>
            <TeamBuildingIcon boxSize="15rem" />
          </Center>
        </Steps.Placeholder>

        <Steps.Step<OnboardingSignUpWizard> name="userProfile" title={t('onboarding:steps.userProfile.title')}>
          <EditOnboardingUserProfileStep lastStep userInformation={null} />
        </Steps.Step>

        <Steps.Placeholder placement="bottom">
          <Center>
            <TeamBuildingIcon boxSize="15rem" />
          </Center>
        </Steps.Placeholder>
      </Steps>
    );
  }

  return (
    <Box w="100vw" h="100vh">
      <Wrap w="100vw" spacing={0}>
        <WrapItem flexGrow={1} minW="30rem" bg="gray.100" h="100vh">
          <Flex
            boxSize="100%"
            backgroundImage={`url(${stars})`}
            backgroundPosition="center 100%"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            alignItems="start"
            paddingTop="13rem"
          >
            <OnboardingSlogan />
          </Flex>
        </WrapItem>

        <WrapItem flexGrow={1} minW="30rem" bg="white" h="100vh" alignItems="start" paddingTop="13rem">
          <OnboardingSignUp userInvitation={userInvitation.data} onSignUp={onSignUp} />
        </WrapItem>
      </Wrap>
    </Box>
  );
};
