import '@tmikeladze/react-cmdk/dist/cmdk.css';
import CommandPalette from '@tmikeladze/react-cmdk';
import { useCallback, type FC, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  EllipsisText,
  filterOnlyContaining,
  RichText,
  Tooltip,
  SimpleCloseIcon,
  useHotkeys,
  HorizontalLoader,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { TFunction } from 'i18next';
import { HStack, Image, Box, Flex, CircularProgress } from '@chakra-ui/react';

import type { SearchResult } from '../hooks/usePalette';
import { usePalette, usePaletteStore } from '../hooks/usePalette';
import { useDebounce } from '../hooks/useDebounce';
import { useLazyHelScoutArticles } from '../services/helpscout.api';

import { useUserCompanies } from 'features/company/services/company.api';
import { useSwitchCompany } from 'features/user/services/user.api';

import './palette.css';

export const getLabelFromType = (type: SearchResult['type'], t: TFunction<['global'], undefined>) => {
  switch (type) {
    case 'quote':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.quotes')}>
            <Box>
              <i className="ri-calculator-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    case 'project':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.projects')}>
            <Box>
              <i className="ri-building-2-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    case 'client':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.clients')}>
            <Box>
              <i className="ri-building-4-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    case 'supplier':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.suppliers')}>
            <Box>
              <i className="ri-truck-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    case 'contact':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.contacts')}>
            <Box>
              <i className="ri-user-3-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    case 'jobs':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.library')}>
            <Box>
              <i className="ri-archive-stack-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    case 'statement':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.salesInvoices')}>
            <Box>
              <i className="ri-file-text-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    case 'bill':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.bills')}>
            <Box>
              <i className="ri-money-euro-circle-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    case 'holdback':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.holdbacks')}>
            <Box>
              <i className="ri-lock-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    case 'order':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.orders')}>
            <Box>
              <i className="ri-shopping-cart-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    case 'supplierInvoice':
      return (
        <Badge colorScheme="greenBrand">
          <Tooltip label={t('global:nav.supplierInvoices')}>
            <Box>
              <i className="ri-receipt-line" />
            </Box>
          </Tooltip>
        </Badge>
      );
    default:
      return null;
  }
};

const Loader = () => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return isShown ? (
    <HStack
      width="100px"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top="8px"
      left="50%"
      transform="translateX(-50%)"
    >
      <HorizontalLoader />
    </HStack>
  ) : null;
};

export const Palette: FC = () => {
  const { t } = useTranslation(['global']);
  const history = useHistory();

  const open = usePaletteStore((state) => state.paletteIsOpen);
  const searchInput = usePaletteStore((state) => state.paletteSearchInput);
  const paletteLoading = usePaletteStore((state) => state.paletteLoading);
  const page = usePaletteStore((state) => state.palettePage);
  const setSearch = usePaletteStore((state) => state.paletteSetSearch);
  const setSearchInput = usePaletteStore((state) => state.paletteSetSearchInput);
  const setPage = usePaletteStore((state) => state.paletteSetPage);
  const setOpen = usePaletteStore((state) => state.paletteToggle);
  const setLoading = usePaletteStore((state) => state.paletteSetLoading);
  const [faqLoading, setFaqLoading] = useState(false);

  const userCompanies = useUserCompanies();
  const { data, isLoading } = usePalette();

  const lazyGetArticles = useLazyHelScoutArticles();

  const switchCompanyMutation = useSwitchCompany();

  const [faqResults, setFaqResults] = useState<
    {
      name: string;
      url: string;
      preview: string;
    }[]
  >([]);

  useHotkeys(
    ['ctrl+k'],
    () => {
      setOpen(!open);
    },
    [open],
  );

  const debounceSearch = useDebounce(async (input) => {
    setSearch(input);
    setFaqLoading(true);
    setFaqResults(await lazyGetArticles(input[0]));
    setFaqLoading(false);
  }, 1000);

  useEffect(() => {
    setLoading(false);
  }, [data, setLoading]);

  const goTo = useCallback((path: string) => () => history.push(path), [history]);

  const displayedCompanies = useMemo(
    () =>
      filterOnlyContaining(userCompanies.data, ['name'], searchInput).sort((companyA, companyB) =>
        companyA.name.localeCompare(companyB.name),
      ),
    [userCompanies.data, searchInput],
  );

  return (
    <CommandPalette
      onChangeSearch={(value) => {
        setLoading(true);
        setSearchInput(value);
        debounceSearch(value);
      }}
      onChangeOpen={setOpen}
      search={searchInput}
      isOpen={open}
      page={page}
      placeholder={t('global:palette.search')}
    >
      <CommandPalette.Page id="root">
        {searchInput.length !== 0 && (
          <CommandPalette.List key="search_result" heading={t('global:palette.searchResults')}>
            {(paletteLoading || faqLoading || isLoading) && <Loader />}
            {displayedCompanies.map((company, index) => (
              <CommandPalette.ListItem
                key={company.id}
                index={index}
                showType={false}
                onClick={() => {
                  switchCompanyMutation.mutateAsync(company.id.toString());
                  setPage('root');
                }}
              >
                <HStack spacing={2}>
                  {company.logo?.url && (
                    <Image
                      display="flex"
                      maxWidth="20px"
                      maxHeight="20px"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius={4}
                      src={company.logo.url}
                    />
                  )}
                  <EllipsisText>{company.name}</EllipsisText>
                </HStack>
              </CommandPalette.ListItem>
            ))}
            {data.map(({ id, type, link, name, code, ...rest }, index) => (
              <CommandPalette.ListItem
                key={`${type}-${id}`}
                index={displayedCompanies.length + index}
                onClick={goTo(link)}
                showType={false}
                {...rest}
              >
                <HStack spacing={2}>
                  {getLabelFromType(type, t)}
                  {code && <Badge colorScheme="purple">{code}</Badge>}
                  <RichText value={name} />
                </HStack>
              </CommandPalette.ListItem>
            ))}
            {faqResults.map((result, index) => (
              <CommandPalette.ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={`faq-${index}`}
                index={displayedCompanies.length + data.length + displayedCompanies.length + index}
                showType={false}
                onClick={() => window.open(result.url, '_blank')}
              >
                <HStack spacing={2} width="100%">
                  <Badge colorScheme="greenBrand">
                    <i className="ri-question-line" />
                  </Badge>
                  <EllipsisText>{result.name}</EllipsisText>
                </HStack>
              </CommandPalette.ListItem>
            ))}
          </CommandPalette.List>
        )}
        {data.length === 0 && displayedCompanies.length === 0 && paletteLoading && (
          <CommandPalette.List key="search" heading="">
            <CommandPalette.ListItem index={data.length + displayedCompanies.length + 1} showType={false} disabled>
              <HStack spacing={2}>
                <Flex width="20px" height="20px" alignItems="center" justifyContent="center">
                  <CircularProgress size={5} color="gray.800" isIndeterminate />
                </Flex>
                <EllipsisText>{t('global:palette.loading')}</EllipsisText>
              </HStack>
            </CommandPalette.ListItem>
          </CommandPalette.List>
        )}
        {data.length === 0 && displayedCompanies.length === 0 && !paletteLoading && (
          <CommandPalette.List key="noresult" heading="">
            <CommandPalette.ListItem index={0} showType={false} disabled>
              <HStack spacing={2}>
                <Flex width="20px" height="20px" alignItems="center" justifyContent="center">
                  <SimpleCloseIcon />
                </Flex>
                <EllipsisText>{t('global:palette.noResult')}</EllipsisText>
              </HStack>
            </CommandPalette.ListItem>
          </CommandPalette.List>
        )}
      </CommandPalette.Page>
    </CommandPalette>
  );
};
