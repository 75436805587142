// https://docs.powens.com/api-reference/user-connections/connections#connectionstate-values
export enum BANKING_POWENS_CONNECTION_STATUS {
  /**
   * An SCA process must be performed to resume the synchronization process.
   */
  SCARequired = 'SCARequired',

  /**
   * A web-based authentication process is required using the /webauth endpoint.
   */
  webauthRequired = 'webauthRequired',

  /**
   * Additional information is needed to resume synchronization, such as an OTP. Connections in this state have fields property.
   */
  additionalInformationNeeded = 'additionalInformationNeeded',

  /**
   * User validation is required on a third-party app or device (ex: digital key).
   */
  decoupled = 'decoupled',

  /**
   * User validation is being processed on our side. This state is temporary.
   */
  validating = 'validating',

  /**
   * An action is needed on the website by the user, synchronization is blocked.
   */
  actionNeeded = 'actionNeeded',

  /**
   * The password has expired and needs to be changed by the user before the synchronization can be retried.
   */
  passwordExpired = 'passwordExpired',

  /**
   * The authentication on website has failed and new credentials must be obtained from the user. Connections in this state have a fields property.
   */
  wrongpass = 'wrongpass',

  /**
   * The target website or API is temporarily blocking synchronizations due to rate limiting.
   */
  rateLimiting = 'rateLimiting',

  /**
   * The connector website or API is unavailable.
   */
  websiteUnavailable = 'websiteUnavailable',

  /**
   *  An internal error has occurred during the synchronization.
   */
  bug = 'bug',

  /**
   * The source is not supported on the connector.
   */
  notSupported = 'notSupported',
}

// https://docs.powens.com/api-reference/products/data-aggregation/bank-transactions#transactiontype-values
export enum BANKING_POWENS_TRANSACTION_TYPE {
  transfer = 'transfer',

  order = 'order',

  check = 'check',

  /**
   * Mandatory/voluntary deposits, contributions, money transfers
   */
  deposit = 'deposit',

  payback = 'payback',

  withdrawal = 'withdrawal',

  loan_repayment = 'loan_repayment',

  /**
   * Bank fees
   */
  bank = 'bank',

  card = 'card',

  deferred_card = 'deferred_card',

  /**
   * Monthly debit of a deferred card
   */
  summary_card = 'summary_card',

  unknown = 'unknown',

  market_order = 'market_order',

  /**
   * Fees regarding a market order
   */
  market_fee = 'market_fee',

  arbitrage = 'arbitrage',

  /**
   * Positive earnings from interests/coupons/dividends
   */
  profit = 'profit',

  /**
   * With opposition to a payback, a refund has a negative value
   */
  refund = 'refund',

  /**
   * Transfer from the e-commerce account (eg; Stripe) to the bank account
   */
  payout = 'payout',

  /**
   * Payment made with a payment method different from card
   */
  payment = 'payment',

  /**
   * Differs from bank type because it considers only tax/commission
   */
  fee = 'fee',
}

export const bankingTransactionTypesAvailableForUser: readonly [
  BANKING_POWENS_TRANSACTION_TYPE.transfer,
  BANKING_POWENS_TRANSACTION_TYPE.check,
  BANKING_POWENS_TRANSACTION_TYPE.deposit,
  BANKING_POWENS_TRANSACTION_TYPE.payback,
  BANKING_POWENS_TRANSACTION_TYPE.withdrawal,
  BANKING_POWENS_TRANSACTION_TYPE.card,
  BANKING_POWENS_TRANSACTION_TYPE.unknown,
] = [
  BANKING_POWENS_TRANSACTION_TYPE.transfer,
  BANKING_POWENS_TRANSACTION_TYPE.check,
  BANKING_POWENS_TRANSACTION_TYPE.deposit,
  BANKING_POWENS_TRANSACTION_TYPE.payback,
  BANKING_POWENS_TRANSACTION_TYPE.withdrawal,
  BANKING_POWENS_TRANSACTION_TYPE.card,
  BANKING_POWENS_TRANSACTION_TYPE.unknown,
];

type TypeAvailableForUser = (typeof bankingTransactionTypesAvailableForUser)[number];
export const BANKING_TRANSACTION_POWENS_TYPE_TO_USER_AVAILABLE_TYPES: Record<
  BANKING_POWENS_TRANSACTION_TYPE,
  TypeAvailableForUser
> = {
  [BANKING_POWENS_TRANSACTION_TYPE.transfer]: BANKING_POWENS_TRANSACTION_TYPE.transfer,
  [BANKING_POWENS_TRANSACTION_TYPE.check]: BANKING_POWENS_TRANSACTION_TYPE.check,
  [BANKING_POWENS_TRANSACTION_TYPE.deposit]: BANKING_POWENS_TRANSACTION_TYPE.deposit,
  [BANKING_POWENS_TRANSACTION_TYPE.withdrawal]: BANKING_POWENS_TRANSACTION_TYPE.withdrawal,
  [BANKING_POWENS_TRANSACTION_TYPE.card]: BANKING_POWENS_TRANSACTION_TYPE.card,

  [BANKING_POWENS_TRANSACTION_TYPE.payback]: BANKING_POWENS_TRANSACTION_TYPE.payback,
  [BANKING_POWENS_TRANSACTION_TYPE.refund]: BANKING_POWENS_TRANSACTION_TYPE.payback,

  [BANKING_POWENS_TRANSACTION_TYPE.order]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.loan_repayment]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.bank]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.deferred_card]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.summary_card]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.unknown]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.market_order]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.market_fee]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.arbitrage]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.profit]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.payout]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.payment]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
  [BANKING_POWENS_TRANSACTION_TYPE.fee]: BANKING_POWENS_TRANSACTION_TYPE.unknown,
};
