import type { FC, PropsWithChildren } from 'react';
import { Form, useForm } from 'graneet-form';

import { configureDefaultLabels } from './configureDefaultLabels';
import { CheckBoxFilters } from './CheckBoxFilters/CheckBoxFilters';
import { MultiSelectFilters } from './MultiSelectFilters/MultiSelectFilters';
import { DateFilters } from './DateFilters/DateFilters';
import { CheckBoxRangeFilters } from './CheckBoxFilters/CheckBoxRangeFilters';
import { AmountFilters } from './AmountFilters/AmountFilters';

const FiltersComponent: FC<PropsWithChildren> = ({ children }) => {
  const form = useForm();

  return (
    <Form form={form} style={{ flex: 1, height: '100%' }}>
      {children}
    </Form>
  );
};

export const Filters = Object.assign(FiltersComponent, {
  CheckBox: CheckBoxFilters,
  CheckBoxRange: CheckBoxRangeFilters,
  MultiSelectFilters,
  AmountSelect: AmountFilters,
  DateSelect: DateFilters,
  configureDefaultLabels,
});
