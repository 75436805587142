import { useCallback } from 'react';
import type { QuoteNode, QuoteTree, Quote } from '@org/quotation-lib';
import {
  ContentTypeGuard,
  MARGIN_TYPE,
  QuoteFile,
  QuoteNodeCreateChildUseCase,
  QuoteQuantityFormula,
  QuoteNodeImportManyCluster,
} from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';
import type { ILibraryComponent, ILibraryJob, ILibraryJobComponent } from '@graneet/business-logic';
import Big from 'big.js';
import { cloneDeep } from 'lodash-es';
import type { QuoteNodeClusterImportDTO } from '@org/graneet-bff-client';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import {
  useQuoteSetClientRequestsStore,
  StatusEnum,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';
import { mapQuoteNodeObjectToQuoteNodeDTO } from 'features/quotation/quote-common/mappers/quoteNodeMapper';
import { appendQuoteExpandedRow } from 'features/quotation/quote-common/store/quoteUpdateZustand';

type ParentType = 'lot' | 'optionalLot' | 'hiddenCostLot' | 'item';

export function useQuoteLibraryImportItem() {
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  const { quoteNodeProxyApi, quoteNodeBulkActionsProxyApi } = useQuotationProxyApis();

  const handleItem = useCallback(
    (libraryItem: ILibraryJob, tree: QuoteTree, parentId: string | null, parentType: ParentType) => {
      const parentNode = parentId === null ? tree.getRootNode() : tree.getNodeOrThrow(parentId);
      const lastItemId = parentNode.getLastChildItem()?.getId() ?? null;
      const firstLotId = parentNode.getFirstChildLot()?.getId() ?? null;

      let quoteNodeCreated: QuoteNode | undefined;

      switch (parentType) {
        case 'lot':
          quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteBasicItem(parentNode, lastItemId, firstLotId);
          break;
        case 'optionalLot':
          quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteOptionalItem(parentNode, lastItemId, firstLotId);
          break;
        case 'hiddenCostLot':
          quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteHiddenItem(parentNode, lastItemId, firstLotId);
          break;
        case 'item':
          quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteSubItem(parentNode, lastItemId, firstLotId);
          break;
        default:
          throw new Error('ParentType is not supported');
      }

      if (!quoteNodeCreated) {
        throw new Error('QuoteNode is not created');
      }

      const content = quoteNodeCreated.getContent();

      if (!ContentTypeGuard.isQuoteItem(content) && !ContentTypeGuard.isQuoteSubItem(content)) {
        throw new Error('Content is not an item');
      }

      content.updateProperties({
        unit: libraryItem.unit,
        refCode: libraryItem.refCode,
        denomination: libraryItem.description,
        note: libraryItem.note,
      });
      content.updateUnitFlatCostAmount(Big(libraryItem.unitDisbursementExVAT), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });
      content.updateTotalMargin(parentNode.getContent().getMargin().getTotalMargin(), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });
      libraryItem.libraryJobFile?.forEach((libraryJobFile) => {
        content.addFile(
          new QuoteFile({
            id: uuid(),
            name: libraryJobFile.file.name,
            url: libraryJobFile.file.url,
          }),
        );
      });
      appendQuoteExpandedRow(quoteNodeCreated.getId());
      appendQuoteExpandedRow(parentNode.getId());
      return quoteNodeCreated;
    },
    [],
  );

  const handleComponents = useCallback(
    (
      { quantity, libraryComponent }: { quantity: number | null; libraryComponent: ILibraryComponent },
      parentNode: QuoteNode,
    ) => {
      const lastChildId = parentNode.getLastChild()?.getId() ?? null;
      const quoteComponent = QuoteNodeCreateChildUseCase.createQuoteComponent(parentNode, lastChildId, null);

      if (!quoteComponent) {
        throw new Error('QuoteComponent is not created');
      }

      const componentContent = quoteComponent.getContent();

      if (!ContentTypeGuard.isQuoteComponent(componentContent)) {
        throw new Error('Content is not a QuoteComponent');
      }

      componentContent.updateProperties({
        refCode: libraryComponent.refCode,
        denomination: libraryComponent.description,
        unit: libraryComponent.unit,
      });

      if (libraryComponent.componentType) {
        componentContent.updateComponentTypeId(libraryComponent.componentType.id, {
          spreadUp: true,
          impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
        });
      }

      componentContent.updateUnitFlatCostAmount(Big(libraryComponent.unitDisbursementExVAT), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });

      componentContent.updateQuantityFormula(
        new QuoteQuantityFormula({
          quantity: quantity?.toString() || null,
        }),
        {
          spreadUp: true,
          impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
        },
      );
      return quoteComponent;
    },
    [],
  );

  return useCallback(
    (libraryItems: ILibraryJob[], parentId: string | null | undefined, parentType: ParentType): ICommand => {
      if (parentId === undefined) {
        throw new Error('parentId is required');
      }

      return {
        execute(quote: Quote) {
          try {
            const tree = quote.getTree();
            const items: QuoteNode[] = [];
            const components: QuoteNode[] = [];

            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();

            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });

            if (!this.oldValue) {
              libraryItems
                .sort((a: ILibraryJob, b: ILibraryJob) => -a.description.localeCompare(b.description))
                .forEach((libraryItem) => {
                  const quoteNodeCreated = handleItem(libraryItem, tree, parentId, parentType);
                  items.push(quoteNodeCreated);

                  if (libraryItem.libraryJobComponents?.length) {
                    libraryItem.libraryJobComponents
                      .sort(
                        (a: ILibraryJobComponent, b: ILibraryJobComponent) =>
                          -(a.libraryComponent.componentType?.name || '').localeCompare(
                            b.libraryComponent.componentType?.name || '',
                          ) || -a.libraryComponent.description.localeCompare(b.libraryComponent.description),
                      )
                      .forEach((libraryJobComponent) => {
                        const quoteComponent = handleComponents(libraryJobComponent, quoteNodeCreated);
                        components.push(quoteComponent);
                      });
                  }
                });

              const newCluster: QuoteNodeClusterImportDTO[] = items.map((item) => ({
                parentId: item.getParentOrThrow().getId(),
                prevSiblingId: item.getPrevSibling()?.getId() ?? null,
                nextSiblingId: item.getNextSibling()?.getId() ?? null,
                cluster: {
                  nodeObject: mapQuoteNodeObjectToQuoteNodeDTO(item.export()),
                  childrenObject: Array.from(item.getChildren().values()).map((child) =>
                    mapQuoteNodeObjectToQuoteNodeDTO(child.export()),
                  ),
                },
              }));

              quoteSetToStore(quote);
              quoteNodeProxyApi
                .importClusters({
                  quoteId: quote.getId(),
                  timestamp,
                  clientRequestId,
                  clusters: newCluster,
                })
                .then(([err]) => {
                  if (err) {
                    quoteError();
                  }
                })
                .catch((err) => quoteError(err.message));
              if (items.length > 0) {
                const nodes: QuoteNode[] = [];
                items.forEach((item: QuoteNode) => {
                  const node = quote.getTree().getNodeOrThrow(item.getId());
                  nodes.push(cloneDeep(node));
                });
                this.oldValue = {
                  nodes,
                };
              }
            } else if (this.oldValue.nodes.length > 0) {
              const clusters = this.oldValue.nodes.map((node: QuoteNode) => {
                const parent = node.getParentOrThrow();
                const prevSiblingId = node.getPrevSibling()?.getId();
                const nextSiblingId = node.getNextSibling()?.getId();
                return {
                  parentId: parent.getId(),
                  prevSiblingId: prevSiblingId ?? null,
                  nextSiblingId: nextSiblingId ?? null,
                  cluster: {
                    nodeObject: node.export(),
                    childrenObject: Array.from(node.getChildren().values()).map((item) => item.export()),
                  },
                };
              });
              QuoteNodeImportManyCluster.execute(quote, clusters);
              quoteSetToStore(quote);
              quoteNodeProxyApi
                .importClusters({
                  quoteId: quote.getId(),
                  timestamp,
                  clientRequestId,
                  clusters,
                })
                .then(([err]) => {
                  if (err) {
                    quoteError();
                  }
                })
                .catch((err) => quoteError(err.message));
            }
            return true;
          } catch (err: any) {
            return quoteError(err.message);
          }
        },
        undo(quote: Quote) {
          try {
            this.oldValue.nodes.forEach((row: QuoteNode) => {
              if (row.getContent().getType() === 'QuoteBasicItem') {
                const quoteBasicItem = quote.getTree().getQuoteBasicItemOrThrow(row.getId());
                quoteBasicItem.deleteQuoteItem();
              } else if (row.getContent().getType() === 'QuoteLot') {
                const quoteLot = quote.getTree().getQuoteLotOrThrow(row.getId());
                quoteLot.deleteQuoteLot();
              } else if (row.getContent().getType() === 'QuoteOptionalItem') {
                const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(row.getId());
                quoteOptionalItem.deleteQuoteItem();
              } else if (row.getContent().getType() === 'QuoteHiddenCostItem') {
                const quoteHiddenCostItem = quote.getTree().getQuoteHiddenCostOrThrow(row.getId());
                quoteHiddenCostItem.deleteQuoteItem();
              } else if (row.getContent().getType() === 'QuoteComponent') {
                const quoteComponent = quote.getTree().getQuoteComponentOrThrow(row.getId());
                quoteComponent.deleteQuoteComponent();
              } else if (row.getContent().getType() === 'QuoteSubItem') {
                const quoteSubItem = quote.getTree().getQuoteSubItemOrThrow(row.getId());
                quoteSubItem.deleteQuoteItem();
              }
            });
            quoteSetToStore(quote);

            if (this.oldValue.nodes.length > 0) {
              const clientRequestId = uuid();
              const timestamp = new Date().toISOString();
              quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
              quoteNodeBulkActionsProxyApi
                .handleBulkDeleteQuoteNode({
                  quoteId: quote.getId(),
                  timestamp,
                  clientRequestId,
                  nodesId: this.oldValue.nodes.map((row: QuoteNode) => row.getId()),
                })
                .then(([err]) => {
                  if (err) {
                    quoteError();
                  }
                })
                .catch((err) => quoteError(err.message));
              return true;
            }
          } catch (err: any) {
            return quoteError(err.message);
          }
          return true;
        },
      };
    },
    [
      handleComponents,
      handleItem,
      quoteError,
      quoteNodeBulkActionsProxyApi,
      quoteNodeProxyApi,
      quoteSetClientRequestToStore,
      quoteSetToStore,
    ],
  );
}
