import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Flex, Stat, StatLabel, StatNumber, useDisclosure } from '@chakra-ui/react';
import type { SimpleTableProps } from '@graneet/lib-ui';
import { ActionMenu, Date, PriceAdvanced, Price, Progress, SimpleTable } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IBill, IPaymentWithRelations } from '@graneet/business-logic';
import { canPaymentBeDeleted, canPaymentBeUpdated } from '@graneet/business-logic';

import { BillPayModal } from '../modals/BillPayModal';

import { useBillPayments, useDeletePayment } from 'features/payment/services/payment.api';

const PaidAtCell = ({ data }: { data?: IPaymentWithRelations }) => (data ? <Date>{data.paidAt}</Date> : null);

const AmountPaidIncVATCell = ({ data }: { data?: IPaymentWithRelations }) =>
  data ? <PriceAdvanced amount={data.amountPaidIncVAT} /> : null;

const MenuCell = ({ data }: { data?: IPaymentWithRelations }) => {
  const { t } = useTranslation(['global', 'bill']);

  const modal = useDisclosure();

  const deletePaymentMutation = useDeletePayment();

  const handleDeletePayment = useCallback(() => {
    if (!data) return;

    deletePaymentMutation.mutate({
      id: data.id,
      bill: data.bill,
    });
  }, [data, deletePaymentMutation]);

  const canBeUpdated = useMemo(() => {
    if (!data) {
      return {
        isDisabled: true,
        tooltip: undefined,
      };
    }

    const state = canPaymentBeUpdated(data);

    if (state.causes.includes('accounting')) {
      return {
        isDisabled: true,
        tooltip: t('bill:tooltips.cannotUpdateBecauseAccounting'),
      };
    }
    if (state.causes.includes('associated-bill-lost')) {
      return {
        isDisabled: true,
        tooltip: undefined,
      };
    }
    if (state.causes.length > 0) {
      throw new Error('Unhandled error');
    }

    return {
      isDisabled: false,
      tooltip: undefined,
    };
  }, [data, t]);

  const canBeDeleted = useMemo(() => {
    if (!data) {
      return {
        isDisabled: true,
        tooltip: undefined,
      };
    }

    const state = canPaymentBeDeleted(data);

    if (state.causes.includes('accounting')) {
      return {
        isDisabled: true,
        tooltip: t('bill:tooltips.cannotDeleteBecauseAccounting'),
      };
    }
    if (state.causes.length > 0) {
      throw new Error('Unhandled error');
    }

    return {
      isDisabled: false,
      tooltip: undefined,
    };
  }, [data, t]);

  return data ? (
    <>
      <ActionMenu>
        <ActionMenu.Edit onClick={modal.onOpen} isDisabled={canBeUpdated.isDisabled} tooltip={canBeUpdated.tooltip} />
        <ActionMenu.Delete
          onClick={handleDeletePayment}
          isDisabled={canBeDeleted.isDisabled}
          tooltip={canBeDeleted.tooltip}
        />
      </ActionMenu>

      <BillPayModal bill={data.bill} modal={modal} payment={data} />
    </>
  ) : null;
};

interface BillPaymentsTableProps {
  bill: IBill;
}

export const BillPaymentsTable: FC<BillPaymentsTableProps> = ({ bill }) => {
  const { t } = useTranslation(['bill']);

  const billPayments = useBillPayments(bill.id);

  const columnDefs = useMemo<SimpleTableProps<IPaymentWithRelations>['columnDefs']>(
    () => [
      {
        field: 'type',
        headerName: t('bill:payment.fields.type'),
        valueFormatter: (props) => (props.data?.type ? t(`bill:payment.types.${props.data?.type}`) : ''),
        flex: 1,
        sortable: true,
      },
      {
        field: 'paidAt',
        headerName: t('bill:payment.fields.paidAt'),
        cellRenderer: PaidAtCell,
        sortable: true,
        flex: 1,
      },
      {
        field: 'amountPaidIncVAT',
        headerName: t('bill:payment.fields.amountPaidIncVAT'),
        cellRenderer: AmountPaidIncVATCell,
        sortable: true,
        flex: 1,
      },
      {
        field: 'menu' as any,
        headerName: '',
        cellRenderer: MenuCell,
        width: 20,
      },
    ],
    [t],
  );

  const alreadyPaid = bill.totalToBePaidIncVAT - bill.remainingToBePaidIncVAT;

  const percentage = bill.totalToBePaidIncVAT ? (alreadyPaid / bill.totalToBePaidIncVAT) * 100 : 0;

  return (
    <Flex direction="column" gap={6}>
      <Flex direction="column" gap={3}>
        <Flex>
          <Stat>
            <StatLabel>{t('bill:fields.paidIncVAT')}</StatLabel>
            <StatNumber>
              <Price amount={alreadyPaid} />
            </StatNumber>
          </Stat>

          <Stat>
            <StatLabel>{t('bill:fields.totalToBePaidIncVAT')}</StatLabel>
            <StatNumber>
              <Price amount={bill.totalToBePaidIncVAT} />
            </StatNumber>
          </Stat>

          <Stat>
            <StatLabel>{t('bill:fields.remainingToBePaidIncVAT')}</StatLabel>
            <StatNumber>
              <Price amount={bill.remainingToBePaidIncVAT} />
            </StatNumber>
          </Stat>
        </Flex>

        <Progress
          value={percentage}
          colors={{ progression: percentage ? 'blue.500' : 'blue.100' }}
          w="100%"
          mode="hover"
        />
      </Flex>

      <SimpleTable
        gridId="payment"
        rowData={billPayments.data.data}
        columnDefs={columnDefs}
        overlayNoRowsTemplate={t('bill:payment.noPayments')}
      />
    </Flex>
  );
};
