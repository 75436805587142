import type { FC } from 'react';
import { Flex, Grid, Text } from '@chakra-ui/react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { MaskEnum, PriceAdvanced, QuotationMaskedField } from '@graneet/lib-ui';
import { QUOTE_SALES_DISCOUNT_TYPE } from '@org/quotation-lib';
import { useTranslation } from 'react-i18next';

import { useSaleDiscountTotal } from '../hooks/useSaleDiscountTotal';

import type { SaleDiscountForm } from './modals/AddSaleDiscountModal';

import { Rule } from 'features/form/rules/Rule';

interface SaleDiscountTotalsProps {
  totalBeforeSaleDiscountExVAT: Big;

  onFieldBlur?(): void;
}

export const SaleDiscountTotals: FC<SaleDiscountTotalsProps> = ({
  totalBeforeSaleDiscountExVAT,
  onFieldBlur = () => {},
}) => {
  const { t } = useTranslation(['discount']);
  const totalExVAT = useSaleDiscountTotal(totalBeforeSaleDiscountExVAT);

  const form = useFormContext<SaleDiscountForm>();
  const { saleDiscountType } = useOnChangeValues(form, ['saleDiscountType']);

  return (
    <Flex bg="gray.100" borderRadius="0.5rem" direction="column" px={4} py={2}>
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <Text fontWeight={600}>{t('discount:totalExVATBeforeDiscount')}</Text>

        <PriceAdvanced textAlign="right" fontWeight={600} amount={totalBeforeSaleDiscountExVAT.toNumber()} />

        {saleDiscountType === QUOTE_SALES_DISCOUNT_TYPE.AMOUNT && (
          <>
            <Text>{t('discount:amountLabel')}</Text>
            <Flex justifyContent="flex-end">
              <QuotationMaskedField<SaleDiscountForm>
                isEditable
                name="saleDiscountAmountExVAT"
                onBlur={onFieldBlur}
                mask={MaskEnum.CURRENCY}
                width="7.5rem"
                options={{
                  forceNegative: true,
                }}
              >
                <Rule.IsRequired />
              </QuotationMaskedField>
            </Flex>
          </>
        )}
        {saleDiscountType === QUOTE_SALES_DISCOUNT_TYPE.PERCENTAGE && (
          <>
            <Text>{t('discount:percentLabel')}</Text>
            <Flex justifyContent="flex-end">
              <QuotationMaskedField<SaleDiscountForm>
                isEditable
                name="saleDiscountPercentage"
                onBlur={onFieldBlur}
                mask={MaskEnum.PERCENT}
                width="7.5rem"
              >
                <Rule.IsRequired />
              </QuotationMaskedField>
            </Flex>
          </>
        )}

        <Text fontWeight={600}>{t('discount:totalExVAT')}</Text>
        <PriceAdvanced textAlign="right" fontWeight={600} amount={totalExVAT.toNumber()} />
      </Grid>
    </Flex>
  );
};
