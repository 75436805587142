import { Box, Flex, HStack, VStack, useOutsideClick } from '@chakra-ui/react';
import { FEATURE_FLAGS, type ILibraryComponent, type ILibraryJob } from '@graneet/business-logic';
import type { GraneetBadgeColor } from '@graneet/lib-ui';
import { RICH_TEXT_BLOCK_TOOLBAR_PRESET, RichTextInput, BadgeDropdown } from '@graneet/lib-ui';
import type { QuoteFileObject, QuoteNodeObject } from '@org/quotation-lib';
import type { AbstractQuoteItemObject } from '@org/quotation-lib/src/abstract-quote-item/entities/abstract-quote-item.object';
import type { GridApi } from '@ag-grid-community/core';
import { useMemo, type FC, type KeyboardEvent, useCallback, useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash-es/uniqBy';

import type { AutoCompleteResult, AutotCompleteResultsHandler, QuoteItem } from './AutoComplete/AutoCompleteResults';
import { AutoCompleteResults } from './AutoComplete/AutoCompleteResults';

import { useStore } from 'store/store';
import { useQuotationAutoCompleteApi } from 'features/quotation/services/quote-autocomplete.api';
import { useQuoteAutoCompleteSelectedItem } from 'features/quotation/quote-common/hooks/useQuoteAutoCompleteSelectedItem';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useAutoSuggestSelectedEvent } from 'features/quotation/ag-grid-quote/hooks/tracking/useAutoSuggestSelectedEvent';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

const sluglify = (str: string) =>
  str
    .replace(/^\s+|\s+$/g, '')
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

const generateUniqueKey = (item: QuoteItem) =>
  `${sluglify(item.denomination ?? '')}-${sluglify(item.refCode ?? '')}-${sluglify(item.unit ?? '')}-${sluglify(item.note ?? '')}-${sluglify(item.unitFlatCostAmount ?? '')}-${sluglify(item.componentTypeId?.toString() ?? '')}`;

const getUniqResults = (results: AutoCompleteResult) => ({
  ...results,
  quote: {
    items: uniqBy(
      results.quote.items.map((item) => ({
        ...item,
        key: generateUniqueKey(item),
      })),
      'key',
    ),
  },
});

export const ItemEditor: FC<{
  item: {
    id: string;
    denomination: string | null;
    note?: string | null;
    files?: QuoteFileObject[];
  };
  componentTypeId?: number | null;
  type: AbstractQuoteItemObject['type'] | 'QuoteComponent';
  level: number;
  hasChildren?: boolean;
  nodeId: string;
  onChange: (key: string) => (value: string) => void;
  columnWidth: number;
  api: GridApi<QuoteNodeObject>;
}> = ({ item, type, componentTypeId, columnWidth, nodeId, hasChildren, onChange, api }) => {
  const isAutoSuggestEnabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_AUTOSUGGEST);
  const cellRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['quote']);
  const { quote } = useQuote();
  const [selectedComponent, setSelectedComponent] = useState<string>(componentTypeId?.toString() ?? '');
  const updatePropertiesFromAutoCompletion = useQuoteAutoCompleteSelectedItem();
  const onAutoSuggestSelectedTracking = useAutoSuggestSelectedEvent();
  const boxRef = useRef<HTMLDivElement>(null);

  const autotCompleteRef = useRef<AutotCompleteResultsHandler>(null);

  const { autoCompleteItems } = useQuotationAutoCompleteApi();

  const componentTypes = useStore((state) => state.quoteComponentTypes);

  const dropDownRef = useRef<HTMLDivElement>(null);

  const [dropdownWidth, setDropdownWidth] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setDropdownWidth(entries[0].contentRect.width);
    });
    if (dropDownRef.current) {
      observer.observe(dropDownRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => (dropDownRef.current ? observer.unobserve(dropDownRef.current) : undefined);
  }, []);

  const minWidth = useMemo(() => {
    if (type === 'QuoteComponent') {
      return columnWidth - dropdownWidth - 8;
    }
    return columnWidth;
  }, [columnWidth, type, dropdownWidth]);

  const availableComponents = useMemo(
    () => [
      {
        value: '',
        label: t('quote:quotation.global.componentTypePlaceholder'),
        color: 'gray' as GraneetBadgeColor,
      },
      ...componentTypes.map((component) => ({
        value: component.id.toString(),
        label: component.name,
        color: component.color,
      })),
    ],
    [componentTypes, t],
  );

  const handleOnChange = useCallback(
    (key: string) => (v: string) => {
      onChange(key)(v);
    },
    [onChange],
  );

  const handleComponentChange = useCallback(
    (v: string) => {
      handleOnChange('componentTypeId')(v);
      setSelectedComponent(v);
    },
    [handleOnChange],
  );

  const handleResultSelected = useCallback(
    (
      result:
        | QuoteItem
        | Omit<ILibraryJob, 'createdAt' | 'updatedAt'>
        | Omit<ILibraryComponent, 'createdAt' | 'updatedAt'>,
      resultType: 'quote' | 'libraryItem' | 'libraryComponent',
    ) => {
      onChange('autoSuggestFill')('true');
      updatePropertiesFromAutoCompletion(nodeId, type, {
        item: result,
        type: resultType,
      });
      api.tabToNextCell();

      onAutoSuggestSelectedTracking(type === 'QuoteComponent' ? 'component' : 'job');
    },
    [onChange, updatePropertiesFromAutoCompletion, nodeId, type, api, onAutoSuggestSelectedTracking],
  );

  const handleNoResultSelected = useCallback(() => {
    api.tabToNextCell();
  }, [api]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || (e.key === 'Enter' && !e.shiftKey)) {
        if (isAutoSuggestEnabled) {
          autotCompleteRef.current?.onKeyPress(e.key, e);
        } else {
          handleNoResultSelected();
        }
      }
    },
    [handleNoResultSelected, isAutoSuggestEnabled],
  );

  const renderSearchResults = useCallback(
    (searchTerm: string, isLoading: boolean, searchResults?: AutoCompleteResult) => (
      <AutoCompleteResults
        key={searchTerm}
        ref={autotCompleteRef}
        isLoading={isLoading}
        searchTerm={searchTerm}
        results={searchResults}
        onResultSelected={handleResultSelected}
        onNoResultSelected={handleNoResultSelected}
      />
    ),
    [handleResultSelected, handleNoResultSelected],
  );

  const handleSearch = useCallback(
    async (searchValue: string) => {
      let results: AutoCompleteResult = {
        quote: {
          items: [],
        },
        library: {
          items: [],
          components: [],
        },
      };
      if (quote) {
        try {
          results = (await autoCompleteItems(
            quote.getId(),
            searchValue.trim(),
            type === 'QuoteComponent',
          )) as unknown as AutoCompleteResult;
        } catch {
          results = {
            quote: {
              items: [],
            },
            library: {
              items: [],
              components: [],
            },
          };
        }
      }
      return getUniqResults(results);
    },
    [autoCompleteItems, quote, type],
  );

  useOutsideClick({
    ref: boxRef,
    handler: () => {
      setTimeout(() => {
        api.stopEditing();
      }, 0);
    },
  });

  const initialEditorTopPosition = useRef<number | null>(null);

  useLayoutEffect(() => {
    const element = document.querySelector('.ag-popup-editor');
    const contentElement = document.querySelector('.ag-popup-editor .denominationEditorContent');

    const observer = new ResizeObserver(([entry, content]) => {
      if (
        entry.target.getBoundingClientRect().bottom > api.getVerticalPixelRange().bottom &&
        (entry.target as Element & { style: { bottom: string; top: string } }).style.bottom !== '44px'
      ) {
        initialEditorTopPosition.current = parseInt(
          (entry.target as Element & { style: { bottom: string; top: string } }).style.top.replace('px', ''),
          10,
        );
        // eslint-disable-next-line no-param-reassign
        (entry.target as Element & { style: { bottom: string; top: string } }).style.bottom = '44px';
        // eslint-disable-next-line no-param-reassign
        (entry.target as Element & { style: { bottom: string; top: string } }).style.top = 'auto';
      }
      if (initialEditorTopPosition.current) {
        if (
          (content.contentRect?.height ?? 0) + initialEditorTopPosition.current <
          api.getVerticalPixelRange().bottom
        ) {
          // eslint-disable-next-line no-param-reassign
          (entry.target as Element & { style: { bottom: string; top: string } }).style.bottom = 'auto';
          // eslint-disable-next-line no-param-reassign
          (entry.target as Element & { style: { bottom: string; top: string } }).style.top =
            `${initialEditorTopPosition.current}px`;
        } else {
          // eslint-disable-next-line no-param-reassign
          (entry.target as Element & { style: { bottom: string; top: string } }).style.bottom = '44px';
          // eslint-disable-next-line no-param-reassign
          (entry.target as Element & { style: { bottom: string; top: string } }).style.top = 'auto';
        }
      }
    });
    // Observe one or multiple elements
    if (element) {
      observer.observe(element);
    }
    if (contentElement) {
      observer.observe(contentElement);
    }
    return () => {
      observer.disconnect();
    };
  }, [api]);

  return (
    <VStack
      height="100%"
      justifyContent="center"
      alignItems="flex-start"
      p={2}
      gap={2}
      width="100%"
      ref={boxRef}
      className="denominationEditorContent"
    >
      <HStack width="100%" ref={cellRef}>
        <Box flex="1" width={minWidth}>
          <RichTextInput<AutoCompleteResult>
            autoFocus
            initialValue={item.denomination ?? ''}
            onChange={handleOnChange('denomination')}
            configuration={RICH_TEXT_BLOCK_TOOLBAR_PRESET}
            navbarType="inline"
            shouldDisplayError={false}
            disableTabIndentation
            useShiftEnter
            placeholder={t('quote:quotation.global.denominationPlaceholder')}
            onSearch={!hasChildren && isAutoSuggestEnabled ? handleSearch : undefined}
            renderSearchResults={!hasChildren && isAutoSuggestEnabled ? renderSearchResults : undefined}
            inputProps={{
              minHeight: '40px',
              className: 'denomination-editor',
              onKeyDown: handleKeyDown,
            }}
          />
        </Box>
        {type === 'QuoteComponent' && (
          <Flex ml="auto" ref={dropDownRef} alignSelf="flex-start" mt={2}>
            <BadgeDropdown
              selectedValue={selectedComponent}
              items={availableComponents}
              placeholder={t('quote:quotation.global.componentTypePlaceholder')}
              onChange={handleComponentChange}
            />
          </Flex>
        )}
      </HStack>
    </VStack>
  );
};
