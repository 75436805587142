import { useCallback, useRef } from 'react';

export const useMemoOnTime = (timeout: number) => {
  const memoizedValues = useRef(new Map());

  return useCallback(
    <T>(key: string, fn: () => T): T => {
      if (memoizedValues.current.has(key)) {
        return memoizedValues.current.get(key);
      }

      const value = fn();

      memoizedValues.current.set(key, value);

      setTimeout(() => {
        memoizedValues.current.delete(key);
      }, timeout);

      return value;
    },
    [timeout],
  );
};
