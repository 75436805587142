import type { FC } from 'react';
import { Divider, Flex, Text } from '@chakra-ui/react';
import { DataTable, formatDateOrEmpty, Price } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { useBankingTransaction } from '../../services/banking-transaction.api';
import { BankingTransactionTags } from '../BankingTransactionTags';
import { BankingTransactionComment } from '../BankingTransactionComment';
import { BankingBankLogo } from '../BankingBankLogo';
import { BankingTransactionStatusDropdown } from '../BankingTransactionStatusDropdown';
import { mapBankingTransactionTypeToDisplayedType } from '../../services/banking-transaction.util';

import { BankingTransactionDrawerStatements } from './BankingTransactionDrawerStatements';

interface BankingTransactionDetailDrawerProps {
  id: string;
  onAssociateSupplierInvoiceToTransaction: () => void;
  onDisassociateBillFromTransaction: (billId: number) => void;
  onAssociateBillToTransaction: () => void;
  onDisassociateSupplierInvoiceFromTransaction: (supplierInvoiceId: number) => void;
  onCreateSupplierInvoiceToTransaction: () => void;
  onBankingTransactionUpdate: () => void;
  allowUpdates: boolean;
}

export const BankingTransactionDetailDrawer: FC<BankingTransactionDetailDrawerProps> = ({
  id,
  onAssociateSupplierInvoiceToTransaction,
  onDisassociateBillFromTransaction,
  onAssociateBillToTransaction,
  onCreateSupplierInvoiceToTransaction,
  onDisassociateSupplierInvoiceFromTransaction,
  onBankingTransactionUpdate,
  allowUpdates,
}) => {
  const { t } = useTranslation(['global', 'banking']);
  const bankingTransaction = useBankingTransaction(id);

  return (
    <Flex direction="column" gap={4} fontSize="sm" color="primaryLight">
      <Flex direction="column" justifyContent="center" textAlign="center">
        <Flex justifyContent="center">
          <BankingBankLogo size="lg" bankingConnection={bankingTransaction.data.bankingAccount.bankingConnection} />
        </Flex>

        <Text fontSize="xl">
          <Price amount={bankingTransaction.data.amount} />
        </Text>
        <Text>{bankingTransaction.data.label}</Text>
      </Flex>

      <Divider />
      <Text fontWeight={600}>{t('banking:bankingTransaction.details.informationBlock.title')}</Text>
      <DataTable numberOfColumns={1} density="sm">
        <DataTable.Row label={t('banking:bankingTransaction.fields.bank')}>
          <DataTable.Cell>{bankingTransaction.data.bankingAccount.bankingConnection.name}</DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('banking:bankingTransaction.fields.account')}>
          <DataTable.Cell>{bankingTransaction.data.bankingAccount.name}</DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('banking:bankingTransaction.fields.type')}>
          <DataTable.Cell>{mapBankingTransactionTypeToDisplayedType(bankingTransaction.data.type, t)}</DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('banking:bankingTransaction.fields.date')}>
          <DataTable.Cell>{formatDateOrEmpty(bankingTransaction.data.date)}</DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row label={t('banking:bankingTransaction.fields.status')}>
          <DataTable.Cell>
            <BankingTransactionStatusDropdown
              bankingTransaction={bankingTransaction.data}
              onBankingTransactionUpdate={onBankingTransactionUpdate}
              isReadOnly={allowUpdates}
            />
          </DataTable.Cell>
        </DataTable.Row>
      </DataTable>

      <Divider />
      <BankingTransactionDrawerStatements
        bankingTransaction={bankingTransaction.data}
        onAssociateSupplierInvoiceToTransaction={onAssociateSupplierInvoiceToTransaction}
        onDisassociateSupplierInvoiceFromTransaction={onDisassociateSupplierInvoiceFromTransaction}
        onAssociateBillToTransaction={onAssociateBillToTransaction}
        onDisassociateBillFromTransaction={onDisassociateBillFromTransaction}
        onCreateSupplierInvoiceToTransaction={onCreateSupplierInvoiceToTransaction}
        hideAssociationButton={allowUpdates}
      />

      <Divider />
      <BankingTransactionTags bankingTransaction={bankingTransaction.data} />

      <Divider />
      <BankingTransactionComment bankingTransaction={bankingTransaction.data} />
    </Flex>
  );
};
