import { useTranslation } from 'react-i18next';
import { Spinner, type SpinnerProps } from '@graneet/lib-ui';
import { Flex } from '@chakra-ui/react';
import type { FC } from 'react';

interface LoadingProps extends SpinnerProps {
  displayText?: boolean;
}

export const Loading: FC<LoadingProps> = ({ displayText = true, ...props }) => {
  const { t, ready } = useTranslation('global', { useSuspense: false });

  return (
    <Flex boxSize="100%" pt={10}>
      <Spinner {...props}>{ready && displayText && t('loading')}</Spinner>
    </Flex>
  );
};
