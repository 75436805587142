import { Flex, Text, VStack } from '@chakra-ui/react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMutating } from '@tanstack/react-query';

import { Loading } from 'features/common/components/Loading';

type ListItem = {
  id: number;
  name: string | null;
};

interface ListingToPayStatusProps<T extends ListItem> {
  list: T[];
}

export const ListingToPayStatus: FC<ListingToPayStatusProps<ListItem>> = ({ list }) => {
  const { t } = useTranslation(['global', 'validationStep']);
  const isMutating = useIsMutating();

  return (
    <>
      {!!isMutating && (
        <VStack>
          <Loading />
          <Text>{t('validationStep:supplierInvoice.processingData')}</Text>
        </VStack>
      )}
      {!isMutating && (
        <Flex ml={8}>
          <ul>
            {list.map((item) => (
              <li key={item.id}>
                <Text>{item.name}</Text>
              </li>
            ))}
          </ul>
        </Flex>
      )}
    </>
  );
};
