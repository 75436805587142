import type { IBill, IPayment, IPaymentCreationDTO, IPaymentWithRelations } from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import type { PaginatedResponse } from '@graneet/lib-ui';
import { useToast } from '@graneet/lib-ui';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { billKeyFactory } from '../../bill/services/bill.api';

import { apiNew } from 'features/api/services/apiNew.service';

const PAYMENT_PATH = '/payments';

const paymentKeyFactory = createQueryKeys('payments', {
  getByBill: (billId: number) => ({
    queryKey: [PAYMENT_PATH, billId],
    queryFn: () =>
      apiNew.get<{ billId: number; _full: true }, PaginatedResponse<IPaymentWithRelations>>(PAYMENT_PATH, {
        _full: true,
        billId,
      }),
  }),
});

export function useBillPayments(billId: number) {
  return useSuspenseQuery(paymentKeyFactory.getByBill(billId));
}

export function useCreatePayment() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'bill']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { bill: IBill; dto: IPaymentCreationDTO }) =>
      apiNew.post<IPaymentCreationDTO, IPayment>(PAYMENT_PATH, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('bill:modalToPay.toastError', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async (payment, params) => {
      await queryClient.invalidateQueries(billKeyFactory.getOne(params.bill.id));
      await queryClient.invalidateQueries(billKeyFactory.get());
      await queryClient.invalidateQueries(paymentKeyFactory.getByBill(params.bill.id));

      toast.success(
        t('global:words.c.success'),
        t(
          payment.amountPaidIncVAT >= params.bill!.remainingToBePaidIncVAT
            ? 'bill:modalToPay.toastSuccessTotal'
            : 'bill:modalToPay.toastSuccessPartial',
        ),
      );
    },
  });
}

export function useUpdatePayment() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'bill']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { bill: IBill; payment: IPayment; dto: IPaymentCreationDTO }) =>
      apiNew.patch<IPaymentCreationDTO, IPayment>(`${PAYMENT_PATH}/${params.payment.id}`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('bill:modalToPay.toastError', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async (payment, params) => {
      await queryClient.invalidateQueries(billKeyFactory.getOne(params.bill.id));
      await queryClient.invalidateQueries(billKeyFactory.get());
      await queryClient.invalidateQueries(paymentKeyFactory.getByBill(params.bill.id));
      toast.success(
        t('global:words.c.success'),
        t(
          payment.amountPaidIncVAT >= params.bill!.remainingToBePaidIncVAT
            ? 'bill:modalToPay.toastSuccessTotal'
            : 'bill:modalToPay.toastSuccessPartial',
        ),
      );
    },
  });
}

export function useDeletePayment() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'bill']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; bill: IBill }) => apiNew.delete<void, IPayment>(`${PAYMENT_PATH}/${params.id}`),
    onError: () => {
      toast.error(t('global:words.c.error'), t('bill:payment.deleteToastError', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries(billKeyFactory.getOne(params.bill.id));
      await queryClient.invalidateQueries(billKeyFactory.get());
      await queryClient.invalidateQueries(paymentKeyFactory.getByBill(params.bill.id));
      toast.success(t('global:words.c.success'), t('bill:payment.deleteToastSuccess'));
    },
  });
}
