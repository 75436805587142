import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import { changeDayJSLocale as changeDayJSLocaleLibUI } from '@graneet/lib-ui';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);

dayjs.updateLocale('en', {
  weekStart: 1,
});
export const changeDayJSLocale = (newLocale: string) => {
  dayjs.locale(newLocale);
  changeDayJSLocaleLibUI(newLocale);
};
