import { useMemo } from 'react';
import { Card, LabeledData, GotoLink } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import type {
  ICredit,
  IDownPayment,
  IInvoice,
  IProgressStatement,
  STATEMENT_SALES_TYPES,
} from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';

import { getStatementPath } from 'features/statement/services/statement.util';

interface RecordAssociatedEntity {
  [STATEMENT_TYPES.PROGRESS_STATEMENT]: IProgressStatement;
  [STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT]: IProgressStatement;
  [STATEMENT_TYPES.CREDIT]: ICredit;
  [STATEMENT_TYPES.INVOICE]: IInvoice;
  [STATEMENT_TYPES.DOWN_PAYMENT]: IDownPayment;
}

interface CreditLinkedStatementCardProps<StatementType extends STATEMENT_SALES_TYPES> {
  statement: RecordAssociatedEntity[StatementType];

  statementType: STATEMENT_SALES_TYPES;

  projectId?: number;
}

export const CreditLinkedStatementCard = <StatementType extends STATEMENT_SALES_TYPES>({
  statement,
  statementType,
  projectId,
}: CreditLinkedStatementCardProps<StatementType>): JSX.Element => {
  const { t } = useTranslation(['credit', 'downPayment', 'invoice', 'progressStatement', 'directProgressStatement']);

  const statementLabel = useMemo(() => {
    switch (statementType) {
      case STATEMENT_TYPES.CREDIT:
        return t('credit:fields.name.label');
      case STATEMENT_TYPES.DOWN_PAYMENT:
        return t('downPayment:fields.name');
      case STATEMENT_TYPES.INVOICE:
        return t('invoice:fields.name');
      case STATEMENT_TYPES.PROGRESS_STATEMENT:
        return t('progressStatement:fields.name');
      case STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT:
        return t('directProgressStatement:fields.name');
      default:
        throw new Error('Not implemented');
    }
  }, [statementType, t]);

  const statementPath = getStatementPath(statementType);

  return (
    <Card
      title={
        <Flex>
          <Text mr={4}>{t(`credit:linked.${statementType}`)}</Text>
          <GotoLink
            to={
              projectId
                ? `/projects/${projectId}/statements/${statementPath}/${statement.id}`
                : `/sales/statements/${statementPath}/${statement.id}`
            }
            label={t(`credit:actions.goToLinked.${statementType}`)}
          />
        </Flex>
      }
    >
      <SimpleGrid columns={{ md: 2 }} spacing={5}>
        <LabeledData label={statementLabel} data={statement.name} />
        <LabeledData label={t('invoice:fields.invoiceNumber')} data={statement.invoiceNumber} />
      </SimpleGrid>
    </Card>
  );
};
