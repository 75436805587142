import type { GeneratorConfig, GeneratorConfigObject, GeneratorInterface, LEDGER_GENERATORS } from '../ledger.type';

export interface GeneratorStaticConfig extends GeneratorConfig<LEDGER_GENERATORS.STATIC> {
  type: LEDGER_GENERATORS.STATIC;
  value: string;
}

export class GeneratorStatic implements GeneratorInterface {
  private readonly value: string;

  constructor(config: GeneratorStaticConfig) {
    const { value } = config;
    this.value = value;
  }

  public static isConfigValid(config: GeneratorConfigObject, block: string): boolean {
    const { value } = config[block] as GeneratorStaticConfig;
    return typeof value === 'string' && value.length > 0;
  }

  public isStateValid(): boolean {
    return true;
  }

  public generateValue(): [string, null, null] {
    return [this.value.toString(), null, null];
  }
}
