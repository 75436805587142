import type { FC } from 'react';
import { useMemo } from 'react';
import type { IProject } from '@graneet/business-logic';
import { divideFloating, PERMISSION } from '@graneet/business-logic';
import { formatDataOrEmpty, multiplyFloating, PriceAdvanced, roundFloating, Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash-es';
import { Box } from '@chakra-ui/react';

import { useProjectWorkers } from '../../project/services/project.api';

import { WorkerCard } from './WorkerCard';

import { usePermissions } from 'features/role/hooks/usePermissions';

interface WorkerProjectTableProps {
  project: IProject;
}
export const WorkerProjectTable: FC<WorkerProjectTableProps> = ({ project }) => {
  const { t } = useTranslation(['project', 'worker']);
  const isUserAllowedToSeeSensitiveInformation = usePermissions([PERMISSION.ACCESS_WORKER_HOURLY_PRICE]);

  const workers = useProjectWorkers(project.id);

  const templateColumns = useMemo(
    () =>
      compact([
        'minmax(5rem, 1fr)',
        'minmax(5rem, 1fr)',
        'minmax(5rem, 1fr)',
        isUserAllowedToSeeSensitiveInformation ? 'minmax(5rem, 1fr)' : null,
      ]),
    [isUserAllowedToSeeSensitiveInformation],
  );

  return (
    <Box borderRadius="md" boxShadow="subtle">
      <Table templateColumns={templateColumns} noResultLabel={t('worker:noResult')}>
        <Table.Header>
          <Table.Cell>{t('worker:name')}</Table.Cell>
          <Table.Cell>{t('worker:fields.email')}</Table.Cell>
          <Table.Cell right>{t('worker:fields.workedTime')}</Table.Cell>
          {isUserAllowedToSeeSensitiveInformation && <Table.Cell right>{t('worker:fields.amount')}</Table.Cell>}
        </Table.Header>

        {workers.data.workers.map((worker) => (
          <Table.Row key={worker.id}>
            <Table.Cell>
              <WorkerCard worker={worker} />
            </Table.Cell>
            <Table.Cell variant="ellipsis">{formatDataOrEmpty(worker.email)}</Table.Cell>
            <Table.Cell right>
              {`${divideFloating(
                roundFloating(multiplyFloating(divideFloating(worker.nbMinutes ?? 0, 60), 100)),
                100,
              )} h`.replace('.', ',')}
            </Table.Cell>
            {isUserAllowedToSeeSensitiveInformation && (
              <Table.Cell variant="ellipsis" right>
                <PriceAdvanced amount={worker.totalAmount} />
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table>
    </Box>
  );
};
