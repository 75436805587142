import { useCallback, useState, type FC } from 'react';
import { ActionMenu, formatNumberToVatRate, useCurrency, useDrawer, SimpleFolderIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { Quote, QuoteBasicItemObject } from '@org/quotation-lib';
import { useFormContext } from 'graneet-form';
import { TRACKING_EVENT_ENUM } from '@graneet/business-logic';

import { QuoteSelectDrawer } from 'features/library-job/components/QuoteSelectDrawer';
import type { OrderEditForm } from 'features/order/forms/order-edit-wizard';
import { getOrderItemFieldName } from 'features/order/forms/order-edit-wizard';
import type { OrderTree } from 'features/order/hooks/useOrderTree';
import { QuoteBasicItemSelectDrawer } from 'features/library-job/components/QuoteBasicItemSelectDrawer';
import { useSelectQuote } from 'features/order/hooks/useSelectQuote';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';

interface OrderAddQuoteBasicItemsButtonProps {
  tree: OrderTree;
}

export const OrderAddQuoteBasicItemsButton: FC<OrderAddQuoteBasicItemsButtonProps> = ({ tree }) => {
  const { t } = useTranslation(['orders', 'global']);
  const segmentAnalytics = useSegmentAnalytics();

  const drawerSelectQuote = useDrawer('quote-select-drawer');
  const drawerSelectBasicItems = useDrawer('quote-basic-items-select-drawer');
  const [selectedQuote, setSelectedQuote] = useState<Quote>();

  const form = useFormContext<OrderEditForm>();
  const { mapNumberToAmount } = useCurrency();
  const quoteSelectOnSubmit = useSelectQuote(setSelectedQuote, drawerSelectBasicItems);

  const onSubmit = useCallback(
    (quoteBasicItemToOrder: QuoteBasicItemObject[]) => {
      const { rootNodeId, nodes } = tree.getDisplayedCurrentTree();
      const lastItemId = tree.getLastLeafOfNode(rootNodeId);

      const { defaultVatRate } = nodes[rootNodeId];
      const formValues: Partial<OrderEditForm> = {};
      quoteBasicItemToOrder.forEach((basicItems) => {
        const id = tree.createLeaf(rootNodeId, lastItemId, {
          code: basicItems.refCode,
          vatRate: defaultVatRate,
          unit: basicItems.unit,
          description: basicItems.denomination ? basicItems.denomination : undefined,
          unitPriceExVAT: basicItems.unitFlatCostAmount ? Number(basicItems.unitFlatCostAmount) : undefined,
          quantity: basicItems.quantityFormula?.quantity ? Number(basicItems.quantityFormula.quantity) : undefined,
        });

        formValues[getOrderItemFieldName(id, 'code')] = basicItems.refCode;
        formValues[getOrderItemFieldName(id, 'vatRate')] = formatNumberToVatRate(defaultVatRate);
        formValues[getOrderItemFieldName(id, 'unit')] = basicItems.unit;
        formValues[getOrderItemFieldName(id, 'description')] = basicItems.denomination
          ? basicItems.denomination
          : undefined;
        formValues[getOrderItemFieldName(id, 'unitPriceExVAT')] = mapNumberToAmount(
          basicItems.unitFlatCostAmount ? Number(basicItems.unitFlatCostAmount) : 0,
        );
        formValues[getOrderItemFieldName(id, 'quantity')] = basicItems.quantityFormula?.quantity
          ? Number(basicItems.quantityFormula.quantity)
          : 0;
      });
      form.setFormValues(formValues);
    },
    [form, mapNumberToAmount, tree],
  );

  const handleSelectQuoteDrawerOpen = useCallback(() => {
    // Tracking event for creating order from quote components supply sheet
    segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.ORDER_ITEMS_IMPORT, { type: 'quote-job' });

    drawerSelectQuote.onOpen();
  }, [drawerSelectQuote, segmentAnalytics]);

  return (
    <>
      <ActionMenu.Action
        icon={<SimpleFolderIcon />}
        label={t('orders:actions.importJobs')}
        onClick={handleSelectQuoteDrawerOpen}
      />

      <QuoteSelectDrawer
        title={t('orders:drawers.quoteSelectDrawer.title')}
        description={t('orders:drawers.quoteSelectDrawer.descriptionBasicItems')}
        drawer={drawerSelectQuote}
        onSubmit={quoteSelectOnSubmit}
        hasPagination
        showClientColumn
        showAccountManagers
      />

      <QuoteBasicItemSelectDrawer
        quote={selectedQuote}
        title={t('orders:drawers.quoteBasicItemDrawer.title')}
        description={t('orders:drawers.quoteBasicItemDrawer.description')}
        drawer={drawerSelectBasicItems}
        onSubmit={onSubmit}
      />
    </>
  );
};
