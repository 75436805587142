import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import type { TableSelectBannerChildrenProps } from '@graneet/lib-ui';
import { SingleSelectField, DateField, Modal, useToast, getCurrentDateAsString } from '@graneet/lib-ui';
import type { IAccountingConfigBankAccount, SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import { FEATURE_FLAGS, FILTERING_PARAMS } from '@graneet/business-logic';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, useForm, useFormStatus } from 'graneet-form';
import qs from 'qs';

import { useSupplierInvoiceUpdateStatusesToPaid } from '../../services/supplier-invoice.api';

import { Rule } from 'features/form/rules/Rule';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useAccountingConfigs } from 'features/accounting/services/accounting-config.api';

interface FormValues {
  paidAt: string;

  accountingConfigBankAccountId?: IAccountingConfigBankAccount['id'];
}

export interface SupplierInvoiceChangeToPayModalProps
  extends TableSelectBannerChildrenProps<{ id: number; status: SUPPLIER_INVOICE_STATUS }> {
  modal: UseDisclosureReturn;

  onStatusesChanged(): Promise<void> | void;
}

export const SupplierInvoiceUpdateAtToPayStatusModal: FC<SupplierInvoiceChangeToPayModalProps> = ({
  modal,
  hasAllCheckboxesSelected,
  selectedItems,
  resetSelectedCheckboxes,
  currentFilters,
  onStatusesChanged,
}) => {
  const toast = useToast();
  const { t } = useTranslation(['supplierInvoices', 'global', 'bill']);

  const accountingConfigQuery = useAccountingConfigs();

  const { mutateAsync: supplierInvoiceUpdateStatusesToPaidMutateAsync, isPending: isMutationPending } =
    useSupplierInvoiceUpdateStatusesToPaid();

  const form = useForm<FormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  // default value
  useEffect(() => {
    form.setFormValues({ paidAt: getCurrentDateAsString() });
  }, [form]);

  /**
   * Hacky solution to display plural if `hasAllCheckboxesSelected` is selected
   */
  const labelSupplierInvoiceCounter = hasAllCheckboxesSelected ? 2 : selectedItems.length;

  // @[ff: accounting-payment-methods]
  const hasNewAccountingPaymentMethods = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_PAYMENT_METHODS);

  const bankAccounts = useMemo(
    () =>
      (accountingConfigQuery.data?.accountingConfigBanks ?? [])
        .map((bank) => bank.accountingConfigBankAccounts ?? [])
        .flat(),
    [accountingConfigQuery.data?.accountingConfigBanks],
  );

  // Use favorite bank account if defined
  useEffect(() => {
    form.setFormValues({
      accountingConfigBankAccountId: accountingConfigQuery.data?.defaultAccountingConfigBankAccount?.id,
    });
  }, [accountingConfigQuery.data, form]);

  const onToPayClicked = useCallback(async () => {
    const paidAt = form.getFormValues().paidAt!;
    const { accountingConfigBankAccountId } = form.getFormValues();

    const response = await supplierInvoiceUpdateStatusesToPaidMutateAsync({
      paidAt,
      filters: qs.parse(currentFilters.toString()),
      selectedItems: selectedItems.map((item) => ({ id: item.id })),
      hasAllSelected: hasAllCheckboxesSelected,
      search: currentFilters.get(FILTERING_PARAMS.SEARCH) || undefined,
      accountingConfigBankAccountId,
    });

    modal.onClose();

    if (response && 'warning' in response && response.warning === 'missing-information') {
      toast.warning(t('supplierInvoices:toasts.partialToPayStatusUpdate'));
    } else {
      toast.success(t('supplierInvoices:updateAtToPayStatusModal.toast', { count: labelSupplierInvoiceCounter }));
    }
    resetSelectedCheckboxes?.();

    onStatusesChanged();
  }, [
    currentFilters,
    form,
    hasAllCheckboxesSelected,
    labelSupplierInvoiceCounter,
    modal,
    onStatusesChanged,
    resetSelectedCheckboxes,
    selectedItems,
    supplierInvoiceUpdateStatusesToPaidMutateAsync,
    t,
    toast,
  ]);

  const modalTitle = t('supplierInvoices:updateAtToPayStatusModal.title', {
    count: labelSupplierInvoiceCounter,
  });

  return (
    <Modal title={modalTitle} isOpen={modal.isOpen} onClose={modal.onClose} size="2xl">
      <Form form={form}>
        <Text>
          <Trans
            t={t}
            i18nKey={
              hasAllCheckboxesSelected
                ? 'supplierInvoices:updateAtToPayStatusModal.descriptionAllSelected'
                : 'supplierInvoices:updateAtToPayStatusModal.description'
            }
            count={selectedItems.length}
          >
            <Box as="span" fontWeight={600} />
          </Trans>
        </Text>

        <DateField<FormValues> name="paidAt" label={t('supplierInvoices:paymentFields.paidAt')} mt={3} isRequired>
          <Rule.IsRequired />
        </DateField>

        {hasNewAccountingPaymentMethods && (
          <Box mt={2}>
            <SingleSelectField<FormValues>
              label={t('bill:modalToPay.bankAccount')}
              name="accountingConfigBankAccountId"
              isRequired
              options={bankAccounts.map((acc) => ({
                label: [acc.label, acc.account].filter(Boolean).join(' - '),
                value: acc.id,
              }))}
            >
              <Rule.IsRequired />
            </SingleSelectField>
          </Box>
        )}
      </Form>

      <Modal.Close isDisabled={isMutationPending} />

      <Modal.PrimaryButton isDisabled={!isFormValid} onClick={onToPayClicked} isLoading={isMutationPending}>
        {t('supplierInvoices:updateAtToPayStatusModal.cta')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
