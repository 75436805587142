import { useTranslation } from 'react-i18next';
import type { PERMISSION } from '@graneet/business-logic';

import { usePermissions } from './usePermissions';

export interface UseDisabledButtonPropsReturn {
  isDisabled: boolean;
  tooltip?: string;
  onClick?: undefined;
}

// TODO: Fix me refactor into PermissionButton after refactoring extractChildrenOfTypes in Card component
/**
 * Get disabled button props if user is unauthorized,
 * use either permissions array or isAuthorized to specify if user is allowed to perform this action
 * @param permissions (allowed permissions array)
 * @param isAuthorized (force authorization state : if usePermissions was called prior to this hook)
 */
export const useDisabledButtonProps = (
  permissions?: PERMISSION[],
  isAuthorized = false,
): ((isDisabled?: boolean) => UseDisabledButtonPropsReturn) => {
  const { t } = useTranslation(['global']);
  const hasPermissions = usePermissions(permissions || []);

  if (hasPermissions || isAuthorized) {
    return (isDisabled?: boolean) => ({
      // If button is already disabled keep it disabled
      isDisabled: isDisabled ?? false,
    });
  }

  // Missing permission will override props
  return () => ({
    isDisabled: true,
    tooltip: t('global:errors.disabledAction'),
    onClick: undefined,
  });
};
