import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { FlexProps, ImageProps, StyleProps } from '@chakra-ui/react';
import { Box, Center, Flex, Image, Stack, Text, useDisclosure, useTheme, VStack } from '@chakra-ui/react';
import { v4 as uuid } from 'uuid';
import { compact } from 'lodash-es';

import { Button } from '../Button/Button';
import { SimpleCirclePlusIcon, SimpleDeleteIcon, SimpleEditIcon } from '../Icons';
import { ConfirmModal } from '../Modal';
import { sleep } from '../../utils';
import { DragAndDrop } from '../DnD';

import { ImageActionSpinner } from './helpers/ImageActionSpinner';
import type { ImageListLabels, OnImageListError } from './helpers/ImageList.type';
import { IMAGE_LIST_ERROR } from './helpers/ImageList.type';
import { ABSOLUTE_COVER } from './helpers/ImageList.util';
import { SelectImageButton } from './helpers/SelectImageButton';
import type { ImageListThumbnailSize } from './helpers/ImageListThumbnail';
import { BORDER_RADIUS, ImageListThumbnail } from './helpers/ImageListThumbnail';

const DELAY_NETWORK_MS = 1000; // Show loader for at least 1s

/// IMAGE LIST

export type ImageListItem = {
  id: string | number;
  url: string | null;
  file?: File;
};

export interface ImageListProps extends Omit<FlexProps, 'onError'> {
  thumbnailSize?: ImageListThumbnailSize;
  images: ImageListItem[];
  labels?: ImageListLabels;
  minDimension?: number;
  maxDimension?: number;
  maxImageSize?: number;
  multiple?: boolean;
  /**
   * Track images
   */
  onImagesChange?(newFile: ImageListItem[]): void;
  /**
   * Open the confirmation modal on delete
   */
  confirmDelete?: boolean;
  onError?: OnImageListError;
  onSuccess?: (deleted: boolean) => void;
  onImageAdded?: (file: File[]) => Promise<(ImageListItem | null | undefined)[]>;
  onImageEdited?: (id: ImageListItem['id'], file: File) => Promise<ImageListItem | null | undefined>;
  onImageDeleted?: (id: ImageListItem['id']) => Promise<void>;
  maximumNumImages?: number;
  readOnly?: boolean;
  styleImage?: ImageProps;
}

export const ImageList: FC<ImageListProps> = ({
  images: originalImages,
  labels,
  minDimension,
  maxDimension,
  confirmDelete = true,
  multiple = false,
  onImagesChange,
  onError,
  onSuccess,
  onImageAdded,
  onImageEdited,
  onImageDeleted,
  thumbnailSize = 'small',
  maxImageSize,
  maximumNumImages = Infinity,
  readOnly = false,
  width,
  styleImage,
  ...flexProps
}) => {
  const theme = useTheme();
  const [isDragging, setIsDragging] = useState(false);

  /// - State

  const [{ images, beingAdded: saving, beingDeleted: deleting }, setState] = useState<{
    /**
     * Contains only the image(s) added via the input
     */
    images: ImageListItem[];
    /**
     * Contains temporary ids of image(s) being added
     */
    beingAdded: Record<ImageListItem['id'], boolean>;
    /**
     * Contains ids of ANY image (input or from props) being deleted
     */
    beingDeleted: Record<ImageListItem['id'], boolean>;
  }>(() => ({ images: originalImages, beingAdded: {}, beingDeleted: {} }));

  /// - Adding image state management helper functions

  /**
   * Add images to the state
   */
  const addImages = useCallback((uploadedImages: ImageListItem[]) => {
    setState((s) => ({ ...s, images: [...s.images, ...uploadedImages] }));
  }, []);

  /**
   * Removes an image from the state
   */
  const removeImage = useCallback((imageId: ImageListItem['id']) => {
    setState((s) => ({ ...s, images: s.images.filter(({ id }) => id !== imageId) }));
  }, []);

  /**
   * Removes images from the state
   */
  const removeImages = useCallback((imageIds: ImageListItem[]) => {
    setState((s) => ({
      ...s,
      images: s.images.filter(({ id }) => !imageIds.find(({ id: newImageId }) => newImageId === id)),
    }));
  }, []);

  /**
   * Say a particular image is loading (using its id)
   */
  const setImageToSaving = useCallback((id: ImageListItem['id']) => {
    setState((s) => ({ ...s, beingAdded: { ...s.beingAdded, [id]: true } }));
  }, []);

  const setImageToDeleting = useCallback((id: ImageListItem['id'], isBeingDeleted: boolean) => {
    setState((s) => ({
      ...s,
      beingDeleted: { ...s.beingDeleted, [id]: isBeingDeleted },
    }));
  }, []);

  /**
   * Remove the id of an image after it stopped loading
   */
  const resetImageSaving = useCallback((id: ImageListItem['id']) => {
    setState((s) => {
      const newSaving = { ...s.beingAdded };
      delete newSaving[id];
      return { ...s, beingAdded: newSaving };
    });
  }, []);

  /**
   * Replace the preview of an image with its actual saved and final url
   */
  const replaceImage = useCallback((id: ImageListItem['id'], newImage: ImageListItem) => {
    setState((s) => ({
      ...s,
      images: s.images.map((image) => (image.id === id ? newImage : image)),
    }));
  }, []);

  const fieldBackgroundColor = useMemo<StyleProps['color']>(() => {
    if (isDragging) {
      return 'gray.100'; // Dragging file
    }
    return 'white'; // Default
  }, [isDragging]);

  /**
   * Listens to images selected by the input
   */
  const onNewImageFileSelected = useCallback(
    async (imageFiles: File[]) => {
      if (imageFiles?.length === 0) return;
      /**
       * Display images preview to the list
       */
      const imageFilesWithUUID = imageFiles.map((file) => {
        const previewTmpId = uuid();
        setImageToSaving(previewTmpId);
        return {
          file,
          url: URL.createObjectURL(file),
          id: previewTmpId,
        };
      });
      addImages(imageFilesWithUUID);

      if (onImageAdded) {
        try {
          const [newImages] = await Promise.all([onImageAdded(imageFiles), sleep(DELAY_NETWORK_MS)]);
          /**
           * Display new images whose src comes from the saving
           */
          removeImages(imageFilesWithUUID);
          addImages(compact(newImages));
          onSuccess?.(false);
        } catch (err) {
          removeImages(imageFilesWithUUID);
          onError?.(IMAGE_LIST_ERROR.REQUEST_ADD);
        }
      }
      imageFilesWithUUID.forEach(({ id }) => {
        resetImageSaving(id);
      });
    },
    [addImages, onImageAdded, setImageToSaving, removeImages, onSuccess, onError, resetImageSaving],
  );

  /// - Updating images
  const onEditedImageFileSelected = useCallback(
    async (imageFile: File, newImageResult: FileReader['result'], imageItem: ImageListItem) => {
      if (!onImageEdited) return;
      replaceImage(imageItem.id, { url: newImageResult as string, id: imageItem.id });
      setImageToSaving(imageItem.id);
      try {
        const [editedImage] = await Promise.all([onImageEdited(imageItem.id, imageFile), sleep(DELAY_NETWORK_MS)]);

        if (!editedImage) {
          throw new Error('No image was returned on edit');
        }

        replaceImage(imageItem.id, editedImage);
        onSuccess?.(false);
      } catch {
        replaceImage(imageItem.id, { url: imageItem.url, id: imageItem.id });
        onError?.(IMAGE_LIST_ERROR.REQUEST_EDITING);
      } finally {
        resetImageSaving(imageItem.id);
      }
    },
    [onImageEdited, onError, onSuccess, replaceImage, resetImageSaving, setImageToSaving],
  );

  /// - Deleting images

  const confirmDeletionModal = useDisclosure();

  const itemToDeleteRef = useRef<ImageListItem | null>(null);

  const onDeletionConfirmed = useCallback(async () => {
    const { current: item } = itemToDeleteRef;
    if (!item) return;
    setImageToDeleting(item.id, true);
    try {
      // If the item still has the file it means it hasn't been uploaded yet, the delete happens only locally
      if (!item.file) {
        await Promise.all([onImageDeleted?.(item.id), sleep(DELAY_NETWORK_MS)]);
      }
      removeImage(item.id);
      onSuccess?.(true);
    } catch {
      onError?.(IMAGE_LIST_ERROR.REQUEST_DELETING);
      setImageToDeleting(item.id, false);
    }
  }, [onImageDeleted, setImageToDeleting, removeImage, onError, onSuccess]);

  const onDeleteOverlayClicked = useCallback(
    async (item: ImageListItem) => {
      itemToDeleteRef.current = item;
      if (confirmDelete) {
        confirmDeletionModal.onOpen();
      } else {
        await onDeletionConfirmed();
      }
    },
    [confirmDelete, confirmDeletionModal, onDeletionConfirmed],
  );

  useEffect(() => {
    onImagesChange?.(images);
  }, [images, onImagesChange]);

  return (
    <DragAndDrop>
      <Flex width={width}>
        {/**
         * Confirm delete modal
         */}
        {onImageDeleted && labels && labels.deleteConfirm && (
          <ConfirmModal
            modal={confirmDeletionModal}
            title={labels.deleteConfirm.title}
            onConfirmed={onDeletionConfirmed}
            labels={labels.deleteConfirm}
            destructive
          />
        )}

        <Flex direction="row" flexWrap="wrap" gap={3} w={width} {...flexProps}>
          {images.length > 0 &&
            images.map((image) => {
              const isDeleting = deleting[image.id] === true;
              const isSaving = saving[image.id] === true;
              return (
                <Stack spacing={1} key={`image-list-item-${image.id}`} role="group">
                  {/**
                   * Box containing the image
                   */}
                  <ImageListThumbnail size={thumbnailSize} border="solid">
                    <Image objectFit="contain" src={image.url ?? ''} mb={3} m={0} h="100%" w="100%" {...styleImage} />

                    {/**
                     * Delete overlay
                     */}
                    {!isSaving && !readOnly && images.length < maximumNumImages && (
                      <Center
                        className="image-delete"
                        {...ABSOLUTE_COVER}
                        borderRadius={BORDER_RADIUS}
                        _hover={{ opacity: 1 }}
                        opacity={isDeleting ? 1 : 0}
                        transition="0.15s all ease-in-out"
                        cursor={isDeleting ? undefined : 'pointer'}
                        {...(isDeleting && {
                          _after: {
                            ...ABSOLUTE_COVER,
                            content: '""',
                            backgroundColor: 'whiteAlpha.900',
                            borderRadius: BORDER_RADIUS,
                          },
                        })}
                      >
                        {isDeleting ? (
                          <ImageActionSpinner zIndex={1} />
                        ) : (
                          <Box
                            position="absolute"
                            right={0}
                            top={0}
                            zIndex={10}
                            borderRadius={BORDER_RADIUS}
                            backgroundColor="rgba(0, 0, 0, 0.3)"
                          >
                            <Flex
                              className="delete-button"
                              onClick={() => onDeleteOverlayClicked(image)}
                              color="whiteAlpha.900"
                              padding={2}
                              sx={{
                                '.image-delete:hover &': {
                                  color: 'whiteAlpha.900',
                                  '&.delete-button:hover': {
                                    color: `red.500`,
                                  },
                                },
                              }}
                              transition="visibility 0s, opacity 0.1s linear"
                            >
                              <SimpleDeleteIcon />
                            </Flex>
                          </Box>
                        )}
                      </Center>
                    )}

                    {/**
                     * Loading/saving overlay
                     */}
                    {isSaving && (
                      <Center {...ABSOLUTE_COVER} bg="whiteAlpha.900">
                        <ImageActionSpinner />
                      </Center>
                    )}
                  </ImageListThumbnail>

                  {/**
                   * Edit image button
                   */}
                  {!readOnly && onImageEdited && labels?.edit && (
                    <SelectImageButton
                      minDimension={minDimension}
                      maxDimension={maxDimension}
                      onNewImageSelected={(file) =>
                        onEditedImageFileSelected(file[0], URL.createObjectURL(file[0]), image)
                      }
                      onError={onError}
                      visibility={maximumNumImages === 1 && images.length === 1 ? undefined : 'hidden'}
                      _groupHover={{ visibility: 'visible' }}
                    >
                      <Button
                        px={2}
                        m={0}
                        variant="outline"
                        leftIcon={<SimpleEditIcon />}
                        fontSize="sm"
                        isDisabled={isSaving}
                      >
                        {labels.edit}
                      </Button>
                    </SelectImageButton>
                  )}
                </Stack>
              );
            })}
          {!readOnly && labels?.add && images.length < maximumNumImages && (
            <SelectImageButton
              minDimension={minDimension}
              maxDimension={maxDimension}
              multiple={multiple}
              maxImageSize={maxImageSize}
              onNewImageSelected={onNewImageFileSelected}
              onError={onError}
              onImageDrag={setIsDragging}
              {...(images.length === 0 && { width: `${width}` })}
            >
              <ImageListThumbnail
                as={Center}
                size={thumbnailSize}
                fontSize="sm"
                textAlign="center"
                border="dashed"
                bg={fieldBackgroundColor}
                {...(images.length === 0 && width && { width: `${width}` })}
              >
                <VStack p={2}>
                  <SimpleCirclePlusIcon boxSize={6} stroke={theme.colors.blue[500]} />
                  <Text textAlign="center">
                    <Text as="span" fontWeight={700} color="greenBrand.light">
                      {labels.add}
                    </Text>
                    <Text whiteSpace="pre-line">{labels.drop}</Text>
                  </Text>
                </VStack>
              </ImageListThumbnail>
            </SelectImageButton>
          )}
        </Flex>
      </Flex>
    </DragAndDrop>
  );
};
