import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IRole } from '@graneet/business-logic';
import type { FC } from 'react';

import { isAdminRole } from '../../role/services/role.util';

interface UserRoleBadgeProps {
  role: IRole;
}

export const UserRoleBadge: FC<UserRoleBadgeProps> = ({ role }) => {
  const { t } = useTranslation(['user']);

  return (
    <Badge showDot colorScheme="gray" maxW="100%">
      {isAdminRole(role) ? t('user:role.administrator.name') : role.name}
    </Badge>
  );
};
