import type { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Card,
  CustomTooltipChart,
  GotoLink,
  MetricWithDescription,
  Price,
  SimpleAlertIcon,
  SimpleHelpIcon,
  Tooltip,
} from '@graneet/lib-ui';
import { MinusIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/react';

interface SupplierInvoiceAwaitingPaymentAmountCardProps {
  amount?: number;
  amountLateIncVAT?: number;
  amountToPayInLessThanThirtyDaysIncVAT?: number;
  amountToPayInMoreThanThirtyDaysIncVAT?: number;
  amountWithoutPaymentDateIncVAT?: number;
  redirectionLink?: string;
}

export const SupplierInvoiceAwaitingPaymentAmountCard: FC<SupplierInvoiceAwaitingPaymentAmountCardProps> = ({
  amount = 0,
  amountLateIncVAT,
  amountToPayInLessThanThirtyDaysIncVAT,
  amountToPayInMoreThanThirtyDaysIncVAT,
  amountWithoutPaymentDateIncVAT,
  redirectionLink = '/purchases/cash-outflows',
}) => {
  const { t } = useTranslation(['dashboard', 'supplierInvoices', 'project']);

  return (
    <Card
      title={t('dashboard:waitingSupplierInvoice')}
      topRightContent={<GotoLink to={redirectionLink} label={t(`supplierInvoices:cards.awaitingPaymentCard.goTo`)} />}
    >
      <MetricWithDescription fontSize="4xl" description={t('project:fields.totalAmountIncVAT')} color="red.600">
        {amount < 0 && <MinusIcon w="1rem" pb="0.5rem" mr="0.4rem" />}
        <Price amount={amount} negative={amount < 0} />

        <Tooltip
          label={
            <CustomTooltipChart
              legends={{
                latePayment: (
                  <Box color="orange.500">
                    <SimpleAlertIcon stroke="orange.500" mb="0.2rem" mr="0.2rem" />
                    {t('supplierInvoices:cards.awaitingPaymentCard.lateSupplierInvoices')}
                  </Box>
                ),

                toBePaidInLessThanThirtyDays: (
                  <Trans t={t} i18nKey="supplierInvoices:cards.awaitingPaymentCard.toPayInLessThanThirtyDays">
                    <Text as="span" fontWeight="semibold">
                      displayed_data
                    </Text>
                    description_end
                  </Trans>
                ),
                toBePaidInMoreThanThirtyDays: (
                  <Trans t={t} i18nKey="supplierInvoices:cards.awaitingPaymentCard.toPayInMoreThanThirtyDays">
                    <Text as="span" fontWeight="semibold">
                      displayed_data
                    </Text>
                    description_end
                  </Trans>
                ),
                noDeadline: t('supplierInvoices:cards.awaitingPaymentCard.noDeadline'),
              }}
              active
              hasBullets={false}
              payload={[
                { name: 'latePayment', value: amountLateIncVAT, textColor: 'orange.500' },
                { name: 'toBePaidInLessThanThirtyDays', value: amountToPayInLessThanThirtyDaysIncVAT },
                { name: 'toBePaidInMoreThanThirtyDays', value: amountToPayInMoreThanThirtyDaysIncVAT },
                { name: 'noDeadline', value: amountWithoutPaymentDateIncVAT },
              ]}
            />
          }
        >
          <Box>
            <SimpleHelpIcon color="gray.300" w="1.3rem" mb="0.5rem" ml="0.6rem" />
          </Box>
        </Tooltip>
      </MetricWithDescription>
    </Card>
  );
};
