import type { FC } from 'react';
import type { IProject, IProjectFinancialSummary } from '@graneet/business-logic';

import { UserAvatars } from 'features/user/components/UserAvatars';

// @[ff: project-financial-summary]
export const ProjectTableProjectAccountManagersCell: FC<{ data?: IProjectFinancialSummary | IProject }> = ({
  data,
}) => (
  <UserAvatars
    users={(data?.projectAccountManagers || []).map((projectAccountManager) => projectAccountManager.user)}
  />
);
