import type { FC } from 'react';
import { StepperModal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Text, VStack } from '@chakra-ui/react';
import { Form, useFormStatus, useStepForm } from 'graneet-form';

import type { ContractSelectionWizard } from '../ContractSelectionWizard';

import { ClientOrSupplierAssociationForm } from 'features/contact/components/forms/ClientOrSupplierAssociationForm';

export interface ClientSelectionStepProps {
  description: string;
}

export const ClientSelectionStep: FC<ClientSelectionStepProps> = ({ description }) => {
  const { t } = useTranslation(['quote', 'clients']);

  const { form } = useStepForm<ContractSelectionWizard, 'associate-client-quote'>();
  const { isValid: isFormValid } = useFormStatus(form);

  return (
    <Form form={form} style={{ width: '100%' }}>
      <VStack w="100%" align="stretch" pt="4">
        <Text alignSelf="self-start">{description}</Text>

        <ClientOrSupplierAssociationForm
          searchSuppliers={false}
          searchClients
          isRequired
          showBadge={false}
          placeholder={t('quote:clientStep.associationPlaceholder')}
        />
      </VStack>

      <StepperModal.CloseButton>{t('clients:modal.buttons.cancel')}</StepperModal.CloseButton>
      <StepperModal.PrimaryButton isDisabled={!isFormValid}>
        {t('quote:clientStep.cta.selectClient')}
      </StepperModal.PrimaryButton>
    </Form>
  );
};
