import { VStack, useDisclosure } from '@chakra-ui/react';
import { AddressCard, SellSheetHeader } from '@graneet/lib-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { IClient, IClientResponseDTO } from '@graneet/business-logic';

import { QuoteContactsCard } from '../../cards/QuoteContactsCard';

import { useStore } from 'store/store';
import { ClientUpsertModal } from 'features/client/components/modals/ClientUpsertModal';
import { useQuoteUpdateClient } from 'features/quotation/quote/hooks/useQuoteUpdateClient';
import { useQuoteUpdateContact } from 'features/quotation/quote/hooks/useQuoteUpdateContact';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const QuoteClient = () => {
  const { t } = useTranslation(['global', 'quote', 'clients', 'contacts']);
  const { quote } = useQuote();
  const hasClient = useMemo(() => quote.getClientId() !== null, [quote]);
  const quoteClient = useStore((state) => state.quoteClient);
  const isEditable = useStore((store) => store.quoteEditable);
  const clientEditionModal = useDisclosure();
  const quoteUpdateClient = useQuoteUpdateClient();
  const quoteUpdateContact = useQuoteUpdateContact();

  const onClientUpdated = useCallback(
    async (clientResponse: IClientResponseDTO) => {
      quoteUpdateClient(clientResponse);
    },
    [quoteUpdateClient],
  );

  const dissociateClient = useCallback(async () => {
    quoteUpdateClient(null);
    quoteUpdateContact(null);
  }, [quoteUpdateClient, quoteUpdateContact]);

  if (!quoteClient) return null;

  return (
    <VStack spacing={3} align="stretch">
      <SellSheetHeader
        title={quoteClient?.enterpriseName}
        subtitle={quoteClient?.code}
        isActive={hasClient}
        type="client"
        actions={
          isEditable
            ? [
                {
                  label: t('global:words.c.update'),
                  onClick: clientEditionModal.onOpen,
                },
                {
                  label: t('global:words.c.dissociate'),
                  onClick: dissociateClient,
                },
              ]
            : []
        }
      />
      <AddressCard title={t('quote:drawer.client.address.title')} data={quoteClient?.address} isEditable={false} />

      <QuoteContactsCard />

      {isEditable && (
        <ClientUpsertModal
          {...clientEditionModal}
          modalSize="xl"
          client={quoteClient as IClient}
          onSuccess={onClientUpdated}
          title={t('clients:editClient')}
          submitButtonLabel={t('global:words.c.update')}
        />
      )}
    </VStack>
  );
};
