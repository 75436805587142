import type { FC } from 'react';
import { useMemo, useRef, useCallback } from 'react';
import {
  Box,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  type MenuItemProps,
  MenuList,
  Portal,
  useDisclosure,
  Text,
  Stack,
} from '@chakra-ui/react';
import { EllipsisText, SimpleChevronBottomIcon, SimpleChevronRightIcon } from '@graneet/lib-ui';
import type { ICompany, ICompanyResponseDTO } from '@graneet/business-logic';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSwitchCompany } from 'features/user/services/user.api';
import { useUserCompanies } from 'features/company/services/company.api';
import { formatUserDisplayName } from 'features/user/services/user.util';
import { useAppContext } from 'features/app/contexts/AppContext';

const MENU_ITEM_HEIGHT = '2rem';

const HeaderMenuButton: FC<{
  isOpen: boolean;
  isFullSize?: boolean;
  name: string;
  company: ICompanyResponseDTO;
}> = ({ isOpen, name, company, isFullSize }) => (
  <HStack
    width="100%"
    maxWidth="14rem"
    background={isOpen ? 'white' : undefined}
    boxShadow={
      isOpen
        ? '0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)'
        : undefined
    }
    _hover={{
      background: 'white',
      cursor: 'pointer',
      boxShadow:
        '0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
    }}
    borderRadius={8}
    p={2}
    gap={3}
    alignItems="center"
  >
    <Image
      display="flex"
      boxSize={isFullSize ? '2rem' : '1.5rem'}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius={4}
      src={company.logo?.url ?? undefined}
    />
    {isFullSize && (
      <Stack
        spacing={0}
        fontStyle="normal"
        fontWeight={400}
        fontFamily="Alliance"
        width="100%"
        maxW="calc(100% - 40px)"
      >
        <Flex alignItems="center">
          <EllipsisText>{company.name}</EllipsisText>
          <SimpleChevronBottomIcon ml={3} fontSize="sm" />
        </Flex>

        <Text
          overflow="hidden"
          color="gray.600"
          textOverflow="ellipsis"
          maxWidth={160}
          whiteSpace="nowrap"
          fontSize=".75rem"
          fontStyle="normal"
          fontWeight={500}
          w="fit-content"
        >
          {name}
        </Text>
      </Stack>
    )}
  </HStack>
);

const HeaderMenuCompanySwitch: FC<{
  currentCompany: ICompanyResponseDTO;
  companies: ICompany[];
  switchToCompany: (companyId: string) => Promise<void>;
  isSubMenuOpen: boolean;
  openSubMenu: (isOpen: boolean) => void;
}> = ({ currentCompany, companies, switchToCompany, isSubMenuOpen, openSubMenu }) => {
  const searchRef = useRef<HTMLInputElement>(null);

  const othersCompanies = useMemo(
    () => (companies || []).filter(({ id }) => id !== currentCompany.id),
    [companies, currentCompany.id],
  );

  return (
    <>
      <Menu
        isLazy
        closeOnSelect={false}
        placement="right-start"
        isOpen={isSubMenuOpen}
        autoSelect={false}
        initialFocusRef={searchRef}
      >
        <MenuButton
          width="100%"
          as={Box}
          _hover={{ background: 'backgroundLight', cursor: 'pointer' }}
          onMouseOver={() => {
            if (othersCompanies.length > 0) {
              openSubMenu(true);
            }
          }}
        >
          <Box
            height="2rem"
            width="100%"
            color="gray.800"
            display="flex"
            alignItems="center"
            fontSize="sm"
            fontStyle="normal"
            fontWeight={500}
            _hover={{ background: 'backgroundLight', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <HStack px="8px" width="100%">
              <Image
                display="flex"
                boxSize="1.25rem"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                borderRadius={4}
                mr={1}
                src={currentCompany.logo?.url ?? undefined}
              />
              <Box width="12rem" textAlign="left">
                <EllipsisText>{currentCompany.name}</EllipsisText>
              </Box>
              {othersCompanies.length > 0 && <SimpleChevronRightIcon boxSize={3} marginLeft="auto" />}
            </HStack>
          </Box>
        </MenuButton>
        <MenuList
          zIndex={25}
          border="none"
          borderRadius={8}
          boxShadow="0px 27px 30px -15px rgba(0, 0, 0, 0.13), 0px 0px 3px -1px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
          onMouseLeave={() => openSubMenu(false)}
        >
          {othersCompanies.length > 0 &&
            othersCompanies.map((company) => (
              <MenuItem
                key={company.id}
                h={MENU_ITEM_HEIGHT}
                color="gray.800"
                fontSize="sm"
                fontStyle="normal"
                fontWeight={500}
                _hover={{ background: 'backgroundLight' }}
                onClick={() => switchToCompany(company.id.toString())}
              >
                <HStack width="100%">
                  <Image
                    display="flex"
                    boxSize="1.25rem"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius={4}
                    src={company.logo?.url ?? undefined}
                  />
                  <Box width="180px" display="flex">
                    <EllipsisText>{company.name}</EllipsisText>
                  </Box>
                </HStack>
              </MenuItem>
            ))}
        </MenuList>
      </Menu>

      <MenuDivider borderColor="gray.200" marginY={1} opacity={1} />
    </>
  );
};

const HeaderMenuItem: FC<
  Omit<MenuItemProps, 'icon'> & {
    icon: string;
    to?: string;
  }
> = ({ children, icon: Icon, to, onClick, ...otherProps }) => (
  <MenuItem
    h={MENU_ITEM_HEIGHT}
    color="gray.800"
    fontSize="sm"
    fontStyle="normal"
    fontWeight={500}
    _hover={{ background: 'backgroundLight', cursor: 'pointer' }}
    as={Link}
    to={to}
    onClick={onClick}
    {...otherProps}
  >
    <Flex
      direction="row"
      transitionDuration="0.2s"
      width="100%"
      _groupHover={{ color: 'gray.800' }}
      alignItems="center"
      justifyContent="start"
      gap={4}
    >
      <i className={Icon} />
      <Box as="span" color="gray.800">
        {children}
      </Box>
    </Flex>
  </MenuItem>
);

interface NavBarCompanySelectorProps {
  isFullSize: boolean;
}

export const NavBarCompanySelector: FC<NavBarCompanySelectorProps> = ({ isFullSize }) => {
  const { t } = useTranslation(['global']);
  const { currentUser, signOut } = useAppContext();

  const menu = useDisclosure();
  const subMenu = useDisclosure();

  const userCompanies = useUserCompanies();

  const switchCompanyMutation = useSwitchCompany();

  const handleOnClose = useCallback(() => {
    if (!subMenu.isOpen) {
      menu.onClose();
    }
  }, [menu, subMenu.isOpen]);

  const closeSubMenu = useCallback(() => {
    if (subMenu.isOpen) {
      subMenu.onClose();
    }
  }, [subMenu]);

  const onReferralClick = useCallback(() => {
    window.open('https://www.graneet.fr/parrainage', '_blank');
  }, []);

  if (!currentUser) {
    return null;
  }

  const { company } = currentUser;
  if (!company) {
    return null;
  }

  return (
    <Menu isLazy onClose={handleOnClose} isOpen={menu.isOpen} closeOnBlur={!subMenu.isOpen}>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            onClick={menu.onOpen}
            as={HStack}
            cursor="pointer"
            width={isFullSize ? '100%' : undefined}
            maxW={isFullSize ? 'calc(100% - 40px)' : undefined}
          >
            <HeaderMenuButton
              isOpen={isOpen}
              name={formatUserDisplayName(currentUser)}
              company={company}
              isFullSize={isFullSize}
            />
          </MenuButton>
          <Portal>
            <MenuList
              py={2}
              border="none"
              borderRadius={8}
              zIndex={40}
              boxShadow="0px 27px 30px -15px rgba(0, 0, 0, 0.13), 0px 0px 3px -1px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
            >
              {userCompanies.data.length > 0 && (
                <HeaderMenuCompanySwitch
                  currentCompany={company}
                  companies={userCompanies.data.slice(0, 10)}
                  switchToCompany={async (companyId) => {
                    await switchCompanyMutation.mutateAsync(companyId);
                    menu.onClose();
                  }}
                  isSubMenuOpen={subMenu.isOpen}
                  openSubMenu={subMenu.onOpen}
                />
              )}
              <HeaderMenuItem onMouseEnter={closeSubMenu} onClick={onReferralClick} to="#" icon="ri-megaphone-line">
                {t('global:nav.referral')}
              </HeaderMenuItem>
              <HeaderMenuItem
                onMouseEnter={closeSubMenu}
                onClick={onClose}
                to="/settings/user"
                icon="ri-user-settings-line"
              >
                {t('global:nav.userSettings')}
              </HeaderMenuItem>
              <HeaderMenuItem
                onMouseEnter={closeSubMenu}
                onClick={onClose}
                to="/settings/company"
                icon="ri-settings-2-line"
              >
                {t('global:nav.companySettingsHeader')}
              </HeaderMenuItem>
              <MenuDivider onMouseEnter={closeSubMenu} borderColor="gray.200" marginY={1} opacity={1} />
              <HeaderMenuItem onMouseEnter={closeSubMenu} onClick={signOut} icon="ri-logout-box-line">
                {t('global:nav.signOut')}
              </HeaderMenuItem>
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  );
};
