import type { IOrderItem } from '../order-item.type';
import type { IOrderLot } from '../order-lot.type';
import type { IComponentType } from '../../component-type/component-type.type';
import type { VAT_TYPE } from '../../vat/constant/vat-type.constant';

export type IOrderRelations = Record<string, { lots: string[]; items: string[] }>;
export type IOrderItemDTO = Pick<IOrderItem, 'id' | 'code' | 'description' | 'unit' | 'quantity'> &
  Partial<Pick<IOrderItem, 'unitPriceExVAT' | 'vatRate'>> & {
    typeId?: IComponentType['id'];
  };
export type IOrderItemRecord = Record<string, IOrderItemDTO>;

export type IOrderLotDTO = Pick<IOrderLot, 'id' | 'defaultVatRate'>;
export type IOrderLotRecord = Record<string, IOrderLotDTO>;

/**
 * We have to pass data associated to order files (if they are displayed in the order PDF). To ensure that a data is associated
 * to the right file, we created a name containing an index.
 */
type IOrderFileName = `orderfile_${number}`;
export const isOrderFileName = (name: string): name is IOrderFileName =>
  // Match  orderfile_${number}
  /^orderfile_(\d+)$/.exec(name) !== null;

export const buildOrderFileName = (index: number): IOrderFileName => `orderfile_${index}`;

export interface IOrderCreationDTO {
  name: string;

  orderNumber?: string;

  orderDate: string;

  deliveryDate?: string;

  deliveryAddress: string;

  note?: string;

  comment?: string;

  amountExVAT: `${number}` | 'null';

  vatRate: `${number}` | 'null';

  typeId?: string;

  supplierId: string;

  isDirectPayment?: `${boolean}`;

  projectId?: string;

  // Not null if and only if isDirectPayment is 'true'.
  subProjectId: string | 'null';

  associatedSupplierInvoices?: string;

  hasUnitPrices?: `${boolean}`;

  /**
   * IOrderRelations as string
   */
  relations?: string;

  /**
   * IOrderItemRecord type as string
   */
  items?: string;

  /**
   * IOrderLotRecord type as string
   */
  lots?: string;

  /**
   * Lot id
   */
  rootLotId?: string | `${null}`;

  vatType?: VAT_TYPE;

  /**
   * Order files are given in the form data following the name IOrderFileName. The last part representing the index.
   * isOrderFilesDisplayedInPDF is a boolean[] as string
   */
  isOrderFilesDisplayedInPDF?: string;

  isLedgerEnabled: `${boolean}`;

  isPriceRequest?: `${boolean}`;
}
