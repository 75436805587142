import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const CircleIcon: FC<IconProps> = ({ color = 'green.600', ...rest }) => (
  <Icon viewBox="0 0 16 16" color={color} {...rest} fill="none">
    <path
      d="M13.8337 7.99984C13.8337 11.2215 11.222 13.8332 8.00033 13.8332C4.77866 13.8332 2.16699 11.2215 2.16699 7.99984C2.16699 4.77817 4.77866 2.1665 8.00033 2.1665C11.222 2.1665 13.8337 4.77817 13.8337 7.99984Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
