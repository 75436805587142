import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { PERMISSION } from '@graneet/business-logic';

import { EditSubProjectParametersScreen } from './EditSubProjectParametersScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { DisplayNotFoundScreen } from 'screens/DisplayNotFoundScreen';

export const SubProjectsRouter = () => {
  const { path } = useRouteMatch();
  const hasCreateProjectsPermission = usePermissions([PERMISSION.CREATE_PROJECTS]);
  const hasCreateStatementsPermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);

  return (
    <Switch>
      {(hasCreateProjectsPermission || hasCreateStatementsPermission) && (
        <Route path={`${path}/:subProjectId/parameters`}>
          <QueryWrapper>
            <EditSubProjectParametersScreen />
          </QueryWrapper>
        </Route>
      )}

      <Route path="*">
        <DisplayNotFoundScreen />
      </Route>
    </Switch>
  );
};
