import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleChangeIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" strokeWidth="2" stroke="#334e68" {...props}>
    <path
      d="M12 21C12 21 12.8492 20.8787 16.364 17.364C19.8787 13.8492 19.8787 8.15076 16.364 4.63604C15.1187 3.39077 13.5993 2.58669 12 2.22383M12 21H18M12 21L12 15M8 1.00019C8 1.00019 7.15076 1.12152 3.63604 4.63624C0.12132 8.15095 0.12132 13.8494 3.63604 17.3642C4.88131 18.6094 6.40072 19.4135 8 19.7764M8 1.00019L2 1M8 1.00019L8 7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
