import type {
  GRANEET_PDF_COLORS,
  IPdfSettings,
  PDF_COMPANY_ADDRESS_POSITION,
  PDF_DENSITY,
  PDF_FONT,
  PDF_LABELS_POSITION,
  PDF_LOGO_POSITION,
} from '@graneet/business-logic';

export const PDF_SETTINGS_FIELDS = {
  LOGO_POSITION: 'logoPosition',
  LOGO_SIZE: 'logoSize',
  LABELS_DISPLAYED: 'labelsDisplayed',
  LABELS_POSITION: 'labelsPosition',
  LABELS_SIZE: 'labelsSize',
  COMPANY_ADDRESS_POSITION: 'companyAddressPosition',
  ACCOUNT_MANAGERS_VISIBILITY: 'areAccountManagersVisible',
  COVER_PAGE: 'hasFirstPageCover',
  DENSITY: 'density',
  FONT: 'font',
  COLOR: 'color',
  DISPLAY_CONTACTS: 'displayQuoteContacts',
  EXTERNAL_PROGRESS_STATEMENT_QR_CODE: 'displayExternalProgressStatementQrCode',
} as const;

export interface PDFSettingsFormValues {
  logoSize: number;

  logoPosition: PDF_LOGO_POSITION;

  labelsSize: number;

  labelsPosition: PDF_LABELS_POSITION;

  companyAddressPosition: PDF_COMPANY_ADDRESS_POSITION;

  areAccountManagersVisible: boolean;

  hasFirstPageCover: boolean;

  density: PDF_DENSITY;

  labelsDisplayed: boolean;

  font: PDF_FONT;

  color: GRANEET_PDF_COLORS;

  displayQuoteContacts: boolean;

  displayExternalProgressStatementQrCode: boolean;

  lotInformation: IPdfSettings['lotInformation'];
}

export const PDFSettingsStep = {
  EDIT_PDF_SETTINGS: 'edit-pdf-settings',
} as const;

export type PDFSettingsWizardValues = {
  [PDFSettingsStep.EDIT_PDF_SETTINGS]: PDFSettingsFormValues;
};
