import type { FC } from 'react';
import type { TextProps } from '@chakra-ui/react';

import type { DropdownItem } from '../types';
import { ActionMenu } from '../../ActionMenu/ActionMenu';
import { SimpleSettingsIcon } from '../../Icons';

import { configureDefaultLabels } from './DifferenceSummaryTranslations';
import { DifferenceSummaryItem } from './DifferenceSummaryItem';

export interface DiscountProps extends TextProps {
  label: string;

  initialDiscountAmount: number;

  newDiscountAmount: number;

  isDiscountRemoved?: boolean;

  dropdownItems?: DropdownItem[];

  hasOppositeSign?: boolean;
}

export const DiscountComponent: FC<DiscountProps> = ({
  initialDiscountAmount,
  newDiscountAmount,
  isDiscountRemoved = false,
  dropdownItems,
  hasOppositeSign = true,
  ...props
}) => {
  const rightContent =
    dropdownItems && dropdownItems.length > 0 ? (
      <ActionMenu placement="top-start" iconSize={6}>
        {dropdownItems.map((item) => (
          <ActionMenu.Action
            key={`item_${item.label}`}
            label={item.label}
            icon={item.icon || <SimpleSettingsIcon />}
            onClick={item.onClick}
            warning={item.warning}
          />
        ))}
      </ActionMenu>
    ) : null;

  return (
    <DifferenceSummaryItem
      light
      initialAmount={initialDiscountAmount}
      newAmount={newDiscountAmount}
      rightContent={rightContent}
      isRemoved={isDiscountRemoved}
      sign
      negative={hasOppositeSign}
      {...props}
    />
  );
};

export const Discount = Object.assign(DiscountComponent, {
  configureDefaultLabels,
});
