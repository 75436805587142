import { CUSTOM_DISCOUNT_TYPES } from '@graneet/business-logic';
import {
  ActionMenu,
  DrawersStack,
  SimpleAddIcon,
  SimpleCustomDiscountIcon,
  SimpleDiscountIcon,
  SimpleProrataIcon,
  useDrawer,
  useDrawersStack,
} from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomDiscountEditionForm } from 'features/custom-discount/forms/custom-discount-edition-form';
import { ContractCustomDiscountDrawer } from 'features/contract/components/modals/ContractCustomDiscountDrawer';
import { ContractDiscountDrawer } from 'features/contract/components/modals/ContractDiscountDrawer';

interface ContractAddDiscountsMenuProps {
  totalAmountWithDiscountExVAT: number;

  totalAmountWithoutDiscountExVAT: number;

  hasDiscount: boolean;
}

export const ContractAddDiscountsMenu: FC<ContractAddDiscountsMenuProps> = ({
  totalAmountWithDiscountExVAT,
  totalAmountWithoutDiscountExVAT,
  hasDiscount,
}) => {
  const { t } = useTranslation(['customDiscount', 'contracts']);
  const drawersStack = useDrawersStack();
  const discountDrawer = useDrawer('drawer-discount', drawersStack);
  const customDiscountDrawer = useDrawer('drawer-custom-discount', drawersStack);

  const [initialCustomDiscountFormValues, setInitialCustomDiscountValues] =
    useState<Partial<CustomDiscountEditionForm> | null>(null);

  const onCustomDiscountDrawerOpen = useCallback(
    ({ hasInitialValues }: { hasInitialValues: boolean }) => {
      if (hasInitialValues) {
        setInitialCustomDiscountValues({
          customDiscountName: t('customDiscount:defaultValues.name'),
          customDiscountVatRate: 20,
          customDiscountType: CUSTOM_DISCOUNT_TYPES.AMOUNT,
        });
      } else {
        setInitialCustomDiscountValues({ customDiscountType: CUSTOM_DISCOUNT_TYPES.AMOUNT });
      }

      customDiscountDrawer.onOpen();
    },
    [customDiscountDrawer, t],
  );

  return (
    <>
      <ActionMenu
        buttonProps={{
          variant: 'secondary',
          leftIcon: <SimpleAddIcon />,
          children: t('contracts:discounts.addDiscountOrCustomValue'),
        }}
      >
        <ActionMenu.Action
          label={t('contracts:discounts.actions.addDiscount')}
          icon={<SimpleDiscountIcon />}
          onClick={discountDrawer.onOpen}
          tooltip={hasDiscount && t('contracts:discounts.addOnlyOneDiscountTooltip')}
          isDisabled={hasDiscount}
        />
        <ActionMenu.Action
          label={t('contracts:discounts.actions.addProrata')}
          onClick={() => onCustomDiscountDrawerOpen({ hasInitialValues: true })}
          icon={<SimpleProrataIcon />}
        />
        <ActionMenu.Action
          label={t('contracts:discounts.actions.addCustomDiscount')}
          onClick={() => onCustomDiscountDrawerOpen({ hasInitialValues: false })}
          icon={<SimpleCustomDiscountIcon />}
        />
      </ActionMenu>

      <DrawersStack drawersStack={drawersStack}>
        <ContractCustomDiscountDrawer
          drawer={customDiscountDrawer}
          totalAmountWithDiscountExVAT={totalAmountWithDiscountExVAT}
          initialCustomDiscountFormValues={initialCustomDiscountFormValues}
        />
      </DrawersStack>

      <DrawersStack drawersStack={drawersStack}>
        <ContractDiscountDrawer
          totalAmountWithoutDiscountExVAT={totalAmountWithoutDiscountExVAT}
          drawer={discountDrawer}
        />
      </DrawersStack>
    </>
  );
};
