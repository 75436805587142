import { SellsheetMarginCard } from '@graneet/lib-ui';
import { MARGIN_TYPE } from '@org/quotation-lib';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Big from 'big.js';

import { useQuote } from '../../hooks/useQuote';

import { useQuoteLotUpdateMargin } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateMargin';
import { useStore } from 'store/store';
import { updateQuoteAmountIsUnreachable } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';

export const QuoteSellSheetLotMarginCard: FC<{ nodeId: string; handleOpenModal?: () => void }> = ({
  nodeId,
  handleOpenModal,
}) => {
  const { t } = useTranslation(['quote']);
  const isEditable = useStore((store) => store.quoteEditable);

  const { quote } = useQuote();
  const quoteLotUpdateMargin = useQuoteLotUpdateMargin();

  const { executeCommand } = useCommands();

  const quoteTree = quote.getTree();

  const quoteLot = quoteTree.getQuoteLotOrThrow(nodeId);

  const quoteLotMargin = useMemo(() => quoteLot.getMargin().export(), [quoteLot]);

  const updateLotAmount = (record: Record<MARGIN_TYPE, string>) => {
    if (!isEditable) {
      return;
    }

    if (
      record.OverheadCosts &&
      !Big(record.OverheadCosts).eq(Big(quoteLotMargin.overheadCosts).round(3, Big.roundDown))
    ) {
      executeCommand(quoteLotUpdateMargin(nodeId, record.OverheadCosts, MARGIN_TYPE.OVERHEAD_COSTS), quote);
    }

    if (record.ProfitMargin && !Big(record.ProfitMargin).eq(Big(quoteLotMargin.profitMargin).round(3, Big.roundDown))) {
      executeCommand(quoteLotUpdateMargin(nodeId, record.ProfitMargin, MARGIN_TYPE.PROFIT_MARGIN), quote);
    }

    if (record.TotalMargin && !Big(record.TotalMargin).eq(Big(quoteLotMargin.totalMargin).round(3, Big.roundDown))) {
      executeCommand(quoteLotUpdateMargin(nodeId, record.TotalMargin, MARGIN_TYPE.TOTAL_MARGIN), quote);
    }

    setTimeout(() => updateQuoteAmountIsUnreachable(false), 5000);
  };

  return (
    <SellsheetMarginCard
      isEditable={isEditable}
      margin={quoteLotMargin}
      onMarginChange={updateLotAmount}
      cta={
        handleOpenModal
          ? {
              label: t('quote:drawer.sale.quoteSellSheetLotMarginCard.componentMargin'),
              onClick: handleOpenModal,
            }
          : undefined
      }
    />
  );
};
