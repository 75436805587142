import { create } from 'zustand';
import { fromPairs } from 'lodash-es';

import { createQuoteCommandSlice } from 'features/quotation/quote-common/store/quoteCommandHistorySlice';
import type { QuoteCommandHistorySlice } from 'features/quotation/quote-common/store/quoteCommandHistorySlice';
import type { QuoteSlice } from 'features/quotation/quote-common/store/quoteSliceZustand';
import { createQuoteSlice } from 'features/quotation/quote-common/store/quoteSliceZustand';
import type { QuoteUndoRedoSlice } from 'features/quotation/quote-common/store/quoteUndoRedoSlice';
import { createQuoteUndoRedoSlice } from 'features/quotation/quote-common/store/quoteUndoRedoSlice';

interface AutoNumberingState {
  autoNumberingIsActivated: boolean;
  autoNumberingSetIsActivated: (isActivated: boolean) => void;

  autoNumberingIsActivatedByDefault: boolean;
  autoNumberingSetIsActivatedByDefault: (isActivated: boolean) => void;

  autoNumberingTable: Record<string, string>;
  autoNumberingSetTable: (table: Map<string, string>) => void;
}

export enum OptionalLotsTableStatus {
  HAS_NO_OPTION = 'hasNoOptions',
  HAS_ONLY_OPTIONS = 'hasOnlyOptions',
  HAS_ONE_OR_MORE_OPTIONS = 'hasOneOrMoreOptions',
}

interface OptionalLotsState {
  optionalLotsTable: Record<string, boolean>;
  optionalLotsSetTable: (table: Map<string, boolean>) => void;

  optionalLotsTableStatus: OptionalLotsTableStatus | undefined;
  optionalLotsSetTableStatus: (status: OptionalLotsTableStatus) => void;

  optionalLotsTableWithoutJob: Record<string, boolean>;
  optionalLotsSetTableWithoutJob: (table: string[]) => void;
}

interface ContractDepthState {
  contractDepthMaxDepth: number;
  contractDepthSetMaxDepth: (maxDepth: number) => void;
}

interface HiddenCostState {
  hasHiddenCosts: boolean;
  setHasHiddenCosts: (hasHiddenCosts: boolean) => void;
}

export interface ClientRequest {
  clientRequestId: string;
  timestamp: string;
  nbEventPusherTreated?: number;
}

interface QuoteRequestState {
  clientRequests: ClientRequest[];
}

interface ConflictState {
  isRequestInConflict: boolean;
  setIsRequestInConflict: (isRequestInClonflict: boolean) => void;
}

interface TokenState {
  token: string | null;
  setToken: (token: string) => void;
}

interface BeaconState {
  shouldDisplayBeacon: boolean;
  setShouldDisplayBeacon(shouldDisplayBeacon: boolean): void;
}

export const useStore = create<
  AutoNumberingState &
    OptionalLotsState &
    HiddenCostState &
    ContractDepthState &
    QuoteSlice &
    QuoteRequestState &
    ConflictState &
    TokenState &
    QuoteCommandHistorySlice &
    BeaconState &
    QuoteUndoRedoSlice
>()((set) => ({
  ...createQuoteSlice(),
  ...createQuoteCommandSlice(),
  ...createQuoteUndoRedoSlice(),
  /**
   * Auto-numbering
   */
  autoNumberingIsActivated: false,
  autoNumberingSetIsActivated: (isActivated: boolean) => set({ autoNumberingIsActivated: isActivated }),

  autoNumberingIsActivatedByDefault: false,
  autoNumberingSetIsActivatedByDefault: async (autoNumberingIsActivatedByDefault: boolean) =>
    set({ autoNumberingIsActivatedByDefault }),

  autoNumberingTable: {},
  autoNumberingSetTable: (autoNumberingTable: Map<string, string>) =>
    set({ autoNumberingTable: fromPairs(Array.from(autoNumberingTable)) }), // TODO improve, this is a dirty mapping

  /**
   * Optional lots
   */
  optionalLotsTable: {},
  optionalLotsSetTable: (optionalLotsTable: Map<string, boolean>) =>
    set({ optionalLotsTable: fromPairs(Array.from(optionalLotsTable)) }), // TODO improve, this is a dirty mapping

  optionalLotsTableStatus: undefined,
  optionalLotsSetTableStatus: (optionalLotsTableStatus: OptionalLotsTableStatus) => set({ optionalLotsTableStatus }),

  optionalLotsTableWithoutJob: {},
  optionalLotsSetTableWithoutJob: (optionalLotsTableWithoutJob: string[]) =>
    set({
      optionalLotsTableWithoutJob: optionalLotsTableWithoutJob.reduce<Record<string, boolean>>((acc, id) => {
        acc[id] = true;
        return acc;
      }, {}),
    }), // TODO improve, this is a dirty mapping

  /**
   * Contract depth
   */
  contractDepthMaxDepth: 0,
  contractDepthSetMaxDepth: (contractDepthMaxDepth: number) => set({ contractDepthMaxDepth }),

  /**
   * HiddenCosts
   */
  hasHiddenCosts: false,
  setHasHiddenCosts: (hasHiddenCosts: boolean) => set({ hasHiddenCosts }),

  /**
   * QuoteRequestState
   */
  clientRequests: [],
  setClientRequests: (clientRequests: ClientRequest[]) => set({ clientRequests }),

  /**
   * ConflictState
   */
  isRequestInConflict: false,
  setIsRequestInConflict: (isRequestInConflict: boolean) => set({ isRequestInConflict }),

  /**
   * Token
   */
  token: null,
  setToken: (token: string) => set({ token }),

  /**
   * Beacon
   */
  shouldDisplayBeacon: true,
  setShouldDisplayBeacon: (shouldDisplayBeacon: boolean) => set({ shouldDisplayBeacon }),
}));
