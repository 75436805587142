/* eslint-disable no-param-reassign */
import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { ButtonProps } from '@chakra-ui/react';
import { Box, Button } from '@chakra-ui/react';
import { Tooltip } from '@graneet/lib-ui';

import { getEnvValue } from 'config/env';

function loadExternalScript(document: Document, scriptUrl: string): void {
  const firstScript = document.getElementsByTagName('script')[0];
  const newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.async = true;
  newScript.src = scriptUrl;
  firstScript.parentNode!.insertBefore(newScript, firstScript);
}

export function initializeBeacon(window: Window, document: Document, REACT_APP_BEACON_URL: string): void {
  window.Beacon = (method: string, options: any, data: any) => {
    window.Beacon.readyQueue.push({ method, options, data });
  };

  window.Beacon.readyQueue = [];

  if (document.readyState === 'complete') {
    loadExternalScript(document, REACT_APP_BEACON_URL);
    return;
  }

  if (window.attachEvent) {
    window.attachEvent('onload', () => loadExternalScript(document, REACT_APP_BEACON_URL));
  }
}

export const Beacon: FC<ButtonProps> = (props) => {
  const REACT_APP_BEACON_ID = getEnvValue('REACT_APP_BEACON_ID');
  const REACT_APP_BEACON_URL = getEnvValue('REACT_APP_BEACON_URL');
  const IS_PRODUCTION = process.env.NODE_ENV === 'production';

  const { t } = useTranslation(['global']);

  useEffect(() => {
    if (REACT_APP_BEACON_ID && REACT_APP_BEACON_URL && IS_PRODUCTION) {
      initializeBeacon(window, document, REACT_APP_BEACON_URL.toString());

      if (window.Beacon) {
        window.Beacon('init', REACT_APP_BEACON_ID);
        window.Beacon('config', {
          hideAvatars: true,
          display: {
            style: 'manual',
          },
        });
      }
    }
    return () => {
      window.Beacon?.('destroy');
    };
  }, [IS_PRODUCTION, REACT_APP_BEACON_ID, REACT_APP_BEACON_URL]);

  const onClick = () => {
    window.Beacon?.('toggle');
  };

  if (!window.Beacon) {
    return null;
  }

  return (
    <Tooltip label={t('global:nav.needHelp')} placement="left">
      <Button
        position="absolute"
        top="-3.4rem"
        right="1rem"
        zIndex={50}
        onClick={onClick}
        borderRadius="100%"
        backgroundColor="black"
        width="2.5rem"
        height="2.5rem"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={0}
        _hover={{ backgroundColor: 'black' }}
        color="white"
        boxShadow="0px 2px 5px -2px rgba(0, 0, 0, 0.06),0px 0px 0px 1px rgba(0, 0, 0, 0.05)"
        {...props}
      >
        <Box display="flex" justifyContent="center" alignItems="center" fontSize="lg" fontWeight={600}>
          ?
        </Box>
      </Button>
    </Tooltip>
  );
};
