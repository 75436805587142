import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleUserIcon: FC<IconProps> = ({ fill, ...props }) => (
  <Icon viewBox="0 0 13 14" fill="none" {...props}>
    <path
      d="M13 13C13 11.7467 12.98 11.4533 12.8533 11.0267C12.5267 9.96 11.6933 9.12667 10.6267 8.8C10.2 8.66667 9.89999 8.65333 8.65333 8.65333H5.31999C4.06666 8.65333 3.77333 8.66667 3.34666 8.79333C2.27999 9.11333 1.44666 9.94666 1.11999 11.0133C0.986661 11.4333 0.973328 11.7333 0.973328 12.98C0.973328 13.3467 1.26666 13.6467 1.63999 13.6467C2.00666 13.6467 2.30666 13.3467 2.30666 12.98C2.30666 11.9 2.31999 11.6333 2.38666 11.3933C2.57999 10.7533 3.07999 10.2533 3.71333 10.0533C3.94666 9.98 4.21333 9.96667 5.29333 9.96667H8.62666C9.69999 9.96667 9.96666 9.98 10.2067 10.0467C10.84 10.24 11.34 10.74 11.5333 11.38C11.6 11.6133 11.6133 11.88 11.6133 12.96C11.6133 13.3267 11.9067 13.6267 12.28 13.6267C12.6467 13.6267 12.9467 13.3267 12.9467 12.96L13 13ZM9.33333 4C9.33333 5.28666 8.28666 6.33333 6.99999 6.33333C5.70666 6.33333 4.66666 5.28666 4.66666 4C4.66666 2.70667 5.70666 1.66667 6.99999 1.66667C8.28666 1.66667 9.33333 2.70667 9.33333 4ZM10.6667 4C10.6667 1.97333 9.01999 0.333332 6.99999 0.333332C4.97333 0.333332 3.33333 1.97333 3.33333 4C3.33333 6.02 4.97333 7.66667 6.99999 7.66667C9.01999 7.66667 10.6667 6.02 10.6667 4Z"
      fill="currentColor"
    />
  </Icon>
);
