import { useCallback, useEffect, useMemo } from 'react';
import { Grid, Stack } from '@chakra-ui/react';
import { Form, useForm } from 'graneet-form';
import { PDF_TEMPLATES_TYPES, PERMISSION } from '@graneet/business-logic';
import { Container } from '@graneet/lib-ui';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useCompanyLegalMentions, useUpdateCompanyLegalMentions } from 'features/company/services/company.api';
import type { LegalMentionsForm, SectionName } from 'features/company/components/CompanyLegalMentionsSectionField';
import { CompanyLegalMentionsSectionField } from 'features/company/components/CompanyLegalMentionsSectionField';
import { PdfSettingsCard } from 'features/pdf/components/PdfSettingCard';

export const PdfTemplatesTabs = () => {
  const canAccessPdfSettings = usePermissions([PERMISSION.UPDATE_COMPANY_INFORMATIONS]);

  const legalMentions = useCompanyLegalMentions();
  const updateCompanyLegalMentionsMutation = useUpdateCompanyLegalMentions();

  const onUpdateAfterBlur = useCallback(
    async (name: SectionName, value: string) => {
      updateCompanyLegalMentionsMutation.mutate({ [name]: value });
    },
    [updateCompanyLegalMentionsMutation],
  );

  const formOptions = useMemo(() => ({ onUpdateAfterBlur }), [onUpdateAfterBlur]);
  const form = useForm<LegalMentionsForm>(formOptions);

  useEffect(() => {
    form.setFormValues(legalMentions.data);
  }, [legalMentions.data, form]);

  return (
    <Container size="md">
      <Stack spacing={8}>
        {canAccessPdfSettings && (
          <Grid templateColumns="1fr 1fr" gap={8}>
            <PdfSettingsCard templateType={PDF_TEMPLATES_TYPES.QUOTE} />
            <PdfSettingsCard templateType={PDF_TEMPLATES_TYPES.STATEMENT} />
          </Grid>
        )}

        <Form form={form}>
          <CompanyLegalMentionsSectionField sectionName="orderNotes" />
          <CompanyLegalMentionsSectionField sectionName="quoteNotes" />
          <CompanyLegalMentionsSectionField sectionName="termsOfSales" />
          <CompanyLegalMentionsSectionField sectionName="legalBillingNotice" />
          <CompanyLegalMentionsSectionField sectionName="documentFooter" />
        </Form>
      </Stack>
    </Container>
  );
};
