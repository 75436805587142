import { useCallback } from 'react';
import type { QuoteComposeDTOProject } from '@org/graneet-bff-client';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuote } from './useQuote';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { updateQuoteProject } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import {
  useQuoteSetClientRequestsStore,
  StatusEnum,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteUpdateProject() {
  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const { quoteProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    (project: QuoteComposeDTOProject | null): boolean => {
      try {
        quote.updateProjectId(project ? project.id : null, project?.address);

        quoteSetToStore(quote);
        updateQuoteProject(project);

        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
        quoteProxyApi
          .updateQuoteAsync({
            projectId: project ? project.id : null,
            quoteId: quote.getId(),
            timestamp: new Date().toString(),
            clientRequestId: uuid(),
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteError, quoteProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
