import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import {
  SingleSelectField,
  Callout,
  SimpleAlertIcon,
  Modal,
  TextField,
  TwoColumns,
  CurrencyField,
  useCurrency,
} from '@graneet/lib-ui';
import { Form, useForm, useFormContext, useFormStatus, useOnBlurValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IWorker, IWorkerDTO } from '@graneet/business-logic';
import { getValidPhoneNumber } from '@graneet/business-logic';

import { useWorkerCreate, useWorkerUpdate } from '../services/worker.api';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { Rule } from 'features/form/rules/Rule';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';

const FORM_ID = 'worker';

interface WorkerUpsertForm {
  componentTypeId: number;
  lastName: string;
  firstName: string;
  email?: string | null;
  phoneNumber?: string | null;
  role?: string;
  hourlyPriceExVAT: number;
  employeeId?: string | null;
}

const PhoneNumberCalloutWarning = () => {
  const { t } = useTranslation(['global']);

  const form = useFormContext<WorkerUpsertForm>();
  const { phoneNumber } = useOnBlurValues(form, ['phoneNumber']);

  const validPhoneNumber = getValidPhoneNumber(phoneNumber);
  if (!phoneNumber || validPhoneNumber.status === 'ok') {
    return null;
  }

  return (
    <Callout colorScheme="yellow" icon={<SimpleAlertIcon boxSize={5} stroke="yellow.500" />}>
      {t('global:toasts.incorrectPhoneNumber')}
    </Callout>
  );
};

type Option = { label: string; value: string };

interface WorkerUpsertModalProps {
  isOpen: boolean;

  onClose(): void;

  onSuccess(): void;

  worker: IWorker | null;

  roles: string[];
}

const WorkerUpsertModalInternal: FC<WorkerUpsertModalProps> = ({ onSuccess, onClose, worker, roles }) => {
  const { t } = useTranslation(['global', 'worker']);

  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const form = useForm<WorkerUpsertForm>({
    defaultValues: {
      componentTypeId: worker?.componentType?.id,
      firstName: worker?.firstName,
      lastName: worker?.lastName,
      role: worker?.role ?? undefined,
      email: worker?.email,
      phoneNumber: worker?.phoneNumber,
      hourlyPriceExVAT: worker ? mapNumberToAmount(worker.hourlyPriceExVAT) : undefined,
      employeeId: worker?.employeeId,
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const workerCreateMutation = useWorkerCreate();
  const workerUpdateMutation = useWorkerUpdate();

  const onSubmit = useCallback(
    async (formValues: WorkerUpsertForm) => {
      const body: IWorkerDTO = {
        componentTypeId: formValues.componentTypeId,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        role: formValues.role ?? '',
        email: formValues.email ?? undefined,
        phoneNumber: formValues.phoneNumber ?? undefined,
        hourlyPriceExVAT: mapAmountToNumber(formValues.hourlyPriceExVAT),
        employeeId: formValues.employeeId,
      };

      const afterSuccess = () => {
        onSuccess();
        onClose();
      };

      if (worker) {
        workerUpdateMutation.mutate({ id: worker.id, dto: body }, { onSuccess: afterSuccess });
      } else {
        workerCreateMutation.mutate(body, { onSuccess: afterSuccess });
      }
    },
    [mapAmountToNumber, onClose, onSuccess, worker, workerCreateMutation, workerUpdateMutation],
  );

  const options = useMemo(
    () =>
      roles.map<Option>((role) => ({
        label: role,
        value: role,
      })),
    [roles],
  );

  return (
    <Form form={form} id={FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
      {worker && <Text>{t('worker:editionModal.description')}</Text>}

      <VStack justifyContent="stretch" alignItems="stretch" spacing={6} my={4}>
        <ComponentTypeField<WorkerUpsertForm>
          label={t('worker:fields.workforceDisbursementType')}
          name="componentTypeId"
          isRequired
          workforce
        >
          <Rule.IsRequired />
        </ComponentTypeField>

        <TextField<WorkerUpsertForm> name="employeeId" label={t('worker:fields.employeeId')} />

        <TwoColumns>
          <TextField<WorkerUpsertForm> name="lastName" label={t('worker:fields.lastName')} isRequired>
            <Rule.IsRequired />
          </TextField>
          <TextField<WorkerUpsertForm> name="firstName" label={t('worker:fields.firstName')} isRequired>
            <Rule.IsRequired />
          </TextField>
        </TwoColumns>

        <TwoColumns>
          <TextField<WorkerUpsertForm> name="email" label={t('worker:fields.email')}>
            <Rule.IsEmail />
          </TextField>
          <TextField<WorkerUpsertForm> name="phoneNumber" label={t('worker:fields.phoneNumber')} />
        </TwoColumns>

        <TwoColumns>
          <SingleSelectField<WorkerUpsertForm>
            name="role"
            placeholder={t('worker:fields.rolePlaceholder')}
            label={t('worker:fields.role')}
            options={options}
            isCreatable
          />
          <CurrencyField<WorkerUpsertForm>
            name="hourlyPriceExVAT"
            label={t('worker:fields.hourlyPriceExVAT')}
            min={0}
            isRequired
          >
            <Rule.IsRequired />
          </CurrencyField>
        </TwoColumns>

        <PhoneNumberCalloutWarning />
      </VStack>

      <Modal.Close />

      <Modal.PrimaryButton
        isLoading={workerCreateMutation.isPending || workerUpdateMutation.isPending}
        isDisabled={!isFormValid}
        type="submit"
        form={FORM_ID}
      >
        {worker ? t('worker:editionModal.action') : t('worker:creationModal.action')}
      </Modal.PrimaryButton>
    </Form>
  );
};

export const WorkerUpsertModal: FC<WorkerUpsertModalProps> = ({ isOpen, onClose, worker, roles, onSuccess }) => {
  const { t } = useTranslation(['worker']);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={worker ? t('worker:editionModal.title') : t('worker:creationModal.title')}
      size="2xl"
      isCentered
      scrollBehavior="inside"
    >
      <QueryWrapper>
        <WorkerUpsertModalInternal
          key={`${isOpen}`} // Reset modal value on open
          worker={worker}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={onSuccess}
          roles={roles}
        />
      </QueryWrapper>
    </Modal>
  );
};
