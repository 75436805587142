import type { ICompany, IProject, ITimeSlot, ITimeTrackingResponse, IWorker } from '@graneet/business-logic';
import type { PaginatedResponse, TimeTableMode } from '@graneet/lib-ui';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext } from 'react';

import type { ReducerAction, TimeTrackingReducerState } from '../services/time-tracking.reducer';
import { initialState } from '../services/time-tracking.reducer';

interface ModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  isControlled: boolean;
  getButtonProps: (props?: any) => any;
  getDisclosureProps: (props?: any) => any;
}

export interface ITimeTrackingContext {
  handleDeleteSlots: (slots: ITimeSlot[]) => Promise<void>;
  handleDateChange: (value: Date | string) => void;
  handleSearchChange: (value?: string) => void;
  handleCreateSlot: (id: number, subEntityId: number | string, isOff: boolean, date: Date) => void;
  handleViewChange: (value: 'week' | 'month') => void;
  handleZoomWeek: (value: Date) => void;
  handleGroupByChange: (value: 'worker' | 'project') => void;
  handleEditSlot: (slot: Partial<ITimeSlot>) => void;
  refetch: () => Promise<void>;
  setWeekData: Dispatch<SetStateAction<ITimeTrackingResponse<IProject | IWorker> | null>>;
  dispatch: Dispatch<ReducerAction>;
  fetch: (...params: unknown[]) => Promise<void>;
  modalCreateSlot: ModalProps;
  modalEditSlot: ModalProps;
  settingsData: Pick<ICompany, 'ttNbMinutesWorkingDay' | 'ttShowWeekends' | 'ttStartHour' | 'ttEndHour'> | null;
  weekData: ITimeTrackingResponse<IProject | IWorker> | null;
  workers: IWorker[] | null;
  projects: PaginatedResponse<IProject> | null;
  slotData: ITimeTrackingResponse<IProject | IWorker> | null;
  state: TimeTrackingReducerState;
  updateMode: (mode: TimeTableMode) => void;
  mode: TimeTableMode;
}

const defaultModal: ModalProps = {
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  onToggle: () => {},
  isControlled: false,
  getButtonProps: () => {},
  getDisclosureProps: () => {},
};

export const TimeTrackingContext = createContext<ITimeTrackingContext>({
  handleDeleteSlots: async () => {},
  handleDateChange: () => {},
  handleSearchChange: () => {},
  handleCreateSlot: () => {},
  handleViewChange: () => {},
  handleGroupByChange: () => {},
  handleZoomWeek: () => {},
  handleEditSlot: () => {},
  refetch: async () => {},
  setWeekData: () => {},
  dispatch: () => {},
  fetch: async () => {},
  modalCreateSlot: defaultModal,
  modalEditSlot: defaultModal,
  settingsData: null,
  weekData: null,
  workers: null,
  projects: null,
  slotData: null,
  state: initialState,
  updateMode: () => {},
  mode: 'default',
});

export const useTimeTrackingContext = () => useContext(TimeTrackingContext);
