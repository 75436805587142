import type { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { PriceAdvanced, Wizard } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

const TOTAL_AMOUNT_STYLE = {
  fontFamily: 'heading',
  fontWeight: 700,
  fontSize: 14,
};

const DIFFERENCE_AMOUNT_STYLE = {
  fontWeight: 600,
  fontSize: 16,
};

export interface ContractTotalAmountProps {
  totalAmountExVAT: number;

  differenceAmountExVAT: number;
}

export const ContractTotalAmount: FC<ContractTotalAmountProps> = ({ totalAmountExVAT, differenceAmountExVAT }) => {
  const { t } = useTranslation(['contracts']);

  return (
    <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_RIGHT}>
      <Box bg="gray.50" borderRadius="8px" h="1.75rem" px={6} border="1px solid" borderColor="gray.150">
        <Flex h="100%" justifyContent="center" alignItems="center">
          <Text {...TOTAL_AMOUNT_STYLE}>{`${t('contracts:wizard.totalAmountExVAT')} :`}</Text>

          <PriceAdvanced amount={totalAmountExVAT} ml={1} {...TOTAL_AMOUNT_STYLE} />

          {differenceAmountExVAT !== 0 && (
            <PriceAdvanced amount={differenceAmountExVAT} ml={3} isAmountColored sign {...DIFFERENCE_AMOUNT_STYLE} />
          )}
        </Flex>
      </Box>
    </Wizard.Placeholder>
  );
};
