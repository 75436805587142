import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Flex, useDisclosure, Text } from '@chakra-ui/react';
import type { IClient, IClientResponseDTO, ISecondaryContactProject } from '@graneet/business-logic';
import { Button, SimpleAddIcon, SimpleDeleteIcon } from '@graneet/lib-ui';

import { ClientSwitchModal } from '../modals/ClientSwitchModal';

import { ClientCardLine } from './ClientCardLine';

import { useClientsWithoutPagination } from 'features/client/services/client.api';

interface SecondaryClientCardProps {
  client: IClientResponseDTO | IClient;
  projectId: number;
  updateSecondaryClient: (clientId: number) => Promise<ISecondaryContactProject[] | null>;
  removeSecondaryClient: (clientId: number) => Promise<void>;
}

export const SecondaryClientCard: FC<SecondaryClientCardProps> = ({
  client,
  projectId,
  updateSecondaryClient,
  removeSecondaryClient,
}) => {
  const { t } = useTranslation(['global', 'project', 'clients']);

  const addSecondaryClientModal = useDisclosure();

  const clients = useClientsWithoutPagination({ projectId });

  const handleRemove = useCallback(async () => {
    await removeSecondaryClient(clients.data.data[0].id);
    clients.refetch();
  }, [clients, removeSecondaryClient]);

  const handleUpdate = useCallback(
    async (clientId: number) => {
      clients.refetch();
      return updateSecondaryClient(clientId);
    },
    [clients, updateSecondaryClient],
  );

  if (clients.data.data.length === 0) {
    return (
      <Box pb="4" w="100%">
        <Button
          data-testid="button:client.change"
          variant="outline"
          leftIcon={<SimpleAddIcon />}
          onClick={addSecondaryClientModal.onOpen}
        >
          {t('clients:associateSecondaryClients')}
        </Button>
        <ClientSwitchModal
          {...addSecondaryClientModal}
          client={client}
          isMultipleSelection={false}
          title={t('project:flow.client.selectSecondaryModal.title')}
          description={t('project:flow.client.selectSecondaryModal.description')}
          onUpdate={handleUpdate}
        />
      </Box>
    );
  }

  return (
    <Card alignSelf="stretch">
      <Flex p={4} borderBottomColor="gray.200" borderBottomWidth={1}>
        <Text textColor="gray.800" fontWeight="500" fontSize="18px" alignItems="center">
          {t('clients:secondaryCard.title')}
        </Text>
        <Flex marginLeft="auto">
          <Button
            data-testid="button:client.edit"
            variant="outline"
            leftIcon={<SimpleDeleteIcon stroke="gray.500" />}
            onClick={handleRemove}
          >
            {t('global:words.c.delete')}
          </Button>
        </Flex>
      </Flex>
      <Box w="100%" p="4">
        <ClientCardLine client={clients.data.data[0]} />
      </Box>
    </Card>
  );
};
