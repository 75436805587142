import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { cloneDeep, isNil, last } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { useSignals } from '../../Signal';
import type { NodeInfos, NodeRelations } from '../types/NodeInfos';
import type { TreeComputedValues, TreeContextApi } from '../contexts/TreeContext';
import type { IInitialTree, INodeRelation } from '../types/Tree';
import type {
  Identifiable,
  LeafPreviousLeafId,
  LeafWithRelations,
  NodeParentNodeId,
  NodePreviousNodeId,
  NodeWithRelations,
} from '../types/Identifiable';
import type { TreeData, TreeDataWithRelations } from '../types/TreeData';
import type { TreeStates } from '../types/TreeStates';
import type { LeafInfos } from '../types/LeafInfos';

export const BUILD_NODE_SIGNAL_KEY = (nodeId: string | number) => `NODE-${nodeId}`;
export const BUILD_NODE_COMPUTED_VALUE_SIGNAL_KEY = (nodeId: string | number) => `NODE-${nodeId}-COMPUTED-VALUE`;
export const BUILD_NODE_RELATIONS_SIGNAL_KEY = (nodeId: string | number) => `NODE-${nodeId}-RELATIONS`;

export const BUILD_LEAF_SIGNAL_KEY = (leafId: string | number) => `LEAF-${leafId}`;
export const BUILD_LEAF_COMPUTED_VALUE_SIGNAL_KEY = (leafId: string | number) => `LEAF-${leafId}-COMPUTED-VALUE`;

interface TreeMutations<Node extends Identifiable, Leaf extends Identifiable> {
  nodes: {
    updated: Record<Node['id'], Node>;

    deleted: Array<Node['id']>;

    created: Record<Node['id'], Node>;
  };

  leaves: {
    updated: Record<Leaf['id'], Leaf>;

    deleted: Array<Leaf['id']>;

    created: Record<Leaf['id'], Leaf>;
  };
}

export interface ComputedValuesFns<
  Node extends Identifiable,
  Leaf extends Identifiable,
  NodeComputedValue extends Record<keyof NodeComputedValue, unknown>,
  LeafComputedValue extends Record<keyof LeafComputedValue, unknown>,
> {
  computeNodeComputedValue: (
    node: Node,
    treeContext: Pick<
      TreeContextApi<Node, Leaf, NodeComputedValue, LeafComputedValue>,
      | 'getCurrentTree'
      | 'getChanges'
      | 'getComputedValues'
      | 'getNodeSignalInfos'
      | 'getLeafSignalInfos'
      | 'getDisplayedCurrentTree'
      | 'getInitialTree'
    >,
  ) => NodeComputedValue;

  computeLeafComputedValue: (
    leaf: Leaf,
    treeContext: Pick<
      TreeContextApi<Node, Leaf, NodeComputedValue, LeafComputedValue>,
      | 'getCurrentTree'
      | 'getChanges'
      | 'getComputedValues'
      | 'getNodeSignalInfos'
      | 'getLeafSignalInfos'
      | 'getDisplayedCurrentTree'
      | 'getInitialTree'
    >,
  ) => LeafComputedValue;
}

export const useTree = <
  Node extends Identifiable,
  Leaf extends Identifiable,
  NodeComputedValue extends Record<keyof NodeComputedValue, unknown> = never,
  LeafComputedValue extends Record<keyof LeafComputedValue, unknown> = never,
>(
  initialTree: IInitialTree<Node, Leaf>,
  computedValuesFns?: ComputedValuesFns<Node, Leaf, NodeComputedValue, LeafComputedValue>,
  treeOptions?: { areDeletionsPermanent?: boolean; defaultNodeExpandedState?: 'close' | 'open' },
): TreeContextApi<Node, Leaf, NodeComputedValue, LeafComputedValue> => {
  /**
   * Signals are heavily used throughout here to MANUALLY inform components when
   * they should re-render themselves
   *
   * It is used for updates in leaves, nodes...
   */
  const { emit, listen } = useSignals();

  /**
   * Tree state which stores the new quote state after each action.
   */
  const treeDataRef = useRef<TreeData<Node, Leaf>>({
    /**
     * A dictionary whose key is the node `id` and whose value the node object
     */
    nodes: cloneDeep(initialTree.nodes),

    /**
     * A dictionary where key is the leaf `id` and whose value the leaf object
     */
    leaves: cloneDeep(initialTree.leaves),

    /**
     * An object representing the parent/previous relations between nodes and leaves
     */
    relations: cloneDeep(initialTree.relations),

    /**
     * The id of the top-most level node of the main entity (like a quote). Useful to start traversing
     * the relations downwards
     */
    rootNodeId: initialTree.rootNodeId,

    /**
     * A dictionary which stores locally which nodes are expanded.
     * Empty by default, it becomes populated as the user collapses lots.
     *
     * Its structure follows:
     * { [lot.id]: true|false }
     */
    expandedNodeIds:
      treeOptions?.defaultNodeExpandedState === 'close'
        ? (Object.values(initialTree.nodes) as Array<Node & { parentNodeId: Node['id'] | null }>)
            .filter((node) => node.parentNodeId)
            .reduce(
              (acc, node) => {
                acc[node.id as Node['id']] = false;
                return acc;
              },
              {} as TreeData<Node, Leaf>['expandedNodeIds'],
            )
        : ({} as TreeData<Node, Leaf>['expandedNodeIds']),

    parentNodeIds: {
      leaves: Object.values<Leaf & { parentNodeId: Node['id'] }>(initialTree.leaves).reduce(
        (acc, leaf) => {
          acc[leaf.id as Leaf['id']] = leaf.parentNodeId;
          return acc;
        },
        {} as TreeData<Node, Leaf>['parentNodeIds']['leaves'],
      ),

      nodes: Object.values<Node & { parentNodeId: Node['id'] | null }>(initialTree.nodes).reduce(
        (acc, node) => {
          acc[node.id as Node['id']] = isNil(node.parentNodeId) ? undefined : node.parentNodeId;
          return acc;
        },
        {} as TreeData<Node, Leaf>['parentNodeIds']['nodes'],
      ),
    },
  });

  const treeComputedValuesRef = useRef<TreeComputedValues<Node, Leaf, NodeComputedValue, LeafComputedValue>>({
    nodes: {} as Record<Node['id'], NodeComputedValue>,
    leaves: {} as Record<Leaf['id'], LeafComputedValue>,
  });

  const treeMutationsRef = useRef<TreeMutations<Node, Leaf>>({
    leaves: {
      updated: {} as TreeMutations<Node, Leaf>['leaves']['updated'],
      deleted: [] as TreeMutations<Node, Leaf>['leaves']['deleted'],
      created: {} as TreeMutations<Node, Leaf>['leaves']['created'],
    },
    nodes: {
      updated: {} as TreeMutations<Node, Leaf>['nodes']['updated'],
      deleted: [] as TreeMutations<Node, Leaf>['nodes']['deleted'],
      created: {} as TreeMutations<Node, Leaf>['nodes']['created'],
    },
  });

  const treeStatesRef = useRef<TreeStates<Node, Leaf>>({
    nodeUpdatedKeys: {} as TreeStates<Node, Leaf>['nodeUpdatedKeys'],

    restorableNodes: {} as TreeStates<Node, Leaf>['restorableNodes'],

    leafUpdatedKeys: {} as TreeStates<Node, Leaf>['leafUpdatedKeys'],

    restorableLeaves: {} as TreeStates<Node, Leaf>['restorableLeaves'],
  });

  /*
   * Keep track of number of non created entities in nodes. This is useful on node deletion because
   * delete a created node is a permanent deletion, so we forbid the deletion when created nodes when
   * there is one or more non created entities (node of leaf inside the node).
   */
  const numberOfInitialEntitiesInNode = useRef<Record<Node['id'], number | undefined>>(
    {} as Record<Node['id'], number | undefined>,
  );

  // -- Utils
  const isNodeDeleted = useCallback(
    (nodeId: Node['id']) => treeMutationsRef.current.nodes.deleted.includes(nodeId),
    [],
  );

  const isNodeCreated = useCallback((nodeId: Node['id']) => !!treeMutationsRef.current.nodes.created[nodeId], []);

  const getUpdatedKeysOfNode = useCallback(
    (nodeId: Node['id']): Array<keyof Node> => treeStatesRef.current.nodeUpdatedKeys[nodeId] ?? [],
    [],
  );

  const canNodeBeRestored = useCallback((nodeId: Node['id']) => !!treeStatesRef.current.restorableNodes[nodeId], []);

  const canNodeBeDeleted = useCallback(
    (nodeId: Node['id']) => (isNodeCreated(nodeId) ? !numberOfInitialEntitiesInNode.current[nodeId] : true),
    [isNodeCreated],
  );

  const isLeafDeleted = useCallback(
    (leafId: Leaf['id']) => treeMutationsRef.current.leaves.deleted.includes(leafId),
    [],
  );

  const isLeafCreated = useCallback((leafId: Leaf['id']) => !!treeMutationsRef.current.leaves.created[leafId], []);

  const getUpdatedKeysOfLeaf = useCallback(
    (leafId: Leaf['id']): Array<keyof Leaf> => treeStatesRef.current.leafUpdatedKeys[leafId] ?? [],
    [],
  );

  const canLeafBeRestored = useCallback((leafId: Leaf['id']) => !!treeStatesRef.current.restorableLeaves[leafId], []);

  const getNodeIdFromIdAsString = useCallback((indexId: string) => {
    // @ts-ignore
    const node: Node | undefined = treeDataRef.current.nodes[indexId];
    return isNil(node) ? null : node.id;
  }, []);

  /**
   * Find the path of nodes to get the given node.
   * Exemple:
   * If the function returns [10,6,2,1], the given node is parent the node 10 then the parent is the node 6, then 2
   * and then 1.
   */
  const findNodePathFromNodeToRootNode = useCallback(
    (nodeId: Node['id']): Node['id'][] => {
      const parentNodeIds: Node['id'][] = [];

      let currentSearchingNodeId = nodeId;
      do {
        Object.entries<INodeRelation<Node, Leaf>>(treeDataRef.current.relations).forEach(
          // eslint-disable-next-line @typescript-eslint/no-loop-func
          ([nodeIdAsString, { nodes }]) => {
            if (nodes.find((id) => id === currentSearchingNodeId)) {
              const parentId = getNodeIdFromIdAsString(nodeIdAsString);

              if (isNil(parentId)) {
                throw new Error(`Could not find node with indexId ${parentId}`);
              }

              parentNodeIds.push(parentId);
              currentSearchingNodeId = parentId;
            }
          },
        );
      } while (parentNodeIds.length > 0 && last(parentNodeIds) !== treeDataRef.current.rootNodeId);

      return parentNodeIds;
    },
    [getNodeIdFromIdAsString],
  );

  /**
   * Find the path of nodes to get the given leaf.
   * Exemple:
   * If the function returns [10,6,2,1], the given leaf has as parent the node 10 then the node 6, then 2
   * and then 1.
   */
  const findLeafPathFromNodeToRootNode = useCallback(
    (leafId: Leaf['id']): Node['id'][] => {
      let parentNodeId: Node['id'] | undefined;

      Object.entries<INodeRelation<Node, Leaf>>(treeDataRef.current.relations).forEach(
        ([nodeIdAsString, { leaves }]) => {
          const leafIdFound = leaves.find((id) => id === leafId);

          if (!isNil(leafIdFound)) {
            const nodeId = getNodeIdFromIdAsString(nodeIdAsString);
            if (isNil(nodeId)) {
              throw new Error(`Could not find node with indexId ${nodeId}`);
            }
            parentNodeId = nodeId;
          }
        },
      );

      if (isNil(parentNodeId)) {
        throw new Error(`Could not find the parent node of leaf ${leafId}`);
      }

      const parentNodeIds = findNodePathFromNodeToRootNode(parentNodeId);

      return [parentNodeId, ...parentNodeIds];
    },
    [findNodePathFromNodeToRootNode, getNodeIdFromIdAsString],
  );

  const findSubNodesOrderByDepthDesc = useCallback((nodeId: Node['id']): Node['id'][] => {
    /*
      Compute and emit computed values for all nodes from the nodes.
      We fill an array with node ids ordered by depth. The array will be like:
      [...(all nodes of the depth 3), ...(all nodes of the depth 2), ...(all nodes of the depth 1), ...(all nodes of the depth 0)]
      By doing this, we can compute the computed values for all nodes from the deepest to the highest depth. because
      a computed node depending on a nested computed node.
   */
    const getSubNodesId = (nodeIds: Node['id'][]): Node['id'][] => {
      if (nodeIds.length === 0) {
        return [];
      }

      const subNodesId = nodeIds.map((id) => treeDataRef.current.relations[id].nodes || []).flat();
      return [...getSubNodesId(subNodesId), ...nodeIds];
    };
    return getSubNodesId([nodeId]);
  }, []);

  const findDescendingChildrenOfNode = useCallback((nodeId: Node['id']) => {
    const childrenEntities: INodeRelation<Node, Leaf> = {
      leaves: [...treeDataRef.current.relations[nodeId].leaves],
      nodes: [...treeDataRef.current.relations[nodeId].nodes],
    };

    treeDataRef.current.relations[nodeId].nodes.forEach((childNodeId) => {
      const childNodeChildrenEntities = findDescendingChildrenOfNode(childNodeId);
      childrenEntities.nodes = [...childrenEntities.nodes, ...childNodeChildrenEntities.nodes];
      childrenEntities.leaves = [...childrenEntities.leaves, ...childNodeChildrenEntities.leaves];
    });

    return childrenEntities;
  }, []);

  const isNodeDescendingChildrenOfNode = useCallback(
    (nodeToSearch: Node['id'], nodeId: Node['id']) => {
      const { nodes } = findDescendingChildrenOfNode(nodeId);
      return nodes.includes(nodeToSearch);
    },
    [findDescendingChildrenOfNode],
  );

  const getLastLeafOfNode = useCallback(
    (nodeId: Node['id']) => last(treeDataRef.current.relations[nodeId].leaves) || null,
    [],
  );

  const getLastNodeOfNode = useCallback(
    (nodeId: Node['id']) => last(treeDataRef.current.relations[nodeId].nodes) || null,
    [],
  );

  /**
   Please use `findNodePosition` or `findLeafPosition` instead of this function.
   @internal
   */
  const findParentNodeAndIndex = useCallback(
    (
      id: Node['id'] | Leaf['id'],
      entityType: 'node' | 'leaf',
      options: { removeDeletedEntities: boolean },
    ): { parentNodeId: Node['id'] | null; index: number } => {
      const propertyToSearchIn = entityType === 'node' ? 'nodes' : 'leaves';

      const parentNodeId: Node['id'] | undefined = treeDataRef.current.parentNodeIds[propertyToSearchIn][id];

      const isRootNode = entityType === 'node' && id === treeDataRef.current.rootNodeId;
      if (isNil(parentNodeId) && !isRootNode) {
        throw new Error(`Could not find ${entityType} with id ${id}`);
      }

      if (isRootNode) {
        return { parentNodeId: null, index: 0 };
      }

      const currentCurrentRelations = options.removeDeletedEntities
        ? treeDataRef.current.relations[parentNodeId as Node['id']][propertyToSearchIn]
            // @ts-ignore
            .filter((entityId) => !treeMutationsRef.current[propertyToSearchIn].deleted.includes(entityId))
        : treeDataRef.current.relations[parentNodeId as Node['id']][propertyToSearchIn];

      const index = currentCurrentRelations.indexOf(id);

      return {
        parentNodeId: isNil(parentNodeId) ? null : parentNodeId,
        index,
      };
    },
    [],
  );

  const findLeafPosition = useCallback(
    (leafId: Leaf['id'], options: { removeDeletedEntities: boolean }) => {
      const { parentNodeId, index } = findParentNodeAndIndex(leafId, 'leaf', options);

      if (isNil(parentNodeId)) {
        throw new Error(`Could not find parent node of leaf ${leafId}`);
      }

      const previousLeafId = index > 0 ? treeDataRef.current.relations[parentNodeId].leaves[index - 1] : null;

      return {
        parentNodeId,
        previousLeafId,
        index,
      };
    },
    [findParentNodeAndIndex],
  );

  const findNodePosition = useCallback(
    (nodeId: Node['id'], options: { removeDeletedEntities: boolean }) => {
      const { parentNodeId, index } = findParentNodeAndIndex(nodeId, 'node', options);

      // If null its root node id
      if (isNil(parentNodeId)) {
        return {
          parentNodeId,
          previousNodeId: null,
          index,
        };
      }

      const previousNodeId = index > 0 ? treeDataRef.current.relations[parentNodeId].nodes[index - 1] : null;

      return {
        parentNodeId,
        previousNodeId,
        index,
      };
    },
    [findParentNodeAndIndex],
  );

  const isLeafAfterLeaf = useCallback(
    (leaf: LeafWithRelations<Node, Leaf>, comparedLeaf: LeafWithRelations<Node, Leaf>) => {
      if (comparedLeaf.parentNodeId !== leaf.parentNodeId) {
        return false;
      }
      const index = treeDataRef.current.relations[leaf.parentNodeId].leaves.indexOf(leaf.id);
      const indexOfComparedLeaf = treeDataRef.current.relations[comparedLeaf.parentNodeId].leaves.indexOf(
        comparedLeaf.id,
      );
      return index + 1 === indexOfComparedLeaf;
    },
    [],
  );

  const isNodeAfterNode = useCallback(
    (nodeId: Node['id'], comparedNodeId: Node['id']) => {
      const { parentNodeId } = findNodePosition(nodeId, { removeDeletedEntities: false });
      const { parentNodeId: parentNodeIdOfComparedNodeId } = findNodePosition(comparedNodeId, {
        removeDeletedEntities: false,
      });

      if (
        isNil(parentNodeId) || // its parent nodeId
        parentNodeIdOfComparedNodeId !== parentNodeId
      ) {
        return false;
      }
      const index = treeDataRef.current.relations[parentNodeId as Node['id']].nodes.indexOf(nodeId);
      const indexOfComparedLeaf =
        treeDataRef.current.relations[parentNodeId as Node['id']].nodes.indexOf(comparedNodeId);
      return index + 1 === indexOfComparedLeaf;
    },
    [findNodePosition],
  );

  /**
   * Get the updated node status for `canBeRestored`. Recompute the property from node values and do not use
   * state of `treeStatesRef.current.restorableNodes`
   */
  const getCanNodeBeRestoredFromCurrentValues = useCallback(
    (nodeId: Node['id']) => {
      const hasSubNodeRestorable = (treeDataRef.current.relations[nodeId]?.nodes || []).reduce(
        (acc, currentNodeId) => canNodeBeRestored(currentNodeId) || acc,
        false,
      );
      const hasSubLeafRestorable = (treeDataRef.current.relations[nodeId]?.leaves || []).reduce(
        (acc, currentLeafId) => canLeafBeRestored(currentLeafId) || acc,
        false,
      );

      return (
        isNodeDeleted(nodeId) ||
        isNodeCreated(nodeId) ||
        getUpdatedKeysOfNode(nodeId).length > 0 ||
        hasSubNodeRestorable ||
        hasSubLeafRestorable
      );
    },
    [canLeafBeRestored, canNodeBeRestored, getUpdatedKeysOfNode, isNodeCreated, isNodeDeleted],
  );

  /**
   * Get the updated leaf status for `canBeRestored`. Recompute the property from leaf values and do not use
   * state of `treeStatesRef.current.restorableLeaves`
   */
  const getCanLeafBeRestoredFromCurrentValues = useCallback(
    (leafId: Leaf['id']) => isLeafDeleted(leafId) || isLeafCreated(leafId) || getUpdatedKeysOfLeaf(leafId).length > 0,
    [getUpdatedKeysOfLeaf, isLeafCreated, isLeafDeleted],
  );

  // -- GETTERS

  const getInitialTree = useCallback(() => initialTree, [initialTree]);

  const getCurrentTreeInternal = useCallback(
    (options: { removeDeletedEntities: boolean }): TreeDataWithRelations<Node, Leaf> => {
      const leavesWithRelations = {} as Record<Leaf['id'], LeafWithRelations<Node, Leaf>>;
      Object.values<Leaf>(treeDataRef.current.leaves).forEach((leaf) => {
        if (options.removeDeletedEntities && isLeafDeleted(leaf.id)) {
          return;
        }

        const leafId = leaf.id as Leaf['id'];
        const { parentNodeId, previousLeafId } = findLeafPosition(leaf.id, options);
        leavesWithRelations[leafId] = {
          ...leaf,
          parentNodeId,
          previousLeafId,
        };
      });

      const nodesWithRelations = {} as Record<Node['id'], NodeWithRelations<Node>>;
      Object.values<Node>(treeDataRef.current.nodes).forEach((node) => {
        if (options.removeDeletedEntities && isNodeDeleted(node.id)) {
          return;
        }

        const nodeId = node.id as Node['id'];
        const { parentNodeId, previousNodeId } = findNodePosition(node.id, options);
        nodesWithRelations[nodeId] = {
          ...node,
          parentNodeId,
          previousNodeId,
        };
      });

      let relations = {} as Record<Node['id'], INodeRelation<Node, Leaf>>;
      if (options.removeDeletedEntities) {
        Object.entries<INodeRelation<Node, Leaf>>(treeDataRef.current.relations).forEach(
          ([nodeIdAsString, nodeRelations]) => {
            const nodeId = getNodeIdFromIdAsString(nodeIdAsString) as Node['id'];
            if (isNodeDeleted(nodeId)) {
              return;
            }

            relations[nodeId] = {
              leaves: nodeRelations.leaves.filter((id) => !isLeafDeleted(id)),
              nodes: nodeRelations.nodes.filter((id) => !isNodeDeleted(id)),
            };
          },
        );
      } else {
        relations = treeDataRef.current.relations;
      }

      return {
        ...treeDataRef.current,
        leaves: leavesWithRelations,
        nodes: nodesWithRelations,
        relations,
      };
    },
    [findLeafPosition, findNodePosition, getNodeIdFromIdAsString, isLeafDeleted, isNodeDeleted],
  );

  const getCurrentTree = useCallback(
    (): TreeDataWithRelations<Node, Leaf> => getCurrentTreeInternal({ removeDeletedEntities: true }),
    [getCurrentTreeInternal],
  );

  const getDisplayedCurrentTree = useCallback(
    (): TreeDataWithRelations<Node, Leaf> => getCurrentTreeInternal({ removeDeletedEntities: false }),
    [getCurrentTreeInternal],
  );

  const getComputedValues = useCallback(() => treeComputedValuesRef.current, []);

  const getChanges = useCallback(() => {
    const currentRelations = getDisplayedCurrentTree().relations;

    // -- Nodes
    const createdNodes: Array<NodeWithRelations<Node>> = [];
    const deletedNodes: Array<Node['id']> = [];
    const updatedNodes: Array<NodeWithRelations<Node>> = [];

    // -- Leaves
    const createdLeaves: Array<LeafWithRelations<Node, Leaf>> = [];
    const deletedLeaves: Array<Leaf['id']> = [];
    const updatedLeaves: Array<LeafWithRelations<Node, Leaf>> = [];

    Object.entries<INodeRelation<Node, Leaf>>(currentRelations).forEach(([nodeIdAsString, nodeRelations]) => {
      const nodeId = getNodeIdFromIdAsString(nodeIdAsString);
      if (isNil(nodeId)) {
        throw new Error(`Could not find node id from idAsString ${nodeIdAsString}`);
      }

      /*
      The boolean depends on the previous leaf id. If the previous leaf is deleted, created
      or moved. The next leaf must have its relations updated. This is to ensure there is no relations
      update missing.
       */
      let hasLeafRelationsToBeUpdated = false;
      let previousLeafId: LeafPreviousLeafId = null;
      nodeRelations.leaves.forEach((leafId) => {
        const isDeleted = isLeafDeleted(leafId);
        const isCreated = isLeafCreated(leafId);

        // If the deleted leaf is also created, we don't need to do anything
        if (isDeleted && isCreated) {
          return;
        }

        // If the leaf is deleted, we need to remove it
        if (isDeleted) {
          hasLeafRelationsToBeUpdated = true;
          deletedLeaves.push(leafId);
          return;
        }

        // If the leaf is created, we need to add it
        if (isCreated) {
          hasLeafRelationsToBeUpdated = true;
          createdLeaves.push({
            ...treeMutationsRef.current.leaves.created[leafId],
            parentNodeId: nodeId,
            previousLeafId,
          });
          previousLeafId = leafId;
          return;
        }

        const isUpdated = !!treeMutationsRef.current.leaves.updated[leafId];
        // If the leaf is updated, we need to update it
        if (isUpdated) {
          updatedLeaves.push({
            ...treeMutationsRef.current.leaves.updated[leafId],
            parentNodeId: nodeId,
            previousLeafId,
          });
          previousLeafId = leafId;
          return;
        }

        // If the leaf is moved, we need to update it
        const indexOfLeafInInitialState = (initialTree.relations[nodeId as Node['id']]?.leaves || []).indexOf(leafId);
        const previousLeafIdInInitialState =
          indexOfLeafInInitialState > 0
            ? initialTree.relations[nodeId as Node['id']].leaves[indexOfLeafInInitialState - 1]
            : null;
        const isMoved = indexOfLeafInInitialState === -1 || previousLeafIdInInitialState !== previousLeafId;
        if (isMoved || hasLeafRelationsToBeUpdated) {
          hasLeafRelationsToBeUpdated = isMoved;
          updatedLeaves.push({
            ...treeDataRef.current.leaves[leafId],
            parentNodeId: nodeId,
            previousLeafId,
          });
          previousLeafId = leafId;
          return;
        }

        // Otherwise, we don't need to do anything
        hasLeafRelationsToBeUpdated = false;
        previousLeafId = leafId;
      });

      /*
      The boolean depends on the previous leaf id. If the previous leaf is deleted, created
      or moved. The next leaf must have its relations updated. This is to ensure there is no relations
      update missing.
       */
      let hasNodeRelationsToBeUpdated = false;
      let previousNodeId: NodePreviousNodeId = null;
      nodeRelations.nodes.forEach((currentNodeId) => {
        const isDeleted = isNodeDeleted(currentNodeId);
        const isCreated = isNodeCreated(currentNodeId);

        // If the deleted node is also created, we don't need to do anything
        if (isDeleted && isCreated) {
          return;
        }

        // If the node is deleted, we need to remove it
        if (isDeleted) {
          hasNodeRelationsToBeUpdated = true;
          deletedNodes.push(currentNodeId);
          return;
        }

        // If the node is created, we need to add it
        if (isCreated) {
          hasNodeRelationsToBeUpdated = true;
          createdNodes.push({
            ...treeMutationsRef.current.nodes.created[currentNodeId],
            parentNodeId: nodeId,
            previousNodeId,
          });
          previousNodeId = currentNodeId;
          return;
        }

        const isUpdated = !!treeMutationsRef.current.nodes.updated[currentNodeId];
        // If the node is updated, we need to update it
        if (isUpdated) {
          updatedNodes.push({
            ...treeMutationsRef.current.nodes.updated[currentNodeId],
            parentNodeId: nodeId,
            previousNodeId,
          });
          previousNodeId = currentNodeId;
          return;
        }

        // If the node is moved, we need to update it
        const indexOfNodeInInitialState = (initialTree.relations[nodeId as Node['id']]?.nodes || []).indexOf(
          currentNodeId,
        );
        const previousNodeIdInInitialState =
          indexOfNodeInInitialState > 0
            ? initialTree.relations[nodeId as Node['id']].nodes[indexOfNodeInInitialState - 1]
            : null;
        const isMoved = indexOfNodeInInitialState === -1 || previousNodeIdInInitialState !== previousNodeId;
        if (isMoved || hasNodeRelationsToBeUpdated) {
          hasNodeRelationsToBeUpdated = isMoved;
          updatedNodes.push({
            ...treeDataRef.current.nodes[currentNodeId],
            parentNodeId: nodeId,
            previousNodeId,
          });
          previousNodeId = currentNodeId;
          return;
        }

        // Otherwise, we don't need to do anything
        hasNodeRelationsToBeUpdated = false;
        previousNodeId = currentNodeId;
      });
    });

    return {
      nodes: {
        created: createdNodes,
        updated: updatedNodes,
        deleted: deletedNodes,
      },
      leaves: {
        created: createdLeaves,
        updated: updatedLeaves,
        deleted: deletedLeaves,
      },
    };
  }, [
    getDisplayedCurrentTree,
    getNodeIdFromIdAsString,
    initialTree.relations,
    isLeafCreated,
    isLeafDeleted,
    isNodeCreated,
    isNodeDeleted,
  ]);

  const getNodeRelationsSignal = useCallback((nodeId: Node['id']): NodeRelations<Node, Leaf> => {
    const nodeRelations = treeDataRef.current.relations[nodeId];

    return {
      nodes: nodeRelations?.nodes ?? [],
      leaves: nodeRelations?.leaves ?? [],
    };
  }, []);

  const getNodeSignalInfos = useCallback(
    (nodeId: Node['id']): NodeInfos<Node> => {
      const updatedKeys = getUpdatedKeysOfNode(nodeId);
      const isDeleted = isNodeDeleted(nodeId);
      const isCreated = isNodeCreated(nodeId);
      const canBeRestored = canNodeBeRestored(nodeId);

      const { parentNodeId, previousNodeId } = findNodePosition(nodeId, { removeDeletedEntities: false });

      return {
        node: {
          ...treeDataRef.current.nodes[nodeId],
          parentNodeId,
          previousNodeId,
        },
        state: {
          isExpanded:
            treeDataRef.current.expandedNodeIds[nodeId] === undefined
              ? true
              : treeDataRef.current.expandedNodeIds[nodeId],
          isCreated,
          updatedKeys,
          isDeleted,
          canBeRestored,
          canBeDeleted: canNodeBeDeleted(nodeId),
        },
      };
    },
    [canNodeBeDeleted, canNodeBeRestored, findNodePosition, getUpdatedKeysOfNode, isNodeCreated, isNodeDeleted],
  );

  const getLeafSignalInfos = useCallback(
    (leafId: Leaf['id']): LeafInfos<Node, Leaf> => {
      const updatedKeys = getUpdatedKeysOfLeaf(leafId);
      const isDeleted = isLeafDeleted(leafId);
      const isCreated = isLeafCreated(leafId);
      const canBeRestored = canLeafBeRestored(leafId);

      const { parentNodeId, previousLeafId } = findLeafPosition(leafId, { removeDeletedEntities: false });

      return {
        leaf: {
          ...treeDataRef.current.leaves[leafId],
          parentNodeId,
          previousLeafId,
        },
        state: {
          updatedKeys,
          isDeleted,
          isCreated,
          canBeRestored,
          canBeDeleted: true,
        },
      };
    },
    [canLeafBeRestored, findLeafPosition, getUpdatedKeysOfLeaf, isLeafCreated, isLeafDeleted],
  );

  // -- Subscribe system --

  const emitLeafUpdate = useCallback(
    (leafId: Leaf['id']) => {
      const key = BUILD_LEAF_SIGNAL_KEY(leafId);
      const leafInfos = getLeafSignalInfos(leafId);
      emit(key, leafInfos);

      return leafInfos;
    },
    [emit, getLeafSignalInfos],
  );

  const listenToLeaf = useCallback(
    (leafId: Leaf['id'], listener: (leafInfos: LeafInfos<Node, Leaf>) => void) => {
      const key = BUILD_LEAF_SIGNAL_KEY(leafId);
      const leafInfos = getLeafSignalInfos(leafId);
      return listen(key, listener, leafInfos);
    },
    [getLeafSignalInfos, listen],
  );

  const emitNodeComputedValueUpdate = useCallback(
    (nodeId: Node['id']) => {
      if (!computedValuesFns) {
        return;
      }

      treeComputedValuesRef.current.nodes[nodeId] = computedValuesFns.computeNodeComputedValue(
        treeDataRef.current.nodes[nodeId],
        {
          getCurrentTree,
          getChanges,
          getComputedValues,
          getLeafSignalInfos,
          getNodeSignalInfos,
          getDisplayedCurrentTree,
          getInitialTree,
        },
      );

      const key = BUILD_NODE_COMPUTED_VALUE_SIGNAL_KEY(nodeId);
      const nodeComputedValue = treeComputedValuesRef.current.nodes[nodeId];
      emit(key, nodeComputedValue);
    },
    [
      computedValuesFns,
      emit,
      getChanges,
      getComputedValues,
      getCurrentTree,
      getDisplayedCurrentTree,
      getLeafSignalInfos,
      getNodeSignalInfos,
      getInitialTree,
    ],
  );

  const emitNodeComputedValuesRecursivelyUpdate = useCallback(
    (nodeId: Node['id']) => {
      if (!computedValuesFns) {
        return;
      }
      const nodeIdsFromNodeToRootNode = [nodeId, ...findNodePathFromNodeToRootNode(nodeId)];
      /*
    Computed values are computed from the deepest node to the root node because a computed value can
    depend on the value of a nested computed value.
     */
      nodeIdsFromNodeToRootNode.forEach((currentNodeId) => {
        emitNodeComputedValueUpdate(currentNodeId);
      });
    },
    [computedValuesFns, findNodePathFromNodeToRootNode, emitNodeComputedValueUpdate],
  );

  const listenToLeafComputedValue = useCallback(
    (leafId: Leaf['id'], listener: Dispatch<SetStateAction<LeafComputedValue>>) => {
      const key = BUILD_LEAF_COMPUTED_VALUE_SIGNAL_KEY(leafId);
      const leafComputedValue = treeComputedValuesRef.current.leaves[leafId];
      return listen(key, listener, leafComputedValue);
    },
    [listen],
  );

  const emitLeafComputedValueUpdate = useCallback(
    (leafId: Leaf['id'], { triggerParentNodeUpdate }: { triggerParentNodeUpdate: boolean }) => {
      if (!computedValuesFns) {
        return;
      }
      treeComputedValuesRef.current.leaves[leafId] = computedValuesFns.computeLeafComputedValue(
        treeDataRef.current.leaves[leafId],
        {
          getCurrentTree,
          getChanges,
          getComputedValues,
          getLeafSignalInfos,
          getNodeSignalInfos,
          getDisplayedCurrentTree,
          getInitialTree,
        },
      );
      const key = BUILD_LEAF_COMPUTED_VALUE_SIGNAL_KEY(leafId);
      const leafComputedValue = treeComputedValuesRef.current.leaves[leafId];
      emit(key, leafComputedValue);

      if (triggerParentNodeUpdate) {
        const { parentNodeId } = findLeafPosition(leafId, { removeDeletedEntities: false });
        emitNodeComputedValuesRecursivelyUpdate(parentNodeId);
      }
    },
    [
      computedValuesFns,
      getCurrentTree,
      getChanges,
      getComputedValues,
      getLeafSignalInfos,
      getNodeSignalInfos,
      getDisplayedCurrentTree,
      emit,
      findLeafPosition,
      emitNodeComputedValuesRecursivelyUpdate,
      getInitialTree,
    ],
  );

  const listenToNodeComputedValue = useCallback(
    (nodeId: Node['id'], listener: Dispatch<SetStateAction<NodeComputedValue>>) => {
      const key = BUILD_NODE_COMPUTED_VALUE_SIGNAL_KEY(nodeId);
      const nodeComputedValue = treeComputedValuesRef.current.nodes[nodeId];
      return listen(key, listener, nodeComputedValue);
    },
    [listen],
  );

  const emitNodeUpdate = useCallback(
    (nodeId: Node['id']) => {
      const key = BUILD_NODE_SIGNAL_KEY(nodeId);
      const nodeInfos = getNodeSignalInfos(nodeId);
      emit(key, nodeInfos);

      return nodeInfos;
    },
    [emit, getNodeSignalInfos],
  );

  // Emit node update on property `canBeRestored` recursively
  const emitNodeCanBeRestoredUpdateRecursively = useCallback(
    (nodeId: Node['id']) => {
      const parentNodeIds = findNodePathFromNodeToRootNode(nodeId);
      const nodeIds = [nodeId, ...parentNodeIds];

      const updateCanBeRestoredAndEmit = (index: number) => {
        const currentNodeId = nodeIds[index];
        const initialCanBeRestored = canNodeBeRestored(currentNodeId);
        const updatedCanBeRestored = getCanNodeBeRestoredFromCurrentValues(currentNodeId);
        treeStatesRef.current.restorableNodes[currentNodeId] = updatedCanBeRestored;
        emitNodeUpdate(currentNodeId);

        if (index < parentNodeIds.length - 1 && initialCanBeRestored !== updatedCanBeRestored) {
          updateCanBeRestoredAndEmit(index + 1);
        }
      };

      updateCanBeRestoredAndEmit(0);
    },
    [canNodeBeRestored, emitNodeUpdate, findNodePathFromNodeToRootNode, getCanNodeBeRestoredFromCurrentValues],
  );

  const listenToNode = useCallback(
    (nodeId: Node['id'], listener: (leafInfos: NodeInfos<Node>) => void) => {
      const key = BUILD_NODE_SIGNAL_KEY(nodeId);
      const nodeInfos = getNodeSignalInfos(nodeId);
      return listen(key, listener, nodeInfos);
    },
    [getNodeSignalInfos, listen],
  );

  const emitNodeRelations = useCallback(
    (nodeId: Node['id']) => {
      const key = BUILD_NODE_RELATIONS_SIGNAL_KEY(nodeId);
      const nodeInfos = getNodeRelationsSignal(nodeId);
      emit(key, nodeInfos);
    },
    [emit, getNodeRelationsSignal],
  );

  const listenToNodeRelations = useCallback(
    (nodeId: Node['id'], listener: Dispatch<SetStateAction<NodeRelations<Node, Leaf>>>) => {
      const key = BUILD_NODE_RELATIONS_SIGNAL_KEY(nodeId);
      const nodeInfos = getNodeRelationsSignal(nodeId);
      return listen(key, listener, nodeInfos);
    },
    [getNodeRelationsSignal, listen],
  );

  // -- Actions
  const deleteCreatedLeaf = useCallback(
    (leafId: Leaf['id']): Node['id'] => {
      if (!isLeafCreated(leafId) && !treeOptions?.areDeletionsPermanent) {
        throw new Error(`${leafId} is not a created leaf`);
      }

      const { parentNodeId } = findLeafPosition(leafId, { removeDeletedEntities: false });

      const createdLeafIndex = treeDataRef.current.relations[parentNodeId].leaves.indexOf(leafId);
      delete treeMutationsRef.current.leaves.created[leafId];
      delete treeDataRef.current.leaves[leafId];
      treeDataRef.current.relations[parentNodeId].leaves.splice(createdLeafIndex, 1);

      return parentNodeId!;
    },
    [treeOptions?.areDeletionsPermanent, findLeafPosition, isLeafCreated],
  );

  const deleteCreatedNode = useCallback(
    (nodeId: Node['id']) => {
      if (!isNodeCreated(nodeId) && !treeOptions?.areDeletionsPermanent) {
        throw new Error(`${nodeId} is not a created node`);
      }
      delete treeMutationsRef.current.nodes.created[nodeId];
      delete treeDataRef.current.nodes[nodeId];

      const { parentNodeId } = findNodePosition(nodeId, { removeDeletedEntities: false });
      if (isNil(parentNodeId)) {
        throw new Error('Cannot delete root node');
      }

      const createdNodeIndex = treeDataRef.current.relations[parentNodeId].nodes.indexOf(nodeId);
      treeDataRef.current.relations[parentNodeId].nodes.splice(createdNodeIndex, 1);

      delete treeDataRef.current.relations[nodeId];
    },
    [treeOptions?.areDeletionsPermanent, findNodePosition, isNodeCreated],
  );

  const updateLeafDataInternal = useCallback(
    (leafId: Leaf['id'], updatesToSave: Partial<Leaf>) => {
      const currentData = treeDataRef.current.leaves[leafId];
      const updatedLeaf = { ...currentData, ...updatesToSave };
      treeDataRef.current.leaves[leafId] = updatedLeaf;

      const currentUpdatedDataKeys = getUpdatedKeysOfLeaf(leafId);

      // Update data key updated. If one data key is updated by the initial data key, we need to pop the data name
      // from the list of data updated.
      const updatedDataKeys = Object.keys(updatesToSave) as (keyof Leaf)[];
      const dataKeysToRemove: (keyof Leaf)[] = [];
      const hasBeenCreated = isLeafCreated(leafId);

      if (!hasBeenCreated) {
        updatedDataKeys.forEach((dataKey) => {
          const initialValue = initialTree.leaves[leafId][dataKey];
          const currentValue = treeDataRef.current.leaves[leafId][dataKey];
          if (initialValue === currentValue) {
            dataKeysToRemove.push(dataKey);
          }
        });
        treeStatesRef.current.leafUpdatedKeys[leafId] = [
          ...new Set(
            [...updatedDataKeys, ...currentUpdatedDataKeys].filter((dataKey) => !dataKeysToRemove.includes(dataKey)),
          ),
        ];

        // When no leaf data is updated, we remove the leaf from the list of updated leaves
        if (getUpdatedKeysOfLeaf(leafId).length > 0) {
          treeMutationsRef.current.leaves.updated[leafId] = updatedLeaf;
        } else {
          delete treeMutationsRef.current.leaves.updated[leafId];
        }
      } else {
        treeMutationsRef.current.leaves.created[leafId] = updatedLeaf;
      }

      treeStatesRef.current.restorableLeaves[leafId] = getCanLeafBeRestoredFromCurrentValues(leafId);
    },
    [getCanLeafBeRestoredFromCurrentValues, getUpdatedKeysOfLeaf, initialTree.leaves, isLeafCreated],
  );

  const updateLeafData = useCallback(
    (leafId: Leaf['id'], updatesToSave: Partial<Leaf>) => {
      updateLeafDataInternal(leafId, updatesToSave);

      const leafInfos = emitLeafUpdate(leafId);
      // Emit node upwards updates to update `canBeRestored`
      emitNodeCanBeRestoredUpdateRecursively(leafInfos.leaf!.parentNodeId);
      emitLeafComputedValueUpdate(leafId, { triggerParentNodeUpdate: true });
    },
    [emitLeafComputedValueUpdate, emitLeafUpdate, emitNodeCanBeRestoredUpdateRecursively, updateLeafDataInternal],
  );

  const deleteLeafInternal = useCallback(
    (leafId: Leaf['id'], { triggerParentNodeUpdate }: { triggerParentNodeUpdate: boolean }) => {
      let parentNodeId: Node['id'];

      if (isLeafCreated(leafId) || treeOptions?.areDeletionsPermanent) {
        parentNodeId = deleteCreatedLeaf(leafId);
        emitNodeRelations(parentNodeId);

        if (triggerParentNodeUpdate) {
          emitNodeComputedValuesRecursivelyUpdate(parentNodeId);
        }
      } else {
        treeMutationsRef.current.leaves.deleted = [
          ...treeMutationsRef.current.leaves.deleted.filter((id) => id !== leafId),
          leafId,
        ];
        treeStatesRef.current.restorableLeaves[leafId] = getCanLeafBeRestoredFromCurrentValues(leafId);
        const leafInfos = emitLeafUpdate(leafId);
        parentNodeId = leafInfos.leaf!.parentNodeId;
        emitLeafComputedValueUpdate(leafId, { triggerParentNodeUpdate });
      }

      if (triggerParentNodeUpdate) {
        emitNodeCanBeRestoredUpdateRecursively(parentNodeId);
      }
    },
    [
      treeOptions?.areDeletionsPermanent,
      deleteCreatedLeaf,
      emitLeafComputedValueUpdate,
      emitLeafUpdate,
      emitNodeCanBeRestoredUpdateRecursively,
      emitNodeComputedValuesRecursivelyUpdate,
      emitNodeRelations,
      getCanLeafBeRestoredFromCurrentValues,
      isLeafCreated,
    ],
  );

  const deleteLeaf = useCallback(
    (leafId: Leaf['id']) => {
      deleteLeafInternal(leafId, { triggerParentNodeUpdate: true });
    },
    [deleteLeafInternal],
  );

  const moveNode = useCallback(
    (
      nodeId: Node['id'],
      parentNodeId: Node['id'],
      previousNodeId: NodePreviousNodeId,
      placement: 'before' | 'after',
    ) => {
      if (isNodeDeleted(nodeId)) {
        throw new Error('Node cannot be moved if it has been deleted');
      }
      if (nodeId === previousNodeId) {
        throw new Error('Cannot move node after itself');
      }
      if (nodeId === treeDataRef.current.rootNodeId) {
        throw new Error('Cannot move root node');
      }
      if (nodeId === parentNodeId) {
        throw new Error('Parent node cannot be the node itself');
      }
      if (!treeDataRef.current.nodes[parentNodeId]) {
        throw new Error('Parent node does not exist');
      }
      if (isNodeDeleted(parentNodeId)) {
        throw new Error('Parent node target is deleted');
      }
      if (isNodeDescendingChildrenOfNode(parentNodeId, nodeId)) {
        throw new Error('Cannot move a node inside one of it descending children');
      }
      if (!treeDataRef.current.nodes[nodeId]) {
        throw new Error('Node does not exist');
      }
      if (!isNil(previousNodeId) && !treeDataRef.current.nodes[previousNodeId as Node['id']]) {
        throw new Error('Previous node does not exist');
      }

      const { parentNodeId: currentParentNodeIdNullable, index: currentIndex } = findNodePosition(nodeId, {
        removeDeletedEntities: false,
      });

      // `currentParentNodeId` cannot be null because root node cannot be moved. So there is always a parent node.
      const currentParentNodeId = currentParentNodeIdNullable!;

      let futureIndex = 0;
      if (previousNodeId !== null) {
        const { parentNodeId: parentNodeIdOfPreviousNodeId, index: previousLeafIndex } = findNodePosition(
          previousNodeId,
          { removeDeletedEntities: false },
        );
        if (parentNodeId !== parentNodeIdOfPreviousNodeId) {
          throw new Error('Given parent node id does not match the parent node id of the previous node');
        }
        const offset = placement === 'before' ? 0 : 1;
        futureIndex = previousLeafIndex + offset;
      }

      treeDataRef.current.relations[currentParentNodeId].nodes.splice(currentIndex, 1);
      // if just before, an element before the future index has been removed, the future index needs to be decremented
      // to keep the same index
      if (currentParentNodeId === parentNodeId && currentIndex < futureIndex) {
        futureIndex -= 1;
      }
      treeDataRef.current.relations[parentNodeId].nodes.splice(futureIndex, 0, nodeId);
      treeDataRef.current.parentNodeIds.nodes[nodeId] = parentNodeId;

      // Keep updated number of initial entities in node
      const initialParentNodeParents = findNodePathFromNodeToRootNode(currentParentNodeId);
      const newParentNodeParents = findNodePathFromNodeToRootNode(parentNodeId);

      [currentParentNodeId, ...initialParentNodeParents].forEach((id) => {
        numberOfInitialEntitiesInNode.current[id] =
          (numberOfInitialEntitiesInNode.current[id] ?? 0) + (numberOfInitialEntitiesInNode.current[nodeId] ?? 0);
        emitNodeUpdate(id);
      });
      [parentNodeId, ...newParentNodeParents].forEach((id) => {
        numberOfInitialEntitiesInNode.current[id] =
          (numberOfInitialEntitiesInNode.current[id] ?? 0) + (numberOfInitialEntitiesInNode.current[nodeId] ?? 0);
        emitNodeUpdate(id);
      });

      emitNodeUpdate(nodeId);
      emitNodeComputedValueUpdate(nodeId);

      emitNodeRelations(currentParentNodeId);
      emitNodeComputedValuesRecursivelyUpdate(currentParentNodeId);

      emitNodeRelations(parentNodeId);
      emitNodeComputedValuesRecursivelyUpdate(parentNodeId);
    },
    [
      emitNodeComputedValueUpdate,
      emitNodeComputedValuesRecursivelyUpdate,
      emitNodeRelations,
      emitNodeUpdate,
      findNodePathFromNodeToRootNode,
      findNodePosition,
      isNodeDeleted,
      isNodeDescendingChildrenOfNode,
    ],
  );

  const moveLeaf = useCallback(
    (
      leafId: Leaf['id'],
      parentNodeId: Node['id'],
      previousLeafId: LeafPreviousLeafId,
      placement: 'before' | 'after',
    ) => {
      if (isLeafDeleted(leafId)) {
        throw new Error('Leaf cannot be moved if it has been deleted');
      }
      if (leafId === previousLeafId) {
        throw new Error('Cannot move leaf after itself');
      }
      if (!treeDataRef.current.nodes[parentNodeId]) {
        throw new Error('Parent node does not exist');
      }
      if (isNodeDeleted(parentNodeId)) {
        throw new Error('Parent node target is deleted');
      }
      if (!treeDataRef.current.leaves[leafId]) {
        throw new Error('Leaf does not exist');
      }
      if (!isNil(previousLeafId) && !treeDataRef.current.leaves[previousLeafId as Leaf['id']]) {
        throw new Error('Previous leaf does not exist');
      }

      const { parentNodeId: currentParentNodeId, index: currentIndex } = findLeafPosition(leafId, {
        removeDeletedEntities: false,
      });
      let futureIndex = 0;

      if (previousLeafId !== null) {
        const { parentNodeId: parentNodeIdOfPreviousLeafId, index: previousLeafIndex } = findLeafPosition(
          previousLeafId,
          { removeDeletedEntities: false },
        );
        if (parentNodeId !== parentNodeIdOfPreviousLeafId) {
          throw new Error('Given parent node id does not match the parent node id of the previous leaf');
        }
        const offset = placement === 'before' ? 0 : 1;
        futureIndex = previousLeafIndex + offset;
      }

      treeDataRef.current.relations[currentParentNodeId].leaves.splice(currentIndex, 1);
      // if just before, an element before the future index has been removed, the future index needs to be decremented
      // to keep the same index
      if (currentParentNodeId === parentNodeId && currentIndex < futureIndex) {
        futureIndex -= 1;
      }
      treeDataRef.current.relations[parentNodeId].leaves.splice(futureIndex, 0, leafId);
      treeDataRef.current.parentNodeIds.leaves[leafId] = parentNodeId;

      emitLeafUpdate(leafId);
      emitLeafComputedValueUpdate(leafId, { triggerParentNodeUpdate: false });

      // Keep updated number of initial entities in node
      if (!isLeafCreated(leafId)) {
        const initialParentNodeParents = findNodePathFromNodeToRootNode(currentParentNodeId);
        const newParentNodeParents = findNodePathFromNodeToRootNode(parentNodeId);

        [currentParentNodeId, ...initialParentNodeParents].forEach((id) => {
          numberOfInitialEntitiesInNode.current[id] = (numberOfInitialEntitiesInNode.current[id] ?? 1) - 1;
          emitNodeUpdate(id);
        });
        [parentNodeId, ...newParentNodeParents].forEach((id) => {
          numberOfInitialEntitiesInNode.current[id] = (numberOfInitialEntitiesInNode.current[id] ?? 0) + 1;
          emitNodeUpdate(id);
        });
      }

      emitNodeRelations(currentParentNodeId);
      emitNodeComputedValuesRecursivelyUpdate(currentParentNodeId);

      emitNodeRelations(parentNodeId);
      emitNodeComputedValuesRecursivelyUpdate(parentNodeId);
    },
    [
      emitLeafComputedValueUpdate,
      emitLeafUpdate,
      emitNodeComputedValuesRecursivelyUpdate,
      emitNodeRelations,
      emitNodeUpdate,
      findLeafPosition,
      findNodePathFromNodeToRootNode,
      isLeafCreated,
      isLeafDeleted,
      isNodeDeleted,
    ],
  );

  const updateNodeDataInternal = useCallback(
    (nodeId: Node['id'], updatesToSave: Partial<Node>) => {
      if (Object.values(updatesToSave).length === 0) {
        return;
      }

      const currentData = treeDataRef.current.nodes[nodeId];
      const updatedNode = { ...currentData, ...updatesToSave };
      treeDataRef.current.nodes[nodeId] = updatedNode;

      const currentUpdatedDataKeys = getUpdatedKeysOfNode(nodeId);

      // Update data key updated. If one data key is updated by the initial data key, we need to pop the data name
      // from the list of data updated.
      const updatedDataKeys = Object.keys(updatesToSave) as (keyof Node)[];
      const dataKeysToRemove: (keyof Node)[] = [];
      const hasBeenCreated = isNodeCreated(nodeId);

      if (!hasBeenCreated) {
        updatedDataKeys.forEach((dataKey) => {
          const initialValue = initialTree.nodes[nodeId][dataKey];
          const currentValue = treeDataRef.current.nodes[nodeId][dataKey];
          if (initialValue === currentValue) {
            dataKeysToRemove.push(dataKey);
          }
        });
        treeStatesRef.current.nodeUpdatedKeys[nodeId] = [
          ...new Set(
            [...updatedDataKeys, ...currentUpdatedDataKeys].filter((dataKey) => !dataKeysToRemove.includes(dataKey)),
          ),
        ];

        // When no node data is updated, we remove the node from the list of updated leaves
        if (getUpdatedKeysOfNode(nodeId).length > 0) {
          treeMutationsRef.current.nodes.updated[nodeId] = updatedNode;
        } else {
          delete treeMutationsRef.current.nodes.updated[nodeId];
        }
      } else {
        treeMutationsRef.current.nodes.created[nodeId] = updatedNode;
      }

      treeStatesRef.current.restorableNodes[nodeId] = getCanNodeBeRestoredFromCurrentValues(nodeId);
    },
    [getCanNodeBeRestoredFromCurrentValues, getUpdatedKeysOfNode, initialTree.nodes, isNodeCreated],
  );

  const updateNodeData = useCallback(
    (nodeId: Node['id'], updatesToSave: Partial<Node>) => {
      updateNodeDataInternal(nodeId, updatesToSave);

      const nodeInfos = emitNodeUpdate(nodeId);
      // Emit node upwards updates to update `canBeRestored`
      if (nodeInfos?.node?.parentNodeId) {
        emitNodeCanBeRestoredUpdateRecursively(nodeInfos.node.parentNodeId);
      }
      emitNodeComputedValuesRecursivelyUpdate(nodeId);
    },
    [
      updateNodeDataInternal,
      emitNodeUpdate,
      emitNodeCanBeRestoredUpdateRecursively,
      emitNodeComputedValuesRecursivelyUpdate,
    ],
  );

  const updateAllChildrenLeavesOfNode = useCallback(
    (nodeId: Node['id'], updatesToSave: Partial<Leaf>) => {
      const childrenEntities = findDescendingChildrenOfNode(nodeId);

      // Update entities data in refs
      childrenEntities.leaves.forEach((id) => {
        updateLeafDataInternal(id, updatesToSave);
        // We directly emit the leaf update because there is no constraint on the leaf
        emitLeafUpdate(id);
        emitLeafComputedValueUpdate(id, { triggerParentNodeUpdate: false });
      });

      // By depth, update nodes watcher and node computed values
      const childrenEntitiesByDepth = findSubNodesOrderByDepthDesc(nodeId);
      childrenEntitiesByDepth.forEach((id) => {
        treeStatesRef.current.restorableNodes[id] = getCanNodeBeRestoredFromCurrentValues(id);
        emitNodeUpdate(id);
        emitNodeComputedValueUpdate(id);
      });

      // Emit node upwards updates
      emitNodeComputedValuesRecursivelyUpdate(nodeId);
      emitNodeCanBeRestoredUpdateRecursively(nodeId);
    },
    [
      emitLeafComputedValueUpdate,
      emitLeafUpdate,
      emitNodeCanBeRestoredUpdateRecursively,
      emitNodeComputedValueUpdate,
      emitNodeComputedValuesRecursivelyUpdate,
      emitNodeUpdate,
      findDescendingChildrenOfNode,
      findSubNodesOrderByDepthDesc,
      getCanNodeBeRestoredFromCurrentValues,
      updateLeafDataInternal,
    ],
  );

  const deleteNodeInternal = useCallback(
    (nodeId: Node['id']) => {
      const {
        relations: {
          [nodeId]: { leaves, nodes },
        },
      } = treeDataRef.current;
      treeMutationsRef.current.nodes.deleted = [
        ...treeMutationsRef.current.nodes.deleted.filter((id) => id !== nodeId),
        nodeId,
      ];

      [...leaves].forEach((leafId) => deleteLeafInternal(leafId, { triggerParentNodeUpdate: false }));
      [...nodes].forEach((childNodeId) => deleteNodeInternal(childNodeId));

      if (isNodeCreated(nodeId) || treeOptions?.areDeletionsPermanent) {
        const { parentNodeId } = findNodePosition(nodeId, { removeDeletedEntities: false });
        deleteCreatedNode(nodeId);
        emitNodeRelations(parentNodeId!);
        return;
      }

      emitNodeUpdate(nodeId);
      emitNodeComputedValueUpdate(nodeId);
    },
    [
      isNodeCreated,
      treeOptions?.areDeletionsPermanent,
      emitNodeUpdate,
      emitNodeComputedValueUpdate,
      deleteLeafInternal,
      findNodePosition,
      deleteCreatedNode,
      emitNodeRelations,
    ],
  );

  const deleteNode = useCallback(
    (nodeId: Node['id']) => {
      if (nodeId === treeDataRef.current.rootNodeId) {
        throw new Error('Cannot delete root node');
      }
      if (isNodeDeleted(nodeId)) {
        return;
      }
      if (!canNodeBeDeleted(nodeId)) {
        throw new Error('Cannot delete a non deletable node');
      }

      const idToEmit = isNodeCreated(nodeId)
        ? findNodePosition(nodeId, { removeDeletedEntities: false }).parentNodeId!
        : nodeId;

      deleteNodeInternal(nodeId);

      emitNodeComputedValuesRecursivelyUpdate(idToEmit);

      treeDataRef.current.expandedNodeIds[nodeId] = false;
      // Emit node upwards updates to update `canBeRestored`
      emitNodeCanBeRestoredUpdateRecursively(idToEmit);
    },
    [
      isNodeDeleted,
      canNodeBeDeleted,
      isNodeCreated,
      findNodePosition,
      deleteNodeInternal,
      emitNodeComputedValuesRecursivelyUpdate,
      emitNodeCanBeRestoredUpdateRecursively,
    ],
  );

  const restoreLeafWithoutUpwardsUpdates = useCallback(
    (leafId: Leaf['id']) => {
      treeMutationsRef.current.leaves.deleted = treeMutationsRef.current.leaves.deleted.filter((id) => id !== leafId);
      delete treeMutationsRef.current.leaves.updated[leafId];
      treeStatesRef.current.leafUpdatedKeys[leafId] = [];

      if (isLeafCreated(leafId)) {
        const parentNodeId = deleteCreatedLeaf(leafId);
        emitNodeRelations(parentNodeId);
        return;
      }

      treeDataRef.current.leaves[leafId] = initialTree.leaves[leafId];

      treeStatesRef.current.restorableLeaves[leafId] = false;

      emitLeafUpdate(leafId);
      emitLeafComputedValueUpdate(leafId, { triggerParentNodeUpdate: false });
    },
    [
      deleteCreatedLeaf,
      emitLeafComputedValueUpdate,
      emitLeafUpdate,
      emitNodeRelations,
      initialTree.leaves,
      isLeafCreated,
    ],
  );

  const restoreNodeWithoutUpwardsUpdates = useCallback(
    (nodeId: Node['id']) => {
      let needsUpdate: boolean;

      // remove node from deleted nodes
      const updatedDeletedNode = treeMutationsRef.current.nodes.deleted.filter((id) => id !== nodeId);
      needsUpdate = updatedDeletedNode.length !== treeMutationsRef.current.nodes.deleted.length;
      treeMutationsRef.current.nodes.deleted = updatedDeletedNode;

      // Remove node updates
      needsUpdate =
        needsUpdate || getUpdatedKeysOfNode(nodeId).length > 0 || !!treeMutationsRef.current.nodes.updated[nodeId];
      treeStatesRef.current.nodeUpdatedKeys[nodeId] = [];
      delete treeMutationsRef.current.nodes.updated[nodeId];

      const isCreatedNode = isNodeCreated(nodeId);
      if (!isCreatedNode) {
        treeDataRef.current.nodes[nodeId] = initialTree.nodes[nodeId];
      }

      // Downwards restoration
      const {
        relations: {
          [nodeId]: { leaves: leavesBeforeUpdate, nodes: nodesBeforeUpdate },
        },
      } = treeDataRef.current;

      [...leavesBeforeUpdate].forEach((leafId) => {
        restoreLeafWithoutUpwardsUpdates(leafId);
      });
      [...nodesBeforeUpdate].forEach((childNodeId) => {
        restoreNodeWithoutUpwardsUpdates(childNodeId);
      });

      if (isCreatedNode) {
        const { parentNodeId } = findNodePosition(nodeId, { removeDeletedEntities: false });
        deleteCreatedNode(nodeId);
        emitNodeRelations(parentNodeId!);
        return;
      }

      treeStatesRef.current.restorableNodes[nodeId] = false;

      /*
    Emitting a node updated only when it's needed. So, when
     - Node was deleted, and it is no longer deleted
     - Node was updated, and it is no longer updated
    */
      if (needsUpdate) {
        emitNodeUpdate(nodeId);
      }

      emitNodeComputedValueUpdate(nodeId);
    },
    [
      getUpdatedKeysOfNode,
      isNodeCreated,
      emitNodeComputedValueUpdate,
      initialTree.nodes,
      restoreLeafWithoutUpwardsUpdates,
      findNodePosition,
      deleteCreatedNode,
      emitNodeRelations,
      emitNodeUpdate,
    ],
  );

  const removeDeletionForNodeIds = useCallback(
    (nodeIds: Node['id'][]) => {
      nodeIds.forEach((nodeId) => {
        const matchingDeletedNodes = treeMutationsRef.current.nodes.deleted.filter((id) => id !== nodeId);
        const shouldNodeDeletionBeRemoved =
          matchingDeletedNodes.length !== treeMutationsRef.current.nodes.deleted.length;
        if (shouldNodeDeletionBeRemoved) {
          treeMutationsRef.current.nodes.deleted = matchingDeletedNodes;
          emitNodeUpdate(nodeId);
        }
      });
    },
    [emitNodeUpdate],
  );

  const restoreNode = useCallback(
    (nodeId: Node['id']) => {
      if (!canNodeBeRestored(nodeId)) {
        return;
      }

      // Upwards restoration: clear isDeleted state of parent node
      const parentNodes = findNodePathFromNodeToRootNode(nodeId);
      const parentNodeId = parentNodes[0];
      const isCreatedNode = isNodeCreated(nodeId);

      parentNodes.reverse();
      removeDeletionForNodeIds(parentNodes);

      restoreNodeWithoutUpwardsUpdates(nodeId);

      if (!isCreatedNode) {
        emitNodeUpdate(nodeId);
      }
      emitNodeComputedValuesRecursivelyUpdate(isCreatedNode ? parentNodeId : nodeId);

      // Emit node upwards updates to update `canBeRestored`
      emitNodeCanBeRestoredUpdateRecursively(parentNodeId);
    },
    [
      canNodeBeRestored,
      emitNodeComputedValuesRecursivelyUpdate,
      emitNodeUpdate,
      emitNodeCanBeRestoredUpdateRecursively,
      findNodePathFromNodeToRootNode,
      isNodeCreated,
      removeDeletionForNodeIds,
      restoreNodeWithoutUpwardsUpdates,
    ],
  );

  const restoreLeaf = useCallback(
    (leafId: Leaf['id']) => {
      if (!canLeafBeRestored(leafId)) {
        return;
      }
      // Upwards restoration: clear isDeleted state of parent node
      const parentNodeIds = findLeafPathFromNodeToRootNode(leafId);
      const parentNodeId = parentNodeIds[0];
      removeDeletionForNodeIds(parentNodeIds.reverse());

      restoreLeafWithoutUpwardsUpdates(leafId);
      emitNodeComputedValuesRecursivelyUpdate(parentNodeId);

      treeStatesRef.current.restorableLeaves[leafId] = false;
      // Emit node upwards updates to update `canBeRestored`
      emitNodeCanBeRestoredUpdateRecursively(parentNodeId);
    },
    [
      canLeafBeRestored,
      emitNodeComputedValuesRecursivelyUpdate,
      emitNodeCanBeRestoredUpdateRecursively,
      findLeafPathFromNodeToRootNode,
      removeDeletionForNodeIds,
      restoreLeafWithoutUpwardsUpdates,
    ],
  );

  /**
   * Saves locally if a lot should be expanded or not.
   */
  const switchNodeIsExpanded = useCallback(
    (nodeId: Node['id']) => {
      const previousIsExpandedState = treeDataRef.current.expandedNodeIds[nodeId];
      treeDataRef.current.expandedNodeIds[nodeId] =
        previousIsExpandedState === undefined ? false : !previousIsExpandedState;

      emitNodeUpdate(nodeId);
    },
    [emitNodeUpdate],
  );

  const createLeaf = useCallback(
    (parentNodeId: Node['id'], previousLeafId: LeafPreviousLeafId, leafData: Partial<Omit<Leaf, 'id'>>) => {
      if (isNodeDeleted(parentNodeId)) {
        throw new Error(`Cannot create leaf in deleted node ${parentNodeId}`);
      }

      const id = uuidv4();

      const leaf = {
        ...leafData,
        id,
      } as Leaf;

      let indexToInsertAfter = 0;

      if (!isNil(previousLeafId)) {
        const { parentNodeId: parentNodeIdOfPreviousLeafId, index } = findLeafPosition(previousLeafId, {
          removeDeletedEntities: false,
        });

        if (parentNodeIdOfPreviousLeafId !== parentNodeId) {
          throw new Error('Parent node given is not the same as the parent node of the previous leaf');
        }
        indexToInsertAfter = index + 1;
      }

      const leafId = leaf.id as Leaf['id'];
      treeMutationsRef.current.leaves.created[leafId] = leaf;
      treeDataRef.current.leaves[leafId] = leaf;

      treeDataRef.current.relations[parentNodeId].leaves.splice(indexToInsertAfter, 0, leafId);
      treeDataRef.current.parentNodeIds.leaves[leafId] = parentNodeId;

      emitNodeRelations(parentNodeId);
      emitLeafComputedValueUpdate(leafId, { triggerParentNodeUpdate: true });
      treeStatesRef.current.restorableLeaves[leafId] = true;
      // Emit node upwards updates to update `canBeRestored`
      emitNodeCanBeRestoredUpdateRecursively(parentNodeId);

      return id;
    },
    [
      emitNodeRelations,
      emitNodeCanBeRestoredUpdateRecursively,
      emitLeafComputedValueUpdate,
      findLeafPosition,
      isNodeDeleted,
    ],
  );

  const createNode = useCallback(
    (
      parentNodeIdNotNullable: NodeParentNodeId,
      previousNodeId: NodePreviousNodeId,
      nodeData: Partial<Omit<Node, 'id'>>,
    ) => {
      if (isNil(parentNodeIdNotNullable)) {
        throw new Error('Parent node id cannot be null');
      }
      const parentNodeId = parentNodeIdNotNullable as Node['id'];

      if (isNodeDeleted(parentNodeId)) {
        throw new Error(`Cannot create node in deleted node ${parentNodeId}`);
      }

      const id = uuidv4();

      const node = {
        ...nodeData,
        id,
      } as Node;

      let indexToInsertAfter = 0;

      if (!isNil(previousNodeId)) {
        const { parentNodeId: parentNodeIdOfPreviousNodeId, index } = findNodePosition(previousNodeId, {
          removeDeletedEntities: false,
        });

        if (parentNodeIdOfPreviousNodeId !== parentNodeId) {
          throw new Error('Parent node given is not the same as the parent node of the previous node');
        }
        indexToInsertAfter = index + 1;
      }

      const nodeId = node.id as Node['id'];
      treeMutationsRef.current.nodes.created[nodeId] = node;
      treeDataRef.current.nodes[nodeId] = node;

      treeDataRef.current.relations[parentNodeId].nodes.splice(indexToInsertAfter, 0, node.id);
      treeDataRef.current.parentNodeIds.nodes[id as Node['id']] = parentNodeIdNotNullable;
      treeDataRef.current.relations[nodeId] = {
        nodes: [],
        leaves: [],
      };

      treeStatesRef.current.restorableNodes[nodeId] = true;
      // Emit node upwards updates to update `canBeRestored`
      emitNodeCanBeRestoredUpdateRecursively(parentNodeId);
      emitNodeComputedValueUpdate(nodeId);
      emitNodeRelations(parentNodeId);

      return id;
    },
    [
      emitNodeRelations,
      emitNodeComputedValueUpdate,
      emitNodeCanBeRestoredUpdateRecursively,
      findNodePosition,
      isNodeDeleted,
    ],
  );

  const duplicateLeaf = useCallback(
    (leafId: Leaf['id'], propertiesToOverwrite?: Partial<Leaf>) => {
      if (isLeafDeleted(leafId)) {
        throw new Error(`Cannot duplicate deleted leaf ${leafId}`);
      }

      const { parentNodeId } = findLeafPosition(leafId, { removeDeletedEntities: false });
      const { id, ...leafToDuplicate } = treeDataRef.current.leaves[leafId];

      const newLeaf = { ...leafToDuplicate, ...(propertiesToOverwrite || {}) };
      return createLeaf(parentNodeId, leafId, newLeaf);
    },
    [createLeaf, findLeafPosition, isLeafDeleted],
  );

  const duplicateNode = useCallback(
    (
      nodeId: Node['id'],
      propertiesToOverwrite?: {
        node?: Partial<Node>;
        leaf?: Partial<Leaf>;
      },
    ) => {
      const { parentNodeId, index } = findNodePosition(nodeId, { removeDeletedEntities: false });

      if (isNil(parentNodeId)) {
        throw new Error('Cannot duplicate root node');
      }
      if (isNodeDeleted(nodeId)) {
        throw new Error(`Cannot duplicate deleted node ${nodeId}`);
      }

      const correspondingCreatedNodes = new Map<Node['id'], { newNodeId: Node['id']; parentNodeId: Node['id'] }>();
      const correspondingCreatedLeaves = new Map<Leaf['id'], { newLeafId: Leaf['id']; parentNodeId: Leaf['id'] }>();
      // Index represents the relative depth compared to the duplicate node
      const nodesPerDepth: Array<Node['id'][] | undefined> = [];

      // Handle relations
      const duplicateRelations = (
        currentNodeId: Node['id'],
        newParentNodeId: Node['id'],
        relativeDepth: number,
      ): string | undefined => {
        if (isNodeDeleted(currentNodeId)) {
          return undefined;
        }

        const newNodeId = uuidv4();
        const newRelations: INodeRelation<Node, Leaf> = {
          nodes: [],
          leaves: [],
        };

        if (nodesPerDepth[relativeDepth]) {
          nodesPerDepth[relativeDepth]?.push(newNodeId);
        } else {
          nodesPerDepth[relativeDepth] = [newNodeId];
        }

        const { leaves, nodes } = treeDataRef.current.relations[currentNodeId];

        nodes.forEach((id) => {
          const newChildNodeId = duplicateRelations(id, newNodeId, relativeDepth + 1);
          // Do nothing if the node is deleted
          if (isNil(newChildNodeId)) {
            return;
          }
          newRelations.nodes.push(newChildNodeId);
        });

        leaves.forEach((id) => {
          if (isLeafDeleted(id)) {
            return;
          }
          const newLeafId = uuidv4() as Leaf['id'];
          correspondingCreatedLeaves.set(id, { newLeafId, parentNodeId: newNodeId });
          newRelations.leaves.push(newLeafId);
        });

        treeDataRef.current.relations[newNodeId as Node['id']] = newRelations;

        correspondingCreatedNodes.set(currentNodeId, { newNodeId, parentNodeId: newParentNodeId });
        return newNodeId;
      };
      const newNodeId = duplicateRelations(nodeId, parentNodeId, 0)!;

      treeDataRef.current.relations[parentNodeId].nodes.splice(index + 1, 0, newNodeId);

      // Duplicate nodes and leaves data
      correspondingCreatedNodes.forEach(({ newNodeId: newId, parentNodeId: newParentNodeId }, oldNodeId) => {
        const newNode = {
          ...treeDataRef.current.nodes[oldNodeId],
          ...(propertiesToOverwrite?.node || {}),
          id: newId,
        };

        treeMutationsRef.current.nodes.created[newId] = newNode;
        treeDataRef.current.parentNodeIds.nodes[newId] = newParentNodeId;
        treeDataRef.current.nodes[newId] = newNode;
        treeStatesRef.current.restorableNodes[newId] = true;
      });
      correspondingCreatedLeaves.forEach(({ newLeafId: newId, parentNodeId: newParentNodeId }, oldLeafId) => {
        const newLeaf = {
          ...treeDataRef.current.leaves[oldLeafId],
          ...(propertiesToOverwrite?.leaf || {}),
          id: newId,
        };

        treeMutationsRef.current.leaves.created[newId] = newLeaf;
        treeDataRef.current.parentNodeIds.leaves[newId] = newParentNodeId;
        treeDataRef.current.leaves[newId] = newLeaf;
        treeStatesRef.current.restorableLeaves[newId] = true;

        emitLeafComputedValueUpdate(newId, { triggerParentNodeUpdate: false });
      });

      // Emit computed value updates for nodes
      nodesPerDepth.reverse().forEach((ids) => {
        if (isNil(ids)) {
          return;
        }
        ids.forEach((id) => {
          emitNodeComputedValueUpdate(id);
        });
      });

      // Emit node upwards updates to update `canBeRestored`
      emitNodeCanBeRestoredUpdateRecursively(parentNodeId);
      emitNodeComputedValuesRecursivelyUpdate(parentNodeId);
      emitNodeRelations(parentNodeId);

      return newNodeId;
    },
    [
      emitLeafComputedValueUpdate,
      emitNodeCanBeRestoredUpdateRecursively,
      emitNodeComputedValueUpdate,
      emitNodeComputedValuesRecursivelyUpdate,
      emitNodeRelations,
      findNodePosition,
      isLeafDeleted,
      isNodeDeleted,
    ],
  );

  const toggleAllNodes = useCallback(() => {
    const states = Object.values(treeDataRef.current.expandedNodeIds);
    const futureExpendState = states.length > 0 && states.every((isExpended) => isExpended === false);

    const nodeIds = Object.values(getCurrentTree().nodes as NodeWithRelations<Node>[])
      .filter((node) => node.parentNodeId)
      .map((node) => node.id) as Node['id'][];

    treeDataRef.current.expandedNodeIds = nodeIds.reduce<Record<Node['id'], boolean>>(
      (acc, nodeId) => {
        acc[nodeId] = futureExpendState;
        return acc;
      },
      {} as Record<Node['id'], boolean>,
    );

    nodeIds.forEach((id) => {
      emitNodeUpdate(id);
    });
  }, [emitNodeUpdate, getCurrentTree]);

  useEffect(() => {
    const nodeIdsSortedByDepth = findSubNodesOrderByDepthDesc(treeDataRef.current.rootNodeId);

    nodeIdsSortedByDepth.forEach((nodeId) => {
      const { leaves, nodes } = treeDataRef.current.relations[nodeId];
      numberOfInitialEntitiesInNode.current[nodeId] =
        1 + // itself
        nodes.reduce((acc, id) => acc + numberOfInitialEntitiesInNode.current[id]!, 0) + // number of leaves of nested nodes
        leaves.length; // number of leaves
    });

    if (!computedValuesFns) {
      return;
    }

    // Compute and emit computed values for all leaves.
    Object.values<Leaf>(treeDataRef.current.leaves).forEach((leaf) => {
      emitLeafComputedValueUpdate(leaf.id, { triggerParentNodeUpdate: false });
    });

    nodeIdsSortedByDepth.forEach((nodeId) => {
      emitNodeComputedValueUpdate(nodeId);
    });
  }, [
    emitLeafComputedValueUpdate,
    emitNodeComputedValuesRecursivelyUpdate,
    getChanges,
    getCurrentTree,
    getLeafSignalInfos,
    getNodeSignalInfos,
    getComputedValues,
    computedValuesFns,
    emitNodeComputedValueUpdate,
    findSubNodesOrderByDepthDesc,
  ]);

  return useMemo<TreeContextApi<Node, Leaf, NodeComputedValue, LeafComputedValue>>(
    () => ({
      getInitialTree,
      getCurrentTree,
      getDisplayedCurrentTree,
      getChanges,
      getComputedValues,
      getNodeSignalInfos,
      getNodeRelationsSignal,
      getLeafSignalInfos,

      // -- Subscribe system
      emitNodeUpdate,
      listenToNode,
      listenToNodeComputedValue,
      listenToNodeRelations,
      emitNodeRelations,
      emitLeafUpdate,
      listenToLeaf,
      listenToLeafComputedValue,

      // -- Update methods
      updateNodeData,
      updateLeafData,
      updateAllChildrenLeavesOfNode,

      // -- Delete methods
      deleteNode,
      deleteLeaf,

      // -- Restore methods
      restoreLeaf,
      restoreNode,

      // -- Create methods
      createLeaf,
      createNode,

      // -- Move methods
      moveLeaf,
      moveNode,

      // -- Duplicate methods
      duplicateLeaf,
      duplicateNode,

      // -- Utils
      isNodeDescendingChildrenOfNode,
      isLeafAfterLeaf,
      getLastLeafOfNode,
      isNodeAfterNode,
      getLastNodeOfNode,
      isNodeDeleted,
      isNodeCreated,
      canNodeBeRestored,
      getUpdatedKeysOfNode,
      isLeafDeleted,
      isLeafCreated,
      canLeafBeRestored,
      getUpdatedKeysOfLeaf,
      findDescendingChildrenOfNode,
      findSubNodesOrderByDepthDesc,
      findNodePathFromNodeToRootNode,
      findNodePosition,
      findLeafPosition,

      // -- State system
      switchNodeIsExpanded,
      toggleAllNodes,
    }),
    [
      getInitialTree,
      getCurrentTree,
      getDisplayedCurrentTree,
      getChanges,
      getComputedValues,
      getNodeSignalInfos,
      getNodeRelationsSignal,
      getLeafSignalInfos,
      emitNodeUpdate,
      listenToNode,
      listenToNodeComputedValue,
      listenToNodeRelations,
      emitNodeRelations,
      emitLeafUpdate,
      listenToLeaf,
      listenToLeafComputedValue,
      updateNodeData,
      updateLeafData,
      updateAllChildrenLeavesOfNode,
      deleteNode,
      deleteLeaf,
      restoreLeaf,
      restoreNode,
      createLeaf,
      createNode,
      moveLeaf,
      moveNode,
      duplicateLeaf,
      duplicateNode,
      isNodeDescendingChildrenOfNode,
      isLeafAfterLeaf,
      getLastLeafOfNode,
      isNodeAfterNode,
      getLastNodeOfNode,
      isNodeDeleted,
      isNodeCreated,
      canNodeBeRestored,
      getUpdatedKeysOfNode,
      isLeafDeleted,
      isLeafCreated,
      canLeafBeRestored,
      getUpdatedKeysOfLeaf,
      findDescendingChildrenOfNode,
      findSubNodesOrderByDepthDesc,
      findNodePathFromNodeToRootNode,
      findNodePosition,
      findLeafPosition,
      switchNodeIsExpanded,
      toggleAllNodes,
    ],
  );
};
