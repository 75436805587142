import { useEffect, useState } from 'react';
import type { IQuoteJob } from '@graneet/business-logic';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Hook used by job components in order to render themselves only
 * when their data has changed
 */
export const useJob = (jobId: number) => {
  const { listenToJob } = useQuoteEditContext();
  const [job, setJob] = useState<IQuoteJob | undefined>(undefined);

  useEffect(() => listenToJob(jobId, setJob), [listenToJob, jobId]);

  return job;
};
