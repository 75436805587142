import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { Card, TextField, DateField, IconAdvanced, SimpleHelpIcon } from '@graneet/lib-ui';
import { Flex } from '@chakra-ui/react';
import type { IProgressStatement, IProject } from '@graneet/business-logic';
import { SUB_PROJECT_BILLING_TYPE, STATEMENT_TYPES } from '@graneet/business-logic';

import { ProgressStatementBillingTermLabel } from '../labels/ProgressStatementBillingTermLabel';
import type { ProgressStatementInformationForm } from '../../forms/progress-statement-information-form';

import { RuleIsInvoiceNumberAvailable } from 'features/statement/rules/RuleIsInvoiceNumberAvailable';
import { Rule } from 'features/form/rules/Rule';
import { InvoiceNumberField } from 'features/statement-ledger/components/InvoiceNumberField';

interface BillingTermProps {
  project: IProject;
}

const BillingTerm: FC<BillingTermProps> = ({ project }) => {
  const form = useFormContext<ProgressStatementInformationForm>();
  const { billingDate } = useOnChangeValues(form, ['billingDate']);

  return (
    <ProgressStatementBillingTermLabel
      billingDate={billingDate}
      paymentTerm={project.paymentTerm}
      deadlineType={project.deadlineType}
    />
  );
};

interface ProgressStatementEditionCardProps {
  project: IProject;
  currentProgressStatement?: IProgressStatement | null;
  billingType: SUB_PROJECT_BILLING_TYPE;
}

export const ProgressStatementEditionCard: FC<ProgressStatementEditionCardProps> = ({
  currentProgressStatement,
  project,
  billingType,
}) => {
  const { t } = useTranslation(['directProgressStatement', 'progressStatement']);

  const isDirectBilling = billingType === SUB_PROJECT_BILLING_TYPE.DIRECT;
  const type = isDirectBilling ? STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT : STATEMENT_TYPES.PROGRESS_STATEMENT;

  return (
    <Card overflow="auto" w="100%">
      <Flex mb={8} justifyContent="space-between">
        <TextField<ProgressStatementInformationForm>
          data-testid="textField:progressStatement.name"
          name="name"
          label={
            isDirectBilling
              ? t('directProgressStatement:fields.name')
              : t('progressStatement:isCreating.nameProgressStatement')
          }
          rightIcon={<IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('progressStatement:tooltips.name')} />}
          w="60%"
          isRequired
        >
          <Rule.IsRequired />
        </TextField>

        <Flex w="30%">
          <InvoiceNumberField<ProgressStatementInformationForm>
            label={t('progressStatement:isCreating.invoiceNumber')}
            name="invoiceNumber"
            invoiceNumber={currentProgressStatement?.invoiceNumber}
            type={type}
          >
            <RuleIsInvoiceNumberAvailable statementId={currentProgressStatement?.id} type={type} />
          </InvoiceNumberField>
        </Flex>
      </Flex>

      <Flex alignItems="flex-start">
        <DateField<ProgressStatementInformationForm>
          name="billingDate"
          label={t('progressStatement:isCreating.billingDate')}
          rightIcon={
            <IconAdvanced
              icon={<SimpleHelpIcon />}
              tooltip={
                isDirectBilling
                  ? t('directProgressStatement:form.information.billingDateTooltip')
                  : t('progressStatement:tooltips.billingDate')
              }
            />
          }
          w="100%"
          isRequired
        >
          <Rule.IsRequired />
        </DateField>

        <BillingTerm project={project} />
      </Flex>
    </Card>
  );
};
