import type { IPenaltyUpsertDTO, IProgressStatement } from '@graneet/business-logic';

export type PriceRevisionFieldName = `priceRevision_${number}`;

export const getPriceRevisionFieldName = (progressStatement: IProgressStatement): PriceRevisionFieldName =>
  `priceRevision_${progressStatement.id}`;

export interface FinalStatementPriceRevisionForm {
  [priceRevision: PriceRevisionFieldName]: number;
}

export interface FinalStatementSummaryForm {
  name: string;

  date: string;

  note?: string | null;
}

export interface FinalStatementPenaltyForm {
  penalties: IPenaltyUpsertDTO[];
}

export type FinalStatementEditionWizard = {
  contracts: {};
  progressStatements: {};
  priceRevision: FinalStatementPriceRevisionForm;
  penalties: FinalStatementPenaltyForm;
  bills: {};
  directPayments: {};
  summary: FinalStatementSummaryForm;
};
