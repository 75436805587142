import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, AlertMessage, List, getChecklistIcon } from '@graneet/lib-ui';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { IQuote, IQuoteCheckResponseDTO } from '@graneet/business-logic';

interface AlertQuoteStatusCompletionErrorModalProps {
  errors: IQuoteCheckResponseDTO;
  quote: IQuote;
  onEdit(): void;
  modalControls: UseDisclosureReturn;
}

export const AlertQuoteStatusCompletionErrorModal: FC<AlertQuoteStatusCompletionErrorModalProps> = ({
  errors,
  quote,
  onEdit,
  modalControls,
}) => {
  const { t } = useTranslation(['quote']);

  return (
    <Modal {...modalControls} title={t('quote:completeModal.quoteCompletion')} size="3xl" scrollBehavior="inside">
      <AlertMessage type="error">{t('quote:completeModal.completionError')}</AlertMessage>
      <Box my={8}>{t('quote:completeModal.completionHint', { name: quote.name })}</Box>
      <List mb={4}>
        <List.Item
          icon={getChecklistIcon(errors.incompleteJobsCount === 0)}
          label={
            errors.incompleteJobsCount === 0
              ? t('quote:completeModal.success.jobs')
              : t('quote:completeModal.error.jobs', { count: errors.incompleteJobsCount })
          }
        />
        <List.Item
          icon={getChecklistIcon(errors.isClientAttached)}
          label={t(errors.isClientAttached ? 'quote:completeModal.success.client' : 'quote:completeModal.error.client')}
        />
        <List.Item
          icon={getChecklistIcon(errors.areMandatoryFieldsFilled)}
          label={t(
            errors.areMandatoryFieldsFilled
              ? 'quote:completeModal.success.mandatoryFields'
              : 'quote:completeModal.error.mandatoryFields',
          )}
        />
      </List>

      <Modal.Close />

      <Modal.PrimaryButton onClick={onEdit}>{t('quote:actions.modify')}</Modal.PrimaryButton>
    </Modal>
  );
};
