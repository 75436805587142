import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

export interface IQuoteDisplayContext {
  openLotUpdateDrawer(lotId: number): void;
  lotIdToUpdate: number | undefined;

  openLotDefaultVatRateEditModal(lotId: number): void;
  lotIdToEditDefaultVatRate: number | undefined;

  openLibraryJobImportDrawer(lotId: number): void;
  getTargetLotIdForLibraryJobImport(): number | undefined;

  openBatiprixImportDrawer(lotId: number): void;
  getTargetLotIdForBatiprixImport(): undefined | number;

  setDisplaySaleView: Dispatch<SetStateAction<boolean>>;
  displaySaleView: boolean;

  setIsReadOnlyView(readOnlyView: boolean): void;
  isReadOnlyView: boolean;
}

const THROW_ERROR = () => {
  throw new Error('No QuoteDisplayContext found');
};

export const QuoteDisplayContext = createContext<IQuoteDisplayContext>({
  openLotUpdateDrawer: THROW_ERROR,
  lotIdToUpdate: undefined,

  openLotDefaultVatRateEditModal: THROW_ERROR,
  lotIdToEditDefaultVatRate: undefined,

  openLibraryJobImportDrawer: THROW_ERROR,
  getTargetLotIdForLibraryJobImport: THROW_ERROR,

  openBatiprixImportDrawer: THROW_ERROR,
  getTargetLotIdForBatiprixImport: THROW_ERROR,

  displaySaleView: false,
  setDisplaySaleView: THROW_ERROR,

  isReadOnlyView: false,
  setIsReadOnlyView: THROW_ERROR,
});
