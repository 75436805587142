import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { CurrencyField, TextField } from '@graneet/lib-ui';
import { Grid } from '@chakra-ui/react';
import { useOnChangeValues, useFormContext } from 'graneet-form';

import { Rule } from 'features/form/rules/Rule';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { useComponentTypes } from 'features/component-type/services/component-type.api';

interface LibraryComponentFieldsProps {
  readOnly?: boolean;
}

export interface LibraryComponentFieldsForm {
  refCode?: string | null;

  description: string;

  unit: string;

  unitDisbursementExVAT: number;

  componentTypeId: number;
}

export const LibraryComponentFields: FC<LibraryComponentFieldsProps> = ({ readOnly = false }) => {
  const { t } = useTranslation(['library']);
  const componentTypes = useComponentTypes();

  const [isUnitForced, setIsUnitForced] = useState(false);

  const form = useFormContext<LibraryComponentFieldsForm>();
  const { componentTypeId } = useOnChangeValues(form, ['componentTypeId']);
  const { setFormValues } = form;

  useEffect(() => {
    const componentType = componentTypes.data.find(({ id }) => id === componentTypeId);
    setIsUnitForced(!!componentType?.unit);
    if (componentType?.unit) {
      setFormValues({
        unit: componentType.unit,
      });
    }
  }, [componentTypes.data, setFormValues, componentTypeId]);

  return (
    <Grid gap={6} w="100%" templateColumns="10rem 6rem 3fr 6rem 10rem">
      <ComponentTypeField<LibraryComponentFieldsForm>
        name="componentTypeId"
        label={t('library:libraryComponent.fields.type')}
        isRequired
        isDisabled={readOnly}
        material
        workforce
      >
        <Rule.IsRequired />
      </ComponentTypeField>

      <TextField<LibraryComponentFieldsForm>
        name="refCode"
        label={t('library:libraryComponent.fields.refCode')}
        isDisabled={readOnly}
      />

      <TextField<LibraryComponentFieldsForm>
        name="description"
        label={t('library:libraryComponent.fields.description')}
        isRequired
        isDisabled={readOnly}
      >
        <Rule.IsRequired />
      </TextField>

      <TextField<LibraryComponentFieldsForm>
        name="unit"
        label={t('library:libraryComponent.fields.unit')}
        isRequired
        isDisabled={isUnitForced || readOnly}
      >
        <Rule.IsRequired />
      </TextField>

      <CurrencyField<LibraryComponentFieldsForm>
        name="unitDisbursementExVAT"
        label={t('library:libraryComponent.fields.unitDisbursementExVAT')}
        isRequired
        isDisabled={readOnly}
      >
        <Rule.IsRequired />
      </CurrencyField>
    </Grid>
  );
};
