import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';

export const GraneetIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 60 60" {...props}>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.9008 29.272H51.7505V49.72C48.9648 51.784 45.7392 53.368 42.0737 54.472C38.4083 55.576 34.7184 56.128 31.0041 56.128C25.6769 56.128 20.8874 55.024 16.6355 52.816C12.3836 50.56 9.03578 47.464 6.59214 43.528C4.19738 39.544 3 35.056 3 30.064C3 25.072 4.19738 20.608 6.59214 16.672C9.03578 12.688 12.408 9.592 16.7088 7.384C21.0096 5.128 25.848 4 31.224 4C35.7203 4 39.8012 4.744 43.4666 6.232C47.1321 7.72 50.2111 9.88 52.7036 12.712L45.0794 19.624C41.414 15.832 36.991 13.936 31.8105 13.936C28.536 13.936 25.6281 14.608 23.0867 15.952C20.5453 17.296 18.566 19.192 17.1487 21.64C15.7313 24.088 15.0227 26.896 15.0227 30.064C15.0227 33.184 15.7313 35.968 17.1487 38.416C18.566 40.864 20.5209 42.784 23.0134 44.176C25.5548 45.52 28.4383 46.192 31.6639 46.192C35.0849 46.192 38.1639 45.472 40.9008 44.032V29.272Z"
        fill="#334D6E"
      />
      <path
        d="M58.0002 21C58.0002 23.7614 55.7209 26 52.9093 26C50.0976 26 47.8184 23.7614 47.8184 21C47.8184 18.2386 50.0976 16 52.9093 16C55.7209 16 58.0002 18.2386 58.0002 21Z"
        fill="#109CF1"
      />
    </svg>
  </Icon>
);
