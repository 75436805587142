import type { CellClassParams, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';
import { QUOTE_ITEM_TYPE_OF_PRICING } from '@org/quotation-lib';

import { AmountCell } from '../../components/columns/AmountCell/AmountCell';
import { AmountEditorCell } from '../../components/columns/AmountCell/AmountEditorCell';
import type { AGColDef } from '../../types';

import { useQuoteBasicItemUpdateUnitAmount } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateUnitAmount';
import { useQuoteComponentUpdateUnitAmount } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateUnitAmount';
import { useQuoteSubItemUpdateUnitAmount } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdateUnitAmount';
import { useQuoteOptionalItemUpdateUnitAmount } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateUnitAmount';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useUnitAmountColumnDef(isEditable: boolean) {
  const quoteBasicItemUpdateUnitAmount = useQuoteBasicItemUpdateUnitAmount();
  const quoteSubItemUpdateUnitAmount = useQuoteSubItemUpdateUnitAmount();
  const quoteComponentUpdateUnitAmount = useQuoteComponentUpdateUnitAmount();
  const quoteOptionalItemUpdateUnitAmount = useQuoteOptionalItemUpdateUnitAmount();

  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'unitAmount',
      width,
      index,
      headerName: label,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressMovable: false,
      editable: (params) => {
        if (!params.data) {
          return false;
        }
        if (!isEditable) {
          return false;
        }
        if (
          (params.data.content.type === 'QuoteBasicItem' ||
            params.data.content.type === 'QuoteOptionalItem' ||
            params.data.content.type === 'QuoteHiddenCostItem' ||
            params.data.content.type === 'QuoteSubItem') &&
          params.data.content.typeOfPricing === QUOTE_ITEM_TYPE_OF_PRICING.FLAT_RATE
        ) {
          return false;
        }

        switch (params.data?.content.type) {
          case 'QuoteBasicItem':
          case 'QuoteOptionalItem':
          case 'QuoteSubItem':
          case 'QuoteComponent':
            return true;
          default:
            return false;
        }
      },
      sortable: false,
      cellStyle: { textAlign: 'right' },
      cellClass: (params: CellClassParams) => {
        if (params.node.footer || params.data?.content.type === 'QuoteLot') return 'no-focus read-only';
        switch (params.data?.content.type) {
          case 'QuoteBasicItem':
          case 'QuoteOptionalItem':
          case 'QuoteSubItem':
          case 'QuoteComponent':
            return '';
          default:
            return 'no-focus read-only';
        }
      },
      cellRenderer: AmountCell,
      cellRendererParams: {
        mandatory: true,
        couldBeCalculated: true,
      },
      cellEditor: AmountEditorCell,
      valueGetter: (params) => {
        if (!params.data) {
          return '-';
        }

        switch (params.data.content.type) {
          case 'QuoteComponent':
          case 'QuoteBasicItem':
          case 'QuoteHiddenCostItem':
          case 'QuoteOptionalItem':
          case 'QuoteSubItem':
            return params.data?.content.unitAmount;
          case 'QuoteLot':
            return '';
          default:
            throw new Error('Type unknown');
        }
      },
      valueSetter: (params) => {
        const newValue = params.newValue.replace(',', '.');

        if (params.oldValue?.toString() === newValue?.toString()) {
          return false;
        }

        switch (params.data?.content.type) {
          case 'QuoteBasicItem': {
            executeCommand(quoteBasicItemUpdateUnitAmount(params.data.id, newValue), quote);
            return true;
          }
          case 'QuoteOptionalItem': {
            executeCommand(quoteOptionalItemUpdateUnitAmount(params.data.id, newValue), quote);
            return true;
          }
          case 'QuoteSubItem': {
            executeCommand(quoteSubItemUpdateUnitAmount(params.data.id, newValue), quote);
            return true;
          }
          case 'QuoteComponent': {
            executeCommand(quoteComponentUpdateUnitAmount(params.data.id, newValue), quote);
            return true;
          }
          default:
            return false;
        }
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [
      isEditable,
      executeCommand,
      quoteBasicItemUpdateUnitAmount,
      quote,
      quoteOptionalItemUpdateUnitAmount,
      quoteSubItemUpdateUnitAmount,
      quoteComponentUpdateUnitAmount,
    ],
  );
}
