import { createQueryKeys } from '@lukemorales/query-key-factory';
import type {
  BankingTransactionPaginatedResponse,
  IBankingTransactionLinkBillDTO,
  IBankingTransactionLinkSupplierInvoiceDTO,
  IBankingTransactionUpdateDTO,
  IBankingTransactionWithRelations,
  SUPPLIER_INVOICE_PAYMENT_FROM,
} from '@graneet/business-logic';
import type { QueryClient } from '@tanstack/react-query';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { type UsePaginationOptions, usePaginationQuery, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { apiNew } from 'features/api/services/apiNew.service';

const FACTORY_NAME = 'banking-transaction';
const BANKING_TRANSACTION_PATH = '/banking-transactions';

async function invalideAllBankingTransactionQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [FACTORY_NAME],
  });
}

const bankingTransactionKeyFactory = createQueryKeys(FACTORY_NAME, {
  get: (queryParams: URLSearchParams) => ({
    queryKey: [BANKING_TRANSACTION_PATH, queryParams],
    queryFn: () =>
      apiNew.get<URLSearchParams, BankingTransactionPaginatedResponse>(BANKING_TRANSACTION_PATH, queryParams),
  }),
  getOne: (id: string) => ({
    queryKey: [BANKING_TRANSACTION_PATH, id],
    queryFn: () => apiNew.get<never, IBankingTransactionWithRelations>(`${BANKING_TRANSACTION_PATH}/${id}`),
  }),
  getAvailableTags: () => ({
    queryKey: [BANKING_TRANSACTION_PATH],
    queryFn: () => apiNew.get<never, string[]>(`${BANKING_TRANSACTION_PATH}/tags`),
  }),
});

export function useBankingTransactions(paginationOptions?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (queryParams) => queryClient.fetchQuery(bankingTransactionKeyFactory.get(queryParams)),
    paginationOptions,
  );
}

export function useBankingTransaction(id: string) {
  return useSuspenseQuery(bankingTransactionKeyFactory.getOne(id));
}

export function useBankingTransactionAvailableTags() {
  return useSuspenseQuery(bankingTransactionKeyFactory.getAvailableTags());
}

// -- Mutations
export function useBankingTransactionUpdate() {
  const { t } = useTranslation(['global', 'banking']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: string; dto: IBankingTransactionUpdateDTO }) =>
      apiNew.patch<IBankingTransactionUpdateDTO, IBankingTransactionWithRelations>(
        `${BANKING_TRANSACTION_PATH}/${params.id}`,
        params.dto,
      ),
    onSuccess: async () => {
      await invalideAllBankingTransactionQueries(queryClient);
      toast.success(t('banking:bankingTransaction.toasts.successUpdate'));
    },
    onError: () => {
      toast.success(t('global:errors.error'));
    },
  });
}

export function useBankingTransactionUpdateToToReconcile() {
  const { t } = useTranslation(['global', 'banking']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) =>
      apiNew.patch<never, IBankingTransactionWithRelations>(`${BANKING_TRANSACTION_PATH}/${id}/status/to-reconcile`),
    onSuccess: async () => {
      await invalideAllBankingTransactionQueries(queryClient);
      toast.success(t('banking:bankingTransaction.toasts.successUpdate'));
    },
    onError: () => {
      toast.success(t('global:errors.error'));
    },
  });
}

export function useBankingTransactionUpdateToReconciled() {
  const { t } = useTranslation(['global', 'banking']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) =>
      apiNew.patch<never, IBankingTransactionWithRelations>(`${BANKING_TRANSACTION_PATH}/${id}/status/reconciled`),
    onSuccess: async () => {
      await invalideAllBankingTransactionQueries(queryClient);
      toast.success(t('banking:bankingTransaction.toasts.successUpdate'));
    },
    onError: () => {
      toast.success(t('global:errors.error'));
    },
  });
}

export function useBankingTransactionLinkBill() {
  const { t } = useTranslation(['global', 'banking']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: string; dto: IBankingTransactionLinkBillDTO }) =>
      apiNew.put<IBankingTransactionLinkBillDTO, IBankingTransactionWithRelations>(
        `${BANKING_TRANSACTION_PATH}/${params.id}/link/bill`,
        params.dto,
      ),
    onSuccess: async () => {
      await invalideAllBankingTransactionQueries(queryClient);
      toast.success(t('banking:bankingTransaction.toasts.successUpdate'));
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useBankingTransactionUnlinkBill() {
  const { t } = useTranslation(['global', 'banking']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: string; billId: number }) =>
      apiNew.patch<never, void>(`${BANKING_TRANSACTION_PATH}/${params.id}/unlink/bill/${params.billId}`),
    onSuccess: async () => {
      await invalideAllBankingTransactionQueries(queryClient);
      toast.success(t('banking:bankingTransaction.toasts.successUpdate'));
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useBankingTransactionLinkSupplierInvoice() {
  const { t } = useTranslation(['global', 'banking']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: {
      id: string;
      dto: IBankingTransactionLinkSupplierInvoiceDTO;
      from: SUPPLIER_INVOICE_PAYMENT_FROM;
    }) =>
      apiNew.put<IBankingTransactionLinkSupplierInvoiceDTO, IBankingTransactionWithRelations>(
        `${BANKING_TRANSACTION_PATH}/${params.id}/link/supplier-invoice?from=${params.from}`,
        params.dto,
      ),
    onSuccess: async () => {
      await invalideAllBankingTransactionQueries(queryClient);
      toast.success(t('banking:bankingTransaction.toasts.successUpdate'));
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useBankingTransactionUnlinkSupplierInvoice() {
  const { t } = useTranslation(['global', 'banking']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: string; supplierInvoiceId: number }) =>
      apiNew.patch<never, void>(
        `${BANKING_TRANSACTION_PATH}/${params.id}/unlink/supplier-invoice/${params.supplierInvoiceId}`,
      ),
    onSuccess: async () => {
      await invalideAllBankingTransactionQueries(queryClient);
      toast.success(t('banking:bankingTransaction.toasts.successUpdate'));
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}
