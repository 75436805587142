import * as ReactDOMClient from 'react-dom/client';

import './config/env';
import './locales/i18next';
import './config/axios';
import './config/sentry';
import './config/datadog';

import { App } from './App';

import './App.css';
import 'remixicon/fonts/remixicon.css';
import '@fontsource/lato/latin.css';
import '@fontsource/open-sans/latin.css';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container!);

root.render(<App />);
