import type { FieldValues, AnyRecord } from 'graneet-form';
import { Field, VALIDATION_OUTCOME, composeEventHandlers } from 'graneet-form';

import type { KeysMatching } from '../../../utils/type.util';
import type { InputProps } from '../../Input';
import { Input } from '../../Input';

export type TextFieldValue = string | undefined | null;

export interface TextFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, TextFieldValue> = KeysMatching<T, TextFieldValue>,
> extends Omit<InputProps, 'onChange'> {
  name: K;

  data?: AnyRecord;

  hideErrorMessage?: boolean;

  onChange?(value: T[K]): void | Promise<void>;
}

export const TextField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, TextFieldValue> = KeysMatching<T, TextFieldValue>,
>({
  name,
  data,
  children = null,
  onBlur,
  onChange,
  onFocus,
  hideErrorMessage = false,
  ...rest
}: TextFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    data={data}
    render={(properties, state) => {
      const { value, onChange: onChangeForm, onBlur: onBlurForm, onFocus: onFocusForm } = properties;
      const { validationStatus } = state;
      const shouldDisplayError = validationStatus.status === VALIDATION_OUTCOME.INVALID;

      return (
        <Input
          {...rest}
          shouldDisplayError={shouldDisplayError && !hideErrorMessage}
          errorMessage={validationStatus.message}
          value={value || ''}
          onChange={composeEventHandlers(onChange, onChangeForm)}
          onBlur={composeEventHandlers(onBlur, onBlurForm)}
          onFocus={composeEventHandlers(onFocus, onFocusForm)}
        />
      );
    }}
  >
    {children}
  </Field>
);
