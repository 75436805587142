import type { FC } from 'react';
import { useCallback, useLayoutEffect } from 'react';
import { Tabs } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { ViewQuoteSummaryTab } from './_tabs/ViewQuoteSummaryTab';
import { ViewQuoteInformationTab } from './_tabs/ViewQuoteInformationTab';
import { ViewQuoteClientInformationTab } from './_tabs/ViewQuoteClientInformationTab';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useContactAssociatedToQuote } from 'features/contact/services/contact.api';
import { useProjectOrUndefined } from 'features/project/services/project.api';
import { getQuoteInfos } from 'features/quote/services/quote.api';
import { Error } from 'features/common/components/Error';
import { Loading } from 'features/common/components/Loading';
import { DisplayNotFoundScreen } from 'screens/DisplayNotFoundScreen';
import { useData } from 'features/api/hooks/useData';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewQuoteScreen: FC = () => {
  const { t } = useTranslation(['quote', 'global']);
  const { quoteId } = useParams<{ quoteId: string }>();
  const { updateHeaderTitle } = useHeaderContext();

  const hasQuoteV2FeatureFlag = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);

  const quoteData = useData(useDataGetter(getQuoteInfos, parseInt(quoteId, 10)));
  const contacts = useContactAssociatedToQuote(parseInt(quoteId, 10));
  const project = useProjectOrUndefined(quoteData.data?.quote.project?.id);

  const refreshData = useCallback(() => {
    quoteData.fetch();
  }, [quoteData]);

  useLayoutEffect(() => {
    updateHeaderTitle(
      quoteData.data?.quote?.name || '',
      hasQuoteV2FeatureFlag
        ? [
            {
              name: t('global:nav.quotes'),
              link: '/opportunities/quotation',
            },
          ]
        : [
            {
              name: t('global:nav.quotes'),
              link: '/opportunities/quotes',
            },
          ],
    );
  }, [hasQuoteV2FeatureFlag, quoteData.data, t, updateHeaderTitle]);

  if (quoteData.loading && !quoteData.data) {
    return <Loading />;
  }

  if (quoteData.error) {
    return <Error />;
  }

  if (quoteData.data.quote.isArchived) {
    return <Error />;
  }

  return (
    <>
      <Tabs
        hasInternalPadding
        fallbackPath="./summary"
        data={[
          {
            id: 'summary',
            title: t('quote:menu.summary'),
            exact: true,
            path: './summary',
            content: (
              <QueryWrapper>
                <ViewQuoteSummaryTab
                  quote={quoteData.data.quote}
                  project={project.data}
                  reloadQuote={refreshData}
                  rootLot={quoteData.data.lots[quoteData.data.rootLotId]}
                  quoteContacts={contacts.data}
                />
              </QueryWrapper>
            ),
          },

          {
            id: 'details',
            title: t('quote:menu.details'),
            exact: true,
            path: './details',
            content: <ViewQuoteInformationTab quote={quoteData.data.quote} reloadQuote={refreshData} />,
          },
          {
            id: 'client',
            title: t('quote:menu.client'),
            exact: true,
            path: './client',
            content: (
              <QueryWrapper>
                <ViewQuoteClientInformationTab quote={quoteData.data.quote} reloadQuote={refreshData} />
              </QueryWrapper>
            ),
          },
        ]}
        errorComponent={<DisplayNotFoundScreen />}
      />
    </>
  );
};
