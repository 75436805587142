import type { QuoteNodeDeleteDTO, QuoteNodeDeletesApi } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteNodeDeletesProxyApi {
  private static quoteNodeDeletesProxyApi: QuoteNodeDeletesProxyApi;

  private readonly quoteNodeDeletesApi: QuoteNodeDeletesApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteNodeDeletesApi = externalApi.getQuoteNodeDeletesApi();
  }

  public static getQuoteNodeDeletesProxyApi(externalApi: ExternalApi): QuoteNodeDeletesProxyApi {
    if (!this.quoteNodeDeletesProxyApi) {
      QuoteNodeDeletesProxyApi.quoteNodeDeletesProxyApi = new QuoteNodeDeletesProxyApi(externalApi);
    }
    return QuoteNodeDeletesProxyApi.quoteNodeDeletesProxyApi;
  }

  public async deleteQuoteNode(quoteNodeDeleteDTO: QuoteNodeDeleteDTO) {
    return proxyHelper(async () => this.quoteNodeDeletesApi.deleteQuoteNode(quoteNodeDeleteDTO));
  }
}
