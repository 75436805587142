import { useMemo } from 'react';
import type { FieldValues } from 'graneet-form';

import type { MaskedNumberFieldProps, RoundableNumberField } from '../MaskedNumberField';
import { MaskedNumberField } from '../MaskedNumberField';
import type { KeysMatching } from '../../../utils';
import { ceilFloating, floorFloating, MAXIMUM_FRACTIONAL_PART_LENGTH } from '../../../utils';

type IntegerFieldValue = number | null | undefined;

export interface PercentageFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, IntegerFieldValue> = KeysMatching<T, IntegerFieldValue>,
> extends Omit<MaskedNumberFieldProps<T, K>, 'type' | 'options'>,
    RoundableNumberField {
  min?: number;
  max?: number;
}

export const PercentageField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, IntegerFieldValue> = KeysMatching<T, IntegerFieldValue>,
>({
  name,
  min,
  max,
  scale = 2,
  children,
  ...rest
}: PercentageFieldProps<T, K>) => {
  const fractionalPartLength = scale ? parseFloat(scale.toString()) : MAXIMUM_FRACTIONAL_PART_LENGTH;

  const options = useMemo(
    () => ({
      min: min && Number.isFinite(min) ? ceilFloating(min, fractionalPartLength) : min,
      max: max && Number.isFinite(max) ? floorFloating(max, fractionalPartLength) : max,
      scale: fractionalPartLength,
    }),
    [min, fractionalPartLength, max],
  );

  return (
    <MaskedNumberField name={name} options={options} {...rest} type="percentage">
      {children}
    </MaskedNumberField>
  );
};
