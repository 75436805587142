import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CounterLoaderProvider,
  useCounterLoader,
  DrawersStack,
  useDrawersStack,
  useDrawer,
  useToast,
} from '@graneet/lib-ui';
import { Box } from '@chakra-ui/react';
import { Form, useStepForm } from 'graneet-form';
import { bool, object } from 'prop-types';

import { Loading } from 'features/common/components/Loading';
import { QUOTE_JOB_KEY } from 'features/quote-job/services/quote-job.util';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { EditQuoteSellSheetDrawer } from 'features/quote-sellsheet/components/modals/EditQuoteSellSheetDrawer';
import { EditQuoteTable } from 'features/quote/components/tables/EditQuoteTable/EditQuoteTable';
import { BatiprixImportDrawer } from 'features/batiprix/components/BatiprixImportDrawer';
import { useImportBatiprixJobInLot } from 'features/quote-lot/hooks/useImportBatiprixJobInLot';
import { isFieldNameOfJob, isFieldNameOfLot } from 'features/quote/forms/quote-edit-lot-step.form';
import { LibraryJobImportDrawer } from 'features/library-job/components/LibraryJobImportDrawer';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';
import { useQuoteInfos } from 'features/quote/hooks/useQuoteInfos';
import { EditQuoteLotDrawer } from 'features/quote-lot/components/modals/EditQuoteLotDrawer';
import { EditQuoteLotDefaultVatRateModal } from 'features/quote-lot/components/EditQuoteLotDefaultVatRateModal';
import { useEditLot } from 'features/quote-lot/hooks/useEditLot';
import { useImportLibraryJobInLot } from 'features/quote-lot/hooks/useImportLibraryJobInLot';
import { useEditJob } from 'features/quote-job/hooks/useEditJob';
import { useEditJobUnitPrice } from 'features/quote-job/hooks/useEditJobUnitPrice';
import { useEditJobMargin } from 'features/quote-job/hooks/useEditJobMargin';

export const EditQuoteLotsStep = ({
  quoteLotDrawer,
  batiprixDrawer,
  libraryJobImportDrawer,
  quoteLotEditDefaultVatRateModal,
  isReadOnlyView,
}) => {
  const topRef = useRef(null);

  const { quoteId: stringQuoteId } = useParams();
  const quoteId = Number(stringQuoteId);

  const { t } = useTranslation(['quote']);
  const drawersStackQuote = useDrawersStack();
  const drawerSellSheet = useDrawer('drawer-sellsheet', drawersStackQuote);
  const drawerSellSheetDiscount = useDrawer('drawer-sellsheet-discount', drawersStackQuote);
  const { setQuoteData, getQuoteAsFormValues, setQuoteForm, startAnotherUpdate, getJob } = useQuoteEditContext();
  const { replace } = useHistory();

  const toast = useToast();

  const editLot = useEditLot();
  const editJob = useEditJob();
  const editJobUnitPrice = useEditJobUnitPrice({ updateComponentContext: false });
  const editJobMargin = useEditJobMargin({ updateComponentContext: false });
  const importJobsFromBatiprix = useImportBatiprixJobInLot();
  const importLibraryJobInLot = useImportLibraryJobInLot();

  const {
    lotIdToEditDefaultVatRate,
    setIsReadOnlyView,
    getTargetLotIdForBatiprixImport,
    getTargetLotIdForLibraryJobImport,
  } = useQuoteDisplayContext();
  setIsReadOnlyView(isReadOnlyView);

  // -- Setup onBlur saving
  const handleSaveLot = useCallback(
    async (name, value, data) => {
      const { lotId, key } = data;
      if (!key) return;

      startAnotherUpdate();
      await editLot(lotId, { [key]: value });
    },
    [editLot, startAnotherUpdate],
  );

  const handleSaveJob = useCallback(
    async (name, rawValue, data, { setFormValues }) => {
      const { key, jobId, mapValue, mapFieldValue } = data;
      if (!key) return;

      startAnotherUpdate();

      const value = mapValue ? mapValue(rawValue) : rawValue;

      const callApi = async () => {
        switch (key) {
          case QUOTE_JOB_KEY.UNIT_PRICE_EX_VAT:
            return editJobUnitPrice(jobId, value);
          case QUOTE_JOB_KEY.TOTAL_MARGIN:
            return editJobMargin(jobId, value);
          case QUOTE_JOB_KEY.QUANTITY: {
            const { value: quantity, content: quantityFormula } = value;
            return editJob(jobId, {
              quantity,
              quantityFormula,
            });
          }
          default:
            return editJob(jobId, {
              [key]: value,
            });
        }
      };

      const hasError = await callApi();

      if (hasError) {
        const previousValue =
          key === QUOTE_JOB_KEY.TOTAL_MARGIN ? getJob(jobId).margin.totalMargin : getJob(jobId)[key];
        setFormValues({
          [name]: mapFieldValue ? mapFieldValue(previousValue) : previousValue,
        });
      }
    },
    [editJob, editJobMargin, editJobUnitPrice, getJob, startAnotherUpdate],
  );

  const onUpdateAfterBlur = useCallback(
    async (name, value, data, partialForm) => {
      if (isFieldNameOfLot(name)) {
        await handleSaveLot(name, value, data);
      }
      if (isFieldNameOfJob(name)) {
        await handleSaveJob(name, value, data, partialForm);
      }
    },
    [handleSaveLot, handleSaveJob],
  );

  const formOptions = useMemo(() => ({ onUpdateAfterBlur }), [onUpdateAfterBlur]);
  const stepForm = useStepForm(formOptions);
  const { form, initFormValues } = stepForm;

  // -- Load quote infos
  const [error, data, loading] = useQuoteInfos(quoteId);

  // -- Display loading when items and lots are rendering
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const loadingTableCtx = useCounterLoader(setIsFullyLoaded);
  const { updateRowsCount } = loadingTableCtx;

  const scrollTop = useCallback(() => {
    topRef.current?.scrollIntoView();
  }, [topRef]);

  useEffect(() => {
    if (data) {
      setQuoteData(data);
      // Minus root lot
      const numberOfLots = Object.keys(data.lots).length - 1;
      const numberOfJobs = Object.keys(data.jobs).length;
      updateRowsCount(numberOfLots + numberOfJobs);
      initFormValues(getQuoteAsFormValues());
    } else if (!loading) updateRowsCount(0);

    setQuoteForm(form);
  }, [data, form, getQuoteAsFormValues, initFormValues, loading, setQuoteData, setQuoteForm, updateRowsCount]);

  /**
   * Handle error if quote infos could not be loaded
   */
  useEffect(() => {
    if (error) {
      toast.error(t('quote:errors.fetchingInfosError', { email: SUPPORT_EMAIL }));
      replace('/opportunities/quotes');
    }
  }, [error, toast, replace, t]);

  const onBatiprixJobImport = useCallback(
    async (batiprixInfos) => importJobsFromBatiprix(batiprixInfos, getTargetLotIdForBatiprixImport()),
    [getTargetLotIdForBatiprixImport, importJobsFromBatiprix],
  );

  const onLibraryJobImport = useCallback(
    async (selectedLibraryJobs) => importLibraryJobInLot(selectedLibraryJobs, getTargetLotIdForLibraryJobImport()),
    [getTargetLotIdForLibraryJobImport, importLibraryJobInLot],
  );

  return (
    <div ref={topRef}>
      <Form form={form} style={{ height: '100%' }}>
        <CounterLoaderProvider value={loadingTableCtx}>
          <DrawersStack drawersStack={quoteLotDrawer.drawersStack}>
            <EditQuoteLotDrawer drawerLot={quoteLotDrawer.drawer} />

            <LibraryJobImportDrawer
              drawersStack={libraryJobImportDrawer.drawersStack}
              drawer={libraryJobImportDrawer.drawer}
              onSubmit={onLibraryJobImport}
              title={t('quote:drawers.jobImportDrawer.title')}
              description={t('quote:drawers.jobImportDrawer.description')}
            />
          </DrawersStack>

          <EditQuoteLotDefaultVatRateModal
            title={t('quote:defaultVatRateModal.lot.title')}
            description={t('quote:defaultVatRateModal.lot.description')}
            fieldDescription={t('quote:defaultVatRateModal.lot.fieldDescription')}
            modal={quoteLotEditDefaultVatRateModal}
            quoteLotId={lotIdToEditDefaultVatRate}
          />
          <BatiprixImportDrawer
            drawer={batiprixDrawer.drawer}
            drawersStack={batiprixDrawer.drawersStack}
            onSubmit={onBatiprixJobImport}
            quoteId={quoteId}
          />

          <Box display={isFullyLoaded ? 'block' : 'none'}>
            <DrawersStack drawersStack={drawersStackQuote}>
              <EditQuoteSellSheetDrawer
                drawerSellSheet={drawerSellSheet}
                drawerSellSheetDiscount={drawerSellSheetDiscount}
                drawersStackQuote={drawersStackQuote}
              />
            </DrawersStack>

            <EditQuoteTable drawerSellSheet={drawerSellSheet} scrollTop={scrollTop} />
          </Box>

          {!isFullyLoaded && (
            <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
              <Loading />
            </Box>
          )}
        </CounterLoaderProvider>
      </Form>
    </div>
  );
};

EditQuoteLotsStep.propTypes = {
  quoteLotDrawer: object.isRequired,
  batiprixDrawer: object.isRequired,
  libraryJobImportDrawer: object.isRequired,
  quoteLotEditDefaultVatRateModal: object.isRequired,
  isReadOnlyView: bool,
};

EditQuoteLotsStep.defaultProps = {
  isReadOnlyView: false,
};
