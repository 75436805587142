import { HStack, Text } from '@chakra-ui/react';
import { MaskEnum, QuotationMaskedInput, useCurrency, useInputMaskInfos } from '@graneet/lib-ui';
import type { QuoteComponentsGroupByType } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { memo, useCallback } from 'react';
import Big from 'big.js';

import { TooltipHiddenCostComponentsType } from 'features/margin/components/TooltipHiddenCostComponentsType';

export const AmountCell = memo((params: CustomCellRendererProps<QuoteComponentsGroupByType>) => {
  const { placeholder, mask } = useInputMaskInfos(MaskEnum.CURRENCY, {});
  const { mapStringNumberToAmount, formatAsStringAmount } = useCurrency();

  const { value, node } = params;

  const emptyCb = useCallback(() => {}, []);

  if (node.footer) {
    return null;
  }

  if (node.data?.hiddenCostQuantity) {
    return (
      <HStack justifyContent="flex-end" py="7px" pr="7px">
        <HStack
          borderRadius="6px"
          border="1px solid"
          borderColor="greenBrand.borderDefault"
          background="#F2F2F0"
          fontSize="12px"
          width="auto"
          height="20px"
          px={1}
        >
          <TooltipHiddenCostComponentsType
            hiddenCost={Big(node.data?.hiddenCostFlatCost ?? 0).toNumber()}
            hiddenCostQuantity={Big(node.data?.hiddenCostQuantity).toNumber()}
            quantity={Big(node.data?.quantity ?? 0)
              .plus(node.data?.hiddenCostQuantity)
              .toNumber()}
            unit={node.data?.units.length === 1 ? node.data?.units[0] : null}
            disbursementExVAT={Big(node.data?.flatCost ?? 0)
              .plus(Big(node.data?.hiddenCostFlatCost ?? 0))
              .toNumber()}
          />

          <Text>
            <i className="ri-box-2-line" />
          </Text>

          <Text>{formatAsStringAmount(value)}</Text>
        </HStack>
      </HStack>
    );
  }

  return (
    <HStack height="100%" width="100%" justifyContent="end" background="transparent" p={2} alignItems="flex-start">
      <QuotationMaskedInput
        isReadOnly
        color="#1F2A37"
        textAlign="right"
        value={mapStringNumberToAmount(value!)}
        onChange={emptyCb}
        onBlur={emptyCb}
        onKeyDownCapture={emptyCb}
        mask={mask}
        placeholder={placeholder}
        noStyle
        w={`${value.length + 2}ch`}
      />
    </HStack>
  );
});
