import type { FieldValues } from 'graneet-form';
import { useCallback, useState } from 'react';

import type { TextFieldProps, TextFieldValue } from '../TextField';
import { TextField } from '../TextField';
import type { KeysMatching } from '../../../utils';
import { SimpleViewIcon } from '../../Icons';

export interface PasswordFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, TextFieldValue> = KeysMatching<T, TextFieldValue>,
> extends Omit<TextFieldProps<T, K>, 'rightIcon' | 'variant'> {}

export const PasswordField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, TextFieldValue> = KeysMatching<T, TextFieldValue>,
>({
  inputProps,
  ...otherProps
}: PasswordFieldProps<T, K>) => {
  const [seePassword, setSeePassword] = useState(false);

  const onRightIconClick = useCallback(() => {
    setSeePassword((v) => !v);
  }, []);

  return (
    <TextField<T, K>
      textAlign="left"
      rightIcon={
        <SimpleViewIcon
          onClick={onRightIconClick}
          stroke={seePassword ? 'greenBrand.light' : 'gray.300'}
          cursor="pointer"
        />
      }
      inputProps={{ type: seePassword ? 'text' : 'password', ...inputProps }}
      {...otherProps}
    />
  );
};
