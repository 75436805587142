import type { FC } from 'react';
import { useEffect } from 'react';
import { Form, useStepForm } from 'graneet-form';
import { FieldGroupLabel, Fieldset, Tooltip, SimpleHelpIcon, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Flex, Grid, Stack, Box } from '@chakra-ui/react';
import type { IUser } from '@graneet/business-logic';

import type { UserFormValues, UserWizardValues } from 'features/user/constants/user.constant';
import { IsUserEmailAvailable } from 'features/user/forms/IsUserEmailAvailable';
import { UserRoleFieldSet } from 'features/user/components/UserRoleFieldSet';
import { Rule } from 'features/form/rules/Rule';

interface EditUserStepProps {
  user: IUser | null;
}

export const EditUserStep: FC<EditUserStepProps> = ({ user }) => {
  const { t } = useTranslation(['user']);

  const { form, initFormValues } = useStepForm<UserWizardValues, 'edit-user'>();
  useEffect(() => {
    if (user) {
      initFormValues({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        role: user.role?.id,
      });
    }
  }, [initFormValues, user]);

  return (
    <Form form={form}>
      <Stack direction="column" spacing={6}>
        <Flex maxWidth="70rem">
          <Fieldset<UserFormValues>
            handle="user"
            title={t('user:userInformation.title')}
            legend={t('user:userInformation.legend')}
            validationNames={['firstName', 'lastName', 'email']}
          >
            <FieldGroupLabel>{t('user:userInformation.cardLabel')}</FieldGroupLabel>

            <Grid templateColumns="3fr 2fr 3fr 3fr" gap={6} mb={4} pt={4}>
              <TextField<UserFormValues> name="lastName" label={t('user:fields.lastName')} isRequired>
                <Rule.IsRequired />
              </TextField>

              <TextField<UserFormValues> name="firstName" label={t('user:fields.firstName')} isRequired>
                <Rule.IsRequired />
              </TextField>

              <TextField<UserFormValues> name="email" isRequired label={t('user:fields.email')}>
                <Rule.IsEmail />
                <Rule.IsRequired />
                <IsUserEmailAvailable userId={user?.id} />
              </TextField>
              <TextField<UserFormValues>
                name="phone"
                label={t('user:fields.phone')}
                rightIcon={
                  <Tooltip label={t('user:tooltip.phone')} placement="right-end">
                    <Box alignItems="center" display="flex">
                      <SimpleHelpIcon />
                    </Box>
                  </Tooltip>
                }
              />
            </Grid>
          </Fieldset>
        </Flex>

        <UserRoleFieldSet user={user} />
      </Stack>
    </Form>
  );
};
