import type { FC } from 'react';
import { useCallback } from 'react';
import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';

interface IsFileSizeValidProps {
  maxFileSize?: number;
}

export const IsFileSizeValid: FC<IsFileSizeValidProps> = ({ maxFileSize = 10 }) => {
  const validationFn = useCallback(
    (file: FieldValue): boolean => {
      const maxFileSizeInBytes = maxFileSize * 1024 * 1024;

      return file ? file.size < maxFileSizeInBytes : true;
    },
    [maxFileSize],
  );

  return <Rule message="" validationFn={validationFn} />;
};
