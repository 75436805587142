import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StepperModal } from '@graneet/lib-ui';
import type { IQuote, ISubProjectWithInformation } from '@graneet/business-logic';
import { SUB_PROJECT_BILLING_TYPE } from '@graneet/business-logic';
import { Form, HiddenField, useHiddenField, useStepForm } from 'graneet-form';

import type { QuoteAcceptModalWizard } from './types';

import { SubProjectSelectionTable } from 'features/sub-project/components/SubProjectSelectionTable';

type SelectSubProjectStepProps = {
  subProjects: ISubProjectWithInformation[];

  quote: IQuote;

  isSubmitting: boolean;
};

export const SelectSubProjectStep: FC<SelectSubProjectStepProps> = ({ subProjects, quote, isSubmitting }) => {
  const { t } = useTranslation(['subProject', 'global', 'quote']);

  const { form } = useStepForm<QuoteAcceptModalWizard, 'subProject'>();
  const hiddenField = useHiddenField(form, 'subProjectId');
  const { setValue: updateSubProjectId } = hiddenField;

  const onSubProjectSelect = useCallback(
    (newSubProject: ISubProjectWithInformation | null) => {
      updateSubProjectId(newSubProject?.id || null);
    },
    [updateSubProjectId],
  );

  const getRowState = useCallback(
    (subProject: ISubProjectWithInformation) => {
      let tooltipMessage: string | undefined;
      if (
        subProject.hasReversalOfLiability !== null &&
        subProject.hasReversalOfLiability !== quote.hasReversalOfLiability
      ) {
        tooltipMessage = t('subProject:toasts.cannotAddQuoteBecauseReversalOfLiability');
      }
      if (
        subProject.billingType === SUB_PROJECT_BILLING_TYPE.DIRECT &&
        subProject.hasValidatedAtLeastOneProgressStatement
      ) {
        tooltipMessage = t('subProject:toasts.cannotAddQuoteBecauseDirectBilling');
      }

      return { isDisabled: !!tooltipMessage, tooltipMessage };
    },
    [quote.hasReversalOfLiability, t],
  );

  return (
    <Form form={form} style={{ width: '100%' }}>
      <HiddenField {...hiddenField} />

      <SubProjectSelectionTable
        subProjects={subProjects}
        onSubProjectSelect={onSubProjectSelect}
        getRowState={getRowState}
        displayNewSubProjectOption
      />

      <StepperModal.SecondaryButton isDisabled={isSubmitting}>{t('global:words.c.back')}</StepperModal.SecondaryButton>
      <StepperModal.PrimaryButton isLoading={isSubmitting}>
        {t(`quote:acceptQuoteProjectModal.chooseBillingEntity`)}
      </StepperModal.PrimaryButton>
    </Form>
  );
};
