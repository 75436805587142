import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { IProjectStatsSummaryDTO } from '@graneet/business-logic';
import { Card, StackedBarChart, CustomTooltipChart } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import type { TooltipProps } from 'recharts';
import { Text } from '@chakra-ui/react';

interface ProjectBilledVersusSpentStatsCardProps {
  stats: IProjectStatsSummaryDTO;
}

enum TOOLTIP_NAME {
  SPENT,
  BILLED,
}

export const ProjectBilledVersusSpentStatsCard: FC<ProjectBilledVersusSpentStatsCardProps> = ({ stats }) => {
  const { t } = useTranslation(['project']);

  const {
    supplierInvoiceAmountExVATSum,
    projectAmountToBeBilledExVAT,
    approvedTotalPriceExVATSum,
    projectTotalBilledAmountExVAT,
    downPaymentAmountExVAT,
  } = stats;

  const legends = useMemo(
    () => ({
      projectTotalBilledAmountExVAT: t('project:projectBilledVersusSpentStatsCard.saleInvoices'),
      projectAmountToBeBilledExVAT: t('project:projectBilledVersusSpentStatsCard.restToBill'),
      supplierInvoicePlusTimeSlot: t('project:projectBilledVersusSpentStatsCard.supplierInvoiceVersusTimeSlot'),
      downPaymentAmountExVAT: t('project:projectBilledVersusSpentStatsCard.downPayment'),
      timeSlotTotalPriceExVATSum: t('project:projectBilledVersusSpentStatsCard.workforceDone'),
      supplierInvoiceAmountExVATSum: t('project:projectBilledVersusSpentStatsCard.supplierInvoices'),
    }),
    [t],
  );

  const CustomTooltip = useCallback(
    ({ payload, active }: TooltipProps<number, string>) => {
      /**
       * Spent bar tooltip
       */
      if (payload?.[0]?.payload?.name === TOOLTIP_NAME.SPENT) {
        const payloadWithDetail = [
          ...payload,
          {
            name: 'timeSlotTotalPriceExVATSum',
            value: approvedTotalPriceExVATSum,
            textColor: 'gray.500',
          },
          {
            name: 'supplierInvoiceAmountExVATSum',
            value: supplierInvoiceAmountExVATSum,
            textColor: 'gray.500',
          },
        ];
        return <CustomTooltipChart legends={legends} payload={payloadWithDetail} active={active} />;
      }

      /**
       * Billed bar tooltip
       */
      const payloadWithDownPayment =
        downPaymentAmountExVAT !== null
          ? [
              ...(payload || []),
              {
                name: 'downPaymentAmountExVAT',
                value: downPaymentAmountExVAT,
              },
            ]
          : payload;

      return <CustomTooltipChart legends={legends} payload={payloadWithDownPayment} active={active} />;
    },
    [supplierInvoiceAmountExVATSum, approvedTotalPriceExVATSum, downPaymentAmountExVAT, legends],
  );

  const cardProps = useMemo(
    () => ({
      title: (
        <Trans t={t} i18nKey="project:projectBilledVersusSpentStatsCard.title">
          description_start
          <Text as="span" color="gray.500">
            displayed_data
          </Text>
          description_end
        </Trans>
      ),
      minH: '24rem',
    }),
    [t],
  );

  const supplierInvoicePlusTimeSlot = supplierInvoiceAmountExVATSum + approvedTotalPriceExVATSum;

  const data = [
    {
      name: TOOLTIP_NAME.BILLED,
      projectTotalBilledAmountExVAT,
      projectAmountToBeBilledExVAT,
    },
    {
      name: TOOLTIP_NAME.SPENT,
      supplierInvoicePlusTimeSlot,
    },
  ];

  return (
    <Card {...cardProps}>
      <StackedBarChart
        data={data}
        columns={[
          {
            name: 'projectTotalBilledAmountExVAT',
            color: 'green.300',
          },
          {
            name: 'projectAmountToBeBilledExVAT',
            color: 'green.100',
          },
          {
            name: 'supplierInvoicePlusTimeSlot',
            color: 'red.200',
          },
        ]}
        xAxis={{
          dataKey: 'name',
          typeFormat: 'string',
          hide: true,
        }}
        yAxis={{
          typeFormat: 'money',
        }}
        legends={legends}
        tooltip={CustomTooltip}
      />
    </Card>
  );
};
