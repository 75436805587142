import type { FC } from 'react';
import type { TooltipProps } from '@chakra-ui/react';
import { Box, Tooltip as ChakraTooltip, HStack } from '@chakra-ui/react';

export const Tooltip: FC<TooltipProps & { shortcut?: string }> = ({ shortcut, label, ...props }) => (
  <ChakraTooltip
    bg="primaryLight"
    color="white"
    borderRadius="0.625rem"
    boxShadow="0px 0px 13.54px 0px rgba(0, 0, 0, 0.20)"
    width="100%"
    maxWidth="22rem"
    label={
      label && (
        <HStack textColor="white" color="white" fontSize="0.75rem" fontWeight={450} gap={2}>
          <Box>{label}</Box>
          {shortcut && (
            <Box
              as="span"
              fontStyle="normal"
              p="4px"
              px={0}
              fontSize="0.688rem"
              color="#9DA4AE"
              ml="auto"
              fontWeight={500}
            >
              {shortcut}
            </Box>
          )}
        </HStack>
      )
    }
    px="1rem"
    py="0.625rem"
    {...props}
  />
);
