import { GRANEET_COLORS } from '../common/constants/colors.constant';
import { divideFloating, multiplyFloating, roundFloating } from '../common/math.util';
import { isNumberFinite } from '../common/number.util';
import type { RequiredByKeys } from '../common/types/object.type';
import type { ICompanyMarginsResponseDTO } from '../company/dtos';
import { MARGIN_COMPUTED_VALUE } from '../margin/margin.type';
import type { IPlannedAmount } from '../project/dtos/planned-amounts.dto';
import type { IQuoteComponent } from '../quote-component/quote-component.type';
import type { IQuoteJob } from '../quote-job/quote-job-type';

import type { IComponentType } from './component-type.type';
import type { IComponentTypeFormatted } from './dtos/component-types-formatted.dto';

type ComponentType = {
  componentType: Omit<IComponentType, 'company'>;
};
type ComponentTypeGrouped = Omit<IComponentTypeFormatted, 'componentType'> &
  ComponentType & {
    hiddenCost: number;
    hasHiddenCost: boolean;
    hiddenCostQuantity: number;
  };

export const isComponentTypeUndefined = (componentType: IComponentTypeFormatted): boolean =>
  componentType.id === 0 || componentType.quantity === 0 || componentType.disbursementExVAT === 0;

/**
 * Generate a fake component type id based on existing components types for display-only purpose.
 * This ID equals the highest component type ID + 1 and doesn't exist in the database.
 */
const generateIndexForUndefinedComponentType = (currentCompanyComponentsTypes: ICompanyMarginsResponseDTO): number => {
  if (!currentCompanyComponentsTypes.componentTypesMargins) return -1;

  const { componentTypesMargins } = currentCompanyComponentsTypes;

  const lastComponentTypeId = componentTypesMargins.reduce<number>((lastId, componentTypeMargin) => {
    const { componentType } = componentTypeMargin;
    return lastId < componentType.id ? componentType.id : lastId;
  }, 0);

  return lastComponentTypeId + 1;
};

export const groupExistingComponentsByType = (
  components: IQuoteComponent[],
  companyComponentTypes: ICompanyMarginsResponseDTO,
  jobs?: RequiredByKeys<IQuoteJob, 'components'>[],
): ComponentTypeGrouped[] => {
  if (!jobs) {
    return [];
  }

  const allJobsAreOptional = jobs?.every((job) => job.isOptional);

  let jobsForComponentsToGroup = jobs;

  if (!allJobsAreOptional) {
    jobsForComponentsToGroup = jobsForComponentsToGroup.filter((job) => !job.isOptional);
  }

  let componentsToGroup = components;

  componentsToGroup = jobsForComponentsToGroup.reduce<IQuoteComponent[]>((acc, job) => {
    const { quantity, components: jobComponents, isHiddenCost } = job;

    const newComponents = jobComponents.reduce<IQuoteComponent[]>((prevComponents, component) => {
      const quoteComponent = components.find(
        (componentUpdated: IQuoteComponent) => componentUpdated.id === component.id,
      );
      if (!quoteComponent) {
        throw new Error(`Quote component not found for component ${component.id}`);
      }

      const componentDisbursementExVAT = component.disbursementExVAT || 0;
      const componentQuantity = component.quantity || 0;
      const { margin: newComponentMargin } = quoteComponent;
      const { totalMargin: componentTotalMargin } = newComponentMargin;

      const jobQuantity = quantity || 0;

      const newQuantity = multiplyFloating(componentQuantity, jobQuantity);
      const newDisbursementExVAT = multiplyFloating(jobQuantity, componentDisbursementExVAT);
      const newAmountExVAT = multiplyFloating(newDisbursementExVAT, componentTotalMargin);

      return [
        ...prevComponents,
        {
          ...component,
          amountExVAT: newAmountExVAT,
          disbursementExVAT: newDisbursementExVAT,
          quantity: newQuantity,
          margin: newComponentMargin,
          isHiddenCost,
        },
      ];
    }, []);

    return [...acc, ...newComponents];
  }, []);

  /**
   * Add all existing components of the same type
   */
  const sumByComponentType = componentsToGroup
    .reduce<ComponentTypeGrouped[]>((acc, component) => {
      if (!component) return acc;

      const { disbursementExVAT, amountExVAT, quantity, componentType, margin, isHiddenCost } = component;

      const lastComponentTypeIndex = generateIndexForUndefinedComponentType(companyComponentTypes);

      // Create row for component with an undefined component type
      if (!component.componentType && !acc[lastComponentTypeIndex]) {
        acc[lastComponentTypeIndex] = {
          id: 0,
          disbursementExVAT,
          amountExVAT: isHiddenCost ? 0 : amountExVAT || 0,
          quantity: 0,
          overheadCosts: 1,
          profitMargin: 1,
          totalMargin: 1,
          computed: margin ? margin.computed : MARGIN_COMPUTED_VALUE.PROFIT,
          hiddenCost: isHiddenCost ? disbursementExVAT : 0,
          hasHiddenCost: !!isHiddenCost,
          hiddenCostQuantity: isHiddenCost ? quantity || 0 : 0,
          componentType: {
            id: lastComponentTypeIndex,
            name: 'undefined',
            color: GRANEET_COLORS.WARM_GRAY,
            unit: null,
            isWorkforce: false,
            createdAt: new Date(),
            updatedAt: new Date(),
            batiprixType: null,
            accountPurchases: null,
          },
        };
        return acc;
      }

      // Sum row for component with an undefined component type
      if (!component.componentType) {
        acc[lastComponentTypeIndex].disbursementExVAT += disbursementExVAT;
        acc[lastComponentTypeIndex].amountExVAT += amountExVAT || 0;
        acc[lastComponentTypeIndex].quantity += quantity || 0;
        acc[lastComponentTypeIndex].hiddenCost += isHiddenCost ? disbursementExVAT : 0;
        acc[lastComponentTypeIndex].hasHiddenCost = acc[lastComponentTypeIndex].hasHiddenCost || isHiddenCost || false;
        acc[lastComponentTypeIndex].hiddenCostQuantity += isHiddenCost ? quantity || 0 : 0;

        return acc;
      }

      if (!acc[component.componentType.id] && componentType) {
        acc[componentType.id] = {
          id: component.id,
          disbursementExVAT,
          amountExVAT: isHiddenCost ? 0 : amountExVAT || 0,
          quantity: quantity || 0,
          overheadCosts: margin ? margin.overheadCosts : 1,
          profitMargin: margin ? margin.profitMargin : 1,
          totalMargin: margin ? margin.totalMargin : 1,
          computed: margin ? margin.computed : MARGIN_COMPUTED_VALUE.PROFIT,
          hiddenCost: isHiddenCost ? disbursementExVAT : 0,
          hasHiddenCost: !!isHiddenCost,
          hiddenCostQuantity: isHiddenCost ? quantity || 0 : 0,
          componentType: {
            id: componentType.id,
            name: componentType.name,
            color: componentType.color,
            unit: componentType.unit,
            isWorkforce: componentType.isWorkforce,
            createdAt: componentType.createdAt,
            updatedAt: componentType.updatedAt,
            batiprixType: componentType.batiprixType,
            accountPurchases: componentType.accountPurchases,
          },
        };
        return acc;
      }

      acc[component.componentType.id].disbursementExVAT += disbursementExVAT;
      acc[component.componentType.id].amountExVAT += isHiddenCost ? 0 : amountExVAT || 0;
      acc[component.componentType.id].quantity += quantity || 0;
      acc[component.componentType.id].hiddenCost += isHiddenCost ? disbursementExVAT : 0;
      acc[component.componentType.id].hasHiddenCost =
        acc[component.componentType.id].hasHiddenCost || isHiddenCost || false;
      acc[component.componentType.id].hiddenCostQuantity += isHiddenCost ? quantity || 0 : 0;

      return acc;
    }, [])
    .filter(Boolean);

  return sumByComponentType.map((componentType) => {
    const { hiddenCost, amountExVAT, disbursementExVAT, overheadCosts } = componentType;
    const newTotalMargin = divideFloating(amountExVAT, disbursementExVAT - hiddenCost);
    const newProfitMargin = divideFloating(newTotalMargin, overheadCosts);
    return {
      ...componentType,
      profitMargin: newProfitMargin,
      totalMargin: newTotalMargin,
    };
  });
};

export const getAmountsGroupedByTypes = (jobs: IQuoteJob[]): IPlannedAmount => {
  const amounts = jobs.reduce<IPlannedAmount>((acc, job) => {
    const { quantity, components: jobComponents } = job;
    const jobQuantity = quantity || 0;

    const newComponents = (jobComponents || []).reduce<IPlannedAmount>((newComps, component) => {
      const typeId = component?.componentType?.id;

      if (!typeId) {
        return newComps;
      }
      const newDisbursementExVAT = multiplyFloating(jobQuantity, component.disbursementExVAT ?? 0);
      const newNbMinutes = component?.componentType?.isWorkforce
        ? multiplyFloating(multiplyFloating(jobQuantity, component.quantity ?? 0), 60)
        : null;

      if (!newComps[typeId]) {
        // eslint-disable-next-line no-param-reassign
        newComps[typeId] = {
          amountExVAT: 0,
          nbMinutes: null,
        };
      }

      // eslint-disable-next-line no-param-reassign
      newComps[typeId] = {
        amountExVAT: newComps[typeId].amountExVAT + newDisbursementExVAT,
        nbMinutes:
          newComps[typeId].nbMinutes !== null || newNbMinutes !== null
            ? (newComps[typeId].nbMinutes ?? 0) + (newNbMinutes ?? 0)
            : null,
      };

      return newComps;
    }, {});

    Object.entries(newComponents).forEach(([typeId, amount]) => {
      if (!acc[typeId]) {
        acc[typeId] = {
          amountExVAT: 0,
          nbMinutes: null,
        };
      }

      acc[typeId] = {
        amountExVAT: acc[typeId].amountExVAT + amount.amountExVAT,
        nbMinutes:
          acc[typeId].nbMinutes !== null || amount.nbMinutes !== null
            ? (acc[typeId].nbMinutes ?? 0) + (amount.nbMinutes ?? 0)
            : null,
      };
    });
    return acc;
  }, {});

  Object.entries(amounts).forEach(([typeId, amount]) => {
    // fix https://sentry.io/organizations/graneet/issues/3098435253/?project=5514287&referrer=slack
    // we round each type value to avoid float values in bigint col (if floating quantity for example)
    amounts[typeId].amountExVAT = roundFloating(amount.amountExVAT);
    amounts[typeId].nbMinutes = isNumberFinite(amount.nbMinutes) ? roundFloating(amount.nbMinutes) : null;
  });

  return amounts;
};

/**
 * Verify if job has a component with workForce type and calculate its value otherwise return undefined
 */
export const getJobWorkForceAmount = (
  components: IQuoteComponent[] | undefined,
  jobQuantity: number | null,
): number | undefined => {
  if (!isNumberFinite(jobQuantity)) return undefined;

  let hasWorkForce = false;
  const workForceAmount = (components || []).reduce((acc, newComponent) => {
    const { componentType, quantity } = newComponent;
    if (componentType?.isWorkforce && isNumberFinite(quantity)) {
      if (!hasWorkForce) hasWorkForce = true;
      return quantity + acc;
    }
    return acc;
  }, 0);

  if (hasWorkForce) return multiplyFloating(workForceAmount, jobQuantity);

  return undefined;
};
