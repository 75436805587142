import type { FC, ReactElement } from 'react';
import { useCallback } from 'react';
import {
  Box,
  Center,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

import type { FormGroupProps } from '../FormGroup/FormGroup';
import { FormGroup } from '../FormGroup/FormGroup';
import { SimpleCheckIcon } from '../Icons';

type Color = { value: string; color: string };
type ColorPickerVariant = 'default' | 'compact';

export interface ColorPickerProps extends Omit<FormGroupProps, 'onChange'> {
  value: string | undefined | null;

  onChange(value: string | undefined | null): void;

  colors: Color[];

  label?: string;

  helpIcon?: ReactElement;

  variant?: ColorPickerVariant;
}

type ColorPickerItemProps = Omit<ColorPickerProps, 'colors' | 'label' | 'helpIcon' | 'variant'> & { color: Color };

const ColorPickersItem: FC<ColorPickerItemProps> = ({ value, onChange, color }) => {
  const handleChange = useCallback(() => {
    onChange(color.value);
  }, [onChange, color]);

  const isCurrentValueSelected = color.value === value;

  return (
    <Box
      bg={color.color}
      boxSize="1.5rem"
      borderRadius="50%"
      onClick={handleChange}
      boxShadow={
        isCurrentValueSelected
          ? '0px 27px 30px -15px rgba(255, 255, 255, 0.13), 0px 0px 3px -1px rgba(255, 255, 255, 0.14), 0px 0px 0px 1px rgba(255, 255, 255, 0.15)'
          : '0px 27px 30px -15px rgba(0, 0, 0, 0.13), 0px 0px 3px -1px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
      }
      border="1px solid rgba(0, 0, 0, 0.2)"
    >
      {isCurrentValueSelected && (
        <Center boxSize="100%">
          <SimpleCheckIcon stroke={color.color === 'white' ? 'gray.500' : 'white'} />
        </Center>
      )}
    </Box>
  );
};

type ColorItemsProps = Omit<ColorPickerProps, 'variant'>;

const ColorItems: FC<ColorItemsProps> = ({ value, onChange, colors, label, helpIcon, ...otherProps }) => (
  <FormGroup label={label} leftContent={helpIcon} {...otherProps} w="100%" my="1.5" h="auto">
    <Wrap spacing={4}>
      {colors.map((currentColor) => (
        <WrapItem key={currentColor.value}>
          <ColorPickersItem color={currentColor} value={value} onChange={onChange} />
        </WrapItem>
      ))}
    </Wrap>
  </FormGroup>
);

type ColorPopoverProps = Omit<ColorPickerProps, 'variant'>;

const ColorPopover: FC<ColorPopoverProps> = ({ alignSelf, ...props }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { value, colors, onChange } = props;
  const selectedColor = colors.find((color) => value === color.value)?.color;

  const handleChange = useCallback(
    (newValue: string | undefined | null) => {
      onChange(newValue);
      onClose();
    },
    [onChange, onClose],
  );

  return (
    <Popover onClose={onClose} onOpen={onOpen} isOpen={isOpen} placement="bottom">
      <PopoverTrigger>
        <Box
          bg={selectedColor ?? 'gray.500'}
          boxSize={props.boxSize ?? '1.5rem'}
          borderRadius="50%"
          alignSelf={alignSelf}
          mt={props.mt}
        />
      </PopoverTrigger>

      <PopoverContent maxW="30rem" w="auto">
        <PopoverBody>
          <ColorItems {...props} onChange={handleChange} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export const ColorPicker: FC<ColorPickerProps> = ({ variant = 'default', ...otherProps }) => {
  if (variant === 'compact') {
    return <ColorPopover {...otherProps} />;
  }

  return <ColorItems {...otherProps} />;
};
