import type { FC } from 'react';
import { useMemo, useCallback } from 'react';
import {
  Section,
  useCurrency,
  TwoColumns,
  useDrawersStack,
  useDrawer,
  DrawersStack,
  Button,
  SimpleSettingsIcon,
  SimpleAddIcon,
  sleep,
} from '@graneet/lib-ui';
import { Form, HiddenField, useHiddenField, useStepForm, useWizardContext } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type {
  IDownPayment,
  IProgressStatementSummaryInputDTO,
  ISubProject,
  IPenaltyUpsertDTO,
  IProgressStatement,
  IProject,
} from '@graneet/business-logic';
import { Flex } from '@chakra-ui/react';

import type { PenaltyFormValues } from 'features/penalty/components/PenaltyDrawer';
import { DescriptionLabel, PenalyDrawer } from 'features/penalty/components/PenaltyDrawer';
import { useSendClickAddPenaltyButtonEvent } from 'features/analytic/hooks/useSendClickAddPenaltyButtonEvent';
import type { ProgressStatementEditWizard } from 'features/progress-statement/forms/progress-statement-edit-wizard';
import { getProgressStatementsLinesAndContractDiscountDTO } from 'features/progress-statement/services/progress-statement.util';
import { useProgressStatementSummary } from 'features/progress-statement/services/progress-statement.api';
import { ProgressSummaryCard } from 'features/progress-statement/components/cards/ProgressStatementSummaryCard';
import { ProgressStatementBillingCard } from 'features/progress-statement/components/cards/ProgressStatementBillingCard/ProgressStatementBillingCard';

interface EditProgressStatementSummaryStepProps {
  currentProgressStatement: IProgressStatement | null;
  downPayment?: IDownPayment;
  subProject: ISubProject;
  project: IProject;
}

export const EditProgressStatementSummaryStep: FC<EditProgressStatementSummaryStepProps> = ({
  currentProgressStatement,
  downPayment,
  subProject,
  project,
}) => {
  const { t } = useTranslation(['progressStatement', 'penalty']);

  const { mapAmountToNumber } = useCurrency();

  const params = useParams<{ progressStatementId: string }>();
  const { progressStatementId: progressStatementIdRaw } = params;
  const progressStatementId = Number(progressStatementIdRaw);

  const { getValuesOfSteps } = useWizardContext<ProgressStatementEditWizard>();
  const { form } = useStepForm<ProgressStatementEditWizard, 'validation'>({
    defaultValues: (() => {
      const { penalties: penaltiesFormValues } = currentProgressStatement || {};

      const penaltiesData: IPenaltyUpsertDTO[] = (penaltiesFormValues || []).map((penalty) => ({
        id: penalty.id,
        name: penalty.name,
        amountIncVAT: penalty.amountIncVAT,
      }));

      return { penalties: penaltiesData };
    })(),
  });
  const penaltiesHiddenField = useHiddenField(form, 'penalties');

  const summaryDTO = useMemo(() => {
    const {
      items: itemsValues,
      downPayment: downPaymentValues,
      priceRevision: priceRevisionValues,
      directPayments: directPaymentValues,
    } = getValuesOfSteps();

    const {
      progressStatementsLinesDTO: lines,
      contractDiscountLinesDTO: discountLines,
      contractCustomDiscountLinesDTO: customDiscountLines,
    } = getProgressStatementsLinesAndContractDiscountDTO(itemsValues, mapAmountToNumber);
    const { cumulativeInputType } = itemsValues;
    const ordersSupplierInvoices = directPaymentValues ? directPaymentValues.ordersSupplierInvoices : [];

    const dto: IProgressStatementSummaryInputDTO = {
      subProjectId: subProject.id,
      lines,
      discountLines,
      customDiscountLines,
      cumulativeInputType,
      downPaymentAmortizationAmount:
        (downPayment && downPaymentValues && mapAmountToNumber(downPaymentValues.downPaymentAmortizationAmount)) || 0,
      priceRevisionExVAT:
        (subProject.hasPriceRevision && priceRevisionValues && mapAmountToNumber(priceRevisionValues.priceRevision)) ||
        0,
      ordersSupplierInvoices,
      penalties: penaltiesHiddenField.value ?? [],
    };
    if (Number.isFinite(progressStatementId)) {
      dto.progressStatementId = progressStatementId;
    }

    return dto;
  }, [
    downPayment,
    getValuesOfSteps,
    mapAmountToNumber,
    penaltiesHiddenField.value,
    progressStatementId,
    subProject.hasPriceRevision,
    subProject.id,
  ]);
  const progressStatementSummary = useProgressStatementSummary(summaryDTO);

  const drawersStack = useDrawersStack();
  const drawerPenalty = useDrawer('drawer-penalty', drawersStack);

  const handleOnSubmit = useCallback(
    async (penaltyFormValues: PenaltyFormValues) => {
      const newPenalties: IPenaltyUpsertDTO[] = Object.entries(penaltyFormValues)
        .filter(([, value]) => value.amountIncVAT !== undefined && value.denomination !== undefined)
        .map(([key, value]) => ({
          id: key,
          name: value.denomination || '',
          amountIncVAT: value.amountIncVAT || 0,
        }));

      drawerPenalty.onClose();
      await sleep(500); // Chakra is bugged, suspense is not working well when popper is closing 🤷‍
      form.setFormValues({
        penalties: newPenalties,
      });
    },
    [drawerPenalty, form],
  );

  const sendClickAddPenaltyEvent = useSendClickAddPenaltyButtonEvent();
  const handleClickOnPenaltyButton = useCallback(() => {
    if (penaltiesHiddenField.value?.length === 0) {
      sendClickAddPenaltyEvent('progress-statement');
    }
    drawerPenalty.onOpen();
  }, [drawerPenalty, penaltiesHiddenField.value?.length, sendClickAddPenaltyEvent]);

  return (
    <Form form={form}>
      <Section
        title={t('progressStatement:isCreating.validationStep.progressStatementVerification')}
        description={t('progressStatement:isCreating.validationStep.verifyInformation')}
      >
        <HiddenField {...penaltiesHiddenField} />
        <TwoColumns mb={5}>
          <ProgressSummaryCard summary={progressStatementSummary.data} />
          <ProgressStatementBillingCard
            summary={progressStatementSummary.data}
            subProject={subProject}
            project={project}
            downPayment={downPayment}
          />
        </TwoColumns>
        <Flex justify="flex-end">
          <Button
            variant="outline"
            leftIcon={penaltiesHiddenField.value?.length ? <SimpleSettingsIcon /> : <SimpleAddIcon />}
            onClick={handleClickOnPenaltyButton}
          >
            {penaltiesHiddenField.value?.length ? t('penalty:drawer.handlePenalty') : t('penalty:drawer.addPenalty')}
          </Button>
        </Flex>

        <DrawersStack drawersStack={drawersStack}>
          <PenalyDrawer
            drawer={drawerPenalty}
            onSubmit={handleOnSubmit}
            existingPenalties={penaltiesHiddenField.value || []}
            descriptionLabel={DescriptionLabel.PROGRESS_STATEMENT}
          />
        </DrawersStack>
      </Section>
    </Form>
  );
};
