import type { IMargin } from '../margin/margin.type';
import { divideFloating, multiplyFloating, roundFloating, sumObjects } from '../common/math.util';
import { filterVATBases, mergeVATDistribution } from '../vat/vat-rates.util';
import { MARGIN_COMPUTED_VALUE } from '../margin/margin.type';
import type { IQuoteComponent } from '../quote-component/quote-component.type';
import { isNumberFinite } from '../common/number.util';
import type { IVatBases } from '../vat/vat-bases.type';
import type { RequiredByKeys } from '../common/types/object.type';
import { getCalculatedTotalMargin } from '../margin/margin.util';
import type { Raw } from '../common/entity.type';

import type { IQuoteJob, IQuoteJobDelta } from './quote-job-type';

// -- Margin related --
/**
 * Compute total margin from quote job: ROUND(amountExVAT / disbursementExVAT)
 */
export const computeQuoteJobTotalMargin = (quoteJob: IQuoteJob): number => {
  const unitHiddenCostAmountExVAT =
    isNumberFinite(quoteJob.quantity) && quoteJob.quantity !== 0
      ? divideFloating(quoteJob.hiddenCostAmountExVAT, quoteJob.quantity)
      : 0;
  return (quoteJob.unitDisbursementExVAT || 0) + unitHiddenCostAmountExVAT === 0
    ? 1
    : divideFloating(quoteJob.unitPriceExVAT || 0, (quoteJob.unitDisbursementExVAT || 0) + unitHiddenCostAmountExVAT);
};

/**
 * Return margin corresponding to amount include in quote job
 */
export const computeQuoteJobMarginFromAmounts = (quoteJob: RequiredByKeys<IQuoteJob, 'margin'>): IMargin => {
  const totalMargin = computeQuoteJobTotalMargin(quoteJob);
  const profitMargin = divideFloating(totalMargin, quoteJob.margin.overheadCosts);
  return {
    ...quoteJob.margin,
    profitMargin,
    totalMargin,
    computed: MARGIN_COMPUTED_VALUE.PROFIT,
  };
};

// -- Totals --

/**
 * Compute unit disbursement exc. VAT from unitPriceExVAT and margin
 */
export const computeQuoteJobUnitDisbursementExVAT = (unitPriceExVAT: number, margin: IMargin): number =>
  divideFloating(unitPriceExVAT, getCalculatedTotalMargin(margin));

export const computeQuoteJobUnitAmountExVAT = (
  disbursementExVAT: number | undefined | null,
  margin: IMargin,
): number | null =>
  isNumberFinite(disbursementExVAT)
    ? roundFloating(multiplyFloating(disbursementExVAT, getCalculatedTotalMargin(margin)))
    : null;

/**
 * Compute unit price exc. VAT from unitDisbursementExVAT and the margin
 */
export const computeQuoteJobUnitPriceExVAT = (
  unitDisbursementExVAT: number | undefined | null,
  margin: Raw<IMargin>,
): number | null =>
  isNumberFinite(unitDisbursementExVAT)
    ? roundFloating(multiplyFloating(unitDisbursementExVAT, getCalculatedTotalMargin(margin)))
    : null;

/**
 * Compute DisbursementExVAT from quote job: ROUND(unitDisbursementExVAT * quantity)
 */
export const computeQuoteJobDisbursementExVAT = (
  unitDisbursementExVAT: number | undefined | null,
  quantity: number | undefined | null,
): number =>
  isNumberFinite(quantity) && isNumberFinite(unitDisbursementExVAT)
    ? roundFloating(multiplyFloating(unitDisbursementExVAT, quantity))
    : 0;

/**
 * Compute amountExVAT from quote job: ROUND(unitPriceExVAT * quantity)
 */
export const computeQuoteJobAmountExVAT = (
  unitPriceExVAT: number | undefined | null,
  quantity: number | undefined | null,
): number =>
  isNumberFinite(unitPriceExVAT) && isNumberFinite(quantity)
    ? roundFloating(multiplyFloating(unitPriceExVAT, quantity))
    : 0;

// -- Utils --
/**
 * Get amount with amount shared proportionally
 */
export const addAmountToAnotherAmountProportionally = (
  initialAmount: number,
  targetAmount: number,
  amountToSplit: number,
): number =>
  roundFloating(initialAmount + multiplyFloating(amountToSplit, divideFloating(initialAmount, targetAmount)));

/**
 * * Get LotDelta from the old quote job and the updated quote job
 */
export const getQuoteJobLotDelta = (updatedQuoteJob?: IQuoteJob, oldQuoteJob?: IQuoteJob): IQuoteJobDelta => {
  /*
    On quote job removing updatedQuoteJob will be undefined (because the new quote job is ... nothing)
    On quote job duplicating oldQuoteJob will be undefined
    So we have to handle cases where updatedQuoteJob and oldQuoteJob can be undefined
   */
  const oldDisbursementExVAT = oldQuoteJob?.disbursementExVAT || 0;
  const newDisbursementExVAT = updatedQuoteJob?.disbursementExVAT || 0;
  const oldAmountExVAT = oldQuoteJob?.amountExVAT || 0;
  const newAmountExVAT = updatedQuoteJob?.amountExVAT || 0;
  const oldAmountIncVAT = oldQuoteJob?.amountIncVAT || 0;
  const newAmountIncVAT = updatedQuoteJob?.amountIncVAT || 0;
  const oldVatRate = oldQuoteJob?.vatRate || 0;
  const newVatRate = updatedQuoteJob?.vatRate || 0;
  const oldHiddenCostAmountExVAT = oldQuoteJob?.hiddenCostAmountExVAT || 0;
  const newHiddenCostAmountExVAT = updatedQuoteJob?.hiddenCostAmountExVAT || 0;

  const updatedQuoteJobIsOptional = updatedQuoteJob?.isOptional || false;
  const oldQuoteJobIsOptional = oldQuoteJob?.isOptional || false;
  const updatedQuoteJobIsHiddenCost = updatedQuoteJob?.isHiddenCost || false;
  const oldQuoteJobIsHiddenCost = oldQuoteJob?.isHiddenCost || false;

  // Checks errors
  if (!oldQuoteJob && !updatedQuoteJob) {
    throw new Error('Cannot compute delta on quote job without old and updated quote job');
  }

  if (
    (updatedQuoteJobIsOptional && updatedQuoteJobIsHiddenCost) ||
    (oldQuoteJobIsOptional && oldQuoteJobIsHiddenCost)
  ) {
    throw new Error('Cannot have a quote job that is optional and hidden cost at the same time');
  }

  if (
    (oldHiddenCostAmountExVAT !== 0 && (oldQuoteJobIsOptional || oldQuoteJobIsHiddenCost)) ||
    (newHiddenCostAmountExVAT !== 0 && (updatedQuoteJobIsOptional || updatedQuoteJobIsHiddenCost))
  ) {
    throw new Error('Cannot have a non-zero hidden cost amount if hidden cost or optionnal');
  }

  // Compute delta
  const addNewAmounts = !updatedQuoteJobIsOptional && !updatedQuoteJobIsHiddenCost;
  const removeOldAmounts = !oldQuoteJobIsOptional && !oldQuoteJobIsHiddenCost;
  const addNewOptionnalAmounts = updatedQuoteJobIsOptional;
  const removeOldOptionnalAmounts = oldQuoteJobIsOptional;

  let deltaAmountExVAT = 0;
  let deltaDisbursementExVAT = 0;
  let deltaOptionalAmountExVAT = 0;
  let deltaOptionalDisbursementExVAT = 0;
  const deltaHiddenCostAmountExVAT = newHiddenCostAmountExVAT - oldHiddenCostAmountExVAT;
  const newVatDistribution: IVatBases = [];

  if (addNewAmounts) {
    deltaAmountExVAT += newAmountExVAT;
    deltaDisbursementExVAT += newDisbursementExVAT;

    newVatDistribution.push({
      amount: newAmountIncVAT - newAmountExVAT,
      base: newAmountExVAT,
      vatRate: newVatRate,
    });
  }

  if (removeOldAmounts) {
    deltaAmountExVAT -= oldAmountExVAT;
    deltaDisbursementExVAT -= oldDisbursementExVAT;

    newVatDistribution.push({
      amount: oldAmountExVAT - oldAmountIncVAT,
      base: -oldAmountExVAT,
      vatRate: oldVatRate,
    });
  }

  if (addNewOptionnalAmounts) {
    deltaOptionalAmountExVAT += newAmountExVAT;
    deltaOptionalDisbursementExVAT += newDisbursementExVAT;
  }

  if (removeOldOptionnalAmounts) {
    deltaOptionalAmountExVAT -= oldAmountExVAT;
    deltaOptionalDisbursementExVAT -= oldDisbursementExVAT;
  }

  return {
    disbursementExVAT: deltaDisbursementExVAT,
    hiddenCostAmountExVAT: deltaHiddenCostAmountExVAT,
    optionalDisbursementExVAT: deltaOptionalDisbursementExVAT,
    amountExVAT: deltaAmountExVAT,
    optionalAmountExVAT: deltaOptionalAmountExVAT,
    vatDistribution: mergeVATDistribution(filterVATBases(newVatDistribution)),
  };
};

/**
 * Get local delta with an array of jobs
 * This is useful when deleting or duplicating a lot
 */
export const computeDeltaFromQuoteJobs = (jobs: Array<IQuoteJob> = [], isDeletion = false): IQuoteJobDelta => {
  const rawDelta: IQuoteJobDelta = {
    disbursementExVAT: 0,
    hiddenCostAmountExVAT: 0,
    optionalAmountExVAT: 0,
    amountExVAT: 0,
    optionalDisbursementExVAT: 0,
    vatDistribution: [],
  };

  return jobs.reduce((accDelta, job: IQuoteJob) => {
    // If it is deletion, the updated job is undefined
    // Otherwise when it's duplicating, the old job is undefined
    const jobDelta = getQuoteJobLotDelta(isDeletion ? undefined : job, isDeletion ? job : undefined);

    return {
      disbursementExVAT: accDelta.disbursementExVAT + jobDelta.disbursementExVAT,
      hiddenCostAmountExVAT: accDelta.hiddenCostAmountExVAT + jobDelta.hiddenCostAmountExVAT,
      optionalDisbursementExVAT: accDelta.optionalDisbursementExVAT + jobDelta.optionalDisbursementExVAT,
      amountExVAT: accDelta.amountExVAT + jobDelta.amountExVAT,
      optionalAmountExVAT: accDelta.optionalAmountExVAT + jobDelta.optionalAmountExVAT,
      vatDistribution: mergeVATDistribution([...accDelta.vatDistribution, ...jobDelta.vatDistribution]),
    };
  }, rawDelta);
};

export const isQuoteJobCompleteForAmountsComputation = (quoteJob: IQuoteJob): boolean => {
  const { quantity, unitDisbursementExVAT, unitPriceExVAT } = quoteJob;

  return Number.isFinite(quantity) && Number.isFinite(unitDisbursementExVAT) && Number.isFinite(unitPriceExVAT);
};

export const isQuoteJobComplete = (quoteJob: IQuoteJob): boolean => {
  const { description, unit } = quoteJob;

  return (
    isQuoteJobCompleteForAmountsComputation(quoteJob) &&
    !!description &&
    description.length > 0 &&
    !!unit &&
    unit.length > 0
  );
};

export const getUpdatedQuoteJobFromComponents = <
  T extends Pick<RequiredByKeys<IQuoteJob, 'margin'>, 'quantity' | 'vatRate' | 'margin' | 'hiddenCostAmountExVAT'>,
>(
  quoteJobToUpdate: T,
  quoteComponents: Pick<IQuoteComponent, 'disbursementExVAT' | 'amountExVAT'>[],
): T &
  Pick<
    IQuoteJob,
    'unitDisbursementExVAT' | 'unitPriceExVAT' | 'disbursementExVAT' | 'amountExVAT' | 'amountIncVAT' | 'margin'
  > => {
  const unitDisbursementExVAT = sumObjects(quoteComponents, 'disbursementExVAT');
  const unitPriceExVATFromComponent = sumObjects(quoteComponents, 'amountExVAT');

  // Margin
  const totalMargin = unitDisbursementExVAT ? divideFloating(unitPriceExVATFromComponent, unitDisbursementExVAT) : 1;
  const profitMargin = divideFloating(totalMargin, quoteJobToUpdate.margin.overheadCosts);

  // When there is no longer components in the job, we keep the old margin
  const margin: IMargin =
    quoteComponents.length > 0
      ? {
          ...quoteJobToUpdate.margin,
          totalMargin,
          profitMargin,
          computed: MARGIN_COMPUTED_VALUE.PROFIT,
        }
      : quoteJobToUpdate.margin;

  const unitHiddenCostAmountExVAT =
    isNumberFinite(quoteJobToUpdate.quantity) && quoteJobToUpdate.quantity !== 0
      ? divideFloating(quoteJobToUpdate.hiddenCostAmountExVAT ?? 0, quoteJobToUpdate.quantity)
      : 0;

  const unitPriceExVAT =
    quoteJobToUpdate.hiddenCostAmountExVAT === 0
      ? unitPriceExVATFromComponent
      : computeQuoteJobUnitPriceExVAT(unitDisbursementExVAT + unitHiddenCostAmountExVAT, margin);

  // Amounts
  const disbursementExVAT = isNumberFinite(quoteJobToUpdate.quantity)
    ? roundFloating(multiplyFloating(unitDisbursementExVAT, quoteJobToUpdate.quantity))
    : 0;
  const amountExVAT = isNumberFinite(quoteJobToUpdate.quantity)
    ? roundFloating(multiplyFloating(unitPriceExVAT ?? 0, quoteJobToUpdate.quantity))
    : 0;
  const amountIncVAT = amountExVAT + roundFloating(multiplyFloating(amountExVAT, quoteJobToUpdate.vatRate));

  return {
    ...quoteJobToUpdate,
    unitDisbursementExVAT,
    unitPriceExVAT,
    disbursementExVAT,
    amountExVAT,
    amountIncVAT,
    margin,
    components: quoteComponents,
  };
};
