export interface FileListTranslations {
  selectFiles: string;
  tooBig: string;
}
export const fileListTranslations: FileListTranslations = {
  selectFiles: 'Select files',
  tooBig: 'File too big',
};

export const configureDefaultLabels = (translation: FileListTranslations) => {
  Object.assign(fileListTranslations, translation);
};
