import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleSelectDropDownIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" fill="#6C737F" {...props}>
    <path
      d="M4.19331 10.4667L7.52665 13.8C7.78665 14.06 8.20665 14.06 8.46665 13.8L11.8 10.4667C12.06 10.2 12.06 9.78003 11.8 9.52003C11.5333 9.25336 11.1133 9.25336 10.8533 9.52003L7.51998 12.8534H8.45998L5.12665 9.52003C4.85998 9.25336 4.43998 9.25336 4.17998 9.52003C3.91331 9.78003 3.91331 10.2 4.17998 10.46L4.19331 10.4667ZM5.13331 6.46669L8.46665 3.13336H7.51998L10.8533 6.46669C11.1133 6.72669 11.5333 6.72669 11.7933 6.46669C12.0533 6.20003 12.0533 5.78003 11.7933 5.52003L8.45998 2.18669C8.19331 1.92003 7.77331 1.92003 7.51331 2.18669L4.17998 5.52003C3.91331 5.78003 3.91331 6.20003 4.17998 6.46003C4.43998 6.72003 4.85998 6.72003 5.11998 6.46003L5.13331 6.46669Z"
      fill="currentColor"
    />
  </Icon>
);
