import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { useLayoutEffect } from 'react';

import { DisplayNotFoundScreen } from '../../DisplayNotFoundScreen';

import { ViewDownPaymentDetailScreen } from './down-payments/ViewDownPaymentDetailScreen';
import { ViewInvoiceDetailScreen } from './invoices/ViewInvoiceDetailScreen';
import { ViewCreditDetailScreen } from './credits/ViewCreditDetailScreen';
import { ViewProgressStatementDetailScreen } from './progress-statements/ViewProgressStatementDetailScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const StatementDetailRouter = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation(['statement', 'global']);
  const { updateHeaderTitle } = useHeaderContext();

  useLayoutEffect(() => {
    updateHeaderTitle(t('statement:title'), []);
  }, [t, updateHeaderTitle]);

  return (
    <>
      <Box p={8}>
        <Switch>
          <Route path={`${path}/invoices/:invoiceId`}>
            <QueryWrapper>
              <ViewInvoiceDetailScreen />
            </QueryWrapper>
          </Route>

          <Route path={`${path}/progress-statements/:progressStatementId`}>
            <QueryWrapper>
              <ViewProgressStatementDetailScreen />
            </QueryWrapper>
          </Route>

          <Route path={`${path}/down-payments/:downPaymentId`}>
            <QueryWrapper>
              <ViewDownPaymentDetailScreen />
            </QueryWrapper>
          </Route>

          <Route path={`${path}/credits/:creditId`}>
            <QueryWrapper>
              <ViewCreditDetailScreen />
            </QueryWrapper>
          </Route>

          <Route path="*">
            <DisplayNotFoundScreen />
          </Route>
        </Switch>
      </Box>
    </>
  );
};
