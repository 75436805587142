import type { FC } from 'react';
import { Bar, Legend, Rectangle } from 'recharts';
import type { StyleProps } from '@chakra-ui/react';

import { useChakraColors } from '../../../hooks';
import type { CustomBarChartProps } from '../common/components/CustomBarChart/CustomBarChart';
import { CustomBarChart } from '../common/components/CustomBarChart/CustomBarChart';
import { CustomLegendGrid } from '../common';

const Y_AXIS_WIDTH = 50;

// TODO: Type it better
// There is currently no better way to type this with Recharts
// We cannot pass shape (props from Bar) type to RectangleBar
type CustomBarProps = {
  [key: string]: any;
  payload?: any;
  index?: number;
};

const CustomBar: FC<CustomBarProps> = ({ index, payload, ...rest }) => {
  const keysWithoutName = Object.keys(payload).filter((key) => key !== 'name');

  const total = keysWithoutName.reduce((accumulator, current) => accumulator + rest[current], 0);
  const {
    value: [, secondValue],
    width,
    height,
    x,
    y,
    fill,
  } = rest;

  return (
    <Rectangle
      // only top left & top right radius only on top bar
      // if total === second, we are on top bar not bottom
      radius={total === secondValue ? [4, 4, 0, 0] : 0}
      width={width}
      x={x}
      y={y}
      height={height < 0 ? 0 : height} // fix for prevent negative height
      fill={fill}
    />
  );
};

export interface StackedBarChartProps<T extends object> extends Omit<CustomBarChartProps<T>, 'barStyle'> {
  columns: Array<{
    name: Extract<keyof T, string>;
    color: StyleProps['color'];
  }>;
}

export const StackedBarChart = <T extends object>({
  data,
  legends,
  tooltip,
  xAxis,
  yAxis,
  columns,
}: StackedBarChartProps<T>) => {
  const colorsToUse = useChakraColors(
    columns.reduce<Record<string, StyleProps['color']>>((acc, column) => {
      acc[column.name] = column.color;
      return acc;
    }, {}),
  );

  return (
    <CustomBarChart<T>
      data={data}
      xAxis={xAxis}
      yAxis={yAxis}
      barStyle={{
        size: 150,
        gap: 160,
        categoryGap: 164,
      }}
      legends={legends}
      tooltip={tooltip}
    >
      {data.map((obj, index) => {
        const keys = Object.keys(obj);

        return columns
          .filter((column) => keys.includes(column.name))
          .map((column) => (
            <Bar
              key={column.name}
              dataKey={column.name}
              stackId={index}
              fill={colorsToUse[column.name]}
              shape={<CustomBar />}
              isAnimationActive={false}
            />
          ));
      })}

      <Legend
        content={<CustomLegendGrid legends={legends} />}
        wrapperStyle={{
          // To center the element according to the cratesian grid, removing the Y axis width
          marginLeft: Y_AXIS_WIDTH,
          width: `calc(100% - ${Y_AXIS_WIDTH * 2}px`,
        }}
      />
    </CustomBarChart>
  );
};
