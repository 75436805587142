import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Address,
  formatDataOrEmpty,
  type PaginatedResponse,
  PaginatedTable,
  type PaginatedTableProps,
  type PaginationQuery,
} from '@graneet/lib-ui';
import { useHistory } from 'react-router-dom';
import type { ISupplier } from '@graneet/business-logic';
import { Box } from '@chakra-ui/react';

import { SupplierTypeBadge } from './SupplierTypeBadge';

import { TagsBadges } from 'features/common/components/TagsBadges';

interface SuppliersTableProps {
  suppliers: PaginationQuery<PaginatedResponse<ISupplier>>;
}

const NameCell = ({ data }: { data?: ISupplier }) =>
  data ? (
    <>
      {data.name}
      {data.tags.length > 0 && (
        <Box mb={2}>
          <TagsBadges tags={data?.tags} />
        </Box>
      )}
    </>
  ) : null;

const BillingAddressCell = ({ data }: { data?: ISupplier }) =>
  data ? <Address address={data.hasBillingAddress ? data.billingAddress! : data.address!} oneLine /> : null;

const TypeCell = ({ data }: { data?: ISupplier }) => (data ? <SupplierTypeBadge type={data.type} /> : null);

export const SuppliersTable: FC<SuppliersTableProps> = ({ suppliers }) => {
  const { t } = useTranslation(['supplier']);
  const history = useHistory();

  const columnDefs = useMemo<PaginatedTableProps<PaginatedResponse<ISupplier>>['columnDefs']>(
    () => [
      {
        field: 'code',
        headerName: t('supplier:fields.code'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.code),
        sortable: true,
      },
      {
        field: 'name',
        autoHeight: true,
        headerName: t('supplier:fields.name'),
        cellRenderer: NameCell,
        width: 500,
        sortable: true,
      },
      {
        field: 'billingAddress',
        headerName: t('supplier:fields.billingAddress'),
        cellRenderer: BillingAddressCell,
        width: 500,
        sortable: true,
      },
      {
        field: 'type',
        headerName: t('supplier:fields.type'),
        cellRenderer: TypeCell,
        sortable: true,
      },
    ],
    [t],
  );

  return (
    <PaginatedTable
      gridId="supplier"
      pagination={suppliers}
      zeroState={{
        icon: <i className="ri-truck-line ri-4x" />,
        label: t('supplier:noSupplier'),
      }}
      emptyState={{
        label: t('supplier:noSupplier'),
      }}
      columnDefs={columnDefs}
      onRowClicked={(event) => {
        history.push(`/contacts/suppliers/${event.data?.id}`);
      }}
    />
  );
};
