import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, SliderMark, Text, VStack } from '@chakra-ui/react';
import { HiddenField, useFormContext, useOnChangeValues } from 'graneet-form';
import { PDF_LABELS_POSITION } from '@graneet/business-logic';
import { SimpleHelpIcon, Tooltip, SegmentedInputGroupField, SliderField, SwitchField } from '@graneet/lib-ui';

import type { PDFSettingsFormValues } from '../constants/pdf-settings.constant';
import { PDF_SETTINGS_FIELDS } from '../constants/pdf-settings.constant';

export const PdfLabelsSettingsWithSize: FC = () => {
  const { t } = useTranslation(['pdfSettings']);

  const form = useFormContext<PDFSettingsFormValues>();
  const {
    [PDF_SETTINGS_FIELDS.LABELS_POSITION]: labelsPositionLive,
    [PDF_SETTINGS_FIELDS.LABELS_DISPLAYED]: isLabelsDisplayed,
  } = useOnChangeValues(form, [
    PDF_SETTINGS_FIELDS.COVER_PAGE,
    PDF_SETTINGS_FIELDS.LABELS_POSITION,
    PDF_SETTINGS_FIELDS.LABELS_DISPLAYED,
  ]);

  const { [PDF_LABELS_POSITION.NONE]: none, ...labelsWithoutNone } = PDF_LABELS_POSITION;

  useEffect(() => {
    if (isLabelsDisplayed === true && labelsPositionLive === PDF_LABELS_POSITION.NONE) {
      form.setFormValues({
        [PDF_SETTINGS_FIELDS.LABELS_POSITION]: PDF_LABELS_POSITION.HEADER,
      });
    }

    if (isLabelsDisplayed === false) {
      form.setFormValues({
        [PDF_SETTINGS_FIELDS.LABELS_POSITION]: PDF_LABELS_POSITION.NONE,
      });
    }
  }, [form, labelsPositionLive, isLabelsDisplayed]);

  return (
    <VStack spacing={2.5} align="start">
      <HStack spacing={2} pb={1.5}>
        <SwitchField<PDFSettingsFormValues>
          name={PDF_SETTINGS_FIELDS.LABELS_DISPLAYED}
          checkedValue
          uncheckedValue={false}
          label={
            <HStack spacing={2}>
              <Text color="black" fontSize="sm">
                {t('pdfSettings:settings.labelsPosition.title')}
              </Text>
              <Tooltip label={t('pdfSettings:settings.labelsPosition.tooltip')} placement="right-end">
                <Box>
                  <SimpleHelpIcon boxSize={5} />
                </Box>
              </Tooltip>
            </HStack>
          }
        />
      </HStack>

      {isLabelsDisplayed ? (
        <>
          <SegmentedInputGroupField<PDFSettingsFormValues>
            name={PDF_SETTINGS_FIELDS.LABELS_POSITION}
            options={Object.values(labelsWithoutNone).map((labelPositionKey) => ({
              optionLabel: t(`pdfSettings:settings.labelsPosition.options.${labelPositionKey}`),
              optionValue: labelPositionKey,
            }))}
          />

          <Box width="100%" m={0} pt={1.5} pb={3.5}>
            <SliderField<PDFSettingsFormValues> name={PDF_SETTINGS_FIELDS.LABELS_SIZE} min={30}>
              <SliderMark value={30} fontSize="sm" mt={2.5}>
                {t('pdfSettings:settings.logoSize.small') as string}
              </SliderMark>
              <SliderMark value={100} fontSize="sm" mt={2.5} ml={-10}>
                {t('pdfSettings:settings.logoSize.big') as string}
              </SliderMark>
            </SliderField>
          </Box>
        </>
      ) : (
        <>
          <HiddenField name={PDF_SETTINGS_FIELDS.LABELS_POSITION} />
          <HiddenField name={PDF_SETTINGS_FIELDS.LABELS_SIZE} />
        </>
      )}
    </VStack>
  );
};
