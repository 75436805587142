import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { ILibraryJobResponseDTO, PaginatedResponse } from '@graneet/business-logic';
import { isNumberFinite, SUPPORT_EMAIL } from '@graneet/business-logic';
import type { PaginatedTableProps, PaginationQuery } from '@graneet/lib-ui';
import { PriceAdvanced, SimpleLibraryIcon, PaginatedTable, formatDataOrEmpty, RichText } from '@graneet/lib-ui';
import { Box } from '@chakra-ui/react';
import type { RowClickedEvent, RowSelectedEvent } from '@ag-grid-community/core';

const DescriptionCell = ({ data }: { data: ILibraryJobResponseDTO | undefined }) =>
  data ? (
    <Box mt={2}>
      <RichText value={data.description} />
    </Box>
  ) : null;

const HasComponentsCell = ({ data }: { data: ILibraryJobResponseDTO | undefined }) =>
  data?.hasComponents ? (
    <Box color="pink.200">
      <i className="ri-puzzle-fill ri-xl" />
    </Box>
  ) : (
    ''
  );

const UnitDisbursementExVATCell = ({ data }: { data: ILibraryJobResponseDTO | undefined }) =>
  isNumberFinite(data?.unitDisbursementExVAT) ? <PriceAdvanced amount={data.unitDisbursementExVAT} /> : '';

interface LibraryJobTableProps {
  gridId: string;

  libraryJobs: PaginationQuery<PaginatedResponse<ILibraryJobResponseDTO>>;

  onRowClicked?: (id: number) => void;

  renderActionsMenu?: (libraryJob: ILibraryJobResponseDTO) => ReactNode;

  displayComponentIndicator?: boolean;

  isResizable?: boolean;

  onRowSelected?: (libraryJobs: ILibraryJobResponseDTO[]) => void;
}

export const LibraryJobTable: FC<LibraryJobTableProps> = ({
  gridId,
  libraryJobs,
  onRowClicked,
  renderActionsMenu,
  displayComponentIndicator = false,
  isResizable = false,
  onRowSelected,
}) => {
  const { t } = useTranslation(['library']);

  const columnDefs = useMemo<PaginatedTableProps<PaginatedResponse<ILibraryJobResponseDTO>>['columnDefs']>(
    () => [
      {
        field: 'refCode',
        sortable: true,
        headerName: t('library:libraryJob.fields.refCode'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.refCode),
      },
      {
        field: 'description',
        sortable: true,
        headerName: t('library:libraryJob.fields.description'),
        cellRenderer: DescriptionCell,
        flex: isResizable ? undefined : 1,
        autoHeight: true,
      },
      {
        field: 'unit',
        sortable: true,
        headerName: t('library:libraryJob.fields.unit'),
      },
      {
        field: 'unitDisbursementExVAT',
        sortable: true,
        headerName: t('library:libraryJob.fields.unitDisbursementExVAT'),
        cellRenderer: UnitDisbursementExVATCell,
      },
      {
        field: 'hasComponents',
        headerName: '',
        cellRenderer: HasComponentsCell,
        width: 20,
        suppressMovable: true,
        hide: !displayComponentIndicator,
        resizable: false,
      },
      {
        field: 'menu' as any,
        headerName: '',
        hide: !renderActionsMenu,
        cellRenderer: (v) => (v.data && renderActionsMenu ? renderActionsMenu(v.data) : null),
        width: 20,
        suppressMovable: true,
        resizable: false,
      },
    ],
    [displayComponentIndicator, isResizable, renderActionsMenu, t],
  );

  const handleRowClicked = useCallback(
    (event: RowClickedEvent<ILibraryJobResponseDTO>) => {
      if (!onRowClicked || !event.data || event.api.getFocusedCell()?.column.getId() === 'menu') {
        return;
      }
      onRowClicked(event.data.id);
    },
    [onRowClicked],
  );

  const onSelectionChanged = useCallback(
    (event: RowSelectedEvent<ILibraryJobResponseDTO>) => {
      const selectedRows = event.api.getSelectedRows();
      onRowSelected?.(selectedRows);
    },
    [onRowSelected],
  );

  return (
    <PaginatedTable
      gridId={gridId}
      onRowClicked={onRowClicked ? handleRowClicked : undefined}
      onRowSelected={onSelectionChanged}
      rowSelection={onRowSelected ? 'multiple' : undefined}
      defaultColDef={{
        resizable: isResizable,
        headerCheckboxSelection: false,
      }}
      pagination={libraryJobs}
      columnDefs={columnDefs}
      zeroState={{
        icon: <SimpleLibraryIcon boxSize={45} />,
        label: <Trans t={t} i18nKey="library:listing.emptyStateLibrary" values={{ email: SUPPORT_EMAIL }} />,
      }}
      emptyState={{
        label: t('library:errors.noResultLibraryJob'),
      }}
    />
  );
};
