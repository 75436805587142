import type { UseModalProps } from '@chakra-ui/react';
import { CircularProgress, Text, VStack } from '@chakra-ui/react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';

export const SpreadsheetImportingModal: FC<UseModalProps> = (props) => {
  const { t } = useTranslation(['global']);

  return (
    <Modal {...props} title={t('global:spreadsheetImport.importing.title')}>
      <VStack spacing={6}>
        <CircularProgress size={12} color="gray.800" isIndeterminate />
        <Text textAlign="center">{t('global:spreadsheetImport.importing.description')}</Text>
      </VStack>
    </Modal>
  );
};
