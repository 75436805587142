import { Modal, Multiplication, Header } from '@graneet/lib-ui';
import { useCallback, useEffect, useState } from 'react';
import { bool, func, object, array } from 'prop-types';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  getMarginComputedValueFromFieldName,
  BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD,
  BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD,
} from '../../services/margins.utils';
import { MarginEditionByComponentsTypesTable } from '../MarginEditionByComponentsTypesTable';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useUpdateCompanyMargins } from 'features/company/services/company.api';
import { Rule } from 'features/form/rules/Rule';
import { MARGIN_FIELD_NAME } from 'features/margin/services/margin.constants';

const CARD_TITLE_STYLE = {
  fontWeight: '600',
  fontSize: 'lg',
};

export const EditCompanyMarginModal = ({ isOpen, onClose, margin, marginComponentsTypes }) => {
  const { t } = useTranslation(['margin']);
  const marginForm = useForm();
  const componentsMarginForm = useForm();
  const { isValid: isFormValidMargin } = useFormStatus(marginForm);
  const { isValid: isFormValidComponentsMargin } = useFormStatus(componentsMarginForm);
  const [computed, setComputed] = useState(margin.computed);
  const [currentEditField, setCurrentEditField] = useState();
  const { setFormValues: setMarginFormValues, getFormValues: getMarginFormValues } = marginForm;
  const { setFormValues: setComponentsMarginFormValues, getFormValues: getComponentsMarginFormValues } =
    componentsMarginForm;

  useEffect(() => {
    setMarginFormValues(margin);
  }, [margin, setMarginFormValues]);

  useEffect(() => {
    marginComponentsTypes.map((component) =>
      setComponentsMarginFormValues({
        componentTypeId: component.componentType.id,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]:
          component.overheadCosts,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
          component.profitMargin,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(component.componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
          component.totalMargin,
        [BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(component.componentType.id)]: component.computed,
      }),
    );
  }, [marginComponentsTypes, setComponentsMarginFormValues]);

  const updateCompanyMarginsMutation = useUpdateCompanyMargins();

  const onClick = useCallback(async () => {
    const {
      [MARGIN_FIELD_NAME.OVERHEAD_COSTS]: overheadCosts,
      [MARGIN_FIELD_NAME.PROFIT_MARGIN]: profitMargin,
      [MARGIN_FIELD_NAME.TOTAL_MARGIN]: totalMargin,
    } = getMarginFormValues();

    const dtoWithComponents = {
      quoteMargin: {
        computed: getMarginComputedValueFromFieldName(computed),
        overheadCosts,
        profitMargin,
        totalMargin,
      },
      componentTypesMargins: [],
    };

    marginComponentsTypes.map(async (marginComponentType) => {
      const {
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(
          marginComponentType.componentType.id,
          MARGIN_FIELD_NAME.OVERHEAD_COSTS,
        )]: overheadCostsComponentType,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(marginComponentType.componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
          profitMarginComponentType,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(marginComponentType.componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
          totalMarginComponentType,
        [BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(marginComponentType.componentType.id)]: computedComponentType,
      } = getComponentsMarginFormValues();

      const componentTypesDto = {
        id: marginComponentType.id,
        componentTypeId: marginComponentType.componentType.id,
        overheadCosts: overheadCostsComponentType,
        profitMargin: profitMarginComponentType,
        totalMargin: totalMarginComponentType,
        computed: computedComponentType,
      };

      dtoWithComponents.componentTypesMargins.push(componentTypesDto);

      return componentTypesDto;
    });

    updateCompanyMarginsMutation.mutate(dtoWithComponents, {
      onSuccess: () => {
        onClose();
      },
    });
  }, [
    getMarginFormValues,
    computed,
    marginComponentsTypes,
    updateCompanyMarginsMutation,
    getComponentsMarginFormValues,
    onClose,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('margin:company.modal.title')} size="2xl" scrollBehavior="inside">
      <QueryWrapper>
        <Text>{t('margin:company.modal.description')}</Text>

        <Form form={marginForm}>
          <Box
            my={4}
            px={4}
            pb={3}
            backgroundColor="gray.50"
            sx={{
              borderRadius: 'var(--chakra-radii-md)',
            }}
          >
            <Header color="gray.800" px={0} backgroundColor="gray.50" sx={CARD_TITLE_STYLE}>
              {t('margin:company.modal.subtitle')}
            </Header>
            <Multiplication computedElement={computed} setComputedElement={setComputed}>
              <Multiplication.Field
                name={MARGIN_FIELD_NAME.OVERHEAD_COSTS}
                label={t('margin:fields.overheadCosts')}
                computedName={MARGIN_FIELD_NAME.TOTAL_MARGIN}
                min={0}
                scale={3}
              >
                <Rule.IsRequired />
                <Rule.IsNotZero />
              </Multiplication.Field>
              <Multiplication.Field
                name={MARGIN_FIELD_NAME.PROFIT_MARGIN}
                label={t('margin:fields.profitMargin')}
                computedName={MARGIN_FIELD_NAME.TOTAL_MARGIN}
                min={0}
                scale={3}
              >
                <Rule.IsRequired />
                <Rule.IsNotZero />
              </Multiplication.Field>
              <Multiplication.Field
                name={MARGIN_FIELD_NAME.TOTAL_MARGIN}
                label={t('margin:fields.totalMargin')}
                computedName={MARGIN_FIELD_NAME.PROFIT_MARGIN}
                min={0}
                scale={3}
              >
                <Rule.IsRequired />
                <Rule.IsNotZero />
              </Multiplication.Field>
            </Multiplication>
          </Box>
        </Form>

        <Box
          my={4}
          px={4}
          pb={3}
          backgroundColor="gray.50"
          sx={{
            borderRadius: 'var(--chakra-radii-md)',
          }}
        >
          <Header color="gray.800" px={0} backgroundColor="gray.50" sx={CARD_TITLE_STYLE}>
            {t('margin:componentTypes.title')}
          </Header>
          <Form form={componentsMarginForm}>
            <MarginEditionByComponentsTypesTable
              componentTypes={marginComponentsTypes}
              currentEditField={currentEditField}
              setCurrentEditField={setCurrentEditField}
              isMultiEditionAllowed
              isEditionAllowed
              bgColor="gray.50"
            />
          </Form>
        </Box>

        <Modal.Close isDisabled={updateCompanyMarginsMutation.isPending} />

        <Modal.PrimaryButton
          onClick={onClick}
          isDisabled={!isFormValidMargin || !isFormValidComponentsMargin}
          isLoading={updateCompanyMarginsMutation.isPending}
        >
          {t('margin:company.modal.cta')}
        </Modal.PrimaryButton>
      </QueryWrapper>
    </Modal>
  );
};

EditCompanyMarginModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  margin: object,
  marginComponentsTypes: array,
};

EditCompanyMarginModal.defaultProps = {
  margin: {},
  marginComponentsTypes: [],
};
