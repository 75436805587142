import { PERMISSION } from '@graneet/business-logic';

export interface RoleFormFields {
  name: string;
  viewAllQuotesAndProjects: boolean;
  accessDashboards: boolean;
  createQuotes: boolean;
  acceptQuotesAndCreateProjects: boolean;
  updateLibrary: boolean;
  createStatements: boolean;
  updateStatusStatements: boolean;
  forceDraftStatements: boolean;
  updateContracts: boolean;
  payBillsAndPayHoldbacks: boolean;
  updateCompanyInformation: boolean;
  updateQuoteParameters: boolean;
  createSupplierInvoice: boolean;
  paySupplierInvoice: boolean;
  updateSupplierInvoicePayment: boolean;
  createOrder: boolean;
  createBudget: boolean;
  accessTimeTracking: boolean;
  accessWorkerHourlyPrice: boolean;
  updateQuoteMarginDisbursementType: boolean;
  updateLedgerParameters: boolean;
  updateThirdParties: boolean;
  updateAccounting: boolean;
  generateAccounting: boolean;
  updateEmailTemplates: boolean;
  displayQuotes: boolean;
  accessOrders: boolean;
  displayAccounting: boolean;
  accessSupplierInvoices: boolean;
  accessStatements: boolean;
  emailReminders: boolean;
  accessSupplierInvoiceValidationWorkflow: boolean;
  accessBalanceBanking: boolean;
  accessTransactionBanking: boolean;
  accessPaymentResourceBanking: boolean;
}

export type RoleFieldName = Exclude<keyof RoleFormFields, 'name'>;

export const CLIENT_TO_SERVER_USER_PERMISSIONS: Record<RoleFieldName, PERMISSION[]> = {
  viewAllQuotesAndProjects: [PERMISSION.VIEW_ALL_QUOTES, PERMISSION.VIEW_ALL_PROJECTS],
  accessDashboards: [PERMISSION.ACCESS_DASHBOARDS],
  createQuotes: [PERMISSION.CREATE_QUOTES],
  acceptQuotesAndCreateProjects: [PERMISSION.ACCEPT_QUOTES, PERMISSION.CREATE_PROJECTS],
  updateLibrary: [PERMISSION.UPDATE_LIBRARY],
  createStatements: [PERMISSION.CREATE_STATEMENTS],
  updateStatusStatements: [PERMISSION.UPDATE_STATUS_STATEMENTS],
  forceDraftStatements: [PERMISSION.FORCE_DRAFT_STATEMENTS],
  updateContracts: [PERMISSION.UPDATE_CONTRACTS],
  payBillsAndPayHoldbacks: [PERMISSION.PAY_BILLS, PERMISSION.PAY_HOLDBACKS],
  updateCompanyInformation: [PERMISSION.UPDATE_COMPANY_INFORMATIONS],
  updateQuoteParameters: [PERMISSION.UPDATE_QUOTE_PARAMETERS],
  createSupplierInvoice: [PERMISSION.CREATE_SUPPLIER_INVOICE],
  paySupplierInvoice: [PERMISSION.PAY_SUPPLIER_INVOICE],
  updateSupplierInvoicePayment: [PERMISSION.UPDATE_SUPPLIER_INVOICE_PAYMENT],
  createOrder: [PERMISSION.CREATE_ORDER],
  createBudget: [PERMISSION.CREATE_BUDGET],
  accessTimeTracking: [PERMISSION.ACCESS_TIME_TRACKING],
  accessWorkerHourlyPrice: [PERMISSION.ACCESS_WORKER_HOURLY_PRICE],
  updateQuoteMarginDisbursementType: [PERMISSION.UPDATE_QUOTE_TYPE_DISBURSEMENT_TYPE],
  updateLedgerParameters: [PERMISSION.UPDATE_LEDGER_PARAMETERS],
  updateThirdParties: [PERMISSION.UPDATE_THIRD_PARTIES],
  updateAccounting: [PERMISSION.UPDATE_ACCOUNTING],
  generateAccounting: [PERMISSION.GENERATE_ACCOUNTING],
  updateEmailTemplates: [PERMISSION.UPDATE_EMAIL_TEMPLATES],
  displayQuotes: [PERMISSION.DISPLAY_QUOTES],
  accessOrders: [PERMISSION.ACCESS_ORDER],
  displayAccounting: [PERMISSION.DISPLAY_ACCOUNTING],
  accessSupplierInvoices: [PERMISSION.ACCESS_SUPPLIER_INVOICE],
  accessStatements: [PERMISSION.ACCESS_STATEMENTS],
  emailReminders: [PERMISSION.UPDATE_EMAIL_REMINDERS],
  accessSupplierInvoiceValidationWorkflow: [PERMISSION.ACCESS_SUPPLIER_INVOICE_VALIDATION_WORKFLOW],
  accessBalanceBanking: [PERMISSION.ACCESS_BALANCE_BANKING],
  accessTransactionBanking: [PERMISSION.ACCESS_TRANSACTION_BANKING],
  accessPaymentResourceBanking: [PERMISSION.ACCESS_PAYMENT_RESOURCE_BANKING],
};
