import type { IContract } from '../contract/contract.type';

export enum DISCOUNT_TYPES {
  PERCENTAGE = 'PERCENTAGE',
  AMOUNT = 'AMOUNT',
}

export interface IDiscount {
  id: number;

  type: DISCOUNT_TYPES;

  percentage: number | null;

  amountExVAT: number | null;

  createdAt: Date;

  updatedAt: Date;

  contract?: IContract;
}
