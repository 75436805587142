export type OptionType = {
  label: string | undefined;
  value: number | string | undefined | object | null;
  badgeColor?: string;
};

export const normalizeOptionValue = <Option extends OptionType, RenderProps>(
  options: (Option & RenderProps)[],
  optionValue: Option['value'],
): (Option & RenderProps) | undefined => {
  if (!optionValue || (Array.isArray(optionValue) && optionValue.length < 1)) {
    return undefined;
  }

  const selectedOption = options.find((option) => option.value === optionValue) ?? ({} as Option & RenderProps);

  return {
    ...selectedOption,
    value: optionValue,
  };
};
