import type { FC, ReactNode } from 'react';
import { Box, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import type { DocumentStatusCardIconValue } from '@graneet/lib-ui';
import { DocumentStatusCardIcon, mapIconToColor } from '@graneet/lib-ui';

interface ExternalMarketingModalProps {
  isOpen: boolean;
  onClose: () => void;
  icon: DocumentStatusCardIconValue;
  title: ReactNode;
  children: ReactNode;
}

export const ExternalMarketingModal: FC<ExternalMarketingModalProps> = ({ isOpen, onClose, icon, title, children }) => {
  const color = mapIconToColor(icon);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent overflow="hidden">
        <ModalHeader p={0}>
          <Box px={10} py={6} bg="black">
            <Box borderRadius={12} bg={`${color}.100`} position="relative">
              <DocumentStatusCardIcon icon={icon} size={75} />
              <Box position="absolute" borderRadius={4} bg={`${color}.200`} w="50%" h={4} left={20} top={5} />
              <Box position="absolute" borderRadius={4} bg={`${color}.200`} w="70%" h={4} left={20} top={10} />
            </Box>
          </Box>
        </ModalHeader>

        <ModalBody p={0}>
          <Flex p={4} direction="column" gap={4}>
            <Text fontSize="lg">{title}</Text>
            {children}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
