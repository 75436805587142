import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { SimpleGrid, useDisclosure } from '@chakra-ui/react';
import { Button, Onboarding, Section, SimpleContactIcon } from '@graneet/lib-ui';
import { Form, HiddenField, useHiddenField, useOnChangeValues, useStepForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IInvoice, IClientResponseDTO } from '@graneet/business-logic';

import { Rule } from 'features/form/rules/Rule';
import type { InvoiceEditionWizard } from 'features/invoice/forms/invoice-edition-wizard';
import { ClientSelectionModal } from 'features/client/components/modals/ClientSelectionModal';
import { ClientUpsertModal } from 'features/client/components/modals/ClientUpsertModal';
import { ClientCard } from 'features/client/components/cards/ClientCard';
import { ClientCardTypeEnum } from 'features/client/components/cards/ClientCardDetails';
import { useClientsWithoutPagination } from 'features/client/services/client.api';

interface EditInvoiceClientStepProps {
  invoice: IInvoice | null;
}

export const EditInvoiceClientStep: FC<EditInvoiceClientStepProps> = ({ invoice }) => {
  const { t } = useTranslation(['invoice', 'clients']);

  const creationModal = useDisclosure();
  const selectionModal = useDisclosure();

  const clients = useClientsWithoutPagination();

  const { form, initFormValues } = useStepForm<InvoiceEditionWizard, 'client'>();
  const clientIdHiddenField = useHiddenField(form, 'clientId');
  const { setValue: setClientId } = clientIdHiddenField;
  const { clientId } = useOnChangeValues(form, ['clientId']);

  const selectedClient = useMemo(() => clients.data.data.find((client) => client.id === clientId), [clientId, clients]);

  useEffect(() => {
    if (invoice?.client) {
      initFormValues({ clientId: invoice?.client.id });
    }
  }, [initFormValues, invoice?.client]);

  const handleClientAssociationSuccess = useCallback(
    async (client: IClientResponseDTO) => {
      setClientId(client.id);
      selectionModal.onClose();
    },
    [selectionModal, setClientId],
  );

  const resetContactId = useCallback(() => {
    setClientId(undefined as any);
  }, [setClientId]);

  return (
    <Form form={form}>
      <HiddenField {...clientIdHiddenField}>
        <Rule.IsRequired />
      </HiddenField>

      <Section title={t('invoice:steps.client.title')} description={t('invoice:steps.client.description')}>
        {!clientId && (
          <Onboarding
            icon={<SimpleContactIcon boxSize={45} />}
            mb={8}
            mt={16}
            h="auto"
            action={
              <SimpleGrid spacing={4} columns={2}>
                <Button onClick={selectionModal.onOpen} variant="outline">
                  {t('invoice:steps.client.select')}
                </Button>
                <Button onClick={creationModal.onOpen} colorScheme="blue">
                  {t('invoice:steps.client.create')}
                </Button>
              </SimpleGrid>
            }
          />
        )}
        {clientId && selectedClient && (
          <>
            <ClientCard
              type={ClientCardTypeEnum.CHANGE}
              client={selectedClient}
              onClientCardButtonClicked={resetContactId}
            />
          </>
        )}
      </Section>
      <ClientSelectionModal
        title={t('clients:associateModal.title.invoice')}
        description={t('clients:associateModal.description.invoice')}
        isOpen={selectionModal.isOpen}
        onClose={selectionModal.onClose}
        handleSuccess={handleClientAssociationSuccess}
        successButtonTranslation={t('clients:associateModal.associateContact.invoice')}
        onSubmit={(id) => {
          form.setFormValues({
            clientId: id,
          });
        }}
      />
      <ClientUpsertModal
        title={t('clients:clientCreation')}
        description={t('clients:clientModalEditor.creationDescription.invoice')}
        isOpen={creationModal.isOpen}
        onClose={creationModal.onClose}
        onSuccess={handleClientAssociationSuccess}
        submitButtonLabel={t('clients:createClient')}
      />
    </Form>
  );
};
