import { useMemo } from 'react';
import type { FEATURE_FLAGS } from '@graneet/business-logic';

import { useAppContext } from 'features/app/contexts/AppContext';

/**
 * @example
 * ```
 * function SomeComponent() {
 *   const isSomeFeatureOn = useFeature('some-feature');
 *   useEffect(() => {
 *     if (isSomeFeatureOn) {
 *       // ...
 *     } else {
 *       // ...
 *     }
 *   }, [isSomeFeatureOn])
 *   // ...
 *   return (
 *     {* ... *}
 *   )
 * }
 * ```
 */
export const useFeatureFlag = (feature: FEATURE_FLAGS): boolean => {
  const { currentUser } = useAppContext();
  const { company } = currentUser || {};
  const { activeFeatureFlags } = company || {};

  return useMemo(() => !!activeFeatureFlags?.includes(feature), [activeFeatureFlags, feature]);
};
