import type { FC } from 'react';
import type { SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';
import { FEATURE_FLAGS, PERMISSION } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Card, DocumentStatusCard, DocumentStatusCardActions, formatDateOrEmpty, GotoLink } from '@graneet/lib-ui';
import { useDisclosure } from '@chakra-ui/react';

import { getCashOutFlowActions, getCashOutFlowStatusCardIcon } from './cash-out-flow.actions';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { SupplierInvoicePaymentModal } from 'features/supplier-invoice/components/SupplierInvoicePaymentModal';
import { SupplierInvoiceStatusBadge } from 'features/supplier-invoice/components/badges/SupplierInvoicesStatusBadge';
import { SupplierInvoiceLateDaysBadge } from 'features/supplier-invoice/components/badges/SupplierInvoiceLateDaysBadge';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { SupplierInvoicePaymentsEditModal } from 'features/supplier-invoice/components/modals/SupplierInvoicePaymentsEditModal';

interface CashOutFlowStatusCardProps {
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}
export const CashOutFlowStatusCard: FC<CashOutFlowStatusCardProps> = ({ supplierInvoice }) => {
  const { t } = useTranslation(['cashOutflow']);

  const markPaymentModal = useDisclosure();
  const payModal = useDisclosure();

  const hasPaySupplierInvoicesPermission = usePermissions([PERMISSION.PAY_SUPPLIER_INVOICE]);

  const hasBankingConnectionFF = useFeatureFlag(FEATURE_FLAGS.BANKING_ACCOUNT_CONNECTION);

  return (
    <Card
      title={t('cashOutflow:cards.stateCard.title')}
      topRightContent={
        <GotoLink
          to={`/purchases/supplier-invoices/${supplierInvoice.id}`}
          label={t(`cashOutflow:goTo.supplierInvoice`)}
        />
      }
    >
      <DocumentStatusCard
        mb={4}
        icon={getCashOutFlowStatusCardIcon(supplierInvoice.status)}
        title={supplierInvoice.name ?? ''}
        subtitle={t('cashOutflow:cards.stateCard.paymentDeadline', {
          date: formatDateOrEmpty(supplierInvoice.billingDate, 'LL'),
        })}
        footer={<SupplierInvoiceLateDaysBadge supplierInvoice={supplierInvoice} />}
        badgeComponent={<SupplierInvoiceStatusBadge status={supplierInvoice.status} />}
      />

      <DocumentStatusCardActions
        actions={getCashOutFlowActions({
          supplierInvoice,
          permissions: {
            hasPaySupplierInvoicesPermission,
          },
          callbacks: {
            onPay: payModal.onOpen,
            onUpdateToPay: markPaymentModal.onOpen,
          },
          hasBankingConnectionFF,
        })}
      />

      <SupplierInvoicePaymentsEditModal
        supplierInvoice={supplierInvoice}
        onClose={markPaymentModal.onClose}
        isOpen={markPaymentModal.isOpen}
      />

      <SupplierInvoicePaymentModal
        supplierInvoice={supplierInvoice}
        isOpen={payModal.isOpen}
        onClose={payModal.onClose}
      />
    </Card>
  );
};
