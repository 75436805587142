import type { ReactNode } from 'react';
import { useCallback, useRef } from 'react';
import type { FieldValues, FieldRenderProps, FieldRenderState, FieldValue, AnyRecord } from 'graneet-form';
import { Field, VALIDATION_OUTCOME, composeEventHandlers } from 'graneet-form';

import type { MaskedInputProps } from '../../Input/MaskedInput';
import { MaskedInput } from '../../Input/MaskedInput';
import { useInputMaskInfos } from '../../Input/MaskedInput/useInputMaskInfos';
import type { MaskOptions, MaskType } from '../../Input/MaskedInput/mask.type';
import type { KeysMatching } from '../../../utils';

type MaskedNumberValue = number | null | undefined;

export const parseFieldFloat = <T extends FieldValue>(value: T): T => {
  // When user write '-' in field, imask returns '-0'
  // so we don't want to parse it to not remove user input for 0
  if (value === '-0') return value;

  // @ts-ignore
  return value && value.length > 0 ? parseFloat(value) : null;
};

export interface RoundableNumberField {
  scale?: number | null;
}

interface InternalMaskedNumberFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, MaskedNumberValue> = KeysMatching<T, MaskedNumberValue>,
> extends Omit<MaskedInputProps, 'mask' | 'errorMessage' | 'shouldDisplayError' | 'value'> {
  type: MaskType;

  options?: MaskOptions;

  onBlur?(): void;

  onChange?(e: T[K] | undefined): void;

  onFocus?(): void;

  hideErrorMessage?: boolean;

  formatValue?(v: T[K] | undefined | null): T[K] | undefined | null;

  fieldRenderProps: FieldRenderProps<T, K>;

  fieldRenderState: FieldRenderState;
}

const InternalMaskedNumberField = <
  T extends FieldValues,
  K extends KeysMatching<T, MaskedNumberValue> = KeysMatching<T, MaskedNumberValue>,
>({
  type,
  options,
  onBlur,
  onChange = () => {},
  onFocus,
  hideErrorMessage = false,
  formatValue = (v) => v,
  fieldRenderProps,
  fieldRenderState,
  ...rest
}: InternalMaskedNumberFieldProps<T, K>) => {
  const { placeholder, mask } = useInputMaskInfos(type, options);

  const { value, onChange: onChangeForm, onBlur: onBlurForm, onFocus: onFocusForm } = fieldRenderProps;
  const { validationStatus } = fieldRenderState;
  const shouldDisplayError = !hideErrorMessage && validationStatus.status === VALIDATION_OUTCOME.INVALID;

  const isMinusSignRef = useRef(false);
  const isFocusedRef = useRef(false);

  const onInputChange = useCallback(
    (v: T[K] | undefined) => {
      const isMinusSign = v === '-0';
      isMinusSignRef.current = isMinusSign;
      if (!isMinusSign && isFocusedRef.current) {
        composeEventHandlers(onChangeForm, onChange)(parseFieldFloat(v));
      }
    },
    [onChange, onChangeForm],
  );

  const onInputBlur = useCallback(() => {
    // When user write '-' or '-0' in field, imask returns '-0'
    // on blur we force the value back to 0, in order for handler
    // to be called with 0 instead of -0
    if (isMinusSignRef.current) {
      onInputChange(0 as T[K]);
    }
    composeEventHandlers(onBlur, onBlurForm)();
    isFocusedRef.current = false;
  }, [onBlur, onBlurForm, onInputChange]);

  const onInputFocus = useCallback(() => {
    isFocusedRef.current = true;
    composeEventHandlers(onFocus, onFocusForm)();
  }, [onFocus, onFocusForm]);

  return (
    <MaskedInput
      placeholder={placeholder}
      {...rest}
      mask={mask}
      errorMessage={validationStatus.message}
      shouldDisplayError={shouldDisplayError}
      value={Number.isFinite(value) ? formatValue(value) : ''}
      onChange={onInputChange}
      onBlur={onInputBlur}
      onFocus={onInputFocus}
    />
  );
};

export interface MaskedNumberFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, MaskedNumberValue> = KeysMatching<T, MaskedNumberValue>,
> extends Omit<InternalMaskedNumberFieldProps<T, K>, 'fieldRenderProps' | 'fieldRenderState'> {
  name: K;

  data?: AnyRecord;

  children?: ReactNode;
}
export const MaskedNumberField = <
  T extends FieldValues,
  K extends KeysMatching<T, MaskedNumberValue> = KeysMatching<T, MaskedNumberValue>,
>({
  name,
  data,
  children,
  ...otherProps
}: MaskedNumberFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    data={data}
    render={(fieldRenderProps, fieldRenderState) => (
      <InternalMaskedNumberField
        {...otherProps}
        fieldRenderProps={fieldRenderProps}
        fieldRenderState={fieldRenderState}
      />
    )}
  >
    {children}
  </Field>
);
