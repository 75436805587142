import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { genericUpdateItemMapper } from 'features/quotation/quote-common/mappers/quoteItemsGenericMapper';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteBasicItemUpdateRefCode() {
  const { quoteBasicItemsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, newRefCode: string | null): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteBasicItem = quote.getTree().getQuoteBasicItemOrThrow(nodeId);
          this.oldValue = quoteBasicItem.getRefCode();
          const quoteNode = quoteBasicItem.getNodeOrThrow();
          quoteBasicItem.updateProperties({
            refCode: newRefCode,
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteBasicItemsProxyApi
            .updateQuoteBasicItem(quoteBasicItem.getId(), {
              ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
              refCode: newRefCode,
              quoteBasicItemId: quoteBasicItem.getId(),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteBasicItem = quote.getTree().getQuoteBasicItemOrThrow(nodeId);
          const quoteNode = quoteBasicItem.getNodeOrThrow();
          quoteBasicItem.updateProperties({
            refCode: this.oldValue,
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteBasicItemsProxyApi
            .updateQuoteBasicItem(quoteBasicItem.getId(), {
              ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
              refCode: this.oldValue,
              quoteBasicItemId: quoteBasicItem.getId(),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteBasicItemsProxyApi, quoteError, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
