import type {
  IContainerCreationDTO,
  IInvoiceWithRelations,
  IItemCreationDTO,
  IItemWithUUID,
  ILotWithUUID,
} from '@graneet/business-logic';
import { DEFAULT_VAT_RATE, multiplyFloating } from '@graneet/business-logic';
import type { IInitialTree } from '@graneet/lib-ui';

import type { ContainerTreeContextApi } from '../hooks/useInvoiceTree';
import type { InvoiceEditionForm } from '../forms/invoice-edition-form';
import { getItemFieldName } from '../forms/invoice-edition-form';

type IItemWithUUIDExtended = IItemWithUUID & { parentNodeId: number | string; previousLeafId: number | string | null };

export const mapInvoiceToInitialTree = (
  invoice: IInvoiceWithRelations | null,
  { defaultVatRate = DEFAULT_VAT_RATE }: { defaultVatRate?: number } = {},
): IInitialTree<ILotWithUUID, IItemWithUUID> => {
  if (invoice) {
    const items: Record<string | number, IItemWithUUIDExtended> = {};
    const relations: Array<string | number> = [];
    invoice.container.items.forEach((item) => {
      items[item.id] = { ...item, parentNodeId: 1, previousLeafId: item.previousItem?.id || null };
      relations.push(item.id);
    });

    return {
      nodes: {
        1: {
          id: 1,
          parentNodeId: null,
        } as ILotWithUUID & { parentNodeId: null },
      },
      leaves: items,
      relations: {
        1: {
          nodes: [],
          leaves: relations,
        },
      },
      rootNodeId: 1,
    };
  }

  return {
    nodes: {
      1: {
        id: 1,
        parentNodeId: null,
      } as ILotWithUUID & { parentNodeId: null },
    },
    leaves: {
      1: {
        id: 1,
        parentNodeId: 1,
        vatRate: defaultVatRate,
      } as IItemWithUUIDExtended,
    },
    relations: {
      1: {
        nodes: [],
        leaves: [1],
      },
    },
    rootNodeId: 1,
  };
};

export const mapTreeAndFormValuesToContainer = (
  tree: ContainerTreeContextApi,
  formValues: InvoiceEditionForm,
  mapAmountToNumber: (value: string | number) => number,
): IContainerCreationDTO => {
  const rootItems = tree.getCurrentTree().relations[1].leaves;

  const items = rootItems.reduce<IItemCreationDTO[]>((acc, itemId) => {
    const itemToSave: IItemCreationDTO = {
      code: formValues[getItemFieldName(itemId, 'code')] || null,
      description: formValues[getItemFieldName(itemId, 'description')],
      unit: formValues[getItemFieldName(itemId, 'unit')],
      quantity: formValues[getItemFieldName(itemId, 'quantity')],
      quantityFormula: null,
      unitPrice: mapAmountToNumber(formValues[getItemFieldName(itemId, 'unitPrice')]),
      vatRate: formValues.hasReversalOfLiability
        ? DEFAULT_VAT_RATE
        : multiplyFloating(formValues[getItemFieldName(itemId, 'vatRate')], 0.01),
      // Invoice items are never optional
      isOptional: false,
      note: null,
    };

    return [...acc, itemToSave];
  }, []);

  return {
    lots: [],
    items,
  };
};
