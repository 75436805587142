/**
 * Product-tour is mainly created directly from within the app UI.
 * Tours and anouncements are to be activated on different pages under certain conditons (segments) and
 * under many forms (triggers).
 *
 * Related Notion page
 * https://www.notion.so/graneet/INVESTIGATE-Benchmark-Product-tour-solutions-4d7c2411c71f4d93905f0fa324254e51
 */

import { getEnvValue } from 'config/env';

export async function setUpProductTour(): Promise<void> {
  const wid = getEnvValue('REACT_APP_STONLY_WID', false);

  if (!wid || window.StonlyWidget) {
    return;
  }
  window.STONLY_WID = wid;
  const StonlyFunc = (...args: any[]) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _api, queue } = window.StonlyWidget;
    if (_api) {
      _api.apply(window.StonlyWidget, args);
    } else {
      queue.push(args);
    }
  };
  window.StonlyWidget = StonlyFunc;
  window.StonlyWidget.queue = [];

  const script = document.createElement('script');
  script.src = `https://stonly.com/js/widget/v2/stonly-widget.js?v=${Date.now()}`;
  document.body.appendChild(script);
}
