import { useContext } from 'react';
import { GridItem } from '@chakra-ui/react';

import { DataTableContext } from '../contexts/DataTableContext';

export const DataTableSeparator = () => {
  const { numberOfColumns } = useContext(DataTableContext);

  return (
    <GridItem
      colSpan={numberOfColumns}
      borderBottomColor="gray.200"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      mb={3}
    />
  );
};
