import type { PaginatedTableProps, PaginationQuery } from '@graneet/lib-ui';
import { PriceAdvanced, formatDataOrEmpty, ActionMenu, PaginatedTable } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IWorker, PaginatedResponse } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { WorkerCard } from './WorkerCard';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { ComponentTypeBadge } from 'features/component-type/components/ComponentTypeBadge';

interface WorkersTableProps {
  workers: PaginationQuery<PaginatedResponse<IWorker>>;
  onWorkerToEditSelected: (worker: IWorker) => void;
  onWorkerToDeleteSelected: (worker: IWorker) => void;
}

const ComponentTypeCell = ({ data }: { data?: IWorker }) =>
  data?.componentType ? <ComponentTypeBadge type={data.componentType} /> : null;

const LastNameCell = ({ data }: { data?: IWorker }) => (data ? <WorkerCard worker={data} /> : null);

const HourlyPriceExVATCell = ({ data }: { data?: IWorker }) =>
  data ? <PriceAdvanced amount={data.hourlyPriceExVAT} /> : null;

export const WorkersTable: FC<WorkersTableProps> = ({ workers, onWorkerToEditSelected, onWorkerToDeleteSelected }) => {
  const { t } = useTranslation(['global', 'worker']);

  const isUserAllowedToSeeSensitiveInformation = usePermissions([PERMISSION.ACCESS_WORKER_HOURLY_PRICE]);

  const MenuCell = useCallback(
    ({ data }: { data?: IWorker }) =>
      data ? (
        <ActionMenu>
          <ActionMenu.Edit onClick={() => onWorkerToEditSelected(data)} />
          <ActionMenu.Delete onClick={() => onWorkerToDeleteSelected(data)} />
        </ActionMenu>
      ) : null,
    [onWorkerToDeleteSelected, onWorkerToEditSelected],
  );

  const columnDefs = useMemo<PaginatedTableProps<PaginatedResponse<IWorker>>['columnDefs']>(
    () => [
      {
        field: 'componentType',
        sortable: true,
        headerName: t('worker:fields.workforceDisbursementType'),
        cellRenderer: ComponentTypeCell,
      },
      { field: 'lastName', sortable: true, headerName: t('worker:name'), cellRenderer: LastNameCell },
      {
        field: 'employeeId',
        sortable: true,
        headerName: t('worker:fields.employeeId'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.employeeId),
      },
      {
        field: 'email',
        sortable: true,
        headerName: t('worker:fields.email'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.email),
      },
      {
        field: 'phoneNumber',
        sortable: true,
        headerName: t('worker:fields.phoneNumber'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.phoneNumber),
      },
      {
        field: 'hourlyPriceExVAT',
        sortable: true,
        headerName: t('worker:fields.hourlyPriceExVAT'),
        cellRenderer: HourlyPriceExVATCell,
        hide: !isUserAllowedToSeeSensitiveInformation,
      },
      {
        field: 'menu' as any,
        cellRenderer: MenuCell,
        hide: !isUserAllowedToSeeSensitiveInformation,
        width: 20,
        headerName: '',
        suppressMovable: true,
        resizable: false,
      },
    ],
    [MenuCell, isUserAllowedToSeeSensitiveInformation, t],
  );

  return (
    <PaginatedTable
      gridId="worker"
      pagination={workers}
      columnDefs={columnDefs}
      rowHeight={82}
      zeroState={{
        icon: <i className="ri-user-line ri-4x" />,
        label: t('worker:noWorker'),
      }}
      emptyState={{
        label: t('worker:noResult'),
      }}
    />
  );
};
