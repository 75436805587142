import type dayjs from 'dayjs';
import type { FC, ReactElement, ReactNode } from 'react';
import type { BoxProps } from '@chakra-ui/react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { configureDefaultLabels, stepperItemTranslations } from './configureDefaultLabels';

export interface StepperItemProps extends Omit<BoxProps, 'title'> {
  title: ReactNode;
  date?: dayjs.Dayjs;
  children?: ReactNode;
  icon: ReactElement;
  borderColor?: BoxProps['borderColor'];
  backgroundColorIcon?: string;
}

const StepperItemComponent: FC<StepperItemProps> = ({
  title,
  date,
  children,
  icon,
  borderColor = 'gray.150',
  backgroundColorIcon,
  ...rest
}) => (
  <Flex {...rest} direction="column" mb={1}>
    <Flex alignItems="center" gap={4}>
      <Box
        as="span"
        ml="2.5px"
        p={1}
        bg={backgroundColorIcon || 'gray.200'}
        borderRadius="full"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        color="gray.700"
      >
        {icon}
      </Box>
      <Box>
        <Text as="span" fontWeight="semibold" color="black" fontSize="sm">
          {title}
        </Text>
        {date && (
          <Text as="span" color="gray.600" fontSize="sm">
            {` ${stepperItemTranslations.date(date)}`}
          </Text>
        )}
      </Box>
    </Flex>
    {children && (
      <Box borderLeftWidth={2} borderColor={borderColor} pl={6} ml={3} mt={1} pb={6}>
        {children}
      </Box>
    )}
  </Flex>
);

export const StepperItem = Object.assign(StepperItemComponent, {
  configureDefaultLabels,
});
