import { useCallback, useEffect, useRef, useState } from 'react';

import { useTreeContext } from '../contexts';
import type { NodeInfos } from '../types/NodeInfos';
import type { Identifiable } from '../types/Identifiable';

export const useNode = <Node extends Identifiable, Leaf extends Identifiable>(nodeId: Node['id']): NodeInfos<Node> => {
  const { listenToNode, getNodeSignalInfos } = useTreeContext<Node, Leaf>();
  const [nodeInfos, setNodeInfos] = useState<NodeInfos<Node>>(() => getNodeSignalInfos(nodeId));

  const hasBeenCalledOnce = useRef(false);
  const updateNodeInfos = useCallback((infos: NodeInfos<Node>) => {
    if (!hasBeenCalledOnce.current) {
      hasBeenCalledOnce.current = true;
      return;
    }
    setNodeInfos(infos);
  }, []);

  useEffect(() => listenToNode(nodeId, updateNodeInfos), [listenToNode, nodeId, updateNodeInfos]);

  return nodeInfos;
};
