import { Badge, DocumentStatusCard, DocumentStatusCardActions, formatDateOrEmpty } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IBillResponseDTO } from '@graneet/business-logic';
import { FEATURE_FLAGS, isBillLost, isBillNotFullyPayed, PERMISSION } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';

import { BillStatusBadge } from '../BillStatusBadge';
import { useMarkBillsAsReminded } from '../../services/bill.api';

import { getBillActions, getBillStatusCardIcon } from './bill.actions';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { BillMarkAsLostModal } from 'features/bill/components/modals/BillMarkAsLostModal';
import { BillPayModal } from 'features/bill/components/modals/BillPayModal';

interface BillStatusCardProps {
  bill: IBillResponseDTO;

  onUpdate: () => void;

  onRemind: () => void;
}

export const BillStatusCard: FC<BillStatusCardProps> = ({ bill, onUpdate, onRemind }) => {
  const { t } = useTranslation(['bill', 'global']);

  const hasPayBillsPermission = usePermissions([PERMISSION.PAY_BILLS]);

  const hasBillEmailRemindFF = useFeatureFlag(FEATURE_FLAGS.BILL_EMAIL_REMIND);

  const toPayModal = useDisclosure();
  const lostModal = useDisclosure();

  const markBillsAsReminded = useMarkBillsAsReminded();

  const onMarkAsReminded = useCallback(async () => {
    await markBillsAsReminded.mutateAsync(
      {
        filters: undefined,
        selectedItems: [{ id: bill.id }],
        hasAllSelected: false,
        search: undefined,
      },
      {
        onSuccess: () => {
          onUpdate();
        },
      },
    );
  }, [bill.id, markBillsAsReminded, onUpdate]);

  return (
    <Box>
      <DocumentStatusCard
        mb={4}
        icon={getBillStatusCardIcon(bill.status)}
        title={bill.name}
        subtitle={t('bill:billingDate', { date: formatDateOrEmpty(bill.paymentDeadline, 'LL') })}
        footer={
          bill.lateDays > 0 &&
          isBillNotFullyPayed(bill) &&
          !isBillLost(bill) && <Badge colorScheme="red">{t('bill:fields.lateDays', { count: bill.lateDays })}</Badge>
        }
        badgeComponent={<BillStatusBadge status={bill.status} />}
      />

      <DocumentStatusCardActions
        actions={getBillActions({
          bill: { ...bill, reminder: hasBillEmailRemindFF ? bill.reminder : null },
          hasPayBillsPermission,
          onPay: toPayModal.onOpen,
          onLost: lostModal.onOpen,
          onRemind,
          onMarkAsReminded,
        })}
      />

      <BillMarkAsLostModal bill={bill} modal={lostModal} onSuccess={onUpdate} />
      <BillPayModal bill={bill} modal={toPayModal} onSuccess={onUpdate} />
    </Box>
  );
};
