import { Flex, GridItem, HStack, useTheme } from '@chakra-ui/react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { ButtonColorScheme } from '../../../../Badge/Badge';
import { useMonthlyTimeTableContext } from '../../contexts';

dayjs.extend(isBetween);

export interface MonthlyTimeTableRangeProps {
  color: ButtonColorScheme;
  row: number;
  startDate: Date;
  endDate: Date;
}

export const MonthlyTimeTableRange: FC<MonthlyTimeTableRangeProps> = ({ color, row, startDate, endDate }) => {
  const { colors } = useTheme();
  const { showWeekEnd } = useMonthlyTimeTableContext();

  const width = useMemo(() => {
    let currentDay = dayjs(startDate);
    let calculatedWidth = 0;

    while (currentDay.isBetween(dayjs(startDate).subtract(1, 'day'), dayjs(endDate).add(1, 'day'))) {
      const isWeekEnd = [0, 6].includes(currentDay.day());
      if (!isWeekEnd || (isWeekEnd && showWeekEnd)) {
        calculatedWidth += 1;
      }
      currentDay = currentDay.add(1, 'day');
    }
    return calculatedWidth;
  }, [startDate, endDate, showWeekEnd]);

  const dayOffset = useMemo(() => {
    let dayIteration = dayjs(startDate).startOf('month');
    let numberOfDaysInWeekend = 0;

    while (dayIteration.isBefore(dayjs(startDate).add(1, 'day'))) {
      const isWeekEnd = [0, 6].includes(dayIteration.day());
      if (isWeekEnd && !showWeekEnd) {
        numberOfDaysInWeekend += 1;
      }
      dayIteration = dayIteration.add(1, 'day');
    }

    return dayjs(startDate).date() - numberOfDaysInWeekend;
  }, [startDate, showWeekEnd]);

  const gridArea = `${row} / ${dayOffset} / ${row} / ${width + dayOffset}`;

  return (
    <Flex
      as={GridItem}
      mt={3}
      mr={2}
      mb={2}
      h={25}
      zIndex={2}
      gridRow={row}
      bgColor={`${color}.100`}
      gridArea={gridArea}
      borderLeft={`5px solid ${colors[color][600]}`}
      borderRightRadius="md"
      justifyContent="space-between"
      overflow="hidden"
      position="relative"
    >
      <HStack ml={3} w="100%" pr="45px" />
    </Flex>
  );
};
