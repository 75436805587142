import type { FC, ReactNode } from 'react';
import { forwardRef } from 'react';
import { Text, Box, HStack } from '@chakra-ui/react';

import { IconAdvanced } from '../IconAdvanced';
import { formatDataOrEmpty } from '../../utils/format.util';
import { SimpleHelpIcon } from '../Icons';

const variantStyles = {
  light: {
    color: 'gray.500',
  },
  default: {
    color: 'primary',
  },
};

const FONT_SIZES = {
  md: {
    title: 'xs',
    content: 'sm',
  },
  lg: {
    title: 'sm',
    content: 'md',
  },
};

export type LabeledDataProps = {
  label: ReactNode;
  data: ReactNode;

  tooltip?: ReactNode;
  multiline?: boolean;
  variant?: 'light' | 'default';
  size?: 'md' | 'lg';
};

export const LabeledData: FC<LabeledDataProps> = forwardRef<HTMLDivElement, LabeledDataProps>(
  ({ label, data = null, variant = 'default', multiline = false, tooltip, size = 'lg' }, ref) => {
    const style = Object.keys(variantStyles).includes(variant) ? variantStyles[variant] : variantStyles.default;
    const fontSize = FONT_SIZES[size];

    return (
      <Box ref={ref}>
        <Text fontSize={fontSize.title} color="gray.700" mb={1}>
          {label}
        </Text>
        <HStack spacing={2}>
          <Text
            color={style.color}
            fontSize={fontSize.content}
            whiteSpace={multiline ? 'pre-wrap' : 'normal'}
            as={Box}
            w="100%"
          >
            {formatDataOrEmpty(data)}
          </Text>

          {tooltip && <IconAdvanced icon={<SimpleHelpIcon m={0} />} tooltip={tooltip} />}
        </HStack>
      </Box>
    );
  },
);
