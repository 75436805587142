import type { FC } from 'react';
import { Flex, Grid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { InputProps } from '@graneet/lib-ui';
import { CurrencyField, PercentageField, PriceAdvanced } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { CUSTOM_DISCOUNT_TYPES } from '@graneet/business-logic';

import { useCustomDiscountTotalExVAT } from '../hooks/useCustomDiscountTotalExVAT';
import type { CustomDiscountEditionForm } from '../forms/custom-discount-edition-form';

import { Rule } from 'features/form/rules/Rule';

const customDiscountFieldInputProps: InputProps = {
  textAlign: 'right',
  borderRadius: 2,
  background: 'white',
};

interface CustomDiscountTotalsProps {
  hasDiscount: boolean;
  totalBeforeCustomDiscountExVAT: number;
  onFieldBlur?(): void;
}

export const CustomDiscountTotals: FC<CustomDiscountTotalsProps> = (props) => {
  const { totalBeforeCustomDiscountExVAT, hasDiscount, onFieldBlur } = props;
  const { t } = useTranslation(['customDiscount']);

  const totalExVAT = useCustomDiscountTotalExVAT(totalBeforeCustomDiscountExVAT);
  const form = useFormContext<CustomDiscountEditionForm>();
  const { customDiscountType, customDiscountMultiplier } = useOnChangeValues(form, [
    'customDiscountType',
    'customDiscountMultiplier',
  ]);

  return (
    <Flex bg="gray.100" borderRadius="4" direction="column" px={4} py={2} flexGrow={1}>
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <Text fontWeight={600} textAlign="right">
          {hasDiscount
            ? t('customDiscount:totalExVATAfterDiscountLabel')
            : t('customDiscount:totalExVATBeforeCustomDiscountLabel')}
        </Text>

        <PriceAdvanced textAlign="right" fontWeight={600} amount={totalBeforeCustomDiscountExVAT} />

        {customDiscountType === CUSTOM_DISCOUNT_TYPES.AMOUNT && (
          <>
            <Text textAlign="right">
              {t(
                customDiscountMultiplier !== -1
                  ? 'customDiscount:fields.customDiscountIncreaseValueExVAT'
                  : 'customDiscount:fields.customDiscountDecreaseValueExVAT',
              )}
            </Text>
            <Flex justifyContent="flex-end">
              <CurrencyField<CustomDiscountEditionForm>
                name="customDiscountAmountExVAT"
                w="8rem"
                inputProps={customDiscountFieldInputProps}
                onBlur={onFieldBlur}
                min={0}
              >
                <Rule.IsRequired />
              </CurrencyField>
            </Flex>
          </>
        )}
        {customDiscountType === CUSTOM_DISCOUNT_TYPES.PERCENTAGE && (
          <>
            <Text textAlign="right">
              {t(
                customDiscountMultiplier !== -1
                  ? 'customDiscount:fields.customDiscountIncreaseValueExVAT'
                  : 'customDiscount:fields.customDiscountDecreaseValueExVAT',
              )}
            </Text>
            <Flex justifyContent="flex-end">
              <PercentageField<CustomDiscountEditionForm>
                name="customDiscountPercentage"
                w="4.5rem"
                inputProps={customDiscountFieldInputProps}
                min={0}
              >
                <Rule.IsRequired />
              </PercentageField>
            </Flex>
          </>
        )}

        <Text fontWeight={600} textAlign="right">
          {t('customDiscount:totalAmountExVAT')}
        </Text>

        <PriceAdvanced textAlign="right" fontWeight={600} amount={totalExVAT} />
      </Grid>
    </Flex>
  );
};
