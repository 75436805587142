import { useContext } from 'react';

import { PaginatedDataContext } from '../contexts';
import {
  DIRECTION_QUERY,
  FULL_QUERY,
  INCREMENTAL_QUERY,
  OFFSET_QUERY,
  OFFSET_V1_QUERY,
  OFFSET_V2_QUERY,
  ORDER_QUERY,
  PAGE_QUERY,
  SEARCH_QUERY,
  SIZE_QUERY,
} from '../constants';

import type { PaginatedDataContextApi } from './usePaginatedData';

export const isFilter = (v: string) =>
  ![
    INCREMENTAL_QUERY,
    PAGE_QUERY,
    ORDER_QUERY,
    DIRECTION_QUERY,
    SIZE_QUERY,
    SEARCH_QUERY,
    FULL_QUERY,
    OFFSET_QUERY,
    OFFSET_V1_QUERY,
    OFFSET_V2_QUERY,
  ].includes(v);
export const usePaginatedDataContext = <T extends object, Summable extends keyof T = never>() =>
  useContext<PaginatedDataContextApi<T, Summable>>(PaginatedDataContext as any);
