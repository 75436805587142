import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import {
  Modal,
  Button,
  CurrencyField,
  ModalSubtitle,
  RequiredText,
  TextAreaField,
  useCurrency,
  divideFloating,
  formatDateToString,
  DateRangeField,
  useToast,
  MultiAutocompleteField,
  SimpleDeleteIcon,
  SingleSelectField,
} from '@graneet/lib-ui';
import { Form, useForm, useFormStatus, useOnBlurValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { ABSENCE_DURATION, IUpdateTimeTrackingDTO } from '@graneet/business-logic';
import { SUPPORT_EMAIL, ABSENCE_TYPES, isNumberFinite, PERMISSION } from '@graneet/business-logic';

import { updateSlot, deleteSlot } from '../../services/time-slot.api';
import { useTimeTrackingContext } from '../../context/TimeTrackingContext';

import { WorkingTimeForm } from './TimeSlotCreateModal/WorkingTimeForm';
import type {
  DateFieldName,
  StartHourFieldName,
  EndHourFieldName,
  TimeSlotCreateForm,
  AbsenceDurationFieldName,
} from './TimeSlotCreateModal/TimeSlotCreateModalForm';
import {
  getAbsenceTypeFieldName,
  getHour,
  getEndHourFieldName,
  getStartHourFieldName,
  getTimeslotOvertimesHourFieldName,
  getTimeslotOvertimesTypeIdFieldName,
} from './TimeSlotCreateModal/TimeSlotCreateModalForm';

import { Rule } from 'features/form/rules/Rule';
import { usePermissions } from 'features/role/hooks/usePermissions';
import type { OrderProjectField } from 'features/project/components/ProjectField/ProjectField';
import { ProjectField } from 'features/project/components/ProjectField/ProjectField';
import { TIME_SLOT_FIELDS } from 'features/time-tracking/constants/time-slot.constant';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { extractTimeslotOvertimes } from 'features/time-tracking/services/time-tracking.util';
import { useIsTimeslotOvertimesValid } from 'features/time-tracking/hooks/useIsTimeslotOvertimesValid';
import { useOvertimeTypes } from 'features/overtime/services/overtime-type.api';

type ProjectFieldValues = {
  projectId: number;
} & OrderProjectField;

interface FormValues {
  [date: DateFieldName]: { startDate: Date; endDate: Date };
  hourlyPriceExVAT: number;
  projectId: number;
  workerIds: number[];
  note?: string | null;
  workforceComponentTypeId: number;
  kmExpense: number;
  mealExpense: number;
  absenceType: ABSENCE_TYPES;
  [absenceDuration: AbsenceDurationFieldName]: string;
  [startHour: StartHourFieldName]: string;
  [endHour: EndHourFieldName]: string;
}

export const TimeSlotEditModal: FC = () => {
  const overtimeTypes = useOvertimeTypes();
  const { t } = useTranslation(['global', 'timeTracking']);
  const {
    state: {
      editingSlot: slot,
      // Slot can potentially be associated to a deleted worker
      workersWithDeleted: workers,
      projects,
    },
    modalEditSlot: { isOpen, onClose },
  } = useTimeTrackingContext();

  const toast = useToast();
  const form = useForm<FormValues>();
  const { getFormValues, setFormValues } = form;
  const watchedFormValues = useOnBlurValues(form, undefined);
  const isTimeslotOvertimesValid = useIsTimeslotOvertimesValid(watchedFormValues as Partial<TimeSlotCreateForm>);
  const { isValid: isFormValid } = useFormStatus(form);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();
  const [isLoading, setIsLoading] = useState(false);

  const showProject = useMemo(() => !!slot?.project, [slot]);
  const canAccessWorkerHourlyPrice = usePermissions([PERMISSION.ACCESS_WORKER_HOURLY_PRICE]);

  const workerOptions = useMemo(
    () =>
      workers.map(({ id, firstName, lastName }) => ({
        label: `${firstName} ${lastName}`,
        value: id,
      })),
    [workers],
  );

  useEffect(() => {
    if (slot) {
      const dateString = formatDateToString(slot.date)!;
      const fieldsToFill: Partial<TimeSlotCreateForm> = {};
      (slot?.timeslotOvertimes || []).forEach((timeSLotOvertime, index) => {
        fieldsToFill[getTimeslotOvertimesHourFieldName(dateString, index)] = parseFloat(
          divideFloating(timeSLotOvertime.nbMinutes, 60).toFixed(2),
        );
        fieldsToFill[getTimeslotOvertimesTypeIdFieldName(dateString, index)] = timeSLotOvertime.overtime?.id
          ? timeSLotOvertime.overtime.id
          : 'null';
      });
      if (slot.absenceDuration) {
        fieldsToFill[getAbsenceTypeFieldName(dateString)] = slot.absenceDuration;
      }

      setFormValues({
        'date.0': slot.date ? { startDate: new Date(slot.date), endDate: new Date(slot.date) } : undefined,
        hourlyPriceExVAT: isNumberFinite(slot.hourlyPriceExVAT) ? mapNumberToAmount(slot.hourlyPriceExVAT) : undefined,
        note: slot.note,
        projectId: slot.project?.id,
        workerIds: slot.worker?.id ? [slot.worker.id] : [],
        workforceComponentTypeId: slot.componentType?.id,
        mealExpense: isNumberFinite(slot.mealExpense) ? mapNumberToAmount(slot.mealExpense) : undefined,
        kmExpense: isNumberFinite(slot.kmExpense) ? mapNumberToAmount(slot.kmExpense) : undefined,
        ...(slot.absenceType ? { absenceType: slot.absenceType } : {}),
        ...fieldsToFill,
      });
    }
  }, [setFormValues, slot, mapNumberToAmount, workerOptions]);

  const handleDelete = useCallback(async () => {
    if (!slot?.id) return;
    await deleteSlot(slot.id);
    onClose();
  }, [slot, onClose]);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    const { hourlyPriceExVAT, note, workforceComponentTypeId, mealExpense, kmExpense, absenceType, ...rest } =
      getFormValues();

    const dateString = formatDateToString(slot!.date);
    const startHour = getHour(rest[getStartHourFieldName(dateString!)]);
    const endHour = getHour(rest[getEndHourFieldName(dateString!)]);
    const absenceDuration = rest[getAbsenceTypeFieldName(dateString!)] as ABSENCE_DURATION;
    const timeslotOvertimes = extractTimeslotOvertimes(dateString!, rest, overtimeTypes.data);

    const dto: IUpdateTimeTrackingDTO = {
      note: note || null,
      hourlyPriceExVAT: mapAmountToNumber(hourlyPriceExVAT ?? 0),
      slot: {
        date: formatDateToString(new Date(slot!.date!))!,
        startHour,
        endHour,
        timeslotOvertimes,
        absenceDuration,
      },
      workforceComponentTypeId,
      mealExpense: mapAmountToNumber(mealExpense ?? 0),
      kmExpense: mapAmountToNumber(kmExpense ?? 0),
    };

    if (absenceType) {
      dto.absenceType = absenceType;
    }

    const [err] = await updateSlot(slot!.id!, dto);
    if (err) {
      toast.error(t('global:words.c.error'), t('timeTracking:editTimeSlotModal.toastError', { email: SUPPORT_EMAIL }));
      setIsLoading(false);
      return;
    }

    onClose();

    form.resetForm();

    setIsLoading(false);
  }, [getFormValues, slot, overtimeTypes, mapAmountToNumber, onClose, form, toast, t]);

  const defaultSelectedTimeslotOvertimes = useMemo(() => {
    if (!slot) {
      return {};
    }
    return {
      [formatDateToString(slot.date)!]: (slot.timeslotOvertimes || []).map((timeslotOvertime) => ({
        title: timeslotOvertime.overtime?.title ?? t('timeTracking:createTimeSlotModal.fields.regularHours'),
        id: timeslotOvertime.overtime?.id ?? 'null',
      })),
    };
  }, [slot, t]);

  return (
    <Form form={form}>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        title={
          <HStack justifyContent="space-between">
            <Text>
              {showProject
                ? t('timeTracking:editTimeSlotModal.title')
                : t('timeTracking:editTimeSlotModal.absentTitle')}
            </Text>
            <Button
              variant="outline"
              colorScheme="red"
              leftIcon={<SimpleDeleteIcon stroke="red.500" />}
              onClick={handleDelete}
            >
              {t('global:words.c.delete')}
            </Button>
          </HStack>
        }
        scrollBehavior="inside"
        size="2xl"
      >
        <VStack align="flex-start" spacing={4} my={3}>
          <Box w="100%">
            <RequiredText>
              <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.workers')}</ModalSubtitle>
            </RequiredText>

            <MultiAutocompleteField
              name="workerIds"
              w="100%"
              options={workerOptions}
              placeholder={t('timeTracking:createTimeSlotModal.fields.workersPlaceholder')}
              isDisabled
              variant="outlined"
            >
              <Rule.IsRequired />
            </MultiAutocompleteField>
          </Box>

          {showProject && (
            <ComponentTypeField<FormValues>
              label={t('global:batiprixEditConfigModal.componentTypeStep.workforceDisbursementType')}
              name="workforceComponentTypeId"
              workforce
              isRequired
            >
              <Rule.IsRequired />
            </ComponentTypeField>
          )}

          {showProject && (
            <Box w="100%">
              <RequiredText>
                <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.project')}</ModalSubtitle>
              </RequiredText>
              <ProjectField<ProjectFieldValues>
                isRequired={showProject}
                name={TIME_SLOT_FIELDS.PROJECT_ID}
                projects={projects}
                isDisabled
              />
            </Box>
          )}
          <Box>
            <RequiredText>
              <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.dateLabel')}</ModalSubtitle>
            </RequiredText>
            <DateRangeField<FormValues>
              name="date.0"
              mb={0}
              isDisabled
              label={t('timeTracking:createTimeSlotModal.fields.dateLabel')}
            >
              <Rule.IsRequired />
            </DateRangeField>
          </Box>

          <WorkingTimeForm
            slot={slot}
            defaultSelectedTimeslotOvertimes={defaultSelectedTimeslotOvertimes}
            showProject={showProject}
            overtimeTypes={overtimeTypes.data}
            isOff={!showProject}
          />

          {showProject && canAccessWorkerHourlyPrice && (
            <HStack spacing={4} w="full">
              <Box flex={1}>
                <RequiredText>
                  <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.hourlyPriceExVAT')}</ModalSubtitle>
                </RequiredText>
                <CurrencyField<FormValues> name="hourlyPriceExVAT" min={0} isRequired>
                  <Rule.IsRequired />
                </CurrencyField>
              </Box>
              <Box flex={1}>
                <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.mealExpense')}</ModalSubtitle>
                <CurrencyField<FormValues> min={0} name="mealExpense" />
              </Box>

              <Box flex={1}>
                <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.kmExpense')}</ModalSubtitle>
                <CurrencyField<FormValues> min={0} name="kmExpense" />
              </Box>
            </HStack>
          )}

          {!showProject && (
            <Box w="100%">
              <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.absenceType')}</ModalSubtitle>

              <SingleSelectField<FormValues>
                name="absenceType"
                options={Object.values(ABSENCE_TYPES).map((absenceType) => ({
                  label: t(`timeTracking:createTimeSlotModal.fields.absenceMotif.${absenceType}`),
                  value: absenceType,
                }))}
              />
            </Box>
          )}

          <Box w="100%">
            <ModalSubtitle>{t('timeTracking:createTimeSlotModal.fields.note')}</ModalSubtitle>
            <TextAreaField<FormValues> name="note" w="100%" inputProps={{ textAlign: 'left', fontWeight: 'normal' }} />
          </Box>
        </VStack>

        <Modal.Close />

        <Modal.PrimaryButton
          onClick={onSubmit}
          isLoading={isLoading}
          isDisabled={!isFormValid || !isTimeslotOvertimesValid}
        >
          {t('global:words.c.update')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
