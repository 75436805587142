import type { FC } from 'react';
import { createContext, useContext } from 'react';

import type { OptionType } from './Autocomplete.util';

export type IAutocompleteContext<Option extends OptionType, RenderProps> = FC<{ data: Option & RenderProps }>;

const AutocompleteContext = createContext<IAutocompleteContext<any, any>>(() => null);

export const useAutocompleteContext = <Option extends OptionType, RenderProps>(): IAutocompleteContext<
  Option,
  RenderProps
> => useContext(AutocompleteContext);
export const AutocompleteProvider = AutocompleteContext.Provider;
