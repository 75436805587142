import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { PaginatedRenderActionsProps } from '@graneet/lib-ui';
import { GraneetButton } from '@graneet/lib-ui';
import type { IBillListingResponseDTO } from '@graneet/business-logic';
import { FILTERING_PARAMS, EXPORT_ENTITY } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';
import qs from 'qs';

import { useBillReminderInfos, useMarkBillsAsReminded } from '../services/bill.api';

import { BillBatchStatusActions } from './BillBatchStatusActions';
import { BillRemindBatchButton } from './BillRemindBatchButton/BillRemindBatchButton';

import { ExportButton } from 'features/export/components/ExportButton/ExportButton';

export const BillBatchActions: FC<PaginatedRenderActionsProps<IBillListingResponseDTO> & { onUpdate: () => void }> = ({
  selectedItems,
  hasAllCheckboxesSelected,
  resetSelectedCheckboxes,
  currentFilters,
  onUpdate,
}) => {
  const { t } = useTranslation(['bill']);

  const paginatedDTO = useMemo(
    () => ({
      filters: qs.parse(currentFilters.toString()),
      selectedItems: selectedItems.map((item) => ({ id: item.id })),
      hasAllSelected: hasAllCheckboxesSelected,
      search: currentFilters.get(FILTERING_PARAMS.SEARCH) || undefined,
    }),
    [currentFilters, hasAllCheckboxesSelected, selectedItems],
  );

  const canBeRemind = useBillReminderInfos(paginatedDTO);

  const markBillsAsRemindedMutation = useMarkBillsAsReminded();

  const handleUpdate = useCallback(() => {
    resetSelectedCheckboxes();
    onUpdate();
  }, [onUpdate, resetSelectedCheckboxes]);

  const selectedItemsWithPaymentLate = useMemo(
    () =>
      selectedItems.map((entity) => ({
        ...entity,
        isPaymentLate: entity.lateDays > 0,
      })),
    [selectedItems],
  );

  const handleMarkBillsAsReminded = useCallback(async () => {
    await markBillsAsRemindedMutation.mutateAsync(paginatedDTO, {
      onSuccess: handleUpdate,
    });
  }, [markBillsAsRemindedMutation, handleUpdate, paginatedDTO]);

  return (
    <Flex gap={3}>
      <BillBatchStatusActions
        selectedItems={selectedItemsWithPaymentLate}
        hasAllCheckboxesSelected={hasAllCheckboxesSelected}
        currentFilters={currentFilters}
        onStatusesChanged={handleUpdate}
      />

      {canBeRemind.data.areAllRemindable && (
        <GraneetButton size="sm" onClick={handleMarkBillsAsReminded} isLoading={markBillsAsRemindedMutation.isPending}>
          {t('bill:markAsReminded')}
        </GraneetButton>
      )}

      <ExportButton
        entity={EXPORT_ENTITY.BILL}
        resetSelectedCheckboxes={handleUpdate}
        selectedItems={selectedItemsWithPaymentLate}
        hasAllCheckboxesSelected={hasAllCheckboxesSelected}
        currentFilters={currentFilters}
      />

      <BillRemindBatchButton
        canBeRemindData={canBeRemind.data}
        currentFilters={paginatedDTO}
        onBillUpdate={handleUpdate}
      />
    </Flex>
  );
};
