import type { ColDef } from '@ag-grid-community/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { QuoteBasicItemObject } from '@org/quotation-lib';

import { DenominationCell } from '../components/columns/DenominationCell/DenominationCell';

import { AmountCell } from 'features/quotation/ag-grid-quote-components-by-type/components/columns/AmountCell/AmountCell';
import { TextCell } from 'features/quotation/ag-grid-quote-components-by-type/components/columns/TextCell/TextCell';

export const useBasicItemsSupplyColumnDefs = () => {
  const { t } = useTranslation(['quote']);

  return useMemo(
    (): ColDef<QuoteBasicItemObject>[] => [
      {
        headerName: t('quote:quotation.columns.refCode'),
        field: 'refCode',
        cellRenderer: TextCell,
        cellStyle: { textAlign: 'center', paddingLeft: '16px' },
        editable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        resizable: false,
      },
      {
        headerName: t('quote:quotation.columns.denomination'),
        field: 'denomination',
        flex: 3,
        autoHeight: true,
        cellRenderer: DenominationCell,
        editable: false,
        resizable: false,
      },
      {
        headerName: t('quote:quotation.columns.unit'),
        field: 'unit',
        cellRenderer: TextCell,
        cellStyle: { textAlign: 'center' },
        editable: false,
        resizable: false,
      },
      {
        headerName: t('quote:quotation.columns.unitFlatCostAmount'),
        cellRenderer: AmountCell,
        field: 'unitFlatCostAmount',
        editable: false,
        resizable: false,
      },
      {
        headerName: t('quote:quotation.columns.quantity'),
        field: 'quantityFormula.quantity',
        colId: 'quantity',
        cellStyle: { textAlign: 'center' },
        cellRenderer: TextCell,
        editable: false,
        resizable: false,
      },
      {
        headerName: t('quote:quotation.columns.flatCostAmount'),
        field: 'flatCostAmount',
        cellRenderer: AmountCell,
        resizable: false,
      },
    ],
    [t],
  );
};
