import type { IEmailTemplate } from '@graneet/business-logic';
import type { FC, ReactNode } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Card, RichText, TemplateIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { getRichTextPluginOption } from '../services/email-template.util';

import { EmailTemplateTypeBadge } from './EmailTemplateTypeBadge';

interface EmailTemplateCardProps {
  emailTemplate: IEmailTemplate;

  topRightContent?: ReactNode;

  onClick?: () => void;
}

export const EmailTemplateCard: FC<EmailTemplateCardProps> = ({ emailTemplate, onClick, topRightContent }) => {
  const { t } = useTranslation(['global', 'mailing']);
  return (
    <Card
      title={
        <Flex alignItems="center">
          <TemplateIcon />
          <Text ml={2}>{emailTemplate.title}</Text>
        </Flex>
      }
      topRightContent={topRightContent}
      boxSize="100%"
      hasTitleSeparator
      onClick={onClick}
    >
      <Box h="8.5rem" overflow="hidden" position="relative">
        <Box color="black" fontWeight="500" mb={3}>
          <RichText
            key={emailTemplate.subject} // Force rerender on email template update
            value={emailTemplate.subject}
            configuration={[getRichTextPluginOption(emailTemplate.type, t)]}
          />
        </Box>

        <Box fontSize="sm">
          <RichText
            key={emailTemplate.template} // Force rerender on email template update
            value={emailTemplate.template}
            configuration={[getRichTextPluginOption(emailTemplate.type, t)]}
          />
        </Box>

        <Box
          position="absolute"
          bottom={0}
          mt={-8}
          h={8}
          w="100%"
          bgGradient="linear(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))"
        />
      </Box>

      <Box>
        <EmailTemplateTypeBadge type={emailTemplate.type} />
      </Box>
    </Card>
  );
};
