import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Image } from '@chakra-ui/react';
import type { ICompany } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { Card, Tooltip, SimpleHelpIcon, RICH_TEXT_INLINE_TOOLBAR_PRESET, RichTextField } from '@graneet/lib-ui';

import { SectionCompanyLegal } from './section/SectionCompanyLegal';

import { usePermissions } from 'features/role/hooks/usePermissions';

export type LegalMentionsForm = Pick<
  ICompany,
  'termsOfSales' | 'legalBillingNotice' | 'documentFooter' | 'quoteNotes' | 'orderNotes'
>;

export type SectionName = 'termsOfSales' | 'legalBillingNotice' | 'documentFooter' | 'quoteNotes' | 'orderNotes';

type CompanyLegalMentionsSectionFieldProps = {
  sectionName: SectionName;
};

export const CompanyLegalMentionsSectionField: FC<CompanyLegalMentionsSectionFieldProps> = ({ sectionName }) => {
  const { t } = useTranslation(['company']);

  const hasUpdateCompanyParametersPermission = usePermissions([PERMISSION.UPDATE_COMPANY_INFORMATIONS]);

  return (
    <SectionCompanyLegal
      title={t(`company:${sectionName}.title`)}
      description={t(`company:${sectionName}.explanation`)}
      topContent={
        <Tooltip
          label={
            <>
              <Trans t={t} i18nKey={`company:${sectionName}.tooltip`} />
              <Image mt={4} src={t(`company:${sectionName}.tooltipImageUrl`)} />
            </>
          }
          placement="right-end"
        >
          <Box>
            <SimpleHelpIcon boxSize={5} />
          </Box>
        </Tooltip>
      }
    >
      <Card>
        <RichTextField<LegalMentionsForm>
          inputProps={{ minH: '4rem' }}
          name={sectionName}
          navbarType="inline"
          label={t(`company:${sectionName}.label`)}
          isDisabled={!hasUpdateCompanyParametersPermission}
          configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
        />
      </Card>
    </SectionCompanyLegal>
  );
};
