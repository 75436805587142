import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useOnChangeValues, useStepForm } from 'graneet-form';
import { AmountSummary, Card, Section, useCurrency } from '@graneet/lib-ui';
import { Box, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import type { IDownPayment, IContractResponseDTO, ISubProject } from '@graneet/business-logic';
import {
  getDownPaymentAmounts,
  getAmountPercentage,
  getFloatingPercentage,
  DOWN_PAYMENT_TYPES,
  VAT_TYPE,
} from '@graneet/business-logic';

import { DownPaymentTypeRadioGroup } from 'features/down-payment/components/DownPaymentTypeRadioGroup';
import type { DownPaymentEditionWizard } from 'features/down-payment/forms/DownPaymentEditionWizard';

interface EditDownPaymentAmountsStepProps {
  downPayment?: IDownPayment | null;

  subProject: ISubProject;

  contracts: IContractResponseDTO[];
}

export const EditDownPaymentAmountsStep: FC<EditDownPaymentAmountsStepProps> = ({
  downPayment,
  subProject,
  contracts,
}) => {
  const { t } = useTranslation(['downPayment', 'project']);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();
  const { form, initFormValues } = useStepForm<DownPaymentEditionWizard, 'amounts'>();

  // Init values
  useEffect(() => {
    if (!downPayment) {
      initFormValues({
        type: DOWN_PAYMENT_TYPES.PERCENTAGE,
      });
      return;
    }

    const isPercentageType = downPayment.type === DOWN_PAYMENT_TYPES.PERCENTAGE;
    const { amountExVAT, percentage } = downPayment;

    initFormValues({
      type: downPayment.type,
      amount: !isPercentageType ? mapNumberToAmount(amountExVAT) : undefined,
      percentage: isPercentageType ? percentage : undefined,
    });
  }, [downPayment, initFormValues, mapNumberToAmount]);

  // Get values from form and compute amounts used in amount summary
  const { amount: amountExVAT, percentage, type } = useOnChangeValues(form, ['amount', 'percentage', 'type']);

  const partialDownPayment = useMemo(
    () => ({
      amountExVAT: mapAmountToNumber(amountExVAT || 0),
      percentage: percentage || 0,
      type,
    }),
    [amountExVAT, mapAmountToNumber, percentage, type],
  );

  const downPaymentAmounts = useMemo(
    () => getDownPaymentAmounts(partialDownPayment, subProject, contracts),
    [contracts, partialDownPayment, subProject],
  );

  useEffect(() => {
    if (type === DOWN_PAYMENT_TYPES.AMOUNT) {
      // Update percentage value when amounts are edited. We store a floating value for the % in the state
      // when the field is focused, we display the full floating number, else just 3 decimals
      const computedPercentage =
        getFloatingPercentage(mapAmountToNumber(amountExVAT || 0), subProject.amountExVAT) || 0;
      form.setFormValues({ percentage: computedPercentage });
    }
    if (type === DOWN_PAYMENT_TYPES.PERCENTAGE) {
      // Update amount value when percentage is edited
      const computedAmount = getAmountPercentage(subProject.amountExVAT, percentage);
      form.setFormValues({ amount: mapNumberToAmount(computedAmount) });
    }
  }, [amountExVAT, percentage, type, form, mapAmountToNumber, mapNumberToAmount, subProject.amountExVAT]);

  return (
    <Form form={form}>
      <Section
        title={t('downPayment:wizard.steps.amounts.title')}
        description={t('downPayment:wizard.steps.amounts.description', { subProjectName: subProject.name })}
      >
        <Card>
          <Wrap justify="space-between" spacing={3}>
            <WrapItem minW="30rem" flexGrow={1}>
              <DownPaymentTypeRadioGroup maxAmount={mapNumberToAmount(subProject.amountExVAT)} />
            </WrapItem>

            <WrapItem minW="30rem" flexGrow={2}>
              <VStack spacing={4} w="100%">
                <Box w="100%" bg="gray.100" borderRadius="md" py={2}>
                  <AmountSummary mt={0}>
                    <AmountSummary.Item
                      label={t('project:fields.projectAmountExVAT')}
                      amount={subProject.amountExVAT}
                      left
                      light
                    />
                    <AmountSummary.VATDistribution
                      left
                      vatType={subProject.hasReversalOfLiability ? VAT_TYPE.REVERSE_CHARGE : VAT_TYPE.NORMAL}
                      vatDistribution={subProject.contractsVATDistribution}
                      withCustomDiscountsLabel={
                        !!subProject.hasReversalOfLiability && !!subProject.contractsVATDistribution?.length
                      }
                    />
                    <AmountSummary.Item
                      label={t('project:fields.projectAmountIncVAT')}
                      amount={subProject.amountIncVAT}
                      left
                      light
                    />
                  </AmountSummary>
                </Box>

                <Box w="100%" bg="gray.100" borderRadius="md" py={2}>
                  <AmountSummary mt={0}>
                    <AmountSummary.Item
                      label={t('downPayment:fields.downPaymentAmountExVAT')}
                      amount={downPaymentAmounts.amountExVAT}
                      left
                      important
                    />
                    <AmountSummary.VATDistribution
                      left
                      vatType={subProject.hasReversalOfLiability ? VAT_TYPE.REVERSE_CHARGE : VAT_TYPE.NORMAL}
                      vatDistribution={downPaymentAmounts.vatDistribution}
                      withCustomDiscountsLabel={
                        !!subProject.hasReversalOfLiability && !!subProject.contractsVATDistribution?.length
                      }
                    />
                    <AmountSummary.Item
                      label={t('downPayment:fields.downPaymentAmountIncVAT')}
                      amount={downPaymentAmounts.amountIncVAT}
                      left
                      important
                    />
                  </AmountSummary>
                </Box>
              </VStack>
            </WrapItem>
          </Wrap>
        </Card>
      </Section>
    </Form>
  );
};
