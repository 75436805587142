import type { IProjectFinancialSummary } from '@graneet/business-logic';
import { PriceAdvanced } from '@graneet/lib-ui';
import type { FC } from 'react';

import { DisplayCellWithIndicator } from './DisplayCellWithIndicator';

export const ProjectTableMarginOnTotalCommittedExcludingVATCell: FC<{ data?: IProjectFinancialSummary }> = ({
  data,
}) => (
  <DisplayCellWithIndicator value={Number(data?.marginOnTotalCommittedExcludingVAT)}>
    <PriceAdvanced amount={Number(data?.marginOnTotalCommittedExcludingVAT)} />
  </DisplayCellWithIndicator>
);
