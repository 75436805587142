import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback } from 'react';

interface IsNotOneOfProps {
  values: (string | number)[];
  message?: string;
}

export const IsNotOneOf: FC<IsNotOneOfProps> = ({ values, message }) => {
  const { t } = useTranslation(['global']);

  const validationFn = useCallback(
    (fieldValue: FieldValue): boolean => !values.find((value) => value === fieldValue),
    [values],
  );

  return <Rule message={message || t('global:form.errors.isNotAvailable')} validationFn={validationFn} />;
};
