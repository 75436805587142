import { GRANEET_COLORS } from '@graneet/business-logic';

export const COLOR_PICKER_COLORS: Array<{ color: string; value: GRANEET_COLORS }> = [
  { color: 'green.300', value: GRANEET_COLORS.GREEN },
  { color: 'cyan.300', value: GRANEET_COLORS.CYAN },
  { color: 'blue.300', value: GRANEET_COLORS.BLUE },
  { color: 'indigo.300', value: GRANEET_COLORS.INDIGO },
  { color: 'purple.300', value: GRANEET_COLORS.PURPLE },
  { color: 'pink.300', value: GRANEET_COLORS.PINK },
  { color: 'red.300', value: GRANEET_COLORS.RED },
  { color: 'orange.300', value: GRANEET_COLORS.ORANGE },
  { color: 'yellow.300', value: GRANEET_COLORS.YELLOW },
  { color: 'gray.300', value: GRANEET_COLORS.WARM_GRAY },
];
