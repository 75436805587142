import { Card, LabeledData, Date, Price, TwoColumns, GotoLink } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IProgressStatement, ISubProject } from '@graneet/business-logic';
import { isSubProjectInDirectBilling, STATEMENT_TYPES } from '@graneet/business-logic';
import type { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { BillStatusBadge } from 'features/bill/components/BillStatusBadge';
import { InvoiceNumberLabeledData } from 'features/statement-ledger/components/InvoiceNumberLabeledData';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';

interface ProgressStatementDetailCardProps {
  progressStatement: IProgressStatement;

  subProject?: ISubProject | null;
}

export const ProgressStatementDetailCard: FC<ProgressStatementDetailCardProps> = ({
  progressStatement,
  subProject,
}) => {
  const { t } = useTranslation(['directProgressStatement', 'progressStatement', 'statement']);
  const { getTransitionPath } = useFiltersQuery();

  const isDirectBilling = isSubProjectInDirectBilling(subProject);
  const statementType = isDirectBilling
    ? STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT
    : STATEMENT_TYPES.PROGRESS_STATEMENT;

  return (
    <Card
      title={
        <Flex>
          {isDirectBilling ? t('directProgressStatement:detail.infoTitle') : t('progressStatement:titles.information')}
          {progressStatement.bill && (
            <GotoLink
              ml={2}
              to={getTransitionPath(`/sales/bills?focusedId=${progressStatement.bill.id}`).pathname}
              label={t('statement:goTo.bill')}
            />
          )}
        </Flex>
      }
    >
      <TwoColumns>
        <LabeledData
          label={t('progressStatement:fields.billingDate')}
          data={<Date>{progressStatement.billingDate}</Date>}
        />
        <LabeledData
          label={
            isDirectBilling
              ? t('directProgressStatement:fields.completionDate')
              : t('progressStatement:fields.acceptanceDate')
          }
          data={<Date>{progressStatement.acceptanceDate}</Date>}
        />
        <LabeledData
          data-testid="labelledData:progressStatement.detailCard.totalExVAT"
          label={t('progressStatement:fields.amountExVAT')}
          data={<Price amount={progressStatement.amountExVAT} />}
        />
        <InvoiceNumberLabeledData statement={progressStatement} statementType={statementType} />
        <LabeledData
          label={t('progressStatement:fields.billStatus')}
          data={progressStatement.bill && <BillStatusBadge status={progressStatement.bill.status} />}
        />
      </TwoColumns>
    </Card>
  );
};
