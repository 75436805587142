import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

export const BatiprixIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 18 20" {...props} stroke="none">
    <svg width="18" height="20" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8447 4.48191L9.22569 0.64645C8.91427 0.465995 8.5299 0.466816 8.21926 0.648601L1.66578 4.4836C1.15218 4.78415 1.1584 5.5286 1.67695 5.82053L8.23368 9.51172C8.53695 9.68245 8.90718 9.68323 9.21116 9.51377L15.8335 5.82207C16.3559 5.53091 16.3621 4.78172 15.8447 4.48191Z"
        fill="#0A6D72"
      />
      <path
        d="M9.55505 10.5324L16.3301 6.63719C16.844 6.34175 17.4851 6.71267 17.4851 7.30541V15.209C17.4851 15.4559 17.3532 15.6839 17.1392 15.8069L10.506 19.6205C9.9381 19.9471 9.22949 19.5371 9.22949 18.882V11.0951C9.22949 10.8628 9.35365 10.6482 9.55505 10.5324Z"
        fill="#40BCC8"
      />
      <path
        d="M0.325562 15.7952L7.1006 19.6905C7.61446 19.9859 8.25558 19.615 8.25558 19.0222V11.1186C8.25558 10.8718 8.12366 10.6438 7.90967 10.5207L1.27655 6.70709C0.708604 6.38056 0 6.79053 0 7.44565V15.2325C0 15.4648 0.12416 15.6794 0.325562 15.7952Z"
        fill="#11949C"
      />
    </svg>
  </Icon>
);
