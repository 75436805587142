import { Flex } from '@chakra-ui/react';
import type { FC } from 'react';

import type { RawCellProps } from './Cell.type';

export const RawCell: FC<RawCellProps> = ({ children, right = false, center = false, ...props }) => (
  <Flex
    alignItems="center"
    fontSize="sm"
    py={3}
    w="100%"
    minH="3.125rem"
    _groupHover={{ color: 'blue.800' }}
    justifyContent={(center && 'center') || (right && 'flex-end') || undefined}
    textAlign={(center && 'center') || (right && 'right') || undefined}
    {...props}
  >
    {children}
  </Flex>
);
