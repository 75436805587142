import { QuoteStatus } from '@org/graneet-bff-client';

export const QUOTE_FIELDS = {
  REF_CODE: 'refCode',
  NAME: 'name',
  DATE: 'createdAt',
  ADDED_AT: 'addedAt',
  CLIENT: 'client',
  AMOUNT_EX_VAT: 'amountExVAT',
  STATUS: 'status',
  PROJECT_ADDRESS: 'projectAddress',
  ACCOUNT_MANAGERS: 'quoteAccountManagers',
  QUOTE_VALIDITY_DURATION: 'quoteValidityDuration',
  DISPLAY_OPTIONAL_LINES_PDF: 'displayOptionalLinesPDF',
  DISPLAY_OPTIONAL_ANNEX_PDF: 'displayOptionalAnnexPDF',
  TAGS: 'tags',
};

export const QUOTE_STATUS_COLOR = {
  [QuoteStatus.Importing]: 'purple',
  [QuoteStatus.Draft]: 'gray',
  [QuoteStatus.Completed]: 'blue',
  [QuoteStatus.Accepted]: 'green',
  [QuoteStatus.Refused]: 'orange',
  LOST: 'orange',
  // TODO: This status is not used in the app
  [QuoteStatus.Archived]: 'white',
} as const;
