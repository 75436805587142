export const DEFAULT_SIZE = 50;
export const DEFAULT_PAGE = 1;

export enum SORTING_DIRECTIONS {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ORDER_BY_RANKS {
  USER = 0,
  PRIMARY = 1,
  SECONDARY = 2,
  THIRD = 3,
  FINAL = 4,
}

export enum PAGINATION_PARAMS {
  SIZE = '_size',
  PAGE = '_page',
  OFFSET = '_offset',
  // @ff quote-v2 clean V1 & V2
  OFFSET_V1 = '_offsetV1',
  OFFSET_V2 = '_offsetV2',
  FULL = '_full',
  INCREMENTAL = '_incremental',
}

export enum FILTERING_PARAMS {
  ORDER = '_order',
  DIRECTION = '_direction',
  SEARCH = '_search',
}

export enum FILTER_TYPES {
  STRING = 'string',
  DATE = 'date',
  NUMBER_RANGE = 'numberRange',
  BOOL = 'bool',
}

export enum FILTERING_METADATA_KEYS {
  SORTABLE = 'sortable',
  FILTERABLE = 'filterable',
  SEARCHABLE = 'searchable',
  SUMMABLE = 'summable',
}
