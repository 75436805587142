import type { FC } from 'react';
import { useCallback, useState } from 'react';
import type { GridItemProps } from '@chakra-ui/react';
import { Box, GridItem } from '@chakra-ui/react';

import { usePaginatedDataContext } from '../../PaginatedData/hooks';
import { Input } from '../../Input';
import type { Pagination } from '../../PaginatedTable/Pagination';
import { SEARCH_QUERY } from '../../PaginatedData/index';

export interface ListingLayoutSearchProps {
  placeholder?: string;

  pagination?: Pagination;
}

export const ListingLayoutSearch: FC<ListingLayoutSearchProps & GridItemProps> = ({ placeholder, pagination }) => {
  const {
    searchState: { setSearchTerm, searchTerm },
  } = usePaginatedDataContext();

  const [search, setSearch] = useState(
    pagination ? (new URLSearchParams(pagination.queryParams).get(SEARCH_QUERY) ?? '') : searchTerm,
  );

  const onChange = useCallback(
    (newSearch: string) => {
      setSearch(newSearch);
      setSearchTerm(newSearch);
      pagination?.updateSearch(newSearch);
    },
    [pagination, setSearchTerm],
  );

  return (
    <GridItem flex={1} alignSelf="center" ml={4}>
      <Box w="15rem">
        <Input
          leftIcon={<i className="ri-search-line" />}
          onChange={onChange}
          value={search}
          placeholder={placeholder}
        />
      </Box>
    </GridItem>
  );
};
