import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';
import { Text } from '@chakra-ui/react';

interface EmailTemplateCardDeleteModalProps {
  isOpen: boolean;

  onClose(): void;

  onDelete(): Promise<void>;
}
export const EmailTemplateCardDeleteModal: FC<EmailTemplateCardDeleteModalProps> = ({ isOpen, onClose, onDelete }) => {
  const { t } = useTranslation(['mailing']);

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    setIsLoading(true);
    await onDelete();
    setIsLoading(false);
  }, [onDelete]);

  return (
    <Modal title={t('mailing:emailTemplateDeleteModal.title')} isOpen={isOpen} onClose={onClose}>
      <Text>{t('mailing:emailTemplateDeleteModal.description')}</Text>

      <Modal.Close isDisabled={isLoading} />

      <Modal.PrimaryButton isLoading={isLoading} onClick={handleDelete} colorScheme="red">
        {t('mailing:emailTemplateDeleteModal.cta')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
