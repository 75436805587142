import type { RequiredByKeys } from '../common/types/object.type';
import type { ICredit } from '../credit/credit.type';
import type { IDownPayment } from '../down-payment/down-payment.type';
import type { IFile } from '../file/file.type';
import type { IInvoice } from '../invoice/invoice.type';
import type { IOrder } from '../order/order.type';
import type { IProgressStatement } from '../progress-statement/progress-statement.type';
import type { IQuote } from '../quote/quote.type';
import type { IUser } from '../user/user.type';

export enum EMAIL_RECIPIENT {
  TO = 'to',
  CC = 'cc',
  BCC = 'bcc',
}

export enum SENDGRID_MAIL_STATUS {
  DELIVERED = 'delivered',
  NOT_DELIVERED = 'not_delivered',
  PROCESSED = 'processed',
}

export enum MAIL_DELIVERY_STATUS {
  /**
   * Graneet status: Sendgrid API has been called and has accepted the email.
   */
  DISPATCHED = 'DISPATCHED',

  /**
   * Graneet status: Sendgrid API has been called but has failed
   */
  ERROR = 'ERROR',

  /**
   * Graneet status: Sendgrid returns a positive opens_count.
   */
  OPENED = 'OPENED',

  /**
   * Sendgrid status: SendGrid receives an individual message and prepares it to be delivered
   */
  PROCESSED = 'PROCESSED',

  /**
   * Sendgrid status: Sendgrid themselves have decided not to deliver the email
   */
  DROPPED = 'DROPPED',

  /**
   * Sendgrid status : delivered
   */
  DELIVERED = 'DELIVERED',

  /**
   * Sendgrid status: email cannot immediately be delivered, but it hasn’t been completely rejected.
   * Sendgrid will retry during 72 hours
   */
  DEFERRED = 'DEFERRED',

  /**
   * Sendgrid status: The receiving server denied the message,
   * and SendGrid will suppress the recipient’s email address going forward.
   */
  BOUNCED = 'BOUNCED',

  /**
   * Sendgrid status: The receiving server denied the message,
   * but SendGrid won’t suppress the email address.
   */
  BLOCKED = 'BLOCKED',
}

export enum EMAIL_ENTITY_TYPE {
  CREDIT = 'CREDIT',
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  INVOICE = 'INVOICE',
  ORDER = 'ORDER',
  PROGRESS_STATEMENT = 'PROGRESS_STATEMENT',
  QUOTE = 'QUOTE',
  QUOTATION = 'QUOTATION',
}

/**
 * There is one email entity per email sent, no matter how many recipients.
 * This entity stores info about the sender, the attachments and the email contents.
 */
export interface IEmail {
  id: string;

  /**
   * The entity from which the email was sent, i.e. quote
   */
  entityType: EMAIL_ENTITY_TYPE;

  senderEmail: string;

  senderFullName: string;

  attachments: IFile[];

  subject: string;

  bodyHtml: string;

  bodyLexical: string;

  // TODO after migration to QuoteV2 remove nullable
  quoteUUID: string | null;

  createdAt: Date;

  updatedAt: Date;

  quote?: IQuote | null;

  invoice?: IInvoice | null;

  order?: IOrder | null;

  downPayment?: IDownPayment | null;

  progressStatement?: IProgressStatement | null;

  credit?: ICredit | null;

  sender?: IUser | null;

  /* eslint-disable no-use-before-define */
  recipients?: IEmailRecipient[];
}

export type IEmailWithRelations = RequiredByKeys<IEmail, 'recipients'> & {
  /* eslint-disable no-use-before-define */
  files: Array<RequiredByKeys<IEmailFile, 'file'>>;
};

/**
 * n-n join between email and file entities
 */
export interface IEmailFile {
  readonly emailId?: IEmail['id'];

  email?: IEmail;

  readonly fileId?: IFile['id'];

  file?: IFile;

  createdAt: Date;

  updatedAt: Date;
}

/**
 * There is one EmailRecipient entity per recipient of an email.
 * So if you send an email with 4 recipients there will be 1 Email entity and 4 EmailRecipient entities linked to it.
 * The delivery status is stored here
 */
export interface IEmailRecipient {
  id: string;

  type: EMAIL_RECIPIENT;

  recipientEmail: string;

  recipientDisplayName: string | null;

  status: MAIL_DELIVERY_STATUS;

  createdAt: Date;

  updatedAt: Date;

  email?: IEmail;
}

export type ISenderDTO = { fullName: string; replyTo: string };

export type IRecipientDTO = Pick<IEmailRecipient, 'recipientEmail' | 'recipientDisplayName' | 'type'>;
