import type { FieldValues, AnyRecord } from 'graneet-form';
import { composeEventHandlers, Field, VALIDATION_OUTCOME } from 'graneet-form';

import type { KeysMatching } from '../../../utils/type.util';
import type { DateInputProps } from '../../Input';
import { DateInput } from '../../Input';

type DateFieldValue = string | undefined | null;

export interface DateFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, DateFieldValue> = KeysMatching<T, DateFieldValue>,
> extends Omit<DateInputProps, 'onChange' | 'value'> {
  name: K;

  data?: AnyRecord;

  hideErrorMessage?: boolean;

  onChange?(value: T[K]): void | Promise<void>;
}

export const DateField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, DateFieldValue> = KeysMatching<T, DateFieldValue>,
>({
  name,
  data,
  children,
  onChange,
  onBlur,
  onFocus,
  ...otherProps
}: DateFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    data={data}
    render={(properties, state) => {
      const { value, onChange: onChangeForm, onBlur: onBlurForm, onFocus: onFocusForm } = properties;
      const { validationStatus } = state;
      const shouldDisplayError = validationStatus.status === VALIDATION_OUTCOME.INVALID;

      return (
        <DateInput
          {...otherProps}
          errorMessage={validationStatus.message}
          shouldDisplayError={shouldDisplayError}
          value={value}
          onChange={composeEventHandlers(onChange, onChangeForm)}
          onBlur={composeEventHandlers(onBlur, onBlurForm)}
          onFocus={composeEventHandlers(onFocus, onFocusForm)}
        />
      );
    }}
  >
    {children}
  </Field>
);
