import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import { useChaptersContext } from '../contexts/ChaptersContext';

export interface ChapterProps {
  children: ReactNode;

  id: string;

  title: string;
}

export const Chapter: FC<ChapterProps> = ({ children, id, title }) => {
  const { registerChapter, unregisterChapter } = useChaptersContext();

  useEffect(() => {
    registerChapter(id, { title });

    return () => unregisterChapter(id);
  }, [registerChapter, id, title, unregisterChapter]);

  return (
    <Box id={id} boxSize="100%">
      {children}
    </Box>
  );
};
