import { Flex } from '@chakra-ui/react';
import { TextField } from '@graneet/lib-ui';

import type { QuoteEditLotStepForm, QuoteJobFormName, QuoteLotFormName } from '../../forms/quote-edit-lot-step.form';

import { useStore } from 'store/store';
import { useAutoNumberingQuoteRow } from 'features/quote/hooks/useAutoNumberingQuoteRow';
import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';

type QuoteAutoCodeProps = {
  type: 'job' | 'lot';
  id: number | string;
  name: QuoteLotFormName<'code'> | QuoteJobFormName<'code'>;
  data: {
    key: string;
    lotId: string;
  };
  handleOnFieldBlur?(): void;
  handleOnFieldFocus?(): void;
  inputProps: {
    borderBottomColor: string;
    fontSize: string;
  };
};

export const QuoteEditCodeRow = ({
  type,
  id,
  name,
  data,
  handleOnFieldBlur,
  handleOnFieldFocus,
  inputProps,
}: QuoteAutoCodeProps) => {
  const { isReadOnlyView } = useQuoteDisplayContext();

  const autoNumberingIsActivated = useStore((state) => state.autoNumberingIsActivated);

  const autoCode = useAutoNumberingQuoteRow({
    id: typeof id === 'string' ? id : id.toString(),
    type,
  });

  return (
    <>
      <Flex alignItems="center" fontSize="1rem" display={autoNumberingIsActivated ? 'flex' : 'none'}>
        {autoCode}
      </Flex>
      <TextField<QuoteEditLotStepForm>
        name={name}
        data={data}
        onBlur={handleOnFieldBlur}
        onFocus={handleOnFieldFocus}
        inputProps={inputProps}
        display={autoNumberingIsActivated ? 'none' : 'flex'}
        isReadOnly={isReadOnlyView}
        noBorder
      />
    </>
  );
};
