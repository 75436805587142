import type { StatusPanelDef } from '@ag-grid-community/core';
import { useMemo } from 'react';

import { QuoteSummaryBar } from '../../components/QuoteSummaryBar';

export const useStatusBar = () =>
  useMemo<{
    statusPanels: StatusPanelDef[];
  }>(
    () => ({
      statusPanels: [
        {
          statusPanel: QuoteSummaryBar,
        },
      ],
    }),
    [],
  );
