import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import type { IQuoteLotUpdateDTO } from '@graneet/business-logic';

import * as lotsApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Edit a lot.
 */
export const useEditLot = () => {
  const { t } = useTranslation(['quote']);
  const toast = useToast();
  const { emitLotUpdate, updateDataLocally, getCurrentQuoteData } = useQuoteEditContext();

  return useCallback(
    async (lotId: number, partialLot: IQuoteLotUpdateDTO, updateLocally = true) => {
      const { lots } = getCurrentQuoteData();
      const originalLot = { ...lots[lotId] };

      // Because the update has already taken place in the front,
      // we update the context first so the current value does not
      // replace the one our user has entered in the render following
      // the blur event.
      lots[lotId] = {
        ...originalLot,
        ...partialLot,
      };

      const [err, result] = await lotsApi.updateLot(lotId, partialLot);
      if (err) {
        // Replace a copy of the orignal job
        lots[lotId] = originalLot;
        toast.error(t('quote:errors.updatingError'));
        emitLotUpdate(lotId);
        return;
      }

      if (updateLocally) {
        updateDataLocally(result);
      }
    },
    [toast, t, emitLotUpdate, updateDataLocally, getCurrentQuoteData],
  );
};
