type EmptyStateTranslations = 'batiprix' | 'quote' | 'library';

type Translation = {
  subtitle: string;
  description: string;
};

export const emptyStateTranslations: Record<EmptyStateTranslations, Translation> = {
  batiprix: {
    subtitle: 'Importer depuis Batiprix',
    description: 'Importez des éléments depuis la Bibliothèque des composants',
  },
  quote: {
    subtitle: 'Importer depuis un devis',
    description: 'Importez des éléments depuis des devis que vous avez déjà créés',
  },
  library: {
    subtitle: 'Importer depuis Bibliothèque de prix',
    description: 'Importez des éléments depuis la Bibliothèque des composants',
  },
};

export const configureDefaultLabels = (translations: Record<EmptyStateTranslations, Translation>) => {
  Object.assign(emptyStateTranslations, translations);
};
