import { StepperModal } from '@graneet/lib-ui';
import type { IContact } from '@graneet/business-logic';
import type { FC } from 'react';
import { useFormStatus, useStepForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';

import type { ContactUpsertWizard } from '../ContactUpsertWizard';

import { ContactUpsertForm } from 'features/contact/components/forms/ContactUpsertForm';

export interface ContactUpsertStepProps {
  contact: IContact | null;
  roles: string[] | null;
}

export const ContactUpsertStep: FC<ContactUpsertStepProps> = ({ contact, roles }) => {
  const { t } = useTranslation(['contacts']);

  const { form } = useStepForm<ContactUpsertWizard, 'create-contact'>();
  const { isValid: isFormValid } = useFormStatus(form);

  return (
    <>
      <ContactUpsertForm form={form} roles={roles ?? []} contact={contact} hasIsDefaultEmailRecipient={false} />

      <StepperModal.CloseButton>{t('contacts:modal.contact.buttons.cancel')}</StepperModal.CloseButton>
      <StepperModal.PrimaryButton isDisabled={!isFormValid}>
        {t(contact ? 'contacts:modal.contact.buttons.nextUpdate' : 'contacts:modal.contact.buttons.next')}
      </StepperModal.PrimaryButton>
    </>
  );
};
