import type { CellClassParams, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';

import { CodeCell } from '../../components/columns/CodeCell/CodeCell';
import type { QuoteAGGridType } from '../../constants/agGridConstants';
import { CodeEditorCell } from '../../components/columns/CodeCell/CodeEditorCell';
import type { AGColDef } from '../../types';

import { useQuoteBasicItemUpdateManualIndex } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateManualIndex';
import { useQuoteComponentUpdateManualIndex } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateManualIndex';
import { useQuoteHiddenCostItemUpdateManualIndex } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemUpdateManualIndex';
import { useQuoteOptionalItemUpdateManualIndex } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateManualIndex';
import { useQuoteSubItemUpdateManualIndex } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdateManualIndex';
import { useQuoteLotUpdateManualIndex } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateManualIndex';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useCodeColumnDef(type: QuoteAGGridType, autoIndex: boolean, isEditable: boolean) {
  const quoteBasicItemUpdateManualIndex = useQuoteBasicItemUpdateManualIndex();
  const quoteOptionalItemUpdateManualIndex = useQuoteOptionalItemUpdateManualIndex();
  const quoteHiddenCostItemUpdateManualIndex = useQuoteHiddenCostItemUpdateManualIndex();
  const quoteSubItemUpdateManualIndex = useQuoteSubItemUpdateManualIndex();
  const quoteComponentUpdateManualIndex = useQuoteComponentUpdateManualIndex();
  const quoteLotUpdateManualIndex = useQuoteLotUpdateManualIndex();

  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'code',
      width,
      index,
      headerName: label,
      editable: isEditable && !autoIndex,
      resizable: true,
      cellClass: (params: CellClassParams) => {
        if (params.node.footer) return 'no-focus';
        return autoIndex ? 'no-focus read-only' : '';
      },
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressMovable: true,
      lockPosition: 'left',
      pinned: 'left',
      sortable: false,
      cellRenderer: CodeCell,
      cellEditor: CodeEditorCell,
      valueGetter: (params) => {
        if (!params.data) {
          return '';
        }

        switch (params.data.content.type) {
          case 'QuoteComponent':
          case 'QuoteBasicItem':
          case 'QuoteHiddenCostItem':
          case 'QuoteOptionalItem':
          case 'QuoteSubItem':
          case 'QuoteLot':
            return params.data.content.manualIndex;
          default:
            return '';
        }
      },
      valueSetter: (params) => {
        const newValue = params.newValue?.toString() === '' ? null : (params.newValue?.toString() ?? null);
        if ((params.oldValue?.toString() ?? '') === newValue) {
          return false;
        }

        if (autoIndex) {
          return false;
        }
        const { data } = params;
        switch (data.content.type) {
          case 'QuoteComponent':
            return executeCommand(quoteComponentUpdateManualIndex(data.id, newValue), quote);
          case 'QuoteBasicItem':
            return executeCommand(quoteBasicItemUpdateManualIndex(data.id, newValue), quote);
          case 'QuoteHiddenCostItem':
            return executeCommand(quoteHiddenCostItemUpdateManualIndex(data.id, newValue), quote);
          case 'QuoteOptionalItem':
            return executeCommand(quoteOptionalItemUpdateManualIndex(data.id, newValue), quote);
          case 'QuoteSubItem':
            return executeCommand(quoteSubItemUpdateManualIndex(data.id, newValue), quote);
          case 'QuoteLot':
            return executeCommand(quoteLotUpdateManualIndex(data.id, newValue), quote);
          default:
            break;
        }

        return true;
      },
      headerComponentParams: {
        label,
        type,
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          if (params.event.key === 'Tab') {
            const nextCell = params.api.getFocusedCell();
            const firstColumn = params.api
              .getColumns()
              ?.filter((col) => !col.isPinned())
              .shift();
            const denominationColumn = params.api.getColumn('denomination');
            const nextColumn = firstColumn ?? denominationColumn;
            if (nextCell) {
              params.api.setFocusedCell(nextCell.rowIndex, nextColumn!, null);
              params.api.startEditingCell({
                colKey: nextColumn!,
                rowIndex: nextCell.rowIndex,
              });
            }
            return true;
          }
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [
      isEditable,
      autoIndex,
      type,
      executeCommand,
      quoteComponentUpdateManualIndex,
      quote,
      quoteBasicItemUpdateManualIndex,
      quoteHiddenCostItemUpdateManualIndex,
      quoteOptionalItemUpdateManualIndex,
      quoteSubItemUpdateManualIndex,
      quoteLotUpdateManualIndex,
    ],
  );
}
