import type { QuoteNodeObject } from '@org/quotation-lib';
import type { RowDragMoveEvent } from '@ag-grid-community/core';
import type { AgGridReact } from '@ag-grid-community/react';
import type { RefObject } from 'react';
import { useCallback } from 'react';

import { DragDirection, LIMIT_LOWER_DRAG_ZONE, LIMIT_UPPER_DRAG_ZONE } from './onRowDragConstant';
import { useOnRowDragPositionInPercentage } from './useOnRowDragPositionInPercentage';
import { setPotentialTargetForNode } from './onRowDragRefreshCell';

import { useQuoteNodeCanBeMovedUpper } from 'features/quotation/quote-node/hooks/useQuoteNodeCanBeMovedUpper';
import { useQuoteNodeCanBeMovedInside } from 'features/quotation/quote-node/hooks/useQuoteNodeCanBeMovedInside';
import { useQuoteNodeCanBeMovedLower } from 'features/quotation/quote-node/hooks/useQuoteNodeCanBeMovedLower';

function determineDragDirection(percentage: number): DragDirection {
  if (percentage < LIMIT_LOWER_DRAG_ZONE) {
    return DragDirection.LOWER;
  }
  if (percentage > LIMIT_UPPER_DRAG_ZONE) {
    return DragDirection.UPPER;
  }
  return DragDirection.INSIDE;
}

export const useOnRowDragMove = (gridRef: RefObject<AgGridReact<QuoteNodeObject>>) => {
  const onRowDragPositionInPercentage = useOnRowDragPositionInPercentage();
  const quoteNodeCanBeMovedUpper = useQuoteNodeCanBeMovedUpper();
  const quoteNodeCanBeMovedLower = useQuoteNodeCanBeMovedLower();
  const quoteNodeCanBeMovedInside = useQuoteNodeCanBeMovedInside();

  return useCallback(
    (event: RowDragMoveEvent<QuoteNodeObject>) => {
      const movingNode = event.node;
      const { overNode } = event;

      if (!gridRef.current?.api) {
        return;
      }

      if (!overNode) {
        setPotentialTargetForNode(gridRef.current?.api, null, null);
        return;
      }

      const percentage = onRowDragPositionInPercentage(movingNode, overNode, event);

      if (percentage === null) {
        setPotentialTargetForNode(gridRef.current?.api, null, null);
        return;
      }

      const newPotentialZone = determineDragDirection(percentage);

      if (newPotentialZone === DragDirection.UPPER && quoteNodeCanBeMovedUpper(movingNode, overNode)) {
        setPotentialTargetForNode(gridRef.current?.api, overNode, newPotentialZone);
        return;
      }

      if (newPotentialZone === DragDirection.LOWER && quoteNodeCanBeMovedLower(movingNode, overNode)) {
        setPotentialTargetForNode(gridRef.current?.api, overNode, newPotentialZone);
        return;
      }

      if (newPotentialZone === DragDirection.INSIDE && quoteNodeCanBeMovedInside(movingNode, overNode)) {
        setPotentialTargetForNode(gridRef.current?.api, overNode, newPotentialZone);
        return;
      }

      setPotentialTargetForNode(gridRef.current?.api, null, null);
    },
    [
      gridRef,
      onRowDragPositionInPercentage,
      quoteNodeCanBeMovedInside,
      quoteNodeCanBeMovedLower,
      quoteNodeCanBeMovedUpper,
    ],
  );
};
