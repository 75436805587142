import type { QuoteNodeObject } from '@org/quotation-lib';
import type { IRowNode, GridApi, RefreshCellsParams } from '@ag-grid-community/core';

import type { DragDirection } from './onRowDragConstant';

// eslint-disable-next-line import/no-mutable-exports
export let potentialTarget: IRowNode<QuoteNodeObject> | null = null;
// eslint-disable-next-line import/no-mutable-exports
export let potentialZone: DragDirection | null = null;

function refreshRows(api: GridApi, rowsToRefresh: IRowNode[]) {
  const params: RefreshCellsParams = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
}

export function setPotentialTargetForNode(
  api: GridApi,
  overNode: IRowNode | undefined | null,
  newPotentialZone: DragDirection | null,
) {
  let newPotentialTarget: IRowNode<QuoteNodeObject> | null = null;

  if (overNode) {
    newPotentialTarget = overNode;
  }

  const rowsToRefresh = [];

  if (potentialTarget) {
    rowsToRefresh.push(potentialTarget);
  }
  if (newPotentialTarget) {
    rowsToRefresh.push(newPotentialTarget);
  }
  potentialTarget = newPotentialTarget;
  potentialZone = newPotentialZone;
  refreshRows(api, rowsToRefresh);
}
