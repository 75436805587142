import type { FC, ReactElement } from 'react';
import type { FlexProps, StyleProps } from '@chakra-ui/react';
import { Box, Flex } from '@chakra-ui/react';
import { cloneElement } from 'react';

import { SimpleLightBulbIcon } from '../Icons';

const ICON_SIZE = 6;

export type CalloutColorScheme = 'blue' | 'orange' | 'red' | 'green' | 'yellow' | 'greenBrand' | 'blueLight' | 'gray';
type CalloutColors = Record<
  CalloutColorScheme,
  { bg: StyleProps['color']; icon: StyleProps['color']; text: StyleProps['color']; hover: StyleProps['color'] }
>;

export const CALLOUT_COLORS: CalloutColors = {
  blue: {
    bg: 'blue.100',
    icon: 'greenBrand.light',
    text: 'blue.800',
    hover: 'greenBrand.light',
  },
  blueLight: {
    bg: 'blue.100',
    icon: 'blue.500',
    text: 'blue.500',
    hover: 'blue.200',
  },
  gray: {
    bg: 'gray.100',
    icon: 'gray.500',
    text: 'gray.800',
    hover: 'gray.200',
  },
  greenBrand: {
    bg: 'greenBrand.light',
    icon: 'white',
    text: 'white',
    hover: 'greenBrand.light',
  },
  orange: {
    bg: 'orange.100',
    icon: 'orange.500',
    text: 'orange.800',
    hover: 'orange.200',
  },
  red: {
    bg: 'red.100',
    icon: 'red.500',
    text: 'red.800',
    hover: 'red.200',
  },
  green: {
    bg: 'green.100',
    icon: 'green.500',
    text: 'green.800',
    hover: 'green.200',
  },
  yellow: {
    bg: 'yellow.100',
    icon: 'yellow.500',
    text: 'yellow.800',
    hover: 'yellow.200',
  },
};

export interface CalloutProps extends FlexProps {
  icon?: ReactElement | null;
  colorScheme?: CalloutColorScheme;
}

export const Callout: FC<CalloutProps> = ({
  children = null,
  icon = <SimpleLightBulbIcon boxSize={ICON_SIZE} />,
  colorScheme = 'blue',
  ...rest
}) => {
  const colors = CALLOUT_COLORS[colorScheme];

  return (
    <Flex bg={colors.bg} borderRadius="md" p={3} {...rest}>
      {icon !== null && (
        <Flex alignSelf="flex-start" justifyContent="center" color={colors.icon} mr={3}>
          {cloneElement(icon, { stroke: colors.icon })}
        </Flex>
      )}

      <Box color={colors.text} flex={1}>
        {children}
      </Box>
    </Flex>
  );
};
