import type { IQuote, IQuoteComponent, IQuoteJob, IQuoteLot } from '@graneet/business-logic';

export type QuoteKey = 'quote';

export const BUILD_QUOTE_SIGNAL_KEY = (): QuoteKey => 'quote';

export type QuoteLotKey = `lot-${number}`;

export const BUILD_LOT_SIGNAL_KEY = (lotId: number): QuoteLotKey => `lot-${lotId}`;

export type QuoteRootLotKey = `ROOT-lot-ID`;
export const BUILD_ROOT_LOT_ID_SIGNAL_KEY = (): QuoteRootLotKey => `ROOT-lot-ID`;

export type QuoteJobKey = `job-${number}`;
export const BUILD_JOB_SIGNAL_KEY = (jobId: number): QuoteJobKey => `job-${jobId}`;

export type QuoteComponentsKey = 'component';
export const BUILD_COMPONENTS_SIGNAL_KEY = (): QuoteComponentsKey => 'component';

export interface QuoteListenerMap {
  quote: IQuote;

  [quoteLot: QuoteLotKey]: IQuoteLot;

  'ROOT-lot-ID': number;

  [quoteItem: QuoteJobKey]: IQuoteJob;

  component: IQuoteComponent[];
}
