import type { IVatBases, IVatDiscountedBases } from '../vat/vat-bases.type';
import { dispatchDiscountProrata, getDiscountAmountExVAT } from '../discount/discount.util';
import { isNil } from '../common/object.util';
import { computeAmountOfVATBase, mergeVATDistribution } from '../vat/vat-rates.util';
import type { IVatDiscountedBase } from '../vat/vat-base.type';
import type { IProgressStatementLine } from '../progress-statement/progress-statement-line.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IProgressStatementDiscountLine } from '../progress-statement/progress-statement-discount-line.type';
import type { IItem } from '../item/item.type';
import type { IProgressStatement } from '../progress-statement/progress-statement.type';
import type { ISubProject } from '../sub-project/sub-project.type';
import { SUB_PROJECT_BILLING_TYPE } from '../sub-project/sub-project.type';

import type { IContract } from './contract.type';
import type { IContainerRelations } from './dtos';

export const getContractsVATDistributionWithDiscountedBases = (
  contracts: Array<
    Pick<IContract, 'vatDistribution' | 'discount' | 'customDiscounts' | 'totalAmountWithoutDiscountExVAT'>
  >,
): IVatDiscountedBases => {
  const contractsVATDistributionWithDiscountedBases = contracts.map<IVatDiscountedBases>(
    ({ vatDistribution, discount, customDiscounts, totalAmountWithoutDiscountExVAT }) => {
      // Get contract total discount amount

      const discountAmount = getDiscountAmountExVAT(discount, totalAmountWithoutDiscountExVAT);
      const discountedBases = dispatchDiscountProrata(vatDistribution as IVatBases, discountAmount, 'base');

      // Make sure custom discounts is an array and passed to this function
      if (!Array.isArray(customDiscounts)) {
        throw new Error('Custom discounts should be an array');
      }
      // Take custom discounts into account
      const vatCustomDiscountedBases = customDiscounts.map(({ vatRate, amountExVAT }) => ({
        base: 0,
        vatRate: isNil(vatRate) ? 0 : vatRate,
        discountedBase: amountExVAT || 0,
        amount: computeAmountOfVATBase({ vatRate, base: amountExVAT || 0 }),
      }));
      const discountedBasedMapped = discountedBases.map<IVatDiscountedBase>(([vatBase, discountedBase]) => ({
        ...vatBase,
        // We store raw discounted base
        discountedBase,
      }));
      return mergeVATDistribution(discountedBasedMapped.concat(vatCustomDiscountedBases));
    },
  );

  // contractsVATDistributionWithDiscountedBases is an array of contracts vat distributions
  // we need to flatten the array to merge its vat component
  const vatDistributionWithDiscountedBases = contractsVATDistributionWithDiscountedBases.flat(1);

  // Merging contracts vatDistribution bases and discounted bases (unrounded)
  return mergeVATDistribution(vatDistributionWithDiscountedBases);
};

const findLinesComingFromContract = (
  lines: IProgressStatementLine[],
  contract: RequiredByKeys<IContract, 'container'>,
): IProgressStatementLine[] => lines.filter((line) => line.item.container.id === contract.container.id);

const findDiscountLineComingFromContract = (
  discountLines: IProgressStatementDiscountLine[],
  contract: IContract,
): IProgressStatementDiscountLine | undefined =>
  discountLines.find((discountLine) => discountLine.contract.id === contract.id);

export const checkIfDiscountHasBeenInvoiced = (
  discountLines: IProgressStatementDiscountLine[],
  contract: IContract,
): boolean => (findDiscountLineComingFromContract(discountLines, contract)?.cumulativeAmountExVAT || 0) !== 0;

export const checkIfItemHasBeenInvoiced = (lines: IProgressStatementLine[], itemId: number): boolean => {
  if (lines.length === 0) return false;
  const linesFromItem = lines.filter((line) => line.item.id === itemId && (line.cumulativeAmountExVAT || 0) !== 0);

  return linesFromItem.length !== 0;
};

export const getLotInvoicedAmount = (
  linesByItemId: Record<IItem['id'], IProgressStatementLine>,
  lotId: number,
  relations: IContainerRelations,
): number => {
  const {
    [lotId]: { items, lots },
  } = relations;

  return (
    items.reduce((acc, id) => acc + (linesByItemId[id]?.cumulativeAmountExVAT ?? 0), 0) +
    lots.reduce((acc, id) => acc + getLotInvoicedAmount(linesByItemId, id, relations), 0)
  );
};

/**
 * For each PS without draft status,
 * If one PS contain PSLines or PSDiscountLines cumulative amount !== 0 from contract,
 * it means it was invoiced.
 */
export const checkIfContractHasBeenInvoiced = (
  progressStatement: IProgressStatement | undefined | null,
  contract: RequiredByKeys<IContract, 'container'>,
): boolean => {
  if (!progressStatement) {
    return false;
  }
  const { lines, discountLines } = progressStatement;
  const linesFromContract = findLinesComingFromContract(lines ?? [], contract);
  const discountLineFromContract = findDiscountLineComingFromContract(discountLines ?? [], contract);

  // A contract is invoiced if there is at least one line/discount line which is invoiced
  const lineInvoiced = linesFromContract.find((line) => (line.cumulativeAmountExVAT || 0) !== 0);
  const isDiscountInvoiced = !!discountLineFromContract && discountLineFromContract.cumulativeAmountExVAT !== 0;
  return !!lineInvoiced || isDiscountInvoiced;
};

/*
  There is two invalid cases:
    - If subproject has direct billing, if contract is invoiced
    - If subproject has progress billing,there is multiple VATs, there is a discount and the contract is invoiced
 */
export const areContractAmountsEditable = (
  contract: IContract,
  subProject: ISubProject,
  lastInvoicedProgressStatement: IProgressStatement | undefined | null,
): boolean => {
  if (subProject.billingType === SUB_PROJECT_BILLING_TYPE.DIRECT) {
    return !lastInvoicedProgressStatement;
  }

  if (subProject.billingType === SUB_PROJECT_BILLING_TYPE.PROGRESS) {
    const hasDiscount = !!contract.discount;
    const hasMultipleVAT = contract.vatDistribution.length > 1;
    const isDiscountInvoiced =
      !!lastInvoicedProgressStatement &&
      checkIfDiscountHasBeenInvoiced(lastInvoicedProgressStatement.discountLines || [], contract);
    const isInvalidCase = hasDiscount && hasMultipleVAT && isDiscountInvoiced;
    return !isInvalidCase;
  }
  return true;
};

export const canContractReversalOfLiabilityBeChanged = (
  contract: RequiredByKeys<IContract, 'container'>,
  lastProgressStatement: IProgressStatement | undefined | null,
  projectContracts: IContract[],
): boolean => {
  const hasInvoicedItems = checkIfContractHasBeenInvoiced(lastProgressStatement, contract);
  return projectContracts.length === 1 && !hasInvoicedItems;
};
