import { useEffect, useCallback, useState } from 'react';
import { ValuesProvider, useValuesContext, StepperModal, STORAGE_STRATEGY, usePaginatedData } from '@graneet/lib-ui';
import type { Response, PaginatedResponse } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, HiddenField, useStepForm } from 'graneet-form';
import type { IContact } from '@graneet/business-logic';

import type { ContractSelectionWizard } from '../ContractSelectionWizard';

import { ContactAvailableDataProviderForm } from 'features/contact/components/forms/ContactAvailableDataProviderForm';

interface ContactSelectionProps<T extends IContact> {
  onClose: () => void;
  contactsRetriever: [
    Record<string, string | number>,
    (queryParams: URLSearchParams) => Promise<Response<PaginatedResponse<T>>>,
  ];
  isContactDefaultEmailRecipient: (contact: T) => boolean;
}

const RawContactSelection = <T extends IContact>({
  onClose,
  contactsRetriever,
  isContactDefaultEmailRecipient,
}: ContactSelectionProps<T>) => {
  const { t } = useTranslation(['contacts']);

  const { form } = useStepForm<ContractSelectionWizard, 'associate-contact-client'>();

  const { resetValues, getValues, numberOfValues } = useValuesContext<string>();
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const data = usePaginatedData(contactsRetriever[1], contactsRetriever[0], undefined, STORAGE_STRATEGY.STATE);

  const resetAll = useCallback(() => {
    setIsAllChecked(false);
    resetValues();
  }, [resetValues]);

  const handleOnClose = useCallback(() => {
    resetAll();
    onClose();
  }, [onClose, resetAll]);

  // Update form when contact is selected
  useEffect(() => {
    form.setFormValues({
      contacts: getValues().map((id) => {
        const entity = (data.data || []).find((d) => d.id === id)!;
        return {
          entity,
          isDefaultEmailRecipient: isContactDefaultEmailRecipient(entity),
        };
      }),
    });
  }, [data.data, form, getValues, isContactDefaultEmailRecipient]);

  return (
    <Form form={form} style={{ width: '100%' }}>
      <HiddenField<ContractSelectionWizard['associate-contact-client']> name="contacts" />

      <ContactAvailableDataProviderForm
        data={data}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        isContactDefaultEmailRecipient={isContactDefaultEmailRecipient}
      />

      <StepperModal.SecondaryButton onClick={handleOnClose}>
        {t('contacts:modal.associate.buttons.cancel')}
      </StepperModal.SecondaryButton>
      <StepperModal.PrimaryButton isDisabled={numberOfValues() === 0}>
        {numberOfValues() <= 1 && t('contacts:availableModal.buttons.associate')}
        {numberOfValues() > 1 && t('contacts:availableModal.buttons.associateMulti')}
      </StepperModal.PrimaryButton>
    </Form>
  );
};

export const ContactSelectionStep = <T extends IContact>(props: ContactSelectionProps<T>) => (
  <ValuesProvider>
    <RawContactSelection {...props} />
  </ValuesProvider>
);
