import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import { Card, GotoLink } from '@graneet/lib-ui';
import type { IProject, ISubProject, RequiredByKeys } from '@graneet/business-logic';

import { ProjectFieldOption } from 'features/project/components/ProjectField/ProjectFieldOption';

interface ProjectAssociationCardProps {
  project?: RequiredByKeys<IProject, 'address'> | null;

  subProject?: ISubProject | null;
}
export const ProjectAssociationCard: FC<ProjectAssociationCardProps> = ({ project, subProject }) => {
  const { t } = useTranslation(['project']);

  return (
    <Card
      title={
        <Flex mb={3}>
          <Text mr={4}>{t('project:associateProjectCard.title')}</Text>
          {project?.id && <GotoLink to={`/projects/${project.id}`} label={t('project:associateProjectCard.goTo')} />}
        </Flex>
      }
    >
      {project && <ProjectFieldOption project={project} subProject={subProject} />}

      {!project && <Text mt={2}>{t('project:associateProjectCard.noAssociateProject')}</Text>}
    </Card>
  );
};
