import type { EmailTemplateType, IEmailTemplate, RequiredByKeys } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback } from 'react';
import { ActionMenu } from '@graneet/lib-ui';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import {
  useCreateEmailTemplate,
  useDeleteEmailTemplate,
  useUpdateEmailTemplate,
} from '../../services/email-template.api';
import { EmailTemplateEditModal, type EmailTemplateEditModalForm } from '../EmailTemplateEditModal';
import { useEmailTemplateQuota } from '../../hooks/useEmailTemplateQuota';
import { EmailTemplateCard } from '../EmailTemplateCard';

import { EmailTemplateCardDeleteModal } from './EmailTemplateCardDeleteModal';

interface EmailTemplateCardWithActionsProps {
  emailTemplates: IEmailTemplate[];

  emailTemplate: RequiredByKeys<IEmailTemplate, 'reminders'>;
}

export const EmailTemplateCardWithActions: FC<EmailTemplateCardWithActionsProps> = ({
  emailTemplates,
  emailTemplate,
}) => {
  const { t } = useTranslation(['global', 'mailing']);

  const emailTemplateQuota = useEmailTemplateQuota(emailTemplates);

  const createEmailTemplateMutation = useCreateEmailTemplate({ type: 'duplication' });
  const editEmailTemplateMutation = useUpdateEmailTemplate();
  const deleteEmailTemplateMutation = useDeleteEmailTemplate();

  const deleteModal = useDisclosure();
  const editModal = useDisclosure();

  const onDelete = useCallback(async () => {
    await deleteEmailTemplateMutation.mutateAsync(emailTemplate);
  }, [deleteEmailTemplateMutation, emailTemplate]);

  const onEdit = useCallback(
    async (
      formValues: EmailTemplateEditModalForm,
      type: EmailTemplateType,
      html: { subject: string; template: string },
    ) => {
      await editEmailTemplateMutation.mutateAsync({
        id: emailTemplate.id,
        dto: {
          ...formValues,
          type,
          templateHTML: html.template,
          subjectHTML: html.subject,
        },
      });
      editModal.onClose();
    },
    [editEmailTemplateMutation, editModal, emailTemplate.id],
  );

  const onDuplicate = useCallback(async () => {
    await createEmailTemplateMutation.mutateAsync({
      type: emailTemplate.type,
      title: t('global:copyOf', { name: emailTemplate.title }),
      subject: emailTemplate.subject,
      subjectHTML: emailTemplate.subjectHTML,
      template: emailTemplate.template,
      templateHTML: emailTemplate.templateHTML,
      isDefault: false,
    });
    editModal.onClose();
  }, [
    createEmailTemplateMutation,
    editModal,
    emailTemplate.subject,
    emailTemplate.subjectHTML,
    emailTemplate.template,
    emailTemplate.templateHTML,
    emailTemplate.title,
    emailTemplate.type,
    t,
  ]);

  return (
    <>
      <EmailTemplateCard
        emailTemplate={emailTemplate}
        topRightContent={
          <Flex alignItems="center">
            {emailTemplate.isDefault ? <StarIcon color="yellow.500" mr={3} /> : undefined}
            <ActionMenu>
              <ActionMenu.Edit onClick={editModal.onOpen} />
              <ActionMenu.Duplicate
                onClick={onDuplicate}
                isDisabled={emailTemplateQuota.hasReachQuota}
                tooltip={emailTemplateQuota.hasReachQuota ? t('mailing:tooltips.quotaReached') : undefined}
              />
              <ActionMenu.Delete
                isDisabled={emailTemplate.reminders.length > 0}
                tooltip={emailTemplate.reminders.length > 0 ? t('mailing:tooltips.cannotDelete') : undefined}
                onClick={deleteModal.onOpen}
              />
            </ActionMenu>
          </Flex>
        }
        onClick={editModal.onOpen}
      />

      <EmailTemplateEditModal
        type={emailTemplate.type}
        isOpen={editModal.isOpen}
        onClose={editModal.onClose}
        onSubmit={onEdit}
        initialValues={emailTemplate}
      />

      <EmailTemplateCardDeleteModal onDelete={onDelete} isOpen={deleteModal.isOpen} onClose={deleteModal.onClose} />
    </>
  );
};
