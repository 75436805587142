import type {
  ILibraryJobsAddToQuoteLotDTO,
  IMoveQuoteLotDTO,
  IQuoteComponent,
  IQuoteDefaultVatRateUpdateDTO,
  IQuoteInfosResponse,
  IQuoteLotImportJobsFromBatiprixDTO,
  IQuoteLotOptionalDTO,
  IQuoteLotUpdateDTO,
  IQuoteLotSellsheetAmountDTO,
  IQuoteSellsheetComponentMarginDTO,
  IQuoteUpdateResponse,
  IUpdateTotalMarginDTO,
} from '@graneet/business-logic';

import { api } from 'features/api/services/api.service';

const QUOTE_LOT_PATH = '/quote-lots';

export const updateLot = (lotId: number, quoteLotUpdateDTO: IQuoteLotUpdateDTO) =>
  api.patch<IQuoteLotUpdateDTO, IQuoteUpdateResponse>(`${QUOTE_LOT_PATH}/${lotId}`, quoteLotUpdateDTO);

export const updateLotMarginSellSheet = (lotId: number, updateTotalMarginDTO: IUpdateTotalMarginDTO) =>
  api.patch<IUpdateTotalMarginDTO, IQuoteUpdateResponse>(
    `${QUOTE_LOT_PATH}/${lotId}/sellsheet/margin`,
    updateTotalMarginDTO,
  );

export const previewLotMarginSellSheet = (lotId: number, updateTotalMarginDTO: IUpdateTotalMarginDTO) =>
  api.post<IUpdateTotalMarginDTO, IQuoteUpdateResponse>(
    `${QUOTE_LOT_PATH}/${lotId}/sellsheet/margin/preview`,
    updateTotalMarginDTO,
  );

export const updateLotAmountSellSheet = (lotId: number, quoteLotSellsheetAmountDTO: IQuoteLotSellsheetAmountDTO) =>
  api.patch<IQuoteLotSellsheetAmountDTO, IQuoteUpdateResponse>(
    `${QUOTE_LOT_PATH}/${lotId}/sellsheet/amount`,
    quoteLotSellsheetAmountDTO,
  );

export const previewLotAmountSellSheet = (lotId: number, quoteLotSellsheetAmountDTO: IQuoteLotSellsheetAmountDTO) =>
  api.post<IQuoteLotSellsheetAmountDTO, IQuoteUpdateResponse>(
    `${QUOTE_LOT_PATH}/${lotId}/sellsheet/amount/preview`,
    quoteLotSellsheetAmountDTO,
  );

export const updateLotComponentTypeMarginSellSheet = (
  lotId: number,
  componentTypeId: number,
  payload: IQuoteSellsheetComponentMarginDTO,
) =>
  api.patch<IQuoteSellsheetComponentMarginDTO, IQuoteUpdateResponse>(
    `${QUOTE_LOT_PATH}/${lotId}/sellsheet/componentType/${componentTypeId}/margin`,
    payload,
  );

export const previewLotComponentTypeMarginSellSheet = (
  lotId: number,
  componentTypeId: number,
  quoteSellsheetComponentMarginDTO: IQuoteSellsheetComponentMarginDTO,
) =>
  api.post<IQuoteSellsheetComponentMarginDTO, IQuoteUpdateResponse>(
    `${QUOTE_LOT_PATH}/${lotId}/sellsheet/componentType/${componentTypeId}/margin/preview`,
    quoteSellsheetComponentMarginDTO,
  );

export const updateLotDefaultVatRate = (lotId: number, payload: IQuoteDefaultVatRateUpdateDTO) =>
  api.patch<IQuoteDefaultVatRateUpdateDTO, IQuoteUpdateResponse>(
    `${QUOTE_LOT_PATH}/${lotId}/default-vat-rate`,
    payload,
  );

export const createLot = (lotId: number, previousLotId?: number) =>
  api.post<never, IQuoteUpdateResponse>(
    previousLotId
      ? `${QUOTE_LOT_PATH}?lotId=${lotId}&previousLotId=${previousLotId}`
      : `${QUOTE_LOT_PATH}?lotId=${lotId}`,
  );

export const deleteLot = (lotId: number) => api.delete<never, IQuoteUpdateResponse>(`${QUOTE_LOT_PATH}/${lotId}`);

export const duplicateLot = (lotId: number) =>
  api.post<never, IQuoteInfosResponse>(`${QUOTE_LOT_PATH}/${lotId}/duplicate`);

export const importLibraryJobLot = (lotId: number, libraryJobsAddToQuoteLotDTO: ILibraryJobsAddToQuoteLotDTO) =>
  api.post<ILibraryJobsAddToQuoteLotDTO, IQuoteUpdateResponse>(
    `${QUOTE_LOT_PATH}/${lotId}/import`,
    libraryJobsAddToQuoteLotDTO,
  );

export const importBatiprixJobsLot = (
  lotId: number,
  quoteLotImportJobsFromBatiprixDTO: IQuoteLotImportJobsFromBatiprixDTO,
) =>
  api.post<IQuoteLotImportJobsFromBatiprixDTO, IQuoteUpdateResponse>(
    `${QUOTE_LOT_PATH}/${lotId}/batiprix-import`,
    quoteLotImportJobsFromBatiprixDTO,
  );

export const moveLot = (lotId: number, moveQuoteLotDTO: IMoveQuoteLotDTO) =>
  api.patch<IMoveQuoteLotDTO, IQuoteUpdateResponse>(`${QUOTE_LOT_PATH}/${lotId}/move`, moveQuoteLotDTO);

export const getComponentsTypes = (quoteLotId: number) =>
  api.get<never, IQuoteComponent[]>(`${QUOTE_LOT_PATH}/${quoteLotId}/components`);

export const setOptionalLot = (lotId: number, isOptional: boolean) =>
  api.post<IQuoteLotOptionalDTO, IQuoteUpdateResponse>(`${QUOTE_LOT_PATH}/${lotId}/optional`, { isOptional });
