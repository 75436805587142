export interface FileFieldTranslations {
  primaryLabel: string;
  primaryLabelError: string;
  secondaryLabel: string;
  maxSizeLabel: string;
  fileFieldDisabled: string;
  fileTooLargeErrorMessage: string;
  fileUnsupportedErrorMessage: string;
}
export const droppableFileFieldTranslations: FileFieldTranslations = {
  primaryLabel: '',
  primaryLabelError: '',
  secondaryLabel: '',
  fileFieldDisabled: '',
  maxSizeLabel: '',
  fileTooLargeErrorMessage: '',
  fileUnsupportedErrorMessage: '',
};

export const configureDefaultLabels = (translation: FileFieldTranslations) => {
  Object.assign(droppableFileFieldTranslations, translation);
};
