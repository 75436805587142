import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { func, object } from 'prop-types';
import { Onboarding, Button, SimplePuzzleIcon } from '@graneet/lib-ui';
import { Box, VStack, Divider } from '@chakra-ui/react';

import { useQuoteEditContext } from '../../../hooks/useQuoteEditContext';
import { QuoteImportJobInLotButton } from '../../buttons/QuoteImportJobInLotButton';

import { EditQuoteTableFooter } from './EditQuoteTableFooter';

import { Loading } from 'features/common/components/Loading';
import { useAddJob } from 'features/quote-job/hooks/useAddJob';
import { useAddLot } from 'features/quote-lot/hooks/useAddLot';
import { EditQuoteRootLot } from 'features/quote-lot/components/EditQuoteRootLot';
import { useLot } from 'features/quote-lot/hooks/useLot';

export const EditQuoteTable = ({ drawerSellSheet, scrollTop }) => {
  const { t } = useTranslation(['quote']);

  const { startAnotherUpdate, useRootLotId } = useQuoteEditContext();

  const rootLotId = useRootLotId();
  const rootLot = useLot(rootLotId);

  const addLot = useAddLot();
  const addJob = useAddJob();

  // -- Handle adding job at the end

  const handleAddJob = useCallback(
    async (isParentHiddenCost = false) => {
      if (rootLotId) {
        startAnotherUpdate();
        await addJob(rootLotId, false, isParentHiddenCost);
        if (isParentHiddenCost) {
          scrollTop();
        }
      }
    },
    [addJob, rootLotId, startAnotherUpdate, scrollTop],
  );

  // -- Handle adding lot at the end

  const handleAddLot = useCallback(async () => {
    if (rootLotId) {
      startAnotherUpdate();
      await addLot(rootLotId);
    }
  }, [addLot, rootLotId, startAnotherUpdate]);

  // -- Display nothing if still loading

  if (!rootLot) {
    return (
      <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <Loading />
      </Box>
    );
  }

  // -- Display onboarding if no job in root lot

  if (!rootLot.jobs.length && !rootLot.subLots.length) {
    return (
      <Onboarding
        icon={<SimplePuzzleIcon boxSize={45} />}
        mt={12}
        h="auto"
        action={
          <VStack spacing={4} w="15rem">
            <Button onClick={handleAddLot} w="100%">
              {t('quote:jobsStep.createLot')}
            </Button>
            <Divider />

            <QuoteImportJobInLotButton quoteLotId={rootLotId} w="100%" />

            <Button onClick={() => handleAddJob(false)} w="100%" variant="outline">
              {t('quote:jobsStep.createJob')}
            </Button>
          </VStack>
        }
      />
    );
  }

  return (
    <>
      <Box mb={1}>
        <EditQuoteRootLot rootLot={rootLot} drawerSellSheet={drawerSellSheet} />
      </Box>

      <EditQuoteTableFooter
        rootLot={{ id: rootLotId, ...rootLot }}
        handleAddJob={handleAddJob}
        handleAddLot={handleAddLot}
      />
    </>
  );
};

EditQuoteTable.propTypes = {
  drawerSellSheet: object.isRequired,
  scrollTop: func.isRequired,
};
