import type { FC } from 'react';
import { Box, Flex, Link, Text, useClipboard } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Card, Container, List, Section, UpsellCard } from '@graneet/lib-ui';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useCurrentUser } from 'features/user/services/user.api';
import { useHelpScoutGetArticles } from 'features/common/services/helpscout.api';
import { getEnvValue } from 'config/env';

const ENTITIES = ['supplierInvoice'] as const;
type EmailImportEntity = (typeof ENTITIES)[number];

function getEmail(entity: EmailImportEntity, emailImportId: string) {
  const env = getEnvValue('REACT_APP_ENV', true);
  const domain = env === 'prod' ? 'fr' : 'dev';
  const suffix = env === 'prod' ? '' : `-${env}`;

  switch (entity) {
    case 'supplierInvoice':
      return `${emailImportId}@supplier-invoice${suffix}.app.graneet.${domain}`;
    default:
      entity satisfies never;
      throw new Error('Not implemented');
  }
}

const EntityCard: FC<{ entity: EmailImportEntity }> = ({ entity }) => {
  const { t } = useTranslation(['global', 'mailing']);

  const user = useCurrentUser();

  const email = getEmail(entity, user.data.user.company.emailImportId);
  const { onCopy, hasCopied } = useClipboard(email);

  return (
    <Card w="100%">
      <Flex justifyContent="space-between">
        <Box>
          <Text color="primaryLight">{email}</Text>
          <Text>{t(`global:emailImports.entities.${entity}`)}</Text>
        </Box>

        <Box>
          <Button
            onClick={onCopy}
            variant="secondary"
            leftIcon={hasCopied ? <i className="ri-check-line" /> : <i className="ri-file-copy-line" />}
          >
            {hasCopied ? t('global:words.c.copied') : t('global:words.c.copy')}
          </Button>
        </Box>
      </Flex>
    </Card>
  );
};

export const EmailImportTab: FC = () => {
  const { t } = useTranslation(['global']);

  const hasEmailImportFeatureFlag = useFeatureFlag(FEATURE_FLAGS.IMPORT_BY_EMAIL);

  const helpScoutArticles = useHelpScoutGetArticles('', 'import'); // TODO scope adresse de transfert

  return (
    <Container size="md">
      <Section
        title={t('global:emailImports.emailTransfer.title')}
        description={<Trans t={t} i18nKey="global:emailImports.emailTransfer.description" />}
      >
        {hasEmailImportFeatureFlag ? (
          <Flex gap={3} w="100%">
            {ENTITIES.map((entity) => (
              <EntityCard key={entity} entity={entity} />
            ))}
          </Flex>
        ) : (
          <UpsellCard
            description={
              <Trans t={t} i18nKey="global:upsellCard.advancedAndMore">
                <strong>displayed_data</strong>
                <strong>displayed_data</strong>
                <strong>displayed_data</strong>
              </Trans>
            }
          />
        )}
      </Section>

      {/* TODO */}
      {false && (
        <Section title={t('global:emailImports.documentation.title')}>
          <List>
            {helpScoutArticles.data.map((data) => (
              <List.Item
                icon={<i className="ri-file-text-fill" />}
                key={data.id}
                label={data.name}
                as={Link}
                // @ts-ignore
                isExternal
                href={data.url}
              />
            ))}
          </List>
        </Section>
      )}
    </Container>
  );
};
