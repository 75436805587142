import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const DiscountIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 14 14" color="green.700" {...props}>
    <path
      d="M11.0715 1.41796L11.4449 3.77391L13.5939 4.86644L12.5102 7.00266L13.6 9.13888L11.4327 10.2314L11.0592 12.5874L8.67757 12.215L6.98166 13.8996L5.27962 12.1906L2.91635 12.5813L2.53676 10.207L0.400024 9.12057L1.48982 6.98435L0.406147 4.86644L2.55513 3.76171L2.9286 1.42407L5.29798 1.81469L7.00002 0.0996094L8.69594 1.79028L11.0715 1.41796ZM5.46941 3.95091C5.22585 3.95091 4.99226 4.04737 4.82003 4.21906C4.6478 4.39076 4.55104 4.62363 4.55104 4.86644C4.55104 5.10925 4.6478 5.34212 4.82003 5.51381C4.99226 5.68551 5.22585 5.78196 5.46941 5.78196C5.71298 5.78196 5.94657 5.68551 6.1188 5.51381C6.29102 5.34212 6.38778 5.10925 6.38778 4.86644C6.38778 4.62363 6.29102 4.39076 6.1188 4.21906C5.94657 4.04737 5.71298 3.95091 5.46941 3.95091ZM8.53064 8.22336C8.28707 8.22336 8.05348 8.31982 7.88125 8.49151C7.70902 8.66321 7.61227 8.89607 7.61227 9.13888C7.61227 9.3817 7.70902 9.61456 7.88125 9.78626C8.05348 9.95795 8.28707 10.0544 8.53064 10.0544C8.7742 10.0544 9.00779 9.95795 9.18002 9.78626C9.35225 9.61456 9.449 9.3817 9.449 9.13888C9.449 8.89607 9.35225 8.66321 9.18002 8.49151C9.00779 8.31982 8.7742 8.22336 8.53064 8.22336ZM4.80206 10.0544L10.0612 4.81151L9.19798 3.95091L3.9388 9.19382L4.80206 10.0544Z"
      fill="currentColor"
    />
  </Icon>
);
