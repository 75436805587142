import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import { divideFloating, multiplyFloating } from '../../../utils';

export interface FieldProps<Name extends string> {
  fieldName: Name;
  computedName: string;
}

type MultiplicationForm<A extends string, B extends string, C extends string> = Record<A | B | C, number>;

export const useMultiplicationUpdate = <A extends string, B extends string, C extends string>(
  factorOne: FieldProps<A>,
  factorTwo: FieldProps<B>,
  result: FieldProps<C>,
  computedElement: A | B | C | undefined,
  setComputedElement: undefined | ((computedElement: A | B | C | null) => void),
  fieldFocusedRef: MutableRefObject<FieldProps<A | B | C> | null>,
  hiddenCostAmount?: number,
) => {
  const { fieldName: factorOneName } = factorOne;
  const { fieldName: factorTwoName } = factorTwo;
  const { fieldName: resultName } = result;

  const previousValuesRef = useRef<Record<A | B | C, number | undefined | null>>({
    [factorOneName]: undefined,
    [factorTwoName]: undefined,
    [resultName]: undefined,
  } as Record<A | B | C, number | undefined | null>);

  const form = useFormContext<any>();
  const values = useOnChangeValues<MultiplicationForm<A, B, C>, A | B | C>(form, [
    factorOneName,
    factorTwoName,
    resultName,
  ]);

  useEffect(() => {
    if (!Object.values(previousValuesRef.current).length) {
      previousValuesRef.current = { ...values };
      return;
    }

    const factorOneValue = values[factorOneName]! + (hiddenCostAmount ?? 0);
    const factorTwoValue = values[factorTwoName]!;
    const resultValue = values[resultName]!;

    const factorOneValueOld = (previousValuesRef.current[factorOneName] ?? 0) + (hiddenCostAmount ?? 0);
    const factorTwoValueOld = previousValuesRef.current[factorTwoName];
    const resultValueOld = previousValuesRef.current[resultName];

    if (
      factorOneValue === factorOneValueOld &&
      factorTwoValue === factorTwoValueOld &&
      resultValue === resultValueOld
    ) {
      return;
    }

    /*
      User is currently modifying the multiplication, add only computed value of the field modified
     */
    const fieldSubjectToChange = [factorOne, factorTwo, result].reduce<(A | B | C)[]>((acc, field) => {
      if (fieldFocusedRef.current && fieldFocusedRef.current.computedName === field.fieldName) {
        return [...acc, field.fieldName];
      }
      return acc;
    }, []);

    const isFieldSubjectToChange = (field: FieldProps<A> | FieldProps<B> | FieldProps<C>) =>
      fieldSubjectToChange.includes(field.fieldName);

    if (isFieldSubjectToChange(result) && Number.isFinite(factorOneValue) && Number.isFinite(factorTwoValue)) {
      form.setFormValues({ [resultName]: multiplyFloating(factorOneValue, factorTwoValue) } as any);
      setComputedElement?.(resultName);
    }

    if (isFieldSubjectToChange(factorOne) && Number.isFinite(factorTwoValue) && Number.isFinite(resultValue)) {
      form.setFormValues({ [factorOneName]: divideFloating(resultValue, factorTwoValue) } as any);
      setComputedElement?.(factorOneName);
    }

    if (isFieldSubjectToChange(factorTwo) && Number.isFinite(factorOneValue) && Number.isFinite(resultValue)) {
      form.setFormValues({ [factorTwoName]: divideFloating(resultValue, factorOneValue) } as any);
      setComputedElement?.(factorTwoName);
    }

    previousValuesRef.current = { ...values };
  }, [
    values,
    computedElement,
    factorOne,
    factorOneName,
    factorTwo,
    factorTwoName,
    fieldFocusedRef,
    result,
    resultName,
    setComputedElement,
    hiddenCostAmount,
    form,
  ]);
};
