import type { DrawerApi } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { TRACKING_EVENT_ENUM, type ILibraryJobResponseDTO } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback } from 'react';
import { ContentTypeGuard } from '@org/quotation-lib';

import { useQuoteLibraryImportItem } from '../hooks/useQuoteLibraryImportItem';

import { LibraryJobImportDrawer } from 'features/library-job/components/LibraryJobImportDrawer';
import { useStore } from 'store/store';
import {
  updateQuoteImportItemModalIsOpen,
  updateQuoteImportItemTargetParentId,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';

interface QuoteLibraryItemDrawerProps {
  drawer: DrawerApi<string>;
}

export const QuoteLibraryItemDrawer: FC<QuoteLibraryItemDrawerProps> = ({ drawer }) => {
  const { t } = useTranslation(['quote', 'library']);

  const segmentAnalytics = useSegmentAnalytics();
  const isOpen = useStore((state) => state.quoteImportItemDrawerIsOpen);
  const quoteImportItemTargetParentId = useStore((state) => state.quoteImportItemTargetParentId);

  const { quote } = useQuote();
  const quoteLibraryImportItem = useQuoteLibraryImportItem();

  const { executeCommand } = useCommands();

  const handleOnClose = useCallback(() => {
    updateQuoteImportItemModalIsOpen(false);
    updateQuoteImportItemTargetParentId(null);
  }, []);

  const onSubmit = useCallback(
    (selectedLibraryJobs: ILibraryJobResponseDTO[]) => {
      segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.QUOTE_JOB_IMPORT, { type: 'library' });

      const tree = quote.getTree();

      const hiddenCostRootNodeId = tree.getHiddenCostNodeId();

      const targetNode = quoteImportItemTargetParentId
        ? tree.getNodeOrThrow(quoteImportItemTargetParentId)
        : tree.getRootNode();

      const targetContent = targetNode.getContent();

      // Case Parent is an Item ( Basic Item, Optional Item, Hidden Cost Item) we create a sub item
      if (ContentTypeGuard.isQuoteItem(targetContent)) {
        executeCommand(quoteLibraryImportItem(selectedLibraryJobs, quoteImportItemTargetParentId, 'item'), quote);
        handleOnClose();
        return;
      }

      if (!ContentTypeGuard.isQuoteLot(targetContent)) {
        throw new Error('Content has to be either an item or a lot');
      }

      if (quoteImportItemTargetParentId === hiddenCostRootNodeId) {
        executeCommand(
          quoteLibraryImportItem(selectedLibraryJobs, quoteImportItemTargetParentId, 'hiddenCostLot'),
          quote,
        );
        handleOnClose();
        return;
      }

      if (targetContent.getIsEveryChildOptional()) {
        executeCommand(
          quoteLibraryImportItem(selectedLibraryJobs, quoteImportItemTargetParentId, 'optionalLot'),
          quote,
        );
        handleOnClose();
        return;
      }

      executeCommand(quoteLibraryImportItem(selectedLibraryJobs, quoteImportItemTargetParentId, 'lot'), quote);
      handleOnClose();
    },
    [executeCommand, handleOnClose, quote, quoteImportItemTargetParentId, quoteLibraryImportItem, segmentAnalytics],
  );

  return (
    <LibraryJobImportDrawer
      drawer={{ ...drawer, isOpen, onClose: handleOnClose }}
      title={t('library:itemDrawer.title')}
      onSubmit={onSubmit}
    />
  );
};
