import { useCallback } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import type { ValueOf } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { DisplayNotFoundScreen } from '../DisplayNotFoundScreen';

import { CreateProjectScreen } from './CreateProjectScreen';
import { EditProgressStatementScreen } from './_tabs/statements/progress-statements/edit/EditProgressStatementScreen';
import { CreateProgressStatementScreen } from './_tabs/statements/progress-statements/create/CreateProgressStatementScreen';
import { EditDirectProgressStatementsScreen } from './_tabs/statements/progress-statements/direct/edit/EditDirectProgressStatementScreen';
import { CreateDirectProgressStatementScreen } from './_tabs/statements/progress-statements/direct/create/CreateDirectProgressStatementScreen';
import { CreateFinalStatementScreen } from './final-statement/CreateFinalStatementScreen';
import { ViewProjectDetailScreen } from './ViewProjectDetailScreen';
import { EditDownPaymentScreen } from './_tabs/statements/down-payments/EditDownPaymentScreen';
import { SubProjectsRouter } from './sub-projects/SubProjectsRouter';
import { ViewProjectsScreen } from './ViewProjectsScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { EditContractScreen } from 'screens/projects/_tabs/contracts/EditContractScreen';
import { STATEMENT_PATHS } from 'features/statement/constants/statement.constant';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { CreateCreditScreen } from 'screens/sales/statements/cancel/CreateCreditScreen';

const formatProjectInvoiceUrl = (projectId: number, type: ValueOf<typeof STATEMENT_PATHS>, statementId: number) =>
  `/projects/${projectId}/statements/${type}/${statementId}`;

export const ProjectsRouter = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const hasCreateProjectsPermission = usePermissions([PERMISSION.CREATE_PROJECTS]);
  const hasCreateStatementsPermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);
  const hasUpdateStatusStatementsPermission = usePermissions([PERMISSION.UPDATE_STATUS_STATEMENTS]);
  const hasUpdateContractsPermission = usePermissions([PERMISSION.UPDATE_CONTRACTS]);
  const hasDisplayQuotesPermission = usePermissions([PERMISSION.DISPLAY_QUOTES]);

  const onCreateCreditQuit = useCallback(
    ({
      projectId,
      type,
      statementId,
    }: {
      projectId: number;
      type: ValueOf<typeof STATEMENT_PATHS>;
      statementId: number;
    }) => history.replace(formatProjectInvoiceUrl(projectId, type, statementId)),
    [history],
  );

  const onStatementCancelled = useCallback(
    ({ projectId, creditId }: { projectId: number; creditId: number }) =>
      history.replace(formatProjectInvoiceUrl(projectId, STATEMENT_PATHS.CREDIT, creditId)),
    [history],
  );

  return (
    <Switch>
      <Route exact path={path}>
        <ViewProjectsScreen />
      </Route>

      {hasCreateProjectsPermission && (
        <Route path={`${path}/create`}>
          <CreateProjectScreen />
        </Route>
      )}

      {/* Down payment (acompte) */}

      {hasCreateStatementsPermission && (
        <Route path={`${path}/:projectId/statements/sub-projects/:subProjectId/down-payments/edit`}>
          <QueryWrapper>
            <EditDownPaymentScreen />
          </QueryWrapper>
        </Route>
      )}

      {/* Statement credit (avoir de facture) */}

      {hasUpdateStatusStatementsPermission && (
        <Route path={`${path}/:projectId/statements/:type/:statementId/cancel`}>
          <CreateCreditScreen onQuit={onCreateCreditQuit} onStatementCancelled={onStatementCancelled} />
        </Route>
      )}

      {/* Progress statements (situations) */}

      {hasCreateStatementsPermission && (
        <Route path={`${path}/:projectId/statements/sub-projects/:subProjectId/progress-statements/create`} exact>
          <QueryWrapper>
            <CreateProgressStatementScreen />
          </QueryWrapper>
        </Route>
      )}

      {hasCreateStatementsPermission && (
        <Route
          path={`${path}/:projectId/statements/sub-projects/:subProjectId/progress-statements/:progressStatementId/edit`}
          exact
        >
          <QueryWrapper>
            <EditProgressStatementScreen />
          </QueryWrapper>
        </Route>
      )}

      {/* Direct progress statements (factures directes) */}

      {hasCreateStatementsPermission && (
        <Route path={`${path}/:projectId/statements/sub-projects/:subProjectId/progress-statements/direct/create`}>
          <QueryWrapper>
            <CreateDirectProgressStatementScreen />
          </QueryWrapper>
        </Route>
      )}

      {hasCreateStatementsPermission && (
        <Route
          path={`${path}/:projectId/statements/sub-projects/:subProjectId/progress-statements/direct/:progressStatementId/edit`}
        >
          <QueryWrapper>
            <EditDirectProgressStatementsScreen />
          </QueryWrapper>
        </Route>
      )}

      {/* Contract */}

      {hasUpdateContractsPermission && hasDisplayQuotesPermission && (
        <Route path={`${path}/:projectId/contracts/:contractId`} exact>
          <QueryWrapper>
            <EditContractScreen />
          </QueryWrapper>
        </Route>
      )}

      {/* Final statement (DGD) */}

      {hasCreateStatementsPermission && (
        <Route path={`${path}/:projectId/final-statement`}>
          <QueryWrapper>
            <CreateFinalStatementScreen />
          </QueryWrapper>
        </Route>
      )}

      {/* Project detail */}

      <Route path={`${path}/:projectId/sub-projects`}>
        <SubProjectsRouter />
      </Route>

      <Route path={`${path}/:projectId`}>
        <ViewProjectDetailScreen />
      </Route>

      <Route path="*">
        <DisplayNotFoundScreen />
      </Route>
    </Switch>
  );
};
