export enum MARGIN_COMPUTED_VALUE {
  PROFIT = 'PROFIT',
  TOTAL = 'TOTAL',
}

export interface IMargin {
  id: number;

  overheadCosts: number;

  profitMargin: number;

  totalMargin: number;

  computed: MARGIN_COMPUTED_VALUE;

  createdAt: Date;

  updatedAt: Date;
}
