import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';
import { QUOTE_ITEM_TYPE_OF_PRICING } from '@org/quotation-lib';
import { QuoteItemTypeOfPricing } from '@org/graneet-bff-client';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { genericUpdateItemMapper } from 'features/quotation/quote-common/mappers/quoteItemsGenericMapper';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteOptionalItemUpdatePricingType() {
  const { quoteOptionalItemsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, type: QUOTE_ITEM_TYPE_OF_PRICING): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(nodeId);
          const quoteNode = quoteOptionalItem.getNodeOrThrow();
          this.oldValue = quoteOptionalItem.getTypeOfPricing();

          quoteOptionalItem.updateTypeOfPricing(type);
          quoteSetToStore(quote);
          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteOptionalItemsProxyApi
            .updateQuoteOptionalItem(quoteOptionalItem.getId(), {
              ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
              typeOfPricing:
                type === QUOTE_ITEM_TYPE_OF_PRICING.UNIT
                  ? QuoteItemTypeOfPricing.Unit
                  : QuoteItemTypeOfPricing.FlatRate,
              quoteOptionalItemId: quoteOptionalItem.getId(),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(nodeId);
          const quoteNode = quoteOptionalItem.getNodeOrThrow();

          quoteOptionalItem.updateTypeOfPricing(this.oldValue);
          quoteSetToStore(quote);
          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteOptionalItemsProxyApi
            .updateQuoteOptionalItem(quoteOptionalItem.getId(), {
              ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
              typeOfPricing: this.oldValue,
              quoteOptionalItemId: quoteOptionalItem.getId(),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteOptionalItemsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
