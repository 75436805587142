export interface IDifferenceSummaryTranslationsTranslations {
  vat: (t: string) => string;
  initialAmount: string;
  newAmount: string;
  withReversalOfLiabilityOn: string;
  globalVat: string;
  totalBeforeDiscount: string;
  discount: string;
  customDiscountVat: (t: string) => string;
}

export const differenceSummaryTranslations: IDifferenceSummaryTranslationsTranslations = {
  vat: (t: string) => t,
  initialAmount: '',
  newAmount: '',
  withReversalOfLiabilityOn: '',
  globalVat: '',
  totalBeforeDiscount: '',
  discount: '',
  customDiscountVat: (t: string) => t,
};

export const configureDefaultLabels = (translations: IDifferenceSummaryTranslationsTranslations) => {
  Object.assign(differenceSummaryTranslations, translations);
};
