import type { QuoteUpdateAsyncDTO } from '@org/graneet-bff-client';
import type { Quote } from '@org/quotation-lib';

import { mapMarginObjectToMarginDTO } from './quoteMarginObjectToMarginDTO';

export const mapQuoteToQuoteUpdateAmountExVatDTO = (
  quote: Quote,
  clientRequestId: string,
  timestamp: string,
): QuoteUpdateAsyncDTO => ({
  quoteId: quote.getId(),
  timestamp,
  clientRequestId,
  amountExVat: quote.export().amountExVat,
});

export const mapQuoteToQuoteUpdateMarginDTO = (
  quote: Quote,
  clientRequestId: string,
  timestamp: string,
): QuoteUpdateAsyncDTO => ({
  quoteId: quote.getId(),
  timestamp,
  clientRequestId,
  margin: mapMarginObjectToMarginDTO(quote.getMargin().export()),
});
