import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

import * as lotsApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Duplicates a lot
 */
export const useDuplicateLot = () => {
  const { t } = useTranslation(['quote']);
  const toast = useToast();

  const {
    setLotIsExpanded,
    updateDataLocally,
    displayNeedsReload,
    findOwnerLotIdFromLotIdInRelations,
    getCurrentQuoteData,
  } = useQuoteEditContext();

  return useCallback(
    async (lotId) => {
      const { lots, relations, expandedLotsIds } = getCurrentQuoteData();

      // Call api to delete the job
      const [err, quoteInfos] = await lotsApi.duplicateLot(lotId);

      const errorMessage = t('quote:jobsStep.errors.duplicatingLotError');

      // Return if it failed and notify user
      if (err) {
        toast.error(errorMessage);
        return;
      }

      // Quit the lot could not be found locally
      if (!lots[lotId]) {
        displayNeedsReload(errorMessage);
        return;
      }

      // Quit the lot's lot could not be found locally
      const ownerLotId = findOwnerLotIdFromLotIdInRelations(lotId);
      const ownerLotRelations = relations[ownerLotId];
      if (!ownerLotRelations) {
        displayNeedsReload(errorMessage);
        return;
      }

      // Quit if the lot could not be found in the lots
      const lotsOfLot = ownerLotRelations.lots;
      const lotPosition = lotsOfLot.indexOf(lotId);
      if (lotPosition < 0) {
        displayNeedsReload(errorMessage);
        return;
      }

      // Display success toast
      toast.success(<Trans i18nKey="quote:jobsStep.successes.duplicatingLot" />);

      // Find id of new lot among all returned lots
      // The new lot has relations but it's not present in other relations
      const { relations: responseRelations, lots: responseLots } = quoteInfos;
      const isLotIdInRelations = (searchLotId) =>
        Object.values(responseRelations).find(({ lots: subLots }) => subLots.includes(searchLotId));
      const newLotId = Object.keys(responseLots).find(
        (lotResponseId) => !isLotIdInRelations(lotResponseId) && Object.keys(responseRelations).includes(lotResponseId),
      );

      setLotIsExpanded(newLotId, expandedLotsIds[lotId]);

      // Update local relations
      ownerLotRelations.lots.splice(lotPosition + 1, 0, parseInt(newLotId, 10));
      Object.entries(responseRelations).forEach(([relationLotId, lotRelations]) => {
        relations[relationLotId] = lotRelations;
      });

      // Update interface
      updateDataLocally(quoteInfos);
    },
    [
      toast,
      t,
      findOwnerLotIdFromLotIdInRelations,
      setLotIsExpanded,
      updateDataLocally,
      displayNeedsReload,
      getCurrentQuoteData,
    ],
  );
};
