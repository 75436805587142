import type {
  IProgressStatementResponseDTO,
  IDirectProgressStatementCreationDTO,
  IDirectProgressStatementUpdateDTO,
  IProgressStatement,
  IProgressStatementCreationDTO,
  IProgressStatementPdfVersionsDTO,
  IProgressStatementStatusDTO,
  IProgressStatementSummaryInputDTO,
  IProgressStatementSummaryResponseDTO,
  IProgressStatementUpdateDTO,
  IProgressStatementValidityResponseDTO,
  IProgressStatementCancelResponseDTO,
  IProgressStatementUpdateStatusResponseDTO,
  IProgressStatementWithDirectPayments,
  IUpdateTagsDTO,
} from '@graneet/business-logic';
import { PROGRESS_STATEMENT_STATUSES } from '@graneet/business-logic';
import type { PaginatedResponse } from '@graneet/lib-ui';
import { useToast } from '@graneet/lib-ui';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { PROGRESS_STATEMENT_FACTORY_NAME } from './progress-statement.factory-name';

import { invalidateStatementRelatedQueries } from 'features/common/services/invalidate-statement-related-queries.util';
import { apiNew } from 'features/api/services/apiNew.service';
import { usePdfVersionsTanStackQuery } from 'features/pdf/hooks/usePdfVersions';

const PROGRESS_STATEMENT_PATH = '/progress-statements';

const progressStatementKeyFactory = createQueryKeys(PROGRESS_STATEMENT_FACTORY_NAME, {
  get: (queryParams: { subProjectId?: string; _full?: boolean }) => ({
    queryKey: [PROGRESS_STATEMENT_PATH, queryParams],
    queryFn: () => {
      const { subProjectId } = queryParams;
      if (!subProjectId) {
        return Promise.resolve<PaginatedResponse<IProgressStatement>>({
          data: [],
          sums: {},
          metadata: {
            hasMore: false,
            totalCount: 0,
            unfilteredCount: 0,
            offset: 0,
            offsetV1: 0,
            offsetV2: 0,
          },
        });
      }

      return apiNew.get<{ subProjectId: string; _full?: boolean }, PaginatedResponse<IProgressStatement>>(
        PROGRESS_STATEMENT_PATH,
        { ...queryParams, subProjectId },
      );
    },
  }),
  getOne: (id: number) => ({
    queryKey: [PROGRESS_STATEMENT_PATH, id],
    queryFn: () => apiNew.get<never, IProgressStatementResponseDTO>(`${PROGRESS_STATEMENT_PATH}/${id}`),
  }),
  getOneExtended: (id?: number) => ({
    queryKey: [PROGRESS_STATEMENT_PATH, id],
    queryFn: () => {
      if (id) {
        return apiNew.get<never, IProgressStatementWithDirectPayments>(`${PROGRESS_STATEMENT_PATH}/${id}/extended`);
      }
      return Promise.resolve(null);
    },
  }),
  getSummary: (dto: IProgressStatementSummaryInputDTO) => ({
    queryKey: [PROGRESS_STATEMENT_PATH, dto],
    queryFn: () =>
      apiNew.post<IProgressStatementSummaryInputDTO, IProgressStatementSummaryResponseDTO>(
        `${PROGRESS_STATEMENT_PATH}/summary`,
        dto,
      ),
  }),
  getOneVersions: (id: number) => ({
    queryKey: [PROGRESS_STATEMENT_PATH, id],
    queryFn: () => apiNew.get<never, IProgressStatementPdfVersionsDTO>(`${PROGRESS_STATEMENT_PATH}/${id}/versions`),
  }),
  getNextOne: (id: number) => ({
    queryKey: [PROGRESS_STATEMENT_PATH, id],
    queryFn: () => apiNew.get<never, IProgressStatement | null>(`${PROGRESS_STATEMENT_PATH}/${id}/next`),
  }),
  getLastBySubProject: (queryParams: { subProjectId: string; progressStatementId?: number }) => ({
    queryKey: [PROGRESS_STATEMENT_PATH, queryParams],
    queryFn: () =>
      apiNew.get<{ subProjectId: string; progressStatementId?: number }, IProgressStatement | null>(
        `${PROGRESS_STATEMENT_PATH}/previous`,
        queryParams,
      ),
  }),
});

export function useProgressStatementsForSubProjects(subProjectId?: string) {
  return useSuspenseQuery(progressStatementKeyFactory.get({ subProjectId, _full: true }));
}

export function useProgressStatement(id: number) {
  return useSuspenseQuery(progressStatementKeyFactory.getOne(id));
}

export function useProgressStatementExtended(id?: number) {
  return useSuspenseQuery(progressStatementKeyFactory.getOneExtended(id));
}

export function useNextProgressStatement(id: number) {
  return useSuspenseQuery(progressStatementKeyFactory.getNextOne(id));
}

export function usePreviousProgressStatementBySubProject(queryParams: {
  subProjectId: string;
  progressStatementId?: number;
}) {
  return useSuspenseQuery(progressStatementKeyFactory.getLastBySubProject(queryParams));
}

export function useProgressStatementVersions(id: number) {
  const data = useSuspenseQuery(progressStatementKeyFactory.getOneVersions(id));
  usePdfVersionsTanStackQuery(data.data, data.refetch);

  return data;
}

export function useProgressStatementSummary(dto: IProgressStatementSummaryInputDTO) {
  return useSuspenseQuery(progressStatementKeyFactory.getSummary(dto));
}

export function useProgressStatementCheckValidity() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'statement']);

  return useMutation({
    mutationFn: (id: number) =>
      apiNew.get<never, IProgressStatementValidityResponseDTO>(`${PROGRESS_STATEMENT_PATH}/${id}/validity`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

// Mutation
export function useProgressStatementDelete() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'statement']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (progressStatement: IProgressStatement) =>
      apiNew.delete<never, void>(`${PROGRESS_STATEMENT_PATH}/${progressStatement.id}`),
    onSuccess: async (_, progressStatement) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('statement:delete.success', { name: progressStatement.name }));
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useProgressStatementCreate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'progressStatement']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IProgressStatementCreationDTO) =>
      apiNew.post<IProgressStatementCreationDTO, IProgressStatement>(PROGRESS_STATEMENT_PATH, dto),
    onSuccess: async (progressStatement) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('progressStatement:isCreating.success.creating', { name: progressStatement.name }));
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useProgressStatementUpdate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'progressStatement']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IProgressStatementUpdateDTO }) =>
      apiNew.patch<IProgressStatementUpdateDTO, IProgressStatement>(
        `${PROGRESS_STATEMENT_PATH}/${params.id}`,
        params.dto,
      ),
    onSuccess: async (progressStatement) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('progressStatement:isCreating.success.editing', { name: progressStatement.name }));
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useDirectProgressStatementCreate() {
  const toast = useToast();
  const { t } = useTranslation(['directProgressStatement']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IDirectProgressStatementCreationDTO) =>
      apiNew.post<IDirectProgressStatementCreationDTO, IProgressStatement>(`${PROGRESS_STATEMENT_PATH}/direct`, dto),
    onSuccess: async (progressStatement) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('directProgressStatement:form.success.creating', { name: progressStatement.name }));
    },
    onError: () => {
      toast.error(t('directProgressStatement:form.errors.creating'));
    },
  });
}

export function useDirectProgressStatementUpdate() {
  const toast = useToast();
  const { t } = useTranslation(['directProgressStatement']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IDirectProgressStatementUpdateDTO }) =>
      apiNew.patch<IDirectProgressStatementUpdateDTO, IProgressStatement>(
        `${PROGRESS_STATEMENT_PATH}/${params.id}/direct`,
        params.dto,
      ),
    onSuccess: async (progressStatement) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('directProgressStatement:form.success.editing', { name: progressStatement.name }));
    },
    onError: () => {
      toast.error(t('directProgressStatement:form.errors.editing'));
    },
  });
}

export function useProgressStatementChangeStatus(type: 'progress' | 'direct') {
  const toast = useToast();
  const { t } = useTranslation(['global', 'directProgressStatement', 'progressStatement']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IProgressStatementStatusDTO }) =>
      apiNew.patch<IProgressStatementStatusDTO, IProgressStatementUpdateStatusResponseDTO>(
        `${PROGRESS_STATEMENT_PATH}/${params.id}/status`,
        {
          status: params.dto.status,
          invoiceNumber: params.dto.invoiceNumber,
          acceptanceDate: params.dto.acceptanceDate,
          billingDate: params.dto.billingDate,
        },
      ),
    onSuccess: async (progressStatement, params) => {
      await invalidateStatementRelatedQueries(queryClient);
      if (type === 'direct' && params.dto.status === PROGRESS_STATEMENT_STATUSES.COMPLETED) {
        toast.success(
          t('global:words.c.success'),
          t('directProgressStatement:workflow.completing.success', { invoiceNumber: progressStatement.invoiceNumber }),
        );
      }
      if (type === 'progress' && params.dto.status === PROGRESS_STATEMENT_STATUSES.VALIDATED) {
        toast.success(
          t('global:words.c.success'),
          t('progressStatement:workflow.validating.success', {
            invoiceNumber: progressStatement.invoiceNumber,
          }),
        );
      }
      if (type === 'progress' && params.dto.status === PROGRESS_STATEMENT_STATUSES.ACCEPTED) {
        toast.success(t('global:words.c.success'), t('progressStatement:workflow.accepting.success'));
      }
      if (type === 'progress' && params.dto.status === PROGRESS_STATEMENT_STATUSES.DRAFT) {
        // TODO nothing but it's weird
      }
    },
    onError: (_, params) => {
      if (type === 'direct' && params.dto.status === PROGRESS_STATEMENT_STATUSES.COMPLETED) {
        toast.error(t('global:words.c.error'), t('directProgressStatement:workflow.completing.error'));
      }
      if (type === 'progress' && params.dto.status === PROGRESS_STATEMENT_STATUSES.VALIDATED) {
        toast.error(t('global:words.c.error'), t('progressStatement:workflow.validating.error'));
      }
      if (type === 'progress' && params.dto.status === PROGRESS_STATEMENT_STATUSES.ACCEPTED) {
        toast.error(t('global:words.c.error'), t('progressStatement:workflow.accepting.error'));
      }
      if (type === 'progress' && params.dto.status === PROGRESS_STATEMENT_STATUSES.DRAFT) {
        toast.error(t('global:words.c.error'), t('progressStatement:workflow.updating.error'));
      }
    },
  });
}

export function useProgressStatementForceDraft() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'progressStatement', 'statement']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: {
      currentProgressStatement: IProgressStatement;
      nextProgressStatement: IProgressStatement | undefined;
    }) =>
      apiNew.patch<never, IProgressStatementUpdateStatusResponseDTO>(
        `${PROGRESS_STATEMENT_PATH}/${params.currentProgressStatement.id}/status/force-draft`,
      ),
    onSuccess: async (progressStatement, params) => {
      await invalidateStatementRelatedQueries(queryClient);
      if (
        params.nextProgressStatement &&
        params.nextProgressStatement.status === PROGRESS_STATEMENT_STATUSES.VALIDATED
      ) {
        toast.success(
          t('statement:forceDraftModal.onSuccessWithNextProgressStatement', {
            invoiceNumber: progressStatement.invoiceNumber,
            nextInvoiceNumber: params.nextProgressStatement.invoiceNumber,
          }),
        );
      } else {
        toast.success(
          t('statement:forceDraftModal.onSuccess', {
            invoiceNumber: progressStatement.invoiceNumber,
          }),
        );
      }
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('progressStatement:workflow.updating.error'));
    },
  });
}

export function useProgressStatementCancel(context: { cancelledInvoiceNumber: string | undefined }) {
  const toast = useToast();
  const { t } = useTranslation(['global', 'credit']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IProgressStatementStatusDTO['credit'] }) =>
      apiNew.patch<IProgressStatementStatusDTO, IProgressStatementCancelResponseDTO>(
        `${PROGRESS_STATEMENT_PATH}/${params.id}/status`,
        {
          status: PROGRESS_STATEMENT_STATUSES.DRAFT,
          credit: params.dto,
        },
      ),
    onSuccess: async (progressStatement) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(
        t(`credit:toast.create.success.PROGRESS_STATEMENT_VALIDATED`, {
          invoiceNumber: progressStatement?.credit.invoiceNumber,
          cancelledInvoiceNumber: context.cancelledInvoiceNumber,
          draftedStatementInvoiceNumber: progressStatement.draftedStatementInvoiceNumber,
        }),
      );
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('credit:toast.create.error'));
    },
  });
}

export function useProgressStatementEditTags() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'credit']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateTagsDTO }) =>
      apiNew.patch<IUpdateTagsDTO, IProgressStatement>(`${PROGRESS_STATEMENT_PATH}/${params.id}/tags`, params.dto),
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('global:words.c.success'), t('global:changeTags.success'));
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:changeTags.error'));
    },
  });
}
