import type { QuoteNodeObject } from '@org/quotation-lib';
import type { ColDef, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';

import { ActionCell } from '../../components/columns/ActionCell/ActionCell';
import { ActionHeader } from '../../components/columns/ActionHeader/ActionHeader';
import type { QuoteAGGridType } from '../../constants/agGridConstants';

export function useActionColumnDef(type: QuoteAGGridType) {
  return useCallback(
    (): ColDef<QuoteNodeObject> => ({
      colId: 'settings',
      width: 64,
      resizable: false,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      headerComponent: ActionHeader,
      editable: false,
      sortable: false,
      cellStyle: { textAlign: 'right' },
      cellClass: 'no-focus',
      cellRenderer: ActionCell,
      pinned: 'right',
      lockPosition: 'right',
      headerComponentParams: {
        type,
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams<QuoteNodeObject>) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      valueGetter: (_params) => '-',
    }),
    [type],
  );
}
