import { useMemo } from 'react';

import { useStore } from 'store/store';

export const useDefaultColDef = () => {
  const isEditable = useStore((store) => store.quoteEditable);

  return useMemo(
    () => ({
      flex: 1,
      resizable: false,
      sortable: false,
      editable: isEditable,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
    }),
    [isEditable],
  );
};
