import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const CheckOutlineIcon: FC<IconProps> = ({ color = 'greenBrand.light', ...otherProps }) => (
  <Icon viewBox="0 0 20 20" color={color} {...otherProps}>
    <path
      d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C10.76 2 11.5 2.11 12.2 2.31L13.77 0.74C12.61 0.26 11.34 0 10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10H18ZM5.91 8.08L4.5 9.5L9 14L19 4L17.59 2.58L9 11.17L5.91 8.08Z"
      fill="currentColor"
    />
  </Icon>
);
