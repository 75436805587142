import type { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Card,
  CustomTooltipChart,
  GotoLink,
  MetricWithDescription,
  Price,
  SimpleAlertIcon,
  SimpleHelpIcon,
  Tooltip,
} from '@graneet/lib-ui';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/react';

interface BillAwaitingPaymentAmountCardProps {
  amount?: number;
  amountLateIncVAT?: number;
  amountToPayInLessThanThirtyDaysIncVAT?: number;
  amountToPayInMoreThanThirtyDaysIncVAT?: number;
  redirectionLink?: string;
}

export const BillAwaitingPaymentAmountCard: FC<BillAwaitingPaymentAmountCardProps> = ({
  amount = 0,
  amountLateIncVAT,
  amountToPayInLessThanThirtyDaysIncVAT,
  amountToPayInMoreThanThirtyDaysIncVAT,
  redirectionLink = '/sales/bills',
}) => {
  const { t } = useTranslation(['dashboard', 'bill', 'project']);

  return (
    <Card
      title={t('dashboard:waitingBill')}
      topRightContent={<GotoLink to={redirectionLink} label={t(`bill:cards.awaitingPaymentCard.goTo`)} />}
    >
      <MetricWithDescription fontSize="4xl" description={t('project:fields.totalAmountIncVAT')} color="green.600">
        {amount > 0 && <AddIcon w="1rem" mr="0.4rem" mb="0.3rem" />}
        {amount < 0 && <MinusIcon w="1rem" mr="0.4rem" mb="0.3rem" />}

        <Price amount={amount} negative={amount < 0} />
        <Tooltip
          label={
            <CustomTooltipChart
              legends={{
                latePayment: (
                  <Box color="orange.500">
                    <SimpleAlertIcon mb="0.2rem" mr="0.2rem" />
                    {t('bill:cards.awaitingPaymentCard.lateBills')}
                  </Box>
                ),
                toBePaidInLessThanThirtyDays: (
                  <Trans t={t} i18nKey="bill:cards.awaitingPaymentCard.toBePaidInLessThanThirtyDays">
                    <Text as="span" fontWeight="semibold">
                      displayed_data
                    </Text>
                    description_end
                  </Trans>
                ),
                toBePaidInMoreThanThirtyDays: (
                  <Trans t={t} i18nKey="bill:cards.awaitingPaymentCard.toBePaidInMoreThanThirtyDays">
                    <Text as="span" fontWeight="semibold">
                      displayed_data
                    </Text>
                    description_end
                  </Trans>
                ),
              }}
              active
              hasBullets={false}
              payload={[
                { name: 'latePayment', value: amountLateIncVAT, textColor: 'orange.500' },
                { name: 'toBePaidInLessThanThirtyDays', value: amountToPayInLessThanThirtyDaysIncVAT },
                { name: 'toBePaidInMoreThanThirtyDays', value: amountToPayInMoreThanThirtyDaysIncVAT },
              ]}
            />
          }
        >
          <Box>
            <SimpleHelpIcon color="gray.300" w="1.3rem" mb="0.5rem" ml="0.6rem" />
          </Box>
        </Tooltip>
      </MetricWithDescription>
    </Card>
  );
};
