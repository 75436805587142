import { MARGIN_TYPE, QuoteMargin } from '@org/quotation-lib';
import { useCallback } from 'react';
import Big from 'big.js';

import type { QuoteUpdateMarginWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import {
  quoteClientRequestsSetToStore,
  StatusEnum,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';

export const useCreateQuoteUpdatedMarginCallback = () =>
  useCallback((message: QuoteUpdateMarginWsMessage) => {
    const quote = quoteGetFromStore();
    const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();
    const margins = message.data;
    const oldMargin = new QuoteMargin(quote?.getMargin().export());

    if (!quote) {
      return;
    }

    const hasClientRequest = quoteClientRequestsFromStore.find(
      (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
    );

    if (hasClientRequest !== undefined) {
      quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
      return;
    }

    if (!Big(margins.overheadCosts).eq(oldMargin.getOverheadCosts())) {
      const newTotalMargin = new Big(oldMargin.getProfitMargin()).mul(Big(margins.overheadCosts)).toString();
      quote.updateTotalMargin(Big(newTotalMargin), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.OVERHEAD_COSTS,
      });
      quoteSetToStore(quote);
      return;
    }

    if (!Big(margins.profitMargin).eq(oldMargin.getProfitMargin())) {
      const newTotalMargin = new Big(margins.profitMargin).mul(oldMargin.getOverheadCosts()).toString();
      quote.updateTotalMargin(Big(newTotalMargin), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });
      quoteSetToStore(quote);
      return;
    }

    if (!Big(margins.totalMargin).eq(oldMargin.getTotalMargin())) {
      quote.updateTotalMargin(Big(margins.totalMargin), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });
      quoteSetToStore(quote);
    }
  }, []);
