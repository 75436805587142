import { Box, Flex, Skeleton } from '@chakra-ui/react';

export const QuoteEditTableSkeleton = () => (
  <Flex direction="column" height="100%" width="100%">
    <Flex direction="column" width="100%" height="100%" minHeight="70%">
      <Flex className="ag-theme-quartz ag-custom-quote-root" width="100%" flexGrow={1}>
        <Box width="100%" height="100%">
          <Skeleton
            width="100%"
            height="100%"
            startColor="gray.50"
            endColor="gray.50"
            borderRadius="8px"
            boxShadow="0px 2px 4px 0px rgba(17, 24, 28, 0.12), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px rgba(17, 24, 28, 0.08)"
          />
        </Box>
      </Flex>
    </Flex>
  </Flex>
);
