import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';

interface AccountingExportNoPermissionStepProps {
  onClose(): void;
}

export const AccountingExportNoPermissionStep: FC<AccountingExportNoPermissionStepProps> = ({ onClose }) => {
  const { t } = useTranslation(['global']);

  return (
    <>
      <Modal.PrimaryButton onClick={onClose} variant="outline">
        {t('global:words.c.close')}
      </Modal.PrimaryButton>
    </>
  );
};
