import type {
  IQuoteComponentCreationDTO,
  IQuoteComponentResponseDTO,
  IQuoteComponentUpdateDTO,
} from '@graneet/business-logic';

import { api } from 'features/api/services/api.service';

const QUOTE_COMPONENT = '/quote-components';

export const fetchJobComponents = (jobId: number) =>
  api.get<never, IQuoteComponentResponseDTO>(`${QUOTE_COMPONENT}?jobId=${jobId}`);

export const createComponent = (jobId: number) =>
  api.post<IQuoteComponentCreationDTO, IQuoteComponentResponseDTO>(QUOTE_COMPONENT, { jobId });

export const editComponent = (componentId: number, quoteComponentUpdateDTO: IQuoteComponentUpdateDTO) =>
  api.patch<IQuoteComponentUpdateDTO, IQuoteComponentResponseDTO>(
    `${QUOTE_COMPONENT}/${componentId}`,
    quoteComponentUpdateDTO,
  );

export const deleteComponent = (componentId: number) =>
  api.delete<never, IQuoteComponentResponseDTO>(`${QUOTE_COMPONENT}/${componentId}`);

export const duplicateComponent = (componentId: number) =>
  api.post<never, IQuoteComponentResponseDTO>(`${QUOTE_COMPONENT}/${componentId}/duplicate`);
