import type { FC } from 'react';

import { SimpleFlipBackwardIcon } from '../../Icons';

import type { ActionProps } from './Action';
import { Action } from './Action';

export const Restore: FC<Omit<ActionProps, 'icon'>> = (props) => (
  <Action icon={<SimpleFlipBackwardIcon />} {...props} />
);
