import { useCallback } from 'react';
import type { SuppressKeyboardEventParams } from '@ag-grid-community/core';

import { AmountCell } from '../../components/columns/AmountCell/AmountCell';
import type { AGColDef } from '../../types';

export function useFlatCostAmountColumnDef() {
  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'flatCostAmount',
      width,
      index,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressMovable: false,
      headerName: label,
      editable: false,
      sortable: false,
      cellStyle: { textAlign: 'right' },
      cellClass: 'no-focus read-only',
      cellRenderer: AmountCell,
      valueGetter: (params) => params.data?.content.flatCostAmount,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [],
  );
}
