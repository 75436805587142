import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from '@chakra-ui/react';
import type { IProgressStatement } from '@graneet/business-logic';
import { Badge } from '@graneet/lib-ui';

import { PROGRESS_STATEMENT_STATUS_COLORS } from 'features/progress-statement/services/progress-statement.constant';

export type ProgressStatementFieldProps = {
  progressStatement: IProgressStatement;
};

export const ProgressStatementField: FC<ProgressStatementFieldProps> = ({ progressStatement }) => {
  const { t } = useTranslation(['progressStatement']);

  return (
    <Flex alignItems="baseline">
      <Badge colorScheme={PROGRESS_STATEMENT_STATUS_COLORS[progressStatement.status]}>
        {t(`progressStatement:statuses.${progressStatement.status}`)}
      </Badge>
      <Box fontFamily="heading" px={2} color="gray.800">
        {progressStatement.name}
      </Box>

      {progressStatement.invoiceNumber ? (
        <>
          <Text mx={4} color="gray.800">
            -
          </Text>

          <Box fontFamily="heading" px={2} color="gray.800">
            {progressStatement.invoiceNumber}
          </Box>
        </>
      ) : null}
      <Text mx={2} fontSize="xs" color="gray.500">
        {progressStatement.project?.name}
      </Text>
    </Flex>
  );
};
