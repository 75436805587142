import type { IconProps } from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/react';
import type { FC } from 'react';

import { SimpleFileAttachmentIcon } from '../Icons';

export interface GenericFileIconProps extends IconProps {
  isArchived?: boolean;
}

export const GenericFileIcon: FC<GenericFileIconProps> = ({ isArchived = false, ...rest }) => {
  const { colors } = useTheme();

  return <SimpleFileAttachmentIcon stroke={isArchived ? colors.gray[300] : colors.blue[500]} {...rest} />;
};
