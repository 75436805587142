import { useCallback } from 'react';
import type { Quote, QuoteComponentsGroupByType } from '@org/quotation-lib';
import Big from 'big.js';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import {
  useQuoteSetClientRequestsStore,
  StatusEnum,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';

export function useQuoteLotUpdateProfitMarginByComponentType() {
  const { quoteLotsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, data: QuoteComponentsGroupByType, newValue: string): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
          this.oldValue = {
            profitMargin: Big(data.profitMargin ?? 1),
            data,
          };
          quoteLot.updateComponentProfitMarginByComponentType(data.componentTypeId, Big(newValue));
          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteLotsProxyApi
            .updateByComponentType(quoteLot.getId(), Number(data.componentTypeId), {
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              profitMargin: newValue,
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          quoteSetToStore(quote);
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
          this.oldValue = {
            profitMargin: quoteLot.getMargin().getProfitMargin(),
            data,
          };
          quoteLot.updateComponentProfitMarginByComponentType(
            this.oldValue.data.componentTypeId,
            this.oldValue.profitMargin,
          );
          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteLotsProxyApi
            .updateByComponentType(quoteLot.getId(), Number(this.oldValue.data.componentTypeId), {
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              profitMargin: this.oldValue.profitMargin,
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          quoteSetToStore(quote);
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteLotsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
