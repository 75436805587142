import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { IOrder, SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';
import {
  PERMISSION,
  isDirectPaymentSupplierInvoice as isDirectPaymentSupplierInvoiceFn,
} from '@graneet/business-logic';
import { Button, Card, SimpleAddIcon, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { useSupplierInvoiceUpdate } from '../../../services/supplier-invoice.api';
import { PickOrCreateOrderModal } from '../../modals/PickOrCreateOrderModal/PickOrCreateOrderModal';
import { AssociatedOrdersAmounts } from '../../AssociatedOrdersAmounts';
import { OrdersList } from '../../OrderList';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface AssociatedOrdersCardProps {
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}

export const AssociatedOrdersCard: FC<AssociatedOrdersCardProps> = ({ supplierInvoice }) => {
  const toast = useToast();
  const isDirectPaymentSupplierInvoice = isDirectPaymentSupplierInvoiceFn(supplierInvoice);

  const supplierInvoiceUpdateMutation = useSupplierInvoiceUpdate();

  const orders = useMemo(
    () => supplierInvoice.ordersSupplierInvoices?.map(({ order }) => order) || [],
    [supplierInvoice],
  );
  const { t } = useTranslation(['supplierInvoices']);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onDeleteOrder = useCallback(
    async (order: IOrder) => {
      await supplierInvoiceUpdateMutation.mutateAsync({
        id: supplierInvoice.id,
        dto: {
          deletedOrders: JSON.stringify([order.id]),
          deletedReceiptFilesIds: '[]',
          deleteInvoiceFile: 'false',
        },
      });
      toast.success(
        t('supplierInvoices:associate.deleteSuccess', { invoiceName: supplierInvoice.name, orderName: order.name }),
      );
    },
    [supplierInvoiceUpdateMutation, supplierInvoice.id, supplierInvoice.name, toast, t],
  );

  const getAssociateButtonProps = useDisabledButtonProps([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  return (
    <Card title={t('supplierInvoices:associate.title')}>
      <OrdersList
        orders={orders}
        onDeleteOrder={onDeleteOrder}
        isDirectPaymentSupplierInvoice={isDirectPaymentSupplierInvoice}
      />

      {!isDirectPaymentSupplierInvoice ? (
        <>
          <Button
            variant="outline"
            leftIcon={<SimpleAddIcon />}
            colorScheme="gray"
            onClick={openModal}
            {...getAssociateButtonProps()}
          >
            {t('supplierInvoices:associate.associateNew')}
          </Button>
          <PickOrCreateOrderModal supplierInvoice={supplierInvoice} isOpen={isOpen} onClose={onClose} />
        </>
      ) : null}
      <AssociatedOrdersAmounts orders={orders} invoiceAmountExVAT={supplierInvoice.amountExVAT ?? 0} />
    </Card>
  );
};
