import { Text, Checkbox, Flex, Grid } from '@chakra-ui/react';
import { HiddenField, useFormContext, useOnChangeValues } from 'graneet-form';
import { every } from 'lodash-es';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useMemo } from 'react';

export interface CheckBoxFiltersProps<Name = string> {
  label: string;
  name: Name;
  availableValues: { label: string; value: string }[];
}

export const CheckBoxFilters: FC<CheckBoxFiltersProps> = ({ label, name, availableValues }) => {
  const form = useFormContext<{ [name: string]: string[] | undefined | null }>();

  const { getFormValues, setFormValues } = form;

  const values = useOnChangeValues(form, [name]);

  const currentValues = getFormValues();

  const selectedValues = useMemo(
    () => [...(values[name] ?? []), ...(currentValues[name] ?? [])],
    [currentValues, values, name],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.currentTarget.checked) {
        const newValue = currentValues[name]
          ? [...currentValues[name]!, ...e.currentTarget.value.split('/')]
          : [...e.currentTarget.value.split('/')];
        setFormValues({ [name]: newValue });
      } else {
        setFormValues({ [name]: currentValues[name]?.filter((value: string) => value !== e.currentTarget.value) });
      }
    },
    [name, setFormValues, currentValues],
  );

  return (
    <Flex gap={3}>
      <Text w="12rem" fontSize="sm" color="baseSecondary">
        {label}
      </Text>

      <HiddenField name={name} />

      <Grid templateColumns="repeat(2, 50%)" w="100%" gridGap={2}>
        {availableValues.map(({ label: checkBoxLabel, value }) => (
          <Checkbox
            colorScheme="greenBrand"
            key={Array.isArray(value) ? value.join('/') : value}
            name={name}
            value={Array.isArray(value) ? value.join('/') : value}
            isChecked={
              Array.isArray(value) ? every(value, (v) => selectedValues.includes(v)) : selectedValues?.includes(value)
            }
            onChange={handleChange}
          >
            <Text color="primaryLight" fontSize="14px">
              {checkBoxLabel}
            </Text>
          </Checkbox>
        ))}
      </Grid>
    </Flex>
  );
};
