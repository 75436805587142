import { Flex, Switch, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useQuoteDisplayContext } from '../../hooks/useQuoteDisplayContext';

export const QuoteSalesViewSwitch = () => {
  const { t } = useTranslation(['quote']);
  const { displaySaleView, setDisplaySaleView } = useQuoteDisplayContext();

  const onToggleValueChanged = () => setDisplaySaleView((isDisplayed) => !isDisplayed);

  return (
    <Flex>
      <Text fontSize="sm" fontWeight={displaySaleView ? '500' : '600'} minW="4.5rem">
        {t('quote:jobsStep.views.disbursement')}
      </Text>

      <Switch mx={3} colorScheme="blue" onChange={onToggleValueChanged} defaultChecked={displaySaleView} />

      <Text fontSize="sm" fontWeight={displaySaleView ? '600' : '500'} minW="4.5rem">
        {t('quote:jobsStep.views.sale')}
      </Text>
    </Flex>
  );
};
