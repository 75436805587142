import type { QuoteNodeCluster, QuoteNodeObject } from '@org/quotation-lib';
import { QuoteNodeImportCluster } from '@org/quotation-lib';

import type { QuoteNodesCreateWsMessage } from '../../quote-common/interfaces/quoteWsMessage';

import {
  quoteClientRequestsSetToStore,
  StatusEnum,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';

const compareAutomaticIndex = (automaticIndex1: string, automaticIndex2: string) => {
  const v1Components = automaticIndex1.split('.').map(Number);
  const v2Components = automaticIndex2.split('.').map(Number);
  const maxLength = Math.max(v1Components.length, v2Components.length);

  for (let i = 0; i < maxLength; i += 1) {
    const v1 = v1Components[i] || 0;
    const v2 = v2Components[i] || 0;
    if (v1 !== v2) return v1 < v2 ? automaticIndex1 : automaticIndex2;
  }
  return automaticIndex1.length < automaticIndex2.length ? automaticIndex1 : automaticIndex2;
};

const getSmallestAutomaticIndex = (nodeToCopy: QuoteNodeObject[]) => {
  const automaticIndexes = nodeToCopy.map((node) => node.content.automaticIndex);
  return automaticIndexes.reduce(
    (acc: string, cur) => (cur ? compareAutomaticIndex(acc, cur) : acc),
    automaticIndexes[0] || '',
  );
};

export const createQuoteCreateManyCallback = (message: QuoteNodesCreateWsMessage) => {
  const quote = quoteGetFromStore();

  if (!quote) {
    return;
  }

  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();
  const hasClientRequest = quoteClientRequestsFromStore.find(
    (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
  );

  if (hasClientRequest === undefined) {
    const quoteNodeObjects: QuoteNodeObject[] = [...message.data];

    const QuoteNodeClusters: QuoteNodeCluster[] = [];

    while (quoteNodeObjects.length > 0) {
      const smallIndex = getSmallestAutomaticIndex(quoteNodeObjects);

      // get smallIndex in quoteNodeObjects and delete it in quoteNodeObjects
      const nodeObject = quoteNodeObjects.find((node) => node.content.automaticIndex === smallIndex);

      if (!nodeObject) {
        throw new Error('Cannot find node with automaticIndex');
      }

      quoteNodeObjects.splice(quoteNodeObjects.indexOf(nodeObject), 1);

      const childrenObject: QuoteNodeObject[] = quoteNodeObjects.filter((node) =>
        node.content.automaticIndex?.startsWith(smallIndex),
      );

      childrenObject.forEach((child) => {
        quoteNodeObjects.splice(quoteNodeObjects.indexOf(child), 1);
      });

      const quoteNodeCluster: QuoteNodeCluster = {
        nodeObject,
        childrenObject,
      };

      QuoteNodeClusters.push(quoteNodeCluster);
    }

    QuoteNodeClusters.reverse().forEach((cluster) => {
      const { parentId } = cluster.nodeObject;

      if (!parentId) {
        throw new Error('Cannot append node without parent');
      }

      const parentNode = quote.getTree().getNodeOrThrow(parentId);
      const prevSiblingId = null;
      const nextSiblingId = parentNode.getFirstChild()?.getId() || null;

      QuoteNodeImportCluster.execute(quote, cluster, parentId, prevSiblingId, nextSiblingId);
    });

    quoteSetToStore(quote);
  } else {
    quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, hasClientRequest, message.nbEventPusher);
  }
};
