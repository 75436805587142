import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

const EVENT = {
  ONLINE: 'online',
  OFFLINE: 'offline',
};

export const useOnLineStatus = ({ onRecovered, onLost }: { onRecovered(): void; onLost(): void }) => {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const isOnlineRef = useRef(true);
  const networkStatusToastIdRef = useRef(0);

  useEffect(() => {
    if (window.navigator.onLine) {
      onRecovered();
    } else {
      onLost();
    }
  }, [onRecovered, onLost]);

  useEffect(() => {
    const handleOnline = async () => {
      if (!isOnlineRef.current && networkStatusToastIdRef.current) {
        toast.update(networkStatusToastIdRef.current, 'success', t('global:network.restored'), '', {
          variant: 'customSuccess',
          duration: 2000,
          isClosable: true,
        });
        isOnlineRef.current = true;
        await onRecovered();
      }
    };

    const handleOffline = async () => {
      // Close existing toast
      if (networkStatusToastIdRef.current) {
        toast.close(networkStatusToastIdRef.current);
      }

      if (isOnlineRef.current) {
        const id = networkStatusToastIdRef.current + 1;

        toast.error(t('global:network.lost'), '', {
          id,
          duration: null,
          isClosable: false,
        });

        networkStatusToastIdRef.current = id;
        isOnlineRef.current = false;

        await onLost();
      }
    };

    window.addEventListener(EVENT.ONLINE, handleOnline);
    window.addEventListener(EVENT.OFFLINE, handleOffline);

    return () => {
      window.removeEventListener(EVENT.ONLINE, handleOnline);
      window.removeEventListener(EVENT.OFFLINE, handleOffline);
    };
  }, [onRecovered, onLost, t, toast]);
};
