import { Box } from '@chakra-ui/react';
import type { PDF_TEMPLATES_TYPES } from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import type { PDFSettingsFormValues } from '../constants/pdf-settings.constant';

import { getEnvValue } from 'config/env';
import { useAppContext } from 'features/app/contexts/AppContext';
import { Loading } from 'features/common/components/Loading';

export const PdfTemplateFrame: FC = () => {
  const form = useFormContext<PDFSettingsFormValues>();
  const settings = useOnChangeValues(form, undefined);
  const { currentUser } = useAppContext();
  const { company } = currentUser || {};
  const { template } = useParams<{ template: PDF_TEMPLATES_TYPES }>();
  const url = `${getEnvValue('REACT_APP_PDF_TEMPLATE_URL')}/template/${template}`;
  const [isLoading, setIsLoading] = useState(true);
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);

  const generateFrameMessageEmitter = useCallback(
    (key: string, payload: Record<string, any>) => {
      const data = {
        key,
        payload,
      };
      const frame = iFrameRef.current;
      if (!frame) return;
      frame.contentWindow?.postMessage(JSON.stringify(data), url);
    },
    [iFrameRef, url],
  );

  useEffect(() => {
    generateFrameMessageEmitter('pdf_settings', settings);
  }, [generateFrameMessageEmitter, settings]);

  // Adding a small timeout as onloading event is fired before iframe is ready to receive env
  const handleOnLoad = useCallback(
    () =>
      setTimeout(() => {
        setIsLoading(false);
        generateFrameMessageEmitter('pdf_settings', settings);
        generateFrameMessageEmitter('data_preview', { company });
      }, 500),
    [company, generateFrameMessageEmitter, settings],
  );

  const frameStyle = useMemo(
    () => ({
      opacity: isLoading ? 0 : 1,
      width: '100%',
    }),
    [isLoading],
  );

  return (
    <Box height="100%" width="100%" background="backgroundLighter">
      {isLoading && <Loading />}

      <iframe
        id="frame-pdf-preview"
        style={frameStyle}
        ref={iFrameRef}
        title="pdf"
        height="100%"
        onLoad={handleOnLoad}
        src={url}
      />
    </Box>
  );
};
