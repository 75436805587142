import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Card, Badge, DocumentStatusCard, DocumentStatusCardActions, formatDateOrEmpty } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type {
  IContactWithProjectInfos,
  ICredit,
  IGroupedContact,
  IProject,
  RequiredByKeys,
} from '@graneet/business-logic';
import { getEmailVariableData, PDF_STATUSES } from '@graneet/business-logic';
import { useDisclosure } from '@chakra-ui/react';

import { CREDIT_STATUS_COLORS } from '../../constants/credit.constant';

import { getProjectDefaultEmailRecipient } from 'features/contact/services/contact.util';
import type { MailingModalProps } from 'features/mailing/components/MailingModal/MailingModal';
import { sendMail } from 'features/mailing/services/mailing.api';
import { MailingModal } from 'features/mailing/components/MailingModal/MailingModal';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import { formatFileName } from 'features/file/services/file.util';
import { getDownloadUrl, getPreviewUrl } from 'features/pdf/services/pdf.api';
import { getCreditActions } from 'features/credit/services/credit.actions';

interface CreditStatusCardProps {
  credit: ICredit;

  project?: RequiredByKeys<IProject, 'address'> | null;

  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
}

export const CreditStatusCard: FC<CreditStatusCardProps> = ({ credit, project, projectContacts }) => {
  const { t } = useTranslation(['credit', 'mailing']);

  /**
   * Mailing
   */
  const mailingModal = useDisclosure();
  const [isPdfGenerated, setIsPdfGenerated] = useState(credit.pdf?.status === PDF_STATUSES.GENERATED);

  useEffect(() => {
    if (credit?.pdf?.status === PDF_STATUSES.GENERATING) {
      subscribeToPDFUpdates({ apiId: credit.pdf!.apiId }, (pdfStatus) => {
        setIsPdfGenerated(pdfStatus === PDF_STATUSES.GENERATED);
      });
    }
  }, [credit]);

  const onViewPdf = useCallback(() => {
    if (credit.pdf) {
      window.open(getPreviewUrl(credit.pdf.apiId));
    }
  }, [credit.pdf]);

  const onDownloadPdf = useCallback(() => {
    if (credit.pdf) {
      window.open(
        getDownloadUrl(credit.pdf.apiId, formatFileName(credit.name, credit.creditDate, credit.invoiceNumber)),
      );
    }
  }, [credit.creditDate, credit.invoiceNumber, credit.name, credit.pdf]);

  const onMailSubmit = useCallback<MailingModalProps['onSubmit']>(
    (data) => sendMail(data.dto, data.attachments, data.mode),
    [],
  );

  return (
    <Card title={t('credit:titles.state')}>
      <DocumentStatusCard
        mb={4}
        icon="completed"
        title={credit.name}
        subtitle={t('credit:fields.billingDate', {
          date: formatDateOrEmpty(credit.creditDate, 'LL'),
        })}
        badgeComponent={
          <Badge colorScheme={CREDIT_STATUS_COLORS[credit.status]}>{t(`credit:statuses.${credit.status}`)}</Badge>
        }
      />
      <DocumentStatusCardActions
        actions={getCreditActions({
          // Hack to add dependency on isPdfGenerated to force rerender and avoid infinite spinner
          pdf: credit?.pdf
            ? { ...credit.pdf, status: isPdfGenerated ? PDF_STATUSES.GENERATED : credit.pdf?.status }
            : undefined,
          onViewPdf,
          onDownloadPdf,
          onOpenMailing: mailingModal.onOpen,
        })}
      />
      {credit.pdf && isPdfGenerated && (
        <MailingModal
          title={t('mailing:modal.title.credit')}
          onSubmit={onMailSubmit}
          isOpen={mailingModal.isOpen}
          onClose={mailingModal.onClose}
          variableData={getEmailVariableData('credit', {
            credit,
            client: credit.client!,
            project: project || null,
          })}
          defaultMailContacts={getProjectDefaultEmailRecipient(projectContacts)}
        />
      )}
    </Card>
  );
};
