import { AbsoluteCenter, useDisclosure } from '@chakra-ui/react';
import { EmptyStateActions, EmptyStateButtons, SimpleUserIcon, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import type { IClient, IClientResponseDTO, IContact } from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';

import { ClientContactCreateStepperModal } from 'features/client/components/modals/ClientContactCreateStepperModal/ClientContactCreateStepperModal';
import type { ClientContactSelectionStepperModalProps } from 'features/client/components/modals/ClientExistingAssociateContactStepperModal/ClientContactSelectionStepperModal';
import { ClientContactSelectionStepperModal } from 'features/client/components/modals/ClientExistingAssociateContactStepperModal/ClientContactSelectionStepperModal';
import { useQuoteUpdateClient } from 'features/quotation/quote/hooks/useQuoteUpdateClient';
import { useQuoteUpdateContact } from 'features/quotation/quote/hooks/useQuoteUpdateContact';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { getClientById } from 'features/client/services/client.api';
import { useStore } from 'store/store';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const QuoteClientEmptyState = () => {
  const { t } = useTranslation(['global', 'quote']);
  const toast = useToast();
  const { quote } = useQuote();
  const { quoteContactsProxyApi } = useQuotationProxyApis();
  const quoteUpdateClient = useQuoteUpdateClient();
  const quoteUpdateContact = useQuoteUpdateContact();
  const [client, setClient] = useState<IClient | IClientResponseDTO | null>(null);
  const isEditable = useStore((store) => store.quoteEditable);

  const createClientModal = useDisclosure();
  const selectClientModal = useDisclosure();

  const onClientCreated = useCallback((clientResponse: IClientResponseDTO) => {
    setClient(clientResponse);
  }, []);

  const onClientSelected = useCallback(
    async (clientId: number) => {
      const [err, response] = await getClientById(clientId);
      if (err) {
        toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
      }
      setClient(response);
      return response;
    },
    [t, toast],
  );

  const onContactCreated = useCallback(
    (contact: IContact) => {
      quoteUpdateClient(client);
      // Case 1 contact is newly created from a new client => contacts are overridden
      quoteUpdateContact([contact]);
    },
    [client, quoteUpdateClient, quoteUpdateContact],
  );

  const onContactsSelected = useCallback(
    (contacts: { entity: IContact; isDefaultEmailRecipient: boolean }[]) => {
      // Case 2 contacts are selected from an existing client => contacts are added
      quoteUpdateContact(contacts.map(({ entity }) => entity));
    },
    [quoteUpdateContact],
  );

  const getQuoteContactsPaginatedGetter = useCallback(
    (params: URLSearchParams | undefined) => quoteContactsProxyApi!.getAvailableContactsForQuote(params),
    [quoteContactsProxyApi],
  );
  const contactsRetriever = useCallback<ClientContactSelectionStepperModalProps<IContact>['contactsRetriever']>(
    (clientId: number) => {
      if (!quote || !client) {
        throw new Error('Quote and client must be defined');
      }
      return [{ quoteId: quote.getId(), clientId }, getQuoteContactsPaginatedGetter];
    },
    [quote, client, getQuoteContactsPaginatedGetter],
  );

  const handleClientSelectionModalOnClose = useCallback(async () => {
    quoteUpdateClient(client);
    selectClientModal.onClose();
  }, [client, quoteUpdateClient, selectClientModal]);

  const handleCreateClientModalOnClose = useCallback(async () => {
    quoteUpdateClient(client);
    createClientModal.onClose();
  }, [client, createClientModal, quoteUpdateClient]);

  return (
    <>
      <AbsoluteCenter>
        <EmptyStateActions
          title={t('quote:drawer.client.emptyState.title')}
          description={t('quote:drawer.client.emptyState.description')}
          icon={<SimpleUserIcon />}
        >
          {isEditable && (
            <EmptyStateButtons
              primaryAction={{
                title: t('quote:drawer.client.emptyState.associateClient'),
                onClick: selectClientModal.onOpen,
              }}
              secondaryAction={{
                title: t('quote:drawer.client.emptyState.createClient'),
                onClick: createClientModal.onOpen,
              }}
            />
          )}
        </EmptyStateActions>
      </AbsoluteCenter>

      {isEditable && (
        <>
          <ClientContactSelectionStepperModal
            isOpen={selectClientModal.isOpen}
            onClose={handleClientSelectionModalOnClose}
            onClientSelected={onClientSelected}
            onSubmit={onContactsSelected}
            contactsRetriever={contactsRetriever}
            isContactDefaultEmailRecipient={(contact) => contact.isDefaultEmailRecipient}
          />

          <ClientContactCreateStepperModal
            isOpen={createClientModal.isOpen}
            onClose={handleCreateClientModalOnClose}
            onClientCreated={onClientCreated}
            onContactCreated={onContactCreated}
          />
        </>
      )}
    </>
  );
};
