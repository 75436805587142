/* eslint-disable react/no-array-index-key */
import type { IconProps } from '@chakra-ui/react';
import { Box, HStack, Menu, MenuButton, MenuItem, MenuList, Text, Portal, useOutsideClick } from '@chakra-ui/react';
import type { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { cloneElement, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { v4 as uuidV4 } from 'uuid';

import { SimpleChevronRightIcon } from '../../Icons/v2/SimpleChevronRightIcon';
import { Tooltip } from '../../Tooltip';

import { MultiLevelMenuProvider, useMultiLevelMenu } from './MultiLevelMenuProvider';

const COLORS_CONFIG = {
  ghost: {
    color: '#6C737F',
    hoverBackground: 'rgba(0, 21, 63, 0.05)',
  },
  default: {
    color: '#1F2A37',
    hoverBackground: 'rgba(0, 21, 63, 0.05)',
  },
  danger: {
    color: '#D92D20',
    hoverBackground: '#FEE4E2',
  },
} as const;

export type MultiLevelMenuHandle = {
  open: (x: number, y: number) => void;
  close: () => void;
};

export type MenuItemClassic = {
  name: string;
  icon?: ReactElement<IconProps>;
  color?: 'default' | 'danger' | 'ghost';
};

export type MenuItemCustom = {
  customElement: ReactNode;
};

export type MenuData = {
  type: 'item';
  onClick: (element?: any) => void;
  disabled?: boolean;
  tooltip?: string;
  shortcut?: string;
  id: string;
  items?: (MenuData | MenuGroup)[];
} & (MenuItemClassic | MenuItemCustom);

export type MenuGroup = {
  type: 'group';
  id: string;
  items: MenuData[];
};

export type MenuElement = MenuData | MenuGroup;

export interface MultiLevelMenuProps extends PropsWithChildren {
  items: MenuElement[];
  id: string;
  level?: number;
  placement?: 'right' | 'left';
  onClose?: () => void;
}

const getItemsLevel = (items: MenuElement[], level: number, record: Record<number, string[]>) => {
  let newRecord = { ...record };
  items.forEach((item) => {
    if (!newRecord[level]) {
      newRecord[level] = [];
    }
    newRecord[level].push(item.id);
    if (item.items && item.items.length > 0) {
      newRecord = { ...newRecord, ...getItemsLevel(item.items, level + 1, newRecord) };
    }
  });
  return newRecord;
};

const MultiLevelMenuItem: FC<{ item: MenuData; level: number; onMouseEnterItem: () => void }> = ({
  item,
  level,
  onMouseEnterItem,
}) => {
  const { placement, setPosition, subMenuStates } = useMultiLevelMenu();

  const { color, hoverBackground } = COLORS_CONFIG['color' in item ? item.color || 'default' : 'default'];
  const iconElement =
    'icon' in item && item.icon ? cloneElement(item.icon, { boxSize: '0.75rem', stroke: color }) : null;
  const subIconElement =
    item.items && item.items.length > 0 ? (
      <SimpleChevronRightIcon
        boxSize="0.75rem"
        stroke="#1F2A37"
        transform={placement === 'left' ? 'rotate(-180deg)' : undefined}
      />
    ) : null;

  const handleOnClick = useCallback(() => {
    if (item.disabled) return;
    if (item.items && item.items.length > 0) return;

    item.onClick();
    setPosition(null);
  }, [item, setPosition]);

  const handleOnMouseEnter = useCallback(() => {
    if (item.disabled) return;

    onMouseEnterItem();
  }, [item, onMouseEnterItem]);

  const elem =
    'name' in item && item.name ? (
      <MenuItem
        as={Box}
        onClick={handleOnClick}
        onMouseMove={item.items && item.items.length > 0 ? undefined : handleOnMouseEnter}
        px="0.875rem"
        py="0.25rem"
        cursor={item.disabled ? 'not-allowed' : 'pointer'}
        _hover={{
          background: item.disabled ? 'transparent' : hoverBackground,
        }}
        background={subMenuStates[item.id] ? hoverBackground : 'transparent'}
      >
        <HStack
          alignItems="center"
          gap={2}
          height="1.25rem"
          width="100%"
          justifyContent={placement === 'right' ? 'flex-start' : 'flex-end'}
          opacity={item.disabled ? 0.5 : 1}
        >
          {placement === 'left' ? (
            <>
              {subIconElement && (
                <Box alignSelf="flex-end" alignItems="center" height="100%" mr="auto" display="flex">
                  {subIconElement}
                </Box>
              )}
            </>
          ) : (
            <>{iconElement}</>
          )}
          <Text fontSize="13px" fontStyle="normal" fontWeight={500} color={color}>
            {item.name}
          </Text>
          {placement === 'right' ? (
            <>
              {subIconElement && (
                <Box alignSelf="flex-end" alignItems="center" height="100%" ml="auto" display="flex">
                  {subIconElement}
                </Box>
              )}
            </>
          ) : (
            <>{iconElement}</>
          )}
        </HStack>
      </MenuItem>
    ) : (
      'customElement' in item && (
        <MenuItem
          onClick={handleOnClick}
          as={Box}
          onMouseMove={item.items && item.items.length > 0 ? undefined : handleOnMouseEnter}
          px="0.875rem"
          py="0.25rem"
          cursor={item.disabled ? 'not-allowed' : 'pointer'}
          _hover={{
            background: item.disabled ? 'transparent' : hoverBackground,
          }}
        >
          {item.customElement}
        </MenuItem>
      )
    );

  return item.items && item.items.length > 0 ? (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <InternalMultiLevelMenu items={item.items} level={level + 1} id={item.id}>
      {elem}
    </InternalMultiLevelMenu>
  ) : (
    <>
      {item.disabled && item.tooltip ? (
        <Tooltip label={item.tooltip} placement="right">
          {elem}
        </Tooltip>
      ) : (
        elem
      )}
    </>
  );
};

const MultiLevelMenuGroup: FC<{ item: MenuGroup; level: number; onMouseEnterItem: () => void }> = ({
  item,
  level,
  onMouseEnterItem,
}) => {
  const { items } = item;

  if (items && items.length !== 0) {
    return (
      <>
        {items.map((subItem, index) => (
          <MultiLevelMenuItem key={`item-${index}`} item={subItem} level={level} onMouseEnterItem={onMouseEnterItem} />
        ))}
        <Box borderBottom="0.063rem solid rgba(0, 19, 58, 0.10)" my="0.188rem" />
      </>
    );
  }

  return null;
};

const InternalMultiLevelMenu = forwardRef<MultiLevelMenuHandle, MultiLevelMenuProps>(
  ({ items, children, level = 0, onClose, placement: definedPlacement, id }, ref) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const {
      hasSubMenuOpen,
      handleMenuStateChange,
      menuStates,
      subMenuStates,
      closeSubMenus,
      setPlacement,
      placement,
      position,
      setPosition,
      setMenuLevelAndIds,
    } = useMultiLevelMenu();

    useEffect(() => {
      if (level === 0) {
        if (position) {
          const { x, y } = position;

          const viewPortWidth = window.innerWidth;
          const viewPortHeight = window.innerHeight;
          const contentWidth = menuRef.current?.clientWidth || 210;
          const contentHeight = menuRef.current?.clientHeight || 210;

          const newX = x + contentWidth > viewPortWidth ? x - contentWidth : x;
          const newY = y + contentHeight > viewPortHeight ? y - contentHeight : y;

          setPosition({ x: newX, y: newY });
        }
      }
    }, [position, level, setPosition]);

    useImperativeHandle(ref, () => ({
      open: (x: number, y: number) => {
        const viewPortWidth = window.innerWidth;
        const viewPortHeight = window.innerHeight;
        const contentWidth = menuRef.current?.clientWidth || 210;
        const contentHeight = menuRef.current?.clientHeight || 210;

        const newX = x + contentWidth > viewPortWidth ? x - contentWidth : x;
        const newY = y + contentHeight > viewPortHeight ? y - contentHeight : y;

        setPosition({ x: newX, y: newY });
      },
      close: () => setPosition(null),
    }));

    useEffect(() => {
      if (level === 0) {
        const record = getItemsLevel(items, 0, {});
        setMenuLevelAndIds(record);
      }
    }, [level, items, setMenuLevelAndIds]);

    useEffect(() => {
      if (definedPlacement) {
        setPlacement(definedPlacement);
      }
    }, [definedPlacement, setPlacement]);

    const handleOnClose = useCallback(
      (checkLevel: number = level) => {
        if (level !== 0 && hasSubMenuOpen(checkLevel)) return;
        if (level === 0 && position) {
          return;
        }
        handleMenuStateChange(level, false, id);
        closeSubMenus(level + 1);
        if (level === 0) closeSubMenus(1);
      },
      [level, hasSubMenuOpen, position, id, handleMenuStateChange, closeSubMenus],
    );

    const handleOnOpen = useCallback(
      (uuid: string) => {
        handleMenuStateChange(level, true, uuid);
      },
      [level, handleMenuStateChange],
    );

    const handleOnClick = useCallback(
      (uuid: string) => () => {
        if (level !== 0) {
          return;
        }
        handleOnOpen(uuid);
      },
      [level, handleOnOpen],
    );

    const handleOnMouseEnter = useCallback(
      (uuid: string) => () => {
        if (level === 0) return;
        handleOnOpen(uuid);
      },
      [level, handleOnOpen],
    );

    const handleOnMouseEnterItem = useCallback(() => {
      closeSubMenus(level + 1);
    }, [level, closeSubMenus]);

    const handleOnMouseLeaveList = useCallback(() => {
      setTimeout(() => {
        if (level === 0) {
          return;
        }
        if (!menuStates[level]) return;
        handleOnClose(level + 1);
      }, 100);
    }, [level, handleOnClose, menuStates]);

    const isOpen = useMemo(() => {
      if (position && level === 0) {
        return true;
      }
      return menuStates[level] && subMenuStates[id];
    }, [position, level, id, menuStates, subMenuStates]);

    const handleClickOutside = useCallback(
      (e: Event) => {
        if (isOpen && level === 0 && position && !hasSubMenuOpen(0)) {
          if ('button' in e && e.button === 2) {
            return;
          }
          setPosition(null);
          if (onClose) {
            onClose();
          }
        }
      },
      [isOpen, level, position, hasSubMenuOpen, setPosition, onClose],
    );

    useOutsideClick({
      ref: menuRef,
      handler: handleClickOutside,
    });

    return (
      <Menu onClose={handleOnClose} isOpen={isOpen} placement={`${placement!}-start`} isLazy>
        <MenuButton
          as={Box}
          onClick={handleOnClick(id)}
          onMouseMove={handleOnMouseEnter(id)}
          cursor="pointer"
          role="group"
        >
          {children}
        </MenuButton>
        <Portal>
          {isOpen && (
            <>
              {level === 0 && position ? (
                <Box
                  ref={menuRef}
                  background="white"
                  position={position ? 'absolute' : undefined}
                  top={position ? position.y : undefined}
                  left={position ? position.x : undefined}
                  onMouseLeave={handleOnMouseLeaveList}
                  minWidth="12.5rem"
                  borderRadius="0.5rem"
                  boxShadow="0px 4px 6px -2px rgba(13, 18, 28, 0.08), 0px 12px 16px -4px rgba(13, 18, 28, 0.14), 0px 0px 0px 2px #FFF inset"
                  border="0.063rem solid #E5E7EB"
                  py="0.25rem"
                >
                  {items.map((item, index) => {
                    if (item.type === 'item') {
                      return (
                        <MultiLevelMenuItem
                          key={`item-${level}-${index}`}
                          item={item}
                          level={level}
                          onMouseEnterItem={handleOnMouseEnterItem}
                        />
                      );
                    }
                    return (
                      <MultiLevelMenuGroup
                        key={`group-${level}-${index}`}
                        item={item}
                        level={level}
                        onMouseEnterItem={handleOnMouseEnterItem}
                      />
                    );
                  })}
                </Box>
              ) : (
                <MenuList
                  onMouseLeave={handleOnMouseLeaveList}
                  minWidth="12.5rem"
                  borderRadius="0.5rem"
                  boxShadow="0px 4px 6px -2px rgba(13, 18, 28, 0.08), 0px 12px 16px -4px rgba(13, 18, 28, 0.14), 0px 0px 0px 2px #FFF inset"
                  border="0.063rem solid #E5E7EB"
                  py="0.25rem"
                >
                  {items.map((item, index) => {
                    if (item.type === 'item') {
                      return (
                        <MultiLevelMenuItem
                          key={`item-${level}-${index}`}
                          item={item}
                          level={level}
                          onMouseEnterItem={handleOnMouseEnterItem}
                        />
                      );
                    }
                    return (
                      <MultiLevelMenuGroup
                        key={`group-${level}-${index}`}
                        item={item}
                        level={level}
                        onMouseEnterItem={handleOnMouseEnterItem}
                      />
                    );
                  })}
                </MenuList>
              )}
            </>
          )}
        </Portal>
      </Menu>
    );
  },
);

export const MultiLevelMenu = forwardRef<MultiLevelMenuHandle, Omit<MultiLevelMenuProps, 'level'>>(
  ({ items, children, onClose, placement = 'right' }, ref) => (
    <MultiLevelMenuProvider>
      <InternalMultiLevelMenu items={items} level={0} placement={placement} ref={ref} onClose={onClose} id={uuidV4()}>
        {children}
      </InternalMultiLevelMenu>
    </MultiLevelMenuProvider>
  ),
);
