import type { FC } from 'react';
import { useMemo } from 'react';
import { VStack } from '@chakra-ui/react';
import { keyLedgersByType, LEDGER_TYPES } from '@graneet/business-logic';

import { useLedgers } from '../services/ledger.api';

import { LedgerItem } from './LedgerItem';

export const LedgerList: FC = () => {
  const ledgers = useLedgers();

  const ledgersByType = useMemo(() => keyLedgersByType(ledgers.data), [ledgers.data]);

  // We loop on LEDGER_TYPE (not on ledgers conts) because a never enabled ledger does not exists in ledgers const
  return (
    <VStack spacing={4} align="stretch">
      {Object.values(LEDGER_TYPES).map((ledgerType) => (
        <LedgerItem key={ledgerType} ledgerType={ledgerType} ledger={ledgersByType[ledgerType]} />
      ))}
    </VStack>
  );
};
