import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useFormContext, useFormStatus } from 'graneet-form';
import { Modal, PercentageField, RadioGroupField, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type Big from 'big.js';
import { Flex, Grid, HStack, Text, VStack } from '@chakra-ui/react';
import { QUOTE_CUSTOM_DISCOUNT_TYPE } from '@org/quotation-lib';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { CustomDiscountTotals } from './CustomDiscountTotals';

import type { QuotationCustomDiscountEditionForm } from 'features/custom-discount/forms/custom-discount-edition-form';
import { Rule } from 'features/form/rules/Rule';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export interface AddCustomDiscountModalProps {
  isOpen: boolean;

  onClose(): void;

  onSubmit(text: Partial<QuotationCustomDiscountEditionForm>): void;

  totalAfterSaleDiscountExVAT: Big;

  hasDiscount: boolean;
}

export const AddCustomDiscountModal: FC<AddCustomDiscountModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  totalAfterSaleDiscountExVAT,
  hasDiscount,
}) => {
  const { t } = useTranslation(['quote', 'global', 'customDiscount']);

  const form = useFormContext<QuotationCustomDiscountEditionForm>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues } = form;
  const [isLoading, setIsLoading] = useState(false);

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    const values = getFormValues();
    onSubmit(values);

    setIsLoading(false);
    onClose();
  }, [getFormValues, onClose, onSubmit]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('quote:discounts.drawers.discount.title')}
      size="xl"
      scrollBehavior="inside"
    >
      <VStack spacing={3} align="stretch" fontSize="13px">
        <Grid templateColumns="4fr 1fr" gap={2}>
          <TextField<QuotationCustomDiscountEditionForm>
            name="customDiscountName"
            label={t('customDiscount:fields.name')}
            isRequired
          >
            <Rule.IsRequired />
          </TextField>
          {hasStandardVATRates ? (
            <AccountingVATField<QuotationCustomDiscountEditionForm>
              name="customDiscountVatRate"
              label={t('customDiscount:fields.vatRate')}
              valueScale={100}
            >
              <Rule.IsRequired />
            </AccountingVATField>
          ) : (
            <PercentageField<QuotationCustomDiscountEditionForm>
              name="customDiscountVatRate"
              label={t('customDiscount:fields.vatRate')}
              min={0}
              isRequired
            >
              <Rule.IsRequired />
            </PercentageField>
          )}
        </Grid>

        <Text fontSize="sm" color="gray.600">
          {' '}
          {t('quote:discounts.drawers.discount.calculusMode')}
        </Text>
        <RadioGroupField<QuotationCustomDiscountEditionForm> name="customDiscountType">
          <HStack spacing={2} align="stretch">
            <Flex
              padding="2"
              align-items="center"
              borderRadius="0.625rem"
              border="1px solid rgba(0, 19, 58, 0.10)"
              background="#FFF"
              align="stretch"
              flex=" 1 0 0"
              fontSize="13px"
            >
              <RadioGroupField.Option
                value={QUOTE_CUSTOM_DISCOUNT_TYPE.PERCENTAGE}
                label={t('customDiscount:fields.percentageType')}
              />
            </Flex>
            <Flex
              padding="2"
              align-items="center"
              borderRadius="0.625rem"
              border="1px solid rgba(0, 19, 58, 0.10)"
              background="#FFF"
              align="stretch"
              flex=" 1 0 0"
              fontSize="13px"
            >
              <RadioGroupField.Option
                value={QUOTE_CUSTOM_DISCOUNT_TYPE.AMOUNT}
                label={t('customDiscount:fields.amountType')}
              />
            </Flex>
          </HStack>
        </RadioGroupField>
        <CustomDiscountTotals totalAfterSaleDiscountExVAT={totalAfterSaleDiscountExVAT} hasDiscount={hasDiscount} />
      </VStack>
      <Modal.Close />

      <Modal.PrimaryButton isLoading={isLoading} isDisabled={!isFormValid} onClick={handleSubmit}>
        {t('global:words.c.validate')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
