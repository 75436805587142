import type { FC } from 'react';
import { useWizardContext } from 'graneet-form';
import type { StepperProps as ChakraStepperProps, BoxProps } from '@chakra-ui/react';
import {
  Box,
  Center,
  Stepper as ChakraStepper,
  Step as ChakraStep,
  StepIndicator as ChakraStepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepSeparator,
} from '@chakra-ui/react';

export interface StepperProps extends Omit<ChakraStepperProps, 'index' | 'children'> {
  internalProps?: BoxProps;
}

export const Stepper: FC<StepperProps> = ({ orientation = 'horizontal', internalProps, ...props }) => {
  const { stepsTitles, currentStep, steps, handleGoBackTo } = useWizardContext();

  const currentStepIndex = steps.indexOf(currentStep as string);

  return (
    <Box {...internalProps}>
      <Center>
        <ChakraStepper
          colorScheme="greenBrand"
          index={currentStepIndex}
          orientation={orientation}
          h={orientation === 'horizontal' ? 12 : undefined}
          w="fit-content"
          {...props}
        >
          {stepsTitles.map((step, index) => {
            const isPreviousStep = index < currentStepIndex;
            const onClick = isPreviousStep ? () => handleGoBackTo(step.name) : undefined;

            return (
              <ChakraStep key={step.name}>
                <ChakraStepIndicator
                  cursor={isPreviousStep ? 'pointer' : undefined}
                  boxSize={6}
                  fontWeight={600}
                  onClick={onClick}
                >
                  <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                </ChakraStepIndicator>

                <Box
                  cursor={isPreviousStep ? 'pointer' : undefined}
                  fontWeight={600}
                  fontSize="0.875rem"
                  onClick={onClick}
                >
                  {step.title}
                </Box>

                <StepSeparator
                  style={{
                    minWidth: orientation === 'horizontal' ? '3rem' : undefined,
                    minHeight: orientation === 'vertical' ? '3rem' : undefined,
                  }}
                />
              </ChakraStep>
            );
          })}
        </ChakraStepper>
      </Center>
    </Box>
  );
};
