import { createQuoteOptionalItemAddedCallback } from './createQuoteOptionalItemAddedCallback';
import { createQuoteOptionalItemDeletedCallback } from './createQuoteOptionalItemDeletedCallback';
import { createQuoteOptionalItemTransformCallback } from './createQuoteOptionalItemTransformCallback';
import { createQuoteOptionalItemUpdatedCallback } from './createQuoteOptionalItemUpdatedCallback';

import type { QuoteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';

export const quoteOptionalItemCallbacks = (quoteId: String) => [
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteOptionalItem:added',
    callback: (message: QuoteWsMessage) => createQuoteOptionalItemAddedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteOptionalItem:deleted',
    callback: (message: QuoteWsMessage) => createQuoteOptionalItemDeletedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteOptionalItem:updated',
    callback: (message: QuoteWsMessage) => createQuoteOptionalItemUpdatedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteOptionalItem:transformed',
    callback: (message: QuoteWsMessage & { oldType: string; newType: string }) =>
      createQuoteOptionalItemTransformCallback(message),
  },
];
