import { HStack } from '@chakra-ui/react';
import { MaskEnum, QuotationMaskedInput, useInputMaskInfos } from '@graneet/lib-ui';
import type { QuoteComponentsGroupByType } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import isEqual from 'lodash-es/isEqual';
import { memo, useCallback, useMemo } from 'react';

export const MarginCell = memo(
  (params: CustomCellRendererProps<QuoteComponentsGroupByType>) => {
    const { placeholder, mask } = useInputMaskInfos(MaskEnum.DECIMAL, {
      scale: 3,
      min: 0,
    });

    const { getValue, node } = params;

    const value: number | undefined = useMemo(() => {
      if (getValue) {
        return parseFloat(getValue());
      }
      return undefined;
    }, [getValue]);

    const emptyCb = useCallback(() => {}, []);

    if (node.footer) {
      return null;
    }

    return (
      <HStack height="100%" width="100%" justifyContent="center" background="transparent" px={2}>
        <QuotationMaskedInput
          value={value}
          isReadOnly
          onChange={emptyCb}
          onBlur={emptyCb}
          onKeyDownCapture={emptyCb}
          mask={mask}
          color="#1F2A37"
          placeholder={placeholder}
          textAlign="right"
          noStyle
        />
      </HStack>
    );
  },
  (prevProps, nextProps) => {
    if (!prevProps && !nextProps) {
      return true;
    }
    if (!prevProps || !nextProps) {
      return false;
    }
    const oldValue = prevProps.getValue ? prevProps.getValue() : undefined;
    const newValue = nextProps.getValue ? nextProps.getValue() : undefined;
    return isEqual(oldValue, newValue);
  },
);
