import { useCallback, memo } from 'react';
import type { HISTORY_ENTITY_TYPE, EmailDetails, IHistoryExtended, IHistoryDTO } from '@graneet/business-logic';
import {
  Accordion,
  FileIcon,
  FinanceIcon,
  formatDateOrEmpty,
  HistoryIcon,
  type RichTextConfiguration,
  SendIcon,
  StepperItem,
  useCurrency,
} from '@graneet/lib-ui';
import dayjs from 'dayjs';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ButtonAccordionEmail } from './ButtonAccordionEmail';
import { PanelAccordionEmail } from './PanelAccordionEmail';
import { ArchivedCard } from './ArchivedCard';
import type { IArchiveDetailsExtended } from './HistoryCard';

import type { FileItemProps } from 'features/file/components/FilesCard/components/FileItem';

const featureStartDate = new Date('2024-03-05');
const formattedFeatureStartDate = featureStartDate.toLocaleDateString();

interface HistoryTimelineProps {
  history: IHistoryDTO;
  entity: HISTORY_ENTITY_TYPE;
  emailTemplateRichTextConfiguration: RichTextConfiguration;
  addActionsProps?: (archiveDetails: IArchiveDetailsExtended) => Pick<FileItemProps, 'rightContent'> | null;
}

export const HistoryTimeline = memo(
  ({ history, emailTemplateRichTextConfiguration, entity, addActionsProps }: HistoryTimelineProps) => {
    const { t } = useTranslation(['global', 'history']);

    const { formatAsAmount } = useCurrency();

    const memoizedButtonComponent = useCallback(
      (itemButton: EmailDetails) => <ButtonAccordionEmail item={itemButton} />,
      [],
    );

    const memoizedPanelComponent = useCallback(
      (itemPanel: EmailDetails, isExpanded: boolean) => (
        <PanelAccordionEmail
          item={itemPanel}
          isExpanded={isExpanded}
          emailTemplateRichTextConfiguration={emailTemplateRichTextConfiguration}
        />
      ),
      [emailTemplateRichTextConfiguration],
    );

    return (
      <>
        {history.map((item, index) => {
          switch (item.actionType) {
            case 'email': {
              const emailDetails = item.actionDetails as EmailDetails;
              return (
                <StepperItem
                  key={item.id}
                  title={t(`history:documentSent.${entity}`)}
                  date={dayjs(item.createdAt)}
                  icon={<SendIcon boxSize={3} />}
                  borderColor={index === history.length - 1 ? 'white' : undefined}
                >
                  <Accordion
                    items={[emailDetails as EmailDetails]}
                    componentButton={memoizedButtonComponent}
                    componentPanel={memoizedPanelComponent}
                    accordionProps={{ allowToggle: true, maxW: 'xl' }}
                    keySelector="emailId"
                  />
                </StepperItem>
              );
            }
            case 'archive': {
              const archiveItem = item as IHistoryExtended<'archive'>;
              const archiveDetails = archiveItem.actionDetails;
              return (
                <StepperItem
                  key={item.id}
                  title={t(`history:documentVersionArchived.${entity}`)}
                  date={dayjs(item.createdAt)}
                  icon={<HistoryIcon boxSize={3} />}
                  borderColor={index === history.length - 1 ? 'white' : undefined}
                >
                  <ArchivedCard addActionsProps={addActionsProps} archiveDetails={archiveDetails} entity={entity} />
                </StepperItem>
              );
            }
            case 'creation': {
              const creationDate = dayjs(item.createdAt);
              return (
                <StepperItem
                  key={item.id}
                  title={t(`history:documentCreated.${entity}`)}
                  date={dayjs(item.createdAt)}
                  icon={<FileIcon color="gray.700" boxSize={3} />}
                  borderColor="white"
                >
                  {dayjs(featureStartDate).isAfter(creationDate) && (
                    <Text color="gray.700" fontSize="sm" fontWeight="medium" lineHeight="5">
                      {t('history:incompleteHistoryNotice', { formattedFeatureStartDate })}
                    </Text>
                  )}
                </StepperItem>
              );
            }
            case 'addPayment': {
              const addPaymentItem = item as IHistoryExtended<'addPayment'>;
              return (
                <StepperItem
                  key={item.id}
                  title={t(`history:documentAddPayment.${entity}`, {
                    amount: formatAsAmount(addPaymentItem.actionDetails.paymentAmount),
                  })}
                  date={dayjs(addPaymentItem.actionDetails.billingDate)}
                  icon={<FinanceIcon color="gray.700" boxSize={3} />}
                  borderColor={index === history.length - 1 ? 'white' : undefined}
                />
              );
            }
            case 'updatePayment': {
              const updatePaymentItem = item as IHistoryExtended<'updatePayment'>;
              return (
                <StepperItem
                  key={item.id}
                  title={t(`history:documentUpdatePayment.${entity}`, {
                    oldPaymentAmount: formatAsAmount(updatePaymentItem.actionDetails.oldPaymentAmount),
                    newPaymentAmount: formatAsAmount(updatePaymentItem.actionDetails.newPaymentAmount),
                    oldBillingDate: formatDateOrEmpty(dayjs(updatePaymentItem.actionDetails.oldBillingDate)),
                    newBillingDate: formatDateOrEmpty(dayjs(updatePaymentItem.actionDetails.newBillingDate)),
                  })}
                  date={dayjs(updatePaymentItem.createdAt)}
                  icon={<FinanceIcon color="gray.700" boxSize={3} />}
                  borderColor={index === history.length - 1 ? 'white' : undefined}
                />
              );
            }
            case 'deletePayment': {
              const deletePaymentItem = item as IHistoryExtended<'deletePayment'>;
              return (
                <StepperItem
                  key={item.id}
                  title={t(`history:documentDeletePayment.${entity}`, {
                    amount: formatAsAmount(deletePaymentItem.actionDetails.paymentAmount),
                  })}
                  date={dayjs(deletePaymentItem.createdAt)}
                  icon={<FinanceIcon color="gray.700" boxSize={3} />}
                  borderColor={index === history.length - 1 ? 'white' : undefined}
                />
              );
            }
            default:
              return null;
          }
        })}
      </>
    );
  },
);
