import { Box } from '@chakra-ui/react';

export const LedgerNumberPreview = ({ value }: { value: string }) => (
  <Box
    width="100%"
    background="gray.50"
    p={3}
    borderColor="gray.300"
    borderWidth={1}
    borderRadius={5}
    fontSize={20}
    fontWeight="semibold"
    textColor="gray.600"
    textAlign="center"
  >
    {value}
  </Box>
);
