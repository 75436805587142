import { useCallback, useState, type FC } from 'react';
import { ActionMenu, useCurrency, useDrawer, ComponentImportIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { Quote, QuoteComponentsSupplyObject } from '@org/quotation-lib';
import { useFormContext } from 'graneet-form';
import { TRACKING_EVENT_ENUM } from '@graneet/business-logic';

import { populateOrderTreeFromQuoteComponentsSupplyObjects } from '../../../services/order.util';

import { QuoteSelectDrawer } from 'features/library-job/components/QuoteSelectDrawer';
import { QuoteComponentSelectDrawer } from 'features/library-job/components/QuoteComponentSelectDrawer';
import type { OrderEditForm } from 'features/order/forms/order-edit-wizard';
import type { OrderTree } from 'features/order/hooks/useOrderTree';
import { useSelectQuote } from 'features/order/hooks/useSelectQuote';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';
import { useComponentTypes } from 'features/component-type/services/component-type.api';

interface OrderAddQuoteComponentsButtonProps {
  tree: OrderTree;
}

export const OrderAddQuoteComponentsButton: FC<OrderAddQuoteComponentsButtonProps> = ({ tree }) => {
  const { t } = useTranslation(['orders', 'global']);
  const segmentAnalytics = useSegmentAnalytics();

  const components = useComponentTypes();

  const drawerSelectQuote = useDrawer('quote-select-drawer');
  const drawerSelectComponent = useDrawer('quote-component-select-drawer');
  const [selectedQuote, setSelectedQuote] = useState<Quote>();

  const form = useFormContext<OrderEditForm>();
  const { mapNumberToAmount } = useCurrency();
  const quoteSelectOnSubmit = useSelectQuote(setSelectedQuote, drawerSelectComponent);

  const onSelection = useCallback(
    (selection: QuoteComponentsSupplyObject[]) => {
      populateOrderTreeFromQuoteComponentsSupplyObjects(selection, form, tree, mapNumberToAmount, components.data);
    },
    [components.data, form, mapNumberToAmount, tree],
  );

  const handleSelectQuoteDrawerOpen = useCallback(() => {
    // Tracking event for creating order from quote components supply sheet
    segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.ORDER_ITEMS_IMPORT, { type: 'quote-components-from-quote' });

    drawerSelectQuote.onOpen();
  }, [drawerSelectQuote, segmentAnalytics]);

  return (
    <>
      <ActionMenu.Action
        icon={<ComponentImportIcon />}
        label={t('orders:actions.importComponents')}
        onClick={handleSelectQuoteDrawerOpen}
      />

      <QuoteSelectDrawer
        title={t('orders:drawers.quoteSelectDrawer.title')}
        description={t('orders:drawers.quoteSelectDrawer.descriptionComponents')}
        drawer={drawerSelectQuote}
        onSubmit={quoteSelectOnSubmit}
        hasPagination
        showClientColumn
        showAccountManagers
      />

      <QuoteComponentSelectDrawer
        quote={selectedQuote}
        title={t('orders:drawers.quoteComponentDrawer.title')}
        description={t('orders:drawers.quoteComponentDrawer.description')}
        drawer={drawerSelectComponent}
        onSelection={onSelection}
      />
    </>
  );
};
