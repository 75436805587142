import { divideFloating, getAmountPercentage, roundFloating } from '../common/math.util';
import type { Raw } from '../common/entity.type';
import { isNumberFinite } from '../common/number.util';

import type { ICustomDiscount } from './custom-discount.type';
import { CUSTOM_DISCOUNT_TYPES } from './custom-discount.type';

export const getCustomDiscountAmountExVAT = (
  customDiscount: Raw<Omit<ICustomDiscount, 'name' | 'vatRate'>> | null | undefined,
  totalAmountExVAT: number,
): number => {
  if (!customDiscount) {
    return 0;
  }
  return customDiscount.type === CUSTOM_DISCOUNT_TYPES.AMOUNT
    ? customDiscount.amountExVAT || 0
    : getAmountPercentage(totalAmountExVAT, customDiscount.percentage);
};

export const getSumCustomDiscountsAmountExVAT = (
  customDiscounts: Raw<Omit<ICustomDiscount, 'name' | 'vatRate'>>[] | undefined | null,
  amountExVAT: number,
): number => {
  if (!customDiscounts || customDiscounts.length === 0) {
    return 0;
  }

  const totalCustomDiscountAmountExVAT = customDiscounts.reduce(
    (acc, customDiscount) => acc + getCustomDiscountAmountExVAT(customDiscount, amountExVAT),
    0,
  );
  return totalCustomDiscountAmountExVAT;
};

/**
 * @returns The initial value of the quote amount without custom discount aka amountWithDiscountExVAT (amount including the primary discount)
 */
export const getAmountWithoutCustomDiscountsFromAmountExVAT = (
  customDiscounts: Raw<Omit<ICustomDiscount, 'name' | 'vatRate'>>[],
  amountExVAT: number,
): number => {
  if (!customDiscounts.length) {
    return amountExVAT;
  }

  const customDiscountSumByType = customDiscounts.reduce(
    (acc, customDiscount) => {
      // Percentage custom discount
      if (customDiscount.type === CUSTOM_DISCOUNT_TYPES.PERCENTAGE) {
        if (!isNumberFinite(customDiscount.percentage)) {
          throw Error('Discount type. is `PERCENTAGE` but percentage is not defined');
        }

        return {
          percentageCustomDiscountsSum: acc.percentageCustomDiscountsSum + customDiscount.percentage,
          fixedAmountCustomDiscountsSum: acc.fixedAmountCustomDiscountsSum,
        };
      }

      // Fixed custom discount amount
      return {
        percentageCustomDiscountsSum: acc.percentageCustomDiscountsSum,
        fixedAmountCustomDiscountsSum: acc.fixedAmountCustomDiscountsSum + (customDiscount.amountExVAT || 0),
      };
    },
    {
      percentageCustomDiscountsSum: 0,
      fixedAmountCustomDiscountsSum: 0,
    },
  );

  // Substruct fixed custom discount amount from amountExVAT
  const amountWithoutFixedCustomDiscountSum = amountExVAT - customDiscountSumByType.fixedAmountCustomDiscountsSum;

  // Calculate initial amount from custom discount percentage
  return roundFloating(
    divideFloating(
      amountWithoutFixedCustomDiscountSum,
      1 + divideFloating(customDiscountSumByType.percentageCustomDiscountsSum, 100),
    ),
  );
};

export const computeContainerAmountWithCustomDiscounts = (
  totalAmountWithDiscountExVAT: number,
  customDiscounts: Raw<Omit<ICustomDiscount, 'name' | 'vatRate'>>[],
): number => {
  const totalCustomDiscountsAmountExVAT = getSumCustomDiscountsAmountExVAT(
    customDiscounts,
    totalAmountWithDiscountExVAT,
  );

  const newTotalAmountWithDiscountsExVAT = totalAmountWithDiscountExVAT + totalCustomDiscountsAmountExVAT;

  return newTotalAmountWithDiscountsExVAT;
};

export const computeCumulativeProgressPercentage = (cumulativeAmountExVAT: number, amountExVAT: number): number =>
  divideFloating(cumulativeAmountExVAT * 100, amountExVAT);
