import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { WrapItem } from '@chakra-ui/react';

import type { BadgeProps } from '../Badge/Badge';
import { Badge } from '../Badge/Badge';

import { useBadgeGroupContext } from './BadgeGroupContext';

const OPTION_HOVER_STYLE = { boxShadow: 'sm' };

export interface BadgeGroupOptionProps<T extends unknown> extends Omit<BadgeProps, 'children'> {
  /**
   * Label displayed in the badge
   */
  label: ReactNode;

  /**
   * Badge value
   */
  value: T;
}
export const BadgeGroupOption = <T extends unknown>({ label, value, ...otherProps }: BadgeGroupOptionProps<T>) => {
  const { fieldValues, onBlur, onChange, onFocus } = useBadgeGroupContext<T>();

  const isSelected = Array.isArray(fieldValues) ? fieldValues.includes(value) : false;

  const handleClick = useCallback(() => {
    onFocus();

    let newFieldValues: T[];
    // If the value is currently selected, remove it from selected values
    if (isSelected && fieldValues) {
      newFieldValues = fieldValues.filter((i) => i !== value);
    } else {
      // Else, if the value is unselected, add it to selected values
      newFieldValues = Array.isArray(fieldValues) ? [...fieldValues, value] : [value];
    }

    onChange(newFieldValues as any);
    onBlur();
  }, [fieldValues, isSelected, onBlur, onChange, onFocus, value]);

  return (
    <WrapItem>
      <Badge
        {...otherProps}
        onClick={handleClick}
        colorScheme={isSelected ? 'greenBrand' : 'white'}
        cursor="pointer"
        _hover={OPTION_HOVER_STYLE}
      >
        {label}
      </Badge>
    </WrapItem>
  );
};
