import type { FC, ReactElement } from 'react';
import { Box, Flex, HStack, Image } from '@chakra-ui/react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import type { FileItem } from '../FileNameChip/FileNameDetails';
import { SimpleChevronLeftIcon } from '../Icons';

export interface CarouselProps {
  images: FileItem[];
  fallback: ReactElement;
  startIndex?: number;
}

export const Carousel: FC<CarouselProps> = ({ images, fallback, startIndex = 0 }) => (
  <CarouselProvider
    naturalSlideWidth={100}
    naturalSlideHeight={100}
    totalSlides={images.length}
    currentSlide={startIndex}
    infinite
  >
    <HStack>
      <ButtonBack style={{ outline: 'none' }}>
        <SimpleChevronLeftIcon boxSize={6} />
      </ButtonBack>

      <Box boxSize="100%" p={5}>
        <Slider style={{ outline: 'none' }}>
          {images.map(({ name, url, id }, index) => (
            <Slide index={index} key={id}>
              <Flex boxSize="100%" align="center" justify="center">
                <Image src={url} alt={name} fallback={fallback} />
              </Flex>
            </Slide>
          ))}
        </Slider>
      </Box>
      <ButtonNext style={{ outline: 'none' }}>
        <SimpleChevronLeftIcon boxSize={6} transform="rotate(180deg)" />
      </ButtonNext>
    </HStack>
  </CarouselProvider>
);
