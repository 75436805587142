import { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface ITableContext {
  numberOfColumns: number;
  isLoading: boolean;
  hasContent: boolean;
  registerRow(): void;
  unRegisterRow(): void;
}

export const useTable = (isLoading: boolean, numberOfColumns: number): ITableContext => {
  const [numberOfRegisteredRow, setNumberOfRegisteredRow] = useState(0);

  const registerRow = useCallback(() => {
    setNumberOfRegisteredRow((state) => state + 1);
  }, []);

  const unRegisterRow = useCallback(() => {
    setNumberOfRegisteredRow((state) => state - 1);
  }, []);

  const hasContent = useMemo(() => numberOfRegisteredRow !== 0, [numberOfRegisteredRow]);

  return useMemo(
    () => ({
      numberOfColumns,
      isLoading,
      hasContent,
      registerRow,
      unRegisterRow,
    }),
    [hasContent, isLoading, numberOfColumns, registerRow, unRegisterRow],
  );
};

export const TableContext = createContext<ITableContext>({
  numberOfColumns: 0,
  isLoading: true,
  hasContent: false,
  registerRow: () => {},
  unRegisterRow: () => {},
});
export const useTableContext = () => useContext(TableContext);
