import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

export const Logo: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 402 84" w="100%" h="auto" color="#122428" {...props}>
    <svg width="402" height="84" viewBox="0 0 402 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M393.012 58.2085C393.012 62.3426 394.777 64.6578 398.559 64.6578H401.5V71.6031H397.298C388.054 71.6031 384.524 66.3114 384.524 59.0353V35.5534H377.885V28.7734H384.524V18.8514L393.012 15.1307V28.7734H401.416V35.5534H393.012V58.2085Z"
        fill="currentColor"
      />
      <path
        d="M375.237 58.8701C372.8 67.7171 365.405 72.5954 355.404 72.5954C342.462 72.5954 333.806 63.4176 333.806 49.9403C333.806 37.0418 342.882 27.6986 355.32 27.6986C370.363 27.6986 375.826 39.3569 375.91 50.9325V52.5035H342.126C342.798 60.6891 348.009 65.6501 355.32 65.6501C360.699 65.6501 365.237 63.583 366.749 58.8701H375.237ZM342.21 46.1369H367.338C366.665 39.3569 362.8 34.4786 355.32 34.4786C347.925 34.4786 343.218 39.1915 342.21 46.1369Z"
        fill="currentColor"
      />
      <path
        d="M329.305 58.8701C326.868 67.7171 319.473 72.5954 309.472 72.5954C296.53 72.5954 287.874 63.4176 287.874 49.9403C287.874 37.0418 296.95 27.6986 309.388 27.6986C324.431 27.6986 329.894 39.3569 329.978 50.9325V52.5035H296.194C296.866 60.6891 302.077 65.6501 309.388 65.6501C314.767 65.6501 319.305 63.583 320.817 58.8701H329.305ZM296.278 46.1369H321.406C320.733 39.3569 316.868 34.4786 309.388 34.4786C301.993 34.4786 297.286 39.1915 296.278 46.1369Z"
        fill="currentColor"
      />
      <path
        d="M244.795 71.5205V28.7735H253.367V36.5457C255.636 31.6674 259.67 27.6986 266.394 27.6986C277.739 27.6986 282.025 35.0574 282.025 44.3179V71.5205H273.453V45.7235C273.453 38.6127 270.68 34.9747 264.713 34.9747C258.494 34.9747 253.451 40.1837 253.451 51.0979V71.5205H244.795Z"
        fill="currentColor"
      />
      <path
        d="M200.501 41.7546C201.509 35.2227 207.056 27.6158 218.401 27.6158C233.613 27.6158 236.302 36.959 236.302 44.8966V62.26C236.302 65.4846 236.554 69.2054 236.974 71.5205H227.982C227.814 69.7015 227.814 67.9651 227.814 65.8981V64.079H227.73C226.469 67.469 222.855 72.5954 213.611 72.5954C203.526 72.5954 198.4 66.3115 198.4 59.8622C198.4 47.8732 213.527 47.2117 219.914 46.1368C225.545 45.2273 227.73 43.9044 227.73 40.349C227.73 36.7937 224.368 34.4785 218.569 34.4785C213.611 34.4785 209.913 37.2897 208.905 41.7546H200.501ZM206.972 59.6968C206.972 63.4176 210.334 65.8981 215.544 65.8981C221.763 65.8981 227.898 62.012 227.898 51.6766V46.4676C226.973 49.0307 224.872 51.4285 216.552 52.8341C210.418 53.9917 206.972 55.7281 206.972 59.6968Z"
        fill="currentColor"
      />
      <path
        d="M184.401 28.7734V37.0417C186.67 30.9231 191.04 28.5253 197.427 28.5253V36.5456C197.007 36.5456 195.074 36.6283 193.814 36.959C189.191 37.7031 184.485 39.9356 184.485 51.6766V71.5205H175.829V28.7734H184.401Z"
        fill="currentColor"
      />
      <path
        d="M140.562 48.2039V41.0932H167.538L166.782 71.5205H160.479L159.975 61.7639C157.034 68.2959 151.067 72.9261 140.057 72.9261C120.812 72.9261 110.56 58.2085 110.56 42.0027C110.56 25.7968 121.317 11.0793 141.066 11.0793C156.277 11.0793 164.429 20.009 167.202 30.0963H158.126C156.025 23.4817 151.151 18.6034 141.066 18.6034C126.863 18.6034 119.636 29.8483 119.636 42.0027C119.636 54.1571 126.695 65.402 140.394 65.402C150.058 65.402 158.798 60.5237 159.303 48.1212L140.562 48.2039Z"
        fill="currentColor"
      />
      <path
        d="M26.1308 60.4964H0.5V48.165H23.4167C24.3157 48.165 25.1858 47.8132 25.8196 47.1833L47.1171 26.2091C48.8747 24.4783 51.2603 23.5079 53.7438 23.5079H79.3688V35.8393H56.4579C55.5589 35.8393 54.6888 36.1912 54.055 36.8211L32.7575 57.7952C30.9999 59.526 28.6143 60.4964 26.1308 60.4964Z"
        fill="currentColor"
      />
      <path
        d="M13.6093 35.838H0.5V23.5123H10.8952C11.7941 23.5123 12.6642 23.1605 13.2981 22.5306L35.5061 0.65979L44.357 9.37632L20.2302 33.1368C18.4784 34.8676 16.0928 35.838 13.6093 35.838Z"
        fill="currentColor"
      />
      <path
        d="M44.3631 83.3402L35.5122 74.6237L59.639 50.8632C61.3965 49.1324 63.7821 48.162 66.2657 48.162H79.375V60.4877H68.9797C68.0808 60.4877 67.2107 60.8395 66.5769 61.4694L44.3631 83.3402Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);
