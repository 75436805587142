import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleEquipmentHookIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 14 14" {...props} fill="none">
    <g clipPath="url(#clip0_5735_9709)">
      <path
        d="M10.5 7.875C10.5001 8.11161 10.5641 8.34379 10.6854 8.54699C10.8066 8.75018 10.9805 8.91682 11.1887 9.02928C11.3969 9.14174 11.6316 9.19583 11.868 9.18584C12.1044 9.17584 12.3337 9.10212 12.5316 8.97249C12.7295 8.84286 12.8887 8.66213 12.9924 8.44942C13.096 8.23672 13.1402 7.99996 13.1204 7.76419C13.1005 7.52841 13.0172 7.3024 12.8794 7.11007C12.7416 6.91773 12.5544 6.76623 12.3375 6.67158C12.1814 6.60347 12.0486 6.49131 11.9554 6.34883C11.8621 6.20636 11.8125 6.03978 11.8125 5.8695V3.9375"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5625 3.9375H0.4375"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 3.9374L5.45242 0.547652C5.52102 0.486945 5.60691 0.449239 5.69803 0.439824C5.78915 0.430408 5.88093 0.449756 5.9605 0.495152L11.8125 3.9374"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.625 3.9375V13.5625"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 3.9375V13.5625"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.4375 13.5625H7.4375"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.625 4.8125L5.25 8.3125"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 9.1875L2.625 12.6875"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5735_9709">
        <rect width="14" height="14" fill="currentColor" />
      </clipPath>
    </defs>
  </Icon>
);
