import type { FC } from 'react';
import { useCallback } from 'react';
import {
  Button,
  formatDateToString,
  getCurrentDateAsString,
  SimpleEditIcon,
  useCurrency,
  Wizard,
} from '@graneet/lib-ui';
import type {
  IContractResponseDTO,
  IProgressStatementWithDirectPayments,
  IProgressStatementUpdateDTO,
  ISubProject,
  IProgressStatement,
} from '@graneet/business-logic';
import { PERMISSION, CUMULATIVE_INPUT_TYPE } from '@graneet/business-logic';
import { useHistory, useParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWizardContext } from 'graneet-form';

import type { ProgressStatementEditWizard } from '../../forms/progress-statement-edit-wizard';
import type { ProgressStatementEditItemForm } from '../../forms/progress-statement-edit-item-form';
import { getProgressStatementsLinesAndContractDiscountDTO } from '../../services/progress-statement.util';
import { useProgressStatementCreate, useProgressStatementUpdate } from '../../services/progress-statement.api';

import { ProgressStatementRedirectContractModal } from './ProgressStatementRedirectContractModal';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useProjectSubProjectsWithContracts } from 'features/project/services/project.api';

interface ProgressStatementRedirectToContractEditionButtonProps {
  subProject: ISubProject;

  contracts: IContractResponseDTO[];

  currentProgressStatement: IProgressStatementWithDirectPayments | null;
}

export const ProgressStatementRedirectToContractEditionButton: FC<
  ProgressStatementRedirectToContractEditionButtonProps
> = ({ subProject, contracts, currentProgressStatement }) => {
  const { t } = useTranslation(['global', 'progressStatement']);

  const { mapAmountToNumber } = useCurrency();

  const history = useHistory();

  const hasDisplayQuotesPermission = usePermissions([PERMISSION.DISPLAY_QUOTES]);

  const { projectId } = useParams<{ projectId: string }>();

  const subProjectsWithContracts = useProjectSubProjectsWithContracts(+projectId);

  const progressStatementCreateMutation = useProgressStatementCreate();
  const progressStatementUpdateMutation = useProgressStatementUpdate();

  const modal = useDisclosure();

  const wizardContext = useWizardContext<ProgressStatementEditWizard>();
  const form = useFormContext<ProgressStatementEditItemForm>();

  const saveAndRedirectToContractEdition = useCallback(
    async (contractId: number) => {
      const wizardValues = wizardContext.getValuesOfSteps();

      const itemValues = form.getFormValues();
      const {
        directPayments: directPaymentsValues,
        information: informationValues,
        priceRevision: priceRevisionValues,
        downPayment: downPaymentValues,
      } = wizardValues;

      const {
        progressStatementsLinesDTO: lines,
        contractDiscountLinesDTO: discountLines,
        contractCustomDiscountLinesDTO: customDiscountLines,
      } = getProgressStatementsLinesAndContractDiscountDTO(
        itemValues as ProgressStatementEditItemForm,
        mapAmountToNumber,
      );

      const dto: Required<IProgressStatementUpdateDTO> = {
        name: informationValues?.name || currentProgressStatement?.name || t('progressStatement:defaultDraftName'),
        invoiceNumber: informationValues?.invoiceNumber || currentProgressStatement?.invoiceNumber || null,
        billingDate:
          informationValues?.billingDate ||
          formatDateToString(currentProgressStatement?.billingDate) ||
          getCurrentDateAsString(),
        note: informationValues?.note || currentProgressStatement?.note || null,
        lines,
        discountLines,
        customDiscountLines,
        displayOptionsAnnexPDF:
          informationValues?.displayOptionsAnnexPDF || currentProgressStatement?.displayOptionsAnnexPDF || false,
        priceRevisionExVAT: priceRevisionValues?.priceRevision || currentProgressStatement?.priceRevisionExVAT || 0,
        downPaymentAmortizationAmount:
          downPaymentValues?.downPaymentAmortizationAmount ||
          currentProgressStatement?.downPaymentAmortizationAmount ||
          0,
        cumulativeInputType:
          itemValues?.cumulativeInputType ||
          currentProgressStatement?.cumulativeInputType ||
          CUMULATIVE_INPUT_TYPE.AMOUNT,
        ordersSupplierInvoices:
          directPaymentsValues?.ordersSupplierInvoices ||
          currentProgressStatement?.directPaymentAssociations?.map((dpa) => ({
            orderId: dpa.order.id,
            supplierInvoiceId: dpa.supplierInvoice.id,
            isDisplayInProgressStatementPdf: dpa.supplierInvoice.isDisplayInProgressStatementPdf,
          })) ||
          [],
        penalties:
          currentProgressStatement?.penalties?.map((penalty) => ({
            id: penalty.id,
            name: penalty.name,
            amountIncVAT: penalty.amountIncVAT,
          })) || [],
      };

      let progressStatement: IProgressStatement;
      if (currentProgressStatement) {
        progressStatement = await progressStatementUpdateMutation.mutateAsync({ id: currentProgressStatement.id, dto });
      } else {
        progressStatement = await progressStatementCreateMutation.mutateAsync({
          ...dto,
          subProjectId: subProject.id,
        });
      }

      history.push(`/projects/${projectId}/contracts/${contractId}`, {
        redirectUrl: `/projects/${projectId}/statements/sub-projects/${subProject.id}/progress-statements/${progressStatement.id}/edit`,
      });
    },
    [
      currentProgressStatement,
      form,
      history,
      mapAmountToNumber,
      progressStatementCreateMutation,
      progressStatementUpdateMutation,
      projectId,
      subProject.id,
      t,
      wizardContext,
    ],
  );

  const onEditClicked = useCallback(() => {
    if (contracts.length === 1) {
      saveAndRedirectToContractEdition(contracts[0].id);
    } else {
      modal.onOpen();
    }
  }, [contracts, modal, saveAndRedirectToContractEdition]);

  const contractWithInformation =
    subProjectsWithContracts.data.subProjects.find((sp) => sp.id === subProject.id)?.contracts || [];

  const isDisabled = contractWithInformation.every((contract) => !contract.areAmountsEditable);

  if (!hasDisplayQuotesPermission) {
    return null;
  }

  return (
    <>
      <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_LEFT}>
        <Button
          variant="outline"
          colorScheme="gray"
          leftIcon={<SimpleEditIcon />}
          onClick={onEditClicked}
          tooltip={
            isDisabled
              ? t('progressStatement:editContractButton.cannotUpdateTooltip', {
                  count: contractWithInformation.length,
                })
              : undefined
          }
          isDisabled={isDisabled}
          isLoading={progressStatementUpdateMutation.isPending || progressStatementCreateMutation.isPending}
        >
          {t('progressStatement:editContractButton.editContract', {
            count: contractWithInformation.length,
          })}
        </Button>
      </Wizard.Placeholder>

      <ProgressStatementRedirectContractModal
        contracts={contractWithInformation}
        onSubmit={saveAndRedirectToContractEdition}
        isOpen={modal.isOpen}
        onClose={modal.onClose}
      />
    </>
  );
};
