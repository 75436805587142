import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const CircleIconLegacy: FC<IconProps> = ({ color = 'greenBrand.light', ...otherProps }) => (
  <Icon viewBox="0 0 12 12" color={color} {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM6 10.8C3.36 10.8 1.2 8.64 1.2 6C1.2 3.36 3.36 1.2 6 1.2C8.64 1.2 10.8 3.36 10.8 6C10.8 8.64 8.64 10.8 6 10.8Z"
      fill="currentColor"
    />
  </Icon>
);
