import { computeTotalDeductionAmountIncVAT } from '../deduction/deduction.util';
import { isProjectActive } from '../project/project.util';
import {
  divideFloating,
  getAmountPercentage,
  getFloatingPercentage,
  getPercentage,
  multiplyFloating,
  roundFloating,
  sumObjects,
} from '../common/math.util';
import {
  mergeVATDistribution,
  computeVatDistributionCumulativeDiscountedWithSumsMethod,
  computeVatDistributionCumulativeDiscountedWithBasesMethod,
  computeVatDistributionCumulativeDiscountedWithBasesRoundedMethod,
} from '../vat/vat-rates.util';
import { getDiscountAmountExVAT } from '../discount/discount.util';
import { isDownPaymentCompleted } from '../down-payment/down-payment.util';
import type { IDownPayment } from '../down-payment/down-payment.type';
import { DOWN_PAYMENT_STATUSES } from '../down-payment/down-payment.type';
import { PRICE_REVISION_METHOD, type IProject } from '../project/project.type';
import type { IDeduction } from '../deduction/deduction.type';
import type { IContract } from '../contract/contract.type';
import type { IItem } from '../item/item.type';
import type { IVatCumulativeBase } from '../vat/vat-cumulative-base.type';
import type { IHoldback } from '../holdback/holdback.type';
import type { StatementStateMachineContext, STATEMENT_TRANSITION_ERRORS } from '../statement/statement.transition';
import { StatementStateMachine } from '../statement/statement.transition';
import { checkDirectPaymentAssociation } from '../order-supplier-invoice/order-supplier-invoice.util';
import type { ISupplierInvoice } from '../supplier-invoice/supplier-invoice.type';
import type { IItemResponseDTO } from '../item/dtos/item-response.dto';
import { SUB_PROJECT_BILLING_TYPE } from '../sub-project/sub-project.type';
import type { ISubProject } from '../sub-project/sub-project.type';
import {
  areSubProjectParamsCompatibleWithDirectBilling,
  getSubProjectWeightedVAT,
  isSubProjectInProgressBilling,
  areSubProjectParamsAllSet,
} from '../sub-project/sub-project.util';
import { getCustomDiscountAmountExVAT } from '../custom-discount/custom-discount.util';
import type { IPenaltyUpsertDTO } from '../penalty/penalty-upsert.dto';
import type { RawExtended } from '../common/entity.type';
import type { RequiredByKeys } from '../common/types/object.type';
import { CUMULATIVE_INPUT_TYPE } from '../common/cumulative-input-type.constant';
import { getHoldbackAmountsWithCumulativeMethod } from '../holdback/holdback.util';
import type { IVatComponentExtended, IVatDiscountedBaseExtended } from '../vat/vat-component-extended.type';
import type { IVatComponentCumulative } from '../vat/vat-component-cumulative.type';
import type { IVatDistribution } from '../vat/vat-distribution.type';

import type {
  IProgressStatementDiscountLine,
  IProgressStatementDiscountLineToSave,
} from './progress-statement-discount-line.type';
import { PROGRESS_STATEMENT_DISCOUNT_MODES } from './progress-statement-discount-line.type';
import type {
  IProgressStatementCustomDiscountLineWithContractDTO,
  IProgressStatementDiscountLineWithContractDTO,
  IProgressStatementLineWithItemDTO,
} from './dtos/progress-statement-lines.dto';
import type { IProgressStatementLine, IProgressStatementLineToSave } from './progress-statement-line.type';
import type { IProgressStatement, IProgressStatementWithDirectPayments } from './progress-statement.type';
import { PROGRESS_STATEMENT_STATUSES } from './progress-statement.type';
import type {
  IProgressStatementCustomDiscountLine,
  IProgressStatementCustomDiscountLineToSave,
} from './progress-statement-custom-discount-line.type';
import { PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES } from './progress-statement-custom-discount-line.type';

export const filterOutCancelledProgressStatements = (progressStatements?: IProgressStatement[]): IProgressStatement[] =>
  (progressStatements || []).filter((ps) => ps.status !== PROGRESS_STATEMENT_STATUSES.CANCELLED);

export const isProgressStatementInDraft = (progressStatement: Pick<IProgressStatement, 'status'>): boolean =>
  progressStatement.status === PROGRESS_STATEMENT_STATUSES.DRAFT;

export const isProgressStatementCancelled = (progressStatement: IProgressStatement): boolean =>
  progressStatement.status === PROGRESS_STATEMENT_STATUSES.CANCELLED;

export const isProgressStatementNotCancelled = (progressStatement: IProgressStatement): boolean =>
  !isProgressStatementCancelled(progressStatement);

export const isProgressStatementAccepted = (progressStatement: IProgressStatement): boolean =>
  progressStatement.status === PROGRESS_STATEMENT_STATUSES.ACCEPTED;

export const isProgressStatementValidated = (progressStatement: IProgressStatement): boolean =>
  progressStatement.status === PROGRESS_STATEMENT_STATUSES.VALIDATED;

export const isProgressStatementCompleted = (progressStatement: IProgressStatement): boolean =>
  progressStatement.status === PROGRESS_STATEMENT_STATUSES.COMPLETED;

export const isProgressStatementProforma = (progressStatement: Pick<IProgressStatement, 'status'>): boolean =>
  isProgressStatementInDraft(progressStatement);

const DISCOUNT_LINE_MODE = {
  AUTO: 'AUTO',
};

export enum PROGRESS_STATEMENT_DELETION_ERROR {
  INVOICE_NUMBER_IS_SET = 'INVOICE_NUMBER_IS_SET',
  NOT_DRAFT = 'NOT_DRAFT',
  NOT_LAST = 'NOT_LAST',
}

export function getProgressStatementDeletionCheckError(
  progressStatement: IProgressStatement,
  billingType: SUB_PROJECT_BILLING_TYPE | null,
  isLastProgressStatement: boolean,
  allowDeletionIfInvoiceNumberIsSet = false,
): PROGRESS_STATEMENT_DELETION_ERROR | null {
  if (progressStatement.isArchived) {
    return null;
  }

  if (!allowDeletionIfInvoiceNumberIsSet && progressStatement.invoiceNumber) {
    return PROGRESS_STATEMENT_DELETION_ERROR.INVOICE_NUMBER_IS_SET;
  }

  if (progressStatement.status !== PROGRESS_STATEMENT_STATUSES.DRAFT) {
    return PROGRESS_STATEMENT_DELETION_ERROR.NOT_DRAFT;
  }

  if (billingType === SUB_PROJECT_BILLING_TYPE.PROGRESS && !isLastProgressStatement) {
    return PROGRESS_STATEMENT_DELETION_ERROR.NOT_LAST;
  }

  return null;
}

/**
 * Determines whether a project can be billed directly.
 * In client package, we sometimes need to use this function before the reversal of liability parameter is defined on the project.
 * In this case, the ignoreReversalOfLiability prop can be set to `true` in the option object
 */
export const isSubProjectBillableAsDirectProgressStatement = (
  subProject: ISubProject,
  {
    ignoreReversalOfLiability,
  }: {
    ignoreReversalOfLiability: boolean;
  } = { ignoreReversalOfLiability: false },
): boolean =>
  // we can ignore reversal of liability when using this method in client to determine how some buttons are displayed
  areSubProjectParamsCompatibleWithDirectBilling(subProject, {
    ignoreReversalOfLiability,
  });
/**
 * Determines whether a subproject can be billed using classic progress statements ("à l'avancement").
 * In client package, we sometimes need to use this function before the reversal of liability parameter is defined on the project.
 * In this case, the ignoreReversalOfLiability prop can be set to `true` in the option object
 */
export const isSubProjectBillableAsProgressStatement = (
  subProject: ISubProject | undefined,
  {
    ignoreReversalOfLiability,
  }: {
    ignoreReversalOfLiability: boolean;
  } = { ignoreReversalOfLiability: false },
): boolean =>
  // we can ignore reversal of liability when using this method in client to determine how some buttons are displayed
  subProject ? areSubProjectParamsAllSet(subProject, { ignoreReversalOfLiability }) : false;

/**
 * Get progress statement (with highest subGroupId if multiple share same groupId) based on the groupId offset in the array
 */
export const getProgressStatementByGroupIdOffset = (
  progressStatements: IProgressStatement[],
  offset: number,
  currentProgressStatement?: IProgressStatement | null,
): IProgressStatement | undefined => {
  if (!progressStatements || offset > 0 || Math.abs(offset) >= progressStatements.length) {
    return undefined;
  }

  const flattenedByGroupId = progressStatements
    .sort((a, b) => {
      // Sort progress statements primarily by ASC `groupId`
      if (a.groupId !== b.groupId) return a.groupId - b.groupId;
      // When 2 progress statements have the same `groupId`, sort
      // them by DESC `subGroupId`
      return (b.subGroupId || 1) - (a.subGroupId || 1);
    })
    .reduce<{
      flattenedProgressStatements: IProgressStatement[];
      groupIdsMemory: number[];
    }>(
      (acc, progressStatement) => {
        const { flattenedProgressStatements, groupIdsMemory } = acc;
        const { groupId } = progressStatement;
        if (groupIdsMemory.includes(groupId)) {
          return acc;
        }
        return {
          flattenedProgressStatements: [...flattenedProgressStatements, progressStatement],
          groupIdsMemory: [...groupIdsMemory, groupId],
        };
      },
      {
        // Stores progress statements with the highest `subGroupId` for each `groupId`
        flattenedProgressStatements: [],
        // Store the current highest `subGroupId` (value) for each `groupId` (key)
        groupIdsMemory: [],
      },
    ).flattenedProgressStatements;

  return flattenedByGroupId.find(({ groupId }) => {
    if (currentProgressStatement) {
      // Use currentProgressStatement as offset origin
      return groupId === currentProgressStatement.groupId + offset;
    }
    // Offset origin is at the end of the array
    return groupId === flattenedByGroupId.length + offset;
  });
};

/**
 * Here we check that all given progress statements are either only in DRAFT, and or VALIDATED
 * for progress billing (this status doesn't exist in DIRECT billing, so we can safely assume
 * it doesn't affect the test in the DIRECT configuration)
 */
export const doesProgressStatementsAllowDownPaymentEdition = (
  progressStatements?: IProgressStatement[] | null,
): boolean => {
  if (!progressStatements || progressStatements.length === 0) {
    return true;
  }

  return progressStatements
    .filter(isProgressStatementNotCancelled)
    .every(
      (progressStatement) =>
        isProgressStatementInDraft(progressStatement) || isProgressStatementValidated(progressStatement),
    );
};

export const canCompleteDirectProgressStatement = (
  directProgressStatement: IProgressStatement,
  project: IProject,
  downPayment?: IDownPayment | null,
): boolean => {
  if (!isProjectActive(project)) {
    return false;
  }
  if (directProgressStatement.status !== PROGRESS_STATEMENT_STATUSES.DRAFT) {
    return false;
  }
  if (downPayment && downPayment.status !== DOWN_PAYMENT_STATUSES.COMPLETED) {
    return false;
  }
  return true;
};

export enum PROGRESS_STATEMENT_TRANSITION_ERRORS {
  PROJECT_NON_ONGOING = 'PROJECT_NON_ONGOING',
  NO_CONTRACT = 'NO_CONTRACT',
  WRONG_BILLING_TYPE = 'WRONG_BILLING_TYPE',
  EXISTING_BILLED_AMOUNT = 'EXISTING_BILLED_AMOUNT',
  UNCOMPLETED_DOWN_PAYMENT = 'UNCOMPLETED_DOWN_PAYMENT',
  DRAFTING_OLD_ACCEPTED = 'DRAFTING_OLD_ACCEPTED',
  DIRECT_PAYMENT_INVALID_SUPPLIER_INVOICE = 'DIRECT_PAYMENT_INVALID_SUPPLIER_INVOICE',
  HAS_ACCOUNTING_EXPORT = 'HAS_ACCOUNTING_EXPORT',
}

/**
 * TODO: method to migrate to a global state-machine instance @g
 * Check if current progress statement can be updated with specified status
 */
export const getProgressStatementTransitionErrors = (
  newStatus: PROGRESS_STATEMENT_STATUSES,
  progressStatement: (IProgressStatement & Partial<IProgressStatementWithDirectPayments>) | undefined,
  context: StatementStateMachineContext = {
    finalStatement: null,
  },
): (PROGRESS_STATEMENT_TRANSITION_ERRORS | STATEMENT_TRANSITION_ERRORS)[] => {
  const { downPayment, latestAcceptedProgressStatement } = context;
  const currentStatus = progressStatement?.status;

  const stateMachine = StatementStateMachine(
    [
      /**
       * Creating a progress statement
       */
      {
        from: 'NON_EXISTING',
        to: [PROGRESS_STATEMENT_STATUSES.DRAFT],
        lastValidStatuses: [
          PROGRESS_STATEMENT_STATUSES.DRAFT,
          PROGRESS_STATEMENT_STATUSES.ACCEPTED,
          PROGRESS_STATEMENT_STATUSES.VALIDATED,
        ],
        beforeLastValidStatuses: [PROGRESS_STATEMENT_STATUSES.ACCEPTED],
        extraConditions: (): (STATEMENT_TRANSITION_ERRORS | PROGRESS_STATEMENT_TRANSITION_ERRORS)[] => {
          if (!context.project || !context.subProject) {
            throw new Error('A project and a sub project is needed to know if a ps can be created');
          }

          const errors = [];
          if (!isProjectActive(context.project)) {
            errors.push(PROGRESS_STATEMENT_TRANSITION_ERRORS.PROJECT_NON_ONGOING);
          }
          if (context.previousProgressStatement && !isSubProjectInProgressBilling(context.subProject)) {
            errors.push(PROGRESS_STATEMENT_TRANSITION_ERRORS.WRONG_BILLING_TYPE);
          }
          if (context.contracts?.length === 0) {
            errors.push(PROGRESS_STATEMENT_TRANSITION_ERRORS.NO_CONTRACT);
          }
          /*
            CORNER CASE: if project amount ex VAT is 0, we allow creating a progress statement
           */
          if (
            context.subProject &&
            context.subProject.amountToBeBilledExVAT === 0 &&
            context.subProject.amountExVAT !== 0
          ) {
            errors.push(PROGRESS_STATEMENT_TRANSITION_ERRORS.EXISTING_BILLED_AMOUNT);
          }
          if (context.finalStatement === null) {
            throw new Error('A final statement (or undefined) needs to be passed to create a ps');
          }
          if (context.downPayment && !isDownPaymentCompleted(context.downPayment)) {
            errors.push(PROGRESS_STATEMENT_TRANSITION_ERRORS.UNCOMPLETED_DOWN_PAYMENT);
          }
          return errors;
        },
      },

      /**
       * Validating a progress statement
       */
      {
        from: [PROGRESS_STATEMENT_STATUSES.DRAFT],
        to: [PROGRESS_STATEMENT_STATUSES.VALIDATED],
        lastValidStatuses: [PROGRESS_STATEMENT_STATUSES.ACCEPTED],
        extraConditions: (): PROGRESS_STATEMENT_TRANSITION_ERRORS[] => {
          if (downPayment && downPayment.status !== DOWN_PAYMENT_STATUSES.COMPLETED) {
            return [PROGRESS_STATEMENT_TRANSITION_ERRORS.UNCOMPLETED_DOWN_PAYMENT];
          }
          // check new direct payments
          if (progressStatement && progressStatement.directPaymentAssociations?.length) {
            // Check each supplier invoice is really associable to progress statement
            if (
              progressStatement.directPaymentAssociations?.some(
                ({ supplierInvoice, order }) =>
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  !checkDirectPaymentAssociation(supplierInvoice, order, context.project!.id, progressStatement.id),
              )
            ) {
              return [PROGRESS_STATEMENT_TRANSITION_ERRORS.DIRECT_PAYMENT_INVALID_SUPPLIER_INVOICE];
            }
          }
          return [];
        },
      },

      /**
       * Accepting a progress statement
       */
      {
        from: [PROGRESS_STATEMENT_STATUSES.VALIDATED],
        to: [PROGRESS_STATEMENT_STATUSES.ACCEPTED],
        nextValidStatuses: [PROGRESS_STATEMENT_STATUSES.DRAFT, PROGRESS_STATEMENT_STATUSES.VALIDATED],
        lastValidStatuses: [PROGRESS_STATEMENT_STATUSES.ACCEPTED],
      },

      /**
       * Editing or Reverting a progress statement back to draft
       */
      {
        from: [
          /**
           * On client, we allow editing a draft PS to display the button, as opposed
           * to server where it makes no sense as no status will be updated
           */
          PROGRESS_STATEMENT_STATUSES.DRAFT,
          PROGRESS_STATEMENT_STATUSES.VALIDATED,
          PROGRESS_STATEMENT_STATUSES.ACCEPTED,
        ],
        to: [PROGRESS_STATEMENT_STATUSES.DRAFT],
        nextValidStatuses: [PROGRESS_STATEMENT_STATUSES.DRAFT, PROGRESS_STATEMENT_STATUSES.VALIDATED],
        extraConditions: (): PROGRESS_STATEMENT_TRANSITION_ERRORS[] => {
          if (!context.project) {
            throw new Error('A project is needed to know if a ps can be edited');
          }
          /**
           * For technical reasons (not accounting reason),
           * we deny the possibility to change the status of a progress statement to DRAFT if one of its payment has been exported in accounting.
           * This is because payments will be deleted when the progress statement is set to DRAFT.
           */
          if ((!context.withCredit && context.isAccountingExported) || context.hasPaymentAccountingExported) {
            return [PROGRESS_STATEMENT_TRANSITION_ERRORS.HAS_ACCOUNTING_EXPORT];
          }
          if (!isSubProjectInProgressBilling(context.subProject)) {
            return [PROGRESS_STATEMENT_TRANSITION_ERRORS.WRONG_BILLING_TYPE];
          }
          if (!isProjectActive(context.project)) {
            return [PROGRESS_STATEMENT_TRANSITION_ERRORS.PROJECT_NON_ONGOING];
          }
          /**
           * Going from ACCEPTED to DRAFT => Cancelling
           * Check that if we try to draft an accepted PS, it IS the MOST RECENT one
           */
          if (currentStatus === PROGRESS_STATEMENT_STATUSES.ACCEPTED) {
            /**
             * If null, it means we didn't pass any latestAcceptedProgressStatement in context,
             * it should either be `undefined` or defined
             */
            if (latestAcceptedProgressStatement === null) {
              throw new Error('Missing latest accepted ps to cancel');
            }
            if (!progressStatement) {
              throw new Error('Current ps is needed when drafting');
            }
            if (
              latestAcceptedProgressStatement &&
              latestAcceptedProgressStatement.groupId !== progressStatement.groupId
            ) {
              /**
               * At this point, we are trying to draft an ACCEPTED PS which is not the most recent one, which is not
               * permitted.
               * And just as a friendly reminder, editing the last ACCEPTED PS will also revert to DRAFT the one
               * VALIDATED PS there may be as well.
               */
              return [PROGRESS_STATEMENT_TRANSITION_ERRORS.DRAFTING_OLD_ACCEPTED];
            }
          }
          return [];
        },
      },
    ],
    currentStatus,
    context,
  );

  return stateMachine.transitionTo(newStatus);
};

/**
 * Check if current direct progress statement can be updated with specified status
 */
export const throwIfDirectProgressStatementTransitionIsNotValid = (
  status: PROGRESS_STATEMENT_STATUSES,
  progressStatement: IProgressStatement,
  options: { hasPaymentAccountingExported: boolean },
): void => {
  const invalidStatus = [
    PROGRESS_STATEMENT_STATUSES.CANCELLED,
    PROGRESS_STATEMENT_STATUSES.VALIDATED,
    PROGRESS_STATEMENT_STATUSES.ACCEPTED,
  ];

  // Arguments checks
  if (invalidStatus.includes(status) || invalidStatus.includes(progressStatement.status)) {
    throw new Error('One of statuses specified is invalid');
  }

  // DRAFT -> COMPLETED
  if (
    status === PROGRESS_STATEMENT_STATUSES.COMPLETED &&
    progressStatement.status !== PROGRESS_STATEMENT_STATUSES.DRAFT
  ) {
    throw new Error('Direct progress statement must be a draft to be completed');
  }

  // COMPLETED -> DRAFT
  if (
    status === PROGRESS_STATEMENT_STATUSES.DRAFT &&
    progressStatement.status !== PROGRESS_STATEMENT_STATUSES.COMPLETED
  ) {
    throw new Error('Direct progress statement must be completed to be drafted');
  }

  // COMPLETED -> DRAFT with payment accounting exported
  if (
    status === PROGRESS_STATEMENT_STATUSES.DRAFT &&
    progressStatement.status === PROGRESS_STATEMENT_STATUSES.COMPLETED &&
    options.hasPaymentAccountingExported
  ) {
    throw new Error('Direct progress statement is associated with a payment that has been exported for accounting');
  }
};

export const doesLastProgressStatementAllowProjectClosing = (
  lastProgressStatement: IProgressStatement | null,
): boolean =>
  !!lastProgressStatement &&
  [PROGRESS_STATEMENT_STATUSES.ACCEPTED, PROGRESS_STATEMENT_STATUSES.COMPLETED].includes(lastProgressStatement.status);

// -- PS Discount

export const computeSuggestedDiscountFromLines = <
  T extends
    | { cumulativeAmountExVAT: number; item: IItemResponseDTO }
    | Pick<IProgressStatementLine, 'cumulativeAmountExVAT' | 'item'>,
>(
  lines: T[],
  previousDiscountLine: IProgressStatementDiscountLine | IProgressStatementCustomDiscountLine | undefined | null,
): number => {
  const { cumulativeSum, totalSum } = lines.reduce(
    (acc, { cumulativeAmountExVAT, item: { totalExVAT, isOptional } }) => {
      if (isOptional) return acc;
      return {
        cumulativeSum: acc.cumulativeSum + (cumulativeAmountExVAT ?? 0),
        totalSum: acc.totalSum + totalExVAT,
      };
    },
    {
      cumulativeSum: 0,
      totalSum: 0,
    },
  );

  let percentage = 0;

  // If totalSum is equal to 0 or when cumulativeSum is greater than totalSum, then discount has to be fully counted
  if (totalSum === 0 || cumulativeSum >= totalSum) {
    percentage = 100;
  }

  // If totalSum and cumulativeSum don't have the same sign, then discount has not to be counted
  else if (totalSum * cumulativeSum <= 0) {
    percentage = 0;
  } else {
    percentage = getFloatingPercentage(cumulativeSum, totalSum);
  }

  // Percentage cannot decrease, so previous percentage is kept if current percentage is lower
  const previousPercentage = previousDiscountLine ? previousDiscountLine.cumulativeProgressPercentage : 0;
  return Math.max(previousPercentage, percentage);
};

/**
 * It will use the prefilled line's cumulativeAmountExVAT and previousCumulativeAmountExVAT to fill
 * the line with missing data
 */
export const getProgressStatementLineWithData = (
  baseProgressStatementLine: Required<IProgressStatementLineWithItemDTO> | IProgressStatementLine,
  previousStatementLine: IProgressStatementLine | undefined | null,
): IProgressStatementLineToSave => {
  if (!baseProgressStatementLine || !baseProgressStatementLine.item) {
    throw new Error("baseProgressStatementLine can't be null neither its item neither");
  }

  const { cumulativeAmountExVAT } = baseProgressStatementLine;

  const previousCumulativeProgressAmount = previousStatementLine ? previousStatementLine.cumulativeAmountExVAT : 0;
  const nonCumulativeAmountExVAT = cumulativeAmountExVAT - previousCumulativeProgressAmount;
  return {
    ...baseProgressStatementLine,
    cumulativeAmountExVAT,
    nonCumulativeAmountExVAT,
    externalComment: null,
  };
};

/**
 * It will use the prefilled line's cumulativeAmountExVAT to fill the line with missing data
 */
export const getProgressStatementDiscountLineWithData = (
  baseDiscountLine: Required<IProgressStatementDiscountLineWithContractDTO> | IProgressStatementDiscountLine,
  previousDiscountLine: IProgressStatementDiscountLine | undefined | null,
  contractItemsLines: Array<Required<IProgressStatementLineWithItemDTO> | IProgressStatementLine>,
  contract: IContract,
): RawExtended<Required<IProgressStatementDiscountLine>, 'progressStatement'> => {
  let { cumulativeAmountExVAT } = baseDiscountLine;

  const discountAmountExVAT = getDiscountAmountExVAT(contract.discount, contract.totalAmountWithoutDiscountExVAT);

  if (baseDiscountLine.mode === DISCOUNT_LINE_MODE.AUTO) {
    const cumulativeProgressPercentage = computeSuggestedDiscountFromLines(contractItemsLines, previousDiscountLine);
    cumulativeAmountExVAT = getAmountPercentage(discountAmountExVAT, cumulativeProgressPercentage);
  }

  const previousCumulativeProgressAmount = previousDiscountLine ? previousDiscountLine.cumulativeAmountExVAT : 0;
  const nonCumulativeAmountExVAT = (cumulativeAmountExVAT ?? 0) - (previousCumulativeProgressAmount ?? 0);

  return {
    ...baseDiscountLine,
    cumulativeAmountExVAT: cumulativeAmountExVAT || 0,
    nonCumulativeAmountExVAT,
  };
};

/**
 * It will use the prefilled line's cumulativeAmountExVAT to fill the line with missing data
 */
export const getProgressStatementCustomDiscountLineWithData = (
  baseCustomDiscountLine: Required<IProgressStatementCustomDiscountLineWithContractDTO>,
  previousCustomDiscountLine: IProgressStatementCustomDiscountLine | undefined | null,
  contractItemsLines: Array<Pick<IProgressStatementLine, 'cumulativeAmountExVAT' | 'item'>>,
  contract: IContract,
): RawExtended<Required<IProgressStatementCustomDiscountLine>, 'progressStatement'> => {
  let { cumulativeAmountExVAT } = baseCustomDiscountLine;

  const customDiscountAmountExVAT = getCustomDiscountAmountExVAT(
    baseCustomDiscountLine.contractCustomDiscount,
    contract.totalAmountWithDiscountExVAT,
  );

  if (baseCustomDiscountLine.mode === PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO) {
    const cumulativeProgressPercentage = computeSuggestedDiscountFromLines(
      contractItemsLines,
      previousCustomDiscountLine,
    );
    cumulativeAmountExVAT = getAmountPercentage(customDiscountAmountExVAT, cumulativeProgressPercentage);
  }

  const previousCumulativeProgressAmount = previousCustomDiscountLine
    ? previousCustomDiscountLine.cumulativeAmountExVAT
    : 0;
  const nonCumulativeAmountExVAT = (cumulativeAmountExVAT ?? 0) - (previousCumulativeProgressAmount ?? 0);

  return {
    ...baseCustomDiscountLine,
    cumulativeAmountExVAT: cumulativeAmountExVAT || 0,
    nonCumulativeAmountExVAT,
  };
};

// -- Computing amounts

/**
 * Compute the sum of each line's cumulativeAmountExVAT to get a total ()
 */
export const getProgressStatementCumulativeAmountExVAT = (
  lines: Pick<IProgressStatementLineToSave, 'cumulativeAmountExVAT'>[],
): number => sumObjects(lines, 'cumulativeAmountExVAT');

/**
 * Compute the sum of each line's nonCumulativeAmountExVAT to get a total ()
 */
export const getProgressStatementNonCumulativeAmountExVAT = (
  lines: Pick<IProgressStatementLineToSave, 'nonCumulativeAmountExVAT'>[],
): number => sumObjects(lines, 'nonCumulativeAmountExVAT');

/**
 * Compute the current progress percentage of the subProject: cumulativeProgressAmountExVAT / subProject.totalAmountExVAT
 */
export const getProgressStatementCumulativeProgressPercentage = (
  progressStatement: Pick<IProgressStatement, 'cumulativeProgressAmountExVAT'>,
  subProject: Pick<ISubProject, 'amountExVAT'>,
): number => getPercentage(progressStatement.cumulativeProgressAmountExVAT || 0, subProject.amountExVAT);

export const computeLineCumulativeProgressPercentage = (
  cumulativeAmountExVAT: number,
  item: Pick<IItem, 'totalExVAT'>,
): number => getFloatingPercentage(cumulativeAmountExVAT, item.totalExVAT);

/**
 * Returns the holdback amount of this progress statement.
 * If there is not holdback amount, it will return 0
 * Computing: (Montant TTC Cumulé situation * % Retenue garantie - Montant TTC Cumulé précédente situation * % Retenue garantie)
 * WARNING: It should use the holdback's hasPaymentGuarantee variable (retenue de garantie cautionnée)
 * to know if it should be billed to the client. If it has paymentGuarantee, there is not holdback to remove (0) because the client pays.
 *
 * En français: Si la retenue de garantie est cautionnée par la banque, on ne retire rien au total à payer par le client, il paye
 * la totalité et c'est la banque qui prendra en charge si y'a des soucis.
 */
export const getProgressStatementHoldbackAmount = (
  progressStatementWithAmounts: Pick<
    IProgressStatement,
    'cumulativeProgressAmountIncVAT' | 'nonCumulativeProgressAmountIncVAT'
  >,
  holdback: IHoldback | undefined | null,
): number => {
  // amountIncVAT is here the amount before price revision
  const amounts = getHoldbackAmountsWithCumulativeMethod(
    holdback,
    progressStatementWithAmounts.cumulativeProgressAmountIncVAT || 0,
    progressStatementWithAmounts.nonCumulativeProgressAmountIncVAT || 0,
  );

  return amounts.nonCumulativeHoldbackWithPaymentAmount;
};

/**
 * Compute the price revision, including taxes
 * Computing: TVA pondérée * Montant révision des prix HT
 */
export const getProgressStatementPriceRevisionIncVAT = (
  priceRevisionExVAT: number,
  vatDistribution: { amountPriceRevision: number }[],
  subProject: RequiredByKeys<ISubProject, 'project'>,
): number => {
  if (subProject.hasReversalOfLiability) {
    return priceRevisionExVAT;
  }

  if (subProject.project.priceRevisionMethod === PRICE_REVISION_METHOD.WEIGHTED_VAT) {
    return roundFloating(multiplyFloating(priceRevisionExVAT, 1 + getSubProjectWeightedVAT(subProject)));
  }

  return priceRevisionExVAT + sumObjects(vatDistribution, 'amountPriceRevision');
};

type ProgressStatementForNetBilledIncVAT = Required<
  Pick<
    IProgressStatement,
    | 'amountExVAT'
    | 'amountIncVAT'
    | 'holdbackAmount'
    | 'nonCumulativeHoldbackWithPaymentGuaranteeAmountIncVAT'
    | 'downPaymentAmortizationAmount'
    | 'cumulativeProgressAmountExVAT'
    | 'cumulativeProgressAmountIncVAT'
    | 'nonCumulativeProgressAmountExVAT'
    | 'nonCumulativeProgressAmountIncVAT'
    | 'priceRevisionIncVAT'
  >
>;
/**
 * Compute the amount to bill to the client, including taxes
 */
export const getProgressStatementNetBilledIncVAT = (
  progressStatementWithData: ProgressStatementForNetBilledIncVAT,
  deductionsActives: IDeduction[],
  penalties: IPenaltyUpsertDTO[],
): number => {
  const nonCumulativeAmountIncVAT = progressStatementWithData.nonCumulativeProgressAmountIncVAT || 0;
  const priceRevisionIncVAT = progressStatementWithData.priceRevisionIncVAT || 0;
  const holdbackIncPaymentGuaranteeAmount =
    progressStatementWithData.nonCumulativeHoldbackWithPaymentGuaranteeAmountIncVAT || 0;
  const downPaymentAmortizationAmount = progressStatementWithData.downPaymentAmortizationAmount || 0;

  const totalDeductions = computeTotalDeductionAmountIncVAT(deductionsActives, progressStatementWithData);
  const totalPenalties = sumObjects(penalties, 'amountIncVAT');

  return (
    nonCumulativeAmountIncVAT +
    priceRevisionIncVAT -
    holdbackIncPaymentGuaranteeAmount -
    downPaymentAmortizationAmount -
    totalDeductions +
    totalPenalties
  );
};

/**
 * Compute the amount to pay to the client, including taxes
 * Computing: Montant TTC situation - [ Retenue garantie + Reprise d'avance forfaitaire + SUM(paiement direct ST) ]
 */
export const getProgressStatementNetToPaidIncVAT = (
  netBilledIncVAT: IProgressStatement['netBilledIncVAT'],
  supplierInvoices: NonNullable<IProgressStatement['supplierInvoices']>,
): number => {
  const incVATSum = sumObjects(supplierInvoices, 'amountIncVAT');

  return (netBilledIncVAT || 0) - incVATSum;
};

/**
 * Return an object describing cumulativeBase of vat for this line
 * (Ex: { cumulativeBase: 5000, vatRate: 0.2 })
 */
export const getLineVATObjectWithBases = (line: IProgressStatementLineToSave): IVatCumulativeBase => {
  const {
    cumulativeAmountExVAT: cumulativeBase,
    nonCumulativeAmountExVAT: base,
    item: { vatRate },
  } = line;

  return {
    base,
    cumulativeBase,
    vatRate,
  };
};

/**
 * Compute progress statements amounts with new computation method (VAT bases rounded)
 */
export const computeProgressStatementVATDistributionCumulativeWithBasesRoundedMethod = (
  contractsLinesAndDiscountLine: {
    lines: IProgressStatementLineToSave[];
    discountLine: IProgressStatementDiscountLineToSave | undefined;
    customDiscountLines: IProgressStatementCustomDiscountLineToSave[];
  }[],
  hasReversalOfLiability: boolean,
): Omit<
  IVatDiscountedBaseExtended,
  | 'amountDownPayment'
  | 'amountHoldback'
  | 'basePriceRevision'
  | 'amountPriceRevision'
  | 'holdbackWithPaymentAmount'
  | 'cumulativeHoldbackWithPaymentAmount'
>[] => {
  const vatDistributions: Array<{
    vatRate: number;
    base: number;
    cumulativeBase: number;
    discountedCumulativeBase: number;
    previousDiscountedCumulativeBase: number;
  }>[] = [];
  const customDiscountVatDistributions: Omit<
    IVatDiscountedBaseExtended,
    | 'amountDownPayment'
    | 'amountHoldback'
    | 'basePriceRevision'
    | 'amountPriceRevision'
    | 'holdbackWithPaymentAmount'
    | 'cumulativeHoldbackWithPaymentAmount'
  >[][] = [];

  contractsLinesAndDiscountLine.forEach(({ lines, discountLine, customDiscountLines }) => {
    const vatCumulativeBases = mergeVATDistribution(lines.map(getLineVATObjectWithBases));

    const { vatCustomDiscountedBases, vatPrimaryDiscountedBases } =
      computeVatDistributionCumulativeDiscountedWithBasesRoundedMethod(
        vatCumulativeBases,
        discountLine,
        customDiscountLines,
      );
    vatDistributions.push(vatPrimaryDiscountedBases);
    customDiscountVatDistributions.push(vatCustomDiscountedBases);
  });

  const vatDistribution = mergeVATDistribution(vatDistributions.flat(1));
  const customDiscountVatDistribution = mergeVATDistribution(customDiscountVatDistributions.flat(1));

  // Then, cumulative and non cumulative amount have to be calculated
  const discountedPrimaryVatDistribution = vatDistribution.map(
    ({ vatRate, discountedCumulativeBase, previousDiscountedCumulativeBase, cumulativeBase, base }) => {
      const cumulativeAmount = roundFloating(multiplyFloating(discountedCumulativeBase, vatRate));
      const previousAmount = roundFloating(multiplyFloating(previousDiscountedCumulativeBase, vatRate));

      return {
        cumulativeAmount,
        amount: cumulativeAmount - previousAmount,
        cumulativeBase,
        discountedCumulativeBase,
        base,
        discountedBase: discountedCumulativeBase - previousDiscountedCumulativeBase,
        vatRate,
      };
    },
  );

  return hasReversalOfLiability
    ? customDiscountVatDistribution
    : mergeVATDistribution([...discountedPrimaryVatDistribution, ...customDiscountVatDistribution]);
};

/**
 * Compute progress statements amounts with new computation method (VAT bases)
 */
export const computeProgressStatementVATDistributionCumulativeWithBasesMethod = (
  contractsLinesAndDiscountLine: {
    lines: IProgressStatementLineToSave[];
    discountLine: IProgressStatementDiscountLineToSave | undefined;
    customDiscountLines: IProgressStatementCustomDiscountLineToSave[];
  }[],
  hasReversalOfLiability: boolean,
): Omit<
  IVatComponentExtended,
  | 'amountDownPayment'
  | 'amountHoldback'
  | 'basePriceRevision'
  | 'amountPriceRevision'
  | 'holdbackWithPaymentAmount'
  | 'cumulativeHoldbackWithPaymentAmount'
>[] => {
  const vatDistributions: Array<{
    vatRate: number;
    base: number;
    cumulativeBase: number;
    discountedCumulativeBase: number;
    previousDiscountedCumulativeBase: number;
  }>[] = [];
  const customDiscountVatDistributions: Omit<
    IVatComponentExtended,
    | 'amountDownPayment'
    | 'amountHoldback'
    | 'basePriceRevision'
    | 'amountPriceRevision'
    | 'holdbackWithPaymentAmount'
    | 'cumulativeHoldbackWithPaymentAmount'
  >[][] = [];

  contractsLinesAndDiscountLine.forEach(({ lines, discountLine, customDiscountLines }) => {
    const vatCumulativeBases = mergeVATDistribution(lines.map(getLineVATObjectWithBases));
    const { vatCustomDiscountedBases, vatPrimaryDiscountedBases } =
      computeVatDistributionCumulativeDiscountedWithBasesMethod(vatCumulativeBases, discountLine, customDiscountLines);
    vatDistributions.push(vatPrimaryDiscountedBases);
    customDiscountVatDistributions.push(vatCustomDiscountedBases);
  });

  const vatDistribution = mergeVATDistribution(vatDistributions.flat(1));
  const customDiscountVatDistribution = mergeVATDistribution(customDiscountVatDistributions.flat(1));

  // Then, cumulative and non cumulative amount have to be calculated
  const discountedPrimaryVatDistribution = vatDistribution.map(
    ({ vatRate, discountedCumulativeBase, previousDiscountedCumulativeBase, cumulativeBase, base }) => {
      const cumulativeAmount = roundFloating(multiplyFloating(discountedCumulativeBase, vatRate));
      const previousAmount = roundFloating(multiplyFloating(previousDiscountedCumulativeBase, vatRate));

      return {
        cumulativeAmount,
        amount: cumulativeAmount - previousAmount,
        cumulativeBase,
        base,
        vatRate,
      };
    },
  );

  if (hasReversalOfLiability) {
    return customDiscountVatDistribution;
  }
  return mergeVATDistribution([...discountedPrimaryVatDistribution, ...customDiscountVatDistribution]);
};

/**
 * Compute progress statements amounts with legacy computation method
 */
export const computeProgressStatementVATDistributionCumulativeWithSumsMethod = (
  contractsLinesAndDiscountLine: {
    lines: IProgressStatementLineToSave[];
    discountLine: IProgressStatementDiscountLineToSave | undefined;
    customDiscountLines: IProgressStatementCustomDiscountLineToSave[];
  }[],
  hasReversalOfLiability: boolean,
): Omit<
  IVatComponentCumulative,
  | 'amountDownPayment'
  | 'amountHoldback'
  | 'basePriceRevision'
  | 'amountPriceRevision'
  | 'holdbackWithPaymentAmount'
  | 'cumulativeHoldbackWithPaymentAmount'
>[] => {
  const vatDistributions = contractsLinesAndDiscountLine.map(({ lines, discountLine, customDiscountLines }) =>
    computeVatDistributionCumulativeDiscountedWithSumsMethod(
      lines,
      discountLine,
      customDiscountLines,
      hasReversalOfLiability,
    ),
  );

  return mergeVATDistribution(vatDistributions.flat(1));
};

/**
 * Build progress statement item lines and discount lines based on previous progress statement
 */
export const getProgressStatementLines = (
  /**
   * The current progress statement lines
   */
  progressStatementLines: {
    lines: Required<IProgressStatementLineWithItemDTO>[];
    discountLines: Required<IProgressStatementDiscountLineWithContractDTO>[];
    customDiscountLines: Required<IProgressStatementCustomDiscountLineWithContractDTO>[];
  },
  /**
   * The previous progress statement with lines and line items
   */
  previousProgressStatement: IProgressStatement | undefined | null,
  /**
   * Project contracts
   */
  contracts: RequiredByKeys<IContract, 'container'>[],
): {
  lines: IProgressStatementLineToSave[];
  discountLines: RawExtended<Required<IProgressStatementDiscountLine>, 'progressStatement'>[];
  customDiscountLines: RawExtended<Required<IProgressStatementCustomDiscountLine>, 'progressStatement'>[];
} => {
  const errors = [];
  if (!progressStatementLines.lines) {
    errors.push('progressStatement lines are null');
  }
  if (previousProgressStatement && !previousProgressStatement.lines) {
    errors.push('previousProgressStatement lines are null');
  }

  // Adding item lines with previous values
  const lines = progressStatementLines.lines.map((line) => {
    let previousStatementLine: IProgressStatementLine | undefined;
    if (previousProgressStatement) {
      if (!previousProgressStatement.lines) {
        throw new Error('Missing lines relation');
      }
      previousStatementLine = previousProgressStatement.lines.find(
        (previousLine) => previousLine.item.id === line.item.id,
      );
    }
    return getProgressStatementLineWithData(line, previousStatementLine);
  });

  // Adding discount lines with previous values
  const discountLines = (progressStatementLines.discountLines || []).map((discountLine) => {
    let previousStatementDiscountLine;
    if (previousProgressStatement && previousProgressStatement.discountLines) {
      previousStatementDiscountLine = previousProgressStatement.discountLines.find(
        (previousLine) => previousLine.contract.id === discountLine.contract.id,
      );
    }

    const currentContract = contracts.find(({ id: contractId }) => contractId === discountLine.contract.id);
    if (!currentContract) {
      throw new Error(`Contract with id ${discountLine.contract.id} not found`);
    }

    const contractItems = progressStatementLines.lines.filter(
      ({ item: { container } }) => container.id === currentContract?.container.id,
    );

    return getProgressStatementDiscountLineWithData(
      discountLine,
      previousStatementDiscountLine,
      contractItems,
      currentContract,
    );
  });

  // Adding custom discount lines with previous values
  const customDiscountLines = (progressStatementLines.customDiscountLines || []).map((customDiscountLine) => {
    let previousStatementCustomDiscountLine;
    if (previousProgressStatement && previousProgressStatement.customDiscountLines) {
      previousStatementCustomDiscountLine = previousProgressStatement.customDiscountLines.find(
        (previousLine) => previousLine.contractCustomDiscount?.id === customDiscountLine.contractCustomDiscount?.id,
      );
    }

    const currentContract = contracts.find(
      ({ id: contractId }) => contractId === customDiscountLine.contractCustomDiscount.contract?.id,
    );
    if (!currentContract) {
      throw new Error(`Contract with id ${customDiscountLine.contractCustomDiscount.contract?.id} not found`);
    }

    const contractItems = progressStatementLines.lines.filter(
      ({ item: { container } }) => container.id === currentContract.container.id,
    );

    return getProgressStatementCustomDiscountLineWithData(
      customDiscountLine,
      previousStatementCustomDiscountLine,
      contractItems,
      currentContract,
    );
  });

  return {
    lines,
    discountLines,
    customDiscountLines,
  };
};

export const getPreviousCumulativeDownPaymentAmortizationAmountIncVAT = (
  lastProgressStatement: IProgressStatement | null | undefined,
): number | null =>
  lastProgressStatement && Number.isFinite(lastProgressStatement.cumulativeDownPaymentAmortizationAmountIncVAT)
    ? lastProgressStatement.cumulativeDownPaymentAmortizationAmountIncVAT
    : null;

export const getCumulativeDownPaymentAmortizationAmountIncVAT = (
  previousCumulativeDownPaymentAmortizationAmountIncVAT: number | null,
  downPaymentAmortizationAmount: number,
): number =>
  previousCumulativeDownPaymentAmortizationAmountIncVAT
    ? previousCumulativeDownPaymentAmortizationAmountIncVAT + downPaymentAmortizationAmount
    : downPaymentAmortizationAmount;

export const getProgressStatementVatDistributionForPayment = (
  progressStatement: IProgressStatement,
): IVatDistribution =>
  progressStatement.vatDistribution.map((vatObject) => {
    const { amount, vatRate, amountDownPayment, amountHoldback, amountPriceRevision } = vatObject;
    // @[ff: project-global-parameters] : replace by holdbackWithPaymentAmount
    return {
      vatRate,
      amount: amount - (amountDownPayment || 0) - (amountHoldback || 0) + (amountPriceRevision || 0),
    };
  });

export const resetProgressStatementDownPaymentAmortization = ({
  progressStatement,
  activeDeductions,
  supplierInvoices,
}: {
  progressStatement: IProgressStatement;
  activeDeductions: IDeduction[];
  supplierInvoices: ISupplierInvoice[];
}): IProgressStatement => {
  const progressStatementWithNullAmortization = {
    ...progressStatement,
    downPaymentAmortizationAmount: 0,
  };

  const netBilledIncVAT = getProgressStatementNetBilledIncVAT(
    progressStatementWithNullAmortization as ProgressStatementForNetBilledIncVAT,
    activeDeductions,
    [],
  );
  const netToPaidIncVAT = getProgressStatementNetToPaidIncVAT(netBilledIncVAT, supplierInvoices);

  return {
    ...progressStatementWithNullAmortization,
    netBilledIncVAT,
    netToPaidIncVAT,
  };
};

export const getUpdatedProgressStatementPercentage = (
  progressStatement: IProgressStatement,
  amountExVAT: number,
): IProgressStatement => ({
  ...progressStatement,
  cumulativeProgressPercentage: getProgressStatementCumulativeProgressPercentage(progressStatement, { amountExVAT }),
});

export const getUpdatedProgressStatementsPercentages = (
  progressStatements: IProgressStatement[] | undefined,
  amountExVAT: number,
): IProgressStatement[] => {
  if (!progressStatements || progressStatements.length === 0) {
    return [];
  }

  return progressStatements.map((progressStatement) =>
    getUpdatedProgressStatementPercentage(progressStatement, amountExVAT),
  );
};

export const getNextProgressStatement = (
  progressStatement: IProgressStatement,
  progressStatements: IProgressStatement[],
): IProgressStatement | undefined =>
  progressStatements.find((ps) => Number(ps.groupId) === Number(progressStatement.groupId) + 1);

const propagatePreviousLineCumulativeAmounts = <
  T extends IProgressStatementDiscountLine | IProgressStatementCustomDiscountLine | IProgressStatementLine,
>(
  previousLine: T | undefined,
  line: T,
): T => {
  const propagatedLine: T = {
    ...line,
    // Use previous line cumulative amount and percentage
    cumulativeProgressPercentage: previousLine ? previousLine.cumulativeProgressPercentage : 0,
    cumulativeAmountExVAT: previousLine ? previousLine.cumulativeAmountExVAT : 0,
    // We override the current cumulative amount so the current cumulative is equal to the previous cumulative
    nonCumulativeAmountExVAT: 0,
  };

  // Only for item lines
  const isItemLine =
    'cumulativeProgressQuantity' in propagatedLine && (!previousLine || 'cumulativeProgressQuantity' in previousLine);
  if (isItemLine) {
    const hasPreviousLine = previousLine && 'cumulativeProgressQuantity' in previousLine;
    propagatedLine.cumulativeProgressQuantity = hasPreviousLine ? previousLine.cumulativeProgressQuantity : 0;
  }

  return propagatedLine;
};

/**
 * Get updated line amounts from propagated progress statement update
 */
export const getPropagatedLineData = (
  previousLine: IProgressStatementLine | undefined,
  line: IProgressStatementLine,
): IProgressStatementLine => {
  const { cumulativeAmountExVAT, item } = line;
  const { cumulativeAmountExVAT: previousCumulativeAmountExVAT } = previousLine || { cumulativeAmountExVAT: 0 };

  const isLineCumulativeAmountGreaterThatItemAmount =
    item.totalExVAT > 0 ? cumulativeAmountExVAT > item.totalExVAT : cumulativeAmountExVAT < item.totalExVAT;

  if (isLineCumulativeAmountGreaterThatItemAmount) {
    return {
      ...line,
      cumulativeProgressPercentage: 100,
      cumulativeAmountExVAT: item.totalExVAT,
      nonCumulativeAmountExVAT: item.totalExVAT - previousCumulativeAmountExVAT,
      cumulativeProgressQuantity: item.quantity,
    };
  }

  const isLineCumulativeAmountGreaterThatPreviousLine =
    item.totalExVAT > 0
      ? previousCumulativeAmountExVAT > cumulativeAmountExVAT
      : previousCumulativeAmountExVAT < cumulativeAmountExVAT;
  /*
   * In the case of draft parallel progress statements if the
   * previous line cumulative is greater than current line we need to override amounts
   */
  if (isLineCumulativeAmountGreaterThatPreviousLine) {
    return propagatePreviousLineCumulativeAmounts(previousLine, line);
  }

  // Current line cumulative is greater than previous line
  // cumulativeProgressPercentage and cumulativeProgressQuantity are updated because they are depending
  // of item, and it can be changed
  return {
    ...line,
    cumulativeProgressPercentage: computeLineCumulativeProgressPercentage(cumulativeAmountExVAT, item),
    cumulativeProgressQuantity: divideFloating(cumulativeAmountExVAT, item.unitPrice),
    nonCumulativeAmountExVAT: cumulativeAmountExVAT - previousCumulativeAmountExVAT,
  };
};

/**
 * Get updated discount line amounts from propagated progress statement update
 */
export const getPropagatedDiscountLineData = (
  previousDiscountLine: IProgressStatementDiscountLine | undefined,
  discountLine: IProgressStatementDiscountLine,
): IProgressStatementDiscountLine => {
  const { cumulativeAmountExVAT, contract } = discountLine;
  const { cumulativeAmountExVAT: previousCumulativeAmountExVAT } = previousDiscountLine || { cumulativeAmountExVAT: 0 };

  const discountAmount = getDiscountAmountExVAT(contract.discount, contract.totalAmountWithoutDiscountExVAT);

  const isLineCumulativeAmountGreaterThatItemAmount =
    discountAmount > 0 ? cumulativeAmountExVAT > discountAmount : cumulativeAmountExVAT < discountAmount;

  if (isLineCumulativeAmountGreaterThatItemAmount) {
    return {
      ...discountLine,
      cumulativeProgressPercentage: 100,
      mode: PROGRESS_STATEMENT_DISCOUNT_MODES.MANUAL,
      cumulativeAmountExVAT: discountAmount,
      nonCumulativeAmountExVAT: discountAmount - previousCumulativeAmountExVAT,
    };
  }

  const greaterCumulativeAmount =
    discountAmount > 0
      ? previousCumulativeAmountExVAT > cumulativeAmountExVAT
      : previousCumulativeAmountExVAT < cumulativeAmountExVAT;

  /*
   * In the case of draft parallel progress statements if the
   * previous discount line cumulative is greater than current line we need to override amounts
   */
  if (greaterCumulativeAmount) {
    return propagatePreviousLineCumulativeAmounts(previousDiscountLine, discountLine);
  }

  // Current line cumulative is greater than previous line, cumulative amounts are untouched
  // Set the mode to manual to avoid the amount to be recomputed
  return {
    ...discountLine,
    mode: PROGRESS_STATEMENT_DISCOUNT_MODES.MANUAL,
    cumulativeProgressPercentage: getFloatingPercentage(cumulativeAmountExVAT, discountAmount),
    nonCumulativeAmountExVAT: discountLine.cumulativeAmountExVAT - previousCumulativeAmountExVAT,
  };
};

/**
 * Get updated custom discount line amounts from propagated progress statement update
 */
export const getPropagatedCustomDiscountLineData = (
  previousCustomDiscountLine: IProgressStatementCustomDiscountLine | undefined,
  customDiscountLine: IProgressStatementCustomDiscountLine,
): IProgressStatementCustomDiscountLine => {
  const { cumulativeAmountExVAT, contractCustomDiscount } = customDiscountLine;
  const { cumulativeAmountExVAT: previousCumulativeAmountExVAT } = previousCustomDiscountLine || {
    cumulativeAmountExVAT: 0,
  };

  if (!contractCustomDiscount?.contract) {
    throw new Error('Missing contract');
  }

  const customDiscountAmount = getCustomDiscountAmountExVAT(
    contractCustomDiscount,
    contractCustomDiscount.contract.totalAmountWithDiscountExVAT,
  );

  const isLineCumulativeAmountGreaterThatItemAmount =
    customDiscountAmount > 0
      ? cumulativeAmountExVAT > customDiscountAmount
      : cumulativeAmountExVAT < customDiscountAmount;

  if (isLineCumulativeAmountGreaterThatItemAmount) {
    return {
      ...customDiscountLine,
      cumulativeProgressPercentage: 100,
      mode: PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.MANUAL,
      cumulativeAmountExVAT: customDiscountAmount,
      nonCumulativeAmountExVAT: customDiscountAmount - previousCumulativeAmountExVAT,
    };
  }

  const greaterCumulativeAmount =
    customDiscountAmount > 0
      ? previousCumulativeAmountExVAT > cumulativeAmountExVAT
      : previousCumulativeAmountExVAT < cumulativeAmountExVAT;

  /*
   * In the case of draft parallel progress statements if the
   * previous discount line cumulative is greater than current line we need to override amounts
   */
  if (greaterCumulativeAmount) {
    return propagatePreviousLineCumulativeAmounts(previousCustomDiscountLine, customDiscountLine);
  }

  // Current line cumulative is greater than previous line, cumulative amounts are untouched
  // Set the mode to manual to avoid the amount to be recomputed
  return {
    ...customDiscountLine,
    mode: PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.MANUAL,
    cumulativeProgressPercentage: getFloatingPercentage(cumulativeAmountExVAT, customDiscountAmount),
    nonCumulativeAmountExVAT: cumulativeAmountExVAT - previousCumulativeAmountExVAT,
  };
};

/**
 * Check if current progress statement direct payments amounts are compatible with the previous statement ones
 */
export const isCurrentProgressStatementDownPaymentAmortizationValid = (
  progressStatement: IProgressStatement,
  lastProgressStatement?: IProgressStatement | null,
  downPayment?: IDownPayment | null,
): boolean => {
  if (!downPayment || !lastProgressStatement) {
    return true;
  }

  const downPaymentAmortizationRemainderAmount =
    downPayment.amountIncVAT -
    downPayment.amountIncVATAmortized -
    // If last statement is in ACCEPTED status, the downPaymentAmortizationAmount is already added in amountIncVATAmortized
    (lastProgressStatement.status !== PROGRESS_STATEMENT_STATUSES.ACCEPTED
      ? lastProgressStatement.downPaymentAmortizationAmount
      : 0);
  return progressStatement.downPaymentAmortizationAmount <= downPaymentAmortizationRemainderAmount;
};

export const computeLineCumulativeAmountExVAT = (
  line: IProgressStatementLineWithItemDTO,
  cumulativeInputType: CUMULATIVE_INPUT_TYPE,
): number => {
  switch (cumulativeInputType) {
    // User input
    case CUMULATIVE_INPUT_TYPE.AMOUNT:
      return line.cumulativeAmountExVAT || 0;
    // Determine computed cumulative amount based on percentage
    case CUMULATIVE_INPUT_TYPE.PERCENTAGE:
      return getAmountPercentage(line.item.totalExVAT, line.cumulativeProgressPercentage);
    // Determine computed cumulative amount based on quantity
    case CUMULATIVE_INPUT_TYPE.QUANTITY:
      return roundFloating(multiplyFloating(line.cumulativeProgressQuantity || 0, line.item.unitPrice));
    default:
      throw new Error('Invalid cumulativeInputType');
  }
};

export const computeLineCumulativeAmounts = (
  line: IProgressStatementLineWithItemDTO,
  cumulativeInputType: CUMULATIVE_INPUT_TYPE,
): Pick<
  IProgressStatementLine,
  'cumulativeProgressQuantity' | 'cumulativeAmountExVAT' | 'cumulativeProgressPercentage'
> => {
  const { cumulativeProgressQuantity, cumulativeProgressPercentage, item } = line;

  const cumulativeAmountExVAT = computeLineCumulativeAmountExVAT(line, cumulativeInputType);

  switch (cumulativeInputType) {
    case CUMULATIVE_INPUT_TYPE.PERCENTAGE:
      return {
        cumulativeProgressQuantity: divideFloating(cumulativeAmountExVAT, item.unitPrice), // Computed
        cumulativeAmountExVAT, // Computed
        cumulativeProgressPercentage: cumulativeProgressPercentage || 0, // User input
      };
    case CUMULATIVE_INPUT_TYPE.QUANTITY:
      return {
        cumulativeProgressPercentage: computeLineCumulativeProgressPercentage(cumulativeAmountExVAT, item), // Computed
        cumulativeAmountExVAT, // Computed
        cumulativeProgressQuantity: cumulativeProgressQuantity || 0, // User input
      };
    case CUMULATIVE_INPUT_TYPE.AMOUNT:
      return {
        cumulativeProgressPercentage: computeLineCumulativeProgressPercentage(cumulativeAmountExVAT, item), // Computed
        cumulativeProgressQuantity: divideFloating(cumulativeAmountExVAT, item.unitPrice), // Computed
        cumulativeAmountExVAT, // User input
      };
    default:
      throw new Error('Invalid cumulativeInputType');
  }
};

// Can be used for both discount and custom discount lines
export const getCumulativeAmountsFromDiscountLine = (
  discountAmount: number,
  cumulativeAmountExVAT: number | undefined,
  cumulativeProgressPercentage: number,
  cumulativeInputType: CUMULATIVE_INPUT_TYPE,
): Pick<IProgressStatementDiscountLine, 'cumulativeAmountExVAT' | 'cumulativeProgressPercentage'> => {
  switch (cumulativeInputType) {
    case CUMULATIVE_INPUT_TYPE.PERCENTAGE:
    case CUMULATIVE_INPUT_TYPE.QUANTITY:
      return {
        cumulativeAmountExVAT: getAmountPercentage(discountAmount, cumulativeProgressPercentage), // Computed
        cumulativeProgressPercentage, // User input
      };
    case CUMULATIVE_INPUT_TYPE.AMOUNT:
      return {
        cumulativeProgressPercentage: getFloatingPercentage(cumulativeAmountExVAT || 0, discountAmount), // Computed
        cumulativeAmountExVAT: cumulativeAmountExVAT || 0, // User input
      };
    default:
      throw new Error('Invalid cumulativeInputType');
  }
};

export const computeDiscountLineCumulativeAmounts = (
  discountLine: IProgressStatementDiscountLineWithContractDTO,
  cumulativeInputType: CUMULATIVE_INPUT_TYPE,
): Pick<IProgressStatementDiscountLine, 'cumulativeAmountExVAT' | 'cumulativeProgressPercentage'> => {
  const { cumulativeAmountExVAT, cumulativeProgressPercentage, contract } = discountLine;
  const discountAmount = getDiscountAmountExVAT(contract.discount, contract.totalAmountWithoutDiscountExVAT);
  return getCumulativeAmountsFromDiscountLine(
    discountAmount,
    cumulativeAmountExVAT,
    cumulativeProgressPercentage,
    cumulativeInputType,
  );
};

export const computeCustomDiscountLineCumulativeAmounts = (
  customDiscountLineDTO: IProgressStatementCustomDiscountLineWithContractDTO,
  cumulativeInputType: CUMULATIVE_INPUT_TYPE,
): Pick<IProgressStatementDiscountLine, 'cumulativeAmountExVAT' | 'cumulativeProgressPercentage'> => {
  const {
    cumulativeAmountExVAT,
    cumulativeProgressPercentage,
    contractCustomDiscount: customDiscount,
  } = customDiscountLineDTO;

  if (!customDiscount.contract) {
    throw new Error('Contract is missing');
  }

  const customDiscountAmount = getCustomDiscountAmountExVAT(
    customDiscount,
    customDiscount.contract.totalAmountWithDiscountExVAT,
  );
  return getCumulativeAmountsFromDiscountLine(
    customDiscountAmount,
    cumulativeAmountExVAT,
    cumulativeProgressPercentage,
    cumulativeInputType,
  );
};

export const replaceOrPrependProgressStatement = (
  progressStatementToConcat: IProgressStatement,
  progressStatements: IProgressStatement[],
): IProgressStatement[] => {
  const progressStatementConcat = progressStatements.filter((ps) => ps.id !== progressStatementToConcat.id);

  return [progressStatementToConcat, ...progressStatementConcat];
};

const setOptionalSubLots = ({
  sublot,
  isOptional,
}: {
  sublot: {
    isOptional?: boolean;
    subLots?: {
      isOptional?: boolean;
    }[];
  };
  isOptional: boolean | undefined;
}): void => {
  if (sublot) {
    // eslint-disable-next-line no-param-reassign
    sublot.isOptional = isOptional;
    sublot.subLots?.forEach((sl) => setOptionalSubLots({ sublot: sl, isOptional }));
  }
};

interface ComputeAutoNumberingAndOptionalLotFromRootLotItems {
  code?: string | null;
  isOptional?: boolean;
}

interface ComputeAutoNumberingAndOptionalLotFromRootLotSubLot {
  code?: string;
  isOptional?: boolean;
  subLots?: ComputeAutoNumberingAndOptionalLotFromRootLotSubLot[];
  items?: ComputeAutoNumberingAndOptionalLotFromRootLotItems[];
}

export interface ComputeAutoNumberingAndOptionalLotFromRootLotNode {
  lots?: {
    code?: string | null;
    isOptional?: boolean;
  }[];
  subLots?: ComputeAutoNumberingAndOptionalLotFromRootLotSubLot[];
  items?: ComputeAutoNumberingAndOptionalLotFromRootLotItems[];
  isOptional?: boolean;
  code?: string;
}

export const computeAutoNumberingAndOptionalLotFromRootLot = (
  node: ComputeAutoNumberingAndOptionalLotFromRootLotNode,
  isAutoNumberingActived: boolean,
  additionalData: { hasOptionalItems: boolean },
  index = '',
): void => {
  /**
   * Set auto numbering for items
   */
  if ('items' in node && node.items) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    node.items.forEach((item: any, i: number) => {
      if (isAutoNumberingActived) {
        // eslint-disable-next-line no-param-reassign
        item.code = index ? `${index}.${i + 1}` : `${i + 1}`;
      }
      if (item.isOptional && !additionalData.hasOptionalItems) {
        // eslint-disable-next-line no-param-reassign
        additionalData.hasOptionalItems = true;
      }
    });
  }

  if ('subLots' in node && 'items' in node && node.subLots) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    node.subLots.forEach((subLot: any, i: number) => {
      if (isAutoNumberingActived) {
        // eslint-disable-next-line no-param-reassign
        subLot.code = index ? `${index}.${i + (node.items?.length || 0) + 1}` : `${i + (node.items?.length || 0) + 1}`;
      }
      computeAutoNumberingAndOptionalLotFromRootLot(subLot, isAutoNumberingActived, additionalData, subLot.code);
    });
  }

  if ('lots' in node && 'items' in node && node.lots) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    node.lots.forEach((lot: any, i: number) => {
      if (isAutoNumberingActived) {
        // eslint-disable-next-line no-param-reassign
        lot.code = index ? `${index}.${i + (node.items?.length || 0) + 1}` : `${i + (node.items?.length || 0) + 1}`;
      }
      computeAutoNumberingAndOptionalLotFromRootLot(lot, isAutoNumberingActived, additionalData, lot.code);
    });
  }

  /**
   * Compute Option
   */
  let allJobsAreOptional: boolean | undefined;
  let allLotsAreOptional: boolean | undefined;
  let allLotsUndefined: boolean | undefined;

  if ('lots' in node && node.lots?.length) {
    node.lots.forEach((sublot) => {
      if (sublot.isOptional === undefined) {
        setOptionalSubLots({ sublot, isOptional: false });
      }
    });
  }

  if ('items' in node && node.items?.length) {
    allJobsAreOptional = node.items.every((item) => item.isOptional);
  }

  if ('subLots' in node && node.subLots?.length) {
    allLotsUndefined = node.subLots.every((subLot) => subLot.isOptional === undefined);
    allLotsAreOptional = node.subLots.every((subLot) => subLot.isOptional === undefined || subLot.isOptional === true);
  }

  let isOptional: boolean | undefined;

  if ('items' in node && node.items?.length) {
    if ('subLots' in node && node.subLots?.length && !allLotsUndefined) {
      isOptional = allJobsAreOptional && allLotsAreOptional;
    } else {
      isOptional = allJobsAreOptional;
    }
  } else if ('subLots' in node && node.subLots?.length && !allLotsUndefined) {
    isOptional = allLotsAreOptional;
  }

  // eslint-disable-next-line no-param-reassign
  node.isOptional = isOptional;

  if ('subLots' in node && node.subLots?.length) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    node.subLots.forEach((subLot: any) => {
      if (subLot.isOptional === undefined) {
        setOptionalSubLots({ sublot: subLot, isOptional });
      }
    });
  }
};
