import { ContentTypeGuard, QuoteOptionalItemTransformUseCase } from '@org/quotation-lib';

import type { QuoteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';

export const createQuoteOptionalItemTransformCallback = (
  message: QuoteWsMessage & { oldType: string; newType: string },
) => {
  const quote = quoteGetFromStore();

  if (!quote) {
    return;
  }

  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();
  const hasClientRequest = quoteClientRequestsFromStore.find(
    (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
  );

  if (hasClientRequest) {
    quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
    return;
  }

  const node = quote.getTree().getNodeOrThrow(message.data.id);
  const quoteOptionalItem = node.getContent();

  if (
    node.getParentOrThrow().getId() !== message.data.parentId ||
    (node.getPrevSibling()?.getId() || null) !== message.data.prevSiblingId ||
    (node.getNextSibling()?.getId() || null) !== message.data.nextSiblingId
  ) {
    if (!message.data.parentId) {
      throw new Error('Cannot move root node');
    }
    node.move(message.data.parentId, message.data.prevSiblingId, message.data.nextSiblingId);
  } else if (
    ContentTypeGuard.isQuoteOptionalItem(quoteOptionalItem) &&
    message.oldType === 'QuoteOptionalItem' &&
    message.newType === 'QuoteBasicItem'
  ) {
    QuoteOptionalItemTransformUseCase.transformToQuoteBasicItem(quoteOptionalItem);
  }

  quoteSetToStore(quote);
};
