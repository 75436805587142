import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { EmailAutocompleteContact } from '@graneet/lib-ui';
import {
  Card,
  ConfirmDeletionModal,
  DocumentStatusCard,
  DocumentStatusCardActions,
  formatDateOrEmpty,
  GotoLink,
  useToast,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import type {
  IClient,
  IContactQuoteResponseDTO,
  IPdf,
  IProject,
  IQuoteFileColumns,
  IQuoteUpdateDTO,
  RequiredByKeys,
} from '@graneet/business-logic';
import { getEmailVariableData, PERMISSION, PDF_STATUSES } from '@graneet/business-logic';
import { QuoteStatus, type QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';
import {
  QUOTE_STATUS,
  Quote,
  QuoteStateMachine,
  QUOTE_TRANSITION_EVENT,
  QUOTE_TRANSITION_ERROR,
} from '@org/quotation-lib';

import { ConfirmQuoteAsLostModal } from '../modals/ConfirmQuoteAsLostModal';
import { AcceptQuoteModal } from '../modals/AcceptQuoteModal/AcceptQuoteModal';
import type { IQuoteCheckResponseDTO } from '../modals/AlertQuoteStatusCompletionErrorModal';
import { AlertQuoteStatusCompletionErrorModal } from '../modals/AlertQuoteStatusCompletionErrorModal';
import { EditQuoteStatusModal } from '../modals/EditQuoteStatusModal';
import { CreateQuoteModal } from '../modals/CreateQuoteModal';

import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import type { MailingModalProps } from 'features/mailing/components/MailingModal/MailingModal';
import { sendMail } from 'features/mailing/services/mailing.api';
import { getQuotationActions, getQuotationStatusCardIcon } from 'features/quote/services/quote.actions';
import { MailingModal } from 'features/mailing/components/MailingModal/MailingModal';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import { getDownloadUrl, getPreviewUrl } from 'features/pdf/services/pdf.api';
import { formatFileName } from 'features/file/services/file.util';
import { useQuotationApi } from 'features/quotation/services/quote.api';
import { QuoteStatusBadge } from 'features/quotation/quote-common/components/badges/QuoteStatusBadge';
import { quoteWithoutRelationShipToQuoteObjectMapper } from 'features/quotation/quote-common/mappers/quoteComposeToQuoteObjectMapper';

interface QuoteStatusCardProps {
  quote: QuoteWithoutRelationsDTO;

  pdf: IPdf | undefined;

  quoteContacts: IContactQuoteResponseDTO;

  reloadPdf(): void;

  project: RequiredByKeys<IProject, 'address'> | null;

  client: IClient | null;

  currentVersion: IQuoteFileColumns | undefined;

  reloadQuote(): void;
}

export const QuoteStatusCard: FC<QuoteStatusCardProps> = ({
  quote,
  pdf,
  project,
  client,
  currentVersion,
  reloadQuote,
  reloadPdf,
  quoteContacts,
}) => {
  const { t } = useTranslation(['global', 'quote', 'mailing']);
  const history = useHistory();
  const toast = useToast();
  const { createRedirectionWithSavedFilters } = useFiltersQuery();
  const [isPdfGenerated, setIsPdfGenerated] = useState(pdf?.status === PDF_STATUSES.GENERATED);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);

  const hasCreatePermission = usePermissions([PERMISSION.CREATE_QUOTES]);
  const hasAcceptPermission = usePermissions([PERMISSION.ACCEPT_QUOTES]);
  const completeErrorsRef = useRef<IQuoteCheckResponseDTO>({
    areMandatoryFieldsFilled: true,
    incompleteJobsCount: 0,
    isClientAttached: true,
  });
  const selectedProjectRef = useRef(project || undefined);
  const settledDateRef = useRef<string>();

  const { useQuoteDuplicate, useQuoteDelete, useQuoteChangeStatus, lazyGetProjectById } = useQuotationApi();
  const duplicateQuoteMutation = useQuoteDuplicate();
  const deleteQuoteMutation = useQuoteDelete();
  const changeStatusMutation = useQuoteChangeStatus();

  // Quote Modals
  const editModal = useDisclosure();
  const completeErrorsModal = useDisclosure();
  const markQuoteAsLostModal = useDisclosure();
  const acceptQuoteProjectModal = useDisclosure();
  const mailingModal = useDisclosure();
  const duplicationModal = useDisclosure();
  const deletionModal = useDisclosure();

  const redirectToQuoteDetail = useCallback(() => {
    history.push(`/opportunities/quotation/${quote.id}/edit`);
  }, [history, quote.id]);

  const statusCardIcon = useMemo(() => getQuotationStatusCardIcon(quote.status), [quote.status]);

  // Quote state machine
  const { useGetIncompleteQuoteAssetsCount } = useQuotationApi();
  const { data: incompleteAssetsCount } = useGetIncompleteQuoteAssetsCount(quote.id);
  const quoteObject = quoteWithoutRelationShipToQuoteObjectMapper(quote);
  const quoteStateMachine = QuoteStateMachine.execute(
    new Quote(
      {
        isAutomaticIndexActivated: quote.isAutomaticIndexActivated,
      },
      quoteObject,
    ),
    {
      areAllChildrenCompleted: incompleteAssetsCount === 0,
    },
  );

  const state = useMemo<QUOTE_STATUS>(() => quoteStateMachine.getSnapshot().value, [quoteStateMachine]);

  const footerText = useMemo(() => {
    if (state === QUOTE_STATUS.IMPORTING) {
      return t('quote:cards.state.breakdownBeingImported');
    }

    if (state === QUOTE_STATUS.DRAFT) {
      return t('quote:versions.waiting');
    }

    return undefined;
  }, [state, t]);

  // callbacks
  const goToEdit = useCallback(
    (quoteId: string) => {
      history.push(`/opportunities/quotation/${quoteId}/edit`, { quoteId });
    },
    [history],
  );

  const onEdit = useCallback(() => {
    if (state === QUOTE_STATUS.IMPORTING || state === QUOTE_STATUS.DRAFT) {
      goToEdit(quote.id);
    } else {
      editModal.onOpen();
    }
  }, [state, goToEdit, quote.id, editModal]);

  const onCompleteQuote = useCallback(async () => {
    try {
      setIsStatusUpdating(true);

      quoteStateMachine.send({ type: QUOTE_TRANSITION_EVENT.COMPLETE_QUOTE });

      if (quoteStateMachine.getSnapshot().value === QUOTE_STATUS.COMPLETED) {
        await changeStatusMutation.mutateAsync({
          quoteId: quote.id,
          status: QuoteStatus.Completed,
        });
        toast.success(t('global:words.c.success'), t('quote:completeModal.toastSuccess'));

        reloadQuote();
      } else {
        const errors = quoteStateMachine.getSnapshot().context.guardErrors;
        completeErrorsRef.current = {
          areMandatoryFieldsFilled:
            !errors.includes(QUOTE_TRANSITION_ERROR.NO_ADDED_DATE) &&
            !errors.includes(QUOTE_TRANSITION_ERROR.NO_VALIDITY_DURATION),
          incompleteJobsCount: incompleteAssetsCount,
          isClientAttached: !errors.includes(QUOTE_TRANSITION_ERROR.NO_CLIENT),
        };
        completeErrorsModal.onOpen();
      }
    } finally {
      setIsStatusUpdating(false);
    }
  }, [
    changeStatusMutation,
    completeErrorsModal,
    incompleteAssetsCount,
    quote.id,
    quoteStateMachine,
    reloadQuote,
    t,
    toast,
  ]);

  const handleQuoteInvoiced = useCallback(
    async (projectId: number, subProjectId: string | null | undefined, settledDate: string) => {
      setIsStatusUpdating(true);
      try {
        const associatedProject = await lazyGetProjectById(projectId);
        await changeStatusMutation.mutateAsync({
          quoteId: quote.id,
          status: QuoteStatus.Accepted,
          settledAt: settledDate,
          projectId,
          subProjectId: subProjectId ?? undefined,
        });
        setIsStatusUpdating(false);

        toast.success(
          t('global:words.c.success'),
          t('quote:successes.commandAdded', { name: associatedProject!.name }),
        );
        history.push(`/projects/${projectId}/contracts`);
      } catch {
        setIsStatusUpdating(false);
      }
    },
    [changeStatusMutation, history, lazyGetProjectById, quote.id, t, toast],
  );

  const onAssociateSubProject = useCallback(
    async (projectId: number, subProjectId: string | null, settledDate: string) => {
      try {
        setIsStatusUpdating(true);
        await handleQuoteInvoiced(projectId, subProjectId, settledDate);
      } finally {
        setIsStatusUpdating(false);
        acceptQuoteProjectModal.onClose();
      }
    },
    [acceptQuoteProjectModal, handleQuoteInvoiced],
  );

  const handleProjectCreation = useCallback(
    (settledDate: string) => {
      settledDateRef.current = settledDate;

      history.push('/projects/create', {
        quoteUUID: quote?.id,
        settledDate,
      });
    },
    [history, quote?.id],
  );

  const duplicateQuote = useCallback(
    async (values: IQuoteUpdateDTO) => {
      const newQuote = await duplicateQuoteMutation.mutateAsync({
        quoteId: quote.id,
        name: values.name,
        hasReversalOfLiability: values.hasReversalOfLiability,
        addedAt: values.date,
        startedAt: values.estimatedStartDate,
        refCode: values.refCode,
        workDuration: values.workDuration,
        validityDuration: values.validityDuration,
        comment: values.comment,
        mentions: values.mentions,
        note: values.note,
        projectAddress: values.projectAddress
          ? {
              address: values.projectAddress.address ?? null,
              city: values.projectAddress.city ?? null,
              postalCode: values.projectAddress.postalCode ?? null,
              country: values.projectAddress.country ?? null,
            }
          : undefined,
      });
      toast.success(t('global:words.c.success'), t('quote:creationForm.duplication.toastSuccess'));

      history.push(`/opportunities/quotation/${newQuote.id}`);
    },
    [duplicateQuoteMutation, history, quote.id, t, toast],
  );

  const redirectToExport = useCallback(() => {
    history.push(`/opportunities/quotation/${quote.id}/edit`);
  }, [history, quote.id]);

  const deleteQuote = useCallback(async () => {
    await deleteQuoteMutation.mutateAsync(quote.id);

    toast.success(t('quote:cards.delete.success', { name: quote.name }));
    createRedirectionWithSavedFilters('/opportunities/quotation', { replace: true })();
  }, [deleteQuoteMutation, quote.id, quote.name, toast, t, createRedirectionWithSavedFilters]);

  const onViewPdf = useCallback(() => {
    if (currentVersion) {
      window.open(getPreviewUrl(currentVersion.pdf.apiId));
    }
  }, [currentVersion]);

  const onDownloadPdf = useCallback(() => {
    if (currentVersion) {
      window.open(
        getDownloadUrl(
          currentVersion.pdf.apiId,
          formatFileName(currentVersion.name, currentVersion.date, currentVersion.refCode),
        ),
      );
    }
  }, [currentVersion]);

  const onMailSubmit = useCallback<MailingModalProps['onSubmit']>(
    (data) => sendMail(data.dto, data.attachments, data.mode),
    [],
  );

  const isLinkedToContract = state === QUOTE_STATUS.ACCEPTED && !!quote.projectId;

  const cardSubtitle: string = useMemo(
    () =>
      state === QUOTE_STATUS.ACCEPTED
        ? [t('quote:cards.state.acceptedOn'), formatDateOrEmpty(quote.settledAt, 'LL')].join(' ')
        : t('quote:versions.creationDate', {
            date: formatDateOrEmpty(quote.createdAt, 'LL'),
            defaultValue: '',
          }),
    [state, t, quote.settledAt, quote.createdAt],
  );

  const canOpenMailing = !!pdf && !!client && isPdfGenerated;

  const defaultMailContacts = useMemo(
    () =>
      quoteContacts
        .filter((quoteContact) => quoteContact.isQuoteDefaultEmailRecipient && quoteContact.email)
        .map((quoteContact) => ({
          firstName: quoteContact.firstName,
          lastName: quoteContact.lastName,
          email: quoteContact.email,
          role: quoteContact.role ?? undefined,
        })) as EmailAutocompleteContact[],
    [quoteContacts],
  );

  useEffect(() => {
    if (pdf?.status === PDF_STATUSES.GENERATING) {
      subscribeToPDFUpdates({ apiId: pdf!.apiId }, (pdfStatus) => {
        reloadPdf();
        setIsPdfGenerated(pdfStatus === PDF_STATUSES.GENERATED);
      });
    }
  }, [pdf, reloadPdf, reloadQuote]);

  return (
    <Card
      title={
        <Flex>
          <Text mr={4}>{t('quote:cards.state.title')}</Text>
          {isLinkedToContract && (
            <GotoLink to={`/projects/${project!.id}/contracts`} label={t('quote:cards.state.goToContracts')} />
          )}
        </Flex>
      }
    >
      <DocumentStatusCard
        mb={4}
        icon={statusCardIcon}
        title={quote.name}
        subtitle={cardSubtitle}
        footer={
          footerText ? (
            <Text fontSize=".8em" color="black">
              {footerText}
            </Text>
          ) : undefined
        }
        badgeComponent={<QuoteStatusBadge quote={quote} />}
      />

      <DocumentStatusCardActions
        actions={getQuotationActions({
          quote,
          pdf: currentVersion?.pdf,

          isCompleteButtonLoading: isStatusUpdating,
          isAcceptButtonLoading: isStatusUpdating,

          hasCreatePermission,
          hasAcceptPermission,

          canOpenMailing,

          onOpenDetail: redirectToQuoteDetail,
          onCompleteQuote,
          onEdit,
          onMarkAsAccepted: acceptQuoteProjectModal.onOpen,
          onMarkAsLost: markQuoteAsLostModal.onOpen,
          onViewPdf,
          onDownloadPdf,
          onOpenMailing: mailingModal.onOpen,
          onGenerateExport: redirectToExport,
          onDuplicate: duplicationModal.onOpen,
          onDelete: deletionModal.onOpen,
        })}
      />

      {state === QUOTE_STATUS.COMPLETED && (
        <>
          <ConfirmQuoteAsLostModal modalControls={markQuoteAsLostModal} quote={quote} onMarkedAsLost={reloadQuote} />
          <AcceptQuoteModal
            project={selectedProjectRef.current ?? null}
            modalControls={acceptQuoteProjectModal}
            onAssociateSubProject={onAssociateSubProject}
            onCreateProject={handleProjectCreation}
            quote={quote}
          />
        </>
      )}

      <AlertQuoteStatusCompletionErrorModal
        modalControls={completeErrorsModal}
        onEdit={onEdit}
        quote={quote}
        errors={completeErrorsRef.current}
      />

      <EditQuoteStatusModal modalControls={editModal} onSuccess={goToEdit} quote={quote} />

      {canOpenMailing && !!client && (
        <MailingModal
          title={t('mailing:modal.title.quote')}
          isOpen={mailingModal.isOpen}
          onClose={mailingModal.onClose}
          onSubmit={onMailSubmit}
          variableData={getEmailVariableData('quotation', {
            quote,
            project: project || null,
            client,
          })}
          defaultMailContacts={defaultMailContacts}
        />
      )}

      <CreateQuoteModal
        title={t('quote:creationForm.duplication.title')}
        description={t('quote:creationForm.duplication.description')}
        action={t('quote:creationForm.duplication.submit')}
        onSubmit={duplicateQuote}
        allowedVersions={[2]}
        {...duplicationModal}
      />

      <ConfirmDeletionModal
        action={t('quote:cards.delete.action')}
        title={t('quote:cards.delete.title')}
        description={t('quote:cards.delete.modalConfirm')}
        onDeleted={deleteQuote}
        {...deletionModal}
      />
    </Card>
  );
};
