import type { MutableRefObject, ReactElement, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { Box } from '@chakra-ui/react';
import type { DropTargetMonitor } from 'react-dnd';

import { DropZone } from './DropZone';
import { Cursor } from './Cursor';
import { findSegment, useDropStyles } from './utils';
import { DropEffect } from './constants';
import { Segment } from './Segment';
import type { SegmentProps } from './contexts/SegmentContext';
import { SegmentContext, useSegment } from './contexts/SegmentContext';

type CallableChildren = (args: { isOver: boolean; segmentName: string | undefined }) => ReactElement;
export interface SegmentedDropZoneProps<Types extends string> {
  id: string | number;

  accept: Types | Types[];

  disabled?: boolean;

  children: (ReactNode | CallableChildren)[] | CallableChildren;
}

const SegmentedDropZoneComponent = <Types extends string, Item extends Record<any, unknown>>({
  id,
  accept,
  disabled = false,
  children,
}: SegmentedDropZoneProps<Types>) => {
  const [segment, setSegment] = useState<SegmentProps<Item> | undefined>(() => undefined);

  // Segments
  const segmentContext = useSegment<Item>();

  const canDrop = useCallback(
    (item: Item, monitor: DropTargetMonitor<Item>) => {
      if (segment === undefined) {
        return false;
      }

      if (!segment.canDrop) {
        return true;
      }
      return segment.canDrop(item, monitor);
    },
    [segment],
  );

  const onDropOnSegment = useCallback(
    (item: Item, monitor: DropTargetMonitor<Item>) => {
      if (segment !== undefined) {
        if (segment.onDrop) {
          segment.onDrop(item, monitor);
        }
        setSegment(undefined);
      }
    },
    [segment],
  );

  const onHover = useCallback(
    (item: Item, monitor: DropTargetMonitor<Item>, dropRef: MutableRefObject<HTMLDivElement | null>) => {
      const clientOffset = monitor.getClientOffset();
      if (!dropRef.current || clientOffset === null) {
        return;
      }

      const cursorY = clientOffset.y;
      const { top, height } = dropRef.current.getBoundingClientRect();

      const cursor = Math.min(1, Math.max(0, (cursorY - top) / height));
      const newSegment = findSegment<Item>(cursor, segmentContext.getSegment());
      if (segment !== newSegment && newSegment) {
        if (!newSegment.canDrop || newSegment.canDrop(item, monitor)) {
          setSegment(newSegment);
        } else if (segment !== undefined) {
          setSegment(undefined);
        }
      }
    },
    [segment, segmentContext],
  );

  const STYLES = useDropStyles();

  const effect = segment ? segment.effect : undefined;

  return (
    <DropZone
      id={id}
      accept={accept}
      canDrop={canDrop}
      onHover={onHover}
      onDrop={onDropOnSegment}
      disabled={disabled}
      effect={DropEffect.None}
    >
      {({ isOver }) => {
        const compileInfos = {
          isOver,
          segmentName: segment ? segment.name : undefined,
        };

        // Compile rendering children functions with drag and drop state infos

        let compiledChildren: SegmentedDropZoneProps<Types>['children'] | ReactElement = children;

        /*
         * In the case where we have just one child and it is a
         * function, no <Segment> are given:
         *
         * <SegmentedDropZone>
         *   {({ isOver,  }) => (...)} <== NEEDS TO BE COMPILED
         * <SegmentedDropZone>
         */
        if (typeof children === 'function') {
          compiledChildren = children(compileInfos);

          /*
           * In the case where we have many children and at least one
           * of them is a rendering function:
           *
           * <SegmentedDropZone>
           *
           *   <SegmentedDropZone.Segment />
           *   <SegmentedDropZone.Segment />
           *
           *   {({ isOver,  }) => (...)} <== NEEDS TO BE COMPILED
           *
           *   <SomeOtherComponent />
           *
           *   {({ isOver,  }) => (...)} <== NEEDS TO BE COMPILED
           *
           * <SegmentedDropZone>
           */
        } else if (Array.isArray(children)) {
          compiledChildren = children.map((childNode) =>
            typeof childNode === 'function' ? childNode(compileInfos) : childNode,
          );
        }

        return (
          <SegmentContext.Provider value={segmentContext}>
            <>
              {isOver && effect === DropEffect.CursorTop && <Cursor />}
              {isOver && effect === DropEffect.Glow ? (
                <Box {...STYLES.BOX[DropEffect.Glow]}>{compiledChildren as ReactNode}</Box>
              ) : (
                compiledChildren
              )}
              {isOver && effect === DropEffect.CursorBottom && <Cursor />}
            </>
          </SegmentContext.Provider>
        );
      }}
    </DropZone>
  );
};

export const SegmentedDropZone = Object.assign(SegmentedDropZoneComponent, {
  Segment,
});
