import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Modal, useToast } from '@graneet/lib-ui';
import type { ACCOUNTING_JOURNAL, AccountingAccountsNeeds, IAccountingExport } from '@graneet/business-logic';
import { ACCOUNTING_EXPORT_FORMAT, PERMISSION } from '@graneet/business-logic';
import { compact } from 'lodash-es';

import { populateDTOFromAccountingConfigForm } from '../../forms/accounting-form.util';
import type { AccountingMissingCodeFormValues } from '../AccountingMissingCodeForm';
import { useUpdateAccountingConfig } from '../../services/accounting-config.api';
import { canAccountingExportBeCreated } from '../../services/accounting-export.util';
import {
  useAccountingExportCheckNeeds,
  useAccountingExportCreate,
  useAccountingExportGenerate,
} from '../../services/accounting-export.api';

import type { AccountingExportGenerateSelectionStepFormValues } from './SelectionStep/AccountingExportGenerateSelectionStepFormValues';
import { AccountingExportNoPermissionStep } from './NoPermissionStep/AccountingExportNoPermissionStep';
import { AccountingExportGenerateSelectionStep } from './SelectionStep/AccountingExportGenerateSelectionStep';
import { AccountingExportGenerateMissingCodeStep } from './MissingCodeStep/AccountingExportGenerateMissingCodeStep';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { subscribeToSse } from 'features/sse/services/sse.util';
import { downloadFile } from 'features/file/services/file.util';

interface AccountingExportGenerateModalProps {
  isOpen: boolean;

  onClose(): void;

  onGenerated(): void;
}

export const AccountingExportGenerateModal: FC<AccountingExportGenerateModalProps> = ({
  isOpen,
  onClose,
  onGenerated,
}) => {
  const toast = useToast();
  const { t } = useTranslation(['accounting', 'global']);

  const [isLoading, setIsLoading] = useState(false);

  const [step, setStep] = useState<'selection' | 'missingCode' | 'noPermission'>('selection');

  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  const updateAccountingConfigMutation = useUpdateAccountingConfig();
  const { mutateAsync: accountingExportCreateMutationAsync } = useAccountingExportCreate();
  const { mutateAsync: accountingExportGenerateMutationAsync } = useAccountingExportGenerate();
  const accountingExportCheckNeedsMutation = useAccountingExportCheckNeeds();

  const afterFilesDownloaded = useCallback(() => {
    setIsLoading(false);
    onClose();
    onGenerated();
    setStep('selection');
  }, [onClose, onGenerated]);

  const handleClose = useCallback(() => {
    onClose();
    setStep('selection');
  }, [onClose]);

  const generateExports = useCallback(
    async (
      formValues: AccountingExportGenerateSelectionStepFormValues,
      selectedAccountingJournals: ACCOUNTING_JOURNAL[],
    ) => {
      const formats = compact([
        formValues.containsExcelFormat ? ACCOUNTING_EXPORT_FORMAT.XLSX : undefined,
        formValues.containsFecFormat ? ACCOUNTING_EXPORT_FORMAT.FEC : undefined,
      ]);

      let accountingExports: IAccountingExport[] = [];
      try {
        accountingExports = await Promise.all(
          selectedAccountingJournals.map(async (journal) =>
            accountingExportCreateMutationAsync({
              entity: undefined,
              selectedItems: undefined,
              fromDate: formValues.fromDate,
              journal,
              toDate: formValues.toDate,
              search: undefined,
              filters: undefined,
            }),
          ),
        );
      } catch {
        toast.error(t('global:errors.error'));
        setIsLoading(false);
        return;
      }

      if (formats.length === 0) {
        afterFilesDownloaded();
        return;
      }

      const accountingExportResponseDTO = await accountingExportGenerateMutationAsync(
        {
          entity: undefined,
          fromDate: undefined,
          selectedItems: undefined,
          toDate: undefined,
          accountingExportsIds: accountingExports.map((accountingExport) => accountingExport.id),
          formats,
          containsAssociatedPdfs: formValues.containsAssociatedPdfs,
          search: undefined,
          filters: undefined,
        },
        {
          onError: () => {
            setIsLoading(false);
          },
        },
      );

      if ('uuid' in accountingExportResponseDTO) {
        subscribeToSse<string>(accountingExportResponseDTO.uuid, {
          onMessage(data) {
            downloadFile(data);
            afterFilesDownloaded();
          },
          onError() {
            toast.error(t('global:errors.error'));
            setIsLoading(false);
          },
        });
      } else {
        downloadFile(accountingExportResponseDTO.url);
        afterFilesDownloaded();
      }
    },
    [accountingExportCreateMutationAsync, accountingExportGenerateMutationAsync, afterFilesDownloaded, t, toast],
  );

  const dataRef = useRef<null | {
    accountingAccountsNeeds: AccountingAccountsNeeds;
    formValues: AccountingExportGenerateSelectionStepFormValues;
    selectedAccountingJournals: ACCOUNTING_JOURNAL[];
  }>(null);
  const onExport = useCallback(
    async (
      formValues: AccountingExportGenerateSelectionStepFormValues,
      selectedAccountingJournals: ACCOUNTING_JOURNAL[],
    ) => {
      setIsLoading(true);
      const accountingAccountsNeeds = await accountingExportCheckNeedsMutation.mutateAsync({
        entity: undefined,
        selectedItems: undefined,
        fromDate: formValues.fromDate,
        toDate: formValues.toDate,
        journals: selectedAccountingJournals,
        search: undefined,
        filters: undefined,
      });

      const canCreateExports = canAccountingExportBeCreated(accountingAccountsNeeds);

      if (canCreateExports) {
        await generateExports(formValues, selectedAccountingJournals);
      } else if (!hasUpdateAccountingPermission) {
        setStep('noPermission');
      } else {
        dataRef.current = {
          accountingAccountsNeeds,
          formValues,
          selectedAccountingJournals,
        };
        setStep('missingCode');
        setIsLoading(false);
      }
    },
    [accountingExportCheckNeedsMutation, generateExports, hasUpdateAccountingPermission],
  );

  const onExportWithCodeSaving = useCallback(
    async (formValues: AccountingMissingCodeFormValues) => {
      setIsLoading(true);
      const dto = populateDTOFromAccountingConfigForm(formValues);

      await updateAccountingConfigMutation.mutateAsync(dto).catch(() => {
        setIsLoading(false);
      });

      await generateExports(dataRef.current!.formValues, dataRef.current!.selectedAccountingJournals);
    },
    [generateExports, updateAccountingConfigMutation],
  );

  const modalProps = useMemo(() => {
    switch (step) {
      case 'selection':
        return {
          title: t('accounting:exportModal.titleSelection'),
          description: undefined,
        };
      case 'missingCode':
        return {
          title: t('accounting:exportModal.titleMissingCode'),
          description: t('accounting:exportModal.descriptionMissingCode'),
        };
      case 'noPermission':
        return {
          title: t('accounting:exportModal.titleMissingCode'),
          description: t('accounting:exportModal.descriptionNoPermission'),
        };
      default:
        throw new Error('Not implemented');
    }
  }, [step, t]);

  return (
    <Modal title={modalProps.title} isOpen={isOpen} onClose={handleClose} size="4xl" scrollBehavior="inside">
      {modalProps.description}

      {step === 'selection' && (
        <AccountingExportGenerateSelectionStep onExport={onExport} onClose={handleClose} isLoading={isLoading} />
      )}

      {step === 'missingCode' && (
        <AccountingExportGenerateMissingCodeStep
          accountingAccountsNeeds={dataRef.current?.accountingAccountsNeeds!}
          onExport={onExportWithCodeSaving}
          onClose={handleClose}
          isLoading={isLoading}
        />
      )}

      {step === 'noPermission' && <AccountingExportNoPermissionStep onClose={handleClose} />}
    </Modal>
  );
};
