import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { TableSelectBannerChildrenProps } from '@graneet/lib-ui';
import { GraneetButton, useToast } from '@graneet/lib-ui';
import type { IReminder } from '@graneet/business-logic';
import { FILTERING_PARAMS, getNextBillStatusesAllowedForBatchActions, BILL_STATUSES } from '@graneet/business-logic';
import { countBy } from 'lodash-es';
import { Box, useDisclosure, CircularProgress } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import { BillUpdateAtToPaidStatusModal } from './BillUpdateAtToPaidStatusModal';

import { useBillStatusesToLost } from 'features/bill/services/bill.api';

export type BillForBatch = { id: number; status: BILL_STATUSES; isPaymentLate: boolean };

export interface BillBatchStatusActionsProps
  extends Omit<TableSelectBannerChildrenProps<BillForBatch>, 'resetSelectedCheckboxes'> {
  onStatusesChanged?: () => void | Promise<void>;

  scopeToReminder?: IReminder['id'];

  resetSelectedCheckboxes?: () => void;
}

export const BillBatchStatusActions: FC<BillBatchStatusActionsProps> = ({
  selectedItems,
  resetSelectedCheckboxes,
  hasAllCheckboxesSelected,
  currentFilters,
  onStatusesChanged,
  scopeToReminder,
}) => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'bill']);

  const billStatusesToLostMutation = useBillStatusesToLost();

  const numberOfItemsPerStatus = useMemo(
    () =>
      countBy(selectedItems, (item) => {
        if ([BILL_STATUSES.PAID, BILL_STATUSES.LOST].includes(item.status)) {
          return item.status;
        }
        if (item.status === BILL_STATUSES.AWAITING_PAYMENT && !item.isPaymentLate) {
          return item.status;
        }
        if (item.status === BILL_STATUSES.PARTIALLY_PAID && !item.isPaymentLate) {
          return item.status;
        }
        if (item.status === BILL_STATUSES.PARTIALLY_PAID && item.isPaymentLate) {
          return `${BILL_STATUSES.PARTIALLY_PAID}_LATE`;
        }
        return `${BILL_STATUSES.AWAITING_PAYMENT}_LATE`;
      }),
    [selectedItems],
  );

  const getRealBillStatus = useCallback(
    (status: string): BILL_STATUSES => status.replace('_LATE', '') as BILL_STATUSES,
    [],
  );
  const numberOfStatus = useMemo(() => Object.keys(numberOfItemsPerStatus).length, [numberOfItemsPerStatus]);

  const modal = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);

  /**
   * Hacky solution to display plural if `hasAllCheckboxesSelected` is selected
   */
  const labelSupplierInvoiceCounter = hasAllCheckboxesSelected ? 2 : selectedItems.length;

  const onOptionSelected = useCallback(
    async (nextStatus: BILL_STATUSES) => {
      if (nextStatus === BILL_STATUSES.PAID) {
        modal.onOpen();
        return;
      }

      setIsLoading(true);

      const apiCall = () => {
        switch (nextStatus) {
          case BILL_STATUSES.LOST:
            return billStatusesToLostMutation.mutateAsync;
          default:
            throw new Error(`Changes to ${nextStatus} is not implemented`);
        }
      };

      await apiCall()(
        {
          filters: qs.parse(currentFilters.toString()),
          selectedItems: selectedItems.map((item) => ({ id: item.id })),
          hasAllSelected: hasAllCheckboxesSelected,
          search: currentFilters.get(FILTERING_PARAMS.SEARCH) || undefined,
          scopeToReminder,
        },
        {
          onSuccess: () => {
            toast.success(t('bill:changeStatusModal.toast', { count: labelSupplierInvoiceCounter }));
            resetSelectedCheckboxes?.();
            onStatusesChanged?.();
          },
        },
      );
      setIsLoading(false);
    },
    [
      billStatusesToLostMutation.mutateAsync,
      currentFilters,
      hasAllCheckboxesSelected,
      labelSupplierInvoiceCounter,
      modal,
      onStatusesChanged,
      resetSelectedCheckboxes,
      scopeToReminder,
      selectedItems,
      t,
      toast,
    ],
  );

  /**
   * Do not allow change of bill with multiple statuses or on batch of entities at "PAID" or "LOST" status,
   * no action is allowed
   */
  if (
    numberOfStatus === 0 ||
    (numberOfStatus !== 1 &&
      !(
        Object.keys(numberOfItemsPerStatus).every((status) => status.startsWith(BILL_STATUSES.AWAITING_PAYMENT)) ||
        Object.keys(numberOfItemsPerStatus).every((status) => status.startsWith(BILL_STATUSES.PARTIALLY_PAID))
      )) ||
    numberOfItemsPerStatus[BILL_STATUSES.PAID] ||
    numberOfItemsPerStatus[BILL_STATUSES.LOST]
  ) {
    return null;
  }

  /**
   *  Now, we know that all statuses are equals and there is at least one selected item, so the first status is the status of all items
   */
  const status = Object.keys(numberOfItemsPerStatus)[0];
  const isPaymentLate = Object.keys(numberOfItemsPerStatus).every((currentStatus) =>
    [`${BILL_STATUSES.AWAITING_PAYMENT}_LATE`, `${BILL_STATUSES.PARTIALLY_PAID}_LATE`].includes(currentStatus),
  );

  const billStatus = getRealBillStatus(status);

  const nextStatusesAllowed = getNextBillStatusesAllowedForBatchActions(billStatus, isPaymentLate);

  return (
    <>
      <BillUpdateAtToPaidStatusModal
        modal={modal}
        selectedItems={selectedItems}
        resetSelectedCheckboxes={resetSelectedCheckboxes}
        hasAllCheckboxesSelected={hasAllCheckboxesSelected}
        currentFilters={currentFilters}
        onStatusesChanged={onStatusesChanged}
      />

      <Box>{isLoading && <CircularProgress isIndeterminate color="gray.800" size={6} />}</Box>

      {nextStatusesAllowed.includes(BILL_STATUSES.PAID) && (
        <GraneetButton size="sm" onClick={() => onOptionSelected(BILL_STATUSES.PAID)}>
          {t('bill:markAsPaid')}
        </GraneetButton>
      )}
      {nextStatusesAllowed.includes(BILL_STATUSES.LOST) && (
        <GraneetButton size="sm" variant="red" onClick={() => onOptionSelected(BILL_STATUSES.LOST)}>
          {t('bill:markAsLost')}
        </GraneetButton>
      )}
    </>
  );
};
