import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import type { FC } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { Box, HStack, useOutsideClick } from '@chakra-ui/react';
import { RICH_TEXT_BLOCK_TOOLBAR_PRESET, RichTextInput } from '@graneet/lib-ui';

export const DenominationEditorCell: FC<CustomCellEditorProps<QuoteComponentsSupplyObject>> = (params) => {
  const { data, column, onValueChange, stopEditing } = params;

  const boxRef = useRef<HTMLDivElement>(null);

  const handleOnChange = useCallback(
    () => (v: string) => {
      onValueChange(v);
    },
    [onValueChange],
  );

  useOutsideClick({
    ref: boxRef,
    handler: () => {
      setTimeout(() => {
        stopEditing();
      }, 0);
    },
  });

  const element = useMemo(() => {
    if (!data) {
      return null;
    }

    return (
      <HStack height="100%" width="100%" alignItems="flex-start" color="#1F2A37" fontSize="0.813rem" p={2} ref={boxRef}>
        <Box flex="1" alignItems="flex-start" width={column.getActualWidth()}>
          <RichTextInput
            autoFocus
            initialValue={data.denomination ?? ''}
            onChange={handleOnChange()}
            configuration={RICH_TEXT_BLOCK_TOOLBAR_PRESET}
            navbarType="inline"
            shouldDisplayError={false}
            disableTabIndentation
            useShiftEnter
            inputProps={{
              minHeight: '40px',
            }}
          />
        </Box>
      </HStack>
    );
  }, [column, data, handleOnChange]);

  return <Box boxSize="100%">{element}</Box>;
};
