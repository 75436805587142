import type { IPenaltyFindByProjectIdOutputDTO } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useSuspenseQuery } from '@tanstack/react-query';

import { apiNew } from 'features/api/services/apiNew.service';

const PENALTY_PATH = '/penalties';

const penaltyFactoryKey = createQueryKeys('penalties', {
  getByProject: (projectId: number) => ({
    queryKey: [PENALTY_PATH, projectId],
    queryFn: () => apiNew.get<never, IPenaltyFindByProjectIdOutputDTO>(`${PENALTY_PATH}/project/${projectId}`),
  }),
});

export function usePenaltyByProject(projectId: number) {
  return useSuspenseQuery(penaltyFactoryKey.getByProject(projectId));
}
