import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import {
  Modal,
  RICH_TEXT_BLOCK_TOOLBAR_PRESET,
  RichTextContextProvider,
  RichTextField,
  useRichText,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import { useStore } from 'store/store';

interface EditQuoteMentionsModalProps {
  isOpen: boolean;

  onClose(): void;

  onSubmit(text: string): void;

  title: string;

  placeholder: string;

  initialValue: string | null;
}

interface EditQuoteMentionsForm {
  text: string;
}

export const EditQuoteMentionsModal: FC<EditQuoteMentionsModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  placeholder,
  initialValue,
}) => {
  const { t } = useTranslation(['global']);

  const isEditable = useStore((store) => store.quoteEditable);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<EditQuoteMentionsForm>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues } = form;
  const templateRichTextContext = useRichText();

  useEffect(() => {
    if (initialValue) {
      form.setFormValues({
        text: initialValue,
      });
    } else {
      form.resetForm();
    }
  }, [form, initialValue]);

  const handleSubmit = useCallback(() => {
    if (!isEditable) {
      onClose();
      return;
    }
    setIsLoading(true);

    const values = getFormValues();
    onSubmit(values.text!);

    setIsLoading(false);
    onClose();
  }, [getFormValues, onClose, onSubmit, isEditable]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="3xl" scrollBehavior="inside">
      <Form form={form}>
        <Box mt={3}>
          <RichTextContextProvider value={templateRichTextContext}>
            <RichTextField<EditQuoteMentionsForm>
              noBorder
              name="text"
              placeholder={placeholder}
              navbarType="block"
              hideErrorMessage
              isDisabled={!isEditable}
              inputProps={{
                minH: '10rem',
                fontWeight: 500,
                fontSize: 'sm',
              }}
              configuration={RICH_TEXT_BLOCK_TOOLBAR_PRESET}
            />
          </RichTextContextProvider>
        </Box>
      </Form>

      <Modal.Close />

      <Modal.PrimaryButton isLoading={isLoading} isDisabled={!isFormValid} onClick={handleSubmit}>
        {t(isEditable ? 'global:words.c.save' : 'global:words.c.close')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
