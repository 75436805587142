import type { FC } from 'react';
import { useMemo, useCallback, useEffect } from 'react';
import { Modal, MultiAutocompleteField, CurrencyField, useCurrency, CheckboxField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IUser, IValidationStepWithRelations } from '@graneet/business-logic';
import { FEATURE_FLAGS, isNumberFinite } from '@graneet/business-logic';
import { useForm, Form, useFormStatus, useOnBlurValues } from 'graneet-form';
import { Box, Divider, Flex, HStack, Stack, Text } from '@chakra-ui/react';

import {
  useCreateValidationStepSupplierInvoice,
  useUpdateValidationStepSupplierInvoice,
} from '../services/validation-step-supplier-invoice.api';

import { ListingToPayStatus } from 'features/validation-step/components/ListingToPayStatus';
import { formatUserDisplayName } from 'features/user/services/user.util';
import { UserAvatars } from 'features/user/components/UserAvatars';
import { Rule } from 'features/form/rules/Rule';
import { useDisplayedUsers } from 'features/user/services/user.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

type MultipleOption = { value: string; label: string; user: IUser };

interface FormValues {
  amount: number;
  validatorIds: string[];
  areAccountManagersIncluded: boolean;
}

interface ValidationWorkflowUpsertModalProps {
  step?: IValidationStepWithRelations;
  onClose: () => void;
  isOpen: boolean;
  alreadyUsedAmounts: number[];
}

export const ValidationStepSupplierInvoiceUpsertModal: FC<ValidationWorkflowUpsertModalProps> = ({
  step,
  onClose,
  isOpen,
  alreadyUsedAmounts,
}) => {
  const { t } = useTranslation(['global', 'validationStep']);
  const updateValidationStepSupplierInvoice = useUpdateValidationStepSupplierInvoice();
  const createValidationStepSupplierInvoice = useCreateValidationStepSupplierInvoice();
  const hasWorkflowValidationImprovement = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION_IMPROVEMENT);

  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const users = useDisplayedUsers();

  const availableUsers = useMemo(
    () =>
      users.data.map<MultipleOption>((user) => ({
        value: user.id.toString(),
        // Add the user email to be able to search by email like what we do on /settings/company/users
        label: `${user.firstName ? user.firstName : ''} ${user.lastName} ${user.email ? user.email : ''}`.trim(),
        user,
      })),
    [users],
  );

  const form = useForm<FormValues>();
  const { areAccountManagersIncluded: watchedAreAccountManagersIncluded } = useOnBlurValues(form, [
    'areAccountManagersIncluded',
  ]);
  const { isValid } = useFormStatus(form);
  useEffect(() => {
    if (isOpen) {
      form.setFormValues({
        amount: isNumberFinite(step?.validationCondition.value)
          ? mapNumberToAmount(step?.validationCondition.value)
          : undefined,
        validatorIds: step?.validators.map(({ validator }) => String(validator?.id)) ?? [],
        areAccountManagersIncluded: step?.areAccountManagersIncluded ?? false,
      });
    } else {
      form.resetForm();
    }
  }, [form, step, isOpen, mapNumberToAmount]);

  const onSubmit = useCallback(async () => {
    const formValues = form.getFormValues();
    const { amount, validatorIds, areAccountManagersIncluded } = formValues;

    const dto = {
      amount: mapAmountToNumber(amount ?? 0),
      validatorIds: validatorIds?.map((validatorId) => Number(validatorId)) ?? [],
      areAccountManagersIncluded: areAccountManagersIncluded ?? false,
    };

    if (step) {
      const updatedValidationStep = await updateValidationStepSupplierInvoice.mutateAsync({ id: step.id, dto });
      if (updatedValidationStep.supplierInvoicesToPay.length === 0) {
        onClose();
      }
    } else {
      await createValidationStepSupplierInvoice.mutateAsync(dto);
      onClose();
    }
  }, [
    form,
    mapAmountToNumber,
    step,
    updateValidationStepSupplierInvoice,
    onClose,
    createValidationStepSupplierInvoice,
  ]);

  const alreadyUsedAmountsOnContext = useMemo(
    () =>
      step
        ? alreadyUsedAmounts.filter((value) => value !== mapNumberToAmount(step.validationCondition.value))
        : alreadyUsedAmounts,
    [alreadyUsedAmounts, mapNumberToAmount, step],
  );

  const supplierInvoicesToPay = useMemo(
    () => updateValidationStepSupplierInvoice.data?.supplierInvoicesToPay || [],
    [updateValidationStepSupplierInvoice.data?.supplierInvoicesToPay],
  );

  const modalTitle = useMemo(() => {
    if (supplierInvoicesToPay?.length) {
      return t('validationStep:supplierInvoice.listingToPayStatus');
    }
    return t(`validationStep:supplierInvoice.upsertModal.${step ? 'updateTitle' : 'addTitle'}`);
  }, [step, supplierInvoicesToPay?.length, t]);

  const handleOnClose = useCallback(() => {
    updateValidationStepSupplierInvoice.reset();
    onClose();
  }, [onClose, updateValidationStepSupplierInvoice]);

  const isLoading = updateValidationStepSupplierInvoice.isPending || createValidationStepSupplierInvoice.isPending;

  return (
    <Modal title={modalTitle} onClose={handleOnClose} isOpen={isOpen}>
      <ListingToPayStatus list={supplierInvoicesToPay} />
      {!isLoading && supplierInvoicesToPay.length === 0 && (
        <Form form={form}>
          <Divider mb={6} />
          <Stack spacing={4}>
            <Text color="gray.800" fontWeight={600}>
              {t('validationStep:supplierInvoice.upsertModal.conditionLabel')}
            </Text>
            <Flex justifyContent="space-between" alignItems="center">
              <Text>{t('validationStep:supplierInvoice.upsertModal.amountConditionLabel')}</Text>
              <Box w="8rem">
                <CurrencyField<FormValues> name="amount" min={0}>
                  <Rule.IsRequired />
                  <Rule.IsNotOneOf
                    message={t('validationStep:supplierInvoice.upsertModal.rule.notAvailable')}
                    values={alreadyUsedAmountsOnContext}
                  />
                </CurrencyField>
              </Box>
            </Flex>
          </Stack>
          <Divider my={6} />
          <Stack spacing={4}>
            <Text color="gray.800" fontWeight={600}>
              {t('validationStep:supplierInvoice.upsertModal.validatorsLabel')}
            </Text>
            <Text>{t('validationStep:supplierInvoice.upsertModal.validatorsDescription')}</Text>

            <Stack spacing={2}>
              <Text color="gray.500" fontWeight="300">
                {t('validationStep:graneetUsers')}
              </Text>
              <MultiAutocompleteField<FormValues, MultipleOption>
                name="validatorIds"
                placeholder={t('global:words.c.research')}
                options={availableUsers}
                formatValue={(value) => ({ ...value, label: formatUserDisplayName(value.user) })}
                render={({ data }) => (
                  <HStack>
                    <UserAvatars users={[data.user]} displayTooltip={false} />
                    <Text>{formatUserDisplayName(data.user)}</Text>
                  </HStack>
                )}
              >
                {!watchedAreAccountManagersIncluded && <Rule.IsNotEmptyArray />}
              </MultiAutocompleteField>
            </Stack>
            {/* @[ff: workflow-validation-improvement] */}
            {hasWorkflowValidationImprovement && (
              <Stack spacing={2}>
                <Text color="gray.500" fontWeight="300">
                  {t('validationStep:dynamicApprover')}
                </Text>
                <CheckboxField<FormValues>
                  name="areAccountManagersIncluded"
                  label={t('validationStep:projectManager')}
                />
              </Stack>
            )}
          </Stack>
          <Divider mt={6} />
        </Form>
      )}

      <Modal.Close />

      {supplierInvoicesToPay.length === 0 && (
        <>
          <Modal.SecondaryButton onClick={onClose}>{t('global:words.c.cancel')}</Modal.SecondaryButton>
          <Modal.PrimaryButton onClick={onSubmit} isDisabled={!isValid || isLoading}>
            {t('global:words.c.save')}
          </Modal.PrimaryButton>
        </>
      )}
    </Modal>
  );
};
