import { QuotationCallout as Callout, SellSheetAmountCard, SimpleInfoCircleIcon, useCurrency } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { CheckType, ComputeBigNull } from '@org/quotation-lib';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { isAmountExVATEditable } from '../../utils/quote-utils';
import { useQuoteUpdateAmountExVat } from '../../hooks/useQuoteUpdateAmountExVat';
import { useQuote } from '../../hooks/useQuote';

import { useStore } from 'store/store';
import { updateQuoteAmountIsUnreachable } from 'features/quotation/quote-common/store/quoteUpdateZustand';

export const QuoteSellSheetGlobalAmountCard: FC = () => {
  const { t } = useTranslation(['quote']);
  const isEditable = useStore((store) => store.quoteEditable);
  const isAmountUnreachable = useStore((state) => state.quoteAmountIsUnreachable);
  const quoteUpdateAmountExVat = useQuoteUpdateAmountExVat();
  const { formatAsStringAmount } = useCurrency();

  const { quote } = useQuote();
  const quoteTree = quote.getTree();

  const hasHiddenCostItems = quoteTree.getQuoteHasHiddenCostItems();

  const hiddenCostLot = quoteTree.getQuoteLotOrThrow(quoteTree.getHiddenCostNodeId());
  const rootLot = quoteTree.getRootNode().getContent();

  const isAmountEditable = useMemo(
    () => isEditable && isAmountExVATEditable(quote.getSalesDiscount(), quote.getAmountBeforeDiscountsExVat()),
    [quote, isEditable],
  );

  const totalDisbursement = useMemo(
    () => ComputeBigNull.plus(rootLot.getFlatCostAmount(), hiddenCostLot.getHiddenCostAmount()),
    [hiddenCostLot, rootLot],
  );

  const quoteProfit = useMemo(
    () =>
      ComputeBigNull.minus(
        quote.getAmountExVat(),
        ComputeBigNull.plus(rootLot.getFlatCostAmount(), hiddenCostLot.getHiddenCostAmount()),
      ),
    [hiddenCostLot, quote, rootLot],
  );

  const overheadCosts = useMemo(
    () =>
      ComputeBigNull.minus(
        ComputeBigNull.mul(totalDisbursement, quote.getMargin().getOverheadCosts()),
        totalDisbursement,
      ),
    [quote, totalDisbursement],
  );

  const profitMargin = useMemo(
    () => ComputeBigNull.minus(quote.getAmountExVat(), ComputeBigNull.plus(totalDisbursement, overheadCosts)),
    [overheadCosts, quote, totalDisbursement],
  );

  const hiddenCostAmount = useMemo(() => hiddenCostLot.getHiddenCostAmount(), [hiddenCostLot]);

  const updateAmount = useCallback(
    (newAmount: string) => {
      quoteUpdateAmountExVat(newAmount);
      setTimeout(() => updateQuoteAmountIsUnreachable(false), 5000);
    },
    [quoteUpdateAmountExVat],
  );

  const amountUnreachableLabel = t('quote:drawer.sale.amountUnreachable', {
    amount: formatAsStringAmount(quote.getAmountExVat()?.toString() ?? '0'),
  });

  // TODO: ADD CONSTRAINS TO THE AMOUNT

  return (
    <>
      <SellSheetAmountCard
        isEditable={isAmountEditable}
        disbursement={CheckType.stringOrNull(rootLot.getFlatCostAmount()) ?? '0'}
        hiddenCostAmount={hasHiddenCostItems ? CheckType.stringOrNull(hiddenCostAmount) : '0'}
        overheadCosts={CheckType.stringOrNull(overheadCosts) ?? '0'}
        profitMargin={CheckType.stringOrNull(profitMargin) ?? '0'}
        marginBenefit={CheckType.stringOrNull(quoteProfit) ?? '0'}
        totalAmountExVAT={CheckType.stringOrNull(quote.getAmountExVat()) ?? '0'}
        onTotalAmountChange={updateAmount}
      />

      {isAmountUnreachable && (
        <Callout color="blue" icon={<SimpleInfoCircleIcon />}>
          <Text>{amountUnreachableLabel}</Text>
        </Callout>
      )}
    </>
  );
};
