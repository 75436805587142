import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, List, Modal, Title } from '@graneet/lib-ui';
import { Box, Flex, Grid, GridItem, Img, Link, Text } from '@chakra-ui/react';

export const UpsellModal: FC<{
  title: string;
  description: string;
  minimumPlan: 'advanced' | 'expert' | 'premium';
  advantages: string[];
  image: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ title, description, minimumPlan, advantages, image, isOpen, onClose }) => {
  const { t } = useTranslation(['global']);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <Grid templateColumns="repeat(2, 1fr)">
        <GridItem display="block" ml="auto">
          <Img src={image} />
        </GridItem>

        <GridItem ml={6} mt={3}>
          <Flex direction="column" gap={3}>
            <Box>
              <Badge colorScheme="warm_gray">
                <Flex gap={2}>
                  <i className="ri-lock-fill" />
                  {t('global:upsellCard.accessibleTo', {
                    plan: t(`global:planType.${minimumPlan}`),
                  })}
                </Flex>
              </Badge>
            </Box>

            <Title>{title}</Title>

            <Text textColor="gray.600">{description}</Text>

            <List>
              {advantages.map((avantage) => (
                <List.Item key={avantage} label={avantage} icon={<i className="ri-checkbox-circle-fill" />} />
              ))}
            </List>
          </Flex>
        </GridItem>
      </Grid>

      <Modal.CloseButton />

      <Modal.PrimaryButton
        as={Link}
        // @ts-ignore
        target="_blank"
        href={t('global:compareOffersLink')}
      >
        {t('global:upsellCard.seePlans')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
