import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import type { IContractUpdateDTO } from '@graneet/business-logic';

import { proxyHelperTanStack } from 'features/api/services/proxyHelper';
import { ExternalApi } from 'config/externalApi';
import { invalidateStatementRelatedQueries } from 'features/common/services/invalidate-statement-related-queries.util';

const contractApi = () => ExternalApi.getExternalApi().getContractApi();

export function useContractDelete() {
  const { t } = useTranslation(['global', 'contracts']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => proxyHelperTanStack(() => contractApi().deleteContract(id)),
    onSuccess: async () => {
      toast.success(t('contracts:deleteModal.success'));
      await invalidateStatementRelatedQueries(queryClient);
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useContractUpdate() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; quoteUUID: string; contractUpdateDTO: IContractUpdateDTO }) =>
      proxyHelperTanStack(() =>
        contractApi().updateContract(params.id, params.quoteUUID, {
          ...params.contractUpdateDTO,
          isAutomaticNumberingActivated: params.contractUpdateDTO.isAutomaticNumberingActivated ?? true,
          customDiscounts: params.contractUpdateDTO.customDiscounts as any,
        }),
      ),
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}
