import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { BlueCircledPlus, Button, Modal, BillSituation } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import type { IOrderResponseDTO, ISupplierInvoiceListingResponseDTO } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { SupplierInvoicePickerDetail } from './SupplierInvoicePickerDetail';

import { useOrderUpdate } from 'features/order/services/order.api';
import { usePermissions } from 'features/role/hooks/usePermissions';

interface PromptProps {
  onPick(): void;
  onCreate(): void;
}

const Prompt: FC<PromptProps> = ({ onPick, onCreate }) => {
  const { t } = useTranslation(['orders']);

  return (
    <>
      <Text>{t('orders:associate.pickOrCreate.body.prompt')}</Text>

      <Flex flexDir="row" paddingBottom="1rem" paddingTop="1rem">
        <Flex flexDir="column" flex={1} alignItems="center" padding="1rem" onClick={onPick}>
          <BillSituation height="5rem" margin="1rem" width="auto" padding=".5rem" />
          <Button>{t('orders:associate.pickOrCreate.pick')}</Button>
        </Flex>

        <Flex
          flexDir="column"
          flex={1}
          alignItems="center"
          padding="1rem"
          bg="gray.100"
          borderRadius=".3rem"
          onClick={onCreate}
        >
          <BlueCircledPlus height="5rem" margin="1rem" width="auto" />
          <Button>{t('orders:associate.pickOrCreate.create')}</Button>
        </Flex>
      </Flex>
    </>
  );
};
interface PickOrCreateSupplierInvoiceModalProps {
  isOpen: boolean;
  onClose(): void;
  order: IOrderResponseDTO;
  withSkipButton: boolean;
}

export const PickOrCreateSupplierInvoiceModal: FC<PickOrCreateSupplierInvoiceModalProps> = ({
  isOpen,
  onClose,
  order,
  withSkipButton,
}) => {
  const { t } = useTranslation(['orders', 'global']);

  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();

  const orderUpdateMutation = useOrderUpdate();

  const [step, setStep] = useState('prompt');

  const [selectedRows, setSelectedRows] = useState<ISupplierInvoiceListingResponseDTO[]>([]);
  const onRowSelected = useCallback((rows: ISupplierInvoiceListingResponseDTO[]) => {
    setSelectedRows(rows);
  }, []);

  const canCreateSupplierInvoice = usePermissions([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  useEffect(() => {
    if (!isOpen) {
      setStep(canCreateSupplierInvoice ? 'prompt' : 'pick');
      setSelectedRows([]);
    }
  }, [canCreateSupplierInvoice, isOpen]);

  const onSubmit = useCallback(async () => {
    await orderUpdateMutation.mutateAsync({
      id: order.id,
      dto: {
        associatedSupplierInvoices: JSON.stringify(selectedRows.map((si) => si.id)),
      },
    });
    onClose();
  }, [onClose, order.id, orderUpdateMutation, selectedRows]);

  const onPick = useCallback(() => setStep('pick'), []);

  const onCreate = useCallback(() => {
    if (projectId) {
      history.push(`/projects/${projectId}/purchases/supplier-invoices/create`, { order });
    } else {
      history.push(`/purchases/supplier-invoices/create`, { order });
    }
  }, [history, order, projectId]);

  const onGoBack = useCallback(() => {
    if (canCreateSupplierInvoice) {
      setStep('prompt');
    } else {
      onClose();
    }
  }, [canCreateSupplierInvoice, onClose]);

  const modalProps = useMemo(
    () =>
      step === 'pick'
        ? {
            size: '6xl',
            fullHeight: true,
          }
        : {
            size: '3xl',
          },
    [step],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      {...modalProps}
      title={
        withSkipButton ? (
          <Flex justifyContent="space-between">
            <Text>{t('orders:associate.pickOrCreate.title')}</Text>
            <Modal.SecondaryButton onClick={onClose}>
              {t('orders:associate.pickOrCreate.body.skip')}
            </Modal.SecondaryButton>
          </Flex>
        ) : (
          t('orders:associate.pickOrCreate.title')
        )
      }
      noFooter={step === 'prompt'}
    >
      {step === 'prompt' ? (
        <Prompt onPick={onPick} onCreate={onCreate} />
      ) : (
        <SupplierInvoicePickerDetail order={order} onRowSelected={onRowSelected} />
      )}

      {step !== 'prompt' && (
        <>
          <Modal.SecondaryButton onClick={onGoBack}>{t('global:words.c.back')}</Modal.SecondaryButton>

          <Modal.PrimaryButton isDisabled={selectedRows.length === 0} onClick={onSubmit}>
            {t('orders:associate.associateCta', { count: selectedRows.length })}
          </Modal.PrimaryButton>
        </>
      )}
    </Modal>
  );
};
