import { useCallback, type FC } from 'react';
import type { IExternalValidation } from '@graneet/business-logic';
import { formatDateOrEmpty } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Button, Callout } from '@graneet/lib-ui';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { useResetExternalProgressStatement } from '../../services/external-progress-statement.api';

export const ExternalProgressStatementConfirmationCallout: FC<{ externalValidation: IExternalValidation | null }> = ({
  externalValidation,
}) => {
  const { t } = useTranslation(['global', 'progressStatement']);
  const { externalId } = useParams<{ externalId: string }>();

  // Services
  const resetExternalProgressStatement = useResetExternalProgressStatement();

  // Callbacks
  const handleCancel = useCallback(() => {
    resetExternalProgressStatement.mutate(externalId);
  }, [resetExternalProgressStatement, externalId]);

  if (!externalValidation) {
    return null;
  }

  return (
    <Callout
      icon={externalValidation.isApproved ? <CheckIcon /> : <CloseIcon />}
      colorScheme={externalValidation.isApproved ? 'green' : 'red'}
      fontSize="md"
      alignItems="center"
    >
      <Flex gap={4}>
        <Text flex={1} fontSize="md">
          {t(
            externalValidation.isApproved
              ? 'progressStatement:external.callout.approved'
              : 'progressStatement:external.callout.rejected',
            {
              name: externalValidation.name,
              date: formatDateOrEmpty(externalValidation.createdAt),
            },
          )}
        </Text>
        <Button onClick={handleCancel} variant="ghost" m={-1}>
          {t('global:words.c.cancel')}
        </Button>
      </Flex>
    </Callout>
  );
};
