import { useCallback } from 'react';
import type { Quote, QuoteNodeObject } from '@org/quotation-lib';
import { QuoteBasicItemTransformUseCase, QuoteOptionalItemTransformUseCase } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';

import { useQuotationProxyApis } from '../useQuoteProxyApis';
import { StatusEnum, quoteClientRequestsSetToStore } from '../../store/quoteClientRequestsSetToStore';
import { useQuoteError } from '../useQuoteError';

import { useQuoteSetToStore } from 'features/quotation/quote-common/hooks/useQuoteSetToStore';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';

export const useQuoteBulkTransformToOptional = () => {
  const { quoteNodeBulkActionsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteError = useQuoteError();

  return useCallback(
    (rows: QuoteNodeObject[]): ICommand => ({
      execute(quote: Quote) {
        try {
          const items: string[] = !this.oldValue
            ? rows.map((row) => row.id)
            : this.oldValue.map((value: { oldType: string; nodeId: string }) => value.nodeId);
          this.oldValue = [];
          items.forEach((nodeId) => {
            const node = quote.getTree().getNodeOrThrow(nodeId);
            if (node.getContent().getType() === 'QuoteLot') {
              const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
              quoteLot.transformQuoteBasicItemsToQuoteOptionalItems();
              this.oldValue.push({
                oldType: 'QuoteLot',
                nodeId,
              });
            } else if (node.getContent().getType() === 'QuoteBasicItem') {
              const quoteBasicItem = quote.getTree().getQuoteBasicItemOrThrow(nodeId);
              QuoteBasicItemTransformUseCase.transformToQuoteOptionalItem(quoteBasicItem);
              this.oldValue.push({
                oldType: 'QuoteBasicItem',
                nodeId,
              });
            }
          });
          quoteSetToStore(quote);
          if (rows.length > 0) {
            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteNodeBulkActionsProxyApi
              .handleBulkTransformQuoteNodeToOptionalItem({
                quoteId: quote.getId(),
                timestamp,
                clientRequestId,
                nodesId: rows.map((row) => row.id),
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
          }
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        if (this.oldValue.length > 0) {
          this.oldValue.forEach((item: { oldType: string; nodeId: string }) => {
            if (item.oldType === 'QuoteLot') {
              const quoteLot = quote.getTree().getQuoteLotOrThrow(item.nodeId);
              quoteLot.transformQuoteOptionalItemsToQuoteBasicItems();
            }
            if (item.oldType === 'QuoteBasicItem') {
              const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(item.nodeId);
              QuoteOptionalItemTransformUseCase.transformToQuoteBasicItem(quoteOptionalItem);
            }
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteNodeBulkActionsProxyApi
            .handleBulkTransformQuoteNodeToBasic({
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              nodesId: this.oldValue.map((item: { oldType: string; nodeId: string }) => item.nodeId),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));
        }
      },
    }),
    [quoteError, quoteNodeBulkActionsProxyApi, quoteSetToStore],
  );
};
