import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { checkPasswordStrength } from '@graneet/business-logic';

const VALIDATE_PASSWORD = (value: FieldValue) => checkPasswordStrength(value || '').isPasswordStrong;

export const IsValidPassword = () => {
  const { t } = useTranslation(['global']);

  return <Rule message={t('global:form.errors.password')} validationFn={VALIDATE_PASSWORD} />;
};
