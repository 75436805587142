import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Text } from '@chakra-ui/react';
import type { PDF_TEMPLATES_TYPES } from '@graneet/business-logic';
import { Button, Card, List, SimpleEditIcon, SimpleCheckCircleIcon } from '@graneet/lib-ui';

type PdfSettingsCardProps = {
  templateType: PDF_TEMPLATES_TYPES;
};

export const PdfSettingsCard: FC<PdfSettingsCardProps> = ({ templateType }) => {
  const { t } = useTranslation(['global', 'pdfSettings']);
  const history = useHistory();

  const goToEditPdfSettings = useCallback(() => {
    history.push(`/pdf-settings/${templateType}/edit`);
  }, [history, templateType]);

  const pdfDocumentTypes = t(`pdfSettings:${templateType}.documentType`, {
    returnObjects: true,
  });

  return (
    <Card
      title={t(`pdfSettings:${templateType}.title`)}
      pt={2}
      topRightContent={
        <Button variant="outline" onClick={goToEditPdfSettings} leftIcon={<SimpleEditIcon />}>
          {t('global:words.c.update')}
        </Button>
      }
    >
      <Text color="black">{t(`pdfSettings:${templateType}.description`)}</Text>
      <List ml={2} color="gray.700" fontSize="sm">
        {pdfDocumentTypes.map((documentType) => (
          <List.Item key={documentType} icon={<SimpleCheckCircleIcon />} label={documentType} />
        ))}
      </List>
    </Card>
  );
};
