import { useTranslation } from 'react-i18next';
import type { KeysMatching, RichTextInputPropsValue } from '@graneet/lib-ui';
import { Card, RichTextField, RICH_TEXT_INLINE_TOOLBAR_PRESET } from '@graneet/lib-ui';
import { FEATURE_FLAGS, PERMISSION } from '@graneet/business-logic';
import type { FieldValues } from 'graneet-form';
import { HiddenField, useFormContext, useHiddenField, useOnBlurValues } from 'graneet-form';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface SupplierInvoiceCommentCardProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, RichTextInputPropsValue> = KeysMatching<T, RichTextInputPropsValue>,
> {
  name: K;
}

export const SupplierInvoiceCommentCard = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, RichTextInputPropsValue> = KeysMatching<T, RichTextInputPropsValue>,
>({
  name,
}: SupplierInvoiceCommentCardProps<T, K>): JSX.Element => {
  const { t } = useTranslation(['supplierInvoices']);

  const hasCreateSupplierInvoicePermission = usePermissions([PERMISSION.CREATE_SUPPLIER_INVOICE]);
  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);

  const form = useFormContext<T>();
  const { [name]: comment } = useOnBlurValues(form, [name]);
  const commentHiddenField = useHiddenField(form, name);
  if (!comment && !hasCreateSupplierInvoicePermission) {
    return <HiddenField<T, K> {...commentHiddenField} />;
  }

  return (
    // @[ff: workflow-validation]
    <Card
      title={
        hasWorkflowValidationFeatureFlag
          ? t('supplierInvoices:cards.noteCard.title')
          : t('supplierInvoices:cards.commentCard.title')
      }
    >
      <RichTextField<T, K>
        name={name}
        navbarType="inline"
        // @[ff: workflow-validation]
        placeholder={
          hasWorkflowValidationFeatureFlag
            ? t('supplierInvoices:cards.noteCard.placeholder')
            : t('supplierInvoices:cards.commentCard.placeholder')
        }
        isDisabled={!hasCreateSupplierInvoicePermission}
        configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
      />
    </Card>
  );
};
