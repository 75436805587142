import { joinWithHyphens } from '@graneet/lib-ui';
import type {
  IContractResponseDTO,
  IItemResponseDTO,
  ILotResponseDTO,
  IProgressStatement,
  IProgressStatementDiscountLine,
  IProgressStatementDiscountLineCreationDTO,
  IProgressStatementLine,
  IProgressStatementLineCreationDTO,
  IProject,
  IProgressStatementCustomDiscountLine,
  IContractCustomDiscount,
  IProgressStatementCustomDiscountLineCreationDTO,
  ProgressStatementDiscountModes,
  ISubProjectWithInformation,
} from '@graneet/business-logic';
import {
  getProgressStatementTransitionErrors,
  PROGRESS_STATEMENT_STATUSES,
  STATEMENT_TRANSITION_ERRORS,
  PROGRESS_STATEMENT_TRANSITION_ERRORS,
  PROGRESS_STATEMENT_DISCOUNT_MODES,
  PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES,
  CUMULATIVE_INPUT_TYPE,
} from '@graneet/business-logic';
import type { TFunction } from 'i18next';
import * as Sentry from '@sentry/react';

import type { ILotTree, InitialProgressStatementTree } from '../hooks/useProgressStatementTree';
import type { ProgressStatementEditItemForm } from '../forms/progress-statement-edit-item-form';
import {
  getInformationsCustomDiscountCumulativeField,
  getInformationsDiscountCumulativeField,
  getInformationsFromItemCumulativeField,
  getItemCumulativeFieldName,
  getKeyForCumulativeInput,
  isCustomDiscountAmountCumulativeField,
  isCustomDiscountCumulativeField,
  isCustomDiscountModeField,
  isDiscountAmountCumulativeField,
  isDiscountCumulativeField,
  isDiscountModeField,
  isItemAmountCumulativeField,
  isItemCumulativeField,
} from '../forms/progress-statement-edit-item-form';

import type { ContractId, LotId } from './progress-statement-tree.util';
import { generateContractId, generateLotId } from './progress-statement-tree.util';

export const formatProgressStatementWizardTitle = (project?: IProject, progressStatement?: IProgressStatement | null) =>
  joinWithHyphens(progressStatement?.invoiceNumber || project?.refCode, project?.name);

const ROOT_NODE_ID = generateLotId(0);

export const mapContractToInitialTree = (
  contracts: IContractResponseDTO[],
  initialProgressStatementDataValues: InitialProgressStatementDataValues,
): InitialProgressStatementTree => {
  const contractIds = contracts.map((contract) => generateContractId(contract.id));
  const initialTree: InitialProgressStatementTree = {
    nodes: {
      [ROOT_NODE_ID]: {
        id: ROOT_NODE_ID,
        parentNodeId: null,
      } as ILotTree,
    },
    leaves: {},
    rootNodeId: ROOT_NODE_ID,
    relations: {
      [ROOT_NODE_ID]: {
        nodes: contractIds,
        leaves: [],
      },
    },
  };

  contracts.forEach((contract) => {
    const { container } = contract;

    const contractId = generateContractId(contract.id);

    // Initialize node
    initialTree.nodes[contractId] = {
      ...contract,
      id: contractId,
      parentNodeId: ROOT_NODE_ID,
    };

    // Initialize relations
    const lotIds = container.lots.map((lot) => generateLotId(lot.id));
    const itemIds = container.items.map((item) => item.id);
    initialTree.relations[contractId] = {
      nodes: lotIds,
      leaves: itemIds,
    };

    // Initialize Items
    container.items.forEach((item) => {
      initialTree.leaves[item.id] = {
        ...item,
        parentNodeId: contractId,
        cumulativeAmountExVAT: initialProgressStatementDataValues.itemValues[item.id].cumulativeAmountExVAT,
        progressPercentage: initialProgressStatementDataValues.itemValues[item.id].cumulativeProgressPercentage,
      };
    }, {});

    // Recursively, add sub lots and subItems;
    const addLotRecursively = (lot: ILotResponseDTO, parentNodeId: ContractId | LotId) => {
      const lotId = generateLotId(lot.id);

      // Initialize node
      initialTree.nodes[lotId] = {
        ...lot,
        id: lotId,
        parentNodeId,
      };

      // Initialize it own relations
      const subLotIds = lot.subLots.map((subLot) => generateLotId(subLot.id));
      const subItemIds = lot.items.map((item) => item.id);
      initialTree.relations[lotId] = {
        nodes: subLotIds,
        leaves: subItemIds,
      };

      // Initialize Items
      lot.items.forEach((item) => {
        initialTree.leaves[item.id] = {
          ...item,
          parentNodeId: lotId,
          cumulativeAmountExVAT: initialProgressStatementDataValues.itemValues[item.id].cumulativeAmountExVAT,
          progressPercentage: initialProgressStatementDataValues.itemValues[item.id].cumulativeProgressPercentage,
        };
      }, {});

      // Recurse on sub lots
      lot.subLots.forEach((subLot) => {
        addLotRecursively(subLot, lotId);
      });
    };

    container.lots.forEach((subLot) => {
      addLotRecursively(subLot, contractId);
    });
  });

  return initialTree;
};

export type IProgressStatementLineValues = Pick<
  IProgressStatementLine,
  'cumulativeProgressPercentage' | 'cumulativeAmountExVAT' | 'cumulativeProgressQuantity'
>;

export const mapItemLinesToDictionaryIndexedPerItemId = (lines?: IProgressStatementLine[]) =>
  (lines || []).reduce<Record<number, IProgressStatementLineValues>>(
    (acc, line) => ({
      ...acc,
      [line.item.id]: {
        cumulativeProgressPercentage: line.cumulativeProgressPercentage,
        cumulativeAmountExVAT: line.cumulativeAmountExVAT,
        cumulativeProgressQuantity: line.cumulativeProgressQuantity,
      },
    }),
    {},
  );

const getLotItem = (lot: ILotResponseDTO): IItemResponseDTO[] => [
  ...lot.items,
  ...lot.subLots.reduce<IItemResponseDTO[]>((acc, subLot) => [...acc, ...getLotItem(subLot)], []),
];

export const getContractsItems = (contracts: IContractResponseDTO[]): IItemResponseDTO[] =>
  (contracts || []).reduce<IItemResponseDTO[]>(
    (acc, contract) => [
      ...acc,
      ...contract.container.items,
      ...contract.container.lots.reduce<IItemResponseDTO[]>((lotAcc, lot) => [...lotAcc, ...getLotItem(lot)], []),
    ],
    [],
  );

export const getAllContractsCustomDiscounts = (contracts: IContractResponseDTO[]): IContractCustomDiscount[] =>
  (contracts || []).reduce<IContractCustomDiscount[]>(
    (acc, contract) => [...acc, ...(contract.customDiscounts ?? [])],
    [],
  );

export const mapDiscountLinesToDictionaryIndexedPerContractId = (discountLines?: IProgressStatementDiscountLine[]) =>
  (discountLines || []).reduce<Record<number, IProgressStatementDiscountLine>>(
    (acc, line) => ({
      ...acc,
      [line.contract.id]: line,
    }),
    {},
  );

export const mapCustomDiscountLinesToDictionaryIndexedPerContractCustomDiscountId = (
  customDiscountLines?: IProgressStatementCustomDiscountLine[],
) =>
  (customDiscountLines || []).reduce<Record<string, IProgressStatementCustomDiscountLine>>((acc, line) => {
    if (!line.contractCustomDiscount) return acc;
    return { ...acc, [line.contractCustomDiscount.id]: line };
  }, {});

export const getProgressStatementsLinesAndContractDiscountDTO = (
  progressStatementEditItemForm: ProgressStatementEditItemForm,
  mapAmountToNumber: (value: string | number) => number,
): {
  progressStatementsLinesDTO: IProgressStatementLineCreationDTO[];
  contractDiscountLinesDTO: IProgressStatementDiscountLineCreationDTO[];
  contractCustomDiscountLinesDTO: IProgressStatementCustomDiscountLineCreationDTO[];
} => {
  const progressStatementsLinesDTO: Record<number, IProgressStatementLineCreationDTO> = {};
  const contractDiscountLinesDTO: Record<number, IProgressStatementDiscountLineCreationDTO> = {};
  const contractCustomDiscountLinesDTO: Record<string, IProgressStatementCustomDiscountLineCreationDTO> = {};

  Object.entries(progressStatementEditItemForm).forEach(([fieldKey, fieldValue]) => {
    // Discount fields
    if (isDiscountModeField(fieldKey)) {
      const { contractId } = getInformationsDiscountCumulativeField(fieldKey);

      contractDiscountLinesDTO[contractId] = {
        ...(contractDiscountLinesDTO[contractId] || {}),
        contractId,
        mode: fieldValue,
      };
      return;
    }
    if (isDiscountCumulativeField(fieldKey)) {
      const { contractId, cumulativeType } = getInformationsDiscountCumulativeField(fieldKey);

      contractDiscountLinesDTO[contractId] = {
        ...(contractDiscountLinesDTO[contractId] || {}),
        contractId,
        // eslint-disable-next-line no-nested-ternary
        [getKeyForCumulativeInput(cumulativeType)]: fieldValue
          ? isDiscountAmountCumulativeField(fieldKey)
            ? mapAmountToNumber(fieldValue)
            : parseFloat(fieldValue)
          : 0,
      };
      return;
    }

    // Custom Discount fields

    if (isCustomDiscountModeField(fieldKey)) {
      const { customDiscountId } = getInformationsCustomDiscountCumulativeField(fieldKey);

      contractCustomDiscountLinesDTO[customDiscountId] = {
        ...(contractCustomDiscountLinesDTO[customDiscountId] || {}),
        customDiscountId,
        mode: fieldValue,
      };
      return;
    }
    if (isCustomDiscountCumulativeField(fieldKey)) {
      const { customDiscountId, cumulativeType } = getInformationsCustomDiscountCumulativeField(fieldKey);

      contractCustomDiscountLinesDTO[customDiscountId] = {
        ...(contractCustomDiscountLinesDTO[customDiscountId] || {}),
        customDiscountId,
        // eslint-disable-next-line no-nested-ternary
        [getKeyForCumulativeInput(cumulativeType)]: fieldValue
          ? isCustomDiscountAmountCumulativeField(fieldKey)
            ? mapAmountToNumber(fieldValue)
            : parseFloat(fieldValue)
          : 0,
      };
      return;
    }

    // Item fields

    if (isItemCumulativeField(fieldKey)) {
      const { itemId, cumulativeType } = getInformationsFromItemCumulativeField(fieldKey);

      progressStatementsLinesDTO[itemId] = {
        ...(progressStatementsLinesDTO[itemId] || {}),
        itemId,
        // eslint-disable-next-line no-nested-ternary
        [getKeyForCumulativeInput(cumulativeType)]: fieldValue
          ? isItemAmountCumulativeField(fieldKey)
            ? mapAmountToNumber(fieldValue)
            : parseFloat(fieldValue)
          : 0,
      };
    }
  }, {});

  return {
    progressStatementsLinesDTO: Object.values(progressStatementsLinesDTO),
    contractDiscountLinesDTO: Object.values(contractDiscountLinesDTO),
    contractCustomDiscountLinesDTO: Object.values(contractCustomDiscountLinesDTO),
  };
};

type ItemValues = Record<
  number,
  { cumulativeProgressPercentage: number; cumulativeAmountExVAT: number; cumulativeProgressQuantity: number }
>;
type DiscountValues = Record<
  number,
  {
    discountMode: ProgressStatementDiscountModes;
    discountCumulativePercentage: number;
    discountCumulativeAmount: number;
  }
>;
type CustomDiscountValues = Record<
  string,
  {
    customDiscountMode: PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES;
    customDiscountCumulativePercentage: number;
    customDiscountCumulativeAmount: number;
  }
>;
export type InitialProgressStatementDataValues = {
  itemValues: ItemValues;
  discountValues: DiscountValues;
  customDiscountValues: CustomDiscountValues;
};

export const initialProgressStatementDataValues = (
  contracts: IContractResponseDTO[],
  currentProgressStatement: IProgressStatement | null,
  previousProgressStatement: IProgressStatement | undefined,
  previousItemsCumulativeValues: Record<number, IProgressStatementLineValues>,
  formValues: ProgressStatementEditItemForm | undefined,
  mapAmountToNumber: (value: string | number) => number,
): InitialProgressStatementDataValues => {
  const getItemValues = () => {
    const items = getContractsItems(contracts);

    const currentItemsCumulativeValuesIndexed = mapItemLinesToDictionaryIndexedPerItemId(
      currentProgressStatement?.lines,
    );

    return items.reduce<ItemValues>((acc, item) => {
      const currentItemCumulativeValues = currentItemsCumulativeValuesIndexed[item.id];
      const previousItemCumulativeValues = previousItemsCumulativeValues[item.id];

      acc[item.id] = {
        cumulativeProgressPercentage:
          formValues?.[getItemCumulativeFieldName(item.id, CUMULATIVE_INPUT_TYPE.PERCENTAGE)] ??
          currentItemCumulativeValues?.cumulativeProgressPercentage ??
          previousItemCumulativeValues?.cumulativeProgressPercentage ??
          0,

        cumulativeAmountExVAT: formValues?.[getItemCumulativeFieldName(item.id, CUMULATIVE_INPUT_TYPE.AMOUNT)]
          ? mapAmountToNumber(formValues?.[getItemCumulativeFieldName(item.id, CUMULATIVE_INPUT_TYPE.AMOUNT)])
          : (currentItemCumulativeValues?.cumulativeAmountExVAT ??
            previousItemCumulativeValues?.cumulativeAmountExVAT ??
            0),

        cumulativeProgressQuantity:
          formValues?.[getItemCumulativeFieldName(item.id, CUMULATIVE_INPUT_TYPE.QUANTITY)] ??
          currentItemCumulativeValues?.cumulativeProgressQuantity ??
          previousItemCumulativeValues?.cumulativeProgressQuantity ??
          0,
      };

      return acc;
    }, {});
  };

  const getDiscountValues = () => {
    const currentDiscountLinesPercentagesIndexed = mapDiscountLinesToDictionaryIndexedPerContractId(
      currentProgressStatement?.discountLines,
    );
    const previousDiscountLinesPercentagesIndexed = mapDiscountLinesToDictionaryIndexedPerContractId(
      previousProgressStatement?.discountLines,
    );

    return contracts
      .filter((contract) => !!contract.discount)
      .reduce<DiscountValues>((acc, contract) => {
        const currentDiscountLineCumulativeValues = currentDiscountLinesPercentagesIndexed[contract.id] || {};
        const previousDiscountLineCumulativeValues = previousDiscountLinesPercentagesIndexed[contract.id] || {};

        acc[contract.id] = {
          discountMode:
            currentDiscountLineCumulativeValues.mode ??
            previousDiscountLineCumulativeValues.mode ??
            PROGRESS_STATEMENT_DISCOUNT_MODES.AUTO,
          discountCumulativePercentage:
            currentDiscountLineCumulativeValues.cumulativeProgressPercentage ??
            previousDiscountLineCumulativeValues.cumulativeProgressPercentage ??
            0,
          discountCumulativeAmount:
            currentDiscountLineCumulativeValues.cumulativeAmountExVAT ??
            previousDiscountLineCumulativeValues.cumulativeAmountExVAT ??
            0,
        };
        return acc;
      }, {});
  };

  const getCustomDiscountValues = () => {
    const customDiscounts = getAllContractsCustomDiscounts(contracts);

    const currentCustomDiscountLinesPercentagesIndexed =
      mapCustomDiscountLinesToDictionaryIndexedPerContractCustomDiscountId(
        currentProgressStatement?.customDiscountLines,
      );
    const previousCustomDiscountLinesPercentagesIndexed =
      mapCustomDiscountLinesToDictionaryIndexedPerContractCustomDiscountId(
        previousProgressStatement?.customDiscountLines,
      );

    return customDiscounts.reduce<CustomDiscountValues>((acc, customDiscount) => {
      const currentCustomDiscountLineCumulativeValues =
        currentCustomDiscountLinesPercentagesIndexed[customDiscount.id] || {};
      const previousCustomDiscountLineCumulativeValues =
        previousCustomDiscountLinesPercentagesIndexed[customDiscount.id] || {};

      acc[customDiscount.id] = {
        customDiscountMode:
          currentCustomDiscountLineCumulativeValues.mode ??
          previousCustomDiscountLineCumulativeValues.mode ??
          PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES.AUTO,
        customDiscountCumulativePercentage:
          currentCustomDiscountLineCumulativeValues.cumulativeProgressPercentage ??
          previousCustomDiscountLineCumulativeValues.cumulativeProgressPercentage ??
          0,
        customDiscountCumulativeAmount:
          currentCustomDiscountLineCumulativeValues.cumulativeAmountExVAT ??
          previousCustomDiscountLineCumulativeValues.cumulativeAmountExVAT ??
          0,
      };

      return acc;
    }, {});
  };

  return {
    itemValues: getItemValues(),
    discountValues: getDiscountValues(),
    customDiscountValues: getCustomDiscountValues(),
  };
};

export const getProgressStatementCreationState = (
  subProjectWithInformation: ISubProjectWithInformation,
  t: TFunction<['progressStatement', 'project']>,
  hasMultipleSubProjects: boolean,
): { canCreate: boolean; tooltip: string | undefined } => {
  const errors = getProgressStatementTransitionErrors(PROGRESS_STATEMENT_STATUSES.DRAFT, undefined, {
    project: subProjectWithInformation.project,
    subProject: subProjectWithInformation,
    downPayment: subProjectWithInformation.downPayment,
    previousProgressStatement: subProjectWithInformation.lastProgressStatements?.[0],
    beforePreviousProgressStatement: subProjectWithInformation.lastProgressStatements?.[1],
  });

  /**
   * You cannot create a new progress statement when there is more than one progress statement on DRAFT OR VALIDATED
   */
  if (errors.includes(STATEMENT_TRANSITION_ERRORS.INVALID_SECOND_PREVIOUS_STATUS)) {
    return {
      canCreate: false,
      tooltip: t('progressStatement:tooltips.cannotCreateBecauseOtherProgressStatement', {
        progressStatementName: subProjectWithInformation.lastProgressStatements?.[1].name,
      }),
    };
  }

  /**
   * You cannot have more than one progress statement on direct billing
   */
  if (errors.includes(PROGRESS_STATEMENT_TRANSITION_ERRORS.WRONG_BILLING_TYPE)) {
    return {
      canCreate: false,
      tooltip: hasMultipleSubProjects
        ? t('progressStatement:tooltips.cannotCreateBecauseDirectBillingWithSubProject')
        : t('progressStatement:tooltips.cannotCreateBecauseDirectBilling'),
    };
  }

  /**
   * You cannot create a progress statement when there is a non-finalized down payment
   */
  if (errors.includes(PROGRESS_STATEMENT_TRANSITION_ERRORS.UNCOMPLETED_DOWN_PAYMENT)) {
    return {
      canCreate: false,
      tooltip: hasMultipleSubProjects
        ? t('progressStatement:tooltips.mustFinalizeDownPaymentWithSubProject')
        : t('progressStatement:tooltips.mustFinalizeDownPayment'),
    };
  }

  /**
   * You cannot create a new progress statement when the last progress statement is 100% billed
   */
  if (
    subProjectWithInformation.lastProgressStatements?.[0]?.cumulativeProgressAmountExVAT ===
    subProjectWithInformation.amountExVAT
  ) {
    return {
      canCreate: false,
      tooltip: hasMultipleSubProjects
        ? t('progressStatement:tooltips.cannotCreateBecauseAlreadyBilledWithSubProject')
        : t('progressStatement:tooltips.cannotCreateBecauseAlreadyBilled'),
    };
  }

  if (!subProjectWithInformation.lastProgressStatements) {
    return {
      canCreate: true,
      tooltip: t('progressStatement:tooltips.warningAfterCreation'),
    };
  }

  if (errors.length > 1) {
    Sentry.addBreadcrumb({
      level: 'warning',
      category: 'Progress statement creation',
      message: 'Unhandled errors',
      data: errors,
    });

    return {
      canCreate: false,
      tooltip: undefined,
    };
  }

  return { canCreate: true, tooltip: undefined };
};
