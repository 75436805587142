import type { FieldsetRef } from '@graneet/lib-ui';
import { Button, Fieldset, PercentageField, SimpleAddIcon, SimpleDeleteIcon, TextField } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import { DEDUCTION_TYPE, type ISubProject } from '@graneet/business-logic';

import type { ProjectEditParametersForm } from 'features/project/forms/project-edit-parameters-wizard';
import {
  getCustomHoldbackNameField,
  getCustomHoldbackPercentageField,
} from 'features/project/forms/project-edit-parameters-wizard';
import { Rule } from 'features/form/rules/Rule';

interface CustomHoldbackFieldsetProps {
  subProject: ISubProject;
  hasBillingStarted: boolean;
}

export const CustomHoldbackFieldset: FC<CustomHoldbackFieldsetProps> = ({ subProject, hasBillingStarted }) => {
  const { t } = useTranslation(['project']);

  const fieldsetRef = useRef<FieldsetRef>(null);
  const [fieldsIds, setFieldsIds] = useState<string[]>(
    subProject.deductions
      ?.filter((deduction) => deduction.type === DEDUCTION_TYPE.OTHER)
      .map((deduction) => deduction.id.toString()) ?? [],
  );

  const generateFieldId = () => uuid();

  const onOpenFieldset = () => {
    if (!fieldsIds.length) {
      setFieldsIds([generateFieldId()]);
    }
  };

  const addField = () => {
    setFieldsIds((prev) => [...prev, generateFieldId()]);
  };

  const removeField = (fieldId: string) => {
    // If user remove the last custom holdback, we close the fieldset
    if (fieldsIds.length === 1) {
      fieldsetRef.current?.handleIsChecked();
    }

    setFieldsIds((prev) => prev.filter((prevFieldId) => prevFieldId !== fieldId));
  };

  return (
    <Fieldset
      noCard
      variant="switch"
      handle="customHoldback"
      title={t('project:parameters.fields.customHoldback.title')}
      legend={t('project:parameters.fields.customHoldback.legend')}
      validationNames={[]}
      onOpen={onOpenFieldset}
      innerRef={fieldsetRef}
      defaultValue={fieldsIds.length > 0}
      isDisabled={hasBillingStarted}
    >
      <Box display="flex" flexDirection="column" gap={3} p={1}>
        <Flex flexDirection="column" gap={2}>
          {fieldsIds.map((fieldId) => (
            <Flex flexDirection="row" gap={24} alignItems="flex-end" backgroundColor="white" p={3} borderRadius="md">
              <TextField<ProjectEditParametersForm>
                name={getCustomHoldbackNameField(fieldId)}
                label={t('project:parameters.fields.customHoldback.labels.name')}
                w="50%"
                isRequired
                isDisabled={hasBillingStarted}
              >
                <Rule.IsRequired />
              </TextField>

              <PercentageField<ProjectEditParametersForm>
                name={getCustomHoldbackPercentageField(fieldId)}
                label={t('project:parameters.fields.customHoldback.labels.percentage')}
                w="20%"
                isRequired
                isDisabled={hasBillingStarted}
              >
                <Rule.IsRequired />
              </PercentageField>

              {!hasBillingStarted && (
                <Button variant="outline" leftIcon={<SimpleDeleteIcon />} onClick={() => removeField(fieldId)}>
                  {t('project:parameters.fields.customHoldback.remove')}
                </Button>
              )}
            </Flex>
          ))}
        </Flex>
        {!hasBillingStarted && (
          <Button variant="outline" leftIcon={<SimpleAddIcon />} onClick={addField}>
            {t('project:parameters.fields.customHoldback.add')}
          </Button>
        )}
      </Box>
    </Fieldset>
  );
};
