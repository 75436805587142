import type { FC, ReactNode } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { useCurrency, useSignals } from '@graneet/lib-ui';
import type { FormContextApi } from 'graneet-form';
import type { IQuoteComponent, IQuoteComponentResponseDTO } from '@graneet/business-logic';
import { isNumberFinite } from '@graneet/business-logic';

import { sortComponents } from '../services/quote-component.util';

import type { IQuoteComponentContext } from './component.context';
import { ComponentContext } from './component.context';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useStartAnotherUpdate } from 'features/common/hooks/useStartAnotherUpdate';
import type { QuoteJobEditModalForm } from 'features/quote-job/forms/quote-job-edit-modal.form';
import { BUILD_COMPONENT_FORM_FIELD } from 'features/quote-job/forms/quote-job-edit-modal.form';

const SIGNAL_COMPONENTS_IS_LOADING = 'component-is-loading';
export const BUILD_COMPONENTS_SIGNAL_KEY = 'components';

interface ComponentContextProviderProps {
  jobId: number;

  children: ReactNode;
}

export const ComponentContextProvider: FC<ComponentContextProviderProps> = ({ jobId, children }) => {
  // GLOBAL

  const { mapNumberToAmount } = useCurrency();
  const { emit, listen } = useSignals();

  const { updateDataLocally } = useQuoteEditContext();

  const formRef = useRef<FormContextApi<QuoteJobEditModalForm> | undefined>(undefined);

  const quoteComponentsRef = useRef<IQuoteComponent[] | null>(null);

  const emitIsLoading = (value: boolean) => emit(SIGNAL_COMPONENTS_IS_LOADING, value);
  const startAnotherUpdate = useStartAnotherUpdate(emitIsLoading);

  const listenIsComponentsUpdating = useCallback(
    (listener: (newValue: boolean) => void) => listen(SIGNAL_COMPONENTS_IS_LOADING, listener, false),
    [listen],
  );

  // Listen and emit components ids
  const listenToComponents = useCallback(
    (listener: (newValue: IQuoteComponent[] | null) => void) =>
      listen(BUILD_COMPONENTS_SIGNAL_KEY, listener, quoteComponentsRef.current),
    [listen],
  );

  const emitComponentsUpdate = useCallback(() => {
    emit(BUILD_COMPONENTS_SIGNAL_KEY, quoteComponentsRef.current);
  }, [emit]);

  const setComponents = useCallback(
    (components: IQuoteComponent[]) => {
      // Sort components and map amounts
      quoteComponentsRef.current = sortComponents(components);

      if (formRef.current) {
        const formValues: Partial<QuoteJobEditModalForm> = {};

        components.forEach((component) => {
          // componentTypeId
          formValues[BUILD_COMPONENT_FORM_FIELD(component.id, 'componentTypeId')] = component.componentType?.id;

          // code
          formValues[BUILD_COMPONENT_FORM_FIELD(component.id, 'code')] =
            component.code !== undefined ? component.code : null;

          // name
          formValues[BUILD_COMPONENT_FORM_FIELD(component.id, 'name')] =
            component.name !== undefined ? component.name : null;

          // unit
          formValues[BUILD_COMPONENT_FORM_FIELD(component.id, 'unit')] =
            component.unit !== undefined ? component.unit : null;

          // quantity
          formValues[BUILD_COMPONENT_FORM_FIELD(component.id, 'quantity')] = {
            value: component.quantity !== undefined ? component.quantity : null,
            content: component.quantityFormula,
          };

          // unitDisbursementExVAT
          formValues[BUILD_COMPONENT_FORM_FIELD(component.id, 'unitDisbursementExVAT')] = isNumberFinite(
            component.unitDisbursementExVAT,
          )
            ? mapNumberToAmount(component.unitDisbursementExVAT)
            : null;

          // totalMargin
          formValues[BUILD_COMPONENT_FORM_FIELD(component.id, 'totalMargin')] = component.margin
            ? component.margin.totalMargin
            : 1;
        });

        formRef.current.setFormValues(formValues);
      } else {
        console.error('Form is not defined in `ComponentContextProvider`. You can initialize it with setForm.');
      }

      emitComponentsUpdate();
    },
    [emitComponentsUpdate, mapNumberToAmount],
  );

  // -- Utils
  const handleComponentUpdate = useCallback(
    (response: IQuoteComponentResponseDTO, updateJob = true) => {
      const { components: updatedComponents, jobs: newJobs, lots: updatedLots, quote, job: updatedJob } = response;

      const newComponents = updatedComponents.reduce(
        (acc, updatedComponent) => ({
          ...acc,
          [updatedComponent.id]: { ...updatedComponent },
        }),
        {},
      );

      if (!updatedComponents.length && updateJob) {
        // When removing all components from the job
        setComponents([]);
        // Notify computed prices changes
        updateDataLocally({
          jobs: { ...newJobs, [updatedJob.id]: updatedJob },
          lots: updatedLots,
          quote,
          components: newComponents,
        });
        return;
      }

      // Update components list
      setComponents(Object.values(newComponents));

      if (updateJob) {
        // updatedJob.components = updatedComponents;
        // Notify computed prices changes
        updateDataLocally({
          jobs: { ...newJobs, [updatedJob.id]: updatedJob },
          lots: updatedLots,
          quote,
          components: newComponents,
        });
      }
    },
    [setComponents, updateDataLocally],
  );

  const setForm = useCallback((form: FormContextApi<QuoteJobEditModalForm>) => {
    formRef.current = form;
  }, []);

  const value = useMemo<IQuoteComponentContext>(
    () => ({
      jobId,
      startAnotherUpdate,
      listenIsComponentsUpdating,
      listenToComponents,
      setComponents,
      handleComponentUpdate,
      setForm,
    }),
    [
      jobId,
      startAnotherUpdate,
      listenIsComponentsUpdating,
      listenToComponents,
      setComponents,
      handleComponentUpdate,
      setForm,
    ],
  );

  return <ComponentContext.Provider value={value}>{children}</ComponentContext.Provider>;
};
