import { Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';
import { Checkbox } from '@chakra-ui/react';

import { CONTACT_FIELDS } from '../../constants/contacts.constant';

import { ContactAssociationRow } from './rows/ContactAssociationRow';

interface ContactAssociationTableProps<T extends IContact> {
  contacts: T[];
  onCheck: (id: string) => () => void;
  handleCheckAll: () => () => void;
  isAllChecked: boolean;
  isContactDefaultEmailRecipient: (contact: T) => boolean;
}

export const ContactAssociationTable = <T extends IContact>({
  contacts = [],
  onCheck,
  handleCheckAll,
  isAllChecked,
  isContactDefaultEmailRecipient,
}: ContactAssociationTableProps<T>) => {
  const { t } = useTranslation(['contacts']);

  return (
    <Table templateColumns={['3rem', 'minmax(8rem, 1fr)', 'minmax(8rem, 1fr)']} noResultLabel={t('contacts:noResult')}>
      <Table.Header>
        <Table.Cell>
          <Checkbox colorScheme="greenBrand" onChange={handleCheckAll()} isChecked={isAllChecked} />
        </Table.Cell>
        <Table.Cell sortBy={CONTACT_FIELDS.LAST_NAME}>{t('contacts:fields.lastName')}</Table.Cell>
        <Table.Cell sortBy={CONTACT_FIELDS.EMAIL}>{t('contacts:fields.contactDetails')}</Table.Cell>
      </Table.Header>

      {contacts.map((contact) => (
        <ContactAssociationRow
          key={`contact-row-table-${contact.id}`}
          contact={contact}
          onCheck={onCheck}
          isContactDefaultEmailRecipient={isContactDefaultEmailRecipient}
        />
      ))}
    </Table>
  );
};
