import { Flex } from '@chakra-ui/react';

import { QuoteHeaderSkeleton } from './QuoteHeaderSkeleton';
import { QuoteEditTableSkeleton } from './QuoteEditTableSkeleton';
import { QuoteDrawerSkeleton } from './QuoteDrawerSkeleton';

export const ViewQuoteScreenSkeleton = () => (
  <Flex backgroundColor="#F9FAFB" height="100%" width="100%" overflow="hidden">
    <Flex
      direction="column"
      h="100%"
      width="calc(100% - 21.5rem)"
      gap="0.75rem"
      padding="0.75rem"
      overflow="hidden"
      transition="width 0.25s ease-in-out"
    >
      <QuoteHeaderSkeleton />
      <QuoteEditTableSkeleton />
    </Flex>
    <QuoteDrawerSkeleton />
  </Flex>
);
