import type { QuoteNodeObject } from '@org/quotation-lib';
import type { IRowNode } from '@ag-grid-community/core';

interface SibblingForMoveQuoteLotInsideQuoteLot {
  newPrevSiblingId: string | null;
  newNextSiblingId: string | null;
}

export const getSibblingForMoveQuoteLotInsideQuoteLot = (
  node: IRowNode<QuoteNodeObject>,
  target: IRowNode<QuoteNodeObject>,
): SibblingForMoveQuoteLotInsideQuoteLot => {
  const nodeType = node.data?.content.type;
  const { childrenAfterSort } = target;

  if (nodeType !== 'QuoteLot' || !childrenAfterSort || childrenAfterSort.length === 0) {
    return {
      newPrevSiblingId: null,
      newNextSiblingId: null,
    };
  }

  const lastChild = childrenAfterSort.at(-1);
  const firstQuoteLotChild = childrenAfterSort.find((child) => child.data?.content.type === 'QuoteLot');

  if (!firstQuoteLotChild) {
    return {
      newPrevSiblingId: lastChild?.data?.id || null,
      newNextSiblingId: null,
    };
  }

  const prevSibblingOfFirstLotChild = firstQuoteLotChild.childIndex
    ? childrenAfterSort.at(firstQuoteLotChild.childIndex - 1)
    : null;

  return {
    newPrevSiblingId: prevSibblingOfFirstLotChild?.data?.id || null,
    newNextSiblingId: firstQuoteLotChild?.data?.id || null,
  };
};
