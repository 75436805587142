import type { FC } from 'react';
import { Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IHoldback } from '@graneet/business-logic';
import type { UseDisclosureReturn } from '@chakra-ui/react';

import { useHoldbackMarkAsReceived } from '../services/holdback.api';

interface HoldbackMarkAsReceivedModalProps {
  holdback: IHoldback;

  modal: UseDisclosureReturn;
}

export const HoldbackMarkAsReceivedModal: FC<HoldbackMarkAsReceivedModalProps> = ({ holdback, modal }) => {
  const { t } = useTranslation(['global', 'holdback']);

  const holdbackMarkAsReceivedMutation = useHoldbackMarkAsReceived();

  const handleSubmit = async () => {
    holdbackMarkAsReceivedMutation.mutate(holdback.id, {
      onSuccess: modal.onClose,
    });
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t('holdback:markAsReceivedModal.title')}>
      {t('holdback:markAsReceivedModal.description')}

      <Modal.Close />

      <Modal.PrimaryButton isLoading={holdbackMarkAsReceivedMutation.isPending} onClick={handleSubmit}>
        {t('global:words.c.validate')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
