import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'graneet-form';
import { Modal } from '@graneet/lib-ui';
import type { UseDisclosureReturn } from '@chakra-ui/react';

import type { ProgressStatementGlobalPercentageForm } from './ProgressStatementGlobalPercentageForm';

interface ConfirmProgressStatementGlobalProgressModalProps {
  modal: UseDisclosureReturn;

  onConfirmationClicked(newPercentage: number): void;

  type: 'container' | 'lot';
}

export const ConfirmProgressStatementGlobalProgressModal: FC<ConfirmProgressStatementGlobalProgressModalProps> = ({
  modal,
  onConfirmationClicked,
  type,
}) => {
  const { t } = useTranslation(['progressStatement', 'global']);

  const { getFormValues, setFormValues } = useFormContext<ProgressStatementGlobalPercentageForm>();

  const translationPath = useMemo(
    () =>
      type === 'lot'
        ? 'progressStatement:isCreating.globalLotProgressPercentageModal'
        : 'progressStatement:isCreating.globalContractProgressPercentageModal',
    [type],
  );

  const resetGlobalProgressPercentage = useCallback(() => {
    setFormValues({ globalPercentage: null });
  }, [setFormValues]);

  const handleClose = useCallback(() => {
    resetGlobalProgressPercentage();
    modal.onClose();
  }, [modal, resetGlobalProgressPercentage]);

  const handleSubmit = useCallback(() => {
    const { globalPercentage: newPercentage } = getFormValues();
    onConfirmationClicked(newPercentage!);

    handleClose();
  }, [getFormValues, handleClose, onConfirmationClicked]);

  return (
    <Modal title={t(`${translationPath}.title`)} {...modal} onClose={handleClose}>
      <Trans t={t} i18nKey={`${translationPath}.description`} />

      <Modal.PrimaryButton onClick={handleSubmit}>{t('global:words.c.confirm')}</Modal.PrimaryButton>

      <Modal.Close />
    </Modal>
  );
};
