export interface ToolbarTranslations {
  undo: string;

  redo: string;

  bold: string;

  italic: string;

  underline: string;

  strikethrough: string;

  superscript: string;

  bulletList: string;

  numberedList: string;

  textColor: string;

  fillColor: string;

  h1Title: string;

  h2Title: string;

  h3Title: string;

  paragraph: string;

  clearFormatting: string;

  link: string;

  variableNotFound: string;
}

export const toolbarTranslations: ToolbarTranslations = {
  undo: 'Undo',
  redo: 'Redo',
  bold: 'Bold',
  italic: 'Italic',
  underline: 'Underline',
  strikethrough: 'Strikethrough',
  superscript: 'Superscript',
  bulletList: 'Bullet list',
  numberedList: 'Numbered list',
  h1Title: 'Titre 1',
  h2Title: 'Titre 2',
  h3Title: 'Titre 3',
  textColor: 'Couleur du texte',
  fillColor: 'Couleur de remplissage',
  paragraph: 'Paragraphe',
  clearFormatting: 'Supprimer la mise en forme',
  link: 'Ajouter un lien',
  variableNotFound: 'Aucune donnée trouvée pour cette variable',
};

export const configureDefaultLabels = (translations: ToolbarTranslations) => {
  Object.assign(toolbarTranslations, translations);
};
