import type {
  IThirdPartyActivationDTO,
  IThirdPartyDeactivationDTO,
  ThirdParties,
  ThirdPartyInformationResponse,
} from '@graneet/business-logic';
import { capitalizeString } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { userKeyFactory } from 'features/user/services/user.api';
import { apiNew } from 'features/api/services/apiNew.service';

const THIRD_PARTIES_PATH = '/third-parties';

const thirdPartyKeyFactory = createQueryKeys('third-parties', {
  list: () => ({
    queryKey: [THIRD_PARTIES_PATH],
    queryFn: () => apiNew.get<never, ThirdPartyInformationResponse[]>(THIRD_PARTIES_PATH),
  }),
});

export function useThirdParties() {
  return useSuspenseQuery(thirdPartyKeyFactory.list());
}

export function useActivateThirdParty<T extends ThirdParties>() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IThirdPartyActivationDTO<T>) =>
      apiNew.patch<IThirdPartyActivationDTO<T>, void>(`${THIRD_PARTIES_PATH}/activate`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
      await queryClient.invalidateQueries(thirdPartyKeyFactory.list());
    },
  });
}

export function useDeactivateThirdParty<T extends ThirdParties>() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (thirdParty: T) =>
      apiNew.patch<IThirdPartyDeactivationDTO<T>, void>(`${THIRD_PARTIES_PATH}/deactivate`, {
        thirdPartyName: thirdParty,
      }),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, thirdPartyName) => {
      await queryClient.invalidateQueries(thirdPartyKeyFactory.list());
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
      toast.success(t('global:thirdParty.toasts.disableToast', { thirdParty: capitalizeString(thirdPartyName) }));
    },
  });
}
