import type { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

export interface ContainerProps {
  size?: 'sm' | 'md' | 'lg' | 'full';

  children: ReactNode;
}

const getSize = (size: ContainerProps['size']) => {
  switch (size) {
    case 'sm':
      return '40rem';
    case 'md':
      return '50rem';
    case 'lg':
      return '80rem';
    default:
      return '100%';
  }
};

export const Container: FC<ContainerProps> = ({ children, size = 'full', ...props }) => (
  <Box width="100%" display="flex" alignItems="center" flexDirection="column">
    <Box width="100%" maxWidth={getSize(size)} alignSelf="center" {...props}>
      {children}
    </Box>
  </Box>
);
