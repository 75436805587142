import type { QuoteFileDeleteDTO, QuoteFilesApi } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteFilesProxyApi {
  private static quoteFileProxyApi: QuoteFilesProxyApi;

  private readonly quoteFilesApi: QuoteFilesApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteFilesApi = externalApi.getQuoteFilesApi();
  }

  public static getQuoteFilesProxyApi(externalApi: ExternalApi): QuoteFilesProxyApi {
    if (!this.quoteFileProxyApi) {
      QuoteFilesProxyApi.quoteFileProxyApi = new QuoteFilesProxyApi(externalApi);
    }
    return QuoteFilesProxyApi.quoteFileProxyApi;
  }

  public async attachFiles(
    quoteId: string,
    timestamp: string,
    clientRequestId: string,
    nodeId: string,
    filesMetadata: string,
    files: File[],
  ) {
    return proxyHelper(async () =>
      this.quoteFilesApi.attachFiles(quoteId, timestamp, clientRequestId, nodeId, filesMetadata, files),
    );
  }

  public async deleteFile(quoteFileDeleteDTO: QuoteFileDeleteDTO) {
    return proxyHelper(async () => this.quoteFilesApi.deleteQuoteFile(quoteFileDeleteDTO));
  }
}
