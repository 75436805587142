import type { FC, ReactNode } from 'react';
import { Box, Collapse as CollapseChakra, Flex, HStack, IconButton, useDisclosure } from '@chakra-ui/react';

import { Title } from '../Title';
import { SimpleChevronBottomIcon } from '../Icons';

const iconProps = {
  boxSize: 4,
};

export interface ChapterCollapseProps {
  children: ReactNode;
  title: ReactNode;
  description?: ReactNode;
  topContent?: ReactNode;
  defaultIsOpen?: boolean;
  onCloseChildren?: ReactNode;
  collapseOverflowVisible?: boolean;
}

export const ChapterCollapse: FC<ChapterCollapseProps> = ({
  children,
  title,
  topContent,
  description = null,
  defaultIsOpen = false,
  onCloseChildren,
  collapseOverflowVisible = false,
}) => {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen,
  });

  return (
    <Box mb={8}>
      <HStack spacing={2} alignItems="center">
        <Title onClick={onToggle} cursor="pointer">
          {title}
        </Title>
        <IconButton
          cursor="pointer"
          onClick={onToggle}
          aria-label="Toggle"
          bg="none"
          size="md"
          borderRadius="8px"
          icon={
            isOpen ? (
              <SimpleChevronBottomIcon {...iconProps} transform="rotate(-180deg)" />
            ) : (
              <SimpleChevronBottomIcon {...iconProps} />
            )
          }
        />
        {topContent && (
          <Flex ml="auto" pl={4}>
            {topContent}
          </Flex>
        )}
      </HStack>

      <CollapseChakra
        in={isOpen}
        animateOpacity
        /**
         * Fix to override Chakra restriction on Collapse overflow
         * !important is needed to override Chakra's overflow: hidden
         */
        style={{
          overflow: collapseOverflowVisible ? 'initial !important' : 'hidden',
        }}
      >
        {description && <Box mt={1}>{description}</Box>}
        <Box mt={3}>{children}</Box>
      </CollapseChakra>

      {!isOpen && onCloseChildren && (
        <Box display="flex" mt={3}>
          {onCloseChildren}
        </Box>
      )}
    </Box>
  );
};
