import type { FC } from 'react';
import { useCallback } from 'react';
import { ActionMenu, SimpleFolderIcon, formatNumberToVatRate, useCurrency, useDrawer } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'graneet-form';
import { TRACKING_EVENT_ENUM, type ILibraryJob } from '@graneet/business-logic';

import type { OrderTree } from '../../../hooks/useOrderTree';

import type { OrderEditForm } from 'features/order/forms/order-edit-wizard';
import { getOrderItemFieldName } from 'features/order/forms/order-edit-wizard';
import { LibraryJobImportDrawer } from 'features/library-job/components/LibraryJobImportDrawer';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';

interface OrderAddJobsButtonProps {
  tree: OrderTree;
}

export const OrderAddJobButton: FC<OrderAddJobsButtonProps> = ({ tree }) => {
  const { t } = useTranslation(['orders']);
  const form = useFormContext<OrderEditForm>();
  const { mapNumberToAmount } = useCurrency();
  const drawer = useDrawer('import-jobs-drawer');
  const segmentAnalytics = useSegmentAnalytics();

  const { createLeaf, getDisplayedCurrentTree, getLastLeafOfNode } = tree;

  const importLibraryJobs = useCallback(
    (libraryJobs: ILibraryJob[]) => {
      const { rootNodeId, nodes } = getDisplayedCurrentTree();
      const lastItemId = getLastLeafOfNode(rootNodeId);

      const { defaultVatRate } = nodes[rootNodeId];
      const formValues: Partial<OrderEditForm> = {};

      libraryJobs.forEach((job) => {
        // add leaf
        const id = createLeaf(rootNodeId, lastItemId, {
          vatRate: defaultVatRate,
          unit: job.unit,
          description: job.description,
          unitPriceExVAT: job.unitDisbursementExVAT,
        });

        // fill form
        formValues[getOrderItemFieldName(id, 'vatRate')] = formatNumberToVatRate(defaultVatRate);
        formValues[getOrderItemFieldName(id, 'unit')] = job.unit;
        formValues[getOrderItemFieldName(id, 'description')] = job.description;
        formValues[getOrderItemFieldName(id, 'unitPriceExVAT')] = mapNumberToAmount(job.unitDisbursementExVAT);
      });

      form.setFormValues(formValues);

      segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.ORDER_ITEMS_IMPORT, { type: 'library-job' });

      return null;
    },
    [createLeaf, form, getDisplayedCurrentTree, getLastLeafOfNode, mapNumberToAmount, segmentAnalytics],
  );

  return (
    <>
      <ActionMenu.Action icon={<SimpleFolderIcon />} label={t('orders:actions.importJobs')} onClick={drawer.onOpen} />

      <LibraryJobImportDrawer
        title={t('orders:drawers.jobImportDrawer.title')}
        description={t('orders:drawers.jobImportDrawer.description')}
        drawer={drawer}
        onSubmit={importLibraryJobs}
      />
    </>
  );
};
