import type { FC } from 'react';
import { Price } from '@graneet/lib-ui';

import type { LotId } from '../../services/progress-statement-tree.util';
import { useProgressStatementLotOrContainerComputedValue } from '../../hooks/useProgressStatementTree';

interface ProgressStatementLotTotalLabelProps {
  id: LotId;
}

export const ProgressStatementLotTotalLabel: FC<ProgressStatementLotTotalLabelProps> = ({ id }) => {
  const computedValues = useProgressStatementLotOrContainerComputedValue(id);

  return <Price amount={computedValues?.cumulativeAmountExVAT || 0} />;
};
