import type { Action } from '@graneet/lib-ui';
import { SimpleDownloadIcon, SimpleEditIcon, SimpleEmailIcon, SimpleViewIcon } from '@graneet/lib-ui';
import { t } from 'i18next';
import { INVOICE_STATUSES, canInvoiceBeCancelled, canInvoiceBeForceDrafted } from '@graneet/business-logic';

import type { InvoiceActionsParams } from './invoice.actions.type';

import { getPDFActionProps } from 'features/common/services/common.actions.util';

export function getCompleteAction({
  isCompleteButtonLoading,
  hasUpdatePermission,
  onSetStatusToCompleted,
}: InvoiceActionsParams): Action {
  return {
    label: t('invoice:actions.complete'),
    tooltip: !hasUpdatePermission ? t('global:errors.disabledAction') : undefined,
    isDisabled: !hasUpdatePermission,
    isLoading: isCompleteButtonLoading,
    onClick: onSetStatusToCompleted,
  };
}

export function getEditAction({
  invoice,
  onEdit,
  hasCreatePermission,
  hasUpdatePermission,
}: InvoiceActionsParams): Action {
  const hasPermission = invoice.status === INVOICE_STATUSES.DRAFT ? hasCreatePermission : hasUpdatePermission;

  return {
    icon: <SimpleEditIcon />,
    label: t('invoice:actions.modify'),
    tooltip: !hasPermission ? t('global:errors.disabledAction') : undefined,
    isDisabled: !hasPermission,
    onClick: onEdit,
  };
}

export function getPreviewPDFAction({ onViewPdf, pdf }: InvoiceActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('global:pdf.viewPDF'),
    icon: <SimpleViewIcon />,
    ...getPDFActionProps(pdf),
    onClick: onViewPdf,
  };
}

export function getDownloadPDFAction({ onDownloadPdf, pdf }: InvoiceActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('global:pdf.downloadPDF'),
    icon: <SimpleDownloadIcon />,
    ...getPDFActionProps(pdf),
    onClick: onDownloadPdf,
  };
}

export function getOpenMailingAction({ onOpenMailing, pdf }: InvoiceActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('mailing:modal.send'),
    icon: <SimpleEmailIcon />,
    ...getPDFActionProps(pdf),
    onClick: onOpenMailing,
  };
}

export function getCancelWithCreditAction({
  invoice,
  hasUpdatePermission,
  onCancelWithCredit,
}: InvoiceActionsParams): Action {
  const missingPermission = !hasUpdatePermission;

  const canCancel = canInvoiceBeCancelled(invoice);

  const tooltip = (() => {
    if (missingPermission) {
      return t('global:errors.disabledAction');
    }
    if (canCancel.causes.includes('accounting-payments')) {
      return t('invoice:tooltips.impossibleToCancelWithAccountingPayments');
    }
    return undefined;
  })();

  return {
    label: t('credit:actions.create'),
    tooltip,
    onClick: onCancelWithCredit,
    isDisabled: missingPermission || !canCancel.ok,
    icon: <SimpleEditIcon />,
  };
}

// Force draft
export function getCancelWithoutCreditAction({
  invoice,
  hasUpdatePermission,
  hasForceDraftPermission,
  onCancelWithoutCredit,
}: InvoiceActionsParams): Action {
  const missingPermission = !hasUpdatePermission || !hasForceDraftPermission;

  const canForceDraft = canInvoiceBeForceDrafted(invoice);

  const tooltip = (() => {
    if (missingPermission) {
      return t('global:errors.disabledAction');
    }

    if (canForceDraft.causes.includes('accounting-payments') && canForceDraft.causes.includes('accounting-invoice')) {
      return t('invoice:tooltips.impossibleToCancelWithAccountingPaymentsAndInvoice');
    }
    if (canForceDraft.causes.includes('accounting-payments')) {
      return t('invoice:tooltips.impossibleToCancelWithAccountingPayments');
    }

    if (canForceDraft.causes.includes('accounting-invoice')) {
      return t('invoice:tooltips.impossibleToCancelWithAccountingInvoice');
    }

    return undefined;
  })();

  return {
    label: t('invoice:actions.forceDraft'),
    tooltip,
    onClick: onCancelWithoutCredit,
    isDisabled: missingPermission || !canForceDraft.ok,
    icon: <SimpleEditIcon />,
  };
}

export function getDeleteAction({ invoice, onDelete, hasCreatePermission }: InvoiceActionsParams): Action | undefined {
  const tooltip = (() => {
    if (!hasCreatePermission) {
      return t('global:errors.disabledAction');
    }
    if (invoice.invoiceNumber) {
      return t('statement:delete.errors.invoiceNumberSet');
    }
    return undefined;
  })();

  const isDisabled = !hasCreatePermission || !!invoice.invoiceNumber;

  return {
    label: t('global:words.c.delete'),
    icon: <SimpleEditIcon />,
    warning: true,
    tooltip,
    isDisabled,
    onClick: onDelete,
  };
}
