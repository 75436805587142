import debounce from 'lodash-es/debounce';
import { useEffect, useMemo, useRef } from 'react';

export type DebouncedCallback<T extends unknown> = (...args: any) => T;

// Add types for the debounced function from LoDash
// Prevent error: The inferred type of 'useDebounce' cannot be named without a reference to '.pnpm/@types+lodash@4.17.0/node_modules/@types/lodash'. This is likely not portable. A type annotation is necessary.

interface DebouncedFunc<T extends (...args: any[]) => any> {
  (...args: Parameters<T>): ReturnType<T> | undefined;
  cancel(): void;
  flush(): ReturnType<T> | undefined;
}
interface DebouncedFuncLeading<T extends (...args: any[]) => any> extends DebouncedFunc<T> {
  (...args: Parameters<T>): ReturnType<T>;
  flush(): ReturnType<T>;
}

export const useDebounce = <T extends unknown>(callback: DebouncedCallback<T>, timer?: number) => {
  const ref = useRef<DebouncedCallback<T>>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback: DebouncedFuncLeading<(...args: any) => T | undefined> = useMemo(() => {
    const func = (...args: any) => ref.current?.(...args);

    return debounce(func, timer ?? 1000, {
      leading: true,
      trailing: true,
      maxWait: timer,
    });
  }, [timer]);

  return debouncedCallback;
};
