import type { ButtonProps } from '@graneet/lib-ui';
import { Button } from '@graneet/lib-ui';
import type { FC, PropsWithChildren } from 'react';

export type ActionLinkProps = PropsWithChildren<ButtonProps>;
export const ActionLink: FC<ActionLinkProps> = ({ children, ...linkProps }) => (
  <Button
    as="a"
    // @ts-ignore
    target="_blank"
    size="md"
    border="none"
    variant="outline"
    {...linkProps}
  >
    {children}
  </Button>
);
