import { StepperModal } from '@graneet/lib-ui';
import { useFormStatus, useStepForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';

import type { ContractCreateWizard } from '../ContractCreateWizard';

import { ClientUpsertForm } from 'features/client/components/ClientUpsertForm/ClientUpsertForm';

export const ClientCreateStep = () => {
  const { t } = useTranslation(['clients']);

  const { form } = useStepForm<ContractCreateWizard, 'create-client'>();
  const { isValid: isFormValid } = useFormStatus(form);

  return (
    <>
      <Flex paddingTop={4} width="100%">
        <ClientUpsertForm form={form} />
      </Flex>

      <StepperModal.CloseButton>{t('clients:modal.buttons.cancel')}</StepperModal.CloseButton>
      <StepperModal.PrimaryButton isDisabled={!isFormValid}>
        {t('clients:modal.buttons.next')}
      </StepperModal.PrimaryButton>
    </>
  );
};
