import type { ChangeEvent } from 'react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { CheckboxField } from '@graneet/lib-ui';
import { useFormContext } from 'graneet-form';

import type { QuoteExportForm } from '../../forms/quote-export-edition.wizard';
import {
  generateIsJobSelectedExportFieldName,
  generateIsLotSelectedExportFieldName,
} from '../../forms/quote-export-edition.wizard';
import { useQuoteExportLotComputedValue, useQuoteExportTreeContext } from '../../hooks/useQuoteExportTree';

export const QuoteExportLotCheckbox = memo<{ lotId: number }>(({ lotId }) => {
  const lotFormKey = generateIsLotSelectedExportFieldName(lotId);
  const quoteExportForm = useFormContext<QuoteExportForm>();
  const { findDescendingChildrenOfNode, updateAllChildrenLeavesOfNode } = useQuoteExportTreeContext();

  const children = useMemo(() => findDescendingChildrenOfNode(lotId), [findDescendingChildrenOfNode, lotId]);

  // Override default form on change for lot checkboxes
  const handleLotCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      updateAllChildrenLeavesOfNode(lotId, { isSelected: event.target.checked });

      // When user interacts directly with the lot checkbox, we want to force note selection, otherwise we don't
      const formValues: Partial<QuoteExportForm> = {};
      formValues[generateIsLotSelectedExportFieldName(lotId)] = event.target.checked;
      children.leaves.forEach((id) => {
        formValues[generateIsJobSelectedExportFieldName(id)] = event.target.checked;
      });
      updateAllChildrenLeavesOfNode(lotId, { isSelected: event.target.checked });
      children.nodes.forEach((id) => {
        formValues[generateIsLotSelectedExportFieldName(id)] = event.target.checked;
      });
      quoteExportForm.setFormValues(formValues);
    },
    [children.leaves, children.nodes, lotId, quoteExportForm, updateAllChildrenLeavesOfNode],
  );

  const quoteLotComputedValue = useQuoteExportLotComputedValue(lotId);
  // Determine whether the lot is fully selected (jobs and sublots state only)
  const isLotPartiallyUnselected = useMemo(() => {
    if (!quoteLotComputedValue) {
      return false;
    }

    return (
      quoteLotComputedValue?.numberOfUnselectedJobs > 0 &&
      quoteLotComputedValue?.numberOfUnselectedJobs !== quoteLotComputedValue?.numberOfJobs
    );
  }, [quoteLotComputedValue]);

  // Update lot checkbox state base on children jobs state
  useEffect(() => {
    if (!quoteLotComputedValue) {
      return;
    }

    if (quoteLotComputedValue.numberOfUnselectedJobs === quoteLotComputedValue.numberOfJobs) {
      quoteExportForm.setFormValues({ [lotFormKey]: false });
    }

    if (quoteLotComputedValue.numberOfUnselectedJobs === 0) {
      quoteExportForm.setFormValues({ [lotFormKey]: true });
    }

    if (isLotPartiallyUnselected) {
      // we set a specific value that is not true or false when selection is partial, because otherwise
      // form value for the lot could be false and else the lot not included in the export
      const formValues: Partial<QuoteExportForm> = {};
      formValues[lotFormKey] = 'partial';
      quoteExportForm.setFormValues(formValues);
    }
  }, [isLotPartiallyUnselected, lotFormKey, quoteExportForm, quoteLotComputedValue]);

  return (
    <CheckboxField<QuoteExportForm>
      name={lotFormKey}
      isIndeterminate={isLotPartiallyUnselected}
      onChange={handleLotCheckboxChange}
      borderColor="gray.300"
      mr={2}
    />
  );
});
