import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Onboarding, SimpleClientIcon } from '@graneet/lib-ui';
import { FEATURE_FLAGS, type IProject } from '@graneet/business-logic';
import { Center, Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { QuoteActionsButtons as QuoteActionsButtonsV1 } from 'features/quote/components/buttons/QuoteActionsButtons';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { QuoteActionsButtons } from 'features/quotation/quote/components/buttons/QuoteActionsButtons';

interface ProjectAddFirstContractCardProps {
  project: IProject;
}

export const ProjectAddFirstContractCard: FC<ProjectAddFirstContractCardProps> = ({ project }) => {
  const { t } = useTranslation(['project']);
  const history = useHistory();
  const isQuoteV2Enabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);

  const onImportCompleted = useCallback(() => {
    history.push(`/projects/${project.id}/contracts`);
  }, [history, project.id]);

  return (
    <Card>
      <Box my={4}>
        <Onboarding
          icon={<SimpleClientIcon color="gray.100" boxSize={45} />}
          message={t('project:dontHaveAssociatedAcceptedQuote')}
        />

        <Center mt={6}>
          {isQuoteV2Enabled ? (
            <QuoteActionsButtons onImportCompleted={onImportCompleted} project={project} spacing={12} />
          ) : (
            <QuoteActionsButtonsV1 onImportCompleted={onImportCompleted} project={project} spacing={12} />
          )}
        </Center>
      </Box>
    </Card>
  );
};
