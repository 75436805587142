import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, StepperItem } from '@graneet/lib-ui';
import { Divider, Text } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';
import type { IFormattedValidationStepHistory } from '@graneet/business-logic';

import { getValidationStepStatus } from 'features/validation-step/utils/getValidationStepStatus';

interface ValidationStepHistoryItemProps {
  historyItem: IFormattedValidationStepHistory;
  isCurrentStep: boolean;
  isLast: boolean;
  isAboveThreshold: boolean;
}

const ValidationStepHistoryItem: FC<ValidationStepHistoryItemProps> = ({
  historyItem,
  isCurrentStep,
  isLast,
  isAboveThreshold,
}) => {
  const { t } = useTranslation(['global', 'validationStep']);
  const userApprovedStep = historyItem.users.find((u) => u.isApproved);
  const { icon, backgroundColorIcon, title } = useMemo(
    () =>
      getValidationStepStatus(
        (translationKey: 'requestApproved' | 'pendingApproval' | 'requiredApproval' | 'possibleApproval') =>
          t(`validationStep:${translationKey}`),
        historyItem,
        isCurrentStep,
        userApprovedStep?.user,
        isAboveThreshold,
      ),
    [historyItem, isCurrentStep, t, userApprovedStep?.user, isAboveThreshold],
  );

  return (
    <StepperItem
      title={title}
      icon={icon}
      backgroundColorIcon={backgroundColorIcon}
      borderColor={isLast ? 'gray.50' : undefined}
    >
      <Text color="gray.500" fontSize="sm">
        {userApprovedStep && t('global:date', { date: dayjs(historyItem.validationStep.updatedAt).format('LLL') })}
      </Text>
    </StepperItem>
  );
};

interface ValidationStepsHistoryModalProps {
  validationStepHistory: IFormattedValidationStepHistory[] | null;
  currentValidationStepHistory?: IFormattedValidationStepHistory;
  isOpen: boolean;
  onClose: () => void;
  handleValidStep?: () => void;
  isUserCanApprove: boolean;
  stepIdsAboveThresholdValidationSteps: string[];
}

export const ValidationStepsHistoryModal: FC<ValidationStepsHistoryModalProps> = ({
  handleValidStep,
  validationStepHistory,
  currentValidationStepHistory,
  isUserCanApprove,
  isOpen,
  onClose,
  stepIdsAboveThresholdValidationSteps,
}) => {
  const { t } = useTranslation(['global', 'validationStep']);

  return (
    <Modal title={t('validationStep:validationHistory')} onClose={onClose} isOpen={isOpen}>
      {validationStepHistory && currentValidationStepHistory ? (
        <>
          <Divider mb={6} />
          {validationStepHistory.map((step, index) => (
            <ValidationStepHistoryItem
              key={step.validationStepId}
              historyItem={step}
              isCurrentStep={step.validationStepId === currentValidationStepHistory.validationStepId}
              isLast={index === validationStepHistory.length - 1}
              isAboveThreshold={stepIdsAboveThresholdValidationSteps.includes(step.validationStepId)}
            />
          ))}

          {handleValidStep && isUserCanApprove && (
            <>
              <Divider mt={6} />
              <Modal.PrimaryButton onClick={handleValidStep} leftIcon={<CheckIcon boxSize={3} />}>
                {t('global:words.c.approve')}
              </Modal.PrimaryButton>
            </>
          )}
        </>
      ) : (
        <Text fontWeight="700" fontSize="sm">
          {t('validationStep:historyModalError')}
        </Text>
      )}

      <Modal.Close />
    </Modal>
  );
};
