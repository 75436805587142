import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { Flex, Box, SimpleGrid } from '@chakra-ui/react';
import { Button, TextField, TextAreaField, IconAdvanced, SimpleHelpIcon } from '@graneet/lib-ui';
import type { IPaymentMethodResponseDTO } from '@graneet/business-logic';

import { useUpdatePaymentMethod } from 'features/payment-method/services/payment-method.api';
import { Rule } from 'features/form/rules/Rule';

interface PaumentMethodEditionForm {
  name?: string;

  iban?: string | null;

  bic?: string | null;

  text?: string | null;
}

interface PaymentMethodEditionFormProps {
  onClose(): void;

  paymentMethod: IPaymentMethodResponseDTO;

  onUpdated(paymentMethod: IPaymentMethodResponseDTO): Promise<void>;

  setIsSubmitting(newValue: boolean): void;
}

export const PaymentMethodEditionForm: FC<PaymentMethodEditionFormProps> = ({
  onClose,
  paymentMethod,
  onUpdated,
  setIsSubmitting,
}) => {
  const { t } = useTranslation(['paymentMethods', 'global']);

  const form = useForm<PaumentMethodEditionForm>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues, setFormValues } = form;

  const updatePaymentMethodMutation = useUpdatePaymentMethod();

  useEffect(() => {
    setFormValues(paymentMethod);
  }, [paymentMethod, setFormValues]);

  useEffect(() => {
    setIsSubmitting?.(updatePaymentMethodMutation.isPending);
  }, [setIsSubmitting, updatePaymentMethodMutation.isPending]);

  const handleSubmit = useCallback(async () => {
    if (Number.isNaN(paymentMethod?.id)) {
      return;
    }

    const values = getFormValues();
    updatePaymentMethodMutation.mutate(
      {
        id: paymentMethod.id,
        dto: values,
      },
      {
        onSuccess: (response) => {
          onUpdated(response);
        },
      },
    );
  }, [paymentMethod.id, getFormValues, updatePaymentMethodMutation, onUpdated]);

  return (
    <Form form={form}>
      <Box mb={3}>{t('paymentMethods:modal.update.desc')}</Box>
      <Flex direction="column" mt={6}>
        <Flex direction="row" mb={5}>
          <TextField<PaumentMethodEditionForm>
            name="name"
            label={t('paymentMethods:field.name.label')}
            rightIcon={<IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('paymentMethods:field.name.tooltip')} />}
            isRequired
          >
            <Rule.IsRequired message={t('paymentMethods:field.name.error.update')} />
          </TextField>
        </Flex>

        <SimpleGrid spacing={4} columns={2} mb={5}>
          <TextField<PaumentMethodEditionForm> name="iban" label={t('paymentMethods:field.iban')} />
          <TextField<PaumentMethodEditionForm> name="bic" label={t('paymentMethods:field.bic')} />
        </SimpleGrid>

        <Flex mb={8}>
          <TextAreaField<PaumentMethodEditionForm>
            name="text"
            label={t('paymentMethods:field.comment.label')}
            rightIcon={<IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('paymentMethods:field.comment.tooltip')} />}
          />
        </Flex>
      </Flex>

      <Flex justify="space-between" w="100%" mb={4}>
        <Button variant="outline" onClick={onClose} isDisabled={updatePaymentMethodMutation.isPending}>
          {t('global:words.c.back')}
        </Button>

        <Button onClick={handleSubmit} isDisabled={!isFormValid} isLoading={updatePaymentMethodMutation.isPending}>
          {t('paymentMethods:modal.update.cta')}
        </Button>
      </Flex>
    </Form>
  );
};
