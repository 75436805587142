import type { FC } from 'react';
import type { FieldValue } from 'graneet-form';

import { usePaginatedDataContext } from '../PaginatedData/hooks';
import type { InputProps } from '../Input';
import { Input } from '../Input';

export const Search: FC<InputProps> = (props) => {
  const { searchState } = usePaginatedDataContext();
  const { searchTerm, setSearchTerm } = searchState;

  return (
    <Input
      w={['100%', '100%', '20rem']}
      {...props}
      onChange={setSearchTerm as (value: FieldValue | null) => void}
      value={searchTerm}
    />
  );
};
