import type { FC } from 'react';
import { useState, useCallback } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IClientResponseDTO } from '@graneet/business-logic';
import { Form, useForm, useFormContext, useFormStatus } from 'graneet-form';

import type { ClientOrSupplierAssociateForm } from 'features/contact/components/forms/ClientOrSupplierAssociationForm';
import { ClientOrSupplierAssociationForm } from 'features/contact/components/forms/ClientOrSupplierAssociationForm';
import type { InvoiceEditionClientForm } from 'features/invoice/forms/invoice-edition-client-form';

interface ClientSelectionModalProps {
  title: string;

  description: string;

  isOpen: boolean;

  onClose(): void;

  handleSuccess?(client: IClientResponseDTO): Promise<void> | void;

  successButtonTranslation: string;

  onSubmit: (clientId: number | undefined) => Promise<void> | void;
}

export const ClientSelectionModal: FC<ClientSelectionModalProps> = ({
  title,
  description,
  isOpen,
  onClose,
  successButtonTranslation,
  onSubmit,
}) => {
  const { t } = useTranslation(['quote']);

  const invoiceEditionClientForm = useFormContext<InvoiceEditionClientForm>();
  const clientOrSupplierAssociateForm = useForm<ClientOrSupplierAssociateForm>();
  const { isValid: isFormValid } = useFormStatus(clientOrSupplierAssociateForm);

  const handleClose = useCallback(() => {
    clientOrSupplierAssociateForm.resetForm();
    onClose();
  }, [clientOrSupplierAssociateForm, onClose]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    const associationFormValues = clientOrSupplierAssociateForm.getFormValues();
    setIsLoading(true);
    await onSubmit(associationFormValues.association?.id ?? undefined);
    setIsLoading(false);

    invoiceEditionClientForm.setFormValues({
      clientId: associationFormValues.association?.id ?? undefined,
    });
    handleClose();
  }, [clientOrSupplierAssociateForm, handleClose, invoiceEditionClientForm, onSubmit]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={title} size="3xl" fullHeight scrollBehavior="inside">
      <Box mb={3}>{description}</Box>
      <VStack w="100%" align="stretch">
        <Form form={clientOrSupplierAssociateForm}>
          <ClientOrSupplierAssociationForm
            searchSuppliers={false}
            searchClients
            isRequired
            showBadge={false}
            placeholder={t('quote:clientStep.associationPlaceholder')}
          />
        </Form>
      </VStack>
      <Modal.Close onClick={onClose} />

      <Modal.PrimaryButton isLoading={isLoading} isDisabled={!isFormValid} onClick={handleSubmit}>
        {successButtonTranslation}
      </Modal.PrimaryButton>
    </Modal>
  );
};
