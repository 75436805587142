export interface BatiprixComponentTypeForm {
  materialComponentTypeId: number;

  workforceComponentTypeId: number;
}

export type BatiprixUserTokenFieldName = `token_${number}`;

export const getBatiprixUserTokenFieldName = (userId: number): BatiprixUserTokenFieldName => `token_${userId}`;

export type BatiprixUserIsActiveFieldName = `isActive_${number}`;

export const getBatiprixUserIsActiveFieldName = (userId: number): BatiprixUserIsActiveFieldName => `isActive_${userId}`;

export const getUserIdFromBatiprixUserIsActiveFieldName = (fieldName: BatiprixUserIsActiveFieldName): number =>
  parseInt(fieldName.split('_')[1], 10);

export interface BatiprixTokenForm {
  [token: BatiprixUserTokenFieldName]: string;

  [isActive: BatiprixUserIsActiveFieldName]: boolean;
}

export const isBatiprixUserIsActiveFieldName = (
  fieldName: keyof BatiprixTokenForm,
): fieldName is BatiprixUserIsActiveFieldName => fieldName.startsWith('isActive_');

export type BatiprixEditConfigWizard = {
  componentType: BatiprixComponentTypeForm;

  token: BatiprixTokenForm;
};
