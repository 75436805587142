import { Flex } from '@chakra-ui/react';
import type { IOrder } from '@graneet/business-logic';
import { isDirectPaymentSupplierInvoice as isDirectPaymentSupplierInvoiceFn } from '@graneet/business-logic';
import { Card, useCurrency } from '@graneet/lib-ui';
import { HiddenField, useFormContext, useHiddenField, useOnChangeValues } from 'graneet-form';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSupplierInvoiceContext } from '../../hooks/UseSupplierInvoiceContext';
import { AssociatedOrdersAmounts } from '../AssociatedOrdersAmounts';
import { OrdersList } from '../OrderList';
import type { EditSupplierInvoiceForm } from '../../forms/edit-supplier-invoice.form';

import { OrderPicker } from './AssociatedOrdersCard/OrderPicker';

export const AssociatedOrderCreationCard: FC = () => {
  const { t } = useTranslation(['supplierInvoices']);
  const { mapAmountToNumber } = useCurrency();

  const form = useFormContext<EditSupplierInvoiceForm>();
  const associatedOrdersHiddenField = useHiddenField(form, 'associatedOrders');
  const { setValue: setAssociatedOrders, value: associatedOrders } = associatedOrdersHiddenField;

  const onDeleteOrder = useCallback(
    (order: IOrder) => setAssociatedOrders(associatedOrders?.filter((o) => o.id !== order.id)),
    [associatedOrders, setAssociatedOrders],
  );

  /**
   * Compute invoiceAmountExVAT amount
   */
  const { amountExVAT: rawAmountExVAT } = useOnChangeValues(form, ['amountExVAT']);
  const invoiceAmountExVAT = rawAmountExVAT ? mapAmountToNumber(rawAmountExVAT) : 0;

  const { supplierInvoice } = useSupplierInvoiceContext();
  const isDirectPaymentSupplierInvoice = isDirectPaymentSupplierInvoiceFn(supplierInvoice);

  const onOrderAdded = useCallback(
    (addedOrder: IOrder) => {
      setAssociatedOrders((associatedOrders || []).concat(addedOrder));
    },
    [associatedOrders, setAssociatedOrders],
  );

  return (
    <Card title={t('supplierInvoices:associate.title')}>
      <HiddenField {...associatedOrdersHiddenField} />

      <Flex w="100%" flexDir="column" gap={3}>
        {!isDirectPaymentSupplierInvoice && (
          <OrderPicker addedOrders={associatedOrders || []} onOrderAdded={onOrderAdded} />
        )}

        <OrdersList
          orders={associatedOrders || []}
          onDeleteOrder={onDeleteOrder}
          isDirectPaymentSupplierInvoice={isDirectPaymentSupplierInvoice}
        />

        <AssociatedOrdersAmounts orders={associatedOrders || []} invoiceAmountExVAT={invoiceAmountExVAT} />
      </Flex>
    </Card>
  );
};
