import { useCallback, useRef, useState } from 'react';

import { TIME_DISPLAY_LOADER } from 'features/common/constants/loading.constant';

export const useDebouncedIsSaving = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const countRef = useRef(0);
  const startAnotherUpdate = useCallback(() => {
    if (!isUpdating) {
      setIsUpdating(true);
    }
    countRef.current += 1;
    setTimeout(() => {
      countRef.current -= 1;
      if (countRef.current < 1) {
        setIsUpdating(false);
      }
    }, TIME_DISPLAY_LOADER);
  }, [isUpdating]);
  return { isUpdating, startAnotherUpdate };
};
