import { HStack, Text } from '@chakra-ui/react';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { memo, useMemo } from 'react';
import isEqual from 'lodash-es/isEqual';

import { useStore } from 'store/store';

export const QuantityCell = memo(
  (params: CustomCellRendererProps) => {
    const { getValue } = params;
    const componentTypes = useStore((state) => state.quoteComponentTypes);

    const itemComponent = useMemo(() => {
      if (!params.data.componentTypeId) {
        return null;
      }
      const componentType = componentTypes.find((component) => String(component.id) === params.data.componentTypeId);

      return componentType ?? null;
    }, [componentTypes, params.data.componentTypeId]);

    const value: string | undefined = useMemo(() => {
      if (getValue) {
        return getValue();
      }
      return null;
    }, [getValue]);

    return (
      <HStack height="100%" display="inline-flex" justifyContent="center" color="#1F2A37" fontSize="0.75rem" p={2}>
        <Text>{value}</Text>
        {itemComponent?.unit && <Text>{itemComponent?.unit}</Text>}
      </HStack>
    );
  },
  (prevProps, nextProps) => {
    if (!prevProps && !nextProps) {
      return true;
    }
    if (!prevProps || !nextProps) {
      return false;
    }
    const oldValue = prevProps.getValue ? prevProps.getValue() : undefined;
    const newValue = nextProps.getValue ? nextProps.getValue() : undefined;
    return isEqual(oldValue, newValue);
  },
);
