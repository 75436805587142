import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import { useCurrency } from '../../../Currency/CurrencyProvider';
import { capitalizeString } from '../../../../utils/string.util';

export function useFormatGraphAxis() {
  const { formatAsGraphAmount } = useCurrency();

  const formatGraphAxis = useCallback(
    (value: any, type: 'money' | 'date' | 'string') => {
      switch (type) {
        case 'money':
          return formatAsGraphAmount(value);
        case 'date':
          return capitalizeString(
            dayjs(value).toDate().toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'short',
            }),
          );
        case 'string':
          return value;
        default:
          throw new Error('Not handled type');
      }
    },
    [formatAsGraphAmount],
  );

  return useMemo(
    () => ({
      formatGraphAxis,
    }),
    [formatGraphAxis],
  );
}
