import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

const TOAST_DURATION_SUCCESS = 1200;
const TOAST_DURATION_ERROR = 2400;

export const useJobMoveToast = () => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);

  return useCallback(
    ({ isError }: { isError: boolean }) => {
      const title = isError ? t('global:errors.error') : t('global:words.c.success');

      const description = <Trans t={t} i18nKey={`quote:jobsStep.${isError ? 'errors' : 'successes'}.moving`} />;

      const toastFn = isError ? toast.error : toast.success;
      const duration = isError ? TOAST_DURATION_ERROR : TOAST_DURATION_SUCCESS;
      toastFn(title, description, { duration });
    },
    [toast, t],
  );
};
