import type { FC } from 'react';
import { CircularProgress, Text, VStack } from '@chakra-ui/react';
import { Callout, Modal, SimpleAlertIcon, SimpleCheckCircleIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

interface ExportModalGenerationStepProps {
  sseStatus: {
    isError: boolean;
    isGenerating: boolean;
    message?: string;
  };

  onClose(): void;
}

export const ExportModalGenerationStep: FC<ExportModalGenerationStepProps> = ({
  sseStatus: { isError, isGenerating, message },
  onClose,
}) => {
  const { t } = useTranslation(['global']);

  return (
    <>
      {isGenerating ? (
        <VStack spacing={6}>
          <CircularProgress size={12} color="gray.800" isIndeterminate />
          <Text textAlign="center">{t('global:generateExportModal.pending')}</Text>
        </VStack>
      ) : (
        <Callout
          colorScheme={isError ? 'red' : 'green'}
          icon={isError ? <SimpleAlertIcon boxSize={5} /> : <SimpleCheckCircleIcon boxSize={5} />}
        >
          {isError
            ? (message ?? t('global:generateExportModal.error', { email: SUPPORT_EMAIL }))
            : (message ?? t('global:generateExportModal.success'))}
        </Callout>
      )}

      <Modal.PrimaryButton isDisabled={isGenerating} onClick={onClose}>
        {t('global:words.c.close')}
      </Modal.PrimaryButton>
    </>
  );
};
