import type {
  DateFormat,
  GeneratorCounterConfig,
  GeneratorDateConfig,
  GeneratorStaticConfig,
  ILedger,
  Separator,
} from '@graneet/business-logic';
import { computeNewLedgerFromSettings, ConfigBlock, LEDGER_GENERATORS } from '@graneet/business-logic';

// The values always have to be string for the select, and undefined won't set the value to any existing option
export type ClientDateOption = 'YYYYMM' | 'YYMM' | 'MMYYYY' | 'MMYY' | 'YYYY' | 'YY' | 'NONE';
export type ClientSeparatorOption = Separator | 'NONE';
export type ClientCounterSizeOption = '2' | '3' | '4' | '5' | 'NONE';
export type ClientResetCounterOptions = 'YEAR' | 'MONTH' | 'NONE';

export interface LedgerModalFormValues {
  dateFormat: ClientDateOption;
  prefix: string;
  separator: ClientSeparatorOption;
  counterSize: ClientCounterSizeOption;
  nextCounterNumber: number;
  resetCounterEvery: ClientResetCounterOptions;
}

// Converts existing ledgers
export const ExistingLedgerToFormValueConverter = {
  counterSizeOption(ledger?: ILedger): ClientCounterSizeOption {
    const counter = ledger?.config?.counter as GeneratorCounterConfig | undefined;

    if (counter?.size && counter.size <= 5) {
      return String(counter.size) as ClientCounterSizeOption;
    }
    return 'NONE';
  },

  dateOption(ledger?: ILedger): ClientDateOption {
    if (!ledger?.config || !ledger?.format) {
      return 'NONE';
    }

    const dateBlocks = Object.entries(ledger.config).filter(([, v]) => v.type === LEDGER_GENERATORS.DATE) as [
      string,
      GeneratorDateConfig,
    ][];

    if (dateBlocks.length > 0) {
      return (
        dateBlocks
          // If the date blocks are not defined in the same order in the config vs. format, this takes care of it
          .sort(([date1], [date2]) => ledger.format.indexOf(date1) - ledger.format.indexOf(date2))
          .map(([, v]) => v)
          .map((dateBlock) => dateBlock.format)
          .join('') as ClientDateOption
      );
    }
    return 'NONE';
  },

  resetCounterOption(ledger?: ILedger): ClientResetCounterOptions {
    const counter = ledger?.config?.counter as GeneratorCounterConfig | undefined;
    switch ((counter?.groupBy ?? []).length) {
      case 2:
        return 'MONTH';
      case 1:
        return 'YEAR';
      default:
        return 'NONE';
    }
  },

  nextCounterNumber(ledger?: ILedger): LedgerModalFormValues['nextCounterNumber'] {
    // e.g. {"["2023","02"]":3,"["2023","03"]":5,"["2023","01"]":2,"["2023","04"]":5,"["2023","05"]":4}
    const counterState = ledger?.state?.[ConfigBlock.COUNTER];

    if (!counterState) {
      return 1;
    }

    // e.g. [3, 5, 2, 5, 4]
    const counterStateGroups = Object.values(counterState) as number[];

    if (counterStateGroups.length === 0) {
      return 1;
    }

    // e.g. 4
    const currentCounterValue = counterStateGroups[counterStateGroups.length - 1]; // peek last value

    // +1 because we want a preview of the next and not the current state
    return currentCounterValue + 1;
  },

  separator(ledger?: ILedger): LedgerModalFormValues['separator'] {
    return ((ledger?.config?.separator as GeneratorStaticConfig)?.value as Separator) ?? 'NONE';
  },

  prefix(ledger?: ILedger): LedgerModalFormValues['prefix'] {
    return (ledger?.config?.prefix as GeneratorStaticConfig)?.value ?? '';
  },
};

const DateMapping: Record<ClientDateOption, DateFormat> = {
  NONE: [],
  MMYY: ['MM', 'YY'],
  YYMM: ['YY', 'MM'],
  YYYYMM: ['YYYY', 'MM'],
  MMYYYY: ['MM', 'YYYY'],
  YY: ['YY'],
  YYYY: ['YYYY'],
};

export function existingLedgerToFormValues(ledger?: ILedger): LedgerModalFormValues {
  return {
    separator: ExistingLedgerToFormValueConverter.separator(ledger),
    prefix: ExistingLedgerToFormValueConverter.prefix(ledger),
    nextCounterNumber: ExistingLedgerToFormValueConverter.nextCounterNumber(ledger),
    counterSize: ExistingLedgerToFormValueConverter.counterSizeOption(ledger),
    dateFormat: ExistingLedgerToFormValueConverter.dateOption(ledger),
    resetCounterEvery: ExistingLedgerToFormValueConverter.resetCounterOption(ledger),
  };
}

export function formValuesToLedger(
  { counterSize, dateFormat, nextCounterNumber, resetCounterEvery, prefix, separator }: Partial<LedgerModalFormValues>,
  isPreview: boolean,
) {
  return computeNewLedgerFromSettings({
    counterSize: counterSize !== 'NONE' ? Number(counterSize) : undefined,
    dateFormat: dateFormat ? DateMapping[dateFormat] : [],
    nextCounterValue:
      (nextCounterNumber || 1) + (isPreview /* if it's the preview, advance the state by one */ ? 1 : 0),
    resetCounterEvery: resetCounterEvery && resetCounterEvery !== 'NONE' ? resetCounterEvery : null,
    prefix: prefix || undefined,
    separator: separator !== 'NONE' ? separator : undefined,
  });
}
