import type { FC } from 'react';
import { useCallback, useLayoutEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { useOrder, useOrderDelete } from 'features/order/services/order.api';
import { OrderDetails } from 'features/order/components/OrderDetails/OrderDetails';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewOrderDetailScreen: FC = () => {
  const { t } = useTranslation(['orders', 'global']);
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const { createRedirectionWithSavedFilters } = useFiltersQuery();
  const { pathname } = useLocation();

  const { title, breadCrumb, updateHeaderTitle } = useHeaderContext();

  const order = useOrder(+id);

  const orderDeleteMutation = useOrderDelete();

  const goBackToListing = useCallback(() => {
    if (projectId) {
      createRedirectionWithSavedFilters(`/projects/${projectId}/purchases/orders`, { replace: true })();
    } else {
      createRedirectionWithSavedFilters('/purchases/orders', { replace: true })();
    }
  }, [createRedirectionWithSavedFilters, projectId]);

  const handleDeleted = useCallback(async () => {
    await orderDeleteMutation.mutateAsync(+id);
    goBackToListing();
  }, [orderDeleteMutation, id, goBackToListing]);

  useLayoutEffect(() => {
    updateHeaderTitle(
      order.data.name ?? '',
      projectId
        ? [
            ...breadCrumb,
            {
              name: title ?? '',
              link: `/projects/${projectId}`,
            },
            {
              name: t('global:nav.orders'),
              link: `/projects/${projectId}/purchases/orders`,
            },
          ]
        : [
            {
              name: t('global:nav.orders'),
              link: '/purchases/orders',
            },
          ],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, order.data.name, projectId, updateHeaderTitle, pathname]);

  return <OrderDetails order={order.data} onDeleted={handleDeleted} />;
};
