import type { FC } from 'react';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Section, useCurrency } from '@graneet/lib-ui';
import { Form, useStepForm } from 'graneet-form';
import { Link } from '@chakra-ui/react';
import type { IProject } from '@graneet/business-logic';

import type { FinalStatementEditionWizard } from 'features/final-statement/forms/final-statement-edition.wizard';
import { getPriceRevisionFieldName } from 'features/final-statement/forms/final-statement-edition.wizard';
import { useProjectSubProjectsWithStatements } from 'features/project/services/project.api';
import { FinalStatementPriceRevisionTable } from 'features/final-statement/components/FinalStatementPriceRevisionTable/FinalStatementPriceRevisionTable';
import { FinalStatementTotalAmount } from 'features/final-statement/components/FinalStatementTotalAmount';
import { URL_INDEXES_PRICE_REVISION } from 'features/progress-statement/services/progress-statement.constant';

interface ViewPriceRevisionStepProps {
  project: IProject;
}

export const ViewPriceRevisionStep: FC<ViewPriceRevisionStepProps> = ({ project }) => {
  const { t } = useTranslation(['project']);

  const { form, initFormValues } = useStepForm<FinalStatementEditionWizard, 'priceRevision'>();
  const { mapNumberToAmount } = useCurrency();

  const subProjectsWithStatements = useProjectSubProjectsWithStatements(project.id);

  // Default values
  useEffect(() => {
    const values = subProjectsWithStatements.data.subProjects.reduce<
      Partial<FinalStatementEditionWizard['priceRevision']>
    >((acc, subProject) => {
      if (!subProject.hasPriceRevision) {
        return acc;
      }

      subProject.progressStatements.forEach((progressStatement) => {
        const name = getPriceRevisionFieldName(progressStatement);
        const defaultValue = progressStatement.priceRevisionDefinitiveExVAT || progressStatement.priceRevisionExVAT;

        acc[name] = mapNumberToAmount(defaultValue);
      });

      return acc;
    }, {});
    initFormValues(values);
  }, [initFormValues, mapNumberToAmount, subProjectsWithStatements.data.subProjects]);

  return (
    <Form form={form}>
      <Section
        title={t('project:finalStatement.priceRevision.title')}
        description={
          <Trans t={t} i18nKey="project:finalStatement.priceRevision.descriptionStart">
            <Link color="greenBrand.light" href={URL_INDEXES_PRICE_REVISION} isExternal>
              description_link
            </Link>
          </Trans>
        }
      >
        <FinalStatementPriceRevisionTable subProjects={subProjectsWithStatements.data.subProjects} />
        <FinalStatementTotalAmount subProjects={subProjectsWithStatements.data.subProjects} />
      </Section>
    </Form>
  );
};
