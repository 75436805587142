import type { IHoldBackResponseDTO, HoldbackPaginatedResponse, IHoldbackWithRelations } from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import type { QueryClient } from '@tanstack/react-query';
import { useQuery, useSuspenseQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { type UsePaginationOptions, usePaginationQuery, useToast } from '@graneet/lib-ui';

import { apiNew } from 'features/api/services/apiNew.service';

const HOLDBACK_PATH = '/holdbacks';

const FACTORY_NAME = 'holdbacks';

const holdbackKeyFactory = createQueryKeys(FACTORY_NAME, {
  get: (queryParams: URLSearchParams) => ({
    queryKey: [HOLDBACK_PATH, queryParams],
    queryFn: () => apiNew.get<URLSearchParams, HoldbackPaginatedResponse>(HOLDBACK_PATH, queryParams),
  }),
  getOne: (id: number) => ({
    queryKey: [HOLDBACK_PATH, id],
    queryFn: () => apiNew.get<never, IHoldBackResponseDTO>(`${HOLDBACK_PATH}/${id}`),
  }),
});

async function invalidAllHoldbacksQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [FACTORY_NAME],
    exact: false,
  });
}

export function useHoldbacks(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) => queryClient.fetchQuery(holdbackKeyFactory.get(urlSearchParams)),
    options,
  );
}

export function useHoldbacksQuery(
  queryParams: URLSearchParams,
  options?: {
    enabled: boolean;
  },
) {
  return useQuery({
    ...holdbackKeyFactory.get(queryParams),
    ...options,
  });
}

export function useHoldback(id: number) {
  return useSuspenseQuery(holdbackKeyFactory.getOne(id));
}

export function useHoldbackMarkAsReceived() {
  const { t } = useTranslation(['global', 'holdback']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.patch<never, IHoldbackWithRelations>(`${HOLDBACK_PATH}/${id}/received`),
    onSuccess: async () => {
      await invalidAllHoldbacksQueries(queryClient);
      toast.success(t('global:words.c.success'), t('holdback:markAsReceivedModal.toastSuccess'));
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
  });
}

export function useHoldbackMarkAsToBeReceived() {
  const { t } = useTranslation(['global', 'holdback']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.patch<never, IHoldbackWithRelations>(`${HOLDBACK_PATH}/${id}/to-be-received`),
    onSuccess: async () => {
      await invalidAllHoldbacksQueries(queryClient);
      toast.success(t('global:words.c.success'), t('holdback:markAsToBeReceived.toastSuccess'));
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
  });
}
