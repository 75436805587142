import { Onboarding, SimpleAlertIcon, WorkerIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ErrorInfo } from '@graneet/business-logic';
import { ErrorIconOption, decodeErrorScreenHash } from '@graneet/business-logic';
import type { FC, ReactElement } from 'react';
import { Text } from '@chakra-ui/react';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

type GetDataReturn = { displayMessage: string; displayIcon: ReactElement };

/*
  TODO
  This component need to be refactored because how translation and icon injected are handled are not typesafe.
  Furthermore, if translation ns lazy loading is enable, this component will potentially have it translation broken.
  For now, some nasty cast are here to have an happy TS.
  A way to change how the component is to send to the client parameters with the error context. Ex:
  - Nothing, display "normal error
  - if there is type="worker", a condition error (we can also imagine having a second parameter to get server context
 */
const useErrorData = () => {
  const { t } = useTranslation(['global']);

  return {
    getData: (infos: ErrorInfo | undefined): GetDataReturn => {
      let displayMessage = t('global:errors.pageNotLoaded', { email: SUPPORT_EMAIL });

      const { message, icon, translationKey, translationParams } = infos || {};

      // icon
      const displayIcon = icon === ErrorIconOption.Worker ? <WorkerIcon /> : <SimpleAlertIcon boxSize={45} />;

      // message (if any)
      if (message) {
        displayMessage = message;
      }

      // translationKey (if any)
      if (translationKey) {
        displayMessage = t(translationKey as any, translationParams as any) as string;
      }

      return { displayIcon, displayMessage };
    },
  };
};

interface ErrorScreenProps {
  errorInfo?: ErrorInfo;
}

export const ErrorScreen: FC<ErrorScreenProps> = ({ errorInfo }) => {
  const { getData } = useErrorData();

  let displayData = getData(errorInfo);

  if (window.location.hash) {
    displayData = getData(decodeErrorScreenHash(window.location.hash.replace('#', '')));
  }

  const { displayIcon, displayMessage } = displayData;
  return (
    <Onboarding icon={displayIcon} h="100vh" textAlign="center">
      <Text mb={4} fontWeight="600">
        {displayMessage}
      </Text>
    </Onboarding>
  );
};
