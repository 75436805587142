import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

import {
  INCREMENTAL_QUERY,
  PAGE_QUERY,
  SEARCH_QUERY,
  SIZE_QUERY,
} from '../PaginatedData/constants/filters-and-pagination.constant';
import { DATE_FORMAT_API } from '../../utils/date.util';
import { isNumberFinite } from '../../utils/number.util';

import { useUrlPaginationStorage } from './useUrlPaginationStorage';
import type { Pagination } from './Pagination';
import { useStatePaginationStorage } from './useStatePaginationStorage';

type Filters = Record<string, any>;

const SIZE = 50;

export type UsePaginationOptions = {
  forcedFilters?: Filters;
  defaultFilters?: Filters;
  storageStrategy?: 'queryParams' | 'state';
};

export function usePagination(options?: UsePaginationOptions): Pagination {
  const usePaginatedStorage =
    options?.storageStrategy === 'state' ? useStatePaginationStorage : useUrlPaginationStorage;
  const { storage, resetFilters, updateFilters: updateFiltersStorage } = usePaginatedStorage(options?.defaultFilters);

  const updateSearch = useCallback(
    (search: string) => {
      const newStorage = storage;
      if (!search) {
        newStorage.delete(SEARCH_QUERY);
      } else {
        newStorage.set(SEARCH_QUERY, search);
      }

      updateFiltersStorage(newStorage);
    },
    [storage, updateFiltersStorage],
  );

  const updateFilters = useCallback(
    async (filters: Record<any, any>) => {
      const newStorage = new URLSearchParams();

      const clearQueryFilter = (filter: string) => {
        Array.from(newStorage.keys()).forEach((key) => {
          if (key.startsWith(filter)) {
            newStorage.delete(key);
          }
        });
      };

      const setArrayQuery = (filter: string, values: string[]) => {
        const isObjectArray = values.every((value) => typeof value === 'object');
        if (isObjectArray) {
          // We cannot replace old queryWithPaginationInfos params due to
          // indexes so we clear all filters parameters before
          clearQueryFilter(filter);
        }

        values.flat(1).forEach((value, i) => {
          // Nested object
          if (typeof value === 'object') {
            Object.keys(value).forEach((key) => {
              if (value[key] || isNumberFinite(value[key])) {
                newStorage.set(`${filter}[${i}][${key}]`, value[key]);
              }
            });

            return;
          }

          // String value
          if (i === 0) {
            newStorage.set(filter, value);
          } else {
            newStorage.append(filter, value);
          }
        });
      };

      const setNestedObjectQuery = (filter: string, values: Record<any, unknown>) => {
        Object.keys(values).forEach((key) => {
          const value =
            typeof values[key] === 'object' && values[key] instanceof Date
              ? // We know now that values[key] is a Date
                dayjs(values[key]).format(DATE_FORMAT_API) // Parse as simple date string
              : values[key];

          if (value) {
            newStorage.set(`${filter}[${key}]`, String(value));
          } else {
            newStorage.delete(`${filter}[${key}]`);
          }
        });
      };

      Object.entries(filters).forEach(([filter, values]) => {
        if (values && Array.isArray(values) && values.length > 0) {
          // Values array
          setArrayQuery(filter, values);
        } else if (typeof values === 'object' && !Array.isArray(values)) {
          // Nested object
          setNestedObjectQuery(filter, values);
        } else if (typeof values === 'string') {
          // String value
          newStorage.set(filter, values);
        } else {
          clearQueryFilter(filter);
        }
      });

      updateFiltersStorage(newStorage);
    },
    [updateFiltersStorage],
  );

  const queryParams = storage.toString();

  const size = parseInt(new URLSearchParams(queryParams).get(SIZE_QUERY) || SIZE.toString(), 10);

  const getParams = useCallback(
    (paginationParams: { page: number; sort: Record<string, string> }) => {
      const params = new URLSearchParams(queryParams);
      params.set(PAGE_QUERY, paginationParams.page.toString());
      params.set(INCREMENTAL_QUERY, 'true');
      params.set(SIZE_QUERY, size.toString());

      // Set forced query params
      Object.entries(options?.forcedFilters || {}).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          const existingFilters = params.getAll(key) || [];
          const intersection =
            existingFilters.length === 0 ? value : value.filter((v) => existingFilters.includes(String(v)));

          intersection.forEach((arrayValue, i) => {
            if (i === 0) {
              params.set(key, String(arrayValue));
            } else {
              params.append(key, String(arrayValue));
            }
          });
        } else {
          params.set(key, String(value));
        }
      });

      Object.entries(paginationParams.sort).forEach(([key, value]) => {
        params.set(key, value);
      });

      return params;
    },
    [options?.forcedFilters, queryParams, size],
  );

  return useMemo(
    () => ({
      updateSearch,
      queryParams,
      updateFilters,
      getParams,
      size,
      resetFilters,
    }),
    [updateSearch, queryParams, updateFilters, getParams, size, resetFilters],
  );
}
