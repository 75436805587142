import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { Card, RadioGroupField, SwitchField, TimePickerField, UnitField } from '@graneet/lib-ui';
import { Form, useForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { COMPANY_TT_APPROVAL_MODE, divideFloating, multiplyFloating } from '@graneet/business-logic';

import { useTimeTrackingSettings, useUpdateTimeTrackingSettings } from '../services/time-tracking-settings.api';

import { getHour } from 'features/time-tracking/components/modals/TimeSlotCreateModal/TimeSlotCreateModalForm';
import { Rule } from 'features/form/rules/Rule';

interface FormValues {
  ttShowWeekends?: boolean;

  ttNbMinutesWorkingDay: number;

  startHour: string;

  endHour: string;

  ttApprovalMode: COMPANY_TT_APPROVAL_MODE;
}

export const TimeDefaultsCard: FC = () => {
  const { t } = useTranslation(['timeTracking']);

  const timeTrackingSettings = useTimeTrackingSettings();

  const updateTimeTrackingSettingsMutation = useUpdateTimeTrackingSettings();

  const form = useForm<FormValues>({
    onUpdateAfterBlur: (_, __, ___, { getFormValues }) => {
      const formValues = getFormValues();
      updateTimeTrackingSettingsMutation.mutate({
        ttShowWeekends: formValues.ttShowWeekends!,
        ttNbMinutesWorkingDay: multiplyFloating(formValues.ttNbMinutesWorkingDay!, 60),
        ttStartHour: getHour(formValues.startHour)!,
        ttEndHour: getHour(formValues.endHour)!,
        ttApprovalMode: formValues.ttApprovalMode!,
      });
    },
    defaultValues: {
      ttShowWeekends: timeTrackingSettings.data.ttShowWeekends,
      ttNbMinutesWorkingDay: divideFloating(timeTrackingSettings.data.ttNbMinutesWorkingDay, 60),
      startHour: timeTrackingSettings.data.ttStartHour ? timeTrackingSettings.data.ttStartHour : '',
      endHour: timeTrackingSettings.data.ttEndHour ? timeTrackingSettings.data.ttEndHour : '',
      ttApprovalMode: timeTrackingSettings.data.ttApprovalMode,
    },
  });

  return (
    <Card title={t('timeTracking:settings.timeDefaultsCard.title')}>
      <Form form={form}>
        <Flex direction="column" gap={4}>
          <Box w="100%" color="gray.600">
            <Text mb={2}>{t('timeTracking:settings.fields.displayWeeksTitle')}</Text>
            <SwitchField<FormValues>
              name="ttShowWeekends"
              checkedValue
              uncheckedValue={false}
              label={<Text color="gray.500">{t('timeTracking:settings.fields.displayWeeksLabel')}</Text>}
            />
          </Box>

          <Box w="100%" color="gray.600">
            <HStack mb={2}>
              <Text>{t('timeTracking:settings.fields.workingHoursLabel')}</Text>
            </HStack>
            <Box w="5rem">
              <UnitField<FormValues> name="ttNbMinutesWorkingDay" min={0} unit="h">
                <Rule.IsRequired />
              </UnitField>
            </Box>
          </Box>

          <Flex gap={2} width="100%">
            <Box w="fit-content">
              <TimePickerField<FormValues> label={t('timeTracking:settings.fields.startHourLabel')} name="startHour" />
            </Box>
            <Box w="fit-content">
              <TimePickerField<FormValues> label={t('timeTracking:settings.fields.endHourLabel')} name="endHour" />
            </Box>
          </Flex>

          <RadioGroupField<FormValues> name="ttApprovalMode">
            <RadioGroupField.Option
              value={COMPANY_TT_APPROVAL_MODE.AUTO}
              label={t('timeTracking:settings.fields.approvalModeAutoLabel')}
            />
            <RadioGroupField.Option
              value={COMPANY_TT_APPROVAL_MODE.MANUAL}
              label={t('timeTracking:settings.fields.approvalModeManualLabel')}
            />
          </RadioGroupField>
        </Flex>
      </Form>
    </Card>
  );
};
