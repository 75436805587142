import { useCallback, type FC } from 'react';
import { Stack } from '@chakra-ui/react';
import {
  TwoColumns,
  usePaginatedData,
  ORDER_QUERY,
  DIRECTION_QUERY,
  SORTING_DIRECTIONS,
  STORAGE_STRATEGY,
  FULL_QUERY,
  Table,
  Progress,
  PriceAdvanced,
  SEARCH_QUERY,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IClientResponseDTO, IQuote } from '@graneet/business-logic';
import { BILL_STATUSES, FEATURE_FLAGS, PROJECT_STATUSES, QUOTE_STATUS } from '@graneet/business-logic';
import type { PaginatedResponse, Response } from '@graneet/lib-ui';
import type { QuotePaginatedDTO, QuoteWithClientAndAccountManagersDTO } from '@org/graneet-bff-client';

import { QuoteStatusBadge as QuoteStatusBadgeV1 } from 'features/quote/components/badges/QuoteStatusBadge';
import { QUOTE_FIELDS } from 'features/quote/constants/quotes.constant';
import { ClientStatementsCard } from 'features/client/components/summary/ClientStatementsCard';
import { ClientDashboardSummaryCard } from 'features/client/components/summary/ClientDashboardSummaryCard';
import { getQuotesPaginated } from 'features/quote/services/quote.api';
import { PROJECT_FIELDS } from 'features/project/constants/project.constant';
import { useClientStats } from 'features/client/services/client.api';
import { BILL_FIELDS } from 'features/bill/constants/bill.constant';
import { useBillsWithoutPagination } from 'features/bill/services/bill.api';
import { ClientCommentCard } from 'features/client/components/summary/ClientCommentCard';
import { BillLateDaysCell } from 'features/bill/components/BillLateDaysCell';
import { useProjectsWithoutPagination } from 'features/project/services/project.api';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { QuoteStatusBadge } from 'features/quotation/quote-common/components/badges/QuoteStatusBadge';

const FORCED_QUOTES_FILTERS = {
  [ORDER_QUERY]: QUOTE_FIELDS.STATUS,
  [DIRECTION_QUERY]: SORTING_DIRECTIONS.ASC,
  [QUOTE_FIELDS.STATUS]: [QUOTE_STATUS.DRAFT, QUOTE_STATUS.COMPLETED],
  [FULL_QUERY]: true,
};

const FORCED_PROJECTS_FILTERS = {
  [ORDER_QUERY]: PROJECT_FIELDS.AMOUNT_EX_VAT,
  [DIRECTION_QUERY]: SORTING_DIRECTIONS.DESC,
  [PROJECT_FIELDS.STATUS]: [PROJECT_STATUSES.ON_GOING],
  [FULL_QUERY]: true,
};

const FORCED_BILLS_FILTERS = {
  [ORDER_QUERY]: BILL_FIELDS.REMAINING_TO_BE_PAID_INC_VAT,
  [DIRECTION_QUERY]: SORTING_DIRECTIONS.ASC,
  [BILL_FIELDS.STATUS]: [BILL_STATUSES.AWAITING_PAYMENT, BILL_STATUSES.PARTIALLY_PAID],
  [FULL_QUERY]: true,
};

interface ClientSummaryTabProps {
  client: IClientResponseDTO;
}

export const ClientSummaryTab: FC<ClientSummaryTabProps> = ({ client }) => {
  const { t } = useTranslation(['global', 'clients', 'quote', 'project', 'bill']);

  const clientStats = useClientStats(client.id);

  const isQuoteV2Enabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);

  const searchParam = `?${SEARCH_QUERY}=${client.enterpriseName}`;

  const quoteProxy = useQuotationProxyApis().quoteProxyApi;

  const emptyGetterV1 = useCallback(
    () =>
      Promise.resolve<Response<PaginatedResponse<IQuote, 'amountExVAT'>>>([
        null,
        {
          data: [],
          metadata: {
            hasMore: false,
            totalCount: 0,
            unfilteredCount: 0,
            offsetV1: 0,
            offsetV2: 0,
          },
          sums: {
            amountExVAT: 0,
          },
        },
      ]),
    [],
  );
  const emptyGetterV2 = useCallback(
    () =>
      Promise.resolve<Response<QuotePaginatedDTO>>([
        null,
        {
          data: [],
          metadata: {
            hasMore: false,
            totalCount: 0,
            unfilteredCount: 0,
            offsetV1: 0,
            offsetV2: 0,
          },
          sums: {
            amountExVAT: 0,
          },
        },
      ]),
    [],
  );

  const getPaginatedQuotesGetter = useCallback(
    (params: URLSearchParams | undefined) => quoteProxy!.getQuotesPaginatedByCompanyId(params),
    [quoteProxy],
  );

  const quotesDataV1 = usePaginatedData(
    isQuoteV2Enabled ? emptyGetterV1 : getQuotesPaginated,
    {
      ...FORCED_QUOTES_FILTERS,

      [QUOTE_FIELDS.CLIENT]: client.id,
    },
    {},
    STORAGE_STRATEGY.STATE,
  );

  const quotesDataV2 = usePaginatedData(
    isQuoteV2Enabled ? getPaginatedQuotesGetter : emptyGetterV2,
    {
      ...FORCED_QUOTES_FILTERS,

      _clientId: client.id,
    },
    {},
    STORAGE_STRATEGY.STATE,
  );

  const projects = useProjectsWithoutPagination({
    ...FORCED_PROJECTS_FILTERS,
    [PROJECT_FIELDS.PRIMARY_CLIENT]: client.id,
  });

  const bills = useBillsWithoutPagination({
    ...FORCED_BILLS_FILTERS,
    [BILL_FIELDS.CLIENT]: client.id,
  });

  return (
    <TwoColumns>
      <Stack flex={1} spacing={4}>
        <ClientDashboardSummaryCard<IQuote | QuoteWithClientAndAccountManagersDTO>
          title={t('clients:summary.quotes.title')}
          data={(isQuoteV2Enabled ? quotesDataV2.data : quotesDataV1.data) ?? []}
          action={{
            title: t('clients:summary.quotes.action.title'),
            link: isQuoteV2Enabled ? `/opportunities/quotation${searchParam}` : `/opportunities/quotes${searchParam}`,
          }}
          numbers={[
            {
              value: isQuoteV2Enabled ? (quotesDataV2.totalCount ?? 0) : clientStats.data.nbPendingQuotes,
              title: t('clients:summary.quotes.numbers.total'),
              color: 'orange.500',
            },
            {
              value: isQuoteV2Enabled
                ? ((quotesDataV2.sums as { amountExVAT: number }).amountExVAT ?? 0)
                : clientStats.data.pendingQuotesAmountExVAT,
              title: t('clients:summary.quotes.numbers.amount'),
              isPrice: true,
            },
          ]}
          table={{
            renderHeader: () => (
              <>
                <Table.Cell>{t('quote:fields.name')}</Table.Cell>
                <Table.Cell center>{t('quote:fields.status')}</Table.Cell>
                <Table.Cell right>{t('quote:fields.amountExVAT')}</Table.Cell>
              </>
            ),
            renderRow: (quote) => (
              <Table.Row
                key={quote.id}
                to={
                  typeof quote.id === 'string'
                    ? `/opportunities/quotation/${quote.id}/summary`
                    : `/opportunities/quotes/${quote.id}`
                }
              >
                <Table.Cell variant="ellipsis">{quote.name}</Table.Cell>
                <Table.Cell center>
                  {isQuoteV2Enabled ? (
                    <QuoteStatusBadge quote={quote as QuoteWithClientAndAccountManagersDTO} />
                  ) : (
                    <QuoteStatusBadgeV1 quote={quote as IQuote} />
                  )}
                </Table.Cell>
                <Table.Cell right>
                  {isQuoteV2Enabled ? (
                    <PriceAdvanced
                      amount={parseFloat((quote as QuoteWithClientAndAccountManagersDTO).amountExVAT ?? '0')}
                    />
                  ) : (
                    <PriceAdvanced amount={(quote as IQuote).amountExVAT} />
                  )}
                </Table.Cell>
              </Table.Row>
            ),
            templateColumns: ['minmax(11rem, 2fr)', '8rem', '8rem'],
          }}
        />
        <ClientDashboardSummaryCard
          title={t('clients:summary.projects.title')}
          data={projects.data?.data ?? []}
          action={{
            title: t('clients:summary.projects.action.title'),
            link: `/projects${searchParam}`,
          }}
          numbers={[
            {
              value: clientStats.data.nbOngoingProjects,
              title: t('clients:summary.projects.numbers.total'),
            },
            {
              value: clientStats.data.ongoingProjectsAmountToBeBilledExVAT,
              title: t('clients:summary.projects.numbers.amount'),
              isPrice: true,
            },
          ]}
          table={{
            renderHeader: () => (
              <>
                <Table.Cell>{t('project:fields.name')}</Table.Cell>
                <Table.Cell center>{t('project:fields.progress')}</Table.Cell>
                <Table.Cell right>{t('project:fields.totalAmountExVAT')}</Table.Cell>
              </>
            ),
            renderRow: (project) => (
              <Table.Row key={project.id} to={`/projects/${project.id}`}>
                <Table.Cell variant="ellipsis">{project.name}</Table.Cell>
                <Table.Cell>
                  <Progress value={project.cumulativeProgressPercentage} />
                </Table.Cell>
                <Table.Cell right>
                  <PriceAdvanced amount={project.amountExVAT} />
                </Table.Cell>
              </Table.Row>
            ),
            templateColumns: ['minmax(11rem, 2fr)', '10rem', '9rem'],
          }}
        />
      </Stack>
      <Stack flex={1} spacing={4}>
        <ClientCommentCard client={client} />
        <ClientStatementsCard
          amount={clientStats.data.totalBilledAmountExVAT}
          link={`/sales/statements${searchParam}`}
        />
        <ClientDashboardSummaryCard
          title={t('clients:summary.pendingBills.title')}
          data={bills.data?.data ?? []}
          action={{
            title: t('clients:summary.pendingBills.action.title'),
            link: `/sales/bills${searchParam}`,
          }}
          numbers={[
            {
              value: clientStats.data.remainingAmountToBePaidExVAT,
              title: t('clients:summary.pendingBills.numbers.total'),
              isPrice: true,
              color: 'orange.500',
            },
          ]}
          table={{
            renderHeader: () => (
              <>
                <Table.Cell>{t('bill:fields.name')}</Table.Cell>
                <Table.Cell center>{t('bill:fields.lateDays')}</Table.Cell>
                <Table.Cell right>{t('bill:fields.remainingToBePaidIncVAT')}</Table.Cell>
              </>
            ),
            renderRow: (bill) => (
              <Table.Row key={bill.id} to={`/sales/bills?focusedId=${bill.id}`}>
                <Table.Cell variant="ellipsis">{bill.name}</Table.Cell>
                <BillLateDaysCell bill={bill} />
                <Table.Cell right>
                  <PriceAdvanced amount={bill.remainingToBePaidIncVAT} />
                </Table.Cell>
              </Table.Row>
            ),
            templateColumns: ['minmax(10rem, 1fr)', '7rem', '10rem'],
          }}
        />
      </Stack>
    </TwoColumns>
  );
};
