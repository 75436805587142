import { useCallback } from 'react';

import * as jobsApi from '../services/quote-job.api';

import { useJobMoveToast } from './useJobMoveToast';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useMoveJobBeforeOtherJob = () => {
  const showJobMoveToast = useJobMoveToast();

  const { moveJobBeforeOtherJobLocally, startAnotherUpdate, updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (sourceJobId: number, targetPreviousJobId: number, isParentHiddenCost = false) => {
      let moveInfos;
      try {
        moveInfos = moveJobBeforeOtherJobLocally(sourceJobId, targetPreviousJobId);
        if (!moveInfos) {
          return;
        }
      } catch (err) {
        showJobMoveToast({ isError: true });
        return;
      }
      const { previousJobId, parentLotId } = moveInfos;
      startAnotherUpdate();
      const [err, infos] = await jobsApi.moveJob(sourceJobId, {
        previousJobId,
        parentLotId,
        isParentHiddenCost,
      });
      if (err) {
        showJobMoveToast({ isError: true });
        return;
      }
      updateDataLocally(infos);
      showJobMoveToast({ isError: false });
    },
    [startAnotherUpdate, updateDataLocally, moveJobBeforeOtherJobLocally, showJobMoveToast],
  );
};
