import { Trans, useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useMemo } from 'react';
import { DeletionSection } from '@graneet/lib-ui';
import type { IClientResponseDTO } from '@graneet/business-logic';
import { getEntitiesPreventingDeletion } from '@graneet/business-logic';
import { Text } from '@chakra-ui/react';

import { ClientEntitiesImpactedCallout } from '../ClientEntitiesImpactedCallout';

interface ClientDeletionSectionProps {
  client: IClientResponseDTO;
  onClientDeleted: () => void;
}

export const ClientDeletionSection: FC<ClientDeletionSectionProps> = ({ client, onClientDeleted }) => {
  const { t } = useTranslation(['clients']);

  const entitiesPreventingDeletion = useMemo(() => (client ? getEntitiesPreventingDeletion(client) : []), [client]);
  const isDeletionDisabled = entitiesPreventingDeletion.length > 0;

  const modalAdditionalContent = useMemo(
    () => (
      <>
        {entitiesPreventingDeletion.length > 0 && (
          <ClientEntitiesImpactedCallout entities={entitiesPreventingDeletion} colorScheme="yellow">
            <Trans t={t} i18nKey="clients:cards.delete.callout" count={entitiesPreventingDeletion.length}>
              <Text as="span" fontWeight={600}>
                displayed_data
              </Text>
              description_end
            </Trans>
          </ClientEntitiesImpactedCallout>
        )}
      </>
    ),
    [entitiesPreventingDeletion, t],
  );

  const tooltip = useMemo(() => {
    if (!isDeletionDisabled) {
      return undefined;
    }
    return t('clients:cards.delete.tooltip');
  }, [isDeletionDisabled, t]);

  return (
    <DeletionSection
      action={t('clients:cards.delete.action')}
      title={t('clients:cards.delete.title')}
      description={t('clients:cards.delete.description')}
      confirmationMessage={t('clients:cards.delete.modalConfirmWithContacts')}
      modalActionTooltip={tooltip}
      isModalActionDisabled={isDeletionDisabled}
      modalAdditionalContent={modalAdditionalContent}
      onDeleted={onClientDeleted}
      isCentered={!isDeletionDisabled}
    />
  );
};
