import type { IComponentType } from '@graneet/business-logic';

export type QuantityFieldName = `quantity-${number}`;

export const getQuantityFieldName = (libraryComponentId: number): QuantityFieldName => `quantity-${libraryComponentId}`;

export const isQuantityFieldName = (fieldName: string) => /^quantity-/.test(fieldName);

export interface LibraryJobEditCardForm {
  refCode?: string;

  description: string;

  unit: string;

  type: IComponentType;

  unitDisbursementExVAT: number;

  [quantityFielName: QuantityFieldName]: number | null;
}
