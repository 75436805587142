import { AbsoluteCenter, Box, Divider, Flex, Text } from '@chakra-ui/react';
import { THIRD_PARTY } from '@graneet/business-logic';
import {
  EmptyStateCard,
  ImportFromBatiprixEmptyState,
  ImportFromLibraryEmptyState,
  SimpleAddRowIcon,
  SimpleFolderIcon,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { getShortcut } from '../modals/ShortcutsModal';
import { useQuote } from '../../hooks/useQuote';

import { useThirdParty } from 'features/third-party/hooks/useThirdParty';
import { useQuoteBasicItemCreate } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemCreate';
import { useQuoteLotCreate } from 'features/quotation/quote-lot/hooks/useQuoteLotCreate';
import { useStore } from 'store/store';
import {
  updateQuoteBatiprixModalIsOpen,
  updateQuoteBatiprixModalParentId,
  updateQuoteImportItemModalIsOpen,
  updateQuoteImportItemTargetParentId,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { QUOTATION_SHORTCUTS, useQuoteHotKeys } from 'features/quotation/quote-hot-key/hooks/useQuoteHotKeys';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { umamiTrackEvent } from 'features/app/hooks/umami/umamiTrackEvent';

export const QuoteEmptyStateCard = () => {
  const isEditable = useStore((store) => store.quoteEditable);

  const hasBatiprix = useThirdParty(THIRD_PARTY.BATIPRIX);
  const { t } = useTranslation(['global', 'quote']);

  const quoteBasicItemCreate = useQuoteBasicItemCreate();
  const quoteLotCreate = useQuoteLotCreate();

  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  useQuoteHotKeys();

  return isEditable ? (
    <Flex
      h="100%"
      justifyContent="center"
      gap={4}
      background="white"
      boxShadow="0px 2px 4px 0px rgba(17, 24, 28, 0.12), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px rgba(17, 24, 28, 0.08)"
      borderRadius="8px"
    >
      <Flex direction="column" justifyContent="center" h="100%" gap={6}>
        <Box position="relative">
          <Divider />
          <AbsoluteCenter bg="white" px="4">
            <Text whiteSpace="nowrap" color="gray.500">
              {t('quote:emptyState.importFrom')}
            </Text>
          </AbsoluteCenter>
        </Box>

        <Flex gap={6} justifyContent="center" alignItems="center" flexDirection={hasBatiprix ? 'row' : 'column'}>
          <ImportFromLibraryEmptyState
            onClick={() => {
              updateQuoteImportItemModalIsOpen(true);
              updateQuoteImportItemTargetParentId(null);
            }}
          />
          {hasBatiprix && (
            <ImportFromBatiprixEmptyState
              onClick={() => {
                updateQuoteBatiprixModalIsOpen(true);
                updateQuoteBatiprixModalParentId(null);
              }}
            />
          )}
        </Flex>

        <Box position="relative">
          <Divider />
          <AbsoluteCenter bg="white" px="4">
            <Text whiteSpace="nowrap" color="gray.500">
              {t('quote:emptyState.createFrom')}
            </Text>
          </AbsoluteCenter>
        </Box>

        <Flex justifyContent="center" alignItems="center" flexDirection={hasBatiprix ? 'row' : 'column'} gap={6}>
          <EmptyStateCard
            icon={<SimpleFolderIcon />}
            title={t('quote:emptyState.cards.newLot.title')}
            shortcut={getShortcut(QUOTATION_SHORTCUTS.QUOTE_LOT.shortCut).join(' ').toUpperCase()}
            onClick={() => executeCommand(quoteLotCreate(null), quote)}
          />
          <EmptyStateCard
            icon={<SimpleAddRowIcon />}
            title={t('quote:emptyState.cards.newItem.title')}
            shortcut={getShortcut(QUOTATION_SHORTCUTS.QUOTE_BASIC_ITEM.shortCut).join(' ').toUpperCase()}
            onClick={() => {
              executeCommand(quoteBasicItemCreate(null), quote);
              umamiTrackEvent({
                feature: 'quotation',
                fromZone: 'emptyStateCard',
                action: 'create',
                entity: 'basicItem',
              });
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  ) : null;
};
