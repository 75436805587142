import type { FlexProps } from '@chakra-ui/react';

import { useRowContext } from '../../contexts/RowContext';

import { ShadowCell } from './ShadowCell';
import { RawCell } from './RawCell';
import { HeaderCell } from './HeaderCell';
import { EllipsisCell } from './EllipsisCell';
import { ShadowExtendedCell } from './ShadowCellExtended';
import type {
  CellProps,
  CellVariant,
  EllipsisCellProps,
  HeaderCellProps,
  RawCellProps,
  ShadowCellProps,
} from './Cell.type';

export const Cell = <T extends CellVariant>({ children = null, variant = 'default', ...rest }: CellProps<T>) => {
  const rowContext = useRowContext();

  if (variant === 'shadow') {
    return (
      <ShadowCell {...(rowContext as ShadowCellProps)} {...(rest as ShadowCellProps)}>
        {children}
      </ShadowCell>
    );
  }

  if (variant === 'shadow_extended') {
    return (
      <ShadowExtendedCell {...(rowContext as FlexProps)} {...(rest as FlexProps)}>
        {children}
      </ShadowExtendedCell>
    );
  }

  if (variant === 'ellipsis') {
    return (
      <EllipsisCell {...(rowContext as EllipsisCellProps)} {...(rest as EllipsisCellProps)}>
        {children}
      </EllipsisCell>
    );
  }

  if (variant === 'header' || rowContext.variant === 'header') {
    return (
      <HeaderCell userSelect="none" {...(rowContext as HeaderCellProps)} {...(rest as HeaderCellProps)}>
        {children}
      </HeaderCell>
    );
  }

  return (
    <RawCell {...(rowContext as RawCellProps)} {...(rest as RawCellProps)}>
      {children}
    </RawCell>
  );
};
