import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Flex, Text, VStack, Grid, Circle } from '@chakra-ui/react';
import type { DrawerApi } from '@graneet/lib-ui';
import {
  SingleSelectField,
  Button,
  Callout,
  DrawersStack,
  PercentageField,
  TextField,
  formatVatRateToNumber,
  useCurrency,
  useToast,
} from '@graneet/lib-ui';
import { v4 as uuid } from 'uuid';
import {
  useForm,
  useFormStatus,
  useFormContext,
  HiddenField,
  useHiddenField,
  Form,
  useOnChangeValues,
} from 'graneet-form';
import { isNil } from 'lodash-es';
import type { CustomDiscountInvoiced } from '@graneet/business-logic';
import { CUSTOM_DISCOUNT_TYPES, FEATURE_FLAGS } from '@graneet/business-logic';

import type { UpdateStatus } from '../../contexts/ContractRuleContext';
import { UPDATE_STATUS_SUCCESS, useContractRuleContext } from '../../contexts/ContractRuleContext';
import type { ContractEditionForm } from '../../forms/contract-edition.form';

import { CustomDiscountTotals } from 'features/custom-discount/components/CustomDiscountTotals';
import type {
  CustomDiscountEditionForm,
  NegativeMultiplier,
  PositiveMultiplier,
} from 'features/custom-discount/forms/custom-discount-edition-form';
import { NEGATIVE_MULTIPLIER, POSITIVE_MULTIPLIER } from 'features/custom-discount/forms/custom-discount-edition-form';
import { Rule } from 'features/form/rules/Rule';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';

interface ContractCustomDiscountDrawerProps {
  totalAmountWithDiscountExVAT: number;
  drawer: DrawerApi<string>;
  // In case of edition customDiscountId is required
  customDiscountId?: string;
  initialCustomDiscountFormValues: Partial<CustomDiscountEditionForm> | null;
  customDiscountsInvoiced?: CustomDiscountInvoiced;
}

export const ContractCustomDiscountDrawer: FC<ContractCustomDiscountDrawerProps> = ({
  totalAmountWithDiscountExVAT,
  customDiscountId,
  initialCustomDiscountFormValues,
  drawer,
  customDiscountsInvoiced,
}) => {
  const { t } = useTranslation(['contracts', 'quote', 'customDiscount']);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const { canCustomDiscountBeUpdated, contractInfos } = useContractRuleContext();
  const [contractRuleResult, setContractRuleResult] = useState<UpdateStatus>(UPDATE_STATUS_SUCCESS);

  const initialDiscount = contractInfos?.contract.discount;

  const globalForm = useFormContext<ContractEditionForm>();
  const { customDiscounts: rawCustomDiscounts } = useOnChangeValues(globalForm, ['customDiscounts']);
  const { discountAmountExVAT: globalDiscountAmountExVAT } = globalForm.getFormValues();

  const hasDiscount = useMemo(
    () => !(isNil(globalDiscountAmountExVAT) && isNil(initialDiscount)),
    [globalDiscountAmountExVAT, initialDiscount],
  );

  const customDiscountForm = useForm<CustomDiscountEditionForm>();
  const { isValid: isFormValid } = useFormStatus(customDiscountForm);

  const customDiscountFormValues = useOnChangeValues(customDiscountForm, undefined);

  const customDiscountTypeHiddenField = useHiddenField(customDiscountForm, 'customDiscountType');

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  useEffect(() => {
    let multiplier: PositiveMultiplier | NegativeMultiplier = POSITIVE_MULTIPLIER;

    if (initialCustomDiscountFormValues?.customDiscountAmountExVAT) {
      multiplier =
        initialCustomDiscountFormValues.customDiscountAmountExVAT > 0 ? POSITIVE_MULTIPLIER : NEGATIVE_MULTIPLIER;
    }

    if (initialCustomDiscountFormValues?.customDiscountPercentage) {
      multiplier =
        initialCustomDiscountFormValues.customDiscountPercentage > 0 ? POSITIVE_MULTIPLIER : NEGATIVE_MULTIPLIER;
    }

    customDiscountForm.resetForm();
    customDiscountForm.setFormValues({
      ...initialCustomDiscountFormValues,
      customDiscountMultiplier: multiplier,
      customDiscountAmountExVAT: initialCustomDiscountFormValues?.customDiscountAmountExVAT
        ? initialCustomDiscountFormValues.customDiscountAmountExVAT * multiplier
        : undefined,
      customDiscountPercentage: initialCustomDiscountFormValues?.customDiscountPercentage
        ? initialCustomDiscountFormValues.customDiscountPercentage * multiplier
        : undefined,
    });
  }, [customDiscountForm, initialCustomDiscountFormValues]);

  const toast = useToast();

  const customDiscountsValues = useMemo(() => {
    const { customDiscountName, customDiscountVatRate, customDiscountAmountExVAT, customDiscountMultiplier } =
      customDiscountFormValues;

    if (isNil(customDiscountAmountExVAT) || isNil(customDiscountVatRate)) return [];

    // Edition
    if (customDiscountId && rawCustomDiscounts) {
      const updatedCustomDiscounts = rawCustomDiscounts.map((customDiscount) =>
        customDiscount.id !== customDiscountId
          ? customDiscount
          : {
              id: customDiscountId,
              name: customDiscountName!,
              vatRate: formatVatRateToNumber(customDiscountVatRate!),
              amountExVAT: !isNil(customDiscountAmountExVAT)
                ? mapAmountToNumber(customDiscountAmountExVAT * (customDiscountMultiplier || POSITIVE_MULTIPLIER))
                : null,
              type: CUSTOM_DISCOUNT_TYPES.AMOUNT,
              percentage: null,
            },
      );
      return updatedCustomDiscounts;
    }

    // Creation
    const newCustomDiscount = {
      id: uuid(),
      name: customDiscountName!,
      vatRate: formatVatRateToNumber(customDiscountVatRate!),
      amountExVAT: !isNil(customDiscountAmountExVAT)
        ? mapAmountToNumber(customDiscountAmountExVAT * (customDiscountMultiplier || POSITIVE_MULTIPLIER))
        : null,
      type: CUSTOM_DISCOUNT_TYPES.AMOUNT,
      percentage: null,
    };

    return [...(rawCustomDiscounts ?? []), newCustomDiscount];
  }, [customDiscountFormValues, customDiscountId, mapAmountToNumber, rawCustomDiscounts]);

  const isOnError = useCallback(() => {
    const customDiscountisInvoiced = customDiscountsInvoiced?.[customDiscountId ?? '']?.isInvoiced;
    const customDiscountInvoicedAmountExVAT = customDiscountsInvoiced?.[customDiscountId ?? '']?.invoicedAmountExVAT;
    const { customDiscountAmountExVAT } = customDiscountFormValues;

    if (
      customDiscountisInvoiced === undefined ||
      customDiscountInvoicedAmountExVAT === undefined ||
      customDiscountAmountExVAT === undefined
    ) {
      return false;
    }

    const invoicedAmountExVAT = mapNumberToAmount(customDiscountInvoicedAmountExVAT);

    const previousCustomDiscountAmountExVAT = rawCustomDiscounts?.find(
      ({ id }) => id === customDiscountId,
    )?.amountExVAT;

    if (invoicedAmountExVAT >= 0 && customDiscountAmountExVAT < invoicedAmountExVAT) {
      toast.error(t('contracts:toasts.cannotUpdateUnitPrice'));
      customDiscountForm.setFormValues({
        customDiscountAmountExVAT: previousCustomDiscountAmountExVAT
          ? mapNumberToAmount(previousCustomDiscountAmountExVAT)
          : undefined,
      });
      return true;
    }

    if (invoicedAmountExVAT < 0 && customDiscountAmountExVAT < invoicedAmountExVAT * NEGATIVE_MULTIPLIER) {
      toast.error(t('contracts:toasts.cannotUpdateUnitPrice'));
      customDiscountForm.setFormValues({
        customDiscountAmountExVAT: previousCustomDiscountAmountExVAT
          ? mapNumberToAmount(previousCustomDiscountAmountExVAT * NEGATIVE_MULTIPLIER)
          : undefined,
      });
      return true;
    }

    return false;
  }, [
    customDiscountForm,
    customDiscountFormValues,
    customDiscountId,
    customDiscountsInvoiced,
    mapNumberToAmount,
    rawCustomDiscounts,
    t,
    toast,
  ]);

  const onFieldBlur = useCallback(() => {
    const { customDiscountName } = customDiscountFormValues;

    if (isOnError()) {
      return;
    }

    const rule = canCustomDiscountBeUpdated(customDiscountsValues, customDiscountName!);
    setContractRuleResult(rule);
  }, [canCustomDiscountBeUpdated, customDiscountFormValues, customDiscountsValues, isOnError]);

  const onSubmit = useCallback(() => {
    globalForm.setFormValues({
      customDiscounts: customDiscountsValues,
    });
    drawer.onClose();
  }, [customDiscountsValues, drawer, globalForm]);

  return (
    <DrawersStack.Drawer
      title={t('contracts:discounts.drawers.customDiscount.title')}
      drawer={drawer}
      footer={
        <Flex justify="flex-end" w="100vw">
          <Button onClick={onSubmit} isDisabled={!isFormValid || !contractRuleResult.canUpdate}>
            {t('contracts:discounts.drawers.customDiscount.applyCustomDiscount')}
          </Button>
        </Flex>
      }
    >
      <Form form={customDiscountForm}>
        <VStack alignItems="stretch" spacing={8}>
          <Text>{t('quote:discounts.drawers.customDiscount.description')}</Text>

          <HiddenField {...customDiscountTypeHiddenField} />

          <Grid templateColumns="2fr 4fr 2fr" gap={3}>
            <SingleSelectField<CustomDiscountEditionForm>
              name="customDiscountMultiplier"
              label={t('customDiscount:fields.signType')}
              isDisabled={customDiscountsInvoiced?.[customDiscountId ?? '']?.isInvoiced ?? false}
              options={[
                {
                  value: POSITIVE_MULTIPLIER,
                  label: (
                    <Flex gap={2} alignItems="center">
                      <Circle size="0.725rem" bg="green.500" />
                      {t('customDiscount:fields.customDiscountIncreaseValue')}
                    </Flex>
                  ),
                },
                {
                  value: NEGATIVE_MULTIPLIER,
                  label: (
                    <Flex gap={2} alignItems="center">
                      <Circle size="0.725rem" bg="red.500" />
                      {t('customDiscount:fields.customDiscountDecreaseValue')}
                    </Flex>
                  ),
                },
              ]}
              isClearable={false}
              isSearchable={false}
            />
            <TextField<CustomDiscountEditionForm>
              name="customDiscountName"
              label={t('customDiscount:fields.name')}
              isRequired
            >
              <Rule.IsRequired />
            </TextField>

            {hasStandardVATRates ? (
              <AccountingVATField<CustomDiscountEditionForm>
                isDisabled={customDiscountsInvoiced?.[customDiscountId ?? '']?.isInvoiced ?? false}
                label={t('customDiscount:fields.vatRate')}
                isClearable={false}
                name="customDiscountVatRate"
                valueScale={100}
              >
                <Rule.IsRequired />
              </AccountingVATField>
            ) : (
              <PercentageField<CustomDiscountEditionForm>
                name="customDiscountVatRate"
                label={t('customDiscount:fields.vatRate')}
                min={0}
                isRequired
                isDisabled={customDiscountsInvoiced?.[customDiscountId ?? '']?.isInvoiced ?? false}
              >
                <Rule.IsRequired />
              </PercentageField>
            )}
          </Grid>

          <Stack mt={8} spacing={4}>
            {!contractRuleResult.canUpdate && <Callout colorScheme="red">{contractRuleResult.errorMessage}</Callout>}
            <CustomDiscountTotals
              totalBeforeCustomDiscountExVAT={totalAmountWithDiscountExVAT}
              hasDiscount={hasDiscount}
              onFieldBlur={onFieldBlur}
            />
          </Stack>
        </VStack>
      </Form>
    </DrawersStack.Drawer>
  );
};
