import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Wizard } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { EditUserStep } from './_steps/EditUserStep';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import type { UserWizardValues, UserFormValues } from 'features/user/constants/user.constant';
import { useEditUser, useUser } from 'features/user/services/user.api';

export const EditUserScreen: FC = () => {
  useHideNavbar();

  const { t } = useTranslation(['global', 'user']);
  const history = useHistory();

  const { userId: rawUserId } = useParams<{ userId: string }>();
  const userId = parseInt(rawUserId, 10);

  const modal = useDisclosure();
  const { onClose } = modal;
  const userInformationRef = useRef<UserFormValues>();

  const user = useUser(userId);

  const editUserMutation = useEditUser();

  const wizardLabels = useWizardLabels({
    save: t('global:words.c.validate'),
  });

  const goBackToUsers = useCallback(() => {
    history.push('/settings/company/users');
  }, [history]);

  const submitForm = useCallback(async () => {
    if (!userInformationRef.current) return;
    const { role: roleId, ...userInformation } = userInformationRef.current;
    const onSuccess = () => {
      onClose();
      goBackToUsers();
    };
    const dto = {
      ...userInformation,
      roleId,
    };

    await editUserMutation.mutateAsync({ id: userId, dto }, { onSuccess });
  }, [userId, onClose, goBackToUsers, editUserMutation]);

  const handleWizardFinish = useCallback(
    async (wizardFormValues: UserWizardValues) => {
      userInformationRef.current = wizardFormValues['edit-user'];
      await submitForm();
    },
    [submitForm],
  );

  return (
    <>
      <Wizard<UserWizardValues>
        headerTitle={t('user:wizard.titleEditUser')}
        onQuit={goBackToUsers}
        onFinish={handleWizardFinish}
        labels={wizardLabels}
      >
        <Wizard.Step<UserWizardValues> name="edit-user">
          <QueryWrapper>
            <EditUserStep user={user.data} />
          </QueryWrapper>
        </Wizard.Step>
      </Wizard>
    </>
  );
};
