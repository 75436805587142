import { useTranslation } from 'react-i18next';
import { Flex, Box, SimpleGrid, useDisclosure, Text } from '@chakra-ui/react';
import { CardButtons, Card, LabeledData, Address, GotoLink, SimpleEditIcon } from '@graneet/lib-ui';
import type { IClientResponseDTO, IClient } from '@graneet/business-logic';
import type { FC, ReactNode } from 'react';

import { ClientUpsertModal } from './modals/ClientUpsertModal';

interface ClientDetailCardProps {
  client: IClientResponseDTO | IClient;
  title: string | null;
  isUpdatable?: boolean;
  displayGoToLink?: boolean;
  footer?: ReactNode;
  modalTitle?: string;
  modalSubmitButtonLabel?: string;
}

export const ClientDetailCard: FC<ClientDetailCardProps> = ({
  client,
  title,
  isUpdatable,
  displayGoToLink,
  footer,
  modalTitle,
  modalSubmitButtonLabel,
}) => {
  const { t } = useTranslation(['global', 'clients']);
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Box>
      <Card
        title={
          <Flex>
            <Text mr={4}>{title}</Text>
            {displayGoToLink && <GotoLink to={`/contacts/clients/${client.id}`} label={t('clients:goToLink')} />}
          </Flex>
        }
        topRightContent={
          isUpdatable && (
            <CardButtons
              primaryAction={{
                onClick: onOpen,
                title: t('clients:editButton'),
                icon: SimpleEditIcon,
              }}
            />
          )
        }
      >
        <SimpleGrid columns={{ md: 2 }} spacing={5}>
          <LabeledData label={t('clients:fields.enterpriseName')} data={client.enterpriseName} />
          <LabeledData label={t('global:address.address')} data={<Address oneLine address={client.address ?? {}} />} />
        </SimpleGrid>
        {footer}
      </Card>

      <ClientUpsertModal
        isOpen={isOpen}
        client={client}
        title={modalTitle || t('clients:editClient')}
        description={t('clients:clientModalEditor.editionDescription')}
        onClose={onClose}
        submitButtonLabel={modalSubmitButtonLabel || t('global:words.c.update')}
      />
    </Box>
  );
};
