import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { HiddenField, useFormContext, useHiddenField, useOnChangeValues } from 'graneet-form';
import {
  ActionMenu,
  Callout,
  Card,
  CheckboxField,
  CurrencyField,
  LabeledData,
  PercentageField,
  Price,
  SimpleAlertIcon,
  SimpleHelpIcon,
  SimpleHiddenCostIcon,
  SimpleSettingsIcon,
  Tooltip,
  useCurrency,
} from '@graneet/lib-ui';
import type { IVatBases } from '@graneet/business-logic';
import {
  computeOrderAmountIncVAT,
  computeOrderAmounts,
  FEATURE_FLAGS,
  isNumberFinite,
  PROGRESS_STATEMENT_STATUSES,
  sumObjects,
  VAT_TYPE,
} from '@graneet/business-logic';
import { isNil } from 'lodash-es';
import { useParams } from 'react-router-dom';

import type { OrderEditForm } from '../../forms/order-edit-wizard';
import type { OrderTree } from '../../hooks/useOrderTree';
import { useCurrentOrderVATDistribution } from '../../hooks/useCurrentOrderVATDistribution';
import { OrderVATRatesLabel } from '../OrderVATRatesLabel';
import { useOrderDirectPaymentInfosContext } from '../../contexts/OrderDirectPaymentInfosContext';
import { OrderOpenItemSelectionButton } from '../buttons/OrderOpenItemSelectionButton/OrderOpenItemSelectionButton';

import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { Rule } from 'features/form/rules/Rule';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface ErrorMessageProps {
  numberOfIncompleteItems: number;

  sumSupplierInvoicesAmountExVAT: number | null;
}

interface VATRateFieldProps {
  isAmountEditable: boolean;

  isAmountRequired: boolean;

  vatBases: IVatBases;

  tree: OrderTree;
}

const VATRateField: FC<VATRateFieldProps> = ({ isAmountEditable, isAmountRequired, vatBases, tree }) => {
  const { t } = useTranslation(['orders']);

  const form = useFormContext<OrderEditForm>();
  const vatRateHiddenField = useHiddenField(form, 'vatRate');
  const vatTypeHiddenField = useHiddenField(form, 'vatType');

  const onChangeVATType = useCallback(
    (newVATType: VAT_TYPE) => {
      // Calculate vat distribution if the reversal of liability is deactivated
      if (vatTypeHiddenField.value !== VAT_TYPE.NORMAL) {
        const displayedItems = tree.getDisplayedCurrentTree().leaves;
        const computedValuesOfDisplayedLeaves = Object.values(tree.getComputedValues().leaves).filter(
          (item) => displayedItems[item.id],
        );
        const { vatDistribution } = computeOrderAmounts(
          {
            hasUnitPrices: true,
            vatRate: null,
            amountExVAT: null,
            vatType: newVATType,
          },
          computedValuesOfDisplayedLeaves,
        );
        form.setFormValues({ vatDistribution });
      }
      vatTypeHiddenField.setValue(newVATType);
    },
    [form, vatTypeHiddenField, tree],
  );

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  return (
    <Flex justifyContent="space-between" alignItems="flex-start">
      {isAmountEditable && !hasStandardVATRates && (
        <PercentageField<OrderEditForm>
          name="vatRate"
          label={t('orders:informationsCard.vatDistribution')}
          min={0}
          isRequired={isAmountRequired}
        >
          {isAmountRequired && <Rule.IsRequired />}
        </PercentageField>
      )}

      {isAmountEditable && hasStandardVATRates && (
        <AccountingVATField<OrderEditForm>
          name="vatRate"
          label={t('orders:informationsCard.vatDistribution')}
          isRequired={isAmountRequired}
          valueScale={100}
        >
          <Rule.IsRequired />
        </AccountingVATField>
      )}

      {!isAmountEditable && (
        <>
          <HiddenField {...vatRateHiddenField} />
          <OrderVATRatesLabel vatDistribution={vatBases || []} vatType={vatTypeHiddenField.value!} />
        </>
      )}

      <HiddenField {...vatTypeHiddenField} />

      <Flex alignSelf="end" mb="1px" ml={2} mt={4}>
        <ActionMenu>
          {vatTypeHiddenField.value === VAT_TYPE.NORMAL ? (
            <>
              <ActionMenu.Action
                label={t('orders:actions.applyReversalOfLiability')}
                icon={<SimpleHiddenCostIcon />}
                onClick={() => onChangeVATType(VAT_TYPE.REVERSE_CHARGE)}
              />
              <ActionMenu.Action
                label={t('orders:actions.applyIntraCommunity')}
                icon={<SimpleHiddenCostIcon />}
                onClick={() => onChangeVATType(VAT_TYPE.INTRA_COMMUNITY)}
              />
            </>
          ) : (
            <ActionMenu.Action
              label={
                vatTypeHiddenField.value === VAT_TYPE.REVERSE_CHARGE
                  ? t('orders:actions.removeReversalOfLiability')
                  : t('orders:actions.removeIntraCommunity')
              }
              icon={<SimpleSettingsIcon />}
              onClick={() => onChangeVATType(VAT_TYPE.NORMAL)}
            />
          )}
        </ActionMenu>
      </Flex>
    </Flex>
  );
};

const ErrorMessage: FC<ErrorMessageProps> = ({ numberOfIncompleteItems, sumSupplierInvoicesAmountExVAT }) => {
  const { t } = useTranslation(['orders']);
  const { mapAmountToNumber, formatAsAmount } = useCurrency();

  const { orderId } = useParams<{ orderId?: string }>();
  const isEdition = !!orderId;

  const form = useFormContext<OrderEditForm>();
  const directPaymentErrorHiddenField = useHiddenField(form, 'directPaymentError');

  const {
    isDirectPayment,
    amountExVAT: rawAmountExVAT,
    vatType,
  } = useOnChangeValues(form, ['isDirectPayment', 'amountExVAT', 'vatType']);

  const vatDistribution = useCurrentOrderVATDistribution();

  const directPaymentErrorMessage = useMemo(() => {
    if (numberOfIncompleteItems !== 0) {
      return t(
        isEdition
          ? 'orders:informationsCard.alertOnIncompleteItemsInEdition'
          : 'orders:informationsCard.alertOnIncompleteItemsInCreation',
      );
    }
    if (isDirectPayment && vatType === VAT_TYPE.NORMAL && vatDistribution.length > 1) {
      return t('orders:errors.cannotHaveMultipleVatRateOnDirectPayment');
    }

    if (sumSupplierInvoicesAmountExVAT && mapAmountToNumber(rawAmountExVAT ?? 0) < sumSupplierInvoicesAmountExVAT) {
      return t('orders:informationsCard.alertOnAmountExVat', {
        sumSupplierInvoicesAmountExVAT: formatAsAmount(sumSupplierInvoicesAmountExVAT || 0),
      });
    }
    return '';
  }, [
    formatAsAmount,
    isDirectPayment,
    isEdition,
    mapAmountToNumber,
    numberOfIncompleteItems,
    rawAmountExVAT,
    sumSupplierInvoicesAmountExVAT,
    t,
    vatDistribution.length,
    vatType,
  ]);

  if (!directPaymentErrorMessage) return null;

  return (
    <Box pt={3}>
      <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
        {directPaymentErrorMessage}
      </Callout>

      <HiddenField {...directPaymentErrorHiddenField}>
        <Rule.IsRequired />
      </HiddenField>
    </Box>
  );
};

interface OrderAmountCardProps {
  tree: OrderTree;
}

export const OrderAmountCard: FC<OrderAmountCardProps> = ({ tree }) => {
  const { t } = useTranslation(['orders']);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const form = useFormContext<OrderEditForm>();
  const {
    associatedSupplierInvoices,
    isDirectPayment,
    amountExVAT: rawAmountExVAT,
    vatRate: rawVatRate,
    vatType,
  } = useOnChangeValues(form, ['associatedSupplierInvoices', 'isDirectPayment', 'amountExVAT', 'vatRate', 'vatType']);

  const hasUnitPriceHiddenField = useHiddenField(form, 'hasUnitPrices');
  const numberOfItemsHiddenField = useHiddenField(form, 'numberOfItems');
  const numberOfIncompleteItemsField = useHiddenField(form, 'numberOfIncompleteItems');

  const { areVatRatesEditable } = useOrderDirectPaymentInfosContext();

  const vatBases = useCurrentOrderVATDistribution();
  const amountIncVAT = useMemo(() => {
    const amountExVAT = mapAmountToNumber(rawAmountExVAT ?? 0);
    return isNil(rawAmountExVAT) || (vatBases.length === 0 && vatType === VAT_TYPE.NORMAL)
      ? null
      : computeOrderAmountIncVAT(amountExVAT, vatBases, vatType ?? VAT_TYPE.NORMAL);
  }, [mapAmountToNumber, rawAmountExVAT, vatBases, vatType]);

  /**
   *  The minimum value of an order in direct payment is the sum of all its supplier invoices
   *  associated to progress statement in accepted or completed and not archived
   */
  const sumSupplierInvoicesAmountExVAT = useMemo(() => {
    if (isDirectPayment) {
      const supplierInvoiceWithAccountableProgressStatement = (associatedSupplierInvoices || []).filter(
        (supplierInvoice) =>
          supplierInvoice.progressStatement &&
          supplierInvoice.progressStatement.status !== PROGRESS_STATEMENT_STATUSES.DRAFT &&
          !supplierInvoice.progressStatement.isArchived,
      );
      return sumObjects(supplierInvoiceWithAccountableProgressStatement, 'amountExVAT');
    }
    return null;
  }, [associatedSupplierInvoices, isDirectPayment]);

  const isAmountRequired = useMemo(
    () => isDirectPayment || !isNil(rawAmountExVAT) || (vatType === VAT_TYPE.NORMAL && !isNil(rawVatRate)),
    [isDirectPayment, rawAmountExVAT, rawVatRate, vatType],
  );

  const isAmountsEditable = !(
    isNumberFinite(numberOfItemsHiddenField.value) &&
    numberOfItemsHiddenField.value > 0 &&
    hasUnitPriceHiddenField.value === true
  );

  return (
    <Card title={t('orders:informationsCard.title')}>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <HiddenField {...hasUnitPriceHiddenField} />
        <HiddenField<OrderEditForm> name="vatDistribution" />
        <HiddenField {...numberOfItemsHiddenField} />
        <HiddenField {...numberOfIncompleteItemsField} />

        <GridItem>
          {isAmountsEditable ? (
            <CurrencyField<OrderEditForm>
              name="amountExVAT"
              label={t('orders:informationsCard.amountExVAT')}
              {...(isDirectPayment ? { min: 0 } : null)}
              isRequired={isAmountRequired}
            >
              {isAmountRequired && <Rule.IsRequired />}
              {!!sumSupplierInvoicesAmountExVAT && (
                <Rule.IsHigherOrEqualThan amount={mapNumberToAmount(sumSupplierInvoicesAmountExVAT)} message="" />
              )}
            </CurrencyField>
          ) : (
            <>
              <HiddenField<OrderEditForm> name="amountExVAT" />
              <LabeledData
                label={t('orders:informationsCard.amountExVAT')}
                data={
                  <Price amount={isNumberFinite(rawAmountExVAT) ? mapAmountToNumber(rawAmountExVAT) : rawAmountExVAT} />
                }
                size="lg"
              />
            </>
          )}
        </GridItem>

        <GridItem>
          <VATRateField
            isAmountEditable={isAmountsEditable && areVatRatesEditable && vatType === VAT_TYPE.NORMAL}
            isAmountRequired={isAmountRequired}
            vatBases={vatBases}
            tree={tree}
          />
        </GridItem>

        <GridItem>
          <LabeledData
            label={t('orders:informationsCard.amountIncVAT')}
            size="md"
            data={<Price amount={amountIncVAT} />}
          />
        </GridItem>
      </Grid>

      <Box mt={2}>
        <CheckboxField<OrderEditForm>
          name="isPriceRequest"
          label={
            <Flex>
              <Box mr={2}>{t('orders:informationsCard.isPriceRequest')}</Box>
              <Tooltip label={t('orders:informationsCard.isPriceRequestTooltip')} placement="right-end">
                <Box>
                  <SimpleHelpIcon boxSize={5} />
                </Box>
              </Tooltip>
            </Flex>
          }
        />
      </Box>

      <Box mt={2}>
        <OrderOpenItemSelectionButton tree={tree} />
      </Box>

      <ErrorMessage
        numberOfIncompleteItems={numberOfIncompleteItemsField.value || 0}
        sumSupplierInvoicesAmountExVAT={sumSupplierInvoicesAmountExVAT}
      />
    </Card>
  );
};
