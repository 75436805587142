import type { Response } from '@graneet/lib-ui';
import type { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';

import { logAxiosErrors } from './api.service';
import { logAxiosErrors as logAxiosErrorsNew } from './apiNew.service';

export const proxyHelper = async <R = unknown>(method: () => Promise<AxiosResponse<R, any>>): Promise<Response<R>> => {
  try {
    const result = await method();
    const data = result && result.data;
    return [null, data];
  } catch (error) {
    console.error(`An error occurred while requesting`);
    Sentry.captureException(error, {});

    logAxiosErrors(error as AxiosError);
    return [error as AxiosError, null];
  }
};

export const proxyHelperTanStack = async <R = unknown>(method: () => Promise<AxiosResponse<R, any>>): Promise<R> => {
  try {
    const result = await method();
    const data = result && result.data;
    return data;
  } catch (error) {
    console.error(`An error occurred while requesting`);
    Sentry.captureException(error, {});

    const err = logAxiosErrorsNew(error as AxiosError);
    throw new Error(err);
  }
};
