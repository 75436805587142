import { datadogRum } from '@datadog/browser-rum';

import { getEnvValue } from './env';

const env = getEnvValue('REACT_APP_ENV', false);
const applicationId = getEnvValue('REACT_APP_DD_APPLICATION_ID', false);
const clientToken = getEnvValue('REACT_APP_DD_CLIENT_TOKEN', false);

if (env && (env === 'prod' || env === 'squad-b') && applicationId && clientToken) {
  // eslint-disable-next-line no-console
  console.log('Datadog RUM enabled');

  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.eu',
    service: `graneet-${env}`,
    env,
    version: getEnvValue('TAG_VERSION', false),
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}
