import { useCallback, type ForwardedRef, useRef, useImperativeHandle, forwardRef } from 'react';
import type { IMask } from 'react-imask';
import { IMaskMixin } from 'react-imask';
import type { FieldValue } from 'graneet-form';

import type { QuotationInputProps } from './Input';
import { QuotationInput } from './Input';

const IMaskInput = IMaskMixin(({ inputRef, ...props }) => (
  <QuotationInput {...props} ref={inputRef as ForwardedRef<HTMLInputElement> | undefined} />
));
export type MaskedInputHandle = {
  getUnmaskedValue: () => FieldValue | null;
};

export interface QuotationMaskedInputProps extends QuotationInputProps {
  mask: IMask.AnyMask;

  inputRef?(el: HTMLInputElement): void;

  onChange?: (value: FieldValue | null) => void | Promise<void>;
}

export const QuotationMaskedInput = forwardRef<MaskedInputHandle, QuotationMaskedInputProps>(
  ({ onChange, onBlur, value, mask, ...rest }, ref) => {
    const stringifyValue = value == null ? '' : value.toString();

    const maskRef = useRef(null);

    useImperativeHandle(
      ref,
      () => ({
        // @ts-ignore
        getUnmaskedValue: () => maskRef?.current?.maskRef.unmaskedValue,
      }),
      [maskRef],
    );

    const handleOnBlur = useCallback(() => {
      // @ts-ignore
      onBlur?.(maskRef?.current?.maskRef.unmaskedValue);
    }, [onBlur]);
    return (
      <IMaskInput
        // mask is not included in typing of IMaskMixin
        // @ts-ignore
        mask={mask}
        onAccept={onChange}
        value={stringifyValue}
        unmask
        onBlur={handleOnBlur}
        ref={maskRef}
        {...rest}
      />
    );
  },
);
