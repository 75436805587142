import type { FC } from 'react';
import { useCallback } from 'react';
import { ChapterCollapse } from '@graneet/lib-ui';
import { useTranslation, Trans } from 'react-i18next';
import type { IQuote } from '@graneet/business-logic';
import { isQuoteInformationEditable } from '@graneet/business-logic';

import { patchQuote } from '../../services/quote.api';

import { TextEditionCard } from 'features/common/components/TextEditionCard';

interface QuoteMentionsCardProps {
  quote: IQuote;
  isReadOnly: boolean;
}

export const QuoteMentionsCard: FC<QuoteMentionsCardProps> = ({ quote, isReadOnly }) => {
  const { t } = useTranslation(['quote']);
  const isEditable = isQuoteInformationEditable(quote);

  const updateMentions = useCallback((mentions: string) => patchQuote(quote.id, { mentions }), [quote]);

  return (
    <ChapterCollapse
      title={t('quote:cards.mentions.title')}
      description={<Trans t={t} i18nKey="quote:cards.mentions.description" />}
    >
      <TextEditionCard
        title={t('quote:cards.mentions.title')}
        text={quote.mentions}
        onTextEdited={updateMentions}
        errorLabel={t('quote:cards.mentions.error')}
        successLabel={t('quote:cards.mentions.success')}
        placeholder={t('quote:cards.mentions.placeholder')}
        isEditable={isEditable && !isReadOnly}
      />
    </ChapterCollapse>
  );
};
