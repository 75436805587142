import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PERMISSION } from '@graneet/business-logic';
import type { IDownPayment } from '@graneet/business-logic';
import { Card } from '@graneet/lib-ui';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { useStatementsAvailableTags } from 'features/statement/services/statement.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useUpdateDownPaymentTags } from 'features/down-payment/services/down-payment.api';

interface DownPaymentTagsCardProps {
  downPayment: IDownPayment;
}

export const DownPaymentTagsCard: FC<DownPaymentTagsCardProps> = ({ downPayment }) => {
  const { t } = useTranslation(['global']);
  const hasPermission = usePermissions([PERMISSION.ACCESS_STATEMENTS, PERMISSION.CREATE_STATEMENTS]);

  const tags = useStatementsAvailableTags();

  const updateDownPaymentTagsMutation = useUpdateDownPaymentTags();

  const onTagsSaved = useCallback(
    (newTags: string[]) => updateDownPaymentTagsMutation.mutateAsync({ id: downPayment.id, dto: { tags: newTags } }),
    [downPayment.id, updateDownPaymentTagsMutation],
  );

  if (!downPayment.tags && !hasPermission) {
    return null;
  }

  return (
    <Card width="100%" title={t('global:words.privateTags')}>
      <TagsEditionBlock
        placeholder={t('global:changeTags.placeholder')}
        tags={downPayment.tags}
        suggestedTags={tags.data}
        onTagsEdited={onTagsSaved}
        isEditable={hasPermission}
      />
    </Card>
  );
};
