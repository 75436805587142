import type { QuoteNode } from '@org/quotation-lib';

export const genericUpdateItemMapper = (
  quoteId: string,
  quoteNode: QuoteNode,
  clientRequestId: string,
  timestamp: string,
) => {
  const parentId = quoteNode.getParent()?.getId();
  if (!parentId) throw new Error('Missing parent ID');
  return {
    quoteId,
    timestamp,
    clientRequestId,
    parentNodeId: parentId,
    prevSiblingId: quoteNode.getPrevSibling()?.getId() || null,
    nextSiblingId: quoteNode.getNextSibling()?.getId() || null,
    nodeId: quoteNode.getId(),
  };
};
