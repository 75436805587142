import { ActionMenu, SimpleDownloadIcon, SimpleSettingsIcon } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TEMPLATE_URL_BASE } from '../constants/import.constant';

import { downloadFile } from 'features/file/services/file.util';

interface SpreadsheetImportMenuProps {
  label: string;
  templateUrl: string;
  isDisabled?: boolean;
  onOpen: () => void;
}

export const SpreadsheetImportMenu: FC<SpreadsheetImportMenuProps> = ({
  label,
  templateUrl,
  isDisabled = false,
  onOpen,
}) => {
  const { t } = useTranslation(['global']);

  return (
    <ActionMenu
      buttonProps={{
        variant: 'secondary',
        leftIcon: null,
        children: label,
      }}
    >
      <ActionMenu.Action
        icon={<SimpleDownloadIcon />}
        onClick={() => downloadFile(TEMPLATE_URL_BASE + templateUrl)}
        label={t('global:spreadsheetImport.menu.downloadTemplate')}
      />
      <ActionMenu.Action
        icon={<SimpleSettingsIcon />}
        onClick={onOpen}
        isDisabled={isDisabled}
        label={t('global:spreadsheetImport.menu.launchImport')}
      />
    </ActionMenu>
  );
};
