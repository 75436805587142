import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import { Card, GotoLink, LabeledData, Price, TwoColumns } from '@graneet/lib-ui';
import type { IHoldback } from '@graneet/business-logic';

interface HoldbackCardProps {
  holdback: IHoldback;
}

export const HoldbackCard: FC<HoldbackCardProps> = ({ holdback }) => {
  const { id, holdbackAmountIncVAT, hasPaymentGuarantee, bankName } = holdback;

  const { t } = useTranslation(['global', 'holdback']);

  return (
    <Card
      title={
        <Flex>
          <Text mr={4}>{t('holdback:holdback')}</Text>
          <GotoLink to={`/sales/holdbacks/${id}`} label={t('holdback:goToLink')} />
        </Flex>
      }
    >
      <LabeledData label={t('holdback:fields.holdbackAmountIncVAT')} data={<Price amount={holdbackAmountIncVAT} />} />
      <TwoColumns mt={4}>
        <LabeledData
          label={t('holdback:fields.paymentGuarantee')}
          data={hasPaymentGuarantee ? t('global:words.c.yes') : t('global:words.c.no')}
        />
        <LabeledData label={t('holdback:fields.bank')} data={hasPaymentGuarantee ? bankName : '-'} />
      </TwoColumns>
    </Card>
  );
};
