import type { FC } from 'react';
import type { BoxProps } from '@chakra-ui/react';
import { Box, Text, Progress, Flex } from '@chakra-ui/react';

import { Price } from '../Formatting';
import { Tooltip } from '../Tooltip';
import { SimpleHelpIcon } from '../Icons';

const getPercentage = (value1: number, value2: number) =>
  value1 < value2 ? `${Math.max(1, (value1 * 100) / value2)}%` : '100%';

interface DetailAmountProps extends BoxProps {
  text: string;
  amount: number;
  color: string;
  max: number;
  progressColor: string;
  description?: string;
  tooltip?: string;
}

const DetailAmount: FC<DetailAmountProps> = ({
  text,
  amount,
  color,
  max,
  progressColor,
  description,
  tooltip,
  ...props
}) => (
  <Box {...props}>
    <Text color={color} fontSize="0.875rem" mb={1} textTransform="uppercase">
      {text}
    </Text>
    <Progress value={100} borderRadius="md" colorScheme={progressColor} w={getPercentage(amount, max)} h={6} />
    <Flex alignItems="baseline">
      <Text as="span" fontSize="4xl" color={color} whiteSpace="nowrap" mr={2}>
        <Price amount={amount} />
      </Text>
      {description && (
        <Text as="span" fontSize="0.875rem">
          {description}
        </Text>
      )}
      {tooltip && (
        <Tooltip label={tooltip} placement="right-end">
          <Box ml={2}>
            <SimpleHelpIcon boxSize={5} />
          </Box>
        </Tooltip>
      )}
    </Flex>
  </Box>
);

interface Amount {
  amount: number;
  text: string;
  description?: string;
  tooltip?: string;
}

export interface ComparativeAmountsProps {
  firstAmountProps: Amount;
  secondAmountProps: Amount;
}

export const ComparativeAmounts = ({ firstAmountProps, secondAmountProps }: ComparativeAmountsProps) => {
  const max = Math.max(firstAmountProps.amount, secondAmountProps.amount);
  return (
    <Box>
      <DetailAmount {...firstAmountProps} color="greenBrand.light" progressColor="greenBrand" max={max} mb={8} />
      <DetailAmount {...secondAmountProps} color="gray.700" progressColor="gray300" max={max} />
    </Box>
  );
};
