import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { CardProps } from '@graneet/lib-ui';
import { Card, LabeledData } from '@graneet/lib-ui';
import { Grid } from '@chakra-ui/react';
import type { IProjectWithRelations } from '@graneet/business-logic';

import { getPaymentTermI18nKey } from 'features/statement/services/statement.util';

interface ProjectWithPaymentSummaryCardProps extends Omit<CardProps, 'children'> {
  project: IProjectWithRelations;
}

export const ProjectWithPaymentSummaryCard: FC<ProjectWithPaymentSummaryCardProps> = ({ project, ...cardProps }) => {
  const { t } = useTranslation(['project', 'statement']);

  return (
    <Card title={t('project:associateProjectCard.title')} {...cardProps}>
      <Grid templateColumns="repeat(4, 1fr)">
        <LabeledData label={t('statement:fields.projectName')} data={project.name} />
        <LabeledData label={t('statement:fields.projectCode')} data={project.refCode} />
        <LabeledData
          label={t('statement:fields.paymentTerm')}
          data={t(getPaymentTermI18nKey(project.deadlineType), { days: project.paymentTerm })}
        />
        <LabeledData label={t('statement:fields.paymentMethod')} data={project.paymentMethod.name} />
      </Grid>
    </Card>
  );
};
