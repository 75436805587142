import type {
  IAccountManagersAssociateDTO,
  ICanBeDeletedResponseDTO,
  IPlannedAmount,
  IProject,
  IProjectAccountManager,
  IProjectAddImportFileDTO,
  IProjectBudgetAnalysisDTO,
  IProjectClosePayloadDTO,
  IProjectCreationDTO,
  IProjectStatsSummaryDTO,
  IProjectSubProjectWithBillsListingDTO,
  IProjectSubProjectWithContractsListingDTO,
  IProjectSubProjectWithDirectPaymentOrdersListingDTO,
  IProjectSubProjectWithStatementsListingDTO,
  IProjectUpdateDTO,
  IProjectUpdateInformationDTO,
  IProjectWithRelations,
  IProjectWorkersDTO,
  IUpdateTagsDTO,
  IUserScopePerProject,
  ProjectFinancialSummaryPaginatedResponse,
  ProjectPaginatedResponse,
} from '@graneet/business-logic';
import { FEATURE_FLAGS, SUPPORT_EMAIL } from '@graneet/business-logic';
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import type { UsePaginationOptions } from '@graneet/lib-ui';
import { useToast, usePaginationQuery } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { PROJECT_FACTORY_NAME } from './project.factory-name';

import { invalidateStatementRelatedQueries } from 'features/common/services/invalidate-statement-related-queries.util';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { userKeyFactory } from 'features/user/services/user.api';
import { ExternalApi } from 'config/externalApi';
import { proxyHelperTanStack } from 'features/api/services/proxyHelper';
import { dtoToFormData } from 'features/api/services/api.util';
import { apiNew } from 'features/api/services/apiNew.service';

export const PROJECT_PATH_API = '/projects';

const projectApi = () => ExternalApi.getExternalApi().getProjectApi();

const projects = createQueryKeys(PROJECT_FACTORY_NAME, {
  get: (queryParams?: URLSearchParams | { _full?: boolean }) => ({
    queryKey: [PROJECT_PATH_API, queryParams],
    queryFn: () =>
      apiNew.get<Partial<URLSearchParams> | { _full?: boolean }, ProjectPaginatedResponse>(
        PROJECT_PATH_API,
        queryParams,
      ),
  }),
  getAssociables: (queryParams?: Record<any, any>) => ({
    queryKey: [PROJECT_PATH_API, 'associable', queryParams],
    queryFn: () =>
      apiNew.get<URLSearchParams | Record<any, any>, ProjectPaginatedResponse>(`${PROJECT_PATH_API}/associable`, {
        _full: true,
        ...queryParams,
      }),
  }),
  getProjectFinancialSummary: (queryParams: URLSearchParams | { _full?: boolean }) => ({
    queryKey: [PROJECT_PATH_API, queryParams],
    queryFn: () =>
      apiNew.get<Partial<URLSearchParams> | { _full?: boolean }, ProjectFinancialSummaryPaginatedResponse>(
        `${PROJECT_PATH_API}/financial-summary`,
        queryParams,
      ),
  }),
  getOne: (id: number) => ({
    queryKey: [PROJECT_PATH_API, id],
    queryFn: () => apiNew.get<never, IProjectWithRelations>(`${PROJECT_PATH_API}/${id}`),
  }),
  getPotentialOne: (id: number | undefined) => ({
    queryKey: [PROJECT_PATH_API, id],
    queryFn: () => (id ? apiNew.get<never, IProjectWithRelations>(`${PROJECT_PATH_API}/${id}`) : Promise.resolve(null)),
  }),
  getAvailableTags: () => ({
    queryKey: [PROJECT_PATH_API],
    queryFn: () => apiNew.get<never, string[]>(`${PROJECT_PATH_API}/tags`),
  }),
  getSubProjectsWithStatements: (id: number) => ({
    queryKey: [PROJECT_PATH_API, id],
    queryFn: () =>
      apiNew.get<never, IProjectSubProjectWithStatementsListingDTO>(
        `${PROJECT_PATH_API}/${id}/subprojects-with-statements`,
      ),
  }),
  getSubProjectsWithBills: (id: number) => ({
    queryKey: [PROJECT_PATH_API, id],
    queryFn: () =>
      apiNew.get<never, IProjectSubProjectWithBillsListingDTO>(`${PROJECT_PATH_API}/${id}/subprojects-with-bills`),
  }),
  getSubProjectsWithContracts: (id: number) => ({
    queryKey: [PROJECT_PATH_API, id],
    queryFn: () =>
      apiNew.get<never, IProjectSubProjectWithContractsListingDTO>(
        `${PROJECT_PATH_API}/${id}/subprojects-with-contracts`,
      ),
  }),
  getSubProjectsWithDirectPaymentOrders: (id: number) => ({
    queryKey: [PROJECT_PATH_API, id],
    queryFn: () =>
      apiNew.get<never, IProjectSubProjectWithDirectPaymentOrdersListingDTO>(
        `${PROJECT_PATH_API}/${id}/subprojects-with-direct-payment-orders`,
      ),
  }),
  getCanBeDeleted: (id: number) => ({
    queryKey: [PROJECT_PATH_API, id],
    queryFn: () => apiNew.get<never, ICanBeDeletedResponseDTO>(`${PROJECT_PATH_API}/${id}/can-be-deleted`),
  }),
  getPlannedAmount: (params: { id: number; hasQuotationFeatureFlag: boolean }) => ({
    queryKey: [PROJECT_PATH_API, params],
    queryFn: async () => {
      if (params.hasQuotationFeatureFlag) {
        return proxyHelperTanStack(() => projectApi().getProjectPlannedAmount(params.id));
      }
      const p = await apiNew.get<never, IPlannedAmount>(`${PROJECT_PATH_API}/${params.id}/planned-amounts`);
      return {
        budgets: p,
      };
    },
  }),
  getSummaryStats: (id: number) => ({
    queryKey: [PROJECT_PATH_API, id],
    queryFn: () => apiNew.get<never, IProjectStatsSummaryDTO>(`${PROJECT_PATH_API}/${id}/stats/summary`),
  }),
  getBudgetAnalysisStats: (id: number) => ({
    queryKey: [PROJECT_PATH_API, id],
    queryFn: () => apiNew.get<never, IProjectBudgetAnalysisDTO>(`${PROJECT_PATH_API}/${id}/stats/budget-analysis`),
  }),
  getWorkers: (id: number) => ({
    queryKey: [PROJECT_PATH_API, id],
    queryFn: () => apiNew.get<{ query?: string }, IProjectWorkersDTO>(`${PROJECT_PATH_API}/${id}/workers`),
  }),
  areInUserScope: (ids: number[]) => ({
    queryKey: [PROJECT_PATH_API, ids],
    queryFn: () => {
      if (ids.length === 0) {
        return Promise.resolve<IUserScopePerProject>({});
      }
      return apiNew.get<never, IUserScopePerProject>(
        `${PROJECT_PATH_API}/user-scope?projectIds=${ids.join('&projectIds=')}`,
      );
    },
  }),
});

// -- Query
export function useProjectsFinancialSummary(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) => queryClient.fetchQuery(projects.getProjectFinancialSummary(urlSearchParams)),
    options,
  );
}

// -- Query
export function useProjects(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery((urlSearchParams) => queryClient.fetchQuery(projects.get(urlSearchParams)), options);
}

export function useProjectsQuery(
  urlSearchParams?: URLSearchParams,
  options?: {
    enabled: boolean;
  },
) {
  return useQuery({ ...projects.get(urlSearchParams), ...options });
}

export function useProjectsWithoutPagination(urlSearchParams?: Record<any, any>) {
  return useSuspenseQuery(projects.get({ _full: true, ...urlSearchParams }));
}

export function useProject(id: number) {
  return useSuspenseQuery(projects.getOne(id));
}

export function useProjectOrUndefined(id: number | undefined) {
  return useSuspenseQuery(projects.getPotentialOne(id));
}

export function useProjectAvailableTags() {
  return useSuspenseQuery(projects.getAvailableTags());
}

export function useProjectSubProjectsWithStatements(id: number) {
  return useSuspenseQuery(projects.getSubProjectsWithStatements(id));
}

export function useProjectSubProjectsWithBills(id: number) {
  return useSuspenseQuery(projects.getSubProjectsWithBills(id));
}

export function useProjectSubProjectsWithContracts(id: number) {
  return useSuspenseQuery(projects.getSubProjectsWithContracts(id));
}

export function useProjectSubProjectsWithDirectPaymentOrders(id: number) {
  return useSuspenseQuery(projects.getSubProjectsWithDirectPaymentOrders(id));
}

export function useProjectCanBeDeleted(id: number) {
  return useSuspenseQuery(projects.getCanBeDeleted(id));
}

export function useProjectPlannedAmount(id: number) {
  const hasQuotationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);

  return useSuspenseQuery(
    projects.getPlannedAmount(
      useMemo(
        () => ({
          id,
          hasQuotationFeatureFlag,
        }),
        [hasQuotationFeatureFlag, id],
      ),
    ),
  );
}

export function useProjectSummaryStats(id: number) {
  return useSuspenseQuery(projects.getSummaryStats(id));
}

export function useProjectBudgetAnalysisStats(id: number) {
  return useSuspenseQuery(projects.getBudgetAnalysisStats(id));
}

export function useProjectWorkers(id: number) {
  return useSuspenseQuery(projects.getWorkers(id));
}

export function useProjectAreInUserScope(ids: number[]) {
  return useSuspenseQuery(projects.areInUserScope(ids));
}

export function useProjectLazyAreInUserScope() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  return useMutation({
    mutationFn: (ids: number[]) => {
      if (ids.length === 0) {
        return Promise.resolve<IUserScopePerProject>({});
      }
      return apiNew.get<never, IUserScopePerProject>(
        `${PROJECT_PATH_API}/user-scope?projectIds=${ids.join('&projectIds=')}`,
      );
    },
    onError: () => {
      toast.error(t('global:words.c.error'));
    },
  });
}

// TODO Remove and usage paginated one instead
export const useAssociableProjectsWithoutPagination = (queryParams?: Record<any, any>) =>
  useSuspenseQuery(projects.getAssociables(queryParams));

// TODO Remove and usage paginated one instead
export const useAssociableProjectsWithoutPaginationQuery = (queryParams?: Record<any, any>) =>
  useQuery(projects.getAssociables(queryParams));

// -- Mutation
export function useProjectUpdateTags() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateTagsDTO }) =>
      apiNew.patch<IUpdateTagsDTO, IProject>(`${PROJECT_PATH_API}/${params.id}/tags`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:changeTags.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:words.c.success'), t('global:changeTags.success'));
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useProjectCreate() {
  const { t } = useTranslation(['global', 'project']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IProjectCreationDTO) =>
      apiNew.post<IProjectCreationDTO, IProjectWithRelations>(PROJECT_PATH_API, dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('project:flow.toasts.error', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useProjectAddImportFile() {
  const { t } = useTranslation(['global', 'project']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: { importFile: File } & IProjectAddImportFileDTO }) =>
      apiNew.patchWithMultiPart<{ importFile: File } & IProjectAddImportFileDTO, IProject>(
        `${PROJECT_PATH_API}/${params.id}/import-file`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:words.c.error'), t('project:flow.toasts.error', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(userKeyFactory.getCurrent()); // Invalidate user quota
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useProjectClose() {
  const { t } = useTranslation(['global', 'project']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { project: IProject; dto: IProjectClosePayloadDTO }) =>
      apiNew.patchWithMultiPart<FormData, IProjectWithRelations>(
        `${PROJECT_PATH_API}/${params.project.id}/close`,
        dtoToFormData(params.dto),
      ),
    onError: (_, params) => {
      toast.error(
        t('project:summary.close.modal.error', { name: params.project.name }),
        t('project:summary.close.modal.errorInfo'),
      );
    },
    onSuccess: async (_, params) => {
      toast.success(t('project:summary.close.modal.success', { name: params.project.name }));
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useProjectReopen() {
  const { t } = useTranslation(['global', 'project']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (project: IProject) =>
      apiNew.patch<number, IProjectWithRelations>(`${PROJECT_PATH_API}/${project.id}/reopen`),
    onError: (_, project) => {
      toast.error(
        t('project:summary.reopen.modal.error', { name: project.name }),
        t('project:summary.close.modal.errorInfo'),
      );
    },
    onSuccess: async (_, project) => {
      toast.success(t('project:summary.reopen.modal.success', { name: project.name }));
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useProjectAttachAcceptanceReport() {
  const { t } = useTranslation(['global', 'project']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: { acceptanceReport: File } }) =>
      apiNew.patchWithMultiPart<FormData, IProjectWithRelations>(
        `${PROJECT_PATH_API}/${params.id}/attach-acceptance-report`,
        dtoToFormData(params.dto),
      ),
    onError: () => {
      toast.error(
        t('project:summary.attachAcceptanceReport.modal.error'),
        t('project:summary.attachAcceptanceReport.modal.errorInfo'),
      );
    },
    onSuccess: async () => {
      toast.success(t('project:summary.attachAcceptanceReport.modal.success'));
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useProjectUpdate() {
  const { t } = useTranslation(['global', 'project']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IProjectUpdateDTO }) =>
      apiNew.patch<IProjectUpdateDTO, IProjectWithRelations>(`${PROJECT_PATH_API}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useProjectUpdateInformation() {
  const { t } = useTranslation(['global', 'project']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IProjectUpdateInformationDTO }) =>
      apiNew.patch<IProjectUpdateInformationDTO, IProjectWithRelations>(
        `${PROJECT_PATH_API}/${params.id}/information`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:toasts.genericError.title'), t('global:toasts.genericError.description'));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('project:editInformationModal.success'));
    },
  });
}

export function useProjectUpdateAssociateAccountManagers() {
  const { t } = useTranslation(['global', 'accountManager']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IAccountManagersAssociateDTO }) =>
      apiNew.patch<IAccountManagersAssociateDTO, IProjectAccountManager[]>(
        `${PROJECT_PATH_API}/${params.id}/account-managers`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('accountManager:toast.associatedSuccess'));
    },
  });
}

export function useProjectDelete() {
  const { t } = useTranslation(['global', 'project']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (project: IProject) => apiNew.delete<number, void>(`${PROJECT_PATH_API}/${project.id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, project) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('project:deleteProjectModal.successMessage', { name: project.name }));
    },
  });
}
