import { useCallback, useEffect, useRef, useState } from 'react';

import { useTreeContext } from '../contexts';
import type { Identifiable } from '../types/Identifiable';
import type { LeafInfos } from '../types/LeafInfos';

export const useLeaf = <Node extends Identifiable, Leaf extends Identifiable>(
  leafId: Leaf['id'],
): LeafInfos<Node, Leaf> => {
  const { listenToLeaf, getLeafSignalInfos } = useTreeContext<Node, Leaf>();
  const [leafInfos, setLeafInfos] = useState<LeafInfos<Node, Leaf>>(() => getLeafSignalInfos(leafId));

  const hasBeenCalledOnce = useRef(false);
  const updateLeafInfos = useCallback((infos: LeafInfos<Node, Leaf>) => {
    if (!hasBeenCalledOnce.current) {
      hasBeenCalledOnce.current = true;
      return;
    }
    setLeafInfos(infos);
  }, []);

  useEffect(() => listenToLeaf(leafId, updateLeafInfos), [listenToLeaf, leafId, updateLeafInfos]);

  return leafInfos;
};
