import type { QuoteSlice } from '../store/quoteSettingSliceZustant';

import { useStore } from 'store/store';

export function useQuoteSettingsGetFromStoreOrThrow(): QuoteSlice {
  const quoteSummaryBarItemDisplayed = useStore((state) => state.quoteSummaryBarItemDisplayed);
  const quoteRootColumns = useStore((state) => state.quoteRootColumns);
  const quoteHiddenCostColumns = useStore((state) => state.quoteHiddenCostColumns);
  const quoteSetting = useStore((state) => state.quoteSetting);
  if (!quoteSummaryBarItemDisplayed || !quoteRootColumns || !quoteHiddenCostColumns) {
    throw new Error('Quote settings not found in store');
  }
  return { quoteSummaryBarItemDisplayed, quoteRootColumns, quoteHiddenCostColumns, quoteSetting };
}
