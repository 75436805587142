import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { Analytics } from '@segment/analytics-next';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { TRACKING_EVENT } from '@graneet/business-logic';

import { getEnvValue } from 'config/env';
import { useAppContext } from 'features/app/contexts/AppContext';

interface EventAnalytics {
  analytics: Analytics | null;
  sendEvent: <T extends keyof TRACKING_EVENT>(eventName: T, properties: TRACKING_EVENT[T]) => Promise<void>;
}

const SegmentContext = createContext<EventAnalytics | null>(null);

export const useSegmentAnalytics = () => useContext(SegmentContext);

export const SegmentProvider: FC<PropsWithChildren> = ({ children }) => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);
  const { currentUser } = useAppContext();

  const sendEvent = useCallback(
    async <T extends keyof TRACKING_EVENT>(eventName: T, properties: TRACKING_EVENT[T]) => {
      if (analytics && currentUser?.isDisplayed) {
        await analytics.track(
          eventName,
          {
            userId: currentUser?.auth0ID.toString(),
            properties: properties || undefined,
          },
          {
            context: {
              groupId: currentUser?.company.id,
            },
          },
        );
      }
    },
    [analytics, currentUser],
  );

  const eventAnalytics = useMemo(
    () => ({
      analytics,
      sendEvent,
    }),
    [analytics, sendEvent],
  );

  useEffect(() => {
    const loadAnalytics = async () => {
      const apiKey = getEnvValue('REACT_APP_SEGMENT_API_KEY', false);
      if (!apiKey) {
        console.warn('SEGMENT_API_KEY is not provided, Segment is not set-up on your environment.');
        return;
      }

      const [response] = await AnalyticsBrowser.load(
        {
          writeKey: apiKey,
          cdnURL: getEnvValue('REACT_APP_SEGMENT_CDN_URL'),
        },
        {
          integrations: {
            'Segment.io': {
              apiHost: getEnvValue('REACT_APP_SEGMENT_API_URL'),
              protocol: 'https',
            },
          },
        },
      );
      setAnalytics(response);
    };

    loadAnalytics();
  }, []);

  return <SegmentContext.Provider value={eventAnalytics}>{children}</SegmentContext.Provider>;
};
