import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Modal, TextField } from '@graneet/lib-ui';
import { Form, useForm, useFormContext, useFormStatus } from 'graneet-form';
import { Trans, useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box, Stack, Text } from '@chakra-ui/react';
import type { IRole, IUser } from '@graneet/business-logic';

import type { RoleFormFields } from '../../constants/role.constant';
import { useCreateRole, useUpdateRole, useUsersUsingRole } from '../../services/role.api';
import { mapFormRoleValuesToServerPermissions, mapServerPermissionsToFormRoleValues } from '../../services/role.util';

import { RoleEditModalVisibility } from './RoleEditModalVisibility';
import { RoleEditModalQuote } from './RoleEditModalQuote';
import { RoleEditModalSupplierInvoice } from './RoleEditModalSupplierInvoice';
import { RoleEditModalBudget } from './RoleEditModalBudget';
import { RoleEditModalTimeTracking } from './RoleEditModalTimeTracking';
import { RoleEditModalAccounting } from './RoleEditModalAccounting';
import { RoleEditModalCompanyParameters } from './RoleEditModalCompanyParameters';
import { RoleEditModalStatement } from './RoleEditModalStatement';
import { RoleEditModalOrder } from './RoleEditModalOrder';
import { RoleEditModalBanking } from './RoleEditModalBanking';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { Rule } from 'features/form/rules/Rule';
import type { UserFormValues } from 'features/user/constants/user.constant';
import { UserImpactedCallout } from 'features/user/components/UserImpactedCallout';
import { UserInvitationImpactedCallout } from 'features/user/components/UserInvitationImpactedCallout';

const FORM_ID = 'role-modal';

interface RoleEditModalProps {
  modal: UseDisclosureReturn;

  roles: IRole[];

  initialRoleValues: IRole | null;

  roleIdEdited: number | null;

  user: IUser | null;

  isAdministrator: boolean;
}

const RoleEditModalInternal: FC<RoleEditModalProps> = ({
  modal,
  roles,
  initialRoleValues,
  roleIdEdited,
  user,
  isAdministrator,
}) => {
  const { t } = useTranslation(['global', 'user']);

  const isEdition = !!roleIdEdited;

  const parentForm = useFormContext<UserFormValues>();

  const form = useForm<RoleFormFields>({
    defaultValues: {
      name: initialRoleValues?.name,
      ...mapServerPermissionsToFormRoleValues(initialRoleValues?.permissions || []),
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const usersUsingRole = useUsersUsingRole(roleIdEdited ?? undefined);

  const createRoleMutation = useCreateRole();
  const updateRoleMutation = useUpdateRole();

  const otherUsersImpacted = usersUsingRole.data.users.filter(({ id }) => id !== user?.id);
  const numberOfOtherUsersImpacted = otherUsersImpacted.length;
  const numberOfInvitedUsersImpacted = (usersUsingRole.data.userInvitations || []).length;

  const handleRoleEdited = useCallback(
    async (formValues: RoleFormFields) => {
      const permissionsFromForm = mapFormRoleValuesToServerPermissions(formValues);

      const dto = {
        name: formValues.name,
        permissions: permissionsFromForm,
      };

      if (isEdition) {
        await updateRoleMutation.mutateAsync({ id: roleIdEdited, dto });
      } else {
        await createRoleMutation.mutateAsync(dto, {
          onSuccess: (response) => {
            if (!isAdministrator) {
              parentForm.setFormValues({
                role: response.id,
              });
            }
          },
        });
      }

      modal.onClose();
    },
    [isEdition, modal, updateRoleMutation, roleIdEdited, createRoleMutation, isAdministrator, parentForm],
  );

  const invalidRoleNames = useMemo(() => {
    const roleNamesForbidden = roles.map((role) => role.name);

    return isEdition
      ? roleNamesForbidden.filter((roleName) => roleName !== initialRoleValues?.name)
      : roleNamesForbidden;
  }, [initialRoleValues?.name, isEdition, roles]);

  return (
    <Form form={form} onSubmit={form.handleSubmit(handleRoleEdited)} id={FORM_ID}>
      {numberOfOtherUsersImpacted > 0 && (
        <Box mb={3}>
          <UserImpactedCallout users={otherUsersImpacted}>
            <Trans t={t} i18nKey="user:editRoleModal.callout" count={numberOfOtherUsersImpacted}>
              <Text as="span" fontWeight={600}>
                displayed_data
              </Text>
              description_end
            </Trans>
          </UserImpactedCallout>
        </Box>
      )}

      {numberOfInvitedUsersImpacted > 0 && (
        <Box mb={3}>
          <UserInvitationImpactedCallout userInvitations={usersUsingRole.data.userInvitations}>
            <Trans t={t} i18nKey="user:editRoleModal.callout_invitation" count={numberOfInvitedUsersImpacted}>
              <Text as="span" fontWeight={600}>
                displayed_data
              </Text>
              description_end
            </Trans>
          </UserInvitationImpactedCallout>
        </Box>
      )}

      <Stack direction="column" gap={5}>
        <TextField<RoleFormFields> name="name" label={t('user:role.fields.name')} isRequired>
          <Rule.IsRequired />
          <Rule.IsNotOneOf message={t('user:role.rule.notAvailable')} values={invalidRoleNames} />
        </TextField>

        <RoleEditModalVisibility />
        <RoleEditModalQuote />
        <RoleEditModalStatement />
        <RoleEditModalOrder />
        <RoleEditModalSupplierInvoice />
        <RoleEditModalBudget />
        <RoleEditModalTimeTracking />
        <RoleEditModalAccounting />
        <RoleEditModalCompanyParameters />
        <RoleEditModalBanking />
      </Stack>

      <Modal.Close isDisabled={createRoleMutation.isPending || updateRoleMutation.isPending} />

      <Modal.PrimaryButton
        type="submit"
        form={FORM_ID}
        isLoading={createRoleMutation.isPending || updateRoleMutation.isPending}
        isDisabled={!isFormValid}
      >
        {t('global:words.c.validate')}
      </Modal.PrimaryButton>
    </Form>
  );
};

export const RoleEditModal: FC<RoleEditModalProps> = ({
  modal,
  roles,
  initialRoleValues,
  roleIdEdited,
  user,
  isAdministrator,
}) => {
  const { t } = useTranslation(['user']);

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      title={roleIdEdited ? t('user:editRoleModal.editionTitle') : t('user:editRoleModal.title')}
      scrollBehavior="inside"
      size="2xl"
    >
      <QueryWrapper>
        <RoleEditModalInternal
          key={roleIdEdited}
          modal={modal}
          roles={roles}
          initialRoleValues={initialRoleValues}
          roleIdEdited={roleIdEdited}
          user={user}
          isAdministrator={isAdministrator}
        />
      </QueryWrapper>
    </Modal>
  );
};
