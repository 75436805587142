import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useCurrency, Wizard } from '@graneet/lib-ui';
import { DOWN_PAYMENT_STATUSES, isNumberFinite } from '@graneet/business-logic';

import { EditDownPaymentAmountsStep } from './_steps/amounts/EditDownPaymentAmountsStep';
import { EditDownPaymentInformationStep } from './_steps/information/EditDownPaymentInformationStep';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { useContractsBySubProject } from 'features/contract/services/contract.api';
import type { DownPaymentEditionWizard } from 'features/down-payment/forms/DownPaymentEditionWizard';
import { Beacon } from 'features/beacon/components/Beacon';
import {
  useCreateOrUpdateSubProjectDownPayment,
  useSubProject,
  useSubProjectDownPayment,
} from 'features/sub-project/services/sub-project.api';
import { useProject } from 'features/project/services/project.api';

export const EditDownPaymentScreen: FC = () => {
  useHideNavbar();

  const { t } = useTranslation(['global', 'downPayment']);
  const history = useHistory();
  const { mapAmountToNumber } = useCurrency();

  const { projectId: rawProjectId, subProjectId } = useParams<{ projectId: string; subProjectId: string }>();
  const projectId = parseInt(rawProjectId, 10);

  const project = useProject(projectId);
  const subProject = useSubProject(subProjectId);
  const contracts = useContractsBySubProject(subProjectId);
  const downPayment = useSubProjectDownPayment(subProjectId);

  const createOrUpdateSubProjectDownPaymentMutation = useCreateOrUpdateSubProjectDownPayment();

  if (downPayment.data?.status === DOWN_PAYMENT_STATUSES.COMPLETED) {
    history.push(`/projects/${projectId}/statements`);
  }

  const wizardLabels = useWizardLabels({
    save: t(downPayment.data ? 'downPayment:actions.saveEdition' : 'downPayment:actions.saveCreation'),
  });

  const handleQuit = useCallback(() => {
    if (downPayment.data) {
      return history.push(`/projects/${projectId}/statements/down-payments/${downPayment.data?.id}`);
    }
    return history.push(`/projects/${projectId}/statements`);
  }, [history, downPayment.data, projectId]);

  const handleFinish = useCallback(
    async (formValues: DownPaymentEditionWizard) => {
      const {
        amounts: { type, percentage, amount },
        information: {
          name,
          invoiceNumber,
          billingDate,
          note,
          isInvoiced,
          paymentMethod: { id: paymentMethodId },
          deadlineType,
          paymentTerm,
        },
      } = formValues;

      await createOrUpdateSubProjectDownPaymentMutation.mutateAsync(
        {
          id: subProjectId,
          dto: {
            name,
            type,
            percentage: isNumberFinite(percentage) ? percentage : undefined,
            amountExVAT: isNumberFinite(amount) ? mapAmountToNumber(amount) : undefined,
            isInvoiced,
            invoiceNumber,
            billingDate,
            note,
            deadlineType,
            paymentTerm,
            paymentMethodId,
          },
        },
        {
          onSuccess: (dp) => {
            history.push(`/projects/${projectId}/statements/down-payments/${dp.id}`);
          },
        },
      );
    },
    [mapAmountToNumber, createOrUpdateSubProjectDownPaymentMutation, subProjectId, history, projectId],
  );

  return (
    <Wizard<DownPaymentEditionWizard>
      headerTitle={t('downPayment:wizard.title', { subProjectName: subProject.data.name })}
      labels={wizardLabels}
      onFinish={handleFinish}
      onQuit={handleQuit}
    >
      <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_HELP}>
        <Beacon top="-4rem" right="0.6rem" />
      </Wizard.Placeholder>
      <Wizard.Step<DownPaymentEditionWizard> name="amounts">
        <EditDownPaymentAmountsStep
          downPayment={downPayment.data}
          subProject={subProject.data}
          contracts={contracts.data}
        />
      </Wizard.Step>

      <Wizard.Step<DownPaymentEditionWizard> name="information">
        <QueryWrapper>
          <EditDownPaymentInformationStep downPayment={downPayment.data} project={project.data} />
        </QueryWrapper>
      </Wizard.Step>
    </Wizard>
  );
};
