import { PlaceholderProvider } from './PlaceholderContext';
import { PlaceholderInject } from './PlaceholderInject';
import { PlaceholderValue } from './PlaceholderValue';

export const APP_FOOTER_PLACEHOLDER = 'app-footer-placeholder';

export const Placeholder = {
  Provider: PlaceholderProvider,
  Value: PlaceholderValue,
  Inject: PlaceholderInject,
};
