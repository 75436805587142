import { Circle, HStack, Text } from '@chakra-ui/react';
import type { IProject, ISubProject, RequiredByKeys } from '@graneet/business-logic';
import { PROJECT_STATUSES } from '@graneet/business-logic';
import { Badge, EllipsisText } from '@graneet/lib-ui';
import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { getProjectColor } from '../../services/project-colors.util';

export type ProjectFieldOptionProps = {
  /**
   * The project witch render the formatted option
   */
  project: RequiredByKeys<IProject, 'address'>;

  subProject?: ISubProject | null;

  isSelected?: boolean;

  displayFull?: boolean;
};

/**
 * Render a AutocompleteField option for a project
 */
interface FieldTextWrapperProps extends PropsWithChildren {
  isSelected?: boolean;
}

const FieldTextWrapper: FC<FieldTextWrapperProps> = ({ isSelected, children }) => {
  if (!children) {
    return null;
  }

  return (
    <HStack px="0.1rem">
      {isSelected ? (
        <EllipsisText>
          <Text fontSize="sm" fontWeight={500} fontFamily="heading">
            {children}
          </Text>
        </EllipsisText>
      ) : (
        <Text fontSize="sm" fontFamily="heading" color="gray.500">
          {children}
        </Text>
      )}
    </HStack>
  );
};

export const ProjectFieldOption: FC<ProjectFieldOptionProps> = ({
  project,
  subProject,
  isSelected = false,
  displayFull = true,
}) => {
  const { t } = useTranslation(['project']);

  const displaySubProjectName = subProject && project.name !== subProject?.name && displayFull;

  return (
    <HStack alignItems="center" w="100%" overflow="hidden">
      <HStack alignItems="center" spacing={3} flexShrink={1} minWidth={0}>
        <Circle size="1rem" bg={project.color ? getProjectColor(project.color) : 'gray.500'} />
        <EllipsisText>
          <Text fontFamily="heading" display="inline">
            {project.name}
          </Text>
          {displaySubProjectName && (
            <Text fontFamily="heading" display="inline">
              {' – '}
              {subProject?.name}
            </Text>
          )}
        </EllipsisText>
      </HStack>

      {displayFull && (
        <HStack flex={1}>
          <FieldTextWrapper isSelected={isSelected}>{project.refCode}</FieldTextWrapper>
          <FieldTextWrapper isSelected={isSelected}>{project.address.address}</FieldTextWrapper>
          {project.status === PROJECT_STATUSES.ENDED && (
            <Badge colorScheme="green" w="5rem" h="1.5rem" mb="0.2rem">
              {t('project:status.ENDED')}
            </Badge>
          )}
        </HStack>
      )}
    </HStack>
  );
};
