import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { SectionProps } from '@graneet/lib-ui';
import { Section } from '@graneet/lib-ui';
import { Box, Grid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { HiddenField, useFormContext, useHiddenField } from 'graneet-form';
import type { DEADLINE_TYPE, ICompanyPaymentTermsUpdateDTO, IPaymentMethodResponseDTO } from '@graneet/business-logic';
import { isNil } from 'lodash-es';

import { sortPaymentMethods } from '../services/payment-method.util';

import { PaymentMethodEditionAndSelectionCard } from './PaymentMethodEditionAndSelectionCard';

import { PaymentDeadlineCard } from 'features/payment/components/PaymentDeadlineCard';

interface PaymentMethodSectionProps extends Omit<SectionProps, 'title' | 'children'> {
  paymentMethods: IPaymentMethodResponseDTO[];

  description: string;

  hideTitle?: boolean;
}

export interface PaymentMethodSectionForm {
  paymentTerm: number;

  deadlineType: DEADLINE_TYPE;

  paymentMethod: IPaymentMethodResponseDTO;
}

export const PaymentMethodSection: FC<PaymentMethodSectionProps> = ({
  paymentMethods,
  hideTitle = false,
  description,
  ...props
}) => {
  const { t } = useTranslation(['invoice', 'project']);

  const form = useFormContext<PaymentMethodSectionForm>();
  const paymentTermHiddenField = useHiddenField(form, 'paymentTerm');
  const deadlineTypeHiddenField = useHiddenField(form, 'deadlineType');
  const paymentMethodHiddenField = useHiddenField(form, 'paymentMethod');

  const { value: paymentTerm, setValue: setPaymentTerm } = paymentTermHiddenField;
  const { value: deadlineType, setValue: setDeadlineType } = deadlineTypeHiddenField;
  const { value: paymentMethod, setValue: setPaymentMethod } = paymentMethodHiddenField;

  // define update callbacks
  const onPaymentMethodUpdated = useCallback(
    (updatedPaymentMethod: IPaymentMethodResponseDTO) => {
      setPaymentMethod(updatedPaymentMethod);
    },
    [setPaymentMethod],
  );

  const onPaymentInfosUpdate = useCallback(
    (paymentInfos: ICompanyPaymentTermsUpdateDTO) => {
      if (isNil(paymentInfos.paymentTerm) || !paymentInfos.deadlineType) {
        throw new Error('paymentTerm or deadlineType is missing on the company');
      }

      setPaymentTerm(paymentInfos.paymentTerm);
      setDeadlineType(paymentInfos.deadlineType);
      return Promise.resolve(null);
    },
    [setDeadlineType, setPaymentTerm],
  );

  const sortedPaymentMethods = useMemo(() => sortPaymentMethods(paymentMethods), [paymentMethods]);

  return (
    <Section
      title={!hideTitle && t('invoice:steps.paymentConditions.title')}
      description={!hideTitle && t('invoice:steps.paymentConditions.description')}
      {...props}
    >
      <HiddenField {...paymentTermHiddenField} />
      <HiddenField {...deadlineTypeHiddenField} />
      <HiddenField {...paymentMethodHiddenField} />

      <Box w="100%" pb={6}>
        <Grid alignItems="flex-start" templateColumns="repeat(2, 1fr)" gap={6}>
          <PaymentMethodEditionAndSelectionCard
            paymentMethod={paymentMethod!}
            paymentMethods={sortedPaymentMethods}
            selectNewPaymentMethodLabel={t('project:editPaymentMethodModal.action')}
            onPaymentMethodUpdated={onPaymentMethodUpdated}
            onNewPaymentMethodSelected={setPaymentMethod}
            selectPaymentMethodModalDescription={description}
          />
          <PaymentDeadlineCard
            paymentInfos={{ deadlineType: deadlineType!, paymentTerm: paymentTerm! }}
            onPaymentInfosUpdate={onPaymentInfosUpdate}
          />
        </Grid>
      </Box>
    </Section>
  );
};
