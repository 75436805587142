import { Flex, Stack, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { IUserInvitation } from '@graneet/business-logic';
import { Form, useFormStatus, useStepForm } from 'graneet-form';
import { Button, Logo } from '@graneet/lib-ui';

import { OnboardingPasswordCheckList } from 'features/onboarding/components/OnboardingPasswordCheckList';
import type { OnboardingSignUpWizard } from 'features/onboarding/forms/OnboardingSignUpWizard';
import type { OnboardingCredentialsForm } from 'features/onboarding/components/OnboardingCredentialsForm';
import { OnboardingCredentials } from 'features/onboarding/components/OnboardingCredentials';

interface OnboardingSignUpProps {
  onSignUp(values: OnboardingCredentialsForm): Promise<void>;

  userInvitation: IUserInvitation;
}

export const OnboardingSignUp: FC<OnboardingSignUpProps> = ({ onSignUp, userInvitation }) => {
  const { t } = useTranslation(['onboarding']);

  const { form, initFormValues } = useStepForm<OnboardingSignUpWizard, 'userCredentials'>();

  const { isValid: isFormValid } = useFormStatus(form);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(async () => {
    const userCredentialsFormValues = form.getFormValues();

    setIsSubmitting(true);
    await onSignUp({ email: userCredentialsFormValues.email!, password: userCredentialsFormValues.password! });
    setIsSubmitting(false);
  }, [form, onSignUp]);

  useEffect(() => {
    initFormValues({
      email: userInvitation.email,
    });
  }, [initFormValues, userInvitation]);

  return (
    <Form form={form} style={{ width: '100%' }}>
      <Stack w="100%" gap={4}>
        <Flex justifyContent="center">
          <Text fontWeight={600} fontSize="2rem" textAlign="center" maxW="35rem">
            <Trans t={t} i18nKey="onboarding:invitation.signUp.slogan2">
              <Logo display="inline-block" verticalAlign="baseline" mb="-1px" h="1.5rem" w="auto" />
            </Trans>
          </Text>
        </Flex>

        <OnboardingCredentials emailDisabled />

        <Flex justifyContent="center">
          <Button w="25rem" onClick={onSubmit} isDisabled={!isFormValid} isLoading={isSubmitting}>
            {t('onboarding:actions.createAccount')}
          </Button>
        </Flex>

        <Flex justifyContent="center">
          <OnboardingPasswordCheckList w="25rem" />
        </Flex>
      </Stack>
    </Form>
  );
};
