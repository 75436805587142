import type { FC } from 'react';
import { useCallback } from 'react';
import { Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { FEATURE_FLAGS, type IContract } from '@graneet/business-logic';

import { useDeleteContract } from '../../services/contract.api';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useContractDelete as useContractDeleteFromQuotation } from 'features/contract/services/external/contract-external.api';

interface ContractDeleteModalProps {
  onClose(): void;

  isOpen: boolean;

  onContractDeleted(): void;

  contract: IContract;

  numberOfContracts: number;
}

export const ContractDeleteModal: FC<ContractDeleteModalProps> = ({
  onClose,
  isOpen,
  onContractDeleted,
  contract,
  numberOfContracts,
}) => {
  const { t } = useTranslation(['global', 'contracts']);

  const hasQuotationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);

  const deleteContractQuotationMutation = useContractDeleteFromQuotation();
  const deleteContractMutation = useDeleteContract();

  const handleContractDelete = useCallback(async () => {
    if (hasQuotationFeatureFlag) {
      await deleteContractQuotationMutation.mutateAsync(contract.id);
    } else {
      await deleteContractMutation.mutateAsync(contract.id);
    }

    onContractDeleted();
    onClose();
  }, [
    contract.id,
    deleteContractMutation,
    deleteContractQuotationMutation,
    hasQuotationFeatureFlag,
    onClose,
    onContractDeleted,
  ]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={t('contracts:deleteModal.title')}>
      <Text>
        {numberOfContracts === 1
          ? t('contracts:deleteModal.descriptionWithOneContract')
          : t('contracts:deleteModal.descriptionWithMultipleContract')}
      </Text>

      <Modal.Close isDisabled={deleteContractQuotationMutation.isPending || deleteContractMutation.isPending} />

      <Modal.PrimaryButton
        onClick={handleContractDelete}
        isLoading={deleteContractQuotationMutation.isPending || deleteContractMutation.isPending}
      >
        {t('global:words.c.confirm')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
