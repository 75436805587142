import type { FC } from 'react';
import { CUMULATIVE_INPUT_TYPE } from '@graneet/business-logic';
import { Price, useCurrency } from '@graneet/lib-ui';
import { useFormContext, useOnBlurValues } from 'graneet-form';

import type { ProgressStatementEditItemForm } from '../../forms/progress-statement-edit-item-form';
import { getItemCumulativeFieldName } from '../../forms/progress-statement-edit-item-form';

import type { IItemTree } from 'features/progress-statement/hooks/useProgressStatementTree';

interface ProgressStatementItemTotalLabelProps {
  item: IItemTree;

  isUpdatable: boolean;
}

export const ProgressStatementItemTotalLabel: FC<ProgressStatementItemTotalLabelProps> = ({ item }) => {
  const { mapAmountToNumber } = useCurrency();

  const itemKey = getItemCumulativeFieldName(item.id, CUMULATIVE_INPUT_TYPE.AMOUNT);
  const form = useFormContext<ProgressStatementEditItemForm>();
  const { [itemKey]: value } = useOnBlurValues(form, [itemKey]);

  return <Price amount={mapAmountToNumber(value || 0)} />;
};
