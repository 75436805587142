import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import type { IDashboardResponseDTO } from '@graneet/business-logic';
import { getCurrentFiscalDate } from '@graneet/business-logic';
import { Card, ComparativeAmounts } from '@graneet/lib-ui';

import { useAppContext } from 'features/app/contexts/AppContext';

interface DashboardProjectInvoicingCardProps {
  stats: IDashboardResponseDTO | null;
}

export const DashboardProjectsInvoicingCard: FC<DashboardProjectInvoicingCardProps> = ({ stats }) => {
  const { t } = useTranslation(['dashboard']);

  const { currentUser } = useAppContext();
  // dayjs wrapper is necessary to use current locale
  const fiscalDate = currentUser?.company ? dayjs(getCurrentFiscalDate(currentUser?.company)) : null;

  return (
    <Card title={t('dashboard:projectsInvoicing')}>
      <ComparativeAmounts
        firstAmountProps={{
          text: t('dashboard:invoicedAmountExVAT'),
          amount: stats?.invoicedAmountExVAT || 0,
          description: t('dashboard:fromFiscalYearBeginning', {
            month: fiscalDate?.format('MMMM'),
            year: fiscalDate?.format('YYYY'),
          }),
          tooltip: t('dashboard:invoicedAmountHelper'),
        }}
        secondAmountProps={{
          text: t('dashboard:amountToBeBilledExVAT'),
          amount: stats?.amountToBeBilledExVAT || 0,
          description: t('dashboard:onAllProjects'),
        }}
      />
    </Card>
  );
};
