import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { IBill, IProjectSubProjectWithBillsListingDTO } from '@graneet/business-logic';
import { Table } from '@graneet/lib-ui';
import { Box, Stack } from '@chakra-ui/react';

import { BillExpansibleRow } from 'features/bill/components/BillExpansibleRow';

interface FinalStatementBillTableProps {
  subProjects: IProjectSubProjectWithBillsListingDTO['subProjects'];

  onPay(bill: IBill): void;
}
export const FinalStatementBillTable: FC<FinalStatementBillTableProps> = ({ subProjects, onPay }) => {
  const { t } = useTranslation(['bill']);

  return (
    <Stack spacing={4}>
      {subProjects.map((subProject, index) => (
        <Box borderRadius="6px" boxShadow="subtle" overflow="hidden" key={subProject.id}>
          <Table
            templateColumns={[
              '4rem',
              'minmax(auto, 10rem)',
              'minmax(13rem, 2fr)',
              '8rem',
              '15rem',
              '15rem',
              '15rem',
              '6rem',
            ]}
            bg="white"
          >
            {index === 0 && (
              <Table.Header>
                <Table.Cell />

                <Table.Cell>{t('bill:fields.invoiceNumber')}</Table.Cell>

                <Table.Cell>{t('bill:fields.name')}</Table.Cell>

                <Table.Cell center>{t('bill:fields.status')}</Table.Cell>

                <Table.Cell center>{t('bill:fields.lateDays')}</Table.Cell>

                <Table.Cell right>{t('bill:fields.amountIncVAT')}</Table.Cell>

                <Table.Cell right>{t('bill:fields.remainingToBePaidIncVAT')}</Table.Cell>

                <Table.Cell />
              </Table.Header>
            )}

            {/* Sub project row */}
            {subProjects.length > 1 && (
              <Table.Row bg="gray.200" textColor="gray.900">
                <Table.Cell gridColumn="span 8">{subProject.name}</Table.Cell>
              </Table.Row>
            )}

            {(subProject.bills || []).map((bill) => (
              <BillExpansibleRow key={bill.id} bill={bill} onPay={onPay} />
            ))}
          </Table>
        </Box>
      ))}
    </Stack>
  );
};
