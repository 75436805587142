import type { DrawerProps } from '@chakra-ui/react';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Flex,
  Box,
  IconButton,
  DrawerOverlay,
} from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { useState } from 'react';

import { SimpleCloseIcon } from '../Icons';
import { ScrollProvider } from '../../hooks/ScrollContext';

import { useDrawersStackContext } from './useDrawersStack';
import type { DrawerApi } from './useDrawer';

const DRAWER_SPACING_HORIZONTAL = -20;

const FORCE_DRAWER_SHADOW = {
  '&:focus:not([data-focus-visible-added])': {
    shadow: 'dark-lg', // default shadow
  },
};

const getDrawerOffset = (index: number) => `${index * DRAWER_SPACING_HORIZONTAL}px`;

export interface DrawersStackDrawerProps<Handle extends string> {
  title: ReactNode;

  footer?: ReactNode;

  drawer: DrawerApi<Handle>;

  children: ReactNode;

  onOverlayClick?(): void;

  size?: string;

  placement?: DrawerProps['placement'];
}

export const DrawersStackDrawer = <Handle extends string>({
  title,
  footer,
  drawer,
  children,
  onOverlayClick,
  placement = 'right',
  size = 'xl',
}: DrawersStackDrawerProps<Handle>) => {
  const { getIndex, getOffsetIndex } = useDrawersStackContext();

  const offset = getOffsetIndex(drawer.handle);
  const index = getIndex(drawer.handle);

  // Ref of ModalBody is transmitted to Infinite scroll because we have to get component size
  // HACK: dirty fix for bad infinite scroll behaviour (#2412)
  const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);

  return (
    <Drawer
      isOpen={drawer.isOpen}
      placement={placement}
      onClose={drawer.onClose}
      autoFocus={false}
      size={size}
      onOverlayClick={onOverlayClick}
    >
      <ScrollProvider value={bodyRef}>
        {index === 0 && <DrawerOverlay />}

        <Box
          transform={`translateX(${getDrawerOffset(offset)})`}
          transition="0.15s transform ease-in-out"
          h="100vh"
          w="100%"
          top={0}
          right={0}
          position="fixed"
          zIndex="modal"
        >
          <DrawerContent shadow="dark-lg" sx={FORCE_DRAWER_SHADOW}>
            <DrawerHeader
              py={1}
              px={2}
              borderBottomWidth="1px"
              fontSize="xl"
              color="gray.800"
              fontWeight="600"
              fontFamily="heading"
            >
              <Flex flexShrink={0} alignItems="center" justifyContent="space-between">
                <Box h="100%" w="100%">
                  {title}
                </Box>

                <IconButton
                  aria-label="Close"
                  bg="none"
                  icon={<SimpleCloseIcon stroke="gray.500" />}
                  onClick={drawer.onClose}
                />
              </Flex>
            </DrawerHeader>

            <DrawerBody py={4} px={6} ref={setBodyRef}>
              {children}
            </DrawerBody>

            {footer && (
              <DrawerFooter borderTopWidth="1px" fontSize="sm">
                {footer}
              </DrawerFooter>
            )}
          </DrawerContent>
        </Box>
      </ScrollProvider>
    </Drawer>
  );
};
