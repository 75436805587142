import type {
  BATIPRIX_SESSION_TYPE,
  IBatiprixInformationResponse,
  IBatiprixTokenResponseDTO,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getEnvValue } from 'config/env';
import { apiNew } from 'features/api/services/apiNew.service';

const BATIPRIX_PATH = '/third-parties/batiprix';

const batiprixKeyFactory = createQueryKeys('batiprix', {
  getInformation: () => ({
    queryKey: [BATIPRIX_PATH],
    queryFn: () => apiNew.get<never, IBatiprixInformationResponse>(BATIPRIX_PATH),
  }),
  getToken: (dto: { batiprixSessionType: BATIPRIX_SESSION_TYPE; typeId: number }) => ({
    queryKey: [BATIPRIX_PATH, dto],
    queryFn: () =>
      apiNew.get<{ batiprixSessionType: BATIPRIX_SESSION_TYPE; typeId: number }, IBatiprixTokenResponseDTO>(
        `${BATIPRIX_PATH}/token`,
        dto,
      ),
  }),
});

export function useBatiprixToken(dto: { batiprixSessionType: BATIPRIX_SESSION_TYPE; typeId: number }) {
  return useSuspenseQuery(batiprixKeyFactory.getToken(dto));
}

export function useBatiprixInformation() {
  return useSuspenseQuery(batiprixKeyFactory.getInformation());
}

export const getBatiprixIframe = (sessionId: string, token: string) =>
  `${getEnvValue(
    'REACT_APP_BATIPRIX_URL',
    true,
  )}/BibliothequeUI/Ouvrage/SearchOuvrages?customerprojectid=${sessionId}&token=${token}`;
