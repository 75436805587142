import type { FC } from 'react';
import { useMemo } from 'react';
import { DeepTable, PriceAdvanced } from '@graneet/lib-ui';

import { useContractItemComputedValue } from '../../hooks/tree.hooks';

interface ContractItemAmountsProps {
  id: string | number;
}

export const ContractItemAmounts: FC<ContractItemAmountsProps> = ({ id }) => {
  const itemComputedValue = useContractItemComputedValue(id);

  const textStyle = useMemo(() => {
    if (itemComputedValue?.totalExVATDifference) {
      return {
        fontWeight: '700',
      };
    }
    return {
      fontWeight: '500',
    };
  }, [itemComputedValue?.totalExVATDifference]);

  return (
    <>
      <DeepTable.Cell right>
        <PriceAdvanced amount={itemComputedValue?.totalExVAT} fontSize="md" {...textStyle} />
      </DeepTable.Cell>

      <DeepTable.Cell right>
        {!!itemComputedValue?.totalExVATDifference && (
          <PriceAdvanced
            amount={itemComputedValue?.totalExVATDifference}
            fontSize="md"
            sign
            isAmountColored
            {...textStyle}
          />
        )}
      </DeepTable.Cell>
    </>
  );
};
