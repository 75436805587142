import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PERMISSION, type IProgressStatement } from '@graneet/business-logic';
import { Card } from '@graneet/lib-ui';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { useStatementsAvailableTags } from 'features/statement/services/statement.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useProgressStatementEditTags } from 'features/progress-statement/services/progress-statement.api';

interface ProgressStatementTagsCardProps {
  progressStatement: IProgressStatement;
}

export const ProgressStatementTagsCard: FC<ProgressStatementTagsCardProps> = ({ progressStatement }) => {
  const { t } = useTranslation(['global']);
  const hasPermission = usePermissions([PERMISSION.ACCESS_STATEMENTS, PERMISSION.CREATE_STATEMENTS]);

  const tags = useStatementsAvailableTags();

  const progressStatementEditTagsMutation = useProgressStatementEditTags();

  const onTagsSaved = useCallback(
    (newTags: string[]) =>
      progressStatementEditTagsMutation.mutateAsync({
        id: progressStatement.id,
        dto: { tags: newTags },
      }),
    [progressStatement.id, progressStatementEditTagsMutation],
  );

  if (!progressStatement.tags && !hasPermission) {
    return null;
  }

  return (
    <Card title={t('global:words.privateTags')}>
      <TagsEditionBlock
        placeholder={t('global:changeTags.placeholder')}
        tags={progressStatement.tags}
        suggestedTags={tags.data}
        onTagsEdited={onTagsSaved}
        isEditable={hasPermission}
      />
    </Card>
  );
};
