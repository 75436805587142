import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Section } from '@graneet/lib-ui';
import { Flex } from '@chakra-ui/react';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { ValidationStepSupplierInvoiceList } from 'features/validation-step/components/ValidationStepSupplierInvoiceList';

export const WorkflowValidationTab: FC = () => {
  const { t } = useTranslation(['validationStep']);
  return (
    <Flex maxW="50rem">
      <Container>
        <Section
          title={t('validationStep:supplierInvoice.title')}
          description={t('validationStep:supplierInvoice.description')}
        >
          <QueryWrapper errorMessage={t('validationStep:listingError')}>
            <ValidationStepSupplierInvoiceList />
          </QueryWrapper>
        </Section>
      </Container>
    </Flex>
  );
};
