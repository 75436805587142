import { Price, useCurrency } from '@graneet/lib-ui';
import { useFormContext, useOnBlurValues } from 'graneet-form';
import { CUMULATIVE_INPUT_TYPE } from '@graneet/business-logic';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { IContractTree } from '../../hooks/useProgressStatementTree';
import { useProgressStatementLotOrContainerComputedValue } from '../../hooks/useProgressStatementTree';
import type {
  CustomDiscountCumulativeFieldName,
  ProgressStatementEditItemForm,
} from '../../forms/progress-statement-edit-item-form';
import {
  getCustomDiscountCumulativeFieldName,
  getDiscountCumulativeFieldName,
} from '../../forms/progress-statement-edit-item-form';
import type { ContractId } from '../../services/progress-statement-tree.util';
import { getIdFromContractId } from '../../services/progress-statement-tree.util';

interface ProgressStatementContratTotalLabelProps {
  id: ContractId;

  contract: IContractTree;
}

export const ProgressStatementContratTotalLabel: FC<ProgressStatementContratTotalLabelProps> = ({ id, contract }) => {
  const { mapAmountToNumber } = useCurrency();

  const contractId = getIdFromContractId(contract.id);

  const customDiscountNames = useMemo<CustomDiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE>[]>(
    () =>
      (contract.customDiscounts || []).map((customDiscount) =>
        getCustomDiscountCumulativeFieldName(customDiscount.id, CUMULATIVE_INPUT_TYPE.AMOUNT),
      ),
    [contract.customDiscounts],
  );

  const form = useFormContext<ProgressStatementEditItemForm>();
  const customDiscountValues = useOnBlurValues(form, customDiscountNames);
  const { [getDiscountCumulativeFieldName(contractId, CUMULATIVE_INPUT_TYPE.AMOUNT)]: discountAmount } =
    useOnBlurValues(form, [getDiscountCumulativeFieldName(contractId, CUMULATIVE_INPUT_TYPE.AMOUNT)]);
  const computedValues = useProgressStatementLotOrContainerComputedValue(id);

  const cumulativeAmountExVATContract = useMemo(() => {
    const totalItemsAmountExVAT = computedValues?.cumulativeAmountExVAT || 0;

    const totalCustomDiscountsAmountExVAT = Object.values(customDiscountValues).reduce<number>(
      (acc, customDiscount) => acc + (customDiscount || 0),
      0,
    );

    return (
      totalItemsAmountExVAT -
      mapAmountToNumber(discountAmount || 0) +
      mapAmountToNumber(totalCustomDiscountsAmountExVAT)
    );
  }, [computedValues?.cumulativeAmountExVAT, customDiscountValues, discountAmount, mapAmountToNumber]);

  return <Price amount={cumulativeAmountExVATContract} />;
};
