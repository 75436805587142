import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, DocumentStatusCardActions, GotoLink } from '@graneet/lib-ui';
import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import type { IProject, SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';
import { PERMISSION, SUPPLIER_INVOICE_STATUS, FEATURE_FLAGS } from '@graneet/business-logic';
import { useHistory } from 'react-router-dom';

import { SupplierInvoicePaymentsEditModal } from '../../modals/SupplierInvoicePaymentsEditModal';
import { useSupplierInvoiceDelete, useSupplierInvoiceUpdateStatus } from '../../../services/supplier-invoice.api';
import { SupplierInvoiceDeleteModal } from '../../modals/SupplierInvoiceDeleteModal/SupplierInvoiceDeleteModal';
import { SupplierInvoiceRevertStatusModal } from '../../modals/SupplierInvoiceRevertStatusModal';
import { useSupplierInvoiceEditButtonProps } from '../../useSupplierInvoiceEditButton';
import { useSupplierInvoiceGoToListing } from '../../../contexts/SupplierInvoiceEditContext.provider';
import { SupplierInvoiceDocumentStatusCard } from '../../SupplierInvoiceDocumentStatusCard';
import { SupplierInvoicePaymentModal } from '../../SupplierInvoicePaymentModal';

import { getSupplierInvoiceActions } from './supplier-invoice.actions';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useGetValidationStepsSupplierInvoice } from 'features/validation-step/services/validation-step-supplier-invoice.api';

interface SupplierInvoiceStatusCardProps {
  project: IProject | undefined;
  onSeeValidationHistory: () => void;
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}

export const SupplierInvoiceStatusCard: FC<SupplierInvoiceStatusCardProps> = ({
  project,
  supplierInvoice,
  onSeeValidationHistory,
}) => {
  const { t } = useTranslation(['global', 'supplierInvoices']);

  const goToListing = useSupplierInvoiceGoToListing({ project });

  const updateToPayModal = useDisclosure();
  const deleteModal = useDisclosure();
  const supplierInvoiceRevertStatusModal = useDisclosure();
  const payModal = useDisclosure();

  const hasPaySupplierInvoicePermission = usePermissions([PERMISSION.PAY_SUPPLIER_INVOICE]);
  const hasUpdateSupplierInvoicePermission = usePermissions([PERMISSION.UPDATE_SUPPLIER_INVOICE_PAYMENT]);
  const hasSupplierInvoiceDeletePermission = usePermissions([PERMISSION.CREATE_SUPPLIER_INVOICE]);
  const hasPayPermissions = hasPaySupplierInvoicePermission && hasUpdateSupplierInvoicePermission;

  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);
  const hasBankingConnectionFF = useFeatureFlag(FEATURE_FLAGS.BANKING_ACCOUNT_CONNECTION);

  const orderedValidationSteps = useGetValidationStepsSupplierInvoice();

  const supplierInvoiceUpdateStatusMutation = useSupplierInvoiceUpdateStatus();
  const supplierInvoiceDeleteMutation = useSupplierInvoiceDelete();

  // @[ff: workflow-validation]
  let canAddPayment = !hasWorkflowValidationFeatureFlag;
  if (orderedValidationSteps.data.length > 0) {
    canAddPayment = orderedValidationSteps.data[0].validationCondition.value > (supplierInvoice.amountExVAT ?? 0);
  }

  let isValidationStepHistory;
  if (supplierInvoice.status === SUPPLIER_INVOICE_STATUS.TO_PROCESS) {
    isValidationStepHistory = false;
    // @[ff: workflow-validation]
  } else if (hasWorkflowValidationFeatureFlag && orderedValidationSteps.data.length > 0) {
    isValidationStepHistory =
      orderedValidationSteps.data[0].validationCondition.value <= (supplierInvoice.amountExVAT ?? 0);
  } else {
    isValidationStepHistory = false;
  }

  const updateStatus = useCallback(
    (newStatus: SUPPLIER_INVOICE_STATUS.TO_PROCESS | SUPPLIER_INVOICE_STATUS.TO_PAY) => async () => {
      await supplierInvoiceUpdateStatusMutation.mutateAsync({ id: supplierInvoice.id, dto: { status: newStatus } });
    },
    [supplierInvoice, supplierInvoiceUpdateStatusMutation],
  );

  const onDeleted = useCallback(async () => {
    await supplierInvoiceDeleteMutation.mutateAsync(supplierInvoice.id);
    goToListing();
  }, [supplierInvoiceDeleteMutation, supplierInvoice, goToListing]);

  const handleConfirmationRevertStatus = useCallback(() => {
    supplierInvoiceRevertStatusModal.onClose();
    updateStatus(SUPPLIER_INVOICE_STATUS.TO_PROCESS)();
  }, [supplierInvoiceRevertStatusModal, updateStatus]);

  const history = useHistory();
  const goToEdition = useCallback(() => {
    if (project) {
      history.push(`/projects/${project.id}/purchases/supplier-invoices/${supplierInvoice.id}/edit`);
    } else {
      history.push(`/purchases/supplier-invoices/${supplierInvoice.id}/edit`);
    }
  }, [history, project, supplierInvoice.id]);

  const hasSupplierInvoiceWithPayments = [
    SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID,
    SUPPLIER_INVOICE_STATUS.TO_PAY,
    SUPPLIER_INVOICE_STATUS.PAID,
  ].includes(supplierInvoice.status);

  const supplierInvoiceEditButtonProps = useSupplierInvoiceEditButtonProps({
    supplierInvoice,
    allowDirectPaymentEdition: false,
  });

  return (
    <Card
      title={t('supplierInvoices:cards.stateCard.title')}
      topRightContent={
        hasSupplierInvoiceWithPayments && (
          <GotoLink
            to={`/purchases/cash-outflows/${supplierInvoice.id}`}
            label={t(`supplierInvoices:goTo.cashOutflow`)}
          />
        )
      }
    >
      <Box mb={4}>
        <SupplierInvoiceDocumentStatusCard supplierInvoice={supplierInvoice} />
      </Box>

      <HStack gap={3}>
        <DocumentStatusCardActions
          actions={getSupplierInvoiceActions({
            supplierInvoice,
            permissions: {
              hasUpdateStatusPermission: hasUpdateSupplierInvoicePermission,
              hasDeletePermission: hasSupplierInvoiceDeletePermission,
              hasPayPermissions,
              canAddPayment,
              canSeeValidationHistory: isValidationStepHistory,
              canUpdate: !supplierInvoiceEditButtonProps.isDisabled,
            },
            tooltips: {
              onUpdate: supplierInvoiceEditButtonProps.tooltip,
            },
            callbacks: {
              onAddPayment: updateToPayModal.onOpen,
              onUpdateToProcess: supplierInvoice.currentValidationStep
                ? supplierInvoiceRevertStatusModal.onOpen
                : updateStatus(SUPPLIER_INVOICE_STATUS.TO_PROCESS),
              onUpdateToPay: updateStatus(SUPPLIER_INVOICE_STATUS.TO_PAY),
              onDelete: deleteModal.onOpen,
              onSeeValidationHistory,
              onUpdate: goToEdition,
              onPay: payModal.onOpen,
            },

            // @[ff: workflow-validation]
            hasWorkflowValidationFeatureFlag,
            hasBankingConnectionFF,
          })}
        />
      </HStack>

      <SupplierInvoicePaymentsEditModal
        supplierInvoice={supplierInvoice}
        isOpen={updateToPayModal.isOpen}
        onClose={updateToPayModal.onClose}
      />

      <SupplierInvoiceDeleteModal
        supplierInvoice={supplierInvoice}
        onDeleted={onDeleted}
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
      />

      <SupplierInvoiceRevertStatusModal
        isOpen={supplierInvoiceRevertStatusModal.isOpen}
        onClose={supplierInvoiceRevertStatusModal.onClose}
        onConfirmation={handleConfirmationRevertStatus}
      />

      <SupplierInvoicePaymentModal
        supplierInvoice={supplierInvoice}
        isOpen={payModal.isOpen}
        onClose={payModal.onClose}
      />
    </Card>
  );
};
