import { useCallback } from 'react';

import { useMoveLotAfterOtherLot } from './useMoveLotAfterOtherLot';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useMoveLotAtEndOfQuote = () => {
  const { getCurrentQuoteData } = useQuoteEditContext();
  const moveLotAfterOtherLot = useMoveLotAfterOtherLot();

  return useCallback(
    async (lotId: number) => {
      const { relations, rootLotId } = getCurrentQuoteData();
      const rootLotSublots = relations[rootLotId]?.lots;
      if (!rootLotSublots) return;

      const lastLotIdInsideRootLot = rootLotSublots[rootLotSublots.length - 1];
      if (lastLotIdInsideRootLot === undefined) return;

      await moveLotAfterOtherLot(lotId, lastLotIdInsideRootLot);
    },
    [moveLotAfterOtherLot, getCurrentQuoteData],
  );
};
