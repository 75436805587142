import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Callout, Modal, SimpleAlertIcon, SimpleCheckCircleIcon } from '@graneet/lib-ui';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { CircularProgress, Text, VStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'graneet-form';
import type { IGeneratePdfModelsDTO, IPdf, PDF_TEMPLATES_TYPES } from '@graneet/business-logic';
import { PDF_STATUSES, SUPPORT_EMAIL } from '@graneet/business-logic';

import { subscribeToPDFUpdates } from '../../hooks/usePdfVersions';
import { getDownloadUrl, useGeneratePdfExample } from '../../services/pdf.api';

import { downloadFile, formatFileName } from 'features/file/services/file.util';
import type { PDFSettingsFormValues } from 'features/pdf/constants/pdf-settings.constant';
import { TIME_DISPLAY_LOADER } from 'features/common/constants/loading.constant';

interface PdfGenerateExampleModalProps {
  modal: UseDisclosureReturn;
}

export const PdfGenerateExampleModal: FC<PdfGenerateExampleModalProps> = ({ modal }) => {
  const { t } = useTranslation(['global', 'pdfSettings']);
  const { template } = useParams<{ template: PDF_TEMPLATES_TYPES }>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const { getFormValues } = useFormContext<PDFSettingsFormValues>();

  const { onClose, isOpen } = modal;

  const generatePdfExample = useGeneratePdfExample();

  const handleLoaded = useCallback(() => {
    setIsLoaded(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const handleReset = useCallback(() => {
    if (isLoaded) {
      onClose();
      setIsError(false);
      setIsLoaded(false);
    }
  }, [isLoaded, onClose]);

  const handlePDFDownload = useCallback(
    (pdf: IPdf) => (pdfStatus: PDF_STATUSES) => {
      if (pdfStatus === PDF_STATUSES.ERROR) {
        setIsError(true);
      } else if (pdf.apiId) {
        // to change file name
        const filename = formatFileName(t(`pdfSettings:${template}.documentName`), new Date());

        const downloadUrl = getDownloadUrl(pdf.apiId, filename);

        if (downloadUrl) {
          // Browser download
          downloadFile(downloadUrl, filename);
        }
      }
      handleLoaded();
    },
    [handleLoaded, t, template],
  );

  const generateDTO = useCallback((): IGeneratePdfModelsDTO => {
    const {
      areAccountManagersVisible,
      companyAddressPosition,
      density,
      hasFirstPageCover,
      labelsPosition,
      labelsSize,
      logoPosition,
      logoSize,
      font,
      color,
      lotInformation,
      displayExternalProgressStatementQrCode,
    } = getFormValues();

    return {
      template,
      settings: {
        areAccountManagersVisible: areAccountManagersVisible!,
        companyAddressPosition: companyAddressPosition!,
        density: density!,
        hasFirstPageCover: hasFirstPageCover!,
        labelsPosition: labelsPosition!,
        labelsSize: labelsSize!,
        logoPosition: logoPosition!,
        logoSize: logoSize!,
        font: font!,
        color: color!,
        lotInformation: lotInformation!,
        displayExternalProgressStatementQrCode: displayExternalProgressStatementQrCode!,
      },
    };
  }, [getFormValues, template]);

  const generateExample = useCallback(() => {
    if (!isLoaded) {
      timeoutRef.current = setTimeout(async () => {
        const pdf = await generatePdfExample.mutateAsync(generateDTO(), {
          onError: () => {
            setIsError(true);
            handleLoaded();
          },
        });

        subscribeToPDFUpdates(pdf, handlePDFDownload(pdf));
      }, TIME_DISPLAY_LOADER);
    }
    return () => clearTimeout(timeoutRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateDTO, generatePdfExample.mutateAsync, handleLoaded, handlePDFDownload, isLoaded]);

  useEffect(() => {
    if (isOpen) {
      return generateExample();
    }

    return () => {};
  }, [generateExample, isLoaded, isOpen]);

  return (
    <Modal
      size="2xl"
      closeOnEsc={isLoaded}
      closeOnOverlayClick={isLoaded}
      isOpen={isOpen}
      onClose={handleReset}
      title={t('pdfSettings:downloadModal.title')}
    >
      {!isLoaded ? (
        <VStack spacing={6} mb={4}>
          <CircularProgress size={70} color="gray.800" isIndeterminate />
          <Text>{t('pdfSettings:downloadModal.pending')}</Text>
        </VStack>
      ) : (
        <Callout
          mb={4}
          colorScheme={isError ? 'red' : 'green'}
          icon={
            isError ? (
              <SimpleAlertIcon boxSize={5} stroke="red.500" />
            ) : (
              <SimpleCheckCircleIcon boxSize={5} stroke="green.500" />
            )
          }
        >
          {isError ? t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }) : t('pdfSettings:downloadModal.success')}
        </Callout>
      )}

      <Modal.PrimaryButton isDisabled={!isLoaded} onClick={handleReset}>
        {t('pdfSettings:downloadModal.back')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
