import type { ISupplier } from '@graneet/business-logic';
import { PERMISSION, LEDGER_TYPES } from '@graneet/business-logic';
import { StepperModal, TextField, Tooltip } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus, useWizardContext } from 'graneet-form';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, SimpleGrid, Stack } from '@chakra-ui/react';

import { AddressesWithBillingFields } from 'features/common/components/fields/AddressesWithBillingFields';
import { usePermissions } from 'features/role/hooks/usePermissions';
import type { SupplierEditForm } from 'features/supplier/forms/supplier-edit-form';
import { Rule } from 'features/form/rules/Rule';
import { LedgerField } from 'features/ledger/components/LedgerField';
import { SupplierTypeField } from 'features/supplier/components/SupplierTypeField';
import { useSupplierCreate } from 'features/supplier/services/supplier.api';
import { mapSupplierModalFormToSupplierDTO } from 'features/supplier/services/supplier.form';

interface SupplierCreateStepProps {
  handleSuccess: (supplier: ISupplier) => void;
  onClose: () => void;
}

export const SupplierCreateStep: FC<SupplierCreateStepProps> = ({ handleSuccess, onClose }) => {
  const { t } = useTranslation(['supplier', 'global', 'clients']);

  const form = useForm<SupplierEditForm>({
    defaultValues: {
      hasNoBillingAddress: true,
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  const supplierCreateMutation = useSupplierCreate();

  const { handleOnNext } = useWizardContext();

  const handleSubmit = useCallback(() => {
    const values = form.getFormValues();

    supplierCreateMutation.mutate(
      mapSupplierModalFormToSupplierDTO({
        ...values,
        auxiliaryAccount: hasUpdateAccountingPermission ? values.auxiliaryAccount : undefined,
      } as SupplierEditForm),
      {
        onSuccess: (response) => {
          handleSuccess(response);
          handleOnNext();
        },
      },
    );
  }, [form, supplierCreateMutation, hasUpdateAccountingPermission, handleSuccess, handleOnNext]);

  return (
    <Box w="full">
      <Form form={form}>
        <Stack gap={4}>
          <SimpleGrid columns={2} row={2} gap={4}>
            <TextField<SupplierEditForm> name="name" label={t('supplier:fields.supplierName')} isRequired>
              <Rule.IsRequired />
            </TextField>

            <LedgerField<SupplierEditForm, 'code'>
              name="code"
              isAutoGenerationDisabled={false}
              ledgerType={LEDGER_TYPES.SUPPLIER}
              label={t('supplier:fields.supplierCode')}
            />

            <SupplierTypeField<SupplierEditForm>
              isRequired
              name="type"
              label={t('supplier:fields.type')}
              placeholder={t('supplier:placeholder.type')}
            >
              <Rule.IsRequired />
            </SupplierTypeField>

            <Tooltip
              label={t('supplier:tooltips.noPermissionForAuxiliaryAccountUpdate')}
              isDisabled={hasUpdateAccountingPermission}
              shouldWrapChildren
            >
              <TextField<SupplierEditForm>
                name="auxiliaryAccount"
                label={t('supplier:fields.auxiliaryAccount')}
                isReadOnly={!hasUpdateAccountingPermission}
              />
            </Tooltip>

            <TextField<SupplierEditForm> name="iban" label={t('supplier:fields.iban')}>
              <Rule.IsIban />
            </TextField>
          </SimpleGrid>

          <AddressesWithBillingFields twoLines />
        </Stack>
      </Form>
      <StepperModal.SecondaryButton onClick={onClose}>{t('clients:modal.buttons.cancel')}</StepperModal.SecondaryButton>
      <StepperModal.PrimaryButton
        isDisabled={!isFormValid}
        onClick={handleSubmit}
        isLoading={supplierCreateMutation.isPending}
      >
        {t('clients:modal.buttons.next')}
      </StepperModal.PrimaryButton>
    </Box>
  );
};
