import type { FC } from 'react';
import { useCallback } from 'react';
import { Button, Callout, FileList, Modal, useFileList, useToast } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import type { IProject } from '@graneet/business-logic';
import { FEATURE_FLAGS, MINDEE_FILE_TYPE } from '@graneet/business-logic';
import { Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { useSupplierInvoiceImportBatch } from '../../services/supplier-invoice.api';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface SupplierInvoiceBatchImportModalProps {
  project?: IProject;

  isOpen: boolean;

  onClose: () => void;

  onImported: () => void;
}

export const SupplierInvoiceBatchImportModal: FC<SupplierInvoiceBatchImportModalProps> = ({
  project,
  isOpen,
  onClose,
  onImported,
}) => {
  const { t } = useTranslation(['supplierInvoices']);
  const toast = useToast();

  const hasEmailImportFeatureFlag = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_IMPORT_BY_EMAIL);

  const supplierInvoiceImportBatchMutation = useSupplierInvoiceImportBatch();

  const handleSuccess = useCallback(() => {
    onClose();
    onImported();
    toast.success(t('supplierInvoices:supplierInvoiceImportModal.toasts.success'));
  }, [toast, onClose, onImported, t]);

  const handleError = useCallback(() => {
    onImported();
    toast.warning(t('supplierInvoices:supplierInvoiceImportModal.toasts.error'));
  }, [toast, onImported, t]);

  const fileList = useFileList({
    onSubmit: (files) =>
      supplierInvoiceImportBatchMutation.mutateAsync({
        dto: { projectId: project ? project.id.toString() : undefined },
        files,
      }),
    onError: handleError,
    onSuccess: handleSuccess,
    batchLength: 3,
  });

  const history = useHistory();
  const onSeeMore = useCallback(() => {
    history.push('/settings/company/email/email-imports');
  }, [history]);

  return (
    <Modal
      title={t('supplierInvoices:supplierInvoiceImportModal.title')}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      {hasEmailImportFeatureFlag && (
        <Box mb={3}>
          <Callout>
            <Trans t={t} i18nKey="supplierInvoices:supplierInvoiceImportModal.emailImportInformation">
              <Button m={2} onClick={onSeeMore} variant="outline">
                display_content
              </Button>
            </Trans>
          </Callout>
        </Box>
      )}

      <FileList fileList={fileList} accept={MINDEE_FILE_TYPE} openOnMount />

      <Modal.CloseButton isDisabled={fileList.isUploading} />
      {!fileList.isUploaded && (
        <Modal.PrimaryButton
          isLoading={fileList.isUploading}
          isDisabled={fileList.files.length === 0}
          onClick={fileList.sendFiles}
        >
          {t('supplierInvoices:supplierInvoiceImportModal.submitButton')}
        </Modal.PrimaryButton>
      )}
    </Modal>
  );
};
