import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Form, HiddenField, useForm, useFormContext, useHiddenField } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';
import {
  ActionMenu,
  DeepTable,
  INPUT_NOTE_DEEP_TABLE_STYLE,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
  RichTextField,
  SimpleRewindIcon,
} from '@graneet/lib-ui';
import { GridItem } from '@chakra-ui/react';

import { useContractLot, useContractTreeContext } from '../../hooks/tree.hooks';
import type { ContractEditionForm } from '../../forms/contract-edition.form';
import { getLotFieldName } from '../../forms/contract-edition.form';

import { STYLE_CONTRACT_LOT_NOTE, STYLE_CONTRACT_LOT_NOTE_INPUT } from 'features/contract/constants/styles';
import { getDeepTableOffset } from 'features/contract/services/contract.util';

interface ContractLotNoteProps {
  id: string | number;

  depth: number;

  onUpdateNote(newNote: undefined | null | string): () => void;

  hasInputFocused: boolean;

  onFocus(): void;
}

interface NoteForm {
  note?: string | null;
}

export const ContractLotNote: FC<ContractLotNoteProps> = ({ id, depth, onUpdateNote, hasInputFocused, onFocus }) => {
  const parentForm = useFormContext<ContractEditionForm>();
  const hiddenField = useHiddenField(parentForm, getLotFieldName(id, 'note'));

  const form = useForm<NoteForm>();

  const {
    node: lot,
    state: { updatedKeys, isDeleted: isLotDeleted },
  } = useContractLot(id);
  const { restoreNode: restoreContractLot, getInitialTree } = useContractTreeContext();

  useEffect(() => {
    form.setFormValues({ note: '' });
  }, [form]);

  useEffect(() => {
    if (lot?.note) {
      form.setFormValues({ note: lot.note });
    }
  }, [form, hiddenField.value, lot]);

  const { t } = useTranslation(['global', 'quote']);

  const isNewNote = !getInitialTree().nodes[id]?.note;
  const isNoteDeleted = (isNil(lot?.note) && updatedKeys.includes('note')) || (isLotDeleted && !isNewNote);

  const handleNoteUpdate = useCallback(() => {
    const value = form.getFormValues().note;
    onUpdateNote(value)();
  }, [form, onUpdateNote]);

  const handleNoteRestore = useCallback(() => {
    const initialNoteValue = getInitialTree().nodes[id]?.note;
    onUpdateNote(initialNoteValue)();
    form.setFormValues({ note: initialNoteValue });

    if (isLotDeleted) {
      restoreContractLot(id);
    }
  }, [form, getInitialTree, id, isLotDeleted, onUpdateNote, restoreContractLot]);

  const handleNoteDelete = useCallback(() => {
    const newNoteValue = isNewNote ? undefined : null;

    onUpdateNote(newNoteValue)();

    if (isNewNote) {
      form.setFormValues({ note: newNoteValue });
    }
  }, [form, isNewNote, onUpdateNote]);

  const noteTextStyle = useMemo(
    () => ({
      ...(isNoteDeleted ? STYLE_CONTRACT_LOT_NOTE_INPUT.IS_DELETED : STYLE_CONTRACT_LOT_NOTE_INPUT.DEFAULT),
      ...INPUT_NOTE_DEEP_TABLE_STYLE(hasInputFocused),
    }),
    [hasInputFocused, isNoteDeleted],
  );

  const noteRowStyle = useMemo(
    () => (isNoteDeleted ? STYLE_CONTRACT_LOT_NOTE.IS_DELETED : STYLE_CONTRACT_LOT_NOTE.DEFAULT),
    [isNoteDeleted],
  );

  const displayNote = !isNil(lot?.note) || isNoteDeleted;
  if (!displayNote) {
    return <HiddenField {...hiddenField} />;
  }

  const canBeRestored = (isNoteDeleted || updatedKeys.includes('note')) && !isNewNote;
  const canBeDeleted = !isNoteDeleted;

  return (
    <DeepTable.Row offset={getDeepTableOffset(depth)} {...noteRowStyle}>
      <GridItem colSpan={8}>
        <DeepTable.Cell>
          <HiddenField {...hiddenField} />

          <Form form={form} style={{ flex: 1 }}>
            <RichTextField<NoteForm>
              noBorder
              name="note"
              navbarType="inline"
              onBlur={handleNoteUpdate}
              onFocus={onFocus}
              inputProps={noteTextStyle}
              isDisabled={isNoteDeleted}
              variant="light"
              configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
            />
          </Form>
        </DeepTable.Cell>
      </GridItem>

      <DeepTable.Cell />

      <DeepTable.Cell alignItems="flex-start">
        <ActionMenu>
          {canBeRestored && (
            <ActionMenu.Action
              onClick={handleNoteRestore}
              label={t('global:words.c.restore')}
              icon={<SimpleRewindIcon />}
            />
          )}

          {canBeDeleted && <ActionMenu.Delete label={t('quote:jobsStep.deleteNote')} onClick={handleNoteDelete} />}
        </ActionMenu>
      </DeepTable.Cell>
    </DeepTable.Row>
  );
};
