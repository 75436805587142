import type { PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES, ProgressStatementDiscountModes } from '@graneet/business-logic';
import { CUMULATIVE_INPUT_TYPE } from '@graneet/business-logic';

// -- Items
export type ItemCumulativeFieldName<T extends CUMULATIVE_INPUT_TYPE> = `item_${number}_${T}`;
export const getItemCumulativeFieldName = <T extends CUMULATIVE_INPUT_TYPE>(
  itemId: number,
  cumulativeInputType: T,
): ItemCumulativeFieldName<T> => `item_${itemId}_${cumulativeInputType}`;

export const isItemCumulativeField = (fieldName: string): fieldName is ItemCumulativeFieldName<CUMULATIVE_INPUT_TYPE> =>
  fieldName.startsWith('item_');

export const getInformationsFromItemCumulativeField = (fieldName: ItemCumulativeFieldName<CUMULATIVE_INPUT_TYPE>) => {
  const [, itemIdRaw, cumulativeType] = fieldName.split('_');

  return {
    itemId: +itemIdRaw,
    cumulativeType: cumulativeType as CUMULATIVE_INPUT_TYPE,
  };
};

export const isItemAmountCumulativeField = (
  fieldName: string,
): fieldName is ItemCumulativeFieldName<CUMULATIVE_INPUT_TYPE.AMOUNT> =>
  isItemCumulativeField(fieldName) && fieldName.endsWith(CUMULATIVE_INPUT_TYPE.AMOUNT);

export type ItemComment = `item_${number}`;
export function getItemCommentFieldName(id: number): ItemComment {
  return `item_${id}`;
}

// -- Discounts
export type DiscountCumulativeFieldName<T extends CUMULATIVE_INPUT_TYPE> = `discount_${number}_${T}`;
export const getDiscountCumulativeFieldName = <T extends CUMULATIVE_INPUT_TYPE>(
  contractId: number,
  cumulativeInputType: T,
): DiscountCumulativeFieldName<T> => `discount_${contractId}_${cumulativeInputType}`;

export type DiscountModeFieldName = `discount_${number}_mode`;

export const getDiscountModeLineFieldName = (contractId: number): DiscountModeFieldName =>
  `discount_${contractId}_mode`;

export const isDiscountCumulativeField = (
  fieldName: string,
): fieldName is DiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE> =>
  fieldName.startsWith('discount_') && !fieldName.endsWith('_mode');

export const isDiscountModeField = (fieldName: string): fieldName is DiscountModeFieldName =>
  fieldName.startsWith('discount_') && fieldName.endsWith('_mode');

export const isDiscountAmountCumulativeField = (
  fieldName: string,
): fieldName is ItemCumulativeFieldName<CUMULATIVE_INPUT_TYPE.AMOUNT> =>
  isDiscountCumulativeField(fieldName) && fieldName.endsWith(CUMULATIVE_INPUT_TYPE.AMOUNT);

export const getInformationsDiscountCumulativeField = <
  T extends DiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE> | DiscountModeFieldName,
>(
  fieldName: T,
) => {
  const [, contractIdRaw, cumulativeType] = fieldName.split('_');

  return {
    contractId: +contractIdRaw,
    cumulativeType: cumulativeType as T extends DiscountModeFieldName ? 'mode' : CUMULATIVE_INPUT_TYPE,
  };
};

// -- Custom Discounts
export type CustomDiscountCumulativeFieldName<T extends CUMULATIVE_INPUT_TYPE> = `custom_discount_${string}_${T}`;
export const getCustomDiscountCumulativeFieldName = <T extends CUMULATIVE_INPUT_TYPE>(
  customDiscountId: string,
  cumulativeInputType: T,
): CustomDiscountCumulativeFieldName<T> => `custom_discount_${customDiscountId}_${cumulativeInputType}`;

export type CustomDiscountModeFieldName = `custom_discount_${string}_mode`;

export const getCustomDiscountModeLineFieldName = (customDiscountId: string): CustomDiscountModeFieldName =>
  `custom_discount_${customDiscountId}_mode`;

export const isCustomDiscountCumulativeField = (
  fieldName: string,
): fieldName is CustomDiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE> =>
  fieldName.startsWith('custom_discount') && !fieldName.endsWith('_mode');

export const isCustomDiscountModeField = (fieldName: string): fieldName is CustomDiscountModeFieldName =>
  fieldName.startsWith('custom_discount') && fieldName.endsWith('_mode');

export const isCustomDiscountAmountCumulativeField = (
  fieldName: string,
): fieldName is ItemCumulativeFieldName<CUMULATIVE_INPUT_TYPE.AMOUNT> =>
  isCustomDiscountCumulativeField(fieldName) && fieldName.endsWith(CUMULATIVE_INPUT_TYPE.AMOUNT);

export const getInformationsCustomDiscountCumulativeField = <
  T extends CustomDiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE> | CustomDiscountModeFieldName,
>(
  fieldName: T,
) => {
  const [, , customDiscountId, cumulativeType] = fieldName.split('_');

  return {
    customDiscountId,
    cumulativeType: cumulativeType as T extends CustomDiscountModeFieldName ? 'mode' : CUMULATIVE_INPUT_TYPE,
  };
};

export const getKeyForCumulativeInput = (cumulativeInputType: CUMULATIVE_INPUT_TYPE) => {
  switch (cumulativeInputType) {
    case CUMULATIVE_INPUT_TYPE.PERCENTAGE:
      return 'cumulativeProgressPercentage';
    case CUMULATIVE_INPUT_TYPE.QUANTITY:
      return 'cumulativeProgressQuantity';
    case CUMULATIVE_INPUT_TYPE.AMOUNT:
      return 'cumulativeAmountExVAT';
    default:
      throw new Error(`Unknown value given: ${cumulativeInputType}`);
  }
};

export const getKeyForDiscountCumulativeInput = (cumulativeInputType: CUMULATIVE_INPUT_TYPE) => {
  switch (cumulativeInputType) {
    case CUMULATIVE_INPUT_TYPE.PERCENTAGE:
      return 'cumulativeProgressPercentage';
    case CUMULATIVE_INPUT_TYPE.AMOUNT:
      return 'cumulativeAmountExVAT';
    default:
      throw new Error(`Unknown value given: ${cumulativeInputType}`);
  }
};

export interface ProgressStatementEditItemForm {
  globalComment: string | undefined | null;

  cumulativeInputType: CUMULATIVE_INPUT_TYPE;

  // -- Items
  [cumulativeItemAmount: ItemCumulativeFieldName<CUMULATIVE_INPUT_TYPE.AMOUNT>]: number;

  [cumulativeItemPercentage: ItemCumulativeFieldName<CUMULATIVE_INPUT_TYPE.PERCENTAGE>]: number;

  [cumulativeItemQuantity: ItemCumulativeFieldName<CUMULATIVE_INPUT_TYPE.QUANTITY>]: number;

  [itemComment: ItemComment]: string | undefined | null;

  // -- Discounts
  [cumulativeDiscountAmount: DiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE.AMOUNT>]: number;

  [cumulativeDiscountPercentage: DiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE.PERCENTAGE>]: number;

  [cumulativeDiscountQuantity: DiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE.QUANTITY>]: number;

  [discountMode: DiscountModeFieldName]: ProgressStatementDiscountModes;

  // -- Custom Discounts
  [cumulativeCustomDiscountAmount: CustomDiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE.AMOUNT>]: number;

  [cumulativeCustomDiscountPercentage: CustomDiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE.PERCENTAGE>]: number;

  [cumulativeCustomDiscountQuantity: CustomDiscountCumulativeFieldName<CUMULATIVE_INPUT_TYPE.QUANTITY>]: number;

  [customDiscountMode: CustomDiscountModeFieldName]: PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES;
}
