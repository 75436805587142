import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, Flex, Grid } from '@chakra-ui/react';
import {
  computeAmountOfVATBase,
  computeSupplierInvoiceAmounts,
  FEATURE_FLAGS,
  SUPPLIER_INVOICE_MODE,
  VAT_TYPE,
} from '@graneet/business-logic';
import {
  Callout,
  CurrencyField,
  formatNumberToVatRate,
  formatPercentage,
  formatVatRateToNumber,
  isNumberFinite,
  LabeledData,
  PercentageField,
  Price,
  SimpleAlertIcon,
  useCurrency,
  Tooltip,
  ActionMenu,
  SimpleHiddenCostIcon,
  SimpleSettingsIcon,
} from '@graneet/lib-ui';
import { HiddenField, useFormContext, useHiddenField, useOnChangeValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { compact, sum, uniq } from 'lodash-es';

import type { EditSupplierInvoiceForm } from '../forms/edit-supplier-invoice.form';
import { buildSupplierInvoiceItemAmountExVATFieldName } from '../forms/edit-supplier-invoice.form';
import { mapFormToSupplierInvoiceItemDTO } from '../services/supplier-invoices.util';
import { useSupplierInvoiceContext } from '../hooks/UseSupplierInvoiceContext';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { Rule } from 'features/form/rules/Rule';
import { FeatureFlag } from 'features/feature-flag/components/FeatureFlag';

interface SupplierInvoiceAmountsFieldProps {
  forceFormError: boolean;
  isDirectPayment?: boolean;
  orderAmountToBeBilledExVAT?: number;
  withScrollToAlertMessage?: boolean;
  canUpdateAmount?: boolean;
  maxAmountAllowed?: false | number;
}

export interface AmountsFieldForm {
  amountExVAT: number;

  vatRate?: number;
}

function useAmountExVAT() {
  const { mapAmountToNumber } = useCurrency();

  const form = useFormContext<EditSupplierInvoiceForm>();
  const formValues = useOnChangeValues(form, undefined);

  const amountExVAT = useMemo(() => {
    if (formValues.mode === SUPPLIER_INVOICE_MODE.CONDENSED) {
      return formValues.amountExVAT ?? 0;
    }

    return (
      computeSupplierInvoiceAmounts({
        items: mapFormToSupplierInvoiceItemDTO(formValues, mapAmountToNumber),
      }).amountExVAT ?? 0
    );
  }, [formValues, mapAmountToNumber]);

  return useMemo(
    () => ({
      amountExVAT,
      isDisabled: formValues.mode !== SUPPLIER_INVOICE_MODE.CONDENSED,
    }),
    [amountExVAT, formValues.mode],
  );
}

const AmountExVAT = ({
  orderAmountToBeBilledExVAT,
  forceDisplayError,
  isDirectPayment,
  canUpdateAmount,
  maxAmountAllowed,
}: {
  orderAmountToBeBilledExVAT: number | undefined;
  forceDisplayError: boolean;
  isDirectPayment: boolean;
  canUpdateAmount: boolean;
  maxAmountAllowed: false | number;
}) => {
  const { mapNumberToAmount } = useCurrency();
  const { t } = useTranslation(['supplierInvoices', 'validationStep']);

  const amountExVAT = useAmountExVAT();

  if (amountExVAT.isDisabled) {
    return (
      <LabeledData
        label={t('supplierInvoices:cards.informationsCard.amountExVAT')}
        data={<Price amount={amountExVAT.amountExVAT} />}
      />
    );
  }

  return (
    <Tooltip label={!canUpdateAmount ? t('validationStep:supplierInvoice.tooltips.cannotUpdateAmount') : ''}>
      <Box>
        <CurrencyField<AmountsFieldForm>
          name="amountExVAT"
          label={t('supplierInvoices:cards.informationsCard.amountExVAT')}
          forceDisplayError={forceDisplayError}
          min={isDirectPayment ? 0 : undefined}
          isRequired
          isDisabled={!canUpdateAmount}
        >
          <Rule.IsRequired />
          {isNumberFinite(orderAmountToBeBilledExVAT) ? (
            <Rule.IsLowerOrEqualThan
              amount={mapNumberToAmount(orderAmountToBeBilledExVAT)}
              message={t('supplierInvoices:cards.informationsCard.onAmountExVatErrorMessage')}
            />
          ) : null}
          {canUpdateAmount && maxAmountAllowed ? (
            <Rule.IsLowerOrEqualThan
              amount={mapNumberToAmount(maxAmountAllowed) - 1}
              message={t('validationStep:supplierInvoice.tooltips.cannotUpdateAmount')}
            />
          ) : null}
        </CurrencyField>
      </Box>
    </Tooltip>
  );
};

const VatRate = () => {
  const { t } = useTranslation(['orders', 'supplierInvoices', 'global']);

  const { mapAmountToNumber } = useCurrency();
  const hasAccountingStandards = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARDS);

  const form = useFormContext<EditSupplierInvoiceForm>();
  const formValues = useOnChangeValues(form, undefined);

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  // Vat Type
  // @[ff: accounting-standards] remove ff
  if (hasAccountingStandards && formValues.vatType !== VAT_TYPE.NORMAL) {
    let data = null;

    if (formValues.vatType === VAT_TYPE.REVERSE_CHARGE) {
      data = t('global:words.c.reversalOfLiability');
    } else if (formValues.vatType === VAT_TYPE.INTRA_COMMUNITY) {
      data = t('global:words.c.intraCommunity');
    }

    return <LabeledData label={t('supplierInvoices:cards.informationsCard.vatDistribution')} data={data} />;
  }

  // Classification
  if (formValues.mode !== SUPPLIER_INVOICE_MODE.CONDENSED) {
    const items = mapFormToSupplierInvoiceItemDTO(formValues, mapAmountToNumber);
    const vatRates = uniq(compact(items.map((item) => item.vatRate)));

    let data = null;
    if (vatRates.length === 1) {
      data = formatPercentage(formatNumberToVatRate(vatRates[0]) ?? null);
    } else if (vatRates.length > 1) {
      data = t('global:words.multiVAT');
    }

    return <LabeledData label={t('supplierInvoices:cards.informationsCard.vatDistribution')} data={data} />;
  }

  if (hasStandardVATRates) {
    return (
      <AccountingVATField<AmountsFieldForm>
        isRequired
        label={t('supplierInvoices:cards.informationsCard.vatDistribution')}
        name="vatRate"
        valueScale={100}
      >
        <Rule.IsRequired />
      </AccountingVATField>
    );
  }

  // Fallback case
  return (
    <PercentageField<AmountsFieldForm>
      name="vatRate"
      label={t('supplierInvoices:cards.informationsCard.vatDistribution')}
      min={0}
      isRequired
    >
      <Rule.IsRequired />
    </PercentageField>
  );
};

const VatFields = () => {
  const { t } = useTranslation(['supplierInvoices']);

  const form = useFormContext<EditSupplierInvoiceForm>();
  const vatTypeHiddenField = useHiddenField(form, 'vatType');

  const onChangeVatType = useCallback(
    (value: VAT_TYPE) => () => {
      vatTypeHiddenField.setValue(value);
    },
    [vatTypeHiddenField],
  );

  return (
    <Flex>
      <VatRate />

      <FeatureFlag feature={FEATURE_FLAGS.ACCOUNTING_STANDARDS}>
        <FeatureFlag.OnActive>
          <Box alignSelf="end" ml={2}>
            <HiddenField {...vatTypeHiddenField} />
            <ActionMenu>
              {vatTypeHiddenField.value === VAT_TYPE.NORMAL ? (
                <>
                  <ActionMenu.Action
                    label={t('supplierInvoices:actions.applyReversalOfLiability')}
                    icon={<SimpleHiddenCostIcon />}
                    onClick={onChangeVatType(VAT_TYPE.REVERSE_CHARGE)}
                  />
                  <ActionMenu.Action
                    label={t('supplierInvoices:actions.applyIntraCommunity')}
                    icon={<SimpleHiddenCostIcon />}
                    onClick={onChangeVatType(VAT_TYPE.INTRA_COMMUNITY)}
                  />
                </>
              ) : (
                <ActionMenu.Action
                  label={t(
                    vatTypeHiddenField.value === VAT_TYPE.INTRA_COMMUNITY
                      ? 'supplierInvoices:actions.removeIntraCommunity'
                      : 'supplierInvoices:actions.removeReversalOfLiability',
                  )}
                  icon={<SimpleSettingsIcon />}
                  onClick={onChangeVatType(VAT_TYPE.NORMAL)}
                />
              )}
            </ActionMenu>
          </Box>
        </FeatureFlag.OnActive>
      </FeatureFlag>
    </Flex>
  );
};

const AmountIncVAT = () => {
  const { t } = useTranslation(['supplierInvoices']);

  const { mapAmountToNumber } = useCurrency();

  const form = useFormContext<EditSupplierInvoiceForm>();
  const formValues = useOnChangeValues(form, undefined);
  const hasAccountingStandards = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARDS);

  const amountIncVAT = useMemo(() => {
    if (formValues.mode !== SUPPLIER_INVOICE_MODE.CONDENSED) {
      const amounts = computeSupplierInvoiceAmounts({
        items: mapFormToSupplierInvoiceItemDTO(formValues, mapAmountToNumber),
      });

      if (!hasAccountingStandards || formValues.vatType === VAT_TYPE.NORMAL) {
        return amounts.amountIncVAT ?? 0;
      }
      return amounts.amountExVAT ?? 0;
    }

    if (!isNumberFinite(formValues.amountExVAT)) {
      return null;
    }

    if (hasAccountingStandards && formValues.vatType !== VAT_TYPE.NORMAL) {
      return mapAmountToNumber(formValues.amountExVAT);
    }

    if (!isNumberFinite(formValues.vatRate)) {
      return null;
    }

    const amountExVAT = mapAmountToNumber(formValues.amountExVAT);
    const vatRate = formatVatRateToNumber(formValues.vatRate);

    return computeSupplierInvoiceAmounts({
      amountExVAT,
      vatDistribution: [
        {
          vatRate,
          base: amountExVAT,
          amount: computeAmountOfVATBase({ vatRate, base: amountExVAT }),
        },
      ],
    }).amountIncVAT;
  }, [formValues, mapAmountToNumber, hasAccountingStandards]);

  return (
    <LabeledData
      label={t('supplierInvoices:cards.informationsCard.amountIncVAT')}
      data={<Price amount={amountIncVAT} />}
    />
  );
};

const OCRWarning = () => {
  const { mapAmountToNumber } = useCurrency();

  const { t } = useTranslation(['supplierInvoices']);
  const form = useFormContext<EditSupplierInvoiceForm>();
  const { itemIds, mode } = useOnChangeValues(form, undefined);

  const { hasOCRBeenDone, amountExVATFromOCR } = useSupplierInvoiceContext();

  const itemLineAmountExVAT = sum(
    compact((itemIds ?? []).map((id) => form.getFormValues()[buildSupplierInvoiceItemAmountExVATFieldName(id)])).map(
      mapAmountToNumber,
    ),
  );
  const displayWarning = hasOCRBeenDone && itemLineAmountExVAT !== amountExVATFromOCR;

  if (!displayWarning || mode !== SUPPLIER_INVOICE_MODE.DETAILED) {
    return null;
  }

  return (
    <Callout my={3} colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
      {t('supplierInvoices:cards.itemsCard.calloutWarningUnexpectedAmountExVATOnOCR')}
    </Callout>
  );
};

export const SupplierInvoiceAmountsField: FC<SupplierInvoiceAmountsFieldProps> = ({
  forceFormError,
  orderAmountToBeBilledExVAT,
  withScrollToAlertMessage = true,
  isDirectPayment = false,
  canUpdateAmount = true,
  maxAmountAllowed = false,
}) => {
  const { t } = useTranslation(['supplierInvoices', 'orders']);

  const { formatAsAmount } = useCurrency();

  const amountExVAT = useAmountExVAT();

  const showDirectPayementAlert = useMemo(() => {
    if (isNumberFinite(orderAmountToBeBilledExVAT)) {
      return amountExVAT.amountExVAT > orderAmountToBeBilledExVAT;
    }
    return false;
  }, [orderAmountToBeBilledExVAT, amountExVAT.amountExVAT]);

  const alertRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (withScrollToAlertMessage) {
      alertRef.current?.scrollIntoView();
    }
  }, [showDirectPayementAlert, withScrollToAlertMessage]);

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <AmountExVAT
          forceDisplayError={forceFormError || showDirectPayementAlert}
          isDirectPayment={isDirectPayment}
          orderAmountToBeBilledExVAT={orderAmountToBeBilledExVAT}
          canUpdateAmount={canUpdateAmount}
          maxAmountAllowed={maxAmountAllowed}
        />

        <VatFields />

        <AmountIncVAT />
      </Grid>

      {showDirectPayementAlert && (
        <Box ref={alertRef} pt={3}>
          <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
            {t('supplierInvoices:cards.informationsCard.alertOnAmountExVat', {
              orderAmountToBeBilledExVAT: formatAsAmount(orderAmountToBeBilledExVAT!),
            })}
          </Callout>
        </Box>
      )}

      <OCRWarning />
    </>
  );
};
