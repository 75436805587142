import { useHotkeys as useReactHotkeys } from 'react-hotkeys-hook';
import { useMemo } from 'react';

import { IS_APPLE } from '../utils/environment.util';

type Letter =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | 'esc'
  | 'del';

export type Shortcut =
  | Letter
  | `ctrl+${Letter}`
  | `shift+${Letter}`
  | `ctrl+shift+${Letter}`
  | 'up'
  | 'down'
  | `alt+${Letter}`
  | `alt+shift+${Letter}`
  | `mod+${Letter}`
  | `mod+shift+${Letter}`;

type UseReactHotkeys = Parameters<typeof useReactHotkeys>;

export const useHotkeys = (
  shortcut: Shortcut | Shortcut[],
  callback: UseReactHotkeys[1],
  options?: UseReactHotkeys[2],
  dependencies?: UseReactHotkeys[3],
) => {
  const shortcutMultiPlatform = useMemo(() => {
    const res: string[] = typeof shortcut === 'string' ? [shortcut] : shortcut;

    // Add shortcut for macOS
    return res.map((sc) => {
      let newShortcut = sc;
      if (newShortcut.includes('ctrl') && IS_APPLE) {
        newShortcut = newShortcut.replace('ctrl', 'meta');
      }
      if (newShortcut.includes('mod') && !IS_APPLE) {
        newShortcut = newShortcut.replace('mod', 'meta');
      }
      return newShortcut;
    });
  }, [shortcut]);

  const appliedOptions = useMemo(
    () => ({
      preventDefault: true,
      ...options,
    }),
    [options],
  );

  const ref = useReactHotkeys(shortcutMultiPlatform, callback, appliedOptions, dependencies);

  return useMemo(
    () => ({
      ref,
      shortcut: shortcutMultiPlatform.map((sc) => (sc.includes('meta') ? sc.replace('meta', '⌘') : sc)),
    }),
    [ref, shortcutMultiPlatform],
  );
};
