import { getEmailTemplateVariables } from '@graneet/business-logic';
import type { EmailTemplateData } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { type RichTextConfiguration, useCurrency } from '@graneet/lib-ui';
import { useMemo } from 'react';

import { getRichTextPluginOption } from '../services/email-template.util';

export const useEmailTemplateRichTextConfiguration = (data: EmailTemplateData): RichTextConfiguration => {
  const { t } = useTranslation(['global', 'mailing']);
  const { formatAsAmount, formatAsStringAmount } = useCurrency();

  return useMemo(() => {
    const variableValues = getEmailTemplateVariables(
      data,
      data.type === 'quotation' ? formatAsStringAmount : formatAsAmount,
    );

    return [
      getRichTextPluginOption(data.type === 'quotation' ? 'quote' : data.type, t, {
        injectValueInLabel: true,
        values: variableValues,
      }),
    ];
  }, [data, t, formatAsAmount, formatAsStringAmount]);
};
