import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useContactAssociatedToProject } from 'features/contact/services/contact.api';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { InvoiceDetail } from 'features/invoice/components/InvoiceDetail/InvoiceDetail';
import { useInvoiceById } from 'features/invoice/services/invoice.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewInvoiceDetailScreen = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { t } = useTranslation(['statement', 'global', 'invoice']);
  const history = useHistory();
  const { createRedirectionWithSavedFilters } = useFiltersQuery();
  const { updateHeaderTitle } = useHeaderContext();

  const invoice = useInvoiceById(+invoiceId);
  const projectContacts = useContactAssociatedToProject(invoice.data.project?.id);

  const goBackToListing = createRedirectionWithSavedFilters('/sales/statements', { replace: true });

  const goToCancelInvoice = useCallback(
    (createDraft: boolean) => {
      history.push(`/sales/statements/invoices/${invoiceId}/cancel`, {
        cancelledInvoiceNumber: invoice.data?.invoiceNumber,
        createDraft,
      });
    },
    [history, invoiceId, invoice.data],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(invoice.data?.name ?? '', [
      {
        name: t('statement:title'),
        link: '/sales/statements',
      },
    ]);
  }, [t, invoice.data?.name, updateHeaderTitle]);

  return (
    <InvoiceDetail
      invoice={invoice.data}
      projectContacts={projectContacts.data}
      onGoBack={goBackToListing}
      onCancelConfirmed={goToCancelInvoice}
      displayAssociatedProject
    />
  );
};
