import { HStack } from '@chakra-ui/react';
import type { BadgeProps } from '@graneet/lib-ui';
import { Badge, generateColorFromString } from '@graneet/lib-ui';
import type { FC } from 'react';

export interface TagsBadgesProps extends Omit<BadgeProps, 'children'> {
  tags: string[];
}

export const TagsBadges: FC<TagsBadgesProps> = ({ tags, ...rest }) => (
  <HStack width="100%" mt={1} overflow="hidden">
    {tags.map((tag) => (
      <Badge showDot color={generateColorFromString(tag)} py={0} px={1} fontSize="12px" {...rest} key={tag}>
        {tag}
      </Badge>
    ))}
  </HStack>
);
