import { HStack, useDisclosure } from '@chakra-ui/react';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { ChangeEvent } from 'react';
import { memo, useState, useCallback, useEffect, useMemo } from 'react';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import type { MaskType } from '@graneet/lib-ui';
import { ControlledDropDownSelect, formatNumberToVatRate, formatPercentage } from '@graneet/lib-ui';
import type { IVat } from '@graneet/business-logic';

import { useStore } from 'store/store';

export const VATRateEditorCell = memo(
  (
    params: CustomCellEditorProps<QuoteNodeObject> & {
      mask?: MaskType;
      unit?: string;
      textAlign?: 'left' | 'right';
    },
  ) => {
    const { value, data, column, node, onValueChange, stopEditing } = params;

    const vats = useStore((state) => state.quoteStandardVATRates);

    const options = useMemo(
      () =>
        vats.map((vat: IVat) => ({
          name: formatPercentage(formatNumberToVatRate(vat.rate)),
          value: String(vat.rate * 100),
        })),
      [vats],
    );

    const [inputValue, setInputValue] = useState(value ?? '');

    const handleOnChange = useCallback(
      (e: string | ChangeEvent<HTMLInputElement>) => {
        const newValue = typeof e === 'string' ? e : e.target.value;
        setInputValue(newValue);
        onValueChange(newValue);
        stopEditing();
      },
      [onValueChange, stopEditing],
    );

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
      onOpen();
    }, [onOpen]);

    if (
      (data?.content.type === 'QuoteLot' ||
        data?.content.type === 'QuoteComponent' ||
        data?.content.type === 'QuoteSubItem') &&
      column?.getColId() === 'vatRate'
    ) {
      return null;
    }

    if (node.footer) {
      return null;
    }

    return (
      <HStack width="100%" justifyContent="center" py="8px">
        <ControlledDropDownSelect
          noBorder
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          isEditable
          items={options}
          onChange={handleOnChange}
          selectedValue={inputValue}
        />
      </HStack>
    );
  },
);
