import type { CellClassParams, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';

import { TextEditorCell } from '../../components/columns/TextCell/TextEditorCell';
import { TextCell } from '../../components/columns/TextCell/TextCell';
import type { AGColDef } from '../../types';

import { useQuoteComponentUpdateRefCode } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateRefCode';
import { useQuoteBasicItemUpdateRefCode } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateRefCode';
import { useQuoteHiddenCostItemUpdateRefCode } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemUpdateRefCode';
import { useQuoteOptionalItemUpdateRefCode } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateRefCode';
import { useQuoteSubItemUpdateRefCode } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdateRefCode';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useRefCode(isEditable: boolean) {
  const quoteComponentUpdateRefCode = useQuoteComponentUpdateRefCode();
  const quoteBasicItemUpdateRefCode = useQuoteBasicItemUpdateRefCode();
  const quoteHiddenCostItemUpdateRefCode = useQuoteHiddenCostItemUpdateRefCode();
  const quoteOptionalItemUpdateRefCode = useQuoteOptionalItemUpdateRefCode();
  const quoteSubItemUpdateRefCode = useQuoteSubItemUpdateRefCode();

  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      cellEditor: TextEditorCell,
      cellEditorParams: { textAlign: 'left' },
      cellRenderer: TextCell,
      cellRendererParams: { textAlign: 'left', indent: true },
      colId: 'refCode',
      headerName: label,
      sortable: false,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressMovable: false,
      pinned: undefined,
      width,
      index,
      editable: (params) => {
        if (params.data?.content.type === 'QuoteLot' || !isEditable) {
          return false;
        }
        return true;
      },
      cellClass: (params: CellClassParams) => {
        if (params.node.footer || params.data?.content.type === 'QuoteLot') return 'no-focus read-only';
        return '';
      },
      valueGetter: (params) => {
        if (!params.data) {
          return '';
        }

        switch (params.data.content.type) {
          case 'QuoteComponent':
          case 'QuoteBasicItem':
          case 'QuoteHiddenCostItem':
          case 'QuoteOptionalItem':
          case 'QuoteSubItem':
            return params.data.content.refCode;
          case 'QuoteLot':
            return '';
          default:
            throw new Error('Type unknown');
        }
      },
      valueSetter: (params) => {
        const { data, newValue, oldValue } = params;

        if (oldValue?.toString() === newValue?.toString()) {
          return false;
        }

        switch (data.content.type) {
          case 'QuoteComponent':
            return executeCommand(quoteComponentUpdateRefCode(data.id, newValue), quote);
          case 'QuoteBasicItem':
            return executeCommand(quoteBasicItemUpdateRefCode(data.id, newValue), quote);
          case 'QuoteHiddenCostItem':
            return executeCommand(quoteHiddenCostItemUpdateRefCode(data.id, newValue), quote);
          case 'QuoteOptionalItem':
            return executeCommand(quoteOptionalItemUpdateRefCode(data.id, newValue), quote);
          case 'QuoteSubItem':
            return executeCommand(quoteSubItemUpdateRefCode(data.id, newValue), quote);
          default:
            break;
        }

        return true;
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [
      executeCommand,
      isEditable,
      quote,
      quoteBasicItemUpdateRefCode,
      quoteComponentUpdateRefCode,
      quoteHiddenCostItemUpdateRefCode,
      quoteOptionalItemUpdateRefCode,
      quoteSubItemUpdateRefCode,
    ],
  );
}
