import React from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { Field, type FieldValues } from 'graneet-form';

import type { KeysMatching } from '../../../utils';

export type TimePickerValue = string;

export interface TimePickerFieldProps<
  T extends FieldValues = Record<string, string>,
  K extends KeysMatching<T, TimePickerValue> = KeysMatching<T, TimePickerValue>,
> {
  name: K;
  label?: string;
}

export const TimePickerField = <
  T extends FieldValues = Record<string, string>,
  K extends KeysMatching<T, TimePickerValue> = KeysMatching<T, TimePickerValue>,
>({
  name,
  label,
}: TimePickerFieldProps<T, K>) => (
  <FormControl>
    <FormLabel pl="1" fontSize="xs" lineHeight="1.25rem" fontWeight="600" color="gray.600" mb="1" whiteSpace="nowrap">
      {label}
    </FormLabel>
    <Field<T, K>
      name={name}
      render={(fieldProps) => {
        const { onChange, value, onBlur, onFocus } = fieldProps;
        return (
          <Input
            border="1px"
            borderColor="inherit"
            type="time"
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={(e) => onChange(e.target.value as T[K])}
            value={value}
          />
        );
      }}
    />
  </FormControl>
);
