import type { FC } from 'react';
import { Icon, type IconProps } from '@chakra-ui/react';

export const DocIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.33335 7.33301H5.33335M6.66669 9.99967H5.33335M10.6667 4.66634H5.33335M13.3334 4.53301V11.4663C13.3334 12.5865 13.3334 13.1465 13.1154 13.5743C12.9236 13.9507 12.6177 14.2566 12.2414 14.4483C11.8135 14.6663 11.2535 14.6663 10.1334 14.6663H5.86669C4.74658 14.6663 4.18653 14.6663 3.75871 14.4483C3.38238 14.2566 3.07642 13.9507 2.88467 13.5743C2.66669 13.1465 2.66669 12.5865 2.66669 11.4663V4.53301C2.66669 3.4129 2.66669 2.85285 2.88467 2.42503C3.07642 2.0487 3.38238 1.74274 3.75871 1.55099C4.18653 1.33301 4.74658 1.33301 5.86669 1.33301H10.1334C11.2535 1.33301 11.8135 1.33301 12.2414 1.55099C12.6177 1.74274 12.9236 2.0487 13.1154 2.42503C13.3334 2.85285 13.3334 3.4129 13.3334 4.53301Z"
        stroke="#334E68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
