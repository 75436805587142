import { type DocumentStatusCardAction, Badge } from '@graneet/lib-ui';
import { t } from 'i18next';

import {
  getDeleteAction,
  getDownloadPDFAction,
  getDuplicateAction,
  getEditAction,
  getExportAction,
  getOpenDetailAction,
  getOpenMailingAction,
  getPreviewPDFAction,
} from './quote.actions.util';
import type { QuotationActionsParams, QuoteActionsParams } from './quote.actions.type';

export function getQuoteCompletedActions(
  params: QuoteActionsParams | QuotationActionsParams,
  full = true,
): DocumentStatusCardAction[] {
  const { onMarkAsAccepted, onMarkAsLost, pdf, isAcceptButtonLoading, hasAcceptPermission } = params;

  const actions: DocumentStatusCardAction[] = [];

  actions.push({
    type: 'primaryDropdown',
    dropDownLabel: t('global:words.markAs'),
    options: [
      {
        label: (
          <Badge showDot colorScheme="green">
            {t('quote:statuses.ACCEPTED')}
          </Badge>
        ),
        isLoading: isAcceptButtonLoading,
        tooltip: !hasAcceptPermission ? t('global:errors.disabledAction') : undefined,
        isDisabled: !hasAcceptPermission,
        onClick: onMarkAsAccepted,
      },
      {
        label: (
          <Badge showDot colorScheme="red">
            {t('quote:statuses.LOST')}
          </Badge>
        ),
        warning: true,
        isLoading: isAcceptButtonLoading,
        tooltip: !hasAcceptPermission ? t('global:errors.disabledAction') : undefined,
        isDisabled: !hasAcceptPermission,
        onClick: onMarkAsLost,
      },
    ],
  });

  if (pdf) {
    actions.push({
      type: 'secondary',
      ...getOpenMailingAction(params, pdf),
    });

    actions.push({
      type: 'secondary',
      ...getPreviewPDFAction(params, pdf),
    });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: [
      ...(full
        ? [getOpenDetailAction(params), getEditAction(params), getExportAction(params)]
        : [getEditAction(params)]),
      ...(pdf ? [getDownloadPDFAction(params, pdf)] : []),
      getDuplicateAction(params),
      getDeleteAction(params),
    ],
  });

  return actions;
}
