import type { QuoteNodeObject } from '@org/quotation-lib';
import { useCallback } from 'react';
import type { IRowNode } from '@ag-grid-community/core';

import { useQuoteNodeCanBeMoved } from './useQuoteNodeCanBeMoved';

export const useQuoteNodeCanBeMovedLower = () => {
  const quoteNodeCanBeMoved = useQuoteNodeCanBeMoved();

  return useCallback(
    (movingNode: IRowNode<QuoteNodeObject>, overNode: IRowNode<QuoteNodeObject>) => {
      const nodeToBeMovedId = movingNode.data?.id;
      const parentId = overNode.data?.parentId;
      const newPrevSiblingId = overNode.data?.prevSiblingId || null;
      const newNextSiblingId = overNode.data?.id || null;

      if (parentId && nodeToBeMovedId) {
        return quoteNodeCanBeMoved(nodeToBeMovedId, parentId, newPrevSiblingId, newNextSiblingId);
      }

      return false;
    },
    [quoteNodeCanBeMoved],
  );
};
