import { useTranslation } from 'react-i18next';
import {
  AreaChart,
  capitalizeString,
  Card,
  DATE_FORMAT_API,
  GraneetIconButton,
  LabeledData,
  Price,
  useCurrency,
} from '@graneet/lib-ui';
import { Box, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { useBankingAccountBalanceStats } from '../services/banking-account.api';

import { BankingBankLogo } from './BankingBankLogo';

import { QueryWrapper } from 'features/api/components/QueryWrapper';

const OFFSET_LENGTH = 3;
const GRAPH_LENGTH = 12;

const InternalCard = ({
  startDate,
  selectedBankingConnectionIds,
}: {
  startDate: dayjs.Dayjs;
  selectedBankingConnectionIds: string[];
}) => {
  const { t } = useTranslation(['banking']);

  const bankingAccountBalanceStats = useBankingAccountBalanceStats(
    useMemo(
      () => ({
        from: startDate.format(DATE_FORMAT_API),
        to: startDate.add(GRAPH_LENGTH, 'month').endOf('month').format(DATE_FORMAT_API),
        accountIds: selectedBankingConnectionIds,
      }),
      [startDate, selectedBankingConnectionIds],
    ),
  );

  const { formatAsAmount } = useCurrency();

  return (
    <Flex gap={5} direction="column">
      <Flex gap={5}>
        {selectedBankingConnectionIds.length > 1 && (
          <>
            <LabeledData
              label={t('banking:bankingAccountBalanceCard.aggregatedBalance')}
              data={formatAsAmount(bankingAccountBalanceStats.data.aggregatedBalance)}
            />

            <Box>
              <Divider orientation="vertical" />
            </Box>
          </>
        )}

        {bankingAccountBalanceStats.data.banks.map((bank) => (
          <LabeledData
            key={bank.id}
            label={
              <Flex gap={2}>
                <BankingBankLogo bankingConnection={bank.bankingConnection} />
                {bank.name.toUpperCase()}
              </Flex>
            }
            data={formatAsAmount(bank.currentBalance)}
          />
        ))}
      </Flex>

      <Box h="20rem">
        <AreaChart
          data={bankingAccountBalanceStats.data.monthlyBalances}
          xAxis={{
            dataKey: 'date',
            typeFormat: 'date',
          }}
          yAxis={{
            dataKey: 'startBalance',
            typeFormat: 'money',
          }}
          areas={[
            {
              dataKey: 'startBalance',
            },
          ]}
          // eslint-disable-next-line react/no-unstable-nested-components
          tooltip={({ value }) => (
            <Flex gap={4} direction="column" minW="10rem">
              <Text>
                {capitalizeString(
                  dayjs(value.date).toDate().toLocaleDateString(undefined, {
                    year: 'numeric',
                    month: 'long',
                  }),
                )}
              </Text>

              {value.banks.map((bank) => {
                const bankWithInfos = bankingAccountBalanceStats.data.banks.find(
                  (b) => b.id === bank.bankingAccountId,
                )!;

                return (
                  <Flex key={bankWithInfos.id} direction="column" gap={2}>
                    <Flex color="primary" gap={1}>
                      <BankingBankLogo bankingConnection={bankWithInfos.bankingConnection} />
                      {bankWithInfos.name}
                    </Flex>

                    <Grid templateColumns="repeat(2, 1fr)" fontSize="smaller" gap={1} w="100%">
                      <GridItem>{t('banking:bankingAccountBalanceCard.monthStartBalance')}</GridItem>
                      <GridItem>
                        <Text textAlign="end">
                          <Price amount={bank.startBalance} />
                        </Text>
                      </GridItem>
                    </Grid>
                  </Flex>
                );
              })}
            </Flex>
          )}
        />
      </Box>
    </Flex>
  );
};

interface BankingAccountBalanceCardProps {
  selectedBankingConnectionIds: string[];
}

export const BankingAccountBalanceCard: FC<BankingAccountBalanceCardProps> = ({ selectedBankingConnectionIds }) => {
  const { t } = useTranslation(['banking']);

  const [startDate, setStartDate] = useState(dayjs().add(-GRAPH_LENGTH, 'month').startOf('month'));

  const onOffsetChange = useCallback(
    (offset: -1 | 1) => () => {
      setStartDate((v) => v.add(offset * OFFSET_LENGTH, 'month').startOf('month'));
    },
    [],
  );

  return (
    <Card
      title={t('banking:bankingAccountBalanceCard.title')}
      subtitle={t('banking:bankingAccountBalanceCard.subtitle')}
      topRightContent={
        <Flex>
          <GraneetIconButton icon="ri-arrow-left-s-line" color="ghost" onClick={onOffsetChange(-1)} />
          <GraneetIconButton icon="ri-arrow-right-s-line" color="ghost" onClick={onOffsetChange(1)} />
        </Flex>
      }
    >
      <Box h="25rem">
        <QueryWrapper>
          <InternalCard startDate={startDate} selectedBankingConnectionIds={selectedBankingConnectionIds} />
        </QueryWrapper>
      </Box>
    </Card>
  );
};
