import { PROGRESS_STATEMENT_STATUSES } from '@graneet/business-logic';
import type { DocumentStatusCardAction, DocumentStatusCardIconValue } from '@graneet/lib-ui';

import { getProgressStatementAcceptedActions } from './progress-statement-accepted.actions';
import { getProgressStatementCancelledActions } from './progress-statement-cancelled.actions';
import { getProgressStatementDraftActions } from './progress-statement-draft.actions';
import { getProgressStatementValidatedActions } from './progress-statement-validated.actions';
import type { ProgressStatementActionsParams } from './progress-statement.actions.type';

export function getProgressStatementActions(params: ProgressStatementActionsParams): DocumentStatusCardAction[] {
  const { progressStatement } = params;

  switch (progressStatement.status) {
    case PROGRESS_STATEMENT_STATUSES.DRAFT:
      return getProgressStatementDraftActions(params);
    case PROGRESS_STATEMENT_STATUSES.VALIDATED:
      return getProgressStatementValidatedActions(params);
    case PROGRESS_STATEMENT_STATUSES.ACCEPTED:
      return getProgressStatementAcceptedActions(params);
    case PROGRESS_STATEMENT_STATUSES.CANCELLED:
      return getProgressStatementCancelledActions(params);
    case PROGRESS_STATEMENT_STATUSES.COMPLETED:
      throw new Error('Not implemented for non-direct progress statement');
    default:
      progressStatement.status satisfies never;
      throw new Error('Not implemented');
  }
}

export function getProgressStatementStatusCardIcon(status: PROGRESS_STATEMENT_STATUSES): DocumentStatusCardIconValue {
  switch (status) {
    case PROGRESS_STATEMENT_STATUSES.DRAFT:
      return 'proforma';
    case PROGRESS_STATEMENT_STATUSES.ACCEPTED:
      return 'completed';
    case PROGRESS_STATEMENT_STATUSES.VALIDATED:
      return 'finalized';
    case PROGRESS_STATEMENT_STATUSES.CANCELLED:
      return 'cancelled';
    case PROGRESS_STATEMENT_STATUSES.COMPLETED:
      throw new Error('Not implemented for non-direct progress statement');
    default:
      status satisfies never;
      throw new Error('Unknown status');
  }
}
