import type { FC } from 'react';
import { useCallback, useState } from 'react';
import {
  Modal,
  ValuesProvider,
  useValuesContext,
  useToast,
  usePaginatedData,
  STORAGE_STRATEGY,
  Search,
  PaginatedDataProvider,
  AsyncTableData,
  SimpleAlertIcon,
  SimpleContactIcon,
  FULL_QUERY,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { ASSOCIATION_TYPE } from '@graneet/business-logic';

import { ContactProjectAssociationTable } from '../tables/ContactProjectAssociationTable';

import {
  getContactAvailableForProjectPaginated,
  useContactsAssociateToProject,
} from 'features/contact/services/contact.api';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

interface RawContactAvailableProjectModalProps {
  isOpen: boolean;

  onClose(): void;

  onSuccess(): void;

  title: string;

  projectId: number;
}

export const RawContactAvailableProjectModal: FC<RawContactAvailableProjectModalProps> = ({
  isOpen,
  onClose,
  title,
  onSuccess,
  projectId,
}) => {
  const { t } = useTranslation(['global', 'contacts']);
  const toast = useToast();

  const { resetValues, getValues, numberOfValues, setValues, hasValue } = useValuesContext<string>();
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const availableContacts = usePaginatedData(
    getContactAvailableForProjectPaginated,
    { projectId, [FULL_QUERY]: false },
    undefined,
    STORAGE_STRATEGY.STATE,
  );

  const contactsAssociateToProjectMutation = useContactsAssociateToProject();

  const resetAll = () => {
    setIsAllChecked(false);
    resetValues();
  };

  const handleOnClose = () => {
    resetAll();
    onClose();
  };

  const handleSubmit = async () => {
    const contactsToAssociate = getValues();

    const clientContacts = availableContacts.data?.find(
      (groupedContact) => groupedContact.type === ASSOCIATION_TYPE.CLIENT,
    );

    await contactsAssociateToProjectMutation.mutateAsync({
      id: projectId,
      dto: {
        contacts: contactsToAssociate.map((contactId) => ({
          id: contactId,
          isDefaultEmailRecipient:
            clientContacts?.contacts.find((contact) => contact.id === contactId)?.isDefaultEmailRecipient ?? false,
        })),
      },
    });

    if (contactsToAssociate.length > 1) {
      toast.success(
        t('contacts:availableModal.toastSuccessManyAssociation', { nbContact: contactsToAssociate.length }),
      );
    } else {
      toast.success(t('contacts:availableModal.toastSuccessOneAssociation'));
    }

    onSuccess();
    onClose();
    resetAll();
  };

  const handleCheckAll = useCallback(
    () => () => {
      if (isAllChecked) {
        resetValues();
      } else {
        const ids: string[] = [];
        (availableContacts.data || []).forEach((client) => {
          ids.push(...client.contacts.map(({ id }) => id));
        });
        setValues(ids);
      }
      setIsAllChecked(!isAllChecked);
    },
    [isAllChecked, setIsAllChecked, setValues, availableContacts.data, resetValues],
  );

  const onCheck = useCallback(
    (id: string) => () => {
      const currentValues = getValues();
      const checked = hasValue(id);
      if (checked) {
        const index = currentValues.indexOf(id);
        currentValues.splice(index, 1);
        setValues([...currentValues]);
        if (numberOfValues() - 1 === 0) {
          setIsAllChecked(false);
        }
      } else {
        setValues([...currentValues, id]);
        setIsAllChecked(true);
      }
    },
    [getValues, hasValue, numberOfValues, setIsAllChecked, setValues],
  );

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} title={title} size="3xl" scrollBehavior="inside">
      <PaginatedDataProvider data={availableContacts}>
        <Box pb={4}>
          <Search placeholder={t('contacts:search')} />
        </Box>

        <AsyncTableData
          errorState={{
            message: t('contacts:listingError', { email: SUPPORT_EMAIL }),
            icon: <SimpleAlertIcon boxSize={45} />,
          }}
          emptyState={{
            message: t('contacts:noContacts'),
            icon: <SimpleContactIcon boxSize={45} />,
            actionMessage: t('contacts:createNewContact'),
          }}
        >
          <ContactProjectAssociationTable
            availableContacts={availableContacts.data ?? []}
            onCheck={onCheck}
            handleCheckAll={handleCheckAll}
            isAllChecked={isAllChecked}
            isContactDefaultEmailRecipient={(contact) => contact.isDefaultEmailRecipient}
          />
        </AsyncTableData>
      </PaginatedDataProvider>

      <Modal.Close />
      <Modal.PrimaryButton isDisabled={numberOfValues() === 0} onClick={handleSubmit}>
        {numberOfValues() <= 1 && t('contacts:availableModal.buttons.associate')}
        {numberOfValues() > 1 && t('contacts:availableModal.buttons.associateMulti')}
      </Modal.PrimaryButton>
    </Modal>
  );
};

export const ContactAvailableProjectModal: FC<RawContactAvailableProjectModalProps> = ({ isOpen, ...otherProps }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ValuesProvider>
      <RawContactAvailableProjectModal isOpen={isOpen} {...otherProps} />
    </ValuesProvider>
  );
};
