import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateToString, getCurrentDateAsString } from '@graneet/lib-ui';
import { Form, HiddenField, useHiddenField, useStepForm } from 'graneet-form';
import type { IInvoice, IProject } from '@graneet/business-logic';

import { InvoiceFieldsSection } from 'features/invoice/components/InvoiceFieldsSection';
import { usePaymentMethods } from 'features/payment-method/services/payment-method.api';
import { PaymentMethodSection } from 'features/payment-method/components/PaymentMethodSection';
import { useCompanyPaymentTerms } from 'features/company/services/company.api';
import {
  extractPaymentConditions,
  useInitialPaymentConditions,
} from 'features/payment-method/hooks/useInitialPaymentConditions';
import type { InvoiceEditionWizard } from 'features/invoice/forms/invoice-edition-wizard';
import type { InvoiceEditionInformationForm } from 'features/invoice/forms/invoice-edition-information-form';
import { NoteFieldSection } from 'features/common/components/fields/NoteFieldSection';
import { useProjectOrUndefined } from 'features/project/services/project.api';

interface EditInvoiceInformationStepProps {
  initialProjectId?: number;

  invoice: IInvoice | null;
}

export const EditInvoiceInformationStep: FC<EditInvoiceInformationStepProps> = ({ invoice, initialProjectId }) => {
  const { t } = useTranslation(['statement', 'paymentMethods']);
  const { form, initFormValues } = useStepForm<InvoiceEditionWizard, 'information'>();
  const { setFormValues } = form;
  const projectIdHiddenField = useHiddenField(form, 'projectId');
  const { value: projectId, setValue: setProjectId } = projectIdHiddenField;

  const paymentMethods = usePaymentMethods();
  const paymentTerms = useCompanyPaymentTerms();
  const project = useProjectOrUndefined(projectId);
  const initialProject = useProjectOrUndefined(initialProjectId);

  const paymentConditions = useInitialPaymentConditions({
    paymentMethods: paymentMethods.data,
    statement: invoice,
    companyPaymentTerms: paymentTerms.data,
    project: initialProject.data,
  });

  const handleProjectLinkChanged = useCallback(
    (newProject: IProject | null) => {
      setProjectId(newProject?.id ?? undefined);
      setFormValues(extractPaymentConditions(newProject, paymentTerms.data, paymentMethods.data));
    },
    [setProjectId, setFormValues, paymentTerms, paymentMethods],
  );

  // Initial values have to be set
  useEffect(() => {
    const { name, billingDate, purchaseOrderNumber, invoiceNumber, note } = invoice || {};
    initFormValues({
      name,
      purchaseOrderNumber,
      invoiceNumber,
      note,
      billingDate: billingDate ? formatDateToString(billingDate) : getCurrentDateAsString(),
      projectId: initialProjectId,
      ...paymentConditions,
    });
  }, [initFormValues, invoice, initialProjectId, paymentConditions]);

  return (
    <Form form={form}>
      <HiddenField {...projectIdHiddenField} />

      <InvoiceFieldsSection project={project.data} onProjectLinkChanged={handleProjectLinkChanged} invoice={invoice} />
      <NoteFieldSection<InvoiceEditionInformationForm> name="note" />

      <PaymentMethodSection
        paymentMethods={paymentMethods.data}
        description={t('paymentMethods:modal.select.desc.invoice')}
        w="80%"
      />
    </Form>
  );
};
