import type { FC, MouseEventHandler } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Flex, Link, Text } from '@chakra-ui/react';

import type { ChapterContextProps } from '../contexts/ChaptersContext';
import { useChaptersContext } from '../contexts/ChaptersContext';
import { MenuIcon } from '../../Icons';
import { chaptersTranslations } from '../configureDefaultLabels';
import { useScrollContext } from '../../../hooks';

const ChapterItem: FC<{ id: string; chapterProps: ChapterContextProps; isActive: boolean }> = ({
  id,
  chapterProps,
  isActive,
}) => {
  const onClick = useCallback<MouseEventHandler<HTMLParagraphElement>>(
    (event) => {
      event.preventDefault();
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    },
    [id],
  );

  return (
    <Link href={`#${id}`} color={isActive ? undefined : 'gray.500'}>
      <Text h={8} onClick={onClick} pl={3} borderLeft="2px solid">
        {chapterProps.title}
      </Text>
    </Link>
  );
};

export const ChaptersTable: FC = () => {
  const { chapters } = useChaptersContext();

  const anchors = useMemo(() => Object.keys(chapters), [chapters]);

  const [currentAnchor, setCurentAnchor] = useState(anchors[0]);

  const scroll = useScrollContext();

  useEffect(() => {
    const scrollElement = scroll || window;

    function isElementInViewport(el: HTMLElement) {
      const rect = el.getBoundingClientRect();

      return rect.top >= 0 && rect.left >= 0;
    }

    const updatePosition = () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const anchor of anchors) {
        const el = document.getElementById(anchor);
        if (el && isElementInViewport(el)) {
          setCurentAnchor(anchor);
          break;
        }
      }
    };
    scrollElement.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => scrollElement.removeEventListener('scroll', updatePosition);
  }, [anchors, scroll]);

  return (
    <Box position="sticky" top={0} h="fit-content">
      <Flex pb={3} pt={2} gap={2} alignItems="center">
        <MenuIcon />
        <Text fontSize="0.875rem" color="gray.700">
          {chaptersTranslations.tableHeader}
        </Text>
      </Flex>
      {Object.entries(chapters).map(([key, chapterProps]) => (
        <ChapterItem key={key} id={key} chapterProps={chapterProps} isActive={currentAnchor === key} />
      ))}
    </Box>
  );
};
