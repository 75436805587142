import type { IClientResponseDTO, ISupplier } from '@graneet/business-logic';
import { SingleSelectField } from '@graneet/lib-ui';
import type { FC, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { keepPreviousData } from '@tanstack/react-query';

import { ClientFieldOption } from '../ClientFieldOption';

import { Rule } from 'features/form/rules/Rule';
import { SupplierFieldOption } from 'features/supplier/components/SupplierFieldOption';
import { useClientsQuery } from 'features/client/services/client.api';
import { useSuppliersQuery } from 'features/supplier/services/supplier.api';
import { useDebounce } from 'features/common/hooks/useDebounce';

export enum CLIENT_OR_SUPPLIER_ASSOCIATION {
  CLIENT = 'CLIENT',
  SUPPLIER = 'SUPPLIER',
}

export type AssociationValue = {
  type: CLIENT_OR_SUPPLIER_ASSOCIATION;
  id: number;
};

export interface ClientOrSupplierAssociateForm {
  association: AssociationValue;
}

export interface ClientOrSupplierAssociationFormProps {
  searchSuppliers: boolean;
  suppliersFilter?: (client: ISupplier) => boolean;
  searchClients: boolean;
  clientsFilter?: (client: IClientResponseDTO) => boolean;
  showBadge: boolean;
  isRequired: boolean;
  placeholder: string;
}
/**
 * To use this component, you must provide a form context.
 */
export const ClientOrSupplierAssociationForm: FC<ClientOrSupplierAssociationFormProps> = ({
  searchSuppliers,
  suppliersFilter = () => true,
  searchClients,
  clientsFilter = () => true,
  isRequired,
  showBadge = true,
  placeholder,
}) => {
  const [size, setSize] = useState(5);

  const [searchTerm, setSearchTerm] = useState('');

  const debounceSearchTerm = useDebounce((data: string[]) => setSearchTerm(data[0]), 500, {
    leading: false,
    trailing: true,
  });

  const clientsQuery = useClientsQuery({ _search: searchTerm, _size: size } as any, {
    enabled: searchClients,
    placeholderData: keepPreviousData,
  });

  const suppliersQuery = useSuppliersQuery({ _search: searchTerm, _size: size } as any, {
    enabled: searchSuppliers,
    placeholderData: keepPreviousData,
  });

  const onMenuScrollToBottom = useCallback(() => {
    setSize(size + 5);
  }, [size]);

  const onSearchUpdate = useCallback(
    (value: string) => {
      debounceSearchTerm(value);
    },
    [debounceSearchTerm],
  );

  const clients = useMemo(
    () =>
      (clientsQuery.data?.data ?? []).filter(clientsFilter).map((client) => ({
        client,
        label: <ClientFieldOption client={client} showBadge={showBadge} />,
        value: { type: CLIENT_OR_SUPPLIER_ASSOCIATION.CLIENT, id: client.id },
        searchTerm: `${client.enterpriseName}${client.code}${client.siren}`,
      })),
    [clientsFilter, clientsQuery.data?.data, showBadge],
  );

  const suppliers = useMemo(
    () =>
      (suppliersQuery.data?.data ?? [])
        .filter(suppliersFilter)
        .map<{ value: AssociationValue; label: ReactNode }>((supplier) => ({
          supplier,
          label: <SupplierFieldOption supplier={supplier} showBadge={showBadge} />,
          value: { type: CLIENT_OR_SUPPLIER_ASSOCIATION.SUPPLIER, id: supplier.id },
          searchTerm: `${supplier.name}${supplier.code}`,
        })),
    [showBadge, suppliersFilter, suppliersQuery.data?.data],
  );

  const options = useMemo(() => [...clients, ...suppliers], [suppliers, clients]);

  return (
    <SingleSelectField<ClientOrSupplierAssociateForm>
      name="association"
      options={options as any}
      placeholder={placeholder}
      isRequired
      onSearchUpdate={onSearchUpdate}
      isLoading={clientsQuery.isLoading || suppliersQuery.isLoading}
      onMenuScrollToBottom={onMenuScrollToBottom}
    >
      {isRequired && <Rule.IsRequired />}
    </SingleSelectField>
  );
};
