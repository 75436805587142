import { useCallback, useState, useEffect, useMemo, memo } from 'react';
import { Box, GridItem } from '@chakra-ui/react';
import {
  DeepTable,
  Tree,
  INPUT_IN_DEEP_TABLE_STYLE,
  DropZone,
  DraggableItem,
  SegmentedDropZone,
  DropEffect,
  useCounterLoaderContext,
  RichTextField,
  BadgeJob,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
} from '@graneet/lib-ui';
import { useFormContext } from 'graneet-form';
import { useTranslation } from 'react-i18next';

import { getDeepTableOffset } from '../../services/contract.util';
import { useContractLot, useContractTreeContext } from '../../hooks/tree.hooks';
import { CONTRACT_ENTITY_TYPES } from '../../constants/contracts.constant';
import type { ContractEditionForm, LotKey } from '../../forms/contract-edition.form';
import { getLotFieldName } from '../../forms/contract-edition.form';
import type { ContractDnDItem } from '../../types/contract-dnd.type';
import { ContractDeepTableCodeRow } from '../ContractDeepTableCodeRow';

import { ContractLotActions } from './ContractLotActions';
import { ContractLotIncompleteItemsCounter } from './ContractLotIncompleteItemsCounter';
import { ContractLotAmounts } from './ContractLotAmounts';
import { ContractLotNote } from './ContractLotNote';

import { useStore } from 'store/store';
import { useMemoOnTime } from 'features/common/hooks/useMemoOnTime';
import { STYLE_CONTRACT_LOT, STYLE_CONTRACT_LOT_INPUT } from 'features/contract/constants/styles';
import { generateFormValuesOfLot } from 'features/contract/services/contract-input.util';

interface ContractDeepTableLotLineProps {
  id: string | number;

  depth: number;
}

export const ContractDeepTableLotLine = memo<ContractDeepTableLotLineProps>(({ id, depth }) => {
  const memoOnTime = useMemoOnTime(1000);

  const { t } = useTranslation(['quote']);

  const [hasInputFocused, setHasInputFocused] = useState(false);

  const {
    node: lot,
    state: { isDeleted, updatedKeys, isCreated },
  } = useContractLot(id);
  const {
    updateNodeData,
    moveLeaf,
    getLastLeafOfNode,
    getDisplayedCurrentTree,
    moveNode,
    isNodeAfterNode,
    isNodeDescendingChildrenOfNode,
    getLastNodeOfNode,
  } = useContractTreeContext();

  const form = useFormContext<ContractEditionForm>();

  const handleUpdateNote = useCallback(
    (note: string | null | undefined) => () => {
      updateNodeData(id, { note });
      setHasInputFocused(false);
    },
    [id, updateNodeData],
  );

  const handleFocus = useCallback(() => {
    setHasInputFocused(true);
  }, []);

  const handleBlur = useCallback(
    (key: LotKey) => () => {
      setHasInputFocused(false);

      const fieldName = getLotFieldName(id, key);
      updateNodeData(id, {
        [key]: form.getFormValues()[fieldName],
      });
    },
    [form, id, updateNodeData],
  );

  const lotRowStyle = useMemo(
    () => (isDeleted ? STYLE_CONTRACT_LOT(depth).IS_DELETED : STYLE_CONTRACT_LOT(depth).DEFAULT),
    [depth, isDeleted],
  );

  const lotTextStyle = useMemo(
    () => (isDeleted ? STYLE_CONTRACT_LOT_INPUT.IS_DELETED : STYLE_CONTRACT_LOT_INPUT.DEFAULT),
    [isDeleted],
  );

  const inputStyle = useCallback(
    (key: LotKey) => {
      const hasFieldBeenUpdated = updatedKeys.includes(key) || isCreated;

      return {
        ...lotTextStyle,
        ...(hasFieldBeenUpdated ? STYLE_CONTRACT_LOT_INPUT.IS_UPDATED : {}),
        ...INPUT_IN_DEEP_TABLE_STYLE(hasInputFocused),
      };
    },
    [hasInputFocused, isCreated, lotTextStyle, updatedKeys],
  );

  useEffect(() => {
    if (lot) {
      form.setFormValues(generateFormValuesOfLot(lot));
    }
  }, [form, lot]);

  // Move entities
  const onItemDropInLot = useCallback(
    (newItemIn: ContractDnDItem) => {
      const lastLeafOfNode = getLastLeafOfNode(lot.id);
      if (lastLeafOfNode === newItemIn.id) {
        return;
      }
      moveLeaf(newItemIn.id, lot.id, lastLeafOfNode, 'after');
    },
    [moveLeaf, lot?.id, getLastLeafOfNode],
  );

  const canDropOnLotTop = useCallback(
    (lotDrag: ContractDnDItem) => {
      const isNodeAfter = memoOnTime(`isNodeAfterNode-${lotDrag.id}-${id}`, () => isNodeAfterNode(lotDrag.id, id));
      const isNodeChildren = memoOnTime(`isNodeDescendingChildrenOfNode-${lotDrag.id}-${id}`, () =>
        isNodeDescendingChildrenOfNode(id, lotDrag.id),
      );
      return !isNodeAfter && !isNodeChildren;
    },
    [id, isNodeAfterNode, isNodeDescendingChildrenOfNode, memoOnTime],
  );

  const canDropOnLotInside = useCallback(
    (lotDrag: ContractDnDItem) => {
      const isNodeChildren = memoOnTime(`isNodeDescendingChildrenOfNode-${lotDrag.id}-${id}`, () =>
        isNodeDescendingChildrenOfNode(id, lotDrag.id),
      );
      return !isNodeChildren;
    },
    [id, isNodeDescendingChildrenOfNode, memoOnTime],
  );

  const onLotDropBefore = useCallback(
    (newPreviousLot: ContractDnDItem) => {
      const { parentNodeId } = getDisplayedCurrentTree().nodes[id];
      moveNode(newPreviousLot.id, parentNodeId!, id, 'before');
    },
    [getDisplayedCurrentTree, id, moveNode],
  );

  const onLotDropInLot = useCallback(
    (newLotIn: ContractDnDItem) => {
      const lastNodeOfNode = getLastNodeOfNode(lot.id);
      if (lastNodeOfNode === newLotIn.id) {
        return;
      }
      moveNode(newLotIn.id, lot.id, lastNodeOfNode, 'after');
    },
    [moveNode, lot?.id, getLastNodeOfNode],
  );

  const { addLineRendered } = useCounterLoaderContext();
  useEffect(() => {
    addLineRendered(`LOT_${id}`);
  }, [addLineRendered, id]);

  const isOptional = useStore((state) => state.optionalLotsTable[id.toString()]);

  return (
    <DropZone id={id} accept={CONTRACT_ENTITY_TYPES.ITEM} onDrop={onItemDropInLot}>
      <SegmentedDropZone id={id} accept={CONTRACT_ENTITY_TYPES.LOT}>
        <SegmentedDropZone.Segment
          weight={1}
          effect={DropEffect.CursorTop}
          canDrop={canDropOnLotTop}
          onDrop={onLotDropBefore}
        />

        {!isDeleted && (
          <SegmentedDropZone.Segment
            weight={1}
            effect={DropEffect.Glow}
            canDrop={canDropOnLotInside}
            onDrop={onLotDropInLot}
          />
        )}

        <DeepTable.Row
          offset={getDeepTableOffset(depth)}
          position="relative"
          leftContent={
            <Box display="flex" flexDirection="row" marginTop={isOptional ? 3 : 0} alignItems="center">
              <DraggableItem.Handle
                mr={1}
                color="gray.400"
                _hover={isDeleted ? {} : { color: 'gray.500' }}
                disabled={isDeleted}
              />
              <Tree.ToggleButton nodeId={id} />
            </Box>
          }
          {...lotRowStyle}
        >
          {isOptional ? (
            <BadgeJob backgroundColor="purple.200" textColor="purple.700" label={t('quote:option.title')} />
          ) : null}
          <DeepTable.Cell>
            <ContractDeepTableCodeRow
              id={id}
              type="lot"
              name={getLotFieldName(id, 'code')}
              input={inputStyle('code')}
              onFocus={handleFocus}
              onBlur={handleBlur('code')}
              isDisabled={isDeleted}
            />
          </DeepTable.Cell>

          <DeepTable.Cell>
            <RichTextField<ContractEditionForm>
              name={getLotFieldName(id, 'description')}
              navbarType="inline"
              inputProps={inputStyle('description')}
              onFocus={handleFocus}
              onBlur={handleBlur('description')}
              isDisabled={isDeleted}
              configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
            />
          </DeepTable.Cell>

          <GridItem colSpan={2}>
            <DeepTable.Cell />
          </GridItem>

          <ContractLotIncompleteItemsCounter id={id} />

          <ContractLotAmounts id={id} />

          <DeepTable.Cell>
            <ContractLotActions lotId={id} onUpdateNote={handleUpdateNote} />
          </DeepTable.Cell>
        </DeepTable.Row>

        <ContractLotNote
          id={id}
          onUpdateNote={handleUpdateNote}
          onFocus={handleFocus}
          depth={depth}
          hasInputFocused={hasInputFocused}
        />
      </SegmentedDropZone>
    </DropZone>
  );
});
