import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Callout } from '@graneet/lib-ui';
import type { ISubProject } from '@graneet/business-logic';

import { SubProjectSettingsCard } from 'features/sub-project/components/cards/SubProjectSettingsCard';

interface ProjectSettingsCardProps {
  subProjects: ISubProject[];

  onEditSubProjectParams(): void;
}

export const ProjectSettingsCard: FC<ProjectSettingsCardProps> = ({ subProjects, onEditSubProjectParams }) => {
  const { t } = useTranslation(['project']);

  if (subProjects.length > 1) {
    return (
      <>
        <Card title={t('project:cards.settings')}>
          <Callout colorScheme="greenBrand">
            {t('project:parameters.cannotEditParametersIfMultipleSubProjects')}
          </Callout>
        </Card>
      </>
    );
  }

  // Pas de contrat rattaché => pas de sub_project
  if (subProjects.length === 0) {
    return (
      <>
        <Card title={t('project:cards.settings')}>
          <Callout colorScheme="greenBrand">{t('project:parameters.cannotEditParametersIfNoContract')}</Callout>
        </Card>
      </>
    );
  }

  return <SubProjectSettingsCard subProject={subProjects[0]} onEditSubProjectParams={onEditSubProjectParams} />;
};
