import { useCallback, useRef } from 'react';

import { TIME_DISPLAY_LOADER } from '../constants/loading.constant';

export const useStartAnotherUpdate = (emitIsUpdating: (isUpdating: boolean) => void) => {
  const countRef = useRef(0);

  return useCallback(() => {
    countRef.current += 1;
    emitIsUpdating(true);
    setTimeout(() => {
      countRef.current -= 1;
      if (countRef.current < 1) {
        emitIsUpdating(countRef.current > 0);
      }
    }, TIME_DISPLAY_LOADER);
  }, [emitIsUpdating]);
};
