import * as Sentry from '@sentry/react';

import { getEnvValue } from './env';

if (getEnvValue('REACT_APP_SENTRY_DSN', false)) {
  Sentry.init({
    dsn: getEnvValue('REACT_APP_SENTRY_DSN', false),
    environment: getEnvValue('REACT_APP_ENV', false),
    release: `client-${getEnvValue('TAG_VERSION', false)}`,
  });
}
