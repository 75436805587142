import type { FC } from 'react';
import { useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, useDisclosure } from '@chakra-ui/react';
import type { IProject } from '@graneet/business-logic';
import { FEATURE_FLAGS, SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import { useParams } from 'react-router-dom';

import { useHeaderContext } from 'features/app/contexts/HeaderContext';
import { SupplierInvoiceDetails } from 'features/supplier-invoice/components/SupplierInvoiceDetails';
import { useAppContext } from 'features/app/contexts/AppContext';
import {
  useGetValidationStepSupplierInvoiceHistory,
  useUpdateValidationStepSupplierInvoiceHistory,
} from 'features/validation-step/services/validation-step-supplier-invoice.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { CurrentValidationStep } from 'features/validation-step/components/CurrentValidationStep';
import { ValidationStepsHistoryModal } from 'features/validation-step/modals/ValidationStepHistoryModal';
import { useSupplierInvoice } from 'features/supplier-invoice/services/supplier-invoice.api';

interface ViewSupplierInvoiceScreenProps {
  project?: IProject;
}

export const ViewSupplierInvoiceScreen: FC<ViewSupplierInvoiceScreenProps> = ({ project }) => {
  const { currentUser } = useAppContext();
  const { t } = useTranslation(['supplierInvoices', 'global', 'validationStep']);
  const { breadCrumb, updateHeaderTitle } = useHeaderContext();

  const { supplierInvoiceId } = useParams<{ supplierInvoiceId: string }>();

  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);
  const validationStepsHistoryModal = useDisclosure();

  const supplierInvoice = useSupplierInvoice(parseInt(supplierInvoiceId, 10));
  const validationStepHistory = useGetValidationStepSupplierInvoiceHistory(
    hasWorkflowValidationFeatureFlag && supplierInvoice.data.currentValidationStep
      ? supplierInvoice.data.id
      : undefined,
  );

  const updateValidationStepSupplierInvoiceHistory = useUpdateValidationStepSupplierInvoiceHistory();

  const handleValidStep = useCallback(async () => {
    await updateValidationStepSupplierInvoiceHistory.mutateAsync(supplierInvoice.data.id);
  }, [supplierInvoice.data.id, updateValidationStepSupplierInvoiceHistory]);

  useLayoutEffect(() => {
    updateHeaderTitle(
      supplierInvoice.data.name ?? '',
      project
        ? [
            ...breadCrumb,
            {
              name: project.name,
              link: `/projects/${project.id}`,
            },
            {
              name: t('global:nav.supplierInvoices'),
              link: `/projects/${project.id}/purchases/supplier-invoices`,
            },
          ]
        : [
            {
              name: t('global:nav.supplierInvoices'),
              link: '/purchases/supplier-invoices',
            },
          ],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, supplierInvoice.data.name, project, updateHeaderTitle]);

  const currentValidationStepHistory = validationStepHistory.data?.find(
    ({ validationStepId }) => validationStepId === supplierInvoice.data.currentValidationStep?.id,
  );
  const displayValidationStep =
    hasWorkflowValidationFeatureFlag &&
    supplierInvoice.data.status !== SUPPLIER_INVOICE_STATUS.TO_PROCESS &&
    currentValidationStepHistory &&
    validationStepHistory;

  // Find subsequent steps to know if the currentUser can approve
  const currentValidationStepHistoryIndex = validationStepHistory.data?.findIndex(
    ({ validationStepId }) => validationStepId === supplierInvoice.data.currentValidationStep?.id,
  );
  const subsequentValidationSteps = validationStepHistory.data?.slice(currentValidationStepHistoryIndex);
  const isUserCanApprove = !!subsequentValidationSteps?.some((step) =>
    step.users.some(({ user }) => user.id === currentUser?.id),
  );

  const stepIdsAboveThresholdValidationSteps = validationStepHistory.data
    ?.filter(({ threshold }) => !!(supplierInvoice.data.amountExVAT && threshold > supplierInvoice.data.amountExVAT))
    .map(({ validationStepId }) => validationStepId);

  const validatorIds = subsequentValidationSteps?.flatMap(({ users }) => users.map(({ user }) => user.id));
  const isUserAboveThreshold = (validatorIds || [])
    .filter((id) => !currentValidationStepHistory?.users.map(({ user }) => user.id).includes(id))
    .includes(currentUser.id);

  return (
    <Flex direction="column" gap={2}>
      <ValidationStepsHistoryModal
        validationStepHistory={validationStepHistory.data}
        currentValidationStepHistory={currentValidationStepHistory}
        isOpen={validationStepsHistoryModal.isOpen}
        onClose={validationStepsHistoryModal.onClose}
        handleValidStep={
          supplierInvoice.data.status === SUPPLIER_INVOICE_STATUS.TO_VALIDATE ? handleValidStep : undefined
        }
        isUserCanApprove={isUserCanApprove}
        stepIdsAboveThresholdValidationSteps={stepIdsAboveThresholdValidationSteps || []}
      />

      {displayValidationStep && (
        <CurrentValidationStep
          isToValidateStatus={supplierInvoice.data.status === SUPPLIER_INVOICE_STATUS.TO_VALIDATE}
          currentValidationStepHistory={currentValidationStepHistory}
          handleValidStep={handleValidStep}
          isUserCanApprove={isUserCanApprove}
          onOpenValidationStepsHistoryModal={validationStepsHistoryModal.onOpen}
          isUserAboveThreshold={isUserAboveThreshold}
        />
      )}

      <SupplierInvoiceDetails
        project={project}
        onSeeValidationHistory={validationStepsHistoryModal.onOpen}
        supplierInvoice={supplierInvoice.data}
      />
    </Flex>
  );
};
