import { useCallback } from 'react';

import type { ClientRequest } from 'store/store';
import { useStore } from 'store/store';

export const enum StatusEnum {
  ADD_ENTRY = 'ADD_ENTRY',
  DELETE_ENTRY = 'DELETE_ENTRY',
}

export function useQuoteSetClientRequestsStore() {
  return useCallback((status: StatusEnum, clientRequestToTreat: ClientRequest, nbEventPusher?: number) => {
    const { clientRequests } = useStore.getState();
    switch (status) {
      case StatusEnum.ADD_ENTRY: {
        clientRequests.push({
          ...clientRequestToTreat,
          nbEventPusherTreated: 0,
        });
        useStore.setState({
          clientRequests,
        });
        break;
      }
      case StatusEnum.DELETE_ENTRY: {
        const updatedClientRequests = clientRequests
          .map((clientRequest) => {
            const copyClientRequest = { ...clientRequest } as ClientRequest;

            if (clientRequest.clientRequestId === clientRequestToTreat.clientRequestId) {
              if (
                clientRequest.nbEventPusherTreated &&
                nbEventPusher &&
                clientRequest.nbEventPusherTreated + 1 >= nbEventPusher
              ) {
                return undefined;
              }

              if (copyClientRequest.nbEventPusherTreated && nbEventPusher) {
                copyClientRequest.nbEventPusherTreated += 1;
              }
            }
            return copyClientRequest;
          })
          .filter((request): request is ClientRequest => request !== undefined);
        useStore.setState({
          clientRequests: updatedClientRequests,
        });
        break;
      }
      default:
        break;
    }
  }, []);
}
