import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useFormContext, useFormStatus } from 'graneet-form';
import { Modal, RadioGroupField } from '@graneet/lib-ui';
import { QUOTE_SALES_DISCOUNT_TYPE } from '@org/quotation-lib';
import { useTranslation } from 'react-i18next';
import Big from 'big.js';
import { Flex, HStack, Text, VStack } from '@chakra-ui/react';

import { SaleDiscountTotals } from '../SaleDiscountTotals';

export interface AddSaleDiscountModalProps {
  isOpen: boolean;

  onClose(): void;

  onSubmit(text: Partial<SaleDiscountForm>): void;

  totalBeforeSaleDiscountExVAT: Big | null;
}

export interface SaleDiscountForm {
  saleDiscountType: QUOTE_SALES_DISCOUNT_TYPE;
  saleDiscountAmountExVAT?: string;
  saleDiscountPercentage?: string;
}

export const AddSaleDiscountModal: FC<AddSaleDiscountModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  totalBeforeSaleDiscountExVAT,
}) => {
  const { t } = useTranslation(['quote', 'global', 'discount']);

  const form = useFormContext<SaleDiscountForm>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues } = form;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    const values = getFormValues();
    onSubmit(values);

    setIsLoading(false);
    onClose();
  }, [getFormValues, onClose, onSubmit]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('quote:discounts.drawers.discount.title')}
      size="xl"
      scrollBehavior="inside"
    >
      <VStack spacing={3} align="stretch" fontSize="13px">
        <Text color="gray.600"> {t('quote:discounts.drawers.discount.calculusMode')}</Text>

        <RadioGroupField<SaleDiscountForm> name="saleDiscountType">
          <HStack spacing={2} align="stretch">
            <Flex
              padding="2"
              align-items="center"
              borderRadius="0.625rem"
              border="1px solid rgba(0, 19, 58, 0.10)"
              background="#FFF"
              align="stretch"
              flex=" 1 0 0"
            >
              <RadioGroupField.Option
                value={QUOTE_SALES_DISCOUNT_TYPE.PERCENTAGE}
                label={t('discount:percentageType')}
              />
            </Flex>
            <Flex
              padding="2"
              align-items="center"
              borderRadius="0.625rem"
              border="1px solid rgba(0, 19, 58, 0.10)"
              background="#FFF"
              align="stretch"
              flex=" 1 0 0"
            >
              <RadioGroupField.Option value={QUOTE_SALES_DISCOUNT_TYPE.AMOUNT} label={t('discount:amountType')} />
            </Flex>
          </HStack>
        </RadioGroupField>
        <SaleDiscountTotals totalBeforeSaleDiscountExVAT={totalBeforeSaleDiscountExVAT ?? Big(0)} />
      </VStack>
      <Modal.Close />

      <Modal.PrimaryButton isLoading={isLoading} isDisabled={!isFormValid} onClick={handleSubmit}>
        {t('global:words.c.validate')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
