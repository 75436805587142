import type { FC } from 'react';
import { usePlaceholder, useWizardContext, VALIDATION_OUTCOME } from 'graneet-form';

import { ModalPrimaryButton } from '../Modal/ModalPrimaryButton';
import type { ButtonProps } from '../Button/Button';

export interface StepperModalPrimaryButtonProps extends ButtonProps {}

export const StepperModalPrimaryButton: FC<StepperModalPrimaryButtonProps> = ({
  isDisabled = false,
  isLoading = false,
  children,
  ...props
}) => {
  const { handleOnNext, isStepReady } = useWizardContext();
  const { stepStatus, isLoading: isStepLoading } = usePlaceholder();

  const formIsInvalid = stepStatus === VALIDATION_OUTCOME.INVALID;
  const displayIsNotReady = isStepLoading || !isStepReady || stepStatus === VALIDATION_OUTCOME.UNDETERMINED;

  return (
    <ModalPrimaryButton
      onClick={handleOnNext}
      {...props}
      isDisabled={isDisabled || formIsInvalid}
      isLoading={isLoading || displayIsNotReady}
    >
      {children}
    </ModalPrimaryButton>
  );
};
