import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { StepperModal } from '@graneet/lib-ui';
import type { IClientDTO, IClientResponseDTO, IContact } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { useClientCreate } from '../../../services/client.api';

import { ClientCreateStep } from './steps/ClientCreateStep';
import { ContactCreateStep } from './steps/ContactCreateStep';
import type { ContractCreateWizard } from './ContractCreateWizard';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useContactCreate, useContactsAssociateToClient } from 'features/contact/services/contact.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { formatAddressDTO } from 'features/common/components/fields/AddressFields';

export interface ClientContactCreateStepperModalProps {
  isOpen: boolean;
  onClose: () => void;

  onClientCreated: (client: IClientResponseDTO) => void;
  onContactCreated: (contact: IContact, data: { isDefaultEmailRecipient: boolean }) => void;

  hasIsDefaultEmailRecipient?: boolean;
}

export const ClientContactCreateStepperModal: FC<ClientContactCreateStepperModalProps> = ({
  isOpen,
  onClose,
  onClientCreated,
  onContactCreated,
  hasIsDefaultEmailRecipient,
}) => {
  const [client, setClient] = useState<IClientResponseDTO | null>();
  const { t } = useTranslation(['contacts', 'clients']);

  const clientCreateMutation = useClientCreate();
  const contactCreateMutation = useContactCreate();
  const contactsAssociateToClientMutation = useContactsAssociateToClient();

  const onFinish = useCallback(
    async (wizardValues: ContractCreateWizard) => {
      if (!client) {
        return;
      }

      const createContactForm = wizardValues['create-contact'];
      const body = {
        firstName: createContactForm.firstName,
        lastName: createContactForm.lastName,
        role: createContactForm.role ?? '',
        email: createContactForm.email,
        phoneNumber: createContactForm.phoneNumber,
      };
      const response = await contactCreateMutation.mutateAsync(body);
      await contactsAssociateToClientMutation.mutateAsync({
        id: client.id,
        dto: {
          contacts: [
            {
              id: response.id,
              isDefaultEmailRecipient: createContactForm.isDefaultEmailRecipient ?? false,
            },
          ],
        },
      });

      onContactCreated(response, { isDefaultEmailRecipient: createContactForm.isDefaultEmailRecipient ?? false });
      onClose();
    },
    [client, contactCreateMutation, contactsAssociateToClientMutation, onClose, onContactCreated],
  );

  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  const onNext = useCallback(
    async (formValues: ContractCreateWizard['create-client'] | undefined) => {
      if (!formValues) {
        return false;
      }

      const dto: IClientDTO = {
        enterpriseName: formValues.enterpriseName,
        siren: formValues.siren || '',
        vatNumber: formValues.vatNumber || '',
        code: formValues.code || '',
        address: formatAddressDTO({
          address: formValues.address,
          city: formValues.city,
          postalCode: formValues.postalCode,
          country: formValues.country,
        }),
        hasBillingAddress: !formValues.hasNoBillingAddress,
        billingAddress: !formValues.hasNoBillingAddress
          ? formatAddressDTO({
              address: formValues.billingAddressAddress,
              city: formValues.billingAddressCity,
              postalCode: formValues.billingAddressPostalCode,
              country: formValues.billingAddressCountry,
            })
          : undefined,
        comment: null,
        auxiliaryAccount: hasUpdateAccountingPermission ? formValues.auxiliaryAccount : undefined,
        isLedgerEnabled: formValues.isLedgerEnabled,
      };
      const response = await clientCreateMutation.mutateAsync(dto);
      setClient(response);
      onClientCreated(response);
      return true;
    },
    [hasUpdateAccountingPermission, clientCreateMutation, onClientCreated],
  );

  return (
    <StepperModal<ContractCreateWizard> isOpen={isOpen} onClose={onClose} onFinish={onFinish} size="2xl">
      <StepperModal.Step<ContractCreateWizard, 'create-client'>
        name="create-client"
        onNext={onNext}
        title={t('clients:createClient')}
      >
        <ClientCreateStep />
      </StepperModal.Step>

      <StepperModal.Step<ContractCreateWizard, 'create-contact'>
        name="create-contact"
        title={t('contacts:creationModal.title')}
      >
        <QueryWrapper>
          <ContactCreateStep hasIsDefaultEmailRecipient={hasIsDefaultEmailRecipient ?? false} />
        </QueryWrapper>
      </StepperModal.Step>
    </StepperModal>
  );
};
