import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Callout, Modal, SimpleAlertIcon } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface StatementForceDraftModalProps {
  modal: UseDisclosureReturn;
  onConfirmed(): Promise<void>;
}

export const StatementForceDraftModal: FC<StatementForceDraftModalProps> = ({ modal, onConfirmed }) => {
  const { t } = useTranslation(['statement', 'global']);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    await onConfirmed();
    setIsLoading(false);
    modal.onClose();
  }, [modal, onConfirmed]);

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t('statement:forceDraftModal.title')}>
      {t('statement:forceDraftModal.description')}
      <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />} mt={4}>
        {t('statement:forceDraftModal.warningCallout')}
      </Callout>
      <Modal.Close>{t('global:words.c.back')}</Modal.Close>
      <Modal.PrimaryButton onClick={handleClick} isLoading={isLoading}>
        {t('statement:forceDraftModal.confirm')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
