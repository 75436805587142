import type { ChangeEvent, FocusEventHandler, MouseEventHandler } from 'react';
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import {
  Text,
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  VStack,
  HStack,
  PopoverAnchor,
} from '@chakra-ui/react';
import { Parser } from 'expr-eval';
import { isFinite } from 'lodash-es';

import { QuotationInput } from '../Input';
import { Tooltip } from '../../../Tooltip';
import { formulaFieldTranslations } from '../../../Field/FormulaField/configureDefaultLabel';

import { FormulaContent } from './FormulaContent';

interface CalculateFormulaResult {
  value: number | null;
  formulaValue: number | null;
  roundedFactor: number;
}

interface FormulaContentValue {
  formula: string;
  comment: string;
  roundFactor: number;
}

export type QuotationFormulaFieldValue =
  | {
      value: string | undefined | null;
      content: FormulaContentValue | undefined | null;
    }
  | undefined
  | null;

interface FormulaFieldInternalProps {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  value: QuotationFormulaFieldValue | undefined;
  onBlur(value: QuotationFormulaFieldValue | undefined): void;
  onChange(value: QuotationFormulaFieldValue | undefined): void;
  onFocus(): void;
  sendClickRoundEvent(type: string): void;
}

const countDecimals = (num: number) => {
  if (Number.isNaN(num) || Math.floor(num) === num) {
    return 0;
  }

  return num.toString().split('.')[1].length || 0;
};

const isNumberOrEmpty = (input: string) => {
  if (input === '') {
    return true;
  }
  const regex = /^-?\d*([.,]\d*)?$/;
  return regex.test(input);
};

export const QuotationFormulaInput = forwardRef<HTMLInputElement, FormulaFieldInternalProps>((props, ref) => {
  const { isDisabled, isReadOnly, value: defaultValue, onChange, onBlur, onFocus, sendClickRoundEvent } = props;

  const [inputValue, setInputValue] = useState<string | null>(
    defaultValue?.value !== null && defaultValue?.value !== undefined && !Number.isNaN(defaultValue.value)
      ? (parseFloat(defaultValue.value).toString() ?? '')
      : null,
  );
  const [formula, setFormula] = useState<string | null>(defaultValue?.content?.formula ?? null);
  const [roundFactor, setRoundFactor] = useState<number>(defaultValue?.content?.roundFactor ?? 0);
  const [comment, setComment] = useState<string | null>(defaultValue?.content?.comment ?? null);

  const [formulaError, setError] = useState<string | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const formulaRef = useRef<HTMLInputElement>(null);

  /**
   * Utils
   */
  const round = useCallback(
    (value: number, factor: number) => {
      if (factor < 0 || value === null || countDecimals(value) - factor < 0) {
        sendClickRoundEvent('has-not-rounded');
        return null;
      }

      sendClickRoundEvent('has-rounded');

      return parseFloat(value.toFixed(countDecimals(value) - factor));
    },
    [sendClickRoundEvent],
  );

  const calculateFormula = useCallback(
    (newFormula: string, newRoundedFactor: number) => {
      setError(null);

      if (newFormula === '') {
        return null;
      }

      const result: CalculateFormulaResult = {
        value: null,
        formulaValue: null,
        roundedFactor: newRoundedFactor,
      };

      try {
        const expr = Parser.parse(newFormula.replaceAll(',', '.'));
        result.formulaValue = parseFloat(expr.evaluate());
      } catch {
        setError(formulaFieldTranslations.genericError);
        return null;
      }

      result.value = result.formulaValue;

      if (newRoundedFactor !== 0) {
        result.value = round(result.formulaValue, newRoundedFactor);
      }

      if (newRoundedFactor === 0 && countDecimals(result.formulaValue) > 2) {
        result.roundedFactor = newRoundedFactor - 2 + countDecimals(result.formulaValue);
        result.value = round(result.formulaValue, result.roundedFactor);
      }

      if (!isFinite(result.formulaValue)) {
        setError(formulaFieldTranslations.impossibleCalculationError);
        return null;
      }

      return result;
    },
    [round],
  );

  /*
   * Handlers for inputs
   */
  const handleOnChangeInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value?.replaceAll(',', '.') ?? '';
      if (!isNumberOrEmpty(newValue)) {
        return;
      }
      setInputValue(newValue);
      setFormula(null);
      setRoundFactor(0);
      setComment(null);
      onChange({
        value: newValue,
        content: {
          formula: '',
          roundFactor: 0,
          comment: '',
        },
      });
    },
    [onChange],
  );

  const handleRoundButton = useCallback(
    (factor: number) => {
      const newRoundFactor = (roundFactor ?? 0) + factor;
      const formulaValue = calculateFormula(formula ?? '', newRoundFactor)?.formulaValue;
      const newValue = round(formulaValue ?? 0, newRoundFactor);

      if (newValue === null) {
        return;
      }
      if (newValue.toString() !== inputValue && !Number.isNaN(newValue)) {
        setInputValue(newValue.toString());
        setRoundFactor(newRoundFactor);
      }
    },
    [formula, calculateFormula, round, inputValue, roundFactor],
  );

  const handleFormulaChange = useCallback(
    (newFormula: string) => {
      const values = calculateFormula(newFormula, 0);
      setFormula(newFormula);
      setInputValue(values?.value && !Number.isNaN(values?.value) ? values.value.toString() : null);
      setRoundFactor(values?.roundedFactor ?? 0);
    },
    [calculateFormula],
  );

  const handleCommentChange = (input: string) => {
    setComment(input);
  };

  /*
   * Handlers for actions
   */
  const handleOnFocus = useCallback(() => {
    onFocus?.();
  }, [onFocus]);

  const handleOnBlur = useCallback(() => {
    onBlur({
      value: inputValue?.replaceAll(',', '.') ?? '',
      content: {
        formula: formula ?? '',
        roundFactor: formula && roundFactor ? roundFactor : 0,
        comment: formula && comment ? comment : '',
      },
    });
  }, [comment, formula, inputValue, onBlur, roundFactor]);

  const handleOutSideClick = useCallback<FocusEventHandler<HTMLDivElement>>(() => {
    if (!isPopoverOpen) {
      handleOnBlur();
    }
  }, [handleOnBlur, isPopoverOpen]);

  const handleOnPopoverOpen = useCallback(() => {
    setIsPopoverOpen(true);
    formulaRef.current?.focus();
  }, [formulaRef]);

  const handleOnPopoverClose = useCallback(() => {
    setIsPopoverOpen(false);
    handleOnFocus();
    handleOnBlur();
  }, [handleOnFocus, handleOnBlur]);

  const preventDefaultOnMouseDown = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    // Prevent AG-Grid from handling the event and un-focus the input
    e.preventDefault();
    e.stopPropagation();
  }, []);

  /*
   * Render
   */

  const content = useMemo(
    () => (
      <FormulaContent
        ref={formulaRef}
        onFormulaChange={handleFormulaChange}
        onCommentChange={handleCommentChange}
        onRoundLeft={() => handleRoundButton(1)}
        onRoundRight={() => handleRoundButton(-1)}
        onClose={() => formulaRef.current?.blur()}
        formula={defaultValue && formula === null ? defaultValue.content?.formula?.toString() : formula}
        comment={comment}
        error={formulaError}
        isReadOnly={isReadOnly || isDisabled}
      />
    ),
    [handleFormulaChange, defaultValue, formula, comment, formulaError, isReadOnly, isDisabled, handleRoundButton],
  );

  const tooltip = (
    <VStack>
      <Text fontSize="12px" color="white">
        {formula}
      </Text>
      {comment && (
        <Text fontSize="12px" color="#C6C5C1">
          {comment}
        </Text>
      )}
    </VStack>
  );

  return (
    <Box
      position="relative"
      role="group"
      width="100%"
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-start"
      height="100%"
    >
      {formula ? (
        <Tooltip label={tooltip} placement="right" shouldWrapChildren>
          <HStack
            borderRadius="6px"
            border="1px solid"
            borderColor="greenBrand.borderDefault"
            background="#F2F2F0"
            fontSize="12px"
            width="auto"
            height="20px"
            justifyContent="flex-end"
            px={1}
          >
            <Text>
              <i className="ri-formula" />
            </Text>
            <QuotationInput
              value={inputValue?.replaceAll('.', ',') ?? ''}
              onChange={handleOnChangeInput}
              onBlur={handleOutSideClick}
              onFocus={handleOnFocus}
              isDisabled={isDisabled}
              isReadOnly
              textAlign="center"
              p={0}
              ref={ref}
              width={`${(inputValue?.replaceAll('.', ',') ?? '').length}ch`}
              noStyle
              height="20px"
            />
          </HStack>
        </Tooltip>
      ) : (
        <QuotationInput
          value={inputValue?.replaceAll('.', ',') ?? ''}
          onChange={handleOnChangeInput}
          onBlur={handleOutSideClick}
          onFocus={handleOnFocus}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
          textAlign="right"
          ref={ref}
          width="auto"
          noStyle
        />
      )}
      {!isDisabled && !isReadOnly && (
        <Popover
          initialFocusRef={formulaRef}
          isOpen={isPopoverOpen}
          onOpen={handleOnPopoverOpen}
          onClose={handleOnPopoverClose}
          placement="bottom-start"
          closeOnBlur
          gutter={3}
        >
          <PopoverTrigger>
            <Box height="100%" alignItems="center" display="flex">
              <PopoverAnchor>
                <Box
                  cursor="pointer"
                  zIndex={2}
                  background="white"
                  boxShadow="0px 2px 8px -2px rgba(21, 24, 26, 0.04), 0px 2px 4px -2px rgba(21, 24, 26, 0.04)"
                  border="1px solid #E0DFDC"
                  borderRadius="4px"
                  _hover={{
                    background: '#F2F2F0',
                  }}
                  onMouseDown={preventDefaultOnMouseDown}
                  position="absolute"
                  left={1}
                  top="50%"
                  transform="translateY(-50%)"
                  fontSize="sm"
                  height="28px"
                  width="28px"
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                >
                  <i className="ri-calculator-line" />
                </Box>
              </PopoverAnchor>
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              w="auto"
              userSelect="none"
              p="8px"
              bg="white"
              borderRadius="1rem"
              border="1px solid #E0DFDC"
              boxShadow="0px 12px 24px -4px rgba(21, 24, 26, 0.08), 0px 8px 16px -4px rgba(21, 24, 26, 0.04)"
            >
              {content}
            </PopoverContent>
          </Portal>
        </Popover>
      )}
    </Box>
  );
});
