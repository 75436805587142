import { useEffect, useState } from 'react';
import type { IQuoteComponent } from '@graneet/business-logic';

import { useComponentContext } from '../contexts/component.context';

export const useComponents = () => {
  const { listenToComponents } = useComponentContext();
  const [components, setComponents] = useState<IQuoteComponent[] | null>(null);

  useEffect(() => listenToComponents(setComponents), [listenToComponents]);

  return components;
};
