import { GridItem, Text, Grid, Box, useTheme } from '@chakra-ui/react';
import type dayjs from 'dayjs';
import type { FC } from 'react';
import { useState, useCallback } from 'react';

import { timeTableTranslations } from '../../../configureDefaultLabels';

interface MonthlyWeekHeaderProps {
  week: number;
  numberOfDays: number;
  days: dayjs.Dayjs[];
  displayTitle?: boolean;
}

export const MonthlyWeekHeader: FC<MonthlyWeekHeaderProps> = ({ week, numberOfDays, days, displayTitle }) => {
  const { colors } = useTheme();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleHovered = useCallback(
    (isIn: boolean) => () => {
      setIsHovered(isIn);
    },
    [setIsHovered],
  );

  return (
    <GridItem
      flex={1}
      colSpan={numberOfDays}
      alignItems="center"
      justifyContent="center"
      position="relative"
      h="12"
      onMouseEnter={handleHovered(true)}
      onMouseLeave={handleHovered(false)}
    >
      <Grid
        alignItems="flex-start"
        templateColumns={`repeat(${numberOfDays}, 1fr)`}
        w="100%"
        h="100%"
        bgColor={colors.white}
      >
        {days.map((day) => {
          const isWeekEnd = [0, 6].includes(day.day());

          return (
            <GridItem
              key={`weekday_${day.toISOString()}`}
              flex={1}
              py={3}
              alignItems="center"
              justifyContent="center"
              h="100%"
              {...{
                ...(isWeekEnd ? { background: 'gray.50' } : {}),
              }}
            />
          );
        })}
      </Grid>
      {(displayTitle || isHovered) && (
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            align="center"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            fontWeight={600}
            color="gray.500"
          >
            {days.length >= 4 ? `${timeTableTranslations.week} ${week}` : week}
          </Text>
        </Box>
      )}
    </GridItem>
  );
};
