import { useCallback, useMemo } from 'react';
import type { FieldValues } from 'graneet-form';

import {
  ceilFloating,
  divideFloating,
  floorFloating,
  MAXIMUM_FRACTIONAL_PART_LENGTH,
  multiplyFloating,
  roundFloating,
} from '../../../utils/math.util';
import type { MaskedNumberFieldProps, RoundableNumberField } from '../MaskedNumberField';
import { MaskedNumberField } from '../MaskedNumberField';
import type { KeysMatching } from '../../../utils';
import type { MaskType } from '../../Input/MaskedInput/mask.type';

type IntegerFieldValue = number | null | undefined;

export interface NumberFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, IntegerFieldValue> = KeysMatching<T, IntegerFieldValue>,
> extends Omit<MaskedNumberFieldProps<T, K>, 'type'>,
    RoundableNumberField {
  type?: MaskType;
  min?: number;
  max?: number;
}

/**
 * type: decimal => float
 * type: number => integer
 */
export const NumberField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, IntegerFieldValue> = KeysMatching<T, IntegerFieldValue>,
>({
  name,
  min,
  max,
  type = 'decimal',
  scale,
  children,
  ...rest
}: NumberFieldProps<T, K>) => {
  const fractionalPartLength = scale ? parseFloat(scale.toString()) : MAXIMUM_FRACTIONAL_PART_LENGTH;

  const options = useMemo(
    () => ({
      min: min && Number.isFinite(min) ? ceilFloating(min, fractionalPartLength) : min,
      max: max && Number.isFinite(max) ? floorFloating(max, fractionalPartLength) : max,
      scale: fractionalPartLength,
    }),
    [min, fractionalPartLength, max],
  );
  const formatValue = useCallback(
    (value: T[K] | undefined | null) => {
      // When user write '-' in field, imask returns '-0'
      // so we dont want to parse it to not erase user input for 0
      if (scale === undefined || value === '-0' || value === undefined || value === null) {
        return value;
      }

      const factor = 10 ** parseFloat(fractionalPartLength.toString());
      const valueByScale = multiplyFloating(value, factor);
      return divideFloating(roundFloating(valueByScale), factor) as T[K];
    },
    [scale, fractionalPartLength],
  );

  return (
    <MaskedNumberField<T, K> name={name} options={options} formatValue={formatValue} {...rest} type={type}>
      {children}
    </MaskedNumberField>
  );
};

export const IntegerField = <
  T extends FieldValues,
  K extends KeysMatching<T, IntegerFieldValue> = KeysMatching<T, IntegerFieldValue>,
>(
  props: NumberFieldProps<T, K>,
) => <NumberField<T, K> {...props} type="number" />;
