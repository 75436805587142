import { useLayoutEffect, type FC, useCallback } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import type { ISupplierInvoice } from '@graneet/business-logic';

import { EditSupplierInvoiceScreen } from './EditSupplierInvoiceScreen';
import { ViewSupplierInvoiceScreen } from './ViewSupplierInvoiceScreen';
import { ViewSupplierInvoicesScreen } from './ViewSupplierInvoicesScreen';

import {
  SupplierInvoiceEditContextProvider,
  useSupplierInvoiceGoToListing,
} from 'features/supplier-invoice/contexts/SupplierInvoiceEditContext.provider';
import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const SupplierInvoicesRouter: FC = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation(['global']);
  const { updateHeaderTitle } = useHeaderContext();

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.supplierInvoices'), []);
  }, [t, updateHeaderTitle]);

  const history = useHistory();
  const goToListing = useSupplierInvoiceGoToListing({ project: undefined });

  const onCreatedOrEdited = useCallback(
    (
      supplierInvoice: ISupplierInvoice,
      options: { containsProjectOutsideOfUserScope: boolean; projectIds: number[] },
    ) => {
      if (options.containsProjectOutsideOfUserScope) {
        goToListing();
      } else if (options.projectIds.length === 1) {
        history.push(`/projects/${options.projectIds[0]}/purchases/supplier-invoices/${supplierInvoice.id}`);
      } else {
        history.push(`/purchases/supplier-invoices/${supplierInvoice.id}`);
      }
    },
    [goToListing, history],
  );

  return (
    <Switch>
      <Route exact path={`${path}/create`}>
        <SupplierInvoiceEditContextProvider>
          <QueryWrapper>
            <EditSupplierInvoiceScreen context="create" onQuit={goToListing} onCreatedOrEdited={onCreatedOrEdited} />
          </QueryWrapper>
        </SupplierInvoiceEditContextProvider>
      </Route>

      <Route path={`${path}/:supplierInvoiceId/edit`} exact>
        <SupplierInvoiceEditContextProvider>
          <QueryWrapper>
            <EditSupplierInvoiceScreen
              context="edit"
              onQuit={(supplierInvoice) => {
                history.push(`/purchases/supplier-invoices/${supplierInvoice?.id}`);
              }}
              onCreatedOrEdited={onCreatedOrEdited}
            />
          </QueryWrapper>
        </SupplierInvoiceEditContextProvider>
      </Route>

      <Route path={`${path}/:supplierInvoiceId`}>
        <Box p={8}>
          <QueryWrapper>
            <ViewSupplierInvoiceScreen />
          </QueryWrapper>
        </Box>
      </Route>

      <Route exact path={path}>
        <QueryWrapper>
          <ViewSupplierInvoicesScreen />
        </QueryWrapper>
      </Route>
    </Switch>
  );
};
