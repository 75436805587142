import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { IBankingConnectionWithRelations } from '@graneet/business-logic';

import { apiNew } from 'features/api/services/apiNew.service';

const BANKING_CONNECTION_PATH = '/banking-connections';

const bankingConnectionKeyFactory = createQueryKeys('banking-connection', {
  get: () => ({
    queryKey: [BANKING_CONNECTION_PATH],
    queryFn: () => apiNew.get<never, IBankingConnectionWithRelations[]>(BANKING_CONNECTION_PATH),
  }),
});

export function useBankingConnections() {
  return useSuspenseQuery({
    ...bankingConnectionKeyFactory.get(),
    refetchInterval: (data) => {
      const hasNonSyncBankingConnection = data.state.data?.some(
        (bankingConnection) => bankingConnection.lastSyncedAt === null,
      );

      return hasNonSyncBankingConnection ? 10_000 : false;
    },
  });
}
