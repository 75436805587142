import type { FC } from 'react';
import { useCallback } from 'react';
import { BadgeAutoNumbering, DeepTable } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';

import { useStore } from 'store/store';

export const ContractDeepTableHeader: FC = () => {
  const { t } = useTranslation(['contracts']);

  const { autoNumberingIsActivated, autoNumberingSetIsActivated } = useStore((state) => ({
    autoNumberingIsActivated: state.autoNumberingIsActivated,
    autoNumberingIsActivatedByDefault: state.autoNumberingIsActivatedByDefault,
    autoNumberingSetIsActivated: state.autoNumberingSetIsActivated,
  }));

  const handleClickAutoNumbering = useCallback(() => {
    autoNumberingSetIsActivated(!autoNumberingIsActivated);
  }, [autoNumberingIsActivated, autoNumberingSetIsActivated]);

  return (
    <DeepTable.StickyContent bg="white" zIndex={5}>
      <DeepTable.Header>
        <DeepTable.Cell>
          <Flex direction="row" gap="1" alignItems="center">
            {t('contracts:fields.code')}
            <BadgeAutoNumbering onClick={handleClickAutoNumbering} isActivated={autoNumberingIsActivated} />
          </Flex>
        </DeepTable.Cell>

        <DeepTable.Cell>{t('contracts:fields.description')}</DeepTable.Cell>

        <DeepTable.Cell right>{t('contracts:fields.unit')}</DeepTable.Cell>

        <DeepTable.Cell right>{t('contracts:fields.quantity')}</DeepTable.Cell>

        <DeepTable.Cell right>{t('contracts:fields.unitPriceExVAT')}</DeepTable.Cell>

        <DeepTable.Cell right>{t('contracts:fields.vatRate')}</DeepTable.Cell>

        <DeepTable.Cell right>{t('contracts:fields.amountBilled')}</DeepTable.Cell>

        <DeepTable.Cell right>{t('contracts:fields.amountExVAT')}</DeepTable.Cell>

        <DeepTable.Cell right>{t('contracts:fields.amountDifference')}</DeepTable.Cell>

        <DeepTable.Cell />
      </DeepTable.Header>
    </DeepTable.StickyContent>
  );
};
