import type {
  IPaymentMethod,
  IPaymentMethodCreationDTO,
  IPaymentMethodResponseDTO,
  IPaymentMethodUpdateDTO,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { sortPaymentMethods } from './payment-method.util';

import { apiNew } from 'features/api/services/apiNew.service';

const PAYMENT_METHOD_PATH = '/payment-methods';

const paymentMethodFactory = createQueryKeys('payment-methods', {
  list: () => ({
    queryKey: [PAYMENT_METHOD_PATH],
    queryFn: () => apiNew.get<never, IPaymentMethodResponseDTO[]>(PAYMENT_METHOD_PATH),
  }),
});

export function usePaymentMethods() {
  return useSuspenseQuery({ ...paymentMethodFactory.list(), select: sortPaymentMethods });
}

export function useCreatePaymentMethod() {
  const toast = useToast();
  const { t } = useTranslation(['paymentMethods']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (paymentMethodCreationDTO: IPaymentMethodCreationDTO) =>
      apiNew.post<IPaymentMethodCreationDTO, IPaymentMethodResponseDTO>(PAYMENT_METHOD_PATH, paymentMethodCreationDTO),
    onError: () => {
      toast.error(t('paymentMethods:toast.create.error.title'), t('paymentMethods:toast.create.error.desc'));
    },
    onSuccess: () => {
      toast.success(t('paymentMethods:toast.create.success'));
      queryClient.invalidateQueries(paymentMethodFactory.list());
    },
  });
}

export function useUpdatePaymentMethod() {
  const toast = useToast();
  const { t } = useTranslation(['paymentMethods']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, dto }: { id: IPaymentMethod['id']; dto: IPaymentMethodUpdateDTO }) =>
      apiNew.patch<IPaymentMethodUpdateDTO, IPaymentMethodResponseDTO>(`${PAYMENT_METHOD_PATH}/${id}`, dto),
    onError: () => {
      toast.error(t('paymentMethods:toast.update.error.title'), t('paymentMethods:toast.update.error.desc'));
    },
    onSuccess: () => {
      toast.success(t('paymentMethods:toast.update.success'));
      queryClient.invalidateQueries(paymentMethodFactory.list());
    },
  });
}
