import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { AmountSummary, useCurrency, Section } from '@graneet/lib-ui';
import type { IBill, IProject } from '@graneet/business-logic';
import { sumObjects } from '@graneet/business-logic';
import { useTranslation, Trans } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import { flatMap, sumBy } from 'lodash-es';

import { useProjectSubProjectsWithBills } from 'features/project/services/project.api';
import { FinalStatementBillTable } from 'features/final-statement/components/FinalStatementBillTable/FinalStatementBillTable';
import { BillPayModal } from 'features/bill/components/modals/BillPayModal';

interface TotalSummaryProps {
  bills: IBill[];
}

const TotalSummary: FC<TotalSummaryProps> = ({ bills }) => {
  const { t } = useTranslation(['bill']);

  const totalToBePaidIncVAT = sumObjects(bills, 'totalToBePaidIncVAT');
  const totalRemainingToBePaidIncVAT = sumObjects(bills, 'remainingToBePaidIncVAT');

  return (
    <AmountSummary>
      <AmountSummary.Item
        important
        label={t('bill:detail.totalPaid')}
        amount={totalToBePaidIncVAT - totalRemainingToBePaidIncVAT}
      />
      <AmountSummary.Item
        warning
        label={t('bill:fields.remainingToBePaidIncVAT')}
        amount={totalRemainingToBePaidIncVAT}
      />
    </AmountSummary>
  );
};

interface ViewBillsStepProps {
  project: IProject;
}

export const ViewBillsStep: FC<ViewBillsStepProps> = ({ project }) => {
  const { t } = useTranslation(['project']);

  const { formatAsAmount } = useCurrency();

  const subProjectsWithBills = useProjectSubProjectsWithBills(project.id);

  const bills = useMemo(
    () => flatMap(subProjectsWithBills.data.subProjects, (subProject) => subProject.bills),
    [subProjectsWithBills.data.subProjects],
  );

  const totalRemainingToBePaidIncVAT = useMemo(() => sumBy(bills, (bill) => bill.remainingToBePaidIncVAT), [bills]);

  // Modal
  const [billToPay, setBillToPay] = useState<undefined | IBill>(undefined);
  const paymentModal = useDisclosure();
  const handleOpenPayment = useCallback(
    (bill: IBill) => {
      setBillToPay(bill);
      paymentModal.onOpen();
    },
    [paymentModal],
  );

  return (
    <>
      {billToPay && (
        <BillPayModal
          bill={billToPay}
          modal={paymentModal}
          onSuccess={async () => {
            await subProjectsWithBills.refetch();
          }}
        />
      )}

      <Section
        title={t('project:finalStatement.bills.title')}
        description={
          <Trans
            t={t}
            i18nKey="project:finalStatement.bills.description"
            values={{ amount: formatAsAmount(totalRemainingToBePaidIncVAT) }}
          />
        }
      >
        <FinalStatementBillTable subProjects={subProjectsWithBills.data.subProjects} onPay={handleOpenPayment} />
        <TotalSummary bills={bills} />
      </Section>
    </>
  );
};
