import { getCustomDiscountAmountExVAT, isNumberFinite } from '@graneet/business-logic';
import { useCurrency } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import type { CustomDiscountEditionForm } from '../forms/custom-discount-edition-form';
import { POSITIVE_MULTIPLIER } from '../forms/custom-discount-edition-form';

export const useCustomDiscountTotalExVAT = (amountWithoutDiscountExVAT: number | undefined) => {
  const { mapAmountToNumber } = useCurrency();

  const form = useFormContext<CustomDiscountEditionForm>();
  const { customDiscountType, customDiscountPercentage, customDiscountAmountExVAT, customDiscountMultiplier } =
    useOnChangeValues(form, [
      'customDiscountType',
      'customDiscountPercentage',
      'customDiscountAmountExVAT',
      'customDiscountMultiplier',
    ]);

  if (!isNumberFinite(amountWithoutDiscountExVAT) || !customDiscountType) {
    return 0;
  }

  const discount = {
    type: customDiscountType,
    percentage: isNumberFinite(customDiscountPercentage)
      ? customDiscountPercentage * (customDiscountMultiplier || POSITIVE_MULTIPLIER)
      : 0,
    amountExVAT: isNumberFinite(customDiscountAmountExVAT)
      ? mapAmountToNumber(customDiscountAmountExVAT * (customDiscountMultiplier || POSITIVE_MULTIPLIER))
      : 0,
  };

  return amountWithoutDiscountExVAT + getCustomDiscountAmountExVAT(discount, amountWithoutDiscountExVAT);
};
