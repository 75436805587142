export enum INVITEE_EMAIL_AVAILABILITY {
  AVAILABLE = 'AVAILABLE',
  ALREADY_INVITED = 'ALREADY_INVITED',
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
  ERROR = 'ERROR',
}

export interface IUserInvitationEmailAvailabilityResponseDTO {
  available: boolean;
  code: INVITEE_EMAIL_AVAILABILITY;
}
