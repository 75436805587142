import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { Nullable, SUPPLY_SHEET_EDITED_TYPE } from '@graneet/business-logic';
import { TRACKING_EVENT_ENUM } from '@graneet/business-logic';
import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';
import { MARGIN_TYPE, QuoteComputeAllChildrenUseCase } from '@org/quotation-lib';
import type { QuoteComponentUpsertDTO } from '@org/graneet-bff-client';
import Big from 'big.js';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { genericUpdateItemMapper } from 'features/quotation/quote-common/mappers/quoteItemsGenericMapper';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export type ComponentUpdateManyDTO = Nullable<
  Partial<Omit<QuoteComponentsSupplyObject, 'similarNodeIds' | 'componentType'>>
> & {
  componentTypeId?: number | null;
};
export function useQuoteComponentUpdateMany() {
  const { quote } = useQuote();
  const { quoteComponentsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const segmentAnalytics = useSegmentAnalytics();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeIds: string[], values: ComponentUpdateManyDTO, trackingEventType: SUPPLY_SHEET_EDITED_TYPE): boolean => {
      try {
        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();

        // Find the components to update:

        if (nodeIds.length === 0) {
          throw new Error('No components related found');
        }

        const componentsToUpdateDTO: QuoteComponentUpsertDTO[] = [];
        nodeIds.forEach((relatedComponentNodeId) => {
          const quoteNode = quote.getTree().getNodeOrThrow(relatedComponentNodeId);
          const quoteComponentRelated = quote.getTree().getQuoteComponentOrThrow(relatedComponentNodeId);
          if ('unitFlatCostAmount' in values) {
            quoteComponentRelated.updateUnitFlatCostAmount(new Big(values.unitFlatCostAmount!), {
              spreadUp: false,
              impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
            });
          }

          if ('componentTypeId' in values && values.componentTypeId !== undefined) {
            quoteComponentRelated.updateComponentTypeId(values.componentTypeId!, {
              spreadUp: false,
              impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
            });
          }

          quoteComponentRelated.updateProperties(values);

          componentsToUpdateDTO.push({
            ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
            quoteComponentId: quoteComponentRelated.getId(),
            ...values,
          });
        });

        QuoteComputeAllChildrenUseCase.execute(quote.getTree());

        quoteSetToStore(quote);

        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });

        quoteComponentsProxyApi
          .updateManyQuoteComponent({
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
            QuoteComponents: componentsToUpdateDTO,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        // Tracking event for editing components supply
        segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.SUPPLY_SHEET_EDITED, { type: trackingEventType });
        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteComponentsProxyApi, quoteError, quoteSetClientRequestToStore, quoteSetToStore, segmentAnalytics],
  );
}
