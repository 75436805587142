import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMenu, AmountSummary, Modal, formatPercentage } from '@graneet/lib-ui';
import { Text, useDisclosure } from '@chakra-ui/react';
import { useFormContext, useOnBlurValues } from 'graneet-form';
import { DISCOUNT_TYPES, VAT_TYPE } from '@graneet/business-logic';

import { useInvoiceTreeContext } from '../hooks/useInvoiceTree';
import { useContainerTotals } from '../hooks/useContainerTotals';
import type { InvoiceEditionForm } from '../forms/invoice-edition-form';

interface InvoiceAmountsSummaryProps {
  onOpenDiscount(): void;
}

export const InvoiceAmountsSummary: FC<InvoiceAmountsSummaryProps> = ({ onOpenDiscount }) => {
  const { t } = useTranslation(['invoice', 'global', 'container', 'discount']);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const form = useFormContext<InvoiceEditionForm>();

  const values = useOnBlurValues<InvoiceEditionForm>(form, undefined);
  const tree = useInvoiceTreeContext();
  const totals = useContainerTotals(values, tree);

  const { discountType, discountPercentage, hasReversalOfLiability } = values;

  const handleRemoveDiscount = useCallback(
    () =>
      form.setFormValues({
        discountType: undefined,
        discountPercentage: undefined,
        discountAmountExVAT: undefined,
      }),
    [form],
  );

  const handleReversalOfLiabilityChange = useCallback(() => {
    form.setFormValues({ hasReversalOfLiability: !hasReversalOfLiability });
    onClose();
  }, [form, hasReversalOfLiability, onClose]);

  const { vatDistribution: vatDistributionRaw } = totals;

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Text mt={4}>{t('invoice:modals.disableReversalOfLiability.description')}</Text>

        <Modal.SecondaryButton onClick={onClose}>{t('global:words.c.cancel')}</Modal.SecondaryButton>

        <Modal.PrimaryButton onClick={handleReversalOfLiabilityChange}>
          {t('global:words.c.continue')}
        </Modal.PrimaryButton>
      </Modal>

      <AmountSummary mt={discountType ? 2 : 6}>
        {discountType && (
          <>
            <AmountSummary.Item
              important
              label={t('container:totals.totalExVATBeforeDiscount')}
              amount={totals.totalBeforeDiscountExVAT}
            />
            <AmountSummary.Item
              label={
                discountType === DISCOUNT_TYPES.PERCENTAGE
                  ? t('container:totals.discount', {
                      value: `${formatPercentage(-(discountPercentage || 0))}`,
                    })
                  : t('discount:amountLabel')
              }
              sign
              negative
              light
              amount={totals.discountExVAT}
              rightContent={
                <ActionMenu placement="top-start">
                  <ActionMenu.Edit onClick={onOpenDiscount} />
                  <ActionMenu.Delete onClick={handleRemoveDiscount} />
                </ActionMenu>
              }
            />
          </>
        )}

        <AmountSummary.Item label={t('container:totals.totalExVAT')} important amount={totals.totalExVAT} />
        <AmountSummary.VATDistribution
          vatType={hasReversalOfLiability ? VAT_TYPE.REVERSE_CHARGE : VAT_TYPE.NORMAL}
          dropdownItems={
            hasReversalOfLiability
              ? [
                  {
                    label: t('invoice:steps.container.disableReversalOfLiability'),
                    onClick: onOpen,
                  },
                ]
              : [
                  {
                    label: t('invoice:steps.container.enableReversalOfLiability'),
                    onClick: handleReversalOfLiabilityChange,
                  },
                ]
          }
          vatDistribution={vatDistributionRaw}
        />
        <AmountSummary.Item important label={t('container:totals.totalIncVAT')} amount={totals.totalIncVAT} />
      </AmountSummary>
    </>
  );
};
