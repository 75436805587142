import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { ModalProps } from '@graneet/lib-ui';
import { Modal } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { Box, VStack } from '@chakra-ui/react';
import type { IClient, IClientDTO, IClientResponseDTO, IUpdateClientDTO } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { ClientUpsertForm } from 'features/client/components/ClientUpsertForm/ClientUpsertForm';
import { useClientCreate, useClientEdit } from 'features/client/services/client.api';
import { formatAddressDTO } from 'features/common/components/fields/AddressFields';
import type { AddressesWithBillingFieldsForm } from 'features/common/components/fields/AddressesWithBillingFields';
import { usePermissions } from 'features/role/hooks/usePermissions';

interface ClientEditionFormValues extends AddressesWithBillingFieldsForm {
  enterpriseName: string;

  code?: string | null;

  auxiliaryAccount?: string | null;

  isLedgerEnabled: boolean;

  siren?: string | null;

  vatNumber?: string | null;
}

interface ClientUpsertModalProps {
  title: string;

  description?: string;

  isOpen: boolean;

  onClose(): void;

  onSuccess?: (client: IClientResponseDTO) => void;

  submitButtonLabel: string;

  client?: IClient | IClientResponseDTO;

  modalSize?: ModalProps['size'];
}

export const ClientUpsertModal: FC<ClientUpsertModalProps> = ({
  title,
  isOpen,
  onSuccess,
  onClose,
  client,
  submitButtonLabel,
  description,
  modalSize = '5xl',
}) => {
  const { t } = useTranslation(['global', 'clients']);

  const form = useForm<ClientEditionFormValues>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues, setFormValues } = form;

  const [isLoading, setIsLoading] = useState(false);

  const isEdit = !!client;
  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  const clientCreateMutation = useClientCreate();
  const clientEditMutation = useClientEdit();

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const {
      code,
      address,
      city,
      postalCode,
      country,
      billingAddressAddress,
      billingAddressCity,
      billingAddressPostalCode,
      billingAddressCountry,
      hasNoBillingAddress,
      enterpriseName,
      isLedgerEnabled,
      auxiliaryAccount,
      siren,
      vatNumber,
    } = getFormValues();

    const dto: IClientDTO | IUpdateClientDTO = {
      enterpriseName: enterpriseName!,
      siren: siren || '',
      vatNumber: vatNumber || '',
      code: code || '',
      address: formatAddressDTO({
        address,
        city,
        postalCode,
        country,
      }),
      hasBillingAddress: !hasNoBillingAddress,
      billingAddress: !hasNoBillingAddress
        ? formatAddressDTO({
            address: billingAddressAddress,
            city: billingAddressCity,
            postalCode: billingAddressPostalCode,
            country: billingAddressCountry,
          })
        : undefined,

      auxiliaryAccount: hasUpdateAccountingPermission ? auxiliaryAccount : undefined,
      ...(!isEdit && { isLedgerEnabled }),
    };

    const savedClient = await (isEdit
      ? clientEditMutation.mutateAsync({ id: client.id, dto: dto as IUpdateClientDTO })
      : clientCreateMutation.mutateAsync(dto as IClientDTO));

    setIsLoading(false);
    onSuccess?.(savedClient);
    onClose();
  }, [
    getFormValues,
    hasUpdateAccountingPermission,
    isEdit,
    clientEditMutation,
    client?.id,
    clientCreateMutation,
    onSuccess,
    onClose,
  ]);

  useEffect(() => {
    if (client) {
      setFormValues({
        ...client,
        address: client.address!.address,
        city: client.address!.city,
        siren: client.siren,
        postalCode: client.address!.postalCode,
        country: client.address!.country,
        hasNoBillingAddress: !client.hasBillingAddress,
        billingAddressAddress: client.billingAddress?.address,
        billingAddressPostalCode: client.billingAddress?.postalCode,
        billingAddressCity: client.billingAddress?.city,
        billingAddressCountry: client.billingAddress?.country,
        auxiliaryAccount: client.auxiliaryAccount,
      });
    }
  }, [client, setFormValues]);

  useEffect(() => {
    if (!client) {
      setFormValues({
        hasNoBillingAddress: true,
      });
    }
  }, [client, isOpen, setFormValues]);

  return (
    <Form form={form}>
      <Modal isOpen={isOpen} onClose={onClose} title={title} size={modalSize} scrollBehavior="inside">
        <VStack spacing={4} align="stretch">
          {description && <Box>{description}</Box>}

          <ClientUpsertForm client={client} form={form} />
        </VStack>

        <Modal.SecondaryButton onClick={onClose}>{t('clients:modal.buttons.cancel')}</Modal.SecondaryButton>

        <Modal.PrimaryButton isLoading={isLoading} isDisabled={!isFormValid} onClick={handleSubmit}>
          {submitButtonLabel}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
