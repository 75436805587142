import type { ICompany, PlanSubscription } from '../company/company.type';
import type { IUser, PERSONNA } from '../user/user.type';

export interface IIdentifyData {
  userId: string;
  traits: {
    id: number;
    lastName: string;
    firstName: string;
    email: string;
    companyId: number;
    companyName: string;
    personna: PERSONNA | null;
  };
}

export interface IGroupData {
  groupId: string;
  traits: {
    id: number;
    name: string;
    createdAt: Date;
    planAnnualPricing: number | null;
    planSubscription: PlanSubscription | null;
    planStartDate: Date | null;
  };
}

export const generateIdentifyData = (
  company: Pick<ICompany, 'id' | 'corporateName'>,
  user: Pick<IUser, 'id' | 'auth0ID' | 'lastName' | 'firstName' | 'email' | 'personna'>,
): IIdentifyData => ({
  userId: user.auth0ID,
  traits: {
    id: user.id,
    lastName: user.lastName,
    firstName: user.firstName,
    email: user.email,
    companyId: company.id,
    companyName: company.corporateName,
    personna: user.personna,
  },
});

export const generateGroupData = (
  company: Pick<
    ICompany,
    'id' | 'corporateName' | 'planAnnualPricing' | 'planSubscription' | 'planStartDate' | 'createdAt'
  >,
): IGroupData => ({
  groupId: `${company.id}`,
  traits: {
    id: company.id,
    name: company.corporateName,
    createdAt: company.createdAt,
    planAnnualPricing: company.planAnnualPricing,
    planSubscription: company.planSubscription,
    planStartDate: company.planStartDate,
  },
});
