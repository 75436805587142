import type { IContract } from '../contract/contract.type';
import type { IContractResponseDTO } from '../contract/dtos/contract-response.dto';
import type { IDownPayment } from '../down-payment/down-payment.type';
import type { IFinalStatement } from '../final-statement/final-statement.type';
import type { IProgressStatement } from '../progress-statement/progress-statement.type';
import type { IProject } from '../project/project.type';
import type { ISubProject } from '../sub-project/sub-project.type';

type NON_EXISTING = 'NON_EXISTING';

export interface IStatementTransition<T extends string, ERR extends string> {
  /**
   * State(s) which are able to transitions to other state(s)
   */
  from: T[] | NON_EXISTING;

  /**
   * From the given state(s) in `from`, the state(s) they are allowed to
   * transition to.
   */
  to: T[];

  /**
   * If a previous statement is found (groupId - 1), what statuses should
   * it be in for the transition to be valid ?
   */
  lastValidStatuses?: T[];

  /**
   * If a statement is found before the previous statement (groupId - 2),
   * what statuses should it be in for the transition to be valid ?
   */
  beforeLastValidStatuses?: T[];

  /**
   * If a next statement is found (groupId + 1), what statuses should it be
   * in for the transition to be valid ?
   */
  nextValidStatuses?: T[];

  /**
   * This function holds the logic besides the above status checks. It is not
   * beeing passed any arguments, thus it should solely rely on its context to
   * check whether this transition still is valid or not.
   * It returns the same type of errors as the status checks.
   */
  extraConditions?: () => ERR[];
}

function findExistingTransition<T extends string, ERR extends string>(
  fromStatus: T | undefined,
  toStatus: T,
  transitions: IStatementTransition<T, ERR>[],
): IStatementTransition<T, ERR> | undefined {
  return transitions.find(({ from, to }) => {
    /**
     * Creation, going from `undefined` status, from directive should be `NON_EXISTING`
     */
    if (fromStatus === undefined) {
      return from === 'NON_EXISTING';
    }
    return from.includes(fromStatus) && to.includes(toStatus);
  });
}

function isLastProgressStatementStatusValid<T extends string, ERR extends string>(
  lastProgressStatementStatus: T | undefined,
  transition: IStatementTransition<T, ERR>,
): boolean {
  if (!lastProgressStatementStatus) {
    return true;
  }
  if (lastProgressStatementStatus && transition.lastValidStatuses) {
    if (!transition.lastValidStatuses.includes(lastProgressStatementStatus)) {
      return false;
    }
  }
  return true;
}

function isBeforeLastProgressStatementStatusValid<T extends string, ERR extends string>(
  beforeLastProgressStatementStatus: T | undefined,
  transition: IStatementTransition<T, ERR>,
): boolean {
  if (!beforeLastProgressStatementStatus) {
    return true;
  }
  if (beforeLastProgressStatementStatus && transition.beforeLastValidStatuses) {
    if (!transition.beforeLastValidStatuses.includes(beforeLastProgressStatementStatus)) {
      return false;
    }
  }
  return true;
}

function isNextProgressStatementStatusValid<T extends string, ERR extends string>(
  nextProgressStatementStatus: T | undefined,
  transition: IStatementTransition<T, ERR>,
): boolean {
  if (!nextProgressStatementStatus) {
    return true;
  }
  if (nextProgressStatementStatus && transition.nextValidStatuses) {
    if (!transition.nextValidStatuses.includes(nextProgressStatementStatus)) {
      return false;
    }
  }
  return true;
}

export type StatementStateMachineContext = {
  beforePreviousProgressStatement?: IProgressStatement;
  previousProgressStatement?: IProgressStatement;
  nextProgressStatement?: IProgressStatement;
  latestAcceptedProgressStatement?: IProgressStatement;
  downPayment?: IDownPayment | null;
  project?: IProject;
  subProject?: ISubProject | null;
  contracts?: (IContract | IContractResponseDTO)[] | null;
  finalStatement?: IFinalStatement | null;
  hasPaymentAccountingExported?: boolean;
  isAccountingExported?: boolean;
  withCredit?: boolean;
};

export enum STATEMENT_TRANSITION_ERRORS {
  INVALID_PREVIOUS_STATUS = 'INVALID_PREVIOUS_STATUS',
  INVALID_SECOND_PREVIOUS_STATUS = 'INVALID_SECOND_PREVIOUS_STATUS',
  INVALID_NEXT_STATUS = 'INVALID_NEXT_STATUS',
  UNKNOWN_TRANSITION = 'UNKNOWN_TRANSITION',
}

export function StatementStateMachine<T extends string, ERR extends string>(
  allowedTransitions: IStatementTransition<T, ERR>[],
  currentStatus: T | undefined,
  context: StatementStateMachineContext = {},
): {
  transitionTo: (newStatus: T) => (ERR | STATEMENT_TRANSITION_ERRORS)[];
} {
  return {
    transitionTo(newStatus: T): (ERR | STATEMENT_TRANSITION_ERRORS)[] {
      const transition = findExistingTransition(currentStatus, newStatus, allowedTransitions);

      if (!transition) {
        return [STATEMENT_TRANSITION_ERRORS.UNKNOWN_TRANSITION];
      }

      const errors: (ERR | STATEMENT_TRANSITION_ERRORS)[] = [];

      /**
       * In found transition, check the status of non-canceled next progress statement
       */
      const { nextProgressStatement } = context;
      const nextStatus = nextProgressStatement?.status;
      if (nextStatus && !isNextProgressStatementStatusValid<T, ERR>(nextStatus as T, transition)) {
        errors.push(STATEMENT_TRANSITION_ERRORS.INVALID_NEXT_STATUS);
      }

      /**
       * In found transition, check the status of non-canceled last progress statement
       */
      const { beforePreviousProgressStatement } = context;
      const secondPreviousStatus = beforePreviousProgressStatement?.status;
      if (!isBeforeLastProgressStatementStatusValid<T, ERR>(secondPreviousStatus as T, transition)) {
        errors.push(STATEMENT_TRANSITION_ERRORS.INVALID_SECOND_PREVIOUS_STATUS);
      }

      /**
       * In found transition, check the status of progress statement before the last non-canceled progress statement
       */
      const { previousProgressStatement } = context;
      const previousStatus = previousProgressStatement?.status;
      if (!isLastProgressStatementStatusValid<T, ERR>(previousStatus as T, transition)) {
        errors.push(STATEMENT_TRANSITION_ERRORS.INVALID_PREVIOUS_STATUS);
      }

      if (transition.extraConditions) {
        errors.push(...transition.extraConditions());
      }

      return errors;
    },
  };
}
