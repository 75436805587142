import { ActionMenu } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { ISupplierInvoicePaymentExtended } from '@graneet/business-logic';
import { canSupplierInvoicePaymentBeUpdated } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

interface SupplierInvoicePaymentActionMenuProps {
  supplierInvoicePayment: ISupplierInvoicePaymentExtended;

  onEdit(): void;

  onDelete(): void;
}

export const SupplierInvoicePaymentActionMenu: FC<SupplierInvoicePaymentActionMenuProps> = ({
  supplierInvoicePayment,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation(['supplierInvoices']);

  const canUpdateSupplierInvoicePayment = useMemo(() => {
    const state = canSupplierInvoicePaymentBeUpdated(supplierInvoicePayment);

    if (state.causes.includes('accounting')) {
      return {
        isDisabled: true,
        tooltip: t('supplierInvoices:tooltips.cannotUpdatePaymentBecauseOfAccounting'),
      };
    }
    if (state.causes.length > 0) {
      throw new Error('Unhandled error');
    }

    return {
      isDisabled: false,
      tooltip: undefined,
    };
  }, [supplierInvoicePayment, t]);

  const canDeleteSupplierInvoicePayment = useMemo(() => {
    const state = canSupplierInvoicePaymentBeUpdated(supplierInvoicePayment);

    if (state.causes.includes('accounting')) {
      return {
        isDisabled: true,
        tooltip: t('supplierInvoices:tooltips.cannotDeletePaymentBecauseOfAccounting'),
      };
    }
    if (state.causes.length > 0) {
      throw new Error('Unhandled error');
    }

    return {
      isDisabled: false,
      tooltip: undefined,
    };
  }, [supplierInvoicePayment, t]);

  return (
    <ActionMenu>
      <ActionMenu.Edit
        onClick={onEdit}
        isDisabled={canUpdateSupplierInvoicePayment.isDisabled}
        tooltip={canUpdateSupplierInvoicePayment.tooltip}
      />
      <ActionMenu.Delete
        onClick={onDelete}
        isDisabled={canDeleteSupplierInvoicePayment.isDisabled}
        tooltip={canDeleteSupplierInvoicePayment.tooltip}
      />
    </ActionMenu>
  );
};
