import { Flex } from '@chakra-ui/react';
import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

interface PlanTypeBadgeProps {
  plan: 'advanced' | 'expert' | 'premium';
}

export const PlanTypeBadge: FC<PlanTypeBadgeProps> = ({ plan }) => {
  const { t } = useTranslation(['global']);

  return (
    <Badge colorScheme="warm_gray">
      <Flex gap={2}>
        <i className="ri-lock-fill" />
        {t(`global:planType.${plan}`)}
      </Flex>
    </Badge>
  );
};
