import { Box, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import type { ILedgerWithNextNumber } from '@graneet/business-logic';
import { LEDGER_TYPES } from '@graneet/business-logic';
import {
  Badge,
  Card,
  Modal,
  SimpleArrowForwardIcon,
  SimpleEditIcon,
  SimplePowerIcon,
  Tooltip,
  Button,
} from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateOrUpdateLedger } from '../services/ledger.api';

import { CreateOrUpdateLedgerModal } from './modals/CreateOrUpdateLedgerModal';
import { UpdateExpertLedgerModal } from './modals/UpdateExpertLedgerModal';

/**
 * Controls to display when ledger is disabled
 */
type EnabledLedgerControlsProps = {
  ledger: ILedgerWithNextNumber;
  isActionAllowed: boolean;
  onClickEditLedger: () => void;
};

const EnabledLedgerControls: FC<EnabledLedgerControlsProps> = ({ ledger, isActionAllowed, onClickEditLedger }) => {
  const { t } = useTranslation(['global', 'ledger']);

  const { isOpen: disableModalIsOpen, onOpen: disableModalOnOpen, onClose: disableModalOnClose } = useDisclosure();

  const createOrUpdateLedgerMutation = useCreateOrUpdateLedger();

  const handleDisable = useCallback(async () => {
    createOrUpdateLedgerMutation.mutate(
      {
        type: ledger.type,
        isEnabled: false,
      },
      {
        onSuccess: () => {
          disableModalOnClose();
        },
      },
    );
  }, [createOrUpdateLedgerMutation, disableModalOnClose, ledger.type]);

  return (
    <>
      <HStack spacing={3}>
        <Tooltip label={t('ledger:settings.action.notAllowed')} isDisabled={isActionAllowed} shouldWrapChildren>
          <Button
            variant="ghost"
            isDisabled={!isActionAllowed}
            leftIcon={<SimplePowerIcon />}
            isLoading={createOrUpdateLedgerMutation.isPending}
            onClick={disableModalOnOpen}
          >
            {t('global:words.c.disable')}
          </Button>
        </Tooltip>
        <Tooltip label={t('ledger:settings.action.notAllowed')} isDisabled={isActionAllowed} shouldWrapChildren>
          <Button
            variant="ghost"
            colorScheme="blue"
            isDisabled={!isActionAllowed}
            leftIcon={<SimpleEditIcon />}
            onClick={onClickEditLedger}
          >
            {t('global:words.c.update')}
          </Button>
        </Tooltip>
        <Box
          borderColor="gray.300"
          borderWidth={1}
          borderRadius="8px"
          p="2px"
          textAlign="center"
          backgroundColor="gray.50"
          fontSize="sm"
          minW={200}
        >
          {ledger.nextNumber}
        </Box>
      </HStack>
      <Modal isOpen={disableModalIsOpen} onClose={disableModalOnClose} title={t('ledger:settings.disableModalTitle')}>
        {t('ledger:settings.disableModalText', { entity: t(`ledger:settings.type.${ledger.type}`) })}

        <Modal.Close>{t('global:words.c.no')}</Modal.Close>

        <Modal.PrimaryButton
          onClick={handleDisable}
          isLoading={createOrUpdateLedgerMutation.isPending}
          colorScheme="red"
        >
          {t('global:words.c.yes')}
        </Modal.PrimaryButton>
      </Modal>
    </>
  );
};

/**
 * Controls to display when ledger is disabled or does not exist
 */
type DisabledLedgerControlsProps = {
  onClickEnableLedger?: () => void;
  isActionAllowed: boolean;
};

const DisabledLedgerControls: FC<DisabledLedgerControlsProps> = ({ onClickEnableLedger, isActionAllowed }) => {
  const { t } = useTranslation(['ledger']);

  return (
    <Tooltip label={t('ledger:settings.action.notAllowed')} isDisabled={isActionAllowed} shouldWrapChildren>
      <Button
        variant="ghost"
        colorScheme="blue"
        isDisabled={!isActionAllowed}
        onClick={onClickEnableLedger}
        leftIcon={<SimpleArrowForwardIcon />}
      >
        {t('ledger:settings.action.enable')}
      </Button>
    </Tooltip>
  );
};

/**
 * Ledger card
 */
export type LedgerItemProps = {
  ledgerType: LEDGER_TYPES;
  ledger?: ILedgerWithNextNumber;
};

export const LedgerItem: FC<LedgerItemProps> = ({ ledgerType, ledger }) => {
  const { t } = useTranslation(['ledger', 'global']);

  const { isOpen: editModalIsOpen, onOpen: editModalOnOpen, onClose: editModalOnClose } = useDisclosure();
  const {
    isOpen: editExpertModalIsOpen,
    onOpen: editExpertModalOnOpen,
    onClose: editExpertModalOnClose,
  } = useDisclosure();

  const createOrUpdateLedgerMutation = useCreateOrUpdateLedger();

  const enableExpertLedger = useCallback(async () => {
    await createOrUpdateLedgerMutation.mutateAsync({ type: ledgerType, isEnabled: true });
  }, [createOrUpdateLedgerMutation, ledgerType]);

  // On a statement ledger, you can only enable it if it didn't exist. All other actions (edit, re-enable, delete are blocked).
  const isActionAllowed = ledgerType !== LEDGER_TYPES.STATEMENT || ledger === undefined;

  return (
    <Card>
      <Flex justifyContent="space-between" alignItems="center">
        <HStack>
          <Text fontFamily="heading" fontSize="xl" lineHeight={1} fontWeight="md" color="chakra-body-text" mr={4}>
            {t(`ledger:settings.type.${ledgerType}`)}
          </Text>
          {ledger?.isEnabled ? (
            <Badge showDot colorScheme="green">
              {t('ledger:settings.enabled')}
            </Badge>
          ) : (
            <Badge showDot colorScheme="red">
              {t('ledger:settings.disabled')}
            </Badge>
          )}
        </HStack>
        {ledger?.isEnabled ? (
          <EnabledLedgerControls
            onClickEditLedger={ledger.isExpert ? editExpertModalOnOpen : editModalOnOpen}
            isActionAllowed={isActionAllowed}
            ledger={ledger}
          />
        ) : (
          <DisabledLedgerControls
            isActionAllowed={isActionAllowed}
            onClickEnableLedger={ledger?.isExpert ? enableExpertLedger : editModalOnOpen}
          />
        )}
      </Flex>
      <CreateOrUpdateLedgerModal
        ledger={ledger}
        ledgerType={ledgerType}
        isOpen={editModalIsOpen}
        onClose={editModalOnClose}
      />
      {ledger?.isExpert && (
        <UpdateExpertLedgerModal
          ledger={ledger}
          ledgerType={ledgerType}
          isOpen={editExpertModalIsOpen}
          onClose={editExpertModalOnClose}
        />
      )}
    </Card>
  );
};
