import { divideFloating, multiplyFloating, roundFloating } from '../../../../utils';
import { timeTableTranslations } from '../../configureDefaultLabels';

const MINUTES_PER_HOUR = 60;
const PERCENT = 100;

const formatNumber = (number: number) => `${number}`.replace('.', ',');

const calculateHoursFromMinutes = (nbMinutes: number) =>
  divideFloating(roundFloating(multiplyFloating(divideFloating(nbMinutes, MINUTES_PER_HOUR), PERCENT)), PERCENT);

export const getTimeTableSlotInfo = (nbMinutes: number, absenceDuration: number) => {
  const isAbsence = !!absenceDuration;

  let info: number | string = isAbsence ? absenceDuration : calculateHoursFromMinutes(nbMinutes);
  if (!isAbsence) {
    info = formatNumber(info);
  }

  const label = isAbsence ? timeTableTranslations.dayLabel : timeTableTranslations.hourLabel;

  return `${info}${label}`;
};
