import type { FC } from 'react';
import { useCallback, useState } from 'react';
import type { DrawerApi } from '@graneet/lib-ui';
import { Button, DrawersStack, ListingLayout } from '@graneet/lib-ui';
import type { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import type { ILibraryJobResponseDTO } from '@graneet/business-logic';

import { useLibraryJobs } from '../services/library-job.api';

import { LibraryJobTable } from './LibraryJobTable';

interface LibraryJobImportProps {
  drawer: DrawerApi<string>;
  onSubmit(selectedLibraryJobs: ILibraryJobResponseDTO[]): Promise<[AxiosError<unknown, any>]> | null | void;
  title: string;
  description?: string;
}

export const LibraryJobImportDrawer: FC<LibraryJobImportProps> = ({ drawer, onSubmit, title, description }) => {
  const { t } = useTranslation(['library']);

  const libraryJobs = useLibraryJobs({
    storageStrategy: 'state',
  });

  const [selectedRows, setSelectedRows] = useState<ILibraryJobResponseDTO[]>([]);
  const [isImporting, setIsImporting] = useState(false);

  const handleOnSubmit = useCallback(async () => {
    setIsImporting(true);

    const errors = await onSubmit(selectedRows);

    if (!errors?.length) {
      drawer.onClose();
    }

    setSelectedRows([]);
    setIsImporting(false);
  }, [drawer, onSubmit, selectedRows]);

  return (
    <DrawersStack.Drawer
      title={title}
      drawer={drawer}
      footer={
        <Flex justify="flex-end" w="100%">
          <Button onClick={handleOnSubmit} isLoading={isImporting} isDisabled={selectedRows.length === 0}>
            {t('library:importLibraryJobModal.cta', { count: selectedRows.length })}
          </Button>
        </Flex>
      }
      size="2xl"
    >
      {description && <Text mb={4}>{description}</Text>}

      <ListingLayout
        pagination={libraryJobs}
        search={{ placeholder: t('library:actions.searchLibraryJob') }}
        content={
          <LibraryJobTable
            gridId="library-job-import"
            libraryJobs={libraryJobs}
            onRowSelected={setSelectedRows}
            displayComponentIndicator
          />
        }
      />
    </DrawersStack.Drawer>
  );
};
