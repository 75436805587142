import type { IAddress } from '../address/address.type';
import type { ICompany } from '../company/company.type';
import type { IContact } from '../contact/contact.type';
import type { IOrder } from '../order/order.type';
import type { ISupplierInvoice } from '../supplier-invoice/supplier-invoice.type';
import type { RequiredByKeys } from '../common/types/object.type';

export enum SUPPLIER_TYPE {
  SUB_CONTRACTOR = 'SUB_CONTRACTOR',
  SUPPLIER = 'SUPPLIER',
}

export interface ISupplier {
  id: number;

  name: string;

  code: string | null;

  type: SUPPLIER_TYPE;

  note: string;

  auxiliaryAccount: string | null;

  hasBillingAddress: boolean;

  address?: IAddress;

  billingAddress?: IAddress | null;

  company?: ICompany;

  supplierInvoices?: ISupplierInvoice[];

  contacts?: IContact[] | null;

  orders?: IOrder[];

  createdAt: Date;

  updatedAt: Date;

  isContactMigrated: boolean;

  tags: string[];

  iban: string | null;
}

export type ISupplierWithRelations = RequiredByKeys<ISupplier, 'address' | 'billingAddress' | 'company'>;
