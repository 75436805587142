import type {
  IQuoteSellsheetAmountDTO,
  IQuoteSellsheetComponentMarginDTO,
  IQuoteSellsheetMarginDTO,
  IQuoteUpdateResponse,
} from '@graneet/business-logic';

import { api } from 'features/api/services/api.service';

const QUOTES_PATH = '/quotes';

export const updateQuoteAmountSellSheet = (quoteId: number, quoteSellsheetAmountDTO: IQuoteSellsheetAmountDTO) =>
  api.patch<IQuoteSellsheetAmountDTO, IQuoteUpdateResponse>(
    `${QUOTES_PATH}/${quoteId}/sellsheet/amount`,
    quoteSellsheetAmountDTO,
  );

export const previewQuoteAmountSellSheet = (quoteId: number, data: IQuoteSellsheetAmountDTO) =>
  api.post<IQuoteSellsheetAmountDTO, IQuoteUpdateResponse>(`${QUOTES_PATH}/${quoteId}/sellsheet/amount/preview`, data);

export const updateComponentTypeMarginSellSheet = (
  quoteId: number,
  componentTypeId: number,
  data: IQuoteSellsheetComponentMarginDTO,
) =>
  api.patch<IQuoteSellsheetComponentMarginDTO, IQuoteUpdateResponse>(
    `${QUOTES_PATH}/${quoteId}/sellsheet/componentType/${componentTypeId}/margin`,
    data,
  );

export const previewComponentTypeMarginSellSheet = (
  quoteId: number,
  componentTypeId: number,
  data: IQuoteSellsheetComponentMarginDTO,
) =>
  api.post<IQuoteSellsheetComponentMarginDTO, IQuoteUpdateResponse>(
    `${QUOTES_PATH}/${quoteId}/sellsheet/componentType/${componentTypeId}/margin/preview`,
    data,
  );

export const updateQuoteMarginSellSheet = (quoteId: number, data: IQuoteUpdateResponse) =>
  api.patch<IQuoteUpdateResponse, IQuoteUpdateResponse>(`${QUOTES_PATH}/${quoteId}/sellsheet/margin`, data);

export const previewQuoteMarginSellSheet = (quoteId: number, data: IQuoteSellsheetMarginDTO) =>
  api.post<IQuoteSellsheetMarginDTO, IQuoteUpdateResponse>(`${QUOTES_PATH}/${quoteId}/sellsheet/margin/preview`, data);
