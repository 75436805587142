import type { FC } from 'react';
import { useCallback } from 'react';
import { ActionMenu, formatNumberToVatRate, useCurrency } from '@graneet/lib-ui';
import { useFormContext } from 'graneet-form';
import { isNumberFinite } from '@graneet/business-logic';

import { useInvoiceTreeContext } from '../../hooks/useInvoiceTree';
import type { InvoiceEditionForm } from '../../forms/invoice-edition-form';
import { getItemFieldName } from '../../forms/invoice-edition-form';

interface InvoiceItemMenuProps {
  itemId: string | number;
}

export const InvoiceItemMenu: FC<InvoiceItemMenuProps> = ({ itemId }) => {
  const { mapNumberToAmount } = useCurrency();
  const { deleteLeaf, duplicateLeaf, getCurrentTree } = useInvoiceTreeContext();

  const { setFormValues } = useFormContext<InvoiceEditionForm>();

  const onDuplicate = useCallback(() => {
    const newId = duplicateLeaf(itemId);

    const item = getCurrentTree().leaves[itemId];
    const newFormValues: Partial<InvoiceEditionForm> = {};
    newFormValues[getItemFieldName(newId, 'code')] = item.code;
    newFormValues[getItemFieldName(newId, 'description')] = item.description;
    newFormValues[getItemFieldName(newId, 'unit')] = item.unit;
    newFormValues[getItemFieldName(newId, 'quantity')] = item.quantity;
    newFormValues[getItemFieldName(newId, 'unitPrice')] = isNumberFinite(item.unitPrice)
      ? mapNumberToAmount(item.unitPrice)
      : undefined;
    newFormValues[getItemFieldName(newId, 'vatRate')] = isNumberFinite(item.vatRate)
      ? formatNumberToVatRate(item.vatRate)
      : item.vatRate;
    setFormValues(newFormValues);
  }, [duplicateLeaf, getCurrentTree, itemId, mapNumberToAmount, setFormValues]);

  const onDelete = useCallback(() => {
    deleteLeaf(itemId);
  }, [deleteLeaf, itemId]);

  return (
    <ActionMenu placement="bottom-end">
      <ActionMenu.Duplicate onClick={onDuplicate} />
      <ActionMenu.Delete onClick={onDelete} />
    </ActionMenu>
  );
};
