import { createContext, useContext } from 'react';
import type { ColGroupDef } from '@ag-grid-community/core';
import { compact, keyBy } from 'lodash-es';

import type { ColDefEnforced } from '../SimpleTable/SimpleTable';

export type PaginatedTableSetting<TData = unknown> = {
  field: keyof TData;
  hide: boolean;
  width: number;
};

export interface PaginatedTableSettingsContext<TData = unknown> {
  settings: Record<string, PaginatedTableSetting<TData>[]>;

  updateSettings: (gridId: string, setting: PaginatedTableSetting<TData>[]) => void;
}

const paginatedTableSettingsContext = createContext<PaginatedTableSettingsContext>({
  settings: {},
  updateSettings: () => {},
});

export const PaginatedTableSettingsProvider = paginatedTableSettingsContext.Provider;

export const usePaginatedTableSettingsContext = <TData = unknown,>() =>
  useContext(paginatedTableSettingsContext) as PaginatedTableSettingsContext<TData>;

export function getDisplayedPaginatedTableConfiguration<TData = unknown>(
  defaultConfiguration: (ColDefEnforced<TData> | ColGroupDef)[],
  setting: PaginatedTableSetting<TData>[],
): (ColDefEnforced<TData> | ColGroupDef)[] {
  const settingsKeyBy = keyBy(setting, (s) => s.field) as Record<keyof TData, PaginatedTableSetting<TData>>;

  const configurationWithCustomSetting: Record<keyof TData, ColDefEnforced<TData> | ColGroupDef> = {} as Record<
    keyof TData,
    ColDefEnforced<TData> | ColGroupDef
  >;
  const configurationWithoutCustomSettings: Array<ColDefEnforced<TData> | ColGroupDef> = [];

  defaultConfiguration.forEach((conf) => {
    // ColGroupDef
    if (!('field' in conf)) {
      configurationWithoutCustomSettings.push(conf);
      return;
    }

    const customConfig = settingsKeyBy[conf.field];
    if (customConfig) {
      configurationWithCustomSetting[conf.field] = {
        ...conf,
        ...customConfig,
      };
    } else {
      configurationWithoutCustomSettings.push(conf);
    }
  });

  return [
    ...compact(
      setting.map((conf) => {
        // ColGroupDef
        if (!('field' in conf)) {
          throw new Error('Group def saving is not implemented');
        }

        return configurationWithCustomSetting[conf.field];
      }),
    ),
    ...configurationWithoutCustomSettings,
  ];
}
