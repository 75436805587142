import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { compact } from 'lodash-es';

import type { DownPaymentActionsParams } from './down-payment.actions.type';
import { getDownloadPDFAction, getOpenMailingAction, getPreviewPDFAction } from './down-payment.actions.util';

export function getDownPaymentCancelledActions(params: DownPaymentActionsParams): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  const viewPdfAction = getPreviewPDFAction(params);

  if (viewPdfAction) {
    actions.push({
      type: 'secondary',
      ...viewPdfAction,
    });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([getOpenMailingAction(params), getDownloadPDFAction(params)]),
  });

  return actions;
}
