import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { deleteQuoteCustomDiscount } from 'features/quote/services/quote.api';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Delete a custom discount with vat rates
 */

export const useDeleteQuoteCustomDiscount = () => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'discount']);

  const { updateDataLocally } = useQuoteEditContext();
  return useCallback(
    async (quoteId: number, customDiscountId: string) => {
      const [err, result] = await deleteQuoteCustomDiscount(quoteId, customDiscountId);
      if (err) {
        toast.error(t('global:words.c.error'), t('discount:toasts.error', { email: SUPPORT_EMAIL }));
        return;
      }

      updateDataLocally(result);
    },
    [updateDataLocally, toast, t],
  );
};
