import { useMemo, useState } from 'react';
import { useCallbackRef } from '@chakra-ui/react';

import type { PaginatedResponse } from '../PaginatedData/index';

import type { UsePaginationOptions } from './usePagination';
import { usePagination } from './usePagination';

export interface Pagination {
  updateSearch: (search: string) => void;
  queryParams: string;
  updateFilters: (
    filters: Record<string, any>,
    options?: {
      eraseFilters?: boolean;
    },
  ) => Promise<void>;
  getParams: (paginationParams: { page: number; sort: Record<string, string> }) => URLSearchParams;
  size: number;
  resetFilters: () => void;
}

export type PaginationQuery<TData extends PaginatedResponse<object>> = {
  fetchData: (params: { page: number; sort: Record<string, any> }) => Promise<TData>;
  pagination: Pagination;
  refetch: () => void;
};

export function usePaginationQuery<TData extends PaginatedResponse<object>>(
  fetchData: (paginationParams: URLSearchParams) => Promise<TData>,
  paginationOptions?: UsePaginationOptions,
): PaginationQuery<TData> {
  const pagination = usePagination(paginationOptions);

  const [trigger, setTrigger] = useState(0);

  const fetch = useCallbackRef(fetchData);

  return useMemo(
    () => ({
      pagination,
      fetchData: (urlSearchParams) => fetch(pagination.getParams(urlSearchParams)),
      refetch: () => setTrigger(trigger + 1),
    }),
    [fetch, pagination, trigger],
  );
}
