import type { ISupplierInvoice } from '../supplier-invoice/supplier-invoice.type';
import { PROGRESS_STATEMENT_STATUSES } from '../progress-statement/progress-statement.type';
import type { IOrderWithDirectPaymentAmounts } from '../order/order.type';

export const isOrderInDirectPaymentPartiallyEditable = (associatedSupplierInvoices: ISupplierInvoice[]): boolean => {
  if (associatedSupplierInvoices?.length === 0) return true;

  return associatedSupplierInvoices.every((supplierInvoice) => {
    if (supplierInvoice) {
      const { progressStatement } = supplierInvoice;
      if (
        progressStatement &&
        progressStatement.status &&
        progressStatement.status !== PROGRESS_STATEMENT_STATUSES.DRAFT &&
        progressStatement.isArchived !== true
      ) {
        return false;
      }
    }
    return true;
  });
};

export const checkDirectPaymentAssociation = (
  supplierInvoice: ISupplierInvoice,
  order: Pick<
    IOrderWithDirectPaymentAmounts,
    | 'id'
    | 'isDirectPayment'
    | 'amountExVAT'
    | 'vatDistribution'
    | 'vatType'
    | 'amountPreviousDirectPaymentsExVAT'
    | 'project'
    | 'supplier'
  >,
  projectId: number,
  progressStatementId?: number, // Only in edition context. undefined in creation context
): boolean => {
  // 0 - Check if order is a direct payment order
  if (!order.isDirectPayment) {
    return false;
  }

  // 1 - Check if amountExVAT is defined for both
  if (supplierInvoice.amountExVAT === null || order.amountExVAT === null) {
    return false;
  }

  // 1 - Check if the supplierInvoice amountExVAT is <= than order amountPreviousDirectPaymentsExVAT
  if (supplierInvoice.amountExVAT > order.amountExVAT - order.amountPreviousDirectPaymentsExVAT) {
    return false;
  }

  // 2 - Check if the supplierInvoice amountExVAT and order amountPreviousDirectPaymentsExVAT has same sign
  if (supplierInvoice.amountExVAT && Math.sign(supplierInvoice.amountExVAT) !== Math.sign(order.amountExVAT)) {
    return false;
  }

  // 4 - Check if order and supplierInvoices are attached to the same project than progress statement
  if (
    supplierInvoice.supplierInvoiceProjects?.length &&
    ((order.project && order.project?.id !== projectId) ||
      supplierInvoice.supplierInvoiceProjects.length > 1 ||
      supplierInvoice.supplierInvoiceProjects?.[0]?.project?.id !== projectId)
  ) {
    return false;
  }

  // 5 - Check if supplier invoice is already attached to a progress statement
  if (supplierInvoice.progressStatement && supplierInvoice.progressStatement.id !== progressStatementId) {
    return false;
  }

  // 6 - Check if supplier invoice and order are attached to the same supplier
  if (supplierInvoice.supplier?.id !== order.supplier?.id) {
    return false;
  }

  // 7 - Check if supplier invoice is attached to any order
  if (
    supplierInvoice.ordersSupplierInvoices?.length &&
    (supplierInvoice.ordersSupplierInvoices?.length > 1 || // If more than one relation exists
      !supplierInvoice.progressStatement?.id || // If at least one relation exists but progress statement is not attached
      supplierInvoice.ordersSupplierInvoices[0].order.id !== order.id) // if existing relation is not the same order
  ) {
    return false;
  }

  // 8 - Check if supplier invoice is attached to any payment
  if (supplierInvoice.supplierInvoicePayments?.length) {
    return false;
  }

  // If all condition matches, return true
  return true;
};
