import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Modal } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface DeleteModalProps {
  modalControls: UseDisclosureReturn;
  onSubmit(): void;
}

export const DeleteQuoteJobModal: FC<DeleteModalProps> = ({ modalControls, onSubmit }) => {
  const { t } = useTranslation(['quote', 'global']);
  const { onClose } = modalControls;

  return (
    <Modal {...modalControls} title={t('quote:jobsStep.deleteJob')} size="lg">
      {t('quote:jobsStep.confirms.deletingJobConfirm')}

      <Modal.SecondaryButton onClick={onClose}>{t('global:words.c.no')}</Modal.SecondaryButton>

      <Modal.PrimaryButton colorScheme="red" onClick={onSubmit}>
        {t('global:words.c.yes')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
