import type { FC, PropsWithChildren } from 'react';
import { Flex, GridItem } from '@chakra-ui/react';

export const ListingLayoutActions: FC<PropsWithChildren> = ({ children }) => {
  if (!children) {
    return null;
  }

  return (
    <GridItem colSpan="auto" display="flex" alignItems="center" justifyContent="end" mt={3} mr={3}>
      <Flex justifyContent="flex-end" alignItems="center" gap={3}>
        {children}
      </Flex>
    </GridItem>
  );
};
