import type { ConfigType, Dayjs, OpUnitType } from 'dayjs';
import dayjs from 'dayjs';
// HACK: This is needed to make dayjs plugins work with ESM build.
// eslint-disable-next-line import/extensions
import utc from 'dayjs/plugin/utc.js';
// eslint-disable-next-line import/extensions
import timezone from 'dayjs/plugin/timezone.js';

import { DEADLINE_TYPE } from './deadline-type.constant';

dayjs.extend(utc);
// timezone plugin depends on utc plugin
dayjs.extend(timezone);

export const dayjsTz: typeof dayjs = dayjs;

export const REFERENCE_TIMEZONE = 'Europe/Paris';

export const LONG_DATE_FORMAT_FR = 'DD/MM/YYYY';

/** Build a new date object using a determined timezone
 * this method use a determined time zone and dayjs valid constructor to return a new dayjs object with a determined tz
 * eg. dayjsTz.tz("2020-08-10", "Europe/Paris") => 2020-08-09T22:00:00.000Z
 * while if runtime is in UTC dayjs("2020-08-10") => 2020-08-10T00:00:00.000Z
 */
export const buildDateWithTz = (date: ConfigType, tz?: string): Dayjs => dayjsTz.tz(date, tz);

/** Add timezone information to a Date
 * this method attributes timezone information to a date, for formatting purposes
 * eg. if runtime is in UTC : dayjsTz("2020-08-10").tz("Europe/Paris") => 2020-08-10 02:00:00.000 Europe/Paris
 */
export const getDateWithTz = (date?: ConfigType, tz?: string): Dayjs => dayjsTz(date).tz(tz);

/** Add timezone information to a Date, preserving the time
 * this method attributes timezone information to a date, but does not change the time held by the object
 * it just adjusts the offset and tz held in the object
 * eg. if runtime is in UTC : dayjsTz("2020-08-10").tz("Europe/Paris", true) => 2020-08-10 00:00:00.000 Europe/Paris
 */
const getDateWithTzAndSameTime = (date?: ConfigType, tz?: string): Dayjs => dayjsTz(date).tz(tz, true);

/**  Dayjs days addition issue across daylight saving time changes
 * https://github.com/iamkun/dayjs/issues/1271
 * When adding N days to a date, dayjs basically adds N * 86400 seconds to the origin date
 * this does not work if there is a DST change in the middle :
 * => 2020-10-08 23:59:59 Europe/Paris + 30 days = 2020-11-07 22:59:59 Europe/Paris
 * we want the destination date to be the same time than the origin date.
 */
const transformDateWithSameTime = (originDateWithTz: Dayjs, transform: (date: Dayjs) => Dayjs): Dayjs => {
  // We derive the timezone from the input date
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const tz = originDateWithTz.$x.$timezone;

  /**
   * dayjsTz.tz(date, tz) and dayjsTz(date).tz(tz) have different ways to store utc offset internally.
   * Using them successively like we are doing here can lead to wrong formating of dates.
   * This does not affect the timestamp itself, only the way local times are computed and displayed.
   * By creating a clone from a ISO Date string, we make sure we will format output in a coherent way.
   */
  const utcOrigin = originDateWithTz.toISOString();
  const originClone = getDateWithTz(utcOrigin, tz);

  // We apply a transform to the local input date
  const destinationDate = transform(originClone);

  // We apply the timezone on the destination date again but without converting to local time
  // this is useful when our transform "crosses" a DST change => our destination time is not offset due to DST
  return getDateWithTzAndSameTime(destinationDate, tz);
};

export const getPaymentDeadline = (
  billingDateWithTz: Dayjs,
  paymentTerm: number,
  deadlineType: DEADLINE_TYPE,
): Dayjs => {
  switch (deadlineType) {
    case DEADLINE_TYPE.END_OF_MONTH:
      return transformDateWithSameTime(billingDateWithTz, (origin) => origin.add(paymentTerm, 'day').endOf('month'));

    case DEADLINE_TYPE.AFTER_TERM:
    default:
      return transformDateWithSameTime(billingDateWithTz, (origin) => origin.add(paymentTerm, 'day').endOf('day'));
  }
};

export const datesDiff = (d1: ConfigType, d2: ConfigType, unit: OpUnitType = 'day'): number => {
  const date1 = dayjs(d1).startOf(unit);
  const date2 = dayjs(d2).startOf(unit);

  return date1.diff(date2, unit);
};

export const datesDiffWithTz = (d1: ConfigType, d2: ConfigType, tz: string, unit: OpUnitType = 'day'): number => {
  const date1 = buildDateWithTz(d1, tz).startOf(unit);
  const date2 = buildDateWithTz(d2, tz).startOf(unit);
  return date1.diff(date2, unit);
};

export function formatDateOrEmpty(date?: dayjs.ConfigType): string {
  return date ? dayjs(date).format(LONG_DATE_FORMAT_FR) : '-';
}

export function isWeekend(date: Date = new Date()): boolean {
  return date.getDay() === 6 || date.getDay() === 0;
}
