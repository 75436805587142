import type { IVatBases } from '@graneet/business-logic';
import { computeAmountOfVATBase } from '@graneet/business-logic';
import { formatVatRateToNumber, useCurrency } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { isNil } from 'lodash-es';

import type { OrderEditForm } from '../forms/order-edit-wizard';

export const useCurrentOrderVATDistribution = (): IVatBases => {
  const form = useFormContext<OrderEditForm>();
  const { mapAmountToNumber } = useCurrency();
  const { vatDistribution, vatRate, hasUnitPrices, amountExVAT, numberOfItems } = useOnChangeValues(form, [
    'vatDistribution',
    'vatRate',
    'hasUnitPrices',
    'amountExVAT',
    'numberOfItems',
  ]);

  if (hasUnitPrices && numberOfItems) {
    return vatDistribution || [];
  }

  return isNil(vatRate)
    ? []
    : [
        {
          vatRate: formatVatRateToNumber(vatRate),
          base: mapAmountToNumber(amountExVAT || 0),
          amount: computeAmountOfVATBase({
            vatRate: formatVatRateToNumber(vatRate),
            base: mapAmountToNumber(amountExVAT || 0),
          }),
        },
      ];
};
