import type { FC, ReactNode } from 'react';
import type { TextProps } from '@chakra-ui/react';
import { GridItem } from '@chakra-ui/react';

import { PriceAdvanced } from '../../PriceAdvanced';

export interface DifferenceSummaryItemProps extends TextProps {
  label: string;
  initialAmount: number;
  newAmount: number;
  rightContent?: ReactNode;
  light?: boolean;
  negative?: boolean;
  important?: boolean;
  sign?: boolean;
  type?: 'default' | 'reversalOfLiability';
  isRemoved?: boolean;
}

export const DifferenceSummaryItem: FC<DifferenceSummaryItemProps> = ({
  label,
  initialAmount,
  newAmount,
  rightContent,
  light,
  negative,
  important,
  isRemoved = false,
  type,
  sign = false,
  ...props
}) => {
  const styleProps = {
    ...props,
    color: light ? 'gray.600' : 'black',
  };

  const isReversalOfLiability = type === 'reversalOfLiability';
  const differenceAmount = isReversalOfLiability ? initialAmount - newAmount : newAmount - initialAmount;

  return (
    <>
      <GridItem
        flex={1}
        pl={2}
        textAlign="right"
        fontWeight={important ? 600 : 400}
        textDecoration={isRemoved ? 'line-through' : 'none'}
        textDecorationColor={isRemoved ? 'red.500' : 'none'}
        {...styleProps}
      >
        {label}
      </GridItem>

      <PriceAdvanced
        amount={initialAmount}
        sign={sign}
        negative={negative}
        pl={4}
        justifyContent="flex-end"
        alignItems="center"
        textAlign="right"
        textDecoration={isRemoved ? 'line-through' : 'none'}
        textDecorationColor={isRemoved ? 'red.500' : 'none'}
        {...styleProps}
      />

      {differenceAmount === 0 ? (
        <GridItem flex={1} pl={2} />
      ) : (
        <PriceAdvanced
          amount={differenceAmount}
          pl={4}
          fontSize={14}
          justifyContent="flex-end"
          alignItems="center"
          negative={negative}
          sign
          textAlign="right"
          isAmountColored
          {...styleProps}
        />
      )}

      <PriceAdvanced
        amount={newAmount}
        sign={sign}
        negative={negative}
        pl={4}
        justifyContent="flex-end"
        alignItems="center"
        textAlign="right"
        fontWeight={important ? 600 : 400}
        {...styleProps}
      />

      <GridItem pl={rightContent ? 2 : 0} {...styleProps}>
        {rightContent}
      </GridItem>
    </>
  );
};
