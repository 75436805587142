import type { FC } from 'react';
import { DeepTable, RichText } from '@graneet/lib-ui';
import { Box } from '@chakra-ui/react';

import { getProgressStatementDeepTableOffset } from '../../services/progress-statement-tree.util';

interface ProgressStatementCommentLotRowProps {
  children: string;

  depth: number;
}

export const ProgressStatementCommentLotRow: FC<ProgressStatementCommentLotRowProps> = ({ children, depth }) => (
  <DeepTable.Row offset={getProgressStatementDeepTableOffset(depth)} backgroundColor="white">
    <DeepTable.Cell gridColumn="span 7" whiteSpace="pre-line" wordBreak="break-word">
      <Box ml={4}>
        <RichText value={children} variant="light" />
      </Box>
    </DeepTable.Cell>
    <DeepTable.Cell variant="shadow" />
    <DeepTable.Cell />
  </DeepTable.Row>
);
