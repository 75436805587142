import type { FC, MouseEventHandler, ReactNode, RefObject } from 'react';
import { useCallback, useEffect } from 'react';
import { Box, Flex, Popover, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';

import type { RichTextBitwise } from '../../../constants/rich-text-configuration.constant';

import { Toolbar } from './Toolbar/Toolbar';

interface CustomRichTextInlineProps {
  isFocused: boolean;

  richTextBitwise: RichTextBitwise | undefined;

  inputRef: RefObject<{
    focus(): void;
  }>;

  toolbarContent?: ReactNode[];
}

export const CustomRichTextInlinePlugin: FC<CustomRichTextInlineProps> = ({
  isFocused,
  richTextBitwise,
  inputRef,
  toolbarContent,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  /*
   * Prevent on blur events on toolbar click to not trigger the on blur input event.
   */
  const preventDefaultOnMouseDown = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    if (!isFocused) {
      onClose();
    }
  }, [isFocused, onClose]);

  return (
    <Flex
      alignItems="flex-end"
      w="1.75rem"
      onMouseDown={preventDefaultOnMouseDown}
      pb="5px"
      className="toolbar-trigger"
    >
      <Popover
        closeOnBlur={false}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        gutter={3}
        placement="bottom-end"
        initialFocusRef={inputRef}
      >
        <PopoverTrigger>
          <Box
            userSelect="none"
            bottom="5px"
            right={0}
            zIndex={9}
            height="1.5rem"
            width="1.75rem"
            paddingX={1}
            paddingY={0}
            cursor="pointer"
            borderRadius={4}
            color={isOpen ? 'black' : 'greenBrand.light'}
            fontFamily="heading"
            fontWeight={600}
            boxShadow={isOpen ? 'md' : 'none'}
            visibility={isFocused ? 'visible' : 'hidden'}
          >
            Aa
          </Box>
        </PopoverTrigger>
        <PopoverContent w="auto" userSelect="none">
          <Toolbar richTextBitwise={richTextBitwise} toolbarContent={toolbarContent} />
        </PopoverContent>
      </Popover>
    </Flex>
  );
};
