import type { OptionProps } from 'chakra-react-select';
import { components } from 'chakra-react-select';
import { HStack, Text } from '@chakra-ui/react';

import type { EmailAutocompleteContactOption } from '../EmailAutocomplete.type';
import { Profile } from '../../Profile';
import { EllipsisText } from '../../EllipsisText';
import { Badge } from '../../Badge/Badge';
import { emailAutocompleteTranslations } from '../configureDefaultLabels';
import { SimpleCirclePlusIcon } from '../../Icons';
import { Tooltip } from '../../Tooltip/Tooltip';

export const OptionComponent = ({ innerRef, ...props }: OptionProps<EmailAutocompleteContactOption, true>) => (
  <components.Option {...props} innerRef={innerRef}>
    {/* eslint-disable-next-line no-underscore-dangle */}
    {props.data.__isCustom ? (
      <HStack>
        <SimpleCirclePlusIcon stroke="greenBrand.light" />
        <Text fontSize={14}>
          {emailAutocompleteTranslations.addOption} &quot;{props.data.label}&quot;
        </Text>
      </HStack>
    ) : (
      <Tooltip shouldWrapChildren label={props.isDisabled ? emailAutocompleteTranslations.missingEmail : undefined}>
        <Profile
          size="sm"
          name={props.data.label}
          mail={props.data.contact.email}
          nameComponent={
            <>
              <EllipsisText fontSize="sm">
                {props.data.label}
                {props.data.contact.role && (
                  <Badge ml={1} px={1} py={0.2} fontWeight="normal" colorScheme="white">
                    {props.data.contact.role}
                  </Badge>
                )}
              </EllipsisText>
            </>
          }
        />
      </Tooltip>
    )}
  </components.Option>
);
