import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IUpdateTotalMarginDTO } from '@graneet/business-logic';

import { updateJobComponentMargin } from '../services/quote-job.api';

import { useComponentContext } from 'features/quote-component/contexts/component.context';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

export const useEditJobComponentMargin = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote', 'global']);
  const { handleComponentUpdate, startAnotherUpdate } = useComponentContext();

  return useCallback(
    async (jobId: number, componentId: number, dto: IUpdateTotalMarginDTO) => {
      startAnotherUpdate();

      if (!dto.totalMargin) {
        toast.error(t('quote:jobEdition.componentMarginNull'));
        return false;
      }

      if (dto.totalMargin < 0) {
        toast.error(t('quote:jobEdition.componentMarginNegative'));
        return false;
      }

      const [err, response] = await updateJobComponentMargin(jobId, componentId, dto);

      if (err) {
        toast.error(t('global:words.c.error'), t('quote:errors.errorComponent', { email: SUPPORT_EMAIL }));
        return false;
      }

      const { 0: job } = Object.values(response.jobs);
      const { lots, quote, components, jobs } = response;

      const quoteUpdateResponse = {
        job,
        jobs,
        lots,
        quote,
        components: Object.values(components),
      };

      handleComponentUpdate(quoteUpdateResponse);
      return true;
    },
    [toast, handleComponentUpdate, startAnotherUpdate, t],
  );
};
