import { useMemo } from 'react';
import dayjs from 'dayjs';

import { capitalizeString } from '../../utils';

export const useDayPickerLocale = () =>
  useMemo(
    () => ({
      months: dayjs.months().map(capitalizeString),
      weekdaysLong: dayjs.weekdays().map(capitalizeString),
      weekdaysMin: dayjs.weekdaysMin().map(capitalizeString),
    }),
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dayjs.locale(),
    ],
  );
