import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, useDisclosure } from '@chakra-ui/react';
import type { CalloutColorScheme, PaginatedResponse, PaginationQuery } from '@graneet/lib-ui';
import { Button, Callout, CALLOUT_COLORS, SimpleAlertIcon, SimpleChevronBottomIcon } from '@graneet/lib-ui';
import type { ILibraryJobResponseDTO } from '@graneet/business-logic';

import { LibraryJobTable } from './LibraryJobTable';

interface LibraryJobImpactedLibraryComponentCalloutProps {
  libraryJobs: PaginationQuery<PaginatedResponse<ILibraryJobResponseDTO>>;

  colorScheme: CalloutColorScheme;

  children: ReactNode;
}

export const LibraryJobImpactedLibraryComponentCallout: FC<LibraryJobImpactedLibraryComponentCalloutProps> = ({
  libraryJobs,
  colorScheme,
  children,
}) => {
  const { t } = useTranslation(['library']);
  const { isOpen, onToggle } = useDisclosure();

  const color = CALLOUT_COLORS[colorScheme];

  return (
    <Callout colorScheme={colorScheme} icon={<SimpleAlertIcon boxSize={5} stroke={color.text} />}>
      {children}

      <Box w="100%">
        <Button
          rightIcon={isOpen ? <SimpleChevronBottomIcon transform="rotate(-180deg)" /> : <SimpleChevronBottomIcon />}
          variant="outline"
          onClick={onToggle}
          ml={-4}
          color={CALLOUT_COLORS[colorScheme].text}
          _hover={{ color: color.text, bg: color.hover }}
        >
          {isOpen
            ? t('library:impactedLibraryJobsCallout.hideTable')
            : t('library:impactedLibraryJobsCallout.displayTable')}
        </Button>

        <Collapse in={isOpen} animateOpacity>
          <Box mt={2} h="15rem">
            <LibraryJobTable gridId="library-job-callout" libraryJobs={libraryJobs} />
          </Box>
        </Collapse>
      </Box>
    </Callout>
  );
};
