import { useEffect, useState } from 'react';

import { useTreeContext } from '../contexts';
import type { Identifiable } from '../types/Identifiable';

export const useNodeComputedValue = <
  Node extends Identifiable,
  NodeComputedValue extends Record<keyof NodeComputedValue, unknown>,
>(
  nodeId: Node['id'],
): NodeComputedValue | undefined => {
  const { listenToNodeComputedValue, getComputedValues } = useTreeContext<Node, any, NodeComputedValue>();
  const [nodeComputedValue, setNodeComputedValue] = useState<NodeComputedValue>(
    () => getComputedValues().nodes[nodeId],
  );

  useEffect(() => listenToNodeComputedValue(nodeId, setNodeComputedValue), [listenToNodeComputedValue, nodeId]);

  return nodeComputedValue;
};
