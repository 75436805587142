import axios from 'axios';
import 'config/axios';
import type { IGeneratePdfModelsDTO, IPdf } from '@graneet/business-logic';
import { useMutation } from '@tanstack/react-query';

import { apiNew } from 'features/api/services/apiNew.service';

const PDFS_PATH = `${axios.defaults.baseURL}/pdfs`;

export const getDownloadUrl = (apiId: string | undefined | null, fileName: string): string | undefined =>
  apiId ? `${PDFS_PATH}/${apiId}/download?fileName=${encodeURIComponent(fileName)}` : undefined;

export const getPreviewUrl = (apiId?: string | null): string | undefined =>
  apiId ? `${PDFS_PATH}/${apiId}/view` : undefined;

export const getStreamUrl = (apiId?: string | null): string | undefined =>
  apiId ? `${PDFS_PATH}/${apiId}/stream` : undefined;

export const subscribeToUpdate = (uuid: string) => new EventSource(`${PDFS_PATH}/${uuid}/subscribe/status`);

export function useGeneratePdfExample() {
  return useMutation({
    mutationFn: (data: IGeneratePdfModelsDTO) => apiNew.post<IGeneratePdfModelsDTO, IPdf>('/pdfs/generate-model', data),
  });
}
