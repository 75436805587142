import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  useDrawer,
  useDrawersStack,
  useToast,
  Wizard,
  Button,
  useChakraColors,
  SimpleDownloadIcon,
} from '@graneet/lib-ui';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import type { IProject, IPdf } from '@graneet/business-logic';
import { isQuoteEditable, PDF_STATUSES } from '@graneet/business-logic';

import { EditQuoteLotsStep } from './_steps/EditQuoteLotsStep';
import { EditQuoteClientStep } from './_steps/EditQuoteClientStep';
import { EditQuoteInformationStep } from './_steps/EditQuoteInformationsStep';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { useData } from 'features/api/hooks/useData';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { getQuoteById, generateDraftQuotePDF } from 'features/quote/services/quote.api';
import { QuoteUpdateSpinner } from 'features/quote/components/spinners/QuoteUpdateSpinner';
import { QuoteSalesViewSwitch } from 'features/quote/components/switches/QuoteSalesViewSwitch';
import { QUOTE_STEPS } from 'features/quote/constants/quotes.constant';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import { getPreviewUrl } from 'features/pdf/services/pdf.api';
import { Error } from 'features/common/components/Error';
import { formatQuoteWizardTitle } from 'features/quote/services/quote.formatting';
import { QuoteEditProvider } from 'features/quote/contexts/QuoteEditProvider.context';
import { QuoteDisplayProvider } from 'features/quote/contexts/QuoteDisplayProvider.context';

interface EditQuoteGeneratePreviewButtonProps {
  quoteId: number;
}

const EditQuoteGeneratePreviewButton: FC<EditQuoteGeneratePreviewButtonProps> = ({ quoteId }) => {
  const { t } = useTranslation(['quote', 'global']);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { red, gray } = useChakraColors({ red: 'red.500', gray: 'gray.500' });
  const [pdfIconColor, setPdfIconColor] = useState(gray);

  const handlePDFPreview = useCallback(
    (pdf: IPdf) => (pdfStatus: PDF_STATUSES) => {
      if (pdfStatus === PDF_STATUSES.GENERATED && pdf.apiId) {
        const previewUrl = getPreviewUrl(pdf.apiId);

        if (previewUrl) {
          // Browser preview - open in new tab
          window.open(previewUrl);
        }
      }
      setIsLoading(false);
    },
    [],
  );

  const generatePdfFromDraftQuote = useCallback(async () => {
    setIsLoading(true);

    const [err, pdf] = await generateDraftQuotePDF(quoteId);

    if (err) {
      setIsLoading(false);
      toast.error(t('quote:creationForm.generatePreview.error'));
      return;
    }

    // Listen to server side event and then view the generated PDF file
    subscribeToPDFUpdates(pdf, handlePDFPreview(pdf));
  }, [handlePDFPreview, quoteId, t, toast]);

  return (
    <Button
      variant="outline"
      colorScheme="gray"
      onMouseOver={() => setPdfIconColor(red)}
      isLoading={isLoading}
      onClick={generatePdfFromDraftQuote}
      leftIcon={<SimpleDownloadIcon stroke={pdfIconColor} />}
      loadingText={t('quote:creationForm.generatePreview.action')}
      onMouseOut={() => setPdfIconColor(gray)}
    >
      {t('quote:creationForm.generatePreview.title')}
    </Button>
  );
};

export const EditQuoteScreen = () => {
  useHideNavbar();
  const history = useHistory();
  const { t } = useTranslation(['global', 'quote']);
  const toast = useToast();
  const { state } = useLocation<{ creation: boolean; project: IProject }>();
  const { quoteId } = useParams<{ quoteId: string }>();
  const quoteDataGetter = useDataGetter(getQuoteById, +quoteId);
  const quoteData = useData(quoteDataGetter);
  const wizardLabels = useWizardLabels({
    save: t('global:words.c.validate'),
  });

  const quoteLotEditDefaultVatRateModal = useDisclosure();

  const drawersStackLibraryJobImport = useDrawersStack<'drawer-quote-lot'>();
  const drawerLibraryJobImport = useDrawer('drawer-quote-lot', drawersStackLibraryJobImport);
  const libraryJobImportDrawer = useMemo(
    () => ({
      drawersStack: drawersStackLibraryJobImport,
      drawer: drawerLibraryJobImport,
    }),
    [drawerLibraryJobImport, drawersStackLibraryJobImport],
  );

  const drawersStackQuoteLot = useDrawersStack<'drawer-quote-lot'>();
  const drawerQuoteLot = useDrawer('drawer-quote-lot', drawersStackQuoteLot);
  const quoteLotDrawer = useMemo(
    () => ({
      drawersStack: drawersStackQuoteLot,
      drawer: drawerQuoteLot,
    }),
    [drawerQuoteLot, drawersStackQuoteLot],
  );

  const drawersStackBatiprix = useDrawersStack();
  const drawerBatiprix = useDrawer('drawer-batiprix', drawersStackBatiprix);
  const batiprixDrawer = useMemo(
    () => ({
      drawersStack: drawersStackBatiprix,
      drawer: drawerBatiprix,
    }),
    [drawerBatiprix, drawersStackBatiprix],
  );

  const { data: quote, fetch } = quoteData;
  const isCreation = !!state?.creation;
  const projectId = state?.project?.id;
  const projectIdRef = useRef<number | string | null>(projectId);

  const onQuit = useCallback(() => {
    if (projectId && projectId === projectIdRef.current) {
      history.push(`/projects/${projectId}/contracts`);
      return;
    }

    history.push(`/opportunities/quotes/${quoteId}`);
  }, [history, projectId, quoteId]);

  const handleProjectAssociationChange = (newProjectId: string | null) => {
    projectIdRef.current = newProjectId;
  };

  // -- Handle invalid route param `quoteId`

  const quoteIdAsNumber = parseInt(quoteId, 10);
  const isQuoteIdInvalid = Number.isNaN(quoteIdAsNumber);
  useEffect(() => {
    if (isQuoteIdInvalid) {
      toast.error(t('quote:errors.quoteNotFound'));
      history.replace('/opportunities/quotes');
    }
  }, [isQuoteIdInvalid, history, t, toast]);

  if (isQuoteIdInvalid) {
    return null;
  }
  if (!quote) return null;

  if (quote.isArchived) {
    return <Error />;
  }

  const headerTitle = t(isCreation ? 'quote:creationForm.creationTitleWithLabel' : 'quote:creationForm.editionTitle', {
    label: formatQuoteWizardTitle(quote),
  });

  /* We want to display the first step only for DRAFT quotes
   * we need to register the step while fetching the quote information on the initial loading (when quote is undefined)
   * to prevent invalid step order
   */
  const isLotStepVisible = !quote || (quote && isQuoteEditable(quote));

  return (
    <QuoteEditProvider quoteId={quoteIdAsNumber}>
      <QuoteDisplayProvider
        onOpenLotEditDrawer={drawerQuoteLot.onOpen}
        onOpenLotEditDefaultVatRateModal={quoteLotEditDefaultVatRateModal.onOpen}
        onOpenBatiprixImportDrawer={drawerBatiprix.onOpen}
        onOpenLibraryJobImportDrawer={drawerLibraryJobImport.onOpen}
      >
        <Wizard headerTitle={headerTitle} labels={wizardLabels} onQuit={onQuit} onFinish={onQuit}>
          {isLotStepVisible && (
            <>
              <Wizard.Step name={QUOTE_STEPS.LOTS}>
                <QueryWrapper>
                  <Wizard.Placeholder placement={Wizard.PLACEMENT.HEADER_RIGHT}>
                    <QuoteSalesViewSwitch />
                  </Wizard.Placeholder>

                  <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_RIGHT}>
                    <EditQuoteGeneratePreviewButton quoteId={quoteIdAsNumber} />
                  </Wizard.Placeholder>

                  <Wizard.Placeholder placement={Wizard.PLACEMENT.HEADER_LEFT}>
                    <QuoteUpdateSpinner />
                  </Wizard.Placeholder>

                  <EditQuoteLotsStep
                    quoteLotDrawer={quoteLotDrawer}
                    batiprixDrawer={batiprixDrawer}
                    libraryJobImportDrawer={libraryJobImportDrawer}
                    quoteLotEditDefaultVatRateModal={quoteLotEditDefaultVatRateModal}
                  />
                </QueryWrapper>
              </Wizard.Step>
            </>
          )}

          <Wizard.Step name={QUOTE_STEPS.CLIENT}>
            <QueryWrapper>
              <EditQuoteClientStep quote={quote} reloadQuote={fetch} />
            </QueryWrapper>
          </Wizard.Step>

          <Wizard.Step name={QUOTE_STEPS.INFORMATION}>
            <QueryWrapper>
              <EditQuoteInformationStep
                quote={quote}
                reloadQuote={fetch}
                onProjectAssociationChange={handleProjectAssociationChange}
              />
            </QueryWrapper>
          </Wizard.Step>
        </Wizard>
      </QuoteDisplayProvider>
    </QuoteEditProvider>
  );
};
