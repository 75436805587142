import type { FC } from 'react';
import { Button, Card, SimpleEditIcon, Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Text, useDisclosure } from '@chakra-ui/react';

import { useComponentTypes } from '../../services/component-type.api';
import { ComponentTypeBadge } from '../ComponentTypeBadge';

import { ComponentTypeEditModal } from './ComponentTypeEditModal';

export const ComponentTypeEditCard: FC = () => {
  const { t } = useTranslation(['componentType', 'global']);

  const componentTypes = useComponentTypes();

  const modal = useDisclosure();

  return (
    <Card
      title={t('componentType:editCard.title')}
      topRightContent={
        <Button variant="outline" leftIcon={<SimpleEditIcon />} onClick={modal.onOpen}>
          {t('global:words.c.update')}
        </Button>
      }
    >
      <Stack>
        <Text>{t('componentType:editCard.description')}</Text>

        <Table templateColumns={['auto', '5rem']} noCard>
          <Table.Header>
            <Table.Cell>{t('componentType:fields.name')}</Table.Cell>

            <Table.Cell center>{t('componentType:fields.unit')}</Table.Cell>
          </Table.Header>

          {componentTypes.data.map((componentType, index) => (
            <Table.Row key={componentType.id} border={componentTypes.data.length - 1 === index ? 0 : undefined}>
              <Table.Cell>
                <Box w="7rem">
                  <ComponentTypeBadge type={componentType} />
                </Box>
              </Table.Cell>

              <Table.Cell center>{componentType.unit ?? '-'}</Table.Cell>
            </Table.Row>
          ))}
        </Table>

        <ComponentTypeEditModal isOpen={modal.isOpen} onClose={modal.onClose} />
      </Stack>
    </Card>
  );
};
