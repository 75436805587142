import type { MutableRefObject, ReactElement } from 'react';
import { useRef } from 'react';
import type { DropTargetMonitor } from 'react-dnd';
import { useDrop } from 'react-dnd';
import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

import { Cursor } from './Cursor';
import { useDropStyles } from './utils';
import { DropEffect } from './constants';

export type PartialBoxProps = Omit<BoxProps, 'id' | 'children' | 'onDrop'>;
export interface DropZoneProps<Types extends string, Item extends Record<any, unknown>> extends PartialBoxProps {
  id: string | number;

  effect?: DropEffect;

  /**
   * A string or an array of strings representing the types of items
   * user can drop in this drop zone
   */
  accept: Types | Types[];

  /**
   * If `true`, no hover or drop on this drop zone will happen.
   * It will not prevent bubbling up on parent drop zones.
   */
  disabled?: boolean;

  /**
   * This prop is ONLY useful for nested drop zones.
   *
   * When `false`, only the drop zone being currently hovered
   * will trigger the `onHover` delegate and the visual effect.
   * When `true`, all the parent drop zone will trigger the
   * `onHover` delegate and their own visual effects.
   */
  deep?: boolean;

  /**
   * This prop is ONLY useful for nested drop zones.
   *
   * When `false`, the `onDrop` delegate will only be called
   * on the drop zone on which a draggable item was dropped.
   *
   * If `true`, the `onDrop` delegates of all parent drop zones
   * will be called as well.
   */
  bubbleUpDrop?: boolean;

  /**
   * When `false` any draggable with the same `id` props as this
   * drop zone will trigger no visual effect, nor call to the `onDrop`
   * delegate during the hovering phase, even if the `canDrop` delegate
   * returned `true`.
   */
  allowDropOnItself?: boolean;

  /**
   * This method returns a boolean dictating whether drop effect and delegate should
   * be active, based on the business logic.
   */
  canDrop?(item: Item, targetMonitor: DropTargetMonitor<Item>): boolean;

  /**
   * Delegate method called when a draggable item is hovering this drop zone, and if:
   * - The dropped item has a type which is accepted
   * - Its `disabled` prop is `false`
   * - Its `canDrop` delegate returned `true`
   * - Its `bubbleUpDrop` is `true` and an accepted item was dropped in a child drop zone.
   *
   * Called with the dropped item and the dnd monitor object
   */
  onHover?(item: Item, targetMonitor: DropTargetMonitor<Item>, dropRef: MutableRefObject<HTMLDivElement | null>): void;

  /**
   * Delegate method called when a draggable item was dropped on this drop zone, and if:
   * - The dropped item has a type which is accepted
   * - Its `canDrop` delegate returned `true`
   * - Its `disabled` prop is `false`
   * - If `allowDropOnItself` is `true` and the user is hovering this drop zone with
   *   a draggable item whose id matches that of this drop zone
   * - Its `bubbleUpDrop` is `true` while an item was dropped in a child drop zone.
   *
   * Called with the dropped item and the dnd monitor object
   */
  onDrop?(item: Item, targetMonitor: DropTargetMonitor<Item>): void;

  children: ReactElement | ((args: { isOver: boolean }) => ReactElement);
}

export const DropZone = <Types extends string, Item extends Record<any, unknown>>({
  id,
  effect = DropEffect.Glow,
  accept,
  disabled = false,
  deep = false,
  bubbleUpDrop = false,
  allowDropOnItself = false,
  canDrop = () => true,
  onHover,
  onDrop,
  children,
  ...otherProps
}: DropZoneProps<Types, Item>) => {
  const dropRef = useRef<HTMLDivElement>(null);

  const STYLES = useDropStyles();

  const [{ isOver }, drop] = useDrop<Item, unknown, { isOver: boolean }>(
    () => ({
      accept,

      canDrop(item, monitor) {
        return !disabled && canDrop(item, monitor);
      },

      hover(item, monitor) {
        if (disabled) {
          return;
        }
        if (!dropRef.current) {
          return;
        }
        if (!allowDropOnItself && id === item.id) {
          return;
        }
        if (onHover) {
          onHover(item, monitor, dropRef);
        }
      },

      drop: (item, monitor) => {
        if (disabled) {
          return;
        }
        if (!dropRef.current) {
          return;
        }
        if (!allowDropOnItself && id === item.id) {
          return;
        }
        if (!bubbleUpDrop && monitor.didDrop()) {
          return;
        }
        if (onDrop) {
          onDrop(item, monitor);
        }
      },

      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: !deep }),
      }),
    }),
    [id, accept, deep, canDrop, onHover, onDrop, bubbleUpDrop, allowDropOnItself],
  );

  drop(dropRef);

  const bareChildren = typeof children === 'function' ? children({ isOver }) : children;

  if (disabled) {
    return bareChildren;
  }

  return (
    <Box ref={dropRef} {...(isOver ? STYLES.BOX[effect] : undefined)} {...otherProps}>
      {isOver && effect === DropEffect.CursorTop && <Cursor />}
      {bareChildren}
      {isOver && effect === DropEffect.CursorBottom && <Cursor />}
    </Box>
  );
};
