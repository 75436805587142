import type { ChangeEvent, FC } from 'react';
import { useCallback } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { PERMISSION, SUB_PROJECT_BILLING_TYPE } from '@graneet/business-logic';
import { Card, CheckboxField, IconAdvanced, SimpleHelpIcon, Tooltip } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';

import type { OrderEditForm } from '../../forms/order-edit-wizard';
import { useOrderDirectPaymentInfosContext } from '../../contexts/OrderDirectPaymentInfosContext';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { SupplierField } from 'features/supplier/components/SupplierField';

interface OrderSupplierCardProps {
  selectedProjectHasNoSubProject: boolean;

  billingType?: SUB_PROJECT_BILLING_TYPE | null;

  onDirectPaymentSelection(isChecked: boolean): void;

  loading?: boolean;
}

export const OrderSupplierCard: FC<OrderSupplierCardProps> = ({
  selectedProjectHasNoSubProject,
  billingType,
  onDirectPaymentSelection,
  loading,
}) => {
  const { t } = useTranslation(['orders']);

  const form = useFormContext<OrderEditForm>();
  const {
    associatedSupplierInvoices,
    isDirectPayment: isDirectPaymentLive,
    amountExVAT,
  } = useOnChangeValues(form, ['associatedSupplierInvoices', 'isDirectPayment', 'amountExVAT']);

  const hasSelectSupplierPermission = usePermissions([PERMISSION.CREATE_ORDER]);
  const { areVatRatesEditable } = useOrderDirectPaymentInfosContext();
  const isSupplierEditable = hasSelectSupplierPermission && areVatRatesEditable;

  /**
   * Associating supplier invoice will block the changes on the checkbox:
   * - checkbox = true: direct supplier invoice
   * - checkbox = false: non-direct supplier invoice
   */
  const hasAssociatedSupplierInvoices = associatedSupplierInvoices && associatedSupplierInvoices?.length > 0;
  const hasAssociatedSupplierInvoicesOnDirectPayment = isDirectPaymentLive && hasAssociatedSupplierInvoices;
  const hasAssociatedSupplierInvoicesNotOnDirectPayment = !isDirectPaymentLive && hasAssociatedSupplierInvoices;
  const hasNullableAmountExVATOrVatRate = isNil(amountExVAT) && !isDirectPaymentLive;
  const hasNegativeAmountExVAT = (amountExVAT || 0) < 0;

  const onDirectPaymentChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      onDirectPaymentSelection(isChecked);
    },
    [onDirectPaymentSelection],
  );

  let tooltipError: string | undefined;
  if (hasAssociatedSupplierInvoicesOnDirectPayment) {
    tooltipError = t('orders:supplierCard.hasSupplierInvoiceInDirectPayment');
  } else if (hasNullableAmountExVATOrVatRate) {
    tooltipError = t('orders:supplierCard.hasNullAmountExVATOrVAT');
  } else if (hasNegativeAmountExVAT) {
    tooltipError = t('orders:supplierCard.hasNegativeAmount');
  } else if (hasAssociatedSupplierInvoicesNotOnDirectPayment) {
    tooltipError = t('orders:supplierCard.hasSupplierInvoice');
  } else if (selectedProjectHasNoSubProject) {
    tooltipError = t('orders:supplierCard.selectedProjectHasNoSubProject');
  } else if (billingType === SUB_PROJECT_BILLING_TYPE.DIRECT) {
    tooltipError = t('orders:supplierCard.hasProjectInDirectPayment');
  }

  const disableDirectPaymentCheckBox = !!tooltipError || loading;

  return (
    <Card title={t('orders:supplierCard.title')} isRequired>
      <VStack align="stretch" spacing={6}>
        <SupplierField<OrderEditForm> name="supplierId" isEditable={isSupplierEditable} />

        <HStack>
          <Tooltip shouldWrapChildren label={tooltipError} isDisabled={!disableDirectPaymentCheckBox}>
            <CheckboxField<OrderEditForm>
              name="isDirectPayment"
              label={t('orders:supplierCard.isDirectPayment')}
              onChange={onDirectPaymentChange}
              isDisabled={disableDirectPaymentCheckBox}
            />
          </Tooltip>
          <IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('orders:supplierCard.directPaymentTooltip')} />
        </HStack>
      </VStack>
    </Card>
  );
};
