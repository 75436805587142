import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AmountSummary,
  Date,
  formatNumberToVatRate,
  formatPercentage,
  PriceAdvanced,
  Section,
  Table,
} from '@graneet/lib-ui';
import { VAT_TYPE, type IOrder, type SubProjectWithOrders } from '@graneet/business-logic';

import { sortDirectPayments } from 'features/direct-payment/services/direct-payments.util';

interface FinalStatementDirectPaymentTableProps {
  subProject: SubProjectWithOrders;

  order: IOrder;
}

export const FinalStatementDirectPaymentTable: FC<FinalStatementDirectPaymentTableProps> = ({ subProject, order }) => {
  const { t } = useTranslation(['project', 'global']);

  const supplierInvoices = sortDirectPayments(
    order.ordersSupplierInvoices?.map(({ supplierInvoice }) => supplierInvoice) || [],
  );

  return (
    <Section
      title={t('project:finalStatement.directPayment.sectionTitle', {
        subProjectName: subProject.name,
        orderName: order.name,
      })}
    >
      <Table templateColumns={['minmax(auto, 10rem)', 'minmax(20rem, 1fr)', '15rem', '15rem', '15rem']} bg="white">
        <Table.Header>
          <Table.Cell>{t('project:finalStatement.directPayment.fields.progressStatementNumber')}</Table.Cell>
          <Table.Cell>{t('project:finalStatement.directPayment.fields.progressStatementName')}</Table.Cell>
          <Table.Cell right>{t('project:finalStatement.directPayment.fields.progressStatementBillingDate')}</Table.Cell>
          <Table.Cell right>{t('project:finalStatement.directPayment.fields.supplierInvoiceNumber')}</Table.Cell>
          <Table.Cell center>{t('project:finalStatement.directPayment.fields.supplierInvoiceAmountExVAT')}</Table.Cell>
        </Table.Header>

        {supplierInvoices.map((supplierInvoice) => (
          <Table.Row key={supplierInvoice.id}>
            <Table.Cell variant="ellipsis">{supplierInvoice.progressStatement?.invoiceNumber}</Table.Cell>
            <Table.Cell variant="ellipsis">{supplierInvoice.progressStatement?.name}</Table.Cell>
            <Table.Cell right>
              <Date>{supplierInvoice.progressStatement?.billingDate}</Date>
            </Table.Cell>
            <Table.Cell right>{supplierInvoice.invoiceNumber}</Table.Cell>
            <Table.Cell center>
              <PriceAdvanced amount={supplierInvoice.amountExVAT} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>

      <AmountSummary>
        <AmountSummary.Item
          important
          label={t('project:finalStatement.directPayment.amount.namedAmountExVAT', { name: order.name })}
          amount={order.amountExVAT}
        />
        {order.vatType === VAT_TYPE.REVERSE_CHARGE && (
          <AmountSummary.Item
            light
            sign
            amount={0}
            label={t('project:finalStatement.directPayment.amount.hasReversalOfLiability')}
          />
        )}
        {order.vatType === VAT_TYPE.INTRA_COMMUNITY && (
          <AmountSummary.Item
            light
            sign
            amount={0}
            label={t('project:finalStatement.directPayment.amount.hasIntraCommunity')}
          />
        )}
        {order.vatType === VAT_TYPE.NORMAL &&
          order.vatDistribution.map((vatObject) => (
            <AmountSummary.Item
              light
              sign
              amount={vatObject.amount}
              label={t('global:VAT', { vatRate: formatPercentage(formatNumberToVatRate(vatObject.vatRate)) })}
            />
          ))}
        <AmountSummary.Item
          important
          label={t('project:finalStatement.directPayment.amount.namedAmountIncVAT', { name: order.name })}
          amount={order.amountIncVAT}
        />
      </AmountSummary>
    </Section>
  );
};
