import { ActionMenu, GotoLink, PriceAdvanced, Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IOrderResponseDTO, ISupplierInvoice } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import type { FC } from 'react';
import { useMemo } from 'react';
import { isString } from 'lodash-es';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { processSupplierInvoiceAmount } from 'features/order/services/order.util';
import { SupplierInvoiceStatusBadge } from 'features/supplier-invoice/components/badges/SupplierInvoicesStatusBadge';

interface SupplierInvoiceListProps {
  supplierInvoices?: ISupplierInvoice[];
  onDeleteSupplierInvoice(supplierInvoice: ISupplierInvoice): void;
  order: IOrderResponseDTO | null;
}

export const SupplierInvoiceList: FC<SupplierInvoiceListProps> = ({
  supplierInvoices = [],
  onDeleteSupplierInvoice,
  order,
}) => {
  const { t } = useTranslation(['orders', 'global']);

  const hasCreateOrderPermission = usePermissions([PERMISSION.CREATE_ORDER]);
  const canEditOrCreate = hasCreateOrderPermission && !order?.isDirectPayment;

  const templateColumns = useMemo(
    () => ['auto', 'auto', 'auto', 'auto', canEditOrCreate ? 'auto' : null].filter(isString),
    [canEditOrCreate],
  );

  if (supplierInvoices.length === 0) {
    return null;
  }

  return (
    <Table noShadow templateColumns={templateColumns}>
      <Table.Header>
        <Table.Cell>{t('orders:associate.status')}</Table.Cell>
        <Table.Cell>{t('orders:associate.name')}</Table.Cell>
        <Table.Cell>{t('orders:associate.number')}</Table.Cell>
        <Table.Cell right>{t('orders:associate.amountExVAT')}</Table.Cell>
        {canEditOrCreate && <Table.Cell />}
      </Table.Header>

      {supplierInvoices.map((supplierInvoice) => (
        <Table.Row key={supplierInvoice.id}>
          <Table.Cell>
            <SupplierInvoiceStatusBadge status={supplierInvoice.status} />
          </Table.Cell>
          <Table.Cell>
            <GotoLink
              fontSize="sm"
              to={`/purchases/supplier-invoices/${supplierInvoice.id}`}
              label={supplierInvoice.name}
            />
          </Table.Cell>
          <Table.Cell>{supplierInvoice.invoiceNumber}</Table.Cell>
          <Table.Cell right>
            <PriceAdvanced amount={processSupplierInvoiceAmount(supplierInvoice, order?.project?.id)} />
          </Table.Cell>
          {canEditOrCreate && (
            <Table.Cell right>
              <ActionMenu>
                <ActionMenu.Delete
                  label={t('global:deleteAssociation')}
                  onClick={() => onDeleteSupplierInvoice(supplierInvoice)}
                />
              </ActionMenu>
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table>
  );
};
