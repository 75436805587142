import type { FC } from 'react';

import { EllipsisText } from '../../../EllipsisText';

import { RawCell } from './RawCell';
import type { EllipsisCellProps } from './Cell.type';

export const EllipsisCell: FC<EllipsisCellProps> = ({
  children,
  right = false,
  center = false,
  textProps,
  ...props
}) => {
  const wrapperStyles = {
    justifyContent: (right && 'flex-end') || (center && 'center') || undefined,
  };

  return (
    <RawCell {...props} {...wrapperStyles}>
      <EllipsisText
        tooltipProps={{
          placement: 'right',
          openDelay: 300,
        }}
        fontSize="0.875rem"
        // we add vertical padding around the text as a trick so that the tooltip displays
        // even if the mouse is not exactly hovering the text content
        {...textProps}
      >
        {children}
      </EllipsisText>
    </RawCell>
  );
};
