import type { FC } from 'react';
import { useState, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  PaginatedRenderActionsProps,
  TableSelectBannerChildrenProps,
  PaginatedTableSelectBannerChildrenProps,
} from '@graneet/lib-ui';
import { Button } from '@graneet/lib-ui';
import qs from 'qs';
import type { ISupplierInvoiceListingResponseDTO } from '@graneet/business-logic';
import { EXPORT_ENTITY, FEATURE_FLAGS, FILTERING_PARAMS, SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';

import { SupplierInvoiceBatchImportButton } from 'features/supplier-invoice/components/SupplierInvoiceBatchImportButton/SupplierInvoiceBatchImportButton';
import { SupplierInvoicesCreateButton } from 'features/supplier-invoice/components/SupplierInvoicesCreateButton';
import {
  useGetValidationStepsSupplierInvoice,
  useUpdateValidationStepSupplierInvoiceBatchHistory,
} from 'features/validation-step/services/validation-step-supplier-invoice.api';
import { useAppContext } from 'features/app/contexts/AppContext';
import type { SelectedItems } from 'features/export/components/ExportButton/ExportButton';
import { ExportButton } from 'features/export/components/ExportButton/ExportButton';
import { SupplierInvoiceBatchStatusActions } from 'features/supplier-invoice/components/SupplierInvoiceBatchStatusActions/SupplierInvoiceBatchStatusActions';
import { SupplierInvoiceBatchUpdate } from 'features/supplier-invoice/components/SupplierInvoiceListing/SupplierInvoiceBatchUpdate';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useSupplierInvoices } from 'features/supplier-invoice/services/supplier-invoice.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { SupplierInvoiceListing } from 'features/supplier-invoice/components/SupplierInvoiceListing/SupplierInvoiceListing';

export const ViewSupplierInvoicesScreen: FC = () => {
  const { t } = useTranslation(['supplierInvoices', 'global', 'validationStep']);
  const { updateHeaderTitle } = useHeaderContext();

  const supplierInvoices = useSupplierInvoices();

  const orderedValidationSteps = useGetValidationStepsSupplierInvoice();
  const updateValidationStepSupplierInvoiceBatchHistory = useUpdateValidationStepSupplierInvoiceBatchHistory();

  const { createRedirectionWithFilters } = useFiltersQuery();
  const onRowClicked = useCallback(
    (id: number) => {
      createRedirectionWithFilters(`/purchases/supplier-invoices/${id}`)();
    },
    [createRedirectionWithFilters],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.supplierInvoices'), []);
  }, [t, updateHeaderTitle]);

  const hasSupplierInvoiceImportImprovementFeatureFlag = useFeatureFlag(
    FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_IMPORT_IMPROVEMENT,
  );
  const hasWorkflowValidationFeatureFlag = useFeatureFlag(FEATURE_FLAGS.WORKFLOW_VALIDATION);

  const { currentUser } = useAppContext();

  const [isUpdateBatchMode, setIsUpdateBatchMode] = useState(false);

  const handleValidStep = useCallback(
    async ({
      currentFilters,
      selectedItems,
      hasAllCheckboxesSelected,
      resetSelectedCheckboxes,
    }: TableSelectBannerChildrenProps<SelectedItems['SUPPLIER_INVOICE']>) => {
      await updateValidationStepSupplierInvoiceBatchHistory.mutateAsync({
        filters: qs.parse(currentFilters.toString()),
        selectedItems: selectedItems.map((item) => ({ id: item.id })),
        hasAllSelected: hasAllCheckboxesSelected,
        search: currentFilters.get(FILTERING_PARAMS.SEARCH) || undefined,
      });

      resetSelectedCheckboxes?.();
      supplierInvoices.refetch();
    },
    [supplierInvoices, updateValidationStepSupplierInvoiceBatchHistory],
  );

  const renderActions = useCallback(
    (props: PaginatedRenderActionsProps<ISupplierInvoiceListingResponseDTO>) => {
      const hasHiddenItems = props.hasAllCheckboxesSelected;

      const displayApproveButton =
        !hasHiddenItems &&
        props.selectedItems.every((item) => {
          if (
            item.status === SUPPLIER_INVOICE_STATUS.TO_VALIDATE &&
            item.currentValidationStep?.id &&
            orderedValidationSteps.data.length
          ) {
            const currentValidationStepIndex = orderedValidationSteps.data.findIndex(
              ({ id }) => item.currentValidationStep?.id === id,
            );
            const subsequentValidationSteps = orderedValidationSteps.data.slice(currentValidationStepIndex);

            const isValidator = subsequentValidationSteps.some((step) =>
              step.validators.some(({ validator }) => validator.id === currentUser?.id),
            );

            const projectAccountManagerIds =
              item.supplierInvoiceProjects?.flatMap(
                ({ project }) => project?.projectAccountManagers?.map(({ userId }) => userId) ?? [],
              ) ?? [];

            const isAccountManager = subsequentValidationSteps.some(
              ({ areAccountManagersIncluded }) =>
                areAccountManagersIncluded && projectAccountManagerIds.includes(currentUser?.id),
            );

            return isValidator || isAccountManager;
          }
          return false;
        });

      const allItemsHaveMissingInformation =
        !hasHiddenItems && props.selectedItems.every((item) => item.hasMissingInformation);

      // Close update batch mode if batch mode is active and the user selects an item that does not have missing information
      setIsUpdateBatchMode(isUpdateBatchMode && allItemsHaveMissingInformation);

      return !isUpdateBatchMode ? (
        <>
          {displayApproveButton && (
            <Button
              onClick={() => handleValidStep(props)}
              variant="outline"
              height="1.75rem"
              isLoading={updateValidationStepSupplierInvoiceBatchHistory.isPending}
            >
              {t('global:words.c.approve')}
            </Button>
          )}
          {/* @[ff: ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_IMPORT_IMPROVEMENT] */}
          {hasSupplierInvoiceImportImprovementFeatureFlag && (
            <Button
              onClick={() => setIsUpdateBatchMode(true)}
              variant="outline"
              height="1.75rem"
              tooltip={
                !allItemsHaveMissingInformation ? t('supplierInvoices:banner.tooltipMessages.cannotBulkCategorize') : ''
              }
              tooltipProps={{
                placement: 'bottom-start',
                textAlign: 'left',
              }}
              isDisabled={!allItemsHaveMissingInformation}
            >
              {t('global:words.c.update')}
            </Button>
          )}
          <SupplierInvoiceBatchStatusActions {...props} onStatusesChanged={supplierInvoices.refetch} />
          <ExportButton entity={EXPORT_ENTITY.SUPPLIER_INVOICE} {...props} />
        </>
      ) : null;
    },
    [
      isUpdateBatchMode,
      updateValidationStepSupplierInvoiceBatchHistory.isPending,
      t,
      hasSupplierInvoiceImportImprovementFeatureFlag,
      supplierInvoices,
      orderedValidationSteps.data,
      currentUser?.id,
      handleValidStep,
    ],
  );

  const SelectTableChildren = useCallback(
    (props: PaginatedTableSelectBannerChildrenProps<ISupplierInvoiceListingResponseDTO>) =>
      isUpdateBatchMode && (
        <SupplierInvoiceBatchUpdate
          {...props}
          onSupplierInvoicesUpdate={async () => {
            setIsUpdateBatchMode(false);
            supplierInvoices.refetch();
          }}
          setIsUpdateBatchMode={setIsUpdateBatchMode}
        />
      ),
    [isUpdateBatchMode, supplierInvoices],
  );

  const allSelectedLabel = useCallback(
    (totalNumberOfItems?: number): string =>
      t('supplierInvoices:banner.allSelectedLabel', { count: totalNumberOfItems }),
    [t],
  );

  const selectLabel = useCallback(
    (numberSelectedItems: number) => {
      if (numberSelectedItems === 0) {
        return '';
      }
      return t('supplierInvoices:banner.selection', { count: numberSelectedItems });
    },
    [t],
  );

  return (
    <SupplierInvoiceListing
      gridId="supplier-invoice"
      pagination={supplierInvoices}
      actions={
        <>
          <SupplierInvoiceBatchImportButton onImported={supplierInvoices.refetch} />
          <SupplierInvoicesCreateButton />
        </>
      }
      tableProps={{
        countLabel: (count) => t('supplierInvoices:supplierInvoice', { count }),
        sums: {
          amountExVAT: {
            label: t('supplierInvoices:fields.amountExVAT'),
            type: 'currency',
          },
        },
        onRowClicked: (event) => {
          if (event.data && event.api.getFocusedCell()?.column.getId() !== 'approve') {
            onRowClicked(event.data.id);
          }
        },
        rowSelection: 'multiple',
        selectionComponentProps: {
          allSelectedLabel,
          selectLabel,
          renderActions,
          children: SelectTableChildren,
        },
      }}
      customLayout={{
        invoiceNumber: {
          hide: false,
        },
        type: {
          hide: false,
        },
        name: {
          hide: false,
        },
        billingDate: {
          hide: false,
        },
        supplier: {
          hide: false,
        },
        supplierInvoiceProjects: {
          hide: false,
        },
        amountExVAT: {
          hide: false,
        },
        status: { hide: false },
        approve: {
          hide: !hasWorkflowValidationFeatureFlag,
        },
        hasMissingInformation: {
          hide: false,
        },
      }}
    />
  );
};
