import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

export const TeamBuildingIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 243 184" {...props}>
    <svg width="243" height="184" viewBox="0 0 243 184" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.45"
        d="M121.5 151.857C176.239 151.857 220.614 148.732 220.614 144.877C220.614 141.022 176.239 137.896 121.5 137.896C66.7614 137.896 22.3867 141.022 22.3867 144.877C22.3867 148.732 66.7614 151.857 121.5 151.857Z"
        fill="#E6E6E6"
      />
      <path
        d="M186.673 42.1907L175.775 85.9307L159.706 81.9272C159.242 81.334 158.981 80.6061 158.965 79.8526C158.949 79.0991 159.177 78.3606 159.615 77.7476C160.389 77.3211 161.067 76.7403 161.607 76.0412C162.147 75.3422 162.538 74.5397 162.756 73.6835C163.129 71.9262 162.807 70.0926 161.856 68.5685C160.905 67.0444 159.399 65.9486 157.657 65.5118C155.914 65.075 154.07 65.3311 152.513 66.2264C150.955 67.1217 149.806 68.5862 149.306 70.3118C149.097 71.1705 149.065 72.063 149.214 72.9342C149.362 73.8055 149.687 74.6373 150.169 75.3784C150.271 76.1252 150.127 76.8849 149.758 77.5426C149.39 78.2003 148.817 78.7201 148.127 79.0234L132.065 75.0139L136.117 58.7693C135.782 58.142 135.263 57.6317 134.63 57.3061C133.998 56.9806 133.281 56.8553 132.575 56.9468C131.835 57.4308 131.004 57.7576 130.132 57.9071C129.261 58.0565 128.368 58.0253 127.509 57.8156C125.796 57.3072 124.345 56.1573 123.458 54.6057C122.572 53.054 122.319 51.2202 122.751 49.4862C123.183 47.7523 124.268 46.2521 125.779 45.2982C127.29 44.3442 129.111 44.0101 130.862 44.3655C131.719 44.5832 132.521 44.9742 133.22 45.5144C133.919 46.0547 134.5 46.7326 134.926 47.5063C135.506 47.9207 136.198 48.148 136.91 48.1578C137.623 48.1675 138.321 47.9593 138.912 47.561L142.964 31.3164L186.673 42.1907Z"
        fill="#CCCCCC"
      />
      <path
        d="M191.319 45.3679C191.319 45.3679 185.013 53.9458 191.41 56.552C197.807 59.1582 198.354 49.6569 198.354 49.6569L195.468 43.2842L191.319 45.3679Z"
        fill="#FFD200"
      />
      <path
        d="M193.532 51.5273L201.612 51.7886C201.612 51.7886 225.863 58.0337 212.274 86.1062C210.117 90.5531 210.299 95.7837 210.178 100.686L210.147 101.962H188.836L187.718 97.3754C186.44 92.2851 184.117 87.5165 180.896 83.3725C176.783 77.9657 173.867 67.2312 193.532 51.5273Z"
        fill="#109CF1"
      />
      <path
        d="M189.698 39.6019C189.768 41.3951 190.079 43.1706 190.622 44.8811C190.699 45.0801 190.816 45.2617 190.964 45.4155C191.112 45.5694 191.289 45.6925 191.485 45.7777C191.681 45.863 191.892 45.9087 192.105 45.9122C192.319 45.9158 192.531 45.8771 192.73 45.7984C193.384 45.5791 193.961 45.176 194.392 44.6373C194.823 44.0986 195.089 43.4472 195.16 42.7609L195.767 39.8449C195.827 39.2484 195.701 38.6478 195.407 38.1257C195.112 37.6036 194.663 37.1856 194.121 36.9289C192.165 35.8719 189.54 37.6397 189.698 39.6019Z"
        fill="#F4A28C"
      />
      <path d="M195.671 40.3916L198.611 50.549L192.9 51.7701L193.052 43.8847L195.671 40.3916Z" fill="#F4A28C" />
      <path
        opacity="0.31"
        d="M192.949 45.6104C193.645 45.3382 194.246 44.8697 194.681 44.2617C194.681 44.2617 194.839 46.1875 192.967 48.3441L192.949 45.6104Z"
        fill="#CE8172"
      />
      <path
        d="M188.932 34.87C188.562 35.2133 188.281 35.6421 188.114 36.119C187.947 36.5958 187.899 37.1063 187.974 37.6059C188.05 38.1054 188.246 38.579 188.547 38.9852C188.847 39.3914 189.242 39.718 189.698 39.9366C190.449 40.3134 191.251 40.5794 192.079 40.7263C195.548 41.3946 193.835 56.6732 201.149 54.5226C208.463 52.3721 206.112 49.2009 202.796 46.3578C199.479 43.5147 198.087 38.2173 196.83 35.5504C195.894 33.6125 191.174 32.9139 188.932 34.87Z"
        fill="#FFD200"
      />
      <path d="M207.377 109.003L208.392 142.446L206.235 143.102L198.957 111.573L207.377 109.003Z" fill="#F4A28C" />
      <path
        d="M208.33 141.954C208.33 141.954 209.667 143.485 211.265 143.333C212.862 143.181 213.215 145.435 210.621 145.326C209.814 145.294 209.012 145.197 208.221 145.034C207.491 144.9 206.738 144.963 206.04 145.216C205.904 145.254 205.759 145.248 205.626 145.202C205.492 145.155 205.376 145.069 205.293 144.955C204.515 144.129 206.222 142.057 206.222 142.057L208.33 141.954Z"
        fill="#109CF1"
      />
      <path d="M190.578 109.052L182.869 141.614L184.844 142.689L198.306 113.262L190.578 109.052Z" fill="#F4A28C" />
      <path
        d="M183.028 141.146C183.028 141.146 181.412 142.361 179.875 141.899C178.338 141.437 177.542 143.569 180.106 143.983C180.909 144.117 181.722 144.182 182.536 144.177C183.275 144.194 183.998 144.403 184.632 144.784C184.758 144.848 184.9 144.871 185.039 144.853C185.179 144.835 185.31 144.775 185.415 144.681C186.345 144.025 185.087 141.644 185.087 141.644L183.028 141.146Z"
        fill="#109CF1"
      />
      <path
        opacity="0.08"
        d="M200.944 62.6143C200.944 62.6143 198.113 73.7437 202.717 81.3192C207.322 88.8947 198.957 99.69 198.957 99.69L210.232 99.2951C210.232 99.2951 210.919 88.9312 213.537 80.0434C216.155 71.1557 200.944 62.6143 200.944 62.6143Z"
        fill="black"
      />
      <path
        d="M188.373 100.06L210.231 99.2949L214.168 135.642H198.609L198.354 108.499L188.373 100.06Z"
        fill="#24285B"
      />
      <path d="M188.374 100.061L177.895 134.494L192.742 135.642L201.271 104.532L188.374 100.061Z" fill="#24285B" />
      <path
        d="M203.847 53.0892C204.82 52.9309 205.817 53.0042 206.757 53.3032C207.697 53.6022 208.553 54.1185 209.256 54.8102C209.959 55.5019 210.489 56.3496 210.803 57.2845C211.117 58.2193 211.206 59.2151 211.064 60.1909C210.456 64.7897 208.257 71.5633 201.714 78.9627C189.807 92.4127 161.249 84.9526 161.249 84.9526C161.249 84.9526 155.441 85.7059 154.67 82.5955C153.898 79.4851 161.595 81.0889 161.595 81.0889C161.595 81.0889 175.568 84.0474 185.895 74.7041C194.26 67.1164 195.384 54.5169 203.847 53.0892Z"
        fill="#F4A28C"
      />
      <path
        d="M150.945 98.2563C150.352 97.7928 149.925 97.1505 149.727 96.4248C149.529 95.6991 149.57 94.9288 149.845 94.2285C150.494 93.6277 151.013 92.8996 151.368 92.0896C151.723 91.2795 151.908 90.405 151.911 89.5204C151.836 87.7323 151.073 86.0422 149.781 84.8035C148.489 83.5647 146.769 82.873 144.979 82.873C143.189 82.873 141.469 83.5647 140.177 84.8035C138.885 86.0422 138.122 87.7323 138.047 89.5204C138.051 90.4047 138.237 91.2789 138.592 92.0887C138.947 92.8985 139.465 93.6268 140.113 94.2285C140.392 94.9276 140.437 95.6987 140.24 96.4253C140.042 97.1519 139.614 97.7945 139.019 98.2563H122.459V114.817C122.919 115.413 123.562 115.843 124.288 116.042C125.015 116.24 125.787 116.196 126.487 115.916C127.089 115.269 127.818 114.752 128.627 114.397C129.437 114.042 130.311 113.856 131.195 113.851C132.983 113.926 134.673 114.689 135.912 115.98C137.151 117.272 137.842 118.993 137.842 120.782C137.842 122.572 137.151 124.293 135.912 125.584C134.673 126.876 132.983 127.639 131.195 127.714C130.311 127.71 129.436 127.525 128.627 127.169C127.817 126.814 127.088 126.296 126.487 125.648C125.786 125.374 125.016 125.332 124.29 125.53C123.565 125.728 122.922 126.155 122.459 126.748V143.308H167.505V98.2563H150.945Z"
        fill="#E6E6E6"
      />
      <path
        d="M137.806 120.794C137.847 122.585 137.177 124.318 135.943 125.616C134.709 126.914 133.011 127.67 131.221 127.72C130.336 127.716 129.462 127.53 128.652 127.175C127.842 126.82 127.114 126.302 126.512 125.654C125.812 125.379 125.042 125.338 124.316 125.536C123.591 125.734 122.948 126.161 122.485 126.754V143.314H77.4082V98.2559H94.0719C94.6285 98.7283 95.0237 99.3628 95.2024 100.071C95.381 100.779 95.3341 101.525 95.0682 102.205C94.4198 102.805 93.9018 103.532 93.5464 104.341C93.1911 105.15 93.006 106.023 93.0027 106.907C93.0776 108.695 93.8406 110.385 95.1324 111.624C96.4241 112.862 98.1446 113.554 99.9343 113.554C101.724 113.554 103.445 112.862 104.736 111.624C106.028 110.385 106.791 108.695 106.866 106.907C106.863 106.023 106.678 105.15 106.322 104.341C105.967 103.532 105.449 102.805 104.8 102.205C104.531 101.525 104.482 100.778 104.661 100.069C104.84 99.3606 105.237 98.7261 105.797 98.2559H122.46V114.816C122.921 115.413 123.563 115.843 124.29 116.041C125.017 116.24 125.789 116.196 126.488 115.916C127.091 115.269 127.819 114.752 128.629 114.397C129.438 114.042 130.312 113.856 131.196 113.85C132.993 113.895 134.699 114.651 135.939 115.954C137.178 117.256 137.85 118.997 137.806 120.794Z"
        fill="#CCCCCC"
      />
      <path
        d="M67.554 140.478C67.554 140.478 69.3765 142.3 71.3813 141.437C73.386 140.575 74.8258 142.276 73.3617 143.709C71.8977 145.143 66.339 145.21 66.339 145.21L64.7109 141.443L67.554 140.478Z"
        fill="#109CF1"
      />
      <path
        d="M36.5894 139.639C36.5894 139.639 35.6843 142.118 37.1969 143.734C38.7096 145.35 37.7558 147.415 35.9394 146.522C34.123 145.629 31.9785 140.235 31.9785 140.235L34.6758 137.228L36.5894 139.639Z"
        fill="#109CF1"
      />
      <path
        d="M31.6563 93.0557C31.6563 93.0557 29.7123 101.148 33.09 105.127C36.4677 109.106 41.6436 127.957 33.09 137.385L36.4494 140.738C36.4494 140.738 51.2603 132.78 47.0382 104.082L57.6087 93.0557H31.6563Z"
        fill="#24285B"
      />
      <path
        d="M57.6328 93.0557L68.4949 140.738L63.0638 141.577C63.0638 141.577 52.2017 108.899 45.0332 102.192L57.6328 93.0557Z"
        fill="#24285B"
      />
      <path
        d="M60.142 51.1328C60.142 51.1328 71.4658 55.9624 69.7344 73.6468L58.6719 72.6991L60.142 51.1328Z"
        fill="#109CF1"
      />
      <path
        opacity="0.36"
        d="M60.142 51.1328C60.142 51.1328 71.4658 55.9624 69.7344 73.6468L58.6719 72.6991L60.142 51.1328Z"
        fill="white"
      />
      <path d="M48.3749 35.9873L45.2402 47.0499L51.461 48.3439L51.2484 39.772L48.3749 35.9873Z" fill="#F4A28C" />
      <path
        opacity="0.31"
        d="M51.3769 41.6494C50.6153 41.3578 49.9547 40.8513 49.4755 40.1914C49.4755 40.1914 49.3175 42.2873 51.3709 44.6262L51.3769 41.6494Z"
        fill="#CE8172"
      />
      <path
        d="M45.2405 47.0505L48.928 47.8342C48.928 47.8342 65.8165 49.6567 66.503 59.1823C67.1895 68.7079 57.6456 93.0687 57.6456 93.0687H32.0577C32.0577 93.0687 11.5425 44.5355 45.2405 47.0505Z"
        fill="#109CF1"
      />
      <path
        d="M79.7411 66.7095C79.9926 68.487 80.9394 70.092 82.3735 71.1719C83.8076 72.2518 85.6118 72.7182 87.3896 72.4686C88.2609 72.3214 89.0928 71.9968 89.8336 71.5151C90.5745 71.0334 91.2086 70.4047 91.6967 69.668C92.3092 69.3029 93.0188 69.134 93.7301 69.1839C94.4414 69.2339 95.1204 69.5003 95.6759 69.9475L98.4157 86.4654L81.9464 89.1445C81.4723 89.7002 81.1841 90.3902 81.122 91.118C81.0599 91.8457 81.2271 92.5746 81.6001 93.2026C82.3379 93.6901 82.9677 94.324 83.4505 95.0649C83.9333 95.8058 84.2587 96.6379 84.4067 97.5098C84.6267 99.2884 84.1499 101.083 83.0761 102.518C82.0022 103.953 80.4148 104.917 78.6463 105.207C76.8778 105.498 75.0655 105.093 73.589 104.077C72.1125 103.061 71.0865 101.513 70.7258 99.7575C70.586 98.8841 70.627 97.9912 70.8465 97.1343C71.0659 96.2774 71.4591 95.4747 72.0016 94.776C72.1536 94.0628 72.079 93.3199 71.7882 92.6511C71.4974 91.9824 71.0049 91.4212 70.3796 91.046L53.9345 93.7493L46.6445 49.3046L91.1014 42.0146L93.8169 58.5447C93.4307 59.1426 92.8715 59.6087 92.2139 59.8811C91.5563 60.1535 90.8313 60.2192 90.1355 60.0695C89.4368 59.5271 88.6341 59.1339 87.7772 58.9144C86.9202 58.695 86.0274 58.6539 85.154 58.7938C84.2784 58.9573 83.4436 59.2918 82.6975 59.7783C81.9513 60.2648 81.3085 60.8937 80.8057 61.629C80.3029 62.3642 79.9501 63.1914 79.7674 64.0632C79.5848 64.935 79.5758 65.8343 79.7411 66.7095Z"
        fill="#E6E6E6"
      />
      <path
        d="M54.8211 36.2913C54.8211 36.2913 54.5113 39.7844 53.5211 41.9714C53.4253 42.1828 53.2888 42.3731 53.1193 42.5316C52.9498 42.6901 52.7507 42.8135 52.5334 42.8948C52.3161 42.976 52.0848 43.0136 51.8529 43.0053C51.6211 42.9969 51.3931 42.9429 51.1822 42.8462C50.4831 42.577 49.8768 42.1115 49.436 41.5058C48.9952 40.9001 48.7387 40.18 48.6975 39.4321L48.1994 36.2305C48.1663 35.5785 48.3368 34.9324 48.6873 34.3817C49.0378 33.8309 49.5509 33.4028 50.1555 33.1566C52.3547 32.1178 55.1188 34.1893 54.8211 36.2913Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.09"
        d="M37.6655 51.4785C37.6655 51.4785 46.4196 56.5633 44.3845 68.0633C42.3494 79.5632 38.9777 83.5181 46.207 81.9143C53.4362 80.3105 56.3948 76.6837 56.3948 76.6837C56.3948 76.6837 61.5889 80.9362 56.3948 84.1377C51.2006 87.3392 44.1536 87.3453 43.0298 93.0376H32.0583C32.0583 93.0376 31.1714 90.5468 29.9928 87.4061C28.8143 84.2653 37.6655 51.4785 37.6655 51.4785Z"
        fill="black"
      />
      <path
        d="M53.9392 36.2915C52.8623 36.1464 51.8061 35.8752 50.7924 35.4836C51.0175 36.0072 51.0845 36.5853 50.9852 37.1466C50.8859 37.7079 50.6246 38.2279 50.2335 38.6426C49.862 39.0528 49.3592 39.3209 48.8116 39.4007C48.2639 39.4806 47.7055 39.3671 47.2324 39.08L48.0768 34.6452C48.1781 34.0086 48.4495 33.4112 48.8622 32.9161C49.275 32.421 49.8138 32.0466 50.4218 31.8325C50.9482 31.6451 51.4859 31.4909 52.0317 31.3708C53.4228 31.0792 55.3911 31.6381 56.1627 32.8774C56.4912 33.4193 56.595 34.0681 56.452 34.6855C56.3091 35.3028 55.9305 35.8399 55.3972 36.1822C54.9333 36.3644 54.4252 36.4025 53.9392 36.2915Z"
        fill="#FFD200"
      />
      <path
        d="M50.6236 38.612C50.6236 38.612 50.8362 37.2816 49.8156 37.2026C48.795 37.1236 48.4791 39.0251 49.8156 39.4746L50.6236 38.612Z"
        fill="#F4A28C"
      />
      <path
        d="M24.1788 71.8242C24.1788 71.8242 22.739 86.5986 29.9925 87.4248C35.46 88.0323 44.2506 84.9523 49.6148 83.4274C51.9354 82.7653 53.0593 82.4555 54.8454 80.8273C56.9838 78.8712 59.9484 77.48 57.6399 75.6272C53.5453 72.3284 50.6415 77.9721 50.6415 77.9721C47.2207 78.9638 43.6954 79.5511 40.1378 79.7217C39.4712 79.7386 38.8098 79.5999 38.2063 79.3165C37.6027 79.0332 37.0734 78.613 36.6606 78.0893C36.2478 77.5656 35.9628 76.9529 35.8282 76.2999C35.6936 75.6468 35.7132 74.9713 35.8853 74.3271L37.24 69.2363C37.24 69.2363 27.6294 60.227 24.1788 71.8242Z"
        fill="#F4A28C"
      />
      <path
        d="M54.5112 38.5029L55.2402 40.0035C55.2811 40.0891 55.2997 40.1837 55.2943 40.2785C55.2889 40.3732 55.2598 40.4651 55.2095 40.5456C55.1593 40.6261 55.0896 40.6927 55.0068 40.7391C54.924 40.7856 54.8309 40.8105 54.736 40.8114H53.3691L54.5112 38.5029Z"
        fill="#F4A28C"
      />
      <path
        d="M31.7175 50.3367C27.945 53.2345 22.277 59.6315 22.3863 71.7086L37.8837 72.6988C37.8837 72.6988 42.3609 59.4371 37.665 51.4788C37.3736 50.9858 36.9808 50.5603 36.5125 50.2306C36.0442 49.901 35.5112 49.6746 34.9488 49.5666C34.3864 49.4586 33.8074 49.4714 33.2503 49.6042C32.6933 49.737 32.1708 49.9867 31.7175 50.3367Z"
        fill="#109CF1"
      />
      <path
        opacity="0.36"
        d="M31.7175 50.3367C27.945 53.2345 22.277 59.6315 22.3863 71.7086L37.8837 72.6988C37.8837 72.6988 42.3609 59.4371 37.665 51.4788C37.3736 50.9858 36.9808 50.5603 36.5125 50.2306C36.0442 49.901 35.5112 49.6746 34.9488 49.5666C34.3864 49.4586 33.8074 49.4714 33.2503 49.6042C32.6933 49.737 32.1708 49.9867 31.7175 50.3367Z"
        fill="white"
      />
      <path
        d="M80.926 67.274C80.4046 67.8643 80.0984 68.6137 80.0572 69.4002C80.004 69.8188 80.0826 70.2434 80.282 70.6152C80.4226 70.8295 80.6069 71.0116 80.8227 71.1498C81.6671 71.7573 82.9671 71.8242 83.6415 71.0466C83.9947 70.5656 84.1727 69.9782 84.1457 69.382C84.1457 68.6895 84.2247 67.3104 83.6658 66.7698C82.9185 66.059 81.5092 66.63 80.926 67.274Z"
        fill="#F4A28C"
      />
    </svg>
  </Icon>
);
