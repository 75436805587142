import type { IComponentType, IComponentTypesListingDTO, IComponentTypeUpdateDTO } from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { apiNew } from 'features/api/services/apiNew.service';

const COMPONENT_TYPES_PATH = '/component-types';

const componentTypesFactoryKey = createQueryKeys('component-types', {
  get: () => ({
    queryKey: [COMPONENT_TYPES_PATH],
    queryFn: () => apiNew.get<unknown, IComponentTypesListingDTO[]>(COMPONENT_TYPES_PATH),
  }),
});

export function useComponentTypes() {
  return useSuspenseQuery({
    ...componentTypesFactoryKey.get(),
    staleTime: 60000,
  });
}

export function useUpdateComponentsTypes() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'componentType']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (componentTypeDTO: IComponentTypeUpdateDTO) =>
      apiNew.patch<IComponentTypeUpdateDTO, IComponentType[]>(`${COMPONENT_TYPES_PATH}`, componentTypeDTO),
    onSuccess: async () => {
      toast.success(t('componentType:tooltips.componentTypeUpdated'));
      await queryClient.invalidateQueries(componentTypesFactoryKey.get());
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
  });
}
