import type { FieldValues } from 'graneet-form';
import { HiddenField } from 'graneet-form';

import { useNegativeField } from '../useNegativeField';
import type { CurrencyFieldProps, CurrencyValue } from '../CurrencyField';
import { CurrencyField } from '../CurrencyField';
import type { KeysMatching } from '../../../utils';

export const NegativeCurrencyField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, CurrencyValue> = KeysMatching<T, CurrencyValue>,
>({
  name,
  ...rest
}: CurrencyFieldProps<T, K>) => {
  const { negativeHiddenField, handleBlur, fieldName } = useNegativeField(name as string, rest.onBlur);

  return (
    <>
      <HiddenField {...negativeHiddenField} />

      <CurrencyField<T, K> name={fieldName as K} forceNegative {...rest} onBlur={handleBlur} />
    </>
  );
};
