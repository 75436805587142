import { useCallback, useEffect } from 'react';
import { Flex, GridItem, HStack, useTheme, Box, Grid } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useFormContext } from 'graneet-form';

import { SimpleDeleteIcon } from '../../../../Icons';
import { ActionMenu } from '../../../../ActionMenu/ActionMenu';
import type { ButtonColorScheme } from '../../../../Badge/Badge';
import { Badge } from '../../../../Badge/Badge';
import type { TimeTableMode } from '../../contexts';
import { useTimeTableContext } from '../../contexts';
import { timeTableTranslations } from '../../../configureDefaultLabels';
import type { ABSENCE_DURATION, ITimeTableSlot } from '../../types';
import { CheckboxField } from '../../../../Field';
import type { TimeTableFormValues } from '../../types/time-table.form';
import { getTimeTableApproveFieldName } from '../../types/time-table.form';
import { EllipsisText } from '../../../../EllipsisText';
import { getTimeTableSlotInfo } from '../../utils/getTimeTableSlotInfo';

import { TimeTableHoveredSlot } from './TimeTableHoveredSlot';

export const TimeTableRangeInfo = ({
  name,
  color,
  nbMinutes,
  mode,
  absenceDuration,
}: {
  name: string;
  color: ButtonColorScheme;
  nbMinutes: number;
  mode: TimeTableMode;
  absenceDuration: number;
}) => (
  <HStack maxW="12rem">
    <Badge colorScheme={color} theme="dark" fontWeight="700">
      {getTimeTableSlotInfo(nbMinutes, absenceDuration)}
    </Badge>
    <EllipsisText fontSize="sm" color={mode === 'default' ? `${color}.900` : 'white'} fontWeight={600}>
      {name}
    </EllipsisText>
  </HStack>
);

export interface TimeTableRangeProps {
  name: string;
  color: ButtonColorScheme;
  row: number;
  startDate: Date;
  slots: ITimeTableSlot[];
  daysPlaceholders: Date[];
}

export const TimeTableRange = ({ name, color, row, startDate, slots, daysPlaceholders }: TimeTableRangeProps) => {
  const { colors } = useTheme();

  const { onEdit, onDelete, mode, numberOfDays, absenceDurationPossibleValues } = useTimeTableContext();

  const getAbsenceDurationNumber = useCallback(
    (absenceDuration: ABSENCE_DURATION | null) =>
      absenceDurationPossibleValues && absenceDuration ? absenceDurationPossibleValues[absenceDuration] : 0,
    [absenceDurationPossibleValues],
  );

  const formContext = useFormContext<TimeTableFormValues>();
  useEffect(() => {
    const values: Partial<TimeTableFormValues> = {};
    slots.forEach((slot) => {
      values[getTimeTableApproveFieldName(slot.id)] = true;
    });

    formContext.setFormValues(values);
  }, [formContext, slots]);

  /**
   * Compute the width of the range
   * @param start start date of the range
   * @param end end date of the range
   */
  const getWidth = useCallback(
    (start: Date, end: Date) => {
      const rawWidth = dayjs(end).diff(dayjs(start), 'day') + 1;

      // If timetable display all days, there is no edge case
      if (daysPlaceholders.length === 7) {
        return rawWidth;
      }

      // If the range contain saturday (not display by the table), reduce the computed size by one
      if (dayjs(end).day() === 6) {
        return rawWidth - 1;
      }

      // If the range contain saturday (not display by the table), reduce the computed size by two
      if (dayjs(end).day() === 0) {
        return rawWidth - 2;
      }

      return rawWidth;
    },
    [daysPlaceholders],
  );

  // Store the current range in analysis
  let currentRange: [number, number] | null = null;
  return slots.map((slot, index) => {
    // If the next range is approved, we delegate the rendering to the next range
    if (slot.isApproved && slots[index + 1]?.isApproved) {
      currentRange = [currentRange?.[0] ?? index, index];
      return null;
    }

    // If the range is approved, we render the range and potentially previous ranges from the same block
    if (slot.isApproved) {
      currentRange = [currentRange?.[0] ?? index, index];

      // Compute slots contained in the range
      const rangeSlots = slots.slice(currentRange?.[0] ?? index, (currentRange?.[1] ?? index) + 1);
      const rangeStartDate = dayjs(startDate)
        .add(currentRange?.[0] ?? index, 'day')
        .toDate();
      const rangeEndDate = dayjs(startDate)
        .add(currentRange?.[1] ?? index, 'day')
        .toDate();
      const width = getWidth(rangeStartDate, rangeEndDate);
      const dayOffset = dayjs(rangeStartDate).weekday();

      if (numberOfDays === 5 && dayjs(rangeEndDate).day() === 6) {
        rangeSlots.length -= 1;
      }
      if (numberOfDays === 5 && dayjs(rangeEndDate).day() === 0) {
        rangeSlots.length -= rangeSlots.length === 1 ? 1 : 2;
      }

      currentRange = null;

      if (rangeSlots.length === 0) {
        return null;
      }

      const sumNbMinutes = rangeSlots.reduce((acc, rangeSlot) => acc + rangeSlot.nbMinutes, 0);
      const sumNbAbsenceDuration = rangeSlots.reduce(
        (acc, rangeSlot) => acc + getAbsenceDurationNumber(rangeSlot.absenceDuration),
        0,
      );

      return (
        <Flex
          key={`${rangeStartDate.toString()}-${rangeEndDate.toString()}`}
          as={GridItem}
          mr={2}
          mb={2}
          h={50}
          zIndex={2}
          gridRow={row}
          bgColor={`${color}.100`}
          gridRowStart={row}
          gridRowEnd={row}
          gridColumnStart={1 + dayOffset}
          gridColumnEnd={width + dayOffset + 1}
          borderLeft={`5px solid ${colors[color][600]}`}
          borderRightRadius="md"
          justifyContent="space-between"
          alignItems="center"
          overflow="hidden"
          position="relative"
          role="group"
        >
          <Flex position="relative" ml={3} _groupHover={{ position: 'relative', display: 'none' }}>
            <TimeTableRangeInfo
              name={name}
              nbMinutes={sumNbMinutes}
              color={color}
              mode="default"
              absenceDuration={sumNbAbsenceDuration}
            />
          </Flex>

          <Grid templateColumns={`repeat(${rangeSlots.length}, 1fr)`} w="100%" ml={-1} mr={-7}>
            {rangeSlots.map((rangeSlot, rangeIndex) => (
              <GridItem
                key={rangeSlot.id}
                colStart={rangeIndex + 1}
                colEnd={rangeIndex + 2}
                opacity={0}
                _hover={{ opacity: 1 }}
                transition="visibility 0s, opacity 0.1s linear"
                ml={3}
                mr={rangeSlots.length === rangeIndex + 1 ? 6 : 3}
              >
                <TimeTableHoveredSlot
                  slot={rangeSlot}
                  color={color}
                  startDate={dayjs(rangeStartDate).add(rangeIndex, 'day').toDate()}
                  absenceDuration={getAbsenceDurationNumber(rangeSlot.absenceDuration)}
                />
              </GridItem>
            ))}
          </Grid>

          <Box mr={3}>
            <ActionMenu placement="top-start">
              <ActionMenu.Action
                onClick={() => onDelete(rangeSlots)}
                label={timeTableTranslations.deleteAllTimes}
                icon={<SimpleDeleteIcon />}
                warning
              />
            </ActionMenu>
          </Box>
        </Flex>
      );
    }

    currentRange = null;

    const rangeStartDate = dayjs(startDate).add(index, 'day').toDate();
    const dayOffset = dayjs(rangeStartDate).weekday();

    if (numberOfDays === 5 && (dayjs(rangeStartDate).day() === 6 || dayjs(rangeStartDate).day() === 0)) {
      return null;
    }

    return (
      <Flex
        key={slot.id}
        as={GridItem}
        mr={2}
        px={3}
        mb={2}
        h={50}
        zIndex={2}
        gridRow={row}
        bgColor={!slot.isApproved ? `${color}.600` : `${color}.100`}
        gridRowStart={row}
        gridRowEnd={row}
        gridColumnStart={1 + dayOffset}
        gridColumnEnd={dayOffset + 2}
        borderRightRadius="md"
        alignItems="center"
        overflow="hidden"
        role="group"
      >
        <Grid templateColumns="auto 0rem 2rem" _groupHover={{ gridTemplateColumns: '0rem auto 2rem' }} boxSize="100%">
          <GridItem overflow="hidden" cursor="pointer">
            <Flex onClick={() => onEdit(slot, dayjs(rangeStartDate).toDate())} alignItems="center" h="100%">
              <TimeTableRangeInfo
                name={name}
                nbMinutes={slot.nbMinutes}
                color={color}
                mode="approve"
                absenceDuration={getAbsenceDurationNumber(slot.absenceDuration)}
              />
            </Flex>
          </GridItem>

          <GridItem overflow="hidden" cursor="pointer">
            <TimeTableHoveredSlot
              slot={slot}
              color={color}
              startDate={dayjs(rangeStartDate).toDate()}
              absenceDuration={getAbsenceDurationNumber(slot.absenceDuration)}
            />
          </GridItem>

          <GridItem>
            {!slot.isApproved && mode === 'approve' && (
              <Flex alignItems="center" h="100%" ml={2}>
                <CheckboxField<TimeTableFormValues> name={getTimeTableApproveFieldName(slot.id)} />
              </Flex>
            )}
          </GridItem>
        </Grid>
      </Flex>
    );
  });
};
