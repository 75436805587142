import { useTranslation } from 'react-i18next';
import { Callout, Card, RadioGroupField, SimpleAlertIcon } from '@graneet/lib-ui';
import { Box, Flex } from '@chakra-ui/react';
import { FEATURE_FLAGS, SUPPLIER_INVOICE_MODE } from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { FC } from 'react';

import type { EditSupplierInvoiceForm } from '../../forms/edit-supplier-invoice.form';

import { AccountingConfigPurchasesAccountField } from 'features/accounting/components/AccountingConfigPurchasesAccountField';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { Rule } from 'features/form/rules/Rule';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';

interface SupplierInvoiceClassificationCardProps {
  isDirectPaymentSupplierInvoice: boolean;
}

export const SupplierInvoiceClassificationCard: FC<SupplierInvoiceClassificationCardProps> = ({
  isDirectPaymentSupplierInvoice,
}) => {
  const { t } = useTranslation(['supplierInvoices']);

  const hasAccountingBreakdownPurchasesAccountFF = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_PURCHASES_ACCOUNT);
  const hasAccountingBreakdownSupplierInvoiceLinesFF = useFeatureFlag(
    FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_LINES,
  );

  const form = useFormContext<EditSupplierInvoiceForm>();
  const { mode } = useOnChangeValues(form, ['mode']);

  return (
    <Card title={t('supplierInvoices:cards.classificationCard.title')}>
      <Flex direction="column" gap={6}>
        <Box display={hasAccountingBreakdownSupplierInvoiceLinesFF ? 'block' : 'none'}>
          {isDirectPaymentSupplierInvoice && (
            <Callout mb={3} colorScheme="yellow" icon={<SimpleAlertIcon boxSize={5} stroke="yellow.500" />}>
              {t('supplierInvoices:cards.classificationCard.directPaymentWarning')}
            </Callout>
          )}
          <RadioGroupField<EditSupplierInvoiceForm> name="mode" isDisabled={isDirectPaymentSupplierInvoice}>
            <RadioGroupField.Option
              value={SUPPLIER_INVOICE_MODE.CONDENSED}
              label={t('supplierInvoices:cards.classificationCard.condensedMode')}
              description={t('supplierInvoices:cards.classificationCard.condensedModeDescription')}
            />
            <RadioGroupField.Option
              value={SUPPLIER_INVOICE_MODE.DETAILED}
              label={t('supplierInvoices:cards.classificationCard.detailedMode')}
              description={t('supplierInvoices:cards.classificationCard.detailedModeDescription')}
            />
          </RadioGroupField>
        </Box>

        {mode === SUPPLIER_INVOICE_MODE.CONDENSED && (
          <Flex gap={3}>
            <Box minW="15rem">
              <ComponentTypeField<EditSupplierInvoiceForm>
                name="typeId"
                material
                label={t('supplierInvoices:fields.invoiceType')}
                isRequired
              >
                <Rule.IsRequired />
              </ComponentTypeField>
            </Box>

            {hasAccountingBreakdownPurchasesAccountFF && (
              <Box minW="15rem">
                <AccountingConfigPurchasesAccountField<EditSupplierInvoiceForm>
                  name="accountingConfigPurchasesAccountId"
                  label={t('supplierInvoices:fields.accountingConfigPurchasesAccount')}
                />
              </Box>
            )}
          </Flex>
        )}
      </Flex>
    </Card>
  );
};
