import { Card, ConfirmDeletionModal, STORAGE_STRATEGY, usePaginatedData, useToast } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';
import { useDisclosure } from '@chakra-ui/react';

import { ContactUpsertModal } from '../modals/ContactUpsertModal';
import { ContactAvailableClientModal } from '../modals/ContactAvailableClientModal';
import { ContactAddOrAssociateButton } from '../ContactAddOrAssociateButton';

import { ContactCardDetails } from './ContactCardDetails';

import {
  getContactAssociatedToClientPaginated,
  useContactDelete,
  useContactDissociateFromClient,
  useContactRoles,
  useContactsAssociateToClient,
} from 'features/contact/services/contact.api';

interface ContactCardClientProps {
  clientId: number;
}

export const ContactCardClient: FC<ContactCardClientProps> = ({ clientId }) => {
  const upsertModal = useDisclosure();
  const deletionModal = useDisclosure();
  const associationModal = useDisclosure();

  const contactsData = usePaginatedData(
    getContactAssociatedToClientPaginated,
    { clientId },
    undefined,
    STORAGE_STRATEGY.STATE,
  );
  const contactRoles = useContactRoles();

  const contactDeleteMutation = useContactDelete();
  const contactsAssociateToClientMutation = useContactsAssociateToClient();
  const contactDissociateFromClientMutation = useContactDissociateFromClient();

  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const [contactUpsert, setContactUpsert] = useState<IContact | null>(null);
  const [contactToDelete, setContactToDelete] = useState<IContact | null>(null);

  const reloadContacts = useCallback(() => {
    contactsData.fetch();
  }, [contactsData]);

  const onUpsert = useCallback(
    (contact?: IContact) => {
      if (contact) {
        setContactUpsert(contact);
      } else {
        setContactUpsert(null);
      }
      upsertModal.onOpen();
    },
    [upsertModal],
  );

  const onDissociate = useCallback(
    async (contactId: string) => {
      await contactDissociateFromClientMutation.mutateAsync({ contactId, clientId });
      toast.success(t('contacts:card.toastDissociateSuccess'));
      reloadContacts();
    },
    [clientId, contactDissociateFromClientMutation, reloadContacts, t, toast],
  );

  const onDelete = useCallback(
    async (contact: IContact) => {
      if (contact) {
        setContactToDelete(contact);
      } else {
        setContactToDelete(null);
      }
      deletionModal.onOpen();
    },
    [deletionModal],
  );

  const onWorkerDeletion = useCallback(async () => {
    if (contactToDelete) {
      await contactDeleteMutation.mutateAsync(contactToDelete);

      contactsData.fetch();
      deletionModal.onClose();
    }
  }, [contactToDelete, contactDeleteMutation, contactsData, deletionModal]);

  const onUpsertSuccess = useCallback(
    async (contact: IContact, data: { isDefaultEmailRecipient?: boolean }) => {
      if (data.isDefaultEmailRecipient !== undefined) {
        await contactsAssociateToClientMutation.mutateAsync({
          id: clientId,
          dto: {
            contacts: [
              {
                id: contact.id,
                isDefaultEmailRecipient: data.isDefaultEmailRecipient ?? false,
              },
            ],
          },
        });
      }

      reloadContacts();
    },
    [clientId, contactsAssociateToClientMutation, reloadContacts],
  );

  const onDefaultEmailRecipientChange = useCallback(
    async (contactId: string, isDefaultEmailRecipient: boolean) => {
      await contactsAssociateToClientMutation.mutateAsync({
        id: clientId,
        dto: {
          contacts: [{ id: contactId, isDefaultEmailRecipient }],
        },
      });
      contactsData.fetch();
    },
    [clientId, contactsAssociateToClientMutation, contactsData],
  );

  return (
    <Card
      w="100%"
      title={t('contacts:card.title')}
      topRightContent={<ContactAddOrAssociateButton onAssociate={associationModal.onOpen} onCreate={onUpsert} />}
    >
      <ContactCardDetails
        contacts={contactsData.data || []}
        isContactDefaultEmailRecipient={(c) => c.isDefaultEmailRecipient}
        onUpsert={onUpsert}
        onDelete={onDelete}
        onDissociate={onDissociate}
        onDefaultEmailRecipientChange={onDefaultEmailRecipientChange}
        emptyState={t('contacts:emptyState.client')}
      />
      <ContactUpsertModal
        isOpen={upsertModal.isOpen}
        onSuccess={onUpsertSuccess}
        onClose={upsertModal.onClose}
        contact={contactUpsert}
        roles={contactRoles.data}
        hasIsDefaultEmailRecipient
      />
      <ConfirmDeletionModal
        isOpen={deletionModal.isOpen}
        onClose={deletionModal.onClose}
        title={t('contacts:deletionModal.title')}
        action={t('contacts:deletionModal.action')}
        description={t('contacts:deletionModal.description')}
        onDeleted={onWorkerDeletion}
      />
      <ContactAvailableClientModal
        isOpen={associationModal.isOpen}
        onClose={associationModal.onClose}
        title={t('contacts:availableModal.title')}
        onSuccess={reloadContacts}
        clientId={clientId}
      />
    </Card>
  );
};
