import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const BlueCircledPlus: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 81 81" {...props}>
    <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M80.4471 40.2253C80.4471 62.4061 62.4007 80.4489 40.2235 80.4489C18.0409 80.4489 0 62.4061 0 40.2253C0 18.0482 18.0409 0 40.2235 0C62.4007 0 80.4471 18.0482 80.4471 40.2253Z"
        fill="#DCF4FF"
      />
      <path
        d="M80.4462 40.2253C80.4462 62.4061 62.3998 80.4489 40.2227 80.4489V0C62.3998 0 80.4462 18.0482 80.4462 40.2253Z"
        fill="#B0E2FF"
      />
      <path
        d="M61.5604 33.2735V47.1781H47.1758V61.5627H33.2711V47.1781H18.8848V33.2735H33.2711V18.8871H47.1758V33.2735H61.5604Z"
        fill="#109CF1"
      />
      <path d="M61.5595 33.2735V47.1781H47.175V61.5627H40.2227V18.8871H47.175V33.2735H61.5595Z" fill="#0D8CD9" />
    </svg>
  </Icon>
);
