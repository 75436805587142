import { HStack, Image, Text, Flex, Box, CircularProgress } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { ValueAnimationTransition } from 'framer-motion';
import { animate, useMotionValue } from 'framer-motion';

import { Modal } from '../../Modal';
import { GraneetIconButton } from '../IconButton/IconButton';
import { SimpleCarrouselLeftIcon } from '../../Icons/v2/SimpleCarrouselLeftIcon';
import { SimpleCarrouselRightIcon } from '../../Icons/v2/SimpleCarrouselRightIcon';

import { Slider } from './Slider';
import type { ImageItem } from './types';

const transition: ValueAnimationTransition = {
  type: 'spring',
  duration: 1.5,
  bounce: 0,
};

export const CarrouselModal: FC<{
  images: ImageItem[];
  onClose: () => void;
  isOpen: boolean;
  selectedImage: ImageItem | null;
}> = ({ images, onClose, isOpen, selectedImage }) => {
  const [index, setIndex] = useState(selectedImage ? images.findIndex((img) => img.id === selectedImage.id) : 0);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleLeftClick = useCallback(() => {
    setIndex((i) => (i === 0 ? images.length - 1 : i - 1));
  }, [images]);

  const handleRightClick = useCallback(() => {
    setIndex((i) => (i === images.length - 1 ? 0 : i + 1));
  }, [images]);

  const x = useMotionValue(0);

  const calculateNewX = useCallback(() => -index * (containerRef.current?.clientWidth || 0), [index]);

  useEffect(() => {
    animate(x, calculateNewX(), transition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        handleLeftClick();
      }
      if (event.key === 'ArrowRight') {
        handleRightClick();
      }
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose, handleLeftClick, handleRightClick],
  );

  useEffect(() => {
    const keyDownListener = (event: KeyboardEvent) => handleKeyDown(event);
    document.addEventListener('keydown', keyDownListener);

    return () => {
      document.removeEventListener('keydown', keyDownListener);
    };
  }, [handleKeyDown]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      title={
        <HStack>
          <Text>Image</Text>
          <Text>{selectedImage?.name}</Text>
        </HStack>
      }
    >
      <Modal.Close />
      <HStack alignItems="center" justifyContent="space-between" height="70vh">
        <GraneetIconButton size="lg" onClick={handleLeftClick} icon={<SimpleCarrouselLeftIcon />} color="ghost" />
        <Flex h="100%" w="100%" maxW="80%" maxH="70vh" overflow="hidden" position="relative" ref={containerRef}>
          {images.map((img, i) => (
            <Slider key={img.id} x={x} i={i}>
              <Flex
                boxSize="100%"
                alignItems="center"
                justifyContent="center"
                overflow="hidden"
                borderRadius="0.5rem"
                position="relative"
              >
                <Image
                  borderRadius="0.5rem"
                  src={img.url}
                  alt={img.name}
                  maxH="65vh"
                  loading="lazy"
                  fallback={
                    <Box
                      position="absolute"
                      borderRadius="0.5rem"
                      top="50%"
                      left="50%"
                      width="50%"
                      height="50%"
                      transform="translate(-50%, -50%)"
                      background="rgba(0, 0, 0, 0.40)"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress isIndeterminate color="white" size="1.5rem" />
                    </Box>
                  }
                />
              </Flex>
            </Slider>
          ))}
        </Flex>
        <GraneetIconButton size="lg" onClick={handleRightClick} icon={<SimpleCarrouselRightIcon />} color="ghost" />
      </HStack>
    </Modal>
  );
};
