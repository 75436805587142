import type { IBillResponseDTO, IProgressStatement } from '@graneet/business-logic';

import { getEnvValue } from 'config/env';

export const getPublicLinkFromProgressStatement = (progressStatement: IProgressStatement): string | null => {
  if (progressStatement.isDirect) {
    return null;
  }

  const base = getEnvValue('REACT_APP_AUTH0_RETURN_TO');
  return `${base}external/progress-statements/${progressStatement.externalId}`;
};

export const getPublicLinkFromBill = (bill: IBillResponseDTO): string | null => {
  if (!bill.progressStatement) {
    return null;
  }

  return getPublicLinkFromProgressStatement(bill.progressStatement);
};
