import type { FC } from 'react';
import { useCallback } from 'react';
import { Card, DataTable, IconAdvanced, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import type { IAccountingDataResponseDTO } from '@graneet/business-logic';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type { AccountingPurchasesEditionBlockForm } from '../../../forms/accounting.form';
import {
  getAccountingAccountPurchasesNameFieldName,
  getAccountingAccountPurchasesFieldName,
} from '../../../forms/accounting.form';
import { useDeleteAccountingConfigPurchasesAccount } from '../../../services/accounting-config.api';

import { AccountingSupplierEditionButton } from './AccountingSupplierEditionButton';
import { AccountingAddPurchasesAccountButton } from './AccountingAddPurchasesAccountButton';

import { Rule } from 'features/form/rules/Rule';
import { useComponentTypes } from 'features/component-type/services/component-type.api';
import { ComponentTypeBadge } from 'features/component-type/components/ComponentTypeBadge';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface AccountingPurchasesEditionBlockProps {
  mustDisplayFields?: Partial<Record<keyof AccountingPurchasesEditionBlockForm, boolean>>;

  haveRequiredFields?: boolean;

  hideSeparator?: boolean;

  canOnlyUpdateAccounts?: boolean;
}

interface AccountingPurchasesEditionBlockWithSupplierProps extends AccountingPurchasesEditionBlockProps {
  suppliers: IAccountingDataResponseDTO['suppliers'];

  purchasesAccounts: IAccountingDataResponseDTO['purchasesAccounts'];
}

export const AccountingPurchasesEditionBlock: FC<
  AccountingPurchasesEditionBlockProps | AccountingPurchasesEditionBlockWithSupplierProps
> = ({
  mustDisplayFields,
  haveRequiredFields = false,
  hideSeparator = false,
  canOnlyUpdateAccounts = false,
  ...otherProps
}) => {
  const { t } = useTranslation(['accounting']);

  const componentTypes = useComponentTypes();

  const hasAccountingBreakdownSalesAccount = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_PURCHASES_ACCOUNT);

  const mustDisplayField = useCallback(
    (fieldName: keyof AccountingPurchasesEditionBlockForm) => {
      if (!mustDisplayFields) {
        return true;
      }
      return !!mustDisplayFields[fieldName];
    },
    [mustDisplayFields],
  );

  const deleteAccountingConfigPurchasesAccountMutation = useDeleteAccountingConfigPurchasesAccount();

  return (
    <Stack>
      <Text fontSize="lg" color="gray.800">
        {t('accounting:parameters.sections.purchases')}
      </Text>

      <Card>
        <DataTable numberOfColumns={1}>
          {!hasAccountingBreakdownSalesAccount &&
            componentTypes.data.map((componentType) => {
              const name = getAccountingAccountPurchasesFieldName(componentType.id);

              if (!mustDisplayField(name)) {
                return null;
              }

              return (
                <DataTable.Row key={componentType.id} label={<ComponentTypeBadge type={componentType} />}>
                  <DataTable.Cell>
                    <TextField<AccountingPurchasesEditionBlockForm>
                      name={name}
                      placeholder={t('accounting:parameters.configPlaceholder')}
                    >
                      {haveRequiredFields && <Rule.IsRequired />}
                    </TextField>
                  </DataTable.Cell>
                </DataTable.Row>
              );
            })}

          {hasAccountingBreakdownSalesAccount && 'purchasesAccounts' in otherProps && (
            <>
              {otherProps.purchasesAccounts.map((purchasesAccount) => {
                const name = getAccountingAccountPurchasesFieldName(purchasesAccount.id);

                if (!mustDisplayField(name)) {
                  return null;
                }

                return (
                  <DataTable.Row
                    key={purchasesAccount.id}
                    label={
                      canOnlyUpdateAccounts ? (
                        purchasesAccount.name
                      ) : (
                        <TextField<AccountingPurchasesEditionBlockForm>
                          name={getAccountingAccountPurchasesNameFieldName(purchasesAccount.id)}
                        />
                      )
                    }
                  >
                    <DataTable.Cell>
                      <Flex alignItems="center" gap={3}>
                        <TextField<AccountingPurchasesEditionBlockForm>
                          name={name}
                          placeholder={t('accounting:parameters.configPlaceholder')}
                        >
                          {haveRequiredFields && <Rule.IsRequired />}
                        </TextField>
                        {!canOnlyUpdateAccounts && (
                          <IconAdvanced
                            icon={
                              <Box
                                color={purchasesAccount.isDeletable ? 'black' : 'gray.500'}
                                onClick={
                                  purchasesAccount.isDeletable
                                    ? () => deleteAccountingConfigPurchasesAccountMutation.mutate(purchasesAccount.id)
                                    : undefined
                                }
                                _hover={
                                  purchasesAccount.isDeletable ? { cursor: 'pointer', color: 'red.500' } : undefined
                                }
                              >
                                <i className="ri-close-line" />
                              </Box>
                            }
                          />
                        )}
                      </Flex>
                    </DataTable.Cell>
                  </DataTable.Row>
                );
              })}
              {!canOnlyUpdateAccounts && <AccountingAddPurchasesAccountButton />}
            </>
          )}

          {!hideSeparator && <DataTable.Separator />}

          {mustDisplayField('accountSupplier') && (
            <DataTable.Row label={t('accounting:fields.accountSupplier')}>
              <DataTable.Cell>
                <Flex gap={3}>
                  <TextField<AccountingPurchasesEditionBlockForm>
                    name="accountSupplier"
                    placeholder={t('accounting:parameters.configPlaceholder')}
                  >
                    {haveRequiredFields && <Rule.IsRequired />}
                  </TextField>
                  {'suppliers' in otherProps && <AccountingSupplierEditionButton suppliers={otherProps.suppliers} />}
                </Flex>
              </DataTable.Cell>
            </DataTable.Row>
          )}
        </DataTable>
      </Card>
    </Stack>
  );
};
