import type { FC } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';

import { DEFAULT_INTERNAL_PADDING_X, SHADOW_CELL_PARENT_PROPS, SHADOW_CELL_PROPS } from './ShadowCell';

export const ShadowExtendedCell: FC<FlexProps> = ({ children, ...props }) => (
  <Flex {...SHADOW_CELL_PARENT_PROPS} {...props}>
    <Flex
      {...SHADOW_CELL_PROPS}
      px={DEFAULT_INTERNAL_PADDING_X}
      justifyContent="center"
      textAlign="center"
      bg="white"
      borderTopRadius="md"
      paddingInlineStart={0}
      paddingInlineEnd={0}
    >
      {children}
    </Flex>
  </Flex>
);
