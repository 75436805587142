import type { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Container } from '@graneet/lib-ui';

import { ComponentTypeEditCard } from 'features/component-type/components/ComponentTypeEditCard/ComponentTypeEditCard';

export const ComponentTypeTab: FC = () => (
  <Container size="sm">
    <Box maxW="40rem">
      <ComponentTypeEditCard />
    </Box>
  </Container>
);
