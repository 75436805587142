import { SellsheetAmountSummaryCard } from '@graneet/lib-ui';
import { CheckType } from '@org/quotation-lib';
import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

import { formatCustomDiscount, formatDiscount } from '../../utils/quote-utils';
import { UpsertQuotationSaleDiscountModal } from '../../../quote-sales-discount/modals/UpsertQuotationSaleDiscountModal';
import { DisableQuotationReversalOfLiabilityModal } from '../modals/DisableQuotationReversalOfLiabilityModal';
import { useQuoteUpdateReversalOfLiability } from '../../hooks/useQuoteUpdateReversalOfLiability';
import { useQuote } from '../../hooks/useQuote';

import { useQuoteSalesDiscountDelete } from 'features/quotation/quote-sales-discount/hooks/useQuoteSalesDiscountDelete';
import { UpsertQuotationCustomDiscountModal } from 'features/quotation/quote-custom-discount/modals/UpsertQuotationCustomDiscountModal';
import { useQuoteCustomDiscountDelete } from 'features/quotation/quote-custom-discount/hooks/useQuoteCustomDiscountDelete';
import { useStore } from 'store/store';
import { updateQuoteLotDefaultVatRateModal } from 'features/quotation/quote-common/store/quoteUpdateZustand';

export const QuoteSellSheetSummaryCard = () => {
  const isEditable = useStore((store) => store.quoteEditable);

  const { quote } = useQuote();
  const discount = quote.getSalesDiscount();
  const customDiscounts = quote.getCustomDiscounts();
  const [customDiscountState, setCustomDiscountState] = useState<{
    hasInitialValues: boolean;
    customDiscountId: string | null;
  }>({
    hasInitialValues: true,
    customDiscountId: null,
  });
  const quoteUpdateReversalOfLiability = useQuoteUpdateReversalOfLiability();

  const saleDiscountModal = useDisclosure();
  const customDiscountModal = useDisclosure();
  const modalDisableReversalOfLiability = useDisclosure();

  const quoteSalesDiscountDelete = useQuoteSalesDiscountDelete();
  const quoteCustomDiscountDelete = useQuoteCustomDiscountDelete();

  const handleOpenCustomDiscountModal = useCallback(
    (hasInitialValues: boolean, customDiscountId?: string) => {
      setCustomDiscountState({
        hasInitialValues,
        customDiscountId: customDiscountId ?? null,
      });
      customDiscountModal.onOpen();
    },
    [customDiscountModal],
  );

  const handleUpdateDefaultVatRate = useCallback(() => {
    updateQuoteLotDefaultVatRateModal({
      isOpen: true,
      nodeId: quote.getTree().getRootNodeId(),
    });
  }, [quote]);

  const handleSwitchReversalOfLiability = useCallback(() => {
    if (quote.getHasReversalOfLiability()) {
      // Disable reversal of liability
      modalDisableReversalOfLiability.onOpen();
    } else {
      // Enable reversal of liability
      quoteUpdateReversalOfLiability(true);
    }
  }, [modalDisableReversalOfLiability, quote, quoteUpdateReversalOfLiability]);

  return (
    <>
      <SellsheetAmountSummaryCard
        hasReversalOfLiability={quote.getHasReversalOfLiability()}
        isEditable={isEditable}
        amountBeforeDiscountsExVat={CheckType.stringOrNull(quote.getAmountBeforeDiscountsExVat()) ?? '0'}
        amountAfterSaleDiscountExVat={CheckType.stringOrNull(quote.getAmountAfterSaleDiscountExVat()) ?? '0'}
        amountExVat={CheckType.stringOrNull(quote.getAmountExVat()) ?? '0'}
        amountIncVat={CheckType.stringOrNull(quote.getAmountIncVat()) ?? '0'}
        {...(discount && {
          salesDiscount: formatDiscount(discount, quote.getAmountBeforeDiscountsExVat()),
        })}
        {...(customDiscounts && {
          customDiscounts: Array.from(customDiscounts.values()).map((customDiscount) =>
            formatCustomDiscount(customDiscount, quote.getAmountAfterSaleDiscountExVat()),
          ),
        })}
        vatBases={
          quote.getVatBases().map((vatBase) => ({
            rate: vatBase.getVatRate().toString(),
            amount: vatBase.getAmount().toString(),
          })) ?? []
        }
        onAddSalesDiscountClick={saleDiscountModal.onOpen}
        onEditSalesDiscountClick={saleDiscountModal.onOpen}
        onRemoveSalesDiscountClick={quoteSalesDiscountDelete}
        onAddCustomDiscountClick={() => {
          const hasInitialValues = false;
          handleOpenCustomDiscountModal(hasInitialValues);
        }}
        onAddProrataClick={() => {
          const hasInitialValues = true;
          handleOpenCustomDiscountModal(hasInitialValues);
        }}
        onEditCustomDiscountClick={(customDiscountId) => {
          const hasInitialValues = false;
          handleOpenCustomDiscountModal(hasInitialValues, customDiscountId);
        }}
        onRemoveCustomDiscountClick={quoteCustomDiscountDelete}
        onSwitchReversalOfLiability={handleSwitchReversalOfLiability}
        onEditGlobalVatRate={handleUpdateDefaultVatRate}
      />

      <UpsertQuotationSaleDiscountModal isOpen={saleDiscountModal.isOpen} onClose={saleDiscountModal.onClose} />
      <UpsertQuotationCustomDiscountModal
        isOpen={customDiscountModal.isOpen}
        onClose={customDiscountModal.onClose}
        hasInitialValues={customDiscountState.hasInitialValues}
        customDiscountId={customDiscountState.customDiscountId}
      />
      <DisableQuotationReversalOfLiabilityModal
        modalControls={modalDisableReversalOfLiability}
        disableReversalOfLiability={() => quoteUpdateReversalOfLiability(false)}
      />
    </>
  );
};
