import type { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PERMISSION } from '@graneet/business-logic';

import { HoldbacksRouter } from './holdbacks/HoldbacksRouter';
import { StatementsRouter } from './statements/StatementsRouter';
import { ViewBillsScreen } from './bills/ViewBillsScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { usePermissions } from 'features/role/hooks/usePermissions';

export const SalesRouter: FC = () => {
  const { path } = useRouteMatch();

  const canSeeStatementModule = usePermissions([PERMISSION.ACCESS_STATEMENTS]);

  if (!canSeeStatementModule) {
    return null;
  }

  return (
    <Switch>
      <Route path={`${path}/bills`}>
        <QueryWrapper>
          <ViewBillsScreen />
        </QueryWrapper>
      </Route>

      <Route path={`${path}/holdbacks`}>
        <HoldbacksRouter />
      </Route>

      <Route path={`${path}/statements`}>
        <StatementsRouter />
      </Route>
    </Switch>
  );
};
