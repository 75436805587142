import { HStack, Text } from '@chakra-ui/react';
import { Tooltip } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Warning: FC<{ align: string; height?: string }> = ({ align = 'center', height = '44px' }) => {
  const { t } = useTranslation(['global']);

  return (
    <HStack height={height} width="100%" justifyContent={align} background="transparent" px={2} alignItems="center">
      <Tooltip label={t('global:form.errors.required')} shouldWrapChildren placement="left">
        <Text color="orange.500">
          <i className="ri-error-warning-line" />
        </Text>
      </Tooltip>
      <Text>-</Text>
    </HStack>
  );
};
