import { Flex, Box, HStack } from '@chakra-ui/react';
import { useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

interface AgGridQuoteComponentsByTypeFooterProps {
  itemWithoutComponentAmount: string;
  totalFlatCostAmount: string;
}

export const AgGridQuoteComponentsByTypeFooter = ({
  itemWithoutComponentAmount,
  totalFlatCostAmount,
}: AgGridQuoteComponentsByTypeFooterProps) => {
  const { t } = useTranslation(['quote']);
  const { formatAsStringAmount } = useCurrency();

  return (
    <Flex width="100%" height="100%" alignItems="center" gap="0.75rem">
      <HStack>
        <Box>{t('quote:quotation.modals.flatCost.itemWithoutComponent')}</Box>
        <Box fontWeight="bold">{formatAsStringAmount(itemWithoutComponentAmount)}</Box>
      </HStack>
      <HStack>
        <Box>{t('quote:quotation.modals.flatCost.totalFlatCost')}</Box>
        <Box fontWeight="bold">{formatAsStringAmount(totalFlatCostAmount)}</Box>
      </HStack>
    </Flex>
  );
};
