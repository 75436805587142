import { Flex } from '@chakra-ui/react';
import type { SummaryBarItem } from '@graneet/lib-ui';
import { SummaryBar, useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { useQuoteSettingsGetFromStoreOrThrow } from 'features/quotation/quote-common/hooks/useQuoteSettingsGetFromStore';
import { updateQuoteSummaryBarItemDisplayed } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useEditUserSettings } from 'features/user/services/user.api';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export type QuoteSummaryDisplayItems = {
  isDisplayed: boolean;
  name:
    | 'flatCostAmount'
    | 'shareOfHiddenCostAmount'
    | 'optionalAmount'
    | 'overheadCosts'
    | 'profitMargin'
    | 'totalMargin'
    | 'amountExVat'
    | 'amountIncVat'
    | 'workforce';
}[];

export const QuoteSummaryBar = () => {
  const { t } = useTranslation(['quote']);
  const { formatAsAmount, formatter } = useCurrency();
  const { quoteSummaryBarItemDisplayed } = useQuoteSettingsGetFromStoreOrThrow();

  const { quote } = useQuote();

  const editUserSettingsMutation = useEditUserSettings(false);

  const items = useMemo(() => {
    const tree = quote.getTree();
    const rootNodeId = tree.getRootNode().getId();
    const rootLot = tree.getQuoteLotOrThrow(rootNodeId);

    return quoteSummaryBarItemDisplayed.map((item): SummaryBarItem => {
      switch (item.name) {
        case 'flatCostAmount':
          return {
            category: t('quote:summaryBar.flatCost'),
            isDisplayed: item.isDisplayed,
            label: t('quote:summaryBar.flatCostAmount'),
            name: item.name,
            value: formatAsAmount(rootLot.getFlatCostAmount()?.toNumber() || 0),
          };
        case 'shareOfHiddenCostAmount':
          return {
            category: t('quote:summaryBar.flatCost'),
            isDisplayed: item.isDisplayed,
            label: t('quote:summaryBar.shareOfHiddenCostAmount'),
            name: item.name,
            value: formatAsAmount(rootLot.getShareOfHiddenCostAmount()?.toNumber() || 0),
          };
        case 'optionalAmount':
          return {
            category: t('quote:summaryBar.flatCost'),
            isDisplayed: item.isDisplayed,
            label: t('quote:summaryBar.optionalAmount'),
            name: item.name,
            value: formatAsAmount(rootLot.getOptionalAmount()?.toNumber() || 0),
          };
        case 'overheadCosts':
          return {
            category: t('quote:summaryBar.margin'),
            isDisplayed: item.isDisplayed,
            label: t('quote:summaryBar.overheadCosts'),
            name: item.name,
            value: quote.getMargin().getOverheadCosts().toFixed(2),
          };
        case 'profitMargin':
          return {
            category: t('quote:summaryBar.margin'),
            isDisplayed: item.isDisplayed,
            label: t('quote:summaryBar.profitMargin'),
            name: item.name,
            value: quote.getMargin().getProfitMargin().toFixed(2),
          };
        case 'totalMargin':
          return {
            category: t('quote:summaryBar.margin'),
            isDisplayed: item.isDisplayed,
            label: t('quote:summaryBar.totalMargin'),
            name: item.name,
            value: quote.getMargin().getTotalMargin().toFixed(2),
          };
        case 'amountExVat':
          return {
            category: t('quote:summaryBar.amount'),
            isDisplayed: item.isDisplayed,
            label: t('quote:summaryBar.amountExVat'),
            name: item.name,
            value: formatAsAmount(quote.getAmountExVat()?.toNumber() || 0),
          };
        case 'amountIncVat':
          return {
            category: t('quote:summaryBar.amount'),
            isDisplayed: item.isDisplayed,
            label: t('quote:summaryBar.amountIncVat'),
            name: item.name,
            value: formatAsAmount(quote.getAmountIncVat()?.toNumber() || 0),
          };
        case 'workforce':
          return {
            category: t('quote:summaryBar.flatCost'),
            isDisplayed: item.isDisplayed,
            label: t('quote:summaryBar.workforce'),
            name: item.name,
            value: `${formatter({ minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(rootLot.getWorkforce()?.toString() || '0'))} h`,
          };
        default:
          throw new Error(`Unknown summary bar item name`);
      }
    });
  }, [formatAsAmount, formatter, quote, quoteSummaryBarItemDisplayed, t]);

  const handleOnSummaryBarChange = async (name: string, checked: boolean) => {
    const quoteSummaryBarItemDisplayedJSON = JSON.stringify(
      quoteSummaryBarItemDisplayed.map((item) => (item.name === name ? { name, isDisplayed: checked } : item)),
    );
    await editUserSettingsMutation.mutateAsync({ quoteSummaryBarItemDisplayedJSON });
    updateQuoteSummaryBarItemDisplayed(quoteSummaryBarItemDisplayedJSON);
  };

  if (!items) {
    return null;
  }

  return (
    <Flex width="100%" height="44px" alignItems="center">
      <SummaryBar
        items={items}
        onChange={handleOnSummaryBarChange}
        tooltip={t('quote:quotation.tooltips.summarySettings')}
      />
    </Flex>
  );
};
