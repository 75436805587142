import type { FC } from 'react';
import { useMemo } from 'react';
import { DeepTable, formatNumber, PriceAdvanced, RichText, EMPTY_SIGN, roundFloatingTo } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { IQuoteComponent } from '@graneet/business-logic';
import {
  computeQuoteComponentDataByQuantity,
  computeQuoteComponentUnitAmountExVAT,
  isNumberFinite,
} from '@graneet/business-logic';
import { useTheme } from '@chakra-ui/react';

import type { QuoteExportForm } from '../../forms/quote-export-edition.wizard';
import { EXPORT_COLUMNS } from '../../constants/quote-export.constant';
import { countSelectedColumns } from '../../services/quote-export.util';
import { useQuoteExportJob } from '../../hooks/useQuoteExportTree';

interface QuoteExportJobComponentRowProps {
  depth: number;

  parentId: number;

  component: IQuoteComponent;

  isOptional: boolean | undefined;
}

export const QuoteExportJobComponentRow: FC<QuoteExportJobComponentRowProps> = ({
  depth,
  parentId,
  component,
  isOptional,
}) => {
  const theme = useTheme();
  const quoteExportForm = useFormContext<QuoteExportForm>();
  const { exportItems: columns } = useOnChangeValues(quoteExportForm, ['exportItems']);

  const { leaf: job } = useQuoteExportJob(parentId);

  const nbColumns = useMemo(() => countSelectedColumns(columns), [columns]);

  if (nbColumns === 0 || !columns) return null;
  if (!component) return null;

  const {
    quantity: componentQuantity,
    code,
    name: description,
    unit,
    margin,
    unitDisbursementExVAT,
    disbursementExVAT: componentDisbursementExVAT,
    amountExVAT: componentAmountExVAT,
    componentType,
  } = component;

  const quantity = computeQuoteComponentDataByQuantity(componentQuantity, job.quantity);
  const disbursementExVAT = computeQuoteComponentDataByQuantity(componentDisbursementExVAT, job.quantity);
  const amountExVAT = computeQuoteComponentDataByQuantity(componentAmountExVAT, job.quantity);

  const unitPriceExVAT = computeQuoteComponentUnitAmountExVAT(unitDisbursementExVAT, margin);

  return (
    <DeepTable.Row
      offset={depth * 0.5}
      backgroundColor="white"
      color="gray.600"
      marginLeft={2}
      borderLeft={isOptional ? `2px solid ${theme.colors.purple[100]}` : ''}
    >
      {!!columns[EXPORT_COLUMNS.REF_CODE] && <DeepTable.Cell variant="ellipsis">{code}</DeepTable.Cell>}
      <DeepTable.Cell>
        <RichText value={description} />
      </DeepTable.Cell>
      {!!columns[EXPORT_COLUMNS.UNIT] && <DeepTable.Cell right>{unit}</DeepTable.Cell>}
      {!!columns[EXPORT_COLUMNS.QUANTITY] && (
        <DeepTable.Cell right>{isNumberFinite(quantity) && formatNumber(quantity)}</DeepTable.Cell>
      )}
      {!!columns[EXPORT_COLUMNS.UNIT_DISBURSEMENT_EX_VAT] && (
        <DeepTable.Cell right>
          <PriceAdvanced amount={unitDisbursementExVAT} />
        </DeepTable.Cell>
      )}
      {!!columns[EXPORT_COLUMNS.DISBURSEMENT_EX_VAT] && (
        <DeepTable.Cell right>
          <PriceAdvanced amount={disbursementExVAT} />
        </DeepTable.Cell>
      )}
      {!!columns[EXPORT_COLUMNS.JOB_MARGIN] && (
        <DeepTable.Cell right>{formatNumber(margin?.totalMargin)}</DeepTable.Cell>
      )}
      {!!columns[EXPORT_COLUMNS.UNIT_PRICE_EX_VAT] && (
        <DeepTable.Cell right>
          <PriceAdvanced amount={unitPriceExVAT} />
        </DeepTable.Cell>
      )}
      {!!columns[EXPORT_COLUMNS.JOB_VAT] && <DeepTable.Cell right>{EMPTY_SIGN}</DeepTable.Cell>}
      {!!columns[EXPORT_COLUMNS.WORK_FORCE] && (
        <DeepTable.Cell right>
          {componentType?.isWorkforce && isNumberFinite(quantity)
            ? `${formatNumber(roundFloatingTo(quantity, 2))} h`
            : '-'}
        </DeepTable.Cell>
      )}
      {!!columns[EXPORT_COLUMNS.AMOUNT_EX_VAT] && (
        <DeepTable.Cell right>
          <PriceAdvanced amount={amountExVAT} />
        </DeepTable.Cell>
      )}
    </DeepTable.Row>
  );
};
