import type { FC } from 'react';
import { useMemo } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/react';

export const MultiplicationIcon: FC<IconProps> = ({ color, ...iconProps }) => {
  const theme = useTheme();
  const fill = useMemo(() => color ?? theme.colors.gray['500'], [color, theme]);

  return (
    <Icon viewBox="0 0 32 32" {...iconProps}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24.5272 9.63377L17.8602 16.3008L24.5272 22.9678L22.3049 25.1901L15.6379 18.5231L8.97089 25.1901L6.74855 22.9678L13.4156 16.3008L6.74855 9.63377L8.97089 7.41144L15.6379 14.0784L22.3049 7.41144L24.5272 9.63377Z"
          fill={fill}
        />
      </svg>
    </Icon>
  );
};
