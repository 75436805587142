import type { FC, ReactNode } from 'react';
import { DeepTable, RequiredText } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type { OrderEditForm } from '../../../forms/order-edit-wizard';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface RequiredTextWrapperProps {
  children: ReactNode;
}

const RequiredTextWrapper: FC<RequiredTextWrapperProps> = ({ children }) => {
  const form = useFormContext<OrderEditForm>();
  const { hasUnitPrices } = useOnChangeValues(form, ['hasUnitPrices']);

  if (hasUnitPrices) {
    return <RequiredText>{children}</RequiredText>;
  }

  return <>{children}</>;
};

export const OrderDeepTableHeader: FC = () => {
  const { t } = useTranslation(['orders']);

  const hasTypeFF = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_ORDER_ITEM_COMPONENT_TYPE);

  return (
    <DeepTable.Header bg="white">
      <DeepTable.Cell />

      <DeepTable.Cell />

      <DeepTable.Cell>{t('orders:fields.orderItems.code')}</DeepTable.Cell>

      <DeepTable.Cell>
        <RequiredText>{t('orders:fields.orderItems.description')}</RequiredText>
      </DeepTable.Cell>

      <DeepTable.Cell>
        <RequiredTextWrapper>{t('orders:fields.orderItems.unit')}</RequiredTextWrapper>
      </DeepTable.Cell>

      <DeepTable.Cell>
        <RequiredTextWrapper>{t('orders:fields.orderItems.quantity')}</RequiredTextWrapper>
      </DeepTable.Cell>

      <DeepTable.Cell>
        <RequiredTextWrapper>{t('orders:fields.orderItems.unitPriceExVAT')}</RequiredTextWrapper>
      </DeepTable.Cell>

      <DeepTable.Cell>
        <RequiredTextWrapper>{t('orders:fields.orderItems.vatRate')}</RequiredTextWrapper>
      </DeepTable.Cell>

      <DeepTable.Cell>{t('orders:fields.orderItems.amountExVAT')}</DeepTable.Cell>

      {hasTypeFF && (
        <DeepTable.Cell>
          <RequiredTextWrapper>{t('orders:fields.orderItems.disbursement')}</RequiredTextWrapper>
        </DeepTable.Cell>
      )}

      <DeepTable.Cell />
    </DeepTable.Header>
  );
};
