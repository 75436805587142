/* eslint-disable no-nested-ternary */
import type { BackgroundProps, ButtonProps, IconProps } from '@chakra-ui/react';
import { Box, Button, HStack } from '@chakra-ui/react';
import type { MouseEventHandler, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { cloneElement, forwardRef } from 'react';

import { Tooltip } from '../../Tooltip';

export type GraneetButtonVariant =
  | 'red_ghost'
  | 'yellow'
  | 'primary'
  | 'secondary'
  | 'secondary_danger'
  | 'secondary_darker'
  | 'danger'
  | 'black'
  | 'ghost'
  | 'red'
  | 'secondary_ghost'
  | 'black_ghost';

export interface GraneetButtonProps extends PropsWithChildren, Omit<ButtonProps, 'leftIcon' | 'rightIcon'> {
  variant?: GraneetButtonVariant;
  textColor?: string;
  isDisabled?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  tooltip?: ReactNode;
  leftIcon?: ReactElement<IconProps> | string | null;
  rightIcon?: ReactElement<IconProps> | string;
  width?: 'sm' | 'md' | 'lg' | 'full';
  size?: 'sm' | 'md' | 'lg';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  backgroundColor?: BackgroundProps['background'];
  type?: 'button' | 'submit';
}

export const WIDTH_CONFIG = {
  sm: '2.5rem',
  md: '5rem',
  lg: '10rem',
  full: '100%',
} as const;

export const HEIGHT_CONFIG = {
  sm: '1.75rem',
  md: '2rem',
  lg: '2.5rem',
} as const;

export const COLORS_CONFIG = {
  yellow: {
    color: '#15181A',
    background: '#F4EC7C',
    border: '1px solid #EEE130',
    hover: {
      border: '1px solid #F7F1A1',
      background: '#F7F1A1',
      boxShadow: '0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
    },
    active: {
      boxShadow:
        '0px 0px 0px 3px rgba(78, 121, 130, 0.50), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
    },
    shadow: '0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
  },
  primary: {
    color: 'white',
    background: 'greenBrand.light',
    border: '1px solid #1E363B',
    hover: {
      border: '1px solid #4E7982',
      background: 'greenBrand.lighter',
      boxShadow: '0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
    },
    active: {
      boxShadow: '0 0 0 0 rgba(21,24,26,.32)',
    },
    shadow: '0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
  },
  secondary: {
    color: '#15181A',
    background: 'linear-gradient(180deg, #FCFCFD 0%, #F9FAFB 100%)',
    border: '1px solid #E7E5E4',
    hover: {
      border: '1px solid transparent',
      background: '#F3F4F6',
      backgroundBlendMode: 'normal, plus-darker',
      boxShadow:
        '0px 2px 4px 0px rgba(17, 24, 28, 0.04), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px rgba(17, 24, 28, 0.08)',
    },
    active: {
      boxShadow: '0 0 0 0 rgba(21,24,26,.32)',
    },
    shadow: '0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
  },
  secondary_danger: {
    color: 'red.500',
    background: 'linear-gradient(180deg, #FCFCFD 0%, #F9FAFB 100%)',
    border: '1px solid #E7E5E4',
    hover: {
      border: '1px solid transparent',
      background: '#F3F4F6',
      backgroundBlendMode: 'normal, plus-darker',
      boxShadow:
        '0px 2px 4px 0px rgba(17, 24, 28, 0.04), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px rgba(17, 24, 28, 0.08)',
    },
    active: {
      boxShadow: '0 0 0 0 rgba(21,24,26,.32)',
    },
    shadow: '0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
  },
  secondary_darker: {
    color: '#15181A',
    background: 'linear-gradient(180deg, #FCFCFD 0%, #F9FAFB 100%)',
    border: '1px solid #E7E5E4',
    hover: {
      border: '1px solid transparent',
      background: '#E0DFDC',
      backgroundBlendMode: 'normal, plus-darker',
      boxShadow:
        '0px 2px 4px 0px rgba(17, 24, 28, 0.04), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px rgba(17, 24, 28, 0.08)',
    },
    active: {
      boxShadow:
        '0px 0px 0px 3px rgba(78, 121, 130, 0.50), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
    },
    shadow: '0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
  },
  danger: {
    color: 'white',
    background: 'red.500',
    border: '1px solid #DC2626',
    hover: {
      border: '1px solid #F87171',
      background: 'red.400',
      boxShadow: 'none',
    },
    active: {
      boxShadow:
        '0px 0px 0px 2px rgba(220, 38, 38, 0.50), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
    },
    shadow: '0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
  },
  black: {
    color: 'white',
    background: '#1F2A37',
    border: '1px solid rgba(255, 255, 255, 0.24)',
    hover: {
      border: '1px solid rgba(255, 255, 255, 0.24)',
      background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #1F2A37',
      boxShadow:
        '0px 2px 4px 0px rgba(17, 24, 28, 0.04), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px #0E0D1C',
    },
    active: {},
    shadow: '0px 2px 4px 0px rgba(17, 24, 28, 0.04), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px #0E0D1C',
  },
  ghost: {
    color: '#15181A',
    background: 'transparent',
    border: '1px solid transparent',
    hover: {
      background: 'gray.100',
      backgroundBlendMode: 'plus-darker',
    },
    active: {
      boxShadow: '0px 0px 0px 2px rgba(78, 121, 130, 0.50)',
    },
    shadow: 'none',
  },
  red_ghost: {
    color: 'red.500',
    background: 'transparent',
    border: '1px solid transparent',
    hover: {
      background: 'gray.100',
      backgroundBlendMode: 'plus-darker',
    },
    active: {
      boxShadow: '0px 0px 0px 2px rgba(220, 38, 38, 0.50)',
    },
    shadow: 'none',
  },
  secondary_ghost: {
    color: '#6C737F',
    background: 'transparent',
    border: '1px solid transparent',
    hover: {
      background: 'gray.100',
      backgroundBlendMode: 'plus-darker',
    },
    active: {
      boxShadow: '0px 0px 0px 2px rgba(220, 38, 38, 0.50)',
    },
    shadow: 'none',
  },
  black_ghost: {
    color: '#FFF',
    background: 'transparent',
    border: '1px solid transparent',
    hover: {
      color: 'primaryLight',
      background: 'gray.100',
      backgroundBlendMode: 'plus-darker',
    },
    active: {
      boxShadow: '0px 0px 0px 2px rgba(220, 38, 38, 0.50)',
    },
    shadow: 'none',
  },
  red: {
    color: 'red.500',
    background: 'linear-gradient(180deg, #FCFCFD 0%, #F9FAFB 100%)',
    border: '1px solid #E7E5E4',
    hover: {
      border: '1px solid transparent',
      background: '#F3F4F6',
      backgroundBlendMode: 'normal, plus-darker',
      boxShadow:
        '0px 2px 4px 0px rgba(17, 24, 28, 0.04), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px rgba(17, 24, 28, 0.08)',
    },
    active: {
      boxShadow: '0px 0px 0px 2px rgba(220, 38, 38, 0.50)',
    },
    shadow: '0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
  },
} as const;

export const GraneetButton = forwardRef<HTMLButtonElement, GraneetButtonProps>(
  (
    {
      variant = 'secondary',
      textColor,
      isDisabled,
      isActive,
      children,
      isLoading,
      tooltip,
      leftIcon,
      rightIcon,
      width = 'sm',
      size = 'md',
      backgroundColor,
      onClick,
      type = 'button',
      ...rest
    },
    ref,
  ) => {
    const { color: themeTextColor, background, border, hover, active: activeStyle, shadow } = COLORS_CONFIG[variant];

    const strokeColor = variant === 'secondary' ? (textColor ?? themeTextColor) : themeTextColor;

    const height = HEIGHT_CONFIG[size];

    const leftIconElement = leftIcon ? (
      typeof leftIcon === 'string' ? (
        <Box fontSize="1rem">
          <i className={leftIcon} />
        </Box>
      ) : (
        cloneElement(leftIcon, { boxSize: '1rem', stroke: strokeColor })
      )
    ) : null;
    const rightIconElement = rightIcon ? (
      typeof rightIcon === 'string' ? (
        <Box fontSize="1rem">
          <i className={rightIcon} />
        </Box>
      ) : (
        cloneElement(rightIcon, { boxSize: '1rem', stroke: strokeColor })
      )
    ) : null;

    return (
      <Tooltip label={tooltip}>
        <Button
          ref={ref}
          px="12px"
          pr={rightIconElement ? '0.5rem' : '12px'}
          pl={leftIconElement ? '0.5rem' : '12px'}
          minWidth={WIDTH_CONFIG[width]}
          fontFamily="body"
          fontSize="0.875rem"
          borderRadius="0.5rem"
          fontWeight={500}
          fontStyle="normal"
          transition="box-shadow 0.1s ease-in-out"
          height={height}
          isActive={isActive}
          isDisabled={isDisabled}
          isLoading={isLoading}
          color={strokeColor}
          boxShadow={shadow}
          border={border}
          background={backgroundColor ?? background}
          _hover={hover}
          _active={activeStyle}
          onClick={onClick}
          type={type}
          {...rest}
        >
          <HStack spacing={2} alignItems="center">
            {leftIconElement}
            {children && <>{children}</>}
            {rightIconElement}
          </HStack>
        </Button>
      </Tooltip>
    );
  },
);
