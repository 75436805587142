import type { STATEMENT_TYPES, StatementPaginatedResponse } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { type UsePaginationOptions, usePaginationQuery } from '@graneet/lib-ui';

import { STATEMENT_FACTORY_NAME } from './statement.factory-name';

import { api } from 'features/api/services/api.service';
import { apiNew } from 'features/api/services/apiNew.service';

const STATEMENT_PATH = '/statements';

const statementKeyFactory = createQueryKeys(STATEMENT_FACTORY_NAME, {
  get: (queryParams: URLSearchParams) => ({
    queryKey: [STATEMENT_PATH, queryParams],
    queryFn: () => apiNew.get<URLSearchParams, StatementPaginatedResponse>(STATEMENT_PATH, queryParams),
  }),
  getAvailableTags: () => ({
    queryKey: [STATEMENT_PATH],
    queryFn: () => apiNew.get<never, string[]>(`${STATEMENT_PATH}/tags`),
  }),
});

export function useStatements(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) => queryClient.fetchQuery(statementKeyFactory.get(urlSearchParams)),
    options,
  );
}

export function useStatementsQuery(
  queryParams: URLSearchParams,
  options?: {
    enabled: boolean;
  },
) {
  return useQuery({ ...statementKeyFactory.get(queryParams), ...options });
}

export function useStatementsAvailableTags() {
  return useSuspenseQuery(statementKeyFactory.getAvailableTags());
}

export const getAllStatementsPaginated = (queryParams: URLSearchParams) =>
  api.get<URLSearchParams, StatementPaginatedResponse>(STATEMENT_PATH, queryParams);

export const testInvoiceNumberAvailability = (
  invoiceNumber: string,
  statementIdExcepted?: number,
  type?: STATEMENT_TYPES,
) =>
  api.get<{ invoiceNumber: string; statementIdExcepted?: number; type?: STATEMENT_TYPES }, boolean>(
    `${STATEMENT_PATH}/availability`,
    { invoiceNumber, statementIdExcepted, type },
  );
