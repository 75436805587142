import { Card, formatDateOrEmpty } from '@graneet/lib-ui';
import type { HISTORY_ENTITY_TYPE } from '@graneet/business-logic';
import { TRACKING_EVENT_ENUM } from '@graneet/business-logic';
import type { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import type { MouseEvent } from 'react';

import { entityTabsConfig } from '../services/history.service';
import { useSegmentAnalytics } from '../../analytic/components/SegmentProvider';

import type { IArchiveDetailsExtended } from './HistoryCard';

import { formatFileName, mapPdfToFileItemProps } from 'features/file/services/file.util';
import { FilesCard } from 'features/file/components/FilesCard/FilesCard';
import type { FileItemProps } from 'features/file/components/FilesCard/components/FileItem';

const mapToFileItemProps = (
  archive: IArchiveDetailsExtended,
  entity: HISTORY_ENTITY_TYPE,
  t: TFunction<['global']>,
  handleClick: (event: MouseEvent) => void,
) => {
  const { translatedGender } = entityTabsConfig[entity];
  const { date, invoiceNumber, isProforma } = entityTabsConfig[entity].getArchiveFile(
    archive as IArchiveDetailsExtended,
  );
  return {
    ...mapPdfToFileItemProps(archive.pdf, formatFileName(archive.name || '', date, invoiceNumber)),
    archived: true,
    name: archive.name || '',
    createdAt: t('global:creationDate', {
      date: formatDateOrEmpty(archive.createdAt),
      context: translatedGender,
    }),
    isProforma,
    onClick: handleClick,
  };
};

interface ArchivedCardProps {
  addActionsProps?: (archiveDetails: IArchiveDetailsExtended) => Pick<FileItemProps, 'rightContent'> | null;
  archiveDetails: IArchiveDetailsExtended;
  entity: HISTORY_ENTITY_TYPE;
}

export const ArchivedCard = ({ addActionsProps, archiveDetails, entity }: ArchivedCardProps) => {
  const { t } = useTranslation(['global']);
  const segmentAnalytics = useSegmentAnalytics();

  const handleClick = (event: MouseEvent) => {
    const href = event.currentTarget.getAttribute('href');
    if (href && href.includes('/download')) {
      segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.HISTORY_ATTACHMENT_DOWNLOAD, null);
    }
  };

  return (
    <Card boxShadow="default" rounded={4} py={0}>
      <FilesCard.FileItem
        {...(addActionsProps ? addActionsProps(archiveDetails) : {})}
        {...mapToFileItemProps(archiveDetails, entity, t, handleClick)}
      />
    </Card>
  );
};
