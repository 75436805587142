import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';
import { Button, Tooltip, SimpleHelpIcon, useCurrency, SimpleCheckCircleIcon, SimpleRepeatIcon } from '@graneet/lib-ui';
import type { IDownPayment, ISubProject } from '@graneet/business-logic';
import { getDownPaymentProrataAmortization } from '@graneet/business-logic';
import { useFormContext, useWizardContext, useOnChangeValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';

import { useCumulativeProgressExVAT } from 'features/progress-statement/hooks/useCumulativeProgressExVAT';
import type { ProgressStatementEditWizard } from 'features/progress-statement/forms/progress-statement-edit-wizard';
import type { ProgressStatementDownPaymentForm } from 'features/progress-statement/forms/progress-statement-down-payment-form';

interface DownPaymentProrataButtonProps {
  subProject: ISubProject;
  downPayment: IDownPayment;
}

export const DownPaymentProrataButton: FC<DownPaymentProrataButtonProps> = ({ subProject, downPayment }) => {
  const { t } = useTranslation(['progressStatement']);
  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();

  const [isAmortizedProportionally, setIsAmortizedProportionally] = useState(false);

  const form = useFormContext<ProgressStatementDownPaymentForm>();
  const { setFormValues } = form;
  const { downPaymentAmortizationAmount } = useOnChangeValues(form, ['downPaymentAmortizationAmount']);

  const { amountExVAT: totalSubProjectAmountExVAT } = subProject;
  const { amountIncVATAmortized: downPaymentAmountIncVATAmortized, amountIncVAT: downPaymentAmountIncVAT } =
    downPayment;

  const { getValuesOfStep } = useWizardContext<ProgressStatementEditWizard>();
  // We compute the new cumulative project progress based on values in item step
  const itemsValues = getValuesOfStep('items')!;
  const cumulativeSubProjectAmountExVAT = mapAmountToNumber(useCumulativeProgressExVAT(itemsValues));

  const setDownPaymentAmortizationAmountProportionally = useCallback(() => {
    const nextDownPaymentAmortizationAmount = getDownPaymentProrataAmortization({
      cumulativeSubProjectAmountExVAT,
      totalSubProjectAmountExVAT,
      downPaymentAmountIncVAT,
      downPaymentAmountIncVATAmortized,
    });
    setFormValues({
      downPaymentAmortizationAmount: mapNumberToAmount(nextDownPaymentAmortizationAmount),
    });
  }, [
    cumulativeSubProjectAmountExVAT,
    totalSubProjectAmountExVAT,
    downPaymentAmountIncVAT,
    downPaymentAmountIncVATAmortized,
    setFormValues,
    mapNumberToAmount,
  ]);

  useEffect(() => {
    // when the value of the input change, we compute the theoretical prorata down payment amortization
    // in order to determine whether we should show the button or the success label
    const nextDownPaymentAmortizationAmount = getDownPaymentProrataAmortization({
      cumulativeSubProjectAmountExVAT,
      totalSubProjectAmountExVAT,
      downPaymentAmountIncVAT,
      downPaymentAmountIncVATAmortized,
    });
    setIsAmortizedProportionally(
      mapNumberToAmount(nextDownPaymentAmortizationAmount) === downPaymentAmortizationAmount,
    );
  }, [
    cumulativeSubProjectAmountExVAT,
    downPaymentAmountIncVAT,
    downPaymentAmountIncVATAmortized,
    mapNumberToAmount,
    downPaymentAmortizationAmount,
    totalSubProjectAmountExVAT,
  ]);

  return (
    <Flex mt={6} alignItems="center">
      {isAmortizedProportionally ? (
        <Flex alignItems="center" py={2}>
          <SimpleCheckCircleIcon stroke="greenBrand.light" mr="0.375rem" />
          <Text>{t('progressStatement:isCreating.downPaymentStep.prorataSuccess')}</Text>
        </Flex>
      ) : (
        <Button
          variant="outline"
          leftIcon={<SimpleRepeatIcon />}
          onClick={setDownPaymentAmortizationAmountProportionally}
        >
          {t('progressStatement:isCreating.downPaymentStep.prorataButton')}
        </Button>
      )}
      <Tooltip label={t('progressStatement:isCreating.downPaymentStep.prorataButtonHelper')} placement="right-end">
        <Box ml={4}>
          <SimpleHelpIcon boxSize={5} />
        </Box>
      </Tooltip>
    </Flex>
  );
};
