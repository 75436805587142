import type { FC } from 'react';
import { useEffect, useCallback } from 'react';
import { Modal, useToast } from '@graneet/lib-ui';
import { useForm, useFormStatus } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';

import type { ContactForm } from '../forms/ContactUpsertForm';
import { ContactUpsertForm } from '../forms/ContactUpsertForm';
import { QueryWrapper } from '../../../api/components/QueryWrapper';

import { useContactCreate, useContactUpdate } from 'features/contact/services/contact.api';

interface ContactUpsertModalProps {
  isOpen: boolean;

  onClose: () => void;

  onSuccess: (contact: IContact, data: { isDefaultEmailRecipient?: boolean }) => void;

  contact: Omit<IContact, 'updatedAt' | 'createdAt'> | null;

  roles: string[];

  hasIsDefaultEmailRecipient: boolean;
}

export const ContactUpsertModal: FC<ContactUpsertModalProps> = ({
  isOpen,
  onSuccess,
  onClose,
  contact,
  roles,
  hasIsDefaultEmailRecipient,
}) => {
  const { t } = useTranslation(['global', 'contacts']);
  const toast = useToast();

  const form = useForm<ContactForm>();
  const { isValid: isFormValid } = useFormStatus(form);

  useEffect(() => {
    if (!isOpen) {
      form.resetForm();
    }
  }, [form, isOpen]);

  const contactCreateMutation = useContactCreate();
  const contactUpdateMutation = useContactUpdate();

  const onSubmit = useCallback(async () => {
    const { firstName, lastName, role, email, phoneNumber, isDefaultEmailRecipient } = form.getFormValues();

    const body = {
      firstName: firstName!,
      lastName: lastName!,
      role: role ?? undefined,
      email: email ?? undefined,
      phoneNumber: phoneNumber!,
    };

    if (contact) {
      await contactUpdateMutation.mutateAsync(
        { id: contact.id, dto: body },
        {
          onSuccess: (response) => {
            toast.success(
              t('contacts:editionModal.toastSuccess', {
                firstName: response.firstName,
                lastName: response.lastName,
              }),
            );
            onSuccess(response, { isDefaultEmailRecipient });
          },
        },
      );
    } else {
      await contactCreateMutation.mutateAsync(body, {
        onSuccess: (response) => {
          toast.success(
            t('contacts:creationModal.toastSuccess', {
              firstName: response.firstName,
              lastName: response.lastName,
            }),
          );
          onSuccess(response, { isDefaultEmailRecipient: !!isDefaultEmailRecipient });
        },
      });
    }

    onClose();
  }, [form, contact, onClose, contactUpdateMutation, toast, t, onSuccess, contactCreateMutation]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={contact ? t('contacts:editionModal.title') : t('contacts:creationModal.title')}
      size="2xl"
      isCentered
      scrollBehavior="inside"
    >
      <QueryWrapper>
        <ContactUpsertForm
          contact={contact}
          roles={roles}
          form={form}
          hasIsDefaultEmailRecipient={!contact && hasIsDefaultEmailRecipient} // Do not display hasIsDefaultEmailRecipient if its upsert
        />
        <Modal.Close />
        <Modal.PrimaryButton
          isLoading={contactCreateMutation.isPending || contactUpdateMutation.isPending}
          isDisabled={!isFormValid}
          onClick={onSubmit}
        >
          {contact ? t('contacts:editionModal.action') : t('contacts:creationModal.action')}
        </Modal.PrimaryButton>
      </QueryWrapper>
    </Modal>
  );
};
