import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Onboarding, Button, Section, useToast, SimpleContactIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { SimpleGrid, useDisclosure } from '@chakra-ui/react';
import type { IQuote, IClientResponseDTO, IClient, IContact } from '@graneet/business-logic';

import { updateQuoteClient } from 'features/quote/services/quote.api';
import { ClientContactCreateStepperModal } from 'features/client/components/modals/ClientContactCreateStepperModal/ClientContactCreateStepperModal';
import {
  ClientCardContactAssociateType,
  ClientInformationsTab,
} from 'screens/contacts/clients/_tabs/information/ClientInformationsTab';
import { ClientCardTypeEnum } from 'features/client/components/cards/ClientCardDetails';
import {
  ClientContactSelectionStepperModal,
  type ClientContactSelectionStepperModalProps,
} from 'features/client/components/modals/ClientExistingAssociateContactStepperModal/ClientContactSelectionStepperModal';
import {
  getContactAvailableForQuote,
  useContactDissociateAllFromQuote,
  useContactsAssociateToQuote,
} from 'features/contact/services/contact.api';

interface EditQuoteClientStepProps {
  quote: IQuote;
  reloadQuote(): void;
}

export const EditQuoteClientStep: FC<EditQuoteClientStepProps> = ({ quote, reloadQuote }) => {
  const { t } = useTranslation(['quote', 'clients', 'global', 'contacts']);
  const toast = useToast();

  const contactsAssociateToQuoteMutation = useContactsAssociateToQuote();
  const contactDissociateAllFromQuoteMutation = useContactDissociateAllFromQuote();

  const selectionModal = useDisclosure();
  const creationModal = useDisclosure();
  const {
    onOpen: onAssociateExistingOpen,
    onClose: onAssociateExistingClose,
    isOpen: isAssociateExistingOpen,
  } = useDisclosure();

  const [currentClient, setCurrentClient] = useState<IClientResponseDTO | IClient | undefined>(
    quote.client ? (quote.client as IClientResponseDTO) : undefined,
  );

  const updateClient = useCallback(
    async (clientIds: number[] | null) => {
      const clientId = clientIds?.[0] ?? null;
      const [err] = await updateQuoteClient(quote.id, clientId);
      if (err) {
        toast.error(t('quote:cards.client.error'));
      } else {
        toast.success(t(`quote:cards.client.${clientId ? 'successLink' : 'successUnlink'}`));
        selectionModal.onClose();
        reloadQuote();
      }
    },
    [quote, reloadQuote, selectionModal, t, toast],
  );

  const updateUniqueClient = useCallback(
    async (clientId: number | null) => {
      const [err, response] = await updateQuoteClient(quote.id, clientId);
      if (err) {
        toast.error(t('quote:cards.client.error'));
        return null;
      }
      toast.success(t(`quote:cards.client.${clientId ? 'successLink' : 'successUnlink'}`));
      return response.client ?? null;
    },
    [quote, t, toast],
  );

  const updateContactsAssociations = useCallback(async () => {
    await contactDissociateAllFromQuoteMutation.mutateAsync({ quoteId: quote.id });
    reloadQuote();
  }, [contactDissociateAllFromQuoteMutation, quote.id, reloadQuote]);

  const removeClientAndContactAssociation = useCallback(async () => {
    await updateClient(null);
    await updateContactsAssociations();
  }, [updateClient, updateContactsAssociations]);

  const onClientCreated = useCallback(
    async (clientResponse: IClientResponseDTO) => {
      const [err] = await updateQuoteClient(quote.id, clientResponse.id);
      if (err) {
        toast.error(t('quote:cards.client.error'));
      } else {
        setCurrentClient(clientResponse);
        toast.success(t(`quote:cards.client.${clientResponse.id ? 'successLink' : 'successUnlink'}`));
      }
    },
    [quote.id, t, toast],
  );

  const onContactCreated = useCallback(
    async (contact: IContact, data: { isDefaultEmailRecipient: boolean }) => {
      await contactsAssociateToQuoteMutation.mutateAsync({
        id: quote.id,
        dto: {
          contacts: [{ id: contact.id, isDefaultEmailRecipient: data.isDefaultEmailRecipient }],
        },
      });
    },
    [contactsAssociateToQuoteMutation, quote.id],
  );

  const onClientSelected = useCallback(
    async (clientId: number) => {
      const client = await updateUniqueClient(clientId);

      if (client) {
        setCurrentClient(client);
      }

      return client;
    },
    [updateUniqueClient],
  );

  const onAssociateModalClose = useCallback(async () => {
    reloadQuote();
    onAssociateExistingClose();
  }, [onAssociateExistingClose, reloadQuote]);

  const onCreateModalClose = useCallback(async () => {
    reloadQuote();
    creationModal.onClose();
  }, [creationModal, reloadQuote]);

  const onContactsSelected = useCallback(
    async (contacts: Array<{ entity: IContact; isDefaultEmailRecipient: boolean }>) => {
      await contactsAssociateToQuoteMutation.mutateAsync({
        id: quote.id,
        dto: {
          contacts: contacts.map((contact) => ({
            id: contact.entity.id,
            isDefaultEmailRecipient: contact.isDefaultEmailRecipient,
          })),
        },
      });

      if (contacts.length > 1) {
        toast.success(t('contacts:availableModal.toastSuccessManyAssociation', { nbContact: contacts.length }));
      } else {
        toast.success(t('contacts:availableModal.toastSuccessOneAssociation'));
      }

      reloadQuote();
    },
    [contactsAssociateToQuoteMutation, quote.id, reloadQuote, t, toast],
  );

  const contactsRetriever = useCallback<ClientContactSelectionStepperModalProps<IContact>['contactsRetriever']>(
    () => [{ quoteId: quote.id }, getContactAvailableForQuote],
    [quote.id],
  );

  return (
    <Section title={t('quote:clientStep.title')} description={t('quote:clientStep.multiContactDescription')} h="100%">
      {quote?.client && currentClient && (
        <>
          <ClientInformationsTab
            associationType={ClientCardContactAssociateType.QUOTE}
            type={ClientCardTypeEnum.CHANGE}
            onInfosUpdated={reloadQuote}
            client={currentClient}
            onClientUpdate={updateUniqueClient}
            onClientCardButtonClicked={removeClientAndContactAssociation}
            quoteId={quote.id}
          />
        </>
      )}

      {!quote?.client && (
        <>
          <Onboarding
            icon={<SimpleContactIcon boxSize={45} />}
            mb={8}
            mt={16}
            h="auto"
            action={
              <SimpleGrid spacing={4} columns={2}>
                <Button onClick={onAssociateExistingOpen} variant="outline">
                  {t('quote:clientStep.cta.billExisting')}
                </Button>
                <Button onClick={creationModal.onOpen} colorScheme="blue">
                  {t('quote:clientStep.cta.billNew')}
                </Button>
              </SimpleGrid>
            }
          />

          <ClientContactSelectionStepperModal
            isOpen={isAssociateExistingOpen}
            onClose={onAssociateModalClose}
            onClientSelected={onClientSelected}
            onSubmit={onContactsSelected}
            contactsRetriever={contactsRetriever}
            isContactDefaultEmailRecipient={(contact) => contact.isDefaultEmailRecipient}
          />
          <ClientContactCreateStepperModal
            isOpen={creationModal.isOpen}
            onClose={onCreateModalClose}
            onClientCreated={onClientCreated}
            onContactCreated={onContactCreated}
            hasIsDefaultEmailRecipient
          />
        </>
      )}
    </Section>
  );
};
