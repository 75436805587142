import { useCallback } from 'react';
import dayjs from 'dayjs';
import { Flex } from '@chakra-ui/react';

import { SimpleEditIcon } from '../../../../Icons';
import type { ButtonColorScheme } from '../../../../Badge/Badge';
import { Badge } from '../../../../Badge/Badge';
import { useTimeTableContext } from '../../contexts';
import type { ITimeTableSlot } from '../../types';
import { getTimeTableSlotInfo } from '../../utils/getTimeTableSlotInfo';

interface TimeTableHoveredSlotProps {
  slot: ITimeTableSlot;
  color: ButtonColorScheme;
  startDate: Date;
  absenceDuration: number;
}

export const TimeTableHoveredSlot = ({ slot, color, startDate, absenceDuration }: TimeTableHoveredSlotProps) => {
  const { onEdit } = useTimeTableContext();

  const handleEdition = useCallback(() => onEdit(slot, dayjs(startDate).toDate()), [onEdit, slot, startDate]);

  if (!slot) {
    return null;
  }

  return (
    <Flex
      bgColor={`${color}.200`}
      as="button"
      borderRadius="md"
      mt="8px"
      h="36px"
      zIndex={3}
      justifyContent="space-between"
      alignItems="center"
      px={2}
      w="100%"
      transition="margin-left 0.2s ease-in-out"
      onClick={handleEdition}
    >
      <Badge h={25} px={2} colorScheme={color} theme="darker" fontWeight="700">
        {getTimeTableSlotInfo(slot.nbMinutes, absenceDuration)}
      </Badge>

      <SimpleEditIcon boxSize={3} stroke="gray.800" />
    </Flex>
  );
};
