export const DISPLAYED_EXPORT_COLUMNS = {
  REF_CODE: 'refCode',
  UNIT: 'unit',
  QUANTITY: 'quantity',
  UNIT_DISBURSEMENT_EX_VAT: 'unitDisbursementExVAT',
  DISBURSEMENT_EX_VAT: 'disbursementExVAT',
  JOB_MARGIN: 'jobMargin',
  UNIT_PRICE_EX_VAT: 'unitPriceExVAT',
  JOB_VAT: 'jobVAT',
  WORK_FORCE: 'workForce',
  AMOUNT_EX_VAT: 'amountExVAT',
} as const;

export const EXPORT_COLUMNS = {
  ...DISPLAYED_EXPORT_COLUMNS,
  INFOS: 'infos',
  NOTES: 'notes',
  IMAGES: 'images',
  COMPONENTS: 'components',
} as const;

export enum EXPORT_FILE_TYPES {
  PDF = 'PDF',
  SPREADSHEET = 'SPREADSHEET',
}
