import type { FC } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { GotoLink, SimpleBuildingIcon } from '@graneet/lib-ui';
import type { IClient, IClientResponseDTO, IAddress, IAddressResponseDTO } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

interface ClientCardLineProps {
  client: IClient | IClientResponseDTO;
}

export const ClientCardLine: FC<ClientCardLineProps> = ({ client }) => {
  const formatAddress = (address?: IAddress | IAddressResponseDTO | undefined) => {
    if (!address) return '';
    return [address.address, address.postalCode, address.city].filter(Boolean).join(', ');
  };

  const { t } = useTranslation(['clients']);

  if (!client) return null;

  return (
    <HStack spacing={3} py={1}>
      <Box
        backgroundColor="gray.100"
        width="2.5rem"
        height="2.5rem"
        py={1}
        px={2}
        borderColor="gray.200"
        borderWidth={1}
        borderRadius={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <SimpleBuildingIcon width="1.3rem" height="1.3rem" />
      </Box>
      <VStack spacing={1} align="flex-start">
        <HStack>
          <GotoLink
            to={`/contacts/clients/${client.id}`}
            label={
              <Text fontWeight="600" color="gray.800">
                {client.enterpriseName}
              </Text>
            }
            _hover={{
              color: 'gray.600',
              textDecoration: 'underline',
            }}
          />
          {client.code && (
            <Text
              textColor="gray.800"
              shadow="sm"
              py={1}
              px={2}
              borderColor="gray.100"
              borderWidth={1}
              borderRadius={5}
            >
              {client.code}
            </Text>
          )}
        </HStack>
        <HStack marginLeft="1">
          {!client.hasBillingAddress ? (
            <Text fontWeight="400">{formatAddress(client.address)}</Text>
          ) : (
            <VStack alignItems="start" gap={0}>
              <Text>{t('clients:card.billingAddress', { address: formatAddress(client.billingAddress!) })}</Text>
              <Text>{t('clients:card.headOfficeAddress', { address: formatAddress(client.address) })}</Text>
            </VStack>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};
