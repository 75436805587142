import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { IBill } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { RICH_TEXT_INLINE_TOOLBAR_PRESET, RichTextField } from '@graneet/lib-ui';
import { Form, useForm } from 'graneet-form';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useBillUpdate } from 'features/bill/services/bill.api';

interface FormValues {
  text: string | undefined | null;
}

interface BillCommentCardProps {
  bill: IBill;
}

export const BillCommentCard: FC<BillCommentCardProps> = ({ bill }) => {
  const { t } = useTranslation(['bill']);
  const hasPayBillsPermission = usePermissions([PERMISSION.PAY_BILLS]);

  const billUpdateMutation = useBillUpdate();

  const form = useForm<FormValues>({
    defaultValues: {
      text: bill.comment,
    },
    onUpdateAfterBlur: (_, value) => {
      billUpdateMutation.mutate({
        id: bill.id,
        dto: { comment: value ?? '' },
      });
    },
  });

  return (
    <Form form={form}>
      <RichTextField<FormValues>
        name="text"
        label={t('bill:fields.comment')}
        navbarType="inline"
        inputProps={{
          minH: '5rem',
        }}
        placeholder={t('bill:changeComment.placeholder')}
        isDisabled={!hasPayBillsPermission}
        configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
      />
    </Form>
  );
};
