import type {
  IDownPaymentWithRelations,
  ICompleteDownPaymentDTO,
  ICreditCreationDTO,
  IDownPayment,
  IDownPaymentVersionsDTO,
  IUpdateTagsDTO,
  IDownPaymentResponse,
} from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { DOWN_PAYMENT_FACTORY_NAME } from './down-payment.factory-name';

import { invalidateStatementRelatedQueries } from 'features/common/services/invalidate-statement-related-queries.util';
import { usePdfVersionsTanStackQuery } from 'features/pdf/hooks/usePdfVersions';
import { apiNew } from 'features/api/services/apiNew.service';

const DOWN_PAYMENTS_PATH = '/down-payments';

const downPaymentKeyFactory = createQueryKeys(DOWN_PAYMENT_FACTORY_NAME, {
  getById: (id: number) => ({
    queryKey: [DOWN_PAYMENTS_PATH, id],
    queryFn: () => apiNew.get<never, IDownPaymentResponse>(`${DOWN_PAYMENTS_PATH}/${id}`),
  }),
  getVersions: (id: number) => ({
    queryKey: [DOWN_PAYMENTS_PATH, id],
    queryFn: () => apiNew.get<never, IDownPaymentVersionsDTO>(`${DOWN_PAYMENTS_PATH}/${id}/versions`),
  }),
});

export function useDownPayment(id: number) {
  return useSuspenseQuery(downPaymentKeyFactory.getById(id));
}

export function useDownPaymentVersions(id: number) {
  const suspenseQuery = useSuspenseQuery(downPaymentKeyFactory.getVersions(id));

  usePdfVersionsTanStackQuery(suspenseQuery.data, suspenseQuery.refetch);

  return suspenseQuery;
}

export function useUpdateDownPaymentTags() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateTagsDTO }) =>
      apiNew.patch<IUpdateTagsDTO, IDownPayment>(`${DOWN_PAYMENTS_PATH}/${params.id}/tags`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:words.c.success'), t('global:changeTags.success'));
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useDeleteDownPayment() {
  const { t } = useTranslation(['global', 'statement']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, void>(`${DOWN_PAYMENTS_PATH}/${id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(t('statement:delete.success'));
    },
  });
}

export function useChangeDownPaymentStatusToCompleted() {
  const { t } = useTranslation(['global', 'downPayment']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: ICompleteDownPaymentDTO }) =>
      apiNew.patch<ICompleteDownPaymentDTO, IDownPayment>(
        `${DOWN_PAYMENTS_PATH}/${params.id}/status/completed`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (downPayment) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(
        t(downPayment.isInvoiced ? 'downPayment:toasts.completedWithInvoiceNumber' : 'downPayment:toasts.completed', {
          invoiceNumber: downPayment.invoiceNumber,
        }),
      );
    },
  });
}

export function useChangeDownPaymentStatusToDraft() {
  const { t } = useTranslation(['global', 'statement']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.patch<never, IDownPayment>(`${DOWN_PAYMENTS_PATH}/${id}/status/draft`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateStatementRelatedQueries(queryClient);
    },
  });
}

export function useForceDraftDownPayment() {
  const { t } = useTranslation(['global', 'downPayment']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.patch<never, IDownPayment>(`${DOWN_PAYMENTS_PATH}/${id}/status/force-draft`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (downPayment) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(
        t('downPayment:toasts.draftedWithoutCredit', {
          invoiceNumber: downPayment.invoiceNumber,
        }),
      );
    },
  });
}

export function useCancelDownPayment(context: { cancelledInvoiceNumber: string | undefined }) {
  const { t } = useTranslation(['global', 'credit']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: ICreditCreationDTO }) =>
      apiNew.patch<ICreditCreationDTO, IDownPaymentWithRelations>(
        `${DOWN_PAYMENTS_PATH}/${params.id}/cancel`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:words.c.error'), t('credit:toast.create.error'));
    },
    onSuccess: async (downPayment) => {
      await invalidateStatementRelatedQueries(queryClient);
      toast.success(
        t(`credit:toast.create.success.${STATEMENT_TYPES.DOWN_PAYMENT}`, {
          invoiceNumber: downPayment?.credit?.invoiceNumber,
          cancelledInvoiceNumber: context.cancelledInvoiceNumber,
        }),
      );
    },
  });
}
