import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import '../../ag-grid-quote/styles/ag-grid.css';

import Big from 'big.js';
import { Box } from '@chakra-ui/react';
import { AgGridReact } from '@ag-grid-community/react';
import { useMemo, useRef } from 'react';
import type { QuoteComponentsGroupByType, QuoteLot } from '@org/quotation-lib';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';

import { useColumnDefs } from '../hooks/useColumnDefs';
import { useDefaultColDef } from '../hooks/useDefaultColDef';
import { useAutoSizeStrategy } from '../hooks/useAutoSizeStrategy';
import { useStatusBar } from '../hooks/useStatusBar';

const MODULES = [ClientSideRowModelModule, RowGroupingModule, StatusBarModule];

interface AgGridQuoteComponentsByTypeProps {
  quoteLot: QuoteLot;
}

export const AgGridQuoteComponentsByType = ({ quoteLot }: AgGridQuoteComponentsByTypeProps) => {
  const gridRef = useRef<AgGridReact<QuoteComponentsGroupByType>>(null);

  const columnDefs = useColumnDefs(quoteLot.getNodeOrThrow().getId());
  const defaultColDef = useDefaultColDef();
  const autoSizeStrategy = useAutoSizeStrategy();
  const rowsData = useMemo(() => quoteLot.getComponentsByType(), [quoteLot]);
  const totalAmountsRow = rowsData.reduce(
    (acc, row) => ({
      totalAmountRow: acc.totalAmountRow.plus(row.flatCost ?? Big(0)),
      totalHiddenCostAmountRow: acc.totalHiddenCostAmountRow.plus(row.hiddenCostFlatCost ?? Big(0)),
    }),
    {
      totalAmountRow: Big(0),
      totalHiddenCostAmountRow: Big(0),
    },
  );
  const quoteLotFlatCostAmount = quoteLot.getFlatCostAmount() ?? Big(0);
  const statusBar = useStatusBar(
    quoteLotFlatCostAmount.minus(totalAmountsRow.totalAmountRow).toString(),
    totalAmountsRow.totalHiddenCostAmountRow.plus(totalAmountsRow.totalAmountRow).toString(),
  );

  return (
    <Box className="ag-theme-quartz quotation component-type" width="100%" height="100%">
      <AgGridReact
        ref={gridRef}
        rowData={rowsData}
        defaultColDef={defaultColDef}
        autoSizeStrategy={autoSizeStrategy}
        columnDefs={columnDefs}
        statusBar={statusBar}
        rowHeight={36}
        headerHeight={44}
        reactiveCustomComponents
        suppressNoRowsOverlay
        suppressContextMenu
        modules={MODULES}
      />
    </Box>
  );
};
