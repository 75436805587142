import type { PaginatedResponse } from '../pagination/paginated-response.type';
import type { IPayment } from '../payment/payment.type';
import type { ISupplierInvoicePayment } from '../supplier-invoice/supplier-invoice-payment.type';
import type { RequiredByKeys } from '../common/types/index';

import type { IBankingAccount } from './banking-account.type';
import type { BANKING_POWENS_TRANSACTION_TYPE } from './banking-powens.type';
import type { IBankingConnection } from './banking-connection.type';

export const BANKING_TRANSACTION_STATUS = ['TO_RECONCILE', 'PARTIALLY_RECONCILED', 'RECONCILED'] as const;

export type BankingTransactionStatus = (typeof BANKING_TRANSACTION_STATUS)[number];

// https://docs.powens.com/api-reference/products/data-aggregation/bank-transactions#transaction-object
export interface IBankingTransaction {
  id: string;

  /**
   * Mapped to powens transaction.id
   */
  powensTransactionId: number;

  /**
   * Mapped to powens transaction.type
   * We don't need all those types.
   * In the backend/frontend at some point we'll have common values | 'other'.
   */
  type: BANKING_POWENS_TRANSACTION_TYPE;

  /**
   * Mapped to powens transaction.value
   * Sign indicates flow direction
   * Inc. VAT.
   */
  amount: number;

  /**
   * Date d'émission
   * Mapped to powens transation.datetime
   */
  date: Date;

  /**
   * Card number associated with the transaction.
   * Mapped to powens transaction.card
   */
  card: string | null;

  /**
   * Mapped to powens transaction.simplified_wording
   */
  label: string | null;

  /**
   * Graneet specific.
   * Defaults to 'TO_RECONCILE'.
   */
  status: BankingTransactionStatus;

  /**
   * Graneet specific.
   * Must be set to amount on initialization.
   * Inc. VAT.
   * NOTE : this value is always positive or zero even in case of negative transaction amount
   */
  remainingAmountToReconcileIncVAT: number;

  note: string;

  tags: string[];

  /**
   * The raw transaction from Powens in jsonb.
   */
  powensData: unknown;

  createdAt: Date;

  updatedAt: Date;

  bankingAccount?: IBankingAccount;

  payments?: IPayment[];

  supplierInvoicePayments?: ISupplierInvoicePayment[];
}

export type BankingTransactionPaginatedResponse = PaginatedResponse<
  Omit<IBankingTransaction, 'bankingAccount'> & {
    bankingAccount: Pick<IBankingConnection, 'id'> & {
      bankingConnection: Pick<IBankingConnection, 'id' | 'name' | 'bankUuid'>;
    };
  }
>;

export type IBankingTransactionWithRelations = RequiredByKeys<
  Omit<IBankingTransaction, 'bankingAccount' | 'supplierInvoicePayments'>,
  'payments'
> & {
  supplierInvoicePayments: RequiredByKeys<ISupplierInvoicePayment, 'supplierInvoice'>[];
  bankingAccount: Pick<IBankingConnection, 'id' | 'name'> & {
    bankingConnection: Pick<IBankingConnection, 'id' | 'name' | 'bankUuid'>;
  };
};
