import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Card, TwoColumns, CurrencyField, useCurrency, Section, SimpleInfoCircleIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, useStepForm, useWizardContext } from 'graneet-form';
import type { IProgressStatement } from '@graneet/business-logic';

import { Rule } from 'features/form/rules/Rule';
import { ProgressStatementExVATAmountLabel } from 'features/progress-statement/components/labels/ProgressStatementExVATAmountLabel';
import { URL_INDEXES_PRICE_REVISION } from 'features/progress-statement/services/progress-statement.constant';
import type { ProgressStatementEditWizard } from 'features/progress-statement/forms/progress-statement-edit-wizard';

interface EditProgressStatementPriceRevisionStepProps {
  previousProgressStatement?: IProgressStatement;

  currentProgressStatement: IProgressStatement | null;
}

export const EditProgressStatementPriceRevisionStep: FC<EditProgressStatementPriceRevisionStepProps> = ({
  previousProgressStatement,
  currentProgressStatement,
}) => {
  const { t } = useTranslation(['progressStatement']);
  const { mapNumberToAmount } = useCurrency();

  const { form, initFormValues } = useStepForm<ProgressStatementEditWizard, 'priceRevision'>();
  const { getValuesOfStep } = useWizardContext<ProgressStatementEditWizard>();

  const [inputRef, setInputRef] = useState<undefined | HTMLInputElement>(undefined);

  useEffect(() => {
    initFormValues({
      priceRevision: mapNumberToAmount(currentProgressStatement?.priceRevisionExVAT || 0),
    });

    inputRef?.focus();
  }, [inputRef, initFormValues, mapNumberToAmount, currentProgressStatement]);

  const itemsValues = getValuesOfStep('items')!;

  return (
    <Form form={form}>
      <Section
        title={t('progressStatement:isCreating.priceRevisionTitle')}
        description={
          <>
            <Text>{t('progressStatement:isCreating.priceRevisionDescription')}</Text>
            <Text mt={2}>{t('progressStatement:isCreating.priceRevisionSubDescription')}</Text>
          </>
        }
      >
        <TwoColumns>
          <Card>
            <Flex h="100%" flexDirection="row" justifyContent="center" pb={2}>
              <SimpleInfoCircleIcon mr={3} stroke="greenBrand.light" mt={6} />
              <Box w="100%" alignItems="center" mt={3}>
                <Text>
                  {t('progressStatement:isCreating.priceRevisionAmount')}
                  <Box as="span" whiteSpace="nowrap" color="greenBrand.light">
                    <ProgressStatementExVATAmountLabel
                      values={itemsValues}
                      previousCumulativeAmountExVAT={previousProgressStatement?.cumulativeProgressAmountExVAT}
                    />
                  </Box>
                </Text>
                <Text py={4}>
                  {t('progressStatement:isCreating.priceRevisionListingBTP')}
                  <Box as="a" color="greenBrand.light" target="_blank" href={URL_INDEXES_PRICE_REVISION}>
                    <Text as="u">{t('progressStatement:isCreating.priceRevisionIndex')}</Text>
                  </Box>
                </Text>
              </Box>
            </Flex>
          </Card>

          <Card>
            <Flex w="100%" flexDirection="column" mt={3}>
              <Box>
                <Text>{t('progressStatement:isCreating.priceRevisionLabel')}</Text>
              </Box>
              <Flex flexDirection="column" my={2} w="60%">
                <CurrencyField<ProgressStatementEditWizard['priceRevision']>
                  name="priceRevision"
                  label={t('progressStatement:isCreating.priceRevisionLabelInput')}
                  inputRef={setInputRef}
                  isRequired
                >
                  <Rule.IsRequired />
                </CurrencyField>
              </Flex>
            </Flex>
          </Card>
        </TwoColumns>
      </Section>
    </Form>
  );
};
