import type { QuoteSettingApi, QuoteSettingCreateDTO, QuoteSettingUpdateDTO } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteSettingProxyApi {
  private static quoteSettingProxyApi: QuoteSettingProxyApi;

  private readonly proxyApi: QuoteSettingApi;

  private constructor(externalApi: ExternalApi) {
    this.proxyApi = externalApi.getQuoteSettingApi();
  }

  public static getQuoteSettingProxyApi(externalApi: ExternalApi): QuoteSettingProxyApi {
    if (!this.quoteSettingProxyApi) {
      QuoteSettingProxyApi.quoteSettingProxyApi = new QuoteSettingProxyApi(externalApi);
    }
    return QuoteSettingProxyApi.quoteSettingProxyApi;
  }

  public async createQuoteSetting(quoteSettingCreateDTO: QuoteSettingCreateDTO) {
    return proxyHelper(async () => this.proxyApi.createQuoteSetting(quoteSettingCreateDTO));
  }

  public async findQuoteSettingByQuoteIdAndUserId(quoteId: string) {
    return proxyHelper(async () => this.proxyApi.findQuoteSettingByQuoteIdAndUserId(quoteId));
  }

  public async updateQuoteSetting(quoteId: string, quoteSettingUpdateDTO: QuoteSettingUpdateDTO) {
    return proxyHelper(async () => this.proxyApi.updateQuoteSetting(quoteId, quoteSettingUpdateDTO));
  }
}
