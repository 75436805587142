import type { FC } from 'react';
import { getChecklistIcon, List } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ListProps } from '@chakra-ui/react';
import { Collapse } from '@chakra-ui/react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { checkPasswordStrength } from '@graneet/business-logic';

import type { OnboardingCredentialsForm } from 'features/onboarding/components/OnboardingCredentialsForm';

interface OnboardingPasswordCheckListProps extends ListProps {}

export const OnboardingPasswordCheckList: FC<OnboardingPasswordCheckListProps> = (props) => {
  const { t } = useTranslation(['onboarding']);

  const form = useFormContext<OnboardingCredentialsForm>();
  const { password } = useOnChangeValues(form, ['password']);

  const conditions = checkPasswordStrength(password || '');

  return (
    <Collapse in={!!password} animateOpacity>
      <List {...props} bg="gray.100" borderRadius="md" px={3} py={2}>
        <List.Item
          label={t('onboarding:passwordConditions.length')}
          icon={getChecklistIcon(conditions.rules.has8CharsOrMore)}
        />
        <List.Item
          label={t('onboarding:passwordConditions.number')}
          icon={getChecklistIcon(conditions.rules.hasNumber)}
        />
        <List.Item
          label={t('onboarding:passwordConditions.lowercaseLetter')}
          icon={getChecklistIcon(conditions.rules.hasLowerCase)}
        />
        <List.Item
          label={t('onboarding:passwordConditions.uppercaseLetter')}
          icon={getChecklistIcon(conditions.rules.hasUpperCase)}
        />
        <List.Item
          label={t('onboarding:passwordConditions.specialCharacter')}
          icon={getChecklistIcon(conditions.rules.hasSpecialChars)}
        />
      </List>
    </Collapse>
  );
};
