import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { t } from 'i18next';

import type { CashOutFlowActionsType } from './cash-out-flow.actions.type';

export function getCashOutToPayActions(params: CashOutFlowActionsType): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];
  const isDisabled = !params.permissions.hasPaySupplierInvoicesPermission;
  const tooltip = isDisabled ? t('global:errors.disabledAction') : undefined;

  if (params.hasBankingConnectionFF !== false) {
    actions.push({
      type: 'primary' as const,
      label: t('supplierInvoices:actions.pay'),
      onClick: params.callbacks.onPay,
      isDisabled,
      tooltip,
    });
  }

  actions.push({
    type: 'secondary',
    label: t('cashOutflow:actions.toPay'),
    onClick: params.callbacks.onUpdateToPay,
    isDisabled,
    tooltip,
  });

  return actions;
}
