import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@graneet/lib-ui';
import type { ILibraryComponent, ILibraryComponentWithRelations } from '@graneet/business-logic';

import { LibraryJobComponentsRow } from './LibraryJobComponentsRow';

interface LibraryComponentTableProps {
  libraryComponents: Array<ILibraryComponent & { quantity: number | null }>;
  renderActionsMenu?: (libraryComponent: ILibraryComponentWithRelations) => ReactNode;
}

export const LibraryJobComponentsTable: FC<LibraryComponentTableProps> = ({ libraryComponents, renderActionsMenu }) => {
  const { t } = useTranslation(['library']);

  return (
    <Table
      templateColumns={[
        '10rem', // Type
        '6rem', // Ref code
        'minmax(11rem, 2fr)', // Description
        '8rem', // Unit
        '6rem', // Quantity
        '12rem', // Unit Disbursement Ex VAT
        '9rem', // Disbursement Ex VAT
        renderActionsMenu ? '4rem' : null, // Actions menu
      ].filter((v) => v !== null)}
    >
      <Table.Header>
        <Table.Cell>{t('library:libraryComponent.fields.type')}</Table.Cell>

        <Table.Cell>{t('library:libraryComponent.fields.refCode')}</Table.Cell>

        <Table.Cell>{t('library:libraryComponent.fields.description')}</Table.Cell>

        <Table.Cell right>{t('library:libraryComponent.fields.unit')}</Table.Cell>

        <Table.Cell right>{t('library:libraryComponent.fields.quantity')}</Table.Cell>

        <Table.Cell right>{t('library:libraryComponent.fields.unitDisbursementExVAT')}</Table.Cell>

        <Table.Cell right>{t('library:libraryComponent.fields.disbursementExVAT')}</Table.Cell>
        {renderActionsMenu && <Table.Cell />}
      </Table.Header>

      {libraryComponents.map((libraryComponent) => (
        <LibraryJobComponentsRow
          key={libraryComponent.id}
          libraryComponent={libraryComponent}
          renderActionsMenu={renderActionsMenu}
        />
      ))}
    </Table>
  );
};
