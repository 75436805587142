/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import type { FlexProps } from '@chakra-ui/react';
import { CircularProgress, Flex } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import type { ReactElement, ReactNode } from 'react';

import { ActionMenu } from '../../ActionMenu/ActionMenu';
import { GraneetButton } from '../../Quotation/Button/Button';
import { GraneetIconButton } from '../../Quotation/IconButton/IconButton';
import { ButtonList } from '../../Quotation/ButtonList/ButtonList';

export type Action = {
  label: string | ReactNode;
  onClick(): void;
  tooltip?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  /**
   * Renders the option in red
   */
  warning?: boolean;
  /*
   * Icon will be ignored unless used in a dropdown
   */
  icon?: ReactElement | string;
};

type ActionDropdownButton =
  | {
      type: 'primaryDropdown' | 'secondaryDropdown';
      options: Action[];
      dropDownLabel: string;
    }
  | {
      type: 'tertiaryDropdown';
      options: Action[];
    };

export type DocumentStatusCardAction = (Action & { type: 'primary' | 'secondary' }) | ActionDropdownButton;

export interface DocumentStatusCardActionsProps extends FlexProps {
  actions: DocumentStatusCardAction[];
}

const ActionMenuDropdownOption = ({ option }: { option: Action }) => (
  <ActionMenu.Action
    tooltip={option.tooltip}
    disabled={option.isDisabled || option.isLoading}
    warning={option.warning}
    label={option.label}
    icon={
      option.isLoading ? (
        <CircularProgress isIndeterminate color="greenBrand.light" size={4} />
      ) : (option.icon as ReactElement) ? (
        (option.icon as ReactElement)
      ) : undefined
    }
    onClick={option.isDisabled || option.isLoading ? undefined : option.onClick}
  />
);

export const DocumentStatusCardActions = ({ actions, ...rest }: DocumentStatusCardActionsProps) => (
  <Flex direction="row" gap={3} {...rest}>
    {actions.map((action, i) => {
      switch (action.type) {
        case 'primary':
        case 'secondary': {
          return (
            <GraneetButton
              key={i}
              tooltip={action.tooltip}
              variant={action.warning ? 'danger' : action.type === 'secondary' ? 'secondary' : 'primary'}
              onClick={action.isDisabled || action.isLoading ? undefined : action.onClick}
              isLoading={action.isLoading}
              isDisabled={action.isDisabled || action.isLoading}
            >
              {action.label}
            </GraneetButton>
          );
        }
        case 'primaryDropdown':
        case 'secondaryDropdown': {
          return (
            <ActionMenu
              key={i}
              buttonProps={{
                variant: action.type === 'secondaryDropdown' ? 'secondary' : 'primary',
                children: action.dropDownLabel,
                rightIcon: <ChevronDownIcon fontSize="1.2em" />,
                leftIcon: null,
              }}
            >
              {action.options.map((option) => (
                <ActionMenuDropdownOption key={option.label?.toString()} option={option} />
              ))}
            </ActionMenu>
          );
        }
        case 'tertiaryDropdown': {
          return (
            <ActionMenu
              key={i}
              buttonProps={{
                variant: 'secondary',
              }}
            >
              {action.options.map((option) => (
                <ActionMenuDropdownOption key={option.label?.toString()} option={option} />
              ))}
            </ActionMenu>
          );
        }
        default: {
          action satisfies never;
          throw new Error(`Unknown action type`);
        }
      }
    })}
  </Flex>
);

export const DocumentStatusCardActionsList = ({ actions, ...rest }: DocumentStatusCardActionsProps) => (
  <Flex direction="row" gap={3} {...rest}>
    {actions.map((action, i) => {
      switch (action.type) {
        case 'primary':
        case 'secondary': {
          return action.icon ? (
            <GraneetIconButton
              key={i}
              onClick={action.isDisabled || action.isLoading ? undefined : action.onClick}
              isDisabled={action.isDisabled || action.isLoading}
              color={action.warning ? 'danger' : 'default'}
              icon={action.icon}
            />
          ) : (
            <GraneetButton
              key={i}
              tooltip={action.tooltip}
              onClick={action.isDisabled || action.isLoading ? undefined : action.onClick}
              isLoading={action.isLoading}
              isDisabled={action.isDisabled || action.isLoading}
              variant={action.warning ? 'red' : action.type}
            >
              {action.label}
            </GraneetButton>
          );
        }
        case 'primaryDropdown':
        case 'secondaryDropdown': {
          return (
            <ButtonList
              key={i}
              label={action.dropDownLabel}
              rightIcon="ri-arrow-down-s-line"
              ctaList={action.options.map((option) => ({
                label: option.label ?? '',
                color: option.warning ? 'red' : 'greenBrand',
                disabled: option.isDisabled || option.isLoading,
                tooltip: option.tooltip,
                onClick: option.onClick,
              }))}
            />
          );
        }
        case 'tertiaryDropdown': {
          return (
            <ButtonList
              key={i}
              rightIcon="ri-more-fill"
              ctaList={action.options.map((option) => ({
                label: option.label ?? '',
                color: option.warning ? 'red' : 'greenBrand',
                disabled: option.isDisabled || option.isLoading,
                tooltip: option.tooltip,
                onClick: option.onClick,
              }))}
            />
          );
        }
        default: {
          action satisfies never;
          throw new Error(`Unknown action type`);
        }
      }
    })}
  </Flex>
);
