import { SellsheetMarginCard } from '@graneet/lib-ui';
import { MARGIN_TYPE } from '@org/quotation-lib';
import type { FC } from 'react';
import { useMemo } from 'react';
import Big from 'big.js';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash-es';

import { useQuoteUpdateMargin } from '../../hooks/useQuoteUpdateMargin';
import { useQuote } from '../../hooks/useQuote';

import { useStore } from 'store/store';
import { updateQuoteAmountIsUnreachable } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';

const isEmptyValue = (value: string | number | undefined | null) => isNil(value) || isEmpty(value);

export const QuoteSellSheetGlobalMarginCard: FC<{ handleOpenModal?: () => void }> = ({ handleOpenModal }) => {
  const { t } = useTranslation(['quote']);
  const isEditable = useStore((store) => store.quoteEditable);

  const { quote } = useQuote();
  const quoteUpdateTotalMargin = useQuoteUpdateMargin();
  const { executeCommand } = useCommands();

  const isQuoteEmpty = useMemo(() => {
    if (quote.getTree().getNodeStack().size === 2) {
      return true;
    }
    let areAllLinesEmpty = true;
    quote
      .getTree()
      .getNodeStack()
      .forEach((node) => {
        const content = node.getContent().export();
        if (content.type !== 'QuoteLot') {
          if (!(isEmptyValue(content.amount) || isEmptyValue(content.quantityFormula?.quantity))) {
            areAllLinesEmpty = false;
          }
        }
      });
    return areAllLinesEmpty;
  }, [quote]);

  const quoteMargin = useMemo(() => quote.getMargin().export(), [quote]);

  const updateQuoteMargin = (record: Record<MARGIN_TYPE, string>) => {
    if (record.OverheadCosts && !Big(record.OverheadCosts).eq(Big(quoteMargin.overheadCosts).round(3, Big.roundDown))) {
      executeCommand(quoteUpdateTotalMargin(record.OverheadCosts, MARGIN_TYPE.OVERHEAD_COSTS), quote);
    }

    if (record.ProfitMargin && !Big(record.ProfitMargin).eq(Big(quoteMargin.profitMargin).round(3, Big.roundDown))) {
      executeCommand(quoteUpdateTotalMargin(record.ProfitMargin, MARGIN_TYPE.PROFIT_MARGIN), quote);
    }

    if (record.TotalMargin && !Big(record.TotalMargin).eq(Big(quoteMargin.totalMargin).round(3, Big.roundDown))) {
      executeCommand(quoteUpdateTotalMargin(record.TotalMargin, MARGIN_TYPE.TOTAL_MARGIN), quote);
    }

    setTimeout(() => updateQuoteAmountIsUnreachable(false), 5000);
  };

  const emptyMargin = useMemo(() => ({ profitMargin: '-', overheadCosts: '-', totalMargin: '-' }), []);

  return (
    <>
      <SellsheetMarginCard
        isEditable={isEditable && !isQuoteEmpty}
        margin={isQuoteEmpty ? emptyMargin : quoteMargin}
        isEmpty={isQuoteEmpty}
        onMarginChange={updateQuoteMargin}
        cta={
          handleOpenModal
            ? {
                label: t('quote:drawer.sale.quoteSellSheetLotMarginCard.componentMargin'),
                onClick: handleOpenModal,
              }
            : undefined
        }
      />
    </>
  );
};
