import type { Channel } from 'pusher-js';
import Pusher from 'pusher-js';

import type { IPusherService } from '../core/ports/IPusherService';
import type { IAction } from '../core/entities/IAction';

export class PusherAdapter implements IPusherService {
  private pusher?: Pusher;

  private channels: Channel[] = [];

  private channelBinds: Record<string, IAction[]> = {};

  connect(
    appKey: string,
    cluster: string,
    authorizeUrl: string,
    authUrl: string,
    authToken: string,
  ): Pusher | undefined {
    if (!this.pusher) {
      this.pusher = new Pusher(appKey, {
        cluster,
        forceTLS: true,
        userAuthentication: {
          endpoint: authUrl,
          transport: 'jsonp',
          params: { auth: `Bearer ${authToken}` },
        },
        channelAuthorization: {
          endpoint: authorizeUrl,
          transport: 'jsonp',
          params: { auth: `Bearer ${authToken}` },
        },
      });
    }
    this.pusher.signin();
    return this.pusher;
  }

  disconnect(): void {
    if (this.pusher) {
      this.pusher.disconnect();
      this.pusher = undefined;
      this.channels = [];
      this.channelBinds = {};
    }
  }

  subscribe(channels: string[]): Channel[] {
    if (!this.pusher) return [];

    channels.forEach((channel) => {
      if (!this.channels.some((currentChannel) => currentChannel.name === channel)) {
        const newChannel = this.pusher?.subscribe(channel);
        if (newChannel) this.channels.push(newChannel);
      }
    });

    return this.channels;
  }

  getPusher(): Pusher | undefined {
    return this.pusher;
  }

  getChannel(channelName: string): Channel | undefined {
    return this.channels.find((channel) => channel.name === channelName);
  }

  bind(channel: Channel, event: string, callback: any): void {
    channel.bind(event, callback);
  }

  isActionExist(channelName: string, eventName: string): boolean {
    return this.channelBinds[channelName]?.some((action) => action.event === eventName) ?? false;
  }

  addActionsToChannel(channelName: string, newAction: IAction): void {
    if (!this.channelBinds[channelName]) {
      this.channelBinds[channelName] = [newAction];
    } else {
      this.channelBinds[channelName].push(newAction);
    }
  }
}
