import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GraneetButton } from '@graneet/lib-ui';
import { Flex, Image, Text } from '@chakra-ui/react';

import { usePowensGetUrlConnection } from '../services/powens.api';

interface BankingAddBankButtonProps {}

export const BankingAddBankButton: FC<BankingAddBankButtonProps> = () => {
  const { t } = useTranslation(['banking']);

  const powensGetUrlConnectionMutation = usePowensGetUrlConnection();

  const onClick = useCallback(async () => {
    const url = await powensGetUrlConnectionMutation.mutateAsync();
    window.open(url);
  }, [powensGetUrlConnectionMutation]);

  return (
    <Flex direction="column" gap={2}>
      <Flex w="fit-content">
        <GraneetButton onClick={onClick} isLoading={powensGetUrlConnectionMutation.isPending}>
          {t('banking:actions.addBank')}
        </GraneetButton>
      </Flex>

      <Flex color="greenBrand.baseTertiary" fontSize="sm" gap={1} alignItems="center">
        <i className="ri-lock-2-fill" />
        <Text>{t('banking:redirectionToOurPartner')}</Text>
        <Image mt={1.5} src="/media/images/powens.png" filter="grayscale(100%)" h="0.75rem" />
      </Flex>
    </Flex>
  );
};
