import type { FC } from 'react';
import { useState } from 'react';
import { StepperModal } from '@graneet/lib-ui';
import type { ISupplier } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { SupplierCreateStep } from './steps/SupplierCreateStep';
import { ContactCreateStep } from './steps/ContactCreateStep';

import { QueryWrapper } from 'features/api/components/QueryWrapper';

type ContractCreateWizard = {
  'create-supplier': {};
  'create-contact': {};
};

export interface SupplierContactCreateStepperModalProps {
  isOpen: boolean;
  onClose(): void;
  onSuccess(client?: ISupplier): void;
}

export const SupplierContactCreateStepperModal: FC<SupplierContactCreateStepperModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [supplier, setSupplier] = useState<ISupplier | null>();
  const { t } = useTranslation(['supplier', 'contacts']);

  const handleSuccess = (supplierResponse: ISupplier) => {
    setSupplier(supplierResponse);
    onSuccess(supplierResponse);
  };

  const onFinish = () => {
    onSuccess();
    onClose();
  };

  return (
    <StepperModal<ContractCreateWizard> isOpen={isOpen} onClose={onClose} onFinish={onFinish} size="2xl">
      <StepperModal.Step<ContractCreateWizard, 'create-supplier'>
        name="create-supplier"
        title={t('supplier:createSupplier')}
      >
        <SupplierCreateStep onClose={onClose} handleSuccess={handleSuccess} />
      </StepperModal.Step>

      <StepperModal.Step<ContractCreateWizard, 'create-contact'>
        name="create-contact"
        title={t('contacts:creationModal.title')}
      >
        <QueryWrapper>
          <ContactCreateStep supplierId={supplier?.id} onClose={onClose} />
        </QueryWrapper>
      </StepperModal.Step>
    </StepperModal>
  );
};
