import type { IClient } from '../client/client.type';
import type { ICompany } from '../company/company.type';
import type { ISupplier } from '../supplier/supplier.type';
import type { PaginatedResponse } from '../pagination/paginated-response.type';

import type { ASSOCIATION_TYPE } from './contact.constant';
import type { IContactQuote } from './contact-quote.entity';
import type { IContactProject } from './contact-project.entity';
import type { IContactWithQuoteInfos } from './dtos/contact-quote-response.dto';

export interface IContact {
  id: string;

  firstName: string | null;

  lastName: string | null;

  email: string | null;

  phoneNumber: string | null;

  role: string | null;

  isDefaultEmailRecipient: boolean;

  createdAt: Date | string;

  updatedAt: Date | string;

  company?: ICompany;

  client?: IClient | null;

  supplier?: ISupplier | null;

  contactQuotes?: IContactQuote[];

  contactProjects?: IContactProject[];
}

export interface IGroupedContact<T extends IContact = IContact> {
  id?: string | number;
  name?: string | null;
  type: ASSOCIATION_TYPE;
  contacts: T[];
}

export type PaginatedContactWithQuoteInfos = PaginatedResponse<IContactWithQuoteInfos>;

export function isGroupedContact(contact: IContact | IGroupedContact): contact is IGroupedContact {
  return 'contacts' in contact;
}
