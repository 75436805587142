import { List, ListItem, Text } from '@chakra-ui/react';
import type { ISupplierInvoiceProject } from '@graneet/business-logic';
import { formatDataOrEmpty, formatNumberToVatRate, formatPercentage, roundFloating, Tooltip } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type SupplierInvoiceProjectsCellProps = {
  supplierInvoiceProjects: ISupplierInvoiceProject[];
};

export const SupplierInvoiceProjectsCell: FC<SupplierInvoiceProjectsCellProps> = ({ supplierInvoiceProjects }) => {
  const { t } = useTranslation(['supplierInvoices']);

  if (!supplierInvoiceProjects.length) {
    return <>{formatDataOrEmpty(null)}</>;
  }

  const projectName =
    supplierInvoiceProjects.length > 1
      ? t('supplierInvoices:projects.multi')
      : supplierInvoiceProjects[0].project!.name;
  const textColor = supplierInvoiceProjects.length > 1 ? 'gray.400' : 'gray.800';

  const label = (
    <List>
      {supplierInvoiceProjects.map(({ project, percentage }) => (
        <ListItem display="flex" key={project!.id}>
          {percentage < 1 && (
            <Text color="gray.600" w="2.7rem">
              {formatPercentage(roundFloating(formatNumberToVatRate(percentage)))}
            </Text>
          )}
          {project!.name}
        </ListItem>
      ))}
    </List>
  );
  return (
    <Tooltip label={label} placement="right">
      <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" color={textColor}>
        {projectName}
      </Text>
    </Tooltip>
  );
};
