import type { FC, ReactNode } from 'react';
import type { BoxProps, FlexProps } from '@chakra-ui/react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { TopRightContent } from './TopRightContent';

export type CardProps = Omit<FlexProps, 'title'> & {
  children: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  titleProps?: BoxProps;
  noShadow?: boolean;
  variant?: 'light-gray' | 'default';
  hasTitleSeparator?: boolean;
  isRequired?: boolean;
  topRightContent?: ReactNode;
};

const CardComponent: FC<CardProps> = ({
  children,
  title,
  subtitle,
  topRightContent,
  noShadow = false,
  variant = 'default',
  isRequired = false,
  hasTitleSeparator = false,
  onClick,
  ...props
}) => {
  const styleProps = { ...props };

  switch (variant) {
    case 'light-gray':
      styleProps.backgroundColor = 'gray.100';
      break;
    default:
      styleProps.backgroundColor = 'white';
      break;
  }

  const hasTopContent = !!title || !!topRightContent || !!subtitle;

  if (onClick) {
    styleProps.boxShadow = noShadow ? 'none' : '0px 4px 4px -4px rgba(21, 24, 26, 0.32)';
    // eslint-disable-next-line no-underscore-dangle
    styleProps._hover = { bg: 'gray.100', cursor: 'pointer' };
    // eslint-disable-next-line no-underscore-dangle
    styleProps._active = { boxShadow: '0 0 0 0 rgba(21,24,26,.32)' };
    styleProps.border = '1px solid';
    styleProps.borderColor = 'gray.200';
  }

  return (
    <Flex
      px={4}
      py={3}
      borderRadius="md"
      boxShadow={!noShadow ? '0px 2px 5px -2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)' : 'none'}
      transition="box-shadow 0.1s ease-in-out"
      color="gray.600"
      overflow="auto"
      flexDirection="column"
      justifyContent="space-between"
      onClick={onClick}
      {...styleProps}
    >
      <Flex flex={1} flexDirection="column">
        <Box
          mb={hasTopContent ? 4 : undefined}
          // eslint-disable-next-line no-nested-ternary
          pb={hasTopContent ? (hasTitleSeparator ? 4 : 1) : hasTitleSeparator ? 4 : undefined}
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderBottom={hasTitleSeparator ? '1px solid' : undefined}
            borderBottomColor="gray.150"
          >
            {title && (
              <Box color="primaryLight" fontFamily="heading">
                {title}
                {isRequired && (
                  <Text color="red.500" display="inline-block" marginLeft={2}>
                    *
                  </Text>
                )}
              </Box>
            )}
            {topRightContent}
          </Flex>
          {subtitle && (
            <Box fontSize="sm" color="greenBrand.baseSecondary" fontFamily="heading">
              {subtitle}
            </Box>
          )}
        </Box>

        <Box flexGrow={1} flexShrink={1}>
          {children}
        </Box>
      </Flex>
    </Flex>
  );
};

export const Card = Object.assign(CardComponent, {
  TopRightContent,
});
