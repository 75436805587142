import { createContext, useContext } from 'react';

const THROW_ERROR = (): void => {
  throw new Error('No CounterLoaderContext found');
};

interface CounterLoaderContextProps {
  addLineRendered: (id: string) => void;
  updateRowsCount: (newRowNumber: number) => void;
}

const CounterLoaderContext = createContext<CounterLoaderContextProps>({
  addLineRendered: THROW_ERROR,
  updateRowsCount: THROW_ERROR,
});

export const useCounterLoaderContext = () => useContext(CounterLoaderContext);

export const CounterLoaderProvider = CounterLoaderContext.Provider;
