import { useCallback } from 'react';
import type { QuoteNode, QuoteTree } from '@org/quotation-lib';
import { ContentTypeGuard, MARGIN_TYPE, QuoteNodeCreateChildUseCase, QuoteQuantityFormula } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';
import { useCurrency } from '@graneet/lib-ui';
import type { BatiprixElement, BatiprixJob, IComponentType } from '@graneet/business-logic';
import Big from 'big.js';
import { useTranslation } from 'react-i18next';
import type { QuoteNodeClusterImportDTO } from '@org/graneet-bff-client';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';
import { mapQuoteNodeObjectToQuoteNodeDTO } from 'features/quotation/quote-common/mappers/quoteNodeMapper';
import { appendQuoteExpandedRow } from 'features/quotation/quote-common/store/quoteUpdateZustand';

type ParentType = 'lot' | 'optionalLot' | 'hiddenCostLot' | 'item';

export function useQuoteBatiprixImportItem() {
  const { t } = useTranslation(['quote']);

  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const { mapAmountToNumber } = useCurrency();
  const quoteError = useQuoteError();

  const {
    quoteBasicItemsProxyApi,
    quoteOptionalItemsProxyApi,
    quoteHiddenCostItemsProxyApi,
    quoteSubItemsProxyApi,
    quoteNodeProxyApi,
  } = useQuotationProxyApis();

  const handleItem = useCallback(
    (batiprixItem: BatiprixJob, tree: QuoteTree, parentId: string | null, parentType: ParentType) => {
      const parentNode = parentId === null ? tree.getRootNode() : tree.getNodeOrThrow(parentId);
      const lastItemId = parentNode.getLastChildItem()?.getId() ?? null;
      const firstLotId = parentNode.getFirstChildLot()?.getId() ?? null;

      let quoteNodeCreated: QuoteNode | undefined;

      switch (parentType) {
        case 'lot':
          quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteBasicItem(parentNode, lastItemId, firstLotId);
          break;
        case 'optionalLot':
          quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteOptionalItem(parentNode, lastItemId, firstLotId);
          break;
        case 'hiddenCostLot':
          quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteHiddenItem(parentNode, lastItemId, firstLotId);
          break;
        case 'item':
          quoteNodeCreated = QuoteNodeCreateChildUseCase.createQuoteSubItem(parentNode, lastItemId, firstLotId);
          break;
        default:
          throw new Error('ParentType is not supported');
      }

      if (!quoteNodeCreated) {
        throw new Error('QuoteNode is not created');
      }

      const content = quoteNodeCreated.getContent();

      if (!ContentTypeGuard.isQuoteItem(content) && !ContentTypeGuard.isQuoteSubItem(content)) {
        throw new Error('Content is not an item');
      }

      content.updateProperties({
        unit: batiprixItem.Unit.Label,
        refCode: null,
        denomination: batiprixItem.Label,
        note: null,
      });
      content.updateUnitFlatCostAmount(Big(mapAmountToNumber(batiprixItem.FlatCost)), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });

      appendQuoteExpandedRow(quoteNodeCreated.getId());
      appendQuoteExpandedRow(parentNode.getId());
      return quoteNodeCreated;
    },
    [mapAmountToNumber],
  );

  const handleComponents = useCallback(
    (batiprixElement: BatiprixElement, componentTypes: IComponentType, parentNode: QuoteNode) => {
      const lastChildId = parentNode.getLastChild()?.getId() ?? null;
      const quoteComponent = QuoteNodeCreateChildUseCase.createQuoteComponent(parentNode, lastChildId, null);

      if (!quoteComponent) {
        throw new Error('QuoteComponent is not created');
      }

      const componentContent = quoteComponent.getContent();

      if (!ContentTypeGuard.isQuoteComponent(componentContent)) {
        throw new Error('Content is not a QuoteComponent');
      }

      componentContent.updateProperties({
        refCode: null,
        denomination: batiprixElement.Label,
        unit: batiprixElement.Unit.Label,
        componentTypeId: componentTypes.id,
      });

      if (componentTypes.id !== undefined) {
        componentContent.updateComponentTypeId(componentTypes.id, {
          spreadUp: true,
          impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
        });
      }

      componentContent.updateUnitFlatCostAmount(Big(mapAmountToNumber(batiprixElement.UnitPrice)), {
        spreadUp: true,
        impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
      });
      componentContent.updateQuantityFormula(
        new QuoteQuantityFormula({
          quantity: batiprixElement.Quantity.toString() || null,
        }),
        {
          spreadUp: true,
          impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
        },
      );

      return quoteComponent;
    },
    [mapAmountToNumber],
  );

  return useCallback(
    (
      batiprixItems: BatiprixJob[],
      batiprixComponentTypes: {
        material: IComponentType;
        workforce: IComponentType;
      },
      parentId: string | null | undefined,
      parentType: ParentType,
    ): boolean => {
      const tree = quote.getTree();

      if (parentId === undefined) {
        throw new Error('parentId or null is required');
      }

      try {
        const items: QuoteNode[] = [];
        const components: QuoteNode[] = [];

        batiprixItems.forEach((batiprixItem) => {
          const quoteNodeCreated = handleItem(batiprixItem, tree, parentId, parentType);
          items.push(quoteNodeCreated);

          batiprixItem.Elements.forEach((libraryJobComponent) => {
            const quoteComponent = handleComponents(
              libraryJobComponent,
              batiprixComponentTypes.material,
              quoteNodeCreated,
            );
            components.push(quoteComponent);
          });

          if (batiprixItem.LaborUnitCost && batiprixItem.LaborQuantity) {
            const quoteComponent = handleComponents(
              {
                Label: t('quote:quotation.library.workforce'),
                Unit: {
                  Label: 'h',
                },
                Quantity: batiprixItem.LaborQuantity,
                UnitPrice: batiprixItem.LaborUnitCost,
              },
              batiprixComponentTypes.workforce,
              quoteNodeCreated,
            );
            components.push(quoteComponent);
          }

          quoteSetToStore(quote);
        });

        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();

        if (
          !quoteBasicItemsProxyApi ||
          !quoteOptionalItemsProxyApi ||
          !quoteHiddenCostItemsProxyApi ||
          !quoteSubItemsProxyApi
        ) {
          throw new Error('The quote proxy api is not available');
        }

        const newCluster: QuoteNodeClusterImportDTO[] = items.map((item) => ({
          parentId: item.getParentOrThrow().getId(),
          prevSiblingId: item.getPrevSibling()?.getId() ?? null,
          nextSiblingId: item.getNextSibling()?.getId() ?? null,
          cluster: {
            nodeObject: mapQuoteNodeObjectToQuoteNodeDTO(item.export()),
            childrenObject: Array.from(item.getChildren().values()).map((child) =>
              mapQuoteNodeObjectToQuoteNodeDTO(child.export()),
            ),
          },
        }));

        quoteNodeProxyApi
          .importClusters({
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
            clusters: newCluster,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        return true;
      } catch (err: any) {
        return quoteError(err.message);
      }
    },
    [
      handleComponents,
      handleItem,
      quote,
      quoteBasicItemsProxyApi,
      quoteError,
      quoteHiddenCostItemsProxyApi,
      quoteNodeProxyApi,
      quoteOptionalItemsProxyApi,
      quoteSetToStore,
      quoteSubItemsProxyApi,
      t,
    ],
  );
}
