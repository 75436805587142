import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Box, Flex, Grid, GridItem, Stack, Text } from '@chakra-ui/react';
import {
  CheckboxField,
  DateField,
  DownloadPdfIcon,
  DownloadSpreadsheetIcon,
  FileIcon,
  IconAdvanced,
  Modal,
  ModalSubtitle,
  SimpleHelpIcon,
} from '@graneet/lib-ui';
import { ACCOUNTING_JOURNAL } from '@graneet/business-logic';
import { Trans, useTranslation } from 'react-i18next';
import { Form, useForm, useFormStatus, useOnChangeValues } from 'graneet-form';
import dayjs from 'dayjs';
import { compact } from 'lodash-es';

import type { AccountingExportGenerateSelectionStepFormValues } from './AccountingExportGenerateSelectionStepFormValues';
import { AccountingJournalCheckBoxLabel } from './AccountingJournalCheckBoxLabel';
import { useNumberOfInvoicesPerJournal } from './useNumberOfInvoicesPerJournal';

import { Rule } from 'features/form/rules/Rule';

interface AccountingExportGenerateSelectionStepProps {
  onExport(
    formValues: AccountingExportGenerateSelectionStepFormValues,
    selectedAccountingJournals: ACCOUNTING_JOURNAL[],
  ): Promise<void>;

  isLoading: boolean;

  onClose(): void;
}

export const AccountingExportGenerateSelectionStep: FC<AccountingExportGenerateSelectionStepProps> = ({
  onExport,
  isLoading,
  onClose,
}) => {
  const { t } = useTranslation(['accounting', 'global']);

  const form = useForm<AccountingExportGenerateSelectionStepFormValues>();
  const numberOfInvoicesPerJournal = useNumberOfInvoicesPerJournal(form);

  const { isValid: isFormValid } = useFormStatus(form);
  const journalFormValues = useOnChangeValues(form, [
    'containsSalesJournal',
    'containsPurchasesJournal',
    'containsBankJournal',
    'toDate',
    'fromDate',
  ]);

  const selectedAccountingJournals = useMemo(
    () =>
      compact([
        journalFormValues.containsBankJournal && numberOfInvoicesPerJournal.data?.journalBank.exportable !== 0
          ? ACCOUNTING_JOURNAL.BANK
          : undefined,
        journalFormValues.containsPurchasesJournal && numberOfInvoicesPerJournal.data?.journalPurchases.exportable !== 0
          ? ACCOUNTING_JOURNAL.PURCHASES
          : undefined,
        journalFormValues.containsSalesJournal && numberOfInvoicesPerJournal.data?.journalSales.exportable !== 0
          ? ACCOUNTING_JOURNAL.SALES
          : undefined,
      ]),
    [
      journalFormValues.containsBankJournal,
      journalFormValues.containsPurchasesJournal,
      journalFormValues.containsSalesJournal,
      numberOfInvoicesPerJournal.data?.journalBank,
      numberOfInvoicesPerJournal.data?.journalPurchases,
      numberOfInvoicesPerJournal.data?.journalSales,
    ],
  );

  const hasAtLeastOneJournalSelected = selectedAccountingJournals.length > 0;
  const isValidDate =
    !!journalFormValues?.fromDate &&
    !!journalFormValues?.toDate &&
    (dayjs(journalFormValues.fromDate).isBefore(journalFormValues.toDate) ||
      dayjs(journalFormValues.fromDate).isSame(journalFormValues.toDate));

  const handleExport = useCallback(async () => {
    await onExport(form.getFormValues() as AccountingExportGenerateSelectionStepFormValues, selectedAccountingJournals);
  }, [form, onExport, selectedAccountingJournals]);

  useEffect(() => {
    form.setFormValues({
      containsAssociatedPdfs: false,
      containsFecFormat: false,
      containsBankJournal: false,
      containsExcelFormat: false,
      containsPurchasesJournal: false,
      containsSalesJournal: false,
    });
  }, [form]);

  return (
    <Form form={form}>
      <Stack>
        <Grid templateColumns="repeat(2, auto)" gap={3}>
          <GridItem>
            <DateField<AccountingExportGenerateSelectionStepFormValues>
              name="fromDate"
              label={t('accounting:fields.fromDate')}
              inputProps={{ w: '100%' }}
            >
              <Rule.IsRequired />
            </DateField>
          </GridItem>

          <GridItem>
            <DateField<AccountingExportGenerateSelectionStepFormValues>
              name="toDate"
              label={t('accounting:fields.toDate')}
              inputProps={{ w: '100%' }}
            >
              <Rule.IsRequired />
            </DateField>
          </GridItem>
        </Grid>

        <Box>
          <ModalSubtitle>{t('accounting:exportModal.sections.include')}</ModalSubtitle>

          <Stack gap={3}>
            <CheckboxField<AccountingExportGenerateSelectionStepFormValues>
              name="containsSalesJournal"
              isDisabled={numberOfInvoicesPerJournal.data?.journalSales.exportable === 0}
              label={
                <AccountingJournalCheckBoxLabel
                  journal={ACCOUNTING_JOURNAL.SALES}
                  numberOfInvoicesPerJournal={numberOfInvoicesPerJournal}
                />
              }
            />
            <CheckboxField<AccountingExportGenerateSelectionStepFormValues>
              name="containsPurchasesJournal"
              isDisabled={numberOfInvoicesPerJournal.data?.journalPurchases.exportable === 0}
              label={
                <Flex gap={3}>
                  <AccountingJournalCheckBoxLabel
                    journal={ACCOUNTING_JOURNAL.PURCHASES}
                    numberOfInvoicesPerJournal={numberOfInvoicesPerJournal}
                  />
                  <IconAdvanced
                    icon={<SimpleHelpIcon />}
                    tooltip={t('accounting:exportModal.warningPurchasesExport')}
                  />
                </Flex>
              }
            />
            <CheckboxField<AccountingExportGenerateSelectionStepFormValues>
              name="containsBankJournal"
              isDisabled={numberOfInvoicesPerJournal.data?.journalBank.exportable === 0}
              label={
                <AccountingJournalCheckBoxLabel
                  journal={ACCOUNTING_JOURNAL.BANK}
                  numberOfInvoicesPerJournal={numberOfInvoicesPerJournal}
                />
              }
            />
          </Stack>
        </Box>

        <Box>
          <ModalSubtitle>{t('accounting:exportModal.sections.format')}</ModalSubtitle>

          <Stack gap={3}>
            <CheckboxField<AccountingExportGenerateSelectionStepFormValues>
              name="containsFecFormat"
              label={
                <Flex alignItems="center">
                  <Trans t={t} i18nKey="accounting:exportModal.fec">
                    <FileIcon mx={2} />

                    <Text color="gray.600" />
                  </Trans>
                </Flex>
              }
            />
            <CheckboxField<AccountingExportGenerateSelectionStepFormValues>
              name="containsExcelFormat"
              label={
                <Flex alignItems="center">
                  {t('accounting:exportModal.excel')}
                  <DownloadSpreadsheetIcon ml={2} />
                </Flex>
              }
            />
          </Stack>
        </Box>

        <Box>
          <ModalSubtitle>{t('accounting:exportModal.sections.invoices')}</ModalSubtitle>

          <CheckboxField<AccountingExportGenerateSelectionStepFormValues>
            name="containsAssociatedPdfs"
            label={
              <Flex alignItems="center">
                {t('accounting:exportModal.includePdf')}
                <DownloadPdfIcon ml={2} />
              </Flex>
            }
          />
        </Box>
      </Stack>

      <Modal.SecondaryButton onClick={onClose} isDisabled={isLoading}>
        {t('global:words.c.cancel')}
      </Modal.SecondaryButton>

      <Modal.PrimaryButton
        isDisabled={
          !isFormValid || !hasAtLeastOneJournalSelected || numberOfInvoicesPerJournal.isLoading || !isValidDate
        }
        isLoading={isLoading}
        onClick={handleExport}
      >
        {t('accounting:exportModal.cta')}
      </Modal.PrimaryButton>
    </Form>
  );
};
