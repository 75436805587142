import { Checkbox, Flex } from '@chakra-ui/react';
import { GraneetIconButton, Tooltip } from '@graneet/lib-ui';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuoteBasicItemCreate } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemCreate';
import { useQuoteComponentCreate } from 'features/quotation/quote-component/hooks/useQuoteComponentCreate';
import { useQuoteLotCreate } from 'features/quotation/quote-lot/hooks/useQuoteLotCreate';
import { useQuoteOptionalItemCreate } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemCreate';
import { useQuoteSubItemCreate } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemCreate';
import type { QuoteAGGridType } from 'features/quotation/ag-grid-quote/constants/agGridConstants';
import { useQuoteHiddenCostItemCreate } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemCreate';
import { useStore } from 'store/store';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import {
  appendQuoteCollapsedRow,
  appendQuoteExpandedRow,
  removeQuoteCollapsedRow,
  removeQuoteExpandedRow,
  updateQuoteAreAllRowsCollapsed,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { umamiTrackEvent } from 'features/app/hooks/umami/umamiTrackEvent';

const MAX_BULK_ACTION_SELECTED_ITEMS = 15;

export const LeftActionCell = (
  params: CustomCellRendererProps<QuoteNodeObject> & {
    type: QuoteAGGridType;
  },
) => {
  const isEditable = useStore((store) => store.quoteEditable);
  const { t } = useTranslation(['quote']);

  const quoteBasicItemCreate = useQuoteBasicItemCreate();
  const quoteLotCreate = useQuoteLotCreate();
  const quoteComponentCreate = useQuoteComponentCreate();
  const quoteSubItemCreate = useQuoteSubItemCreate();
  const quoteOptionalItemCreate = useQuoteOptionalItemCreate();
  const quoteHiddenCostItemCreate = useQuoteHiddenCostItemCreate();
  const { quote } = useQuote();
  const { executeCommand } = useCommands();

  const nbSelectedRows = useStore((state) => state.quoteNbSelectedRows);
  const nbHiddenCostSelectedRows = useStore((state) => state.quoteHiddenCostNbSelectedRows);

  const { node, data, api } = params;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isSelected = useMemo(() => node.isSelected(), [node, nbSelectedRows, nbHiddenCostSelectedRows]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (nbSelectedRows + nbHiddenCostSelectedRows < MAX_BULK_ACTION_SELECTED_ITEMS) {
        node.setSelected(!isSelected);
      }
    },
    [isSelected, nbHiddenCostSelectedRows, nbSelectedRows, node],
  );

  const handleAddClick = useCallback(() => {
    if (!data || !data.content || !data.parentId) {
      return;
    }

    switch (data.content.type) {
      case 'QuoteBasicItem':
        executeCommand(
          quoteBasicItemCreate(data.parentId, {
            prevSiblingId: data.id,
            nextSiblingId: data.nextSiblingId,
          }),
          quote,
        );
        umamiTrackEvent({
          feature: 'quotation',
          fromZone: 'leftActionCell',
          action: 'create',
          entity: 'basicItem',
        });
        break;
      case 'QuoteLot':
        executeCommand(
          quoteLotCreate(data.parentId, {
            prevSiblingId: data.id,
            nextSiblingId: data.nextSiblingId,
          }),
          quote,
        );
        break;
      case 'QuoteComponent':
        executeCommand(
          quoteComponentCreate(data.parentId, {
            prevSiblingId: data.id,
            nextSiblingId: data.nextSiblingId,
          }),
          quote,
        );
        break;
      case 'QuoteSubItem':
        executeCommand(
          quoteSubItemCreate(data.parentId, {
            prevSiblingId: data.id,
            nextSiblingId: data.nextSiblingId,
          }),
          quote,
        );
        break;
      case 'QuoteOptionalItem':
        executeCommand(
          quoteOptionalItemCreate(data.parentId, {
            prevSiblingId: data.id,
            nextSiblingId: data.nextSiblingId,
          }),
          quote,
        );
        break;
      case 'QuoteHiddenCostItem':
        executeCommand(
          quoteHiddenCostItemCreate({
            prevSiblingId: data.id,
            nextSiblingId: data.nextSiblingId,
          }),
          quote,
        );
        break;
      default:
        throw new Error('Unsupported type');
    }
  }, [
    data,
    executeCommand,
    quote,
    quoteBasicItemCreate,
    quoteComponentCreate,
    quoteHiddenCostItemCreate,
    quoteLotCreate,
    quoteOptionalItemCreate,
    quoteSubItemCreate,
  ]);

  const [isExpanded, setIsExpanded] = useState(node.expanded);

  useEffect(() => {
    setIsExpanded(node.expanded);
  }, [node]);

  const handleCollapse = useCallback(() => {
    api.setRowNodeExpanded(node, false);
    setIsExpanded(false);
    if (node.key) {
      appendQuoteCollapsedRow(node.key);
      removeQuoteExpandedRow(node.key);
    }
    api.refreshHeader();
  }, [api, node]);

  const handleExpand = useCallback(() => {
    api.setRowNodeExpanded(node, true);
    setIsExpanded(true);
    if (node.key) {
      appendQuoteExpandedRow(node.key);
      removeQuoteCollapsedRow(node.key);
      updateQuoteAreAllRowsCollapsed(false);
    }
    api.refreshHeader();
  }, [api, node]);

  const hasChildren = useMemo(() => (node.childrenAfterGroup?.length ?? 0) > 0, [node]);

  return (
    <Flex py="10px" fontSize="0.813rem" gap="1px">
      <Checkbox size="md" onChange={handleChange} isChecked={isSelected} colorScheme="greenBrand" mr="4px" />
      {isEditable && (
        <Tooltip label={t('quote:quotation.tooltips.addLine')} placement="right" shouldWrapChildren>
          <GraneetIconButton color="ghost" icon="ri-add-fill" size="xs" onClick={handleAddClick} />
        </Tooltip>
      )}
      {hasChildren && (
        <Tooltip
          label={t(isExpanded ? 'quote:quotation.tooltips.collapse' : 'quote:quotation.tooltips.expand')}
          placement="left"
          shouldWrapChildren
        >
          <GraneetIconButton
            size="xs"
            color="ghost"
            icon={isExpanded ? 'ri-arrow-down-s-line' : 'ri-arrow-right-s-line'}
            onClick={isExpanded ? handleCollapse : handleExpand}
          />
        </Tooltip>
      )}
    </Flex>
  );
};
