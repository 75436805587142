import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { Form, useForm } from 'graneet-form';
import type { ILibraryJobResponseDTO } from '@graneet/business-logic';
import { Modal, useCurrency } from '@graneet/lib-ui';

import { useLibraryJobDelete } from '../../services/library-job.api';
import { LibraryJobFields } from '../LibraryJobFields';

import type { LibraryJobFieldsForm } from 'features/library-job/types/library-job.type';

type LibraryJobDeleteModalProps = {
  libraryJobToRemove: ILibraryJobResponseDTO;
  modal: UseDisclosureReturn;
  onLibraryJobRemoved(): void;
};

export const LibraryJobDeleteModal: FC<LibraryJobDeleteModalProps> = ({
  libraryJobToRemove,
  modal,
  onLibraryJobRemoved,
}) => {
  const { t } = useTranslation(['global', 'library']);
  const { mapNumberToAmount } = useCurrency();
  const form = useForm<LibraryJobFieldsForm>({
    defaultValues: {
      ...libraryJobToRemove,
      unitDisbursementExVAT: mapNumberToAmount(libraryJobToRemove.unitDisbursementExVAT),
    },
  });

  const libraryJobDeleteMutation = useLibraryJobDelete();

  const handleSubmit = useCallback(async () => {
    libraryJobDeleteMutation.mutate(libraryJobToRemove.id, {
      onSuccess: () => {
        onLibraryJobRemoved();
        modal.onClose();
      },
    });
  }, [libraryJobDeleteMutation, libraryJobToRemove.id, modal, onLibraryJobRemoved]);

  return (
    <Modal title={t('library:removeLibraryJobModal.title')} size="5xl" isOpen={modal.isOpen} onClose={modal.onClose}>
      <Form form={form}>
        <Text mb={4}>{t('library:removeLibraryJobModal.description')}</Text>
        <LibraryJobFields isDisabled />
      </Form>

      <Modal.Close isDisabled={libraryJobDeleteMutation.isPending} />

      <Modal.PrimaryButton isLoading={libraryJobDeleteMutation.isPending} colorScheme="red" onClick={handleSubmit}>
        {t('library:removeLibraryJobModal.cta')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
