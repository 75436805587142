import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { IAccountingDataResponseDTO } from '@graneet/business-logic';
import { Box, useDisclosure, Flex } from '@chakra-ui/react';
import { Button, Input, Modal, SimpleAlertIcon, SimpleArrowUpRightIcon } from '@graneet/lib-ui';
import { Form, HiddenField, useForm } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { differenceBy } from 'lodash-es';

import type { AccountingSupplierEditionBlockForm } from '../../../forms/accounting.form';
import { getAccountingSupplierAuxiliaryAccountFieldName } from '../../../forms/accounting.form';
import { AccountingSupplierEditionBlock } from '../AccountingSupplierEditionBlock';
import { populateDTOFromAccountingConfigForm } from '../../../forms/accounting-form.util';
import { useUpdateAccountingConfig } from '../../../services/accounting-config.api';

import { filterOnlyContaining } from 'features/common/services/common.util';

const AccountingSupplierEditionModal: FC<{
  isOpen: boolean;
  onClose(): void;
  suppliers: IAccountingDataResponseDTO['suppliers'];
}> = ({ isOpen, onClose, suppliers }) => {
  const { t } = useTranslation(['accounting', 'global']);

  const updateAccountingConfigMutation = useUpdateAccountingConfig();

  const form = useForm<AccountingSupplierEditionBlockForm>();
  useEffect(() => {
    const formValues: Partial<AccountingSupplierEditionBlockForm> = {};
    suppliers.forEach((supplier) => {
      formValues[getAccountingSupplierAuxiliaryAccountFieldName(supplier.id)] = supplier.auxiliaryAccount;
    });

    form.setFormValues(formValues);
  }, [form, suppliers]);

  const [search, setSearch] = useState('');
  const filteredSuppliers = useMemo(
    () => filterOnlyContaining(suppliers, ['code', 'name', 'auxiliaryAccount'], search),
    [search, suppliers],
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const formValues = form.getFormValues();

    await updateAccountingConfigMutation.mutateAsync(populateDTOFromAccountingConfigForm(formValues));
    setIsSubmitting(false);

    onClose();
  }, [form, onClose, updateAccountingConfigMutation]);

  const filteredOutSuppliers = useMemo(
    () => differenceBy(suppliers, filteredSuppliers, (s) => s.id).map((s) => s.id),
    [filteredSuppliers, suppliers],
  );

  return (
    <Form form={form}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={t('accounting:supplierEditionModal.title')}
        size="3xl"
        scrollBehavior="inside"
      >
        <Box mb={4}>
          <Input
            w={['100%', '100%', '20rem']}
            placeholder={t('global:words.c.research')}
            onChange={setSearch}
            value={search}
          />
        </Box>

        <AccountingSupplierEditionBlock suppliers={filteredSuppliers} />

        {/* If the supplier is not displayed, render a hidden field to not lose the value */}
        {filteredOutSuppliers.map((supplierId) => (
          <HiddenField<AccountingSupplierEditionBlockForm>
            key={supplierId}
            name={getAccountingSupplierAuxiliaryAccountFieldName(supplierId)}
          />
        ))}

        <Modal.SecondaryButton onClick={onClose} isDisabled={isSubmitting}>
          {t('global:words.c.back')}
        </Modal.SecondaryButton>

        <Modal.PrimaryButton onClick={onSubmit} isLoading={isSubmitting}>
          {t('accounting:supplierEditionModal.cta')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};

interface AccountingSupplierEditionButtonProps {
  suppliers: IAccountingDataResponseDTO['suppliers'];
}

export const AccountingSupplierEditionButton: FC<AccountingSupplierEditionButtonProps> = ({ suppliers }) => {
  const { t } = useTranslation(['accounting']);

  const modal = useDisclosure();

  const suppliersWithMissingAuxiliaryAccount = useMemo(
    () => suppliers.filter((supplier) => !supplier.auxiliaryAccount),
    [suppliers],
  );

  const numberOfMissingCodes = suppliersWithMissingAuxiliaryAccount.length;

  if (numberOfMissingCodes === 0) {
    return null;
  }

  return (
    <Flex w="20rem" justifyContent="center">
      <Button
        variant="outline"
        colorScheme="gray"
        size="sm"
        onClick={modal.onOpen}
        leftIcon={<SimpleAlertIcon stroke="yellow.500" />}
        rightIcon={<SimpleArrowUpRightIcon />}
      >
        {t('accounting:missingSupplierAuxiliaryCode', { count: numberOfMissingCodes })}
      </Button>

      <AccountingSupplierEditionModal
        onClose={modal.onClose}
        isOpen={modal.isOpen}
        suppliers={suppliersWithMissingAuxiliaryAccount}
      />
    </Flex>
  );
};
