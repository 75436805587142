import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import type { ILibraryJobResponseDTO } from '@graneet/business-logic';
import { Modal, useCurrency } from '@graneet/lib-ui';

import { useLibraryJobDuplicate } from '../../services/library-job.api';
import { LibraryJobFields } from '../LibraryJobFields';

import type { LibraryJobFieldsForm } from 'features/library-job/types/library-job.type';

const FORM_ID = 'library-job-duplicate';

type LibraryJobDuplicateModalProps = {
  libraryJobToDuplicate: ILibraryJobResponseDTO;
  modal: UseDisclosureReturn;
  onLibraryJobDuplicated(): void;
  isUnitDisbursementEditable?: boolean;
};

export const LibraryJobDuplicateModal: FC<LibraryJobDuplicateModalProps> = ({
  libraryJobToDuplicate,
  modal,
  onLibraryJobDuplicated,
  isUnitDisbursementEditable = false,
}) => {
  const { t } = useTranslation(['global', 'library']);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();
  const form = useForm<LibraryJobFieldsForm>({
    defaultValues: {
      ...libraryJobToDuplicate,
      unitDisbursementExVAT: mapNumberToAmount(libraryJobToDuplicate.unitDisbursementExVAT),
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const libraryJobDuplicate = useLibraryJobDuplicate();

  const handleSubmit = useCallback(
    async (formValues: LibraryJobFieldsForm) => {
      libraryJobDuplicate.mutate(
        {
          duplicatedId: libraryJobToDuplicate.id,
          dto: {
            ...formValues,
            unitDisbursementExVAT: mapAmountToNumber(formValues.unitDisbursementExVAT!),
          },
        },
        {
          onSuccess: () => {
            modal.onClose();
            onLibraryJobDuplicated();
          },
        },
      );
    },
    [libraryJobDuplicate, libraryJobToDuplicate.id, mapAmountToNumber, modal, onLibraryJobDuplicated],
  );

  return (
    <Modal title={t('library:duplicateLibraryJobModal.title')} size="5xl" isOpen={modal.isOpen} onClose={modal.onClose}>
      <Form form={form} id={FORM_ID} onSubmit={form.handleSubmit(handleSubmit)}>
        <Text mb={4}>{t('library:duplicateLibraryJobModal.description')}</Text>
        <LibraryJobFields isUnitDisbursementEditable={isUnitDisbursementEditable} />
      </Form>

      <Modal.Close isDisabled={libraryJobDuplicate.isPending} />

      <Modal.PrimaryButton
        isLoading={libraryJobDuplicate.isPending}
        isDisabled={!isFormValid}
        form={FORM_ID}
        type="submit"
      >
        {t('library:duplicateLibraryJobModal.cta')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
