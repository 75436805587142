import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

import * as lotsApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Deletes a lot
 */
export const useDeleteLot = () => {
  const { t } = useTranslation(['quote']);
  const toast = useToast();

  const {
    displayNeedsReload,
    findOwnerLotIdFromLotIdInRelations,
    deleteLotAndItsSubLotsLocally,
    updateDataLocally,
    getCurrentQuoteData,
  } = useQuoteEditContext();

  return useCallback(
    async (lotId: number) => {
      const { lots, relations } = getCurrentQuoteData();

      // Call api to delete the job
      const [err, quoteInfos] = await lotsApi.deleteLot(lotId);

      const errorMessage = t('quote:jobsStep.errors.deletingLotError');

      // Return if it failed and notify user
      if (err) {
        toast.error(errorMessage);
        return;
      }

      // Quit the lot could not be found locally
      if (!lots[lotId]) {
        displayNeedsReload(errorMessage);
        return;
      }

      // Quit the lot's lot could not be found locally
      const ownerLotId = findOwnerLotIdFromLotIdInRelations(lotId)!;
      const ownerLotRelations = relations[ownerLotId];
      if (!ownerLotRelations) {
        displayNeedsReload(errorMessage);
        return;
      }

      // Quit if the lot could not be found in the lots
      const lotsOfLot = ownerLotRelations.lots;
      const lotPosition = lotsOfLot.indexOf(lotId);
      if (lotPosition < 0) {
        displayNeedsReload(errorMessage);
        return;
      }

      // Display success toast
      toast.success(<Trans t={t} i18nKey="quote:jobsStep.successes.deletingLot" />);

      // Delete lot from local relations
      deleteLotAndItsSubLotsLocally(lotId);
      ownerLotRelations.lots.splice(lotPosition, 1);

      // Update interface
      updateDataLocally(quoteInfos);
    },
    [
      toast,
      t,
      updateDataLocally,
      displayNeedsReload,
      getCurrentQuoteData,
      deleteLotAndItsSubLotsLocally,
      findOwnerLotIdFromLotIdInRelations,
    ],
  );
};
