import type { KeysMatching } from './types/object.type';

export const MAXIMUM_FRACTIONAL_PART_LENGTH = 10;

// Method using IEEE 754 standard for rounding
// https://en.wikipedia.org/wiki/Rounding#Round_half_away_from_zero
export const roundFloating = (x: number): number => Math.sign(x) * Math.round(Math.abs(x));

// Method to handle issues with floating calculations
export const multiplyFloating = (x: number, y: number): number => {
  function fl(z: number): number {
    // Length of fractional part
    const parts = z.toString().split('.');
    return parts.length < 2 ? 0 : parts[1].length;
  }
  const f = 10 ** (fl(x) + fl(y));
  return roundFloating(x * y * f) / f;
};

export const divideFloating = (x: number, y: number): number => {
  function fl(z: number): number {
    // Length of fractional part
    const parts = z.toString().split('.');
    return parts.length < 2 ? 0 : parts[1].length;
  }

  const maxFractionalLength = Math.max(fl(x), fl(y), MAXIMUM_FRACTIONAL_PART_LENGTH);
  return parseFloat((x / y).toFixed(maxFractionalLength));
};

export const sumObjects = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Item extends Record<string, any>,
  K extends KeysMatching<Item, number | null>,
>(
  objects: Item[],
  fieldToUse: K,
): number => objects.reduce<number>((acc: number, cur: Item) => acc + cur[fieldToUse], 0);

export const getFloatingPercentage = (dividend: number, divider: number): number => {
  if (!dividend || !divider) {
    return 0;
  }

  return multiplyFloating(divideFloating(dividend, divider), 100);
};

export const getPercentage = (dividend: number, divider: number): number => {
  const floatingPercentage = getFloatingPercentage(dividend, divider);

  return roundFloating(floatingPercentage);
};

export const getAmountPercentage = (value?: number | null, percentage?: number | null): number => {
  if (!percentage || !value) {
    return 0;
  }
  const result = multiplyFloating(multiplyFloating(value, percentage), 0.01);
  return roundFloating(result);
};
