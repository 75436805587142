import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import type { CenterProps } from '@chakra-ui/react';
import { Box, useTheme } from '@chakra-ui/react';

export const BORDER_RADIUS = 'md';

export type ImageListThumbnailSize = 'small' | 'medium' | 'large';

export type ImageListThumbnailBorder = 'dashed' | 'solid';

function mapThumbnailSizeToBoxSize(size: ImageListThumbnailSize): string | number {
  switch (size) {
    case 'small':
      return '150px';
    case 'medium':
      return '171px';
    case 'large':
      return '200px';
    default:
      return 0;
  }
}

type ImageListThumbnailProps = PropsWithChildren<{
  size: ImageListThumbnailSize;
  border: ImageListThumbnailBorder;
}> &
  CenterProps;

export const ImageListThumbnail: FC<ImageListThumbnailProps> = ({ size, children, border, ...centerProps }) => {
  const boxSize = useMemo(() => mapThumbnailSizeToBoxSize(size), [size]);
  const {
    colors: { gray },
  } = useTheme();
  return (
    <Box
      position="relative"
      boxSize={boxSize}
      borderRadius={BORDER_RADIUS}
      bg="white"
      p={2}
      {...centerProps}
      border={`2px ${border} ${gray[500]}`}
    >
      {children}
    </Box>
  );
};
