import { Box, VStack } from '@chakra-ui/react';
import { AgGridReact } from '@ag-grid-community/react';
import type { ChangeEvent, FC, RefObject } from 'react';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { QuoteComponentsSupplyObject, Quote } from '@org/quotation-lib';
import { HashAsset, QuoteComponent, QuoteGetComponentsSupply } from '@org/quotation-lib';
import { useTranslation } from 'react-i18next';
import type { RichTextGetterHandler } from '@graneet/lib-ui';
import { QuotationInput, RichTextGetter } from '@graneet/lib-ui';
import type { SelectionChangedEvent } from '@ag-grid-community/core';

import { useGridComponentsSupplyColumnDefs } from '../hooks/useGridComponentsSupplyColumnDefs';
import { useDefaultColDef } from '../hooks/useDefaultColDef';

import { updateQuoteDrawerRichTextEditorRef } from 'features/quotation/quote-common/store/quoteUpdateZustand';

export interface AgGridQuoteComponentsSupplyProps {
  quote: Quote;
  gridRef: RefObject<AgGridReact<QuoteComponentsSupplyObject>>;
  onRowSelected?: (rowsSelected: unknown[]) => void;
}

export const AgGridQuoteComponentsSupply: FC<AgGridQuoteComponentsSupplyProps> = memo(
  ({ quote, gridRef, onRowSelected }) => {
    const { t } = useTranslation(['global']);

    const [input, setInput] = useState<string | null>(null);

    const defaultColDef = useDefaultColDef();

    const columnDefs = useGridComponentsSupplyColumnDefs();

    const rowsData = useMemo(() => {
      const componentsSupply = QuoteGetComponentsSupply.execute(quote);

      return Array.from(componentsSupply.values());
    }, [quote]);

    const onFilterTextBoxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setInput(e.currentTarget.value);
    }, []);

    const handleSelectionChanged = useCallback(
      (event: SelectionChangedEvent) => {
        onRowSelected?.(event.api.getSelectedRows());
      },
      [onRowSelected],
    );

    const editorRef = useRef<RichTextGetterHandler>(null);

    useEffect(() => {
      updateQuoteDrawerRichTextEditorRef(editorRef);
    }, []);

    return (
      <VStack align="stretch" w="100%" h="100%" gap={4}>
        <Box paddingLeft="1rem" width="300px">
          <QuotationInput
            placeholder={t('global:words.c.research')}
            onChange={onFilterTextBoxChanged}
            icon={<i className="ri-search-line" />}
            pl={30}
          />
        </Box>
        <Box className="ag-theme-quartz quotation component-type" width="100%" height="100%">
          <AgGridReact
            ref={gridRef}
            rowData={rowsData}
            defaultColDef={defaultColDef}
            getRowId={(params) => HashAsset.hashComponent(new QuoteComponent(params.data))}
            columnDefs={columnDefs}
            rowSelection="multiple"
            rowHeight={45}
            reactiveCustomComponents
            headerHeight={44}
            onSelectionChanged={handleSelectionChanged}
            suppressNoRowsOverlay
            suppressContextMenu
            quickFilterText={input ?? ''}
            singleClickEdit
            suppressRowClickSelection
          />
        </Box>
        <RichTextGetter ref={editorRef} />
      </VStack>
    );
  },
);
