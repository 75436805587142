import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Grid, useDisclosure } from '@chakra-ui/react';
import { Button, Container, Section, SimpleAddIcon, useToast } from '@graneet/lib-ui';
import type { IPaymentMethodResponseDTO } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { usePaymentMethods, useUpdatePaymentMethod } from 'features/payment-method/services/payment-method.api';
import { PaymentDeadlineCard } from 'features/payment/components/PaymentDeadlineCard';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { useCompanyPaymentTerms, useUpdateCompanyPaymentTerms } from 'features/company/services/company.api';
import { PaymentMethodEditionAndSelectionCard } from 'features/payment-method/components/PaymentMethodEditionAndSelectionCard';
import { PaymentMethodCreateModal } from 'features/payment-method/components/modals/PaymentMethodCreateModal/PaymentMethodCreateModal';
import { PaymentMethodCardList } from 'features/payment-method/components/PaymentMethodCardList';

const PERMISSION_UPDATE_COMPANY_INFOS = [PERMISSION.UPDATE_COMPANY_INFORMATIONS];

export const PaymentMethodsSettingsTab: FC = () => {
  const { t } = useTranslation(['paymentMethods']);

  const hasUpdateCompanyParametersPermission = usePermissions(PERMISSION_UPDATE_COMPANY_INFOS);
  const updateCompanyParametersButtonDisabledProps = useDisabledButtonProps(PERMISSION_UPDATE_COMPANY_INFOS);

  const paymentMethods = usePaymentMethods();
  const paymentTerms = useCompanyPaymentTerms();

  const updatePaymentMethodMutation = useUpdatePaymentMethod();

  // Creating payment method
  const creationModal = useDisclosure();

  const onPaymentMethodCreated = useCallback(() => {
    creationModal.onClose();
  }, [creationModal]);

  // Updating a payment method
  const updateModal = useDisclosure();

  const handlePaymentMethodUpdated = useCallback(() => {
    updateModal.onClose();
  }, [updateModal]);

  const otherPaymentMethods = paymentMethods.data.filter((paymentMethod) => !paymentMethod.isDefault) ?? [];
  const defaultPaymentMethod = paymentMethods.data.find((paymentMethod) => paymentMethod.isDefault) ?? null;

  const toast = useToast();

  const handleNewDefaultPaymentMethod = useCallback(
    async (newDefaultPaymentMethod: IPaymentMethodResponseDTO | null) => {
      if (!newDefaultPaymentMethod) {
        toast.error('global:errors.error');
        return;
      }

      updatePaymentMethodMutation.mutate({ id: newDefaultPaymentMethod.id, dto: { isDefault: true } });
    },
    [updatePaymentMethodMutation, toast],
  );

  /**
   * Do not display page content where there is not default payment method and where a no longer in loading et there is no error.
   * This is not optimal from user side perspective, he can't create one without CS intervention, but it was initially developed like that
   */
  const isPaymentMethodsNull = useCallback(
    (methods: IPaymentMethodResponseDTO[] | null): methods is null => methods === null,
    [],
  );

  const isDefaultPaymentMethodNull = useCallback(
    (defaultPM: IPaymentMethodResponseDTO | null): defaultPM is null => defaultPM === null,
    [],
  );

  const updateCompanyPaymentTermsMutation = useUpdateCompanyPaymentTerms();

  if (isPaymentMethodsNull(paymentMethods.data) || isDefaultPaymentMethodNull(defaultPaymentMethod)) {
    return null;
  }

  return (
    <Container size="md">
      <Section title={t('paymentMethods:companyDefaultPaymentMethod')}>
        <Grid alignItems="flex-start" templateColumns="1fr" gap={6}>
          <PaymentMethodEditionAndSelectionCard
            paymentMethod={defaultPaymentMethod}
            paymentMethods={paymentMethods.data}
            selectNewPaymentMethodLabel={t('paymentMethods:cta.updateDefaultPaymentMethod')}
            selectPaymentMethodModalDescription={t('paymentMethods:modal.select.desc.settings')}
            hasUpdatePermission={hasUpdateCompanyParametersPermission}
            onPaymentMethodUpdated={handlePaymentMethodUpdated}
            onNewPaymentMethodSelected={handleNewDefaultPaymentMethod}
          />

          <PaymentDeadlineCard
            modalTitle={t('paymentMethods:deadline.modal.update.defaultTitle')}
            paymentInfos={paymentTerms.data}
            hasUpdatePermission={hasUpdateCompanyParametersPermission}
            onPaymentInfosUpdate={updateCompanyPaymentTermsMutation.mutateAsync}
          />
        </Grid>
      </Section>

      <Section
        title={t('paymentMethods:companyOtherPaymentMethods')}
        topContent={
          hasUpdateCompanyParametersPermission && (
            <Flex w="100%" justifyContent="flex-end">
              <Button
                onClick={creationModal.onOpen}
                variant="outline"
                leftIcon={<SimpleAddIcon />}
                {...updateCompanyParametersButtonDisabledProps()}
              >
                {t('paymentMethods:cta.create')}
              </Button>
            </Flex>
          )
        }
      >
        <PaymentMethodCreateModal
          isOpen={creationModal.isOpen}
          onClose={creationModal.onClose}
          onCreated={onPaymentMethodCreated}
        />

        <PaymentMethodCardList
          paymentMethods={otherPaymentMethods}
          hasUpdatePermission={hasUpdateCompanyParametersPermission}
          pb={12}
          onPaymentMethodUpdated={handlePaymentMethodUpdated}
        />
      </Section>
    </Container>
  );
};
