import { Badge, type DocumentStatusCardAction } from '@graneet/lib-ui';
import { t } from 'i18next';
import { isSupplierInvoiceIncomplete, SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';

import type { SupplierInvoiceActionsType } from './supplier-invoice.actions.type';
import {
  getSupplierInvoiceAddPaymentActions,
  getSupplierInvoiceMenuAction,
  getSupplierInvoiceUpdatePaymentAction,
} from './supplier-invoice.actions.util';

export function getSupplierInvoiceToProcessActions(params: SupplierInvoiceActionsType): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  if (params.permissions.canAddPayment) {
    actions.push(...getSupplierInvoiceAddPaymentActions(params));
  }

  const processState = (() => {
    // Do not have permission
    if (!params.permissions.hasUpdateStatusPermission) {
      return {
        isDisabled: true,
        tooltip: t('global:errors.disabledAction'),
      };
    }

    // Have missing information
    if (isSupplierInvoiceIncomplete(params.supplierInvoice)) {
      return {
        isDisabled: true,
        tooltip: t('supplierInvoices:tooltips.cannotChangeToToPayBecauseMissingInformation'),
      };
    }

    return {
      isDisabled: false,
      tooltip: undefined,
    };
  })();

  // @[ff: workflow-validation]
  if (params.hasWorkflowValidationFeatureFlag) {
    actions.push({
      type: 'primary',
      label: t(`supplierInvoices:statuses.VALIDATING`),
      onClick: params.callbacks.onUpdateToPay,
      isDisabled: processState.isDisabled,
      tooltip: processState.tooltip,
    });
  } else {
    actions.push({
      type: 'secondaryDropdown',
      dropDownLabel: t('global:words.markAs'),
      options: [
        {
          label: <Badge colorScheme="blue">{t(`supplierInvoices:statuses.${SUPPLIER_INVOICE_STATUS.TO_PAY}`)}</Badge>,
          onClick: params.callbacks.onUpdateToPay,
          isDisabled: processState.isDisabled,
          tooltip: processState.tooltip,
        },
      ],
    });
  }

  actions.push(getSupplierInvoiceUpdatePaymentAction(params));

  actions.push(getSupplierInvoiceMenuAction(params));

  return actions;
}
