import type { UseDisclosureReturn } from '@chakra-ui/react';
import type { IProgressStatement } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback } from 'react';

import { useProgressStatementForceDraft } from '../services/progress-statement.api';

import { StatementForceDraftModal } from 'features/statement/components/StatementForceDraftModal';

interface ForceDraftProgressStatementModalProps {
  modal: UseDisclosureReturn;
  progressStatement: IProgressStatement;
  nextProgressStatement?: IProgressStatement;
  onConfirmed(progressStatement: IProgressStatement): void;
}

export const ForceDraftProgressStatementModal: FC<ForceDraftProgressStatementModalProps> = ({
  modal,
  progressStatement,
  nextProgressStatement,
  onConfirmed,
}) => {
  const progressStatementForceDraftMutation = useProgressStatementForceDraft();

  const handleClick = useCallback(async () => {
    await progressStatementForceDraftMutation.mutateAsync(
      {
        currentProgressStatement: progressStatement,
        nextProgressStatement,
      },
      {
        onSuccess: (ps) => {
          onConfirmed(ps);
        },
      },
    );
  }, [nextProgressStatement, onConfirmed, progressStatement, progressStatementForceDraftMutation]);

  return <StatementForceDraftModal modal={modal} onConfirmed={handleClick} />;
};
