import type { FC, UIEvent } from 'react';
import { Fragment, Suspense, useCallback, useState } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { Flex, ChakraProvider, Box, HStack, VStack } from '@chakra-ui/react';
import {
  CurrencyProvider,
  ActionMenu,
  SavingSpinner,
  Modal,
  Filters,
  AsyncTableData,
  theme,
  AmountSummary,
  DroppableFileField,
  DifferenceSummary,
  ScrollProvider,
  Placeholder,
  APP_FOOTER_PLACEHOLDER,
  RichTextInput,
  StepperItem,
  Confetti,
  Chapters,
  EmailAutocomplete,
  MultipleFileUpload,
  FileNameDetails,
  HeaderTitle,
  Header,
  EllipsisText,
  Link,
  RichText,
  EmptyStateCard,
  GenericCard,
  UpsellCard,
  FileList,
  SingleSelect,
  ListingLayout,
  Nav,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ModuleRegistry } from '@ag-grid-community/core';

import { StartOnboardingScreen } from './screens/onboarding/StartOnboardingScreen';
import { NavBar } from './features/common/components/NavBar/NavBar';
import { SentryAnalytics } from './features/analytic/components/SentryAnalytics';
import { SegmentProvider } from './features/analytic/components/SegmentProvider';
import { SegmentAnalytics } from './features/analytic/components/SegmentAnalytics';
import { QueryWrapper } from './features/api/components/QueryWrapper';
import { HeaderProvider, useHeaderContext } from './features/app/contexts/HeaderContext';
import { PaginatedTableSettingsProvider } from './features/app/components/PaginatedTableSettingsProvider';
import { ProgressStatementExternalScreen } from './screens/external/progress-statement/ProgressStatementExternalScreen';

import { AppProvider, useAppContext } from 'features/app/contexts/AppContext';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { RootScreen } from 'screens/RootScreen';
import { AuthProvider } from 'features/app/components/AuthProvider';
import { VitallyNps } from 'features/analytic/components/VitallyNps';
import { UserInvitationsScreen } from 'screens/user-invitations/UserInvitationsScreen';
import { ErrorScreen } from 'screens/ErrorScreen';
import { Palette } from 'features/common/components/Palette';
import { Beacon } from 'features/beacon/components/Beacon';
import { getEnvValue } from 'config/env';
import { NavbarProvider, useNavbarContext } from 'features/common/contexts/NavbarContext';
import { Loading } from 'features/common/components/Loading';
import { StripeProvider } from 'features/common/contexts/StripeProvider';
import { Env } from 'features/common/components/Env';
import { useStore } from 'store/store';
import { useUmami } from 'features/app/hooks/umami/useUmami';

LicenseManager.setLicenseKey(getEnvValue('REACT_APP_AG_GRID_LICENSE', true));
ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule, StatusBarModule, ExcelExportModule]);

const queryClient = new QueryClient();

const REACT_APP_AUTH0_DOMAIN = getEnvValue('REACT_APP_AUTH0_DOMAIN');
const REACT_APP_AUTH0_CLIENT_ID = getEnvValue('REACT_APP_AUTH0_CLIENT_ID');
const REACT_APP_AUTH0_REDIRECT_URI = getEnvValue('REACT_APP_AUTH0_REDIRECT_URI');
const REACT_APP_AUTH0_AUDIENCE = getEnvValue('REACT_APP_AUTH0_AUDIENCE');

const Footer: FC<{ isNavBarVisible?: boolean; isFullScreen: boolean; shouldDisplayBeacon: boolean }> = ({
  isNavBarVisible,
  isFullScreen,
  shouldDisplayBeacon,
}) => (
  // Chakra modal zIndex is 1400. The footer must be bellowed this value but greater than other UI elements
  <Box position="relative">
    {shouldDisplayBeacon && <Beacon top={isNavBarVisible || isFullScreen ? '-3.4rem' : '-7rem'} />}
    <Box zIndex={1300} borderTop="1px solid" borderTopColor="gray.300">
      <Placeholder.Value name={APP_FOOTER_PLACEHOLDER} />
    </Box>
  </Box>
);

interface ScrollProps {
  scrollRef: HTMLDivElement | null;
  setScrollRef(element: HTMLDivElement | null): void;
}

const PrivateContent: FC<ScrollProps> = ({ scrollRef, setScrollRef }) => {
  const history = useHistory();
  const { currentUser, isFullScreen } = useAppContext();
  const shouldDisplayBeacon = useStore((state) => state.shouldDisplayBeacon);
  const { title, customElement, breadCrumb } = useHeaderContext();

  const [isScrollTop, setIsScrollTop] = useState(true);
  const { isVisible } = useNavbarContext();

  const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    setIsScrollTop((e.target as HTMLDivElement).scrollTop === 0);
  }, []);
  return (
    <>
      <SentryAnalytics />

      <SegmentProvider>
        <SegmentAnalytics />
        <VitallyNps />

        <CurrencyProvider value={currentUser.company.currency}>
          <StripeProvider>
            <Placeholder.Provider>
              <Flex h="100vh" w="100vw" backgroundColor="gray.300" boxSizing="border-box" flexFlow="column">
                <Palette />
                <Flex flex={1} overflowY="auto" backgroundColor="white">
                  {isVisible && <NavBar />}
                  <ScrollProvider value={scrollRef}>
                    <Flex
                      flexGrow={1}
                      direction="column"
                      backgroundColor="white"
                      margin={0}
                      boxShadow="md"
                      position="relative"
                      overflow="auto"
                    >
                      {isVisible && (
                        <Header
                          height="auto"
                          borderTopLeftRadius={5}
                          borderTopRightRadius={5}
                          zIndex={20}
                          borderBottom={!isScrollTop ? '1px solid #D9E2EC' : undefined}
                          minHeight="4.2rem"
                        >
                          <VStack width="100%" alignItems="stretch" pt={3}>
                            {breadCrumb.length > 0 && (
                              <HStack gap={0}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="center"
                                  alignItems="center"
                                  width="20px"
                                  fontWeight={400}
                                  color="greenBrand.light"
                                  _hover={{ background: 'gray.50', borderRadius: 8, cursor: 'pointer' }}
                                  onClick={history.goBack}
                                >
                                  <i className="ri-arrow-drop-left-line" />
                                </Box>
                                {breadCrumb.map((item, index) => (
                                  <Fragment key={item.name}>
                                    {item.link ? (
                                      <Link
                                        to={item.link}
                                        pl={0.5}
                                        color="greenBrand.light"
                                        fontWeight={400}
                                        fontSize="12px"
                                        _hover={{
                                          textDecoration: 'none',
                                        }}
                                      >
                                        {item.name}
                                      </Link>
                                    ) : (
                                      <EllipsisText pl={0.5} color="greenBrand.light" fontWeight={400} fontSize="12px">
                                        {item.name}
                                      </EllipsisText>
                                    )}
                                    {index < breadCrumb.length - 1 && (
                                      <EllipsisText pl={0.5} color="greenBrand.light" fontWeight={400} fontSize="12px">
                                        /
                                      </EllipsisText>
                                    )}
                                  </Fragment>
                                ))}
                                <Env />
                              </HStack>
                            )}
                            <HStack>
                              <HeaderTitle fontWeight={400} fontSize="1.25rem" fontFamily="Alliance" maxH="2rem">
                                {title?.startsWith('{') ? <RichText value={title} /> : title}
                              </HeaderTitle>
                              {customElement}
                            </HStack>
                          </VStack>
                        </Header>
                      )}
                      <Flex
                        flexGrow={1}
                        direction="column"
                        overflowY="auto"
                        ref={setScrollRef}
                        position="relative"
                        onScroll={handleScroll}
                      >
                        <PaginatedTableSettingsProvider>
                          <RootScreen />
                        </PaginatedTableSettingsProvider>
                      </Flex>
                    </Flex>
                  </ScrollProvider>
                </Flex>

                <Footer
                  isNavBarVisible={isVisible}
                  isFullScreen={isFullScreen}
                  shouldDisplayBeacon={shouldDisplayBeacon}
                />
              </Flex>
            </Placeholder.Provider>
          </StripeProvider>
        </CurrencyProvider>
      </SegmentProvider>
    </>
  );
};

const Root: FC = () => {
  const { t, i18n } = useTranslation(['quote', 'global', 'mailing']);
  useUmami();

  ActionMenu.configureDefaultLabels({
    update: t('global:words.c.update'),
    duplicate: t('global:words.c.duplicate'),
    delete: t('global:words.c.delete'),
  });

  AmountSummary.VATDistribution.configureDefaultLabels({
    vat: (vatRate) => t('global:VAT', { vatRate }),
    vatWithReverseLiability: t('global:VATWithReversalOfLiability'),
    vatWithIntraCommunity: t('global:VATWithIntraCommunity'),
    globalVat: t('global:words.VAT'),
    customDiscountVat: (vatRate) => t('global:customDiscountVAT', { vatRate }),
  });

  Chapters.configureDefaultLabels({
    tableHeader: t('global:chapters.header'),
  });

  SavingSpinner.configureDefaultLabel({ saving: t('global:form.saving') });

  Modal.configureDefaultLabels({ back: t('global:words.c.back') });

  Filters.configureDefaultLabels({
    checkedValue: t('global:words.c.yes'),
    uncheckedValue: t('global:words.c.no'),
    minAmountPlaceholder: t('global:words.c.min'),
    maxAmountPlaceholder: t('global:words.c.max'),
    startDatePlaceholder: t('global:words.startDate'),
    endDatePlaceholder: t('global:words.endDate'),
  });

  AsyncTableData.configureDefaultLabels({
    error: t('global:errors.error'),
  });

  DroppableFileField.configureDefaultLabels({
    fileFieldDisabled: t('global:fileField.fileFieldDisabled'),
    primaryLabel: t('global:fileField.primaryLabel'),
    primaryLabelError: t('global:fileField.primaryLabelError'),
    secondaryLabel: t('global:fileField.secondaryLabel'),
    maxSizeLabel: t('global:fileField.maxSizeLabel'),
    fileTooLargeErrorMessage: t('global:fileField.fileTooLargeErrorMessage'),
    fileUnsupportedErrorMessage: t('global:fileField.fileUnsupportedErrorMessage'),
  });

  DifferenceSummary.configureDefaultLabels({
    vat: (vatRate) => t('global:VAT', { vatRate }),
    withReversalOfLiabilityOn: t('global:VATWithReversalOfLiability'),
    globalVat: t('global:words.VAT'),
    initialAmount: t('global:differenceSummary.initialAmount'),
    newAmount: t('global:differenceSummary.newAmount'),
    totalBeforeDiscount: t('global:differenceSummary.totalBeforeDiscount'),
    discount: t('global:differenceSummary.discount'),
    customDiscountVat: (vatRate) => t('global:customDiscountVAT', { vatRate }),
  });

  EmailAutocomplete.configureDefaultLabels({
    addOption: t('mailing:autocomplete.addOption'),
    noMoreSuggestions: t('mailing:autocomplete.noMoreSuggestions'),
    placeholder: t('mailing:autocomplete.placeholder'),
    missingEmail: t('mailing:autocomplete.missingEmail'),
  });

  StepperItem.configureDefaultLabels({
    date: (date) =>
      t('global:date', {
        date: date.format('LLL'),
      }),
  });

  RichTextInput.configureDefaultLabels({
    undo: t('global:richText.undo'),
    redo: t('global:richText.redo'),
    bold: t('global:richText.bold'),
    italic: t('global:richText.italic'),
    underline: t('global:richText.underline'),
    strikethrough: t('global:richText.strikethrough'),
    superscript: t('global:richText.superscript'),
    bulletList: t('global:richText.bulletList'),
    textColor: t('global:richText.textColor'),
    fillColor: t('global:richText.fillColor'),
    numberedList: t('global:richText.numberedList'),
    h1Title: t('global:richText.h1Title'),
    h2Title: t('global:richText.h2Title'),
    h3Title: t('global:richText.h3Title'),
    paragraph: t('global:richText.paragraph'),
    clearFormatting: t('global:richText.clearFormatting'),
    link: t('global:richText.link'),
    variableNotFound: t('global:richText.variableNotFound'),
  });

  MultipleFileUpload.configureDefaultLabels({
    attachment: t('mailing:attachment.attachment'),
    attachments: t('mailing:attachment.attachments'),
    drop: t('mailing:attachment.drop'),
    lang: i18n.language,
  });

  FileNameDetails.configureDefaultLabels({
    lang: i18n.language,
    fileTypes: {
      pdf: t('mailing:attachment.fileTypes.pdf'),
      spreadSheet: t('mailing:attachment.fileTypes.spreadSheet'),
      image: t('mailing:attachment.fileTypes.image'),
      other: t('mailing:attachment.fileTypes.other'),
    },
  });

  EmptyStateCard.configureDefaultLabels({
    quote: {
      subtitle: t('quote:emptyState.cards.quote.subtitle'),
      description: t('quote:emptyState.cards.quote.description'),
    },
    batiprix: {
      subtitle: t('quote:emptyState.cards.batiprix.subtitle'),
      description: t('quote:emptyState.cards.batiprix.description'),
    },
    library: {
      subtitle: t('quote:emptyState.cards.library.subtitle'),
      description: t('quote:emptyState.cards.library.description'),
    },
  });

  GenericCard.configureDefaultLabels({
    margin: {
      title: t('quote:drawer.sale.quoteSellSheetLotMarginCard.title'),
      overheadCosts: t('quote:drawer.sale.quoteSellSheetLotMarginCard.overheadCosts'),
      profit: t('quote:drawer.sale.quoteSellSheetLotMarginCard.profit'),
      total: t('quote:drawer.sale.quoteSellSheetLotMarginCard.total'),
    },
    amount: {
      title: t('quote:drawer.sale.quoteSellSheetLotAmountCard.title'),
      hiddenCost: t('quote:drawer.sale.quoteSellSheetLotAmountCard.hiddenCost'),
      disbursement: t('quote:drawer.sale.quoteSellSheetLotAmountCard.disbursement'),
      totalMargin: t('quote:drawer.sale.quoteSellSheetLotAmountCard.totalMargin'),
      marginBenefit: t('quote:drawer.sale.quoteSellSheetLotAmountCard.marginBenefit'),
      totalAmountExVAT: t('quote:drawer.sale.quoteSellSheetLotAmountCard.totalAmountExVAT'),
    },
    summary: {
      title: t('quote:drawer.sale.quoteSellSheetSummaryCard.title'),
      totalBeforeDiscounts: t('quote:drawer.sale.quoteSellSheetSummaryCard.totalBeforeDiscounts'),
      totalAfterSaleDiscount: t('quote:drawer.sale.quoteSellSheetSummaryCard.totalAfterSaleDiscount'),
      amountExVat: t('quote:drawer.sale.quoteSellSheetSummaryCard.amountExVat'),
      amountIncVat: t('quote:drawer.sale.quoteSellSheetSummaryCard.amountIncVat'),
      menuEdit: t('quote:drawer.sale.quoteSellSheetSummaryCard.menuEdit'),
      menuRemove: t('quote:drawer.sale.quoteSellSheetSummaryCard.menuRemove'),
      menuAddDiscount: t('quote:drawer.sale.quoteSellSheetSummaryCard.menuAddDiscount'),
      menuAddProrata: t('quote:drawer.sale.quoteSellSheetSummaryCard.menuAddProrata'),
      menuAddCustomDiscount: t('quote:drawer.sale.quoteSellSheetSummaryCard.menuAddCustomDiscount'),
      menuAddDiscountTooltip: t('quote:drawer.sale.quoteSellSheetSummaryCard.menuAddDiscountTooltip'),
      addDiscounts: t('quote:drawer.sale.quoteSellSheetSummaryCard.addDiscounts'),
      vat: t('quote:drawer.sale.quoteSellSheetSummaryCard.vat'),
      disableReversalOfLiability: t('quote:drawer.sale.quoteSellSheetSummaryCard.disableReversalOfLiability'),
      enableReversalOfLiability: t('quote:drawer.sale.quoteSellSheetSummaryCard.enableReversalOfLiability'),
      editGlobalVat: t('global:applyGlobalVAT'),
      reversalOfLiability: t('global:VATWithReversalOfLiability'),
      vatCustomDiscount: t('global:customDiscountVAT'),
    },
    information: {
      name: t('quote:drawer.details.informationCard.name'),
      refCode: t('quote:drawer.details.informationCard.refCode'),
      refCodeTooltip: t('quote:drawer.details.informationCard.refCodeTooltip'),
    },
    address: {
      address: t('global:address.label'),
      postalCode: t('global:address.postalCode'),
      city: t('global:address.city'),
      country: t('global:address.country'),
    },
    associateProjectModal: {
      code: t('quote:drawer.project.associateProjectModal.code'),
      name: t('quote:drawer.project.associateProjectModal.name'),
      client: t('quote:drawer.project.associateProjectModal.client'),
      amountExVAT: t('quote:drawer.project.associateProjectModal.amountExVAT'),
    },
    global: {
      days: t('global:words.day_plural'),
      noResults: t('global:palette.noResult'),
      loading: t('global:palette.loading'),
    },
  });

  UpsellCard.configureDefaultLabels({
    compareOffers: t('global:compareOffers'),
    compareOffersLink: t('global:compareOffersLink'),
    learnMore: t('global:learnMore'),
    learnMoreLink: `mailto:${SUPPORT_EMAIL}`,
  });

  FileList.configureDefaultLabels({
    selectFiles: t('global:fileList.selectFiles'),
    tooBig: t('global:fileList.tooBig'),
  });

  SingleSelect.configureDefaultLabels({
    noResult: t('global:singleSelect.noResult'),
    create: (value) => t('global:singleSelect.create', { value }),
    loading: t('global:singleSelect.loading'),
  });

  ListingLayout.configureDefaultLabels({
    filters: {
      cta: t('global:listingLayout.filters.cta'),
      removeAll: t('global:listingLayout.filters.removeAll'),
      modal: {
        title: t('global:listingLayout.filters.modal.title'),
        cta: t('global:listingLayout.filters.modal.cta'),
      },
    },
  });

  Nav.configureDefaultLabels({
    new: t('global:words.c.new'),
  });

  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />

      <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN}
        clientId={REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: REACT_APP_AUTH0_REDIRECT_URI,
          audience: REACT_APP_AUTH0_AUDIENCE,
        }}
      >
        <BrowserRouter>
          <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
            <NavbarProvider>
              <Switch>
                {/* This route isn't authenticated */}
                <Route path="/error" exact>
                  <ErrorScreen />
                </Route>

                {/* This route isn't authenticated */}
                <Route path="/user-invitations/:userInvitationId" exact>
                  <UserInvitationsScreen />
                </Route>

                {/* This route isn't authenticated but needs Auth0Provider to allow user to connect */}
                <Route path="/onboarding/:onboardingId" exact>
                  <StartOnboardingScreen />
                </Route>

                <Route path="/external/progress-statements/:externalId" exact>
                  <ProgressStatementExternalScreen />
                </Route>

                {/* Those routes aren't authenticated */}
                <Route path="*">
                  <AuthProvider>
                    <QueryWrapper>
                      <AppProvider>
                        <HeaderProvider>
                          <PrivateContent scrollRef={scrollRef} setScrollRef={setScrollRef} />
                        </HeaderProvider>
                      </AppProvider>
                    </QueryWrapper>
                  </AuthProvider>
                </Route>
              </Switch>
            </NavbarProvider>
          </Sentry.ErrorBoundary>
        </BrowserRouter>
      </Auth0Provider>
    </QueryClientProvider>
  );
};

export const App: FC = () => (
  <ChakraProvider theme={theme}>
    <Flex h="100vh" w="100vw" bg="backgroundLight">
      <Suspense fallback={<Loading />}>
        <Confetti />

        <Root />
      </Suspense>
    </Flex>
  </ChakraProvider>
);
