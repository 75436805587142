import { compact } from 'lodash-es';
import type { DocumentStatusCardAction } from '@graneet/lib-ui';

import type { InvoiceActionsParams } from './invoice.actions.type';
import {
  getCancelWithCreditAction,
  getCancelWithoutCreditAction,
  getDownloadPDFAction,
  getOpenMailingAction,
  getPreviewPDFAction,
} from './invoice.actions.util';

export function getInvoiceCompletedActions(params: InvoiceActionsParams): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];
  const previewPDFAction = getPreviewPDFAction(params);

  actions.push({ type: 'secondary', ...getCancelWithCreditAction(params) });

  if (previewPDFAction) {
    actions.push({ type: 'secondary', ...previewPDFAction });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([
      getCancelWithoutCreditAction(params),
      getOpenMailingAction(params),
      getDownloadPDFAction(params),
    ]),
  });

  return actions;
}
