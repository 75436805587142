import type { FC } from 'react';
import { useEffect, useCallback, useState } from 'react';
import { Modal, useCurrency } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import type { ILibraryComponentWithRelations } from '@graneet/business-logic';

import type { LibraryComponentFieldsForm } from '../LibraryComponentFields';
import { LibraryComponentFields } from '../LibraryComponentFields';
import { useLibraryComponentUpdate } from '../../services/library-component.api';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useLibraryJobs } from 'features/library-job/services/library-job.api';
import { LibraryJobImpactedLibraryComponentCallout } from 'features/library-job/components/LibraryJobImpactedLibraryComponentCallout';

const FORM_ID = 'library-component-edit';

interface LibraryComponentEditModalProps {
  libraryComponentToModify: ILibraryComponentWithRelations;

  modalControls: UseDisclosureReturn;

  onLibraryComponentUpdated: () => void;
}

export const LibraryComponentEditModal: FC<LibraryComponentEditModalProps> = ({
  libraryComponentToModify,
  modalControls,
  onLibraryComponentUpdated,
}) => {
  const { t } = useTranslation(['library', 'global']);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const form = useForm<LibraryComponentFieldsForm>({
    defaultValues: {
      ...libraryComponentToModify,
      refCode: libraryComponentToModify?.refCode,
      componentTypeId: libraryComponentToModify.componentType.id,
      unitDisbursementExVAT: mapNumberToAmount(libraryComponentToModify.unitDisbursementExVAT),
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const libraryJobs = useLibraryJobs({
    forcedFilters: {
      libraryComponentId: libraryComponentToModify.id,
    },
    storageStrategy: 'state',
  });

  const libraryComponentUpdateMutation = useLibraryComponentUpdate();

  const handleSubmit = useCallback(
    async (formValues: LibraryComponentFieldsForm) => {
      const { unitDisbursementExVAT, ...rest } = formValues;

      libraryComponentUpdateMutation.mutate(
        {
          id: libraryComponentToModify.id,
          dto: {
            ...rest,
            unitDisbursementExVAT: mapAmountToNumber(unitDisbursementExVAT!),
          },
        },
        {
          onSuccess: () => {
            modalControls.onClose();
            onLibraryComponentUpdated();
          },
        },
      );
    },
    [
      libraryComponentToModify.id,
      libraryComponentUpdateMutation,
      mapAmountToNumber,
      modalControls,
      onLibraryComponentUpdated,
    ],
  );

  const [isUsed, setIsUsed] = useState<null | boolean>(null);
  useEffect(() => {
    const getIsUsed = async () => {
      const response = await libraryJobs.fetchData({ page: 0, sort: {} });
      setIsUsed(response.data.length > 0);
    };

    getIsUsed();
  }, [libraryJobs]);
  const isIsUsedLoading = isUsed === null;

  return (
    <Modal {...modalControls} title={t('library:modifyLibraryComponentModal.title')} size="5xl" autoFocus={false}>
      <QueryWrapper>
        {!isIsUsedLoading && (
          <Form form={form} id={FORM_ID} onSubmit={form.handleSubmit(handleSubmit)}>
            <Text mb={4}>{t('library:modifyLibraryComponentModal.description')}</Text>
            <LibraryComponentFields />
          </Form>
        )}

        {isUsed && (
          <Box mt={5}>
            <LibraryJobImpactedLibraryComponentCallout colorScheme="yellow" libraryJobs={libraryJobs}>
              <Box>
                <Trans t={t} i18nKey="library:modifyLibraryComponentModal.callout">
                  <Text as="span" fontWeight={600}>
                    displayed_data
                  </Text>
                  description_end
                </Trans>
              </Box>
            </LibraryJobImpactedLibraryComponentCallout>
          </Box>
        )}

        <Modal.Close isDisabled={libraryComponentUpdateMutation.isPending} />

        <Modal.PrimaryButton
          isLoading={libraryComponentUpdateMutation.isPending || isIsUsedLoading}
          isDisabled={!isFormValid}
          form={FORM_ID}
          type="submit"
        >
          {t('library:modifyLibraryComponentModal.cta')}
        </Modal.PrimaryButton>
      </QueryWrapper>
    </Modal>
  );
};
