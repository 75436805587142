import { QUOTE_STATUS } from '@graneet/business-logic';

export const QUOTE_FIELDS = {
  REF_CODE: 'refCode',
  NAME: 'name',
  DATE: 'date',
  SETTLED_AT: 'settledAt',
  CLIENT: 'client',
  AMOUNT_EX_VAT: 'amountExVAT',
  STATUS: 'status',
  PROJECT_ADDRESS: 'projectAddress',
  ACCOUNT_MANAGERS: 'quoteAccountManagers',
  QUOTE_VALIDITY_DURATION: 'quoteValidityDuration',
  DISPLAY_OPTIONAL_LINES_PDF: 'displayOptionalLinesPDF',
  DISPLAY_OPTIONAL_ANNEX_PDF: 'displayOptionalAnnexPDF',
  TAGS: 'tags',
};

export const QUOTE_STATUS_COLOR = {
  [QUOTE_STATUS.IMPORTING]: 'purple',
  [QUOTE_STATUS.DRAFT]: 'gray',
  [QUOTE_STATUS.COMPLETED]: 'blue',
  [QUOTE_STATUS.ACCEPTED]: 'green',
  [QUOTE_STATUS.LOST]: 'red',
} as const;

export const QUOTE_TOTAL_FIELDS = {
  DISBURSEMENT_EX_VAT: 'disbursementExVAT',
  TOTAL_MARGIN: 'totalMargin',
  AMOUNT_EX_VAT: 'amountExVAT',
};

export const QUOTE_ITEM_TYPE = {
  QUOTE: 'quote',
  LOT: 'lot',
  JOB: 'job',
  COMPONENT: 'component',
};

export const MARGIN_ENDPOINT_TYPE = {
  MARGIN: 'MARGIN',
  MARGIN_COMPONENT_TYPE: 'MARGIN_COMPONENT_TYPE',
  AMOUNT_EX_VAT: 'AMOUNT_EX_VAT',
};

export const AMOUNT_ENDPOINT_TYPE = {
  TOTAL_EX_VAT: 'TOTAL_EX_VAT',
};

export const QUOTE_TOTAL_MARGIN_FIELD = `QUOTE_${QUOTE_TOTAL_FIELDS.TOTAL_MARGIN}`;

export const INCLUDE_COMPONENT = 'component-';

export const QUOTE_STEPS = {
  LOTS: 'lots',
  CLIENT: 'client',
  INFORMATION: 'information',
};
