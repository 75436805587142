import { useTranslation } from 'react-i18next';
import { Box, Center, HStack } from '@chakra-ui/react';
import { Button, Onboarding, SimpleArrowForwardIcon, SimpleDashboardIcon } from '@graneet/lib-ui';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import type { IProject } from '@graneet/business-logic';

interface ProjectStatsEmptyStateProps {
  project: IProject;
}

export const ProjectStatsEmptyState = ({ project }: ProjectStatsEmptyStateProps) => {
  const { t } = useTranslation(['project']);

  const history = useHistory();

  const goToBudgets = useCallback(() => {
    history.push(`/projects/${project.id}/information/budgets`, { forceCreate: true });
  }, [history, project]);

  const goToOrders = useCallback(() => {
    history.push('/purchases/orders/create', { projectId: project.id });
  }, [history, project]);

  return (
    <Onboarding
      icon={
        <Box
          p={2}
          boxSize={36}
          borderRadius="50%"
          bg="gray.100"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <SimpleDashboardIcon boxSize={45} />
        </Box>
      }
      action={
        <HStack gap={6}>
          <Button variant="ghost" colorScheme="gray" leftIcon={<SimpleArrowForwardIcon />} onClick={goToBudgets}>
            {t('project:actions.addBudget')}
          </Button>
          <Button variant="ghost" colorScheme="gray" leftIcon={<SimpleArrowForwardIcon />} onClick={goToOrders}>
            {t('project:actions.addOrder')}
          </Button>
        </HStack>
      }
    >
      <Center fontSize="sm" mb={6}>
        {t('project:emptyState.noDataAvailable')}
      </Center>
    </Onboarding>
  );
};
