import type { FC } from 'react';
import type { StackProps } from '@chakra-ui/react';
import { Stack, Box } from '@chakra-ui/react';

import './Spinner.css';

export type SpinnerProps = StackProps;

export const Spinner: FC<SpinnerProps> = ({ children, ...props }) => (
  <Stack
    align="center"
    spacing={4}
    color="primary"
    fontWeight="bold"
    flexGrow={1}
    justify="center"
    direction="column"
    justifySelf="center"
    alignSelf="center"
    className="animatedLogo"
    {...props}
  >
    <svg
      width="80"
      height="84"
      viewBox="0 0 80 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#122428"
      strokeWidth="2"
      id="animatedLogo"
    >
      <path
        id="Vector"
        d="M26.1308 60.4964H0.5V48.165H23.4167C24.3157 48.165 25.1858 47.8132 25.8196 47.1833L47.1171 26.2091C48.8747 24.4783 51.2603 23.5079 53.7438 23.5079H79.3688V35.8393H56.4579C55.5589 35.8393 54.6888 36.1912 54.055 36.8211L32.7575 57.7952C30.9999 59.526 28.6143 60.4964 26.1308 60.4964Z"
        fill="#122428"
        className="svg-elem-1"
      />
      <path
        id="Vector_2"
        d="M13.6093 35.838H0.5V23.5123H10.8952C11.7941 23.5123 12.6642 23.1605 13.2981 22.5306L35.5061 0.65979L44.357 9.37632L20.2302 33.1368C18.4784 34.8676 16.0928 35.838 13.6093 35.838Z"
        fill="#122428"
        className="svg-elem-2"
      />
      <path
        id="Vector_3"
        d="M44.3631 83.3402L35.5122 74.6237L59.639 50.8632C61.3965 49.1324 63.7821 48.162 66.2657 48.162H79.375V60.4877H68.9797C68.0808 60.4877 67.2107 60.8395 66.5769 61.4694L44.3631 83.3402Z"
        fill="#122428"
        className="svg-elem-3"
      />
    </svg>
    {!!children && <Box>{children}</Box>}
  </Stack>
);
