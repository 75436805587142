import type { FC } from 'react';
import { Fragment, useEffect, useMemo } from 'react';
import {
  Card,
  TwoColumns,
  TextField,
  DateField,
  formatDateToString,
  getCurrentDateAsString,
  Section,
  useCurrency,
  IconAdvanced,
  AmountSummary,
  formatPercentage,
  SimpleHelpIcon,
} from '@graneet/lib-ui';
import { Box, Flex, VStack } from '@chakra-ui/react';
import { useStepForm, Form, useWizardContext } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import {
  type IDeductionAggregatedRaw,
  type IFinalStatement,
  type IHoldback,
  type IOrder,
  type IProject,
  type ISubProject,
  type IPenaltyFindByProjectIdOutputDTO,
  PRICE_REVISION_METHOD,
  VAT_TYPE,
  FEATURE_FLAGS,
} from '@graneet/business-logic';

import { Rule } from 'features/form/rules/Rule';
import { useFinalStatementSummary } from 'features/final-statement/services/final-statement.api';
import type {
  FinalStatementEditionWizard,
  FinalStatementSummaryForm,
} from 'features/final-statement/forms/final-statement-edition.wizard';
import { NoteFieldSection } from 'features/common/components/fields/NoteFieldSection';
import { formatPriceRevisionDTO } from 'features/final-statement/services/final-statement.util';
import { FeatureFlag } from 'features/feature-flag/components/FeatureFlag';

interface ViewSummaryStepProps {
  project: IProject;

  subProjects: ISubProject[];

  lastFinalStatement: IFinalStatement | null | undefined;

  directPaymentOrders: IOrder[];

  penalties: IPenaltyFindByProjectIdOutputDTO;
}

export const ViewSummaryStep: FC<ViewSummaryStepProps> = ({
  project,
  subProjects,
  lastFinalStatement,
  directPaymentOrders,
  penalties,
}) => {
  const { t } = useTranslation(['project']);
  const { form, initFormValues } = useStepForm<FinalStatementEditionWizard, 'summary'>();

  const { mapAmountToNumber } = useCurrency();
  const { getValuesOfStep } = useWizardContext<FinalStatementEditionWizard>();

  const dto = useMemo(() => {
    const priceRevisions = getValuesOfStep('priceRevision')!;
    const finalStatementPenalties = getValuesOfStep('penalties')!;

    return {
      projectId: project.id,
      finalPriceRevisions: formatPriceRevisionDTO(priceRevisions, mapAmountToNumber),
      penalties: finalStatementPenalties?.penalties,
    };
  }, [getValuesOfStep, mapAmountToNumber, project.id]);
  const finalStatementSummary = useFinalStatementSummary(dto);

  useEffect(() => {
    if (lastFinalStatement) {
      initFormValues({
        name: lastFinalStatement.name,
        date: formatDateToString(lastFinalStatement.date),
        note: lastFinalStatement.note,
      });
    } else {
      initFormValues({
        date: getCurrentDateAsString(),
      });
    }
  }, [lastFinalStatement, initFormValues]);

  const hasAllSubProjectReversalOfLiability = subProjects.every((subProject) => subProject.hasReversalOfLiability);
  const hasAtLeastOneSubProjectWithPriceRevision = subProjects.some((subProject) => subProject.hasPriceRevision);

  const deductionAndHoldbackSubProjects = useMemo<
    Record<ISubProject['name'], [IHoldback | null, IDeductionAggregatedRaw[]]>
  >(
    () =>
      subProjects.reduce((acc, subProject) => {
        const deductions = finalStatementSummary.data.deductions.filter((d) => d.subProjectId === subProject.id);
        return { ...acc, [subProject.id]: [subProject.holdback || null, deductions || []] };
      }, {}),
    [subProjects, finalStatementSummary.data.deductions],
  );

  const displaySubProjectName = subProjects.length > 1;

  const hasDirectPayment = directPaymentOrders.length > 0;

  const hasPenalties = useMemo(() => {
    const progressStatementPenalties = Object.values(penalties.progressStatement.penaltiesGroupBySubProjectId || {});
    const finalStatementPenalties = getValuesOfStep('penalties')!;
    return progressStatementPenalties.length || !!finalStatementPenalties?.penalties?.length;
  }, [getValuesOfStep, penalties]);

  const vatDistributionWithPriceRevision =
    project.priceRevisionMethod === PRICE_REVISION_METHOD.PRORATA_VAT
      ? (finalStatementSummary.data.vatDistribution || []).map((vatObject) => ({
          vatRate: vatObject.vatRate,
          amount: vatObject.amount + vatObject.amountPriceRevision!,
        }))
      : finalStatementSummary.data.vatDistribution || [];

  return (
    <Form form={form}>
      <Section
        title={t('project:finalStatement.validation.title')}
        description={t('project:finalStatement.validation.description')}
      >
        <TwoColumns>
          <Box>
            <Card title={t('project:finalStatement.validation.information')}>
              <VStack spacing={6}>
                <TextField<FinalStatementSummaryForm>
                  name="name"
                  label={t('project:finalStatement.fields.name')}
                  isRequired
                >
                  <Rule.IsRequired />
                </TextField>
                <Flex w="100%">
                  <DateField<FinalStatementSummaryForm>
                    name="date"
                    label={t('project:finalStatement.fields.date')}
                    isRequired
                    rightIcon={
                      <IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('project:finalStatement.tooltips.date')} />
                    }
                  >
                    <Rule.IsRequired />
                  </DateField>
                </Flex>
              </VStack>
            </Card>
          </Box>

          <Card title={t('project:finalStatement.validation.summary')} pb={8}>
            <AmountSummary>
              {hasAtLeastOneSubProjectWithPriceRevision &&
                project.priceRevisionMethod === PRICE_REVISION_METHOD.PRORATA_VAT && (
                  <>
                    <AmountSummary.Highlight
                      left
                      label={t('project:finalStatement.validation.projectAmountExVAT')}
                      amount={
                        finalStatementSummary.data.amountExVAT - finalStatementSummary.data.finalTotalPriceRevisionExVAT
                      }
                    />
                    <AmountSummary.Item
                      left
                      light
                      sign
                      label={t('project:finalStatement.validation.finalTotalPriceRevisionExVAT')}
                      amount={finalStatementSummary.data.finalTotalPriceRevisionExVAT}
                    />
                  </>
                )}
              <AmountSummary.Highlight
                left
                label={t('project:finalStatement.validation.amountExVAT')}
                amount={finalStatementSummary.data.amountExVAT}
              />
              <AmountSummary.VATDistribution
                left
                vatType={hasAllSubProjectReversalOfLiability ? VAT_TYPE.REVERSE_CHARGE : VAT_TYPE.NORMAL}
                vatDistribution={vatDistributionWithPriceRevision}
                withCustomDiscountsLabel={
                  hasAllSubProjectReversalOfLiability && !!vatDistributionWithPriceRevision.length
                }
              />
              <AmountSummary.Highlight
                left
                label={t('project:finalStatement.validation.amountIncVAT')}
                amount={finalStatementSummary.data.amountIncVAT}
              />

              {hasPenalties ? (
                <AmountSummary.Item
                  left
                  light
                  sign
                  label={t('project:finalStatement.validation.amountPenaltyIncVAT')}
                  amount={finalStatementSummary.data.amountPenaltyIncVAT}
                />
              ) : null}

              {hasAtLeastOneSubProjectWithPriceRevision &&
                project.priceRevisionMethod === PRICE_REVISION_METHOD.WEIGHTED_VAT && (
                  <AmountSummary.Item
                    left
                    light
                    sign
                    label={t('project:finalStatement.validation.finalTotalPriceRevisionIncVAT')}
                    amount={finalStatementSummary.data.finalTotalPriceRevisionIncVAT}
                  />
                )}

              {Object.entries(deductionAndHoldbackSubProjects).map(([subProjectId, [holdback, deductions]]) => (
                <Fragment key={subProjectId}>
                  {displaySubProjectName && (
                    <AmountSummary.Item
                      mt={2}
                      important
                      left
                      light
                      label={subProjects.find((sp) => sp.id === subProjectId)!.name}
                    />
                  )}

                  <FeatureFlag feature={FEATURE_FLAGS.PROJECT_GLOBAL_PARAMETERS}>
                    <FeatureFlag.OnActive>
                      {holdback && (
                        <AmountSummary.Item
                          key={holdback.id}
                          left
                          light
                          sign
                          negative
                          label={t(
                            holdback.hasPaymentGuarantee
                              ? 'project:finalStatement.validation.holdbackWithPaymentGuarantee'
                              : 'project:finalStatement.validation.amountHoldbackIncVAT',
                          )}
                          amount={holdback.holdbackAmountIncVAT - (holdback.paymentGuaranteeAmount ?? 0)}
                        />
                      )}
                    </FeatureFlag.OnActive>
                    <FeatureFlag.OnFallback>
                      {holdback && (
                        <AmountSummary.Item
                          key={holdback.id}
                          left
                          light
                          sign
                          negative
                          label={t(
                            holdback.hasPaymentGuarantee
                              ? 'project:finalStatement.validation.holdbackWithPaymentGuarantee'
                              : 'project:finalStatement.validation.amountHoldbackIncVAT',
                          )}
                          amount={holdback.holdbackAmountIncVAT}
                        />
                      )}
                    </FeatureFlag.OnFallback>
                  </FeatureFlag>

                  {deductions.map((deduction) => (
                    <AmountSummary.Item
                      key={deduction.name}
                      left
                      light
                      sign
                      negative
                      label={t('project:finalStatement.validation.deductionLabel', {
                        deductionName: deduction.name,
                        percentage: formatPercentage(deduction.percentage),
                      })}
                      amount={deduction.totalAmountIncVAT}
                    />
                  ))}
                </Fragment>
              ))}

              {hasDirectPayment && (
                <AmountSummary.Item
                  left
                  light
                  sign
                  negative
                  label={t('project:finalStatement.validation.amountDirectPaymentIncVAT')}
                  amount={finalStatementSummary.data.amountDirectPaymentIncVAT}
                />
              )}

              <AmountSummary.Item
                left
                light
                sign
                negative
                label={t('project:finalStatement.validation.amountReceivedIncVAT')}
                amount={finalStatementSummary.data.amountReceivedIncVAT}
              />

              <AmountSummary.Highlight
                left
                label={t('project:finalStatement.validation.amountToBePaidIncVAT')}
                amount={finalStatementSummary.data.amountToBePaidIncVAT}
              />
            </AmountSummary>
          </Card>
        </TwoColumns>
      </Section>

      <NoteFieldSection<FinalStatementSummaryForm> name="note" />
    </Form>
  );
};
