import type { Raw } from '../common/entity.type';
import { divideFloating, getAmountPercentage, multiplyFloating, roundFloating } from '../common/math.util';
import { isNumberFinite } from '../common/number.util';
import { splitBiggestObjectAndOthers } from '../common/object.util';
import type { IQuoteCustomDiscount } from '../custom-discount/custom-discount.type';
import { getSumCustomDiscountsAmountExVAT } from '../custom-discount/custom-discount.util';
import type { IMargin } from '../margin/margin.type';

import type { IDiscount } from './discount.type';
import { DISCOUNT_TYPES } from './discount.type';

export const getDiscountAmountExVAT = (
  discount: Raw<IDiscount> | null | undefined,
  totalAmountExVAT: number,
): number => {
  if (!discount) {
    return 0;
  }
  return discount.type === DISCOUNT_TYPES.AMOUNT
    ? discount.amountExVAT || 0
    : getAmountPercentage(totalAmountExVAT, discount.percentage);
};

export const getAmountWithoutDiscountFromAmountExVAT = (
  discount: IDiscount | undefined | null,
  amountExVAT: number,
): number => {
  if (!discount) {
    return amountExVAT;
  }

  // Percentage discount
  if (discount.type === DISCOUNT_TYPES.PERCENTAGE) {
    if (discount.percentage === 100) {
      throw new Error('Cannot compute amountWithoutDiscountExVAT when the discount is 100%');
    }

    if (!isNumberFinite(discount.percentage)) {
      throw Error('Discount type. is `PERCENTAGE` but percentage is not defined');
    }
    return roundFloating(divideFloating(amountExVAT, 1 - divideFloating(discount.percentage, 100)));
  }

  // Fixed amount discount
  const discountAmountExVAT = getDiscountAmountExVAT(discount, amountExVAT);

  return amountExVAT + discountAmountExVAT;
};

export const getMarginWithoutDiscount = (
  amountWithoutDiscountExVAT: number,
  margin: Raw<IMargin>,
  disbursementExVAT: number,
  discount: IDiscount | undefined | null,
  customDiscounts: IQuoteCustomDiscount[],
): Raw<IMargin> => {
  if (!discount && (!customDiscounts || customDiscounts.length === 0)) {
    return margin;
  }

  const discountAmountExVAT = getDiscountAmountExVAT(discount, amountWithoutDiscountExVAT);

  const amountWithDiscountExVAT = amountWithoutDiscountExVAT - discountAmountExVAT;

  const customDiscountsAmountExVAT = getSumCustomDiscountsAmountExVAT(customDiscounts || [], amountWithDiscountExVAT);

  // When quote is fully discounted, we cannot compute the newMargin so we return the current margin
  if (amountWithoutDiscountExVAT - discountAmountExVAT + customDiscountsAmountExVAT === 0) {
    return margin;
  }

  const totalMargin =
    margin.totalMargin +
    (disbursementExVAT !== 0 ? divideFloating(discountAmountExVAT - customDiscountsAmountExVAT, disbursementExVAT) : 0);

  return {
    ...margin,
    profitMargin: divideFloating(totalMargin, margin.overheadCosts),
    totalMargin,
  };
};

/**
 * Calculate new amount when discount has to be distributed
 */
export const computeDiscountedAmount = (amount: number, discountAmount: number, amountTotal: number): number => {
  /*                              discountAmount * amount
   * discountedAmount = amount -  ———————————————————————
   *                                    amountTotal
   */
  if (amountTotal !== 0) {
    return amount - divideFloating(multiplyFloating(discountAmount, amount), amountTotal);
  }

  return amount;
};

/**
 * Distribute discount all over items (eg: vat distribution)
 */
export const dispatchDiscountProrata = <K extends PropertyKey, T extends Record<K, number>>(
  items: T[],
  discountAmount: number,
  column: K,
): [item: T, discounted: number][] => {
  // Easiest method when there is only 1 item (vat rate)
  // The new amount is the original minus discount amount
  if (items.length === 1) {
    return [[items[0], items[0][column] - discountAmount]];
  }

  // Otherwise discount amount has to be distributed
  const sum = items.reduce((acc, item) => acc + item[column], 0);
  return items.map((item) => [item, computeDiscountedAmount(item[column], discountAmount, sum)]);
};

/**
 * Distribute discount all over items (eg: vat distribution)
 * This method rounds new amounts
 */
export const dispatchDiscountProrataRounded = <K extends PropertyKey, T extends Record<K, number>>(
  items: T[],
  discountAmount: number,
  column: K,
): [item: T, discounted: number][] => {
  // Extract biggest item
  const { biggest, others } = splitBiggestObjectAndOthers(items, column);

  if (biggest === null) {
    return [];
  }

  // Discount amount has to be distributed and rounded on items (except biggest one)
  const sum = items.reduce((acc, item) => acc + item[column], 0);
  const otherItemsMap: [item: T, discounted: number][] = others.map((item) => [
    item,
    roundFloating(computeDiscountedAmount(item[column], discountAmount, sum)),
  ]);

  // Calculate rest
  const sumOtherItems = otherItemsMap.reduce(
    (acc, [item, amountDiscounted]) => acc + item[column] - amountDiscounted,
    0,
  );
  const restDiscountAmount = discountAmount - sumOtherItems;

  // Return all items with discounted and rounded amounts
  const t: [item: T, discounted: number] = [biggest, biggest[column] - restDiscountAmount];
  return [...otherItemsMap, t];
};
