import type { FC } from 'react';
import { DeepTable, Price, useCurrency, formatPercentage, Progress } from '@graneet/lib-ui';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnBlurValues } from 'graneet-form';
import type { IProgressStatement, ISubProject } from '@graneet/business-logic';
import { getPercentage } from '@graneet/business-logic';

import { useCumulativeProgressExVAT } from '../../hooks/useCumulativeProgressExVAT';

import type { ProgressStatementEditItemForm } from 'features/progress-statement/forms/progress-statement-edit-item-form';

const CELL_STYLES = {
  fontSize: 'md',
  py: 2,
};

interface ProgressStatementFooterProps {
  subProject: ISubProject;

  previousProgressStatement?: IProgressStatement;
}

export const ProgressStatementFooter: FC<ProgressStatementFooterProps> = ({
  subProject,
  previousProgressStatement,
}) => {
  const { t } = useTranslation(['progressStatement']);
  const { formatAsAmount, mapAmountToNumber } = useCurrency();

  const form = useFormContext<ProgressStatementEditItemForm>();
  const cumulativeValues = useOnBlurValues(form, undefined);
  const cumulativeAmountExVAT = useCumulativeProgressExVAT(cumulativeValues);

  return (
    <Box background="white" py={2}>
      <DeepTable.Row fontWeight={600} noBorder>
        <DeepTable.Cell gridColumn="span 5" {...CELL_STYLES}>
          <Text ml={50}>{t('progressStatement:isCreating.totalProject')}</Text>
        </DeepTable.Cell>

        <DeepTable.Cell whiteSpace="nowrap" right {...CELL_STYLES}>
          <Price amount={subProject?.amountExVAT} />
        </DeepTable.Cell>

        <DeepTable.Cell center {...CELL_STYLES}>
          {formatPercentage(previousProgressStatement?.cumulativeProgressPercentage || 0)}
        </DeepTable.Cell>

        <DeepTable.Cell {...CELL_STYLES}>
          <Progress
            mx={4}
            w="100%"
            value={getPercentage(mapAmountToNumber(cumulativeAmountExVAT), subProject?.amountExVAT)}
          />
        </DeepTable.Cell>

        <DeepTable.Cell right {...CELL_STYLES} data-testid="cell:progressStatement.totals.cumulated">
          {formatAsAmount(mapAmountToNumber(cumulativeAmountExVAT))}
        </DeepTable.Cell>
      </DeepTable.Row>
    </Box>
  );
};
