import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { capitalizeString, CheckboxField, SingleSelectField, Table } from '@graneet/lib-ui';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import type { IUserPaginated, IUser } from '@graneet/business-logic';

import { UserRoleBadge } from 'features/user/components/UserRoleBadge';

export type UserFieldName = `${number}-userId`;
export const buildUserFieldName = (user: IUser): UserFieldName => `${user.id}-userId`;
export const isUserFieldName = (fieldName: string): fieldName is UserFieldName => fieldName.split('-')[1] === 'userId';
export const getUserIdFromFieldName = (fieldName: UserFieldName): number => parseInt(fieldName.split('-')[0], 10);

export interface FormValuesUserToRemind {
  frequencyWeekday: string;
  [userId: UserFieldName]: boolean;
}

interface UserToRemindTableProps {
  users: IUserPaginated[];
}

export const UserToRemindTable = ({ users }: UserToRemindTableProps) => {
  const { t } = useTranslation(['mailing', 'user']);

  const options = useMemo(
    () =>
      dayjs.weekdays().map((day, index) => ({
        label: capitalizeString(day),
        value: index.toString(),
      })),
    [],
  );

  return (
    <>
      <Divider my={4} />

      <Flex mt={4} w="100%" justifyContent="space-between">
        <Box>
          <Text textColor="gray.800">{t('mailing:remindersCard.frequency')}</Text>
          <Text fontSize="sm">{t('mailing:remindersCard.frequencyDescription')}</Text>
        </Box>
        <SingleSelectField<FormValuesUserToRemind>
          name="frequencyWeekday"
          options={options}
          isSearchable={false}
          isClearable={false}
        />
      </Flex>

      <Divider my={4} />

      <Text fontSize="sm" color="gray.800" fontWeight={600}>
        {t('mailing:remindersCard.sentTo')}
      </Text>

      <Box mt={2} borderRadius="6px" boxShadow="subtle" overflow="hidden">
        <Table templateColumns={['4rem', '1fr', '1fr', '1fr', '12rem']} noCard>
          <Table.Header>
            <Table.Cell />
            <Table.Cell>{t('user:fields.lastName')}</Table.Cell>
            <Table.Cell>{t('user:fields.firstName')}</Table.Cell>
            <Table.Cell>{t('user:fields.email')}</Table.Cell>
            <Table.Cell>{t('user:fields.role')}</Table.Cell>
          </Table.Header>

          {users?.map((user) => (
            <Table.Row key={user.id}>
              <Table.Cell>
                <CheckboxField<FormValuesUserToRemind> name={buildUserFieldName(user)} />
              </Table.Cell>
              <Table.Cell>{user.lastName}</Table.Cell>
              <Table.Cell>{user.firstName}</Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>
                <UserRoleBadge role={user.role} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
      </Box>
    </>
  );
};
