import type { QuoteBasicItemUpsertDTOQuantityFormula } from '@org/graneet-bff-client';
import type { QuoteQuantityFormulaObject } from '@org/quotation-lib';

export const mapQuantityFormulaObjectToQuantityFormulaDTO = (
  quoteQuantityFormulaObject: QuoteQuantityFormulaObject | null,
): QuoteBasicItemUpsertDTOQuantityFormula | null => {
  if (quoteQuantityFormulaObject && quoteQuantityFormulaObject.quantity) {
    return {
      quantity: quoteQuantityFormulaObject.quantity,
      formula: quoteQuantityFormulaObject.formula,
      roundFactor: quoteQuantityFormulaObject.roundFactor,
      comment: quoteQuantityFormulaObject.comment,
    };
  }
  return null;
};
