import { QuoteNodeAppendChildUseCase } from '@org/quotation-lib';

import type { QuoteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';

export const createQuoteComponentAddedCallback = (message: QuoteWsMessage) => {
  const quote = quoteGetFromStore();
  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();

  if (quote && message.data.parentId && message.data.content.type === 'QuoteComponent') {
    const hasClientRequest = quoteClientRequestsFromStore.find(
      (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
    );
    if (hasClientRequest === undefined) {
      const parentNode = quote.getTree().getNodeOrThrow(message.data.parentId);
      QuoteNodeAppendChildUseCase.appendQuoteComponent(
        message.data.id,
        parentNode,
        message.data.prevSiblingId,
        message.data.nextSiblingId,
        message.data.content,
      );
      quoteSetToStore(quote);
    } else {
      quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
    }
  }
};
