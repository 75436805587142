import type { QuoteOptionalItemUpsertDTO } from '@org/graneet-bff-client';
import type { QuoteNode } from '@org/quotation-lib';

import { mapQuantityFormulaObjectToQuantityFormulaDTO } from './quoteQuantityFormulaObjectToQuantityFormulaDTO';
import { mapMarginObjectToMarginDTO } from './quoteMarginObjectToMarginDTO';
import { genericUpdateItemMapper } from './quoteItemsGenericMapper';
import { mapQuoteFileObjectToFileDTO } from './quoteFileObjectToFileDTO';

export const mapQuoteOptionalItemToUpdateQuantityFormula = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteOptionalItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteOptionalItem' && quoteNodeObject.parentId) {
    return {
      quantityFormula: mapQuantityFormulaObjectToQuantityFormulaDTO(content.quantityFormula),
      quoteOptionalItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content is not QuoteOptionalItem');
};

export const mapQuoteOptionalItemToUpdateTotalMargin = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteOptionalItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteOptionalItem' && quoteNodeObject.parentId) {
    return {
      margin: mapMarginObjectToMarginDTO(content.margin),
      quoteOptionalItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteOptionalItem');
};

export const mapQuoteOptionalItemToUpdateUnitAmount = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteOptionalItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteOptionalItem' && quoteNodeObject.parentId) {
    return {
      unitAmount: content.unitAmount,
      quoteOptionalItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteOptionalItem');
};

export const mapQuoteOptionalItemToUpdateUnitFlastCostAmount = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteOptionalItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteOptionalItem' && quoteNodeObject.parentId) {
    return {
      unitFlatCostAmount: content.unitFlatCostAmount,
      quoteOptionalItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteOptionalItem');
};

export const mapQuoteOptionalItemToUpdateVatRate = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteOptionalItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteOptionalItem' && quoteNodeObject.parentId) {
    return {
      vatRate: content.vatRate,
      quoteOptionalItemId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteOptionalItem');
};

export const mapQuoteNodeToUpsertOptionalItemDTO = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteOptionalItemUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteOptionalItem' && quoteNodeObject.parentId) {
    return {
      denomination: content.denomination,
      manualIndex: content.manualIndex,
      refCode: content.refCode,
      note: content.note,
      unit: content.unit,
      vatRate: content.vatRate,
      amount: content.amount,
      unitFlatCostAmount: content.unitFlatCostAmount,
      unitAmount: content.unitAmount,
      flatCostAmount: content.flatCostAmount,
      quantityFormula: mapQuantityFormulaObjectToQuantityFormulaDTO(content.quantityFormula),
      margin: mapMarginObjectToMarginDTO(content.margin),
      quoteOptionalItemId: content.id,
      files: content.files.map((file) => mapQuoteFileObjectToFileDTO(file)),
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content is not QuoteOptionalItem');
};
