import { useMemo } from 'react';

import { DragDirection } from '../on-row-drag/onRowDragConstant';
import { potentialTarget, potentialZone } from '../on-row-drag/onRowDragRefreshCell';

export const useDefaultColDef = () =>
  useMemo(
    () => ({
      resizable: true,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      cellClassRules: {
        'drag-inside': (params: any) => params.node === potentialTarget && potentialZone === DragDirection.INSIDE,
        'drag-lower': (params: any) => params.node === potentialTarget && potentialZone === DragDirection.LOWER,
        'drag-upper': (params: any) => params.node === potentialTarget && potentialZone === DragDirection.UPPER,
      },
    }),
    [],
  );
