import type { FC, MouseEventHandler } from 'react';
import { useCallback } from 'react';
import {
  DeepTable,
  formatNumber,
  formatPercentage,
  PencilIcon,
  PriceAdvanced,
  RichText,
  Tooltip,
} from '@graneet/lib-ui';
import { Box, IconButton } from '@chakra-ui/react';
import { HiddenField, useFormContext, useOnBlurValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';

import { useProgressStatementContext } from '../../contexts/ProgressStatementContext';
import { useProgressStatementItem } from '../../hooks/useProgressStatementTree';
import { getProgressStatementDeepTableOffset } from '../../services/progress-statement-tree.util';
import type { ProgressStatementEditItemForm } from '../../forms/progress-statement-edit-item-form';
import { getItemCommentFieldName } from '../../forms/progress-statement-edit-item-form';

import { ProgressStatementCommentItemRow } from './ProgressStatementCommentItemRow';
import { ProgressStatementItemTotalLabel } from './ProgressStatementItemTotalLabel';
import { ProgressStatementItemPreviousCumulative } from './ProgressStatementItemPreviousCumulative';
import { ProgressStatementItemCumulativeField } from './ProgressStatementItemCumulativeField';

interface ProgressStatementDeepTableItemLineProps {
  id: number;

  depth: number;

  onComment?: (itemId: number) => void;

  isUpdatable?: boolean;
}

export const ProgressStatementDeepTableItemLine: FC<ProgressStatementDeepTableItemLineProps> = ({
  id,
  depth,
  onComment,
  isUpdatable = true,
}) => {
  const { t } = useTranslation(['progressStatement']);

  const { leaf: item } = useProgressStatementItem(id);

  const itemCommentFieldName = getItemCommentFieldName(id);
  const form = useFormContext<ProgressStatementEditItemForm>();
  const values = useOnBlurValues(form, [itemCommentFieldName]);

  const { previousItemsCumulativeValues } = useProgressStatementContext();
  const previousItemValues = previousItemsCumulativeValues[item.id];

  const handleComment = useCallback(
    (lineId: number) => () => {
      onComment?.(lineId);
    },
    [onComment],
  );

  const handleFieldFocus = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    if ('select' in e.target && typeof e.target.select === 'function') {
      e.target.select();
    }
  }, []);

  if (item.isOptional) {
    return null;
  }

  return (
    <Box ml={2}>
      <DeepTable.Row
        data-testid="row:progressStatement.item"
        color="gray.600"
        background="white"
        offset={getProgressStatementDeepTableOffset(depth)}
        borderColor={item.note ? 'gray.100' : 'gray.200'}
      >
        <DeepTable.Cell>{item.code}</DeepTable.Cell>

        <DeepTable.Cell whiteSpace="pre-line" wordBreak="break-word">
          <RichText value={item.description} />
        </DeepTable.Cell>

        <DeepTable.Cell right>{formatNumber(item.quantity)}</DeepTable.Cell>

        <DeepTable.Cell right>{item.unit}</DeepTable.Cell>

        <DeepTable.Cell right>
          <PriceAdvanced amount={item.unitPrice} />
        </DeepTable.Cell>

        <DeepTable.Cell right>
          <PriceAdvanced amount={item.totalExVAT} />
        </DeepTable.Cell>

        <DeepTable.Cell center>
          <ProgressStatementItemPreviousCumulative item={item} previousItemValues={previousItemValues} />
        </DeepTable.Cell>

        <DeepTable.Cell variant="shadow" center onClick={handleFieldFocus}>
          <Box display={isUpdatable ? undefined : 'none'}>
            <ProgressStatementItemCumulativeField item={item} previousItemValues={previousItemValues} />
          </Box>

          {!isUpdatable && (
            <>
              {formatPercentage(item.progressPercentage)}
              <Tooltip label={t('progressStatement:tooltips.addCommentOnLine')} placement="right">
                <IconButton
                  ml={2}
                  variant="ghost"
                  icon={<PencilIcon color={values[itemCommentFieldName] ? 'gray.800' : 'gray.400'} />}
                  onClick={handleComment(id)}
                  aria-label="Comment"
                />
              </Tooltip>
              <HiddenField<ProgressStatementEditItemForm> name={itemCommentFieldName} />
            </>
          )}
        </DeepTable.Cell>

        <DeepTable.Cell right>
          <ProgressStatementItemTotalLabel item={item} isUpdatable={isUpdatable} />
        </DeepTable.Cell>
      </DeepTable.Row>

      {item.note && <ProgressStatementCommentItemRow depth={depth}>{item.note}</ProgressStatementCommentItemRow>}
    </Box>
  );
};
