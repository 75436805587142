import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import {
  Button,
  PriceAdvanced,
  AmountSummary,
  ActionMenu,
  Tooltip,
  SimpleDashboardIcon,
  Onboarding,
  SimpleTable,
  type SimpleTableProps,
  Card,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import type { IBudgetUsage, IProject } from '@graneet/business-logic';
import { PERMISSION, sumObjects } from '@graneet/business-logic';

import { useProjectPlannedAmount } from 'features/project/services/project.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { BudgetEditModal } from 'features/budget/components/BudgetEditModal';
import { BudgetDeleteModal } from 'features/budget/components/BudgetDeleteModal';
import { useComponentTypes } from 'features/component-type/services/component-type.api';
import { ComponentTypeBadge } from 'features/component-type/components/ComponentTypeBadge';
import { getBudgetType } from 'features/budget/services/budget.util';
import { useBudgetUsages } from 'features/budget/services/budget.api';

const AmountExVATCell = ({ data }: { data?: IBudgetUsage }) =>
  data ? <PriceAdvanced amount={data.amountExVAT} /> : null;

const ConsumedAmountExVATCell = ({ data }: { data?: IBudgetUsage }) =>
  data ? <PriceAdvanced amount={data.consumedAmountExVAT} /> : null;

const RemainingToSpendExVATCell = ({ data }: { data?: IBudgetUsage }) =>
  data ? <PriceAdvanced amount={data.remainingToSpendExVAT} isAmountColored /> : null;

interface ViewProjectBudgetsSubTabProps {
  project: IProject;
}

export const ViewProjectBudgetsSubTab: FC<ViewProjectBudgetsSubTabProps> = ({ project }) => {
  const { t } = useTranslation(['project']);
  const { state } = useLocation<{ forceCreate?: boolean }>();

  const alreadyForceOpenRef = useRef(false);

  const componentTypes = useComponentTypes();
  const budgetUsages = useBudgetUsages(project.id);
  const projectPannedAmount = useProjectPlannedAmount(project.id);

  const getComponentType = useCallback((id: number) => getBudgetType(componentTypes.data, id), [componentTypes.data]);

  const [budgetToDelete, setBudgetToDelete] = useState<IBudgetUsage | null>(null);
  const [budgetToEdit, setBudgetToEdit] = useState<IBudgetUsage | null>(null);

  const deleteModal = useDisclosure();

  const {
    onOpen: onCreateOpen,
    onClose: onCreateClose,
    isOpen: isCreateOpen,
  } = useDisclosure({
    onClose: () => setBudgetToEdit(null),
  });

  const openEdit = useCallback(
    (budget: IBudgetUsage) => () => {
      setBudgetToEdit(budget);
      onCreateOpen();
    },
    [onCreateOpen],
  );

  const openDelete = useCallback(
    (budgetUsage: IBudgetUsage) => () => {
      setBudgetToDelete(budgetUsage);
      deleteModal.onOpen();
    },
    [deleteModal],
  );

  const disabledTypes = useMemo(() => budgetUsages.data.data.map((ct) => ct.typeId), [budgetUsages.data]);

  const allTypesUsed = useMemo(
    () => disabledTypes.length === componentTypes.data.length,
    [disabledTypes, componentTypes.data],
  );

  const hasCreateOrDeleteBudgetPermission = usePermissions([PERMISSION.CREATE_BUDGET]);
  const getCreateBudgetButtonProps = useDisabledButtonProps([PERMISSION.CREATE_BUDGET]);

  const createBudgetButton = useMemo(() => {
    const buttonProps = getCreateBudgetButtonProps();
    return (
      <Button onClick={onCreateOpen} {...buttonProps} isDisabled={allTypesUsed || buttonProps.isDisabled}>
        {t('project:budget.create.title')}
      </Button>
    );
  }, [allTypesUsed, getCreateBudgetButtonProps, onCreateOpen, t]);

  useEffect(() => {
    if (state?.forceCreate && !allTypesUsed && budgetUsages.data.data && !alreadyForceOpenRef.current) {
      alreadyForceOpenRef.current = true;
      onCreateOpen();
    }
  }, [state, onCreateOpen, allTypesUsed, budgetUsages.data]);

  const hasPermissionsToSeeQuotes = usePermissions([PERMISSION.DISPLAY_QUOTES]);
  const hasPermissionsToSeeOrder = usePermissions([PERMISSION.ACCESS_ORDER]);

  const TypeCell = useCallback(
    ({ data }: { data?: IBudgetUsage }) => (data ? <ComponentTypeBadge type={getComponentType(data.typeId)!} /> : null),
    [getComponentType],
  );
  const DisbursementExVATCell = useCallback(
    ({ data }: { data?: IBudgetUsage }) =>
      data && projectPannedAmount.data.budgets?.[data.typeId] ? (
        <PriceAdvanced amount={projectPannedAmount.data.budgets?.[data.typeId].amountExVAT} />
      ) : (
        '-'
      ),
    [projectPannedAmount.data.budgets],
  );
  const MenuCell = useCallback(
    ({ data }: { data?: IBudgetUsage }) =>
      data ? (
        <ActionMenu>
          <ActionMenu.Edit onClick={openEdit(data)} label={t('project:budget.edit.label')} />

          {getComponentType(data.typeId)?.isWorkforce ? (
            <Tooltip
              label={t('project:budget.delete.workforce')}
              shouldWrapChildren
              placement="bottom-start"
              isDisabled={data.consumedAmountExVAT === 0}
            >
              <ActionMenu.Delete
                isDisabled={data.consumedAmountExVAT !== 0}
                onClick={openDelete(data)}
                label={t('project:budget.delete.label')}
              />
            </Tooltip>
          ) : (
            <Tooltip
              label={t('project:budget.delete.typeUsed')}
              shouldWrapChildren
              placement="bottom-start"
              isDisabled={!data.isUsedInOrders}
            >
              <ActionMenu.Delete
                isDisabled={data.isUsedInOrders}
                onClick={openDelete(data)}
                label={t('project:budget.delete.label')}
              />
            </Tooltip>
          )}
        </ActionMenu>
      ) : null,
    [getComponentType, openDelete, openEdit, t],
  );

  const columnDefs = useMemo<SimpleTableProps<IBudgetUsage>['columnDefs']>(
    () => [
      {
        field: 'typeName',
        headerName: t('project:budget.fields.type'),
        sortable: true,
        cellRenderer: TypeCell,
      },
      {
        field: 'disbursementExVAT' as any,
        headerName: t('project:budget.fields.disbursementExVAT'),
        cellRenderer: DisbursementExVATCell,
        sortable: true,
        hide: !hasPermissionsToSeeQuotes,
      },
      {
        field: 'amountExVAT',
        headerName: t('project:budget.fields.amountExVAT'),
        sortable: true,
        cellRenderer: AmountExVATCell,
      },
      {
        field: 'consumedAmountExVAT',
        headerName: t('project:budget.fields.consumedAmountExVAT'),
        cellRenderer: ConsumedAmountExVATCell,
        sortable: true,
        hide: !hasPermissionsToSeeOrder,
      },
      {
        field: 'remainingToSpendExVAT',
        headerName: t('project:budget.fields.remainingToSpendExVAT'),
        cellRenderer: RemainingToSpendExVATCell,
        sortable: true,
        hide: !hasPermissionsToSeeOrder,
      },
      {
        field: 'menu' as any,
        cellRenderer: MenuCell,
        hide: !hasCreateOrDeleteBudgetPermission,
        width: 20,
        headerName: '',
      },
    ],
    [
      DisbursementExVATCell,
      MenuCell,
      TypeCell,
      hasCreateOrDeleteBudgetPermission,
      hasPermissionsToSeeOrder,
      hasPermissionsToSeeQuotes,
      t,
    ],
  );

  return (
    <>
      {budgetUsages.data.data.length === 0 ? (
        <Onboarding icon={<SimpleDashboardIcon boxSize={45} />} action={createBudgetButton}>
          {t('project:budget.noBudget')}
        </Onboarding>
      ) : (
        <Card px={0}>
          <Flex direction="column" gap={4}>
            <Flex pr={6} w="100%" justifyContent="flex-end">
              {createBudgetButton}
            </Flex>

            <SimpleTable gridId="budget" columnDefs={columnDefs} rowData={budgetUsages.data.data} />

            <AmountSummary>
              <AmountSummary.Item
                label={t('project:budget.summary.totalProjectExVAT')}
                important
                amount={sumObjects(budgetUsages.data.data, 'amountExVAT')}
              />
              {hasPermissionsToSeeOrder && (
                <>
                  <AmountSummary.Item
                    light
                    label={t('project:budget.summary.totalOrdersExVAT')}
                    amount={sumObjects(budgetUsages.data.data, 'consumedAmountExVAT')}
                  />

                  <AmountSummary.Item
                    important
                    contrasted
                    label={t('project:budget.summary.totalRemainingExVAT')}
                    amount={sumObjects(budgetUsages.data.data, 'remainingToSpendExVAT')}
                  />
                </>
              )}
            </AmountSummary>
          </Flex>
        </Card>
      )}

      <BudgetEditModal
        key={budgetToEdit?.id}
        isOpen={isCreateOpen}
        onClose={onCreateClose}
        project={project}
        disabledTypes={budgetToEdit ? [] : disabledTypes}
        budgetUsage={budgetToEdit}
      />
      <BudgetDeleteModal modal={deleteModal} budgetUsage={budgetToDelete} />
    </>
  );
};
