import type {
  ICustomDiscountCreationDTO,
  IAccountManagersAssociateDTO,
  IDiscountCreationDTO,
  IPdf,
  IQuote,
  IQuoteAccountManager,
  IQuoteCheckResponseDTO,
  IQuoteCreationDTO,
  IQuoteCreationFromImportDTO,
  IQuoteGenerateExportDTO,
  IQuoteInfosResponse,
  IQuoteRestoreDTO,
  IQuoteRestoreResponseDTO,
  IQuoteUpdateClientDTO,
  IQuoteUpdateCommentDTO,
  IQuoteUpdateDTO,
  IQuoteUpdateProjectDTO,
  IQuoteUpdateResponse,
  IQuoteUpdateStatusAcceptedDTO,
  IQuoteVersionsDTO,
  QuotePaginatedResponse,
  IQuoteUpdateTagsDTO,
  IQuoteImportDTO,
} from '@graneet/business-logic';

import { api } from 'features/api/services/api.service';
import { dtoToFormData } from 'features/api/services/api.util';

const QUOTE_PATH = '/quotes';

export const getQuotesPaginated = (queryParams: URLSearchParams) =>
  api.get<URLSearchParams, QuotePaginatedResponse>(QUOTE_PATH, queryParams);

export const getQuotesNotAccepted = (projectId: number) =>
  api.get<{ projectId: number }, IQuote[]>(`${QUOTE_PATH}/not-accepted`, { projectId });

export const getQuoteInfos = (quoteId: number) => api.get<never, IQuoteInfosResponse>(`${QUOTE_PATH}/${quoteId}/infos`);

export const getAvailableQuoteTags = () => api.get<never, string[]>(`${QUOTE_PATH}/tags`);

export const getQuoteById = (quoteId: number) => api.get<never, IQuote>(`${QUOTE_PATH}/${quoteId}`);

export const createQuote = (quoteCreationDTO: IQuoteCreationDTO) =>
  api.post<IQuoteCreationDTO, IQuote>(QUOTE_PATH, quoteCreationDTO);

export const importQuote = (quoteId: number, dto: IQuoteImportDTO) =>
  api.patch<any, IQuote>(`${QUOTE_PATH}/${quoteId}/import`, dto);

export const createQuoteFromImport = (dto: IQuoteCreationFromImportDTO) =>
  api.postWithMultiPart<FormData, IQuote>(`${QUOTE_PATH}/create-from-import`, dtoToFormData(dto));

export const duplicateQuote = (originalQuoteId: number, quoteUpdateDTO: IQuoteUpdateDTO) =>
  api.post<IQuoteUpdateDTO, IQuote>(`${QUOTE_PATH}/${originalQuoteId}/duplicate`, quoteUpdateDTO);

export const patchQuote = (quoteId: number, quoteUpdateDTO: IQuoteUpdateDTO) =>
  api.patch<IQuoteUpdateDTO, IQuote>(`${QUOTE_PATH}/${quoteId}`, quoteUpdateDTO);

export const patchQuoteComment = (quoteId: number, quoteUpdateCommentDTO: IQuoteUpdateCommentDTO) =>
  api.patch<IQuoteUpdateCommentDTO, IQuote>(`${QUOTE_PATH}/${quoteId}/comment`, quoteUpdateCommentDTO);

export const patchQuoteTags = (quoteId: number, quoteUpdateTagsDTO: IQuoteUpdateTagsDTO) =>
  api.patch<IQuoteUpdateTagsDTO, IQuote>(`${QUOTE_PATH}/${quoteId}/tags`, quoteUpdateTagsDTO);

export const updateQuoteProject = (quoteId: number, projectId: number | null) =>
  api.patch<IQuoteUpdateProjectDTO, IQuote>(`${QUOTE_PATH}/${quoteId}/project`, { projectId });

export const updateQuoteClient = (quoteId: number, clientId: number | null) =>
  api.patch<IQuoteUpdateClientDTO, IQuote>(`${QUOTE_PATH}/${quoteId}/client`, { clientId });

export const checkQuoteCompletion = (quoteId: number) =>
  api.patch<never, IQuoteCheckResponseDTO>(`${QUOTE_PATH}/${quoteId}/check`);

export const updateQuoteStatus =
  (status: 'completed' | 'draft' | 'lost' | 'accept') => (quoteId: number, data?: IQuoteUpdateStatusAcceptedDTO) =>
    api.patch<IQuoteUpdateStatusAcceptedDTO | undefined, IQuote>(`${QUOTE_PATH}/${quoteId}/${status}`, data);

export const updateQuoteStatusToCompleted = (quoteId: number) => updateQuoteStatus('completed')(quoteId);

export const updateQuoteStatusToDraft = (quoteId: number) => updateQuoteStatus('draft')(quoteId);

export const updateQuoteStatusToLost = (quoteId: number) => updateQuoteStatus('lost')(quoteId);

export const updateQuoteStatusToAccepted = (quoteId: number, data: IQuoteUpdateStatusAcceptedDTO) =>
  updateQuoteStatus('accept')(quoteId, data);

export const getQuoteVersions = (quoteId: number) =>
  api.get<never, IQuoteVersionsDTO>(`${QUOTE_PATH}/${quoteId}/versions`);

export const getQuoteLastVersion = (quoteId: number) => api.get<never, IQuote>(`${QUOTE_PATH}/${quoteId}/last-version`);

export const putQuoteDiscount = (quoteId: number, data: IDiscountCreationDTO) =>
  api.put<IDiscountCreationDTO, IQuoteUpdateResponse>(`${QUOTE_PATH}/${quoteId}/discount`, data);

export const deleteQuoteDiscount = (quoteId: number) =>
  api.delete<never, IQuoteUpdateResponse>(`${QUOTE_PATH}/${quoteId}/discount`);

export const postQuoteCustomDiscount = (quoteId: number, data: ICustomDiscountCreationDTO) =>
  api.post<ICustomDiscountCreationDTO, IQuoteUpdateResponse>(`${QUOTE_PATH}/${quoteId}/custom-discount`, data);

export const patchQuoteCustomDiscount = (quoteId: number, customDiscountId: string, data: ICustomDiscountCreationDTO) =>
  api.patch<ICustomDiscountCreationDTO, IQuoteUpdateResponse>(
    `${QUOTE_PATH}/${quoteId}/custom-discount/${customDiscountId}`,
    data,
  );

export const deleteQuoteCustomDiscount = (quoteId: number, customDiscountId: string) =>
  api.delete<never, IQuoteUpdateResponse>(`${QUOTE_PATH}/${quoteId}/custom-discount/${customDiscountId}`);

export const updateQuoteAccountManagers = (
  quoteId: number,
  accountManagersAssociateDTO: IAccountManagersAssociateDTO,
) =>
  api.patch<IAccountManagersAssociateDTO, IQuoteAccountManager[]>(
    `${QUOTE_PATH}/${quoteId}/account-managers`,
    accountManagersAssociateDTO,
  );

export const deleteQuote = (quoteId: number) => api.delete<never, void>(`${QUOTE_PATH}/${quoteId}`);

export const generateDraftQuotePDF = (quoteId: number) =>
  api.put<null, IPdf>(`${QUOTE_PATH}/${quoteId}/generate-draft-pdf`, null);

export const generatePDFExport = (quoteGenerateExportDTO: IQuoteGenerateExportDTO) =>
  api.post<IQuoteGenerateExportDTO, IPdf>(`${QUOTE_PATH}/export/pdf`, quoteGenerateExportDTO);

export const generateSpreadsheetExport = (quoteGenerateExportDTO: IQuoteGenerateExportDTO) =>
  api.post<IQuoteGenerateExportDTO, string>(`${QUOTE_PATH}/export/spreadsheet`, quoteGenerateExportDTO);

export const restoreQuote = (restoreQuoteDTO: IQuoteRestoreDTO) =>
  api.post<IQuoteRestoreDTO, IQuoteRestoreResponseDTO>(`${QUOTE_PATH}/restore`, restoreQuoteDTO);
