import type { FC } from 'react';
import { useState } from 'react';
import { Flex, HStack, useDisclosure } from '@chakra-ui/react';
import { Button, Onboarding, ActionMenu, Section, useToast, SimplePuzzleIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'graneet-form';
import type {
  ILibraryJob,
  ILibraryComponent,
  ILibraryJobComponent,
  ILibraryComponentWithRelations,
} from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import type { LibraryJobEditCardForm } from '../../forms/library-job-component.form';
import { getQuantityFieldName } from '../../forms/library-job-component.form';
import {
  useLibraryJobDeleteComponents,
  useLibraryJobImportComponents,
  useLibraryJobUpdateComponents,
} from '../../services/library-job.api';

import { LibraryJobComponentsTable } from './LibraryJobComponentsTable';

import { LibraryComponentAddModal } from 'features/library-component/components/modals/LibraryComponentAddModal';
import { LibraryComponentImportModal } from 'features/library-component/components/modals/LibraryComponentImportModal';
import { LibraryComponentEditModal } from 'features/library-component/components/modals/LibraryComponentEditModal';
import { LibraryComponentDuplicateModal } from 'features/library-component/components/modals/LibraryComponentDuplicateModal';
import { sortLibraryComponents } from 'features/library-component/services/library-component.util';
import { usePermissions } from 'features/role/hooks/usePermissions';

interface ILibraryJobComponentsListing {
  libraryJob: ILibraryJob;
  libraryJobComponents: ILibraryJobComponent[];
  onComponentUpdated: () => void;
}

export const LibraryJobComponentsListing: FC<ILibraryJobComponentsListing> = ({
  libraryJob,
  libraryJobComponents,
  onComponentUpdated,
}) => {
  const { t } = useTranslation(['global', 'library']);
  const toast = useToast();
  const form = useFormContext<LibraryJobEditCardForm>();
  const hasUpdateLibraryPermission = usePermissions([PERMISSION.UPDATE_LIBRARY]);

  const importComponentsModalControls = useDisclosure();
  const { onOpen: openImportComponentModal } = importComponentsModalControls;

  const [libraryComponent, setLibraryComponent] = useState<ILibraryComponentWithRelations | null>(null);
  // Library components modal controls
  const componentCreationModalControls = useDisclosure();
  const componentModifyModalControls = useDisclosure({
    onClose() {
      setLibraryComponent(null);
    },
  });
  const componentDuplicateModalControls = useDisclosure({
    onClose() {
      setLibraryComponent(null);
    },
  });

  const libraryJobImportComponentsMutation = useLibraryJobImportComponents();
  const libraryJobUpdateComponentsMutation = useLibraryJobUpdateComponents();
  const useLibraryJobDeleteComponentsMutation = useLibraryJobDeleteComponents();

  // Dropdown callbacks
  const handleModifyAction = (libraryComponentToModify: ILibraryComponentWithRelations) => () => {
    setLibraryComponent(libraryComponentToModify);
    componentModifyModalControls.onOpen();
  };

  const handleDuplicateAction = (libraryComponentToDuplicate: ILibraryComponentWithRelations) => () => {
    setLibraryComponent(libraryComponentToDuplicate);
    componentDuplicateModalControls.onOpen();
  };

  // Modals callbacks
  const handleDeleteAction = (libraryComponentToDelete: ILibraryComponent) => async () => {
    useLibraryJobDeleteComponentsMutation.mutate(
      { id: libraryJob.id, component: libraryComponentToDelete },
      {
        onSuccess: () => {
          onComponentUpdated();
        },
      },
    );
  };

  const onLibraryComponentAddedOrDuplicated = async (libraryComponentOnEdit: ILibraryComponent) => {
    await libraryJobImportComponentsMutation.mutateAsync({
      id: libraryJob.id,
      dto: {
        libraryComponentsIds: [libraryComponentOnEdit.id],
      },
    });

    if (libraryComponent?.id) {
      // Keep old quantity
      const quantityFielName = getQuantityFieldName(libraryComponent?.id);
      const { [quantityFielName]: quantity } = form.getFormValues();
      if (quantity) {
        await libraryJobUpdateComponentsMutation.mutateAsync({
          id: libraryJob.id,
          componentId: libraryComponentOnEdit.id,
          dto: { quantity },
        });
        form.setFormValues({
          [getQuantityFieldName(libraryComponentOnEdit.id)]: quantity,
        });
      }
    }

    await onComponentUpdated();
  };

  const onLibraryComponentsImported = async (libraryComponentsIds: number[]) => {
    await libraryJobImportComponentsMutation.mutateAsync({
      id: libraryJob.id,
      dto: {
        libraryComponentsIds,
      },
    });

    toast.success(
      t('library:importLibraryComponentToLibraryJobModal.successToast', {
        count: libraryComponentsIds.length,
      }),
    );
    importComponentsModalControls.onClose();
    onComponentUpdated();
  };

  const nonSelectableIds = libraryJobComponents.reduce<number[]>(
    (acc, { libraryComponent: lc }) => [...acc, lc.id],
    [],
  );

  const actionsButtons = (
    <HStack spacing={4}>
      {hasUpdateLibraryPermission && (
        <>
          <Button variant="outline" onClick={componentCreationModalControls.onOpen}>
            {t('library:actions.createNewLibraryComponent')}
          </Button>
          <Button onClick={openImportComponentModal}>{t('library:actions.importExistingLibraryComponent')}</Button>
        </>
      )}

      <LibraryComponentAddModal
        key={`${componentCreationModalControls.isOpen}`}
        onComponentCreated={onLibraryComponentAddedOrDuplicated}
        modalControls={componentCreationModalControls}
      />
      <LibraryComponentImportModal
        key={`${importComponentsModalControls.onOpen}`}
        onLibraryComponentsImported={onLibraryComponentsImported}
        nonSelectableIds={nonSelectableIds}
        {...importComponentsModalControls}
      />
      {libraryComponent && (
        <>
          <LibraryComponentEditModal
            modalControls={componentModifyModalControls}
            libraryComponentToModify={libraryComponent}
            onLibraryComponentUpdated={onComponentUpdated}
          />
          <LibraryComponentDuplicateModal
            key={libraryComponent.id}
            sourceLibraryComponent={libraryComponent}
            modalControls={componentDuplicateModalControls}
            onLibraryComponentDuplicated={onLibraryComponentAddedOrDuplicated}
          />
        </>
      )}
    </HStack>
  );

  if (!libraryJobComponents.length) {
    return (
      <Flex mt={6}>
        <Onboarding
          icon={<SimplePuzzleIcon boxSize={45} />}
          action={actionsButtons}
          actionBoxProps={{
            mt: -4,
          }}
        />
      </Flex>
    );
  }

  const componentsWithQuantity = libraryJobComponents.map(({ quantity, libraryComponent: lc }) => ({
    quantity,
    ...lc,
  }));
  const componentsWithQuantitySorted = sortLibraryComponents(componentsWithQuantity);

  return (
    <Section
      title={t('library:jobModify.components')}
      topContent={
        <Flex w="100%" justifyContent="flex-end">
          {actionsButtons}
        </Flex>
      }
      mt={6}
    >
      <LibraryJobComponentsTable
        libraryComponents={componentsWithQuantitySorted}
        renderActionsMenu={
          hasUpdateLibraryPermission
            ? (currentLibraryComponent) => (
                <ActionMenu>
                  <ActionMenu.Edit onClick={handleModifyAction(currentLibraryComponent)} />
                  <ActionMenu.Duplicate onClick={handleDuplicateAction(currentLibraryComponent)} />
                  <ActionMenu.Delete onClick={handleDeleteAction(currentLibraryComponent)} />
                </ActionMenu>
              )
            : undefined
        }
      />
    </Section>
  );
};
