import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, RICH_TEXT_INLINE_TOOLBAR_PRESET, RichTextField } from '@graneet/lib-ui';
import { PERMISSION } from '@graneet/business-logic';
import { HiddenField, useFormContext, useHiddenField, useOnChangeValues } from 'graneet-form';

import type { OrderEditForm } from '../../forms/order-edit-wizard';

import { usePermissions } from 'features/role/hooks/usePermissions';

export const OrderCommentCard: FC = () => {
  const { t } = useTranslation(['orders']);

  const hasCreateOrderPermission = usePermissions([PERMISSION.CREATE_ORDER]);

  const form = useFormContext<OrderEditForm>();
  const { comment } = useOnChangeValues(form, ['comment']);
  const commentHiddenField = useHiddenField(form, 'comment');

  if (!comment && !hasCreateOrderPermission) {
    return <HiddenField {...commentHiddenField} />;
  }

  return (
    <Card title={t('orders:commentCard.title')}>
      <RichTextField<OrderEditForm>
        name="comment"
        navbarType="inline"
        placeholder={t('orders:commentCard.placeholder')}
        isDisabled={!hasCreateOrderPermission}
        configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
      />
    </Card>
  );
};
