import type { FC } from 'react';
import { useCallback } from 'react';
import type { IFile } from '@graneet/business-logic';
import { ActionMenu, Card, File, SimpleDownloadIcon, SimpleViewIcon } from '@graneet/lib-ui';
import { HiddenField, useFormContext, useOnChangeValues } from 'graneet-form';
import { isArray } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { SupplierInvoiceFileAddReceiptFileButton } from './SupplierInvoiceFileAddReceiptFileButton';

import { formatFileDownloadUrl, formatFilePreviewUrl, isIFile } from 'features/file/services/file.util';

interface SupplierInvoiceReceiptFilesCardProps {
  needsConfirmationToSubmit?: boolean;

  isDisabled?: boolean;

  tooltip?: string;

  onFileDelete?(deletedFileId: string): Promise<void>;

  onFileAdded?(addedFile: File): Promise<void>;
}

export interface SupplierInvoiceFilesForm {
  initialReceiptFiles: IFile[];

  receiptFiles: File[];

  deletedReceiptFilesIds: string[];
}

export const SupplierInvoiceReceiptFilesCard: FC<SupplierInvoiceReceiptFilesCardProps> = ({
  needsConfirmationToSubmit = false,
  isDisabled = false,
  tooltip,
  onFileDelete,
  onFileAdded,
}) => {
  const { t } = useTranslation(['global']);

  const form = useFormContext<SupplierInvoiceFilesForm>();
  const { receiptFiles, deletedReceiptFilesIds, initialReceiptFiles } = useOnChangeValues(form, [
    'receiptFiles',
    'deletedReceiptFilesIds',
    'initialReceiptFiles',
  ]);

  /**
   * Handle to delete existing file in view and edition context
   * Not called in creation context
   */
  const handleDeleteFile = useCallback(
    (deletedFile: File | IFile, index: number) => () => {
      if (isIFile(deletedFile)) {
        const fileId = deletedFile.id;
        onFileDelete?.(fileId);
        const newDeletedReceiptFilesIds = isArray(deletedReceiptFilesIds)
          ? [...deletedReceiptFilesIds, fileId]
          : [fileId];
        form.setFormValues({ deletedReceiptFilesIds: newDeletedReceiptFilesIds });
      }
      const newFiles = (receiptFiles || []).filter((_: unknown, i: number) => i !== index);
      form.setFormValues({ receiptFiles: newFiles });
    },
    [deletedReceiptFilesIds, form, onFileDelete, receiptFiles],
  );

  const handleFileSubmit = useCallback(
    async (file: File) => {
      if (onFileAdded) {
        await onFileAdded?.(file);
      }
      const { receiptFiles: newReceiptFiles } = form.getFormValues();
      form.setFormValues({ receiptFiles: isArray(newReceiptFiles) ? [...newReceiptFiles, file] : [file] });
    },
    [form, onFileAdded],
  );

  const allReceiptFiles: (File | IFile)[] = [
    ...(receiptFiles || []),
    ...(initialReceiptFiles || []).filter((irf) => !(deletedReceiptFilesIds || []).includes(irf.id)),
  ];

  return (
    <Card title={t('global:receiptsFileField.title')}>
      {allReceiptFiles.map((file, index) => (
        <File
          name={file.name}
          key={uuid()}
          actions={
            <ActionMenu>
              {isIFile(file) && (
                <>
                  <ActionMenu.Action
                    icon={<SimpleViewIcon />}
                    label={t('global:pdf.view')}
                    onClick={() => window.open(formatFilePreviewUrl(file.id))}
                  />
                  <ActionMenu.Action
                    icon={<SimpleDownloadIcon />}
                    label={t('global:pdf.download')}
                    onClick={() => window.open(formatFileDownloadUrl(file.id))}
                  />
                </>
              )}
              <ActionMenu.Delete onClick={handleDeleteFile(file, index)} isDisabled={isDisabled} tooltip={tooltip} />
            </ActionMenu>
          }
        />
      ))}

      <SupplierInvoiceFileAddReceiptFileButton
        onSubmit={handleFileSubmit}
        needsConfirmationToSubmit={needsConfirmationToSubmit}
        isDisabled={isDisabled}
        tooltip={tooltip}
      />

      {/* Hidden field to store new files and deleted files ids */}
      <HiddenField<SupplierInvoiceFilesForm> name="initialReceiptFiles" />
      <HiddenField<SupplierInvoiceFilesForm> name="receiptFiles" />
      <HiddenField<SupplierInvoiceFilesForm> name="deletedReceiptFilesIds" />
    </Card>
  );
};
