import { useMemo } from 'react';
import type { FC, ReactNode } from 'react';
import type { PaginatedTableSetting } from '@graneet/lib-ui';
import { PaginatedTableSettingsProvider as PaginatedTableSettingsProviderGeneric } from '@graneet/lib-ui';

import { useEditUserSettings, useUserSettings } from '../../user/services/user.api';

export const PaginatedTableSettingsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const userSettings = useUserSettings();

  const { mutateAsync: editUserSettingsMutation } = useEditUserSettings(true);

  const value = useMemo(
    () => ({
      settings: JSON.parse(userSettings.data.tablesSettingColumnsJSON ?? '{}'),
      updateSettings: (gridId: string, setting: PaginatedTableSetting[]) => {
        const obj = JSON.parse(userSettings.data.tablesSettingColumnsJSON ?? '{}');

        return editUserSettingsMutation({
          tablesSettingColumnsJSON: JSON.stringify({
            ...obj,
            [gridId]: setting,
          }),
        });
      },
    }),
    [editUserSettingsMutation, userSettings.data.tablesSettingColumnsJSON],
  );

  return <PaginatedTableSettingsProviderGeneric value={value}>{children}</PaginatedTableSettingsProviderGeneric>;
};
