import { SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import type { DocumentStatusCardAction, DocumentStatusCardIconValue } from '@graneet/lib-ui';

import type { SupplierInvoiceActionsType } from './supplier-invoice.actions.type';
import { getSupplierInvoiceToPayActions } from './supplier-invoice-to-pay.actions';
import { getSupplierInvoiceToProcessActions } from './supplier-invoice-to-process.actions';
import { getSupplierInvoicePartiallyPaidActions } from './supplier-invoice-partially-paid.actions';
import { getSupplierInvoiceMenuAction, getSupplierInvoiceUpdatePaymentAction } from './supplier-invoice.actions.util';

export function getSupplierInvoiceActions(params: SupplierInvoiceActionsType): DocumentStatusCardAction[] {
  const { supplierInvoice } = params;

  switch (supplierInvoice.status) {
    case SUPPLIER_INVOICE_STATUS.TO_PROCESS:
      return getSupplierInvoiceToProcessActions(params);
    case SUPPLIER_INVOICE_STATUS.TO_PAY:
    case SUPPLIER_INVOICE_STATUS.TO_VALIDATE:
      return getSupplierInvoiceToPayActions(params);
    case SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID:
      return getSupplierInvoicePartiallyPaidActions(params);
    case SUPPLIER_INVOICE_STATUS.PAID:
    case SUPPLIER_INVOICE_STATUS.DIRECT_PAYMENT:
      return [getSupplierInvoiceUpdatePaymentAction(params), getSupplierInvoiceMenuAction(params)];
    default:
      supplierInvoice.status satisfies never;
      throw new Error('Unknown status');
  }
}

export function getSupplierInvoiceStatusCardIcon(status: SUPPLIER_INVOICE_STATUS): DocumentStatusCardIconValue {
  switch (status) {
    case SUPPLIER_INVOICE_STATUS.TO_PROCESS:
      return 'draft';
    case SUPPLIER_INVOICE_STATUS.TO_PAY:
      return 'finalized';
    case SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID:
      return 'partial';
    case SUPPLIER_INVOICE_STATUS.TO_VALIDATE:
      return 'onHold';
    case SUPPLIER_INVOICE_STATUS.PAID:
    case SUPPLIER_INVOICE_STATUS.DIRECT_PAYMENT:
      return 'completed';
    default:
      status satisfies never;
      throw new Error('Unknown status');
  }
}
