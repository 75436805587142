import { SimpleGrid, Stack } from '@chakra-ui/react';
import type { IFile, IOrderResponseDTO, IProject, RequiredByKeys } from '@graneet/business-logic';
import { getEmailVariableData, HISTORY_ENTITY_TYPE, ORDER_STATUS, PERMISSION } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Form, useForm } from 'graneet-form';

import { AssociatedSupplierInvoicesCard } from '../AssociatedSupplierInvoicesCard/AssociatedSupplierInvoicesCard';
import type { OrderFilesForm } from '../cards/OrderFileReceiptFilesCard/OrderFileReceiptFilesCard';
import { OrderFileReceiptFilesCard } from '../cards/OrderFileReceiptFilesCard/OrderFileReceiptFilesCard';
import { OrderTagsCard } from '../cards/OrderTagsCard';
import { OrderCommentCard } from '../cards/OrderCommentCard';

import { AssociatedSupplierCard } from './AssociatedSupplierCard';
import { InformationsCard } from './InformationsCard';
import { OrderAmountCard } from './OrderAmountCard';
import { OrderStatusCard } from './OrderStatusCard/OrderStatusCard';

import { useEmailTemplateRichTextConfiguration } from 'features/email-template/hooks/useEmailTemplateRichTextConfiguration';
import { isIFile } from 'features/file/services/file.util';
import { HistoryCard } from 'features/history/components/HistoryCard';
import { EmailProvider } from 'features/history/contexts/EmailContext';
import { useOrderUpdate } from 'features/order/services/order.api';
import { ProjectAssociationCard } from 'features/project/components/cards/ProjectAssociationCard';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

type OrderProps = {
  order: IOrderResponseDTO;
  onDeleted: () => void;
};

type CommentFormValues = {
  comment?: string;
};

export const OrderDetails: FC<OrderProps> = ({ order, onDeleted }) => {
  const { id, supplier, project, comment, orderFiles } = order;

  const [promptAssociate, setPromptAssociate] = useState(false);

  const hasAccessToSupplierInvoiceModule = usePermissions([PERMISSION.ACCESS_SUPPLIER_INVOICE]);

  const emailTemplateRichTextConfiguration = useEmailTemplateRichTextConfiguration(
    getEmailVariableData('order', {
      order,
      supplier: order.supplier,
      project: (order.project as RequiredByKeys<IProject, 'address'>) || null,
    }),
  );

  const orderUpdateMutation = useOrderUpdate();

  const onUpdateAfterBlur = useCallback(
    async (_: unknown, newComment: string) => {
      await orderUpdateMutation.mutateAsync({ id, dto: { comment: newComment } });
    },
    [id, orderUpdateMutation],
  );
  const commentForm = useForm<CommentFormValues>({ onUpdateAfterBlur });
  useEffect(() => {
    if (comment) {
      commentForm.setFormValues({ comment });
    }
  }, [comment, commentForm]);

  const receiptsForm = useForm<OrderFilesForm>();
  useEffect(() => {
    const initialReceiptFiles = orderFiles
      ?.map((f) => ({ file: f.file, isDisplayInOrderPdf: f.isDisplayInOrderPdf }))
      .filter(Boolean) as { file: IFile; isDisplayInOrderPdf: boolean }[];
    if (initialReceiptFiles) {
      receiptsForm.setFormValues({ initialReceiptFiles, receiptFiles: [] });
    }
  }, [orderFiles, receiptsForm]);

  const onStatusChangeSuccess = useCallback(
    (oldStatus: string) => {
      setPromptAssociate(oldStatus === ORDER_STATUS.PROCESSED && !order?.ordersSupplierInvoices?.length);
    },
    [order?.ordersSupplierInvoices?.length],
  );

  const onFileDelete = useCallback(
    async (fileId: string) => {
      await orderUpdateMutation.mutateAsync({ id: order.id, dto: { deletedFilesIds: JSON.stringify([fileId]) } });
    },
    [order.id, orderUpdateMutation],
  );

  const onFileAddedOrUpdated = useCallback(
    async (data: { file: File | IFile; isDisplayInOrderPdf: boolean }) => {
      const { file, isDisplayInOrderPdf } = data;

      if (isIFile(file)) {
        await orderUpdateMutation.mutateAsync({
          id: order.id,
          dto: {
            isOrderFilesDisplayedInPDFUpdate: JSON.stringify({
              [file.id]: data.isDisplayInOrderPdf,
            }),
          },
        });
      } else {
        await orderUpdateMutation.mutateAsync({
          id: order.id,
          dto: {},
          orderFiles: [{ file, isDisplayInOrderPdf }],
        });
      }
    },
    [order.id, orderUpdateMutation],
  );

  const getPermissionsButtonProps = useDisabledButtonProps([PERMISSION.CREATE_ORDER]);

  return (
    <EmailProvider>
      <SimpleGrid spacing={4} columns={2}>
        <Stack flex={1} spacing={4}>
          <InformationsCard order={order} />
          <AssociatedSupplierCard supplier={supplier!} />
          <ProjectAssociationCard
            project={project as RequiredByKeys<IProject, 'address'>}
            subProject={order.subProject}
          />
          <HistoryCard
            id={order.id}
            entity={HISTORY_ENTITY_TYPE.ORDER}
            emailTemplateRichTextConfiguration={emailTemplateRichTextConfiguration}
          />
        </Stack>
        <Stack flex={1} spacing={4}>
          <OrderStatusCard order={order} onStatusChangeSuccess={onStatusChangeSuccess} onDeleted={onDeleted} />
          <OrderAmountCard order={order} />
          {hasAccessToSupplierInvoiceModule && (
            <AssociatedSupplierInvoicesCard order={order} forceOpen={promptAssociate} />
          )}
          <Form form={commentForm}>
            <OrderCommentCard />
          </Form>
          <OrderTagsCard order={order} />
          <Form form={receiptsForm}>
            <OrderFileReceiptFilesCard
              buttonPermissionsProps={getPermissionsButtonProps()}
              onFileDelete={onFileDelete}
              onFileAdded={onFileAddedOrUpdated}
              onFileUpdate={onFileAddedOrUpdated}
            />
          </Form>
        </Stack>
      </SimpleGrid>
    </EmailProvider>
  );
};
