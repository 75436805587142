import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useLayoutEffect } from 'react';

import { useProject } from 'features/project/services/project.api';
import { Error } from 'features/common/components/Error';
import { useContactAssociatedToProject } from 'features/contact/services/contact.api';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { useDownPayment } from 'features/down-payment/services/down-payment.api';
import { DownPaymentDetail } from 'features/down-payment/components/DownPaymentDetail/DownPaymentDetail';
import { useProgressStatementsForSubProjects } from 'features/progress-statement/services/progress-statement.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewDownPaymentDetailScreen = () => {
  const { downPaymentId } = useParams<{
    downPaymentId: string;
  }>();
  const { t } = useTranslation(['statement', 'global']);
  const history = useHistory();
  const { updateHeaderTitle } = useHeaderContext();
  const { createRedirectionWithSavedFilters } = useFiltersQuery();

  const downPayment = useDownPayment(parseInt(downPaymentId, 10));
  const progressStatements = useProgressStatementsForSubProjects(downPayment.data.subProject?.id);
  const project = useProject(downPayment.data.project.id);
  const projectContacts = useContactAssociatedToProject(downPayment.data.project.id);

  const goBackToListing = createRedirectionWithSavedFilters('/sales/statements', { replace: true });

  const goToCancelDownPayment = useCallback(
    (createDraft: boolean) =>
      history.push(`/sales/statements/down-payments/${downPaymentId}/cancel`, {
        cancelledInvoiceNumber: downPayment.data.invoiceNumber,
        createDraft,
      }),
    [downPaymentId, downPayment, history],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(downPayment.data.name ?? '', [
      {
        name: t('statement:title'),
        link: '/sales/statements',
      },
    ]);
  }, [t, downPayment.data.name, updateHeaderTitle]);

  if (downPayment.data.isArchived) {
    return <Error />;
  }

  return (
    <DownPaymentDetail
      downPayment={downPayment.data}
      progressStatements={progressStatements.data.data}
      project={project.data}
      projectContacts={projectContacts.data}
      onGoBack={goBackToListing}
      onCancelConfirmed={goToCancelDownPayment}
      displayAssociatedProject
      displayPaymentTerms
      displayPrimaryClient
    />
  );
};
