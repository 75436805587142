import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';

import type { Filters } from '../PaginatedData/types/Filters';

import type { PaginatedStorage } from './PaginatedStorage';

export const useUrlPaginationStorage = (defaultFilters: Filters = {}): PaginatedStorage => {
  const { search, pathname, state } = useLocation();
  const { replace } = useHistory();

  const [query, setQuery] = useState<URLSearchParams>(() => {
    const url = new URLSearchParams(search);

    Object.entries(defaultFilters).forEach(([key, value]) => {
      if (!url.has(key)) {
        url.set(key, String(value));
      }
    });

    return url;
  });

  const resetFilters = useCallback(() => {
    replace(pathname, state);
    setQuery(new URLSearchParams());
  }, [pathname, replace, state]);

  const updateFilters = useCallback(
    (urlSearchParams: URLSearchParams) => {
      replace(`${pathname}?${urlSearchParams}`, state);
      setQuery(urlSearchParams);
    },
    [pathname, replace, state],
  );

  return useMemo(
    () => ({
      storage: query,
      resetFilters,
      updateFilters,
    }),
    [query, resetFilters, updateFilters],
  );
};
