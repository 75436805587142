import { Badge } from '@graneet/lib-ui';
import type { ISupplierInvoice } from '@graneet/business-logic';
import { SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

const isSupplierInvoiceNotFullyPayed = (supplierInvoice?: ISupplierInvoice): boolean =>
  !!supplierInvoice && supplierInvoice.status !== SUPPLIER_INVOICE_STATUS.PAID;

interface SupplierInvoiceLateDaysBadgeProps {
  supplierInvoice: ISupplierInvoice;
}

export const SupplierInvoiceLateDaysBadge: FC<SupplierInvoiceLateDaysBadgeProps> = ({ supplierInvoice }) => {
  const { t } = useTranslation(['supplierInvoices']);

  // If no late days
  if (
    !(!!supplierInvoice.lateDays && supplierInvoice.lateDays > 0 && isSupplierInvoiceNotFullyPayed(supplierInvoice))
  ) {
    return null;
  }

  return (
    <Badge showDot colorScheme="orange">
      {t('supplierInvoices:lateDays', { count: supplierInvoice.lateDays })}
    </Badge>
  );
};
