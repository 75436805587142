import { Box, Table, Tbody, Tr, Td } from '@chakra-ui/react';
import { formatNumber, useCurrency, Tooltip, SimpleHelpIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

interface TooltipHiddenCostComponentsTypeProps {
  hiddenCost: number;
  hiddenCostQuantity: number;
  quantity: number;
  unit: string | null;
  disbursementExVAT: number;
}

export const TooltipHiddenCostComponentsType = ({
  hiddenCost,
  hiddenCostQuantity,
  quantity,
  unit,
  disbursementExVAT,
}: TooltipHiddenCostComponentsTypeProps) => {
  const { t } = useTranslation(['quote']);
  const { formatAsAmount } = useCurrency();

  return (
    <Tooltip
      display="flex"
      backgroundColor="transparent"
      shadow="none"
      p={0}
      label={
        <Table
          variant="unstyled"
          size="sm"
          width={quantity && unit ? '22rem' : '16rem'}
          backgroundColor="white"
          borderRadius="md"
          color="gray.900"
          shadow="md"
        >
          <Tbody>
            <Tr>
              <Td>{t('quote:hiddenCost.flatDisbursement')}</Td>
              {quantity && unit ? (
                <Td textAlign="end" color="gray.500">
                  {`${formatNumber(quantity - hiddenCostQuantity)} ${unit}`}
                </Td>
              ) : null}
              <Td textAlign="end">{formatAsAmount(disbursementExVAT - hiddenCost)}</Td>
            </Tr>
            <Tr>
              <Td>
                <Box backgroundColor="pink.100" color="pink.700" px={2} py={1} borderRadius="lg" width="fit-content">
                  {t('quote:hiddenCost.title')}
                </Box>
              </Td>

              {quantity && unit ? (
                <Td textAlign="end" color="gray.500">
                  {`${formatNumber(hiddenCostQuantity)} ${unit}`}{' '}
                </Td>
              ) : null}
              <Td textAlign="end">{formatAsAmount(hiddenCost)}</Td>
            </Tr>
          </Tbody>
        </Table>
      }
    >
      <Box>
        <SimpleHelpIcon />
      </Box>
    </Tooltip>
  );
};
