import type { IStatement } from '@graneet/business-logic';
import type { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Tooltip } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { BILL_STATUSES_STYLE } from '../../bill/constants/bill.constant';

interface StatementBillIndicatorProps {
  statement: IStatement;
}
export const StatementBillIndicator: FC<StatementBillIndicatorProps> = ({ statement }) => {
  const { t } = useTranslation(['bill']);
  if (!statement.billStatus) {
    return null;
  }

  const color = `${BILL_STATUSES_STYLE[statement.billStatus].variantColor}.500`;

  return (
    <Tooltip label={t(`bill:indicator.${statement.billStatus}`)}>
      <Box color={color}>
        <i className="ri-money-euro-circle-fill ri-lg" />
      </Box>
    </Tooltip>
  );
};
