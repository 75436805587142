import type { UseToastOptions as UseToastOptionsFromChakra } from '@chakra-ui/react';
import { useToast as useToastFromChakra } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';

import { SimpleCheckCircleIcon } from '../../components/Icons/v2/SimpleCheckCircleIcon';
import { SimpleInfoCircleIcon } from '../../components/Icons/v2/SimpleInfoCircleIcon';
import { SimpleAlertIcon } from '../../components/Icons/v2/SimpleAlertIcon';

export type ToastStatus = UseToastOptionsFromChakra['status'];
export type ToastTitle = UseToastOptionsFromChakra['title'];
export type ToastDescription = UseToastOptionsFromChakra['description'];
export type ToastExtraConfig = Omit<UseToastOptionsFromChakra, 'status' | 'title' | 'description'>;
type ToastDisplayFn = (
  title?: ToastTitle,
  description?: ToastDescription,
  extraConfig?: ToastExtraConfig,
) => number | string;

const toastConfig = (
  status: ToastStatus,
  title: ToastTitle,
  description: ToastDescription,
  extraConfig?: ToastExtraConfig,
) => ({
  isClosable: true,
  status,
  title,
  description,
  id: status,
  ...(extraConfig || {}),
});

export const useToast = () => {
  const toast = useToastFromChakra();

  const toastUpdate = useCallback(
    (
      toastId: number | string,
      status: ToastStatus,
      title?: ToastTitle,
      description?: ToastDescription,
      extraConfig?: ToastExtraConfig,
    ) => {
      const { id, ...updatedConfig } = toastConfig(status, title, description, extraConfig);
      toast.update(toastId, updatedConfig);
      return toastId;
    },
    [toast],
  );

  const toastMessage = useCallback(
    (status: ToastStatus, title?: ToastTitle, description?: ToastDescription, extraConfig?: ToastExtraConfig) => {
      if (toast.isActive(status as string)) {
        return toastUpdate(status as string, status, title, description, extraConfig);
      }
      return toast(toastConfig(status, title, description, extraConfig));
    },
    [toast, toastUpdate],
  );

  const toastSuccess = useCallback<ToastDisplayFn>(
    (title, description, extraConfig) =>
      toastMessage('success', title, description, {
        variant: 'customSuccess',
        icon: <SimpleCheckCircleIcon />,
        isClosable: false,
        ...extraConfig,
      }),

    [toastMessage],
  );

  const toastInfo = useCallback<ToastDisplayFn>(
    (title, description, extraConfig) =>
      toastMessage('info', title, description, {
        variant: 'customInfo',
        icon: <SimpleInfoCircleIcon />,
        isClosable: false,
        ...extraConfig,
      }),

    [toastMessage],
  );

  const toastWarning = useCallback<ToastDisplayFn>(
    (title, description, extraConfig) =>
      toastMessage('warning', title, description, {
        variant: 'customWarning',
        icon: <SimpleInfoCircleIcon />,
        isClosable: false,
        ...extraConfig,
      }),
    [toastMessage],
  );

  const toastError = useCallback<ToastDisplayFn>(
    (title, description, extraConfig) =>
      toastMessage('error', title, description, {
        variant: 'customError',
        icon: <SimpleAlertIcon />,
        isClosable: false,
        ...extraConfig,
      }),

    [toastMessage],
  );

  return useMemo(
    () => ({
      success: toastSuccess,
      info: toastInfo,
      warning: toastWarning,
      error: toastError,
      update: toastUpdate,
      close: toast.close,
    }),
    [toast.close, toastError, toastInfo, toastSuccess, toastUpdate, toastWarning],
  );
};
