import type { KeysMatching } from './types/object.type';

const isObject = (potentialObject: unknown): potentialObject is Record<string, unknown> =>
  typeof potentialObject === 'object' && !Array.isArray(potentialObject) && potentialObject !== null;

export const forEachRecursively = <Value>(
  objectToFlatten: Record<string, Value>,
  cb: (value: unknown) => void,
): void => {
  Object.values(objectToFlatten).forEach((value) => {
    if (isObject(value)) {
      forEachRecursively(value as Record<string, Value>, cb);
    }
    cb(value);
  });
};

export const getItemsRelativelyOrderedByKey = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Item extends Record<string, any>,
  K extends KeysMatching<Item, { id: number | string } | undefined | null>,
>(
  items: Item[],
  key: K,
): Item[] => {
  const FIRST_ITEM_KEY = -1;

  const itemsByKey = items.reduce<Record<string, Item>>(
    (acc, subItem) => ({
      ...acc,
      [subItem[key] ? subItem[key].id : FIRST_ITEM_KEY]: subItem,
    }),
    {},
  );

  const sortedItems = [];

  let nextItemId = FIRST_ITEM_KEY;
  while (itemsByKey[nextItemId] !== undefined) {
    const nextItem = itemsByKey[nextItemId];
    sortedItems.push(nextItem);
    nextItemId = nextItem.id;
  }

  return sortedItems;
};

export const splitBiggestObjectAndOthers = <T, K extends keyof T>(
  objects: T[],
  attribute: K,
): {
  biggest: T | null;
  others: T[];
} =>
  objects.reduce<{ biggest: T | null; others: T[] }>(
    (acc, obj) => {
      const { biggest, others } = acc;

      if (!biggest) {
        return { biggest: obj, others };
      }

      if (obj[attribute] > biggest[attribute]) {
        others.push(biggest);
        return { biggest: obj, others };
      }

      others.push(obj);
      return acc;
    },
    { biggest: null, others: [] },
  );

export const isNil = (v: unknown): v is null | undefined => v === null || v === undefined;
