import type { Reducer } from 'react';
import { useCallback, useMemo, useReducer, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import type { AxiosError } from 'axios';

import { DATE_FORMAT_API } from '../../../utils';
import type { SORTING_DIRECTIONS } from '../constants';
import {
  ORDER_QUERY,
  DIRECTION_QUERY,
  LOAD_MORE_CALLBACK_DELAY,
  PAGE_QUERY,
  INCREMENTAL_QUERY,
  SEARCH_QUERY,
  OFFSET_QUERY,
  OFFSET_V1_QUERY,
  OFFSET_V2_QUERY,
} from '../constants';
import { DEBOUNCE_DELAY } from '../../../constants';
import type { Filters } from '../types/Filters';
import type { PaginatedResponse } from '../types';
import type { Response } from '../../../types';

import { useStatePaginationStorage } from './useStatePaginationStorage';
import { useUrlPaginationStorage } from './useUrlPaginationStorage';

interface PaginationReducerState<T extends object, Summable extends keyof T = never> {
  error?: AxiosError;
  loading: boolean;
  loadingMore: boolean;
  data: T[] | null;
  sums: Record<Summable, number>;
  hasMore: boolean;
  unfilteredCount?: number;
  totalCount?: number;
  offset?: number;
  offsetV1?: number;
  offsetV2?: number;
}

const INITIAL_STATE: PaginationReducerState<Record<any, any>, any> = {
  error: undefined,
  loading: true,
  loadingMore: false,
  data: null,
  sums: {},
  hasMore: false,
  offset: 0,
  offsetV1: 0,
  offsetV2: 0,
};

enum PAGINATION_ACTION_TYPES {
  FETCHED = 'FETCHED',
  FETCHED_INCREMENTAL = 'FETCHED_INCREMENTAL',
  FETCHING = 'FETCHING',
  FETCHING_INCREMENTAL = 'FETCHING_INCREMENTAL',
  ERROR = 'ERROR',
}

type PaginationAction<T extends Record<any, any>> =
  | { type: PAGINATION_ACTION_TYPES.FETCHING_INCREMENTAL }
  | { type: PAGINATION_ACTION_TYPES.ERROR; error: AxiosError }
  | ({ type: PAGINATION_ACTION_TYPES.FETCHING } & PaginationReducerState<T>)
  | ({ type: PAGINATION_ACTION_TYPES.FETCHED } & Omit<PaginationReducerState<T>, 'loading' | 'loadingMore'>)
  | ({ type: PAGINATION_ACTION_TYPES.FETCHED_INCREMENTAL } & Omit<
      PaginationReducerState<T>,
      'loading' | 'loadingMore'
    >);

const reducer = <T extends Record<any, any>, Summable extends keyof T = never>(
  state: PaginationReducerState<T, Summable>,
  { type, ...action }: PaginationAction<T>,
): PaginationReducerState<T, Summable> => {
  switch (type) {
    case PAGINATION_ACTION_TYPES.FETCHING:
      return {
        ...(action as PaginationReducerState<T, Summable>),
        loading: true,
        loadingMore: false,
      };
    case PAGINATION_ACTION_TYPES.FETCHED:
      return {
        ...state,
        ...(action as Omit<PaginationReducerState<T, Summable>, 'loading' | 'loadingMore'>),
        loading: false,
        loadingMore: false,
      };
    case PAGINATION_ACTION_TYPES.FETCHING_INCREMENTAL: {
      const { data } = state;
      return {
        ...state,
        data,
        loading: false,
        loadingMore: true,
      };
    }
    case PAGINATION_ACTION_TYPES.FETCHED_INCREMENTAL: {
      const { data: prevData } = state;
      const { data: newData } = action as Omit<PaginationReducerState<T>, 'loading' | 'loadingMore'>;

      return {
        ...state,
        ...(action as PaginationReducerState<T, Summable>),
        data: [...(prevData || []), ...(newData || [])],
        loading: false,
        loadingMore: false,
      };
    }
    case PAGINATION_ACTION_TYPES.ERROR: {
      const { error } = action as { error: AxiosError };

      return {
        ...state,
        error,
        loading: false,
        loadingMore: false,
      };
    }
    default:
      return state;
  }
};

export enum STORAGE_STRATEGY {
  QUERY_PARAMS = 'queryParams',
  STATE = 'state',
}

export interface PaginatedDataContextApi<T extends object, Summable extends keyof T = never>
  extends PaginationReducerState<T, Summable> {
  loadMore(): void;
  fetch(): Promise<void>;
  updateSorting(column: string, direction: SORTING_DIRECTIONS | null): Promise<void>;
  updateFilters(filters: Record<any, any>): Promise<void>;
  storage: URLSearchParams;
  searchState: { searchTerm: string; setSearchTerm(newValue: string): void };
}

export const usePaginatedData = <T extends { id?: string | number }, Summable extends keyof T = never>(
  getterFunc: (queryParams: URLSearchParams) => Promise<Response<PaginatedResponse<T, Summable>>>,
  forcedFilters: Filters = {},
  defaultFilters: Filters = {},
  storageStrategy = STORAGE_STRATEGY.QUERY_PARAMS,
): PaginatedDataContextApi<T, Summable> => {
  const usePaginatedStorage =
    storageStrategy === STORAGE_STRATEGY.QUERY_PARAMS ? useUrlPaginationStorage : useStatePaginationStorage;
  const { storage, forcePaginationRender } = usePaginatedStorage(defaultFilters);

  const [state, dispatch] = useReducer<Reducer<PaginationReducerState<T, Summable>, PaginationAction<T>>>(
    reducer,
    INITIAL_STATE,
  );

  const [searchTerm, setSearchTerm] = useState<string>(storage.get(SEARCH_QUERY) || '');
  const searchState = useMemo(
    () => ({
      searchTerm,
      setSearchTerm: (newValue: string) => {
        dispatch({
          type: PAGINATION_ACTION_TYPES.FETCHING,
          ...INITIAL_STATE,
        });

        setSearchTerm(newValue);
      },
    }),
    [searchTerm, setSearchTerm],
  );

  const loadMoreSleep = useRef<NodeJS.Timeout | null>(null);
  const lastSearchTerm = useRef<string | null>(null);
  const debouncedSearch = useRef<NodeJS.Timeout | null>(null);
  const throttling = useRef<NodeJS.Timeout | null>(null);

  const fetchApi = useCallback(
    async (
      queryParams: URLSearchParams,
      type: PAGINATION_ACTION_TYPES.FETCHED | PAGINATION_ACTION_TYPES.FETCHED_INCREMENTAL,
    ): Promise<void> => {
      if (throttling.current) {
        clearTimeout(throttling.current);
      }

      throttling.current = setTimeout(async () => {
        const querySent = new URLSearchParams(queryParams);

        Object.entries(forcedFilters).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((arrayValue, i) => {
              if (i === 0) {
                querySent.set(key, String(arrayValue));
              } else {
                querySent.append(key, String(arrayValue));
              }
            });
          } else {
            querySent.set(key, String(value));
          }
        });

        forcePaginationRender();
        const [error, response] = await getterFunc(querySent);

        if (error) {
          dispatch({ type: PAGINATION_ACTION_TYPES.ERROR, error });
          return;
        }

        // @ff quote-v2 : clean offsetV1 & V2

        const { metadata, data, sums } = response;
        const { hasMore, unfilteredCount, totalCount, offsetV1, offsetV2, offset } = metadata;

        throttling.current = null;
        dispatch({
          type,
          data,
          hasMore,
          unfilteredCount,
          error: undefined,
          sums,
          totalCount,
          offset,
          offsetV1,
          offsetV2,
        });
      }, LOAD_MORE_CALLBACK_DELAY);
    },
    [forcedFilters, getterFunc, forcePaginationRender],
  );

  const cancelLoadMore = useCallback(() => {
    if (loadMoreSleep.current) {
      clearTimeout(loadMoreSleep.current);
    }
    loadMoreSleep.current = null;
  }, [loadMoreSleep]);

  // this function fetches data from the api taking search params into account
  // former data will be replaced by the new one
  const fetch = useCallback(
    async (rawParams: URLSearchParams) => {
      cancelLoadMore();

      dispatch({
        type: PAGINATION_ACTION_TYPES.FETCHING,
        ...INITIAL_STATE,
      });

      await fetchApi(rawParams, PAGINATION_ACTION_TYPES.FETCHED);
    },
    [fetchApi, cancelLoadMore],
  );

  // this function fetches ADDITIONAL pages of data from the api, taking search params into account
  // new data will be APPENDED to the former data.
  const fetchMore = useCallback(() => {
    dispatch({
      type: PAGINATION_ACTION_TYPES.FETCHING_INCREMENTAL,
    });

    const loadMore = async () => {
      const newQueryWithIncremental = new URLSearchParams(storage);
      newQueryWithIncremental.set(INCREMENTAL_QUERY, 'true');

      await fetchApi(newQueryWithIncremental, PAGINATION_ACTION_TYPES.FETCHED_INCREMENTAL);
    };

    // we add a delay so that there is no flickering on the loader at the bottom of the table
    loadMoreSleep.current = setTimeout(loadMore, LOAD_MORE_CALLBACK_DELAY);
  }, [fetchApi, storage]);

  const loadMore = useCallback(async () => {
    // this is the update we make to the browser URL
    const { loadingMore, offset, offsetV1, offsetV2 } = state;

    if (!loadingMore && !throttling.current) {
      const rawCurrentPage = storage.get(PAGE_QUERY);
      const currentPage =
        rawCurrentPage && Number.parseInt(rawCurrentPage, 10) ? Number.parseInt(rawCurrentPage, 10) : 1;

      storage.set(PAGE_QUERY, String(currentPage + 1));

      if (offset) {
        storage.set(OFFSET_QUERY, String(offset));
      }

      if (offsetV1) {
        storage.set(OFFSET_V1_QUERY, String(offsetV1));
      }

      if (offsetV2) {
        storage.set(OFFSET_V2_QUERY, String(offsetV2));
      }

      await fetchMore();
    }
  }, [state, storage, fetchMore]);

  const updateSorting = useCallback(
    async (column: string, direction: SORTING_DIRECTIONS | null) => {
      storage.delete(PAGE_QUERY);

      if (direction === null) {
        storage.delete(ORDER_QUERY);
        storage.delete(DIRECTION_QUERY);
      } else {
        storage.set(ORDER_QUERY, column);
        storage.set(DIRECTION_QUERY, direction);
      }

      await fetch(storage);
    },
    [storage, fetch],
  );

  const onSearchUpdate = useCallback(async () => {
    storage.delete(PAGE_QUERY);

    if (searchTerm.length > 0) {
      storage.set(SEARCH_QUERY, searchTerm);
    } else {
      storage.delete(SEARCH_QUERY);
    }
    await fetch(storage);
  }, [fetch, storage, searchTerm]);

  const updateFilters = useCallback(
    async (filters: Record<any, any>) => {
      storage.delete(PAGE_QUERY);

      const clearQueryFilter = (filter: string) => {
        Array.from(storage.keys()).forEach((key) => {
          if (key.startsWith(filter)) {
            storage.delete(key);
          }
        });
      };

      const setArrayQuery = (filter: string, values: string[]) => {
        const isObjectArray = values.every((value) => typeof value === 'object');
        if (isObjectArray) {
          // We cannot replace old queryWithPaginationInfos params due to
          // indexes so we clear all filters parameters before
          clearQueryFilter(filter);
        }

        values.flat(1).forEach((value, i) => {
          // Nested object
          if (typeof value === 'object') {
            Object.keys(value).forEach((key) => {
              storage.set(`${filter}[${i}][${key}]`, value[key]);
            });

            return;
          }

          // String value
          if (i === 0) {
            storage.set(filter, value);
          } else {
            storage.append(filter, value);
          }
        });
      };

      const setNestedObjectQuery = (filter: string, values: Record<any, unknown>) => {
        Object.keys(values).forEach((key) => {
          const value =
            typeof values[key] === 'object' && values[key] instanceof Date
              ? // We know now that values[key] is a Date
                dayjs(values[key]).format(DATE_FORMAT_API) // Parse as simple date string
              : values[key];

          if (value) {
            storage.set(`${filter}[${key}]`, String(value));
          } else {
            storage.delete(`${filter}[${key}]`);
          }
        });
      };

      Object.entries(filters).forEach(([filter, values]) => {
        if (values && Array.isArray(values) && values.length > 0) {
          // Values array
          setArrayQuery(filter, values);
        } else if (typeof values === 'object' && !Array.isArray(values)) {
          // Nested object
          setNestedObjectQuery(filter, values);
        } else if (typeof values === 'string') {
          // String value
          storage.set(filter, values);
        } else {
          clearQueryFilter(filter);
        }
      });

      await fetch(storage);
    },
    [storage, fetch],
  );

  // Handle search
  useEffect(() => {
    if (lastSearchTerm.current !== null && lastSearchTerm.current !== searchTerm) {
      if (debouncedSearch.current) {
        clearTimeout(debouncedSearch.current);
      }
      debouncedSearch.current = setTimeout(onSearchUpdate, DEBOUNCE_DELAY);
    }
    lastSearchTerm.current = searchTerm;
  }, [onSearchUpdate, searchTerm]);

  const refetchData = useCallback(() => fetch(storage), [fetch, storage]);

  useEffect(() => {
    // Initial data
    fetch(storage);
    // Do not put 'fetch' in dependencies so to avoid cyclic update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage]);

  return useMemo(
    () => ({
      loadMore,
      fetch: refetchData,
      updateSorting,
      updateFilters,
      ...state,
      storage,
      searchState,
    }),
    [loadMore, refetchData, updateSorting, updateFilters, state, storage, searchState],
  );
};
