import type { FC } from 'react';
import { useMemo } from 'react';
import type { ISupplierInvoiceListingResponseDTO } from '@graneet/business-logic';
import { SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import { Link } from '@graneet/lib-ui';

import { SupplierInvoiceListing } from 'features/supplier-invoice/components/SupplierInvoiceListing/SupplierInvoiceListing';
import { useSupplierInvoices } from 'features/supplier-invoice/services/supplier-invoice.api';

const RowAction = ({ data }: { data: { id: number } | undefined }) => (
  <Link to={`../purchases/supplier-invoices/${data?.id}`} isExternal>
    <i className="ri-share-box-line" />
  </Link>
);

interface BankingTransactionAssociateSupplierInvoiceDrawerProps {
  id: string;

  onRowSelected: (selectedRows: ISupplierInvoiceListingResponseDTO[]) => void;
}

export const BankingTransactionAssociateSupplierInvoiceDrawer: FC<
  BankingTransactionAssociateSupplierInvoiceDrawerProps
> = ({ onRowSelected }) => {
  const supplierInvoices = useSupplierInvoices(
    useMemo(
      () => ({
        storageStrategy: 'state',
        forcedFilters: {
          status: [SUPPLIER_INVOICE_STATUS.TO_PAY, SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID],
        },
      }),
      [],
    ),
  );

  return (
    <SupplierInvoiceListing
      gridId="transaction-supplier-invoice"
      pagination={supplierInvoices}
      rowAction={RowAction}
      tableProps={{
        rowSelection: 'single',
        defaultColDef: {
          headerCheckboxSelection: false,
        },
        onRowSelected: (event) => {
          onRowSelected(event.api.getSelectedRows() ?? []);
        },
      }}
      customLayout={{
        invoiceNumber: {
          hide: false,
        },
        name: {
          hide: false,
        },
        billingDate: {
          hide: false,
        },
        supplier: {
          hide: false,
        },
        paymentDate: {
          hide: false,
        },
        amountExVAT: {
          hide: false,
        },
        status: {
          hide: false,
        },
        supplierInvoiceProjects: { hide: false },
        type: { hide: false },
      }}
    />
  );
};
