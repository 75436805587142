import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, useDisclosure } from '@chakra-ui/react';
import { formatDateOrEmpty, Card, Button, SimpleCirclePlusIcon } from '@graneet/lib-ui';
import type { IFile, IProject } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { ProjectAcceptanceReportCard } from '../ProjectAcceptanceReportCard';

import { ProjectAttachAcceptanceReportModal } from './ProjectAttachAcceptanceReportModal';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface ProjectAcceptanceReportCardWithEmptyStateProps {
  project: IProject;

  acceptanceReport?: IFile | null;
}

export const ProjectAcceptanceReportCardWithEmptyState: FC<ProjectAcceptanceReportCardWithEmptyStateProps> = ({
  project,
  acceptanceReport,
}) => {
  const { t } = useTranslation(['project']);

  const modal = useDisclosure();

  const createStatementsButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_STATEMENTS]);

  if (!acceptanceReport) {
    return (
      <Card title={t('project:acceptanceReport.title')}>
        <ProjectAttachAcceptanceReportModal project={project} isOpen={modal.isOpen} onClose={modal.onClose} />

        <Stack direction="column">
          <Trans
            t={t}
            i18nKey="project:summary.attachAcceptanceReport.empty.label"
            values={{
              date: formatDateOrEmpty(project.endDate),
            }}
          />

          <Button
            variant="ghost"
            leftIcon={<SimpleCirclePlusIcon stroke="greenBrand.light" />}
            onClick={modal.onOpen}
            {...createStatementsButtonDisabledProps()}
          >
            {t('project:summary.attachAcceptanceReport.empty.cta')}
          </Button>
        </Stack>
      </Card>
    );
  }

  return <ProjectAcceptanceReportCard acceptanceReport={acceptanceReport} variant="compact" />;
};
