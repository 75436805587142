import { useCallback } from 'react';
import type { Quote } from '@org/quotation-lib';
import { MARGIN_TYPE } from '@org/quotation-lib';
import Big from 'big.js';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { mapQuoteSubItemToUpdateTotalMargin } from 'features/quotation/quote-common/mappers/quoteSubItemMapper';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteSubItemUpdateTotalMargin() {
  const { quoteSubItemsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, newTotalMargin: string): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteSubItem = quote.getTree().getQuoteSubItemOrThrow(nodeId);
          const quoteNode = quoteSubItem.getNodeOrThrow();
          this.oldValue = quoteSubItem.getMargin();
          quoteSubItem.updateTotalMargin(new Big(newTotalMargin), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteSubItemsProxyApi
            .updateQuoteSubItem(
              quoteSubItem.getId(),
              mapQuoteSubItemToUpdateTotalMargin(quoteNode, quote.getId(), clientRequestId, timestamp),
            )
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteSubItem = quote.getTree().getQuoteSubItemOrThrow(nodeId);
          const quoteNode = quoteSubItem.getNodeOrThrow();
          quoteSubItem.updateTotalMargin(this.oldValue.getTotalMargin(), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteSubItemsProxyApi
            .updateQuoteSubItem(
              quoteSubItem.getId(),
              mapQuoteSubItemToUpdateTotalMargin(quoteNode, quote.getId(), clientRequestId, timestamp),
            )
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteSetClientRequestToStore, quoteSetToStore, quoteSubItemsProxyApi],
  );
}
