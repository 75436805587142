import type { DrawersStackContextApi } from '@graneet/lib-ui';
import {
  AmountSummary,
  ActionMenu,
  useCurrency,
  formatNumberToVatRate,
  DrawersStack,
  useDrawersStack,
  useDrawer,
} from '@graneet/lib-ui';
import type { ICustomDiscount } from '@graneet/business-logic';
import { CUSTOM_DISCOUNT_TYPES, getCustomDiscountAmountExVAT, isNumberFinite } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { useDeleteQuoteCustomDiscount } from '../hooks/useDeleteQuoteCustomDiscount';

import { QuoteCustomDiscountEditDrawer } from './modals/QuoteCustomDiscountEditDrawer';

import type { CustomDiscountEditionForm } from 'features/custom-discount/forms/custom-discount-edition-form';

interface QuoteTotalsCustomDiscountProps {
  quoteId: number | undefined;

  amountWithDiscountExVAT: number;

  customDiscount: ICustomDiscount;

  left?: boolean;

  noActions?: boolean;

  drawersStack?: DrawersStackContextApi<string>;
}

export const QuoteTotalsCustomDiscount: FC<QuoteTotalsCustomDiscountProps> = ({
  quoteId,
  amountWithDiscountExVAT,
  customDiscount,
  drawersStack,
  left = false,
  noActions = false,
}) => {
  const getDrawersStack = useDrawersStack();
  const drawersStackContext = drawersStack ?? getDrawersStack;
  const customDiscountDrawer = useDrawer('drawer-custom-discount', drawersStackContext);

  const discountAmountExVAT = getCustomDiscountAmountExVAT(customDiscount, amountWithDiscountExVAT);
  const onDeleteCustomDiscount = useDeleteQuoteCustomDiscount();
  const { mapNumberToAmount } = useCurrency();

  const customDiscountFormValues = useMemo<Partial<CustomDiscountEditionForm>>(
    () => ({
      customDiscountName: customDiscount.name,
      customDiscountVatRate: isNumberFinite(customDiscount.vatRate)
        ? formatNumberToVatRate(customDiscount.vatRate)
        : undefined,
      customDiscountType: customDiscount.type,
      customDiscountAmountExVAT:
        customDiscount.type === CUSTOM_DISCOUNT_TYPES.AMOUNT && isNumberFinite(customDiscount.amountExVAT)
          ? mapNumberToAmount(customDiscount.amountExVAT)
          : undefined,
      customDiscountPercentage: isNumberFinite(customDiscount.percentage) ? customDiscount.percentage : undefined,
    }),
    [customDiscount, mapNumberToAmount],
  );

  const onEditCustomDiscount = useCallback(() => {
    customDiscountDrawer.onOpen();
  }, [customDiscountDrawer]);

  if (!quoteId) return null;

  return (
    <AmountSummary.Item
      label={customDiscount.name}
      amount={discountAmountExVAT}
      sign
      light
      left={left}
      pl={0}
      rightContent={
        noActions ? null : (
          <>
            <ActionMenu placement="top-start">
              <ActionMenu.Edit onClick={onEditCustomDiscount} />
              <ActionMenu.Delete onClick={() => onDeleteCustomDiscount(quoteId, customDiscount.id)} />
            </ActionMenu>

            <DrawersStack drawersStack={drawersStackContext}>
              <QuoteCustomDiscountEditDrawer
                drawer={customDiscountDrawer}
                initialCustomDiscountValue={customDiscountFormValues}
                customDiscountId={customDiscount.id}
              />
            </DrawersStack>
          </>
        )
      }
    />
  );
};
