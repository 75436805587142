import { divideFloating, multiplyFloating } from '../common/math.util';

import { MARGIN_COMPUTED_VALUE } from './margin.type';

type MarginForTotalMarginComputation =
  | {
      computed: MARGIN_COMPUTED_VALUE.TOTAL;
      overheadCosts: number;
      profitMargin: number;
    }
  | {
      computed: MARGIN_COMPUTED_VALUE.PROFIT;
      totalMargin: number;
    };

export const getCalculatedTotalMargin = (margin: MarginForTotalMarginComputation): number =>
  margin.computed === MARGIN_COMPUTED_VALUE.TOTAL
    ? multiplyFloating(margin.overheadCosts, margin.profitMargin)
    : margin.totalMargin;

type MarginForProfitMarginComputation =
  | {
      computed: MARGIN_COMPUTED_VALUE.PROFIT;
      totalMargin: number;
      overheadCosts: number;
    }
  | {
      computed: MARGIN_COMPUTED_VALUE.TOTAL;
      profitMargin: number;
    };

export const getCalculatedProfitMargin = (margin: MarginForProfitMarginComputation): number =>
  margin.computed === MARGIN_COMPUTED_VALUE.PROFIT
    ? divideFloating(margin.totalMargin, margin.overheadCosts)
    : margin.profitMargin;
