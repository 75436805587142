type ExclusiveValues<U1, U2> = Exclude<U1, U2> | Exclude<U2, U1>;
type EnumValues<T> = T extends string ? `${T}` : never;
export type CompatibleUnions<U1, U2> = ExclusiveValues<U1, U2> extends never ? U1 : never;
export type CompatibleEnums<E1, E2> = CompatibleUnions<EnumValues<E1>, EnumValues<E2>> extends never ? never : E1;

// Use this function to safely convert an enum value to another enum value.
// This is only a temporary solution when we are using enums generated by openapi-generator.
export function safelyConvertEnum<From extends string, To extends string>(fromValue: CompatibleEnums<From, To>): To {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return fromValue as any as To;
}
