import type { FC, ForwardedRef } from 'react';
import type { IMask } from 'react-imask';
import { IMaskMixin } from 'react-imask';

import type { InputProps } from '../Input';
import { Input } from '../Input';

const IMaskInput = IMaskMixin(({ inputRef, ...props }) => (
  <Input {...props} ref={inputRef as ForwardedRef<HTMLInputElement> | undefined} />
));

export interface MaskedInputProps extends InputProps {
  mask: IMask.AnyMask;

  inputRef?(el: HTMLInputElement): void;
}

export const MaskedInput: FC<MaskedInputProps> = ({ onChange, value, mask, ...rest }) => {
  // value == null checks if value is null or undefined
  const stringifyValue = value == null ? '' : value.toString();
  return (
    <IMaskInput
      // mask is not included in typing of IMaskMixin
      // @ts-ignore
      mask={mask}
      onAccept={onChange}
      value={stringifyValue}
      unmask
      {...rest}
    />
  );
};
