import { multiplyFloating } from '@graneet/business-logic';
import type { UseCurrencyApi } from '@graneet/lib-ui';
import { divideFloating } from '@graneet/lib-ui';
import { isNil } from 'lodash-es';

import type { ContractEditionForm } from '../forms/contract-edition.form';
import { getItemFieldName, getLotFieldName } from '../forms/contract-edition.form';
import type { IContractInfosItemWithUUID, IContractInfosLotWithUUID } from '../types/contract.type';

export const generateFormValuesOfLot = (lot: IContractInfosLotWithUUID): Partial<ContractEditionForm> => {
  if (isNil(lot)) return {};

  const { id } = lot;

  return {
    [getLotFieldName(id, 'code')]: lot.code,
    [getLotFieldName(id, 'description')]: lot.description,
    [getLotFieldName(id, 'note')]: lot.note,
  } as any;
};

export const generateFormValuesOfItem = (
  item: IContractInfosItemWithUUID,
  currency: UseCurrencyApi['currency'],
): Partial<ContractEditionForm> => {
  const { id, unitPrice, vatRate, quantity, quantityFormula, code, description, unit, note } = item;

  const unitPriceValue = isNil(unitPrice)
    ? {}
    : {
        // Do not use mapAmountToNumber. We want to keep the precision of the amount
        [getItemFieldName(id, 'unitPrice')]: divideFloating(unitPrice, 10 ** currency.precision, 15),
      };

  const vatRateValue = isNil(vatRate) ? {} : { [getItemFieldName(id, 'vatRate')]: multiplyFloating(vatRate, 100) };

  const quantityValue = isNil(quantity)
    ? {}
    : {
        [getItemFieldName(id, 'quantity')]: {
          value: quantity,
          content: quantityFormula,
        },
      };

  return {
    [getItemFieldName(id, 'code')]: code,
    [getItemFieldName(id, 'description')]: description,
    [getItemFieldName(id, 'unit')]: unit,
    [getItemFieldName(id, 'note')]: note,
    ...quantityValue,
    ...vatRateValue,
    ...unitPriceValue,
  } as any;
};
