import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatDateOrEmpty,
  ListingLayout,
  PaginatedTable,
  type PaginatedTableProps,
  PriceAdvanced,
  Tooltip,
} from '@graneet/lib-ui';
import type { BankingPaymentResourcePaginatedResponse } from '@graneet/business-logic';
import { Flex, Text } from '@chakra-ui/react';
import type { GridApi, GridReadyEvent } from '@ag-grid-community/core';

import { BankingBankLogo } from 'features/banking/components/BankingBankLogo';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';
import { useBankingPaymentResources } from 'features/banking/services/banking-payment-resource.api';
import { useBankingConnections } from 'features/banking/services/banking-connection.api';
import { BankingPaymentResourceStatusBadge } from 'features/banking/components/BankingPaymentResourceStatusBadge';

const StatusCell = ({ data }: { data: BankingPaymentResourcePaginatedResponse['data'][number] | undefined }) =>
  data ? <BankingPaymentResourceStatusBadge bankingPaymentResource={data} /> : null;

const AmountCell = ({ data }: { data: BankingPaymentResourcePaginatedResponse['data'][number] | undefined }) =>
  data ? <PriceAdvanced amount={data.amount} /> : null;

export const ViewBankingPaymentResources = () => {
  const { t } = useTranslation(['global', 'banking']);

  const { updateHeaderTitle } = useHeaderContext();
  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.bankingPaymentResources'), []);
  }, [t, updateHeaderTitle]);

  const bankingPaymentResources = useBankingPaymentResources();
  const bankingConnections = useBankingConnections();

  const LabelCell = useCallback(
    ({ data }: { data: BankingPaymentResourcePaginatedResponse['data'][number] | undefined }) =>
      data ? (
        <Tooltip
          label={
            bankingConnections.data.find((bankingConnection) => bankingConnection.bankUuid === data.connectorUuid)?.name
          }
        >
          <Flex gap={2} alignItems="center">
            {data.connectorUuid ? <BankingBankLogo bankingConnection={{ bankUuid: data.connectorUuid }} /> : null}
            <Text lineHeight="1.25rem">{data.label}</Text>
          </Flex>
        </Tooltip>
      ) : null,
    [bankingConnections.data],
  );

  const columnDefs = useMemo<PaginatedTableProps<BankingPaymentResourcePaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'label',
        headerName: t('banking:bankingPaymentResource.fields.label'),
        cellRenderer: LabelCell,
        sortable: true,
      },
      {
        field: 'iban',
        headerName: t('banking:bankingPaymentResource.fields.iban'),
        sortable: true,
      },
      {
        field: 'registerDate',
        headerName: t('banking:bankingPaymentResource.fields.registerDate'),
        valueFormatter: (v) => formatDateOrEmpty(v.data?.registerDate),
        sortable: true,
      },
      {
        field: 'state',
        headerName: t('banking:bankingPaymentResource.fields.status'),
        cellRenderer: StatusCell,
        sortable: true,
      },
      {
        field: 'amount',
        headerName: t('banking:bankingPaymentResource.fields.amount'),
        cellRenderer: AmountCell,
        sortable: true,
      },
    ],
    [t, LabelCell],
  );

  const tableRef = useRef<null | GridApi>(null);
  const onGridReady = useCallback((event: GridReadyEvent) => {
    tableRef.current = event.api;
  }, []);

  return (
    <ListingLayout
      pagination={bankingPaymentResources}
      search={{
        placeholder: t('global:words.c.research'),
      }}
      filters={[
        {
          type: 'multi',
          label: t('banking:bankingPaymentResource.filters.bank.label'),
          placeholder: t('banking:bankingPaymentResource.filters.bank.placeholder'),
          name: 'connectorUuid',
          availableValues: bankingConnections.data
            .flatMap((bankingConnection) => bankingConnection.bankingAccounts)
            .map((bankingAccount) => ({
              value: bankingAccount.id,
              label: bankingAccount.name,
            })),
        },
        {
          type: 'amount',
          name: 'amount',
          label: t('banking:bankingPaymentResource.filters.amount.label'),
        },
        {
          type: 'date',
          name: 'registerDate',
          label: t('banking:bankingPaymentResource.filters.date.label'),
        },
      ]}
      content={
        <PaginatedTable
          gridId="banking-payment-resources"
          pagination={bankingPaymentResources}
          columnDefs={columnDefs}
          zeroState={{
            icon: <></>, // @[ff: banking-account-connection] TODO waiting product specs
            label: '',
          }}
          emptyState={{ label: t('banking:bankingTransaction.emptyStateLabel') }}
          onGridReady={onGridReady}
        />
      }
    />
  );
};
