import { GraneetButton, useHotkeys } from '@graneet/lib-ui';
import { t } from 'i18next';
import { useCallback } from 'react';

import { QUOTATION_SHORTCUTS } from 'features/quotation/quote-hot-key/hooks/useQuoteHotKeys';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useQuoteUpdate } from 'features/quotation/quote/hooks/useQuoteUpdate';
import { useStore } from 'store/store';

export const QuoteToolBarAutoIndexButton = () => {
  const { quote } = useQuote();
  const updateQuote = useQuoteUpdate();
  const isEditable = useStore((store) => store.quoteEditable);

  const handleToggleAutoIndex = useCallback(() => {
    if (!isEditable) return;
    updateQuote({ isAutomaticIndexActivated: !quote.getIsAutomaticIndexActivated() }, false);
  }, [quote, updateQuote, isEditable]);

  useHotkeys(
    [QUOTATION_SHORTCUTS.TOGGLE_AUTO_NUMBERING.shortCut],
    () => {
      handleToggleAutoIndex();
    },
    [handleToggleAutoIndex],
  );

  return (
    <GraneetButton leftIcon={<i className="ri-sort-number-asc" />} variant="ghost" onClick={handleToggleAutoIndex}>
      {t('quote:quotation.tooltips.autonumbering')}
    </GraneetButton>
  );
};
