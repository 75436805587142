import type { FC } from 'react';
import { useMemo } from 'react';
import { Card, Stack, Text } from '@chakra-ui/react';
import { keyBy } from 'lodash-es';
import type { AccountingAccountsNeeds, IClient, ISupplier, IVat } from '@graneet/business-logic';
import { DataTable, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import type {
  AccountingBankEditionBlockForm,
  AccountingJournalEditionBlockForm,
  AccountingPurchasesEditionBlockForm,
  AccountingSalesEditionBlockForm,
  AccountingVatEditionBlockForm,
} from '../forms/accounting.form';
import {
  getAccountingAccountPurchasesFieldName,
  getAccountingConfigBankAccountAccountFieldName,
  getAccountingConfigBankJournalFieldName,
  getAccountingVatFieldName,
} from '../forms/accounting.form';
import { useAccountingConfigs } from '../services/accounting-config.api';

import { AccountingJournalEditionBlock } from './blocks/AccountingJournalEditionBlock';
import { AccountingSalesEditionBlock } from './blocks/AccountingSalesEditionBlock/AccountingSalesEditionBlock';
import { AccountingClientEditionBlock } from './blocks/AccountingClientEditionBlock';
import { AccountingPurchasesEditionBlock } from './blocks/AccountingPurchasesEditionBlock/AccountingPurchasesEditionBlock';
import { AccountingSupplierEditionBlock } from './blocks/AccountingSupplierEditionBlock';
import { AccountingBankEditionBlock } from './blocks/AccountingBankEditionBlock';
import { AccountingVatEditionBlock } from './blocks/AccountingVatEditionBlock';

import { Rule } from 'features/form/rules/Rule';

export type AccountingMissingCodeFormValues = Partial<AccountingJournalEditionBlockForm> &
  Partial<AccountingSalesEditionBlockForm> &
  Partial<AccountingBankEditionBlockForm> &
  Partial<AccountingVatEditionBlockForm>;

interface AccountingMissingCodeFormForm {
  accountingAccountsNeeds: AccountingAccountsNeeds;
}

export const AccountingMissingCodeForm: FC<AccountingMissingCodeFormForm> = ({ accountingAccountsNeeds }) => {
  const accountingConfigsQuery = useAccountingConfigs();

  // -- Journal --
  const journalBlockRequiredFields = useMemo<Record<keyof AccountingJournalEditionBlockForm, boolean>>(
    () => ({
      journalSales: accountingAccountsNeeds.accountingConfig?.journalSales === false,
      journalPurchases: accountingAccountsNeeds.accountingConfig?.journalPurchases === false,
      journalBank: accountingAccountsNeeds.accountingConfig?.journalBank === false,
    }),
    [accountingAccountsNeeds],
  );
  const displayJournalBlock = Object.values(journalBlockRequiredFields).filter(Boolean).length > 0;

  // -- Sales --
  const salesBlockRequiredFields = useMemo(
    () => ({
      accountSalesDownPayment: accountingAccountsNeeds.accountingConfig?.accountSalesDownPayment === false,
      accountSalesHoldback: accountingAccountsNeeds.accountingConfig?.accountSalesHoldback === false,
      accountSalesPriceRevision: accountingAccountsNeeds.accountingConfig?.accountSalesPriceRevision === false,
      accountSalesDirectPayment: accountingAccountsNeeds.accountingConfig?.accountSalesDirectPayment === false,
      accountSalesWork: accountingAccountsNeeds.accountingConfig?.accountSalesWork === false,
      accountSalesWorkReversalOfLiability:
        accountingAccountsNeeds.accountingConfig?.accountSalesWorkReversalOfLiability === false,
      accountSalesOtherDeduction: accountingAccountsNeeds.accountingConfig?.accountSalesOtherDeduction === false,
      accountSalesProrataAccount: accountingAccountsNeeds.accountingConfig?.accountSalesProrataAccount === false,
      accountClient: accountingAccountsNeeds.accountingConfig?.accountClient === false,
    }),
    [accountingAccountsNeeds],
  );
  const workVats = useMemo(() => {
    const vatsKeyById = keyBy(accountingConfigsQuery.data?.vats, 'id');

    return accountingAccountsNeeds.vatList
      ? Object.entries(accountingAccountsNeeds.vatList).reduce<Pick<IVat, 'id' | 'name' | 'rate' | 'type'>[]>(
          (acc, [id, needs]) => {
            if (needs.accountWork === false) {
              acc.push({
                id,
                type: vatsKeyById[id].type,
                name: vatsKeyById[id].name,
                rate: vatsKeyById[id].rate,
              });
            }

            return acc;
          },
          [],
        )
      : [];
  }, [accountingAccountsNeeds.vatList, accountingConfigsQuery.data?.vats]);
  const displaySalesBlock = Object.values(salesBlockRequiredFields).filter(Boolean).length > 0 || workVats.length > 0;

  // -- Client --
  const clients = useMemo(() => {
    const clientsKeyByIds = keyBy(accountingConfigsQuery.data?.clients || [], (c) => c.id);

    return Object.entries(accountingAccountsNeeds.clients || {}).reduce<
      Pick<IClient, 'id' | 'code' | 'enterpriseName'>[]
    >((acc, [id, needs]) => {
      if (needs.auxiliaryAccount === false) {
        const client = clientsKeyByIds[id];
        acc.push({
          id: client.id,
          code: client.code,
          enterpriseName: client.enterpriseName,
        });
      }

      return acc;
    }, []);
  }, [accountingAccountsNeeds.clients, accountingConfigsQuery.data?.clients]);
  const displayClientBlock = clients.length > 0;

  // -- Purchases --
  // TODO @[ff: ACCOUNTING_BREAKDOWN_PURCHASES_ACCOUNT] add sales accounts to missing data
  const purchasesBlockRequiredFields = useMemo(() => {
    const init: Record<keyof AccountingPurchasesEditionBlockForm, boolean> = {
      accountSupplier: accountingAccountsNeeds.accountingConfig?.accountSupplier === false,
    };

    Object.entries(accountingAccountsNeeds.componentTypes || {}).forEach(([id, value]) => {
      const name = getAccountingAccountPurchasesFieldName(parseInt(id, 10));
      init[name] = value.accountPurchases === false;
    });

    Object.entries(accountingAccountsNeeds.accountingConfigPurchasesAccounts || {}).forEach(([id, value]) => {
      const name = getAccountingAccountPurchasesFieldName(id);
      init[name] = value.account === false;
    });

    return init;
  }, [accountingAccountsNeeds]);
  const displayPurchasesBlock = Object.values(purchasesBlockRequiredFields).filter(Boolean).length > 0;

  // -- Supplier --
  const suppliers = useMemo(() => {
    const suppliersKeyByIds = keyBy(accountingConfigsQuery.data?.suppliers || [], (c) => c.id);

    return Object.entries(accountingAccountsNeeds.suppliers || {}).reduce<Pick<ISupplier, 'id' | 'code' | 'name'>[]>(
      (acc, [id, needs]) => {
        if (needs.auxiliaryAccount === false) {
          const supplier = suppliersKeyByIds[id];
          acc.push({
            id: supplier.id,
            code: supplier.code,
            name: supplier.name,
          });
        }

        return acc;
      },
      [],
    );
  }, [accountingAccountsNeeds.suppliers, accountingConfigsQuery.data?.suppliers]);

  const displaySupplierBlock = suppliers.length > 0;

  // -- Bank --

  // TODO RM @[ff: accounting-payment-methods]
  const bankBlockRequiredFields = useMemo(
    () => ({
      accountBankSettlementWire: accountingAccountsNeeds.accountingConfig?.accountBankSettlementWire === false,
    }),
    [accountingAccountsNeeds],
  );

  const displayBankBlock = Object.values(bankBlockRequiredFields).filter(Boolean).length > 0;

  const accountingConfigBankRequiredFields = useMemo(
    () =>
      Object.entries(accountingAccountsNeeds.accountingConfigBank || {})
        .filter(([, isValid]) => !isValid)
        .map(([id]) => id),
    [accountingAccountsNeeds],
  );

  const displayAccountingConfigBankBlock = accountingConfigBankRequiredFields.length > 0;

  const accountingConfigBankAccountRequiredFields = useMemo(
    () =>
      Object.entries(accountingAccountsNeeds.accountingConfigBankAccount || {})
        .filter(([, isValid]) => !isValid)
        .map(([id]) => id),
    [accountingAccountsNeeds],
  );

  const displayAccountingConfigBankAccountBlock = accountingConfigBankAccountRequiredFields.length > 0;

  // -- VAT --
  const vatsData = useMemo(() => {
    const vatsKeyById = keyBy(accountingConfigsQuery.data?.vats, 'id');

    const vats: Pick<IVat, 'id' | 'type' | 'name' | 'rate'>[] = [];
    const mustDisplayFields: Record<keyof AccountingVatEditionBlockForm, boolean> = {};

    if (accountingAccountsNeeds.vatList) {
      Object.entries(accountingAccountsNeeds.vatList).forEach(([id, needs]) => {
        let mustDisplayVAT = false;
        if (needs.accountPurchases === false) {
          mustDisplayFields[getAccountingVatFieldName(id, 'accountPurchases')] = true;
          mustDisplayVAT = true;
        }
        if (needs.accountSales === false) {
          mustDisplayFields[getAccountingVatFieldName(id, 'accountSales')] = true;
          mustDisplayVAT = true;
        }
        if (needs.accountDownPayment === false) {
          mustDisplayFields[getAccountingVatFieldName(id, 'accountDownPayment')] = true;
          mustDisplayVAT = true;
        }
        if (needs.accountSalesCollected === false) {
          mustDisplayFields[getAccountingVatFieldName(id, 'accountSalesCollected')] = true;
          mustDisplayVAT = true;
        }
        if (needs.accountSalesPending === false) {
          mustDisplayFields[getAccountingVatFieldName(id, 'accountSalesPending')] = true;
          mustDisplayVAT = true;
        }
        if (needs.accountPurchasesDeductible === false) {
          mustDisplayFields[getAccountingVatFieldName(id, 'accountPurchasesDeductible')] = true;
          mustDisplayVAT = true;
        }
        if (needs.accountPurchasesPending === false) {
          mustDisplayFields[getAccountingVatFieldName(id, 'accountPurchasesPending')] = true;
          mustDisplayVAT = true;
        }

        if (mustDisplayVAT) {
          vats.push({
            id,
            type: vatsKeyById[id].type,
            name: vatsKeyById[id].name,
            rate: vatsKeyById[id].rate,
          });
        }
      });
    }

    return {
      vats,
      mustDisplayFields,
    };
  }, [accountingAccountsNeeds.vatList, accountingConfigsQuery.data?.vats]);

  const displayVatBlock = Object.values(vatsData.mustDisplayFields).filter(Boolean).length > 0;

  const { t } = useTranslation(['accounting', 'global']);

  return (
    <Stack bg="gray.100" p={3} mt={3} gap={6}>
      {displayJournalBlock && (
        <AccountingJournalEditionBlock mustDisplayFields={journalBlockRequiredFields} haveRequiredFields />
      )}

      {displaySalesBlock && (
        <AccountingSalesEditionBlock
          vats={workVats}
          mustDisplayFields={salesBlockRequiredFields}
          haveRequiredFields
          hideSplitSalesWorkAsBases
          hideSplitProrataWithVAT
          hideUseClientCodeWithHoldback
          hideSeparator
        />
      )}

      {displayAccountingConfigBankBlock && accountingConfigsQuery.data?.accountingConfigBanks && (
        <Card p={4}>
          <Text fontSize="lg" color="gray.800" mb={4}>
            {t('accounting:missingAccountingConfigBank', { count: accountingConfigBankRequiredFields.length })}
          </Text>
          <DataTable numberOfColumns={2}>
            {accountingConfigBankRequiredFields.map((id) => {
              const bank = accountingConfigsQuery.data?.accountingConfigBanks?.find((bk) => bk.id === id);
              return (
                <DataTable.Row key={id} label={bank?.label}>
                  <DataTable.Cell>
                    <TextField<any> name={getAccountingConfigBankJournalFieldName(id)}>
                      <Rule.IsRequired />
                    </TextField>
                  </DataTable.Cell>
                </DataTable.Row>
              );
            })}
          </DataTable>
        </Card>
      )}

      {displayAccountingConfigBankAccountBlock && accountingConfigsQuery.data?.accountingConfigBanks && (
        <Card p={4}>
          <Text fontSize="lg" color="gray.800" mb={4}>
            {t('accounting:missingAccountingConfigBankAccount', {
              count: accountingConfigBankAccountRequiredFields.length,
            })}
          </Text>
          <DataTable numberOfColumns={2}>
            {accountingConfigBankAccountRequiredFields.map((id) => {
              const bankAccount = (accountingConfigsQuery.data?.accountingConfigBanks ?? [])
                .map((bank) => bank.accountingConfigBankAccounts ?? [])
                .flat()
                .find((account) => account.id === id);
              return (
                <DataTable.Row key={id} label={bankAccount?.label}>
                  <DataTable.Cell>
                    <TextField<any> name={getAccountingConfigBankAccountAccountFieldName(id)}>
                      <Rule.IsRequired />
                    </TextField>
                  </DataTable.Cell>
                </DataTable.Row>
              );
            })}
          </DataTable>
        </Card>
      )}

      {displayClientBlock && <AccountingClientEditionBlock clients={clients} haveRequiredFields />}

      <>
        {displayPurchasesBlock && (
          <AccountingPurchasesEditionBlock
            mustDisplayFields={purchasesBlockRequiredFields}
            haveRequiredFields
            hideSeparator
            canOnlyUpdateAccounts
            purchasesAccounts={accountingConfigsQuery.data?.purchasesAccounts || []}
          />
        )}
      </>

      {displaySupplierBlock && <AccountingSupplierEditionBlock suppliers={suppliers} haveRequiredFields />}

      {displayBankBlock && (
        <AccountingBankEditionBlock mustDisplayFields={bankBlockRequiredFields} haveRequiredFields />
      )}

      {displayVatBlock && (
        <AccountingVatEditionBlock
          vats={vatsData.vats}
          mustDisplayFields={vatsData.mustDisplayFields}
          haveRequiredFields
        />
      )}
    </Stack>
  );
};
