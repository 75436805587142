import type { IRole, PERMISSION } from '@graneet/business-logic';
import { ADMIN_ROLE } from '@graneet/business-logic';

import type { RoleFieldName, RoleFormFields } from 'features/role/constants/role.constant';
import { CLIENT_TO_SERVER_USER_PERMISSIONS } from 'features/role/constants/role.constant';

export const isAdminRole = (role: IRole) => role.name === ADMIN_ROLE;

export const mapFormRoleValuesToServerPermissions = (formValues: RoleFormFields): PERMISSION[] =>
  Object.entries(formValues).reduce<PERMISSION[]>((acc, [formPermissionKey, formPermissionValue]) => {
    if (!formPermissionValue) {
      return acc;
    }

    let addedPermissions: PERMISSION[] = [];
    Object.entries(CLIENT_TO_SERVER_USER_PERMISSIONS).forEach(([frontPermission, serverPermissions]) => {
      if (formPermissionKey === frontPermission) {
        addedPermissions = [...serverPermissions];
      }
    });

    return [...acc, ...addedPermissions];
  }, []);

export const mapServerPermissionsToFormRoleValues = (serverPermissions: PERMISSION[]) => {
  let formRoleValues = {};

  (Object.keys(CLIENT_TO_SERVER_USER_PERMISSIONS) as RoleFieldName[]).forEach((fieldRoleName) => {
    const serverPermissionsEquivalent = CLIENT_TO_SERVER_USER_PERMISSIONS[fieldRoleName];

    const isPermissionSelected = serverPermissionsEquivalent.some((serverPermissionEquivalent) =>
      serverPermissions.includes(serverPermissionEquivalent),
    );

    formRoleValues = {
      ...formRoleValues,
      [fieldRoleName]: isPermissionSelected,
    };
  });

  return formRoleValues;
};
