import { useTheme } from '@chakra-ui/react';
import type { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Suspense, lazy, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import type { Field, RowHook, Result } from 'react-spreadsheet-import/types/types';

/*
Use lazy to load the library because it's a big bundle
This library doesn't export component as default, so we have to trick it
*/
const ReactSpreadsheetImport = lazy(() =>
  import('react-spreadsheet-import').then((module) => ({
    default: module.ReactSpreadsheetImport,
  })),
);

export type ImportSpreadsheetField<T extends string> = Field<T>;

export type ImportSpreadsheetRowHook<T extends string> = RowHook<T>;

export type ImportSpreadsheetSubmit<T extends string> = (data: Result<T>, file: File) => void;

interface SpreadsheetImportProps {
  onClose: () => void;

  onSubmit: ImportSpreadsheetSubmit<string>;

  fields: ImportSpreadsheetField<string>[];

  rowHook?: ImportSpreadsheetRowHook<string>;
}

export const SpreadsheetImport: FC<SpreadsheetImportProps> = ({ onClose, onSubmit, fields, rowHook }) => {
  const theme = useTheme();
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const onError = useCallback(() => {
    toast.error(t('global:errors.error'));
    onClose();
  }, [toast, t, onClose]);

  const translations = useMemo(
    () => ({
      uploadStep: {
        title: t('global:spreadsheetImport.uploadStep.title'),
        manifestTitle: null,
        manifestDescription: null,
        maxRecordsExceeded: (maxRecords: string) =>
          t('global:spreadsheetImport.uploadStep.maxRecordsExceeded', { maxRecords }),
        dropzone: {
          title: t('global:spreadsheetImport.uploadStep.dropzone.title'),
          errorToastDescription: t('global:spreadsheetImport.uploadStep.dropzone.errorToastDescription'),
          activeDropzoneTitle: t('global:spreadsheetImport.uploadStep.dropzone.activeDropzoneTitle'),
          buttonTitle: t('global:spreadsheetImport.uploadStep.dropzone.buttonTitle'),
          loadingTitle: t('global:spreadsheetImport.uploadStep.dropzone.loadingTitle'),
        },
        selectSheet: {
          title: t('global:spreadsheetImport.uploadStep.selectSheet.title'),
          nextButtonTitle: t('global:spreadsheetImport.uploadStep.selectSheet.nextButtonTitle'),
          backButtonTitle: t('global:spreadsheetImport.uploadStep.selectSheet.backButtonTitle'),
        },
      },
      selectHeaderStep: {
        title: t('global:spreadsheetImport.selectHeaderStep.title'),
        nextButtonTitle: t('global:spreadsheetImport.selectHeaderStep.nextButtonTitle'),
        backButtonTitle: t('global:spreadsheetImport.selectHeaderStep.backButtonTitle'),
      },
      matchColumnsStep: {
        title: t('global:spreadsheetImport.matchColumnsStep.title'),
        nextButtonTitle: t('global:spreadsheetImport.matchColumnsStep.nextButtonTitle'),
        backButtonTitle: t('global:spreadsheetImport.matchColumnsStep.backButtonTitle'),
        userTableTitle: t('global:spreadsheetImport.matchColumnsStep.userTableTitle'),
        templateTitle: t('global:spreadsheetImport.matchColumnsStep.templateTitle'),
        selectPlaceholder: t('global:spreadsheetImport.matchColumnsStep.selectPlaceholder'),
        ignoredColumnText: t('global:spreadsheetImport.matchColumnsStep.ignoredColumnText'),
        subSelectPlaceholder: t('global:spreadsheetImport.matchColumnsStep.subSelectPlaceholder'),
        matchDropdownTitle: t('global:spreadsheetImport.matchColumnsStep.matchDropdownTitle'),
        unmatched: t('global:spreadsheetImport.matchColumnsStep.unmatched'),
        duplicateColumnWarningTitle: t('global:spreadsheetImport.matchColumnsStep.duplicateColumnWarningTitle'),
        duplicateColumnWarningDescription: t(
          'global:spreadsheetImport.matchColumnsStep.duplicateColumnWarningDescription',
        ),
      },
      validationStep: {
        title: t('global:spreadsheetImport.validationStep.title'),
        nextButtonTitle: t('global:spreadsheetImport.validationStep.nextButtonTitle'),
        backButtonTitle: t('global:spreadsheetImport.validationStep.backButtonTitle'),
        noRowsMessage: t('global:spreadsheetImport.validationStep.noRowsMessage'),
        noRowsMessageWhenFiltered: t('global:spreadsheetImport.validationStep.noRowsMessageWhenFiltered'),
        discardButtonTitle: t('global:spreadsheetImport.validationStep.discardButtonTitle'),
        filterSwitchTitle: t('global:spreadsheetImport.validationStep.filterSwitchTitle'),
      },
      alerts: {
        confirmClose: {
          headerTitle: t('global:spreadsheetImport.alerts.confirmClose.headerTitle'),
          bodyText: t('global:spreadsheetImport.alerts.confirmClose.bodyText'),
          cancelButtonTitle: t('global:spreadsheetImport.alerts.confirmClose.cancelButtonTitle'),
          exitButtonTitle: t('global:spreadsheetImport.alerts.confirmClose.exitButtonTitle'),
        },
        submitIncomplete: {
          headerTitle: t('global:spreadsheetImport.alerts.submitIncomplete.headerTitle'),
          bodyText: t('global:spreadsheetImport.alerts.submitIncomplete.bodyText'),
          bodyTextSubmitForbidden: t('global:spreadsheetImport.alerts.submitIncomplete.bodyTextSubmitForbidden'),
          cancelButtonTitle: t('global:spreadsheetImport.alerts.submitIncomplete.cancelButtonTitle'),
          finishButtonTitle: t('global:spreadsheetImport.alerts.submitIncomplete.finishButtonTitle'),
        },
        submitError: {
          title: t('global:spreadsheetImport.alerts.submitError.title'),
          defaultMessage: t('global:spreadsheetImport.alerts.submitError.defaultMessage'),
        },
        unmatchedRequiredFields: {
          headerTitle: t('global:spreadsheetImport.alerts.unmatchedRequiredFields.headerTitle'),
          bodyText: t('global:spreadsheetImport.alerts.unmatchedRequiredFields.bodyText'),
          listTitle: t('global:spreadsheetImport.alerts.unmatchedRequiredFields.listTitle'),
          cancelButtonTitle: t('global:spreadsheetImport.alerts.unmatchedRequiredFields.cancelButtonTitle'),
          continueButtonTitle: t('global:spreadsheetImport.alerts.unmatchedRequiredFields.continueButtonTitle'),
        },
        toast: {
          error: t('global:spreadsheetImport.alerts.toast.error'),
        },
      },
    }),
    [t],
  );

  return (
    <ErrorBoundary onError={onError} fallback={null}>
      <Suspense fallback={null}>
        <ReactSpreadsheetImport
          isOpen
          isNavigationEnabled
          allowInvalidSubmit={false}
          onSubmit={onSubmit}
          onClose={onClose}
          rowHook={rowHook}
          customTheme={theme}
          fields={fields}
          translations={translations}
          autoMapHeaders
          autoMapSelectValues
        />
      </Suspense>
    </ErrorBoundary>
  );
};
