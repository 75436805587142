import { useMemo } from 'react';

import { QuoteCustomDiscountsProxyApi } from '../proxies/quoteCustomDiscountsProxyApi';
import { QuoteProxyApi } from '../proxies/quoteProxyApi';
import { QuoteSalesDiscountProxyApi } from '../proxies/quoteSalesDiscountsProxyApi';
import { QuoteLotsProxyApi } from '../proxies/quoteLotsProxyApi';
import { QuoteBasicItemsProxyApi } from '../proxies/quoteBasicItemsProxyApi';
import { QuoteHiddenCostItemsProxyApi } from '../proxies/quoteHiddenCostItemsProxyApi';
import { QuoteComponentsProxyApi } from '../proxies/quoteComponentsProxyApi';
import { QuoteOptionalItemsProxyApi } from '../proxies/quoteOptionalItemsProxyApi';
import { QuoteSubItemsProxyApi } from '../proxies/quoteSubItemsProxyApi';
import { QuoteNodeMovesProxyApi } from '../proxies/quoteNodeMovesProxyApi';
import { QuoteNodeDeletesProxyApi } from '../proxies/quoteNodeDeletesProxyApi';
import { QuoteAddressProxyApi } from '../proxies/quoteAddressProxyApi';
import { QuoteNodeBulkActionsProxyApi } from '../proxies/quoteNodeBulkActions';
import { QuoteContactsProxyApi } from '../proxies/quoteContactsProxyApi';
import { QuoteFilesProxyApi } from '../proxies/quoteFilesProxyApi';
import { QuoteLibraryProxyApi } from '../proxies/quoteLibraryProxyApi';
import { QuoteExportsProxyApi } from '../proxies/quoteExportProxyApi';
import { PusherProxyApi } from '../proxies/quotePusherProxyApi';
import { QuoteImportsProxyApi } from '../proxies/quoteImportsProxyApi';
import { MessagingProxyApi } from '../proxies/messagingProxyApi';
import { QuoteAutoCompleteProxyApi } from '../proxies/quoteAutoCompleteProxyApi';
import { QuoteNodesProxyApi } from '../proxies/quoteNodesProxyApi';
import { QuoteSettingProxyApi } from '../proxies/quoteSettingProxyApi';

import { useAppContext } from 'features/app/contexts/AppContext';

interface UseQuotationProxyApis {
  messagingProxyApi: MessagingProxyApi;
  pusherProxyApi: PusherProxyApi;
  quoteAddressProxyApi: QuoteAddressProxyApi;
  quoteAutoCompleteProxyApi: QuoteAutoCompleteProxyApi;
  quoteBasicItemsProxyApi: QuoteBasicItemsProxyApi;
  quoteComponentsProxyApi: QuoteComponentsProxyApi;
  quoteContactsProxyApi: QuoteContactsProxyApi;
  quoteCustomDiscountsProxyApi: QuoteCustomDiscountsProxyApi;
  quoteExportsProxyApi: QuoteExportsProxyApi;
  quoteFilesProxyApi: QuoteFilesProxyApi;
  quoteHiddenCostItemsProxyApi: QuoteHiddenCostItemsProxyApi;
  quoteImportsProxyApi: QuoteImportsProxyApi;
  quoteLibraryProxyApi: QuoteLibraryProxyApi;
  quoteLotsProxyApi: QuoteLotsProxyApi;
  quoteNodeBulkActionsProxyApi: QuoteNodeBulkActionsProxyApi;
  quoteNodeDeletesProxyApi: QuoteNodeDeletesProxyApi;
  quoteNodeMovesProxyApi: QuoteNodeMovesProxyApi;
  quoteNodeProxyApi: QuoteNodesProxyApi;
  quoteOptionalItemsProxyApi: QuoteOptionalItemsProxyApi;
  quoteProxyApi: QuoteProxyApi;
  quoteSalesDiscountsProxyApi: QuoteSalesDiscountProxyApi;
  quoteSubItemsProxyApi: QuoteSubItemsProxyApi;
  quoteSettingProxyApi: QuoteSettingProxyApi;
}

export function useQuotationProxyApis(): UseQuotationProxyApis {
  const { externalApi } = useAppContext();
  if (!externalApi) throw new Error("Can't call external api");
  return useMemo(
    () => ({
      messagingProxyApi: MessagingProxyApi.getMessagingProxyApi(externalApi),
      pusherProxyApi: PusherProxyApi.getPusherProxyApi(externalApi),
      quoteAddressProxyApi: QuoteAddressProxyApi.getQuoteAddressProxyApi(externalApi),
      quoteAutoCompleteProxyApi: QuoteAutoCompleteProxyApi.getQuoteSubItemsProxyApi(externalApi),
      quoteBasicItemsProxyApi: QuoteBasicItemsProxyApi.getQuoteBasicItemsProxyApi(externalApi),
      quoteComponentsProxyApi: QuoteComponentsProxyApi.getQuoteComponentsProxyApi(externalApi),
      quoteContactsProxyApi: QuoteContactsProxyApi.getQuoteContactsProxyApi(externalApi),
      quoteCustomDiscountsProxyApi: QuoteCustomDiscountsProxyApi.getQuoteCustomDiscountProxyApi(externalApi),
      quoteExportsProxyApi: QuoteExportsProxyApi.getQuoteExportsProxyApi(externalApi),
      quoteFilesProxyApi: QuoteFilesProxyApi.getQuoteFilesProxyApi(externalApi),
      quoteHiddenCostItemsProxyApi: QuoteHiddenCostItemsProxyApi.getQuoteHiddenCostItemsProxyApi(externalApi),
      quoteImportsProxyApi: QuoteImportsProxyApi.getQuoteImportsProxyApi(externalApi),
      quoteLibraryProxyApi: QuoteLibraryProxyApi.getQuoteLibraryProxyApi(externalApi),
      quoteLotsProxyApi: QuoteLotsProxyApi.getQuoteLotsProxyApi(externalApi),
      quoteNodeBulkActionsProxyApi: QuoteNodeBulkActionsProxyApi.getQuoteNodeBulkActionProxyApi(externalApi),
      quoteNodeDeletesProxyApi: QuoteNodeDeletesProxyApi.getQuoteNodeDeletesProxyApi(externalApi),
      quoteNodeMovesProxyApi: QuoteNodeMovesProxyApi.getQuoteNodeMovesProxyApi(externalApi),
      quoteNodeProxyApi: QuoteNodesProxyApi.getQuoteNodesProxyApi(externalApi),
      quoteOptionalItemsProxyApi: QuoteOptionalItemsProxyApi.getQuoteOptionalItemsProxyApi(externalApi),
      quoteProxyApi: QuoteProxyApi.getQuoteProxyApi(externalApi),
      quoteSalesDiscountsProxyApi: QuoteSalesDiscountProxyApi.getQuoteSalesDiscountProxyApi(externalApi),
      quoteSubItemsProxyApi: QuoteSubItemsProxyApi.getQuoteSubItemsProxyApi(externalApi),
      quoteSettingProxyApi: QuoteSettingProxyApi.getQuoteSettingProxyApi(externalApi),
    }),
    [externalApi],
  );
}
