import type { FC } from 'react';
import { useEffect } from 'react';
import type { ButtonProps } from '@chakra-ui/react';

import { BUTTON_TYPE } from './useModalButtons';
import { useModalButtonsContext } from './ModalButtonsContext';

export const ModalCloseButton: FC<ButtonProps> = (props) => {
  const { registerButton, unRegisterButton } = useModalButtonsContext();

  useEffect(() => {
    registerButton(BUTTON_TYPE.CLOSE, props);
    return () => unRegisterButton(BUTTON_TYPE.CLOSE, props);
  }, [registerButton, unRegisterButton, props]);

  return null;
};
