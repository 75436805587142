import { useReward } from 'react-rewards';
import { useCallback } from 'react';

import { sleep } from '../../utils';

export const useConfetti = () => {
  const { reward: leftReward } = useReward('leftConfetti', 'confetti', {
    angle: 30,
    spread: 60,
    lifetime: 2000,
    elementCount: 100,
    elementSize: 12,
  });
  const { reward: rightReward } = useReward('rightConfetti', 'confetti', {
    angle: 150,
    spread: 60,
    lifetime: 2000,
    elementCount: 100,
    elementSize: 12,
  });

  return useCallback(async () => {
    sleep(1000).then(async () => {
      leftReward();
      rightReward();

      await sleep(500);

      leftReward();
      rightReward();
    });

    // Avoid laggy behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
