import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const BillSituation: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 110 131" {...props}>
    <svg width="110" height="131" viewBox="0 0 110 131" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3005_79975)">
        <path d="M109.754 0V130.952H0.245117V30.4618L0.250233 30.4567L30.3105 0H109.754Z" fill="#B0E2FF" />
        <path d="M109.754 0H55V130.952H109.754V0Z" fill="#53B9F6" />
        <path d="M30.3189 0V30.4567H0.250977L30.3112 0H30.3189Z" fill="#004E7A" />
        <path d="M82.7505 28.709H46.2168V36.382H82.7505V28.709Z" fill="#0D8CD9" />
        <path d="M82.7513 46.5234H27.25V54.1964H82.7513V46.5234Z" fill="#0D8CD9" />
        <path
          d="M42.1919 90.6592C36.1709 90.6592 31.2725 85.7607 31.2725 79.7397C31.2725 73.7187 36.1709 68.8203 42.1919 68.8203C48.2126 68.8203 53.1111 73.7187 53.1111 79.7397C53.1111 85.7607 48.2126 90.6592 42.1919 90.6592ZM42.1919 76.4933C40.4018 76.4933 38.9455 77.9496 38.9455 79.7397C38.9455 81.5298 40.4018 82.9862 42.1919 82.9862C43.982 82.9862 45.4381 81.5298 45.4381 79.7397C45.4381 77.9496 43.9818 76.4933 42.1919 76.4933Z"
          fill="#334E68"
        />
        <path
          d="M69.648 118.169C63.627 118.169 58.7285 113.27 58.7285 107.249C58.7285 101.228 63.627 96.3301 69.648 96.3301C75.669 96.3301 80.5671 101.229 80.5671 107.249C80.5674 113.27 75.669 118.169 69.648 118.169ZM69.648 104.003C67.8578 104.003 66.4015 105.459 66.4015 107.249C66.4015 109.039 67.8578 110.496 69.648 110.496C71.4381 110.496 72.8941 109.039 72.8941 107.249C72.8944 105.459 71.4383 104.003 69.648 104.003Z"
          fill="#002F4C"
        />
        <path d="M79.4437 75.5836L37.8229 117.204L32.3955 111.78L74.0189 70.1562L79.4437 75.5836Z" fill="#334E68" />
        <path d="M74.0188 70.1562L79.4436 75.5836L55 100.027V89.175L74.0188 70.1562Z" fill="#002F4C" />
        <path d="M82.7506 28.709H55V36.382H82.7506V28.709Z" fill="#004E7A" />
        <path d="M82.7506 46.5234H55V54.1964H82.7506V46.5234Z" fill="#004E7A" />
      </g>
      <defs>
        <clipPath id="clip0_3005_79975">
          <rect width="110" height="130.952" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);
