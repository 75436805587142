import type { FieldValues } from 'graneet-form';
import { Field } from 'graneet-form';
import type { ReactNode } from 'react';

import { BadgeGroup } from '../../BadgeGroup';
import type { KeysMatching } from '../../../utils';

type BadgeGroupFieldValue = unknown | undefined | null;

export interface BadgeGroupFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, BadgeGroupFieldValue> = KeysMatching<T, BadgeGroupFieldValue>,
> {
  name: K;

  children: ReactNode;

  rules?: ReactNode;
}
const BadgeGroupFieldComponent = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, BadgeGroupFieldValue> = KeysMatching<T, BadgeGroupFieldValue>,
>({
  name,
  children,
  rules,
  ...otherProps
}: BadgeGroupFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    render={(properties) => (
      <BadgeGroup<T>
        onChange={properties.onChange as any}
        onBlur={properties.onBlur}
        onFocus={properties.onFocus}
        value={properties.value}
        {...otherProps}
      >
        {children}
      </BadgeGroup>
    )}
  >
    {rules}
  </Field>
);

export const BadgeGroupField = Object.assign(BadgeGroupFieldComponent, {
  Option: BadgeGroup.Option,
});
