import type { FC } from 'react';
import { useCallback, useLayoutEffect, useState } from 'react';
import { Button, SuccessIcon, useConfetti } from '@graneet/lib-ui';
import { Center, Flex, Stack, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

import { getEnvValue } from '../../../config/env';

interface EditOnboardingCongratulationScreenProps {
  isUserLoggedFromAnExistingAccount: boolean;
}

export const EditOnboardingCongratulationScreen: FC<EditOnboardingCongratulationScreenProps> = ({
  isUserLoggedFromAnExistingAccount,
}) => {
  const fireConfetti = useConfetti();

  const history = useHistory();

  const { logout } = useAuth0();

  const { t } = useTranslation(['onboarding']);

  useLayoutEffect(() => {
    fireConfetti();
  }, [fireConfetti]);

  const [isLoading, setIsLoading] = useState(false);
  const onDiscover = useCallback(async () => {
    setIsLoading(true);
    if (isUserLoggedFromAnExistingAccount) {
      history.push('/');
    } else {
      await logout({ logoutParams: { returnTo: getEnvValue('REACT_APP_AUTH0_RETURN_TO', true) } });
    }
    setIsLoading(false);
  }, [history, isUserLoggedFromAnExistingAccount, logout]);

  return (
    <>
      <Flex h="100vh" w="30rem" bg="backgroundLight" alignItems="end">
        <Center w="100%">
          <SuccessIcon boxSize="15rem" />
        </Center>
      </Flex>

      <Stack justifyContent="center" alignItems="center" spacing={6} w="100vw" h="100vh">
        <Text fontSize="5xl" fontWeight="600" justifyContent="center" textAlign="center">
          <Trans t={t} i18nKey="onboarding:creationCongratulations" />
        </Text>

        <Button onClick={onDiscover} isLoading={isLoading}>
          {t('onboarding:discoverGraneet')}
        </Button>
      </Stack>
    </>
  );
};
