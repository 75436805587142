import {
  Badge,
  Callout,
  CheckboxField,
  IconAdvanced,
  ModalSubtitle,
  SimpleAlertIcon,
  SimpleHelpIcon,
  StarsIcon,
  SwitchField,
  Tooltip,
} from '@graneet/lib-ui';
import { HStack, ListItem, Stack, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { FEATURE_FLAGS, SUPPORT_EMAIL } from '@graneet/business-logic';
import { useCallback } from 'react';

import type { RoleFormFields } from '../../constants/role.constant';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

const Header = () => {
  const { t } = useTranslation(['user']);

  const form = useFormContext<RoleFormFields>();
  const onChange = useCallback(
    (newValue: boolean) => {
      form.setFormValues({
        createStatements: newValue,
        updateStatusStatements: newValue,
        payBillsAndPayHoldbacks: newValue,
        forceDraftStatements: newValue,
      });
    },
    [form],
  );

  const hasUserPermissionsFeatureFlag = useFeatureFlag(FEATURE_FLAGS.USER_PERMISSIONS);

  if (!hasUserPermissionsFeatureFlag) {
    return <Text mb={2}>{t('user:editRoleModal.sections.billing')}</Text>;
  }

  return (
    <SwitchField<RoleFormFields>
      name="accessStatements"
      onChange={onChange as any}
      label={
        <HStack spacing={2}>
          <Text>{t('user:permissions.ACCESS_STATEMENTS')}</Text>
          <IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('user:role.ACCESS_STATEMENTS.tooltip')} />
        </HStack>
      }
      checkedValue
      uncheckedValue={false}
    />
  );
};

export const RoleEditModalStatement = () => {
  const { t } = useTranslation(['user', 'global']);

  const form = useFormContext<RoleFormFields>();
  const {
    forceDraftStatements: isForceDraftStatementsEnabled,
    createStatements: isCreateStatementsEnabled,
    updateStatusStatements: isUpdateStatusStatementsEnabled,
    accessStatements,
  } = useOnChangeValues(form, [
    'forceDraftStatements',
    'createStatements',
    'updateStatusStatements',
    'accessStatements',
  ]);

  const hasUserPermissionsFeatureFlag = useFeatureFlag(FEATURE_FLAGS.USER_PERMISSIONS);

  const isForceDraftDisabled =
    hasUserPermissionsFeatureFlag && (!isCreateStatementsEnabled || !isUpdateStatusStatementsEnabled);

  const canEditSubRole = hasUserPermissionsFeatureFlag ? !!accessStatements : true;

  const isForceDraftStatementsActive = !(isForceDraftDisabled || !canEditSubRole);

  // Force "forceDraftStatements" to be false when "createStatements" or "accessStatements" is disabled
  if (isForceDraftStatementsEnabled === true && !isForceDraftStatementsActive) {
    form.setFormValues({
      forceDraftStatements: false,
    });
  }

  return (
    <Stack direction="column" gap={0}>
      <ModalSubtitle mb={0}>
        <Header />
      </ModalSubtitle>

      <Stack direction="column" gap={1} pl={3}>
        {hasUserPermissionsFeatureFlag && (
          <>
            <CheckboxField<RoleFormFields>
              name="createStatements"
              label={t('user:permissions.CREATE_STATEMENTS')}
              isDisabled={!canEditSubRole}
            />

            <CheckboxField<RoleFormFields>
              name="updateStatusStatements"
              label={
                <HStack spacing={2}>
                  <Text>{t('user:permissions.UPDATE_STATUS_STATEMENTS')}</Text>
                  <IconAdvanced
                    icon={<SimpleHelpIcon boxSize={4} />}
                    tooltip={t('user:role.UPDATE_STATUS_STATEMENTS.tooltip')}
                  />
                </HStack>
              }
              isDisabled={!canEditSubRole}
            />

            <CheckboxField<RoleFormFields>
              name="payBillsAndPayHoldbacks"
              label={t('user:permissions.PAY_BILLS_AND_PAY_HOLDBACKS')}
              isDisabled={!canEditSubRole}
            />
          </>
        )}

        {/* Force draft is a permission available on all plans */}
        <Tooltip
          shouldWrapChildren
          label={
            <>
              <Text>{t('user:role.FORCE_DRAFT_STATEMENTS.disabled')}</Text>
              <UnorderedList ml={8}>
                <ListItem>{t('user:permissions.CREATE_STATEMENTS')} </ListItem>
                <ListItem>{t('user:permissions.UPDATE_STATUS_STATEMENTS')}</ListItem>
              </UnorderedList>
            </>
          }
          isDisabled={!isForceDraftDisabled}
        >
          <CheckboxField<RoleFormFields>
            name="forceDraftStatements"
            label={
              <HStack spacing={2}>
                <Text>{t('user:permissions.FORCE_DRAFT_STATEMENTS')}</Text>
                <IconAdvanced
                  icon={<SimpleHelpIcon boxSize={4} />}
                  tooltip={t('user:role.FORCE_DRAFT_STATEMENTS.tooltip')}
                />
              </HStack>
            }
            isDisabled={!isForceDraftStatementsActive}
          />
        </Tooltip>

        <VStack>
          {isForceDraftStatementsEnabled && (
            <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" />} mt={1}>
              {t('user:role.FORCE_DRAFT_STATEMENTS.callout')}
            </Callout>
          )}
          {!hasUserPermissionsFeatureFlag && (
            <Callout
              icon={
                <Badge w="5rem" colorScheme="white" px={0}>
                  <StarsIcon boxSize={5} /> {t('global:planType.advanced')}
                </Badge>
              }
            >
              {t('user:role.FORCE_DRAFT_STATEMENTS.withoutUserPermission', { email: SUPPORT_EMAIL })}
            </Callout>
          )}
        </VStack>
      </Stack>
    </Stack>
  );
};
