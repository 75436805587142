import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Modal, ValuesProvider, useValuesContext, usePaginatedData, STORAGE_STRATEGY } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';

import { ContactAvailableDataProviderForm } from '../forms/ContactAvailableDataProviderForm';

import { getContactAssociatedToClientPaginated } from 'features/contact/services/contact.api';

interface RawContactAvailableModalProps {
  isOpen: boolean;

  onClose(): void;

  onSuccess(): void;

  title: string;

  clientId: number;

  onContactAssociated: (contacts: IContact[]) => void;

  contactsSelected: IContact[];
}

export const RawContactAvailableModal: FC<RawContactAvailableModalProps> = ({
  isOpen,
  onClose,
  title,
  onSuccess,
  clientId,
  onContactAssociated,
  contactsSelected,
}) => {
  const { t } = useTranslation(['contacts']);
  const data = usePaginatedData(getContactAssociatedToClientPaginated, { clientId }, undefined, STORAGE_STRATEGY.STATE);
  const { data: contactsAssociateToClient } = data;

  const { resetValues, getValues, numberOfValues } = useValuesContext<string>();
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const resetAll = useCallback(() => {
    setIsAllChecked(false);
    resetValues();
  }, [resetValues]);

  const handleOnClose = useCallback(() => {
    resetAll();
    onSuccess();
    onClose();
  }, [onClose, onSuccess, resetAll]);

  const handleSubmit = useCallback(async () => {
    const idsToAssociate = getValues();
    const contactsToAdd: IContact[] = [];
    idsToAssociate.forEach((id) => {
      const contactToAdd = contactsAssociateToClient?.find((contact) => contact.id === id);
      if (contactToAdd) {
        contactsToAdd.push(contactToAdd);
      }
    });
    onContactAssociated(contactsToAdd);
    handleOnClose();
  }, [contactsAssociateToClient, getValues, handleOnClose, onContactAssociated]);

  useEffect(() => {
    data.fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, clientId]);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} title={title} size="3xl" scrollBehavior="inside" maxHeight="33rem">
      <ContactAvailableDataProviderForm
        data={data}
        isAllChecked={isAllChecked}
        setIsAllChecked={setIsAllChecked}
        alreadySelectedContacts={contactsSelected}
        isContactDefaultEmailRecipient={() => false}
      />
      <Modal.Close />
      <Modal.PrimaryButton isDisabled={numberOfValues() === 0} onClick={handleSubmit}>
        {numberOfValues() <= 1 && t('contacts:availableModal.buttons.associate')}
        {numberOfValues() > 1 && t('contacts:availableModal.buttons.associateMulti')}
      </Modal.PrimaryButton>
    </Modal>
  );
};

export const ContactAvailableModal: FC<RawContactAvailableModalProps> = (props) => (
  <ValuesProvider>
    <RawContactAvailableModal {...props} />
  </ValuesProvider>
);
