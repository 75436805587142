import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import type { ModalProps } from '@graneet/lib-ui';
import { formatNumberToVatRate, formatVatRateToNumber, Modal, PercentageField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { useContractTreeContext } from '../../hooks/tree.hooks';
import { useContractDefaultVatRateContext } from '../../contexts/ContractDefaultVatRate';

import { Rule } from 'features/form/rules/Rule';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';

interface ContractLotVatRateModalProps extends Omit<ModalProps, 'id' | 'children'> {
  id: string | number;

  type: 'contract' | 'lot';
}

interface FormValues {
  vatRate: number;
}

export const ContractLotVatRateModal: FC<ContractLotVatRateModalProps> = ({ id, type, isOpen, onClose, ...props }) => {
  const { t } = useTranslation(['contracts', 'global']);
  const tradKey = type === 'contract' ? 'editContractVATRateModal' : 'editLotVATRateModal';

  const form = useForm<FormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  const { updateAllChildrenLeavesOfNode } = useContractTreeContext();
  const { getDefaultVATRate, setDefaultVATRate } = useContractDefaultVatRateContext();

  const onVATChanged = useCallback(() => {
    const formattedVatRate = formatVatRateToNumber(form.getFormValues().vatRate || 0);
    setDefaultVATRate(id, formattedVatRate);
    updateAllChildrenLeavesOfNode(id, { vatRate: formattedVatRate });
    onClose();
  }, [form, id, onClose, setDefaultVATRate, updateAllChildrenLeavesOfNode]);

  useEffect(() => {
    form.setFormValues({
      vatRate: formatNumberToVatRate(getDefaultVATRate(id)),
    });

    // do not remove `isOpen`, we need to refresh data on open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, getDefaultVATRate, id, isOpen]);

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  if (!isOpen) {
    return null;
  }

  return (
    <Form form={form}>
      <Modal title={t(`contracts:${tradKey}.title`)} isOpen={isOpen} onClose={onClose} {...props}>
        <Stack spacing={4}>
          <Text>{t(`contracts:${tradKey}.description`)}</Text>

          <HStack spacing={4}>
            <Text>{t(`contracts:${tradKey}.label`)}</Text>

            <Box w="10rem">
              {hasStandardVATRates && (
                <AccountingVATField<FormValues> name="vatRate" valueScale={100}>
                  <Rule.IsRequired />
                </AccountingVATField>
              )}

              {!hasStandardVATRates && (
                <PercentageField<FormValues> name="vatRate" max={100}>
                  <Rule.IsRequired />
                </PercentageField>
              )}
            </Box>
          </HStack>
        </Stack>

        <Modal.Close />

        <Modal.PrimaryButton onClick={onVATChanged} isDisabled={!isFormValid}>
          {t('global:words.c.apply')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
