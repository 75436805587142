import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type { PropsWithChildren } from 'react';
import { memo } from 'react';

export const DragAndDrop = memo(({ children }: PropsWithChildren) => (
  <DndProvider backend={HTML5Backend}>{children}</DndProvider>
));

DragAndDrop.displayName = 'DragAndDrop';
