import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IQuoteSellsheetComponentMarginDTO } from '@graneet/business-logic';

import { previewComponentTypeMarginSellSheet } from '../services/quote-sellsheet.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useQuoteSellSheetComponentTypePreview = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (quoteId: number, componentTypeId: number, dto: IQuoteSellsheetComponentMarginDTO) => {
      if (!dto) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        return;
      }

      const [err, result] = await previewComponentTypeMarginSellSheet(quoteId, componentTypeId, dto);

      if (err) {
        toast.error(t('quote:errors.updatingError'));
        return;
      }

      updateDataLocally(result);
    },
    [toast, t, updateDataLocally],
  );
};
