import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Modal, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';

import * as quoteApi from '../../services/quote.api';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

interface ConfirmQuoteAsLostModalProps {
  modalControls: UseDisclosureReturn;
  onMarkedAsLost(): void;
  quoteId: number;
}

export const ConfirmQuoteAsLostModal: FC<ConfirmQuoteAsLostModalProps> = ({
  modalControls,
  onMarkedAsLost,
  quoteId,
}) => {
  const { t } = useTranslation(['global', 'quote']);
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptQuote = useCallback(async () => {
    setIsLoading(true);
    const [markAsLostError] = await quoteApi.updateQuoteStatusToLost(quoteId);
    if (markAsLostError) {
      toast.error(t('global:words.c.error'), t('quote:markAsLostModal.error', { email: SUPPORT_EMAIL }));
    } else {
      toast.success(t('global:words.c.success'), t('quote:markAsLostModal.success'));
      modalControls.onClose();
      onMarkedAsLost();
    }
    setIsLoading(false);
  }, [quoteId, toast, t, onMarkedAsLost, modalControls]);

  return (
    <Modal {...modalControls} title={t('quote:markAsLostModal.title')}>
      <Modal.SecondaryButton onClick={modalControls.onClose}>{t('global:words.c.cancel')}</Modal.SecondaryButton>

      <Modal.PrimaryButton colorScheme="red" onClick={handleAcceptQuote} isLoading={isLoading}>
        {t('quote:actions.markAsLost')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
