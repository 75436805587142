import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { Box } from '@chakra-ui/react';

import type { EditSupplierInvoiceForm } from '../../../forms/edit-supplier-invoice.form';

import { SupplierInvoiceProjectsAssociationRow } from './SupplierInvoiceProjectsAssociationRow';

export const SupplierInvoiceProjectsAssociationTable: FC = () => {
  const { t } = useTranslation(['supplierInvoices', 'project']);

  const form = useFormContext<EditSupplierInvoiceForm>();
  const { associatedProjects } = useOnChangeValues(form, ['associatedProjects']);

  if (!associatedProjects?.length) {
    return null;
  }

  return (
    <Box w="100%" borderRadius="6px" boxShadow="subtle" overflow="hidden">
      <Table templateColumns={['2rem', '1fr', '4rem', '6rem', '7rem', '10rem', '3rem']} noCard>
        <Table.Header>
          <Table.Cell />
          <Table.Cell>{t('project:fields.name')}</Table.Cell>
          <Table.Cell>{t('project:fields.code')}</Table.Cell>
          <Table.Cell>{t('project:fields.client')}</Table.Cell>
          <Table.Cell>{t('supplierInvoices:cards.associatedProjectCard.distribution')}</Table.Cell>
          <Table.Cell>{t('supplierInvoices:cards.associatedProjectCard.projectAmountExVAT')}</Table.Cell>
          <Table.Cell right />
        </Table.Header>

        {associatedProjects.map((associatedProject) => (
          <SupplierInvoiceProjectsAssociationRow key={associatedProject.id} project={associatedProject} />
        ))}
      </Table>
    </Box>
  );
};
