import type {
  GeneratorConfig,
  GeneratorConfigObject,
  GeneratorData,
  GeneratorInterface,
  LEDGER_GENERATORS,
} from '../ledger.type';

export interface GeneratorAttributeConfig extends GeneratorConfig<LEDGER_GENERATORS.ATTRIBUTE> {
  type: LEDGER_GENERATORS.ATTRIBUTE;
  path: string;
}

export class GeneratorAttribute implements GeneratorInterface {
  private readonly path: string;

  constructor(config: GeneratorAttributeConfig) {
    const { path } = config;
    this.path = path;
  }

  public static isConfigValid(config: GeneratorConfigObject, block: string): boolean {
    const { path } = config[block] as GeneratorAttributeConfig;
    return typeof path === 'string' && !!path.match(/^\w+(\.\w+)*$/);
  }

  public isStateValid(): boolean {
    return true;
  }

  public generateValue(data: GeneratorData): [string, null, null] {
    const path = this.path.split('.');
    // field can be a key of GeneratorData or a key of nested object of GeneratorData. It's not type-able with TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const leafData = path.reduce((subData, field) => subData?.[field], data);
    const value = leafData ?? '';
    return [value.toString(), null, null];
  }
}
