import { Quote, QuoteUnflattenObject } from '@org/quotation-lib';

import { useStore } from 'store/store';

export function quoteGetFromStore() {
  const {
    quote,
    quoteSalesDiscount,
    quoteTree,
    quoteNode: quoteNodeObjectFlat,
    quoteNodeHiddenCost,
    quoteCustomDiscounts,
    quoteComponentTypes,
  } = useStore.getState();

  if (!quote || !quoteTree || !quoteNodeObjectFlat) {
    return null;
  }

  const quoteNode = Array.from([...quoteNodeObjectFlat.values(), ...(quoteNodeHiddenCost?.values() || [])]);
  const quoteObject = QuoteUnflattenObject.execute({
    quote,
    quoteNode,
    quoteTree,
    quoteCustomDiscount: [...(quoteCustomDiscounts?.values() || [])],
    quoteSalesDiscount,
  });

  return new Quote(
    { isAutomaticIndexActivated: quoteObject.isAutomaticIndexActivated, componentTypes: quoteComponentTypes },
    quoteObject,
  );
}
