import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { t } from 'i18next';

import type { HoldbackActionsType } from './holdback.actions.type';

export function getHoldbackOnGoingActions(params: Omit<HoldbackActionsType, 'holdback'>): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  if (params.hasAccountingStandards) {
    actions.push({
      type: 'primary',
      label: t('holdback:toBeReceived'),
      onClick: params.onMarkToBeReceived,
    });
  }

  return actions;
}
