import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { getEnvValue } from 'config/env';

export const StripeContext = createContext<Stripe | null>(null);

export const useStripeContext = () => useContext(StripeContext);

export const StripeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    const publicKey = getEnvValue('REACT_APP_STRIPE_PUBLIC_KEY', false);
    if (!publicKey) {
      console.warn('STRIPE_PUBLIC_KEY is not provided, Stripe is not set-up on your environment.');
      return;
    }

    loadStripe(publicKey).then((stripeResponse) => {
      setStripe(stripeResponse);
    });
  }, []);

  return <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>;
};
