import type { FC } from 'react';
import { Grid, GridItem, Stack } from '@chakra-ui/react';
import type {
  IContactWithProjectInfos,
  ICreditWithRelations,
  IGroupedContact,
  IProject,
  RequiredByKeys,
} from '@graneet/business-logic';
import { getEmailVariableData, HISTORY_ENTITY_TYPE, STATEMENT_TYPES } from '@graneet/business-logic';
import { TwoColumns } from '@graneet/lib-ui';

import { CreditTagsCard } from './cards/CreditTagsCard';
import { CreditStatusCard } from './cards/CreditStatusCard';
import { CreditLinkedStatementCard } from './cards/CreditLinkedStatementCard';
import { CreditInformationCard } from './cards/CreditInformationCard';

import { ClientCard } from 'features/client/components/cards/ClientCard';
import { useEmailTemplateRichTextConfiguration } from 'features/email-template/hooks/useEmailTemplateRichTextConfiguration';
import { HistoryCard } from 'features/history/components/HistoryCard';
import { EmailProvider } from 'features/history/contexts/EmailContext';
import { PaymentMethodDetailCard } from 'features/payment-method/components/PaymentMethodDetailCard';
import { ProjectAssociationCard } from 'features/project/components/cards/ProjectAssociationCard';

interface CreditDetailProps {
  credit: ICreditWithRelations;
  project: RequiredByKeys<IProject, 'address'> | null;
  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
  displayAssociatedProject?: boolean;
}

export const CreditDetail: FC<CreditDetailProps> = ({
  credit,
  project,
  projectContacts,
  displayAssociatedProject = false,
}) => {
  const emailTemplateRichTextConfiguration = useEmailTemplateRichTextConfiguration(
    getEmailVariableData('credit', {
      credit,
      client: credit.client,
      project,
    }),
  );

  return (
    <EmailProvider>
      <TwoColumns>
        <Grid templateColumns={{ base: '1fr' }} gap={4}>
          <CreditStatusCard credit={credit} project={project} projectContacts={projectContacts} />
          {credit.invoice && (
            <CreditLinkedStatementCard
              statement={credit.invoice}
              statementType={STATEMENT_TYPES.INVOICE}
              projectId={project?.id}
            />
          )}
          {credit.downPayment && (
            <CreditLinkedStatementCard
              statement={credit.downPayment}
              statementType={STATEMENT_TYPES.DOWN_PAYMENT}
              projectId={project?.id}
            />
          )}
          {credit.progressStatement && !credit.progressStatement.isDirect && (
            <CreditLinkedStatementCard
              statement={credit.progressStatement!}
              statementType={STATEMENT_TYPES.PROGRESS_STATEMENT}
              projectId={project?.id}
            />
          )}
          {credit.progressStatement && credit.progressStatement.isDirect && (
            <CreditLinkedStatementCard
              statement={credit.progressStatement}
              statementType={STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT}
              projectId={project?.id}
            />
          )}
        </Grid>

        <CreditInformationCard credit={credit} />

        <GridItem colSpan={2}>
          <HistoryCard
            id={credit.id}
            entity={HISTORY_ENTITY_TYPE.CREDIT}
            emailTemplateRichTextConfiguration={emailTemplateRichTextConfiguration}
          />
        </GridItem>

        <Stack spacing={6}>
          <ClientCard client={credit.client} type={null} />
          <CreditTagsCard credit={credit} />
        </Stack>
        <Stack spacing={4}>
          <PaymentMethodDetailCard
            paymentMethod={credit.paymentMethod}
            paymentTerm={credit.paymentTerm}
            deadlineType={credit.deadlineType}
          />
          {displayAssociatedProject && project && (
            <ProjectAssociationCard project={project} subProject={credit.subProject} />
          )}
        </Stack>
      </TwoColumns>
    </EmailProvider>
  );
};
