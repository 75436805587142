import { Box, Collapse, useDisclosure } from '@chakra-ui/react';
import type { CalloutColorScheme } from '@graneet/lib-ui';
import { Button, Callout, CALLOUT_COLORS, SimpleAlertIcon, SimpleChevronBottomIcon, Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC, ReactNode } from 'react';
import { useCallback } from 'react';

type EntityType = 'quote' | 'project' | 'invoice' | 'credit';

const getUrlPath = (entityType: EntityType, id: number) => {
  switch (entityType) {
    case 'quote':
      return `/opportunities/quotes/${id}`;
    case 'project':
      return `/projects/${id}`;
    case 'invoice':
      return `/sales/statements/invoices/${id}`;
    case 'credit':
      return `/sales/statements/credits/${id}`;
    default:
      return '/';
  }
};

interface ClientEntitiesImpactedCalloutProps {
  entities: { type: EntityType; entity: { id: number; name: string } }[];
  children: ReactNode;
  colorScheme: CalloutColorScheme;
}

export const ClientEntitiesImpactedCallout: FC<ClientEntitiesImpactedCalloutProps> = ({
  entities,
  colorScheme,
  children,
}) => {
  const { t } = useTranslation(['clients']);

  const { isOpen, onToggle } = useDisclosure();

  const handleOnClick = useCallback(
    (entityItem: { type: EntityType; entity: { id: number } }) => () => {
      window.open(getUrlPath(entityItem.type, entityItem.entity.id), '_blank', 'noopener,noreferrer');
    },
    [],
  );

  const color = CALLOUT_COLORS[colorScheme];

  return (
    <Callout colorScheme={colorScheme} icon={<SimpleAlertIcon boxSize={5} />} w="100%">
      {children}

      <Box w="100%">
        <Button
          rightIcon={isOpen ? <SimpleChevronBottomIcon transform="rotate(-180deg)" /> : <SimpleChevronBottomIcon />}
          variant="outline"
          onClick={onToggle}
          ml={-4}
          color={color.text}
          _hover={{ color: color.text, bg: color.hover }}
        >
          {isOpen ? t('clients:cards.delete.closeCollapse') : t('clients:cards.delete.openCollapse')}
        </Button>

        <Collapse in={isOpen} animateOpacity>
          <Box mt={2} w="100%">
            <Table templateColumns={['15rem', 'minmax(10rem, 30rem)']}>
              {(entities ?? []).map((entityItem) => (
                <Table.Row key={`${entityItem.type}-${entityItem.entity.id}`} onClick={handleOnClick(entityItem)}>
                  <Table.Cell>{t(`clients:cards.delete.entityTypes.${entityItem.type}`)}</Table.Cell>
                  <Table.Cell>{entityItem.entity.name}</Table.Cell>
                </Table.Row>
              ))}
            </Table>
          </Box>
        </Collapse>
      </Box>
    </Callout>
  );
};
