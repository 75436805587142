import type { ReactNode } from 'react';
import type { FieldValues } from 'graneet-form';
import { Field } from 'graneet-form';

import type { KeysMatching } from '../../../utils';

import { configureDefaultLabels } from './config/configureDefaultLabel';
import type { DroppableFileProps } from './components/DroppableFile';
import { DroppableFile } from './components/DroppableFile';

export type DroppableFileFieldValue = File | undefined | null;

export interface DroppableFileFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, DroppableFileFieldValue> = KeysMatching<T, DroppableFileFieldValue>,
> extends Omit<DroppableFileProps, 'onChange'> {
  name: K;

  children?: ReactNode;

  onFileChange?: (newFile?: File) => void;
}

const DroppableFileFieldComponent = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, DroppableFileFieldValue> = KeysMatching<T, DroppableFileFieldValue>,
>({
  name,
  children,
  onFileChange,
  ...otherProps
}: DroppableFileFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    render={({ value: file, onChange }) => (
      <DroppableFile file={file} {...otherProps} onChange={onChange} onFileChange={onFileChange} />
    )}
  >
    {children}
  </Field>
);

export const DroppableFileField = Object.assign(DroppableFileFieldComponent, {
  configureDefaultLabels,
});
