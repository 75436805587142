import { useEffect, useState } from 'react';
import { NavigationModal } from '@graneet/lib-ui';
import { object, number, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Flex } from '@chakra-ui/react';

import { QuoteJobEditView } from './QuoteJobEditView';
import { QuoteJobImportLibraryComponentView } from './QuoteJobImportLibraryComponentView';

import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';
import { useJob } from 'features/quote-job/hooks/useJob';
import { useComponentContext } from 'features/quote-component/contexts/component.context';
import { ComponentContextProvider } from 'features/quote-component/contexts/component-provider.context';
import { QueryWrapper } from 'features/api/components/QueryWrapper';

const HeaderQuoteJobEditionView = ({ isHiddenCost }) => {
  const { t } = useTranslation(['quote']);

  const [isUpdating, setIsUpdating] = useState();
  const { listenIsComponentsUpdating } = useComponentContext();

  useEffect(() => listenIsComponentsUpdating(setIsUpdating), [listenIsComponentsUpdating]);

  return (
    <Flex alignItems="end" gap="3">
      {t('quote:jobEdition.title')}
      {isHiddenCost ? (
        <Box backgroundColor="pink.100" color="pink.700" paddingY={1} paddingX={4} borderRadius={8} fontSize="0.8rem">
          {t('quote:hiddenCost.title')}
        </Box>
      ) : null}
      {isUpdating && (
        <Box flex={1} alignItems="center" textAlign="right" fontWeight="400" fontSize="md">
          <CircularProgress mr={3} size={5} isIndeterminate color="gray.800" />
          {t('global:form.saving')}
        </Box>
      )}
    </Flex>
  );
};

HeaderQuoteJobEditionView.propTypes = {
  isHiddenCost: bool.isRequired,
};

export const JobEditionModal = ({ modalControls, jobId }) => {
  const { t } = useTranslation(['library']);

  const { isReadOnlyView } = useQuoteDisplayContext();
  const { onClose, isOpen } = modalControls;

  const job = useJob(jobId);

  // TODO: Once we have only one modal in the whole screen, remove this conditional rendering
  if (!isOpen) {
    return null;
  }

  return (
    <ComponentContextProvider jobId={jobId}>
      <NavigationModal
        {...modalControls}
        size="6xl"
        isCentered={isReadOnlyView}
        fullHeight={!isReadOnlyView}
        scrollBehavior="inside"
      >
        <NavigationModal.View title={<HeaderQuoteJobEditionView isHiddenCost={job.isHiddenCost} />}>
          <Box pt={6}>
            <QueryWrapper>
              <QuoteJobEditView jobId={jobId} onClose={onClose} />
            </QueryWrapper>
          </Box>
        </NavigationModal.View>

        <NavigationModal.View path="/import" title={t('library:importLibraryComponentToQuoteJobModal.title')}>
          <QuoteJobImportLibraryComponentView jobId={jobId} />
        </NavigationModal.View>
      </NavigationModal>
    </ComponentContextProvider>
  );
};

JobEditionModal.propTypes = {
  modalControls: object.isRequired,
  jobId: number,
};

JobEditionModal.defaultProps = {
  jobId: undefined,
};
