import { Flex, GridItem } from '@chakra-ui/react';

import { SimpleCirclePlusIcon } from '../../../../Icons';
import { useTimeTableContext } from '../../contexts';
import type { ITimeTableRange } from '../../types';

interface TimeTableSlotPlaceholderProps {
  colStart: number;
  rowStart: number;
  range?: ITimeTableRange;
  date: Date;
  disabled?: boolean;
  id: string;
  subEntityId?: number;
  isOff?: boolean;
}

export const TimeTableSlotPlaceholder = ({
  colStart,
  rowStart,
  range,
  date,
  id,
  subEntityId,
  isOff = false,
  disabled = false,
}: TimeTableSlotPlaceholderProps) => {
  const { onCreate } = useTimeTableContext();

  return (
    <GridItem
      mb={2}
      role="group"
      gridRowStart={rowStart}
      gridColumnStart={colStart}
      gridRowEnd="span 1"
      bg="white"
      // Hack sometime 100% does not fill the full parent displaying a gray line
      h="110%"
    >
      <Flex
        h="100%"
        maxH={range ? 50 : undefined}
        w="100%"
        alignItems="center"
        justifyContent="center"
        _groupHover={!disabled ? { bg: 'gray.100' } : {}}
        cursor={!disabled ? 'pointer' : 'default'}
        onClick={!disabled ? () => onCreate(id, subEntityId, isOff, date, range) : undefined}
      >
        {!disabled && <SimpleCirclePlusIcon boxSize={5} stroke="white" _groupHover={{ stroke: 'gray.500' }} />}
      </Flex>
    </GridItem>
  );
};
