import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

/**
 * State based hook for saving and restoring filters from queryParams while navigating
 */
export const useFiltersQuery = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { search, state } = useLocation();
  const stateRef = useRef<Record<string, any>>(state || {});

  // Save initial search query and redirect
  // From: [Listing Page] (with filters) -> To: [Detail Page] (filters in state)
  const createRedirectionWithFilters = useCallback(
    (pathname: string, additionalState = {} as Record<string, any>) =>
      () => {
        const { replace = false, ...otherState } = additionalState;
        const routeState = { ...otherState, search };
        if (replace) history.replace(pathname, routeState);
        else history.push(pathname, routeState);
      },
    [history, search],
  );

  // Get transition state path for goto links
  // Keep the first url saved
  // [...] -> From: [Detail page] (filters in state) -> To: [Detail Page] (filters in state) -> [...]
  const getTransitionPath = useCallback(
    (pathname: string) => ({
      pathname,
      state: {
        ...stateRef.current,
        transitionUrl: stateRef.current.transitionUrl || url,
      },
    }),
    [url, stateRef],
  );

  // Redirect to listing with restored search query
  // [...] -> From: [Detail page] -> To: [Listing Page] (with filters)
  // If there is a transitionUrl from a previous transition redirect to the transitionUrl instead with saved state
  // [...] -> From: [Detail page] -> To: [Detail Page] (filters in state) -> [...]
  const createRedirectionWithSavedFilters = useCallback(
    (pathname: string, additionalState = {} as Record<string, any>) =>
      () => {
        const { replace = false, ...otherState } = additionalState;
        const navigationMethod = replace ? history.replace : history.push;
        if (stateRef.current.transitionUrl && stateRef.current.transitionUrl !== url) {
          // Coming from transition state
          const { transitionUrl, ...newState } = stateRef.current;
          navigationMethod(transitionUrl, { ...otherState, ...newState });
        } else {
          // Final redirection to listing with restored search
          navigationMethod(`${pathname}${stateRef.current.search || ''}`, otherState);
        }
      },
    [history, url, stateRef],
  );

  return useMemo(
    () => ({
      createRedirectionWithFilters,
      getTransitionPath,
      createRedirectionWithSavedFilters,
    }),
    [createRedirectionWithFilters, getTransitionPath, createRedirectionWithSavedFilters],
  );
};
