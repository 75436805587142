import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleEuroIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" stroke="#627D98" {...props} fill="none">
    <path
      d="M12.6667 3.67952C11.6784 2.83998 10.3983 2.3335 9 2.3335C5.87039 2.3335 3.33333 4.87055 3.33333 8.00016C3.33333 11.1298 5.87039 13.6668 9 13.6668C10.3983 13.6668 11.6784 13.1604 12.6667 12.3208M2 9.3335H8.66667M2 6.66683H8.66667"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
