import type { FC } from 'react';
import { useCallback } from 'react';
import type { AccountingAccountsNeeds } from '@graneet/business-logic';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import type { AccountingMissingCodeFormValues } from '../../AccountingMissingCodeForm';
import { AccountingMissingCodeForm } from '../../AccountingMissingCodeForm';

import { QueryWrapper } from 'features/api/components/QueryWrapper';

interface AccountingExportGenerateMissingCodeStepProps {
  accountingAccountsNeeds: AccountingAccountsNeeds;
  onClose(): void;
  onExport(formValues: AccountingMissingCodeFormValues): Promise<void>;
  isLoading: boolean;
}

export const AccountingExportGenerateMissingCodeStep: FC<AccountingExportGenerateMissingCodeStepProps> = ({
  accountingAccountsNeeds,
  onClose,
  onExport,
  isLoading,
}) => {
  const { t } = useTranslation(['global', 'accounting']);

  const form = useForm<AccountingMissingCodeFormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  const handleExport = useCallback(async () => {
    await onExport(form.getFormValues());
  }, [form, onExport]);

  return (
    <Form form={form}>
      <QueryWrapper>
        <AccountingMissingCodeForm accountingAccountsNeeds={accountingAccountsNeeds} />
      </QueryWrapper>

      <Modal.SecondaryButton onClick={onClose} isDisabled={isLoading}>
        {t('global:words.c.cancel')}
      </Modal.SecondaryButton>

      <Modal.PrimaryButton isDisabled={!isFormValid} isLoading={isLoading} onClick={handleExport}>
        {t('accounting:exportModal.cta')}
      </Modal.PrimaryButton>
    </Form>
  );
};
