import type { ChangeEvent, FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Text, Checkbox, Flex, Grid } from '@chakra-ui/react';
import { HiddenField, useFormContext, useOnChangeValues } from 'graneet-form';
import { isNil } from 'lodash-es';

export interface CheckBoxRangeFiltersProps<Name extends string = string> {
  label: string;
  name: Name;
  availableValues: {
    label: string;
    value: {
      min?: number;
      max?: number;
    };
  }[];
}

export const CheckBoxRangeFilters: FC<CheckBoxRangeFiltersProps> = ({ label, name, availableValues }) => {
  const form = useFormContext<{
    [name: string]:
      | {
          min?: number;
          max?: number;
        }[]
      | undefined
      | null;
  }>();

  const { getFormValues, setFormValues } = form;

  const values = useOnChangeValues(form, [name]);

  const currentValues = getFormValues();

  const selectedValues = useMemo(
    () => [...(isNil(values) ? values : []), ...(currentValues[name] ?? [])],
    [currentValues, values, name],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const parsedValue = JSON.parse(e.currentTarget.value);
      if (e.currentTarget.checked) {
        const newValue = currentValues[name] ? [...currentValues[name]!, parsedValue] : [parsedValue];
        setFormValues({ [name]: newValue });
      } else {
        setFormValues({
          [name]: currentValues[name]?.filter(
            (value: { min?: number; max?: number }) => JSON.stringify(value) !== e.currentTarget.value,
          ),
        });
      }
    },
    [name, setFormValues, currentValues],
  );

  const handleIsChecked = useCallback(
    (value: { min?: number; max?: number }) =>
      !!selectedValues?.find((selectedValue) => JSON.stringify(selectedValue) === JSON.stringify(value)),
    [selectedValues],
  );

  return (
    <Flex gap={3}>
      <Text w="12rem" fontSize="sm" color="baseSecondary">
        {label}
      </Text>
      <HiddenField name={name} />

      <Grid templateColumns="repeat(2, 50%)" w="100%" gridGap={2}>
        {availableValues.map(({ label: checkBoxLabel, value }) => (
          <Checkbox
            colorScheme="greenBrand"
            key={label}
            name={name}
            value={JSON.stringify(value)}
            isChecked={handleIsChecked(value)}
            onChange={handleChange}
          >
            <Text color="primaryLight" fontSize="14px">
              {checkBoxLabel}
            </Text>
          </Checkbox>
        ))}
      </Grid>
    </Flex>
  );
};
