import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICompanyMarginsResponseDTO, IQuoteSellsheetAmountDTO } from '@graneet/business-logic';
import { groupExistingComponentsByType } from '@graneet/business-logic';

import * as sellSheetApi from '../services/quote-sellsheet.api';

export const useQuoteSellSheetAmountPreview = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);

  return useCallback(
    async (
      quoteId: number,
      dto: IQuoteSellsheetAmountDTO,
      companyComponentTypes: ICompanyMarginsResponseDTO,
      setTotalAmountIsUnreachable: (isUnreachable: boolean, amountExVAT: number | null) => void,
    ) => {
      if (!dto) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        return true;
      }

      const [err, result] = await sellSheetApi.previewQuoteAmountSellSheet(quoteId, dto);

      if (err) {
        toast.error(t('quote:errors.updatingError'));
        return true;
      }

      setTotalAmountIsUnreachable(
        result.quote.amountExVAT !== dto.amountExVAT,
        result.quote.amountExVAT ? result.quote.amountExVAT : null,
      );

      const { components } = result;

      return groupExistingComponentsByType(Object.values(components), companyComponentTypes);
    },
    [toast, t],
  );
};
