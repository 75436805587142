import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Modal, TextField, Tooltip } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { SimpleGrid, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { ISupplier } from '@graneet/business-logic';
import { LEDGER_TYPES, PERMISSION } from '@graneet/business-logic';

import type { SupplierEditForm } from '../forms/supplier-edit-form';

import { SupplierTypeField } from './SupplierTypeField';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { Rule } from 'features/form/rules/Rule';
import { AddressesWithBillingFields } from 'features/common/components/fields/AddressesWithBillingFields';
import { LedgerField } from 'features/ledger/components/LedgerField';

interface SupplierEditModalProps {
  onClose(): void;
  isOpen: boolean;
  onSubmit(formValue: SupplierEditForm): Promise<void>;
  title: string;
  submitLabel: string;
  existingSupplier?: ISupplier;
  defaultSupplierName?: string;
}
export const SupplierEditModal: FC<SupplierEditModalProps> = ({
  onClose,
  isOpen,
  onSubmit,
  title,
  submitLabel,
  existingSupplier,
  defaultSupplierName,
}) => {
  const { t } = useTranslation(['supplier', 'global', 'clients']);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<SupplierEditForm>();
  const { isValid: isFormValid } = useFormStatus(form);

  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  useEffect(() => {
    if (isOpen) {
      const initialFormValues: Partial<SupplierEditForm> = existingSupplier
        ? {
            address: existingSupplier.address?.address,
            city: existingSupplier.address?.city,
            postalCode: existingSupplier.address?.postalCode,
            country: existingSupplier.address?.country,
            billingAddressAddress: existingSupplier.billingAddress?.address,
            billingAddressCity: existingSupplier.billingAddress?.city,
            billingAddressCountry: existingSupplier.billingAddress?.country,
            billingAddressPostalCode: existingSupplier.billingAddress?.postalCode,
            hasNoBillingAddress: !existingSupplier.hasBillingAddress,
            code: existingSupplier.code,
            name: existingSupplier.name,
            type: existingSupplier.type,
            auxiliaryAccount: existingSupplier.auxiliaryAccount,
            iban: existingSupplier.iban,
          }
        : { hasNoBillingAddress: true, name: defaultSupplierName };
      form.setFormValues(initialFormValues);
    } else {
      form.resetForm();
    }
  }, [isOpen, form, existingSupplier, defaultSupplierName]);

  const handleSubmit = useCallback(async () => {
    const values = form.getFormValues();
    setIsLoading(true);
    await onSubmit({
      ...values,
      auxiliaryAccount: hasUpdateAccountingPermission ? values.auxiliaryAccount : undefined,
    } as SupplierEditForm);
    setIsLoading(false);
  }, [form, hasUpdateAccountingPermission, onSubmit]);

  return (
    <Modal title={title} onClose={onClose} isOpen={isOpen} size="5xl" scrollBehavior="inside">
      <Form form={form}>
        <Stack gap={4}>
          <SimpleGrid columns={2} row={2} gap={4}>
            <TextField<SupplierEditForm> name="name" label={t('supplier:fields.supplierName')} isRequired>
              <Rule.IsRequired />
            </TextField>

            <LedgerField<SupplierEditForm, 'code'>
              name="code"
              isAutoGenerationDisabled={!!existingSupplier}
              ledgerType={LEDGER_TYPES.SUPPLIER}
              label={t('supplier:fields.supplierCode')}
            />

            <SupplierTypeField<SupplierEditForm>
              isRequired
              name="type"
              label={t('supplier:fields.type')}
              placeholder={t('supplier:placeholder.type')}
            >
              <Rule.IsRequired />
            </SupplierTypeField>

            <Tooltip
              label={t('supplier:tooltips.noPermissionForAuxiliaryAccountUpdate')}
              isDisabled={hasUpdateAccountingPermission}
              shouldWrapChildren
            >
              <TextField<SupplierEditForm>
                name="auxiliaryAccount"
                label={t('supplier:fields.auxiliaryAccount')}
                isReadOnly={!hasUpdateAccountingPermission}
              />
            </Tooltip>

            <TextField<SupplierEditForm> name="iban" label={t('supplier:fields.iban')}>
              <Rule.IsIban />
            </TextField>
          </SimpleGrid>

          <AddressesWithBillingFields />
        </Stack>

        <Modal.Close isDisabled={isLoading} />

        <Modal.PrimaryButton isLoading={isLoading} isDisabled={!isFormValid} onClick={handleSubmit}>
          {submitLabel}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
