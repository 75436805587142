import Big from 'big.js';
import type { CellClassParams, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type { AGColDef } from '../../types';
import { TextCell } from '../../components/columns/TextCell/TextCell';
import { VATRateEditorCell } from '../../components/columns/VATRateCell/VATRateEditorCell';
import { TextEditorCell } from '../../components/columns/TextCell/TextEditorCell';

import { useQuoteBasicItemUpdateVatRate } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateVatRate';
import { useQuoteOptionalItemUpdateVatRate } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateVatRate';
import { useQuoteSubItemUpdateVatRate } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdateVatRate';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export function useVatRateColumnDef(isEditable: boolean) {
  const quoteBasicItemUpdateVatRate = useQuoteBasicItemUpdateVatRate();
  const quoteOptionalItemUpdateVatRate = useQuoteOptionalItemUpdateVatRate();
  const quoteSubItemItemUpdateVatRate = useQuoteSubItemUpdateVatRate();

  const { executeCommand } = useCommands();
  const { quote } = useQuote();
  // @[ff: accounting-standard-vat-rates]
  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'vatRate',
      width,
      index,
      headerName: label,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressMovable: false,
      editable: (params) => {
        if (!isEditable) return false;
        switch (params.data?.content.type) {
          case 'QuoteBasicItem':
          case 'QuoteOptionalItem':
            return true;
          case 'QuoteComponent':
          case 'QuoteSubItem':
          default:
            return false;
        }
      },
      cellClass: (params: CellClassParams) => {
        if (params.node.footer || params.data?.content.type === 'QuoteLot') return 'no-focus read-only';
        switch (params.data?.content.type) {
          case 'QuoteBasicItem':
          case 'QuoteOptionalItem':
            return '';
          case 'QuoteComponent':
          case 'QuoteSubItem':
          default:
            return 'no-focus read-only';
        }
      },
      sortable: false,
      cellStyle: { textAlign: 'right' },
      cellRenderer: TextCell,
      cellRendererParams: {
        mask: 'percentage',
      },
      // @[ff: accounting-standard-vat-rates]
      cellEditor: hasStandardVATRates ? VATRateEditorCell : TextEditorCell,
      cellEditorParams: {
        mask: 'percentage',
      },
      valueGetter: (params) => {
        if (!params.data) {
          return undefined;
        }

        switch (params.data.content.type) {
          case 'QuoteOptionalItem':
          case 'QuoteHiddenCostItem':
          case 'QuoteBasicItem':
            return params.data.content.vatRate;
          case 'QuoteSubItem':
          case 'QuoteLot':
          case 'QuoteComponent':
            return undefined;
          default:
            throw new Error('Type unknown');
        }
      },
      valueSetter: (params) => {
        try {
          Big(params.newValue);
        } catch {
          return false;
        }

        if (params.oldValue?.toString() === params.newValue?.toString()) {
          return false;
        }

        switch (params.data?.content.type) {
          case 'QuoteBasicItem': {
            executeCommand(quoteBasicItemUpdateVatRate(params.data.id, params.newValue), quote);
            return true;
          }
          case 'QuoteOptionalItem': {
            executeCommand(quoteOptionalItemUpdateVatRate(params.data.id, params.newValue), quote);
            return true;
          }
          case 'QuoteSubItem': {
            executeCommand(quoteSubItemItemUpdateVatRate(params.data.id, params.newValue), quote);
            return true;
          }
          default:
            return false;
        }
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [
      executeCommand,
      isEditable,
      quote,
      quoteBasicItemUpdateVatRate,
      quoteOptionalItemUpdateVatRate,
      quoteSubItemItemUpdateVatRate,
      hasStandardVATRates,
    ],
  );
}
