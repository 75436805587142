import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import type { StackProps } from '@chakra-ui/react';
import { Stack, useRadioGroup } from '@chakra-ui/react';

import { Option } from './Option';
import { CardOption } from './CardOption';
import { RadioGroupContextProvider } from './RadioGroupContext';

export interface RadioGroupProps extends Omit<StackProps, 'onChange' | 'onBlur' | 'onFocus'> {
  name: string;
  onChange: (nextValue: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  children: ReactNode;
  value?: string;
  defaultValue?: string;
  rules?: ReactNode;
  isDisabled?: boolean;
}

const RadioGroupComponent: FC<RadioGroupProps> = ({
  name,
  onChange,
  onFocus,
  onBlur,
  children,
  value,
  defaultValue,
  rules = null,
  isDisabled,
  ...stackProps
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    onChange: (newValue) => {
      onFocus?.();
      onChange(newValue);
      onBlur?.();
    },
    name,
    defaultValue,
    value,
  });
  const group = getRootProps();

  const radioGroupContext = useMemo(() => ({ getRadioProps, isDisabled }), [getRadioProps, isDisabled]);

  return (
    <Stack {...group} {...stackProps}>
      {rules}
      <RadioGroupContextProvider value={radioGroupContext}>{children}</RadioGroupContextProvider>
    </Stack>
  );
};

export const RadioGroup = Object.assign(RadioGroupComponent, {
  Option,
  CardOption,
});
