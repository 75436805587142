import type { IconProps } from '@chakra-ui/react';
import { Box, Flex, HStack } from '@chakra-ui/react';
import type { FC, PropsWithChildren, ReactElement } from 'react';
import { cloneElement } from 'react';

const COLORS_CONFIG = {
  green: {
    iconColor: 'green.500',
    color: 'green.900',
    background: 'green.100',
    borderColor: 'green.200',
  },
  red: {
    iconColor: 'red.500',
    color: 'red.900',
    background: 'red.100',
    borderColor: 'red.200',
  },
  blue: {
    iconColor: 'greenBrand.light',
    color: 'blue.900',
    background: 'blue.100',
    borderColor: 'blue.200',
  },
  purple: {
    iconColor: 'purple.500',
    color: 'purple.900',
    background: 'purple.100',
    borderColor: 'purple.200',
  },
} as const;

type CalloutProps = {
  color: 'green' | 'red' | 'blue' | 'purple';
  icon?: ReactElement<IconProps>;
} & PropsWithChildren;

export const QuotationCallout: FC<CalloutProps> = ({ icon, color, children }) => {
  const { iconColor, color: textColor, borderColor, background } = COLORS_CONFIG[color];
  const iconElement = icon
    ? cloneElement(icon, {
        boxSize: '0.875rem',
        stroke: iconColor,
        color: iconColor,
      })
    : null;
  return (
    <HStack
      width="100%"
      alignItems="center"
      background={background}
      borderRadius="0.375rem"
      border="1px solid"
      borderColor={borderColor}
      px="0.5rem"
      py="0.25rem"
      gap={2}
    >
      {iconElement && (
        <Flex alignItems="center" alignSelf="center">
          {iconElement}
        </Flex>
      )}
      <Box fontWeight={500} color={textColor} fontSize="0.75rem">
        {children}
      </Box>
    </HStack>
  );
};
