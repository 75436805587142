import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Button, DroppableFileField, Modal, SimpleAddIcon } from '@graneet/lib-ui';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Form, useForm, useOnChangeValues } from 'graneet-form';

import { Rule } from 'features/form/rules/Rule';

interface SupplierInvoiceFileAddReceiptFileButtonProps {
  isDisabled: boolean;

  tooltip?: string;

  onSubmit(file: File): void | Promise<void>;

  needsConfirmationToSubmit?: boolean;
}

type ReceiptFileForm = { file: File };

export const SupplierInvoiceFileAddReceiptFileButton: FC<SupplierInvoiceFileAddReceiptFileButtonProps> = ({
  isDisabled,
  tooltip,
  onSubmit,
  needsConfirmationToSubmit = false,
}) => {
  const { t } = useTranslation(['global']);

  const [isLoading, setIsLoading] = useState(false);

  const modal = useDisclosure();

  const form = useForm<ReceiptFileForm>();
  const { file } = useOnChangeValues(form, ['file']);

  const handleSubmit = useCallback(async () => {
    const { file: currentFile } = form.getFormValues();

    setIsLoading(true);
    await onSubmit(currentFile!);
    setIsLoading(false);

    modal.onClose();
  }, [form, modal, onSubmit]);

  // Reset modal on open
  useEffect(() => {
    if (modal.isOpen) {
      form.resetForm();
    }
  }, [form, modal.isOpen]);

  return (
    <>
      <Button
        variant="outline"
        leftIcon={<SimpleAddIcon />}
        colorScheme="gray"
        onClick={modal.onOpen}
        isDisabled={isDisabled}
        tooltip={tooltip}
      >
        {t('global:receiptsFileField.add')}
      </Button>

      <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t('global:receiptsFileField.add')}>
        <Form form={form}>
          <DroppableFileField<ReceiptFileForm>
            name="file"
            isRequired
            accept="application/pdf"
            onFileChange={!needsConfirmationToSubmit ? handleSubmit : undefined}
          >
            <Rule.IsRequired />
          </DroppableFileField>
        </Form>
        <Modal.Close isDisabled={isLoading} />

        {needsConfirmationToSubmit && (
          <Modal.PrimaryButton onClick={handleSubmit} isDisabled={!file} isLoading={isLoading}>
            {t('global:receiptsFileField.add')}
          </Modal.PrimaryButton>
        )}
      </Modal>
    </>
  );
};
