import { STATEMENT_TYPES } from '@graneet/business-logic';
import type { ButtonColorScheme } from '@graneet/lib-ui';

export const STATEMENT_FIELDS = {
  INVOICE_NUMBER: 'invoiceNumber',
  CLIENT_NAME: 'clientName',
  NAME: 'name',
  AMOUNT_EX_VAT: 'amountExVAT',
  BILLING_DATE: 'billingDate',
  TYPE: 'type',
  PROJECT_NAME: 'projectName',
  STATUS: 'status',
  PROGRESS: 'cumulativeProgressPercentage',
  SUB_PROJECT_NAME: 'subProjectName',
};

export enum STATEMENT_PAGINATED_STATUSES {
  DRAFT = 'DRAFT',
  VALIDATED = 'VALIDATED',
  ACCEPTED_COMPLETED = 'ACCEPTED_COMPLETED',
  CANCELLED = 'CANCELLED',
}

export const STATEMENT_PAGINATED_STATUSES_VALUES: Record<STATEMENT_PAGINATED_STATUSES, string | string[]> = {
  [STATEMENT_PAGINATED_STATUSES.DRAFT]: 'DRAFT',
  [STATEMENT_PAGINATED_STATUSES.VALIDATED]: 'VALIDATED',
  [STATEMENT_PAGINATED_STATUSES.ACCEPTED_COMPLETED]: ['ACCEPTED', 'COMPLETED'],
  [STATEMENT_PAGINATED_STATUSES.CANCELLED]: ['CANCELLED'],
};

export const STATEMENT_PAGINATED_STATUSES_COLORS: Record<STATEMENT_PAGINATED_STATUSES, ButtonColorScheme> = {
  [STATEMENT_PAGINATED_STATUSES.DRAFT]: 'gray',
  [STATEMENT_PAGINATED_STATUSES.VALIDATED]: 'blue',
  [STATEMENT_PAGINATED_STATUSES.ACCEPTED_COMPLETED]: 'green',
  [STATEMENT_PAGINATED_STATUSES.CANCELLED]: 'red',
};

export const STATEMENT_FILTERS = {
  STATUS: 'status',
  TYPE: 'type',
  BILLING_DATE: 'billingDate',
  SUB_PROJECT_ID: 'subProjectId',
  TAGS: 'tags',
};

export const STATEMENT_PATHS = {
  [STATEMENT_TYPES.INVOICE]: 'invoices',
  [STATEMENT_TYPES.PROGRESS_STATEMENT]: 'progress-statements',
  [STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT]: 'progress-statements',
  [STATEMENT_TYPES.DOWN_PAYMENT]: 'down-payments',
  [STATEMENT_TYPES.CREDIT]: 'credits',
  [STATEMENT_TYPES.HOLDBACK]: 'holdbacks',
} as const;
