import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { Text } from '@chakra-ui/react';
import { Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IPaymentMethodResponseDTO } from '@graneet/business-logic';

import { PaymentMethodRadioGroup } from '../PaymentMethodRadioGroup';

interface PaymentMethodSelectModalProps {
  isOpen: boolean;

  onClose(): void;

  selectedPaymentMethod?: IPaymentMethodResponseDTO;

  paymentMethods: IPaymentMethodResponseDTO[];

  onSelected(newPaymentMethod: IPaymentMethodResponseDTO | null): void;

  title?: string;

  description?: string;
}

export const PaymentMethodSelectModal: FC<PaymentMethodSelectModalProps> = ({
  isOpen,
  onClose,
  selectedPaymentMethod,
  paymentMethods,
  onSelected,
  title,
  description,
}) => {
  const { t } = useTranslation(['paymentMethods', 'global']);

  const selectedPaymentMethodRef = useRef<IPaymentMethodResponseDTO | null>(null);

  useEffect(() => {
    if (selectedPaymentMethod) {
      selectedPaymentMethodRef.current = selectedPaymentMethod;
    }
  }, [selectedPaymentMethod]);

  const handleContinueClicked = useCallback(() => {
    onSelected(selectedPaymentMethodRef.current);
    onClose();
  }, [onSelected, onClose]);

  const handlePaymentMethodSelected = useCallback((paymentMethod: IPaymentMethodResponseDTO | null) => {
    selectedPaymentMethodRef.current = paymentMethod;
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="5xl"
      isCentered
      scrollBehavior="inside"
      title={title || t('paymentMethods:modal.select.title')}
    >
      <Text mb={8}>{description || t('paymentMethods:modal.select.desc.invoice')}</Text>

      <PaymentMethodRadioGroup
        paymentMethods={paymentMethods}
        selectedPaymentMethod={selectedPaymentMethod}
        onSelected={handlePaymentMethodSelected}
      />

      <Modal.SecondaryButton onClick={onClose}>{t('global:words.c.previous')}</Modal.SecondaryButton>

      <Modal.PrimaryButton onClick={handleContinueClicked}>{t('paymentMethods:modal.select.cta')}</Modal.PrimaryButton>
    </Modal>
  );
};
