import { useLayoutEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { QuoteStatus } from '@org/graneet-bff-client';
import { Badge, SimpleNewQuoteIcon, Tabs, Tooltip } from '@graneet/lib-ui';
import { Flex } from '@chakra-ui/react';

import { ViewQuoteSummaryTab } from './_tabs/ViewQuoteSummaryTab';
import { ViewQuoteInformationTab } from './_tabs/ViewQuoteInformationTab';
import { ViewQuoteClientInformationTab } from './_tabs/ViewQuoteClientInformationTab';

import { Loading } from 'features/common/components/Loading';
import { useQuotationApi } from 'features/quotation/services/quote.api';
import { Error } from 'features/common/components/Error';
import { DisplayNotFoundScreen } from 'screens/DisplayNotFoundScreen';
import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewQuoteDetailsScreen: FC = () => {
  const { t } = useTranslation(['quote', 'global']);
  const { quoteId } = useParams<{ quoteId: string }>();
  const { updateHeaderTitle } = useHeaderContext();
  const { useGetQuoteById } = useQuotationApi();
  const { data: quote, isLoading, isError } = useGetQuoteById(quoteId);

  useLayoutEffect(() => {
    updateHeaderTitle(
      quote?.name || '',
      [
        {
          name: t('global:nav.quotes'),
          link: '/opportunities/quotation',
        },
      ],
      <Tooltip label={t('quote:quotation.columns.createdWithNewModule')} shouldWrapChildren placement="right">
        <Badge colorScheme="blue">
          <Flex alignItems="center" justifyContent="center">
            <SimpleNewQuoteIcon />
          </Flex>
        </Badge>
      </Tooltip>,
    );
  }, [quote, t, updateHeaderTitle]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError || quote.status === QuoteStatus.Archived) {
    return <Error />;
  }

  return (
    <Tabs
      hasInternalPadding
      fallbackPath="./summary"
      data={[
        {
          id: 'summary',
          title: t('quote:menu.summary'),
          exact: true,
          path: './summary',
          content: (
            <QueryWrapper>
              <ViewQuoteSummaryTab quote={quote} />
            </QueryWrapper>
          ),
        },

        {
          id: 'details',
          title: t('quote:menu.details'),
          exact: true,
          path: './details',
          content: (
            <QueryWrapper>
              <ViewQuoteInformationTab quote={quote} />
            </QueryWrapper>
          ),
        },
        {
          id: 'client',
          title: t('quote:menu.client'),
          exact: true,
          path: './client',
          content: (
            <QueryWrapper>
              <ViewQuoteClientInformationTab quote={quote} />
            </QueryWrapper>
          ),
        },
      ]}
      errorComponent={<DisplayNotFoundScreen />}
    />
  );
};
