import type { FC } from 'react';
import { Flex, Text, Wrap } from '@chakra-ui/react';
import { Button, DataTable, Price } from '@graneet/lib-ui';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import type { IExternalProgressStatementResponseDTO } from '@graneet/business-logic';
import { buildDateWithTz, getPaymentDeadline, REFERENCE_TIMEZONE, formatDateOrEmpty } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { ProgressStatementExternalFooter } from '../ProgressStatementExternalFooter';

import { ExternalProgressStatementConfirmationCallout } from './ProgressStatementExternalConfirmationCallout';

interface ProgressStatementExternalValidationBlockProps {
  externalProgressStatement: IExternalProgressStatementResponseDTO;

  onCommentAndReject: () => void;

  onApprove: () => void;
}

export const ProgressStatementExternalValidationBlock: FC<ProgressStatementExternalValidationBlockProps> = ({
  externalProgressStatement,
  onCommentAndReject,
  onApprove,
}) => {
  const { t } = useTranslation(['progressStatement']);

  const { company, project, progressStatement } = externalProgressStatement;

  const psBillingDate = buildDateWithTz(progressStatement.billingDate, REFERENCE_TIMEZONE);
  const dueDate = getPaymentDeadline(psBillingDate, project.paymentTerm, project.deadlineType).toDate();

  return (
    <>
      <Flex flex={1} overflow="auto" p={4} direction="column" gap={8}>
        <ExternalProgressStatementConfirmationCallout externalValidation={progressStatement.externalValidation} />

        <Flex fontSize="sm" direction="column" gap={3}>
          <Text fontWeight="bold">{t('progressStatement:external.information.title')}</Text>

          <DataTable numberOfColumns={1} density="sm">
            <DataTable.Row label={t('progressStatement:external.information.company')}>
              <DataTable.Cell>{company.name}</DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row label={t('progressStatement:external.information.project')}>
              <DataTable.Cell>{project.name}</DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row label={t('progressStatement:external.information.invoiceName')}>
              <DataTable.Cell>{progressStatement.name}</DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row label={t('progressStatement:external.information.invoiceNumber')}>
              <DataTable.Cell>{progressStatement.invoiceNumber}</DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row label={t('progressStatement:external.information.billingDate')}>
              <DataTable.Cell>{formatDateOrEmpty(progressStatement.billingDate)}</DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row label={t('progressStatement:external.information.dueDate')}>
              <DataTable.Cell>{formatDateOrEmpty(dueDate)}</DataTable.Cell>
            </DataTable.Row>
          </DataTable>

          <Text fontWeight="bold">{t('progressStatement:external.amounts.title')}</Text>

          <DataTable numberOfColumns={1} density="sm">
            <DataTable.Row label={t('progressStatement:external.amounts.exVAT')}>
              <DataTable.Cell>
                <Price amount={progressStatement.amountExVAT} />
              </DataTable.Cell>
            </DataTable.Row>

            <DataTable.Row label={t('progressStatement:external.amounts.incVAT')}>
              <DataTable.Cell>
                <Price amount={progressStatement.amountIncVAT} />
              </DataTable.Cell>
            </DataTable.Row>

            {progressStatement.netBilledIncVAT !== progressStatement.amountIncVAT && (
              <DataTable.Row label={t('progressStatement:external.amounts.netBilledIncVAT')}>
                <DataTable.Cell>
                  <Price amount={progressStatement.netBilledIncVAT} />
                </DataTable.Cell>
              </DataTable.Row>
            )}

            {progressStatement.netToPaidIncVAT !== progressStatement.netBilledIncVAT && (
              <DataTable.Row label={t('progressStatement:external.amounts.netToPaidIncVAT')}>
                <DataTable.Cell>
                  <Price amount={progressStatement.netToPaidIncVAT} />
                </DataTable.Cell>
              </DataTable.Row>
            )}
          </DataTable>
        </Flex>
      </Flex>

      <ProgressStatementExternalFooter>
        {!progressStatement.externalValidation && (
          <Wrap gap={4} justify="center">
            <Button leftIcon={<CloseIcon />} onClick={onCommentAndReject} colorScheme="red" variant="outline">
              {t('progressStatement:external.actions.commentAndReject')}
            </Button>
            <Button leftIcon={<CheckIcon />} onClick={onApprove}>
              {t('progressStatement:external.actions.approve')}
            </Button>
          </Wrap>
        )}
      </ProgressStatementExternalFooter>
    </>
  );
};
