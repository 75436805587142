import type { GroupBase, GroupHeadingProps } from 'chakra-react-select';
import { components } from 'chakra-react-select';
import { HStack, Text } from '@chakra-ui/react';

import { SimpleBuildingIcon } from '../../Icons';

export const GroupHeading = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
  children,
  ...props
}: GroupHeadingProps<Option, IsMulti, Group>) => (
  <components.GroupHeading<Option, IsMulti, Group> {...props}>
    <>
      {children !== undefined && (
        <HStack my={2}>
          <SimpleBuildingIcon width=".8rem" height=".8rem" />
          <Text color="gray.800" fontSize=".9rem" textTransform="none">
            {children}
          </Text>
        </HStack>
      )}
    </>
  </components.GroupHeading>
);
