import type { FC } from 'react';
import { useCallback } from 'react';
import {
  CUMULATIVE_INPUT_TYPE,
  divideFloating,
  getAmountPercentage,
  getFloatingPercentage,
  isNumberFinite,
  multiplyFloating,
  roundFloating,
} from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import {
  CurrencyField,
  NegativeCurrencyField,
  NegativeUnitField,
  PercentageField,
  Tooltip,
  UnitField,
  useCurrency,
} from '@graneet/lib-ui';
import { Box, Flex } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import type { ProgressStatementTreeItem } from '../../hooks/useProgressStatementTree';
import { useProgressStatementTreeContext } from '../../hooks/useProgressStatementTree';
import type { IProgressStatementLineValues } from '../../services/progress-statement.util';
import type { ProgressStatementEditItemForm } from '../../forms/progress-statement-edit-item-form';
import { getItemCumulativeFieldName, getKeyForCumulativeInput } from '../../forms/progress-statement-edit-item-form';

const INPUT_STYLE = {
  outline: 'none',
  border: 0,
  textAlign: 'center',
  autoComplete: 'off',
} as const;

interface ProgressStatementItemCumulativeFieldProps {
  item: ProgressStatementTreeItem;

  previousItemValues?: IProgressStatementLineValues;
}

export const ProgressStatementItemCumulativeField: FC<ProgressStatementItemCumulativeFieldProps> = ({
  item,
  previousItemValues,
}) => {
  const { t } = useTranslation(['progressStatement']);

  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();
  const progressStatementTreeContext = useProgressStatementTreeContext();

  const cumulativeProgressPercentageField = getItemCumulativeFieldName(item.id, CUMULATIVE_INPUT_TYPE.PERCENTAGE);
  const cumulativeProgressQuantityField = getItemCumulativeFieldName(item.id, CUMULATIVE_INPUT_TYPE.QUANTITY);
  const cumulativeAmountField = getItemCumulativeFieldName(item.id, CUMULATIVE_INPUT_TYPE.AMOUNT);

  const form = useFormContext<ProgressStatementEditItemForm>();
  const { getFormValues, setFormValues } = form;

  const { cumulativeInputType: cumulativeInputTypeRaw, [cumulativeAmountField]: currentCumulativeAmountExVAT } =
    useOnChangeValues(form, ['cumulativeInputType', cumulativeAmountField]);
  // TS Hack, here we know the value is always defined
  const cumulativeInputType = cumulativeInputTypeRaw!;

  const handleBlur = useCallback(() => {
    const fieldName = getItemCumulativeFieldName(item.id, cumulativeInputType);
    let { [fieldName]: value } = getFormValues();

    const newFormValues: Partial<ProgressStatementEditItemForm> = {};

    // If the user enters 0, replace by the old value
    if (!isNumberFinite(value)) {
      const previousValue = previousItemValues?.[getKeyForCumulativeInput(cumulativeInputType)] || 0;

      value = cumulativeInputType === CUMULATIVE_INPUT_TYPE.AMOUNT ? mapNumberToAmount(previousValue) : previousValue;

      newFormValues[fieldName] = value;
    }

    // New computed values
    if (cumulativeInputType !== CUMULATIVE_INPUT_TYPE.AMOUNT) {
      newFormValues[cumulativeAmountField] =
        cumulativeInputType === CUMULATIVE_INPUT_TYPE.PERCENTAGE
          ? // Determine computed cumulative amount based on percentage
            mapNumberToAmount(getAmountPercentage(item.totalExVAT, value))
          : // Determine computed cumulative amount based on quantity
            mapNumberToAmount(roundFloating(multiplyFloating(value || 0, item.unitPrice)));
    }

    const newCumulativeAmount = mapAmountToNumber(
      cumulativeInputType === CUMULATIVE_INPUT_TYPE.AMOUNT
        ? value || 0 // Use input value
        : newFormValues[cumulativeAmountField] || 0, // Use previously computed value
    );

    if (cumulativeInputType !== CUMULATIVE_INPUT_TYPE.PERCENTAGE) {
      const progressPercentage = getFloatingPercentage(newCumulativeAmount, item.totalExVAT);
      newFormValues[cumulativeProgressPercentageField] = progressPercentage;
      progressStatementTreeContext.updateLeafData(item.id, {
        progressPercentage,
      });
    } else {
      progressStatementTreeContext.updateLeafData(item.id, {
        progressPercentage: value,
      });
    }

    if (cumulativeInputType !== CUMULATIVE_INPUT_TYPE.QUANTITY) {
      newFormValues[cumulativeProgressQuantityField] = divideFloating(newCumulativeAmount, item.unitPrice);
    }

    progressStatementTreeContext.updateLeafData(item.id, {
      cumulativeAmountExVAT: newCumulativeAmount,
    });

    setFormValues(newFormValues);
  }, [
    cumulativeAmountField,
    cumulativeInputType,
    cumulativeProgressPercentageField,
    cumulativeProgressQuantityField,
    getFormValues,
    item.id,
    item.totalExVAT,
    item.unitPrice,
    mapAmountToNumber,
    mapNumberToAmount,
    previousItemValues,
    progressStatementTreeContext,
    setFormValues,
  ]);

  const maxAmount = Math.abs(item.totalExVAT);

  const AbsoluteCurrencyField =
    item.totalExVAT < 0
      ? NegativeCurrencyField<ProgressStatementEditItemForm>
      : CurrencyField<ProgressStatementEditItemForm>;
  const AbsoluteUnitField =
    item.quantity < 0 ? NegativeUnitField<ProgressStatementEditItemForm> : UnitField<ProgressStatementEditItemForm>;

  return (
    <Flex alignItems="center">
      <Box textAlign="center" borderBottom="2px" borderColor="greenBrand.light" w="5rem">
        <Box display={cumulativeInputType !== CUMULATIVE_INPUT_TYPE.PERCENTAGE ? 'none' : undefined}>
          <PercentageField<ProgressStatementEditItemForm>
            name={cumulativeProgressPercentageField}
            size="3"
            inputProps={INPUT_STYLE}
            onBlur={handleBlur}
            scale={null}
            isRequired
            noBorder
          />
        </Box>
        <Box display={cumulativeInputType !== CUMULATIVE_INPUT_TYPE.QUANTITY ? 'none' : undefined}>
          <AbsoluteUnitField
            name={cumulativeProgressQuantityField}
            unit={item.unit}
            max={item.quantity}
            inputProps={INPUT_STYLE}
            onBlur={handleBlur}
            scale={null}
            noBorder
            isRequired
          />
        </Box>
        <Box display={cumulativeInputType !== CUMULATIVE_INPUT_TYPE.AMOUNT ? 'none' : undefined}>
          <AbsoluteCurrencyField
            name={cumulativeAmountField}
            max={mapNumberToAmount(maxAmount)}
            inputProps={INPUT_STYLE}
            onBlur={handleBlur}
            isRequired
            noBorder
          />
        </Box>
      </Box>
      {mapNumberToAmount(previousItemValues?.cumulativeAmountExVAT ?? 0) > (currentCumulativeAmountExVAT ?? 0) && (
        <Tooltip label={t('progressStatement:tooltips.newPercentageSmallerThanPreviousProgressStatement')}>
          <WarningIcon boxSize={5} color="yellow.500" />
        </Tooltip>
      )}
    </Flex>
  );
};
