import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, GridItem, Stack, Text } from '@chakra-ui/react';
import { Card, DataTable, EllipsisText, TextField } from '@graneet/lib-ui';
import type { IClient } from '@graneet/business-logic';

import type { AccountingClientEditionBlockForm } from '../../forms/accounting.form';
import { getAccountingClientAuxiliaryAccountFieldName } from '../../forms/accounting.form';

import { Rule } from 'features/form/rules/Rule';

interface AccountingClientEditionBlockProps {
  clients: Pick<IClient, 'id' | 'code' | 'enterpriseName'>[];

  haveRequiredFields?: boolean;
}

export const AccountingClientEditionBlock: FC<AccountingClientEditionBlockProps> = ({
  clients,
  haveRequiredFields = false,
}) => {
  const { t } = useTranslation(['accounting']);

  return (
    <Stack>
      <Text fontSize="lg" color="gray.800">
        {t('accounting:parameters.sections.client')}
      </Text>

      <Card>
        <DataTable numberOfColumns={1}>
          {clients.map((client) => {
            const fieldName = getAccountingClientAuxiliaryAccountFieldName(client.id);

            return (
              <DataTable.Row
                key={client.id}
                label={
                  <Grid templateColumns="6rem auto" gap={3}>
                    <GridItem>
                      <EllipsisText color="gray.600">{client.code}</EllipsisText>
                    </GridItem>

                    <GridItem>
                      <EllipsisText>{client.enterpriseName}</EllipsisText>
                    </GridItem>
                  </Grid>
                }
              >
                <DataTable.Cell>
                  <TextField<AccountingClientEditionBlockForm>
                    name={fieldName}
                    placeholder={t('accounting:parameters.configPlaceholder')}
                  >
                    {haveRequiredFields && <Rule.IsRequired />}
                  </TextField>
                </DataTable.Cell>
              </DataTable.Row>
            );
          })}
        </DataTable>
      </Card>
    </Stack>
  );
};
