import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { QuoteAGGridType } from '../../constants/agGridConstants';

import { useEditUserSettings } from 'features/user/services/user.api';
import { useDebounce } from 'features/common/hooks/useDebounce';
import type { QuoteColumns } from 'features/quotation/quote-common/store/quoteSettingSliceZustant';
import {
  defaultQuoteHiddenCostColumns,
  defaultQuoteRootColumns,
} from 'features/quotation/quote-common/store/quoteSettingSliceZustant';
import {
  updateQuoteHiddenCostColumns,
  updateQuoteRootColumns,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useQuoteSettingsGetFromStoreOrThrow } from 'features/quotation/quote-common/hooks/useQuoteSettingsGetFromStore';

export function useColumnSettings(type: QuoteAGGridType) {
  const { t } = useTranslation(['quote']);

  const editUserSettingsMutation = useEditUserSettings(false);
  const quoteSettings = useQuoteSettingsGetFromStoreOrThrow();
  const quoteColumns =
    type === QuoteAGGridType.ROOT ? quoteSettings.quoteRootColumns : quoteSettings.quoteHiddenCostColumns;

  const saveColumns = useDebounce(
    async (data: string[]) => {
      const [quoteColumnsJSON] = data;
      if (type === QuoteAGGridType.ROOT) {
        await editUserSettingsMutation.mutateAsync({ quoteRootColumnsJSON: quoteColumnsJSON });
      } else {
        await editUserSettingsMutation.mutateAsync({ quoteHiddenCostColumnsJSON: quoteColumnsJSON });
      }
    },
    2000,
    { leading: false, trailing: true },
  );

  const updateColumns = useCallback(
    async (columns: QuoteColumns) => {
      const quoteColumnsJSON = JSON.stringify(columns, null, 4);
      if (type === QuoteAGGridType.ROOT) {
        updateQuoteRootColumns(quoteColumnsJSON);
      } else {
        updateQuoteHiddenCostColumns(quoteColumnsJSON);
      }
      saveColumns(quoteColumnsJSON);
    },
    [saveColumns, type],
  );

  const defaultQuoteColumns = useMemo(
    () => (type === QuoteAGGridType.ROOT ? defaultQuoteRootColumns : defaultQuoteHiddenCostColumns),
    [type],
  );

  const toggleColumn = useCallback(
    (name: string, checked: boolean) => {
      if (name === 'debours') {
        const newRootColumns = quoteColumns.map((col) => {
          if (
            col.name === 'unitFlatCostAmount' ||
            col.name === 'flatCostAmount' ||
            col.name === 'shareOfHiddenCostAmount' ||
            col.name === 'costAmount'
          ) {
            return { ...col, enabled: checked };
          }
          return col;
        });
        updateColumns(newRootColumns);
        return;
      }
      if (name === 'sale') {
        if (type === QuoteAGGridType.HIDDEN_COST) {
          return;
        }
        const newColumns = quoteColumns.map((col) => {
          if (
            col.name === 'totalMargin' ||
            col.name === 'unitAmount' ||
            col.name === 'vatRate' ||
            col.name === 'amount'
          ) {
            return { ...col, enabled: checked };
          }
          return col;
        });
        updateColumns(newColumns);
        return;
      }
      const newRootColumns = quoteColumns.map((col) => {
        if (col.name === name) {
          return { ...col, enabled: checked };
        }
        return col;
      });
      updateColumns(newRootColumns);
    },
    [quoteColumns, type, updateColumns],
  );

  const getColumnDef = useCallback(
    (name: string) => {
      let column = quoteColumns.find((col) => col.name === name);
      if (!column) {
        column = defaultQuoteColumns.find((col) => col.name === name);
        if (!column) {
          throw new Error(`Column ${name} not found`);
        }
      }
      return {
        width: column.width,
        label: t(`quote:quotation.columns.${name}` as any),
        enabled: column.enabled,
      };
    },
    [quoteColumns, defaultQuoteColumns, t],
  );

  const isColumnEnabled = useCallback((name: string) => getColumnDef(name).enabled, [getColumnDef]);

  return useMemo(
    () => ({
      quoteColumns,
      defaultQuoteColumns,
      toggleColumn,
      getColumnDef,
      isColumnEnabled,
    }),
    [quoteColumns, defaultQuoteColumns, toggleColumn, getColumnDef, isColumnEnabled],
  );
}
