import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import type { FileItem } from '@graneet/lib-ui';
import { CheckboxField, DeepTable, FileNameList } from '@graneet/lib-ui';
import { Box, GridItem, useTheme } from '@chakra-ui/react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { IQuoteJobFile } from '@graneet/business-logic';

import { countSelectedColumns } from '../../services/quote-export.util';
import type { QuoteExportForm } from '../../forms/quote-export-edition.wizard';
import {
  generateImagesExportFieldName,
  generateIsJobSelectedExportFieldName,
} from '../../forms/quote-export-edition.wizard';

interface QuoteExportImagesRowProps {
  depth: number;

  parentId: number;

  quoteJobFiles: IQuoteJobFile[];

  isOptional: boolean | undefined;
}

export const QuoteExportImagesRow: FC<QuoteExportImagesRowProps> = ({ depth, parentId, quoteJobFiles, isOptional }) => {
  const quoteExportForm = useFormContext<QuoteExportForm>();
  const { exportItems: items, [generateIsJobSelectedExportFieldName(parentId)]: isParentJobSelected } =
    useOnChangeValues(quoteExportForm, ['exportItems', generateIsJobSelectedExportFieldName(parentId)]);

  const theme = useTheme();
  const nbColumns = useMemo(() => countSelectedColumns(items), [items]);

  useEffect(() => {
    if (isParentJobSelected === false) {
      quoteExportForm.setFormValues({
        [generateImagesExportFieldName(parentId)]: false,
      });
    }
  }, [isParentJobSelected, parentId, quoteExportForm]);

  if (nbColumns === 0) return null;

  return (
    <DeepTable.Row
      backgroundColor="gray.50"
      offset={depth}
      color="gray.600"
      leftContent={
        <CheckboxField<QuoteExportForm>
          name={generateImagesExportFieldName(parentId)}
          isDisabled={!isParentJobSelected}
          mr={2}
        />
      }
      borderLeft={isOptional ? `2px solid ${theme.colors.purple[100]}` : ''}
    >
      <GridItem colSpan={nbColumns}>
        <DeepTable.Cell pt={1} minHeight={0}>
          <Box>
            <FileNameList
              files={(quoteJobFiles || []).map((jobFile) => jobFile.file as FileItem)}
              readOnly
              openCarousel
            />
          </Box>
        </DeepTable.Cell>
      </GridItem>
    </DeepTable.Row>
  );
};
