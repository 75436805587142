import type { ChangeEvent, FC, ReactNode } from 'react';
import { useCallback } from 'react';
import { FormControl, HStack, VStack } from '@chakra-ui/react';

import { SellsheetCard } from '../Card';
import { QuotationCallout as Callout } from '../../../Callout/Callout';
import { Label } from '../../../Label/Label';
import { QuotationInput } from '../../../Input/Input';
import { quotationCardTranslations } from '../translations';
import { DateInput } from '../../../../Input/DateInput';

type DatesCardProps = {
  date: {
    label: string;
    field: string;
    value?: string | null;
  };
  duration: {
    label: string;
    field: string;
    value?: string | null;
  };
  callout?: ReactNode;
  title: string;
  isEditable?: boolean;
  onChange: (key: string, value: string | number) => void;
};

export const DateCard: FC<DatesCardProps> = ({ date, duration, callout, title, isEditable, onChange }) => {
  const handleValidityDurationChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(duration.field, parseFloat(e.target.value));
    },
    [duration.field, onChange],
  );

  const handleDateChange = useCallback(
    (value: string) => {
      onChange(date.field, value);
    },
    [date.field, onChange],
  );

  return (
    <SellsheetCard title={title}>
      <VStack width="100%" spacing={2} alignItems="flex-start">
        <HStack width="100%" spacing={2} alignItems="center">
          <Label width="50%" mb={0}>
            {date.label}
          </Label>
          <FormControl width="50%">
            <DateInput
              size="sm"
              value={date.value ?? ''}
              onChange={handleDateChange}
              isDisabled={!isEditable}
              width="100%"
              minWidth="100%"
            />
          </FormControl>
        </HStack>
        <HStack width="100%" spacing={2} alignItems="center">
          <Label width="50%" mb={0}>
            {duration.label}
          </Label>
          <HStack alignItems="center" width="50%">
            <FormControl width="2rem">
              <QuotationInput
                defaultValue={duration.value ?? ''}
                onBlur={handleValidityDurationChange}
                isDisabled={!isEditable}
                type="number"
              />
            </FormControl>
            <Label mb={0}>{quotationCardTranslations.global.days}</Label>
          </HStack>
        </HStack>
        {callout && <Callout color="purple">{callout}</Callout>}
      </VStack>
    </SellsheetCard>
  );
};
