import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';

import { RoundLeftIcon, RoundRightIcon } from '../../../Icons';

export interface RoundedComponentProps {
  onRoundLeft: () => void;
  onRoundRight: () => void;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}

export const RoundedComponent: FC<RoundedComponentProps> = ({
  onRoundLeft,
  onRoundRight,
  isDisabled = false,
  isReadOnly = false,
}) => {
  const boxStyle = useMemo(
    () => ({
      bg: 'white',
      borderRadius: 5,
      ml: 2,
      color: isDisabled || isReadOnly ? 'gray.300' : 'gray.500',
      cursor: isDisabled || isReadOnly ? 'not-allowed' : 'pointer',
      boxSizing: 'border-box',
      border: '1px solid white',
    }),
    [isDisabled, isReadOnly],
  );

  const boxHoverStyle = useMemo(
    () =>
      isDisabled || isReadOnly
        ? {}
        : {
            border: '1px solid #829AB1',
          },
    [isDisabled, isReadOnly],
  );

  const handleRoundLeft = useCallback(() => {
    if (!isDisabled && !isReadOnly) {
      onRoundLeft();
    }
  }, [isDisabled, isReadOnly, onRoundLeft]);

  const handleRoundRight = useCallback(() => {
    if (!isDisabled && !isReadOnly) {
      onRoundRight();
    }
  }, [isDisabled, isReadOnly, onRoundRight]);

  return (
    <>
      <Box sx={boxStyle} _hover={boxHoverStyle} onClick={handleRoundLeft}>
        <RoundLeftIcon h="36px" w="33px" />
      </Box>
      <Box sx={boxStyle} _hover={boxHoverStyle} onClick={handleRoundRight}>
        <RoundRightIcon h="36px" w="33px" />
      </Box>
    </>
  );
};
