import { useCallback } from 'react';

import { useAddJobInLotAfterJob } from './useAddJobInLotAfterJob';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Adds a job in last position
 */
export const useAddJob = () => {
  const { getCurrentQuoteData } = useQuoteEditContext();
  const addJobInLotAfterJob = useAddJobInLotAfterJob();

  return useCallback(
    async (lotId: number, isParentOptional: boolean, isParentHiddenCost: boolean) => {
      const { relations } = getCurrentQuoteData();
      const jobsOfLot = relations[lotId]?.jobs;
      const lastJobId = jobsOfLot ? jobsOfLot[jobsOfLot.length - 1] : undefined;
      await addJobInLotAfterJob(lotId, lastJobId, isParentOptional, isParentHiddenCost);
    },
    [addJobInLotAfterJob, getCurrentQuoteData],
  );
};
