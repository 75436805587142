import type {
  IOrder,
  IProject,
  ISupplierInvoice,
  ISupplierInvoiceAssociatedProjectDTO,
  ISupplierInvoiceCreateBaseInformationDTO,
  ISupplierInvoiceCreateDTO,
  ISupplierInvoiceItemsDTO,
  ISupplierInvoiceOCRResponse,
  ISupplierInvoiceUpdateDTO,
  RequiredByKeys,
  SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT,
} from '@graneet/business-logic';
import {
  canSupplierInvoiceBeDeleted,
  canSupplierInvoiceBeUpdated,
  divideFloating,
  isDirectPaymentSupplierInvoice as isDirectPaymentSupplierInvoiceFn,
  mapSupplierInvoiceOCRResponseToSupplierInvoice,
  SUPPLIER_INVOICE_MODE,
  VAT_TYPE,
} from '@graneet/business-logic';
import {
  formatDateToString,
  formatNumberToVatRate,
  formatVatRateToNumber,
  isNumberFinite,
  multiplyFloating,
} from '@graneet/lib-ui';
import { compact, differenceBy, isNil, uniqBy } from 'lodash-es';
import type { TFunction } from 'i18next';
import { v4 } from 'uuid';

import type { EditSupplierInvoiceForm } from '../forms/edit-supplier-invoice.form';
import {
  buildSupplierInvoiceItemCodeFieldName,
  buildSupplierInvoiceItemQuantityFieldName,
  buildSupplierInvoiceItemUnitAmountExVATFieldName,
  buildSupplierInvoiceItemUnitFieldName,
  buildSupplierInvoiceItemProjectFieldName,
  buildSupplierInvoiceItemPurchasesAccountFieldName,
  buildSupplierInvoiceItemAmountExVATFieldName,
  buildSupplierInvoiceItemDescriptionFieldName,
  buildSupplierInvoiceItemTypeFieldName,
  buildSupplierInvoiceItemVatRateFieldName,
  buildProjectAmountExVATFieldName,
  buildProjectDistributionFieldName,
} from '../forms/edit-supplier-invoice.form';
import { formatFilePreviewUrl } from '../../file/services/file.util';

export function mapFormToSupplierInvoiceItemDTO(
  formValues: Partial<EditSupplierInvoiceForm>,
  mapAmountToNumber: (value: string | number) => number,
): Array<ISupplierInvoiceItemsDTO> {
  const { itemIds } = formValues;

  return (itemIds || []).map((itemId) => {
    const unitAmountExVAT = formValues[buildSupplierInvoiceItemUnitAmountExVATFieldName(itemId)];
    const amountExVAT = formValues[buildSupplierInvoiceItemAmountExVATFieldName(itemId)];
    const vatRate = formValues[buildSupplierInvoiceItemVatRateFieldName(itemId)];
    return {
      id: itemId,
      description: formValues[buildSupplierInvoiceItemDescriptionFieldName(itemId)] ?? '',
      code: formValues[buildSupplierInvoiceItemCodeFieldName(itemId)] ?? undefined,
      unitAmountExVAT: isNumberFinite(unitAmountExVAT) ? mapAmountToNumber(unitAmountExVAT) : undefined,
      quantity: formValues[buildSupplierInvoiceItemQuantityFieldName(itemId)] ?? undefined,
      unit: formValues[buildSupplierInvoiceItemUnitFieldName(itemId)] ?? undefined,
      amountExVAT: isNumberFinite(amountExVAT) ? mapAmountToNumber(amountExVAT) : undefined,
      vatRate: isNumberFinite(vatRate) ? formatVatRateToNumber(vatRate) : undefined,
      projectId: formValues[buildSupplierInvoiceItemProjectFieldName(itemId)] ?? undefined,
      typeId: formValues[buildSupplierInvoiceItemTypeFieldName(itemId)] ?? undefined,
      accountingConfigPurchasesAccountId:
        formValues[buildSupplierInvoiceItemPurchasesAccountFieldName(itemId)] ?? undefined,
    };
  });
}

export const mapCreateSupplierInvoiceDTO = (
  formValues: EditSupplierInvoiceForm,
  mapAmountToNumber: (value: string | number) => number,
): ISupplierInvoiceCreateDTO => {
  const {
    name,
    invoiceNumber,
    typeId,
    supplierId,
    vatRate,
    amountExVAT,
    billingDate,
    paymentDate,
    comment,
    associatedProjects,
    associatedOrders,
    accountingConfigPurchasesAccountId,
    mode,
    vatType,
  } = formValues;

  const associatedProjectsDTO = (associatedProjects || []).map<ISupplierInvoiceAssociatedProjectDTO>((project) => {
    const projectAmountExVAT = formValues[buildProjectAmountExVATFieldName(project)];
    const projectDistribution = formValues[buildProjectDistributionFieldName(project)];

    return {
      projectId: project.id,
      amountExVAT: mapAmountToNumber(projectAmountExVAT),
      percentage: divideFloating(projectDistribution, 100),
    };
  });

  const baseInformation: ISupplierInvoiceCreateBaseInformationDTO = {
    name,
    invoiceNumber,
    billingDate,
    paymentDate: paymentDate ?? undefined,
    comment: comment ?? undefined,
    supplierId: supplierId.toString(),
    associatedOrders: JSON.stringify((associatedOrders || []).map((o) => o.id)),
  };

  if (mode === SUPPLIER_INVOICE_MODE.DETAILED) {
    return {
      ...baseInformation,
      mode: SUPPLIER_INVOICE_MODE.DETAILED,
      vatType,
      items: JSON.stringify(mapFormToSupplierInvoiceItemDTO(formValues, mapAmountToNumber)),
    };
  }

  return {
    ...baseInformation,
    mode: SUPPLIER_INVOICE_MODE.CONDENSED,
    vatType,
    amountExVAT: mapAmountToNumber(amountExVAT).toString(),
    vatRate: Number.isFinite(vatRate) ? formatVatRateToNumber(vatRate!).toString() : undefined,
    typeId: typeId.toString(),
    associatedProjects: JSON.stringify(associatedProjectsDTO),
    accountingConfigPurchasesAccountId: accountingConfigPurchasesAccountId ?? undefined,
  };
};

export const mapUpdateSupplierInvoiceDTO = (
  formValues: Partial<EditSupplierInvoiceForm>,
  mapAmountToNumber: (value: string | number) => number,
  supplierInvoice?: ISupplierInvoice | null,
  initialAssociatedOrders?: IOrder[],
): ISupplierInvoiceUpdateDTO => {
  const {
    name,
    invoiceNumber,
    typeId,
    supplierId,
    vatRate,
    amountExVAT,
    billingDate,
    paymentDate,
    comment,
    deletedReceiptFilesIds,
    deleteInvoiceFile,
    associatedProjects,
    associatedOrders,
    accountingConfigPurchasesAccountId,
    mode,
    vatType,
  } = formValues;

  const isEditable = isDirectPaymentSupplierInvoiceFn(supplierInvoice);

  const associatedProjectsDTO = associatedProjects?.map<ISupplierInvoiceAssociatedProjectDTO>((project) => {
    const projectAmountExVAT = formValues[buildProjectAmountExVATFieldName(project)];
    const projectDistribution = formValues[buildProjectDistributionFieldName(project)];

    return {
      projectId: project.id,
      amountExVAT: mapAmountToNumber(projectAmountExVAT!),
      percentage: divideFloating(projectDistribution!, 100),
    };
  });

  const associatedOrdersDTO = associatedOrders?.map(({ id }) => id);
  const deletedOrdersDTO = associatedOrders
    ? differenceBy(initialAssociatedOrders, associatedOrders, 'id').map(({ id }) => id)
    : undefined;

  const isDetailed = mode === SUPPLIER_INVOICE_MODE.DETAILED;

  const editableValuesWithoutDirectPayment = !isEditable
    ? {
        supplierId: supplierId?.toString(),
        associatedOrders: associatedOrdersDTO ? JSON.stringify(associatedOrdersDTO) : undefined,
        deletedOrders: deletedOrdersDTO ? JSON.stringify(deletedOrdersDTO) : undefined,
        associatedProjects: associatedProjectsDTO ? JSON.stringify(associatedProjectsDTO) : undefined,
      }
    : {};

  return {
    name,
    mode,
    vatType,
    invoiceNumber,
    typeId: isDetailed ? undefined : typeId?.toString(),
    vatRate: !isDetailed && vatRate !== undefined ? formatVatRateToNumber(vatRate).toString() : undefined,
    amountExVAT: !isDetailed && Number.isFinite(amountExVAT) ? mapAmountToNumber(amountExVAT!).toString() : undefined,
    items: isDetailed ? JSON.stringify(mapFormToSupplierInvoiceItemDTO(formValues, mapAmountToNumber)) : undefined,
    billingDate,
    paymentDate: paymentDate === null ? 'null' : (paymentDate ?? undefined),
    comment: comment || undefined,
    deletedReceiptFilesIds: JSON.stringify(deletedReceiptFilesIds || []),
    deleteInvoiceFile: deleteInvoiceFile ? 'true' : 'false',
    accountingConfigPurchasesAccountId,
    ...editableValuesWithoutDirectPayment,
  };
};

export const mapSupplierInvoiceToFormValues = (
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT | null,
  project: IProject | undefined,
  mapNumberToAmount: (value: number) => number,
  order?: IOrder,
): Partial<EditSupplierInvoiceForm> => {
  const emptyInitValues: Partial<EditSupplierInvoiceForm> = {};
  // If supplierInvoice is null, we return an empty object with empty array initialization for associatedProjects
  if (!supplierInvoice) {
    emptyInitValues.associatedProjects = [];
    emptyInitValues.associatedOrders = [];
    emptyInitValues.mode = SUPPLIER_INVOICE_MODE.CONDENSED;
    emptyInitValues.vatType = VAT_TYPE.NORMAL;
    emptyInitValues.itemIds = [v4()];

    /**
     * If project is defined, we add it to the associatedProjects array
     * It could only be the case when we create a supplier invoice in a project context or from an existing order
     * So it could only be occurred in creation context, where we don't have supplierInvoice
     */
    const projetToPrefill = (project ?? order?.project) as RequiredByKeys<IProject, 'primaryClient'>;
    if (projetToPrefill) {
      emptyInitValues.associatedProjects.push(projetToPrefill);
      emptyInitValues[buildProjectDistributionFieldName(projetToPrefill)] = 100;
    }

    if (order) {
      emptyInitValues.name = order.name;
      emptyInitValues.typeId = order.type?.id;
      emptyInitValues.supplierId = order.supplier?.id;
      emptyInitValues.amountExVAT = isNil(order.amountExVAT) ? undefined : mapNumberToAmount(order.amountExVAT);
      emptyInitValues.vatType = order.vatType;
      emptyInitValues.vatRate = order.vatDistribution[0] ? multiplyFloating(order.vatDistribution[0].vatRate, 100) : 0;

      emptyInitValues.associatedOrders = [order];
    }

    return emptyInitValues;
  }

  const initialReceiptFiles = compact((supplierInvoice?.supplierInvoiceFiles || []).map((f) => f.file));

  const itemIds = supplierInvoice.supplierInvoiceItems.length
    ? supplierInvoice.supplierInvoiceItems.map((item) => item.id)
    : [v4()];
  const itemsData = supplierInvoice.supplierInvoiceItems.reduce<Partial<EditSupplierInvoiceForm>>((acc, item) => {
    acc[buildSupplierInvoiceItemDescriptionFieldName(item.id)] = item.description;
    acc[buildSupplierInvoiceItemCodeFieldName(item.id)] = item.code;
    acc[buildSupplierInvoiceItemUnitAmountExVATFieldName(item.id)] = isNumberFinite(item.unitAmountExVAT)
      ? mapNumberToAmount(item.unitAmountExVAT)
      : undefined;
    acc[buildSupplierInvoiceItemQuantityFieldName(item.id)] = item.quantity ?? undefined;
    acc[buildSupplierInvoiceItemUnitFieldName(item.id)] = item.unit;
    acc[buildSupplierInvoiceItemAmountExVATFieldName(item.id)] = isNumberFinite(item.amountExVAT)
      ? mapNumberToAmount(item.amountExVAT)
      : undefined;
    acc[buildSupplierInvoiceItemVatRateFieldName(item.id)] = isNumberFinite(item.vatRate)
      ? formatNumberToVatRate(item.vatRate)
      : undefined;
    acc[buildSupplierInvoiceItemProjectFieldName(item.id)] = item.project?.id;
    acc[buildSupplierInvoiceItemTypeFieldName(item.id)] = item.type?.id ?? undefined;
    acc[buildSupplierInvoiceItemPurchasesAccountFieldName(item.id)] =
      item.accountingConfigPurchasesAccount?.id ?? undefined;

    return acc;
  }, {});

  // Mapping of the supplier invoice data
  const initValues: Partial<EditSupplierInvoiceForm> = {
    ...supplierInvoice,
    name: supplierInvoice.name ?? undefined,
    invoiceNumber: supplierInvoice.invoiceNumber ?? undefined,
    initialReceiptFiles,
    receiptFiles: [],
    deletedReceiptFilesIds: [],
    comment: supplierInvoice.comment || '',
    mode: supplierInvoice.mode,
    vatType: supplierInvoice.vatType,
    vatRate: supplierInvoice.vatDistribution.length
      ? formatNumberToVatRate(supplierInvoice.vatDistribution[0].vatRate)
      : 0,
    amountExVAT: isNumberFinite(supplierInvoice.amountExVAT)
      ? mapNumberToAmount(supplierInvoice.amountExVAT)
      : undefined,
    typeId: supplierInvoice.type?.id,
    supplierId: supplierInvoice.supplier?.id,
    accountingConfigPurchasesAccountId: supplierInvoice.accountingConfigPurchasesAccount?.id,
    billingDate: formatDateToString(supplierInvoice.billingDate) || '',
    paymentDate: formatDateToString(supplierInvoice.paymentDate) || '',
    invoiceFile: supplierInvoice.invoiceFile
      ? {
          fileURL: formatFilePreviewUrl(supplierInvoice.invoiceFile.id),
          mimeType: supplierInvoice.invoiceFile.mimeType,
        }
      : undefined,
    deletedOrders: [],
    associatedProjects:
      supplierInvoice.supplierInvoiceProjects?.map((suppInvoiceProject) => ({
        ...(suppInvoiceProject.project as RequiredByKeys<IProject, 'primaryClient'>),
      })) || [],

    associatedOrders:
      supplierInvoice?.ordersSupplierInvoices?.map((ordersSupplierInvoices) => ordersSupplierInvoices.order) || [],
    pdfPageNumber: 0,

    itemIds,
    ...itemsData,
  };

  // Mapping of the associated projects
  supplierInvoice.supplierInvoiceProjects?.forEach((suppInvoiceProject) => {
    initValues[buildProjectAmountExVATFieldName(suppInvoiceProject.project!)] = mapNumberToAmount(
      suppInvoiceProject.amountExVAT,
    );
    initValues[buildProjectDistributionFieldName(suppInvoiceProject.project!)] = multiplyFloating(
      suppInvoiceProject.percentage,
      100,
    );
  });

  return initValues;
};

export const mapSupplierInvoiceOCRResponseToFormValues = (
  supplierInvoiceOCRResponse: ISupplierInvoiceOCRResponse,
  mapNumberToAmount: (value: number) => number,
  associatedProjects?: RequiredByKeys<IProject, 'primaryClient'>[],
  associatedOrders?: IOrder[],
): Partial<EditSupplierInvoiceForm> => {
  const partialSupplierInvoice = mapSupplierInvoiceOCRResponseToSupplierInvoice(supplierInvoiceOCRResponse);

  const itemIds = partialSupplierInvoice.items?.map((item) => item.id);
  const itemsValues = (partialSupplierInvoice.items ?? []).reduce<Partial<EditSupplierInvoiceForm>>((acc, item) => {
    acc[buildSupplierInvoiceItemDescriptionFieldName(item.id)] = item.description;
    acc[buildSupplierInvoiceItemCodeFieldName(item.id)] = item.code;
    acc[buildSupplierInvoiceItemUnitAmountExVATFieldName(item.id)] = isNumberFinite(item.unitAmountExVAT)
      ? mapNumberToAmount(item.unitAmountExVAT)
      : undefined;
    acc[buildSupplierInvoiceItemQuantityFieldName(item.id)] = item.quantity;
    acc[buildSupplierInvoiceItemAmountExVATFieldName(item.id)] = isNumberFinite(item.amountExVAT)
      ? mapNumberToAmount(item.amountExVAT)
      : undefined;
    acc[buildSupplierInvoiceItemVatRateFieldName(item.id)] = isNumberFinite(item.vatRate)
      ? multiplyFloating(item.vatRate, 100)
      : undefined;

    return acc;
  }, {});

  return {
    invoiceNumber: partialSupplierInvoice.invoiceNumber ?? undefined,
    billingDate: partialSupplierInvoice.billingDate
      ? formatDateToString(partialSupplierInvoice.billingDate)
      : undefined,
    paymentDate: partialSupplierInvoice.paymentDate
      ? formatDateToString(partialSupplierInvoice.paymentDate)
      : undefined,
    vatRate: isNumberFinite(partialSupplierInvoice.vatRate)
      ? multiplyFloating(partialSupplierInvoice.vatRate, 100)
      : undefined,
    amountExVAT: isNumberFinite(partialSupplierInvoice.amountExVAT)
      ? mapNumberToAmount(partialSupplierInvoice.amountExVAT)
      : undefined,
    supplierId: partialSupplierInvoice.supplier?.id,
    ...(partialSupplierInvoice.project && !associatedProjects?.length
      ? {
          associatedProjects: [partialSupplierInvoice.project],
          [buildProjectDistributionFieldName(partialSupplierInvoice.project)]: isNumberFinite(
            partialSupplierInvoice.amountExVAT,
          )
            ? 100
            : 0,
        }
      : {}),
    associatedOrders: uniqBy([...(partialSupplierInvoice.orders ?? []), ...(associatedOrders ?? [])], 'id'),
    itemIds,
    ...itemsValues,
  };
};

export const getSupplierInvoiceBeEdited = (
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT,
  t: TFunction<['supplierInvoices', 'global']>,
  options = { allowDirectPaymentEdition: false },
) => {
  const state = canSupplierInvoiceBeUpdated(supplierInvoice);

  if (state.causes.includes('accounting')) {
    return {
      isDisabled: true,
      tooltip: t('supplierInvoices:tooltips.cannotUpdateBecauseOfAccounting'),
    };
  }
  if (
    state.causes.includes('is-direct-payment-with-non-draft-progress-statement') &&
    !options.allowDirectPaymentEdition
  ) {
    return {
      isDisabled: true,
      tooltip: undefined,
    };
  }
  if (state.causes.length > 0 && !options.allowDirectPaymentEdition) {
    throw new Error('Unhandled error');
  }

  return {
    isDisabled: false,
    tooltip: undefined,
  };
};

export const getSupplierInvoiceBeDeleted = (
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT,
  t: TFunction<['project', 'supplierInvoices', 'global']>,
) => {
  const state = canSupplierInvoiceBeDeleted(supplierInvoice);

  if (state.causes.includes('accounting')) {
    return {
      isDisabled: true,
      tooltip: t('supplierInvoices:cards.delete.cannotDeleteBecauseAccountingTooltip'),
    };
  }
  if (state.causes.includes('is-direct-payment-with-non-draft-progress-statement')) {
    return {
      isDisabled: false,
      tooltip: undefined,
    };
  }
  if (state.causes.length > 0) {
    throw new Error('Unhandled issue');
  }

  return {
    isDisabled: false,
    tooltip: undefined,
  };
};
