import type {
  PROGRESS_STATEMENT_SUMMARY_TEMPLATES,
  PROGRESS_STATEMENT_TEMPLATES,
  SUB_PROJECT_TYPES,
} from '@graneet/business-logic';

// Custom holdback fields name
export const PREFIX_CUSTOM_HOLDBACK_NAME = 'custom-holdback-name';

export type CustomHoldbackNameFieldName = `custom-holdback-name_${string}`;

export const getCustomHoldbackNameField = (id: string): CustomHoldbackNameFieldName =>
  `${PREFIX_CUSTOM_HOLDBACK_NAME}_${id}`;

export const isCustomHoldbackNameField = (fieldName: string): fieldName is CustomHoldbackNameFieldName =>
  fieldName.startsWith(PREFIX_CUSTOM_HOLDBACK_NAME);

// Custom holdback fields percentage
export const PREFIX_CUSTOM_HOLDBACK_PERCENTAGE = 'custom-holdback-percentage';

export type CustomHoldbackPercentageFieldPercentage = `custom-holdback-percentage_${string}`;

export const getCustomHoldbackPercentageField = (id: string): CustomHoldbackPercentageFieldPercentage =>
  `${PREFIX_CUSTOM_HOLDBACK_PERCENTAGE}_${id}`;

export const isCustomHoldbackPercentageField = (
  fieldName: string,
): fieldName is CustomHoldbackPercentageFieldPercentage => fieldName.startsWith(PREFIX_CUSTOM_HOLDBACK_PERCENTAGE);

export const getCustomHoldbackFormValues = (billingParams: ProjectEditParametersForm) => {
  const customHoldbacks: {
    [id: string]: {
      id: number;
      name: string;
      percentage: number;
    };
  } = {};

  Object.keys(billingParams).forEach((key) => {
    if (!isCustomHoldbackNameField(key) && !isCustomHoldbackPercentageField(key)) {
      return;
    }

    const id = key.split('_')[1];

    if (isCustomHoldbackNameField(key)) {
      customHoldbacks[id] = {
        ...customHoldbacks[id],
        id: parseInt(id, 10),
        name: billingParams[key],
      };
    }

    if (isCustomHoldbackPercentageField(key)) {
      customHoldbacks[id] = {
        ...customHoldbacks[id],
        percentage: billingParams[key],
      };
    }

    // eslint-disable-next-line no-param-reassign
    delete billingParams[key];
  });
  return customHoldbacks;
};
export interface ProjectEditParametersForm {
  type: SUB_PROJECT_TYPES;

  progressStatementTemplate: PROGRESS_STATEMENT_TEMPLATES;

  summaryTemplate: PROGRESS_STATEMENT_SUMMARY_TEMPLATES;

  holdbackHasPaymentGuarantee: boolean;

  holdbackBankName?: string;

  deductionPercentage?: number;

  deductionVatRate?: number;

  hasPriceRevision: boolean;

  deductionName?: string;

  holdbackPercentage?: number;

  holdbackPaymentGuaranteeAmount?: number;

  [customHoldbackName: CustomHoldbackNameFieldName]: string;

  [customHoldbackPercentage: CustomHoldbackPercentageFieldPercentage]: number;
}

export type ProjectEditParametersWizard = {
  editParams: ProjectEditParametersForm;
};
