import { Button, Input, ListingLayout, SimpleSearchIcon, Container } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PERMISSION, PAGINATION_PARAMS } from '@graneet/business-logic';
import { GridItem } from '@chakra-ui/react';

import { useUsersPaginated } from 'features/user/services/user.api';
import { UserTable } from 'features/user/components/UserTable';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { filterOnlyContaining } from 'features/common/services/common.util';
import { useUserInvitations } from 'features/onboarding/services/user-invitation.api';
import { createURLSearchParams } from 'features/api/services/api.util';

export const ViewUsersSettingsTab: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['global', 'user']);

  const [search, setSearch] = useState('');

  const isAdmin = usePermissions([PERMISSION.ADMIN]);

  const createUserButtonDisabledProps = useDisabledButtonProps([PERMISSION.ADMIN]);

  const userInvitations = useUserInvitations();

  const filteredUserInvitations = useMemo(
    () => filterOnlyContaining(userInvitations.data, ['email'], search),
    [search, userInvitations.data],
  );

  const { data: usersData } = useUsersPaginated(
    createURLSearchParams({
      [PAGINATION_PARAMS.FULL]: true,
    }),
  );

  const filteredUsers = useMemo(
    () => filterOnlyContaining(usersData.data, ['email', 'firstName', 'lastName'], search),
    [search, usersData],
  );

  const goToAddUser = useCallback(() => {
    history.push(`/users/invite`);
  }, [history]);

  return (
    <Container>
      <ListingLayout h="auto">
        <GridItem flex={1} alignSelf="center">
          <Input
            leftIcon={<SimpleSearchIcon />}
            inputProps={{
              width: '320px',
              borderWidth: '1px',
              borderColor: 'gray.200',
              background: 'white',
              borderRadius: '8px',
              height: '32px',
              fontSize: 'sm',
              _focus: {
                borderColor: 'gray.200',
                outline: 'none',
                boxShadow: '0px 2px 5px -2px rgba(0, 0, 0, 0.06),0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
              },
              _invalid: { borderColor: 'red.500' },
            }}
            onChange={setSearch}
            value={search}
            placeholder={t('global:words.c.research')}
          />
        </GridItem>
        {isAdmin && (
          <ListingLayout.Actions>
            <Button onClick={goToAddUser} {...createUserButtonDisabledProps()}>
              {t('user:inviteUser')}
            </Button>
          </ListingLayout.Actions>
        )}

        <ListingLayout.Content>
          <UserTable isAdmin={isAdmin} users={filteredUsers || []} userInvitations={filteredUserInvitations} />
        </ListingLayout.Content>
      </ListingLayout>
    </Container>
  );
};
