import type dayjs from 'dayjs';
import { Flex, Grid, HStack, IconButton, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import { useTimeTableContext } from '../../contexts';
import { SimpleChevronLeftIcon } from '../../../../Icons';

import { TimeTableHeaderDay } from './TimeTableHeaderDay';

interface TimeTableHeaderProps {
  onPreviousWeek(): void;
  onNextWeek(): void;
  currentStartOfWeek: dayjs.Dayjs;
}

export const TimeTableHeader = ({ onPreviousWeek, onNextWeek, currentStartOfWeek }: TimeTableHeaderProps) => {
  const { numberOfDays } = useTimeTableContext();

  const title = useMemo(() => {
    const lastWeekDay = currentStartOfWeek.add((numberOfDays ?? 1) - 1, 'day');
    const isLastWeekDayOnSameMonth = lastWeekDay.isSame(currentStartOfWeek, 'month');

    if (!isLastWeekDayOnSameMonth) {
      return `${currentStartOfWeek.format('MMM')} - ${lastWeekDay.format('MMM YYYY')}`;
    }

    return currentStartOfWeek.format('MMMM YYYY');
  }, [currentStartOfWeek, numberOfDays]);

  const days = useMemo(() => {
    if (!numberOfDays) {
      return [];
    }
    const weekDays = [];
    for (let i = 0; i < numberOfDays; i += 1) {
      weekDays.push(currentStartOfWeek.clone().add(i, 'day'));
    }
    return weekDays;
  }, [currentStartOfWeek, numberOfDays]);

  return (
    <Flex direction="row" alignItems="center">
      <HStack w="18rem">
        <Flex>
          <IconButton
            variant="clear"
            icon={<SimpleChevronLeftIcon boxSize={4} />}
            onClick={onPreviousWeek}
            aria-label="previous"
            color="gray.500"
          />
          <IconButton
            color="gray.500"
            variant="clear"
            icon={<SimpleChevronLeftIcon boxSize={4} transform="rotate(180deg)" />}
            onClick={onNextWeek}
            aria-label="next"
          />
        </Flex>

        <Text fontSize="l" fontWeight="600" textTransform="capitalize" color="gray.700">
          {title}
        </Text>
      </HStack>

      <Grid alignItems="flex-start" templateColumns={`repeat(${days.length}, 1fr)`} w="100%">
        {days.map((day) => (
          <TimeTableHeaderDay day={day} key={day.toISOString()} />
        ))}
      </Grid>
    </Flex>
  );
};
