import type { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import type { DrawerApi } from '@graneet/lib-ui';
import { Button, RadioGroupField, DrawersStack } from '@graneet/lib-ui';
import { DISCOUNT_TYPES } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { useForm, useFormStatus } from 'graneet-form';

import type { DiscountEditionForm } from '../forms/discount-edition-form';

import { DiscountTotals } from './DiscountTotals';

interface DiscountEditDrawerProps {
  title: string;

  description: string;

  totalBeforeDiscountExVAT: number;

  drawer: DrawerApi<string>;

  onSubmit(): void;

  submitLabel: string;
}

/**
 * Drawer to edit a discount.
 *
 * Internally, form type is `DiscountEditionForm`
 */
export const DiscountEditDrawer: FC<DiscountEditDrawerProps> = ({
  title,
  description,
  totalBeforeDiscountExVAT,
  drawer,
  onSubmit,
  submitLabel,
}) => {
  const { t } = useTranslation(['discount']);

  const form = useForm<DiscountEditionForm>();
  const { isValid: isFormValid } = useFormStatus(form);

  return (
    <DrawersStack.Drawer
      title={title}
      drawer={drawer}
      footer={
        <Flex justify="flex-end" w="100vw">
          <Button onClick={onSubmit} isDisabled={!isFormValid}>
            {submitLabel}
          </Button>
        </Flex>
      }
    >
      <Text>{description}</Text>
      <Flex direction="column">
        <Box mt={4}>{t('discount:discountTypeLabel')}</Box>

        <Box mt={4} ml={2}>
          <RadioGroupField<DiscountEditionForm> name="discountType">
            <RadioGroupField.Option value={DISCOUNT_TYPES.PERCENTAGE} label={t('discount:percentageType')} />
            <RadioGroupField.Option value={DISCOUNT_TYPES.AMOUNT} label={t('discount:amountType')} />
          </RadioGroupField>
        </Box>
      </Flex>

      <Box mt={8}>
        <DiscountTotals totalBeforeDiscountExVAT={totalBeforeDiscountExVAT} />
      </Box>
    </DrawersStack.Drawer>
  );
};
