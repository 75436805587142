import type { FC } from 'react';
import { useCallback } from 'react';
import { StepperModal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { LEDGER_TYPES } from '@graneet/business-logic';
import { VStack } from '@chakra-ui/react';

import type { ImportFileStepForm } from './ImportFileStep';
import { ImportFileStep } from './ImportFileStep';
import type { QuoteInformationStepForm } from './QuoteInformationStep';
import { QuoteInformationStep } from './QuoteInformationStep';

import { useData } from 'features/api/hooks/useData';
import { useCompanyQuoteCredit } from 'features/company/services/company.api';
import { getNextLedgerStatePreview } from 'features/ledger/services/ledger.api';

export type QuoteImportWizard = {
  FILE: ImportFileStepForm;

  INFORMATION: QuoteInformationStepForm;
};

interface CreateQuoteFromImportModalProps {
  isOpen: boolean;

  onClose(): void;

  onImportCompleted(): void;

  onQuoteCreated(values: { name: string }): Promise<void>;

  projectId?: number;
}

export const CreateQuoteFromImportModal: FC<CreateQuoteFromImportModalProps> = ({
  isOpen,
  onClose,
  onImportCompleted,
  projectId,
  onQuoteCreated,
}) => {
  const { t } = useTranslation(['quote', 'global']);

  const getLedgerStatePreviewData = useCallback(() => getNextLedgerStatePreview({ type: LEDGER_TYPES.QUOTE }), []);

  const ledgerStatePreviewData = useData(getLedgerStatePreviewData);
  const { fetch: reloadLedgerStatePreviewData } = ledgerStatePreviewData;

  const companyQuoteCredit = useCompanyQuoteCredit();

  const handleSubmit = useCallback(async () => {
    onImportCompleted();
    await reloadLedgerStatePreviewData();
    await companyQuoteCredit.refetch();
  }, [companyQuoteCredit, onImportCompleted, reloadLedgerStatePreviewData]);

  return (
    <StepperModal<QuoteImportWizard> isOpen={isOpen} onClose={onClose} size="2xl">
      <StepperModal.Step<QuoteImportWizard> name="FILE" title={t('quote:importModal.steps.FILE')}>
        <VStack gap={6} justifySelf="stretch" w="100%">
          <ImportFileStep quoteCredits={companyQuoteCredit.data} onQuoteCreated={onQuoteCreated} />
        </VStack>
      </StepperModal.Step>

      <StepperModal.Step<QuoteImportWizard> name="INFORMATION" title={t('quote:importModal.steps.INFORMATION')}>
        <QuoteInformationStep projectId={projectId} onClose={onClose} onImportCompleted={handleSubmit} />
      </StepperModal.Step>
    </StepperModal>
  );
};
