import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { memo, useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { QuotationInput } from '@graneet/lib-ui';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const CodeCell = memo(
  (params: CustomCellRendererProps<QuoteNodeObject>) => {
    const { data, node } = params;

    const { quote } = useQuote();

    const color = useMemo(() => {
      if (data?.content.type === 'QuoteSubItem' || data?.content.type === 'QuoteComponent') {
        return '#6C737F';
      }
      return '#1F2A37';
    }, [data?.content.type]);

    const isAutomaticIndexActivated = useMemo(() => quote?.getIsAutomaticIndexActivated(), [quote]);

    return (
      <HStack px="0.5rem" pl={`${node.level * 0.625}rem`} alignItems="center" py="8px">
        <Box fontSize="0.813rem" color={color} fontFamily="Lato" lineHeight="normal" fontWeight={500}>
          <QuotationInput
            isReadOnly
            value={(isAutomaticIndexActivated ? data?.content.automaticIndex : data?.content.manualIndex) ?? ''}
            noStyle
          />
        </Box>
      </HStack>
    );
  },
  (prevProps, nextProps) => {
    if (!prevProps && !nextProps) {
      return true;
    }
    if (!prevProps || !nextProps) {
      return false;
    }
    if (
      prevProps.data?.content.automaticIndex !== nextProps.data?.content.automaticIndex &&
      prevProps.data?.content.manualIndex !== nextProps.data?.content.manualIndex
    ) {
      return false;
    }
    return true;
  },
);
