import type { FieldValues, StepValidator } from 'graneet-form';
import { Step } from 'graneet-form';
import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useInternalWizardContext } from './hooks';

export interface WizardStepProps<WizardValues extends Record<string, FieldValues>, Step extends keyof WizardValues>
  extends BoxProps {
  children: ReactNode;
  name: Step;
  onNext?: StepValidator<WizardValues, Step>;
  noFooter?: boolean;
  title?: string;
  displaySecondaryButton?: boolean;
}

export const WizardStep = <
  WizardValues extends Record<string, FieldValues>,
  Step extends keyof WizardValues = keyof WizardValues,
>({
  children,
  name,
  onNext,
  noFooter,
  title,
  displaySecondaryButton = true,
  ...props
}: WizardStepProps<WizardValues, Step>) => {
  const { setDisplaySecondaryButton } = useInternalWizardContext();
  useEffect(() => {
    setDisplaySecondaryButton(name, displaySecondaryButton);
  }, [displaySecondaryButton, name, setDisplaySecondaryButton]);

  return (
    <Step name={name} onNext={onNext} noFooter={noFooter} title={title}>
      <Box flex={1} py={6} {...props}>
        {children}
      </Box>
    </Step>
  );
};
