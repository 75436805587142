import type { RawExtended } from '../common/entity.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IContractCustomDiscount } from '../custom-discount/custom-discount.type';

import type { IProgressStatement } from './progress-statement.type';

export enum PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES {
  MANUAL = 'MANUAL', // User input
  AUTO = 'AUTO', // Filled based on progress statement cumulative percentage
}

export interface IProgressStatementCustomDiscountLine {
  id: string;

  mode: PROGRESS_STATEMENT_CUSTOM_DISCOUNT_MODES;

  cumulativeProgressPercentage: number;

  cumulativeAmountExVAT: number;

  nonCumulativeAmountExVAT: number;

  progressStatement?: IProgressStatement;

  contractCustomDiscount?: IContractCustomDiscount;

  createdAt: Date;

  updatedAt: Date;
}

export type IProgressStatementCustomDiscountLineToSave = RawExtended<
  RequiredByKeys<IProgressStatementCustomDiscountLine, 'contractCustomDiscount'>,
  'progressStatement'
>;
