import { CheckboxField, ModalSubtitle } from '@graneet/lib-ui';
import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type { RoleFormFields } from '../../constants/role.constant';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const RoleEditModalBudget = () => {
  const { t } = useTranslation(['user']);

  const hasUserPermissionsFeatureFlag = useFeatureFlag(FEATURE_FLAGS.USER_PERMISSIONS);
  if (!hasUserPermissionsFeatureFlag) {
    return null;
  }

  return (
    <Stack direction="column" gap={0}>
      <ModalSubtitle mb={2}>{t('user:editRoleModal.sections.budget')}</ModalSubtitle>

      <Stack direction="column" gap={1} pl={3}>
        <CheckboxField<RoleFormFields> name="createBudget" label={t('user:permissions.CREATE_BUDGET')} />
      </Stack>
    </Stack>
  );
};
