import { useDisclosure } from '@chakra-ui/react';
import { Card } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import type { ISupplierListingDTO } from '@graneet/business-logic';

import { SupplierUpsertModal } from '../modals/SupplierUpsertModal';

import { SupplierCardDetails } from './SupplierCardDetails';

interface SupplierCardProps {
  supplier: ISupplierListingDTO;
}

export const SupplierCard: FC<SupplierCardProps> = ({ supplier }) => {
  const { t } = useTranslation(['supplier', 'global']);

  const supplierEditionModal = useDisclosure();

  return (
    <Card marginBottom="0" padding="inherit" alignSelf="stretch">
      <SupplierCardDetails supplier={supplier} onSupplierCardButtonClicked={supplierEditionModal.onOpen}>
        <SupplierUpsertModal
          isOpen={supplierEditionModal.isOpen}
          onClose={supplierEditionModal.onClose}
          supplier={supplier}
          title={t('supplier:modal.edit.editSupplier')}
          toastSuccessMessage={t('supplier:modal.edit.success')}
          submitButtonLabel={t('global:words.c.update')}
        />
      </SupplierCardDetails>
    </Card>
  );
};
