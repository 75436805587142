import { Flex } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';

import { ExternalPoweredBy } from '../../external/components/ExternalPoweredBy';

export const ProgressStatementExternalFooter: FC<{ children: ReactNode }> = ({ children }) => (
  <Flex alignItems="center" borderTop="1px solid" borderColor="gray.200" p={4} direction="column" gap={4}>
    {children}

    <ExternalPoweredBy />
  </Flex>
);
