import type { FC } from 'react';
import { Chapters, UpsellCard } from '@graneet/lib-ui';
import { FEATURE_FLAGS } from '@graneet/business-logic';
import { Box, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ReminderList } from 'features/reminder/components/ReminderList';
import { useReminders } from 'features/reminder/services/reminder.api';
import { MailingBillRemindersSection } from 'features/mailing/components/MailingBillRemindersSection';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { ReminderConfigureSender } from 'features/reminder/components/ReminderConfigureSender';

export const EmailReminderTab: FC = () => {
  const { t } = useTranslation(['global', 'mailing']);

  const hasBillEmailRemindFF = useFeatureFlag(FEATURE_FLAGS.BILL_EMAIL_REMIND);

  const reminders = useReminders();

  return (
    <Stack spacing={0}>
      {!hasBillEmailRemindFF && (
        <Box mb={2}>
          <UpsellCard description={t('global:reminders.upperPlanProposition')} />
        </Box>
      )}

      <Chapters>
        {hasBillEmailRemindFF && (
          <Chapters.Chapter title={t('global:reminders.settingsCard.sender')} id="sender">
            <ReminderConfigureSender />
          </Chapters.Chapter>
        )}

        {hasBillEmailRemindFF && (
          <Chapters.Chapter title={t('global:reminders.settingsCard.title')} id="reminders">
            <ReminderList reminders={reminders.data} />
          </Chapters.Chapter>
        )}

        <Chapters.Chapter title={t('mailing:remindersCard.title')} id="remind">
          <MailingBillRemindersSection />
        </Chapters.Chapter>
      </Chapters>
    </Stack>
  );
};
