import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { BILL_STATUSES } from '@graneet/business-logic';
import type { FC } from 'react';

import { BILL_STATUSES_STYLE } from '../constants/bill.constant';

export type BillStatusBadgeProps = {
  /**
   * The supplier invoice status for which the badge is displayed
   */
  status: BILL_STATUSES;
};

export const BillStatusBadge: FC<BillStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation(['bill']);

  return (
    <Badge w="auto" minWidth="5.25rem" px={3} colorScheme={BILL_STATUSES_STYLE[status].variantColor}>
      {t(`bill:statuses.${status}`)}
    </Badge>
  );
};
