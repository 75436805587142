import type { FC } from 'react';
import { Icon, type IconProps } from '@chakra-ui/react';

export const HashtagIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.25512 3.41501L4.75512 12.415C4.70512 12.685 4.89012 12.94 5.16512 12.99C5.43512 13.035 5.69012 12.85 5.74012 12.575L7.24012 3.57501C7.28512 3.30001 7.10012 3.04501 6.82512 2.99501C6.55012 2.94501 6.29512 3.13001 6.24512 3.40501L6.25512 3.41501ZM10.2551 3.41501L8.75512 12.415C8.70512 12.685 8.89012 12.94 9.16512 12.99C9.43512 13.035 9.69012 12.85 9.74012 12.575L11.2401 3.57501C11.2851 3.30001 11.1001 3.04501 10.8251 2.99501C10.5501 2.94501 10.2951 3.13001 10.2451 3.40501L10.2551 3.41501ZM12.2451 5.49501H3.74512C3.46512 5.49501 3.24512 5.71501 3.24512 5.99501C3.24512 6.27001 3.46512 6.49501 3.74512 6.49501H12.2451C12.5201 6.49501 12.7451 6.27001 12.7451 5.99501C12.7451 5.71501 12.5201 5.49501 12.2451 5.49501ZM11.7451 9.49501H3.24512C2.96512 9.49501 2.74512 9.71501 2.74512 9.99501C2.74512 10.27 2.96512 10.495 3.24512 10.495H11.7451C12.0201 10.495 12.2451 10.27 12.2451 9.99501C12.2451 9.71501 12.0201 9.49501 11.7451 9.49501Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);
