import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

export const ParagraphIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 15.625C2.5 15.4592 2.56585 15.3003 2.68306 15.1831C2.80027 15.0658 2.95924 15 3.125 15H11.875C12.0408 15 12.1997 15.0658 12.3169 15.1831C12.4342 15.3003 12.5 15.4592 12.5 15.625C12.5 15.7908 12.4342 15.9497 12.3169 16.0669C12.1997 16.1842 12.0408 16.25 11.875 16.25H3.125C2.95924 16.25 2.80027 16.1842 2.68306 16.0669C2.56585 15.9497 2.5 15.7908 2.5 15.625ZM2.5 11.875C2.5 11.7092 2.56585 11.5503 2.68306 11.4331C2.80027 11.3158 2.95924 11.25 3.125 11.25H16.875C17.0408 11.25 17.1997 11.3158 17.3169 11.4331C17.4342 11.5503 17.5 11.7092 17.5 11.875C17.5 12.0408 17.4342 12.1997 17.3169 12.3169C17.1997 12.4342 17.0408 12.5 16.875 12.5H3.125C2.95924 12.5 2.80027 12.4342 2.68306 12.3169C2.56585 12.1997 2.5 12.0408 2.5 11.875ZM2.5 8.125C2.5 7.95924 2.56585 7.80027 2.68306 7.68306C2.80027 7.56585 2.95924 7.5 3.125 7.5H16.875C17.0408 7.5 17.1997 7.56585 17.3169 7.68306C17.4342 7.80027 17.5 7.95924 17.5 8.125C17.5 8.29076 17.4342 8.44973 17.3169 8.56694C17.1997 8.68415 17.0408 8.75 16.875 8.75H3.125C2.95924 8.75 2.80027 8.68415 2.68306 8.56694C2.56585 8.44973 2.5 8.29076 2.5 8.125ZM7.5 4.375C7.5 4.20924 7.56585 4.05027 7.68306 3.93306C7.80027 3.81585 7.95924 3.75 8.125 3.75H16.875C17.0408 3.75 17.1997 3.81585 17.3169 3.93306C17.4342 4.05027 17.5 4.20924 17.5 4.375C17.5 4.54076 17.4342 4.69973 17.3169 4.81694C17.1997 4.93415 17.0408 5 16.875 5H8.125C7.95924 5 7.80027 4.93415 7.68306 4.81694C7.56585 4.69973 7.5 4.54076 7.5 4.375Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);
