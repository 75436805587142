import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleGrid, Flex, Text } from '@chakra-ui/react';
import { Card, LabeledData, GotoLink } from '@graneet/lib-ui';
import type { DEADLINE_TYPE, IPaymentMethod } from '@graneet/business-logic';

import { getPaymentTermI18nKey } from 'features/statement/services/statement.util';

interface PaymentMethodDetailCardProps {
  paymentMethod: IPaymentMethod;

  paymentTerm: number;

  deadlineType: DEADLINE_TYPE;
}

export const PaymentMethodDetailCard: FC<PaymentMethodDetailCardProps> = ({
  paymentMethod,
  paymentTerm,
  deadlineType,
}) => {
  const { t } = useTranslation(['statement', 'paymentMethods']);

  return (
    <Card
      title={
        <Flex>
          <Text mr={4}>{t('statement:cards.paymentTerms')}</Text>
          <GotoLink to="/settings/company/company/payment-methods" label={t('paymentMethods:cta.goToLink')} />
        </Flex>
      }
    >
      <SimpleGrid columns={{ md: 2 }} spacing={4}>
        <LabeledData label={t('paymentMethods:field.name.label')} data={paymentMethod.name} />
        <LabeledData
          label={t('statement:fields.paymentTerm')}
          data={t(getPaymentTermI18nKey(deadlineType), { days: paymentTerm })}
        />
      </SimpleGrid>
    </Card>
  );
};
