import { useMemo } from 'react';

import { getLocalDateFormat } from '../../../../utils/date.util';
import type { MaskInfo } from '../mask.type';

export const useDateMask = (): MaskInfo => {
  const format = getLocalDateFormat();

  const pattern = format.replace('MM', 'm').replace('DD', 'd').replace('YYYY', 'Y');

  return useMemo<MaskInfo>(
    () => ({
      placeholder: format,
      mask: {
        // @ts-ignore
        mask: Date,
        pattern,
        autofix: true,
        overwrite: true,
        // https://github.com/uNmAnNeR/imaskjs/issues/371#issuecomment-720216894 It fixes the issue but nobody knows why
        // @ts-ignore
        validate: () => {},
      },
    }),
    [format, pattern],
  );
};
