import type { TreeDataWithRelations, TreeChanges, TreeComputedValues } from '@graneet/lib-ui';
import { Section, formatDateToString, useCurrency, useCounterLoader, CounterLoaderProvider } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC, MutableRefObject } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Form, useStepForm, HiddenField, useHiddenField, useFormContext } from 'graneet-form';
import type { IContractInfosResponseDTO } from '@graneet/business-logic';
import {
  getDiscountAmountExVAT,
  isSubProjectInDirectBilling,
  isSubProjectInProgressBilling,
} from '@graneet/business-logic';
import { Box, Center } from '@chakra-ui/react';

import { Loading } from 'features/common/components/Loading';
import type {
  ContractItemComputedValue,
  ContractLotComputedValue,
  IContractInfosItemWithUUID,
  IContractInfosLotWithUUID,
} from 'features/contract/types/contract.type';
import type { ContractEditionForm } from 'features/contract/forms/contract-edition.form';
import { formatCustomDiscountsToAmountType } from 'features/contract/services/contract.util';
import { ContractEditionCard } from 'features/contract/components/ContractEditionField';
import { ContractEditionTable } from 'features/contract/components/ContractEditionTable';
import type { ContractEditionWizardForm } from 'features/contract/forms/contract-edition-wizard.form';

const HiddenFields = memo(() => {
  const form = useFormContext<ContractEditionForm>();
  const discountAmountHiddenField = useHiddenField(form, 'discountAmountExVAT');
  const hasReversalOfLiabilityHiddenField = useHiddenField(form, 'hasReversalOfLiability');
  const hasDiscountBeenUpdatedHiddenField = useHiddenField(form, 'hasDiscountBeenUpdated');
  const customDiscountsHiddenField = useHiddenField(form, 'customDiscounts');

  return (
    <>
      <HiddenField {...discountAmountHiddenField} />
      <HiddenField {...hasReversalOfLiabilityHiddenField} />
      <HiddenField {...hasDiscountBeenUpdatedHiddenField} />
      <HiddenField {...customDiscountsHiddenField} />
    </>
  );
});

interface EditContainerStepProps {
  contractInfos: IContractInfosResponseDTO;

  getCurrentTreeRef: MutableRefObject<
    () => TreeDataWithRelations<IContractInfosLotWithUUID, IContractInfosItemWithUUID>
  >;

  getTreeChangesRef: MutableRefObject<() => TreeChanges<IContractInfosLotWithUUID, IContractInfosItemWithUUID>>;

  getComputedValuesRef: MutableRefObject<
    () => TreeComputedValues<
      IContractInfosLotWithUUID,
      IContractInfosItemWithUUID,
      ContractLotComputedValue,
      ContractItemComputedValue
    >
  >;
}

export const EditContainerStep: FC<EditContainerStepProps> = ({
  contractInfos,
  getCurrentTreeRef,
  getTreeChangesRef,
  getComputedValuesRef,
}) => {
  const { t } = useTranslation(['contracts']);
  const { form } = useStepForm<ContractEditionWizardForm, 'editContainer'>();
  const { mapNumberToAmount } = useCurrency();

  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const loadingTableCtx = useCounterLoader(setIsFullyLoaded);
  const { updateRowsCount } = loadingTableCtx;

  const editionSectionDescriptionTranslation = useMemo(() => {
    if (!contractInfos) return '';

    if (!contractInfos.areAmountsEditable && isSubProjectInDirectBilling(contractInfos.subProject)) {
      return t('contracts:sections.information.descriptionEditionNotAllowedOnDirectBilling');
    }

    if (!contractInfos.areAmountsEditable && isSubProjectInProgressBilling(contractInfos.subProject)) {
      return t('contracts:sections.information.descriptionEditionNotAllowedOnProgressBilling');
    }

    return t('contracts:sections.information.descriptionEditionAllowed');
  }, [contractInfos, t]);

  useEffect(() => {
    if (!contractInfos) return;

    const {
      contract: {
        discount,
        totalAmountWithoutDiscountExVAT,
        name: contractName,
        code,
        receptionDate,
        customDiscounts: rawCustomDiscounts,
      },
      subProject: { hasReversalOfLiability },
    } = contractInfos;

    form.setFormValues({
      name: contractName,
      code,
      receptionDate: formatDateToString(receptionDate),
      discountAmountExVAT: discount
        ? mapNumberToAmount(getDiscountAmountExVAT(discount, totalAmountWithoutDiscountExVAT))
        : undefined,
      hasDiscountBeenUpdated: false,
      hasReversalOfLiability,
      customDiscounts: formatCustomDiscountsToAmountType(rawCustomDiscounts),
    });
  }, [contractInfos, form, mapNumberToAmount, updateRowsCount]);

  useEffect(() => {
    const numberOfItems = Object.values(contractInfos.items).length;
    const numberOfLots = Object.values(contractInfos.lots).length;
    updateRowsCount(numberOfItems + numberOfLots);
  }, [contractInfos, updateRowsCount]);

  const { areAmountsEditable } = contractInfos;
  const displayRenderingLoader = areAmountsEditable ? !isFullyLoaded : false;

  return (
    <Form form={form} style={{ height: '100%' }}>
      <CounterLoaderProvider value={loadingTableCtx}>
        <HiddenFields />

        <Box display={!displayRenderingLoader ? 'block' : 'none'}>
          <Section title={t('contracts:sections.information.title')} description={editionSectionDescriptionTranslation}>
            <ContractEditionCard />
          </Section>

          {areAmountsEditable && (
            <Section
              title={t('contracts:sections.details.title')}
              description={t('contracts:sections.details.description')}
            >
              <ContractEditionTable
                contractInfos={contractInfos}
                getCurrentTreeRef={getCurrentTreeRef}
                getTreeChangesRef={getTreeChangesRef}
                getComputedValuesRef={getComputedValuesRef}
              />
            </Section>
          )}
        </Box>

        <Center display={displayRenderingLoader ? 'block' : 'none'} boxSize="100%">
          <Loading />
        </Center>
      </CounterLoaderProvider>
    </Form>
  );
};
