import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ORDER_STATUS } from '@graneet/business-logic';
import type { FC } from 'react';

import { ORDER_STATUS_COLOR } from '../../constants/orders.constant';

export type OrderStatusBadgeProps = {
  /**
   * Order status displayed by the badge
   */
  status: ORDER_STATUS;
};

export const OrderStatusBadge: FC<OrderStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation(['orders']);

  return (
    <Badge w="auto" minWidth="5.25rem" px={3} colorScheme={ORDER_STATUS_COLOR[status]}>
      {t(`orders:statuses.${status}`)}
    </Badge>
  );
};
