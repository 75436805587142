export enum PDF_STATUSES {
  GENERATING = 'GENERATING',
  GENERATED = 'GENERATED',
  ERROR = 'ERROR',
  ERROR_CONVERTING = 'ERROR_CONVERTING',
}

export enum PDF_TEMPLATES {
  PROGRESS_STATEMENT_CLASSIC = 'progress-statement-classic',
  PROGRESS_STATEMENT_FULL = 'progress-statement-full',
  DIRECT_PROGRESS_STATEMENT = 'direct-progress-statement',
  FINAL_STATEMENT = 'final-statement',
  INVOICE = 'invoice',
  QUOTE = 'quote',
  DOWN_PAYMENT = 'down-payment',
  CREDIT = 'credit',
  ORDER = 'order',
}

export enum PDF_TEMPLATES_TYPES {
  QUOTE = 'quote',
  STATEMENT = 'statement',
}

export interface IPdf {
  id: number;

  apiId: string;

  status: PDF_STATUSES;

  url: string | null;

  createdAt: Date;

  updatedAt: Date;
}
