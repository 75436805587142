import type { FC } from 'react';
import { useCallback } from 'react';
import { RadioGroup } from '@graneet/lib-ui';
import type { IPaymentMethodResponseDTO } from '@graneet/business-logic';

import { PaymentMethodDetail } from './PaymentMethodDetail';

interface PaymentMethodRadioGroupProps {
  selectedPaymentMethod?: IPaymentMethodResponseDTO;

  paymentMethods: IPaymentMethodResponseDTO[];

  onSelected(newPaymentMethod: IPaymentMethodResponseDTO | null): void;
}

export const PaymentMethodRadioGroup: FC<PaymentMethodRadioGroupProps> = ({
  selectedPaymentMethod,
  paymentMethods,
  onSelected,
}) => {
  const onPaymentMethodIdSelected = useCallback(
    (selectedId: string) => {
      const paymentMethod = paymentMethods.find(({ id }) => id === parseInt(selectedId, 10));
      onSelected(paymentMethod ?? null);
    },
    [onSelected, paymentMethods],
  );

  return (
    <RadioGroup
      name="paymentMethod"
      defaultValue={selectedPaymentMethod?.id.toString()}
      onChange={onPaymentMethodIdSelected}
    >
      {paymentMethods.map((paymentMethod) => (
        <RadioGroup.CardOption label={paymentMethod.name} key={paymentMethod.id} value={paymentMethod.id.toString()}>
          <PaymentMethodDetail paymentMethod={paymentMethod} displayFavourite />
        </RadioGroup.CardOption>
      ))}
    </RadioGroup>
  );
};
