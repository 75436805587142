import { createQuoteHiddenCostItemAddedCallback } from './createQuoteHiddenCostItemAddedCallback';
import { createQuoteHiddenCostItemDeletedCallback } from './createQuoteHiddenCostItemDeletedCallback';
import { createQuoteHiddenCostItemTransformCallback } from './createQuoteHiddenCostItemTransformCallback';
import { createQuoteHiddenCostItemUpdatedCallback } from './createQuoteHiddenCostItemUpdatedCallback';

import type { QuoteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';

export const quoteHiddenCostItemCallbacks = (quoteId: String) => [
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteHiddenCostItem:added',
    callback: (message: QuoteWsMessage) => createQuoteHiddenCostItemAddedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteHiddenCostItem:deleted',
    callback: (message: QuoteWsMessage) => createQuoteHiddenCostItemDeletedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteHiddenCostItem:updated',
    callback: (message: QuoteWsMessage) => createQuoteHiddenCostItemUpdatedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteHiddenCostItem:transformed',
    callback: (message: QuoteWsMessage & { oldType: string; newType: string }) =>
      createQuoteHiddenCostItemTransformCallback(message),
  },
];
