import type { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

export const DisplayCellWithIndicator: FC<{ value: number; children: ReactNode }> = ({ value, children }) => (
  <Box display="inline-block">
    <Box display="flex" px={3} h={6} lineHeight={6} rounded="md" backgroundColor={value < 0 ? 'red.150' : undefined}>
      {value >= 0 ? '+' : ''}
      {children}
    </Box>
  </Box>
);
