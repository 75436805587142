import type { ChangeEventHandler, CSSProperties, DragEventHandler, FC, ReactElement, ReactNode } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { StyleProps } from '@chakra-ui/react';
import { Box, Flex, IconButton, Text, useTheme, VStack } from '@chakra-ui/react';

import { FormGroup } from '../../../FormGroup';
import { droppableFileFieldTranslations } from '../config/configureDefaultLabel';
import { SimpleCloseIcon } from '../../../Icons';

import { DroppableFileIcon } from './DroppableFileIcon';

const INPUT_PROPS: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  opacity: 0,
  cursor: 'pointer',
};

export interface DroppableFileProps {
  file?: File;

  onChange(newFile: File | undefined): void;

  onFileChange?: (newFile?: File) => void;

  maxFileSize?: number;

  isRequired?: boolean;

  isDisabled?: boolean;

  label?: string;

  accept?: string;

  h?: string;

  minHeight?: string;

  defaultIcon?: ReactElement;

  primaryLabel?: ReactNode;

  primaryLabelError?: ReactNode;

  secondaryLabel?: ReactNode;

  fileFieldDisabled?: ReactNode;

  maxSizeLabel?: ReactNode;

  fileTooLargeErrorMessage?: ReactNode;

  fileUnsupportedErrorMessage?: ReactNode;
}

export const DroppableFile: FC<DroppableFileProps> = ({
  file,
  onChange,
  onFileChange,
  maxFileSize = 10,
  label,
  isRequired = false,
  isDisabled = false,
  accept,
  h = '15rem',
  defaultIcon,
  minHeight,
  primaryLabel,
  primaryLabelError,
  secondaryLabel,
  maxSizeLabel,
  fileFieldDisabled,
  fileTooLargeErrorMessage,
  fileUnsupportedErrorMessage,
}) => {
  const {
    colors: { gray, blue, red },
  } = useTheme();
  const fileInputRef = useRef<null | HTMLInputElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isTooLarge, setIsTooLarge] = useState(false);
  const [isUnsupported, setIsUnsupported] = useState(false);

  const acceptArray = accept?.split(',').map((element) => element.trim());

  const isInvalid = isTooLarge || isUnsupported;

  const maxFileSizeInBytes = useMemo(() => maxFileSize * 1024 * 1024, [maxFileSize]);

  const setFile = useCallback<
    DragEventHandler<HTMLInputElement> & ChangeEventHandler<HTMLInputElement> & ((e: undefined) => void)
  >(
    (e) => {
      const newFile = e?.target && 'files' in e.target && e.target.files?.[0];

      if (!newFile) {
        onChange(undefined);

        if (fileInputRef.current) {
          fileInputRef.current.files = null;
        }
        setIsUnsupported(false);
        setIsTooLarge(false);
      }

      if (newFile) {
        if (newFile.size >= maxFileSizeInBytes) {
          setIsTooLarge(true);
          return;
        }
        if (acceptArray && !acceptArray.includes(newFile.type)) {
          setIsUnsupported(true);
          return;
        }
        setIsUnsupported(false);
        setIsTooLarge(false);
        onChange(newFile);
        if (onFileChange) onFileChange(newFile);
      }
    },
    [acceptArray, maxFileSizeInBytes, onChange, onFileChange],
  );

  const fieldBackgroundColor = useMemo<StyleProps['color']>(() => {
    if (isInvalid) {
      return 'red.50'; // Invalid file
    }
    if (file) {
      return 'blue.50'; // File selected
    }
    if (isDragging) {
      return 'gray.200'; // Dragging file
    }
    return 'gray.100'; // Default
  }, [file, isDragging, isInvalid]);

  const fieldBorder = useMemo<StyleProps['border']>(() => {
    if (isInvalid) {
      return `1px solid ${red[500]}`; // Invalid file
    }
    if (file) {
      return `1px solid ${blue[500]}`; // File selected
    }
    return `2px dashed ${gray[500]}`; // Default
  }, [blue, red, gray, file, isInvalid]);

  const cancelActionColor = useMemo<StyleProps['color']>(() => {
    if (isInvalid) {
      return 'red.300'; // Invalid file
    }
    if (file) {
      return 'blue.300'; // File selected
    }
    return 'gray.300'; // Default
  }, [file, isInvalid]);

  const isUnselectedOrInvalid = !file || isInvalid;

  if (isDisabled)
    return (
      <Flex justifyContent="center" flexDirection="row" style={{ border: '1px' }}>
        <Text>{fileFieldDisabled ?? droppableFileFieldTranslations.fileFieldDisabled}</Text>
      </Flex>
    );

  return (
    <FormGroup role="group" isRequired={isRequired} label={label} height="100%">
      <input
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDrop={setFile}
        onChange={setFile}
        type="file"
        accept={accept}
        multiple={false}
        ref={fileInputRef}
        style={{
          ...INPUT_PROPS,
          display: isUnselectedOrInvalid ? 'block' : 'none',
        }}
      />

      {(file || isInvalid) && (
        <Box position="absolute" right={0} zIndex={10}>
          <IconButton
            icon={<SimpleCloseIcon boxSize={4} />}
            color={cancelActionColor}
            display="block"
            verticalAlign="flex-start"
            variant="clear"
            onClick={() => setFile(undefined)}
            aria-label="Cancel"
          />
        </Box>
      )}

      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        w="100%"
        borderRadius="md"
        h={h}
        bg={fieldBackgroundColor}
        border={fieldBorder}
        minHeight={minHeight}
        _groupHover={!file ? { bg: 'gray.200' } : undefined}
      >
        <VStack spacing={1} w="100%" alignItems="center" justifyContent="center">
          <DroppableFileIcon isInvalid={isInvalid} isFileSelected={!!file} defaultIcon={defaultIcon} />

          {isTooLarge && (
            <Text fontWeight={700}>
              {fileTooLargeErrorMessage ?? droppableFileFieldTranslations.fileTooLargeErrorMessage}
            </Text>
          )}

          {isUnsupported && (
            <Text fontWeight={700}>
              {fileUnsupportedErrorMessage ?? droppableFileFieldTranslations.fileUnsupportedErrorMessage}
            </Text>
          )}

          {isUnselectedOrInvalid ? (
            <Text textAlign="center">
              <Text as="span" fontWeight={700} color="greenBrand.light">
                {isInvalid
                  ? (primaryLabelError ?? droppableFileFieldTranslations.primaryLabelError)
                  : (primaryLabel ?? droppableFileFieldTranslations.primaryLabel)}
              </Text>{' '}
              <Text as="span" whiteSpace="pre-line">
                {secondaryLabel ?? ` ${droppableFileFieldTranslations.secondaryLabel}`}
              </Text>
            </Text>
          ) : (
            <Text userSelect="none">{file.name}</Text>
          )}

          {isUnselectedOrInvalid && (
            <Text fontSize="xs">{`${
              maxSizeLabel ?? droppableFileFieldTranslations.maxSizeLabel
            } : ${maxFileSize}MB`}</Text>
          )}
        </VStack>
      </Flex>
    </FormGroup>
  );
};
