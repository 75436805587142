export interface AsyncDataTableTranslations {
  error: string;
}
export const asyncDataTableTranslations: AsyncDataTableTranslations = {
  error: 'Error',
};

export const configureDefaultLabels = (translation: AsyncDataTableTranslations) => {
  Object.assign(asyncDataTableTranslations, translation);
};
