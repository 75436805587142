import type { FC } from 'react';
import { useCallback } from 'react';
import type { ISupplierInvoice, ISupplierInvoicePaymentExtended } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import { Date, Price, Table } from '@graneet/lib-ui';

import { useSupplierInvoiceDeletePayment } from '../../../services/supplier-invoice.api';
import { SupplierInvoicePaymentsEditModal } from '../../modals/SupplierInvoicePaymentsEditModal';
import { SupplierInvoicePaymentActionMenu } from '../../SupplierInvoicePaymentActionMenu';

export interface SupplierInvoicePaymentRowProps {
  supplierInvoicePayment: ISupplierInvoicePaymentExtended;

  displayPaymentActions: boolean;

  supplierInvoice: ISupplierInvoice;
}

export const SupplierInvoicePaymentRow: FC<SupplierInvoicePaymentRowProps> = ({
  supplierInvoicePayment,
  displayPaymentActions,
  supplierInvoice,
}) => {
  const { t } = useTranslation(['global', 'supplierInvoices']);

  const supplierInvoiceDeletePaymentMutation = useSupplierInvoiceDeletePayment();

  const modal = useDisclosure();

  const onDeletePayment = useCallback(
    (id: number) => async () => {
      await supplierInvoiceDeletePaymentMutation.mutateAsync(id);
    },
    [supplierInvoiceDeletePaymentMutation],
  );

  return (
    <Table.Row>
      <Table.Cell>{t(`supplierInvoices:paymentType.${supplierInvoicePayment.type}`)}</Table.Cell>
      <Table.Cell center>
        <Date>{supplierInvoicePayment.paidAt}</Date>
      </Table.Cell>
      <Table.Cell center>
        <Price amount={supplierInvoicePayment.amountPaidIncVAT} />
      </Table.Cell>
      {displayPaymentActions && (
        <Table.Cell right>
          <SupplierInvoicePaymentActionMenu
            supplierInvoicePayment={supplierInvoicePayment}
            onEdit={modal.onOpen}
            onDelete={onDeletePayment(supplierInvoicePayment.id)}
          />

          <SupplierInvoicePaymentsEditModal
            supplierInvoice={supplierInvoice}
            isOpen={modal.isOpen}
            onClose={modal.onClose}
            supplierInvoicePayment={supplierInvoicePayment}
          />
        </Table.Cell>
      )}
    </Table.Row>
  );
};
