import type { FC, ReactNode } from 'react';
import { useCallback, Suspense } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Text, VStack } from '@chakra-ui/react';
import { Button } from '@graneet/lib-ui';
import { SUPPORT_EMAIL } from '@graneet/business-logic';

import { Error } from '../../common/components/Error';
import { Loading } from '../../common/components/Loading';

interface FallbackRenderProps extends FallbackProps {
  message: string;
  hideRetryButton: boolean;
}

const FallbackRender: FC<FallbackRenderProps> = ({ resetErrorBoundary, message, hideRetryButton }) => {
  const { t } = useTranslation(['global']);

  return (
    <Error
      message={
        <VStack>
          <Text>{message}</Text>
          {!hideRetryButton && (
            <Button variant="outline" size="sm" onClick={resetErrorBoundary}>
              {t('global:tryAgain')}
            </Button>
          )}
        </VStack>
      }
    />
  );
};

interface QueryWrapperProps {
  children: ReactNode;
  errorMessage?: string;
  hideRetryButton?: boolean;
  loadingFallback?: ReactNode;
}

export const QueryWrapper: FC<QueryWrapperProps> = ({
  children,
  errorMessage,
  hideRetryButton = false,
  loadingFallback,
}) => {
  const { t } = useTranslation(['global']);

  const fallbackRender = useCallback(
    (props: FallbackProps) => (
      <FallbackRender
        {...props}
        message={errorMessage ?? t('global:errors.pageNotLoaded', { email: SUPPORT_EMAIL })}
        hideRetryButton={hideRetryButton}
      />
    ),
    [hideRetryButton, errorMessage, t],
  );

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary fallbackRender={fallbackRender} onReset={reset}>
          <Suspense fallback={loadingFallback ?? <Loading />}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
