import type { FC, KeyboardEventHandler } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { NodeWithRelations } from '@graneet/lib-ui';
import { formatVatRateToNumber, PercentageField, useCurrency } from '@graneet/lib-ui';
import type { FormContextApi } from 'graneet-form';
import { Form, useForm, useFormContext, useOnChangeValues } from 'graneet-form';
import { Box, useDisclosure } from '@chakra-ui/react';
import {
  CUMULATIVE_INPUT_TYPE,
  getAmountPercentage,
  divideFloating,
  getDiscountAmountExVAT,
  isNumberFinite,
  roundFloating,
  multiplyFloating,
  isNil,
} from '@graneet/business-logic';

import type { IContractTree } from '../../../hooks/useProgressStatementTree';
import {
  useProgressStatementLotOrContainerComputedValue,
  useProgressStatementTreeContext,
} from '../../../hooks/useProgressStatementTree';
import type { ContractId, LotId } from '../../../services/progress-statement-tree.util';
import { getIdFromContractId } from '../../../services/progress-statement-tree.util';
import type { ProgressStatementEditItemForm } from '../../../forms/progress-statement-edit-item-form';
import {
  getDiscountCumulativeFieldName,
  getItemCumulativeFieldName,
} from '../../../forms/progress-statement-edit-item-form';
import { useProgressStatementContext } from '../../../contexts/ProgressStatementContext';

import type { ProgressStatementGlobalPercentageForm } from './ProgressStatementGlobalPercentageForm';
import { ConfirmProgressStatementGlobalProgressModal } from './ConfirmProgressStatementGlobalProgressModal';

const INPUT_STYLE = {
  w: '4rem',
  borderBottom: 0,
  textAlign: 'center',
  _groupHover: {
    borderBottom: '1px',
    borderBottomColor: 'gray.500',
  },
} as const;

interface ProgressStatementContainerGlobalProgressFormProps {
  id: number;

  nodeId: ContractId;

  type: 'container';

  onPercentageUpdatedWithoutHigherNestedPercentage(): void;

  contract: NodeWithRelations<IContractTree>;
}

interface ProgressStatementLotGlobalProgressFormProps {
  id: number;

  nodeId: LotId;

  type: 'lot';

  onPercentageUpdatedWithoutHigherNestedPercentage(): void;
}

type ProgressStatementGlobalProgressFormProps =
  | ProgressStatementContainerGlobalProgressFormProps
  | ProgressStatementLotGlobalProgressFormProps;

export const ProgressStatementGlobalProgressForm: FC<ProgressStatementGlobalProgressFormProps> = ({
  id,
  type,
  nodeId,
  onPercentageUpdatedWithoutHigherNestedPercentage,
  ...otherProps
}) => {
  const contract = 'contract' in otherProps ? otherProps.contract : undefined;

  const parentForm = useFormContext<ProgressStatementEditItemForm>();
  const { cumulativeInputType } = useOnChangeValues(parentForm, ['cumulativeInputType']);
  const { mapNumberToAmount } = useCurrency();

  const {
    getItemsIdsOfLot,
    getItemsIdsOfContainer,
    previousItemsCumulativeValues,
    previousDiscountsCumulativeValues,
    indexedItems,
  } = useProgressStatementContext();

  const previousPercentageRef = useRef<null | number>(null);

  const higherPercentageWarningModal = useDisclosure();

  const isFocusedRef = useRef(false);
  const onFocus = useCallback(() => {
    isFocusedRef.current = true;
  }, [isFocusedRef]);
  const onBlur = useCallback(() => {
    isFocusedRef.current = false;
  }, [isFocusedRef]);

  const itemsIds = useMemo(
    () => (type === 'lot' ? getItemsIdsOfLot(id) : getItemsIdsOfContainer(id)),
    [getItemsIdsOfLot, getItemsIdsOfContainer, id, type],
  );

  const updateProgressPercentageOfNestedItems = useCallback(
    (value: number) => {
      const newPercentageFieldValues = itemsIds.reduce<Partial<ProgressStatementEditItemForm>>((acc, itemId) => {
        const cumulativeAmountExVAT = getAmountPercentage(indexedItems[itemId].totalExVAT, value);
        const cumulativeQuantity = divideFloating(cumulativeAmountExVAT, indexedItems[itemId].unitPrice);

        acc[getItemCumulativeFieldName(itemId, CUMULATIVE_INPUT_TYPE.PERCENTAGE)] = value;
        acc[getItemCumulativeFieldName(itemId, CUMULATIVE_INPUT_TYPE.AMOUNT)] =
          mapNumberToAmount(cumulativeAmountExVAT);
        acc[getItemCumulativeFieldName(itemId, CUMULATIVE_INPUT_TYPE.QUANTITY)] = cumulativeQuantity;
        return acc;
      }, {});

      // Editing contract global percentage, propagates new percentage to the discount
      if (type === 'container' && contract && contract.discount) {
        const discountAmount = getDiscountAmountExVAT(contract.discount, contract.totalAmountWithoutDiscountExVAT);

        const contractId = getIdFromContractId(contract.id);

        const cumulativeProgressPercentage = Math.max(
          previousDiscountsCumulativeValues[contractId]?.cumulativeProgressPercentage || 0,
          value,
        );

        const cumulativeAmountExVAT = getAmountPercentage(discountAmount, cumulativeProgressPercentage);

        newPercentageFieldValues[
          getDiscountCumulativeFieldName(getIdFromContractId(contract.id), CUMULATIVE_INPUT_TYPE.PERCENTAGE)
        ] = cumulativeProgressPercentage;
        newPercentageFieldValues[
          getDiscountCumulativeFieldName(getIdFromContractId(contract.id), CUMULATIVE_INPUT_TYPE.AMOUNT)
        ] = mapNumberToAmount(cumulativeAmountExVAT);
      }

      parentForm.setFormValues(newPercentageFieldValues);

      previousPercentageRef.current = value;

      onBlur();
    },
    [itemsIds, type, contract, parentForm, onBlur, indexedItems, mapNumberToAmount, previousDiscountsCumulativeValues],
  );

  const onUpdateAfterBlur = useCallback(
    (
      _: unknown,
      value: number,
      __: unknown,
      { setFormValues }: Pick<FormContextApi<ProgressStatementGlobalPercentageForm>, 'getFormValues' | 'setFormValues'>,
    ) => {
      // When we empty the field and set previous value, only fill by the previous value if defined
      if (!Number.isFinite(value)) {
        if (Number.isFinite(previousPercentageRef.current)) {
          setFormValues({ globalPercentage: previousPercentageRef.current });
        }
        onBlur();
        return;
      }

      const hasBiggerPercentage = itemsIds
        .map((itemId) => previousItemsCumulativeValues[itemId]?.cumulativeProgressPercentage || 0)
        .some((percentage) => percentage > value);

      if (hasBiggerPercentage) {
        higherPercentageWarningModal.onOpen();
        return;
      }

      updateProgressPercentageOfNestedItems(value);
      onPercentageUpdatedWithoutHigherNestedPercentage();
    },
    [
      higherPercentageWarningModal,
      itemsIds,
      onBlur,
      onPercentageUpdatedWithoutHigherNestedPercentage,
      previousItemsCumulativeValues,
      updateProgressPercentageOfNestedItems,
    ],
  );
  const form = useForm<ProgressStatementGlobalPercentageForm>({ onUpdateAfterBlur });

  const onConfirm = useCallback(
    (value: number) => {
      updateProgressPercentageOfNestedItems(value);
      onBlur();
    },
    [onBlur, updateProgressPercentageOfNestedItems],
  );

  const onInputKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>((e) => {
    /**
     * The input is used in a context where there is a form nested in another form. It's bad, but it's much simpler
     * to nest forms in this case. But because of that, when user press enters, the page is reloaded. This is to prevent this
     * that we prevent propagation.
     */
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }, []);

  const computedValues = useProgressStatementLotOrContainerComputedValue(nodeId);

  useEffect(() => {
    if (isFocusedRef.current || !computedValues) return;

    const hasUniqueProgressPercentage = computedValues.itemProgressPercentages.size === 1;
    const newGlobalProgressPercentage =
      hasUniqueProgressPercentage && isNumberFinite([...computedValues.itemProgressPercentages][0])
        ? [...computedValues.itemProgressPercentages][0]
        : null;

    form.setFormValues({ globalPercentage: newGlobalProgressPercentage });
    previousPercentageRef.current = newGlobalProgressPercentage || null;
  }, [computedValues, form]);

  const progressStatementTreeContext = useProgressStatementTreeContext();
  const onInputBlur = useCallback(() => {
    const { globalPercentage } = form.getFormValues();
    if (isNil(globalPercentage)) {
      return;
    }

    progressStatementTreeContext.updateAllChildrenLeavesOfNode(nodeId, {
      progressPercentage: globalPercentage!,
    });

    const items = progressStatementTreeContext.getCurrentTree().leaves;

    itemsIds.forEach((itemId) => {
      progressStatementTreeContext.updateLeafData(itemId, {
        cumulativeAmountExVAT: roundFloating(
          multiplyFloating(items[itemId].totalExVAT, formatVatRateToNumber(globalPercentage || 0)),
        ),
      });
    });
  }, [form, progressStatementTreeContext, nodeId, itemsIds]);

  return (
    <Box display={cumulativeInputType !== CUMULATIVE_INPUT_TYPE.PERCENTAGE ? 'none' : undefined}>
      <Form form={form}>
        <PercentageField<ProgressStatementGlobalPercentageForm>
          name="globalPercentage"
          inputProps={INPUT_STYLE}
          scale={null}
          placeholder=""
          onFocus={onFocus}
          onBlur={onInputBlur}
          onKeyDown={onInputKeyDown}
          noBorder
        />

        {higherPercentageWarningModal.isOpen && (
          <ConfirmProgressStatementGlobalProgressModal
            onConfirmationClicked={onConfirm}
            modal={higherPercentageWarningModal}
            type={type}
          />
        )}
      </Form>
    </Box>
  );
};
