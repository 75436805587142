import { Box, Flex } from '@chakra-ui/react';
import type { FC, PropsWithChildren } from 'react';
import { cloneElement, useRef } from 'react';

import { extractChildrenOfType } from '../../utils';
import { EqualIcon, MultiplicationIcon } from '../Icons';

import type { FieldProps } from './hooks';
import { useMultiplicationUpdate } from './hooks';
import { MultiplicationFieldWithComputedName } from './components';

export interface MultiplicationProps {
  setComputedElement?(computedElement: string | null): void;
  computedElement?: string | null;
  hiddenCost?: JSX.Element;
  hiddenCostAmount?: number | undefined;
}

const MultiplicationComponent: FC<PropsWithChildren<MultiplicationProps>> = ({
  children,
  setComputedElement,
  computedElement,
  hiddenCost,
  hiddenCostAmount,
}) => {
  const { matching: fields } = extractChildrenOfType(children, MultiplicationFieldWithComputedName);
  const fieldNameFocusedRef = useRef<FieldProps<string> | null>(null);

  if (fields.length !== 3) {
    throw new Error('Multiplication need to have 3 Fields');
  }

  const getFieldNameAndComputedName = (index: number) => ({
    fieldName: fields[index].props.name,
    computedName: fields[index].props.computedName,
  });

  useMultiplicationUpdate(
    getFieldNameAndComputedName(0),
    getFieldNameAndComputedName(1),
    getFieldNameAndComputedName(2),
    computedElement,
    setComputedElement,
    fieldNameFocusedRef,
    hiddenCostAmount,
  );

  const handleFocusField = (index: number) => () => {
    const { onFocus } = fields[index].props;
    if (onFocus) {
      onFocus();
    }
    fieldNameFocusedRef.current = getFieldNameAndComputedName(index);
  };

  const handleBlurField = (index: number) => () => {
    const { onBlur } = fields[index].props;
    if (onBlur) {
      onBlur();
    }
    fieldNameFocusedRef.current = null;
  };

  const factorOneElement = cloneElement(fields[0], {
    onFocus: handleFocusField(0),
    onBlur: handleBlurField(0),
  });

  const factorTwoElement = cloneElement(fields[1], {
    onFocus: handleFocusField(1),
    onBlur: handleBlurField(1),
  });

  const resultElement = cloneElement(fields[2], {
    onFocus: handleFocusField(2),
    onBlur: handleBlurField(2),
  });

  return (
    <Flex alignItems="flex-end">
      <Box flexGrow={1}>{factorOneElement}</Box>
      <MultiplicationIcon boxSize={4} mx={2} mb={2} />
      {hiddenCost || null}
      <Box flexGrow={1} width={hiddenCost ? '7.2rem' : undefined}>
        {factorTwoElement}
      </Box>
      <EqualIcon boxSize={4} mx={2} mb={2} />
      <Box flexGrow={1}>{resultElement}</Box>
    </Flex>
  );
};

export const Multiplication = Object.assign(MultiplicationComponent, {
  Field: MultiplicationFieldWithComputedName,
});
