import type { IAccountingConfigBankAccount } from '../accounting/accounting-config-bank-account.type';
import type { IAccountingExport } from '../accounting/accounting-export.type';
import type { IBankingTransaction } from '../banking/banking-transaction.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IVatDistribution } from '../vat/vat-distribution.type';

import type { ISupplierInvoice } from './supplier-invoice.type';

export enum SUPPLIER_INVOICE_PAYMENT_TYPES {
  TOTAL = 'TOTAL',
  PARTIAL = 'PARTIAL',
}

export interface ISupplierInvoicePayment {
  id: number;

  type: SUPPLIER_INVOICE_PAYMENT_TYPES;

  amountPaidIncVAT: number;

  paidAt: Date;

  vatDistribution: IVatDistribution | null;

  supplierInvoice?: ISupplierInvoice;

  accountingExport?: IAccountingExport | null;

  accountingConfigBankAccount?: IAccountingConfigBankAccount | null; // @[ff: accounting-payment-methods] make not nullable?

  bankingTransaction?: IBankingTransaction | null;

  createdAt: Date;

  updatedAt: Date;
}

export type ISupplierInvoicePaymentWithRelations = RequiredByKeys<
  ISupplierInvoicePayment,
  'supplierInvoice' | 'accountingExport'
>;

export type ISupplierInvoicePaymentExtended = ISupplierInvoicePayment & { isAccountingExported: boolean };
