import { TimeTable, Card, Container, Callout, SimpleAlertIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import { TimeTrackingHeader } from 'features/time-tracking/components/TimeTrackingHeader';
import { TimeTrackingFilters } from 'features/time-tracking/components/TimeTrackingFilters';
import { TimeTrackingTable } from 'features/time-tracking/components/TimeTrackingTable/TimeTrackingTable';
import { useTimeTracking } from 'features/time-tracking/hooks/useTimeTracking';
import { TimeTrackingContext } from 'features/time-tracking/context/TimeTrackingContext';
import { TimeSlotCreateModal } from 'features/time-tracking/components/modals/TimeSlotCreateModal/TimeSlotCreateModal';
import { TimeSlotEditModal } from 'features/time-tracking/components/modals/TimeSlotEditModal';

export const ViewTimeTrackingScreen = () => {
  const { t } = useTranslation(['timeTracking']);

  const timeTracking = useTimeTracking();

  const {
    weekData,
    state: { numberOfDays },
  } = timeTracking;

  TimeTable.configureDefaultLabels({
    hourLabel: t('timeTracking:timeTable.hourLabel'),
    dayLabel: t('timeTracking:timeTable.dayLabel'),
    away: t('timeTracking:timeTable.away'),
    deleteAllTimes: t('timeTracking:timeTable.deleteAllTimes'),
    week: t('timeTracking:timeTable.week'),
    totalWorkedInWeek: (weekNumber) => t('timeTracking:timeTable.totalWorkedInWeek', { weekNumber }),
    totalWorkedInMonth: (month) => t('timeTracking:timeTable.totalWorkedInMonth', { month }),
    approve: t('timeTracking:timeTable.approve'),
    selectedTimeSlots: (numberOfSelectedTimes) =>
      t('timeTracking:timeTable.selectedTimeSlots', { count: numberOfSelectedTimes }),
  });

  const showWarning = (weekData?.hasSlotsOnWeekend ?? false) && numberOfDays === 5;

  return (
    <TimeTrackingContext.Provider value={timeTracking}>
      <TimeTrackingHeader />
      <Box mt={10}>
        <Container>
          {showWarning && (
            <Box pb={4}>
              <Callout p={2} colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
                {t(
                  timeTracking.state.filters.view === 'week'
                    ? 'timeTracking:timeTable.slotsWeekend'
                    : 'timeTracking:timeTable.slotsMonthlyWeekend',
                )}
              </Callout>
            </Box>
          )}

          <Card px={0}>
            <TimeTrackingFilters />
            <TimeTrackingTable />
          </Card>

          <TimeSlotCreateModal />
          <TimeSlotEditModal />
        </Container>
      </Box>
    </TimeTrackingContext.Provider>
  );
};
