import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { ICredit } from '@graneet/business-logic';
import { Card } from '@graneet/lib-ui';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { useStatementsAvailableTags } from 'features/statement/services/statement.api';
import { useEditCreditTags } from 'features/credit/services/credit.api';

interface CreditTagsCardProps {
  credit: ICredit;
}

export const CreditTagsCard: FC<CreditTagsCardProps> = ({ credit }) => {
  const { t } = useTranslation(['global']);
  const tags = useStatementsAvailableTags();

  const editCreditTagsMutation = useEditCreditTags();

  const onTagsSaved = useCallback(
    async (newTags: string[]) => editCreditTagsMutation.mutateAsync({ id: credit.id, dto: { tags: newTags } }),
    [credit.id, editCreditTagsMutation],
  );

  return (
    <Card title={t('global:words.privateTags')}>
      <TagsEditionBlock
        placeholder={t('global:changeTags.placeholder')}
        tags={credit.tags}
        suggestedTags={tags.data}
        onTagsEdited={onTagsSaved}
        isEditable
      />
    </Card>
  );
};
