import type { ReactNode } from 'react';
import { useEffect, useState, useRef } from 'react';
import { Box, Grid } from '@chakra-ui/react';

import type { CardProps } from '../Card/Card';
import { usePaginatedDataContext } from '../PaginatedData/hooks';

import {
  SkeletonRow,
  Row,
  Header,
  Cell,
  NoResultRow,
  TableHeaderSelect,
  TableSelectBanner,
  TableInformation,
} from './components';
import { TableSelectionContextProvider } from './contexts/TableSelectionContext.context';
import { TableSelect } from './components/TableSelect';
import { TableContext, useTable } from './contexts/TableContext';

const FORCE_LOADER_DELAY = 300;

export interface OwnTableProps {
  children: ReactNode | ReactNode[];

  numberOfSkeletonRows?: number;

  templateColumns: string[];

  noCard?: boolean;

  noResultLabel?: string;

  showNoResult?: boolean;
}

export type TableProps<NoCard extends boolean = false> = NoCard extends true
  ? OwnTableProps & CardProps
  : OwnTableProps;

export const TableComponent = <NoCard extends boolean = true>({
  children,
  numberOfSkeletonRows = 4,
  templateColumns,
  noCard = false,
  noResultLabel,
  showNoResult = true,
  ...props
}: TableProps<NoCard>) => {
  const [forceLoader, setForceLoader] = useState(false);
  const forceLoaderTimer = useRef<NodeJS.Timeout>();
  const numberOfColumns = templateColumns.length;
  const templateColumnsAsString = templateColumns.join(' ');

  const paginatedDataContext = usePaginatedDataContext();

  const displaySkeleton = paginatedDataContext
    ? paginatedDataContext.loadingMore || paginatedDataContext.hasMore
    : false;
  const isLoading = paginatedDataContext ? paginatedDataContext.loading : false;

  useEffect(() => {
    if (isLoading) {
      setForceLoader(true);

      if (forceLoaderTimer.current) {
        clearTimeout(forceLoaderTimer.current);
      }

      forceLoaderTimer.current = setTimeout(() => {
        setForceLoader(false);
      }, FORCE_LOADER_DELAY);
    }
  }, [isLoading]);

  const tableContext = useTable(forceLoader || isLoading, numberOfColumns);

  const content = (
    <TableSelectionContextProvider>
      <TableContext.Provider value={tableContext}>
        <Grid templateColumns={templateColumnsAsString}>
          {children}

          {/* Loading state */}
          {tableContext.isLoading &&
            new Array(numberOfSkeletonRows).fill(null).map((val, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <SkeletonRow numberOfColumns={numberOfColumns} key={`skeleton_${index}`} />
            ))}

          {/* Loaded state, display bottom skeleton if there is content to fetch */}
          {!tableContext.isLoading && tableContext.hasContent && displaySkeleton && (
            <SkeletonRow numberOfColumns={numberOfColumns} />
          )}

          {/* Zero state */}
          {!tableContext.isLoading && !tableContext.hasContent && showNoResult && (
            <NoResultRow numberOfColumns={numberOfColumns} noResultLabel={noResultLabel} />
          )}
        </Grid>
      </TableContext.Provider>
    </TableSelectionContextProvider>
  );

  if (noCard) {
    return content;
  }

  return (
    <Box p={0} {...props}>
      {content}
    </Box>
  );
};

export const Table = Object.assign(TableComponent, {
  Information: TableInformation,
  Header,
  Row,
  Cell,
  Select: TableSelect,
  HeaderSelect: TableHeaderSelect,
  SelectBanner: TableSelectBanner,
});
