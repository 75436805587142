import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { Modal, SimpleHelpIcon, TextField, Tooltip } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Grid } from '@chakra-ui/react';
import type { ISupplier, ISupplierListingDTO } from '@graneet/business-logic';
import { FEATURE_FLAGS, PERMISSION } from '@graneet/business-logic';
import { omit } from 'lodash-es';

import { SupplierTypeField } from '../SupplierTypeField';
import { mapSupplierModalFormToSupplierDTO } from '../../services/supplier.form';

import { Rule } from 'features/form/rules/Rule';
import { usePermissions } from 'features/role/hooks/usePermissions';
import type { SupplierEditForm } from 'features/supplier/forms/supplier-edit-form';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { AddressesWithBillingFields } from 'features/common/components/fields/AddressesWithBillingFields';
import { useSupplierEdit } from 'features/supplier/services/supplier.api';

interface SupplierUpsertModalProps {
  title: string;

  description?: string;

  isOpen: boolean;

  onClose(): void;

  toastSuccessMessage: string;

  submitButtonLabel: string;

  supplier: ISupplierListingDTO | ISupplier;
}

export const SupplierUpsertModal: FC<SupplierUpsertModalProps> = ({
  title,
  isOpen,
  onClose,
  supplier,
  submitButtonLabel,
  description,
}) => {
  const { t } = useTranslation(['supplier', 'global', 'clients']);

  const hasAccountingStandards = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARDS);
  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  const form = useForm<SupplierEditForm>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues, setFormValues, resetForm } = form;

  const supplierEditMutation = useSupplierEdit();

  const handleSubmit = useCallback(async () => {
    const values = getFormValues() as SupplierEditForm;

    const dto = omit(mapSupplierModalFormToSupplierDTO(values), ['isLedgerEnabled']);

    supplierEditMutation.mutate(
      {
        id: supplier.id,
        dto: {
          ...dto,
          auxiliaryAccount: hasUpdateAccountingPermission ? values.auxiliaryAccount : undefined,
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  }, [getFormValues, supplierEditMutation, supplier.id, hasUpdateAccountingPermission, onClose]);

  useEffect(() => {
    if (supplier) {
      setFormValues({
        ...supplier,
        hasNoBillingAddress: !supplier.hasBillingAddress,
        type: supplier.type,
        address: supplier.address?.address,
        billingAddressAddress: supplier.billingAddress?.address,
        billingAddressCity: supplier.billingAddress?.city,
        billingAddressCountry: supplier.billingAddress?.country,
        billingAddressPostalCode: supplier.billingAddress?.postalCode,
        city: supplier.address?.city,
        country: supplier.address?.country,
        postalCode: supplier.address?.postalCode,
        iban: supplier.iban,
      });
    }
  }, [supplier, setFormValues, t]);

  useEffect(() => {
    if (!supplier) {
      resetForm();
    }
  }, [supplier, isOpen, resetForm]);

  return (
    <Form form={form}>
      <Modal isOpen={isOpen} onClose={onClose} title={title} size="2xl" scrollBehavior="inside">
        {description && <Box>{description}</Box>}

        <Grid gap={6} mt={description ? 8 : 0}>
          <Flex w="100%" justifyContent="space-between">
            <Grid templateColumns="repeat(2, 1fr)" gap={4} w="full">
              <TextField<SupplierEditForm> name="name" label={t('supplier:fields.supplierName')} isRequired />

              <TextField<SupplierEditForm> name="code" label={t('supplier:fields.code')} />

              <SupplierTypeField<SupplierEditForm>
                name="type"
                label={t('supplier:fields.type')}
                placeholder={t('supplier:placeholder.type')}
                isRequired
              >
                <Rule.IsRequired />
              </SupplierTypeField>

              <Tooltip
                label={t('supplier:tooltips.noPermissionForAuxiliaryAccountUpdate')}
                isDisabled={hasUpdateAccountingPermission}
                shouldWrapChildren
              >
                <TextField<SupplierEditForm>
                  name="auxiliaryAccount"
                  label={t('supplier:fields.auxiliaryAccount')}
                  isReadOnly={!hasUpdateAccountingPermission}
                  rightIcon={
                    hasAccountingStandards ? (
                      <Tooltip label={t('supplier:tooltips.auxiliaryCode')} placement="right-end" shouldWrapChildren>
                        <SimpleHelpIcon boxSize={5} />
                      </Tooltip>
                    ) : undefined
                  }
                />
              </Tooltip>

              <TextField<SupplierEditForm> name="iban" label={t('supplier:fields.iban')}>
                <Rule.IsIban />
              </TextField>
            </Grid>
          </Flex>

          <AddressesWithBillingFields twoLines />
        </Grid>

        <Modal.SecondaryButton onClick={onClose}>{t('clients:modal.buttons.cancel')}</Modal.SecondaryButton>

        <Modal.PrimaryButton
          isLoading={supplierEditMutation.isPending}
          isDisabled={!isFormValid}
          onClick={handleSubmit}
        >
          {submitButtonLabel}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
