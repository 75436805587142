import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Modal, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import type { QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';
import { QuoteStatus } from '@org/graneet-bff-client';
import { QUOTE_STATUS, QUOTE_TRANSITION_EVENT, Quote, QuoteStateMachine } from '@org/quotation-lib';
import { SUPPORT_EMAIL } from '@graneet/business-logic';

import { useQuotationApi } from 'features/quotation/services/quote.api';
import { quoteWithoutRelationShipToQuoteObjectMapper } from 'features/quotation/quote-common/mappers/quoteComposeToQuoteObjectMapper';

interface ConfirmQuoteAsLostModalProps {
  modalControls: UseDisclosureReturn;
  onMarkedAsLost(): void;
  quote: QuoteWithoutRelationsDTO;
}

export const ConfirmQuoteAsLostModal: FC<ConfirmQuoteAsLostModalProps> = ({ modalControls, onMarkedAsLost, quote }) => {
  const { id: quoteId } = quote;
  const { t } = useTranslation(['global', 'quote']);
  const toast = useToast();
  const { useQuoteChangeStatus } = useQuotationApi();
  const changeStatusMutation = useQuoteChangeStatus();

  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptQuote = useCallback(async () => {
    // Get state machine
    const quoteObject = quoteWithoutRelationShipToQuoteObjectMapper(quote);
    const quoteStateMachine = QuoteStateMachine.execute(
      new Quote(
        {
          isAutomaticIndexActivated: quote.isAutomaticIndexActivated,
        },
        quoteObject,
      ),
    );

    setIsLoading(true);
    quoteStateMachine.send({ type: QUOTE_TRANSITION_EVENT.REFUSE_QUOTE });
    // Check if the transition was successful
    const transitionSuccess = quoteStateMachine.getSnapshot().value === QUOTE_STATUS.REFUSED;

    if (!transitionSuccess) {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
      setIsLoading(false);
      return;
    }

    await changeStatusMutation.mutateAsync({ quoteId, status: QuoteStatus.Refused });
    toast.success(t('global:words.c.success'), t('quote:markAsLostModal.success'));
    modalControls.onClose();
    onMarkedAsLost();
    setIsLoading(false);
  }, [quote, changeStatusMutation, quoteId, toast, t, modalControls, onMarkedAsLost]);

  return (
    <Modal {...modalControls} title={t('quote:markAsLostModal.title')}>
      <Modal.SecondaryButton onClick={modalControls.onClose}>{t('global:words.c.cancel')}</Modal.SecondaryButton>

      <Modal.PrimaryButton colorScheme="red" onClick={handleAcceptQuote} isLoading={isLoading}>
        {t('quote:actions.markAsLost')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
