import type { ISupplierCreateDTO } from '@graneet/business-logic';

import type { SupplierEditForm } from '../forms/supplier-edit-form';

import { formatAddressDTO } from 'features/common/components/fields/AddressFields';

export const mapSupplierModalFormToSupplierDTO = (supplierForm: SupplierEditForm): ISupplierCreateDTO => {
  const {
    address,
    city,
    postalCode,
    country,
    billingAddressAddress,
    billingAddressCity,
    billingAddressCountry,
    billingAddressPostalCode,
    hasNoBillingAddress,
    code,
    name,
    type,
    isLedgerEnabled,
    auxiliaryAccount,
    iban,
  } = supplierForm;

  const billingAddress = hasNoBillingAddress
    ? undefined
    : formatAddressDTO({
        address: billingAddressAddress,
        city: billingAddressCity,
        postalCode: billingAddressPostalCode,
        country: billingAddressCountry,
      });

  return {
    code: code ?? undefined,
    name,
    type,
    hasBillingAddress: !hasNoBillingAddress,
    address: formatAddressDTO({
      address,
      city,
      postalCode,
      country,
    }),
    billingAddress,
    isLedgerEnabled: isLedgerEnabled ?? false,
    auxiliaryAccount,
    iban,
  };
};
