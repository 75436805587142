import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import * as jobsApi from '../services/quote-job.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useDuplicateJob = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { findLotIdFromJobIdInRelations, insertJobsAfterJobInLotInRelations, updateDataLocally } =
    useQuoteEditContext();

  return useCallback(
    async (sourceJobId: number) => {
      const [err, quoteInfos] = await jobsApi.duplicateJob(sourceJobId);

      // Return if it failed and notify user
      if (err) {
        toast.error(t('quote:jobsStep.errors.duplicatingJobError'));
        return;
      }

      // Update local relations
      const lotId = findLotIdFromJobIdInRelations(sourceJobId)!;
      insertJobsAfterJobInLotInRelations(quoteInfos.jobs, lotId, sourceJobId);

      // Update lot containing duplicated job
      updateDataLocally(quoteInfos);
    },
    [findLotIdFromJobIdInRelations, insertJobsAfterJobInLotInRelations, updateDataLocally, toast, t],
  );
};
