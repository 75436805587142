import { useCallback } from 'react';
import type { KeysMatching } from '@graneet/lib-ui';
import { Button, SimpleCirclePlusIcon } from '@graneet/lib-ui';
import { Box, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { FieldValues } from 'graneet-form';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { ISupplier } from '@graneet/business-logic';

import { useSupplierCreate, useSupplierOptional, useSuppliers } from '../services/supplier.api';
import type { SupplierEditForm } from '../forms/supplier-edit-form';
import { mapSupplierModalFormToSupplierDTO } from '../services/supplier.form';

import { SupplierFieldOption } from './SupplierFieldOption';
import { SupplierEditModal } from './SupplierEditModal';

import { SingleSelectFieldPaginated } from 'features/common/components/SingleSelectFieldPaginated';
import { Rule } from 'features/form/rules/Rule';

type ValueReturned = number | undefined | null;

export type SupplierFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, ValueReturned> = KeysMatching<T, ValueReturned>,
> = {
  name: K;

  defaultInputValue?: string;

  isEditable?: boolean;
};

export const SupplierField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, ValueReturned> = KeysMatching<T, ValueReturned>,
>({
  name,
  defaultInputValue,
  isEditable = true,
}: SupplierFieldProps<T, K>) => {
  const { t } = useTranslation(['global', 'supplier']);

  const createSupplierModal = useDisclosure();

  const suppliers = useSuppliers({ storageStrategy: 'state' });

  const supplierCreateMutation = useSupplierCreate();

  const form = useFormContext<T>();

  const { [name]: fieldValue } = useOnChangeValues(form, [name]);

  const selectedSupplier = useSupplierOptional(fieldValue);

  const onSupplierCreated = useCallback(
    async (newSupplier: SupplierEditForm) => {
      const dto = mapSupplierModalFormToSupplierDTO(newSupplier);
      const response = await supplierCreateMutation.mutateAsync(dto);

      await suppliers.refetch();
      form.setFormValues({ [name]: response.id } as T);
      createSupplierModal.onClose();
    },
    [supplierCreateMutation, suppliers, form, name, createSupplierModal],
  );

  const mapFunction = useCallback(
    (supplier: ISupplier) => ({
      label: <SupplierFieldOption supplier={supplier} />,
      value: supplier.id,
    }),
    [],
  );

  return (
    <Grid templateColumns="30rem 6rem" gap={3} alignItems="center">
      <SingleSelectFieldPaginated<ISupplier, T, K>
        pagination={suppliers}
        name={name}
        initialSelectedValue={selectedSupplier?.data ?? undefined}
        selectedOptionColorScheme="white"
        placeholder={t('supplier:supplierField.placeholder')}
        mapFunction={mapFunction}
        isDisabled={!isEditable}
        key={defaultInputValue}
        defaultInputValue={defaultInputValue}
      >
        <Rule.IsRequired />
      </SingleSelectFieldPaginated>

      <GridItem>
        {!fieldValue && (
          <Box>
            <Button leftIcon={<SimpleCirclePlusIcon />} variant="outline" onClick={createSupplierModal.onOpen}>
              {t('supplier:createSupplier')}
            </Button>
          </Box>
        )}
        <SupplierEditModal
          title={t('supplier:createSupplier')}
          isOpen={createSupplierModal.isOpen}
          onSubmit={onSupplierCreated}
          onClose={createSupplierModal.onClose}
          submitLabel={t('supplier:createSupplierModal.create')}
          defaultSupplierName={defaultInputValue}
        />
      </GridItem>
    </Grid>
  );
};
