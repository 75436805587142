import type { FC } from 'react';
import { useCallback } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import type { ValueOf } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { DisplayNotFoundScreen } from '../../DisplayNotFoundScreen';

import { CreateInvoiceScreen } from './invoices/create/CreateInvoiceScreen';
import { CreateCreditScreen } from './cancel/CreateCreditScreen';
import { StatementDetailRouter } from './StatementDetailRouter';

import { StatementsListing } from 'features/statement/components/StatementsListing/StatementsListing';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { STATEMENT_PATHS } from 'features/statement/constants/statement.constant';

const formatStatementUrl = (type: ValueOf<typeof STATEMENT_PATHS>, statementId: number) =>
  `/sales/statements/${type}/${statementId}`;

export const StatementsRouter: FC = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const hasCreateStatementsPermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);
  const hasUpdateStatusStatementsPermission = usePermissions([PERMISSION.UPDATE_STATUS_STATEMENTS]);

  const onCreateCreditQuit = useCallback(
    ({ type, statementId }: { type: ValueOf<typeof STATEMENT_PATHS>; statementId: number }) =>
      history.replace(formatStatementUrl(type, statementId)),
    [history],
  );

  const onStatementCancelled = useCallback(
    ({ creditId }: { creditId: number }) => history.replace(formatStatementUrl(STATEMENT_PATHS.CREDIT, creditId)),
    [history],
  );

  return (
    <Switch>
      <Route exact path={path}>
        <StatementsListing />
      </Route>

      {hasCreateStatementsPermission && (
        <Route path={`${path}/invoices/create`}>
          <CreateInvoiceScreen />
        </Route>
      )}

      {hasCreateStatementsPermission && (
        <Route path={`${path}/invoices/:invoiceId/edit`}>
          <CreateInvoiceScreen />
        </Route>
      )}

      {hasUpdateStatusStatementsPermission && (
        <Route path={`${path}/:type/:statementId/cancel`}>
          <CreateCreditScreen onQuit={onCreateCreditQuit} onStatementCancelled={onStatementCancelled} />
        </Route>
      )}

      <Route path={path}>
        <StatementDetailRouter />
      </Route>

      <Route path="*">
        <DisplayNotFoundScreen />
      </Route>
    </Switch>
  );
};
