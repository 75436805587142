import type { FC, ReactNode } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { formatNumberToVatRate, useCurrency, useSignals, useToast, Button } from '@graneet/lib-ui';
import { HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type {
  IDiscountCreationDTO,
  IQuote,
  IQuoteComponent,
  IQuoteInfosResponse,
  IQuoteJob,
  IQuoteLot,
  IQuoteUpdateResponse,
  RequiredByKeys,
} from '@graneet/business-logic';
import { isNumberFinite, isQuoteJobComplete } from '@graneet/business-logic';
import type { FormContextApi } from 'graneet-form';

import * as quoteApi from '../services/quote.api';
import { useQuoteData } from '../hooks/useQuoteData';
import { TreeDataPreCalculation } from '../../common/services/treeDataPreCalculation/treeDataPreCalculation.util';
import type { QuoteEditLotStepForm } from '../forms/quote-edit-lot-step.form';
import { generateJobFieldName, generateLotFieldName } from '../forms/quote-edit-lot-step.form';
import type { QuoteLotData } from '../../quote-lot/types/QuoteLotData';
import {
  BUILD_COMPONENTS_SIGNAL_KEY,
  BUILD_JOB_SIGNAL_KEY,
  BUILD_LOT_SIGNAL_KEY,
  BUILD_QUOTE_SIGNAL_KEY,
} from '../services/quote.util';

import type { IQuoteEditContext } from './QuoteEditContext.context';
import { QuoteEditContext } from './QuoteEditContext.context';

import { useJobMoveToast } from 'features/quote-job/hooks/useJobMoveToast';
import { getLotSignalData } from 'features/quote-lot/services/quote-lot.util';
import { useStartAnotherUpdate } from 'features/common/hooks/useStartAnotherUpdate';
import { useStore } from 'store/store';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

const NOOP = () => {};

const SIGNAL_QUOTE_IS_LOADING = 'quote-is-loading';
const SIGNAL_QUOTE_LOT_MAX_DEPTH = 'quote-lot-max-depth';

interface QuoteEditProviderProps {
  quoteId: number;
  children: ReactNode;
}

export const QuoteEditProvider: FC<QuoteEditProviderProps> = ({ quoteId, children }) => {
  // -- GLOBAL

  const toast = useToast();
  const { t } = useTranslation(['global', 'discount']);
  const { mapNumberToAmount } = useCurrency();

  const autoNumberingSetTable = useStore((state) => state.autoNumberingSetTable);
  const optionalLotsSetTable = useStore((state) => state.optionalLotsSetTable);
  const optionalLotsSetTableWithoutJob = useStore((state) => state.optionalLotsSetTableWithoutJob);
  const optionalLotsSetTableStatus = useStore(useCallback((state) => state.optionalLotsSetTableStatus, []));
  const setHasHiddenCosts = useStore(useCallback((state) => state.setHasHiddenCosts, []));

  /**
   * Signals are heavily used throughout here to MANUALLY inform components when
   * they should re-render themselves
   *
   * It is used for updates in jobs, lots, quote, reversal of liability
   * settings aso...
   */
  const { emit, listen } = useSignals();

  const { currentQuoteData, emitRootLotId, findMaxLotDepth, useRootLotId } = useQuoteData(emit, listen);

  // ---- Utils: Finding lots

  const findLotIdFromJobIdInRelations = useCallback(
    (jobId: number) =>
      Object.entries(currentQuoteData.relations).reduce<number | null>(
        (memory, [lotId, { jobs: jobsIds }]) => memory ?? (jobsIds.includes(jobId) ? parseInt(lotId, 10) : null),
        null,
      ),
    [currentQuoteData],
  );

  const findOwnerLotIdFromLotIdInRelations = useCallback(
    (currentLotId: number) =>
      Object.entries(currentQuoteData.relations).reduce<number | null>(
        (memory, [lotId, { lots: lotsIds }]) => memory ?? (lotsIds.includes(currentLotId) ? parseInt(lotId, 10) : null),
        null,
      ),
    [currentQuoteData],
  );

  const findLastLotIdInLot = useCallback(
    (lotId: number) => {
      const subLotsIds = currentQuoteData.relations[lotId]?.lots;
      if (!subLotsIds || !subLotsIds.length) return null;
      return subLotsIds[subLotsIds.length - 1];
    },
    [currentQuoteData.relations],
  );

  const findRootLotLastSublotId = useCallback(() => {
    const { rootLotId } = currentQuoteData;
    if (!rootLotId) return null;
    const rootLotSubLots = currentQuoteData.relations[rootLotId]?.lots;
    if (!rootLotSubLots) return null;
    const count = rootLotSubLots.length;
    if (!count) return null;
    return rootLotSubLots[count - 1];
  }, [currentQuoteData]);

  const findNumberOfIncompleteJobsInLot = useCallback(
    (lotId: number) => {
      if (!currentQuoteData.relations[lotId]) {
        return 0;
      }

      const countIncompleteJobs = (subLotJobIds: number[]) =>
        subLotJobIds.reduce((acc, jobId) => acc + (!isQuoteJobComplete(currentQuoteData.jobs[jobId]) ? 1 : 0), 0);

      const getIncompleteJobsLots = (nestedLotId: number): number => {
        const { jobs: subLotJobs, lots: subLots } = currentQuoteData.relations[nestedLotId];
        const incompleteLotJobs = countIncompleteJobs(subLotJobs);

        return subLots.reduce((acc, subLot) => acc + getIncompleteJobsLots(subLot), incompleteLotJobs);
      };

      return getIncompleteJobsLots(lotId);
    },
    [currentQuoteData],
  );

  // -- QUOTE - Loading state

  /**
   * Debounce loading state by 800ms everytime we start a new update
   * and notify its signal after it comes back to false
   */
  const emitIsLoading = useCallback((value: boolean) => emit(SIGNAL_QUOTE_IS_LOADING, value), [emit]);
  const startAnotherUpdate = useStartAnotherUpdate(emitIsLoading);

  const onQuoteSavingChange = useCallback(
    (handler: (newValue: boolean) => void) => {
      listen(SIGNAL_QUOTE_IS_LOADING, handler, false);
    },
    [listen],
  );

  // -- QUOTE

  /**
   * The form used in the quote that we use to update the values
   * of our fields when the api replies with new values
   */
  const quoteFormRef = useRef<FormContextApi<QuoteEditLotStepForm>>({ setFormValues: NOOP } as any);
  const setQuoteForm = useCallback((form: FormContextApi<QuoteEditLotStepForm>) => {
    quoteFormRef.current = form;
  }, []);

  /**
   * Notify quote watcher when quote has changed.
   */
  const emitQuoteUpdate = useCallback(
    (updatedQuote: IQuote) => {
      const key = BUILD_QUOTE_SIGNAL_KEY();
      currentQuoteData.quote = updatedQuote;
      emit(key, updatedQuote);

      const treeDataPreCalculation = new TreeDataPreCalculation(currentQuoteData.relations, currentQuoteData.jobs);
      autoNumberingSetTable(treeDataPreCalculation.autoNumberingTable);
      optionalLotsSetTable(treeDataPreCalculation.optionalLotsTable);
      optionalLotsSetTableWithoutJob(treeDataPreCalculation.optionalLotsWithoutJobInTheseChildren);
      optionalLotsSetTableStatus(treeDataPreCalculation.optionalLotsTableStatus);
      setHasHiddenCosts(treeDataPreCalculation.hiddenCostStatus);
    },
    [
      emit,
      currentQuoteData,
      autoNumberingSetTable,
      optionalLotsSetTable,
      optionalLotsSetTableWithoutJob,
      optionalLotsSetTableStatus,
      setHasHiddenCosts,
    ],
  );

  // -- LOTS

  /**
   * Updates the fields of a lot row with `lot` being usually the
   * new value coming from the api
   */
  const updateLotFormFields = useCallback((lot: IQuoteLot, oldLot?: IQuoteLot) => {
    const generateFieldName = generateLotFieldName(lot.id);

    const existingValues = quoteFormRef.current.getFormValues();

    const formValues: Partial<QuoteEditLotStepForm> = {};
    if (!oldLot || !existingValues[generateFieldName('code')] || oldLot.code !== lot.code) {
      formValues[generateFieldName('code')] = lot.code;
    } else {
      formValues[generateFieldName('code')] = existingValues[generateFieldName('code')];
    }
    if (!oldLot || !existingValues[generateFieldName('note')] || oldLot.note !== lot.note) {
      formValues[generateFieldName('note')] = lot.note;
    } else {
      formValues[generateFieldName('note')] = existingValues[generateFieldName('note')];
    }
    if (!oldLot || !existingValues[generateFieldName('description')] || oldLot.description !== lot.description) {
      formValues[generateFieldName('description')] = lot.description;
    } else {
      formValues[generateFieldName('description')] = existingValues[generateFieldName('description')];
    }
    quoteFormRef.current.setFormValues(formValues);
  }, []);

  // ---- Watching lots

  /**
   * Notify a lot component when its own data (incl. its jobs)
   * has changed.
   */
  const emitLotUpdate = useCallback(
    (lotId: number) => {
      if (!lotId) return;
      const key = BUILD_LOT_SIGNAL_KEY(lotId);
      const data = getLotSignalData(lotId, currentQuoteData);
      emit(key, data);
    },
    [emit, currentQuoteData],
  );

  const listenToLot = useCallback(
    (lotId: number, listener: (newValue: QuoteLotData) => void) => {
      if (!lotId) return () => undefined;
      const key = BUILD_LOT_SIGNAL_KEY(lotId);
      const data = getLotSignalData(lotId, currentQuoteData)!;
      return listen(key, listener, data);
    },
    [listen, currentQuoteData],
  );

  const emitMaxLotDepthUpdate = useCallback(() => {
    emit(SIGNAL_QUOTE_LOT_MAX_DEPTH, findMaxLotDepth());
  }, [emit, findMaxLotDepth]);

  const listenToMaxLotDepth = useCallback(
    (listener: (newValue: number) => void) => listen(SIGNAL_QUOTE_LOT_MAX_DEPTH, listener, findMaxLotDepth() || 0),
    [listen, findMaxLotDepth],
  );

  // ---- Checking lots

  const hasLotSubLots = useCallback(
    (lotId: number) => currentQuoteData.relations[lotId]?.lots.length > 0,
    [currentQuoteData.relations],
  );

  const hasLotJobs = useCallback(
    (lotId: number) => currentQuoteData.relations[lotId]?.jobs.length > 0,
    [currentQuoteData.relations],
  );

  const isLotDescendantOfOtherLot = useCallback(
    (lotId: number, otherLotId: number): boolean => {
      const { lots } = currentQuoteData.relations[otherLotId];
      if (!lots) {
        return false;
      }
      if (lots.includes(lotId)) {
        return true;
      }
      return lots.reduce((ok, sublotId) => ok || isLotDescendantOfOtherLot(lotId, sublotId), false);
    },
    [currentQuoteData.relations],
  );

  const isLotBeforeOtherLot = useCallback(
    (lotId: number, nextLotId: number) => {
      const ownerLotId = findOwnerLotIdFromLotIdInRelations(lotId);
      if (ownerLotId === null) return false;

      const nextOwnerLotId = findOwnerLotIdFromLotIdInRelations(nextLotId);
      if (nextOwnerLotId === null) return false;

      if (ownerLotId !== nextOwnerLotId) return false;

      const lotIdPosition = currentQuoteData.relations[ownerLotId].lots.indexOf(lotId);
      const nextLotIdPosition = currentQuoteData.relations[ownerLotId].lots.indexOf(nextLotId);

      return lotIdPosition === nextLotIdPosition - 1;
    },
    [currentQuoteData.relations, findOwnerLotIdFromLotIdInRelations],
  );

  // ---- Iterating on lots

  const forEachDescendingLot = useCallback(
    (lotId: number, callback: (quoteLot: IQuoteLot) => void) => {
      const lot = currentQuoteData.lots[lotId];
      if (lot === undefined) return;

      callback(lot);

      const sublotsIds = currentQuoteData.relations[lotId]?.lots;
      if (sublotsIds === undefined) return;

      sublotsIds.forEach((sublotsId) => forEachDescendingLot(sublotsId, callback));
    },
    [currentQuoteData.lots, currentQuoteData.relations],
  );

  // ---- Editing lots locally

  /**
   * Updates local lots then updates all the lots form fields and notifies changes
   * to listening components so they re-render themselves
   */
  const updateLotsLocally = useCallback(
    (lotsResults: Record<number, IQuoteLot>) => {
      const oldLots = currentQuoteData.lots;
      // Before all update and render, all new lots and jobs have to be stored
      Object.entries(lotsResults).forEach(([lotId, lot]) => {
        currentQuoteData.lots[lotId as any] = lot;
      });

      // Then we are able to update form and table
      Object.entries(lotsResults).forEach(([lotId, lot]) => {
        emitLotUpdate(lotId as any);
        const oldLot = oldLots[lotId as any];

        updateLotFormFields(lot, oldLot);
      });
    },
    [emitLotUpdate, updateLotFormFields, currentQuoteData],
  );

  /**
   * Saves locally if a lot should be expanded or not.
   */
  const setLotIsExpanded = useCallback(
    (lotId: number, isExpanded: boolean) => {
      currentQuoteData.expandedLotsIds[lotId] = isExpanded;
      const key = BUILD_LOT_SIGNAL_KEY(lotId);
      const data = getLotSignalData(lotId, currentQuoteData);
      emit(key, data);
    },
    [currentQuoteData, emit],
  );

  /**
   * Apply a depth delta locally to a lot and all its descendant sub-lots
   */
  const updateLotAndSublotsDepthLocally = useCallback(
    (lotId: number, depthDelta: number) => {
      forEachDescendingLot(lotId, (lot) => {
        lot.depth += depthDelta; // eslint-disable-line no-param-reassign
      });
    },
    [forEachDescendingLot],
  );

  /**
   * Destructive operation. Deletes a lot and all its sub-lots locally, for good.
   */
  const deleteLotAndItsSubLotsLocally = useCallback(
    (lotIdToDelete: number) => {
      const jobsIdsToDelete = currentQuoteData.relations[lotIdToDelete].jobs;

      if (currentQuoteData.lots[lotIdToDelete]) {
        const subLotsIds = currentQuoteData.relations[lotIdToDelete].lots;
        if (subLotsIds) {
          subLotsIds.forEach((subLotId) => deleteLotAndItsSubLotsLocally(subLotId));
        }
        delete currentQuoteData.lots[lotIdToDelete];
      }

      // Delete all components and jobs
      jobsIdsToDelete.forEach((jobId) => {
        const {
          [jobId]: { components },
        } = currentQuoteData.jobs;
        components.forEach((component) => {
          delete currentQuoteData.components[component.id];
        });

        delete currentQuoteData.jobs[jobId];
      });
    },
    [currentQuoteData],
  );

  /**
   * Useful for a non-destructive local removal of a lot. See this as a shallow removal.
   * No sub-lots or jobs are moved in the process.
   * This is used mainly for moving lots around, as we need to detach them first (using
   * `removeLotLocally(lotId)`) before we add it again later on.
   */
  const removeLotLocally = useCallback(
    (lotId: number) => {
      const ownerLotId = findOwnerLotIdFromLotIdInRelations(lotId);

      if (ownerLotId === null) throw new Error('No parent lot was found');
      const lotSiblings = currentQuoteData.relations[ownerLotId]?.lots;

      if (!lotSiblings) throw new Error('Could not find siblings');
      const lotPosition = lotSiblings.indexOf(lotId);

      if (lotPosition < 0) throw new Error('Could not locate lot amidst its siblings');
      lotSiblings.splice(lotPosition, 1);

      return { ownerLotId };
    },
    [currentQuoteData.relations, findOwnerLotIdFromLotIdInRelations],
  );

  /**
   * Inserts an array of sub-lots into a lot after one of its children.
   * The initial order of the new sub-lots is preserved once inserted.
   */
  const insertLotsAfterOtherLotLocally = useCallback(
    (responseLots: Record<number, IQuoteLot>, lotId: number, previousLotId: number) => {
      const previousLotIndex = currentQuoteData.relations[lotId]?.lots.indexOf(previousLotId);
      // Upsert lots objects in the context ref
      Object.values(responseLots).forEach((lot) => {
        currentQuoteData.lots[lot.id] = lot;
        currentQuoteData.relations[lot.id] = {
          lots: [],
          jobs: [],
        };
      });

      // Insert new lots in relations after given previous job id
      currentQuoteData.relations[lotId].lots.splice(
        previousLotIndex + 1,
        0,
        ...Object.keys(responseLots).map(parseInt),
      );
    },
    [currentQuoteData],
  );

  const moveLotBeforeOtherLotLocally = useCallback(
    (lotId: number, nextLotId: number) => {
      const { ownerLotId } = removeLotLocally(lotId);

      const nextOwnerLotId = findOwnerLotIdFromLotIdInRelations(nextLotId);
      if (nextOwnerLotId === null) throw new Error('No next parent lot was found');

      const nextLotSiblings = currentQuoteData.relations[nextOwnerLotId]?.lots;
      if (!nextLotSiblings) throw new Error('Could not find siblings');

      const nextLotPosition = nextLotSiblings.indexOf(nextLotId);
      if (nextLotPosition < 0) throw new Error('Could not locate lot amidst its siblings');

      nextLotSiblings.splice(nextLotPosition, 0, lotId);

      const { lots } = currentQuoteData;
      const depthFrom = lots[ownerLotId]?.depth;
      const depthTo = lots[nextOwnerLotId]?.depth;
      if (depthFrom !== undefined && depthTo !== undefined) {
        updateLotAndSublotsDepthLocally(lotId, depthTo - depthFrom);
      }

      emitLotUpdate(ownerLotId);
      emitLotUpdate(nextOwnerLotId);

      return {
        parentLotId: nextOwnerLotId,
        previousLotId: nextLotSiblings[nextLotPosition - 1] ?? null,
      };
    },
    [
      currentQuoteData,
      emitLotUpdate,
      findOwnerLotIdFromLotIdInRelations,
      removeLotLocally,
      updateLotAndSublotsDepthLocally,
    ],
  );

  const moveLotAfterOtherLotLocally = useCallback(
    (lotId: number, previousLotId?: number, newParentLotId?: number) => {
      const { ownerLotId } = removeLotLocally(lotId);

      let nextOwnerLotId: number | undefined | null = newParentLotId;

      if (nextOwnerLotId === undefined && previousLotId !== undefined) {
        nextOwnerLotId = findOwnerLotIdFromLotIdInRelations(previousLotId);
      }

      if (!isNumberFinite(nextOwnerLotId)) throw new Error('No next parent lot was found');

      const nextLotSiblings = currentQuoteData.relations[nextOwnerLotId]?.lots;
      if (!nextLotSiblings) throw new Error('Could not find siblings');

      let nextLotPosition = -1;
      if (isNumberFinite(previousLotId)) {
        nextLotPosition = nextLotSiblings.indexOf(previousLotId);

        if (nextLotPosition < 0) throw new Error('Could not locate lot amidst its siblings');
      }
      nextLotSiblings.splice(nextLotPosition + 1, 0, lotId);

      const { lots } = currentQuoteData;
      const depthFrom = lots[ownerLotId]?.depth;
      const depthTo = lots[nextOwnerLotId]?.depth;
      if (depthFrom !== undefined && depthTo !== undefined) {
        updateLotAndSublotsDepthLocally(lotId, depthTo - depthFrom);
      }

      emitLotUpdate(ownerLotId);
      emitLotUpdate(nextOwnerLotId);

      return {
        parentLotId: nextOwnerLotId,
        previousLotId: previousLotId ?? null,
      };
    },
    [
      currentQuoteData,
      emitLotUpdate,
      findOwnerLotIdFromLotIdInRelations,
      removeLotLocally,
      updateLotAndSublotsDepthLocally,
    ],
  );

  // -- JOBS

  /**
   * Update quote job fields with job given
   */
  const updateJobFormFields = useCallback(
    (
      job: RequiredByKeys<IQuoteJob, 'margin' | 'components'>,
      oldJob?: RequiredByKeys<IQuoteJob, 'margin' | 'components'>,
    ) => {
      const generateFieldName = generateJobFieldName(job.id);

      const existingValues = quoteFormRef.current.getFormValues();

      const formValues: Partial<QuoteEditLotStepForm> = {};
      if (!oldJob || !existingValues[generateFieldName('code')] || oldJob.code !== job.code) {
        formValues[generateFieldName('code')] = job.code;
      } else {
        formValues[generateFieldName('code')] = existingValues[generateFieldName('code')];
      }
      if (!oldJob || !existingValues[generateFieldName('note')] || oldJob.note !== job.note) {
        formValues[generateFieldName('note')] = job.note;
      } else {
        formValues[generateFieldName('note')] = existingValues[generateFieldName('note')];
      }
      if (!oldJob || !existingValues[generateFieldName('description')] || oldJob.description !== job.description) {
        formValues[generateFieldName('description')] = job.description;
      } else {
        formValues[generateFieldName('description')] = existingValues[generateFieldName('description')];
      }
      if (!oldJob || !existingValues[generateFieldName('unit')] || oldJob.unit !== job.unit) {
        formValues[generateFieldName('unit')] = job.unit;
      } else {
        formValues[generateFieldName('unit')] = existingValues[generateFieldName('unit')];
      }
      if (
        !oldJob ||
        !existingValues[generateFieldName('totalMargin')] ||
        oldJob.margin.totalMargin !== job.margin.totalMargin
      ) {
        formValues[generateFieldName('totalMargin')] = job.margin.totalMargin;
      } else {
        formValues[generateFieldName('totalMargin')] = existingValues[generateFieldName('totalMargin')];
      }
      if (
        !oldJob ||
        !existingValues[generateFieldName('quantity')] ||
        (oldJob.quantity !== job.quantity && oldJob.quantityFormula !== job.quantityFormula)
      ) {
        formValues[generateFieldName('quantity')] = {
          value: job.quantity,
          content: job.quantityFormula,
        };
      } else {
        formValues[generateFieldName('quantity')] = existingValues[generateFieldName('quantity')];
      }
      if (!oldJob || !existingValues[generateFieldName('vatRate')] || oldJob.vatRate !== job.vatRate) {
        formValues[generateFieldName('vatRate')] = formatNumberToVatRate(job.vatRate);
      } else {
        formValues[generateFieldName('vatRate')] = existingValues[generateFieldName('vatRate')];
      }
      if (
        !oldJob ||
        !existingValues[generateFieldName('unitPriceExVAT')] ||
        oldJob.unitPriceExVAT !== job.unitPriceExVAT
      ) {
        formValues[generateFieldName('unitPriceExVAT')] = isNumberFinite(job.unitPriceExVAT)
          ? mapNumberToAmount(job.unitPriceExVAT)
          : null;
      } else {
        formValues[generateFieldName('unitPriceExVAT')] = existingValues[generateFieldName('unitPriceExVAT')];
      }
      if (
        !oldJob ||
        !existingValues[generateFieldName('unitDisbursementExVAT')] ||
        oldJob.unitDisbursementExVAT !== job.unitDisbursementExVAT
      ) {
        formValues[generateFieldName('unitDisbursementExVAT')] = isNumberFinite(job.unitDisbursementExVAT)
          ? mapNumberToAmount(job.unitDisbursementExVAT)
          : null;
      } else {
        formValues[generateFieldName('unitDisbursementExVAT')] =
          existingValues[generateFieldName('unitDisbursementExVAT')];
      }

      quoteFormRef.current.setFormValues(formValues);
    },
    [mapNumberToAmount],
  );

  const showJobMoveToast = useJobMoveToast();

  // ---- Watching jobs

  /**
   * Notify a job component when its own data has changed.
   */
  const emitJobUpdate = useCallback(
    (jobId: number) => {
      const key = BUILD_JOB_SIGNAL_KEY(jobId);
      const job = currentQuoteData.jobs[jobId];
      emit(key, job);
    },
    [emit, currentQuoteData],
  );

  const listenToJob = useCallback(
    (jobId: number, listener: (newValue: IQuoteJob) => void) => {
      const key = BUILD_JOB_SIGNAL_KEY(jobId);
      const matchingJob = currentQuoteData.jobs[jobId];
      return listen(key, listener, matchingJob);
    },
    [listen, currentQuoteData],
  );

  // ---- Finding jobs

  const getJob = useCallback((jobId: number) => currentQuoteData.jobs[jobId], [currentQuoteData]);

  // ---- Checking on jobs

  /**
   * Return a tuple containing:
   * - A boolean `true` if jobId1 does come after jobId2, `false` otherwise
   * - The id when both jobs share the same parent lot, `null` otherwise
   */
  const areJobsInTheSameLot = useCallback(
    (jobId1: number, jobId2: number): [false, null] | [true, number] => {
      const lotId1 = findLotIdFromJobIdInRelations(jobId1);
      if (lotId1 === null) return [false, null];

      const lotId2 = findLotIdFromJobIdInRelations(jobId2);
      if (lotId2 === null) return [false, null];

      // If lots are different, they cannot be adjacent. Return false
      if (lotId2 !== lotId1) return [false, null];

      return [true, lotId1];
    },
    [findLotIdFromJobIdInRelations],
  );

  const isJobAfterOtherJob = useCallback(
    (sourceJobId: number, otherJobId: number) => {
      const [areJobsInSameLot, lotId] = areJobsInTheSameLot(sourceJobId, otherJobId);

      if (!areJobsInSameLot) return false;

      const otherJobs = currentQuoteData.relations[lotId]?.jobs;
      if (otherJobs === undefined) return false;

      const sourceJobIndex = otherJobs.indexOf(sourceJobId);
      const otherJobIndex = otherJobs.indexOf(otherJobId);
      return otherJobIndex + 1 === sourceJobIndex;
    },
    [areJobsInTheSameLot, currentQuoteData.relations],
  );

  const isJobBeforeOtherJob = useCallback(
    (sourceJobId: number, otherJobId: number) => {
      const [areJobsInSameLot, lotId] = areJobsInTheSameLot(sourceJobId, otherJobId);

      if (!areJobsInSameLot) return false;

      const otherJobs = currentQuoteData.relations[lotId]?.jobs;
      if (otherJobs === undefined) return false;

      const sourceJobIndex = otherJobs.indexOf(sourceJobId);
      const otherJobIndex = otherJobs.indexOf(otherJobId);

      return sourceJobIndex + 1 === otherJobIndex;
    },
    [areJobsInTheSameLot, currentQuoteData.relations],
  );

  // ---- Editing jobs locally

  /**
   * Updates local jobs, notifies listening components they should
   * re-render themselves and notifies changes
   */
  const updateJobsLocally = useCallback(
    (jobsResults: IQuoteUpdateResponse['jobs']) => {
      Object.entries(jobsResults).forEach(([jobId, job]) => {
        const oldJob = currentQuoteData.jobs[jobId as any];
        currentQuoteData.jobs[jobId as any] = job;
        updateJobFormFields(job, oldJob);
        emitJobUpdate(jobId as any);
      });
    },
    [emitJobUpdate, updateJobFormFields, currentQuoteData],
  );

  const removeJobLocally = useCallback(
    (sourceJobId: number) => {
      const sourceLotId = findLotIdFromJobIdInRelations(sourceJobId);

      if (!sourceLotId) {
        throw new Error('Not parent lot found');
      }

      const sourceJobs = currentQuoteData.relations[sourceLotId]?.jobs;
      if (!sourceJobs) throw new Error('No source jobs found');

      const sourceJobIndex = sourceJobs.indexOf(sourceJobId);
      if (sourceJobIndex < 0) throw new Error('No source job index found');

      sourceJobs.splice(sourceJobIndex, 1);
      return { sourceLotId };
    },
    [findLotIdFromJobIdInRelations, currentQuoteData],
  );

  const insertJobsAfterJobInLotInRelations = useCallback(
    (responseJobs: IQuoteUpdateResponse['jobs'], lotId: number, previousJobId: number) => {
      const existingJobIds: number[] = [];

      const previousJobIndex = currentQuoteData.relations[lotId]?.jobs.indexOf(previousJobId);
      // Upsert jobs objects in the context ref
      Object.values(responseJobs).forEach((job) => {
        if (currentQuoteData.jobs[job.id]) {
          existingJobIds.push(job.id);
        }
        currentQuoteData.jobs[job.id] = job;
      });

      // Insert new jobs in relations after given previous job id
      currentQuoteData.relations[lotId].jobs.splice(
        previousJobIndex + 1,
        0,
        ...Object.keys(responseJobs)
          .filter((jobId) => !existingJobIds.includes(parseInt(jobId, 10)))
          .map((jobId) => parseInt(jobId, 10)),
      );
    },
    [currentQuoteData],
  );

  const moveJobAfterOtherJobLocally = useCallback(
    (sourceJobId: number, targetPreviousJobId: number) => {
      if (sourceJobId === targetPreviousJobId) return null;

      const { sourceLotId } = removeJobLocally(sourceJobId);

      const targetLotId = findLotIdFromJobIdInRelations(targetPreviousJobId);
      if (!targetLotId) throw new Error('No target lot id found');

      const targetJobs = currentQuoteData.relations[targetLotId]?.jobs;
      if (!targetJobs) throw new Error('No target jobs found');

      const targetPreviousJobIndex = targetJobs.indexOf(targetPreviousJobId);
      if (targetPreviousJobIndex < 0) throw new Error('No target previous jobs index');

      targetJobs.splice(targetPreviousJobIndex + 1, 0, sourceJobId);

      emitLotUpdate(sourceLotId!);
      emitLotUpdate(targetLotId);

      return {
        previousJobId: targetPreviousJobId,
        parentLotId: targetLotId,
      };
    },
    [removeJobLocally, findLotIdFromJobIdInRelations, currentQuoteData.relations, emitLotUpdate],
  );

  const moveJobBeforeOtherJobLocally = useCallback(
    (sourceJobId: number, targetNextJobId: number) => {
      if (sourceJobId === targetNextJobId) return null;

      const { sourceLotId } = removeJobLocally(sourceJobId);

      const targetLotId = findLotIdFromJobIdInRelations(targetNextJobId);
      if (!targetLotId) throw new Error('No target lot id found');

      const targetJobs = currentQuoteData.relations[targetLotId]?.jobs;
      if (!targetJobs) throw new Error('No target jobs found');

      const targetNextJobIndex = targetJobs.indexOf(targetNextJobId);
      if (targetNextJobIndex < 0) throw new Error('No target previous jobs index');

      targetJobs.splice(targetNextJobIndex, 0, sourceJobId);

      emitLotUpdate(sourceLotId!);
      emitLotUpdate(targetLotId);

      return {
        previousJobId: targetNextJobIndex < 1 ? null : targetJobs[targetNextJobIndex - 1],
        parentLotId: targetLotId,
      };
    },
    [findLotIdFromJobIdInRelations, currentQuoteData.relations, emitLotUpdate, removeJobLocally],
  );

  const moveJobInsideLotLocally = useCallback(
    (sourceJobId: number, targetLotId: number) => {
      const targetJobs = currentQuoteData.relations[targetLotId]?.jobs;
      if (!targetJobs) throw new Error('No target jobs found');

      const lastTargetJobId = targetJobs[targetJobs.length - 1];
      if (lastTargetJobId === sourceJobId) {
        return null;
      }

      const { sourceLotId } = removeJobLocally(sourceJobId);

      const lastJobId = targetJobs[targetJobs.length - 1];
      targetJobs.push(sourceJobId);

      emitLotUpdate(sourceLotId!);
      if (targetLotId !== sourceLotId) {
        emitLotUpdate(targetLotId);
      }

      return {
        parentLotId: targetLotId,
        previousJobId: lastJobId ?? null,
      };
    },
    [currentQuoteData.relations, emitLotUpdate, removeJobLocally],
  );

  // -- COMPONENT

  // ---- Watching components

  /**
   * Notify a component when its own data
   * has changed.
   */
  const emitComponentUpdate = useCallback(() => {
    emit(BUILD_COMPONENTS_SIGNAL_KEY(), currentQuoteData.components);
  }, [emit, currentQuoteData]);

  const listenToComponents = useCallback(
    (listener: (newValue: Record<number, IQuoteComponent>) => void) =>
      listen(BUILD_COMPONENTS_SIGNAL_KEY(), listener, currentQuoteData.components),
    [listen, currentQuoteData],
  );

  // ---- Editing component types

  const updateComponentsLocally = useCallback(
    (newComponents: Record<number, IQuoteComponent>) => {
      if (newComponents) {
        // Before all updates and renders, all new components have to be stored
        Object.entries(newComponents).forEach(([componentId, component]) => {
          currentQuoteData.components[componentId as any] = component;
        });

        emitComponentUpdate();
      }
    },
    [currentQuoteData, emitComponentUpdate],
  );

  const deleteComponentLocally = useCallback(
    (componentIdToDelete: number) => {
      if (!currentQuoteData.components[componentIdToDelete]) {
        return;
      }
      delete currentQuoteData.components[componentIdToDelete];
    },
    [currentQuoteData.components],
  );

  // -- THIS QUOTE

  // ---- Watching this quote

  const getCurrentQuoteData = useCallback(() => currentQuoteData, [currentQuoteData]);
  const getQuoteId = useCallback(() => currentQuoteData.quote?.id ?? null, [currentQuoteData]);
  const hasRootLotId = useCallback(() => currentQuoteData.rootLotId !== null, [currentQuoteData]);
  const getRootLotId = useCallback(() => currentQuoteData.rootLotId, [currentQuoteData]);

  const listenToQuote = useCallback(
    (listener: (quote: IQuote) => void) => {
      const key = BUILD_QUOTE_SIGNAL_KEY();
      return listen(key, listener, currentQuoteData.quote);
    },
    [listen, currentQuoteData],
  );

  // ---- Editing this quote

  const updateDataLocally = useCallback(
    (response: Omit<IQuoteUpdateResponse, 'rootLotId'>) => {
      const { jobs: newJobs, lots: newLots, quote: newQuote, components: newComponents } = response;

      // Affect jobs returned by the api with updated amounts
      updateJobsLocally(newJobs);
      updateLotsLocally(newLots);
      updateComponentsLocally(newComponents);
      emitQuoteUpdate(newQuote);
      emitMaxLotDepthUpdate();
    },
    [emitMaxLotDepthUpdate, emitQuoteUpdate, updateJobsLocally, updateLotsLocally, updateComponentsLocally],
  );

  const setQuoteData = useCallback(
    (initialValues: IQuoteInfosResponse) => {
      Object.assign(currentQuoteData, initialValues);
      emitRootLotId();
      updateDataLocally(currentQuoteData);
    },
    [emitRootLotId, updateDataLocally, currentQuoteData],
  );

  const editQuoteDiscount = useCallback(
    async (newDiscount: IDiscountCreationDTO) => {
      const [err, result] = await quoteApi.putQuoteDiscount(quoteId, newDiscount);
      if (err) {
        toast.error(t('global:words.c.error'), t('discount:toasts.error', { email: SUPPORT_EMAIL }));
        return;
      }

      updateDataLocally(result);
    },
    [quoteId, updateDataLocally, toast, t],
  );

  const deleteQuoteDiscount = useCallback(async () => {
    const [err, result] = await quoteApi.deleteQuoteDiscount(quoteId);

    if (err) {
      toast.error(t('global:words.c.error'), t('discount:toasts.error', { email: SUPPORT_EMAIL }));
      return;
    }
    toast.success(t('global:words.c.success'), t('discount:toasts.deletion'));
    updateDataLocally(result);
  }, [quoteId, updateDataLocally, toast, t]);

  // ---- Mapping quote

  /**
   * Formats all jobs in a format expected by a `<Form />`
   * ```
   * {
   *   'job-1-code': 'lorem'
   *   'job-1-description': 'ipsum'
   *   ...
   *   'job-2-code': undefined
   *   'job-2-description': 'dolor'
   *   ...
   * }
   * ```
   */
  const getQuoteAsFormValues = useCallback(() => {
    const jobValues = Object.keys(currentQuoteData.jobs).reduce<QuoteEditLotStepForm>((acc, jobId) => {
      const job = currentQuoteData.jobs[jobId as any];
      if (!job) {
        return acc;
      }
      const generateKey = generateJobFieldName(jobId as any);

      acc[generateKey('code')] = job.code;
      acc[generateKey('note')] = job.note;
      acc[generateKey('description')] = job.description;
      acc[generateKey('unit')] = job.unit;
      acc[generateKey('quantity')] = {
        value: job.quantity,
        content: job.quantityFormula,
      };
      acc[generateKey('totalMargin')] = job.margin.totalMargin;
      acc[generateKey('vatRate')] = isNumberFinite(job.vatRate) ? formatNumberToVatRate(job.vatRate) : null;
      acc[generateKey('unitPriceExVAT')] = isNumberFinite(job.unitPriceExVAT)
        ? mapNumberToAmount(job.unitPriceExVAT)
        : null;
      acc[generateKey('unitDisbursementExVAT')] = isNumberFinite(job.unitDisbursementExVAT)
        ? mapNumberToAmount(job.unitDisbursementExVAT)
        : null;

      return acc;
    }, {});

    const lotValues = Object.keys(currentQuoteData.lots).reduce<QuoteEditLotStepForm>((acc, lotId) => {
      const lot = currentQuoteData.lots[lotId as any];
      if (!lot) {
        return acc;
      }
      const generateKey = generateLotFieldName(lotId as any);

      acc[generateKey('code')] = lot.code;
      acc[generateKey('note')] = lot.note;
      acc[generateKey('description')] = lot.description;

      return acc;
    }, {});

    return {
      ...jobValues,
      ...lotValues,
    };
  }, [currentQuoteData.jobs, currentQuoteData.lots, mapNumberToAmount]);

  const displayNeedsReload = useCallback(
    (message: ReactNode) => {
      toast.error(
        <HStack spacing={6}>
          <Text flexGrow={1}>{message}</Text>
          <Button variant="outline" color="white" onClick={() => window.location.reload()}>
            {t('global:words.c.refresh')}
          </Button>
        </HStack>,
        null,
        { isClosable: false, duration: null },
      );
    },
    [toast, t],
  );

  const context = useMemo<IQuoteEditContext>(
    () => ({
      // Global
      startAnotherUpdate,
      displayNeedsReload,

      // Quote
      getQuoteId,
      setQuoteData,
      getCurrentQuoteData,
      getQuoteAsFormValues,
      emitQuoteUpdate,
      listenToQuote,
      updateDataLocally,
      hasRootLotId,
      getRootLotId,
      editQuoteDiscount,
      deleteQuoteDiscount,
      onQuoteSavingChange,
      setQuoteForm,
      useRootLotId,

      // Lot
      listenToLot,
      emitLotUpdate,
      listenToMaxLotDepth,
      emitMaxLotDepthUpdate,
      findLotIdFromJobIdInRelations,
      findOwnerLotIdFromLotIdInRelations,
      findLastLotIdInLot,
      findRootLotLastSublotId,
      findNumberOfIncompleteJobsInLot,
      isLotBeforeOtherLot,
      isLotDescendantOfOtherLot,
      hasLotSubLots,
      hasLotJobs,
      deleteLotAndItsSubLotsLocally,
      insertLotsAfterOtherLotLocally,
      moveLotBeforeOtherLotLocally,
      moveLotAfterOtherLotLocally,
      setLotIsExpanded,
      forEachDescendingLot,

      // Job
      getJob,
      showJobMoveToast,
      insertJobsAfterJobInLotInRelations,
      isJobAfterOtherJob,
      isJobBeforeOtherJob,

      // jobs
      emitJobUpdate,
      listenToJob,
      moveJobBeforeOtherJobLocally,
      moveJobAfterOtherJobLocally,
      moveJobInsideLotLocally,

      // Components
      updateComponentsLocally,
      deleteComponentLocally,
      listenToComponents,
    }),
    [
      startAnotherUpdate,
      displayNeedsReload,
      getQuoteId,
      setQuoteData,
      getCurrentQuoteData,
      getQuoteAsFormValues,
      emitQuoteUpdate,
      listenToQuote,
      updateDataLocally,
      hasRootLotId,
      getRootLotId,
      editQuoteDiscount,
      deleteQuoteDiscount,
      onQuoteSavingChange,
      setQuoteForm,
      useRootLotId,
      listenToLot,
      emitLotUpdate,
      listenToMaxLotDepth,
      emitMaxLotDepthUpdate,
      findLotIdFromJobIdInRelations,
      findOwnerLotIdFromLotIdInRelations,
      findLastLotIdInLot,
      findRootLotLastSublotId,
      findNumberOfIncompleteJobsInLot,
      isLotBeforeOtherLot,
      isLotDescendantOfOtherLot,
      hasLotSubLots,
      hasLotJobs,
      deleteLotAndItsSubLotsLocally,
      insertLotsAfterOtherLotLocally,
      moveLotBeforeOtherLotLocally,
      moveLotAfterOtherLotLocally,
      setLotIsExpanded,
      forEachDescendingLot,
      getJob,
      showJobMoveToast,
      insertJobsAfterJobInLotInRelations,
      isJobAfterOtherJob,
      isJobBeforeOtherJob,
      emitJobUpdate,
      listenToJob,
      moveJobBeforeOtherJobLocally,
      moveJobAfterOtherJobLocally,
      moveJobInsideLotLocally,
      updateComponentsLocally,
      deleteComponentLocally,
      listenToComponents,
    ],
  );

  return <QuoteEditContext.Provider value={context}>{children}</QuoteEditContext.Provider>;
};
