import { Route, Switch } from 'react-router-dom';

import { ContactsRouter } from './contacts/ContactsRouter';
import { ViewDashboardScreen } from './dashboard/ViewDashboardScreen';
import { OpportunitiesRouter } from './opportunities/OpportunitiesRouter';
import { ProjectsRouter } from './projects/ProjectsRouter';
import { UsersRouter } from './users/UsersRouter';
import { DisplayNotFoundScreen } from './DisplayNotFoundScreen';
import { ViewAccessForbiddenScreen } from './ViewAccessForbiddenScreen';
import { TimeTrackingRouter } from './time-tracking/TimeTrackingRouter';
import { PdfSettingsRouter } from './settings/company/pdf-settings/PdfSettingsRouter';
import { PurchasesRouter } from './purchases/PurchasesRouter';
import { SalesRouter } from './sales/SalesRouter';
import { AccountingRouter } from './accounting/AccountingRouter';
import { SettingsRouter } from './settings/SettingsRouter';
import { BankingRouter } from './banking/BankingRouter';

import { QueryWrapper } from 'features/api/components/QueryWrapper';

export const RootScreen = () => (
  <QueryWrapper>
    <Switch>
      <Route path="/" exact>
        <ViewDashboardScreen />
      </Route>

      <Route path="/forbidden" exact>
        <ViewAccessForbiddenScreen />
      </Route>

      <Route path="/contacts">
        <ContactsRouter />
      </Route>

      <Route path="/projects">
        <ProjectsRouter />
      </Route>

      <Route path="/settings">
        <SettingsRouter />
      </Route>

      <Route path="/opportunities">
        <OpportunitiesRouter />
      </Route>

      <Route path="/sales">
        <SalesRouter />
      </Route>

      <Route path="/users">
        <UsersRouter />
      </Route>

      <Route path="/pdf-settings">
        <PdfSettingsRouter />
      </Route>

      <Route path="/time-tracking">
        <TimeTrackingRouter />
      </Route>

      <Route path="/purchases">
        <PurchasesRouter />
      </Route>

      <Route path="/accounting">
        <AccountingRouter />
      </Route>

      <Route path="/banking">
        <BankingRouter />
      </Route>

      <Route path="*">
        <DisplayNotFoundScreen />
      </Route>
    </Switch>
  </QueryWrapper>
);
