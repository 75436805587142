import type { FC } from 'react';
import { useCallback } from 'react';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { StepperModal, useToast } from '@graneet/lib-ui';
import { capitalizeString, THIRD_PARTY } from '@graneet/business-logic';

import type { BatiprixEditConfigWizard, BatiprixTokenForm } from '../../forms/batiprix-edit-config.wizard';
import {
  getBatiprixUserTokenFieldName,
  getUserIdFromBatiprixUserIsActiveFieldName,
  isBatiprixUserIsActiveFieldName,
} from '../../forms/batiprix-edit-config.wizard';
import { useBatiprixInformation } from '../../services/batiprix.api';

import { BatiprixEditConfigComponentTypeStep } from './BatiprixEditConfigComponentTypeStep';
import { BatiprixEditConfigTokenStep } from './BatiprixEditConfigTokenStep';

import { useActivateThirdParty } from 'features/third-party/services/third-party.api';

interface BatiprixEditConfigModalProps {
  modal: UseDisclosureReturn;

  isEdition: boolean;
}

export const BatiprixEditConfigModal: FC<BatiprixEditConfigModalProps> = ({ modal, isEdition }) => {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const batiprixInformation = useBatiprixInformation();

  const activateThirdPartyMutation = useActivateThirdParty();

  const onFinish = useCallback(
    async (wizardValues: BatiprixEditConfigWizard) => {
      const activeUsers = (Object.keys(wizardValues.token) as (keyof BatiprixTokenForm)[]).reduce<
        { token: string; userId: number }[]
      >((acc, fieldName) => {
        if (isBatiprixUserIsActiveFieldName(fieldName) && wizardValues.token[fieldName]) {
          const userId = getUserIdFromBatiprixUserIsActiveFieldName(fieldName);

          return [
            ...acc,
            {
              userId,
              token: wizardValues.token[getBatiprixUserTokenFieldName(userId)],
            },
          ];
        }

        return acc;
      }, []);

      await activateThirdPartyMutation.mutateAsync(
        {
          thirdPartyName: 'batiprix',
          data: {
            materialComponentTypeId: wizardValues.componentType.materialComponentTypeId,
            workforceComponentTypeId: wizardValues.componentType.workforceComponentTypeId,
            activeUsers,
          },
        },
        {
          onSuccess: () => {
            modal.onClose();

            const isActivation = !!batiprixInformation.data.activeUsers.length;
            if (isActivation) {
              toast.success(
                t(`global:thirdParty.toasts.${isEdition ? 'updateToast' : 'enableToast'}`, {
                  thirdParty: capitalizeString(THIRD_PARTY.BATIPRIX),
                }),
              );
            } else {
              toast.success(
                t('global:thirdParty.toasts.updateToast', { thirdParty: capitalizeString(THIRD_PARTY.BATIPRIX) }),
              );
            }
          },
        },
      );
    },
    [activateThirdPartyMutation, batiprixInformation.data.activeUsers.length, isEdition, modal, t, toast],
  );

  const selectedMaterialComponentTypeId = batiprixInformation.data.componentTypes.find(
    (componentType) => componentType.batiprixType === 'material',
  )?.id;

  const selectedWorkforceComponentTypeId = batiprixInformation.data.componentTypes.find(
    (componentType) => componentType.batiprixType === 'workforce',
  )?.id;

  return (
    <StepperModal<BatiprixEditConfigWizard>
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      onFinish={onFinish}
      size="4xl"
      scrollBehavior="inside"
    >
      <>
        <StepperModal.Step<BatiprixEditConfigWizard>
          name="componentType"
          title={t('global:batiprixEditConfigModal.componentTypeStep.title')}
        >
          <BatiprixEditConfigComponentTypeStep
            selectedMaterialComponentTypeId={selectedMaterialComponentTypeId}
            selectedWorkforceComponentTypeId={selectedWorkforceComponentTypeId}
          />
        </StepperModal.Step>
        <StepperModal.Step<BatiprixEditConfigWizard>
          name="token"
          title={t('global:batiprixEditConfigModal.token.title')}
        >
          <BatiprixEditConfigTokenStep activeUsers={batiprixInformation.data.activeUsers} isEdition={isEdition} />
        </StepperModal.Step>
      </>
    </StepperModal>
  );
};
