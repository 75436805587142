import { useMemo } from 'react';

import type { ProgressStatementEditItemForm } from '../forms/progress-statement-edit-item-form';
import {
  isCustomDiscountAmountCumulativeField,
  isDiscountAmountCumulativeField,
  isItemAmountCumulativeField,
} from '../forms/progress-statement-edit-item-form';

export const useCumulativeProgressExVAT = (cumulativeValues: Partial<ProgressStatementEditItemForm>) => {
  const [itemAmountExVAT, discountExVAT, customDiscountAmountExVAT] = useMemo(
    () =>
      (Object.keys(cumulativeValues) as (keyof ProgressStatementEditItemForm)[]).reduce(
        (acc, key) => {
          const accItemAmountExVAT = acc[0] + (isItemAmountCumulativeField(key) ? cumulativeValues[key] || 0 : 0);
          const accDiscountAmountExVAT =
            acc[1] + (isDiscountAmountCumulativeField(key) ? cumulativeValues[key] || 0 : 0);

          const accCustomDiscountAmountExVAT =
            acc[2] + (isCustomDiscountAmountCumulativeField(key) ? cumulativeValues[key] || 0 : 0);

          return [accItemAmountExVAT, accDiscountAmountExVAT, accCustomDiscountAmountExVAT];
        },
        [0, 0, 0],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(cumulativeValues)],
  );

  return itemAmountExVAT - discountExVAT + customDiscountAmountExVAT;
};
