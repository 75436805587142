import { useLayoutEffect, type FC } from 'react';
import { Tabs } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { DisplayNotFoundScreen } from '../../DisplayNotFoundScreen';

import { ViewLibraryComponentsScreen } from './components/ViewLibraryComponentsScreen';
import { LibraryJobsRouter } from './jobs/LibraryJobsRouter';

import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const LibraryRouter: FC = () => {
  const { t } = useTranslation(['library', 'global']);
  const { updateHeaderTitle } = useHeaderContext();

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.library'));
  }, [t, updateHeaderTitle]);

  return (
    <Tabs
      hasInternalPadding
      fallbackPath="./jobs"
      data={[
        {
          id: 'libraryJobs',
          title: t('library:tabs.libraryJobs'),
          path: './jobs',
          content: <LibraryJobsRouter />,
        },
        {
          id: 'libraryComponents',
          title: t('library:tabs.libraryComponents'),
          path: './components',
          content: <ViewLibraryComponentsScreen />,
        },
      ]}
      errorComponent={<DisplayNotFoundScreen />}
    />
  );
};
