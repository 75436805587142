import type { FC } from 'react';
import { useCallback } from 'react';
import type { IBillReminderInfosResponse, IExportFiltersDTO, IReminderBulkSendDTO } from '@graneet/business-logic';
import { GraneetButton } from '@graneet/lib-ui';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BillRemindBatchModal } from './BillRemindBatchModal';

import { useBulkRemindBills } from 'features/reminder/services/reminder.api';

interface BillRemindBatchButtonProps {
  canBeRemindData: IBillReminderInfosResponse;

  currentFilters: IExportFiltersDTO<'BILL'>;

  onBillUpdate: () => void;
}

export const BillRemindBatchButton: FC<BillRemindBatchButtonProps> = ({
  canBeRemindData,
  currentFilters,
  onBillUpdate,
}) => {
  const { t } = useTranslation(['bill']);
  const modal = useDisclosure();

  const bulkRemindBillsMutation = useBulkRemindBills();

  const remindBills = useCallback(
    async (defaultEmailRecipientsOverride: IReminderBulkSendDTO['defaultEmailRecipientsOverride']) => {
      await bulkRemindBillsMutation.mutateAsync({ ...currentFilters, defaultEmailRecipientsOverride });
      modal.onClose();
      onBillUpdate();
    },
    [bulkRemindBillsMutation, currentFilters, modal, onBillUpdate],
  );

  const onClick = useCallback(async () => {
    if (canBeRemindData.projectsWithoutDefaultEmailRecipient.length > 0) {
      modal.onOpen();
      return;
    }
    await bulkRemindBillsMutation.mutateAsync({ ...currentFilters, defaultEmailRecipientsOverride: [] });
    modal.onClose();
    onBillUpdate();
  }, [
    bulkRemindBillsMutation,
    canBeRemindData.projectsWithoutDefaultEmailRecipient.length,
    currentFilters,
    modal,
    onBillUpdate,
  ]);

  if (!canBeRemindData.areAllRemindable) {
    return null;
  }

  return (
    <>
      <GraneetButton size="sm" onClick={onClick}>
        {t('bill:detail.remindPerMail')}
      </GraneetButton>

      <BillRemindBatchModal
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        projects={canBeRemindData.projectsWithoutDefaultEmailRecipient}
        onSubmit={remindBills}
      />
    </>
  );
};
