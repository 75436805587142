import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { GridItem, Stack } from '@chakra-ui/react';
import { useToast, Container } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IQuote, IProject, RequiredByKeys } from '@graneet/business-logic';
import { PERMISSION, isQuoteAccepted } from '@graneet/business-logic';

import { ProjectLinkAndUnlinkCard } from 'features/project/components/cards/ProjectLinkAndUnlinkCard';
import { updateQuoteAccountManagers, updateQuoteProject } from 'features/quote/services/quote.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { AccountManagerAssociationCard } from 'features/account-manager/components/AccountManagerAssociationCard/AccountManagerAssociationCard';
import { QuoteNoteCard } from 'features/quote/components/cards/QuoteNoteCard';
import { QuoteMentionsCard } from 'features/quote/components/cards/QuoteMentionsCard';

interface ViewQuoteInformationTabProps {
  quote: IQuote;
  reloadQuote: () => void;
}

export const ViewQuoteInformationTab: FC<ViewQuoteInformationTabProps> = ({ quote, reloadQuote }) => {
  const { t } = useTranslation(['quote', 'accountManager', 'global']);
  const toast = useToast();

  const hasPermissions = usePermissions([PERMISSION.CREATE_QUOTES]);

  const accountManagerUsers = useMemo(
    () => (quote.quoteAccountManagers || []).map(({ user }) => user),
    [quote.quoteAccountManagers],
  );

  const updateProject = useCallback(
    async (project: IProject | null) => {
      const projectId = project ? project.id : null;

      const [err] = await updateQuoteProject(quote.id, projectId);
      if (err) {
        toast.error(t('quote:cards.project.error'));
      } else {
        toast.success(t(`quote:cards.project.${projectId ? 'successLink' : 'successUnlink'}`));
        reloadQuote();
      }
    },
    [quote.id, toast, t, reloadQuote],
  );

  const handleAccountManagersUpdated = useCallback(
    async (accountManagersIds: number[]) => {
      const [error] = await updateQuoteAccountManagers(quote.id, { userIds: accountManagersIds });
      if (error) {
        toast.error(t('global:errors.error'));
        return;
      }

      toast.success(t('accountManager:toast.associatedSuccess'));

      await reloadQuote();
    },
    [quote.id, reloadQuote, t, toast],
  );

  const removeProject = useCallback(() => updateProject(null), [updateProject]);

  return (
    <Container size="md">
      <Stack mb={8} gap={4}>
        <GridItem>
          <ProjectLinkAndUnlinkCard
            project={quote?.project as RequiredByKeys<IProject, 'address'>}
            onLink={updateProject}
            onUnlink={removeProject}
            translations={{
              linkLabel: t('quote:cards.project.link'),
              unlinkLabel: t('quote:cards.project.unlink'),
              modalTitle: t('quote:cards.project.modalTitle'),
              modalDescription: t('quote:cards.project.modalDesc'),
            }}
            canUpdateLink={hasPermissions && !isQuoteAccepted(quote)}
          />
        </GridItem>

        <GridItem>
          <AccountManagerAssociationCard
            associatedAccountManagerUsers={accountManagerUsers}
            onAccountManagersUpdated={handleAccountManagersUpdated}
            emptyStateLabel={t('accountManager:quote.emptyState')}
            actionLabel={t('accountManager:cta.associateOneToQuote')}
            changeAssociatedLabel={t('accountManager:cta.changeAssociatedQuote')}
            modalActionLabel={t('accountManager:cta.associateToQuote')}
            modalTitleLabel={t('accountManager:quote.associateTitle')}
            modalDescriptionLabel={t('accountManager:quote.associateDescription')}
            hasUpdatePermission={hasPermissions}
          />
        </GridItem>
      </Stack>

      <QuoteNoteCard quote={quote} isReadOnly={!hasPermissions} />

      <QuoteMentionsCard quote={quote} isReadOnly={!hasPermissions} />
    </Container>
  );
};
