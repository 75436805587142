import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleCalendarIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" strokeWidth="0.2" {...props}>
    <path
      d="M14 6H1.99997C1.62664 6 1.33331 6.29333 1.33331 6.66666C1.33331 7.03333 1.62664 7.33333 1.99997 7.33333H14C14.3666 7.33333 14.6666 7.03333 14.6666 6.66666C14.6666 6.29333 14.3666 6 14 6ZM9.99997 1.33333V4C9.99997 4.36666 10.2933 4.66666 10.6666 4.66666C11.0333 4.66666 11.3333 4.36666 11.3333 4V1.33333C11.3333 0.959997 11.0333 0.666664 10.6666 0.666664C10.2933 0.666664 9.99997 0.959997 9.99997 1.33333ZM4.66664 1.33333V4C4.66664 4.36666 4.95997 4.66666 5.33331 4.66666C5.69997 4.66666 5.99997 4.36666 5.99997 4V1.33333C5.99997 0.959997 5.69997 0.666664 5.33331 0.666664C4.95997 0.666664 4.66664 0.959997 4.66664 1.33333ZM5.19997 15.3333H10.8C12.3333 15.3333 12.6933 15.3 13.2066 15.04C13.7066 14.78 14.1133 14.3733 14.3666 13.8733C14.6266 13.36 14.6533 12.9933 14.6533 11.46V5.86C14.6533 4.32 14.62 3.96 14.36 3.44666C14.1 2.94 13.6933 2.53333 13.1933 2.28C12.68 2.01333 12.3133 1.98666 10.78 1.98666H5.17997C3.63997 1.98666 3.27997 2.01333 2.76664 2.27333C2.25997 2.52666 1.85331 2.93333 1.59997 3.43333C1.33331 3.94 1.30664 4.30666 1.30664 5.84V11.44C1.30664 12.9733 1.33331 13.3333 1.59331 13.8467C1.84664 14.3467 2.25331 14.7533 2.75331 15.0067C3.25997 15.2667 3.62664 15.2933 5.15997 15.2933L5.19997 15.3333ZM5.19997 14C3.93331 14 3.62664 13.9733 3.39331 13.8533C3.13997 13.72 2.93331 13.52 2.80664 13.2667C2.67997 13.0267 2.65997 12.7267 2.65997 11.46V5.86C2.65997 4.59333 2.67997 4.28666 2.79997 4.05333C2.92664 3.8 3.12664 3.59333 3.37997 3.46666C3.61331 3.34 3.91331 3.32 5.17997 3.32H10.78C12.04 3.32 12.3466 3.34 12.58 3.46C12.8266 3.58666 13.0333 3.78666 13.16 4.04C13.28 4.27333 13.3 4.57333 13.3 5.84V11.44C13.3 12.7 13.2733 13.0067 13.1533 13.24C13.02 13.4867 12.82 13.6933 12.5666 13.82C12.3266 13.94 12.0266 13.96 10.76 13.96H5.15997L5.19997 14Z"
      fill="#627D98"
    />
  </Icon>
);
