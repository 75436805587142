import { HStack, Text, VStack } from '@chakra-ui/react';
import { ColorPickerField } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GRANEET_PDF_COLORS } from '@graneet/business-logic';

import type { PDFSettingsFormValues } from '../constants/pdf-settings.constant';

export const PDF_COLOR_PICKER_COLORS: Array<{ color: string; value: GRANEET_PDF_COLORS }> = [
  { color: 'white', value: GRANEET_PDF_COLORS.WHITE },
  { color: '#cbd5e0', value: GRANEET_PDF_COLORS.WARM_GRAY },
  { color: '#f6e05e', value: GRANEET_PDF_COLORS.YELLOW },
  { color: '#f6ad55', value: GRANEET_PDF_COLORS.ORANGE },
  { color: '#fc8181', value: GRANEET_PDF_COLORS.RED },
  { color: '#f687b3', value: GRANEET_PDF_COLORS.PINK },
  { color: '#b794f4', value: GRANEET_PDF_COLORS.PURPLE },
  { color: '#63b3ed', value: GRANEET_PDF_COLORS.BLUE },
  { color: '#76e4f7', value: GRANEET_PDF_COLORS.CYAN },
  { color: '#4fd1c5', value: GRANEET_PDF_COLORS.TEAL },
  { color: '#68d391', value: GRANEET_PDF_COLORS.GREEN },
  { color: '#888888', value: GRANEET_PDF_COLORS.GRAY },
];

export const PdfColorSettings: FC = () => {
  const { t } = useTranslation(['pdfSettings']);
  return (
    <VStack spacing={2} align="start" w="100%">
      <HStack spacing={2}>
        <Text color="black" fontSize="lg">
          {t('pdfSettings:settings.color.title')}
        </Text>
      </HStack>
      <ColorPickerField<PDFSettingsFormValues> name="color" colors={PDF_COLOR_PICKER_COLORS} isRequired />
    </VStack>
  );
};
