import { Box } from '@chakra-ui/react';
import type { IPdfSettings } from '@graneet/business-logic';
import {
  PDF_COMPANY_LOT_INFORMATION,
  GRANEET_PDF_COLORS,
  PDF_FONT,
  PDF_LABELS_POSITION,
} from '@graneet/business-logic';
import { Button, Callout, Card } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PDFSettingsFormValues } from '../constants/pdf-settings.constant';

export const generatePdfSettingsFormValues = (
  pdfSettings: IPdfSettings | undefined | null,
): Partial<PDFSettingsFormValues> => ({
  logoPosition: pdfSettings?.logoPosition,
  logoSize: pdfSettings?.logoSize,
  labelsSize: pdfSettings?.labelsSize,
  labelsDisplayed: pdfSettings?.labelsPosition && pdfSettings?.labelsPosition !== PDF_LABELS_POSITION.NONE,
  labelsPosition: pdfSettings?.labelsPosition,
  companyAddressPosition: pdfSettings?.companyAddressPosition,
  areAccountManagersVisible: pdfSettings?.areAccountManagersVisible,
  hasFirstPageCover: pdfSettings?.hasFirstPageCover,
  density: pdfSettings?.density,
  font: pdfSettings?.font ?? PDF_FONT.OPEN_SANS,
  color: pdfSettings?.color ?? GRANEET_PDF_COLORS.WARM_GRAY,
  displayQuoteContacts: pdfSettings?.displayQuoteContacts,
  lotInformation: pdfSettings?.lotInformation ?? PDF_COMPANY_LOT_INFORMATION.FULL,
  displayExternalProgressStatementQrCode: pdfSettings?.displayExternalProgressStatementQrCode,
});

interface PdfSettingsCopyBannerProps {
  hasLabels?: boolean;
  otherPdfSettings?: IPdfSettings | null;
}

export const PdfSettingsCopyBanner: FC<PdfSettingsCopyBannerProps> = ({ hasLabels = false, otherPdfSettings }) => {
  const { t } = useTranslation(['pdfSettings']);
  const form = useFormContext<PDFSettingsFormValues>();
  const { setFormValues } = form;
  const values = useOnChangeValues(form, undefined);

  const copyPdfSettings = useCallback(() => {
    const generatedPDFSettings = generatePdfSettingsFormValues(otherPdfSettings);
    // Cumulative progress is not shared between the settings so we have to remove it
    delete generatedPDFSettings.displayQuoteContacts;
    setFormValues(generatedPDFSettings);
  }, [setFormValues, otherPdfSettings]);

  const arePdfSettingsSame = useMemo(() => {
    if (!values || !otherPdfSettings) return false;
    const labelsEquality = hasLabels
      ? values.labelsPosition === otherPdfSettings.labelsPosition && values.labelsSize === otherPdfSettings.labelsSize
      : true;
    return (
      values.logoPosition === otherPdfSettings.logoPosition &&
      values.logoSize === otherPdfSettings.logoSize &&
      values.companyAddressPosition === otherPdfSettings.companyAddressPosition &&
      values.areAccountManagersVisible === otherPdfSettings.areAccountManagersVisible &&
      values.hasFirstPageCover === otherPdfSettings.hasFirstPageCover &&
      values.density === otherPdfSettings.density &&
      values.color === otherPdfSettings.color &&
      values.font === otherPdfSettings.font &&
      labelsEquality
    );
  }, [hasLabels, otherPdfSettings, values]);

  return (
    <Box mb={8}>
      {arePdfSettingsSame ? (
        <Card p={4} minH="110px">
          <Box w="100%" h="100%" alignItems="center" justifyContent="center" alignSelf="center">
            <Callout p={2} w="100%">
              {t('pdfSettings:sameSettingsDescription')}
            </Callout>
          </Box>
        </Card>
      ) : (
        <Card title={t('pdfSettings:differentSettingsDescription')} p={4} minH="110px">
          <Button onClick={copyPdfSettings} variant="outline">
            {t('pdfSettings:button.copy')}
          </Button>
        </Card>
      )}
    </Box>
  );
};
