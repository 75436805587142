import type { FC, ReactNode } from 'react';
import { useCallback } from 'react';
import { Box, HStack, Text, useBoolean } from '@chakra-ui/react';
import { Button, EmailAutocompleteField } from '@graneet/lib-ui';
import type {
  EmailAutocompleteContact,
  EmailAutocompleteFieldValue,
  KeysMatching,
  EmailAutocompleteProps,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Rule } from 'graneet-form';
import type { EmailTemplateData, IContact, IGroupedContact } from '@graneet/business-logic';
import { isEmail } from '@graneet/business-logic';

import { useGetContactsAvailableForMailing } from '../../hooks/useGetContactsAvailableForMailing';

import type { MailingFormValues } from './mailing.form';

export interface EmailRecipientsFieldGroupProps {
  projectContacts: IGroupedContact[];

  companyContacts: IContact[];

  variableData: EmailTemplateData;
}

type AutocompleteFieldWithNameAndTitleProps<K extends KeysMatching<MailingFormValues, EmailAutocompleteFieldValue>> = {
  name: K;
  label: string;
  groupedContacts: EmailAutocompleteProps['groupedContacts'];
  children?: ReactNode;
};

const AutocompleteFieldWithNameAndTitle = <K extends KeysMatching<MailingFormValues, EmailAutocompleteFieldValue>>({
  name,
  label,
  groupedContacts,
  children,
}: AutocompleteFieldWithNameAndTitleProps<K>) => {
  const { t } = useTranslation(['mailing']);

  return (
    <HStack alignItems="flex-start">
      <Text fontWeight={600} fontSize=".8em" mt={2} width="30px">
        {label}
      </Text>
      <EmailAutocompleteField<MailingFormValues>
        name={name}
        groupedContacts={groupedContacts}
        selectProps={{
          size: 'sm',
        }}
      >
        <Rule
          message={t('mailing:modal.check.invalidEmail')}
          validationFn={useCallback(
            (value: EmailAutocompleteContact[] | undefined) => !value || value.every((v) => isEmail(v.email)),
            [],
          )}
        />
        {children}
      </EmailAutocompleteField>
    </HStack>
  );
};

export const EmailRecipientsFieldGroup: FC<EmailRecipientsFieldGroupProps> = ({
  projectContacts,
  companyContacts,
  variableData,
}) => {
  const [isCcVisible, { on: showCcField }] = useBoolean(false);
  const [isBccVisible, { on: showBccField }] = useBoolean(false);

  const { t } = useTranslation(['contacts', 'mailing']);

  const groupedContacts = useGetContactsAvailableForMailing(projectContacts, companyContacts, variableData);

  return (
    <HStack alignItems="flex-start" w="100%">
      <Box flexGrow={1}>
        <AutocompleteFieldWithNameAndTitle
          name="to"
          label={t('mailing:recipientsFieldGroup.to')}
          groupedContacts={groupedContacts}
        >
          <Rule
            message={t('mailing:modal.check.recipientIsRequired')}
            validationFn={useCallback((value) => value && value.length !== 0, [])}
          />
        </AutocompleteFieldWithNameAndTitle>
        {isCcVisible && (
          <AutocompleteFieldWithNameAndTitle
            name="cc"
            label={t('mailing:recipientsFieldGroup.cc')}
            groupedContacts={groupedContacts}
          />
        )}
        {isBccVisible && (
          <AutocompleteFieldWithNameAndTitle
            name="bcc"
            label={t('mailing:recipientsFieldGroup.bcc')}
            groupedContacts={groupedContacts}
          />
        )}
      </Box>

      <HStack gap={2}>
        {!isCcVisible && (
          <Button variant="outline" onClick={showCcField} fontWeight="normal">
            {t('mailing:recipientsFieldGroup.cc')}
          </Button>
        )}
        {!isBccVisible && (
          <Button variant="outline" onClick={showBccField} fontWeight="normal">
            {t('mailing:recipientsFieldGroup.bcc')}
          </Button>
        )}
      </HStack>
    </HStack>
  );
};
