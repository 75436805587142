import { QUOTE_ITEM_TYPE_OF_PRICING, type QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';

import { Lot } from './Lot';
import { Item } from './Item';
import { SubItem } from './SubItem';
import { Component } from './Component';

export const DenominationCell = (params: CustomCellRendererProps<QuoteNodeObject>) => {
  const { data, node, api } = params;

  const element = useMemo(() => {
    if (!data) {
      return null;
    }

    if (data.content.type === 'QuoteLot') {
      return (
        <Box pl="1rem">
          <Lot quoteLot={data.content} level={node.level} />
        </Box>
      );
    }
    if (
      data.content.type === 'QuoteOptionalItem' ||
      data.content.type === 'QuoteBasicItem' ||
      data.content.type === 'QuoteHiddenCostItem'
    ) {
      return (
        <Box pl="1rem">
          <Item
            nodeId={data.id}
            isExpanded={node.expanded}
            level={node.level}
            hasChildren={data.childrenId.length > 0}
            type={data.content.type}
            item={data.content}
            api={api}
            typeOfPricing={data.content.typeOfPricing ?? QUOTE_ITEM_TYPE_OF_PRICING.UNIT}
          />
        </Box>
      );
    }
    if (data.content.type === 'QuoteSubItem') {
      return (
        <Box pl="1rem">
          <SubItem
            nodeId={data.id}
            isExpanded={node.expanded}
            level={node.level}
            parentId={data.parentId}
            hasChildren={data.childrenId.length > 0}
            item={data.content}
            api={api}
            typeOfPricing={data.content.typeOfPricing ?? QUOTE_ITEM_TYPE_OF_PRICING.UNIT}
          />
        </Box>
      );
    }
    if (data.content.type === 'QuoteComponent') {
      return (
        <Box pl="1rem">
          <Component nodeId={data.id} level={node.level} parentId={data.parentId} item={data.content} api={api} />
        </Box>
      );
    }
    return null;
  }, [data, node.level, node.expanded, api]);

  return <Box boxSize="100%">{element}</Box>;
};
