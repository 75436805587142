import type { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Badge } from '@graneet/lib-ui';
import type { IClientResponseDTO } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

export type ClientFieldOptionProps = {
  client: IClientResponseDTO;
  showBadge?: boolean;
};

/**
 * Render a AutocompleteField option for a client
 */
export const ClientFieldOption: FC<ClientFieldOptionProps> = ({ client, showBadge = true }) => {
  const { t } = useTranslation(['contacts']);

  return (
    <Flex alignItems="baseline">
      {showBadge && (
        <Badge showDot colorScheme="yellow" w="7rem">
          {t('contacts:fields.client')}
        </Badge>
      )}
      <Box fontFamily="heading" px={2} color="gray.800">
        {client.enterpriseName}
      </Box>
      {client.code && (
        <>
          <Text margin="0 1rem 0 1rem" color="gray.500">
            -
          </Text>
          <Text fontSize="xs" color="gray.500">
            {client?.code}
          </Text>
        </>
      )}
      {client?.address && (
        <>
          <Text margin="0 1rem 0 1rem" color="gray.500">
            -
          </Text>

          <Text fontSize="xs" color="gray.500">
            {client?.address.address}
          </Text>
        </>
      )}
    </Flex>
  );
};
