import type { FC } from 'react';
import { useEffect } from 'react';
import { formatDateToString, getCurrentDateAsString, Section } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, useStepForm } from 'graneet-form';
import { WrapItem, Wrap } from '@chakra-ui/react';
import type { IDownPayment, IProgressStatement, IProject, ISubProject } from '@graneet/business-logic';
import { SUB_PROJECT_BILLING_TYPE } from '@graneet/business-logic';

import { NoteFieldSection } from 'features/common/components/fields/NoteFieldSection';
import { useHasLedger } from 'features/statement-ledger/hooks/useHasLedger';
import type {
  DirectProgressStatementInformationForm,
  DirectProgressStatementWizard,
} from 'features/progress-statement/forms/direct-progress-statement.wizard';
import { ProgressStatementEditionCard } from 'features/progress-statement/components/cards/ProgressStatementEditionCard';
import { DirectProgressStatementSummaryCard } from 'features/progress-statement/components/cards/DirectProgressStatementSummaryCard';

interface DirectProgressStatementInformationsStepProps {
  subProject: ISubProject;
  project: IProject;
  contractName?: string;
  currentProgressStatement?: IProgressStatement | null;
  downPayment?: IDownPayment | null;
}

export const DirectProgressStatementInformationsStep: FC<DirectProgressStatementInformationsStepProps> = ({
  subProject,
  project,
  contractName,
  currentProgressStatement,
  downPayment,
}) => {
  const { t } = useTranslation(['directProgressStatement', 'invoice']);
  const { form, initFormValues } = useStepForm<DirectProgressStatementWizard, 'information'>();
  const hasLedger = useHasLedger();

  useEffect(() => {
    const { name, invoiceNumber: initialInvoiceNumber, billingDate, note } = currentProgressStatement || {};
    // Edition default values
    initFormValues({
      name: name || t('directProgressStatement:fields.nameDefault', { contractName }),
      invoiceNumber: initialInvoiceNumber,
      note,
      billingDate: billingDate ? formatDateToString(billingDate) : getCurrentDateAsString(),
    });
  }, [initFormValues, currentProgressStatement, contractName, t]);

  return (
    <Form form={form}>
      <Section
        title={t('invoice:steps.information.title')}
        description={
          hasLedger
            ? t('directProgressStatement:form.information.descriptionWithLedger')
            : t('directProgressStatement:form.information.description')
        }
      >
        <Wrap spacing={4}>
          <WrapItem flex={2.5} minW="25rem" mb={5}>
            <ProgressStatementEditionCard
              currentProgressStatement={currentProgressStatement}
              project={project}
              billingType={SUB_PROJECT_BILLING_TYPE.DIRECT}
            />
          </WrapItem>

          <WrapItem flex={2} minW="20rem">
            <DirectProgressStatementSummaryCard subProject={subProject} downPayment={downPayment} />
          </WrapItem>
        </Wrap>
      </Section>
      <NoteFieldSection<DirectProgressStatementInformationForm> name="note" />
    </Form>
  );
};
