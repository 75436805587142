import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { Form, HiddenField, useFormContext, useOnChangeValues, useStepForm } from 'graneet-form';
import { Box } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { EllipsisText, EMPTY_SIGN, INPUT_HEIGHT, StepperModal, SwitchField, Table, TextField } from '@graneet/lib-ui';
import type { IBatiprixInformationResponse, IUser } from '@graneet/business-logic';

import type { BatiprixEditConfigWizard, BatiprixTokenForm } from '../../forms/batiprix-edit-config.wizard';
import {
  getBatiprixUserIsActiveFieldName,
  getBatiprixUserTokenFieldName,
  isBatiprixUserIsActiveFieldName,
} from '../../forms/batiprix-edit-config.wizard';

import { useDisplayedUsers } from 'features/user/services/user.api';
import { Rule } from 'features/form/rules/Rule';
import { URL_BATIPRIX_INFORMATION } from 'features/batiprix/batiprix.constant';

const HasAtLeastOneActiveUserRule: FC = () => {
  const form = useFormContext<BatiprixTokenForm>();
  const formValues = useOnChangeValues(form, undefined);

  const hasUserWithBatiprixActive = useMemo(
    () =>
      Object.entries(formValues).reduce<boolean>((acc, [key, value]) => {
        if (isBatiprixUserIsActiveFieldName(key as keyof BatiprixTokenForm)) {
          return acc || !!value;
        }
        return acc;
      }, false),
    [formValues],
  );

  return <HiddenField name="atLeastOneActive">{!hasUserWithBatiprixActive && <Rule.IsRequired />}</HiddenField>;
};

const Row: FC<{ user: IUser }> = ({ user }) => {
  const switchFieldName = getBatiprixUserIsActiveFieldName(user.id);
  const tokenFieldName = getBatiprixUserTokenFieldName(user.id);

  const form = useFormContext<BatiprixTokenForm>();
  const { [switchFieldName]: isActive } = useOnChangeValues(form, [switchFieldName]);

  return (
    <Table.Row>
      <Table.Cell>
        <SwitchField<BatiprixTokenForm> name={switchFieldName} uncheckedValue={false} checkedValue />
      </Table.Cell>
      <Table.Cell>{user.lastName}</Table.Cell>
      <Table.Cell>{user.firstName}</Table.Cell>
      <Table.Cell>
        <EllipsisText>{user.email}</EllipsisText>
      </Table.Cell>
      <Table.Cell>
        {isActive ? (
          <TextField<BatiprixTokenForm> name={tokenFieldName}>
            <Rule.IsRequired />
          </TextField>
        ) : (
          <Box w="100%" textAlign="center" h={INPUT_HEIGHT}>
            {EMPTY_SIGN}
          </Box>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

interface BatiprixEditConfigTokenStepProps {
  activeUsers: IBatiprixInformationResponse['activeUsers'];

  isEdition: boolean;
}

export const BatiprixEditConfigTokenStep: FC<BatiprixEditConfigTokenStepProps> = ({ activeUsers, isEdition }) => {
  const { t } = useTranslation(['user', 'global']);
  const { form, initFormValues } = useStepForm<BatiprixEditConfigWizard, 'token'>();

  const users = useDisplayedUsers();

  useEffect(() => {
    const formValues: Partial<BatiprixTokenForm> = {};

    activeUsers.forEach((activeUser) => {
      formValues[getBatiprixUserIsActiveFieldName(activeUser.id)] = activeUser.isActive;
      formValues[getBatiprixUserTokenFieldName(activeUser.id)] = activeUser.token;
    });
    initFormValues(formValues);
  }, [activeUsers, initFormValues]);

  return (
    <Form form={form}>
      <Trans t={t} i18nKey="global:batiprixEditConfigModal.token.description">
        <Box as="a" color="greenBrand.light" target="_blank" href={URL_BATIPRIX_INFORMATION} />
      </Trans>

      <Box mt={5} w="100%">
        <Table templateColumns={['3rem', '6rem', '6rem', '15rem', 'auto']}>
          <Table.Header>
            <Table.Cell />
            <Table.Cell>{t('user:fields.lastName')}</Table.Cell>
            <Table.Cell>{t('user:fields.firstName')}</Table.Cell>
            <Table.Cell>{t('user:fields.email')}</Table.Cell>
            <Table.Cell>{t('global:batiprixEditConfigModal.token.batiprixUserToken')}</Table.Cell>
          </Table.Header>

          {users.data.map((user) => (
            <Row key={user.id} user={user} />
          ))}
        </Table>
      </Box>

      <HasAtLeastOneActiveUserRule />

      <StepperModal.SecondaryButton>{t('global:words.c.back')}</StepperModal.SecondaryButton>
      <StepperModal.PrimaryButton>
        {t(`global:batiprixEditConfigModal.token.${isEdition ? 'updateCta' : 'activateCta'}`)}
      </StepperModal.PrimaryButton>
    </Form>
  );
};
