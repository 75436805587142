import type { QuoteQuantityFormulaObject } from '@org/quotation-lib';
import { QuoteQuantityFormula } from '@org/quotation-lib';
import type { CellClassParams, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';
import { isEqual } from 'lodash-es';

import { QuantityCell } from '../../components/columns/QuantityCell/QuantityCell';
import { QuantityEditorCell } from '../../components/columns/QuantityCell/QuantityEditorCell';
import type { AGColDef } from '../../types';

import { useQuoteBasicItemUpdateQuantity } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateQuantity';
import { useQuoteComponentUpdateQuantity } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateQuantity';
import { useQuoteSubItemUpdateQuantity } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdateQuantity';
import { useQuoteOptionalItemUpdateQuantity } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateQuantity';
import { useQuoteHiddenCostItemUpdateQuantity } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemUpdateQuantity';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useQuantityColumnDef(isEditable: boolean) {
  const quoteBasicItemUpdateQuantity = useQuoteBasicItemUpdateQuantity();
  const quoteSubItemUpdateQuantity = useQuoteSubItemUpdateQuantity();
  const quoteComponentUpdateQuantity = useQuoteComponentUpdateQuantity();
  const quoteOptionalItemUpdateQuantity = useQuoteOptionalItemUpdateQuantity();
  const quoteHiddenCostItemUpdateQuantity = useQuoteHiddenCostItemUpdateQuantity();

  const { executeCommand } = useCommands();
  const { quote } = useQuote();
  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      cellEditor: QuantityEditorCell,
      cellEditorParams: { mask: 'number', textAlign: 'center' },
      cellRenderer: QuantityCell,
      cellRendererParams: {
        mandatory: true,
      },
      index,
      colId: 'quantity',
      headerName: label,
      sortable: false,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressMovable: false,
      width,
      editable: (params) => {
        if (!isEditable) return false;
        switch (params.data?.content.type) {
          case 'QuoteBasicItem':
          case 'QuoteSubItem':
          case 'QuoteComponent':
          case 'QuoteOptionalItem':
          case 'QuoteHiddenCostItem':
            return true;
          default:
            return false;
        }
      },
      cellClass: (params: CellClassParams) => {
        if (params.node.footer || params.data?.content.type === 'QuoteLot') return 'no-focus read-only';
        switch (params.data?.content.type) {
          case 'QuoteBasicItem':
          case 'QuoteSubItem':
          case 'QuoteComponent':
          case 'QuoteOptionalItem':
          case 'QuoteHiddenCostItem':
            return '';
          default:
            return 'no-focus read-only';
        }
      },
      valueGetter: (params) => {
        if (!params.data) {
          return null;
        }

        switch (params.data.content.type) {
          case 'QuoteComponent':
          case 'QuoteBasicItem':
          case 'QuoteHiddenCostItem':
          case 'QuoteOptionalItem':
          case 'QuoteSubItem':
            if (params.data.content.quantityFormula) {
              return {
                value: params.data.content.quantityFormula.quantity,
                content: {
                  formula: params.data.content.quantityFormula.formula,
                  roundFactor: params.data.content.quantityFormula.roundFactor
                    ? parseInt(params.data.content.quantityFormula.roundFactor, 10)
                    : null,
                  comment: params.data.content.quantityFormula.comment,
                },
              };
            }
            return null;
          case 'QuoteLot':
            return '';
          default:
            throw new Error('Type unknown');
        }
      },
      valueSetter: (params) => {
        if (isEqual(JSON.stringify(params.oldValue), JSON.stringify(params.newValue))) {
          return false;
        }

        if (params.data.content.type === 'QuoteLot') {
          return false;
        }

        let quantityFormula: Partial<QuoteQuantityFormulaObject>;

        if (params.newValue?.content.formula) {
          quantityFormula = {
            formula: params.newValue.content.formula,
            roundFactor: params.newValue.content.roundFactor,
            comment: params.newValue.content.comment,
          };
        } else if (
          params.newValue?.value !== null &&
          params.newValue?.value !== undefined &&
          !Number.isNaN(params.newValue.value)
        ) {
          quantityFormula = {
            quantity: params.newValue.value.toString(),
          };
        } else {
          quantityFormula = {
            quantity: null,
          };
        }

        switch (params.data?.content.type) {
          case 'QuoteBasicItem': {
            executeCommand(
              quoteBasicItemUpdateQuantity(params.data.id, new QuoteQuantityFormula(quantityFormula)),
              quote,
            );
            return true;
          }
          case 'QuoteOptionalItem': {
            executeCommand(
              quoteOptionalItemUpdateQuantity(params.data.id, new QuoteQuantityFormula(quantityFormula)),
              quote,
            );
            return true;
          }
          case 'QuoteHiddenCostItem': {
            executeCommand(
              quoteHiddenCostItemUpdateQuantity(params.data.id, new QuoteQuantityFormula(quantityFormula)),
              quote,
            );
            return true;
          }
          case 'QuoteSubItem': {
            executeCommand(
              quoteSubItemUpdateQuantity(params.data.id, new QuoteQuantityFormula(quantityFormula)),
              quote,
            );
            return true;
          }
          case 'QuoteComponent': {
            executeCommand(
              quoteComponentUpdateQuantity(params.data.id, new QuoteQuantityFormula(quantityFormula)),
              quote,
            );
            return true;
          }
          default:
            return false;
        }
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [
      isEditable,
      executeCommand,
      quoteBasicItemUpdateQuantity,
      quote,
      quoteOptionalItemUpdateQuantity,
      quoteHiddenCostItemUpdateQuantity,
      quoteSubItemUpdateQuantity,
      quoteComponentUpdateQuantity,
    ],
  );
}
