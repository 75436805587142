import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import type { PaginatedTableProps, PaginationQuery } from '@graneet/lib-ui';
import {
  generateColorFromString,
  ListingLayout,
  PaginatedTable,
  SimpleInfoBubbleIcon,
  Badge,
  Date,
  Link,
  Price,
} from '@graneet/lib-ui';
import type { BillPaginatedResponse, IBillListingResponseDTO, PaginatedResponse } from '@graneet/business-logic';
import { FEATURE_FLAGS, BILL_STATUSES } from '@graneet/business-logic';
import { Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { BILL_LATE_DAYS_RANGES, BILL_LATE_DAYS_RANGES_VALUES, BILL_STATUSES_STYLE } from '../constants/bill.constant';

import { BillStatusBadge } from './BillStatusBadge';

import { PlanTypeBadge } from 'features/common/components/PlanTypeBadge';
import { PaginatedTableSettings } from 'features/common/components/PaginatedTableSettings';
import { useReminders } from 'features/reminder/services/reminder.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useProjectsWithoutPagination } from 'features/project/services/project.api';
import { useClientsWithoutPagination } from 'features/client/services/client.api';
import { useStatementsAvailableTags } from 'features/statement/services/statement.api';

type AvailableColumns =
  | 'invoiceNumber'
  | 'name'
  | 'project'
  | 'client'
  | 'billingDate'
  | 'paymentDeadline'
  | 'lateDays'
  | 'billToRemind'
  | 'remainingToBePaidIncVAT'
  | 'status';

interface BillListingProps {
  gridId: string;
  pagination: PaginationQuery<BillPaginatedResponse>;

  topContent?: ReactNode;

  rowAction: (props: { data: IBillListingResponseDTO | undefined }) => ReactNode;
  tableProps: Omit<
    PaginatedTableProps<PaginatedResponse<IBillListingResponseDTO>>,
    'gridId' | 'pagination' | 'columnDefs' | 'zeroState' | 'emptyState'
  >;

  /**
   * Choose and customize displayed columns
   */
  customLayout: Partial<
    Record<
      AvailableColumns,
      {
        hide: boolean;
      }
    >
  >;
}

export const BillListing: FC<BillListingProps> = ({
  gridId,
  pagination,
  topContent,
  rowAction,
  tableProps,
  customLayout,
}) => {
  const { t } = useTranslation(['bill', 'global']);

  const reminders = useReminders();
  const projects = useProjectsWithoutPagination();
  const clients = useClientsWithoutPagination();
  const tags = useStatementsAvailableTags();

  const hasReminderFF = useFeatureFlag(FEATURE_FLAGS.BILL_EMAIL_REMIND);

  const displayReminder = reminders.data.length > 0 && hasReminderFF;

  const isColumnIsDisplayed = useCallback(
    (columnName: AvailableColumns) => customLayout[columnName] !== undefined,
    [customLayout],
  );

  const isColumnHidden = useCallback(
    (columnName: AvailableColumns) => {
      // If the column is not in the object, do not display the column
      if (customLayout[columnName] === undefined) {
        return true;
      }

      return customLayout[columnName].hide;
    },
    [customLayout],
  );

  const columnDefs = useMemo<PaginatedTableProps<PaginatedResponse<IBillListingResponseDTO>>['columnDefs']>(
    () => [
      {
        field: 'invoiceNumber',
        headerName: t('bill:fields.invoiceNumber'),
        sortable: true,
        hide: isColumnHidden('invoiceNumber'),
      },
      {
        field: 'name',
        headerName: t('bill:fields.name'),
        sortable: true,
        hide: isColumnHidden('name'),
      },
      {
        field: 'project',
        headerName: t('bill:fields.project.name'),
        sortable: true,
        hide: isColumnHidden('project'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) =>
          props.data?.project ? <Link to={`/projects/${props.data.project.id}`}>{props.data.project.name}</Link> : null,
      },
      {
        field: 'client',
        headerName: t('bill:fields.client.name'),
        sortable: true,
        hide: isColumnHidden('client'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) => {
          const client = props.data?.project?.primaryClient ?? props.data?.invoice?.client;

          return client ? <Link to={`/contacts/clients/${client.id}`}>{client.enterpriseName}</Link> : null;
        },
      },
      {
        field: 'billingDate',
        headerName: t('bill:fields.billingDate'),
        sortable: true,
        hide: isColumnHidden('billingDate'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) => (props.data ? <Date>{props.data.billingDate}</Date> : null),
      },
      {
        field: 'paymentDeadline',
        headerName: t('bill:fields.paymentDeadline'),
        sortable: true,
        hide: isColumnHidden('paymentDeadline'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) => (props.data ? <Date>{props.data.paymentDeadline}</Date> : null),
      },
      {
        field: 'lateDays',
        headerName: t('bill:fields.lateDaysHeader'),
        sortable: true,
        hide: isColumnHidden('lateDays'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) =>
          props.data &&
          [BILL_STATUSES.PARTIALLY_PAID, BILL_STATUSES.AWAITING_PAYMENT].includes(props.data?.status) &&
          props.data.lateDays > 0 ? (
            <Badge colorScheme="red">{t('bill:fields.lateDays', { count: props.data.lateDays })}</Badge>
          ) : null,
      },
      {
        field: 'billToRemind',
        headerName: t('bill:fields.reminder'),
        sortable: true,
        hide: !displayReminder ? true : isColumnHidden('billToRemind'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) => {
          const reminder = reminders.data.find((r) => props.data?.billToRemind?.reminderId === r.id);

          return reminder ? reminder.name : null;
        },
      },
      {
        field: 'remainingToBePaidIncVAT',
        headerName: t('bill:fields.totalToBePaidIncVAT'),
        sortable: true,
        hide: isColumnHidden('remainingToBePaidIncVAT'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) =>
          props.data ? (
            <Flex direction="column">
              <Text>
                <Price amount={props.data.remainingToBePaidIncVAT} />
              </Text>
              {props.data.lateDays && (
                <Text mt={-6} fontSize="xs" textColor="gray.900">
                  <Trans t={t} i18nKey="bill:on">
                    <Price amount={props.data.totalToBePaidIncVAT} />
                  </Trans>
                </Text>
              )}
            </Flex>
          ) : null,
      },
      {
        field: 'status',
        headerName: t('bill:fields.status'),
        sortable: true,
        hide: isColumnHidden('status'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) => (props.data ? <BillStatusBadge status={props.data.status} /> : null),
      },
      {
        field: 'pay' as any,
        headerName: '',
        maxWidth: 80,
        resizable: false,
        suppressMovable: true,
        lockPosition: 'right',
        cellRenderer: rowAction,
      },
    ],
    [t, isColumnHidden, displayReminder, rowAction, reminders.data],
  );

  return (
    <ListingLayout
      noPadding
      pagination={pagination}
      topContent={topContent}
      content={
        <PaginatedTable
          gridId={gridId}
          pagination={pagination}
          columnDefs={columnDefs}
          zeroState={{
            icon: <SimpleInfoBubbleIcon bg="white" borderRadius="100%" />,
            label: t('bill:noBill'),
          }}
          emptyState={{
            label: t('bill:noResult'),
          }}
          {...tableProps}
        />
      }
      search={{ placeholder: t('global:words.c.research') }}
      leftActions={
        <PaginatedTableSettings<PaginatedResponse<IBillListingResponseDTO>>
          gridId={gridId}
          columnDefs={columnDefs}
          groupedData={{
            label: t('global:table.chooseColumns'),
            items: [
              isColumnIsDisplayed('invoiceNumber') && { key: 'invoiceNumber' },
              isColumnIsDisplayed('name') && { key: 'name' },
              isColumnIsDisplayed('project') && { key: 'project' },
              isColumnIsDisplayed('client') && { key: 'client' },
              isColumnIsDisplayed('billingDate') && { key: 'billingDate' },
              isColumnIsDisplayed('paymentDeadline') && { key: 'paymentDeadline' },
              isColumnIsDisplayed('lateDays') && { key: 'lateDays' },
              isColumnIsDisplayed('billToRemind') && {
                key: 'billToRemind',
                additionalContent: hasReminderFF ? undefined : <PlanTypeBadge plan="advanced" />,
                isDisabled: !hasReminderFF,
              },
              isColumnIsDisplayed('remainingToBePaidIncVAT') && { key: 'remainingToBePaidIncVAT' },
              isColumnIsDisplayed('status') && { key: 'status' },
            ],
          }}
        />
      }
      filters={[
        {
          type: 'amount',
          label: t('bill:filters.totalToBePaidIncVAT'),
          name: 'totalToBePaidIncVAT',
        },
        {
          type: 'date',
          label: t('bill:fields.billingDate'),
          name: 'billingDate',
        },
        {
          type: 'date',
          label: t('bill:fields.paymentDeadline'),
          name: 'paymentDeadline',
        },
        {
          type: 'date',
          label: t('bill:fields.paidAt'),
          name: 'paymentPaidAt',
        },
        {
          type: 'checkbox',
          availableValues: (Object.keys(BILL_STATUSES) as BILL_STATUSES[]).map((status) => ({
            value: status,
            colorScheme: BILL_STATUSES_STYLE[status].variantColor,
            label: t(`bill:statuses.${status}`),
          })),
          label: t('bill:fields.status'),
          name: 'status',
        },
        {
          type: 'checkbox-range',
          availableValues: (Object.keys(BILL_LATE_DAYS_RANGES) as Array<keyof typeof BILL_LATE_DAYS_RANGES>).map(
            (range) => ({
              value: BILL_LATE_DAYS_RANGES_VALUES[range],
              colorScheme: 'red' as const,
              label: t(`bill:filters.lateDaysRanges.${range}`),
              theme: 'ghost',
            }),
          ),
          label: t('bill:filters.lateDays'),
          name: 'lateDays',
        },
        {
          type: 'multi',
          name: 'tags',
          availableValues: tags.data.map((tag) => ({
            value: tag,
            label: tag,
            badgeColor: generateColorFromString(tag),
          })),
          label: t('global:words.tags'),
          noValueMessage: t('global:tags.noLabel'),
          placeholder: t('global:words.c.select'),
        },
        ...(hasReminderFF
          ? [
              {
                type: 'multi',
                name: 'billToRemind',
                availableValues: reminders.data.map((reminder) => ({
                  value: reminder.id,
                  label: reminder.name,
                })),
                label: t('bill:fields.reminder'),
                placeholder: t('global:words.c.select'),
              } as const,
            ]
          : []),
        {
          type: 'multi',
          name: 'project',
          availableValues: projects.data.data.map((project) => ({
            value: project.id.toString(),
            label: project.name,
          })),
          label: t('bill:fields.project.name'),
          placeholder: t('global:words.c.select'),
        },
        {
          type: 'multi',
          name: 'client',
          availableValues: clients.data.data.map((client) => ({
            value: client.id.toString(),
            label: client.enterpriseName,
          })),
          label: t('bill:fields.client.name'),
          placeholder: t('global:words.c.select'),
        },
      ]}
    />
  );
};
