import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import * as jobsApi from '../services/quote-job.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useComponentContext } from 'features/quote-component/contexts/component.context';

/**
 * Edit margin of quote job and return error state
 */
export const useEditJobMargin = ({ updateComponentContext }: { updateComponentContext: boolean }) => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { updateDataLocally } = useQuoteEditContext();
  const componentContext = useComponentContext();

  return useCallback(
    async (jobId: number, totalMargin: number) => {
      if (!totalMargin) {
        toast.error(t('quote:jobsStep.errors.totalMarginNull'));
        return false;
      }
      const [err, result] = await jobsApi.updateJobMargin(jobId, { totalMargin });

      if (err) {
        toast.error(t('quote:errors.updatingError'));
        return false;
      }
      updateDataLocally(result);
      if (updateComponentContext) {
        componentContext.setComponents(Object.values(result.components));
      }
      return true;
    },
    [updateDataLocally, updateComponentContext, toast, t, componentContext],
  );
};
