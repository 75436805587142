import type { FC, MouseEvent } from 'react';
import { Flex, Text, CircularProgress, Box } from '@chakra-ui/react';
import type { FileProps } from '@graneet/lib-ui';
import { Badge, File, SimpleViewIcon } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';

import type { DOWNLOAD_FILE_TYPE } from './DownloadFileIcon';
import { DownloadFileIcon } from './DownloadFileIcon';
import { ActionLink } from './ActionLink';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

export type FileItemVariant = 'default' | 'compact';

export interface FileItemProps extends Omit<FileProps, 'actions' | 'id'> {
  archived: boolean;

  previewLink?: string;

  downloadLink?: string;

  type: DOWNLOAD_FILE_TYPE;

  hasError?: boolean;

  isGenerating?: boolean;

  isGenerated?: boolean;

  messageWhileGenerating?: string;

  messageWhileWaiting?: string;

  createdAt?: string;

  isProforma?: boolean;

  rightContent?: FC<{ previewLink?: string; downloadLink?: string }>;

  variant?: FileItemVariant;

  onClick?: (event: MouseEvent) => void;
}

export const FileItem: FC<FileItemProps> = ({
  previewLink,
  downloadLink,
  type,
  hasError = false,
  isGenerating = false,
  archived,
  messageWhileGenerating,
  createdAt,
  messageWhileWaiting,
  isGenerated = false,
  isProforma = false,
  variant = 'default',
  rightContent: RightContent,
  onClick,
  ...otherProps
}) => {
  const { t } = useTranslation(['global']);

  const actions = (
    <>
      {!hasError && !isGenerated && !isGenerating && (
        <Flex direction="row" flexGrow={0}>
          <Text colorScheme="gray.800" fontSize="sm">
            {messageWhileWaiting}
          </Text>
        </Flex>
      )}

      {hasError && (
        <Flex direction="row" flexGrow={0}>
          <Text colorScheme="gray.800" fontSize="sm">
            {t('global:pdf.pdfError', { email: SUPPORT_EMAIL })}
          </Text>
        </Flex>
      )}

      {isGenerating && (
        <>
          <Text colorScheme="gray.800" fontSize="sm" mr={4} whiteSpace="nowrap">
            {messageWhileGenerating}
          </Text>
          <CircularProgress mr={3} size={6} isIndeterminate color="gray.800" />
        </>
      )}

      {isGenerated && (
        <Flex mr={2} direction="row">
          {variant === 'default' && (
            <>
              <ActionLink
                // @ts-ignore
                href={previewLink}
                leftIcon={<SimpleViewIcon />}
                mr={2}
              >
                {t('global:pdf.view')}
              </ActionLink>

              <ActionLink
                // @ts-ignore
                href={downloadLink}
                onClick={onClick}
              >
                <DownloadFileIcon type={type} boxSize={4} />
                <Text ml={2}>
                  <Trans t={t} i18nKey="global:pdf.download" />
                </Text>
              </ActionLink>
            </>
          )}

          {RightContent && variant === 'compact' && (
            <RightContent previewLink={previewLink} downloadLink={downloadLink} />
          )}
        </Flex>
      )}

      {RightContent && variant !== 'compact' && (
        <Flex ml={4} direction="row">
          <RightContent previewLink={previewLink} downloadLink={downloadLink} />
        </Flex>
      )}
    </>
  );

  const additionalContent =
    archived === false && !archived && isProforma ? (
      <Box>
        <Badge showDot colorScheme="gray" width="auto" px={2} whiteSpace="nowrap">
          {t('global:pdf.proforma')}
        </Badge>
      </Box>
    ) : null;

  return (
    <File
      actions={actions}
      isArchived={archived}
      additionalContent={additionalContent}
      createdAtLabel={createdAt}
      {...otherProps}
    />
  );
};
