import { Checkbox, Flex } from '@chakra-ui/react';
import type { ChangeEvent, MouseEventHandler, ReactElement } from 'react';
import { useCallback, useEffect, useId, useState } from 'react';

import { useTableSelectionContext } from '../contexts/TableSelectionContext.context';

export interface TableSelectProps<T extends unknown> {
  /**
   * Value that will be transmitted to table selection context consumers as table footer
   */
  data: T;
}

/**
 * Checkbox in a row table
 * @example
 * ```
 *  <Table>
 *    {headers}
 *
 *    <Table.Row>
 *      <Table.Cell>
 *        <Table.Select />
 *      </Table.Cell>
 *    </Table.Row>
 *  </Table>
 * ```
 */
export const TableSelect = <T extends unknown>({ data }: TableSelectProps<T>): ReactElement => {
  const id = useId();
  const tableSelectionContext = useTableSelectionContext();

  const [isSelected, setIsSelected] = useState(false);

  /**
   * Register and unregister select
   */
  useEffect(() => {
    tableSelectionContext.registerCheckbox(id, setIsSelected, data);
    return () => tableSelectionContext.unregisterCheckbox(id);
    // Do not had data to avoid async bug with pagination
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tableSelectionContext]);

  /**
   * On change, update the state and value stored in table selection context
   */
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked;
      setIsSelected(newValue);
      // Update selected items when isSelected state is updated
      tableSelectionContext.updateSelectedItem(id, data, newValue);
    },
    [data, id, tableSelectionContext],
  );

  const onClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation();
  }, []);

  return (
    // Margin with padding is here to avoid user miss click on select left side
    <Flex onClick={onClick} boxSize="100%" py={5} my={-5} ml={-3} pl={3}>
      <Checkbox isChecked={isSelected} onChange={onChange} colorScheme="greenBrand" />
    </Flex>
  );
};
