import Big from 'big.js';
import { MARGIN_TYPE } from '@org/quotation-lib';
import { QuoteStatus } from '@org/graneet-bff-client';
import { useCallback } from 'react';

import type { QuoteRootWsMessage } from '../../quote-common/interfaces/quoteWsMessage';

import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { updateQuoteEditable } from 'features/quotation/quote-common/store/quoteUpdateZustand';

export const useCreateQuoteUpdatedCallback = () =>
  useCallback((message: QuoteRootWsMessage) => {
    const quoteFromStore = quoteGetFromStore();
    const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();

    if (quoteFromStore) {
      if (quoteFromStore.getStatus() !== message.data.status) {
        if (![QuoteStatus.Importing, QuoteStatus.Draft].includes(message.data.status as unknown as QuoteStatus)) {
          updateQuoteEditable(false);
        }
      }
      const hasClientRequest = quoteClientRequestsFromStore.find(
        (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
      );
      if (hasClientRequest === undefined) {
        const quoteObject = quoteFromStore.export();
        if (message.data.amountExVat && message.data.amountExVat !== quoteObject.amountExVat) {
          quoteFromStore.updateAmountExVat(Big(message.data.amountExVat), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
        }
        if (message.data.amountExVat && message.data.amountExVat !== quoteObject.amountExVat) {
          quoteFromStore.updateAmountExVat(Big(message.data.amountExVat), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
        }
        quoteSetToStore(quoteFromStore);
      } else {
        quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
      }
    }
  }, []);
