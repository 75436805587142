import type { IAddress } from '../address/address.type';
import type { IClient } from '../client/client.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { ICompany } from '../company/company.type';
import type { IQuoteCustomDiscount } from '../custom-discount/custom-discount.type';
import type { IDiscount } from '../discount/discount.type';
import type { IFile } from '../file/file.type';
import type { PaginatedResponse } from '../pagination/paginated-response.type';
import type { IPdf } from '../pdf/pdf.type';
import type { IProject } from '../project/project.type';
import type { IQuoteAccountManager } from '../quote-account-manager/quote-account-manager.type';
import type { IQuoteJob } from '../quote-job/quote-job-type';
import type { IQuoteLot } from '../quote-lot/quote-lot.type';
import type { IVatBases, IVatDiscountedBases } from '../vat/vat-bases.type';
import type { VAT_METHOD } from '../vat/vat-method.type';
import type { IContactQuote } from '../contact/contact-quote.entity';

export enum QUOTE_STATUS {
  IMPORTING = 'IMPORTING',
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
  ACCEPTED = 'ACCEPTED',
  LOST = 'LOST',
}

export interface IQuote {
  id: number;

  historyFamilyId: string;

  groupId: number;

  isArchived: boolean;

  name: string;

  status: QUOTE_STATUS;

  hasReversalOfLiability: boolean;

  amountWithoutDiscountExVAT: number;

  amountWithDiscountExVAT: number;

  amountExVAT: number;

  amountIncVAT: number;

  vatDistribution: IVatBases | IVatDiscountedBases;

  date: Date | null;

  estimatedStartDate: Date | null;

  settledDate: Date | null;

  projectAddress?: IAddress | null;

  refCode: string | null;

  workDuration: number | null;

  validityDuration: number | null;

  comment: string | null;

  mentions: string | null;

  note: string | null;

  vatMethod: VAT_METHOD;

  displayOptionalLinesPDF: boolean;

  displayOptionalAnnexPDF: boolean;

  lots?: IQuoteLot[];

  jobs: IQuoteJob[];

  discount?: IDiscount | null;

  customDiscounts?: IQuoteCustomDiscount[];

  client?: IClient | null;

  project?: IProject | null;

  pdf?: IPdf | null;

  importFile?: IFile | null;

  company: ICompany;

  quoteAccountManagers?: IQuoteAccountManager[];

  isAutomaticNumberingActivated: boolean;

  createdAt: Date;

  updatedAt: Date;

  tags: string[];

  contactQuotes?: IContactQuote[];
}

export type QuotePaginatedResponse = PaginatedResponse<IQuote, 'amountExVAT'>;

export type IQuoteWithRelations = RequiredByKeys<
  IQuote,
  | 'client'
  | 'project'
  | 'pdf'
  | 'importFile'
  | 'discount'
  | 'projectAddress'
  | 'lots'
  | 'quoteAccountManagers'
  | 'customDiscounts'
>;
