import { useCallback, useEffect, useRef, useState } from 'react';
import type { IQuote } from '@graneet/business-logic';

import { useQuoteEditContext } from './useQuoteEditContext';

/**
 * Hook used by components using quote infos in order to render themselves only
 * when their data has changed
 */
export const useQuote = () => {
  const { listenToQuote } = useQuoteEditContext();
  const [watchedQuote, setWatchedQuote] = useState<null | IQuote>(null);

  useEffect(() => listenToQuote(setWatchedQuote), [listenToQuote]);

  return watchedQuote;
};

/**
 * Hook used by components using quote infos in order to render themselves only
 * when their data has changed
 */
export const useQuoteReversalOfLiability = () => {
  const [hasReversalOfLiability, setHasReversalOfLiability] = useState(false);
  const { listenToQuote } = useQuoteEditContext();

  const previousValue = useRef<boolean | undefined>();

  const handleNewQuote = useCallback((quote: IQuote) => {
    const newValue = quote?.hasReversalOfLiability || false;
    if (newValue !== previousValue.current) {
      setHasReversalOfLiability(newValue);
      previousValue.current = newValue;
    }
  }, []);

  useEffect(() => listenToQuote(handleNewQuote), [listenToQuote, handleNewQuote]);

  return hasReversalOfLiability;
};
