import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import {
  ActionMenu,
  useToast,
  SimpleDuplicateIcon,
  SimpleRewindIcon,
  SimpleOptionIcon,
  SimpleAddNoteIcon,
  SimpleCirclePlusIcon,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash-es';
import { useDisclosure } from '@chakra-ui/react';

import { useContractTreeContext, useContractItem } from '../../hooks/tree.hooks';
import { NEW_ITEM_DATA } from '../../constants/contracts.constant';
import { useContractDefaultVatRateContext } from '../../contexts/ContractDefaultVatRate';
import { useContractRuleContext } from '../../contexts/ContractRuleContext';

interface ContractItemActionsProps {
  itemId: string | number;

  onUpdateNote(newNote: string): () => void;
}

export const ContractItemActions: FC<ContractItemActionsProps> = ({ itemId, onUpdateNote }) => {
  const { t } = useTranslation(['contracts', 'global']);
  const toast = useToast();

  const actionControls = useDisclosure();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const {
    canItemBeDuplicated: isContractValidAfterItemDuplication,
    canItemBeDeleted: isContractValidAfterItemDeletion,
  } = useContractRuleContext();

  const {
    leaf: item,
    state: {
      isDeleted: isItemDeleted,
      isCreated: isItemCreated,
      canBeRestored: canItemBeRestored,
      canBeDeleted: canItemBeDeleted,
      updatedKeys,
    },
  } = useContractItem(itemId);
  const {
    deleteLeaf: deleteContractItem,
    restoreLeaf: restoreContractItem,
    createLeaf: createContractItem,
    updateLeafData: updateContractItem,
    duplicateLeaf: duplicateContractItem,
  } = useContractTreeContext();
  const { getDefaultVATRate } = useContractDefaultVatRateContext();

  const onDelete = useCallback(() => {
    try {
      const { errorMessage, canUpdate } = isContractValidAfterItemDeletion(itemId);
      if (!canUpdate) {
        throw new Error(errorMessage);
      }
      deleteContractItem(itemId);
    } catch (error) {
      toast.error((error as Error).message);
    }
  }, [deleteContractItem, itemId, isContractValidAfterItemDeletion, toast]);

  const onRestore = useCallback(() => {
    restoreContractItem(itemId);
  }, [itemId, restoreContractItem]);

  const onAddItem = useCallback(() => {
    const vatRate = getDefaultVATRate(item.parentNodeId);
    createContractItem(item.parentNodeId, item.id, NEW_ITEM_DATA(vatRate));
  }, [item.parentNodeId, item.id, createContractItem, getDefaultVATRate]);

  const onAcceptOption = useCallback(() => {
    updateContractItem(item.id, { isOptional: false });
  }, [item.id, updateContractItem]);

  const onDuplicateItem = useCallback(() => {
    try {
      const { errorMessage, canUpdate } = isContractValidAfterItemDuplication(itemId);
      if (!canUpdate) {
        throw new Error(errorMessage);
      }
      duplicateContractItem(itemId, { invoicedAmountExVAT: 0, isOptional: false });
    } catch (error) {
      toast.error((error as Error).message);
    }
  }, [isContractValidAfterItemDuplication, duplicateContractItem, itemId, toast]);

  const onAddNote = useCallback(() => {
    updateContractItem(item.id, { note: '' });
    onUpdateNote('')();
  }, [item.id, onUpdateNote, updateContractItem]);

  const cannotBeDeleted = item.invoicedAmountExVAT !== 0;
  const hasNote = !isNil(item.note) || (isNil(item.note) && updatedKeys.includes('note'));

  const displayCanRestoreAction = canItemBeRestored && !isItemCreated;
  const displayCanDeleteAction = canItemBeDeleted && !isItemDeleted;

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  return (
    <ActionMenu {...actionControls}>
      {item.isOptional ? (
        <ActionMenu.Action
          icon={<SimpleOptionIcon />}
          label={t('contracts:option.acceptOption')}
          onClick={onAcceptOption}
        />
      ) : null}

      {!hasNote && (
        <ActionMenu.Action
          onClick={onAddNote}
          label={t('contracts:itemEdition.addNote')}
          icon={<SimpleAddNoteIcon />}
        />
      )}

      <ActionMenu.Action
        onClick={onAddItem}
        label={t('contracts:itemEdition.addItem')}
        icon={<SimpleCirclePlusIcon />}
      />

      {displayCanRestoreAction && (
        <ActionMenu.Action onClick={onRestore} label={t('global:words.c.restore')} icon={<SimpleRewindIcon />} />
      )}

      {!isItemDeleted && (
        <ActionMenu.Action
          onClick={onDuplicateItem}
          label={t('global:words.c.duplicate')}
          icon={<SimpleDuplicateIcon />}
        />
      )}

      {displayCanDeleteAction && (
        <ActionMenu.Delete
          tooltipProps={{
            label: t('contracts:itemEdition.error.impossibleToDelete'),
            placement: 'bottom-start',
            isDisabled: !cannotBeDeleted,
          }}
          onClick={onDelete}
          isDisabled={cannotBeDeleted}
        />
      )}
    </ActionMenu>
  );
};
