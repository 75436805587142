import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, useDisclosure } from '@chakra-ui/react';
import { Button, useToast } from '@graneet/lib-ui';
import type { IProject } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { useHistory } from 'react-router-dom';

import { createQuote } from '../../services/quote.api';
import { CreateQuoteModal } from '../modals/CreateQuoteModal';
import { CreateQuoteFromImportModal } from '../modals/CreateQuoteFromImportModal/CreateQuoteFromImportModal';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

interface QuoteActionsButtonsProps {
  onImportCompleted(): void;

  isDisabled?: boolean;

  project?: IProject;

  spacing?: number;
}

export const QuoteActionsButtons: FC<QuoteActionsButtonsProps> = ({
  onImportCompleted,
  isDisabled = false,
  project,
  spacing = 6,
}) => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);
  const history = useHistory();

  const createQuotesButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_QUOTES]);

  const importModal = useDisclosure();
  const createModal = useDisclosure();

  const handleQuoteCreation = useCallback(
    async (values: { name: string }) => {
      const [err, createdQuote] = await createQuote({
        ...values,
        projectId: project?.id,
      });

      if (err) {
        toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
        return;
      }

      toast.success(t('global:words.c.success'), t('quote:creationForm.toastSuccess'));
      history.push(`/opportunities/quotes/${createdQuote.id}/edit`, {
        creation: true,
        project,
      });
    },
    [history, project, t, toast],
  );

  return (
    <>
      <HStack spacing={spacing}>
        <Button
          onClick={importModal.onOpen}
          variant="outline"
          colorScheme="blue"
          {...createQuotesButtonDisabledProps(isDisabled)}
        >
          {t('quote:actions.import')}
        </Button>

        <Button onClick={createModal.onOpen} colorScheme="blue" {...createQuotesButtonDisabledProps(isDisabled)}>
          {t('quote:actions.create')}
        </Button>
      </HStack>

      <CreateQuoteFromImportModal
        isOpen={importModal.isOpen}
        onClose={importModal.onClose}
        onImportCompleted={onImportCompleted}
        projectId={project?.id}
        onQuoteCreated={handleQuoteCreation}
      />

      <CreateQuoteModal
        title={t('quote:creationForm.creationTitle')}
        description={t('quote:creationForm.description')}
        action={t('quote:creationForm.submit')}
        isOpen={createModal.isOpen}
        onClose={createModal.onClose}
        onSubmit={handleQuoteCreation}
      />
    </>
  );
};
