import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox } from '@chakra-ui/react';

import { useTableSelectionContext } from '../contexts/TableSelectionContext.context';
import { defaultPaginatedDataContext, usePaginatedDataContext } from '../../PaginatedData';

type IsSelected = boolean | 'undetermined';

/**
 * Add a checkbox to in a table header
 */
export const TableHeaderSelect: FC = () => {
  const tableSelectionContext = useTableSelectionContext();
  const paginatedDataContext = usePaginatedDataContext();
  const [isSelected, setIsSelected] = useState<IsSelected>(false);
  const isInPaginatedContext = paginatedDataContext !== defaultPaginatedDataContext;

  /**
   * Register/unregister header select
   */
  useEffect(() => {
    tableSelectionContext.registerHeaderSelect((numberOfRegisterCheckboxes, numberOfSelectedItems, selectAll) => {
      let newIsSelected: IsSelected;

      if (selectAll) {
        newIsSelected = true;
      }
      // If there is no selected item, set to false
      else if (numberOfSelectedItems === 0) {
        newIsSelected = false;
        // If there is some selected items, set to `undetermined`
      } else if (numberOfRegisterCheckboxes !== numberOfSelectedItems) {
        newIsSelected = 'undetermined';

        // Now, we know that numberOfRegisterCheckboxes === numberOfSelectedItems
        // If we are in the paginated context, if there is more item to load, set `undetermined` or to true
      } else if (isInPaginatedContext) {
        newIsSelected = paginatedDataContext.hasMore ? 'undetermined' : true;
      } else {
        newIsSelected = true;
      }

      setIsSelected(newIsSelected);
    });

    return () => tableSelectionContext.unregisterHeaderSelect();
  }, [isInPaginatedContext, paginatedDataContext.hasMore, tableSelectionContext]);

  /**
   * Uncheck all when filters are changed
   * This is a dirty hacky method
   */
  // Return is string to be a primitive value
  const currentFiltersWithoutPage = useMemo<string>(
    () => {
      if (!isInPaginatedContext) return '{}';

      return [...paginatedDataContext.storage.entries()]
        .filter(([key]) => key !== '_page' && key !== '_offsetV1' && key !== '_offsetV2')
        .toString();
    },
    // Because storage is URLParams, so the reference do not change, so we stringify the object to force the memo update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInPaginatedContext, paginatedDataContext.storage.toString()],
  );

  useEffect(() => {
    tableSelectionContext.updateAllCheckboxesDisplayed(false);
  }, [
    // Do not remove currentFiltersWithoutPage, because the effect must be executed when filters change
    currentFiltersWithoutPage,
    tableSelectionContext,
  ]);

  /**
   * On change, update checkbox value and design
   */
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = isSelected === 'undetermined' ? false : event.target.checked;
      tableSelectionContext.updateAllCheckboxesDisplayed(newValue);
      if (newValue) {
        tableSelectionContext.selectAllCheckboxes();
      }
    },
    [isSelected, tableSelectionContext],
  );

  const isCheckboxChecked = isSelected === 'undetermined' ? true : isSelected;

  return (
    <Checkbox
      isChecked={isCheckboxChecked}
      onChange={onChange}
      colorScheme="greenBrand"
      isIndeterminate={isSelected === 'undetermined'}
    />
  );
};
