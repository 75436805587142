import type { QuoteNodeObject, Quote } from '@org/quotation-lib';
import { QuoteFlattenObject } from '@org/quotation-lib';

import { useStore } from 'store/store';

export function quoteSetToStore(quote: Quote) {
  const quoteFlatObject = QuoteFlattenObject.execute(quote.export());

  const nodesFromRootNode: QuoteNodeObject[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const node of quote.getTree().getNodesFromRootNode().values()) {
    nodesFromRootNode.push(node.export());
  }

  const nodesFromHiddenCostNode: QuoteNodeObject[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const node of quote.getTree().getNodesFromHiddenCostNode().values()) {
    nodesFromHiddenCostNode.push(node.export());
  }

  useStore.setState(() => ({
    quote: quoteFlatObject.quote,
    quoteTree: quoteFlatObject.quoteTree,
    quoteNode: nodesFromRootNode,
    quoteNodeHiddenCost: nodesFromHiddenCostNode,
    quoteSalesDiscount: quoteFlatObject.quoteSalesDiscount,
    quoteCustomDiscounts: quoteFlatObject.quoteCustomDiscount,
  }));
}
