type Value<K extends keyof ImportMetaEnv, ThrowOnMissing extends boolean> = ThrowOnMissing extends true
  ? ImportMetaEnv[K]
  : ImportMetaEnv[K] | undefined;

export const getEnvValue = <K extends keyof ImportMetaEnv, ThrowOnMissing extends boolean = true>(
  key: K,
  throwOnMissing?: ThrowOnMissing,
): Value<K, ThrowOnMissing> => {
  const value = import.meta.env[key];
  const mustThrowOnMissing = throwOnMissing || throwOnMissing === undefined;
  if (!value && mustThrowOnMissing) {
    throw new Error(`config error - missing import.meta.${key}`);
  }

  return value;
};
