import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import type { FC } from 'react';

export const OnboardingIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 563 333" {...props}>
    <svg width="563" height="333" viewBox="0 0 563 333" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M492.017 78.0935L284.313 78.6011L284.682 229.572L492.386 229.065L492.017 78.0935Z" fill="#F2F2F2" />
      <path d="M492.083 78.3417H284.303V108.067H492.083V78.3417Z" fill="#E6E6E6" />
      <path
        d="M302.944 97.4408C305.022 97.4408 306.708 95.7551 306.708 93.6757C306.708 91.5963 305.022 89.9106 302.944 89.9106C300.865 89.9106 299.18 91.5963 299.18 93.6757C299.18 95.7551 300.865 97.4408 302.944 97.4408Z"
        fill="#24285B"
      />
      <path
        d="M314.142 97.4118C316.221 97.4118 317.906 95.7261 317.906 93.6467C317.906 91.5673 316.221 89.8816 314.142 89.8816C312.063 89.8816 310.378 91.5673 310.378 93.6467C310.378 95.7261 312.063 97.4118 314.142 97.4118Z"
        fill="#109CF1"
      />
      <path
        d="M324.69 97.3844C326.769 97.3844 328.454 95.6987 328.454 93.6192C328.454 91.5398 326.769 89.8541 324.69 89.8541C322.611 89.8541 320.926 91.5398 320.926 93.6192C320.926 95.6987 322.611 97.3844 324.69 97.3844Z"
        fill="#FFD200"
      />
      <path d="M468.304 179.69H379.258V185.234H468.304V179.69Z" fill="#E6E6E6" />
      <path d="M468.304 194.373H379.258V199.918H468.304V194.373Z" fill="#E6E6E6" />
      <path d="M441.128 209.057H379.258V214.601H441.128V209.057Z" fill="#E6E6E6" />
      <path d="M465.998 118.581H379.258V170.54H465.998V118.581Z" fill="#E6E6E6" />
      <path opacity="0.17" d="M369.275 118.112H303.095V136.787H369.275V118.112Z" fill="#666666" />
      <path d="M369.275 144.562H303.095V163.237H369.275V144.562Z" fill="#E6E6E6" />
      <path
        opacity="0.17"
        d="M314.622 160.704C318.333 160.704 321.341 157.695 321.341 153.983C321.341 150.271 318.333 147.262 314.622 147.262C310.911 147.262 307.903 150.271 307.903 153.983C307.903 157.695 310.911 160.704 314.622 160.704Z"
        fill="#666666"
      />
      <path opacity="0.17" d="M353.099 149.804H326.403V152.759H353.099V149.804Z" fill="#666666" />
      <path opacity="0.17" d="M343.134 155.716H326.403V158.671H343.134V155.716Z" fill="#666666" />
      <path d="M369.275 171.021H303.095V189.696H369.275V171.021Z" fill="#E6E6E6" />
      <path
        opacity="0.17"
        d="M314.622 187.163C318.333 187.163 321.341 184.154 321.341 180.442C321.341 176.73 318.333 173.721 314.622 173.721C310.911 173.721 307.903 176.73 307.903 180.442C307.903 184.154 310.911 187.163 314.622 187.163Z"
        fill="#666666"
      />
      <path opacity="0.17" d="M353.099 176.254H326.403V179.209H353.099V176.254Z" fill="#666666" />
      <path opacity="0.17" d="M343.134 182.175H326.403V185.13H343.134V182.175Z" fill="#666666" />
      <path d="M369.275 197.471H303.095V216.146H369.275V197.471Z" fill="#E6E6E6" />
      <path
        opacity="0.17"
        d="M314.622 213.613C318.333 213.613 321.341 210.604 321.341 206.892C321.341 203.18 318.333 200.171 314.622 200.171C310.911 200.171 307.903 203.18 307.903 206.892C307.903 210.604 310.911 213.613 314.622 213.613Z"
        fill="#666666"
      />
      <path opacity="0.17" d="M353.099 202.713H326.403V205.668H353.099V202.713Z" fill="#666666" />
      <path opacity="0.17" d="M343.134 208.625H326.403V211.58H343.134V208.625Z" fill="#666666" />
      <path d="M264.216 180.126H180.515V241.441H264.216V180.126Z" fill="#FFD200" />
      <path opacity="0.43" d="M257.31 185.84H187.432V235.728H257.31V185.84Z" fill="white" />
      <path
        opacity="0.49"
        d="M188.231 235.728L207.249 200.552L223.396 227.83L237.925 210.784L257.3 235.728H188.231Z"
        fill="white"
      />
      <path
        d="M224.695 207.283C227.793 207.283 230.304 204.772 230.304 201.673C230.304 198.575 227.793 196.063 224.695 196.063C221.598 196.063 219.087 198.575 219.087 201.673C219.087 204.772 221.598 207.283 224.695 207.283Z"
        fill="white"
      />
      <path d="M264.607 32.1087H70.8154V160.42H264.607V32.1087Z" fill="#E6E6E6" />
      <path d="M264.607 32.1087H70.8154V51.2656H264.607V32.1087Z" fill="#C9C9C9" />
      <path
        d="M124.655 116.319C119.46 116.321 114.381 114.784 110.061 111.902C105.741 109.02 102.373 104.924 100.384 100.13C98.3945 95.336 97.873 90.0605 98.8853 84.9705C99.8975 79.8806 102.398 75.2048 106.071 71.5347C109.743 67.8645 114.423 65.3648 119.518 64.3517C124.613 63.3386 129.894 63.8575 134.693 65.843C139.493 67.8284 143.595 71.1912 146.482 75.5059C149.368 79.8206 150.908 84.8935 150.908 90.0829C150.901 97.037 148.132 103.704 143.211 108.622C138.289 113.541 131.616 116.308 124.655 116.319ZM124.655 70.9161C120.86 70.9161 117.151 72.0402 113.995 74.1463C110.84 76.2523 108.381 79.2458 106.929 82.7481C105.477 86.2503 105.097 90.1041 105.837 93.8221C106.577 97.5401 108.405 100.955 111.088 103.636C113.771 106.316 117.19 108.142 120.912 108.881C124.634 109.621 128.492 109.241 131.997 107.791C135.503 106.34 138.5 103.883 140.608 100.731C142.716 97.5794 143.842 93.8737 143.842 90.0829C143.837 85.0011 141.813 80.129 138.216 76.5357C134.619 72.9423 129.742 70.9213 124.655 70.9161Z"
        fill="white"
      />
      <path
        d="M124.655 116.319C118.235 116.32 112.037 113.97 107.235 109.713L111.923 104.428C115.433 107.539 119.963 109.255 124.655 109.25V116.319Z"
        fill="white"
      />
      <path
        d="M150.909 90.0823H143.842C143.837 85.0006 141.814 80.1284 138.217 76.5351C134.619 72.9418 129.742 70.9207 124.655 70.9155V63.8561C131.616 63.864 138.289 66.6296 143.211 71.5462C148.132 76.4629 150.901 83.1291 150.909 90.0823Z"
        fill="#24285B"
      />
      <path
        d="M124.655 116.318V109.249C129.742 109.244 134.619 107.223 138.217 103.629C141.814 100.036 143.837 95.1638 143.842 90.082H150.909C150.901 97.0362 148.133 103.703 143.211 108.622C138.29 113.54 131.617 116.308 124.655 116.318Z"
        fill="#FFD200"
      />
      <path d="M139.48 126.218H109.821V138.453H139.48V126.218Z" fill="#109CF1" />
      <path
        d="M125.228 81.6033C125.702 81.6033 125.84 81.7611 125.84 82.2244V83.2104C126.658 83.297 127.438 83.6042 128.095 84.0991C128.752 84.5941 129.262 85.2582 129.571 86.0203C129.817 86.5133 129.709 86.7795 129.166 87.0063L128.179 87.45C127.715 87.6767 127.488 87.5781 127.192 87.0852C126.981 86.584 126.615 86.163 126.149 85.883C125.682 85.603 125.138 85.4784 124.596 85.5274C122.869 85.5274 122.08 86.0401 122.08 87.1936C122.12 87.5463 122.282 87.8741 122.537 88.1205C122.793 88.367 123.127 88.5169 123.481 88.5444C123.962 88.6518 124.45 88.7276 124.942 88.7711C125.861 88.8126 126.768 88.9887 127.636 89.2937C128.07 89.4365 128.475 89.657 128.83 89.9444C129.191 90.3213 129.473 90.767 129.657 91.2548C129.842 91.7427 129.927 92.2627 129.906 92.7839C129.906 94.9925 128.426 96.4911 125.81 96.8756V97.9207C125.81 98.394 125.672 98.5517 125.198 98.5517H124.083C123.619 98.5517 123.461 98.394 123.461 97.9207V96.9348C122.552 96.8718 121.681 96.5415 120.96 95.9853C120.238 95.4291 119.697 94.672 119.405 93.8093C119.36 93.7193 119.338 93.6203 119.338 93.5199C119.338 93.4196 119.362 93.3207 119.407 93.231C119.452 93.1413 119.517 93.0631 119.597 93.0027C119.677 92.9422 119.77 92.9011 119.869 92.8825L120.856 92.5177C121.359 92.3304 121.586 92.4586 121.843 92.9614C122.05 93.5067 122.43 93.969 122.926 94.2771C123.422 94.5853 124.005 94.7223 124.586 94.6671C126.452 94.6671 127.38 94.0755 127.38 92.8825C127.395 92.6058 127.318 92.3318 127.161 92.1034C127.004 91.875 126.775 91.7052 126.511 91.6205C125.874 91.4036 125.209 91.2742 124.537 91.236C123.607 91.1854 122.692 90.9891 121.823 90.6543C121.396 90.5 120.998 90.2732 120.648 89.9838C120.284 89.6265 119.999 89.1974 119.81 88.7238C119.622 88.2501 119.534 87.7424 119.553 87.233C119.553 85.0442 120.935 83.585 123.501 83.2893V82.3033C123.501 81.8399 123.659 81.6822 124.123 81.6822L125.228 81.6033Z"
        fill="#A5A5A5"
      />
      <path opacity="0.44" d="M234.357 66.2525H175.119V107.445H234.357V66.2525Z" fill="white" />
      <path d="M234.357 117.876H175.119V129.826H234.357V117.876Z" fill="white" />
      <path d="M234.357 117.876H212.831V129.826H234.357V117.876Z" fill="#24285B" />
      <path d="M191.138 89.1858H183.094V101.569H191.138V89.1858Z" fill="#A5A5A5" />
      <path d="M226.382 86.3652H218.338V101.569H226.382V86.3652Z" fill="#C9C9C9" />
      <path d="M202.991 79.3057H194.947V101.568H202.991V79.3057Z" fill="#C9C9C9" />
      <path d="M214.954 72.1382H206.91V101.569H214.954V72.1382Z" fill="#A5A5A5" />
      <path d="M348.581 32.1087H284.313V58.3868H348.581V32.1087Z" fill="#FFD200" />
      <path
        opacity="0.17"
        d="M298.912 52.2997C302.958 52.2997 306.239 49.0191 306.239 44.9724C306.239 40.9256 302.958 37.6451 298.912 37.6451C294.865 37.6451 291.585 40.9256 291.585 44.9724C291.585 49.0191 294.865 52.2997 298.912 52.2997Z"
        fill="black"
      />
      <path opacity="0.09" d="M336.854 39.6928H314.092V43.4511H336.854V39.6928Z" fill="black" />
      <path opacity="0.09" d="M336.854 46.7997H314.092V50.558H336.854V46.7997Z" fill="black" />
      <path d="M432.651 32.1087H368.384V58.3868H432.651V32.1087Z" fill="#B0E2FF" />
      <path opacity="0.61" d="M421.835 38.6536H399.072V42.412H421.835V38.6536Z" fill="white" />
      <path opacity="0.21" d="M421.835 45.7614H399.072V49.5198H421.835V45.7614Z" fill="white" />
      <path
        opacity="0.61"
        d="M374.721 53.5894L379.414 40.6887L383.899 50.4605L387.902 44.0865L392.045 53.5894H374.721Z"
        fill="white"
      />
      <path
        opacity="0.61"
        d="M384.181 41.1652C385.065 41.1652 385.782 40.4484 385.782 39.5641C385.782 38.6798 385.065 37.9629 384.181 37.9629C383.297 37.9629 382.58 38.6798 382.58 39.5641C382.58 40.4484 383.297 41.1652 384.181 41.1652Z"
        fill="white"
      />
      <path
        d="M151.375 290.217C151.375 290.217 145.661 291.625 144.69 296.522C143.719 301.42 138.722 302.152 137.934 297.606C137.146 293.06 144.169 282.125 144.169 282.125L153.233 283.532L151.375 290.217Z"
        fill="#109CF1"
      />
      <path
        d="M399.348 122.84L376.926 146.217C370.086 153.344 361.217 158.192 351.524 160.103C341.832 162.014 331.786 160.895 322.752 156.899L297.938 145.921L273.011 188.705L309.085 194.109C324.619 196.438 340.494 194.286 354.848 187.907C369.201 181.528 381.436 171.185 390.115 158.095L407.54 131.819C404.725 128.911 401.994 125.918 399.348 122.84Z"
        fill="#109CF1"
      />
      <path
        opacity="0.46"
        d="M399.348 122.84L376.926 146.217C370.086 153.344 361.217 158.192 351.524 160.103C341.832 162.014 331.786 160.895 322.752 156.899L297.938 145.921L273.011 188.705L309.085 194.109C324.619 196.438 340.494 194.286 354.848 187.907C369.201 181.528 381.436 171.185 390.115 158.095L407.54 131.819C404.725 128.911 401.994 125.918 399.348 122.84Z"
        fill="white"
      />
      <path
        d="M226.465 285.038C226.465 285.038 222.046 288.923 223.482 293.736C224.917 298.549 220.849 301.547 218.02 297.831C215.191 294.116 216.303 281.21 216.303 281.21L224.973 278.227L226.465 285.038Z"
        fill="#109CF1"
      />
      <path
        d="M276.979 238.638L237.569 266.785C217.215 281.295 193.096 289.605 168.123 290.71L151.782 291.442V282.534L178.187 273.625C188.29 270.228 197.451 264.5 204.929 256.905L231.039 230.334L276.979 238.638Z"
        fill="#D9E2EC"
      />
      <path
        d="M304.004 114.874C305.525 118.116 306.6 121.548 307.199 125.078C307.285 125.934 307.029 126.79 306.485 127.458C305.942 128.125 305.156 128.55 304.299 128.638C302.95 128.847 301.57 128.663 300.322 128.109C299.074 127.555 298.012 126.655 297.262 125.514L293.504 120.898C292.853 119.895 292.53 118.715 292.578 117.52C292.626 116.325 293.043 115.175 293.771 114.227C296.22 110.526 302.512 111.3 304.004 114.874Z"
        fill="#F4A28C"
      />
      <path d="M296.164 121.657L291.52 142.247L303.047 143.992L301.794 128.314L296.164 121.657Z" fill="#F4A28C" />
      <path
        d="M302.597 115.677C300.76 116.444 298.836 116.988 296.869 117.295C297.71 117.919 298.35 118.775 298.713 119.757C299.075 120.739 299.144 121.805 298.91 122.826C298.698 123.82 298.146 124.709 297.35 125.341C296.554 125.972 295.563 126.307 294.547 126.288L291.732 118.491C291.3 117.388 291.174 116.188 291.367 115.019C291.561 113.85 292.066 112.755 292.829 111.849C293.495 111.067 294.21 110.329 294.969 109.639C296.869 107.922 300.697 108.232 302.147 105.952C302.294 105.727 302.497 105.544 302.735 105.421C302.974 105.298 303.241 105.24 303.509 105.251C303.777 105.262 304.038 105.343 304.266 105.486C304.493 105.628 304.68 105.828 304.807 106.064C306.018 108.288 306.961 112.172 304.244 114.635C303.755 115.069 303.199 115.421 302.597 115.677Z"
        fill="#24285B"
      />
      <path
        d="M299.485 122.418C299.485 122.418 298.585 120.109 296.896 120.926C295.207 121.742 296.502 125.148 298.993 124.529L299.485 122.418Z"
        fill="#F4A28C"
      />
      <path
        d="M305.539 118.632L308.087 120.349C308.232 120.443 308.351 120.573 308.434 120.725C308.516 120.877 308.56 121.048 308.56 121.221C308.56 121.395 308.516 121.565 308.434 121.717C308.351 121.87 308.232 121.999 308.087 122.094L305.933 123.375L305.539 118.632Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.31"
        d="M301.583 128.793C300.1 128.638 298.696 128.05 297.544 127.104C297.544 127.104 297.276 130.989 302.02 134.141L301.583 128.793Z"
        fill="#CE8172"
      />
      <path
        d="M303.048 143.993L291.52 142.262C291.52 142.262 232.757 125.697 224.2 160.952C215.642 196.206 216.641 219.484 216.641 219.484L256.291 238.793C256.291 238.793 365.442 194.334 303.048 143.993Z"
        fill="#109CF1"
      />
      <path
        opacity="0.08"
        d="M277.46 166.032C277.46 166.032 283.85 181.949 259.767 187.142C235.685 192.336 235.699 208.506 251.477 209.491C267.255 210.476 290.563 201.906 290.141 220.37C290.141 220.37 271.844 232.952 256.291 238.835L216.641 219.526V217.077L214.601 182.653L235.206 162.865L277.46 166.032Z"
        fill="black"
      />
      <path
        d="M279.783 155.758C278.967 150.477 276.125 145.72 271.86 142.499C267.596 139.278 262.243 137.845 256.939 138.504C241.809 140.221 218.937 146.948 208.282 171.225C191.687 209.112 201.864 232.755 281.036 209.435L278.896 200.555C278.896 200.555 225.411 206.944 228.606 190.112C231.309 175.912 283.259 181.189 279.783 155.758Z"
        fill="#109CF1"
      />
      <path
        opacity="0.46"
        d="M279.783 155.758C278.967 150.477 276.125 145.72 271.86 142.499C267.596 139.278 262.243 137.845 256.939 138.504C241.809 140.221 218.937 146.948 208.282 171.225C191.687 209.112 201.864 232.755 281.036 209.435L278.896 200.555C278.896 200.555 225.411 206.944 228.606 190.112C231.309 175.912 283.259 181.189 279.783 155.758Z"
        fill="white"
      />
      <path
        d="M278.88 200.555C278.88 200.555 286.255 193.307 291.336 194.377C296.417 195.446 293.49 206.001 281.019 209.435L278.88 200.555Z"
        fill="#F4A28C"
      />
      <path
        d="M216.641 219.469C216.641 219.469 293.519 229.138 290.155 248.405C286.791 267.671 225.804 286.924 225.804 286.924L220.976 277.368L260.569 249.01C260.569 249.01 193.6 264.899 216.641 219.469Z"
        fill="#F0F4F8"
      />
      <path
        d="M407.54 131.818C407.54 131.818 413.241 122.741 412.875 119.87C412.509 116.999 409.286 120.517 409.286 120.517C409.286 120.517 413.114 114.24 411.2 110.412C409.286 106.584 404.05 117.942 399.349 122.839L407.54 131.818Z"
        fill="#F4A28C"
      />
    </svg>
  </Icon>
);
