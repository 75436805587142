import type { FC } from 'react';
import { useCallback } from 'react';
import { ActionMenu, DroppableFileField, File, SimpleDownloadIcon, SimpleViewIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import {
  formatSupplierInvoiceFileName,
  MINDEE_FILE_TYPE,
  PERMISSION,
  type SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT,
} from '@graneet/business-logic';
import { Box } from '@chakra-ui/react';

import { getSupplierInvoiceBeEdited } from '../../../services/supplier-invoices.util';

import { formatFileDownloadUrl, formatFilePreviewUrl } from 'features/file/services/file.util';
import { Rule } from 'features/form/rules/Rule';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

export type SupplierInvoiceInvoiceFileForm = { invoiceFile?: File | null };

export const UpdatableInvoiceFileDetails: FC<{
  onFileChange: (newFile?: File) => void;
  onFileDelete: () => Promise<void>;
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}> = ({ onFileChange, onFileDelete, supplierInvoice }) => {
  const { t } = useTranslation(['supplierInvoices', 'global']);

  const getCreateSupplierInvoiceButtonProps = useDisabledButtonProps([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  const canCreateSupplierInvoice = usePermissions([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  const invoiceFileName = formatSupplierInvoiceFileName(supplierInvoice);

  const onFileView = useCallback(() => {
    window.open(formatFilePreviewUrl(supplierInvoice.invoiceFile!.id));
  }, [supplierInvoice]);

  const onFileDownload = useCallback(() => {
    const downloadUrl = formatFileDownloadUrl(supplierInvoice.invoiceFile!.id, invoiceFileName);
    window.open(downloadUrl);
  }, [invoiceFileName, supplierInvoice]);

  const canBeUpdatedProps = getSupplierInvoiceBeEdited(supplierInvoice, t, { allowDirectPaymentEdition: true });
  const createSupplierInvoiceButtonProps = getCreateSupplierInvoiceButtonProps();

  /**
   * Early return if the invoice file is already defined
   */
  if (supplierInvoice.invoiceFile) {
    return (
      <>
        <Box fontSize="lg" color="gray.800" mb={3} mt={6} fontFamily="heading">
          {t('supplierInvoices:fields.invoiceFile')}
        </Box>
        <File
          name={invoiceFileName}
          actions={
            <ActionMenu>
              <ActionMenu.Action icon={<SimpleViewIcon />} label={t('global:pdf.view')} onClick={onFileView} />
              <ActionMenu.Action
                icon={<SimpleDownloadIcon />}
                label={t('supplierInvoices:cards.informationsCard.download')}
                onClick={onFileDownload}
              />
              <ActionMenu.Delete
                onClick={onFileDelete}
                tooltip={createSupplierInvoiceButtonProps.tooltip || canBeUpdatedProps.tooltip}
                isDisabled={createSupplierInvoiceButtonProps.isDisabled || canBeUpdatedProps.isDisabled}
              />
            </ActionMenu>
          }
        />
      </>
    );
  }

  if (!canCreateSupplierInvoice) {
    return null;
  }

  return (
    <>
      <Box fontSize="lg" color="gray.800" mb={3} mt={6} fontFamily="heading">
        {t('supplierInvoices:fields.invoiceFile')}
      </Box>

      <DroppableFileField<SupplierInvoiceInvoiceFileForm>
        name="invoiceFile"
        accept={MINDEE_FILE_TYPE}
        isDisabled={canBeUpdatedProps.isDisabled}
        onFileChange={onFileChange}
      >
        <Rule.IsFileSizeValid />
      </DroppableFileField>
    </>
  );
};
