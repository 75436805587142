import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Modal } from '@graneet/lib-ui';
import { Box } from '@chakra-ui/react';

interface CreateQuoteModalProps {
  isOpen: boolean;
  onSubmit(): Promise<void>;
  onClose(): void;
  title: string;
  description: string;
  action: string;
}

export const RestoreQuoteModal: FC<CreateQuoteModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  description,
  action,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    await onSubmit();

    setIsLoading(false);
    onClose();
  }, [onClose, onSubmit]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="2xl">
      <Box mb={5}>{description}</Box>
      <Modal.Close />
      <Modal.PrimaryButton isLoading={isLoading} onClick={handleSubmit}>
        {action}
      </Modal.PrimaryButton>
    </Modal>
  );
};
