import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Table, Badge, PriceAdvanced, Date, Button, CollapseButton } from '@graneet/lib-ui';
import { GridItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { IBill } from '@graneet/business-logic';
import { isBillNotFullyPayed, PERMISSION } from '@graneet/business-logic';

import { BILL_STATUSES_STYLE } from '../constants/bill.constant';

import { BillLateDaysCell } from './BillLateDaysCell';

import { usePermissions } from 'features/role/hooks/usePermissions';

interface BillExpansibleRowProps {
  bill: IBill;

  onPay(bill: IBill): void;
}

export const BillExpansibleRow: FC<BillExpansibleRowProps> = ({ bill, onPay }) => {
  const { t } = useTranslation(['bill']);
  const [collapsed, setCollapsed] = useState(true);

  const handlePay = useCallback(() => onPay(bill), [bill, onPay]);
  const hasPayBillsPermission = usePermissions([PERMISSION.PAY_BILLS]);

  return (
    <>
      <Table.Row>
        {bill.payments && bill.payments?.length > 0 ? (
          <Table.Cell p={0}>
            <CollapseButton onChange={setCollapsed} />
          </Table.Cell>
        ) : (
          <Table.Cell />
        )}

        <Table.Cell variant="ellipsis">{bill.invoiceNumber}</Table.Cell>

        <Table.Cell variant="ellipsis">{bill.name}</Table.Cell>

        <Table.Cell center>
          <Badge colorScheme={BILL_STATUSES_STYLE[bill.status].variantColor}>{t(`bill:statuses.${bill.status}`)}</Badge>
        </Table.Cell>

        <BillLateDaysCell bill={bill} />

        <Table.Cell right>
          <PriceAdvanced amount={bill.totalToBePaidIncVAT} />
        </Table.Cell>

        <Table.Cell right>
          <PriceAdvanced amount={bill.remainingToBePaidIncVAT} />
        </Table.Cell>

        {isBillNotFullyPayed(bill) && hasPayBillsPermission ? (
          <Table.Cell center py={0}>
            <Button variant="outline" onClick={handlePay}>
              {t('bill:toPay')}
            </Button>
          </Table.Cell>
        ) : (
          <Table.Cell />
        )}
      </Table.Row>

      {!collapsed && (
        <Table.Row bg="gray.50" px={0}>
          <GridItem colSpan={5} />
          <GridItem py={2} colSpan={3} pr={4}>
            <Table bg="none" boxShadow="none" templateColumns={['1fr', '1fr', '1fr']}>
              <Table.Header _last={{ px: 2 }} _first={{ px: 2 }}>
                <Table.Cell center py={2}>
                  {t('bill:payment.fields.type')}
                </Table.Cell>
                <Table.Cell right py={2}>
                  {t('bill:payment.fields.paidAt')}
                </Table.Cell>
                <Table.Cell right py={2}>
                  {t('bill:payment.fields.amountPaidIncVAT')}
                </Table.Cell>
              </Table.Header>

              {(bill.payments || []).map((payment) => (
                <Table.Row _last={{ px: 2 }} _first={{ px: 2 }} key={`${payment.id}_payment`}>
                  <Table.Cell center py={2}>
                    {t(`bill:payment.types.${payment.type}`)}
                  </Table.Cell>

                  <Table.Cell right py={2}>
                    <Date>{payment.paidAt}</Date>
                  </Table.Cell>

                  <Table.Cell right py={2}>
                    <PriceAdvanced amount={payment.amountPaidIncVAT} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table>
          </GridItem>
          <GridItem colSpan={2} bg="gray.50" />
        </Table.Row>
      )}
    </>
  );
};
