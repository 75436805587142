export interface IQuotationCardTranslations {
  margin: {
    title: string;
    overheadCosts: string;
    profit: string;
    total: string;
  };
  amount: {
    title: string;
    hiddenCost: string;
    disbursement: string;
    totalMargin: string;
    marginBenefit: string;
    totalAmountExVAT: string;
  };

  summary: {
    title: string;
    totalBeforeDiscounts: string;
    totalAfterSaleDiscount: string;
    amountExVat: string;
    amountIncVat: string;
    menuEdit: string;
    menuRemove: string;
    menuAddDiscount: string;
    menuAddProrata: string;
    menuAddCustomDiscount: string;
    menuAddDiscountTooltip: string;
    addDiscounts: string;
    vat: string;
    reversalOfLiability: string;
    disableReversalOfLiability: string;
    enableReversalOfLiability: string;
    editGlobalVat: string;
    vatCustomDiscount: string;
  };
  information: {
    name: string;
    refCode: string;
    refCodeTooltip: string;
  };
  global: {
    days: string;
    loading: string;
    noResults: string;
  };
  associateProjectModal: {
    code: string;
    name: string;
    client: string;
    amountExVAT: string;
  };
  address: {
    address: string;
    postalCode: string;
    city: string;
    country: string;
  };
}

export const quotationCardTranslations: IQuotationCardTranslations = {
  margin: {
    title: 'Marge',
    overheadCosts: 'Frais généraux',
    profit: 'Marge bénéficiaire',
    total: 'Marge totale',
  },
  amount: {
    title: 'Prix de vente',
    hiddenCost: 'Frais cachés',
    disbursement: 'Déboursé sec HT',
    totalMargin: 'Marge totale',
    marginBenefit: 'La marge brute est estimée à',
    totalAmountExVAT: 'Total prix de vente HT',
  },
  summary: {
    title: 'Total',
    totalBeforeDiscounts: 'Total HT avant remise',
    totalAfterSaleDiscount: 'Total HT après remise',
    amountExVat: 'Total prix de vente HT',
    amountIncVat: 'Total prix de vente TTC',
    menuEdit: 'Modifier',
    menuRemove: 'Supprimer',
    menuAddDiscount: 'Ajouter une remise',
    menuAddProrata: 'Compte prorata',
    menuAddCustomDiscount: 'Plus et moins value',
    vat: 'TVA',
    menuAddDiscountTooltip: "Vous ne pouvez ajouter qu'une seule remise commerciale par devis",
    addDiscounts: 'Remise / Plus-value',
    reversalOfLiability: 'Autoliquidation de la TVA',
    disableReversalOfLiability: "Supprimer l'autoliquidation du devis",
    enableReversalOfLiability: "Soumettre le devis à l'autoliquidation",
    editGlobalVat: 'Appliquer un taux de TVA global',
    vatCustomDiscount: 'TVA {{ vatRate }} (± values)',
  },
  information: {
    name: 'Nom du devis',
    refCode: 'Numéro du devis',
    refCodeTooltip: 'Numéro du devis',
  },
  global: {
    days: 'jours',
    loading: 'Recherche en cours...',
    noResults: 'Aucun résultat',
  },
  associateProjectModal: {
    code: 'Code',
    name: 'Nom',
    client: 'Client',
    amountExVAT: 'Montant chantier HT',
  },
  address: {
    address: 'Adresse',
    postalCode: 'Code postal',
    city: 'Ville',
    country: 'Pays',
  },
};

export const configureDefaultLabels = (translations: IQuotationCardTranslations) => {
  Object.assign(quotationCardTranslations, translations);
};
