import { HStack, VStack, Text, CircularProgress, Box } from '@chakra-ui/react';
import type { ChangeEvent, FC } from 'react';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { GraneetButton, QuotationInput, useLocalStorage } from '@graneet/lib-ui';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useHelpScoutGetArticles } from 'features/common/services/helpscout.api';
import { Error } from 'features/common/components/Error';
import { useDebounce } from 'features/common/hooks/useDebounce';
import { useQuotationApi } from 'features/quotation/services/quote.api';

interface IItem {
  title: string;
  content: string;
  link: string;
}

interface IGroup {
  name: string;
  link: string;
  items: IItem[];
}

const Item: FC<IItem> = ({ title, content, link }) => {
  const handleOnClick = useCallback(() => {
    window.open(link, '_blank');
  }, [link]);

  return (
    <VStack
      onClick={handleOnClick}
      cursor="pointer"
      background="white"
      borderRadius="0.5rem"
      p="12px"
      gap="4px"
      alignSelf="stretch"
      border="1px solid rgba(0, 0, 0, 0.10)"
      alignItems="flex-start"
    >
      <Text fontSize="14px" fontWeight={600} color="primaryLight">
        {title}
      </Text>
      <Text fontSize="14px" color="baseSecondary">
        {content}...
      </Text>
    </VStack>
  );
};

const Group: FC<IGroup> = ({ name, link, items }) => {
  const { t } = useTranslation(['global']);

  const handleOnClick = useCallback(() => {
    window.open(link, '_blank');
  }, [link]);

  return (
    <VStack spacing={3} align="stretch">
      <HStack justifyContent="space-between">
        <Text fontSize="1rem" color="black">
          {name}
        </Text>
        {link && (
          <Text fontSize="0.813rem" color="primary" cursor="pointer" onClick={handleOnClick}>
            {t('global:help.seeMore')}
          </Text>
        )}
      </HStack>
      {items.map((item) => (
        <Item key={uuid()} {...item} />
      ))}
    </VStack>
  );
};

const Articles: FC<{ search: string }> = ({ search }) => {
  const { t } = useTranslation(['global']);
  const { data } = useHelpScoutGetArticles('60e42a4461c60c534bd6c726', search);
  const { useAskFormation } = useQuotationApi();
  const askFormationMutation = useAskFormation();
  const [alreadyAskedForFormation] = useLocalStorage('alreadyAskedForFormation', 'false');

  const items = useMemo(
    () => [
      {
        name: 'Devis',
        link: 'https://help.graneet.fr/',
        items:
          data?.map((article) => ({
            title: article.name,
            content: article.preview,
            link: article.url,
          })) ?? [],
      },
    ],
    [data],
  );

  const handleContactSupport = useCallback(() => {
    window.open('mailto:support@graneet.fr');
  }, []);

  if (data && data.length === 0) {
    return (
      <VStack justifyContent="center" alignItems="center" w="100%" h="100%">
        <Text fontSize="1rem" color="black">
          {t('global:help.noResult')}
        </Text>
        <GraneetButton size="md" variant="primary" onClick={handleContactSupport}>
          {t('global:help.contactSupport')}
        </GraneetButton>
        <GraneetButton
          size="md"
          onClick={() => askFormationMutation.mutateAsync()}
          isLoading={alreadyAskedForFormation === 'true'}
          tooltip={t('global:help.alreadyAskedFormation')}
        >
          {t('global:help.askFormation')}
        </GraneetButton>
      </VStack>
    );
  }

  return (
    <>
      {items.map((group) => (
        <Group key={group.name} {...group} />
      ))}
    </>
  );
};

export const QuoteHelp = () => {
  const [search, setSearch] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['global']);

  const debounceSearch = useDebounce(([input]) => {
    setSearch(input);
    setLoading(false);
  }, 500);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      setSearchInputValue(e.target.value);
      debounceSearch(e.target.value);
    },
    [debounceSearch],
  );

  return (
    <VStack spacing={3} align="stretch" height="100%">
      <QuotationInput
        placeholder={t('global:nav.search')}
        background="transparent"
        value={searchInputValue}
        onChange={handleSearch}
      />
      <Box height="100%" overflowY="auto">
        <Suspense
          fallback={
            <HStack justifyContent="center" alignItems="center" w="100%" h="100%">
              <CircularProgress isIndeterminate color="greenBrand.light" />
            </HStack>
          }
        >
          <ErrorBoundary fallback={<Error />}>
            {loading ? (
              <HStack justifyContent="center" alignItems="center" w="100%" h="100%">
                <CircularProgress isIndeterminate color="greenBrand.light" />
              </HStack>
            ) : (
              <Articles search={search} />
            )}
          </ErrorBoundary>
        </Suspense>
      </Box>
    </VStack>
  );
};
