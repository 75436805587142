import { forwardRef, useImperativeHandle, useRef } from 'react';
import type { InitialConfigType } from '@lexical/react/LexicalComposer';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import type { SerializedEditorState, LexicalEditor } from 'lexical';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { $getRoot } from 'lexical';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { ListItemNode, ListNode } from '@lexical/list';
import { isNil } from 'lodash-es';
import { Box } from '@chakra-ui/react';
import { HeadingNode } from '@lexical/rich-text';
import { AutoLinkNode, LinkNode } from '@lexical/link';

import { createInjectedVariableNodes, getPotentialStringifyObject, getRichTextPluginOptions } from '../../utils';
import { VariableNode } from '../RichTextInput/plugins/VariablePlugin';
import type { RichTextConfiguration, RichTextVariableConfiguration } from '../RichTextInput';
import '../../utils/richtext.css';

/*
 /!\/!\ This component is similar to a RichText in client-pdf. Please change both at the same time /!\/!\
 */

export interface RichTextGetterProps {
  configuration?: RichTextConfiguration;
}

const getConditionalNodes = (richTextVariableConfiguration?: RichTextVariableConfiguration) => {
  const nodes = [];
  if (richTextVariableConfiguration?.injectValueInLabel) {
    nodes.push(...createInjectedVariableNodes(richTextVariableConfiguration));
  }
  return nodes;
};

export type RichTextGetterHandler = {
  getPlainText: (value: string | null | undefined) => string | null | undefined;
  getLexicalEditor: () => LexicalEditor | null | undefined;
};

export const RichTextGetter = forwardRef<RichTextGetterHandler, RichTextGetterProps>(({ configuration = [] }, ref) => {
  const lexicalEditor = useRef<LexicalEditor | null | undefined>(null);

  useImperativeHandle(ref, () => ({
    getPlainText: (v: string | null | undefined): string | null | undefined => {
      if (isNil(v) || !lexicalEditor) {
        return null;
      }

      const [isObject, objectState] = getPotentialStringifyObject<SerializedEditorState>(v);
      if (isObject) {
        const state = lexicalEditor.current?.parseEditorState(objectState);
        return state?.read(() => $getRoot().getTextContent());
      }
      return objectState;
    },
    getLexicalEditor: () => lexicalEditor.current,
  }));

  const { theme, variablePluginOptions } = getRichTextPluginOptions(configuration);

  const initialConfig: InitialConfigType = {
    namespace: 'RichText',
    onError(error): void {
      console.warn(error);
      throw new Error(JSON.stringify(error));
    },
    editorState(editor): void {
      lexicalEditor.current = editor;
    },
    nodes: [
      ...getConditionalNodes(variablePluginOptions),
      ListNode,
      ListItemNode,
      VariableNode,
      LinkNode,
      AutoLinkNode,
      HeadingNode,
    ],
    theme,
  };

  return (
    <Box display="none">
      <LexicalComposer initialConfig={initialConfig}>
        <RichTextPlugin
          contentEditable={<ContentEditable />}
          placeholder={<div />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ListPlugin />
      </LexicalComposer>
    </Box>
  );
});
