import type { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { DEADLINE_TYPE } from '@graneet/business-logic';
import { getPaymentDeadline, REFERENCE_TIMEZONE, getDateWithTz, buildDateWithTz } from '@graneet/business-logic';
import type { BoxProps } from '@chakra-ui/react';
import { Text, Box } from '@chakra-ui/react';
import { getLocalDateFormat, isDate } from '@graneet/lib-ui';
import { isNil } from 'lodash-es';

interface ProgressStatementBillingTermLabelProps extends BoxProps {
  paymentTerm?: number;

  billingDate?: string;

  deadlineType: DEADLINE_TYPE;
}

export const ProgressStatementBillingTermLabel: FC<ProgressStatementBillingTermLabelProps> = ({
  paymentTerm,
  billingDate,
  deadlineType,
  ...styleProps
}) => {
  let dataDisplayed;
  let translationUsed;
  const { t } = useTranslation(['statement']);

  if (isNil(paymentTerm) || !billingDate || !isDate(billingDate)) {
    dataDisplayed = paymentTerm || 0;

    translationUsed = `statement:paymentDeadLine.termHint.${deadlineType}` as const;
  } else {
    // getPaymentDeadline returns a dayjs object with time zone information
    const paymentDeadline = getPaymentDeadline(
      buildDateWithTz(billingDate, REFERENCE_TIMEZONE),
      paymentTerm || 0,
      deadlineType,
    );

    // we need to format the deadline according to the reference tz
    dataDisplayed = getDateWithTz(paymentDeadline, REFERENCE_TIMEZONE).format(getLocalDateFormat());
    translationUsed = `statement:paymentDeadLine.deadlineHint.${deadlineType}` as const;
  }

  return (
    <Box ml={6} pt={6} mt="2px" {...styleProps}>
      <Trans t={t} i18nKey={translationUsed} values={{ paymentTerm: dataDisplayed }}>
        description_start
        <Text as="span" color="greenBrand.light" fontWeight={600}>
          displayed_data
        </Text>
        description_end
      </Trans>
    </Box>
  );
};
