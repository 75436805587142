import type { ReactNode, FC } from 'react';
import { createContext, useContext, useCallback, useMemo } from 'react';
import type {
  IContractResponseDTO,
  IItemResponseDTO,
  ILotResponseDTO,
  IProgressStatement,
  IProgressStatementDiscountLine,
  IProgressStatementLine,
  IProgressStatementCustomDiscountLine,
} from '@graneet/business-logic';
import { computeSuggestedDiscountFromLines } from '@graneet/business-logic';

import type { IProgressStatementLineValues } from '../services/progress-statement.util';
import {
  getContractsItems,
  mapCustomDiscountLinesToDictionaryIndexedPerContractCustomDiscountId,
  mapDiscountLinesToDictionaryIndexedPerContractId,
  mapItemLinesToDictionaryIndexedPerItemId,
} from '../services/progress-statement.util';

interface ProgressStatementContextValue {
  contracts: IContractResponseDTO[];

  previousItemsCumulativeValues: Record<number, IProgressStatementLineValues>;

  previousDiscountsCumulativeValues: Record<number, IProgressStatementDiscountLine>;

  previousCustomDiscountsCumulativeValues: Record<string, IProgressStatementCustomDiscountLine>;

  getItemsIdsOfLot(lotId: number): number[];

  getItemsIdsOfContainer(containerId: number): number[];

  indexedItems: Record<number, IItemResponseDTO>;

  getContractDiscountPercentageSuggestion(
    contractId: number,
    itemLines: { cumulativeAmountExVAT: number; item: IItemResponseDTO }[],
  ): number;

  getContractCustomDiscountPercentageSuggestion(
    customDiscountId: string,
    itemLines: { cumulativeAmountExVAT: number; item: IItemResponseDTO }[],
  ): number;
}

/**
 * This hook is simply a helper which builds the `value` prop of the ProgressStatementContext.Provider
 * inside the ProgressStatementProvider
 */
const useProgressStatementContextValue = (
  contracts: IContractResponseDTO[],
  previousProgressStatement?: IProgressStatement,
) => {
  // -- Items --
  const previousItemCumulativeValuesIndexed = useMemo(
    () => mapItemLinesToDictionaryIndexedPerItemId(previousProgressStatement?.lines),
    [previousProgressStatement],
  );

  const previousItemsCumulativeValues = useMemo(
    () =>
      getContractsItems(contracts).reduce<
        Record<
          number,
          Pick<
            IProgressStatementLine,
            'cumulativeProgressPercentage' | 'cumulativeAmountExVAT' | 'cumulativeProgressQuantity'
          >
        >
      >((acc, progressStatementLine) => {
        acc[progressStatementLine.id] = previousItemCumulativeValuesIndexed[progressStatementLine.id];

        return acc;
      }, {}),
    [contracts, previousItemCumulativeValuesIndexed],
  );

  // -- Discounts --
  const previousDiscountLinesCumulativeValuesIndexed = useMemo(
    () => mapDiscountLinesToDictionaryIndexedPerContractId(previousProgressStatement?.discountLines),
    [previousProgressStatement],
  );

  const previousDiscountsCumulativeValues = useMemo(
    () =>
      (contracts || []).reduce<Record<number, IProgressStatementDiscountLine>>((acc, contract) => {
        acc[contract.id] = previousDiscountLinesCumulativeValuesIndexed[contract.id];

        return acc;
      }, {}),
    [contracts, previousDiscountLinesCumulativeValuesIndexed],
  );

  // -- Custom Discounts --
  const previousCustomDiscountsCumulativeValues = useMemo(
    () =>
      mapCustomDiscountLinesToDictionaryIndexedPerContractCustomDiscountId(
        previousProgressStatement?.customDiscountLines,
      ),
    [previousProgressStatement],
  );

  const itemsIndexedByParentEntity = useMemo(() => {
    const relationsInternal = {
      containers: {} as Record<number, number[]>,
      lots: {} as Record<number, number[]>,
    };

    const buildLotItemIds = (lot: ILotResponseDTO): number[] => {
      const itemIds = lot.items.map((item) => item.id);
      const itemsOfSubLots = lot.subLots.map((subLot) => buildLotItemIds(subLot)).flat();

      const response = [...itemIds, ...itemsOfSubLots];
      relationsInternal.lots[lot.id] = response;

      return response;
    };

    (contracts || []).forEach((contract) => {
      const itemIds = contract.container.items.map((item) => item.id);

      relationsInternal.containers[contract.container.id] = [
        ...itemIds,
        ...contract.container.lots.map((lot) => buildLotItemIds(lot)).flat(),
      ];
    });

    return relationsInternal;
  }, [contracts]);

  const getItemsIdsOfLot = useCallback(
    (lotId: number) => itemsIndexedByParentEntity.lots[lotId],
    [itemsIndexedByParentEntity.lots],
  );

  const getItemsIdsOfContainer = useCallback(
    (containerId: number) => itemsIndexedByParentEntity.containers[containerId],
    [itemsIndexedByParentEntity.containers],
  );

  const indexedItems = useMemo<Record<number, IItemResponseDTO>>(() => {
    const items = getContractsItems(contracts);

    return items.reduce<Record<number, IItemResponseDTO>>((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [contracts]);

  const getContractDiscountPercentageSuggestion = useCallback(
    (contractId: number, itemLines: { cumulativeAmountExVAT: number; item: IItemResponseDTO }[]) => {
      const previousDiscountCumulativeValues = previousDiscountsCumulativeValues[contractId];
      const previousCumulativeProgressPercentage = previousDiscountCumulativeValues?.cumulativeProgressPercentage ?? 0;

      return computeSuggestedDiscountFromLines(
        itemLines,
        previousCumulativeProgressPercentage > 0 ? previousDiscountCumulativeValues : null,
      );
    },
    [previousDiscountsCumulativeValues],
  );

  const getContractCustomDiscountPercentageSuggestion = useCallback(
    (customDiscountId: string, itemLines: { cumulativeAmountExVAT: number; item: IItemResponseDTO }[]) => {
      const previousCustomDiscountCumulativeValues = previousCustomDiscountsCumulativeValues[customDiscountId];
      const previousCumulativeProgressPercentage =
        previousCustomDiscountCumulativeValues?.cumulativeProgressPercentage ?? 0;

      return computeSuggestedDiscountFromLines(
        itemLines,
        previousCumulativeProgressPercentage > 0 ? previousCustomDiscountCumulativeValues : null,
      );
    },
    [previousCustomDiscountsCumulativeValues],
  );

  return useMemo<ProgressStatementContextValue>(
    () => ({
      contracts,
      previousItemsCumulativeValues,
      previousDiscountsCumulativeValues,
      previousCustomDiscountsCumulativeValues,
      getItemsIdsOfLot,
      getItemsIdsOfContainer,
      indexedItems,
      getContractDiscountPercentageSuggestion,
      getContractCustomDiscountPercentageSuggestion,
    }),
    [
      contracts,
      previousItemsCumulativeValues,
      previousDiscountsCumulativeValues,
      previousCustomDiscountsCumulativeValues,
      getItemsIdsOfLot,
      getItemsIdsOfContainer,
      indexedItems,
      getContractDiscountPercentageSuggestion,
      getContractCustomDiscountPercentageSuggestion,
    ],
  );
};

const THROW_ERROR = () => {
  throw new Error('No ProgressStatementContext found');
};

const ProgressStatementContext = createContext<ProgressStatementContextValue>({
  contracts: [],
  previousItemsCumulativeValues: {},
  previousDiscountsCumulativeValues: {},
  previousCustomDiscountsCumulativeValues: {},
  getItemsIdsOfLot: THROW_ERROR,
  getItemsIdsOfContainer: THROW_ERROR,
  indexedItems: {},
  getContractDiscountPercentageSuggestion: THROW_ERROR,
  getContractCustomDiscountPercentageSuggestion: THROW_ERROR,
});

interface ProgressStatementProviderProps {
  contracts: IContractResponseDTO[];

  previousProgressStatement?: IProgressStatement;

  children: ReactNode;
}

export const ProgressStatementProvider: FC<ProgressStatementProviderProps> = ({
  contracts = [],
  previousProgressStatement,
  children,
}) => {
  const progressStatementContextValue = useProgressStatementContextValue(contracts, previousProgressStatement);

  return (
    <ProgressStatementContext.Provider value={progressStatementContextValue}>
      {children}
    </ProgressStatementContext.Provider>
  );
};

export const useProgressStatementContext = () => useContext(ProgressStatementContext);
