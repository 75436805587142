import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

export const MenuIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 14 14" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M1.75 7H12.25M1.75 3.5H12.25M1.75 10.5H8.75"
        stroke="#486581"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
