import type { IProject, IWorker } from '@graneet/business-logic';
import type { ITimeTableRange } from '@graneet/lib-ui';
import { MonthlyTimeTable } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Form, useForm } from 'graneet-form';

import { TimeTrackingCardProject } from './TimeTrackingCardProject';
import { TimeTrackingCardWorker } from './TimeTrackingCardWorker';

interface TimeTrackingMonthlyRowProps {
  ranges: ITimeTableRange[][];

  groupBy: 'project' | 'worker';

  row: IProject | IWorker;

  onColorChanged(): void;
}

const isRowProject = (groupBy: 'project' | 'worker', row: IProject | IWorker): row is IProject => groupBy === 'project';

export const TimeTrackingMonthlyRow: FC<TimeTrackingMonthlyRowProps> = ({ ranges, groupBy, row, onColorChanged }) => {
  const form = useForm();

  const header = useCallback(
    (nbMinutes: number) => {
      if (isRowProject(groupBy, row)) {
        return (
          <Form form={form}>
            <TimeTrackingCardProject
              id={row.id}
              name={row.name}
              refCode={row.refCode ?? ''}
              color={row.color ?? 'gray'}
              nbMinutes={nbMinutes}
              clientCode={row.primaryClient?.code}
              enterpriseName={row.primaryClient?.enterpriseName}
              onColorChanged={onColorChanged}
            />
          </Form>
        );
      }

      return <TimeTrackingCardWorker worker={row} nbMinutes={nbMinutes} />;
    },
    [groupBy, onColorChanged, row, form],
  );

  return <MonthlyTimeTable.Row ranges={ranges} header={header} />;
};
