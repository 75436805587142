import { HStack, VStack, Text } from '@chakra-ui/react';
import type { KeyboardEvent } from 'react';
import { forwardRef } from 'react';
import type { FieldValue } from 'graneet-form';

import { Input } from '../../../Input';
import { DeleteInCircleIcon } from '../../../Icons';
import { formulaFieldTranslations } from '../configureDefaultLabel';
import { AutoResizeTextarea } from '../../../AutoResizeTextarea/AutoResizeTextarea';

import { RoundedComponent } from './RoundedComponent';

export interface FormulaContentProps {
  comment?: string | null;
  error?: string | null;
  formula?: string | null;
  isReadOnly?: boolean;
  onClose: () => void;
  onCommentChange: (value: string) => void;
  onFormulaChange: (value: string) => void;
  onRoundLeft: () => void;
  onRoundRight: () => void;
}

export const FormulaContent = forwardRef<HTMLInputElement, FormulaContentProps>(
  (
    { onFormulaChange, onCommentChange, onRoundLeft, onRoundRight, error, formula, comment, isReadOnly, onClose },
    ref,
  ) => {
    const handleInput = (value: FieldValue) => {
      onFormulaChange(value.replaceAll('.', ','));
    };

    const handlePressEnter = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onClose();
      }
    };

    return (
      <VStack gap="4px">
        <HStack gap={0} paddingX={isReadOnly ? '4px' : 0} w="100%">
          <Input
            ref={ref}
            onChange={handleInput}
            value={formula}
            textAlign="left"
            inputProps={{
              style: {
                borderWidth: 1,
                fontWeight: 'normal',
                color: 'black',
                ...(isReadOnly ? { backgroundColor: 'white', opacity: 1 } : {}),
              },
            }}
            onKeyDown={handlePressEnter}
            placeholder={formulaFieldTranslations.formulaPlaceholder}
            isDisabled={isReadOnly}
          />
          {!isReadOnly && <RoundedComponent onRoundLeft={onRoundLeft} onRoundRight={onRoundRight} />}
        </HStack>
        {error ? (
          <HStack w="100%" mt={1}>
            <DeleteInCircleIcon />
            <Text color="red.500" fontSize="0.875rem" align="left" width="100%">
              {error}
            </Text>
          </HStack>
        ) : null}
        {(!isReadOnly || (isReadOnly && comment && comment !== '')) && (
          <HStack gap={0} w="100%" mt={1} paddingX={isReadOnly ? '4px' : 0}>
            <Input
              placeholder={formulaFieldTranslations.commentPlaceholder}
              value={comment ?? ''}
              shouldDisplayError={false}
              onChange={onCommentChange}
              textAlign="left"
              inputProps={{
                as: AutoResizeTextarea,
                style: {
                  border: 0,
                  fontWeight: 'normal',
                  color: 'black',
                  ...(isReadOnly ? { backgroundColor: 'white', opacity: 1, cursor: 'default' } : {}),
                },
              }}
              multiline
              isDisabled={isReadOnly}
            />
          </HStack>
        )}
      </VStack>
    );
  },
);
