import type { FC } from 'react';
import { Grid } from '@chakra-ui/react';
import type { TestableElement, TwoColumnsProps } from '@graneet/lib-ui';
import { TextAreaField, TextField, TwoColumns } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { Rule } from 'features/form/rules/Rule';
import { ADDRESS_FIELDS } from 'features/common/constants/address.constant';

/**
 * Please do not use this because it can cause headaches due to TS
 */
const prefixFieldName = (prefix: string | undefined, name: string) =>
  prefix ? `${prefix}${name[0].toUpperCase()}${name.slice(1)}` : name;

export const formatAddressDTO = ({
  address,
  postalCode,
  city,
  country,
}: {
  address?: string;
  postalCode?: string;
  city?: string;
  country?: string;
}) => ({
  address: address || '',
  postalCode: postalCode || '',
  city: city || '',
  country: country || '',
});

interface AddressFieldsProps extends TestableElement, TwoColumnsProps {
  isDisabled?: boolean;

  isRequired?: boolean;

  isReadOnly?: boolean;

  twoLines?: boolean;

  customAdressFieldLabel?: string;

  /**
   * @internal Do not use because its cannot compatible with Typescript
   */
  prefix?: string;
}

export interface AddressForm {
  address: string;

  postalCode?: string;

  city?: string;

  country?: string;
}

/**
 * Internally AddressForm is used.
 */
export const AddressFields: FC<AddressFieldsProps> = ({
  isDisabled = false,
  isRequired = true,
  isReadOnly = false,
  twoLines = false,
  'data-testid': testId,
  prefix,
  customAdressFieldLabel,
  ...props
}) => {
  const { t } = useTranslation(['global']);
  const forceProps = twoLines ? { templateColumns: '1fr' } : {};

  return (
    <TwoColumns data-testid={testId} mb={4} {...props} {...forceProps}>
      <TextAreaField<AddressForm>
        data-testid="textField:inlineAddress.street"
        name={prefixFieldName(prefix, 'address') as 'address'}
        label={customAdressFieldLabel || t('global:address.label')}
        data={{ fieldName: ADDRESS_FIELDS.ADDRESS }}
        isRequired={isRequired}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
      >
        {isRequired && <Rule.IsRequired />}
      </TextAreaField>

      <Grid templateColumns="8rem minmax(10rem, 1fr) minmax(10rem, 1fr)" gap={6}>
        <TextField<AddressForm>
          data-testid="textField:inlineAddress.postalCode"
          name={prefixFieldName(prefix, 'postalCode') as 'postalCode'}
          label={t('global:address.postalCode')}
          data={{ fieldName: ADDRESS_FIELDS.POSTAL_CODE }}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
        />
        <TextField<AddressForm>
          data-testid="textField:inlineAddress.city"
          name={prefixFieldName(prefix, 'city') as 'city'}
          label={t('global:address.city')}
          data={{ fieldName: ADDRESS_FIELDS.CITY }}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
        />
        <TextField<AddressForm>
          data-testid="textField:inlineAddress.country"
          name={prefixFieldName(prefix, 'country') as 'country'}
          label={t('global:address.country')}
          data={{ fieldName: ADDRESS_FIELDS.COUNTRY }}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
        />
      </Grid>
    </TwoColumns>
  );
};
