import type { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

import type { RichTextBitwise } from '../../../constants/rich-text-configuration.constant';

import { Toolbar } from './Toolbar/Toolbar';

interface CustomRichTextAreaProps {
  richTextBitwise: RichTextBitwise | undefined;

  toolbarContent?: ReactNode[];
}

export const CustomRichTextAreaPlugin: FC<CustomRichTextAreaProps> = ({ richTextBitwise, toolbarContent }) => (
  <Box>
    <Toolbar richTextBitwise={richTextBitwise} hasBoxShadow={false} toolbarContent={toolbarContent} />
  </Box>
);
