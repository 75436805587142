import type { FC, ReactNode } from 'react';
import type { GridItemProps } from '@chakra-ui/react';
import { GridItem } from '@chakra-ui/react';

import { PriceAdvanced } from '../../PriceAdvanced';

export interface ItemProps extends Omit<GridItemProps, 'left'> {
  label: string;
  amount?: number | null;
  rightContent?: ReactNode;
  important?: boolean;
  contrasted?: boolean;
  light?: boolean;
  darkGrey?: boolean;
  lightGrey?: boolean;
  green?: boolean;
  warning?: boolean;
  left?: boolean;
  sign?: boolean;
  negative?: boolean;
  isAmountColored?: boolean;
  hasAmountEqualToZeroWarning?: boolean;
}

export const Item: FC<ItemProps> = ({
  label,
  amount,
  rightContent,
  important = false,
  contrasted = false,
  light = false,
  lightGrey = false,
  darkGrey = false,
  green = false,
  warning = false,
  left = false,
  sign = false,
  negative = false,
  isAmountColored = false,
  hasAmountEqualToZeroWarning,
  ...props
}) => {
  const styleProps = {
    ...props,
  };

  // Color
  if (contrasted) {
    styleProps.color = 'greenBrand.light';
  } else if (warning) {
    styleProps.color = 'red.500';
  } else if (light) {
    styleProps.color = 'gray.600';
  } else if (darkGrey) {
    styleProps.color = 'gray.800';
  } else if (lightGrey) {
    styleProps.color = 'gray.500';
  } else if (green) {
    styleProps.color = 'green.700';
  } else {
    styleProps.color = 'black';
  }

  // Font Weight
  if (warning) {
    styleProps.fontWeight = '700';
  } else if (important) {
    styleProps.fontWeight = '600';
  }

  return (
    <>
      <GridItem flex={1} pl={2} textAlign={!left ? 'right' : undefined} {...styleProps}>
        {label}
      </GridItem>

      <GridItem>
        {amount !== null && amount !== undefined && (
          <PriceAdvanced
            amount={amount}
            display="flex"
            pl={4}
            justifyContent="flex-end"
            alignItems="center"
            negative={negative}
            sign={sign}
            textAlign="right"
            isAmountColored={isAmountColored}
            hasAmountEqualToZeroWarning={hasAmountEqualToZeroWarning}
            {...styleProps}
          />
        )}
      </GridItem>

      <GridItem pr={rightContent ? 4 : 2} {...styleProps}>
        {rightContent}
      </GridItem>
    </>
  );
};
