import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Callout, Modal, SimpleAlertIcon, SimpleCheckCircleIcon } from '@graneet/lib-ui';
import { CircularProgress, Text, VStack } from '@chakra-ui/react';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

interface AccountingTableDownloadModalProps {
  isOpen: boolean;

  onClose(): void;

  sseStatus: {
    isError: boolean;
    isGenerating: boolean;
  };
}

export const AccountingTableDownloadModal: FC<AccountingTableDownloadModalProps> = ({
  sseStatus: { isError, isGenerating },
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation(['accounting', 'global']);

  const handleClose = useMemo(() => (isGenerating ? () => {} : onClose), [isGenerating, onClose]);

  return (
    <Modal title={t('global:generateExportModal.titleExport')} isOpen={isOpen} onClose={handleClose}>
      {isGenerating ? (
        <VStack spacing={6}>
          <CircularProgress size={12} color="gray.800" isIndeterminate />
          <Text textAlign="center">{t('global:generateExportModal.pending')}</Text>
        </VStack>
      ) : (
        <Callout
          colorScheme={isError ? 'red' : 'green'}
          icon={
            isError ? (
              <SimpleAlertIcon boxSize={5} stroke="red.500" />
            ) : (
              <SimpleCheckCircleIcon stroke="green.500" boxSize={5} />
            )
          }
        >
          {isError
            ? t('global:generateExportModal.error', { email: SUPPORT_EMAIL })
            : t('global:generateExportModal.success')}
        </Callout>
      )}

      <Modal.PrimaryButton isDisabled={isGenerating} onClick={onClose}>
        {t('global:words.c.close')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
