import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useFormStatus, useOnChangeValues, useStepForm } from 'graneet-form';
import { Text, useDisclosure, VStack } from '@chakra-ui/react';
import {
  DroppableFileField,
  StepperModal,
  Callout,
  SimpleAlertIcon,
  SegmentedInputGroupField,
  Button,
} from '@graneet/lib-ui';
import type { ICompanyQuoteCreditResponse } from '@graneet/business-logic';
import { FEATURE_FLAGS, LONG_DATE_FORMAT_FR, TRACKING_EVENT_ENUM } from '@graneet/business-logic';
import dayjs from 'dayjs';

import { CreateQuoteModal } from '../CreateQuoteModal';

import type { QuoteImportWizard } from './CreateQuoteFromImportModal';

import { Rule } from 'features/form/rules/Rule';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';
import { TEMPLATE_URL, TEMPLATE_URL_BASE } from 'features/import/constants/import.constant';
import { downloadFile } from 'features/file/services/file.util';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export type ImportFileStepForm = {
  type: 'SIMPLE' | 'COMPLEX';
  version: 'V1' | 'V2';
  file: File;
};

interface ImportFileStepProps {
  quoteCredits: ICompanyQuoteCreditResponse;

  onQuoteCreated(values: { name: string }): Promise<void>;
}

export const ImportFileStep: FC<ImportFileStepProps> = ({ quoteCredits, onQuoteCreated }) => {
  const isQuoteV2Enabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);
  const isQuoteV1Disabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_DISABLE_V1);

  const { t } = useTranslation(['global', 'quote']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { form, initFormValues } = useStepForm<QuoteImportWizard, 'FILE'>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { type, version } = useOnChangeValues(form, ['type', 'version']);
  const { limitQuoteCredit, consumedQuoteCredit, offsetQuoteCredit, planPeriodResetDate } = quoteCredits;
  const hasQuoteCredit = useMemo(
    () => consumedQuoteCredit < limitQuoteCredit || offsetQuoteCredit > 0,
    [consumedQuoteCredit, limitQuoteCredit, offsetQuoteCredit],
  );

  const formatResetDate = dayjs(planPeriodResetDate).add(1, 'month').format(LONG_DATE_FORMAT_FR);

  const segmentAnalytics = useSegmentAnalytics();
  useEffect(() => {
    // Avoid multiple send of this event
    if (!hasQuoteCredit) {
      segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.QUOTA_REACHED, { type: 'quote-import' });
    }
  }, [hasQuoteCredit, segmentAnalytics]);

  const downloadTemplate = useCallback(() => {
    downloadFile(TEMPLATE_URL_BASE + TEMPLATE_URL.SIMPLE_QUOTE);
  }, []);

  // Form has to be empty on first render
  useEffect(() => {
    initFormValues({});
  }, [initFormValues]);

  return (
    <Form form={form}>
      <VStack gap={6} w="100%">
        <SegmentedInputGroupField<ImportFileStepForm>
          flex={1}
          name="type"
          options={[
            {
              optionValue: 'SIMPLE',
              optionLabel: t('quote:importModal.modes.SIMPLE.label'),
              optionTooltip: t('quote:importModal.modes.SIMPLE.tooltip'),
            },
            {
              optionValue: 'COMPLEX',
              optionLabel: t('quote:importModal.modes.COMPLEX.label'),
              optionTooltip: t('quote:importModal.modes.COMPLEX.tooltip'),
            },
          ]}
        />

        {isQuoteV2Enabled && !isQuoteV1Disabled && (
          <SegmentedInputGroupField<ImportFileStepForm>
            flex={1}
            name="version"
            options={[
              {
                optionValue: 'V1',
                optionLabel: t('quote:importModal.versions.V1.label'),
                optionTooltip: t('quote:importModal.versions.V1.tooltip'),
              },
              {
                optionValue: 'V2',
                optionLabel: t('quote:importModal.versions.V2.label'),
                optionTooltip: t('quote:importModal.versions.V2.tooltip'),
              },
            ]}
          />
        )}

        {type === 'SIMPLE' && (
          <Callout>
            <Text>{t('quote:importModal.modes.SIMPLE.description')}</Text>
            <Button variant="outline" mt={4} onClick={downloadTemplate}>
              {t('global:spreadsheetImport.menu.downloadTemplate')}
            </Button>
          </Callout>
        )}

        {type === 'COMPLEX' &&
          hasQuoteCredit &&
          ((isQuoteV2Enabled && version) || !isQuoteV2Enabled || isQuoteV1Disabled) && (
            <VStack spacing={6} justifyContent="flex-start">
              <Text>{t('quote:importModal.descriptionFirstStep')}</Text>

              <DroppableFileField<ImportFileStepForm> name="file">
                <Rule.IsRequired />
                <Rule.IsFileSizeValid />
              </DroppableFileField>

              <Callout w="100%">
                <Text>
                  {t('quote:creditStatus.quoteConsumption', {
                    limitQuoteCredit,
                    consumedQuoteCredit,
                    formatResetDate,
                  })}
                </Text>
                {offsetQuoteCredit > 0 && (
                  <Text>
                    {t('quote:creditStatus.quoteOffsetConsumption', {
                      count: offsetQuoteCredit,
                    })}
                  </Text>
                )}
              </Callout>
              <StepperModal.PrimaryButton isDisabled={!isFormValid}>
                {t('global:words.c.confirm')}
              </StepperModal.PrimaryButton>
            </VStack>
          )}

        {type === 'COMPLEX' && !hasQuoteCredit && (
          <>
            <Callout w="100%" colorScheme="yellow" my={3} icon={<SimpleAlertIcon stroke="yellow.500" boxSize={6} />}>
              <Text whiteSpace="pre-line">
                {t('quote:creditStatus.quoteConsumptionLimitReached', {
                  limitQuoteCredit,
                  formatResetDate,
                  supportEmail: SUPPORT_EMAIL,
                })}
              </Text>
            </Callout>

            <StepperModal.PrimaryButton onClick={onOpen}>
              {t('quote:actions.createManually')}
            </StepperModal.PrimaryButton>
            <CreateQuoteModal
              title={t('quote:creationForm.creationTitle')}
              description={t('quote:creationForm.description')}
              action={t('quote:creationForm.submit')}
              isOpen={isOpen}
              onClose={onClose}
              onSubmit={onQuoteCreated}
            />
          </>
        )}

        {type === 'SIMPLE' && ((isQuoteV2Enabled && version) || !isQuoteV2Enabled || isQuoteV1Disabled) && (
          <StepperModal.PrimaryButton>{t('global:words.c.next')}</StepperModal.PrimaryButton>
        )}
      </VStack>

      <StepperModal.CloseButton />
    </Form>
  );
};
