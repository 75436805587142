import { useCallback } from 'react';
import type { Quote, QuoteNodeCluster } from '@org/quotation-lib';
import { QuoteNodeDuplicateClusterUsecase, QuoteNodeImportCluster } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from 'features/quotation/quote-common/hooks/useQuoteSetToStore';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { mapQuoteNodeObjectToQuoteNodeDTO } from 'features/quotation/quote-common/mappers/quoteNodeMapper';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteNodeDuplicate() {
  const quoteSetToStore = useQuoteSetToStore();

  const { quoteNodeProxyApi, quoteNodeDeletesProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId?: string): ICommand => {
      if (!nodeId) {
        throw new Error('nodeId is required');
      }

      return {
        execute(quote: Quote) {
          try {
            const tree = quote.getTree();
            const node = tree.getNodeOrThrow(this.oldValue && this.oldValue.nodeId ? this.oldValue.nodeId : nodeId);
            const parent = node.getParentOrThrow();
            const nextSibling = node.getNextSibling();
            let newCluster: QuoteNodeCluster;
            if (this.oldValue && this.oldValue.nodeCluster) {
              newCluster = this.oldValue.nodeCluster;
            } else {
              newCluster = QuoteNodeDuplicateClusterUsecase.execute(node);
            }

            const nodeIdToDelete = QuoteNodeImportCluster.execute(
              quote,
              newCluster,
              parent.getId(),
              node.getId(),
              nextSibling?.getId() || null,
            );

            this.oldValue = {
              nodeCluster: newCluster,
              nodeId,
              nodeIdToDelete,
            };
            quoteSetToStore(quote);

            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });

            quoteNodeProxyApi
              .importCluster({
                quoteId: quote.getId(),
                timestamp,
                clientRequestId,
                parentId: parent.getId(),
                prevSiblingId: node.getId(),
                nextSiblingId: nextSibling?.getId() || null,
                nodeObject: mapQuoteNodeObjectToQuoteNodeDTO(newCluster.nodeObject),
                childrenObject: newCluster.childrenObject.map((child) => mapQuoteNodeObjectToQuoteNodeDTO(child)),
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
          } catch (err: any) {
            return quoteError(err.message);
          }
          return true;
        },
        undo(quote: Quote) {
          try {
            const node = quote.getTree().getNodeOrThrow(this.oldValue.nodeIdToDelete);
            const content = node.getContent();
            switch (content.getType()) {
              case 'QuoteLot': {
                const quoteLot = quote.getTree().getQuoteLotOrThrow(node.getId());
                quoteLot.deleteQuoteLot();
                break;
              }
              case 'QuoteBasicItem': {
                const quoteBasicItem = quote.getTree().getQuoteBasicItemOrThrow(node.getId());
                quoteBasicItem.deleteQuoteItem();
                break;
              }
              case 'QuoteSubItem': {
                const quoteSubItem = quote.getTree().getQuoteSubItemOrThrow(node.getId());
                quoteSubItem.deleteQuoteItem();
                break;
              }
              case 'QuoteHiddenCostItem': {
                const quoteHiddenCostItem = quote.getTree().getQuoteHiddenCostOrThrow(node.getId());
                quoteHiddenCostItem.deleteQuoteItem();
                break;
              }
              case 'QuoteOptionalItem': {
                const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(node.getId());
                quoteOptionalItem.deleteQuoteItem();
                break;
              }
              case 'QuoteComponent': {
                const quoteComponent = quote.getTree().getQuoteComponentOrThrow(node.getId());
                quoteComponent.deleteQuoteComponent();
                break;
              }
              default:
                return false;
            }

            quoteSetToStore(quote);
            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteNodeDeletesProxyApi
              .deleteQuoteNode({
                quoteId: quote.getId(),
                timestamp,
                clientRequestId,
                nodeId: node.getId(),
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
            return true;
          } catch (err: any) {
            return quoteError(err.message);
          }
        },
      };
    },
    [quoteError, quoteNodeDeletesProxyApi, quoteNodeProxyApi, quoteSetToStore],
  );
}
