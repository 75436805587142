import type { ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import { Card, MetricWithDescription, Price, GotoLink, Table, ErrorIcon } from '@graneet/lib-ui';
import { HStack, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

const DEFAULT_VISIBLE_ROWS = 20;

interface ClientDashboardSummaryCardProps<T extends Record<any, any>> {
  action: {
    title: string;
    link: string;
  };
  numbers: {
    value: number;
    title: string;
    color?: string;
    isPrice?: boolean;
  }[];
  title: string;
  data: T[];
  table: {
    numberOfSkeletonRows?: number;
    templateColumns: string[];
    renderHeader: () => ReactNode;
    renderRow: (item: T) => ReactNode;
  };
}

export const ClientDashboardSummaryCard = <T extends Record<any, any>>({
  action,
  title,
  numbers,
  data,
  table,
}: ClientDashboardSummaryCardProps<T>): JSX.Element => {
  const { t } = useTranslation(['global']);
  const defaultTable = {
    numberOfSkeletonRows: DEFAULT_VISIBLE_ROWS,
    templateColumns: [],
    errorState: {
      icon: <ErrorIcon />,
      message: t('global:listingError', { email: SUPPORT_EMAIL }),
    },
  };
  const tableData = {
    ...defaultTable,
    ...(table ?? {}),
  };

  const { numberOfSkeletonRows, templateColumns, renderHeader, renderRow } = tableData;

  const renderHeaderElement = useMemo(renderHeader, [renderHeader]);
  const renderRowElement = useCallback((item: T) => renderRow(item), [renderRow]);

  return (
    <Card title={title} topRightContent={action && <GotoLink to={action.link} label={action.title} />}>
      <HStack spacing={12}>
        {numbers.map((numberData) => (
          <MetricWithDescription key={numberData.title} description={numberData.title} color={numberData.color}>
            {numberData.isPrice ? <Price amount={numberData.value} /> : numberData.value}
          </MetricWithDescription>
        ))}
      </HStack>
      {data.length > 0 && (
        <Box borderRadius="6px" boxShadow="subtle" overflow="hidden" mt={4}>
          <Table templateColumns={templateColumns} numberOfSkeletonRows={numberOfSkeletonRows}>
            <Table.Header>{renderHeaderElement}</Table.Header>
            {data.map((item) => renderRowElement(item))}
          </Table>
        </Box>
      )}
    </Card>
  );
};
