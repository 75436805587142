import { PERMISSION } from '@graneet/business-logic';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { EditPdfSettingsScreen } from './EditPdfSettingsScreen';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { DisplayNotFoundScreen } from 'screens/DisplayNotFoundScreen';

export const PdfSettingsRouter = () => {
  const { path } = useRouteMatch();
  const hasUpdateCompanyInformationPermission = usePermissions([PERMISSION.UPDATE_COMPANY_INFORMATIONS]);

  return (
    <Switch>
      {hasUpdateCompanyInformationPermission && (
        <Route path={`${path}/:template/edit`} component={EditPdfSettingsScreen} />
      )}
      <Route path="*" component={DisplayNotFoundScreen} />
    </Switch>
  );
};
