import type { IconProps } from '@chakra-ui/react';
import { Box, HStack, Menu, MenuButton, MenuItem, MenuList, Portal, Text } from '@chakra-ui/react';
import type { ReactElement, ReactNode } from 'react';

import type { GraneetButtonVariant } from '../Button/Button';
import { GraneetButton } from '../Button/Button';
import { Tooltip } from '../../Tooltip';
import { GraneetIconButton } from '../IconButton/IconButton';

export interface ButtonListCta {
  label: string | ReactNode;
  color: 'gray' | 'blue' | 'green' | 'red' | 'greenBrand' | 'black';
  leftIcon?: ReactElement<IconProps> | string;
  rightIcon?: ReactElement<IconProps> | string;
  shortCut?: string;
  disabled?: boolean;
  tooltip?: string;
  onClick: () => void;
}

interface ButtonListProps {
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  tooltip?: ReactNode;
  rightIcon?: ReactElement<IconProps> | string;
  leftIcon?: ReactElement<IconProps> | string;
  width?: 'sm' | 'md' | 'lg' | 'full';
  size?: 'sm' | 'md' | 'lg';
  ctaList: ButtonListCta[];
  variant?: GraneetButtonVariant;
}

export const ButtonList = ({
  label,
  ctaList,
  disabled,
  loading,
  tooltip,
  rightIcon,
  leftIcon,
  width = 'sm',
  size = 'md',
  variant,
}: ButtonListProps) => {
  const elem = (
    <Menu matchWidth>
      {({ isOpen }) => (
        <>
          <MenuButton as={Box} role="group" cursor={!disabled ? 'pointer' : 'not-allowed'}>
            {label ? (
              <GraneetButton
                width={width}
                size={size}
                isDisabled={disabled}
                isLoading={loading}
                rightIcon={rightIcon}
                leftIcon={leftIcon}
                variant={variant}
                isActive={isOpen}
              >
                {label}
              </GraneetButton>
            ) : (
              <GraneetIconButton isDisabled={disabled || loading} color="default" icon={rightIcon!} size={size} />
            )}
          </MenuButton>
          {!disabled && (
            <Portal>
              <MenuList
                zIndex={1401}
                borderRadius="0.375rem"
                display="flex"
                flexDirection="column"
                p="8px"
                gap="4px"
                minWidth="250px"
              >
                {ctaList.map((cta, index) => (
                  <MenuItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`button-list-${index}-${cta.label}`}
                    fontFamily="body"
                    fontSize="0.813rem"
                    fontWeight={500}
                    fontStyle="normal"
                    onClick={cta.onClick}
                    borderRadius="6px"
                    isDisabled={cta.disabled}
                  >
                    {cta.disabled ? (
                      <Tooltip label={cta.tooltip} placement="left">
                        <HStack width="100%" color={`${cta.color}.500`}>
                          {typeof cta.leftIcon === 'string' ? <i className={cta.leftIcon} /> : cta.leftIcon}
                          <>{cta.label}</>
                          {cta.rightIcon && (
                            <Box ml="auto">
                              {typeof cta.rightIcon === 'string' ? <i className={cta.rightIcon} /> : cta.rightIcon}
                            </Box>
                          )}
                          {cta.shortCut && (
                            <Text fontSize="0.688rem" color="#9DA4AE" ml="auto" fontWeight={500}>
                              {cta.shortCut}
                            </Text>
                          )}
                        </HStack>
                      </Tooltip>
                    ) : (
                      <HStack width="100%" color={`${cta.color}.500`}>
                        {typeof cta.leftIcon === 'string' ? <i className={cta.leftIcon} /> : cta.leftIcon}
                        <>{cta.label}</>
                        {cta.rightIcon && (
                          <Box ml="auto">
                            {typeof cta.rightIcon === 'string' ? <i className={cta.rightIcon} /> : cta.rightIcon}
                          </Box>
                        )}
                        {cta.shortCut && (
                          <Text fontSize="0.688rem" color="#9DA4AE" ml="auto" fontWeight={500}>
                            {cta.shortCut}
                          </Text>
                        )}
                      </HStack>
                    )}
                  </MenuItem>
                ))}
              </MenuList>
            </Portal>
          )}
        </>
      )}
    </Menu>
  );

  if (tooltip) {
    return <Tooltip label={tooltip}>{elem}</Tooltip>;
  }

  return elem;
};
