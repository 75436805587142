import { useCallback, type FC } from 'react';
import type { RenderActionsProps } from '@graneet/lib-ui';
import { Badge, Date, PriceAdvanced, SimpleNewQuoteIcon, Table, Tooltip, formatDataOrEmpty } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { EXPORT_ENTITY, type IUser, type QuotePaginatedResponse } from '@graneet/business-logic';
import type { QuoteWithClientAndAccountManagersDTO } from '@org/graneet-bff-client';
import { Flex } from '@chakra-ui/react';

import { QuoteStatusBadge } from '../../../quote-common/components/badges/QuoteStatusBadge';
import { QUOTE_FIELDS } from '../../../quote-common/constants/quote.constant';
import { QuoteBatchStatusActions } from '../QuoteBatchStatusActions';

import { TagsBadges } from 'features/common/components/TagsBadges';
import { UserAvatars } from 'features/user/components/UserAvatars';
import type { SelectedItems } from 'features/export/components/ExportButton/ExportButton';
import { ExportButton } from 'features/export/components/ExportButton/ExportButton';

const URL_QUOTE = (quote: QuoteWithClientAndAccountManagersDTO) =>
  typeof quote.id === 'string' ? `/opportunities/quotation/${quote.id}` : `/opportunities/quotes/${quote.id}`;

const TEMPLATE_COLUMNS = (showClientColumn: boolean, showAccountManagers: boolean, displaySelects: boolean) =>
  [
    displaySelects ? '4rem' : null,
    '4rem',
    'minmax(auto, 11rem)',
    'minmax(11rem, 2fr)',
    '8rem',
    showClientColumn ? 'minmax(8rem, 1fr)' : null,
    '8rem',
    '8rem',
    showAccountManagers ? '5rem' : null,
  ].filter(Boolean) as string[];

interface QuotesTableProps {
  quotes: (QuoteWithClientAndAccountManagersDTO & {
    quoteAccountManagers?: {
      user: IUser;
    }[];
  })[];

  showClientColumn: boolean;

  showAccountManagers: boolean;

  hasPagination: boolean;

  displaySelects?: boolean;

  onStatusesChanged?: () => Promise<void>;
}

export const QuoteTable: FC<QuotesTableProps> = ({
  quotes,
  showClientColumn,
  showAccountManagers,
  hasPagination,
  displaySelects = false,
  onStatusesChanged = async () => {},
}) => {
  const { t } = useTranslation(['quote', 'accountManager']);

  type Data = SelectedItems['QUOTE'];

  const renderActions = useCallback<FC<RenderActionsProps<Data>>>(
    (props) => (
      <>
        <QuoteBatchStatusActions {...props} onStatusesChanged={onStatusesChanged} />
        <ExportButton entity={EXPORT_ENTITY.QUOTE} {...props} />
      </>
    ),
    [onStatusesChanged],
  );

  const selectLabel = useCallback(
    (numberSelectedItems: number) => {
      if (numberSelectedItems === 0) {
        return '';
      }
      return t('quote:banner.selection', { count: numberSelectedItems });
    },
    [t],
  );

  const allSelectedLabel = useCallback(
    (totalNumberOfItems?: number): string => t('quote:banner.allSelectedLabel', { count: totalNumberOfItems }),
    [t],
  );

  return (
    <Table
      templateColumns={TEMPLATE_COLUMNS(showClientColumn, showAccountManagers, displaySelects)}
      noResultLabel={t('quote:errors.noResult')}
    >
      {hasPagination && (
        <Table.Information<QuotePaginatedResponse>
          countLabel={(count) => t('quote:quote', { count })}
          sums={{
            amountExVAT: {
              label: t('quote:fields.amountExVAT'),
              type: 'currency',
            },
          }}
        />
      )}

      <Table.Header>
        {displaySelects && (
          <Table.Cell>
            <Table.HeaderSelect />
            <Table.SelectBanner<Data>
              allSelectedLabel={allSelectedLabel}
              selectLabel={selectLabel}
              renderActions={renderActions}
            />
          </Table.Cell>
        )}
        <Table.Cell />
        <Table.Cell sortBy={hasPagination ? QUOTE_FIELDS.REF_CODE : undefined}>{t('quote:fields.refCode')}</Table.Cell>

        <Table.Cell sortBy={hasPagination ? QUOTE_FIELDS.NAME : undefined}>{t('quote:fields.name')}</Table.Cell>

        <Table.Cell sortBy={hasPagination ? QUOTE_FIELDS.ADDED_AT : undefined}>{t('quote:fields.date')}</Table.Cell>

        {showClientColumn && <Table.Cell>{t('quote:fields.client')}</Table.Cell>}

        <Table.Cell right sortBy={hasPagination ? QUOTE_FIELDS.AMOUNT_EX_VAT : undefined}>
          {t('quote:fields.amountExVAT')}
        </Table.Cell>

        <Table.Cell center sortBy={hasPagination ? QUOTE_FIELDS.STATUS : undefined}>
          {t('quote:fields.status')}
        </Table.Cell>
        {showAccountManagers && <Table.Cell center>{t('accountManager:title')}</Table.Cell>}
      </Table.Header>

      {(quotes || []).map((quote) => (
        <Table.Row key={`quote-${quote.id}`} to={URL_QUOTE(quote)}>
          {displaySelects && (
            <Table.Cell>
              <Table.Select<Data> data={{ id: quote.id, status: quote.status }} />
            </Table.Cell>
          )}
          <Table.Cell center color="yellow.500">
            {typeof quote.id === 'string' && (
              <Tooltip label={t('quote:quotation.columns.createdWithNewModule')} shouldWrapChildren placement="right">
                <Badge colorScheme="blue">
                  <Flex alignItems="center" justifyContent="center">
                    <SimpleNewQuoteIcon />
                  </Flex>
                </Badge>
              </Tooltip>
            )}
          </Table.Cell>
          <Table.Cell variant="ellipsis">{formatDataOrEmpty(quote.refCode)}</Table.Cell>

          <Table.Cell variant="ellipsis">
            {quote.name}
            <TagsBadges tags={quote.tags ?? []} />
          </Table.Cell>

          <Table.Cell>
            <Date>{quote.addedAt}</Date>
          </Table.Cell>

          {showClientColumn && (
            <Table.Cell variant="ellipsis">{formatDataOrEmpty(quote.client?.enterpriseName)}</Table.Cell>
          )}

          <Table.Cell right>
            <PriceAdvanced amount={parseFloat(quote.amountExVAT ?? '0')} />
          </Table.Cell>

          <Table.Cell center>
            <QuoteStatusBadge quote={quote} />
          </Table.Cell>

          {showAccountManagers && (
            <>
              {quote.quoteAccountManagers ? (
                <Table.Cell center>
                  <UserAvatars
                    users={(quote.quoteAccountManagers ?? []).filter((am) => !!am.user).map((am) => am.user)}
                  />
                </Table.Cell>
              ) : (
                <Table.Cell center>
                  <UserAvatars users={(quote.accountManagers ?? []).filter((am) => !!am)} />
                </Table.Cell>
              )}
            </>
          )}
        </Table.Row>
      ))}
    </Table>
  );
};
