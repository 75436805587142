import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Flex, Stack, Text } from '@chakra-ui/react';
import {
  IconAdvanced,
  ImageList,
  SimpleHelpIcon,
  Steps,
  TeamBuildingIcon,
  TextField,
  Title,
  useToast,
} from '@graneet/lib-ui';
import type { IMAGE_LIST_ERROR, ImageListItem } from '@graneet/lib-ui';
import { Form, useStepForm } from 'graneet-form';
import type { IOnboarding } from '@graneet/business-logic';
import { LOGO_SIZE_MIN } from '@graneet/business-logic';

import type {
  EditOnboardingAdditionalInformationForm,
  EditOnboardingWizard,
} from 'features/onboarding/forms/EditOnboardingWizard';
import { Rule } from 'features/form/rules/Rule';
import { useSaveLogoForOnboarding } from 'features/onboarding/services/onboarding.api';

interface EditOnboardingAdditionalInformationStepProps {
  onboarding: IOnboarding;

  logoState: [ImageListItem[], Dispatch<SetStateAction<ImageListItem[]>>];
}

export const EditOnboardingAdditionalInformationStep: FC<EditOnboardingAdditionalInformationStepProps> = ({
  onboarding,
  logoState,
}) => {
  const { t } = useTranslation(['company', 'onboarding']);

  const toast = useToast();

  const { form } = useStepForm<EditOnboardingWizard, 'additionalInformation'>();

  const saveLogoForOnboardingMutation = useSaveLogoForOnboarding();

  /// LOGO
  const [logo, setLogo] = logoState;

  const onLogoAdded = useCallback(
    async (logoFile: File[]) => {
      const response = await saveLogoForOnboardingMutation.mutateAsync(
        {
          id: onboarding.id,
          dto: { logo: logoFile[0] },
        },
        {
          onError: () => {
            // The error will be handled by the imageList component
            throw new Error('Bad request error');
          },
        },
      );

      const newFile = {
        id: response.id,
        url: response.url,
        file: logoFile[0],
      };

      setLogo([newFile]);
      return [newFile];
    },
    [onboarding.id, saveLogoForOnboardingMutation, setLogo],
  );

  const onLogoEdited = useCallback(
    async (_: unknown, logoFile: File) => {
      const response = await saveLogoForOnboardingMutation.mutateAsync(
        { id: onboarding.id, dto: { logo: logoFile } },
        {
          onError: () => {
            // The error will be handled by the imageList component
            throw new Error('Bad request error');
          },
        },
      );

      const newFile = {
        id: response.id,
        url: response.url,
        file: logoFile,
      };

      setLogo([newFile]);
      return newFile;
    },
    [onboarding.id, saveLogoForOnboardingMutation, setLogo],
  );

  const onLogoEditSuccess = useCallback(() => {
    toast.success(t('company:logo.success'));
  }, [toast, t]);

  const onLogoError = useCallback(
    (err: IMAGE_LIST_ERROR) => {
      toast.error(t(`company:logo.errors.${err}`, { minSize: LOGO_SIZE_MIN }));
    },
    [t, toast],
  );

  return (
    <Form form={form} style={{ width: '100%', height: '100%' }}>
      <Stack gap={6} boxSize="100%" justifyContent="center" alignItems="center">
        <Flex alignItems="baseline" gap={3}>
          <Title fontFamily="Alliance">{t('onboarding:steps.additionalInformation.title')}</Title>
          <IconAdvanced icon={<SimpleHelpIcon m={0} />} tooltip={t('onboarding:steps.additionalInformation.tooltip')} />
        </Flex>

        <Box w="40rem">
          <ImageList
            thumbnailSize="large"
            maximumNumImages={1}
            labels={{
              edit: t('company:logo.actions.edit'),
              add: t('company:logo.actions.add'),
              drop: t('company:logo.actions.drop'),
            }}
            minDimension={LOGO_SIZE_MIN}
            images={logo}
            onImageEdited={onLogoEdited}
            onImageAdded={onLogoAdded}
            onSuccess={onLogoEditSuccess}
            onError={onLogoError}
          />
        </Box>

        <Box w="40rem" mb={-3}>
          <Flex alignItems="baseline" gap={3}>
            <Text fontSize="lg">{t('onboarding:steps.additionalInformation.subTitle')}</Text>
            <IconAdvanced
              icon={<SimpleHelpIcon m={0} />}
              tooltip={t('onboarding:steps.additionalInformation.subTitleTooltip')}
            />
          </Flex>
        </Box>

        <Flex w="40rem">
          <Box w="50%" mr={2}>
            <TextField<EditOnboardingAdditionalInformationForm>
              name="paymentMethodName"
              label={t('onboarding:fields.paymentMethodName')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>

          <Box w="50%" ml={2}>
            <TextField<EditOnboardingAdditionalInformationForm>
              name="bic"
              label={t('onboarding:fields.bic')}
              textAlign="left"
            >
              <Rule.IsRequired />
            </TextField>
          </Box>
        </Flex>

        <Flex w="40rem">
          <TextField<EditOnboardingAdditionalInformationForm>
            name="iban"
            label={t('onboarding:fields.iban')}
            textAlign="left"
          >
            <Rule.IsRequired />
          </TextField>
        </Flex>

        <Steps.NextStepButton isDisabled={logo.length === 0} w="40rem">
          {t('onboarding:steps.additionalInformation.cta')}
        </Steps.NextStepButton>
      </Stack>

      <Steps.Placeholder placement="bottom">
        <Center>
          <TeamBuildingIcon boxSize="15rem" />
        </Center>
      </Steps.Placeholder>
    </Form>
  );
};
