import type { FC } from 'react';
import { useState, useCallback, useRef } from 'react';
import type { DrawerApi } from '@graneet/lib-ui';
import { Button, DrawersStack, ValuesProvider } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import type { AgGridReact } from '@ag-grid-community/react';
import type { Quote, QuoteBasicItemObject } from '@org/quotation-lib';

import { AgGridQuoteBasicItemsSupply } from 'features/quotation/ag-grid-quote-components-supply/components/AgGridQuoteBasicItemsSupply';

interface InternalQuoteBasicItemSelectDrawerProps {
  drawer: DrawerApi<string>;
  quote?: Quote;
  title: string;
  description: string;
  onSubmit: (basicItems: QuoteBasicItemObject[]) => void;
}

const InternalQuoteBasicItemSelectDrawer: FC<InternalQuoteBasicItemSelectDrawerProps> = ({
  drawer,
  quote,
  title,
  description,
  onSubmit,
}) => {
  const { t } = useTranslation(['orders']);
  const gridRef = useRef<AgGridReact<QuoteBasicItemObject>>(null);

  const [nbElementsSelected, setNbElementsSelected] = useState(0);
  const onSelect = useCallback((selection: unknown[]) => {
    setNbElementsSelected(selection.length);
  }, []);

  const handleOnSubmit = useCallback(() => {
    if (gridRef.current) {
      onSubmit(gridRef.current.api.getSelectedRows());
      drawer.onClose();
    }
  }, [drawer, onSubmit]);

  return (
    <DrawersStack.Drawer
      title={title}
      drawer={drawer}
      footer={
        <Flex justify="flex-end" w="100%">
          <Button onClick={handleOnSubmit} isDisabled={nbElementsSelected === 0}>
            {t('orders:actions.importJobs')}
          </Button>
        </Flex>
      }
      size="2xl"
    >
      <Text mb={4}>{description}</Text>
      {quote && <AgGridQuoteBasicItemsSupply quote={quote} gridRef={gridRef} onSelect={onSelect} />}
    </DrawersStack.Drawer>
  );
};

export const QuoteBasicItemSelectDrawer: FC<InternalQuoteBasicItemSelectDrawerProps> = (props) => (
  <ValuesProvider>
    <InternalQuoteBasicItemSelectDrawer {...props} />
  </ValuesProvider>
);
