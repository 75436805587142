import type {
  QuoteOptionalItemPopulateDTO,
  QuoteOptionalItemTransformDTO,
  QuoteOptionalItemUpsertDTO,
  QuoteOptionalItemsApi,
} from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteOptionalItemsProxyApi {
  private static quoteOptionalItemsProxyApi: QuoteOptionalItemsProxyApi;

  private readonly quoteOptionalItems: QuoteOptionalItemsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteOptionalItems = externalApi.getQuoteOptionalItemsApi();
  }

  public static getQuoteOptionalItemsProxyApi(externalApi: ExternalApi): QuoteOptionalItemsProxyApi {
    if (!this.quoteOptionalItemsProxyApi) {
      QuoteOptionalItemsProxyApi.quoteOptionalItemsProxyApi = new QuoteOptionalItemsProxyApi(externalApi);
    }
    return QuoteOptionalItemsProxyApi.quoteOptionalItemsProxyApi;
  }

  public async createQuoteOptionalItem(quoteOptionalItemCreateDTO: QuoteOptionalItemUpsertDTO) {
    return proxyHelper(async () => this.quoteOptionalItems.createQuoteOptionalItem(quoteOptionalItemCreateDTO));
  }

  public async updateQuoteOptionalItem(
    quoteOptionalItemId: string,
    quoteOptionalItemUpdateDTO: QuoteOptionalItemUpsertDTO,
  ) {
    return proxyHelper(async () =>
      this.quoteOptionalItems.updateQuoteOptionalItem(quoteOptionalItemId, quoteOptionalItemUpdateDTO),
    );
  }

  public async transformQuoteOptionalItem(
    quoteOptionalItemId: string,
    quoteOptionalItemTransformDTO: QuoteOptionalItemTransformDTO,
  ) {
    return proxyHelper(async () =>
      this.quoteOptionalItems.transformQuoteOptionalItem(quoteOptionalItemId, quoteOptionalItemTransformDTO),
    );
  }

  public async populateQuoteOptionalItem(quoteOptionalItemUpsertManyDTO: QuoteOptionalItemPopulateDTO) {
    return proxyHelper(async () => this.quoteOptionalItems.populateQuoteOptionalItem(quoteOptionalItemUpsertManyDTO));
  }
}
