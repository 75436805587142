import axios from 'axios';
import type {
  IUser,
  IUserCreateDTO,
  IUserResponseDTO,
  IUserWrapperResponseDTO,
  IUserWithoutToken,
  IUserEmailAvailabilityResponseDTO,
  IUserWithRelations,
  UserPaginatedResponse,
  IUserSettings,
  IUserSettingsDTO,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { sortUsers } from './user.util';

import { apiNew } from 'features/api/services/apiNew.service';

const USER_PATH = '/users';

export const userKeyFactory = createQueryKeys('users', {
  getCurrent: () => ({
    queryKey: [USER_PATH, 'current'],
    queryFn: () => apiNew.get<never, IUserWrapperResponseDTO>(`${USER_PATH}/current`),
  }),
  get: (id?: number) => ({
    queryKey: [USER_PATH, id],
    queryFn: () => (id ? apiNew.get<never, IUserWithRelations>(`${USER_PATH}/${id}`) : Promise.resolve(null)),
  }),
  getDisplayedUsers: () => ({
    queryKey: [USER_PATH, 'displayed'],
    queryFn: () => apiNew.get<never, IUser[]>(`${USER_PATH}/displayed`),
  }),
  getSettings: () => ({
    queryKey: [USER_PATH, 'settings'],
    queryFn: () => apiNew.get<never, IUserSettings>(`${USER_PATH}/settings`),
  }),
  getUsersPaginated: (queryParams: URLSearchParams) => ({
    queryKey: [USER_PATH, queryParams],
    queryFn: () => apiNew.get<URLSearchParams, UserPaginatedResponse>(`${USER_PATH}`, queryParams),
  }),
});

export function useCurrentUser() {
  return useSuspenseQuery(userKeyFactory.getCurrent());
}

export function useUsersPaginated(queryParams: URLSearchParams) {
  return useSuspenseQuery(userKeyFactory.getUsersPaginated(queryParams));
}

export function useUserSettings() {
  return useSuspenseQuery(userKeyFactory.getSettings());
}

export function useCurrentUserQuery(options: { enabled: boolean }) {
  return useQuery({
    ...userKeyFactory.getCurrent(),
    ...options,
  });
}

export function useDisplayedUsers() {
  return useSuspenseQuery({ ...userKeyFactory.getDisplayedUsers(), select: sortUsers });
}

export function useUser(id?: number) {
  return useSuspenseQuery(userKeyFactory.get(id));
}

export function useSwitchCompany() {
  const toast = useToast();
  const { t } = useTranslation(['global']);
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (companyId: number | string) =>
      apiNew.patch<never, IUserResponseDTO>(`${USER_PATH}/switch-company/${companyId}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (data) => {
      history.replace('/');
      await queryClient.resetQueries();
      toast.success(
        t('global:auth.switchCompany', {
          companyName: data.company?.name,
        }),
      );
    },
  });
}

export const getUserCompanyEventSource = (auth0ID: string) =>
  new EventSource(`${axios.defaults.baseURL}${USER_PATH}/${auth0ID}/subscribe/company`);

export function useEditUser() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'user']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUserCreateDTO }) =>
      apiNew.patch<IUserCreateDTO, IUserWithoutToken>(`${USER_PATH}/${params.id}`, params.dto),
    onSuccess: (_, params) => {
      toast.success(t('user:toast.update.success'));
      queryClient.invalidateQueries(userKeyFactory.getDisplayedUsers());
      queryClient.invalidateQueries(userKeyFactory.get(params.id));
      queryClient.invalidateQueries(userKeyFactory.getCurrent());
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('user:toast.update.error'));
    },
  });
}

export function useDeleteUser() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'user']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, void>(`${USER_PATH}/${id}`),
    onSuccess: (_, id) => {
      toast.success(t('user:toast.delete.success'));
      queryClient.invalidateQueries(userKeyFactory.getDisplayedUsers());
      queryClient.invalidateQueries(userKeyFactory.get(id));
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('user:toast.delete.error'));
    },
  });
}

export function useIsEmailAvailable() {
  return useMutation({
    mutationFn: (params: { email: string; userId?: number }) =>
      apiNew.get<{ email: string; userId?: number }, IUserEmailAvailabilityResponseDTO>(
        `${USER_PATH}/email/availability`,
        {
          email: params.email,
          userId: params.userId,
        },
      ),
  });
}

export function useEditUserSettings(invalidateQueries: boolean = true) {
  const toast = useToast();
  const { t } = useTranslation(['global', 'user']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IUserSettingsDTO) => apiNew.patch<IUserSettingsDTO, IUserSettings>(`${USER_PATH}/settings`, dto),
    onSuccess: () => {
      if (invalidateQueries) {
        queryClient.invalidateQueries(userKeyFactory.getSettings());
      }
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}
