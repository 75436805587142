import { ContentTypeGuard, DiffMargin, MARGIN_TYPE, QuoteMargin, QuoteQuantityFormula } from '@org/quotation-lib';
import Big from 'big.js';
import isEqual from 'lodash-es/isEqual';

import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';
import type { QuoteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';

export const createQuoteComponentUpdatedCallback = (message: QuoteWsMessage) => {
  const quote = quoteGetFromStore();

  if (!quote) {
    return;
  }

  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();
  const hasClientRequest = quoteClientRequestsFromStore.find(
    (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
  );

  if (hasClientRequest) {
    quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
    return;
  }

  const node = quote.getTree().getNodeOrThrow(message.data.id);
  const quoteComponent = node.getContent();

  if (!ContentTypeGuard.isQuoteComponent(quoteComponent) || message.data.content.type !== 'QuoteComponent') {
    return;
  }

  if (
    node.getParentOrThrow().getId() !== message.data.parentId ||
    (node.getPrevSibling()?.getId() || null) !== message.data.prevSiblingId ||
    (node.getNextSibling()?.getId() || null) !== message.data.nextSiblingId
  ) {
    if (!message.data.parentId) {
      throw new Error('Cannot move root node');
    }
    node.move(message.data.parentId, message.data.prevSiblingId, message.data.nextSiblingId);
  }

  if (
    message.data.content.quantityFormula &&
    !isEqual(quoteComponent.getQuantityFormula()?.export(), message.data.content.quantityFormula)
  ) {
    const quantityFormula = new QuoteQuantityFormula(message.data.content.quantityFormula);
    quoteComponent.updateQuantityFormula(quantityFormula, {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });
  }

  if (
    message.data.content.unitFlatCostAmount &&
    quoteComponent.getUnitFlatCostAmount()?.toString() !== message.data.content.unitFlatCostAmount
  ) {
    quoteComponent.updateUnitFlatCostAmount(new Big(message.data.content.unitFlatCostAmount), {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });
  }

  if (
    message.data.content.unitAmount &&
    quoteComponent.getUnitAmount()?.toString() !== message.data.content.unitAmount
  ) {
    quoteComponent.updateUnitAmount(new Big(message.data.content.unitAmount), {
      spreadUp: true,
      impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
    });
  }

  if (
    message.data.content.margin.totalMargin &&
    quoteComponent.getMargin().getTotalMargin().toString() !== message.data.content.margin.totalMargin
  ) {
    const newMargin = new QuoteMargin(message.data.content.margin);
    const oldMargin = new QuoteMargin(quoteComponent.getMargin().export());
    const diffMargin = DiffMargin.execute(newMargin, oldMargin);
    if (diffMargin) {
      quoteComponent.updateTotalMargin(new Big(message.data.content.margin.totalMargin), {
        spreadUp: true,
        impactMargin: diffMargin,
      });
    }
  }

  quoteComponent.updateProperties(message.data.content);
  quoteSetToStore(quote);
};
