import type { ICredit } from '../credit/credit.type';
import type { IInvoice } from '../invoice/invoice.type';
import type { IProject } from '../project/project.type';
import type { IQuote } from '../quote/quote.type';
import { QUOTE_STATUS } from '../quote/quote.type';
import type { DeepPartial } from '../common/entity.type';

import type { IClient } from './client.type';
import type { IClientResponseDTO } from './dtos/client-response.dto';

type EntitiesPreventingDeletion = {
  entity:
    | Pick<IProject, 'id' | 'name'>
    | Pick<ICredit, 'id' | 'name'>
    | Pick<IInvoice, 'id' | 'name'>
    | Pick<IQuote, 'id' | 'status' | 'name'>;
  type: 'project' | 'quote' | 'invoice' | 'credit';
}[];

export const getEntitiesPreventingDeletion = (
  client: DeepPartial<IClient> & {
    projects?: Pick<IProject, 'id' | 'name'>[];
    credits?: Pick<ICredit, 'id' | 'name'>[];
    invoices?: Pick<IInvoice, 'id' | 'name'>[];
    quotes?: Pick<IQuote, 'id' | 'status' | 'name'>[];
  },
): EntitiesPreventingDeletion => {
  const linkedEntitiesPreventingDeletion: EntitiesPreventingDeletion = [];

  (client.projects ?? []).forEach((p: Pick<IProject, 'id' | 'name'>) =>
    linkedEntitiesPreventingDeletion.push({ type: 'project', entity: p }),
  );
  (client.quotes ?? []).forEach((q: Pick<IQuote, 'id' | 'status' | 'name'>) => {
    if ([QUOTE_STATUS.ACCEPTED, QUOTE_STATUS.COMPLETED].includes(q.status)) {
      linkedEntitiesPreventingDeletion.push({
        type: 'quote',
        entity: q,
      });
    }
  });
  (client.credits ?? []).forEach((c: Pick<ICredit, 'id' | 'name'>) =>
    linkedEntitiesPreventingDeletion.push({ type: 'credit', entity: c }),
  );
  (client.invoices ?? []).forEach((i: Pick<IInvoice, 'id' | 'name'>) =>
    linkedEntitiesPreventingDeletion.push({ type: 'invoice', entity: i }),
  );

  return linkedEntitiesPreventingDeletion;
};

/**
 * We only take the first secondary contact to send it to PDF
 */
export const getFirstClientFromArray = <T extends IClient | Partial<IClient | IClientResponseDTO>>(
  clients: T[],
): T[] => (clients[0] ? [clients[0]] : []);
