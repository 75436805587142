import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

import * as lotsApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Edit the default VAT rate of a lot
 */
export const useEditLotDefaultVatRate = () => {
  const { t } = useTranslation(['global']);
  const toast = useToast();
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (lotId: number, defaultVatRate: number) => {
      const dto = { defaultVatRate };
      const [err, result] = await lotsApi.updateLotDefaultVatRate(lotId, dto);
      if (err) {
        toast.error(t('global:errors.error'));
        return true;
      }
      updateDataLocally(result);
      return false;
    },
    [t, toast, updateDataLocally],
  );
};
