import type { FC } from 'react';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { Flex, useToast } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

import type { TimeTableFormValues } from '../types/time-table.form';
import { getIdFromTimeTableApproveFieldName } from '../types/time-table.form';
import { Button } from '../../../Button/Button';
import { timeTableTranslations } from '../../configureDefaultLabels';

interface TimeTableApproveToastProps {
  onApprove: (ids: number[]) => void | Promise<void>;
}

const id = 'time-table-approve-toast';

export const TimeTableApproveToast: FC<TimeTableApproveToastProps> = ({ onApprove }) => {
  const toast = useToast({ duration: null });

  const formContext = useFormContext<TimeTableFormValues>();
  const formValues = useOnChangeValues(formContext, undefined);

  const numberOfSelectedValues = useMemo(() => Object.values(formValues).filter((value) => value).length, [formValues]);

  const [isLoading, setIsLoading] = useState(false);
  const handleApprove = useCallback(async () => {
    const values = formContext.getFormValues();
    const ids = (Object.keys(values) as Array<keyof TimeTableFormValues>)
      .filter((key) => values[key])
      .map(getIdFromTimeTableApproveFieldName);

    setIsLoading(true);
    await onApprove(ids);
    setIsLoading(false);
    formContext.resetForm();
  }, [formContext, onApprove]);

  const render = useCallback(
    () => (
      <Flex
        mb={5}
        p={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        rounded="md"
        boxShadow="0px 4px 6px -2px rgba(0, 0, 0, 0.13), 0px 12px 16px -4px rgba(0, 0, 0, 0.04)"
        bg="white"
      >
        <Flex direction="row" gap={3} alignItems="center" mr={3}>
          {timeTableTranslations.selectedTimeSlots(numberOfSelectedValues)}
          <CloseIcon boxSize={3} onClick={formContext.resetForm} cursor="pointer" />
        </Flex>

        <Button onClick={handleApprove} isLoading={isLoading}>
          {timeTableTranslations.approve}
        </Button>
      </Flex>
    ),
    [formContext.resetForm, handleApprove, isLoading, numberOfSelectedValues],
  );

  useEffect(
    () => () => {
      toast.close(id);
    },
    // On component unmounts, close the toast
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!numberOfSelectedValues) {
    if (toast.isActive(id)) {
      toast.close(id);
    }

    return null;
  }

  if (toast.isActive(id)) {
    toast.update(id, {
      render,
    });
    return null;
  }

  toast({
    id,
    render,
  });

  return null;
};
