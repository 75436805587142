import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDeletionModal, useCurrency } from '@graneet/lib-ui';
import type { IValidationStepWithRelations } from '@graneet/business-logic';
import { useQueryClient } from '@tanstack/react-query';

import {
  useDeleteValidationStepSupplierInvoice,
  validationStepsKeys,
} from 'features/validation-step/services/validation-step-supplier-invoice.api';
import { ListingToPayStatus } from 'features/validation-step/components/ListingToPayStatus';

interface WorkflowValidationSupplierInvoiceDeleteModalProps {
  step: IValidationStepWithRelations;

  isOpen: boolean;

  onClose: () => void;
}

export const ValidationStepSupplierInvoiceDeleteModal: FC<WorkflowValidationSupplierInvoiceDeleteModalProps> = ({
  step,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation(['global', 'validationStep']);
  const deleteValidationStepSupplierInvoice = useDeleteValidationStepSupplierInvoice();
  const { formatAsAmount } = useCurrency();
  const queryClient = useQueryClient();

  const handleDeletion = useCallback(async () => {
    const updatedValidationStep = await deleteValidationStepSupplierInvoice.mutateAsync(step.id);
    if (updatedValidationStep.supplierInvoicesToPay.length === 0) {
      queryClient.invalidateQueries(validationStepsKeys.validationStepSupplierInvoice);
      onClose();
    }
  }, [deleteValidationStepSupplierInvoice, onClose, queryClient, step.id]);

  const supplierInvoicesToPay = useMemo(
    () => deleteValidationStepSupplierInvoice.data?.supplierInvoicesToPay || [],
    [deleteValidationStepSupplierInvoice.data?.supplierInvoicesToPay],
  );

  const modalTitle = useMemo(() => {
    if (supplierInvoicesToPay?.length) {
      return t('validationStep:supplierInvoice.listingToPayStatus');
    }
    return t('validationStep:supplierInvoice.deleteModal.deleteTitle', {
      amount: formatAsAmount(step.validationCondition.value),
    });
  }, [formatAsAmount, step.validationCondition.value, supplierInvoicesToPay, t]);

  const handleOnClose = useCallback(() => {
    queryClient.invalidateQueries(validationStepsKeys.validationStepSupplierInvoice);
    onClose();
  }, [onClose, queryClient]);

  const modalAdditionalContent = useMemo(
    () => <ListingToPayStatus list={supplierInvoicesToPay} />,
    [supplierInvoicesToPay],
  );

  return (
    <ConfirmDeletionModal
      title={modalTitle}
      action={
        !deleteValidationStepSupplierInvoice.isPending && supplierInvoicesToPay.length === 0
          ? t('global:words.c.delete')
          : undefined
      }
      isOpen={isOpen}
      onClose={handleOnClose}
      onDeleted={handleDeletion}
      additionalContent={modalAdditionalContent}
    />
  );
};
