import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Price, NumberField, formatNumber } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, HiddenField, useFormStatus } from 'graneet-form';
import {
  divideFloating,
  multiplyFloating,
  isComponentTypeUndefined,
  MARGIN_COMPUTED_VALUE,
} from '@graneet/business-logic';

import {
  isCurrentFieldEdit,
  BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD,
  BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD,
} from '../services/margins.utils';
import { MARGIN_COMPONENT_INPUT_STYLE } from '../constants/style.constant';

import { TooltipHiddenCostComponentsType } from './TooltipHiddenCostComponentsType';

import { ComponentTypeBadge } from 'features/component-type/components/ComponentTypeBadge';
import { useQuoteSellSheetComponentTypePreview } from 'features/quote-sellsheet/hooks/useQuoteSellSheetComponentTypePreview';
import { MARGIN_ENDPOINT_TYPE } from 'features/quote/constants/quotes.constant';
import { Loading } from 'features/common/components/Loading';
import { Rule } from 'features/form/rules/Rule';
import { MARGIN_FIELD_NAME } from 'features/margin/services/margin.constants';
import { usePreviewLotComponentTypeMarginSellSheet } from 'features/quote-lot/hooks/usePreviewLotComponentTypeMarginSellSheet';
import { useCompanyMargins } from 'features/company/services/company.api';

const TABLE_STYLE = {
  boxShadow: 'none',
  backgroundColor: 'transparent',
};

const TABLE_CELL_STYLES = {
  fontWeight: 500,
  fontFamily: 'body',
};

export const MarginEditionByComponentsTypesTable = ({
  lotId,
  componentTypes,
  currentEditField,
  setCurrentEditField,
  setComponentsMarginFormValues,
  setLastEditedFieldValue,
  setIsLoadingSpinner,
  setIsDisabledButton,
  setEndpointTypeToCall,
  isMultiEditionAllowed,
  isAdditionalInfoDisplayed,
  isEditionDisabled,
  setIsEditionDisabled,
  setDisplayPreviewButton,
  isMarginComponentDataLoading,
  handleBackToInitialState,
  isUpdateLot,
  bgColor,
}) => {
  const { t } = useTranslation(['margin']);
  const form = useFormContext();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues, setFormValues } = form;
  const [hasFocus, setHasFocus] = useState(false);
  const [currentComponentFocused, setCurrentComponentFocused] = useState(false);

  const previewQuoteComponentTypeMarginSellSheet = useQuoteSellSheetComponentTypePreview();
  const previewLotComponentTypeMarginSellSheet = usePreviewLotComponentTypeMarginSellSheet();

  const companyMargins = useCompanyMargins();

  const handleComponentMarginEdition = useCallback(
    (value, componentTypeId, marginFieldName) => {
      setDisplayPreviewButton(true);
      const {
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.PROFIT_MARGIN)]: profitMargin,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]: overheadCosts,
      } = getFormValues();

      if (!value) return;

      switch (marginFieldName) {
        case MARGIN_FIELD_NAME.TOTAL_MARGIN:
          setFormValues({
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.PROFIT_MARGIN)]: divideFloating(
              value,
              overheadCosts,
            ),
          });
          setFormValues({ [BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(componentTypeId)]: MARGIN_COMPUTED_VALUE.PROFIT });
          break;
        case MARGIN_FIELD_NAME.PROFIT_MARGIN:
          setFormValues({
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.TOTAL_MARGIN)]: multiplyFloating(
              value,
              overheadCosts,
            ),
          });
          setFormValues({ [BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(componentTypeId)]: MARGIN_COMPUTED_VALUE.TOTAL });
          break;
        case MARGIN_FIELD_NAME.OVERHEAD_COSTS:
          setFormValues({
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.TOTAL_MARGIN)]: multiplyFloating(
              profitMargin,
              value,
            ),
          });
          setFormValues({ [BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(componentTypeId)]: MARGIN_COMPUTED_VALUE.TOTAL });
          break;

        default:
          break;
      }
      if (!isMultiEditionAllowed) {
        setEndpointTypeToCall(MARGIN_ENDPOINT_TYPE.MARGIN_COMPONENT_TYPE);
        setCurrentEditField(BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, marginFieldName));
      }
    },
    [
      isMultiEditionAllowed,
      setCurrentEditField,
      setEndpointTypeToCall,
      setDisplayPreviewButton,
      setFormValues,
      getFormValues,
    ],
  );

  const handleOnFieldFocus = useCallback(
    (componentTypeId, marginFieldName) => {
      const { [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, marginFieldName)]: valueEdited } =
        getFormValues();

      if (
        setLastEditedFieldValue &&
        currentEditField !== BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, marginFieldName)
      ) {
        setLastEditedFieldValue(valueEdited);
      }

      setCurrentComponentFocused(componentTypeId);
      setHasFocus(true);
    },
    [currentEditField, setLastEditedFieldValue, getFormValues],
  );

  const handleStyleField = useCallback(
    (componentTypeId, isFocused, isEditable) => {
      if (isEditionDisabled && componentTypeId !== currentComponentFocused) {
        return MARGIN_COMPONENT_INPUT_STYLE.DISABLED;
      }
      if (isFocused && componentTypeId === currentComponentFocused) {
        return MARGIN_COMPONENT_INPUT_STYLE.FOCUS;
      }
      if (isEditable && componentTypeId === currentComponentFocused) {
        return MARGIN_COMPONENT_INPUT_STYLE.EDITION;
      }
      return MARGIN_COMPONENT_INPUT_STYLE.BLURRED;
    },
    [currentComponentFocused, isEditionDisabled],
  );

  const handleOnFieldBlur = useCallback(async () => {
    if (currentEditField) {
      setDisplayPreviewButton(false);
      setIsEditionDisabled(true);
      setIsLoadingSpinner(true);
      const { 1: componentTypeId } = currentEditField.split('-');

      const {
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.PROFIT_MARGIN)]: profitMargin,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]: overheadCosts,
        [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentTypeId, MARGIN_FIELD_NAME.TOTAL_MARGIN)]: totalMargin,
        [BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(componentTypeId)]: valueComputed,
      } = getFormValues();

      if (!overheadCosts || !profitMargin || !totalMargin) {
        // Timeout to prevent double click after preview
        await handleBackToInitialState();
        setTimeout(() => {
          setIsLoadingSpinner(false);
        }, 500);
        return;
      }

      const dto = {
        overheadCosts,
        profitMargin,
        totalMargin,
        computed: valueComputed,
      };

      if (isUpdateLot) {
        const previewResults = await previewLotComponentTypeMarginSellSheet(
          lotId,
          componentTypeId,
          dto,
          companyMargins.data,
        );

        previewResults.map((previewResult) => {
          const {
            componentType: { id: previewComponentTypeId },
            disbursementExVAT,
            quantity,
            overheadCosts: overheadCostsComponentType,
            profitMargin: profitMarginComponentType,
            totalMargin: totalMarginComponentType,
          } = previewResult;

          const currentComponent = componentTypes.find((componentType) => componentType.id === previewComponentTypeId);

          if (currentComponent) {
            currentComponent.quantity = disbursementExVAT;
            currentComponent.disbursementExVAT = quantity;
          }

          return setComponentsMarginFormValues({
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(previewComponentTypeId, MARGIN_FIELD_NAME.OVERHEAD_COSTS)]:
              overheadCostsComponentType,
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(previewComponentTypeId, MARGIN_FIELD_NAME.PROFIT_MARGIN)]:
              profitMarginComponentType,
            [BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(previewComponentTypeId, MARGIN_FIELD_NAME.TOTAL_MARGIN)]:
              totalMarginComponentType,
          });
        });
      } else {
        await previewQuoteComponentTypeMarginSellSheet(lotId, componentTypeId, dto);
      }

      // Timeout to prevent double click after preview
      setTimeout(() => {
        if (isFormValid) {
          setIsDisabledButton(false);
        }
        setIsLoadingSpinner(false);
      }, 500);
    }
    setHasFocus(false);
  }, [
    companyMargins.data,
    componentTypes,
    currentEditField,
    getFormValues,
    handleBackToInitialState,
    isFormValid,
    isUpdateLot,
    lotId,
    previewLotComponentTypeMarginSellSheet,
    previewQuoteComponentTypeMarginSellSheet,
    setComponentsMarginFormValues,
    setDisplayPreviewButton,
    setIsDisabledButton,
    setIsEditionDisabled,
    setIsLoadingSpinner,
  ]);

  const templateColumns = useMemo(
    () => [
      'minmax(5rem, 1fr)',
      ...(isAdditionalInfoDisplayed ? ['minmax(5rem, 1fr)', 'minmax(5rem, 1fr)'] : []),
      'minmax(5rem, 1fr)',
      'minmax(5rem, 1fr)',
      'minmax(5rem, 1fr)',
    ],
    [isAdditionalInfoDisplayed],
  );

  if (isMarginComponentDataLoading) {
    return <Loading />;
  }

  return (
    <Table templateColumns={templateColumns} sx={TABLE_STYLE} noResultLabel={t('margin:componentTypes.emptyTable')}>
      <Table.Header bgColor={isEditionDisabled ? 'transparent' : bgColor}>
        <Table.Cell sx={TABLE_CELL_STYLES} center>
          {t('margin:componentTypes.fields.type')}
        </Table.Cell>
        {isAdditionalInfoDisplayed && (
          <>
            <Table.Cell sx={TABLE_CELL_STYLES} center>
              {t('margin:componentTypes.fields.quantity')}
            </Table.Cell>
            <Table.Cell sx={TABLE_CELL_STYLES} center>
              {t('margin:componentTypes.fields.disbursementExVAT')}
            </Table.Cell>
          </>
        )}
        <Table.Cell sx={TABLE_CELL_STYLES} center>
          {t('margin:componentTypes.fields.overheadCosts')}
        </Table.Cell>
        <Table.Cell sx={TABLE_CELL_STYLES} center>
          {t('margin:componentTypes.fields.profitMargin')}
        </Table.Cell>
        <Table.Cell sx={TABLE_CELL_STYLES} center>
          {t('margin:componentTypes.fields.totalMargin')}
        </Table.Cell>
      </Table.Header>
      {(componentTypes || []).map((componentType, index) => (
        <Table.Row
          key={componentType.componentType.id}
          bgColor={
            isEditionDisabled && !currentEditField?.includes(`component-${componentType.componentType.id}-`)
              ? 'transparent'
              : bgColor
          }
          border={componentTypes.length - 1 === index && 0}
        >
          <HiddenField name={BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD(componentType.componentType.id)} />
          <Table.Cell py={0}>
            <ComponentTypeBadge type={componentType.componentType} />
          </Table.Cell>
          {isAdditionalInfoDisplayed && (
            <Table.Cell py={0} center>
              {componentType.componentType.unit && componentType.quantity
                ? `${formatNumber(componentType.quantity)} ${componentType.componentType.unit}`
                : ''}
            </Table.Cell>
          )}
          {isAdditionalInfoDisplayed && (
            <Table.Cell py={0} right gap={2} alignItems="center">
              <Price amount={componentType.disbursementExVAT} />
              {componentType.hasHiddenCost && (
                <TooltipHiddenCostComponentsType
                  hiddenCost={componentType.hiddenCost}
                  hiddenCostQuantity={componentType.hiddenCostQuantity}
                  quantity={componentType.quantity}
                  unit={componentType.componentType.unit}
                  disbursementExVAT={componentType.disbursementExVAT}
                />
              )}
            </Table.Cell>
          )}
          <Table.Cell py={0} center>
            {isComponentTypeUndefined(componentType) ? (
              '-'
            ) : (
              <NumberField
                min={0}
                scale={3}
                name={BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(
                  componentType.componentType.id,
                  MARGIN_FIELD_NAME.OVERHEAD_COSTS,
                )}
                value={componentType.overheadCosts}
                isDisabled={
                  isEditionDisabled &&
                  !isCurrentFieldEdit(
                    currentEditField,
                    componentType.componentType.id,
                    MARGIN_FIELD_NAME.OVERHEAD_COSTS,
                  )
                }
                onFocus={() => handleOnFieldFocus(componentType.componentType.id, MARGIN_FIELD_NAME.OVERHEAD_COSTS)}
                onBlur={handleOnFieldBlur}
                onChange={(value) =>
                  handleComponentMarginEdition(value, componentType.componentType.id, MARGIN_FIELD_NAME.OVERHEAD_COSTS)
                }
                inputProps={handleStyleField(
                  componentType.componentType.id,
                  hasFocus,
                  isCurrentFieldEdit(
                    currentEditField,
                    componentType.componentType.id,
                    MARGIN_FIELD_NAME.OVERHEAD_COSTS,
                  ),
                )}
              >
                <Rule.IsRequired />
                <Rule.IsNotZero />
              </NumberField>
            )}
          </Table.Cell>
          <Table.Cell py={0} center>
            {isComponentTypeUndefined(componentType) ? (
              '-'
            ) : (
              <NumberField
                min={0}
                scale={3}
                name={BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(
                  componentType.componentType.id,
                  MARGIN_FIELD_NAME.PROFIT_MARGIN,
                )}
                value={componentType.profitMargin}
                isDisabled={
                  isEditionDisabled &&
                  !isCurrentFieldEdit(currentEditField, componentType.componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)
                }
                onFocus={() => handleOnFieldFocus(componentType.componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)}
                onBlur={handleOnFieldBlur}
                onChange={(value) =>
                  handleComponentMarginEdition(value, componentType.componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN)
                }
                inputProps={handleStyleField(
                  componentType.componentType.id,
                  hasFocus,
                  isCurrentFieldEdit(currentEditField, componentType.componentType.id, MARGIN_FIELD_NAME.PROFIT_MARGIN),
                )}
              >
                <Rule.IsRequired />
                <Rule.IsNotZero />
              </NumberField>
            )}
          </Table.Cell>
          <Table.Cell py={0} center>
            {isComponentTypeUndefined(componentType) ? (
              '-'
            ) : (
              <NumberField
                min={0}
                scale={3}
                name={BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(
                  componentType.componentType.id,
                  MARGIN_FIELD_NAME.TOTAL_MARGIN,
                )}
                value={componentType.totalMargin}
                isDisabled={
                  isEditionDisabled &&
                  !isCurrentFieldEdit(currentEditField, componentType.componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)
                }
                onFocus={() => handleOnFieldFocus(componentType.componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)}
                onBlur={handleOnFieldBlur}
                onChange={(value) =>
                  handleComponentMarginEdition(value, componentType.componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN)
                }
                inputProps={handleStyleField(
                  componentType.componentType.id,
                  hasFocus,
                  isCurrentFieldEdit(currentEditField, componentType.componentType.id, MARGIN_FIELD_NAME.TOTAL_MARGIN),
                )}
              >
                <Rule.IsRequired />
                <Rule.IsNotZero />
              </NumberField>
            )}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table>
  );
};

MarginEditionByComponentsTypesTable.propTypes = {
  componentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      componentType: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.string,
        color: PropTypes.string,
      }),
      margin: PropTypes.shape({
        overheadCosts: PropTypes.number,
        profitMargin: PropTypes.number,
        totalMargin: PropTypes.number,
      }),
      quantity: PropTypes.number,
      disbursementExVAT: PropTypes.number,
      allowedFieldToEdit: PropTypes.string,
    }),
  ),
  isMultiEditionAllowed: PropTypes.bool,
  isAdditionalInfoDisplayed: PropTypes.bool,
  isEditionDisabled: PropTypes.bool,
  setIsEditionDisabled: PropTypes.func,
  setDisplayPreviewButton: PropTypes.func,
  currentEditField: PropTypes.string,
  setCurrentEditField: PropTypes.func,
  setEndpointTypeToCall: PropTypes.func,
  setLastEditedFieldValue: PropTypes.func,
  setIsLoadingSpinner: PropTypes.func,
  setIsDisabledButton: PropTypes.func,
  isMarginComponentDataLoading: PropTypes.bool,
  lotId: PropTypes.number,
  isUpdateLot: PropTypes.bool,
  setComponentsMarginFormValues: PropTypes.func,
  handleBackToInitialState: PropTypes.func,
  bgColor: PropTypes.string,
};

MarginEditionByComponentsTypesTable.defaultProps = {
  componentTypes: [],
  isMultiEditionAllowed: false,
  isAdditionalInfoDisplayed: false,
  isEditionDisabled: false,
  setIsEditionDisabled: () => {},
  setDisplayPreviewButton: () => {},
  currentEditField: undefined,
  setCurrentEditField: () => {},
  setEndpointTypeToCall: () => {},
  setLastEditedFieldValue: () => {},
  setIsLoadingSpinner: () => {},
  setIsDisabledButton: () => {},
  handleBackToInitialState: () => Promise.resolve(),
  isMarginComponentDataLoading: false,
  lotId: undefined,
  isUpdateLot: false,
  setComponentsMarginFormValues: () => {},
  bgColor: 'white',
};
