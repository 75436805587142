import { useCallback } from 'react';
import { IconButton } from '@chakra-ui/react';

import { useNode } from '../hooks/useNode';
import { useTreeContext } from '../contexts';
import type { Identifiable } from '../types/Identifiable';
import { SimpleChevronBottomIcon } from '../../Icons';

export interface ToggleButtonProps<Node extends Identifiable> {
  nodeId: Node['id'];

  color?: string;
}

export const ToggleButton = <Node extends Identifiable>({ nodeId, color = 'gray.300' }: ToggleButtonProps<Node>) => {
  const {
    state: { isExpanded },
  } = useNode<Node, any>(nodeId);
  const { switchNodeIsExpanded } = useTreeContext<Node, any>();

  const onNodeComponentSwitched = useCallback(() => {
    switchNodeIsExpanded(nodeId);
  }, [nodeId, switchNodeIsExpanded]);

  return (
    <IconButton
      aria-label={isExpanded ? 'Collapse' : 'Expand'}
      variant="clear"
      icon={
        isExpanded ? (
          <SimpleChevronBottomIcon transform="rotate(-180deg)" boxSize={5} />
        ) : (
          <SimpleChevronBottomIcon boxSize={5} />
        )
      }
      height="auto"
      onClick={onNodeComponentSwitched}
      color={color}
      _hover={{ color: 'greenBrand.light' }}
    />
  );
};
