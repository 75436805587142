import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import type { BatiprixJob, IComponentType } from '@graneet/business-logic';

import { apiNew } from 'features/api/services/apiNew.service';

export const useBatiprixApi = () => {
  const queryClient = useQueryClient();

  const useGetItemsFromSession = () => {
    const { t } = useTranslation(['global', 'quote']);
    const toast = useToast();

    return useMutation({
      mutationFn: (query: { sessionId: string; token: string }) =>
        apiNew.get<
          unknown,
          {
            batiprixJobs: BatiprixJob[];
            batiprixComponentTypes: {
              material: IComponentType;
              workforce: IComponentType;
            };
          }
        >(`/third-parties/batiprix/jobs-from-session/`, query),
      onSuccess: () => {},
      onError: () => {
        toast.error(t('global:errors.error'));
      },
    });
  };

  return {
    useGetItemsFromSession,
    queryClient,
  };
};
