import type { FC, ReactNode } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Text, useRadio, Flex, Box } from '@chakra-ui/react';

import { Button } from './Button';
import { Label } from './Label';
import { useRadioGroupContext } from './RadioGroupContext';

export interface OptionProps extends Omit<FlexProps, 'children'> {
  value: string | number;
  label?: string;
  description?: string;
  rightIcon?: ReactNode;
  children?: ReactNode | Function;
  isChecked?: boolean;
}

export const Option: FC<OptionProps> = ({
  value,
  label = '',
  rightIcon = null,
  children = null,
  description,
  ...props
}) => {
  const { getRadioProps, isDisabled } = useRadioGroupContext();
  const radioProps = getRadioProps({ value });

  const { getInputProps, getCheckboxProps } = useRadio({
    isDisabled,
    ...props,
    ...radioProps,
  });

  // input props let us handle the changes of the radio state
  const inputProps = getInputProps();

  // checkbox props let us use _checked styles in children
  const checkboxProps = getCheckboxProps();

  const button = <Button {...checkboxProps} />;
  const labelComponent = label ? <Label {...checkboxProps}>{label}</Label> : null;

  return (
    <Flex as="label" direction="row" cursor={isDisabled ? 'not-allowed' : 'pointer'} align="center" {...props}>
      <Box position="relative">
        <input {...inputProps} />
      </Box>
      {typeof children === 'function' ? (
        children(button, labelComponent)
      ) : (
        <Box>
          <Flex alignItems="center">
            {button}
            {labelComponent}
            {rightIcon}
          </Flex>
          {description && (
            <Text ml={8} fontSize="sm">
              {description}
            </Text>
          )}
        </Box>
      )}
    </Flex>
  );
};
