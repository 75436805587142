import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteSalesDiscountDelete() {
  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const { quoteSalesDiscountsProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback((): boolean => {
    try {
      quote.deleteSalesDiscount();

      const clientRequestId = uuid();
      const timestamp = new Date().toString();
      quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
      quoteSalesDiscountsProxyApi
        .deleteQuoteSalesDiscount({
          quoteId: quote.getId(),
          timestamp,
          clientRequestId,
        })
        .then(([err]) => {
          if (err) {
            quoteError();
          }
        })
        .catch((err) => quoteError(err.message));

      quoteSetToStore(quote);
      return true;
    } catch (e: any) {
      return quoteError(e.message);
    }
  }, [quote, quoteError, quoteSalesDiscountsProxyApi, quoteSetToStore]);
}
