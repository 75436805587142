import { useCallback } from 'react';
import { Box, CircularProgress, Image } from '@chakra-ui/react';
import type { FC, MouseEvent } from 'react';

import { SimpleCloseIcon } from '../../Icons/v2/SimpleCloseIcon';

import type { ImageItem, ImageSize } from './types';
import { useGetImageBoxSize } from './useGetImageBoxSize';

interface SingleImageProps {
  image: ImageItem;
  isEditable: boolean;
  onImageClick: (image: ImageItem) => void;
  onImageDelete: (image: ImageItem) => void;
  size: ImageSize;
}

export const SingleImage: FC<SingleImageProps> = ({ image, isEditable, onImageClick, onImageDelete, size }) => {
  const handleImageClick = useCallback(() => {
    onImageClick(image);
  }, [onImageClick, image]);

  const handleDeleteClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onImageDelete(image);
    },
    [onImageDelete, image],
  );

  const boxSize = useGetImageBoxSize(size);

  return (
    <Box
      width={boxSize}
      height={boxSize}
      minWidth={boxSize}
      minHeight={boxSize}
      borderRadius="0.5rem"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={handleImageClick}
      role="group"
      cursor="pointer"
    >
      <Box width="100%" height="100%" overflow="hidden" borderRadius="0.5rem">
        <Image
          src={image.url}
          alt={image.id}
          width="100%"
          height="100%"
          loading="lazy"
          fallback={
            <Box
              position="absolute"
              borderRadius="0.5rem"
              top="0"
              left="0"
              right="0"
              bottom="0"
              background="rgba(0, 0, 0, 0.40)"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress isIndeterminate color="white" size="1.5rem" />
            </Box>
          }
        />
      </Box>
      <Box
        position="absolute"
        borderRadius="0.5rem"
        top="0"
        left="0"
        right="0"
        bottom="0"
        background="rgba(0, 0, 0, 0.40)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        opacity={image.isLoading ? 1 : 0}
        _groupHover={{
          opacity: 1,
        }}
        transition="opacity 0.3s"
      >
        {image.isLoading && <CircularProgress isIndeterminate color="white" size="1.5rem" />}
      </Box>
      {isEditable && (
        <Box
          position="absolute"
          top="-0.25rem"
          right="-0.25rem"
          background="#6C737F"
          border="0.094rem solid #FFF"
          boxSize="1rem"
          display="flex"
          borderRadius="100%"
          justifyContent="center"
          alignItems="center"
          onClick={handleDeleteClick}
          opacity={0}
          _groupHover={{
            opacity: 1,
          }}
          transition="all 0.3s"
          _hover={{
            cursor: 'pointer',
            background: '#384250',
          }}
        >
          <SimpleCloseIcon stroke="#fff" boxSize={2} strokeWidth={3} />
        </Box>
      )}
    </Box>
  );
};
