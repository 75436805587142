import {
  TimeSlotApi,
  QuoteExportsApi,
  QuoteFilesApi,
  QuoteAddressApi,
  QuoteBasicItemsApi,
  QuoteComponentsApi,
  QuoteCustomDiscountsApi,
  QuoteHiddenCostItemsApi,
  QuoteNodeMovesApi,
  QuoteOptionalItemsApi,
  QuoteSalesDiscountsApi,
  QuoteSubItemsApi,
  QuotesApi,
  QuoteLotsApi,
  QuoteNodeDeletesApi,
  QuoteNodeBulkActionsApi,
  QuoteContactsApi,
  QuoteLibraryApi,
  ContractsApi,
  ProjectsApi,
  PusherApi,
  QuoteImportsApi,
  ChannelsMessagingApi,
  QuotesAutoCompleteApi,
  QuoteNodesApi,
  QuoteSettingApi,
} from '@org/graneet-bff-client';
import axios from 'axios';

import { getEnvValue } from './env';

export class ExternalApi {
  private static externalApi: ExternalApi;

  private readonly baseBffUrl = getEnvValue('REACT_APP_BFF_URL', true) as string;

  private token: string | undefined;

  // API Modules
  private quotesApi: QuotesApi;

  private contractApi: ContractsApi;

  private projectApi: ProjectsApi;

  private timeSlotApi: TimeSlotApi;

  private quoteContactsProxyApi: QuoteContactsApi;

  private quoteCustomDiscountsApi: QuoteCustomDiscountsApi;

  private quoteSalesDiscountsApi: QuoteSalesDiscountsApi;

  private quoteLotsApi: QuoteLotsApi;

  private quoteBasicItemsApi: QuoteBasicItemsApi;

  private quoteHiddenCostItemsApi: QuoteHiddenCostItemsApi;

  private quoteComponentsApi: QuoteComponentsApi;

  private quoteOptionalItemsApi: QuoteOptionalItemsApi;

  private quoteSubItemsApi: QuoteSubItemsApi;

  private quoteNodeMovesApi: QuoteNodeMovesApi;

  private quoteNodeDeletesApi: QuoteNodeDeletesApi;

  private quoteAddressApi: QuoteAddressApi;

  private quoteNodeBulkActionsApi: QuoteNodeBulkActionsApi;

  private quoteFilesApi: QuoteFilesApi;

  private quoteLibraryApi: QuoteLibraryApi;

  private quoteExportsApi: QuoteExportsApi;

  private pusherApi: PusherApi;

  private quoteImportsApi: QuoteImportsApi;

  private channelsMessagingApi: ChannelsMessagingApi;

  private quoteAutoCompleteApi: QuotesAutoCompleteApi;

  private quoteNodesApi: QuoteNodesApi;

  private quoteSettingApi: QuoteSettingApi;

  private constructor(token?: string) {
    this.token = token;
    const axiosInstance = axios.create({
      baseURL: this.baseBffUrl,
    });

    // SET the API Modules there
    this.quotesApi = new QuotesApi(this.getApiConfig(), undefined, axiosInstance);

    this.projectApi = new ProjectsApi(this.getApiConfig(), undefined, axiosInstance);

    this.timeSlotApi = new TimeSlotApi(this.getApiConfig(), undefined, axiosInstance);

    this.contractApi = new ContractsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteContactsProxyApi = new QuoteContactsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteCustomDiscountsApi = new QuoteCustomDiscountsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteSalesDiscountsApi = new QuoteSalesDiscountsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteLotsApi = new QuoteLotsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteBasicItemsApi = new QuoteBasicItemsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteHiddenCostItemsApi = new QuoteHiddenCostItemsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteComponentsApi = new QuoteComponentsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteOptionalItemsApi = new QuoteOptionalItemsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteSubItemsApi = new QuoteSubItemsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteNodeMovesApi = new QuoteNodeMovesApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteNodeDeletesApi = new QuoteNodeDeletesApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteAddressApi = new QuoteAddressApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteNodeBulkActionsApi = new QuoteNodeBulkActionsApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteFilesApi = new QuoteFilesApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteLibraryApi = new QuoteLibraryApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteExportsApi = new QuoteExportsApi(this.getApiConfig(), undefined, axiosInstance);

    this.pusherApi = new PusherApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteImportsApi = new QuoteImportsApi(this.getApiConfig(), undefined, axiosInstance);

    this.channelsMessagingApi = new ChannelsMessagingApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteAutoCompleteApi = new QuotesAutoCompleteApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteNodesApi = new QuoteNodesApi(this.getApiConfig(), undefined, axiosInstance);

    this.quoteSettingApi = new QuoteSettingApi(this.getApiConfig(), undefined, axiosInstance);
  }

  public static getExternalApi(token?: string): ExternalApi {
    if (!this.externalApi) {
      ExternalApi.externalApi = new ExternalApi(token);
    }
    if (token) {
      ExternalApi.externalApi.setToken(token);
    }
    return ExternalApi.externalApi;
  }

  private getApiConfig() {
    return {
      accessToken: `Bearer ${this.token}`,
      isJsonMime(mime: string): boolean {
        return !!mime;
      },
    };
  }

  private setToken(token: string | undefined) {
    this.token = token;
  }

  // GET the API Modules there
  public getQuotesApi(): QuotesApi {
    return this.quotesApi;
  }

  public getContractApi(): ContractsApi {
    return this.contractApi;
  }

  public getProjectApi(): ProjectsApi {
    return this.projectApi;
  }

  public getTimeSlotApi(): TimeSlotApi {
    return this.timeSlotApi;
  }

  public getQuoteCustomDiscountsApi(): QuoteCustomDiscountsApi {
    return this.quoteCustomDiscountsApi;
  }

  public getQuoteSalesDiscountsApi(): QuoteSalesDiscountsApi {
    return this.quoteSalesDiscountsApi;
  }

  public getQuoteContactsApi(): QuoteContactsApi {
    return this.quoteContactsProxyApi;
  }

  public getQuoteLotsApi(): QuoteLotsApi {
    return this.quoteLotsApi;
  }

  public getQuoteBasicItemsApi(): QuoteBasicItemsApi {
    return this.quoteBasicItemsApi;
  }

  public getQuoteHiddenCostItemsApi(): QuoteHiddenCostItemsApi {
    return this.quoteHiddenCostItemsApi;
  }

  public getQuoteComponentsApi(): QuoteComponentsApi {
    return this.quoteComponentsApi;
  }

  public getQuoteOptionalItemsApi(): QuoteOptionalItemsApi {
    return this.quoteOptionalItemsApi;
  }

  public getQuoteSubItemsApi(): QuoteSubItemsApi {
    return this.quoteSubItemsApi;
  }

  public getQuoteNodeMovesApi(): QuoteNodeMovesApi {
    return this.quoteNodeMovesApi;
  }

  public getQuoteNodeDeletesApi(): QuoteNodeDeletesApi {
    return this.quoteNodeDeletesApi;
  }

  public getQuoteAddressApi(): QuoteAddressApi {
    return this.quoteAddressApi;
  }

  public getQuoteNodeBulkActionsApi(): QuoteNodeBulkActionsApi {
    return this.quoteNodeBulkActionsApi;
  }

  public getQuoteFilesApi(): QuoteFilesApi {
    return this.quoteFilesApi;
  }

  public getQuoteLibraryApi(): QuoteLibraryApi {
    return this.quoteLibraryApi;
  }

  public getQuoteExportsApi(): QuoteExportsApi {
    return this.quoteExportsApi;
  }

  public getPusherApi(): PusherApi {
    return this.pusherApi;
  }

  public getQuoteImportsApi(): QuoteImportsApi {
    return this.quoteImportsApi;
  }

  public getMessagingApi(): ChannelsMessagingApi {
    return this.channelsMessagingApi;
  }

  public getAutoCompleteApi(): QuotesAutoCompleteApi {
    return this.quoteAutoCompleteApi;
  }

  public getQuoteNodesApi(): QuoteNodesApi {
    return this.quoteNodesApi;
  }

  public getQuoteSettingApi(): QuoteSettingApi {
    return this.quoteSettingApi;
  }
}
