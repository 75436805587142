import type { FC } from 'react';
import { memo, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { generateGroupData, generateIdentifyData } from '@graneet/business-logic';

import { useSegmentAnalytics } from './SegmentProvider';

import { useAppContext } from 'features/app/contexts/AppContext';

export const SegmentAnalytics: FC = memo(() => {
  const { currentUser: user } = useAppContext();
  const segmentAnalytics = useSegmentAnalytics();

  const location = useLocation();
  const { company } = user || {};

  const segmentIdentifyData = useMemo(
    () => (company && user ? generateIdentifyData(company, user) : null),
    [company, user],
  );
  const segmentGroupData = useMemo(() => (company ? generateGroupData(company) : null), [company]);

  // 1. User tracking
  useEffect(() => {
    if (!segmentAnalytics?.analytics) {
      return;
    }

    // No need to emit tracking calls is user is not displayed (eg: admin users)
    if (user && !user.isDisplayed) {
      return;
    }

    if (segmentIdentifyData) {
      segmentAnalytics.analytics.identify(segmentIdentifyData.userId, segmentIdentifyData.traits);
    } else {
      segmentAnalytics.analytics.identify(null);
    }
  }, [segmentAnalytics, segmentIdentifyData, user]);

  // 2. Company tracking
  useEffect(() => {
    if (!segmentAnalytics) {
      return;
    }

    // No need to emit tracking calls is user is not displayed (eg: admin users)
    if (user && !user.isDisplayed) {
      return;
    }

    if (segmentGroupData) {
      segmentAnalytics.analytics?.group(segmentGroupData.groupId, segmentGroupData.traits);
    } else {
      segmentAnalytics.analytics?.group(null);
    }
  }, [segmentAnalytics, segmentGroupData, user]);

  // 3. Page tracking
  useEffect(() => {
    if (!segmentAnalytics) {
      return;
    }

    // No need to emit tracking calls is user is not displayed (eg: admin users)
    if (user && !user.isDisplayed) {
      return;
    }

    segmentAnalytics.analytics?.page(location.pathname);
  }, [segmentAnalytics, location.pathname, user]);

  return null;
});
