import { CheckboxField, Section, Card } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { FEATURE_FLAGS, PAGINATION_PARAMS } from '@graneet/business-logic';
import type { IUserPaginated } from '@graneet/business-logic';
import { Form, useForm } from 'graneet-form';

import type { FormValuesUserToRemind } from './UserToRemindTable';
import { buildUserFieldName, UserToRemindTable, isUserFieldName, getUserIdFromFieldName } from './UserToRemindTable';

import { useUpdateCompanyBillReminders } from 'features/company/services/company.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useUsersPaginated } from 'features/user/services/user.api';
import { createURLSearchParams } from 'features/api/services/api.util';
import { useAppContext } from 'features/app/contexts/AppContext';

interface FormValuesBill extends FormValuesUserToRemind {
  isLateBillRemindersEnabled: boolean;
  isToSentBillRemindersEnabled: boolean;
}

export const MailingBillRemindersSection: FC = () => {
  const hasBillEmailRemindFF = useFeatureFlag(FEATURE_FLAGS.BILL_EMAIL_REMIND);
  const { t } = useTranslation(['mailing']);
  const { currentUser } = useAppContext();
  const { data } = useUsersPaginated(
    createURLSearchParams({
      [PAGINATION_PARAMS.FULL]: true,
    }),
  );

  const updateCompanyBillRemindersMutation = useUpdateCompanyBillReminders();

  const defaultValues: Partial<FormValuesBill> = {
    isLateBillRemindersEnabled: currentUser?.company?.isLateBillRemindersEnabled || false,
    isToSentBillRemindersEnabled: currentUser?.company?.isToSentBillRemindersEnabled || false,
    frequencyWeekday: currentUser?.company?.frequencyWeekday?.toString() || '0',
  };
  data.data.forEach((user: IUserPaginated) => {
    if (user.sendBillReminder) {
      defaultValues[buildUserFieldName(user)] = true;
    }
  });

  const form = useForm<FormValuesBill>({
    defaultValues,
    onUpdateAfterBlur: async () => {
      const { frequencyWeekday, isLateBillRemindersEnabled, isToSentBillRemindersEnabled, ...userIdsValues } =
        form.getFormValues();

      const userIds = Object.keys(userIdsValues)
        .flatMap((fieldName) => (isUserFieldName(fieldName) && form.getFormValues()[fieldName] ? [fieldName] : []))
        .map((fieldName) => getUserIdFromFieldName(fieldName));

      await updateCompanyBillRemindersMutation.mutateAsync({
        userIds,
        isLateBillRemindersEnabled: isLateBillRemindersEnabled || false,
        isToSentBillRemindersEnabled: isToSentBillRemindersEnabled || false,
        frequencyWeekday: parseInt(frequencyWeekday || '0', 10),
      });
    },
  });

  return (
    <Form form={form}>
      <Section title={t('mailing:remindersCard.title')}>
        <Card>
          <Box color="gray.800" fontWeight={600}>
            <CheckboxField<FormValuesBill>
              name="isLateBillRemindersEnabled"
              label={t('mailing:remindersCard.isLateBillRemindersEnabledCheckboxLabel')}
            />
          </Box>
          <Text fontSize="sm" color="gray.600">
            {t('mailing:remindersCard.isLateBillRemindersEnabledCheckboxDescription')}
          </Text>

          {hasBillEmailRemindFF && (
            <>
              <Box color="gray.800" mt={2} fontWeight={600}>
                <CheckboxField<FormValuesBill>
                  name="isToSentBillRemindersEnabled"
                  label={t('mailing:remindersCard.isToSentBillRemindersEnabledCheckboxLabel')}
                />
              </Box>
              <Text fontSize="sm" color="gray.600">
                {t('mailing:remindersCard.isToSentBillRemindersEnabledCheckboxDescription')}
              </Text>
            </>
          )}

          <UserToRemindTable users={data.data} />
        </Card>
      </Section>
    </Form>
  );
};
