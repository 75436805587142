import { ActionMenu, SimpleChevronBottomIcon, Tooltip } from '@graneet/lib-ui';
import { useDisclosure } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import type { EmailTemplateType, IEmailTemplate } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { useCreateEmailTemplate } from '../services/email-template.api';
import { useEmailTemplateQuota } from '../hooks/useEmailTemplateQuota';

import type { EmailTemplateEditModalForm } from './EmailTemplateEditModal';
import { EmailTemplateEditModal } from './EmailTemplateEditModal';

interface EmailTemplateCreateButtonProps {
  emailTemplates: IEmailTemplate[];
}

export const EmailTemplateCreateButton: FC<EmailTemplateCreateButtonProps> = ({ emailTemplates }) => {
  const { t } = useTranslation(['mailing', 'global']);

  const modal = useDisclosure();
  const emailTemplateTypeRef = useRef<EmailTemplateType>('quote');

  const emailTemplateQuota = useEmailTemplateQuota(emailTemplates);

  const createEmailTemplateMutation = useCreateEmailTemplate({ type: 'creation' });

  const onClick = useCallback(
    (type: EmailTemplateType) => () => {
      emailTemplateTypeRef.current = type;
      modal.onOpen();
    },
    [modal],
  );

  const onEmailTemplateCreation = useCallback(
    async (
      formValues: EmailTemplateEditModalForm,
      type: EmailTemplateType,
      html: { subject: string; template: string },
    ) => {
      await createEmailTemplateMutation.mutateAsync({
        ...formValues,
        type,
        templateHTML: html.template,
        subjectHTML: html.subject,
      });
      modal.onClose();
    },
    [createEmailTemplateMutation, modal],
  );

  return (
    <Tooltip
      label={emailTemplateQuota.hasReachQuota ? t('mailing:tooltips.quotaReached') : undefined}
      shouldWrapChildren
    >
      <ActionMenu
        buttonProps={{
          variant: 'secondary',
          leftIcon: null,
          rightIcon: <SimpleChevronBottomIcon stroke="white" boxSize={3} />,
          isDisabled: emailTemplateQuota.hasReachQuota,
          children: t('mailing:createTemplate'),
        }}
        isOpen={emailTemplateQuota.hasReachQuota ? false : undefined}
      >
        <ActionMenu.Action label={t('mailing:emailTemplateType.quote')} onClick={onClick('quote')} />
        <ActionMenu.Action label={t('mailing:emailTemplateType.statement')} onClick={onClick('statement')} />
        <ActionMenu.Action label={t('mailing:emailTemplateType.order')} onClick={onClick('order')} />
      </ActionMenu>

      <EmailTemplateEditModal
        type={emailTemplateTypeRef.current}
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        onSubmit={onEmailTemplateCreation}
      />
    </Tooltip>
  );
};
