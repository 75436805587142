import { useCallback } from 'react';
import type { Quote } from '@org/quotation-lib';
import { MARGIN_TYPE } from '@org/quotation-lib';
import Big from 'big.js';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { mapQuoteLotToUpdateAmount } from 'features/quotation/quote-common/mappers/quoteLotMapper';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { updateQuoteAmountIsUnreachable } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteLotUpdateAmount() {
  const { quoteLotsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, newAmount: string): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
          const quoteNode = quote.getTree().getNodeOrThrow(nodeId);
          this.oldValue = quoteLot.getAmount();
          quoteLot.updateAmount(new Big(newAmount), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });

          if (quoteLot.getAmount()?.eq(Big(newAmount))) {
            updateQuoteAmountIsUnreachable(false);
          } else {
            updateQuoteAmountIsUnreachable(true);
          }

          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteLotsProxyApi
            .updateQuoteLot(
              quoteLot.getId(),
              mapQuoteLotToUpdateAmount(quoteNode, quote.getId(), clientRequestId, timestamp),
            )
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
          const quoteNode = quote.getTree().getNodeOrThrow(nodeId);
          quoteLot.updateAmount(this.oldValue, {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });

          if (quoteLot.getAmount()?.eq(this.oldValue)) {
            updateQuoteAmountIsUnreachable(false);
          } else {
            updateQuoteAmountIsUnreachable(true);
          }

          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteLotsProxyApi
            .updateQuoteLot(
              quoteLot.getId(),
              mapQuoteLotToUpdateAmount(quoteNode, quote.getId(), clientRequestId, timestamp),
            )
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteLotsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
