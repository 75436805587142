import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

const VALIDATE_IS_NOT_ZERO = (value: unknown) => value !== '0' && value !== 0;

interface IsNotZeroProps {
  message?: string;
}

export const IsNotZero: FC<IsNotZeroProps> = ({ message }) => {
  const { t } = useTranslation(['global']);

  return <Rule message={message || t('global:form.errors.notZero')} validationFn={VALIDATE_IS_NOT_ZERO} />;
};
