import type { FC } from 'react';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Text } from '@chakra-ui/react';
import type { IOrder, IOrderWithRelations } from '@graneet/business-logic';
import { Form, useForm } from 'graneet-form';

import { SingleSelectFieldPaginated } from 'features/common/components/SingleSelectFieldPaginated';
import { OrderComponentTypeBadge } from 'features/order/components/OrderComponentTypeBadge';
import { useOrders } from 'features/order/services/order.api';

interface FormValues {
  order: IOrder;
}

const Dash: FC = () => (
  <Text margin="0 1rem 0 1rem" color="gray.500">
    -
  </Text>
);

const OrderLine: FC<{ order: IOrderWithRelations }> = ({ order }) => (
  <Stack direction="row" spacing={2} alignItems="baseline">
    <Box minW="7rem" w="7rem">
      <OrderComponentTypeBadge order={order} />
    </Box>

    <Text fontFamily="heading" color="gray.800">
      {order.name}
    </Text>

    {order.orderNumber && (
      <>
        <Dash />

        <Text fontSize="sm" color="gray.500">
          {order.orderNumber}
        </Text>
      </>
    )}

    {order.project?.name && (
      <>
        <Dash />

        <Text fontSize="sm" color="gray.500">
          {order.project?.name}
        </Text>
      </>
    )}
  </Stack>
);

interface OrderPickerProps {
  addedOrders: IOrder[];
  onOrderAdded: (newValue: IOrder) => void;
}

export const OrderPicker: FC<OrderPickerProps> = ({ addedOrders, onOrderAdded }) => {
  const { t } = useTranslation(['supplierInvoices', 'orders', 'global']);

  const orders = useOrders({ storageStrategy: 'state' });

  const mapFunction = useCallback(
    (order: IOrderWithRelations) => ({
      label: <OrderLine order={order} />,
      value: order,
    }),
    [],
  );

  const nestedForm = useForm<FormValues>();

  const [trigger, setTrigger] = useState(0);

  return (
    <Form form={nestedForm}>
      <SingleSelectFieldPaginated<IOrderWithRelations, FormValues>
        key={trigger}
        name="order"
        pagination={orders}
        mapFunction={mapFunction}
        placeholder={t('global:words.c.research')}
        filterFunction={(order) => !addedOrders.find((o) => o.id === order.id)}
        onChange={(prop) => {
          if (prop?.value) {
            onOrderAdded(prop.value);
            nestedForm.resetForm();
            setTrigger((c) => c + 1);
          }
        }}
      />
    </Form>
  );
};
