import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
  IconAdvanced,
  SimpleHelpIcon,
  StepperModal,
  TextAreaField,
  TextField,
  useCurrency,
  useToast,
} from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import { Form, useFormStatus, useOnChangeValues, useStepForm, useWizardContext } from 'graneet-form';
import { Box, VStack } from '@chakra-ui/react';
import type { IQuoteImportDTO } from '@graneet/business-logic';
import { FEATURE_FLAGS, LEDGER_TYPES } from '@graneet/business-logic';
import { useHistory } from 'react-router-dom';
import type { QuoteImportDTO } from '@org/graneet-bff-client';

import { createQuote, createQuoteFromImport, importQuote } from '../../../services/quote.api';

import type { QuoteImportWizard } from './CreateQuoteFromImportModal';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { Rule } from 'features/form/rules/Rule';
import { useHasQuoteLedger } from 'features/quote-ledger/hooks/useHasQuoteLedger';
import { LedgerField } from 'features/ledger/components/LedgerField';
import type { ImportSpreadsheetSubmit } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImport } from 'features/import/components/SpreadsheetImport';
import type { ImportQuoteField } from 'features/quote/hooks/useImportQuoteFields';
import { useImportQuoteJobFields, useImportQuoteJobRowHook } from 'features/quote/hooks/useImportQuoteFields';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useAppContext } from 'features/app/contexts/AppContext';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export interface QuoteInformationStepForm {
  name: string;

  refCode?: string;

  needsQuoteLedger?: boolean;

  comment?: string;
}

interface QuoteInformationStepProps {
  onClose(): void;

  projectId?: number;

  onImportCompleted(): void;
}

export const QuoteInformationStep: FC<QuoteInformationStepProps> = ({ onClose, projectId, onImportCompleted }) => {
  const toast = useToast();
  const history = useHistory();
  const { mapAmountToNumber } = useCurrency();
  const { form, initFormValues } = useStepForm<QuoteImportWizard, 'INFORMATION'>();
  const { getValuesOfStep } = useWizardContext<QuoteImportWizard>();
  const { t } = useTranslation(['quote', 'ledger', 'global']);
  const { isValid: isFormValid } = useFormStatus(form);
  const [isLoading, setIsLoading] = useState(false);
  const hasQuoteLedger = useHasQuoteLedger();
  const { needsQuoteLedger } = useOnChangeValues(form, ['needsQuoteLedger']);
  const [isImporterOpen, setIsImporterOpen] = useState(false);
  const importFields = useImportQuoteJobFields();
  const rowHook = useImportQuoteJobRowHook();

  const isQuoteV2Enabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);
  const isQuoteV1Disabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_DISABLE_V1);

  const { currentUser } = useAppContext();
  const { quoteImportsProxyApi, quoteProxyApi } = useQuotationProxyApis();

  const previousValues = getValuesOfStep('FILE');

  // Form has to be empty on first render
  useEffect(() => {
    initFormValues({});
  }, [initFormValues]);

  const getRefCode = useCallback(
    (values: Partial<QuoteInformationStepForm>) => (values.needsQuoteLedger ? undefined : values.refCode),
    [],
  );

  const onImporterSubmit = useCallback<ImportSpreadsheetSubmit<ImportQuoteField>>(
    async (data) => {
      setIsImporterOpen(false);
      setIsLoading(true);

      const values = form.getFormValues();

      // First, build dto
      const tree = data.validData.reduce(
        (acc, piece) => {
          if (piece.type === 'LOT') {
            acc.lots.push({
              code: piece.code,
              description: piece.name,
              note: piece.note,
              lots: [],
              jobs: [],
            });
          } else {
            const job = {
              code: piece.code,
              description: piece.name,
              note: piece.note,
              unit: piece.unit,
              quantity: piece.quantity ? parseFloat(piece.quantity.toString()) : undefined,
              unitDisbursementExVAT: piece.unit_price
                ? mapAmountToNumber(parseFloat(piece.unit_price.toString()))
                : undefined,
            };

            if (acc.lots.length === 0) {
              acc.jobs.push(job);
            } else {
              acc.lots[acc.lots.length - 1].jobs.push(job);
            }
          }

          return acc;
        },
        { jobs: [] as any[], lots: [] as any[] },
      );

      if (!isQuoteV1Disabled && ((isQuoteV2Enabled && previousValues?.version === 'V1') || !isQuoteV2Enabled)) {
        const dto: IQuoteImportDTO = {
          ...tree,
          hasReversalOfLiability: false,
          isManual: true,
        };

        // Secondly, create quote
        const [errorCreating, quote] = await createQuote({
          name: values.name!,
          refCode: getRefCode(values),
        });

        // Then, import data
        const [errorImporting] = await importQuote(quote?.id!, dto);

        // Finally
        if (errorCreating || errorImporting) {
          toast.error(t('global:words.c.error'), t('quote:importModal.toasts.error', { email: SUPPORT_EMAIL }));
        }

        if (!errorCreating) {
          history.push(`/opportunities/quotes/${quote?.id!}`);

          if (!errorImporting) {
            toast.success(t('quote:importModal.toasts.successSimple', { name: values.name }));
          }
        }
      }

      if (isQuoteV1Disabled || (isQuoteV2Enabled && previousValues?.version === 'V2')) {
        const dto: QuoteImportDTO = {
          ...tree,
          hasReversalOfLiability: false,
          isManual: true,
        };
        const [errorCreating, quote] = await quoteProxyApi.createQuote({
          name: values.name || '',
          companyId: currentUser.company.id,
        });
        if (errorCreating) {
          toast.error(t('global:words.c.error'), t('quote:importModal.toasts.error', { email: SUPPORT_EMAIL }));
        }

        const [errorImporting] = await quoteImportsProxyApi.importQuote(quote?.id!, dto);
        if (errorImporting) {
          toast.error(t('global:words.c.error'), t('quote:importModal.toasts.error', { email: SUPPORT_EMAIL }));
        }

        if (!errorCreating) {
          history.push(`/opportunities/quotation/${quote?.id!}`);

          if (!errorImporting) {
            toast.success(t('quote:importModal.toasts.successSimple', { name: values.name }));
          }
        }
      }
    },
    [
      form,
      isQuoteV1Disabled,
      isQuoteV2Enabled,
      previousValues?.version,
      mapAmountToNumber,
      getRefCode,
      toast,
      t,
      history,
      quoteProxyApi,
      currentUser.company.id,
      quoteImportsProxyApi,
    ],
  );

  const onImporterClose = useCallback(() => {
    setIsImporterOpen(false);
  }, [setIsImporterOpen]);

  const onSubmit = useCallback(async () => {
    if (previousValues?.type === 'SIMPLE') {
      setIsImporterOpen(true);
      return;
    }

    setIsLoading(true);
    const values = form.getFormValues();

    if (!isQuoteV1Disabled && ((isQuoteV2Enabled && previousValues?.version === 'V1') || !isQuoteV2Enabled)) {
      const [error, newQuote] = await createQuoteFromImport({
        ...values,
        name: values.name!,
        refCode: getRefCode(values),
        projectId: projectId?.toString(),
        importFile: previousValues?.file,
      });
      if (error) {
        toast.error(t('global:words.c.error'), t('quote:importModal.toasts.error', { email: SUPPORT_EMAIL }));
      } else {
        toast.success(t('quote:importModal.toasts.success', { name: newQuote.name }));
      }
    }

    if (isQuoteV1Disabled || (isQuoteV2Enabled && previousValues?.version === 'V2')) {
      const [error, newQuote] = await quoteImportsProxyApi.createQuoteFromImport(
        values.name ?? '',
        previousValues?.file ? [previousValues?.file] : [],
        getRefCode(values),
        values.needsQuoteLedger,
        values.comment,
        projectId,
      );
      if (error) {
        toast.error(t('global:words.c.error'), t('quote:importModal.toasts.error', { email: SUPPORT_EMAIL }));
      } else {
        toast.success(t('quote:importModal.toasts.success', { name: newQuote }));
      }
    }

    setIsLoading(false);
    onClose();
    onImportCompleted();
  }, [
    previousValues?.type,
    previousValues?.version,
    previousValues?.file,
    form,
    isQuoteV1Disabled,
    isQuoteV2Enabled,
    onClose,
    onImportCompleted,
    getRefCode,
    projectId,
    toast,
    t,
    quoteImportsProxyApi,
  ]);

  const setRefCodeToNextQuoteLedgerValue = useCallback(() => {
    form.setFormValues({
      refCode: t('ledger:ledgerField.autoGenerated'),
      needsQuoteLedger: true,
    });
  }, [form, t]);

  useEffect(() => {
    if (needsQuoteLedger === true) {
      // Toggling quote ledger on
      setRefCodeToNextQuoteLedgerValue();
    }
  }, [needsQuoteLedger, setRefCodeToNextQuoteLedgerValue]);

  return (
    <Form form={form} style={{ width: '100%' }}>
      <VStack spacing={6}>
        <TextField<QuoteInformationStepForm> name="name" label={t('quote:fields.name')} isRequired>
          <Rule.IsRequired />
        </TextField>

        <Box w="100%">
          <LedgerField<QuoteInformationStepForm, 'refCode', 'needsQuoteLedger'>
            data-testid="textField:client.code"
            name="refCode"
            label={t('quote:fields.refCode')}
            isAutoGenerationDisabled={!hasQuoteLedger}
            isLedgerEnabledHiddenFieldName="needsQuoteLedger"
            ledgerType={LEDGER_TYPES.QUOTE}
            isReadOnly={false}
          />
        </Box>

        {previousValues?.type === 'COMPLEX' && (
          <TextAreaField<QuoteInformationStepForm>
            name="comment"
            label={t('quote:importModal.fields.comment')}
            rightIcon={
              <IconAdvanced
                icon={<SimpleHelpIcon />}
                tooltip={<Trans t={t} i18nKey="quote:importModal.commentHint" />}
              />
            }
          />
        )}
      </VStack>

      <StepperModal.SecondaryButton>{t('global:words.c.previous')}</StepperModal.SecondaryButton>

      <StepperModal.PrimaryButton isDisabled={!isFormValid} isLoading={isLoading} onClick={onSubmit}>
        {t('quote:importModal.actions.import')}
      </StepperModal.PrimaryButton>

      {isImporterOpen && (
        <SpreadsheetImport
          onClose={onImporterClose}
          onSubmit={onImporterSubmit}
          fields={importFields}
          rowHook={rowHook}
        />
      )}
    </Form>
  );
};
