import type { FC, ReactNode } from 'react';
import { useContext } from 'react';
import { Box, Flex, GridItem } from '@chakra-ui/react';

import { DataTableContext } from '../contexts/DataTableContext';

export interface DataTableRowProps {
  label: ReactNode;

  children: ReactNode;
}

const getMinH = (density: 'sm' | 'md') => {
  switch (density) {
    case 'sm':
      return '1.8rem';
    case 'md':
      return '2.3rem';
    default:
      return '2.3rem';
  }
};

export const DataTableRow: FC<DataTableRowProps> = ({ label, children }) => {
  const { density } = useContext(DataTableContext);

  return (
    <>
      <GridItem minH={getMinH(density)}>
        <Flex justifyContent="space-between" alignItems="baseline">
          <Box color="gray.800">{label}</Box>
        </Flex>
      </GridItem>

      {children}
    </>
  );
};
