import type { IRole, IRoleDTO, IRoleUsersDTO } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { apiNew } from 'features/api/services/apiNew.service';

const ROLE_PATH = '/roles';

export const roleKeyFactory = createQueryKeys('roles', {
  get: () => ({
    queryKey: [ROLE_PATH],
    queryFn: () => apiNew.get<never, IRole[]>(ROLE_PATH),
  }),
  getUsersUsingRole: (id?: number) => ({
    queryKey: [ROLE_PATH, id, 'users'],
    queryFn: () => {
      if (!id) {
        return Promise.resolve<IRoleUsersDTO>({ users: [], userInvitations: [] });
      }
      return apiNew.get<never, IRoleUsersDTO>(`${ROLE_PATH}/${id}/users`);
    },
  }),
});

export function useRoles() {
  return useSuspenseQuery(roleKeyFactory.get());
}

export function useUsersUsingRole(id?: number) {
  return useSuspenseQuery(roleKeyFactory.getUsersUsingRole(id));
}

export function useCreateRole() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (roleDTO: IRoleDTO) => apiNew.post<IRoleDTO, IRole>(ROLE_PATH, roleDTO),
    onSuccess: async () => {
      await queryClient.invalidateQueries(roleKeyFactory.get());
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useUpdateRole() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IRoleDTO }) =>
      apiNew.patch<IRoleDTO, IRole>(`${ROLE_PATH}/${params.id}`, params.dto),
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(roleKeyFactory.get());
      await queryClient.invalidateQueries(roleKeyFactory.getUsersUsingRole(response.id));
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}

export function useDeleteRole() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, void>(`${ROLE_PATH}/${id}`),
    onSuccess: async () => {
      await queryClient.invalidateQueries(roleKeyFactory.get());
    },
    onError: () => {
      toast.error(t('global:errors.error'));
    },
  });
}
