import type { BoxProps, IconProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { useMemo } from 'react';

import {
  DocumentCancelledIcon,
  DocumentCompletedIcon,
  DocumentDraftIcon,
  DocumentFinalizedIcon,
  DocumentImportIcon,
  DocumentPartialIcon,
  DocumentProformaIcon,
  DocumentToValidateIcon,
} from '../../Icons';
import { useChakraColors } from '../../../hooks';
import type { DocumentStatusCardIconValue } from '../DocumentStatusCard.util';
import { mapIconToColor } from '../DocumentStatusCard.util';

export interface DocumentStatusCardIconProps extends BoxProps {
  icon: DocumentStatusCardIconValue;
  size: number;
  iconProps?: IconProps;
}

export const DocumentStatusCardIcon: FC<DocumentStatusCardIconProps> = ({ icon, size, iconProps = {}, ...rest }) => {
  const chakraColor = useMemo(() => mapIconToColor(icon), [icon]);

  const { backgroundColor } = useChakraColors({
    backgroundColor: `${chakraColor}.200`,
  });

  const IconComponent = useMemo(() => {
    switch (icon) {
      case 'draft':
        return DocumentDraftIcon;
      case 'proforma':
        return DocumentProformaIcon;
      case 'finalized':
        return DocumentFinalizedIcon;
      case 'completed':
        return DocumentCompletedIcon;
      case 'importing':
        return DocumentImportIcon;
      case 'partial':
        return DocumentPartialIcon;
      case 'cancelled':
        return DocumentCancelledIcon;
      case 'onHold':
        return DocumentToValidateIcon;
      default:
        icon satisfies never;
        throw new Error('unknown icon');
    }
  }, [icon]);

  const backdropSize = useMemo(() => size * 0.4, [size]);

  return (
    <Box
      background={`radial-gradient(${backgroundColor} ${backdropSize}px, transparent ${backdropSize}px)`}
      height="min-content"
      width="min-content"
      {...rest}
    >
      <IconComponent width={size} height={size} {...iconProps} />
    </Box>
  );
};
