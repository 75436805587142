import type { InputProps, TextProps } from '@chakra-ui/react';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import type { ChangeEvent, FocusEventHandler, ReactElement } from 'react';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import type { FieldValue } from 'graneet-form';

import type { FormGroupProps } from '../../FormGroup/FormGroup';
import { FormGroup } from '../../FormGroup/FormGroup';

export interface OutlineInputProps
  extends Omit<FormGroupProps, 'label' | 'isFocused' | 'showError' | 'value' | 'onChange' | 'defaultValue'> {
  label?: string;

  onChange?: (value: FieldValue | null) => void;

  onBlur?: FocusEventHandler<HTMLInputElement>;

  onFocus?: FocusEventHandler<HTMLInputElement>;

  defaultValue?: string;

  value?: string;

  isDisabled?: boolean;

  placeholder?: string;

  shouldDisplayError?: boolean;

  labelProps?: TextProps;

  inputProps?: Omit<InputProps, 'textAlign'>;

  leftIcon?: ReactElement;

  rightIcon?: ReactElement;

  errorMessage?: string;
}

/*
  This Input is more simple than DefaultInput. For now, there is no implementation of:
  - Icon in the input
  - Calendar icon
 */
export const OutlineInput = forwardRef<HTMLInputElement, OutlineInputProps>(
  (
    {
      label,
      onChange = () => {},
      onBlur = () => {},
      onFocus,
      defaultValue,
      value,
      isReadOnly = false,
      isDisabled = false,
      placeholder,
      shouldDisplayError = false,
      textAlign = 'right',
      labelProps,
      inputProps,
      leftIcon,
      rightIcon,
      errorMessage,
      onKeyDown,
    },
    ref,
  ) => {
    const [hasBeenBlurred, setHasBeenBlurred] = useState(false);
    const showError = shouldDisplayError && hasBeenBlurred && !isDisabled;

    const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
      (e) => {
        onBlur(e);
        setHasBeenBlurred(true);
      },
      [onBlur],
    );

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      },
      [onChange],
    );

    const focusBorderColor = useMemo(() => {
      if (isReadOnly || isDisabled) return 'gray.300';

      if (showError) return 'red.500';

      return 'greenBrand.light';
    }, [isDisabled, isReadOnly, showError]);

    return (
      <FormGroup label={label} textAlign={textAlign} {...labelProps} showError={showError} errorMessage={errorMessage}>
        <InputGroup>
          {leftIcon && <InputLeftElement>{leftIcon}</InputLeftElement>}

          <Input
            cursor="inherit"
            textAlign={textAlign}
            defaultValue={defaultValue}
            value={value}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            onFocus={onFocus}
            placeholder={placeholder}
            backgroundColor="white"
            onBlur={handleBlur}
            onChange={handleChange}
            color="gray.700"
            fontWeight={600}
            _disabled={{ backgroundColor: 'gray.100' }}
            focusBorderColor={focusBorderColor}
            borderColor={showError ? 'red.500' : 'gray.300'}
            _hover={{ borderColor: showError ? 'red.500' : undefined }}
            borderWidth="2px"
            borderStyle="solid"
            ref={ref}
            onKeyDown={onKeyDown}
            {...inputProps}
          />

          {rightIcon && <InputRightElement>{rightIcon}</InputRightElement>}
        </InputGroup>
      </FormGroup>
    );
  },
);
