import type {
  IProject,
  IOrder,
  IFile,
  RequiredByKeys,
  SUPPLIER_INVOICE_MODE,
  ISupplierInvoiceItem,
  IAccountingConfigPurchasesAccount,
  IComponentType,
  VAT_TYPE,
} from '@graneet/business-logic';
import type { File } from '@graneet/lib-ui';

import type { AmountsFieldForm } from '../components/SupplierInvoiceAmountsField';

export type ProjectDistributionFieldName = `project_distribution_${number}`;
export const buildProjectDistributionFieldName = (project: IProject): ProjectDistributionFieldName =>
  `project_distribution_${project.id}`;

export type ProjectAmountExVATFieldName = `project_amount_ex_vat_${number}`;
export const buildProjectAmountExVATFieldName = (project: IProject): ProjectAmountExVATFieldName =>
  `project_amount_ex_vat_${project.id}`;
export const isProjectAmountExVATFieldName = (fieldName: string): fieldName is ProjectAmountExVATFieldName =>
  fieldName.includes('project_amount_ex_vat_');

type SupplierInvoiceItemDescription = `item_description__${string}`;
export const buildSupplierInvoiceItemDescriptionFieldName = (
  id: ISupplierInvoiceItem['id'],
): SupplierInvoiceItemDescription => `item_description__${id}`;

type SupplierInvoiceItemCode = `item_code__${string}`;
export const buildSupplierInvoiceItemCodeFieldName = (id: ISupplierInvoiceItem['id']): SupplierInvoiceItemCode =>
  `item_code__${id}`;

type SupplierInvoiceItemUnitAmountExVAT = `item_unitAmountExVAT__${string}`;
export const buildSupplierInvoiceItemUnitAmountExVATFieldName = (
  id: ISupplierInvoiceItem['id'],
): SupplierInvoiceItemUnitAmountExVAT => `item_unitAmountExVAT__${id}`;

type SupplierInvoiceItemQuantity = `item_quantity__${string}`;
export const buildSupplierInvoiceItemQuantityFieldName = (
  id: ISupplierInvoiceItem['id'],
): SupplierInvoiceItemQuantity => `item_quantity__${id}`;

type SupplierInvoiceItemUnit = `item_unit__${string}`;
export const buildSupplierInvoiceItemUnitFieldName = (id: ISupplierInvoiceItem['id']): SupplierInvoiceItemUnit =>
  `item_unit__${id}`;

type SupplierInvoiceItemAmountExVAT = `item_amountExVAT__${string}`;
export const buildSupplierInvoiceItemAmountExVATFieldName = (
  id: ISupplierInvoiceItem['id'],
): SupplierInvoiceItemAmountExVAT => `item_amountExVAT__${id}`;

type SupplierInvoiceItemVatRate = `item_vatRate__${string}`;
export const buildSupplierInvoiceItemVatRateFieldName = (id: ISupplierInvoiceItem['id']): SupplierInvoiceItemVatRate =>
  `item_vatRate__${id}`;

type SupplierInvoiceItemProject = `item_project__${string}`;
export const buildSupplierInvoiceItemProjectFieldName = (id: ISupplierInvoiceItem['id']): SupplierInvoiceItemProject =>
  `item_project__${id}`;

type SupplierInvoiceItemType = `item_type__${string}`;
export const buildSupplierInvoiceItemTypeFieldName = (id: ISupplierInvoiceItem['id']): SupplierInvoiceItemType =>
  `item_type__${id}`;

type SupplierInvoiceItemPurchasesAccount = `item_purchasesAccount__${string}`;
export const buildSupplierInvoiceItemPurchasesAccountFieldName = (
  id: ISupplierInvoiceItem['id'],
): SupplierInvoiceItemPurchasesAccount => `item_purchasesAccount__${id}`;

export interface SupplierInvoiceFilesForm {
  initialReceiptFiles: IFile[];

  receiptFiles: File[];

  deletedReceiptFilesIds: string[];
}

export interface EditSupplierInvoiceForm extends SupplierInvoiceFilesForm, AmountsFieldForm {
  name: string;

  invoiceNumber: string;

  typeId: number;

  billingDate: string;

  paymentDate?: string | null;

  supplierId: number;

  associatedProjects?: RequiredByKeys<IProject, 'primaryClient'>[];

  associatedOrders?: IOrder[];

  comment?: string | null;

  invoiceFile?: {
    fileURL: string;
    mimeType: string;
    file?: File;
  };

  deletedOrders?: number[];

  [projectDistribution: ProjectDistributionFieldName]: number;

  [projectAmountExVAT: ProjectAmountExVATFieldName]: number;

  deleteInvoiceFile?: boolean;

  pdfPageNumber: number;

  accountingConfigPurchasesAccountId?: string | null;

  mode: SUPPLIER_INVOICE_MODE;

  vatType: VAT_TYPE;

  itemIds: string[];

  // Items
  [itemDescription: SupplierInvoiceItemDescription]: string;

  [itemCode: SupplierInvoiceItemCode]: string | null | undefined;

  [itemUnitAmountExVAT: SupplierInvoiceItemUnitAmountExVAT]: number;

  [itemQuantity: SupplierInvoiceItemQuantity]: number;

  [itemUnit: SupplierInvoiceItemUnit]: string | null | undefined;

  [itemAmountExVAT: SupplierInvoiceItemAmountExVAT]: number;

  [itemVatRate: SupplierInvoiceItemVatRate]: number;

  [itemProjectId: SupplierInvoiceItemProject]: IProject['id'] | undefined;

  [itemTypeId: SupplierInvoiceItemType]: IComponentType['id'];

  [itemPurchasesAccountId: SupplierInvoiceItemPurchasesAccount]:
    | IAccountingConfigPurchasesAccount['id']
    | null
    | undefined;
}

export type EditSupplierInvoiceScreenFormValues = {
  information: EditSupplierInvoiceForm;
};
