/**
 * `Signal` is a simple event emitter which allows
 * third-party logic to listen to those events via
 * a subscription-like mechanism, and to pass data
 * along down to them.
 *
 * @example
 * ```
 * const signal = new Signal()
 * signal.listen((name) => {
 *   console.log(`Hi, I am ${name}`
 * }))
 * ...
 * signal.emit('Rudy')
 * // => 'Hi, I am Rudy'
 * ```
 */
export class Signal<T> {
  private readonly callbacks: Set<(newValue: T) => void>;

  constructor() {
    this.callbacks = new Set();
  }

  listen(callback: (newValue: T) => void, data?: T): void {
    if (this.callbacks.has(callback)) {
      return;
    }
    this.callbacks.add(callback);
    if (data) {
      Signal.executeCallbackWithData(callback, data);
    }
  }

  emit(messageData: T) {
    this.callbacks.forEach((callback) => {
      Signal.executeCallbackWithData(callback, messageData);
    });
  }

  ignore(callback: (newValue: T) => void) {
    this.callbacks.delete(callback);
  }

  static executeCallbackWithData<T>(callback: (newValue: T) => void, data: T) {
    callback(data);
  }
}
