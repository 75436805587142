import { useCallback } from 'react';
import Big from 'big.js';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { mapQuoteOptionalItemToUpdateVatRate } from 'features/quotation/quote-common/mappers/quoteOptionalItemMapper';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteOptionalItemUpdateVatRate() {
  const { quoteOptionalItemsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, newVatRate: string): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(nodeId);
          const quoteNode = quoteOptionalItem.getNodeOrThrow();
          this.oldValue = quoteOptionalItem.getVatRate();

          quoteOptionalItem.updateVatRate(new Big(newVatRate));
          quoteSetToStore(quote);
          if (quoteOptionalItemsProxyApi) {
            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteOptionalItemsProxyApi
              .updateQuoteOptionalItem(
                quoteOptionalItem.getId(),
                mapQuoteOptionalItemToUpdateVatRate(quoteNode, quote.getId(), clientRequestId, timestamp),
              )
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
          }
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteOptionalItem = quote.getTree().getQuoteOptionalItemOrThrow(nodeId);
          const quoteNode = quoteOptionalItem.getNodeOrThrow();

          quoteOptionalItem.updateVatRate(this.oldValue);
          quoteSetToStore(quote);
          if (quoteOptionalItemsProxyApi) {
            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteOptionalItemsProxyApi
              .updateQuoteOptionalItem(
                quoteOptionalItem.getId(),
                mapQuoteOptionalItemToUpdateVatRate(quoteNode, quote.getId(), clientRequestId, timestamp),
              )
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
          }
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteOptionalItemsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
