import type { IClient } from '../client/client.type';
import type { ICompany } from '../company/company.type';
import type { IDownPayment } from '../down-payment/down-payment.type';
import type { IInvoice } from '../invoice/invoice.type';
import type { IPayment } from '../payment/payment.type';
import type { IProgressStatement } from '../progress-statement/progress-statement.type';
import type { IProject } from '../project/project.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IReminderInstance } from '../reminder/reminder.type';
import type { IHoldback } from '../holdback/holdback.type';

export enum BILL_STATUSES {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID = 'PAID',
  LOST = 'LOST',
}

/**
 * BillToRemind is a view that assigns bills to reminder,
 * considering how late the bill is and what reminders have already been sent
 * (or marked as reminded, which is equivalent to having been sent).
 */
export interface IBillToRemind {
  billId: number;

  reminderId: string;

  // For convenience, this value is reminder.companyId.
  companyId: number;

  // For convenience, this value only depends on bill.paymentDeadline and the current time.
  lateDays: number;
}

export interface IBill {
  id: number;

  historyFamilyId: string;

  status: BILL_STATUSES;

  name: string;

  invoiceNumber: string | null;

  totalToBePaidIncVAT: number;

  remainingToBePaidIncVAT: number;

  billingDate: Date;

  paymentDeadline: Date;

  createdAt: Date;

  updatedAt: Date;

  project?: IProject | null;

  client?: IClient;

  company?: ICompany;

  progressStatement?: IProgressStatement | null;

  invoice?: IInvoice | null;

  downPayment?: IDownPayment | null;

  holdback?: IHoldback | null;

  payments?: IPayment[];

  comment: string | null;

  lateDays?: number;

  paymentPaidAt?: Date;

  tags: string[];

  lastReminderInstance?: IReminderInstance | null;

  billToRemind?: IBillToRemind | null;
}

export type IBillWithRelations = RequiredByKeys<IBill, 'project' | 'invoice' | 'payments' | 'billToRemind'>;
