import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, DroppableFileField } from '@graneet/lib-ui';
import { Text } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import type { IProject } from '@graneet/business-logic';

import { useProjectAttachAcceptanceReport } from '../../services/project.api';

import { Rule } from 'features/form/rules/Rule';

interface ProjectAttachAcceptanceReportModalProps {
  project: IProject;

  isOpen: boolean;

  onClose(): void;
}

interface ProjectAttachAcceptanceReportForm {
  acceptanceReport: File;
}

export const ProjectAttachAcceptanceReportModal: FC<ProjectAttachAcceptanceReportModalProps> = ({
  project,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation(['project']);

  const form = useForm<ProjectAttachAcceptanceReportForm>();
  const { isValid: isFormValid } = useFormStatus(form);

  const projectAttachAcceptanceReportMutation = useProjectAttachAcceptanceReport();

  const handleSubmit = useCallback(() => {
    const { acceptanceReport } = form.getFormValues();

    projectAttachAcceptanceReportMutation.mutate(
      {
        id: project.id,
        dto: { acceptanceReport: acceptanceReport! },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  }, [form, projectAttachAcceptanceReportMutation, project.id, onClose]);

  return (
    <Modal
      size="2xl"
      title={t('project:summary.attachAcceptanceReport.modal.title', { name: project.name })}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Form form={form}>
        <Text mb={6}>{t('project:summary.attachAcceptanceReport.modal.legend')}</Text>

        <DroppableFileField<ProjectAttachAcceptanceReportForm>
          isRequired
          accept="application/pdf"
          name="acceptanceReport"
          label={t('project:summary.attachAcceptanceReport.modal.fileLabel')}
        >
          <Rule.IsRequired />
        </DroppableFileField>
      </Form>

      <Modal.SecondaryButton onClick={onClose}>
        {t('project:summary.attachAcceptanceReport.modal.back')}
      </Modal.SecondaryButton>

      <Modal.PrimaryButton
        isLoading={projectAttachAcceptanceReportMutation.isPending}
        isDisabled={!isFormValid}
        onClick={handleSubmit}
      >
        {t('project:actions.close')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
