import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';
import { MARGIN_TYPE } from '@org/quotation-lib';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuoteComponentUpdateSimilar } from './useQuoteComponentUpdateSimilar';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { genericUpdateItemMapper } from 'features/quotation/quote-common/mappers/quoteItemsGenericMapper';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteComponentUpdateDenomination() {
  const { quoteComponentsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteComponentUpdateSimilar = useQuoteComponentUpdateSimilar();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, denomination: string | null, componentTypeId?: string | null): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteComponent = quote.getTree().getQuoteComponentOrThrow(nodeId);
          const quoteNode = quoteComponent.getNodeOrThrow();
          this.oldValue = {
            denomination: quoteComponent.getDenomination(),
            componentTypeId: quoteComponent.getComponentTypeId()?.toString() || null,
          };

          const values = {
            denomination,
            ...(componentTypeId
              ? { componentTypeId: parseInt(componentTypeId, 10) }
              : {
                  componentTypeId: componentTypeId === null ? null : undefined,
                }),
          };

          quoteComponentUpdateSimilar(nodeId, values);

          if (componentTypeId !== undefined) {
            const formattedComponentTypeId = componentTypeId ? parseInt(componentTypeId, 10) : null;
            quoteComponent.updateComponentTypeId(formattedComponentTypeId, {
              spreadUp: true,
              impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
            });
          }

          quoteComponent.updateProperties({ denomination });

          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteComponentsProxyApi
            .updateQuoteComponent(quoteComponent.getId(), {
              ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
              ...values,
              quoteComponentId: quoteComponent.getId(),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteComponent = quote.getTree().getQuoteComponentOrThrow(nodeId);
          const quoteNode = quoteComponent.getNodeOrThrow();

          const values = {
            denomination: this.oldValue.denomination,
            ...(this.oldValue.componentTypeId
              ? { componentTypeId: parseInt(this.oldValue.componentTypeId, 10) }
              : {
                  componentTypeId: this.oldValue.componentTypeId === null ? null : undefined,
                }),
          };

          if (this.oldValue.componentTypeId !== undefined) {
            const formattedComponentTypeId = this.oldValue.componentTypeId
              ? parseInt(this.oldValue.componentTypeId, 10)
              : null;
            quoteComponent.updateComponentTypeId(formattedComponentTypeId, {
              spreadUp: true,
              impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
            });
          }

          // We don't run the updateSimilar on the undo
          quoteComponent.updateProperties({ denomination: this.oldValue.denomination });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteComponentsProxyApi
            .updateQuoteComponent(quoteComponent.getId(), {
              ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
              ...values,
              quoteComponentId: quoteComponent.getId(),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteComponentUpdateSimilar, quoteComponentsProxyApi, quoteError, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
