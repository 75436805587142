import type { FC } from 'react';
import { useCallback } from 'react';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ThirdParties } from '@graneet/business-logic';
import { capitalizeString } from '@graneet/business-logic';

import { useDeactivateThirdParty } from '../../services/third-party.api';

interface ThirdPartyDisableModalProps {
  thirdPartyName: ThirdParties;
  modal: UseDisclosureReturn;
}

export const ThirdPartyDisableModal: FC<ThirdPartyDisableModalProps> = ({ thirdPartyName, modal }) => {
  const { t } = useTranslation(['global']);

  const deactivateThirdPartyMutation = useDeactivateThirdParty();

  const onSubmit = useCallback(() => {
    deactivateThirdPartyMutation.mutate(thirdPartyName, {
      onSuccess: () => {
        modal.onClose();
      },
    });
  }, [deactivateThirdPartyMutation, modal, thirdPartyName]);

  return (
    <Modal
      title={t('global:thirdParty.disableModal.title', { thirdParty: capitalizeString(thirdPartyName) })}
      isOpen={modal.isOpen}
      onClose={modal.onClose}
    >
      <Text>{t('global:thirdParty.disableModal.description', { thirdParty: capitalizeString(thirdPartyName) })}</Text>

      <Modal.Close isDisabled={deactivateThirdPartyMutation.isPending} />
      <Modal.PrimaryButton onClick={onSubmit} isLoading={deactivateThirdPartyMutation.isPending} colorScheme="red">
        {t('global:words.c.disable')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
