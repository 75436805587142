import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type {
  IContractResponseDTO,
  ISupplierInvoice,
  IProgressStatement,
  IOrderWithDirectPaymentAmounts,
} from '@graneet/business-logic';

// Context value type
export interface DirectPaymentContextValue {
  /**
   * List of direct payment orders
   */
  directPaymentOrders: IOrderWithDirectPaymentAmounts[];

  /**
   * Current progress statement if exists (null if user is in creation context)
   */
  currentProgressStatement: IProgressStatement | null;

  selectedOrderIndex: number;

  onOrderSelect(orderIndex: number): void;

  selectedOrder: IOrderWithDirectPaymentAmounts | null;

  previousProgressStatement?: IProgressStatement | null;

  contracts: IContractResponseDTO[] | null;

  supplierInvoices: Record<number, ISupplierInvoice>;

  updateSupplierInvoice(supplierInvoice: ISupplierInvoice): void;
}

// Context default value (before init)
const DirectPaymentContextEmptyValue: DirectPaymentContextValue = {
  directPaymentOrders: [],
  selectedOrderIndex: 0,
  onOrderSelect: () => {},
  selectedOrder: null,
  currentProgressStatement: null,
  previousProgressStatement: null,
  contracts: [],
  supplierInvoices: {},
  updateSupplierInvoice: () => {},
};

// Context creation
export const DirectPaymentContext = createContext(DirectPaymentContextEmptyValue);

// useContext shortcut
export const useDirectPaymentContext = () => useContext(DirectPaymentContext);

// Provider component props
export type DirectPaymentContextProviderProps = PropsWithChildren<
  Pick<
    DirectPaymentContextValue,
    'directPaymentOrders' | 'currentProgressStatement' | 'previousProgressStatement' | 'contracts'
  >
>;

const getInitialSupplierInvoices = (
  directPaymentOrders: IOrderWithDirectPaymentAmounts[],
  currentProgressStatementId: number | undefined,
): Record<number, ISupplierInvoice> =>
  directPaymentOrders.reduce<Record<number, ISupplierInvoice>>((acc, dpo) => {
    const supplierInvoice =
      dpo.ordersSupplierInvoices?.filter(
        (osi) => osi.supplierInvoice!.progressStatement!.id === currentProgressStatementId,
      )[0]?.supplierInvoice || null;

    if (supplierInvoice) {
      acc[supplierInvoice.id] = { ...supplierInvoice, supplier: dpo.supplier };
    }

    return acc;
  }, {});

// Provider component
export const DirectPaymentContextProvider: FC<DirectPaymentContextProviderProps> = ({
  directPaymentOrders,
  currentProgressStatement,
  previousProgressStatement,
  contracts,
  children,
}) => {
  // Selected Order
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(0);

  const handleSelectedOrderIndexChange = useCallback(
    (newValue: number) => {
      if (newValue < 0 || newValue > directPaymentOrders.length - 1) {
        throw new Error('new selectedOrderIndex value is out of bounds.');
      }
      setSelectedOrderIndex(newValue);
    },
    [directPaymentOrders],
  );
  const selectedOrder = useMemo(
    () => directPaymentOrders[selectedOrderIndex] || null,
    [directPaymentOrders, selectedOrderIndex],
  );

  // Selected supplier invoices
  const [supplierInvoices, setSupplierInvoices] = useState<Record<number, ISupplierInvoice>>(() =>
    getInitialSupplierInvoices(directPaymentOrders, currentProgressStatement?.id),
  );

  const updateSupplierInvoice = useCallback((supplierInvoice: ISupplierInvoice) => {
    setSupplierInvoices((prevValues) => ({
      ...prevValues,
      [supplierInvoice.id]: supplierInvoice,
    }));
  }, []);

  const value = useMemo<DirectPaymentContextValue>(
    () => ({
      directPaymentOrders,
      selectedOrderIndex,
      onOrderSelect: handleSelectedOrderIndexChange,
      selectedOrder,
      currentProgressStatement,
      previousProgressStatement,
      contracts,
      supplierInvoices,
      updateSupplierInvoice,
    }),
    [
      directPaymentOrders,
      selectedOrderIndex,
      handleSelectedOrderIndexChange,
      selectedOrder,
      currentProgressStatement,
      previousProgressStatement,
      contracts,
      supplierInvoices,
      updateSupplierInvoice,
    ],
  );

  return <DirectPaymentContext.Provider value={value}>{children}</DirectPaymentContext.Provider>;
};
