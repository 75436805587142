import type { FC } from 'react';
import { Highlight, useCurrency, formatPercentage } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IProgressStatement, ISubProject } from '@graneet/business-logic';
import { getPercentage } from '@graneet/business-logic';
import type { FormContextApi } from 'graneet-form';
import { useOnBlurValues } from 'graneet-form';
import { Box } from '@chakra-ui/react';

import { useCumulativeProgressExVAT } from '../hooks/useCumulativeProgressExVAT';
import type { ProgressStatementEditItemForm } from '../forms/progress-statement-edit-item-form';

import { ProgressStatementExVATAmountLabel } from './labels/ProgressStatementExVATAmountLabel';

interface ProgressStatementTotalsSummaryProps {
  subProject: ISubProject;

  previousProgressStatement?: IProgressStatement;

  form: FormContextApi<ProgressStatementEditItemForm>;
}

export const ProgressStatementTotalsSummary: FC<ProgressStatementTotalsSummaryProps> = ({
  subProject,
  previousProgressStatement,
  form,
}) => {
  const { t } = useTranslation(['progressStatement']);
  const { mapAmountToNumber } = useCurrency();

  const cumulativeValues = useOnBlurValues(form, undefined);
  const cumulativeAmountExVAT = useCumulativeProgressExVAT(cumulativeValues);

  return (
    <>
      <Box mr={2} minW="10rem" data-testid="label:progressStatement.editItems.totalPercentage">
        <Highlight
          label={t('progressStatement:isCreating.cumulativePercentage')}
          value={formatPercentage(getPercentage(mapAmountToNumber(cumulativeAmountExVAT), subProject.amountExVAT))}
        />
      </Box>

      <Box minW="15rem" data-testid="label:progressStatement.editItems.totalAmountExVAT">
        <Highlight
          label={t('progressStatement:isCreating.nonCumulativeAmountExVAT')}
          value={
            <ProgressStatementExVATAmountLabel
              values={cumulativeValues}
              previousCumulativeAmountExVAT={previousProgressStatement?.cumulativeProgressAmountExVAT || 0}
            />
          }
        />
      </Box>
    </>
  );
};
