/* eslint-disable react/no-array-index-key */
import type { ReactElement } from 'react';
import { useCallback, useMemo } from 'react';
import { Box, Divider, Flex, Grid, useTheme } from '@chakra-ui/react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { useTimeTableContext } from '../../contexts';
import type { ButtonColorScheme } from '../../../../Badge/Badge';
import type { ITimeTableRange } from '../../types';

import { TimeTableSlotPlaceholder } from './TimeTableSlotPlaceholder';
import { TimeTableRange } from './TimeTableRange';

dayjs.extend(isBetween);

export interface TimeTableRowProps {
  header: (numberOfMinutesWorked: number) => ReactElement;
  ranges: ITimeTableRange[][];
  id: string;
}

export const TimeTableRow = ({ header, ranges, id }: TimeTableRowProps) => {
  const { colors } = useTheme();
  const { numberOfDays, currentStartOfWeek } = useTimeTableContext();

  const daysPlaceholders = useMemo(() => {
    const start = dayjs(currentStartOfWeek).startOf('week');
    const end = start.add(numberOfDays ?? 1, 'day');
    const days = [];
    for (let day = start; day.isBefore(end); day = day.add(1, 'day')) {
      days.push(day.toDate());
    }
    return days;
  }, [numberOfDays, currentStartOfWeek]);

  // keeps only week events
  const weekRanges = useMemo(
    () =>
      ranges.map((currRanges) =>
        currRanges.filter((range) => dayjs(currentStartOfWeek).isSame(range.startDate, 'week')),
      ),
    [currentStartOfWeek, ranges],
  );

  const isDateInRange = useCallback(
    (date: Date, range: ITimeTableRange) => dayjs(date).isBetween(range.startDate, range.endDate, 'day', '[]'),
    [],
  );

  const numberOfMinutesWorked = useMemo(
    () =>
      ranges.reduce(
        (acc, range) =>
          acc +
          range
            .filter((timeTable) => timeTable.associatedEntityId !== -1)
            .reduce((accMin, rangeMin) => accMin + rangeMin.nbMinutes, 0),
        0,
      ),
    [ranges],
  );

  return (
    <Flex direction="row" minHeight="6rem">
      <Box w="18rem">
        <Divider />
        {header(numberOfMinutesWorked)}
      </Box>

      <Flex direction="column" w="100%">
        <Grid
          alignItems="flex-start"
          templateColumns={`repeat(${numberOfDays}, 1fr)`}
          borderTop={`1px solid ${colors.gray[200]}`}
          borderRight={`1px solid ${colors.gray[200]}`}
          borderLeft={`1px solid ${colors.gray[200]}`}
          gridGap="0 1px"
          bgColor={colors.gray[200]}
          h="100%"
          w="100%"
          overflow="hidden"
        >
          {weekRanges.map((rangesGroupedBy, rowIndex) =>
            rangesGroupedBy.map((range, index) => (
              <TimeTableRange
                key={`${range.id}-${index}`}
                name={range.name}
                color={range.color as ButtonColorScheme}
                startDate={new Date(range.startDate)}
                slots={range.slots}
                row={rowIndex + 1}
                daysPlaceholders={daysPlaceholders}
              />
            )),
          )}

          {weekRanges.map((rangesGroupedBy, rangeIndex) =>
            rangesGroupedBy.map((range) =>
              daysPlaceholders.map((date, dayIndex) => (
                <TimeTableSlotPlaceholder
                  key={`${range.id}-${date.toISOString()}`}
                  id={id}
                  subEntityId={range.id}
                  isOff={!range.id}
                  date={date}
                  range={range}
                  colStart={dayIndex + 1}
                  rowStart={rangeIndex + 1}
                  disabled={isDateInRange(date, range)}
                />
              )),
            ),
          )}

          {daysPlaceholders.map((date, dayIndex) => (
            <TimeTableSlotPlaceholder
              key={date.toISOString()}
              isOff={false}
              id={id}
              date={date}
              colStart={dayIndex + 1}
              rowStart={weekRanges.flat().length + 1}
            />
          ))}
        </Grid>
      </Flex>
    </Flex>
  );
};
