import type { FC } from 'react';
import { useMemo } from 'react';
import type { IProjectStatsSummaryDTO } from '@graneet/business-logic';
import { getFloatingPercentage } from '@graneet/business-logic';
import { Badge, Card, formatPercentage, Price, Progress, SIGN_EMPTY_AMOUNT } from '@graneet/lib-ui';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ProjectSummaryStats } from './ProjectSummaryStats';

type PercentProps = {
  value: number | null;
};
const Percent: FC<PercentProps> = ({ value }) => {
  const isValueEqualToNull = value === null;
  const isValueEqualToZero = value === 0;
  const isValueSuperiorToZero = !isValueEqualToNull && value! > 0;

  const colorScheme = useMemo(() => {
    if (isValueEqualToNull || isValueEqualToZero) {
      return 'gray';
    }

    return isValueSuperiorToZero ? 'green' : 'red';
  }, [isValueEqualToNull, isValueSuperiorToZero, isValueEqualToZero]);

  const renderValue = useMemo(() => {
    if (isValueEqualToNull) {
      return SIGN_EMPTY_AMOUNT;
    }

    return formatPercentage(value, { signDisplay: 'exceptZero', maximumFractionDigits: 2 });
  }, [value, isValueEqualToNull]);

  return (
    <Badge showDot colorScheme={colorScheme} minW="5rem" fontWeight={700}>
      {renderValue}
    </Badge>
  );
};

// Computed stat values
/**
 * If number of budgets is 0, return null for an empty state
 */
const getBudget = ({ numberOfBudgets, budgetAmountExVATSum }: IProjectStatsSummaryDTO) => {
  if (numberOfBudgets === 0) {
    return null;
  }
  return budgetAmountExVATSum;
};

/**
 * If number of budgets is 0, return null for an empty state
 */
const getRestToSpendExVAT = ({
  numberOfBudgets,
  budgetAmountExVATSum,
  orderAmountExVATSum,
  timeSlotTotalPriceExVATSum,
}: IProjectStatsSummaryDTO) => {
  if (numberOfBudgets === 0) {
    return null;
  }
  return budgetAmountExVATSum - orderAmountExVATSum - timeSlotTotalPriceExVATSum;
};

/**
 * If number of budgets is 0 or denominator is equal to 0, return null for an empty state
 */
const getBudgetSpentPercentage = ({
  numberOfBudgets,
  budgetAmountExVATSum,
  orderAmountExVATSum,
  timeSlotTotalPriceExVATSum,
}: IProjectStatsSummaryDTO) => {
  if (numberOfBudgets === 0 || budgetAmountExVATSum === 0) {
    return null;
  }
  return getFloatingPercentage(orderAmountExVATSum + timeSlotTotalPriceExVATSum, budgetAmountExVATSum);
};

/**
 * If there is no contract, return null for an empty state
 */
const getRestToBillExVAT = ({ numberOfContracts, projectAmountToBeBilledExVAT }: IProjectStatsSummaryDTO) => {
  if (numberOfContracts === 0) {
    return null;
  }
  return projectAmountToBeBilledExVAT;
};

/**
 * If there is no (contract + invoices) or no (supplier invoices + time slots) or denominator is equal to 0, return null for an empty state
 */
const getBilledVersusSpentPercentage = ({
  numberOfContracts,
  numberOfInvoices,
  supplierInvoiceAmountExVATSum,
  approvedTotalPriceExVATSum,
  projectTotalBilledAmountExVAT,
}: IProjectStatsSummaryDTO) => {
  const amountSpentExVAT = supplierInvoiceAmountExVATSum + approvedTotalPriceExVATSum;

  if (numberOfContracts + numberOfInvoices === 0 || projectTotalBilledAmountExVAT === 0) {
    return null;
  }
  return getFloatingPercentage(projectTotalBilledAmountExVAT - amountSpentExVAT, projectTotalBilledAmountExVAT);
};

interface ProjectSummaryStatsProps {
  stats: IProjectStatsSummaryDTO;
}

export const ProjectSummaryStatsCard: FC<ProjectSummaryStatsProps> = ({ stats }) => {
  const { t } = useTranslation(['project']);

  return (
    <Card>
      <Flex boxSize="100%">
        <ProjectSummaryStats
          topStat={{ label: t('project:projectSummaryStatsCard.projectAmount'), stat: stats.projectAmountExVAT }}
          bottomStat={{
            label: t('project:projectSummaryStatsCard.budget'),
            stat: (
              <Badge showDot colorScheme={getBudget(stats) ? 'blue' : 'gray'} minW="5rem" fontWeight={700}>
                <Price amount={getBudget(stats)} />
              </Badge>
            ),
          }}
        />

        <Box px={4}>
          <Divider orientation="vertical" />
        </Box>

        <ProjectSummaryStats
          topStat={{ label: t('project:projectSummaryStatsCard.restToSpend'), stat: getRestToSpendExVAT(stats) }}
          bottomStat={{
            label: t('project:projectSummaryStatsCard.budgetSpent'),
            stat: (
              <Flex display="inline-flex" verticalAlign="bottom">
                {getBudgetSpentPercentage(stats) ? (
                  <Progress
                    h={7}
                    value={getBudgetSpentPercentage(stats)!}
                    colors={{
                      progression: 'green.100',
                      number: 'green.900',
                    }}
                    superiorToMaxColors={{
                      progression: 'red.100',
                      number: 'red.800',
                    }}
                  />
                ) : (
                  <Badge showDot colorScheme="gray" minW="5rem">
                    <Price amount={null} />
                  </Badge>
                )}
              </Flex>
            ),
          }}
        />

        <Box px={4}>
          <Divider orientation="vertical" />
        </Box>

        <ProjectSummaryStats
          topStat={{ label: t('project:projectSummaryStatsCard.restToBill'), stat: getRestToBillExVAT(stats) }}
          bottomStat={{
            label: t('project:projectSummaryStatsCard.billedVersusSpend'),
            stat: <Percent value={getBilledVersusSpentPercentage(stats)} />,
          }}
        />
      </Flex>
    </Card>
  );
};
