import { useCallback, useRef } from 'react';
import { useCallbackRef } from '@chakra-ui/react';
import type { IUserResponseDTO } from '@graneet/business-logic';
import { isNumberFinite } from '@graneet/business-logic';

import { useUserCompanies } from '../services/company.api';

import { getUserCompanyEventSource } from 'features/user/services/user.api';
import { useOnLineStatus } from 'features/common/hooks/useOnLineStatus';

const SSE_POLLING_DELAY = 3000;

export const useOnUserCompanyChanged = (
  user: IUserResponseDTO | null,
  onCompanyChanged: (companyName: string) => Promise<void>,
) => {
  const eventSourceRef = useRef<EventSource | null>(null);

  const userCompanies = useUserCompanies();

  const onCompanyChangedRef = useCallbackRef(onCompanyChanged, []);

  const pollingTimeoutRef = useRef<number | null>(null);

  const clearPendingPolling = useCallback(() => {
    if (isNumberFinite(pollingTimeoutRef.current)) {
      window.clearTimeout(pollingTimeoutRef.current);
    }
  }, []);

  const listenToCompanyChanges = useCallback(() => {
    /**
     * Clear any timeout from previous polling (useful if we use/get network
     * many times quickly for instance)
     */
    clearPendingPolling();

    /**
     * User is not defined yet
     */
    if (!user) {
      return;
    }

    /**
     * Already listening, close previous event source
     */
    if (eventSourceRef.current) {
      eventSourceRef.current?.close();
    }

    /**
     * Create and assigning the event source to its ref (we bind `onmessage`/`onerror`
     * later, once `handleEventSourceSuccess` and `handleEventSourceError` have been defined)
     */
    eventSourceRef.current = getUserCompanyEventSource(encodeURIComponent(user.auth0ID));

    /**
     * Handler for when the company was successfully
     */
    const handleEventSourceSuccess = async (e: { data: string }) => {
      if (user.company.id.toString() !== e.data) {
        const newCompany = userCompanies.data.find((company) => company.id.toString() === e.data);
        onCompanyChangedRef(newCompany?.name ?? user.company.name);
      }
    };

    const handleEventSourceError = () => {
      // Closing current event source
      eventSourceRef.current?.close();

      const createCompanyUpdateEventSource = () =>
        new Promise<EventSource>((resolve, reject) => {
          const eventSource = getUserCompanyEventSource(encodeURIComponent(user.auth0ID));

          /**
           * When event source succeeds, it stops the polling cycle
           */
          eventSource.onopen = () => {
            resolve(eventSource);
          };

          /**
           * This will cause `eventSourceRef.current.onerror` to be called thus
           * continuing the polling cycle
           */
          eventSource.onerror = () => {
            eventSource.close();
            reject();
          };
        });

      const pollingCompanyEndpoint = async () => {
        try {
          /**
           * Create a new event source listening to company change
           */
          eventSourceRef.current = await createCompanyUpdateEventSource();
          /**
           * Re-binding success/error listeners for newly created event source
           */
          eventSourceRef.current.onmessage = handleEventSourceSuccess;
          eventSourceRef.current.onerror = handleEventSourceError;
        } catch {
          /**
           * If calling `getCompanyUpdateEventSource` failed, try again a few moments later
           */
          pollingTimeoutRef.current = window.setTimeout(pollingCompanyEndpoint, SSE_POLLING_DELAY);
        }
      };

      /**
       * Start polling with a little delay (to avoid infinite calls if SSE route returns an error)
       */
      pollingTimeoutRef.current = window.setTimeout(pollingCompanyEndpoint, SSE_POLLING_DELAY);
    };

    /**
     * Binding of success/error listeners for initial event source
     */
    eventSourceRef.current.onmessage = handleEventSourceSuccess;
    eventSourceRef.current.onerror = handleEventSourceError;
  }, [clearPendingPolling, user, onCompanyChangedRef, userCompanies.data]);

  /**
   * Kick-off listening when we have network, and do nothing otherwise
   */
  useOnLineStatus({
    onRecovered: listenToCompanyChanges,
    onLost: clearPendingPolling,
  });
};
