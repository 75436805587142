import type { FC } from 'react';
import { Card, DocumentStatusCard, DocumentStatusCardActions, formatDateOrEmpty } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IHoldBackResponseDTO } from '@graneet/business-logic';
import { FEATURE_FLAGS, PERMISSION } from '@graneet/business-logic';

import { HoldbackStatusBadge } from '../HoldbackStatusBadge';

import { getHoldbackActions, getHoldbackStatusCardIcon } from './holdback.actions';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useHoldbackMarkAsToBeReceived } from 'features/holdback/services/holdback.api';

interface HoldbackStatusCardProps {
  holdback: IHoldBackResponseDTO;

  openReceiveModal: () => void;
}

export const HoldbackStatusCard: FC<HoldbackStatusCardProps> = ({ holdback, openReceiveModal }) => {
  const { t } = useTranslation(['holdback', 'global']);

  const hasPayHoldbackPermission = usePermissions([PERMISSION.PAY_HOLDBACKS]);
  const hasAccountingStandards = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARDS);

  const holdbackMarkAsToBeReceivedMutation = useHoldbackMarkAsToBeReceived();

  const handleToBeReceived = async () => {
    await holdbackMarkAsToBeReceivedMutation.mutateAsync(holdback.id);
  };

  return (
    <Card title={t('holdback:cards.status')}>
      <DocumentStatusCard
        mb={4}
        icon={getHoldbackStatusCardIcon(holdback.status)}
        title={`${t('holdback:holdback')} - ${holdback.project.name}`}
        subtitle={
          holdback.holdbackEndDate
            ? t('holdback:endDateAt', { date: formatDateOrEmpty(holdback.holdbackEndDate, 'LL') })
            : undefined
        }
        badgeComponent={<HoldbackStatusBadge holdback={holdback} />}
      />

      <DocumentStatusCardActions
        actions={getHoldbackActions({
          holdback,
          hasPayHoldbackPermission,
          hasAccountingStandards,
          onMarkReceived: openReceiveModal,
          onMarkToBeReceived: handleToBeReceived,
        })}
      />
    </Card>
  );
};
