import type { IInvoiceResponseDTO } from './dtos';
import type { IInvoice } from './invoice.type';
import { INVOICE_STATUSES } from './invoice.type';

export const isInvoiceInDraft = (invoice: Pick<IInvoice, 'status'>): boolean =>
  invoice.status === INVOICE_STATUSES.DRAFT;

export const isInvoiceProForma = (invoice: Pick<IInvoice, 'status'>): boolean => isInvoiceInDraft(invoice);

export type InvoiceCannotBeCancelledCause = 'accounting-payments';
/**
 * Check if an invoice can be canceled.
 * For technical reasons, we can't cancel an invoice if it has payments with accounting export.
 * But we can cancel an invoice if invoice itself has accounting export.
 * @param {IInvoiceResponseDTO} invoice
 * @returns { ok: boolean; causes: InvoiceCannotBeCancelledCause[] }
 */
export const canInvoiceBeCancelled = (
  invoice: IInvoiceResponseDTO,
): { ok: boolean; causes: InvoiceCannotBeCancelledCause[] } => {
  const result = {
    ok: true,
    causes: [] as InvoiceCannotBeCancelledCause[],
  };

  if (invoice.hasPaymentAccountingExported) {
    result.ok = false;
    result.causes.push('accounting-payments');
  }
  return result;
};

type InvoiceCannotBeForceDraftedCause = 'accounting-payments' | 'accounting-invoice';
/**
 * Check if a invoice can be force draft.
 * For technical reasons, we can't force draft an invoice if it has accounting export or has payments with accounting export.
 * @param {IInvoiceResponseDTO} invoice
 * @returns { ok: boolean; causes: InvoiceCannotBeForceDraftCause[] }
 */
export const canInvoiceBeForceDrafted = (
  invoice: IInvoiceResponseDTO,
): { ok: boolean; causes: InvoiceCannotBeForceDraftedCause[] } => {
  const result = {
    ok: true,
    causes: [] as InvoiceCannotBeForceDraftedCause[],
  };

  if (invoice.hasPaymentAccountingExported) {
    result.ok = false;
    result.causes.push('accounting-payments');
  }

  if (invoice.isAccountingExported) {
    result.ok = false;
    result.causes.push('accounting-invoice');
  }

  return result;
};
