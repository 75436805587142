import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Wizard } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PDF_TEMPLATES_TYPES, PDF_LABELS_POSITION } from '@graneet/business-logic';

import { EditPdfSettingsStep } from './_steps/EditPdfSettingsStep';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { usePdfSettings, useUpdateCompanyPdfSettings } from 'features/pdf/services/pdf-settings.api';
import type { PDFSettingsFormValues, PDFSettingsWizardValues } from 'features/pdf/constants/pdf-settings.constant';
import { PDFSettingsStep, PDF_SETTINGS_FIELDS } from 'features/pdf/constants/pdf-settings.constant';
import { Beacon } from 'features/beacon/components/Beacon';
import { PdfSettingsConfirmEditModal } from 'features/pdf/components/modals/PdfSettingsConfirmEditModal';
import { PdfSettingsCancelModal } from 'features/pdf/components/modals/PdfSettingsCancelModal';

const EditPdfSettingsScreenInternal = ({ template }: { template: PDF_TEMPLATES_TYPES }) => {
  const pdfSettingsList = usePdfSettings();

  const { quotePdfSettings, statementPdfSettings } = pdfSettingsList.data;

  let pdfSettings;
  let otherPdfSettings;

  if (template === PDF_TEMPLATES_TYPES.QUOTE) {
    pdfSettings = quotePdfSettings;
    otherPdfSettings = statementPdfSettings;
  } else if (template === PDF_TEMPLATES_TYPES.STATEMENT) {
    pdfSettings = statementPdfSettings;
    otherPdfSettings = quotePdfSettings;
  }

  return (
    <>
      <EditPdfSettingsStep template={template} pdfSettings={pdfSettings} otherPdfSettings={otherPdfSettings} />
      <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_HELP}>
        <Beacon top="-4rem" right="0.6rem" />
      </Wizard.Placeholder>
    </>
  );
};

export const EditPdfSettingsScreen: FC = () => {
  useHideNavbar();

  const { t } = useTranslation(['pdfSettings', 'global']);
  const history = useHistory();
  const { template } = useParams<{ template: PDF_TEMPLATES_TYPES }>();
  const confirmModal = useDisclosure();
  const cancelModal = useDisclosure();

  const pdfSettingsRef = useRef<PDFSettingsFormValues>();

  const updateCompanyPdfSettingsMutation = useUpdateCompanyPdfSettings();

  const wizardLabels = useWizardLabels({
    save: t(`pdfSettings:${template}.wizard.label`),
  });

  const goBackToPdfSettings = useCallback(() => {
    history.push('/settings/company/pdf-models');
  }, [history]);

  const submitForm = useCallback(async () => {
    if (!pdfSettingsRef.current) return;

    const {
      labelsDisplayed,
      [PDF_SETTINGS_FIELDS.LABELS_POSITION]: labelsPosition,
      ...values
    } = pdfSettingsRef.current;

    await updateCompanyPdfSettingsMutation.mutateAsync({
      template,
      settings: {
        [PDF_SETTINGS_FIELDS.LABELS_POSITION]: labelsDisplayed ? labelsPosition : PDF_LABELS_POSITION.NONE,
        ...values,
      },
    });

    confirmModal.onClose();
    goBackToPdfSettings();
  }, [updateCompanyPdfSettingsMutation, template, confirmModal, goBackToPdfSettings]);

  const handleWizardFinish = useCallback(
    async (wizardFormValues: PDFSettingsWizardValues) => {
      pdfSettingsRef.current = wizardFormValues[PDFSettingsStep.EDIT_PDF_SETTINGS];
      confirmModal.onOpen();
    },
    [confirmModal],
  );

  return (
    <>
      <Wizard<PDFSettingsWizardValues>
        headerTitle={t(`pdfSettings:${template}.wizard.title`)}
        onQuit={cancelModal.onOpen}
        onFinish={handleWizardFinish}
        labels={wizardLabels}
        px={0}
      >
        <Wizard.Step<PDFSettingsWizardValues> name={PDFSettingsStep.EDIT_PDF_SETTINGS} h="100%" py={0}>
          <QueryWrapper>
            <EditPdfSettingsScreenInternal template={template} />
          </QueryWrapper>
        </Wizard.Step>
      </Wizard>

      <PdfSettingsConfirmEditModal template={template} modal={confirmModal} onConfirmed={submitForm} />
      <PdfSettingsCancelModal template={template} modal={cancelModal} onConfirmed={goBackToPdfSettings} />
    </>
  );
};
