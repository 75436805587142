import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import {
  ColorPickerField,
  CurrencyField,
  DateField,
  FieldGroupLabel,
  IconAdvanced,
  Modal,
  SimpleHelpIcon,
  TextAreaField,
  TextField,
  useCurrency,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IProjectWithRelations } from '@graneet/business-logic';
import { GRANEET_COLORS } from '@graneet/business-logic';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Flex, HStack, VStack } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';

import { PROJECT_COLOR_PICKER_COLORS } from '../../constants/project-color.constant';
import { useProjectUpdateInformation } from '../../services/project.api';

import { Rule } from 'features/form/rules/Rule';

interface EditProjectInformationsModalProps {
  project: IProjectWithRelations;

  modal: UseDisclosureReturn;

  onUpdated(): void;
}

interface EditProjectInformationsForm {
  name: string;

  refCode?: string | null;

  purchaseOrderNumber?: string | null;

  startDate?: string | null;

  endDate?: string | null;

  note?: string | null;

  color: GRANEET_COLORS | null;

  addressAddress: string;

  addressPostalCode?: string | null;

  addressCity?: string | null;

  addressCountry?: string | null;

  defaultKmExpense?: number | null;
}

export const EditProjectInformationsModal: FC<EditProjectInformationsModalProps> = ({ project, modal, onUpdated }) => {
  const { t } = useTranslation(['project', 'global']);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const form = useForm<EditProjectInformationsForm>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues, setFormValues } = form;

  const projectUpdateInformationMutation = useProjectUpdateInformation();

  useEffect(() => {
    setFormValues({
      name: project.name,
      color: project.color,
      refCode: project.refCode,
      purchaseOrderNumber: project.purchaseOrderNumber,
      startDate: project.startDate,
      endDate: project.endDate,
      note: project.note,
      addressAddress: project.address.address,
      addressPostalCode: project.address.postalCode,
      addressCity: project.address.city,
      addressCountry: project.address.country,
      defaultKmExpense: mapNumberToAmount(project.defaultKmExpense ?? 0),
    });
  }, [project, setFormValues, mapNumberToAmount]);

  const handleSubmit = useCallback(async () => {
    if (Number.isNaN(project?.id)) {
      return;
    }
    const values = getFormValues();

    await projectUpdateInformationMutation.mutateAsync({
      id: project.id,
      dto: {
        name: values.name!,
        color: values.color || GRANEET_COLORS.WARM_GRAY,
        refCode: values.refCode || null,
        purchaseOrderNumber: values.purchaseOrderNumber || null,
        startDate: values.startDate || null,
        endDate: values.endDate || null,
        note: values.note || '',
        address: {
          address: values.addressAddress!,
          postalCode: values.addressPostalCode,
          city: values.addressCity,
          country: values.addressCountry,
        },
        defaultKmExpense: mapAmountToNumber(values.defaultKmExpense ?? 0),
      },
    });
    onUpdated();
  }, [project.id, getFormValues, projectUpdateInformationMutation, mapAmountToNumber, onUpdated]);

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={modal.onClose}
      closeOnOverlayClick={!projectUpdateInformationMutation.isPending}
      title={t('project:cards.information')}
    >
      <Form form={form}>
        <VStack spacing={4} align="stretch">
          <Flex direction="row">
            <TextField<EditProjectInformationsForm> name="name" label={t('project:fields.name')} isRequired>
              <Rule.IsRequired />
            </TextField>
          </Flex>

          <HStack spacing={4}>
            <TextField<EditProjectInformationsForm> name="refCode" label={t('project:fields.code')} />
            <TextField<EditProjectInformationsForm>
              name="purchaseOrderNumber"
              label={t('project:fields.purchaseOrderNumber')}
            />
          </HStack>

          <HStack spacing={4}>
            <DateField<EditProjectInformationsForm>
              name="startDate"
              label={t('project:fields.startDate')}
              width="2xs"
            />
            <DateField<EditProjectInformationsForm> name="endDate" label={t('project:fields.endDate')} width="2xs" />
          </HStack>

          <FieldGroupLabel>{t('project:editInformationModal.addressGroupLabel')}</FieldGroupLabel>
          <Flex direction="row">
            <TextAreaField<EditProjectInformationsForm>
              name="addressAddress"
              label={t('global:address.label')}
              isRequired
            >
              <Rule.IsRequired />
            </TextAreaField>
          </Flex>

          <HStack spacing={4}>
            <TextField<EditProjectInformationsForm> name="addressPostalCode" label={t('global:address.postalCode')} />
            <TextField<EditProjectInformationsForm> name="addressCity" label={t('global:address.city')} />
            <TextField<EditProjectInformationsForm> name="addressCountry" label={t('global:address.country')} />
          </HStack>

          <Flex direction="row">
            <CurrencyField<EditProjectInformationsForm>
              name="defaultKmExpense"
              label={t('project:fields.defaultKmExpense')}
            />
          </Flex>

          <Flex direction="row">
            <TextAreaField<EditProjectInformationsForm> name="note" label={t('project:fields.note')} />
          </Flex>

          <ColorPickerField<EditProjectInformationsForm>
            name="color"
            colors={PROJECT_COLOR_PICKER_COLORS}
            label={t('project:fields.projectColor')}
            isRequired
            helpIcon={<IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('project:fields.projectColorTooltip')} />}
          >
            <Rule.IsRequired />
          </ColorPickerField>
        </VStack>

        <Modal.Close variant="outline" onClick={modal.onClose} isDisabled={projectUpdateInformationMutation.isPending}>
          {t('global:words.c.back')}
        </Modal.Close>
        <Modal.PrimaryButton
          onClick={handleSubmit}
          isDisabled={!isFormValid}
          isLoading={projectUpdateInformationMutation.isPending}
        >
          {t('global:words.c.validate')}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
