import { useLayoutEffect, type FC } from 'react';
import { Tabs } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { PERMISSION } from '@graneet/business-logic';

import { DisplayNotFoundScreen } from '../DisplayNotFoundScreen';

import { ViewAccountingExportsScreen } from './ViewAccountingExportsScreen';
import { EditAccountingConfigScreen } from './EditAccountingConfigScreen';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const AccountingRouter: FC = () => {
  const { t } = useTranslation(['global', 'accounting']);
  const { updateHeaderTitle } = useHeaderContext();

  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  const canSeeAccountingModule = usePermissions([PERMISSION.DISPLAY_ACCOUNTING]);

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.accounting'), []);
  }, [t, updateHeaderTitle]);

  if (!canSeeAccountingModule) {
    return null;
  }

  return (
    <>
      <Tabs
        hasInternalPadding
        fallbackPath="./accounting-exports"
        data={[
          {
            id: 'accountingExport',
            title: t('accounting:tabs.accountingExport'),
            path: './accounting-exports',
            content: <ViewAccountingExportsScreen />,
          },
          ...(hasUpdateAccountingPermission
            ? [
                {
                  id: 'accountingConfig',
                  title: t('accounting:tabs.accountingConfig'),
                  path: './accounting-configs',
                  content: (
                    <QueryWrapper>
                      <EditAccountingConfigScreen />
                    </QueryWrapper>
                  ),
                },
              ]
            : []),
        ]}
        errorComponent={<DisplayNotFoundScreen />}
      />
    </>
  );
};
