/* eslint react/no-unstable-nested-components: 0 */
import { Form, HiddenField, useForm, useFormContext, useOnChangeValues } from 'graneet-form';
import {
  FEATURE_FLAGS,
  isNumberFinite,
  multiplyFloating,
  roundFloating,
  SUPPLIER_INVOICE_MODE,
  VAT_TYPE,
} from '@graneet/business-logic';
import type { SimpleTableProps } from '@graneet/lib-ui';
import {
  Button,
  Card,
  CurrencyField,
  IconAdvanced,
  NumberField,
  PercentageField,
  RequiredText,
  SimpleTable,
  TextField,
  useCurrency,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useState, useCallback, useMemo, useRef } from 'react';
import { v4 } from 'uuid';
import { Box, Flex } from '@chakra-ui/react';
import type { GridApi } from '@ag-grid-community/core';

import type { EditSupplierInvoiceForm } from '../../forms/edit-supplier-invoice.form';
import {
  buildSupplierInvoiceItemQuantityFieldName,
  buildSupplierInvoiceItemUnitFieldName,
  buildSupplierInvoiceItemUnitAmountExVATFieldName,
  buildSupplierInvoiceItemCodeFieldName,
  buildSupplierInvoiceItemTypeFieldName,
  buildSupplierInvoiceItemProjectFieldName,
  buildSupplierInvoiceItemPurchasesAccountFieldName,
  buildSupplierInvoiceItemVatRateFieldName,
  buildSupplierInvoiceItemAmountExVATFieldName,
  buildSupplierInvoiceItemDescriptionFieldName,
} from '../../forms/edit-supplier-invoice.form';

import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { AccountingConfigPurchasesAccountField } from 'features/accounting/components/AccountingConfigPurchasesAccountField';
import { ProjectAssociableField } from 'features/project/components/ProjectAssociableField';
import { Rule } from 'features/form/rules/Rule';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface BatchForm {
  type?: number;

  project?: number;

  purchasesAccount?: string;

  vat?: number;
}

const AmountExVATField: FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation(['supplierInvoices']);

  const { mapAmountToNumber } = useCurrency();

  const unitAmountExVATFieldName = buildSupplierInvoiceItemUnitAmountExVATFieldName(id);
  const quantityFieldName = buildSupplierInvoiceItemQuantityFieldName(id);
  const amountExVATFieldName = buildSupplierInvoiceItemAmountExVATFieldName(id);

  const form = useFormContext<EditSupplierInvoiceForm>();
  const {
    [unitAmountExVATFieldName]: unitAmountExVAT,
    [quantityFieldName]: quantity,
    [amountExVATFieldName]: amountExVAT,
  } = useOnChangeValues(form, [unitAmountExVATFieldName, quantityFieldName, amountExVATFieldName]);

  const amountExVATExpected =
    !isNumberFinite(unitAmountExVAT) ||
    !isNumberFinite(quantity) ||
    !isNumberFinite(amountExVAT) ||
    roundFloating(multiplyFloating(mapAmountToNumber(unitAmountExVAT), quantity)) === mapAmountToNumber(amountExVAT);

  return (
    <Flex direction="row" gap={2} alignItems="center">
      <CurrencyField<EditSupplierInvoiceForm> name={buildSupplierInvoiceItemAmountExVATFieldName(id)}>
        <Rule.IsRequired />
      </CurrencyField>
      <Box color="orange.500" opacity={amountExVATExpected ? 0 : 1}>
        <IconAdvanced
          tooltip={t('supplierInvoices:cards.itemsCard.toastUnexpectedAmountExVAT')}
          icon={<i className="ri-error-warning-line" />}
        />
      </Box>
    </Flex>
  );
};

export const SupplierInvoiceItemsCard = () => {
  const { t } = useTranslation(['supplierInvoices']);
  const form = useFormContext<EditSupplierInvoiceForm>();
  const { mode, itemIds, vatType } = useOnChangeValues(form, ['mode', 'itemIds', 'vatType']);

  const [selectedRows, setSelectRows] = useState<string[]>([]);
  const resetSelection = useRef<Pick<GridApi, 'deselectAll'>>({
    deselectAll: () => {},
  });
  const onRowSelected = useCallback<NonNullable<SimpleTableProps<string>['onRowSelected']>>((e) => {
    setSelectRows(e.api.getSelectedRows());
    resetSelection.current = e.api;
  }, []);

  const batchForm = useForm<BatchForm>();
  const applyBatchUpdates = useCallback(() => {
    const formValues = batchForm.getFormValues();

    const newFormValues: Partial<EditSupplierInvoiceForm> = {};
    selectedRows.forEach((row) => {
      if (formValues.project) {
        newFormValues[buildSupplierInvoiceItemProjectFieldName(row)] = formValues.project;
      }
      if (formValues.type) {
        newFormValues[buildSupplierInvoiceItemTypeFieldName(row)] = formValues.type;
      }
      if (formValues.purchasesAccount) {
        newFormValues[buildSupplierInvoiceItemPurchasesAccountFieldName(row)] = formValues.purchasesAccount;
      }
      if (isNumberFinite(formValues.vat)) {
        newFormValues[buildSupplierInvoiceItemVatRateFieldName(row)] = formValues.vat;
      }
    });

    form.setFormValues(newFormValues);
    batchForm.resetForm();
    resetSelection.current.deselectAll();
  }, [batchForm, form, selectedRows]);

  const addItem = useCallback(() => {
    form.setFormValues({
      itemIds: [...(form.getFormValues().itemIds ?? []), v4()],
    });
    resetSelection.current.deselectAll();
  }, [form]);

  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();

  const focusedField = useRef<keyof EditSupplierInvoiceForm | null>(null);
  /*
    PRODUCT SPEC: when quantity or unit price is updated, update the total price.
    But we do not make updates when the total price is updated.
    By consequence, we can have `unitPrice x quantity != price` and this is wanted to allow flexibility.
   */
  const onFieldChange = useCallback(
    (id: string, field: 'quantity' | 'unitPrice') => (value: number | null | undefined) => {
      const quantity =
        field === 'quantity' ? value : form.getFormValues()[buildSupplierInvoiceItemQuantityFieldName(id)];
      const unitPrice =
        field === 'unitPrice' ? value : form.getFormValues()[buildSupplierInvoiceItemUnitAmountExVATFieldName(id)];

      // If the field has not been updated through a user input
      if (
        focusedField.current !== buildSupplierInvoiceItemUnitAmountExVATFieldName(id) &&
        focusedField.current !== buildSupplierInvoiceItemQuantityFieldName(id)
      ) {
        return;
      }

      // If the quantity or the unit price is not defined, do noting, we cannot compute the total price
      if (!isNumberFinite(quantity) || !isNumberFinite(unitPrice)) {
        return;
      }

      const newFormValues: Partial<EditSupplierInvoiceForm> = {};
      newFormValues[buildSupplierInvoiceItemAmountExVATFieldName(id)] = mapNumberToAmount(
        roundFloating(multiplyFloating(mapAmountToNumber(unitPrice), quantity)),
      );
      form.setFormValues(newFormValues);
    },
    [form, mapAmountToNumber, mapNumberToAmount],
  );

  const onFieldFocus = useCallback(
    (fieldName: keyof EditSupplierInvoiceForm) => () => {
      focusedField.current = fieldName;
    },
    [],
  );

  const onBlurBlur = useCallback(() => {
    focusedField.current = null;
  }, []);

  const removeLine = useCallback(
    (id: string) => () => {
      form.setFormValues({
        itemIds: (form.getFormValues().itemIds ?? []).filter((itemId) => itemId !== id),
      });
      resetSelection.current.deselectAll();
    },
    [form],
  );

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  const columnDefs = useMemo(
    (): SimpleTableProps<string>['columnDefs'] => [
      {
        field: 'itemCode' as any,
        headerName: t('supplierInvoices:fields.itemCode'),
        minWidth: 150,
        cellRenderer: (props) =>
          props.data ? (
            <TextField<EditSupplierInvoiceForm> name={buildSupplierInvoiceItemCodeFieldName(props.data)} />
          ) : null,
      },
      {
        field: 'itemDescription' as any,
        headerComponent: () => <RequiredText>{t('supplierInvoices:fields.itemDescription')}</RequiredText>,
        minWidth: 250,
        cellRenderer: (props) =>
          props.data ? (
            <TextField<EditSupplierInvoiceForm> name={buildSupplierInvoiceItemDescriptionFieldName(props.data)}>
              <Rule.IsRequired />
            </TextField>
          ) : null,
      },
      {
        field: 'itemUnit' as any,
        headerName: t('supplierInvoices:fields.itemUnit'),
        cellRenderer: (props) =>
          props.data ? (
            <TextField<EditSupplierInvoiceForm> name={buildSupplierInvoiceItemUnitFieldName(props.data)} />
          ) : null,
      },
      {
        field: 'itemUnitAmountExVAT' as any,
        headerComponent: () => <RequiredText>{t('supplierInvoices:fields.itemUnitAmountExVAT')}</RequiredText>,
        minWidth: 250,
        cellRenderer: (props) =>
          props.data ? (
            <CurrencyField<EditSupplierInvoiceForm>
              name={buildSupplierInvoiceItemUnitAmountExVATFieldName(props.data)}
              onChange={onFieldChange(props.data, 'unitPrice')}
              onFocus={onFieldFocus(buildSupplierInvoiceItemUnitAmountExVATFieldName(props.data))}
              onBlur={onBlurBlur}
            >
              <Rule.IsRequired />
            </CurrencyField>
          ) : null,
      },
      {
        field: 'itemQuantity' as any,
        headerComponent: () => <RequiredText>{t('supplierInvoices:fields.itemQuantity')}</RequiredText>,
        cellRenderer: (props) =>
          props.data ? (
            <NumberField<EditSupplierInvoiceForm>
              name={buildSupplierInvoiceItemQuantityFieldName(props.data)}
              onChange={onFieldChange(props.data, 'quantity')}
              onFocus={onFieldFocus(buildSupplierInvoiceItemQuantityFieldName(props.data))}
              onBlur={onBlurBlur}
            >
              <Rule.IsRequired />
            </NumberField>
          ) : null,
      },
      {
        field: 'itemAmountVatRate' as any,
        headerComponent: () => <RequiredText>{t('supplierInvoices:fields.itemAmountVatRate')}</RequiredText>,
        cellRenderer: (props) => {
          if (!props.data) {
            return null;
          }

          if (vatType === VAT_TYPE.INTRA_COMMUNITY || vatType === VAT_TYPE.REVERSE_CHARGE) {
            return '-';
          }

          if (hasStandardVATRates) {
            return (
              <AccountingVATField<EditSupplierInvoiceForm>
                name={buildSupplierInvoiceItemVatRateFieldName(props.data)}
                valueScale={100}
              >
                <Rule.IsRequired />
              </AccountingVATField>
            );
          }

          return (
            <PercentageField<EditSupplierInvoiceForm> name={buildSupplierInvoiceItemVatRateFieldName(props.data)}>
              <Rule.IsRequired />
            </PercentageField>
          );
        },
      },
      {
        field: 'itemAmountExVAT' as any,
        headerComponent: () => <RequiredText>{t('supplierInvoices:fields.itemAmountExVAT')}</RequiredText>,
        minWidth: 250,
        cellRenderer: (props) => (props.data ? <AmountExVATField id={props.data} /> : null),
      },
      {
        field: 'itemType' as any,
        headerComponent: () => <RequiredText>{t('supplierInvoices:fields.itemType')}</RequiredText>,
        minWidth: 250,
        flex: 2,
        cellRenderer: (props) =>
          props.data ? (
            <ComponentTypeField<EditSupplierInvoiceForm>
              name={buildSupplierInvoiceItemTypeFieldName(props.data)}
              material
              isRequired
            >
              <Rule.IsRequired />
            </ComponentTypeField>
          ) : null,
      },
      {
        field: 'itemPurchasesAccount' as any,
        headerName: t('supplierInvoices:fields.itemPurchasesAccount'),
        minWidth: 250,
        cellRenderer: (props) =>
          props.data ? (
            <AccountingConfigPurchasesAccountField<EditSupplierInvoiceForm>
              name={buildSupplierInvoiceItemPurchasesAccountFieldName(props.data)}
            />
          ) : null,
      },
      {
        field: 'itemProject' as any,
        headerName: t('supplierInvoices:fields.itemProject'),
        minWidth: 350,
        cellRenderer: (props) =>
          props.data ? (
            <ProjectAssociableField<EditSupplierInvoiceForm>
              name={buildSupplierInvoiceItemProjectFieldName(props.data)}
            />
          ) : null,
      },
      {
        field: 'remove' as any,
        headerName: '',
        minWidth: 50,
        cellRenderer: (props) =>
          props.data && itemIds && itemIds?.length > 1 ? (
            <Flex color="red.500" onClick={removeLine(props.data)} cursor="pointer">
              <i className="ri-delete-bin-line" />
            </Flex>
          ) : null,
      },
    ],
    [hasStandardVATRates, itemIds, onBlurBlur, onFieldChange, onFieldFocus, removeLine, t, vatType],
  );

  if (mode !== SUPPLIER_INVOICE_MODE.DETAILED) {
    return null;
  }

  return (
    <Card title={t('supplierInvoices:cards.itemsCard.title')}>
      <HiddenField<EditSupplierInvoiceForm> name="itemIds">
        <Rule.IsRequired />
      </HiddenField>

      {selectedRows.length > 0 && (
        <Form form={batchForm}>
          <Flex direction="row" gap={3} alignItems="center" mb={2}>
            <Box flex={2}>
              <ComponentTypeField<BatchForm>
                name="type"
                material
                isRequired
                placeholder={t('supplierInvoices:fields.itemType')}
              />
            </Box>

            <Box flex={2}>
              <AccountingConfigPurchasesAccountField<BatchForm>
                name="purchasesAccount"
                placeholder={t('supplierInvoices:fields.itemPurchasesAccount')}
              />
            </Box>

            <Box flex={2}>
              <ProjectAssociableField<BatchForm>
                name="project"
                placeholder={t('supplierInvoices:fields.itemProject')}
              />
            </Box>

            <Box flex={1}>
              {hasStandardVATRates ? (
                <AccountingVATField<BatchForm>
                  name="vat"
                  valueScale={100}
                  placeholder={t('supplierInvoices:fields.itemAmountVatRate')}
                />
              ) : (
                <PercentageField<BatchForm> name="vat" placeholder={t('supplierInvoices:fields.itemAmountVatRate')} />
              )}
            </Box>

            <Button onClick={applyBatchUpdates}>
              {t('supplierInvoices:cards.itemsCard.updateSelectedItems', { count: selectedRows.length })}
            </Button>
          </Flex>
        </Form>
      )}

      <Flex direction="column" gap={6}>
        <Box>
          <SimpleTable
            rowSelection="multiple"
            gridId="supplier-invoice-items"
            columnDefs={columnDefs}
            rowData={itemIds}
            onRowSelected={onRowSelected}
          />
        </Box>
        <Button onClick={addItem}>{t('supplierInvoices:actions.addItem')}</Button>
      </Flex>
    </Card>
  );
};
