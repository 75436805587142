import type { PaginatedTableProps } from '@graneet/lib-ui';
import { Button, generateColorFromString, ListingLayout, PaginatedTable } from '@graneet/lib-ui';
import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ProjectFinancialSummaryPaginatedResponse, ProjectPaginatedResponse } from '@graneet/business-logic';
import { EXPORT_ENTITY, FEATURE_FLAGS, PERMISSION, PROJECT_STATUSES } from '@graneet/business-logic';
import { useHistory } from 'react-router-dom';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { ExportButton } from 'features/export/components/ExportButton/ExportButton';
import { formatUserDisplayName } from 'features/user/services/user.util';
import { useDisplayedUsers } from 'features/user/services/user.api';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';
import { PROJECT_FIELDS, PROJECT_STATUS_COLOR } from 'features/project/constants/project.constant';
import {
  useProjectAvailableTags,
  useProjects,
  useProjectsFinancialSummary,
} from 'features/project/services/project.api';
import { useAppContext } from 'features/app/contexts/AppContext';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { PaginatedTableSettings } from 'features/common/components/PaginatedTableSettings';
import { CustomAgGridHeaderTooltip } from 'features/common/components/CustomAgGridHeaderTooltip';
import { ProjectTableAmountExVATCell } from 'features/project/components/tableCells/ProjectTableAmountExVATCell';
import { ProjectTableAmountToBeBilledExVATCell } from 'features/project/components/tableCells/ProjectTableAmountToBeBilledExVATCell';
import { ProjectTableConsumedBudgetPercentageCell } from 'features/project/components/tableCells/ProjectTableConsumedBudgetPercentageCell';
import { ProjectTableNameCell } from 'features/project/components/tableCells/ProjectTableNameCell';
import { ProjectTableProgressCell } from 'features/project/components/tableCells/ProjectTableProgressCell';
import { ProjectTableProjectAccountManagersCell } from 'features/project/components/tableCells/ProjectTableProjectAccountManagersCell';
import { ProjectTableTheoreticalDisbursementExVATMarginCell } from 'features/project/components/tableCells/ProjectTableTheoreticalDisbursementExVATMarginCell';
import { ProjectTableTotalCommittedExcludingVATCell } from 'features/project/components/tableCells/ProjectTableTotalCommittedExcludingVATCell';
import { ProjectTableTotalDisbursementExVATCell } from 'features/project/components/tableCells/ProjectTableTotalDisbursementExVATCell';
import { ProjectTableTotalExpenseAmountExVATCell } from 'features/project/components/tableCells/ProjectTableTotalExpenseAmountExVATCell';
import { ProjectTableTotalRemainingToSpendCell } from 'features/project/components/tableCells/ProjectTableTotalRemainingToSpendCell';
import { ProjectTableTotalSupplierInvoiceAmountExVATCell } from 'features/project/components/tableCells/ProjectTableTotalSupplierInvoiceAmountExVATCell';
import { ProjectTableTotalSupplierInvoicesRemainingToBePaidIncVATCell } from 'features/project/components/tableCells/ProjectTableTotalSupplierInvoicesRemainingToBePaidIncVATCell';
import { ProjectTableTotalTimeSlotNbMinutesCell } from 'features/project/components/tableCells/ProjectTableTotalTimeSlotNbMinutesCell';
import { ProjectTableTotalTimeSlotNbMinutesRemainingCell } from 'features/project/components/tableCells/ProjectTableTotalTimeSlotNbMinutesRemainingCell';
import { ProjectTableTotalTimeSlotPriceExVATCell } from 'features/project/components/tableCells/ProjectTableTotalTimeSlotPriceExVATCell';
import { ProjectTableTotalWorkforceAmountExVATRemainingCell } from 'features/project/components/tableCells/ProjectTableTotalWorkforceAmountExVATRemainingCell';
import { ProjectTableTotalBilledAmountExVATCell } from 'features/project/components/tableCells/ProjectTableTotalBilledAmountExVATCell';
import { ProjectTableTotalBillsRemainingToBePaidIncVATCell } from 'features/project/components/tableCells/ProjectTableTotalBillsRemainingToBePaidIncVATCell';
import { ProjectTableTotalBudgetAmountExVATCell } from 'features/project/components/tableCells/ProjectTableTotalBudgetAmountExVATCell';
import { ProjectTableTotalWorkforceCell } from 'features/project/components/tableCells/ProjectTableTotalWorkforceCell';
import { ProjectTableTotalBudgetNbMinutesCell } from 'features/project/components/tableCells/ProjectTableTotalBudgetNbMinutesCell';
import { ProjectTableTotalWorkforceBudgetAmountExVATCell } from 'features/project/components/tableCells/ProjectTableTotalWorkforceBudgetAmountExVATCell';
import { ProjectTableTotalOrderAmountExVATCell } from 'features/project/components/tableCells/ProjectTableTotalOrderAmountExVATCell';
import { ProjectTableTheoreticalDisbursementExVATMarginPercentageCell } from 'features/project/components/tableCells/ProjectTableTheoreticalDisbursementExVATMarginPercentageCell';
import { ProjectTableTheoreticalGrossMarginCell } from 'features/project/components/tableCells/ProjectTableTheoreticalGrossMarginCell';
import { ProjectTableTheoreticalGrossMarginPercentageCell } from 'features/project/components/tableCells/ProjectTableTheoreticalGrossMarginPercentageCell';
import { ProjectTableMarginOnInvoicedAmountsCell } from 'features/project/components/tableCells/ProjectTableMarginOnInvoicedAmountsCell';
import { ProjectTableMarginOnInvoicedAmountsPercentageCell } from 'features/project/components/tableCells/ProjectTableMarginOnInvoicedAmountsPercentageCell';
import { ProjectTableMarginOnTotalCommittedExcludingVATCell } from 'features/project/components/tableCells/ProjectTableMarginOnTotalCommittedExcludingVATCell';
import { ProjectTableMarginOnTotalCommittedExcludingVATPercentageCell } from 'features/project/components/tableCells/ProjectTableMarginOnTotalCommittedExcludingVATPercentageCell';

const SCREEN_PERMISSIONS = [PERMISSION.VIEW_ALL_PROJECTS];

const GRID_ID = 'project';

export const ViewProjectsScreen = () => {
  const { t } = useTranslation(['global', 'project', 'accountManager']);
  const hasProjectFinancialSummaryFF = useFeatureFlag(FEATURE_FLAGS.PROJECT_FINANCIAL_SUMMARY);

  // @[ff: project-financial-summary]
  const oldDefaultColumnDefs = useMemo<PaginatedTableProps<ProjectPaginatedResponse>['columnDefs']>(
    () => [
      {
        lockPosition: 'left',
        suppressMovable: true,
        sortable: true,
        headerName: t('project:fields.code'),
        field: 'refCode',
        width: 100,
      },
      {
        sortable: true,
        headerName: t('project:fields.name'),
        field: 'name',
        autoHeight: true,
        cellRenderer: ProjectTableNameCell,
      },
      {
        sortable: true,
        headerName: t('project:fields.client'),
        field: 'primaryClient' as const,
        cellRenderer: (props) => props.data?.primaryClient.enterpriseName,
      },
      {
        sortable: true,
        headerName: t('project:fields.totalAmountExVAT'),
        field: 'amountExVAT',
        width: 150,
        cellRenderer: ProjectTableAmountExVATCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.amountToBeBilledExVAT'),
        field: 'amountToBeBilledExVAT',
        width: 160,
        cellRenderer: ProjectTableAmountToBeBilledExVATCell,
      },
      {
        sortable: true,
        headerName: t('project:fields.progress'),
        field: 'cumulativeProgressPercentage',
        width: 160,
        cellRenderer: ProjectTableProgressCell,
      },
      {
        headerName: t('accountManager:title'),
        field: 'projectAccountManagers',
        width: 100,
        cellRenderer: ProjectTableProjectAccountManagersCell,
      },
    ],
    [t],
  );

  const defaultColumnDefs = useMemo<PaginatedTableProps<ProjectFinancialSummaryPaginatedResponse>['columnDefs']>(
    () => [
      {
        lockPosition: 'left',
        suppressMovable: true,
        sortable: true,
        headerName: t('project:fields.code'),
        field: 'refCode',
        width: 100,
      },
      {
        sortable: true,
        headerName: t('project:fields.name'),
        field: 'name',
        autoHeight: true,
        cellRenderer: ProjectTableNameCell,
      },
      {
        sortable: true,
        headerName: t('project:fields.client'),
        field: 'enterpriseName' as const,
        cellRenderer: (props) => props.data?.enterpriseName,
      },
      {
        sortable: true,
        headerName: t('project:fields.totalAmountExVAT'),
        field: 'amountExVAT',
        width: 150,
        type: 'rightAligned',
        cellRenderer: ProjectTableAmountExVATCell,
      },
      {
        sortable: true,
        headerName: t('project:fields.progress'),
        field: 'cumulativeProgressPercentage',
        width: 160,
        cellRenderer: ProjectTableProgressCell,
      },
      {
        headerName: t('accountManager:title'),
        field: 'projectAccountManagers',
        width: 100,
        cellRenderer: ProjectTableProjectAccountManagersCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.totalDisbursementExVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalDisbursementExVAT') },
        headerTooltip: t('project:table.columns.totalDisbursementExVAT'),
        field: 'totalDisbursementExVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalDisbursementExVATCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.totalBudgetAmountExVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalBudgetAmountExVAT') },
        headerTooltip: t('project:table.columns.totalBudgetAmountExVAT'),
        field: 'totalBudgetAmountExVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalBudgetAmountExVATCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.totalWorkforce'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalWorkforce') },
        headerTooltip: t('project:table.columns.totalWorkforce'),
        field: 'totalWorkforce',
        width: 100,
        cellRenderer: ProjectTableTotalWorkforceCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.totalBudgetNbMinutes'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalBudgetNbMinutes') },
        headerTooltip: t('project:table.columns.totalBudgetNbMinutes'),
        field: 'totalBudgetNbMinutes',
        width: 100,
        cellRenderer: ProjectTableTotalBudgetNbMinutesCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.totalWorkforceBudgetAmountExVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalWorkforceBudgetAmountExVAT') },
        headerTooltip: t('project:table.columns.totalWorkforceBudgetAmountExVAT'),
        field: 'totalWorkforceBudgetAmountExVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalWorkforceBudgetAmountExVATCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.totalBilledAmountExVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalBilledAmountExVAT') },
        headerTooltip: t('project:table.columns.totalBilledAmountExVAT'),
        field: 'totalBilledAmountExVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalBilledAmountExVATCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.amountToBeBilledExVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.amountToBeBilledExVAT') },
        headerTooltip: t('project:table.columns.amountToBeBilledExVAT'),
        field: 'amountToBeBilledExVAT',
        width: 160,
        type: 'rightAligned',
        cellRenderer: ProjectTableAmountToBeBilledExVATCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.totalBillsRemainingToBePaidIncVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalBillsRemainingToBePaidIncVAT') },
        headerTooltip: t('project:table.columns.totalBillsRemainingToBePaidIncVAT'),
        field: 'totalBillsRemainingToBePaidIncVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalBillsRemainingToBePaidIncVATCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.totalOrderAmountExVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalOrderAmountExVAT') },
        headerTooltip: t('project:table.columns.totalOrderAmountExVAT'),
        field: 'totalOrderAmountExVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalOrderAmountExVATCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.totalTimeSlotNbMinutes'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalTimeSlotNbMinutes') },
        headerTooltip: t('project:table.columns.totalTimeSlotNbMinutes'),
        field: 'totalTimeSlotNbMinutes',
        width: 100,
        cellRenderer: ProjectTableTotalTimeSlotNbMinutesCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.totalTimeSlotPriceExVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalTimeSlotPriceExVAT') },
        headerTooltip: t('project:table.columns.totalTimeSlotPriceExVAT'),
        field: 'totalTimeSlotPriceExVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalTimeSlotPriceExVATCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.totalTimeSlotNbMinutesRemaining'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalTimeSlotNbMinutesRemaining') },
        headerTooltip: t('project:table.columns.totalTimeSlotNbMinutesRemaining'),
        field: 'totalTimeSlotNbMinutesRemaining',
        width: 100,
        cellRenderer: ProjectTableTotalTimeSlotNbMinutesRemainingCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.totalWorkforceAmountExVATRemaining'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalWorkforceAmountExVATRemaining') },
        headerTooltip: t('project:table.columns.totalWorkforceAmountExVATRemaining'),
        field: 'totalWorkforceAmountExVATRemaining',
        width: 100,
        cellRenderer: ProjectTableTotalWorkforceAmountExVATRemainingCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.totalCommittedExcludingVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalCommittedExcludingVAT') },
        headerTooltip: t('project:table.columns.totalCommittedExcludingVAT'),
        field: 'totalCommittedExcludingVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalCommittedExcludingVATCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.totalSupplierInvoiceAmountExVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalSupplierInvoiceAmountExVAT') },
        headerTooltip: t('project:table.columns.totalSupplierInvoiceAmountExVAT'),
        field: 'totalSupplierInvoiceAmountExVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalSupplierInvoiceAmountExVATCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.totalSupplierInvoicesRemainingToBePaidIncVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalSupplierInvoicesRemainingToBePaidIncVAT') },
        headerTooltip: t('project:table.columns.totalSupplierInvoicesRemainingToBePaidIncVAT'),
        field: 'totalSupplierInvoicesRemainingToBePaidIncVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalSupplierInvoicesRemainingToBePaidIncVATCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.totalExpenseAmountExVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalExpenseAmountExVAT') },
        headerTooltip: t('project:table.columns.totalExpenseAmountExVAT'),
        field: 'totalExpenseAmountExVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalExpenseAmountExVATCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.totalRemainingToSpend'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.totalRemainingToSpend') },
        headerTooltip: t('project:table.columns.totalRemainingToSpend'),
        field: 'totalRemainingToSpend',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTotalRemainingToSpendCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.consumedBudgetPercentage'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.consumedBudgetPercentage') },
        headerTooltip: t('project:table.columns.consumedBudgetPercentage'),
        field: 'consumedBudgetPercentage',
        width: 100,
        cellRenderer: ProjectTableConsumedBudgetPercentageCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.theoreticalDisbursementExVATMargin'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.theoreticalDisbursementExVATMargin') },
        headerTooltip: t('project:table.columns.theoreticalDisbursementExVATMargin'),
        field: 'theoreticalDisbursementExVATMargin',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTheoreticalDisbursementExVATMarginCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.theoreticalDisbursementExVATMarginPercentage'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.theoreticalDisbursementExVATMarginPercentage') },
        headerTooltip: t('project:table.columns.theoreticalDisbursementExVATMarginPercentage'),
        field: 'theoreticalDisbursementExVATMarginPercentage',
        width: 100,
        cellRenderer: ProjectTableTheoreticalDisbursementExVATMarginPercentageCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.theoreticalGrossMargin'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.theoreticalGrossMargin') },
        headerTooltip: t('project:table.columns.theoreticalGrossMargin'),
        field: 'theoreticalGrossMargin',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableTheoreticalGrossMarginCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.theoreticalGrossMarginPercentage'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.theoreticalGrossMarginPercentage') },
        headerTooltip: t('project:table.columns.theoreticalGrossMarginPercentage'),
        field: 'theoreticalGrossMarginPercentage',
        width: 100,
        cellRenderer: ProjectTableTheoreticalGrossMarginPercentageCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.marginOnInvoicedAmounts'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.marginOnInvoicedAmounts') },
        headerTooltip: t('project:table.columns.marginOnInvoicedAmounts'),
        field: 'marginOnInvoicedAmounts',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableMarginOnInvoicedAmountsCell,
      },
      {
        sortable: true,
        headerName: t('project:table.columns.marginOnInvoicedAmountsPercentage'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.marginOnInvoicedAmountsPercentage') },
        headerTooltip: t('project:table.columns.marginOnInvoicedAmountsPercentage'),
        field: 'marginOnInvoicedAmountsPercentage',
        width: 100,
        cellRenderer: ProjectTableMarginOnInvoicedAmountsPercentageCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.marginOnTotalCommittedExcludingVAT'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.marginOnTotalCommittedExcludingVAT') },
        headerTooltip: t('project:table.columns.marginOnTotalCommittedExcludingVAT'),
        field: 'marginOnTotalCommittedExcludingVAT',
        width: 100,
        type: 'rightAligned',
        cellRenderer: ProjectTableMarginOnTotalCommittedExcludingVATCell,
      },
      {
        sortable: true,
        hide: true,
        headerName: t('project:table.columns.marginOnTotalCommittedExcludingVATPercentage'),
        tooltipComponent: CustomAgGridHeaderTooltip,
        tooltipComponentParams: { content: t('project:table.tooltip.marginOnTotalCommittedExcludingVATPercentage') },
        headerTooltip: t('project:table.columns.marginOnTotalCommittedExcludingVATPercentage'),
        field: 'marginOnTotalCommittedExcludingVATPercentage',
        width: 100,
        cellRenderer: ProjectTableMarginOnTotalCommittedExcludingVATPercentageCell,
      },
    ],
    [t],
  );

  const { currentUser } = useAppContext();
  const hasPermissions = usePermissions(SCREEN_PERMISSIONS);
  const projects = useProjectsFinancialSummary({
    forcedFilters:
      hasPermissions || !currentUser?.id
        ? undefined
        : {
            [PROJECT_FIELDS.ACCOUNT_MANAGERS]: [currentUser.id],
          },
  });

  // @[ff: project-financial-summary]
  const oldProjects = useProjects({
    forcedFilters:
      hasPermissions || !currentUser?.id
        ? undefined
        : {
            [PROJECT_FIELDS.ACCOUNT_MANAGERS]: [currentUser.id],
          },
  });

  const isExportEnabled = useFeatureFlag(FEATURE_FLAGS.EXPORT_TABLES);

  const createProjectsButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_PROJECTS]);

  const history = useHistory();

  const goToCreation = useCallback(() => {
    history.push('/projects/create');
  }, [history]);

  // Filters
  const availableStatuses = useMemo(
    () =>
      (Object.keys(PROJECT_STATUSES) as Array<PROJECT_STATUSES>)
        .filter((status) => status !== PROJECT_STATUSES.ARCHIVED)
        .map((status) => ({
          value: status,
          colorScheme: PROJECT_STATUS_COLOR[status],
          label: t(`project:status.${status}`),
        })),
    [t],
  );

  const users = useDisplayedUsers();
  const availableUsers = useMemo(
    () =>
      users.data.map<{ value: string; label: string }>((user) => ({
        value: user.id.toString(),
        label: formatUserDisplayName(user),
      })),
    [users],
  );

  const tags = useProjectAvailableTags();
  const availableTags = useMemo(
    () =>
      tags.data.map<{ value: string; label: string }>((tag) => ({
        value: tag,
        label: tag,
        badgeColor: generateColorFromString(tag),
      })),
    [tags.data],
  );

  const selectionComponentProps = useMemo(
    (): PaginatedTableProps<ProjectFinancialSummaryPaginatedResponse>['selectionComponentProps'] => ({
      allSelectedLabel: (totalNumberOfItems?: number): string =>
        t('project:banner.allSelectedLabel', { count: totalNumberOfItems }),
      selectLabel: (numberSelectedItems: number) => t('project:banner.selection', { count: numberSelectedItems }),
      renderActions: (props) => <ExportButton entity={EXPORT_ENTITY.PROJECT} {...props} />,
    }),
    [t],
  );

  // @[ff: project-financial-summary]
  const oldSelectionComponentProps = useMemo(
    (): PaginatedTableProps<ProjectPaginatedResponse>['selectionComponentProps'] => ({
      allSelectedLabel: (totalNumberOfItems?: number): string =>
        t('project:banner.allSelectedLabel', { count: totalNumberOfItems }),
      selectLabel: (numberSelectedItems: number) => t('project:banner.selection', { count: numberSelectedItems }),
      renderActions: (props) => <ExportButton entity={EXPORT_ENTITY.PROJECT_OLD} {...props} />,
    }),
    [t],
  );

  const { updateHeaderTitle } = useHeaderContext();
  useLayoutEffect(() => {
    updateHeaderTitle(t('project:myProjects'), []);
  }, [t, updateHeaderTitle]);

  return (
    // @[ff: project-financial-summary]
    hasProjectFinancialSummaryFF ? (
      <ListingLayout
        pagination={projects}
        search={{
          placeholder: t('project:search'),
        }}
        filters={[
          {
            type: 'checkbox',
            name: 'status',
            availableValues: availableStatuses,
            label: t('project:fields.status'),
          },
          {
            type: 'multi',
            name: 'projectAccountManagers',
            availableValues: availableUsers,
            label: t('accountManager:title'),
            placeholder: t('global:words.c.select'),
          },
          {
            type: 'multi',
            name: 'tags',
            availableValues: availableTags,
            label: t('global:words.tags'),
            noValueMessage: t('global:tags.noLabel'),
            placeholder: t('global:words.c.select'),
          },
        ]}
        actions={
          <Button onClick={goToCreation} {...createProjectsButtonDisabledProps()}>
            {t('project:actions.create')}
          </Button>
        }
        leftActions={
          <PaginatedTableSettings<ProjectFinancialSummaryPaginatedResponse>
            gridId={GRID_ID}
            columnDefs={defaultColumnDefs}
            groupedData={{
              label: t('global:table.chooseColumns'),
              items: [
                {
                  label: t('project:table.setting.generalData'),
                  items: [
                    {
                      key: 'refCode',
                      isDisabled: true,
                    },
                    { key: 'name' },
                    { key: 'enterpriseName' },
                    { key: 'amountExVAT' },
                    { key: 'projectAccountManagers' },
                  ],
                },
                {
                  label: t('project:table.setting.quotation_estimation'),
                  items: [{ key: 'totalDisbursementExVAT' }, { key: 'totalWorkforce' }],
                },
                {
                  label: t('project:table.setting.budget'),
                  items: [
                    { key: 'totalBudgetAmountExVAT' },
                    { key: 'totalBudgetNbMinutes' },
                    { key: 'totalWorkforceBudgetAmountExVAT' },
                  ],
                },
                {
                  label: t('project:table.setting.revenues'),
                  items: [
                    { key: 'cumulativeProgressPercentage' },
                    { key: 'totalBilledAmountExVAT' },
                    { key: 'amountToBeBilledExVAT' },
                    { key: 'totalBillsRemainingToBePaidIncVAT' },
                  ],
                },
                {
                  label: t('project:table.setting.expenses'),
                  items: [
                    { key: 'totalOrderAmountExVAT' },
                    { key: 'totalTimeSlotNbMinutes' },
                    { key: 'totalTimeSlotPriceExVAT' },
                    { key: 'totalTimeSlotNbMinutesRemaining' },
                    { key: 'totalWorkforceAmountExVATRemaining' },
                    { key: 'totalCommittedExcludingVAT' },
                    { key: 'totalSupplierInvoiceAmountExVAT' },
                    { key: 'totalSupplierInvoicesRemainingToBePaidIncVAT' },
                    { key: 'totalExpenseAmountExVAT' },
                    { key: 'totalRemainingToSpend' },
                    { key: 'consumedBudgetPercentage' },
                  ],
                },
                {
                  label: t('project:table.setting.margins'),
                  items: [
                    { key: 'theoreticalDisbursementExVATMargin' },
                    { key: 'theoreticalDisbursementExVATMarginPercentage' },
                    { key: 'theoreticalGrossMargin' },
                    { key: 'theoreticalGrossMarginPercentage' },
                    { key: 'marginOnInvoicedAmounts' },
                    { key: 'marginOnInvoicedAmountsPercentage' },
                    { key: 'marginOnTotalCommittedExcludingVAT' },
                    { key: 'marginOnTotalCommittedExcludingVATPercentage' },
                  ],
                },
              ],
            }}
          />
        }
        content={
          <PaginatedTable
            gridId={GRID_ID}
            columnDefs={defaultColumnDefs}
            rowSelection={isExportEnabled ? 'multiple' : undefined}
            pagination={projects}
            countLabel={(count) => t('project:project', { count })}
            sums={{
              amountExVAT: {
                label: t('project:fields.totalAmountExVAT'),
                type: 'currency',
              },
              amountToBeBilledExVAT: {
                label: t('project:table.columns.amountToBeBilledExVAT'),
                type: 'currency',
              },
            }}
            onRowClicked={(event) => {
              history.push(`/projects/${event.data?.id}`);
            }}
            zeroState={{
              icon: <i className="ri-building-line ri-4x" />,
              label: t('project:noProject'),
            }}
            emptyState={{
              label: t('project:noResult'),
            }}
            selectionComponentProps={selectionComponentProps}
          />
        }
      />
    ) : (
      <ListingLayout
        pagination={oldProjects}
        search={{
          placeholder: t('project:search'),
        }}
        filters={[
          {
            type: 'checkbox',
            name: 'status',
            availableValues: availableStatuses,
            label: t('project:fields.status'),
          },
          {
            type: 'multi',
            name: 'projectAccountManagers',
            availableValues: availableUsers,
            label: t('accountManager:title'),
            placeholder: t('global:words.c.select'),
          },
          {
            type: 'multi',
            name: 'tags',
            availableValues: availableTags,
            label: t('global:words.tags'),
            noValueMessage: t('global:tags.noLabel'),
            placeholder: t('global:words.c.select'),
          },
        ]}
        actions={
          <Button onClick={goToCreation} {...createProjectsButtonDisabledProps()}>
            {t('project:actions.create')}
          </Button>
        }
        leftActions={
          <PaginatedTableSettings<ProjectPaginatedResponse>
            gridId={GRID_ID}
            columnDefs={oldDefaultColumnDefs}
            groupedData={{
              label: t('global:table.chooseColumns'),
              items: [
                {
                  key: 'refCode',
                  isDisabled: true,
                },
                { key: 'name' },
                { key: 'primaryClient' },
                { key: 'amountExVAT' },
                { key: 'amountToBeBilledExVAT' },
                { key: 'cumulativeProgressPercentage' },
                { key: 'projectAccountManagers' },
              ],
            }}
          />
        }
        content={
          <PaginatedTable
            gridId={GRID_ID}
            columnDefs={oldDefaultColumnDefs}
            rowSelection={isExportEnabled ? 'multiple' : undefined}
            pagination={oldProjects}
            countLabel={(count) => t('project:project', { count })}
            sums={{
              amountExVAT: {
                label: t('project:fields.totalAmountExVAT'),
                type: 'currency',
              },
              amountToBeBilledExVAT: {
                label: t('project:table.columns.amountToBeBilledExVAT'),
                type: 'currency',
              },
            }}
            onRowClicked={(event) => {
              history.push(`/projects/${event.data?.id}`);
            }}
            zeroState={{
              icon: <i className="ri-building-line ri-4x" />,
              label: t('project:noProject'),
            }}
            emptyState={{
              label: t('project:noResult'),
            }}
            selectionComponentProps={oldSelectionComponentProps}
          />
        }
      />
    )
  );
};
