import { useMemo } from 'react';
import { FEATURE_FLAGS, PERMISSION, PERMISSIONS_WITHOUT_USER_FEATURE_FLAG } from '@graneet/business-logic';

import { useAppContext } from 'features/app/contexts/AppContext';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

/**
 * @example
 * ```
 * function SomeComponent() {
 *   const hasPermission = usePermissions(['access:quotes', 'access:projects']);
 *
 *   // ...
 *   return (
 *     {* ... *}
 *   )
 * }
 * ```
 */
export const usePermissions = (permissions: PERMISSION[]) => {
  const { currentUser } = useAppContext();
  const userPermissions = currentUser?.role?.permissions;

  const hasUserPermissionsFlagEnabled = useFeatureFlag(FEATURE_FLAGS.USER_PERMISSIONS);

  return useMemo(() => {
    let filteredRequiredPermissions: PERMISSION[] = permissions;

    if (!hasUserPermissionsFlagEnabled) {
      // if user permission flag is not active, we only check certain permissions only
      filteredRequiredPermissions = permissions.filter((permission) =>
        PERMISSIONS_WITHOUT_USER_FEATURE_FLAG.includes(permission),
      );
    }

    if (userPermissions?.includes(PERMISSION.ADMIN)) return true;

    return filteredRequiredPermissions.length
      ? filteredRequiredPermissions.every((permission) => userPermissions?.includes(permission))
      : true;
    // join transforms the non-primitive value (permissions) to primitive value. By that, memo will return
    // same reference if permissions have not changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions?.join(), userPermissions]);
};
