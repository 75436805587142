import type { FC } from 'react';
import { useCallback } from 'react';
import type { IFile } from '@graneet/business-logic';
import {
  ActionMenu,
  Badge,
  Card,
  File,
  SimpleDownloadIcon,
  SimpleFileAttachmentIcon,
  SimpleViewIcon,
} from '@graneet/lib-ui';
import { HiddenField, useFormContext, useOnChangeValues } from 'graneet-form';
import { isArray } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { OrderFileAddReceiptFileButton } from './OrderFileAddReceiptFileButton';

import type { UseDisabledButtonPropsReturn } from 'features/role/hooks/useDisabledButtonProps';
import { formatFileDownloadUrl, formatFilePreviewUrl, isIFile } from 'features/file/services/file.util';

interface UploadFilesFieldCardProps {
  buttonPermissionsProps?: UseDisabledButtonPropsReturn;

  onFileDelete?(deletedFileId: string): Promise<void>;

  onFileAdded?(data: { file: File; isDisplayInOrderPdf: boolean }): Promise<void>;

  onFileUpdate?(data: { file: IFile; isDisplayInOrderPdf: boolean }): Promise<void>;
}

export interface OrderFilesForm {
  initialReceiptFiles: { file: IFile; isDisplayInOrderPdf: boolean }[];

  receiptFiles: { file: File; isDisplayInOrderPdf: boolean }[];

  deletedReceiptFilesIds: string[];

  isOrderFilesDisplayedInPDFUpdate: Record<string, boolean>;
}

export const OrderFileReceiptFilesCard: FC<UploadFilesFieldCardProps> = ({
  buttonPermissionsProps,
  onFileDelete,
  onFileAdded,
  onFileUpdate,
}) => {
  const { t } = useTranslation(['global', 'orders']);

  const form = useFormContext<OrderFilesForm>();
  const { receiptFiles, deletedReceiptFilesIds, initialReceiptFiles } = useOnChangeValues(form, [
    'receiptFiles',
    'deletedReceiptFilesIds',
    'initialReceiptFiles',
  ]);

  /**
   * Handle to delete existing file in view and edition context
   * Not called in creation context
   * @param fileId The id of the file to delete
   */
  const handleDeleteFile = useCallback(
    (deletedFile: File | IFile, index: number) => () => {
      const { isOrderFilesDisplayedInPDFUpdate } = form.getFormValues();

      if (isIFile(deletedFile)) {
        const fileId = deletedFile.id;
        onFileDelete?.(fileId);
        const newDeletedReceiptFilesIds = [...(isArray(deletedReceiptFilesIds) ? deletedReceiptFilesIds : []), fileId];
        const newIsOrderFilesDisplayedInPDFUpdate = { ...(isOrderFilesDisplayedInPDFUpdate || {}) };
        delete newIsOrderFilesDisplayedInPDFUpdate[fileId];
        form.setFormValues({
          deletedReceiptFilesIds: newDeletedReceiptFilesIds,
          isOrderFilesDisplayedInPDFUpdate: newIsOrderFilesDisplayedInPDFUpdate,
        });
      }

      const newFiles = (receiptFiles || []).filter((_: unknown, i: number) => i !== index);
      form.setFormValues({ receiptFiles: newFiles, isOrderFilesDisplayedInPDFUpdate });
    },
    [deletedReceiptFilesIds, form, onFileDelete, receiptFiles],
  );

  const handleFileSubmit = useCallback(
    async (data: { file: File; isDisplayInOrderPdf: boolean }) => {
      await onFileAdded?.(data);
      const { receiptFiles: newReceiptFiles } = form.getFormValues();

      form.setFormValues({
        receiptFiles: isArray(newReceiptFiles) ? [...newReceiptFiles, data] : [data],
      });
    },
    [form, onFileAdded],
  );

  const handleFileUpdate = useCallback(
    async (file: File | IFile, isDisplayInOrderPdf: boolean) => {
      const { isOrderFilesDisplayedInPDFUpdate } = form.getFormValues();

      if (isIFile(file)) {
        await onFileUpdate?.({ file, isDisplayInOrderPdf });

        const newEntry = { [file.id]: isDisplayInOrderPdf };

        form.setFormValues({
          isOrderFilesDisplayedInPDFUpdate: {
            ...(isOrderFilesDisplayedInPDFUpdate || {}),
            ...newEntry,
          },
          initialReceiptFiles: (initialReceiptFiles || []).map((initialReceiptFile) =>
            initialReceiptFile.file === file ? { file, isDisplayInOrderPdf } : initialReceiptFile,
          ),
        });
      } else {
        form.setFormValues({
          receiptFiles: (receiptFiles || []).map((receiptFile) =>
            receiptFile.file === file ? { file, isDisplayInOrderPdf } : receiptFile,
          ),
        });
      }
    },
    [form, initialReceiptFiles, onFileUpdate, receiptFiles],
  );

  const allReceiptFiles = [
    ...(receiptFiles || []),
    ...(initialReceiptFiles || []).filter((irf) => !(deletedReceiptFilesIds || []).includes(irf.file.id)),
  ];

  return (
    <Card title={t('global:receiptsFileField.title')}>
      {allReceiptFiles.map((fileWithData, index) => {
        const { file, isDisplayInOrderPdf } = fileWithData;

        return (
          <File
            name={file.name}
            additionalContent={
              isDisplayInOrderPdf ? (
                <Badge showDot colorScheme="gray" width="auto" px={2} whiteSpace="nowrap">
                  {t('orders:addReceiptFileModal.displayedInPDF')}
                </Badge>
              ) : undefined
            }
            key={uuid()}
            actions={
              <ActionMenu>
                <ActionMenu.Action
                  icon={<SimpleFileAttachmentIcon />}
                  label={t(
                    isDisplayInOrderPdf
                      ? 'orders:addReceiptFileModal.doNotDisplayInPDF'
                      : 'orders:addReceiptFileModal.displayInPDF',
                  )}
                  onClick={() => handleFileUpdate(file, !isDisplayInOrderPdf)}
                />

                {isIFile(file) && (
                  <>
                    <ActionMenu.Action
                      icon={<SimpleViewIcon />}
                      label={t('global:pdf.view')}
                      onClick={() => window.open(formatFilePreviewUrl(file.id))}
                    />
                    <ActionMenu.Action
                      icon={<SimpleDownloadIcon />}
                      label={t('global:pdf.download')}
                      onClick={() => window.open(formatFileDownloadUrl(file.id))}
                    />
                  </>
                )}
                <ActionMenu.Delete onClick={handleDeleteFile(file, index)} {...buttonPermissionsProps} />
              </ActionMenu>
            }
          />
        );
      })}

      <OrderFileAddReceiptFileButton onSubmit={handleFileSubmit} buttonPermissionsProps={buttonPermissionsProps} />

      {/* Hidden field to store new files and deleted files ids */}
      <HiddenField<OrderFilesForm> name="initialReceiptFiles" />
      <HiddenField<OrderFilesForm> name="receiptFiles" />
      <HiddenField<OrderFilesForm> name="deletedReceiptFilesIds" />
      <HiddenField<OrderFilesForm> name="isOrderFilesDisplayedInPDFUpdate" />
    </Card>
  );
};
