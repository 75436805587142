import type {
  ISupplierInvoicePaymentExtended,
  SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT,
} from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import { Date, Price, Table } from '@graneet/lib-ui';

import { SupplierInvoicePaymentActionMenu } from 'features/supplier-invoice/components/SupplierInvoicePaymentActionMenu';
import { useSupplierInvoiceDeletePayment } from 'features/supplier-invoice/services/supplier-invoice.api';
import { SupplierInvoicePaymentsEditModal } from 'features/supplier-invoice/components/modals/SupplierInvoicePaymentsEditModal';

export interface CashOutflowPaymentRowProps {
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
  supplierInvoicePayment: ISupplierInvoicePaymentExtended;
}

export const CashOutflowPaymentRow: FC<CashOutflowPaymentRowProps> = ({ supplierInvoice, supplierInvoicePayment }) => {
  const { t } = useTranslation(['global', 'cashOutflow']);
  const modal = useDisclosure();

  const supplierInvoiceDeletePaymentMutation = useSupplierInvoiceDeletePayment();

  const onDeletePayment = useCallback(
    (id: number) => async () => {
      await supplierInvoiceDeletePaymentMutation.mutateAsync(id);
    },
    [supplierInvoiceDeletePaymentMutation],
  );

  return (
    <Table.Row key={supplierInvoicePayment.id}>
      <Table.Cell>{t(`cashOutflow:paymentType.${supplierInvoicePayment.type}`)}</Table.Cell>
      <Table.Cell center>
        <Date>{supplierInvoicePayment.paidAt}</Date>
      </Table.Cell>
      <Table.Cell center>
        <Price amount={supplierInvoicePayment.amountPaidIncVAT} />
      </Table.Cell>

      <Table.Cell right>
        <SupplierInvoicePaymentActionMenu
          supplierInvoicePayment={supplierInvoicePayment}
          onEdit={modal.onOpen}
          onDelete={onDeletePayment(supplierInvoicePayment.id)}
        />
      </Table.Cell>
      <SupplierInvoicePaymentsEditModal
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        supplierInvoice={supplierInvoice}
        supplierInvoicePayment={supplierInvoicePayment}
      />
    </Table.Row>
  );
};
