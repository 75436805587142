import { useEffect, useState } from 'react';
import type { FormValues } from 'graneet-form';
import { useFormContext, WATCH_MODE } from 'graneet-form';
import type { ILibraryComponent } from '@graneet/business-logic';
import { isNumberFinite } from '@graneet/business-logic';

import type { LibraryJobEditCardForm, QuantityFieldName } from '../../forms/library-job-component.form';
import { getQuantityFieldName } from '../../forms/library-job-component.form';

/*
  Because we can't use useOnBlurValues outside of form context (an error is thrown), it's an issue to check if the
  line has to be orange when displayQuantities is false, so we use form internal to bypass this limit
 */
export const useHasQuantity = (libraryComponent: ILibraryComponent & { quantity: number | null }) => {
  const {
    formInternal: { addValueSubscriber, removeValueSubscriber },
  } = useFormContext<LibraryJobEditCardForm>();
  const quantityFieldName = getQuantityFieldName(libraryComponent.id);

  const [quantity, setQuantity] = useState<FormValues<LibraryJobEditCardForm, QuantityFieldName>>({});

  useEffect(() => {
    addValueSubscriber(setQuantity, WATCH_MODE.ON_BLUR, [quantityFieldName]);
    return () => removeValueSubscriber(setQuantity, WATCH_MODE.ON_BLUR, [quantityFieldName]);
  }, [addValueSubscriber, quantityFieldName, removeValueSubscriber]);

  return isNumberFinite(quantity[quantityFieldName]);
};
