import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { IContact } from '@graneet/business-logic';
import type { QuoteContactDTO } from '@org/graneet-bff-client';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuote } from './useQuote';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { updateQuoteContacts } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteUpdateContact() {
  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const { quoteProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    (contacts: IContact[] | null): boolean => {
      try {
        quote.updateProperties({ contacts });

        quoteSetToStore(quote);
        updateQuoteContacts(contacts as QuoteContactDTO[]);

        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
        quoteProxyApi
          .updateQuoteAsync({
            contacts: (contacts ?? []).map(({ id, isDefaultEmailRecipient }) => ({ id, isDefaultEmailRecipient })),
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
          })
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteError, quoteProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
