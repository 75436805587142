import type { FC, PropsWithChildren } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import type { IQuoteDisplayContext } from './QuoteDisplayContext.context';
import { QuoteDisplayContext } from './QuoteDisplayContext.context';

interface QuoteDisplayProviderProps extends PropsWithChildren {
  onOpenLotEditDrawer(): void;
  onOpenLotEditDefaultVatRateModal(): void;
  onOpenBatiprixImportDrawer(): void;
  onOpenLibraryJobImportDrawer(): void;
}

export const QuoteDisplayProvider: FC<QuoteDisplayProviderProps> = ({
  children,
  onOpenLotEditDrawer,
  onOpenLotEditDefaultVatRateModal,
  onOpenBatiprixImportDrawer,
  onOpenLibraryJobImportDrawer,
}) => {
  // Lot update modal
  const lotIdToUpdateRef = useRef<number | undefined>();

  const openLotUpdateDrawer = useCallback(
    (lotId: number) => {
      lotIdToUpdateRef.current = lotId;
      onOpenLotEditDrawer();
    },
    [onOpenLotEditDrawer],
  );

  const lotIdToEditDefaultVatRateRef = useRef<number | undefined>();
  const openLotDefaultVatRateEditModal = useCallback(
    (lotId: number) => {
      lotIdToEditDefaultVatRateRef.current = lotId;
      onOpenLotEditDefaultVatRateModal();
    },
    [onOpenLotEditDefaultVatRateModal],
  );

  // Library job import drawer
  const targetLotIdForLibraryJobsImportRef = useRef<number | undefined>();
  const openLibraryJobImportDrawer = useCallback(
    (lotId: number) => {
      targetLotIdForLibraryJobsImportRef.current = lotId;
      onOpenLibraryJobImportDrawer();
    },
    [onOpenLibraryJobImportDrawer],
  );
  const getTargetLotIdForLibraryJobImport = useCallback(() => targetLotIdForLibraryJobsImportRef.current, []);

  // Batiprix import drawer
  const targetLotIdForBatiprixImportRef = useRef<number | undefined>();
  const openBatiprixImportDrawer = useCallback(
    (lotId: number) => {
      targetLotIdForBatiprixImportRef.current = lotId;
      onOpenBatiprixImportDrawer();
    },
    [onOpenBatiprixImportDrawer],
  );
  const getTargetLotIdForBatiprixImport = useCallback(() => targetLotIdForBatiprixImportRef.current, []);

  const [displaySaleView, setDisplaySaleView] = useState(false);
  const [isReadOnlyView, setIsReadOnlyView] = useState(false);

  const context = useMemo<IQuoteDisplayContext>(
    () => ({
      openLotUpdateDrawer,
      get lotIdToUpdate() {
        return lotIdToUpdateRef.current;
      },

      openLotDefaultVatRateEditModal,
      get lotIdToEditDefaultVatRate() {
        return lotIdToEditDefaultVatRateRef.current;
      },

      openLibraryJobImportDrawer,
      getTargetLotIdForLibraryJobImport,

      openBatiprixImportDrawer,
      getTargetLotIdForBatiprixImport,

      // Sales view
      get displaySaleView() {
        return displaySaleView;
      },
      setDisplaySaleView,

      // Readonly view,
      // Sales view
      get isReadOnlyView() {
        return isReadOnlyView;
      },
      setIsReadOnlyView,
    }),
    [
      openLotUpdateDrawer,
      openLotDefaultVatRateEditModal,
      openLibraryJobImportDrawer,
      getTargetLotIdForLibraryJobImport,
      openBatiprixImportDrawer,
      getTargetLotIdForBatiprixImport,
      displaySaleView,
      isReadOnlyView,
    ],
  );

  return <QuoteDisplayContext.Provider value={context}>{children}</QuoteDisplayContext.Provider>;
};
