import type { Dispatch, PropsWithChildren, ReactElement, SetStateAction } from 'react';
import { createContext, useCallback, useContext, useMemo, useRef } from 'react';

export interface TableSelectBannerState<Data extends unknown> {
  /**
   * List of selected items
   */
  selectedItems: Data[];

  /**
   * Is "select all" enabled
   */
  selectAll: boolean;

  /**
   * Number of registered checkboxes
   */
  numberOfRegisteredCheckboxes: number;
}

export interface ITableSelectionContext<Data extends unknown> {
  /**
   * Update the value for a selected item
   * @param id `Table.Check` internal id
   * @param data Data given to `Table.Check`
   * @param isSelected New Value
   */
  updateSelectedItem(id: string, data: Data, isSelected: boolean): void;

  /**
   * Change all registered checkboxes to a new
   * @param newValue New Value
   */
  updateAllCheckboxesDisplayed(newValue: boolean): void;

  /**
   * Register a new Checkbox than can be changed from the context
   * @param id `Table.Check` internal id
   * @param setter Table.Check` internal setter
   * @param data Data given to `Table.Check`
   */
  registerCheckbox(id: string, setter: (newValue: boolean) => void, data: Data): void;

  /**
   * UnRegister a Checkbox
   * @param id `Table.Check` internal id
   */
  unregisterCheckbox(id: string): void;

  /**
   * Register a header select.
   * Note: there is only one header select per `Table`
   * @param onCheckboxChanged Callback executed when a checkbox value is changed
   */
  registerHeaderSelect(
    onCheckboxChanged: (numberOfRegisterCheckboxes: number, numberOfSelectedItems: number, selectAll: boolean) => void,
  ): void;

  /**
   * Unregister a header select
   */
  unregisterHeaderSelect(): void;

  /**
   * Register a select banner.
   * @param setter select banner state setter
   */
  registerSelectBanner(setter: Dispatch<SetStateAction<TableSelectBannerState<Data>>>): void;

  /**
   * Unregister a select banner
   */
  unregisterSelectBanner(): void;

  /**
   * Select all checkboxes: displayed and futures ones
   */
  selectAllCheckboxes(): void;
}

export const TableSelectionContext = createContext<ITableSelectionContext<any>>({
  updateSelectedItem() {},
  updateAllCheckboxesDisplayed() {},
  registerCheckbox() {},
  unregisterCheckbox() {},
  registerHeaderSelect() {},
  unregisterHeaderSelect() {},
  selectAllCheckboxes() {},
  registerSelectBanner() {},
  unregisterSelectBanner() {},
});

/**
 * Provide methods to handle checkboxes in a table
 */
export const TableSelectionContextProvider = <Data extends unknown>({ children }: PropsWithChildren): ReactElement => {
  const selectAllRef = useRef<boolean>(false);

  const setSelectBannerStateRef = useRef<Dispatch<SetStateAction<TableSelectBannerState<Data>>>>(() => {});

  /**
   * Store all selected items
   */
  const selectedItemsRef = useRef<Map<string, Data>>(new Map());

  /**
   * Store all displayed
   */
  type RegisterCheckboxInfo = {
    setter(newValue: boolean): void;
    data: Data;
  };
  const registerCheckboxesRef = useRef<Map<string, RegisterCheckboxInfo>>(new Map());
  const registerCheckbox = useCallback((id: string, setter: (newValue: boolean) => void, data: Data) => {
    registerCheckboxesRef.current.set(id, { data, setter });

    // When a new checkbox is registered and user has "select all" enabled, the new displayed checkbox is checked
    if (selectAllRef.current) {
      setter(true);
      selectedItemsRef.current.set(id, data);
    }

    setSelectBannerStateRef.current(
      (previousValue) =>
        ({
          ...previousValue,
          numberOfRegisteredCheckboxes: registerCheckboxesRef.current.size,
          selectedItems: [...selectedItemsRef.current.values()],
        }) satisfies TableSelectBannerState<Data>,
    );
  }, []);
  const unregisterCheckbox = useCallback((id: string) => {
    registerCheckboxesRef.current.delete(id);
    setSelectBannerStateRef.current(
      (previousValue) =>
        ({
          ...previousValue,
          numberOfRegisteredCheckboxes: registerCheckboxesRef.current.size,
        }) satisfies TableSelectBannerState<Data>,
    );
  }, []);

  /**
   * Store a function to rerender header select. This is useful to update the component after a check is updated
   */
  const onCheckboxChangedRef = useRef<
    (numberOfRegisterCheckboxes: number, numberOfSelectedItems: number, selectAll: boolean) => void
  >(() => {});
  const registerHeaderSelect = useCallback(
    (
      onCheckboxChanged: (
        numberOfRegisterCheckboxes: number,
        numberOfSelectedItems: number,
        selectAll: boolean,
      ) => void,
    ) => {
      onCheckboxChangedRef.current = onCheckboxChanged;
    },
    [],
  );
  const unregisterHeaderSelect = useCallback(() => {
    onCheckboxChangedRef.current = () => {};
  }, []);

  /**
   * Expose a fonction to modify selected items.
   */
  const updateSelectedItem = useCallback((id: string, data: Data, isSelected: boolean) => {
    // If item is selected, add it to selected item
    if (isSelected) {
      selectedItemsRef.current.set(id, data);
      // Remove it from selected item
    } else {
      selectedItemsRef.current.delete(id);
    }

    selectAllRef.current = false;

    setSelectBannerStateRef.current(
      (previousValue) =>
        ({
          ...previousValue,
          selectAll: selectAllRef.current,
          selectedItems: [...selectedItemsRef.current.values()],
        }) satisfies TableSelectBannerState<Data>,
    );
    onCheckboxChangedRef.current(
      registerCheckboxesRef.current.size,
      selectedItemsRef.current.size,
      selectAllRef.current,
    );
  }, []);

  /**
   * Update value of all checkboxes
   */
  const updateAllCheckboxesDisplayed = useCallback((newValue: boolean) => {
    registerCheckboxesRef.current.forEach((value, id) => {
      value.setter(newValue);
      if (newValue) {
        selectedItemsRef.current.set(id, value.data);
      }
    });

    if (!newValue) {
      selectedItemsRef.current.clear();
    }

    selectAllRef.current = false;

    setSelectBannerStateRef.current(
      (previousValue) =>
        ({
          ...previousValue,
          selectedItems: [...selectedItemsRef.current.values()],
          selectAll: selectAllRef.current,
        }) satisfies TableSelectBannerState<Data>,
    );
    onCheckboxChangedRef.current(
      registerCheckboxesRef.current.size,
      selectedItemsRef.current.size,
      selectAllRef.current,
    );
  }, []);

  const selectAllCheckboxes = useCallback(() => {
    selectAllRef.current = true;

    onCheckboxChangedRef.current(
      registerCheckboxesRef.current.size,
      selectedItemsRef.current.size,
      selectAllRef.current,
    );

    setSelectBannerStateRef.current(
      (previousValue) =>
        ({
          ...previousValue,
          selectAll: selectAllRef.current,
        }) satisfies TableSelectBannerState<Data>,
    );
  }, []);

  const registerSelectBanner = useCallback((setter: Dispatch<SetStateAction<TableSelectBannerState<Data>>>) => {
    setter({
      selectedItems: [...selectedItemsRef.current.values()],
      selectAll: selectAllRef.current,
      numberOfRegisteredCheckboxes: registerCheckboxesRef.current.size,
    });
    setSelectBannerStateRef.current = setter;
  }, []);
  const unregisterSelectBanner = useCallback(() => {
    setSelectBannerStateRef.current = () => {};
  }, []);

  const tableSelectionContext = useMemo<ITableSelectionContext<Data>>(
    () => ({
      updateSelectedItem,

      registerCheckbox,
      unregisterCheckbox,
      updateAllCheckboxesDisplayed,

      registerHeaderSelect,
      unregisterHeaderSelect,

      selectAllCheckboxes,

      registerSelectBanner,
      unregisterSelectBanner,
    }),
    [
      updateSelectedItem,
      registerCheckbox,
      unregisterCheckbox,
      updateAllCheckboxesDisplayed,
      registerHeaderSelect,
      unregisterHeaderSelect,
      selectAllCheckboxes,
      registerSelectBanner,
      unregisterSelectBanner,
    ],
  );

  return <TableSelectionContext.Provider value={tableSelectionContext}>{children}</TableSelectionContext.Provider>;
};

/**
 * @internal Avoid to use this hook outside of Table component or its subcomponents
 */
export const useTableSelectionContext = () => useContext(TableSelectionContext);
