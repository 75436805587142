import { useCallback } from 'react';

import { useAddLotAfterAnother } from './useAddLotAfterAnother';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Adds a lot in last position
 */
export const useAddLot = () => {
  const { getCurrentQuoteData } = useQuoteEditContext();
  const addLotAfterAnother = useAddLotAfterAnother();

  return useCallback(
    async (lotId: number) => {
      const { relations } = getCurrentQuoteData();
      const lotsOfLot = relations[lotId]?.lots;
      const lastLotId = lotsOfLot ? lotsOfLot[lotsOfLot.length - 1] : undefined;
      await addLotAfterAnother(lotId, lastLotId);
    },
    [getCurrentQuoteData, addLotAfterAnother],
  );
};
