import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const DocumentPartialIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 64 76" {...props}>
    <svg width="64" height="76" viewBox="0 0 64 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1175_4124)">
        <mask
          id="mask0_1175_4124"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="12"
          y="10"
          width="38"
          height="45"
        >
          <path
            d="M12 12C12 10.8954 12.8954 10 14 10H47.7299C49.4256 10 50.3519 11.9777 49.2664 13.2804L15.5364 53.7563C14.3389 55.1933 12 54.3465 12 52.4759V12Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_1175_4124)">
          <g filter="url(#filter1_d_1175_4124)">
            <path d="M12 12C12 10.8954 12.8954 10 14 10H52L12 58V12Z" fill="white" />
          </g>
          <rect x="17" y="38" width="30" height="2" rx="1" fill="#FFDCAF" />
          <rect x="17" y="42" width="30" height="2" rx="1" fill="#FFDCAF" />
          <rect x="17" y="46" width="30" height="2" rx="1" fill="#FFDCAF" />
          <rect x="17" y="15" width="9" height="3" rx="1" fill="#FFDCAF" />
          <rect x="32" y="20" width="15" height="6" rx="1" fill="#FFDCAF" />
          <rect x="17" y="28" width="30" height="6" rx="1" fill="#FFDCAF" />
        </g>
        <mask
          id="mask1_1175_4124"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="14"
          y="21"
          width="38"
          height="45"
        >
          <path
            d="M48.4636 22.2437C49.6611 20.8067 52 21.6535 52 23.5241V64C52 65.1046 51.1046 66 50 66H16.2701C14.5744 66 13.6481 64.0223 14.7336 62.7196L48.4636 22.2437Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_1175_4124)">
          <g filter="url(#filter2_d_1175_4124)">
            <path
              d="M48.4636 22.2437C49.6611 20.8067 52 21.6535 52 23.5241V64C52 65.1046 51.1046 66 50 66H16.2701C14.5744 66 13.6481 64.0223 14.7336 62.7196L48.4636 22.2437Z"
              fill="white"
            />
          </g>
          <rect x="17" y="38" width="30" height="2" rx="1" fill="#FFDCAF" />
          <rect x="17" y="42" width="30" height="2" rx="1" fill="#FFDCAF" />
          <rect x="17" y="46" width="30" height="2" rx="1" fill="#FFDCAF" />
          <rect x="32" y="20" width="15" height="6" rx="1" fill="#FFDCAF" />
          <rect x="33" y="55" width="14" height="6" rx="1" fill="#FFDCAF" />
          <rect x="17" y="55" width="14" height="6" rx="1" fill="#FFDCAF" />
          <rect x="17" y="28" width="30" height="6" rx="1" fill="#FFDCAF" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1175_4124"
          x="2"
          y="0"
          width="60"
          height="76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1175_4124" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1175_4124" result="shape" />
        </filter>
        <filter
          id="filter1_d_1175_4124"
          x="2"
          y="0"
          width="60"
          height="68"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1175_4124" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1175_4124" result="shape" />
        </filter>
        <filter
          id="filter2_d_1175_4124"
          x="4.2666"
          y="11.5201"
          width="57.7334"
          height="64.4799"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1175_4124" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1175_4124" result="shape" />
        </filter>
      </defs>
    </svg>
  </Icon>
);
