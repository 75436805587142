import type { DocumentStatusCardAction } from '@graneet/lib-ui';

import {
  getDeleteAction,
  getDownloadPDFAction,
  getDuplicateAction,
  getExportAction,
  getOpenDetailAction,
  getOpenMailingAction,
  getPreviewPDFAction,
} from './quote.actions.util';
import type { QuotationActionsParams, QuoteActionsParams } from './quote.actions.type';

export function getQuoteAcceptedActions(
  params: QuoteActionsParams | QuotationActionsParams,
  full = true,
): DocumentStatusCardAction[] {
  const { pdf } = params;
  const actions: DocumentStatusCardAction[] = [];

  if (pdf && full) {
    actions.push({
      type: 'secondary',
      ...getPreviewPDFAction(params, pdf),
    });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: [
      ...(full ? [getOpenDetailAction(params)] : []),
      ...(pdf ? [getOpenMailingAction(params, pdf), getDownloadPDFAction(params, pdf)] : []),
      ...(full ? [getExportAction(params)] : []),
      getDuplicateAction(params),
      getDeleteAction(params),
    ],
  });

  return actions;
}
