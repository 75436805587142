import { GridItem, Stack, VStack } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import {
  DOWN_PAYMENT_STATUSES,
  HISTORY_ENTITY_TYPE,
  STATEMENT_TYPES,
  isDownPaymentCancelled,
  getEmailVariableData,
} from '@graneet/business-logic';
import type {
  IProgressStatement,
  IProjectWithRelations,
  IGroupedContact,
  IContactWithProjectInfos,
  IDownPaymentResponse,
} from '@graneet/business-logic';
import { Callout, TwoColumns } from '@graneet/lib-ui';
import type { FC } from 'react';

import { useDownPaymentVersions } from '../../services/down-payment.api';

import { DownPaymentInformationCard } from './DownPaymentInformationCard';
import { DownPaymentStatusCard } from './DownPaymentStatusCard/DownPaymentStatusCard';
import { DownPaymentTagsCard } from './DownPaymentTagsCard';

import { ClientCard } from 'features/client/components/cards/ClientCard';
import { CreditLinkedStatementCard } from 'features/credit/components/cards/CreditLinkedStatementCard';
import { useEmailTemplateRichTextConfiguration } from 'features/email-template/hooks/useEmailTemplateRichTextConfiguration';
import { HistoryCard } from 'features/history/components/HistoryCard';
import { EmailProvider } from 'features/history/contexts/EmailContext';
import { PaymentMethodDetailCard } from 'features/payment-method/components/PaymentMethodDetailCard';
import { ProjectAssociationCard } from 'features/project/components/cards/ProjectAssociationCard';

interface DownPaymentDetailProps {
  downPayment: IDownPaymentResponse;
  project: IProjectWithRelations;
  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
  progressStatements: IProgressStatement[];
  onGoBack: () => void;
  onCancelConfirmed: (createDraft: boolean) => void;
  displayAssociatedProject?: boolean;
  displayPaymentTerms?: boolean;
  displayPrimaryClient?: boolean;
}

export const DownPaymentDetail: FC<DownPaymentDetailProps> = ({
  downPayment,
  project,
  projectContacts,
  progressStatements,
  onGoBack,
  onCancelConfirmed,
  displayAssociatedProject = false,
  displayPaymentTerms = false,
  displayPrimaryClient = false,
}) => {
  const { t } = useTranslation(['global', 'statement', 'downPayment']);

  const versions = useDownPaymentVersions(downPayment.id);

  const emailTemplateRichTextConfiguration = useEmailTemplateRichTextConfiguration(
    getEmailVariableData('downPayment', {
      downPayment,
      project,
      client: project.primaryClient,
    }),
  );

  const shouldDisplayLinkedCredit = downPayment.credit && isDownPaymentCancelled(downPayment);

  return (
    <EmailProvider>
      {!downPayment.isInvoiced && (
        <Callout mb={4}>
          <Trans
            t={t}
            i18nKey={
              downPayment.status === DOWN_PAYMENT_STATUSES.DRAFT
                ? 'downPayment:callouts.draftNotInvoiced'
                : 'downPayment:callouts.completedNotInvoiced'
            }
          />
        </Callout>
      )}

      <TwoColumns>
        <VStack justifyContent="stretch" alignItems="stretch" spacing={4}>
          <DownPaymentStatusCard
            currentVersion={versions.data.current ?? undefined}
            downPayment={downPayment}
            project={project}
            projectContacts={projectContacts}
            progressStatements={progressStatements}
            onCancelConfirmed={onCancelConfirmed}
            onDeleted={onGoBack}
          />
          {shouldDisplayLinkedCredit && (
            <CreditLinkedStatementCard
              statement={downPayment.credit!}
              statementType={STATEMENT_TYPES.CREDIT}
              projectId={project.id}
            />
          )}
        </VStack>

        <DownPaymentInformationCard downPayment={downPayment} />

        <GridItem colSpan={2}>
          <HistoryCard
            id={downPayment.id}
            entity={HISTORY_ENTITY_TYPE.DOWN_PAYMENT}
            emailTemplateRichTextConfiguration={emailTemplateRichTextConfiguration}
            cardPosition={{ mt: 6 }}
          />
        </GridItem>

        {displayPrimaryClient && (
          <>
            <Stack spacing={6}>
              <ClientCard client={project.primaryClient} type={null} />
              <DownPaymentTagsCard downPayment={downPayment} />
            </Stack>
            <Stack spacing={4}>
              {displayPaymentTerms &&
                (downPayment ? (
                  <PaymentMethodDetailCard
                    paymentMethod={downPayment.paymentMethod}
                    paymentTerm={downPayment.paymentTerm}
                    deadlineType={downPayment.deadlineType}
                  />
                ) : (
                  <PaymentMethodDetailCard
                    paymentMethod={project.paymentMethod}
                    paymentTerm={project.paymentTerm}
                    deadlineType={project.deadlineType}
                  />
                ))}
              {displayAssociatedProject && (
                <ProjectAssociationCard project={project} subProject={downPayment.subProject} />
              )}
            </Stack>
          </>
        )}

        {!displayPrimaryClient && (
          <>
            {displayAssociatedProject && (
              <ProjectAssociationCard project={project} subProject={downPayment.subProject} />
            )}
            {displayPaymentTerms && (
              <PaymentMethodDetailCard
                paymentMethod={project.paymentMethod}
                paymentTerm={project.paymentTerm}
                deadlineType={project.deadlineType}
              />
            )}
          </>
        )}
      </TwoColumns>
    </EmailProvider>
  );
};
