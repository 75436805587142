import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IProject, RequiredByKeys } from '@graneet/business-logic';

import { AssociateProjectBox } from './AssociateProjectBox';

interface AssociateProjectModalProps {
  isOpen: boolean;
  onClose(): void;
  onSubmit(project: RequiredByKeys<IProject, 'address'> | null): void;
  title: ReactNode;
  description: ReactNode;
}

export const AssociateProjectModal: FC<AssociateProjectModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  description,
}) => {
  const { t } = useTranslation(['project']);

  const [isSaving, setIsSaving] = useState(false);

  const [selectedProject, setSelectedProject] = useState<RequiredByKeys<IProject, 'address'> | null>(null);

  const handleSubmit = useCallback(async () => {
    setIsSaving(true);
    onSubmit(selectedProject);
    setIsSaving(false);
    onClose();
  }, [onClose, onSubmit, selectedProject]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedProject(null);
  }, [onClose]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={title} size="5xl" fullHeight scrollBehavior="inside">
      <AssociateProjectBox onSelectProject={setSelectedProject} description={description} />

      <Modal.Close />

      <Modal.PrimaryButton isLoading={isSaving} isDisabled={!selectedProject} onClick={handleSubmit}>
        {t('project:associateModal.associate')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
