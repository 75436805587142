import type { FC } from 'react';
import { Flex } from '@chakra-ui/react';

export const Confetti: FC = () => (
  <>
    <Flex h="100vh" bottom={0} left={0} position="fixed" alignItems="center">
      <span id="leftConfetti" style={{ height: '30rem' }} />
    </Flex>

    <Flex h="100vh" bottom={0} right={0} position="fixed" alignItems="center">
      <span id="rightConfetti" style={{ height: '30rem' }} />
    </Flex>
  </>
);
