import type { FC } from 'react';
import type { BadgeProps } from '@graneet/lib-ui';
import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { PROJECT_STATUSES } from '@graneet/business-logic';

import { PROJECT_STATUS_COLOR } from '../constants/project.constant';

interface ProjectStatusBadgeProps extends Omit<BadgeProps, 'children'> {
  status: PROJECT_STATUSES;
}

export const ProjectStatusBadge: FC<ProjectStatusBadgeProps> = ({ status, ...rest }) => {
  const { t } = useTranslation(['project']);
  return (
    <Badge w="auto" minWidth="5.25rem" px={3} colorScheme={PROJECT_STATUS_COLOR[status]} {...rest}>
      {t(`project:status.${status}`)}
    </Badge>
  );
};
