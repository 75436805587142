import type { FC } from 'react';
import type { IProject, ISubProjectByProjectListingResponseDTO } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { Button } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';

import { getProgressStatementCreationState } from '../../services/progress-statement.util';

import { ProgressStatementCreateOnSubProjectModal } from './ProgressStatementCreateOnSubProjectModal';

import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { SubProjectSelectBillingTypeModal } from 'features/sub-project/components/SubProjectSelectBillingTypeModal';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface ProgressStatementCreateButtonProps {
  subProjects: ISubProjectByProjectListingResponseDTO;

  project: IProject;
}

export const ProgressStatementCreateButton: FC<ProgressStatementCreateButtonProps> = ({ subProjects, project }) => {
  const { t } = useTranslation(['progressStatement', 'project']);

  const { createRedirectionWithFilters } = useFiltersQuery();
  const { url } = useRouteMatch();

  const selectBillingModal = useDisclosure();
  const selectSubProjectModal = useDisclosure();

  const createStatementsButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_STATEMENTS]);
  const permissionsProps = createStatementsButtonDisabledProps();

  /**
   * You cannot create a progress statement if there is no subproject
   */
  if (subProjects.length === 0) {
    return (
      <Button tooltip={t('progressStatement:tooltips.noContract')} tooltipProps={{ placement: 'left' }} isDisabled>
        {t('project:actions.selectBilling')}
      </Button>
    );
  }

  /**
   * You don't have to display a selectBillingModal displaying the list of subprojects when there is only one
   */
  if (subProjects.length === 1) {
    const progressStatementState = getProgressStatementCreationState(subProjects[0], t, false);

    const onClick = subProjects[0].billingType
      ? // Redirect user to progress statement creation flow
        createRedirectionWithFilters(
          `/projects/${project.id}/statements/sub-projects/${subProjects[0].id}/progress-statements/create`,
          {
            referrerUrl: url,
          },
        )
      : // Open Modal to select the billing type
        selectBillingModal.onOpen;

    return (
      <>
        <SubProjectSelectBillingTypeModal
          isOpen={selectBillingModal.isOpen}
          onClose={selectBillingModal.onClose}
          isDirectDisabled={subProjects[0].hasDirectPaymentOrders}
          project={project}
          subProject={subProjects[0]}
        />

        <Button
          tooltip={progressStatementState.tooltip || permissionsProps.tooltip}
          tooltipProps={{ placement: 'left' }}
          isDisabled={!progressStatementState.canCreate || permissionsProps.isDisabled}
          onClick={onClick}
        >
          {t('project:actions.selectBilling')}
        </Button>
      </>
    );
  }

  return (
    <>
      <ProgressStatementCreateOnSubProjectModal
        subProjects={subProjects}
        onClose={selectSubProjectModal.onClose}
        isOpen={selectSubProjectModal.isOpen}
        project={project}
      />

      <Button onClick={selectSubProjectModal.onOpen}>{t('project:actions.selectBilling')}</Button>
    </>
  );
};
