import type { InputProps } from '@chakra-ui/react';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { forwardRef, type ReactElement } from 'react';

export type QuotationInputProps = InputProps & {
  icon?: ReactElement;
  rightIcon?: ReactElement;
  noStyle?: boolean;
};

const NO_STYLE = {
  borderWidth: '0px',
  background: 'transparent',
  boxShadow: 'none',
  outlineWidth: 0,
};

export const QuotationInput = forwardRef<HTMLInputElement, QuotationInputProps>(
  ({ icon, width, isDisabled, isReadOnly, rightIcon, defaultValue, value, noStyle, ...props }, ref) => (
    <InputGroup alignItems="center" width={width}>
      {icon && (!isReadOnly || isDisabled) && (
        <InputLeftElement width="auto" left="0.5rem" height="1.75rem" alignItems="center">
          {icon}
        </InputLeftElement>
      )}
      <Input
        ref={ref}
        defaultValue={defaultValue}
        value={value}
        height="1.75rem"
        pr="0.25rem"
        pl="0.5rem"
        py="0.25rem"
        borderRadius="0.375rem"
        fontSize="0.75rem"
        border={noStyle ? 'none' : '1px solid'}
        borderColor="greenBrand.borderDefault"
        background={noStyle ? 'transparent' : 'white'}
        boxShadow="none"
        outline="none"
        outlineOffset="none"
        variant="outline"
        _hover={
          noStyle
            ? NO_STYLE
            : {
                borderColor: 'greenBrand.lighter',
              }
        }
        _focus={
          noStyle
            ? NO_STYLE
            : {
                borderColor: 'greenBrand.light',
                borderWidth: '1px',
                outline: 'none',
                outlineWidth: '0px',
                outlineOffset: 'none',
                boxShadow: '0px 0px 0px 2px rgba(43, 80, 88, 0.32)',
              }
        }
        _readOnly={{
          borderWidth: '0px',
          background: 'transparent',
        }}
        _disabled={{
          ...(noStyle
            ? NO_STYLE
            : {
                borderWidth: '1px',
                borderColor: 'gray.150',
                background: 'transparent',
              }),
          cursor: 'not-allowed',
        }}
        _invalid={{
          ...(noStyle
            ? NO_STYLE
            : {
                borderColor: 'red.500',
                borderWidth: '1px',
              }),
          color: 'red.500',
        }}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        width={icon || rightIcon ? '100%' : width}
        {...props}
      />
      {rightIcon && (!isReadOnly || isDisabled) && (
        <InputRightElement width="auto" right="0.5rem" height="1.75rem" alignItems="center">
          {rightIcon}
        </InputRightElement>
      )}
    </InputGroup>
  ),
);
