import type { FC, ReactNode } from 'react';
import type { CardProps } from '@graneet/lib-ui';
import { Card, Spinner } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { FileItem } from './components/FileItem';
import { Versions } from './components/Versions';

export interface FilesCardProps extends CardProps {
  title: string;

  loading?: boolean;

  children: ReactNode;
}

export const FilesCardComponent: FC<FilesCardProps> = ({ title, loading = false, children, ...otherProps }) => {
  const { t } = useTranslation(['global']);

  if (loading) {
    return (
      <Spinner flexGrow={1} justify="center" my={8}>
        {t('global:loading')}
      </Spinner>
    );
  }

  return (
    <Card title={title} {...otherProps}>
      {children}
    </Card>
  );
};

export const FilesCard = Object.assign(FilesCardComponent, {
  FileItem,
  Versions,
});
