import type { FC } from 'react';
import type { IFormattedValidationStepHistory, IUser } from '@graneet/business-logic';
import { CheckIcon, TimeIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useMemo } from 'react';

import { formatUserDisplayName, sortUsers } from 'features/user/services/user.util';
import { UserAvatars } from 'features/user/components/UserAvatars';

interface StepTitleProps {
  step: IFormattedValidationStepHistory;
  text: string;
}

export const StepStatusTitle: FC<StepTitleProps> = ({ step, text }) => {
  const sortedUsers = useMemo(() => sortUsers(step.users.map(({ user }) => user)), [step.users]);

  return (
    <Flex alignItems="center" gap={2}>
      <Text fontSize="sm" noOfLines={1} fontWeight="500">
        {text}
      </Text>
      {sortedUsers && (
        <>
          <UserAvatars users={sortedUsers} />
          <Text fontWeight="700" fontSize="sm" noOfLines={1}>
            {sortedUsers.map((user) => formatUserDisplayName(user)).join(', ')}
          </Text>
        </>
      )}
    </Flex>
  );
};

export const getValidationStepStatus = (
  translation: (
    translationKey: 'requestApproved' | 'pendingApproval' | 'requiredApproval' | 'possibleApproval',
  ) => string,
  step: IFormattedValidationStepHistory,
  isCurrentStep: boolean,
  userApprovedStep?: IUser,
  isAboveThreshold = false,
) => {
  let icon;
  let backgroundColorIcon;
  let title;

  if (userApprovedStep) {
    icon = <CheckIcon boxSize={3} color="green.500" />;
    backgroundColorIcon = 'green.100';
    title = (
      <Text fontWeight="700" fontSize="sm" noOfLines={1}>
        {formatUserDisplayName(userApprovedStep)} {translation('requestApproved')}
      </Text>
    );
  } else if (isCurrentStep) {
    icon = <TimeIcon boxSize={3} color="orange.500" />;
    backgroundColorIcon = 'orange.100';
    title = <StepStatusTitle step={step} text={translation('pendingApproval')} />;
  } else if (isAboveThreshold) {
    icon = <Box boxSize={3} />;
    backgroundColorIcon = 'gray.200';
    title = <StepStatusTitle step={step} text={translation('possibleApproval')} />;
  } else {
    icon = <Box boxSize={3} />;
    backgroundColorIcon = 'gray.200';
    title = <StepStatusTitle step={step} text={translation('requiredApproval')} />;
  }

  return {
    icon,
    backgroundColorIcon,
    title,
  };
};
