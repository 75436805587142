import type { StyleProps } from '@chakra-ui/react';
import { useToken } from '@chakra-ui/react';

/**
 * From a record of key and chakra tokens, returns a record where chakra tokens are replaced by hexadecimal values
 * from chakra theme.
 * @example
 * ```
 * const colors = useChakraColors({ color1: 'greenBrand.light', color2: 'red.50' })
 * console.log(colors.color1); // something like #4287f5
 * console.log(colors.color2); // something like #b81616
 * ```
 */
export const useChakraColors = <T extends Record<string, StyleProps['color']>>(colors: T): Record<keyof T, string> => {
  const colorsTheme = useToken('colors', Object.values(colors) as (string | number)[]);

  return Object.keys(colors).reduce<Record<keyof T, string>>(
    (accumulator, key, index) => ({
      ...accumulator,
      [key]: colorsTheme[index],
    }),
    {} as Record<keyof T, string>,
  );
};
