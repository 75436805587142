import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const NoteIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" color="gray.300" {...props}>
    <path
      d="M12 7.2H10.4V5.6H12V7.2ZM8.8 7.2H7.2V5.6H8.8V7.2ZM5.6 7.2H4V5.6H5.6V7.2ZM14.4 0H1.6C1.17565 0 0.768687 0.168571 0.468629 0.468629C0.168571 0.768687 0 1.17565 0 1.6V16L3.2 12.8H14.4C14.8243 12.8 15.2313 12.6314 15.5314 12.3314C15.8314 12.0313 16 11.6243 16 11.2V1.6C16 0.712 15.28 0 14.4 0Z"
      fill="currentColor"
    />
  </Icon>
);
