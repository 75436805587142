import { useCallback } from 'react';
import type { QuoteCustomDiscountObject } from '@org/quotation-lib';
import { QuoteCustomDiscount } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  quoteClientRequestsSetToStore,
  StatusEnum,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { mapQuoteCustomDiscountToQuoteCustomDiscountUpdateDTO } from 'features/quotation/quote-common/mappers/quoteCustomDiscountMapper';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteCustomDiscountUpdate() {
  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const { quoteCustomDiscountsProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    (
      quoteCustomDiscountObject: Partial<QuoteCustomDiscountObject> & {
        id: string;
      },
    ): boolean => {
      try {
        const quoteCustomDiscountEntity = new QuoteCustomDiscount(quoteCustomDiscountObject);
        quote.upsertCustomDiscount(quoteCustomDiscountEntity);

        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
        quoteCustomDiscountsProxyApi
          .updateQuoteCustomDiscount(
            quoteCustomDiscountObject.id,
            mapQuoteCustomDiscountToQuoteCustomDiscountUpdateDTO(
              quoteCustomDiscountEntity,
              quote.getId(),
              clientRequestId,
              timestamp,
            ),
          )
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        quoteSetToStore(quote);
        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteCustomDiscountsProxyApi, quoteError, quoteSetToStore],
  );
}
