import type { IComponentTypesListingDTO } from '@graneet/business-logic';
import { useCurrency } from '@graneet/lib-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IMPORT_AMOUNT_REGEX } from 'features/import/constants/import.constant';
import type { ImportSpreadsheetField } from 'features/import/components/SpreadsheetImport';

export type ImportLibraryComponentField = 'type' | 'code' | 'name' | 'unit' | 'unit_price';

export const useImportLibraryComponentFields = (
  componentTypes: IComponentTypesListingDTO[],
): ImportSpreadsheetField<ImportLibraryComponentField>[] => {
  const { t } = useTranslation(['library', 'global']);
  const {
    currency: { precision },
  } = useCurrency();

  return useMemo<ImportSpreadsheetField<ImportLibraryComponentField>[]>(
    () => [
      {
        label: t('library:libraryComponent.fields.type'),
        key: 'type',
        fieldType: {
          type: 'select',
          options: componentTypes.map((componentType) => ({
            label: componentType.name,
            value: componentType.id.toString(),
          })),
        },
        alternateMatches: ['Type*'], // Column name in template
        example: t('library:libraryComponent.import.examples.type'),
        validations: [
          {
            rule: 'required',
            errorMessage: t('global:spreadsheetImport.common.errorMandatory'),
          },
        ],
      },
      {
        label: t('library:libraryComponent.fields.refCode'),
        key: 'code',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Code'],
        example: t('library:libraryComponent.import.examples.code'),
      },
      {
        label: t('library:libraryComponent.fields.description'),
        key: 'name',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Intitulé*'],
        example: t('library:libraryComponent.import.examples.name'),
        validations: [
          {
            rule: 'required',
            errorMessage: t('global:spreadsheetImport.common.errorMandatory'),
          },
        ],
      },
      {
        label: t('library:libraryComponent.fields.unit'),
        key: 'unit',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Unité*'],
        example: t('library:libraryComponent.import.examples.unit'),
        // There is not validation for unit here because it's handled in row hook
      },
      {
        label: t('library:libraryComponent.fields.unitDisbursementExVAT'),
        key: 'unit_price',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Déboursé unitaire HT*'],
        example: t('library:libraryComponent.import.examples.unit_price'),
        validations: [
          {
            rule: 'required',
            errorMessage: t('global:spreadsheetImport.common.errorMandatory'),
          },
          {
            rule: 'regex',
            value: IMPORT_AMOUNT_REGEX(precision),
            errorMessage: t('global:spreadsheetImport.common.errorAmount'),
            level: 'error',
          },
        ],
      },
    ],
    [t, componentTypes, precision],
  );
};
