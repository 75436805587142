import type { FC } from 'react';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { OrdersListing } from 'features/order/components/tables/OrdersListing';
import { useOrders } from 'features/order/services/order.api';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';

export const ViewOrdersScreen: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const { createRedirectionWithFilters } = useFiltersQuery();

  const orders = useOrders({
    forcedFilters: {
      projectId,
    },
  });

  const onRowClicked = useCallback(
    (id: number) => {
      createRedirectionWithFilters(`/projects/${projectId}/purchases/orders/${id}`)();
    },
    [createRedirectionWithFilters, projectId],
  );

  return <OrdersListing gridId="order-project" orders={orders} onRowClicked={onRowClicked} projectId={+projectId} />;
};
