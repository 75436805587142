import type { FC } from 'react';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import { Modal } from '@graneet/lib-ui';
import type { IRole } from '@graneet/business-logic';

import { useDeleteRole, useUsersUsingRole } from 'features/role/services/role.api';
import { UserImpactedCallout } from 'features/user/components/UserImpactedCallout';
import { UserInvitationImpactedCallout } from 'features/user/components/UserInvitationImpactedCallout';
import { QueryWrapper } from 'features/api/components/QueryWrapper';

interface RoleDeleteModalProps {
  modal: UseDisclosureReturn;

  role: IRole;
}

const RoleDeleteModalInternal: FC<RoleDeleteModalProps> = ({ modal, role }) => {
  const { t } = useTranslation(['global', 'user']);

  const usersUsingRole = useUsersUsingRole(role.id);

  const deleteRoleMutation = useDeleteRole();

  const numberOfUsersImpacted = usersUsingRole.data.users.length || 0;
  const numberOfInvitationsImpacted = usersUsingRole.data.userInvitations.length || 0;

  const handleRoleDeleted = useCallback(async () => {
    deleteRoleMutation.mutate(role.id, {
      onSuccess: () => {
        modal.onClose();
      },
    });
  }, [deleteRoleMutation, modal, role.id]);

  return (
    <>
      <Box w="100%">
        {numberOfUsersImpacted > 0 && (
          <Box mb={3}>
            <UserImpactedCallout users={usersUsingRole.data.users}>
              <Trans t={t} i18nKey="user:deleteRoleModal.callout" count={numberOfUsersImpacted}>
                <Text as="span" fontWeight={600}>
                  displayed_data
                </Text>
                description_end
              </Trans>
            </UserImpactedCallout>
          </Box>
        )}

        {numberOfInvitationsImpacted > 0 && (
          <Box mb={3}>
            <UserInvitationImpactedCallout userInvitations={usersUsingRole.data.userInvitations}>
              <Trans t={t} i18nKey="user:deleteRoleModal.callout_invitation" count={numberOfInvitationsImpacted}>
                <Text as="span" fontWeight={600}>
                  displayed_data
                </Text>
                description_end
              </Trans>
            </UserInvitationImpactedCallout>
          </Box>
        )}

        {numberOfUsersImpacted === 0 && numberOfInvitationsImpacted === 0 && t('user:deleteRoleModal.description')}
      </Box>

      {(numberOfUsersImpacted > 0 || numberOfInvitationsImpacted > 0) && (
        <Modal.PrimaryButton onClick={modal.onClose}>{t('global:words.c.back')}</Modal.PrimaryButton>
      )}

      {numberOfUsersImpacted === 0 && numberOfInvitationsImpacted === 0 && (
        <Modal.PrimaryButton
          onClick={handleRoleDeleted}
          isLoading={deleteRoleMutation.isPending}
          variant="outline"
          colorScheme="red"
        >
          {t('global:words.c.confirm')}
        </Modal.PrimaryButton>
      )}

      {numberOfUsersImpacted === 0 && numberOfInvitationsImpacted === 0 && (
        <Modal.Close isDisabled={deleteRoleMutation.isPending} />
      )}
    </>
  );
};

export const RoleDeleteModal: FC<RoleDeleteModalProps> = ({ modal, role }) => {
  const { t } = useTranslation(['user']);

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t('user:deleteRoleModal.title')}>
      <QueryWrapper>
        <RoleDeleteModalInternal role={role} modal={modal} />
      </QueryWrapper>
    </Modal>
  );
};
