import type { FC } from 'react';
import type { InputProps } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { TextField } from '@graneet/lib-ui';

import { useStore } from 'store/store';
import { useAutoNumberingQuoteRow } from 'features/quote/hooks/useAutoNumberingQuoteRow';
import type { ContractEditionForm, ItemFieldName, LotFieldName } from 'features/contract/forms/contract-edition.form';

export interface ContractDeepTableCodeRowProps {
  id: string | number;
  type: 'lot' | 'job';
  name: LotFieldName<'code'> | ItemFieldName<'code'>;
  input: InputProps;
  onFocus(): void;
  onBlur(): void;
  isDisabled: boolean;
}

export const ContractDeepTableCodeRow: FC<ContractDeepTableCodeRowProps> = ({
  id,
  type,
  name,
  input,
  onFocus,
  onBlur,
  isDisabled,
}) => {
  const autoNumberingIsActivated = useStore((state) => state.autoNumberingIsActivated);

  const autoCode = useAutoNumberingQuoteRow({
    id: id.toString(),
    type,
  });

  return (
    <>
      {autoNumberingIsActivated && (
        <Text fontSize="1rem" display={autoNumberingIsActivated ? 'flex' : 'none'} noOfLines={2}>
          {autoCode}
        </Text>
      )}

      <TextField<ContractEditionForm>
        name={name}
        inputProps={input}
        onFocus={onFocus}
        onBlur={onBlur}
        isDisabled={isDisabled}
        display={autoNumberingIsActivated ? 'none' : 'flex'}
        noBorder
      />
    </>
  );
};
