import type { CellClassParams, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';

import { AmountCell } from '../../components/columns/AmountCell/AmountCell';
import { AmountEditorCell } from '../../components/columns/AmountCell/AmountEditorCell';
import type { AGColDef } from '../../types';

import { useQuoteBasicItemUpdateUnitFlatCostAmount } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateUnitFlatCostAmount';
import { useQuoteComponentUpdateUnitFlatCostAmount } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateUnitFlatCost';
import { useQuoteSubItemUpdateUnitFlatCostAmount } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdateUnitFlatCos';
import { useQuoteOptionalItemUpdateUnitFlatCostAmount } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateUnitFlatCostAmount';
import { useQuoteHiddenCostItemUpdateUnitFlatCostAmount } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemUpdateUnitFlatCostAmount';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useUnitFlatCostAmountColumnDef(isEditable: boolean) {
  const quoteBasicItemUpdateUnitFlatCostAmount = useQuoteBasicItemUpdateUnitFlatCostAmount();
  const quoteSubItemUpdateUnitFlatCostAmount = useQuoteSubItemUpdateUnitFlatCostAmount();
  const quoteComponentUpdateUnitFlatCostAmount = useQuoteComponentUpdateUnitFlatCostAmount();
  const quoteOptionalItemUpdateUnitFlatCostAmount = useQuoteOptionalItemUpdateUnitFlatCostAmount();
  const quoteHiddenCostItemUpdateUnitFlatCostAmount = useQuoteHiddenCostItemUpdateUnitFlatCostAmount();

  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'unitFlatCostAmount',
      width,
      index,
      headerName: label,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressMovable: false,
      editable: (params) => {
        if (!isEditable) return false;
        switch (params.data?.content.type) {
          case 'QuoteBasicItem':
          case 'QuoteSubItem':
          case 'QuoteComponent':
          case 'QuoteOptionalItem':
          case 'QuoteHiddenCostItem':
            return params.data.childrenId.length === 0;
          default:
            return false;
        }
      },
      sortable: false,
      cellStyle: { textAlign: 'right' },
      cellClass: (params: CellClassParams) => {
        if (params.node.footer || params.data?.content.type === 'QuoteLot') return 'no-focus read-only';
        switch (params.data?.content.type) {
          case 'QuoteBasicItem':
          case 'QuoteSubItem':
          case 'QuoteComponent':
          case 'QuoteOptionalItem':
          case 'QuoteHiddenCostItem':
            return params.data.childrenId.length === 0 ? '' : 'no-focus read-only';
          default:
            return 'no-focus read-only';
        }
      },
      cellRenderer: AmountCell,
      cellRendererParams: {
        mandatory: true,
        couldBeCalculated: true,
      },
      cellEditor: AmountEditorCell,
      valueGetter: (params) => {
        if (!params.data) {
          return null;
        }
        switch (params.data.content.type) {
          case 'QuoteComponent':
          case 'QuoteBasicItem':
          case 'QuoteHiddenCostItem':
          case 'QuoteOptionalItem':
          case 'QuoteSubItem':
            return params.data?.content.unitFlatCostAmount;
          case 'QuoteLot':
            return '';
          default:
            throw new Error('Type unknown');
        }
      },
      valueSetter: (params) => {
        const newValue = params.newValue.replace(',', '.');

        if (params.oldValue?.toString() === newValue?.toString()) {
          return false;
        }

        switch (params.data?.content.type) {
          case 'QuoteBasicItem': {
            executeCommand(quoteBasicItemUpdateUnitFlatCostAmount(params.data.id, newValue), quote);
            return true;
          }
          case 'QuoteOptionalItem': {
            executeCommand(quoteOptionalItemUpdateUnitFlatCostAmount(params.data.id, newValue), quote);
            return true;
          }
          case 'QuoteHiddenCostItem': {
            executeCommand(quoteHiddenCostItemUpdateUnitFlatCostAmount(params.data.id, newValue), quote);
            return true;
          }
          case 'QuoteSubItem': {
            executeCommand(quoteSubItemUpdateUnitFlatCostAmount(params.data.id, newValue), quote);
            return true;
          }
          case 'QuoteComponent': {
            executeCommand(quoteComponentUpdateUnitFlatCostAmount(params.data.id, newValue), quote);
            return true;
          }
          default:
            return false;
        }
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [
      quote,
      isEditable,
      quoteBasicItemUpdateUnitFlatCostAmount,
      quoteOptionalItemUpdateUnitFlatCostAmount,
      quoteHiddenCostItemUpdateUnitFlatCostAmount,
      executeCommand,
      quoteSubItemUpdateUnitFlatCostAmount,
      quoteComponentUpdateUnitFlatCostAmount,
    ],
  );
}
