import { createQuoteSubItemAddedCallback } from './createQuoteSubItemAddedCallback';
import { createQuoteSubItemDeletedCallback } from './createQuoteSubItemDeletedCallback';
import { createQuoteSubItemUpdatedCallback } from './createQuoteSubItemUpdatedCallback';

import type { QuoteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';

export const quoteSubItemCallbacks = (quoteId: String) => [
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteSubItem:added',
    callback: (message: QuoteWsMessage) => createQuoteSubItemAddedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteSubItem:deleted',
    callback: (message: QuoteWsMessage) => createQuoteSubItemDeletedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteSubItem:updated',
    callback: (message: QuoteWsMessage) => createQuoteSubItemUpdatedCallback(message),
  },
];
