import { multiplyFloating, roundFloating } from '../common/math.util';
import { getCalculatedTotalMargin } from '../margin/margin.util';
import type { IDiscount } from '../discount/discount.type';
import { DISCOUNT_TYPES } from '../discount/discount.type';
import {
  computeVatDistributionDiscountedWithBasesMethod,
  getTotalVATAmountFromVATDistribution,
  mergeVATDistribution,
  computeVatDistributionDiscountedWithBasesRoundedMethod,
} from '../vat/vat-rates.util';
import type { IQuoteJob } from '../quote-job/quote-job-type';
import type { IQuoteLot } from '../quote-lot/quote-lot.type';
import type { IMargin } from '../margin/margin.type';
import { getJobWorkForceAmount } from '../component-type/component-type.util';
import type { ICustomDiscount } from '../custom-discount/custom-discount.type';
import { getSumCustomDiscountsAmountExVAT } from '../custom-discount/custom-discount.util';
import type { Raw } from '../common/entity.type';
import { getDiscountAmountExVAT } from '../discount/discount.util';
import type { IVatBase } from '../vat/vat-base.type';
import { VAT_METHOD } from '../vat/vat-method.type';
import type { IVatBases } from '../vat/vat-bases.type';

import type { IQuote } from './quote.type';
import { QUOTE_STATUS } from './quote.type';
import type { IQuoteJobExportData, IQuoteLotExportData } from './dtos/export/quote-generate-export-data.dto';
import type { IQuotation } from './quotation.type';
import { QuoteStatus } from './quotation.type';

/**
 * Vérifie le status du devis pour savoir s'il est en éditable
 */
export const isQuoteEditable = (quote: IQuote): boolean => quote.status === QUOTE_STATUS.DRAFT;

export const isQuoteInformationEditable = (quote: IQuote): boolean =>
  [QUOTE_STATUS.IMPORTING, QUOTE_STATUS.DRAFT].includes(quote.status);

export const isQuotationInformationEditable = (quote: IQuotation): boolean =>
  [QuoteStatus.Importing, QuoteStatus.Draft].includes(quote.status);

export const isQuoteBeingImported = (quote: IQuote): boolean => quote.status === QUOTE_STATUS.IMPORTING;

export const isQuoteAccepted = (quote: IQuote): boolean => quote.status === QUOTE_STATUS.ACCEPTED;

export const canQuoteBeRevertedToDraft = (quote: IQuote): boolean => quote.status === QUOTE_STATUS.COMPLETED;

export const isQuoteLinkedToContract = (quote: IQuote): boolean =>
  quote.status === QUOTE_STATUS.ACCEPTED && !!quote.project;

export const computeAmountExVATWithDisbursementAndMargin = (disbursementExVAT: number, margin: Raw<IMargin>): number =>
  roundFloating(
    Number.isFinite(disbursementExVAT) ? multiplyFloating(disbursementExVAT, getCalculatedTotalMargin(margin)) : 0,
  );

export const computeAmountExVATWithDisbursementAndTotalMargin = (
  disbursementExVAT: number,
  totalMargin: number,
): number => roundFloating(Number.isFinite(disbursementExVAT) ? multiplyFloating(disbursementExVAT, totalMargin) : 0);

export const isComputableMargin = (disbursementExVAT: number, amountExVAT: number): boolean =>
  !((disbursementExVAT === 0 && amountExVAT !== 0) || (disbursementExVAT !== 0 && amountExVAT === 0));

export const isAmountExVATEditable = (discount: IDiscount, disbursementExVAT: number): boolean => {
  if (discount && discount.type === DISCOUNT_TYPES.PERCENTAGE && discount.percentage === 100) {
    return false;
  }
  return disbursementExVAT !== 0;
};

/**
 * Project address field is disabled when the client address is the same as the projectAddress
 */
export const isProjectAddressClientAddress = (quote: IQuote): boolean =>
  !!quote &&
  !!quote.client &&
  !!quote.projectAddress &&
  !!quote.client.address &&
  quote.client.address.address === quote.projectAddress.address &&
  quote.client.address.city === quote.projectAddress.city &&
  quote.client.address.postalCode === quote.projectAddress.postalCode &&
  quote.client.address.country === quote.projectAddress.country;

/**
 * Project address field is disabled when the quote is linked to a project
 * or when the client address is the same as the projectAddress
 */
export const isProjectAddressAutofilled = (quote: IQuote): boolean =>
  !!quote && (!!quote.project || isProjectAddressClientAddress(quote));

export interface IQuoteSelectionAmounts {
  disbursementExVAT: number;

  amountExVAT: number;

  workForceAmount: number;

  optionalWorkForceAmount: number;

  optionalAmountExVAT: number;

  optionalDisbursementExVAT: number;
}
/**
 * Compute rootLot nested lots jobs and lots amounts
 */
const computeQuoteSelectionAmounts = (rootLot: IQuoteLotExportData): IQuoteLotExportData => {
  const sumLotsAmounts = (
    lots: Array<IQuoteLotExportData>,
    defaultValues: Partial<IQuoteSelectionAmounts> = {},
  ): IQuoteSelectionAmounts =>
    lots.reduce<IQuoteSelectionAmounts>(
      (acc, current) => ({
        disbursementExVAT: (acc.disbursementExVAT || 0) + (current.disbursementExVAT || 0),
        amountExVAT: (acc.amountExVAT || 0) + (current.amountExVAT || 0),
        workForceAmount: (acc.workForceAmount || 0) + (current.workForceAmount || 0),

        // Optional amounts
        optionalAmountExVAT: (acc.optionalAmountExVAT || 0) + (current.optionalAmountExVAT || 0),
        optionalDisbursementExVAT: (acc.optionalDisbursementExVAT || 0) + (current.optionalDisbursementExVAT || 0),
        optionalWorkForceAmount: (acc.optionalWorkForceAmount || 0) + (current.optionalWorkForceAmount || 0),
      }),
      {
        disbursementExVAT: defaultValues.disbursementExVAT || 0,
        amountExVAT: defaultValues.amountExVAT || 0,
        workForceAmount: defaultValues.workForceAmount || 0,

        optionalWorkForceAmount: defaultValues.optionalWorkForceAmount || 0,
        optionalAmountExVAT: defaultValues.optionalAmountExVAT || 0,
        optionalDisbursementExVAT: defaultValues.optionalDisbursementExVAT || 0,
      },
    );

  const sumJobsAmounts = (
    jobs: Array<IQuoteJobExportData>,
    defaultValues: Partial<IQuoteSelectionAmounts> = {},
  ): IQuoteSelectionAmounts =>
    jobs.reduce<IQuoteSelectionAmounts>(
      (acc, current) => {
        if (current.isOptional)
          return {
            disbursementExVAT: acc.disbursementExVAT || 0,
            amountExVAT: acc.amountExVAT || 0,
            workForceAmount: acc.workForceAmount || 0,

            // Optional amounts
            optionalAmountExVAT: (acc.optionalAmountExVAT || 0) + (current.amountExVAT || 0),
            optionalDisbursementExVAT: (acc.optionalDisbursementExVAT || 0) + (current.disbursementExVAT || 0),
            optionalWorkForceAmount: (acc.optionalWorkForceAmount || 0) + (current.workForceAmount || 0),
          };

        return {
          disbursementExVAT: (acc.disbursementExVAT || 0) + (current.disbursementExVAT || 0),
          amountExVAT: (acc.amountExVAT || 0) + (current.amountExVAT || 0),
          workForceAmount: (acc.workForceAmount || 0) + (current.workForceAmount || 0),

          optionalAmountExVAT: acc.optionalAmountExVAT || 0,
          optionalDisbursementExVAT: acc.optionalDisbursementExVAT || 0,
          optionalWorkForceAmount: acc.optionalWorkForceAmount || 0,
        };
      },
      {
        disbursementExVAT: defaultValues.disbursementExVAT || 0,
        amountExVAT: defaultValues.amountExVAT || 0,
        workForceAmount: defaultValues.workForceAmount || 0,

        // Default Optional amounts
        optionalWorkForceAmount: defaultValues.optionalWorkForceAmount || 0,
        optionalAmountExVAT: defaultValues.optionalAmountExVAT || 0,
        optionalDisbursementExVAT: defaultValues.optionalDisbursementExVAT || 0,
      },
    );

  // Re-compute tree amounts
  const computeLotAmounts = (lot: IQuoteLotExportData): IQuoteLotExportData => {
    const { jobs } = lot;

    const {
      disbursementExVAT: jobsDisbursementExVAT,
      amountExVAT: jobsAmountExVAT,
      workForceAmount: jobsWorkForceAmount,
      optionalWorkForceAmount: jobsOptionalWorkForceAmount,
      optionalAmountExVAT: jobsOptionalAmountExVAT,
      optionalDisbursementExVAT: jobsOptionalDisbursementExVAT,
    } = sumJobsAmounts(jobs);

    // Tree leaves
    if (!lot.lots.length) {
      return {
        ...lot,
        disbursementExVAT: jobsDisbursementExVAT,
        amountExVAT: jobsAmountExVAT,
        workForceAmount: jobsWorkForceAmount,
        optionalAmountExVAT: jobsOptionalAmountExVAT,
        optionalDisbursementExVAT: jobsOptionalDisbursementExVAT,
        optionalWorkForceAmount: jobsOptionalWorkForceAmount,
        lots: [],
      };
    }
    // Recursion
    const lots = lot.lots.map(computeLotAmounts);

    const {
      disbursementExVAT,
      amountExVAT,
      workForceAmount,
      optionalAmountExVAT,
      optionalWorkForceAmount,
      optionalDisbursementExVAT,
    } = sumLotsAmounts(lots, {
      disbursementExVAT: jobsDisbursementExVAT,
      amountExVAT: jobsAmountExVAT,
      workForceAmount: jobsWorkForceAmount,
      optionalWorkForceAmount: jobsOptionalWorkForceAmount,
      optionalDisbursementExVAT: jobsOptionalDisbursementExVAT,
      optionalAmountExVAT: jobsOptionalAmountExVAT,
    });

    return {
      ...lot,
      // Selection amounts
      disbursementExVAT,
      amountExVAT,
      workForceAmount,
      optionalAmountExVAT,
      optionalWorkForceAmount,
      optionalDisbursementExVAT,
      lots,
    };
  };

  return computeLotAmounts(rootLot);
};

export type IQuoteLotsSelectionMap = Record<
  IQuoteLot['id'],
  {
    note: boolean;
  }
>;
export type IQuoteJobsSelectionMap = Record<
  IQuoteJob['id'],
  {
    note: boolean;
    images: boolean;
  }
>;
export interface IQuoteBuildRootLotOptions {
  // To specify whether we include notes, images and components in the quote
  excludeNotes: boolean;

  excludeImages: boolean;

  excludeComponents: boolean;

  // To specify whether we calculate work force amount for a job
  displayWorkForce: boolean;
}
/**
 * Removes lot, jobs and notes not included in selectedJobs and selectedLots from the tree
 */
export const buildRootLotFromSelectedJobsAndLots = (
  rootLot: IQuoteLotExportData,
  selectedJobs: IQuoteJobsSelectionMap,
  selectedLots: IQuoteLotsSelectionMap,
  options: IQuoteBuildRootLotOptions,
): IQuoteLotExportData => {
  const extractLotSelection = (lot: IQuoteLotExportData): IQuoteLotExportData => {
    const { jobs, lots } = lot;

    function excludeRows<T extends IQuoteLotExportData | IQuoteJobExportData>(
      lotsOrJobs: IQuoteJobsSelectionMap | IQuoteLotsSelectionMap,
    ): (lotOrJob: T) => T {
      return (lotOrJob: T): T => {
        const modifiedLotOrJob = { ...lotOrJob };

        /**
         * Excluding note from the export
         */

        if (modifiedLotOrJob.note && (options.excludeNotes || !lotsOrJobs[modifiedLotOrJob.id].note)) {
          modifiedLotOrJob.note = null;
        }

        /**
         * Excluding images from the export
         */
        if (
          'quoteJobFile' in modifiedLotOrJob &&
          modifiedLotOrJob.quoteJobFile &&
          (options.excludeImages || !(lotsOrJobs as IQuoteJobsSelectionMap)[modifiedLotOrJob.id].images)
        ) {
          modifiedLotOrJob.quoteJobFile = [];
        }

        /**
         * Calculate job workforce
         */
        if (options.displayWorkForce && 'previousJob' in modifiedLotOrJob) {
          modifiedLotOrJob.workForceAmount = getJobWorkForceAmount(
            modifiedLotOrJob.components,
            modifiedLotOrJob.quantity || 1,
          );
        }

        /**
         * Excluding components from the export
         */
        if ('components' in modifiedLotOrJob && modifiedLotOrJob.components && options.excludeComponents) {
          modifiedLotOrJob.components = [];
        }

        return modifiedLotOrJob;
      };
    }

    const jobsSelection = jobs
      .filter(({ id, isHiddenCost }) => !isHiddenCost && selectedJobs[id])
      .map(excludeRows<IQuoteJobExportData>(selectedJobs));

    // Nested lots
    const lotsSelection = lots.filter(({ id }) => selectedLots[id]).map(excludeRows<IQuoteLotExportData>(selectedLots));

    return {
      ...lot,
      jobs: jobsSelection,
      lots: lotsSelection.map(extractLotSelection),
    };
  };

  const rootLotWithSelection = extractLotSelection(rootLot);
  return computeQuoteSelectionAmounts(rootLotWithSelection);
};

export const getQuoteAmountsFromRootLot = (
  quote: IQuote,
  rootLot: IQuoteLot,
  vatDistribution: IVatBase[],
  discount?: IDiscount | null,
  customDiscounts?: ICustomDiscount[],
): Pick<
  IQuote,
  'amountWithoutDiscountExVAT' | 'amountWithDiscountExVAT' | 'amountExVAT' | 'amountIncVAT' | 'vatDistribution'
> => {
  const { amountExVAT: amountWithoutDiscountExVAT } = rootLot;

  const quoteNewAmountWithDiscountExVAT =
    amountWithoutDiscountExVAT - getDiscountAmountExVAT(discount, amountWithoutDiscountExVAT);
  const discountAmount = amountWithoutDiscountExVAT - quoteNewAmountWithDiscountExVAT;

  const customDiscountsAmountExVAT = getSumCustomDiscountsAmountExVAT(
    quote.customDiscounts || [],
    quoteNewAmountWithDiscountExVAT,
  );
  const quoteNewAmountExVAT = quoteNewAmountWithDiscountExVAT + customDiscountsAmountExVAT;
  const discountedVatDistribution =
    quote.vatMethod === VAT_METHOD.BASES_ROUNDED
      ? computeVatDistributionDiscountedWithBasesRoundedMethod(
          vatDistribution,
          quote.hasReversalOfLiability,
          discountAmount,
          customDiscounts || [],
        )
      : computeVatDistributionDiscountedWithBasesMethod(
          vatDistribution,
          quote.hasReversalOfLiability,
          discountAmount,
          customDiscounts || [],
        );

  const amountTotalVAT = getTotalVATAmountFromVATDistribution(discountedVatDistribution);

  const amountIncVAT = quoteNewAmountExVAT + amountTotalVAT;
  return {
    amountWithoutDiscountExVAT,
    amountWithDiscountExVAT: quoteNewAmountWithDiscountExVAT,
    amountExVAT: quoteNewAmountExVAT,
    amountIncVAT,
    vatDistribution: discountedVatDistribution,
  };
};

/**
 * Compute new quote amounts based the rootLot parameter tree amounts
 * The discount and custom discount parameter must be specified only if the quote selection is complete
 */
export const computeNewQuoteAmounts = <T extends IQuote>(
  quote: T,
  rootLot: IQuoteLot,
  discount?: IDiscount | null,
  customDiscounts?: ICustomDiscount[],
): T => {
  const getJobsVATDistribution = (jobs: IQuoteJob[]): IVatBases => {
    const jobsVatDistribution: IVatBases = [];

    jobs.forEach((job) => {
      if (!job.isOptional)
        jobsVatDistribution.push({
          vatRate: job.vatRate,
          amount: multiplyFloating(job.vatRate, job.amountExVAT),
          base: job.amountExVAT,
        });
    });

    return jobsVatDistribution;
  };

  const getLotVATDistribution = (acc: IVatBases, lot: IQuoteLot): IVatBases => [
    ...acc,
    ...getJobsVATDistribution(lot.jobs),
    ...lot.lots.reduce(getLotVATDistribution, []),
  ];

  const vatDistribution = mergeVATDistribution([
    ...getJobsVATDistribution(rootLot.jobs),
    ...rootLot.lots.reduce(getLotVATDistribution, []),
  ]);

  return {
    ...quote,
    discount,
    customDiscounts,
    ...getQuoteAmountsFromRootLot(quote, rootLot, vatDistribution, discount, customDiscounts),
  };
};
