import type { FC } from 'react';
import { DeepTable, RequiredText } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

export const InvoiceDeepTableHeader: FC = () => {
  const { t } = useTranslation(['container']);

  return (
    <DeepTable.Header bg="white">
      <DeepTable.Cell />

      <DeepTable.Cell>{t('container:fields.code')}</DeepTable.Cell>

      <DeepTable.Cell>
        <RequiredText>{t('container:fields.name')}</RequiredText>
      </DeepTable.Cell>

      <DeepTable.Cell>
        <RequiredText>{t('container:fields.unit')}</RequiredText>
      </DeepTable.Cell>

      <DeepTable.Cell>
        <RequiredText>{t('container:fields.quantity')}</RequiredText>
      </DeepTable.Cell>

      <DeepTable.Cell>
        <RequiredText>{t('container:fields.unitPrice')}</RequiredText>
      </DeepTable.Cell>

      <DeepTable.Cell>
        <RequiredText>{t('container:fields.VAT')}</RequiredText>
      </DeepTable.Cell>

      <DeepTable.Cell>{t('container:totals.totalExVAT')}</DeepTable.Cell>

      <DeepTable.Cell />
    </DeepTable.Header>
  );
};
