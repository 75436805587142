import type {
  QuoteSalesDiscountCreateDTO,
  QuoteSalesDiscountDeleteDTO,
  QuoteSalesDiscountUpdateDTO,
  QuoteSalesDiscountsApi,
} from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteSalesDiscountProxyApi {
  private static quoteSalesDiscountProxyApi: QuoteSalesDiscountProxyApi;

  private readonly quoteSalesDiscountsApi: QuoteSalesDiscountsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteSalesDiscountsApi = externalApi.getQuoteSalesDiscountsApi();
  }

  public static getQuoteSalesDiscountProxyApi(externalApi: ExternalApi): QuoteSalesDiscountProxyApi {
    if (!this.quoteSalesDiscountProxyApi) {
      QuoteSalesDiscountProxyApi.quoteSalesDiscountProxyApi = new QuoteSalesDiscountProxyApi(externalApi);
    }
    return QuoteSalesDiscountProxyApi.quoteSalesDiscountProxyApi;
  }

  public async createQuoteSalesDiscount(quoteSalesDiscountsCreateDTO: QuoteSalesDiscountCreateDTO) {
    return proxyHelper(async () => this.quoteSalesDiscountsApi.createQuoteSalesDiscount(quoteSalesDiscountsCreateDTO));
  }

  public async deleteQuoteSalesDiscount(quoteSalesDiscountDeleteDTO: QuoteSalesDiscountDeleteDTO) {
    return proxyHelper(async () => this.quoteSalesDiscountsApi.deleteQuoteSalesDiscount(quoteSalesDiscountDeleteDTO));
  }

  public async updateQuoteSalesDiscount(
    quoteSalesDiscountId: string,
    quoteSalesDiscountUpdateDTO: QuoteSalesDiscountUpdateDTO,
  ) {
    return proxyHelper(async () =>
      this.quoteSalesDiscountsApi.updateQuoteSalesDiscount(quoteSalesDiscountId, quoteSalesDiscountUpdateDTO),
    );
  }
}
