import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import { Flex, HStack, useDisclosure } from '@chakra-ui/react';
import { ActionMenu, Fieldset, List, SelectableCard, SimpleCheckCircleIcon, Tooltip } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHiddenField, HiddenField, useFormContext } from 'graneet-form';
import type { IRole, IUser } from '@graneet/business-logic';

import type { UserFormValues } from '../constants/user.constant';

import { RoleEditModal } from 'features/role/components/RoleEditModal/RoleEditModal';
import { RoleDeleteModal } from 'features/role/components/RoleDeleteModal';
import { Rule } from 'features/form/rules/Rule';
import { useAppContext } from 'features/app/contexts/AppContext';
import { useRoles } from 'features/role/services/role.api';
import { isAdminRole } from 'features/role/services/role.util';

interface RoleCardProps {
  role: IRole;

  user: IUser | null;

  onRoleSelected(roleId: number): void;

  isSelected: boolean;

  onRoleDeleted(deletedRole: IRole): void;

  onRoleDuplicated(duplicatedRole: IRole): void;

  onRoleEdited(editedRole: IRole): void;
}

const RoleCard: FC<RoleCardProps> = ({
  role,
  user,
  isSelected,
  onRoleSelected,
  onRoleEdited,
  onRoleDuplicated,
  onRoleDeleted,
}) => {
  const { t } = useTranslation(['user']);

  const { currentUser } = useAppContext();

  const isAdmin = isAdminRole(role);
  const isDisabled = !isAdmin && currentUser?.id === user?.id;

  const handleRoleSelected = useCallback(() => {
    onRoleSelected(role.id);
  }, [onRoleSelected, role.id]);

  const handleRoleDuplicated = useCallback(() => {
    onRoleDuplicated({
      ...role,
      name: t('user:role.roleCopiedName', { name: role.name }),
    });
  }, [onRoleDuplicated, role, t]);

  const handleRoleDeleted = useCallback(() => {
    onRoleDeleted(role);
  }, [onRoleDeleted, role]);

  const handleRoleEdited = useCallback(() => {
    onRoleEdited(role);
  }, [onRoleEdited, role]);

  const adminPermissionsTranslation = t('user:role.administrator.permissions', {
    returnObjects: true,
  });

  // Without closeDelay, tooltips stay open when modal is opened
  // see https://github.com/chakra-ui/chakra-ui/issues/5304
  return (
    <Tooltip closeDelay={5} label={t('user:tooltip.cannotUpdateProfile')} placement="top" isDisabled={!isDisabled}>
      <SelectableCard
        title={isAdmin ? t('user:role.administrator.name') : role.name}
        w="15rem"
        isSelected={isSelected}
        isDisabled={isDisabled}
        topRightChildren={
          !isAdmin && (
            <ActionMenu iconSize={3}>
              <ActionMenu.Edit onClick={handleRoleEdited} />
              <ActionMenu.Duplicate onClick={handleRoleDuplicated} />
              <ActionMenu.Delete onClick={handleRoleDeleted} />
            </ActionMenu>
          )
        }
        onSelected={handleRoleSelected}
      >
        {isAdmin && (
          <List>
            {adminPermissionsTranslation.map((adminPermission) => (
              <List.Item key={adminPermission} icon={<SimpleCheckCircleIcon boxSize={4} />} label={adminPermission} />
            ))}
          </List>
        )}
      </SelectableCard>
    </Tooltip>
  );
};

interface UserRoleFieldSetProps {
  user: IUser | null;
}

export const UserRoleFieldSet: FC<UserRoleFieldSetProps> = ({ user }) => {
  const { t } = useTranslation(['global', 'user']);

  const roles = useRoles();

  const initialRoleValuesRef = useRef<null | IRole>(null);
  const roleIdEditedRef = useRef<null | number>(null);
  const editRoleModal = useDisclosure({
    onClose() {
      initialRoleValuesRef.current = null;
      roleIdEditedRef.current = null;
    },
  });

  const deletedRoleRef = useRef<null | IRole>(null);
  const deleteRoleModal = useDisclosure({
    onClose() {
      deletedRoleRef.current = null;
    },
  });

  const form = useFormContext<UserFormValues>();
  const roleHiddenField = useHiddenField(form, 'role');
  const { setValue: setRole, value: role } = roleHiddenField;

  const onRoleEdited = useCallback(
    (editedRole: IRole) => {
      initialRoleValuesRef.current = editedRole;
      roleIdEditedRef.current = editedRole.id;
      editRoleModal.onOpen();
    },
    [editRoleModal],
  );

  const onRoleDuplicated = useCallback(
    (duplicatedRole: IRole) => {
      initialRoleValuesRef.current = duplicatedRole;
      editRoleModal.onOpen();
    },
    [editRoleModal],
  );

  const onRoleDeleted = useCallback(
    (deletedRole: IRole) => {
      deletedRoleRef.current = deletedRole;
      deleteRoleModal.onOpen();
    },
    [deleteRoleModal],
  );

  return (
    <>
      <HiddenField {...roleHiddenField}>
        <Rule.IsRequired />
      </HiddenField>

      <Fieldset
        handle="role"
        title={t('user:userRole.title')}
        legend={t('user:userRole.legend')}
        validationNames={[roleHiddenField.name]}
        noCard
      >
        <RoleEditModal
          modal={editRoleModal}
          roles={roles.data}
          initialRoleValues={initialRoleValuesRef.current}
          roleIdEdited={roleIdEditedRef.current}
          user={user}
          isAdministrator={user?.role ? isAdminRole(user.role) : false}
        />

        {deletedRoleRef.current && <RoleDeleteModal modal={deleteRoleModal} role={deletedRoleRef.current} />}

        {/* margin and padding avoid shadow cut because of overflow auto */}
        <Flex overflow="auto" m={-5} p={5}>
          <HStack spacing={8} alignItems="stretch" pt={3} pb={3} h="18rem">
            {roles.data.map((companyRole) => (
              <RoleCard
                key={companyRole.name}
                role={companyRole}
                user={user}
                isSelected={role === companyRole.id}
                onRoleSelected={setRole}
                onRoleEdited={onRoleEdited}
                onRoleDuplicated={onRoleDuplicated}
                onRoleDeleted={onRoleDeleted}
              />
            ))}

            <SelectableCard.AddItemCard
              w="15rem"
              title={t('user:actions.createProfile')}
              onClick={editRoleModal.onOpen}
            />
          </HStack>
        </Flex>
      </Fieldset>
    </>
  );
};
