import { useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { HiddenField, useFormContext } from 'graneet-form';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import './PreviewPDF.css';

interface PreviewPDFProps {
  fileSource: string;

  scale?: number;
}

export const PreviewPDF = <T extends { pdfPageNumber: number }>({ fileSource, scale = 1 }: PreviewPDFProps) => {
  const [numPage, setNumPages] = useState(0);
  const { setFormValues } = useFormContext<T>();
  const [initialWidth, setInitialWidth] = useState(0);

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

  useEffect(() => {
    setFormValues({
      pdfPageNumber: numPage,
    } as T);
  }, [numPage, setFormValues]);
  const onDocumentLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  }, []);

  const pdfWrapper = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth(pdfWrapper.current.getBoundingClientRect().width);
    }
  }, []);

  return (
    <div id="pdfWrapper" ref={pdfWrapper}>
      <HiddenField name="pdfPageNumber" />
      <Document file={fileSource} onLoadSuccess={onDocumentLoadSuccess} loading={<></>} error={<></>}>
        {Array.from(new Array(numPage), (_, index) => (
          <Page width={initialWidth} key={`page_${index + 1}`} pageNumber={index + 1} loading={<></>} scale={scale} />
        ))}
      </Document>
    </div>
  );
};
