import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';

import { ViewQuoteScreenSkeleton } from 'features/quotation/quote/components/skeleton/ViewQuoteScreenSkeleton';
import { QuotationEdit } from 'features/quotation/QuotationEdit';
import { QuoteProvider } from 'features/quotation/quote/hooks/useQuote';
import { useFetchQuote } from 'features/quotation/quote-common/hooks/useFetchQuote';

export const ViewQuoteEditScreen = () => {
  const { refetch, loading, quoteComposed, quoteCanBeLoad } = useFetchQuote();

  if (loading || !quoteComposed || !quoteCanBeLoad) {
    return <ViewQuoteScreenSkeleton />;
  }

  return (
    <QuoteProvider refetchQuote={refetch}>
      <QuotationEdit handleOnClose={refetch} quoteComposed={quoteComposed} />
    </QuoteProvider>
  );
};
