import type { Action } from '@graneet/lib-ui';
import { SimpleDeleteIcon, SimpleDownloadIcon, SimpleEditIcon, SimpleEmailIcon, SimpleViewIcon } from '@graneet/lib-ui';
import { t } from 'i18next';
import {
  DOWN_PAYMENT_STATUSES,
  canCancelDownPayment,
  doesProgressStatementsAllowDownPaymentEdition,
} from '@graneet/business-logic';

import type { DownPaymentActionsParams } from './down-payment.actions.type';

import { getPDFActionProps } from 'features/common/services/common.actions.util';

export function getCompleteAction({
  hasUpdatePermission,
  isLoading,
  onSetStatusToComplete,
}: DownPaymentActionsParams): Action {
  return {
    label: t('downPayment:actions.complete'),
    tooltip: !hasUpdatePermission ? t('global:errors.disabledAction') : undefined,
    onClick: onSetStatusToComplete,
    isLoading,
    isDisabled: !hasUpdatePermission,
  };
}

export function getDeleteAction({
  downPayment,
  onDelete,
  isLoading,
  hasCreatePermission,
}: DownPaymentActionsParams): Action | undefined {
  if (downPayment.status !== DOWN_PAYMENT_STATUSES.DRAFT) {
    return undefined;
  }

  const tooltip = (() => {
    if (!hasCreatePermission) {
      return t('global:errors.disabledAction');
    }
    if (downPayment.invoiceNumber) {
      return t('statement:delete.errors.invoiceNumberSet');
    }
    return undefined;
  })();

  return {
    label: t('statement:delete.action'),
    warning: true,
    tooltip,
    icon: <SimpleDeleteIcon />,
    onClick: onDelete,
    isDisabled: !hasCreatePermission || !!downPayment.invoiceNumber,
    isLoading,
  };
}

export function getCancelAction(
  {
    downPayment,
    hasUpdatePermission,
    hasForceDraftPermission,
    progressStatements,
    onCancelWithCredit,
    onCancelWithoutCredit,
    isLoading,
  }: DownPaymentActionsParams,
  withCredit: boolean,
): Action | undefined {
  if (!downPayment.isInvoiced) {
    return undefined;
  }

  const missingPermission = !hasUpdatePermission || (!withCredit && !hasForceDraftPermission);
  const canEditDownPayment = doesProgressStatementsAllowDownPaymentEdition(progressStatements);
  const cancelErrors = canCancelDownPayment(downPayment);
  const isDisabled = missingPermission || !canEditDownPayment || !cancelErrors.ok;

  const tooltip = (() => {
    if (missingPermission) {
      return t('global:errors.disabledAction');
    }
    if (!canEditDownPayment) {
      return t('downPayment:tooltips.noUpdatePossible');
    }
    if (cancelErrors.causes.includes('accounting')) {
      return t('downPayment:tooltips.noUpdatePossibleAccounting');
    }
    if (cancelErrors.causes.includes('accounting-payment')) {
      return t('downPayment:tooltips.hasPaymentAccountingExported');
    }
    return undefined;
  })();

  return {
    label: withCredit ? t('credit:actions.create') : t('downPayment:actions.forceDraft'),
    icon: withCredit ? undefined : <SimpleEditIcon />,
    isLoading,
    tooltip,
    isDisabled,
    onClick: withCredit ? onCancelWithCredit : onCancelWithoutCredit,
  };
}

export function getEditAction({
  downPayment,
  progressStatements,
  hasCreatePermission,
  hasUpdatePermission,
  isLoading,
  onEdit,
}: DownPaymentActionsParams): Action | undefined {
  // Can only use edit outside of DRAFT if it is not invoiced
  if (downPayment.status !== DOWN_PAYMENT_STATUSES.DRAFT && downPayment.isInvoiced) {
    return undefined;
  }

  const missingPermission = !hasCreatePermission && downPayment.status === DOWN_PAYMENT_STATUSES.DRAFT;
  const canEditDownPayment = doesProgressStatementsAllowDownPaymentEdition(progressStatements);
  const cancelErrors = canCancelDownPayment(downPayment);
  const isDisabled = missingPermission || !canEditDownPayment || !cancelErrors.ok;

  const tooltip = (() => {
    if (!hasUpdatePermission) {
      return t('global:errors.disabledAction');
    }
    if (!canEditDownPayment) {
      return t('downPayment:tooltips.noUpdatePossible');
    }
    // We also need check if down payment is not exported in accounting before allow to edit it
    if (cancelErrors.causes.includes('accounting')) {
      return t('downPayment:tooltips.noUpdatePossibleAccounting');
    }
    if (cancelErrors.causes.includes('accounting-payment')) {
      return t('downPayment:tooltips.hasPaymentAccountingExported');
    }
    return undefined;
  })();

  return {
    icon: <SimpleEditIcon />,
    label: t('downPayment:actions.update'),
    tooltip,
    isLoading,
    isDisabled,
    onClick: onEdit,
  };
}

export function getPreviewPDFAction({ onViewPdf, pdf }: DownPaymentActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('global:pdf.viewPDF'),
    icon: <SimpleViewIcon />,
    ...getPDFActionProps(pdf),
    onClick: onViewPdf,
  };
}

export function getDownloadPDFAction({ onDownloadPdf, pdf }: DownPaymentActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('global:pdf.downloadPDF'),
    icon: <SimpleDownloadIcon />,
    ...getPDFActionProps(pdf),
    onClick: onDownloadPdf,
  };
}

export function getOpenMailingAction({ onOpenMailing, pdf }: DownPaymentActionsParams): Action | undefined {
  if (!pdf) {
    return undefined;
  }

  return {
    label: t('mailing:modal.send'),
    icon: <SimpleEmailIcon />,
    ...getPDFActionProps(pdf),
    onClick: onOpenMailing,
  };
}
