import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, List, Button, SimpleCheckCircleIcon } from '@graneet/lib-ui';
import { Text, Grid, GridItem, Flex, Box } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import type { IProject, ISubProject } from '@graneet/business-logic';

interface SubProjectSelectBillingTypeModalProps {
  isOpen: boolean;

  onClose(): void;

  isDirectDisabled: boolean;

  project: IProject;

  subProject: ISubProject;
}

export const SubProjectSelectBillingTypeModal: FC<SubProjectSelectBillingTypeModalProps> = ({
  isOpen,
  onClose,
  isDirectDisabled,
  project,
  subProject,
}) => {
  const { t } = useTranslation(['subProject']);

  const history = useHistory();

  const handleDirectBillingSelected = useCallback(() => {
    onClose();
    history.push(`/projects/${project.id}/statements/sub-projects/${subProject.id}/progress-statements/direct/create`);
  }, [history, onClose, project.id, subProject.id]);

  const handleProgressBillingSelected = useCallback(() => {
    onClose();
    history.push(`/projects/${project.id}/statements/sub-projects/${subProject.id}/progress-statements/create`);
  }, [history, onClose, project.id, subProject.id]);

  return (
    <Modal size="5xl" title={t('subProject:billingTypeSelectionModal.title')} isOpen={isOpen} onClose={onClose}>
      <Text mb={8}>{t('subProject:billingTypeSelectionModal.description')}</Text>
      <Grid templateColumns="1fr 1fr">
        <GridItem p={8}>
          <Flex direction="column" justifyContent="space-between" h="100%">
            <Box>
              <Text fontWeight="600" mb={4}>
                {t('subProject:billingTypeSelectionModal.directSection.title')}
              </Text>
              <List mb={8}>
                <List.Item
                  icon={<SimpleCheckCircleIcon />}
                  label={t('subProject:billingTypeSelectionModal.directSection.listItem')}
                />
              </List>
            </Box>
            <Button
              variant="outline"
              alignSelf="center"
              onClick={handleDirectBillingSelected}
              isDisabled={isDirectDisabled}
              tooltip={isDirectDisabled ? t('subProject:billingTypeSelectionModal.directSection.tooltip') : undefined}
              display="flex"
              margin="auto"
            >
              {t('subProject:billingTypeSelectionModal.directSection.action')}
            </Button>
          </Flex>
        </GridItem>
        <GridItem p={8} bg="gray.100" borderRadius="sm">
          <Flex direction="column" justifyContent="space-between" h="100%">
            <Box>
              <Text fontWeight="600" mb={4}>
                {t('subProject:billingTypeSelectionModal.progressSection.title')}
              </Text>
              <List mb={8}>
                {Array(4)
                  .fill(null)
                  .map((el, index) => (
                    <List.Item
                      // eslint-disable-next-line react/no-array-index-key
                      key={`checklist-${index}`}
                      icon={<SimpleCheckCircleIcon />}
                      label={t(`subProject:billingTypeSelectionModal.progressSection.checklist.${index}` as any)}
                    />
                  ))}
              </List>
            </Box>
            <Button alignSelf="center" onClick={handleProgressBillingSelected}>
              {t('subProject:billingTypeSelectionModal.progressSection.action')}
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </Modal>
  );
};
