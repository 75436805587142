import { VStack, useDisclosure } from '@chakra-ui/react';
import { STORAGE_STRATEGY, SimpleDotsIcon, UsersCard, usePaginatedData } from '@graneet/lib-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { QuoteContactDTO } from '@org/graneet-bff-client';
import type { IContact } from '@graneet/business-logic';
import { v4 as uuid } from 'uuid';

import { useQuote } from '../../hooks/useQuote';

import { useStore } from 'store/store';
import { useQuoteUpdateContact } from 'features/quotation/quote/hooks/useQuoteUpdateContact';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useContactRoles, useContactsAssociateToClient } from 'features/contact/services/contact.api';
import { ContactUpsertModal } from 'features/contact/components/modals/ContactUpsertModal';

export const QuoteContactsCard = () => {
  const { t } = useTranslation(['global', 'quote', 'clients', 'contacts']);
  const { quote } = useQuote();
  const { quoteContactsProxyApi } = useQuotationProxyApis();
  const quoteClient = useStore((state) => state.quoteClient);
  const quoteContacts = useStore((state) => state.quoteContacts);

  const contactRoles = useContactRoles();

  const quoteUpdateContact = useQuoteUpdateContact();
  const contactsAssociateToClientMutation = useContactsAssociateToClient();

  const [contactUpsert, setContactUpsert] = useState<QuoteContactDTO | null>(null);

  const upsertContactModal = useDisclosure();

  const onContactUpsert = useCallback(
    (contact?: QuoteContactDTO) => {
      if (contact) {
        setContactUpsert(contact);
      } else {
        setContactUpsert(null);
      }
      upsertContactModal.onOpen();
    },
    [upsertContactModal],
  );

  const getQuoteContactsPaginatedGetter = useCallback(
    (params: URLSearchParams | undefined) => quoteContactsProxyApi!.getAvailableContactsForQuote(params),
    [quoteContactsProxyApi],
  );

  const { data: usersData, updateFilters: updateProjectFiltersAndRefetch } = usePaginatedData(
    getQuoteContactsPaginatedGetter,
    { quoteId: quote.getId(), clientId: quoteClient?.id },
    undefined,
    STORAGE_STRATEGY.STATE,
  );

  const onContactSearch = useCallback(
    async (searchTerm: string) => {
      updateProjectFiltersAndRefetch({ quoteId: quote.getId(), clientId: quoteClient?.id, search: searchTerm });
      return usersData ?? [];
    },
    [quote, quoteClient?.id, updateProjectFiltersAndRefetch, usersData],
  );

  const onContactAdded = useCallback(
    async (contact: IContact, data: { isDefaultEmailRecipient?: boolean } = { isDefaultEmailRecipient: false }) => {
      await contactsAssociateToClientMutation.mutateAsync({
        id: quoteClient!.id,
        dto: {
          contacts: [{ id: contact.id, isDefaultEmailRecipient: data.isDefaultEmailRecipient ?? false }],
        },
      });

      const isContactAlreadyAssociated = quoteContacts?.find((c) => c.id === contact.id);

      let newQuoteContacts = quoteContacts ?? [];
      if (data.isDefaultEmailRecipient) {
        newQuoteContacts = newQuoteContacts.map((c) => ({ ...c, isDefaultEmailRecipient: false }));
      }
      if (isContactAlreadyAssociated) {
        newQuoteContacts = newQuoteContacts.filter((c) => c.id !== contact.id);
      }
      newQuoteContacts.push({
        ...contact,
        isDefaultEmailRecipient: data.isDefaultEmailRecipient ?? false,
      } as QuoteContactDTO);

      quoteUpdateContact(newQuoteContacts);
    },
    [contactsAssociateToClientMutation, quoteClient, quoteContacts, quoteUpdateContact],
  );

  const onContactDissociate = useCallback(
    async (contactToDelete: QuoteContactDTO) => {
      const newContacts = quote.getContactsId() ?? [];
      newContacts.splice(
        newContacts.findIndex(({ id }) => id === contactToDelete.id),
        1,
      );

      const newQuoteContacts = quoteContacts ?? [];
      newQuoteContacts.splice(
        newQuoteContacts.findIndex((c) => c.id === contactToDelete.id),
        1,
      );
      quoteUpdateContact(newQuoteContacts);
    },
    [quote, quoteContacts, quoteUpdateContact],
  );

  if (!quoteClient) return null;

  return (
    <VStack spacing={3} align="stretch">
      <UsersCard<QuoteContactDTO>
        action={{
          actions: [
            {
              name: t('global:words.c.update'),
              onClick: onContactUpsert,
              type: 'item',
              id: uuid(),
            },
            {
              name: t('contacts:modal.associate.buttons.clear'),
              onClick: onContactDissociate,
              type: 'item',
              id: uuid(),
            },
          ],
          icon: <SimpleDotsIcon />,
        }}
        addCTA={{
          label: t('contacts:createNewContact'),
          onClick: () => onContactUpsert(),
        }}
        isEditable
        onSearch={onContactSearch}
        onSelect={onContactAdded}
        placeholder={t('contacts:creationModal.title')}
        title={t('contacts:card.title')}
        users={quoteContacts ?? []}
      />

      <ContactUpsertModal
        isOpen={upsertContactModal.isOpen}
        onSuccess={onContactAdded}
        onClose={upsertContactModal.onClose}
        contact={contactUpsert}
        roles={contactRoles.data}
        hasIsDefaultEmailRecipient
      />
    </VStack>
  );
};
