import { Badge, Button, Card, SimpleAddIcon, SimpleHelpIcon, SimpleRepeatIcon, Tooltip } from '@graneet/lib-ui';
import { Box, Flex, useDisclosure, Wrap, WrapItem, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { IUser } from '@graneet/business-logic';

import { AccountManagerAssociateModal } from './AccountManagerAssociateModal';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { formatUserDisplayName, sortUsers } from 'features/user/services/user.util';

export interface AccountManagerAssociationCardProps {
  associatedAccountManagerUsers: IUser[];
  onAccountManagersUpdated(newAccountManagers: number[]): Promise<void>;

  emptyStateLabel: string;

  actionLabel: string;

  changeAssociatedLabel: string;

  modalTitleLabel: string;

  modalDescriptionLabel: string;

  modalActionLabel: string;

  hasUpdatePermission: boolean;
}

export const AccountManagerAssociationCard: FC<AccountManagerAssociationCardProps> = ({
  associatedAccountManagerUsers,
  onAccountManagersUpdated,
  emptyStateLabel,
  actionLabel,
  changeAssociatedLabel,
  modalTitleLabel,
  modalDescriptionLabel,
  modalActionLabel,
  hasUpdatePermission,
}) => {
  const { t } = useTranslation(['statement', 'accountManager']);
  const updateButtonDisabledProps = useDisabledButtonProps(undefined, hasUpdatePermission);

  const associateModal = useDisclosure();

  const sortedAccountManagerUsers = useMemo(
    () => sortUsers(associatedAccountManagerUsers),
    [associatedAccountManagerUsers],
  );

  const associateModalElement = (
    <AccountManagerAssociateModal
      modal={associateModal}
      associatedAccountManagerUsers={sortedAccountManagerUsers}
      onAccountManagersUpdated={onAccountManagersUpdated}
      description={modalDescriptionLabel}
      title={modalTitleLabel}
      actionLabel={modalActionLabel}
    />
  );
  if (!sortedAccountManagerUsers?.length) {
    return (
      <>
        <Card
          title={
            <Flex alignItems="center">
              <Text mr={4}>{t('accountManager:title')}</Text>
              <Tooltip label={emptyStateLabel} placement="right-end">
                <Box>
                  <SimpleHelpIcon boxSize={4} />
                </Box>
              </Tooltip>
            </Flex>
          }
        >
          <Flex justifyContent="left" mt={6}>
            <Button variant="outline" onClick={associateModal.onOpen}>
              <SimpleAddIcon mx={2} />
              {actionLabel || t('statement:actions.associateProject')}
            </Button>
          </Flex>
        </Card>

        {associateModalElement}
      </>
    );
  }

  return (
    <Box>
      <Card title={t('accountManager:title')}>
        <Wrap>
          {sortedAccountManagerUsers.map((accountManagerUser) => (
            <WrapItem key={accountManagerUser.id}>
              <Badge colorScheme="greenBrand">{formatUserDisplayName(accountManagerUser)}</Badge>
            </WrapItem>
          ))}
        </Wrap>
        <Button mt={5} variant="outline" size="md" onClick={associateModal.onOpen} {...updateButtonDisabledProps()}>
          <SimpleRepeatIcon mx={2} />
          {changeAssociatedLabel}
        </Button>
      </Card>

      {associateModalElement}
    </Box>
  );
};
