import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';

import type { GraneetButtonProps } from './Button';
import { COLORS_CONFIG, GraneetButton } from './Button';

export interface AutoClickButtonProps {
  duration: number;

  onClick: () => void;
}

export const AutoClickButton: FC<AutoClickButtonProps & Omit<GraneetButtonProps, 'onClick'>> = ({
  duration,
  onClick,
  isDisabled,
  ...props
}) => {
  const [width, setWidth] = useState<number>(0);
  const ref = useRef<HTMLButtonElement>(null);
  const timeRef = useRef<number>(0);
  const refInterval = useRef<NodeJS.Timeout | null>(null);

  const startInterval = useCallback(() => {
    if (!isDisabled) {
      refInterval.current = setInterval(() => {
        if (timeRef.current >= duration) {
          clearInterval(refInterval.current!);
          if (!isDisabled) {
            onClick?.();
            return;
          }
        }
        timeRef.current += 10;
        const buttonWidth = ref.current?.offsetWidth;
        setWidth((prev) => prev + (buttonWidth! / duration) * 10);
      }, 10);
    }
  }, [isDisabled, duration, onClick]);

  const handleMouseEnter = useCallback(() => {
    clearInterval(refInterval.current!);
  }, []);

  const handleMouseLeave = useCallback(() => {
    startInterval();
  }, [startInterval]);

  useEffect(() => {
    startInterval();
    return () => (refInterval.current ? clearInterval(refInterval.current) : undefined);
  }, [startInterval]);

  const color = COLORS_CONFIG[props.variant ?? 'secondary'].hover.background;

  return (
    <Box
      position="relative"
      role="group"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={isDisabled ? undefined : onClick}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      borderRadius="0.5rem"
      background="linear-gradient(180deg, #FCFCFD 0%, #F9FAFB 100%)"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        bottom="0"
        width={`${width}%`}
        maxWidth={ref.current?.offsetWidth}
        opacity={0.9}
        background={color}
        borderRadius="0.5rem"
        animation="width 0.2s linear"
        _groupHover={{
          width: 0,
        }}
      />
      <GraneetButton {...props} ref={ref} backgroundColor="transparent" />
    </Box>
  );
};
