import type { FC } from 'react';
import { useCallback } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import { Badge, Container, GraneetButton, Section } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { usePowensGetUrlManage } from '../../../../features/banking/services/powens.api';

import { BankingBankLogo } from 'features/banking/components/BankingBankLogo';
import { BankingAddBankButton } from 'features/banking/components/BankingAddBankButton';
import { useThirdParties } from 'features/third-party/services/third-party.api';
import { ThirdPartyCard } from 'features/third-party/components/ThirdPartyCard/ThirdPartyCard';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { IntegrationCard } from 'features/third-party/components/IntegrationCard';
import { useBankingConnections } from 'features/banking/services/banking-connection.api';

export const ThirdPartyConfigTab: FC = () => {
  const { t } = useTranslation(['global', 'banking']);

  const thirdParties = useThirdParties();
  const bankingConnections = useBankingConnections();

  const hasBankingAccountConnectionFF = useFeatureFlag(FEATURE_FLAGS.BANKING_ACCOUNT_CONNECTION);

  const powensGetUrlManageMutation = usePowensGetUrlManage();

  const onManage = useCallback(
    async (connectionId: string) => {
      const url = await powensGetUrlManageMutation.mutateAsync(connectionId);
      window.open(url);
    },
    [powensGetUrlManageMutation],
  );

  return (
    <>
      <Container size="md">
        <Section title={t('global:settingsNav.thirdParties.applications')}>
          <Stack spacing={3}>
            {thirdParties.data
              .filter((thirdPartyInformation) => thirdPartyInformation.name !== 'banking')
              .map((thirdPartyInformation) => (
                <ThirdPartyCard
                  key={thirdPartyInformation.name}
                  thirdPartyName={thirdPartyInformation.name}
                  isUnderMaintenance={thirdPartyInformation.isUnderMaintenance}
                  isActive={thirdPartyInformation.isActive}
                />
              ))}
          </Stack>
        </Section>
      </Container>

      {hasBankingAccountConnectionFF && (
        <Container size="md">
          <Section title={t('global:settingsNav.thirdParties.banks')}>
            <Stack spacing={3}>
              {bankingConnections.data.map((bankingConnection) => (
                <IntegrationCard
                  key={bankingConnection.id}
                  title={bankingConnection.name}
                  icon={<BankingBankLogo bankingConnection={bankingConnection} size="lg" />}
                  actions={
                    <GraneetButton
                      variant="ghost"
                      rightIcon="ri-share-box-line"
                      onClick={() => onManage(bankingConnection.id)}
                    >
                      {t('global:words.c.manage')}
                    </GraneetButton>
                  }
                  secondaryContent={
                    <>
                      <Text>
                        {t('banking:bankingConnectionsCard.numberConnectedOfAccounts', {
                          count: bankingConnection.bankingAccounts.length,
                        })}
                      </Text>
                      {bankingConnection.lastSyncedAt ? (
                        <Text>
                          {t('banking:bankingConnectionsCard.lastUpdate', {
                            lastSyncedAt: new Date(bankingConnection.lastSyncedAt).toLocaleDateString(undefined, {
                              weekday: 'long',
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: '2-digit',
                              minute: '2-digit',
                            }),
                          })}
                        </Text>
                      ) : (
                        <Badge>{t('banking:bankingAccount.notSync')}</Badge>
                      )}
                      {bankingConnection.syncError && (
                        <Badge colorScheme="red">{t('banking:bankingAccount.errorSync')}</Badge>
                      )}
                    </>
                  }
                />
              ))}
            </Stack>

            <Box mt={6}>
              <BankingAddBankButton />
            </Box>
          </Section>
        </Container>
      )}
    </>
  );
};
