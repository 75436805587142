import type { FC, FocusEventHandler, ReactElement, Ref } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useIMask } from 'react-imask';
import {
  Center,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
  Flex,
} from '@chakra-ui/react';
import type DayPicker from 'react-day-picker';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import type { DayPickerProps } from 'react-day-picker';

import { DAY_PICKER_POPPER_WIDTH, POPOVER_PROPS } from '../../../constants';
import { DATE_FORMAT_API, getLocalDateFormat } from '../../../utils';
import { useDateMask } from '../MaskedInput/utils';
import type { InputProps } from '../Input';
import { Input } from '../Input';
import { DatePicker } from '../../DatePicker';

dayjs.extend(customParseFormat);

const NOOP = () => {};

export interface DateInputProps extends Omit<InputProps, 'onChange'> {
  onChange(newValue: string): void;

  onFocus?: FocusEventHandler<HTMLInputElement>;

  onBlur?(): void;

  value: string | undefined;

  leftIcon?: ReactElement;

  rightIcon?: ReactElement;

  width?: string;

  datePickerProps?: DayPickerProps;
}

export const DateInput: FC<DateInputProps> = ({
  onChange,
  value,
  onFocus = NOOP,
  onBlur = NOOP,
  leftIcon,
  rightIcon,
  width = DAY_PICKER_POPPER_WIDTH,
  inputProps,
  size = 'md',
  datePickerProps = {},
  ...props
}) => {
  const format = getLocalDateFormat();

  const [dateAsString, setDateAsString] = useState<string>('');
  const { mask, placeholder } = useDateMask();

  const dateRef = useRef<DayPicker>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const updatedByDatePickerRef = useRef<boolean>(false);

  const handleChange = useCallback(
    (newDateAsString: string) => {
      setDateAsString(newDateAsString);

      if (newDateAsString === '') {
        onChange(newDateAsString);
        return;
      }

      // Do nothing when the change come from date picker
      if (updatedByDatePickerRef.current) {
        updatedByDatePickerRef.current = false;
        return;
      }

      const newDate = dayjs(newDateAsString, format);
      const isDateFullyCompleted = newDateAsString.length === format.length && newDate.isValid();
      if (isDateFullyCompleted) {
        dateRef.current?.showMonth(newDate.toDate());
        onChange(newDate.format(DATE_FORMAT_API));
      }
    },
    [onChange, format],
  );

  // Add onComplete to match broken TS type
  // Do not memoize second parameter because it's broke `react-imask`
  const { ref: inputRef, maskRef } = useIMask(mask as any, { onAccept: handleChange, onComplete: NOOP });

  // - Popover

  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleOutSideClick = useCallback(() => {
    if (isOpen) {
      onClose();
      onBlur();
    }
  }, [onClose, onBlur, isOpen]);

  useOutsideClick({
    ref: popoverRef,
    handler: handleOutSideClick,
  });

  // - Date picker

  // Handle update of exterior value like by form
  useEffect(() => {
    const newDate = dayjs(value, DATE_FORMAT_API);
    if (newDate.isValid() && newDate.toString() !== dateAsString) {
      setDateAsString(newDate.format(format));
    } else if (!value) {
      setDateAsString('');
    }
    // We only want render on value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, format]);

  const onDayClicked = useCallback(
    (rawNewDate: Date) => {
      const newDate = dayjs(rawNewDate);
      updatedByDatePickerRef.current = true;
      setDateAsString(newDate.format(format));

      onClose();
      onChange(newDate.format(DATE_FORMAT_API));
      // Introduce a small timeout to let time for onChange action to be performed
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      setTimeout(onBlur, 100);
    },
    [format, onBlur, onChange, onClose],
  );

  const selectedDay = useMemo(() => {
    const date = dayjs(dateAsString, format);

    return dateAsString && date.isValid() ? date.toDate() : undefined;
  }, [dateAsString, format]);

  const initialMonth = useMemo(() => {
    const initialDate = dayjs(dateAsString, format);
    return initialDate.isValid() ? initialDate.toDate() : undefined;
  }, [dateAsString, format]);

  // Input

  const handleFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      onFocus(e);
      e.target.select();
      maskRef.current?.updateValue();
    },
    [maskRef, onFocus],
  );

  const inputPropsWithNoAutoComplete = useMemo(
    () => ({
      ...inputProps,
      autoComplete: 'off',
    }),
    [inputProps],
  );

  return (
    <Flex ref={popoverRef} minW={width} w={width}>
      <Popover
        onOpen={onOpen}
        isOpen={isOpen && !props.isDisabled}
        placement="bottom-start"
        autoFocus={false}
        returnFocusOnClose={false}
        gutter={size === 'sm' ? 3 : 0}
        closeOnBlur
        strategy="fixed"
      >
        <PopoverTrigger>
          <Input
            {...props}
            size={size}
            inputProps={inputPropsWithNoAutoComplete}
            ref={inputRef as Ref<HTMLInputElement> | undefined}
            placeholder={props.placeholder ?? placeholder}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            value={dateAsString}
            onFocus={handleFocus}
            isCalendar
          />
        </PopoverTrigger>

        <PopoverContent {...POPOVER_PROPS} borderColor="greenBrand.light" borderRadius="0.5rem" width="15rem" mt="-1px">
          <PopoverBody>
            <Center>
              <DatePicker
                onDayClick={onDayClicked}
                selectedDays={selectedDay}
                ref={dateRef}
                month={initialMonth}
                {...datePickerProps}
              />
            </Center>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};
