import type {
  EmailTemplateType,
  IEmailTemplate,
  IEmailTemplateCreateDTO,
  IEmailTemplateUpdateDTO,
  RequiredByKeys,
} from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

import { apiNew } from 'features/api/services/apiNew.service';

const EMAIL_TEMPLATES_PATH = '/email-templates';

export const emailTemplates = createQueryKeys('email-templates', {
  list: (options?: { type: EmailTemplateType }) => ({
    queryKey: [EMAIL_TEMPLATES_PATH, options],
    queryFn: () =>
      apiNew.get<{ type: EmailTemplateType }, RequiredByKeys<IEmailTemplate, 'reminders'>[]>(
        EMAIL_TEMPLATES_PATH,
        options,
      ),
  }),
});

/**
 * List email templates
 */
export function useEmailTemplates(options?: { type: EmailTemplateType }) {
  return useSuspenseQuery(emailTemplates.list(options));
}

/**
 * Create a new email template
 */
export function useCreateEmailTemplate(options: { type: 'creation' | 'duplication' }) {
  const { t } = useTranslation(['global', 'mailing']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (emailTemplateCreateDTO: IEmailTemplateCreateDTO) =>
      apiNew.post<IEmailTemplateCreateDTO, IEmailTemplate>(EMAIL_TEMPLATES_PATH, emailTemplateCreateDTO),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: () => {
      if (options.type === 'creation') {
        toast.success(t('mailing:toasts.emailTemplateCreated'));
      } else {
        toast.success(t('mailing:toasts.emailTemplateDuplicated'));
      }
      queryClient.invalidateQueries(emailTemplates.list());
    },
  });
}

/**
 * Update an existing email template
 */
export function useUpdateEmailTemplate() {
  const { t } = useTranslation(['global', 'mailing']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, dto }: { id: string; dto: IEmailTemplateUpdateDTO }) =>
      apiNew.patch<IEmailTemplateUpdateDTO, IEmailTemplate>(`${EMAIL_TEMPLATES_PATH}/${id}`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: () => {
      toast.success(t('mailing:toasts.emailTemplateUpdated'));
      queryClient.invalidateQueries(emailTemplates.list());
    },
  });
}

/**
 * Delete an existing email template
 */
export function useDeleteEmailTemplate() {
  const { t } = useTranslation(['global', 'mailing']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (emailTemplate: IEmailTemplate) =>
      apiNew.delete<never, void>(`${EMAIL_TEMPLATES_PATH}/${emailTemplate.id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: (_, emailTemplate) => {
      toast.success(t('mailing:toasts.emailTemplateDeleted', { title: emailTemplate.title }));
      queryClient.invalidateQueries(emailTemplates.list());
    },
  });
}
