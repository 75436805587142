import type { QuoteNodeObject } from '@org/quotation-lib';
import type { IRowNode, RowDragEvent } from '@ag-grid-community/core';
import { useCallback } from 'react';

import { useOnRowDragPositionInPercentage } from './useOnRowDragPositionInPercentage';
import { LIMIT_LOWER_DRAG_ZONE, LIMIT_UPPER_DRAG_ZONE } from './onRowDragConstant';

import { useQuoteNodeMove } from 'features/quotation/quote-node/hooks/useQuoteNodeMove';
import { useQuoteNodeCanBeMovedLower } from 'features/quotation/quote-node/hooks/useQuoteNodeCanBeMovedLower';
import { useQuoteNodeCanBeMovedInside } from 'features/quotation/quote-node/hooks/useQuoteNodeCanBeMovedInside';
import { useQuoteNodeCanBeMovedUpper } from 'features/quotation/quote-node/hooks/useQuoteNodeCanBeMovedUpper';
import { getSibblingForMoveQuoteLotInsideQuoteLot } from 'features/quotation/quote-node/utils/getSibblingForMoveQuoteLotInsideQuoteLot';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

const useQuoteNodeMoveUp = () => {
  const quoteNodeMove = useQuoteNodeMove();
  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useCallback(
    (movingNode: IRowNode<QuoteNodeObject>, overNode: IRowNode<QuoteNodeObject>) => {
      const nodeToBeMovedId = movingNode.data?.id;
      const parentId = overNode.data?.parentId;
      const newPrevSiblingId = overNode.data?.id || null;
      const newNextSiblingId = overNode.data?.nextSiblingId || null;

      if (parentId && nodeToBeMovedId) {
        executeCommand(quoteNodeMove(nodeToBeMovedId, parentId, newPrevSiblingId, newNextSiblingId), quote);
      }
    },
    [executeCommand, quote, quoteNodeMove],
  );
};

const useQuoteNodeMoveDown = () => {
  const quoteNodeMove = useQuoteNodeMove();
  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useCallback(
    (movingNode: IRowNode<QuoteNodeObject>, overNode: IRowNode<QuoteNodeObject>) => {
      const nodeToBeMovedId = movingNode.data?.id;
      const parentId = overNode.data?.parentId;
      const newPrevSiblingId = overNode.data?.prevSiblingId || null;
      const newNextSiblingId = overNode.data?.id || null;

      if (parentId && nodeToBeMovedId) {
        executeCommand(quoteNodeMove(nodeToBeMovedId, parentId, newPrevSiblingId, newNextSiblingId), quote);
      }
    },
    [executeCommand, quote, quoteNodeMove],
  );
};

const useQuoteNodeMoveInside = () => {
  const quoteNodeMove = useQuoteNodeMove();
  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useCallback(
    (movingNode: IRowNode<QuoteNodeObject>, overNode: IRowNode<QuoteNodeObject>) => {
      const nodeToBeMovedId = movingNode.data?.id;
      const targetId = overNode.data?.id;

      if (!targetId || !nodeToBeMovedId) {
        return false;
      }

      if (movingNode.data?.content.type === 'QuoteLot') {
        const { newPrevSiblingId, newNextSiblingId } = getSibblingForMoveQuoteLotInsideQuoteLot(movingNode, overNode);
        return executeCommand(quoteNodeMove(nodeToBeMovedId, targetId, newPrevSiblingId, newNextSiblingId), quote);
      }

      const newNextSiblingId = overNode.childrenAfterSort?.at(0)?.data?.id || null;
      return executeCommand(quoteNodeMove(nodeToBeMovedId, targetId, null, newNextSiblingId), quote);
    },
    [executeCommand, quote, quoteNodeMove],
  );
};

export const useOnRowDragEnd = () => {
  const quoteNodeMoveUp = useQuoteNodeMoveUp();
  const quoteNodeMoveDown = useQuoteNodeMoveDown();
  const quoteNodeMoveInside = useQuoteNodeMoveInside();
  const onRowDragPositionInPercentage = useOnRowDragPositionInPercentage();
  const quoteNodeCanBeMovedUpper = useQuoteNodeCanBeMovedUpper();
  const quoteNodeCanBeMovedLower = useQuoteNodeCanBeMovedLower();
  const quoteNodeCanBeMovedInside = useQuoteNodeCanBeMovedInside();

  return useCallback(
    (event: RowDragEvent<QuoteNodeObject>) => {
      const movingNode = event.node;
      const { overNode } = event;

      if (!overNode) {
        return;
      }

      const percentage = onRowDragPositionInPercentage(movingNode, overNode, event);

      if (percentage === null) {
        return;
      }

      if (percentage < LIMIT_LOWER_DRAG_ZONE && quoteNodeCanBeMovedLower(movingNode, overNode)) {
        quoteNodeMoveDown(movingNode, overNode);
        return;
      }

      if (percentage > LIMIT_UPPER_DRAG_ZONE && quoteNodeCanBeMovedUpper(movingNode, overNode)) {
        quoteNodeMoveUp(movingNode, overNode);
        return;
      }

      if (quoteNodeCanBeMovedInside(movingNode, overNode)) {
        quoteNodeMoveInside(movingNode, overNode);
      }
    },
    [
      onRowDragPositionInPercentage,
      quoteNodeCanBeMovedInside,
      quoteNodeCanBeMovedLower,
      quoteNodeCanBeMovedUpper,
      quoteNodeMoveDown,
      quoteNodeMoveInside,
      quoteNodeMoveUp,
    ],
  );
};
