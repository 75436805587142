import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

export const BuildingIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_806_10121)">
        <path
          d="M2.33348 2.33305H13.6668C13.8468 2.33305 14.0001 2.17972 14.0001 1.99972C14.0001 1.91972 13.9735 1.84639 13.9268 1.78639L12.7868 0.366387V0.35972C12.5935 0.11972 12.3068 -0.02028 12.0068 -0.02028H3.98015C3.67348 -0.0269467 3.38682 0.113053 3.20015 0.353053L2.06682 1.77305C1.94682 1.91305 1.97348 2.12639 2.11348 2.23972C2.16682 2.28639 2.24015 2.30639 2.32015 2.30639L2.33348 2.33305Z"
          fill="#334E68"
        />
        <path
          d="M13.6667 3.33301H2.33333C2.14667 3.33301 2 3.47967 2 3.66634V14.9997C2 15.5463 2.44667 15.9997 3 15.9997H6.33333C6.51333 15.9997 6.66667 15.8463 6.66667 15.6663V14.333C6.66667 13.593 7.26 12.9997 8 12.9997C8.73333 12.9997 9.33333 13.593 9.33333 14.333V15.6663C9.33333 15.8463 9.48 15.9997 9.66667 15.9997H13C13.5467 15.9997 14 15.5463 14 14.9997V3.66634C14 3.47967 13.8467 3.33301 13.6667 3.33301ZM5.33333 10.9997C5.33333 11.1797 5.18 11.333 5 11.333H3.66667C3.48 11.333 3.33333 11.1797 3.33333 10.9997V9.66634C3.33333 9.11301 3.78 8.66634 4.33333 8.66634C4.88 8.66634 5.33333 9.11301 5.33333 9.66634V10.9997ZM5.33333 6.99967C5.33333 7.17967 5.18 7.33301 5 7.33301H3.66667C3.48 7.33301 3.33333 7.17967 3.33333 6.99967V5.66634C3.33333 5.11301 3.78 4.66634 4.33333 4.66634C4.88 4.66634 5.33333 5.11301 5.33333 5.66634V6.99967ZM9 10.9997C9 11.1797 8.84667 11.333 8.66667 11.333H7.33333C7.14667 11.333 7 11.1797 7 10.9997V9.66634C7 9.11301 7.44667 8.66634 8 8.66634C8.54667 8.66634 9 9.11301 9 9.66634V10.9997ZM9 6.99967C9 7.17967 8.84667 7.33301 8.66667 7.33301H7.33333C7.14667 7.33301 7 7.17967 7 6.99967V5.66634C7 5.11301 7.44667 4.66634 8 4.66634C8.54667 4.66634 9 5.11301 9 5.66634V6.99967ZM12.6667 10.9997C12.6667 11.1797 12.5133 11.333 12.3333 11.333H11C10.8133 11.333 10.6667 11.1797 10.6667 10.9997V9.66634C10.6667 9.11301 11.1133 8.66634 11.6667 8.66634C12.2133 8.66634 12.6667 9.11301 12.6667 9.66634V10.9997ZM12.6667 6.99967C12.6667 7.17967 12.5133 7.33301 12.3333 7.33301H11C10.8133 7.33301 10.6667 7.17967 10.6667 6.99967V5.66634C10.6667 5.11301 11.1133 4.66634 11.6667 4.66634C12.2133 4.66634 12.6667 5.11301 12.6667 5.66634V6.99967Z"
          fill="#334E68"
        />
      </g>
      <defs>
        <clipPath id="clip0_806_10121">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);
