import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PERMISSION } from '@graneet/business-logic';
import type { IInvoice } from '@graneet/business-logic';
import { Card } from '@graneet/lib-ui';

import { TagsEditionBlock } from 'features/common/components/TagsEditionBlock';
import { useStatementsAvailableTags } from 'features/statement/services/statement.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useEditInvoiceTags } from 'features/invoice/services/invoice.api';

interface InvoiceTagsCardProps {
  invoice: IInvoice;
}

export const InvoiceTagsCard: FC<InvoiceTagsCardProps> = ({ invoice }) => {
  const { t } = useTranslation(['global']);
  const hasPermission = usePermissions([PERMISSION.ACCESS_STATEMENTS, PERMISSION.CREATE_STATEMENTS]);

  const tags = useStatementsAvailableTags();

  const editInvoiceTagsMutation = useEditInvoiceTags();

  const onTagsSaved = useCallback(
    (newTags: string[]) => editInvoiceTagsMutation.mutateAsync({ id: invoice.id, dto: { tags: newTags } }),
    [editInvoiceTagsMutation, invoice.id],
  );

  if (!invoice.tags && !hasPermission) {
    return null;
  }

  return (
    <Card title={t('global:words.privateTags')}>
      <TagsEditionBlock
        placeholder={t('global:changeTags.placeholder')}
        tags={invoice.tags}
        suggestedTags={tags.data}
        onTagsEdited={onTagsSaved}
        isEditable={hasPermission}
      />
    </Card>
  );
};
