import { cloneElement } from 'react';
import type { ReactElement, FC, ReactNode } from 'react';
import type { StackProps } from '@chakra-ui/react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';

export type EmptyStateActionsProps = Omit<StackProps, 'title'> & {
  children?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  icon?: ReactElement;
};

export const EmptyStateActions: FC<EmptyStateActionsProps> = ({ children, title, description, icon, ...props }) => (
  <Box minWidth="15.625rem" width="17.313rem" overflow="hidden" p="0.75rem">
    <VStack alignItems="center" justifyContent="center" {...props}>
      {icon && (
        <Flex
          alignItems="center"
          justifyContent="center"
          borderRadius="0.5rem"
          backgroundColor="#FFF"
          height="2.25rem"
          width="2.25rem"
          backgroundBlendMode="plus-darker"
          boxShadow="0px 2px 4px 0px rgba(17, 24, 28, 0.12), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px rgba(17, 24, 28, 0.08)"
        >
          {cloneElement(icon, { boxSize: '1rem', color: '#6C737F' })}
        </Flex>
      )}
      {title && (
        <Text fontSize="0.813rem" fontWeight={600} color="#1F2A37" textOverflow="ellipsis">
          {title}
        </Text>
      )}

      {description && (
        <Text fontSize="0.75rem" color="#4D5761" fontWeight={400} textAlign="center">
          {description}
        </Text>
      )}

      <Box flexGrow={1} flexShrink={1}>
        {children}
      </Box>
    </VStack>
  </Box>
);
