import type { FC } from 'react';
import { useEffect } from 'react';
import { Form, useStepForm } from 'graneet-form';
import { Button, Wizard } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Flex, Grid, VStack, useDisclosure, Box } from '@chakra-ui/react';
import type { IPdfSettings } from '@graneet/business-logic';
import { FEATURE_FLAGS, PDF_TEMPLATES_TYPES } from '@graneet/business-logic';

import { useAppContext } from 'features/app/contexts/AppContext';
import type { PDFSettingsWizardValues, PDFSettingsStep } from 'features/pdf/constants/pdf-settings.constant';
import { PdfTypoSettings } from 'features/pdf/components/PdfTypoSettings';
import { PdfContactsSettings } from 'features/pdf/components/PdfContactsSettings';
import { PdfColorSettings } from 'features/pdf/components/PdfColorSettings';
import { PdfLotAmountsSettings } from 'features/pdf/components/PdfLotAmountsSettings';
import { generatePdfSettingsFormValues, PdfSettingsCopyBanner } from 'features/pdf/components/PdfSettingsCopyBanner';
import { PdfLogoSettings } from 'features/pdf/components/PdfLogoSettings';
import { PdfLabelsSettingsWithSize } from 'features/pdf/components/PdfLabelsSettingsWithSize';
import { PdfCompanyAddressSettings } from 'features/pdf/components/PdfCompanyAddressSettings';
import { PdfAccountManagersSettings } from 'features/pdf/components/PdfAccountManagersSettings';
import { PdfCoverPageSettings } from 'features/pdf/components/PdfCoverPageSettings';
import { PdfDensitySettings } from 'features/pdf/components/PdfDensitySettings';
import { PdfTemplateFrame } from 'features/pdf/components/PdfTemplateFrame';
import { PdfGenerateExampleModal } from 'features/pdf/components/modals/PdfGenerateExampleModal';
import { PdfExternalProgressStatementSettings } from 'features/pdf/components/PdfExternalProgressStatementSettings';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface EditPdfSettingsStepProps {
  template: PDF_TEMPLATES_TYPES;
  pdfSettings?: IPdfSettings | null;
  otherPdfSettings?: IPdfSettings | null;
}

export const EditPdfSettingsStep: FC<EditPdfSettingsStepProps> = ({ template, pdfSettings, otherPdfSettings }) => {
  const { t } = useTranslation(['pdfSettings']);
  const { currentUser } = useAppContext();
  const hasLabels = currentUser?.company.labels ? currentUser.company.labels.length > 0 : false;
  const generatePdfExampleModal = useDisclosure();
  const { form, initFormValues } = useStepForm<PDFSettingsWizardValues, typeof PDFSettingsStep.EDIT_PDF_SETTINGS>();

  // @[ff: virality]
  const hasViralityFeature = useFeatureFlag(FEATURE_FLAGS.VIRALITY);

  // init form values
  useEffect(() => {
    initFormValues(generatePdfSettingsFormValues(pdfSettings));
  }, [initFormValues, pdfSettings]);

  return (
    <>
      <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_RIGHT}>
        <Button size="lg" variant="outline" boxShadow="md" onClick={generatePdfExampleModal.onOpen}>
          {t('pdfSettings:button.download')}
        </Button>
      </Wizard.Placeholder>

      <Form form={form} style={{ height: '100%' }}>
        <Grid h="100%" templateColumns="542px 1.5fr" background="white">
          <Box overflow="auto" py={8} px={5} justifySelf="center" background="white">
            {pdfSettings && otherPdfSettings && (
              <PdfSettingsCopyBanner hasLabels={hasLabels} otherPdfSettings={otherPdfSettings} />
            )}

            <VStack align="start" spacing={8}>
              <PdfLogoSettings />

              {hasLabels && <PdfLabelsSettingsWithSize />}

              <PdfCompanyAddressSettings />

              <PdfAccountManagersSettings />

              {template === PDF_TEMPLATES_TYPES.QUOTE && <PdfContactsSettings />}

              <PdfCoverPageSettings />

              <PdfColorSettings />

              <PdfTypoSettings />

              <PdfDensitySettings />

              <PdfLotAmountsSettings pdfTemplateType={template} />

              {template === PDF_TEMPLATES_TYPES.STATEMENT && hasViralityFeature && (
                <PdfExternalProgressStatementSettings />
              )}
            </VStack>
          </Box>

          <Flex background="backgroundLighter" boxShadow="md">
            <PdfTemplateFrame />
          </Flex>
        </Grid>
        <PdfGenerateExampleModal modal={generatePdfExampleModal} />
      </Form>
    </>
  );
};
