import { useTranslation } from 'react-i18next';
import {
  PriceAdvanced,
  formatDataOrEmpty,
  ListingLayout,
  SimpleOrderIcon,
  PaginatedTable,
  type PaginatedTableProps,
} from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import type { IOrderWithRelations, ISupplierInvoice, OrderPaginatedResponse } from '@graneet/business-logic';
import type { IRowNode } from '@ag-grid-community/core';

import { useOrders } from 'features/order/services/order.api';
import { OrderComponentTypeBadge } from 'features/order/components/OrderComponentTypeBadge';

const TypeCell = ({ data }: { data: IOrderWithRelations | undefined }) =>
  data ? (
    <Box w="7rem">
      <OrderComponentTypeBadge order={data} />
    </Box>
  ) : null;

const AmountExVATCell = ({ data }: { data: IOrderWithRelations | undefined }) =>
  data ? <PriceAdvanced amount={data.amountExVAT} /> : null;

interface OrderPickerDetailProps {
  supplierInvoice: ISupplierInvoice;

  onRowSelected: (rows: IOrderWithRelations[]) => void;
}

export const OrderPickerDetail: FC<OrderPickerDetailProps> = ({ supplierInvoice, onRowSelected }) => {
  const { t } = useTranslation(['orders', 'supplierInvoices']);

  const orders = useOrders();

  const columnDefs = useMemo<PaginatedTableProps<OrderPaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'orderNumber',
        headerName: t('orders:fields.orderNumber'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.orderNumber),
      },
      {
        field: 'type',
        headerName: t('orders:fields.type'),
        sortable: true,
        cellRenderer: TypeCell,
      },
      {
        field: 'name',
        headerName: t('orders:fields.name'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.name),
      },
      {
        field: 'supplier',
        headerName: t('orders:fields.supplier'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.supplier.name),
      },
      {
        field: 'orderProject' as any,
        headerName: t('orders:fields.orderProject'),
        valueFormatter: (v) => formatDataOrEmpty(v.data?.project?.name),
      },
      {
        field: 'amountExVAT',
        headerName: t('orders:fields.amountExVAT'),
        sortable: true,
        cellRenderer: AmountExVATCell,
      },
    ],
    [t],
  );

  const isRowSelectable = useCallback(
    (event: IRowNode<IOrderWithRelations>) => {
      const nonSelectableIds = (supplierInvoice.ordersSupplierInvoices || []).map((i) => i.order.id);

      if (!nonSelectableIds) {
        return true;
      }

      return (supplierInvoice.ordersSupplierInvoices || []).every(
        (nonSelectable) => nonSelectable.order.id !== event.data?.id,
      );
    },
    [supplierInvoice.ordersSupplierInvoices],
  );

  return (
    <Box h="40rem">
      <Box mb={3}>{t('supplierInvoices:associate.pickOrCreate.body.pick')}</Box>

      <ListingLayout
        pagination={orders}
        search={{ placeholder: t('orders:actions.search') }}
        content={
          <PaginatedTable
            gridId="order-picker"
            pagination={orders}
            columnDefs={columnDefs}
            zeroState={{
              icon: <SimpleOrderIcon boxSize={45} />,
              label: t('orders:errors.noOrder'),
            }}
            emptyState={{
              label: t('orders:errors.noResult'),
            }}
            rowSelection="multiple"
            defaultColDef={{
              headerCheckboxSelection: false,
            }}
            onRowSelected={(event) => {
              onRowSelected(event.api.getSelectedRows() ?? []);
            }}
            isRowSelectable={isRowSelectable}
          />
        }
      />
    </Box>
  );
};
