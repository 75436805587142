import type { ICompany } from '../company/company.type';
import type { IProject } from '../project/project.type';

import type { GeneratorAttributeConfig } from './generators/attribute.generator';
import type { CounterGeneratorState, GeneratorCounterConfig } from './generators/counter.generator';
import type { GeneratorDateConfig } from './generators/date.generator';
import type { GeneratorStaticConfig } from './generators/static.generator';

export enum LEDGER_GENERATORS {
  DATE = 'date',
  ATTRIBUTE = 'attribute',
  COUNTER = 'counter',
  STATIC = 'static',
}

export enum LEDGER_TYPES {
  QUOTE = 'QUOTE',
  STATEMENT = 'STATEMENT',
  ORDER = 'ORDER',
  PROJECT = 'PROJECT',
  CLIENT = 'CLIENT',
  SUPPLIER = 'SUPPLIER',
}

export interface GeneratorConfig<T extends LEDGER_GENERATORS> {
  type: T;
}

export type GeneratorData = {
  // the reference date to use to generate the number with date blocks
  // it can either be the billing date for statements or the quote date for quotes
  referenceDate: Date | string;

  company?: Omit<ICompany, 'activeFeatureFlags'>;

  project?: IProject | null;
};

export interface GeneratorInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isStateValid(newState: any, oldState?: any): boolean;

  generateValue(
    data: GeneratorData,
    state: CounterGeneratorState,
    values?: { [block: string]: string },
    lastCounter?: number,
  ): [string, number | null, CounterGeneratorState | null];
}

type AnyGeneratorConfig =
  | GeneratorAttributeConfig
  | GeneratorCounterConfig
  | GeneratorDateConfig
  | GeneratorStaticConfig;

export type GeneratorConfigObject = {
  [block: string]: AnyGeneratorConfig;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GeneratorStateObject = { [block: string]: any };

export interface ILedger {
  id: number;

  format: string;

  config: GeneratorConfigObject;

  state: GeneratorStateObject;

  company?: ICompany;

  type: LEDGER_TYPES;

  isEnabled: boolean;

  isExpert: boolean;

  createdAt: Date;

  updatedAt: Date;
}

export interface ILedgerWithNextNumber extends ILedger {
  nextNumber: string;
}
