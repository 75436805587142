import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { FEATURE_FLAGS, PERMISSION } from '@graneet/business-logic';

import { DisplayNotFoundScreen } from '../DisplayNotFoundScreen';
import { QueryWrapper } from '../../features/api/components/QueryWrapper';

import { LibraryRouter } from './library/LibraryRouter';
import { EditQuoteScreen } from './quotes/edit/EditQuoteScreen';
import { ExportQuoteScreen } from './quotes/export/ExportQuoteScreen';
import { ViewQuotationDetailScreen } from './quotes/edit/ViewQuotationDetailScreen';
import { ViewQuoteScreen } from './quotes/details/ViewQuoteScreen';
import { ViewQuotesScreen } from './quotes/ViewQuotesScreen';
import { QuotationRouter } from './QuotationRouter';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const OpportunitiesRouter = () => {
  const { path } = useRouteMatch();

  const hasDisplayQuotesPermission = usePermissions([PERMISSION.DISPLAY_QUOTES]);
  const hasQuoteV2FeatureFlag = useFeatureFlag(FEATURE_FLAGS.QUOTE_V2);

  if (!hasDisplayQuotesPermission) {
    return null;
  }

  return (
    <Switch>
      <Route path={`${path}/quotes/:quoteId/edit`} exact>
        <EditQuoteScreen />
      </Route>

      <Route path={`${path}/quotes/:quoteId/export`} exact>
        <ExportQuoteScreen />
      </Route>

      <Route path={`${path}/quotes/:quoteId/view`} exact>
        <ViewQuotationDetailScreen />
      </Route>

      <Route path={`${path}/quotes/:quoteId`}>
        <QueryWrapper>
          <ViewQuoteScreen />
        </QueryWrapper>
      </Route>

      <Route path={`${path}/quotes`} exact>
        <ViewQuotesScreen />
      </Route>

      <Route path={`${path}/library`}>
        <LibraryRouter />
      </Route>

      {hasQuoteV2FeatureFlag ? (
        <Route path={`${path}/quotation`}>
          <QuotationRouter />
        </Route>
      ) : (
        <Route path="*">
          <DisplayNotFoundScreen />
        </Route>
      )}
    </Switch>
  );
};
