import { useEffect, useState } from 'react';

import { useStore } from 'store/store';

type NodeType = 'lot' | 'job';

export const useAutoNumberingQuoteRow = ({ id, type }: { id: string; type: NodeType }): string | undefined => {
  const [code, setCode] = useState<string | undefined>('1');

  const item = useStore((state) => state.autoNumberingTable[`${type}-${id}`]);

  useEffect(() => {
    if (!item) {
      return;
    }

    const newCode = item && item.length > 2 ? item?.substring(2) : item;

    if (code !== newCode) {
      setCode(newCode);
    }
  }, [code, id, item, type]);

  return code;
};
