import type { FC } from 'react';
import { Flex, Stat, StatLabel, StatNumber, type StyleProps, Text } from '@chakra-ui/react';
import { Badge, Card, Price } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

interface BillStatsCardProps {
  onClick: () => void;

  information: {
    sum: number;
    count: number;
  };

  title: {
    text: string;
    color?: StyleProps['color'];
  };
}

export const BillStatsCard: FC<BillStatsCardProps> = ({ onClick, information, title }) => {
  const { t } = useTranslation(['global']);

  return (
    <Card onClick={onClick}>
      <Stat>
        <StatLabel>
          <Flex gap={2} alignItems="center">
            <Text color={title.color}>{title.text}</Text>
            <Badge colorScheme="gray">{information.count}</Badge>
          </Flex>
        </StatLabel>

        <StatNumber>
          <Flex gap={2} alignItems="baseline">
            <Text color="primaryLight" fontSize="xl">
              <Price amount={information.sum} />
            </Text>
            <Text color="greenBrand.baseSecondary" fontSize="sm">
              {t('global:incVat')}
            </Text>
          </Flex>
        </StatNumber>
      </Stat>
    </Card>
  );
};
