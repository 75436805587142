import { useCallback } from 'react';
import type { QuoteSalesDiscountObject } from '@org/quotation-lib';
import { QuoteSalesDiscount } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  quoteClientRequestsSetToStore,
  StatusEnum,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { mapQuoteSalesDiscountToQuoteSalesDiscountUpdateDTO } from 'features/quotation/quote-common/mappers/quoteSalesDiscountMapper';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteSalesDiscountUpdate() {
  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const { quoteSalesDiscountsProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    (quoteSalesDiscountObject: Partial<QuoteSalesDiscountObject>): boolean => {
      try {
        const quoteSalesDiscountEntity = new QuoteSalesDiscount(quoteSalesDiscountObject);
        quote.upsertSalesDiscount(quoteSalesDiscountEntity);

        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
        quoteSalesDiscountsProxyApi
          .updateQuoteSalesDiscount(
            quoteSalesDiscountEntity.getId(),
            mapQuoteSalesDiscountToQuoteSalesDiscountUpdateDTO(
              quoteSalesDiscountEntity,
              quote.getId(),
              clientRequestId,
              timestamp,
            ),
          )
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        quoteSetToStore(quote);
        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteError, quoteSalesDiscountsProxyApi, quoteSetToStore],
  );
}
