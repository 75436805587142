import { useCallback } from 'react';
import Big from 'big.js';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { mapQuoteBasicItemToUpdateVatRate } from 'features/quotation/quote-common/mappers/quoteBasicItemMapper';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteBasicItemUpdateVatRate() {
  const { quoteBasicItemsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, newVatRate: string): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteBasicItem = quote.getTree().getQuoteBasicItemOrThrow(nodeId);
          this.oldValue = quoteBasicItem.getVatRate();
          const quoteNode = quoteBasicItem.getNodeOrThrow();
          quoteBasicItem.updateVatRate(new Big(newVatRate));
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteBasicItemsProxyApi
            .updateQuoteBasicItem(
              quoteBasicItem.getId(),
              mapQuoteBasicItemToUpdateVatRate(quoteNode, quote.getId(), clientRequestId, timestamp),
            )
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteBasicItem = quote.getTree().getQuoteBasicItemOrThrow(nodeId);
          const quoteNode = quoteBasicItem.getNodeOrThrow();
          quoteBasicItem.updateVatRate(this.oldValue);
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteBasicItemsProxyApi
            .updateQuoteBasicItem(
              quoteBasicItem.getId(),
              mapQuoteBasicItemToUpdateVatRate(quoteNode, quote.getId(), clientRequestId, timestamp),
            )
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteBasicItemsProxyApi, quoteError, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
