import type { FC } from 'react';

import { PreviewPDF } from './PreviewPDF';
import './PreviewFile.css';

export interface PreviewFileProps {
  fileType: string;
  fileUrl: string;
  scale?: number;
}

export const PreviewFile: FC<PreviewFileProps> = ({ fileType, fileUrl, scale = 1 }) => (
  <>
    {fileType === 'application/pdf' ? (
      <PreviewPDF fileSource={fileUrl} scale={scale} />
    ) : (
      <img
        src={fileUrl}
        alt="invoiceFile"
        className="img-preview"
        style={{
          transform: `scale(${scale})`,
        }}
      />
    )}
  </>
);
