import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { BoxProps } from '@chakra-ui/react';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { HeartIcon, LabeledData } from '@graneet/lib-ui';
import type { IPaymentMethodResponseDTO } from '@graneet/business-logic';

interface PaymentMethodDetailProps extends BoxProps {
  paymentMethod?: IPaymentMethodResponseDTO;

  displayFavourite?: boolean;
}

export const PaymentMethodDetail: FC<PaymentMethodDetailProps> = ({
  paymentMethod,
  displayFavourite = false,
  ...otherProps
}) => {
  const { t } = useTranslation(['paymentMethods']);

  return (
    <Box {...otherProps}>
      <Grid gap={6} rowGap={6} templateColumns="18rem 8rem 1fr">
        <LabeledData label={t('paymentMethods:field.iban')} data={paymentMethod?.iban} />
        <LabeledData label={t('paymentMethods:field.bic')} data={paymentMethod?.bic} />
        <LabeledData label={t('paymentMethods:field.comment.label')} data={paymentMethod?.text} multiline />
      </Grid>

      {displayFavourite && paymentMethod?.isDefault && (
        <Flex direction="row" alignItems="center" mt={4}>
          <HeartIcon color="green.700" mr={3} />
          <Text color="green.700">{t('paymentMethods:companyDefaultPaymentMethod')}</Text>
        </Flex>
      )}
    </Box>
  );
};
