import type { ColDef, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';

import { DenominationCell } from '../components/columns/DenominationCell/DenominationCell';
import { DenominationEditorCell } from '../components/columns/DenominationCell/DenominationEditorCell';
import { ComponentTypeEditorCell } from '../components/columns/ComponentTypeCell/ComponentTypeEditorCell';
import { AmountEditorCell } from '../components/columns/AmountCell/AmountEditorCell';
import { ComponentTypeCell } from '../components/columns/ComponentTypeCell/ComponentTypeCell';
import { TextEditorCell } from '../components/columns/TextCell/TextEditorCell';

import { AmountCell } from 'features/quotation/ag-grid-quote-components-by-type/components/columns/AmountCell/AmountCell';
import { useStore } from 'store/store';
import { useQuoteComponentUpdateMany } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateMany';
import { TextCell } from 'features/quotation/ag-grid-quote-components-by-type/components/columns/TextCell/TextCell';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';

export const useGridComponentsSupplyColumnDefs = () => {
  const { t } = useTranslation(['quote']);
  const isEditable = useStore((state) => state.quoteEditable);
  const supplySheetTrackingEventType = 'supply-sheet';

  const { resetCommand } = useCommands();

  const updateQuoteComponentMany = useQuoteComponentUpdateMany();
  return useMemo(
    (): ColDef<QuoteComponentsSupplyObject>[] => [
      {
        editable: false,
        resizable: false,
        cellStyle: { paddingLeft: '16px' },
        checkboxSelection: true,
        headerCheckboxSelection: true,
        maxWidth: 40,
      },
      {
        headerName: t('quote:quotation.columns.type'),
        field: 'componentType.name',
        colId: 'componentType',
        editable: isEditable,
        resizable: false,

        cellRenderer: ComponentTypeCell,
        cellEditor: ComponentTypeEditorCell,

        valueSetter: (params) => {
          const { newValue, data } = params;
          const newComponentTypeId = newValue ? parseInt(newValue, 10) : null;
          resetCommand();
          updateQuoteComponentMany(
            data.similarNodeIds,
            {
              componentTypeId: newComponentTypeId,
            },
            supplySheetTrackingEventType,
          );
          return true;
        },
      },
      {
        headerName: t('quote:quotation.columns.refCode'),
        field: 'refCode',
        editable: isEditable,
        resizable: false,

        cellRenderer: TextCell,
        cellEditor: TextEditorCell,
        cellStyle: { textAlign: 'center' },

        valueSetter: (params) => {
          const { newValue, data } = params;
          resetCommand();
          updateQuoteComponentMany(data.similarNodeIds, { refCode: newValue }, supplySheetTrackingEventType);
          return true;
        },
      },
      {
        headerName: t('quote:quotation.columns.denomination'),
        field: 'denomination',
        autoHeight: true,
        resizable: false,
        editable: isEditable,
        flex: 2,

        cellEditorPopup: true,
        cellEditorPopupPosition: 'over',
        cellRenderer: DenominationCell,
        cellEditor: DenominationEditorCell,

        suppressKeyboardEvent: (params: SuppressKeyboardEventParams<QuoteComponentsSupplyObject>) => {
          const { key } = params.event;
          if (key === 'Tab' && params.editing) {
            params.api.tabToNextCell();
            const nextCell = params.api.getFocusedCell();
            if (nextCell) {
              params.api.startEditingCell({
                colKey: nextCell.column,
                rowIndex: nextCell.rowIndex,
              });
            }
            return true;
          }
          if (params.editing) {
            return true;
          }
          return false;
        },

        valueSetter: (params) => {
          const { newValue, oldValue, data } = params;

          if (oldValue?.toString() === newValue?.toString()) {
            return false;
          }
          resetCommand();
          updateQuoteComponentMany(data.similarNodeIds, { denomination: newValue }, supplySheetTrackingEventType);
          return true;
        },
      },
      {
        headerName: t('quote:quotation.columns.quantity'),
        field: 'quantity',
        editable: false,
        cellClass: 'no-focus read-only',
        resizable: false,
        cellStyle: { textAlign: 'center' },
        cellRenderer: TextCell,
      },
      {
        headerName: t('quote:quotation.columns.unit'),
        field: 'unit',
        resizable: false,

        cellRenderer: TextCell,
        cellEditor: TextEditorCell,

        cellStyle: { textAlign: 'center' },
        cellClass: (params) => {
          if (!params.data) return '';

          return isEditable && !params.data.componentType?.isWorkforce ? '' : 'no-focus read-only';
        },

        editable: (params) => {
          if (!params.data) return false;

          return isEditable && !params.data.componentType?.isWorkforce;
        },
        valueSetter: (params) => {
          const { newValue, data } = params;
          resetCommand();
          updateQuoteComponentMany(data.similarNodeIds, { unit: newValue }, supplySheetTrackingEventType);
          return true;
        },
      },
      {
        headerName: t('quote:quotation.columns.unitFlatCostAmount'),
        field: 'unitFlatCostAmount',
        resizable: false,
        editable: isEditable,

        cellRenderer: AmountCell,
        cellEditor: AmountEditorCell,

        valueSetter: (params) => {
          const newValue = params.newValue.replace(',', '.').toString();
          const oldValue = params.oldValue?.toString();

          if (oldValue === newValue) {
            return false;
          }
          resetCommand();
          updateQuoteComponentMany(
            params.data.similarNodeIds,
            { unitFlatCostAmount: newValue },
            supplySheetTrackingEventType,
          );
          return true;
        },
      },
      {
        headerName: t('quote:quotation.columns.flatCostAmount'),
        field: 'flatCostAmount',
        cellClass: 'no-focus read-only',
        editable: false,
        resizable: false,
        cellRenderer: AmountCell,
      },
    ],
    [isEditable, resetCommand, t, updateQuoteComponentMany],
  );
};
