import {
  EMAIL_TEMPLATE_SUPPLIER_VARIABLE,
  EMAIL_TEMPLATE_CLIENT_VARIABLE,
  EMAIL_TEMPLATE_ORDER_VARIABLE,
  EMAIL_TEMPLATE_PROJECT_VARIABLE,
  EMAIL_TEMPLATE_QUOTE_VARIABLE,
  EMAIL_TEMPLATE_STATEMENT_VARIABLE,
} from '@graneet/business-logic';
import type { EmailTemplateType, EmailTemplateVariable } from '@graneet/business-logic';
import type { TFunction } from 'i18next';
import type { RichTextVariableConfiguration, RichTextVariableOptionConfiguration } from '@graneet/lib-ui';

export const getEmailTemplateVariableDropdownOptions = (
  type: EmailTemplateType,
  t: TFunction<['global', 'mailing']>,
): RichTextVariableConfiguration['options'] => [
  // Entity
  ...(type === 'quote'
    ? [
        { type: 'header' as const, text: t('mailing:emailTemplateType.quote') },
        ...EMAIL_TEMPLATE_QUOTE_VARIABLE.map((value) => ({ type: 'option' as const, value })),
      ]
    : []),
  ...(type === 'statement'
    ? [
        { type: 'header' as const, text: t('mailing:emailTemplateType.statement') },
        ...EMAIL_TEMPLATE_STATEMENT_VARIABLE.map((value) => ({ type: 'option' as const, value })),
      ]
    : []),
  ...(type === 'order'
    ? [
        { type: 'header' as const, text: t('mailing:emailTemplateType.order') },
        ...EMAIL_TEMPLATE_ORDER_VARIABLE.map((value) => ({ type: 'option' as const, value })),
      ]
    : []),

  // Client or supplier
  ...(type === 'quote' || type === 'statement'
    ? [
        { type: 'header' as const, text: t('mailing:variableDropDown.header.client') },
        ...EMAIL_TEMPLATE_CLIENT_VARIABLE.map((value) => ({ type: 'option' as const, value })),
      ]
    : []),
  ...(type === 'order'
    ? [
        { type: 'header' as const, text: t('mailing:variableDropDown.header.supplier') },
        ...EMAIL_TEMPLATE_SUPPLIER_VARIABLE.map((value) => ({ type: 'option' as const, value })),
      ]
    : []),

  { type: 'header', text: t('mailing:variableDropDown.header.project') },

  // Project
  ...EMAIL_TEMPLATE_PROJECT_VARIABLE.map((value) => ({ type: 'option' as const, value })),
];

export const getEmailTemplateVariableLabel = (
  option: Pick<RichTextVariableOptionConfiguration, 'value'>,
  t: TFunction<['global', 'mailing']>,
): string =>
  t(
    `mailing:variableDropDown.text.${
      option.value.replace(':', '_') /* i18n replace ":" by ".". Usage of underscore is a workaround */
    }` as any,
  );

export const getRichTextPluginOption = (
  type: EmailTemplateType,
  t: TFunction<['global', 'mailing']>,
  additionalOptions?: { injectValueInLabel?: boolean; values?: Partial<Record<EmailTemplateVariable, string | null>> },
): RichTextVariableConfiguration => {
  const options: RichTextVariableConfiguration['options'] = getEmailTemplateVariableDropdownOptions(type, t);

  const optionsText = options
    .filter((option): option is RichTextVariableOptionConfiguration => option.type === 'option')
    .reduce<Record<string, { label: string; value: string | undefined }>>((acc, option) => {
      acc[option.value] = {
        label: getEmailTemplateVariableLabel(option, t),
        value: additionalOptions?.values?.[option.value as EmailTemplateVariable] || undefined,
      };

      return acc;
    }, {});

  return {
    name: 'variable',
    injectValueInLabel: additionalOptions?.injectValueInLabel || false,
    options,
    optionsText,
  };
};
