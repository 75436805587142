import type { FC } from 'react';
import { useMemo } from 'react';
import { HiddenField, useFormContext, useOnChangeValues } from 'graneet-form';

import { useDirectPaymentContext } from '../contexts/DirectPaymentContext';
import type { ProgressStatementDirectPaymentForm } from '../../progress-statement/forms/progress-statement-direct-payment-form';

import { DirectPaymentEmptyState } from './DirectPaymentEmptyState';
import { DirectPaymentSupplierInvoiceDetail } from './DirectPaymentSupplierInvoiceDetail';

export const DirectPaymentSupplierInvoice: FC = () => {
  const { selectedOrder } = useDirectPaymentContext();
  const form = useFormContext<ProgressStatementDirectPaymentForm>();

  const { ordersSupplierInvoices } = useOnChangeValues(form, ['ordersSupplierInvoices']);

  const associatedSupplierInvoice = useMemo(
    () => ordersSupplierInvoices?.find(({ orderId }) => orderId === selectedOrder?.id),
    [ordersSupplierInvoices, selectedOrder?.id],
  );

  return (
    <>
      <HiddenField<ProgressStatementDirectPaymentForm> name="ordersSupplierInvoices" />

      {associatedSupplierInvoice ? (
        <DirectPaymentSupplierInvoiceDetail supplierInvoiceId={associatedSupplierInvoice.supplierInvoiceId} />
      ) : (
        <DirectPaymentEmptyState />
      )}
    </>
  );
};
