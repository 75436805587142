import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import {
  Modal,
  RICH_TEXT_BLOCK_TOOLBAR_PRESET,
  RichTextContextProvider,
  RichTextField,
  useRichText,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { ContentTypeGuard } from '@org/quotation-lib';

import { useQuote } from '../../hooks/useQuote';

import { useStore } from 'store/store';
import { updateQuoteNotesModal } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useQuoteBasicItemUpdateNote } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateNote';
import { useQuoteComponentUpdateNote } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateNote';
import { useQuoteHiddenCostItemUpdateNote } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemUpdateNote';
import { useQuoteOptionalItemUpdateNote } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateNote';
import { useQuoteSubItemUpdateNote } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdateNote';
import { useQuoteLotUpdateNote } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateNote';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';

interface EditQuoteNotesForm {
  text: string;
}

export const EditQuoteItemNoteModal: FC = () => {
  const { t } = useTranslation(['global', 'quote']);

  const isEditable = useStore((store) => store.quoteEditable);
  const modal = useStore((store) => store.quoteNotesModal);

  const { quote } = useQuote();

  const quoteBasicItemUpdateNote = useQuoteBasicItemUpdateNote();
  const quoteHiddenCostItemUpdateNote = useQuoteHiddenCostItemUpdateNote();
  const quoteOptionalItemUpdateNote = useQuoteOptionalItemUpdateNote();
  const quoteSubItemUpdateNote = useQuoteSubItemUpdateNote();
  const quoteComponentUpdateNote = useQuoteComponentUpdateNote();
  const quoteLotUpdateNote = useQuoteLotUpdateNote();

  const { executeCommand } = useCommands();

  const form = useForm<EditQuoteNotesForm>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues } = form;
  const templateRichTextContext = useRichText();

  const onClose = useCallback(() => {
    form.resetForm();
    updateQuoteNotesModal({ open: false, note: null, nodeId: null });
  }, [form]);

  useEffect(() => {
    if (modal.note) {
      form.setFormValues({
        text: modal.note,
      });
    } else {
      form.setFormValues({
        text: '',
      });
    }
  }, [form, modal.note]);

  const handleSubmit = useCallback(() => {
    if (!isEditable) {
      onClose();
      return;
    }

    if (!modal.nodeId) {
      onClose();
      return;
    }

    const values = getFormValues();

    const node = quote.getTree().getNodeOrThrow(modal.nodeId);
    const content = node.getContent();
    if (ContentTypeGuard.isQuoteLot(content)) {
      executeCommand(quoteLotUpdateNote(modal.nodeId, values.text ?? null), quote);
    }
    if (ContentTypeGuard.isQuoteBasicItem(content)) {
      executeCommand(quoteBasicItemUpdateNote(modal.nodeId, values.text ?? null), quote);
    }
    if (ContentTypeGuard.isQuoteHiddenCostItem(content)) {
      executeCommand(quoteHiddenCostItemUpdateNote(modal.nodeId, values.text ?? null), quote);
    }
    if (ContentTypeGuard.isQuoteOptionalItem(content)) {
      executeCommand(quoteOptionalItemUpdateNote(modal.nodeId, values.text ?? null), quote);
    }
    if (ContentTypeGuard.isQuoteSubItem(content)) {
      executeCommand(quoteSubItemUpdateNote(modal.nodeId, values.text ?? null), quote);
    }
    if (ContentTypeGuard.isQuoteComponent(content)) {
      executeCommand(quoteComponentUpdateNote(modal.nodeId, values.text ?? null), quote);
    }

    onClose();
  }, [
    isEditable,
    modal.nodeId,
    getFormValues,
    quote,
    onClose,
    quoteLotUpdateNote,
    quoteBasicItemUpdateNote,
    quoteHiddenCostItemUpdateNote,
    quoteOptionalItemUpdateNote,
    executeCommand,
    quoteSubItemUpdateNote,
    quoteComponentUpdateNote,
  ]);

  const title = useMemo(
    () =>
      modal.note ? t('quote:quotation.modals.updateNote.updateTitle') : t('quote:quotation.modals.updateNote.addTitle'),
    [modal.note, t],
  );

  return (
    <Modal isOpen={modal.open} onClose={onClose} title={title} size="3xl" scrollBehavior="inside">
      <Form form={form}>
        <Box mt={3}>
          <RichTextContextProvider value={templateRichTextContext}>
            <RichTextField<EditQuoteNotesForm>
              noBorder
              name="text"
              navbarType="block"
              hideErrorMessage
              isDisabled={!isEditable}
              autoFocus
              inputProps={{
                minH: '10rem',
                fontWeight: 500,
                fontSize: 'sm',
              }}
              configuration={RICH_TEXT_BLOCK_TOOLBAR_PRESET}
            />
          </RichTextContextProvider>
        </Box>
      </Form>

      <Modal.Close />

      <Modal.PrimaryButton isDisabled={!isFormValid} onClick={handleSubmit}>
        {t(isEditable ? 'global:words.c.save' : 'global:words.c.close')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
