import type { IBankingTransaction } from '@graneet/business-logic';
import type { FC } from 'react';
import type { ButtonColorScheme } from '@graneet/lib-ui';
import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

export const BANKING_TRANSACTION_STATUS_COLOR: Record<IBankingTransaction['status'], ButtonColorScheme> = {
  TO_RECONCILE: 'gray',
  PARTIALLY_RECONCILED: 'yellow',
  RECONCILED: 'green',
};

interface BankingTransactionStatusBadgeProps {
  bankingTransaction: Pick<IBankingTransaction, 'status'>;
}

export const BankingTransactionStatusBadge: FC<BankingTransactionStatusBadgeProps> = ({ bankingTransaction }) => {
  const { t } = useTranslation(['banking']);

  return (
    <Badge colorScheme={BANKING_TRANSACTION_STATUS_COLOR[bankingTransaction.status]}>
      {t(`banking:bankingTransaction.status.${bankingTransaction.status}`)}
    </Badge>
  );
};
