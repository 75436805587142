import { useCallback } from 'react';
import { useToast, useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import * as jobsApi from '../services/quote-job.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useComponentContext } from 'features/quote-component/contexts/component.context';

/**
 * Edit unit price of quote job and return error state
 */
export const useEditJobUnitPrice = ({ updateComponentContext }: { updateComponentContext: boolean }) => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { symbol } = useCurrency();
  const { updateDataLocally, getCurrentQuoteData } = useQuoteEditContext();
  const componentContext = useComponentContext();

  return useCallback(
    async (jobId: number, unitPriceExVAT: number) => {
      const { jobs } = getCurrentQuoteData();
      const { unitDisbursementExVAT } = jobs[jobId];

      if ((unitDisbursementExVAT || 0) * unitPriceExVAT < 0) {
        toast.error(t('quote:jobsStep.errors.unitPriceOppositeSign'));
        return true;
      }

      if (unitPriceExVAT === 0 && unitDisbursementExVAT !== 0 && unitDisbursementExVAT !== null) {
        toast.error(t('quote:jobsStep.errors.unitPriceEmpty', { zeroAmount: `0 ${symbol}` }));
        return true;
      }

      // If job have components, unit price cannot be set to null
      const hasComponents = !!jobs[jobId].components.length;
      if (hasComponents && unitPriceExVAT === null) {
        toast.error(t('quote:jobsStep.errors.unitPriceNullWithComponents'));
        return true;
      }

      const dto = {
        unitPriceExVAT,
      };
      const [err, result] = await jobsApi.updateJobUnitPrice(jobId, dto);

      if (err) {
        toast.error(t('quote:errors.updatingError'));
        return true;
      }

      updateDataLocally(result);
      if (updateComponentContext) {
        componentContext.setComponents(Object.values(result.components));
      }
      return result;
    },
    [getCurrentQuoteData, updateDataLocally, updateComponentContext, toast, t, symbol, componentContext],
  );
};
