import type { FC } from 'react';
import { useCallback } from 'react';
import { GridItem, Stack } from '@chakra-ui/react';
import { useToast, Container } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IProject, RequiredByKeys } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { QuoteStatus, type QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';
import { uniqueId } from 'lodash-es';

import { ProjectLinkAndUnlinkCard } from 'features/project/components/cards/ProjectLinkAndUnlinkCard';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { AccountManagerAssociationCard } from 'features/account-manager/components/AccountManagerAssociationCard/AccountManagerAssociationCard';
import { useQuotationApi } from 'features/quotation/services/quote.api';
import { QuoteNoteCard } from 'features/quotation/quote/components/cards/QuoteNoteCard';
import { QuoteMentionsCard } from 'features/quotation/quote/components/cards/QuoteMentionsCard';

interface ViewQuoteInformationTabProps {
  quote: QuoteWithoutRelationsDTO;
}

export const ViewQuoteInformationTab: FC<ViewQuoteInformationTabProps> = ({ quote }) => {
  const { t } = useTranslation(['quote', 'accountManager', 'global']);
  const toast = useToast();

  const hasPermissions = usePermissions([PERMISSION.CREATE_QUOTES]);

  const {
    refetchQuoteById: reloadQuote,
    useGetProjectInformationById,
    useQuotePartialUpdate,
    useGetUsersByIds,
  } = useQuotationApi();
  const { data: project } = useGetProjectInformationById(quote.projectId);
  const { data: accountManagerUsers } = useGetUsersByIds(quote.accountManagers?.map(Number) ?? []);
  const quotePartialUpdateMutation = useQuotePartialUpdate();

  const updateProject = useCallback(
    async (newProject: IProject | null) => {
      const projectId = newProject ? newProject.id : null;
      const address = {
        address: (newProject ? newProject.address?.address : quote.address?.address) ?? null,
        city: (newProject ? newProject.address?.city : quote.address?.city) ?? null,
        postalCode: (newProject ? newProject.address?.postalCode : quote.address?.postalCode) ?? null,
        country: (newProject ? newProject.address?.country : quote.address?.country) ?? null,
        quoteId: quote.id,
        clientRequestId: uniqueId(),
        timestamp: new Date().toISOString(),
      };
      await quotePartialUpdateMutation.mutateAsync({ quoteId: quote.id, projectId, address });

      toast.success(t(`quote:cards.project.${projectId ? 'successLink' : 'successUnlink'}`));
      await reloadQuote(quote.id);
    },
    [
      quote.address?.address,
      quote.address?.city,
      quote.address?.postalCode,
      quote.address?.country,
      quote.id,
      quotePartialUpdateMutation,
      toast,
      t,
      reloadQuote,
    ],
  );

  const handleAccountManagersUpdated = useCallback(
    async (accountManagersIds: number[]) => {
      await quotePartialUpdateMutation.mutateAsync({
        quoteId: quote.id,
        accountManagers: accountManagersIds.map((id) => String(id)),
      });

      toast.success(t('accountManager:toast.associatedSuccess'));
    },
    [quote.id, quotePartialUpdateMutation, t, toast],
  );

  const removeProject = useCallback(() => updateProject(null), [updateProject]);

  return (
    <Container size="md">
      <Stack mb={8} gap={4}>
        <GridItem>
          <ProjectLinkAndUnlinkCard
            project={project as RequiredByKeys<IProject, 'address'>}
            onLink={updateProject}
            onUnlink={removeProject}
            translations={{
              linkLabel: t('quote:cards.project.link'),
              unlinkLabel: t('quote:cards.project.unlink'),
              modalTitle: t('quote:cards.project.modalTitle'),
              modalDescription: t('quote:cards.project.modalDesc'),
            }}
            canUpdateLink={hasPermissions && quote.status !== QuoteStatus.Accepted}
          />
        </GridItem>

        <GridItem>
          <AccountManagerAssociationCard
            associatedAccountManagerUsers={accountManagerUsers}
            onAccountManagersUpdated={handleAccountManagersUpdated}
            emptyStateLabel={t('accountManager:quote.emptyState')}
            actionLabel={t('accountManager:cta.associateOneToQuote')}
            changeAssociatedLabel={t('accountManager:cta.changeAssociatedQuote')}
            modalActionLabel={t('accountManager:cta.associateToQuote')}
            modalTitleLabel={t('accountManager:quote.associateTitle')}
            modalDescriptionLabel={t('accountManager:quote.associateDescription')}
            hasUpdatePermission={hasPermissions}
          />
        </GridItem>
      </Stack>

      <QuoteNoteCard quote={quote} isReadOnly={!hasPermissions} />

      <QuoteMentionsCard quote={quote} isReadOnly={!hasPermissions} />
    </Container>
  );
};
