import { useTranslation } from 'react-i18next';
import { HStack } from '@chakra-ui/react';
import { Button } from '@graneet/lib-ui';
import type { FC } from 'react';
import { PERMISSION } from '@graneet/business-logic';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

export type OrdersActionsButtonsProps = {
  /**
   * Event callback when user click on 'add' button
   */
  onCreate(): void;
};

export const OrdersActionsButtons: FC<OrdersActionsButtonsProps> = ({ onCreate }) => {
  const { t } = useTranslation(['orders']);

  const getUpdateButtonProps = useDisabledButtonProps([PERMISSION.CREATE_ORDER]);

  return (
    <HStack spacing={6}>
      <Button onClick={onCreate} colorScheme="blue" {...getUpdateButtonProps()}>
        {t('orders:actions.create')}
      </Button>
    </HStack>
  );
};
