import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

import * as lotsApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useAddLotAfterAnother = () => {
  const { t } = useTranslation(['quote']);
  const toast = useToast();

  const { emitLotUpdate, emitQuoteUpdate, insertLotsAfterOtherLotLocally, emitMaxLotDepthUpdate } =
    useQuoteEditContext();

  return useCallback(
    async (lotId: number, previousLotId?: number) => {
      const [err, infos] = await lotsApi.createLot(lotId, previousLotId);
      if (err) {
        toast.error(t('quote:jobsStep.errors.addingLotError'));
        return;
      }
      insertLotsAfterOtherLotLocally(infos.lots, lotId, previousLotId);

      // Notify the matching lot component it needs to re-render
      emitLotUpdate(lotId);
      emitQuoteUpdate(infos.quote);
      emitMaxLotDepthUpdate();
    },
    [insertLotsAfterOtherLotLocally, emitLotUpdate, emitQuoteUpdate, toast, t, emitMaxLotDepthUpdate],
  );
};
