import type { FC } from 'react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { IItem } from '@graneet/business-logic';
import { CUMULATIVE_INPUT_TYPE } from '@graneet/business-logic';
import { formatNumber, formatPercentage, PriceAdvanced } from '@graneet/lib-ui';

import type { IProgressStatementLineValues } from '../../services/progress-statement.util';
import type { ProgressStatementEditItemForm } from '../../forms/progress-statement-edit-item-form';

interface ProgressStatementItemPreviousCumulativeProps {
  item: Pick<IItem, 'unit'>;

  previousItemValues?: IProgressStatementLineValues;
}

export const ProgressStatementItemPreviousCumulative: FC<ProgressStatementItemPreviousCumulativeProps> = ({
  item,
  previousItemValues,
}) => {
  const form = useFormContext<ProgressStatementEditItemForm>();
  const { cumulativeInputType } = useOnChangeValues(form, ['cumulativeInputType']);

  switch (cumulativeInputType) {
    case CUMULATIVE_INPUT_TYPE.QUANTITY:
      return <>{`${formatNumber(previousItemValues?.cumulativeProgressQuantity || 0)} ${item.unit}`}</>;
    case CUMULATIVE_INPUT_TYPE.AMOUNT:
      return <PriceAdvanced amount={previousItemValues?.cumulativeAmountExVAT || 0} />;
    default: // TODO : improve
      return <>{formatPercentage(previousItemValues?.cumulativeProgressPercentage || 0)}</>;
  }
};
