import { GraneetButton } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

export const QuoteToolBarToggleGridButton = ({ value, onClick }: { value: boolean; onClick: () => void }) => {
  const { t } = useTranslation(['quote']);

  return (
    <GraneetButton
      leftIcon={<i className={value ? 'ri-eye-off-line' : 'ri-eye-line'} />}
      variant="ghost"
      onClick={onClick}
    >
      {t(value ? 'quote:quotation.tooltips.hideGrid' : 'quote:quotation.tooltips.showGrid')}
    </GraneetButton>
  );
};
