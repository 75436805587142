import {
  type QuoteCustomDiscountCreateDTO,
  type QuoteCustomDiscountUpdateDTO,
  QuoteCustomDiscountType,
} from '@org/graneet-bff-client';
import { type QuoteCustomDiscount, QUOTE_CUSTOM_DISCOUNT_TYPE } from '@org/quotation-lib';

export const mapCustomDiscountType = (value: QUOTE_CUSTOM_DISCOUNT_TYPE) => {
  switch (value) {
    case QUOTE_CUSTOM_DISCOUNT_TYPE.AMOUNT:
      return QuoteCustomDiscountType.Amount;
    case QUOTE_CUSTOM_DISCOUNT_TYPE.PERCENTAGE:
      return QuoteCustomDiscountType.Percentage;
    default:
      throw new Error('margin enum type is not matching');
  }
};

export const mapQuoteCustomDiscountToQuoteCustomDiscountUpdateDTO = (
  quoteCustomDiscount: QuoteCustomDiscount,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteCustomDiscountUpdateDTO => {
  const quoteCustomDiscountObject = quoteCustomDiscount.export();
  return {
    quoteId,
    timestamp,
    clientRequestId,
    ...(quoteCustomDiscountObject.name && { name: quoteCustomDiscountObject.name }),
    ...(quoteCustomDiscountObject.vatRate && { vatRate: quoteCustomDiscountObject.vatRate }),
    amount: quoteCustomDiscountObject.amount,
    percentage: quoteCustomDiscountObject.percentage,
    type: mapCustomDiscountType(quoteCustomDiscountObject.type),
  };
};

export const mapQuoteCustomDiscountToQuoteCustomDiscountCreateDTO = (
  quoteCustomDiscount: QuoteCustomDiscount,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteCustomDiscountCreateDTO => {
  const quoteCustomDiscountObject = quoteCustomDiscount.export();
  if (!quoteCustomDiscountObject.name || !quoteCustomDiscountObject.vatRate)
    throw new Error('Missing name or vatRate field to map the custom discount');
  return {
    quoteCustomDiscountId: quoteCustomDiscount.getId(),
    quoteId,
    timestamp,
    clientRequestId,
    name: quoteCustomDiscountObject.name,
    vatRate: quoteCustomDiscountObject.vatRate,
    amount: quoteCustomDiscountObject.amount,
    percentage: quoteCustomDiscountObject.percentage,
    type: mapCustomDiscountType(quoteCustomDiscountObject.type),
  };
};
