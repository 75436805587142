import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import { useCallback } from 'react';
import { keyBy } from 'lodash-es';

import { useQuoteEditContext } from '../../quote/hooks/useQuoteEditContext';
import * as lotsApi from '../services/quote-lot.api';

export const useImportBatiprixJobInLot = () => {
  const { t } = useTranslation(['quote']);
  const toast = useToast();
  const { updateDataLocally, emitLotUpdate, insertJobsAfterJobInLotInRelations, getCurrentQuoteData } =
    useQuoteEditContext();

  return useCallback(
    async ({ token, sessionId }: { token: string; sessionId: string }, lotId: number) => {
      const { relations, rootLotId } = getCurrentQuoteData();
      const jobsOfLot = relations[lotId]?.jobs;
      const previousJobId = jobsOfLot && Object.values(jobsOfLot).length > 0 ? jobsOfLot[jobsOfLot.length - 1] : null;

      if (!rootLotId) {
        return [null];
      }

      const [err, infos] = await lotsApi.importBatiprixJobsLot(lotId, {
        token,
        batiprixSessionId: sessionId,
        previousJobId,
      });

      if (err) {
        toast.error(t('quote:jobsStep.errors.importJobsError'));
        return [err];
      }

      insertJobsAfterJobInLotInRelations(infos.jobs, lotId, previousJobId || undefined);

      const { jobs } = infos;

      const groupJobsComponents = Object.values(jobs)
        .map(({ components }) => components)
        .flat();

      const newComponent = keyBy(groupJobsComponents, 'id');

      // Notify the matching lot component it needs to re-render
      updateDataLocally({ ...infos, components: newComponent });
      emitLotUpdate(lotId);

      toast.success(t('quote:toasts.batiprixImportSuccess', { count: Object.values(infos.jobs).length }));

      return [null];
    },
    [emitLotUpdate, getCurrentQuoteData, insertJobsAfterJobInLotInRelations, t, toast, updateDataLocally],
  );
};
