import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Form, HiddenField, useForm, useFormStatus, useOnChangeValues } from 'graneet-form';
import { Badge, Modal, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Image, Flex, HStack, Radio, VStack, Text } from '@chakra-ui/react';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { Rule } from 'features/form/rules/Rule';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface CreateQuoteModalProps {
  isOpen: boolean;

  onClose(): void;

  onSubmit(information: { name: string; version: number }): Promise<void>;

  title: string;

  description: string;

  action: string;

  allowedVersions?: number[];
}

interface CreateQuoteForm {
  name: string;
  version: number;
}

const VersionControl: FC<{
  version: number;
  isSelected: boolean;
  onClick: () => void;
}> = ({ version, isSelected, onClick }) => {
  const { t } = useTranslation(['quote', 'global']);
  const handleOnClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <HStack
      background={!isSelected ? '#F2F2F0' : 'white'}
      borderRadius="0.5rem"
      borderWidth="2px"
      borderColor={isSelected ? 'greenBrand.light' : 'transparent'}
      alignItems="center"
      onClick={handleOnClick}
      width="100%"
      padding="0.5rem"
      cursor="pointer"
      justifyContent="flex-start"
      gap="12px"
    >
      <Image
        src={version === 2 ? '/media/images/new-interface.png' : '/media/images/current-interface.png'}
        alt="quote"
      />
      <VStack justifyContent="flex-start" alignItems="flex-start" gap="2px">
        <HStack>
          <Text>
            {version === 2
              ? t('quote:quotation.modals.create.newModule')
              : t('quote:quotation.modals.create.oldModule')}
          </Text>
          {version === 2 && (
            <Badge colorScheme="blue">
              <HStack width="auto" fontWeight={600} color="#2563EB">
                <i className="ri-tools-fill" />
                <Text>{t('global:words.c.new')}</Text>
              </HStack>
            </Badge>
          )}
        </HStack>
        <Text fontSize="12px" color="rgba(6, 6, 0, 0.43)">
          {version === 2
            ? t('quote:quotation.modals.create.newModuleDescription')
            : t('quote:quotation.modals.create.oldModuleDescription')}
        </Text>
      </VStack>
      <Flex alignItems="center" justifyContent="center" pt={1} ml="auto">
        <Radio colorScheme="greenBrand" isChecked={isSelected} isReadOnly />
      </Flex>
    </HStack>
  );
};

export const CreateQuoteModal: FC<CreateQuoteModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  action,
  allowedVersions = [1, 2],
}) => {
  const { t } = useTranslation(['quote']);

  const [isLoading, setIsLoading] = useState(false);

  const isQuoteV1Disabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_DISABLE_V1);

  const form = useForm<CreateQuoteForm>({
    // eslint-disable-next-line no-nested-ternary
    defaultValues: { name: '', version: isQuoteV1Disabled ? 2 : allowedVersions.includes(2) ? 2 : 1 },
  });
  const { isValid: isFormValid } = useFormStatus(form);
  const { setFormValues } = form;

  const { version } = useOnChangeValues(form, ['version']);

  const handleSubmit = useCallback(
    async (values: CreateQuoteForm) => {
      setIsLoading(true);

      await onSubmit({ name: values.name!, version: values.version! });

      setIsLoading(false);
    },
    [onSubmit],
  );

  const handleVersionChange = useCallback(
    (v: number) => () => {
      setFormValues({ version: v });
    },
    [setFormValues],
  );

  const selectedVersion = useMemo((): number => (version === undefined ? 2 : version!), [version]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="2xl">
      <Form form={form} onSubmit={form.handleSubmit(handleSubmit)} id="CREATE_QUOTE_MODAL_FORM">
        <VStack gap={5} width="100%">
          {!isQuoteV1Disabled && (
            <VStack width="100%">
              {allowedVersions.includes(2) && (
                <VersionControl version={2} isSelected={selectedVersion === 2} onClick={handleVersionChange(2)} />
              )}
              {allowedVersions.includes(1) && (
                <VersionControl version={1} isSelected={selectedVersion === 1} onClick={handleVersionChange(1)} />
              )}
            </VStack>
          )}
          <HiddenField<CreateQuoteForm> name="version" />
          <TextField<CreateQuoteForm> name="name" label={t('quote:creationForm.fields.name')} autoFocus>
            <Rule.IsRequired />
          </TextField>
          <HStack width="100%" color="rgba(6, 6, 0, 0.43)" fontSize="12px" mt={-4}>
            <i className="ri-information-line" />
            <Text fontSize="12px" color="rgba(6, 6, 0, 0.43)">
              {t('quote:quotation.modals.create.info')}
            </Text>
          </HStack>
        </VStack>

        <Modal.Close />
        <Modal.PrimaryButton
          isLoading={isLoading}
          isDisabled={!isFormValid}
          type="submit"
          form="CREATE_QUOTE_MODAL_FORM"
        >
          {action}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
