import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { DisplayNotFoundScreen } from '../../DisplayNotFoundScreen';

import { ViewHoldbacksScreen } from './ViewHoldbacksScreen';
import { ViewHoldbackDetailScreen } from './ViewHoldbackDetailScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';

export const HoldbacksRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ViewHoldbacksScreen />
      </Route>
      <Route exact path={`${path}/:holdbackId`}>
        <QueryWrapper>
          <ViewHoldbackDetailScreen />
        </QueryWrapper>
      </Route>
      <Route path="*">
        <DisplayNotFoundScreen />
      </Route>
    </Switch>
  );
};
