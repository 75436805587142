import type { IDiscount, Raw } from '@graneet/business-logic';
import { getDiscountAmountExVAT } from '@graneet/business-logic';
import type { DrawerApi } from '@graneet/lib-ui';
import {
  DifferenceSummary,
  SimpleDeleteIcon,
  SimpleEditIcon,
  SimpleRewindIcon,
  useCurrency,
  useToast,
} from '@graneet/lib-ui';
import { isNil } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import { useContractRuleContext } from 'features/contract/contexts/ContractRuleContext';
import type { ContractEditionForm } from 'features/contract/forms/contract-edition.form';

export interface ContractDiscountAmountSummaryLineProps {
  discountDrawer: DrawerApi<string>;

  currentDiscount: Raw<IDiscount> | undefined;

  totalAmountWithoutDiscountExVAT: {
    initialAmount: number;
    newAmount: number;
  };
}

export const ContractDiscountAmountSummaryLine: FC<ContractDiscountAmountSummaryLineProps> = ({
  currentDiscount,
  discountDrawer,
  totalAmountWithoutDiscountExVAT,
}) => {
  const { t } = useTranslation(['global', 'contracts']);
  const toast = useToast();

  const form = useFormContext<ContractEditionForm>();
  const { canDiscountBeUpdatedOrDeleted, canDiscountBeDeleted, contractInfos } = useContractRuleContext();
  const { discountAmountExVAT: currentDiscountAmountExVAT } = useOnChangeValues(form, ['discountAmountExVAT']);

  const initialDiscount = contractInfos?.contract.discount;

  const isDiscountRemoved = useMemo(
    () => !!initialDiscount && currentDiscountAmountExVAT === null,
    [currentDiscountAmountExVAT, initialDiscount],
  );

  const displayDiscountLines = !isNil(currentDiscount) || isDiscountRemoved;

  const { mapNumberToAmount } = useCurrency();
  const initialDiscountAmountExVAT = getDiscountAmountExVAT(
    initialDiscount,
    totalAmountWithoutDiscountExVAT.initialAmount,
  );

  const handleDiscountRemove = useCallback(() => {
    try {
      const rule = canDiscountBeDeleted();
      if (!rule.canUpdate) {
        throw new Error(rule.errorMessage);
      }

      form.setFormValues({
        discountAmountExVAT: null,
        hasDiscountBeenUpdated: true,
      });
    } catch (error) {
      toast.error((error as Error).message);
    }
  }, [canDiscountBeDeleted, form, toast]);

  const handleDiscountRestore = useCallback(() => {
    form.setFormValues({
      discountAmountExVAT: initialDiscount ? mapNumberToAmount(initialDiscountAmountExVAT) : null,
    });
  }, [initialDiscount, form, initialDiscountAmountExVAT, mapNumberToAmount]);

  const discountDropdownItems = useMemo(() => {
    const items = [];

    const restoreDiscount = {
      onClick: handleDiscountRestore,
      icon: <SimpleRewindIcon />,
      label: t('global:words.c.restore'),
    };
    const deleteDiscount = {
      onClick: handleDiscountRemove,
      icon: <SimpleDeleteIcon />,
      warning: true,
      label: t('global:words.c.delete'),
    };
    const editDiscount = {
      onClick: discountDrawer.onOpen,
      icon: <SimpleEditIcon />,
      label: t('global:words.c.update'),
    };

    if (isNil(initialDiscount)) {
      // New discount
      items.push(editDiscount, deleteDiscount);
    } else if (isDiscountRemoved) {
      // Deleted discount
      items.push(restoreDiscount);
    } else if (initialDiscountAmountExVAT !== currentDiscount?.amountExVAT) {
      // Edited discount
      items.push(editDiscount, restoreDiscount, deleteDiscount);
    } else if (canDiscountBeUpdatedOrDeleted()) {
      // Existing discount
      items.push(editDiscount, deleteDiscount);
    }

    return items;
  }, [
    handleDiscountRestore,
    t,
    handleDiscountRemove,
    discountDrawer,
    initialDiscount,
    isDiscountRemoved,
    initialDiscountAmountExVAT,
    currentDiscount?.amountExVAT,
    canDiscountBeUpdatedOrDeleted,
  ]);

  return (
    <>
      {displayDiscountLines && (
        <>
          <DifferenceSummary.Item
            label={t('contracts:totalAmountWithoutDiscountExVAT')}
            initialAmount={totalAmountWithoutDiscountExVAT.initialAmount}
            newAmount={totalAmountWithoutDiscountExVAT.newAmount}
            important
          />

          <DifferenceSummary.Discount
            label={t('contracts:discount')}
            initialDiscountAmount={initialDiscountAmountExVAT}
            newDiscountAmount={currentDiscount?.amountExVAT || 0}
            isDiscountRemoved={isDiscountRemoved}
            dropdownItems={discountDropdownItems}
          />
        </>
      )}
    </>
  );
};
