import type { FC, ReactNode } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Card } from '@graneet/lib-ui';

interface IntegrationCardProps {
  title: ReactNode;
  icon: ReactNode;
  actions: ReactNode;
  secondaryContent?: ReactNode;
}

// TODO: See if it must be moved to lib-ui
export const IntegrationCard: FC<IntegrationCardProps> = ({ title, icon, actions, secondaryContent }) => (
  <Card variant="light-gray">
    <Flex w="100%" justifyContent="space-between" gap={2}>
      <Flex gap={2}>
        <Flex w="3.5rem" alignItems="center">
          <Flex bg="white" boxSize="2.25rem" borderRadius="md" p={1} justifyContent="center" boxShadow="md">
            {icon}
          </Flex>
        </Flex>

        <Box>
          <Flex gap={5} color="gray.950" alignItems="center" fontWeight={400}>
            {title}
          </Flex>

          <Box fontSize="xs" color="greenBrand.baseSecondary">
            {secondaryContent}
          </Box>
        </Box>
      </Flex>

      <Flex alignItems="center" gap={2} minW="15rem" justifyContent="flex-end">
        {actions}
      </Flex>
    </Flex>
  </Card>
);
