import type { AnyRecord, FieldValues } from 'graneet-form';
import { composeEventHandlers, Field } from 'graneet-form';
import type { ReactNode } from 'react';

import type { KeysMatching } from '../../../utils';
import { RadioGroup } from '../../RadioGroup';
import { Option } from '../../RadioGroup/Option';
import { CardOption } from '../../RadioGroup/CardOption';
import type { RadioGroupProps } from '../../RadioGroup/RadioGroup';

type RadioGroupFieldValue = string | undefined;

export interface RadioGroupFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, RadioGroupFieldValue> = KeysMatching<T, RadioGroupFieldValue>,
> extends Omit<RadioGroupProps, 'onChange' | 'children' | 'value' | 'name'> {
  name: K;
  children?: ReactNode;
  data?: AnyRecord;
  rules?: ReactNode;
  onChange?(value: T[K]): void | Promise<void>;
  onFocus?(): void;
  onBlur?(): void;
}

const RadioGroupFieldComponent = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, RadioGroupFieldValue> = KeysMatching<T, RadioGroupFieldValue>,
>({
  name,
  data,
  children,
  rules,
  onBlur,
  onChange,
  onFocus,
  ...rest
}: RadioGroupFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    data={data}
    render={({ value, onChange: onChangeForm, onBlur: onBlurForm, onFocus: onFocusForm }) => (
      <RadioGroup
        {...rest}
        name={name as string}
        value={value}
        onChange={composeEventHandlers(onChange, onChangeForm) as any}
        onBlur={composeEventHandlers(onBlur, onBlurForm)}
        onFocus={composeEventHandlers(onFocus, onFocusForm)}
      >
        {children}
      </RadioGroup>
    )}
  >
    {rules}
  </Field>
);

export const RadioGroupField = Object.assign(RadioGroupFieldComponent, {
  Option,
  CardOption,
});
