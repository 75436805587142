import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const HammerIcon: FC<IconProps> = ({ backgroundColor = 'white', ...props }) => (
  <Icon viewBox="0 0 300 300" color={backgroundColor} {...props}>
    <svg width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="150" cy="150" r="150" fill="currentcolor" />
      <path
        d="M218.671 221.207C211.339 227.136 200.617 225.985 194.702 218.653C116.363 121.548 157.023 171.947 126.906 134.614C127.413 134.541 127.888 134.33 128.298 134.002L152.253 114.724C152.686 114.376 153.003 113.922 153.181 113.406C162.059 124.351 213.815 188.141 221.188 197.228C227.129 204.548 226.001 215.281 218.671 221.207Z"
        fill="#B0E2FF"
      />
      <path
        d="M218.879 221.034C216.168 217.673 144.754 129.149 140.648 124.063L152.252 114.724C152.686 114.376 153.002 113.922 153.181 113.406C153.181 113.406 199.907 171.004 221.188 197.228C227.073 204.478 226.021 215.076 218.879 221.034Z"
        fill="#83CDFA"
      />
      <path d="M169.493 133.514L143.118 154.713L137.599 147.87L163.956 126.691L169.493 133.514Z" fill="#53B9F6" />
      <path
        d="M169.493 133.514L156.621 143.86L151.102 137.02L163.956 126.691C165.778 128.933 164.646 127.539 169.493 133.514Z"
        fill="#109CF1"
      />
      <path
        d="M182.314 84.7873C159.412 71.3392 137.955 71.7569 120.263 85.9961L101.593 101.022C99.6965 102.548 99.4026 105.329 100.939 107.217C102.441 109.065 102.201 111.801 100.271 113.355C98.3802 114.876 95.6017 114.582 94.0774 112.698C92.5539 110.815 89.7947 110.52 87.9077 112.037L79.912 118.463C74.2367 123.031 73.3457 131.315 77.9383 136.98L88.9949 150.617C93.5667 156.256 101.885 157.132 107.534 152.586L115.527 146.161C117.423 144.637 117.72 141.86 116.187 139.969C114.663 138.089 114.96 135.349 116.845 133.832C118.737 132.309 121.519 132.603 123.048 134.487C124.572 136.368 127.33 136.66 129.217 135.143L153.17 115.865C155.064 114.34 155.359 111.564 153.828 109.675L148.36 102.93C158.362 95.4762 165.221 93.506 180.246 92.9673C182.199 92.8972 183.87 91.547 184.349 89.6529C184.828 87.7592 183.998 85.7766 182.314 84.7873Z"
        fill="#004E7A"
      />
      <path
        d="M184.349 89.6523C183.871 91.5446 182.199 92.8952 180.248 92.9655C165.222 93.5046 158.361 95.4759 148.36 102.931L153.829 109.674C155.373 111.58 155.049 114.358 153.17 115.864L141.569 125.202C140.07 123.346 115.061 92.3482 114.002 91.0351L120.262 85.9966C137.954 71.7575 159.413 71.3386 182.313 84.787C183.997 85.7769 184.826 87.7571 184.349 89.6523Z"
        fill="#002F4C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.0083 68.9676C78.8107 70.3996 75.3865 71.0298 71.5429 70.6544C69.9462 74.1707 67.6883 76.8211 65.3493 78.0083C63.9174 75.8107 63.2872 72.3865 63.6626 68.5429C60.1462 66.9462 57.4958 64.6883 56.3086 62.3493C58.5062 60.9174 61.9305 60.2872 65.7741 60.6626C67.3708 57.1462 69.6287 54.4958 71.9676 53.3086C73.3996 55.5062 74.0298 58.9305 73.6544 62.7741C77.1707 64.3708 79.8211 66.6287 81.0083 68.9676ZM258.354 108.704C254.789 110.008 249.756 109.782 244.46 108.002C241.064 112.439 237.018 115.441 233.334 116.354C232.03 112.789 232.256 107.756 234.037 102.46C229.599 99.0639 226.597 95.0179 225.685 91.3339C229.249 90.0303 234.282 90.2564 239.579 92.0367C242.974 87.5989 247.02 84.5971 250.704 83.6846C252.008 87.249 251.782 92.2819 250.002 97.5786C254.439 100.974 257.441 105.02 258.354 108.704ZM236.701 247.105C239.036 250.271 241.847 252.433 244.429 253.121C245.391 250.628 245.293 247.082 244.105 243.332C247.271 240.997 249.433 238.186 250.121 235.604C247.628 234.642 244.083 234.74 240.332 235.928C237.997 232.762 235.186 230.6 232.604 229.912C231.643 232.405 231.74 235.95 232.928 239.701C229.762 242.036 227.6 244.847 226.912 247.429C229.405 248.39 232.951 248.293 236.701 247.105ZM103.783 241.775C100.611 240.931 97.158 238.274 94.2887 234.385C89.6815 235.845 85.326 235.964 82.2634 234.783C83.1081 231.611 85.7649 228.158 89.6537 225.288C88.1941 220.681 88.0746 216.326 89.2556 213.263C92.4275 214.108 95.881 216.765 98.7503 220.653C103.357 219.194 107.713 219.074 110.776 220.255C109.931 223.427 107.274 226.881 103.385 229.75C104.845 234.357 104.964 238.713 103.783 241.775Z"
        fill="#109CF1"
      />
    </svg>
  </Icon>
);
