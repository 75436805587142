import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import { Card, Date, FieldGroupLabel, LabeledData, RichText } from '@graneet/lib-ui';
import type { IOrderResponseDTO } from '@graneet/business-logic';

import { OrderComponentTypeBadge } from '../OrderComponentTypeBadge';

type InformationsCardProps = {
  order: IOrderResponseDTO;
};

export const InformationsCard: FC<InformationsCardProps> = ({ order }) => {
  const { t } = useTranslation(['orders', 'global']);
  const { orderNumber, orderDate, deliveryDate, deliveryAddress, note } = order;

  return (
    <Card title={t('orders:informationsCard.title')}>
      <Flex direction="column" mb={2.5}>
        <Box mb={6}>
          <Flex wrap="wrap" justify={{ base: 'center', xl: 'flex-start' }} align="start">
            <Box flexBasis={{ base: '100%', xl: '33.33%' }} py={2}>
              <LabeledData label={t('orders:informationsCard.orderNumber')} data={orderNumber} />
            </Box>
            <Box flexBasis={{ base: '100%', xl: '33.33%' }} py={2}>
              <LabeledData
                label={t('orders:informationsCard.type')}
                data={<OrderComponentTypeBadge order={{ ...order, orderItems: Object.values(order.orderItems) }} />}
              />
            </Box>
            <Box flexBasis={{ base: '100%', xl: '33.33%' }} py={2}>
              <LabeledData label={t('orders:informationsCard.orderDate')} data={<Date>{orderDate}</Date>} />
            </Box>
            <Box flexBasis={{ base: '100%', xl: '33.33%' }} py={2}>
              <LabeledData label={t('orders:informationsCard.deliveryDate')} data={<Date>{deliveryDate}</Date>} />
            </Box>
          </Flex>
        </Box>

        <Box mb={6}>
          <FieldGroupLabel>{t('orders:informationsCard.deliveryAddress')}</FieldGroupLabel>
          <LabeledData label={t('global:address.label')} data={deliveryAddress?.address} />

          <Flex justify="space-between" pt={2}>
            <Box flex="1" py={3} pr={3}>
              <LabeledData label={t('global:address.city')} data={deliveryAddress?.city} />
            </Box>
            <Box flex="1" p={3}>
              <LabeledData label={t('global:address.postalCode')} data={deliveryAddress?.postalCode} />
            </Box>
            <Box flex="1" p={3}>
              <LabeledData label={t('global:address.country')} data={deliveryAddress?.country} />
            </Box>
          </Flex>
        </Box>

        <Box mb={6}>
          <LabeledData label={t('orders:informationsCard.additionalInformation')} data={<RichText value={note} />} />
        </Box>
      </Flex>
    </Card>
  );
};
