import type { FC, MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { GridItem, useDisclosure, VStack } from '@chakra-ui/react';
import type { FormContextApi } from 'graneet-form';
import { Form, useOnChangeValues, useStepForm } from 'graneet-form';
import { formatNumberToVatRate, TwoColumns, useCurrency, getCurrentDateAsString } from '@graneet/lib-ui';
import { useLocation } from 'react-router-dom';
import type {
  IOrderResponseDTO,
  IProject,
  ISubProject,
  ISupplierInvoice,
  RequiredByKeys,
} from '@graneet/business-logic';
import { DEFAULT_VAT_RATE, PERMISSION, VAT_TYPE, isNumberFinite } from '@graneet/business-logic';
import { isNil } from 'lodash-es';

import { usePermissions } from 'features/role/hooks/usePermissions';
import type { OrderEditForm, OrderEditWizard } from 'features/order/forms/order-edit-wizard';
import { getOrderItemFieldName } from 'features/order/forms/order-edit-wizard';
import type { InitialOrderTree, OrderTree } from 'features/order/hooks/useOrderTree';
import { useOrderTree } from 'features/order/hooks/useOrderTree';
import { mapOrderResponseDTOToInitialTree } from 'features/order/services/order.util';
import { OrderDirectPaymentInfosProvider } from 'features/order/contexts/OrderDirectPaymentInfosContext';
import { OrderFileReceiptFilesCard } from 'features/order/components/cards/OrderFileReceiptFilesCard/OrderFileReceiptFilesCard';
import { useAssociableProjectsWithoutPaginationQuery } from 'features/project/services/project.api';
import { Error } from 'features/common/components/Error';
import { useCompanyLegalMentions } from 'features/company/services/company.api';
import { OrderProjectCard } from 'features/order/components/cards/OrderProjectCard';
import { OrderInformationCard } from 'features/order/components/cards/OrderInformationCard';
import { OrderSupplierCard } from 'features/order/components/cards/OrderSupplierCard';
import { OrderEditAssociatedSupplierInvoiceCard } from 'features/order/components/cards/OrderEditAssociatedSupplierInvoiceCard';
import { OrderCommentCard } from 'features/order/components/cards/OrderCommentCard';
import { OrderSubProjectSelectionModal } from 'features/order/components/OrderSubProjectSelectionModal';
import { OrderAmountCard } from 'features/order/components/cards/OrderAmountCard';
import {
  useSubProjectsByProjectLazy,
  useSubProjectsByProjectQuery,
} from 'features/sub-project/services/sub-project.api';
import { useDefaultVatRate } from 'features/common/hooks/useDefaultVATRate';

const getProjectGetterFilters = (isDirectPayment: boolean) => ({
  onlyWithSubProject: isDirectPayment,
  onlyNonDirect: isDirectPayment, // TODO #3374 @Victor
});

type UseOrderDataReturnType =
  | { loading: true; error: false; projects: null; subProjects: null }
  | { loading: false; error: true; projects: null; subProjects: null }
  | { loading: false; error: false; projects: RequiredByKeys<IProject, 'address'>[]; subProjects: ISubProject[] };

const useOrderData = (order: IOrderResponseDTO | null, form: FormContextApi<OrderEditForm>): UseOrderDataReturnType => {
  const { projectId: currentProjectId = order?.project?.id, isDirectPayment } = useOnChangeValues(form, [
    'projectId',
    'isDirectPayment',
    'subProjectId',
  ]);

  const projects = useAssociableProjectsWithoutPaginationQuery(getProjectGetterFilters(isDirectPayment ?? false));
  const subProjects = useSubProjectsByProjectQuery(currentProjectId ? +currentProjectId : undefined);

  const loading = subProjects.isLoading || projects.isLoading;
  const error = subProjects.isError || projects.isError;

  return { loading, error, projects: projects.data?.data, subProjects: subProjects.data } as any;
};

interface OrderInformationStepProps {
  order: IOrderResponseDTO | null;
  getCurrentTreeRef: MutableRefObject<OrderTree['getCurrentTree']>;
}

export const OrderInformationStep: FC<OrderInformationStepProps> = ({ order, getCurrentTreeRef }) => {
  const { mapNumberToAmount } = useCurrency();

  const hasAccessToSupplierInvoiceModule = usePermissions([PERMISSION.ACCESS_SUPPLIER_INVOICE]);

  const subProjectSelectionModal = useDisclosure();

  const defaultVatRate = useDefaultVatRate();

  const { form, initFormValues } = useStepForm<OrderEditWizard, 'information'>();

  const { state } = useLocation<{ projectId?: string; supplierInvoice?: ISupplierInvoice }>();
  const defaultProjectId = state?.projectId;

  const legalMentions = useCompanyLegalMentions();

  const { mutateAsync: subProjectsByProjectQuery } = useSubProjectsByProjectLazy();

  const { loading, error, subProjects, projects } = useOrderData(order, form);

  // Initial value
  useEffect(() => {
    const supplierInvoice = state?.supplierInvoice;

    const initialValue: Partial<OrderEditForm> = {
      orderDate: getCurrentDateAsString(),
      hasUnitPrices: true,
      vatType: VAT_TYPE.NORMAL,
      isPriceRequest: false,
      vatRate: formatNumberToVatRate(defaultVatRate ?? DEFAULT_VAT_RATE),
      note: order ? undefined : legalMentions.data.orderNotes,
    };

    if (defaultProjectId && !order) {
      Object.assign(initialValue, { projectId: +defaultProjectId });
    }

    if (supplierInvoice) {
      const { vatDistribution } = supplierInvoice;
      const { vatRate } = vatDistribution[0] || {};
      const onlyProject =
        (supplierInvoice.supplierInvoiceProjects || []).length === 1
          ? (supplierInvoice.supplierInvoiceProjects || [])[0].project
          : null;

      Object.assign(initialValue, {
        typeId: supplierInvoice?.type?.id,
        supplierId: supplierInvoice?.supplier?.id,
        amountExVAT: mapNumberToAmount(supplierInvoice.amountExVAT ?? 0),
        vatRate: formatNumberToVatRate(vatRate),
        associatedSupplierInvoices: state?.supplierInvoice ? [state?.supplierInvoice] : [],
        ...(onlyProject && {
          projectId: +onlyProject.id,
          address: onlyProject.address?.address,
          city: onlyProject.address?.city,
          country: onlyProject.address?.country,
          postalCode: onlyProject.address?.postalCode,
        }),
      });
    }

    // edit order in any context
    if (order) {
      const { deliveryAddress, vatDistribution } = order;
      const { vatRate } = vatDistribution[0] || {};

      const items = Object.values(order.orderItems).reduce<Partial<OrderEditForm>>((acc, orderItem) => {
        acc[getOrderItemFieldName(orderItem.id, 'code')] = orderItem.code;
        acc[getOrderItemFieldName(orderItem.id, 'description')] = orderItem.description;
        acc[getOrderItemFieldName(orderItem.id, 'unit')] = orderItem.unit;
        acc[getOrderItemFieldName(orderItem.id, 'quantity')] = orderItem.quantity;
        acc[getOrderItemFieldName(orderItem.id, 'unitPriceExVAT')] = isNumberFinite(orderItem.unitPriceExVAT)
          ? mapNumberToAmount(orderItem.unitPriceExVAT)
          : null;
        acc[getOrderItemFieldName(orderItem.id, 'vatRate')] = isNumberFinite(orderItem.vatRate)
          ? formatNumberToVatRate(orderItem.vatRate)
          : orderItem.vatRate;
        acc[getOrderItemFieldName(orderItem.id, 'typeId')] = orderItem.type?.id;

        return acc;
      }, {});

      Object.assign(initialValue, {
        name: order.name,
        orderNumber: order.orderNumber,
        orderDate: order.orderDate,
        deliveryDate: order.deliveryDate,
        note: order.note,
        subProjectId: order.subProject?.id ?? null,
        isDirectPayment: order.isDirectPayment,
        hasUnitPrices: order.hasUnitPrices,
        vatType: order.vatType,
        isPriceRequest: order.isPriceRequest,

        address: deliveryAddress?.address,
        city: deliveryAddress?.city,
        country: deliveryAddress?.country,
        postalCode: deliveryAddress?.postalCode,
        initialReceiptFiles: order.orderFiles?.map((f) => ({
          file: f.file,
          isDisplayInOrderPdf: f.isDisplayInOrderPdf,
        })),

        amountExVAT: isNil(order?.amountExVAT) ? null : mapNumberToAmount(order?.amountExVAT),
        vatRate: isNil(vatRate) ? null : formatNumberToVatRate(vatRate),
        vatDistribution: order.vatDistribution,
        comment: order.comment,
        deletedSupplierInvoices: [],
        typeId: order?.type?.id,
        supplierId: order.supplier.id,
        ...items,
        numberOfItems: Object.values(order.orderItems).length,
        associatedSupplierInvoices: state?.supplierInvoice
          ? [state?.supplierInvoice]
          : (order?.ordersSupplierInvoices || []).map(({ supplierInvoice: si }) => si),
        ...(order.project?.id && { projectId: order.project.id }),
      });
    }
    initFormValues(initialValue);
  }, [
    defaultProjectId,
    defaultVatRate,
    initFormValues,
    legalMentions.data.orderNotes,
    mapNumberToAmount,
    order,
    state?.supplierInvoice,
  ]);

  useEffect(() => {
    if (defaultProjectId) {
      const selectedProject = (projects ?? []).find((project) => project.id === +defaultProjectId);
      form.setFormValues({ projectAddress: selectedProject?.address });
    }
  }, [defaultProjectId, form, projects]);

  const initialTree = useMemo<InitialOrderTree>(
    () => mapOrderResponseDTOToInitialTree(order, { defaultVatRate }),
    [defaultVatRate, order],
  );
  const tree = useOrderTree(initialTree, form);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    getCurrentTreeRef.current = tree.getCurrentTree;
  }, [getCurrentTreeRef, tree]);

  // Direct payement
  const {
    projectId: currentProjectId = order?.project?.id,
    isDirectPayment,
    subProjectId,
  } = useOnChangeValues(form, ['projectId', 'isDirectPayment', 'subProjectId']);

  const onDirectPaymentSelection = useCallback(
    (isChecked: boolean) => {
      if (!subProjects) return;

      if (isChecked) {
        if (subProjects.length === 0) {
          form.setFormValues({ isDirectPayment: false });
        } else if (subProjects.length === 1) {
          form.setFormValues({ subProjectId: subProjects[0].id });
        } else if (subProjects.length > 1) {
          subProjectSelectionModal.onOpen();
        }
      } else {
        form.setFormValues({ subProjectId: null });
      }
    },
    [form, subProjectSelectionModal, subProjects],
  );

  const onProjectSelected = useCallback(
    async (projectId: number | undefined) => {
      if (!projectId) {
        form.setFormValues({ subProjectId: null, isDirectPayment: false });
        return;
      }
      const formValues = form.getFormValues();
      if (!formValues.isDirectPayment) return;

      const localSubProjects = await subProjectsByProjectQuery(projectId);

      if (localSubProjects.length === 0) {
        form.setFormValues({ isDirectPayment: false });
      } else if (localSubProjects.length === 1) {
        form.setFormValues({ subProjectId: localSubProjects[0].id });
      } else if (localSubProjects.length > 1) {
        subProjectSelectionModal.onOpen();
      }
    },
    [form, subProjectSelectionModal, subProjectsByProjectQuery],
  );

  if (error) return <Error />;

  // Used on the project card to switch the sub project when one is currently selected
  const canChangeSubProject = !!isDirectPayment && !!subProjects && subProjects.length > 1 && !!subProjectId;
  // Used on the supplier card
  const selectedProjectHasNoSubProject = !!currentProjectId && !!subProjects && subProjects.length === 0;

  const selectedSubProject = subProjects && subProjectId ? subProjects.find((sp) => sp.id === subProjectId) : undefined;

  return (
    <OrderDirectPaymentInfosProvider order={order}>
      <Form form={form}>
        <TwoColumns>
          <GridItem>
            <VStack spacing={6} align="stretch">
              <OrderInformationCard isEdit={!!order} />
              <OrderProjectCard
                loading={loading}
                projects={projects ?? []}
                selectedSubProject={selectedSubProject}
                canChangeSubProject={canChangeSubProject}
                onSubProjectChange={subProjectSelectionModal.onOpen}
                onProjectSelected={onProjectSelected}
              />
            </VStack>
          </GridItem>

          <GridItem>
            <VStack spacing={6} align="stretch">
              <OrderAmountCard tree={tree} />
              <OrderSupplierCard
                loading={loading}
                selectedProjectHasNoSubProject={selectedProjectHasNoSubProject}
                billingType={
                  selectedSubProject?.billingType ??
                  (subProjects && subProjects?.length === 1 ? subProjects[0].billingType : undefined)
                }
                onDirectPaymentSelection={onDirectPaymentSelection}
              />
              {hasAccessToSupplierInvoiceModule && <OrderEditAssociatedSupplierInvoiceCard order={order} />}
              <OrderCommentCard />
              <OrderFileReceiptFilesCard />
            </VStack>
          </GridItem>
        </TwoColumns>

        <OrderSubProjectSelectionModal
          subProjects={subProjects}
          isOpen={subProjectSelectionModal.isOpen}
          onClose={subProjectSelectionModal.onClose}
          defaultSelectedSubProjectId={subProjectId ?? undefined}
        />
      </Form>
    </OrderDirectPaymentInfosProvider>
  );
};
