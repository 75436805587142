import { useCallback } from 'react';
import type { AxiosError } from 'axios';
import type { Response } from '@graneet/lib-ui';

export enum HTTP_STATUS {
  FORBIDDEN = 403,
}

// TODO replace this any by history type when react router dom is upgraded to v6
export const redirectToForbiddenScreen = (history: any) => {
  history.replace('/forbidden');
};

export const isAnyErrorForbidden = (errors: Array<AxiosError | null>) =>
  errors.some((error) => error?.response?.status === HTTP_STATUS.FORBIDDEN);

/**
 * Custom hook to define a getter function used in `useData` custom hook
 * This one is pretty useful when the function needs an argument (only one)
 * @param getterFunc axios function used to fetch data
 * @param args (optional) values used as parameters of getterFunc
 *
 * Example of use
 const getData = useDataGetter(fetchBill, location.params.id);
 */
export const useDataGetter = <T extends Response<unknown> | Response<unknown>[], Args extends unknown[]>(
  getterFunc: (...args: Args) => Promise<T>,
  ...args: Args
): (() => Promise<T>) =>
  useCallback(
    (...p: Args) => (p.length > 0 ? getterFunc(...p) : getterFunc(...args)),
    // args is an array (so not a primitive type), do not add it to dependencies because it's create infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getterFunc, args?.join()],
  );

export const EMPTY_GETTER: Promise<Response<null>> = Promise.resolve([null, null]);
