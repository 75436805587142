import type { FC } from 'react';
import { useCallback } from 'react';
import type {
  IFinalStatementConditionsResponseDTO,
  IFinalStatementsVersionsDTO,
  IProject,
  ISubProject,
} from '@graneet/business-logic';
import { isProjectActive, needsFinalStatementToCloseProject, PERMISSION } from '@graneet/business-logic';
import { useDisclosure } from '@chakra-ui/react';
import { Button } from '@graneet/lib-ui';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FinalStatementCheckModal } from './modals/FinalStatementCheckModal';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

interface ProjectSummaryHeaderProps {
  project: IProject;

  subProjects: ISubProject[];

  finalStatements: IFinalStatementsVersionsDTO | null;

  finalStatementCheckDTO: IFinalStatementConditionsResponseDTO;
}

export const FinalStatementCreateButton: FC<ProjectSummaryHeaderProps> = ({
  project,
  subProjects,
  finalStatements,
  finalStatementCheckDTO,
}) => {
  const { t } = useTranslation(['progressStatement']);
  const history = useHistory();

  const finalStatementCheckModal = useDisclosure();

  const createStatementsButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_STATEMENTS]);

  const onFinalStatementCreateClicked = useCallback(() => {
    if (finalStatementCheckDTO.isFinalStatementAvailable) {
      history.push(`/projects/${project.id}/final-statement`);
    } else {
      finalStatementCheckModal.onOpen();
    }
  }, [finalStatementCheckDTO.isFinalStatementAvailable, finalStatementCheckModal, history, project.id]);

  const hasFinalStatement = finalStatements && !!finalStatements.current;

  const canCreateFinalStatement = needsFinalStatementToCloseProject(subProjects) && !hasFinalStatement;

  const canSeeStatementModule = usePermissions([PERMISSION.ACCESS_STATEMENTS]);

  if (!canCreateFinalStatement || !canSeeStatementModule) {
    return null;
  }

  return (
    <>
      <FinalStatementCheckModal
        onClose={finalStatementCheckModal.onClose}
        isOpen={finalStatementCheckModal.isOpen}
        finalStatementCheckDTO={finalStatementCheckDTO}
        projectName={project.name}
      />

      <Button
        variant="outline"
        onClick={onFinalStatementCreateClicked}
        {...createStatementsButtonDisabledProps(!isProjectActive(project))}
      >
        {t('progressStatement:actions.createFinalStatement')}
      </Button>
    </>
  );
};
