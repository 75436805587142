import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useForm, useFormContext, useOnChangeValues } from 'graneet-form';
import { DISCOUNT_TYPES } from '@graneet/business-logic';
import type { DrawerApi } from '@graneet/lib-ui';

import type { InvoiceEditionForm } from '../../forms/invoice-edition-form';
import { useInvoiceTreeContext } from '../../hooks/useInvoiceTree';
import { useContainerTotals } from '../../hooks/useContainerTotals';

import { DiscountEditDrawer } from 'features/discount/components/DiscountEditDrawer';
import type { DiscountEditionForm } from 'features/discount/forms/discount-edition-form';

interface InvoiceEditDiscountFormModalProps {
  drawer: DrawerApi<'discount'>;
}

export const InvoiceEditDiscountFormModal: FC<InvoiceEditDiscountFormModalProps> = ({ drawer }) => {
  const { t } = useTranslation(['discount', 'invoice', 'global']);

  const parentForm = useFormContext<InvoiceEditionForm>();
  const { setFormValues: setParentFormValues, getFormValues } = parentForm;

  const localForm = useForm<DiscountEditionForm>();
  const { getFormValues: getLocalFormValues, setFormValues: setLocalFormValues } = localForm;

  const invoiceValues = useOnChangeValues(parentForm, undefined);

  const tree = useInvoiceTreeContext();
  const { totalBeforeDiscountExVAT } = useContainerTotals(invoiceValues, tree);

  const discountValues = useOnChangeValues(parentForm, ['discountPercentage', 'discountAmountExVAT', 'discountType']);

  useEffect(() => {
    const { discountPercentage, discountAmountExVAT, discountType } = discountValues;

    setLocalFormValues({
      discountType: discountType || DISCOUNT_TYPES.PERCENTAGE,
      discountPercentage: discountPercentage || undefined,
      discountAmountExVAT: discountAmountExVAT || undefined,
    });
  }, [discountValues, getFormValues, setLocalFormValues]);

  const submitDiscount = useCallback(() => {
    const { discountPercentage, discountAmountExVAT, discountType } = getLocalFormValues();

    setParentFormValues({
      discountType,
      discountPercentage,
      discountAmountExVAT,
    });
    drawer.onClose();
  }, [getLocalFormValues, setParentFormValues, drawer]);

  return (
    <Form form={localForm}>
      <DiscountEditDrawer
        drawer={drawer}
        title={t('discount:title')}
        description={t('invoice:modals.addDiscount.description')}
        onSubmit={submitDiscount}
        totalBeforeDiscountExVAT={totalBeforeDiscountExVAT}
        submitLabel={t('global:words.c.validate')}
      />
    </Form>
  );
};
