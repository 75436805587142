import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Flex, Text } from '@chakra-ui/react';
import type { DrawerApi } from '@graneet/lib-ui';
import { Button, Callout, DrawersStack, useCurrency } from '@graneet/lib-ui';
import { useForm, useFormStatus, useFormContext, HiddenField, useHiddenField, Form } from 'graneet-form';
import { isNil } from 'lodash-es';
import type { IDiscount, Raw } from '@graneet/business-logic';
import { DISCOUNT_TYPES } from '@graneet/business-logic';

import type { UpdateStatus } from '../../contexts/ContractRuleContext';
import { UPDATE_STATUS_SUCCESS, useContractRuleContext } from '../../contexts/ContractRuleContext';
import type { ContractEditionForm } from '../../forms/contract-edition.form';

import { DiscountTotals } from 'features/discount/components/DiscountTotals';
import type { DiscountEditionForm } from 'features/discount/forms/discount-edition-form';

interface ContractDiscountDrawerProps {
  totalAmountWithoutDiscountExVAT: number;

  drawer: DrawerApi<string>;
}

export const ContractDiscountDrawer: FC<ContractDiscountDrawerProps> = ({
  totalAmountWithoutDiscountExVAT,
  drawer,
}) => {
  const { t } = useTranslation(['contracts']);
  const { mapAmountToNumber } = useCurrency();

  const globalForm = useFormContext<ContractEditionForm>();

  const { canDiscountBeUpdated } = useContractRuleContext();
  const [contractRuleResult, setContractRuleResult] = useState<UpdateStatus>(UPDATE_STATUS_SUCCESS);

  const { discountAmountExVAT: globalDiscountAmountExVAT } = globalForm.getFormValues();

  const discountForm = useForm<DiscountEditionForm>();
  const { isValid: isFormValid } = useFormStatus(discountForm);

  const discountTypeHiddenField = useHiddenField(discountForm, 'discountType');

  useEffect(() => {
    discountForm.setFormValues({
      discountType: DISCOUNT_TYPES.AMOUNT,
      discountAmountExVAT: !isNil(globalDiscountAmountExVAT) ? globalDiscountAmountExVAT : undefined,
    });
  }, [discountForm, globalDiscountAmountExVAT]);

  const onOverlayClick = useCallback(() => {
    discountForm.setFormValues({
      discountAmountExVAT: undefined,
    });

    drawer.onClose();
  }, [discountForm, drawer]);

  const onFieldBlur = useCallback(() => {
    const { discountAmountExVAT: rawAmountExVAT } = discountForm.getFormValues();
    const newDiscount = isNil(rawAmountExVAT)
      ? undefined
      : ({
          percentage: null,
          amountExVAT: mapAmountToNumber(rawAmountExVAT),
          type: DISCOUNT_TYPES.AMOUNT,
        } satisfies Raw<IDiscount>);
    const rule = canDiscountBeUpdated(newDiscount);
    setContractRuleResult(rule);
  }, [canDiscountBeUpdated, discountForm, mapAmountToNumber]);

  const onSubmit = useCallback(() => {
    const { discountAmountExVAT } = discountForm.getFormValues();

    globalForm.setFormValues({
      hasDiscountBeenUpdated: true,
      discountAmountExVAT,
    });

    drawer.onClose();
  }, [discountForm, drawer, globalForm]);

  return (
    <DrawersStack.Drawer
      title={t('contracts:discounts.drawers.discount.title')}
      drawer={drawer}
      onOverlayClick={onOverlayClick}
      footer={
        <Flex justify="flex-end" w="100vw">
          <Button onClick={onSubmit} isDisabled={!isFormValid || !contractRuleResult.canUpdate}>
            {t('contracts:discounts.drawers.discount.applyDiscount')}
          </Button>
        </Flex>
      }
    >
      <Form form={discountForm}>
        <Text>{t('contracts:discounts.drawers.discount.description')}</Text>

        <HiddenField {...discountTypeHiddenField} />

        <Stack mt={8} spacing={4}>
          {!contractRuleResult.canUpdate && <Callout colorScheme="red">{contractRuleResult.errorMessage}</Callout>}
          <DiscountTotals totalBeforeDiscountExVAT={totalAmountWithoutDiscountExVAT} onFieldBlur={onFieldBlur} />
        </Stack>
      </Form>
    </DrawersStack.Drawer>
  );
};
