import type {
  ISupplierInvoiceOCRResponse,
  ISupplierInvoiceCreateDTO,
  ICreateSupplierInvoicePaymentDTO,
  ISupplierInvoiceUpdateDTO,
  IUpdateSupplierInvoicePaymentDTO,
  IUpdateSupplierInvoiceStatusDTO,
  ISupplierInvoiceUpdateStatusesDTO,
  ISupplierInvoiceUpdateStatusesToPaidDTO,
  SupplierInvoicePaginatedResponse,
  SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT,
  ISupplierInvoiceResponseDTO,
  IUpdateTagsDTO,
  SupplierInvoiceBatchImportResponse,
  ISupplierInvoiceUpdateStatusesToPayResponseDTO,
  ISupplierInvoiceBatchImportDTO,
  ISupplierInvoiceUpdateBatchDTO,
} from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import type { PaginationQuery, UsePaginationOptions } from '@graneet/lib-ui';
import { usePaginationQuery, useToast } from '@graneet/lib-ui';
import { type QueryClient, useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { dtoToFormData } from 'features/api/services/api.util';
import { apiNew } from 'features/api/services/apiNew.service';

const FACTORY_NAME = 'supplier-invoice';
const SUPPLIER_INVOICES_PATH = '/supplier-invoices';

export async function invalidateSupplierInvoiceQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [FACTORY_NAME],
  });
}

const supplierInvoiceKeyFactory = createQueryKeys(FACTORY_NAME, {
  get: (queryParams: URLSearchParams) => ({
    queryKey: [SUPPLIER_INVOICES_PATH, queryParams],
    queryFn: () => apiNew.get<URLSearchParams, SupplierInvoicePaginatedResponse>(SUPPLIER_INVOICES_PATH, queryParams),
  }),
  getDirectPaymentAllowedSupplierInvoice: (queryParams: URLSearchParams) => ({
    queryKey: [SUPPLIER_INVOICES_PATH, queryParams],
    queryFn: () =>
      apiNew.get<URLSearchParams, SupplierInvoicePaginatedResponse>(
        `${SUPPLIER_INVOICES_PATH}/direct-payment-allowed`,
        queryParams,
      ),
  }),
  getOne: (id: number) => ({
    queryKey: [SUPPLIER_INVOICES_PATH, id],
    queryFn: () =>
      apiNew.get<never, SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT>(`${SUPPLIER_INVOICES_PATH}/${id}`),
  }),
  getOneOrUndefined: (id?: number) => ({
    queryKey: [SUPPLIER_INVOICES_PATH, id],
    queryFn: () => {
      if (!id) {
        return Promise.resolve(null);
      }
      return apiNew.get<never, SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT>(`${SUPPLIER_INVOICES_PATH}/${id}`);
    },
  }),
  getAvailableTags: () => ({
    queryKey: [SUPPLIER_INVOICES_PATH],
    queryFn: () => apiNew.get<never, string[]>(`${SUPPLIER_INVOICES_PATH}/tags`),
  }),
});

// -- Query
export function useSupplierInvoices(options?: UsePaginationOptions): PaginationQuery<SupplierInvoicePaginatedResponse> {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) => queryClient.fetchQuery(supplierInvoiceKeyFactory.get(urlSearchParams)),
    options,
  );
}

export function useSupplierInvoicesQuery(urlSearchParams: URLSearchParams, options?: { enabled?: boolean }) {
  return useQuery({ ...supplierInvoiceKeyFactory.get(urlSearchParams), ...options });
}

export function useSupplierInvoice(id: number) {
  return useSuspenseQuery(supplierInvoiceKeyFactory.getOne(id));
}

export function useSupplierInvoiceOrUndefined(id: number | undefined) {
  return useSuspenseQuery(supplierInvoiceKeyFactory.getOneOrUndefined(id));
}

export function useSupplierInvoiceAvailableTags() {
  return useSuspenseQuery(supplierInvoiceKeyFactory.getAvailableTags());
}

export function useSupplierInvoicesDirectPaymentAllowed(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) =>
      queryClient.fetchQuery(supplierInvoiceKeyFactory.getDirectPaymentAllowedSupplierInvoice(urlSearchParams)),
    options,
  );
}

// -- Mutation
export function useSupplierInvoiceUpdateTags() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateTagsDTO }) =>
      apiNew.patch<IUpdateTagsDTO, ISupplierInvoiceResponseDTO>(
        `${SUPPLIER_INVOICES_PATH}/${params.id}/tags`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:changeTags.error'));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('global:changeTags.success'));
    },
  });
}

export function useSupplierInvoiceProgressOCRLazy() {
  const { t } = useTranslation(['supplierInvoices']);
  const toast = useToast();

  return useMutation({
    mutationFn: (dto: { invoiceFile: File }) =>
      apiNew.postWithMultiPart<FormData, ISupplierInvoiceOCRResponse>(
        `${SUPPLIER_INVOICES_PATH}/ocr`,
        dtoToFormData(dto),
      ),
    onError: () => {
      toast.error(t('supplierInvoices:errors.ocrError'));
    },
  });
}

export function useSupplierInvoiceCreate() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { dto: ISupplierInvoiceCreateDTO; invoiceFile?: File; receiptFiles?: File[] }) => {
      const formData = dtoToFormData({ ...params.dto, invoiceFile: params.invoiceFile });

      params.receiptFiles?.forEach((file) => {
        formData.append('receiptFiles', file);
      });

      return apiNew.postWithMultiPart<FormData, ISupplierInvoiceResponseDTO>(SUPPLIER_INVOICES_PATH, formData);
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('global:words.c.success'), t('supplierInvoices:toasts.created'));
    },
  });
}

export function useSupplierInvoiceUpdate() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: ISupplierInvoiceUpdateDTO; invoiceFile?: File; receiptFiles?: File[] }) => {
      const formData = dtoToFormData({
        ...params.dto,
        invoiceFile: params.invoiceFile,
        deleteInvoiceFile: params.invoiceFile ? 'true' : params.dto.deleteInvoiceFile || 'false',
        // Since we use form data null is passed as string and validator can not be used we use default nullable uuid
        accountingConfigPurchasesAccountId:
          params.dto.accountingConfigPurchasesAccountId === null
            ? '00000000-0000-0000-0000-000000000000'
            : params.dto.accountingConfigPurchasesAccountId,
      });

      if (params.receiptFiles) {
        params.receiptFiles.forEach((receiptFile) => {
          formData.append('receiptFiles', receiptFile);
        });
      }

      formData.set('deletedReceiptFilesIds', params.dto.deletedReceiptFilesIds || '[]');

      return apiNew.patchWithMultiPart<FormData, ISupplierInvoiceResponseDTO>(
        `${SUPPLIER_INVOICES_PATH}/${params.id}`,
        formData,
      );
    },
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('global:words.c.success'), t('supplierInvoices:toasts.updated'));
    },
  });
}

export function useSupplierInvoiceUpdateNote() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: { note: string } }) =>
      apiNew.patch<{ note: string }, ISupplierInvoiceResponseDTO>(
        `${SUPPLIER_INVOICES_PATH}/${params.id}/note`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:words.c.error'));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('supplierInvoices:toasts.updated'));
    },
  });
}

export function useSupplierInvoiceUpdateStatus() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateSupplierInvoiceStatusDTO }) =>
      apiNew.patch<IUpdateSupplierInvoiceStatusDTO, void>(`${SUPPLIER_INVOICES_PATH}/${params.id}/status`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('supplierInvoices:toasts.statusUpdate'));
    },
  });
}

export function useSupplierInvoiceDelete() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, void>(`${SUPPLIER_INVOICES_PATH}/${id}`),
    onError: () => {
      toast.error(t('supplierInvoices:cards.delete.error'));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('supplierInvoices:cards.delete.success'));
    },
  });
}

export function useSupplierInvoiceAddPayment() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { supplierInvoiceId: number; dto: ICreateSupplierInvoicePaymentDTO }) =>
      apiNew.post<ICreateSupplierInvoicePaymentDTO, void>(
        `${SUPPLIER_INVOICES_PATH}/${params.supplierInvoiceId}/payments`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('supplierInvoices:toasts.paymentAdd'));
    },
  });
}

export function useSupplierInvoiceUpdatePayment() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateSupplierInvoicePaymentDTO }) =>
      apiNew.patch<ICreateSupplierInvoicePaymentDTO, void>(
        `${SUPPLIER_INVOICES_PATH}/payments/${params.id}`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('supplierInvoices:toasts.paymentAdd'));
    },
  });
}

export function useSupplierInvoiceDeletePayment() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, void>(`${SUPPLIER_INVOICES_PATH}/payments/${id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('supplierInvoices:toasts.paymentDelete'));
    },
  });
}

export function useSupplierInvoiceUpdateStatusesToPay() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ISupplierInvoiceUpdateStatusesDTO) =>
      apiNew.patch<ISupplierInvoiceUpdateStatusesDTO, ISupplierInvoiceUpdateStatusesToPayResponseDTO>(
        `${SUPPLIER_INVOICES_PATH}/status/to-pay`,
        dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
    },
  });
}

export function useSupplierInvoiceUpdateStatusesToProcess() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ISupplierInvoiceUpdateStatusesDTO) =>
      apiNew.patch<ISupplierInvoiceUpdateStatusesDTO, void>(`${SUPPLIER_INVOICES_PATH}/status/to-process`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
    },
  });
}

export function useSupplierInvoiceUpdateStatusesToPaid() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ISupplierInvoiceUpdateStatusesToPaidDTO) =>
      apiNew.patch<ISupplierInvoiceUpdateStatusesToPaidDTO, ISupplierInvoiceUpdateStatusesToPayResponseDTO>(
        `${SUPPLIER_INVOICES_PATH}/status/paid`,
        dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
    },
  });
}

export function useSupplierInvoiceUpdateBatch() {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ISupplierInvoiceUpdateBatchDTO) =>
      apiNew.patch<ISupplierInvoiceUpdateBatchDTO, void>(`${SUPPLIER_INVOICES_PATH}/batch`, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, { selectedItems }) => {
      await invalidateSupplierInvoiceQueries(queryClient);
      toast.success(t('supplierInvoices:toasts.updated', { count: selectedItems?.length }));
    },
  });
}

export function useSupplierInvoiceImportBatch() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { dto: ISupplierInvoiceBatchImportDTO; files: File[] }) => {
      const formData = dtoToFormData(params.dto);

      params.files.forEach((file) => {
        formData.append('files', file);
      });

      return apiNew.postWithMultiPart<FormData, SupplierInvoiceBatchImportResponse>(
        `${SUPPLIER_INVOICES_PATH}/batch`,
        formData,
      );
    },
    onSuccess: async () => {
      await invalidateSupplierInvoiceQueries(queryClient);
    },
  });
}
