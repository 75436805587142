import { HStack, useDisclosure, VStack, Text, CircularProgress } from '@chakra-ui/react';
import type { ITimeSlotExportDTO } from '@graneet/business-logic';
import {
  Button,
  Callout,
  Modal,
  DateRangeField,
  IconAdvanced,
  formatDateToString,
  SimpleHelpIcon,
  SimpleDownloadIcon,
  SimpleAlertIcon,
  SimpleCheckCircleIcon,
} from '@graneet/lib-ui';
import { Form, useForm, useOnChangeValues } from 'graneet-form';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { generateTimeSlotExport } from '../services/time-slot.api';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { downloadFile } from 'features/file/services/file.util';
import { Rule } from 'features/form/rules/Rule';

interface FormValues {
  exportTimeTracking?: {
    startDate: Date;
    endDate: Date;
  };
}

export const TimeTrackingExportButton = () => {
  const { t } = useTranslation(['timeTracking', 'global']);

  const [currentScreen, setCurrentScreen] = useState<'selection' | 'generation'>('selection');

  const [isGenerating, setIsGenerating] = useState(true);
  const [isError, setIsError] = useState(false);

  const modal = useDisclosure({
    onClose: () => {
      setCurrentScreen('selection');
      setIsGenerating(true);
      setIsError(false);
    },
  });

  const form = useForm<FormValues>();
  const { resetForm } = form;

  const onCloseClickedAfterGeneration = useCallback(() => {
    if (!isError) {
      resetForm();
    }
    modal.onClose();
  }, [isError, modal, resetForm]);

  const modalTitle =
    currentScreen === 'selection'
      ? t('timeTracking:exportModal.titleSelection')
      : t('timeTracking:exportModal.titleGeneration');

  const formValues = useOnChangeValues(form, undefined);
  const hasSelectedValues = !!Object.values(formValues).find((v) => v);

  const onGenerateClicked = useCallback(async () => {
    setIsGenerating(true);
    setCurrentScreen('generation');

    const exportTimeSlotData: ITimeSlotExportDTO = {
      startDate: formatDateToString(formValues.exportTimeTracking!.startDate)!,
      endDate: formatDateToString(formValues.exportTimeTracking!.endDate)!,
    };

    const [err, res] = await generateTimeSlotExport(exportTimeSlotData);

    if (err) {
      setIsError(true);
      setIsGenerating(false);
      return;
    }

    downloadFile(res.url);
    setIsGenerating(false);
  }, [formValues]);

  const onCloseModal = useCallback(() => {
    if (currentScreen === 'selection') {
      modal.onClose();
      return;
    }

    // Do not allow modal closing when export is generating
    if (isGenerating) {
      return;
    }

    onCloseClickedAfterGeneration();
  }, [currentScreen, isGenerating, modal, onCloseClickedAfterGeneration]);

  return (
    <>
      <Button leftIcon={<SimpleDownloadIcon />} variant="outline" onClick={modal.onOpen} mr="auto" ml={5}>
        {t('timeTracking:actions.export')}
      </Button>

      <Modal onClose={onCloseModal} isOpen={modal.isOpen} title={modalTitle}>
        {currentScreen === 'selection' && (
          <Form form={form}>
            <Text fontSize="lg">{t('timeTracking:exportModal.actions.onlyApproved')}</Text>
            <HStack spacing={2} mb={2} mt={2}>
              <Text>{t('timeTracking:exportModal.actions.dates')}</Text>
              <IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('timeTracking:exportModal.actions.tooltip')} />
            </HStack>
            <DateRangeField<FormValues>
              mb={0}
              name="exportTimeTracking"
              label={t('timeTracking:exportModal.actions.date')}
            >
              <Rule.IsValidDateRange />
            </DateRangeField>

            <Modal.Close />

            <Modal.PrimaryButton onClick={onGenerateClicked} isDisabled={!hasSelectedValues}>
              {t('global:generateExportModal.actions.export')}
            </Modal.PrimaryButton>
          </Form>
        )}

        {currentScreen === 'generation' && (
          <>
            {isGenerating ? (
              <VStack spacing={6}>
                <CircularProgress size={12} color="gray.800" isIndeterminate />
                <Text textAlign="center">{t('global:generateExportModal.pending')}</Text>
              </VStack>
            ) : (
              <Callout
                colorScheme={isError ? 'red' : 'green'}
                icon={
                  isError ? (
                    <SimpleAlertIcon stroke="red.500" boxSize={5} />
                  ) : (
                    <SimpleCheckCircleIcon stroke="green.500" boxSize={5} />
                  )
                }
              >
                {isError
                  ? t('global:generateExportModal.error', { email: SUPPORT_EMAIL })
                  : t('global:generateExportModal.success')}
              </Callout>
            )}

            <Modal.PrimaryButton isDisabled={isGenerating} onClick={onCloseClickedAfterGeneration}>
              {t('global:words.c.close')}
            </Modal.PrimaryButton>
          </>
        )}
      </Modal>
    </>
  );
};
