import { Text, HStack, Switch, Stack, Collapse, FormLabel } from '@chakra-ui/react';
import type { FieldValues } from 'graneet-form';
import { mapValidationStatusesToOutcome, useFormContext, useValidations, VALIDATION_OUTCOME } from 'graneet-form';
import type { ReactNode, Ref } from 'react';
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { Card } from '../../Card/Card';
import type { TestableElement } from '../../../types/TestableElement';
import { SimpleAlertIcon, SimpleCheckIcon } from '../../Icons';

export interface FieldsetRef {
  handleIsChecked: () => void;
}
export interface FieldsetProps<T extends FieldValues = Record<string, unknown>> extends TestableElement {
  handle: string;

  variant?: 'default' | 'switch';

  title: ReactNode;

  legend?: ReactNode;

  children: ReactNode;

  validationNames: (keyof T)[];

  noCard?: boolean;

  defaultValue?: boolean;

  isReadOnly?: boolean;

  isDisabled?: boolean;

  innerRef?: Ref<FieldsetRef>;

  onOpen?: () => void;

  onClose?: () => void;
}

export const Fieldset = <T extends FieldValues = Record<string, unknown>>({
  handle,
  variant = 'default',
  title,
  legend,
  validationNames,
  isReadOnly = false,
  isDisabled = false,
  noCard = false,
  children,
  'data-testid': testId,
  innerRef,
  defaultValue,
  onOpen,
  onClose,
}: FieldsetProps<T>) => {
  const form = useFormContext<T>();
  const validations = useValidations(form, validationNames);

  const isValid = useMemo(
    () => mapValidationStatusesToOutcome(validations) === VALIDATION_OUTCOME.VALID,
    [validations],
  );

  const [isChecked, setIsChecked] = useState(defaultValue ?? false);

  const prevIsChecked = useRef<boolean>(isChecked);

  useEffect(() => {
    if (isChecked !== prevIsChecked.current && isChecked) {
      onOpen?.();
    }

    if (isChecked !== prevIsChecked.current && !isChecked) {
      onClose?.();
    }

    prevIsChecked.current = isChecked;
  }, [isChecked, onOpen, onClose]);

  const handleIsChecked = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  useImperativeHandle(
    innerRef,
    () => ({
      handleIsChecked,
    }),
    [handleIsChecked],
  );

  return (
    <Stack data-testid={testId} direction="column" alignItems="flex-start" w="100%">
      <HStack alignItems="baseline">
        {variant === 'default' && isValid && <SimpleCheckIcon stroke="green.500" />}
        {variant === 'default' && !isValid && <SimpleAlertIcon stroke="red.500" />}
        {variant === 'switch' && (
          <Switch
            isReadOnly={isReadOnly}
            isDisabled={isDisabled}
            id={handle}
            colorScheme="blue"
            isChecked={isChecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
            }}
          />
        )}
        <FormLabel
          htmlFor={handle}
          fontSize={22}
          fontWeight={600}
          cursor={variant === 'switch' ? 'pointer' : 'default'}
        >
          {title}
        </FormLabel>
      </HStack>
      <Stack direction="column" alignItems="stretch" w="100%">
        <Collapse in={variant === 'switch' ? isChecked : true} animateOpacity unmountOnExit>
          <Text mb={3}>{legend}</Text>
          {noCard ? children : <Card alignSelf="stretch">{children}</Card>}
        </Collapse>
      </Stack>
    </Stack>
  );
};
