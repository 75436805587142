import type { EmailTemplateType } from '@graneet/business-logic';
import type { FC } from 'react';
import { useEffect, useCallback, useState } from 'react';
import {
  CheckboxField,
  Modal,
  RICH_TEXT_BLOCK_TOOLBAR_PRESET,
  RichTextContextProvider,
  RichTextField,
  TextField,
  useRichText,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { Box, Divider, Flex, Stack } from '@chakra-ui/react';
import type { RichTextConfiguration } from '@graneet/lib-ui';
import type { TFunction } from 'i18next';

import { getRichTextPluginOption } from '../services/email-template.util';

import { EmailTemplateTypeBadge } from './EmailTemplateTypeBadge';
import { EmailVariableRichSelect } from './EmailVariableRichSelect';

import { Rule } from 'features/form/rules/Rule';

export interface EmailTemplateEditModalForm {
  title: string;

  subject: string;

  template: string;

  isDefault: boolean;
}

const SUBJECT_RICH_TEXT_CONFIGURATION = (
  type: EmailTemplateType,
  t: TFunction<['global', 'mailing']>,
): RichTextConfiguration => [getRichTextPluginOption(type, t), { name: 'max-length', maxLength: 1 }];
const TEMPLATE_RICH_TEXT_CONFIGURATION = (
  type: EmailTemplateType,
  t: TFunction<['global', 'mailing']>,
): RichTextConfiguration => [...RICH_TEXT_BLOCK_TOOLBAR_PRESET, getRichTextPluginOption(type, t)];

interface EmailTemplateEditModalProps {
  type: EmailTemplateType;

  isOpen: boolean;

  onClose(): void;

  onSubmit(
    formValues: EmailTemplateEditModalForm,
    type: EmailTemplateType,
    html: { subject: string; template: string },
  ): Promise<void>;

  initialValues?: EmailTemplateEditModalForm;
}

export const EmailTemplateEditModal: FC<EmailTemplateEditModalProps> = ({
  type,
  isOpen,
  onClose,
  onSubmit,
  initialValues,
}) => {
  const { t } = useTranslation(['global', 'mailing']);

  const form = useForm<EmailTemplateEditModalForm>();
  const { isValid } = useFormStatus(form);

  const subjectRichTextContext = useRichText();
  const templateRichTextContext = useRichText();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    const values = form.getFormValues();
    await onSubmit(
      {
        title: values.title!,
        subject: values.subject!,
        template: values.template!,
        isDefault: values.isDefault!,
      },
      type,
      {
        subject: await subjectRichTextContext.exportHtml(),
        template: await templateRichTextContext.exportHtml(),
      },
    );
    setIsLoading(false);
  }, [form, onSubmit, subjectRichTextContext, templateRichTextContext, type]);

  useEffect(() => {
    if (!isOpen) {
      form.resetForm();
      return;
    }

    form.setFormValues({
      title: initialValues?.title,
      subject: initialValues?.subject,
      template: initialValues?.template,
      isDefault: initialValues?.isDefault || false,
    });
  }, [form, initialValues?.isDefault, initialValues?.subject, initialValues?.template, initialValues?.title, isOpen]);

  return (
    <Form form={form}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="3xl"
        title={
          <Flex gap={3} mr={10} alignItems="center">
            <TextField<EmailTemplateEditModalForm>
              hideErrorMessage
              name="title"
              placeholder={t(`mailing:placeholders.title.${type}`)}
              inputProps={{
                fontSize: 'sm',
                fontWeight: 500,
              }}
            >
              <Rule.IsRequired />
            </TextField>
            <EmailTemplateTypeBadge type={type} />
          </Flex>
        }
        footerContent={
          <Flex>
            <CheckboxField<EmailTemplateEditModalForm>
              name="isDefault"
              label={t(`mailing:placeholders.isDefault.${type}`)}
            />
          </Flex>
        }
      >
        <Modal.Close />

        <Stack mt={2}>
          <RichTextContextProvider value={subjectRichTextContext}>
            <RichTextField<EmailTemplateEditModalForm>
              name="subject"
              placeholder={t('mailing:placeholders.subject')}
              navbarType="none"
              configuration={SUBJECT_RICH_TEXT_CONFIGURATION(type, t)}
              hideErrorMessage
              // Remove rich text borders
              inputProps={{
                fontWeight: 500,
                fontSize: 'sm',
              }}
              toolbarContent={[
                <EmailVariableRichSelect key={EmailVariableRichSelect.name} type={type} />,
                <Rule.IsRichTextVariablesValid key={Rule.IsRichTextVariablesValid.name} />,
              ]}
            >
              <Rule.IsRequired />
            </RichTextField>
          </RichTextContextProvider>

          <Box mt={3}>
            <RichTextContextProvider value={templateRichTextContext}>
              <RichTextField<EmailTemplateEditModalForm>
                noBorder
                name="template"
                placeholder={t('mailing:placeholders.template')}
                navbarType="block"
                configuration={TEMPLATE_RICH_TEXT_CONFIGURATION(type, t)}
                hideErrorMessage
                // Remove rich text borders
                inputProps={{
                  minH: '10rem',
                  fontWeight: 500,
                  fontSize: 'sm',
                }}
                toolbarContent={[
                  <EmailVariableRichSelect key={EmailVariableRichSelect.name} type={type} hasTrackingId />,
                  <Rule.IsRichTextVariablesValid key={Rule.IsRichTextVariablesValid.name} />,
                ]}
              >
                <Rule.IsRequired />
              </RichTextField>
            </RichTextContextProvider>
          </Box>

          <Divider mt={3} />

          <Modal.PrimaryButton isDisabled={!isValid} isLoading={isLoading} onClick={handleSubmit}>
            {t('mailing:emailTemplateEditModal.cta')}
          </Modal.PrimaryButton>
        </Stack>
      </Modal>
    </Form>
  );
};
