import { Flex, HStack, VStack, Text, Button } from '@chakra-ui/react';
import { useMemo, type FC } from 'react';

import { SimpleQuoteIcon, SimpleUserIcon, SimpleEuroIcon, SimpleEquipmentHookIcon } from '../../../Icons/v2';

type Action = {
  label: string;
  onClick: () => void;
};

type HeaderProps = {
  type: 'client' | 'quote' | 'project' | 'sell';
  title?: string;
  subtitle?: string | null;
  actions?: Action[];
  isActive?: boolean;
};

const COLORS_CONFIG = {
  black: {
    background: 'linear-gradient(180deg, rgba(31, 42, 55, 0.95) 0%, rgba(14, 13, 28, 0.95) 100%)',
    shadow: '0px 0px 0px 2px rgba(13, 18, 28, 0.10), 0px 0px 0px 1px #0E0D1C inset',
  },
  purple: {
    background: 'linear-gradient(180deg, #7F56D9 0%, #6941C6 100%)',
    shadow: '0px 0px 0px 2px rgba(13, 18, 28, 0.10), 0px 0px 0px 1px rgba(14, 13, 28, 0.40) inset',
  },
  inactive: {
    background: '#fff',
    shadow:
      '0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.12)',
  },
} as const;

const ActionLink: FC<Action> = ({ label, onClick }) => (
  <Button
    color="1F2A37"
    background="rgba(0, 21, 63, 0.05)"
    fontSize="0.813rem"
    fontWeight="500"
    px="0.5rem"
    py="0.25rem"
    height="auto"
    minWidth="auto"
    borderRadius="0.375rem"
    _hover={{
      background: 'rgba(0, 21, 63, 0.1)',
    }}
    onClick={onClick}
  >
    {label}
  </Button>
);

export const SellSheetHeader: FC<HeaderProps> = ({ type, title, subtitle, actions, isActive }) => {
  const { background, shadow } = isActive
    ? COLORS_CONFIG[type === 'project' ? 'purple' : 'black']
    : COLORS_CONFIG.inactive;

  const iconColor = useMemo(() => (isActive ? '#fff' : '#6C737F'), [isActive]);

  const icon = useMemo(() => {
    switch (type) {
      case 'client':
        return <SimpleUserIcon color={iconColor} />;
      case 'quote':
        return <SimpleQuoteIcon color={iconColor} />;
      case 'project':
        return <SimpleEquipmentHookIcon color={iconColor} />;
      case 'sell':
        return <SimpleEuroIcon color={iconColor} />;
      default:
        return null;
    }
  }, [type, iconColor]);

  return (
    <VStack width="100%" spacing="0.125rem" alignItems="flex-start">
      <HStack width="100%" justifyContent="space-between" alignItems="center" mb="0.625rem">
        <Flex
          alignItems="center"
          justifyContent="center"
          background={background}
          boxShadow={shadow}
          borderRadius="0.5rem"
          boxSize="2.25rem"
          border="0.125rem solid #FFF"
        >
          {icon}
        </Flex>
        {actions && actions.length > 0 && (
          <HStack gap="0.5rem">
            {actions.map((action, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ActionLink {...action} key={`action-${i}`} />
            ))}
          </HStack>
        )}
      </HStack>
      {title && (
        <Text color="#1F2A37" fontSize="1.063rem">
          {title}
        </Text>
      )}
      {subtitle && (
        <Text fontSize="0.75rem" color="#6C737F" fontWeight={400}>
          {subtitle}
        </Text>
      )}
    </VStack>
  );
};
