import { Box, Flex } from '@chakra-ui/react';
import { Form, useForm } from 'graneet-form';
import { Button, type TableSelectBannerChildrenProps } from '@graneet/lib-ui';
import type { FC, Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import type { ISupplier } from '@graneet/business-logic';
import { FEATURE_FLAGS, FILTERING_PARAMS } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import qs from 'qs';

import { useSupplierInvoiceUpdateBatch } from '../../services/supplier-invoice.api';

import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { useSuppliers } from 'features/supplier/services/supplier.api';
import { AccountingConfigPurchasesAccountField } from 'features/accounting/components/AccountingConfigPurchasesAccountField';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import type { SelectedItems } from 'features/export/components/ExportButton/ExportButton';
import { ProjectAssociableField } from 'features/project/components/ProjectAssociableField';
import { SingleSelectFieldPaginated } from 'features/common/components/SingleSelectFieldPaginated';

interface BatchSupplierInvoiceForm {
  typeId?: number;
  accountingConfigPurchasesAccountId?: string;
  supplierId?: number;
  associatedProjectId?: number;
}

interface SupplierInvoiceBatchUpdateProps extends TableSelectBannerChildrenProps<SelectedItems['SUPPLIER_INVOICE']> {
  onSupplierInvoicesUpdate(): Promise<void>;
  setIsUpdateBatchMode: Dispatch<SetStateAction<boolean>>;
}

export const SupplierInvoiceBatchUpdate: FC<SupplierInvoiceBatchUpdateProps> = ({
  selectedItems,
  resetSelectedCheckboxes,
  hasAllCheckboxesSelected,
  currentFilters,
  onSupplierInvoicesUpdate,
  setIsUpdateBatchMode,
}) => {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const form = useForm<BatchSupplierInvoiceForm>();
  const hasAccountingBreakdownPurchasesAccountFF = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_PURCHASES_ACCOUNT);

  const supplierInvoiceUpdateBatchMutation = useSupplierInvoiceUpdateBatch();

  const handleSubmit = useCallback(
    async ({
      typeId,
      accountingConfigPurchasesAccountId,
      supplierId,
      associatedProjectId,
    }: BatchSupplierInvoiceForm) => {
      await supplierInvoiceUpdateBatchMutation.mutateAsync({
        filters: qs.parse(currentFilters.toString()),
        selectedItems: selectedItems.map((item) => ({ id: item.id })),
        hasAllSelected: hasAllCheckboxesSelected,
        search: currentFilters.get(FILTERING_PARAMS.SEARCH) || undefined,
        typeId,
        accountingConfigPurchasesAccountId,
        supplierId,
        associatedProjectId,
      });

      resetSelectedCheckboxes?.();
      await onSupplierInvoicesUpdate();
    },
    [
      currentFilters,
      hasAllCheckboxesSelected,
      onSupplierInvoicesUpdate,
      resetSelectedCheckboxes,
      selectedItems,
      supplierInvoiceUpdateBatchMutation,
    ],
  );

  const suppliers = useSuppliers({ storageStrategy: 'state' });

  const mapFunction = useCallback(
    (supplier: ISupplier) => ({
      label: supplier.name,
      value: supplier.id,
    }),
    [],
  );

  return (
    <Form form={form} onSubmit={form.handleSubmit(handleSubmit)}>
      <Flex gap={2}>
        <Box w="13rem">
          <ComponentTypeField<BatchSupplierInvoiceForm>
            name="typeId"
            material
            placeholder={t('supplierInvoices:fields.disbursementType')}
            menuPlacement="top"
          />
        </Box>
        <Box width="13rem">
          <SingleSelectFieldPaginated<ISupplier, BatchSupplierInvoiceForm>
            name="supplierId"
            pagination={suppliers}
            mapFunction={mapFunction}
            placeholder={t('supplierInvoices:fields.supplier')}
            isSearchable
            menuPlacement="top"
          />
        </Box>
        <Box width="13rem">
          <ProjectAssociableField<BatchSupplierInvoiceForm>
            name="associatedProjectId"
            placeholder={t('supplierInvoices:fields.itemProject')}
            displayFull={false}
            isSearchable
            menuPlacement="top"
          />
        </Box>
        {hasAccountingBreakdownPurchasesAccountFF && (
          <Box width="13rem">
            <AccountingConfigPurchasesAccountField<BatchSupplierInvoiceForm>
              name="accountingConfigPurchasesAccountId"
              placeholder={t('supplierInvoices:fields.accountingConfigPurchasesAccount')}
              menuPlacement="top"
            />
          </Box>
        )}
        <Button
          type="submit"
          fontSize={14}
          minW="fit-content"
          h="auto"
          isLoading={supplierInvoiceUpdateBatchMutation.isPending}
        >
          {t('global:words.c.update')}
        </Button>
        <Button onClick={() => setIsUpdateBatchMode(false)} variant="outline" height="auto" minW="fit-content">
          {t('global:words.c.cancel')}
        </Button>
      </Flex>
    </Form>
  );
};
