import type { FC } from 'react';
import { useEffect } from 'react';
import { Fieldset } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICompany, IPaymentMethodResponseDTO } from '@graneet/business-logic';

import type { ProjectCreateForm } from '../../forms/project-create-wizard';

import { PaymentMethodSection } from 'features/payment-method/components/PaymentMethodSection';
import { useInitialPaymentConditions } from 'features/payment-method/hooks/useInitialPaymentConditions';

interface ProjectPaymentTermsFieldsetProps {
  initFormValues(initialValues: Partial<ProjectCreateForm>): void;
  companyPaymentTerms: Pick<ICompany, 'paymentTerm' | 'deadlineType'> | null | undefined;
  paymentMethods: IPaymentMethodResponseDTO[];
}

export const ProjectPaymentTermsFieldset: FC<ProjectPaymentTermsFieldsetProps> = ({
  initFormValues,
  companyPaymentTerms,
  paymentMethods,
}) => {
  const { t } = useTranslation(['invoice', 'paymentMethods']);

  const paymentConditions = useInitialPaymentConditions({
    companyPaymentTerms,
    paymentMethods,
  });

  useEffect(() => {
    if (paymentConditions) {
      initFormValues(paymentConditions);
    }
  }, [initFormValues, paymentConditions]);

  return (
    <Fieldset<ProjectCreateForm>
      handle="client"
      title={t('invoice:steps.paymentConditions.title')}
      legend={t('invoice:steps.paymentConditions.description')}
      validationNames={['paymentTerm', 'deadlineType', 'paymentMethod']}
      noCard
    >
      <PaymentMethodSection
        paymentMethods={paymentMethods}
        description={t('paymentMethods:modal.select.desc.project')}
        hideTitle
        w="100%"
      />
    </Fieldset>
  );
};
