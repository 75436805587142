import type { FC } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PaymentMethodCreationForm } from './PaymentMethodCreationForm';

interface PaymentMethodCreateModalProps {
  onCreated(): void;

  isOpen: boolean;

  onClose(): void;
}

export const PaymentMethodCreateModal: FC<PaymentMethodCreateModalProps> = ({ onCreated, isOpen, onClose }) => {
  const { t } = useTranslation(['paymentMethods']);

  // TODO replace be lib-ui modal component
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader whiteSpace="nowrap">{t('paymentMethods:modal.create.title')}</ModalHeader>

        <ModalBody>
          <PaymentMethodCreationForm onClose={onClose} onCreated={onCreated} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
