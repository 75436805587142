import type { DrawerApi } from '@graneet/lib-ui';
import { DrawersStack, GraneetButton, ListingLayout } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { TRACKING_EVENT_ENUM, type ILibraryComponentWithRelations } from '@graneet/business-logic';
import type { FC } from 'react';
import { useState, useCallback } from 'react';

import { useQuoteLibraryImportComponent } from '../hooks/useQuoteLibraryImportComponent';

import { LibraryComponentTable } from 'features/library-component/components/LibraryComponentTable';
import { useStore } from 'store/store';
import {
  updateQuoteImportComponentDrawerIsOpen,
  updateQuoteImportComponentTargetParentId,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useLibraryComponents } from 'features/library-component/services/library-component.api';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';

interface QuoteLibraryComponentDrawerProps {
  drawer: DrawerApi<string>;
}

export const QuoteLibraryComponentDrawer: FC<QuoteLibraryComponentDrawerProps> = ({ drawer }) => {
  const { t } = useTranslation(['quote', 'library']);
  const segmentAnalytics = useSegmentAnalytics();

  const isOpen = useStore((state) => state.quoteImportComponentDrawerIsOpen);
  const parentId = useStore((state) => state.quoteImportComponentTargetParentId);

  const [selectedRows, setSelectedRows] = useState<ILibraryComponentWithRelations[]>([]);

  const quoteLibraryImportComponent = useQuoteLibraryImportComponent();
  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  const libraryComponents = useLibraryComponents({ storageStrategy: 'state' });

  const handleOnClose = useCallback(() => {
    updateQuoteImportComponentDrawerIsOpen(false);
    updateQuoteImportComponentTargetParentId(null);
  }, []);

  const handleOnSubmit = useCallback(async () => {
    if (!parentId) {
      throw new Error('Parent ID is required');
    }

    segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.QUOTE_COMPONENT_IMPORT, null);
    executeCommand(quoteLibraryImportComponent(selectedRows, parentId), quote);
    handleOnClose();
  }, [executeCommand, handleOnClose, parentId, quote, quoteLibraryImportComponent, segmentAnalytics, selectedRows]);

  return (
    <DrawersStack.Drawer
      title={t('library:componentDrawer.title')}
      drawer={{ ...drawer, isOpen, onClose: handleOnClose }}
      footer={
        <GraneetButton variant="primary" onClick={handleOnSubmit}>
          {t('quote:quotation.library.addButton')}
        </GraneetButton>
      }
      size="2xl"
    >
      <ListingLayout
        pagination={libraryComponents}
        search={{ placeholder: t('library:actions.searchLibraryComponent') }}
        content={
          <LibraryComponentTable
            gridId="library-component-import"
            libraryComponents={libraryComponents}
            onRowSelected={setSelectedRows}
            nonSelectableIds={[]}
          />
        }
      />
    </DrawersStack.Drawer>
  );
};
