import { Flex, Grid, Text } from '@chakra-ui/react';
import { useMemo, type FC, useCallback } from 'react';
import { Form, HiddenField, useForm, useFormContext } from 'graneet-form';
import { useLocation } from 'react-router-dom';

import { usePaginatedDataContext } from '../../PaginatedData/hooks/usePaginatedDataContext';
import { CurrencyField } from '../../Field/CurrencyField/CurrencyField';
import { useCurrency } from '../../Currency/CurrencyProvider';
import { isNumberFinite } from '../../../utils/number.util';
import { filtersTranslations } from '../configureDefaultLabels';
import { defaultPaginatedDataContext } from '../../PaginatedData/contexts/PaginatedDataContext.context';

export interface AmountFiltersProps<Name extends string = string> {
  name: Name;
  label: string;
}

interface InternalForm {
  minAmount?: number | null;
  maxAmount?: number | null;
}

export const AmountFilters: FC<AmountFiltersProps> = ({ label, name }) => {
  const paginatedDataContext = usePaginatedDataContext();
  const { search } = useLocation();
  const queryFromURL = useMemo(() => new URLSearchParams(search), [search]);
  const query = paginatedDataContext !== defaultPaginatedDataContext ? paginatedDataContext.storage : queryFromURL;

  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();

  const globalForm = useFormContext<{
    [name: string]: Array<{ min?: number | null; max?: number | null }>;
  }>();

  const minAmount = query.get(`${String(name)}[0][min]`);
  const maxAmount = query.get(`${String(name)}[0][max]`);

  const form = useForm<InternalForm>({
    defaultValues: {
      minAmount: minAmount ? mapNumberToAmount(parseInt(minAmount, 10)) : undefined,
      maxAmount: maxAmount ? mapNumberToAmount(parseInt(maxAmount, 10)) : undefined,
    },
  });

  const onChange = useCallback(() => {
    const values = form.getFormValues();
    globalForm.setFormValues({
      [name]: [
        {
          min: isNumberFinite(values.minAmount) ? mapAmountToNumber(values.minAmount) : undefined,
          max: isNumberFinite(values.maxAmount) ? mapAmountToNumber(values.maxAmount) : undefined,
        },
      ],
    });
  }, [form, globalForm, mapAmountToNumber, name]);

  return (
    <Flex gap={3}>
      <Text w="12rem" fontSize="sm" color="baseSecondary">
        {label}
      </Text>

      <HiddenField name={name} />

      <Form form={form} style={{ width: '100%' }}>
        <Grid templateColumns="repeat(2, 50%)" w="100%" gridGap={2}>
          <CurrencyField<InternalForm>
            name="minAmount"
            placeholder={filtersTranslations.minAmountPlaceholder}
            onChange={onChange}
          />

          <CurrencyField<InternalForm>
            name="maxAmount"
            placeholder={filtersTranslations.maxAmountPlaceholder}
            onChange={onChange}
          />
        </Grid>
      </Form>
    </Flex>
  );
};
