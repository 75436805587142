import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { CurrencyField, LabeledData, Modal, UnitField, useCurrency } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus, useOnBlurValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IBudgetUsage, IProject } from '@graneet/business-logic';
import { isNumberFinite, multiplyFloating, divideFloating, roundFloating } from '@graneet/business-logic';
import { useQueryClient } from '@tanstack/react-query';

import { useBudgetUpdate, useBudgetCreate } from '../services/budget.api';
import { getBudgetType } from '../services/budget.util';

import { useComponentTypes } from 'features/component-type/services/component-type.api';
import { Rule } from 'features/form/rules/Rule';
import { ComponentTypeBadge } from 'features/component-type/components/ComponentTypeBadge';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { timeSlotKeyFactory } from 'features/time-tracking/services/time-slot.api';

const FORM_ID = 'budget-edit';

interface BudgetEditModalProps {
  isOpen: boolean;

  onClose: () => void;

  onSuccess?: () => void;

  project: IProject;

  disabledTypes: number[];

  budgetUsage: IBudgetUsage | null;
}

interface BudgetEditForm {
  amountExVAT: number;

  nbMinutes: number;

  typeId: number;
}

export const BudgetEditModal: FC<BudgetEditModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  project,
  disabledTypes,
  budgetUsage,
}) => {
  const { t } = useTranslation(['global', 'project']);
  const { mapAmountToNumber, mapNumberToAmount } = useCurrency();
  const queryClient = useQueryClient();

  const form = useForm<BudgetEditForm>({
    defaultValues: budgetUsage
      ? {
          amountExVAT: mapNumberToAmount(budgetUsage.amountExVAT),
          typeId: budgetUsage.typeId,
          nbMinutes: isNumberFinite(budgetUsage.nbMinutes) ? divideFloating(budgetUsage.nbMinutes, 60) : undefined,
        }
      : {},
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const componentTypes = useComponentTypes();

  const budgetCreateMutation = useBudgetCreate();
  const budgetUpdateMutation = useBudgetUpdate();

  const translations = useMemo(() => {
    const base = `project:budget.${budgetUsage ? 'edit' : 'create'}` as const;
    return {
      submit: `${base}.submit` as const,
      title: `${base}.title` as const,
    };
  }, [budgetUsage]);

  const onSubmit = useCallback(
    async (formValues: BudgetEditForm) => {
      if (!budgetUsage) {
        await budgetCreateMutation.mutateAsync({
          projectId: project.id,
          typeId: formValues.typeId,
          amountExVAT: mapAmountToNumber(formValues.amountExVAT),
          nbMinutes: isNumberFinite(formValues.nbMinutes)
            ? roundFloating(multiplyFloating(formValues.nbMinutes, 60))
            : null,
        });
      } else {
        await budgetUpdateMutation.mutateAsync(
          {
            id: budgetUsage.id,
            dto: {
              amountExVAT: mapAmountToNumber(formValues.amountExVAT),
              nbMinutes: isNumberFinite(formValues.nbMinutes || budgetUsage.nbMinutes)
                ? roundFloating(multiplyFloating(formValues.nbMinutes ?? budgetUsage.nbMinutes!, 60))
                : null,
            },
          },
          {
            onSuccess: async () => {
              await queryClient.invalidateQueries(timeSlotKeyFactory.list(project.id));
            },
          },
        );
      }

      onSuccess?.();
      onClose();
    },
    [
      budgetCreateMutation,
      budgetUpdateMutation,
      budgetUsage,
      mapAmountToNumber,
      onClose,
      onSuccess,
      project.id,
      queryClient,
    ],
  );

  const { typeId } = useOnBlurValues(form, ['typeId']);

  const componentTypeId = budgetUsage?.typeId || typeId;
  const componentType = componentTypeId ? getBudgetType(componentTypes.data, componentTypeId) : undefined;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t(translations.title)} scrollBehavior="inside">
      <Form form={form} id={FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
        <Flex justifyContent="space-between">
          <Flex w="10rem">
            {budgetUsage ? (
              <Box w="100%">
                <LabeledData
                  label={t('project:budget.fields.type')}
                  data={<ComponentTypeBadge type={componentType!} />}
                />
              </Box>
            ) : (
              <ComponentTypeField<BudgetEditForm>
                name="typeId"
                disabledTypes={disabledTypes}
                label={t('project:budget.fields.type')}
                isRequired
                material
                workforce
              >
                <Rule.IsRequired />
              </ComponentTypeField>
            )}
          </Flex>

          <Flex gap={3} justifyContent="center">
            <Box w="10rem">
              {componentType?.isWorkforce && (
                <UnitField<BudgetEditForm>
                  label={t('project:budget.fields.nbMinutes')}
                  name="nbMinutes"
                  min={0}
                  scale={2}
                  unit="h"
                  isRequired
                >
                  <Rule.IsRequired />
                </UnitField>
              )}
            </Box>

            <Box w="10rem">
              <CurrencyField<BudgetEditForm>
                name="amountExVAT"
                label={t('project:budget.fields.amountExVAT')}
                isRequired
              >
                <Rule.IsRequired />
              </CurrencyField>
            </Box>
          </Flex>
        </Flex>

        <Modal.Close />

        <Modal.PrimaryButton
          isLoading={budgetCreateMutation.isPending || budgetUpdateMutation.isPending}
          isDisabled={!isFormValid}
          form={FORM_ID}
          type="submit"
        >
          {t(translations.submit)}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
