import { useCurrency, Wizard, ConfirmModal } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, useDisclosure } from '@chakra-ui/react';
import type { IProject, ISupplierInvoice } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { SupplierInvoiceInformationStep } from './_step/SupplierInvoiceInformationStep';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import {
  mapCreateSupplierInvoiceDTO,
  mapUpdateSupplierInvoiceDTO,
} from 'features/supplier-invoice/services/supplier-invoices.util';
import {
  useSupplierInvoiceCreate,
  useSupplierInvoiceUpdate,
} from 'features/supplier-invoice/services/supplier-invoice.api';
import { useSupplierInvoiceContext } from 'features/supplier-invoice/hooks/UseSupplierInvoiceContext';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useProjectLazyAreInUserScope } from 'features/project/services/project.api';
import type {
  EditSupplierInvoiceForm,
  EditSupplierInvoiceScreenFormValues,
} from 'features/supplier-invoice/forms/edit-supplier-invoice.form';

interface EditSupplierInvoiceScreenProps {
  context: 'create' | 'edit';

  project?: IProject;

  onQuit: (supplierInvoice: ISupplierInvoice | null) => void;

  onCreatedOrEdited: (
    supplierInvoice: ISupplierInvoice,
    options: { containsProjectOutsideOfUserScope: boolean; projectIds: number[] },
  ) => Promise<void> | void;
}

export const EditSupplierInvoiceScreen: FC<EditSupplierInvoiceScreenProps> = ({
  context,
  project,
  onCreatedOrEdited,
  onQuit,
}) => {
  useHideNavbar();

  const { t } = useTranslation(['global', 'supplierInvoices']);
  const { mapAmountToNumber } = useCurrency();
  const accessProjects = usePermissions([PERMISSION.VIEW_ALL_PROJECTS]);
  const [values, setValues] = useState<EditSupplierInvoiceForm | null>(null);

  const { supplierInvoice } = useSupplierInvoiceContext();

  const confirmScopeModal = useDisclosure();

  const { mutateAsync: projectLazyAreInUserScopeQuery } = useProjectLazyAreInUserScope();
  const supplierInvoiceCreateMutation = useSupplierInvoiceCreate();
  const supplierInvoiceUpdateMutation = useSupplierInvoiceUpdate();

  // Edition or creation context labels
  const wizardLabels = useWizardLabels(
    context === 'edit'
      ? {
          save: t('supplierInvoices:actions.editTheInvoice'),
          saving: t('global:form.saving'),
        }
      : {
          save: t('supplierInvoices:actions.createTheInvoice'),
          saving: t('global:form.saving'),
        },
  );

  const handleQuit = useCallback(() => {
    onQuit(supplierInvoice);
  }, [onQuit, supplierInvoice]);

  const submitSupplierInvoiceToApi = useCallback(
    async (
      formValues: EditSupplierInvoiceForm,
      { containsProjectOutsideOfUserScope } = { containsProjectOutsideOfUserScope: false },
    ) => {
      let createdOrUpdatedSupplierInvoice: ISupplierInvoice;

      // Edit context
      if (context === 'edit') {
        const initialAssociatedOrders = (supplierInvoice?.ordersSupplierInvoices || []).map(({ order }) => order);
        const dto = mapUpdateSupplierInvoiceDTO(
          {
            ...formValues,
            // Since we use form data null is passed as string and validator can not be used we use default nullable uuid
            accountingConfigPurchasesAccountId: formValues.accountingConfigPurchasesAccountId ?? null,
            deleteInvoiceFile: !formValues.invoiceFile,
          },
          mapAmountToNumber,
          supplierInvoice,
          initialAssociatedOrders,
        );

        createdOrUpdatedSupplierInvoice = await supplierInvoiceUpdateMutation.mutateAsync({
          id: supplierInvoice!.id,
          dto,
          invoiceFile: formValues.invoiceFile?.file,
          receiptFiles: formValues.receiptFiles,
        });
      } else {
        // Creation context
        const dto = mapCreateSupplierInvoiceDTO(formValues, mapAmountToNumber);

        createdOrUpdatedSupplierInvoice = await supplierInvoiceCreateMutation.mutateAsync({
          dto,
          invoiceFile: formValues.invoiceFile?.file,
          receiptFiles: formValues.receiptFiles,
        });
      }

      await onCreatedOrEdited(createdOrUpdatedSupplierInvoice, {
        containsProjectOutsideOfUserScope,
        projectIds: (createdOrUpdatedSupplierInvoice?.supplierInvoiceProjects || []).map((sip) => sip!.project!.id),
      });
    },
    [
      context,
      mapAmountToNumber,
      onCreatedOrEdited,
      supplierInvoice,
      supplierInvoiceCreateMutation,
      supplierInvoiceUpdateMutation,
    ],
  );

  /**
   * Handle wizard submit
   */
  const onFinish = useCallback(
    async (formValues: EditSupplierInvoiceScreenFormValues) => {
      if (!accessProjects) {
        const projects = formValues.information.associatedProjects;

        if (projects && projects?.length > 0) {
          const userScope = await projectLazyAreInUserScopeQuery(projects.map(({ id }) => id));

          const numberOfProjectsInScope = Object.entries(userScope).filter(([, inScope]) => inScope).length;

          if (numberOfProjectsInScope === 0) {
            setValues(formValues.information);
            confirmScopeModal.onOpen();
            return;
          }
        }
      }

      await submitSupplierInvoiceToApi(formValues.information);
    },
    [accessProjects, submitSupplierInvoiceToApi, projectLazyAreInUserScopeQuery, confirmScopeModal],
  );

  const onConfirmScope = useCallback(() => {
    submitSupplierInvoiceToApi(values!, { containsProjectOutsideOfUserScope: true });
  }, [submitSupplierInvoiceToApi, values]);

  return (
    <Wizard<EditSupplierInvoiceScreenFormValues>
      onQuit={handleQuit}
      onFinish={onFinish}
      labels={wizardLabels}
      headerTitle={
        context === 'edit'
          ? t('supplierInvoices:actions.editTheInvoice')
          : t('supplierInvoices:titles.addSupplierInvoice')
      }
    >
      <Wizard.Step<EditSupplierInvoiceScreenFormValues> name="information" h="100%">
        <QueryWrapper>
          <SupplierInvoiceInformationStep project={project} />
        </QueryWrapper>
      </Wizard.Step>

      <ConfirmModal
        title={t(`supplierInvoices:confirmScopeModal.${context.toLowerCase()}.title` as any)}
        modal={confirmScopeModal}
        onConfirmed={onConfirmScope}
        labels={{ ok: t('global:words.c.validate'), ko: t('global:words.c.back') }}
      >
        <Text>{t(`supplierInvoices:confirmScopeModal.${context.toLowerCase()}.body` as any)}</Text>
      </ConfirmModal>
    </Wizard>
  );
};
