import type { FC, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { IProject } from '@graneet/business-logic';

import { useSupplierInvoiceOrUndefined } from '../services/supplier-invoice.api';

import type { SupplierInvoiceEditContextType } from './SupplierInvoiceEditContext.context';
import { SupplierInvoiceEditContext } from './SupplierInvoiceEditContext.context';

import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';

export function useSupplierInvoiceGoToListing(options: { project: IProject | null | undefined }) {
  const { createRedirectionWithSavedFilters } = useFiltersQuery();

  return useCallback(() => {
    if (options.project) {
      createRedirectionWithSavedFilters(`/projects/${options.project.id}/purchases/supplier-invoices`)();
    } else {
      createRedirectionWithSavedFilters('/purchases/supplier-invoices')();
    }
  }, [createRedirectionWithSavedFilters, options.project]);
}

export type SupplierInvoiceEditContextProviderProps = {
  children: ReactNode;
};

export const SupplierInvoiceEditContextProvider: FC<SupplierInvoiceEditContextProviderProps> = ({ children }) => {
  const { supplierInvoiceId } = useParams<{ supplierInvoiceId?: string }>();

  const [hasOCRBeenDone, setHasOCRBeenDone] = useState(false);
  const [amountExVATFromOCR, setAmountExVATFromOCR] = useState<number | null>(null);

  const onOCR = useCallback((amountExVAT: number | null) => {
    setAmountExVATFromOCR(amountExVAT);
    setHasOCRBeenDone(true);
  }, []);

  const supplierInvoice = useSupplierInvoiceOrUndefined(
    supplierInvoiceId ? parseInt(supplierInvoiceId, 10) : undefined,
  );

  const contextValue = useMemo<SupplierInvoiceEditContextType>(
    () => ({
      supplierInvoice: supplierInvoice.data,
      onOCR,
      hasOCRBeenDone,
      amountExVATFromOCR,
    }),
    [amountExVATFromOCR, hasOCRBeenDone, onOCR, supplierInvoice.data],
  );

  return <SupplierInvoiceEditContext.Provider value={contextValue}>{children}</SupplierInvoiceEditContext.Provider>;
};
