import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import type { LexicalEditor } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';

import { RICH_TEXT_CSS_VAR_DARK } from '../../constants';

export interface RichTextContextApi {
  editor: LexicalEditor | null;
  internal: {
    registerEditor: (newEditor: LexicalEditor) => void;
    unregisterEditor: () => void;
  };
  exportHtml(): Promise<string>;
  exportText(): string;
}

export const RichTextContext = createContext<RichTextContextApi>({
  editor: null,
  internal: {
    registerEditor() {},
    unregisterEditor() {},
  },
  exportHtml() {
    return Promise.reject(new Error('No editor available'));
  },
  exportText() {
    return '';
  },
});

export const useRichTextContext = () => useContext(RichTextContext);

export const replaceColorVarsWithHex = (htmlSnippet: string) => {
  const regex = /color:\s*var\((--[a-z-]+)\)/gi;

  return htmlSnippet.replace(regex, (match, colorVar: string) => {
    if (colorVar in RICH_TEXT_CSS_VAR_DARK) {
      return `color: ${RICH_TEXT_CSS_VAR_DARK[colorVar as keyof typeof RICH_TEXT_CSS_VAR_DARK]}`;
    }
    return match;
  });
};

export const useRichText = (): RichTextContextApi => {
  const editorRef = useRef<LexicalEditor | null>(null);

  const exportHtml = useCallback(
    () =>
      new Promise<string>((resolve, reject) => {
        if (editorRef.current) {
          editorRef.current.update(() => {
            try {
              const htmlString = $generateHtmlFromNodes(editorRef.current as LexicalEditor, null);
              resolve(replaceColorVarsWithHex(htmlString));
            } catch (error) {
              reject(error);
            }
          });
        } else {
          reject(new Error('Editor not initialized'));
        }
      }),
    [],
  );

  const exportText = useCallback(() => editorRef.current?.getRootElement()?.textContent || '', []);

  const registerEditor = useCallback((newEditor: LexicalEditor) => {
    if (editorRef.current) {
      throw new Error('Editor already registered');
    }
    editorRef.current = newEditor;
  }, []);

  const unregisterEditor = useCallback(() => {
    if (!editorRef.current) {
      throw new Error('No editor is registered.');
    }
    editorRef.current = null;
  }, []);

  return useMemo(
    () => ({
      editor: editorRef.current,
      internal: {
        registerEditor,
        unregisterEditor,
      },
      exportHtml,
      exportText,
    }),
    [registerEditor, unregisterEditor, exportHtml, exportText],
  );
};

export const RichTextContextProvider = RichTextContext.Provider;
