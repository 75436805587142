import {
  Modal,
  Table,
  TextField,
  ColorPickerField,
  Tooltip,
  SimpleHelpIcon,
  Button,
  SimpleDeleteIcon,
  SimpleAddIcon,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Spacer, Stack, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { v4 as uuid } from 'uuid';
import type { IComponentType, IComponentTypeUpdateDTO } from '@graneet/business-logic';
import { GRANEET_COLORS, TRACKING_EVENT_ENUM } from '@graneet/business-logic';
import { differenceBy } from 'lodash-es';
import { AddIcon } from '@chakra-ui/icons';

import { useComponentTypes, useUpdateComponentsTypes } from '../../services/component-type.api';

import { Rule } from 'features/form/rules/Rule';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';
import { COLOR_PICKER_COLORS } from 'features/common/constants/color.constant';

type KeyOfComponentType = keyof Pick<IComponentType, 'name' | 'unit' | 'color'>;
type FieldName<T extends KeyOfComponentType> = `${T}-${number | string}`;
interface FormValues {
  [name: FieldName<'name'>]: string;

  [unit: FieldName<'unit'>]: string | null;

  [color: FieldName<'color'>]: string;
}

const generateFieldName = <T extends KeyOfComponentType>(id: string | number, type: T): FieldName<T> => `${type}-${id}`;
const getValuesFromFormValuesForId = (
  id: string | number,
  formValues: FormValues,
  isWorkforce: boolean,
): { name: string; unit: string | null; color: string } => {
  const {
    [generateFieldName(id, 'name')]: name,
    [generateFieldName(id, 'unit')]: unit,
    [generateFieldName(id, 'color')]: color,
  } = formValues;

  return {
    name,
    // if unit is equal to '', replace by null
    unit: isWorkforce ? 'h' : unit || null,
    color,
  };
};

interface ComponentTypeRowProps {
  id: number | string;

  isUsed: boolean;

  isWorkforce?: boolean;

  onDelete(id: number | string): void;

  isLastWorkforce: boolean;
}

const ComponentTypeRow: FC<ComponentTypeRowProps> = ({
  id,
  isUsed,
  isWorkforce = false,
  onDelete,
  isLastWorkforce,
}) => {
  const { t } = useTranslation(['componentType']);
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  const canBeDeleted = !isUsed && (!isWorkforce || (isWorkforce && !isLastWorkforce));

  return (
    <Table.Row onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Table.Cell>
        <Stack direction="row" alignItems="baseline">
          <TextField<FormValues> name={generateFieldName(id, 'name')}>
            <Rule.IsRequired />
          </TextField>

          {isWorkforce && (
            <Tooltip label={t('componentType:tooltips.workforceInformation')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          )}
        </Stack>
      </Table.Cell>

      <Table.Cell>
        <TextField<FormValues> name={generateFieldName(id, 'unit')} isReadOnly={isWorkforce || isUsed} />
      </Table.Cell>

      <Table.Cell center>
        <ColorPickerField<FormValues>
          name={generateFieldName(id, 'color')}
          colors={COLOR_PICKER_COLORS}
          variant="compact"
        >
          <Rule.IsRequired />
        </ColorPickerField>
      </Table.Cell>

      <Table.Cell>
        {canBeDeleted && (
          <SimpleDeleteIcon
            visibility={isHovered ? 'visible' : 'hidden'}
            opacity={isHovered ? 1 : 0}
            transition="visibility 0s, opacity 0.1s linear"
            stroke="gray.300"
            onClick={handleDelete}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

interface ComponentTypeEditModalProps {
  onClose(): void;

  isOpen: boolean;
}

export const ComponentTypeEditModal: FC<ComponentTypeEditModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation(['global', 'componentType']);
  const segmentAnalytics = useSegmentAnalytics();

  const form = useForm<FormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  const initialComponentTypes = useComponentTypes();

  const updateComponentsTypesMutation = useUpdateComponentsTypes();

  const initialComponents = useMemo<Array<{ isWorkforce: boolean; id: number | string }>>(
    () =>
      initialComponentTypes.data.map((componentType) => ({
        isWorkforce: componentType.isWorkforce,
        id: componentType.id,
      })),
    [initialComponentTypes.data],
  );

  const [componentTypes, setComponentTypes] = useState<Array<{ isWorkforce: boolean; id: number | string }>>([]);
  useEffect(() => {
    setComponentTypes([...initialComponents]);
  }, [initialComponents]);

  const onSubmit = useCallback(async () => {
    const dto: IComponentTypeUpdateDTO = {
      created: [],
      updated: [],
      deleted: [],
    };

    const formValues = form.getFormValues() as FormValues;

    componentTypes.forEach((comp) => {
      const initialIds = initialComponents.map((component) => component.id);

      if (initialIds.includes(comp.id)) {
        const updatedEntity = {
          ...getValuesFromFormValuesForId(comp.id, formValues, comp.isWorkforce),
          id: comp.id as number,
        };
        dto.updated.push(updatedEntity);
      } else {
        dto.created.push({
          ...getValuesFromFormValuesForId(comp.id, formValues, comp.isWorkforce),
          isWorkforce: comp.isWorkforce,
        });
      }
    });

    dto.deleted = differenceBy(initialComponents, componentTypes, 'id').map((item) => item.id) as number[];

    updateComponentsTypesMutation.mutate(dto, {
      onSuccess: () => {
        onClose();
      },
    });
  }, [componentTypes, form, initialComponents, onClose, updateComponentsTypesMutation]);

  useEffect(() => {
    const formValues = initialComponentTypes.data.reduce<FormValues>((acc, componentType) => {
      const { id, color, unit, name } = componentType;

      acc[generateFieldName(id, 'color')] = color;
      acc[generateFieldName(id, 'unit')] = unit;
      acc[generateFieldName(id, 'name')] = name;

      return acc;
    }, {});

    form.setFormValues(formValues);
  }, [initialComponentTypes.data, form]);

  const addComponentType = useCallback(
    (isWorkforce: boolean = false) => {
      const id = uuid();
      setComponentTypes((prevState) => [...prevState, { isWorkforce, id }]);
      form.setFormValues({
        [generateFieldName(id, 'color')]: GRANEET_COLORS.WARM_GRAY,
        [generateFieldName(id, 'unit')]: isWorkforce ? 'h' : '',
      });

      segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.PLANNING_COMPONENT_TYPE_CREATED, {
        type: isWorkforce ? 'workforce' : 'material',
      });
    },
    [form, segmentAnalytics],
  );

  const deleteComponentType = useCallback((id: string | number) => {
    setComponentTypes((prevState) => [...prevState.filter((componentType) => componentType.id !== id)]);
  }, []);

  const countWorkforces = componentTypes.filter((item) => item.isWorkforce).length;

  return (
    <Form form={form}>
      <Modal title={t('componentType:editCard.title')} onClose={onClose} isOpen={isOpen} scrollBehavior="inside">
        <Stack>
          <Text>{t('componentType:editCard.description')}</Text>

          <Box bg="gray.50" rounded="sm" p={4}>
            <Table templateColumns={['auto', '8rem', '6rem', '3rem']}>
              <Table.Header>
                <Table.Cell>{t('componentType:fields.name')}</Table.Cell>

                <Table.Cell>{t('componentType:fields.unit')}</Table.Cell>

                <Table.Cell center>{t('componentType:fields.color')}</Table.Cell>

                <Table.Cell />
              </Table.Header>

              {componentTypes.map((comp) => (
                <ComponentTypeRow
                  key={comp.id}
                  id={comp.id}
                  isUsed={
                    initialComponentTypes.data.find((componentType) => componentType.id === comp.id)?.isUsed ?? false
                  }
                  isWorkforce={comp.isWorkforce}
                  onDelete={deleteComponentType}
                  isLastWorkforce={countWorkforces === 1}
                />
              ))}
            </Table>

            <Flex mt={2} alignItems="center">
              <Button onClick={() => addComponentType()} leftIcon={<SimpleAddIcon />} variant="outline">
                {t('componentType:cta.create')}
              </Button>

              <Spacer />

              <Button onClick={() => addComponentType(true)} leftIcon={<AddIcon />} variant="outline">
                {t('componentType:cta.createMO')}
              </Button>
            </Flex>
          </Box>
        </Stack>

        <Modal.Close isDisabled={updateComponentsTypesMutation.isPending} />

        <Modal.PrimaryButton
          onClick={onSubmit}
          isLoading={updateComponentsTypesMutation.isPending}
          isDisabled={!isFormValid}
        >
          {t('global:applyUpdate')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
