import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, HStack, Spacer, Box } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { Button, Tooltip } from '@graneet/lib-ui';
import type { IFormattedValidationStepHistory } from '@graneet/business-logic';

import { getValidationStepStatus } from 'features/validation-step/utils/getValidationStepStatus';

interface ValidationStepProps {
  currentValidationStepHistory: IFormattedValidationStepHistory;
  isToValidateStatus: boolean;
  handleValidStep: () => void;
  isUserCanApprove: boolean;
  onOpenValidationStepsHistoryModal: () => void;
  isUserAboveThreshold: boolean;
}

export const CurrentValidationStep: FC<ValidationStepProps> = ({
  currentValidationStepHistory,
  isToValidateStatus,
  handleValidStep,
  isUserCanApprove,
  onOpenValidationStepsHistoryModal,
  isUserAboveThreshold,
}) => {
  const { t } = useTranslation(['global', 'validationStep']);
  const userApprovedStep = currentValidationStepHistory.users.find((u) => u.isApproved);
  const { icon, title } = useMemo(
    () =>
      getValidationStepStatus(
        (translationKey: 'requestApproved' | 'pendingApproval' | 'requiredApproval' | 'possibleApproval') =>
          t(`validationStep:${translationKey}`),
        currentValidationStepHistory,
        isToValidateStatus,
        userApprovedStep?.user,
      ),
    [currentValidationStepHistory, isToValidateStatus, t, userApprovedStep?.user],
  );

  return (
    <HStack backgroundColor="gray.100" borderRadius="md" p={4}>
      {icon}
      {title}
      <Spacer />
      <Flex gap={4}>
        {isToValidateStatus && isUserCanApprove && (
          <Tooltip
            label={
              isUserAboveThreshold ? t('validationStep:supplierInvoice.tooltips.delegateToPrecedingValidators') : ''
            }
            placement="top"
          >
            <Box>
              <Button leftIcon={<CheckIcon boxSize={3} />} onClick={handleValidStep}>
                {t('global:words.c.approve')}
              </Button>
            </Box>
          </Tooltip>
        )}
        <Button variant="ghost" onClick={onOpenValidationStepsHistoryModal}>
          {t('validationStep:validationHistory')}
        </Button>
      </Flex>
    </HStack>
  );
};
