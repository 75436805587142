import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Input, Modal } from '@graneet/lib-ui';
import type { ISubProject, ISubProjectWithInformation } from '@graneet/business-logic';
import { SUB_PROJECT_BILLING_TYPE } from '@graneet/business-logic';
import { Box, Text } from '@chakra-ui/react';
import { HiddenField, useFormContext, useHiddenField } from 'graneet-form';

import { filterOnlyContaining } from 'features/common/services/common.util';
import { SubProjectSelectionTable } from 'features/sub-project/components/SubProjectSelectionTable';
import type { OrderEditForm } from 'features/order/forms/order-edit-wizard';

interface OrderSubProjectSelectionModalProps {
  onClose(): void;

  isOpen: boolean;

  subProjects: ISubProject[] | null;

  defaultSelectedSubProjectId?: string;
}

export const OrderSubProjectSelectionModal: FC<OrderSubProjectSelectionModalProps> = ({
  onClose,
  isOpen,
  subProjects,
  defaultSelectedSubProjectId,
}) => {
  const { t } = useTranslation(['orders', 'global']);

  const form = useFormContext<OrderEditForm>();

  const hiddenField = useHiddenField(form, 'subProjectId');
  const { setValue: updateSubProjectId } = hiddenField;
  const [search, setSearch] = useState('');
  const filteredSubProjects = useMemo(
    () => filterOnlyContaining(subProjects || [], ['name'], search),
    [search, subProjects],
  );

  const [selectedSubProject, setSelectedSubProject] = useState<ISubProjectWithInformation | null>(null);

  const onSubProjectSelect = useCallback((newSubProject: ISubProjectWithInformation | null) => {
    setSelectedSubProject(newSubProject);
  }, []);

  const onClick = useCallback(() => {
    updateSubProjectId(selectedSubProject?.id);
    onClose();
  }, [onClose, selectedSubProject?.id, updateSubProjectId]);

  const getRowState = useCallback(
    (subProject: ISubProjectWithInformation) => ({
      isDisabled: subProject.billingType === SUB_PROJECT_BILLING_TYPE.DIRECT,
      tooltipMessage:
        subProject.billingType === SUB_PROJECT_BILLING_TYPE.DIRECT
          ? t('orders:subProjectSelectionModal.tooltips.cannotAssociateDirectBilling')
          : undefined,
    }),
    [t],
  );

  const handleClose = useCallback(() => {
    const formValues = form.getFormValues();

    if (formValues.isDirectPayment && !formValues.subProjectId) {
      form.setFormValues({ isDirectPayment: false });
    }

    onClose();
  }, [form, onClose]);

  return (
    <>
      <HiddenField {...hiddenField} />
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        scrollBehavior="inside"
        title={t('orders:subProjectSelectionModal.title')}
        size="3xl"
      >
        <Text mb={2}>{t('orders:subProjectSelectionModal.description')}</Text>

        <Box mb={2}>
          <Input
            w={['100%', '100%', '20rem']}
            placeholder={t('global:words.c.research')}
            onChange={setSearch}
            value={search}
          />
        </Box>

        <SubProjectSelectionTable
          subProjects={filteredSubProjects}
          onSubProjectSelect={onSubProjectSelect}
          getRowState={getRowState}
          defaultSelectedSubProjectId={defaultSelectedSubProjectId}
        />

        <Modal.Close />

        <Modal.PrimaryButton onClick={onClick} isDisabled={!selectedSubProject}>
          {t('orders:subProjectSelectionModal.cta')}
        </Modal.PrimaryButton>
      </Modal>
    </>
  );
};
