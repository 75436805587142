import { groupBy } from 'lodash-es';
import { Box, Flex, HStack, Text, Wrap } from '@chakra-ui/react';
import {
  CheckFilledIcon,
  CheckOutlineFullyRoundedIcon,
  CircleIcon,
  MultipleFileUpload,
  RichText,
  type RichTextConfiguration,
  SlashCircleIcon,
  useToast,
} from '@graneet/lib-ui';
import { useCallback } from 'react';
import { SUPPORT_EMAIL, MAIL_DELIVERY_STATUS, EMAIL_RECIPIENT } from '@graneet/business-logic';
import type { EmailDetails, IEmailRecipient, IFile, IEmailWithRelations } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@graneet/lib-ui';

import { Error } from 'features/common/components/Error';
import { Loading } from 'features/common/components/Loading';
import { getEmailWithRelationsById } from 'features/mailing/services/mailing.api';
import { useData } from 'features/api/hooks/useData';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { formatFilePreviewUrl, formatOptionsFileData } from 'features/file/services/file.util';

const iconStatus = {
  [MAIL_DELIVERY_STATUS.OPENED]: <CheckFilledIcon />,
  [MAIL_DELIVERY_STATUS.DELIVERED]: <CheckOutlineFullyRoundedIcon />,
  [MAIL_DELIVERY_STATUS.ERROR]: <SlashCircleIcon />,
  [MAIL_DELIVERY_STATUS.BOUNCED]: <SlashCircleIcon />,
  [MAIL_DELIVERY_STATUS.BLOCKED]: <SlashCircleIcon />,
  [MAIL_DELIVERY_STATUS.DROPPED]: <SlashCircleIcon />,
  [MAIL_DELIVERY_STATUS.DISPATCHED]: <CircleIcon />,
  [MAIL_DELIVERY_STATUS.PROCESSED]: <CircleIcon />,
  [MAIL_DELIVERY_STATUS.DEFERRED]: <CircleIcon />,
};

const Recipients = ({ recipients, context }: { recipients: IEmailRecipient[]; context: EMAIL_RECIPIENT }) => {
  const { t } = useTranslation(['mailing']);
  return (
    <Flex gap={1} alignItems="center">
      <HStack minW={6}>
        <Text
          as="span"
          fontWeight="semibold"
          fontFamily="lato"
          fontSize="sm"
          color="gray.500"
          textTransform="uppercase"
        >
          {t(`mailing:recipientsFieldGroup.${context}`)}
        </Text>
      </HStack>
      <Wrap spacing={0}>
        {recipients.map(({ recipientEmail, status }, index) => (
          <Tooltip label={t(`emailStatus.${status}`)}>
            <Flex key={recipientEmail} alignItems="center" gap={1} p={0.5}>
              {iconStatus[status]}
              <Text as="span" fontWeight="medium" fontSize="sm" color="gray.800" lineHeight={5} whiteSpace="nowrap">
                {recipientEmail}
                {index < recipients.length - 1 && ', '}
              </Text>
            </Flex>
          </Tooltip>
        ))}
      </Wrap>
    </Flex>
  );
};

function shouldDisplayRichText(data: IEmailWithRelations) {
  try {
    const parsed = JSON.parse(data.bodyLexical);
    return Object.keys(parsed).length !== 0;
  } catch (_e) {
    // case bodyLexical is a string
    return true;
  }
}

export interface PanelAccordionEmailProps {
  item: EmailDetails;
  isExpanded: boolean;
  emailTemplateRichTextConfiguration: RichTextConfiguration;
}

export const PanelAccordionEmail = ({
  item,
  isExpanded,
  emailTemplateRichTextConfiguration,
}: PanelAccordionEmailProps) => {
  const { data, loading, error } = useData(useDataGetter(getEmailWithRelationsById, item.emailId), {
    autoLoad: isExpanded,
  });
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const groupedRecipients = groupBy(data?.recipients, 'type');

  const handleAttachmentOpen = useCallback(
    (index: string) => {
      const file = data?.files[Number(index)];
      if (file) {
        const fileUrl = formatFilePreviewUrl(file?.file.id);
        window.open(fileUrl, '_blank');
      } else {
        toast.error(t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
      }
    },
    [data, t, toast],
  );

  return (
    <>
      <Box borderTop="1px" borderColor="gray.150" py={2} pr={4} marginLeft={8}>
        {loading && <Loading my={8} />}
        {error && <Error message={t('errors.error')} />}
        {!error && !loading && (
          <>
            {groupedRecipients[EMAIL_RECIPIENT.TO] ? (
              <Recipients recipients={groupedRecipients[EMAIL_RECIPIENT.TO]} context={EMAIL_RECIPIENT.TO} />
            ) : null}
            {groupedRecipients[EMAIL_RECIPIENT.CC] ? (
              <Recipients recipients={groupedRecipients[EMAIL_RECIPIENT.CC]} context={EMAIL_RECIPIENT.CC} />
            ) : null}
            {groupedRecipients[EMAIL_RECIPIENT.BCC] ? (
              <Recipients recipients={groupedRecipients[EMAIL_RECIPIENT.BCC]} context={EMAIL_RECIPIENT.BCC} />
            ) : null}
          </>
        )}
      </Box>
      {!error && !loading && (
        <Box borderTop="1px" borderColor="gray.150" py={4} pr={4} marginLeft={8}>
          {shouldDisplayRichText(data) && (
            <RichText value={data.bodyLexical} configuration={emailTemplateRichTextConfiguration} />
          )}
        </Box>
      )}
      {!error && !loading && (
        <Box borderTop="1px" borderColor="gray.150" py={4} pr={4} marginLeft={8}>
          <MultipleFileUpload
            files={data.files
              .filter((file) => file.file)
              .map((file, index) =>
                formatOptionsFileData(file.file as IFile, index.toString(), (initialFile: IFile) =>
                  formatFilePreviewUrl(initialFile.id),
                ),
              )}
            onFileOpen={handleAttachmentOpen}
            canUpload={false}
          />
        </Box>
      )}
    </>
  );
};
