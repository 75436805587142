import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { keyBy } from 'lodash-es';

import * as jobsApi from '../services/quote-job.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

/**
 * Import an array of library components into a quote job
 * @returns {(function(number, number[]): Promise<undefined|*>)|*}
 */
export const useImportLibraryComponentsIntoQuoteJob = () => {
  const toast = useToast();
  const { t } = useTranslation(['global']);
  const { updateDataLocally, emitLotUpdate, findLotIdFromJobIdInRelations } = useQuoteEditContext();

  return useCallback(
    async (jobId: number, libraryComponentsIds: number[]) => {
      const [err, response] = await jobsApi.importComponentsInJob(jobId, { libraryComponentsIds });

      if (err) {
        toast.error(t('global:errors.error'));
        return undefined;
      }

      const { components: updatedComponents, job: updatedJob } = response;

      const newComponents = keyBy(updatedComponents, 'id');

      updateDataLocally({ ...response, jobs: { [updatedJob.id]: updatedJob }, components: newComponents });

      const currentLot = findLotIdFromJobIdInRelations(jobId);

      if (currentLot) {
        emitLotUpdate(currentLot);
      }
      return [err, updatedComponents];
    },
    [t, toast, updateDataLocally, emitLotUpdate, findLotIdFromJobIdInRelations],
  );
};
