import { useCurrency } from '@graneet/lib-ui';

import type { ProgressStatementEditItemForm } from '../forms/progress-statement-edit-item-form';

import { useCumulativeProgressExVAT } from './useCumulativeProgressExVAT';

export const useAmountExVATCompute = (
  cumulativeValues: Partial<ProgressStatementEditItemForm>,
  previousCumulativeAmountExVAT = 0,
) => {
  const { mapAmountToNumber } = useCurrency();

  const cumulativeAmountExVAT = useCumulativeProgressExVAT(cumulativeValues);

  return mapAmountToNumber(cumulativeAmountExVAT) - previousCumulativeAmountExVAT;
};
