import type {
  IGroupedContact,
  IContact,
  ICreateContactDTO,
  IUpdateContactDTO,
  IImportContactsDTO,
  IContactQuoteResponseDTO,
  IContactWithProjectInfos,
  IContactAssociateToClientDTO,
  IContactAssociateToSupplierDTO,
  IContactAssociateToQuoteDTO,
  IContactAssociateToProjectDTO,
  IContactIsUsedAsDefaultRecipientDTO,
} from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import type { PaginatedResponse, UsePaginationOptions } from '@graneet/lib-ui';
import { useToast, usePaginationQuery } from '@graneet/lib-ui';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { type QueryClient, useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { api } from 'features/api/services/api.service';
import { apiNew } from 'features/api/services/apiNew.service';

const FACTORY_NAME = 'contacts';

const CONTACT_PATH = '/contacts';

export async function invalidAllContactQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [FACTORY_NAME],
    exact: false,
  });
}

const contactFactoryKey = createQueryKeys('contacts', {
  get: (queryParams: URLSearchParams | { _full: true }) => ({
    queryKey: [CONTACT_PATH, queryParams],
    queryFn: () =>
      apiNew.get<URLSearchParams | { _full: true }, PaginatedResponse<IContact>>(CONTACT_PATH, queryParams),
  }),
  getRoles: () => ({
    queryKey: [CONTACT_PATH],
    queryFn: () => apiNew.get<never, string[]>(`${CONTACT_PATH}/roles`),
  }),
  getAssociatedToSupplier: (supplierId: number) => ({
    queryKey: [CONTACT_PATH, supplierId],
    queryFn: () => apiNew.get<never, IContact[]>(`${CONTACT_PATH}/supplier/${supplierId}`),
  }),
  getAssociatedToQuote: (quoteId?: number) => ({
    queryKey: [CONTACT_PATH, quoteId],
    queryFn: () => {
      if (quoteId) {
        return apiNew.get<never, IContactQuoteResponseDTO>(`${CONTACT_PATH}/quote/${quoteId}`);
      }
      return Promise.resolve<IContactQuoteResponseDTO>([]);
    },
  }),
  getAssociatedToProject: (projectId?: number) => ({
    queryKey: [CONTACT_PATH, projectId],
    queryFn: () => {
      if (projectId) {
        return apiNew.get<never, IGroupedContact<IContactWithProjectInfos>[]>(`${CONTACT_PATH}/project/${projectId}`);
      }
      return Promise.resolve<IGroupedContact<IContactWithProjectInfos>[]>([]);
    },
  }),
  isUsedAsDefaultRecipient: (id?: string) => ({
    queryKey: [CONTACT_PATH, id],
    queryFn: () => {
      if (!id) {
        return Promise.resolve<IContactIsUsedAsDefaultRecipientDTO>({ isUsed: false });
      }
      return apiNew.get<never, IContactIsUsedAsDefaultRecipientDTO>(
        `${CONTACT_PATH}/${id}/is-used-as-default-recipient`,
      );
    },
  }),
});

// -- Query
export function useContacts(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) => queryClient.fetchQuery(contactFactoryKey.get(urlSearchParams)),
    options,
  );
}

export function useContactsWithoutPagination() {
  return useSuspenseQuery(
    contactFactoryKey.get({
      _full: true,
    }),
  );
}

export function useContactRoles() {
  return useSuspenseQuery(contactFactoryKey.getRoles());
}

// TODO Migrate to TanStack Query and migrate associated table to AG grid
/*
  There is multiple usage bugged because of missing _full: at some plate, data is fetch to find an entity but it can be in the page 2. In this case, it's an issue
 */
export const getContactAssociatedToClientPaginated = (queryParams: URLSearchParams) =>
  api.get<URLSearchParams, PaginatedResponse<IContact>>(
    `${CONTACT_PATH}/client/${queryParams.get('clientId')}`,
    queryParams,
  );

export function useContactAssociatedToSupplier(supplierId: number) {
  return useSuspenseQuery(contactFactoryKey.getAssociatedToSupplier(supplierId));
}

export function useContactAssociatedToQuote(quoteId: number | undefined) {
  return useSuspenseQuery(contactFactoryKey.getAssociatedToQuote(quoteId));
}

export function useContactAssociatedToProject(projectId: number | undefined) {
  return useSuspenseQuery(contactFactoryKey.getAssociatedToProject(projectId));
}

// TODO Migrate to TanStack Query and migrate associated table to AG grid
export const getContactAvailableForClient = (queryParams: URLSearchParams) =>
  api.get<URLSearchParams, PaginatedResponse<IContact>>(`${CONTACT_PATH}/available/client`, queryParams);

// TODO Migrate to TanStack Query and migrate associated table to AG grid
export const getContactAvailableForSupplier = (queryParams: URLSearchParams) =>
  api.get<URLSearchParams, PaginatedResponse<IContact>>(`${CONTACT_PATH}/available/supplier`, queryParams);

// TODO Migrate to TanStack Query and migrate associated table to AG grid
export const getContactAvailableForQuote = (queryParams: URLSearchParams) =>
  api.get<URLSearchParams, PaginatedResponse<IContact>>(
    `${CONTACT_PATH}/available/quote/${queryParams.get('quoteId')}`,
    queryParams,
  );

// TODO Migrate to TanStack Query and migrate associated table to AG grid
export const getContactAvailableForProjectPaginated = (queryParams: URLSearchParams) =>
  api.get<URLSearchParams, PaginatedResponse<IGroupedContact>>(
    `${CONTACT_PATH}/available/project/${queryParams.get('projectId')}`,
    queryParams,
  );

export function useContactIsUsedAsDefaultRecipient(id: string | undefined) {
  return useSuspenseQuery(contactFactoryKey.isUsedAsDefaultRecipient(id));
}

// -- Mutation
export function useContactCreate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ICreateContactDTO) => apiNew.post<ICreateContactDTO, IContact>(CONTACT_PATH, dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('contacts:creationModal.toastError'));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
    },
  });
}

export function useContactUpdate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { dto: ICreateContactDTO; id: string }) =>
      apiNew.patch<IUpdateContactDTO, IContact>(`${CONTACT_PATH}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('contacts:editionModal.toastError'));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
    },
  });
}

export function useContactDelete() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (contact: IContact) => apiNew.delete<never, void>(`${CONTACT_PATH}/${contact.id}`),
    onError: () => {
      toast.error(t('global:words.c.error'), t('contacts:deletionModal.toastError'));
    },
    onSuccess: async (_, contact) => {
      await invalidAllContactQueries(queryClient);

      toast.success(
        t('contacts:deletionModal.toastSuccess', {
          firstName: contact.firstName,
          lastName: contact.lastName,
        }),
      );
    },
  });
}

export function useContactImport() {
  const toast = useToast();
  const { t } = useTranslation(['contacts']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IImportContactsDTO) => apiNew.post<IImportContactsDTO, IContact[]>(`${CONTACT_PATH}/batch`, dto),
    onError: () => {
      toast.error(t('contacts:import.error'));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
      toast.success(t('contacts:import.success'));
    },
  });
}

export function useContactsAssociateToClient() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IContactAssociateToClientDTO }) =>
      apiNew.patch<IContactAssociateToClientDTO, void>(`${CONTACT_PATH}/associate/clients/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
    },
  });
}

export function useContactsAssociateToSupplier() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IContactAssociateToSupplierDTO }) =>
      apiNew.patch<IContactAssociateToSupplierDTO, void>(
        `${CONTACT_PATH}/associate/suppliers/${params.id}`,
        params.dto,
      ),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
    },
  });
}

export function useContactsAssociateToQuote() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IContactAssociateToQuoteDTO }) =>
      apiNew.patch<IContactAssociateToQuoteDTO, void>(`${CONTACT_PATH}/associate/quotes/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
    },
  });
}

export function useContactsAssociateToProject() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IContactAssociateToProjectDTO }) =>
      apiNew.patch<IContactAssociateToProjectDTO, void>(`${CONTACT_PATH}/associate/projects/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
    },
  });
}

export function useContactDissociateFromClient() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { contactId: string; clientId: number }) =>
      apiNew.patch<never, void>(`${CONTACT_PATH}/${params.contactId}/dissociate/client/${params.clientId}`),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
    },
  });
}

export function useContactDissociateFromQuote() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { contactId: string; quoteId: number }) =>
      apiNew.patch<never, void>(`${CONTACT_PATH}/${params.contactId}/dissociate/quote/${params.quoteId}`),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
      toast.success(t('contacts:card.toastDissociateSuccess'));
    },
  });
}

export function useContactDissociateFromProject() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { contactId: string; projectId: number }) =>
      apiNew.patch<never, void>(`${CONTACT_PATH}/${params.contactId}/dissociate/project/${params.projectId}`),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
      toast.success(t('contacts:card.toastDissociateSuccess'));
    },
  });
}

export function useContactDissociateAllFromQuote() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { quoteId: number }) =>
      apiNew.patch<never, void>(`${CONTACT_PATH}/dissociate/many/quote/${params.quoteId}`),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
    },
  });
}

export function useContactDissociateAllFromProjectLinkedToClient() {
  const toast = useToast();
  const { t } = useTranslation(['project']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { projectId: number; clientId: number }) =>
      apiNew.patch<never, void>(
        `${CONTACT_PATH}/dissociate/many/project/${params.projectId}/client/${params.clientId}`,
      ),
    onError: () => {
      toast.error(t('project:flow.client.toast.errorLink'));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
    },
  });
}

export function useContactDissociateFromSupplier() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { contactId: string; supplierId: number }) =>
      apiNew.patch<never, void>(`${CONTACT_PATH}/${params.contactId}/dissociate/supplier/${params.supplierId}`),
    onError: () => {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    },
    onSuccess: async () => {
      await invalidAllContactQueries(queryClient);
    },
  });
}
