import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleNewQuoteIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 14 14" fill="none" strokeWidth="2" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.63998 10.2233L3.16797 8.63936H3.83205L4.36005 10.2233L5.94403 10.7513V11.4154L4.36005 11.9434L3.83205 13.5274H3.16797L2.63998 11.9434L1.056 11.4154V10.7513L2.63998 10.2233Z"
      fill="currentColor"
    />
    <path
      d="M7.17781 1.75004C7.14155 1.74998 7.10441 1.75 7.06631 1.75002L7.01065 1.75004H7.17781Z"
      fill="currentColor"
    />
    <path
      d="M7.00001 1.75004L5.69259 1.75004C5.22302 1.75003 4.83548 1.75002 4.51978 1.77582C4.19189 1.80261 3.89048 1.8601 3.60737 2.00435C3.16832 2.22806 2.81137 2.58501 2.58766 3.02406C2.44341 3.30717 2.38592 3.60858 2.35913 3.93647C2.33333 4.25217 2.33334 4.63971 2.33335 5.10927V7.58337H4.37501L4.95835 9.33337L7.00001 9.9167V12.25H8.30745C8.77701 12.25 9.16455 12.25 9.48024 12.2243C9.80813 12.1975 10.1095 12.14 10.3927 11.9957C10.8317 11.772 11.1887 11.4151 11.4124 10.976C11.5566 10.6929 11.6141 10.3915 11.6409 10.0636C11.6667 9.74791 11.6667 9.36037 11.6667 8.89081V6.4167H7.58334C7.26118 6.4167 7.00001 6.15553 7.00001 5.83337V1.75004Z"
      fill="currentColor"
    />
    <path
      d="M11.6667 6.40983L11.6667 6.3504C11.6667 6.31203 11.6667 6.27461 11.6667 6.23809V6.40983Z"
      fill="currentColor"
    />
    <path
      d="M11.5512 5.25004C11.4947 5.07713 11.4181 4.91121 11.3228 4.75575C11.1778 4.51908 10.9798 4.32127 10.7178 4.05947L9.35725 2.69896C9.09545 2.43691 8.89764 2.23891 8.66097 2.09388C8.5055 1.99861 8.33959 1.922 8.16668 1.86549V5.25004H11.5512Z"
      fill="currentColor"
    />
  </Icon>
);
