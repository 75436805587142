import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import type { QuoteStatus } from '@graneet/business-logic';

import { QUOTE_STATUS_COLOR } from '../../constants/quotes.constant';

export type QuotationStatusBadgeProps = {
  /**
   * Quote status displayed by the badge
   */
  status: QuoteStatus;
};

export const QuotationStatusBadge: FC<QuotationStatusBadgeProps> = ({ status }) => {
  const { t } = useTranslation(['quote']);

  return (
    <Badge w="auto" minWidth="5.25rem" px={3} colorScheme={QUOTE_STATUS_COLOR[status]}>
      {t(`quote:statuses.${status}`)}
    </Badge>
  );
};
