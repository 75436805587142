import type { FC, ReactNode } from 'react';
import { createContext } from 'react';
import type { GridItemProps } from '@chakra-ui/react';
import { GridItem } from '@chakra-ui/react';

export const DataTableHeaderContext = createContext({
  isInHeader: false,
});

export interface DataTableHeaderProps {
  label: ReactNode;

  children: ReactNode;
}

const contextValue = {
  isInHeader: true,
};

export const dataTableHeaderStyle: GridItemProps = {
  color: 'gray.700',
  fontWeight: 700,
  borderBottomColor: 'gray.200',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  pb: 2,
};

export const DataTableHeader: FC<DataTableHeaderProps> = ({ label, children }) => (
  <DataTableHeaderContext.Provider value={contextValue}>
    <GridItem {...dataTableHeaderStyle}>{label}</GridItem>

    {children}
  </DataTableHeaderContext.Provider>
);
