import type { RawExtended } from '../common/entity.type';
import type { ValueOf } from '../common/types/object.type';
import type { IContract } from '../contract/contract.type';

import type { IProgressStatement } from './progress-statement.type';

export const PROGRESS_STATEMENT_DISCOUNT_MODES = {
  MANUAL: 'MANUAL', // User input
  AUTO: 'AUTO', // Filled based on progress statement cumulative percentage
} as const;

export type ProgressStatementDiscountModes = ValueOf<typeof PROGRESS_STATEMENT_DISCOUNT_MODES>;

export interface IProgressStatementDiscountLine {
  id: number;

  mode: ProgressStatementDiscountModes;

  cumulativeProgressPercentage: number;

  cumulativeAmountExVAT: number;

  nonCumulativeAmountExVAT: number;

  progressStatement: IProgressStatement;

  contract: IContract;

  createdAt: Date;

  updatedAt: Date;
}

export type IProgressStatementDiscountLineToSave = RawExtended<IProgressStatementDiscountLine, 'progressStatement'>;
