import type { CellClassParams, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';

import { AmountCell } from '../../components/columns/AmountCell/AmountCell';
import { AmountEditorCell } from '../../components/columns/AmountCell/AmountEditorCell';
import type { AGColDef } from '../../types';

import { useQuoteLotUpdateAmount } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateAmount';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useAmountColumnDef(isEditable: boolean) {
  const quoteLotUpdateAmount = useQuoteLotUpdateAmount();
  const { quote } = useQuote();
  const { executeCommand } = useCommands();

  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'amount',
      width,
      index,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      headerName: label,
      editable: (params) => {
        if (!isEditable) return false;
        switch (params.data?.content.type) {
          case 'QuoteLot':
            return true;
          default:
            return false;
        }
      },
      cellClass: (params: CellClassParams) => {
        if (params.node.footer || params.data?.content.type !== 'QuoteLot') return 'no-focus read-only';
        return '';
      },
      suppressMovable: false,
      sortable: false,
      cellStyle: { textAlign: 'right' },
      cellRenderer: AmountCell,
      cellRendererParams: {
        mandatory: true,
      },
      cellEditor: AmountEditorCell,
      valueGetter: (params) => {
        if (params.data?.content.type === 'QuoteLot' && params.data?.content.isEveryChildOptional) {
          return params.data?.content.optionalAmount;
        }

        return params.data?.content.amount;
      },
      valueSetter: (params) => {
        const newValue = params.newValue.replace(',', '.').toString();
        const oldValue = params.oldValue?.toString();

        if (oldValue === newValue) {
          return false;
        }

        if (oldValue === '0' || newValue === '0') {
          return false;
        }

        switch (params.data?.content.type) {
          case 'QuoteLot': {
            executeCommand(quoteLotUpdateAmount(params.data.id, newValue), quote);
            return true;
          }
          default:
            return false;
        }
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [isEditable, executeCommand, quoteLotUpdateAmount, quote],
  );
}
