import type { ITimeTableRange, ABSENCE_DURATION } from '@graneet/lib-ui';
import { useToast, TimeTable } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { approveTimeSlots } from '../../services/time-slot.api';

import { TimeTrackingWeeklyRow } from './TimeTrackingWeeklyRow';

import { useTimeTrackingContext } from 'features/time-tracking/context/TimeTrackingContext';

interface TimeTrackingWeeklyProps {
  aggregatedRanges: Record<string, Record<string, ITimeTableRange[]>>;
  absenceDurationPossibleValues: Record<ABSENCE_DURATION, number>;
}

export const TimeTrackingWeekly: FC<TimeTrackingWeeklyProps> = ({
  aggregatedRanges,
  absenceDurationPossibleValues,
}) => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'timeTracking']);

  const {
    state: { filters, numberOfDays },
    weekData,
    handleDeleteSlots,
    handleEditSlot,
    handleCreateSlot,
    handleDateChange,
    refetch,
    fetch,
    mode,
    updateMode,
  } = useTimeTrackingContext();
  const { date, groupBy } = filters;

  const onColorChanged = useCallback(() => {
    fetch(filters);
    refetch();
  }, [fetch, filters, refetch]);

  const onApprove = useCallback(
    async (ids: number[]) => {
      const [error] = await approveTimeSlots(ids);

      if (error) {
        toast.error(t('global:words.c.error'));
        return;
      }

      await fetch(filters);
      await refetch();
      updateMode('default');
    },
    [fetch, filters, refetch, t, toast, updateMode],
  );

  return (
    <TimeTable
      startDate={date}
      numberOfDays={numberOfDays}
      onDelete={handleDeleteSlots}
      onEdit={handleEditSlot}
      onCreate={handleCreateSlot}
      onNextWeek={handleDateChange}
      onPreviousWeek={handleDateChange}
      mode={mode}
      onApprove={onApprove}
      absenceDurationPossibleValues={absenceDurationPossibleValues}
    >
      {((groupBy === 'project' ? weekData?.projects : weekData?.workers) ?? []).map((row) => {
        const rangesGroupedBy = Object.values(aggregatedRanges[row.id] ?? {});

        return (
          <TimeTrackingWeeklyRow
            key={row.id}
            row={row}
            groupBy={groupBy}
            onColorChanged={onColorChanged}
            ranges={rangesGroupedBy}
          />
        );
      })}
    </TimeTable>
  );
};
