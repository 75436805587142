import type { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { DisplayNotFoundScreen } from '../DisplayNotFoundScreen';

import { ClientsRouter } from './clients/ClientsRouter';
import { ContactsRouter as ContactManagementRouter } from './contacts/ContactsRouter';
import { SuppliersRouter } from './suppliers/SuppliersRouter';

export const ContactsRouter: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/contacts`}>
        <ContactManagementRouter />
      </Route>

      <Route path={`${path}/clients`}>
        <ClientsRouter />
      </Route>

      <Route path={`${path}/suppliers`}>
        <SuppliersRouter />
      </Route>

      <Route path="*">
        <DisplayNotFoundScreen />
      </Route>
    </Switch>
  );
};
