import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import type { FC } from 'react';

export const SuccessIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 281 197" {...props}>
    <svg width="281" height="197" viewBox="0 0 281 197" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.45"
        d="M140.022 191.874C202.975 191.874 254.009 184.577 254.009 175.575C254.009 166.573 202.975 159.275 140.022 159.275C77.0688 159.275 26.0352 166.573 26.0352 175.575C26.0352 184.577 77.0688 191.874 140.022 191.874Z"
        fill="#E6E6E6"
      />
      <path
        d="M61.4785 25.1602V40.4977H71.4567L73.6423 46.6011L76.223 40.4977H86.2012V25.1602H61.4785Z"
        fill="#E6E6E6"
      />
      <path
        opacity="0.12"
        d="M72.6332 36.0986C72.5228 36.0942 72.4146 36.0664 72.316 36.0171C72.2174 35.9678 72.1307 35.8982 72.0619 35.8129L69.4949 32.548C69.381 32.3903 69.3332 32.1954 69.3615 32.0038C69.3898 31.8122 69.4921 31.6389 69.647 31.5199C69.802 31.4009 69.9975 31.3454 70.1929 31.3651C70.3883 31.3849 70.5684 31.4782 70.6956 31.6257L72.716 34.1886L77.6843 28.8831C77.7544 28.8125 77.8382 28.7564 77.9306 28.7182C78.023 28.6799 78.1222 28.6602 78.2225 28.6602C78.3227 28.6602 78.422 28.6799 78.5144 28.7182C78.6068 28.7564 78.6906 28.8125 78.7607 28.8831C78.9013 29.0249 78.98 29.2154 78.98 29.4137C78.98 29.612 78.9013 29.8024 78.7607 29.9442L73.1632 35.8946C73.093 35.9615 73.01 36.014 72.919 36.049C72.828 36.0841 72.7309 36.1009 72.6332 36.0986Z"
        fill="black"
      />
      <path d="M190.342 5.03223H90.3574V173.278H190.342V5.03223Z" fill="#C9C9C9" />
      <path d="M181.372 25.1602H98.6719V154.243H181.372V25.1602Z" fill="white" />
      <path
        opacity="0.56"
        d="M140.569 170.215C144.134 170.215 147.024 167.325 147.024 163.761C147.024 160.196 144.134 157.307 140.569 157.307C137.005 157.307 134.115 160.196 134.115 163.761C134.115 167.325 137.005 170.215 140.569 170.215Z"
        fill="white"
      />
      <path opacity="0.12" d="M159.932 12.9082H131.928V17.0651H159.932V12.9082Z" fill="black" />
      <path
        d="M121.755 19.9096C124.232 19.9096 126.24 17.9016 126.24 15.4245C126.24 12.9475 124.232 10.9395 121.755 10.9395C119.278 10.9395 117.27 12.9475 117.27 15.4245C117.27 17.9016 119.278 19.9096 121.755 19.9096Z"
        fill="white"
      />
      <path
        opacity="0.08"
        d="M90.3729 116.214L90.8065 125.303L99.5633 123.478L103.291 107.627C103.993 104.637 105.601 101.939 107.894 99.9036C110.186 97.8684 113.05 96.5958 116.093 96.2609C122.765 95.5327 131.038 94.8464 136.317 95.2147C146.266 95.9094 167.283 93.5408 174.346 99.6003C181.41 105.66 184.216 109.612 186.185 120.66L181.81 151.181L190.342 156.541V116.615L187.949 86.0083L178.917 82.9199L146.942 87.6822L143.531 88.268L139.995 90.193L132.489 86.4267L89.2637 84.6106L90.3729 116.214Z"
        fill="black"
      />
      <path
        d="M79.9792 71.7617L136.303 84.581L135.274 89.3946C135.274 89.3946 107.113 88.6805 86.0665 92.7548C65.0204 96.8291 79.9792 71.7617 79.9792 71.7617Z"
        fill="#109CF1"
      />
      <path
        opacity="0.08"
        d="M79.9792 71.7617L136.303 84.581L135.274 89.3946C135.274 89.3946 107.113 88.6805 86.0665 92.7548C65.0204 96.8291 79.9792 71.7617 79.9792 71.7617Z"
        fill="black"
      />
      <path
        d="M78.7953 58.9787C79.0435 60.8177 79.0435 62.6823 78.7953 64.5212C78.7005 64.9549 78.4418 65.3338 78.0747 65.5769C77.7075 65.8199 77.261 65.9078 76.8306 65.8218C76.1349 65.7182 75.4839 65.4124 74.9563 64.9417C74.4288 64.4709 74.0474 63.8554 73.8584 63.1696L72.7418 60.2964C72.5801 59.6981 72.6055 59.0638 72.8145 58.4808C73.0235 57.8978 73.4058 57.3946 73.9088 57.0406C75.6804 55.6125 78.6189 56.9556 78.7953 58.9787Z"
        fill="#F4A28C"
      />
      <path d="M72.9681 60.8223L71.7617 71.5104L77.6689 71.7615L76.1713 63.8772L72.9681 60.8223Z" fill="#F4A28C" />
      <path
        d="M78.9294 61.041L79.9583 62.2914C80.0158 62.3626 80.0539 62.4467 80.0691 62.5358C80.0842 62.6249 80.076 62.7162 80.0451 62.8014C80.0142 62.8867 79.9616 62.963 79.8922 63.0236C79.8228 63.0841 79.7388 63.1268 79.6479 63.1479L78.3262 63.4476L78.9294 61.041Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.31"
        d="M76.5624 65.5801C75.8205 65.4299 75.14 65.0689 74.6055 64.542C74.8965 66.0371 75.7571 67.3672 77.0121 68.2613L76.5624 65.5801Z"
        fill="#CE8172"
      />
      <path
        d="M84.1359 72.7113C84.1359 72.7113 58.7224 67.0444 49.6223 72.1287C40.5222 77.2131 58.3884 125.144 58.3884 125.144L86.4484 124.262C86.4484 124.262 110.994 77.5793 84.1359 72.7113Z"
        fill="#109CF1"
      />
      <path
        d="M78.4724 170.215C78.4724 170.215 79.7836 172.655 82.0865 172.473C84.3895 172.292 85.356 174.481 83.3977 175.415C81.4393 176.35 75.7492 174.55 75.7492 174.55L75.2617 170.224L78.4724 170.215Z"
        fill="#109CF1"
      />
      <path
        d="M57.9684 169.813C57.9684 169.813 57.0547 172.349 54.7992 172.552C52.5437 172.755 51.9263 174.995 53.976 175.596C56.0257 176.196 61.2611 173.567 61.2611 173.567L61.1047 169.34L57.9684 169.813Z"
        fill="#109CF1"
      />
      <path
        opacity="0.08"
        d="M62.1995 76.5742C62.1995 76.5742 70.2433 81.759 66.1461 93.6016C62.049 105.444 61.3885 113.933 71.046 110.962C80.7035 107.991 79.9927 94.7335 79.9927 94.7335L89.8426 96.2481L90.7958 115.065L86.615 124.269L62.1995 122.738L52.2577 120.241L48.5703 107.808L55.3264 86.7773L62.1995 76.5742Z"
        fill="black"
      />
      <path
        d="M86.4206 124.341L79.2667 170.215H74.5916C74.5916 170.215 78.9256 135.712 71.364 131.553C63.8024 127.394 62.3134 170.215 62.3134 170.215L57.3223 169.815L57.3722 122.52L86.4206 124.341Z"
        fill="#24285B"
      />
      <path
        d="M57.1435 70.5311C57.1435 70.5311 41.3496 67.8689 41.3496 92.7298C41.3496 117.591 45.1458 139.723 81.606 117.549L78.836 113.318C78.836 113.318 59.1959 121.964 55.0159 114.369C50.8359 106.775 71.4188 74.7038 57.1435 70.5311Z"
        fill="#109CF1"
      />
      <path
        opacity="0.46"
        d="M57.1435 70.5311C57.1435 70.5311 41.3496 67.8689 41.3496 92.7298C41.3496 117.591 45.1458 139.723 81.606 117.549L78.836 113.318C78.836 113.318 59.1959 121.964 55.0159 114.369C50.8359 106.775 71.4188 74.7038 57.1435 70.5311Z"
        fill="white"
      />
      <path d="M81.0567 112.688L94.4316 116.051L99.4672 96.0458L86.0923 92.6835L81.0567 112.688Z" fill="#FFD200" />
      <path
        d="M78.2118 59.1651C77.9666 59.2435 77.7166 59.3066 77.4631 59.3539C77.1077 59.4326 76.7611 59.5453 76.4284 59.6905L76.1592 59.8054C75.9682 59.8871 75.8058 60.0216 75.6922 60.1923C75.5786 60.3629 75.5187 60.5623 75.5198 60.7658C75.5608 61.1584 75.5173 61.5551 75.3921 61.9303C75.2669 62.3055 75.0627 62.651 74.7926 62.9447C74.5225 63.2384 74.1924 63.4738 73.8237 63.6358C73.4549 63.7978 73.0555 63.8827 72.6511 63.8853C72.6511 63.8853 70.0516 60.1009 72.1295 57.1949C72.7378 56.3908 73.5226 55.7294 74.4265 55.2593C75.3304 54.7892 76.3305 54.5222 77.3538 54.4777C77.9394 54.4708 78.5085 54.667 78.9598 55.0312C79.4111 55.3954 79.7153 55.904 79.8183 56.4666C79.9213 57.0292 79.8163 57.6091 79.5222 58.1033C79.228 58.5974 78.7637 58.9737 78.2118 59.1651Z"
        fill="#24285B"
      />
      <path
        d="M75.5207 62.5673C75.6121 62.4532 75.671 62.3171 75.6915 62.1731C75.7119 62.0291 75.6931 61.8824 75.637 61.7479C75.4542 61.3218 75.0969 60.7482 74.4985 60.8302C73.9002 60.9121 73.7755 61.879 74.1993 62.469C74.2769 62.5692 74.3769 62.6504 74.4914 62.7063C74.606 62.7622 74.7322 62.7913 74.86 62.7913C74.9879 62.7913 75.114 62.7622 75.2286 62.7063C75.3432 62.6504 75.4431 62.5692 75.5207 62.469V62.5673Z"
        fill="#F4A28C"
      />
      <path
        d="M136.058 85.3262L138.187 85.9823C138.772 86.1557 139.301 86.4891 139.716 86.9468C140.131 87.4046 140.418 87.9695 140.545 88.5815C140.774 89.7254 140.791 90.9788 139.915 91.3573C138.195 92.0891 135.428 88.3459 135.428 88.3459L136.058 85.3262Z"
        fill="#F4A28C"
      />
      <path
        d="M140.022 76.5747C149.326 76.5747 156.869 69.0323 156.869 59.7283C156.869 50.4242 149.326 42.8818 140.022 42.8818C130.718 42.8818 123.176 50.4242 123.176 59.7283C123.176 69.0323 130.718 76.5747 140.022 76.5747Z"
        fill="#109CF1"
      />
      <path
        d="M133.678 60.0045L137.787 66.5106L146.148 54.915"
        stroke="white"
        strokeWidth="1.11033"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.5742 70.9323C69.5742 70.9323 69.9445 66.9078 74.884 68.3566C79.8234 69.8054 80.0759 71.7613 80.0759 71.7613L69.5742 70.9323Z"
        fill="#109CF1"
      />
      <path
        opacity="0.46"
        d="M69.5742 70.9323C69.5742 70.9323 69.9445 66.9078 74.884 68.3566C79.8234 69.8054 80.0759 71.7613 80.0759 71.7613L69.5742 70.9323Z"
        fill="white"
      />
      <path opacity="0.34" d="M154.244 126.677H116.613V130.615H154.244V126.677Z" fill="#C9C9C9" />
      <path opacity="0.34" d="M154.244 120.55H116.613V124.488H154.244V120.55Z" fill="#C9C9C9" />
      <path opacity="0.34" d="M154.244 114.425H116.613V118.363H154.244V114.425Z" fill="#C9C9C9" />
      <path opacity="0.34" d="M154.244 108.299H116.613V112.237H154.244V108.299Z" fill="#C9C9C9" />
      <path d="M154.244 132.802H116.613V136.74H154.244V132.802Z" fill="#FFD200" />
      <path opacity="0.34" d="M164.745 126.677H156.432V130.615H164.745V126.677Z" fill="#C9C9C9" />
      <path opacity="0.34" d="M154.244 138.929H116.613V142.867H154.244V138.929Z" fill="#C9C9C9" />
      <path opacity="0.34" d="M164.745 120.55H156.432V124.488H164.745V120.55Z" fill="#C9C9C9" />
      <path opacity="0.34" d="M164.745 114.425H156.432V118.363H164.745V114.425Z" fill="#C9C9C9" />
      <path opacity="0.34" d="M164.745 108.299H156.432V112.237H164.745V108.299Z" fill="#C9C9C9" />
      <path d="M164.745 132.802H156.432V136.74H164.745V132.802Z" fill="#FFD200" />
      <path opacity="0.34" d="M164.745 138.929H156.432V142.867H164.745V138.929Z" fill="#C9C9C9" />
      <path
        d="M79.4199 114.203C79.4199 114.203 82.146 110.088 84.7107 112.167C87.2754 114.246 81.008 116.612 81.008 116.612L79.4199 114.203Z"
        fill="#F4A28C"
      />
      <path d="M187.526 132.697L184.884 171.175L187.337 172.034L197.117 136.028L187.526 132.697Z" fill="#F4A28C" />
      <path
        d="M184.975 170.618C184.975 170.618 183.367 172.322 181.529 172.071C179.69 171.819 179.187 174.41 182.186 174.396C183.123 174.402 184.058 174.324 184.982 174.166C185.829 174.048 186.691 174.154 187.484 174.473C187.64 174.522 187.807 174.524 187.963 174.476C188.119 174.429 188.257 174.335 188.358 174.208C189.288 173.293 187.415 170.828 187.415 170.828L184.975 170.618Z"
        fill="#109CF1"
      />
      <path d="M211.108 132.697L213.75 171.175L211.296 172.034L201.517 136.028L211.108 132.697Z" fill="#F4A28C" />
      <path
        d="M213.664 170.618C213.664 170.618 215.265 172.322 217.111 172.071C218.956 171.819 219.446 174.41 216.412 174.396C215.475 174.401 214.539 174.324 213.615 174.166C212.771 174.047 211.91 174.153 211.12 174.473C210.963 174.523 210.795 174.525 210.638 174.477C210.481 174.43 210.342 174.336 210.239 174.208C209.309 173.293 211.183 170.828 211.183 170.828L213.664 170.618Z"
        fill="#109CF1"
      />
      <path d="M202.892 66.7762L203.605 70.9103L207.338 71.1827L205.066 64.5625L202.892 66.7762Z" fill="#F4A28C" />
      <path
        d="M221.43 77.2509C226.428 81.5038 232.782 89.3879 235.166 98.2916C235.933 101.145 235.691 104.175 234.481 106.871C233.271 109.567 231.168 111.763 228.525 113.089L212.146 121.322L210.049 118.396L221.332 109.151C221.948 108.648 222.458 108.03 222.834 107.33C223.21 106.63 223.444 105.862 223.522 105.072C223.599 104.282 223.52 103.484 223.288 102.724C223.057 101.964 222.677 101.258 222.171 100.645C218.466 96.1617 213.362 89.0317 212.146 82.5722C211.935 81.4188 212.087 80.2283 212.581 79.1647C213.075 78.1011 213.887 77.2166 214.905 76.633C215.923 76.0494 217.097 75.7955 218.265 75.9062C219.434 76.0168 220.539 76.4867 221.43 77.2509Z"
        fill="#F4A28C"
      />
      <path
        d="M204.199 60.6578C203.873 60.4019 203.489 60.2312 203.081 60.161C202.673 60.0909 202.254 60.1235 201.862 60.2559C201.47 60.3884 201.117 60.6165 200.835 60.9195C200.554 61.2226 200.352 61.5911 200.249 61.9916C199.746 63.891 199.396 66.6634 201.598 66.8799C204.967 67.2291 205.555 62.8924 205.555 62.8924C205.329 62.0318 204.858 61.2553 204.199 60.6578Z"
        fill="#F4A28C"
      />
      <path
        d="M190.65 76.2509C194.736 73.2732 199.642 71.6271 204.698 71.5371C209.755 71.4471 214.716 72.9176 218.906 75.7481C222.632 78.283 225.498 82.033 224.184 87.5428C221.108 100.399 211.251 108.87 210.944 120.169L187.602 118.605V104.051C187.61 102.676 187.332 101.315 186.785 100.052C186.239 98.7904 185.436 97.6552 184.428 96.7189C182.387 94.8474 180.367 91.7887 181.751 87.5987C182.981 83.8836 186.169 79.54 190.65 76.2509Z"
        fill="#109CF1"
      />
      <path
        d="M203.549 63.7801C203.549 63.7801 203.898 62.9142 204.584 63.5077C205.269 64.1013 204.353 65.072 203.815 64.9044C203.276 64.7368 203.549 63.7801 203.549 63.7801Z"
        fill="#F4A28C"
      />
      <path
        d="M212.587 73.1724C211.469 71.7758 208.295 69.6808 208.68 63.298C208.763 61.9433 208.071 59.1569 206.219 57.8301C201.787 54.6597 198.334 57.9209 199.871 60.1136C201.409 62.3064 202.262 63.298 202.262 63.298C202.262 63.298 196.942 72.2646 199.871 75.868C202.479 79.0593 217.222 79.0593 212.587 73.1724Z"
        fill="#24285B"
      />
      <path d="M211.964 114.22H187.602L183.729 147.9L216.025 157.516L211.964 114.22Z" fill="#24285B" />
      <path
        d="M197.353 77.291C197.122 76.5935 196.75 75.9595 196.259 75.4256C195.768 74.8917 195.168 74.4687 194.494 74.1809C193.82 73.8932 193.085 73.7465 192.332 73.7494C191.579 73.7523 190.822 73.9048 190.107 74.1979C188.702 74.7973 187.336 75.4972 186.02 76.2917C178.108 80.9859 162.92 87.6402 148.624 85.9904C148.624 85.9904 143.771 84.7616 141.893 88.1505C140.014 91.5394 144.646 92.6225 146.632 89.3093C146.632 89.3093 172.97 100.507 194.315 85.9068C195.694 84.9458 196.744 83.5736 197.296 82.0102C197.848 80.4468 197.87 78.7829 197.359 77.2853L197.353 77.291Z"
        fill="#F4A28C"
      />
    </svg>
  </Icon>
);
