import type { FC } from 'react';
import { usePlaceholder, useWizardContext, VALIDATION_OUTCOME } from 'graneet-form';

import { ModalSecondaryButton } from '../Modal/ModalSecondaryButton';
import type { ButtonProps } from '../Button/Button';

export interface StepperModalSecondaryButtonProps extends ButtonProps {}

export const StepperModalSecondaryButton: FC<StepperModalSecondaryButtonProps> = ({
  isDisabled,
  children,
  ...props
}) => {
  const { handleOnPrevious, isStepReady } = useWizardContext();
  const { stepStatus, isLoading } = usePlaceholder();

  const displayIsNotReady = isLoading || !isStepReady || stepStatus === VALIDATION_OUTCOME.UNDETERMINED;

  return (
    <ModalSecondaryButton onClick={handleOnPrevious} {...props} isDisabled={isDisabled || displayIsNotReady}>
      {children}
    </ModalSecondaryButton>
  );
};
