import { HStack, VStack } from '@chakra-ui/react';
import { CardLineEnum, GenericCard, MaskEnum, Modal, SimpleEqualIcon, useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { QuoteSellSheetLotMarginCard } from '../cards/QuoteSellSheetLotMarginCard';
import { QuoteSellSheetGlobalMarginCard } from '../cards/QuoteSellSheetGlobalMarginCard';

import { AgGridQuoteComponentsByType } from 'features/quotation/ag-grid-quote-components-by-type/components/AgGridQuoteComponentsByType';
import { useQuoteGetFromStoreOrThrow } from 'features/quotation/quote-common/hooks/useQuoteGetFromStoreOrThrow';
import { useStore } from 'store/store';

interface MargeByComponentsTypeModalProps {
  nodeId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const MargeByComponentsTypeModal = ({ nodeId, isOpen, onClose }: MargeByComponentsTypeModalProps) => {
  const { t } = useTranslation(['quote']);
  const { mapStringNumberToAmount } = useCurrency();

  const getQuote = useQuoteGetFromStoreOrThrow();
  const quote = useMemo(() => getQuote(), [getQuote]);
  const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
  const isRootNode = nodeId === quote.getTree().getRootNodeId();

  const editorRef = useStore((state) => state.quoteRichTextEditorRef);

  const getPlainText = useCallback(
    (v: string | null | undefined) =>
      editorRef?.current?.getPlainText ? (editorRef?.current?.getPlainText(v) ?? null) : null,
    [editorRef],
  );

  const title = useMemo(() => {
    if (isRootNode) {
      return '';
    }
    const denomination = getPlainText(quote.getTree().getNodeOrNull(nodeId)?.getContent().getDenomination());
    if (denomination) {
      return ` - ${denomination}`;
    }
    return '';
  }, [getPlainText, isRootNode, nodeId, quote]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${t('quote:drawer.sale.quoteSellSheetLotMarginCard.componentMargin')}${title}`}
      size="6xl"
      noFooter
    >
      <HStack height="25rem" gap={0} alignItems="start" mb={-2} mx={-6} borderTop="1px solid #E5E7EB">
        <AgGridQuoteComponentsByType quoteLot={quoteLot} />
        <VStack
          gap="0.75rem"
          alignItems="start"
          justifyItems="start"
          alignContent="start"
          height="100%"
          width="20rem"
          p="0.75rem"
          borderLeft="1px solid #E5E7EB"
        >
          {isRootNode ? <QuoteSellSheetGlobalMarginCard /> : <QuoteSellSheetLotMarginCard nodeId={nodeId} />}
          <GenericCard
            title={t('quote:drawer.sale.quoteSellSheetSummaryCard.title')}
            groups={[
              {
                items: [
                  {
                    type: CardLineEnum.ITEM,
                    name: t('quote:drawer.sale.quoteSellSheetSummaryCard.title'),
                    label: t('quote:drawer.sale.quoteSellSheetSummaryCard.total'),
                    icon: <SimpleEqualIcon />,
                    mask: MaskEnum.CURRENCY,
                    value: mapStringNumberToAmount(quoteLot.getAmount()?.toString() ?? ''),
                    isEditable: false,
                    onBlur: undefined,
                    isBold: true,
                  },
                ],
              },
            ]}
          />
        </VStack>
      </HStack>
    </Modal>
  );
};
