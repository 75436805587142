import { useMemo } from 'react';
import type { ComputedValuesFns, IInitialTree } from '@graneet/lib-ui';
import { useLeaf, useNode, useNodeComputedValue, useTree, useTreeContext } from '@graneet/lib-ui';
import type { IQuoteJob, IQuoteLot, RequiredByKeys } from '@graneet/business-logic';

export type TreeExportQuoteJob = RequiredByKeys<IQuoteJob, 'margin' | 'components'> & {
  isSelected: boolean;
  workForceQuantity: number | undefined;
};

interface QuoteJobComputedValue {}

interface QuoteLotComputedValue {
  disbursementExVAT: number;
  amountExVAT: number;
  numberOfJobs: number;
  numberOfUnselectedJobs: number;
  numberOfUnselectedNoneOptionalJobs: number;
  workForceQuantity: number;
  optionaWorkForceQuantity: number;
  optionalDisbursementExVAT: number;
  optionalAmountExVAT: number;
}

export const useQuoteExportTree = (initialTree: IInitialTree<IQuoteLot, TreeExportQuoteJob>) => {
  const computedValuesFns = useMemo<
    ComputedValuesFns<IQuoteLot, TreeExportQuoteJob, QuoteLotComputedValue, QuoteJobComputedValue>
  >(
    () => ({
      computeNodeComputedValue: (quoteLot, treeContext) => {
        const displayedTree = treeContext.getDisplayedCurrentTree();
        const directChildren = displayedTree.relations[quoteLot.id];

        const computedValues = treeContext.getComputedValues();

        let numberOfUnselectedJobs = 0;
        let numberOfUnselectedNoneOptionalJobs = 0;
        let numberOfJobs = 0;
        let disbursementExVAT = 0;
        let amountExVAT = 0;
        let workForceQuantity = 0;
        let optionaWorkForceQuantity = 0;
        let optionalAmountExVAT = 0;
        let optionalDisbursementExVAT = 0;

        directChildren.nodes.forEach((nodeId) => {
          numberOfUnselectedJobs += computedValues.nodes[nodeId].numberOfUnselectedJobs;
          numberOfUnselectedNoneOptionalJobs += computedValues.nodes[nodeId].numberOfUnselectedNoneOptionalJobs;
          numberOfJobs += computedValues.nodes[nodeId].numberOfJobs;

          amountExVAT += computedValues.nodes[nodeId].amountExVAT;
          disbursementExVAT += computedValues.nodes[nodeId].disbursementExVAT;
          workForceQuantity += computedValues.nodes[nodeId].workForceQuantity;

          optionalAmountExVAT += computedValues.nodes[nodeId].optionalAmountExVAT;
          optionalDisbursementExVAT += computedValues.nodes[nodeId].optionalDisbursementExVAT;
          optionaWorkForceQuantity += computedValues.nodes[nodeId].optionaWorkForceQuantity;
        });

        directChildren.leaves.forEach((leaveId) => {
          if (displayedTree.leaves[leaveId].isHiddenCost) {
            return;
          }
          const isCurrentLeafOptional = displayedTree.leaves[leaveId].isOptional;
          const isLeafSelected = displayedTree.leaves[leaveId].isSelected;
          numberOfUnselectedJobs += isLeafSelected ? 0 : 1;
          numberOfUnselectedNoneOptionalJobs += isLeafSelected || isCurrentLeafOptional ? 0 : 1;
          numberOfJobs += 1;

          if (isCurrentLeafOptional) {
            optionalAmountExVAT += isLeafSelected ? displayedTree.leaves[leaveId].amountExVAT : 0;
            optionalDisbursementExVAT += isLeafSelected ? displayedTree.leaves[leaveId].disbursementExVAT : 0;
            optionaWorkForceQuantity += isLeafSelected ? displayedTree.leaves[leaveId].workForceQuantity || 0 : 0;
          } else {
            amountExVAT += isLeafSelected ? displayedTree.leaves[leaveId].amountExVAT : 0;
            disbursementExVAT += isLeafSelected ? displayedTree.leaves[leaveId].disbursementExVAT : 0;
            workForceQuantity += isLeafSelected ? displayedTree.leaves[leaveId].workForceQuantity || 0 : 0;
          }
        });

        return {
          numberOfUnselectedJobs,
          numberOfUnselectedNoneOptionalJobs,
          numberOfJobs,
          disbursementExVAT,
          amountExVAT,
          workForceQuantity,
          optionaWorkForceQuantity,
          optionalAmountExVAT,
          optionalDisbursementExVAT,
        };
      },
      computeLeafComputedValue: () => ({}),
    }),
    [],
  );

  return useTree<IQuoteLot, TreeExportQuoteJob, QuoteLotComputedValue, QuoteJobComputedValue>(
    initialTree,
    computedValuesFns,
  );
};

export const useQuoteExportTreeContext = () =>
  useTreeContext<IQuoteLot, TreeExportQuoteJob, QuoteLotComputedValue, QuoteJobComputedValue>();

export const useQuoteExportJob = (id: number) => useLeaf<IQuoteLot, TreeExportQuoteJob>(id);
export const useQuoteExportLot = (id: number) => useNode<IQuoteLot, TreeExportQuoteJob>(id);

export const useQuoteExportLotComputedValue = (id: number) =>
  useNodeComputedValue<IQuoteLot, QuoteLotComputedValue>(id);
