import type { ICompany } from '../company/company.type';
import type { IReminder } from '../reminder/reminder.type';

export const EMAIL_TEMPLATE_TYPE = ['quote', 'statement', 'order'] as const;
export type EmailTemplateType = (typeof EMAIL_TEMPLATE_TYPE)[number];

export const EMAIL_TEMPLATE_CLIENT_VARIABLE = ['client:enterpriseName'] as const;
export type EmailTemplateClientVariable = (typeof EMAIL_TEMPLATE_CLIENT_VARIABLE)[number];

export const EMAIL_TEMPLATE_PROJECT_VARIABLE = [
  'project:name',
  'project:refCode',
  'project:purchaseOrderNumber',
  'project:address',
  'project:startDate',
  'project:endDate',
] as const;
export type EmailTemplateProjectVariable = (typeof EMAIL_TEMPLATE_PROJECT_VARIABLE)[number];

export const EMAIL_TEMPLATE_SUPPLIER_VARIABLE = ['supplier:name'] as const;
export type EmailTemplateSupplierVariable = (typeof EMAIL_TEMPLATE_SUPPLIER_VARIABLE)[number];

export const EMAIL_TEMPLATE_QUOTE_VARIABLE = [
  'quote:name',
  'quote:refCode',
  'quote:date',
  'quote:endDate',
  'quote:amountExVAT',
  'quote:amountIncVAT',
  'quote:validityDuration',
] as const;
export type EmailTemplateQuoteVariable = (typeof EMAIL_TEMPLATE_QUOTE_VARIABLE)[number];

export const EMAIL_TEMPLATE_STATEMENT_VARIABLE = [
  'statement:name',
  'statement:refCode',
  'statement:billingDate',
  'statement:paymentDeadline',
  'statement:amountExVAT',
  'statement:amountIncVAT',
  'statement:totalToBePaidIncVAT',
  'statement:remainingToBePaidIncVAT',
  'statement:externalProgressStatementLink',
] as const;
export type EmailTemplateStatementVariable = (typeof EMAIL_TEMPLATE_STATEMENT_VARIABLE)[number];

export const EMAIL_TEMPLATE_ORDER_VARIABLE = [
  'order:name',
  'order:orderNumber',
  'order:orderDate',
  'order:deliveryDate',
  'order:amountExVAT',
  'order:amountIncVAT',
  'order:deliveryAddress',
] as const;
export type EmailTemplateOrderVariable = (typeof EMAIL_TEMPLATE_ORDER_VARIABLE)[number];

export type EmailTemplateAllQuoteVariable =
  | EmailTemplateQuoteVariable
  | EmailTemplateClientVariable
  | EmailTemplateProjectVariable;
export type EmailTemplateAllStatementVariable =
  | EmailTemplateStatementVariable
  | EmailTemplateClientVariable
  | EmailTemplateProjectVariable;
export type EmailTemplateAllOrderVariable =
  | EmailTemplateOrderVariable
  | EmailTemplateSupplierVariable
  | EmailTemplateProjectVariable;

export type EmailTemplateVariable =
  | EmailTemplateAllQuoteVariable
  | EmailTemplateAllStatementVariable
  | EmailTemplateAllOrderVariable;

export interface IEmailTemplate {
  id: string;

  title: string;

  // Lexical JSON export
  subject: string;

  subjectHTML: string;

  // Lexical JSON export
  template: string;

  templateHTML: string;

  type: EmailTemplateType;

  isDefault: boolean;

  company?: ICompany;

  reminders?: IReminder[];

  createdAt: Date;

  updatedAt: Date;
}
