import { Form, useForm, useFormStatus } from 'graneet-form';
import {
  Modal,
  MultipleFileUploadField,
  RICH_TEXT_BLOCK_TOOLBAR_PRESET,
  RichTextContextProvider,
  RichTextField,
  SwitchField,
  useCurrency,
  useRichText,
  useToast,
} from '@graneet/lib-ui';
import type { EmailAutocompleteContact, FileItem, Response, FileWithOptions } from '@graneet/lib-ui';
import {
  banishedExtensions,
  buildFullName,
  EMAIL_RECIPIENT,
  MAX_TOTAL_UPLOAD_SIZE_BYTES,
  mergeLexicalToLexical,
  getEmailTemplateVariables,
} from '@graneet/business-logic';
import type { EmailTemplateData, IMailingSendWithSenderAndRecipientDTO, IEmailTemplate } from '@graneet/business-logic';
import type { FC } from 'react';
import { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { Box, Divider, Stack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getMailingInitialValues } from '../../services/mailing.api';

import { EmailRecipientsFieldGroup } from './EmailRecipientsFieldGroup';
import type { MailingFormValues } from './mailing.form';
import { MailingModalEmailTemplateMenu } from './MailingModalEmailTemplateMenu';
import { MailingModalSenderField } from './MailingModalSenderField';

import { formatOptionsFileData, getTotalSize } from 'features/file/services/file.util';
import { useEmail } from 'features/history/contexts/EmailContext';
import { getRichTextPluginOption } from 'features/email-template/services/email-template.util';
import { useEmailTemplates } from 'features/email-template/services/email-template.api';
import { useData } from 'features/api/hooks/useData';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { Error } from 'features/common/components/Error';
import { Loading } from 'features/common/components/Loading';
import { getPreviewUrl } from 'features/pdf/services/pdf.api';
import { useContactAssociatedToProject, useContactsWithoutPagination } from 'features/contact/services/contact.api';
import { Rule } from 'features/form/rules/Rule';
import { EmailVariableRichSelect } from 'features/email-template/components/EmailVariableRichSelect';

export type MailingModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: {
    dto: IMailingSendWithSenderAndRecipientDTO;
    attachments: FileWithOptions[];
    mode: 'template' | 'manual';
  }) => Promise<Response<unknown>>;
  variableData: EmailTemplateData;
  defaultMailContacts?: EmailAutocompleteContact[];
  defaultEmailTemplate?: IEmailTemplate;
};

export const MailingModal: FC<MailingModalProps> = ({
  title,
  isOpen,
  onClose,
  variableData,
  onSubmit,
  defaultMailContacts,
  defaultEmailTemplate: defaultEmailTemplateProps,
}) => {
  const { handleEmailSent } = useEmail();
  const toast = useToast();
  const { t } = useTranslation(['global', 'mailing']);
  const form = useForm<MailingFormValues>();
  const [mailLoading, setMailLoading] = useState(false);
  const { isValid } = useFormStatus(form);

  const variableDataType = variableData.type === 'quotation' ? 'quote' : variableData.type;

  const subjectRichTextContext = useRichText();
  const bodyRichTextContext = useRichText();

  const companyContacts = useContactsWithoutPagination();
  const projectContacts = useContactAssociatedToProject(variableData.project?.id);
  const {
    data: initialData,
    loading: isLoadingMailingInitialData,
    error: isErrorMailingInitialData,
  } = useData(
    useDataGetter(getMailingInitialValues, { entityId: variableData.entityId, entityType: variableData.entityType }),
  );
  const emailTemplates = useEmailTemplates({
    type: variableDataType,
  });

  const initialValues = useMemo(() => {
    if (!initialData || !emailTemplates.data) return null;

    const defaultEmailTemplate =
      defaultEmailTemplateProps ?? emailTemplates.data.find((emailTemplate) => emailTemplate.isDefault);

    const formValues: Partial<MailingFormValues> = {
      senderFullName: initialData.sender.fullName,
      senderEmail: initialData.sender.replyTo,
      subject: defaultEmailTemplate ? defaultEmailTemplate.subject : '',
      mailingReceiveCopy: initialData.receiveCopy,
      // eslint-disable-next-line no-nested-ternary
      body: defaultEmailTemplate?.template
        ? initialData.body
          ? JSON.stringify(
              mergeLexicalToLexical(JSON.parse(defaultEmailTemplate.template), JSON.parse(initialData.body)),
            )
          : defaultEmailTemplate.template
        : initialData.body,
      to: defaultMailContacts,
    };

    if (initialData.defaultAttachment) {
      formValues.attachments = [
        formatOptionsFileData(initialData.defaultAttachment, '0', () =>
          getPreviewUrl(initialData.defaultAttachment.apiId),
        ),
      ];
    }

    return formValues;
  }, [initialData, emailTemplates.data, defaultEmailTemplateProps, defaultMailContacts]);

  const checkBeforeUpload = useCallback(
    (newFiles: File[]) => {
      // Files must not be from the list of banished extensions
      const hasBanishedExtension = newFiles.some((file) => {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();

        return !fileExtension || banishedExtensions.includes(fileExtension);
      });

      if (hasBanishedExtension) {
        toast.error(t('mailing:modal.check.type'));

        return false;
      }

      // The total of uploaded files size (beside the first default one) must not exceed max value
      const allFilesExceptFirst = (form.getFormValues().attachments || []).concat(
        newFiles.map((nf) => ({ file: nf, readOnly: false })),
      );
      const totalSize = getTotalSize(allFilesExceptFirst);

      if (totalSize > MAX_TOTAL_UPLOAD_SIZE_BYTES) {
        toast.error(t('mailing:modal.check.size', { maxSizeMb: MAX_TOTAL_UPLOAD_SIZE_BYTES / 1e6 }));

        return false;
      }

      return true;
    },
    [form, t, toast],
  );

  const handleAttachmentOpen = useCallback(
    (index: string) => {
      const file = (form.getFormValues().attachments || [])[parseInt(index, 10)];
      const fileUrl = file.customFile ? (file.file as FileItem).url : URL.createObjectURL(file.file as File);
      window.open(fileUrl, '_blank');
    },
    [form],
  );

  const emailModeRef = useRef<'template' | 'manual'>('template');
  const isEmailRichTextFocusedRef = useRef(false);
  useEffect(() => {
    const updateEmailMode = () => {
      if (isEmailRichTextFocusedRef.current) {
        emailModeRef.current = 'manual';
      }
    };
    document.addEventListener('keydown', updateEmailMode);
    return () => {
      isEmailRichTextFocusedRef.current = false;
      document.removeEventListener('keydown', updateEmailMode);
    };
  }, []);
  const switchIsEmailRichTextFocused = useCallback(() => {
    isEmailRichTextFocusedRef.current = !isEmailRichTextFocusedRef.current;
  }, []);

  const handleClose = useCallback(() => {
    isEmailRichTextFocusedRef.current = false;
    emailModeRef.current = 'template';
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    const formValues = form.getFormValues();

    const html = await bodyRichTextContext.exportHtml();

    const areEmailsUsedUniquely = () => {
      const allEmails = [...(formValues.to || []), ...(formValues.cc || []), ...(formValues.bcc || [])].map(
        ({ email }) => email,
      );
      return new Set(allEmails).size === allEmails.length;
    };
    if (!areEmailsUsedUniquely()) {
      toast.error(t('mailing:modal.check.uniqueEmails'));
      return;
    }

    // If the user sent the email to it own mail
    if (formValues.senderEmail && formValues.to?.some(({ email }) => email === formValues.senderEmail)) {
      toast.error(t('mailing:modal.check.cantSendToOwnEmail'));
      return;
    }

    const mapRecipientDTO = (recipient: EmailAutocompleteContact, type: EMAIL_RECIPIENT) => ({
      type,
      recipientDisplayName: buildFullName(recipient.firstName, recipient.lastName),
      recipientEmail: recipient.email,
    });

    const recipients = [
      ...(formValues.to || []).map((recipient) => mapRecipientDTO(recipient, EMAIL_RECIPIENT.TO)),
      ...(formValues.cc || []).map((recipient) => mapRecipientDTO(recipient, EMAIL_RECIPIENT.CC)),
      ...(formValues.bcc || []).map((recipient) => mapRecipientDTO(recipient, EMAIL_RECIPIENT.BCC)),
    ];

    const dto: IMailingSendWithSenderAndRecipientDTO = {
      subject: subjectRichTextContext.exportText(),
      sender: { fullName: formValues.senderFullName!, replyTo: formValues.senderEmail! },
      recipients,
      bodyHtml: html,
      bodyLexical: formValues.body!,
      entityId: variableData.entityId,
      entityType: variableData.entityType,
      receiveCopy: formValues.mailingReceiveCopy ? 'true' : 'false',
    };

    setMailLoading(true);

    const [errorSend] = await onSubmit({ dto, attachments: formValues.attachments || [], mode: emailModeRef.current });
    setMailLoading(false);

    if (!errorSend) {
      toast.success(t('mailing:modal.success'));
      handleClose();
      handleEmailSent();
    } else {
      toast.error(t('mailing:modal.error'));
    }

    form.resetForm();
    emailModeRef.current = 'template';
    if (initialValues) {
      form.setFormValues(initialValues);
    }
  }, [
    form,
    bodyRichTextContext,
    subjectRichTextContext,
    variableData.entityId,
    variableData.entityType,
    onSubmit,
    initialValues,
    toast,
    t,
    handleClose,
    handleEmailSent,
  ]);

  useEffect(() => {
    if (!initialValues) {
      form.setFormValues({
        mailingReceiveCopy: false,
      });
      return;
    }

    emailModeRef.current = 'template';
    form.resetForm();
    form.setFormValues(initialValues);
  }, [companyContacts.data, form, initialValues]);

  const baseModalProps = {
    isOpen,
    onClose: handleClose,
    size: '3xl' as const,
    scrollBehavior: 'inside' as const,
  };

  const { formatAsAmount } = useCurrency();

  if (isLoadingMailingInitialData) {
    return (
      <Modal {...baseModalProps}>
        <Loading />
      </Modal>
    );
  }

  if (isErrorMailingInitialData) {
    return (
      <Modal {...baseModalProps}>
        <Error />
      </Modal>
    );
  }

  const variableValues = getEmailTemplateVariables(variableData, formatAsAmount);

  return (
    <Form form={form}>
      <Modal
        {...baseModalProps}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        autoFocus={false}
        title={title}
        footerContent={
          <>
            <SwitchField<MailingFormValues>
              name="mailingReceiveCopy"
              label={t('mailing:modal.copy')}
              checkedValue
              uncheckedValue={false}
            />

            <Box w="fit-content">
              <MailingModalEmailTemplateMenu emailTemplates={emailTemplates.data} defaultBody={initialData.body} />
            </Box>
          </>
        }
      >
        <Modal.Close />

        <Stack>
          <VStack alignItems="flex-start" width="100%" gap={3}>
            <MailingModalSenderField />
            <EmailRecipientsFieldGroup
              projectContacts={projectContacts.data}
              companyContacts={companyContacts.data.data}
              variableData={variableData}
            />
          </VStack>

          <RichTextContextProvider value={subjectRichTextContext}>
            <RichTextField<MailingFormValues>
              noBorder
              name="subject"
              placeholder={t('mailing:modal.subject')}
              shouldDisplayError={false}
              configuration={[
                { name: 'max-length', maxLength: 1 },
                getRichTextPluginOption(variableDataType, t, {
                  injectValueInLabel: true,
                  values: variableValues,
                }),
              ]}
              navbarType="none"
              // Remove rich text borders
              inputProps={{
                fontWeight: 500,
                fontSize: 'sm',
              }}
              toolbarContent={[
                <EmailVariableRichSelect key={EmailVariableRichSelect.name} type={variableDataType} />,
                <Rule.IsRichTextVariablesValid key={Rule.IsRichTextVariablesValid.name} />,
              ]}
              hideErrorMessage
            >
              <Rule.IsRequired message={t('mailing:modal.check.subjectIsRequired')} />
            </RichTextField>
          </RichTextContextProvider>

          <RichTextContextProvider value={bodyRichTextContext}>
            <RichTextField<MailingFormValues>
              noBorder
              name="body"
              navbarType="block"
              onFocus={switchIsEmailRichTextFocused}
              onBlur={switchIsEmailRichTextFocused}
              configuration={[
                ...RICH_TEXT_BLOCK_TOOLBAR_PRESET,
                getRichTextPluginOption(variableDataType, t, {
                  injectValueInLabel: true,
                  values: variableValues,
                }),
              ]}
              // Remove rich text borders
              inputProps={{
                minH: '10rem',
                fontWeight: 500,
                fontSize: 'sm',
              }}
              toolbarContent={[
                <EmailVariableRichSelect key={EmailVariableRichSelect.name} type={variableDataType} hasTrackingId />,
                <Rule.IsRichTextVariablesValid key={Rule.IsRichTextVariablesValid.name} />,
              ]}
              hideErrorMessage
            />
          </RichTextContextProvider>

          <Divider my={2} />

          <MultipleFileUploadField<MailingFormValues>
            name="attachments"
            onFileOpen={handleAttachmentOpen}
            checkBeforeUpload={checkBeforeUpload}
          />

          <Divider my={2} />
        </Stack>

        <Modal.PrimaryButton onClick={handleSubmit} isDisabled={!isValid || mailLoading} isLoading={mailLoading}>
          {t('mailing:modal.send')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
