import type { FC } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';

export const TemplateIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 22 22" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M12.8327 2.08057V5.86689C12.8327 6.38027 12.8327 6.63697 12.9326 6.83305C13.0205 7.00553 13.1607 7.14576 13.3332 7.23365C13.5293 7.33356 13.7859 7.33356 14.2994 7.33356H18.0856M12.8327 15.5835H7.33268M14.666 11.9168H7.33268M18.3327 9.15603V15.7668C18.3327 17.307 18.3327 18.077 18.0329 18.6653C17.7693 19.1828 17.3486 19.6034 16.8312 19.8671C16.2429 20.1668 15.4729 20.1668 13.9327 20.1668H8.06602C6.52587 20.1668 5.7558 20.1668 5.16754 19.8671C4.65009 19.6034 4.2294 19.1828 3.96575 18.6653C3.66602 18.077 3.66602 17.307 3.66602 15.7668V6.2335C3.66602 4.69335 3.66602 3.92328 3.96575 3.33502C4.2294 2.81757 4.65009 2.39688 5.16754 2.13323C5.7558 1.8335 6.52587 1.8335 8.06602 1.8335H11.0102C11.6827 1.8335 12.0191 1.8335 12.3356 1.90948C12.6162 1.97684 12.8844 2.08795 13.1305 2.23874C13.408 2.4088 13.6458 2.64661 14.1214 3.12223L17.0439 6.04476C17.5196 6.52039 17.7574 6.7582 17.9274 7.03572C18.0782 7.28176 18.1893 7.55001 18.2567 7.8306C18.3327 8.1471 18.3327 8.48341 18.3327 9.15603Z"
        stroke="#102A43"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
