import { PROGRESS_STATEMENT_STATUSES } from '@graneet/business-logic';
import type { ButtonColorScheme } from '@graneet/lib-ui';

export const PROGRESS_STATEMENT_STATUS_COLORS: Record<PROGRESS_STATEMENT_STATUSES, ButtonColorScheme> = {
  [PROGRESS_STATEMENT_STATUSES.DRAFT]: 'gray',
  [PROGRESS_STATEMENT_STATUSES.ACCEPTED]: 'green',
  [PROGRESS_STATEMENT_STATUSES.VALIDATED]: 'blue',
  [PROGRESS_STATEMENT_STATUSES.COMPLETED]: 'green',
  [PROGRESS_STATEMENT_STATUSES.CANCELLED]: 'red',
};

export const URL_INDEXES_PRICE_REVISION =
  'https://www.ffbatiment.fr/federation-francaise-du-batiment/le-batiment-et-vous/en_chiffres/indices-index/Chiffres_IndexBT.html';

export const PROGRESS_STATEMENT_FIELDS = {
  INVOICE_NUMBER: 'invoiceNumber',
  NAME: 'name',
  AMOUNT_EX_VAT: 'amountExVAT',
  BILLING_DATE: 'billingDate',
  CUMULATIVE_PROGRESS_PERCENTAGE: 'cumulativeProgressPercentage',
  CUMULATIVE_PROGRESS_QUANTITY: 'cumulativeProgressQuantity',
  CUMULATIVE_AMOUNT_EX_VAT: 'cumulativeAmountExVAT',
  STATUS: 'status',
  DOWN_PAYMENT_AMORTIZATION_AMOUNT: 'downPaymentAmortizationAmount',
  GLOBAL_PERCENTAGE: 'globalPercentage',
  CUMULATIVE_INPUT_TYPE: 'cumulativeInputType',
  ORDER_SUPPLIER_INVOICES: 'ordersSupplierInvoices',
};

// - Steps

export const PROGRESS_STATEMENT_STEPS = {
  ITEMS: 'items',
  PRICE_REVISION: 'priceRevision',
  DIRECT_PAYMENTS: 'directPayments',
  DOWN_PAYMENT: 'downPayment',
  INFORMATION: 'information',
  VALIDATION: 'validation',
};
