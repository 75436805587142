import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { compact } from 'lodash-es';

import {
  getDeleteAction,
  getDownloadPDFAction,
  getEditAction,
  getOpenMailingAction,
  getPreviewPDFAction,
  getValidateAction,
} from './progress-statement.actions.util';
import type { ProgressStatementActionsParams } from './progress-statement.actions.type';

export function getProgressStatementDraftActions(params: ProgressStatementActionsParams): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  const editAction = getEditAction(params);

  actions.push({ type: 'primary', ...getValidateAction(params) });

  if (editAction) {
    actions.push({
      type: 'secondary',
      ...editAction,
    });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([
      getPreviewPDFAction(params),
      getOpenMailingAction(params),
      getDownloadPDFAction(params),
      getDeleteAction(params),
    ]),
  });

  return actions;
}
