import type { MultiValueGenericProps } from 'chakra-react-select';
import { components } from 'chakra-react-select';
import { Box } from '@chakra-ui/react';

import type { EmailAutocompleteContactOption } from '../EmailAutocomplete.type';
import { Tooltip } from '../../Tooltip';

export const MultiValueLabel = (props: MultiValueGenericProps<EmailAutocompleteContactOption>) => {
  const { data } = props;

  return (
    <Tooltip label={data.contact.email}>
      <Box cursor="default">
        <components.MultiValueLabel {...props} />
      </Box>
    </Tooltip>
  );
};
