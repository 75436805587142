import { Flex, Text } from '@chakra-ui/react';
import { BlueCircledPlus, Button, Modal, BillSituation, useToast } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import type { IOrderWithRelations, ISupplierInvoice } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { useSupplierInvoiceUpdate } from '../../../services/supplier-invoice.api';

import { OrderPickerDetail } from './OrderPickerDetail';

import { usePermissions } from 'features/role/hooks/usePermissions';

const Prompt: FC<{ onPick(): void; onCreate(): void }> = ({ onPick, onCreate }) => {
  const { t } = useTranslation(['supplierInvoices']);

  return (
    <>
      <Text>{t('supplierInvoices:associate.pickOrCreate.body.prompt')}</Text>

      <Flex flexDir="row" paddingBottom="1rem" paddingTop="1rem">
        <Flex flexDir="column" flex={1} alignItems="center" padding="1rem" onClick={onPick}>
          <BillSituation height="5rem" margin="1rem" width="auto" padding=".5rem" />
          <Button>{t('supplierInvoices:associate.pickOrCreate.pick')}</Button>
        </Flex>

        <Flex
          flexDir="column"
          flex={1}
          alignItems="center"
          padding="1rem"
          bg="gray.100"
          borderRadius=".3rem"
          onClick={onCreate}
        >
          <BlueCircledPlus height="5rem" margin="1rem" width="auto" />
          <Button>{t('supplierInvoices:associate.pickOrCreate.create')}</Button>
        </Flex>
      </Flex>
    </>
  );
};

interface PickOrCreateOrderModalProps {
  isOpen: boolean;
  onClose(): void;
  supplierInvoice: ISupplierInvoice;
}

export const PickOrCreateOrderModal: FC<PickOrCreateOrderModalProps> = ({ isOpen, onClose, supplierInvoice }) => {
  const { t } = useTranslation(['supplierInvoices', 'global']);

  const history = useHistory();

  const toast = useToast();
  const { projectId } = useParams<{ projectId?: string }>();

  const [step, setStep] = useState('prompt');
  const [isValid, setValid] = useState(false);

  const [selectedRows, setSelectedRows] = useState<IOrderWithRelations[]>([]);

  const canCreateOrder = usePermissions([PERMISSION.CREATE_ORDER]);

  const supplierInvoiceUpdateMutation = useSupplierInvoiceUpdate();

  useEffect(() => {
    if (!isOpen) {
      setStep(canCreateOrder ? 'prompt' : 'pick');
      setValid(false);
      setSelectedRows([]);
    }
  }, [canCreateOrder, isOpen]);

  const onSubmit = useCallback(() => {
    supplierInvoiceUpdateMutation.mutate(
      {
        id: supplierInvoice.id,
        dto: {
          associatedOrders: JSON.stringify(selectedRows.map((o) => o.id)),
        },
      },
      {
        onSuccess: (si) => {
          toast.success(t('supplierInvoices:associate.associateSuccess', { invoiceName: si.name }));
          onClose();
        },
      },
    );
  }, [supplierInvoiceUpdateMutation, supplierInvoice.id, selectedRows, toast, t, onClose]);

  const onPick = useCallback(() => setStep('pick'), []);

  const onCreate = useCallback(() => {
    history.push(`/purchases/orders/create`, { supplierInvoice, projectId });
  }, [history, supplierInvoice, projectId]);

  const onGoBack = useCallback(() => {
    if (canCreateOrder) {
      setStep('prompt');
    } else {
      onClose();
    }
  }, [canCreateOrder, onClose]);

  const modalPropsOnFF =
    step === 'pick'
      ? {
          size: '6xl',
          fullHeight: true,
        }
      : {
          size: '3xl',
        };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      {...modalPropsOnFF}
      title={t('supplierInvoices:associate.pickOrCreate.title')}
      noFooter={step === 'prompt'}
    >
      {step === 'prompt' ? (
        <Prompt onPick={onPick} onCreate={onCreate} />
      ) : (
        <OrderPickerDetail supplierInvoice={supplierInvoice} onRowSelected={setSelectedRows} />
      )}

      {step === 'prompt' ? null : (
        <>
          <Modal.SecondaryButton onClick={onGoBack}>{t('global:words.c.back')}</Modal.SecondaryButton>

          <Modal.PrimaryButton isDisabled={!isValid && selectedRows.length === 0} onClick={onSubmit}>
            {t('supplierInvoices:associate.associateCta', { count: selectedRows.length })}
          </Modal.PrimaryButton>
        </>
      )}
    </Modal>
  );
};
