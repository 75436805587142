import type { ImageListAndUploadHandle } from '@graneet/lib-ui';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { RefObject } from 'react';
import { useCallback } from 'react';

import { useStore } from 'store/store';

export function useQuoteResetStore() {
  return useCallback(() => {
    useStore.setState(() => ({
      quote: null,
      quoteTree: null,
      quoteNode: [],
      quoteProject: null,
      quoteClient: null,
      quoteContacts: null,
      quoteNodeHiddenCost: [],
      quoteCustomDiscount: null,
      quoteSalesDiscount: null,
      quoteNbSelectedRows: 0,
      quoteGridRef: null,
      quoteHiddenCostGridRef: null,
      quoteSelectedRows: new Map<string, QuoteNodeObject>(),
      quoteHiddenCostNbSelectedRows: 0,
      quoteHiddenCostSelectedRows: new Map<string, QuoteNodeObject>(),
      quoteExpandedRows: [],
      quoteCollapsedRows: [],
      quoteImageUploadRefs: new Map<string, RefObject<ImageListAndUploadHandle>>(),
      quoteEditable: false,
      quoteNewlyCreatedNodeId: null,
      quoteMembers: [],
      quoteNotesModal: { open: false, note: null, nodeId: null },
      quoteLotDefaultVatRateModal: { isOpen: false, nodeId: null },
      quoteDrawerRef: null,
      quoteAmountIsUnreachable: false,
      quoteAreAllRowsCollapsed: false,
    }));
  }, []);
}
