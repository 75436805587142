import { ActionMenu, MinusCercle, SimpleUnlockIcon, Table } from '@graneet/lib-ui';
import type { IContact } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import { ContactDetails } from 'features/contact/components/ContactDetails';

interface ClientCardLineProps<T extends IContact> {
  contact: T;
  onUpsert: (contact: IContact) => void;
  onDelete: (contact: IContact) => void;
  onDissociate: (contactId: string) => void;
  onDefaultEmailRecipientChange?: (contactId: string, isDefaultEmailRecipient: boolean) => void;
  isContactDefaultEmailRecipient: (contact: T) => boolean;
}

export const ContactCardLine = <T extends IContact>({
  contact,
  isContactDefaultEmailRecipient,
  onUpsert,
  onDelete,
  onDissociate,
  onDefaultEmailRecipientChange,
}: ClientCardLineProps<T>) => {
  const { t } = useTranslation(['contacts']);

  const isDefaultEmailRecipient = isContactDefaultEmailRecipient(contact);

  return (
    <Table.Row>
      <Table.Cell>
        <ContactDetails contact={contact} isContactDefaultEmailRecipient={isContactDefaultEmailRecipient} />
      </Table.Cell>
      <Table.Cell variant="ellipsis">{contact.email}</Table.Cell>
      <Table.Cell variant="ellipsis">{contact.phoneNumber}</Table.Cell>
      <Table.Cell>
        <ActionMenu>
          <ActionMenu.Edit onClick={() => onUpsert(contact)} />
          <ActionMenu.Action
            icon={<SimpleUnlockIcon />}
            label={t('contacts:card.dissociate')}
            onClick={() => onDissociate(contact.id)}
          />
          {isDefaultEmailRecipient && onDefaultEmailRecipientChange && (
            <ActionMenu.Action
              icon={<SimpleUnlockIcon />}
              label={t('contacts:card.removeDefaultEmailRecipient')}
              onClick={() => onDefaultEmailRecipientChange(contact.id, false)}
            />
          )}
          {!isDefaultEmailRecipient && onDefaultEmailRecipientChange && (
            <ActionMenu.Action
              tooltip={!contact.email ? t('contacts:tooltips.cannotHaveDefaultEmailRecipientWithoutEmail') : undefined}
              isDisabled={!contact.email}
              icon={<MinusCercle />}
              label={t('contacts:card.changeToDefaultEmailRecipient')}
              onClick={() => onDefaultEmailRecipientChange(contact.id, true)}
            />
          )}
          <ActionMenu.Delete onClick={() => onDelete(contact)} />
        </ActionMenu>
      </Table.Cell>
    </Table.Row>
  );
};
