import type { QuoteComponentUpsertDTO, QuoteComponentUpsertManyDTO, QuoteComponentsApi } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteComponentsProxyApi {
  private static quoteComponentsProxyApi: QuoteComponentsProxyApi;

  private readonly quoteComponentsApi: QuoteComponentsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteComponentsApi = externalApi.getQuoteComponentsApi();
  }

  public static getQuoteComponentsProxyApi(externalApi: ExternalApi): QuoteComponentsProxyApi {
    if (!this.quoteComponentsProxyApi) {
      QuoteComponentsProxyApi.quoteComponentsProxyApi = new QuoteComponentsProxyApi(externalApi);
    }
    return QuoteComponentsProxyApi.quoteComponentsProxyApi;
  }

  public async createQuoteComponent(quoteComponentCreateDTO: QuoteComponentUpsertDTO) {
    return proxyHelper(async () => this.quoteComponentsApi.createQuoteComponent(quoteComponentCreateDTO));
  }

  public async updateQuoteComponent(quoteComponentId: string, quoteComponentUpdateDTO: QuoteComponentUpsertDTO) {
    return proxyHelper(async () =>
      this.quoteComponentsApi.updateQuoteComponent(quoteComponentId, quoteComponentUpdateDTO),
    );
  }

  public async updateManyQuoteComponent(quoteComponentUpsertManyDTO: QuoteComponentUpsertManyDTO) {
    return proxyHelper(async () => this.quoteComponentsApi.updateManyQuoteComponent(quoteComponentUpsertManyDTO));
  }
}
