import type { FlexProps, StyleProps } from '@chakra-ui/react';
import { Flex, Box } from '@chakra-ui/react';
import { forwardRef, useMemo } from 'react';

import { formatPercentage } from '../../utils';
import { Tooltip } from '../Tooltip/Tooltip';

export interface ProgressProps extends FlexProps {
  value?: number;
  colors?: {
    progression: StyleProps['color'];
    number?: StyleProps['color'];
  };
  superiorToMaxColors?: {
    progression: StyleProps['color'];
    number: StyleProps['color'];
  };

  mode?: 'hover' | 'always';
}

export const Progress = forwardRef<HTMLDivElement, ProgressProps>(
  (
    { colors = { progression: 'greenBrand.light' }, superiorToMaxColors, value = 0, mode = 'always', ...props },
    ref,
  ) => {
    const { progression, number } = colors;
    const { progression: progressionSuperiorColor, number: numberSuperior } = superiorToMaxColors || {};

    const isValueSuperiorToFifty = useMemo(() => value >= 50, [value]);
    const isValueSuperiorToHundred = useMemo(() => value > 100, [value]);

    const numberColor = useMemo(() => {
      if (isValueSuperiorToHundred && numberSuperior) {
        return numberSuperior;
      }

      if (number) {
        return number;
      }

      return isValueSuperiorToFifty ? 'white' : progression;
    }, [isValueSuperiorToHundred, isValueSuperiorToFifty, number, numberSuperior, progression]);

    const backgroundColor = useMemo(() => {
      if (isValueSuperiorToHundred && progressionSuperiorColor) {
        return progressionSuperiorColor;
      }

      return progression;
    }, [isValueSuperiorToHundred, progression, progressionSuperiorColor]);

    const progressWidth = useMemo(() => {
      if (isValueSuperiorToHundred) {
        return 100;
      }

      if (value < 0) {
        return 0;
      }

      return value;
    }, [isValueSuperiorToHundred, value]);

    const formatedPercentage = formatPercentage(value, { maximumFractionDigits: 2 });

    return (
      <Flex {...props} w={props.w || '7.5rem'} h={props.h || 5} ref={ref}>
        <Tooltip label={mode === 'hover' ? formatedPercentage : undefined}>
          <Box w="100%" h="100%" borderRadius="6px" overflow="hidden" bg="greenBrand.background">
            <Box
              position="relative"
              w={`${progressWidth}%`}
              h="100%"
              aria-valuemax={100}
              aria-valuemin={0}
              aria-valuenow={value}
              role="progressbar"
              backgroundColor={backgroundColor}
            >
              <Flex
                alignItems="center"
                justifyContent="flex-end"
                position="absolute"
                left={isValueSuperiorToFifty ? 'inherit' : '100%'}
                right={isValueSuperiorToFifty ? 0 : 'inherit'}
                m={0}
                paddingX={2}
                height="100%"
                fontSize="0.625rem"
                color={numberColor}
                fontFamily="TWKLausanne700"
              >
                {mode === 'always' && formatedPercentage}
              </Flex>
            </Box>
          </Box>
        </Tooltip>
      </Flex>
    );
  },
);
