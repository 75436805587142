import { useCallback, useMemo, useRef, useState } from 'react';

import type { PaginatedStorage } from '../types/PaginatedStorage';

export const useStatePaginationStorage = (defaultFilters: Record<any, any> = {}): PaginatedStorage => {
  const query = useRef(new URLSearchParams(Object.entries(defaultFilters)));

  const [count, setCount] = useState(0);

  const forcePaginationRender = useCallback(() => {
    setCount((previous) => previous + 1);
  }, []);

  return useMemo(
    () => ({
      storage: query.current,
      forcePaginationRender,
    }),
    // count is added to force render when STORAGE_STRATEGY is STATE
    // Disable rule as we need count to update memoized value
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forcePaginationRender, count],
  );
};
