import type { ReactNode } from 'react';

import type { DrawersStackContextApi } from './useDrawersStack';
import { DrawersStackContext } from './useDrawersStack';
import { DrawersStackDrawer } from './DrawersStackDrawer';

export interface DrawersStackProps<Handle extends string> {
  drawersStack: DrawersStackContextApi<Handle>;

  children: ReactNode;
}

const DrawersStackComponent = <Handle extends string>({ drawersStack, children }: DrawersStackProps<Handle>) => (
  <DrawersStackContext.Provider value={drawersStack}>{children}</DrawersStackContext.Provider>
);

export const DrawersStack = Object.assign(DrawersStackComponent, {
  Drawer: DrawersStackDrawer,
});
