import type { IContractInfosItem, IContractInfosLot } from '@graneet/business-logic';

export enum CONTRACT_ENTITY_TYPES {
  LOT = 'LOT',
  ITEM = 'ITEM',
}

export const NEW_LOT_DATA: Partial<IContractInfosLot> = {
  note: undefined,
  description: '',
  code: '',
  totalAmountExVAT: 0,
  totalAmountIncVAT: 0,
  invoicedAmountExVAT: 0,
};

export const NEW_ITEM_DATA = (vatRate: number): Partial<IContractInfosItem> => ({
  code: '',
  note: undefined,
  description: '',
  unit: '',
  invoicedAmountExVAT: 0,
  totalExVAT: 0,
  totalIncVAT: 0,
  vatRate,
});

export const CONTRACT_FIELDS = {
  CODE: 'code',
  NAME: 'name',
  RECEPTION_DATE: 'receptionDate',
  TOTAL_AMOUNT_EX_VAT: 'totalAmountExVAT',
  HAS_DISCOUNT_BEEN_UPDATED: 'hasDiscountBeenUpdated',
  DISCOUNT_AMOUNT_EX_VAT: 'discountAmountExVAT',
  HAS_REVERSAL_OF_LIABILITY: 'hasReversalOfLiability',
} as const;
