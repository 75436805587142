/* eslint-disable no-bitwise */

export const LABELS_COLORS = {
  '#FFE6E3': {
    border: '#FFBBB4',
    text: '#AA1608',
  },
  '#EDF8FF': {
    border: '#B0E2FF',
    text: '#006DAA',
  },
  '#E0FEEE': {
    border: '#BBF3D5',
    text: '#1A9453',
  },
  '#F9FBFF': {
    border: '#D9E2EC',
    text: '#486581',
  },
  '#E0E8F9': {
    border: '#BED0F7',
    text: '#4055A8',
  },
  '#FFF1DB': {
    border: '#FFDCAF',
    text: '#B34B00',
  },
  '#F4EBFF': {
    border: '#7F56D9',
    text: '#7F56D9',
  },
  white: {
    border: '#D9E2EC',
    text: '#9DA4AE',
  },
};

export const generateColorFromString = (value: string): string => {
  const colors = Object.keys(LABELS_COLORS);
  const uniqNumberFromString = value
    .split('')
    .reduce(
      (hashCode: number, currentVal) => currentVal.charCodeAt(0) + (hashCode << 6) + (hashCode << 16) - hashCode,
      0,
    );
  const number = Math.abs(uniqNumberFromString) % colors.length;
  return colors[number];
};
