import type { FC } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { BuildingIcon } from '@graneet/lib-ui';
import type { IAddress, IAddressResponseDTO, ISupplierListingDTO, ISupplier } from '@graneet/business-logic';
import { SUPPLIER_TYPE } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

interface SupplierCardLineProps {
  supplier: ISupplierListingDTO | ISupplier;
}

export const SupplierCardLine: FC<SupplierCardLineProps> = ({ supplier }) => {
  const formatAddress = (address?: IAddress | IAddressResponseDTO | undefined) => {
    if (!address) return '';
    return [address.address, address.postalCode, address.city].filter(Boolean).join(', ');
  };

  const { t } = useTranslation(['clients']);

  const color = supplier.type === SUPPLIER_TYPE.SUPPLIER ? 'purple.100' : 'blue.100';

  return (
    <HStack spacing={3} py={1}>
      <Box
        backgroundColor={color}
        width="2.5rem"
        height="2.5rem"
        py={1}
        px={2}
        borderColor={color}
        borderWidth={1}
        borderRadius={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <BuildingIcon width="1.3rem" height="1.3rem" />
      </Box>
      <VStack spacing={1} align="flex-start">
        <HStack>
          <Text fontWeight="600" color="gray.800">
            {supplier.name}
          </Text>
          {supplier.code && (
            <Text
              textColor="gray.800"
              shadow="sm"
              py={1}
              px={2}
              borderColor="gray.100"
              borderWidth={1}
              borderRadius={5}
            >
              {supplier.code}
            </Text>
          )}
        </HStack>
        <HStack marginLeft="1">
          {!supplier.hasBillingAddress ? (
            <Text fontWeight="400">{formatAddress(supplier.address)}</Text>
          ) : (
            <VStack alignItems="start" gap={0}>
              <Text>{t('clients:card.billingAddress', { address: formatAddress(supplier.billingAddress!) })}</Text>
              <Text>{t('clients:card.headOfficeAddress', { address: formatAddress(supplier.address) })}</Text>
            </VStack>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};
