import { Box, Tooltip } from '@chakra-ui/react';

import { Multiplication } from '../Multiplication';
import { MultiplicationIcon } from '../Icons';

interface HiddenCostDisplayProps {
  hiddenCostAmount: number | string;
  toolTipsLabel: string;
  fieldLabel: string;
}

export const HiddenCostDisplay = ({ hiddenCostAmount, toolTipsLabel, fieldLabel }: HiddenCostDisplayProps) => (
  <>
    <Tooltip
      backgroundColor="white"
      borderRadius="md"
      label={
        <Box display="flex" gap={12} alignItems="center" py={1}>
          <Box py={1} px={3} fontSize="0.75rem" borderRadius="md" backgroundColor="pink.100" color="pink.700">
            {toolTipsLabel}
          </Box>
          <Box color="black">{hiddenCostAmount}</Box>
        </Box>
      }
    >
      <Box flexGrow={1} width="7.2rem">
        <Multiplication.Field
          label={fieldLabel}
          computedName="hiddenCostPercent"
          name="hiddenCostPercent"
          min={0}
          scale={3}
          isReadOnly
        />
      </Box>
    </Tooltip>
    <MultiplicationIcon boxSize={8} mx={2} mb={1} />
  </>
);
