import { MARGIN_COMPUTED_VALUE } from '@graneet/business-logic';

import { MARGIN_FIELD_NAME } from './margin.constants';

export const BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD = (componentId, field) => `component-${componentId}-${field}`;

export const BUILD_COMPUTED_COMPONENT_TYPE_FORM_FIELD = (componentId) => `component-${componentId}-computed`;

export const getMarginComputedValueFromFieldName = (computedField) =>
  computedField === MARGIN_FIELD_NAME.TOTAL_MARGIN ? MARGIN_COMPUTED_VALUE.TOTAL : MARGIN_COMPUTED_VALUE.PROFIT;

export const isCurrentFieldEdit = (currentEditField, componentId, field) =>
  currentEditField === BUILD_MARGIN_COMPONENT_TYPE_FORM_FIELD(componentId, field);
