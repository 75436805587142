import { Flex, HStack, Text } from '@chakra-ui/react';
import type { IClient, IClientResponseDTO } from '@graneet/business-logic';
import { Button, SimpleEditIcon, SimpleChangeIcon, Tooltip } from '@graneet/lib-ui';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ClientCardLine } from './ClientCardLine';

export enum ClientCardTypeEnum {
  EDIT = 'EDIT',
  CHANGE = 'CHANGE',
  SWITCH = 'SWITCH',
}

interface ClientCardDetailsProps {
  client: IClientResponseDTO | IClient;
  type: ClientCardTypeEnum | null;
  onClientCardButtonClicked?: () => void;
  children: ReactNode;
  isButtonDisable?: boolean;
}

export const ClientCardDetails: FC<ClientCardDetailsProps> = ({
  client,
  onClientCardButtonClicked,
  children,
  type,
  isButtonDisable = false,
}) => {
  const { t } = useTranslation(['clients']);

  return (
    <>
      {children}
      <Flex p={4}>
        <Text textColor="gray.800" fontWeight="500" fontSize="18px" alignItems="center">
          {t('clients:card.title')}
        </Text>
        {type === ClientCardTypeEnum.EDIT && (
          <Flex marginLeft="auto">
            <Button
              data-testid="button:client.edit"
              variant="outline"
              leftIcon={<SimpleEditIcon />}
              onClick={onClientCardButtonClicked}
              isDisabled={isButtonDisable}
            >
              {t('clients:editButton')}
            </Button>
          </Flex>
        )}
        {(type === ClientCardTypeEnum.CHANGE || type === ClientCardTypeEnum.SWITCH) && (
          <Flex marginLeft="auto">
            {isButtonDisable ? (
              <Tooltip placement="left" label={t('clients:changeButtonDisabledTooltip')}>
                <HStack>
                  <SimpleChangeIcon stroke="gray.300" />
                  <Text color="gray.300">{t('clients:changeButton')}</Text>
                </HStack>
              </Tooltip>
            ) : (
              <Button
                data-testid="button:client.change"
                variant="outline"
                leftIcon={<SimpleChangeIcon />}
                onClick={onClientCardButtonClicked}
              >
                {t('clients:changeButton')}
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      <Flex p={4} pt={2}>
        <ClientCardLine client={client} />
      </Flex>
    </>
  );
};
