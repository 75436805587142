import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const FileIcon: FC<IconProps> = ({ color = '#109CF1', ...otherProps }) => (
  <Icon viewBox="0 0 16 16" color={color} {...otherProps}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.83523 5.9V2.05L12.554 5.9H8.83523ZM4.10227 1C3.35176 1 2.75 1.623 2.75 2.4V13.6C2.75 13.9713 2.89247 14.3274 3.14607 14.5899C3.39967 14.8525 3.74363 15 4.10227 15H12.2159C12.5746 15 12.9185 14.8525 13.1721 14.5899C13.4257 14.3274 13.5682 13.9713 13.5682 13.6V5.2L9.51136 1H4.10227Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);
