import type { FC } from 'react';
import { useMemo } from 'react';
import { Box, Flex, Link, Progress, Text } from '@chakra-ui/react';
import type { IEmailTemplate } from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';
import { Trans, useTranslation } from 'react-i18next';

import { useEmailTemplateQuota } from '../hooks/useEmailTemplateQuota';

interface EmailTemplateQuotaBarProps {
  emailTemplates: IEmailTemplate[];
}

export const EmailTemplateQuotaBar: FC<EmailTemplateQuotaBarProps> = ({ emailTemplates }) => {
  const { t } = useTranslation(['mailing']);

  const emailTemplateQuota = useEmailTemplateQuota(emailTemplates);

  const progressColor = useMemo(() => {
    if (emailTemplateQuota.level === 'error') {
      return 'red';
    }
    if (emailTemplateQuota.level === 'warn') {
      return 'yellow';
    }
    return 'green';
  }, [emailTemplateQuota.level]);

  if (emailTemplateQuota.quota === null) {
    return null;
  }

  return (
    <Box w="100%">
      <Flex justifyContent="space-between">
        <Text>
          <Trans
            t={t}
            i18nKey="mailing:quota.currentNumber"
            values={{ count: emailTemplateQuota.numberOfEmailTemplates, quota: emailTemplateQuota.quota }}
          >
            <strong>displayed_data</strong>
          </Trans>
        </Text>

        <Text>
          <Trans
            t={t}
            i18nKey="mailing:quota.needMore"
            values={{ count: emailTemplateQuota.numberOfEmailTemplates, quota: emailTemplateQuota.quota }}
          >
            <Link color="greenBrand.light" href={`mailto:${SUPPORT_EMAIL}`}>
              displayed_data
            </Link>
          </Trans>
        </Text>
      </Flex>

      <Progress
        w="100%"
        size="sm"
        borderRadius="sm"
        mt={1}
        colorScheme={progressColor}
        bg="gray.300"
        value={emailTemplateQuota.numberOfEmailTemplates}
        max={emailTemplateQuota.quota}
      />
    </Box>
  );
};
