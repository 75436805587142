import { createQuoteComponentAddedCallback } from './createQuoteComponentAddedCallback';
import { createQuoteComponentDeletedCallback } from './createQuoteComponentDeletedCallback';
import { createQuoteComponentUpdatedCallback } from './createQuoteComponentUpdatedCallback';

import type { QuoteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';

export const quoteComponentCallbacks = (quoteId: String) => [
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteComponent:added',
    callback: (message: QuoteWsMessage) => createQuoteComponentAddedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteComponent:deleted',
    callback: (message: QuoteWsMessage) => createQuoteComponentDeletedCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'QuoteComponent:updated',
    callback: (message: QuoteWsMessage) => createQuoteComponentUpdatedCallback(message),
  },
];
