import type { FC } from 'react';
import { useMemo } from 'react';
import type { TextProps } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';

import type { PriceProps } from '../Formatting';
import { Price } from '../Formatting';
import { useCurrency } from '../Currency';

const TEXT_PROPS_NO_STYLES: TextProps = {};

type PriceAdvancedOwnProps = {
  isAmountColored?: boolean;

  isPositiveColored?: boolean;

  hasAmountEqualToZeroWarning?: boolean;
};

export type PriceAdvancedProps = PriceProps & PriceAdvancedOwnProps & TextProps;

export const PriceAdvanced: FC<PriceAdvancedProps> = ({
  amount = null,
  negative = false,
  sign = false,
  isAmountColored = false,
  isPositiveColored = false,
  hasAmountEqualToZeroWarning = false,
  ...textProps
}) => {
  const { symbol } = useCurrency();

  const signedAmount = (negative ? -1 : 1) * (amount ?? 1);
  const textStylesOverrides: TextProps = useMemo(() => {
    if (isPositiveColored && signedAmount > 0) return { color: 'green.600' };
    if (!isAmountColored) return TEXT_PROPS_NO_STYLES;
    if (signedAmount > 0) return hasAmountEqualToZeroWarning ? { color: 'red.600' } : { color: 'green.600' };
    if (signedAmount < 0) return { color: 'red.600' };
    if (signedAmount === 0 && hasAmountEqualToZeroWarning) return { color: 'green.600' };
    return TEXT_PROPS_NO_STYLES;
  }, [isPositiveColored, signedAmount, isAmountColored, hasAmountEqualToZeroWarning]);

  const isAmountDefined = Number.isFinite(parseFloat(`${amount}`));
  return (
    <Text {...textProps} {...textStylesOverrides}>
      {isAmountDefined ? <Price amount={amount} sign={sign} negative={negative} /> : `- ${symbol}`}
    </Text>
  );
};
