import { ContentTypeGuard } from '@org/quotation-lib';

import { quoteSetToStore } from '../store/quoteSetToStore';
import { StatusEnum, quoteClientRequestsSetToStore } from '../store/quoteClientRequestsSetToStore';
import { quoteGetFromStore } from '../store/quoteGetFromStore';

import type { QuoteFileDeleteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';

export const createQuoteFileDeleteCallback = (message: QuoteFileDeleteWsMessage) => {
  const quote = quoteGetFromStore();
  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();

  if (quote) {
    const hasClientRequest = quoteClientRequestsFromStore.find(
      (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
    );
    if (hasClientRequest === undefined) {
      const node = quote.getTree().getNodeOrThrow(message.data.nodeId);
      const content = node.getContent();
      if (
        ContentTypeGuard.isQuoteBasicItem(content) ||
        // ContentTypeGuard.isQuoteHiddenCostItem(content) || TODO LATER
        ContentTypeGuard.isQuoteOptionalItem(content) ||
        ContentTypeGuard.isQuoteSubItem(content)
      ) {
        content.deleteFile(message.data.fileId);
      }
      quoteSetToStore(quote);
    } else {
      quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
    }
  }
};
