import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import type { ModalProps } from '@graneet/lib-ui';
import { formatNumberToVatRate, formatVatRateToNumber, Modal, PercentageField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { Form, useForm, useFormContext, useFormStatus } from 'graneet-form';

import { useOrderTreeContext } from '../../../hooks/useOrderTree';
import type { OrderEditForm } from '../../../forms/order-edit-wizard';
import { getOrderItemFieldName } from '../../../forms/order-edit-wizard';

import { Rule } from 'features/form/rules/Rule';

interface OrderLotVatRateModalProps extends Omit<ModalProps, 'id' | 'children'> {
  orderLotId: string;
}

interface FormValues {
  vatRate: number;
}

export const OrderLotVatRateModal: FC<OrderLotVatRateModalProps> = ({ orderLotId, isOpen, onClose, ...props }) => {
  const { t } = useTranslation(['orders', 'global']);

  const parentForm = useFormContext<OrderEditForm>();

  const form = useForm<FormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  const { updateAllChildrenLeavesOfNode, getCurrentTree, findDescendingChildrenOfNode, updateNodeData } =
    useOrderTreeContext();

  const onVATChanged = useCallback(() => {
    const rawVatRate = form.getFormValues().vatRate;
    const formattedVatRate = formatVatRateToNumber(rawVatRate || 0);
    updateAllChildrenLeavesOfNode(orderLotId, { vatRate: formattedVatRate });
    updateNodeData(orderLotId, { defaultVatRate: formattedVatRate });

    const { leaves: items } = findDescendingChildrenOfNode(orderLotId);
    const newFormValues: Partial<OrderEditForm> = {};
    items.forEach((itemId) => {
      newFormValues[getOrderItemFieldName(itemId, 'vatRate')] = rawVatRate;
    });
    parentForm.setFormValues(newFormValues);

    onClose();
  }, [
    form,
    updateAllChildrenLeavesOfNode,
    orderLotId,
    updateNodeData,
    findDescendingChildrenOfNode,
    parentForm,
    onClose,
  ]);

  useEffect(() => {
    const currentTree = getCurrentTree();
    form.setFormValues({
      vatRate: formatNumberToVatRate(currentTree.nodes[orderLotId]?.defaultVatRate),
    });

    // do not remove `isOpen`, we need to refresh data on open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, orderLotId, isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Form form={form}>
      <Modal title={t('orders:editOrderVATRateModal.title')} isOpen={isOpen} onClose={onClose} {...props}>
        <Stack spacing={4}>
          <Text>{t('orders:editOrderVATRateModal.description')}</Text>

          <HStack spacing={4}>
            <Text>{t('orders:editOrderVATRateModal.label')}</Text>

            <Box w="10rem">
              <PercentageField<FormValues> name="vatRate" min={0} max={100}>
                <Rule.IsRequired />
              </PercentageField>
            </Box>
          </HStack>
        </Stack>

        <Modal.Close />

        <Modal.PrimaryButton onClick={onVATChanged} isDisabled={!isFormValid}>
          {t('global:words.c.apply')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
