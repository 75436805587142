import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FieldValues } from 'graneet-form';
import { Field, useFormContext, useOnChangeValues, VALIDATION_OUTCOME } from 'graneet-form';
import type { BoxProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  Center,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import type { Modifier, RangeModifier } from 'react-day-picker';
import { DateUtils } from 'react-day-picker';

import type { KeysMatching } from '../../../utils';
import { formatDateOrEmpty } from '../../../utils';
import {
  DAY_PICKER_POPPER_HEIGHT,
  DAY_PICKER_POPPER_WIDTH,
  NO_STYLE_BUTTON_PROPS,
  POPOVER_PROPS,
} from '../../../constants';
import { RichSelectContent } from '../../RichSelect/RichSelectContent';
import { BadgeFieldOption } from '../../Filters/shared/BadgeFieldOption';
import { DateRangePicker } from '../../DatePicker';
import { SimpleCalendarIcon, SimpleCloseIcon } from '../../Icons';
import { EllipsisText } from '../../EllipsisText';
import { FormGroup } from '../../FormGroup';

export type DateRangeValue =
  | {
      startDate: Date | null;
      endDate: Date | null;
    }
  | undefined;

interface DateRangeFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, DateRangeValue> = KeysMatching<T, DateRangeValue>,
> extends Omit<BoxProps, 'onChange'> {
  name: K;

  label: ReactNode;

  isDisabled?: boolean;

  children?: ReactNode;

  onChange?(newValue: T): void;
  noIcon?: boolean;
  noDisplaySelectedValue?: boolean;
  variant?: 'classic' | 'filter';
}

export const DateRangeField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, DateRangeValue> = KeysMatching<T, DateRangeValue>,
>({
  label,
  name,
  children,
  isDisabled = false,
  onChange = () => {},
  noIcon = false,
  noDisplaySelectedValue = false,
  variant = 'classic',
  ...props
}: DateRangeFieldProps<T, K>) => {
  const form = useFormContext<T>();
  const { setFormValues } = form;
  const watchedFields = useOnChangeValues(form, [name]);
  const { [name]: range } = watchedFields as Record<string, DateRangeValue>;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [hasBeenFocused, setHasBeenFocused] = useState(false);

  const [currentRange, setCurrentRange] = useState<{
    from: null | Date;
    to: null | Date;
    enteredTo: null | Date;
  }>({
    from: null,
    to: null,
    enteredTo: null,
  });

  useEffect(() => {
    if (range) {
      // Bind form updates to currentRange temporary state
      setCurrentRange({
        from: range.startDate,
        to: range.endDate,
        enteredTo: range.endDate,
      });
    }
  }, [range]);

  const isSelectingFirstDay = useCallback((from: Date | null, to: Date | null, day: Date) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }, []);

  const handleDayMouseEnter = useCallback(
    (day: Date) => {
      const { from, to } = currentRange;

      if (!isSelectingFirstDay(from, to, day)) {
        setCurrentRange({
          ...currentRange,
          enteredTo: day,
        });
      }
    },
    [setCurrentRange, currentRange, isSelectingFirstDay],
  );

  const isActive = isOpen || (range !== undefined && range.startDate !== null && range.endDate !== null);

  const selectedDate = useMemo(() => {
    if (range && range.startDate && range.endDate) {
      return !DateUtils.isSameDay(range.startDate, range.endDate)
        ? [formatDateOrEmpty(range.startDate), '-', formatDateOrEmpty(range.endDate)].join(' ')
        : formatDateOrEmpty(range.startDate);
    }
    return null;
  }, [range]);

  const onRender = useCallback<Parameters<typeof Field<T, K>>[0]['render']>(
    ({ onChange: onFormChange, onBlur, onFocus }, fieldRenderState) => {
      const handleOpen = () => {
        if (isDisabled) return;
        setHasBeenFocused(true);
        onFocus();
        onOpen();
      };

      const handleClose = () => {
        if (isDisabled) return;

        onClose();
        setCurrentRange({
          from: null,
          to: null,
          enteredTo: null,
        });
      };

      const onReset = () => {
        // Reset range
        setFormValues({
          [name]: {
            startDate: null,
            endDate: null,
          },
        } as any);
        onClose();
        onChange(undefined as any);
      };

      const handleDayClick = (day: Date) => {
        const { from: currentFrom, to } = currentRange;

        if (
          currentFrom &&
          to &&
          (DateUtils.isDayAfter(day, currentFrom) ||
            DateUtils.isDayBefore(day, to) ||
            DateUtils.isSameDay(currentFrom, to))
        ) {
          // New range
          setCurrentRange({
            from: day,
            to: null,
            enteredTo: null,
          });
          return;
        }

        if (isSelectingFirstDay(currentFrom, to, day)) {
          // Selecting first day (temporary state)
          setCurrentRange({
            ...currentRange,
            from: day,
          });
        } else {
          // Selecting end day (update form)
          onClose();
          onFormChange({
            startDate: currentRange.from,
            endDate: day,
          } as any);
          onBlur();
          onChange(undefined as any);
        }
      };

      const { from, enteredTo } = currentRange;
      const disabledDays: Modifier = { before: from! };
      const selectedDays: RangeModifier[] = [
        { from, to: null },
        { from, to: enteredTo },
      ];

      return (
        <FormGroup
          showError={hasBeenFocused && fieldRenderState.validationStatus.status === VALIDATION_OUTCOME.INVALID}
          errorMessage={fieldRenderState.validationStatus.message}
        >
          <Box
            w="fit-content"
            bg="white"
            borderWidth="1px"
            borderRadius="4px"
            boxShadow={isActive ? 'md' : 'none'}
            borderColor={isActive ? 'greenBrand.light' : 'gray.300'}
            {...props}
          >
            <Popover
              onClose={handleClose}
              isOpen={isOpen}
              onOpen={handleOpen}
              placement="bottom-start"
              strategy="fixed"
              gutter={0}
              isLazy
            >
              <PopoverTrigger>
                <Button h="32px" {...NO_STYLE_BUTTON_PROPS} px={3} role="group" isDisabled={isDisabled}>
                  <RichSelectContent
                    fontSize="sm"
                    text={label}
                    leftIcon={!noIcon ? <SimpleCalendarIcon boxSize={4} /> : undefined}
                  >
                    {selectedDate && !noDisplaySelectedValue && (
                      <>
                        {variant === 'classic' ? (
                          <BadgeFieldOption
                            isDisabled={isDisabled}
                            label={selectedDate}
                            colorScheme="gray"
                            onRemove={onReset}
                            ml={3}
                            mr={-2}
                            theme="ghost"
                          />
                        ) : (
                          <HStack gap={1} px={2} mr={-4} alignItems="center">
                            <EllipsisText maxWidth="200px" fontSize="sm" color="gray.600" fontWeight={500}>
                              {selectedDate}
                            </EllipsisText>
                            <SimpleCloseIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                onReset();
                              }}
                              stroke="gray.600"
                              boxSize={3}
                              _hover={{
                                cursor: 'pointer',
                                stroke: 'gray.800',
                              }}
                            />
                          </HStack>
                        )}
                      </>
                    )}
                  </RichSelectContent>
                </Button>
              </PopoverTrigger>
              {/*
                - "-0.125rem" is used to remove margin introduced by border width
                - Default size of Popover content is 318px, but it's too large for DatePicker,
                 so we overwrite with 18rem
              */}
              <PopoverContent
                mt={POPOVER_PROPS.mt}
                ml="-0.125rem"
                width={DAY_PICKER_POPPER_WIDTH}
                height={DAY_PICKER_POPPER_HEIGHT}
              >
                <PopoverBody>
                  <Center>
                    <DateRangePicker
                      initialMonth={range?.startDate ?? new Date()}
                      selectedDays={selectedDays}
                      disabledDays={disabledDays}
                      onDayClick={handleDayClick}
                      onDayMouseEnter={handleDayMouseEnter}
                    />
                  </Center>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        </FormGroup>
      );
    },
    [
      currentRange,
      hasBeenFocused,
      isActive,
      props,
      isOpen,
      isDisabled,
      label,
      noIcon,
      selectedDate,
      noDisplaySelectedValue,
      variant,
      range?.startDate,
      handleDayMouseEnter,
      onOpen,
      onClose,
      setFormValues,
      name,
      onChange,
      isSelectingFirstDay,
    ],
  );

  return (
    <Field<T, K> name={name} render={onRender}>
      {children}
    </Field>
  );
};
