import type { QuoteNodeObject } from '@org/quotation-lib';
import { useCallback } from 'react';
import type { IRowNode } from '@ag-grid-community/core';

import { getSibblingForMoveQuoteLotInsideQuoteLot } from '../utils/getSibblingForMoveQuoteLotInsideQuoteLot';

import { useQuoteNodeCanBeMoved } from './useQuoteNodeCanBeMoved';

export const useQuoteNodeCanBeMovedInside = () => {
  const quoteNodeCanBeMoved = useQuoteNodeCanBeMoved();

  return useCallback(
    (movingNode: IRowNode<QuoteNodeObject>, overNode: IRowNode<QuoteNodeObject>) => {
      const nodeToBeMovedId = movingNode.data?.id;
      const targetId = overNode.data?.id;

      if (!targetId || !nodeToBeMovedId) {
        return false;
      }

      if (movingNode.data?.content.type === 'QuoteLot') {
        const { newPrevSiblingId, newNextSiblingId } = getSibblingForMoveQuoteLotInsideQuoteLot(movingNode, overNode);
        return quoteNodeCanBeMoved(nodeToBeMovedId, targetId, newPrevSiblingId, newNextSiblingId);
      }

      const newNextSiblingId = overNode.childrenAfterSort?.at(0)?.data?.id || null;
      return quoteNodeCanBeMoved(nodeToBeMovedId, targetId, null, newNextSiblingId);
    },
    [quoteNodeCanBeMoved],
  );
};
