import { useCallback, useEffect, useState } from 'react';
import { number } from 'prop-types';
import {
  ActionMenu,
  Badge,
  BadgeJob,
  DeepTable,
  DraggableItem,
  INPUT_NOTE_DEEP_TABLE_STYLE,
  Modal,
  PriceAdvanced,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
  RichTextField,
  SimpleChevronBottomIcon,
  SimpleCirclePlusIcon,
  SimpleDeleteIcon,
  useCounterLoaderContext,
} from '@graneet/lib-ui';
import { Box, GridItem, IconButton, useDisclosure, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { QUOTE_LOT_KEY } from '../services/quote-lot.util';
import { generateLotFieldName } from '../../quote/forms/quote-edit-lot-step.form';

import { QuoteEditLotActions } from './QuoteLotEditActions';

import { useAddJob } from 'features/quote-job/hooks/useAddJob';
import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';
import { QuoteEditCodeRow } from 'features/quote/components/codeRow/QuoteEditCodeRow';
import { useStore } from 'store/store';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useEditLot } from 'features/quote-lot/hooks/useEditLot';
import { useDeleteLot } from 'features/quote-lot/hooks/useDeleteLot';
import { useLot } from 'features/quote-lot/hooks/useLot';

const { CODE, DESCRIPTION, NOTE } = QUOTE_LOT_KEY;

const baseStyles = {
  inputWithFocus: {
    fontSize: 'sm',
    borderColor: 'gray.500',
  },
  noteInput: INPUT_NOTE_DEEP_TABLE_STYLE(false),
  blurredInput: {
    fontSize: 'sm',
  },
};
const noteInputWithFocus = {
  ...baseStyles.noteInput,
  ...baseStyles.inputWithFocus,

  noBorder: true,
};
const noteBlurredInput = {
  ...baseStyles.noteInput,
  ...baseStyles.blurredInput,
  noBorder: true,
  borderColor: 'gray.300',
  _hover: {},
};

const getStyleForInput = (hasFocus) => (hasFocus ? baseStyles.inputWithFocus : baseStyles.blurredInput);
const getStyleForNoteInput = (hasFocus) => (hasFocus ? noteInputWithFocus : noteBlurredInput);

export const EditQuoteLotRow = ({ lotId }) => {
  const { t } = useTranslation(['quote']);
  const theme = useTheme();

  const { startAnotherUpdate, findNumberOfIncompleteJobsInLot, setLotIsExpanded } = useQuoteEditContext();

  const { displaySaleView, isReadOnlyView } = useQuoteDisplayContext();

  const editLot = useEditLot();
  const deleteLot = useDeleteLot();

  const addJob = useAddJob();

  const isOptional = useStore((state) => state.optionalLotsTable[lotId.toString()]);

  const wrapperLot = useLot(lotId);

  const [hasFocus, setHasFocus] = useState(false);
  const confirmDeleteModal = useDisclosure();
  const isExpanded = !!wrapperLot?.isExpanded;

  const fieldData = useCallback((key) => ({ key, lotId }), [lotId]);

  // Event handlers
  const handleOnFieldFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  // Event handlers
  const handleOnFieldBlur = useCallback(() => {
    setHasFocus(false);
  }, []);

  const handleAddJob = useCallback(async () => {
    await addJob(lotId, isOptional);
    startAnotherUpdate();
  }, [addJob, isOptional, lotId, startAnotherUpdate]);

  const handleDeleteLot = useCallback(async () => {
    await deleteLot(lotId);
    startAnotherUpdate();
  }, [deleteLot, lotId, startAnotherUpdate]);

  const handleUpdateNote = useCallback(
    (note) => () => {
      startAnotherUpdate();
      editLot(lotId, { note });
    },
    [editLot, lotId, startAnotherUpdate],
  );

  const toggleExpanded = useCallback(() => {
    setLotIsExpanded(lotId, !isExpanded);
  }, [setLotIsExpanded, isExpanded, lotId]);

  const { addLineRendered } = useCounterLoaderContext();
  useEffect(() => {
    addLineRendered(`LOT_${lotId}`);
  }, [addLineRendered, lotId]);

  if (!wrapperLot) {
    return null;
  }

  const nameForKey = generateLotFieldName(lotId);
  const inputStyle = getStyleForInput(hasFocus);
  const noteInputStyle = getStyleForNoteInput(hasFocus);
  const backgroundColor = wrapperLot.lot.depth > 1 ? 'gray.150' : 'gray.200';
  const borderColor = theme.colors.gray['300'];

  const fillArrayLength = displaySaleView ? 5 : 3;
  const lotIncompleteJobs = isExpanded ? 0 : findNumberOfIncompleteJobsInLot(lotId);

  const showIncompleteJobsBadge = !isExpanded && lotIncompleteJobs > 0;
  const emptyCellWidth = showIncompleteJobsBadge ? fillArrayLength - 2 : fillArrayLength;
  const hasNote = wrapperLot.lot.note !== null;

  return (
    <Box position="relative">
      {isOptional ? (
        <BadgeJob backgroundColor="purple.200" textColor="purple.700" label={t('quote:option.title')} />
      ) : null}
      <DeepTable.Row
        offset={(wrapperLot.lot.depth - 1) * 0.5}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        leftContent={
          <Box display="flex" flexDirection="row" marginTop={isOptional ? 3 : 0} alignItems="center">
            {!isReadOnlyView && <DraggableItem.Handle mr={1} color="gray.400" _hover={{ color: 'gray.500' }} />}
            <IconButton
              variant="clear"
              icon={
                isExpanded ? (
                  <SimpleChevronBottomIcon transform="rotate(-180deg)" boxSize={5} stroke="gray.400" />
                ) : (
                  <SimpleChevronBottomIcon boxSize={5} stroke="gray.400" />
                )
              }
              height="auto"
              onClick={toggleExpanded}
              color="gray.400"
              _hover={{ stroke: 'greenBrand.light' }}
            />
          </Box>
        }
      >
        <DeepTable.Cell>
          <QuoteEditCodeRow
            id={lotId}
            type="lot"
            name={nameForKey(CODE)}
            data={fieldData(CODE)}
            onBlur={handleOnFieldBlur}
            onFocus={handleOnFieldFocus}
            inputProps={inputStyle}
          />
        </DeepTable.Cell>
        <DeepTable.Cell>
          <RichTextField
            noBorder
            name={nameForKey(DESCRIPTION)}
            data={fieldData(DESCRIPTION)}
            onBlur={handleOnFieldBlur}
            onFocus={handleOnFieldFocus}
            // inputProps={inputStyle}
            isDisabled={isReadOnlyView}
            configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
            navbarType="inline"
          />
        </DeepTable.Cell>

        <GridItem colSpan={emptyCellWidth}>
          <DeepTable.Cell />
        </GridItem>

        {showIncompleteJobsBadge && (
          <GridItem colSpan={2}>
            <DeepTable.Cell right>
              <Badge colorScheme="yellow" w="auto" px={2}>
                {t('quote:jobsStep.errors.incompleteJobs', { count: lotIncompleteJobs })}
              </Badge>
            </DeepTable.Cell>
          </GridItem>
        )}
        <DeepTable.Cell right gap={8}>
          {isOptional ? (
            <PriceAdvanced
              amount={displaySaleView ? wrapperLot.lot.optionalAmountExVAT : wrapperLot.lot.optionalDisbursementExVAT}
              fontSize="md"
            />
          ) : (
            <PriceAdvanced
              amount={displaySaleView ? wrapperLot.lot.amountExVAT : wrapperLot.lot.disbursementExVAT}
              fontSize="md"
            />
          )}
        </DeepTable.Cell>
        <DeepTable.Cell right>
          {!isReadOnlyView && (
            <IconButton
              variant="clear"
              icon={<SimpleCirclePlusIcon boxSize={6} stroke="gray.300" _hover={{ stroke: 'greenBrand.light' }} />}
              mr={2}
              height="auto"
              onClick={handleAddJob}
            />
          )}

          <QuoteEditLotActions
            wrappedLot={wrapperLot}
            onDelete={confirmDeleteModal.onOpen}
            onNoteUpdated={handleUpdateNote}
          />
          <Modal {...confirmDeleteModal} title={t('quote:jobsStep.deleteLot')} size="lg">
            {t('quote:jobsStep.confirms.deletingLotConfirm')}

            <Modal.Close />

            <Modal.PrimaryButton
              bg="red.500"
              _hover={{ bg: 'red.600' }}
              _active={{ bg: 'red.800' }}
              onClick={handleDeleteLot}
            >
              {t('global:words.c.yes')}
            </Modal.PrimaryButton>
          </Modal>
        </DeepTable.Cell>
      </DeepTable.Row>
      {hasNote && (
        <DeepTable.Row backgroundColor="white" borderColor={borderColor} offset={wrapperLot.lot.depth - 1}>
          <GridItem colSpan={displaySaleView ? 8 : 6}>
            <DeepTable.Cell>
              <RichTextField
                variant="light"
                name={nameForKey(NOTE)}
                data={fieldData(NOTE)}
                onBlur={handleOnFieldBlur}
                onFocus={handleOnFieldFocus}
                inputProps={noteInputStyle}
                isDisabled={isReadOnlyView}
                configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
                navbarType="inline"
              />
            </DeepTable.Cell>
          </GridItem>
          {!isReadOnlyView && (
            <DeepTable.Cell right alignItems="flex-start">
              <ActionMenu>
                <ActionMenu.Action
                  icon={<SimpleDeleteIcon />}
                  label={t('quote:jobsStep.deleteNote')}
                  onClick={handleUpdateNote(null)}
                  warning
                />
              </ActionMenu>
            </DeepTable.Cell>
          )}
        </DeepTable.Row>
      )}
    </Box>
  );
};

EditQuoteLotRow.propTypes = {
  lotId: number.isRequired,
};
