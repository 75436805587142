import { Box, Grid, GridItem, HStack, Text } from '@chakra-ui/react';
import { GraneetButton, SingleSelectField, TextField } from '@graneet/lib-ui';
import { Form, HiddenField, useForm, useFormStatus } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { type ChangeEvent, useCallback, useMemo, useRef, useState, type FC } from 'react';
import { KYC_SCHEME_NAME } from '@graneet/business-logic';

import { useUpdateCompanyKYCValidation } from '../services/company.api';

import { Rule } from 'features/form/rules/Rule';

interface CompanyKYCFieldsProps {
  kycOrgName: string | null;
  kycAddress: string | null;
  kycIdentification: string | null;
  kycOrgLegalStatus: string | null;
  kycSchemeName: KYC_SCHEME_NAME | null;
  canEdit?: boolean;
}

interface CompanyKYCFieldsFormValues {
  kycOrgName: string;
  kycAddress: string;
  kycIdentification: string;
  kycOrgLegalStatus: string;
  kycSchemeName: KYC_SCHEME_NAME;
  kyc?: File;
}

export const CompanyKYCFields: FC<CompanyKYCFieldsProps> = ({
  kycOrgName,
  kycAddress,
  kycIdentification,
  kycOrgLegalStatus,
  kycSchemeName,
  canEdit = false,
}) => {
  const { t } = useTranslation(['company']);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const sendKYCMutation = useUpdateCompanyKYCValidation();

  const form = useForm<CompanyKYCFieldsFormValues>({
    defaultValues: {
      kycOrgName: kycOrgName ?? '',
      kycAddress: kycAddress ?? '',
      kycIdentification: kycIdentification ?? '',
      kycOrgLegalStatus: kycOrgLegalStatus ?? '',
      kycSchemeName: kycSchemeName ?? KYC_SCHEME_NAME.SIREN,
    },
  });

  const { isValid: isFormValid } = useFormStatus(form);

  const handleSubmit = useCallback(
    async (values: CompanyKYCFieldsFormValues) => {
      await sendKYCMutation.mutateAsync(values);
    },
    [sendKYCMutation],
  );

  const optionsLegalStatus = useMemo(
    () => [
      { value: 'SAS', label: 'SAS' },
      { value: 'SARL', label: 'SARL' },
      { value: 'SA', label: 'SA' },
      { value: 'SASU', label: 'SASU' },
    ],
    [],
  );

  const optionsSchemeName = useMemo(
    () => [
      { value: KYC_SCHEME_NAME.SIREN, label: t('company:kyc.schemes.siren') },
      { value: KYC_SCHEME_NAME.EUID, label: t('company:kyc.schemes.euid') },
      { value: KYC_SCHEME_NAME.INCORPORATION_NUMBER, label: t('company:kyc.schemes.incorporation_number') },
    ],
    [t],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        setSelectedFile(file);
        form.setFormValues({ kyc: file });
      } else {
        setSelectedFile(null);
        form.setFormValues({ kyc: undefined });
      }
    },
    [form],
  );

  return (
    <Form form={form} onSubmit={form.handleSubmit(handleSubmit)} id="KYC_FORM">
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <TextField<CompanyKYCFieldsFormValues>
          name="kycOrgName"
          label={t('company:kyc.fields.kycOrgName')}
          isReadOnly={!canEdit}
          isRequired
        >
          <Rule.IsRequired />
        </TextField>
        {canEdit ? (
          <SingleSelectField<CompanyKYCFieldsFormValues>
            name="kycOrgLegalStatus"
            label={t('company:kyc.fields.kycOrgLegalStatus')}
            isRequired
            options={optionsLegalStatus}
            isCreatable
          >
            <Rule.IsRequired />
          </SingleSelectField>
        ) : (
          <TextField<CompanyKYCFieldsFormValues>
            name="kycOrgLegalStatus"
            label={t('company:kyc.fields.kycOrgLegalStatus')}
            isReadOnly
            isRequired
          >
            <Rule.IsRequired />
          </TextField>
        )}
        {canEdit && (
          <SingleSelectField<CompanyKYCFieldsFormValues>
            name="kycSchemeName"
            label={t('company:kyc.fields.kycSchemeName')}
            isDisabled={!canEdit}
            options={optionsSchemeName}
            isRequired
          >
            <Rule.IsRequired />
          </SingleSelectField>
        )}
        <TextField<CompanyKYCFieldsFormValues>
          name="kycIdentification"
          label={t('company:kyc.fields.kycIdentification')}
          isReadOnly={!canEdit}
          isRequired
        >
          <Rule.IsRequired />
        </TextField>
        <GridItem colSpan={2}>
          <TextField<CompanyKYCFieldsFormValues>
            name="kycAddress"
            label={t('company:kyc.fields.kycAddress')}
            isReadOnly={!canEdit}
            isRequired
          >
            <Rule.IsRequired />
          </TextField>
        </GridItem>
        {canEdit && (
          <GridItem colSpan={2}>
            <Box display="none">
              <HiddenField name="kyc">
                <Rule.IsRequired />
              </HiddenField>
              <input type="file" onChange={handleChange} ref={inputFileRef} />
            </Box>
            <HStack>
              <GraneetButton leftIcon="ri-file-upload-line" onClick={() => inputFileRef.current?.click()}>
                {t('company:kyc.actions.addFile')}
                <Text className="chakra-form__required-indicator css-1tfjd1n" ml={0}>
                  *
                </Text>
              </GraneetButton>
              {selectedFile && <Text>{selectedFile?.name}</Text>}
            </HStack>
          </GridItem>
        )}

        {canEdit && (
          <GridItem colSpan={2} justifySelf="end">
            <GraneetButton type="submit" variant="primary" form="KYC_FORM" isDisabled={!isFormValid}>
              {t('company:kyc.actions.submit')}
            </GraneetButton>
          </GridItem>
        )}
      </Grid>
    </Form>
  );
};
