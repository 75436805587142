import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { RichText } from '@graneet/lib-ui';
import { isEmpty, isNil } from 'lodash-es';

import { useStore } from 'store/store';

export const DenominationCell: FC<CustomCellRendererProps<QuoteComponentsSupplyObject>> = (params) => {
  const { data } = params;
  const editorRef = useStore((state) => state.quoteDrawerRichTextEditorRef);
  const getPlainText = useCallback(
    (v: string | null | undefined) =>
      editorRef?.current?.getPlainText ? (editorRef?.current?.getPlainText(v) ?? null) : null,
    [editorRef],
  );

  const plainTextValue = getPlainText(data?.denomination);

  const value = data && !isNil(plainTextValue) && !isEmpty(plainTextValue);
  const element = useMemo(
    () => (
      <HStack height="100%" minHeight={45} width="100%" color="#1F2A37" fontSize="0.813rem" display="inline-flex" p={2}>
        {value ? <RichText value={data.denomination} /> : <Text>-</Text>}
      </HStack>
    ),
    [data, value],
  );

  return element;
};
