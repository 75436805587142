import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleCashOutFlowIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props} fill="none">
    <path
      d="M4 12L12 4M12 4H6.66667M12 4V9.33333"
      stroke="#627D98"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
