import { useEffect, useMemo } from 'react';
import { Form, useForm } from 'graneet-form';
import { Multiplication, useCurrency, HiddenCostDisplay, divideFloating } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';
import { getDiscountAmountExVAT, isComputableMargin, getSumCustomDiscountsAmountExVAT } from '@graneet/business-logic';

import { QUOTE_TOTAL_FIELDS } from '../../constants/quotes.constant';

import { useStore } from 'store/store';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useQuote } from 'features/quote/hooks/useQuote';
import { useLot } from 'features/quote-lot/hooks/useLot';
import { QuoteDiscountLabel } from 'features/quote-discount/components/QuoteDiscountLabel';
import { QuoteCustomDiscountLabel } from 'features/quote-discount/components/QuoteCustomDiscountLabel';
import { QuoteDiscountMarginErrorComponent } from 'features/quote-discount/components/QuoteDiscountMarginError';

const BOX_SHADOW_STYLE = (blurRadius) => `0 2px 10px rgba(0, 0, 0, ${blurRadius});`;

export const QuoteTotalMultiplications = () => {
  const form = useForm();
  const { setFormValues } = form;
  const { t } = useTranslation(['margin', 'quote']);
  const { getCurrentQuoteData } = useQuoteEditContext();
  const { rootLotId } = getCurrentQuoteData();

  const quote = useQuote();
  const rootLot = useLot(rootLotId);
  const { mapNumberToAmount } = useCurrency();
  const hasHiddenCosts = useStore((state) => state.hasHiddenCosts);
  const { formatAsAmount } = useCurrency();

  useEffect(() => {
    if (!rootLot) return;

    const {
      disbursementExVAT,
      margin: { totalMargin },
    } = rootLot.lot;
    const { amountExVAT } = quote;

    setFormValues({
      [QUOTE_TOTAL_FIELDS.DISBURSEMENT_EX_VAT]: mapNumberToAmount(disbursementExVAT),
      [QUOTE_TOTAL_FIELDS.TOTAL_MARGIN]: totalMargin,
      [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT]: mapNumberToAmount(amountExVAT),
    });
  }, [rootLot, mapNumberToAmount, setFormValues, quote]);

  useEffect(() => {
    if (!rootLot) return;

    setFormValues({
      hiddenCostPercent: 1 + divideFloating(rootLot.lot.hiddenCostAmountExVAT, rootLot.lot.disbursementExVAT),
    });
  }, [rootLot, setFormValues]);

  const discount = quote?.discount;
  const customDiscounts = quote?.customDiscounts;

  const hasCustomDiscounts = useMemo(() => customDiscounts && customDiscounts.length > 0, [customDiscounts]);

  if (!rootLot) {
    return null;
  }

  const { disbursementExVAT } = rootLot.lot;
  const discountAmountExVAT = getDiscountAmountExVAT(discount, quote?.amountWithoutDiscountExVAT);

  const amountWithDiscountExVAT = (quote?.amountWithoutDiscountExVAT || 0) - discountAmountExVAT;

  const customDiscountsAmountExVAT = getSumCustomDiscountsAmountExVAT(quote?.customDiscounts, amountWithDiscountExVAT);
  const amountExVAT = quote.amountWithoutDiscountExVAT - discountAmountExVAT + customDiscountsAmountExVAT;

  return (
    <Flex
      maxW={hasHiddenCosts ? '42rem' : '35rem'}
      backgroundColor="white"
      boxShadow={BOX_SHADOW_STYLE(0.08)}
      borderRadius="md"
      cursor="pointer"
      mb={discount || hasCustomDiscounts ? 3 : 0}
      pb={!discount && !hasCustomDiscounts && 4}
      px={4}
      pt={4}
      _hover={{ boxShadow: BOX_SHADOW_STYLE(0.16) }}
      width="2000px"
    >
      <Flex direction="column" align="flex-end">
        <Form form={form}>
          <Multiplication
            hiddenCostAmount={mapNumberToAmount(rootLot?.lot?.hiddenCostAmountExVAT || 0)}
            hiddenCost={
              hasHiddenCosts && (
                <HiddenCostDisplay
                  hiddenCostAmount={formatAsAmount(rootLot?.lot?.hiddenCostAmountExVAT || 0)}
                  toolTipsLabel={t('quote:hiddenCost.title')}
                  fieldLabel={t('quote:hiddenCost.title')}
                />
              )
            }
          >
            <Multiplication.Field
              label={t(
                hasHiddenCosts
                  ? 'quote:jobsStep.fields.flatUnitDisbursementExVAT'
                  : 'quote:jobsStep.fields.totalDisbursementExVAT',
              )}
              computedName={QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT}
              name={QUOTE_TOTAL_FIELDS.DISBURSEMENT_EX_VAT}
              isReadOnly
              isCurrency
            />
            <Multiplication.Field
              label={t('margin:fields.totalMargin')}
              computedName={QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT}
              name={QUOTE_TOTAL_FIELDS.TOTAL_MARGIN}
              isReadOnly
              scale={3}
            />
            {/* Because we dont want margin to be recompute and equal to 0 when
          total is set to 0, computed is disbursement exc. VAT */}
            <Multiplication.Field
              label={t('quote:fields.priceExVAT')}
              computedName={QUOTE_TOTAL_FIELDS.DISBURSEMENT_EX_VAT}
              name={QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT}
              isReadOnly
              isCurrency
            />
          </Multiplication>
        </Form>
        {!isComputableMargin(disbursementExVAT, amountExVAT) ? (
          <Flex justifyContent="flex-end" p={2}>
            <QuoteDiscountMarginErrorComponent />
          </Flex>
        ) : (
          <>
            {hasCustomDiscounts && (
              <QuoteCustomDiscountLabel
                discount={discount}
                discountAmount={discountAmountExVAT}
                customDiscounts={customDiscounts || []}
              />
            )}
            {discount && !hasCustomDiscounts && (
              <QuoteDiscountLabel discount={discount} discountAmount={discountAmountExVAT} />
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
};
