import type { QuoteNodeObject } from '@org/quotation-lib';
import { AgGridReact } from '@ag-grid-community/react';
import { Box, Flex } from '@chakra-ui/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import type {
  CellContextMenuEvent,
  ColumnResizedEvent,
  DragStartedEvent,
  GridReadyEvent,
  RowDataUpdatedEvent,
  SelectionChangedEvent,
  CellFocusedEvent,
  IsGroupOpenByDefaultParams,
} from '@ag-grid-community/core';
import type { MenuElement, MultiLevelMenuHandle } from '@graneet/lib-ui';
import { MultiLevelMenu } from '@graneet/lib-ui';
import { v4 as uuid } from 'uuid';

import { useAutoGroupColumnDef } from '../hooks/auto-group-column-def/useAutoGroupColumnDef';
import { useColumnDefs } from '../hooks/column-def/useColumnDefs';
import { useDefaultColDef } from '../hooks/default-col-def/useDefaultColDef';
import { useGetDataPath } from '../hooks/get-data-path/useGetDataPath';
import { useGridOption } from '../hooks/grid-option/useGridOption';
import { useOnRowDragEnd } from '../hooks/on-row-drag/useOnRowDragEnd';
import { useOnRowDragLeave } from '../hooks/on-row-drag/useOnRowDragLeave';
import { useOnRowDragMove } from '../hooks/on-row-drag/useOnRowDragMove';
import { useRowDragText } from '../hooks/row-drag-text/useRowDragText';
import { QuoteAGGridType } from '../constants/agGridConstants';
import { useContextMenu } from '../hooks/context-menu/useContextMenu';
import { useGetRowClass } from '../hooks/get-row-style/useGetRowClass';
import { useRowHeight } from '../hooks/row-height/use-row-height';

import { QuoteToolBar } from './toolbar/QuoteToolBar';

import { useEditUserSettings } from 'features/user/services/user.api';
import { useStore } from 'store/store';
import { sortQuoteNodeObjectByAutomaticIndex } from 'features/quotation/quote-node/utils/sortQuoteNodeObjectByAutomaticIndex';
import {
  appendQuoteExpandedRow,
  updateQuoteHiddenCostColumns,
  updateQuoteHiddenCostGridRef,
  updateQuoteHiddenCostNbSelectedRows,
  updateQuoteNewlyCreatedNodeId,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useQuoteSettingsGetFromStoreOrThrow } from 'features/quotation/quote-common/hooks/useQuoteSettingsGetFromStore';
import { useDebounce } from 'features/common/hooks/useDebounce';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';

interface AgGridQuoteHiddenCostRootProps {
  onGridReady: (params: GridReadyEvent) => void;
}

export const AgGridQuoteHiddenCostRoot = ({ onGridReady }: AgGridQuoteHiddenCostRootProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [contextMenuItems, setContextMenuItems] = useState<MenuElement[]>([]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<AgGridReact<QuoteNodeObject>>(null);
  const mainGridRef = useStore((state) => state.quoteGridRef);
  const contextMenuRef = useRef<MultiLevelMenuHandle>(null);
  const rowHeight = useRowHeight();

  const isEditable = useStore((store) => store.quoteEditable);
  const quoteNewlyCreatedNodeId = useStore((state) => state.quoteNewlyCreatedNodeId);
  const quoteExpandedRows = useStore((state) => state.quoteExpandedRows);
  const quoteCollapsedRows = useStore((state) => state.quoteCollapsedRows);
  const { quote } = useQuote();

  const { quoteHiddenCostColumns, quoteSetting } = useQuoteSettingsGetFromStoreOrThrow();

  const quoteHiddenCostNodes = useStore((state) => state.quoteNodeHiddenCost);
  const quoteHiddenCostNodesSort = useMemo(
    () => sortQuoteNodeObjectByAutomaticIndex(Array.from(quoteHiddenCostNodes?.values() || [])),
    [quoteHiddenCostNodes],
  );

  const contextMenuDef = useContextMenu();

  const editUserSettingsMutation = useEditUserSettings(false);

  const handleOpenGrid = () => {
    setIsOpen(!isOpen);
  };

  const autoGroupColumnDef = useAutoGroupColumnDef();
  const columnDefs = useColumnDefs(QuoteAGGridType.HIDDEN_COST);
  const defaultColDef = useDefaultColDef();
  const getDataPath = useGetDataPath();
  const getRowClass = useGetRowClass();
  const gridOption = useGridOption();
  const onRowDragEnd = useOnRowDragEnd();
  const onRowDragLeave = useOnRowDragLeave();
  const onRowDragMove = useOnRowDragMove(gridRef);
  const rowDragText = useRowDragText();

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      if (event.source !== 'apiSelectAll') {
        mainGridRef?.current?.api.deselectAll();
      }
      const selectedRows = event.api.getSelectedRows();
      updateQuoteHiddenCostNbSelectedRows(selectedRows.length);
    },
    [mainGridRef],
  );

  const saveColumns = useDebounce(
    async (data: string[]) => {
      const [quoteHiddenCostColumnsJSON] = data;
      await editUserSettingsMutation.mutateAsync({ quoteHiddenCostColumnsJSON });
    },
    2000,
    { leading: false, trailing: true },
  );

  const handleColumnResized = useCallback(
    async (event: ColumnResizedEvent) => {
      if (event.finished) {
        const { column } = event;
        if (!column) {
          return;
        }
        const quoteHiddenCostColumnsJSON = JSON.stringify(
          quoteHiddenCostColumns.map((col) => {
            if (col.name === column.getColId()) {
              return {
                ...col,
                width: column.getActualWidth(),
              };
            }
            return col;
          }),
        );
        updateQuoteHiddenCostColumns(quoteHiddenCostColumnsJSON);
        saveColumns(quoteHiddenCostColumnsJSON);
      }
    },
    [saveColumns, quoteHiddenCostColumns],
  );

  const handleCellClicked = useCallback(
    (event: CellContextMenuEvent) => {
      const { data, node } = event;
      if (node.footer || !isEditable) {
        return;
      }
      if (!data) {
        return;
      }
      if (contextMenuRef.current) {
        setContextMenuItems(contextMenuDef(contextMenuRef.current.close, data));
        const e = event.event as MouseEvent;
        contextMenuRef.current.open(e?.clientX, e?.clientY);
      }
    },
    [contextMenuDef, isEditable],
  );

  const hasHiddenCost = quoteHiddenCostNodesSort.length > 1;

  const onRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent<QuoteNodeObject>) => {
      setTimeout(() => {
        if (quoteNewlyCreatedNodeId) {
          const node = quote.getTree().getNodeOrNull(quoteNewlyCreatedNodeId);
          updateQuoteNewlyCreatedNodeId(null);
          if (node) {
            const parentId = node.getParent()?.getId();
            if (parentId) {
              appendQuoteExpandedRow(parentId);
              event.api.forEachNode((n) => {
                if (n.data?.id === parentId) {
                  event.api.setRowNodeExpanded(n, true, false);
                }
              });
            }
          }
          setTimeout(() => {
            event.api.forEachNode((n) => {
              if (n.data?.id === quoteNewlyCreatedNodeId && n.rowIndex !== null) {
                setTimeout(() => {
                  event.api.startEditingCell({
                    rowIndex: n.rowIndex!,
                    colKey: 'denomination',
                  });
                }, 10);
              }
            });
          }, 0);
        }
      }, 10);
    },
    [quoteNewlyCreatedNodeId, quote],
  );

  const onCellValueChanged = useCallback((event: CellFocusedEvent) => {
    const focusedCell = event.api.getFocusedCell();
    if (focusedCell === null) return;
    if (event.column === null) return;
    const columnId = typeof event.column === 'string' ? event.column : event.column.getColId();
    if (focusedCell.column.getColId() === columnId) {
      event.api.setFocusedCell(focusedCell.rowIndex, columnId);
      return;
    }
    setTimeout(() => {
      event.api.startEditingCell({
        rowIndex: focusedCell.rowIndex,
        colKey: focusedCell.column,
      });
    }, 10);
  }, []);

  const isGroupOpenByDefault = useCallback(
    (params: IsGroupOpenByDefaultParams<QuoteNodeObject>) => {
      const isExpanded = quoteExpandedRows.includes(params.key!);
      const isCollapsed = quoteCollapsedRows.includes(params.key!);
      const node = quote.getTree().getNodeOrNull(params.key!);

      if (isExpanded) {
        return true;
      }
      if (isCollapsed) {
        return false;
      }
      if (node) {
        if (
          ['QuoteBasicItem', 'QuoteOptionalItem', 'QuoteSubItem', 'QuoteHiddenCostItem'].includes(
            node.getContent().getType() ?? '',
          )
        ) {
          if (node.hasChildren()) {
            if (node.getFirstChild()?.getContent().getType() === 'QuoteComponent') {
              return quoteSetting.isComponentsExpanded;
            }
          }
        }
        return quoteSetting.isAllItemsExpanded;
      }
      return true;
    },
    [quoteCollapsedRows, quoteExpandedRows, quoteSetting.isAllItemsExpanded, quoteSetting.isComponentsExpanded, quote],
  );

  const handleColumnMoved = useCallback(async () => {
    const gridColumns = gridRef.current?.api.getAllGridColumns();

    const newColumns = quoteHiddenCostColumns.map((col) => {
      const columnIndex = (gridColumns ?? []).findIndex((column) => column.getColId() === col.name);

      if (columnIndex !== -1) {
        return {
          ...col,
          index: columnIndex - 2,
        };
      }
      return col;
    });

    const quoteHiddenCostColumnsJSON = JSON.stringify(newColumns);
    saveColumns([quoteHiddenCostColumnsJSON]);
    updateQuoteHiddenCostColumns(quoteHiddenCostColumnsJSON);
  }, [saveColumns, quoteHiddenCostColumns]);

  const handleDragStarted = useCallback((event: DragStartedEvent) => {
    event.api.deselectAll();
  }, []);

  const handleOnGridReady = useCallback(
    (event: GridReadyEvent) => {
      onGridReady(event);
      setIsReady(true);
      updateQuoteHiddenCostGridRef(gridRef);
    },
    [onGridReady],
  );

  return (
    <Flex
      direction="column"
      width="100%"
      height={isOpen ? '100%' : '50px'}
      paddingBottom={hasHiddenCost ? '0.75rem' : '0rem'}
      display={hasHiddenCost ? 'flex' : 'none'}
      ref={wrapperRef}
    >
      <QuoteToolBar
        type={QuoteAGGridType.HIDDEN_COST}
        handleOpenGrid={{
          onClick: handleOpenGrid,
          value: isOpen,
        }}
        api={gridRef?.current?.api || null}
      />

      {isOpen ? (
        <Flex
          className={`no-scroll-left no-scroll-right ag-theme-quartz quotation ag-custom-hidden-cost ${!isReady ? 'hidden' : ''}`}
          width="100%"
          height="100%"
        >
          <Box width="100%" height="100%">
            <AgGridReact
              autoGroupColumnDef={autoGroupColumnDef}
              isGroupOpenByDefault={isGroupOpenByDefault}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              getDataPath={getDataPath}
              getRowClass={getRowClass}
              gridOptions={gridOption}
              groupDefaultExpanded={-1}
              onRowDragEnd={onRowDragEnd}
              onRowDragLeave={onRowDragLeave}
              onRowDragMove={onRowDragMove}
              ref={gridRef}
              rowData={quoteHiddenCostNodesSort}
              rowDragText={rowDragText}
              treeData
              onGridReady={handleOnGridReady}
              onDragStarted={handleDragStarted}
              onSelectionChanged={handleSelectionChanged}
              rowSelection="multiple"
              suppressRowClickSelection
              reactiveCustomComponents
              suppressGroupRowsSticky
              animateRows={false}
              onColumnResized={handleColumnResized}
              suppressContextMenu
              onCellContextMenu={handleCellClicked}
              preventDefaultOnContextMenu
              suppressRowDrag={!isEditable}
              rowHeight={rowHeight}
              singleClickEdit
              onRowDataUpdated={onRowDataUpdated}
              onCellValueChanged={onCellValueChanged}
              suppressMoveWhenRowDragging
              suppressDragLeaveHidesColumns
              suppressColumnMoveAnimation
              allowDragFromColumnsToolPanel
              onColumnMoved={handleColumnMoved}
            />
          </Box>
        </Flex>
      ) : null}
      <MultiLevelMenu items={contextMenuItems} ref={contextMenuRef} id={uuid()} />
    </Flex>
  );
};
