import { Flex } from '@chakra-ui/react';
import type { FC } from 'react';

import type { ShadowCellProps } from './Cell.type';

export const SHADOW_CELL_PARENT_PROPS = {
  fontSize: 'sm',
  boxSize: '100%',
  _groupHover: { color: 'blue.800' },
  overflow: 'hidden',
  alignItems: 'stretch',
  p: 0,
};

export const SHADOW_CELL_PROPS = {
  background: 'inherit',
  boxShadow: '0 0 8px 3px rgba(196,196,196,0.5)',
  alignItems: 'center',
  mx: 2,
  flex: 1,
};

export const DEFAULT_INTERNAL_PADDING_X = 8;

export const ShadowCell: FC<ShadowCellProps> = ({
  children,
  internalPaddingX = DEFAULT_INTERNAL_PADDING_X,
  center = false,
  ...props
}) => (
  <Flex {...SHADOW_CELL_PARENT_PROPS} {...props}>
    <Flex
      {...SHADOW_CELL_PROPS}
      px={internalPaddingX}
      {...(center
        ? {
            justifyContent: 'center',
            textAlign: 'center',
            paddingInlineStart: 0,
            paddingInlineEnd: 0,
          }
        : {})}
    >
      {children}
    </Flex>
  </Flex>
);
