import { CheckboxField, IconAdvanced, ModalSubtitle, SimpleHelpIcon, SwitchField } from '@graneet/lib-ui';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS } from '@graneet/business-logic';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useCallback } from 'react';

import type { RoleFormFields } from '../../constants/role.constant';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const RoleEditModalAccounting = () => {
  const { t } = useTranslation(['global', 'user']);

  const form = useFormContext<RoleFormFields>();
  const { displayAccounting } = useOnChangeValues(form, ['displayAccounting']);

  const onChange = useCallback(
    (newValue: boolean) => {
      form.setFormValues({
        generateAccounting: newValue,
        updateAccounting: newValue,
      });
    },
    [form],
  );

  const hasUserPermissionsFeatureFlag = useFeatureFlag(FEATURE_FLAGS.USER_PERMISSIONS);

  if (!hasUserPermissionsFeatureFlag) {
    return null;
  }

  const canEditSubRole = !!displayAccounting;

  return (
    <Stack direction="column" gap={0}>
      <ModalSubtitle mb={0}>
        <SwitchField<RoleFormFields>
          name="displayAccounting"
          onChange={onChange as any}
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.DISPLAY_ACCOUNTING')}</Text>
              <IconAdvanced icon={<SimpleHelpIcon boxSize={4} />} tooltip={t('user:role.DISPLAY_ACCOUNTING.tooltip')} />
            </HStack>
          }
          checkedValue
          uncheckedValue={false}
        />
      </ModalSubtitle>

      <Stack direction="column" gap={1} pl={3}>
        <CheckboxField<RoleFormFields>
          name="generateAccounting"
          isDisabled={!canEditSubRole}
          label={
            <HStack spacing={2} alignItems="center">
              <Text>{t('user:permissions.GENERATE_ACCOUNTING')}</Text>
              <IconAdvanced
                icon={<SimpleHelpIcon boxSize={4} />}
                tooltip={t('user:role.GENERATE_ACCOUNTING.tooltip')}
              />
            </HStack>
          }
        />

        <CheckboxField<RoleFormFields>
          name="updateAccounting"
          label={t('user:permissions.UPDATE_ACCOUNTING')}
          isDisabled={!canEditSubRole}
        />
      </Stack>
    </Stack>
  );
};
