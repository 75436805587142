import type { FC, MouseEventHandler, ReactNode } from 'react';
import { useCallback } from 'react';
import type { MenuProps } from '@chakra-ui/react';
import { Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react';

import type { GraneetButtonProps } from '../Quotation/Button/Button';
import { GraneetButton } from '../Quotation/Button/Button';

import { configureDefaultLabels } from './ActionMenuTranslations';
import { Separator } from './Actions/Separator';
import { Duplicate } from './Actions/Duplicate';
import { Action } from './Actions/Action';
import { Restore } from './Actions/Restore';
import { Edit } from './Actions/Edit';
import { Delete } from './Actions/Delete';

export interface ActionMenuProps extends MenuProps {
  iconSize?: number;

  children: ReactNode;

  buttonProps?: GraneetButtonProps;

  w?: string;

  isLazy?: boolean;
}

const ActionMenuComponent: FC<ActionMenuProps> = ({ children, buttonProps, w, isLazy = true, ...props }) => {
  const onClick = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    // Dropdown is always clickable without triggering other onCLick effect of parent component
    e.stopPropagation();
  }, []);

  return (
    <Flex onClick={onClick} w={w} justifyContent="center">
      {/* `fixed` avoid wizard scrolling issue. `isLazy` improve performance when many menu are displayed in a page */}
      <Menu strategy="fixed" isLazy={isLazy} variant="dropdown" {...props}>
        <MenuButton as={GraneetButton} leftIcon="ri-more-line" variant="ghost" {...buttonProps} />

        <MenuList>{children}</MenuList>
      </Menu>
    </Flex>
  );
};

export const ActionMenu = Object.assign(ActionMenuComponent, {
  Separator,
  Edit,
  Duplicate,
  Delete,
  Action,
  configureDefaultLabels,
  Restore,
});
