import type { FC, ReactNode } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { Text, Flex, Box } from '@chakra-ui/react';

export interface HighlightProps extends FlexProps {
  label: string;
  value: ReactNode;
}

export const Highlight: FC<HighlightProps> = ({ label, value, ...props }) => (
  <Flex
    align="center"
    h="28px"
    bg="gray.100"
    display="inline-flex"
    px={4}
    fontWeight={600}
    color="black"
    whiteSpace="nowrap"
    borderRadius="md"
    fontSize="sm"
    {...props}
  >
    <Text mr={2}>{label}</Text>
    <Box>{value}</Box>
  </Flex>
);
