import { divideFloating, multiplyFloating } from '../common/math.util';
import { isNumberFinite } from '../common/number.util';

import type { ICurrency } from './currency.type';

/**
 * @example
 *  1773.99  => 177399
 * '1773.99' => 177399
 * !warning: this function is duplicated in packages/lib-ui/src/components/Currency/CurrencyProvider.ts
 */
export const mapAmountToNumber = (numberOrStringAmount: string | number, currencyPrecision: number): number =>
  multiplyFloating(
    isNumberFinite(numberOrStringAmount) ? numberOrStringAmount : Number(numberOrStringAmount),
    10 ** currencyPrecision,
  );

/**
 * @example
 * 177399 => 1773.99
 * !warning: this function is duplicated in packages/lib-ui/src/components/Currency/CurrencyProvider.ts
 */
export const mapNumberToAmount = (number: number, currencyPrecision: number): number =>
  divideFloating(number, 10 ** currencyPrecision);

/**
 * @example
 * '177399' => '1773.99'
 * !warning: this function is duplicated in packages/business-logic/currency/currency.utils.ts
 */
export const mapStringNumberToAmount = (stringNumber: string, currencyPrecision: number): string =>
  divideFloating(Number(stringNumber), 10 ** currencyPrecision).toString();

export const formatCurrency = (amount: number, currency: Pick<ICurrency, 'locale' | 'precision' | 'code'>): string =>
  new Intl.NumberFormat(currency.locale, {
    style: 'currency',
    currency: currency.code,
    minimumFractionDigits: currency.precision,
    maximumFractionDigits: currency.precision,
    notation: 'standard',
  }).format(mapNumberToAmount(amount, currency.precision));
