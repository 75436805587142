import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { Modal, SwitchField } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';

import { useUpdateQuoteDefaultAutomaticNumbering } from 'features/company/services/company.api';
import { Rule } from 'features/form/rules/Rule';

interface FormValues {
  isActivated: boolean;
}

export interface QuoteEditAutoNumberingModalProps {
  defaultValue: boolean;
  isOpen: boolean;
  onClose(): void;
}

export const QuoteEditAutoNumberingModal: FC<QuoteEditAutoNumberingModalProps> = ({
  isOpen,
  onClose,
  defaultValue,
}) => {
  const { t } = useTranslation(['global', 'quote']);
  const form = useForm<FormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  useEffect(() => {
    form.setFormValues({ isActivated: defaultValue });
  }, [defaultValue, form]);

  const updateQuoteDefaultAutomaticNumberingMutation = useUpdateQuoteDefaultAutomaticNumbering();

  const handleSubmit = useCallback(async () => {
    const { isActivated } = form.getFormValues();

    updateQuoteDefaultAutomaticNumberingMutation.mutate(
      {
        defaultQuoteAutomaticNumbering: isActivated!,
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  }, [form, onClose, updateQuoteDefaultAutomaticNumberingMutation]);

  return (
    <Form form={form}>
      <Modal isOpen={isOpen} onClose={onClose} title={t('quote:settings.autoNumbering.modal.title')} size="4xl">
        <Flex flexDirection="column" width="full">
          <Text mr={4}>{t('quote:settings.autoNumbering.modal.label')}</Text>
          <HStack mt="2rem" mb="1rem">
            <Box>
              <SwitchField<FormValues> name="isActivated" checkedValue uncheckedValue={false}>
                <Rule.IsRequired />
              </SwitchField>
            </Box>
            <Box>
              <Text width="full">{t('quote:settings.autoNumbering.modal.action')}</Text>
            </Box>
          </HStack>
        </Flex>
        <Modal.Close isDisabled={updateQuoteDefaultAutomaticNumberingMutation.isPending} />
        <Modal.PrimaryButton
          onClick={handleSubmit}
          isDisabled={!isFormValid}
          isLoading={updateQuoteDefaultAutomaticNumberingMutation.isPending}
        >
          {t('global:words.c.validate')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
