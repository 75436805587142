import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { TextField, PasswordField } from '@graneet/lib-ui';

import { Rule } from 'features/form/rules/Rule';
import type { OnboardingCredentialsForm } from 'features/onboarding/components/OnboardingCredentialsForm';

interface OnboardingCredentialsProps {
  emailDisabled: boolean;

  emailRules?: ReactNode;
}

export const OnboardingCredentials: FC<OnboardingCredentialsProps> = ({ emailDisabled, emailRules }) => {
  const { t } = useTranslation(['onboarding']);

  return (
    <>
      <Flex justifyContent="center">
        <Box w="25rem">
          <TextField<OnboardingCredentialsForm>
            name="email"
            label={t('onboarding:fields.email')}
            textAlign="left"
            isDisabled={emailDisabled}
          >
            {emailRules}
          </TextField>
          <Rule.IsEmail />
          <Rule.IsRequired />
        </Box>
      </Flex>

      <Flex justifyContent="center">
        <Box w="25rem">
          <PasswordField<OnboardingCredentialsForm> name="password" label={t('onboarding:fields.password')}>
            <Rule.IsRequired />
            <Rule.IsValidPassword />
          </PasswordField>
        </Box>
      </Flex>
    </>
  );
};
