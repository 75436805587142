import { useLayoutEffect, useMemo, useState } from 'react';
import { Box, HStack, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';

import type { GraneetBadgeColor } from '../Badge/Badge';
import { GraneetBadge } from '../Badge/Badge';
import { SimpleChevronBottomIcon } from '../../Icons/v2/SimpleChevronBottomIcon';
import { SimpleCheckCircleBlackIcon } from '../../Icons/v2/SimpleCheckCircleBlackIcon';

export type BadgeDropdownItem<T> = { color: GraneetBadgeColor } & {
  label: string;
  value: T;
};

export interface BadgeDropdownProps<T> {
  selectedValue?: T;
  placeholder?: string;
  items: BadgeDropdownItem<T>[];
  onChange: (value: T) => void;
}

export const BadgeDropdown = <T extends unknown>({
  items,
  selectedValue,
  placeholder,
  onChange,
}: BadgeDropdownProps<T>) => {
  const [currentValue, setCurrentValue] = useState<T | undefined>(selectedValue);

  const selectedItem = useMemo(() => items.find((item) => item.value === currentValue), [currentValue, items]);

  useLayoutEffect(() => {
    setCurrentValue(selectedValue);
  }, [selectedValue]);

  const { label, value, ...other } = selectedItem ?? {
    label: placeholder,
    value: undefined,
    color: 'gray',
  };

  return (
    <Menu gutter={4}>
      <MenuButton>
        <Box maxWidth="200px">
          <GraneetBadge {...other} icon={<SimpleChevronBottomIcon />}>
            {label}
          </GraneetBadge>
        </Box>
      </MenuButton>
      <Portal>
        <MenuList
          zIndex={1401}
          py="4px"
          minWidth="9.375rem"
          borderRadius="0.75rem"
          border="0.063rem solid var(--Base-Solid-Grey-Grey-200, #E5E7EB)"
          background="rgba(255, 255, 255, 0.88)"
          boxShadow="0px 4px 6px -2px rgba(13, 18, 28, 0.08), 0px 12px 16px -4px rgba(13, 18, 28, 0.14), 0px 0px 0px 2px #FFF inset"
          backdropFilter="blur(0.313rem)"
          maxHeight="15.6rem"
          overflowY="scroll"
        >
          {items.map((item) => (
            <MenuItem
              background="transparent"
              _hover={{
                background: 'rgba(0, 42, 85, 0.02)',
              }}
              key={`${item.value}`}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentValue(item.value);
                onChange(item.value);
              }}
              px="8px"
            >
              <HStack spacing={2} alignItems="center" width="100%">
                <Box maxWidth="200px">
                  <GraneetBadge {...item}>{item.label}</GraneetBadge>
                </Box>
                {item.value === currentValue ? (
                  <Box ml="auto" display="flex">
                    <SimpleCheckCircleBlackIcon />
                  </Box>
                ) : null}
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};
