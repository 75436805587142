import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MetricWithDescription, Card } from '@graneet/lib-ui';
import type { FC } from 'react';
import type { IDashboardResponseDTO } from '@graneet/business-logic';
import type { StatsDTO } from '@org/graneet-bff-client';

interface DashboardQuoteStatsCardProps {
  stats: IDashboardResponseDTO | null;
  quoteStats: StatsDTO | null;
}

export const DashboardQuoteStatsCard: FC<DashboardQuoteStatsCardProps> = ({ stats, quoteStats }) => {
  const { t } = useTranslation(['dashboard']);

  return (
    <Card>
      <HStack spacing="3rem">
        <MetricWithDescription description={t('dashboard:monthQuotes')}>
          <>{(stats?.countQuotesCreatedLastMonth ?? 0) + (quoteStats?.countQuotesCreatedLastMonth ?? 0)}</>
        </MetricWithDescription>
        <MetricWithDescription description={t('dashboard:monthAcceptedQuotes')}>
          <>{(stats?.countQuotesAcceptedLastMonth ?? 0) + (quoteStats?.countQuotesAcceptedLastMonth ?? 0)}</>
        </MetricWithDescription>
      </HStack>
    </Card>
  );
};
