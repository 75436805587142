import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import { DATE_FORMAT_API } from '../../../utils';

import { MonthlyTimeTableHeader } from './components/MonthlyTimeTableHeader';
import { MonthlyTimeTableContext } from './contexts';
import { MonthlyTimeTableRow } from './components/MonthlyTimeTableRow';

dayjs.extend(weekday);

export interface MonthlyTimeTableProps {
  startDate: Date;
  showWeekEnd: boolean;
  onNextMonth(date: string): void;
  onPreviousMonth(date: string): void;
  onWeekClick(date: Date): void;
  children: ReactNode;
}

const MonthlyTimeTableComponent: FC<MonthlyTimeTableProps> = ({
  startDate,
  onNextMonth,
  onPreviousMonth,
  onWeekClick,
  showWeekEnd,
  children,
}) => {
  const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>(dayjs(startDate).startOf('month'));
  const [numberOfDays, setNumberOfDays] = useState<number>(dayjs(startDate).daysInMonth());
  const [hoveredWeek, setHoveredWeek] = useState<number | null>(null);

  const handleHover = useCallback((week: number | null) => setHoveredWeek(week), [setHoveredWeek]);

  const handlePreviousMonth = useCallback(() => {
    const date = currentDate.subtract(1, 'month');

    setCurrentDate(date);
    setNumberOfDays(date.daysInMonth());
    onPreviousMonth(date.format(DATE_FORMAT_API));
  }, [setCurrentDate, setNumberOfDays, onPreviousMonth, currentDate]);

  const handleNextMonth = useCallback(() => {
    const date = currentDate.add(1, 'month');

    setCurrentDate(date);
    setNumberOfDays(date.daysInMonth());
    onNextMonth(date.format(DATE_FORMAT_API));
  }, [setCurrentDate, setNumberOfDays, onNextMonth, currentDate]);

  useEffect(() => {
    const daysInMonth = dayjs(currentDate).daysInMonth();

    let numberOfActiveDays = showWeekEnd ? daysInMonth : 0;

    if (!showWeekEnd) {
      for (let i = 0; i < daysInMonth; i += 1) {
        const day = currentDate.clone().add(i, 'day');
        if (![0, 6].includes(day.day())) {
          numberOfActiveDays += 1;
        }
      }
    }
    setNumberOfDays(numberOfActiveDays);
  }, [showWeekEnd, currentDate]);

  const weeksList = useMemo(() => {
    if (!numberOfDays) {
      return {};
    }
    const weeks: Record<string, { days: dayjs.Dayjs[]; week: number }> = {};

    const daysInMonth = dayjs(currentDate).daysInMonth();
    for (let i = 0; i < daysInMonth; i += 1) {
      const day = currentDate.clone().add(i, 'day');
      const weekNumber = `week_${day.week()}`;
      if (!weeks[weekNumber]) {
        weeks[weekNumber] = {
          days: [],
          week: day.week(),
        };
      }
      if (showWeekEnd) {
        weeks[weekNumber].days.push(day);
      } else if (![0, 6].includes(day.day())) {
        weeks[weekNumber].days.push(day);
      }
    }
    return weeks;
  }, [currentDate, numberOfDays, showWeekEnd]);

  const value = useMemo(
    () => ({
      numberOfDays,
      currentDate,
      startDate,
      showWeekEnd,
      hoveredWeek,
      onHoverWeek: handleHover,
      onWeekClick,
      weeksList,
    }),
    [numberOfDays, currentDate, startDate, showWeekEnd, hoveredWeek, handleHover, weeksList, onWeekClick],
  );

  return (
    <MonthlyTimeTableContext.Provider value={value}>
      <Box bg="white">
        <Flex direction="row" justifyContent="flex-end" alignItems="center" />

        <Divider />

        <MonthlyTimeTableHeader
          onPreviousMonth={handlePreviousMonth}
          onNextMonth={handleNextMonth}
          currentDate={currentDate}
        />

        {children}
        <Divider />
      </Box>
    </MonthlyTimeTableContext.Provider>
  );
};

export const MonthlyTimeTable = Object.assign(MonthlyTimeTableComponent, {
  Row: MonthlyTimeTableRow,
});
