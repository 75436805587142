import type { FC } from 'react';
import { Box, GridItem } from '@chakra-ui/react';
import type { IProject } from '@graneet/business-logic';
import { isProjectActive, SUB_PROJECT_BILLING_TYPE } from '@graneet/business-logic';
import { Placeholder, TwoColumns } from '@graneet/lib-ui';

import { PROJECT_PLACEHOLDER_KEY } from 'features/project/constants/project.constant';
import {
  useIsProjectReadyForFinalStatement,
  useProjectFinalStatementsVersions,
} from 'features/final-statement/services/final-statement.api';
import { useProjectSummaryStats } from 'features/project/services/project.api';
import { BillAwaitingPaymentAmountCard } from 'features/bill/components/BillAwaitingPaymentAmountCard';
import { useSubProjectsByProject } from 'features/sub-project/services/sub-project.api';
import { DashboardTimelineCard } from 'features/dashboard/components/DashboardTimelineCard';
import { FinalStatementCreateButton } from 'features/final-statement/components/FinalStatementCreateButton';
import { FinalStatementVersionsCard } from 'features/final-statement/components/FinalStatementVersionsCard';
import { ProjectSummaryStatsCard } from 'features/project/components/cards/ProjectSummaryStatsCard/ProjectSummaryStatsCard';
import { ProjectCommentCard } from 'features/project/components/cards/ProjectCommentCard';
import { ProjectAddFirstContractCard } from 'features/project/components/cards/ProjectAddFirstContractCard';
import { ProjectBudgetConsumptionCard } from 'features/project/components/cards/ProjectBudgetConsumptionCard';
import { ProjectBilledVersusSpentStatsCard } from 'features/project/components/cards/ProjectBilledVersusSpentStatsCard';
import { ProjectCloseButton } from 'features/project/components/ProjectCloseButton';
import { ProjectReopenButton } from 'features/project/components/ProjectReopenButton';
import { ProjectAcceptanceReportCardWithEmptyState } from 'features/project/components/ProjectAcceptanceReportCardWithEmptyState/ProjectAcceptanceReportCardWithEmptyState';
import { ProjectBudgetAnalysisStatsCard } from 'features/project/components/cards/ProjectBudgetAnalysisStatsCard';
import { SupplierInvoiceAwaitingPaymentAmountCard } from 'features/supplier-invoice/components/cards/SupplierInvoiceAwaitingPaymentAmountCard';

export interface ViewProjectSummarySubTabProps {
  project: IProject;
}
export const ViewProjectSummarySubTab: FC<ViewProjectSummarySubTabProps> = ({ project }) => {
  const subProjects = useSubProjectsByProject(project.id);
  const finalStatements = useProjectFinalStatementsVersions(project.id);
  const isProjectReadyForFinalStatement = useIsProjectReadyForFinalStatement(project.id);
  const projectSummaryStats = useProjectSummaryStats(project.id);

  // Display graphs only if the project has at least one subproject, so one contract
  const shouldDisplayGraphs = subProjects.data.length > 0;

  const isProjectAtOnGoingStatus = isProjectActive(project);
  const hasOnlyDirectBillingSubProjects =
    subProjects.data.every((subProject) => subProject.billingType === SUB_PROJECT_BILLING_TYPE.DIRECT) || false;

  return (
    <>
      <Placeholder.Inject name={PROJECT_PLACEHOLDER_KEY.INFORMATION_BUTTON}>
        <Box mt={-5} mr={5}>
          <FinalStatementCreateButton
            project={project}
            subProjects={subProjects.data}
            finalStatements={finalStatements.data}
            finalStatementCheckDTO={isProjectReadyForFinalStatement.data}
          />
          <ProjectCloseButton
            project={project}
            subProjects={subProjects.data}
            finalStatements={finalStatements.data}
            finalStatementCheckDTO={isProjectReadyForFinalStatement.data}
          />
          <ProjectReopenButton project={project} />
        </Box>
      </Placeholder.Inject>

      <TwoColumns>
        {/* Final statements & project closing handle */}
        {finalStatements && finalStatements.data.current && !hasOnlyDirectBillingSubProjects && (
          <GridItem colSpan={isProjectAtOnGoingStatus ? 2 : 1}>
            <FinalStatementVersionsCard
              project={project}
              finalStatements={finalStatements.data}
              finalStatementCheckDTO={isProjectReadyForFinalStatement.data}
            />
          </GridItem>
        )}
        {!isProjectAtOnGoingStatus && (
          <GridItem colSpan={hasOnlyDirectBillingSubProjects ? 2 : 1}>
            <ProjectAcceptanceReportCardWithEmptyState project={project} acceptanceReport={project.acceptanceReport} />
          </GridItem>
        )}

        {/* Amounts card & Comment card */}
        {/* Box is needed to keep card size static */}
        <Box>
          <ProjectSummaryStatsCard stats={projectSummaryStats.data} />
        </Box>

        <ProjectCommentCard project={project} />

        {shouldDisplayGraphs && (
          <Box>
            <SupplierInvoiceAwaitingPaymentAmountCard
              amount={projectSummaryStats.data.amountSupplierInvoiceNotPaidIncVAT}
              amountLateIncVAT={projectSummaryStats.data.amountSupplierInvoicesLateIncVAT}
              amountToPayInLessThanThirtyDaysIncVAT={
                projectSummaryStats.data.amountSupplierInvoicesToPayInLessThanThirtyDaysIncVAT
              }
              amountToPayInMoreThanThirtyDaysIncVAT={
                projectSummaryStats.data.amountSupplierInvoicesToPayInMoreThanThirtyDaysIncVAT
              }
              amountWithoutPaymentDateIncVAT={projectSummaryStats.data.amountSupplierInvoicesWithoutPaymentDateIncVAT}
              redirectionLink={`/purchases/cash-outflows?_search=${project.name}`}
            />
          </Box>
        )}

        {shouldDisplayGraphs && (
          <Box>
            <BillAwaitingPaymentAmountCard
              amount={projectSummaryStats.data.remainingToBePaidIncVAT}
              amountLateIncVAT={projectSummaryStats.data.amountBillsLateIncVAT}
              amountToPayInLessThanThirtyDaysIncVAT={
                projectSummaryStats.data.amountBillsToPayInLessThanThirtyDaysIncVAT
              }
              amountToPayInMoreThanThirtyDaysIncVAT={
                projectSummaryStats.data.amountBillsToPayInMoreThanThirtyDaysIncVAT
              }
              redirectionLink={`/sales/bills?_search=${project.name}`}
            />
          </Box>
        )}

        {/* If graphs are not displayed, display empty state */}
        {!shouldDisplayGraphs && (
          <GridItem colSpan={2}>
            <ProjectAddFirstContractCard project={project} />
          </GridItem>
        )}

        {shouldDisplayGraphs && (
          <>
            <ProjectBudgetConsumptionCard stats={projectSummaryStats.data} project={project} />
            <ProjectBilledVersusSpentStatsCard stats={projectSummaryStats.data} />

            <ProjectBudgetAnalysisStatsCard
              project={project}
              numberOfBudgets={projectSummaryStats.data.numberOfBudgets}
            />
            <DashboardTimelineCard months={6} monthsIncrement={3} project={project} />
            <GridItem />
          </>
        )}
      </TwoColumns>
    </>
  );
};
