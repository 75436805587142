import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Callout, Modal, SimpleAlertIcon, SimpleCheckCircleIcon } from '@graneet/lib-ui';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box, CircularProgress, VStack } from '@chakra-ui/react';
import type { IPdf, IQuote, IQuoteGenerateExportDTO } from '@graneet/business-logic';
import { SUPPORT_EMAIL } from '@graneet/business-logic';

import { EXPORT_FILE_TYPES } from '../../constants/quote-export.constant';
import type { QuoteExportForm } from '../../forms/quote-export-edition.wizard';
import {
  generateImagesExportFieldName,
  generateIsNoteSelectedExportFieldName,
  getJobIdFromIsJobSelectedFieldName,
  getLotIdFromIsJobSelectedFieldName,
  isJobSelectedFieldName,
  isLotSelectedFieldName,
} from '../../forms/quote-export-edition.wizard';

import { generatePDFExport, generateSpreadsheetExport } from 'features/quote/services/quote.api';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import { downloadFile, formatFileName } from 'features/file/services/file.util';
import { getDownloadUrl } from 'features/pdf/services/pdf.api';
import { TIME_DISPLAY_LOADER } from 'features/common/constants/loading.constant';

interface QuoteExportGenerateModalProps {
  modal: UseDisclosureReturn;

  quoteId: number;

  quoteLastVersion: IQuote;

  exportFormValues: QuoteExportForm | null;
}

export const QuoteExportGenerateModal: FC<QuoteExportGenerateModalProps> = ({
  modal,
  quoteId,
  quoteLastVersion,
  exportFormValues,
}) => {
  const { t } = useTranslation(['quote']);
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const { onClose, isOpen } = modal;

  const handleLoaded = useCallback(() => {
    setIsLoaded(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(null);
  }, [timeoutId]);

  const generateExportDTO = useCallback<() => IQuoteGenerateExportDTO>(() => {
    if (!exportFormValues) {
      throw new Error();
    }

    const { note, ...selectedItems } = exportFormValues;
    const { notes, images, infos, components } = exportFormValues.exportItems || {};
    const { lots, jobs } = Object.keys(selectedItems).reduce<{
      jobs: Record<string, { note: boolean; images: boolean }>;
      lots: Record<string, { note: boolean }>;
    }>(
      (acc, formKey) => {
        if (isJobSelectedFieldName(formKey) && [true, 'partial'].includes(selectedItems[formKey])) {
          const jobId = getJobIdFromIsJobSelectedFieldName(formKey);

          const isNoteSelected = !!selectedItems[generateIsNoteSelectedExportFieldName(jobId, 'job')];
          const isImagesSelected = !!selectedItems[generateImagesExportFieldName(jobId)];

          acc.jobs[jobId] = { note: isNoteSelected, images: isImagesSelected };
        }

        if (isLotSelectedFieldName(formKey) && [true, 'partial'].includes(selectedItems[formKey])) {
          const lotId = getLotIdFromIsJobSelectedFieldName(formKey);

          const isNoteSelected = !!selectedItems[generateIsNoteSelectedExportFieldName(lotId, 'lot')];

          acc.lots[lotId] = { note: isNoteSelected };
        }
        return acc;
      },
      {
        lots: {},
        jobs: {},
      },
    );

    const dto: IQuoteGenerateExportDTO = {
      quoteId,
      options: {
        columns: {
          refCode: !!exportFormValues.exportItems?.refCode,
          amountExVAT: !!exportFormValues.exportItems?.amountExVAT,
          disbursementExVAT: !!exportFormValues.exportItems?.disbursementExVAT,
          jobVAT: !!exportFormValues.exportItems?.jobVAT,
          unitDisbursementExVAT: !!exportFormValues.exportItems?.unitDisbursementExVAT,
          unit: !!exportFormValues.exportItems?.unit,
          unitPriceExVAT: !!exportFormValues.exportItems?.unitPriceExVAT,
          jobMargin: !!exportFormValues.exportItems?.jobMargin,
          quantity: !!exportFormValues.exportItems?.quantity,
          workForce: !!exportFormValues.exportItems?.workForce,
        },
        notes: notes ?? true,
        images: images ?? true,
        infos: infos ?? true,
        components,
      },
      export: {
        lots,
        jobs,
      },
      comment: note,
    };

    return dto;
  }, [exportFormValues, quoteId]);

  const handlePDFDownload = useCallback(
    (pdf: IPdf) => () => {
      const filename = formatFileName(quoteLastVersion.name, quoteLastVersion.date, quoteLastVersion.refCode);
      const downloadUrl = getDownloadUrl(pdf.apiId, filename);

      // Browser download
      downloadFile(downloadUrl!, filename);

      handleLoaded();
    },
    [handleLoaded, quoteLastVersion.date, quoteLastVersion.name, quoteLastVersion.refCode],
  );

  const generateExport = useCallback(() => {
    if (isLoaded || timeoutId || !exportFormValues) {
      // Already generated or started
      return;
    }

    const timeoutKey = setTimeout(async () => {
      const exportDto = generateExportDTO();

      if (exportFormValues.fileType === EXPORT_FILE_TYPES.PDF) {
        const [err, pdf] = await generatePDFExport(exportDto);

        if (err) {
          setIsError(true);
          handleLoaded();
          return;
        }

        // Listen to server side event and then download the generated PDF file
        subscribeToPDFUpdates(pdf, handlePDFDownload(pdf));
      }

      if (exportFormValues.fileType === EXPORT_FILE_TYPES.SPREADSHEET) {
        const [err, xlsUrl] = await generateSpreadsheetExport(exportDto);

        if (err) {
          setIsError(true);
          handleLoaded();
          return;
        }

        downloadFile(xlsUrl);
        handleLoaded();
      }
    }, TIME_DISPLAY_LOADER);

    setTimeoutId(timeoutKey);
  }, [exportFormValues, generateExportDTO, handleLoaded, handlePDFDownload, isLoaded, timeoutId]);

  const handleReset = useCallback(() => {
    if (isLoaded) {
      onClose();
      setIsError(false);
      setIsLoaded(false);
    }
  }, [isLoaded, onClose]);

  const handleBackToQuote = useCallback(() => {
    history.push(`/opportunities/quotes/${quoteLastVersion ? quoteLastVersion.id : quoteId}`);
  }, [history, quoteLastVersion, quoteId]);

  useEffect(() => {
    if (!timeoutId && isOpen) {
      generateExport();

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }

    return () => {};
  }, [generateExport, isLoaded, isOpen, timeoutId]);

  return (
    <Modal
      size="2xl"
      closeOnEsc={isLoaded}
      closeOnOverlayClick={isLoaded}
      isOpen={isOpen}
      onClose={handleReset}
      title={t('quote:export.generateExportModal.title')}
    >
      {!isLoaded ? (
        <VStack spacing={6} mb={4}>
          <CircularProgress size={70} color="gray.800" isIndeterminate />
          <Box>{t('quote:export.generateExportModal.pending')}</Box>
        </VStack>
      ) : (
        <Callout
          mb={4}
          colorScheme={isError ? 'red' : 'green'}
          icon={
            isError ? (
              <SimpleAlertIcon stroke="red.500" boxSize={5} />
            ) : (
              <SimpleCheckCircleIcon stroke="green.500" boxSize={5} />
            )
          }
        >
          {isError
            ? t('quote:export.generateExportModal.error', { email: SUPPORT_EMAIL })
            : t('quote:export.generateExportModal.success')}
        </Callout>
      )}

      <Modal.SecondaryButton isDisabled={!isLoaded} onClick={handleReset}>
        {t('quote:export.generateExportModal.actions.generate')}
      </Modal.SecondaryButton>

      <Modal.PrimaryButton isDisabled={!isLoaded} onClick={handleBackToQuote}>
        {t('quote:export.generateExportModal.actions.backToQuote')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
