import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ImportSpreadsheetField } from 'features/import/components/SpreadsheetImport';
import { IMPORT_EMAIL_REGEX } from 'features/import/constants/import.constant';

export type ImportContactField = 'firstName' | 'lastName' | 'role' | 'email' | 'phoneNumber';

export const useImportContactFields = (): ImportSpreadsheetField<ImportContactField>[] => {
  const { t } = useTranslation(['global', 'contacts']);

  return useMemo<ImportSpreadsheetField<ImportContactField>[]>(
    () => [
      {
        label: t('contacts:fields.lastName'),
        key: 'lastName',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Nom*'], // Column name in template
        example: t('contacts:import.example.lastName'),
        validations: [
          {
            rule: 'required',
            errorMessage: t('global:spreadsheetImport.common.errorMandatory'),
          },
        ],
      },
      {
        label: t('contacts:fields.firstName'),
        key: 'firstName',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Prénom'],
        example: t('contacts:import.example.firstName'),
      },
      {
        label: t('contacts:fields.role'),
        key: 'role',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Rôle'],
        example: t('contacts:import.example.role'),
      },
      {
        label: t('contacts:fields.email'),
        key: 'email',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Email'],
        example: t('contacts:import.example.email'),
        validations: [
          {
            rule: 'regex',
            value: IMPORT_EMAIL_REGEX(true),
            errorMessage: t('global:spreadsheetImport.common.errorEmail'),
            level: 'error',
          },
        ],
      },
      {
        label: t('contacts:fields.phoneNumber'),
        key: 'phoneNumber',
        fieldType: {
          type: 'input',
        },
        alternateMatches: ['Téléphone'],
        example: t('contacts:import.example.phoneNumber'),
      },
    ],
    [t],
  );
};
