import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { StepperModal, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';

import {
  useContactCreate,
  useContactDissociateFromClient,
  useContactDissociateFromSupplier,
  useContactsAssociateToClient,
  useContactsAssociateToSupplier,
  useContactUpdate,
} from '../../../services/contact.api';
import { CLIENT_OR_SUPPLIER_ASSOCIATION } from '../../forms/ClientOrSupplierAssociationForm';
import { QueryWrapper } from '../../../../api/components/QueryWrapper';

import { ContactUpsertStep } from './steps/ContactUpsertStep';
import { ContactAssociateStep } from './steps/ContactAssociateStep';
import type { ContactUpsertWizard } from './ContactUpsertWizard';

export interface ContactUpsertStepperModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  contact: IContact | null;
  roles: string[] | null;
}

export const ContactUpsertStepperModal: FC<ContactUpsertStepperModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  contact,
  roles,
}) => {
  const toast = useToast();
  const { t } = useTranslation(['global', 'contacts']);

  const [firstStepContact, setFirstStepContact] = useState<IContact | null>(null);

  const contactCreateMutation = useContactCreate();
  const contactUpdateMutation = useContactUpdate();
  const contactsAssociateToClientMutation = useContactsAssociateToClient();
  const contactsAssociateToSupplierMutation = useContactsAssociateToSupplier();
  const contactDissociateFromClientMutation = useContactDissociateFromClient();
  const contactDissociateFromSupplierMutation = useContactDissociateFromSupplier();

  const onNext = useCallback(
    async (values: ContactUpsertWizard['create-contact'] | undefined) => {
      if (!values) {
        return false;
      }

      const { firstName, lastName, role, email, phoneNumber } = values;

      const body = {
        firstName: firstName || undefined,
        lastName,
        role: role || undefined,
        email: email || undefined,
        phoneNumber: phoneNumber || undefined,
      };

      let updatedContact: IContact;
      if (contact) {
        updatedContact = await contactUpdateMutation.mutateAsync({ id: contact.id, dto: body });
      } else {
        updatedContact = await contactCreateMutation.mutateAsync(body);
      }

      toast.success(
        t(contact ? 'contacts:editionModal.toastSuccess' : 'contacts:creationModal.toastSuccess', {
          firstName: updatedContact.firstName,
          lastName: updatedContact.lastName,
        }),
      );
      setFirstStepContact(updatedContact);
      onSuccess();

      return true;
    },
    [contact, contactCreateMutation, contactUpdateMutation, onSuccess, t, toast],
  );

  const onFinish = useCallback(
    async (wizardValues: ContactUpsertWizard) => {
      if (!firstStepContact) {
        return;
      }

      const { association } = wizardValues['associate-contact'];

      if (firstStepContact.client) {
        await contactDissociateFromClientMutation.mutateAsync({
          contactId: firstStepContact.id,
          clientId: firstStepContact.client.id,
        });
      }
      if (firstStepContact.supplier) {
        await contactDissociateFromSupplierMutation.mutateAsync({
          contactId: firstStepContact.id,
          supplierId: firstStepContact.supplier.id,
        });
      }

      if (association?.type === CLIENT_OR_SUPPLIER_ASSOCIATION.CLIENT) {
        await contactsAssociateToClientMutation.mutateAsync({
          id: association.id,
          dto: {
            contacts: [
              {
                id: firstStepContact.id,
                isDefaultEmailRecipient: false,
              },
            ],
          },
        });
      }
      if (association?.type === CLIENT_OR_SUPPLIER_ASSOCIATION.SUPPLIER) {
        await contactsAssociateToSupplierMutation.mutateAsync({
          id: association.id,
          dto: { contacts: [{ id: firstStepContact.id }] },
        });
      }

      onSuccess();
      onClose();
    },
    [
      contactDissociateFromClientMutation,
      contactDissociateFromSupplierMutation,
      contactsAssociateToClientMutation,
      contactsAssociateToSupplierMutation,
      firstStepContact,
      onClose,
      onSuccess,
    ],
  );

  return (
    <StepperModal<ContactUpsertWizard> isOpen={isOpen} onClose={onClose} onFinish={onFinish} size="3xl">
      <StepperModal.Step<ContactUpsertWizard, 'create-contact'>
        name="create-contact"
        title={t(contact ? 'contacts:steps.updateContact' : 'contacts:steps.createContact')}
        onNext={onNext}
      >
        <QueryWrapper>
          <ContactUpsertStep contact={contact} roles={roles} />
        </QueryWrapper>
      </StepperModal.Step>

      <StepperModal.Step<ContactUpsertWizard, 'associate-contact'>
        name="associate-contact"
        title={t('contacts:steps.associate')}
      >
        <ContactAssociateStep contact={firstStepContact} />
      </StepperModal.Step>
    </StepperModal>
  );
};
