import type { FC } from 'react';
import { useState, useCallback } from 'react';
import { Center, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button, Logo, TextField } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';

import { ExternalMarketingModal } from '../../external/components/ExternalMarketingModal';

import { Rule } from 'features/form/rules/Rule';

type ValidationForm = {
  name: string;
  email: string;
};

interface ProgressStatementExternalValidationModalProps {
  isApproval: boolean;

  onSubmit: (formValues: ValidationForm) => Promise<void>;

  onClose: () => void;

  isOpen: boolean;
}

export const ProgressStatementExternalValidationModal: FC<ProgressStatementExternalValidationModalProps> = ({
  isApproval,
  onSubmit,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation(['progressStatement']);
  const form = useForm<ValidationForm>();
  const { isValid } = useFormStatus(form);

  const [isSent, setIsSent] = useState(false);

  // Callbacks
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback(
    async (formValues: ValidationForm) => {
      setIsSubmitting(true);
      await onSubmit(formValues);
      setIsSubmitting(false);
      setIsSent(true);
    },
    [onSubmit],
  );

  const onGoToGraneet = useCallback(() => {
    window.open('https://www.graneet.com');
  }, []);

  if (isSent) {
    return (
      <ExternalMarketingModal
        isOpen={isOpen}
        onClose={onClose}
        icon={isApproval ? 'completed' : 'cancelled'}
        title={t(`progressStatement:external.confirmationModal.${isApproval ? 'titleApproval' : 'titleRejection'}`)}
      >
        <Text fontSize="sm">{t('progressStatement:external.confirmationModal.description')}</Text>
        <Text fontSize="sm" color="gray.600">
          {t('progressStatement:external.confirmationModal.about')}
        </Text>
        <Center>
          <Button variant="outline" w="50%" onClick={onGoToGraneet}>
            {t('progressStatement:external.confirmationModal.discover')}
            <Logo boxSize="64px" ml={2} />
          </Button>
        </Center>
      </ExternalMarketingModal>
    );
  }

  return (
    <ExternalMarketingModal
      isOpen={isOpen}
      onClose={onClose}
      icon="draft"
      title={t('progressStatement:external.validationModal.title')}
    >
      <Text fontSize="sm">{t('progressStatement:external.validationModal.description')}</Text>
      <Form form={form} onSubmit={form.handleSubmit(handleSubmit)}>
        <Flex direction="column" gap={4}>
          <TextField<ValidationForm> name="name" label={t('progressStatement:external.confirmationModal.name')}>
            <Rule.IsRequired />
          </TextField>
          <TextField<ValidationForm> name="email" label={t('progressStatement:external.confirmationModal.email')}>
            <Rule.IsRequired />
            <Rule.IsEmail />
          </TextField>

          <Button isDisabled={!isValid} type="submit" isLoading={isSubmitting}>
            {t(`progressStatement:external.actions.${isApproval ? 'approve' : 'reject'}`)}
          </Button>
        </Flex>
      </Form>
    </ExternalMarketingModal>
  );
};
