import type { FieldValues, AnyRecord } from 'graneet-form';
import { Field, VALIDATION_OUTCOME, composeEventHandlers } from 'graneet-form';
import type { ReactNode } from 'react';

import type { KeysMatching } from '../../../utils';
import type { EmailAutocompleteProps } from '../../EmailAutocomplete';
import { EmailAutocomplete } from '../../EmailAutocomplete';
import type { EmailAutocompleteContact } from '../../EmailAutocomplete/EmailAutocomplete.type';

export type EmailAutocompleteFieldValue = EmailAutocompleteContact[] | undefined;

export interface EmailAutocompleteFieldProps<
  T extends FieldValues,
  K extends KeysMatching<T, EmailAutocompleteFieldValue> = KeysMatching<T, EmailAutocompleteFieldValue>,
> extends Omit<EmailAutocompleteProps, 'onChange' | 'value'> {
  name: K;

  data?: AnyRecord;

  hideErrorMessage?: boolean;

  onChange?(value: T[K]): void | Promise<void>;

  children?: ReactNode;
}

export const EmailAutocompleteField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, EmailAutocompleteFieldValue> = KeysMatching<T, EmailAutocompleteFieldValue>,
>({
  name,
  data,
  onBlur,
  onChange,
  onFocus,
  hideErrorMessage = false,
  children,
  ...rest
}: EmailAutocompleteFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    data={data}
    render={(properties, state) => {
      const { onChange: onChangeForm, onBlur: onBlurForm, onFocus: onFocusForm, value } = properties;
      const { validationStatus, isPristine } = state;
      const shouldDisplayError = validationStatus.status === VALIDATION_OUTCOME.INVALID;

      return (
        <EmailAutocomplete
          {...rest}
          shouldDisplayError={!isPristine && shouldDisplayError && !hideErrorMessage}
          errorMessage={validationStatus.message}
          onChange={composeEventHandlers(onChange, onChangeForm) as any}
          onBlur={composeEventHandlers(onBlur, onBlurForm)}
          onFocus={composeEventHandlers(onFocus, onFocusForm)}
          value={value || []}
        />
      );
    }}
  >
    {children}
  </Field>
);
