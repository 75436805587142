import {
  Callout,
  DroppableFileField,
  Fieldset,
  formatDateOrEmpty,
  SimpleHelpIcon,
  TextAreaField,
  Tooltip,
} from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { canProjectBeImported } from '@graneet/business-logic';

import type { ProjectCreateForm } from '../../forms/project-create-wizard';

import { useAppContext } from 'features/app/contexts/AppContext';
import { Rule } from 'features/form/rules/Rule';

export const ProjectImportFileFieldset = () => {
  const { t } = useTranslation(['project']);

  const { currentUser } = useAppContext();

  if (!currentUser?.company) {
    return null;
  }

  const isProjectImportable = canProjectBeImported(currentUser.company);
  if (isProjectImportable.status === 'ko') {
    return null;
  }

  return (
    <Fieldset<ProjectCreateForm>
      handle="projectImportFile"
      title={t('project:flow.importFile.title')}
      legend={t('project:flow.importFile.legend')}
      validationNames={[]}
    >
      <Flex direction="column" gap={3}>
        <DroppableFileField<ProjectCreateForm> name="importFile">
          <Rule.IsFileSizeValid />
        </DroppableFileField>

        <Callout icon={<InfoIcon boxSize={5} />}>
          {t('project:flow.importFile.toast', {
            numberOfProjectsImported: currentUser.company.consumedProjectImportCredit,
            count: currentUser.company.limitProjectImportCredit,
            date: formatDateOrEmpty(currentUser.company.limitDateProjectImportCredit),
          })}
        </Callout>

        <TextAreaField<ProjectCreateForm>
          name="importComment"
          label={t('project:fields.importComment')}
          rightIcon={
            <Tooltip label={<Trans t={t} i18nKey="project:flow.importFile.commentTooltip" />} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          }
        />
      </Flex>
    </Fieldset>
  );
};
