import { ListingLayout, Button, ConfirmDeletionModal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import type { IWorker } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { useWorkersRoles, useWorkers, useWorkerDelete } from 'features/worker/services/worker.api';
import { WorkerUpsertModal } from 'features/worker/components/WorkerUpsertModal';
import { WorkersTable } from 'features/worker/components/WorkersTable';

export const ViewWorkersScreen = () => {
  const { t } = useTranslation(['global', 'worker']);

  const getCreationButtonProps = useDisabledButtonProps([PERMISSION.ACCESS_WORKER_HOURLY_PRICE]);

  const upsertModal = useDisclosure();
  const deletionModal = useDisclosure();
  const [selectedWorker, setSelectedWorker] = useState<IWorker | null>(null);

  const workers = useWorkers();
  const workersRoles = useWorkersRoles();

  const workerDeleteMutation = useWorkerDelete();

  const handleUpsertModalClose = useCallback(() => {
    setSelectedWorker(null);
    upsertModal.onClose();
  }, [upsertModal]);

  const handleWorkerToEditSelection = useCallback(
    (worker: IWorker) => {
      setSelectedWorker(worker);
      upsertModal.onOpen();
    },
    [upsertModal],
  );

  const handleWorkerToDeleteSelection = useCallback(
    (worker: IWorker) => {
      setSelectedWorker(worker);
      deletionModal.onOpen();
    },
    [deletionModal],
  );

  const handleDeletionModalClose = useCallback(() => {
    setSelectedWorker(null);
    deletionModal.onClose();
  }, [deletionModal]);

  const handleWorkerDeletion = useCallback(async () => {
    if (selectedWorker) {
      await workerDeleteMutation.mutateAsync(selectedWorker);
      setSelectedWorker(null);
      deletionModal.onClose();
      workers.refetch();
    }
  }, [deletionModal, selectedWorker, workerDeleteMutation, workers]);

  return (
    <>
      <ListingLayout
        pagination={workers}
        search={{ placeholder: t('worker:search') }}
        actions={
          <Button onClick={upsertModal.onOpen} {...getCreationButtonProps()}>
            {t('worker:create')}
          </Button>
        }
        content={
          <WorkersTable
            workers={workers}
            onWorkerToEditSelected={handleWorkerToEditSelection}
            onWorkerToDeleteSelected={handleWorkerToDeleteSelection}
          />
        }
      />

      <WorkerUpsertModal
        isOpen={upsertModal.isOpen}
        onSuccess={workers.refetch}
        onClose={handleUpsertModalClose}
        worker={selectedWorker}
        roles={workersRoles.data}
      />

      <ConfirmDeletionModal
        isOpen={deletionModal.isOpen}
        onClose={handleDeletionModalClose}
        title={t('worker:deletionModal.title')}
        action={t('worker:deletionModal.action')}
        description={t('worker:deletionModal.description')}
        onDeleted={handleWorkerDeletion}
      />
    </>
  );
};
