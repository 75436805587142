import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const MailIcon: FC<IconProps> = ({ color = 'grey.600', ...rest }) => (
  <Icon viewBox="0 0 20 20" color={color} {...rest}>
    <path
      fill="currentColor"
      d="m18.475 14.375-5.542-5a.833.833 0 0 0-1.183.058.824.824 0 0 0 .058 1.175l5.534 5a.832.832 0 0 0 1.175-.067.842.842 0 0 0-.067-1.183l.025.017Zm-11.417-5-5.541 5a.831.831 0 0 0-.067 1.175.838.838 0 0 0 1.175.058l5.533-5a.849.849 0 0 0 .059-1.183.842.842 0 0 0-1.184-.067l.025.017ZM1.183 6.508l6.8 4.758c.784.55.992.675 1.4.775.392.092.809.092 1.209-.008.4-.108.608-.233 1.4-.783l6.8-4.767a.84.84 0 0 0 .2-1.167.834.834 0 0 0-1.167-.208l-6.808 4.758c-.592.409-.734.492-.85.525a1.121 1.121 0 0 1-.409 0c-.125-.033-.266-.116-.85-.533L2.1 5.09a.838.838 0 0 0-1.167.2.83.83 0 0 0 .2 1.159l.05.058ZM5.658 17.49h8.667c1.917 0 2.367-.041 3.008-.366a3.357 3.357 0 0 0 1.45-1.459c.325-.641.359-1.1.359-3.016V7.316c0-1.925-.042-2.375-.367-3.016a3.333 3.333 0 0 0-1.458-1.459c-.642-.333-1.1-.366-3.017-.366H5.633c-1.925 0-2.375.033-3.016.358a3.273 3.273 0 0 0-1.459 1.45C.825 4.916.792 5.375.792 7.29v5.334c0 1.916.033 2.366.358 3.008a3.296 3.296 0 0 0 1.45 1.45c.633.325 1.092.358 3.008.358l.05.05Zm0-1.666c-1.583 0-1.966-.034-2.258-.184a1.685 1.685 0 0 1-.733-.733c-.159-.3-.184-.675-.184-2.258V7.316c0-1.583.025-1.966.175-2.258.159-.317.409-.575.725-.733.292-.159.667-.184 2.25-.184H14.3c1.575 0 1.958.025 2.25.175.308.159.567.409.725.725.15.292.175.667.175 2.25v5.334c0 1.575-.033 1.958-.183 2.25a1.71 1.71 0 0 1-.734.725c-.3.15-.675.175-2.258.175H5.608l.05.05Z"
    />
  </Icon>
);
