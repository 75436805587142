import type { FC } from 'react';
import { useCallback } from 'react';
import { Card, CheckboxField, DataTable, SwitchField, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Flex, GridItem, Stack, Text } from '@chakra-ui/react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { FEATURE_FLAGS, VAT_TYPE, type IAccountingDataResponseDTO, type IVat } from '@graneet/business-logic';

import type { AccountingSalesEditionBlockForm } from '../../../forms/accounting.form';
import { getAccountingVatAccountWorkFieldName } from '../../../forms/accounting.form';

import { AccountingClientEditionButton } from './AccountingClientEditionButton';

import { Rule } from 'features/form/rules/Rule';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { sortVatList } from 'features/accounting/services/accounting-export.util';

const AccountingWorkRows: FC<{
  vats: Pick<IVat, 'id' | 'name' | 'rate' | 'type'>[];
  hideSplitSalesWorkAsBases?: boolean;
  haveRequiredFields?: boolean;
  mustDisplayField(fieldName: keyof AccountingSalesEditionBlockForm): boolean;
}> = ({ vats, hideSplitSalesWorkAsBases, mustDisplayField, haveRequiredFields }) => {
  const { t } = useTranslation(['accounting']);
  const hasAccountingStandards = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARDS);

  const form = useFormContext<AccountingSalesEditionBlockForm>();
  const { splitSalesWorkAsBases } = useOnChangeValues(form, ['splitSalesWorkAsBases']);

  return (
    <>
      {(!splitSalesWorkAsBases || hideSplitSalesWorkAsBases) && mustDisplayField('accountSalesWork') && (
        <DataTable.Row label={t('accounting:fields.accountSalesWork')}>
          <DataTable.Cell>
            <TextField<AccountingSalesEditionBlockForm>
              name="accountSalesWork"
              placeholder={t('accounting:parameters.configPlaceholder')}
            >
              {haveRequiredFields && <Rule.IsRequired />}
            </TextField>
          </DataTable.Cell>
        </DataTable.Row>
      )}

      {!hasAccountingStandards &&
        (!!splitSalesWorkAsBases || hideSplitSalesWorkAsBases) &&
        mustDisplayField('accountSalesWorkReversalOfLiability') && (
          <DataTable.Row label={t('accounting:fields.accountSalesWorkReversalOfLiability')}>
            <DataTable.Cell>
              <TextField<AccountingSalesEditionBlockForm>
                name="accountSalesWorkReversalOfLiability"
                placeholder={t('accounting:parameters.configPlaceholder')}
              >
                {haveRequiredFields && <Rule.IsRequired />}
              </TextField>
            </DataTable.Cell>
          </DataTable.Row>
        )}

      {(!!splitSalesWorkAsBases || hideSplitSalesWorkAsBases) &&
        vats.sort(sortVatList).map((vat) => {
          if ((!hasAccountingStandards && vat.type !== VAT_TYPE.NORMAL) || vat.type === VAT_TYPE.INTRA_COMMUNITY) {
            return null;
          }

          return (
            <DataTable.Row
              key={vat.id}
              label={t(`accounting:fields.vatAccountWork.${vat.type}`, {
                name: vat.name,
              })}
            >
              <DataTable.Cell>
                <TextField<AccountingSalesEditionBlockForm>
                  name={getAccountingVatAccountWorkFieldName(vat.id)}
                  placeholder={t('accounting:parameters.configPlaceholder')}
                >
                  {haveRequiredFields && <Rule.IsRequired />}
                </TextField>
              </DataTable.Cell>
            </DataTable.Row>
          );
        })}
    </>
  );
};

interface AccountingSalesEditionBlockBaseProps {
  vats: Pick<IVat, 'id' | 'type' | 'name' | 'rate'>[];

  mustDisplayFields?: Partial<Record<keyof AccountingSalesEditionBlockForm, boolean>>;

  haveRequiredFields?: boolean;

  hideSplitSalesWorkAsBases?: boolean;

  hideSplitProrataWithVAT?: boolean;

  hideUseClientCodeWithHoldback?: boolean;

  hideSeparator?: boolean;
}

interface AccountingSalesEditionBlockWithClientProps extends AccountingSalesEditionBlockBaseProps {
  clients: IAccountingDataResponseDTO['clients'];
}

export const AccountingSalesEditionBlock: FC<
  AccountingSalesEditionBlockBaseProps | AccountingSalesEditionBlockWithClientProps
> = ({
  vats,
  haveRequiredFields,
  mustDisplayFields,
  hideSplitSalesWorkAsBases = false,
  hideSplitProrataWithVAT = false,
  hideUseClientCodeWithHoldback = false,
  hideSeparator = false,
  ...otherProps
}) => {
  const { t } = useTranslation(['accounting']);
  const hasAccountingStandards = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARDS);

  const mustDisplayVats = vats.reduce(
    (acc, vat) => {
      acc[getAccountingVatAccountWorkFieldName(vat.id)] = true;
      return acc;
    },
    {} as Record<string, boolean>,
  );

  const mustDisplayField = useCallback(
    (fieldName: keyof AccountingSalesEditionBlockForm) => {
      if (!mustDisplayFields) {
        return true;
      }

      return !!mustDisplayFields[fieldName] || !!mustDisplayVats[fieldName];
    },
    [mustDisplayFields, mustDisplayVats],
  );

  return (
    <Stack>
      <Text fontSize="lg" color="gray.800">
        {t('accounting:parameters.sections.sales')}
      </Text>

      <Card>
        {!hideSplitSalesWorkAsBases && (
          <>
            <Box color="black">
              <SwitchField<AccountingSalesEditionBlockForm>
                name="splitSalesWorkAsBases"
                label={t('accounting:fields.splitSalesWorkAsBases')}
                uncheckedValue={false}
                checkedValue
              />
            </Box>
            <Text pb={2}>{t('accounting:parameters.splitSalesWorkAsBases')}</Text>
          </>
        )}

        <DataTable numberOfColumns={1}>
          <AccountingWorkRows
            vats={vats}
            hideSplitSalesWorkAsBases={hideSplitSalesWorkAsBases}
            haveRequiredFields={haveRequiredFields}
            mustDisplayField={mustDisplayField}
          />

          {!hideSeparator && <DataTable.Separator />}

          {mustDisplayField('accountSalesDownPayment') && (
            <DataTable.Row label={t('accounting:fields.accountSalesDownPayment')}>
              <DataTable.Cell>
                <TextField<AccountingSalesEditionBlockForm>
                  name="accountSalesDownPayment"
                  placeholder={t('accounting:parameters.configPlaceholder')}
                >
                  {haveRequiredFields && <Rule.IsRequired />}
                </TextField>
              </DataTable.Cell>
            </DataTable.Row>
          )}

          {mustDisplayField('accountSalesPriceRevision') && (
            <DataTable.Row label={t('accounting:fields.accountSalesPriceRevision')}>
              <DataTable.Cell>
                <TextField<AccountingSalesEditionBlockForm>
                  name="accountSalesPriceRevision"
                  placeholder={t('accounting:parameters.configPlaceholder')}
                >
                  {haveRequiredFields && <Rule.IsRequired />}
                </TextField>
              </DataTable.Cell>
            </DataTable.Row>
          )}

          {mustDisplayField('accountSalesDirectPayment') && (
            <DataTable.Row label={t('accounting:fields.accountSalesDirectPayment')}>
              <DataTable.Cell>
                <TextField<AccountingSalesEditionBlockForm>
                  name="accountSalesDirectPayment"
                  placeholder={t('accounting:parameters.configPlaceholder')}
                />
              </DataTable.Cell>
            </DataTable.Row>
          )}

          {mustDisplayField('accountSalesOtherDeduction') && (
            <DataTable.Row label={t('accounting:fields.accountSalesOtherDeduction')}>
              <DataTable.Cell>
                <TextField<AccountingSalesEditionBlockForm>
                  name="accountSalesOtherDeduction"
                  placeholder={t('accounting:parameters.configPlaceholder')}
                >
                  {haveRequiredFields && <Rule.IsRequired />}
                </TextField>
              </DataTable.Cell>
            </DataTable.Row>
          )}

          {!hideSeparator && <DataTable.Separator />}

          {mustDisplayField('accountSalesProrataAccount') && (
            <DataTable.Row label={t('accounting:fields.accountSalesProrataAccount')}>
              <DataTable.Cell>
                <TextField<AccountingSalesEditionBlockForm>
                  name="accountSalesProrataAccount"
                  placeholder={t('accounting:parameters.configPlaceholder')}
                />
              </DataTable.Cell>
            </DataTable.Row>
          )}

          {!hideSplitProrataWithVAT && (
            <GridItem colSpan={2} color="gray.800">
              <CheckboxField<AccountingSalesEditionBlockForm>
                name="splitProrataWithVAT"
                label={t('accounting:fields.splitProrataWithVAT')}
              />
            </GridItem>
          )}

          {!hideSeparator && <DataTable.Separator />}

          {mustDisplayField('accountSalesHoldback') && (
            <DataTable.Row label={t('accounting:fields.accountSalesHoldback')}>
              <DataTable.Cell>
                <TextField<AccountingSalesEditionBlockForm>
                  name="accountSalesHoldback"
                  placeholder={t('accounting:parameters.configPlaceholder')}
                >
                  {haveRequiredFields && <Rule.IsRequired />}
                </TextField>
              </DataTable.Cell>
            </DataTable.Row>
          )}

          {!hideUseClientCodeWithHoldback && hasAccountingStandards && (
            <GridItem colSpan={2} color="gray.800">
              <CheckboxField<AccountingSalesEditionBlockForm>
                name="useClientCodeWithHoldback"
                label={t('accounting:fields.useClientCodeWithHoldback')}
              />
            </GridItem>
          )}

          {!hideSeparator && <DataTable.Separator />}

          {mustDisplayField('accountClient') && (
            <DataTable.Row label={t('accounting:fields.accountClient')}>
              <DataTable.Cell>
                <Flex gap={3}>
                  <TextField<AccountingSalesEditionBlockForm>
                    name="accountClient"
                    placeholder={t('accounting:parameters.configPlaceholder')}
                  >
                    {haveRequiredFields && <Rule.IsRequired />}
                  </TextField>
                  {'clients' in otherProps && <AccountingClientEditionButton clients={otherProps.clients} />}
                </Flex>
              </DataTable.Cell>
            </DataTable.Row>
          )}
        </DataTable>
      </Card>
    </Stack>
  );
};
