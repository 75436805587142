import type { FC } from 'react';
import { DateField, TextField, Card, Tooltip, SimpleHelpIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, Wrap, WrapItem } from '@chakra-ui/react';

import type { ContractEditionForm } from '../forms/contract-edition.form';

import { Rule } from 'features/form/rules/Rule';

export const ContractEditionCard: FC = () => {
  const { t } = useTranslation(['contracts']);

  return (
    <Card>
      <Wrap spacing={8}>
        <WrapItem minW="18rem" flexGrow={1}>
          <TextField<ContractEditionForm>
            name="name"
            label={t('contracts:fields.contractName')}
            rightIcon={
              <Tooltip label={t('contracts:tooltips.informationName')} placement="right-end">
                <Box ml={2}>
                  <SimpleHelpIcon boxSize={5} />
                </Box>
              </Tooltip>
            }
            isRequired
          >
            <Rule.IsRequired />
          </TextField>
        </WrapItem>

        <WrapItem minW="18rem">
          <TextField<ContractEditionForm>
            name="code"
            label={t('contracts:fields.contractCode')}
            rightIcon={
              <Tooltip label={t('contracts:tooltips.informationCode')} placement="right-end">
                <Box ml={2}>
                  <SimpleHelpIcon boxSize={5} />
                </Box>
              </Tooltip>
            }
          />
        </WrapItem>

        <WrapItem minW="18rem">
          <DateField<ContractEditionForm>
            name="receptionDate"
            label={t('contracts:fields.receptionDate')}
            width="100%"
            rightIcon={
              <Tooltip label={t('contracts:tooltips.informationReceptionDate')} placement="right-end">
                <Box ml={2}>
                  <SimpleHelpIcon boxSize={5} />
                </Box>
              </Tooltip>
            }
            isRequired
          >
            <Rule.IsRequired />
          </DateField>
        </WrapItem>
      </Wrap>
    </Card>
  );
};
