import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import type { Response } from '@graneet/lib-ui';
import { ChapterCollapse } from '@graneet/lib-ui';
import { isQuotationInformationEditable } from '@graneet/business-logic';
import type { QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';

import { TextEditionCard } from 'features/common/components/TextEditionCard';
import { useQuotationApi } from 'features/quotation/services/quote.api';

interface QuoteNoteCardProps {
  quote: QuoteWithoutRelationsDTO;
  isReadOnly: boolean;
}

export const QuoteNoteCard: FC<QuoteNoteCardProps> = ({ quote, isReadOnly }) => {
  const { t } = useTranslation(['quote']);
  const isEditable = isQuotationInformationEditable(quote);

  const { useQuotePartialUpdate } = useQuotationApi();
  const quotePartialUpdateMutation = useQuotePartialUpdate();

  const updateNote = useCallback(
    async (note: string) => {
      const res = await quotePartialUpdateMutation.mutateAsync({ quoteId: quote.id, note });
      return [null, res] as Response<QuoteWithoutRelationsDTO>;
    },
    [quote, quotePartialUpdateMutation],
  );

  return (
    <ChapterCollapse
      title={t('quote:cards.note.title')}
      description={<Trans t={t} i18nKey="quote:cards.note.description" />}
    >
      <TextEditionCard
        title={t('quote:cards.note.title')}
        text={quote.note}
        onTextEdited={updateNote}
        errorLabel={t('quote:cards.note.error')}
        successLabel={t('quote:cards.note.success')}
        placeholder={t('quote:cards.note.placeholder')}
        isEditable={isEditable && !isReadOnly}
      />
    </ChapterCollapse>
  );
};
