import { Box, Flex } from '@chakra-ui/react';
import { Multiplication } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { bool, func, string } from 'prop-types';

import { Rule } from 'features/form/rules/Rule';
import { MARGIN_FIELD_NAME } from 'features/margin/services/margin.constants';

export const QuoteSalesSheetMarginMultiplication = ({
  onFocus,
  onBlur,
  onChange,
  setMarginComputedValue,
  marginComputedValue,
  currentEditField,
  isEditionDisabled,
}) => {
  const { t } = useTranslation(['margin']);

  const form = useFormContext();
  const values = useOnChangeValues(form, [
    MARGIN_FIELD_NAME.OVERHEAD_COSTS,
    MARGIN_FIELD_NAME.PROFIT_MARGIN,
    MARGIN_FIELD_NAME.TOTAL_MARGIN,
  ]);

  return (
    <Flex backgroundColor="gray.100" alignItems="center" borderRadius="md" py={3}>
      <Box maxW="35rem" mr={4}>
        <Multiplication setComputedElement={setMarginComputedValue} computedElement={marginComputedValue}>
          <Multiplication.Field
            name={MARGIN_FIELD_NAME.OVERHEAD_COSTS}
            label={t('margin:fields.overheadCosts')}
            computedName={MARGIN_FIELD_NAME.TOTAL_MARGIN}
            onChange={(value) => {
              onChange(MARGIN_FIELD_NAME.OVERHEAD_COSTS, value);
            }}
            onFocus={onFocus(MARGIN_FIELD_NAME.OVERHEAD_COSTS, values[MARGIN_FIELD_NAME.OVERHEAD_COSTS])}
            onBlur={onBlur()}
            min={0}
            scale={3}
            isDisabled={isEditionDisabled && currentEditField !== MARGIN_FIELD_NAME.OVERHEAD_COSTS}
          >
            <Rule.IsRequired />
            <Rule.IsNotZero />
          </Multiplication.Field>

          <Multiplication.Field
            name={MARGIN_FIELD_NAME.PROFIT_MARGIN}
            label={t('margin:fields.profitMargin')}
            computedName={MARGIN_FIELD_NAME.TOTAL_MARGIN}
            onChange={(value) => {
              onChange(MARGIN_FIELD_NAME.PROFIT_MARGIN, value);
            }}
            onFocus={onFocus(MARGIN_FIELD_NAME.PROFIT_MARGIN, values[MARGIN_FIELD_NAME.PROFIT_MARGIN])}
            onBlur={onBlur()}
            min={0}
            scale={3}
            isDisabled={isEditionDisabled && currentEditField !== MARGIN_FIELD_NAME.PROFIT_MARGIN}
          >
            <Rule.IsRequired />
            <Rule.IsNotZero />
          </Multiplication.Field>

          <Multiplication.Field
            name={MARGIN_FIELD_NAME.TOTAL_MARGIN}
            label={t('margin:fields.totalMargin')}
            computedName={MARGIN_FIELD_NAME.PROFIT_MARGIN}
            onChange={(value) => {
              onChange(MARGIN_FIELD_NAME.TOTAL_MARGIN, value);
            }}
            onFocus={onFocus(MARGIN_FIELD_NAME.TOTAL_MARGIN, values[MARGIN_FIELD_NAME.TOTAL_MARGIN])}
            onBlur={onBlur()}
            min={0}
            scale={3}
            isDisabled={isEditionDisabled && currentEditField !== MARGIN_FIELD_NAME.TOTAL_MARGIN}
          >
            <Rule.IsRequired />
            <Rule.IsNotZero />
          </Multiplication.Field>
        </Multiplication>
      </Box>
    </Flex>
  );
};

QuoteSalesSheetMarginMultiplication.propTypes = {
  onFocus: func.isRequired,
  onBlur: func,
  onChange: func,
  setMarginComputedValue: func.isRequired,
  marginComputedValue: string,
  currentEditField: string,
  isEditionDisabled: bool,
};

QuoteSalesSheetMarginMultiplication.defaultProps = {
  marginComputedValue: undefined,
  currentEditField: undefined,
  isEditionDisabled: false,
  onBlur: () => {},
  onChange: () => {},
};
