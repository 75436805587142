import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { genericUpdateItemMapper } from 'features/quotation/quote-common/mappers/quoteItemsGenericMapper';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteHiddenCostItemUpdateManualIndex() {
  const { quoteHiddenCostItemsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, index: string | null): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteHiddenCostItem = quote.getTree().getQuoteHiddenCostOrThrow(nodeId);
          const quoteNode = quoteHiddenCostItem.getNodeOrThrow();
          this.oldValue = quoteHiddenCostItem.getManualIndex();

          quoteHiddenCostItem.updateProperties({
            manualIndex: index,
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteHiddenCostItemsProxyApi
            .updateQuoteHiddenCostItem(quoteHiddenCostItem.getId(), {
              ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
              manualIndex: index,
              quoteHiddenCostItemId: quoteHiddenCostItem.getId(),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteHiddenCostItem = quote.getTree().getQuoteHiddenCostOrThrow(nodeId);
          const quoteNode = quoteHiddenCostItem.getNodeOrThrow();

          quoteHiddenCostItem.updateProperties({
            manualIndex: this.oldValue,
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteHiddenCostItemsProxyApi
            .updateQuoteHiddenCostItem(quoteHiddenCostItem.getId(), {
              ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
              manualIndex: this.oldValue,
              quoteHiddenCostItemId: quoteHiddenCostItem.getId(),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteHiddenCostItemsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
