import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { QuoteWithoutRelationsDTO } from '@org/graneet-bff-client';

import { useQuotationApi } from 'features/quotation/services/quote.api';

interface EditQuoteStatusModalProps {
  quote: QuoteWithoutRelationsDTO;
  onSuccess(quoteId: string): void;
  modalControls: UseDisclosureReturn;
}

export const EditQuoteStatusModal: FC<EditQuoteStatusModalProps> = ({ quote, onSuccess, modalControls }) => {
  const { t } = useTranslation(['global', 'quote']);
  const { useQuoteChangeStatusToDraft } = useQuotationApi();
  const changeStatusToDraftMutation = useQuoteChangeStatusToDraft();

  const [isLoading, setIsLoading] = useState(false);
  const { onClose, isOpen } = modalControls;

  const handleQuoteCopy = useCallback(async () => {
    setIsLoading(true);

    const quoteCopy = await changeStatusToDraftMutation.mutateAsync(quote.id);

    onSuccess(quoteCopy.id);
    setIsLoading(false);
  }, [changeStatusToDraftMutation, onSuccess, quote.id]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('quote:editVersionModal.title')} size="3xl">
      <Box>{t('quote:editVersionModal.body')}</Box>

      <Modal.Close />

      <Modal.PrimaryButton isLoading={isLoading} onClick={handleQuoteCopy}>
        {t('quote:actions.modify')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
