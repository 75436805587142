import { Box, GridItem, useDisclosure, VStack } from '@chakra-ui/react';
import { ActionMenu, Badge, CogIcon, formatDateOrEmpty, TwoColumns, useToast } from '@graneet/lib-ui';
import type {
  IProjectWithRelations,
  IQuote,
  IQuoteLot,
  QuoteArchiveDetails,
  IPdf,
  IQuoteUpdateDTO,
  IContactQuoteResponseDTO,
} from '@graneet/business-logic';
import {
  getEmailVariableData,
  HISTORY_ENTITY_TYPE,
  PERMISSION,
  QUOTE_STATUS,
  SUPPORT_EMAIL,
} from '@graneet/business-logic';
import type { FC } from 'react';
import { useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { QuoteProjectUpdateCallout } from 'features/quote/components/QuoteProjectUpdateCallout';
import { EmailProvider } from 'features/history/contexts/EmailContext';
import { HistoryCard } from 'features/history/components/HistoryCard';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { duplicateQuote, getQuoteVersions, restoreQuote } from 'features/quote/services/quote.api';
import { usePdfVersions } from 'features/pdf/hooks/usePdfVersions';
import { QuoteTagsCard } from 'features/quote/components/cards/QuoteTagsCard';
import { useEmailTemplateRichTextConfiguration } from 'features/email-template/hooks/useEmailTemplateRichTextConfiguration';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { CreateQuoteModal } from 'features/quote/components/modals/CreateQuoteModal';
import { RestoreQuoteModal } from 'features/quote/components/modals/RestoreQuoteModal';
import { QuoteStatusCard } from 'features/quote/components/cards/QuoteStatusCard';
import { QuoteInformationCard } from 'features/quote/components/cards/QuoteInformationCard';
import { QuoteMarginCard } from 'features/quote/components/cards/QuoteMarginCard';
import { QuoteCommentCard } from 'features/quote/components/cards/QuoteCommentCard';

type IQuoteArchiveDetailsExtended = Omit<QuoteArchiveDetails, 'pdfId'> & { pdf: IPdf | null };

interface ViewQuoteSummaryTabProps {
  quote: IQuote;

  quoteContacts: IContactQuoteResponseDTO;

  project: IProjectWithRelations | null;

  reloadQuote(): void;

  rootLot?: IQuoteLot;
}

export const ViewQuoteSummaryTab: FC<ViewQuoteSummaryTabProps> = ({
  quote,
  quoteContacts,
  project,
  reloadQuote,
  rootLot,
}) => {
  const getVersions = useDataGetter(getQuoteVersions, quote.id);
  const quoteVersions = usePdfVersions(getVersions);
  const history = useHistory();
  const toast = useToast();
  const { t } = useTranslation(['global', 'quote']);
  const selectedQuoteIdRef = useRef<number>();
  const hasPermissions = usePermissions([PERMISSION.CREATE_QUOTES]);
  const duplicationModalControls = useDisclosure();
  const onRestoreModalControls = useDisclosure();

  const emailTemplateRichTextConfiguration = useEmailTemplateRichTextConfiguration(
    getEmailVariableData('quote', {
      quote,
      project,
      client: quote.client!,
    }),
  );

  const onReloadQuote = useCallback(() => {
    quoteVersions.fetch();
    reloadQuote();
  }, [quoteVersions, reloadQuote]);

  const hasPdfUpdatedFromProject = !!quoteVersions.versions?.current?.isContractVersion;

  const redirectToExport = useCallback(
    (quoteId: number) => () => {
      history.push(`/opportunities/quotes/${quoteId}/export`);
    },
    [history],
  );

  const redirectToQuoteDetail = useCallback(
    (quoteId: number, currentQuoteId: number) => () => {
      history.push(`/opportunities/quotes/${quoteId}/view`, { currentQuoteVersionId: currentQuoteId });
    },
    [history],
  );

  const onQuoteDuplication = useCallback(
    async (values: IQuoteUpdateDTO) => {
      if (selectedQuoteIdRef.current) {
        const [err, duplicatedQuote] = await duplicateQuote(selectedQuoteIdRef.current, values);
        if (err) {
          toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
          return;
        }

        toast.success(t('global:words.c.success'), t('quote:creationForm.duplication.toastSuccess'));
        history.push(`/opportunities/quotes/${duplicatedQuote.id}`);
      }
    },
    [history, t, toast],
  );

  const onQuoteRestore = useCallback(async () => {
    if (
      !selectedQuoteIdRef.current ||
      (quote.status !== QUOTE_STATUS.DRAFT && quote.status !== QUOTE_STATUS.COMPLETED)
    ) {
      return;
    }

    const response = await restoreQuote({
      toRestoreQuoteId: selectedQuoteIdRef.current,
    });

    if (response[0]) {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
      return;
    }

    toast.success(
      t('global:words.c.success'),
      t('quote:restore.toastSuccess', { date: formatDateOrEmpty(response[1].restoreDate) }),
    );

    if (selectedQuoteIdRef.current !== response[1].quoteIdRestored) {
      history.push(`/opportunities/quotes/${response[1].quoteIdRestored}`);
    }
  }, [history, quote.status, t, toast]);

  const addQuoteActionsProps = useCallback(
    (pdfVersion: IQuoteArchiveDetailsExtended) => {
      if (!hasPermissions || !pdfVersion) {
        return null;
      }

      if (pdfVersion.isContractVersion) {
        return {
          // eslint-disable-next-line react/no-unstable-nested-components
          rightContent: () => <Box w={6} />,
          additionalContent: (
            <Badge showDot colorScheme="gray">
              {t('quote:versions.updateDuringProject')}
            </Badge>
          ),
        };
      }

      const onDuplicate = () => {
        selectedQuoteIdRef.current = pdfVersion.quoteId as number;
        duplicationModalControls.onOpen();
      };

      const onRestore = () => {
        selectedQuoteIdRef.current = pdfVersion.quoteId as number;

        if (quote.status === QUOTE_STATUS.DRAFT) {
          onRestoreModalControls.onOpen();
          return;
        }
        (async () => onQuoteRestore())();
      };

      return {
        // eslint-disable-next-line react/no-unstable-nested-components
        rightContent: () => (
          <ActionMenu>
            <ActionMenu.Action
              icon={<SearchIcon />}
              label={t('quote:versions.viewQuotationDetail')}
              onClick={redirectToQuoteDetail(pdfVersion.quoteId! as number, quote.id)}
            />

            <ActionMenu.Action
              icon={<CogIcon />}
              label={t('quote:export.action')}
              onClick={redirectToExport(pdfVersion.quoteId! as number)}
            />
            <ActionMenu.Restore
              label={t('quote:restore.action')}
              tooltip={
                !(quote.status === QUOTE_STATUS.COMPLETED || quote.status === QUOTE_STATUS.DRAFT)
                  ? t('quote:restore.tooltip')
                  : undefined
              }
              onClick={onRestore}
              disabled={!(quote.status === QUOTE_STATUS.COMPLETED || quote.status === QUOTE_STATUS.DRAFT)}
            />
            <ActionMenu.Duplicate onClick={onDuplicate} />
          </ActionMenu>
        ),
      };
    },
    [
      duplicationModalControls,
      hasPermissions,
      quote.id,
      onQuoteRestore,
      onRestoreModalControls,
      quote.status,
      redirectToExport,
      redirectToQuoteDetail,
      t,
    ],
  );

  return (
    <EmailProvider>
      <CreateQuoteModal
        title={t('quote:creationForm.duplication.title')}
        description={t('quote:creationForm.duplication.description')}
        action={t('quote:creationForm.duplication.submit')}
        onSubmit={onQuoteDuplication}
        {...duplicationModalControls}
      />
      <RestoreQuoteModal
        {...onRestoreModalControls}
        title={t('quote:restore.modal.title')}
        description={t('quote:restore.modal.description')}
        action={t('quote:restore.modal.action')}
        onSubmit={onQuoteRestore}
      />

      {hasPdfUpdatedFromProject && (
        <Box mb={6}>
          <QuoteProjectUpdateCallout />
        </Box>
      )}

      <TwoColumns>
        <QuoteStatusCard
          quote={quote}
          project={project}
          currentVersion={quoteVersions.versions?.current ?? undefined}
          reloadQuote={onReloadQuote}
          quoteContacts={quoteContacts}
        />
        <QuoteInformationCard quote={quote} />
      </TwoColumns>

      <TwoColumns mt={4}>
        <GridItem>
          <VStack gap={4}>
            {rootLot && (
              <GridItem width="100%">
                <QuoteMarginCard quote={quote} rootLot={rootLot} />
              </GridItem>
            )}
            <GridItem width="100%">
              <QuoteTagsCard quote={quote} />
            </GridItem>
          </VStack>
        </GridItem>
        <GridItem>
          <QuoteCommentCard reloadQuote={reloadQuote} quote={quote} />
        </GridItem>
      </TwoColumns>

      <HistoryCard
        id={quote.id}
        entity={HISTORY_ENTITY_TYPE.QUOTE}
        emailTemplateRichTextConfiguration={emailTemplateRichTextConfiguration}
        cardPosition={{ mt: 4 }}
        addActionsProps={(archiveDetails) => addQuoteActionsProps(archiveDetails as IQuoteArchiveDetailsExtended)}
      />
    </EmailProvider>
  );
};
