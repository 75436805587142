import { create } from 'zustand';

import { EXPORT_FILE_TYPES } from '../constants/quote-export.constant';

interface QuoteExportFileTypeStore {
  fileType: EXPORT_FILE_TYPES;
  changeFileType(fielType: EXPORT_FILE_TYPES): void;
}

export const useQuoteExportFileTypeStore = create<QuoteExportFileTypeStore>((set) => ({
  fileType: EXPORT_FILE_TYPES.PDF,
  changeFileType(fileType: EXPORT_FILE_TYPES) {
    set({ fileType });
  },
}));
