import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getEnvValue } from 'config/env';
import { useAppContext } from 'features/app/contexts/AppContext';

export type vitallyScoredText = string | ((infos: { productName: string; score: number }) => string);

export type Vitally = {
  // @see : https://docs.vitally.io/pushing-data-to-vitally/using-the-vitally-api/vitallys-javascript-sdk-vitally.js
  init: (token: string) => void;
  account: (config: { accountId: string | number; traits: { name: string; avatar?: string | null } }) => void;
  user: (config: { userId: string; traits: Record<string, string | number> }) => void;
  // @see : https://docs.vitally.io/using-vitallys-nps-surveys/installing-and-configuring-nps-surveys#installing-the-nps-script
  nps: (
    name: 'survey' | 'test',
    config: Partial<{
      productName: string;
      delay: number;
      primaryColor: string;
      npsQuestion: string | (() => string);
      followUpTitle: vitallyScoredText;
      followUpSubtitle: vitallyScoredText;
      thanksTitle: vitallyScoredText;
      thanksSubtitle: vitallyScoredText;
      placeholderText: vitallyScoredText;
      minLabel: string | (() => string);
      maxLabel: string | (() => string);
      submitText: string | (() => string);
      dismissText: string | (() => string);
    }>,
  ) => void;
};

export const VitallyNps = () => {
  const vitallyToken = getEnvValue('REACT_APP_VITALLY_TOKEN', false);
  const vitallyDisplay = getEnvValue('REACT_APP_VITALLY_DISPLAY', false) || 'hide';
  const { t } = useTranslation(['global']);
  const { currentUser } = useAppContext();
  const [vitally, setVitally] = useState<Vitally | null>(null);

  const getTextForScore = useCallback(
    (
      score: number,
      key: 'followUpTitle' | 'followUpSubtitle' | 'thanksTitle' | 'thanksSubtitle' | 'placeholderText',
    ) => {
      if (score < 7) {
        return t(`global:vitally.scores.low.${key}`);
      }
      if (score < 9) {
        return t(`global:vitally.scores.avg.${key}`);
      }
      // score >= 9
      return t(`global:vitally.scores.high.${key}`);
    },
    [t],
  );

  const onVitallyLoaded = useCallback(() => {
    setVitally((window as any).Vitally);
  }, []);

  useEffect(() => {
    if (vitally && currentUser && currentUser.isDisplayed) {
      vitally.init(vitallyToken!);
      vitally.account({
        accountId: currentUser.company.id,
        traits: { name: currentUser.company.name, avatar: currentUser.company.logo?.url },
      });
      vitally.user({
        userId: currentUser.auth0ID,
        traits: {
          companyId: currentUser.company.id,
          companyName: currentUser.company.name,
          id: currentUser.id,
          email: currentUser.email,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        },
      });
      // This function is run only if vitallyDisplay is 'survey' or 'test'. because elsewher, vitally.js is not loaded.
      // and vitallyRef.current is null
      vitally.nps(vitallyDisplay as 'survey' | 'test', {
        productName: 'Graneet',
        primaryColor: '#439CEA',
        npsQuestion: t('global:vitally.question'),
        minLabel: t('global:vitally.minLabel'),
        maxLabel: t('global:vitally.maxLabel'),
        submitText: t('global:vitally.submitText'),
        dismissText: t('global:vitally.dismissText'),
        followUpTitle: ({ score }) => getTextForScore(score, 'followUpTitle'),
        followUpSubtitle: ({ score }) => getTextForScore(score, 'followUpSubtitle'),
        thanksTitle: ({ score }) => getTextForScore(score, 'thanksTitle'),
        thanksSubtitle: ({ score }) => getTextForScore(score, 'thanksSubtitle'),
        placeholderText: ({ score }) => getTextForScore(score, 'placeholderText'),
      });
    }
  }, [vitally, currentUser, vitallyToken, t, getTextForScore, vitallyDisplay]);

  useEffect(() => {
    let script: HTMLScriptElement;
    if (vitallyToken && ['test', 'survey'].includes(vitallyDisplay)) {
      script = document.createElement('script');
      script.src = 'https://cdn.vitally.io/vitally.js/v1/vitally.js';
      script.addEventListener('load', onVitallyLoaded);

      document.body.appendChild(script);
    }

    return () => {
      if (script && document.body.contains(script)) {
        script.removeEventListener('load', onVitallyLoaded);
        document.body.removeChild(script);
      }
    };
  }, [vitallyToken, vitallyDisplay, onVitallyLoaded]);

  return null;
};
