import type { FC, ReactNode } from 'react';
import { useState, useCallback, useContext, useMemo, createContext } from 'react';

interface IHeaderContext {
  title: string | null;

  breadCrumb: Array<{
    link?: string;
    name: string;
  }>;

  customElement: ReactNode;

  updateHeaderTitle(
    title: string | null,
    breadCrumb?: {
      link?: string;
      name: string;
    }[],
    customElement?: ReactNode,
  ): void;
}
const HeaderContext = createContext<IHeaderContext>({
  title: '',
  breadCrumb: [],
  customElement: null,
  updateHeaderTitle: () => {},
});

export const useHeaderContext = () => useContext(HeaderContext);

export const HeaderProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<Omit<IHeaderContext, 'updateHeaderTitle'>>({
    title: null,
    breadCrumb: [],
    customElement: null,
  });

  const updateHeaderTitle = useCallback(
    (
      title: string | null,
      breadCrumb?: {
        link?: string;
        name: string;
      }[],
      customElement?: ReactNode,
    ) => {
      setState({
        title: title ?? null,
        breadCrumb: breadCrumb ?? [],
        customElement: customElement ?? null,
      });
    },
    [],
  );

  const value = useMemo(
    () => ({
      updateHeaderTitle,
      title: state.title,
      breadCrumb: state.breadCrumb,
      customElement: state.customElement,
    }),
    [state.breadCrumb, state.customElement, state.title, updateHeaderTitle],
  );

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};
