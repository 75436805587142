import { Callout, CheckboxField, ModalSubtitle } from '@graneet/lib-ui';
import { Stack } from '@chakra-ui/react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type { RoleFormFields } from '../../constants/role.constant';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const RoleEditModalVisibility = () => {
  const { t } = useTranslation(['user']);

  const form = useFormContext<RoleFormFields>();
  const { viewAllQuotesAndProjects: isAccessQuotesAccessProjectsEnabled } = useOnChangeValues(form, [
    'viewAllQuotesAndProjects',
  ]);

  const hasUserPermissionsFeatureFlag = useFeatureFlag(FEATURE_FLAGS.USER_PERMISSIONS);
  if (!hasUserPermissionsFeatureFlag) {
    return null;
  }

  return (
    <Stack direction="column" gap={0}>
      <ModalSubtitle mb={2}>{t('user:editRoleModal.sections.visibility')}</ModalSubtitle>

      <Stack direction="column" gap={1} pl={3}>
        <CheckboxField<RoleFormFields>
          name="viewAllQuotesAndProjects"
          label={t('user:permissions.VIEW_ALL_QUOTES_AND_PROJECTS')}
        />
        {!isAccessQuotesAccessProjectsEnabled && (
          <Callout>{t('user:role.VIEW_ALL_QUOTES_AND_PROJECTS.callout')}</Callout>
        )}

        <CheckboxField<RoleFormFields> name="accessDashboards" label={t('user:permissions.ACCESS_DASHBOARDS')} />
      </Stack>
    </Stack>
  );
};
