import { Tooltip } from '@graneet/lib-ui';
import type { FlexProps } from '@chakra-ui/react';
import { Avatar, AvatarGroup, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { IUser } from '@graneet/business-logic';

import { formatUserDisplayName, sortUsers } from '../services/user.util';

interface UserAvatarsProps extends FlexProps {
  users: Pick<IUser, 'id' | 'firstName' | 'lastName'>[];
  displayTooltip?: boolean;
}

export const UserAvatars: FC<UserAvatarsProps> = ({ users, displayTooltip = true, ...otherProps }) => {
  const sortedUsers = useMemo(() => sortUsers(users), [users]);

  if (!users.length) {
    return null;
  }
  const tooltipContent = displayTooltip ? sortedUsers.map(formatUserDisplayName).join(', ') : '';

  return (
    <Flex display="inline-block" {...otherProps}>
      <Tooltip label={tooltipContent} placement="top">
        <AvatarGroup max={1} size="sm" spacing="-2">
          {sortedUsers.map((user) => (
            <Avatar key={user.id} name={formatUserDisplayName(user)} />
          ))}
        </AvatarGroup>
      </Tooltip>
    </Flex>
  );
};
