import { HStack, Center, Divider, Text } from '@chakra-ui/react';
import { PopUpMenu, GraneetIconButton, SimpleCloseIcon, useHotkeys } from '@graneet/lib-ui';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { QuoteNodeObject } from '@org/quotation-lib';

import { useQuote } from '../../hooks/useQuote';

import { useStore } from 'store/store';
import { useQuoteBulkDelete } from 'features/quotation/quote-common/hooks/bulk-actions/useQuoteBulkDelete';
import { useQuoteBulkTransformToBasic } from 'features/quotation/quote-common/hooks/bulk-actions/useQuoteBulkTransformToBasic';
import { useQuoteBulkTransformToHiddenCost } from 'features/quotation/quote-common/hooks/bulk-actions/useQuoteBulkTransformToHiddenCost';
import { useQuoteBulkTransformToOptional } from 'features/quotation/quote-common/hooks/bulk-actions/useQuoteBulkTransformToOptional';
import {
  updateQuoteSelectedRows,
  updateQuoteHiddenCostSelectedRows,
  updateQuoteNbSelectedRows,
  updateQuoteHiddenCostNbSelectedRows,
  updateQuoteNotesModal,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { QUOTATION_SHORTCUTS } from 'features/quotation/quote-hot-key/hooks/useQuoteHotKeys';
import { useQuoteAddImage } from 'features/quotation/quote-common/hooks/useQuoteAddImage';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';

export const SelectionMenu = () => {
  const { t } = useTranslation(['quote']);
  const nbSelectedRows = useStore((state) => state.quoteNbSelectedRows);
  const nbHiddenCostSelectedRows = useStore((state) => state.quoteHiddenCostNbSelectedRows);
  const gridRef = useStore((state) => state.quoteGridRef);
  const hiddenCostGridRef = useStore((state) => state.quoteHiddenCostGridRef);
  const isEditable = useStore((store) => store.quoteEditable);

  const allSelectedRowsLength = useMemo(
    () => nbHiddenCostSelectedRows + nbSelectedRows,
    [nbHiddenCostSelectedRows, nbSelectedRows],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedRows = useMemo(() => gridRef?.current?.api?.getSelectedRows() ?? [], [gridRef, nbSelectedRows]);
  const hiddenCostSelectedRows = useMemo(
    () => hiddenCostGridRef?.current?.api?.getSelectedRows() ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hiddenCostGridRef, nbHiddenCostSelectedRows],
  );

  const removeBulk = useQuoteBulkDelete();
  const transformToBasicBulk = useQuoteBulkTransformToBasic();
  const transformToOptionalBulk = useQuoteBulkTransformToOptional();
  const transformBulkToHiddenCost = useQuoteBulkTransformToHiddenCost();

  const { executeCommand, resetCommand } = useCommands();
  const { quote } = useQuote();

  const handleOnClear = useCallback(() => {
    gridRef?.current?.api.deselectAll();
    hiddenCostGridRef?.current?.api.deselectAll();
    updateQuoteSelectedRows(new Map<string, QuoteNodeObject>());
    updateQuoteHiddenCostSelectedRows(new Map<string, QuoteNodeObject>());
    updateQuoteNbSelectedRows(0);
    updateQuoteHiddenCostNbSelectedRows(0);
  }, [gridRef, hiddenCostGridRef]);

  const handleRemoveRows = useCallback(() => {
    resetCommand();
    removeBulk([...selectedRows, ...hiddenCostSelectedRows]);
    handleOnClear();
  }, [resetCommand, removeBulk, selectedRows, hiddenCostSelectedRows, handleOnClear]);

  const handleTransformRowsToOptional = useCallback(() => {
    executeCommand(transformToOptionalBulk([...selectedRows, ...hiddenCostSelectedRows]), quote);
    handleOnClear();
  }, [executeCommand, handleOnClear, hiddenCostSelectedRows, quote, selectedRows, transformToOptionalBulk]);

  const handleTransformRowsToBasic = useCallback(() => {
    executeCommand(transformToBasicBulk([...selectedRows, ...hiddenCostSelectedRows]), quote);
    handleOnClear();
  }, [executeCommand, handleOnClear, hiddenCostSelectedRows, quote, selectedRows, transformToBasicBulk]);

  const handleTransformRowsToHiddenCost = useCallback(() => {
    executeCommand(transformBulkToHiddenCost([...selectedRows, ...hiddenCostSelectedRows]), quote);
    handleOnClear();
  }, [executeCommand, handleOnClear, hiddenCostSelectedRows, quote, selectedRows, transformBulkToHiddenCost]);

  const getActionsOptions = useCallback((lines: QuoteNodeObject[]) => {
    let areAllOptions = true;
    let areAllNonOptions = true;
    let canRemoveHiddenCost = true;
    let canAddHiddenCost = true;
    let areComponents = false;
    let areHiddenCosts = false;

    lines.forEach((row) => {
      if (row.content.type === 'QuoteLot') {
        if (!row.content.isEveryChildOptional) {
          areAllOptions = false;
        } else {
          areAllNonOptions = false;
        }
      }
      if (row.content.type !== 'QuoteOptionalItem') {
        areAllOptions = false;
      } else {
        areAllNonOptions = false;
      }
      if (row.content.type !== 'QuoteHiddenCostItem') {
        canRemoveHiddenCost = false;
      } else {
        areHiddenCosts = true;
      }
      if (
        row.content.type === 'QuoteLot' ||
        row.content.type === 'QuoteHiddenCostItem' ||
        row.content.type === 'QuoteComponent' ||
        row.content.type === 'QuoteSubItem'
      ) {
        canAddHiddenCost = false;
      }
      if (row.content.type === 'QuoteComponent' || row.content.type === 'QuoteSubItem') {
        areComponents = true;
      }
    });

    return {
      areAllOptions,
      areAllNonOptions,
      canRemoveHiddenCost,
      canAddHiddenCost,
      areComponents,
      areHiddenCosts,
    };
  }, []);

  const actions = useMemo(() => {
    const { areAllOptions, areAllNonOptions, canRemoveHiddenCost, canAddHiddenCost, areComponents, areHiddenCosts } =
      getActionsOptions([...selectedRows, ...hiddenCostSelectedRows]);

    const userCanAddHiddenCost = areAllNonOptions && canAddHiddenCost;

    return [
      ...(areAllNonOptions && !areComponents && !areHiddenCosts
        ? [
            {
              name: t('quote:quotation.popupmenu.option'),
              onClick: handleTransformRowsToOptional,
            },
          ]
        : []),
      ...(userCanAddHiddenCost
        ? [
            {
              name: t('quote:quotation.popupmenu.hiddenCost'),
              onClick: handleTransformRowsToHiddenCost,
            },
          ]
        : []),
      ...(canRemoveHiddenCost
        ? [
            {
              name: t('quote:quotation.popupmenu.removeHiddenCost'),
              onClick: handleTransformRowsToBasic,
            },
          ]
        : []),
      ...(areAllOptions && !areComponents
        ? [
            {
              name: t('quote:quotation.popupmenu.removeOption'),
              onClick: handleTransformRowsToBasic,
            },
          ]
        : []),
    ];
  }, [
    handleTransformRowsToBasic,
    handleTransformRowsToHiddenCost,
    handleTransformRowsToOptional,
    getActionsOptions,
    hiddenCostSelectedRows,
    selectedRows,
    t,
  ]);

  useHotkeys(
    [QUOTATION_SHORTCUTS.REMOVE_LINE.shortCut],
    () => {
      const currentLineIndex = gridRef?.current?.api.getFocusedCell()?.rowIndex;
      const currentLine = currentLineIndex
        ? gridRef?.current?.api?.getDisplayedRowAtIndex(currentLineIndex)?.data
        : undefined;
      if (selectedRows.length === 0 && hiddenCostSelectedRows.length === 0 && currentLine) {
        resetCommand();
        removeBulk([currentLine]);
      } else {
        resetCommand();
        removeBulk([...selectedRows, ...hiddenCostSelectedRows]);
      }
      handleOnClear();
    },
    [removeBulk, selectedRows, hiddenCostSelectedRows, handleOnClear],
  );

  const quoteAddImage = useQuoteAddImage();

  useHotkeys(
    [QUOTATION_SHORTCUTS.ADD_IMAGE.shortCut],
    () => {
      const currentLineIndex = gridRef?.current?.api.getFocusedCell()?.rowIndex;
      const data =
        currentLineIndex !== undefined
          ? gridRef?.current?.api?.getDisplayedRowAtIndex(currentLineIndex)?.data
          : undefined;
      if (selectedRows.length === 0 && hiddenCostSelectedRows.length === 0 && data) {
        if (data.content.type === 'QuoteBasicItem' || data.content.type === 'QuoteOptionalItem') {
          quoteAddImage(data.content.id);
        }
      }
    },
    [quoteAddImage, selectedRows, hiddenCostSelectedRows],
  );

  const handleUpdateNote = useCallback((nodeId: string, note: string | null) => {
    updateQuoteNotesModal({ open: true, note, nodeId });
  }, []);

  useHotkeys(
    [QUOTATION_SHORTCUTS.ADD_NOTE.shortCut],
    () => {
      const currentLineIndex = gridRef?.current?.api.getFocusedCell()?.rowIndex;
      const data =
        currentLineIndex !== undefined
          ? gridRef?.current?.api?.getDisplayedRowAtIndex(currentLineIndex)?.data
          : undefined;
      if (selectedRows.length === 0 && hiddenCostSelectedRows.length === 0 && data) {
        if (
          data.content.type === 'QuoteLot' ||
          data.content.type === 'QuoteBasicItem' ||
          data.content.type === 'QuoteOptionalItem'
        ) {
          handleUpdateNote(data.id, data.content.note);
        }
      }
    },
    [handleUpdateNote, selectedRows, hiddenCostSelectedRows],
  );

  useHotkeys(
    [QUOTATION_SHORTCUTS.OPTION.shortCut],
    () => {
      const currentLineIndex = gridRef?.current?.api.getFocusedCell()?.rowIndex;
      const currentLine =
        currentLineIndex !== undefined
          ? gridRef?.current?.api?.getDisplayedRowAtIndex(currentLineIndex)?.data
          : undefined;
      if (selectedRows.length === 0 && hiddenCostSelectedRows.length === 0 && currentLine) {
        const actionsOptions = getActionsOptions([currentLine]);
        if (actionsOptions.areAllNonOptions && !actionsOptions.areComponents && !actionsOptions.areHiddenCosts) {
          executeCommand(transformToOptionalBulk([currentLine]), quote);
        } else if (actionsOptions.areAllOptions && !actionsOptions.areComponents) {
          executeCommand(transformToBasicBulk([currentLine]), quote);
        }
      } else {
        const actionsOptions = getActionsOptions([...selectedRows, ...hiddenCostSelectedRows]);
        if (actionsOptions.areAllNonOptions && !actionsOptions.areComponents && !actionsOptions.areHiddenCosts) {
          executeCommand(transformToOptionalBulk([...selectedRows, ...hiddenCostSelectedRows]), quote);
          handleOnClear();
        } else if (actionsOptions.areAllOptions && !actionsOptions.areComponents) {
          executeCommand(transformToBasicBulk([...selectedRows, ...hiddenCostSelectedRows]), quote);
          handleOnClear();
        }
      }
    },
    [transformToOptionalBulk, transformToBasicBulk, selectedRows, hiddenCostSelectedRows, handleOnClear],
  );

  useHotkeys(
    [QUOTATION_SHORTCUTS.HIDDEN_COST.shortCut],
    () => {
      const currentLineIndex = gridRef?.current?.api.getFocusedCell()?.rowIndex;
      const currentLine =
        currentLineIndex !== undefined
          ? gridRef?.current?.api?.getDisplayedRowAtIndex(currentLineIndex)?.data
          : undefined;
      if (selectedRows.length === 0 && hiddenCostSelectedRows.length === 0 && currentLine) {
        const actionsOptions = getActionsOptions([currentLine]);
        if (actionsOptions.areAllNonOptions && actionsOptions.canAddHiddenCost) {
          executeCommand(transformBulkToHiddenCost([currentLine]), quote);
        } else if (actionsOptions.canRemoveHiddenCost) {
          executeCommand(transformToBasicBulk([currentLine]), quote);
        }
      } else {
        const actionsOptions = getActionsOptions([...selectedRows, ...hiddenCostSelectedRows]);
        if (actionsOptions.areAllNonOptions && actionsOptions.canAddHiddenCost) {
          executeCommand(transformBulkToHiddenCost([...selectedRows, ...hiddenCostSelectedRows]), quote);
          handleOnClear();
        } else if (actionsOptions.canRemoveHiddenCost) {
          executeCommand(transformToBasicBulk([...selectedRows, ...hiddenCostSelectedRows]), quote);
          handleOnClear();
        }
      }
    },
    [transformBulkToHiddenCost, transformToBasicBulk, selectedRows, hiddenCostSelectedRows, handleOnClear],
  );

  return isEditable ? (
    <PopUpMenu isOpen={allSelectedRowsLength !== 0} withAnimation justifyContent="space-between">
      <HStack spacing="0.25rem" alignItems="center" minWidth="9.375rem">
        <Text color="#4D5761" fontSize="13px">
          {allSelectedRowsLength === 1
            ? t('quote:quotation.popupmenu.selectedLine')
            : t('quote:quotation.popupmenu.selectedLines', { count: allSelectedRowsLength })}
        </Text>
        <GraneetIconButton color="ghost" icon={<SimpleCloseIcon />} onClick={handleOnClear} />
      </HStack>
      <HStack>
        {actions.map((action) => (
          <PopUpMenu.Button key={action.name} variant="secondary" text={action.name} onClick={action.onClick} />
        ))}
        <Center height="20px">
          <Divider orientation="vertical" borderRadius="0.063rem" width="0.063rem" />
        </Center>
        <PopUpMenu.Button
          variant="secondary"
          text={t('quote:quotation.popupmenu.remove')}
          textColor="red"
          onClick={handleRemoveRows}
        />
      </HStack>
    </PopUpMenu>
  ) : null;
};
