import type { FC } from 'react';
import { Card, AmountSummary, formatNumber } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type {
  IDownPayment,
  IProgressStatementSummaryResponseDTO,
  IProject,
  ISubProject,
  IVatComponent,
  IVatComponentCumulative,
} from '@graneet/business-logic';
import { PRICE_REVISION_METHOD, VAT_TYPE } from '@graneet/business-logic';

import { DirectPaymentLines } from './DirectPaymentLines/DirectPaymentLines';

interface ProgressStatementBillingCardProps {
  subProject: ISubProject;

  project: IProject;

  downPayment?: IDownPayment;

  summary: IProgressStatementSummaryResponseDTO;
}

export const ProgressStatementBillingCard: FC<ProgressStatementBillingCardProps> = ({
  subProject,
  project,
  downPayment,
  summary,
}) => {
  const { t } = useTranslation(['progressStatement', 'global']);

  // Price revision
  const isPriceRevisionBeforeExVAT = project.priceRevisionMethod === PRICE_REVISION_METHOD.PRORATA_VAT;
  const rawVatDistribution = summary.vatDistribution || [];
  const vatDistribution: IVatComponent[] = isPriceRevisionBeforeExVAT
    ? (rawVatDistribution as IVatComponentCumulative[]).map((vatObject) => ({
        vatRate: vatObject.vatRate,
        amount: vatObject.amount + vatObject.amountPriceRevision,
      }))
    : summary.vatDistribution;

  return (
    <Card title={t('progressStatement:isCreating.validationStep.billingDetails')} pb={8}>
      <AmountSummary>
        <AmountSummary.Highlight
          left
          label={t('progressStatement:isCreating.validationStep.progressExVAT')}
          amount={summary.nonCumulativeProgressAmountExVAT}
        />

        {subProject.hasPriceRevision && isPriceRevisionBeforeExVAT && (
          <>
            <AmountSummary.Item
              left
              sign
              light
              label={t('progressStatement:isCreating.priceRevisionExVAT')}
              amount={summary.priceRevisionExVAT}
            />
            <AmountSummary.Highlight
              left
              label={t('progressStatement:fields.amountExVAT')}
              amount={summary.amountExVAT}
            />
          </>
        )}

        <AmountSummary.VATDistribution
          left
          vatType={subProject.hasReversalOfLiability ? VAT_TYPE.REVERSE_CHARGE : VAT_TYPE.NORMAL}
          vatDistribution={vatDistribution}
          withCustomDiscountsLabel={subProject.hasReversalOfLiability && !!summary.vatDistribution.length}
        />

        <AmountSummary.Highlight
          left
          label={t('progressStatement:isCreating.validationStep.nonCumulativeAmountIncVAT')}
          amount={summary.amountIncVAT}
        />

        {subProject.hasPriceRevision && !isPriceRevisionBeforeExVAT && (
          <AmountSummary.Item
            left
            sign
            light
            label={t('progressStatement:isCreating.priceRevisionIncVAT')}
            amount={summary.priceRevisionIncVAT}
          />
        )}

        {!!subProject.holdback && (
          <AmountSummary.Item
            left
            sign
            negative
            light
            label={t(
              subProject.holdback?.hasPaymentGuarantee
                ? 'progressStatement:isCreating.validationStep.holdbackWithPaymentGuarantee'
                : 'progressStatement:isCreating.validationStep.holdbackIncVAT',
            )}
            amount={summary.holdbackAmountIncVAT}
          />
        )}

        {(summary?.deductions || []).map((deduction) => (
          <AmountSummary.Item
            key={deduction.name}
            left
            sign
            light
            negative
            label={`${deduction.name}  ${t('global:incVat')} - ${formatNumber(deduction.percentage)} %`}
            amount={deduction.amountIncVAT}
          />
        ))}

        {downPayment && (
          <AmountSummary.Item
            left
            sign
            negative
            light
            label={t('progressStatement:isCreating.validationStep.downPaymentAmortizationIncVAT')}
            amount={summary.downPaymentAmortizationAmountIncVAT}
          />
        )}

        {(summary?.penalties || []).map((penalty) => (
          <AmountSummary.Item key={penalty.name} left sign light label={penalty.name} amount={penalty.amountIncVAT} />
        ))}

        <AmountSummary.Highlight
          left
          label={t('progressStatement:isCreating.validationStep.netBillIncVAT')}
          amount={summary.netBilledIncVAT}
        />
        <DirectPaymentLines summary={summary} />
      </AmountSummary>
    </Card>
  );
};
