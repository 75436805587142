import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const FinanceIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 12" {...props}>
    <path
      fill="currentColor"
      d="M0 0.5H16V11.1667H0V0.5ZM8 3.16667C8.70724 3.16667 9.38552 3.44762 9.88562 3.94772C10.3857 4.44781 10.6667 5.12609 10.6667 5.83333C10.6667 6.54058 10.3857 7.21886 9.88562 7.71895C9.38552 8.21905 8.70724 8.5 8 8.5C7.29276 8.5 6.61448 8.21905 6.11438 7.71895C5.61428 7.21886 5.33333 6.54058 5.33333 5.83333C5.33333 5.12609 5.61428 4.44781 6.11438 3.94772C6.61448 3.44762 7.29276 3.16667 8 3.16667ZM3.55556 2.27778C3.55556 2.74927 3.36825 3.20146 3.03486 3.53486C2.70146 3.86825 2.24927 4.05556 1.77778 4.05556V7.61111C2.24927 7.61111 2.70146 7.79841 3.03486 8.13181C3.36825 8.46521 3.55556 8.91739 3.55556 9.38889H12.4444C12.4444 8.91739 12.6317 8.46521 12.9651 8.13181C13.2985 7.79841 13.7507 7.61111 14.2222 7.61111V4.05556C13.7507 4.05556 13.2985 3.86825 12.9651 3.53486C12.6317 3.20146 12.4444 2.74927 12.4444 2.27778H3.55556Z"
    />
  </Icon>
);
