import type { FC } from 'react';
import { useCallback } from 'react';
import { Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Text, VStack } from '@chakra-ui/react';
import type { IClient, IClientResponseDTO, ISecondaryContactProject } from '@graneet/business-logic';
import { Form, useForm, useFormStatus } from 'graneet-form';

import type { ClientOrSupplierAssociateForm } from 'features/contact/components/forms/ClientOrSupplierAssociationForm';
import { ClientOrSupplierAssociationForm } from 'features/contact/components/forms/ClientOrSupplierAssociationForm';

export interface ClientSwitchModalProps {
  onClose(): void;
  isMultipleSelection: boolean;
  isOpen: boolean;
  title: string;
  description: string;
  client: IClientResponseDTO | IClient;
  onUpdate:
    | ((clientId: number | null) => Promise<IClientResponseDTO | IClient | null>)
    | ((clientId: number) => Promise<ISecondaryContactProject[] | null>);
}

export const ClientSwitchModal: FC<ClientSwitchModalProps> = ({
  onClose,
  isOpen,
  title,
  onUpdate,
  description,
  client,
}) => {
  const { t } = useTranslation(['quote', 'clients']);

  const clientOrSupplierAssociateForm = useForm<ClientOrSupplierAssociateForm>();
  const { isValid: isFormValid } = useFormStatus(clientOrSupplierAssociateForm);

  const handleClose = useCallback(() => {
    clientOrSupplierAssociateForm.resetForm();
    onClose();
  }, [clientOrSupplierAssociateForm, onClose]);

  const onSubmit = useCallback(async () => {
    const formValues = clientOrSupplierAssociateForm.getFormValues().association?.id;
    if (formValues) {
      // Errors are already handled by the update function
      const response = await onUpdate(formValues);
      if (response) {
        clientOrSupplierAssociateForm.resetForm();
        onClose();
      }
    }
  }, [clientOrSupplierAssociateForm, onClose, onUpdate]);

  const clientsFilter = useCallback((value: IClientResponseDTO) => !(value.id === client.id), [client.id]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title={title} size="2xl" scrollBehavior="inside">
      <VStack w="100%" align="stretch">
        <Text alignSelf="self-start">{description}</Text>
        <Form form={clientOrSupplierAssociateForm}>
          <ClientOrSupplierAssociationForm
            searchSuppliers={false}
            searchClients
            isRequired
            clientsFilter={clientsFilter}
            showBadge={false}
            placeholder={t('quote:clientStep.associationPlaceholder')}
          />
        </Form>
        <Modal.SecondaryButton onClick={onClose}>{t('clients:modal.buttons.cancel')}</Modal.SecondaryButton>
        <Modal.PrimaryButton isDisabled={!isFormValid} onClick={onSubmit}>
          {t('quote:clientStep.cta.selectClient')}
        </Modal.PrimaryButton>
      </VStack>
    </Modal>
  );
};
