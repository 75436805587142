import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { IProjectStatsSummaryDTO, IProject } from '@graneet/business-logic';
import { getPercentage } from '@graneet/business-logic';
import { Card, CustomTooltipChart, formatPercentage, StackedBarChart, useCurrency } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import type { TooltipProps } from 'recharts';
import { Text } from '@chakra-ui/react';

import { ProjectStatsEmptyState } from './ProjectStatsEmptyState';

interface ProjectBudgetConsumptionCardProps {
  stats: IProjectStatsSummaryDTO;

  project: IProject;
}

enum BARS {
  BUDGETS,
  ORDERS,
}

export const ProjectBudgetConsumptionCard: FC<ProjectBudgetConsumptionCardProps> = ({ stats, project }) => {
  const { t } = useTranslation(['project']);
  const { formatAsAmount } = useCurrency();

  const {
    timeSlotTotalPriceExVATSum,
    orderAmountExVATSum,
    projectAmountExVAT,
    budgetAmountExVATSum,
    numberOfBudgets,
    numberOfTimeSlots,
    numberOfOrders,
  } = stats;

  const theoreticalBenefitExVAT = projectAmountExVAT - budgetAmountExVATSum;
  const ordersPlusTimeSlotsExVAT = orderAmountExVATSum + timeSlotTotalPriceExVATSum;
  const restToSpendExVAT = budgetAmountExVATSum - ordersPlusTimeSlotsExVAT;

  const data = [
    {
      name: BARS.BUDGETS,
      budgetAmountExVATSum,
      theoreticalBenefitExVAT,
    },
    {
      name: BARS.ORDERS,
      ordersPlusTimeSlotsExVAT,
      restToSpendExVAT,
    },
  ];

  const legends = useMemo(
    () => ({
      budgetAmountExVATSum: t('project:projectBudgetConsumptionCard.budgetAmountExVATSum'),
      theoreticalBenefitExVAT: t('project:projectBudgetConsumptionCard.theoreticalBenefitExVAT'),
      ordersPlusTimeSlotsExVAT: t('project:projectBudgetConsumptionCard.ordersPlusTimeSlotsExVAT'),
      orderAmountExVATSum: t('project:projectBudgetConsumptionCard.orderAmountExVATSum'),
      timeSlotTotalPriceExVATSum: t('project:projectBudgetConsumptionCard.timeSlotTotalPriceExVATSum'),
      restToSpendExVAT: t('project:projectBudgetConsumptionCard.restToSpendExVAT'),
    }),
    [t],
  );

  const CustomTheoreticalMarginValue = useCallback(
    (value: number | undefined) => {
      const fixedValue = value || 0;
      if (projectAmountExVAT === 0) {
        return formatAsAmount(fixedValue);
      }

      return (
        <>
          {formatAsAmount(fixedValue)} / {formatPercentage(getPercentage(fixedValue, projectAmountExVAT))}
        </>
      );
    },
    [formatAsAmount, projectAmountExVAT],
  );

  const CustomTooltip = useCallback(
    ({ payload, active }: TooltipProps<number, string>) => {
      const bar = payload?.[0]?.payload?.name;
      let customPayload;

      if (bar === BARS.ORDERS) {
        customPayload = [
          payload![0],
          {
            name: 'orderAmountExVATSum',
            value: orderAmountExVATSum,
            textColor: 'gray.500',
          },
          {
            name: 'timeSlotTotalPriceExVATSum',
            value: timeSlotTotalPriceExVATSum,
            textColor: 'gray.500',
          },
          payload![1],
        ];
      } else {
        customPayload = [
          payload![0],
          {
            ...payload![1],
            formatValue: CustomTheoreticalMarginValue,
          },
        ];
      }

      return <CustomTooltipChart legends={legends} payload={customPayload} active={active} />;
    },
    [legends, orderAmountExVATSum, timeSlotTotalPriceExVATSum, CustomTheoreticalMarginValue],
  );

  const isEmpty = !numberOfBudgets && !numberOfOrders && !numberOfTimeSlots;

  return (
    <Card
      title={
        <Trans t={t} i18nKey="project:projectBudgetConsumptionCard.title">
          description_start
          <Text as="span" color="gray.500">
            displayed_data
          </Text>
          description_end
        </Trans>
      }
    >
      {isEmpty && <ProjectStatsEmptyState project={project} />}
      {!isEmpty && (
        <StackedBarChart
          data={data}
          columns={[
            {
              name: 'budgetAmountExVATSum',
              color: 'blue.200',
            },
            {
              name: 'theoreticalBenefitExVAT',
              color: 'blue.100',
            },
            {
              name: 'ordersPlusTimeSlotsExVAT',
              color: 'purple.200',
            },
            {
              name: 'restToSpendExVAT',
              color: 'purple.100',
            },
          ]}
          xAxis={{
            dataKey: 'name',
            typeFormat: 'string',
            hide: true,
          }}
          yAxis={{
            typeFormat: 'money',
          }}
          tooltip={CustomTooltip}
          legends={legends}
        />
      )}
    </Card>
  );
};
