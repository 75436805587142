import type { FC, ReactNode } from 'react';
import { Fragment, memo, useMemo, useRef } from 'react';
import { Box } from '@chakra-ui/react';

import { useIntersection, useScrollContext } from '../../hooks';

import { ToggleButton } from './components/ToggleButton';
import { TreeComponentsContext, useTreeComponentsContext } from './contexts/TreeComponentsContext';
import type { TreeContextApi } from './contexts';
import { TreeContext, useTreeContext } from './contexts';
import { useNode } from './hooks/useNode';
import type { Identifiable } from './types';
import { useNodeRelations } from './hooks/useNodeRelations';

const MINIMUM_NUMBER_OF_ITEMS_FOR_VIRTUALIZATION = 300;

interface NodeWrapperProps<Node extends Identifiable> {
  id: Node['id'];

  depth: number;

  canEnableVirtualization: boolean;
}

const Leaf: FC<{ leafId: string | number; depth: number; canEnableVirtualization: boolean }> = memo(
  ({ leafId, depth, canEnableVirtualization }) => {
    const { leafComponent: LeafComponent, virtualizedLeafComponent: VirtualizedLeafComponent } =
      useTreeComponentsContext();

    const scrollContext = useScrollContext();
    const ref = useRef<HTMLDivElement | null>(null);

    const isIntersecting = useIntersection(
      ref,
      useMemo(
        () => ({
          rootMargin: '1500px 0% 1500px 0%',
          root: scrollContext,
        }),
        [scrollContext],
      ),
    );

    const isNotVisible = !isIntersecting && VirtualizedLeafComponent && canEnableVirtualization;

    return (
      <div ref={ref}>
        {isNotVisible && <VirtualizedLeafComponent id={leafId} depth={depth} />}

        {!isNotVisible && <LeafComponent id={leafId} depth={depth} />}
      </div>
    );
  },
);

const NodeRelations = memo(
  <Node extends Identifiable>({ id, depth, canEnableVirtualization }: NodeWrapperProps<Node>) => {
    const relations = useNodeRelations(id);

    const { separatorComponent: SeparatorComponent } = useTreeComponentsContext();

    return (
      <>
        {relations.leaves.map((leafId) => (
          <Leaf
            key={`LEAF_${leafId}`}
            leafId={leafId}
            depth={depth + 1}
            canEnableVirtualization={canEnableVirtualization}
          />
        ))}

        {relations.leaves.length > 0 && relations.nodes.length > 0 && <SeparatorComponent />}

        {relations.nodes.map((nodeId, index) => (
          <Fragment key={`NODE_${nodeId}`}>
            {(index === 0 && relations.leaves.length === 0) ||
            (index === 0 && relations.leaves.length > 0 && relations.nodes.length > 0) ? null : (
              <SeparatorComponent />
            )}

            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            <NodeWrapper id={nodeId} depth={depth + 1} canEnableVirtualization={canEnableVirtualization} />
          </Fragment>
        ))}
      </>
    );
  },
);

const InternalNodeWrapper = memo(
  <Node extends Identifiable>({ id, depth, canEnableVirtualization }: NodeWrapperProps<Node>) => {
    const {
      state: { isExpanded },
    } = useNode(id);

    return (
      <Box display={isExpanded ? 'block' : 'none'}>
        <NodeRelations id={id} depth={depth} canEnableVirtualization={canEnableVirtualization} />
      </Box>
    );
  },
);

const NodeWrapper = memo(
  <Node extends Identifiable>({ id, depth, canEnableVirtualization }: NodeWrapperProps<Node>) => {
    const { getInitialTree } = useTreeContext();
    const { rootNodeId } = getInitialTree();

    const { nodeWrapperComponent: NodeWrapperComponent, nodeComponent: NodeComponent } = useTreeComponentsContext();

    const InternalWrapper = useMemo(
      () => (NodeWrapperComponent && rootNodeId !== id ? NodeWrapperComponent : Fragment),
      [NodeWrapperComponent, id, rootNodeId],
    );

    const internalProps = useMemo(
      () => (NodeWrapperComponent && rootNodeId !== id ? { id, depth } : {}),
      [NodeWrapperComponent, rootNodeId, id, depth],
    );

    return (
      // @ts-ignore
      <InternalWrapper {...internalProps}>
        {rootNodeId !== id && <NodeComponent id={id} depth={depth} />}

        <InternalNodeWrapper id={id} depth={depth} canEnableVirtualization={canEnableVirtualization} />
      </InternalWrapper>
    );
  },
);

export type TreeProps<
  Node extends Identifiable,
  Leaf extends Identifiable,
  NodeComputedValue extends Record<keyof NodeComputedValue, unknown>,
  LeafComputedValue extends Record<keyof LeafComputedValue, unknown>,
> = {
  tree: TreeContextApi<Node, Leaf, NodeComputedValue, LeafComputedValue>;

  headerComponent: FC;

  nodeWrapperComponent?: FC<{ id: Node['id']; depth: number; children: ReactNode }>;

  nodeComponent: FC<{ id: Node['id']; depth: number }>;

  leafComponent: FC<{ id: Leaf['id']; depth: number }>;

  virtualizedLeafComponent?: FC<{ id: Leaf['id']; depth: number }>;

  separatorComponent: FC;

  footerComponent?: FC;
};

const TreeComponent = <
  Node extends Identifiable,
  Leaf extends Identifiable,
  NodeComputedValue extends Record<keyof NodeComputedValue, unknown>,
  LeafComputedValue extends Record<keyof LeafComputedValue, unknown>,
>({
  tree,
  headerComponent: HeaderComponent,
  nodeWrapperComponent,
  nodeComponent,
  leafComponent,
  virtualizedLeafComponent,
  separatorComponent,
  footerComponent: FooterComponent,
}: TreeProps<Node, Leaf, NodeComputedValue, LeafComputedValue>) => {
  const { getInitialTree } = tree;
  const { rootNodeId, leaves } = getInitialTree();
  const canEnableVirtualization = Object.keys(leaves).length > MINIMUM_NUMBER_OF_ITEMS_FOR_VIRTUALIZATION;

  const treeComponents = useMemo(
    () => ({
      nodeWrapperComponent,
      nodeComponent,
      leafComponent,
      separatorComponent,
      virtualizedLeafComponent,
    }),
    [nodeWrapperComponent, nodeComponent, leafComponent, separatorComponent, virtualizedLeafComponent],
  );

  return (
    <TreeContext.Provider value={tree}>
      <TreeComponentsContext.Provider value={treeComponents}>
        <HeaderComponent />

        <NodeWrapper id={rootNodeId} depth={0} canEnableVirtualization={canEnableVirtualization} />

        {FooterComponent && <FooterComponent />}
      </TreeComponentsContext.Provider>
    </TreeContext.Provider>
  );
};

export const Tree = Object.assign(TreeComponent, {
  ToggleButton,
});
