import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { Tooltip } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ICompany, IProgressStatement } from '@graneet/business-logic';
import type { FC } from 'react';

import { downloadFile } from '../../file/services/file.util';
import { getDownloadUrl } from '../../pdf/services/pdf.api';

interface ProgressStatementExternalHeaderProps {
  company: Pick<ICompany, 'name'>;

  progressStatement: Pick<IProgressStatement, 'name' | 'invoiceNumber' | 'pdf'>;
}

export const ProgressStatementExternalHeader: FC<ProgressStatementExternalHeaderProps> = ({
  progressStatement,
  company,
}) => {
  const { t } = useTranslation(['global', 'progressStatement']);

  const downloadUrl = getDownloadUrl(progressStatement.pdf?.apiId || '', progressStatement.name) || '';

  return (
    <Flex bg="white" borderBottom="1px solid" borderColor="gray.200" alignItems="center" px={4} py={2} gap={2}>
      <Flex
        alignItems="center"
        justifyContent="center"
        background="#000000"
        borderRadius="0.5rem"
        boxSize={10}
        color="white"
        fontSize={25}
      >
        <i className="ri-building-line" />
      </Flex>

      <Box flex={1}>
        <Text fontWeight="bold" fontSize="md">
          {progressStatement.name}
        </Text>
        <Flex fontSize="sm" color="gray.500" gap={4}>
          <span>{company.name}</span>
          <span>
            <i className="ri-price-tag-3-line" /> {progressStatement.invoiceNumber}
          </span>
        </Flex>
      </Box>

      <Tooltip label={t('progressStatement:external.actions.download')}>
        <IconButton
          aria-label={t('global:pdf.download')}
          variant="outline"
          icon={<DownloadIcon />}
          onClick={() => downloadFile(downloadUrl)}
        />
      </Tooltip>
    </Flex>
  );
};
