import type { FC, PropsWithChildren, ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useRef } from 'react';

interface IPlaceholderContext {
  updatePlaceholder(name: string, newPlaceholder: ReactNode): void;
  resetPlaceholder(name: string): void;
  initializeSetter(name: string, setter: (newPlaceholder: ReactNode) => void): void;
  removeSetter(name: string): void;
}

const PlaceholderContext = createContext<IPlaceholderContext>({
  updatePlaceholder: () => {},
  resetPlaceholder: () => {},
  initializeSetter: () => {},
  removeSetter: () => {},
});

export const usePlaceholderContext = () => useContext(PlaceholderContext);

export const PlaceholderProvider: FC<PropsWithChildren> = ({ children }) => {
  const currentPlaceholdersRef = useRef(new Map<string, ReactNode>());
  const settersRef = useRef(new Map<string, (newPlaceholder: ReactNode) => void>());

  const updatePlaceholder = useCallback((name: string, newPlaceholder: ReactNode) => {
    currentPlaceholdersRef.current.set(name, newPlaceholder);
    settersRef.current.get(name)?.(newPlaceholder);
  }, []);

  const resetPlaceholder = useCallback((name: string) => {
    currentPlaceholdersRef.current.delete(name);
    settersRef.current.get(name)?.(null);
  }, []);

  const initializeSetter = useCallback((name: string, setter: (newPlaceholder: ReactNode) => void) => {
    settersRef.current.set(name, setter);
    setter(currentPlaceholdersRef.current.get(name));
  }, []);

  const removeSetter = useCallback((key: string) => {
    settersRef.current.delete(key);
  }, []);

  const context = useMemo<IPlaceholderContext>(
    () => ({
      updatePlaceholder,
      resetPlaceholder,
      initializeSetter,
      removeSetter,
    }),
    [initializeSetter, removeSetter, resetPlaceholder, updatePlaceholder],
  );

  return <PlaceholderContext.Provider value={context}>{children}</PlaceholderContext.Provider>;
};
