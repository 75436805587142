import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { DrawerApi } from '@graneet/lib-ui';
import {
  AsyncTableData,
  Button,
  DrawersStack,
  ListingLayout,
  PaginatedDataProvider,
  SimpleAlertIcon,
  usePaginatedData,
  ValuesProvider,
  Filters,
  SimpleQuoteIcon,
  useValuesContext,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { PERMISSION } from '@graneet/business-logic';
import { QuoteStatus } from '@org/graneet-bff-client';
import { Flex, Text } from '@chakra-ui/react';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { useAppContext } from 'features/app/contexts/AppContext';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { QUOTE_FIELDS, QUOTE_STATUS_COLOR } from 'features/quotation/quote-common/constants/quote.constant';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useData } from 'features/api/hooks/useData';
import { formatUserDisplayName } from 'features/user/services/user.util';
import type { QuoteTable } from 'features/quotation/quote/components/tables/QuoteSelectTable';
import { QuoteSelectTable } from 'features/quotation/quote/components/tables/QuoteSelectTable';
import { useDisplayedUsers } from 'features/user/services/user.api';

const SCREEN_PERMISSIONS = [PERMISSION.VIEW_ALL_QUOTES];

interface QuoteSelectDrawerProps {
  drawer: DrawerApi<string>;
  onSubmit(selectedQuote: QuoteTable): void;
  title: string;
  description: string;
  hasPagination: boolean;
  showClientColumn: boolean;
  showAccountManagers: boolean;
}

const InternalQuoteSelectDrawer: FC<QuoteSelectDrawerProps> = ({
  drawer,
  onSubmit,
  title,
  description,
  hasPagination,
  showClientColumn,
  showAccountManagers,
}) => {
  const { t } = useTranslation(['global', 'quote', 'accountManager']);
  const quoteProxy = useQuotationProxyApis().quoteProxyApi;
  const { getValues, resetValues, hasValue, toggleValue, numberOfValues } = useValuesContext<QuoteTable>();

  const users = useDisplayedUsers();

  const hasPermissions = usePermissions(SCREEN_PERMISSIONS);
  const { currentUser } = useAppContext();
  const forcedFilters = useMemo(
    () =>
      hasPermissions
        ? undefined
        : {
            [QUOTE_FIELDS.ACCOUNT_MANAGERS]: [currentUser!.id],
          },
    [currentUser, hasPermissions],
  );

  const getPaginatedQuotesGetter = useCallback(
    (params: URLSearchParams | undefined) => {
      params?.append('v1QuoteIsIgnored', 'true');
      return quoteProxy!.getQuotesPaginatedByCompanyId(params);
    },
    [quoteProxy],
  );

  const data = usePaginatedData(getPaginatedQuotesGetter, forcedFilters, forcedFilters);
  const { data: paginatedQuotes } = data;

  const getAvailableQuoteTagsGetter = useCallback(() => quoteProxy!.getAvailableTagsByCompanyId(), [quoteProxy]);
  const { data: tagsData } = useData(getAvailableQuoteTagsGetter);
  const availableTags = useMemo(
    () => (tagsData?.tags || []).map<{ value: string; label: string }>((tag) => ({ value: tag, label: tag })),
    [tagsData],
  );

  const handleOnSubmit = useCallback(() => {
    drawer.onClose();
    onSubmit(getValues()[0]);
    resetValues();
  }, [drawer, getValues, onSubmit, resetValues]);

  const handleSeclectQuote = useCallback(
    (quote: QuoteTable) => () => {
      resetValues();
      const checked = hasValue(quote);
      toggleValue(quote, !checked);
    },
    [hasValue, resetValues, toggleValue],
  );

  const availableStatuses = useMemo(
    () =>
      Object.values(QuoteStatus).map((status) => ({
        value: status,
        colorScheme: QUOTE_STATUS_COLOR[status],
        label: t(`quote:statuses.${status}`),
      })),
    [t],
  );

  const availableUsers = useMemo(
    () =>
      users.data.map<{ value: string; label: string }>((user) => ({
        value: user.id.toString(),
        label: formatUserDisplayName(user),
      })),
    [users],
  );

  return (
    <DrawersStack.Drawer
      title={title}
      drawer={drawer}
      footer={
        <Flex justify="flex-end" w="100%">
          <Button onClick={handleOnSubmit} isDisabled={numberOfValues() === 0}>
            {t('global:words.c.validate')}
          </Button>
        </Flex>
      }
      size="2xl"
    >
      <Text mb={4}>{description}</Text>

      <PaginatedDataProvider data={data}>
        <Filters>
          <ListingLayout p={8} h="auto">
            <ListingLayout.Search placeholder={t('quote:actions.search')} />
            <ListingLayout.FiltersApplied
              filters={{
                [QUOTE_FIELDS.STATUS]: {
                  label: t('quote:filters.status'),
                  type: 'multi',
                  availableValues: availableStatuses,
                },
                [QUOTE_FIELDS.ACCOUNT_MANAGERS]: {
                  label: t('accountManager:title'),
                  type: 'multi',
                  availableValues: availableUsers,
                },
                [QUOTE_FIELDS.TAGS]: {
                  label: t('global:words.tags'),
                  type: 'multi',
                  availableValues: availableTags,
                },
                [QUOTE_FIELDS.DATE]: {
                  label: t('quote:filters.date'),
                  type: 'date',
                },
              }}
            />
            <ListingLayout.Content>
              <AsyncTableData
                errorState={{
                  message: t('quote:errors.listingError', { email: SUPPORT_EMAIL }),
                  icon: <SimpleAlertIcon boxSize={45} />,
                }}
                emptyState={{
                  icon: <SimpleQuoteIcon boxSize={45} />,
                  children: t('quote:errors.noQuote'),
                }}
              >
                <QuoteSelectTable
                  quotes={paginatedQuotes}
                  showClientColumn={showClientColumn}
                  showAccountManagers={showAccountManagers}
                  hasPagination={hasPagination}
                  handleSelectQuote={handleSeclectQuote}
                  hasValue={hasValue}
                />
              </AsyncTableData>
            </ListingLayout.Content>
          </ListingLayout>
        </Filters>
      </PaginatedDataProvider>
    </DrawersStack.Drawer>
  );
};

export const QuoteSelectDrawer: FC<QuoteSelectDrawerProps> = (props) => (
  <ValuesProvider>
    <InternalQuoteSelectDrawer {...props} />
  </ValuesProvider>
);
