export interface TimeTableTranslations {
  hourLabel: string;
  dayLabel: string;
  deleteAllTimes: string;
  away: string;
  week: string;
  totalWorkedInWeek: (weekNumber: number) => string;
  totalWorkedInMonth: (month: string) => string;
  approve: string;
  selectedTimeSlots: (numberOfSelectedTimes: number) => string;
}

export const timeTableTranslations: TimeTableTranslations = {
  hourLabel: 'h',
  dayLabel: 'd',
  away: 'Absent',
  deleteAllTimes: 'Delete all times',
  week: 'Week',
  totalWorkedInWeek: (weekNumber: number) => `Total worked in week ${weekNumber}`,
  totalWorkedInMonth: (month: string) => `Total worked in ${month}`,
  approve: 'Approve times',
  selectedTimeSlots: (numberOfSelectedTimes: number) => `${numberOfSelectedTimes} timeslot(s) selected`,
};

export const configureDefaultLabels = (translations: TimeTableTranslations) => {
  Object.assign(timeTableTranslations, translations);
};
