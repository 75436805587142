export enum MIME_TYPE {
  APPLICATION_JSON = 'application/json',
  APPLICATION_PDF = 'application/pdf',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP = 'application/zip',
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
  TXT = 'text/plain',
  // Other any other valid mime type
}

const IMAGE_MIME_TYPES: MIME_TYPE[] = [MIME_TYPE.PNG, MIME_TYPE.JPG, MIME_TYPE.JPEG, MIME_TYPE.SVG];

export type MIME_TYPE_IMAGE = (typeof IMAGE_MIME_TYPES)[number];

export const isValidImageMimeType = (mimeType: string): mimeType is MIME_TYPE_IMAGE =>
  IMAGE_MIME_TYPES.includes(<MIME_TYPE_IMAGE>mimeType.toLowerCase());

export const isSvgMimeType = (mimeType: string): mimeType is MIME_TYPE.SVG => mimeType === MIME_TYPE.SVG;
