import type { CUSTOM_DISCOUNT_TYPES } from '@graneet/business-logic';
import type { QUOTE_CUSTOM_DISCOUNT_TYPE } from '@org/quotation-lib';

export type PositiveMultiplier = 1;
export type NegativeMultiplier = -1;

export const POSITIVE_MULTIPLIER = 1;
export const NEGATIVE_MULTIPLIER = -1;

export interface CustomDiscountEditionForm {
  customDiscountMultiplier: PositiveMultiplier | NegativeMultiplier;

  customDiscountType: CUSTOM_DISCOUNT_TYPES;

  customDiscountAmountExVAT?: number;

  customDiscountPercentage?: number;

  customDiscountVatRate: number;

  customDiscountName: string;
}

// TODO: replace CustomDiscountEditionForm
export interface QuotationCustomDiscountEditionForm {
  customDiscountMultiplier: PositiveMultiplier | NegativeMultiplier;

  customDiscountType: QUOTE_CUSTOM_DISCOUNT_TYPE;

  customDiscountAmountExVAT?: number;

  customDiscountPercentage?: number;

  customDiscountVatRate: number;

  customDiscountName: string;
}
