import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { Flex, Box, SimpleGrid } from '@chakra-ui/react';
import { Button, TextField, TextAreaField, CheckboxField, IconAdvanced, SimpleHelpIcon } from '@graneet/lib-ui';

import { useCreatePaymentMethod } from 'features/payment-method/services/payment-method.api';
import { Rule } from 'features/form/rules/Rule';

interface PaymentMethodCreationFormValues {
  name?: string;

  iban?: string | null;

  bic?: string | null;

  text?: string | null;

  isDefault?: boolean;
}

interface PaymentMethodCreationFormProps {
  onCreated: () => void;

  onClose: () => void;
}

export const PaymentMethodCreationForm: FC<PaymentMethodCreationFormProps> = ({ onCreated, onClose }) => {
  const { t } = useTranslation(['global', 'paymentMethods']);

  const createPaymentMethodMutation = useCreatePaymentMethod();

  const form = useForm<PaymentMethodCreationFormValues>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { getFormValues } = form;

  const handleSubmit = useCallback(async () => {
    const values = getFormValues();
    createPaymentMethodMutation.mutate(
      {
        name: values.name!,
        iban: values.iban || null,
        bic: values.iban || null,
        text: values.text || null,
        isDefault: !!values.isDefault,
      },
      {
        onSuccess: () => {
          onCreated();
        },
      },
    );
  }, [createPaymentMethodMutation, getFormValues, onCreated]);

  return (
    <Form form={form}>
      <Box mb={3}>{t('paymentMethods:modal.create.desc')}</Box>

      <Flex direction="column" mt={6}>
        <Flex direction="row" mb={5}>
          <TextField<PaymentMethodCreationFormValues>
            name="name"
            label={t('paymentMethods:field.name.label')}
            rightIcon={<IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('paymentMethods:field.name.tooltip')} />}
            isRequired
          >
            <Rule.IsRequired message={t('paymentMethods:field.name.error.create')} />
          </TextField>
        </Flex>

        <SimpleGrid spacing={4} columns={2} mb={5}>
          <TextField<PaymentMethodCreationFormValues> name="iban" label={t('paymentMethods:field.iban')} />
          <TextField<PaymentMethodCreationFormValues> name="bic" label={t('paymentMethods:field.bic')} />
        </SimpleGrid>

        <Flex mb={8}>
          <TextAreaField<PaymentMethodCreationFormValues>
            name="text"
            label={t('paymentMethods:field.comment.label')}
            rightIcon={<IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('paymentMethods:field.comment.tooltip')} />}
          />
        </Flex>

        <Flex direction="row" mb={8}>
          <CheckboxField<PaymentMethodCreationFormValues>
            name="isDefault"
            label={t('paymentMethods:field.isDefault')}
          />
        </Flex>
      </Flex>

      <Flex justify="space-between" w="100%" mb={4}>
        <Button variant="outline" onClick={onClose} isDisabled={createPaymentMethodMutation.isPending}>
          {t('global:words.c.back')}
        </Button>
        <Button onClick={handleSubmit} isDisabled={!isFormValid} isLoading={createPaymentMethodMutation.isPending}>
          {t('paymentMethods:modal.create.cta')}
        </Button>
      </Flex>
    </Form>
  );
};
