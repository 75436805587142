import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';
import type { RenderActionsProps } from '@graneet/lib-ui';
import { SingleSelect, Tooltip } from '@graneet/lib-ui';
import { FILTERING_PARAMS, QUOTE_STATUS, QuoteStatus } from '@graneet/business-logic';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import countBy from 'lodash-es/countBy';

import { QuotationStatusBadge } from './badges/QuotationStatusBadge';

import { useQuotationBatchUpdateLost } from 'features/quotation/services/quote.api';

export interface QuoteBatchStatusActionsProps
  extends RenderActionsProps<{ id: number | string; status: QuoteStatus | QUOTE_STATUS }> {
  onStatusesChanged(): Promise<void>;
}

export const QuoteBatchStatusActions: FC<QuoteBatchStatusActionsProps> = ({
  selectedItems,
  resetSelectedCheckboxes,
  hasAllCheckboxesSelected,
  currentFilters,
  onStatusesChanged,
}) => {
  const { t } = useTranslation(['global', 'quote']);

  const quotationBatchUpdateLost = useQuotationBatchUpdateLost();

  const getRealStatus = useCallback((status: QuoteStatus | QUOTE_STATUS) => {
    if ([QuoteStatus.Accepted, QUOTE_STATUS.ACCEPTED].includes(status)) {
      return QuoteStatus.Accepted;
    }
    if (status === QuoteStatus.Archived) {
      return QuoteStatus.Archived;
    }
    if ([QuoteStatus.Completed, QUOTE_STATUS.COMPLETED].includes(status)) {
      return QuoteStatus.Completed;
    }
    if ([QuoteStatus.Draft, QUOTE_STATUS.DRAFT].includes(status)) {
      return QuoteStatus.Draft;
    }
    if ([QuoteStatus.Importing, QUOTE_STATUS.IMPORTING].includes(status)) {
      return QuoteStatus.Importing;
    }
    if ([QuoteStatus.Refused, QUOTE_STATUS.LOST].includes(status)) {
      return QuoteStatus.Refused;
    }
    return QuoteStatus.Archived;
  }, []);

  const numberOfItemsPerStatus = useMemo(
    () => countBy(selectedItems, (item) => getRealStatus(item.status)),
    [getRealStatus, selectedItems],
  );

  const numberOfStatus = useMemo(() => Object.keys(numberOfItemsPerStatus).length, [numberOfItemsPerStatus]);

  const onOptionSelected = useCallback(async () => {
    await quotationBatchUpdateLost.mutateAsync({
      filters: qs.parse(currentFilters.toString()),
      selectedItems: selectedItems.map((item) => ({ id: item.id })),
      hasAllSelected: hasAllCheckboxesSelected,
      search: currentFilters.get(FILTERING_PARAMS.SEARCH) || undefined,
    });

    resetSelectedCheckboxes();
    onStatusesChanged();
  }, [
    currentFilters,
    hasAllCheckboxesSelected,
    onStatusesChanged,
    quotationBatchUpdateLost,
    resetSelectedCheckboxes,
    selectedItems,
  ]);

  if (numberOfStatus === 0) {
    return null;
  }

  const isSelectStatusDisabled = !Object.keys(numberOfItemsPerStatus).every(
    (status) => status === QuoteStatus.Completed,
  );

  let tooltipMessage = '';
  if (numberOfStatus !== 1) {
    tooltipMessage = t('quote:banner.tooltipMessages.multipleStatusError');
  } else if (isSelectStatusDisabled) {
    tooltipMessage = t('quote:banner.tooltipMessages.selectStatusDisabledError');
  }

  return (
    <Tooltip placement="right-start" label={tooltipMessage}>
      <Box width="9rem">
        <SingleSelect<{ value: QuoteStatus; label: ReactNode }>
          onChange={onOptionSelected}
          placeholder={t('global:words.c.status')}
          isDisabled={quotationBatchUpdateLost.isPending || isSelectStatusDisabled}
          options={[{ value: QuoteStatus.Refused, label: <QuotationStatusBadge status={QuoteStatus.Refused} /> }]}
          isClearable={false}
          isSearchable={false}
          menuPlacement="top"
          isLoading={quotationBatchUpdateLost.isPending}
        />
      </Box>
    </Tooltip>
  );
};
