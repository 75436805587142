import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListingLayout,
  PaginatedTable,
  type PaginatedTableProps,
  PriceAdvanced,
  SimpleStatementIcon,
} from '@graneet/lib-ui';
import type {
  ISupplierInvoice,
  ISupplierInvoiceListingResponseDTO,
  SupplierInvoicePaginatedResponse,
} from '@graneet/business-logic';
import { useParams } from 'react-router-dom';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { IRowNode } from '@ag-grid-community/core';

import { useDirectPaymentContext } from '../../../contexts/DirectPaymentContext';

import { SupplierInvoiceComponentTypeBadge } from 'features/supplier-invoice/components/SupplierInvoiceComponentTypeBadge';
import { SupplierInvoiceProjectsCell } from 'features/supplier-invoice/components/SupplierInvoiceProjectsCell';
import { useSupplierInvoicesDirectPaymentAllowed } from 'features/supplier-invoice/services/supplier-invoice.api';
import type { ProgressStatementDirectPaymentForm } from 'features/progress-statement/forms/progress-statement-direct-payment-form';

type AssociateSupplierInvoiceTableProps = {
  onRowSelected: (supplierInvoice: ISupplierInvoice | null) => void;
};

const TypeCell = ({ data }: { data: ISupplierInvoiceListingResponseDTO | undefined }) =>
  data ? <SupplierInvoiceComponentTypeBadge supplierInvoice={data} /> : null;

const ProjectsCell = ({ data }: { data: ISupplierInvoiceListingResponseDTO | undefined }) =>
  data ? <SupplierInvoiceProjectsCell supplierInvoiceProjects={data.supplierInvoiceProjects!} /> : null;

const AmountExVATCell = ({ data }: { data: ISupplierInvoiceListingResponseDTO | undefined }) =>
  data ? <PriceAdvanced amount={data.amountExVAT} /> : null;

export const AssociateSupplierInvoiceTable: FC<AssociateSupplierInvoiceTableProps> = ({ onRowSelected }) => {
  const { t } = useTranslation(['supplierInvoices']);
  const form = useFormContext<ProgressStatementDirectPaymentForm>();
  const { progressStatementId } = useParams<{ progressStatementId: string }>();
  const { selectedOrder } = useDirectPaymentContext();
  const { ordersSupplierInvoices } = useOnChangeValues(form, ['ordersSupplierInvoices']);

  const forcedFilters = useMemo(() => {
    if (selectedOrder) {
      const { id: orderId, project } = selectedOrder;

      return {
        orderId,
        projectId: project?.id,
        ...(progressStatementId ? { progressStatementId } : null),
      };
    }

    return {};
  }, [selectedOrder, progressStatementId]);

  const supplierInvoices = useSupplierInvoicesDirectPaymentAllowed({
    forcedFilters,
    storageStrategy: 'state',
  });

  const columnDefs = useMemo<PaginatedTableProps<SupplierInvoicePaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'invoiceNumber',
        headerName: t('supplierInvoices:fields.invoiceNumber'),
        sortable: true,
      },
      {
        field: 'type',
        headerName: t('supplierInvoices:fields.type'),
        sortable: true,
        cellRenderer: TypeCell,
      },
      {
        field: 'name',
        headerName: t('supplierInvoices:fields.name'),
        sortable: true,
      },
      {
        field: 'supplierInvoiceProjects' as any,
        headerName: t('supplierInvoices:fields.supplierInvoiceProjects'),
        cellRenderer: ProjectsCell,
      },
      {
        field: 'amountExVAT',
        headerName: t('supplierInvoices:fields.amountExVAT'),
        sortable: true,
        cellRenderer: AmountExVATCell,
      },
    ],
    [t],
  );

  const isRowSelectable = useCallback(
    (event: IRowNode<ISupplierInvoice>) => {
      const alreadyAssociatedOrderIds = (ordersSupplierInvoices ?? []).map((osi) => osi.supplierInvoiceId);
      return !alreadyAssociatedOrderIds.includes(event.data?.id ?? -1);
    },
    [ordersSupplierInvoices],
  );

  return (
    <ListingLayout
      pagination={supplierInvoices}
      search={{
        placeholder: t('supplierInvoices:actions.search'),
      }}
      content={
        <>
          <PaginatedTable
            gridId="supplier-invoice-association"
            pagination={supplierInvoices}
            columnDefs={columnDefs}
            zeroState={{
              icon: <SimpleStatementIcon boxSize={45} />,
              label: t('supplierInvoices:associateDirectPaymentSupplierInvoice.emptyMessage'),
            }}
            rowSelection="single"
            emptyState={{
              label: t('supplierInvoices:errors.noResult'),
            }}
            isRowSelectable={isRowSelectable}
            onRowSelected={(event) => {
              onRowSelected(event.api.getSelectedRows()[0] ?? null);
            }}
          />
        </>
      }
    />
  );
};
