import { CurrencyField, useCurrency } from '@graneet/lib-ui';
import { useFormContext } from 'graneet-form';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useJob } from 'features/quote-job/hooks/useJob';
import { useComponents } from 'features/quote-component/hooks/useComponents';

export interface EditQuoteJobDisbursementProps {
  jobId: number;
  isReadOnly: boolean;
}

export const EditQuoteJobDisbursement = ({ jobId, isReadOnly }: EditQuoteJobDisbursementProps) => {
  const { t } = useTranslation(['quote']);
  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();
  const { setFormValues } = useFormContext();

  const job = useJob(jobId);
  const components = useComponents();
  const hasComponents = !!components?.length;

  const amountData = useMemo(
    () => ({ mapValue: (value: number) => (Number.isFinite(value) ? mapAmountToNumber(value) : null) }),
    [mapAmountToNumber],
  );

  useEffect(() => {
    if (!job) {
      return;
    }

    let formValues: any = null;

    if (Number.isFinite(job.unitDisbursementExVAT)) {
      formValues = {
        unitDisbursementExVAT: mapNumberToAmount(job.unitDisbursementExVAT || 0),
      };
      setFormValues(formValues);
    }
  }, [job, mapNumberToAmount, setFormValues]);

  return (
    <CurrencyField
      label={t('quote:fields.unitDisbursementExVAT')}
      data={amountData}
      isDisabled={isReadOnly || hasComponents}
      name={'unitDisbursementExVAT' as never}
      noBorder
      inputProps={{
        color: 'gray.700',
        fontFamily: 'heading',
        fontSize: 16,
        borderStyle: 'solid',
        backgroundColor: 'white !important',
        borderWidth: '2px',
        padding: '1.1rem',
        textAlign: 'right',
        width: '10.7rem',
        borderRadius: '5',
      }}
      labelProps={{
        fontFamily: 'Open Sans',
        fontSize: 14,
        textAlign: 'right',
        width: '10.7rem',
      }}
    />
  );
};
