import type { FC } from 'react';
import { useCallback } from 'react';
import { PreviewFile } from '@graneet/lib-ui';
import { Form, useForm } from 'graneet-form';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import type { IExternalProgressStatementResponseDTO } from '@graneet/business-logic';

import { ProgressStatementExternalValidationModal } from 'features/progress-statement/components/ProgressStatementExternalValidationModal';
import { useApproveExternalProgressStatement } from 'features/progress-statement/services/external-progress-statement.api';
import { ProgressStatementExternalValidationBlock } from 'features/progress-statement/components/ProgressStatementExternalValidationBlock/ProgressStatementExternalValidationBlock';
import { getStreamUrl } from 'features/pdf/services/pdf.api';

interface ProgressStatementExternalPresentationPanelProps {
  externalProgressStatement: IExternalProgressStatementResponseDTO;

  externalId: string;

  onGoToReject: () => void;
}

export const ProgressStatementExternalApproveScreen: FC<ProgressStatementExternalPresentationPanelProps> = ({
  externalProgressStatement,
  externalId,
  onGoToReject,
}) => {
  const pdfForm = useForm();

  const modalValidation = useDisclosure();

  const approveExternalProgressStatementMutation = useApproveExternalProgressStatement();

  const onSubmit = useCallback(
    async (validatorForm: { name: string; email: string }) => {
      await approveExternalProgressStatementMutation.mutateAsync({
        externalId,
        dto: validatorForm,
      });
    },
    [approveExternalProgressStatementMutation, externalId],
  );

  return (
    <>
      <Box flex={1} display={{ base: 'none', md: 'block' }} overflow="auto" p={4} pt={2}>
        <Form form={pdfForm}>
          <PreviewFile
            fileType="application/pdf"
            fileUrl={getStreamUrl(externalProgressStatement.progressStatement.pdf?.apiId) || ''}
          />
        </Form>
      </Box>

      <Flex flex={1} bg="white" borderLeft="1px solid" borderColor="gray.200" direction="column">
        <ProgressStatementExternalValidationBlock
          externalProgressStatement={externalProgressStatement}
          onCommentAndReject={onGoToReject}
          onApprove={modalValidation.onOpen}
        />
      </Flex>

      <ProgressStatementExternalValidationModal
        isOpen={modalValidation.isOpen}
        onClose={modalValidation.onClose}
        onSubmit={onSubmit}
        isApproval
      />
    </>
  );
};
