import { useCallback } from 'react';

import * as jobsApi from '../services/quote-job.api';

import { useJobMoveToast } from './useJobMoveToast';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useStore } from 'store/store';

export const useMoveJobInsideLot = () => {
  const showJobMoveToast = useJobMoveToast();

  const { moveJobInsideLotLocally, startAnotherUpdate, updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (sourceJobId: number, targetLotId: number, isParentHiddenCost = false) => {
      let moveInfos;

      const isParentOptional = useStore.getState().optionalLotsTable[targetLotId?.toString()] || false;

      try {
        moveInfos = moveJobInsideLotLocally(sourceJobId, targetLotId);
        if (moveInfos === null) return;
      } catch (err) {
        showJobMoveToast({ isError: true });
        return;
      }
      const { previousJobId, parentLotId } = moveInfos;

      startAnotherUpdate();

      const [err, infos] = await jobsApi.moveJob(sourceJobId, {
        previousJobId,
        parentLotId,
        isParentOptional,
        isParentHiddenCost,
      });
      if (err) {
        showJobMoveToast({ isError: true });
        return;
      }

      showJobMoveToast({ isError: false });
      updateDataLocally(infos);
    },
    [moveJobInsideLotLocally, showJobMoveToast, startAnotherUpdate, updateDataLocally],
  );
};
