import type {
  QuoteLibraryApi,
  QuoteLibraryCreateBasicItemDTO,
  QuoteLibraryCreateBasicItemWithComponentsDTO,
  QuoteLibraryCreateComponentDTO,
} from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteLibraryProxyApi {
  private static quoteLibraryProxyApi: QuoteLibraryProxyApi;

  private readonly quoteLibraryApi: QuoteLibraryApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteLibraryApi = externalApi.getQuoteLibraryApi();
  }

  public static getQuoteLibraryProxyApi(externalApi: ExternalApi): QuoteLibraryProxyApi {
    if (!this.quoteLibraryProxyApi) {
      QuoteLibraryProxyApi.quoteLibraryProxyApi = new QuoteLibraryProxyApi(externalApi);
    }
    return QuoteLibraryProxyApi.quoteLibraryProxyApi;
  }

  public async createBasicItemWithComponents(
    quoteLibraryCreateBasicItemWithComponentsDTO: QuoteLibraryCreateBasicItemWithComponentsDTO,
  ) {
    return proxyHelper(async () =>
      this.quoteLibraryApi.createBasicItemWithComponents(quoteLibraryCreateBasicItemWithComponentsDTO),
    );
  }

  public async createBasicItem(quoteLibraryCreateBasicItemDTO: QuoteLibraryCreateBasicItemDTO) {
    return proxyHelper(async () => this.quoteLibraryApi.createBasicItem(quoteLibraryCreateBasicItemDTO));
  }

  public async createComponent(quoteLibraryCreateComponentDTO: QuoteLibraryCreateComponentDTO) {
    return proxyHelper(async () => this.quoteLibraryApi.createComponent(quoteLibraryCreateComponentDTO));
  }
}
