import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import { OptionBadge, RichText } from '@graneet/lib-ui';
import type { QuoteLotObject } from '@org/quotation-lib';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDenominationHeight } from './useDenominationHeight';

import { useStore } from 'store/store';

export const Lot: FC<{
  quoteLot: QuoteLotObject;
  level: number;
}> = ({ quoteLot, level }) => {
  const { t } = useTranslation(['quote']);

  const quoteRowHeight = useStore((state) => state.quoteRowHeight);
  const denominationHeight = useDenominationHeight();

  return (
    <VStack
      height="100%"
      justifyContent="center"
      alignItems="flex-start"
      pr="0.5rem"
      pl={`${level * 0.625}rem`}
      width="100%"
      gap="22px"
    >
      <HStack fontSize="14px" width="100%" height={denominationHeight} alignItems="flex-start">
        {quoteLot.isEveryChildOptional && (
          <Flex mt="12px">
            <OptionBadge>{t('quote:option.title')}</OptionBadge>
          </Flex>
        )}
        <Box height={denominationHeight} overflow="hidden" mt="12px" fontWeight={600}>
          <RichText value={quoteLot.denomination ?? ''} />
        </Box>
      </HStack>
      {quoteRowHeight === 'sm' && quoteLot.note && (
        <Box position="absolute" right="12px" top="8px" p="4px" zIndex={1}>
          <i className="ri-sticky-note-line" />
        </Box>
      )}
      {quoteRowHeight !== 'sm' && quoteLot.note && (
        <HStack width="100%" height="30px" overflow="hidden">
          <Flex alignSelf="stretch" alignItems="flex-start" color="#6C737F" fontSize="0.75rem">
            <RichText value={quoteLot.note} />
          </Flex>
        </HStack>
      )}
    </VStack>
  );
};
