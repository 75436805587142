import { useMemo } from 'react';

import { useStore } from 'store/store';

export const useRowHeight = () => {
  const quoteRowHeight = useStore((state) => state.quoteRowHeight);

  return useMemo(() => {
    switch (quoteRowHeight) {
      case 'sm':
        return 44;
      case 'md':
        return 92;
      case 'lg':
        return 180;
      default:
        return 92;
    }
  }, [quoteRowHeight]);
};
