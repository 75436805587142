import { divideFloating, MAXIMUM_FRACTIONAL_PART_LENGTH, multiplyFloating } from './math.util';
import { isNumberFinite } from './number.util';

export const EMPTY_SIGN = '-';

export const formatNumberToVatRate = (number: number) => multiplyFloating(number, 100);

export const formatVatRateToNumber = (number: number) => divideFloating(number, 100);

export const formatPercentage = (percentage: number | null, options?: Intl.NumberFormatOptions): string => {
  if (percentage === null) {
    console.error('Called formatPercentage with a null value');
    return '#ERR';
  }
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'percent',
    maximumFractionDigits: MAXIMUM_FRACTIONAL_PART_LENGTH,
    ...options,
  });

  // the formatter formats 1 as 100%
  return formatter.format(isNumberFinite(percentage) ? formatVatRateToNumber(percentage) : percentage);
};

export const sortVATDistribution = <T extends { vatRate: number }[]>(vatObjects: T): T => {
  if (!vatObjects) return [] as unknown as T;
  const clone = [...vatObjects] as T;
  clone.sort((a, b) => a.vatRate - b.vatRate);
  return clone;
};

export const formatNumber = (floatNumber: number) =>
  Number.isFinite(floatNumber) ? floatNumber.toLocaleString('fr-FR') : null;

export const formatDataOrEmpty = <T>(data: T): NonNullable<T> | string => data || EMPTY_SIGN;

export const joinWithHyphens = (...strings: (string | undefined | null)[]) => strings.filter(Boolean).join(' - ');
