import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@graneet/lib-ui';
import type { IComponentType } from '@graneet/business-logic';

interface ComponentTypeBadgeProps {
  type: Pick<IComponentType, 'name' | 'color'>;
}

export const ComponentTypeBadge: FC<ComponentTypeBadgeProps> = ({ type }) => {
  const { t } = useTranslation(['quote']);
  const { color } = type;

  return (
    <Badge w="100%" showDot colorScheme={color}>
      {type.name === 'undefined' ? t('quote:componentType.undefined') : type.name}
    </Badge>
  );
};
