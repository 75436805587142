import type {
  IClientDTO,
  IClientResponseDTO,
  IClientStatsResponseDTO,
  IClientsImportDTO,
  ISecondaryContactProject,
  ISecondaryContactProjectCreationDTO,
  ISecondaryContactProjectDeleteDTO,
  IUpdateClientDTO,
  IUpdateTagsDTO,
} from '@graneet/business-logic';
import type { PaginatedResponse, UsePaginationOptions } from '@graneet/lib-ui';
import { usePaginationQuery, useToast } from '@graneet/lib-ui';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import {
  type QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
  queryOptions,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { apiNew } from '../../api/services/apiNew.service';

import { api } from 'features/api/services/api.service';

const FACTORY_NAME = 'clients';

const CLIENT_PATH = '/clients';

const clients = createQueryKeys(FACTORY_NAME, {
  get: (queryParams: URLSearchParams | { _full?: boolean }) => ({
    queryKey: [CLIENT_PATH, queryParams],
    queryFn: () =>
      apiNew.get<URLSearchParams | { _full?: boolean }, PaginatedResponse<IClientResponseDTO>>(
        CLIENT_PATH,
        queryParams,
      ),
  }),
  getTags: () => ({
    queryKey: [CLIENT_PATH, 'tags'],
    queryFn: () => apiNew.get<never, string[]>(`${CLIENT_PATH}/tags`),
  }),
  getOne: (id: number) => ({
    queryKey: [CLIENT_PATH, id],
    queryFn: () => apiNew.get<never, IClientResponseDTO>(`${CLIENT_PATH}/${id}`),
  }),
  getOneStats: (id: number) => ({
    queryKey: [CLIENT_PATH, 'stats', id],
    queryFn: () => apiNew.get<never, IClientStatsResponseDTO>(`${CLIENT_PATH}/${id}/stats`),
  }),
});

async function invalidAllClientsQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [FACTORY_NAME],
  });
}

export function useClients(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery((urlSearchParams) => queryClient.fetchQuery(clients.get(urlSearchParams)), options);
}

function getClientsOptions(queryParams: URLSearchParams) {
  return queryOptions(clients.get(queryParams));
}

export function useClientsQuery(
  queryParams: URLSearchParams,
  options?: Omit<ReturnType<typeof getClientsOptions>, 'queryKey'>,
) {
  return useQuery({
    ...clients.get(queryParams),
    ...options,
  });
}

export function useClientsWithoutPagination(options?: { projectId: number }) {
  return useSuspenseQuery(clients.get({ _full: true, ...options }));
}

export function useClientsTags() {
  return useSuspenseQuery(clients.getTags());
}

export function useClient(id: number) {
  return useSuspenseQuery(clients.getOne(id));
}

export function useClientStats(id: number) {
  return useSuspenseQuery(clients.getOneStats(id));
}

export function useClientCreate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'clients']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IClientDTO) => apiNew.post<IClientDTO, IClientResponseDTO>(CLIENT_PATH, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:words.c.success'), t('clients:clientModalEditor.clientCreationToastSuccess'));
      await invalidAllClientsQueries(queryClient);
    },
  });
}

export function useClientEdit() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'clients']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateClientDTO }) =>
      apiNew.patch<IUpdateClientDTO, IClientResponseDTO>(`${CLIENT_PATH}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:words.c.success'), t('clients:clientModalEditor.clientEditionToastSuccess'));
      await invalidAllClientsQueries(queryClient);
    },
  });
}

export function useClientEditTags() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateTagsDTO }) =>
      apiNew.patch<IUpdateTagsDTO, IClientResponseDTO>(`${CLIENT_PATH}/${params.id}/tags`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('global:words.c.success'), t('global:changeTags.success'));
      await invalidAllClientsQueries(queryClient);
    },
  });
}

export function useClientRemove() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'clients']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, IClientResponseDTO>(`${CLIENT_PATH}/${id}`),
    onError: () => {
      toast.error(t('global:toasts.genericError.title'), t('global:toasts.genericError.description'));
    },
    onSuccess: async () => {
      toast.success(t('clients:cards.delete.success'));
      invalidAllClientsQueries(queryClient);
    },
  });
}

export function useClientImport() {
  const toast = useToast();
  const { t } = useTranslation(['clients']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: IClientsImportDTO) =>
      apiNew.post<IClientsImportDTO, IClientResponseDTO[]>(`${CLIENT_PATH}/batch`, dto),
    onError: () => {
      toast.error(t('clients:import.error'));
    },
    onSuccess: async () => {
      toast.success(t('clients:import.success'));
      await invalidAllClientsQueries(queryClient);
    },
  });
}

export function useClientAssociateToProject() {
  const toast = useToast();
  const { t } = useTranslation(['project']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ISecondaryContactProjectCreationDTO) =>
      apiNew.post<ISecondaryContactProjectCreationDTO, ISecondaryContactProject[]>(`${CLIENT_PATH}/project-link`, dto),
    onError: () => {
      toast.error(t('project:flow.client.toast.errorLink'));
    },
    onSuccess: async () => {
      toast.success(t(`project:flow.client.toast.successLinkSecondary`));
      await invalidAllClientsQueries(queryClient);
    },
  });
}

export function useClientDeassociateToProject() {
  const toast = useToast();
  const { t } = useTranslation(['project']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ISecondaryContactProjectDeleteDTO) =>
      apiNew.delete<ISecondaryContactProjectDeleteDTO, void>(`${CLIENT_PATH}/project-link`, dto),
    onError: () => {
      toast.error(t('project:flow.client.toast.errorLink'));
    },
    onSuccess: async () => {
      toast.success(t(`project:flow.client.toast.successUnLinkSecondary`));
      await invalidAllClientsQueries(queryClient);
    },
  });
}

// TODO See how we can remove this
export const getClientById = (clientId: number) => api.get<never, IClientResponseDTO>(`${CLIENT_PATH}/${clientId}`);
