import dayjs from 'dayjs';

import type { ICompany } from '../company/company.type';
import { COMPANY_TT_APPROVAL_MODE } from '../company/company.type';

import { ABSENCE_DURATION } from './time-slot.type';

export const ABSENCE_DURATION_POSSIBLE_VALUES: {
  FULL_DAY: number;
  HALF_DAY: number;
} = {
  [ABSENCE_DURATION.FULL_DAY]: 1,
  [ABSENCE_DURATION.HALF_DAY]: 0.5,
};

export function canTimeSlotBeApprovedForCreation(timeslotDate: Date, company: ICompany): boolean {
  return !dayjs(timeslotDate).isAfter(dayjs(), 'day') && company.ttApprovalMode === COMPANY_TT_APPROVAL_MODE.AUTO;
}
