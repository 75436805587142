import type { AnyRecord, FieldValues } from 'graneet-form';
import { Field } from 'graneet-form';
import type { ReactNode } from 'react';

import type { KeysMatching } from '../../../utils/type.util';
import type { SegmentedInputGroupProps } from '../../Input';
import { SegmentedInputGroup } from '../../Input';

type SegmentedInputFieldValue = string | undefined;

export interface SegmentedInputGroupFieldProps<
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, SegmentedInputFieldValue> = KeysMatching<T, SegmentedInputFieldValue>,
> extends Omit<SegmentedInputGroupProps, 'onChange' | 'name'> {
  name: K;
  data?: AnyRecord;
  children?: ReactNode;
  rules?: ReactNode;
}

export const SegmentedInputGroupField = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, SegmentedInputFieldValue> = KeysMatching<T, SegmentedInputFieldValue>,
>({
  name,
  data,
  children = null,
  rules,
  ...rest
}: SegmentedInputGroupFieldProps<T, K>) => (
  <Field<T, K>
    name={name}
    data={data}
    render={({ value, onChange, onBlur, onFocus }) => (
      <SegmentedInputGroup
        name={name as string}
        {...rest}
        value={value}
        onChange={(e) => {
          onFocus();
          onChange(e as any);
          onBlur();
        }}
      >
        {children}
      </SegmentedInputGroup>
    )}
  >
    {rules}
  </Field>
);
