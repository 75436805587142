import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { genericUpdateItemMapper } from 'features/quotation/quote-common/mappers/quoteItemsGenericMapper';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteSubItemUpdateRefCode() {
  const { quoteSubItemsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, newRefCode: string | null): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteSubItem = quote.getTree().getQuoteSubItemOrThrow(nodeId);
          const quoteNode = quoteSubItem.getNodeOrThrow();
          this.oldValue = quoteSubItem.getRefCode();
          quoteSubItem.updateProperties({
            refCode: newRefCode,
          });
          quoteSetToStore(quote);
          if (quoteSubItemsProxyApi) {
            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteSubItemsProxyApi
              .updateQuoteSubItem(quoteSubItem.getId(), {
                ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
                refCode: newRefCode,
                quoteSubItemId: quoteSubItem.getId(),
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
          }
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteSubItem = quote.getTree().getQuoteSubItemOrThrow(nodeId);
          const quoteNode = quoteSubItem.getNodeOrThrow();
          quoteSubItem.updateProperties({
            refCode: this.oldValue,
          });
          quoteSetToStore(quote);
          if (quoteSubItemsProxyApi) {
            const clientRequestId = uuid();
            const timestamp = new Date().toISOString();
            quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
            quoteSubItemsProxyApi
              .updateQuoteSubItem(quoteSubItem.getId(), {
                ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
                refCode: this.oldValue,
                quoteSubItemId: quoteSubItem.getId(),
              })
              .then(([err]) => {
                if (err) {
                  quoteError();
                }
              })
              .catch((err) => quoteError(err.message));
          }
          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteSetClientRequestToStore, quoteSetToStore, quoteSubItemsProxyApi],
  );
}
