import { useCallback, useEffect, useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Multiplication, useCurrency, HiddenCostDisplay, divideFloating } from '@graneet/lib-ui';
import { Trans, useTranslation } from 'react-i18next';
import { useOnChangeValues, useFormContext } from 'graneet-form';
import { isComputableMargin, isAmountExVATEditable, getDiscountAmountExVAT } from '@graneet/business-logic';
import { bool, func, object, string } from 'prop-types';

import { QUOTE_TOTAL_FIELDS } from '../../constants/quotes.constant';

import { useStore } from 'store/store';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useLot } from 'features/quote-lot/hooks/useLot';
import { QuoteDiscountMarginErrorComponent } from 'features/quote-discount/components/QuoteDiscountMarginError';
import { Rule } from 'features/form/rules/Rule';
import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';

const QUOTE_TOTAL_MARGIN_FIELD = `QUOTE_${QUOTE_TOTAL_FIELDS.TOTAL_MARGIN}`;

export const QuoteSalesSheetSellingMultiplication = ({
  onFocus,
  onBlur,
  quote,
  currentEditField,
  isEditionDisabled,
  onChange,
  type = 'quote',
}) => {
  const { t } = useTranslation(['quote']);

  const form = useFormContext();

  const { lotIdToUpdate } = useQuoteDisplayContext();
  const wrapperLot = useLot(lotIdToUpdate);

  const { mapAmountToNumber, formatAsAmount, mapNumberToAmount } = useCurrency();
  const values = useOnChangeValues(form, [QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT, QUOTE_TOTAL_FIELDS.DISBURSEMENT_EX_VAT]);

  const { getCurrentQuoteData } = useQuoteEditContext();
  const { rootLotId } = getCurrentQuoteData();
  const rootLot = useLot(rootLotId);
  const hasHiddenCosts = useStore((state) => state.hasHiddenCosts);

  const getAmountExVATConstrains = useCallback(() => {
    if (!rootLot) return {};

    const { disbursementExVAT } = rootLot.lot;
    // if the quote disbursement is negative, only allow negative value
    if (disbursementExVAT < 0) {
      return { max: 0 };
    }
    // if the quote disbursement is null, only allow 0
    if (disbursementExVAT === 0) {
      return { min: 0, max: 0 };
    }
    // if the quote disbursement is positive
    return { min: 0 };
  }, [rootLot]);

  const amountExVAT = values[QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT];
  const disbursementExVAT = values[QUOTE_TOTAL_FIELDS.DISBURSEMENT_EX_VAT];

  const amountWithoutDiscountExVAT = quote?.amountWithoutDiscountExVAT || 0;
  const discountAmountExVAT = getDiscountAmountExVAT(quote?.discount, amountWithoutDiscountExVAT);

  const isAmountExVatEditionAllowed = isAmountExVATEditable(quote?.discount, disbursementExVAT);

  const hiddenCostAmount = useMemo(
    () => (wrapperLot ?? rootLot)?.lot.hiddenCostAmountExVAT ?? 0,
    [wrapperLot, rootLot],
  );

  const quoteProfit = amountExVAT - (disbursementExVAT + mapNumberToAmount(hiddenCostAmount));
  const quoteProfitInt = mapAmountToNumber(quoteProfit);
  const quoteProfitText = Number.isNaN(quoteProfitInt) ? '-' : formatAsAmount(quoteProfitInt);

  useEffect(() => {
    if (!rootLot && !wrapperLot) {
      return;
    }

    form.setFormValues({
      hiddenCostPercent:
        1 +
        divideFloating(
          (wrapperLot ?? rootLot).lot.hiddenCostAmountExVAT,
          (wrapperLot ?? rootLot).lot.disbursementExVAT,
        ),
    });
  }, [wrapperLot, rootLot, form]);

  return (
    <Flex backgroundColor="gray.100" direction="column" borderRadius="md" pt={3} pb={1}>
      <Box maxW={hasHiddenCosts ? '42rem' : '35rem'} mr={4}>
        <Multiplication
          hiddenCostAmount={mapNumberToAmount(hiddenCostAmount)}
          hiddenCost={
            hasHiddenCosts && (
              <HiddenCostDisplay
                hiddenCostAmount={formatAsAmount(hiddenCostAmount)}
                toolTipsLabel={t('quote:hiddenCost.title')}
                fieldLabel={t('quote:hiddenCost.title')}
              />
            )
          }
        >
          <Multiplication.Field
            name={QUOTE_TOTAL_FIELDS.DISBURSEMENT_EX_VAT}
            label={t(
              hasHiddenCosts
                ? 'quote:jobsStep.fields.flatUnitDisbursementExVAT'
                : 'quote:jobsStep.fields.totalDisbursementExVAT',
            )}
            computedName={QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT}
            isCurrency
            isDisabled
          >
            <Rule.IsRequired />
          </Multiplication.Field>

          <Multiplication.Field
            name={QUOTE_TOTAL_MARGIN_FIELD}
            label={t('margin:fields.totalMargin')}
            computedName={QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT}
            isDisabled
            scale={3}
            min={0}
          >
            <Rule.IsRequired />
            <Rule.IsNotZero />
          </Multiplication.Field>

          <Multiplication.Field
            name={QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT}
            label={t('quote:fields.priceExVAT')}
            computedName={QUOTE_TOTAL_MARGIN_FIELD}
            {...getAmountExVATConstrains()}
            onChange={(value) => {
              onChange(QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT, value);
            }}
            onFocus={onFocus(QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT, values[QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT])}
            onBlur={onBlur()}
            isDisabled={
              !isAmountExVatEditionAllowed ||
              (isEditionDisabled && currentEditField !== QUOTE_TOTAL_FIELDS.AMOUNT_EX_VAT)
            }
            isCurrency
          >
            <Rule.IsRequired />
            {disbursementExVAT !== 0 && discountAmountExVAT !== amountWithoutDiscountExVAT && <Rule.IsNotZero />}
          </Multiplication.Field>
        </Multiplication>
      </Box>

      <Box mt={2}>
        {!isComputableMargin(disbursementExVAT, amountExVAT) ? (
          <QuoteDiscountMarginErrorComponent />
        ) : (
          <Text color="gray.800">
            <Trans
              t={t}
              i18nKey={
                type === 'quote'
                  ? 'quote:jobsStep.salesSheetModal.marginEstimated'
                  : 'quote:lotEdition.lotSalesSheetDrawer.marginEstimated'
              }
              values={{ profit: quoteProfitText }}
            >
              description_start
              <Text as="span" color="greenBrand.light" fontWeight={600} ml={1}>
                displayed_data
              </Text>
              description_end
            </Trans>
          </Text>
        )}
      </Box>
    </Flex>
  );
};

QuoteSalesSheetSellingMultiplication.propTypes = {
  onFocus: func.isRequired,
  onBlur: func,
  quote: object,
  currentEditField: string,
  isEditionDisabled: bool,
  onChange: func,
  type: string.isRequired,
};

QuoteSalesSheetSellingMultiplication.defaultProps = {
  quote: undefined,
  currentEditField: undefined,
  isEditionDisabled: false,
  onBlur: () => {},
  onChange: () => {},
};
