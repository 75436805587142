import type { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import type { ISupplier } from '@graneet/business-logic';

import { SupplierTypeBadge } from './SupplierTypeBadge';

export type SupplierFieldOptionProps = {
  /**
   * The supplier for which render the formatted option
   */
  supplier: ISupplier;
  showBadge?: boolean;
};

/**
 * Render a AutocompleteField option for a supplier
 */
export const SupplierFieldOption: FC<SupplierFieldOptionProps> = ({ supplier, showBadge = true }) => (
  <Flex alignItems="baseline">
    {showBadge && <SupplierTypeBadge type={supplier.type} w="7rem" />}
    <Box fontFamily="heading" px={2} color="gray.800">
      {supplier.name}
    </Box>

    {supplier.code && (
      <>
        <Text margin="0 1rem 0 1rem" color="gray.500">
          -
        </Text>

        <Text fontSize="xs" color="gray.500">
          {supplier?.code}
        </Text>
      </>
    )}
    {supplier?.address && (
      <>
        <Text margin="0 1rem 0 1rem" color="gray.500">
          -
        </Text>

        <Text fontSize="xs" color="gray.500">
          {supplier?.address.address}
        </Text>
      </>
    )}
  </Flex>
);
