import { MIME_TYPE } from './mime-type.enum';

export enum FILE_TYPE {
  SUPPLIER_INVOICE = 'supplier-invoice',
  ORDER = 'order',
  COMPANY_LOGO = 'company-logo',
  QUOTE_IMPORT = 'quote-import',
  PROJECT_IMPORT = 'project-import',
  QUOTE_JOB = 'quote-job',
  ACCEPTANCE_REPORT = 'acceptance-report',
  EMAIL_ATTACHMENT = 'email-attachment',
}

export enum CONVERSION_STATUS {
  NOT_CONVERTED = 'not-converted',
  CONVERTING = 'converting',
  CONVERTED = 'converted',
  ERROR = 'error',
}

export const MAX_COMPANY_LOGO_SIZE = 1000;

export const THUMBNAIL_PROPERTIES: {
  WIDTH: number;
  HEIGHT: number;
  MIME_TYPE: MIME_TYPE;
  EXTENSION: string;
} = {
  WIDTH: 2500,
  HEIGHT: 300,
  MIME_TYPE: MIME_TYPE.PNG,
  EXTENSION: '_thumbnail.png',
};
