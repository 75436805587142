import type { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { ViewLibraryJobsScreen } from './ViewLibraryJobsScreen';
import { ViewLibraryJobDetailScreen } from './ViewLibraryJobDetailScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';

export const LibraryJobsRouter: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/:libraryJobId`}>
        <QueryWrapper>
          <ViewLibraryJobDetailScreen />
        </QueryWrapper>
      </Route>
      <Route exact path="*">
        <ViewLibraryJobsScreen />
      </Route>
    </Switch>
  );
};
