import { QuoteSettingDrawerSelected } from '@org/graneet-bff-client';

import type { QuoteSummaryDisplayItems } from 'features/quotation/ag-grid-quote/components/QuoteSummaryBar';

export type QuoteColumns = {
  name: string;
  width: number;
  enabled: boolean;
  index: number;
}[];

export type QuoteSetting = {
  drawerSelected: QuoteSettingDrawerSelected;
  isAllItemsExpanded: boolean;
  isComponentsExpanded: boolean;
  isDrawerExpanded: boolean;
  isHiddenCostTabAllItemsExpanded: boolean;
};

export type QuoteSlice = {
  quoteSummaryBarItemDisplayed: QuoteSummaryDisplayItems;
  quoteRootColumns: QuoteColumns;
  quoteHiddenCostColumns: QuoteColumns;
  quoteSetting: QuoteSetting;
};

export const defaultQuoteRootColumns = [
  {
    name: 'refCode',
    width: 100,
    enabled: false,
    index: 0,
  },
  {
    name: 'code',
    width: 100,
    enabled: true,
    index: 1,
  },
  {
    name: 'denomination',
    width: 284,
    enabled: true,
    index: 2,
  },
  {
    name: 'quantity',
    width: 105,
    enabled: true,
    index: 3,
  },
  {
    name: 'unit',
    width: 85,
    enabled: true,
    index: 4,
  },
  {
    name: 'unitFlatCostAmount',
    width: 133,
    enabled: true,
    index: 5,
  },
  {
    name: 'flatCostAmount',
    width: 165,
    enabled: false,
    index: 6,
  },
  {
    name: 'shareOfHiddenCostAmount',
    width: 165,
    enabled: false,
    index: 7,
  },
  {
    name: 'costAmount',
    width: 165,
    enabled: true,
    index: 8,
  },
  {
    name: 'totalMargin',
    width: 91,
    enabled: true,
    index: 9,
  },
  {
    name: 'unitAmount',
    width: 108,
    enabled: true,
    index: 10,
  },
  {
    name: 'vatRate',
    width: 80,
    enabled: true,
    index: 11,
  },
  {
    name: 'workingDuration',
    width: 140,
    enabled: true,
    index: 12,
  },
  {
    name: 'amount',
    width: 154,
    enabled: true,
    index: 13,
  },
  {
    name: 'image',
    width: 100,
    enabled: true,
    index: 14,
  },
];

export const defaultQuoteHiddenCostColumns = [
  {
    name: 'refCode',
    width: 100,
    enabled: true,
    index: 0,
  },
  {
    name: 'code',
    width: 100,
    enabled: true,
    index: 1,
  },
  {
    name: 'denomination',
    width: 284,
    enabled: true,
    index: 2,
  },
  {
    name: 'quantity',
    width: 105,
    enabled: true,
    index: 3,
  },
  {
    name: 'unit',
    width: 85,
    enabled: true,
    index: 4,
  },
  {
    name: 'unitFlatCostAmount',
    width: 133,
    enabled: true,
    index: 5,
  },
  {
    name: 'flatCostAmount',
    width: 165,
    enabled: true,
    index: 6,
  },
  {
    name: 'workingDuration',
    width: 140,
    enabled: true,
    index: 7,
  },
  {
    name: 'image',
    width: 100,
    enabled: true,
    index: 8,
  },
];

export const defaultQuoteSummaryBarItemDisplayed: QuoteSummaryDisplayItems = [
  {
    isDisplayed: true,
    name: 'flatCostAmount',
  },
  {
    isDisplayed: false,
    name: 'shareOfHiddenCostAmount',
  },
  {
    isDisplayed: false,
    name: 'optionalAmount',
  },
  {
    isDisplayed: false,
    name: 'overheadCosts',
  },
  {
    isDisplayed: false,
    name: 'profitMargin',
  },
  {
    isDisplayed: true,
    name: 'totalMargin',
  },
  {
    isDisplayed: false,
    name: 'workforce',
  },
  {
    isDisplayed: true,
    name: 'amountExVat',
  },
  {
    isDisplayed: true,
    name: 'amountIncVat',
  },
];

export const defaultQuoteSetting: QuoteSetting = {
  drawerSelected: QuoteSettingDrawerSelected.Sale,
  isDrawerExpanded: true,
  isAllItemsExpanded: true,
  isComponentsExpanded: true,
  isHiddenCostTabAllItemsExpanded: true,
};

const initialSlice: QuoteSlice = {
  quoteSummaryBarItemDisplayed: defaultQuoteSummaryBarItemDisplayed,
  quoteRootColumns: defaultQuoteRootColumns,
  quoteHiddenCostColumns: defaultQuoteHiddenCostColumns,
  quoteSetting: defaultQuoteSetting,
};

export const createQuoteSettingSlice = () => initialSlice;
