import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box, Grid, HStack, VStack } from '@chakra-ui/react';
import type {
  IProject,
  ISupplierInvoice,
  ISupplierInvoiceResponseDTO,
  IUpdatePartialDraftedDirectPaymentSupplierInvoiceDTO,
  RequiredByKeys,
} from '@graneet/business-logic';
import { SUPPLIER_INVOICE_MODE, MINDEE_MAX_PAGES, multiplyFloating, VAT_TYPE } from '@graneet/business-logic';
import {
  Callout,
  DateField,
  formatDateToString,
  formatNumberToVatRate,
  isNumberFinite,
  Modal,
  SimpleAlertIcon,
  TextField,
  useCurrency,
} from '@graneet/lib-ui';
import { Form, HiddenField, useForm, useFormStatus, VALIDATION_OUTCOME } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash-es';

import { useDirectPaymentContext } from '../../contexts/DirectPaymentContext';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';
import { Rule } from 'features/form/rules/Rule';
import {
  useSupplierInvoiceCreate,
  useSupplierInvoiceProgressOCRLazy,
  useSupplierInvoiceUpdate,
} from 'features/supplier-invoice/services/supplier-invoice.api';
import {
  mapCreateSupplierInvoiceDTO,
  mapUpdateSupplierInvoiceDTO,
  mapSupplierInvoiceOCRResponseToFormValues,
} from 'features/supplier-invoice/services/supplier-invoices.util';
import { formatFilePreviewUrl } from 'features/file/services/file.util';
import type { AmountsFieldForm } from 'features/supplier-invoice/components/SupplierInvoiceAmountsField';
import { SupplierInvoiceAmountsField } from 'features/supplier-invoice/components/SupplierInvoiceAmountsField';
import type { EditSupplierInvoiceForm } from 'features/supplier-invoice/forms/edit-supplier-invoice.form';
import {
  buildProjectAmountExVATFieldName,
  buildProjectDistributionFieldName,
} from 'features/supplier-invoice/forms/edit-supplier-invoice.form';
import type { UpdatableInvoiceFileCardFormValues } from 'features/supplier-invoice/components/cards/UpdatableInvoiceFileCard/UpdatableInvoiceFileCard';
import { UpdatableInvoiceFileCard } from 'features/supplier-invoice/components/cards/UpdatableInvoiceFileCard/UpdatableInvoiceFileCard';

interface FormValues extends AmountsFieldForm, UpdatableInvoiceFileCardFormValues {
  name: string;

  invoiceNumber: string;

  typeId: number;

  billingDate: string;

  paymentDate?: string | null;

  mode: SUPPLIER_INVOICE_MODE;

  vatType: VAT_TYPE;
}

interface CreateOrEditDirectPaymentSupplierInvoiceModalProps {
  modal: UseDisclosureReturn;
  supplierInvoice?: ISupplierInvoice;
  isEditContext?: boolean;
  onSubmitSuccess(supplierInvoice: ISupplierInvoice): void;
}

export const CreateOrEditDirectPaymentSupplierInvoiceModal: FC<CreateOrEditDirectPaymentSupplierInvoiceModalProps> = ({
  modal,
  supplierInvoice,
  isEditContext = false,
  onSubmitSuccess,
}) => {
  const { t } = useTranslation(['global', 'supplierInvoices']);

  const [areVatRatesDifferent, setAreVatRatesDifferent] = useState(false);
  const { mapNumberToAmount, mapAmountToNumber } = useCurrency();

  const form = useForm<FormValues>();
  const { isValid: isFormValid } = useFormStatus(form);

  const [forceFormError, setForceFormError] = useState(false);
  const { selectedOrder } = useDirectPaymentContext();
  const {
    amountExVAT: orderAmountExVAT = 0,
    amountPreviousDirectPaymentsExVAT = 0,
    vatDistribution: orderVatDistribution,
  } = selectedOrder ?? {};

  const supplierInvoiceProgressOCRLazy = useSupplierInvoiceProgressOCRLazy();
  const supplierInvoiceCreateMutation = useSupplierInvoiceCreate();
  const supplierInvoiceUpdateMutation = useSupplierInvoiceUpdate();

  const currentSelectedFileRef = useRef<File | undefined>();
  const onFileChange = useCallback(
    async (file: File | undefined, hasOCRCreditLeft: boolean) => {
      currentSelectedFileRef.current = file;

      if (!file || !hasOCRCreditLeft) {
        return;
      }

      const ocrFormValidator = form.formInternal.getFormErrorsForNames(['invoiceNumber', 'billingDate', 'amountExVAT']);

      // The OCR is called if at least one OCR field is not valid
      const canOCRBeCalled = !Object.values(ocrFormValidator).every(
        (validationStatus) => validationStatus?.status === VALIDATION_OUTCOME.VALID,
      );

      const { invoiceFile, pdfPageNumber } = form.getFormValues();
      const hasTooManyPages = (pdfPageNumber || 0) > MINDEE_MAX_PAGES;
      if (canOCRBeCalled && invoiceFile && !hasTooManyPages) {
        const ocrDatas = await supplierInvoiceProgressOCRLazy.mutateAsync({
          invoiceFile: invoiceFile as unknown as File,
        });

        if (ocrDatas.ocrSuccess) {
          const initialFormValues = mapSupplierInvoiceOCRResponseToFormValues(ocrDatas, mapNumberToAmount);

          const vatRate = orderVatDistribution?.[0]?.vatRate;
          if (
            isNumberFinite(vatRate) &&
            isNumberFinite(initialFormValues?.vatRate) &&
            initialFormValues.vatRate !== multiplyFloating(vatRate, 100)
          ) {
            setAreVatRatesDifferent(true);
          }

          // Keep the same vatRate as Order
          delete initialFormValues.vatRate;
          form.setFormValues(initialFormValues);
          setForceFormError(true);
        }
      }
    },
    [form, mapNumberToAmount, orderVatDistribution, supplierInvoiceProgressOCRLazy],
  );

  const initForm = useCallback(() => {
    const initialFormValues = {
      typeId: selectedOrder?.type?.id,
      mode: SUPPLIER_INVOICE_MODE.CONDENSED,
      vatType: VAT_TYPE.NORMAL,
      vatRate:
        selectedOrder?.vatType === VAT_TYPE.NORMAL && selectedOrder?.vatDistribution.length
          ? formatNumberToVatRate(selectedOrder?.vatDistribution[0].vatRate || 0)
          : 0,
    };

    if (isEditContext && supplierInvoice) {
      Object.assign(initialFormValues, {
        ...supplierInvoice,
        amountExVAT: mapNumberToAmount(supplierInvoice.amountExVAT ?? 0),
        billingDate: formatDateToString(supplierInvoice.billingDate) || '',
        paymentDate: formatDateToString(supplierInvoice.paymentDate) || '',
        vatType: supplierInvoice.vatType,
        vatRate: supplierInvoice?.vatDistribution.length
          ? formatNumberToVatRate(supplierInvoice?.vatDistribution[0].vatRate || 0)
          : 0,
        invoiceFile: supplierInvoice.invoiceFile
          ? {
              fileURL: formatFilePreviewUrl(supplierInvoice.invoiceFile.id),
              mimeType: supplierInvoice.invoiceFile.mimeType,
            }
          : undefined,
      });
    }
    form.setFormValues(initialFormValues);
  }, [
    form,
    isEditContext,
    mapNumberToAmount,
    selectedOrder?.type?.id,
    selectedOrder?.vatDistribution,
    selectedOrder?.vatType,
    supplierInvoice,
  ]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const handleClose = useCallback(() => {
    form.resetForm();
    initForm();
    modal.onClose();
    setForceFormError(false);
    setAreVatRatesDifferent(false);
  }, [form, initForm, modal]);

  const handleSubmit = useCallback(async () => {
    const { invoiceFile: currentInvoiceFile, ...values } = form.getFormValues();

    if (!selectedOrder || !selectedOrder?.project) {
      throw Error('orderProject must be defined.');
    }
    // Add additional values from selected order
    const orderProject = selectedOrder?.project;
    const distributionFieldName = buildProjectDistributionFieldName(orderProject);
    const projectAmountExVAT = buildProjectAmountExVATFieldName(orderProject);

    const supplierInvoiceDTO: EditSupplierInvoiceForm = {
      mode: SUPPLIER_INVOICE_MODE.CONDENSED,
      vatType: values.vatType!,
      itemIds: [],
      amountExVAT: values.amountExVAT!,
      billingDate: values.billingDate!,
      invoiceNumber: values.invoiceNumber!,
      name: values.name!,
      pdfPageNumber: values.pdfPageNumber!,
      typeId: values.typeId!,
      vatRate: values.vatRate!,
      paymentDate: values.paymentDate,

      deletedReceiptFilesIds: [],
      initialReceiptFiles: [],
      receiptFiles: [],

      supplierId: selectedOrder?.supplier.id,
      associatedProjects: [{ id: orderProject.id }] as RequiredByKeys<IProject, 'primaryClient'>[],
    };
    supplierInvoiceDTO[distributionFieldName] = 100;
    supplierInvoiceDTO[projectAmountExVAT] = values.amountExVAT!;

    let supplierInvoiceReturned: ISupplierInvoiceResponseDTO;
    if (isEditContext) {
      supplierInvoiceReturned = await supplierInvoiceUpdateMutation.mutateAsync({
        id: supplierInvoice!.id,
        dto: {
          ...omit(mapUpdateSupplierInvoiceDTO(supplierInvoiceDTO, mapAmountToNumber, supplierInvoice), [
            // #3619: fields that cannot be updated and that the backend will reject
            'associatedProjects',
            'supplierId',
          ]),
          deleteInvoiceFile: `${!currentInvoiceFile}`,
        } satisfies IUpdatePartialDraftedDirectPaymentSupplierInvoiceDTO,
        invoiceFile: currentSelectedFileRef.current,
      });
    } else {
      supplierInvoiceReturned = await supplierInvoiceCreateMutation.mutateAsync({
        dto: mapCreateSupplierInvoiceDTO(supplierInvoiceDTO, mapAmountToNumber),
        invoiceFile: currentSelectedFileRef.current,
      });
    }

    onSubmitSuccess(supplierInvoiceReturned);

    handleClose();
  }, [
    form,
    handleClose,
    isEditContext,
    mapAmountToNumber,
    onSubmitSuccess,
    selectedOrder,
    supplierInvoice,
    supplierInvoiceCreateMutation,
    supplierInvoiceUpdateMutation,
  ]);

  const isMutationPending = supplierInvoiceCreateMutation.isPending || supplierInvoiceUpdateMutation.isPending;

  return (
    <Modal
      isOpen={modal.isOpen}
      onClose={handleClose}
      title={
        isEditContext
          ? t('supplierInvoices:directPaymentSupplierInvoice.editTitle')
          : t('supplierInvoices:directPaymentSupplierInvoice.addTitle')
      }
      size="6xl"
      scrollBehavior="inside"
    >
      <QueryWrapper>
        <Box height="25rem">
          <Form form={form} style={{ height: '100%' }}>
            <Grid h="100%" templateColumns="1.25fr 1fr" gap={6}>
              <Box overflow="auto">
                <UpdatableInvoiceFileCard onFileChange={onFileChange} withCardHeader={false} hasOCR />
              </Box>

              <Box overflow="auto">
                <VStack align="stretch" spacing={6}>
                  <TextField<FormValues>
                    name="name"
                    label={t('supplierInvoices:cards.informationsCard.name')}
                    forceDisplayError={forceFormError}
                    isRequired
                  >
                    <Rule.IsRequired />
                  </TextField>

                  <HStack align="stretch" spacing={8}>
                    <TextField<FormValues>
                      name="invoiceNumber"
                      label={t('supplierInvoices:cards.informationsCard.invoiceNumber')}
                      forceDisplayError={forceFormError}
                      isRequired
                    >
                      <Rule.IsRequired />
                    </TextField>

                    <ComponentTypeField<FormValues>
                      name="typeId"
                      label={t('supplierInvoices:fields.invoiceType')}
                      isRequired
                      material
                    >
                      <Rule.IsRequired />
                    </ComponentTypeField>
                  </HStack>

                  <DateField<FormValues>
                    name="billingDate"
                    label={t('supplierInvoices:cards.informationsCard.billingDate')}
                    width="100%"
                    forceDisplayError={forceFormError}
                    isRequired
                  >
                    <Rule.IsRequired />
                  </DateField>

                  <HiddenField<FormValues> name="mode" />

                  <DateField<FormValues>
                    name="paymentDate"
                    label={t('supplierInvoices:cards.informationsCard.paymentDate')}
                    width="100%"
                  />

                  <SupplierInvoiceAmountsField
                    forceFormError={forceFormError}
                    orderAmountToBeBilledExVAT={(orderAmountExVAT || 0) - amountPreviousDirectPaymentsExVAT}
                    isDirectPayment
                  />
                </VStack>

                {areVatRatesDifferent && (
                  <Box pt={3}>
                    <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
                      {t('supplierInvoices:cards.informationsCard.alertOnDifferentVat')}
                    </Callout>
                  </Box>
                )}
              </Box>
            </Grid>
          </Form>
        </Box>

        <Modal.Close isDisabled={isMutationPending} />

        <Modal.PrimaryButton isLoading={isMutationPending} isDisabled={!isFormValid} onClick={handleSubmit}>
          {isEditContext
            ? t('supplierInvoices:actions.editTheInvoice')
            : t('supplierInvoices:actions.createTheInvoice')}
        </Modal.PrimaryButton>
      </QueryWrapper>
    </Modal>
  );
};
