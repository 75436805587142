export interface VATTranslations {
  vat(vatRate: string): string;
  vatWithReverseLiability: string;
  vatWithIntraCommunity: string;
  globalVat: string;
  customDiscountVat: (vatRate: string) => string;
}

export const vatTranslations = {
  vat: (vatRate: string) => vatRate,
  vatWithReverseLiability: '',
  vatWithIntraCommunity: '',
  globalVat: '',
  customDiscountVat: (vatRate: string) => vatRate,
};

export const configureDefaultLabels = (translations: VATTranslations) => {
  Object.assign(vatTranslations, translations);
};
