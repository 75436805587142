import type { FC } from 'react';
import { useEffect } from 'react';
import { Form, useStepForm } from 'graneet-form';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { StepperModal } from '@graneet/lib-ui';

import type { BatiprixComponentTypeForm, BatiprixEditConfigWizard } from '../../forms/batiprix-edit-config.wizard';

import { Rule } from 'features/form/rules/Rule';
import { ComponentTypeField } from 'features/component-type/components/ComponentTypeField';

interface BatiprixEditConfigComponentTypeStepProps {
  selectedMaterialComponentTypeId: number | undefined;
  selectedWorkforceComponentTypeId: number | undefined;
}
export const BatiprixEditConfigComponentTypeStep: FC<BatiprixEditConfigComponentTypeStepProps> = ({
  selectedMaterialComponentTypeId,
  selectedWorkforceComponentTypeId,
}) => {
  const { t } = useTranslation(['global']);
  const { form, initFormValues } = useStepForm<BatiprixEditConfigWizard, 'componentType'>();

  useEffect(() => {
    initFormValues({
      materialComponentTypeId: selectedMaterialComponentTypeId,
      workforceComponentTypeId: selectedWorkforceComponentTypeId,
    });
  }, [initFormValues, selectedMaterialComponentTypeId, selectedWorkforceComponentTypeId]);

  return (
    <Form form={form}>
      <Text>
        <Trans t={t} i18nKey="global:batiprixEditConfigModal.componentTypeStep.description">
          <strong>displayed_data</strong>
        </Trans>
      </Text>

      <Box mt={5}>
        <Flex justify="space-between">
          <Box flex="1" mr={5}>
            <ComponentTypeField<BatiprixComponentTypeForm>
              label={t('global:batiprixEditConfigModal.componentTypeStep.disbursementType')}
              name="materialComponentTypeId"
              isRequired
              material
            >
              <Rule.IsRequired />
            </ComponentTypeField>
          </Box>

          <Box flex="1">
            <ComponentTypeField<BatiprixComponentTypeForm>
              label={t('global:batiprixEditConfigModal.componentTypeStep.workforceDisbursementType')}
              name="workforceComponentTypeId"
              workforce
              isRequired
            >
              <Rule.IsRequired />
            </ComponentTypeField>
          </Box>
        </Flex>
      </Box>

      <StepperModal.CloseButton />
      <StepperModal.PrimaryButton>{t('global:words.c.confirm')}</StepperModal.PrimaryButton>
    </Form>
  );
};
