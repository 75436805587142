import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { ActionMenu, DownloadPdfIcon, DownloadSpreadsheetIcon, FileIcon, useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IAccountingExport } from '@graneet/business-logic';
import { PERMISSION, ACCOUNTING_EXPORT_FORMAT } from '@graneet/business-logic';
import { useDisclosure } from '@chakra-ui/react';
import { compact } from 'lodash-es';

import { useAccountingExportGenerate } from '../../services/accounting-export.api';

import { AccountingTableDownloadModal } from './AccountingTableDownloadModal';
import { AccountingTableCancelModal } from './AccountingTableCancelModal';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { subscribeToSse } from 'features/sse/services/sse.util';
import { downloadFile } from 'features/file/services/file.util';

interface AccountingTableRowProps {
  accountingExport: IAccountingExport;

  onCanceled(): void;
}

export const AccountingTableMenu: FC<AccountingTableRowProps> = ({ accountingExport, onCanceled }) => {
  const { t } = useTranslation(['accounting', 'global']);

  const canCancelAccountingExport = usePermissions([PERMISSION.GENERATE_ACCOUNTING]);

  const { mutateAsync: accountingExportGenerateMutationAsync } = useAccountingExportGenerate();

  const downloadModal = useDisclosure();
  const cancelModal = useDisclosure();

  const toast = useToast();

  const [sseStatus, setSseStatus] = useState<{
    isError: boolean;
    isGenerating: boolean;
  }>({
    isError: false,
    isGenerating: true,
  });

  const onExport = useCallback(
    async (options: { format: ACCOUNTING_EXPORT_FORMAT | undefined; containsAssociatedPdfs: boolean }) => {
      const accountingExportResponseDTO = await accountingExportGenerateMutationAsync({
        entity: undefined,
        fromDate: undefined,
        selectedItems: undefined,
        toDate: undefined,
        accountingExportsIds: [accountingExport.id],
        formats: compact([options.format]),
        containsAssociatedPdfs: options.containsAssociatedPdfs,
        search: undefined,
        filters: undefined,
      });

      if ('uuid' in accountingExportResponseDTO) {
        downloadModal.onOpen();
        subscribeToSse<string>(accountingExportResponseDTO.uuid, {
          onMessage(data) {
            downloadFile(data);
            setSseStatus({
              isError: false,
              isGenerating: false,
            });
          },
          onError() {
            toast.error(t('global:errors.error'));
            setSseStatus({
              isError: true,
              isGenerating: false,
            });
          },
        });
      } else {
        downloadFile(accountingExportResponseDTO.url);
      }
    },
    [accountingExport.id, accountingExportGenerateMutationAsync, downloadModal, t, toast],
  );

  return (
    <>
      <ActionMenu>
        <ActionMenu.Action
          label={t('accounting:cta.downloadFec')}
          icon={<FileIcon color="greenBrand.light" />}
          onClick={() => onExport({ format: ACCOUNTING_EXPORT_FORMAT.FEC, containsAssociatedPdfs: false })}
        />
        <ActionMenu.Action
          label={t('accounting:cta.downloadExcel')}
          icon={<DownloadSpreadsheetIcon />}
          onClick={() => onExport({ format: ACCOUNTING_EXPORT_FORMAT.XLSX, containsAssociatedPdfs: false })}
        />
        <ActionMenu.Action
          label={t('accounting:cta.downloadPdf')}
          icon={<DownloadPdfIcon color="red.500" />}
          onClick={() => onExport({ format: undefined, containsAssociatedPdfs: true })}
          isDisabled={!accountingExport.isActive}
        />
        {canCancelAccountingExport && accountingExport.isActive && (
          <ActionMenu.Delete label={t('accounting:cta.cancelAccounting')} onClick={cancelModal.onOpen} />
        )}
      </ActionMenu>

      <AccountingTableCancelModal
        onClose={cancelModal.onClose}
        isOpen={cancelModal.isOpen}
        accountingExport={accountingExport}
        onCanceled={onCanceled}
      />
      <AccountingTableDownloadModal
        onClose={downloadModal.onClose}
        isOpen={downloadModal.isOpen}
        sseStatus={sseStatus}
      />
    </>
  );
};
