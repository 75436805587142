import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import type { WrapProps } from '@chakra-ui/react';
import { Wrap } from '@chakra-ui/react';

import { BadgeGroupContext } from './BadgeGroupContext';
import { BadgeGroupOption } from './BadgeGroupOption';

export interface BadgeGroupProps<T extends unknown>
  extends PropsWithChildren,
    Omit<WrapProps, 'onBlur' | 'onChange' | 'onFocus'> {
  onBlur(): void;
  onChange(newValue: T): void;
  onFocus(): void;

  /**
   * List of selected values
   */
  value: T[] | undefined | null;
}

const BadgeGroupComponent = <T extends unknown>({
  children,
  onBlur,
  onChange,
  onFocus,
  value,
  ...otherProps
}: BadgeGroupProps<T>) => {
  const badgeGroupCtx = useMemo(
    () => ({
      onBlur,
      onChange,
      onFocus,
      fieldValues: value,
    }),
    [onBlur, onChange, onFocus, value],
  );

  return (
    <BadgeGroupContext.Provider value={badgeGroupCtx}>
      <Wrap {...otherProps}>{children}</Wrap>
    </BadgeGroupContext.Provider>
  );
};

export const BadgeGroup = Object.assign(BadgeGroupComponent, {
  Option: BadgeGroupOption,
});
