import type { FC } from 'react';
import type { ListProps } from '@chakra-ui/react';
import { List as ChakraList } from '@chakra-ui/react';

import { Item } from './Item';

const ListComponent: FC<ListProps> = ({ children, ...otherProps }) => (
  <ChakraList {...otherProps}>{children}</ChakraList>
);

export const List = Object.assign(ListComponent, {
  Item,
});
