import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { compact } from 'lodash-es';

import type { InvoiceActionsParams } from './invoice.actions.type';
import { getDownloadPDFAction, getOpenMailingAction, getPreviewPDFAction } from './invoice.actions.util';

export function getInvoiceCancelledActions(params: InvoiceActionsParams): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  const previewAction = getPreviewPDFAction(params);

  if (previewAction) {
    actions.push({
      type: 'secondary',
      ...previewAction,
    });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([getOpenMailingAction(params), getDownloadPDFAction(params)]),
  });

  return actions;
}
