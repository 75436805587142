import { useTranslation } from 'react-i18next';
import { HStack } from '@chakra-ui/react';
import { Button } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback } from 'react';
import type { IProject } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { useHistory } from 'react-router-dom';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';

export type SupplierInvoicesActionsButtonsProps = {
  project?: IProject;
};

export const SupplierInvoicesCreateButton: FC<SupplierInvoicesActionsButtonsProps> = ({ project }) => {
  const history = useHistory();

  const { t } = useTranslation(['supplierInvoices']);

  const getCreateButtonProps = useDisabledButtonProps([PERMISSION.CREATE_SUPPLIER_INVOICE]);

  const onCreate = useCallback(() => {
    if (project) {
      history.push(`/projects/${project.id}/purchases/supplier-invoices/create`);
    } else {
      history.push('/purchases/supplier-invoices/create');
    }
  }, [history, project]);

  return (
    <HStack spacing={6}>
      <Button onClick={onCreate} colorScheme="blue" {...getCreateButtonProps()}>
        {t('supplierInvoices:actions.create')}
      </Button>
    </HStack>
  );
};
