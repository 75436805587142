import { Heading, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const DisplayNotFoundScreen: FC = () => {
  const { t } = useTranslation(['global']);

  return (
    <Flex alignItems="center" justifyContent="center" direction="column">
      <Heading textAlign="center" color="gray.700">
        {t('global:pageNotFound')}
      </Heading>
    </Flex>
  );
};
