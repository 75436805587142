import { ConfirmModal, Wizard, formatVatRateToNumber, useCurrency } from '@graneet/lib-ui';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FEATURE_FLAGS, isSubProjectBillableAsProgressStatement } from '@graneet/business-logic';
import { Text, useDisclosure } from '@chakra-ui/react';

import { EditSubProjectParametersStep } from './parameters/_steps/edit-params/EditSubProjectParametersStep';

import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import type {
  ProjectEditParametersForm,
  ProjectEditParametersWizard,
} from 'features/project/forms/project-edit-parameters-wizard';
import { getCustomHoldbackFormValues } from 'features/project/forms/project-edit-parameters-wizard';
import {
  useSubProject,
  useSubProjectHasBillingStarted,
  useUpdateSubProjectBillingParams,
} from 'features/sub-project/services/sub-project.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const EditSubProjectParametersScreen = () => {
  useHideNavbar();

  const { t } = useTranslation(['global', 'project']);
  const labels = useWizardLabels({ quit: t('global:words.c.previous'), save: t('global:words.c.validate') });

  const { subProjectId } = useParams<{ subProjectId: string }>();
  const subProject = useSubProject(subProjectId);
  const getHasBillingStarted = useSubProjectHasBillingStarted(subProjectId);
  const updateSubProjectBillingParamsMutation = useUpdateSubProjectBillingParams();

  const { mapAmountToNumber } = useCurrency();
  const confirmScopeModal = useDisclosure();

  const projectId = subProject.data.project!.id;
  const history = useHistory();
  const { state = {} } = useLocation<{ nextUrl?: string; referrerUrl?: string }>();
  const formValues = useRef<ProjectEditParametersForm | undefined>();

  const hasProjectGlobalParametersActive = useFeatureFlag(FEATURE_FLAGS.PROJECT_GLOBAL_PARAMETERS);
  const areProjectGlobalParametersSet = isSubProjectBillableAsProgressStatement(subProject.data, {
    ignoreReversalOfLiability: true,
  });

  // Go back to where we come from or to project information screen
  const goToNextURL = useCallback(() => {
    const { nextUrl } = state;
    if (nextUrl) {
      history.replace(nextUrl); // Go here if nextUrl is specified
    } else {
      history.replace(`/projects/${projectId}/information`); // Go to project infos otherwise
    }
  }, [state, history, projectId]);

  const goBack = useCallback(() => {
    const { referrerUrl } = state;
    if (referrerUrl) {
      history.replace(referrerUrl); // Go back to referrer if specified
    } else {
      history.goBack(); // Use navigator back else
    }
  }, [history, state]);

  /**
   * Go back to the project if it already has billing params set
   */
  useEffect(() => {
    if (areProjectGlobalParametersSet && !hasProjectGlobalParametersActive) {
      history.replace(`/projects/${projectId}/information`);
    }
  }, [areProjectGlobalParametersSet, hasProjectGlobalParametersActive, history, projectId, subProject]);

  const projectName = subProject.data.project!.name;

  const onSubProjectEditParameterWizardFinish = useCallback(async () => {
    if (!formValues.current) {
      return;
    }

    const formData = formValues.current;
    const billingParams: ProjectEditParametersForm = {
      ...formData,
      deductionName: !isNil(formData.deductionVatRate)
        ? t('project:parameters.fields.deduction.defaultValue')
        : undefined,
      deductionVatRate: !isNil(formData.deductionVatRate)
        ? formatVatRateToNumber(formData.deductionVatRate)
        : undefined,
      holdbackPaymentGuaranteeAmount: !isNil(formData.holdbackPaymentGuaranteeAmount)
        ? mapAmountToNumber(formData.holdbackPaymentGuaranteeAmount)
        : undefined,
    };

    if (billingParams.hasPriceRevision === undefined) billingParams.hasPriceRevision = false;

    const customHoldbacks = getCustomHoldbackFormValues(billingParams);

    await updateSubProjectBillingParamsMutation.mutateAsync(
      {
        id: subProjectId,
        dto: {
          ...billingParams,
          customHoldbacks: Object.values(customHoldbacks),
        },
      },
      {
        onSuccess: () => {
          goToNextURL();
        },
      },
    );
  }, [t, mapAmountToNumber, updateSubProjectBillingParamsMutation, subProjectId, goToNextURL]);

  /**
   * Save project billing params once the wizard is completed
   */
  const onFinish = useCallback(
    async (formData: ProjectEditParametersWizard) => {
      if (!subProjectId) {
        return;
      }
      formValues.current = formData.editParams;
      if (hasProjectGlobalParametersActive && areProjectGlobalParametersSet) {
        confirmScopeModal.onOpen();
        return;
      }

      onSubProjectEditParameterWizardFinish();
    },
    [
      subProjectId,
      hasProjectGlobalParametersActive,
      areProjectGlobalParametersSet,
      onSubProjectEditParameterWizardFinish,
      confirmScopeModal,
    ],
  );

  return (
    <Wizard<ProjectEditParametersWizard>
      headerTitle={
        areProjectGlobalParametersSet
          ? t('project:parameters.wizard.editTitle', { projectName })
          : t('project:parameters.wizard.createTitle', { projectName })
      }
      onQuit={goBack}
      onFinish={onFinish}
      labels={labels}
    >
      <Wizard.Step<ProjectEditParametersWizard> name="editParams">
        <EditSubProjectParametersStep
          subProject={subProject.data}
          hasBillingStarted={getHasBillingStarted.data.hasBillingStarted}
        />
      </Wizard.Step>

      <ConfirmModal
        title={t(`project:parameters.modal.title`)}
        modal={confirmScopeModal}
        onConfirmed={onSubProjectEditParameterWizardFinish}
        labels={{ ok: t('global:words.c.validate'), ko: t('global:words.c.back') }}
      >
        <Text>
          {getHasBillingStarted.data.hasBillingStarted
            ? t(`project:parameters.modal.descriptionAfterBilling`)
            : t(`project:parameters.modal.descriptionBeforeBilling`)}
        </Text>
      </ConfirmModal>
    </Wizard>
  );
};
