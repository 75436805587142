import type { FC } from 'react';
import { useEffect } from 'react';

import type { ButtonProps } from '../Button/Button';

import { BUTTON_TYPE } from './useModalButtons';
import { useModalButtonsContext } from './ModalButtonsContext';

export const ModalPrimaryButton: FC<ButtonProps> = (props) => {
  const { registerButton, unRegisterButton } = useModalButtonsContext();

  useEffect(() => {
    registerButton(BUTTON_TYPE.PRIMARY, props);
    return () => unRegisterButton(BUTTON_TYPE.PRIMARY, props);
  }, [registerButton, unRegisterButton, props]);

  return null;
};
