import { useDisclosure } from '@chakra-ui/react';
import type {
  AccountingAccountsNeeds,
  BILL_STATUSES,
  ExportData,
  IExportDTO,
  ISupplierInvoiceListingResponseDTO,
  ORDER_STATUS,
  PROJECT_STATUSES,
  QUOTE_STATUS,
  QuoteStatus,
  STATEMENT_TYPES,
  SUPPLIER_INVOICE_STATUS,
} from '@graneet/business-logic';
import {
  ACCOUNTING_EXPORT_FORMAT,
  ACCOUNTING_JOURNAL,
  EXPORT_ENTITY,
  FILTERING_PARAMS,
  PERMISSION,
} from '@graneet/business-logic';
import type { RenderActionsProps } from '@graneet/lib-ui';
import { GraneetButton, Modal, useToast } from '@graneet/lib-ui';
import qs from 'qs';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash-es';

import { useUpdateAccountingConfig } from '../../../accounting/services/accounting-config.api';
import {
  useAccountingExportCheckNeeds,
  useAccountingExportCountInvoicesPerJournal,
  useAccountingExportCreate,
} from '../../../accounting/services/accounting-export.api';
import { canAccountingExportBeCreated } from '../../../accounting/services/accounting-export.util';
import { useExportGenerate } from '../../services/export.api';

import { ExportModalSelectionStep } from './SelectionStep/ExportModalSelectionStep';
import { ExportModalGenerationStep } from './GenerationStep/ExportModalGenerationStep';
import { ExportModalMissingCodeStep } from './MissingCodeStep/ExportModalMissingCodeStep';
import { ExportModalNoPermissionStep } from './NoPermissionStep/ExportModalNoPermissionStep';

import { populateDTOFromAccountingConfigForm } from 'features/accounting/forms/accounting-form.util';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { subscribeToSse } from 'features/sse/services/sse.util';
import { downloadFile } from 'features/file/services/file.util';
import type { AccountingMissingCodeFormValues } from 'features/accounting/components/AccountingMissingCodeForm';

export interface SelectedItems {
  [EXPORT_ENTITY.PROJECT]: { id: number; status: PROJECT_STATUSES };
  // @[ff: project-financial-summary]
  [EXPORT_ENTITY.PROJECT_OLD]: { id: number; status: PROJECT_STATUSES };
  [EXPORT_ENTITY.ORDER]: { id: number; status: ORDER_STATUS };
  [EXPORT_ENTITY.QUOTE]: { id: number | string; status: QUOTE_STATUS | QuoteStatus };
  [EXPORT_ENTITY.SUPPLIER_INVOICE]: ISupplierInvoiceListingResponseDTO;
  [EXPORT_ENTITY.SUPPLIER_INVOICE_PAYMENT]: { id: number; status: SUPPLIER_INVOICE_STATUS };
  [EXPORT_ENTITY.STATEMENT]: { id: number; type: STATEMENT_TYPES };
  [EXPORT_ENTITY.BILL]: { id: number; status: BILL_STATUSES; isPaymentLate: boolean };
}

export interface ExportButtonProps<Entity extends keyof typeof EXPORT_ENTITY>
  extends RenderActionsProps<SelectedItems[Entity]> {
  entity: Entity;
}

export const ExportButton = <Entity extends keyof SelectedItems>({
  entity,
  resetSelectedCheckboxes,
  selectedItems,
  hasAllCheckboxesSelected,
  currentFilters,
}: ExportButtonProps<Entity>) => {
  const { t } = useTranslation(['global', 'supplierInvoices']);
  const toast = useToast();

  const updateAccountingConfigMutation = useUpdateAccountingConfig();
  const { mutateAsync: exportGenerateMutationFn } = useExportGenerate();
  const { mutateAsync: accountingExportCreateMutationAsync } = useAccountingExportCreate();
  const { mutateAsync: accountingExportCheckNeedsMutationAsync } = useAccountingExportCheckNeeds();

  const hasAccessAccountingPermission = usePermissions([PERMISSION.DISPLAY_ACCOUNTING]);
  const canGenerateAccountingExport = usePermissions([PERMISSION.GENERATE_ACCOUNTING]);
  const hasUpdateAccountingPermission = usePermissions([PERMISSION.UPDATE_ACCOUNTING]);

  const [currentScreen, setCurrentScreen] = useState<'selection' | 'generation' | 'missingCode' | 'noPermission'>(
    'selection',
  );

  const [sseStatus, setSseStatus] = useState<{
    isError: boolean;
    isGenerating: boolean;
    message?: string;
  }>(() => ({
    isError: false,
    isGenerating: true,
  }));

  const modal = useDisclosure({
    onClose: () => {
      setCurrentScreen('selection');
      setSseStatus({
        isError: false,
        isGenerating: true,
      });
    },
  });

  const configurationRef = useRef<
    | {
        exportPDF: boolean;
        exportXLS: boolean;
        exportAccounting: boolean;
        exportAccountingFEC: boolean;
        exportAccountingXLSX: boolean;
      }
    | undefined
  >(undefined);

  const accountingAccountsNeedsRef = useRef<AccountingAccountsNeeds | undefined>(undefined);

  const getAccountingExportFilters = useCallback(() => {
    const getDTOItem = (): IExportDTO<Entity>['selectedItems'] => {
      switch (entity) {
        case EXPORT_ENTITY.QUOTE:
          return selectedItems.map(
            (d) => ({ id: d.id }) satisfies ExportData['QUOTE'],
          ) as IExportDTO<Entity>['selectedItems'];
        case EXPORT_ENTITY.SUPPLIER_INVOICE_PAYMENT:
        case EXPORT_ENTITY.SUPPLIER_INVOICE:
          return selectedItems.map(
            (d) => ({ id: d.id as number }) satisfies ExportData['SUPPLIER_INVOICE'],
          ) as IExportDTO<Entity>['selectedItems'];
        case EXPORT_ENTITY.BILL:
          return selectedItems.map(
            (d) => ({ id: d.id as number }) satisfies ExportData['BILL'],
          ) as IExportDTO<Entity>['selectedItems'];
        case EXPORT_ENTITY.ORDER:
          return selectedItems.map(
            (d) => ({ id: d.id as number }) satisfies ExportData['ORDER'],
          ) as IExportDTO<Entity>['selectedItems'];
        case EXPORT_ENTITY.PROJECT:
          return selectedItems.map(
            (d) => ({ id: d.id as number }) satisfies ExportData['PROJECT'],
          ) as IExportDTO<Entity>['selectedItems'];
        // @[ff: project-financial-summary]
        case EXPORT_ENTITY.PROJECT_OLD:
          return selectedItems.map(
            (d) => ({ id: d.id as number }) satisfies ExportData['PROJECT_OLD'],
          ) as IExportDTO<Entity>['selectedItems'];
        case EXPORT_ENTITY.STATEMENT:
          return selectedItems.map(
            (d) =>
              ({ id: d.id as number, type: (d as SelectedItems['STATEMENT']).type }) satisfies ExportData['STATEMENT'],
          ) as IExportDTO<Entity>['selectedItems'];

        default:
          throw new Error('Entity not implemented');
      }
    };

    const filters = qs.parse(currentFilters.toString());

    return {
      fromDate: undefined,
      toDate: undefined,
      selectedItems: hasAllCheckboxesSelected ? undefined : getDTOItem(),
      search: currentFilters.get(FILTERING_PARAMS.SEARCH) || undefined,
      filters: Object.keys(filters).length > 0 ? filters : undefined,
      entity,
    };
  }, [currentFilters, entity, hasAllCheckboxesSelected, selectedItems]);

  const availableSelection = useMemo(() => {
    switch (entity) {
      case EXPORT_ENTITY.SUPPLIER_INVOICE:
      case EXPORT_ENTITY.SUPPLIER_INVOICE_PAYMENT:
      case EXPORT_ENTITY.BILL:
      case EXPORT_ENTITY.STATEMENT:
        return {
          xls: true,
          pdf: true,
          accounting: true,
        };
      case EXPORT_ENTITY.PROJECT:
        return {
          xls: true,
          pdf: false,
          accounting: false,
        };
      // @[ff: project-financial-summary]
      case EXPORT_ENTITY.PROJECT_OLD:
        return {
          xls: true,
          pdf: false,
          accounting: false,
        };
      case EXPORT_ENTITY.ORDER:
        return {
          xls: true,
          pdf: true,
          accounting: false,
        };
      case EXPORT_ENTITY.QUOTE:
        return {
          xls: true,
          pdf: true,
          accounting: false,
        };
      default:
        return {
          xls: true,
          pdf: true,
          accounting: true,
        };
    }
  }, [entity]);

  const countData = useAccountingExportCountInvoicesPerJournal(
    !modal.isOpen || !canGenerateAccountingExport || !hasAccessAccountingPermission || !availableSelection.accounting
      ? undefined
      : getAccountingExportFilters(),
  );

  const onCloseClickedAfterGeneration = useCallback(() => {
    if (!sseStatus.isError) {
      resetSelectedCheckboxes();
    }
    modal.onClose();
  }, [modal, resetSelectedCheckboxes, sseStatus.isError]);

  const onGenerateClicked = useCallback(
    async (configuration: {
      exportPDF: boolean;
      exportXLS: boolean;
      exportAccounting: boolean;
      exportAccountingFEC: boolean;
      exportAccountingXLSX: boolean;
    }) => {
      setSseStatus({
        isError: false,
        isGenerating: true,
      });
      setCurrentScreen('generation');

      // 1. If a user asks to join accounting export, we must check if all codes needed by the export are parametrized
      let associatedAccountingExportIds: undefined | string[];
      if (configuration.exportAccounting) {
        const journals = compact([
          countData.data?.journalSales.exportable !== 0 ? ACCOUNTING_JOURNAL.SALES : undefined,
          countData.data?.journalPurchases.exportable !== 0 ? ACCOUNTING_JOURNAL.PURCHASES : undefined,
          countData.data?.journalBank.exportable !== 0 ? ACCOUNTING_JOURNAL.BANK : undefined,
        ]);
        const accountingAccountsNeeds = await accountingExportCheckNeedsMutationAsync({
          ...getAccountingExportFilters(),
          journals,
        });

        const canCreateExports = canAccountingExportBeCreated(accountingAccountsNeeds);

        // Use have missing code and have the permission to update them, change screen to complete them
        if (!canCreateExports && hasUpdateAccountingPermission) {
          configurationRef.current = configuration;
          accountingAccountsNeedsRef.current = accountingAccountsNeeds;
          setSseStatus({
            isError: false,
            isGenerating: false,
          });
          setCurrentScreen('missingCode');
          return;
        }

        // Use have missing code and do not have the permission to update them, change screen to no permission
        if (!canCreateExports && !hasUpdateAccountingPermission) {
          setSseStatus({
            isError: false,
            isGenerating: false,
          });
          setCurrentScreen('noPermission');
          return;
        }

        // Generate exports
        try {
          associatedAccountingExportIds = (
            await Promise.all(
              journals.map(async (journal) =>
                accountingExportCreateMutationAsync({
                  ...getAccountingExportFilters(),
                  journal,
                }),
              ),
            )
          ).map((accountingExport) => accountingExport.id);
        } catch {
          toast.error(t('global:errors.error'));
          setSseStatus({
            isError: true,
            isGenerating: false,
          });
          return;
        }
      }

      // 2. Here, the user can generate an export (missing code has been checked), build and call generate export api endpoint
      let accountingFormats: ACCOUNTING_EXPORT_FORMAT[] | undefined;
      if (configuration.exportAccounting) {
        const newAccountingFormats = compact([
          configuration.exportAccountingXLSX ? ACCOUNTING_EXPORT_FORMAT.XLSX : undefined,
          configuration.exportAccountingFEC ? ACCOUNTING_EXPORT_FORMAT.FEC : undefined,
        ]);
        accountingFormats = newAccountingFormats.length > 0 ? newAccountingFormats : undefined;
      }

      const exportData: IExportDTO<Entity> = {
        hasAllSelected: hasAllCheckboxesSelected,
        withPDF: configuration.exportPDF,
        withXLS: configuration.exportXLS,
        associatedAccountingExportIds,
        accountingFormats,
        ...getAccountingExportFilters(),
      };
      const res = await exportGenerateMutationFn(exportData, {
        onError: () => {
          setSseStatus({
            isError: true,
            isGenerating: false,
          });
        },
      });

      if (res.success) {
        if ('uuid' in res && res.uuid) {
          subscribeToSse<string>(res.uuid, {
            onMessage(data) {
              downloadFile(data);
              setSseStatus({
                isError: false,
                isGenerating: false,
              });
            },
            onError() {
              setSseStatus({
                isError: true,
                isGenerating: false,
              });
            },
          });
        }
        if ('url' in res && res.url) {
          downloadFile(res.url);
          setSseStatus({
            isError: false,
            isGenerating: false,
          });
        }
      } else {
        setSseStatus({
          isError: true,
          isGenerating: false,
          message: t('global:generateExportModal.errorNoFile'),
        });
      }
    },
    [
      accountingExportCheckNeedsMutationAsync,
      accountingExportCreateMutationAsync,
      countData.data?.journalBank.exportable,
      countData.data?.journalPurchases.exportable,
      countData.data?.journalSales.exportable,
      exportGenerateMutationFn,
      getAccountingExportFilters,
      hasAllCheckboxesSelected,
      hasUpdateAccountingPermission,
      t,
      toast,
    ],
  );

  const onMissingCodeSubmit = useCallback(
    async (formValues: AccountingMissingCodeFormValues) => {
      await updateAccountingConfigMutation.mutateAsync(populateDTOFromAccountingConfigForm(formValues));

      await onGenerateClicked(configurationRef.current!);
    },
    [onGenerateClicked, updateAccountingConfigMutation],
  );

  const onCloseModal = useCallback(() => {
    if (currentScreen === 'selection') {
      modal.onClose();
      return;
    }

    // Do not allow modal closing when export is generating
    if (sseStatus.isGenerating) {
      return;
    }

    onCloseClickedAfterGeneration();
  }, [currentScreen, modal, onCloseClickedAfterGeneration, sseStatus.isGenerating]);

  const modalTitle =
    currentScreen === 'selection'
      ? t('global:generateExportModal.titleSelection')
      : t('global:generateExportModal.titleExport');

  return (
    <>
      <GraneetButton size="sm" onClick={modal.onOpen}>
        {t('global:generateExportModal.actions.export')}
      </GraneetButton>

      <Modal onClose={onCloseModal} isOpen={modal.isOpen} title={modalTitle} size="5xl">
        {currentScreen === 'selection' && (
          <ExportModalSelectionStep
            countData={countData}
            onGenerate={onGenerateClicked}
            allowPDFExport={availableSelection.pdf}
            allowXLSExport={availableSelection.xls}
            allowAccountingExport={availableSelection.accounting}
          />
        )}

        {currentScreen === 'generation' && (
          <ExportModalGenerationStep sseStatus={sseStatus} onClose={onCloseClickedAfterGeneration} />
        )}

        {currentScreen === 'missingCode' && (
          <ExportModalMissingCodeStep
            accountingAccountsNeeds={accountingAccountsNeedsRef.current!}
            onClose={onCloseModal}
            onSubmit={onMissingCodeSubmit}
          />
        )}

        {currentScreen === 'noPermission' && <ExportModalNoPermissionStep onClose={onCloseModal} />}
      </Modal>
    </>
  );
};
