import {
  Card,
  Badge,
  Modal,
  DocumentStatusCard,
  DocumentStatusCardActions,
  ConfirmDeletionModal,
  formatDateOrEmpty,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Text, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import type {
  DownPaymentVersion,
  IContactWithProjectInfos,
  IDownPaymentResponse,
  IGroupedContact,
  IProgressStatement,
  IProjectWithRelations,
} from '@graneet/business-logic';
import {
  getEmailVariableData,
  isDownPaymentMatchingInvoiceNumberRequirement,
  isDownPaymentCompleted,
  PERMISSION,
  STATEMENT_TYPES,
} from '@graneet/business-logic';
import dayjs from 'dayjs';

import { DOWN_PAYMENT_STATUS_COLORS } from '../../../constants/down-payment.constant';
import {
  useChangeDownPaymentStatusToCompleted,
  useChangeDownPaymentStatusToDraft,
  useDeleteDownPayment,
} from '../../../services/down-payment.api';
import { ForceDraftDownPaymentModal } from '../ForceDraftDownPaymentModal';

import { getDownPaymentActions, getDownPaymentStatusCardIcon } from './down-payment.actions';

import { getProjectDefaultEmailRecipient } from 'features/contact/services/contact.util';
import type { MailingModalProps } from 'features/mailing/components/MailingModal/MailingModal';
import { sendMail } from 'features/mailing/services/mailing.api';
import { MailingModal } from 'features/mailing/components/MailingModal/MailingModal';
import { StatementEditInvoiceNumberModal } from 'features/statement-ledger/components/StatementEditInvoiceNumberModal';
import { CreditConfirmModal } from 'features/credit/components/modals/CreditConfirmModal';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useHasLedger } from 'features/statement-ledger/hooks/useHasLedger';
import { getDownloadUrl, getPreviewUrl } from 'features/pdf/services/pdf.api';
import { formatFileName } from 'features/file/services/file.util';
import { UpdateStatementBillingDateModal } from 'features/statement/components/UpdateStatementBillingDateModal';

interface DownPaymentStatusCardProps {
  downPayment: IDownPaymentResponse;
  currentVersion: DownPaymentVersion | undefined;
  project: IProjectWithRelations;
  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
  onDeleted(): void;
  onCancelConfirmed: (createDraft: boolean) => void;
  progressStatements?: IProgressStatement[];
}

export const DownPaymentStatusCard: FC<DownPaymentStatusCardProps> = ({
  downPayment,
  currentVersion,
  project,
  projectContacts,
  onCancelConfirmed,
  onDeleted,
  progressStatements = [],
}) => {
  const { t } = useTranslation(['downPayment', 'global', 'credit', 'mailing', 'statement', 'mailing']);
  const history = useHistory();
  const hasLedger = useHasLedger();

  const hasCreatePermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);
  const hasUpdatePermission = usePermissions([PERMISSION.UPDATE_STATUS_STATEMENTS]);
  const hasForceDraftPermission = usePermissions([PERMISSION.FORCE_DRAFT_STATEMENTS]);

  const draftingModal = useDisclosure();
  const forceDraftModal = useDisclosure();
  const createCreditModal = useDisclosure();
  const invoiceNumberModal = useDisclosure();
  const mailingModal = useDisclosure();
  const deletionModal = useDisclosure();
  const updateStatementBillingDateModal = useDisclosure();

  const deleteDownPaymentMutation = useDeleteDownPayment();
  const changeDownPaymentStatusToCompletedMutation = useChangeDownPaymentStatusToCompleted();
  const changeDownPaymentStatusToDraftMutation = useChangeDownPaymentStatusToDraft();

  const handleDelete = useCallback(async () => {
    await deleteDownPaymentMutation.mutateAsync(downPayment.id, {
      onSuccess: () => {
        onDeleted();
      },
    });
  }, [deleteDownPaymentMutation, downPayment.id, onDeleted]);

  const completeDownPayment = useCallback(
    async (billingDate?: string) => {
      await changeDownPaymentStatusToCompletedMutation.mutateAsync({
        id: downPayment.id,
        dto: { billingDate },
      });
    },
    [changeDownPaymentStatusToCompletedMutation, downPayment.id],
  );

  const onCompleteDownPaymentClicked = useCallback(async () => {
    const needsInvoiceNumberToBeCompleted = !hasLedger && !isDownPaymentMatchingInvoiceNumberRequirement(downPayment);
    if (needsInvoiceNumberToBeCompleted) {
      invoiceNumberModal.onOpen();
      return;
    }

    if (hasLedger && !downPayment.invoiceNumber && dayjs(downPayment.billingDate).isBefore(dayjs(new Date()), 'day')) {
      updateStatementBillingDateModal.onOpen();
      return;
    }

    await completeDownPayment();
  }, [hasLedger, downPayment, completeDownPayment, invoiceNumberModal, updateStatementBillingDateModal]);

  const goToEdition = useCallback(
    () =>
      history.push(`/projects/${project.id}/statements/sub-projects/${downPayment.subProject!.id}/down-payments/edit`),
    [downPayment.subProject, history, project.id],
  );

  const onUpdateDownPaymentButtonClicked = useCallback(() => {
    if (isDownPaymentCompleted(downPayment)) {
      draftingModal.onOpen();
    } else {
      goToEdition();
    }
  }, [downPayment, draftingModal, goToEdition]);

  const onDraftButtonClicked = useCallback(async () => {
    changeDownPaymentStatusToDraftMutation.mutate(downPayment.id, {
      onSuccess: () => {
        goToEdition();
      },
    });
  }, [changeDownPaymentStatusToDraftMutation, downPayment.id, goToEdition]);

  const updateDownPaymentInvoiceNumber = useCallback(
    (invoiceNumber: string) =>
      changeDownPaymentStatusToCompletedMutation.mutateAsync({
        id: downPayment.id,
        dto: {
          invoiceNumber,
        },
      }),
    [changeDownPaymentStatusToCompletedMutation, downPayment.id],
  );

  /**
   * PDF
   */
  const onViewPdf = useCallback(() => {
    if (currentVersion?.pdf) {
      window.open(getPreviewUrl(currentVersion.pdf.apiId));
    }
  }, [currentVersion]);

  const onDownloadPdf = useCallback(() => {
    if (currentVersion?.pdf) {
      window.open(
        getDownloadUrl(
          currentVersion.pdf.apiId,
          formatFileName(currentVersion.name, currentVersion.billingDate, currentVersion.invoiceNumber),
        ),
      );
    }
  }, [currentVersion]);

  const onMailSubmit = useCallback<MailingModalProps['onSubmit']>(
    (data) => sendMail(data.dto, data.attachments, data.mode),
    [],
  );

  return (
    <>
      <Card title={t('downPayment:titles.state')} flex={1}>
        <DocumentStatusCard
          mb={4}
          icon={getDownPaymentStatusCardIcon(downPayment.status)}
          title={downPayment.name}
          subtitle={t('downPayment:fields.creationDate', { date: formatDateOrEmpty(downPayment.createdAt, 'LL') })}
          badgeComponent={
            <Badge colorScheme={DOWN_PAYMENT_STATUS_COLORS[downPayment.status]}>
              {t(`downPayment:statuses.${downPayment.status}`)}
            </Badge>
          }
        />
        <DocumentStatusCardActions
          actions={getDownPaymentActions({
            // Data
            downPayment,
            progressStatements,
            pdf: currentVersion?.pdf ?? undefined,

            // Permissions
            hasCreatePermission,
            hasUpdatePermission,
            hasForceDraftPermission,

            // States
            isLoading:
              changeDownPaymentStatusToDraftMutation.isPending || changeDownPaymentStatusToCompletedMutation.isPending,

            // Handlers
            onEdit: onUpdateDownPaymentButtonClicked,
            onSetStatusToComplete: onCompleteDownPaymentClicked,
            onCancelWithCredit: createCreditModal.onOpen,
            onCancelWithoutCredit: forceDraftModal.onOpen,
            onDownloadPdf,
            onViewPdf,
            onOpenMailing: mailingModal.onOpen,
            onDelete: deletionModal.onOpen,
          })}
        />
      </Card>

      <Modal {...draftingModal}>
        <Text mt={4}>
          {t(`downPayment:draftingModal.content.${downPayment.isInvoiced ? 'invoiced' : 'notInvoiced'}`)}
        </Text>

        <Modal.Close />

        <Modal.PrimaryButton
          onClick={onDraftButtonClicked}
          isLoading={
            changeDownPaymentStatusToDraftMutation.isPending || changeDownPaymentStatusToCompletedMutation.isPending
          }
        >
          {t('downPayment:draftingModal.action')}
        </Modal.PrimaryButton>
      </Modal>

      <ForceDraftDownPaymentModal modal={forceDraftModal} downPayment={downPayment} onConfirmed={goToEdition} />

      <CreditConfirmModal modal={createCreditModal} onConfirmed={onCancelConfirmed} />

      <StatementEditInvoiceNumberModal
        {...invoiceNumberModal}
        statementId={downPayment.id}
        statementType={STATEMENT_TYPES.DOWN_PAYMENT}
        title={t('downPayment:modalInvoiceNumber.title')}
        completeLabel={t('downPayment:modalInvoiceNumber.action')}
        updateInvoiceNumber={updateDownPaymentInvoiceNumber}
      />

      <UpdateStatementBillingDateModal
        {...updateStatementBillingDateModal}
        currentBillingDate={downPayment.billingDate}
        validateStatement={completeDownPayment}
      />

      <UpdateStatementBillingDateModal
        {...updateStatementBillingDateModal}
        currentBillingDate={downPayment.billingDate}
        validateStatement={completeDownPayment}
      />

      <ConfirmDeletionModal
        action={t('statement:delete.action')}
        title={t('statement:delete.title')}
        description={t('statement:delete.confirmationMessage')}
        onDeleted={handleDelete}
        {...deletionModal}
      />

      {downPayment.pdf && (
        <MailingModal
          title={t('mailing:modal.title.downPayment')}
          isOpen={mailingModal.isOpen}
          onClose={mailingModal.onClose}
          onSubmit={onMailSubmit}
          variableData={getEmailVariableData('downPayment', {
            downPayment,
            project,
            client: project.primaryClient,
          })}
          defaultMailContacts={getProjectDefaultEmailRecipient(projectContacts)}
        />
      )}
    </>
  );
};
