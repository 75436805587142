import type { DocumentStatusCardAction, DocumentStatusCardIconValue } from '@graneet/lib-ui';
import { DOWN_PAYMENT_STATUSES } from '@graneet/business-logic';

import { getDownPaymentDraftActions } from './down-payment-draft.actions';
import type { DownPaymentActionsParams } from './down-payment.actions.type';
import { getDownPaymentCompletedActions } from './down-payment-completed.actions';
import { getDownPaymentCancelledActions } from './down-payment-cancelled.actions';

export function getDownPaymentActions(params: DownPaymentActionsParams): DocumentStatusCardAction[] {
  const { downPayment } = params;

  switch (downPayment.status) {
    case DOWN_PAYMENT_STATUSES.DRAFT:
      return getDownPaymentDraftActions(params);
    case DOWN_PAYMENT_STATUSES.COMPLETED:
      return getDownPaymentCompletedActions(params);
    case DOWN_PAYMENT_STATUSES.CANCELLED:
      return getDownPaymentCancelledActions(params);
    default:
      downPayment.status satisfies never;
      throw new Error('Not implemented');
  }
}

export function getDownPaymentStatusCardIcon(status: DOWN_PAYMENT_STATUSES): DocumentStatusCardIconValue {
  switch (status) {
    case DOWN_PAYMENT_STATUSES.DRAFT:
      return 'proforma';
    case DOWN_PAYMENT_STATUSES.CANCELLED:
      return 'cancelled';
    case DOWN_PAYMENT_STATUSES.COMPLETED:
      return 'completed';
    default:
      status satisfies never;
      throw new Error('Unknown status');
  }
}
