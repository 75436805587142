import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MaskEnum, Modal, QuotationMaskedField } from '@graneet/lib-ui';
import { Box, HStack, Text } from '@chakra-ui/react';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import { useQuote } from '../../hooks/useQuote';

import { Rule } from 'features/form/rules/Rule';
import { useStore } from 'store/store';
import { updateQuoteLotDefaultVatRateModal } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useQuoteLotUpdateDefaultVatRate } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateDefaultVatRate';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface QuoteLotDefaultVatRateForm {
  defaultVatRate: string;
}

export const EditQuotationLotDefaultVatRateModal: FC = () => {
  const { t } = useTranslation(['global', 'quote']);
  const quoteLotDefaultVatRateModal = useStore((store) => store.quoteLotDefaultVatRateModal);

  const form = useForm<QuoteLotDefaultVatRateForm>();
  const { isValid: isFormValid } = useFormStatus(form);

  const { quote } = useQuote();
  const quoteTree = quote.getTree();
  const nodeId = quoteLotDefaultVatRateModal.nodeId ?? quoteTree.getRootNodeId();
  const quoteLot = quoteTree.getQuoteLotOrThrow(nodeId);
  const isRootLot = quoteLot.isRootLot();
  const quoteLotUpdateAmount = useQuoteLotUpdateDefaultVatRate();

  const { executeCommand } = useCommands();

  const [isUpdating, setIsUpdating] = useState(false);

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  useEffect(() => {
    form.setFormValues({
      defaultVatRate: quoteLot.getDefaultVatRate().toString(),
    });
  }, [form, quoteLot]);

  const onClose = useCallback(() => {
    form.resetForm();
    updateQuoteLotDefaultVatRateModal({ isOpen: false, nodeId: null });
  }, [form]);

  const handleDefaultVatRateUpdate = useCallback(async () => {
    const { defaultVatRate } = form.getFormValues();

    setIsUpdating(true);
    executeCommand(
      quoteLotUpdateAmount(nodeId, hasStandardVATRates ? String(defaultVatRate!) : defaultVatRate!),
      quote,
    );
    setIsUpdating(false);
    onClose();
  }, [form, executeCommand, quoteLotUpdateAmount, nodeId, hasStandardVATRates, quote, onClose]);

  return (
    <Form form={form}>
      <Modal
        isOpen={quoteLotDefaultVatRateModal.isOpen}
        onClose={onClose}
        title={isRootLot ? t('quote:defaultVatRateModal.quote.title') : t('quote:defaultVatRateModal.lot.title')}
      >
        <Text mb={4}>
          {isRootLot
            ? t('quote:defaultVatRateModal.quote.description')
            : t('quote:defaultVatRateModal.lot.description')}
        </Text>

        <HStack spacing={3}>
          <Text>
            {isRootLot
              ? t('quote:defaultVatRateModal.quote.fieldDescription')
              : t('quote:defaultVatRateModal.lot.fieldDescription')}
          </Text>

          <Box w="7rem">
            {hasStandardVATRates ? (
              <AccountingVATField<QuoteLotDefaultVatRateForm>
                isClearable={false}
                name="defaultVatRate"
                valueScale={100}
              >
                <Rule.IsRequired />
              </AccountingVATField>
            ) : (
              <QuotationMaskedField<QuoteLotDefaultVatRateForm>
                isEditable
                name="defaultVatRate"
                mask={MaskEnum.PERCENT}
                width="7.5rem"
              >
                <Rule.IsRequired />
              </QuotationMaskedField>
            )}
          </Box>
        </HStack>

        <Modal.Close isDisabled={isUpdating} />

        <Modal.PrimaryButton onClick={handleDefaultVatRateUpdate} isLoading={isUpdating} isDisabled={!isFormValid}>
          {t('global:words.c.apply')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
