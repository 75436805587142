import { useEffect, useState } from 'react';

import { useTreeContext } from '../contexts';
import type { Identifiable } from '../types/Identifiable';

export const useLeafComputedValue = <
  Leaf extends Identifiable,
  LeafComputedValue extends Record<keyof LeafComputedValue, unknown>,
>(
  leafId: Leaf['id'],
): LeafComputedValue | undefined => {
  const { listenToLeafComputedValue, getComputedValues } = useTreeContext<any, Leaf, any, LeafComputedValue>();
  const [leafComputedValue, setLeafComputedValue] = useState<LeafComputedValue>(
    () => getComputedValues().leaves[leafId],
  );

  useEffect(() => listenToLeafComputedValue(leafId, setLeafComputedValue), [leafId, listenToLeafComputedValue]);

  return leafComputedValue;
};
