import { INVOICE_STATUSES } from '@graneet/business-logic';
import type { DocumentStatusCardAction, DocumentStatusCardIconValue } from '@graneet/lib-ui';

import type { InvoiceActionsParams } from './invoice.actions.type';
import { getInvoiceDraftActions } from './invoice-draft.actions';
import { getInvoiceCompletedActions } from './invoice-completed.actions';
import { getInvoiceCancelledActions } from './invoice-cancelled.actions';

export function getInvoiceActions(params: InvoiceActionsParams): DocumentStatusCardAction[] {
  const { invoice } = params;

  switch (invoice.status) {
    case INVOICE_STATUSES.DRAFT:
      return getInvoiceDraftActions(params);
    case INVOICE_STATUSES.COMPLETED:
      return getInvoiceCompletedActions(params);
    case INVOICE_STATUSES.CANCELLED:
      return getInvoiceCancelledActions(params);
    default:
      invoice.status satisfies never;
      throw new Error('Unknown status');
  }
}

export function getInvoiceStatusCardIcon(status: INVOICE_STATUSES): DocumentStatusCardIconValue {
  switch (status) {
    case INVOICE_STATUSES.DRAFT:
      return 'proforma';
    case INVOICE_STATUSES.COMPLETED:
      return 'completed';
    case INVOICE_STATUSES.CANCELLED:
      return 'cancelled';
    default:
      status satisfies never;
      throw new Error('Unknown status');
  }
}
