import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { ISupplierInvoice } from '@graneet/business-logic';
import { List, SimpleCheckCircleIcon, SimpleDeleteIcon } from '@graneet/lib-ui';

type SupplierInvoiceCheckListDeletionProps = {
  supplierInvoiceFiles: ISupplierInvoice['supplierInvoiceFiles'];
};

export const SupplierInvoiceCheckListDeletion: FC<SupplierInvoiceCheckListDeletionProps> = ({
  supplierInvoiceFiles,
}) => {
  const { t } = useTranslation(['supplierInvoices']);

  return (
    <>
      <List pl={4}>
        {supplierInvoiceFiles?.length ? (
          <List.Item
            color="gray.600"
            icon={<SimpleDeleteIcon boxSize={4} />}
            label={t('supplierInvoices:cards.delete.deleteFiles')}
          />
        ) : (
          ''
        )}

        <List.Item
          color="gray.600"
          icon={<SimpleCheckCircleIcon boxSize={4} />}
          label={t('supplierInvoices:cards.delete.updateProgressStatementPDF')}
        />
      </List>
    </>
  );
};
