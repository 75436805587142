import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  DateField,
  formatDateFieldValue,
  formatDateToString,
  IntegerField,
  TextField,
  ChapterCollapse,
  Section,
  Wizard,
  IconAdvanced,
  useToast,
  RichTextField,
  SwitchField,
  Callout,
  RICH_TEXT_INLINE_TOOLBAR_PRESET,
  SimpleHelpIcon,
  SimpleDeleteIcon,
  SimpleAddIcon,
  SimpleAlertIcon,
} from '@graneet/lib-ui';
import { Checkbox, HStack, Text, useDisclosure, VStack, Flex, Box } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, useOnChangeValues, useStepForm } from 'graneet-form';
import type { IQuote, IQuoteUpdateDTO, IProject, RequiredByKeys } from '@graneet/business-logic';
import { isProjectAddressAutofilled, isProjectAddressClientAddress, PERMISSION } from '@graneet/business-logic';
import { useParams } from 'react-router-dom';

import { QUOTE_FIELDS } from 'features/quote/constants/quotes.constant';
import { QuoteUpdateSpinner } from 'features/quote/components/spinners/QuoteUpdateSpinner';
import type { QuoteEditInformationForm, QuoteEditWizard } from 'features/quote/forms/quote-edit-wizard';
import { patchQuote, updateQuoteAccountManagers, updateQuoteProject } from 'features/quote/services/quote.api';
import { AddressFields } from 'features/common/components/fields/AddressFields';
import { Rule } from 'features/form/rules/Rule';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { AssociateProjectModal } from 'features/project/components/modals/AssociateProjectModal';
import { ProjectWithPaymentSummaryCard } from 'features/project/components/cards/ProjectWithPaymentSummaryCard';
import { useHasQuoteLedger } from 'features/quote-ledger/hooks/useHasQuoteLedger';
import { UserBadgeGroupField } from 'features/user/components/UserBadgeGroupField';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { QuoteEndValidityDate } from 'features/quote/components/QuoteEndValidityDate';
import type { AddressForm } from 'features/common/components/fields/AddressFields';
import { OptionalLotsTableStatus, useStore } from 'store/store';
import { useProjectOrUndefined } from 'features/project/services/project.api';

const getInfoFormValues = (quote: IQuote) => {
  if (!quote) {
    return {};
  }

  let projectAddress;
  if (quote.projectAddress || quote.project) {
    const address = quote.projectAddress || quote.project?.address;

    projectAddress = {
      projectAddressAddress: address?.address,
      projectAddressPostalCode: address?.postalCode,
      projectAddressCity: address?.city,
      projectAddressCountry: address?.country,
    };
  }

  const { date, estimatedStartDate } = quote;

  const accountManagers = (quote.quoteAccountManagers || []).map((quoteAccountManager) => quoteAccountManager.user.id);

  return {
    ...quote,
    ...projectAddress,
    date: formatDateToString(date),
    estimatedStartDate: formatDateToString(estimatedStartDate) ?? null,
    [QUOTE_FIELDS.ACCOUNT_MANAGERS]: accountManagers,
  };
};

interface EditQuoteInformationStepProps {
  quote: IQuote;
  onProjectAssociationChange(newProjectId: number | string | null): void;
  reloadQuote(): Promise<void>;
}
export const EditQuoteInformationStep: FC<EditQuoteInformationStepProps> = ({
  quote,
  onProjectAssociationChange,
  reloadQuote,
}) => {
  const { t } = useTranslation(['quote', 'global']);
  const toast = useToast();
  const { quoteId } = useParams<{ quoteId: string }>();
  const [currentQuote, setCurrentQuote] = useState(quote);
  const projectModal = useDisclosure();
  const { startAnotherUpdate } = useQuoteEditContext();
  const hasQuoteLedger = useHasQuoteLedger();
  const hasUpdateQuoteParametersPermission = usePermissions([PERMISSION.UPDATE_QUOTE_PARAMETERS]);

  const isSameProjectAddressAsClient = isProjectAddressClientAddress(currentQuote);
  const isAddressAutofilled = isProjectAddressAutofilled(currentQuote);

  const project = useProjectOrUndefined(currentQuote?.project?.id);

  const updateQuote = useCallback(
    async (payload: IQuoteUpdateDTO) => {
      startAnotherUpdate();

      return patchQuote(+quoteId, payload);
    },
    [quoteId, startAnotherUpdate],
  );

  const onUpdateAfterBlur = useCallback(
    async <K extends keyof QuoteEditInformationForm>(
      name: K,
      rawValue: QuoteEditInformationForm[K],
      data: Record<string, any>,
    ) => {
      const { mapValue, fieldName } = data;
      const value = mapValue ? mapValue(rawValue) : rawValue;

      const handleAccountManagersUpdate = async () => {
        startAnotherUpdate();

        const payload = { userIds: value };
        const [error] = await updateQuoteAccountManagers(+quoteId, payload);

        return error;
      };

      const handleInfoUpdate = async () => {
        let payload = { [name]: value };
        if (name.startsWith(QUOTE_FIELDS.PROJECT_ADDRESS)) {
          payload = {
            [QUOTE_FIELDS.PROJECT_ADDRESS]: {
              [fieldName]: value,
            },
          };
        }

        const [error] = await updateQuote(payload);

        return error;
      };

      const apiCall = name === QUOTE_FIELDS.ACCOUNT_MANAGERS ? handleAccountManagersUpdate : handleInfoUpdate;
      const error = await apiCall();
      if (error) {
        toast.error(t('quote:errors.updatingError'));
        return;
      }
      reloadQuote();
    },
    [reloadQuote, startAnotherUpdate, quoteId, updateQuote, toast, t],
  );

  const formOptions = useMemo(() => ({ onUpdateAfterBlur }), [onUpdateAfterBlur]);
  const { form, initFormValues } = useStepForm<QuoteEditWizard, 'information'>(formOptions);
  const { setFormValues } = form;
  const { displayOptionalLinesPDF, displayOptionalAnnexPDF } = useOnChangeValues(form, [
    'displayOptionalLinesPDF',
    'displayOptionalAnnexPDF',
  ]);
  const optionsStatusInQuote = useStore((state) => state.optionalLotsTableStatus);

  const hasOptionsInQuote = useMemo(
    () =>
      !!optionsStatusInQuote &&
      [OptionalLotsTableStatus.HAS_ONE_OR_MORE_OPTIONS, OptionalLotsTableStatus.HAS_ONLY_OPTIONS].includes(
        optionsStatusInQuote,
      ),
    [optionsStatusInQuote],
  );

  const displayWarningOnPDFOptions = useMemo(
    () => !displayOptionalLinesPDF && !displayOptionalAnnexPDF && hasOptionsInQuote,
    [displayOptionalAnnexPDF, displayOptionalLinesPDF, hasOptionsInQuote],
  );

  const updateAddress = useCallback(
    async (clientAddress: AddressForm) => {
      const { address, postalCode, city, country } = clientAddress;
      setFormValues({
        projectAddressAddress: address,
        projectAddressPostalCode: postalCode,
        projectAddressCity: city,
        projectAddressCountry: country,
      });

      const payload = {
        [QUOTE_FIELDS.PROJECT_ADDRESS]: {
          address,
          postalCode,
          city,
          country,
        },
      };

      return updateQuote(payload);
    },
    [setFormValues, updateQuote],
  );

  const handleSameProjectAddressAsClient = useCallback(
    async (evt: ChangeEvent<HTMLInputElement>) => {
      if (!currentQuote.client) {
        return;
      }

      const updatedAddress = evt.target.checked
        ? currentQuote.client.address!
        : // Reset
          {
            address: '',
            city: '',
            postalCode: '',
            country: '',
          };

      const [error] = await updateAddress(updatedAddress);
      if (error) {
        toast.error(t('quote:errors.updatingError'));
      }

      reloadQuote();
    },
    [currentQuote, reloadQuote, t, toast, updateAddress],
  );

  const updateProject = useCallback(
    async (newProject: RequiredByKeys<IProject, 'address'> | null) => {
      const projectId = newProject ? newProject.id : null;

      startAnotherUpdate();
      const [err] = await updateQuoteProject(currentQuote.id, projectId);
      if (err) {
        toast.error(t('quote:cards.project.error'));
        return;
      }

      if (newProject) {
        const [error] = await updateAddress(newProject.address);
        if (error) {
          toast.error(t('quote:errors.updatingError'));
          return;
        }
      }

      reloadQuote();
      toast.success(t(`quote:cards.project.${projectId ? 'successLink' : 'successUnlink'}`));
      projectModal.onClose();

      onProjectAssociationChange(projectId);
    },
    [startAnotherUpdate, currentQuote, reloadQuote, toast, t, projectModal, onProjectAssociationChange, updateAddress],
  );

  const removeProject = useCallback(() => updateProject(null), [updateProject]);

  const handleQuoteOptionalPDFSettingsEdition = useCallback(
    async (isChecked: boolean, name: string) => {
      const payload = {
        [name]: isChecked,
      };

      setFormValues(payload);

      const [error] = await updateQuote(payload);

      if (error) {
        toast.error(t('quote:errors.updatingError'));
      }
      return reloadQuote();
    },
    [reloadQuote, setFormValues, t, toast, updateQuote],
  );

  /**
   * Add existing values in the form
   */
  useEffect(() => {
    if (currentQuote) {
      initFormValues(getInfoFormValues(currentQuote));
    }
  }, [initFormValues, currentQuote]);

  useEffect(() => {
    setFormValues({
      displayOptionalAnnexPDF: currentQuote.displayOptionalAnnexPDF,
      displayOptionalLinesPDF: currentQuote.displayOptionalLinesPDF,
    });
  }, [currentQuote, hasOptionsInQuote, setFormValues]);

  /**
   * Preserve quote data until data is fetched to avoid flickering
   */
  useEffect(() => {
    if (quote) {
      setCurrentQuote(quote);
    }
  }, [setCurrentQuote, quote]);

  const dateData = useMemo(() => ({ mapValue: formatDateFieldValue }), []);

  const noSettingsSelectedWarning = displayWarningOnPDFOptions && (
    <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
      <Text>{t('quote:informationStep.displayOptions.noOptionsWillBeDisplayed')}</Text>
    </Callout>
  );

  return (
    <Form form={form}>
      <Wizard.Placeholder placement={Wizard.PLACEMENT.HEADER_LEFT}>
        <QuoteUpdateSpinner />
      </Wizard.Placeholder>

      <Section
        title={t('quote:informationStep.information.title')}
        description={t('quote:informationStep.information.description')}
      >
        <Card p={6} w="80%">
          <HStack spacing={4}>
            <TextField<QuoteEditInformationForm> name="name" label={t('quote:fields.name')} isRequired>
              <Rule.IsRequired />
            </TextField>
            <Flex align="center">
              <TextField<QuoteEditInformationForm>
                name="refCode"
                w={40}
                label={t('quote:fields.refCode')}
                rightIcon={
                  <>
                    {hasQuoteLedger && hasUpdateQuoteParametersPermission && (
                      <IconAdvanced
                        icon={<SimpleHelpIcon />}
                        tooltip={t('quote:informationStep.information.quoteNumber.tooltip')}
                      />
                    )}
                  </>
                }
              />
            </Flex>
            <DateField<QuoteEditInformationForm>
              name="estimatedStartDate"
              label={t('quote:fields.estimatedStartDate')}
              data={dateData}
            />
            <IntegerField<QuoteEditInformationForm>
              name="workDuration"
              label={t('quote:fields.workDuration')}
              min={0}
              rightLabel={t('global:words.day_plural')}
              w={44}
              flexShrink={0}
            />
          </HStack>
        </Card>
        {project.data && (
          <>
            <ProjectWithPaymentSummaryCard project={project.data} w="80%" mt={4} />
            <Button mt={5} variant="outline" leftIcon={<SimpleDeleteIcon />} onClick={removeProject}>
              {t('quote:cards.project.unlink')}
            </Button>
          </>
        )}
        {!currentQuote?.project && (
          <>
            <AssociateProjectModal
              {...projectModal}
              title={t('quote:cards.project.modalTitle')}
              description={t('quote:cards.project.modalDesc')}
              onSubmit={updateProject}
            />
            <Button mt={5} variant="outline" leftIcon={<SimpleAddIcon />} onClick={projectModal.onOpen}>
              {t('quote:cards.project.link') as string}
            </Button>
          </>
        )}
      </Section>

      <Section
        title={t('quote:informationStep.validityDuration.title')}
        description={t('quote:informationStep.validityDuration.description')}
      >
        <Card p={6} maxWidth="56rem">
          <HStack spacing={4}>
            <DateField<QuoteEditInformationForm>
              name="date"
              label={t('quote:fields.date')}
              flex={1}
              data={dateData}
              isRequired
            >
              <Rule.IsRequired />
            </DateField>
            <IntegerField<QuoteEditInformationForm>
              name="validityDuration"
              label={t('quote:fields.validityDuration')}
              min={0}
              rightLabel={t('global:words.day_plural')}
              w={44}
              flexShrink={0}
              isRequired
            />
            <Box flex={2} alignSelf="end">
              <QuoteEndValidityDate />
            </Box>
          </HStack>
        </Card>
      </Section>

      <ChapterCollapse
        title={t('quote:informationStep.accountManagers.title')}
        description={<Trans t={t} i18nKey="quote:informationStep.accountManagers.description" />}
        defaultIsOpen
      >
        <Card p={6} w="80%">
          <UserBadgeGroupField<QuoteEditInformationForm> name="quoteAccountManagers" />
        </Card>
      </ChapterCollapse>

      <ChapterCollapse
        title={t('quote:informationStep.address.title')}
        description={<Trans t={t} i18nKey="quote:informationStep.address.description" />}
      >
        <Card p={6} w="80%">
          <VStack spacing={4} alignItems="flex-start">
            {!currentQuote.project && !!currentQuote.client && (
              <Checkbox
                colorScheme="greenBrand"
                isChecked={isSameProjectAddressAsClient}
                onChange={handleSameProjectAddressAsClient}
              >
                {t('quote:informationStep.address.sameAsClient')}
              </Checkbox>
            )}
            <AddressFields
              prefix={QUOTE_FIELDS.PROJECT_ADDRESS}
              twoLines
              isDisabled={isAddressAutofilled}
              isRequired={false}
              mt={4}
            />
          </VStack>
        </Card>
      </ChapterCollapse>

      {hasOptionsInQuote && (
        <ChapterCollapse
          title={t('quote:informationStep.displayOptions.title')}
          description={<Trans t={t} i18nKey="quote:informationStep.displayOptions.description" />}
          onCloseChildren={noSettingsSelectedWarning}
        >
          <VStack spacing={4} alignItems="flex-start">
            {noSettingsSelectedWarning}
            <Card p={6} w="80%">
              <VStack spacing={2}>
                <SwitchField<QuoteEditInformationForm>
                  name="displayOptionalLinesPDF"
                  checkedValue
                  uncheckedValue={false}
                  label={
                    <Text color="black" fontSize="lg">
                      {t('quote:informationStep.displayOptions.displayOptionalLinesPDF')}
                    </Text>
                  }
                  onChange={(isChecked) =>
                    handleQuoteOptionalPDFSettingsEdition(isChecked as any, QUOTE_FIELDS.DISPLAY_OPTIONAL_LINES_PDF)
                  }
                />
                <SwitchField<QuoteEditInformationForm>
                  name="displayOptionalAnnexPDF"
                  checkedValue
                  uncheckedValue={false}
                  label={
                    <Text color="black" fontSize="lg">
                      {t('quote:informationStep.displayOptions.displayOptionalAnnexPDF')}
                    </Text>
                  }
                  onChange={(isChecked) =>
                    handleQuoteOptionalPDFSettingsEdition(isChecked as any, QUOTE_FIELDS.DISPLAY_OPTIONAL_ANNEX_PDF)
                  }
                />
              </VStack>
            </Card>
          </VStack>
        </ChapterCollapse>
      )}

      <ChapterCollapse
        title={t('quote:informationStep.additionalNote.title')}
        description={<Trans t={t} i18nKey="quote:informationStep.additionalNote.description" />}
      >
        <Card p={6} w="80%">
          <RichTextField<QuoteEditInformationForm>
            name="note"
            navbarType="inline"
            label={t('quote:fields.note')}
            variant="light"
            configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
          />
        </Card>
      </ChapterCollapse>

      <ChapterCollapse
        title={t('quote:informationStep.mentions.title')}
        description={<Trans t={t} i18nKey="quote:informationStep.mentions.description" />}
        defaultIsOpen={!quote?.mentions}
      >
        <Card p={6} w="80%">
          <RichTextField<QuoteEditInformationForm>
            name="mentions"
            navbarType="inline"
            label={t('quote:fields.mentions')}
            configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
          />
        </Card>
      </ChapterCollapse>
    </Form>
  );
};
