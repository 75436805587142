import type { PaginatedTableProps, PaginationQuery } from '@graneet/lib-ui';
import {
  formatDateOrEmpty,
  PaginatedTable,
  generateColorFromString,
  ListingLayout,
  SimpleOrderIcon,
  PriceAdvanced,
  formatDataOrEmpty,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IOrderWithRelations, OrderPaginatedResponse } from '@graneet/business-logic';
import { ORDER_STATUS, EXPORT_ENTITY } from '@graneet/business-logic';
import { useCallback, type FC, useMemo } from 'react';
import { Box } from '@chakra-ui/react';

import { ORDER_STATUS_COLOR } from '../../constants/orders.constant';
import { OrderStatusBadge } from '../badges/OrderStatusBadge';
import { OrderComponentTypeBadge } from '../OrderComponentTypeBadge';
import { useOrderAvailableTags } from '../../services/order.api';
import { OrdersActionsButtons } from '../buttons/OrdersActionsButtons';
import { useComponentTypes } from '../../../component-type/services/component-type.api';

import { OrderBatchStatusActions } from './OrderBatchStatusActions';

import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { TagsBadges } from 'features/common/components/TagsBadges';
import { ExportButton } from 'features/export/components/ExportButton/ExportButton';

const TypeCell = ({ data }: { data: IOrderWithRelations | undefined }) =>
  data ? <OrderComponentTypeBadge order={data} /> : null;

const NameCell = ({ data }: { data: IOrderWithRelations | undefined }) =>
  data ? (
    <Box mb={2}>
      {formatDataOrEmpty(data.name)}
      <TagsBadges tags={data.tags} />
    </Box>
  ) : null;

const AmountExVATCell = ({ data }: { data: IOrderWithRelations | undefined }) =>
  data ? <PriceAdvanced amount={data.amountExVAT} /> : null;

const StatusCell = ({ data }: { data: IOrderWithRelations | undefined }) =>
  data ? <OrderStatusBadge status={data.status} /> : null;

export type OrdersTableProps = {
  gridId: string;

  orders: PaginationQuery<OrderPaginatedResponse>;

  onRowClicked: (id: number) => void;

  projectId?: number;

  canExportEntities?: boolean;
};

export const OrdersListing: FC<OrdersTableProps> = ({
  gridId,
  orders,
  onRowClicked,
  projectId,
  canExportEntities = false,
}) => {
  const { t } = useTranslation(['orders', 'global']);
  const { createRedirectionWithFilters } = useFiltersQuery();

  const selectLabel = useCallback(
    (numberSelectedItems: number) => {
      if (numberSelectedItems === 0) {
        return '';
      }
      return t('orders:banner.selection', { count: numberSelectedItems });
    },
    [t],
  );

  const allSelectedLabel = useCallback(
    (totalNumberOfItems?: number): string => t('orders:banner.allSelectedLabel', { count: totalNumberOfItems }),
    [t],
  );

  const orderAvailableTags = useOrderAvailableTags();

  const ordersActions = useMemo(
    () => <OrdersActionsButtons onCreate={createRedirectionWithFilters('/purchases/orders/create', { projectId })} />,
    [createRedirectionWithFilters, projectId],
  );

  const availableStatuses = useMemo(
    () =>
      (Object.keys(ORDER_STATUS) as Array<keyof typeof ORDER_STATUS>).map((status) => ({
        label: t(`orders:statuses.${status}`),
        value: ORDER_STATUS[status],
        colorScheme: ORDER_STATUS_COLOR[status],
      })),
    [t],
  );

  const componentTypes = useComponentTypes();
  const availableTypes = useMemo(
    () =>
      componentTypes.data
        .filter((type) => !type.isWorkforce)
        .map((type) => ({
          value: type.id.toString(),
          colorScheme: type.color,
          label: type.name,
          variant: 'rounded',
        })),
    [componentTypes.data],
  );

  const availableTags = useMemo(
    () =>
      orderAvailableTags.data.map<{ value: string; label: string }>((tag) => ({
        value: tag,
        label: tag,
        badgeColor: generateColorFromString(tag),
      })),
    [orderAvailableTags.data],
  );

  const columnDefs = useMemo<PaginatedTableProps<OrderPaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'orderNumber',
        headerName: t('orders:fields.orderNumber'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.orderNumber),
      },
      {
        field: 'type',
        headerName: t('orders:fields.type'),
        sortable: true,
        cellRenderer: TypeCell,
      },
      {
        field: 'name',
        headerName: t('orders:fields.name'),
        sortable: true,
        autoHeight: true,
        cellRenderer: NameCell,
      },
      {
        field: 'orderDate',
        headerName: t('orders:fields.orderDate'),
        sortable: true,
        valueFormatter: (v) => formatDateOrEmpty(v.data?.orderDate),
      },
      {
        field: 'supplier',
        headerName: t('orders:fields.supplier'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.supplier?.name),
      },
      {
        field: 'project',
        hide: !!projectId,
        headerName: t('orders:fields.orderProject'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.project?.name),
      },
      {
        field: 'deliveryDate',
        headerName: t('orders:fields.deliveryDate'),
        sortable: true,
        valueFormatter: (v) => formatDateOrEmpty(v.data?.deliveryDate),
      },
      {
        field: 'amountExVAT',
        headerName: t('orders:fields.amountExVAT'),
        sortable: true,
        cellRenderer: AmountExVATCell,
      },
      {
        field: 'status',
        headerName: t('orders:fields.status'),
        sortable: true,
        cellRenderer: StatusCell,
      },
    ],
    [projectId, t],
  );

  return (
    <ListingLayout
      pagination={orders}
      search={{ placeholder: t('orders:actions.search') }}
      filters={[
        {
          type: 'checkbox',
          name: 'status',
          availableValues: availableStatuses,
          label: t('orders:filters.status'),
        },
        {
          type: 'multi',
          name: 'tags',
          availableValues: availableTags,
          label: t('global:words.tags'),
          noValueMessage: t('global:tags.noLabel'),
          placeholder: t('global:words.c.select'),
        },
        {
          type: 'multi',
          name: 'type',
          availableValues: availableTypes,
          label: t('orders:filters.type'),
          placeholder: t('global:words.c.select'),
        },
        {
          type: 'date',
          name: 'orderDate',
          label: t('orders:filters.date'),
        },
      ]}
      actions={ordersActions}
      content={
        <PaginatedTable
          gridId={gridId}
          columnDefs={columnDefs}
          pagination={orders}
          onRowClicked={(event) => {
            if (event.data) {
              onRowClicked(event.data.id);
            }
          }}
          zeroState={{
            icon: <SimpleOrderIcon boxSize={45} />,
            label: t(`orders:errors.${projectId ? 'noOrderOnProject' : 'noOrder'}`),
          }}
          emptyState={{
            label: t('orders:errors.noResult'),
          }}
          countLabel={(count) => t('orders:order', { count })}
          sums={{
            amountExVAT: {
              label: t('orders:fields.amountExVAT'),
              type: 'currency',
            },
          }}
          rowSelection={canExportEntities ? 'multiple' : undefined}
          selectionComponentProps={{
            allSelectedLabel,
            selectLabel,
            renderActions: (props) => (
              <>
                <OrderBatchStatusActions {...props} onStatusesChanged={orders.refetch} />
                <ExportButton entity={EXPORT_ENTITY.ORDER} {...props} />
              </>
            ),
          }}
        />
      }
    />
  );
};
