import type { FC } from 'react';
import { useCallback } from 'react';
import {
  Button,
  DrawersStack,
  SimpleAddIcon,
  formatNumberToVatRate,
  useCurrency,
  useDrawer,
  useDrawersStack,
} from '@graneet/lib-ui';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'graneet-form';
import type { ILibraryJobResponseDTO } from '@graneet/business-logic';

import { useInvoiceTreeContext } from '../hooks/useInvoiceTree';
import type { InvoiceEditionForm } from '../forms/invoice-edition-form';
import { getItemFieldName } from '../forms/invoice-edition-form';

import { LibraryJobImportDrawer } from 'features/library-job/components/LibraryJobImportDrawer';
import { useDefaultVatRate } from 'features/common/hooks/useDefaultVATRate';

export const InvoiceAddItemsButtons: FC = () => {
  const { t } = useTranslation(['invoice']);
  const form = useFormContext<InvoiceEditionForm>();
  const { mapNumberToAmount } = useCurrency();
  const { getDisplayedCurrentTree, getLastLeafOfNode, createLeaf } = useInvoiceTreeContext();

  const defaultVATRate = useDefaultVatRate();

  const jobImportDrawersStack = useDrawersStack<'job-import-drawerstack'>();
  const jobImportDrawer = useDrawer('job-import-drawer', jobImportDrawersStack);

  const onItemAdd = useCallback(() => {
    const { rootNodeId } = getDisplayedCurrentTree();
    const lastItemId = getLastLeafOfNode(rootNodeId);
    const id = createLeaf(rootNodeId, lastItemId, {});

    const formValues: Partial<InvoiceEditionForm> = {};
    formValues[getItemFieldName(id, 'vatRate')] = defaultVATRate ? formatNumberToVatRate(defaultVATRate) : undefined;
    form.setFormValues(formValues);
  }, [createLeaf, defaultVATRate, form, getDisplayedCurrentTree, getLastLeafOfNode]);

  const importLibraryJobs = useCallback(
    (libraryJobs: ILibraryJobResponseDTO[]) => {
      const { rootNodeId } = getDisplayedCurrentTree();
      const lastItemId = getLastLeafOfNode(rootNodeId);
      const formValues: Partial<InvoiceEditionForm> = {};

      libraryJobs.forEach((job) => {
        // add leaf
        const id = createLeaf(rootNodeId, lastItemId, {
          unit: job.unit,
          description: job.description,
          unitPrice: job.unitDisbursementExVAT,
        });

        // fill form
        formValues[getItemFieldName(id, 'code')] = job.refCode;
        formValues[getItemFieldName(id, 'unit')] = job.unit;
        formValues[getItemFieldName(id, 'description')] = job.description;
        formValues[getItemFieldName(id, 'unitPrice')] = mapNumberToAmount(job.unitDisbursementExVAT);
      });

      form.setFormValues(formValues);

      return null;
    },
    [createLeaf, form, getDisplayedCurrentTree, getLastLeafOfNode, mapNumberToAmount],
  );

  return (
    <Flex justifyContent="space-between">
      <Flex gap={2}>
        <Button leftIcon={<SimpleAddIcon />} variant="outline" onClick={onItemAdd}>
          {t('invoice:steps.container.addItem')}
        </Button>
        <Button leftIcon={<SimpleAddIcon />} variant="outline" onClick={jobImportDrawer.onOpen}>
          {t('invoice:steps.container.importFromPriceLibrary')}
        </Button>
      </Flex>

      <DrawersStack drawersStack={jobImportDrawersStack}>
        <LibraryJobImportDrawer
          title={t('invoice:drawers.jobImportDrawer.title')}
          description={t('invoice:drawers.jobImportDrawer.description')}
          drawer={jobImportDrawer}
          onSubmit={importLibraryJobs}
        />
      </DrawersStack>
    </Flex>
  );
};
