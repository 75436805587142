import type { FC } from 'react';
import { useState, useCallback } from 'react';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Checkbox } from '@chakra-ui/react';
import { Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

interface CreditConfirmModalProps {
  modal: UseDisclosureReturn;

  onConfirmed(isCreateDraftChecked: boolean): void;
}

export const CreditConfirmModal: FC<CreditConfirmModalProps> = ({ modal, onConfirmed }) => {
  const { t } = useTranslation(['credit']);
  const [isCreateDraftChecked, setIsCreateDraftChecked] = useState(true);

  const toggleDraftCheck = useCallback(() => {
    setIsCreateDraftChecked((checked) => !checked);
  }, [setIsCreateDraftChecked]);

  const handleConfirmed = useCallback(() => {
    onConfirmed(isCreateDraftChecked);
  }, [isCreateDraftChecked, onConfirmed]);

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t('credit:newCreditModal.title')}>
      {t('credit:newCreditModal.description')}
      <Checkbox colorScheme="greenBrand" isChecked={isCreateDraftChecked} onChange={toggleDraftCheck} mt={6} mb={2}>
        {t('credit:newCreditModal.checkboxLabel')}
      </Checkbox>
      <Modal.Close>{t('credit:newCreditModal.cancel')}</Modal.Close>

      <Modal.PrimaryButton onClick={handleConfirmed}>
        {t(isCreateDraftChecked ? 'credit:newCreditModal.confirm' : 'credit:newCreditModal.confirmOnlyCredit')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
