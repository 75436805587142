import { useCallback } from 'react';
import type { QuoteComponent } from '@org/quotation-lib';
import { ContentTypeGuard } from '@org/quotation-lib';

import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const useQuoteLibraryCanExport = () => {
  const { quote } = useQuote();

  const checkComponent = useCallback((nodeContent: QuoteComponent) => {
    const nodeContentObject = nodeContent.export();

    if (
      nodeContentObject.denomination !== null &&
      nodeContentObject.unit !== null &&
      nodeContentObject.unitFlatCostAmount !== null &&
      nodeContentObject.componentTypeId !== null
    ) {
      return true;
    }

    return false;
  }, []);

  return useCallback(
    (nodeId: string) => {
      const node = quote.getTree().getNodeOrThrow(nodeId);
      const nodeContent = node.getContent();

      if (ContentTypeGuard.isQuoteItem(nodeContent) || ContentTypeGuard.isQuoteSubItem(nodeContent)) {
        const nodeContentObject = nodeContent.export();

        if (
          nodeContentObject.denomination === null ||
          nodeContentObject.unit === null ||
          nodeContentObject.unitFlatCostAmount === null
        ) {
          return false;
        }

        const children = Array.from(node.getChildren().values());

        if (!children.length) {
          return true;
        }

        return children.every((child) => {
          const content = child.getContent();
          if (ContentTypeGuard.isQuoteComponent(content)) {
            return checkComponent(content);
          }
          return false;
        });
      }

      if (ContentTypeGuard.isQuoteComponent(nodeContent)) {
        return checkComponent(nodeContent);
      }

      return false;
    },
    [checkComponent, quote],
  );
};
