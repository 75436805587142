import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleStatementIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 16 16" {...props} fill="none">
    <path
      d="M14 8H6M14 4H6M14 12H6M3.33333 8C3.33333 8.3682 3.03485 8.66667 2.66667 8.66667C2.29848 8.66667 2 8.3682 2 8C2 7.6318 2.29848 7.33333 2.66667 7.33333C3.03485 7.33333 3.33333 7.6318 3.33333 8ZM3.33333 4C3.33333 4.36819 3.03485 4.66667 2.66667 4.66667C2.29848 4.66667 2 4.36819 2 4C2 3.63181 2.29848 3.33333 2.66667 3.33333C3.03485 3.33333 3.33333 3.63181 3.33333 4ZM3.33333 12C3.33333 12.3682 3.03485 12.6667 2.66667 12.6667C2.29848 12.6667 2 12.3682 2 12C2 11.6318 2.29848 11.3333 2.66667 11.3333C3.03485 11.3333 3.33333 11.6318 3.33333 12Z"
      stroke="#627D98"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
