import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { IProgressStatementSummaryResponseDTO } from '@graneet/business-logic';
import { AmountSummary } from '@graneet/lib-ui';

type DirectPaymentLinesProps = {
  summary: IProgressStatementSummaryResponseDTO;
};

export const DirectPaymentLines: FC<DirectPaymentLinesProps> = ({ summary }) => {
  const { t } = useTranslation(['progressStatement']);

  const displayDirectPayment = (summary.directPaymentsSupplierInvoices || []).length > 0;

  return displayDirectPayment ? (
    <>
      {summary.directPaymentsSupplierInvoices.map(({ id, amountIncVAT, ordersSupplierInvoices, supplier }) => (
        <AmountSummary.Item
          key={id}
          left
          light
          negative
          label={t('progressStatement:isCreating.validationStep.directPayment', {
            supplierName: supplier?.name,
            orderName: ordersSupplierInvoices && ordersSupplierInvoices[0].order.name,
          })}
          amount={amountIncVAT}
        />
      ))}

      <AmountSummary.Highlight
        left
        label={t('progressStatement:isCreating.validationStep.netBillContractHolder')}
        amount={summary.netToPaidIncVAT}
      />
    </>
  ) : null;
};
