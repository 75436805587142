import type { FC } from 'react';
import { ListingLayout } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ILibraryComponentWithRelations } from '@graneet/business-logic';

import { useLibraryComponents } from '../services/library-component.api';

import { LibraryComponentTable } from './LibraryComponentTable';

interface ImportLibraryComponentListingProps {
  onRowSelected: (libraryJobs: ILibraryComponentWithRelations[]) => void;

  nonSelectableIds: number[];
}

export const ImportLibraryComponentListing: FC<ImportLibraryComponentListingProps> = ({
  onRowSelected,
  nonSelectableIds,
}) => {
  const { t } = useTranslation(['library']);

  const libraryComponents = useLibraryComponents({ storageStrategy: 'state' });

  return (
    <ListingLayout
      search={{ placeholder: t('library:actions.searchLibraryComponent') }}
      pagination={libraryComponents}
      content={
        <LibraryComponentTable
          gridId="library-component-import"
          libraryComponents={libraryComponents}
          onRowSelected={onRowSelected}
          nonSelectableIds={nonSelectableIds}
        />
      }
    />
  );
};
