import { useCallback } from 'react';
import { HashAsset, QuoteGetComponentsSupply } from '@org/quotation-lib';
import { useToast } from '@chakra-ui/react';

import type { ComponentUpdateManyDTO } from './useQuoteComponentUpdateMany';
import { useQuoteComponentUpdateMany } from './useQuoteComponentUpdateMany';

import { UpdateSimilarComponentToast } from 'features/quotation/quote/components/toasts/updateSimilarComponentToast';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';

export function useQuoteComponentUpdateSimilar() {
  const { quote } = useQuote();

  const componentSimilarUpdate = 'component-similar-update';
  const toast = useToast();
  const { resetCommand } = useCommands();
  const quoteComponentUpdateMany = useQuoteComponentUpdateMany();

  return useCallback(
    (nodeId: string, values: ComponentUpdateManyDTO) => {
      const quoteComponent = quote.getTree().getQuoteComponentOrThrow(nodeId);
      const componentsSupply = QuoteGetComponentsSupply.execute(quote);

      const componentHash = HashAsset.hashComponent(quoteComponent);
      const componentSupply = componentsSupply.get(componentHash);
      const componentsRelated = componentSupply?.similarNodeIds || [];
      if (!componentSupply || componentsRelated.length === 1) {
        return;
      }

      const onConfirmation = () => {
        resetCommand();
        quoteComponentUpdateMany(componentSupply.similarNodeIds, values, 'inline');
        toast.close(componentSimilarUpdate);
      };
      const onRejection = () => {
        toast.close(componentSimilarUpdate);
      };

      toast({
        id: componentSimilarUpdate,
        duration: 6000,
        render: () => (
          <UpdateSimilarComponentToast
            componentsRelatedCount={componentsRelated.length - 1}
            onConfirmation={onConfirmation}
            onRejection={onRejection}
          />
        ),
      });
    },
    [quote, quoteComponentUpdateMany, resetCommand, toast],
  );
}
