import type { Quote } from '@org/quotation-lib';
import { useCallback } from 'react';

import { quoteSetToStore } from '../store/quoteSetToStore';

export function useQuoteSetToStore() {
  return useCallback((quote: Quote) => {
    quoteSetToStore(quote);
  }, []);
}
