export interface NavTranslations {
  new: string;
}
export const navTranslations: NavTranslations = {
  new: 'Nouveau',
};

export const configureDefaultLabels = (translation: NavTranslations) => {
  Object.assign(navTranslations, translation);
};
