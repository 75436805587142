import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { Form, useForm, useFormContext, useFormStatus, useOnChangeValues } from 'graneet-form';
import {
  useCurrency,
  Modal,
  CurrencyField,
  DateField,
  getCurrentDateAsString,
  Callout,
  SimpleAlertIcon,
  SingleSelectField,
  Card,
  Price,
  Title,
} from '@graneet/lib-ui';
import type { IBill, IPayment } from '@graneet/business-logic';
import {
  getBillMaxAmountToBePaidIncVAT,
  getBillMinAmountToBePaidIncVAT,
  isNumberFinite,
  isBillOnOverpayment,
  FEATURE_FLAGS,
} from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { Rule } from 'features/form/rules/Rule';
import { useCreatePayment, useUpdatePayment } from 'features/payment/services/payment.api';
import { useAccountingConfigs } from 'features/accounting/services/accounting-config.api';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

const FORM_ID = 'bill-pay-form';

type PayForm = {
  paidAt: string;

  amountPaidIncVAT: number;

  accountingConfigBankAccountId: string | undefined; // @[ff: accounting-payment-methods] make non-optional
};

interface OverpaymentCalloutProps {
  bill: IBill;
  paymentAmountToUpdate?: number;
}

const OverpaymentCallout: FC<OverpaymentCalloutProps> = ({ bill, paymentAmountToUpdate }) => {
  const form = useFormContext<PayForm>();
  const { amountPaidIncVAT: rawAmountPaidIncVAT } = useOnChangeValues(form, ['amountPaidIncVAT']);
  const { mapAmountToNumber } = useCurrency();
  const { t } = useTranslation(['bill']);
  const amountPaidIncVAT = isNumberFinite(rawAmountPaidIncVAT) ? mapAmountToNumber(rawAmountPaidIncVAT) : 0;
  let remainingToBePaidIncVAT = bill.remainingToBePaidIncVAT - amountPaidIncVAT;

  if (paymentAmountToUpdate) {
    remainingToBePaidIncVAT += paymentAmountToUpdate;
  }

  if (isBillOnOverpayment(bill.totalToBePaidIncVAT, remainingToBePaidIncVAT)) {
    return (
      <Callout mt={4} icon={<SimpleAlertIcon boxSize={5} />}>
        <Text fontSize="sm">{t('bill:modalToPay.callout')}</Text>
      </Callout>
    );
  }
  return null;
};

interface BillPayModalProps {
  bill: IBill;

  modal: UseDisclosureReturn;

  onSuccess?: () => void;

  payment?: IPayment;
}

const BillPayModalInternal: FC<BillPayModalProps> = ({ bill, modal, onSuccess, payment }) => {
  const { t } = useTranslation(['global', 'bill']);
  const { formatAsAmount, mapNumberToAmount, mapAmountToNumber } = useCurrency();

  // @[ff: accounting-payment-methods]
  const hasNewAccountingPaymentMethods = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_PAYMENT_METHODS);

  const accountingConfigQuery = useAccountingConfigs();

  const createPaymentMutation = useCreatePayment();
  const updatePaymentMutation = useUpdatePayment();

  const form = useForm<PayForm>({
    defaultValues: {
      accountingConfigBankAccountId: accountingConfigQuery.data?.defaultAccountingConfigBankAccount?.id,
      amountPaidIncVAT: payment
        ? mapNumberToAmount(payment.amountPaidIncVAT)
        : mapNumberToAmount(bill.remainingToBePaidIncVAT),
      paidAt: getCurrentDateAsString(),
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const bankAccounts = useMemo(
    () =>
      (accountingConfigQuery.data?.accountingConfigBanks ?? [])
        .map((bank) => bank.accountingConfigBankAccounts ?? [])
        .flat(),
    [accountingConfigQuery.data?.accountingConfigBanks],
  );

  const onSubmit = useCallback(
    async (formValues: PayForm) => {
      const amountPaidIncVAT = mapAmountToNumber(formValues.amountPaidIncVAT);

      if (!payment) {
        await createPaymentMutation.mutateAsync({
          bill,
          dto: {
            ...formValues,
            amountPaidIncVAT,
            paidAt: formValues.paidAt,
            billId: bill.id,
          },
        });
      } else {
        await updatePaymentMutation.mutateAsync({
          bill,
          payment,
          dto: {
            ...formValues,
            amountPaidIncVAT,
            paidAt: formValues.paidAt,
            billId: bill.id,
          },
        });
      }

      onSuccess?.();
      modal.onClose();
    },
    [bill, createPaymentMutation, mapAmountToNumber, modal, onSuccess, payment, updatePaymentMutation],
  );

  return (
    <Form form={form} onSubmit={form.handleSubmit(onSubmit)} id={FORM_ID}>
      <Flex direction="column" gap={6} w="100%">
        <Card variant="light-gray">
          <Flex direction="column" alignItems="center">
            <Title>
              <Price amount={bill.remainingToBePaidIncVAT} />
            </Title>
            {t('bill:modalToPay.remainingInfo', { amount: formatAsAmount(bill.totalToBePaidIncVAT) })}
          </Flex>
        </Card>

        <Flex gap={3}>
          <CurrencyField<PayForm>
            label={t('bill:modalToPay.amountPaidIncVAT')}
            name="amountPaidIncVAT"
            min={getBillMinAmountToBePaidIncVAT(bill)}
            max={getBillMaxAmountToBePaidIncVAT(bill)}
            isRequired
          >
            <Rule.IsRequired />
          </CurrencyField>

          <DateField<PayForm> label={t('bill:modalToPay.paidAt')} name="paidAt" isRequired>
            <Rule.IsRequired />
          </DateField>
        </Flex>

        {hasNewAccountingPaymentMethods && (
          <Box>
            <SingleSelectField<PayForm>
              label={t('bill:modalToPay.bankAccount')}
              name="accountingConfigBankAccountId"
              isRequired
              options={bankAccounts.map((acc) => ({
                label: [acc.label, acc.account].filter(Boolean).join(' - '),
                value: acc.id,
              }))}
            >
              <Rule.IsRequired />
            </SingleSelectField>
          </Box>
        )}

        <OverpaymentCallout bill={bill} paymentAmountToUpdate={payment?.amountPaidIncVAT} />
      </Flex>

      <Modal.CloseButton />

      <Modal.PrimaryButton
        isLoading={createPaymentMutation.isPending || updatePaymentMutation.isPending}
        isDisabled={!isFormValid}
        type="submit"
        form={FORM_ID}
      >
        {t('global:words.c.validate')}
      </Modal.PrimaryButton>
    </Form>
  );
};

export const BillPayModal: FC<BillPayModalProps> = ({ modal, ...otherProps }) => {
  const { t } = useTranslation(['bill']);

  return (
    <Modal isOpen={modal.isOpen} onClose={modal.onClose} title={t('bill:modalToPay.title')}>
      <QueryWrapper>
        <BillPayModalInternal modal={modal} {...otherProps} />
      </QueryWrapper>
    </Modal>
  );
};
