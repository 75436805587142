import type { MotionStyle, MotionValue } from 'framer-motion';
import { motion } from 'framer-motion';
import type { FC, ReactNode } from 'react';

const pageStyle: MotionStyle = {
  width: '100%',
  height: '100%',
  display: 'inline-block',
  flex: 'none',
};

type SliderProps = {
  x: MotionValue<number>;
  i: number;
  children: ReactNode;
};

export const Slider: FC<SliderProps> = ({ x, i, children }: SliderProps) => (
  <motion.div
    style={{
      ...pageStyle,
      x,
      left: `${i * 100}%`,
      right: `${i * 100}%`,
    }}
  >
    {children}
  </motion.div>
);
