import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import type { ISupplier } from '@graneet/business-logic';
import { Card, GotoLink } from '@graneet/lib-ui';

import { SupplierFieldOption } from 'features/supplier/components/SupplierFieldOption';

type AssociatedSupplierCardProps = {
  supplier: ISupplier;
};

export const AssociatedSupplierCard: FC<AssociatedSupplierCardProps> = ({ supplier }) => {
  const { t } = useTranslation(['orders']);

  const { id } = supplier;

  return (
    <Card
      title={
        <Flex mb={3}>
          <Text mr={4}>{t('orders:supplierCard.title')}</Text>
          <GotoLink to={`/contacts/suppliers/${id}`} label={t('orders:supplierCard.goTo')} />
        </Flex>
      }
    >
      <SupplierFieldOption supplier={supplier} />
    </Card>
  );
};
