import type { FC } from 'react';
import type { ButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button } from '@graneet/lib-ui';
import { AddIcon } from '@chakra-ui/icons';

interface AddValidationLevelButtonProps {
  openModal: () => void;
}

export const AddValidationStepButton: FC<AddValidationLevelButtonProps & ButtonProps> = ({ openModal, ...rest }) => {
  const { t } = useTranslation(['validationStep']);
  return (
    <Button variant="outline" leftIcon={<AddIcon />} size="sm" onClick={openModal} {...rest}>
      {t('validationStep:addValidationStepButton')}
    </Button>
  );
};
