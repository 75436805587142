import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, useToast } from '@graneet/lib-ui';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { IQuote } from '@graneet/business-logic';

import * as quoteApi from '../../services/quote.api';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';

interface EditQuoteStatusModalProps {
  quote: IQuote;
  onSuccess(quoteId: number): void;
  modalControls: UseDisclosureReturn;
}

export const EditQuoteStatusModal: FC<EditQuoteStatusModalProps> = ({ quote, onSuccess, modalControls }) => {
  const { t } = useTranslation(['global', 'quote']);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { onClose, isOpen } = modalControls;

  const handleQuoteCopy = useCallback(async () => {
    setIsLoading(true);

    const [updateError, quoteCopy] = await quoteApi.updateQuoteStatusToDraft(quote.id);
    if (updateError) {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
      setIsLoading(false);
      return;
    }

    onSuccess(quoteCopy.id);
    setIsLoading(false);
  }, [onSuccess, quote.id, t, toast]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('quote:editVersionModal.title')} size="3xl">
      <Box>{t('quote:editVersionModal.body')}</Box>

      <Modal.Close />

      <Modal.PrimaryButton isLoading={isLoading} onClick={handleQuoteCopy}>
        {t('quote:actions.modify')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
