import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import { SingleSelectField, CheckboxField, TextField, TwoColumns } from '@graneet/lib-ui';
import type { FormContextApi } from 'graneet-form';
import { Form } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IContact } from '@graneet/business-logic';

import { useContactIsUsedAsDefaultRecipient } from '../../services/contact.api';

import { Rule } from 'features/form/rules/Rule';

export interface ContactForm {
  lastName: string;
  firstName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  role?: string;
  isDefaultEmailRecipient?: boolean;
}

type Option = { label: string; value: string; color: string };

interface ContactUpsertFormProps {
  contact?: Omit<IContact, 'updatedAt' | 'createdAt'> | null;
  roles: string[];
  form: FormContextApi<ContactForm>;
  hasIsDefaultEmailRecipient?: boolean;
}

export const ContactUpsertForm: FC<ContactUpsertFormProps> = ({ contact, roles, form, hasIsDefaultEmailRecipient }) => {
  const { t } = useTranslation(['contacts']);

  const contactIsUsedAsDefaultRecipient = useContactIsUsedAsDefaultRecipient(contact?.id);

  useEffect(() => {
    if (contact) {
      form.setFormValues({
        firstName: contact.firstName,
        lastName: contact.lastName ?? '',
        role: contact.role ?? '',
        email: contact.email ?? undefined,
        phoneNumber: contact.phoneNumber ?? '',
        isDefaultEmailRecipient: contact.isDefaultEmailRecipient,
      });
    }
  }, [form, contact]);

  const options = useMemo(
    () =>
      roles.map<Option>((role) => ({
        label: role,
        value: role,
        color: 'white',
      })),
    [roles],
  );

  return (
    <Box w="100%">
      <Form form={form}>
        <VStack justifyContent="stretch" alignItems="stretch" spacing={6} my={4}>
          <TwoColumns>
            <TextField<ContactForm> name="lastName" label={t('contacts:fields.lastName')} isRequired>
              <Rule.IsRequired />
            </TextField>
            <TextField<ContactForm> name="firstName" label={t('contacts:fields.firstName')} />
          </TwoColumns>

          <SingleSelectField<ContactForm>
            name="role"
            placeholder={t('contacts:fields.rolePlaceholder')}
            label={t('contacts:fields.role')}
            options={options}
            isCreatable
          />

          <TwoColumns>
            <TextField<ContactForm>
              name="email"
              label={t('contacts:fields.email')}
              isRequired={contactIsUsedAsDefaultRecipient.data.isUsed}
            >
              {contactIsUsedAsDefaultRecipient.data.isUsed && <Rule.IsRequired />}
              <Rule.IsEmail />
            </TextField>
            <TextField<ContactForm> name="phoneNumber" label={t('contacts:fields.phoneNumber')} />
          </TwoColumns>

          {hasIsDefaultEmailRecipient && (
            <VStack>
              <CheckboxField<ContactForm>
                name="isDefaultEmailRecipient"
                label={t('contacts:fields.isDefaultEmailRecipient')}
              />
              <Text color="gray.600">{t('contacts:fields.isDefaultEmailRecipientPlaceholder')}</Text>
            </VStack>
          )}
        </VStack>
      </Form>
    </Box>
  );
};
