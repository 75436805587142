import { useCallback } from 'react';
import type { QuoteMarginObject, Quote } from '@org/quotation-lib';
import { MARGIN_TYPE } from '@org/quotation-lib';
import Big from 'big.js';
import { v4 as uuid } from 'uuid';
import type { QuoteLotUpsertDTO } from '@org/graneet-bff-client';
import { MarginComputedValue } from '@org/graneet-bff-client';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { updateQuoteAmountIsUnreachable } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';
import { genericUpdateItemMapper } from 'features/quotation/quote-common/mappers/quoteItemsGenericMapper';

export function useQuoteLotUpdateMargin() {
  const { quoteLotsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  const handleExcute = useCallback(
    (quote: Quote, nodeId: string, newMargin: string, marginType: MARGIN_TYPE) => {
      const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
      const quoteNode = quoteLot.getNodeOrThrow();
      const quoteLotMargin = quoteLot.getMargin();
      let newTotalMargin;
      const margins = {
        overheadCosts: quoteLotMargin.getOverheadCosts().toString(),
        profitMargin: quoteLotMargin.getProfitMargin().toString(),
        totalMargin: quoteLotMargin.getTotalMargin().toString(),
      };

      switch (marginType) {
        case MARGIN_TYPE.OVERHEAD_COSTS: {
          newTotalMargin = new Big(margins.profitMargin).mul(newMargin).toString();
          quoteLot.updateTotalMargin(Big(newTotalMargin), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.OVERHEAD_COSTS,
          });
          break;
        }
        case MARGIN_TYPE.PROFIT_MARGIN: {
          newTotalMargin = new Big(newMargin).mul(margins.overheadCosts).toString();
          quoteLot.updateTotalMargin(Big(newTotalMargin), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          break;
        }
        case MARGIN_TYPE.TOTAL_MARGIN: {
          newTotalMargin = newMargin;
          quoteLot.updateTotalMargin(Big(newTotalMargin), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          break;
        }
        default: {
          console.error('Invalid margin type');
          return false;
        }
      }

      quoteSetToStore(quote);

      const clientRequestId = uuid();
      const timestamp = new Date().toISOString();
      quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });

      const body: QuoteLotUpsertDTO = {
        quoteLotId: quoteLot.getId(),
        margin: {
          overheadCosts:
            marginType === MARGIN_TYPE.OVERHEAD_COSTS ? newMargin : quoteLotMargin.getOverheadCosts().toString(),
          profitMargin:
            marginType === MARGIN_TYPE.PROFIT_MARGIN ? newMargin : quoteLotMargin.getProfitMargin().toString(),
          totalMargin: marginType === MARGIN_TYPE.TOTAL_MARGIN ? newMargin : quoteLotMargin.getTotalMargin().toString(),
          computed: MarginComputedValue.Total,
        },
        ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
      };

      quoteLotsProxyApi
        .updateQuoteLot(quoteLot.getId(), body)
        .then(([err]) => err && quoteError())
        .catch((err) => quoteError(err.message));

      if (quoteLot.getMargin().getTotalMargin()?.eq(Big(newTotalMargin))) {
        updateQuoteAmountIsUnreachable(false);
      } else {
        updateQuoteAmountIsUnreachable(true);
      }
      return true;
    },
    [quoteError, quoteLotsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );

  const handleUndo = useCallback(
    (
      quote: Quote,
      nodeId: string,
      oldValue: {
        margins: QuoteMarginObject;
        marginType: MARGIN_TYPE;
      },
    ) => {
      const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
      const quoteNode = quoteLot.getNodeOrThrow();
      const quoteLotMargin = quoteLot.getMargin();

      let newTotalMargin;

      switch (oldValue.marginType) {
        case MARGIN_TYPE.OVERHEAD_COSTS: {
          newTotalMargin = new Big(quoteLotMargin.getProfitMargin()).mul(oldValue.margins.overheadCosts).toString();
          quoteLot.updateTotalMargin(Big(newTotalMargin), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.OVERHEAD_COSTS,
          });
          break;
        }
        case MARGIN_TYPE.PROFIT_MARGIN: {
          newTotalMargin = new Big(oldValue.margins.profitMargin).mul(quoteLotMargin.getOverheadCosts()).toString();
          quoteLot.updateTotalMargin(Big(newTotalMargin), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          break;
        }
        case MARGIN_TYPE.TOTAL_MARGIN: {
          newTotalMargin = oldValue.margins.totalMargin;
          quoteLot.updateTotalMargin(Big(newTotalMargin), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          break;
        }
        default: {
          console.error('Invalid margin type');
          return false;
        }
      }

      quoteSetToStore(quote);

      const clientRequestId = uuid();
      const timestamp = new Date().toISOString();
      quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
      const body: QuoteLotUpsertDTO = {
        quoteLotId: quoteLot.getId(),
        margin: {
          overheadCosts:
            oldValue.marginType === MARGIN_TYPE.OVERHEAD_COSTS
              ? oldValue.margins.overheadCosts
              : quoteLotMargin.getOverheadCosts().toString(),
          profitMargin:
            oldValue.marginType === MARGIN_TYPE.PROFIT_MARGIN
              ? oldValue.margins.profitMargin
              : quoteLotMargin.getProfitMargin().toString(),
          totalMargin:
            oldValue.marginType === MARGIN_TYPE.TOTAL_MARGIN
              ? oldValue.margins.totalMargin
              : quoteLotMargin.getTotalMargin().toString(),
          computed: MarginComputedValue.Total,
        },
        ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
      };

      quoteLotsProxyApi
        .updateQuoteLot(quoteLot.getId(), body)
        .then(([err]) => err && quoteError())
        .catch((err) => quoteError(err.message));

      if (quoteLot.getMargin().getTotalMargin()?.eq(Big(newTotalMargin))) {
        updateQuoteAmountIsUnreachable(false);
      } else {
        updateQuoteAmountIsUnreachable(true);
      }
      return true;
    },
    [quoteError, quoteLotsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );

  return useCallback(
    (nodeId: string, newMargin: string, marginType: MARGIN_TYPE): ICommand => ({
      execute(quote: Quote) {
        try {
          this.oldValue = {
            margins: quote.getMargin().export(),
            marginType,
          };
          return handleExcute(quote, nodeId, newMargin, marginType);
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          return handleUndo(quote, nodeId, this.oldValue);
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [handleExcute, handleUndo, quoteError],
  );
}
