import { createContext } from 'react';

import type { PaginatedDataContextApi } from '../hooks/usePaginatedData';

export const defaultPaginatedDataContext = {
  data: [],
  fetch: async () => {},
  hasMore: false,
  loadMore(): void {},
  loading: false,
  loadingMore: false,
  searchState: {
    searchTerm: '',
    setSearchTerm: () => {},
  },
  storage: new URLSearchParams(),
  updateFilters(): any {},
  updateSorting(): any {},
  sums: {},
};

export const PaginatedDataContext =
  createContext<PaginatedDataContextApi<Record<any, any>>>(defaultPaginatedDataContext);
