import { SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import type { ButtonColorScheme } from '@graneet/lib-ui';

export const SUPPLIER_INVOICES_STATUS_COLOR: Record<SUPPLIER_INVOICE_STATUS, ButtonColorScheme> = {
  [SUPPLIER_INVOICE_STATUS.TO_PROCESS]: 'gray',
  [SUPPLIER_INVOICE_STATUS.TO_PAY]: 'blue',
  [SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID]: 'yellow',
  [SUPPLIER_INVOICE_STATUS.PAID]: 'green',
  [SUPPLIER_INVOICE_STATUS.DIRECT_PAYMENT]: 'green',
  [SUPPLIER_INVOICE_STATUS.TO_VALIDATE]: 'orange',
};

export const SUPPLIER_INVOICE_LATE_DAYS_RANGES = {
  LESS_THAN_30_DAYS: 'LESS_THAN_30_DAYS',
  BETWEEN_30_AND_60_DAYS: 'BETWEEN_30_AND_60_DAYS',
  BETWEEN_60_AND_90_DAYS: 'BETWEEN_60_AND_90_DAYS',
  MORE_THAN_90_DAYS: 'MORE_THAN_90_DAYS',
};

export const SUPPLIER_INVOICE_LATE_DAYS_RANGES_VALUES = {
  [SUPPLIER_INVOICE_LATE_DAYS_RANGES.LESS_THAN_30_DAYS]: { max: 30 },
  [SUPPLIER_INVOICE_LATE_DAYS_RANGES.BETWEEN_30_AND_60_DAYS]: { min: 30, max: 60 },
  [SUPPLIER_INVOICE_LATE_DAYS_RANGES.BETWEEN_60_AND_90_DAYS]: { min: 60, max: 90 },
  [SUPPLIER_INVOICE_LATE_DAYS_RANGES.MORE_THAN_90_DAYS]: { min: 90 },
};
