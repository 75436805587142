import type { FC } from 'react';
import { useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import type { ButtonProps } from '@graneet/lib-ui';
import { ActionMenu, Button, formatNumberToVatRate } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'graneet-form';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type { OrderTree } from '../../../hooks/useOrderTree';
import { useOrderTreeContext } from '../../../hooks/useOrderTree';
import type { OrderEditForm } from '../../../forms/order-edit-wizard';
import { getOrderItemFieldName } from '../../../forms/order-edit-wizard';

import { OrderAddComponentButton } from './OrderAddComponentsButton';
import { OrderAddJobButton } from './OrderAddJobsButton';
import { OrderAddQuoteComponentsButton } from './OrderAddQuoteComponentsButton';
import { OrderAddQuoteBasicItemsButton } from './OrderAddQuoteBasicItemsButton';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

interface OrderAddItemButtonProps {
  variant?: ButtonProps['variant'];

  tree?: OrderTree;

  direction: 'row' | 'column';
}

export const OrderAddItemButton: FC<OrderAddItemButtonProps> = ({ direction, variant, tree }) => {
  const { t } = useTranslation(['orders']);

  const isSupplySheetEnabled = useFeatureFlag(FEATURE_FLAGS.QUOTE_SUPPLYSHEET);

  const treeContext = useOrderTreeContext();
  const { createLeaf, getDisplayedCurrentTree, getLastLeafOfNode } = tree || treeContext;

  const form = useFormContext<OrderEditForm>();

  const onClick = useCallback(() => {
    const { rootNodeId, nodes } = getDisplayedCurrentTree();
    const lastItemId = getLastLeafOfNode(rootNodeId);

    const { defaultVatRate } = nodes[rootNodeId];

    const id = createLeaf(rootNodeId, lastItemId, { vatRate: defaultVatRate });

    const formValues: Partial<OrderEditForm> = {};
    formValues[getOrderItemFieldName(id, 'vatRate')] = formatNumberToVatRate(defaultVatRate);

    form.setFormValues(formValues);
  }, [createLeaf, form, getDisplayedCurrentTree, getLastLeafOfNode]);

  return (
    <Flex direction={direction} gap={3} w="fit-content" alignItems="center" justifyContent="center">
      <Button w="100%" variant={variant} onClick={onClick}>
        {t('orders:actions.addItem')}
      </Button>

      <ActionMenu
        isLazy={false}
        w="100%"
        buttonProps={{
          leftIcon: null,
          variant: 'secondary',
          children: t('orders:actions.importFromLibrary'),
        }}
      >
        <OrderAddJobButton tree={tree || treeContext} />
        <OrderAddComponentButton tree={tree || treeContext} />
      </ActionMenu>

      {isSupplySheetEnabled && (
        <ActionMenu
          isLazy={false}
          w="100%"
          buttonProps={{
            leftIcon: null,
            variant: 'secondary',
            children: t('orders:actions.importFromQuote'),
          }}
        >
          <OrderAddQuoteBasicItemsButton tree={tree || treeContext} />
          <OrderAddQuoteComponentsButton tree={tree || treeContext} />
        </ActionMenu>
      )}
    </Flex>
  );
};
