import type { ICredit, ICreditWithRelations, IUpdateTagsDTO } from '@graneet/business-logic';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';

import { usePdfVersionsTanStackQuery } from 'features/pdf/hooks/usePdfVersions';
import { apiNew } from 'features/api/services/apiNew.service';

const CREDIT_PATH = '/credits';

const creditKeyFactory = createQueryKeys('credits', {
  getById: (id: number) => ({
    queryKey: [CREDIT_PATH, id],
    queryFn: () => apiNew.get<never, ICreditWithRelations>(`${CREDIT_PATH}/${id}`),
  }),
});

export function useCredit(id: number) {
  const suspenseQuery = useSuspenseQuery(creditKeyFactory.getById(id));

  usePdfVersionsTanStackQuery({ archived: [], current: suspenseQuery.data }, suspenseQuery.refetch);
  return suspenseQuery;
}

export function useEditCreditTags() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateTagsDTO }) =>
      apiNew.patch<IUpdateTagsDTO, ICredit>(`${CREDIT_PATH}/${params.id}/tags`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (credit) => {
      toast.success(t('global:words.c.success'), t('global:changeTags.success'));
      await queryClient.resetQueries(creditKeyFactory.getById(credit.id));
    },
  });
}
