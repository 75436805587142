import { Box, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import type { ILedger, LEDGER_TYPES } from '@graneet/business-logic';
import {
  IconAdvanced,
  Modal,
  NumberField,
  SimpleHelpIcon,
  SingleSelectField,
  TextField,
  Tooltip,
} from '@graneet/lib-ui';
import { Form, useForm, useFormStatus, useOnChangeValues } from 'graneet-form';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LedgerNumberPreview } from '../LedgerNumberPreview';
import type {
  ClientCounterSizeOption,
  ClientDateOption,
  ClientResetCounterOptions,
  ClientSeparatorOption,
  LedgerModalFormValues,
} from '../../services/ledger.util';
import { existingLedgerToFormValues, formValuesToLedger } from '../../services/ledger.util';

import { useCreateOrUpdateLedger } from 'features/ledger/services/ledger.api';
import { Rule } from 'features/form/rules/Rule';

type CreateOrUpdateLedgerModalProps = {
  isOpen: boolean;
  onClose(): void;
  ledger?: ILedger;
  ledgerType: LEDGER_TYPES;
};

const Label = ({ value, helpText }: { value: string; helpText?: string }) => (
  <HStack mt={4} mb={3} alignItems="center">
    <Text fontSize={16} fontWeight="semibold">
      {value}
    </Text>
    {helpText && <IconAdvanced icon={<SimpleHelpIcon mb={0} />} tooltip={helpText} />}
  </HStack>
);

export const CreateOrUpdateLedgerModal: FC<CreateOrUpdateLedgerModalProps> = ({
  ledger,
  ledgerType,
  isOpen,
  onClose,
}) => {
  const form = useForm<LedgerModalFormValues>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { t } = useTranslation(['ledger', 'global']);
  // Used to avoid updating the ledger counter if it hasn't changed,
  const [ledgerStateBeforeEdit, setLedgerStateBeforeEdit] = useState('');

  const DateOptions = [
    { label: t('ledger:settings.editModal.select.date.YYYYMM'), value: 'YYYYMM' },
    { label: t('ledger:settings.editModal.select.date.YYMM'), value: 'YYMM' },
    { label: t('ledger:settings.editModal.select.date.MMYYYY'), value: 'MMYYYY' },
    { label: t('ledger:settings.editModal.select.date.MMYY'), value: 'MMYY' },
    { label: t('ledger:settings.editModal.select.date.YYYY'), value: 'YYYY' },
    { label: t('ledger:settings.editModal.select.date.YY'), value: 'YY' },
    { label: t('ledger:settings.editModal.select.date.NONE'), value: 'NONE' },
  ] satisfies { label: string; value: ClientDateOption }[];

  const SeparatorOptions = [
    { label: t('ledger:settings.editModal.select.separator.-'), value: '-' },
    { label: t('ledger:settings.editModal.select.separator..'), value: '.' },
    { label: t('ledger:settings.editModal.select.separator./'), value: '/' },
    { label: t('ledger:settings.editModal.select.separator._'), value: '_' },
    { label: t('ledger:settings.editModal.select.separator.NONE'), value: 'NONE' },
  ] satisfies { label: string; value: ClientSeparatorOption }[];

  const CounterSizeOptions = [
    { label: t('ledger:settings.editModal.select.counterSize.NONE'), value: 'NONE' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ] satisfies { label: string; value: ClientCounterSizeOption }[];

  const formValues = useOnChangeValues(form, [
    'nextCounterNumber',
    'separator',
    'prefix',
    'counterSize',
    'dateFormat',
    'resetCounterEvery',
  ]);

  const activeResetCounterOptions = useMemo(
    () =>
      [
        ...(formValues.dateFormat?.includes('M')
          ? [{ label: t('ledger:settings.editModal.select.counterReset.MONTH'), value: 'MONTH' }]
          : []),
        ...(formValues.dateFormat?.includes('Y')
          ? [{ label: t('ledger:settings.editModal.select.counterReset.YEAR'), value: 'YEAR' }]
          : []),
        { label: t('ledger:settings.editModal.select.counterReset.NONE'), value: 'NONE' },
      ] as { label: string; value: ClientResetCounterOptions }[],
    [formValues.dateFormat, t],
  );

  useEffect(() => {
    if (
      (formValues.resetCounterEvery === 'MONTH' && !formValues.dateFormat?.includes('M')) ||
      (formValues.resetCounterEvery === 'YEAR' && !formValues.dateFormat?.includes('Y'))
    ) {
      form.setFormValues({ resetCounterEvery: activeResetCounterOptions[0]!.value });
    }
  }, [activeResetCounterOptions, form, formValues.dateFormat, formValues.resetCounterEvery]);

  useEffect(() => {
    form.setFormValues(existingLedgerToFormValues(ledger));
    setLedgerStateBeforeEdit(JSON.stringify(ledger?.state));
  }, [form, ledger]);

  const createOrUpdateLedgerMutation = useCreateOrUpdateLedger();

  const handleSubmit = useCallback(async () => {
    const { config, format, state } = formValuesToLedger(formValues, false);

    // Compare the ledger state before edit. If it's the same, we don't update it.
    const stateHasChanged = ledgerStateBeforeEdit !== JSON.stringify(state);

    createOrUpdateLedgerMutation.mutate(
      {
        type: ledgerType,
        isEnabled: true,
        config,
        format,
        ...(stateHasChanged && { state }),
      },
      {
        onSuccess: onClose,
      },
    );
  }, [createOrUpdateLedgerMutation, formValues, ledgerStateBeforeEdit, ledgerType, onClose]);

  const numberPreview = useMemo(() => formValuesToLedger(formValues, true).preview, [formValues]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t(`ledger:settings.type.${ledgerType}`)}
      size="xl"
      scrollBehavior="inside"
    >
      <Form form={form}>
        <VStack alignItems="start" gap={0}>
          <Label value={t('ledger:settings.editModal.sections.preview')} />

          <LedgerNumberPreview value={numberPreview} />

          <Label
            value={t('ledger:settings.editModal.sections.prefix')}
            helpText={t('ledger:settings.editModal.hints.prefix')}
          />

          <TextField<LedgerModalFormValues> textAlign="left" name="prefix" />
          <SimpleGrid columns={2} spacing={10} width="100%">
            <Box>
              <Label
                value={t('ledger:settings.editModal.sections.date')}
                helpText={t('ledger:settings.editModal.hints.date')}
              />
              <SingleSelectField<LedgerModalFormValues> name="dateFormat" options={DateOptions} isClearable={false} />
            </Box>
            <Box>
              <Label
                value={t('ledger:settings.editModal.sections.separator')}
                helpText={t('ledger:settings.editModal.hints.separator')}
              />
              <SingleSelectField<LedgerModalFormValues>
                name="separator"
                options={SeparatorOptions}
                isClearable={false}
              />
            </Box>
          </SimpleGrid>
          <Label
            value={t('ledger:settings.editModal.sections.counter')}
            helpText={t('ledger:settings.editModal.hints.counter')}
          />
          <NumberField<LedgerModalFormValues>
            type="number"
            label={t('ledger:settings.editModal.sections.counterNextValue')}
            textAlign="left"
            name="nextCounterNumber"
            min={1}
          >
            <Rule.IsHigherOrEqualThan amount={1} message={t('ledger:settings.editModal.errors.invalidCounter')} />
          </NumberField>
          <SimpleGrid columns={2} spacing={10} mt={4} width="100%">
            <Tooltip
              label={t('ledger:settings.editModal.hints.resetCounterConstraints')}
              shouldWrapChildren
              placement="top-end"
              isDisabled={activeResetCounterOptions.length > 1}
            >
              <SingleSelectField<LedgerModalFormValues>
                name="resetCounterEvery"
                label={t('ledger:settings.editModal.sections.counterReset')}
                isDisabled={activeResetCounterOptions.length <= 1}
                options={activeResetCounterOptions}
                isClearable={false}
              />
            </Tooltip>
            <Box>
              <SingleSelectField<LedgerModalFormValues>
                name="counterSize"
                label={t('ledger:settings.editModal.sections.counterSize')}
                options={CounterSizeOptions}
                isClearable={false}
              />
            </Box>
          </SimpleGrid>
        </VStack>
        <Modal.Close />

        <Modal.PrimaryButton
          isLoading={createOrUpdateLedgerMutation.isPending}
          isDisabled={!isFormValid}
          onClick={handleSubmit}
        >
          {ledger?.isEnabled
            ? t('ledger:settings.editModal.buttons.saveLedger')
            : t('ledger:settings.editModal.buttons.createLedger')}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
