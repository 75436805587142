import type { FC } from 'react';
import { useCallback, useState } from 'react';
import type { FieldValue } from 'graneet-form';
import { Rule } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import { INVITEE_EMAIL_AVAILABILITY } from '@graneet/business-logic';

import { useIsEmailAvailable } from '../services/user-invitation.api';

export const IsInviteeEmailAvailable: FC = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState(INVITEE_EMAIL_AVAILABILITY.ERROR);

  const isEmailAvailableMutation = useIsEmailAvailable();

  const testEmail = useCallback(
    async (value: FieldValue) => {
      if (!value || value.length === 0) {
        return true;
      }

      const status = await isEmailAvailableMutation.mutateAsync({ email: value });

      if (status.code !== INVITEE_EMAIL_AVAILABILITY.AVAILABLE) {
        setCode(status.code);
      }
      return status.available;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEmailAvailableMutation.mutateAsync],
  );

  return (
    <Rule
      message={t(`onboarding:invitation.userInvitationInformation.${code}` as any)}
      validationFn={testEmail}
      isDebounced
    />
  );
};
