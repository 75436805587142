import type { FC } from 'react';

import { GraneetButton as Button, type GraneetButtonProps } from '../../Button/Button';
import { SimpleCloseIcon } from '../../../Icons/v2/SimpleCloseIcon';

import { PopUpMenuItem } from './PopUpMenuItem';

export interface PopUpSumItemProps extends GraneetButtonProps {
  text: string;
}

export const PopUpSumItem: FC<PopUpSumItemProps> = ({ text, ...props }) => (
  <PopUpMenuItem>
    <Button variant="ghost" rightIcon={<SimpleCloseIcon />} {...props}>
      {text}
    </Button>
  </PopUpMenuItem>
);
