import type { QueryClient } from '@tanstack/react-query';

import { BUDGET_FACTORY_NAME } from 'features/budget/services/budget.factory-name';
import { PROGRESS_STATEMENT_FACTORY_NAME } from 'features/progress-statement/services/progress-statement.factory-name';
import { PROJECT_FACTORY_NAME } from 'features/project/services/project.factory-name';
import { INVOICE_FACTORY_NAME } from 'features/invoice/services/invoice.factory-name';
import { FINAL_STATEMENT_FACTORY_NAME } from 'features/final-statement/services/final-statement.factory-name';
import { DOWN_PAYMENT_FACTORY_NAME } from 'features/down-payment/services/down-payment.factory-name';
import { CONTRACT_FACTORY_NAME } from 'features/contract/services/contract.factory-name';
import { SUB_PROJECT_FACTORY_NAME } from 'features/sub-project/services/sub-project.factory-name';
import { STATEMENT_FACTORY_NAME } from 'features/statement/services/statement.factory-name';

export async function invalidateStatementRelatedQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    predicate: (query) =>
      typeof query.queryKey[0] === 'string' &&
      [
        FINAL_STATEMENT_FACTORY_NAME,
        INVOICE_FACTORY_NAME,
        PROJECT_FACTORY_NAME,
        PROGRESS_STATEMENT_FACTORY_NAME,
        DOWN_PAYMENT_FACTORY_NAME,
        CONTRACT_FACTORY_NAME,
        BUDGET_FACTORY_NAME,
        SUB_PROJECT_FACTORY_NAME,
        STATEMENT_FACTORY_NAME,
      ].includes(query.queryKey[0]),
    exact: false,
  });
}
