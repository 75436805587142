import type { ButtonColorScheme, BadgeTheme, PaginatedTableProps } from '@graneet/lib-ui';
import {
  PaginatedTable,
  Badge,
  PriceAdvanced,
  formatDataOrEmpty,
  Button,
  ListingLayout,
  generateColorFromString,
  SimpleCashOutFlowIcon,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { ISupplierInvoice, SupplierInvoicePaginatedResponse } from '@graneet/business-logic';
import { formatDateOrEmpty, EXPORT_ENTITY, PERMISSION, SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { SupplierInvoiceBatchStatusActions } from 'features/supplier-invoice/components/SupplierInvoiceBatchStatusActions/SupplierInvoiceBatchStatusActions';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { ExportButton } from 'features/export/components/ExportButton/ExportButton';
import { usePermissions } from 'features/role/hooks/usePermissions';
import {
  useSupplierInvoiceAvailableTags,
  useSupplierInvoices,
} from 'features/supplier-invoice/services/supplier-invoice.api';
import {
  SUPPLIER_INVOICES_STATUS_COLOR,
  SUPPLIER_INVOICE_LATE_DAYS_RANGES,
  SUPPLIER_INVOICE_LATE_DAYS_RANGES_VALUES,
} from 'features/supplier-invoice/constants/supplier-invoice.constant';
import { SupplierInvoiceStatusBadge } from 'features/supplier-invoice/components/badges/SupplierInvoicesStatusBadge';
import { SupplierInvoicePaymentsEditModal } from 'features/supplier-invoice/components/modals/SupplierInvoicePaymentsEditModal';
import { TagsBadges } from 'features/common/components/TagsBadges';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

enum CashOutFlowStatuses {
  TO_PAY = 'TO_PAY',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID = 'PAID',
}

// This is front side rule. Backend always allows adding payment
const canAddNewPayment = (supplierInvoice: ISupplierInvoice): boolean =>
  supplierInvoice.status === SUPPLIER_INVOICE_STATUS.TO_PAY ||
  supplierInvoice.status === SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID;

const isSupplierInvoiceNotFullyPayed = (supplierInvoice?: ISupplierInvoice): boolean =>
  !!supplierInvoice && supplierInvoice.status !== SUPPLIER_INVOICE_STATUS.PAID;

const NameCell = ({ data }: { data?: ISupplierInvoice }) =>
  data ? (
    <>
      {formatDataOrEmpty(data.name)}
      <TagsBadges tags={data.tags} />
    </>
  ) : null;

const RemainingToBePaidIncVATCell = ({ data }: { data?: ISupplierInvoice }) =>
  data ? <PriceAdvanced amount={data.remainingToBePaidIncVAT} /> : null;

const StatusCell = ({ data }: { data?: ISupplierInvoice }) =>
  data ? <SupplierInvoiceStatusBadge status={data.status} /> : null;

const LateDaysCell = ({ data }: { data?: ISupplierInvoice }) => {
  const { t } = useTranslation(['supplierInvoices']);

  return data ? (
    <Box opacity={data.lateDays && data.lateDays > 0 && isSupplierInvoiceNotFullyPayed(data) ? 1 : 0}>
      <Badge showDot colorScheme="red" borderRadius="full">
        {t('supplierInvoices:cells.lateDays', { count: data.lateDays ?? 0 })}
      </Badge>
    </Box>
  ) : null;
};

export const ViewCashOutflowsScreen: FC = () => {
  const { t } = useTranslation(['supplierInvoices', 'cashOutflow', 'global']);
  const { updateHeaderTitle } = useHeaderContext();

  const selectedSupplierInvoiceRef = useRef<undefined | ISupplierInvoice>();
  const availableStatuses = useMemo(
    () =>
      (Object.keys(CashOutFlowStatuses) as Array<keyof typeof CashOutFlowStatuses>).map((status) => ({
        label: t(`supplierInvoices:statuses.${status}`),
        value: CashOutFlowStatuses[status],
        colorScheme: SUPPLIER_INVOICES_STATUS_COLOR[status],
      })),
    [t],
  );

  const availableLateDaysRanges = useMemo(
    () =>
      (Object.keys(SUPPLIER_INVOICE_LATE_DAYS_RANGES) as Array<keyof typeof SUPPLIER_INVOICE_LATE_DAYS_RANGES>).map<{
        value: Record<string, number>;
        colorScheme: ButtonColorScheme;
        label: string;
        theme: BadgeTheme;
      }>((range) => ({
        value: SUPPLIER_INVOICE_LATE_DAYS_RANGES_VALUES[range] as Record<string, number>,
        colorScheme: 'red',
        label: t(`cashOutflow:filters.lateDaysRanges.${range}`),
        theme: 'ghost',
      })),
    [t],
  );
  const { createRedirectionWithFilters } = useFiltersQuery();

  const tags = useSupplierInvoiceAvailableTags();
  const supplierInvoices = useSupplierInvoices({
    forcedFilters: {
      status: [SUPPLIER_INVOICE_STATUS.PAID, SUPPLIER_INVOICE_STATUS.PARTIALLY_PAID, SUPPLIER_INVOICE_STATUS.TO_PAY],
    },
  });

  const hasPaySupplierInvoicePermission = usePermissions([PERMISSION.PAY_SUPPLIER_INVOICE]);
  const markPaymentModal = useDisclosure();

  const selectLabel = useCallback(
    (numberSelectedItems: number) => {
      if (numberSelectedItems === 0) {
        return '';
      }
      return t('cashOutflow:banner.selection', { count: numberSelectedItems });
    },
    [t],
  );

  const allSelectedLabel = useCallback(
    (totalNumberOfItems?: number): string => t('cashOutflow:banner.allSelectedLabel', { count: totalNumberOfItems }),
    [t],
  );

  const openModalMarkPayment = useCallback(
    (supplierInvoice: ISupplierInvoice) => () => {
      selectedSupplierInvoiceRef.current = supplierInvoice;
      markPaymentModal.onOpen();
    },
    [markPaymentModal],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(t('cashOutflow:cashOutflow_plural'), []);
  }, [t, updateHeaderTitle]);

  const availableTags = useMemo(
    () =>
      tags.data.map<{ value: string; label: string }>((tag) => ({
        value: tag,
        label: tag,
        badgeColor: generateColorFromString(tag),
      })),
    [tags.data],
  );

  const payCell = useCallback(
    ({ data }: { data?: ISupplierInvoice }) =>
      data ? (
        <Box opacity={canAddNewPayment(data) && hasPaySupplierInvoicePermission ? 1 : 0}>
          <Button onClick={openModalMarkPayment(data)} variant="outline" tooltip={t('cashOutflow:actions.toPay')}>
            <i className="ri-hand-coin-line ri-lg" />
          </Button>
        </Box>
      ) : null,
    [hasPaySupplierInvoicePermission, openModalMarkPayment, t],
  );

  const columnDefs = useMemo<PaginatedTableProps<SupplierInvoicePaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'invoiceNumber',
        headerName: t('cashOutflow:fields.invoiceNumber'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.invoiceNumber),
      },
      {
        field: 'name',
        headerName: t('cashOutflow:fields.name'),
        sortable: true,
        cellRenderer: NameCell,
      },
      {
        field: 'billingDate',
        headerName: t('cashOutflow:fields.billingDate'),
        sortable: true,
        valueFormatter: (v) => formatDateOrEmpty(v.data?.billingDate),
      },
      {
        field: 'supplier',
        headerName: t('cashOutflow:fields.supplier'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.supplier?.name),
      },
      {
        field: 'remainingToBePaidIncVAT',
        headerName: t('cashOutflow:fields.remainingToBePaidIncVAT'),
        sortable: true,
        cellRenderer: RemainingToBePaidIncVATCell,
      },
      {
        field: 'status',
        headerName: t('cashOutflow:fields.status'),
        sortable: true,
        cellRenderer: StatusCell,
      },
      {
        field: 'paymentDate',
        headerName: t('cashOutflow:fields.paymentDate'),
        sortable: true,
        valueFormatter: (v) => formatDateOrEmpty(v.data?.paymentDate),
      },
      {
        field: 'lateDays',
        headerName: t('cashOutflow:fields.lateDays'),
        sortable: true,
        cellRenderer: LateDaysCell,
      },
      {
        field: 'pay' as any,
        headerName: '',
        maxWidth: 80,
        resizable: false,
        suppressMovable: true,
        cellRenderer: payCell,
      },
    ],
    [payCell, t],
  );

  return (
    <>
      {selectedSupplierInvoiceRef.current && (
        <SupplierInvoicePaymentsEditModal
          key={selectedSupplierInvoiceRef.current.id}
          onClose={markPaymentModal.onClose}
          isOpen={markPaymentModal.isOpen}
          supplierInvoice={selectedSupplierInvoiceRef.current}
          onSubmit={supplierInvoices.refetch}
        />
      )}

      <ListingLayout
        pagination={supplierInvoices}
        search={{
          placeholder: t('cashOutflow:actions.search'),
        }}
        filters={[
          {
            type: 'checkbox',
            name: 'status',
            availableValues: availableStatuses,
            label: t('cashOutflow:filters.status'),
          },
          {
            type: 'checkbox-range',
            name: 'lateDays',
            availableValues: availableLateDaysRanges,
            label: t('cashOutflow:filters.lateDays'),
          },
          {
            type: 'multi',
            name: 'tags',
            availableValues: availableTags,
            label: t('global:words.tags'),
            noValueMessage: t('global:tags.noLabel'),
            placeholder: t('global:words.c.select'),
          },
          {
            type: 'date',
            name: 'billingDate',
            label: t('cashOutflow:filters.date'),
          },
          {
            type: 'date',
            name: 'paymentDate',
            label: t('cashOutflow:filters.paymentDate'),
          },
        ]}
        content={
          <PaginatedTable
            gridId="supplier-invoice"
            pagination={supplierInvoices}
            columnDefs={columnDefs}
            zeroState={{
              icon: <SimpleCashOutFlowIcon boxSize={45} />,
              label: t('cashOutflow:errors.noCashOutflow'),
            }}
            emptyState={{
              label: t('cashOutflow:errors.noResult'),
            }}
            sums={{
              remainingToBePaidIncVAT: {
                label: t('cashOutflow:fields.remainingToBePaidIncVAT'),
                type: 'currency',
              },
            }}
            countLabel={(count) => t('cashOutflow:cashOutflow', { count })}
            rowSelection="multiple"
            selectionComponentProps={{
              allSelectedLabel,
              selectLabel,
              renderActions: (props) => (
                <QueryWrapper>
                  <SupplierInvoiceBatchStatusActions {...props} onStatusesChanged={supplierInvoices.refetch} />
                  <ExportButton entity={EXPORT_ENTITY.SUPPLIER_INVOICE_PAYMENT} {...props} />
                </QueryWrapper>
              ),
            }}
            onRowClicked={(event) => {
              if (event.data?.id && event.api.getFocusedCell()?.column.getId() !== 'pay') {
                createRedirectionWithFilters(`/purchases/cash-outflows/${event.data.id}`)();
              }
            }}
          />
        }
      />
    </>
  );
};
