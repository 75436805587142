import type { StatusPanelDef } from '@ag-grid-community/core';
import { useMemo } from 'react';

import { AgGridQuoteComponentsByTypeFooter } from '../components/AgGridQuoteComponentsByTypeFooter';

export const useStatusBar = (itemWithoutComponentAmount: string, totalFlatCostAmount: string) =>
  useMemo<{
    statusPanels: StatusPanelDef[];
  }>(
    () => ({
      statusPanels: [
        {
          statusPanel: AgGridQuoteComponentsByTypeFooter,
          statusPanelParams: {
            itemWithoutComponentAmount,
            totalFlatCostAmount,
          },
        },
      ],
    }),
    [itemWithoutComponentAmount, totalFlatCostAmount],
  );
