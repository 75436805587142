import { Text, Box } from '@chakra-ui/react';
import type { IContact, IGroupedContact } from '@graneet/business-logic';
import { isGroupedContact, ASSOCIATION_TYPE } from '@graneet/business-logic';
import { Fragment } from 'react';
import { BuildingIcon, GroupIcon, Table } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { ContactCardLine } from './ContactCardLine';

interface ContactCardDetailsProps<T extends IContact> {
  contacts: (T | IGroupedContact<T>)[];
  isContactDefaultEmailRecipient: (contact: T) => boolean;
  onUpsert: (contact: IContact) => void;
  onDelete: (contact: IContact) => void;
  onDissociate: (contactId: string) => void;
  onDefaultEmailRecipientChange?: (contactId: string, isDefaultEmailRecipient: boolean) => void;
  emptyState: string;
}

export const ContactCardDetails = <T extends IContact>({
  contacts,
  isContactDefaultEmailRecipient,
  onUpsert,
  onDelete,
  onDissociate,
  onDefaultEmailRecipientChange,
  emptyState,
}: ContactCardDetailsProps<T>) => {
  const { t } = useTranslation(['contacts']);

  return (
    <>
      <Box w="100%">
        {contacts && contacts.length === 0 && (
          <Box w="100%">
            <Text>{emptyState}</Text>
          </Box>
        )}
      </Box>
      <Table
        templateColumns={['minmax(8rem, 1fr)', 'minmax(8rem, 1fr)', 'minmax(4rem, 1fr)', '4rem']}
        noCard
        showNoResult={false}
      >
        {contacts.map((contactOrGroupedContact) => {
          if (isGroupedContact(contactOrGroupedContact)) {
            return (
              <Fragment key={contactOrGroupedContact.id}>
                {(contacts[0] as IGroupedContact).type !== ASSOCIATION_TYPE.CLIENT && (
                  <Table.Row backgroundColor="gray.100">
                    <Table.Cell variant="header">
                      {contactOrGroupedContact.type === ASSOCIATION_TYPE.CLIENT && (
                        <Box display="flex" justifyItems="center" alignItems="center">
                          <BuildingIcon width="1.3rem" height="1.3rem" />
                        </Box>
                      )}
                      {contactOrGroupedContact.type === ASSOCIATION_TYPE.OTHER && (
                        <Box display="flex" justifyItems="center" alignItems="center">
                          <GroupIcon color="gray.800" width="1.3rem" height="1.3rem" />
                        </Box>
                      )}
                      <Text marginLeft="1.5rem" fontWeight="600" textColor="gray.800">
                        {contactOrGroupedContact.type === ASSOCIATION_TYPE.OTHER
                          ? t('contacts:fields.other')
                          : contactOrGroupedContact.name}
                      </Text>
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                    <Table.Cell />
                  </Table.Row>
                )}

                {contactOrGroupedContact.contacts.map((contact) => (
                  <ContactCardLine
                    key={contact.id}
                    isContactDefaultEmailRecipient={isContactDefaultEmailRecipient}
                    onUpsert={onUpsert}
                    onDissociate={onDissociate}
                    onDelete={onDelete}
                    onDefaultEmailRecipientChange={onDefaultEmailRecipientChange}
                    contact={contact}
                  />
                ))}
              </Fragment>
            );
          }
          return (
            <ContactCardLine
              key={contactOrGroupedContact.id}
              isContactDefaultEmailRecipient={isContactDefaultEmailRecipient}
              onUpsert={() => onUpsert(contactOrGroupedContact)}
              onDissociate={onDissociate}
              onDelete={onDelete}
              onDefaultEmailRecipientChange={onDefaultEmailRecipientChange}
              contact={contactOrGroupedContact}
            />
          );
        })}
      </Table>
    </>
  );
};
