import type { QuoteComponentObject, QuoteNodeObject } from '@org/quotation-lib';
import type { RowClassParams } from '@ag-grid-community/core';
import { isEmpty, isNil } from 'lodash-es';
import type { IComponentTypesListingDTO } from '@graneet/business-logic';

import { useStore } from 'store/store';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

const isEmptyValue = (value: string | number | undefined | null) => isNil(value) || isEmpty(value);

const isEmptyRow = (params: RowClassParams<QuoteNodeObject>, componentTypes: IComponentTypesListingDTO[]) => {
  if (params.data?.content.type === 'QuoteLot') {
    return false;
  }
  let unit = params.data?.content.unit;
  if (params.data?.content.type === 'QuoteComponent') {
    const componentType = componentTypes.find(
      (ct) => ct.id === (params.data?.content as QuoteComponentObject).componentTypeId,
    );
    if (!isNil(componentType?.unit)) {
      unit = componentType.unit;
    }
    return isEmptyValue(params.data?.content.amount) || isEmptyValue(params.data?.content.quantityFormula?.quantity);
  }
  if (params.data?.content.type === 'QuoteSubItem') {
    return isEmptyValue(params.data?.content.amount) || isEmptyValue(params.data?.content.quantityFormula?.quantity);
  }
  return (
    isEmptyValue(params.data?.content.amount) ||
    isEmptyValue(params.data?.content.denomination) ||
    isEmptyValue(unit) ||
    isEmptyValue(params.data?.content.quantityFormula?.quantity)
  );
};

export const useGetRowClass = () => {
  const componentTypes = useStore((store) => store.quoteComponentTypes);
  const { quote } = useQuote();

  return (params: RowClassParams<QuoteNodeObject>): string | undefined => {
    const classes = ['row'];

    if (!params.data) return 'row row-fill-empty';

    if (!params.data) return classes.join(' ');

    if (params.data.parentId) {
      if (
        !(
          params.data.parentId === quote?.getTree().getRootNodeId() ||
          params.data.parentId === quote?.getTree().getHiddenCostNodeId()
        ) &&
        !(
          params.data.content.type === 'QuoteHiddenCostItem' ||
          params.data.content.type === 'QuoteBasicItem' ||
          params.data.content.type === 'QuoteOptionalItem'
        )
      ) {
        classes.push('row-no-border');
      }
      if (
        (params.data.parentId === quote?.getTree().getRootNodeId() ||
          params.data.parentId === quote?.getTree().getHiddenCostNodeId()) &&
        !params.data.nextSiblingId
      ) {
        classes.push('row-border-bottom');
      }
    }

    if (
      params.data.content.type === 'QuoteOptionalItem' ||
      (params.data.content.type === 'QuoteLot' && params.data.content.isEveryChildOptional)
    ) {
      classes.push('row-optional');
    }

    if (params.data.content.type === 'QuoteSubItem' || params.data.content.type === 'QuoteComponent') {
      classes.push('row-sub-item');
    }

    if (params.data.content.type === 'QuoteLot') {
      classes.push('row-lot');
    }

    if (isEmptyRow(params, componentTypes)) {
      classes.push('row-empty');
    }

    return classes.join(' ');
  };
};
