import type { IVatDistribution } from '../vat/vat-distribution.type';
import type { IAccountingExport } from '../accounting/accounting-export.type';
import type { IVatBases, IVatDiscountedBases } from '../vat/vat-bases.type';
import type { DEADLINE_TYPE } from '../common/deadline-type.constant';
import type { IPdf } from '../pdf/pdf.type';
import type { IClient } from '../client/client.type';
import type { IPaymentMethod } from '../payment-method/payment-method.type';
import type { IInvoice } from '../invoice/invoice.type';
import type { IProgressStatement } from '../progress-statement/progress-statement.type';
import type { IDownPayment } from '../down-payment/down-payment.type';
import type { ICompany } from '../company/company.type';
import type { IProject } from '../project/project.type';
import type { ISubProject } from '../sub-project/sub-project.type';
import type { RequiredByKeys } from '../common/types/object.type';

export enum CREDIT_STATUS {
  COMPLETED = 'COMPLETED',
}

export interface ICredit {
  id: number;

  name: string;

  historyFamilyId: string;

  invoiceNumber: string;

  note: string;

  status: CREDIT_STATUS;

  creditDate: Date;

  completionDate: Date | null;

  amountExVAT: number;

  amountIncVAT: number;

  vatDistribution: IVatDistribution | IVatBases | IVatDiscountedBases;

  deadlineType: DEADLINE_TYPE;

  paymentTerm: number;

  pdf?: IPdf | null;

  client?: IClient;

  paymentMethod?: IPaymentMethod;

  invoice?: IInvoice | null;

  progressStatement?: IProgressStatement | null;

  downPayment?: IDownPayment | null;

  company?: ICompany;

  project?: IProject | null;

  subProject?: ISubProject | null;

  accountingExport?: IAccountingExport | null;

  tags: string[];
}

export type ICreditWithRelations = RequiredByKeys<
  ICredit,
  | 'client'
  | 'paymentMethod'
  | 'invoice'
  | 'progressStatement'
  | 'downPayment'
  | 'company'
  | 'project'
  | 'subProject'
  | 'pdf'
>;
