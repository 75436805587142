import { MARGIN_TYPE } from '@org/quotation-lib';
import type { CellClassParams, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';
import Big from 'big.js';

import { MarginCell } from '../../components/columns/MarginCell/MarginCell';
import { MarginEditorCell } from '../../components/columns/MarginCell/MarginEditorCell';
import type { AGColDef } from '../../types';

import { useQuoteLotUpdateMargin } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateMargin';
import { useQuoteBasicItemUpdateTotalMargin } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateTotalMargin';
import { useQuoteComponentUpdateTotalMargin } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateTotalMargin';
import { useQuoteSubItemUpdateTotalMargin } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdateTotalMargin';
import { useQuoteOptionalItemUpdateTotalMargin } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateTotalMargin';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useTotalMarginColumnDef(isEditable: boolean) {
  const quoteLotUpdateMargin = useQuoteLotUpdateMargin();
  const quoteBasicItemUpdateTotalMargin = useQuoteBasicItemUpdateTotalMargin();
  const quoteSubItemUpdateTotalMargin = useQuoteSubItemUpdateTotalMargin();
  const quoteComponentUpdateTotalMargin = useQuoteComponentUpdateTotalMargin();
  const quoteOptionalItemUpdateTotalMargin = useQuoteOptionalItemUpdateTotalMargin();

  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'totalMargin',
      width,
      index,
      headerName: label,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressMovable: false,
      cellClass: (params: CellClassParams) => {
        if (params.node.footer || params.data?.content.type === 'QuoteLot') return 'no-focus read-only';
        switch (params.data?.content.type) {
          case 'QuoteLot':
          case 'QuoteBasicItem':
          case 'QuoteSubItem':
          case 'QuoteComponent':
          case 'QuoteOptionalItem':
            return '';
          default:
            return 'no-focus read-only';
        }
      },
      editable: (params) => {
        if (!isEditable) return false;
        switch (params.data?.content.type) {
          case 'QuoteLot':
            return false;
          case 'QuoteBasicItem':
          case 'QuoteSubItem':
          case 'QuoteComponent':
          case 'QuoteOptionalItem':
            return true;
          default:
            return false;
        }
      },
      sortable: false,
      cellStyle: { textAlign: 'right' },
      cellRenderer: MarginCell,
      cellEditor: MarginEditorCell,
      valueFormatter: (params) => (params.value ? new Big(params.value).toFixed(2) : '-'),
      valueGetter: (params) => {
        if (!params.data) {
          return '-';
        }

        switch (params.data.content.type) {
          case 'QuoteComponent':
          case 'QuoteBasicItem':
          case 'QuoteHiddenCostItem':
          case 'QuoteOptionalItem':
          case 'QuoteSubItem':
          case 'QuoteLot':
            return params.data.content.margin?.totalMargin;
          default:
            throw new Error('Type unknown');
        }
      },
      valueSetter: (params) => {
        const newValue = params.newValue?.replace(',', '.');
        if (!newValue) {
          return false;
        }

        try {
          const value = Big(newValue);
          if (value.eq(0)) {
            return false;
          }
        } catch {
          return false;
        }

        if (params.oldValue?.toString() === newValue?.toString()) {
          return false;
        }

        switch (params.data?.content.type) {
          case 'QuoteLot': {
            executeCommand(quoteLotUpdateMargin(params.data.id, newValue, MARGIN_TYPE.TOTAL_MARGIN), quote);
            return true;
          }
          case 'QuoteBasicItem': {
            executeCommand(quoteBasicItemUpdateTotalMargin(params.data.id, newValue), quote);
            return true;
          }
          case 'QuoteOptionalItem': {
            executeCommand(quoteOptionalItemUpdateTotalMargin(params.data.id, newValue), quote);
            return true;
          }
          case 'QuoteSubItem': {
            executeCommand(quoteSubItemUpdateTotalMargin(params.data.id, newValue), quote);
            return true;
          }
          case 'QuoteComponent': {
            executeCommand(quoteComponentUpdateTotalMargin(params.data.id, newValue), quote);
            return true;
          }
          default:
            return false;
        }
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [
      isEditable,
      executeCommand,
      quoteLotUpdateMargin,
      quote,
      quoteBasicItemUpdateTotalMargin,
      quoteOptionalItemUpdateTotalMargin,
      quoteSubItemUpdateTotalMargin,
      quoteComponentUpdateTotalMargin,
    ],
  );
}
