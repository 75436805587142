import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Wizard, useDrawersStack, useDrawer, useToast } from '@graneet/lib-ui';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';

import { EditQuoteLotsStep } from './_steps/EditQuoteLotsStep';

import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { useData } from 'features/api/hooks/useData';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { getQuoteById } from 'features/quote/services/quote.api';
import { QuoteUpdateSpinner } from 'features/quote/components/spinners/QuoteUpdateSpinner';
import { QuoteSalesViewSwitch } from 'features/quote/components/switches/QuoteSalesViewSwitch';
import { QUOTE_STEPS } from 'features/quote/constants/quotes.constant';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { Beacon } from 'features/beacon/components/Beacon';
import { formatQuoteWizardTitle } from 'features/quote/services/quote.formatting';
import { QuoteEditProvider } from 'features/quote/contexts/QuoteEditProvider.context';
import { QuoteDisplayProvider } from 'features/quote/contexts/QuoteDisplayProvider.context';

export const ViewQuotationDetailScreen: FC = () => {
  useHideNavbar();
  const history = useHistory();
  const { t } = useTranslation(['quote', 'global']);
  const toast = useToast();
  const { quoteId } = useParams<{ quoteId: string }>();
  const { state } = useLocation<{ currentQuoteVersionId: number }>();
  const quoteDataGetter = useDataGetter(getQuoteById, +quoteId);
  const quoteData = useData(quoteDataGetter);
  const wizardLabels = useWizardLabels({
    save: t('global:words.c.close'),
  });

  const quoteLotEditDefaultVatRateModal = useDisclosure();

  const drawersStackLibraryJobImport = useDrawersStack<'drawer-quote-lot'>();
  const drawerLibraryJobImport = useDrawer('drawer-quote-lot', drawersStackLibraryJobImport);
  const libraryJobImportDrawer = useMemo(
    () => ({
      drawersStack: drawersStackLibraryJobImport,
      drawer: drawerLibraryJobImport,
    }),
    [drawerLibraryJobImport, drawersStackLibraryJobImport],
  );

  const drawersStackQuoteLot = useDrawersStack();
  const drawerQuoteLot = useDrawer('drawer-lot', drawersStackQuoteLot);
  const quoteLotDrawer = useMemo(
    () => ({
      drawersStack: drawersStackQuoteLot,
      drawer: drawerQuoteLot,
    }),
    [drawerQuoteLot, drawersStackQuoteLot],
  );

  const drawersStackBatiprix = useDrawersStack();
  const drawerBatiprix = useDrawer('drawer-batiprix', drawersStackBatiprix);
  const batiprixDrawer = useMemo(
    () => ({
      drawersStack: drawersStackBatiprix,
      drawer: drawerBatiprix,
    }),
    [drawerBatiprix, drawersStackBatiprix],
  );

  const { data: quote } = quoteData;

  const onQuit = useCallback(() => {
    let currentQuoteId = +quoteId;
    if (state?.currentQuoteVersionId) currentQuoteId = state.currentQuoteVersionId;
    history.push(`/opportunities/quotes/${currentQuoteId}`);
  }, [history, quoteId, state]);

  // -- Handle invalid route param `quoteId`
  const quoteIdAsNumber = parseInt(quoteId, 10);
  const isQuoteIdInvalid = Number.isNaN(quoteIdAsNumber);

  useEffect(() => {
    if (isQuoteIdInvalid) {
      toast.error(t('quote:errors.quoteNotFound'));
      history.replace('/opportunities/quotes');
    }
  }, [isQuoteIdInvalid, history, t, toast]);

  if (isQuoteIdInvalid) {
    return null;
  }
  if (!quote) return null;

  return (
    <QuoteEditProvider quoteId={quoteIdAsNumber}>
      <QuoteDisplayProvider
        onOpenLotEditDrawer={drawerQuoteLot.onOpen}
        onOpenLotEditDefaultVatRateModal={quoteLotEditDefaultVatRateModal.onOpen}
        onOpenBatiprixImportDrawer={drawerBatiprix.onOpen}
        onOpenLibraryJobImportDrawer={drawerLibraryJobImport.onOpen}
      >
        <Wizard
          headerTitle={t('quote:viewQuote.viewTitle', {
            label: formatQuoteWizardTitle(quote),
          })}
          labels={wizardLabels}
          onQuit={onQuit}
          onFinish={onQuit}
        >
          <Wizard.Step name={QUOTE_STEPS.LOTS} displaySecondaryButton={false}>
            <Wizard.Placeholder placement={Wizard.PLACEMENT.HEADER_RIGHT}>
              <QuoteSalesViewSwitch />
            </Wizard.Placeholder>

            <Wizard.Placeholder placement={Wizard.PLACEMENT.HEADER_LEFT}>
              <QuoteUpdateSpinner />
            </Wizard.Placeholder>

            <EditQuoteLotsStep
              quoteLotDrawer={quoteLotDrawer}
              batiprixDrawer={batiprixDrawer}
              quoteLotEditDefaultVatRateModal={quoteLotEditDefaultVatRateModal}
              libraryJobImportDrawer={libraryJobImportDrawer}
              isReadOnlyView
            />
          </Wizard.Step>
          <Wizard.Placeholder placement={Wizard.PLACEMENT.FOOTER_HELP}>
            <Beacon top="-4rem" right="0.6rem" />
          </Wizard.Placeholder>
        </Wizard>
      </QuoteDisplayProvider>
    </QuoteEditProvider>
  );
};
