export interface SavingSpinnerTranslations {
  saving: string;
}

export const savingSpinnerTranslations: SavingSpinnerTranslations = {
  saving: 'Saving',
};

export const configureDefaultLabel = (translation: SavingSpinnerTranslations) => {
  Object.assign(savingSpinnerTranslations, translation);
};
