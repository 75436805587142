import {
  type IOrderUpdateDTO,
  type IOrderCreationDTO,
  type ISupplierInvoice,
  type IOrderResponseDTO,
  type IOrderItem,
  type IOrderLot,
  type IOrderLotDTO,
  type IOrderItemDTO,
  type IOrderRelations,
  type IOrderItemRecord,
  type IOrderLotRecord,
  type IComponentType,
  VAT_TYPE,
  DEFAULT_VAT_RATE,
} from '@graneet/business-logic';
import type { INodeRelation, TreeDataWithRelations } from '@graneet/lib-ui';
import { DATE_FORMAT_API, formatNumberToVatRate, formatVatRateToNumber } from '@graneet/lib-ui';
import dayjs from 'dayjs';
import { differenceBy, isNil, omit } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';
import type { FormContextApi } from 'graneet-form';

import type { OrderEditForm } from '../forms/order-edit-wizard';
import { getOrderItemFieldName } from '../forms/order-edit-wizard';
import type { InitialOrderTree, OrderTree } from '../hooks/useOrderTree';

export const mapCreateOrderDTO = (
  formValues: Omit<OrderEditForm, 'receiptFiles' | 'isOrderFilesDisplayedInPDFUpdate'>,
  treeState: TreeDataWithRelations<IOrderLotDTO, IOrderItemDTO>,
  mapAmountToNumber: (value: number | string) => number,
): IOrderCreationDTO => {
  const {
    amountExVAT,
    vatRate,
    typeId,
    supplierId,
    projectId,
    subProjectId,
    address,
    city,
    postalCode,
    country,
    deliveryDate,
    orderDate,
    comment,
    note,
    name,
    isDirectPayment,
    orderNumber,
    deletedReceiptFilesIds,
    associatedSupplierInvoices,
    hasUnitPrices,
    vatType,
    isLedgerEnabled,
    isPriceRequest,
  } = formValues;

  const hasItems = Object.values(treeState.leaves).length !== 0;
  const itemsDTO = (): Pick<IOrderCreationDTO, 'items' | 'lots' | 'rootLotId' | 'relations'> => {
    if (!hasItems) {
      return {
        items: 'null',
        lots: 'null',
        relations: 'null',
        rootLotId: 'null',
      };
    }

    const relations = Object.entries(treeState.relations).reduce<IOrderRelations>((acc, [orderId, relation]) => {
      acc[orderId] = {
        items: relation.leaves,
        lots: relation.nodes,
      };
      return acc;
    }, {});

    const items = Object.values(treeState.leaves).reduce<IOrderItemRecord>((acc, item) => {
      acc[item.id] = {
        id: item.id,
        code: item.code || null,
        description: item.description,
        quantity: item.quantity ?? null,
        unit: item.unit || null,
        vatRate: item.vatRate ?? null,
        unitPriceExVAT: item.unitPriceExVAT ?? null,
        typeId: item.typeId,
      };
      return acc;
    }, {});

    const lots = Object.values(treeState.nodes).reduce<IOrderLotRecord>((acc, lot) => {
      acc[lot.id] = {
        id: lot.id,
        defaultVatRate: lot.defaultVatRate,
      };
      return acc;
    }, {});

    return {
      items: JSON.stringify(items),
      lots: JSON.stringify(lots),
      relations: JSON.stringify(relations),
      rootLotId: treeState.rootNodeId,
    };
  };
  const itemsLotsAndRelations = itemsDTO();

  const areAmountsDefinedByItems = hasUnitPrices && hasItems;

  return {
    name,
    orderNumber,
    comment,
    note,
    vatType,
    isDirectPayment: isDirectPayment ? `${isDirectPayment}` : 'false',
    typeId: typeId ? typeId.toString() : undefined,
    vatRate:
      !isNil(vatRate) && !areAmountsDefinedByItems && vatType === VAT_TYPE.NORMAL
        ? (formatVatRateToNumber(vatRate).toString() as `${number}`)
        : 'null',
    amountExVAT:
      !isNil(amountExVAT) && !areAmountsDefinedByItems
        ? (mapAmountToNumber(amountExVAT).toString() as `${number}`)
        : 'null',
    supplierId: supplierId.toString(),
    projectId: projectId ? projectId.toString() : '0', // explicit delete project
    subProjectId: !isDirectPayment || !subProjectId ? 'null' : subProjectId,
    orderDate: dayjs(orderDate).format(DATE_FORMAT_API),
    deliveryDate: deliveryDate ? dayjs(deliveryDate).format(DATE_FORMAT_API) : 'null',
    associatedSupplierInvoices: JSON.stringify((associatedSupplierInvoices || []).map((i) => i.id)),
    ...(deletedReceiptFilesIds && { deletedFilesIds: JSON.stringify(deletedReceiptFilesIds || []) }),
    deliveryAddress: JSON.stringify({
      address,
      postalCode,
      city,
      country,
    }),
    hasUnitPrices: `${hasUnitPrices}`,
    isLedgerEnabled: `${!!isLedgerEnabled}`,
    isPriceRequest: `${!!isPriceRequest}`,
    ...itemsLotsAndRelations,
  };
};

export const mapUpdateOrderDTO = (
  formValues: Omit<OrderEditForm, 'receiptFiles'>,
  treeState: TreeDataWithRelations<IOrderLotDTO, IOrderItemDTO>,
  mapAmountToNumber: (value: number | string) => number,
  initialAssociatedSupplierInvoice?: ISupplierInvoice[],
): IOrderUpdateDTO => {
  const { associatedSupplierInvoices, isOrderFilesDisplayedInPDFUpdate } = formValues;

  return {
    ...omit(mapCreateOrderDTO(formValues, treeState, mapAmountToNumber), ['isLedgerEnabled']),
    isOrderFilesDisplayedInPDFUpdate: JSON.stringify(isOrderFilesDisplayedInPDFUpdate),
    deletedSupplierInvoices: initialAssociatedSupplierInvoice?.length
      ? JSON.stringify(
          differenceBy(initialAssociatedSupplierInvoice, associatedSupplierInvoices || [], 'id').map(({ id }) => id),
        )
      : undefined,
  };
};

export const processSupplierInvoiceAmount = (supplierInvoice: ISupplierInvoice, orderProjectId: number | undefined) => {
  const { amountExVAT, supplierInvoiceProjects } = supplierInvoice;

  if (!orderProjectId || !supplierInvoiceProjects?.length) {
    return amountExVAT;
  }

  const orderProject = supplierInvoiceProjects.find((p) => p?.project?.id === orderProjectId);

  return orderProject?.amountExVAT || amountExVAT;
};

export const mapOrderResponseDTOToInitialTree = (
  order: IOrderResponseDTO | null,
  { defaultVatRate = DEFAULT_VAT_RATE }: { defaultVatRate?: number } = {},
): InitialOrderTree => {
  const { orderItems, orderLots, rootLotId, relations } = order || {};

  // If order has items and lots, populate tree
  if (!isNil(orderItems) && !isNil(orderLots) && !isNil(rootLotId) && !isNil(relations)) {
    const leavesWithRelations = Object.values(orderItems).reduce<
      Record<string, IOrderItem & { parentNodeId: string; typeId: number | undefined }>
    >((acc, orderItem) => {
      acc[orderItem.id] = {
        ...orderItem,
        parentNodeId: orderItem.parentOrderLot!.id,
        typeId: orderItem.type?.id,
      };

      return acc;
    }, {});

    const nodesWithRelations = Object.values(orderLots).reduce<Record<string, IOrderLot & { parentNodeId: null }>>(
      (acc, orderItem) => {
        acc[orderItem.id] = {
          ...orderItem,
          parentNodeId: null,
        };

        return acc;
      },
      {},
    );

    const relationsForTree = Object.entries(relations).reduce<Record<string, INodeRelation<IOrderLot, IOrderItem>>>(
      (acc, [id, relation]) => ({
        [id]: {
          nodes: relation.lots,
          leaves: relation.items,
        },
      }),
      {},
    );

    return {
      relations: relationsForTree,
      leaves: leavesWithRelations,
      rootNodeId: rootLotId,
      nodes: nodesWithRelations,
    };
  }

  // Else, build a root lot
  const id = uuid();
  return {
    relations: {
      [id]: {
        leaves: [],
        nodes: [],
      },
    },
    nodes: {
      [id]: { id, defaultVatRate, parentNodeId: null },
    },
    leaves: {},
    rootNodeId: id,
  };
};

export const cloneOrderItemsLotsAndRelations = (
  order: IOrderResponseDTO,
): Pick<IOrderResponseDTO, 'orderItems' | 'orderLots' | 'relations' | 'rootLotId'> => {
  const orderItems: IOrderResponseDTO['orderItems'] = {};
  const orderLots: IOrderResponseDTO['orderLots'] = {};
  const relations: IOrderResponseDTO['relations'] = {};

  const oldToNewIds = new Map<string, string>();

  Object.entries(order.orderLots).forEach(([oldId, lot]) => {
    const newId = uuid();
    orderLots[newId] = { ...lot, id: newId };
    oldToNewIds.set(oldId, newId);
  });

  Object.entries(order.orderItems).forEach(([oldId, item]) => {
    const newId = uuid();
    orderItems[newId] = {
      ...item,
      id: newId,
      parentOrderLot: orderLots[oldToNewIds.get(item.parentOrderLot!.id)!],
    };
    oldToNewIds.set(oldId, newId);
  });

  Object.keys(order.orderLots).forEach((lotId) => {
    const newRelationId = oldToNewIds.get(lotId)!;

    relations[newRelationId] = {
      items: order.relations[lotId].items.map((oldItemId) => oldToNewIds.get(oldItemId)!),
      lots: order.relations[lotId].lots.map((oldLotId) => oldToNewIds.get(oldLotId)!),
    };
  });

  return {
    orderItems,
    orderLots,
    relations,
    rootLotId: oldToNewIds.get(order.rootLotId!)!,
  };
};

export const duplicateOrder = (
  order: IOrderResponseDTO,
  newName: string,
  newOrderNumber: string | undefined,
  mapAmountToNumber: (value: number | string) => number,
  isLedgerEnabled: boolean,
): IOrderCreationDTO => {
  const createDTO = mapCreateOrderDTO(
    {
      // Comment, note, supplier invoices are not carried over
      comment: '',
      note: '',
      associatedSupplierInvoices: [],
      initialReceiptFiles: [],
      orderNumber: newOrderNumber,
      isLedgerEnabled,
      name: newName,
      amountExVAT: order.amountExVAT,

      vatType: order.vatType,
      hasUnitPrices: order.hasUnitPrices,
      isPriceRequest: order.isPriceRequest,

      isDirectPayment: order.isDirectPayment,
      projectId: order.project?.id,
      subProjectId: order.subProject?.id,

      orderDate: order.orderDate.toString(),
      deliveryDate: order.deliveryDate?.toString(),
      typeId: order.type?.id,
      vatDistribution: order.vatDistribution,
      vatRate: order.vatDistribution[0].vatRate ? formatNumberToVatRate(order.vatDistribution[0].vatRate) : null,
      address: order.deliveryAddress.address,
      city: order.deliveryAddress.city,
      country: order.deliveryAddress.country,
      postalCode: order.deliveryAddress.postalCode,
      projectAddress: order.project?.address,
      supplierId: order.supplier.id,
      directPaymentError: false,
      incompleteItemsError: false,
    },
    // Empty tree for now, duplicated tree is injected down below
    { expandedNodeIds: {}, nodes: {}, relations: {}, leaves: {}, rootNodeId: 'null' },
    mapAmountToNumber,
  );

  const clonedOrderItemsLotsAndRelations = cloneOrderItemsLotsAndRelations(order);

  return {
    ...createDTO,
    ...(Object.keys(order.orderItems).length === 0
      ? {
          items: 'null',
          lots: 'null',
          relations: 'null',
          rootLotId: 'null',
        }
      : {
          items: JSON.stringify(clonedOrderItemsLotsAndRelations.orderItems),
          lots: JSON.stringify(clonedOrderItemsLotsAndRelations.orderLots),
          relations: JSON.stringify(clonedOrderItemsLotsAndRelations.relations),
          rootLotId: clonedOrderItemsLotsAndRelations.rootLotId,
        }),
  };
};

export function populateOrderTreeFromQuoteComponentsSupplyObjects(
  quoteComponentsSupplyObjects: QuoteComponentsSupplyObject[],
  form: FormContextApi<OrderEditForm>,
  tree: OrderTree,
  mapNumberToAmount: (v: number) => number,
  companyComponentTypes: IComponentType[],
) {
  const { rootNodeId, nodes } = tree.getDisplayedCurrentTree();
  const lastItemId = tree.getLastLeafOfNode(rootNodeId);

  const { defaultVatRate } = nodes[rootNodeId];
  const formValues: Partial<OrderEditForm> = {};

  quoteComponentsSupplyObjects.forEach((quoteComponent) => {
    const componentTypeId = quoteComponent.componentType?.id ? Number(quoteComponent.componentType.id) : undefined;
    const componentType = companyComponentTypes.find(({ id }) => id === componentTypeId);
    const id = tree.createLeaf(rootNodeId, lastItemId, {
      code: quoteComponent.refCode,
      vatRate: defaultVatRate,
      unit: quoteComponent.unit,
      description: quoteComponent.denomination ? quoteComponent.denomination : undefined,
      unitPriceExVAT: quoteComponent.unitFlatCostAmount ? Number(quoteComponent.unitFlatCostAmount) : undefined,
      quantity: quoteComponent.quantity ? Number(quoteComponent.quantity) : undefined,
      typeId: componentType?.isWorkforce ? undefined : componentTypeId,
    });

    formValues[getOrderItemFieldName(id, 'typeId')] = componentType?.isWorkforce ? undefined : componentTypeId;
    formValues[getOrderItemFieldName(id, 'code')] = quoteComponent.refCode;
    formValues[getOrderItemFieldName(id, 'vatRate')] = formatNumberToVatRate(defaultVatRate);
    formValues[getOrderItemFieldName(id, 'unit')] = quoteComponent.unit;
    formValues[getOrderItemFieldName(id, 'description')] = quoteComponent.denomination
      ? quoteComponent.denomination
      : undefined;
    formValues[getOrderItemFieldName(id, 'unitPriceExVAT')] = mapNumberToAmount(
      quoteComponent.unitFlatCostAmount ? Number(quoteComponent.unitFlatCostAmount) : 0,
    );
    formValues[getOrderItemFieldName(id, 'quantity')] = quoteComponent.quantity ? Number(quoteComponent.quantity) : 0;
  });
  form.setFormValues(formValues);
}
