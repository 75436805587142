import { useCallback, useEffect, useState } from 'react';
import { GraneetButton, useHotkeys } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { GridApi } from '@ag-grid-community/core';

import {
  updateQuoteAreAllRowsCollapsed,
  updateQuoteCollapsedRows,
  updateQuoteExpandedRows,
  updateQuoteSetting,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { QUOTATION_SHORTCUTS } from 'features/quotation/quote-hot-key/hooks/useQuoteHotKeys';
import { useStore } from 'store/store';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { QuoteAGGridType } from 'features/quotation/ag-grid-quote/constants/agGridConstants';

export const QuoteToolBarToggleAllLinesButton = ({
  type,
  api,
}: {
  api: GridApi<QuoteNodeObject>;
  type: QuoteAGGridType;
}) => {
  const { t } = useTranslation(['quote']);
  const { quote } = useQuote();

  const quoteSetting = useStore((state) => state.quoteSetting);
  const { quoteSettingProxyApi } = useQuotationProxyApis();

  const setItemsExpanded = useCallback(
    (isAllItemExpanded: boolean) => {
      api.forEachNode((node) => {
        node.setExpanded(isAllItemExpanded);
      });
      return isAllItemExpanded;
    },
    [api],
  );

  let value = quoteSetting.isAllItemsExpanded;
  if (type === QuoteAGGridType.HIDDEN_COST) value = quoteSetting.isHiddenCostTabAllItemsExpanded;

  const [areAllOpen, setAreAllOpen] = useState<boolean>(value);

  useEffect(() => {
    setItemsExpanded(quoteSetting.isAllItemsExpanded);
  }, [quoteSetting.isAllItemsExpanded, setItemsExpanded]);

  const handleExpandAll = useCallback(async () => {
    setAreAllOpen(setItemsExpanded(true));
    updateQuoteAreAllRowsCollapsed(false);
    updateQuoteCollapsedRows([]);
    updateQuoteExpandedRows([]);
    if (type === QuoteAGGridType.ROOT) {
      await quoteSettingProxyApi.updateQuoteSetting(quote.getId(), {
        isAllItemsExpanded: true,
        isComponentsExpanded: true,
      });
      updateQuoteSetting({ ...quoteSetting, isAllItemsExpanded: true, isComponentsExpanded: true });
    } else if (type === QuoteAGGridType.HIDDEN_COST) {
      await quoteSettingProxyApi.updateQuoteSetting(quote.getId(), {
        isHiddenCostTabAllItemsExpanded: true,
      });
      updateQuoteSetting({ ...quoteSetting, isHiddenCostTabAllItemsExpanded: true });
    }
  }, [quote, quoteSetting, quoteSettingProxyApi, setItemsExpanded, type]);

  const handleCollapseAll = useCallback(async () => {
    setAreAllOpen(setItemsExpanded(false));
    updateQuoteAreAllRowsCollapsed(true);
    updateQuoteCollapsedRows([]);
    updateQuoteExpandedRows([]);
    if (type === QuoteAGGridType.ROOT) {
      await quoteSettingProxyApi.updateQuoteSetting(quote.getId(), {
        isAllItemsExpanded: false,
        isComponentsExpanded: false,
      });
      updateQuoteSetting({ ...quoteSetting, isAllItemsExpanded: false, isComponentsExpanded: false });
    } else if (type === QuoteAGGridType.HIDDEN_COST) {
      await quoteSettingProxyApi.updateQuoteSetting(quote.getId(), {
        isHiddenCostTabAllItemsExpanded: false,
      });
      updateQuoteSetting({ ...quoteSetting, isHiddenCostTabAllItemsExpanded: false });
    }
  }, [quote, quoteSetting, quoteSettingProxyApi, setItemsExpanded, type]);

  useHotkeys(
    [QUOTATION_SHORTCUTS.COLLAPSE_ALL.shortCut],
    () => {
      handleCollapseAll();
    },
    [handleCollapseAll],
  );

  useHotkeys(
    [QUOTATION_SHORTCUTS.EXPAND_ALL.shortCut],
    () => {
      handleExpandAll();
    },
    [handleExpandAll],
  );

  return (
    <GraneetButton
      leftIcon={<i className={areAllOpen ? 'ri-skip-up-line' : 'ri-skip-down-line'} />}
      variant="ghost"
      onClick={areAllOpen ? handleCollapseAll : handleExpandAll}
    >
      {t(areAllOpen ? 'quote:quotation.tooltips.collapseAll' : 'quote:quotation.tooltips.expandAll')}
    </GraneetButton>
  );
};
