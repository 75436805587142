import { v4 } from 'uuid';

import { MenuOption } from '../components/VariableMenu';

export class VariableOption extends MenuOption {
  text: string;

  value: string;

  constructor(text: string, value: string) {
    super(v4(), true);

    this.text = text;
    this.value = value;
  }
}
