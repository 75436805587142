import type { FC, ReactElement, ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import type { ModalProps, UseDisclosureReturn } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { useHistory, MemoryRouter } from 'react-router-dom';

import { ModalPrimaryButton } from '../Modal/ModalPrimaryButton';
import { ModalSecondaryButton } from '../Modal/ModalSecondaryButton';
import { ModalCloseButton } from '../Modal/ModalCloseButton';

import { ModalView } from './ModalView';
import { NavigationModalContext } from './NavigationModalContext';

interface InternalNavigationModalProps extends ModalProps {
  children: ReactNode;

  isOpen: boolean;

  onClose(): void;

  size?: string;

  fullHeight?: boolean;
}

const InternalNavigationModal: FC<InternalNavigationModalProps> = ({
  children,
  isOpen,
  onClose,
  size = 'xl',
  fullHeight = false,
  ...otherProps
}) => {
  const history = useHistory();

  useEffect(() => {
    if (!isOpen) {
      history.goBack();
    }
  }, [history, isOpen]);

  const navigationModalContext = useMemo(
    () => ({
      onClose,
    }),
    [onClose],
  );

  return (
    <Modal size={size} isOpen={isOpen} onClose={onClose} {...otherProps}>
      <ModalOverlay />

      <ModalContent alignSelf={fullHeight ? 'stretch' : undefined}>
        <NavigationModalContext.Provider value={navigationModalContext}>{children}</NavigationModalContext.Provider>
      </ModalContent>
    </Modal>
  );
};

export interface NavigationModalProps extends InternalNavigationModalProps, UseDisclosureReturn {
  children: ReactElement[] | ReactNode[];
}

const NavigationModalComponent: FC<NavigationModalProps> = ({ children, ...props }) => (
  <MemoryRouter>
    <InternalNavigationModal {...props}>{children}</InternalNavigationModal>
  </MemoryRouter>
);

export const NavigationModal = Object.assign(NavigationModalComponent, {
  View: ModalView,
  PrimaryButton: ModalPrimaryButton,
  SecondaryButton: ModalSecondaryButton,
  CloseButton: ModalCloseButton,
});
