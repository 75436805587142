import type { FC, ReactNode } from 'react';
import { Box, Flex, Text, Link } from '@chakra-ui/react';

import { Button } from '../Button/Button';

import { configureDefaultLabels, upsellTranslations } from './configureDefaultLabel';

export interface UpsellCardProps {
  description: ReactNode;
}

const UpsellCardComponent: FC<UpsellCardProps> = ({ description }) => (
  <Box bg="#1E363B" rounded="6px" p={4}>
    <Text color="white">{description}</Text>

    <Flex mt={3} gap={3} alignItems="center">
      <Button
        color="#15181A"
        bg="#F4EC7C"
        _hover={{ bg: '#F4EC7C' }}
        as={Link}
        // @ts-ignore
        target="_blank"
        // @ts-ignore
        href={upsellTranslations.compareOffersLink}
      >
        {upsellTranslations.compareOffers}
      </Button>

      <Button
        color="white"
        variant="link"
        as={Link}
        // @ts-ignore
        href={upsellTranslations.learnMoreLink}
      >
        {upsellTranslations.learnMore}
      </Button>
    </Flex>
  </Box>
);

export const UpsellCard = Object.assign(UpsellCardComponent, {
  configureDefaultLabels,
});
