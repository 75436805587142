import type { FC } from 'react';
import { useCallback } from 'react';
import { Flex } from '@chakra-ui/react';
import { Card, Tooltip, useCurrency } from '@graneet/lib-ui';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IOrderResponseDTO, ISupplierInvoice } from '@graneet/business-logic';

import { SupplierInvoicePickerField } from '../SupplierInvoicePickerField';
import { SupplierInvoiceList } from '../SupplierInvoiceList';
import { AssociatedSupplierInvoicesAmounts } from '../AssociatedSupplierInvoicesAmounts';
import type { OrderEditForm } from '../../forms/order-edit-wizard';

interface OrderEditAssociatedSupplierInvoiceCardProps {
  order: IOrderResponseDTO | null;
}

export const OrderEditAssociatedSupplierInvoiceCard: FC<OrderEditAssociatedSupplierInvoiceCardProps> = ({ order }) => {
  const { t } = useTranslation(['orders']);
  const { mapAmountToNumber } = useCurrency();

  const form = useFormContext<OrderEditForm>();
  const { associatedSupplierInvoices } = useOnChangeValues(form, ['associatedSupplierInvoices']);

  const {
    amountExVAT: rawAmountExVAT,
    projectId: orderProjectId,
    isDirectPayment,
  } = useOnChangeValues(form, ['amountExVAT', 'projectId', 'isDirectPayment']);

  const orderAmountExVAT = rawAmountExVAT ? mapAmountToNumber(rawAmountExVAT) : 0;

  const onDeleteSupplierInvoice = useCallback(
    (supplierInvoice: ISupplierInvoice) => {
      form.setFormValues({
        associatedSupplierInvoices: (associatedSupplierInvoices || []).filter((i) => i.id !== supplierInvoice.id),
      });
    },
    [form, associatedSupplierInvoices],
  );

  return (
    <Card title={t('orders:associate.title')}>
      <Flex w="100%" flexDir="column" gap={2}>
        <Tooltip shouldWrapChildren label={t('orders:associate.disableOnDirectPayment')} isDisabled={!isDirectPayment}>
          <SupplierInvoicePickerField isDisabled={isDirectPayment} />
        </Tooltip>

        <SupplierInvoiceList
          supplierInvoices={associatedSupplierInvoices}
          onDeleteSupplierInvoice={onDeleteSupplierInvoice}
          order={order}
        />
        <AssociatedSupplierInvoicesAmounts
          supplierInvoices={associatedSupplierInvoices || []}
          orderProjectId={orderProjectId}
          amountExVAT={orderAmountExVAT}
        />
      </Flex>
    </Card>
  );
};
