import type { FC } from 'react';
import { useMemo, useCallback } from 'react';
import type { BankingTransactionStatus, IBankingTransactionWithRelations } from '@graneet/business-logic';
import { sumObjects } from '@graneet/business-logic';
import { ActionMenu } from '@graneet/lib-ui';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  useBankingTransactionUpdateToReconciled,
  useBankingTransactionUpdateToToReconcile,
} from '../services/banking-transaction.api';

import { BANKING_TRANSACTION_STATUS_COLOR, BankingTransactionStatusBadge } from './BankingTransactionStatusBadge';

interface BankingTransactionStatusDropdownProps {
  bankingTransaction: IBankingTransactionWithRelations;

  onBankingTransactionUpdate: () => void;

  isReadOnly?: boolean;
}
export const BankingTransactionStatusDropdown: FC<BankingTransactionStatusDropdownProps> = ({
  bankingTransaction,
  onBankingTransactionUpdate,
  isReadOnly,
}) => {
  const { t } = useTranslation(['banking']);

  const bankingTransactionUpdateToToReconcileMutation = useBankingTransactionUpdateToToReconcile();
  const bankingTransactionUpdateToReconciledMutation = useBankingTransactionUpdateToReconciled();

  const availableStatuses = useMemo((): BankingTransactionStatus[] => {
    if (bankingTransaction.status === 'PARTIALLY_RECONCILED') {
      return ['PARTIALLY_RECONCILED', 'RECONCILED'];
    }

    // The transaction amount is linked to a statement only
    if (
      sumObjects(bankingTransaction.payments, 'amountPaidIncVAT') +
        sumObjects(bankingTransaction.supplierInvoicePayments, 'amountPaidIncVAT') ===
      bankingTransaction.amount
    ) {
      return ['RECONCILED'];
    }

    return ['TO_RECONCILE', 'RECONCILED'];
  }, [
    bankingTransaction.amount,
    bankingTransaction.payments,
    bankingTransaction.status,
    bankingTransaction.supplierInvoicePayments,
  ]);

  const onChange = useCallback(
    (status: BankingTransactionStatus) => () => {
      if (bankingTransaction.status === status) {
        return;
      }

      switch (status) {
        case 'TO_RECONCILE':
          bankingTransactionUpdateToToReconcileMutation.mutate(bankingTransaction.id);
          onBankingTransactionUpdate();
          break;
        case 'RECONCILED':
          bankingTransactionUpdateToReconciledMutation.mutate(bankingTransaction.id);
          onBankingTransactionUpdate();
          break;
        default:
          break;
      }
    },
    [
      bankingTransaction.id,
      bankingTransaction.status,
      bankingTransactionUpdateToReconciledMutation,
      bankingTransactionUpdateToToReconcileMutation,
      onBankingTransactionUpdate,
    ],
  );

  return (
    <ActionMenu
      buttonProps={{
        children: <BankingTransactionStatusBadge bankingTransaction={bankingTransaction} />,
        leftIcon: null,
        rightIcon: 'ri-arrow-down-s-line',
      }}
      isOpen={isReadOnly === true ? false : undefined}
    >
      {availableStatuses.map((status) => (
        <ActionMenu.Action
          onClick={onChange(status)}
          label={
            <Flex key={status} gap={2}>
              <Box color={BANKING_TRANSACTION_STATUS_COLOR[status]}>
                <i className="ri-circle-fill" />
              </Box>

              {t(`banking:bankingTransaction.status.${status}`)}

              {bankingTransaction.status === status && <i className="ri-check-line" />}
            </Flex>
          }
        />
      ))}
    </ActionMenu>
  );
};
