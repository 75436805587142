export const OPTION_STYLE_HOVER = { bg: 'gray.50' };
export const INPUT_HEIGHT = '1.8rem';

export const DAY_PICKER_POPPER_WIDTH = '15rem';
export const DAY_PICKER_POPPER_HEIGHT = '18rem';

export const POPOVER_PROPS = {
  borderWidth: '1px',
  borderRadius: '0.5rem',
  borderColor: 'greenBrand.light',
  mt: '0.375rem',
};

export const MENU_LIST_PROPS = {
  ...POPOVER_PROPS,
  ml: '-0.9rem',
};

export const INPUT_IN_DEEP_TABLE_STYLE = (hasPeerElementFocus: boolean) => ({
  borderColor: hasPeerElementFocus ? 'gray.500' : undefined,
  noBorder: true,
  fontSize: 'sm',
  _hover: {
    borderColor: 'gray.300',
  },
  _disabled: {
    borderColor: 'transparent',
  },
});

export const INPUT_NOTE_DEEP_TABLE_STYLE = (hasPeerElementFocus: boolean) => ({
  borderColor: hasPeerElementFocus ? 'gray.500' : 'transparent',
  color: 'gray.500',
  marginLeft: 4,
  noBorder: true,
  maxRows: 100, // Hack to have unlimited rows
});
