import { HStack } from '@chakra-ui/react';
import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';
import type { FocusEvent } from 'react';
import { memo, useState, useCallback, useEffect, useRef } from 'react';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import type { MaskedInputHandle } from '@graneet/lib-ui';
import { MaskEnum, QuotationMaskedInput, useCurrency, useInputMaskInfos } from '@graneet/lib-ui';

export const AmountEditorCell = memo((params: CustomCellEditorProps<QuoteComponentsSupplyObject>) => {
  const { value, onValueChange, stopEditing } = params;
  const { mapAmountToStringNumber, mapStringNumberToAmount } = useCurrency();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const { placeholder, mask } = useInputMaskInfos(MaskEnum.CURRENCY, {});

  const ref = useRef<MaskedInputHandle>(null);

  const [inputValue, setInputValue] = useState(mapStringNumberToAmount(value));

  const handleOnChange = useCallback(
    (v: string) => {
      setInputValue(v);
      onValueChange(mapAmountToStringNumber(v));
    },
    [mapAmountToStringNumber, onValueChange],
  );

  const handleOnFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  }, []);

  useEffect(() => {
    inputRef?.focus();
  }, [inputRef]);

  return (
    <HStack height="100%" justifyContent="center" background="transparent" p={2} alignItems="flex-start">
      <QuotationMaskedInput
        textAlign="right"
        value={inputValue}
        inputRef={setInputRef}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={() => stopEditing()}
        mask={mask}
        placeholder={placeholder}
        ref={ref}
        noStyle
        w={`${value.length + 2}ch`}
      />
    </HStack>
  );
});
