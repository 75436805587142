export enum ACCOUNTING_JOURNAL {
  SALES = 'SALES',
  PURCHASES = 'PURCHASES',
  BANK = 'BANK',
}

export enum ACCOUNTING_ENTRY_TYPE {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum ACCOUNTING_EXPORT_FORMAT {
  XLSX = 'XLSX',
  FEC = 'FEC',
}
