import { HStack } from '@chakra-ui/react';
import type { FormulaFieldValue, QuotationFormulaFieldValue } from '@graneet/lib-ui';
import { QuotationFormulaInput } from '@graneet/lib-ui';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import { memo, useCallback, useMemo } from 'react';
import isEqual from 'lodash-es/isEqual';
import isNil from 'lodash-es/isNil';
import isEmpty from 'lodash-es/isEmpty';

import { Warning } from '../Warning';

export const QuantityCell = memo(
  (
    params: CustomCellRendererProps<QuoteNodeObject> & {
      mandatory?: boolean;
    },
  ) => {
    const { getValue, data, node } = params;

    const isEditable = params.column?.isCellEditable(node);

    const value: QuotationFormulaFieldValue | null = useMemo(() => {
      if (getValue) {
        return getValue();
      }
      return null;
    }, [getValue]);

    const color = useMemo(() => {
      if (data?.content.type === 'QuoteSubItem' || data?.content.type === 'QuoteComponent') {
        return '#6C737F';
      }
      return '#1F2A37';
    }, [data]);

    const emptyCb = useCallback(() => {}, []);

    if (isEditable && params.mandatory && (isNil(value?.value) || isEmpty(value.value))) {
      return <Warning align="flex-end" />;
    }

    return (
      <HStack
        py="8px"
        width="100%"
        justifyContent="flex-end"
        color={color}
        fontSize="0.813rem"
        height="100%"
        alignItems="stretch"
      >
        <QuotationFormulaInput
          isReadOnly
          value={value}
          onChange={emptyCb}
          onBlur={emptyCb}
          onFocus={emptyCb}
          sendClickRoundEvent={emptyCb}
        />
      </HStack>
    );
  },
  (prevProps, nextProps) => {
    if (!prevProps && !nextProps) {
      return true;
    }
    if (!prevProps || !nextProps) {
      return false;
    }
    const oldValue: FormulaFieldValue = prevProps.getValue ? prevProps.getValue() : undefined;
    const newValue: FormulaFieldValue = nextProps.getValue ? nextProps.getValue() : undefined;
    return isEqual(oldValue?.value, newValue?.value);
  },
);
