import type { QuoteNodeObject } from '@org/quotation-lib';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import type { ChangeEvent, FocusEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { GraneetIconButton, QuotationInput, SimpleChevronBottomIcon } from '@graneet/lib-ui';

import { useStore } from 'store/store';
import {
  updateQuoteCollapsedRows,
  updateQuoteExpandedRows,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useKeyboard } from 'features/quotation/ag-grid-quote/hooks/keyboard/useKeyboard';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const CodeEditorCell = (params: CustomCellEditorProps<QuoteNodeObject>) => {
  const { data, node, api, onValueChange, stopEditing } = params;

  const { handleKeyDown } = useKeyboard();

  const inputRef = useRef<HTMLInputElement>(null);

  const { quote } = useQuote();
  const quoteExpandedRows = useStore((state) => state.quoteExpandedRows);
  const quoteCollapsedRows = useStore((state) => state.quoteCollapsedRows);

  const isExpandDefined = useMemo(() => quoteExpandedRows.includes(node.key!), [node, quoteExpandedRows]);
  const isCollapseDefined = useMemo(() => quoteCollapsedRows.includes(node.key!), [node, quoteCollapsedRows]);

  const isExpanded = useMemo(() => {
    if (isExpandDefined) {
      return true;
    }
    if (isCollapseDefined) {
      return false;
    }
    return node.expanded;
  }, [isExpandDefined, isCollapseDefined, node]);

  const handleCollapse = useCallback(() => {
    api.setRowNodeExpanded(node, false);
    updateQuoteExpandedRows(quoteExpandedRows.filter((row) => row !== node.key!));
    updateQuoteCollapsedRows([...quoteCollapsedRows, node.key!]);
  }, [api, node, quoteCollapsedRows, quoteExpandedRows]);

  const handleExpand = useCallback(() => {
    api.setRowNodeExpanded(node, true);
    updateQuoteCollapsedRows(quoteCollapsedRows.filter((row) => row !== node.key!));
    updateQuoteExpandedRows([...quoteExpandedRows, node.key!]);
  }, [api, node, quoteCollapsedRows, quoteExpandedRows]);

  const hasChildren = useMemo(() => (node.childrenAfterGroup?.length ?? 0) > 0, [node]);

  const isAutomaticIndexActivated = useMemo(() => quote?.getIsAutomaticIndexActivated(), [quote]);
  const [value, setValue] = useState(
    (isAutomaticIndexActivated ? data?.content.automaticIndex : data?.content.manualIndex) ?? '',
  );

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      onValueChange(e.target.value);
    },
    [onValueChange],
  );

  const handleSave = useCallback(
    (v: string) => {
      onValueChange(v);
      stopEditing();
    },
    [onValueChange, stopEditing],
  );

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      handleSave(newValue);
    },
    [handleSave],
  );

  const handleOnFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  }, []);

  const color = useMemo(() => {
    if (data?.content.type === 'QuoteSubItem' || data?.content.type === 'QuoteComponent') {
      return '#6C737F';
    }
    return '#1F2A37';
  }, [data?.content.type]);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  if (node.footer) {
    return null;
  }

  return (
    <HStack px="0.5rem" pl={`${node.level * 0.625}rem`} alignItems="center" py="8px">
      <Box width="1.5rem" display="flex" alignItems="center" justifyContent="center">
        {hasChildren && (
          <GraneetIconButton
            size="sm"
            color="ghost"
            icon={
              isExpanded ? (
                <SimpleChevronBottomIcon boxSize="0.5rem" />
              ) : (
                <SimpleChevronBottomIcon boxSize="0.5rem" transform="rotate(-90deg)" />
              )
            }
            onClick={isExpanded ? handleCollapse : handleExpand}
          />
        )}
      </Box>
      <Box fontSize="0.813rem" color={color} fontFamily="Lato" lineHeight="normal" fontWeight={500}>
        <QuotationInput
          isReadOnly={isAutomaticIndexActivated}
          value={value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          onKeyDownCapture={handleKeyDown(handleSave)}
          ref={inputRef}
          noStyle
        />
      </Box>
    </HStack>
  );
};
