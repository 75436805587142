import type { FocusEventHandler, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { EditorState, SerializedEditorState } from 'lexical';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import type { InitialConfigType } from '@lexical/react/LexicalComposer';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListItemNode, ListNode } from '@lexical/list';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import type { InputProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { isNil } from 'lodash-es';
import { HeadingNode } from '@lexical/rich-text';
import { AutoLinkNode, LinkNode } from '@lexical/link';

import { createInjectedVariableNodes, getPotentialStringifyObject, getRichTextPluginOptions } from '../../utils';
import { FormGroup } from '../FormGroup';

import { getRichTextBitwiseFromConfiguration, hasRichTextConfiguration } from './constants';
import type {
  RichTextBitwise,
  RichTextConfiguration,
  RichTextMaxLengthConfiguration,
  RichTextVariableConfiguration,
} from './constants';
import { configureDefaultLabels } from './configureDefaultLabels';
import { CustomRichTextPlugin } from './plugins/CustomRichTextPlugin';
import { CustomAutoLinkPlugin } from './plugins/CustomAutoLinkPlugin';
import { VariableNode, VariablePlugin } from './plugins/VariablePlugin';
import { TabIndentationPlugin } from './plugins/TabIndentationPlugin';
import { MaxLengthPlugin } from './plugins/MaxLengthPlugin';
import { ShiftEnterPlugin } from './plugins/ShiftEnterPlugin';
import { isLexicalEditorEmpty } from './utils/rich-text.util';

import '../../utils/richtext.css';

export interface RichTextInputProps<SearchResultType> {
  /**
   * Initial value assign to the rich text
   */
  initialValue: string | null | undefined;

  /**
   * Content display when the field is empty
   */
  placeholder?: ReactNode;

  /**
   * Label display to describe what the field corresponds to
   */
  label?: string;

  /**
   * Callback executed on value change
   * @param state new value
   */
  onChange: (state: string) => void;

  /**
   * Callback executed on input focus
   */
  onFocus?: FocusEventHandler<HTMLDivElement>;

  /**
   * Callback executed on input blur
   */
  onBlur?: FocusEventHandler<HTMLDivElement>;

  /**
   * Variant will influe on colors used.
   * @default "dark"
   */
  variant?: 'dark' | 'light';

  /**
   * Type of navbar, inline or block
   */
  navbarType: 'block' | 'inline' | 'none';

  configuration: RichTextConfiguration;

  isRequired?: boolean;

  inputProps?: InputProps & { noBorder?: boolean };

  shouldDisplayError?: boolean;

  errorMessage?: string;

  footerContent?: ReactNode;

  toolbarContent?: ReactNode[];

  // eslint-disable-next-line react/no-unused-prop-types
  noBorder?: boolean;

  autoFocus?: boolean;

  disableTabIndentation?: boolean;

  useShiftEnter?: boolean;

  onSearch?: (state: string) => Promise<SearchResultType>;

  renderSearchResults?: (searchTerm: string, isLoading: boolean, searchResults?: SearchResultType) => ReactNode;
}

export type RichTextInputComponentType = RichTextInputProps<unknown>;

const getConditionalNodes = (
  richTextBitwise: RichTextBitwise | undefined,
  richTextVariableConfiguration: RichTextVariableConfiguration | undefined,
) => {
  const nodes = [];

  if (hasRichTextConfiguration(richTextBitwise, 'variable')) {
    if (richTextVariableConfiguration?.injectValueInLabel) {
      nodes.push(...createInjectedVariableNodes(richTextVariableConfiguration));
    } else {
      nodes.push(VariableNode);
    }
  }

  if (hasRichTextConfiguration(richTextBitwise, 'link')) {
    nodes.push(LinkNode, AutoLinkNode);
  }
  if (hasRichTextConfiguration(richTextBitwise, 'block')) {
    nodes.push(HeadingNode);
  }

  if (hasRichTextConfiguration(richTextBitwise, 'numbered') || hasRichTextConfiguration(richTextBitwise, 'bullet')) {
    nodes.push(ListNode, ListItemNode);
  }

  return nodes;
};

export const RichTextInputComponent = <SearchResultType extends unknown>({
  initialValue,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  label,
  variant = 'dark',
  navbarType,
  isRequired = false,
  inputProps,
  shouldDisplayError = true,
  errorMessage,
  footerContent,
  toolbarContent,
  configuration,
  autoFocus,
  disableTabIndentation = false,
  useShiftEnter = false,
  onSearch,
  renderSearchResults,
}: RichTextInputProps<SearchResultType>) => {
  const richTextBitwise = getRichTextBitwiseFromConfiguration(configuration);
  const { variablePluginOptions, theme } = getRichTextPluginOptions(configuration);
  const maxLengthPluginOptions = configuration.find(
    (c): c is RichTextMaxLengthConfiguration => typeof c === 'object' && c.name === 'max-length',
  );

  const initialConfig: InitialConfigType = {
    namespace: 'RichTextInput',
    onError(error) {
      console.warn(error);
      throw new Error(JSON.stringify(error));
    },
    editorState(editor) {
      if (isNil(initialValue) || initialValue === '') {
        return;
      }

      const [isObject, objectState] = getPotentialStringifyObject<SerializedEditorState>(initialValue);
      if (isObject) {
        if (objectState.root.children.length === 0) {
          return;
        }
        const state = editor.parseEditorState(objectState);
        editor.setEditorState(state);
      } else {
        if (objectState === null || objectState === '') {
          return;
        }
        const root = $getRoot();
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode(objectState);
        paragraphNode.append(textNode);
        root.append(paragraphNode);
      }
    },
    nodes: getConditionalNodes(richTextBitwise, variablePluginOptions),
    theme,
  };

  const handleOnChange = useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        const isEmpty = isLexicalEditorEmpty(editorState);
        onChange(isEmpty ? '' : JSON.stringify(editorState.toJSON()));
      });
    },
    [onChange],
  );

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = useCallback<FocusEventHandler<HTMLDivElement>>(
    (event) => {
      onFocus?.(event);
      setIsFocused(true);
    },
    [onFocus],
  );

  const handleBlur = useCallback<FocusEventHandler<HTMLDivElement>>(
    (event) => {
      onBlur?.(event);
      setIsFocused(false);
    },
    [onBlur],
  );

  const useAutoComplete = useMemo(() => !!onSearch && !!renderSearchResults, [onSearch, renderSearchResults]);

  return (
    <Box boxSize="100%">
      <FormGroup
        label={label}
        isRequired={isRequired}
        isFocused={isFocused}
        errorMessage={errorMessage}
        showError={shouldDisplayError}
        position="static"
      >
        <LexicalComposer initialConfig={initialConfig}>
          {useShiftEnter && <ShiftEnterPlugin onSubmit={handleBlur} />}

          <CustomRichTextPlugin
            inputProps={inputProps}
            richTextBitwise={richTextBitwise}
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            variant={variant}
            shouldDisplayError={shouldDisplayError}
            navbarType={navbarType}
            footerContent={footerContent}
            toolbarContent={toolbarContent}
            autoFocus={autoFocus}
            onSearch={onSearch}
            onChange={handleOnChange}
            renderSearchResults={renderSearchResults}
          />

          {!disableTabIndentation && <TabIndentationPlugin />}

          {hasRichTextConfiguration(richTextBitwise, 'numbered') ||
          hasRichTextConfiguration(richTextBitwise, 'bullet') ? (
            <ListPlugin />
          ) : (
            <></>
          )}
          {hasRichTextConfiguration(richTextBitwise, 'link') ? <LinkPlugin /> : <></>}
          {hasRichTextConfiguration(richTextBitwise, 'link') ? <CustomAutoLinkPlugin /> : <></>}
          {maxLengthPluginOptions ? <MaxLengthPlugin maxLength={maxLengthPluginOptions.maxLength} /> : <></>}

          {!useAutoComplete && <OnChangePlugin onChange={handleOnChange} />}
          <HistoryPlugin />

          {variablePluginOptions ? <VariablePlugin onChange={onChange} configuration={variablePluginOptions} /> : <></>}
        </LexicalComposer>
      </FormGroup>
    </Box>
  );
};

export const RichTextInput = Object.assign(RichTextInputComponent, {
  configureDefaultLabels,
});
