import { useTranslation } from 'react-i18next';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { CheckboxField, ModalSubtitle } from '@graneet/lib-ui';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type { RoleFormFields } from '../../constants/role.constant';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const RoleEditModalBanking = () => {
  const { t } = useTranslation(['global', 'user']);

  const hasBankingAccountConnection = useFeatureFlag(FEATURE_FLAGS.BANKING_ACCOUNT_CONNECTION);

  if (!hasBankingAccountConnection) {
    return null;
  }

  return (
    <Stack direction="column" gap={0}>
      <ModalSubtitle mb={2}>{t('user:editRoleModal.sections.bankingParameters')}</ModalSubtitle>

      <Stack direction="column" gap={1} pl={3}>
        <CheckboxField<RoleFormFields>
          name="accessTransactionBanking"
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.ACCESS_TRANSACTION_BANKING')}</Text>
            </HStack>
          }
        />

        <CheckboxField<RoleFormFields>
          name="accessBalanceBanking"
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.ACCESS_BALANCE_BANKING')}</Text>
            </HStack>
          }
        />

        <CheckboxField<RoleFormFields>
          name="accessPaymentResourceBanking"
          label={
            <HStack spacing={2}>
              <Text>{t('user:permissions.ACCESS_PAYMENT_RESOURCE_BANKING')}</Text>
            </HStack>
          }
        />
      </Stack>
    </Stack>
  );
};
