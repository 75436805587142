import type { FC, ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { IOrderResponseDTO } from '@graneet/business-logic';
import { isOrderInDirectPaymentPartiallyEditable } from '@graneet/business-logic';

const OrderDirectPaymentInfosContext = createContext({
  areVatRatesEditable: false,
});
export const useOrderDirectPaymentInfosContext = () => useContext(OrderDirectPaymentInfosContext);

interface OrderDirectPaymentInfosContextProps {
  order?: IOrderResponseDTO | null;

  children: ReactNode;
}

export const OrderDirectPaymentInfosProvider: FC<OrderDirectPaymentInfosContextProps> = ({ order, children }) => {
  const associatedSupplierInvoices = useMemo(
    () => (order?.ordersSupplierInvoices || []).map(({ supplierInvoice: si }) => si),
    [order?.ordersSupplierInvoices],
  );

  const value = useMemo(
    () => ({
      areVatRatesEditable:
        !order?.isDirectPayment || isOrderInDirectPaymentPartiallyEditable(associatedSupplierInvoices),
    }),
    [associatedSupplierInvoices, order?.isDirectPayment],
  );

  return <OrderDirectPaymentInfosContext.Provider value={value}>{children}</OrderDirectPaymentInfosContext.Provider>;
};
