import { Button, ListingLayout, ConfirmDeletionModal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import type { FC } from 'react';
import type { IContact } from '@graneet/business-logic';
import { useCallback, useLayoutEffect, useState } from 'react';

import {
  useContacts,
  useContactDelete,
  useContactImport,
  useContactRoles,
} from 'features/contact/services/contact.api';
import { ContactTable } from 'features/contact/components/tables/ContactTable';
import { ContactUpsertStepperModal } from 'features/contact/components/modals/ContactUpsertStepperModal/ContactUpsertStepperModal';
import { TEMPLATE_URL } from 'features/import/constants/import.constant';
import type { ImportContactField } from 'features/contact/hooks/useImportContactFields';
import { useImportContactFields } from 'features/contact/hooks/useImportContactFields';
import { SpreadsheetImportingModal } from 'features/import/components/SpreadsheetImportingModal';
import type { ImportSpreadsheetSubmit } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImport } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImportMenu } from 'features/import/components/SpreadsheetImportMenu';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewContactsScreen: FC = () => {
  const { t } = useTranslation(['contacts', 'global']);

  const upsertModal = useDisclosure();
  const deletionModal = useDisclosure();
  const importingModal = useDisclosure();
  const importModal = useDisclosure();

  const { updateHeaderTitle } = useHeaderContext();

  const [selectedContact, setSelectedContact] = useState<IContact | null>(null);

  const contacts = useContacts();
  const contactRoles = useContactRoles();

  const contactDeleteMutation = useContactDelete();
  const contactImportMutation = useContactImport();

  const importFields = useImportContactFields();

  const onSubmitImport = useCallback<ImportSpreadsheetSubmit<ImportContactField>>(
    async (importData) => {
      importingModal.onOpen();

      const dto = importData.validData.map((row) => ({
        lastName: row.lastName!.toString(),
        firstName: row.firstName?.toString(),
        role: row.role?.toString(),
        email: row.email?.toString(),
        phoneNumber: row.phoneNumber?.toString(),
      }));

      await contactImportMutation.mutateAsync({ contacts: dto });
      contacts.refetch();
      importingModal.onClose();
    },
    [importingModal, contactImportMutation, contacts],
  );

  const reloadContactsAndRoles = useCallback(() => {
    contacts.refetch();
  }, [contacts]);

  const onUpsertModalClose = useCallback(() => {
    setSelectedContact(null);
    upsertModal.onClose();
  }, [upsertModal]);

  const onDeletionModalClose = useCallback(() => {
    setSelectedContact(null);
    deletionModal.onClose();
  }, [deletionModal]);

  const onContactToEditSelection = useCallback(
    (contact: IContact) => {
      setSelectedContact(contact);
      upsertModal.onOpen();
    },
    [upsertModal],
  );

  const onContactToDeleteSelection = useCallback(
    (contact: IContact) => {
      setSelectedContact(contact);
      deletionModal.onOpen();
    },
    [deletionModal],
  );

  const onWorkerDeletion = useCallback(async () => {
    if (selectedContact) {
      await contactDeleteMutation.mutateAsync(selectedContact);
      setSelectedContact(null);
      reloadContactsAndRoles();
      deletionModal.onClose();
    }
  }, [selectedContact, contactDeleteMutation, reloadContactsAndRoles, deletionModal]);

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.contacts'), []);
  }, [t, updateHeaderTitle]);

  return (
    <>
      <ListingLayout
        pagination={contacts}
        search={{
          placeholder: t('contacts:search'),
        }}
        actions={
          <>
            <Button onClick={upsertModal.onOpen}>{t('contacts:createNewContact')}</Button>
            <SpreadsheetImportMenu
              label={t('contacts:importContacts')}
              templateUrl={TEMPLATE_URL.CONTACTS}
              onOpen={importModal.onOpen}
            />
          </>
        }
        content={
          <ContactTable
            contacts={contacts}
            onContactToEditSelected={onContactToEditSelection}
            onContactToDeleteSelected={onContactToDeleteSelection}
          />
        }
      />

      {importModal.isOpen && (
        <SpreadsheetImport onClose={importModal.onClose} onSubmit={onSubmitImport} fields={importFields} />
      )}

      <SpreadsheetImportingModal {...importingModal} />

      <ContactUpsertStepperModal
        key={selectedContact?.id}
        isOpen={upsertModal.isOpen}
        onSuccess={reloadContactsAndRoles}
        onClose={onUpsertModalClose}
        contact={selectedContact}
        roles={contactRoles.data}
      />

      <ConfirmDeletionModal
        isOpen={deletionModal.isOpen}
        onClose={onDeletionModalClose}
        title={t('contacts:deletionModal.title')}
        action={t('contacts:deletionModal.action')}
        description={t('contacts:deletionModal.description')}
        onDeleted={onWorkerDeletion}
      />
    </>
  );
};
