import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const GroupIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M7.08317 9.99935C8.69442 9.99935 9.99984 8.69393 9.99984 7.08268C9.99984 5.47143 8.69442 4.16602 7.08317 4.16602C5.47192 4.16602 4.1665 5.47143 4.1665 7.08268C4.1665 8.69393 5.47192 9.99935 7.08317 9.99935ZM16.2498 8.54102C16.2498 9.80727 15.2244 10.8327 13.9582 10.8327C13.6572 10.8327 13.3592 10.7735 13.0811 10.6584C12.8031 10.5432 12.5504 10.3744 12.3376 10.1616C12.1248 9.94878 11.956 9.69613 11.8408 9.41806C11.7257 9.14 11.6664 8.84198 11.6665 8.54102C11.6665 7.27477 12.6919 6.24935 13.9582 6.24935C15.2244 6.24935 16.2498 7.27477 16.2498 8.54102ZM7.08317 10.8327C8.22234 10.8327 10.0761 11.1873 11.2919 11.8931C11.7886 12.3985 12.0832 12.9498 12.0832 13.4994V15.8327H1.6665V13.4994C1.6665 11.726 5.27525 10.8327 7.08317 10.8327ZM18.3332 15.8327H12.9165V13.4994C12.9165 12.9094 12.7036 12.366 12.3648 11.8814C13.0069 11.7377 13.639 11.666 14.1219 11.666C15.6748 11.666 18.3332 12.4106 18.3332 13.8881V15.8327Z"
      fill="currentColor"
    />
  </Icon>
);
