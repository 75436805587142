import type {
  ISupplierUpdateDTO,
  ISupplier,
  ISupplierCreateDTO,
  ISupplierListingDTO,
  ISuppliersImportDTO,
  IUpdateTagsDTO,
} from '@graneet/business-logic';
import type { PaginatedResponse, UsePaginationOptions } from '@graneet/lib-ui';
import { usePaginationQuery, useToast } from '@graneet/lib-ui';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import type { QueryClient } from '@tanstack/react-query';
import { queryOptions, useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { apiNew } from 'features/api/services/apiNew.service';

const FACTORY_NAME = 'suppliers';

const SUPPLIER_PATH = '/suppliers';

const supplierKeyFactory = createQueryKeys(FACTORY_NAME, {
  get: (queryParams: URLSearchParams | { _full?: boolean }) => ({
    queryKey: [SUPPLIER_PATH, queryParams],
    queryFn: () =>
      apiNew.get<URLSearchParams | { _full?: boolean }, PaginatedResponse<ISupplier>>(SUPPLIER_PATH, queryParams),
  }),
  getTags: () => ({
    queryKey: [SUPPLIER_PATH, 'tags'],
    queryFn: () => apiNew.get<never, string[]>(`${SUPPLIER_PATH}/tags`),
  }),
  getOne: (id: number) => ({
    queryKey: [SUPPLIER_PATH, id],
    queryFn: () => apiNew.get<never, ISupplierListingDTO>(`${SUPPLIER_PATH}/${id}`),
  }),
  getOneOptional: (id: number | undefined) => ({
    queryKey: [SUPPLIER_PATH, id],
    queryFn: () => (id ? apiNew.get<never, ISupplierListingDTO>(`${SUPPLIER_PATH}/${id}`) : Promise.resolve(null)),
  }),
});

async function invalidAllSuppliersQueries(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [FACTORY_NAME],
  });
}

export function useSuppliers(options?: UsePaginationOptions) {
  const queryClient = useQueryClient();

  return usePaginationQuery(
    (urlSearchParams) => queryClient.fetchQuery(supplierKeyFactory.get(urlSearchParams)),
    options,
  );
}

function getSuppliersOptions(queryParams: URLSearchParams) {
  return queryOptions(supplierKeyFactory.get(queryParams));
}

export function useSuppliersQuery(
  queryParams: URLSearchParams,
  options?: Omit<ReturnType<typeof getSuppliersOptions>, 'queryKey'>,
) {
  return useQuery({ ...options, ...supplierKeyFactory.get(queryParams) });
}

export function useSupplier(id: number) {
  return useSuspenseQuery(supplierKeyFactory.getOne(id));
}

export function useSupplierOptional(id: number | undefined) {
  return useQuery(supplierKeyFactory.getOneOptional(id));
}

export function useSuppliersTags() {
  return useSuspenseQuery(supplierKeyFactory.getTags());
}

export function useSupplierEditTags() {
  const toast = useToast();
  const { t } = useTranslation(['global']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: IUpdateTagsDTO }) =>
      apiNew.patch<IUpdateTagsDTO, ISupplier>(`${SUPPLIER_PATH}/${params.id}/tags`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      await invalidAllSuppliersQueries(queryClient);
    },
  });
}

export function useSupplierCreate() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'supplier']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ISupplierCreateDTO) => apiNew.post<ISupplierCreateDTO, ISupplier>(SUPPLIER_PATH, dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('supplier:toasts.createSuccess'));
      await invalidAllSuppliersQueries(queryClient);
    },
  });
}

export function useSupplierImport() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'supplier']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: ISuppliersImportDTO) =>
      apiNew.post<ISuppliersImportDTO, ISupplier[]>(`${SUPPLIER_PATH}/batch`, dto),
    onError: () => {
      toast.error(t('supplier:import.error'));
    },
    onSuccess: async () => {
      toast.success(t('supplier:import.success'));
      await invalidAllSuppliersQueries(queryClient);
    },
  });
}

export function useSupplierDelete() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'supplier']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, void>(`${SUPPLIER_PATH}/${id}`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: () => {
      toast.success(t('supplier:toasts.deleteSuccess'));
      invalidAllSuppliersQueries(queryClient);
    },
  });
}

export function useSupplierEdit() {
  const toast = useToast();
  const { t } = useTranslation(['global', 'supplier']);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; dto: Partial<ISupplierUpdateDTO> }) =>
      apiNew.patch<Partial<ISupplierUpdateDTO>, ISupplier>(`${SUPPLIER_PATH}/${params.id}`, params.dto),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async () => {
      toast.success(t('supplier:toasts.editSuccess'));
      await invalidAllSuppliersQueries(queryClient);
    },
  });
}
