import type { DOWN_PAYMENT_STATUSES } from '../down-payment/down-payment.type';
import type { INVOICE_STATUSES } from '../invoice/invoice.type';
import type { EMAIL_RECIPIENT } from '../mailing/email.type';
import type { PROGRESS_STATEMENT_STATUSES } from '../progress-statement/progress-statement.type';
import type { IUser } from '../user/user.type';

export enum HISTORY_ACTION {
  ADD_EMAIL = 'addEmail',
  ADD_CREATION = 'create',
  ADD_PAYMENT = 'addPayment',
  UPDATE_PAYMENT = 'updatePayment',
  DELETE_PAYMENT = 'deletePayment',
  ARCHIVE = 'archive',
}

export enum HISTORY_ENTITY_TYPE {
  QUOTE = 'quote',
  INVOICE = 'invoice',
  DOWN_PAYMENT = 'downPayment',
  PROGRESS_STATEMENT = 'progressStatement',
  ORDER = 'order',
  CREDIT = 'credit',
  SUPPLIER_INVOICE = 'supplierInvoice',
  BILL = 'bill',
  HOLDBACK = 'holdback',
}

type EmailRecipient = {
  email: string;
  recipientType: EMAIL_RECIPIENT;
};

export type EmailDetails = {
  emailId: string;
  emailSubject: string;
  sender: string;
  recipients: EmailRecipient[];
};

type CreationDetails = {
  creationDate: Date;
};

type AddPaymentDetails = {
  paymentAmount: number;
  billingDate: Date;
};

type UpdatePaymentDetails = {
  oldPaymentAmount: number;
  newPaymentAmount: number;
  oldBillingDate: Date;
  newBillingDate: Date;
};

type DeletePaymentDetails = {
  paymentAmount: number;
  billingDate: Date;
};

export type QuoteArchiveDetails = {
  isContractVersion: boolean;
  name: string;
  date: Date | null;
  quoteId: number | undefined | string;
  refCode: string | null;
  createdAt: Date;
  pdfId: number | undefined;
};

export type InvoiceArchiveDetails = {
  id: number;
  name: string;
  billingDate: Date;
  invoiceNumber: string | null;
  createdAt: Date;
  pdfId: number | undefined;
  statusWhenArchived: INVOICE_STATUSES;
};

export type ProgressStatementArchiveDetails = {
  id: number;
  name: string;
  billingDate: Date;
  invoiceNumber: string | null;
  createdAt: Date;
  pdfId: number | undefined;
  statusWhenArchived: PROGRESS_STATEMENT_STATUSES;
};

export type DownPaymentArchiveDetails = {
  id: number;
  name: string;
  billingDate: Date;
  invoiceNumber: string | null;
  createdAt: Date;
  pdfId: number | undefined;
  statusWhenArchived: DOWN_PAYMENT_STATUSES;
  isInvoiced: boolean;
};

export type ArchiveDetails =
  | QuoteArchiveDetails
  | InvoiceArchiveDetails
  | ProgressStatementArchiveDetails
  | DownPaymentArchiveDetails;

export type HistoryMap = {
  email: EmailDetails;
  archive: ArchiveDetails;
  creation: CreationDetails;
  addPayment: AddPaymentDetails;
  updatePayment: UpdatePaymentDetails;
  deletePayment: DeletePaymentDetails;
};

export type HistoryActionType = keyof HistoryMap;

export interface IHistory<T extends HistoryActionType> {
  id: string;

  actionType: T;

  actionDetails: HistoryMap[T];

  user?: IUser | null;

  familyId: string;

  createdAt: Date;

  updatedAt: Date;
}
