import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { EmailAutocompleteContact } from '@graneet/lib-ui';
import {
  Card,
  ConfirmDeletionModal,
  DocumentStatusCard,
  DocumentStatusCardActions,
  formatDateOrEmpty,
  GotoLink,
  useToast,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import type {
  IContactQuoteResponseDTO,
  IProjectWithRelations,
  IQuote,
  IQuoteCheckResponseDTO,
  IQuoteFileColumns,
  IQuoteUpdateDTO,
} from '@graneet/business-logic';
import {
  getEmailVariableData,
  isQuoteInformationEditable,
  isQuoteLinkedToContract,
  PERMISSION,
  QUOTE_STATUS,
  PDF_STATUSES,
} from '@graneet/business-logic';

import * as quoteApi from '../../services/quote.api';
import { updateQuoteStatusToAccepted } from '../../services/quote.api';
import { QuoteStatusBadge } from '../badges/QuoteStatusBadge';
import { ConfirmQuoteAsLostModal } from '../modals/ConfirmQuoteAsLostModal';
import { AcceptQuoteModal } from '../modals/AcceptQuoteModal/AcceptQuoteModal';
import { AlertQuoteStatusCompletionErrorModal } from '../modals/AlertQuoteStatusCompletionErrorModal';
import { EditQuoteStatusModal } from '../modals/EditQuoteStatusModal';
import { CreateQuoteModal } from '../modals/CreateQuoteModal';

import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import type { MailingModalProps } from 'features/mailing/components/MailingModal/MailingModal';
import { sendMail } from 'features/mailing/services/mailing.api';
import { getQuoteActions, getQuoteStatusCardIcon } from 'features/quote/services/quote.actions';
import { MailingModal } from 'features/mailing/components/MailingModal/MailingModal';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import { getDownloadUrl, getPreviewUrl } from 'features/pdf/services/pdf.api';
import { formatFileName } from 'features/file/services/file.util';

interface QuoteStatusCardProps {
  quote: IQuote;

  quoteContacts: IContactQuoteResponseDTO;

  project: IProjectWithRelations | null;

  currentVersion: IQuoteFileColumns | undefined;

  reloadQuote(): void;
}

export const QuoteStatusCard: FC<QuoteStatusCardProps> = ({
  quote,
  project,
  currentVersion,
  reloadQuote,
  quoteContacts,
}) => {
  const { t } = useTranslation(['global', 'quote', 'mailing']);
  const history = useHistory();
  const toast = useToast();
  const { createRedirectionWithSavedFilters } = useFiltersQuery();

  const [isPdfGenerated, setIsPdfGenerated] = useState(quote.pdf?.status === PDF_STATUSES.GENERATED);

  const hasCreatePermission = usePermissions([PERMISSION.CREATE_QUOTES]);
  const hasAcceptPermission = usePermissions([PERMISSION.ACCEPT_QUOTES]);

  const [isStatusUpdating, setIsStatusUpdating] = useState(false);

  const completeErrorsRef = useRef<IQuoteCheckResponseDTO>({
    areMandatoryFieldsFilled: true,
    isQuoteCompletable: false,
    incompleteJobsCount: 0,
    isClientAttached: true,
  });
  const selectedProjectRef = useRef(quote.project || undefined);

  const editModal = useDisclosure();
  const completeErrorsModal = useDisclosure();
  const markQuoteAsLostModal = useDisclosure();
  const acceptQuoteProjectModal = useDisclosure();
  const mailingModal = useDisclosure();
  const duplicationModal = useDisclosure();
  const deletionModal = useDisclosure();

  const redirectToQuoteDetail = useCallback(() => {
    history.push(`/opportunities/quotes/${quote.id}/view`);
  }, [history, quote.id]);

  const settledDateRef = useRef<string>();
  const isEditable = isQuoteInformationEditable(quote);

  const statusCardIcon = useMemo(() => getQuoteStatusCardIcon(quote.status), [quote.status]);

  const footerText = useMemo(() => {
    if (quote.status === QUOTE_STATUS.IMPORTING) {
      return t('quote:cards.state.breakdownBeingImported');
    }

    if (quote.status === QUOTE_STATUS.DRAFT) {
      return t('quote:versions.waiting');
    }

    return undefined;
  }, [quote.status, t]);

  // callbacks
  const goToEdit = useCallback(
    (quoteId: number) => {
      history.push(`/opportunities/quotes/${quoteId}/edit`, { quoteId });
    },
    [history],
  );

  const onEdit = useCallback(() => {
    if (isEditable) {
      goToEdit(quote.id);
    } else {
      editModal.onOpen();
    }
  }, [quote, goToEdit, editModal, isEditable]);

  const onCompleteQuote = useCallback(async () => {
    setIsStatusUpdating(true);
    const [checkError, check] = await quoteApi.checkQuoteCompletion(quote.id);

    if (checkError) {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
      setIsStatusUpdating(false);
      return;
    }

    if (!check.isQuoteCompletable) {
      completeErrorsRef.current = check;
      completeErrorsModal.onOpen();
      setIsStatusUpdating(false);
      return;
    }

    const [updateError] = await quoteApi.updateQuoteStatusToCompleted(quote.id);

    if (updateError) {
      toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
    } else {
      toast.success(t('global:words.c.success'), t('quote:completeModal.toastSuccess'));
      reloadQuote();
    }

    setIsStatusUpdating(false);
  }, [completeErrorsModal, quote.id, reloadQuote, t, toast]);

  const handleQuoteInvoiced = useCallback(
    async (projectId: number, subProjectId: string | null | undefined, settledDate: string) => {
      setIsStatusUpdating(true);
      const [acceptError, updatedQuote] = await updateQuoteStatusToAccepted(quote.id, {
        settledDate,
        projectId,
        subProjectId: subProjectId || null,
      });
      setIsStatusUpdating(false);

      if (acceptError) {
        return [t('quote:errors.cannotTransformToCommand', { email: SUPPORT_EMAIL })];
      }

      toast.success(
        t('global:words.c.success'),
        t('quote:successes.commandAdded', { name: updatedQuote?.project?.name }),
      );
      history.push(`/projects/${projectId}/contracts`);

      return [];
    },
    [history, quote.id, t, toast],
  );

  const onAssociateSubProject = useCallback(
    async (projectId: number, subProjectId: string | null, settledDate: string) => {
      setIsStatusUpdating(true);
      const [error] = await handleQuoteInvoiced(projectId, subProjectId, settledDate);
      setIsStatusUpdating(false);
      if (error) {
        toast.error(t('global:words.c.error'), error);
        return;
      }
      acceptQuoteProjectModal.onClose();
    },
    [acceptQuoteProjectModal, handleQuoteInvoiced, t, toast],
  );

  const handleProjectCreation = useCallback(
    (settledDate: string) => {
      settledDateRef.current = settledDate;

      history.push('/projects/create', {
        quoteId: quote?.id,
        settledDate,
      });
    },
    [history, quote?.id],
  );

  const duplicateQuote = useCallback(
    async (values: IQuoteUpdateDTO) => {
      const [err, duplicatedQuote] = await quoteApi.duplicateQuote(quote.id, values);

      if (err) {
        toast.error(t('global:words.c.error'), t('global:errors.contactAdmin', { email: SUPPORT_EMAIL }));
        return;
      }

      toast.success(t('global:words.c.success'), t('quote:creationForm.duplication.toastSuccess'));
      history.push(`/opportunities/quotes/${duplicatedQuote.id}`);
    },
    [history, quote.id, t, toast],
  );

  const redirectToExport = useCallback(() => {
    history.push(`/opportunities/quotes/${quote.id}/export`);
  }, [history, quote.id]);

  const deleteQuote = useCallback(async () => {
    const [error] = await quoteApi.deleteQuote(quote.id);

    if (error) {
      toast.error(t('global:errors.error'));
      return;
    }

    toast.success(t('quote:cards.delete.success', { name: quote.name }));
    createRedirectionWithSavedFilters('/opportunities/quotes', { replace: true })();
  }, [createRedirectionWithSavedFilters, quote.id, t, toast, quote.name]);

  const onViewPdf = useCallback(() => {
    if (currentVersion) {
      window.open(getPreviewUrl(currentVersion.pdf.apiId));
    }
  }, [currentVersion]);

  const onDownloadPdf = useCallback(() => {
    if (currentVersion) {
      window.open(
        getDownloadUrl(
          currentVersion.pdf.apiId,
          formatFileName(currentVersion.name, currentVersion.date, currentVersion.refCode),
        ),
      );
    }
  }, [currentVersion]);

  const onMailSubmit = useCallback<MailingModalProps['onSubmit']>(
    (data) => sendMail(data.dto, data.attachments, data.mode),
    [],
  );

  const isLinkedToContract = isQuoteLinkedToContract(quote);

  const cardSubtitle = useMemo(
    () =>
      quote.status === QUOTE_STATUS.ACCEPTED
        ? [t('quote:cards.state.acceptedOn'), formatDateOrEmpty(quote.settledDate, 'LL')].join(' ')
        : t('quote:fields.creationDate', {
            date: formatDateOrEmpty(quote.createdAt, 'LL'),
          }),
    [quote.status, quote.createdAt, quote.settledDate, t],
  );

  const canOpenMailing = !!quote.pdf && !!quote.client && isPdfGenerated;

  const defaultMailContacts = useMemo(
    () =>
      quoteContacts
        .filter((quoteContact) => quoteContact.isQuoteDefaultEmailRecipient && quoteContact.email)
        .map((quoteContact) => ({
          firstName: quoteContact.firstName,
          lastName: quoteContact.lastName,
          email: quoteContact.email,
          role: quoteContact.role ?? undefined,
        })) as EmailAutocompleteContact[],
    [quoteContacts],
  );

  useEffect(() => {
    if (quote?.pdf?.status === PDF_STATUSES.GENERATING) {
      subscribeToPDFUpdates({ apiId: quote.pdf!.apiId }, (pdfStatus) => {
        setIsPdfGenerated(pdfStatus === PDF_STATUSES.GENERATED);
      });
    }
  }, [quote]);

  return (
    <Card
      title={
        <Flex>
          <Text mr={4}>{t('quote:cards.state.title')}</Text>
          {isLinkedToContract && (
            <GotoLink to={`/projects/${quote.project!.id}/contracts`} label={t('quote:cards.state.goToContracts')} />
          )}
        </Flex>
      }
    >
      <DocumentStatusCard
        mb={4}
        icon={statusCardIcon}
        title={quote.name}
        subtitle={cardSubtitle}
        footer={
          footerText ? (
            <Text fontSize=".8em" color="black">
              {footerText}
            </Text>
          ) : undefined
        }
        badgeComponent={<QuoteStatusBadge quote={quote} />}
      />

      <DocumentStatusCardActions
        actions={getQuoteActions({
          quote,
          pdf: currentVersion?.pdf,

          isCompleteButtonLoading: isStatusUpdating,
          isAcceptButtonLoading: isStatusUpdating,

          hasCreatePermission,
          hasAcceptPermission,

          canOpenMailing,

          onOpenDetail: redirectToQuoteDetail,
          onCompleteQuote,
          onEdit,
          onMarkAsAccepted: acceptQuoteProjectModal.onOpen,
          onMarkAsLost: markQuoteAsLostModal.onOpen,
          onViewPdf,
          onDownloadPdf,
          onOpenMailing: mailingModal.onOpen,
          onGenerateExport: redirectToExport,
          onDuplicate: duplicationModal.onOpen,
          onDelete: deletionModal.onOpen,
        })}
      />

      {quote.status === QUOTE_STATUS.COMPLETED && (
        <>
          <ConfirmQuoteAsLostModal
            modalControls={markQuoteAsLostModal}
            quoteId={quote.id}
            onMarkedAsLost={reloadQuote}
          />
          <AcceptQuoteModal
            project={selectedProjectRef.current ?? null}
            modalControls={acceptQuoteProjectModal}
            onAssociateSubProject={onAssociateSubProject}
            onCreateProject={handleProjectCreation}
            quote={quote}
          />
        </>
      )}

      <AlertQuoteStatusCompletionErrorModal
        modalControls={completeErrorsModal}
        onEdit={onEdit}
        quote={quote}
        errors={completeErrorsRef.current}
      />

      <EditQuoteStatusModal modalControls={editModal} onSuccess={goToEdit} quote={quote} />

      {canOpenMailing && !!quote.client && (
        <MailingModal
          title={t('mailing:modal.title.quote')}
          isOpen={mailingModal.isOpen}
          onClose={mailingModal.onClose}
          onSubmit={onMailSubmit}
          variableData={getEmailVariableData('quote', {
            quote,
            project: project || null,
            client: quote.client,
          })}
          defaultMailContacts={defaultMailContacts}
        />
      )}

      <CreateQuoteModal
        title={t('quote:creationForm.duplication.title')}
        description={t('quote:creationForm.duplication.description')}
        action={t('quote:creationForm.duplication.submit')}
        onSubmit={duplicateQuote}
        {...duplicationModal}
      />

      <ConfirmDeletionModal
        action={t('quote:cards.delete.action')}
        title={t('quote:cards.delete.title')}
        description={t('quote:cards.delete.modalConfirm')}
        onDeleted={deleteQuote}
        {...deletionModal}
      />
    </Card>
  );
};
