import type { FC } from 'react';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, GridItem } from '@chakra-ui/react';
import { TwoColumns } from '@graneet/lib-ui';
import { useParams } from 'react-router-dom';
import { Form, useForm } from 'graneet-form';

import { useSupplierInvoice, useSupplierInvoiceUpdate } from 'features/supplier-invoice/services/supplier-invoice.api';
import { CashOutFlowStatusCard } from 'features/cash-outflow/components/CashOutFlowStatusCard/CashOutFlowStatusCard';
import { CashOutflowSupplierInvoiceCard } from 'features/cash-outflow/components/cards/CashOutflowSupplierInvoiceCard';
import { CashOutflowPaymentsTable } from 'features/cash-outflow/components/CashOutflowPaymentTable/CashOutFlowPaymentsTable';
import { CashOutflowInformationCard } from 'features/cash-outflow/components/cards/CashOutFlowInformationCard';
import type { CommentFormValues } from 'features/supplier-invoice/forms/supplier-invoice.form';
import { SupplierInvoiceCommentCard } from 'features/supplier-invoice/components/cards/SupplierInvoiceCommentCard';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewCashOutflowScreen: FC = () => {
  const { t } = useTranslation(['global', 'supplierInvoices', 'cashOutflow']);
  const { updateHeaderTitle } = useHeaderContext();
  const { id } = useParams<{ id: string }>();

  const supplierInvoice = useSupplierInvoice(parseInt(id, 10));

  const supplierInvoiceUpdateMutation = useSupplierInvoiceUpdate();

  const commentForm = useForm<CommentFormValues>({
    onUpdateAfterBlur: async (_, newComment) => {
      supplierInvoiceUpdateMutation.mutate({
        id: supplierInvoice.data.id,
        dto: {
          comment: newComment,
          deletedReceiptFilesIds: JSON.stringify([]),
          deleteInvoiceFile: JSON.stringify(false),
        },
      });
    },
    defaultValues: {
      comment: supplierInvoice.data.comment ?? undefined,
    },
  });

  useLayoutEffect(() => {
    updateHeaderTitle(supplierInvoice.data.name ?? '', [
      {
        name: t('cashOutflow:cashOutflow_plural'),
        link: '/purchases/cash-outflows',
      },
    ]);
  }, [t, supplierInvoice.data.name, updateHeaderTitle]);

  return (
    <Box p={8}>
      <TwoColumns>
        <CashOutFlowStatusCard supplierInvoice={supplierInvoice.data} />
        <CashOutflowInformationCard supplierInvoice={supplierInvoice.data} />
        <CashOutflowSupplierInvoiceCard supplier={supplierInvoice.data.supplier} />

        <Form form={commentForm}>
          <SupplierInvoiceCommentCard<CommentFormValues> name="comment" />
        </Form>

        <GridItem colSpan={2}>
          <CashOutflowPaymentsTable supplierInvoice={supplierInvoice.data} />
        </GridItem>
      </TwoColumns>
    </Box>
  );
};
