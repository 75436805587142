import type { ICompany } from '../company/company.type';

import { generateValuesAndState } from './generators';
import type { GeneratorConfigObject, ILedger, LEDGER_TYPES } from './ledger.type';
import { LEDGER_GENERATORS } from './ledger.type';

type LedgerByType<T> = Partial<Record<LEDGER_TYPES, T>>;

/**
 * The purpose of this function is to avoid doing ledgers.find(l => l.type == ...) everywhere.
 *
 * @param an array of ledgers
 * @returns an object keyed by the type of the ledger. ie. { QUOTE: Ledger, STATEMENT: Ledger }
 */
export function keyLedgersByType<T extends ILedger>(ledgers: T[]): LedgerByType<T> {
  return ledgers.reduce(
    (acc, ledger) => ({
      ...acc,
      [ledger.type]: ledger,
    }),
    {},
  ) as LedgerByType<T>;
}

export type DateFormat = [] | ['YY'] | ['YYYY'] | ['YY', 'MM'] | ['MM', 'YY'] | ['YYYY', 'MM'] | ['MM', 'YYYY'];
export type ResetCounterEvery = 'MONTH' | 'YEAR' | null;
export type Separator = '-' | '_' | '/' | '.';
export enum ConfigBlock {
  DATE_Y = 'date_y',
  DATE_M = 'date_m',
  SEPARATOR = 'separator',
  COUNTER = 'counter',
  PREFIX = 'prefix',
}

export function generateLedgerFormatFromSettings({
  dateFormat,
  hasPrefix,
  hasSeparator,
}: {
  dateFormat: DateFormat;
  hasPrefix: boolean;
  hasSeparator: boolean;
}): string {
  let format = '';
  if (hasPrefix) {
    format += `{{${ConfigBlock.PREFIX}}}`;
  }
  if (hasPrefix && hasSeparator) {
    format += `{{${ConfigBlock.SEPARATOR}}}`;
  }
  if (dateFormat[0]) {
    format += `{{${dateFormat[0].includes('Y') ? ConfigBlock.DATE_Y : ConfigBlock.DATE_M}}}`;
  }
  if (dateFormat[1]) {
    format += `{{${dateFormat[1].includes('Y') ? ConfigBlock.DATE_Y : ConfigBlock.DATE_M}}}`;
  }
  if (dateFormat.length && hasSeparator) {
    format += `{{${ConfigBlock.SEPARATOR}}}`;
  }
  format += `{{${ConfigBlock.COUNTER}}}`;
  return format;
}

type UserConfiguredLedgerFormat = {
  [ConfigBlock.COUNTER]: { type: LEDGER_GENERATORS.COUNTER; size?: number; groupBy: ConfigBlock[] };
  [ConfigBlock.PREFIX]?: { type: LEDGER_GENERATORS.STATIC; value: string };
  [ConfigBlock.DATE_Y]?: { type: LEDGER_GENERATORS.DATE; format: string };
  [ConfigBlock.DATE_M]?: { type: LEDGER_GENERATORS.DATE; format: string };
  [ConfigBlock.SEPARATOR]?: { type: LEDGER_GENERATORS.STATIC; value: string };
};

function getCounterGroupBy(dateFormat: DateFormat, resetCounterEvery: ResetCounterEvery): ConfigBlock[] {
  if (dateFormat.length === 0 || !resetCounterEvery) {
    return [];
  }
  if (resetCounterEvery === 'YEAR') {
    return [ConfigBlock.DATE_Y];
  }
  if (resetCounterEvery === 'MONTH' && dateFormat.length === 2) {
    return [ConfigBlock.DATE_Y, ConfigBlock.DATE_M];
  }
  return [];
}

export function generateLedgerConfigFromSettings({
  dateFormat,
  prefix,
  resetCounterEvery,
  separator,
  counterSize,
}: {
  dateFormat: DateFormat;
  resetCounterEvery: ResetCounterEvery;
  separator?: Separator;
  prefix?: string;
  counterSize?: number;
}): UserConfiguredLedgerFormat {
  const config: UserConfiguredLedgerFormat = {
    counter: {
      type: LEDGER_GENERATORS.COUNTER,
      ...(counterSize && { size: counterSize }),
      groupBy: getCounterGroupBy(dateFormat, resetCounterEvery),
    },
  };
  if (prefix) {
    config.prefix = { type: LEDGER_GENERATORS.STATIC, value: prefix };
  }
  if (dateFormat.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    config.date_y = { type: LEDGER_GENERATORS.DATE, format: (dateFormat as string[]).find((f) => f.includes('Y'))! };
  }
  if (dateFormat.length > 1) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    config.date_m = { type: LEDGER_GENERATORS.DATE, format: (dateFormat as string[]).find((f) => f.includes('M'))! };
  }
  // Don't generate separator block if there isn't anything to separate
  if (separator && (dateFormat.length !== 0 || prefix)) {
    config.separator = { type: LEDGER_GENERATORS.STATIC, value: separator };
  }

  return config;
}

export function computeNewLedgerFromSettings({
  dateFormat,
  resetCounterEvery,
  counterSize,
  separator,
  nextCounterValue = 1,
  prefix,
  company,
}: {
  dateFormat: DateFormat;
  resetCounterEvery: ResetCounterEvery;
  counterSize?: number;
  separator?: Separator;
  nextCounterValue: number;
  prefix?: string;
  company?: ICompany;
}): Pick<ILedger, 'config' | 'state' | 'format'> & { preview: string } {
  const config = generateLedgerConfigFromSettings({
    dateFormat,
    prefix,
    separator,
    counterSize,
    resetCounterEvery,
  }) as GeneratorConfigObject;

  const format = generateLedgerFormatFromSettings({
    dateFormat,
    hasPrefix: prefix !== undefined,
    hasSeparator: separator !== undefined,
  });

  const { nextStateWithCounter: state, formattedNumber: preview } = generateValuesAndState(
    { config, format, state: {} },
    { company, referenceDate: new Date() },
    // -1 because we want the state to be one less than the expected next value, and again -1 because generateValueAndState will increase the counter
    nextCounterValue - 2,
  );
  return {
    config,
    format,
    state,
    preview,
  };
}
