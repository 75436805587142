import { useMemo } from 'react';
import type { ICompany, IDownPayment, IInvoice, IPaymentMethodResponseDTO, IProject } from '@graneet/business-logic';

import { useFirstDefinedValue } from 'features/common/hooks/useFirstDefinedValue';

/*
  This method returns payment conditions in function of project and company
  It is used with initial values but also when project is updated
  If a project is linked, payment conditions come from it
  Otherwise, company default values are selected
 */
export const extractPaymentConditions = (
  project: IProject | null | undefined,
  companyPaymentTerms?: Pick<ICompany, 'paymentTerm' | 'deadlineType'> | null,
  paymentMethods?: IPaymentMethodResponseDTO[] | null,
) => {
  if (project) {
    const { paymentMethod, paymentTerm, deadlineType } = project;
    return { paymentMethod, paymentTerm, deadlineType };
  }

  const { paymentTerm, deadlineType } = companyPaymentTerms || {};
  const paymentMethod = paymentMethods?.find((pm) => pm.isDefault);
  return { paymentMethod, paymentTerm, deadlineType };
};

export const useInitialPaymentConditions = <T extends IInvoice | IDownPayment>({
  paymentMethods,
  statement,
  companyPaymentTerms,
  project,
}: {
  paymentMethods: IPaymentMethodResponseDTO[] | null;
  statement?: T | null;
  companyPaymentTerms?: Pick<ICompany, 'paymentTerm' | 'deadlineType'> | null;
  project?: IProject | null;
}) => {
  const memoizedData = useMemo(() => {
    if (statement) {
      const { paymentMethod, paymentTerm, deadlineType } = statement;
      return { paymentMethod, paymentTerm, deadlineType };
    }

    return extractPaymentConditions(project, companyPaymentTerms, paymentMethods);
  }, [statement, project, companyPaymentTerms, paymentMethods]);

  return useFirstDefinedValue(memoizedData);
};
