import type { FC, ReactNode } from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { Button, Callout, Card, SimpleAlertIcon, SimpleDeleteIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import type { IUpdateCompanyOCRCreditDTO } from '@graneet/business-logic';
import { LONG_DATE_FORMAT_FR } from '@graneet/business-logic';
import dayjs from 'dayjs';

import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import type { UseDisabledButtonPropsReturn } from 'features/role/hooks/useDisabledButtonProps';

interface UpdatableInvoiceFileCardHeaderProps {
  children: ReactNode;

  hasOCR: boolean;

  ocrCreditData: IUpdateCompanyOCRCreditDTO;

  isLoading?: boolean;

  withCardHeader?: boolean;

  onFileDelete(): void;

  disableButtonProps?: UseDisabledButtonPropsReturn;
}

export const UpdatableInvoiceFileCardHeader: FC<UpdatableInvoiceFileCardHeaderProps> = ({
  children,
  hasOCR,
  ocrCreditData,
  isLoading,
  withCardHeader,
  disableButtonProps,
  onFileDelete,
}) => {
  const { t } = useTranslation(['supplierInvoices', 'global']);

  const form = useFormContext();
  const { invoiceFile } = useOnChangeValues(form, ['invoiceFile']);
  const hasInvoiceFile = !!invoiceFile;

  let isOCRCreditAvailable = false;
  let limitOCRCredit = 0;
  let consumedOCRCredit = 0;
  let formatResetDate = '';

  formatResetDate = dayjs(ocrCreditData.planPeriodResetDate).add(1, 'month').format(LONG_DATE_FORMAT_FR);
  limitOCRCredit = ocrCreditData.limitOCRCredit || 0;
  consumedOCRCredit = ocrCreditData.consumedOCRCredit || 0;
  isOCRCreditAvailable = consumedOCRCredit < limitOCRCredit;

  const invoiceFileChildren = (
    <VStack gap={4} w="100%" h="100%" justifyContent="center">
      {children}
      {!hasInvoiceFile &&
        hasOCR &&
        (isOCRCreditAvailable ? (
          <Callout w="100%">
            <Text whiteSpace="pre-line">
              {t('supplierInvoices:ocrCreditStatus.ocrConsumption', {
                consumedOCRCredit,
                limitOCRCredit,
                formatResetDate,
              })}
            </Text>
          </Callout>
        ) : (
          <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
            <Text whiteSpace="pre-line">
              {t('supplierInvoices:ocrCreditStatus.noMoreOCRCredit', {
                limitOCRCredit,
                formatResetDate,
                SUPPORT_EMAIL,
              })}
            </Text>
          </Callout>
        ))}
    </VStack>
  );

  if (withCardHeader) {
    return (
      <Card
        title={t('supplierInvoices:fields.invoiceFile')}
        h="100%"
        topRightContent={
          hasInvoiceFile &&
          !isLoading && (
            <Button
              variant="outline"
              colorScheme="gray"
              leftIcon={<SimpleDeleteIcon />}
              onClick={onFileDelete}
              _hover={{
                background: 'none',
                color: `red.500`,
              }}
              {...disableButtonProps}
            >
              {t('global:words.c.delete')}
            </Button>
          )
        }
      >
        {invoiceFileChildren}
      </Card>
    );
  }

  return (
    <Flex position="relative" h="100%" className="invoice-file">
      {hasInvoiceFile && !isLoading && (
        <Box position="absolute" right={0} zIndex={10} pt={2}>
          <Button
            className="delete-button"
            variant="outline"
            leftIcon={<SimpleDeleteIcon />}
            onClick={onFileDelete}
            display="none"
            colorScheme="gray"
            background="white"
            sx={{
              '.invoice-file:hover &': {
                display: 'block',
                background: 'white',
                '&.delete-button:hover': {
                  color: `red.500`,
                },
              },
            }}
            transition="visibility 0s, opacity 0.1s linear"
          >
            {t('global:words.c.delete')}
          </Button>
        </Box>
      )}
      {invoiceFileChildren}
    </Flex>
  );
};
