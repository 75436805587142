import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Modal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import type { ILibraryComponentWithRelations } from '@graneet/business-logic';

import { ImportLibraryComponentListing } from '../ImportLibraryComponentListing';

interface RawImportLibraryComponentModalProps extends Pick<UseDisclosureReturn, 'isOpen' | 'onClose'> {
  onLibraryComponentsImported(libraryComponentsIds: number[]): Promise<void>;

  nonSelectableIds: number[];
}

export const LibraryComponentImportModal: FC<RawImportLibraryComponentModalProps> = ({
  onLibraryComponentsImported,
  nonSelectableIds,
  ...props
}) => {
  const { t } = useTranslation(['library']);

  const [isImporting, setIsImporting] = useState(false);
  const [selectedRows, setSelectedRows] = useState<ILibraryComponentWithRelations[]>([]);

  const handleSubmit = useCallback(async () => {
    const libraryComponentsIds = selectedRows.map((libraryComponent) => libraryComponent.id);

    setIsImporting(true);
    await onLibraryComponentsImported(libraryComponentsIds);
    setIsImporting(false);
  }, [selectedRows, onLibraryComponentsImported]);

  return (
    <Modal
      title={t('library:importLibraryComponentToLibraryJobModal.title')}
      size="6xl"
      fullHeight
      scrollBehavior="inside"
      {...props}
    >
      <Text mb={4}>{t('library:importLibraryComponentToLibraryJobModal.description')}</Text>

      <Box h="40rem">
        <ImportLibraryComponentListing onRowSelected={setSelectedRows} nonSelectableIds={nonSelectableIds} />
      </Box>

      <Modal.Close onClick={() => setSelectedRows([])} />

      <Modal.PrimaryButton isDisabled={selectedRows.length === 0} onClick={handleSubmit} isLoading={isImporting}>
        {t('library:importLibraryComponentToQuoteJobModal.cta', { count: selectedRows.length })}
      </Modal.PrimaryButton>
    </Modal>
  );
};
