import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';
import { useTheme } from '@chakra-ui/react';

export const EqualIcon: FC<IconProps> = (props) => {
  const theme = useTheme();

  return (
    <Icon viewBox="0 0 40 40" {...props}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="9" y="15" width="22" height="3" fill={theme.colors.gray['500']} />
        <rect x="9" y="22" width="22" height="3" fill={theme.colors.gray['500']} />
      </svg>
    </Icon>
  );
};
