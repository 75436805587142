import type { QuoteLotUpsertDTO } from '@org/graneet-bff-client';
import type { QuoteNode } from '@org/quotation-lib';

import { mapMarginObjectToMarginDTO } from './quoteMarginObjectToMarginDTO';
import { genericUpdateItemMapper } from './quoteItemsGenericMapper';

export const mapQuoteLotToUpdateAmount = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteLotUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteLot' && quoteNodeObject.parentId) {
    return {
      amount: content.amount ?? undefined,
      quoteLotId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteLot');
};

export const mapQuoteLotToUpdateTotalMargin = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteLotUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteLot' && quoteNodeObject.parentId) {
    return {
      margin: mapMarginObjectToMarginDTO(content.margin),
      quoteLotId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content Type, is not QuoteLot');
};

export const mapQuoteNodeToUpsertLotDTO = (
  quoteNode: QuoteNode,
  quoteId: string,
  clientRequestId: string,
  timestamp: string,
): QuoteLotUpsertDTO => {
  const quoteNodeObject = quoteNode.export();
  const content = quoteNode.getContent().export();
  if (content.type === 'QuoteLot' && quoteNodeObject.parentId) {
    return {
      denomination: content.denomination,
      manualIndex: content.manualIndex,
      note: content.note,
      amount: content.amount !== null ? content.amount : undefined,
      margin: mapMarginObjectToMarginDTO(content.margin),
      quoteLotId: content.id,
      ...genericUpdateItemMapper(quoteId, quoteNode, clientRequestId, timestamp),
    };
  }
  throw new Error('Content is not QuoteBasicItem');
};
