import { AccountIcon, ActionMenu, QuoteIcon, SimpleAddIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FC } from 'react';

interface ContactAddOrAssociateButtonProps {
  onCreate: () => void;

  onAssociate: () => void;
}

export const ContactAddOrAssociateButton: FC<ContactAddOrAssociateButtonProps> = ({ onCreate, onAssociate }) => {
  const { t } = useTranslation(['contacts']);

  return (
    <ActionMenu
      buttonProps={{
        variant: 'secondary',
        leftIcon: <SimpleAddIcon />,
        children: t('contacts:card.action'),
      }}
    >
      <ActionMenu.Action
        icon={<AccountIcon />}
        label={t('contacts:card.buttons.createNew')}
        onClick={() => onCreate()}
      />
      <ActionMenu.Action
        icon={<QuoteIcon />}
        label={t('contacts:card.buttons.addExisting')}
        onClick={() => onAssociate()}
      />
    </ActionMenu>
  );
};
