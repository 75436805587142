import type { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { DisplayNotFoundScreen } from '../../DisplayNotFoundScreen';

import { ViewSuppliersScreen } from './ViewSuppliersScreen';
import { ViewSupplierScreen } from './ViewSupplierScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';

export const SuppliersRouter: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <QueryWrapper>
          <ViewSuppliersScreen />
        </QueryWrapper>
      </Route>

      <Route path={`${path}/:supplierId`}>
        <QueryWrapper>
          <ViewSupplierScreen />
        </QueryWrapper>
      </Route>

      <Route path="*">
        <DisplayNotFoundScreen />
      </Route>
    </Switch>
  );
};
