import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import type { GridItemProps, GridProps } from '@chakra-ui/react';
import { Grid, GridItem } from '@chakra-ui/react';

import { Card } from '../../Card/Card';

import { DifferenceSummaryItem } from './DifferenceSummaryItem';
import { Discount } from './Discount';
import { VATDistribution } from './VATDistribution';
import { configureDefaultLabels, differenceSummaryTranslations } from './DifferenceSummaryTranslations';

const GRID_ITEM_HEADER: GridItemProps = {
  flex: 1,
  textAlign: 'right',
  color: 'gray.800',
  fontSize: 'sm',
  mb: 2,
};

export type DifferenceSummaryProps = PropsWithChildren<GridProps>;

const DifferenceSummaryComponent: FC<DifferenceSummaryProps> = ({ children, ...props }) => {
  const templateColumns = useMemo(() => '1fr minmax(8rem, auto) minmax(5rem, auto) minmax(10rem, auto) 2rem', []);

  return (
    <Card minW={600}>
      <Grid templateColumns={templateColumns} alignItems="center" gap={2} {...props}>
        <GridItem />
        <GridItem {...GRID_ITEM_HEADER}>{differenceSummaryTranslations.initialAmount}</GridItem>
        <GridItem />
        <GridItem {...GRID_ITEM_HEADER}>{differenceSummaryTranslations.newAmount}</GridItem>
        <GridItem />
        {children}
      </Grid>
    </Card>
  );
};

export const DifferenceSummary = Object.assign(DifferenceSummaryComponent, {
  Item: DifferenceSummaryItem,
  VATDistribution,
  Discount,
  configureDefaultLabels,
});
