import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleCarrouselRightIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 25" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0.499999C5.70934 0.499998 0.549225 5.34047 0.041074 11.5L12.3333 11.5L10.451 7.97059L12.2157 7.02941L14.8824 12.0294L15.1333 12.5L14.8824 12.9706L12.2157 17.9706L10.451 17.0294L12.3333 13.5L0.0410738 13.5C0.549223 19.6595 5.70934 24.5 12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.499999Z"
      fill="#384250"
    />
  </Icon>
);
