import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps, StyleProps } from '@chakra-ui/react';

interface DownloadPdfIconProps extends IconProps {
  color?: StyleProps['color'];
}

export const DownloadPdfIcon: FC<DownloadPdfIconProps> = ({ color = 'red.500', ...props }) => (
  <Icon viewBox="0 0 16 16" {...props} color={color}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_771_20176)">
        <g clipPath="url(#clip1_771_20176)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.31055 1V15H13.199V4.1115L9.69905 1H2.31055ZM9.05505 1.4725V4.4615H12.6312L9.05505 1.4725Z"
            fill="currentColor"
          />
          <path
            d="M5.28538 9.96066C5.46738 9.96066 5.59862 9.91691 5.67912 9.82941C5.75962 9.74279 5.799 9.62116 5.799 9.46454C5.7999 9.40052 5.78953 9.33684 5.76837 9.27641C5.74645 9.21978 5.71212 9.16877 5.6679 9.12714C5.62369 9.0855 5.57072 9.05429 5.51288 9.03579C5.43941 9.01218 5.36253 9.00094 5.28538 9.00254H4.91963V9.96066H5.28538ZM5.28538 8.47754C5.498 8.47754 5.68 8.50379 5.83225 8.55454C5.98625 8.60441 6.11225 8.67354 6.21025 8.76191C6.31 8.85029 6.38263 8.95441 6.42988 9.07516C6.47713 9.19504 6.49988 9.32541 6.49988 9.46454C6.49988 9.61591 6.47625 9.75329 6.42812 9.88016C6.38093 10.0043 6.30473 10.1153 6.20588 10.2039C6.09526 10.2997 5.96594 10.3715 5.82613 10.4148C5.67388 10.4647 5.49362 10.4892 5.2845 10.4892H4.91963V11.4998H4.21875V8.47754H5.2845H5.28538ZM9.453 9.98779C9.453 10.2065 9.41538 10.4078 9.33925 10.5933C9.264 10.777 9.159 10.9372 9.02163 11.0728C8.87953 11.2102 8.71105 11.3173 8.52638 11.3878C8.33388 11.4622 8.12037 11.4998 7.885 11.4998H6.71075V8.47754H7.885C8.12037 8.47754 8.33387 8.51604 8.5255 8.59129C8.70953 8.65878 8.87719 8.76447 9.01744 8.90141C9.1577 9.03835 9.26737 9.20343 9.33925 9.38579C9.41538 9.56954 9.453 9.77079 9.453 9.98779ZM8.7355 9.98779C8.7355 9.83904 8.71625 9.70341 8.67775 9.58354C8.6436 9.47117 8.58638 9.36717 8.50975 9.27816C8.4376 9.19382 8.34643 9.12783 8.24375 9.08566C8.13021 9.03813 8.00807 9.0146 7.885 9.01654H7.416V10.9608H7.885C8.01887 10.9608 8.13875 10.938 8.24375 10.8943C8.34584 10.8511 8.4368 10.7853 8.50975 10.7018C8.58607 10.6119 8.64323 10.5074 8.67775 10.3947C8.71625 10.2739 8.7355 10.1383 8.7355 9.98779ZM10.4269 9.01654V9.78216H11.4594V10.3238H10.4269V11.4998H9.72163V8.47754H11.6676V9.01654H10.4269Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_771_20176">
          <rect width="11.375" height="14" fill="white" transform="translate(2.31055 1)" />
        </clipPath>
        <clipPath id="clip1_771_20176">
          <rect width="11.375" height="14" fill="white" transform="translate(2.31055 1)" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);
