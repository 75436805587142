import type { QuotesAutoCompleteApi } from '@org/graneet-bff-client';

import { proxyHelperTanStack } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteAutoCompleteProxyApi {
  private static quoteAutoCompleteProxyApi: QuoteAutoCompleteProxyApi;

  private readonly quoteAutoCompleteApi: QuotesAutoCompleteApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteAutoCompleteApi = externalApi.getAutoCompleteApi();
  }

  public static getQuoteSubItemsProxyApi(externalApi: ExternalApi): QuoteAutoCompleteProxyApi {
    if (!this.quoteAutoCompleteProxyApi) {
      QuoteAutoCompleteProxyApi.quoteAutoCompleteProxyApi = new QuoteAutoCompleteProxyApi(externalApi);
    }
    return QuoteAutoCompleteProxyApi.quoteAutoCompleteProxyApi;
  }

  public async search(quoteId: string, searchTerm: string, onlyComponents: boolean) {
    return proxyHelperTanStack(async () =>
      this.quoteAutoCompleteApi.searchQuoteItems(quoteId, searchTerm, onlyComponents),
    );
  }
}
