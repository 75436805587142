import type { FC } from 'react';
import type { BadgeProps } from '@graneet/lib-ui';
import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IQuote } from '@graneet/business-logic';

import { QUOTE_STATUS_COLOR } from 'features/quote/constants/quotes.constant';

interface QuoteStatusBadgeProps extends Omit<BadgeProps, 'children'> {
  quote: IQuote;
}

export const QuoteStatusBadge: FC<QuoteStatusBadgeProps> = ({ quote, ...rest }) => {
  const { t } = useTranslation(['quote']);
  const { status } = quote;

  return (
    <Badge w="auto" minWidth="5.25rem" px={3} colorScheme={QUOTE_STATUS_COLOR[status]} {...rest}>
      {t(`quote:statuses.${status}`)}
    </Badge>
  );
};
