import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MetricWithDescription, Card } from '@graneet/lib-ui';
import type { FC } from 'react';
import type { IDashboardResponseDTO } from '@graneet/business-logic';

interface DashboardStatsCardProps {
  stats: IDashboardResponseDTO | null;
}

export const DashboardStatsCard: FC<DashboardStatsCardProps> = ({ stats }) => {
  const { t } = useTranslation(['dashboard']);

  return (
    <Card>
      <HStack spacing="3rem">
        <MetricWithDescription description={t('dashboard:onGoingProjects')}>
          <>{stats?.onGoingProjectCount}</>
        </MetricWithDescription>
        <MetricWithDescription description={t('dashboard:progressStatementAndInvoicesLastMonthCount')}>
          <>{stats?.progressStatementAndInvoicesLastMonthCount}</>
        </MetricWithDescription>
      </HStack>
    </Card>
  );
};
