import type { QuoteImportDTO, QuoteImportsApi } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteImportsProxyApi {
  private static quoteImportsProxyApi: QuoteImportsProxyApi;

  private readonly quoteImportsApi: QuoteImportsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteImportsApi = externalApi.getQuoteImportsApi();
  }

  public static getQuoteImportsProxyApi(externalApi: ExternalApi): QuoteImportsProxyApi {
    if (!this.quoteImportsProxyApi) {
      QuoteImportsProxyApi.quoteImportsProxyApi = new QuoteImportsProxyApi(externalApi);
    }
    return QuoteImportsProxyApi.quoteImportsProxyApi;
  }

  public async importQuote(id: string, quoteImportDTO: QuoteImportDTO) {
    return proxyHelper(async () => this.quoteImportsApi.importQuote(id, quoteImportDTO));
  }

  public async createQuoteFromImport(
    name: string,
    file: File[],
    refCode?: string | undefined,
    needsQuoteLedger?: boolean | undefined,
    comment?: string | undefined,
    projectId?: number | undefined,
  ) {
    return proxyHelper(async () =>
      this.quoteImportsApi.createQuoteFromImport(name, file, refCode, needsQuoteLedger, comment, projectId),
    );
  }
}
