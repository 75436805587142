import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react';
import { Card, GotoLink } from '@graneet/lib-ui';
import type { SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT } from '@graneet/business-logic';

import { SupplierFieldOption } from 'features/supplier/components/SupplierFieldOption';

export const AssociatedSupplierCard: FC<{ supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT }> = ({
  supplierInvoice,
}) => {
  const { t } = useTranslation(['supplierInvoices']);

  if (!supplierInvoice.supplier) {
    return null;
  }

  return (
    <Card
      title={
        <Flex mb={3}>
          <Text mr={4}>{t('supplierInvoices:cards.supplierCard.title')}</Text>
          <GotoLink
            to={`/contacts/suppliers/${supplierInvoice.supplier.id}`}
            label={t('supplierInvoices:cards.supplierCard.goTo')}
          />
        </Flex>
      }
    >
      <SupplierFieldOption supplier={supplierInvoice.supplier} />
    </Card>
  );
};
