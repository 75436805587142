import { decodeString, encodeString } from '../common/string.util';

import { ERROR_SCREEN_SLUG } from './error.constant';
import type { ErrorInfo } from './error.type';

interface IGetErrorScreenUrlOptions {
  /**
   * Part to add in front of the returned url
   * It could be useful when you use this function in backend to add the frontend url in front of the returned url
   * @default ''
   */
  urlPrefix?: string;
}

export const getErrorScreenUrl = (error: ErrorInfo, options: IGetErrorScreenUrlOptions = {}): string => {
  const encodedError = encodeString(JSON.stringify(error));
  return `${options.urlPrefix || ''}${ERROR_SCREEN_SLUG}#${encodedError}`;
};

export const decodeErrorScreenHash = (hash: string): ErrorInfo | undefined => {
  try {
    const decodedHash = decodeString(hash);
    return JSON.parse(decodedHash);
  } catch (e) {
    console.error('Error while decoding error screen hash', e);
    return undefined;
  }
};
