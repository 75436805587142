import type { FieldValues } from 'graneet-form';
import { usePlaceholder, useWizard, WizardContext } from 'graneet-form';
import type { FlexProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';

import { Stepper } from '../Stepper';

import { Step } from './components';
import { NextStepButton } from './components/NextStepButton';
import { Placeholder } from './components/Placeholder';

export interface StepsProps<StepsValues extends Record<string, FieldValues>> extends FlexProps {
  /**
   * Function run when use quit the wizard
   */
  onQuit(): void | Promise<void>;

  /**
   * Function run at the last step
   */
  onFinish(stepsValues: StepsValues): void;
}

const TopContent = () => {
  const { placeholderContent } = usePlaceholder();

  return placeholderContent.top;
};

const BottomContent = () => {
  const { placeholderContent } = usePlaceholder();

  return placeholderContent.bottom;
};

export const StepsComponent = <StepsValues extends Record<string, FieldValues>>({
  children,
  onQuit,
  onFinish,
}: StepsProps<StepsValues>) => {
  const wizard = useWizard(onFinish, onQuit);

  return (
    <Flex height="100vh" width="100%">
      <WizardContext.Provider value={wizard}>
        <Flex flexDirection="column" justifyContent="space-between" pt="10rem" w="30rem" h="100vh" bg="backgroundLight">
          <TopContent />
          {wizard.steps.length > 1 && <Stepper h="15rem" orientation="vertical" />}
          <BottomContent />
        </Flex>

        <Flex flex={1} direction="column" bg="white" overflow="auto" px={5}>
          {children}
        </Flex>
      </WizardContext.Provider>
    </Flex>
  );
};

export const Steps = Object.assign(StepsComponent, {
  Step,
  Placeholder,
  NextStepButton,
});
