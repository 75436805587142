import type { CSSProperties, FC, FocusEventHandler, ReactNode } from 'react';
import { useEffect, useRef, useCallback, useState } from 'react';
import type { InputProps } from '@chakra-ui/react';
import { Flex, Box, HStack } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import type { EditorState } from 'lexical';
import { $getRoot } from 'lexical';

import { RICH_TEXT_CSS_VAR_DARK, RICH_TEXT_CSS_VAR_LIGHT } from '../../../../constants';
import type { RichTextBitwise } from '../../constants/rich-text-configuration.constant';
import { hasRichTextConfiguration } from '../../constants/rich-text-configuration.constant';
import { useRichTextContext } from '../../RichTextInputProvider';
import { useDebounce } from '../../../../hooks/useDebounce';

import { CustomRichTextInlinePlugin } from './components/CustomRichTextInlinePlugin';
import { CustomRichTextAreaPlugin } from './components/CustomRichTextAreaPlugin';

interface CustomRichTextProps<SearchResultType> {
  placeholder?: ReactNode;

  onFocus?: FocusEventHandler<HTMLDivElement>;

  onBlur?: FocusEventHandler<HTMLDivElement>;

  variant: 'dark' | 'light';

  navbarType: 'inline' | 'block' | 'none';

  shouldDisplayError?: boolean;

  inputProps?: InputProps & { noBorder?: boolean };

  footerContent: ReactNode;

  toolbarContent?: ReactNode[];

  richTextBitwise: RichTextBitwise | undefined;

  autoFocus?: boolean;

  onChange?: (editorState: EditorState) => void;

  onSearch?: (state: string) => Promise<SearchResultType>;

  renderSearchResults?: (searchTerm: string, isLoading: boolean, searchResults?: SearchResultType) => ReactNode;
}

export type CustomRichTextPluginType<T = unknown> = FC<CustomRichTextProps<T>>;

export const CustomRichTextPlugin = <SearchResultType extends unknown>({
  placeholder = '',
  onFocus,
  onBlur,
  onChange,
  variant,
  shouldDisplayError,
  navbarType,
  footerContent,
  toolbarContent,
  inputProps,
  richTextBitwise,
  autoFocus,
  onSearch,
  renderSearchResults,
}: CustomRichTextProps<SearchResultType>) => {
  const [editor] = useLexicalComposerContext();
  const [isAutoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SearchResultType>();

  const {
    internal: { registerEditor, unregisterEditor },
  } = useRichTextContext();

  useEffect(() => {
    registerEditor(editor);

    return () => {
      unregisterEditor();
    };
  }, [editor, registerEditor, unregisterEditor]);

  const inputRef = useRef<HTMLDivElement | null>(null);

  const rootElementRef = useRef<null | HTMLElement>(null);
  const ref = useCallback(
    (rootElement: null | HTMLElement) => {
      rootElementRef.current = rootElement;
      editor.setRootElement(rootElement);
    },
    [editor],
  );

  const [isFocused, setIsFocused] = useState(false);

  const handleOutSideClick = useCallback<FocusEventHandler<HTMLDivElement>>(
    (event) => {
      if (!(onSearch && renderSearchResults)) {
        if (onBlur && isFocused) {
          onBlur(event);
        }
        setIsFocused(false);
        setAutoCompleteOpen(false);
      }
    },
    [isFocused, onBlur, onSearch, renderSearchResults],
  );

  const handleOnFocus = useCallback<FocusEventHandler<HTMLInputElement>>(
    (event) => {
      if (onFocus) {
        onFocus(event);
      }
      setIsFocused(true);
    },
    [onFocus],
  );

  const focusRootElement = useCallback(() => {
    if (!rootElementRef.current?.textContent) {
      rootElementRef.current?.focus();
    } else {
      editor.focus(undefined, {
        defaultSelection: 'rootEnd',
      });
    }
  }, [editor]);

  useEffect(() => {
    if (autoFocus) {
      focusRootElement();
    }
  }, [autoFocus, focusRootElement]);

  const style: CSSProperties = variant === 'dark' ? RICH_TEXT_CSS_VAR_DARK : RICH_TEXT_CSS_VAR_LIGHT;
  const color = variant === 'dark' ? 'black' : 'gray.700';

  const { noBorder } = inputProps || {};

  const TextPlugin = hasRichTextConfiguration(richTextBitwise, 'style') ? RichTextPlugin : PlainTextPlugin;

  const handleSearch = useDebounce(async (searchValue: string) => {
    if (!onSearch) {
      setIsLoading(false);
      return;
    }
    const result = await onSearch(searchValue);
    if (result) {
      setSearchResults(result);
    } else {
      setSearchResults(undefined);
      setAutoCompleteOpen(false);
    }
    setIsLoading(false);
  }, 500);

  const handleOnChange = useCallback(
    (editorState: EditorState) => {
      if (onChange) {
        onChange(editorState);
      }
      const value = editorState.read(() => $getRoot().getTextContent());
      setAutoCompleteOpen(value.length !== 0);
      if (onSearch && value.length !== 0) {
        setIsLoading(true);
        handleSearch(value);
      }
    },
    [handleSearch, onSearch, onChange],
  );

  return (
    <Flex
      ref={inputRef}
      onFocus={handleOnFocus}
      onBlur={handleOutSideClick}
      color={isFocused ? color : 'primaryLight'}
      background={noBorder ? 'transparent' : 'white'}
      borderRadius={noBorder ? 0 : '8px'}
      borderBottomLeftRadius={noBorder ? 0 : '8px'}
      borderBottomRightRadius={noBorder ? 0 : '8px'}
      borderWidth={noBorder ? '2px' : '1px'}
      borderTop={noBorder ? 'none' : undefined}
      borderLeft={noBorder ? 'none' : undefined}
      borderRight={noBorder ? 'none' : undefined}
      outline={noBorder ? undefined : 'none'}
      minHeight="2rem"
      lineHeight="2rem"
      // eslint-disable-next-line no-nested-ternary
      borderColor={isFocused ? 'greenBrand.light' : shouldDisplayError ? 'red.500' : 'greenBrand.borderDefault'}
      boxShadow={isFocused && !noBorder ? '0px 0px 0px 2px rgba(43, 80, 88, 0.32)' : 'none'}
      transitionDuration="0.2s"
      style={style}
      _hover={{
        borderColor: isFocused ? 'greenBrand.lighter' : 'gray.300',
      }}
      position="relative"
      paddingBottom={navbarType === 'block' ? '2rem' : undefined}
      flexDirection="column"
      fontSize="sm"
      {...inputProps}
    >
      <HStack width="100%" px={2} mb={2}>
        <Box flex={1} position="relative" flexGrow={1} onClick={focusRootElement} cursor="text">
          <TextPlugin
            contentEditable={
              <>
                <Box
                  boxSize="100%"
                  ref={ref}
                  contentEditable="true"
                  outline="none"
                  pt="4px"
                  pb="2px"
                  className="richtext-content-editable"
                />
                {footerContent}
              </>
            }
            placeholder={
              <Box
                top={0}
                maxH="100%"
                overflow="hidden"
                userSelect="none"
                pointerEvents="none"
                opacity={0.6}
                position="absolute"
              >
                {placeholder}
              </Box>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
        </Box>
        {navbarType === 'inline' && (
          <CustomRichTextInlinePlugin
            richTextBitwise={richTextBitwise}
            inputRef={inputRef}
            isFocused={isFocused}
            toolbarContent={toolbarContent}
          />
        )}
      </HStack>

      {navbarType === 'block' && (
        <Box px={2} position="absolute" bottom="0" left="0" right="0">
          <CustomRichTextAreaPlugin richTextBitwise={richTextBitwise} toolbarContent={toolbarContent} />
        </Box>
      )}

      {onSearch && renderSearchResults && (
        <>
          <OnChangePlugin onChange={handleOnChange} />
          {isAutoCompleteOpen && (
            <Box
              width="100%"
              borderTop="1px solid"
              borderColor="greenBrand.borderDefault"
              px="0.25rem"
              maxHeight="15.6rem"
              overflowY="scroll"
            >
              {renderSearchResults(
                editor.getEditorState().read(() => $getRoot().getTextContent()),
                isLoading,
                searchResults,
              )}
            </Box>
          )}
        </>
      )}

      {navbarType === 'none' && toolbarContent && (
        <Box
          px={2}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        >
          {toolbarContent}
        </Box>
      )}
    </Flex>
  );
};
