import type { StyleProps } from '@chakra-ui/react';
import { MenuItem } from '@chakra-ui/react';
import type { PropsWithChildren, ReactNode } from 'react';
import { useCallback, useEffect } from 'react';

import { SimpleCheckIcon } from '../Icons';

import { useRichSelectContext } from './useRichSelectContext.hook';

const EMPTY_DEFAULT_PROPS = {};

export interface RichSelectMenuItemProps<Value extends number | string | string[] | Record<string, number | string>> {
  value: Value;

  children: ReactNode;

  closeOnSelect?: boolean;

  onClick?(): void;

  cursor?: StyleProps['cursor'];

  isSelected?: boolean;
}

export const RichSelectMenuItem = <Value extends number | string | string[] | Record<string, number | string>>({
  value,
  children,
  closeOnSelect,
  onClick,
  cursor,
  isSelected,
}: PropsWithChildren<RichSelectMenuItemProps<Value>>) => {
  const { onOptionSelected, onClose, setMenuSize, buttonRef } = useRichSelectContext();

  const handleOptionSelected = useCallback(() => {
    onOptionSelected(value);
    onClose();
  }, [onClose, onOptionSelected, value]);

  useEffect(() => {
    if (buttonRef?.current) {
      setMenuSize(buttonRef.current.offsetWidth);
    }
  }, [buttonRef, setMenuSize]);

  return (
    <MenuItem
      onClick={onClick || handleOptionSelected}
      _hover={{ bg: 'gray.100' }}
      _focus={EMPTY_DEFAULT_PROPS}
      _active={EMPTY_DEFAULT_PROPS}
      background="inherit"
      fontSize="sm"
      textColor="black.500"
      closeOnSelect={closeOnSelect}
      cursor={cursor}
      py={1}
    >
      {children}
      {isSelected && <SimpleCheckIcon boxSize={4} ml={2} />}
    </MenuItem>
  );
};
