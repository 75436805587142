import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { Grid } from '@chakra-ui/react';

import { DataTableCell, DataTableHeader, DataTableRow } from './components';
import { DataTableContext } from './contexts/DataTableContext';
import { DataTableSeparator } from './components/DataTableSeparator';

export interface DataTableProps {
  numberOfColumns: number;

  children: ReactNode;

  density?: 'sm' | 'md';
}

const DataTableComponent: FC<DataTableProps> = ({ numberOfColumns, children, density = 'md' }) => {
  const dataTableContext = useMemo(
    () => ({
      numberOfColumns: numberOfColumns + 1,
      density,
    }),
    [density, numberOfColumns],
  );

  return (
    <DataTableContext.Provider value={dataTableContext}>
      <Grid templateColumns={`13rem repeat(${numberOfColumns}, 1fr)`} rowGap={2}>
        {children}
      </Grid>
    </DataTableContext.Provider>
  );
};

export const DataTable = Object.assign(DataTableComponent, {
  Header: DataTableHeader,
  Cell: DataTableCell,
  Row: DataTableRow,
  Separator: DataTableSeparator,
});
