import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Rule } from 'graneet-form';

interface ProjectsAmountsContextApi {
  isValid: boolean;
  setIsValid(newValue: boolean): void;
}
export const ProjectsAmountsContext = createContext<ProjectsAmountsContextApi>({
  isValid: false,
  setIsValid: () => {},
});

export const ProjectsAmountsProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isValid, setIsValid] = useState(true);

  const context = useMemo(
    () => ({
      isValid,
      setIsValid,
    }),
    [isValid],
  );
  return <ProjectsAmountsContext.Provider value={context}>{children}</ProjectsAmountsContext.Provider>;
};
export const useProjectsAmountsContext = () => useContext(ProjectsAmountsContext);

export const RuleIsProjectsAmountsAmInferiorToAmountExVAT = () => {
  const { isValid } = useProjectsAmountsContext();
  const validationFn = useCallback(() => isValid, [isValid]);

  return <Rule validationFn={validationFn} message="" />;
};
