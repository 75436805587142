import type { FC, PropsWithChildren } from 'react';
import { Text } from '@chakra-ui/react';

import type { TestableElement } from '../../types/TestableElement';

export type FieldGroupLabelProps = PropsWithChildren<TestableElement>;
export const FieldGroupLabel: FC<FieldGroupLabelProps> = ({ children, 'data-testid': testId }) => (
  <Text fontWeight={500} color="gray.800" fontSize="sm" data-testid={testId}>
    {children}
  </Text>
);
