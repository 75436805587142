import type { ICompanyLabel } from '@graneet/business-logic';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { dtoToFormData } from 'features/api/services/api.util';
import { apiNew } from 'features/api/services/apiNew.service';
import { userKeyFactory } from 'features/user/services/user.api';

const COMPANY_LABEL_PATH = '/company-labels';

export function useAddCompanyLabel() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (labelFile: File) =>
      apiNew.postWithMultiPart<FormData, ICompanyLabel>(COMPANY_LABEL_PATH, dtoToFormData({ label: labelFile })),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
    },
  });
}

export function useUpdateCompanyLabel() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { id: number; labelFile: File }) =>
      apiNew.patchWithMultiPart<FormData, ICompanyLabel>(
        `${COMPANY_LABEL_PATH}/${params.id}`,
        dtoToFormData({ label: params.labelFile }),
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
    },
  });
}

export function useDeleteCompanyLabel() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => apiNew.delete<never, void>(`${COMPANY_LABEL_PATH}/${id}`),
    onSuccess: async () => {
      await queryClient.invalidateQueries(userKeyFactory.getCurrent());
    },
  });
}
