import type { StackProps } from '@chakra-ui/react';
import { Flex, GridItem, Box, Grid } from '@chakra-ui/react';
import type { ReactNode } from 'react';

import type { PaginationQuery } from '../../PaginatedTable/Pagination';
import type { CheckBoxFiltersProps } from '../../Filters/CheckBoxFilters/CheckBoxFilters';
import type { MultiSelectFiltersProps } from '../../Filters/MultiSelectFilters/MultiSelectFilters';
import type { PaginatedResponse } from '../../PaginatedData/index';
import type { DateFiltersProps } from '../../Filters/DateFilters/DateFilters';
import type { CheckBoxRangeFiltersProps } from '../../Filters/CheckBoxFilters/CheckBoxRangeFilters';
import type { AmountFiltersProps } from '../../Filters/AmountFilters/AmountFilters';
import { Filters } from '../../Filters/Filters';

import { ListingLayoutContent } from './ListingLayoutContent';
import { ListingLayoutSearch } from './ListingLayoutSearch';
import { ListingLayoutActions } from './ListingLayoutActions';
import { ListingLayoutFilters } from './ListingLayoutFilters';
import type { FilterApplied } from './ListingLayoutFiltersApplied';
import { ListingLayoutFiltersApplied } from './ListingLayoutFiltersApplied';
import { ListingLayoutFilterButton } from './ListingLayoutFilterButton';
import { configureDefaultLabels } from './configureDefaultLabel';

interface CheckboxFilter<Name extends string = string> extends CheckBoxFiltersProps<Name> {
  type: 'checkbox';
}

interface CheckboxRangeFilter<Name extends string = string> extends CheckBoxRangeFiltersProps<Name> {
  type: 'checkbox-range';
}

interface MultiFilter<Name extends string = string> extends MultiSelectFiltersProps<Name> {
  type: 'multi';
}

interface DateFilter<Name extends string = string> extends DateFiltersProps<Name> {
  type: 'date';
}

interface AmountFilter<Name extends string = string> extends AmountFiltersProps<Name> {
  type: 'amount';
}

type Unpacked<T> = T extends PaginatedResponse<infer U> ? U : never;

export interface ListingLayoutProps<TData extends PaginatedResponse<object>> extends Omit<StackProps, 'content'> {
  noPadding?: boolean;
  noShadow?: boolean;

  pagination?: PaginationQuery<TData>;

  topContent?: ReactNode;
  search?: {
    placeholder: string;
  };
  filters?: Array<
    | CheckboxFilter<keyof Unpacked<TData> & string>
    | MultiFilter<keyof Unpacked<TData> & string>
    | DateFilter<keyof Unpacked<TData> & string>
    | CheckboxRangeFilter<keyof Unpacked<TData> & string>
    | AmountFilter<keyof Unpacked<TData> & string>
    | false
  >;

  actions?: ReactNode;

  leftActions?: ReactNode;

  /**
   * TODO Migrate to children
   */
  content?: ReactNode;
}

const ListingLayoutComponent = <TData extends PaginatedResponse<object>>({
  children,
  noPadding,
  noShadow,
  search,
  pagination,
  filters,
  actions,
  content,
  topContent,
  leftActions,
  ...restProps
}: ListingLayoutProps<TData>) => (
  <Box p={noPadding ? 0 : 8} pt={noPadding ? 0 : 3} h="100%">
    <Grid
      templateRows="min-content min-content min-content auto"
      templateColumns="auto 1fr auto"
      gridRowGap={4}
      w="100%"
      h="100%"
      {...restProps}
      borderWidth="1px"
      borderColor="gray.200"
      borderRadius="md"
      pt={4}
    >
      {topContent && (
        <GridItem colSpan={3} mx={4}>
          {topContent}
        </GridItem>
      )}
      {search && <ListingLayoutSearch pagination={pagination?.pagination} placeholder={search.placeholder} />}

      {(filters || leftActions) && (
        <Flex alignItems="center" gap={1} ml={2}>
          {filters && (
            <ListingLayoutFilterButton pagination={pagination?.pagination}>
              {filters.map((filter) => {
                if (filter === false) {
                  return null;
                }
                if (filter.type === 'checkbox') {
                  return <Filters.CheckBox key={filter.name} {...filter} />;
                }
                if (filter.type === 'multi') {
                  return <Filters.MultiSelectFilters key={filter.name} {...filter} />;
                }

                if (filter.type === 'date') {
                  return <Filters.DateSelect key={filter.name} {...filter} />;
                }

                if (filter.type === 'checkbox-range') {
                  return <Filters.CheckBoxRange key={filter.name} {...filter} />;
                }

                if (filter.type === 'amount') {
                  return <Filters.AmountSelect key={filter.name} {...filter} />;
                }

                return null;
              })}
            </ListingLayoutFilterButton>
          )}
          {leftActions}
        </Flex>
      )}

      {actions && <ListingLayoutActions>{actions}</ListingLayoutActions>}

      {filters && (
        <ListingLayoutFiltersApplied
          pagination={pagination?.pagination}
          filters={filters.reduce<Record<string, FilterApplied>>((acc, filter) => {
            if (filter !== false) {
              acc[filter.name] = {
                label: filter.label,
                type: filter.type,
                availableValues: 'availableValues' in filter ? filter.availableValues : undefined,
              };
            }
            return acc;
          }, {})}
        />
      )}

      {content && <ListingLayoutContent>{content}</ListingLayoutContent>}

      {children}
    </Grid>
  </Box>
);

export const ListingLayout = Object.assign(ListingLayoutComponent, {
  configureDefaultLabels,

  /**
   * @deprecated use search props on ListingLayout
   */
  Search: ListingLayoutSearch,
  /**
   * @deprecated use actions props on ListingLayout
   */
  Actions: ListingLayoutActions,
  /**
   * @deprecated use content props on ListingLayout
   */
  Content: ListingLayoutContent,
  /**
   * @deprecated use filters props on ListingLayout
   */
  Filters: ListingLayoutFilters,
  /**
   * @deprecated use filters props on ListingLayout
   */
  FiltersApplied: ListingLayoutFiltersApplied,
  /**
   * @deprecated use filters props on ListingLayout
   */
  FilterButton: ListingLayoutFilterButton,
});
