import { forwardRef } from 'react';
import type { TextareaProps } from '@chakra-ui/react';
import { Textarea } from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';

interface AutoResizeTextareaProps extends TextareaProps {
  maxRows?: number;
}

export const AutoResizeTextarea = forwardRef<HTMLTextAreaElement, AutoResizeTextareaProps>(
  ({ maxRows = 5, ...props }, ref) => (
    <Textarea
      minH="unset"
      w="100%"
      resize="none"
      ref={ref}
      minRows={1}
      maxRows={maxRows > 0 ? maxRows : undefined}
      as={ResizeTextarea}
      transition={undefined}
      style={{ paddingTop: 0, paddingBottom: '6px' }} // CSS hack to set the correct initial height
      {...props}
    />
  ),
);

export const AutoResizeTextareaTooltip = (props: AutoResizeTextareaProps) => <AutoResizeTextarea pr={10} {...props} />;
