import type { CellClassParams, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';
import type { IComponentTypesListingDTO } from '@graneet/business-logic';
import type { QuoteComponentObject } from '@org/quotation-lib';
import isNil from 'lodash-es/isNil';

import { TextCell } from '../../components/columns/TextCell/TextCell';
import { TextEditorCell } from '../../components/columns/TextCell/TextEditorCell';
import type { AGColDef } from '../../types';

import { useQuoteBasicItemUpdateUnit } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemUpdateUnit';
import { useQuoteOptionalItemUpdateUnit } from 'features/quotation/quote-optional-item/hooks/useQuoteOptionalItemUpdateUnit';
import { useQuoteHiddenCostItemUpdateUnit } from 'features/quotation/quote-hidden-cost/hooks/useQuoteHiddenCostItemUpdateUnit';
import { useQuoteSubItemUpdateUnit } from 'features/quotation/quote-sub-item/hooks/useQuoteSubItemUpdateUnit';
import { useQuoteComponentUpdateUnit } from 'features/quotation/quote-component/hooks/useQuoteComponentUpdateUnit';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export function useUnitColumnDef(
  isEditable: boolean,
  workforceComponentTypeIds: number[],
  componentTypes: IComponentTypesListingDTO[],
) {
  const quoteBasicItemUpdateUnit = useQuoteBasicItemUpdateUnit();
  const quoteOptionalItemUpdateUnit = useQuoteOptionalItemUpdateUnit();
  const quoteHiddenCostItemUpdateUnit = useQuoteHiddenCostItemUpdateUnit();
  const quoteSubItemUpdateUnit = useQuoteSubItemUpdateUnit();
  const quoteComponentUpdateUnit = useQuoteComponentUpdateUnit();

  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useCallback(
    (width: number, label: string, index: number): AGColDef => ({
      colId: 'unit',
      width,
      index,
      headerName: label,
      editable: (params) => {
        if (!isEditable) return false;
        switch (params.data?.content.type) {
          case 'QuoteLot':
            return false;
          default: {
            if (!params.data) return false;
            if (params.data.content.type === 'QuoteComponent') {
              if (workforceComponentTypeIds.includes(params.data.content.componentTypeId ?? -1)) {
                return false;
              }
              const componentType = componentTypes.find(
                (ct) => ct.id === (params.data?.content as QuoteComponentObject).componentTypeId,
              );
              if (!componentType) {
                return true;
              }
              if (!isNil(componentType?.unit)) {
                return false;
              }
            }
            return true;
          }
        }
      },
      sortable: false,
      field: 'content.unit',
      cellStyle: { textAlign: 'right' },
      cellClass: (params: CellClassParams) => {
        if (
          params.node.footer ||
          params.data?.content.type === 'QuoteLot' ||
          (params.data?.content.type === 'QuoteComponent' &&
            workforceComponentTypeIds.includes(params.data.content.componentTypeId ?? -1))
        )
          return 'no-focus read-only';
        if (params.data.content.type === 'QuoteComponent') {
          const componentType = componentTypes.find(
            (ct) => ct.id === (params.data?.content as QuoteComponentObject).componentTypeId,
          );
          if (!isNil(componentType?.unit)) {
            return 'no-focus read-only';
          }
        }
        return '';
      },
      suppressAutoSize: true,
      suppressSizeToFit: true,
      suppressMovable: false,
      cellRenderer: TextCell,
      cellRendererParams: {
        textAlign: 'right',
        mandatory: true,
      },
      cellEditor: TextEditorCell,
      cellEditorParams: {
        textAlign: 'right',
      },
      valueSetter: (params) => {
        const { data, newValue, oldValue } = params;

        if (oldValue?.toString() === newValue?.toString()) {
          return false;
        }

        switch (data.content.type) {
          case 'QuoteComponent':
            return executeCommand(quoteComponentUpdateUnit(data.id, newValue), quote);
          case 'QuoteBasicItem':
            return executeCommand(quoteBasicItemUpdateUnit(data.id, newValue), quote);
          case 'QuoteHiddenCostItem':
            return executeCommand(quoteHiddenCostItemUpdateUnit(data.id, newValue), quote);
          case 'QuoteOptionalItem':
            return executeCommand(quoteOptionalItemUpdateUnit(data.id, newValue), quote);
          case 'QuoteSubItem':
            return executeCommand(quoteSubItemUpdateUnit(data.id, newValue), quote);
          default:
            break;
        }
        return true;
      },
      valueGetter: (params) => {
        if (!params.data) {
          return '-';
        }

        switch (params.data.content.type) {
          case 'QuoteComponent': {
            const componentType = componentTypes.find(
              (ct) => ct.id === (params.data?.content as QuoteComponentObject).componentTypeId,
            );
            if (!isNil(componentType?.unit)) {
              return componentType.unit;
            }
            return params.data.content.unit;
          }
          case 'QuoteBasicItem':
          case 'QuoteHiddenCostItem':
          case 'QuoteOptionalItem':
          case 'QuoteSubItem':
            return params.data.content.unit;
          case 'QuoteLot':
            return '';
          default:
            throw new Error('Type unknown');
        }
      },
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [
      isEditable,
      workforceComponentTypeIds,
      componentTypes,
      executeCommand,
      quoteComponentUpdateUnit,
      quote,
      quoteBasicItemUpdateUnit,
      quoteHiddenCostItemUpdateUnit,
      quoteOptionalItemUpdateUnit,
      quoteSubItemUpdateUnit,
    ],
  );
}
