import { useTranslation } from 'react-i18next';
import { Wizard, useToast } from '@graneet/lib-ui';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import type { FC } from 'react';
import { useCallback } from 'react';
import type { IDirectProgressStatementUpdateDTO, IProgressStatement } from '@graneet/business-logic';
import { SUB_PROJECT_BILLING_TYPE } from '@graneet/business-logic';

import { DirectProgressStatementInformationsStep } from './_steps/informations/DirectProgressStatementInformationsStep';

import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { useContractsBySubProject } from 'features/contract/services/contract.api';
import { useSubProject, useSubProjectDownPayment } from 'features/sub-project/services/sub-project.api';
import type { DirectProgressStatementWizard } from 'features/progress-statement/forms/direct-progress-statement.wizard';
import { formatProgressStatementWizardTitle } from 'features/progress-statement/services/progress-statement.util';
import {
  useDirectProgressStatementCreate,
  useDirectProgressStatementUpdate,
  useProgressStatementExtended,
} from 'features/progress-statement/services/progress-statement.api';
import { useProject } from 'features/project/services/project.api';

export const EditDirectProgressStatementsScreen: FC = () => {
  useHideNavbar();
  const { t } = useTranslation(['directProgressStatement', 'invoice']);

  const history = useHistory();
  const { state = {} } = useLocation<{ referrerUrl?: string; url?: string }>();

  const {
    projectId: stringProjectId,
    subProjectId,
    progressStatementId,
  } = useParams<{
    projectId: string;
    subProjectId: string;
    progressStatementId?: string;
  }>();
  const toast = useToast();
  const { createRedirectionWithSavedFilters } = useFiltersQuery();

  // - Computed values
  const isEdition = !!progressStatementId;
  const projectId = Number(stringProjectId);

  const wizardLabels = useWizardLabels({
    save: isEdition ? t('directProgressStatement:actions.modify') : t('directProgressStatement:actions.create'),
  });

  const project = useProject(projectId);
  const subProject = useSubProject(subProjectId);
  const contracts = useContractsBySubProject(subProjectId);
  const downPayment = useSubProjectDownPayment(subProjectId);
  const currentProgressStatement = useProgressStatementExtended(
    progressStatementId ? parseInt(progressStatementId, 10) : undefined,
  );

  const directProgressStatementCreateMutation = useDirectProgressStatementCreate();
  const directProgressStatementUpdateMutation = useDirectProgressStatementUpdate();

  /**
   * Where to go when we press back button for first step
   */
  const handleGoBack = useCallback(() => {
    if (state.referrerUrl) {
      return createRedirectionWithSavedFilters(state.referrerUrl, { replace: true })();
    }

    if (!isEdition) {
      return createRedirectionWithSavedFilters(`/projects/${projectId}/statements`, { replace: true })();
    }

    return createRedirectionWithSavedFilters(
      `/projects/${projectId}/statements/progress-statements/${progressStatementId}`,
      { replace: true },
    )();
  }, [state, isEdition, createRedirectionWithSavedFilters, projectId, progressStatementId]);

  // - Submitting
  const handleSubmit = useCallback(
    async (wizardValues: DirectProgressStatementWizard) => {
      const { information: informationFormValues } = wizardValues;
      const dto: IDirectProgressStatementUpdateDTO = {
        billingDate: informationFormValues.billingDate,
        invoiceNumber: informationFormValues.invoiceNumber,
        name: informationFormValues.name,
        note: informationFormValues.note,
      };

      let progressStatement: IProgressStatement;
      if (isEdition) {
        progressStatement = await directProgressStatementUpdateMutation.mutateAsync({
          id: +progressStatementId,
          dto,
        });
      } else {
        progressStatement = await directProgressStatementCreateMutation.mutateAsync({
          ...dto,
          subProjectId,
        });
      }

      history.replace(state?.url || `/projects/${projectId}/statements/progress-statements/${progressStatement.id}`);
    },
    [
      directProgressStatementCreateMutation,
      directProgressStatementUpdateMutation,
      history,
      isEdition,
      progressStatementId,
      projectId,
      state?.url,
      subProjectId,
    ],
  );

  const headerTitle = t(isEdition ? 'invoice:steps.titleModification' : 'invoice:steps.titleCreationWithLabel', {
    label: formatProgressStatementWizardTitle(project.data, currentProgressStatement.data),
  });

  // Redirect if conditions are not satisfied to create a direct invoice
  if (subProject.data.billingType === SUB_PROJECT_BILLING_TYPE.PROGRESS) {
    history.replace(`/projects/${project.data.id}/statements`);
    toast.error(t('directProgressStatement:form.errors.billingType'));
    return null;
  }

  return (
    <Wizard<DirectProgressStatementWizard>
      headerTitle={headerTitle}
      labels={wizardLabels}
      onFinish={handleSubmit}
      onQuit={handleGoBack}
    >
      <Wizard.Step<DirectProgressStatementWizard> name="information">
        <DirectProgressStatementInformationsStep
          subProject={subProject.data}
          project={project.data}
          currentProgressStatement={currentProgressStatement.data}
          contractName={contracts.data[0]?.name}
          downPayment={downPayment.data}
        />
      </Wizard.Step>
    </Wizard>
  );
};
