import type { FC, ReactElement } from 'react';
import { useMemo } from 'react';
import { Box, Divider, Flex, Grid, useToken } from '@chakra-ui/react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { useMonthlyTimeTableContext } from '../../contexts/MonthlyTimeTable.context';
import type { IMonthlyTimeTableRange } from '../../types/MonthlyTimeTable.type';
import type { ButtonColorScheme } from '../../../../Badge/Badge';
import type { RangeWithNumberOfWorkedHours } from '../MonthlyTimeTableWeekTooltip';
import { MonthlyTimeTableWeekTooltip } from '../MonthlyTimeTableWeekTooltip';

import { MonthlyTimeTableRange } from './MonthlyTimeTableRange';
import { MonthlyTimeTableMonthGrid } from './MonthlyTimeTableMonthGrid';
import { MonthlyTimeTableWeekPlaceholder } from './MonthlyTimeTableWeekPlaceholder';

dayjs.extend(isBetween);

export interface MonthlyTimeTableRowProps {
  header: (numberOfHoursWorked: number) => ReactElement;
  ranges: IMonthlyTimeTableRange[][];
}

export const MonthlyTimeTableRow: FC<MonthlyTimeTableRowProps> = ({ header, ranges }) => {
  const { numberOfDays, weeksList, currentDate } = useMonthlyTimeTableContext();

  const grayColor = useToken('colors', 'gray.200');
  const borderStyle = `1px solid ${grayColor}`;
  const rangesWithNumberOfWorkedHours = useMemo(
    () =>
      ranges.reduce<RangeWithNumberOfWorkedHours[]>(
        (acc, monthlyTimeTableRanges) =>
          acc.concat(
            monthlyTimeTableRanges
              .filter((timeTable) => timeTable.associatedEntityId !== -1)
              .map((range) => ({
                range,
                numberOfWorkedMinutes: range.nbMinutes,
              })),
          ),
        [],
      ),
    [ranges],
  );

  const numberOfMinutesWorked = useMemo(
    () => rangesWithNumberOfWorkedHours.reduce((acc, range) => acc + range.numberOfWorkedMinutes, 0),
    [rangesWithNumberOfWorkedHours],
  );

  let weekPlaceholderColumnIndex = 0;

  return (
    <Flex direction="row" minHeight="6rem">
      <Box w="18rem">
        <Divider />
        <MonthlyTimeTableWeekTooltip
          ranges={rangesWithNumberOfWorkedHours}
          monthNumber={currentDate?.get('month') || 1}
        >
          {/* Needed by chakra to have a working tooltip */}
          <Box w="100%">{header(numberOfMinutesWorked)}</Box>
        </MonthlyTimeTableWeekTooltip>
      </Box>

      <Grid
        alignItems="flex-start"
        templateColumns={`repeat(${numberOfDays}, 1fr)`}
        w="100%"
        bgColor={grayColor}
        border={borderStyle}
        borderBottom="0"
        gridGap="0 1px"
        overflow="hidden"
      >
        {/* Add grid to the timeslot */}
        {(ranges.length > 0 ? ranges : [[]]).map((_, rowIndex) => {
          let columnStartIndex = 0;

          return Object.keys(weeksList).map((week) => {
            columnStartIndex += weeksList[week].days.length;

            return (
              <MonthlyTimeTableMonthGrid
                key={week}
                rowIndex={rowIndex}
                columnStartIndex={columnStartIndex}
                weekData={weeksList[week]}
              />
            );
          });
        })}

        {/* Add ranges to the timeslot */}
        {ranges.map((rangesGroupedBy, rowIndex) =>
          rangesGroupedBy.map((range) => (
            <MonthlyTimeTableRange
              key={range.id + range.startDate.toString()}
              color={range.color as ButtonColorScheme}
              startDate={new Date(range.startDate)}
              endDate={new Date(range.endDate)}
              row={rowIndex + 1}
            />
          )),
        )}

        {/* Add placeholders on each week */}
        {Object.keys(weeksList).map((week) => {
          weekPlaceholderColumnIndex += weeksList[week].days.length;

          return (
            <MonthlyTimeTableWeekPlaceholder
              key={week}
              weekData={weeksList[week]}
              columnStartIndex={weekPlaceholderColumnIndex}
              ranges={ranges}
              rowSpan={ranges.length}
            />
          );
        })}
      </Grid>
    </Flex>
  );
};
