import type { QuoteFileDeleteWsMessage, QuoteFileWsMessage } from '../interfaces/quoteWsMessage';

import { createQuoteFileAttachCallback } from './createQuoteFileAttachCallback';
import { createQuoteFileDeleteCallback } from './createQuoteFileDeleteCallback';

export const quoteFileCallbacks = (quoteId: String) => [
  {
    channel: `private-quote-${quoteId}`,
    event: 'file:created',
    callback: (message: QuoteFileWsMessage) => createQuoteFileAttachCallback(message),
  },
  {
    channel: `private-quote-${quoteId}`,
    event: 'file:deleted',
    callback: (message: QuoteFileDeleteWsMessage) => createQuoteFileDeleteCallback(message),
  },
];
