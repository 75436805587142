import { ContentTypeGuard } from '@org/quotation-lib';

import type { QuoteWsMessage } from 'features/quotation/quote-common/interfaces/quoteWsMessage';
import { quoteClientRequestsGetFromStore } from 'features/quotation/quote-common/store/quoteClientRequestsGetFromStore';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { quoteGetFromStore } from 'features/quotation/quote-common/store/quoteGetFromStore';
import { quoteSetToStore } from 'features/quotation/quote-common/store/quoteSetToStore';

export const createQuoteLotTransformChildCallback = (
  message: QuoteWsMessage & { oldType: string; newType: string },
) => {
  const quote = quoteGetFromStore();
  const quoteClientRequestsFromStore = quoteClientRequestsGetFromStore();

  if (quote && message.data.parentId) {
    const hasClientRequest = quoteClientRequestsFromStore.find(
      (clientRequest) => clientRequest.clientRequestId === message.clientRequestId,
    );
    if (hasClientRequest === undefined) {
      const parentNode = quote.getTree().getNodeOrThrow(message.data.parentId);
      const quoteLot = parentNode.getContent();
      if (
        ContentTypeGuard.isQuoteLot(quoteLot) &&
        message.oldType === 'QuoteBasicItem' &&
        message.newType === 'QuoteOptionalItem'
      ) {
        quoteLot.transformQuoteBasicItemsToQuoteOptionalItems();
      } else if (
        ContentTypeGuard.isQuoteLot(quoteLot) &&
        message.oldType === 'QuoteOptionalItem' &&
        message.newType === 'QuoteBasicItem'
      ) {
        quoteLot.transformQuoteOptionalItemsToQuoteBasicItems();
      }
      quoteSetToStore(quote);
    } else {
      quoteClientRequestsSetToStore(StatusEnum.DELETE_ENTRY, hasClientRequest, message.nbEventPusher);
    }
  }
};
