import type { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Modal, DateField, getCurrentDateAsString } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { useTranslation } from 'react-i18next';
import type { IProgressStatement } from '@graneet/business-logic';
import { PROGRESS_STATEMENT_STATUSES } from '@graneet/business-logic';

import { useProgressStatementChangeStatus } from '../services/progress-statement.api';

import { Rule } from 'features/form/rules/Rule';

interface FormValues {
  acceptanceDate: string;
}

interface AcceptProgressStatementModalProps {
  progressStatement: IProgressStatement;
  isOpen: boolean;
  onClose(): void;
}

export const AcceptProgressStatementModal: FC<AcceptProgressStatementModalProps> = ({
  progressStatement,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation(['global', 'progressStatement']);

  const form = useForm<FormValues>({
    defaultValues: {
      acceptanceDate: getCurrentDateAsString(),
    },
  });
  const { isValid: isFormValid } = useFormStatus(form);

  const progressStatementChangeStatusMutation = useProgressStatementChangeStatus('progress');

  const handleSubmit = () => {
    const { acceptanceDate } = form.getFormValues();

    progressStatementChangeStatusMutation.mutate(
      {
        id: progressStatement.id,
        dto: {
          status: PROGRESS_STATEMENT_STATUSES.ACCEPTED,
          acceptanceDate,
        },
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  return (
    <Form form={form}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        title={t('progressStatement:workflow.accepting.title')}
        scrollBehavior="inside"
        autoFocus={false}
      >
        <Flex direction="column" mb={6}>
          {t('progressStatement:workflow.accepting.description')}
          <br />
          {t('progressStatement:workflow.accepting.action')}
        </Flex>
        <DateField<FormValues> name="acceptanceDate" label={t('progressStatement:fields.acceptanceDate')} isRequired>
          <Rule.IsRequired />
        </DateField>

        <Modal.Close />

        <Modal.PrimaryButton
          isLoading={progressStatementChangeStatusMutation.isPending}
          isDisabled={!isFormValid}
          onClick={handleSubmit}
        >
          {t('progressStatement:actions.accept')}
        </Modal.PrimaryButton>
      </Modal>
    </Form>
  );
};
