import { useCallback } from 'react';
import { MARGIN_TYPE } from '@org/quotation-lib';
import { v4 as uuid } from 'uuid';
import Big from 'big.js';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuote } from './useQuote';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import { mapQuoteToQuoteUpdateAmountExVatDTO } from 'features/quotation/quote-common/mappers/quoteMapper';
import { updateQuoteAmountIsUnreachable } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteUpdateAmountExVat() {
  const { quote } = useQuote();
  const quoteSetToStore = useQuoteSetToStore();
  const { quoteProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  return useCallback(
    (amountExVat: string): boolean => {
      try {
        quote.updateAmountExVat(Big(amountExVat), {
          spreadUp: true,
          impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
        });

        const clientRequestId = uuid();
        const timestamp = new Date().toISOString();
        quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
        quoteProxyApi
          .updateQuoteAsync(mapQuoteToQuoteUpdateAmountExVatDTO(quote, clientRequestId, timestamp))
          .then(([err]) => {
            if (err) {
              quoteError();
            }
          })
          .catch((err) => quoteError(err.message));

        if (quote.getAmountExVat()?.eq(Big(amountExVat))) {
          updateQuoteAmountIsUnreachable(false);
        } else {
          updateQuoteAmountIsUnreachable(true);
        }

        quoteSetToStore(quote);

        return true;
      } catch (e: any) {
        return quoteError(e.message);
      }
    },
    [quote, quoteError, quoteProxyApi, quoteSetToStore],
  );
}
