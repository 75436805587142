import { useCallback, useMemo, useState } from 'react';
import type { ButtonProps } from '@chakra-ui/react';

export enum BUTTON_TYPE {
  CLOSE = 'CLOSE',
  SECONDARY = 'SECONDARY',
  PRIMARY = 'PRIMARY',
}

export type RegisteredButton = Record<BUTTON_TYPE, ButtonProps[]>;

export const useModalButtons = () => {
  const [buttons, setButtons] = useState<RegisteredButton>({
    [BUTTON_TYPE.CLOSE]: [],
    [BUTTON_TYPE.SECONDARY]: [],
    [BUTTON_TYPE.PRIMARY]: [],
  });

  const registerButton = useCallback((type: BUTTON_TYPE, button: ButtonProps) => {
    setButtons((rest) => ({
      ...rest,
      [type]: rest[type] ? [...(rest[type] ?? []), button] : [button],
    }));
  }, []);

  const unRegisterButton = useCallback((type: BUTTON_TYPE, oldButton: ButtonProps) => {
    setButtons((rest) => ({
      ...rest,
      [type]: rest[type] ? rest[type].filter((button) => button !== oldButton) : [],
    }));
  }, []);

  return useMemo(
    () => ({
      buttons,
      registerButton,
      unRegisterButton,
    }),
    [buttons, registerButton, unRegisterButton],
  );
};
