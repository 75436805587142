import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { Button, Onboarding, Container, SimpleAlertIcon } from '@graneet/lib-ui';
import { Box, Flex } from '@chakra-ui/react';
import {
  safelyConvertEnum,
  type GRANEET_COLORS,
  type IProject,
  type ITimeTrackingProjectStatsResponse,
} from '@graneet/business-logic';
import type { GraneetColors, TimeTrackingProjectStatsDTO } from '@org/graneet-bff-client';

import { getTimeTrackingStatsLegacy, useTimeTrackingStats } from 'features/time-tracking/services/time-slot.api';
import { Loading } from 'features/common/components/Loading';
import { TimeTrackingProgressCard } from 'features/time-tracking/components/TimeTrackingProgressCard';
import { WorkerProjectTable } from 'features/worker/components/WorkerProjectTable';
import { useDataGetter } from 'features/api/hooks/useDataGetter';
import { useData } from 'features/api/hooks/useData';
import { useBudgetUsages } from 'features/budget/services/budget.api';

export interface ViewTimeTrackingScreenProps {
  project: IProject;
}

const mapTimeTrackingDTOToResponse = (data: TimeTrackingProjectStatsDTO): ITimeTrackingProjectStatsResponse => ({
  ...data,
  componentTypes: data.componentTypes.map((componentTypeData) => ({
    ...componentTypeData,
    componentType: {
      ...componentTypeData.componentType,
      color: safelyConvertEnum<GraneetColors, GRANEET_COLORS>(componentTypeData.componentType.color),
    },
  })),
});

export const ViewTimeTrackingScreen = ({ project }: ViewTimeTrackingScreenProps) => {
  const { t } = useTranslation(['global', 'project', 'worker']);
  const history = useHistory();

  const statsData = useData(useDataGetter(getTimeTrackingStatsLegacy, project.id, null));
  const budgetUsages = useBudgetUsages(project.id);

  const statsDataV2 = useTimeTrackingStats(project.id);

  const onUpdate = useCallback(() => {
    statsData.fetch();
  }, [statsData]);

  const onRedirect = useCallback(() => {
    history.push({
      pathname: `/time-tracking/.`,
      search: `?${qs.stringify({
        filters: {
          groupBy: 'project',
          query: project.name,
        },
      })}`,
    });
  }, [project, history]);

  return (
    <Container size="md">
      <Flex direction="column" gap={3}>
        <Flex justifyContent="flex-end">
          <Button colorScheme="blue" onClick={onRedirect}>
            {t('project:timeTracking.gotoLink')}
          </Button>
        </Flex>

        {statsData.loading && <Loading />}
        {statsData.error && <Onboarding icon={<SimpleAlertIcon boxSize={45} />}>{t('global:errors.error')}</Onboarding>}
        {!statsData.loading && !statsData.error && (
          <TimeTrackingProgressCard
            // For retro compatibility, to remove old stats eventually #0021
            timeTrackingProjectStats={
              statsDataV2.data ? mapTimeTrackingDTOToResponse(statsDataV2.data) : statsData.data
            }
            budgets={budgetUsages.data.data}
            project={project}
            onUpdate={onUpdate}
          />
        )}

        <Box>
          <WorkerProjectTable project={project} />
        </Box>
      </Flex>
    </Container>
  );
};
