import { useTranslation } from 'react-i18next';
import { CheckboxField, Modal, TextField } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { Form, HiddenField, useForm } from 'graneet-form';
import type { IAccountingConfigBank, IAccountingConfigBankAccount } from '@graneet/business-logic';
import { Flex, Text } from '@chakra-ui/react';

import { useCreateOrUpdateAccoutingConfigBankAccount } from '../../../services/accounting-config.api';
import { AccountingConfigBanksAccountField } from '../../AccountingConfigBanksAccountField';

import { Rule } from 'features/form/rules/Rule';

type FormValues = Pick<IAccountingConfigBankAccount, 'account' | 'label'> & {
  id?: string; // in case of edit
  accountingConfigBankId: IAccountingConfigBank['id'];
  setAsDefault: boolean;
};

interface AccountingBankAccountCreateOrUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;

  // in case of edit
  existingAccountingConfigBankAccount: (IAccountingConfigBankAccount & { isDefault: boolean }) | undefined;
}

export const AccountingBankAccountCreateOrUpdateModal: FC<AccountingBankAccountCreateOrUpdateModalProps> = ({
  isOpen,
  onClose,
  existingAccountingConfigBankAccount,
}) => {
  const { t } = useTranslation(['accounting', 'global']);

  const form = useForm<FormValues>();

  const createAccountingConfigBankAccountMutation = useCreateOrUpdateAccoutingConfigBankAccount();

  useEffect(() => {
    if (existingAccountingConfigBankAccount) {
      form.setFormValues({
        id: existingAccountingConfigBankAccount.id,
        accountingConfigBankId: existingAccountingConfigBankAccount.accountingConfigBank?.id,
        account: existingAccountingConfigBankAccount.account || null,
        label: existingAccountingConfigBankAccount.label,
        setAsDefault: existingAccountingConfigBankAccount.isDefault,
      });
    } else {
      form.setFormValues({
        setAsDefault: false,
      });
    }
  });

  const onCreate = useCallback(async () => {
    const formValues = form.getFormValues();

    await createAccountingConfigBankAccountMutation.mutateAsync({
      ...(existingAccountingConfigBankAccount && { id: existingAccountingConfigBankAccount.id }), // in case of edit
      label: formValues.label!,
      account: formValues.account || null,
      accountingConfigBankId: formValues.accountingConfigBankId!,
      setAsDefault: formValues.setAsDefault!,
    });

    setTimeout(onClose, 500);
  }, [createAccountingConfigBankAccountMutation, existingAccountingConfigBankAccount, form, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('accounting:addBankAccount.title')}>
      <Form form={form}>
        {existingAccountingConfigBankAccount && <HiddenField name="id" />}

        <Flex gap={4} direction="column">
          <AccountingConfigBanksAccountField<FormValues>
            label={t('accounting:fields.bankJournal')}
            name="accountingConfigBankId"
          >
            <Rule.IsRequired />
          </AccountingConfigBanksAccountField>

          <TextField<FormValues> name="label" label={t('accounting:fields.bankAccountLabel')}>
            <Rule.IsRequired />
          </TextField>

          <TextField<FormValues> name="account" label={t('accounting:fields.bankAccountCode')} />

          <CheckboxField<FormValues>
            name="setAsDefault"
            label={
              <Text verticalAlign="middle" fontSize=".9rem">
                {t('accounting:fields.setAsDefaultBankAccount')}
              </Text>
            }
          />
        </Flex>
        <Modal.CloseButton isDisabled={createAccountingConfigBankAccountMutation.isPending} />
        <Modal.PrimaryButton onClick={onCreate} isLoading={createAccountingConfigBankAccountMutation.isPending}>
          {existingAccountingConfigBankAccount ? t('global:words.c.update') : t('global:words.c.create')}
        </Modal.PrimaryButton>
      </Form>
    </Modal>
  );
};
