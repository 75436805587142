import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import localeData from 'dayjs/plugin/localeData';

import type {
  GeneratorConfig,
  GeneratorConfigObject,
  GeneratorData,
  GeneratorInterface,
  LEDGER_GENERATORS,
} from '../ledger.type';
import { capitalizeString, normalizeStringNFD } from '../../common/string.util';

dayjs.locale('fr');
dayjs.extend(localeData);

const GENERATOR_DATE_FORMATS = ['DD', 'MM', 'MMM', 'MMMM', 'YYYY', 'YY'] as const;

const GENERATOR_DATE_FISCAL_YEAR_OPTIONS = ['FIRSTMONTH', 'JANUARY'] as const;

type GeneratorDateFormat = (typeof GENERATOR_DATE_FORMATS)[number];

type GeneratorDateFiscalYear = (typeof GENERATOR_DATE_FISCAL_YEAR_OPTIONS)[number];

export interface GeneratorDateConfig extends GeneratorConfig<LEDGER_GENERATORS.DATE> {
  type: LEDGER_GENERATORS.DATE;
  format: GeneratorDateFormat;
  fiscalYear?: GeneratorDateFiscalYear;
}

export class GeneratorDate implements GeneratorInterface {
  format: GeneratorDateFormat;

  fiscalYear?: GeneratorDateFiscalYear;

  constructor(config: GeneratorDateConfig) {
    const { format, fiscalYear } = config;
    this.format = format;
    this.fiscalYear = fiscalYear;
  }

  public static isConfigValid(config: GeneratorConfigObject, block: string): boolean {
    const { format, fiscalYear } = config[block] as GeneratorDateConfig;
    if (!GENERATOR_DATE_FORMATS.includes(format)) {
      return false;
    }

    if (fiscalYear && !GENERATOR_DATE_FISCAL_YEAR_OPTIONS.includes(fiscalYear)) {
      return false;
    }

    return true;
  }

  public isStateValid(): boolean {
    return true;
  }

  public generateValue(data: GeneratorData): [string, null, null] {
    return [this.generateRawValue(data), null, null];
  }

  private generateRawValue(data: GeneratorData): string {
    const { referenceDate, company } = data;
    const { startDateFiscalYear = 1 } = company ?? {};

    switch (this.format) {
      case 'DD':
        return this.formatDD(referenceDate);
      case 'MM':
        return this.formatMM(referenceDate);
      case 'MMM':
        return this.formatMMM(referenceDate);
      case 'MMMM':
        return this.formatMMMM(referenceDate);
      case 'YY':
        return this.formatYY(referenceDate, startDateFiscalYear);
      case 'YYYY':
        return this.formatYYYY(referenceDate, startDateFiscalYear);

      default:
        return '';
    }
  }

  /**
   * Display day with 2 digits (eg: 01, 04, 14, 31, ...)
   * this should only be possible in quote ledger, though we do not implemented validation in the code
   */
  private formatDD(billingDate: Date | string): string {
    return dayjs(billingDate).format('DD');
  }

  /**
   * Display month with 2 digits (eg: 01, 04, 09, 12, ...)
   */
  private formatMM(billingDate: Date | string): string {
    return dayjs(billingDate).format('MM');
  }

  private getMonthLabel = (month: string, labelLength: number): string =>
    GeneratorDate.cleanString(month).toUpperCase().substring(0, labelLength);

  /**
   * Display month with 3 letters, french language is used
   * String is upper cased and accents are removed (eg: JAN, AVR, SEP, DEC, ...)
   */
  private formatMMM(billingDate: Date | string): string {
    const currentMonth = dayjs(billingDate).month();
    const months = dayjs.months();
    let labelLength = 3;

    const isLabelDistinguishable = (): boolean => {
      const labels = months.map((v) => this.getMonthLabel(v, labelLength));
      const currentLabel = labels[currentMonth];
      const count = labels.reduce((acc, label) => acc + (label === currentLabel ? 1 : 0), 0);
      return count === 1;
    };

    while (!isLabelDistinguishable()) {
      labelLength += 1;
    }
    return this.getMonthLabel(months[currentMonth], labelLength);
  }

  /**
   * Display month, french language is used
   * String is capitalized and accents are removed (eg: Janvier, Avril, Septembre, Décembre, ...)
   */
  private formatMMMM(billingDate: Date | string): string {
    return GeneratorDate.cleanString(dayjs(billingDate).format('MMMM'));
  }

  /**
   * Display year (or fiscal year when using corresponding option) with 2 digits
   */
  private formatYY(billingDate: Date | string, startDateFiscalYear: number): string {
    if (!this.fiscalYear) {
      return dayjs(billingDate).format('YY');
    }

    return this.getReferenceDate(billingDate, startDateFiscalYear).format('YY');
  }

  /**
   * Display year (or fiscal year when using corresponding option) with 4 digits
   */
  private formatYYYY(billingDate: Date | string, startDateFiscalYear: number): string {
    if (!this.fiscalYear) {
      return dayjs(billingDate).format('YYYY');
    }

    return this.getReferenceDate(billingDate, startDateFiscalYear).format('YYYY');
  }

  /**
   * Get reference date when using fiscal option
   * If fiscal option is `FIRSTMONTH`, reference date is the first month of the billing date's fiscal year
   * If fiscal option is `JANUARY`, reference date is the month of January of the billing date's fiscal year
   * Few examples when fiscal year starts in January :
   * - Billing Date = 12/01/2020 > Reference Date = 01/2020
   * - Billing Date = 12/08/2020 > Reference Date = 01/2020
   * - Billing Date = 12/10/2020 > Reference Date = 01/2020
   * Few examples when fiscal year starts in another month (September for instance)
   * - Billing Date = 12/01/2020 > Reference Date `FIRSTMONTH` = 09/2019 / `JANUARY` = 01/2020
   * - Billing Date = 12/08/2020 > Reference Date `FIRSTMONTH` = 09/2019 / `JANUARY` = 01/2020
   * - Billing Date = 12/10/2020 > Reference Date `FIRSTMONTH` = 09/2020 / `JANUARY` = 01/2021
   */
  private getReferenceDate(billingDate: Date | string, startDateFiscalYear: number): Dayjs {
    const date = dayjs(billingDate);
    const year = date.year();
    const month = date.month() + 1;
    let offset = 0;

    if (month < startDateFiscalYear) {
      offset -= 1;
    }

    if (startDateFiscalYear > 1 && this.fiscalYear === 'JANUARY') {
      offset += 1;
    }

    return date
      .year(year + offset)
      .month(startDateFiscalYear - 1)
      .startOf('month');
  }

  /**
   * Method used to clean string
   * String is capitalized (first letter is upper cased)
   * All accents are removed
   */
  private static cleanString(string: string): string {
    return normalizeStringNFD(capitalizeString(string));
  }
}
