import { OptionalLotsTableStatus } from 'store/store';

type NodeType = 'rootLot' | 'lot' | 'job';

interface Tree {
  id: string;
  type: NodeType;
  subTrees?: Tree[];
  isOptional?: boolean | undefined;
}

interface TreeRelations {
  [key: string]: {
    nodes: (string | number)[];
    leaves: (string | number)[];
  };
}

interface JobLotRelations {
  [key: number | string]: {
    jobs: number[];
    lots: number[];
  };
}

interface Jobs {
  [key: number | string]: {
    id: number | string;
    isOptional?: boolean;
    isHiddenCost?: boolean;
  };
}

interface BuildTreeParams {
  id: number | string;
  type: NodeType;
  isOptional?: boolean | undefined;
  depth: number;
}

export class TreeDataPreCalculation {
  public autoNumberingTable: Map<string, string> = new Map();

  public optionalLotsTable: Map<string, boolean> = new Map();

  public optionalLotsWithoutJobInTheseChildren: string[] = [];

  public optionalLotsTableStatus: OptionalLotsTableStatus;

  public maxDepth: number = 0;

  public hiddenCostStatus: boolean;

  private readonly relations: JobLotRelations | undefined = undefined;

  private readonly jobs: Jobs;

  private readonly tree: Tree;

  constructor(relations: JobLotRelations | TreeRelations, jobs: Jobs) {
    // Vérification du type de relation
    if (this.isRelations(relations)) {
      this.relations = relations;
    }

    if (this.isTreeRelations(relations)) {
      // Adaptation des relations si elles sont de type TreeRelations
      this.relations = this.adapterTreeRelationsToRelations(relations);
    }

    this.jobs = jobs;
    const rootLotId = Object.keys(relations)[0];
    this.tree = this.buildTree({ id: rootLotId, type: 'rootLot', isOptional: false, depth: 0 });

    const autoNumberingStartIndex = '1';

    this.optionalLotsTableStatus = this.findOptionalLotsTableStatus(jobs);
    this.hiddenCostStatus = this.findHiddenCostStatus(jobs);
    this.computeTree(this.tree, autoNumberingStartIndex);
  }

  // Méthode pour vérifier si les relations sont de type TreeRelations
  private isTreeRelations(arg: any): arg is TreeRelations {
    const firstKey = Object.keys(arg)[0];

    return (
      Object.prototype.hasOwnProperty.call(arg[firstKey], 'nodes') &&
      Object.prototype.hasOwnProperty.call(arg[firstKey], 'leaves')
    );
  }

  // Méthode pour vérifier si les relations sont de type JobLotRelations
  private isRelations(arg: any): arg is JobLotRelations {
    const firstKey = Object.keys(arg)[0];

    return (
      Object.prototype.hasOwnProperty.call(arg[firstKey], 'jobs') &&
      Object.prototype.hasOwnProperty.call(arg[firstKey], 'lots')
    );
  }

  // Méthode pour adapter les TreeRelations en JobLotRelations
  private adapterTreeRelationsToRelations(relations: TreeRelations): JobLotRelations {
    const relationsDeepCopy = JSON.parse(JSON.stringify(relations));

    Object.keys(relationsDeepCopy).forEach((nodeKey) => {
      relationsDeepCopy[nodeKey].jobs = relationsDeepCopy[nodeKey].leaves.map((l: string | number) =>
        typeof l === 'string' ? l : l.toString(),
      );

      relationsDeepCopy[nodeKey].lots = relationsDeepCopy[nodeKey].nodes.map((n: string | number) =>
        typeof n === 'string' ? n : n.toString(),
      );

      delete relationsDeepCopy[nodeKey].nodes;
      delete relationsDeepCopy[nodeKey].leaves;
    });

    return relationsDeepCopy;
  }

  // Méthode pour construire l'arbre de données
  private buildTree({ id, type, isOptional, depth }: BuildTreeParams): Tree {
    if (!this.relations) {
      throw new Error('Relations is not defined');
    }

    if (this.maxDepth < depth) {
      this.maxDepth = depth;
    }

    const subTrees: Tree[] = [];

    const jobs = this.relations[id]?.jobs.filter((j) => this.jobs[j].isHiddenCost !== true);

    // Si des jobs sont définis dans les relations, on les ajoute aux sous-arbres
    if (jobs.length) {
      jobs.forEach((jobId) => {
        subTrees.push({ id: jobId.toString(), type: 'job', isOptional: this.jobs[jobId].isOptional });
      });
    }

    // Si des lots sont définis dans les relations, on construit leurs sous-arbres respectifs.
    if (this.relations[id]?.lots.length) {
      this.relations[id].lots.forEach((lotId) => {
        subTrees.push(this.buildTree({ id: lotId.toString(), type: 'lot', isOptional: undefined, depth: depth + 1 }));
      });
    }

    // Renvoie l'arbre construit avec son id, son type, ses sous-arbres.
    return { id: id.toString(), type, subTrees, isOptional };
  }

  // Méthode pour définir le caractère optionnel du sous-arbre
  private setSubTreeOptional(tree: Tree, isOptional: boolean) {
    // eslint-disable-next-line no-param-reassign
    tree.isOptional = isOptional;
    this.optionalLotsTable.set(`${tree.id}`, tree.isOptional);
    tree.subTrees?.forEach((subTree) => this.setSubTreeOptional(subTree, isOptional));
  }

  private computeTree(tree: Tree, index: string) {
    this.autoNumberingTable.set(`${tree.type}-${tree.id}`, index);
    tree.subTrees?.forEach((subTree, i) => this.computeTree(subTree, `${index}.${i + 1}`));

    const subTreesLot = tree.subTrees?.filter((subTree) => subTree.type === 'lot');
    const subTreesJob = tree.subTrees?.filter((subTree) => subTree.type === 'job');

    // Si le lot n'a pas de sous-arbres de type lot et de sous-arbres de type job, on définit son caractère optionnel a undefined
    if (tree.type === 'lot' && !subTreesLot?.length && !subTreesJob?.length) {
      // eslint-disable-next-line no-param-reassign
      tree.isOptional = undefined;
      this.optionalLotsWithoutJobInTheseChildren.push(tree.id);
      return;
    }

    // Si le lot n'a pas de sous-arbres de type lot, on définit son caractère optionnel par rapport à ses sous-arbres de type job
    if (tree.type === 'lot' && !subTreesLot?.length) {
      // eslint-disable-next-line no-param-reassign
      tree.isOptional = tree.subTrees?.every((subTree) => subTree.isOptional);
      if (tree.isOptional !== undefined) {
        this.optionalLotsTable.set(`${tree.id}`, tree.isOptional);
      }

      return;
    }

    // Si le lot a des sous-arbres de type lot et de type job, on définit son caractère optionnel par rapport à ses sous-arbres de type lot et de type job
    if (tree.type === 'lot' && tree.isOptional === undefined) {
      // Le lot n'est pas optionnel si au moins un de ses sous-arbres de type lot ou job n'est pas optionnel
      const isNotOptional = tree.subTrees?.some((subTree) => subTree.isOptional === false);

      if (isNotOptional) {
        // eslint-disable-next-line no-param-reassign
        tree.isOptional = false;
        tree.subTrees?.forEach((subTree) => {
          if (subTree.isOptional === undefined) {
            this.setSubTreeOptional(subTree, false);
          }
        });
        this.optionalLotsTable.set(`${tree.id}`, false);
        return;
      }

      // Vérifie si tous les sous-arbres de subTreesLot sont optionnels ou si subTreesLot est indéfini. Renvoie true dans ces cas.
      const allSubTreesLotOptionalOrUndefined = subTreesLot?.every((subTree) => subTree.isOptional !== false);
      // Vérifie s'il y a au moins un sous-arbre optionnel dans subTreesLot, ou si subTreesLot est indéfini. Renvoie true dans ces cas.
      const atLeastOneSubTreeLotOptional = subTreesLot?.some((subTree) => subTree.isOptional === true);
      // Vérifie si tous les sous-arbres de subTreesJob sont optionnels et si subTreesJob n'est pas vide. Renvoie true si ces conditions sont remplies.
      const allSubTreesJobOptional = subTreesJob?.length && subTreesJob.every((subTree) => subTree.isOptional === true);

      // Définit isOptional comme true si tous les sous-arbres de subTreesLot sont optionnels (ou si subTreesLot est indéfini)
      // et si au moins un sous-arbre de subTreesLot est optionnel ou si tous les sous-arbres de subTreesJob sont optionnels.
      const isOptional = allSubTreesLotOptionalOrUndefined && (atLeastOneSubTreeLotOptional || allSubTreesJobOptional);

      if (isOptional) {
        // eslint-disable-next-line no-param-reassign
        tree.isOptional = true;
        tree.subTrees?.forEach((subTree) => {
          if (subTree.isOptional === undefined) {
            this.setSubTreeOptional(subTree, true);
          }
        });
        this.optionalLotsTable.set(`${tree.id}`, true);
        return;
      }

      this.optionalLotsWithoutJobInTheseChildren.push(tree.id);
    }
  }

  private findHiddenCostStatus(jobs: Jobs): boolean {
    return Object.values(jobs)?.some((subTree) => subTree.isHiddenCost);
  }

  private findOptionalLotsTableStatus(jobs: Jobs): OptionalLotsTableStatus {
    let isOptional = OptionalLotsTableStatus.HAS_NO_OPTION;

    const jobArray = Object.values(jobs);

    const atLeastOneJobIsOptional = jobArray?.some((subTree) => subTree.isOptional);

    if (atLeastOneJobIsOptional) {
      isOptional = OptionalLotsTableStatus.HAS_ONE_OR_MORE_OPTIONS;
      const allJobsIsOptional = jobArray?.every((subTree) => subTree.isOptional);

      if (allJobsIsOptional) {
        isOptional = OptionalLotsTableStatus.HAS_ONLY_OPTIONS;
      }
    }
    return isOptional;
  }
}
