export enum PHONE_NUMBER_ERROR {
  UNDEFINED_PHONE_NUMBER = 'UNDEFINED_PHONE_NUMBER',
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
}

const PHONE_FRENCH_REGEX = /((00|\+)?330?|0)((6|7)[0-9]{8})/;

export function getValidPhoneNumber(
  phoneNumber: string | null | undefined,
): { status: 'ok'; result: string } | { status: 'ko'; error: PHONE_NUMBER_ERROR } {
  if (!phoneNumber) {
    return {
      status: 'ko',
      error: PHONE_NUMBER_ERROR.UNDEFINED_PHONE_NUMBER,
    };
  }

  const cleanedUpPhoneNumber = phoneNumber.replace(/[^0-9+]/g, '');

  // If number is +330637441722 or +33637441722, it's valid
  // Is French phone
  if (cleanedUpPhoneNumber.match(PHONE_FRENCH_REGEX)) {
    return {
      status: 'ok',
      result: cleanedUpPhoneNumber.replace(PHONE_FRENCH_REGEX, '+33$3'),
    };
  }

  return {
    status: 'ko',
    error: PHONE_NUMBER_ERROR.INVALID_PHONE_NUMBER,
  };
}
