import type {
  QuoteBasicItemPopulateDTO,
  QuoteBasicItemTransformDTO,
  QuoteBasicItemUpsertDTO,
  QuoteBasicItemsApi,
} from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteBasicItemsProxyApi {
  private static quoteBasicItemsProxyApi: QuoteBasicItemsProxyApi;

  private readonly quoteBasicItemsApi: QuoteBasicItemsApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteBasicItemsApi = externalApi.getQuoteBasicItemsApi();
  }

  public static getQuoteBasicItemsProxyApi(externalApi: ExternalApi): QuoteBasicItemsProxyApi {
    if (!this.quoteBasicItemsProxyApi) {
      QuoteBasicItemsProxyApi.quoteBasicItemsProxyApi = new QuoteBasicItemsProxyApi(externalApi);
    }
    return QuoteBasicItemsProxyApi.quoteBasicItemsProxyApi;
  }

  public async createQuoteBasicItem(quoteBasicItemCreateDTO: QuoteBasicItemUpsertDTO) {
    return proxyHelper(async () => this.quoteBasicItemsApi.createQuoteBasicItem(quoteBasicItemCreateDTO));
  }

  public async updateQuoteBasicItem(quoteBasicItemId: string, quoteBasicItemUpdateDTO: QuoteBasicItemUpsertDTO) {
    return proxyHelper(async () =>
      this.quoteBasicItemsApi.updateQuoteBasicItem(quoteBasicItemId, quoteBasicItemUpdateDTO),
    );
  }

  public async transformQuoteBasicItem(
    quoteBasicItemId: string,
    quoteBasicItemTransformDTO: QuoteBasicItemTransformDTO,
  ) {
    return proxyHelper(async () =>
      this.quoteBasicItemsApi.transformQuoteBasicItem(quoteBasicItemId, quoteBasicItemTransformDTO),
    );
  }

  public async populateQuoteBasicItem(quoteBasicItemUpsertManyDTO: QuoteBasicItemPopulateDTO) {
    return proxyHelper(async () => this.quoteBasicItemsApi.populateQuoteBasicItem(quoteBasicItemUpsertManyDTO));
  }
}
