import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import { ActionMenu, SimpleDownloadIcon, SimpleViewIcon, formatDateOrEmpty } from '@graneet/lib-ui';
import { useHistory } from 'react-router-dom';
import type {
  IFinalStatementsVersionsDTO,
  IProject,
  IFinalStatementVersion,
  IFinalStatementConditionsResponseDTO,
} from '@graneet/business-logic';
import { PERMISSION, isProjectActive, PDF_STATUSES } from '@graneet/business-logic';
import type { TFunction } from 'i18next';

import { FinalStatementCheckModal } from './modals/FinalStatementCheckModal';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { formatFileName, mapPdfToFileItemProps } from 'features/file/services/file.util';
import { FilesCard } from 'features/file/components/FilesCard/FilesCard';

const mapFinalStatementToFileItemProps = (fs: IFinalStatementVersion, t: TFunction<['project', 'global'], undefined>) =>
  ({
    ...mapPdfToFileItemProps(fs.pdf, formatFileName(fs.name, fs.createdAt)),
    key: fs.id,
    hasError: !fs.pdf || fs.pdf.status === PDF_STATUSES.ERROR,
    archived: fs.isArchived,
    name: fs.name,
    createdAt: t('project:finalStatement.pdf.creationDate', {
      date: formatDateOrEmpty(fs.createdAt),
    }),
    variant: 'compact',
  }) as const;

interface FinalStatementVersionsCardProps {
  project: IProject;

  finalStatements: IFinalStatementsVersionsDTO;

  finalStatementCheckDTO: IFinalStatementConditionsResponseDTO;
}

export const FinalStatementVersionsCard: FC<FinalStatementVersionsCardProps> = ({
  project,
  finalStatements,
  finalStatementCheckDTO,
}) => {
  const { t } = useTranslation(['project', 'global'] as const);
  const history = useHistory();

  const canSeeStatementModule = usePermissions([PERMISSION.ACCESS_STATEMENTS]);

  const finalStatementCheckModal = useDisclosure();

  const currentFinalStatement = finalStatements.current;
  const archivedFinalStatements = finalStatements.archived;
  const { isFinalStatementAvailable } = finalStatementCheckDTO;

  const onModifyFinalStatementClicked = useCallback(() => {
    if (!isFinalStatementAvailable) {
      finalStatementCheckModal.onOpen();
    } else {
      history.push(`/projects/${project.id}/final-statement`);
    }
  }, [isFinalStatementAvailable, finalStatementCheckModal, history, project.id]);

  const finalStatementFileItemTranslations = {
    messageWhileGenerating: t('project:finalStatement.pdf.pdfNotReady'),
  };

  const PreviewAction = useCallback(
    ({ previewLink, downloadLink }: { previewLink?: string; downloadLink?: string }) => (
      <>
        <ActionMenu.Action
          icon={<SimpleViewIcon />}
          onClick={() => window.open(previewLink)}
          label={t('global:pdf.view')}
        />

        <ActionMenu.Action
          icon={<SimpleDownloadIcon />}
          onClick={() => window.open(downloadLink)}
          label={t('global:pdf.download')}
        />
      </>
    ),
    [t],
  );

  const PreviewMenu = useCallback(
    ({ previewLink, downloadLink }: { previewLink?: string; downloadLink?: string }) => (
      <ActionMenu>
        <PreviewAction previewLink={previewLink} downloadLink={downloadLink} />
      </ActionMenu>
    ),
    [PreviewAction],
  );

  const ModifyMenu = useCallback(
    ({ previewLink, downloadLink }: { previewLink?: string; downloadLink?: string }) => (
      <ActionMenu>
        {isProjectActive(project) && canSeeStatementModule && (
          <ActionMenu.Edit onClick={onModifyFinalStatementClicked} />
        )}
        <PreviewAction previewLink={previewLink} downloadLink={downloadLink} />
      </ActionMenu>
    ),
    [PreviewAction, canSeeStatementModule, onModifyFinalStatementClicked, project],
  );

  // If there is no current final statement, do not display the card
  if (!currentFinalStatement) {
    return null;
  }

  return (
    <>
      <FinalStatementCheckModal
        onClose={finalStatementCheckModal.onClose}
        isOpen={finalStatementCheckModal.isOpen}
        finalStatementCheckDTO={finalStatementCheckDTO}
        projectName={project.name}
      />

      <FilesCard title={t('project:finalStatement.pdf.title')}>
        <FilesCard.FileItem
          {...finalStatementFileItemTranslations}
          {...mapFinalStatementToFileItemProps(currentFinalStatement, t)}
          rightContent={ModifyMenu}
        />

        <FilesCard.Versions>
          {archivedFinalStatements.map((archivedFinalStatement) => (
            <FilesCard.FileItem
              {...finalStatementFileItemTranslations}
              {...mapFinalStatementToFileItemProps(archivedFinalStatement, t)}
              rightContent={PreviewMenu}
            />
          ))}
        </FilesCard.Versions>
      </FilesCard>
    </>
  );
};
