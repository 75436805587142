import { useCallback } from 'react';

import { useMoveLotAfterOtherLot } from './useMoveLotAfterOtherLot';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useMoveLotInsideOtherLot = () => {
  const moveLotAfterOtherLot = useMoveLotAfterOtherLot();

  const { findLastLotIdInLot } = useQuoteEditContext();

  return useCallback(
    async (lotId: number, newParentLotId: number) => {
      const lastLotIdInNewParentLot = findLastLotIdInLot(newParentLotId);
      if (lotId === lastLotIdInNewParentLot) return;
      await moveLotAfterOtherLot(lotId, lastLotIdInNewParentLot || undefined, newParentLotId);
    },
    [findLastLotIdInLot, moveLotAfterOtherLot],
  );
};
