import type { FC, PropsWithChildren } from 'react';
import { useCallback } from 'react';
import type { UseDisclosureReturn } from '@chakra-ui/react';

import { Modal } from './Modal';

type AlertModalProps = PropsWithChildren<{
  title: string;
  modal: UseDisclosureReturn;
  labels: { ok: string; ko: string };
  onConfirmed: () => void;
  destructive?: boolean;
}>;

export const ConfirmModal: FC<AlertModalProps> = ({
  title,
  modal,
  onConfirmed,
  destructive = false,
  labels,
  children,
}) => {
  const onAnswered = useCallback(
    (confirmed: boolean) => {
      if (confirmed) onConfirmed();
      modal.onClose();
    },
    [onConfirmed, modal],
  );

  return (
    <Modal title={title} {...modal}>
      <Modal.PrimaryButton colorScheme={destructive ? 'red' : undefined} onClick={() => onAnswered(true)}>
        {labels.ok}
      </Modal.PrimaryButton>

      <Modal.SecondaryButton colorScheme="gray" onClick={() => onAnswered(false)}>
        {labels.ko}
      </Modal.SecondaryButton>

      {children}
    </Modal>
  );
};
