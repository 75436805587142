import type {
  CREDIT_STATUS,
  DOWN_PAYMENT_STATUSES,
  HOLDBACK_STATUSES,
  INVOICE_STATUSES,
  IStatement,
  PROGRESS_STATEMENT_STATUSES,
  ValueOf,
} from '@graneet/business-logic';
import { DEADLINE_TYPE, STATEMENT_TYPES } from '@graneet/business-logic';
import type { TFunction } from 'i18next';

import { INVOICE_STATUS_COLORS } from '../../invoice/constants/invoice.constant';
import { STATEMENT_PATHS } from '../constants/statement.constant';

import { testInvoiceNumberAvailability } from './statement.api';

import { PROGRESS_STATEMENT_STATUS_COLORS } from 'features/progress-statement/services/progress-statement.constant';

export const isInvoiceNumberNotAvailable = async (
  invoiceNumber: string,
  statementIdExcepted?: number,
  type?: STATEMENT_TYPES,
) => {
  const [err, isAvailable] = await testInvoiceNumberAvailability(
    invoiceNumber,
    statementIdExcepted,
    statementIdExcepted ? type : undefined,
  );
  if (err) {
    return true;
  }
  return !isAvailable;
};

export const getStatementStatusColor = (statement: Pick<IStatement, 'type' | 'status'>) =>
  [STATEMENT_TYPES.PROGRESS_STATEMENT, STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT].includes(statement.type)
    ? PROGRESS_STATEMENT_STATUS_COLORS[statement.status]
    : INVOICE_STATUS_COLORS[statement.status as unknown as INVOICE_STATUSES];

export const getStatementStatusLabel = (
  t: TFunction<['statement', 'invoice', 'downPayment', 'progressStatement', 'credit', 'holdback'], undefined>,
  statement: Pick<IStatement, 'type' | 'status'>,
) => {
  if (statement.type === STATEMENT_TYPES.INVOICE) {
    return t(`invoice:statuses.${statement.status as unknown as INVOICE_STATUSES}`);
  }
  if (statement.type === STATEMENT_TYPES.DOWN_PAYMENT) {
    return t(`downPayment:statuses.${statement.status as unknown as DOWN_PAYMENT_STATUSES}`);
  }
  if (
    statement.type === STATEMENT_TYPES.PROGRESS_STATEMENT ||
    statement.type === STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT
  ) {
    return t(`progressStatement:statuses.${statement.status as unknown as PROGRESS_STATEMENT_STATUSES}`);
  }
  if (statement.type === STATEMENT_TYPES.CREDIT) {
    return t(`credit:statuses.${statement.status as unknown as CREDIT_STATUS}`);
  }
  if (statement.type === STATEMENT_TYPES.HOLDBACK) {
    return t(`holdback:statuses.${statement.status as unknown as HOLDBACK_STATUSES}`);
  }
  statement.type satisfies never;
  throw new Error('Translation not implemented');
};

// TODO : remove default value when invoices translations are done
export const getPaymentTermI18nKey = (deadlineType?: DEADLINE_TYPE) =>
  `statement:paymentDeadLine.types.${deadlineType || DEADLINE_TYPE.AFTER_TERM}` as const;

export const getStatementType = (statementTypeInPath: ValueOf<typeof STATEMENT_PATHS>) =>
  Object.keys(STATEMENT_PATHS).find(
    (key) => STATEMENT_PATHS[key as keyof typeof STATEMENT_PATHS] === statementTypeInPath,
  );

export const getStatementPath = (statementType: keyof typeof STATEMENT_PATHS) => STATEMENT_PATHS[statementType];
