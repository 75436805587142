import { useMemo } from 'react';
import type { ReactElement, FC, ReactNode } from 'react';
import type { BoxProps, TooltipProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

import { Tooltip } from '../../../Tooltip';

export interface PopUpMenuItemProps extends BoxProps {
  children?: ReactElement;

  onClick?: () => void;

  tooltip?: ReactNode;

  tooltipProps?: Omit<TooltipProps, 'children'>;
}

export const PopUpMenuItem: FC<PopUpMenuItemProps> = ({ tooltip, tooltipProps, children, ...props }) => {
  const content = useMemo(() => {
    if (tooltip || tooltipProps) {
      return (
        <Tooltip label={tooltip} {...tooltipProps}>
          {children}
        </Tooltip>
      );
    }
    return children;
  }, [children, tooltip, tooltipProps]);

  return <Box {...props}>{content}</Box>;
};
