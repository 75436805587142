import type { IWorker, IUser, IContact } from '@graneet/business-logic';

export const formatUserDisplayName = <T extends IUser | IWorker | IContact>(user: Pick<T, 'firstName' | 'lastName'>) =>
  `${user.firstName ? user.firstName : ''} ${user.lastName}`.trim();

export const sortUsers = <T extends Pick<IUser, 'id' | 'firstName' | 'lastName'>[]>(users: T) =>
  users?.sort((userA, userB) => {
    const orderPriorityLastName = userA.lastName.localeCompare(userB.lastName);
    const orderPriorityFirstName = userA.firstName.localeCompare(userB.firstName);
    const orderPriorityId = userA.id.toString().localeCompare(userB.id.toString());

    return orderPriorityLastName || orderPriorityFirstName || orderPriorityId;
  });
