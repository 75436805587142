import type {
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  SizeColumnsToContentStrategy,
} from '@ag-grid-community/core';
import { useMemo } from 'react';

export const useAutoSizeStrategy = () =>
  useMemo<SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy>(
    () => ({
      type: 'fitGridWidth',
    }),
    [],
  );
