import type { FC } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useCurrency, Wizard, ConfirmModal } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import type { IOrder } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { useDisclosure, Text } from '@chakra-ui/react';

import { OrderInformationStep } from './_step/OrderInformationStep';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { mapCreateOrderDTO, mapUpdateOrderDTO } from 'features/order/services/order.util';
import { useOrderCreate, useOrderOrUndefined, useOrderUpdate } from 'features/order/services/order.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useProjectLazyAreInUserScope } from 'features/project/services/project.api';
import type { OrderEditForm, OrderEditWizard } from 'features/order/forms/order-edit-wizard';
import type { OrderTree } from 'features/order/hooks/useOrderTree';

export const CreateOrEditOrderScreen: FC = () => {
  useHideNavbar();

  const { t } = useTranslation(['global', 'orders']);
  const { mapAmountToNumber } = useCurrency();

  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();

  const { state } = useLocation<{ projectId?: string }>();
  const projectId = state?.projectId;
  const { createRedirectionWithSavedFilters } = useFiltersQuery();
  const contextPath = projectId ? `/projects/${projectId}` : '';

  const accessProjects = usePermissions([PERMISSION.VIEW_ALL_PROJECTS]);
  const hasCreateOrdersPermission = usePermissions([PERMISSION.CREATE_ORDER]);
  const confirmScopeModal = useDisclosure();
  const formValues = useRef<OrderEditForm | undefined>();
  const getCurrentTreeRef = useRef<OrderTree['getCurrentTree']>(() => ({
    leaves: {},
    nodes: {},
    rootNodeId: '',
    relations: {},
    expandedNodeIds: {},
  }));

  const goBackToListing = createRedirectionWithSavedFilters(`${contextPath}/purchases/orders/`, { replace: true });

  const { mutateAsync: projectLazyAreInUserScopeQuery } = useProjectLazyAreInUserScope();

  const onQuit = useCallback(() => {
    if (orderId) {
      return history.push(`${contextPath}/purchases/orders/${orderId}`);
    }
    return goBackToListing();
  }, [orderId, goBackToListing, history, contextPath]);

  const wizardState = orderId ? 'update' : 'create';
  const wizardLabels = useWizardLabels({
    previous: t('global:words.c.previous'),
    save: t(`orders:${wizardState}.action`),
  });

  const order = useOrderOrUndefined(orderId ? +orderId : undefined);

  const orderCreateMutation = useOrderCreate('creation');
  const orderUpdateMutation = useOrderUpdate();

  const sendData = useCallback(
    async (orderEditForm: OrderEditForm, forceListingRedirection = false) => {
      const { receiptFiles, ...otherFormValues } = orderEditForm;

      const treeState = getCurrentTreeRef.current();

      let response: IOrder;
      if (orderId) {
        const initialAssociatedSupplierInvoice = order.data?.ordersSupplierInvoices?.map(
          ({ supplierInvoice }) => supplierInvoice,
        );

        response = await orderUpdateMutation.mutateAsync({
          id: +orderId,
          dto: mapUpdateOrderDTO(otherFormValues, treeState, mapAmountToNumber, initialAssociatedSupplierInvoice),
          orderFiles: receiptFiles,
        });
      } else {
        response = await orderCreateMutation.mutateAsync({
          dto: mapCreateOrderDTO(otherFormValues, treeState, mapAmountToNumber),
          orderFiles: receiptFiles,
        });
      }

      const projectFormId = orderEditForm.projectId;
      const orderResultId = response.id;

      // Redirect user
      const path = projectId && projectFormId === parseInt(projectId, 10) ? `/projects/${projectId}` : '';
      if (!forceListingRedirection) {
        history.push(`${path}/purchases/orders/${orderResultId}`);
        return;
      }
      goBackToListing();
    },
    [
      goBackToListing,
      history,
      mapAmountToNumber,
      order.data?.ordersSupplierInvoices,
      orderCreateMutation,
      orderId,
      orderUpdateMutation,
      projectId,
    ],
  );

  const onFinish = useCallback(
    async (wizardValues: OrderEditWizard) => {
      const { information: orderEditForm } = wizardValues;

      if (orderEditForm.projectId && !accessProjects) {
        const scopeResult = await projectLazyAreInUserScopeQuery([orderEditForm.projectId]);

        if (orderEditForm.projectId && scopeResult[orderEditForm.projectId] === false) {
          formValues.current = orderEditForm;
          confirmScopeModal.onOpen();
          return;
        }
      }
      await sendData(orderEditForm);
    },
    [accessProjects, sendData, projectLazyAreInUserScopeQuery, confirmScopeModal],
  );

  const onConfirmScope = useCallback(async () => {
    await sendData(formValues.current!, true);
  }, [sendData]);

  useEffect(() => {
    if (!hasCreateOrdersPermission) {
      goBackToListing();
    }
  }, [goBackToListing, hasCreateOrdersPermission]);

  return (
    <Wizard<OrderEditWizard>
      onQuit={onQuit}
      onFinish={onFinish}
      labels={wizardLabels}
      headerTitle={t(`orders:${wizardState}.title`)}
    >
      <Wizard.Step<OrderEditWizard> name="information">
        <QueryWrapper>
          <OrderInformationStep order={order.data} getCurrentTreeRef={getCurrentTreeRef} />
        </QueryWrapper>
      </Wizard.Step>

      <ConfirmModal
        title={t(`orders:confirmScopeModal.${wizardState}.title`)}
        modal={confirmScopeModal}
        onConfirmed={onConfirmScope}
        labels={{ ok: t('global:words.c.validate'), ko: t('global:words.c.back') }}
      >
        <Text>{t(`orders:confirmScopeModal.${wizardState}.body`)}</Text>
      </ConfirmModal>
    </Wizard>
  );
};
