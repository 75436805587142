import { HStack } from '@chakra-ui/react';
import { SettingsMenu } from '@graneet/lib-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { QuoteAGGridType } from 'features/quotation/ag-grid-quote/constants/agGridConstants';
import { useColumnSettings } from 'features/quotation/ag-grid-quote/hooks/column-def/useColumnSettings';

export const ActionHeader = ({ type }: { type: QuoteAGGridType }) => {
  const { t } = useTranslation(['quote']);

  const { getColumnDef, toggleColumn } = useColumnSettings(type);

  const groups = useMemo(() => {
    const code = getColumnDef('code');
    const denomination = getColumnDef('denomination');
    const refCode = getColumnDef('refCode');
    const quantity = getColumnDef('quantity');
    const unit = getColumnDef('unit');
    const unitFlatCostAmount = getColumnDef('unitFlatCostAmount');
    const flatCostAmount = getColumnDef('flatCostAmount');
    const totalMargin = type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('totalMargin');
    const unitAmount = type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('unitAmount');
    const vatRate = type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('vatRate');
    const workingDuration = getColumnDef('workingDuration');
    const amount = type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('amount');
    const shareOfHiddenCostAmount =
      type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('shareOfHiddenCostAmount');
    const costAmount = type === QuoteAGGridType.HIDDEN_COST ? null : getColumnDef('costAmount');
    const image = getColumnDef('image');

    return [
      {
        items: [
          {
            label: t('quote:quotation.modals.export.fields.disbursement'),
            name: 'debours',
            value: true,
            items: [
              {
                label: unitFlatCostAmount.label,
                name: 'unitFlatCostAmount',
                value: unitFlatCostAmount.enabled,
              },
              {
                label: flatCostAmount.label,
                name: 'flatCostAmount',
                value: flatCostAmount.enabled,
              },
              ...(type === QuoteAGGridType.HIDDEN_COST
                ? []
                : [
                    {
                      label: shareOfHiddenCostAmount!.label,
                      name: 'shareOfHiddenCostAmount',
                      value: shareOfHiddenCostAmount!.enabled,
                    },
                    {
                      label: costAmount!.label,
                      name: 'costAmount',
                      value: costAmount!.enabled,
                    },
                  ]),
            ],
          },
          ...(type === QuoteAGGridType.HIDDEN_COST
            ? []
            : [
                {
                  label: t('quote:quotation.modals.export.fields.sale'),
                  name: 'sale',
                  value: true,
                  items: [
                    {
                      label: totalMargin!.label,
                      name: 'totalMargin',
                      value: totalMargin!.enabled,
                    },
                    {
                      label: unitAmount!.label,
                      name: 'unitAmount',
                      value: unitAmount!.enabled,
                    },
                    {
                      label: vatRate!.label,
                      name: 'vatRate',
                      value: vatRate!.enabled,
                    },
                    {
                      label: amount!.label,
                      name: 'amount',
                      value: amount!.enabled,
                    },
                  ],
                },
              ]),
        ],
      },
      {
        items: [
          ...(type === QuoteAGGridType.HIDDEN_COST
            ? []
            : [
                {
                  label: code.label,
                  name: 'code',
                  value: code.enabled,
                  disabled: true,
                },
              ]),
          {
            label: denomination.label,
            name: 'denomination',
            value: denomination.enabled,
            disabled: true,
          },
          {
            label: refCode.label,
            name: 'refCode',
            value: refCode.enabled,
          },
          {
            label: unit.label,
            name: 'unit',
            value: unit.enabled,
          },
          {
            label: quantity.label,
            name: 'quantity',
            value: quantity.enabled,
          },
          {
            label: workingDuration.label,
            name: 'workingDuration',
            value: workingDuration.enabled,
          },
          {
            label: image.label,
            name: 'image',
            value: image.enabled,
          },
        ],
      },
    ];
  }, [getColumnDef, type, t]);

  return (
    <HStack height="100%" width="100%" justifyContent="center" fontSize="0.813rem">
      <SettingsMenu
        tooltip={t('quote:quotation.tooltips.columnsSettings')}
        isEditable
        variant="ghost"
        icon="ri-settings-2-line"
        placement="bottom-end"
        groups={groups}
        onChange={toggleColumn}
        size="md"
      />
    </HStack>
  );
};
