import type {
  IQuoteComponentResponseDTO,
  IQuoteInfosResponse,
  IQuoteJobCreateDTO,
  IQuoteJobLibraryComponentsImportDTO,
  IQuoteJobMoveDTO,
  IQuoteJobUnitPriceUpdateDTO,
  IQuoteJobUpdateDTO,
  IQuoteUpdateResponse,
  IUpdateTotalMarginDTO,
} from '@graneet/business-logic';

import { api } from 'features/api/services/api.service';

const QUOTE_JOB_PATH = '/quote-jobs';

export const createJobAfterJob = (quoteJobCreateDTO: IQuoteJobCreateDTO) =>
  api.post<IQuoteJobCreateDTO, IQuoteUpdateResponse>(`${QUOTE_JOB_PATH}`, quoteJobCreateDTO);

export const duplicateJob = (jobId: number) =>
  api.post<never, IQuoteInfosResponse>(`${QUOTE_JOB_PATH}/${jobId}/duplicate`);

export const updateJob = (jobId: number, quoteJobUpdateDTO: IQuoteJobUpdateDTO) =>
  api.patch<IQuoteJobUpdateDTO, IQuoteUpdateResponse>(`${QUOTE_JOB_PATH}/${jobId}`, quoteJobUpdateDTO);

export const uploadJobFiles = (jobId: number, filesInFormData: FormData) =>
  api.putWithMultiPart<FormData, IQuoteUpdateResponse>(`${QUOTE_JOB_PATH}/${jobId}/files`, filesInFormData);

export const deleteJobFile = (jobId: number, fileId: string) =>
  api.delete<never, IQuoteUpdateResponse>(`${QUOTE_JOB_PATH}/${jobId}/file/${fileId}`);

export const updateJobMargin = (jobId: number, updateTotalMarginDTO: IUpdateTotalMarginDTO) =>
  api.patch<IUpdateTotalMarginDTO, IQuoteUpdateResponse>(`${QUOTE_JOB_PATH}/${jobId}/margin`, updateTotalMarginDTO);

export const updateJobUnitPrice = (jobId: number, quoteJobUnitPriceUpdateDTO: IQuoteJobUnitPriceUpdateDTO) =>
  api.patch<IQuoteJobUnitPriceUpdateDTO, IQuoteUpdateResponse>(
    `${QUOTE_JOB_PATH}/${jobId}/unit-price`,
    quoteJobUnitPriceUpdateDTO,
  );

export const updateJobComponentMargin = (
  jobId: number,
  componentId: number,
  updateTotalMarginDTO: IUpdateTotalMarginDTO,
) =>
  api.patch<IUpdateTotalMarginDTO, IQuoteUpdateResponse>(
    `${QUOTE_JOB_PATH}/${jobId}/component/${componentId}/margin`,
    updateTotalMarginDTO,
  );

export const deleteJob = (jobId: number) => api.delete<never, IQuoteUpdateResponse>(`${QUOTE_JOB_PATH}/${jobId}`);

export const moveJob = (jobId: number, moveJobPayload: IQuoteJobMoveDTO) =>
  api.patch<IQuoteJobMoveDTO, IQuoteUpdateResponse>(`${QUOTE_JOB_PATH}/${jobId}/move`, moveJobPayload);

export const importComponentsInJob = (
  jobId: number,
  quoteJobLibraryComponentsImportDTO: IQuoteJobLibraryComponentsImportDTO,
) =>
  api.post<IQuoteJobLibraryComponentsImportDTO, IQuoteComponentResponseDTO>(
    `${QUOTE_JOB_PATH}/${jobId}/import`,
    quoteJobLibraryComponentsImportDTO,
  );
