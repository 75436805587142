import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { Quote } from '@org/quotation-lib';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuoteComponentUpdateSimilar } from './useQuoteComponentUpdateSimilar';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { genericUpdateItemMapper } from 'features/quotation/quote-common/mappers/quoteItemsGenericMapper';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export function useQuoteComponentUpdateUnit() {
  const { quoteComponentsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteComponentUpdateSimilar = useQuoteComponentUpdateSimilar();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, newUnit: string | null): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteComponent = quote.getTree().getQuoteComponentOrThrow(nodeId);
          const quoteNode = quoteComponent.getNodeOrThrow();
          this.oldValue = quoteComponent.getUnit();

          quoteComponentUpdateSimilar(nodeId, {
            unit: newUnit,
          });

          quoteComponent.updateProperties({
            unit: newUnit,
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteComponentsProxyApi
            .updateQuoteComponent(quoteComponent.getId(), {
              ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
              unit: newUnit,
              quoteComponentId: quoteComponent.getId(),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteComponent = quote.getTree().getQuoteComponentOrThrow(nodeId);
          const quoteNode = quoteComponent.getNodeOrThrow();

          quoteComponentUpdateSimilar(nodeId, {
            unit: this.oldValue,
          });

          quoteComponent.updateProperties({
            unit: this.oldValue,
          });
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteComponentsProxyApi
            .updateQuoteComponent(quoteComponent.getId(), {
              ...genericUpdateItemMapper(quote.getId(), quoteNode, clientRequestId, timestamp),
              unit: this.oldValue,
              quoteComponentId: quoteComponent.getId(),
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteComponentUpdateSimilar, quoteComponentsProxyApi, quoteError, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
