import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Text, useDisclosure } from '@chakra-ui/react';
import {
  Badge,
  Card,
  ConfirmDeletionModal,
  DocumentStatusCard,
  DocumentStatusCardActions,
  DollarLoadingIcon,
  formatDateOrEmpty,
  useCurrency,
} from '@graneet/lib-ui';
import type { IOrderResponseDTO, IProject, RequiredByKeys, ORDER_STATUS } from '@graneet/business-logic';
import {
  getEmailVariableData,
  PERMISSION,
  PDF_STATUSES,
  isOrderInDirectPaymentPartiallyEditable,
  LEDGER_TYPES,
} from '@graneet/business-logic';
import { useHistory } from 'react-router-dom';

import { OrderCheckListDeletion } from './OrderCheckListDeletion';
import { getOrderActions, getOrderStatusCardIcon } from './order.actions';

import type { MailingModalProps } from 'features/mailing/components/MailingModal/MailingModal';
import { sendMail } from 'features/mailing/services/mailing.api';
import { MailingModal } from 'features/mailing/components/MailingModal/MailingModal';
import { ORDER_DIRECT_PAYMENT_COLOR, ORDER_STATUS_COLOR } from 'features/order/constants/orders.constant';
import { useOrderCreate, useOrderUpdateStatus } from 'features/order/services/order.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { subscribeToPDFUpdates } from 'features/pdf/hooks/usePdfVersions';
import { getDownloadUrl, getPreviewUrl } from 'features/pdf/services/pdf.api';
import { formatFileName } from 'features/file/services/file.util';
import { duplicateOrder } from 'features/order/services/order.util';
import { getNextLedgerStatePreview } from 'features/ledger/services/ledger.api';
import { useData } from 'features/api/hooks/useData';

type OrderStatusCardProps = {
  order: IOrderResponseDTO;
  onStatusChangeSuccess: (oldStatus: string, newStatus: string) => void;
  onDeleted: () => void;
};

export const OrderStatusCard: FC<OrderStatusCardProps> = ({ order, onStatusChangeSuccess, onDeleted }) => {
  const { t } = useTranslation(['global', 'orders', 'mailing']);
  const history = useHistory();
  const { mapAmountToNumber } = useCurrency();
  const [isPdfGenerated, setIsPdfGenerated] = useState(order.pdf?.status === PDF_STATUSES.GENERATED);

  const orderCreateMutation = useOrderCreate('duplication');
  const orderUpdateStatusMutation = useOrderUpdateStatus();

  const mailingModal = useDisclosure();
  const deletionModal = useDisclosure();

  const onOrderStatusChange = useCallback(
    async (newStatus: ORDER_STATUS) => {
      const oldStatus = order.status;
      await orderUpdateStatusMutation.mutateAsync({ id: order.id, dto: { status: newStatus } });

      onStatusChangeSuccess(oldStatus, newStatus);
    },
    [order.status, order.id, orderUpdateStatusMutation, onStatusChangeSuccess],
  );

  const hasCreatePermission = usePermissions([PERMISSION.CREATE_ORDER]);

  /*
   * Duplication
   */

  const nextLedgerNumber = useCallback(
    () => getNextLedgerStatePreview({ type: LEDGER_TYPES.ORDER, projectId: order.project?.id }),
    [order.project?.id],
  );
  const { data: ledgerData } = useData(nextLedgerNumber);
  const formattedNextNumber = ledgerData === null ? undefined : (ledgerData?.formattedNextNumber ?? '');

  const onDuplicate = useCallback(async () => {
    const data = await orderCreateMutation.mutateAsync({
      dto: duplicateOrder(
        order,
        t('global:copyOf', { name: order.name }),
        formattedNextNumber,
        mapAmountToNumber,
        ledgerData !== null,
      ),
    });

    const path = order.project?.id ? `/projects/${order.project.id}` : '';

    history.push(`${path}/purchases/orders/${data.id}`);
  }, [formattedNextNumber, history, ledgerData, mapAmountToNumber, order, orderCreateMutation, t]);

  /*
   * Deletion
   */

  const canDelete = useMemo(
    () =>
      isOrderInDirectPaymentPartiallyEditable(
        order.ordersSupplierInvoices?.map(({ supplierInvoice }) => supplierInvoice) || [],
      ),
    [order.ordersSupplierInvoices],
  );

  const displayDeletionCheckList = order.isDirectPayment && !!order.ordersSupplierInvoices?.length;

  /**
   * PDF
   */

  const onViewPdf = useCallback(() => {
    if (order.pdf) {
      window.open(getPreviewUrl(order.pdf.apiId));
    }
  }, [order.pdf]);

  const onDownloadPdf = useCallback(() => {
    if (order.pdf) {
      window.open(getDownloadUrl(order.pdf.apiId, formatFileName(order.name, order.orderDate, order.orderNumber)));
    }
  }, [order.name, order.orderDate, order.orderNumber, order.pdf]);

  const onEdit = useCallback(
    () => history.push(`/purchases/orders/${order.id}/edit`, { projectId: order.project?.id }),
    [history, order.id, order.project],
  );

  const onMailSubmit = useCallback<MailingModalProps['onSubmit']>(
    (data) => sendMail(data.dto, data.attachments, data.mode),
    [],
  );

  useEffect(() => {
    if (order?.pdf?.status === PDF_STATUSES.GENERATING) {
      subscribeToPDFUpdates({ apiId: order.pdf.apiId }, (pdfStatus) => {
        setIsPdfGenerated(pdfStatus === PDF_STATUSES.GENERATED);
      });
    }
  }, [order]);

  return (
    <Card title={t('orders:stateCard.title')}>
      <DocumentStatusCard
        mb={4}
        icon={getOrderStatusCardIcon(order.status)}
        title={order.name}
        subtitle={t('orders:fields.creationDate', {
          date: formatDateOrEmpty(order.createdAt, 'LL'),
        })}
        badgeComponent={
          <HStack align="end">
            {order.isDirectPayment && (
              <Badge showDot colorScheme={ORDER_DIRECT_PAYMENT_COLOR}>
                <HStack align="end">
                  <DollarLoadingIcon boxSize={4} />
                  <Text>{t(`orders:stateCard.isDirectPayment`)}</Text>
                </HStack>
              </Badge>
            )}
            <Badge colorScheme={ORDER_STATUS_COLOR[order.status]}>{t(`orders:statuses.${order.status}`)}</Badge>
          </HStack>
        }
      />

      <DocumentStatusCardActions
        actions={getOrderActions({
          status: order.status,

          // Hack to add dependency on isPdfGenerated to force rerender and avoid infinite spinner
          pdf: { ...order.pdf, status: isPdfGenerated ? PDF_STATUSES.GENERATED : order.pdf?.status },

          canDelete,

          hasCreatePermission,

          onSetOrderStatus: onOrderStatusChange,
          onDelete: deletionModal.onOpen,
          onOpenMailing: mailingModal.onOpen,
          onEdit,
          onDuplicate,
          onViewPdf,
          onDownloadPdf,
        })}
      />

      {order.pdf && isPdfGenerated && (
        <MailingModal
          title={t('mailing:modal.title.order')}
          isOpen={mailingModal.isOpen}
          onClose={mailingModal.onClose}
          onSubmit={onMailSubmit}
          variableData={getEmailVariableData('order', {
            order,
            supplier: order.supplier,
            project: (order.project as RequiredByKeys<IProject, 'address'>) || null,
          })}
        />
      )}

      <ConfirmDeletionModal
        title={t('orders:delete.title')}
        action={t('orders:delete.action')}
        description={
          displayDeletionCheckList
            ? t('orders:delete.directPaymentConfirmationMessage')
            : t('orders:delete.defaultConfirmationMessage')
        }
        onDeleted={onDeleted}
        additionalContent={
          <>
            {displayDeletionCheckList && <OrderCheckListDeletion order={order} />}
            <Text mt={5}>{t('orders:delete.confirmationQuestion')}</Text>
          </>
        }
        isActionDisabled={!canDelete}
        {...deletionModal}
      />
    </Card>
  );
};
