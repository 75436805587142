import { createContext } from 'react';

import type { PaginationFilters } from '../hooks/filters-from-url.hook';
import type { SORTING_DIRECTIONS } from '../../PaginatedData';

export interface SortingContextApi {
  onSorting?(column: string, direction: SORTING_DIRECTIONS | null): Promise<void>;

  currentSorting?: PaginationFilters;
}
export const SortingContext = createContext<SortingContextApi>({
  onSorting: undefined,
  currentSorting: undefined,
});

export const SortingContextProvider = SortingContext.Provider;
