import type { FC } from 'react';
import type { LinkProps as LinkPropsChakra } from '@chakra-ui/react';
import { Link as LinkChakra } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export type LinkProps = LinkPropsChakra & Parameters<RouterLink>[0] & { isDisabled?: boolean };

export const Link: FC<LinkProps> = ({ children, isDisabled = false, ...props }) => {
  if (isDisabled) {
    return children;
  }

  return (
    <LinkChakra
      as={RouterLink}
      textColor="greenBrand.light"
      textDecoration="underline"
      textDecorationColor="greenBrand.borderDefault"
      fontWeight={600}
      {...props}
    >
      {children}
    </LinkChakra>
  );
};
