import { useCallback, type FC } from 'react';
import { Modal } from '@graneet/lib-ui';
import { Box } from '@chakra-ui/react';

interface RequestConflictQuoteModalProps {
  isOpen: boolean;
  onClose(): void;
  title: string;
  description: string;
  action: string;
}

export const RequestConflictQuoteModal: FC<RequestConflictQuoteModalProps> = ({
  isOpen,
  onClose,
  title,
  description,
  action,
}) => {
  const handleOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} title={title} size="2xl">
      <Modal.Close />
      <Box mb={5}>{description}</Box>
      <Modal.PrimaryButton onClick={handleOnClose}>{action}</Modal.PrimaryButton>
    </Modal>
  );
};
