import { Box, CircularProgress, Grid, Text } from '@chakra-ui/react';
import {
  Callout,
  Card,
  formatNumberToVatRate,
  formatPercentage,
  LabeledData,
  Price,
  SimpleAlertIcon,
} from '@graneet/lib-ui';
import { Form, useForm } from 'graneet-form';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  VAT_TYPE,
  isSupplierInvoiceIncomplete,
  FEATURE_FLAGS,
  type SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT,
} from '@graneet/business-logic';

import { useSupplierInvoiceUpdate } from '../../../services/supplier-invoice.api';
import { SupplierInvoiceComponentTypeBadge } from '../../SupplierInvoiceComponentTypeBadge';
import { SupplierInvoicePurchasesAccountLabel } from '../../SupplierInvoicePurchasesAccountLabel';

import type { SupplierInvoiceInvoiceFileForm } from './UpdatableInvoiceFileDetails';
import { UpdatableInvoiceFileDetails } from './UpdatableInvoiceFileDetails';

import { SupplierInvoiceCalloutDuplicate } from 'features/supplier-invoice/components/SupplierInvoiceCalloutDuplicate';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';

export const SupplierInvoiceInformationsCard: FC<{
  supplierInvoice: SupplierInvoiceWithAmountPreviousDirectPaymentsExVAT;
}> = ({ supplierInvoice }) => {
  const hasSupplierInvoiceImportImprovementFeatureFlag = useFeatureFlag(
    FEATURE_FLAGS.ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_IMPORT_IMPROVEMENT,
  );
  const { t } = useTranslation(['global', 'supplierInvoices']);

  const supplierInvoiceUpdateMutation = useSupplierInvoiceUpdate();

  const form = useForm<SupplierInvoiceInvoiceFileForm>();

  const onFileChange = useCallback(
    async (invoiceFile?: File) => {
      if (!invoiceFile) {
        return;
      }

      await supplierInvoiceUpdateMutation.mutateAsync({
        id: supplierInvoice.id,
        dto: { deletedReceiptFilesIds: '[]', deleteInvoiceFile: 'true' },
        invoiceFile,
      });
    },
    [supplierInvoice, supplierInvoiceUpdateMutation],
  );

  const onFileDelete = useCallback(async () => {
    await supplierInvoiceUpdateMutation.mutateAsync({
      id: supplierInvoice.id,
      dto: {
        deletedReceiptFilesIds: '[]',
        deleteInvoiceFile: 'true',
      },
    });
    form.setFormValues({
      invoiceFile: null,
    });
  }, [form, supplierInvoice, supplierInvoiceUpdateMutation]);

  return (
    <Card title={t('supplierInvoices:cards.informationsCard.title')}>
      {isSupplierInvoiceIncomplete(supplierInvoice) && (
        <Box mb={3}>
          <Callout colorScheme="yellow" icon={<SimpleAlertIcon stroke="yellow.500" boxSize={5} />}>
            <Text fontSize="sm">{t('supplierInvoices:callouts.missingInformation')}</Text>
          </Callout>
        </Box>
      )}

      {/* @[ff: ACCOUNTING_BREAKDOWN_SUPPLIER_INVOICE_IMPORT_IMPROVEMENT] */}
      {hasSupplierInvoiceImportImprovementFeatureFlag &&
        supplierInvoice.hasDuplicate &&
        supplierInvoice.invoiceNumber && (
          <Box mb={3}>
            <SupplierInvoiceCalloutDuplicate invoiceNumber={supplierInvoice.invoiceNumber} />
          </Box>
        )}

      <Grid gap={6} templateColumns={{ base: '1fr', xl: 'repeat(4, 1fr)' }}>
        <LabeledData label={t('supplierInvoices:cards.informationsCard.name')} data={supplierInvoice.name} />
        <LabeledData
          label={t('supplierInvoices:cards.informationsCard.invoiceNumber')}
          data={supplierInvoice.invoiceNumber}
        />
        <LabeledData
          label={t('supplierInvoices:fields.invoiceType')}
          data={
            <Box w="7rem">
              <SupplierInvoiceComponentTypeBadge supplierInvoice={supplierInvoice} />
            </Box>
          }
        />
        <LabeledData
          label={t('supplierInvoices:fields.accountingConfigPurchasesAccount')}
          data={<SupplierInvoicePurchasesAccountLabel supplierInvoice={supplierInvoice} />}
        />

        <LabeledData
          label={t('supplierInvoices:cards.informationsCard.amountExVAT')}
          data={<Price amount={supplierInvoice.amountExVAT} />}
        />
        <LabeledData
          label={t('supplierInvoices:cards.informationsCard.vatDistribution')}
          data={
            <>
              {supplierInvoice.vatType === VAT_TYPE.REVERSE_CHARGE && t('global:words.c.reversalOfLiability')}
              {supplierInvoice.vatType === VAT_TYPE.INTRA_COMMUNITY && t('global:words.c.intraCommunity')}
              {supplierInvoice.vatType === VAT_TYPE.NORMAL &&
                supplierInvoice.vatDistribution.length === 1 &&
                formatPercentage(formatNumberToVatRate(supplierInvoice.vatDistribution[0].vatRate))}
              {supplierInvoice.vatType === VAT_TYPE.NORMAL &&
                supplierInvoice.vatDistribution.length > 1 &&
                t('global:words.multiVAT')}
            </>
          }
        />
        <LabeledData
          label={t('supplierInvoices:cards.informationsCard.amountIncVAT')}
          data={<Price amount={supplierInvoice.amountIncVAT} />}
        />
      </Grid>

      <Form form={form}>
        <Box display={supplierInvoiceUpdateMutation.isPending ? 'block' : 'none'}>
          <Box textAlign="center">
            <CircularProgress size={50} color="gray.800" isIndeterminate />
            <Box>{t('supplierInvoices:cards.informationsCard.downloading')}</Box>
          </Box>
        </Box>

        <Box display={supplierInvoiceUpdateMutation.isPending ? 'none' : 'block'}>
          <UpdatableInvoiceFileDetails
            onFileChange={onFileChange}
            onFileDelete={onFileDelete}
            supplierInvoice={supplierInvoice}
          />
        </Box>
      </Form>
    </Card>
  );
};
