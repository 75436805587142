import { useCallback } from 'react';
import type { Quote, QuoteComponentsGroupByType } from '@org/quotation-lib';
import Big from 'big.js';
import { v4 as uuid } from 'uuid';

import { useQuoteSetToStore } from '../../quote-common/hooks/useQuoteSetToStore';

import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import {
  StatusEnum,
  useQuoteSetClientRequestsStore,
} from 'features/quotation/quote-common/hooks/client-requests/useQuoteSetClientRequestsStore';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';

export function useQuoteLotUpdateOverheadCostMarginByComponentType() {
  const { quoteLotsProxyApi } = useQuotationProxyApis();
  const quoteSetToStore = useQuoteSetToStore();
  const quoteSetClientRequestToStore = useQuoteSetClientRequestsStore();
  const quoteError = useQuoteError();

  return useCallback(
    (nodeId: string, data: QuoteComponentsGroupByType, newValue: string): ICommand => ({
      execute(quote: Quote) {
        try {
          const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
          this.oldValue = {
            overhadCostMargin: Big(data.overheadCosts ?? 1),
            data,
          };
          quoteLot.updateComponentOverheadCostMarginByComponentType(data.componentTypeId, Big(newValue));
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();

          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });

          const body = {
            quoteId: quote.getId(),
            timestamp,
            clientRequestId,
            overheadCosts: newValue,
          };

          quoteLotsProxyApi
            .updateByComponentType(quoteLot.getId(), Number(data.componentTypeId), body)
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          const quoteLot = quote.getTree().getQuoteLotOrThrow(nodeId);
          quoteLot.updateComponentOverheadCostMarginByComponentType(
            this.oldValue.data,
            this.oldValue.overhadCostMargin,
          );
          quoteSetToStore(quote);

          const clientRequestId = uuid();
          const timestamp = new Date().toISOString();
          quoteSetClientRequestToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
          quoteLotsProxyApi
            .updateByComponentType(quoteLot.getId(), Number(this.oldValue.data.componentTypeId), {
              quoteId: quote.getId(),
              timestamp,
              clientRequestId,
              overheadCosts: this.oldValue.overhadCostMargin,
            })
            .then(([err]) => {
              if (err) {
                quoteError();
              }
            })
            .catch((err) => quoteError(err.message));

          return true;
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [quoteError, quoteLotsProxyApi, quoteSetClientRequestToStore, quoteSetToStore],
  );
}
