import type { ExtractUndefinedProperties, RequiredByKeys } from './types';

/**
 * Throws an error if the specified key is undefined in the given object.
 *
 * @param {T} value - The object to check for the key.
 * @param {K} key - The key to check for in the object.
 *
 * @throws {Error} - Throws an error with a message indicating that the relation is not defined.
 *
 * @template T - The type of the object.
 * @template K - The type of the key.
 *
 * @returns {void}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throwIfKeyIsUndefined<const T extends Record<string, any>, K extends ExtractUndefinedProperties<T>>(
  value: T,
  key: K,
): asserts value is RequiredByKeys<T, K> {
  if (value[key] === undefined) {
    throw new Error(`Relation ${value} is not defined`);
  }
}
