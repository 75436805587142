export enum ENV {
  PROD = 'prod',
  LOCAL = 'local',
  STAGING = 'staging',
  PREPROD = 'preprod',
  E2E = 'e2e',
  TEST = 'test',
  SQUADA = 'squad-a',
  SQUADB = 'squad-b',
}
