import { useTranslation } from 'react-i18next';
import { Badge, Date, EMPTY_SIGN, formatDataOrEmpty, Progress, Table } from '@graneet/lib-ui';
import type { IStatement } from '@graneet/business-logic';
import { STATEMENT_TYPES } from '@graneet/business-logic';
import { compact } from 'lodash-es';
import type { FC } from 'react';

import { STATEMENT_FIELDS } from '../../constants/statement.constant';
import { getStatementStatusColor, getStatementStatusLabel } from '../../services/statement.util';
import { StatementPrice } from '../StatementPrice';
import { StatementBillIndicator } from '../StatementBillIndicator';

import { TagsBadges } from 'features/common/components/TagsBadges';

const TABLE_COLUMNS = compact([
  'minmax(auto, 11rem)',
  'minmax(14rem, 2fr)',
  '8rem',
  '6rem',
  '8rem',
  '11rem',
  '8rem',
  '3rem',
]);

export interface StatementTableProps {
  statements: IStatement[];

  onRowClick(statement: IStatement): void;

  displayHeader: boolean;

  subProjectName: string | null;
}

export const StatementTable: FC<StatementTableProps> = ({ statements, onRowClick, displayHeader, subProjectName }) => {
  const { t } = useTranslation(['statement', 'invoice', 'downPayment', 'progressStatement', 'credit']);

  return (
    <Table templateColumns={TABLE_COLUMNS} noResultLabel={t('statement:noResult')}>
      {displayHeader && (
        <Table.Information
          countLabel={(count) => t('statement:statement', { count })}
          sums={{
            amountExVAT: {
              label: t('statement:fields.amountExVAT'),
              type: 'currency',
            },
          }}
        />
      )}

      {displayHeader && (
        <Table.Header>
          <Table.Cell sortBy={STATEMENT_FIELDS.INVOICE_NUMBER}>{t('statement:fields.invoiceNumber')}</Table.Cell>
          <Table.Cell sortBy={STATEMENT_FIELDS.NAME}>{t('statement:fields.name')}</Table.Cell>
          <Table.Cell sortBy={STATEMENT_FIELDS.BILLING_DATE} right>
            {t('statement:fields.billingDate')}
          </Table.Cell>
          <Table.Cell sortBy={STATEMENT_FIELDS.TYPE} center>
            {t('statement:fields.type')}
          </Table.Cell>
          <Table.Cell sortBy={STATEMENT_FIELDS.AMOUNT_EX_VAT} right>
            {t('statement:fields.amountExVAT')}
          </Table.Cell>
          <Table.Cell sortBy={STATEMENT_FIELDS.PROGRESS}>{t('progressStatement:fields.cumulativeProgress')}</Table.Cell>
          <Table.Cell sortBy={STATEMENT_FIELDS.STATUS} center>
            {t('statement:fields.status')}
          </Table.Cell>

          <Table.Cell />
        </Table.Header>
      )}

      {subProjectName && (
        <Table.Row bg="gray.200" textColor="gray.900">
          <Table.Cell gridColumn={`span ${TABLE_COLUMNS.length}`}>{subProjectName}</Table.Cell>
        </Table.Row>
      )}

      {statements.map((statement) => {
        const isStatementProgressType = [
          STATEMENT_TYPES.PROGRESS_STATEMENT,
          STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT,
        ].includes(statement.type);

        return (
          <Table.Row key={`${statement.type}-${statement.id}`} onClick={() => onRowClick(statement)}>
            <Table.Cell variant="ellipsis">{formatDataOrEmpty(statement.invoiceNumber)}</Table.Cell>
            <Table.Cell variant="ellipsis">
              {statement.name}
              <TagsBadges tags={statement.tags} />
            </Table.Cell>
            <Table.Cell right>
              <Date>{statement.billingDate}</Date>
            </Table.Cell>
            <Table.Cell center>{t(`statement:types.${statement.type}`)}</Table.Cell>
            <Table.Cell right>
              <StatementPrice statement={statement} isCredit={statement.type === STATEMENT_TYPES.CREDIT} />
            </Table.Cell>
            {isStatementProgressType ? (
              <Table.Cell>
                <Progress value={statement.cumulativeProgressPercentage} />
              </Table.Cell>
            ) : (
              <Table.Cell center>{EMPTY_SIGN}</Table.Cell>
            )}
            <Table.Cell center>
              <Badge colorScheme={getStatementStatusColor(statement)}>{getStatementStatusLabel(t, statement)}</Badge>
            </Table.Cell>

            <Table.Cell>
              <StatementBillIndicator statement={statement} />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table>
  );
};
