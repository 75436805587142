import type { KeysMatching, TextAreaFieldValue } from '@graneet/lib-ui';
import { Card, RichTextField, Section, RICH_TEXT_INLINE_TOOLBAR_PRESET } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { FieldValues } from 'graneet-form';

export interface NoteFieldSectionProps<
  T extends FieldValues,
  K extends KeysMatching<T, TextAreaFieldValue> = KeysMatching<T, TextAreaFieldValue>,
> {
  name: K;
}
export const NoteFieldSection = <
  T extends FieldValues = Record<string, unknown>,
  K extends KeysMatching<T, TextAreaFieldValue> = KeysMatching<T, TextAreaFieldValue>,
>({
  name,
}: NoteFieldSectionProps<T, K>) => {
  const { t } = useTranslation(['global']);

  return (
    <Section title={t('global:noteField.label')} description={t('global:noteField.description')}>
      <Card width="min(1120px, 100%)">
        <RichTextField<T, K>
          name={name}
          navbarType="inline"
          label={t('global:noteField.label')}
          configuration={RICH_TEXT_INLINE_TOOLBAR_PRESET}
        />
      </Card>
    </Section>
  );
};
