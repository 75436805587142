import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface ValuesContextApi<T> {
  hasValue(componentId: T): boolean;
  setValues(newIds: T[]): void;
  resetValues(): void;
  toggleValue(componentId: T, isSelected: boolean): void;
  getValues(): T[];
  numberOfValues(): number;
}

const ValuesContext = createContext<ValuesContextApi<any>>({
  hasValue: () => false,
  setValues: () => {},
  resetValues: () => {},
  toggleValue: () => {},
  getValues: () => [],
  numberOfValues: () => 0,
});

/**
 * A hook to access/toggle/reset ids of selected library
 * components
 */
export const useValuesContext = <T extends unknown>() => useContext<ValuesContextApi<T>>(ValuesContext);

export type ValuesProviderProps = PropsWithChildren<{}>;
/**
 * A convenience provider to store the ids of selected
 * library components and avoid prop drilling, as the
 * component structure is rather deep.
 *
 * @example
 * ```
 * function DeeperComponent() {
 *
 *   const {
 *     hasValue,
 *     setValues,
 *     resetValues,
 *     toggleValue,
 *     getValues,
 *     numberOfValues,
 *   } = useValuesContext();
 *
 *   // ...
 * }
 *
 * <ValuesProvider>
 *   // ...
 *     <DeeperComponent />
 *   // ...
 * </ValuesProvider>
 * ```
 */
export const ValuesProvider = <T extends unknown>({ children }: ValuesProviderProps) => {
  const [ids, setIds] = useState<Set<T>>(new Set());

  // - Accessing

  const hasValue = useCallback((componentId: T) => ids.has(componentId), [ids]);

  // - Resetting

  const resetValues = useCallback(() => {
    setIds(new Set());
  }, []);

  // - Toggling

  const addSelectedComponentId = useCallback(
    (componentId: T) => {
      if (!ids.has(componentId)) {
        setIds((currentSet) => {
          const newIds = [...currentSet, componentId];
          return new Set(newIds);
        });
      }
    },
    [ids],
  );

  const deleteSelectedComponentId = useCallback(
    (componentId: T) => {
      if (ids.has(componentId)) {
        setIds((currentSet) => {
          const filteredIds = [...currentSet].filter((id) => id !== componentId);
          return new Set(filteredIds);
        });
      }
    },
    [ids],
  );

  const toggleValue = useCallback(
    (componentId: T, isSelected: boolean) => {
      if (isSelected) {
        addSelectedComponentId(componentId);
      } else {
        deleteSelectedComponentId(componentId);
      }
    },
    [addSelectedComponentId, deleteSelectedComponentId],
  );

  // - Provider's value

  const value = useMemo(
    () => ({
      getValues: () => [...ids],
      numberOfValues: () => ids.size,
      hasValue,

      setValues: (newIds: T[]) => setIds(new Set(newIds)),
      resetValues,
      toggleValue,
    }),
    [ids, resetValues, hasValue, toggleValue],
  );

  return <ValuesContext.Provider value={value}>{children}</ValuesContext.Provider>;
};
