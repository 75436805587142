import type { QuoteNodeObject } from '@org/quotation-lib';
import type { ColDef, SuppressKeyboardEventParams } from '@ag-grid-community/core';
import { useCallback } from 'react';

import type { QuoteAGGridType } from '../../constants/agGridConstants';
import { LeftActionHeader } from '../../components/columns/LeftActionHeader/LeftActionHeader';
import { LeftActionCell } from '../../components/columns/LeftActionCell/LeftActionCell';

export function useLeftActionColumnDef(type: QuoteAGGridType) {
  return useCallback(
    (): ColDef<QuoteNodeObject> => ({
      colId: 'leftAction',
      width: 75,
      resizable: false,
      suppressAutoSize: true,
      suppressSizeToFit: true,
      headerComponent: LeftActionHeader,
      editable: false,
      sortable: false,
      cellStyle: { textAlign: 'right' },
      cellClass: 'no-focus',
      headerClass: 'no-padding',
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: LeftActionCell,
      cellRendererParams: {
        type,
      },
      headerComponentParams: {
        type,
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      valueGetter: (_params) => '-',
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        if (params.editing) {
          return false;
        }
        const { key } = params.event;
        if (
          [
            'PageUp',
            'PageDown',
            'Tab',
            'ArrowLeft',
            'ArrowUp',
            'ArrowRight',
            'ArrowDown',
            'Enter',
            'Backspace',
          ].includes(key)
        ) {
          return false;
        }
        return true;
      },
    }),
    [type],
  );
}
