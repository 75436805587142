import type { FC } from 'react';
import { GridItem, Stack } from '@chakra-ui/react';
import { TwoColumns } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import {
  getEmailVariableData,
  INVOICE_STATUSES,
  PERMISSION,
  STATEMENT_TYPES,
  HISTORY_ENTITY_TYPE,
} from '@graneet/business-logic';
import type {
  IProject,
  IInvoiceResponseDTO,
  RequiredByKeys,
  IGroupedContact,
  IContactWithProjectInfos,
} from '@graneet/business-logic';
import { isNil } from 'lodash-es';

import { useInvoiceVersions, useLinkInvoiceToProject, useUnlinkInvoiceToProject } from '../../services/invoice.api';

import { InvoiceInformationCard } from './InvoiceInformationCard';
import { InvoiceStatusCard } from './InvoiceStatusCard/InvoiceStatusCard';
import { InvoiceTagsCard } from './InvoiceTagsCard';

import { PaymentMethodDetailCard } from 'features/payment-method/components/PaymentMethodDetailCard';
import { ProjectLinkAndUnlinkCard } from 'features/project/components/cards/ProjectLinkAndUnlinkCard';
import { CreditLinkedStatementCard } from 'features/credit/components/cards/CreditLinkedStatementCard';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { ClientCard } from 'features/client/components/cards/ClientCard';
import { useEmailTemplateRichTextConfiguration } from 'features/email-template/hooks/useEmailTemplateRichTextConfiguration';
import { EmailProvider } from 'features/history/contexts/EmailContext';
import { HistoryCard } from 'features/history/components/HistoryCard';

interface InvoiceDetailProps {
  invoice: IInvoiceResponseDTO;
  onGoBack(): void;
  onCancelConfirmed(isCreateDraftChecked: boolean): void;
  projectId?: number;
  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
  displayAssociatedProject?: boolean;
}

export const InvoiceDetail: FC<InvoiceDetailProps> = ({
  invoice,
  onGoBack,
  onCancelConfirmed,
  projectId,
  projectContacts,
  displayAssociatedProject = false,
}) => {
  const { t } = useTranslation(['statement']);

  const invoiceVersions = useInvoiceVersions(invoice.id);
  const hasCreateStatementsPermission = usePermissions([PERMISSION.CREATE_STATEMENTS]);

  const linkInvoiceToProjectMutation = useLinkInvoiceToProject();
  const unlinkInvoiceToProjectMutation = useUnlinkInvoiceToProject();

  const emailTemplateRichTextConfiguration = useEmailTemplateRichTextConfiguration(
    getEmailVariableData('invoice', {
      invoice,
      project: (invoice.project as RequiredByKeys<IProject, 'address'>) || null,
      client: invoice.client,
    }),
  );

  const handleLink = async ({ id: newProjectId }: IProject) => {
    await linkInvoiceToProjectMutation.mutateAsync({ id: invoice.id, projectId: newProjectId });
  };

  const handleUnlink = async () => {
    await unlinkInvoiceToProjectMutation.mutateAsync({ id: invoice.id });
  };

  const isInvoiceCancelled = invoice.status === INVOICE_STATUSES.CANCELLED;
  const shouldDisplayLinkedCredit = !isNil(invoice.credit) && isInvoiceCancelled;

  return (
    <EmailProvider>
      <TwoColumns>
        <Stack spacing={4}>
          <InvoiceStatusCard
            invoice={invoice}
            currentVersion={invoiceVersions.data.current}
            projectId={projectId}
            projectContacts={projectContacts}
            onCancelConfirmed={onCancelConfirmed}
            onDeleted={onGoBack}
          />
          {shouldDisplayLinkedCredit && (
            <CreditLinkedStatementCard
              statement={invoice.credit!}
              statementType={STATEMENT_TYPES.CREDIT}
              projectId={projectId}
            />
          )}
        </Stack>

        <InvoiceInformationCard invoice={invoice} />

        <GridItem colSpan={2}>
          <HistoryCard
            id={invoice.id}
            entity={HISTORY_ENTITY_TYPE.INVOICE}
            emailTemplateRichTextConfiguration={emailTemplateRichTextConfiguration}
            cardPosition={{ mt: 6 }}
          />
        </GridItem>

        <Stack spacing={4}>
          <ClientCard client={invoice.client} type={null} />
          <InvoiceTagsCard invoice={invoice} />
        </Stack>

        <Stack spacing={4}>
          <PaymentMethodDetailCard
            paymentMethod={invoice.paymentMethod}
            paymentTerm={invoice.paymentTerm}
            deadlineType={invoice.deadlineType}
          />
          {displayAssociatedProject && (
            <ProjectLinkAndUnlinkCard
              project={invoice.project as RequiredByKeys<IProject, 'address'>}
              onLink={handleLink}
              onUnlink={handleUnlink}
              translations={{
                linkLabel: t('statement:actions.associateProject'),
                unlinkLabel: t('statement:actions.deleteProjectAssociation'),
                modalTitle: t('statement:associateProject.title'),
                modalDescription: t('statement:associateProject.description'),
              }}
              canUpdateLink={hasCreateStatementsPermission}
            />
          )}
        </Stack>
      </TwoColumns>
    </EmailProvider>
  );
};
