import { Badge, type DocumentStatusCardAction } from '@graneet/lib-ui';
import { t } from 'i18next';
import { SUPPLIER_INVOICE_STATUS } from '@graneet/business-logic';

import type { SupplierInvoiceActionsType } from './supplier-invoice.actions.type';
import {
  getSupplierInvoiceAddPaymentActions,
  getSupplierInvoiceMenuAction,
  getSupplierInvoiceUpdatePaymentAction,
} from './supplier-invoice.actions.util';

export function getSupplierInvoiceToPayActions(params: SupplierInvoiceActionsType): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  if (params.supplierInvoice.status === SUPPLIER_INVOICE_STATUS.TO_PAY) {
    actions.push(...getSupplierInvoiceAddPaymentActions(params));
  }

  actions.push(getSupplierInvoiceUpdatePaymentAction(params));

  const isToProcessDisabled = !params.permissions.hasUpdateStatusPermission;
  actions.push({
    type: 'secondaryDropdown',
    dropDownLabel: t('global:words.markAs'),
    options: [
      {
        label: <Badge colorScheme="gray">{t(`supplierInvoices:statuses.${SUPPLIER_INVOICE_STATUS.TO_PROCESS}`)}</Badge>,
        onClick: params.callbacks.onUpdateToProcess,
        isDisabled: isToProcessDisabled,
        tooltip: isToProcessDisabled ? t('global:errors.disabledAction') : undefined,
      },
    ],
  });

  actions.push(getSupplierInvoiceMenuAction(params));

  return actions;
}
