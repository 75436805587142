import { GraneetButton } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HStack,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { ContentTypeGuard } from '@org/quotation-lib';
import { BATIPRIX_SESSION_TYPE, THIRD_PARTY, TRACKING_EVENT_ENUM } from '@graneet/business-logic';

import { useQuoteBatiprixImportItem } from '../hooks/useQuoteBatiprixImportItem';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useThirdParty } from 'features/third-party/hooks/useThirdParty';
import { updateQuoteBatiprixModalIsOpen } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useStore } from 'store/store';
import { getBatiprixIframe, useBatiprixToken } from 'features/batiprix/services/batiprix.api';
import { useBatiprixApi } from 'features/quotation/services/batiprix.api';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useSegmentAnalytics } from 'features/analytic/components/SegmentProvider';

export const QuoteBatiprixModalInternal: FC<{
  onDataLoaded: (data: { sessionId: string; token: string }) => void;
  quoteId: number;
}> = ({ onDataLoaded, quoteId }) => {
  const batiprixToken = useBatiprixToken({ batiprixSessionType: BATIPRIX_SESSION_TYPE.QUOTE, typeId: quoteId });
  onDataLoaded(batiprixToken.data);

  const url = getBatiprixIframe(batiprixToken.data.sessionId, batiprixToken.data.token);

  return <iframe title="batiprix" height="100%" width="100%" src={url} />;
};

export const QuoteBatiprixModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['global', 'quote', 'library']);

  const { quote } = useQuote();
  const segmentAnalytics = useSegmentAnalytics();
  const { useGetItemsFromSession } = useBatiprixApi();
  const getItemsFromSession = useGetItemsFromSession();
  const quoteBatiprixImportItem = useQuoteBatiprixImportItem();

  const isOpen = useStore((state) => state.quoteBatiprixModalIsOpen);
  const parentId = useStore((state) => state.quoteBatiprixModalParentId);

  const [tokens, setTokens] = useState<{ sessionId: string; token: string } | undefined>(undefined);
  const quoteIdRef = useRef(Math.floor(Math.random() * 100_000_000) + 1);

  const handleOnClose = useCallback(() => {
    updateQuoteBatiprixModalIsOpen(false);
  }, []);

  const onSubmit = useCallback(
    async (batiprixInfos: { sessionId: string; token: string }) => {
      const { batiprixJobs: batiprixItems, batiprixComponentTypes } = await getItemsFromSession.mutateAsync({
        sessionId: batiprixInfos.sessionId,
        token: batiprixInfos.token,
      });
      segmentAnalytics?.sendEvent(TRACKING_EVENT_ENUM.QUOTE_JOB_IMPORT, { type: 'batiprix' });

      const tree = quote.getTree();
      const hiddenCostRootNodeId = tree.getHiddenCostNodeId();

      const targetNode = parentId ? tree.getNodeOrThrow(parentId) : tree.getRootNode();

      const targetContent = targetNode.getContent();

      // Case Parent is an Item ( Basic Item, Optional Item, Hidden Cost Item) we create a sub item
      if (ContentTypeGuard.isQuoteItem(targetContent)) {
        quoteBatiprixImportItem(batiprixItems, batiprixComponentTypes, parentId, 'item');
        handleOnClose();
        return;
      }

      if (!ContentTypeGuard.isQuoteLot(targetContent)) {
        throw new Error('Content has to be either an item or a lot');
      }

      if (parentId === hiddenCostRootNodeId) {
        quoteBatiprixImportItem(batiprixItems, batiprixComponentTypes, parentId, 'hiddenCostLot');
        handleOnClose();
        return;
      }

      if (targetContent.getIsEveryChildOptional()) {
        quoteBatiprixImportItem(batiprixItems, batiprixComponentTypes, parentId, 'optionalLot');
        handleOnClose();
        return;
      }

      quoteBatiprixImportItem(batiprixItems, batiprixComponentTypes, parentId, 'lot');
      handleOnClose();
    },
    [getItemsFromSession, handleOnClose, parentId, quote, quoteBatiprixImportItem, segmentAnalytics],
  );

  const handleOnSubmit = useCallback(async () => {
    if (!tokens) {
      return;
    }
    setIsLoading(true);
    onSubmit({ sessionId: tokens.sessionId, token: tokens.token });
    setIsLoading(false);
    handleOnClose();
  }, [tokens, onSubmit, handleOnClose]);

  const hasBatiprix = useThirdParty(THIRD_PARTY.BATIPRIX);
  if (!hasBatiprix) {
    return null;
  }

  return (
    <Drawer isOpen={isOpen} onClose={handleOnClose} size="2xl" placement="right">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton top={4} />
        <DrawerHeader borderBottom="1px solid #E5E7EB">{t('global:batiprixDrawer.title')}</DrawerHeader>
        <DrawerBody px={0}>
          <QueryWrapper>
            <HStack height="80vh" gap={0} alignItems="start">
              <QuoteBatiprixModalInternal onDataLoaded={setTokens} quoteId={quoteIdRef.current} />
            </HStack>
          </QueryWrapper>
        </DrawerBody>

        <DrawerFooter>
          <GraneetButton variant="primary" isLoading={isLoading} onClick={handleOnSubmit}>
            {t('quote:quotation.library.addButton')}
          </GraneetButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
