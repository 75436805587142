import { FEATURE_FLAGS } from '@graneet/business-logic';
import type { Shortcut } from '@graneet/lib-ui';
import { useHotkeys } from '@graneet/lib-ui';

import { umamiTrackEvent } from 'features/app/hooks/umami/umamiTrackEvent';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { useQuoteBasicItemCreate } from 'features/quotation/quote-basic-item/hooks/useQuoteBasicItemCreate';
import { quoteUndoRedoGetFromStore } from 'features/quotation/quote-common/store/quoteUndoRedoGetFromStore';
import { useQuoteLotCreate } from 'features/quotation/quote-lot/hooks/useQuoteLotCreate';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';

export const QUOTATION_SHORTCUTS: Record<
  string,
  {
    shortCut: Shortcut;
    name: string;
  }
> = {
  //
  QUOTE_BASIC_ITEM: {
    shortCut: 'alt+o',
    name: 'createQuoteBasicItem',
  },
  //
  QUOTE_LOT: {
    shortCut: 'alt+l',
    name: 'createQuoteLot',
  },
  //
  COLLAPSE_ALL: {
    shortCut: 'alt+c',
    name: 'collapseAll',
  },
  //
  COLLAPSE_ALL_COMPONENTS: {
    shortCut: 'alt+shift+c',
    name: 'collapseAllComponents',
  },
  //
  EXPAND_ALL: {
    shortCut: 'alt+e',
    name: 'expandAll',
  },
  //
  EXPAND_ALL_COMPONENTS: {
    shortCut: 'alt+shift+e',
    name: 'expandAllComponents',
  },
  //
  EXPORT: {
    shortCut: 'mod+e',
    name: 'export',
  },
  SUPPLY_SHEET: {
    shortCut: 'mod+shift+a',
    name: 'supplySheet',
  },
  //
  PDF_PREVIEW: {
    shortCut: 'mod+shift+e',
    name: 'previewPDF',
  },
  //
  TOGGLE_AUTO_NUMBERING: {
    shortCut: 'mod+i',
    name: 'toggleAutonumbering',
  },
  //
  HELP: {
    shortCut: 'mod+h',
    name: 'help',
  },
  //
  BATIPRIX_IMPORT: {
    shortCut: 'alt+shift+b',
    name: 'batiprixImport',
  },
  //
  LIBRARY_IMPORT: {
    shortCut: 'alt+shift+i',
    name: 'libraryImport',
  },
  //
  QUIT: {
    shortCut: 'alt+esc',
    name: 'quit',
  },
  //
  ADD_NOTE: {
    shortCut: 'alt+n',
    name: 'addNote',
  },
  //
  ADD_IMAGE: {
    shortCut: 'alt+i',
    name: 'addImages',
  },
  //
  REMOVE_LINE: {
    shortCut: 'mod+d',
    name: 'removeLine',
  },
  TOGGLE_DRAWER: {
    shortCut: 'alt+d',
    name: 'toggleDrawer',
  },
  DRAWER_SELLSHEET: {
    shortCut: 'alt+1',
    name: 'drawerSellsheet',
  },
  DRAWER_CLIENT: {
    shortCut: 'alt+2',
    name: 'drawerClient',
  },
  DRAWER_PROJECT: {
    shortCut: 'alt+4',
    name: 'drawerProject',
  },
  DRAWER_QUOTE: {
    shortCut: 'alt+3',
    name: 'drawerQuote',
  },
  DRAWER_HELP: {
    shortCut: 'alt+5',
    name: 'drawerHelp',
  },
  //
  HIDDEN_COST: {
    shortCut: 'alt+shift+f',
    name: 'addHiddenCost',
  },
  //
  OPTION: {
    shortCut: 'alt+shift+o',
    name: 'addOption',
  },
  UNDO: {
    shortCut: 'mod+z',
    name: 'undo',
  },
  REDO: {
    shortCut: 'mod+shift+z',
    name: 'redo',
  },
};

export const useQuoteHotKeys = () => {
  const hasQuotationUndoRedo = useFeatureFlag(FEATURE_FLAGS.QUOTATION_UNDO_REDO);

  const { executeCommand, undoCommand, redoCommand } = useCommands();
  const { quote } = useQuote();
  const quoteBasicItemCreate = useQuoteBasicItemCreate();
  const quoteLotCreate = useQuoteLotCreate();

  useHotkeys(
    [QUOTATION_SHORTCUTS.QUOTE_BASIC_ITEM.shortCut],
    () => {
      executeCommand(quoteBasicItemCreate(null), quote);
      umamiTrackEvent({
        feature: 'quotation',
        fromZone: 'hotKeys',
        action: 'create',
        entity: 'basicItem',
      });
    },
    [quoteBasicItemCreate],
  );

  useHotkeys(
    [QUOTATION_SHORTCUTS.QUOTE_LOT.shortCut],
    () => {
      executeCommand(quoteLotCreate(null), quote);
    },
    [quoteLotCreate],
  );

  useHotkeys([QUOTATION_SHORTCUTS.UNDO.shortCut], () => {
    const canUndoRedo = quoteUndoRedoGetFromStore();
    if (hasQuotationUndoRedo && canUndoRedo) {
      undoCommand(quote, 'keyboard');
    }
  });

  useHotkeys([QUOTATION_SHORTCUTS.REDO.shortCut], () => {
    const canUndoRedo = quoteUndoRedoGetFromStore();
    if (hasQuotationUndoRedo && canUndoRedo) {
      redoCommand(quote, 'keyboard');
    }
  });
};
