import type { IPdf } from '@graneet/business-logic';
import type { Action } from '@graneet/lib-ui';
import { SimpleDownloadIcon, SimpleEmailIcon, SimpleViewIcon } from '@graneet/lib-ui';
import { t } from 'i18next';

import type { CreditActionsParams } from './credit.actions.type';

import { getPDFActionProps } from 'features/common/services/common.actions.util';

export function getPreviewPDFAction({ onViewPdf }: CreditActionsParams, pdf: IPdf): Action {
  return {
    label: t('global:pdf.viewPDF'),
    icon: <SimpleViewIcon />,
    ...getPDFActionProps(pdf),
    onClick: onViewPdf,
  };
}

export function getDownloadPDFAction({ onDownloadPdf }: CreditActionsParams, pdf: IPdf): Action {
  return {
    label: t('global:pdf.downloadPDF'),
    icon: <SimpleDownloadIcon />,
    ...getPDFActionProps(pdf),
    onClick: onDownloadPdf,
  };
}

export function getOpenMailingAction({ onOpenMailing }: CreditActionsParams, pdf: IPdf): Action {
  return {
    label: t('mailing:modal.send'),
    icon: <SimpleEmailIcon />,
    ...getPDFActionProps(pdf),
    onClick: onOpenMailing,
  };
}
