import type { DrawerApi } from '@graneet/lib-ui';
import { DrawersStack } from '@graneet/lib-ui';
import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';
import type { AgGridReact } from '@ag-grid-community/react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, VStack } from '@chakra-ui/react';

import { ComponentsSupplyDrawerActions } from './ComponentsSupplyDrawerActions';

import { AgGridQuoteComponentsSupply } from 'features/quotation/ag-grid-quote-components-supply/components/AgGridQuoteComponentsSupply';
import { useQuoteGetFromStoreOrThrow } from 'features/quotation/quote-common/hooks/useQuoteGetFromStoreOrThrow';

interface ComponentsSupplyDrawerProps {
  drawer: DrawerApi<string>;
}

export const ComponentsSupplyDrawer = ({ drawer }: ComponentsSupplyDrawerProps) => {
  const { t } = useTranslation(['quote']);
  const getQuote = useQuoteGetFromStoreOrThrow();
  const quote = useMemo(() => getQuote(), [getQuote]);

  const gridRef = useRef<AgGridReact<QuoteComponentsSupplyObject>>(null);

  const [rowsSelected, setRowsSelected] = useState<unknown[]>([]);
  const onRowSelected = useCallback((newRowsSelected: unknown[]) => {
    setRowsSelected(newRowsSelected);
  }, []);

  return (
    <DrawersStack.Drawer
      drawer={drawer}
      title={t('quote:quotation.modals.supply.title')}
      footer={
        <ComponentsSupplyDrawerActions gridRef={gridRef} quoteName={quote.getName()} rowsSelected={rowsSelected} />
      }
      size="2xl"
    >
      <VStack align="stretch" w="100%" h="100%" gap={4}>
        <Text>{t('quote:quotation.modals.supply.description')}</Text>
        <AgGridQuoteComponentsSupply quote={quote} gridRef={gridRef} onRowSelected={onRowSelected} />
      </VStack>
    </DrawersStack.Drawer>
  );
};
