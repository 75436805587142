import type { Props, GroupBase, InputActionMeta, ActionMeta, OnChangeValue } from 'chakra-react-select';
import { CreatableSelect, Select } from 'chakra-react-select';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';

import { FormGroup } from '../FormGroup/FormGroup';
import { getInputStyle } from '../Input/DefaultInput/DefaultInput';

import { configureDefaultLabels, singleSelectTranslations } from './configureDefaultLabels';

const SIZE = 'sm';

export interface SingleSelectProps<Option = unknown, Group extends GroupBase<Option> = GroupBase<Option>>
  extends Pick<
    Props<Option, false, Group>,
    | 'placeholder'
    | 'onMenuOpen'
    | 'onMenuClose'
    | 'onChange'
    | 'options'
    | 'value'
    | 'isClearable'
    | 'onMenuScrollToBottom'
    | 'menuPlacement'
    | 'selectedOptionColorScheme'
  > {
  label?: string;

  errorMessage?: string;

  isDisabled?: boolean;

  isInvalid?: boolean;

  placeholder?: ReactNode;

  isRequired?: boolean;

  isSearchable?: boolean;

  isCreatable?: boolean;

  isLoading?: boolean;

  onSearchUpdate?: (search: string) => void | Promise<void>;

  defaultInputValue?: string;

  onChange?: (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => void | Promise<void>;

  noBorder?: boolean;

  displayMode?: 'default' | 'compact';

  onClear?: () => void;
}

const SingleSelectComponent = <Option = unknown, Group extends GroupBase<Option> = GroupBase<Option>>({
  label,
  placeholder,
  options,
  onMenuClose,
  onMenuOpen,
  onChange,
  isDisabled = false,
  errorMessage,
  isInvalid,
  isRequired,
  value,
  isSearchable,
  isClearable = true,
  isCreatable = false,
  isLoading: isLoadingProp = false,
  onSearchUpdate,
  defaultInputValue,
  onMenuScrollToBottom,
  menuPlacement,
  noBorder = false,
  displayMode = 'default',
  onClear,
  selectedOptionColorScheme = 'greenBrand',
}: SingleSelectProps<Option, Group>) => {
  const [isPristine, setIsPristine] = useState(true);

  const handleOnMenuClose = useCallback(() => {
    onMenuClose?.();
    setIsPristine(false);
  }, [onMenuClose]);

  const [isLoading, setIsLoading] = useState(false);
  const [currentInputValue, setCurrentInputValue] = useState(defaultInputValue);
  const onInputChange = useCallback(
    async (search: string, actionMeta: InputActionMeta) => {
      setCurrentInputValue(search);

      if (onSearchUpdate && actionMeta.action === 'input-change') {
        setIsLoading(true);
        await onSearchUpdate(search);
        setIsLoading(false);
      }
    },
    [onSearchUpdate],
  );

  const inputStyle = getInputStyle({
    size: 'md',
    isReadOnly: isDisabled,
    noBorder,
  });

  const SelectComp = isCreatable ? CreatableSelect : Select;

  return (
    <FormGroup
      label={label}
      isDisabled={isDisabled}
      bg={isDisabled ? 'gray.100' : 'inherit'}
      rounded="md"
      errorMessage={errorMessage}
      isInvalid={!isPristine && isInvalid}
      isRequired={isRequired}
    >
      <SelectComp<Option, false, Group>
        isClearable={isClearable}
        options={options}
        isLoading={isLoading || isLoadingProp}
        placeholder={placeholder ?? ''}
        closeMenuOnSelect
        menuPortalTarget={document.body}
        blurInputOnSelect
        onInputChange={onInputChange}
        inputValue={currentInputValue}
        onMenuScrollToBottom={onMenuScrollToBottom}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        chakraStyles={{
          control: (base) => ({
            ...base,
            ...inputStyle,
            background: 'inherit',
            minH: inputStyle.height,
          }),
          option: (base, state) => ({
            ...base,
            bg: state.isFocused ? 'greenBrand.backgroundNeutralSubtle' : base.bg,
          }),
          valueContainer: (base) => ({
            ...base,
            ...(displayMode === 'compact' && { paddingInlineStart: 1, paddingInlineEnd: 1 }),
          }),
          inputContainer: (base) => ({
            ...base,
            justifyContent: 'center',
            display: 'flex',
            maxHeight: inputStyle.height,
            ...(displayMode === 'compact' && { marginInlineStart: 1, marginInlineEnd: 1 }),
          }),
          menu: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          dropdownIndicator: (base) => ({
            ...base,
            ...(displayMode === 'compact' && { paddingInlineStart: 1, paddingInlineEnd: 1 }),
          }),
        }}
        filterOption={(option, inputValue) => {
          const searchTerm =
            typeof option.data === 'object' && option.data && 'searchTerm' in option.data ? option.data.searchTerm : '';
          const searchString = `${option.value}${option.label}${searchTerm}`;
          return searchString.toLowerCase().includes(inputValue.toLowerCase());
        }}
        selectedOptionColorScheme={selectedOptionColorScheme}
        size={SIZE}
        onMenuClose={handleOnMenuClose}
        onMenuOpen={onMenuOpen}
        onChange={(selectedOption, triggeredAction) => {
          // https://github.com/JedWatson/react-select/issues/1309#issuecomment-561546579
          if (triggeredAction.action === 'clear') {
            onClear?.();
          }
          onChange?.(selectedOption, triggeredAction);
        }}
        noOptionsMessage={() => singleSelectTranslations.noResult}
        formatCreateLabel={singleSelectTranslations.create}
        loadingMessage={() => singleSelectTranslations.loading}
        value={value}
        isSearchable={isSearchable}
        menuPlacement={menuPlacement}
      />
    </FormGroup>
  );
};

export const SingleSelect = Object.assign(SingleSelectComponent, {
  configureDefaultLabels,
});
