import { CUSTOM_DISCOUNT_TYPES } from '@graneet/business-logic';
import type { DrawersStackContextApi } from '@graneet/lib-ui';
import {
  ActionMenu,
  DrawersStack,
  SimpleAddIcon,
  SimpleCustomDiscountIcon,
  SimpleDiscountIcon,
  SimpleProrataIcon,
  useDrawer,
  useDrawersStack,
} from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomDiscountEditionForm } from 'features/custom-discount/forms/custom-discount-edition-form';
import { QuoteDiscountEditDrawer } from 'features/quote-discount/components/modals/QuoteDiscountEditDrawer';
import { QuoteCustomDiscountEditDrawer } from 'features/quote-discount/components/modals/QuoteCustomDiscountEditDrawer';
import { useQuote } from 'features/quote/hooks/useQuote';

interface QuoteAddDiscountButtonsProps {
  drawersStack?: DrawersStackContextApi<string>;
}

export const QuoteAddDiscountButtons: FC<QuoteAddDiscountButtonsProps> = ({ drawersStack }) => {
  const { t } = useTranslation(['customDiscount', 'quote']);
  const getDrawersStack = useDrawersStack();
  const drawersStackContext = drawersStack ?? getDrawersStack;
  const discountDrawer = useDrawer('drawer-discount', drawersStackContext);
  const customDiscountDrawer = useDrawer('drawer-custom-discount', drawersStackContext);

  const [initialCustomDiscountValue, setInitialCustomDiscountValue] =
    useState<Partial<CustomDiscountEditionForm> | null>(null);

  const quote = useQuote();
  const discount = quote?.discount;

  const onCustomDiscountDrawerOpen = useCallback(
    ({ hasInitialValues }: { hasInitialValues: boolean }) => {
      if (hasInitialValues) {
        setInitialCustomDiscountValue({
          customDiscountName: t('customDiscount:defaultValues.name'),
          customDiscountVatRate: 20,
          customDiscountType: CUSTOM_DISCOUNT_TYPES.PERCENTAGE,
        });
      } else {
        setInitialCustomDiscountValue({ customDiscountType: CUSTOM_DISCOUNT_TYPES.PERCENTAGE });
      }

      customDiscountDrawer.onOpen();
    },
    [customDiscountDrawer, t],
  );

  return (
    <>
      <ActionMenu
        offset={[10, 0]}
        buttonProps={{
          variant: 'secondary',
          leftIcon: <SimpleAddIcon />,
          children: t('quote:discounts.addDiscountOrCustomValue'),
        }}
      >
        <ActionMenu.Action
          label={t('quote:discounts.actions.addDiscount')}
          icon={<SimpleDiscountIcon />}
          onClick={discountDrawer.onOpen}
          tooltip={!!discount && t('quote:discounts.addOnlyOneDiscountTooltip')}
          isDisabled={!!discount}
        />
        <ActionMenu.Action
          label={t('quote:discounts.actions.addProrata')}
          onClick={() => onCustomDiscountDrawerOpen({ hasInitialValues: true })}
          icon={<SimpleProrataIcon />}
        />
        <ActionMenu.Action
          label={t('quote:discounts.actions.addCustomDiscount')}
          onClick={() => onCustomDiscountDrawerOpen({ hasInitialValues: false })}
          icon={<SimpleCustomDiscountIcon />}
        />
      </ActionMenu>

      <DrawersStack drawersStack={drawersStackContext}>
        <QuoteDiscountEditDrawer drawer={discountDrawer} />
      </DrawersStack>

      <DrawersStack drawersStack={drawersStackContext}>
        <QuoteCustomDiscountEditDrawer
          drawer={customDiscountDrawer}
          initialCustomDiscountValue={initialCustomDiscountValue}
        />
      </DrawersStack>
    </>
  );
};
