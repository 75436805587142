import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { StepperModal, useToast } from '@graneet/lib-ui';
import { useForm, useFormStatus, useWizardContext } from 'graneet-form';
import { useTranslation } from 'react-i18next';

import {
  useContactCreate,
  useContactRoles,
  useContactsAssociateToSupplier,
} from 'features/contact/services/contact.api';
import type { ContactForm } from 'features/contact/components/forms/ContactUpsertForm';
import { ContactUpsertForm } from 'features/contact/components/forms/ContactUpsertForm';

interface ContactCreateStepProps {
  onClose: () => void;
  supplierId?: number;
}

export const ContactCreateStep: FC<ContactCreateStepProps> = ({ onClose, supplierId }) => {
  const form = useForm<ContactForm>();
  const { isValid: isFormValid } = useFormStatus(form);
  const { t } = useTranslation(['contacts', 'global']);
  const toast = useToast();
  const { handleOnNext } = useWizardContext();

  const contactRoles = useContactRoles();

  const contactCreateMutation = useContactCreate();
  const contactsAssociateToSupplierMutation = useContactsAssociateToSupplier();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (!supplierId) {
      throw new Error('missing supplierId');
    }
    const { firstName, lastName, role, email, phoneNumber } = form.getFormValues();
    setIsLoading(true);
    const body = {
      firstName: firstName!,
      lastName: lastName!,
      role: role ?? '',
      email: email!,
      phoneNumber: phoneNumber!,
    };
    const contact = await contactCreateMutation.mutateAsync(body);
    await contactsAssociateToSupplierMutation.mutateAsync({
      id: supplierId,
      dto: {
        contacts: [{ id: contact.id }],
      },
    });

    toast.success(
      t(contact ? 'contacts:editionModal.toastSuccess' : 'contacts:creationModal.toastSuccess', {
        firstName: contact.firstName,
        lastName: contact.lastName,
      }),
    );
    setIsLoading(false);
    handleOnNext();
  }, [form, contactCreateMutation, supplierId, toast, t, handleOnNext, contactsAssociateToSupplierMutation]);

  return (
    <>
      <ContactUpsertForm roles={contactRoles.data} form={form} hasIsDefaultEmailRecipient={false} />
      <StepperModal.SecondaryButton onClick={onClose}>
        {t('contacts:modal.client.buttons.cancel')}
      </StepperModal.SecondaryButton>
      <StepperModal.PrimaryButton isDisabled={!isFormValid} onClick={handleSubmit} isLoading={isLoading}>
        {t('contacts:modal.client.buttons.next')}
      </StepperModal.PrimaryButton>
    </>
  );
};
