import { Grid, GridItem, Box, Spacer, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Card, SwitchField, TextField } from '@graneet/lib-ui';

import type { UserMailsSettingsFormValues } from '../constants/user.constant';

import { Rule } from 'features/form/rules/Rule';

export const UserMailsSettingsFields = () => {
  const { t } = useTranslation(['mailing']);

  return (
    <Box mb={6}>
      <Card title={t('mailing:settings.configuration.title')} maxW="40rem">
        <Text mb={4}>{t('mailing:settings.configuration.description')}</Text>
        <Grid gap={6}>
          <GridItem colSpan={2}>
            <TextField<UserMailsSettingsFormValues>
              name="mailingDefaultSenderFullName"
              label={t('mailing:settings.configuration.fields.name')}
              isRequired
            >
              <Rule.IsRequired />
            </TextField>
          </GridItem>
          <GridItem colSpan={2}>
            <TextField<UserMailsSettingsFormValues>
              name="mailingSenderReplyTo"
              label={t('mailing:settings.configuration.fields.mail')}
              isRequired
            >
              <Rule.IsRequired />
              <Rule.IsEmail />
            </TextField>
          </GridItem>
          <GridItem colSpan={4}>
            <HStack>
              <Box>
                <Text width="full">{t('mailing:settings.configuration.fields.receiveCopyDescription')}</Text>
              </Box>
              <Spacer />
              <Box>
                <SwitchField<UserMailsSettingsFormValues> name="mailingReceiveCopy" checkedValue uncheckedValue={false}>
                  <Rule.IsRequired />
                </SwitchField>
              </Box>
            </HStack>
          </GridItem>
        </Grid>
      </Card>
    </Box>
  );
};
