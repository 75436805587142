import { useCallback } from 'react';

import * as lotsApi from '../services/quote-lot.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useStore } from 'store/store';

// -- Moving lots

export const useMoveLotBeforeOtherLot = () => {
  const { showJobMoveToast, moveLotBeforeOtherLotLocally, updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (lotId: number, otherLotId: number) => {
      let result;

      try {
        result = moveLotBeforeOtherLotLocally(lotId, otherLotId);
      } catch (err) {
        showJobMoveToast({ isError: true });
        return;
      }

      const { parentLotId, previousLotId } = result;
      const isParentOptional = useStore.getState().optionalLotsTable[otherLotId?.toString()] || false;
      const [err, quoteInfos] = await lotsApi.moveLot(lotId, { parentLotId, previousLotId, isParentOptional });

      if (err) {
        showJobMoveToast({ isError: true });
        return;
      }

      updateDataLocally(quoteInfos);
      showJobMoveToast({ isError: false });
    },
    [moveLotBeforeOtherLotLocally, showJobMoveToast, updateDataLocally],
  );
};
