import type { FC } from 'react';
import type { ICredit, IProgressStatement } from '@graneet/business-logic';
import { isNumberFinite } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Badge, Date, EMPTY_SIGN, Progress, Table } from '@graneet/lib-ui';

import { CREDIT_STATUS_COLORS } from 'features/credit/constants/credit.constant';
import { StatementPrice } from 'features/statement/components/StatementPrice';
import { PROGRESS_STATEMENT_STATUS_COLORS } from 'features/progress-statement/services/progress-statement.constant';

type StatementSummarizeRowProps =
  | { statement: IProgressStatement; type: 'progress-statement' }
  | { statement: ICredit; type: 'credit' };

export const FinalStatementStatementTableRow: FC<StatementSummarizeRowProps> = ({ statement, type }) => {
  const { t } = useTranslation(['credit', 'progressStatement']);

  const isCredit = type === 'credit';

  return (
    <Table.Row key={statement.id}>
      <Table.Cell variant="ellipsis">{statement.invoiceNumber}</Table.Cell>

      <Table.Cell variant="ellipsis">{statement.name}</Table.Cell>

      <Table.Cell right>
        <StatementPrice statement={{ amountExVAT: statement.amountExVAT || 0 }} isCredit={isCredit} />
      </Table.Cell>

      <Table.Cell right>
        {/* eslint-disable-next-line no-nested-ternary */}
        {'billingDate' in statement ? (
          <Date>{statement.billingDate}</Date>
        ) : 'creditDate' in statement ? (
          <Date>{statement.creditDate}</Date>
        ) : undefined}
      </Table.Cell>

      <Table.Cell>
        {'cumulativeProgressPercentage' in statement && isNumberFinite(statement.cumulativeProgressPercentage) ? (
          <Progress value={statement.cumulativeProgressPercentage} />
        ) : (
          EMPTY_SIGN
        )}
      </Table.Cell>

      <Table.Cell center>
        <Badge
          colorScheme={
            type === 'credit'
              ? CREDIT_STATUS_COLORS[statement.status]
              : PROGRESS_STATEMENT_STATUS_COLORS[statement.status]
          }
        >
          {t(
            type === 'credit'
              ? `credit:statuses.${statement.status}`
              : `progressStatement:statuses.${statement.status}`,
          )}
        </Badge>
      </Table.Cell>
    </Table.Row>
  );
};
