import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const PencilIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 11 11" {...props}>
    <path
      fill="currentColor"
      d="M10.8294 2.8578C11.0569 2.63033 11.0569 2.25122 10.8294 2.03541L9.46459 0.670601C9.24879 0.443133 8.86967 0.443133 8.6422 0.670601L7.56902 1.73795L9.75622 3.92515L10.8294 2.8578ZM0.5 8.8128V11H2.6872L9.13797 4.5434L6.95077 2.3562L0.5 8.8128Z"
    />
  </Icon>
);
