import { t } from 'i18next';
import { SimpleDeleteIcon, type DocumentStatusCardAction, type Action } from '@graneet/lib-ui';
import { canSupplierInvoiceBeDeleted, isSupplierInvoiceIncomplete } from '@graneet/business-logic';

import type { SupplierInvoiceActionsType } from './supplier-invoice.actions.type';

export function getSupplierInvoiceUpdatePaymentAction(params: SupplierInvoiceActionsType): DocumentStatusCardAction {
  return {
    type: 'secondary',
    label: t('global:words.c.update'),
    onClick: params.callbacks.onUpdate,
    isDisabled: !params.permissions.canUpdate,
    tooltip: params.tooltips.onUpdate,
  };
}

export function getSupplierInvoiceAddPaymentActions(params: SupplierInvoiceActionsType): DocumentStatusCardAction[] {
  const addPaymentState = (() => {
    if (!params.permissions.hasPayPermissions) {
      return {
        isDisabled: true,
        tooltip: t('global:errors.disabledAction'),
      };
    }
    if (isSupplierInvoiceIncomplete(params.supplierInvoice)) {
      return {
        isDisabled: true,
        tooltip: t('supplierInvoices:tooltips.cannotChangeToToPayBecauseMissingInformation'),
      };
    }

    return {
      isDisabled: false,
      tooltip: undefined,
    };
  })();

  return [
    ...(params.hasBankingConnectionFF !== false
      ? [
          {
            type: 'primary' as const,
            label: t('supplierInvoices:actions.pay'),
            onClick: params.callbacks.onPay,
            isDisabled: addPaymentState.isDisabled,
            tooltip: addPaymentState.tooltip,
          },
        ]
      : []),
    {
      type: 'secondary',
      label: t('supplierInvoices:actions.addPayment'),
      onClick: params.callbacks.onAddPayment,
      isDisabled: addPaymentState.isDisabled,
      tooltip: addPaymentState.tooltip,
    },
  ];
}

export function getSupplierInvoiceMenuAction(params: SupplierInvoiceActionsType): DocumentStatusCardAction {
  const tooltip = (() => {
    const state = canSupplierInvoiceBeDeleted(params.supplierInvoice);

    if (!params.permissions.hasDeletePermission) {
      return {
        isDisabled: true,
        tooltip: t('global:errors.disabledAction'),
      };
    }

    if (state.causes.includes('accounting')) {
      return {
        isDisabled: true,
        tooltip: t('supplierInvoices:cards.delete.cannotDeleteBecauseAccountingTooltip'),
      };
    }
    if (state.causes.includes('is-direct-payment-with-non-draft-progress-statement')) {
      return {
        isDisabled: true,
        tooltip: t('supplierInvoices:cards.delete.cannotDeleteBecauseDirectPaymentTooltip'),
      };
    }
    if (state.causes.length > 0) {
      throw new Error('Unhandled issue');
    }

    return {
      isDisabled: false,
      tooltip: undefined,
    };
  })();

  const options: Action[] = [
    {
      label: t('supplierInvoices:cards.delete.action'),
      onClick: params.callbacks.onDelete,
      isDisabled: tooltip.isDisabled,
      tooltip: tooltip.tooltip,
      warning: true,
      icon: <SimpleDeleteIcon />,
    },
  ];

  if (params.permissions.canSeeValidationHistory)
    options.push({
      label: t('validationStep:validationHistory'),
      onClick: params.callbacks.onSeeValidationHistory,
    });

  return {
    type: 'tertiaryDropdown',
    options,
  };
}
