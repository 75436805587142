import type { FC } from 'react';
import { useCallback } from 'react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Circle, Flex, HStack, Popover, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';

import { RICH_TEXT_CSS_VAR_DARK } from '../../../../../../constants';

import { RICH_TEXT_ICON_PROPS } from './icon-props.constant';

const getVarFromToken = (token: string) => `var(${token})`;

const COLORS = Object.keys(RICH_TEXT_CSS_VAR_DARK);

interface ColorPickerProps {
  currentColor: string | null;

  onColorChanged(newColor: string | null): void;

  fill?: boolean;
}
export const ColorPicker: FC<ColorPickerProps> = ({ currentColor, onColorChanged, fill = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onFontColorSelected = useCallback(
    (value: string | null) => () => {
      onColorChanged(value);
      onClose();
    },
    [onClose, onColorChanged],
  );

  return (
    <Popover
      closeOnBlur={false}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-end"
      flip={false}
      offset={[4, 5]}
      autoFocus={false}
    >
      <PopoverTrigger>
        <Flex
          w={7}
          position="relative"
          className="color-picker"
          {...RICH_TEXT_ICON_PROPS(isOpen)}
          sx={{ color: currentColor ? getVarFromToken(currentColor) : undefined }}
        >
          {fill ? <i className="ri-paint-fill" /> : <i className="ri-font-color" />}
        </Flex>
      </PopoverTrigger>

      <PopoverContent w="auto" userSelect="none">
        <HStack spacing={1} p={2}>
          {fill ? (
            <Circle cursor="pointer" size={6} bg="gray.100" onClick={onFontColorSelected(null)}>
              <CloseIcon color="#000000" />
            </Circle>
          ) : (
            <Circle cursor="pointer" size={6} bg="#000000" onClick={onFontColorSelected(null)}>
              {!currentColor && <CheckIcon color="white" />}
            </Circle>
          )}

          {COLORS.map((key) => {
            const isCurrentColor = key === currentColor;
            const colorToken = getVarFromToken(key);

            return (
              <Circle
                cursor="pointer"
                key={key}
                size={6}
                sx={{ backgroundColor: colorToken }}
                onClick={onFontColorSelected(colorToken)}
              >
                {isCurrentColor && <CheckIcon color="white" />}
              </Circle>
            );
          })}
        </HStack>
      </PopoverContent>
    </Popover>
  );
};
