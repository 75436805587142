import { Big } from 'big.js';
import { MARGIN_TYPE } from '@org/quotation-lib';
import { MarginComputedValue } from '@org/graneet-bff-client';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import type { QuoteMarginObject, Quote } from '@org/quotation-lib';
import type { QuoteUpdateAsyncDTO } from '@org/graneet-bff-client';

import { updateQuoteAmountIsUnreachable } from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';
import { useQuoteSetToStore } from 'features/quotation/quote-common/hooks/useQuoteSetToStore';
import {
  StatusEnum,
  quoteClientRequestsSetToStore,
} from 'features/quotation/quote-common/store/quoteClientRequestsSetToStore';
import type { ICommand } from 'features/quotation/undo-redo/command/types/ICommand';

export function useQuoteUpdateMargin() {
  const quoteSetToStore = useQuoteSetToStore();
  const { quoteProxyApi } = useQuotationProxyApis();
  const quoteError = useQuoteError();

  const handleExecute = useCallback(
    (quote: Quote, newMargin: string, marginType: MARGIN_TYPE) => {
      const oldQuoteMargin = quote.getMargin().export();
      let newTotalMargin: Big;

      switch (marginType) {
        case MARGIN_TYPE.OVERHEAD_COSTS: {
          newTotalMargin = new Big(oldQuoteMargin.profitMargin).mul(newMargin);
          quote.updateTotalMargin(newTotalMargin, {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.OVERHEAD_COSTS,
          });
          break;
        }
        case MARGIN_TYPE.PROFIT_MARGIN: {
          newTotalMargin = new Big(newMargin).mul(oldQuoteMargin.overheadCosts);
          quote.updateTotalMargin(newTotalMargin, {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          break;
        }
        case MARGIN_TYPE.TOTAL_MARGIN: {
          newTotalMargin = Big(newMargin);
          quote.updateTotalMargin(newTotalMargin, {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          break;
        }
        default: {
          console.error('Invalid margin type');
          return false;
        }
      }

      quoteSetToStore(quote);

      const clientRequestId = uuid();
      const timestamp = new Date().toISOString();
      quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
      const body: QuoteUpdateAsyncDTO = {
        quoteId: quote.getId(),
        timestamp,
        clientRequestId,
        margin: {
          overheadCosts:
            marginType === MARGIN_TYPE.OVERHEAD_COSTS ? newMargin : oldQuoteMargin.overheadCosts.toString(),
          profitMargin: marginType === MARGIN_TYPE.PROFIT_MARGIN ? newMargin : oldQuoteMargin.profitMargin.toString(),
          totalMargin: marginType === MARGIN_TYPE.TOTAL_MARGIN ? newMargin : oldQuoteMargin.totalMargin.toString(),
          computed: MarginComputedValue.Total,
        },
      };

      quoteProxyApi
        .updateQuoteAsync(body)
        .then(([err]) => err && quoteError())
        .catch((err) => quoteError(err.message));

      if (quote.getMargin().getTotalMargin()?.eq(Big(newTotalMargin))) {
        updateQuoteAmountIsUnreachable(false);
      } else {
        updateQuoteAmountIsUnreachable(true);
      }
      return true;
    },
    [quoteError, quoteProxyApi, quoteSetToStore],
  );

  const handleUndo = useCallback(
    (
      quote: Quote,
      oldValue: {
        margins: QuoteMarginObject;
        marginType: MARGIN_TYPE;
      },
    ) => {
      const quoteMargin = quote.getMargin();
      let newTotalMargin;

      switch (oldValue.marginType) {
        case MARGIN_TYPE.OVERHEAD_COSTS: {
          newTotalMargin = new Big(quoteMargin.getProfitMargin()).mul(oldValue.margins.overheadCosts).toString();
          quote.updateTotalMargin(Big(newTotalMargin), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.OVERHEAD_COSTS,
          });
          break;
        }
        case MARGIN_TYPE.PROFIT_MARGIN: {
          newTotalMargin = new Big(oldValue.margins.profitMargin).mul(quoteMargin.getOverheadCosts()).toString();
          quote.updateTotalMargin(Big(newTotalMargin), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          break;
        }
        case MARGIN_TYPE.TOTAL_MARGIN: {
          newTotalMargin = oldValue.margins.totalMargin;
          quote.updateTotalMargin(Big(newTotalMargin), {
            spreadUp: true,
            impactMargin: MARGIN_TYPE.PROFIT_MARGIN,
          });
          break;
        }
        default: {
          console.error('Invalid margin type');
          return false;
        }
      }

      quoteSetToStore(quote);

      const clientRequestId = uuid();
      const timestamp = new Date().toISOString();
      quoteClientRequestsSetToStore(StatusEnum.ADD_ENTRY, { clientRequestId, timestamp });
      const body: QuoteUpdateAsyncDTO = {
        quoteId: quote.getId(),
        timestamp,
        clientRequestId,
        margin: {
          overheadCosts:
            oldValue.marginType === MARGIN_TYPE.OVERHEAD_COSTS
              ? oldValue.margins.overheadCosts
              : quoteMargin.getOverheadCosts().toString(),
          profitMargin:
            oldValue.marginType === MARGIN_TYPE.PROFIT_MARGIN
              ? oldValue.margins.profitMargin
              : quoteMargin.getProfitMargin().toString(),
          totalMargin:
            oldValue.marginType === MARGIN_TYPE.TOTAL_MARGIN
              ? oldValue.margins.totalMargin
              : quoteMargin.getTotalMargin().toString(),
          computed: MarginComputedValue.Total,
        },
      };

      quoteProxyApi
        .updateQuoteAsync(body)
        .then(([err]) => err && quoteError())
        .catch((err) => quoteError(err.message));

      if (quote.getMargin().getTotalMargin()?.eq(Big(newTotalMargin))) {
        updateQuoteAmountIsUnreachable(false);
      } else {
        updateQuoteAmountIsUnreachable(true);
      }
      return true;
    },
    [quoteError, quoteProxyApi, quoteSetToStore],
  );

  return useCallback(
    (newMargin: string, marginType: MARGIN_TYPE): ICommand => ({
      execute(quote: Quote) {
        this.oldValue = {
          margins: quote.getMargin().export(),
          marginType,
        };
        try {
          return handleExecute(quote, newMargin, marginType);
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
      undo(quote: Quote) {
        try {
          return handleUndo(quote, this.oldValue);
        } catch (e: any) {
          return quoteError(e.message);
        }
      },
    }),
    [handleExecute, handleUndo, quoteError],
  );
}
