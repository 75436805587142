import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { Flex, Grid, GridItem, HStack, IconButton, Text, useTheme } from '@chakra-ui/react';
import type { FC } from 'react';
import { useMemo } from 'react';

import { useMonthlyTimeTableContext } from '../../contexts/MonthlyTimeTable.context';
import { SimpleChevronLeftIcon } from '../../../../Icons';

import { MonthlyTimeTableHeaderDay } from './MonthlyTimeTableHeaderDay';
import { MonthlyWeekHeader } from './MonthlyWeekHeader';

dayjs.extend(weekOfYear);

interface MonthlyTimeTableHeaderProps {
  onPreviousMonth(): void;
  onNextMonth(): void;
  currentDate: dayjs.Dayjs;
}

export const MonthlyTimeTableHeader: FC<MonthlyTimeTableHeaderProps> = ({
  onPreviousMonth,
  onNextMonth,
  currentDate,
}) => {
  const { colors } = useTheme();

  const { numberOfDays, hoveredWeek, onHoverWeek, weeksList } = useMonthlyTimeTableContext();

  const title = useMemo(() => currentDate.format('MMMM YYYY'), [currentDate]);

  return (
    <Flex direction="row" alignItems="center">
      <HStack w="18rem">
        <Flex>
          <IconButton
            variant="clear"
            color="gray.500"
            icon={<SimpleChevronLeftIcon boxSize={4} />}
            onClick={onPreviousMonth}
            aria-label="previous"
          />
          <IconButton
            color="gray.500"
            variant="clear"
            icon={<SimpleChevronLeftIcon boxSize={4} transform="rotate(180deg)" />}
            onClick={onNextMonth}
            aria-label="next"
          />
        </Flex>

        <Text fontSize="l" fontWeight="600" textTransform="capitalize" color="gray.700">
          {title}
        </Text>
      </HStack>

      <Grid
        alignItems="flex-start"
        templateColumns={`repeat(${numberOfDays}, 1fr)`}
        w="100%"
        bgColor={colors.gray[200]}
        borderX={`1px solid ${colors.white}`}
        gridGap="0 1px"
      >
        {Object.keys(weeksList).map((week) =>
          weeksList[week].days.length > 0 ? (
            <MonthlyWeekHeader
              week={weeksList[week].week}
              numberOfDays={weeksList[week].days.length}
              days={weeksList[week].days}
              displayTitle={hoveredWeek === weeksList[week].week}
              key={week}
            />
          ) : null,
        )}
        {Object.keys(weeksList).map((week) =>
          weeksList[week].days.length > 0 ? (
            <GridItem flex={1} colSpan={weeksList[week].days.length} key={week}>
              <Grid
                alignItems="flex-start"
                templateColumns={`repeat(${weeksList[week].days.length}, 1fr)`}
                boxSize="100%"
              >
                {weeksList[week].days.map((day) => (
                  <MonthlyTimeTableHeaderDay
                    day={day}
                    key={day.toISOString()}
                    onHover={onHoverWeek}
                    week={weeksList[week].week}
                  />
                ))}
              </Grid>
            </GridItem>
          ) : null,
        )}
      </Grid>
    </Flex>
  );
};
