export enum GRANEET_COLORS {
  BLUE = 'blue',
  YELLOW = 'yellow',
  GREEN = 'green',
  RED = 'red',
  PURPLE = 'purple',
  PINK = 'pink',
  WARM_GRAY = 'warm_gray',
  CYAN = 'cyan',
  ORANGE = 'orange',
  INDIGO = 'indigo',
}

export enum GRANEET_PDF_COLORS {
  BLUE = 'blue',
  YELLOW = 'yellow',
  GREEN = 'green',
  RED = 'red',
  PURPLE = 'purple',
  PINK = 'pink',
  WARM_GRAY = 'warm_gray',
  GRAY = 'gray',
  CYAN = 'cyan',
  ORANGE = 'orange',
  TEAL = 'teal',
  WHITE = 'white',
}
