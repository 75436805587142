import { Box, Checkbox, HStack } from '@chakra-ui/react';
import type { ChangeEvent } from 'react';
import { useCallback } from 'react';

import { QuoteAGGridType } from 'features/quotation/ag-grid-quote/constants/agGridConstants';
import { useStore } from 'store/store';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const LeftActionHeader = ({ type }: { type: QuoteAGGridType }) => {
  const nbSelectedRows = useStore((state) =>
    type === QuoteAGGridType.ROOT ? state.quoteNbSelectedRows : state.quoteHiddenCostNbSelectedRows,
  );
  const gridRef = useStore((state) =>
    type === QuoteAGGridType.ROOT ? state.quoteGridRef : state.quoteHiddenCostGridRef,
  );

  const { quote } = useQuote();

  const isIndeterminate =
    (type === QuoteAGGridType.ROOT
      ? quote.getTree().getNodesFromRootNode()
      : quote.getTree().getNodesFromHiddenCostNode()
    ).size -
      1 !==
    nbSelectedRows;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if ((gridRef?.current?.api?.getSelectedRows().length ?? 0) === 0) {
        gridRef?.current?.api?.selectAll();
        return;
      }
      gridRef?.current?.api?.deselectAll();
    },
    [gridRef],
  );

  return (
    <HStack width="100%" px="5px">
      <Box className="ag-header-cell-resize" cursor="initial" />
      <Checkbox
        size="md"
        isIndeterminate={isIndeterminate && nbSelectedRows > 0}
        isChecked={nbSelectedRows > 0}
        onChange={handleChange}
        colorScheme="greenBrand"
        margin={0}
        padding={0}
      />
    </HStack>
  );
};
