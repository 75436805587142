import type { IHoldback } from '@graneet/business-logic';
import type { FC } from 'react';
import { Badge } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { HOLDBACK_STATUSES_STYLE } from '../constants/holdback.constant';

interface HoldbackStatusBadgeProps {
  holdback: IHoldback;
}

export const HoldbackStatusBadge: FC<HoldbackStatusBadgeProps> = ({ holdback }) => {
  const { t } = useTranslation(['holdback']);

  return (
    <Badge colorScheme={HOLDBACK_STATUSES_STYLE[holdback.status].variantColor}>
      {t(`holdback:statuses.${holdback.status}`)}
    </Badge>
  );
};
