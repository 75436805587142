/* eslint-disable no-nested-ternary */
import type { FC, ReactNode } from 'react';
import type { ButtonProps as ChakraButtonProps, TooltipProps } from '@chakra-ui/react';
import { Button as ChakraButton } from '@chakra-ui/react';

import { Tooltip } from '../Tooltip';
import type { TestableElement } from '../../types';

type ButtonChildrenProps = ChakraButtonProps & TestableElement;

export type ButtonProps = Omit<ButtonChildrenProps, 'disabled' | 'onSubmit'> & {
  tooltip?: ReactNode;
  tooltipProps?: Omit<TooltipProps, 'children'>;
};

export type ButtonColor = 'blue' | 'red' | 'black' | 'default';

const COLOR_CONFIG = {
  transparent: {
    background: 'transparent',
    backgroundHover: '#F5F5F4',
    color: 'black',
    shadow: 'none',
    fontWeight: 400,
    fontSize: '14px',
    border: 'none',
    height: '32px',
  },
  blue: {
    background: 'greenBrand.dark',
    backgroundHover: 'greenBrand.light',
    color: 'white',
    shadow: '0px 0px 0px 1px #2B5058, 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
    fontWeight: 400,
    fontSize: '14px',
    border: '1px solid transparent',
    height: '32px',
  },
  red: {
    background: '#D92D20',
    backgroundHover: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #D92D20',
    color: '#fff',
    shadow: '0px 2px 4px 0px rgba(17, 24, 28, 0.04), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px #B42318',
    fontWeight: 600,
    fontSize: '14px',
    border: '1px solid rgba(255,255,255,.1)',
    height: '32px',
  },
  black: {
    background: '#1F2A37',
    backgroundHover: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #1F2A37',
    color: '#fff',
    shadow: '0px 2px 4px 0px rgba(17, 24, 28, 0.04), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px #0E0D1C',
    fontWeight: 600,
    fontSize: '14px',
    border: '1px solid rgba(255,255,255,.1)',
    height: '32px',
  },
  default: {
    background: 'linear-gradient(180deg, #FCFCFD 0%, #F9FAFB 100%)',
    backgroundHover: '#F5F5F4',
    color: 'primary',
    shadow:
      '0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
    fontWeight: 600,
    fontSize: '14px',
    border: '1px solid transparent',
    height: '32px',
  },
  primary: {
    background: 'greenBrand.dark',
    backgroundHover: 'greenBrand.light',
    color: 'white',
    shadow: '0px 0px 0px 1px #2B5058, 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
    fontWeight: 400,
    fontSize: '14px',
    border: '1px solid transparent',
    height: '32px',
  },
} as const;

const ButtonChildren: FC<ButtonChildrenProps> = ({
  children = null,
  variant = 'solid',
  size = 'md',
  colorScheme = 'primary',
  isDisabled = false,
  'data-testid': testId,
  ...props
}) => {
  const scheme: ButtonColor = (
    ['blue', 'red', 'black', 'default', 'primary', 'transparent'].includes(colorScheme) ? colorScheme : 'default'
  ) as ButtonColor;
  const color =
    variant === 'solid'
      ? COLOR_CONFIG[scheme].color
      : scheme === 'red'
        ? COLOR_CONFIG[scheme].background
        : COLOR_CONFIG.default.color;
  const { background, border, shadow, backgroundHover, fontSize, fontWeight, height } =
    COLOR_CONFIG[variant === 'solid' ? scheme : 'default'];

  if (variant === 'link') {
    return (
      <ChakraButton
        type="button"
        data-testid={testId}
        aria-disabled={isDisabled}
        isDisabled={isDisabled}
        width="min-content"
        opacity={!isDisabled ? '1' : '0.4'}
        background="none"
        color={colorScheme === 'blue' ? '#009DFF' : `${colorScheme}.500`}
        fontSize="16px"
        fontStyle="normal"
        fontFamily="Lato"
        fontWeight={500}
        _hover={{
          background: 'none',
          color: '#009DFF',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        _active={{
          background: 'none',
          color: '#009DFF',
        }}
        {...props}
      >
        {children}
      </ChakraButton>
    );
  }

  return (
    <ChakraButton
      data-testid={testId}
      type="button"
      width="min-content"
      size={size}
      fontFamily="Lato"
      variant={variant}
      color={color}
      isDisabled={isDisabled}
      borderRadius="6px"
      border={border}
      background={background}
      boxShadow={shadow}
      height={height}
      px="0.75rem"
      fontSize={fontSize}
      fontStyle="normal"
      fontWeight={fontWeight}
      backgroundBlendMode="plus-darker"
      _hover={{
        background: backgroundHover,
      }}
      {...props}
    >
      {children}
    </ChakraButton>
  );
};

export const Button = ({ children, tooltip, tooltipProps, ...props }: ButtonProps) => {
  if (tooltip) {
    return (
      <Tooltip
        hasArrow
        label={tooltip}
        shouldWrapChildren
        placement="bottom"
        textAlign="center"
        px="1rem"
        py="0.625rem"
        {...tooltipProps}
      >
        <ButtonChildren {...props}>{children}</ButtonChildren>
      </Tooltip>
    );
  }

  return <ButtonChildren {...props}>{children}</ButtonChildren>;
};
