import type { CSSProperties, FC } from 'react';
import type { StyleProps } from '@chakra-ui/react';

import { useChakraColors } from '../../hooks';

export interface UnderlineProps
  extends Omit<
    CSSProperties,
    'color' | 'backgroundImage' | 'backgroundRepeat' | 'backgroundPosition' | 'backgroundSize'
  > {
  color: StyleProps['color'];

  children: string;
}

export const Underline: FC<UnderlineProps> = ({ color = 'yellow.400', children, ...props }) => {
  const { color: colorAsHex } = useChakraColors({ color });

  return (
    <span
      style={{
        ...props,
        backgroundImage: `linear-gradient(to right, ${colorAsHex} 0%, ${colorAsHex} 100%)`,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: '0 100%',
        backgroundSize: '100% 33%',
      }}
    >
      {children}
    </span>
  );
};
