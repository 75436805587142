import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import type { ISubProject } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { Badge, PriceAdvanced, Progress, RadioGroup, StarsIcon, Table, Tooltip } from '@graneet/lib-ui';

import { Loading } from 'features/common/components/Loading';

const RowTooltip: FC<{ error?: string; children: ReactNode }> = ({ error, children }) => {
  if (error) {
    return (
      <Tooltip label={error} shouldWrapChildren>
        {children}
      </Tooltip>
    );
  }

  return children;
};

const SubProjectSelectionTableRow = <T extends ISubProject>({
  subProject,
  onSubProjectSelect,
  subProjectId,
  getRowState = () => ({ isDisabled: false, tooltipMessage: undefined }),
}: {
  subProject: T;
  onSubProjectSelect(newSubProject: T | null): void;
  subProjectId: string | null;
  getRowState?(subProject: T): { isDisabled: boolean; tooltipMessage: string | undefined };
}) => {
  const handleCheckSubProject = useCallback(() => {
    onSubProjectSelect(subProject);
  }, [onSubProjectSelect, subProject]);

  const rowState = getRowState(subProject);

  return (
    <Table.Row onClick={handleCheckSubProject} isDisabled={rowState.isDisabled}>
      <Table.Cell>
        <RowTooltip error={rowState.tooltipMessage}>
          <RadioGroup.Option isChecked={subProjectId === subProject.id} value="" />
        </RowTooltip>
      </Table.Cell>

      <Table.Cell>
        <RowTooltip error={rowState.tooltipMessage}>{subProject.name}</RowTooltip>
      </Table.Cell>

      <Table.Cell>
        <PriceAdvanced amount={subProject.amountExVAT} />
      </Table.Cell>

      <Table.Cell>
        <Progress value={subProject.cumulativeProgressPercentage} />
      </Table.Cell>
    </Table.Row>
  );
};

interface SubProjectSelectionTableProps<T extends ISubProject> {
  subProjects: T[] | null;

  onSubProjectSelect(newSubProject: T | null): void;

  displayNewSubProjectOption?: boolean;

  getRowState?(subProject: T): { isDisabled: boolean; tooltipMessage: string | undefined };

  defaultSelectedSubProjectId?: string;
}

export const SubProjectSelectionTable = <T extends ISubProject>({
  subProjects,
  onSubProjectSelect,
  displayNewSubProjectOption = false,
  getRowState,
  defaultSelectedSubProjectId,
}: SubProjectSelectionTableProps<T>) => {
  const { t } = useTranslation(['subProject']);

  const [subProjectId, setSubProjectId] = useState<string | null>(defaultSelectedSubProjectId ?? null);

  const handleCheckSubProject = useCallback(
    (newSubProject: T | null) => {
      onSubProjectSelect(newSubProject || null);
      setSubProjectId(newSubProject?.id || null);
    },
    [onSubProjectSelect],
  );

  return subProjects === null ? (
    <Loading />
  ) : (
    <Table templateColumns={['50px', '5fr', '2fr', '3fr']}>
      <Table.Header>
        <Table.Cell />
        <Table.Cell>{t('subProject:quoteAssociation.table.name')}</Table.Cell>
        <Table.Cell>{t('subProject:quoteAssociation.table.amountToBeBilledExVAT')}</Table.Cell>
        <Table.Cell>{t('subProject:quoteAssociation.table.cumulativeProgressPercentage')}</Table.Cell>
      </Table.Header>

      {displayNewSubProjectOption && (
        <Table.Row onClick={() => handleCheckSubProject(null)}>
          <Table.Cell>
            <RadioGroup.Option isChecked={subProjectId === null} value="" />
          </Table.Cell>
          <Table.Cell gridColumn="span 3">
            <Badge mr={2} colorScheme="gray">
              <StarsIcon boxSize={4} /> {t('subProject:quoteAssociation.newBadge')}
            </Badge>
            {t('subProject:quoteAssociation.newSubProjectOption')}
          </Table.Cell>
        </Table.Row>
      )}

      {subProjects.map((subProject) => (
        <SubProjectSelectionTableRow
          key={subProject.id}
          subProject={subProject}
          onSubProjectSelect={handleCheckSubProject}
          getRowState={getRowState}
          subProjectId={subProjectId}
        />
      ))}
    </Table>
  );
};
