import type { ICustomDiscountEditionDTO } from '@graneet/business-logic';
import { CUSTOM_DISCOUNT_TYPES } from '@graneet/business-logic';
import {
  DifferenceSummary,
  DrawersStack,
  SimpleDeleteIcon,
  SimpleEditIcon,
  SimpleRewindIcon,
  formatNumberToVatRate,
  useCurrency,
  useDrawer,
  useDrawersStack,
  useToast,
} from '@graneet/lib-ui';
import { isNil } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import { ContractCustomDiscountDrawer } from '../../modals/ContractCustomDiscountDrawer';

import { useContractRuleContext } from 'features/contract/contexts/ContractRuleContext';
import type { ContractEditionForm } from 'features/contract/forms/contract-edition.form';
import type { CustomDiscountEditionForm } from 'features/custom-discount/forms/custom-discount-edition-form';
import { NEGATIVE_MULTIPLIER, POSITIVE_MULTIPLIER } from 'features/custom-discount/forms/custom-discount-edition-form';
import { formatCustomDiscountsToAmountType } from 'features/contract/services/contract.util';

export interface ContractCustomDiscountAmountSummaryLineProps {
  customDiscount: ICustomDiscountEditionDTO;
  totalAmountWithDiscountExVAT: {
    initialAmount: number;
    newAmount: number;
  };
}

export const ContractCustomDiscountAmountSummaryLine: FC<ContractCustomDiscountAmountSummaryLineProps> = ({
  customDiscount,
  totalAmountWithDiscountExVAT,
}) => {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const form = useFormContext<ContractEditionForm>();
  const { canCustomDiscountBeUpdatedOrDeleted, canCustomDiscountBeUpdated, contractInfos } = useContractRuleContext();

  const drawersStack = useDrawersStack();
  const customDiscountDrawer = useDrawer('custom-discount-drawer', drawersStack);

  const { mapNumberToAmount } = useCurrency();
  const initialCustomDiscounts = formatCustomDiscountsToAmountType(contractInfos?.contract.customDiscounts);
  const initialCustomDiscount = initialCustomDiscounts?.find(({ id }) => id === customDiscount.id);

  // For custom discounts the amount is always calculated and saved in the database
  const initialCustomDiscountAmountExVAT = initialCustomDiscount?.amountExVAT ?? 0;

  const { customDiscounts: currentCustomDiscounts } = useOnChangeValues(form, ['customDiscounts']);

  const currentCustomDiscount = currentCustomDiscounts?.find(({ id }) => id === customDiscount.id);

  const isCustomDiscountRemoved = useMemo(
    () => !!initialCustomDiscount && currentCustomDiscount === undefined,
    [currentCustomDiscount, initialCustomDiscount],
  );

  const initialCustomDiscountFormValues = useMemo<CustomDiscountEditionForm>(
    () => ({
      customDiscountName: customDiscount.name,
      customDiscountVatRate: formatNumberToVatRate(customDiscount.vatRate),
      customDiscountType: CUSTOM_DISCOUNT_TYPES.AMOUNT,
      customDiscountAmountExVAT: mapNumberToAmount(customDiscount.amountExVAT ?? 0),
      customDiscountMultiplier:
        !customDiscount?.amountExVAT || customDiscount.amountExVAT > 0 ? POSITIVE_MULTIPLIER : NEGATIVE_MULTIPLIER,
    }),
    [customDiscount, mapNumberToAmount],
  );

  const handleCustomDiscountRemove = useCallback(() => {
    try {
      const { customDiscounts: rawCustomDiscounts } = form.getFormValues();
      if (!rawCustomDiscounts) {
        return;
      }

      // Remove custom discount from array and test the consequences of the change
      const newCustomDiscounts = rawCustomDiscounts.filter(({ id }) => id !== customDiscount.id);

      const isDeletionContext = true;
      const rule = canCustomDiscountBeUpdated(newCustomDiscounts, customDiscount.name, isDeletionContext);
      if (!rule.canUpdate) {
        throw new Error(rule.errorMessage);
      }

      form.setFormValues({
        customDiscounts: newCustomDiscounts,
      });
    } catch (error) {
      toast.error((error as Error).message);
    }
  }, [canCustomDiscountBeUpdated, customDiscount, form, toast]);

  const handleCustomDiscountRestore = useCallback(() => {
    const { customDiscounts: rawCustomDiscounts } = form.getFormValues();
    if (initialCustomDiscount) {
      // Remove the modified custom discount from array, if it exists, and add the initial one
      const updatedCustomDiscounts = [
        ...(rawCustomDiscounts ?? []).filter((rawCustomDiscount) => rawCustomDiscount.id !== initialCustomDiscount.id),
        { ...initialCustomDiscount },
      ];
      form.setFormValues({
        customDiscounts: updatedCustomDiscounts,
      });
    }
  }, [form, initialCustomDiscount]);

  const discountDropdownItems = useMemo(() => {
    const actions = {
      restore: {
        icon: <SimpleRewindIcon />,
        label: t('global:words.c.restore'),
        onClick: handleCustomDiscountRestore,
      },
      delete: {
        icon: <SimpleDeleteIcon />,
        label: t('global:words.c.delete'),
        onClick: handleCustomDiscountRemove,
        warning: true,
      },
      edit: {
        icon: <SimpleEditIcon />,
        label: t('global:words.c.update'),
        onClick: customDiscountDrawer.onOpen,
      },
    };

    // Is new discount
    if (isNil(initialCustomDiscount)) {
      return [actions.edit, actions.delete];
    }

    // Is deleted discount
    if (isCustomDiscountRemoved) {
      return [actions.restore];
    }

    // Is edited discount
    if (
      initialCustomDiscount.name !== customDiscount?.name ||
      initialCustomDiscount.vatRate !== customDiscount?.vatRate ||
      initialCustomDiscountAmountExVAT !== customDiscount?.amountExVAT
    ) {
      return [actions.edit, actions.restore, actions.delete];
    }

    // Is existing discount
    if (canCustomDiscountBeUpdatedOrDeleted(customDiscount.id)) {
      return [actions.edit, actions.delete];
    }

    return [];
  }, [
    canCustomDiscountBeUpdatedOrDeleted,
    customDiscount?.amountExVAT,
    t,
    handleCustomDiscountRestore,
    handleCustomDiscountRemove,
    customDiscountDrawer.onOpen,
    customDiscount.id,
    customDiscount?.name,
    customDiscount?.vatRate,
    initialCustomDiscount,
    isCustomDiscountRemoved,
    initialCustomDiscountAmountExVAT,
  ]);

  return (
    <>
      <DifferenceSummary.Discount
        label={customDiscount?.name ?? ''}
        initialDiscountAmount={initialCustomDiscountAmountExVAT}
        newDiscountAmount={isCustomDiscountRemoved ? 0 : (customDiscount?.amountExVAT ?? 0)}
        isDiscountRemoved={isCustomDiscountRemoved}
        dropdownItems={discountDropdownItems}
        hasOppositeSign={false}
      />

      <DrawersStack drawersStack={drawersStack}>
        <ContractCustomDiscountDrawer
          drawer={customDiscountDrawer}
          totalAmountWithDiscountExVAT={totalAmountWithDiscountExVAT.newAmount}
          customDiscountId={customDiscount.id}
          initialCustomDiscountFormValues={initialCustomDiscountFormValues}
          customDiscountsInvoiced={contractInfos?.customDiscountsInvoiced}
        />
      </DrawersStack>
    </>
  );
};
