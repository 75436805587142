import type { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface QuoteExportDeepTableLotWrapperProps {
  id: number;
  depth: number;
  children: ReactNode;
}

export const QuoteExportDeepTableLotWrapper: FC<QuoteExportDeepTableLotWrapperProps> = ({ children, depth }) => (
  <Box ml={depth === 1 ? 0 : 2}>{children}</Box>
);
