import type { FC } from 'react';
import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { IPaymentMethodResponseDTO } from '@graneet/business-logic';

import { PaymentMethodEditionForm } from './PaymentMethodEditionForm';

interface PaymentMethodEditModalProps {
  paymentMethod: IPaymentMethodResponseDTO;

  isOpen: boolean;

  onClose(): void;

  onUpdated(paymentMethod: IPaymentMethodResponseDTO): Promise<void>;
}

export const PaymentMethodEditModal: FC<PaymentMethodEditModalProps> = ({
  paymentMethod,
  isOpen,
  onClose,
  onUpdated,
}) => {
  const { t } = useTranslation(['paymentMethods']);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // TODO replace be lib-ui modal component
  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered closeOnOverlayClick={!isSubmitting}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader whiteSpace="nowrap">{t('paymentMethods:modal.update.title')}</ModalHeader>

        <ModalBody>
          <PaymentMethodEditionForm
            onClose={onClose}
            paymentMethod={paymentMethod}
            setIsSubmitting={setIsSubmitting}
            onUpdated={onUpdated}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
