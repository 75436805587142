import { useMemo } from 'react';

import type { MaskInfo, MaskInfos, MaskOptions, MaskType } from './mask.type';
import { useCurrencyMask } from './utils/currency-mask.hook';

const NEGATIVE_SIGN = '-';

const BLOCK_NUM_EMPTY = {
  mask: '',
};

const generateEscapedMask = (mask = '') =>
  mask
    .split('')
    .map((char) => `\\${char}`)
    .join('');

// There is no nullish operator on lib-ui
// In order to replace `scale ?? 10`
// We can write `scale == null ? 10 : scale`
// `scale == null` returns true with null and undefined
const generateMaskOtherThanCurrency = ({ min, max, scale, unit, forceNegative }: MaskOptions = {}): Omit<
  MaskInfos,
  'currency'
> => ({
  decimal: {
    placeholder: '0,00',
    mask: [
      BLOCK_NUM_EMPTY,
      {
        mask: 'num',
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            min,
            max,
            signed: typeof min === 'undefined' || min === null || min < 0,
            scale: scale == null ? 10 : scale,
            thousandsSeparator: ' ',
            radix: ',',
            mapToRadix: ['.'],
          },
        },
      },
    ],
  },
  percentage: {
    placeholder: '0 %',
    mask: [
      BLOCK_NUM_EMPTY,
      {
        mask: 'num %',
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            signed: typeof min === 'undefined' || min === null || min < 0,
            scale: scale == null ? 2 : scale,
            min: min == null ? 0 : min,
            max: max == null ? 100 : max,
          },
        },
      },
    ],
  },
  number: {
    placeholder: '',
    mask: [
      BLOCK_NUM_EMPTY,
      {
        mask: 'num',
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            min,
            max,
            signed: typeof min === 'undefined' || min === null || min < 0,
            scale: 0,
            thousandsSeparator: ' ',
            radix: ',',
            mapToRadix: ['.'],
          },
        },
      },
    ],
  },
  unit: {
    placeholder: `0 ${unit}`,
    mask: [
      BLOCK_NUM_EMPTY,
      {
        mask: `${forceNegative ? NEGATIVE_SIGN : ''}num ${generateEscapedMask(unit)}`,
        lazy: false,
        blocks: {
          num: {
            mask: Number,
            min,
            max,
            signed: forceNegative ? false : typeof min === 'undefined' || min === null || min < 0,
            scale: scale == null ? 10 : scale,
            thousandsSeparator: ' ',
            radix: ',',
            mapToRadix: ['.'],
          },
        },
      },
    ],
  },
});

export const useInputMaskInfos = (type: MaskType, options?: MaskOptions): MaskInfo => {
  const currencyMaskInfos = useCurrencyMask(options);

  return useMemo<MaskInfo>(
    () => (type === 'currency' ? currencyMaskInfos : generateMaskOtherThanCurrency(options)[type] || null),
    // Disable rule to allow JSON.stringify(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(options), type, currencyMaskInfos],
  );
};
