import { HStack } from '@chakra-ui/react';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { ChangeEvent, FocusEvent } from 'react';
import { memo, useState, useCallback, useRef, useEffect } from 'react';
import type { CustomCellEditorProps } from '@ag-grid-community/react';
import type { MaskType, MaskedInputHandle } from '@graneet/lib-ui';
import { QuotationInput, QuotationMaskedInput, useInputMaskInfos } from '@graneet/lib-ui';

import { useKeyboard } from 'features/quotation/ag-grid-quote/hooks/keyboard/useKeyboard';

export const TextEditorCell = memo(
  (
    params: CustomCellEditorProps<QuoteNodeObject> & {
      mask?: MaskType;
      unit?: string;
      textAlign?: 'left' | 'right';
    },
  ) => {
    const { value, data, column, node, onValueChange, stopEditing, mask, unit, textAlign } = params;
    const { placeholder, mask: imask } = useInputMaskInfos(mask ?? 'unit', {
      unit,
    });

    const { handleKeyDown } = useKeyboard();
    const [inputValue, setInputValue] = useState(value ?? '');
    const [maskedInputRef, setMaskedInputRef] = useState<HTMLInputElement | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const ref = useRef<MaskedInputHandle>(null);

    const handleOnChange = useCallback(
      (e: string | ChangeEvent<HTMLInputElement>) => {
        const newValue = typeof e === 'string' ? e : e.target.value;
        setInputValue(newValue);
        onValueChange(newValue);
      },
      [onValueChange],
    );

    const handleSave = useCallback(
      (v: string) => {
        onValueChange(v);
        stopEditing();
      },
      [onValueChange, stopEditing],
    );

    const handleOnBlur = useCallback(
      (e: string | FocusEvent<HTMLInputElement>) => {
        const newValue = typeof e === 'string' ? e : e.target.value;
        handleSave(newValue);
      },
      [handleSave],
    );

    const handleOnFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
      e.target.select();
    }, []);

    const handleSubmit = useCallback(() => {
      const v = ref.current?.getUnmaskedValue();
      handleSave(v);
    }, [handleSave]);

    useEffect(() => {
      if (mask) {
        maskedInputRef?.focus();
      } else {
        inputRef.current?.focus();
      }
    }, [mask, maskedInputRef]);

    if (
      (data?.content.type === 'QuoteLot' || data?.content.type === 'QuoteComponent') &&
      column?.getColId() === 'vatRate'
    ) {
      return null;
    }

    if (node.footer) {
      return null;
    }

    return (
      <HStack width="100%" justifyContent="center" background="transparent" px={2} py="8px">
        {mask ? (
          <QuotationMaskedInput
            textAlign={textAlign}
            value={inputValue}
            inputRef={setMaskedInputRef}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onKeyDownCapture={handleKeyDown(handleSubmit)}
            mask={imask}
            placeholder={placeholder}
            ref={ref}
            noStyle
          />
        ) : (
          <QuotationInput
            textAlign={textAlign}
            value={inputValue}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onKeyDownCapture={handleKeyDown(handleSave)}
            ref={inputRef}
            noStyle
          />
        )}
      </HStack>
    );
  },
);
