import { Tabs } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';

import { DisplayNotFoundScreen } from '../DisplayNotFoundScreen';

import { ViewTimeTrackingScreen } from './ViewTimeTrackingScreen';
import { ViewWorkersScreen } from './ViewWorkersScreen';

import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const TimeTrackingRouter = () => {
  const { t } = useTranslation(['global', 'timeTracking']);
  const { updateHeaderTitle } = useHeaderContext();

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.timeTracking'), []);
  }, [t, updateHeaderTitle]);

  return (
    <>
      <Tabs
        hasInternalPadding
        fallbackPath="."
        data={[
          {
            id: 'timeTracking',
            title: t('timeTracking:tabs.timeTracking'),
            exact: true,
            path: '.',
            content: (
              <QueryWrapper>
                <ViewTimeTrackingScreen />
              </QueryWrapper>
            ),
          },
          {
            id: 'workers',
            title: t('timeTracking:tabs.workers'),
            path: './workers',
            content: (
              <QueryWrapper>
                <ViewWorkersScreen />
              </QueryWrapper>
            ),
            exact: true,
          },
        ]}
        errorComponent={<DisplayNotFoundScreen />}
      />
    </>
  );
};
