import type { FC } from 'react';
import { useCallback, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import type { ILibraryJobResponseDTO } from '@graneet/business-logic';
import { PERMISSION } from '@graneet/business-logic';
import { ActionMenu, Button, ListingLayout, useCurrency } from '@graneet/lib-ui';

import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { useLibraryJobImportBatch, useLibraryJobs } from 'features/library-job/services/library-job.api';
import { usePermissions } from 'features/role/hooks/usePermissions';
import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import type { ImportLibraryJobField } from 'features/library-job/hooks/useImportLibraryJobFields';
import { useImportLibraryJobFields } from 'features/library-job/hooks/useImportLibraryJobFields';
import { TEMPLATE_URL } from 'features/import/constants/import.constant';
import { formatImportAmount } from 'features/import/utils/import.util';
import { LibraryJobTable } from 'features/library-job/components/LibraryJobTable';
import type { ImportSpreadsheetRowHook, ImportSpreadsheetSubmit } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImport } from 'features/import/components/SpreadsheetImport';
import { SpreadsheetImportingModal } from 'features/import/components/SpreadsheetImportingModal';
import { LibraryJobAddModal } from 'features/library-job/components/modals/LibraryJobAddModal';
import { LibraryJobDeleteModal } from 'features/library-job/components/modals/LibraryJobDeleteModal';
import { LibraryJobDuplicateModal } from 'features/library-job/components/modals/LibraryJobDuplicateModal';
import { SpreadsheetImportMenu } from 'features/import/components/SpreadsheetImportMenu';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

const EDIT_PERMISSIONS = [PERMISSION.UPDATE_LIBRARY];

export const ViewLibraryJobsScreen: FC = () => {
  const { t } = useTranslation(['library', 'global']);
  const { updateHeaderTitle } = useHeaderContext();

  useLayoutEffect(() => {
    updateHeaderTitle(t('global:nav.library'), []);
  }, [t, updateHeaderTitle]);

  const creationModal = useDisclosure();
  const removeModal = useDisclosure();
  const duplicateModal = useDisclosure();
  const importingModal = useDisclosure();

  const { createRedirectionWithFilters } = useFiltersQuery();
  const hasUpdateLibraryPermission = usePermissions(EDIT_PERMISSIONS);
  const updateLibraryButtonDisabledProps = useDisabledButtonProps(EDIT_PERMISSIONS);

  const libraryJobs = useLibraryJobs();

  const libraryJobImportBatchMutation = useLibraryJobImportBatch();

  const [selectedLibraryJob, setSelectedLibraryJob] = useState<ILibraryJobResponseDTO | null>(null);

  const { mapAmountToNumber } = useCurrency();
  const importFields = useImportLibraryJobFields();

  const { isOpen: isImportOpen, onClose: onCloseImport, onOpen: onOpenImport } = useDisclosure();

  const importRowHook = useCallback<ImportSpreadsheetRowHook<ImportLibraryJobField>>((rowData) => {
    const transformedData = { ...rowData };
    if (rowData.unit_price !== undefined) {
      transformedData.unit_price = formatImportAmount(rowData.unit_price.toString());
    }

    return transformedData;
  }, []);

  const onSubmitImport = useCallback<ImportSpreadsheetSubmit<ImportLibraryJobField>>(
    async (importData) => {
      importingModal.onOpen();

      const dto = importData.validData.map((row) => ({
        refCode: row.code?.toString() || null,
        description: row.name!.toString(),
        unit: row.unit!.toString(),
        unitDisbursementExVAT: mapAmountToNumber(parseFloat(row.unit_price!.toString())),
      }));

      await libraryJobImportBatchMutation.mutateAsync(
        { jobs: dto },
        {
          onSuccess: () => {
            libraryJobs.refetch();
          },
        },
      );
      importingModal.onClose();
    },
    [importingModal, libraryJobImportBatchMutation, mapAmountToNumber, libraryJobs],
  );

  const onRowClicked = useCallback(
    (id: number) => {
      createRedirectionWithFilters(`/opportunities/library/jobs/${id}`)();
    },
    [createRedirectionWithFilters],
  );

  const handleRemove = useCallback(
    (libraryJob: ILibraryJobResponseDTO) => () => {
      setSelectedLibraryJob(libraryJob);
      removeModal.onOpen();
    },
    [removeModal],
  );

  const handleDuplicate = useCallback(
    (libraryJob: ILibraryJobResponseDTO) => () => {
      setSelectedLibraryJob(libraryJob);
      duplicateModal.onOpen();
    },
    [duplicateModal],
  );

  const onLibraryJobDuplicated = useCallback(async () => {
    await libraryJobs.refetch();
    setSelectedLibraryJob(null);
  }, [libraryJobs]);

  return (
    <>
      <ListingLayout
        pagination={libraryJobs}
        search={{
          placeholder: t('library:actions.searchLibraryJob'),
        }}
        actions={
          <>
            <Button onClick={creationModal.onOpen} {...updateLibraryButtonDisabledProps()}>
              {t('library:actions.addLibraryJob')}
            </Button>

            {!updateLibraryButtonDisabledProps().isDisabled && (
              <SpreadsheetImportMenu
                label={t('library:actions.importLibraryJobs')}
                templateUrl={TEMPLATE_URL.LIBRARY_JOBS}
                onOpen={onOpenImport}
              />
            )}
          </>
        }
        content={
          <LibraryJobTable
            gridId="library-job"
            libraryJobs={libraryJobs}
            onRowClicked={onRowClicked}
            renderActionsMenu={
              hasUpdateLibraryPermission
                ? (libraryJob) => (
                    <ActionMenu>
                      <ActionMenu.Duplicate onClick={handleDuplicate(libraryJob)} />
                      <ActionMenu.Delete onClick={handleRemove(libraryJob)} />
                    </ActionMenu>
                  )
                : undefined
            }
            isResizable
            displayComponentIndicator
          />
        }
      />

      {isImportOpen && (
        <SpreadsheetImport
          onClose={onCloseImport}
          onSubmit={onSubmitImport}
          fields={importFields}
          rowHook={importRowHook}
        />
      )}

      <SpreadsheetImportingModal {...importingModal} />
      <LibraryJobAddModal modal={creationModal} onLibraryJobAdded={libraryJobs.refetch} />

      {selectedLibraryJob && (
        <>
          <LibraryJobDeleteModal
            key={selectedLibraryJob.id}
            libraryJobToRemove={selectedLibraryJob}
            modal={removeModal}
            onLibraryJobRemoved={libraryJobs.refetch}
          />

          <LibraryJobDuplicateModal
            key={selectedLibraryJob.id}
            libraryJobToDuplicate={selectedLibraryJob}
            modal={duplicateModal}
            onLibraryJobDuplicated={onLibraryJobDuplicated}
            isUnitDisbursementEditable={!selectedLibraryJob?.hasComponents}
          />
        </>
      )}
    </>
  );
};
