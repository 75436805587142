export enum PowensPaymentExecutionDateType {
  FIRST_OPEN_DAY = 'first_open_day',
  INSTANT = 'instant',
  DEFERRED = 'deferred',
}

export enum PowensPaymentAccountSchemeName {
  IBAN = 'iban',
  SORT_CODE_ACCOUNT_NUMBER = 'sort_code_account_number',
}

export enum PowensIdentityKind {
  INDIVIDUAL = 'individual',
  CORPORATE = 'corporate',
}

export enum PowensIdentitySchemeName {
  NATIONAL_ID_NUMBER = 'national_id_number',
  PASSPORT_NUMBER = 'passport_number',
  ALIEN_REG_NUMBER = 'alien_reg_number',
  SIREN = 'siren',
  INCORPORATION_NUMBER = 'incorporation_number',
  EUID = 'euid',
  RNA = 'rna',
}

export enum PowensFieldType {
  TEXT = 'text',
  LIST = 'list',
}

// https://docs.powens.com/api-reference/products/payments/payments#paymentstate-values
export enum PAYMENT_SATE_VALUES {
  /**
   * Intermediate state
   * The payment request was registered, but not yet validated.
   */
  created = 'created',

  /**
   * The payment request was validated and initialized with the bank. The validate_uri is available.
   * Intermediate state
   */
  validating = 'validating',

  /**
   * The payment request has been validated on the bank website, but execution has not been confirmed yet.
   * Intermediate state
   */
  pending = 'pending',

  /**
   * The payment request has been rejected by the bank or the user. The error_code is available.
   * Final state
   */
  rejected = 'rejected',

  /**
   * The payment has been executed and confirmed by the bank.
   * Final state
   */
  done = 'done',

  /**
   * The payment has passed acceptance checks from the banks and is awaiting execution. However the bank will not give us further update on the payment state.
   * Final state
   */
  accepted = 'accepted',

  /**
   * The payment has automatically expired because it did not have a definitive status (ie done or rejected) N days after its execution. N value is configurable with the payment.manual_expire_time_days configuration key.
   * Final sate
   */
  expired = 'expired',

  /**
   * (Only for bulk payments) At least one instruction was rejected, and at least one instruction was either done or accepted.
   * Final state
   */
  partial = 'partial',
}

// https://docs.powens.com/api-reference/products/payments/payments#paymentaction-values
export enum PAYMENT_ACTION_VALUES {
  /**
   * The end user must complete a redirect flow, for which the URL is present in validate_uri.
   */
  redirect = 'redirect',

  /**
   * A validation is required from the end user on a side channel, e.g. a mobile app or on a link sent in an email or an SMS.
   */
  decoupled = 'decoupled',

  /**
   * Values for one or more fields are required from the end user. Such fields are provided in fields.
   */
  additionalInformationNeeded = 'additionalInformationNeeded',

  /**
   * A confirmation is required from the client.
   */
  confirm = 'confirm',

  /**
   * A validation is required from the end user.
   */
  psuValidation = 'psuValidation',
}

// https://docs.powens.com/api-reference/products/payments/payments#paymenterrorcode-values
export enum PAYMENT_ERROR_CODE_VALUES {
  /**
   * Identification of the emitter account is invalid, or transfer emission is not possible.
   */
  invalidEmitter = 'invalidEmitter',

  /**
   * The balance of the emitter account is too low.
   */
  insufficientFunds = 'insufficientFunds',

  /**
   * The amount of the payment request is invalid or out of the authorized bounds.
   */
  invalidAmount = 'invalidAmount',

  /**
   * The currency of the payment request is invalid or unsupported.
   */
  invalidCurrency = 'invalidCurrency',

  /**
   * The execution date of the payment request is invalid or out of the supported bounds.
   */
  invalidDate = 'invalidDate',

  /**
   * The beneficiary identification is invalid or unsupported.
   */
  invalidBeneficiary = 'invalidBeneficiary',

  /**
   * The label of the payment request does not match the constraints enforced by the bank.
   */
  invalidLabel = 'invalidLabel',

  /**
   * The request was aborted by the bank for regulatory reason (e.g. fraud detection).
   */
  regulatoryReason = 'regulatoryReason',

  /**
   * The request was aborted because the end-user failed to identify with the bank.
   */
  authenticationFailed = 'authenticationFailed',

  /**
   * The payment request expired without any interaction with the end-user.
   */
  noAnswerFromCustomer = 'noAnswerFromCustomer',

  /**
   * The payment request was rejected by the bank without any reason.
   */
  noSpecifiedReason = 'noSpecifiedReason',

  /**
   * The payment was explicitly cancelled by the end-user.
   */
  CancelledByUser = 'CancelledByUser',

  /**
   * The payment has been cancelled through the API.
   */
  CancelledByClient = 'CancelledByClient',

  /**
   * The payment confirmation was refused by the client.
   */
  confirmationRefused = 'confirmationRefused',

  /**
   * The bank sent an error message to the user.
   */
  bankMessage = 'bankMessage',

  /**
   * The request contains invalid data.
   */
  invalidValue = 'invalidValue',

  /**
   * An action is needed on the website by the user.
   */
  actionNeeded = 'actionNeeded',

  /**
   * The connector is currently unavailable, typically happens if the bank's interface is unreachable.
   */
  websiteUnavailable = 'websiteUnavailable',

  /**
   * A bug has occurred during the request validation.
   */
  bug = 'bug',
}
