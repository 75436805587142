import type { MouseEvent, ReactElement } from 'react';
import { cloneElement, forwardRef } from 'react';
import type { IconProps } from '@chakra-ui/react';
import { Box, CircularProgress } from '@chakra-ui/react';

export interface GraneetIconButtonProps {
  onClick?: (e?: MouseEvent) => void;
  icon: ReactElement<IconProps> | string;
  color: 'ghost' | 'danger' | 'default';
  isActive?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  isDisabled?: boolean;
  isLoading?: boolean;
}

const SIZE_CONFIG = {
  xs: {
    height: '1.3rem',
    width: '1.3rem',
    boxSize: '0.5rem',
  },
  sm: {
    height: '1.75rem',
    width: '1.75rem',
    boxSize: '0.75rem',
  },
  md: {
    height: '2rem',
    width: '2rem',
    boxSize: '0.875rem',
  },
  lg: {
    height: '2.5rem',
    width: '2.5rem',
    boxSize: '1.125rem',
  },
} as const;

const COLORS_CONFIG = {
  ghost: {
    color: '#6C737F',
    activeColor: '#6C737F',
    background: 'transparent',
    hoverBackground: 'rgba(0, 21, 63, 0.05)',
    activeBackground: 'rgba(0, 21, 63, 0.05)',
    hoverActiveBackground: 'rgba(0, 19, 58, 0.10)',
  },
  default: {
    color: '#15181A',
    activeColor: '#1F2A37',
    background: 'linear-gradient(180deg, #FCFCFD 0%, #F9FAFB 100%)',
    hoverBackground: 'rgba(0, 21, 63, 0.05)',
    activeBackground: 'rgba(0, 21, 63, 0.05)',
    hoverActiveBackground: 'rgba(0, 19, 58, 0.10)',
  },
  danger: {
    color: '#D92D20',
    activeColor: '#D92D20',
    background: 'transparent',
    hoverBackground: '#FEE4E2',
    activeBackground: 'transparent',
    hoverActiveBackground: '#FEE4E2',
  },
} as const;

export const GraneetIconButton = forwardRef<HTMLInputElement, GraneetIconButtonProps>(
  ({ icon, size = 'md', isActive = false, color = 'ghost', onClick, isDisabled = false, isLoading = false }, ref) => {
    const textColor = !isActive ? COLORS_CONFIG[color].color : COLORS_CONFIG[color].activeColor;
    const backgroundColor = !isActive ? COLORS_CONFIG[color].background : COLORS_CONFIG[color].activeBackground;
    const hoverBackgroundColor = !isActive
      ? COLORS_CONFIG[color].hoverBackground
      : COLORS_CONFIG[color].hoverActiveBackground;

    const { height, width, boxSize } = SIZE_CONFIG[size];

    const iconElement =
      typeof icon === 'string' ? <i className={icon} /> : cloneElement(icon, { boxSize, stroke: textColor });

    return (
      <Box
        ref={ref}
        height={height}
        width={width}
        display="flex"
        alignItems="center"
        justifyContent="center"
        color={textColor}
        borderRadius="0.5rem"
        opacity={isDisabled ? 0.5 : 1}
        background={backgroundColor}
        border={color === 'default' ? '0.063rem solid transparent' : undefined}
        boxShadow={
          color === 'default'
            ? '0px 2px 4px 0px rgba(17, 24, 28, 0.04), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 0px 0px 1px rgba(17, 24, 28, 0.08)'
            : undefined
        }
        _hover={{
          background: hoverBackgroundColor,
        }}
        _groupHover={{
          background: hoverBackgroundColor,
        }}
        onClick={isDisabled ? undefined : onClick}
        // eslint-disable-next-line no-nested-ternary
        cursor={isDisabled ? 'not-allowed' : onClick ? 'pointer' : 'default'}
      >
        {isLoading ? <CircularProgress size={boxSize} isIndeterminate color={textColor} /> : iconElement}
      </Box>
    );
  },
);
