import type { ReactNode, FC } from 'react';
import { useMemo, createContext, useState, useCallback, useContext } from 'react';

interface EmailContextType {
  emailSent: boolean;
  handleEmailSent: () => void;
  resetEmailSent: () => void;
}

const initialValue: EmailContextType = {
  emailSent: false,
  handleEmailSent: () => {},
  resetEmailSent: () => {},
};

const EmailContext = createContext<EmailContextType>(initialValue);

export const EmailProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [emailSent, setEmailSent] = useState(false);

  const handleEmailSent = useCallback(() => {
    setEmailSent(true);
  }, []);

  const resetEmailSent = useCallback(() => {
    setEmailSent(false);
  }, []);

  const contextValue = useMemo(
    () => ({
      emailSent,
      handleEmailSent,
      resetEmailSent,
    }),
    [emailSent, handleEmailSent, resetEmailSent],
  );

  return <EmailContext.Provider value={contextValue}>{children}</EmailContext.Provider>;
};

export const useEmail = () => useContext(EmailContext);
