import { useEffect } from 'react';

import { getEnvValue } from 'config/env';

export const useUmami = () => {
  const REACT_APP_ENV = getEnvValue('REACT_APP_ENV', false);
  const REACT_APP_UMAMI_ID = getEnvValue('REACT_APP_UMAMI_ID', false);
  const REACT_APP_UMAMI_URL = getEnvValue('REACT_APP_UMAMI_URL', false);

  useEffect(() => {
    if (REACT_APP_ENV && REACT_APP_UMAMI_ID && REACT_APP_UMAMI_URL) {
      const script = document.createElement('script');
      script.src = REACT_APP_UMAMI_URL;
      script.defer = true;
      script.setAttribute('data-website-id', REACT_APP_UMAMI_ID);
      document.body.appendChild(script);
    }
  }, [REACT_APP_ENV, REACT_APP_UMAMI_ID, REACT_APP_UMAMI_URL]);
};
