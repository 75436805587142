import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import '../styles/ag-grid.css';

import { useCallback, useMemo, useRef, useState } from 'react';
import type { QuoteNodeObject } from '@org/quotation-lib';
import { AgGridReact } from '@ag-grid-community/react';
import { Box, Flex } from '@chakra-ui/react';
import type {
  BodyScrollEndEvent,
  BodyScrollEvent,
  CellContextMenuEvent,
  ColumnResizedEvent,
  DragStartedEvent,
  GridReadyEvent,
  IsGroupOpenByDefaultParams,
  RowDataUpdatedEvent,
  SelectionChangedEvent,
  CellFocusedEvent,
  CellEditingStartedEvent,
} from '@ag-grid-community/core';
import type { MultiLevelMenuHandle, MenuElement } from '@graneet/lib-ui';
import { MultiLevelMenu, useHotkeys } from '@graneet/lib-ui';
import { v4 as uuid } from 'uuid';

import { QuoteAGGridType } from '../constants/agGridConstants';
import { useStatusBar } from '../hooks/status-bar/useStatusBar';
import { useContextMenu } from '../hooks/context-menu/useContextMenu';
import { useGetRowClass } from '../hooks/get-row-style/useGetRowClass';
import { useRowHeight } from '../hooks/row-height/use-row-height';

import { QuoteToolBar } from './toolbar/QuoteToolBar';

import { useEditUserSettings } from 'features/user/services/user.api';
import { useStore } from 'store/store';
import { useColumnDefs } from 'features/quotation/ag-grid-quote/hooks/column-def/useColumnDefs';
import { useAutoGroupColumnDef } from 'features/quotation/ag-grid-quote/hooks/auto-group-column-def/useAutoGroupColumnDef';
import { useOnRowDragEnd } from 'features/quotation/ag-grid-quote/hooks/on-row-drag/useOnRowDragEnd';
import { useOnRowDragMove } from 'features/quotation/ag-grid-quote/hooks/on-row-drag/useOnRowDragMove';
import { useDefaultColDef } from 'features/quotation/ag-grid-quote/hooks/default-col-def/useDefaultColDef';
import { useGetDataPath } from 'features/quotation/ag-grid-quote/hooks/get-data-path/useGetDataPath';
import { useRowDragText } from 'features/quotation/ag-grid-quote/hooks/row-drag-text/useRowDragText';
import { useOnRowDragLeave } from 'features/quotation/ag-grid-quote/hooks/on-row-drag/useOnRowDragLeave';
import { useGridOption } from 'features/quotation/ag-grid-quote/hooks/grid-option/useGridOption';
import { sortQuoteNodeObjectByAutomaticIndex } from 'features/quotation/quote-node/utils/sortQuoteNodeObjectByAutomaticIndex';
import {
  appendQuoteExpandedRow,
  updateQuoteGridRef,
  updateQuoteNbSelectedRows,
  updateQuoteNewlyCreatedNodeId,
  updateQuoteRootColumns,
  updateQuoteRootSelectedRows,
} from 'features/quotation/quote-common/store/quoteUpdateZustand';
import { useQuoteSettingsGetFromStoreOrThrow } from 'features/quotation/quote-common/hooks/useQuoteSettingsGetFromStore';
import { useDebounce } from 'features/common/hooks/useDebounce';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

interface AgGridQuoteRootProps {
  onGridReady: (params: GridReadyEvent) => void;
}

export const AgGridQuoteRoot = ({ onGridReady }: AgGridQuoteRootProps) => {
  const columnWidth = useRef<number>(0);
  const [className, setClassName] = useState<string>('');
  const [isReady, setIsReady] = useState(false);
  const [contextMenuItems, setContextMenuItems] = useState<MenuElement[]>([]);

  const contextMenuRef = useRef<MultiLevelMenuHandle>(null);
  const gridRef = useRef<AgGridReact<QuoteNodeObject>>(null);

  const hiddenCostGridRef = useStore((state) => state.quoteHiddenCostGridRef);
  const { quoteRootColumns, quoteSetting } = useQuoteSettingsGetFromStoreOrThrow();
  const quoteNode = useStore((state) => state.quoteNode);
  const quoteExpandedRows = useStore((state) => state.quoteExpandedRows);
  const quoteCollapsedRows = useStore((state) => state.quoteCollapsedRows);
  const isEditable = useStore((store) => store.quoteEditable);
  const quoteNewlyCreatedNodeId = useStore((state) => state.quoteNewlyCreatedNodeId);
  const { quote } = useQuote();

  const editUserSettingsMutation = useEditUserSettings(false);

  const quoteNodeSort = useMemo(
    () => sortQuoteNodeObjectByAutomaticIndex(Array.from(quoteNode?.values() || [])),
    [quoteNode],
  );

  const contextMenuDef = useContextMenu();

  const autoGroupColumnDef = useAutoGroupColumnDef();
  const columnDefs = useColumnDefs(QuoteAGGridType.ROOT);
  const defaultColDef = useDefaultColDef();
  const getDataPath = useGetDataPath();
  const getRowClass = useGetRowClass();
  const gridOption = useGridOption();
  const onRowDragEnd = useOnRowDragEnd();
  const onRowDragLeave = useOnRowDragLeave();
  const onRowDragMove = useOnRowDragMove(gridRef);
  const rowDragText = useRowDragText();
  const statusBar = useStatusBar();
  const rowHeight = useRowHeight();

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      if (event.source !== 'apiSelectAll') {
        hiddenCostGridRef?.current?.api.deselectAll();
      }
      const selectedRows: any[] = event.api.getSelectedRows();
      if (selectedRows.length > 0) {
        event.api.stopEditing();
      }
      updateQuoteNbSelectedRows(selectedRows.length);
      updateQuoteRootSelectedRows(selectedRows);
    },
    [hiddenCostGridRef],
  );

  const saveColumns = useDebounce(
    async (data: string[]) => {
      const [quoteRootColumnsJSON] = data;
      await editUserSettingsMutation.mutateAsync({ quoteRootColumnsJSON });
    },
    200,
    { leading: false, trailing: true },
  );

  const handleColumnResized = useCallback(
    async (event: ColumnResizedEvent) => {
      if (event.finished) {
        const { column } = event;
        if (!column) {
          return;
        }
        const newColumns = quoteRootColumns.map((col) => {
          if (col.name === column.getColId()) {
            return {
              ...col,
              width: column.getActualWidth(),
            };
          }
          return col;
        });
        const quoteRootColumnsJSON = JSON.stringify(newColumns);
        saveColumns(quoteRootColumnsJSON);
        updateQuoteRootColumns(JSON.stringify(newColumns));
      }
    },
    [saveColumns, quoteRootColumns],
  );

  const handleCloseMenu = useCallback(() => {
    setContextMenuItems([]);
    contextMenuRef.current?.close();
  }, []);

  useHotkeys('esc', handleCloseMenu);

  const handleCellClicked = useCallback(
    (event: CellContextMenuEvent) => {
      const { data, node } = event;
      event.event?.preventDefault();
      event.event?.stopPropagation();
      if (node.footer || !isEditable) {
        return;
      }
      if (!data) {
        return;
      }
      handleCloseMenu();
      if (contextMenuRef.current) {
        setContextMenuItems(contextMenuDef(contextMenuRef.current!.close, data));
        const e = event.event as MouseEvent;
        contextMenuRef.current!.open(e?.clientX, e?.clientY);
      }
    },
    [contextMenuDef, handleCloseMenu, isEditable],
  );

  const handleOnCloseContextMenu = useCallback(() => {
    setContextMenuItems([]);
  }, []);

  const onRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent<QuoteNodeObject>) => {
      setTimeout(() => {
        if (quoteNewlyCreatedNodeId) {
          const node = quote.getTree().getNodeOrNull(quoteNewlyCreatedNodeId);
          updateQuoteNewlyCreatedNodeId(null);
          if (node) {
            const parentId = node.getParent()?.getId();
            if (parentId) {
              appendQuoteExpandedRow(parentId);
              event.api.forEachNode((n) => {
                if (n.data?.id === parentId) {
                  event.api.setRowNodeExpanded(n, true, false);
                }
              });
            }
          }
          setTimeout(() => {
            event.api.forEachNode((n) => {
              if (n.data?.id === quoteNewlyCreatedNodeId && n.rowIndex !== null) {
                setTimeout(() => {
                  event.api.startEditingCell({
                    rowIndex: n.rowIndex!,
                    colKey: 'denomination',
                  });
                }, 10);
              }
            });
          }, 0);
        }
      }, 10);
    },
    [quote, quoteNewlyCreatedNodeId],
  );

  const onCellValueChanged = useCallback((event: CellFocusedEvent) => {
    const focusedCell = event.api.getFocusedCell();
    if (focusedCell === null) return;
    if (event.column === null) return;
    const columnId = typeof event.column === 'string' ? event.column : event.column.getColId();
    if (focusedCell.column.getColId() === columnId && focusedCell.rowIndex === event.rowIndex) {
      event.api.setFocusedCell(focusedCell.rowIndex, columnId);
      return;
    }
    setTimeout(() => {
      event.api.startEditingCell({
        rowIndex: focusedCell.rowIndex,
        colKey: focusedCell.column,
      });
    }, 10);
  }, []);

  const handleColumnMoved = useCallback(async () => {
    const gridColumns = gridRef.current?.api.getAllGridColumns();

    const newColumns = quoteRootColumns.map((col) => {
      const columnIndex = (gridColumns ?? []).findIndex((column) => column.getColId() === col.name);

      if (columnIndex !== -1) {
        return {
          ...col,
          index: columnIndex - 3,
        };
      }
      return col;
    });

    const quoteRootColumnsJSON = JSON.stringify(newColumns);
    updateQuoteRootColumns(quoteRootColumnsJSON);
    saveColumns(quoteRootColumnsJSON);
  }, [saveColumns, quoteRootColumns]);

  const onBodyScroll = useCallback((event: BodyScrollEvent) => {
    if (event.left === 0) {
      setClassName('no-scroll-left');
    } else {
      setClassName('');
    }
  }, []);

  const onBodyScrollEnd = useCallback((event: BodyScrollEndEvent) => {
    const columnsWidth =
      event.api.getColumns()?.reduce((acc, col) => (col.isPinned() ? acc : acc + col.getActualWidth()), 0) ?? 0;
    columnWidth.current = columnsWidth;
    if (event.api.getHorizontalPixelRange().right === columnsWidth) {
      if (event.left === 0) {
        setClassName('no-scroll-right no-scroll-left');
      } else {
        setClassName('no-scroll-right');
      }
    } else if (event.left === 0) {
      setClassName('no-scroll-left');
    } else {
      setClassName('');
    }
  }, []);

  const handleOnGridReady = useCallback(
    (event: GridReadyEvent) => {
      columnWidth.current =
        event.api.getColumns()?.reduce((acc, col) => (col.isPinned() ? acc : acc + col.getActualWidth()), 0) ?? 0;
      if (event.api.getHorizontalPixelRange().left === 0) {
        if (event.api.getHorizontalPixelRange().right === columnWidth.current) {
          setClassName('no-scroll-left no-scroll-right');
        }
        setClassName('no-scroll-left');
      }
      onGridReady(event);
      setIsReady(true);
      updateQuoteGridRef(gridRef);
    },
    [onGridReady],
  );

  const isGroupOpenByDefault = useCallback(
    (params: IsGroupOpenByDefaultParams<QuoteNodeObject>) => {
      const isExpanded = quoteExpandedRows.includes(params.key!);
      const isCollapsed = quoteCollapsedRows.includes(params.key!);
      const node = quote.getTree().getNodeOrNull(params.key!);

      if (isExpanded) {
        return true;
      }
      if (isCollapsed) {
        return false;
      }
      if (node) {
        if (['QuoteBasicItem', 'QuoteOptionalItem', 'QuoteSubItem'].includes(node.getContent().getType() ?? '')) {
          if (node.hasChildren()) {
            if (node.getFirstChild()?.getContent().getType() === 'QuoteComponent') {
              return quoteSetting.isComponentsExpanded;
            }
          }
        }
        return quoteSetting.isAllItemsExpanded;
      }
      return true;
    },
    [quoteCollapsedRows, quoteExpandedRows, quoteSetting.isAllItemsExpanded, quoteSetting.isComponentsExpanded, quote],
  );

  const handleDragStarted = useCallback((event: DragStartedEvent) => {
    event.api.deselectAll();
  }, []);

  const onCellEditingStarted = useCallback((event: CellEditingStartedEvent) => {
    event.api.deselectAll();
  }, []);

  return (
    <Flex direction="column" width="100%" height="100%" minHeight="70%">
      <QuoteToolBar type={QuoteAGGridType.ROOT} api={gridRef?.current?.api || null} />
      <Flex
        className={`${className} ag-theme-quartz quotation ag-custom-quote-root ${!isReady ? 'hidden' : ''}`}
        width="100%"
        flexGrow={1}
      >
        <Box width="100%" height="100%">
          <AgGridReact
            onBodyScrollEnd={onBodyScrollEnd}
            onBodyScroll={onBodyScroll}
            autoGroupColumnDef={autoGroupColumnDef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getDataPath={getDataPath}
            getRowClass={getRowClass}
            gridOptions={gridOption}
            groupDefaultExpanded={-1}
            isGroupOpenByDefault={isGroupOpenByDefault}
            onRowDragEnd={onRowDragEnd}
            reactiveCustomComponents
            onRowDragLeave={onRowDragLeave}
            onRowDragMove={onRowDragMove}
            suppressRowDrag={!isEditable}
            ref={gridRef}
            rowData={quoteNodeSort}
            rowDragText={rowDragText}
            onDragStarted={handleDragStarted}
            treeData
            onGridReady={handleOnGridReady}
            statusBar={statusBar}
            onSelectionChanged={handleSelectionChanged}
            rowSelection="multiple"
            suppressRowClickSelection
            suppressGroupRowsSticky
            animateRows={false}
            onColumnResized={handleColumnResized}
            suppressContextMenu
            onCellContextMenu={handleCellClicked}
            preventDefaultOnContextMenu
            rowHeight={rowHeight}
            singleClickEdit
            onRowDataUpdated={onRowDataUpdated}
            onCellValueChanged={onCellValueChanged}
            suppressMoveWhenRowDragging
            suppressDragLeaveHidesColumns
            suppressColumnMoveAnimation
            allowDragFromColumnsToolPanel
            onColumnMoved={handleColumnMoved}
            onCellEditingStarted={onCellEditingStarted}
          />
        </Box>
      </Flex>
      <MultiLevelMenu items={contextMenuItems} ref={contextMenuRef} onClose={handleOnCloseContextMenu} id={uuid()} />
    </Flex>
  );
};
