import { createContext, useContext } from 'react';

import type { ABSENCE_DURATION, ITimeTableRange, ITimeTableSlot } from '../types';

const THROW_ERROR = () => {
  throw new Error('No TimeTableContext found');
};

export type TimeTableMode = 'default' | 'approve';

interface ITimeTableContext {
  onDelete: (slots: ITimeTableSlot[]) => void;
  onEdit: (slot: ITimeTableSlot, segmentedDate: Date) => void;
  onCreate: (id: string, subEntityId: number | undefined, isOff: boolean, date: Date, range?: ITimeTableRange) => void;
  numberOfDays?: number;
  startDate?: Date;
  currentStartOfWeek?: Date;
  mode: TimeTableMode;
  absenceDurationPossibleValues?: Record<ABSENCE_DURATION, number>;
}

export const TimeTableContext = createContext<ITimeTableContext>({
  onDelete: THROW_ERROR,
  onEdit: THROW_ERROR,
  onCreate: THROW_ERROR,
  numberOfDays: undefined,
  startDate: undefined,
  currentStartOfWeek: undefined,
  mode: 'default',
  absenceDurationPossibleValues: undefined,
});

export const useTimeTableContext = () => useContext(TimeTableContext);
