import type { FC } from 'react';
import { DownloadPdfIcon, DownloadSpreadsheetIcon, GenericFileIcon } from '@graneet/lib-ui';
import type { IconProps } from '@chakra-ui/react';

export enum DOWNLOAD_FILE_TYPE {
  PDF = 'PDF',
  SPREADSHEET = 'SPREADSHEET',
}

export type DownloadFileIconProps = IconProps & { type: DOWNLOAD_FILE_TYPE };

export const DownloadFileIcon: FC<DownloadFileIconProps> = ({ type, ...rest }) => {
  switch (type) {
    case DOWNLOAD_FILE_TYPE.PDF:
      return <DownloadPdfIcon {...rest} color="red.500" />;

    case DOWNLOAD_FILE_TYPE.SPREADSHEET:
      return <DownloadSpreadsheetIcon {...rest} />;

    default:
      return <GenericFileIcon {...rest} />;
  }
};
