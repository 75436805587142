import type { FC } from 'react';
import { Button, Card, Container, SimpleEditIcon } from '@graneet/lib-ui';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { QuotePreviewLabelCard } from 'features/quote/components/cards/QuotePreviewLabelCard';
import {
  useCompanyQuoteValidityDuration,
  useQuoteDefaultAutomaticNumbering,
} from 'features/company/services/company.api';
import { QuoteEditAutoNumberingModal } from 'features/quote/components/QuoteEditAutoNumberingModal';
import { QuoteEditValidityDurationModal } from 'features/quote/components/modals/QuoteEditValidityDurationModal';

export const QuoteSettingsTab: FC = () => {
  const { t } = useTranslation(['global', 'quote']);

  const quoteDurationValidityModal = useDisclosure();
  const quoteAutoNumberingModal = useDisclosure();

  const companyQuoteValidityDuration = useCompanyQuoteValidityDuration();
  const quoteDefaultAutomaticNumbering = useQuoteDefaultAutomaticNumbering();

  return (
    <Container size="sm">
      <Box maxW="40rem">
        <Card
          title={t('quote:settings.validityDuration.title')}
          pt={2}
          mb={8}
          topRightContent={
            <Button variant="outline" onClick={quoteDurationValidityModal.onOpen} leftIcon={<SimpleEditIcon />}>
              {t('global:words.c.update')}
            </Button>
          }
        >
          <QuotePreviewLabelCard
            variant="emphasis"
            number={companyQuoteValidityDuration.data.quoteValidityDuration}
            translationKey="quote:settings.validityDuration.previewLabel"
          />
        </Card>
      </Box>

      <Box maxW="40rem">
        <Card
          title={t('quote:settings.autoNumbering.title')}
          pt={2}
          mb={8}
          topRightContent={
            <Button variant="outline" onClick={quoteAutoNumberingModal.onOpen} leftIcon={<SimpleEditIcon />}>
              {t('global:words.c.update')}
            </Button>
          }
        >
          <QuotePreviewLabelCard
            variant="emphasis"
            isActivated={
              quoteDefaultAutomaticNumbering.data.defaultQuoteAutomaticNumbering
                ? t('quote:settings.autoNumbering.activated')
                : t('quote:settings.autoNumbering.disabled')
            }
            translationKey="quote:settings.autoNumbering.previewLabel"
          />
        </Card>
      </Box>

      <QuoteEditValidityDurationModal
        isOpen={quoteDurationValidityModal.isOpen}
        onClose={quoteDurationValidityModal.onClose}
        validityDurationInitialValue={companyQuoteValidityDuration.data.quoteValidityDuration}
      />

      <QuoteEditAutoNumberingModal
        defaultValue={quoteDefaultAutomaticNumbering.data.defaultQuoteAutomaticNumbering}
        isOpen={quoteAutoNumberingModal.isOpen}
        onClose={quoteAutoNumberingModal.onClose}
      />
    </Container>
  );
};
