import { Box } from '@chakra-ui/react';
import type { FC, PropsWithChildren } from 'react';

export interface OptionBadgeProps extends PropsWithChildren {
  onClick?: () => void;
}

export const OptionBadge: FC<OptionBadgeProps> = ({ children, onClick }) => (
  <Box
    overflow="hidden"
    color="#6941C6"
    textOverflow="ellipsis"
    fontFamily="body"
    fontSize="0.688rem"
    fontStyle="normal"
    fontWeight={600}
    lineHeight="1.25rem"
    borderRadius="0.875rem"
    background="#F4EBFF"
    px="0.375rem"
    _hover={
      onClick
        ? {
            background: '#E9D7FE',
          }
        : {}
    }
    onClick={onClick}
    cursor={onClick ? 'pointer' : 'default'}
  >
    {children}
  </Box>
);
