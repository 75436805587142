import type { IProject } from '@graneet/business-logic';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import { Button, Card, GroupedBarChart, CustomTooltipChart, SimpleChevronLeftIcon } from '@graneet/lib-ui';
import type { TooltipProps } from 'recharts';

import { useProjectBudgetAnalysisStats } from '../../services/project.api';

import { ProjectStatsEmptyState } from './ProjectStatsEmptyState';

const OFFSET_SIZE = 3;
const NUMBER_OF_BAR_DISPLAYED = 3;

interface ProjectBudgetAnalysisStatsCardProps {
  project: IProject;

  numberOfBudgets: number;
}

export const ProjectBudgetAnalysisStatsCard: FC<ProjectBudgetAnalysisStatsCardProps> = ({
  project,
  numberOfBudgets,
}) => {
  const { t } = useTranslation(['global', 'project']);

  const projectBudgetAnalysisStats = useProjectBudgetAnalysisStats(project.id);

  const [offset, setOffset] = useState<number>(0);
  const changeOffset = useCallback(
    (incrementFactor: -1 | 1) => {
      setOffset((previousValue) => {
        const numberOfBudgetTypesLength = projectBudgetAnalysisStats.data.barChartData.length;
        const newOffset = previousValue + incrementFactor * OFFSET_SIZE;

        if (newOffset + NUMBER_OF_BAR_DISPLAYED > numberOfBudgetTypesLength) {
          return numberOfBudgetTypesLength - NUMBER_OF_BAR_DISPLAYED;
        }
        if (newOffset < 0) {
          return 0;
        }
        return newOffset;
      });
    },
    [projectBudgetAnalysisStats.data.barChartData.length],
  );

  const cardProps = useMemo(
    () => ({
      title: (
        <Trans t={t} i18nKey="project:projectBudgetAnalysisStatsCard.title">
          description_start
          <Text as="span" color="gray.500">
            displayed_data
          </Text>
          description_end
        </Trans>
      ),
      minH: '22rem',
    }),
    [t],
  );

  const legends = useMemo(
    () => ({
      budgetAmountExVAT: t('project:projectBudgetAnalysisStatsCard.budget'),
      orderAmountExVATOrWorkforce: t('project:projectBudgetAnalysisStatsCard.ordersOrWorkforce'),
      supplierInvoiceAmountExVAT: t('project:projectBudgetAnalysisStatsCard.supplierInvoices'),
    }),
    [t],
  );

  const tooltip = useCallback(
    ({ payload, active, label }: TooltipProps<number | string, string>) => {
      const legendsWithTooltipTexts = {
        budgetAmountExVAT: t('project:projectBudgetAnalysisStatsCard.budget'),
        orderAmountExVAT: t('project:projectBudgetAnalysisStatsCard.orders'),
        orderAmountExVATOrWorkforce: t('project:projectBudgetAnalysisStatsCard.ordersOrWorkforce'),
        supplierInvoiceAmountExVAT: t('project:projectBudgetAnalysisStatsCard.supplierInvoices'),
        workforce: t('project:projectBudgetAnalysisStatsCard.workforce'),
      };

      if (payload && payload[0]?.payload.isWorkforce === 'true') {
        const formattedPayload = [payload[0], { ...payload[1], name: 'workforce' }];

        return (
          <CustomTooltipChart
            payload={formattedPayload}
            active={active}
            legends={legendsWithTooltipTexts}
            label={label}
          />
        );
      }

      const formattedPayload = [...(payload || [])];
      formattedPayload[1] = { ...formattedPayload[1], name: 'orderAmountExVAT' };
      return (
        <CustomTooltipChart
          payload={formattedPayload}
          active={active}
          legends={legendsWithTooltipTexts}
          label={label}
        />
      );
    },
    [t],
  );

  if (numberOfBudgets === 0) {
    return (
      <Card {...cardProps}>
        <ProjectStatsEmptyState project={project} />
      </Card>
    );
  }

  const currentLastIndex = offset + NUMBER_OF_BAR_DISPLAYED;
  const dataDisplayed = projectBudgetAnalysisStats.data.barChartData
    .map(({ name, budgetAmountExVAT, orderAmountExVAT, supplierInvoiceAmountExVAT, isWorkforce }) => ({
      name,
      budgetAmountExVAT,
      orderAmountExVATOrWorkforce: orderAmountExVAT,
      supplierInvoiceAmountExVAT,
      isWorkforce: isWorkforce ? 'true' : 'false',
    }))
    .slice(offset, currentLastIndex);

  const cannotGoBefore = offset === 0;
  const cannotGoAfter = currentLastIndex >= projectBudgetAnalysisStats.data.barChartData.length;

  return (
    <Card
      {...cardProps}
      topRightContent={
        <Box>
          <Button size="xs" variant="outline" p={1} isDisabled={cannotGoBefore} onClick={() => changeOffset(-1)}>
            <SimpleChevronLeftIcon fontSize="lg" />
          </Button>

          <Button size="xs" variant="outline" p={1} ml={1} isDisabled={cannotGoAfter} onClick={() => changeOffset(1)}>
            <SimpleChevronLeftIcon transform="rotate(180deg)" fontSize="lg" />
          </Button>
        </Box>
      }
    >
      <GroupedBarChart
        data={dataDisplayed}
        columns={[
          {
            name: 'budgetAmountExVAT',
            color: 'blue.200',
          },
          {
            name: 'orderAmountExVATOrWorkforce',
            color: 'purple.200',
          },
          {
            name: 'supplierInvoiceAmountExVAT',
            color: 'red.200',
          },
        ]}
        xAxis={{
          dataKey: 'name',
          typeFormat: 'string',
        }}
        yAxis={{
          typeFormat: 'money',
        }}
        legends={legends}
        tooltip={tooltip}
      />
    </Card>
  );
};
