import type { IconProps } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import type { FC } from 'react';

export const OptionIcon: FC<IconProps> = ({ ...props }) => (
  <Icon viewBox="0 0 16 16" fill="transparent" {...props}>
    <rect x="1" y="1" width="14" height="14" rx="3" stroke="#BCCCDC" strokeWidth="2" />
    <rect x="3" y="6.5" width="10" height="3" rx="1.5" fill="#BCCCDC" />
  </Icon>
);
