import type { FC } from 'react';
import { AmountSummary } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IProject, ISubProject } from '@graneet/business-logic';
import { VAT_TYPE, computeProjectVATDistribution } from '@graneet/business-logic';

interface ProjectAmountSummaryProps {
  project: IProject;

  subProjects: Pick<ISubProject, 'hasReversalOfLiability' | 'contractsVATDistribution'>[];
}

export const ProjectAmountSummary: FC<ProjectAmountSummaryProps> = ({ project, subProjects }) => {
  const { t } = useTranslation(['contracts']);

  const vatDistribution = computeProjectVATDistribution(subProjects);
  const hasReversalOfLiability = subProjects.every((subProject) => subProject.hasReversalOfLiability);

  return (
    <AmountSummary>
      <AmountSummary.Item important label={t('contracts:totalProjectExVAT')} amount={project.amountExVAT} />
      <AmountSummary.VATDistribution
        vatType={hasReversalOfLiability ? VAT_TYPE.REVERSE_CHARGE : VAT_TYPE.NORMAL}
        vatDistribution={vatDistribution}
        withCustomDiscountsLabel={hasReversalOfLiability && !!vatDistribution.length}
      />
      <AmountSummary.Item
        important
        contrasted
        label={t('contracts:totalProjectIncVAT')}
        amount={project.amountIncVAT}
      />
    </AmountSummary>
  );
};
