import type { FC, ReactElement, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { APP_FOOTER_PLACEHOLDER, Placeholder } from '../../Placeholder';
import { FOOTER_HEIGHT } from '../../Wizard/Footer';
import type { TableSelectBannerState } from '../contexts/TableSelectionContext.context';
import { useTableSelectionContext } from '../contexts/TableSelectionContext.context';
import { defaultPaginatedDataContext, usePaginatedDataContext } from '../../PaginatedData';

export interface RenderActionsProps<Data extends unknown> {
  selectedItems: Data[];

  currentFilters: URLSearchParams;

  resetSelectedCheckboxes(): void;

  hasAllCheckboxesSelected: boolean;

  totalCount?: number;
}

export interface TableSelectBannerChildrenProps<Data extends unknown> {
  selectedItems: Data[];

  currentFilters: URLSearchParams;

  resetSelectedCheckboxes?: () => void;

  hasAllCheckboxesSelected: boolean;

  totalCount?: number;
}

export interface TableSelectBannerProps<Data extends unknown> {
  selectLabel(numberOfSelectedItems: number): string;

  allSelectedLabel(totalNumberOfItems?: number): string;

  children?(props: TableSelectBannerChildrenProps<Data>): ReactNode;

  renderActions: FC<RenderActionsProps<Data>>;
}

/**
 * Banner displayed when there is selected items
 */
export const TableSelectBanner = <Data extends unknown>({
  selectLabel,
  allSelectedLabel,
  children,
  renderActions: RenderActions,
}: TableSelectBannerProps<Data>): ReactElement | null => {
  const tableSelectionContext = useTableSelectionContext();
  const paginatedDataContext = usePaginatedDataContext();
  const { storage: currentFilters, totalCount } = paginatedDataContext;
  const isInPaginatedContext = paginatedDataContext !== defaultPaginatedDataContext;

  const [{ selectedItems, selectAll, numberOfRegisteredCheckboxes }, setBannerState] = useState<
    TableSelectBannerState<Data>
  >({
    selectedItems: [],
    selectAll: false,
    numberOfRegisteredCheckboxes: 0,
  });
  useEffect(() => {
    tableSelectionContext.registerSelectBanner(setBannerState);
    return () => tableSelectionContext.unregisterSelectBanner();
  }, [tableSelectionContext]);

  const resetSelectedCheckboxes = useCallback(() => {
    tableSelectionContext.updateAllCheckboxesDisplayed(false);
  }, [tableSelectionContext]);

  const numberSelectedItems = selectedItems.length;

  if (numberSelectedItems === 0) {
    return null;
  }

  const hasAllDisplayedCheckboxesSelected = numberOfRegisteredCheckboxes === numberSelectedItems;
  const hasAllCheckboxesSelected =
    selectAll ||
    (isInPaginatedContext
      ? !paginatedDataContext.hasMore && hasAllDisplayedCheckboxesSelected
      : hasAllDisplayedCheckboxesSelected);

  return (
    <Placeholder.Inject name={APP_FOOTER_PLACEHOLDER}>
      <Flex
        px={6}
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        h={FOOTER_HEIGHT}
        boxShadow="sm-reversed"
      >
        <Text alignSelf="center" fontSize={14}>
          {hasAllCheckboxesSelected ? allSelectedLabel(totalCount) : selectLabel(numberSelectedItems)}
        </Text>

        <Flex gap={2} height="1.75rem">
          {children?.({ selectedItems, currentFilters, resetSelectedCheckboxes, hasAllCheckboxesSelected })}

          <RenderActions
            selectedItems={selectedItems}
            currentFilters={currentFilters}
            resetSelectedCheckboxes={resetSelectedCheckboxes}
            hasAllCheckboxesSelected={hasAllCheckboxesSelected}
            totalCount={totalCount}
          />
        </Flex>
      </Flex>
    </Placeholder.Inject>
  );
};
