import type { FC } from 'react';
import { useLayoutEffect, useMemo } from 'react';
import type { ButtonColorScheme, PaginatedTableProps } from '@graneet/lib-ui';
import { PaginatedTable, formatDateOrEmpty, Button, ListingLayout, SimpleBookIcon } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { AccountingExportPaginatedResponse } from '@graneet/business-logic';
import { ACCOUNTING_JOURNAL, PERMISSION } from '@graneet/business-logic';
import { useDisclosure } from '@chakra-ui/react';

import { AccountingTableMenu } from '../../features/accounting/components/AccountingTable/AccountingTableMenu';
import { AccountingExportBadge } from '../../features/accounting/components/AccountingExportBadge';

import { usePermissions } from 'features/role/hooks/usePermissions';
import { useAccountingExports } from 'features/accounting/services/accounting-export.api';
import { AccountingExportGenerateModal } from 'features/accounting/components/AccountingExportGenerateModal/AccountingExportGenerateModal';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

export const ViewAccountingExportsScreen: FC = () => {
  const { t } = useTranslation(['accounting', 'global']);
  const { updateHeaderTitle } = useHeaderContext();

  const modal = useDisclosure();

  const accountingExports = useAccountingExports();

  const hasAccountingGenerationPermission = usePermissions([PERMISSION.GENERATE_ACCOUNTING]);

  const availableStatuses = useMemo(
    () => [
      { value: 'true', colorScheme: 'green' as ButtonColorScheme, label: t('accounting:statuses.exported') },
      { value: 'false', colorScheme: 'red' as ButtonColorScheme, label: t('accounting:statuses.cancelled') },
    ],
    [t],
  );

  const availableJournals = useMemo(
    () =>
      (Object.keys(ACCOUNTING_JOURNAL) as Array<keyof typeof ACCOUNTING_JOURNAL>).map((journal) => ({
        value: journal,
        colorScheme: 'gray' as ButtonColorScheme,
        label: t(`accounting:type.${journal}`),
      })),
    [t],
  );

  const columnDefs = useMemo<PaginatedTableProps<AccountingExportPaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'createdAt',
        headerName: t('accounting:fields.exportDate'),
        valueFormatter: (v) => formatDateOrEmpty(v.data?.createdAt),
        sortable: true,
      },
      {
        field: 'journal',
        headerName: t('accounting:fields.journal'),
        valueFormatter: (v) => (v.data ? t(`accounting:type.${v.data.journal}`) : ''),
        sortable: true,
      },
      {
        field: 'period' as any,
        headerName: t('accounting:fields.period'),
        valueFormatter: (v) =>
          v.data
            ? t('accounting:fields.periodFromTo', {
                fromDate: formatDateOrEmpty(v.data.fromDate),
                toDate: formatDateOrEmpty(v.data.toDate),
              })
            : '',
      },
      {
        field: 'isActive',
        headerName: t('accounting:fields.status'),
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) => (props.data ? <AccountingExportBadge accountingExport={props.data} /> : null),
        sortable: true,
      },
      {
        field: 'numberOfInvoices',
        headerName: t('accounting:fields.numberOfInvoices'),
        sortable: true,
      },
      {
        field: 'menu' as any,
        headerName: '',
        // eslint-disable-next-line react/no-unstable-nested-components
        cellRenderer: (props) =>
          props.data ? (
            <AccountingTableMenu accountingExport={props.data} onCanceled={accountingExports.refetch} />
          ) : null,
        resizable: false,
        suppressMovable: true,
        width: 20,
      },
    ],
    [accountingExports.refetch, t],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(t('accounting:tabs.accountingExport'), []);
  }, [t, updateHeaderTitle]);

  return (
    <>
      <ListingLayout
        pagination={accountingExports}
        filters={[
          {
            type: 'checkbox',
            name: 'isActive',
            label: t('accounting:fields.status'),
            availableValues: availableStatuses,
          },
          {
            type: 'checkbox',
            name: 'journal',
            label: t('accounting:fields.journal'),
            availableValues: availableJournals,
          },
          {
            type: 'date',
            name: 'createdAt',
            label: t('accounting:fields.exportDate'),
          },
        ]}
        actions={
          hasAccountingGenerationPermission && (
            <Button onClick={modal.onOpen}>{t('accounting:cta.generateAnExport')}</Button>
          )
        }
        content={
          <PaginatedTable
            gridId="accounting-export"
            pagination={accountingExports}
            columnDefs={columnDefs}
            zeroState={{
              icon: <SimpleBookIcon boxSize={45} />,
              label: t('accounting:noAccountingExport'),
            }}
            emptyState={{
              label: t('accounting:noResult'),
            }}
          />
        }
      />

      <AccountingExportGenerateModal
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        onGenerated={accountingExports.refetch}
      />
    </>
  );
};
