import { memo, useCallback } from 'react';
import { bool, number } from 'prop-types';
import { Box } from '@chakra-ui/react';
import { DraggableItem, DropEffect, DropZone, SegmentedDropZone, useDropStyles } from '@graneet/lib-ui';

import { useAddJobInLotAfterJob } from '../../quote-job/hooks/useAddJobInLotAfterJob';
import { QUOTE_ITEM_TYPE } from '../../quote/constants/quotes.constant';
import { useMoveLotAfterOtherLot } from '../hooks/useMoveLotAfterOtherLot';
import { useMoveLotBeforeOtherLot } from '../hooks/useMoveLotBeforeOtherLot';
import { useMoveLotAtEndOfQuote } from '../hooks/useMoveLotAtEndOfQuote';
import { useLot } from '../hooks/useLot';
import { useMoveLotInsideOtherLot } from '../hooks/useMoveLotInsideOtherLot';

import { EditQuoteLotRow } from './EditQuoteLotRow';

import { useQuoteDisplayContext } from 'features/quote/hooks/useQuoteDisplayContext';
import { EditQuoteJobRow } from 'features/quote-job/components/EditQuoteJobRow/EditQuoteJobRow';
import { useStore } from 'store/store';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useMoveJobInsideLot } from 'features/quote-job/hooks/useMoveJobInsideLot';

const DROP_LOT_INSIDE = Symbol('drop-lot-inside');

export const EditQuoteLot = memo(({ lotId, isRootLot, displayHiddenCosts }) => {
  const { hasLotSubLots, hasLotJobs, findRootLotLastSublotId } = useQuoteEditContext();
  const { isReadOnlyView } = useQuoteDisplayContext();

  const moveLotBeforeOtherLot = useMoveLotBeforeOtherLot();
  const moveLotAfterOtherLot = useMoveLotAfterOtherLot();
  const moveLotInsideOtherLot = useMoveLotInsideOtherLot();
  const moveLotAtEndOfQuote = useMoveLotAtEndOfQuote();
  const moveJobInsideLot = useMoveJobInsideLot();
  const addJobInLotAfterJob = useAddJobInLotAfterJob();
  const wrapperLot = useLot(lotId);

  const jobs = wrapperLot?.jobs.filter((job) => job.isHiddenCost === displayHiddenCosts);

  // -- Get a previous/the latest value for a job and a key

  const { startAnotherUpdate, isLotBeforeOtherLot, isLotDescendantOfOtherLot, getJob } = useQuoteEditContext();

  const handleAddNewJobAfterGivenJobId = useCallback(
    async (previousJobId) => {
      const isParentOptional = useStore.getState().optionalLotsTable[lotId.toString()];

      startAnotherUpdate();
      await addJobInLotAfterJob(lotId, previousJobId, isParentOptional, displayHiddenCosts);
    },
    [lotId, startAnotherUpdate, addJobInLotAfterJob, displayHiddenCosts],
  );

  const { BOX } = useDropStyles();

  const canDropLotBeforeLot = useCallback(
    (lotItem) =>
      /*
      FIXME Because this function is executed many times per second (more than 100 times) and computation
      inside it is expensive, we need to cache the result.
     */
      !isLotBeforeOtherLot(lotItem.id, lotId) && !isLotDescendantOfOtherLot(lotId, lotItem.id),
    [isLotBeforeOtherLot, isLotDescendantOfOtherLot, lotId],
  );

  const canDropLotAfterLot = useCallback(
    (lotItem) =>
      /*
      FIXME Because this function is executed many times per second (more than 100 times) and computation
      inside it is expensive, we need to cache the result.
     */
      !isLotBeforeOtherLot(lotId, lotItem.id) && !isLotDescendantOfOtherLot(lotId, lotItem.id),
    [isLotBeforeOtherLot, isLotDescendantOfOtherLot, lotId],
  );

  const canDropLotInsideLot = useCallback(
    (lotItem) =>
      /*
      FIXME Because this function is executed many times per second (more than 100 times) and computation
      inside it is expensive, we need to cache the result.
     */
      !isLotDescendantOfOtherLot(lotId, lotItem.id),
    [isLotDescendantOfOtherLot, lotId],
  );

  const handleDropLotBeforeLot = useCallback(
    async (lotItem) => {
      await moveLotBeforeOtherLot(lotItem.id, lotId);
    },
    [moveLotBeforeOtherLot, lotId],
  );

  const handleDropLotAfterLot = useCallback(
    async (lotItem) => {
      await moveLotAfterOtherLot(lotItem.id, lotId);
    },
    [lotId, moveLotAfterOtherLot],
  );

  const handleDropLotInsideLot = useCallback(
    async (lotItem) => {
      await moveLotInsideOtherLot(lotItem.id, lotId);
    },
    [lotId, moveLotInsideOtherLot],
  );

  const handleDropJobInLot = useCallback(
    async (jobItem) => {
      await moveJobInsideLot(jobItem.id, lotId, displayHiddenCosts);
    },
    [moveJobInsideLot, lotId, displayHiddenCosts],
  );

  const handleDropLotAtEndOfQuote = useCallback(
    async (lotItem) => {
      await moveLotAtEndOfQuote(lotItem.id);
    },
    [moveLotAtEndOfQuote],
  );

  const hasLots = wrapperLot?.subLots.length > 0;
  const hasJobs = wrapperLot?.jobs.length > 0;
  const isExpanded = wrapperLot?.isExpanded;
  const hasChildren = hasJobs || hasLots;

  const canDropJobInSection = useCallback(
    (jobItem) => {
      const targetLotIsOptional = useStore.getState().optionalLotsTable[lotId.toString()];

      const job = getJob(jobItem.id);

      if (targetLotIsOptional && job.isHiddenCost) {
        return false;
      }

      return true;
    },
    [getJob, lotId],
  );

  if (!wrapperLot) {
    return null;
  }

  let shouldDisplayRootDropZone = false;

  if (isRootLot) {
    const lastLotId = findRootLotLastSublotId();
    if (lastLotId !== null) {
      shouldDisplayRootDropZone = hasLotSubLots(lastLotId) || hasLotJobs(lastLotId);
    }
  }

  const allJobsIsHiddenCost = jobs.every((job) => job.isHiddenCost);

  return (
    <>
      <DraggableItem type={QUOTE_ITEM_TYPE.LOT} item={{ id: lotId }} disabled={isReadOnlyView || isRootLot}>
        {!displayHiddenCosts && (
          <SegmentedDropZone id={lotId} accept={QUOTE_ITEM_TYPE.LOT}>
            <SegmentedDropZone.Segment
              weight={1}
              effect={DropEffect.CursorTop}
              canDrop={canDropLotBeforeLot}
              onDrop={handleDropLotBeforeLot}
            />

            <SegmentedDropZone.Segment
              name={DROP_LOT_INSIDE}
              weight={1}
              effect={DropEffect.None}
              canDrop={canDropLotInsideLot}
              onDrop={handleDropLotInsideLot}
            />

            {(!isExpanded || !hasChildren) && (
              <SegmentedDropZone.Segment
                weight={1}
                effect={DropEffect.CursorBottom}
                canDrop={canDropLotAfterLot}
                onDrop={handleDropLotAfterLot}
              />
            )}

            {({ isOver, segmentName }) =>
              !isRootLot && (
                <Box pb={hasChildren && isExpanded ? 0 : 2}>
                  <DropZone
                    id={lotId}
                    accept={QUOTE_ITEM_TYPE.JOB}
                    onDrop={handleDropJobInLot}
                    canDrop={canDropJobInSection}
                  >
                    <Box {...(isOver && segmentName === DROP_LOT_INSIDE && BOX[DropEffect.Glow])}>
                      <EditQuoteLotRow lotId={lotId} />
                    </Box>
                  </DropZone>
                </Box>
              )
            }
          </SegmentedDropZone>
        )}
        <Box display={isExpanded ? 'block' : 'none'} ml={isRootLot ? 0 : 2}>
          <Box pb={hasJobs && !allJobsIsHiddenCost ? 2 : 0}>
            {jobs.map((job) => (
              <EditQuoteJobRow
                key={`${QUOTE_ITEM_TYPE.JOB}-${job.id}`}
                jobId={job.id}
                depth={wrapperLot.lot.depth}
                onAddJobAfter={handleAddNewJobAfterGivenJobId}
              />
            ))}
          </Box>

          {!displayHiddenCosts && (
            <Box>
              {wrapperLot.subLots.map((subLot, i) => (
                <EditQuoteLot
                  key={`${QUOTE_ITEM_TYPE.LOT}-${subLot.id}`}
                  lotId={subLot.id}
                  parentHasJobs={hasJobs}
                  index={i}
                />
              ))}
            </Box>
          )}
        </Box>
      </DraggableItem>

      {shouldDisplayRootDropZone && !displayHiddenCosts && (
        <DropZone
          id={wrapperLot.lot.id}
          accept={QUOTE_ITEM_TYPE.LOT}
          effect={DropEffect.CursorTop}
          onDrop={handleDropLotAtEndOfQuote}
          h={3}
        />
      )}
    </>
  );
});

EditQuoteLot.propTypes = {
  lotId: number.isRequired,
  isRootLot: bool,
  parentHasJobs: bool,
  displayHiddenCosts: bool,
  index: number,
};

EditQuoteLot.defaultProps = {
  isRootLot: false,
  parentHasJobs: false,
  index: 0,
  displayHiddenCosts: false,
};
