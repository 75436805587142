import { Form, useForm } from 'graneet-form';
import { Card, Section, SingleSelectField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { buildFullName } from '@graneet/business-logic';

import { useUpdateDefaultSender } from '../services/reminder.api';

import { useAppContext } from 'features/app/contexts/AppContext';
import { useDisplayedUsers } from 'features/user/services/user.api';

interface FormValues {
  userId: string;
}

export const ReminderConfigureSender = () => {
  const { t } = useTranslation(['global']);

  const users = useDisplayedUsers();

  const updateDefaultSenderMutation = useUpdateDefaultSender();

  const { currentUser } = useAppContext();

  const form = useForm<FormValues>({
    onUpdateAfterBlur: (_, userId) => {
      if (!userId) {
        return;
      }
      updateDefaultSenderMutation.mutate({ userId: +userId });
    },
    defaultValues: {
      userId: currentUser?.company?.emailRemindersSender?.id.toString(),
    },
  });

  return (
    <Section
      title={t('global:reminders.settingsCard.sender')}
      description={t('global:reminders.settingsCard.senderDescription')}
    >
      <Card>
        <Form form={form}>
          <SingleSelectField<FormValues>
            name="userId"
            options={users.data
              .filter((u) => !!u.email)
              .map((u) => ({
                label: `${buildFullName(u.firstName, u.lastName)} (${u.email})`,
                value: String(u.id),
              }))}
            isSearchable={false}
            isClearable={false}
          />
        </Form>
      </Card>
    </Section>
  );
};
