import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Onboarding, SimpleAlertIcon, Wizard, useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@chakra-ui/react';
import type { IInvoice, IInvoiceWithRelations } from '@graneet/business-logic';
import { DISCOUNT_TYPES } from '@graneet/business-logic';
import { isNil } from 'lodash-es';

import { EditInvoiceItemsStep } from './_steps/container/EditInvoiceItemsStep';
import { EditInvoiceClientStep } from './_steps/client/EditInvoiceClientStep';
import { EditInvoiceInformationStep } from './_steps/information/EditInvoiceInformationStep';

import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { Loading } from 'features/common/components/Loading';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { useCreateInvoice, useEditInvoice, useInvoiceQuery } from 'features/invoice/services/invoice.api';
import { useInvoiceTree } from 'features/invoice/hooks/useInvoiceTree';
import { mapInvoiceToInitialTree, mapTreeAndFormValuesToContainer } from 'features/invoice/services/invoice.util';
import type { InvoiceEditionWizard } from 'features/invoice/forms/invoice-edition-wizard';
import { InvoiceConfirmExitCreationModal } from 'features/invoice/components/modals/InvoiceConfirmExitCreationModal';
import { useDefaultVatRate } from 'features/common/hooks/useDefaultVATRate';

const formatInvoiceWizardTitle = (invoice: IInvoice | null) => invoice?.name || '';

interface CreateInvoiceScreenInternalProps {
  invoice: IInvoiceWithRelations | undefined;
}

const CreateInvoiceScreenInternal: FC<CreateInvoiceScreenInternalProps> = ({ invoice = null }) => {
  const { t } = useTranslation(['invoice']);

  const { mapAmountToNumber } = useCurrency();

  const history = useHistory();
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { state } = useLocation<{ projectId?: string }>();

  const { onOpen, onClose, isOpen } = useDisclosure();

  const { createRedirectionWithSavedFilters } = useFiltersQuery();

  const [initialProjectId, setInitialProjectId] = useState<undefined | number>();

  useEffect(() => {
    setInitialProjectId(state?.projectId ? parseInt(state?.projectId, 10) : invoice?.project?.id);
  }, [invoice, state]);

  const handleQuit = useMemo(() => {
    const baseUrl = state?.projectId ? `/projects/${state.projectId}` : '';

    if (invoiceId) {
      const redirectionUrl = state?.projectId
        ? `${baseUrl}/statements/invoices/${invoiceId}`
        : `${baseUrl}/sales/statements/invoices/${invoiceId}`;

      return createRedirectionWithSavedFilters(redirectionUrl, { replace: true });
    }

    return createRedirectionWithSavedFilters(`${baseUrl}/sales/statements`, { replace: true });
  }, [createRedirectionWithSavedFilters, invoiceId, state]);

  const defaultVatRate = useDefaultVatRate();

  const initialTree = useMemo(() => mapInvoiceToInitialTree(invoice, { defaultVatRate }), [invoice, defaultVatRate]);
  const tree = useInvoiceTree(initialTree);

  const createInvoiceMutation = useCreateInvoice();
  const editInvoiceMutation = useEditInvoice();

  const handleSubmit = useCallback(
    async (values: InvoiceEditionWizard) => {
      const { hasReversalOfLiability, discountType, discountPercentage, discountAmountExVAT } = values.container;
      const { clientId } = values.client;
      const {
        name,
        invoiceNumber,
        purchaseOrderNumber,
        billingDate,
        note,
        projectId,
        paymentTerm,
        paymentMethod,
        deadlineType,
      } = values.information;
      const container = mapTreeAndFormValuesToContainer(tree, values.container, mapAmountToNumber);

      const discount = discountType
        ? {
            type: discountType,
            amountExVAT:
              discountType === DISCOUNT_TYPES.AMOUNT && !isNil(discountAmountExVAT)
                ? mapAmountToNumber(discountAmountExVAT)
                : null,
            percentage:
              discountType === DISCOUNT_TYPES.PERCENTAGE && !isNil(discountPercentage) ? discountPercentage : null,
          }
        : undefined;

      let id;
      if (invoiceId) {
        await editInvoiceMutation.mutateAsync({
          id: +invoiceId,
          dto: {
            container,
            hasReversalOfLiability: !!hasReversalOfLiability,
            discount: discount || null,
            clientId,

            name,
            invoiceNumber,
            purchaseOrderNumber: purchaseOrderNumber || null,
            billingDate,
            note: note || '',

            projectId: projectId || null,

            paymentTerm,
            deadlineType,
            paymentMethodId: paymentMethod.id,
          },
        });
        id = invoiceId;
      } else {
        const response = await createInvoiceMutation.mutateAsync({
          container,
          hasReversalOfLiability: !!hasReversalOfLiability,
          discount: discount || null,
          clientId,

          name,
          invoiceNumber: invoiceNumber || null,
          purchaseOrderNumber: purchaseOrderNumber || null,
          billingDate,
          note: note || '',

          projectId: projectId || null,

          paymentTerm,
          deadlineType,
          paymentMethodId: paymentMethod.id,
        });
        id = response.id;
      }

      if (projectId && state?.projectId) {
        // We go to the associated project
        history.replace(`/projects/${projectId}/statements/invoices/${id}`);
      } else {
        // If we come directly from an invoice
        history.replace(`/sales/statements/invoices/${id}`);
      }
    },
    [createInvoiceMutation, editInvoiceMutation, history, invoiceId, mapAmountToNumber, state?.projectId, tree],
  );

  const wizardLabels = useWizardLabels({
    save: invoiceId ? t('invoice:actions.modify') : t('invoice:actions.create'),
  });
  const headerTitle = t(invoiceId ? 'invoice:steps.titleModification' : 'invoice:steps.titleCreation', {
    label: formatInvoiceWizardTitle(invoice),
  });

  return (
    <>
      <Wizard<InvoiceEditionWizard>
        headerTitle={headerTitle}
        onQuit={onOpen}
        onFinish={handleSubmit}
        labels={wizardLabels}
      >
        <Wizard.Step<InvoiceEditionWizard> name="container">
          <EditInvoiceItemsStep invoice={invoice} tree={tree} />
        </Wizard.Step>

        <Wizard.Step<InvoiceEditionWizard> name="client">
          <EditInvoiceClientStep invoice={invoice} />
        </Wizard.Step>

        <Wizard.Step<InvoiceEditionWizard> name="information">
          <EditInvoiceInformationStep invoice={invoice} initialProjectId={initialProjectId} />
        </Wizard.Step>
      </Wizard>

      <InvoiceConfirmExitCreationModal onClose={onClose} isOpen={isOpen} onConfirmed={handleQuit} />
    </>
  );
};

export const CreateInvoiceScreen: FC = () => {
  useHideNavbar();
  const { t } = useTranslation(['global']);

  const { invoiceId } = useParams<{ invoiceId: string }>();

  const invoice = useInvoiceQuery(invoiceId ? +invoiceId : undefined);

  if (invoice.isLoading) {
    return <Loading />;
  }

  if (invoice.isError) {
    return <Onboarding message={t('global:errors.error')} icon={<SimpleAlertIcon boxSize={45} />} />;
  }

  return <CreateInvoiceScreenInternal invoice={invoice.data} />;
};
