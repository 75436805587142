import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';

import { SimpleErrorIcon } from '../Icons';
import { usePaginatedDataContext } from '../PaginatedData/hooks';
import type { OnboardingProps } from '../Onboarding/Onboarding';
import { Onboarding } from '../Onboarding/Onboarding';
import type { InfiniteScrollerProps } from '../InfiniteScroller/InfiniteScroller';
import { InfiniteScroller } from '../InfiniteScroller/InfiniteScroller';

import { asyncDataTableTranslations, configureDefaultLabels } from './configureDefaultLabel';

export interface AsyncTableDataProps {
  emptyState?: OnboardingProps;

  errorState?: OnboardingProps;

  infiniteScrollerProps?: Omit<InfiniteScrollerProps, 'children' | 'loadMore' | 'hasMore'>;

  children: ReactNode;
}

const AsyncTableDataComponent: FC<AsyncTableDataProps> = ({
  emptyState,
  errorState,
  infiniteScrollerProps,
  children,
}) => {
  const defaultErrorState = useMemo(
    () => ({
      icon: <SimpleErrorIcon boxSize={6} stroke="red.500" />,
      message: asyncDataTableTranslations.error,
    }),
    [],
  );

  const paginatedDataContext = usePaginatedDataContext();
  const { error, hasMore, loadMore, data, unfilteredCount } = paginatedDataContext;

  // Error state (both legacy and paginated table)
  if (error) {
    return (
      <Box p={5}>
        <Onboarding {...defaultErrorState} {...errorState} />
      </Box>
    );
  }

  // Empty state (paginated table)
  if (Array.isArray(data) && unfilteredCount === 0 && data.length === 0) {
    return emptyState ? (
      <Box p={5}>
        <Onboarding {...emptyState} />
      </Box>
    ) : null;
  }

  return (
    <InfiniteScroller hasMore={hasMore} loadMore={loadMore} {...infiniteScrollerProps}>
      {children}
    </InfiniteScroller>
  );
};

export const AsyncTableData = Object.assign(AsyncTableDataComponent, {
  configureDefaultLabels,
});
