import { useTranslation } from 'react-i18next';
import { Card, RICH_TEXT_BLOCK_TOOLBAR_PRESET, RichTextField } from '@graneet/lib-ui';
import { Text, Box } from '@chakra-ui/react';

import type { UserMailsSettingsFormValues } from '../constants/user.constant';

export const UserSignatureSettings = () => {
  const { t } = useTranslation(['mailing']);

  return (
    <Card title={t('mailing:settings.configuration.signature.title')} maxW="40rem" overflow="hidden">
      <Text mb={4}>{t('mailing:settings.configuration.signature.description')}</Text>
      <Box borderRadius={8} pt={2}>
        <RichTextField<UserMailsSettingsFormValues>
          navbarType="block"
          name="mailingEmailSignatureJSON"
          configuration={RICH_TEXT_BLOCK_TOOLBAR_PRESET}
        />
      </Box>
    </Card>
  );
};
