import type { ICompany } from '../company/company.type';
import type { IProject } from '../project/project.type';
import type { IWorker } from '../worker/worker.type';
import type { RequiredByKeys } from '../common/types/object.type';
import type { IComponentType } from '../component-type/component-type.type';
import type { ITimeslotOvertime } from '../overtime/timeslot-overtime.type';

export enum ABSENCE_TYPES {
  PAID_LEAVE = 'PAID_LEAVE',
  REDUCTION_OF_WORKING_HOURS = 'REDUCTION_OF_WORKING_HOURS',
  PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY',
  BAD_WEATHER = 'BAD_WEATHER',
  FAMILY_EVENT = 'FAMILY_EVENT',
  SICK_LEAVE = 'SICK_LEAVE',
  WORK_ACCIDENT = 'WORK_ACCIDENT',
  UNPAID_LEAVE = 'UNPAID_LEAVE',
  OTHER = 'OTHER',
}

export enum ABSENCE_DURATION {
  HALF_DAY = 'HALF_DAY',
  FULL_DAY = 'FULL_DAY',
}

export interface AlobeesData {
  id: string;

  note: string;

  nbMinutes: number;

  day_start: string | null;

  day_end: string | null;
}

export interface ITimeSlot {
  id: number;

  date: Date;

  // total nbMinutes
  nbMinutes: number;

  hourlyPriceExVAT: number;

  totalPriceExVAT: number;

  note?: string;

  createdAt: Date;

  updatedAt: Date;

  project?: IProject | null;

  worker?: IWorker;

  company?: ICompany;

  alobeesData: AlobeesData[];

  alobeesUpdatedAt: string | null;

  componentType?: IComponentType;

  timeslotOvertimes?: ITimeslotOvertime[];

  mealExpense: number;

  kmExpense: number;

  absenceType: ABSENCE_TYPES | null;

  absenceDuration: ABSENCE_DURATION | null;

  startHour: string | null;

  endHour: string | null;

  isApproved: boolean;
}

export type ITimeSlotWithRelations = RequiredByKeys<
  ITimeSlot,
  'project' | 'worker' | 'company' | 'componentType' | 'timeslotOvertimes'
>;

export interface ITimeTrackingResponse<T extends IProject | IWorker> {
  slots: Record<T['id'], Record<string, ITimeSlotWithRelations[]>>;
  workers: IWorker[];
  projects: IProject[];
  hasSlotsOnWeekend: boolean;
  nextStartDate: string;
  previousStartDate: string;
}

export type TimeTrackingSlotsGroupBy = 'project' | 'worker';

export interface ITimeTrackingProjectStatsResponse {
  estimatedQuoteWorkTime: number;
  estimatedProjectWorkTime: number;
  estimatedApprovedWorkTime: number;
  estimatedToApprovedWorkTime: number;
  componentTypes: {
    componentType: Omit<IComponentType, 'createdAt' | 'updatedAt'>;
    estimatedQuoteWorkTime: number | null;
    estimatedProjectWorkTime: number | null;
    estimatedApprovedWorkTime: number | null;
    estimatedToApprovedWorkTime: number | null;
  }[];
}
