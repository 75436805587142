import type { FC } from 'react';
import { ButtonField, DeepTable, Tooltip, useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Box, GridItem, HStack, Text } from '@chakra-ui/react';
import { CUMULATIVE_INPUT_TYPE } from '@graneet/business-logic';

import type { ProgressStatementEditItemForm } from '../forms/progress-statement-edit-item-form';
import { useProgressStatementTreeContext } from '../hooks/useProgressStatementTree';

interface ProgressStatementDeepTableHeaderProps {
  isUpdatable?: boolean;
}

export const ProgressStatementDeepTableHeader: FC<ProgressStatementDeepTableHeaderProps> = ({ isUpdatable = true }) => {
  const { t } = useTranslation(['progressStatement', 'container']);

  const tree = useProgressStatementTreeContext();

  const { symbol } = useCurrency();

  if (!isUpdatable) {
    return (
      <DeepTable.StickyContent zIndex={2}>
        <DeepTable.Header bg="white">
          <DeepTable.Cell>{t('container:fields.code')}</DeepTable.Cell>
          <DeepTable.Cell>{t('container:fields.name')}</DeepTable.Cell>
          <DeepTable.Cell right>{t('container:fields.quantity')}</DeepTable.Cell>
          <DeepTable.Cell right>{t('container:fields.unit')}</DeepTable.Cell>
          <DeepTable.Cell right>{t('container:fields.unitPrice')}</DeepTable.Cell>
          <DeepTable.Cell right>{t('container:totals.totalExVAT')}</DeepTable.Cell>
          <DeepTable.Cell center>{t('progressStatement:isCreating.previousCumulativeProgress')}</DeepTable.Cell>
          <DeepTable.Cell variant="shadow" center color="blue.500">
            {t('progressStatement:isCreating.cumulativeProgress')}
          </DeepTable.Cell>
          <DeepTable.Cell right>{t('progressStatement:isCreating.cumulativeAmountExVAT')}</DeepTable.Cell>
        </DeepTable.Header>
      </DeepTable.StickyContent>
    );
  }

  return (
    <DeepTable.StickyContent top="-3.5rem" zIndex={2}>
      <DeepTable.Header border="none" h="3.5rem">
        <GridItem colSpan={7}>
          <DeepTable.Cell />
        </GridItem>

        <DeepTable.Cell variant="shadow_extended" color="greenBrand.light" alignItems="end">
          <Text h="3rem">
            <Box pt={5}>{t('progressStatement:isCreating.cumulativeProgress')}</Box>
          </Text>
        </DeepTable.Cell>
        <DeepTable.Cell />
      </DeepTable.Header>

      <DeepTable.Header
        bg="white"
        leftContent={
          <Tooltip label={t('progressStatement:isCreating.openOrCloseAllLots')}>
            <Box onClick={tree.toggleAllNodes} cursor="pointer">
              <i className="ri-contract-up-down-line ri-lg" />
            </Box>
          </Tooltip>
        }
      >
        <DeepTable.Cell>{t('container:fields.code')}</DeepTable.Cell>
        <DeepTable.Cell>{t('container:fields.name')}</DeepTable.Cell>
        <DeepTable.Cell right>{t('container:fields.quantity')}</DeepTable.Cell>
        <DeepTable.Cell right>{t('container:fields.unit')}</DeepTable.Cell>
        <DeepTable.Cell right>{t('container:fields.unitPrice')}</DeepTable.Cell>
        <DeepTable.Cell right>{t('container:totals.totalExVAT')}</DeepTable.Cell>
        <DeepTable.Cell center>{t('progressStatement:isCreating.previousCumulativeProgress')}</DeepTable.Cell>
        <DeepTable.Cell variant="shadow" color="greenBrand.light" center>
          <HStack spacing={4} paddingInlineStart={0} paddingInlineEnd={0} bg="white">
            <ButtonField<ProgressStatementEditItemForm> name="cumulativeInputType" size="xs">
              <ButtonField.Option
                value={CUMULATIVE_INPUT_TYPE.PERCENTAGE}
                label={t('progressStatement:isCreating.cumulativeInputType.percentage')}
              />
              <ButtonField.Option
                value={CUMULATIVE_INPUT_TYPE.QUANTITY}
                label={t('progressStatement:isCreating.cumulativeInputType.quantity')}
              />
              <ButtonField.Option value={CUMULATIVE_INPUT_TYPE.AMOUNT} label={symbol} />
            </ButtonField>
          </HStack>
        </DeepTable.Cell>
        <DeepTable.Cell right>{t('progressStatement:isCreating.cumulativeAmountExVAT')}</DeepTable.Cell>
      </DeepTable.Header>
    </DeepTable.StickyContent>
  );
};
