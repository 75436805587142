import type { FC } from 'react';
import { DrawersStack, useDrawer, useDrawersStack } from '@graneet/lib-ui';

import { BillDetailLayout } from './BillDetailLayout';

import { HotKeysDrawerNavigationHeader } from 'features/common/components/HotKeysDrawerNavigationHeader';
import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { EmailProvider } from 'features/history/contexts/EmailContext';

interface BillDrawerDetailProps {
  isOpen: boolean;

  onClose: () => void;

  id: number | undefined;

  onUpdate: () => void;

  onChangeFocusClicked: (offset: -1 | 1) => void;
}

export const BillDrawerDetail: FC<BillDrawerDetailProps> = ({
  isOpen,
  onClose,
  id,
  onUpdate,
  onChangeFocusClicked,
}) => {
  const drawersStack = useDrawersStack<'detail'>();
  const drawer = useDrawer('detail', drawersStack, {
    isOpen,
    onClose,
  });

  return (
    <DrawersStack drawersStack={drawersStack}>
      <DrawersStack.Drawer
        title={<HotKeysDrawerNavigationHeader onChangeFocusClicked={onChangeFocusClicked} />}
        drawer={drawer}
        size="lg"
      >
        <QueryWrapper>
          {id && (
            <EmailProvider>
              <BillDetailLayout id={id} onUpdate={onUpdate} />
            </EmailProvider>
          )}
        </QueryWrapper>
      </DrawersStack.Drawer>
    </DrawersStack>
  );
};
