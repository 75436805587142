import type { FC } from 'react';
import { useCurrency } from '@graneet/lib-ui';

import { useAmountExVATCompute } from 'features/progress-statement/hooks/useAmountExVATCompute';
import type { ProgressStatementEditItemForm } from 'features/progress-statement/forms/progress-statement-edit-item-form';

interface ProgressStatementExVATAmountLabelProps {
  values: Partial<ProgressStatementEditItemForm>;

  previousCumulativeAmountExVAT?: number | null;
}

export const ProgressStatementExVATAmountLabel: FC<ProgressStatementExVATAmountLabelProps> = ({
  values,
  previousCumulativeAmountExVAT,
}) => {
  const { formatAsAmount } = useCurrency();
  const amountExVAT = useAmountExVATCompute(values, previousCumulativeAmountExVAT || undefined);

  return <>{formatAsAmount(amountExVAT)}</>;
};
