import type { FC, ReactNode } from 'react';
import type { FlexProps, IconProps } from '@chakra-ui/react';
import { Flex, Text } from '@chakra-ui/react';

import { Button } from '../Button/Button';

export type ActionPropTypes = {
  onClick: () => void;
  title: ReactNode;
  icon?: FC<IconProps>; // TODO: Change to ReactElement
  iconProps?: IconProps;
};

export type CardButtonsProps = FlexProps & {
  primaryAction?: ActionPropTypes;
  secondaryAction?: ActionPropTypes;
};

export const CardButtons: FC<CardButtonsProps> = ({ primaryAction, secondaryAction, ...props }) => (
  <Flex fontFamily="heading" fontWeight={500} align="center" justify="space-between" mb={3} {...props}>
    {secondaryAction && (
      <Button
        onClick={secondaryAction.onClick || (() => {})}
        colorScheme="blue"
        variant="outline"
        size="sm"
        color="gray.500"
        fontWeight="700"
        mr={primaryAction ? 4 : 0}
      >
        {secondaryAction.title && <Text>{secondaryAction.title}</Text>}
        {secondaryAction.icon && <secondaryAction.icon ml={2} {...(secondaryAction.iconProps || {})} />}
      </Button>
    )}
    {primaryAction && (
      <Button
        onClick={primaryAction.onClick || (() => {})}
        colorScheme="blue"
        variant="outline"
        size="sm"
        color={secondaryAction ? 'greenBrand.light' : 'gray.500'}
        fontWeight="700"
        pr={0}
      >
        {primaryAction.title && <Text>{primaryAction.title}</Text>}
        {primaryAction.icon && <primaryAction.icon ml={2} {...(primaryAction.iconProps || {})} />}
      </Button>
    )}
  </Flex>
);
