import type { FC } from 'react';
import { HiddenField, useFormContext, useHiddenField, useOnChangeValues } from 'graneet-form';
import type { InputProps } from '@graneet/lib-ui';
import { DeepTable, PercentageField } from '@graneet/lib-ui';
import { FEATURE_FLAGS } from '@graneet/business-logic';

import type { ContractEditionForm, ItemKey } from '../../forms/contract-edition.form';
import { getItemFieldName } from '../../forms/contract-edition.form';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { Rule } from 'features/form/rules/Rule';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';

interface ContractItemVATRateProps {
  id: string | number;

  inputStyle(key: ItemKey, textAlign?: 'right' | 'left'): InputProps;

  handleFocus(): void;

  handleBlur(key: ItemKey): () => void;

  isDeleted: boolean;
}

export const ContractItemVATRate: FC<ContractItemVATRateProps> = ({
  id,
  inputStyle,
  handleFocus,
  handleBlur,
  isDeleted,
}) => {
  const form = useFormContext<ContractEditionForm>();

  const fieldName = getItemFieldName(id, 'vatRate');
  const { hasReversalOfLiability } = useOnChangeValues(form, ['hasReversalOfLiability']);

  const vatHiddenField = useHiddenField(form, fieldName);

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  if (hasReversalOfLiability) {
    return (
      <DeepTable.Cell right>
        <HiddenField {...vatHiddenField} />-
      </DeepTable.Cell>
    );
  }

  if (hasStandardVATRates) {
    return (
      <DeepTable.Cell>
        <AccountingVATField<ContractEditionForm>
          name={fieldName}
          {...inputStyle('vatRate', 'right')}
          onFocus={handleFocus}
          onBlur={handleBlur('vatRate')}
          isDisabled={isDeleted}
          valueScale={100}
          noBorder={false}
        >
          <Rule.IsRequired />
        </AccountingVATField>
      </DeepTable.Cell>
    );
  }

  return (
    <DeepTable.Cell right>
      <PercentageField<ContractEditionForm>
        name={fieldName}
        inputProps={inputStyle('vatRate', 'right')}
        onFocus={handleFocus}
        onBlur={handleBlur('vatRate')}
        isDisabled={isDeleted}
        hideErrorMessage
        noBorder
      >
        <Rule.IsRequired />
      </PercentageField>
    </DeepTable.Cell>
  );
};
