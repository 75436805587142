import type { FC } from 'react';
import { SimpleGrid, Box } from '@chakra-ui/react';
import { Card, DateField, Tooltip, SimpleHelpIcon, Section, TextField } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { STATEMENT_TYPES } from '@graneet/business-logic';

import type { CreditEditionInformationStepForm } from '../../forms/CreditEditionWizard';

import { InvoiceNumberField } from 'features/statement-ledger/components/InvoiceNumberField';
import { RuleIsInvoiceNumberAvailable } from 'features/statement/rules/RuleIsInvoiceNumberAvailable';
import { Rule } from 'features/form/rules/Rule';
import { useHasLedger } from 'features/statement-ledger/hooks/useHasLedger';

interface CreditInformationFieldsSectionProps {
  invoiceId?: number;
}

export const CreditInformationFieldsSection: FC<CreditInformationFieldsSectionProps> = ({ invoiceId }) => {
  const { t } = useTranslation(['credit']);
  const hasLedger = useHasLedger();

  return (
    <Section
      title={t('credit:wizard.information.title')}
      description={
        hasLedger
          ? t('credit:wizard.information.description.withLedger')
          : t('credit:wizard.information.description.withoutLedger')
      }
    >
      <Card>
        <SimpleGrid spacing={10} columns={2} mb={5} templateColumns="2fr 1fr">
          <TextField<CreditEditionInformationStepForm>
            name="name"
            label={t('credit:fields.name.label')}
            rightIcon={
              <Tooltip label={t('credit:fields.name.tooltip')} placement="right-end">
                <Box>
                  <SimpleHelpIcon boxSize={5} />
                </Box>
              </Tooltip>
            }
            isRequired
          >
            <Rule.IsRequired />
          </TextField>

          <InvoiceNumberField<CreditEditionInformationStepForm>
            name="invoiceNumber"
            label={t('credit:fields.invoiceNumber')}
            type={STATEMENT_TYPES.CREDIT}
            isRequired
          >
            <Rule.IsRequired />
            <RuleIsInvoiceNumberAvailable statementId={invoiceId} type={STATEMENT_TYPES.CREDIT} />
          </InvoiceNumberField>
        </SimpleGrid>

        <DateField<CreditEditionInformationStepForm>
          name="creditDate"
          label={t('credit:fields.date.label')}
          rightIcon={
            <Tooltip label={t('credit:fields.date.tooltip')} placement="right-end">
              <Box>
                <SimpleHelpIcon boxSize={5} />
              </Box>
            </Tooltip>
          }
          isRequired
          width="150px"
        >
          <Rule.IsRequired />
        </DateField>
      </Card>
    </Section>
  );
};
