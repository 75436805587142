import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { isBillLosable } from '@graneet/business-logic';
import { compact } from 'lodash-es';

import type { BillActionsType } from './bill.actions.type';
import {
  getBillMarkAsLost,
  getBillMarkAsPaidAction,
  getBillMarkAsRemindedAction,
  getBillRemindPerMailAction,
} from './bill.actions.util';

export function getBillToBeReceivedActions(params: BillActionsType): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  actions.push(getBillMarkAsPaidAction(params));

  const remindPerMailAction = getBillRemindPerMailAction(params);
  if (remindPerMailAction) {
    actions.push(remindPerMailAction);
  }

  const markAsLostAction = isBillLosable(params.bill) ? getBillMarkAsLost(params) : null;
  const markAsRemindedAction = getBillMarkAsRemindedAction(params);
  if (markAsRemindedAction) {
    actions.push(markAsRemindedAction);
  }

  if (markAsLostAction) {
    actions.push({
      type: 'tertiaryDropdown',
      options: compact([markAsLostAction]),
    });
  }

  return actions;
}
