import { INPUT_NOTE_DEEP_TABLE_STYLE } from '@graneet/lib-ui';

export const QUOTE_INPUT_STYLE = {
  RIGHT: {
    noBorder: true,
    textAlign: 'right',
  },
  CENTER: {
    noBorder: true,
    textAlign: 'center',
  },
  NOTE: INPUT_NOTE_DEEP_TABLE_STYLE(false),
  FOCUS: {
    noBorder: true,
    fontSize: 'md',
  },
  BLURRED: {
    noBorder: true,
    fontSize: 'md',
    _hover: { borderBottomColor: 'gray.300' },
  },
};
