import { ENV, FEATURE_FLAGS } from '@graneet/business-logic';
import type { ButtonColorScheme } from '@graneet/lib-ui';
import { Badge } from '@graneet/lib-ui';

import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { getEnvValue } from 'config/env';

const ENV_NAMES: Record<ENV, string> = {
  [ENV.PROD]: 'Prod',
  [ENV.LOCAL]: 'Local',
  [ENV.STAGING]: 'Recette',
  [ENV.PREPROD]: 'Preprod',
  [ENV.E2E]: 'Test E2E',
  [ENV.TEST]: 'Test',
  [ENV.SQUADA]: 'Squad A',
  [ENV.SQUADB]: 'Squad B',
};

const ENV_COLORS: Record<ENV, ButtonColorScheme> = {
  [ENV.PROD]: 'red',
  [ENV.LOCAL]: 'green',
  [ENV.STAGING]: 'yellow',
  [ENV.PREPROD]: 'yellow',
  [ENV.E2E]: 'blue',
  [ENV.TEST]: 'blue',
  [ENV.SQUADA]: 'purple',
  [ENV.SQUADB]: 'pink',
};

export const Env = () => {
  const env = getEnvValue('REACT_APP_ENV');
  const isEnabled = useFeatureFlag(FEATURE_FLAGS.DISPLAY_ENV_BANNER);

  if (!isEnabled) {
    return null;
  }

  return (
    <Badge
      py={2}
      showDot
      colorScheme={ENV_COLORS[env as ENV]}
      fontWeight="bold"
      justifyContent="center"
      marginLeft="auto"
      borderRadius={5}
      paddingX={2}
    >
      {ENV_NAMES[env as ENV]}
    </Badge>
  );
};
