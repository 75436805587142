import type { QuoteNodeMoveDTO, QuoteNodeMovesApi } from '@org/graneet-bff-client';

import { proxyHelper } from 'features/api/services/proxyHelper';
import type { ExternalApi } from 'config/externalApi';

export class QuoteNodeMovesProxyApi {
  private static quoteNodeMovesProxyApi: QuoteNodeMovesProxyApi;

  private readonly quoteNodeMovesApi: QuoteNodeMovesApi;

  private constructor(externalApi: ExternalApi) {
    this.quoteNodeMovesApi = externalApi.getQuoteNodeMovesApi();
  }

  public static getQuoteNodeMovesProxyApi(externalApi: ExternalApi): QuoteNodeMovesProxyApi {
    if (!this.quoteNodeMovesProxyApi) {
      QuoteNodeMovesProxyApi.quoteNodeMovesProxyApi = new QuoteNodeMovesProxyApi(externalApi);
    }
    return QuoteNodeMovesProxyApi.quoteNodeMovesProxyApi;
  }

  public async moveQuoteNode(quoteNodeMoveDTO: QuoteNodeMoveDTO) {
    return proxyHelper(async () => this.quoteNodeMovesApi.moveQuoteNode(quoteNodeMoveDTO));
  }
}
