import type { FC } from 'react';
import { useCallback } from 'react';
import type { IAccountingExport } from '@graneet/business-logic';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from '@graneet/lib-ui';
import { Flex } from '@chakra-ui/react';

import { useAccountingExportCancel } from '../../services/accounting-export.api';

interface AccountingTableCancelModalProps {
  accountingExport: IAccountingExport;

  onCanceled: () => void;

  onClose: () => void;

  isOpen: boolean;
}

export const AccountingTableCancelModal: FC<AccountingTableCancelModalProps> = ({
  accountingExport,
  onCanceled,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation(['global', 'accounting']);

  const accountingExportCancelMutation = useAccountingExportCancel();

  const handleCancel = useCallback(() => {
    accountingExportCancelMutation.mutate(accountingExport.id, {
      onSuccess: async () => {
        onClose();
        onCanceled();
      },
    });
  }, [accountingExport.id, accountingExportCancelMutation, onCanceled, onClose]);

  return (
    <Modal title={t('accounting:cancelModal.title')} size="2xl" isOpen={isOpen} onClose={onClose}>
      <Flex>
        <Trans t={t} i18nKey="accounting:cancelModal.description" />
      </Flex>

      <Modal.Close isDisabled={accountingExportCancelMutation.isPending} />
      <Modal.PrimaryButton isLoading={accountingExportCancelMutation.isPending} onClick={handleCancel}>
        {t('accounting:cancelModal.cta')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
