import { Text, type UseDisclosureReturn } from '@chakra-ui/react';
import { Modal } from '@graneet/lib-ui';
import { useCallback, type FC } from 'react';
import { useTranslation } from 'react-i18next';

interface DisableQuotationReversalOfLiabilityModalProps {
  modalControls: UseDisclosureReturn;

  disableReversalOfLiability: () => void;
}

export const DisableQuotationReversalOfLiabilityModal: FC<DisableQuotationReversalOfLiabilityModalProps> = ({
  modalControls: modalDisableReversalOfLiability,
  disableReversalOfLiability,
}) => {
  const { t } = useTranslation(['quote', 'global']);

  const handleDisableReversalOfLiability = useCallback(() => {
    disableReversalOfLiability();
    modalDisableReversalOfLiability.onClose();
  }, [modalDisableReversalOfLiability, disableReversalOfLiability]);

  return (
    <Modal {...modalDisableReversalOfLiability}>
      <Text mt={4}>{t('quote:reversalOfLiability.disableModal.description')}</Text>

      <Modal.Close />

      <Modal.PrimaryButton onClick={handleDisableReversalOfLiability}>
        {t('global:words.c.continue')}
      </Modal.PrimaryButton>
    </Modal>
  );
};
