import { Box } from '@chakra-ui/react';
import { useMemo } from 'react';
import type { QuoteNodeObject } from '@org/quotation-lib';
import type { GridApi } from '@ag-grid-community/core';

import { QuoteAGGridType } from '../../constants/agGridConstants';

import { QuoteToolBarToggleComponentsLinesButton } from './quote-toolbar-button/QuoteToolBarToggleComponentsLinesButton';
import { QuoteToolBarToggleAllLinesButton } from './quote-toolbar-button/QuoteToolBarToggleAllLinesButton';
import { QuoteToolBarAutoIndexButton } from './quote-toolbar-button/QuoteToolBarAutoIndexButton';
import { QuoteToolBarToggleGridButton } from './quote-toolbar-button/QuoteToolBarToggleGridButton';
import { QuoteToolBarRowHeightButton } from './quote-toolbar-button/QuoteToolBarRowHeightButton';

interface QuoteToolBarProps {
  type: QuoteAGGridType;
  handleOpenGrid?: {
    value: boolean;
    onClick: () => void;
  };
  api: GridApi<QuoteNodeObject> | null;
}

export const QuoteToolBar = ({ type, handleOpenGrid, api }: QuoteToolBarProps) => {
  const ButtonHiddenCost = useMemo(
    () => (
      <>
        {handleOpenGrid && (
          <QuoteToolBarToggleGridButton value={handleOpenGrid.value} onClick={handleOpenGrid.onClick} />
        )}
        {api && handleOpenGrid?.value && <QuoteToolBarToggleAllLinesButton type={type} api={api} />}
      </>
    ),
    [api, handleOpenGrid, type],
  );

  const ButtonRoot = useMemo(
    () =>
      api ? (
        <>
          <QuoteToolBarToggleAllLinesButton type={type} api={api} />
          <QuoteToolBarToggleComponentsLinesButton api={api} />
          <QuoteToolBarAutoIndexButton />
          <QuoteToolBarRowHeightButton />
        </>
      ) : null,
    [api, type],
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      height="44px"
      minHeight="44px"
      gap="6px"
      paddingX="6px"
      background="white"
      borderTopRadius="8px"
      borderBottomRadius={type === QuoteAGGridType.HIDDEN_COST && !handleOpenGrid?.value ? '8px' : '0px'}
      borderTopWidth="1px"
      borderLeftWidth="1px"
      borderRightWidth="1px"
      borderBottomWidth={type === QuoteAGGridType.HIDDEN_COST && !handleOpenGrid?.value ? '1px' : '0px'}
      borderColor={type === QuoteAGGridType.HIDDEN_COST ? '#fab8d9' : 'color(srgb 0.0941176 0.113725 0.121569 / 0.15)'}
    >
      {type === QuoteAGGridType.HIDDEN_COST ? ButtonHiddenCost : ButtonRoot}
    </Box>
  );
};
