import { Box, Button, Flex, ScaleFade, Stack, useDisclosure, Text, Center, VStack } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { Fragment, useCallback, useMemo } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { compact } from 'lodash-es';
import { motion } from 'framer-motion';

import { Tooltip } from '../Tooltip';
import { useLocalStorage } from '../../hooks';
import { Badge } from '../Badge/Badge';

import { configureDefaultLabels, navTranslations } from './configureDefaultLabel';

type Item = {
  icon: ReactNode | string;
  name: ReactNode;
  to: string;
  onClick?: () => void;
  shortcut?: string;
};

type ItemsGroup = {
  name: string;
  isNew?: boolean;
  items: (Item | false)[];
};

export interface NavProps {
  /**
   * Header definition
   */
  header: {
    /**
     * Content displayed in the header
     */
    content: FC<{ isFullSize: boolean }>;
  };

  /**
   * Nav items. Can be grouped under a title or be isolated item
   */
  items: Array<ItemsGroup | Item | false>;
}

const Header = ({
  content: Content,
  isFullSize,
  onFullSizeUpdate,
}: {
  content: FC<{ isFullSize: boolean }>;
  isFullSize: boolean;
  onFullSizeUpdate: (newValue: 'true' | 'false') => void;
}) => (
  <Stack
    direction={isFullSize ? 'row' : 'column-reverse'}
    gap={2}
    mt={2}
    justifyContent="space-between"
    alignItems="center"
    maxWidth="17rem"
    width="100%"
    h={isFullSize ? '4rem' : undefined}
  >
    <Content isFullSize={isFullSize} />

    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding={1}
      color="primaryLightOpacity"
      fontSize="1.25rem"
      mr={isFullSize ? 1 : 0}
      pt={isFullSize ? 0 : 1}
      ml={isFullSize ? 'auto' : undefined}
      _hover={{ color: 'primary', cursor: 'pointer' }}
      onClick={() => {
        onFullSizeUpdate(isFullSize ? 'false' : 'true');
      }}
      h="fit-content"
    >
      {isFullSize ? <i className="ri-menu-fold-3-line" /> : <i className="ri-menu-unfold-3-line" />}
    </Box>
  </Stack>
);

const isItemActive = (pathname: string, to: string): boolean => {
  if (to === '/') {
    return pathname === to;
  }

  const pathInfos = matchPath(pathname, to);
  if (!pathInfos) {
    return false;
  }

  return pathInfos.path.startsWith(to);
};

const ItemComponent = ({ icon, to, name, onClick, shortcut, isFullSize }: Item & { isFullSize: boolean }) => {
  const { pathname } = useLocation();
  const isActive = isItemActive(pathname, to);

  return (
    <Flex
      as={Link}
      to={to}
      color="primary"
      alignItems="center"
      justifyContent={isFullSize ? 'flex-start' : 'center'}
      gap={isFullSize ? 2 : 0}
      minH="2rem"
      maxH="2rem"
      px={isFullSize ? 3 : 0}
      _hover={
        isActive
          ? undefined
          : {
              borderRadius: 6,
              textDecoration: 'none',
              color: 'primary',
              backgroundColor: 'white',
              boxShadow:
                '0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)',
            }
      }
      background={isActive ? 'white' : 'transparent'}
      borderRadius={isActive ? 6 : 0}
      boxShadow={
        isActive
          ? '0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)'
          : undefined
      }
      onClick={onClick}
    >
      <Tooltip label={name} isDisabled={isFullSize} h="100%">
        <Center h="100%">{typeof icon === 'string' ? <i className={icon} /> : icon}</Center>
      </Tooltip>

      <motion.div
        animate={isFullSize ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, width: '100%' },
          closed: { opacity: 0, width: 0 },
        }}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <Box ml={1}>
          <Text fontSize="sm">{name}</Text>
        </Box>

        {shortcut && (
          <Box>
            <Text fontSize="sm" fontWeight={400} color="rgba(21, 24, 26, 0.60)">
              {shortcut}
            </Text>
          </Box>
        )}
      </motion.div>
    </Flex>
  );
};

const ItemsGroupComponent = ({ items, name, isFullSize, isNew }: ItemsGroup & { isFullSize: boolean }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const { pathname } = useLocation();
  const hasSubItemSelected = useMemo(
    () => compact(items).some((item) => isItemActive(pathname, item.to)),
    [items, pathname],
  );

  const handleToggle = useCallback(() => {
    if (hasSubItemSelected) {
      return;
    }

    onToggle();
  }, [hasSubItemSelected, onToggle]);

  if (!isFullSize) {
    return (
      <VStack gap={2} width="100%" alignItems="stretch">
        {compact(items).map((item) => (
          <ItemComponent key={item.to} {...item} isFullSize={isFullSize} />
        ))}
      </VStack>
    );
  }

  return (
    <>
      <Flex h="1rem" alignItems="center" onClick={handleToggle} mb={2}>
        <Button variant="unstyled" w="fit-content" fontSize="1rem" color="primary" fontWeight={400}>
          <Flex alignItems="center">
            <Text>{name}</Text>
            <Text color="primaryLightOpacity" fontSize="1.5rem">
              <i className={isOpen ? 'ri-arrow-drop-down-fill' : 'ri-arrow-drop-right-fill'} />
            </Text>
            {isNew && <Badge colorScheme="purple">{navTranslations.new}</Badge>}
          </Flex>
        </Button>
      </Flex>

      <ScaleFade in={isOpen || hasSubItemSelected} unmountOnExit>
        <VStack gap={2} width="100%" alignItems="stretch">
          {compact(items).map((item) => (
            <ItemComponent key={item.to} {...item} isFullSize={isFullSize} />
          ))}
        </VStack>
      </ScaleFade>
    </>
  );
};

const NavComponent: FC<NavProps> = ({ header, items }) => {
  const [isFullSizeStringLike, setIsFullSize] = useLocalStorage('isSidebarFullSize', 'true');
  const isFullSize = isFullSizeStringLike !== 'false';

  return (
    <motion.div
      animate={isFullSize ? 'open' : 'closed'}
      variants={{
        open: { width: '17rem' },
        closed: { width: '3.75rem' },
      }}
    >
      <Stack h="100%" overflowY="auto" w="inherit" px={2} backgroundColor="backgroundLight" gap={1}>
        <Header {...header} isFullSize={isFullSize} onFullSizeUpdate={setIsFullSize} />
        <VStack px={2} mb={2} alignItems="stretch">
          <Box borderColor="rgba(21, 24, 26, 0.08)" borderWidth={0.5} my={2.5} />
          {compact(items).map((itemOrGroupedItem, index) => {
            if ('items' in itemOrGroupedItem) {
              return (
                <Fragment key={itemOrGroupedItem.name}>
                  {index !== 0 && <Box borderColor="rgba(21, 24, 26, 0.08)" borderWidth={0.5} my={2.5} />}
                  <ItemsGroupComponent
                    items={itemOrGroupedItem.items}
                    name={itemOrGroupedItem.name}
                    isNew={itemOrGroupedItem.isNew}
                    isFullSize={isFullSize}
                  />
                </Fragment>
              );
            }

            return <ItemComponent key={itemOrGroupedItem.to} {...itemOrGroupedItem} isFullSize={isFullSize} />;
          })}
        </VStack>
      </Stack>
    </motion.div>
  );
};

export const Nav = Object.assign(NavComponent, {
  configureDefaultLabels,
});
