import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import type {
  IExternalProgressStatementApprovalDTO,
  IExternalProgressStatementRejectDTO,
  IExternalProgressStatementResponseDTO,
} from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';
import { useToast } from '@graneet/lib-ui';
import { createQueryKeys } from '@lukemorales/query-key-factory';

import { apiNew } from 'features/api/services/apiNew.service';

const EXTERNAL_PROGRESS_STATEMENT_PATH = '/progress-statements/external';

export const externalProgressStatementsQueryKeys = createQueryKeys('external-progress-statement', {
  getExternalProgressStatement: (externalId: string) => ({
    queryKey: [EXTERNAL_PROGRESS_STATEMENT_PATH, externalId],
    queryFn: () =>
      apiNew.get<never, IExternalProgressStatementResponseDTO>(`${EXTERNAL_PROGRESS_STATEMENT_PATH}/${externalId}`),
  }),
});

export function useExternalProgressStatement(externalId: string) {
  return useSuspenseQuery(externalProgressStatementsQueryKeys.getExternalProgressStatement(externalId));
}

export function useApproveExternalProgressStatement() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ externalId, dto }: { externalId: string; dto: IExternalProgressStatementApprovalDTO }) =>
      apiNew.post<IExternalProgressStatementApprovalDTO, void>(
        `${EXTERNAL_PROGRESS_STATEMENT_PATH}/${externalId}/approve`,
        dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries(
        externalProgressStatementsQueryKeys.getExternalProgressStatement(params.externalId),
      );
    },
  });
}

export function useRejectExternalProgressStatement() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ externalId, dto }: { externalId: string; dto: IExternalProgressStatementRejectDTO }) =>
      apiNew.post<IExternalProgressStatementRejectDTO, void>(
        `${EXTERNAL_PROGRESS_STATEMENT_PATH}/${externalId}/reject`,
        dto,
      ),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries(
        externalProgressStatementsQueryKeys.getExternalProgressStatement(params.externalId),
      );
    },
  });
}

export function useResetExternalProgressStatement() {
  const { t } = useTranslation(['global']);
  const toast = useToast();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (externalId: string) =>
      apiNew.post<never, void>(`${EXTERNAL_PROGRESS_STATEMENT_PATH}/${externalId}/reset`),
    onError: () => {
      toast.error(t('global:errors.error'));
    },
    onSuccess: async (_, externalId) => {
      await queryClient.invalidateQueries(externalProgressStatementsQueryKeys.getExternalProgressStatement(externalId));
    },
  });
}
