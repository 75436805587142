import { Box, Flex, HStack, Skeleton, Stack, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';

import { QuoteLoadingCard } from './QuoteLoadingCard';

export const QuoteDrawerSkeleton = () => (
  <Stack
    width="21.5rem"
    height="100%"
    backgroundColor="#F9FAFB"
    gap={8}
    borderLeft="1px solid var(--Base-Opacity-Grey-200, rgba(0, 19, 58, 0.10))"
    overflow="hidden"
    transition="width 0.25s ease-in-out"
  >
    <Tabs paddingX={2} index={1} colorScheme="blue" size="sm" variant="unstyled" height="100%">
      <TabList borderBottom="1px solid var(--Base-Opacity-Grey-200, rgba(0, 19, 58, 0.10))" py={0}>
        <Tab paddingX={2} _selected={{ color: 'greenBrand.light' }}>
          <Skeleton height="14px" width="20px" />
        </Tab>
        <Tab paddingX={2} _selected={{ color: 'greenBrand.light' }}>
          <Skeleton height="14px" width="20px" />
        </Tab>
        <Tab paddingX={2} _selected={{ color: 'greenBrand.light' }}>
          <Skeleton height="14px" width="20px" />
        </Tab>
        <Flex justifyContent="flex-end" flex={1} alignItems="center" paddingY={2} paddingRight={1}>
          <Skeleton borderRadius="8px" width="32px" height="32px" />
        </Flex>
      </TabList>
      <TabPanels
        style={{
          overflowY: 'auto',
          height: 'calc(100% - 73px)',
        }}
      >
        <Box paddingX={2} height="100%" py={4}>
          <VStack spacing={3} align="stretch">
            <VStack width="100%" spacing="0.125rem" alignItems="flex-start">
              <HStack width="100%" justifyContent="space-between" alignItems="center" mb="0.625rem">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  boxShadow="0px 0px 0px 2px rgba(13, 18, 28, 0.10), 0px 0px 0px 1px #0E0D1C inset"
                  borderRadius="0.5rem"
                  boxSize="2.25rem"
                  border="0.125rem solid #FFF"
                >
                  <Skeleton width="100%" height="100%" startColor="gray.50" endColor="gray.300" />
                </Flex>
              </HStack>
              <Skeleton borderRadius="8px" width="85px" height="1.063rem" />
              <Skeleton borderRadius="8px" width="85px" height="0.75rem" />
            </VStack>
            <QuoteLoadingCard />
            <QuoteLoadingCard />
            <QuoteLoadingCard />
            <QuoteLoadingCard />
          </VStack>
        </Box>
      </TabPanels>
    </Tabs>
  </Stack>
);
