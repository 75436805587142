import { useTranslation } from 'react-i18next';
import type { IClientResponseDTO } from '@graneet/business-logic';
import type { FC } from 'react';

import { useClientEdit } from 'features/client/services/client.api';
import { TextEditionCard } from 'features/common/components/TextEditionCard';

interface ClientCommentCardProps {
  client: IClientResponseDTO;
}

export const ClientCommentCard: FC<ClientCommentCardProps> = ({ client }) => {
  const { t } = useTranslation(['clients']);

  const clientEditMutation = useClientEdit();

  const updateComment = async (comment: string) => clientEditMutation.mutateAsync({ id: client.id, dto: { comment } });

  return (
    <TextEditionCard
      title={t('clients:fields.comment')}
      errorLabel={t('clients:changeComment.error')}
      successLabel={t('clients:changeComment.success')}
      placeholder={t('clients:changeComment.placeholder')}
      text={client.comment}
      onTextEdited={updateComment}
    />
  );
};
