import { VStack, HStack, Text, useBoolean } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { useFormContext, useOnChangeValues } from 'graneet-form';
import { Button, SimpleHelpIcon, TextField, Tooltip } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import type { MailingFormValues } from './mailing.form';

import { Rule } from 'features/form/rules/Rule';

const Label = ({ children }: { children: ReactNode }) => (
  <Text fontWeight="600" fontSize="smaller" minW="fit-content" display="flex" alignItems="center">
    {children}
  </Text>
);

export const MailingModalSenderField = () => {
  const { t } = useTranslation(['mailing']);

  const [isReadOnly, setIsReadOnly] = useBoolean(true);

  const formContext = useFormContext<MailingFormValues>();

  const { senderFullName } = useOnChangeValues(formContext, ['senderFullName']);

  return (
    <>
      <VStack gap={2} w="100%">
        <HStack hidden={!isReadOnly} width="100%">
          <Label>{t('mailing:senderEditor.nameFieldLabel')}</Label>
          <Button
            tooltip={t('mailing:senderEditor.editTooltip')}
            tooltipProps={{ placement: 'bottom-start' }}
            onClick={setIsReadOnly.toggle}
            variant="outline"
            color="gray.900"
            fontWeight="normal"
            size="xs"
            py={0.5}
          >
            {senderFullName}
          </Button>
        </HStack>

        <HStack hidden={isReadOnly} width="100%">
          <Label>{t('mailing:senderEditor.nameFieldLabel')}</Label>
          <TextField<MailingFormValues> name="senderFullName" inputProps={{ fontSize: 'smaller' }}>
            <Rule.IsRequired />
          </TextField>
        </HStack>

        <HStack hidden={isReadOnly} width="100%">
          <Label>
            {t('mailing:senderEditor.emailFieldLabel')}
            <Tooltip label={t('mailing:senderEditor.replyToHelpTooltip')} shouldWrapChildren>
              <SimpleHelpIcon mx={1} />
            </Tooltip>
          </Label>
          <TextField<MailingFormValues> name="senderEmail" inputProps={{ fontSize: 'smaller' }}>
            <Rule.IsRequired />
            <Rule.IsEmail />
          </TextField>
        </HStack>
      </VStack>
    </>
  );
};
