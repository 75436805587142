import { useTranslation } from 'react-i18next';
import { Fieldset, IconAdvanced, TextField, TwoColumns, ColorPickerField, SimpleHelpIcon } from '@graneet/lib-ui';
import { Grid } from '@chakra-ui/react';
import type { FC } from 'react';
import { LEDGER_TYPES } from '@graneet/business-logic';

import { PROJECT_COLOR_PICKER_COLORS } from '../../constants/project-color.constant';
import type { ProjectCreateForm } from '../../forms/project-create-wizard';

import { Rule } from 'features/form/rules/Rule';
import { LedgerField } from 'features/ledger/components/LedgerField';

export const ProjectSummaryFieldset: FC = () => {
  const { t } = useTranslation(['project']);

  return (
    <Fieldset<ProjectCreateForm>
      handle="client"
      title={t('project:flow.information.title')}
      legend={t('project:flow.information.legend')}
      validationNames={['name']}
      data-testid="fieldSet:project.fields"
    >
      <Grid gap={6}>
        <TwoColumns>
          <TextField<ProjectCreateForm>
            data-testid="textField:project.name"
            name="name"
            label={t('project:fields.name')}
            rightIcon={<IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('project:flow.information.tooltip')} />}
            isRequired
          >
            <Rule.IsRequired />
          </TextField>
          <Grid templateColumns="1fr 13rem" gap={6}>
            <LedgerField<ProjectCreateForm>
              data-testid="textField:project.refCode"
              name="refCode"
              ledgerType={LEDGER_TYPES.PROJECT}
              label={t('project:fields.code')}
            />
            <TextField<ProjectCreateForm>
              data-testid="textField:project.orderNumber"
              name="purchaseOrderNumber"
              label={t('project:fields.purchaseOrderNumber')}
            />
          </Grid>
        </TwoColumns>
        <TextField<ProjectCreateForm>
          data-testid="textField:project.note"
          name="note"
          label={t('project:fields.note')}
        />
        <ColorPickerField<ProjectCreateForm>
          name="color"
          colors={PROJECT_COLOR_PICKER_COLORS}
          label={t('project:fields.projectColor')}
          isRequired
          helpIcon={<IconAdvanced icon={<SimpleHelpIcon />} tooltip={t('project:fields.projectColorTooltip')} />}
        >
          <Rule.IsRequired />
        </ColorPickerField>
      </Grid>
    </Fieldset>
  );
};
