import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { DrawerApi } from '@graneet/lib-ui';
import {
  SingleSelectField,
  Button,
  DrawersStack,
  PercentageField,
  RadioGroupField,
  TextField,
  formatVatRateToNumber,
  useCurrency,
  formatNumberToVatRate,
} from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { isNil } from 'lodash-es';
import type { ICustomDiscountCreationDTO } from '@graneet/business-logic';
import { CUSTOM_DISCOUNT_TYPES, FEATURE_FLAGS } from '@graneet/business-logic';
import { Circle, Flex, Grid, HStack, Text, VStack } from '@chakra-ui/react';

import type {
  CustomDiscountEditionForm,
  NegativeMultiplier,
  PositiveMultiplier,
} from 'features/custom-discount/forms/custom-discount-edition-form';
import { NEGATIVE_MULTIPLIER, POSITIVE_MULTIPLIER } from 'features/custom-discount/forms/custom-discount-edition-form';
import { CustomDiscountTotals } from 'features/custom-discount/components/CustomDiscountTotals';
import { useCreateQuoteCustomDiscount } from 'features/quote-discount/hooks/useCreateQuoteCustomDiscount';
import { useEditQuoteCustomDiscount } from 'features/quote-discount/hooks/useEditQuoteCustomDiscount';
import { Rule } from 'features/form/rules/Rule';
import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';
import { useQuote } from 'features/quote/hooks/useQuote';
import { useFeatureFlag } from 'features/feature-flag/hooks/useFeatureFlag';
import { AccountingVATField } from 'features/accounting/components/AccountingVATField';
import { useDefaultVatRate } from 'features/common/hooks/useDefaultVATRate';

interface QuoteCustomDiscountEditDrawerProps {
  drawer: DrawerApi<string>;

  initialCustomDiscountValue: Partial<CustomDiscountEditionForm> | null;

  /**
   * In case of edit, the custom discount id is required
   */
  customDiscountId?: string;
}

export const QuoteCustomDiscountEditDrawer: FC<QuoteCustomDiscountEditDrawerProps> = ({
  drawer,
  initialCustomDiscountValue,
  customDiscountId,
}) => {
  const { t } = useTranslation(['customDiscount', 'quote']);
  const { mapAmountToNumber } = useCurrency();

  const { startAnotherUpdate } = useQuoteEditContext();
  const quote = useQuote();

  const createQuoteCustomDiscount = useCreateQuoteCustomDiscount();
  const editQuoteCustomDiscount = useEditQuoteCustomDiscount();

  const form = useForm<CustomDiscountEditionForm>();
  const { getFormValues } = form;

  const hasStandardVATRates = useFeatureFlag(FEATURE_FLAGS.ACCOUNTING_STANDARD_VAT_RATES);

  const defaultVatRate = useDefaultVatRate();

  useEffect(() => {
    let multiplier: PositiveMultiplier | NegativeMultiplier = POSITIVE_MULTIPLIER;

    if (initialCustomDiscountValue?.customDiscountAmountExVAT) {
      multiplier = initialCustomDiscountValue.customDiscountAmountExVAT > 0 ? POSITIVE_MULTIPLIER : NEGATIVE_MULTIPLIER;
    }

    if (initialCustomDiscountValue?.customDiscountPercentage) {
      multiplier = initialCustomDiscountValue.customDiscountPercentage > 0 ? POSITIVE_MULTIPLIER : NEGATIVE_MULTIPLIER;
    }

    form.resetForm();
    form.setFormValues({
      ...initialCustomDiscountValue,
      customDiscountMultiplier: multiplier,
      customDiscountAmountExVAT: initialCustomDiscountValue?.customDiscountAmountExVAT
        ? initialCustomDiscountValue.customDiscountAmountExVAT * multiplier
        : undefined,
      customDiscountPercentage: initialCustomDiscountValue?.customDiscountPercentage
        ? initialCustomDiscountValue.customDiscountPercentage * multiplier
        : undefined,
      ...(defaultVatRate && { customDiscountVatRate: formatNumberToVatRate(defaultVatRate) }),
    });
  }, [defaultVatRate, form, initialCustomDiscountValue]);

  const onSubmit = useCallback(async () => {
    if (!quote) return;
    const {
      customDiscountAmountExVAT,
      customDiscountPercentage,
      customDiscountType,
      customDiscountName,
      customDiscountVatRate,
      customDiscountMultiplier,
    } = getFormValues();

    const customDiscountDto: ICustomDiscountCreationDTO = {
      name: customDiscountName!,
      vatRate: formatVatRateToNumber(customDiscountVatRate!),
      type: customDiscountType!,
      percentage: customDiscountPercentage
        ? customDiscountPercentage * (customDiscountMultiplier || POSITIVE_MULTIPLIER)
        : null,
      amountExVAT: !isNil(customDiscountAmountExVAT)
        ? mapAmountToNumber(customDiscountAmountExVAT * (customDiscountMultiplier || POSITIVE_MULTIPLIER))
        : null,
    };

    startAnotherUpdate();

    if (customDiscountId) {
      await editQuoteCustomDiscount(customDiscountId, quote?.id, customDiscountDto);
    } else {
      await createQuoteCustomDiscount(quote?.id, customDiscountDto);
    }

    drawer.onClose();
  }, [
    quote,
    getFormValues,
    mapAmountToNumber,
    startAnotherUpdate,
    customDiscountId,
    drawer,
    editQuoteCustomDiscount,
    createQuoteCustomDiscount,
  ]);

  const totalBeforeCustomDiscountExVAT = quote ? quote?.amountWithDiscountExVAT : 0;
  const { isValid: isFormValid } = useFormStatus(form);

  return (
    <Form form={form}>
      <DrawersStack.Drawer
        title={t('quote:discounts.drawers.customDiscount.title')}
        drawer={drawer}
        footer={
          <Flex justify="flex-end" w="100vw">
            <Button onClick={onSubmit} isDisabled={!isFormValid}>
              {t('quote:discounts.drawers.customDiscount.cta')}
            </Button>
          </Flex>
        }
      >
        <VStack alignItems="stretch" spacing={8}>
          <Text>{t('quote:discounts.drawers.customDiscount.description')}</Text>

          <Grid templateColumns="2fr 4fr 2fr" gap={3}>
            <SingleSelectField<CustomDiscountEditionForm>
              name="customDiscountMultiplier"
              label={t('customDiscount:fields.signType')}
              options={[
                {
                  value: POSITIVE_MULTIPLIER,
                  label: (
                    <Flex gap={2} alignItems="center">
                      <Circle size="0.725rem" bg="green.500" />
                      {t('customDiscount:fields.customDiscountIncreaseValue')}
                    </Flex>
                  ),
                },
                {
                  value: NEGATIVE_MULTIPLIER,
                  label: (
                    <Flex gap={2} alignItems="center">
                      <Circle size="0.725rem" bg="red.500" />
                      {t('customDiscount:fields.customDiscountDecreaseValue')}
                    </Flex>
                  ),
                },
              ]}
              isSearchable={false}
              isClearable={false}
              isRequired
            >
              <Rule.IsRequired />
            </SingleSelectField>
            <TextField<CustomDiscountEditionForm>
              name="customDiscountName"
              label={t('customDiscount:fields.name')}
              isRequired
            >
              <Rule.IsRequired />
            </TextField>

            {hasStandardVATRates ? (
              <AccountingVATField<CustomDiscountEditionForm>
                isClearable={false}
                name="customDiscountVatRate"
                label={t('customDiscount:fields.vatRate')}
                valueScale={100}
              >
                <Rule.IsRequired />
              </AccountingVATField>
            ) : (
              <PercentageField<CustomDiscountEditionForm>
                name="customDiscountVatRate"
                label={t('customDiscount:fields.vatRate')}
                min={0}
                isRequired
              >
                <Rule.IsRequired />
              </PercentageField>
            )}
          </Grid>

          <HStack spacing={8}>
            <RadioGroupField<CustomDiscountEditionForm> name="customDiscountType">
              <RadioGroupField.Option
                value={CUSTOM_DISCOUNT_TYPES.PERCENTAGE}
                label={t('customDiscount:fields.percentageType')}
              />
              <RadioGroupField.Option
                value={CUSTOM_DISCOUNT_TYPES.AMOUNT}
                label={t('customDiscount:fields.amountType')}
              />
            </RadioGroupField>

            <CustomDiscountTotals
              totalBeforeCustomDiscountExVAT={totalBeforeCustomDiscountExVAT}
              hasDiscount={!!quote?.discount}
            />
          </HStack>
        </VStack>
      </DrawersStack.Drawer>
    </Form>
  );
};
