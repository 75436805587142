import type { DocumentStatusCardAction } from '@graneet/lib-ui';
import { compact } from 'lodash-es';

import {
  getDisplayPublicLinkAction,
  getDownloadPDFAction,
  getOpenMailingAction,
  getPreviewPDFAction,
} from './progress-statement.actions.util';
import type { ProgressStatementActionsParams } from './progress-statement.actions.type';

export function getProgressStatementCancelledActions(
  params: ProgressStatementActionsParams,
): DocumentStatusCardAction[] {
  const actions: DocumentStatusCardAction[] = [];

  const previewAction = getPreviewPDFAction(params);

  if (previewAction) {
    actions.push({
      type: 'secondary',
      ...previewAction,
    });
  }

  actions.push({
    type: 'tertiaryDropdown',
    options: compact([
      getOpenMailingAction(params),
      getDownloadPDFAction(params),
      // @[ff: virality]
      params.hasViralityFeature ? getDisplayPublicLinkAction(params) : null,
    ]),
  });

  return actions;
}
