import type { ColDef } from '@ag-grid-community/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { QuoteComponentsGroupByType } from '@org/quotation-lib';
import uniq from 'lodash-es/uniq';
import Big from 'big.js';

import { ComponentTypeCell } from '../components/columns/ComponentTypeCell/ComponentTypeCell';
import { MarginCell } from '../components/columns/MarginCell/MarginCell';
import { MarginEditorCell } from '../components/columns/MarginCell/MarginEditorCell';
import { QuantityCell } from '../components/columns/QuantityCell/QuantityCell';
import { AmountCell } from '../components/columns/AmountCell/AmountCell';

import { useQuoteLotUpdateTotalMarginByComponentType } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateTotalMarginByComponentType';
import { useQuoteLotUpdateOverheadCostMarginByComponentType } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateOverheadCostMarginByComponentType';
import { useQuoteLotUpdateProfitMarginByComponentType } from 'features/quotation/quote-lot/hooks/useQuoteLotUpdateProfitMarginByComponentType';
import { useStore } from 'store/store';
import { useCommands } from 'features/quotation/undo-redo/command/useCommand';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const useColumnDefs = (nodeId: string) => {
  const { t } = useTranslation(['quote']);
  const quoteLotUpdateTotalMarginByComponentType = useQuoteLotUpdateTotalMarginByComponentType();
  const quoteLotUpdateOverheadCostMarginByComponentType = useQuoteLotUpdateOverheadCostMarginByComponentType();
  const quoteLotUpdateProfitMarginByComponentType = useQuoteLotUpdateProfitMarginByComponentType();
  const isEditable = useStore((store) => store.quoteEditable);

  const { executeCommand } = useCommands();
  const { quote } = useQuote();

  return useMemo(
    (): ColDef<QuoteComponentsGroupByType>[] => [
      {
        headerName: t('quote:quotation.columns.type'),
        field: 'componentTypeId',
        editable: false,
        cellRenderer: ComponentTypeCell,
      },
      {
        headerName: t('quote:quotation.columns.quantity'),
        field: 'quantity',
        cellStyle: { textAlign: 'right' },
        cellRenderer: QuantityCell,
        editable: false,
        valueGetter: (params) => {
          if (uniq(params.data?.units ?? []).length > 1) {
            return '-';
          }
          return params.data?.quantity || params.data?.hiddenCostQuantity
            ? Big(params.data?.quantity ?? '0')
                .plus(Big(params.data?.hiddenCostQuantity ?? '0'))
                .toString()
            : '-';
        },
      },
      {
        headerName: t('quote:quotation.columns.flatCostAmount'),
        cellStyle: { textAlign: 'right' },
        cellRenderer: AmountCell,
        field: 'flatCost',
        valueGetter: (params) =>
          params.data?.flatCost || params.data?.hiddenCostFlatCost
            ? Big(params.data?.flatCost ?? '0')
                .plus(Big(params.data?.hiddenCostFlatCost ?? '0'))
                .toString()
            : '-',
        editable: false,
      },
      {
        headerName: t('quote:quotation.columns.overheadCosts'),
        field: 'overheadCosts',
        cellStyle: { textAlign: 'right' },
        headerClass: 'center',
        cellRenderer: MarginCell,
        cellEditor: MarginEditorCell,
        editable: (params) => !!(params.data && params.data.overheadCosts !== null) && isEditable,
        valueGetter: (params) => params.data?.overheadCosts ?? '-',
        valueSetter: (params) => {
          if (params.newValue?.toString() === params.oldValue?.toString()) {
            return false;
          }
          const newValue = params.newValue?.replace(',', '.');
          return executeCommand(quoteLotUpdateOverheadCostMarginByComponentType(nodeId, params.data, newValue), quote);
        },
      },
      {
        headerName: t('quote:quotation.columns.profitMargin'),
        field: 'profitMargin',
        headerClass: 'center',
        cellStyle: { textAlign: 'right' },
        cellRenderer: MarginCell,
        cellEditor: MarginEditorCell,
        editable: (params) => !!(params.data && params.data.profitMargin !== null) && isEditable,
        valueGetter: (params) => params.data?.profitMargin ?? '-',
        valueSetter: (params) => {
          if (params.oldValue?.toString() === params.newValue?.toString()) {
            return false;
          }
          const newValue = params.newValue?.replace(',', '.');
          return executeCommand(quoteLotUpdateProfitMarginByComponentType(nodeId, params.data, newValue), quote);
        },
      },
      {
        headerName: t('quote:quotation.columns.totalMargin'),
        headerClass: 'center',
        cellStyle: { textAlign: 'right' },
        cellRenderer: MarginCell,
        cellEditor: MarginEditorCell,
        editable: (params) => !!(params.data && params.data.totalMargin !== null) && isEditable,
        valueGetter: (params) => params.data?.totalMargin ?? '-',
        valueSetter: (params) => {
          if (params.oldValue?.toString() === params.newValue?.toString()) {
            return false;
          }
          const newValue = params.newValue?.replace(',', '.');
          return executeCommand(quoteLotUpdateTotalMarginByComponentType(nodeId, params.data, newValue), quote);
        },
      },
    ],
    [
      t,
      isEditable,
      executeCommand,
      quoteLotUpdateOverheadCostMarginByComponentType,
      nodeId,
      quote,
      quoteLotUpdateProfitMarginByComponentType,
      quoteLotUpdateTotalMarginByComponentType,
    ],
  );
};
