import type { QuoteNodeObject } from '@org/quotation-lib';
import type { IRowNode, RowDragEvent } from '@ag-grid-community/core';
import { useCallback } from 'react';

export const useOnRowDragPositionInPercentage = () =>
  useCallback(
    (
      movingNode: IRowNode<QuoteNodeObject>,
      overNode: IRowNode<QuoteNodeObject>,
      event: RowDragEvent<QuoteNodeObject>,
    ): number | null => {
      if (movingNode === overNode) {
        return null;
      }

      const rowHeight = overNode?.rowHeight;
      const rowTop = overNode?.rowTop;

      if (!rowHeight || rowTop === null || rowTop === undefined) {
        return null;
      }

      const point = event.y - rowTop;
      const percentage = (point * 100) / rowHeight;
      return percentage;
    },
    [],
  );
