import type { FC, ReactNode } from 'react';
import type { ListType } from '@lexical/list';
import { $setBlocksType } from '@lexical/selection';
import type { HeadingTagType } from '@lexical/rich-text';
import { $createHeadingNode } from '@lexical/rich-text';
import type { LexicalEditor } from 'lexical';
import { $createParagraphNode, $getSelection, $isRangeSelection } from 'lexical';
import { Box, Text, Flex } from '@chakra-ui/react';

import { ParagraphIcon } from '../../../../../Icons';
import { toolbarTranslations } from '../../../../configureDefaultLabels';
import { SingleSelect } from '../../../../../SingleSelect/SingleSelect';

export type BlockType = null | ListType | HeadingTagType | 'paragraph';

interface BlockOptionsProps {
  blockType: BlockType;
  editor: LexicalEditor;
}

const options: Array<{ value: BlockType; label: ReactNode }> = [
  {
    label: (
      <Flex alignItems="baseline">
        <Text>H1</Text> &nbsp;&nbsp;
        <Text>{toolbarTranslations.h1Title}</Text>
      </Flex>
    ),
    value: 'h1',
  },
  {
    label: (
      <Flex alignItems="baseline">
        <Text>H2</Text> &nbsp;&nbsp;
        <Text>{toolbarTranslations.h2Title}</Text>
      </Flex>
    ),
    value: 'h2',
  },
  {
    label: (
      <Flex alignItems="baseline">
        <Text>H3</Text> &nbsp;&nbsp;
        <Text>{toolbarTranslations.h3Title}</Text>
      </Flex>
    ),
    value: 'h3',
  },
  {
    label: (
      <Flex alignItems="baseline">
        <ParagraphIcon /> &nbsp;&nbsp;
        <Text>{toolbarTranslations.paragraph}</Text>
      </Flex>
    ),
    value: 'paragraph',
  },
] as const;

export const BlockOptions: FC<BlockOptionsProps> = ({ blockType, editor }) => {
  const handleChangeBlockType = (selectedBlockOption: HeadingTagType | 'paragraph') => {
    if (blockType !== selectedBlockOption) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () =>
            selectedBlockOption === 'paragraph' ? $createParagraphNode() : $createHeadingNode(selectedBlockOption),
          );
        }
      });
    }
  };

  return (
    <Box w="11rem">
      <SingleSelect<{ value: BlockType; label: ReactNode }>
        key={blockType}
        options={options}
        isSearchable={false}
        isClearable={false}
        onChange={(e) => {
          if (e?.value) {
            handleChangeBlockType(e.value as HeadingTagType | 'paragraph');
          }
        }}
        value={options.find((o) => o.value === blockType) ?? options[3]}
        noBorder
      />
    </Box>
  );
};
