import type { IComponentTypesListingDTO, IVat } from '@graneet/business-logic';
import { QUOTE_ROW_HEIGHT } from '@graneet/business-logic';
import type { DrawerHandle, ImageListAndUploadHandle, RichTextGetterHandler } from '@graneet/lib-ui';
import type { QuoteComposeDTOClient, QuoteComposeDTOProject, QuoteContactDTO } from '@org/graneet-bff-client';
import type {
  QuoteCustomDiscountObject,
  QuoteFlatObject,
  QuoteNodeObject,
  QuoteSalesDiscountObject,
  QuoteTreeFlatObject,
} from '@org/quotation-lib';
import type { AgGridReact } from '@ag-grid-community/react';
import type { RefObject } from 'react';

import { type QuoteSetting, createQuoteSettingSlice, type QuoteColumns } from './quoteSettingSliceZustant';

import type { QuoteSummaryDisplayItems } from 'features/quotation/ag-grid-quote/components/QuoteSummaryBar';

export type QuoteSlice = {
  quote: QuoteFlatObject | null;
  quoteTree: QuoteTreeFlatObject | null;
  quoteNode: QuoteNodeObject[];
  quoteNodeHiddenCost: QuoteNodeObject[];
  quoteCustomDiscounts: QuoteCustomDiscountObject[];
  quoteSalesDiscount: QuoteSalesDiscountObject | null;
  quoteNbSelectedRows: number;
  quoteHiddenCostNbSelectedRows: number;
  quoteSelectedRows: Map<string, QuoteNodeObject>;
  quoteHiddenCostSelectedRows: Map<string, QuoteNodeObject>;
  quoteGridRef: RefObject<AgGridReact<QuoteNodeObject>> | null;
  quoteHiddenCostGridRef: RefObject<AgGridReact<QuoteNodeObject>> | null;
  quoteComponentTypes: IComponentTypesListingDTO[];
  quoteProject: QuoteComposeDTOProject | null;
  quoteImageUploadRefs: Map<string, RefObject<ImageListAndUploadHandle>>;
  quoteCollapsedRows: string[];
  quoteExpandedRows: string[];
  quoteEditable: boolean;
  quoteClient: QuoteComposeDTOClient | null;
  quoteContacts: QuoteContactDTO[] | null;
  quoteRichTextEditorRef: RefObject<RichTextGetterHandler> | null;
  quoteDrawerRichTextEditorRef: RefObject<RichTextGetterHandler> | null;
  quoteSummaryBarItemDisplayed: QuoteSummaryDisplayItems;
  quoteRootColumns: QuoteColumns;
  quoteHiddenCostColumns: QuoteColumns;
  selectedRows: any[];
  quoteImportItemDrawerIsOpen: boolean;
  quoteImportItemTargetParentId: string | null;
  quoteImportComponentDrawerIsOpen: boolean;
  quoteImportComponentTargetParentId: string | null;
  quoteNewlyCreatedNodeId: string | null;
  quoteMembers: string[];
  quoteNotesModal: { open: boolean; note: string | null; nodeId: string | null };
  quoteLotDefaultVatRateModal: { isOpen: boolean; nodeId: string | null };
  quoteBatiprixModalIsOpen: boolean;
  quoteAmountIsUnreachable: boolean;
  quoteBatiprixModalParentId: string | null;
  quoteDrawerRef: RefObject<DrawerHandle> | null;
  quoteLibrarySaved: boolean;
  quoteAreAllRowsCollapsed: boolean;
  quoteStandardVATRates: IVat[];
  quoteRowHeight: QUOTE_ROW_HEIGHT;
  quoteSetting: QuoteSetting;
};

const initialSlice: QuoteSlice = {
  quote: null,
  quoteTree: null,
  quoteNode: [],
  quoteNodeHiddenCost: [],
  quoteCustomDiscounts: [],
  quoteSalesDiscount: null,
  quoteProject: null,
  quoteClient: null,
  quoteContacts: null,
  quoteNbSelectedRows: 0,
  quoteHiddenCostNbSelectedRows: 0,
  quoteSelectedRows: new Map<string, QuoteNodeObject>(),
  quoteHiddenCostSelectedRows: new Map<string, QuoteNodeObject>(),
  quoteGridRef: null,
  quoteHiddenCostGridRef: null,
  quoteComponentTypes: [],
  quoteCollapsedRows: [],
  quoteExpandedRows: [],
  quoteImageUploadRefs: new Map<string, RefObject<ImageListAndUploadHandle>>(),
  quoteEditable: false,
  quoteRichTextEditorRef: null,
  quoteDrawerRichTextEditorRef: null,
  quoteSummaryBarItemDisplayed: createQuoteSettingSlice().quoteSummaryBarItemDisplayed,
  quoteRootColumns: createQuoteSettingSlice().quoteRootColumns,
  quoteHiddenCostColumns: createQuoteSettingSlice().quoteHiddenCostColumns,
  selectedRows: [],
  quoteImportItemDrawerIsOpen: false,
  quoteImportItemTargetParentId: null,
  quoteImportComponentDrawerIsOpen: false,
  quoteImportComponentTargetParentId: null,
  quoteNewlyCreatedNodeId: null,
  quoteMembers: [],
  quoteNotesModal: { open: false, note: null, nodeId: null },
  quoteLotDefaultVatRateModal: { isOpen: false, nodeId: null },
  quoteBatiprixModalIsOpen: false,
  quoteAmountIsUnreachable: false,
  quoteBatiprixModalParentId: null,
  quoteDrawerRef: null,
  quoteLibrarySaved: false,
  quoteAreAllRowsCollapsed: false,
  quoteStandardVATRates: [],
  quoteRowHeight: QUOTE_ROW_HEIGHT.Medium,
  quoteSetting: createQuoteSettingSlice().quoteSetting,
};

export const createQuoteSlice = () => initialSlice;
