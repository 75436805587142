import { Box, HStack, Menu, MenuButton, MenuItem, MenuList, Portal, useDisclosure } from '@chakra-ui/react';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { flatten } from 'lodash-es';

import { EllipsisText } from '../../EllipsisText/EllipsisText';
import { SimpleCheckCircleBlackIcon } from '../../Icons/v2/SimpleCheckCircleBlackIcon';
import { SimpleSelectDropDownIcon } from '../../Icons/v2/SimpleSelectDropDownIcon';

export type ItemProps = {
  name: string | null;
  value: string;
  items?: ItemProps[];
};

type DropDownSelectProps = {
  onChange: (value: string) => void;
  selectedValue?: string;
  isEditable: boolean;
  items: ItemProps[];
  placeholder?: string;
  itemPlaceholder?: string;
  noBorder?: boolean;
};

const Item: FC<
  { item: ItemProps; level: number; itemPlaceholder?: string } & Pick<DropDownSelectProps, 'onChange' | 'selectedValue'>
> = ({ item, level, onChange, selectedValue, itemPlaceholder }) => {
  const isSelected = useMemo(() => selectedValue === item.value, [selectedValue, item.value]);

  const handleClick = useCallback(() => onChange(item.value), [onChange, item.value]);

  return (
    <>
      <MenuItem
        onClick={handleClick}
        cursor="pointer"
        alignItems="center"
        width="100%"
        py="0.25rem"
        px="0.5rem"
        pl={`${level * 0.5 + 0.5}rem`}
        _hover={{
          background: 'rgba(0, 21, 63, 0.05)',
        }}
      >
        <HStack spacing={2} alignItems="center" width="100%" overflow="hidden">
          {item.name ? (
            <EllipsisText color="#1F2A37" fontSize="0.813rem" fontWeight={500}>
              {item.name}
            </EllipsisText>
          ) : (
            <EllipsisText color="blackAlpha.500" fontSize="0.813rem" fontWeight={500}>
              {itemPlaceholder}
            </EllipsisText>
          )}

          {isSelected && (
            <Box ml="auto">
              <SimpleCheckCircleBlackIcon boxSize={3} />
            </Box>
          )}
        </HStack>
      </MenuItem>
      {item.items &&
        item.items.map((subItem) => (
          <Item
            key={subItem.value}
            item={subItem}
            level={level + 1}
            onChange={onChange}
            selectedValue={selectedValue}
            itemPlaceholder={itemPlaceholder}
          />
        ))}
    </>
  );
};

export const flattenItems = (items: ItemProps[]): ItemProps[] =>
  flatten(items.map(({ name, value, items: subItems }) => [{ name, value }, ...flattenItems(subItems ?? [])]));

export const ControlledDropDownSelect: FC<
  DropDownSelectProps & {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  }
> = ({
  items,
  onChange,
  selectedValue,
  isEditable,
  placeholder,
  itemPlaceholder,
  isOpen,
  onOpen,
  onClose,
  noBorder,
}) => {
  const selectedItem = useMemo(
    () => flattenItems(items).find((item) => item.value === selectedValue),
    [items, selectedValue],
  );

  const handleOnChange = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  const handleOpen = useCallback(() => {
    if (!isEditable) return;
    onOpen();
  }, [isEditable, onOpen]);

  return (
    <Menu gutter={0} matchWidth isLazy onOpen={handleOpen} isOpen={isOpen} onClose={onClose}>
      <MenuButton width="100%" as={Box} cursor={isEditable ? 'pointer' : 'not-allowed'}>
        <HStack
          width="100%"
          background="white"
          boxShadow={
            isOpen || noBorder
              ? 'none'
              : '0px 0px 0px 1px rgba(17, 24, 28, 0.08), 0px 1px 2px -1px rgba(17, 24, 28, 0.08), 0px 2px 4px 0px rgba(17, 24, 28, 0.04)'
          }
          borderRadius="0.375rem"
          border={isOpen ? '1px solid' : undefined}
          borderColor={noBorder ? 'transparent' : 'rgba(0, 19, 58, 0.10)'}
          borderBottomLeftRadius={isOpen ? 0 : '0.375rem'}
          borderBottomRightRadius={isOpen ? 0 : '0.375rem'}
          height="1.75rem"
          gap="0.25rem"
          px="0.5rem"
        >
          <EllipsisText color="#1F2A37" fontSize="0.813rem" fontWeight={500}>
            {selectedItem?.name ?? placeholder}
          </EllipsisText>
          {isOpen && selectedItem ? (
            <SimpleCheckCircleBlackIcon ml="auto" boxSize={3} />
          ) : (
            <SimpleSelectDropDownIcon color="#6C737F" boxSize={3} ml="auto" />
          )}
        </HStack>
      </MenuButton>
      <Portal>
        <MenuList
          gap="0.5rem"
          borderTopLeftRadius={0}
          borderTopRightRadius={0}
          borderBottomLeftRadius="0.375rem"
          borderBottomRightRadius="0.375rem"
          border="1px solid"
          borderColor="rgba(0, 19, 58, 0.10)"
          borderTopWidth={0}
          boxSizing="border-box"
          maxHeight="10rem"
          overflowY="auto"
          boxShadow="0px 0px 0px 2px #FFF inset, 0px 12px 16px -4px rgba(13, 18, 28, 0.14), 0px 4px 6px -2px rgba(13, 18, 28, 0.08)"
          pt={0}
        >
          {items.map((item) => (
            <Item
              key={item.value}
              item={item}
              itemPlaceholder={itemPlaceholder}
              level={0}
              onChange={handleOnChange}
              selectedValue={selectedValue}
            />
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export const DropDownSelect: FC<DropDownSelectProps> = ({ ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return <ControlledDropDownSelect {...props} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />;
};
