import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SimpleTreeIndicatorIcon: FC<IconProps> = (props) => (
  <Icon viewBox="0 0 20 44" stroke="#e9ebed" strokeWidth="2px" fill="none" {...props}>
    <g clipPath="url(#clip0_727_3940)">
      <path d="M10 0V18C10 20.2091 11.7909 22 14 22H18" stroke="currentColor" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_727_3940">
        <rect width="20" height="44" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
