import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Wizard, useCurrency, useToast } from '@graneet/lib-ui';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { Stack } from '@chakra-ui/react';
import { Form, useStepForm } from 'graneet-form';
import type { IClientResponseDTO } from '@graneet/business-logic';
import { random } from 'lodash-es';
import { QuoteStatus } from '@org/graneet-bff-client';

import { useData } from 'features/api/hooks/useData';
import { QueryWrapper } from 'features/api/components/QueryWrapper';
import { Loading } from 'features/common/components/Loading';
import { ProjectImportFileFieldset } from 'features/project/components/fieldsets/ProjectImportFileFieldset';
import { PROJECT_COLOR_PICKER_COLORS } from 'features/project/constants/project-color.constant';
import { useWizardLabels } from 'features/form/hooks/useWizardLabels';
import { useProjectAddImportFile, useProjectCreate } from 'features/project/services/project.api';
import { SUPPORT_EMAIL } from 'features/common/constants/support-email.constant';
import { updateQuoteStatusToAccepted, getQuoteById } from 'features/quote/services/quote.api';
import { usePaymentMethods } from 'features/payment-method/services/payment-method.api';
import { useAppContext } from 'features/app/contexts/AppContext';
import { useHideNavbar } from 'features/common/contexts/NavbarContext';
import { useCompanyPaymentTerms } from 'features/company/services/company.api';
import type { ProjectCreateWizard } from 'features/project/forms/project-create-wizard';
import { useContactAssociatedToQuote, useContactsAssociateToProject } from 'features/contact/services/contact.api';
import { ProjectClientMultiContactCreateFieldset } from 'features/project/components/fieldsets/ProjectClientMultiContactCreateFieldset';
import { ProjectKmExpenseFieldset } from 'features/project/components/fieldsets/ProjectKmExpenseFieldset';
import { EMPTY_GETTER } from 'features/api/hooks/useDataGetter';
import { ProjectSummaryFieldset } from 'features/project/components/fieldsets/ProjectSummaryFieldset';
import { ProjectAddressFieldset } from 'features/project/components/fieldsets/ProjectAddressFieldset';
import { ProjectAccountManagersFieldset } from 'features/project/components/fieldsets/ProjectAccountManagersFieldset';
import { ProjectPaymentTermsFieldset } from 'features/project/components/fieldsets/ProjectPaymentTermsFieldset';
import { useQuotationProxyApis } from 'features/quotation/quote-common/hooks/useQuoteProxyApis';
import { useQuotationApi } from 'features/quotation/services/quote.api';

export interface NewProjectOnePageStepProps {
  quoteId?: number;
  quoteUUID?: string;
}

const ProjectOnePageStep: FC<NewProjectOnePageStepProps> = ({ quoteId, quoteUUID }) => {
  const { form, initFormValues } = useStepForm<ProjectCreateWizard, 'project'>();
  const { currentUser } = useAppContext();

  const quoteProxy = useQuotationProxyApis().quoteProxyApi;

  const paymentMethods = usePaymentMethods();
  const paymentTerms = useCompanyPaymentTerms();
  const contacts = useContactAssociatedToQuote(quoteId);
  const oldQuote = useData(useCallback(() => (quoteId ? getQuoteById(quoteId) : EMPTY_GETTER), [quoteId]));
  const quote = useData(
    // TODO: improve this call, reduce data fetched
    useCallback(() => (quoteUUID ? quoteProxy!.getQuoteComposeById(quoteUUID) : EMPTY_GETTER), [quoteProxy, quoteUUID]),
  );

  useEffect(() => {
    if (!currentUser) return;
    /*
      1. There is accountManagersIds given (empty or not), it's the default value
      2. Current user has isDisplayed equal to true: this is the default value
      3. There is no default account managers
     */
    let accountManagersIdsFromQuote: number[] | undefined;

    if (oldQuote.data) {
      accountManagersIdsFromQuote = (oldQuote.data.quoteAccountManagers || []).map(({ user }) => user.id);
    }

    // New quote case
    if (quote.data) {
      accountManagersIdsFromQuote = (quote.data.accountManagers || []).map((am) => parseInt(am.toString(), 10));
    }

    const defaultAccountManagers = accountManagersIdsFromQuote ?? (currentUser.isDisplayed ? [currentUser!.id] : []);

    const randomColor = random(PROJECT_COLOR_PICKER_COLORS.length - 1);

    let initFormValuesWithQuote = {};

    if (oldQuote.data) {
      initFormValuesWithQuote = {
        primaryClient: (oldQuote.data.client as IClientResponseDTO) ?? undefined,
        name: oldQuote.data.name,
        projectAddressAddress: oldQuote.data.projectAddress?.address ?? oldQuote.data.client?.address?.address,
        projectAddressPostalCode: oldQuote.data.projectAddress?.postalCode ?? oldQuote.data.client?.address?.postalCode,
        projectAddressCity: oldQuote.data.projectAddress?.city ?? oldQuote.data.client?.address?.city,
        projectAddressCountry: oldQuote.data.projectAddress?.country ?? oldQuote.data.client?.address?.country,
        contacts: (contacts.data ?? []).map((contact) => ({
          entity: contact,
          isDefaultEmailRecipient: contact.isQuoteDefaultEmailRecipient,
        })),
      };
    }

    if (quote.data) {
      initFormValuesWithQuote = {
        primaryClient: (quote.data.client as IClientResponseDTO) ?? undefined,
        name: quote.data.name,
        projectAddressAddress: quote.data.address?.address ?? quote.data.client?.address?.address,
        projectAddressPostalCode: quote.data.address?.postalCode ?? quote.data.client?.address?.postalCode,
        projectAddressCity: quote.data.address?.city ?? quote.data.client?.address?.city,
        projectAddressCountry: quote.data.address?.country ?? quote.data.client?.address?.country,
        contacts: (quote.data.contacts ?? []).map((contact) => ({
          entity: contact,
          // TODO: edit contact to include isQuoteDefaultEmailRecipient
          isDefaultEmailRecipient: false,
          // isDefaultEmailRecipient: contact.isQuoteDefaultEmailRecipient,
        })),
      };
    }

    initFormValues({
      projectAccountManagers: defaultAccountManagers,
      color: PROJECT_COLOR_PICKER_COLORS[randomColor].value,
      ...initFormValuesWithQuote,
    });
  }, [contacts.data, currentUser, initFormValues, quote.data, oldQuote]);

  // SWR, to remove when migrated to Tanstack query
  if (quote.loading && oldQuote.loading) {
    return <Loading />;
  }

  return (
    <Form form={form}>
      <Stack spacing={6} w="100%">
        <Container size="lg">
          <ProjectClientMultiContactCreateFieldset
            reloadData={async () => {
              quote.fetch();
              oldQuote.fetch();
            }}
          />
        </Container>

        <Container size="lg">
          <ProjectSummaryFieldset />
        </Container>

        <Container size="lg">
          <ProjectAddressFieldset />
        </Container>

        <Container size="lg">
          <ProjectKmExpenseFieldset />
        </Container>

        <Container size="lg">
          <ProjectImportFileFieldset />
        </Container>

        <Container size="lg">
          <ProjectAccountManagersFieldset />
        </Container>

        <Container size="lg">
          <ProjectPaymentTermsFieldset
            initFormValues={initFormValues}
            paymentMethods={paymentMethods.data}
            companyPaymentTerms={paymentTerms.data}
          />
        </Container>
      </Stack>
    </Form>
  );
};

export const CreateProjectScreen = () => {
  const { t } = useTranslation(['quote', 'global', 'project']);
  const history = useHistory();
  const { state } = useLocation<{ quoteId?: string; settledDate: string; quoteUUID?: string }>();
  const { quoteId, settledDate, quoteUUID } = state || {};
  const toast = useToast();
  const wizardLabels = useWizardLabels({
    save: t('project:flow.create'),
  });
  useHideNavbar();
  const { useQuoteChangeStatus } = useQuotationApi();
  const { mapAmountToNumber } = useCurrency();

  const quoteChangeStatusMutation = useQuoteChangeStatus();
  const projectCreateMutation = useProjectCreate();
  const projectAddImportFileMutation = useProjectAddImportFile();
  const contactsAssociateToProjectMutation = useContactsAssociateToProject();

  // Callbacks
  const onSubmit = useCallback(
    async (values: ProjectCreateWizard) => {
      // -- Save project
      const { project: formValues } = values;

      const project = await projectCreateMutation.mutateAsync({
        name: formValues.name,
        refCode: formValues.refCode || null,
        note: formValues.note || null,
        purchaseOrderNumber: formValues.purchaseOrderNumber,
        deadlineType: formValues.deadlineType,
        paymentTerm: formValues.paymentTerm,
        color: formValues.color,
        address: {
          address: formValues.projectAddressAddress,
          postalCode: formValues.projectAddressPostalCode,
          city: formValues.projectAddressCity,
          country: formValues.projectAddressCountry,
        },
        paymentMethodId: formValues.paymentMethod.id,
        accountManagerIds: formValues.projectAccountManagers,
        isLedgerEnabled: formValues.isLedgerEnabled,
        primaryClientId: formValues.primaryClient.id,
        defaultKmExpense: mapAmountToNumber(formValues.defaultKmExpense ?? 0),
      });

      if (project && formValues.contacts && formValues.contacts.length > 0) {
        await contactsAssociateToProjectMutation.mutateAsync({
          id: project.id,
          dto: {
            contacts: formValues.contacts.map((contact) => ({
              id: contact.entity.id,
              isDefaultEmailRecipient: contact.isDefaultEmailRecipient,
            })),
          },
        });
      }

      if (formValues.importFile) {
        await projectAddImportFileMutation.mutateAsync({
          id: project.id,
          dto: {
            importFile: formValues.importFile,
            comment: formValues.importComment ?? null,
          },
        });
      }

      if (!quoteId && !quoteUUID) {
        history.replace(`/projects/${project.id}`);
        return;
      }

      if (quoteId) {
        // -- Save quote if coming from quote accepting
        const [acceptError] = await updateQuoteStatusToAccepted(+quoteId, {
          settledDate,
          projectId: project.id,
          subProjectId: null,
        });

        if (acceptError) {
          toast.error(t('global:words.c.error'), t('quote:errors.cannotTransformToCommand', { email: SUPPORT_EMAIL }));
        } else {
          toast.success(
            t('global:words.c.success'),
            t('quote:successes.commandAdded', {
              name: project.name,
            }),
          );
        }
      }

      if (quoteUUID) {
        await quoteChangeStatusMutation.mutateAsync({
          quoteId: quoteUUID,
          status: QuoteStatus.Accepted,
          settledAt: settledDate,
          projectId: project.id,
          subProjectId: undefined,
        });

        toast.success(t('global:words.c.success'), t('quote:successes.commandAdded', { name: project.name }));
      }

      history.replace(`/projects/${project.id}/contracts`);
    },
    [
      projectCreateMutation,
      mapAmountToNumber,
      quoteId,
      quoteUUID,
      history,
      contactsAssociateToProjectMutation,
      projectAddImportFileMutation,
      settledDate,
      toast,
      t,
      quoteChangeStatusMutation,
    ],
  );

  const onQuit = useCallback(() => {
    if (quoteId) {
      history.push(`/opportunities/quotes/${quoteId}/summary`);
      return;
    }

    if (quoteUUID) {
      history.push(`/opportunities/quotation/${quoteUUID}/summary`);
      return;
    }

    history.push('/projects');
  }, [history, quoteId, quoteUUID]);

  return (
    <Wizard<ProjectCreateWizard>
      headerTitle={t('project:flow.title')}
      labels={wizardLabels}
      onQuit={onQuit}
      onFinish={onSubmit}
    >
      <Wizard.Step<ProjectCreateWizard> name="project">
        <QueryWrapper>
          <ProjectOnePageStep quoteId={quoteId ? parseInt(quoteId, 10) : undefined} quoteUUID={quoteUUID} />
        </QueryWrapper>
      </Wizard.Step>
    </Wizard>
  );
};
