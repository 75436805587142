/* eslint-disable no-nested-ternary */
import {
  VStack,
  type IconProps,
  Avatar,
  Text,
  HStack,
  Box,
  Popover,
  Portal,
  PopoverContent,
  PopoverBody,
  PopoverTrigger,
  useDisclosure,
  CircularProgress,
} from '@chakra-ui/react';
import type { ChangeEvent, ReactElement } from 'react';
import { useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useDebounce } from '../../../../../hooks/useDebounce';
import { SimpleAddIcon } from '../../../../Icons/v2/SimpleAddIcon';
import { MultiLevelMenu, type MenuElement } from '../../../Menu/MultiLevelMenu';
import { SellsheetCard } from '../Card';
import { QuotationInput } from '../../../Input/Input';
import { GraneetButton } from '../../../Button/Button';
import { SimpleSearchIcon } from '../../../../Icons/v2/SimpleSearchIcon';
import { GraneetIconButton } from '../../../IconButton/IconButton';
import { quotationCardTranslations } from '../translations';

type User = {
  id: number | string;
  firstName: string | null;
  lastName: string | null;
  subtitle?: string;
};

type UsersCardProps<T extends User> = {
  users: T[];
  title: string;
  isEditable: boolean;
  onSearch: (search: string) => Promise<T[]> | T[];
  onSelect?: (user: T) => void;
  addCTA?: {
    label: string;
    onClick: () => void;
  };
  action?: {
    icon: ReactElement<IconProps>;
    iconAction?: (id: T['id']) => void;
    actions?: MenuElement[];
  };
  placeholder: string;
};

const Users = <T extends User>({
  users,
  action,
  onSelect,
  allowSelection,
  isEditable,
}: Pick<UsersCardProps<T>, 'users' | 'action' | 'onSelect' | 'isEditable'> & {
  allowSelection?: boolean;
}) => {
  const handleSelect = useCallback(
    (user: T) => () => (allowSelection && onSelect ? onSelect(user) : undefined),
    [onSelect, allowSelection],
  );

  const formatUserDisplayName = useCallback(
    (user: T) => `${user.firstName ? user.firstName : ''} ${user.lastName}`.trim(),
    [],
  );

  const getMenuActions = useCallback(
    (user: T) =>
      (action?.actions ?? []).map((a) => ({
        ...a,
        ...('onClick' in a ? { onClick: () => a.onClick(user) } : {}),
      })),
    [action?.actions],
  );

  return (
    <VStack width="100%" gap="0.5rem" alignItems="flex-start">
      {users.map((user) => (
        <HStack
          key={user.id}
          gap="0.75rem"
          p={2}
          width="100%"
          _hover={
            allowSelection
              ? {
                  cursor: 'pointer',
                  borderRadius: '0.375rem',
                  background: 'rgba(0, 42, 85, 0.03)',
                }
              : {}
          }
          onClick={handleSelect(user)}
        >
          <Avatar
            name={formatUserDisplayName(user)}
            border="1px solid rgba(0, 21, 63, 0.05)"
            boxSize="2.25rem"
            background="#F3F4F6"
            color="white"
            fontWeight={600}
            fontSize="0.813rem"
            size="sm"
          />
          <VStack alignItems="flex-start" gap={0}>
            <Text color="#1F2A37" fontSize="0.813rem" fontWeight={500}>
              {formatUserDisplayName(user)}
            </Text>
            {user.subtitle && (
              <Text color="#4D5761" fontSize="0.75rem" fontWeight={400}>
                {user.subtitle}
              </Text>
            )}
          </VStack>
          {action && isEditable && (
            <Box ml="auto">
              {action.actions ? (
                <MultiLevelMenu items={getMenuActions(user)} id={uuid()}>
                  <GraneetIconButton icon={action.icon} color="ghost" />
                </MultiLevelMenu>
              ) : (
                <GraneetIconButton icon={action.icon} color="ghost" onClick={() => action.iconAction?.(user.id)} />
              )}
            </Box>
          )}
        </HStack>
      ))}
    </VStack>
  );
};

export const UsersCard = <T extends User>({
  users,
  title,
  isEditable,
  addCTA,
  action,
  placeholder,
  onSearch,
  onSelect,
}: UsersCardProps<T>) => {
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<T[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleSearch = useDebounce(async (searchValue: string) => {
    const result = await onSearch(searchValue);
    if (result) {
      setSearchResults(result);
    }
    setLoading(false);
  }, 500);

  const handleOnClose = useCallback(() => {
    setSearch('');
    setSearchResults([]);
    onClose();
  }, [onClose]);

  const handleSearchChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value;
      setSearch(searchValue);
      if (searchValue === '') {
        handleOnClose();
      } else {
        onOpen();
        setLoading(true);
        handleSearch(searchValue);
      }
    },
    [handleSearch, onOpen, handleOnClose],
  );

  const handleOnFocus = useCallback(async () => {
    onOpen();
    setLoading(true);
    handleSearch('');
  }, [handleSearch, onOpen]);

  const handleOnSelect = useCallback(
    (user: T) => {
      if (onSelect) {
        onSelect(user);
      }
      handleOnClose();
    },
    [onSelect, handleOnClose],
  );

  const handleOnBlur = useCallback(() => setTimeout(() => handleOnClose(), 100), [handleOnClose]);

  return (
    <SellsheetCard title={title}>
      <VStack width="100%" gap="0.75rem" alignItems="flex-start">
        {isEditable && (
          <Popover
            isOpen={loading || searchResults.length > 0 || isOpen}
            autoFocus={false}
            onClose={handleOnClose}
            isLazy
          >
            <PopoverTrigger>
              <QuotationInput
                ref={inputRef}
                onChange={handleSearchChange}
                onFocus={handleOnFocus}
                value={search}
                placeholder={placeholder}
                rightIcon={<SimpleSearchIcon />}
                onBlur={handleOnBlur}
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                width={inputRef.current?.offsetWidth}
                background="#F9FAFB"
                borderRadius="0.375rem"
                boxShadow="0px 0px 0px 2px #FFF inset, 0px 12px 16px -4px rgba(13, 18, 28, 0.14), 0px 4px 6px -2px rgba(13, 18, 28, 0.08)"
                border="1px solid #E5E7EB"
              >
                <PopoverBody p="0.375rem">
                  {loading ? (
                    <HStack width="100%" alignItems="center" height="2.375rem" justifyContent="center">
                      <CircularProgress size="1.25rem" color="gray.500" isIndeterminate />
                      <Text color="#1F2A37" fontSize="0.813rem" fontWeight={500}>
                        {quotationCardTranslations.global.loading}
                      </Text>
                    </HStack>
                  ) : searchResults.length === 0 ? (
                    <HStack width="100%" alignItems="center" height="2.375rem" justifyContent="center">
                      <SimpleSearchIcon />
                      <Text color="#6C737" fontSize="0.813rem" fontWeight={500}>
                        {quotationCardTranslations.global.noResults}
                      </Text>
                    </HStack>
                  ) : (
                    <Users users={searchResults} allowSelection onSelect={handleOnSelect} isEditable={isEditable} />
                  )}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        )}
        {users && users.length > 0 && <Users users={users} action={action} isEditable={isEditable} />}
        {addCTA && isEditable && (
          <GraneetButton onClick={addCTA.onClick} leftIcon={<SimpleAddIcon />} variant="ghost">
            {addCTA.label}
          </GraneetButton>
        )}
      </VStack>
    </SellsheetCard>
  );
};
