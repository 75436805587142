import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import type { IQuoteJob } from '@graneet/business-logic';

import { updateJob } from '../services/quote-job.api';
import { useQuoteEditContext } from '../../quote/hooks/useQuoteEditContext';

export const useSetQuoteJobOptional = (job: IQuoteJob) => {
  const toast = useToast();
  const { t } = useTranslation(['global']);
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(async () => {
    const [err, quoteInfos] = await updateJob(job.id, { isOptional: !job.isOptional });

    if (err) {
      toast.error(t('global:errors.error'));
      return;
    }

    updateDataLocally(quoteInfos);
  }, [job.id, job.isOptional, t, toast, updateDataLocally]);
};
