import type { QuantityFormula, DISCOUNT_TYPES, IItem, VAT_METHOD } from '@graneet/business-logic';

export type ItemKeys = keyof Pick<
  IItem,
  'code' | 'description' | 'unit' | 'quantity' | 'quantityFormula' | 'unitPrice' | 'vatRate'
>;

export type ItemFieldName<T extends ItemKeys> = `item[${string | number}].${T}`;
export const getItemFieldName = <T extends ItemKeys>(itemId: string | number, key: T): ItemFieldName<T> =>
  `item[${itemId}].${key}`;

export interface InvoiceEditionForm {
  hasReversalOfLiability?: boolean;

  vatMethod?: VAT_METHOD;

  discountType?: DISCOUNT_TYPES;

  discountPercentage?: number | null;

  discountAmountExVAT?: number;

  [itemCode: ItemFieldName<'code'>]: string | undefined | null;

  [itemDescription: ItemFieldName<'description'>]: string;

  [itemUnit: ItemFieldName<'unit'>]: string;

  [itemQuantity: ItemFieldName<'quantity'>]: number;

  [itemQuantityFormula: ItemFieldName<'quantityFormula'>]: QuantityFormula | null;

  [itemUnitPrice: ItemFieldName<'unitPrice'>]: number;

  [itemVatRate: ItemFieldName<'vatRate'>]: number;
}
