import { useCallback } from 'react';

import { useCreateQuoteRevisionRejectedCallback } from './useCreateQuoteRevisionRejectedCallback';
import { useCreateQuoteUpdatedCallback } from './useCreateQuoteUpdatedCallback';
import { useCreateQuoteUpdatedMarginCallback } from './useCreateQuoteUpdatedMarginCallback';

import { useQuoteError } from 'features/quotation/quote-common/hooks/useQuoteError';

export const useQuoteCallbacks = () => {
  const createQuoteRevisionRejectedCallback = useCreateQuoteRevisionRejectedCallback();
  const createQuoteUpdatedCallback = useCreateQuoteUpdatedCallback();
  const createQuoteUpdatedMarginCallback = useCreateQuoteUpdatedMarginCallback();
  const quoteError = useQuoteError();

  return useCallback(
    (quoteId: String) => [
      {
        channel: `private-quote-${quoteId}`,
        event: 'revision:rejected',
        callback: createQuoteRevisionRejectedCallback,
      },
      {
        channel: `private-quote-${quoteId}`,
        event: 'quote:updated',
        callback: createQuoteUpdatedCallback,
      },
      {
        channel: `private-quote-${quoteId}`,
        event: 'quote-update-margin',
        callback: createQuoteUpdatedMarginCallback,
      },
      {
        channel: `private-quote-${quoteId}`,
        event: 'quote:reload',
        callback: () => {
          quoteError();
        },
      },
    ],
    [createQuoteRevisionRejectedCallback, createQuoteUpdatedCallback, createQuoteUpdatedMarginCallback, quoteError],
  );
};
