import { useMemo } from 'react';

import { useStore } from 'store/store';

export const useDenominationHeight = () => {
  const quoteRowHeight = useStore((state) => state.quoteRowHeight);

  return useMemo(() => {
    switch (quoteRowHeight) {
      case 'sm':
        return '22px';
      case 'md':
        return '36px';
      case 'lg':
        return '115px';
      default:
        return '36px';
    }
  }, [quoteRowHeight]);
};
