import { VStack, useDisclosure } from '@chakra-ui/react';
import {
  GraneetButton as Button,
  DateCard,
  InformationCard,
  SellSheetHeader,
  SimpleCloseIcon,
  UsersCard,
  getLocalDateFormat,
} from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { Suspense, useCallback, useMemo } from 'react';
import { isDate } from 'lodash-es';
import dayjs from 'dayjs';
import { CheckType } from '@org/quotation-lib';
import type { IUser } from '@graneet/business-logic';
import { ErrorBoundary } from 'react-error-boundary';

import { EditQuoteMentionsModal } from '../../modals/EditQuoteMentionsModal';
import { QuoteDisplayPDFOptionsCard } from '../../cards/QuoteDisplayPDFOptionsCard';
import { useQuoteUpdate } from '../../../hooks/useQuoteUpdate';
import { QuoteSellSheetStatusCard } from '../../cards/QuoteSellSheetStatusCard';
import { QuoteLoadingCard } from '../../skeleton/QuoteLoadingCard';

import { useStore } from 'store/store';
import { formatUserDisplayName } from 'features/user/services/user.util';
import { useDisplayedUsers } from 'features/user/services/user.api';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const QuoteDetails = () => {
  const { t } = useTranslation(['quote']);
  const editAdditionalInformationModal = useDisclosure();
  const editMentionsModal = useDisclosure();
  const quoteUpdate = useQuoteUpdate();
  const { quote } = useQuote();
  const quoteTree = quote.getTree();
  const isEditable = useStore((store) => store.quoteEditable);

  const users = useDisplayedUsers();

  // Get users that can be associated with the quote
  const availableUsers = useMemo(
    () =>
      users.data.filter(
        ({ id }) => !(quote.getAccountManagers() || []).map((am) => am.toString()).includes(id.toString()),
      ),
    [users, quote],
  );

  // Get users that are already associated with the quote
  const filteredUsers = useMemo(
    () =>
      users.data.filter(({ id }) =>
        (quote.getAccountManagers() || []).map((am) => am.toString()).includes(id.toString()),
      ),
    [users, quote],
  );

  const endDate = useMemo(() => {
    const date = quote.getAddedAt();
    const validityDuration = quote.getValidityDuration();

    if (!validityDuration || !Number.isFinite(validityDuration.toNumber()) || !date || !isDate(date)) {
      return null;
    }

    return dayjs(date).add(validityDuration.toNumber(), 'day').format(getLocalDateFormat());
  }, [quote]);

  const quoteAddedAt = useMemo(
    () => (quote.getAddedAt() ? dayjs(quote.getAddedAt()).format('YYYY-MM-DD') : null),
    [quote],
  );

  const quoteStartedAt = useMemo(
    () => (quote.getStartedAt() ? dayjs(quote.getStartedAt()).format('YYYY-MM-DD') : null),
    [quote],
  );

  const updateQuoteInformation = useCallback(
    (key: string, value: string | number) => {
      quoteUpdate({
        [key]: value,
      });
    },
    [quoteUpdate],
  );

  const updateQuoteMentions = useCallback(
    (text: string) => {
      quoteUpdate({
        mentions: text,
      });
    },
    [quoteUpdate],
  );

  const updateQuoteAdditionalInformation = useCallback(
    (text: string) => {
      quoteUpdate({
        note: text,
      });
    },
    [quoteUpdate],
  );

  const onUsersSearch = useCallback(
    (searchTerm: string) =>
      availableUsers.filter((user) => formatUserDisplayName(user).toLowerCase().includes(searchTerm.toLowerCase())),
    [availableUsers],
  );

  const onUserSelect = useCallback(
    (user: IUser) => {
      const newAccountManagers = quote.getAccountManagers() ?? [];
      newAccountManagers.push(user.id);
      quoteUpdate({
        accountManagers: newAccountManagers,
      });
    },
    [quote, quoteUpdate],
  );

  const onUserRemove = useCallback(
    (id: IUser['id']) => {
      const newAccountManagers = quote.getAccountManagers() ?? [];
      newAccountManagers.splice(newAccountManagers.indexOf(id), 1);
      quoteUpdate({
        accountManagers: newAccountManagers,
      });
    },
    [quote, quoteUpdate],
  );

  return (
    <>
      <VStack spacing={3} align="stretch">
        <SellSheetHeader isActive title={t('quote:drawer.details.header')} type="quote" />

        <Suspense fallback={<QuoteLoadingCard />}>
          <ErrorBoundary fallback={null}>
            <QuoteSellSheetStatusCard quoteId={quote.getId()} />
          </ErrorBoundary>
        </Suspense>

        <InformationCard
          isEditable={isEditable}
          name={quote.getName()}
          onChange={updateQuoteInformation}
          refCode={CheckType.stringOrNull(quote.getRefCode())}
          title={t('quote:drawer.details.informationCard.title')}
        />

        <DateCard
          callout={endDate && t('quote:drawer.details.validityCard.endDate', { endDate })}
          date={{
            label: t('quote:drawer.details.validityCard.dateLabel'),
            field: 'addedAt',
            value: quoteAddedAt,
          }}
          duration={{
            label: t('quote:drawer.details.validityCard.validityDurationLabel'),
            field: 'validityDuration',
            value: quote.getValidityDuration()?.toString() ?? null,
          }}
          isEditable={isEditable}
          onChange={updateQuoteInformation}
          title={t('quote:drawer.details.validityCard.title')}
        />

        <DateCard
          date={{
            label: t('quote:drawer.details.workCard.workStartDateLabel'),
            field: 'startedAt',
            value: quoteStartedAt,
          }}
          duration={{
            label: t('quote:drawer.details.workCard.estimatedDurationLabel'),
            field: 'workDuration',
            value: quote.getWorkDuration()?.toString() ?? null,
          }}
          isEditable={isEditable}
          onChange={updateQuoteInformation}
          title={t('quote:drawer.details.workCard.title')}
        />

        <UsersCard<IUser>
          action={{
            icon: <SimpleCloseIcon />,
            iconAction: onUserRemove,
          }}
          isEditable
          onSearch={onUsersSearch}
          onSelect={onUserSelect}
          placeholder={t('quote:drawer.details.usersCard.placeholder')}
          title={t('quote:drawer.details.usersCard.title')}
          users={filteredUsers}
        />

        {quoteTree.getQuoteHasOptionalItems() && <QuoteDisplayPDFOptionsCard />}

        <VStack spacing={2} align="stretch">
          <Button onClick={editAdditionalInformationModal.onOpen}>
            {t('quote:drawer.details.additionalInformation')}
          </Button>
          <Button onClick={editMentionsModal.onOpen}>{t('quote:drawer.details.mentions')}</Button>
        </VStack>
      </VStack>

      <EditQuoteMentionsModal
        title={t('quote:modals.additionalInformation.title')}
        placeholder={t('quote:modals.additionalInformation.placeholder')}
        isOpen={editAdditionalInformationModal.isOpen}
        onClose={editAdditionalInformationModal.onClose}
        onSubmit={updateQuoteAdditionalInformation}
        initialValue={quote.getNote()}
      />
      <EditQuoteMentionsModal
        title={t('quote:modals.mentions.title')}
        placeholder={t('quote:modals.mentions.placeholder')}
        isOpen={editMentionsModal.isOpen}
        onClose={editMentionsModal.onClose}
        onSubmit={updateQuoteMentions}
        initialValue={quote.getMentions()}
      />
    </>
  );
};
