import { VStack, useDisclosure } from '@chakra-ui/react';
import type { ItemProps } from '@graneet/lib-ui';
import { DropDownSelect, SellSheetHeader, useCurrency } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { CheckType } from '@org/quotation-lib';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { QuoteSellSheetLotAmountCard } from '../../cards/QuoteSellSheetLotAmountCard';
import { QuoteSellSheetLotMarginCard } from '../../cards/QuoteSellSheetLotMarginCard';
import { QuoteSellSheetSummaryCard } from '../../cards/QuoteSellSheetSummaryCard';
import { QuoteSellSheetGlobalMarginCard } from '../../cards/QuoteSellSheetGlobalMarginCard';
import { QuoteSellSheetGlobalAmountCard } from '../../cards/QuoteSellSheetGlobalAmountCard';
import { MargeByComponentsTypeModal } from '../../modals/MargeByComponentsTypeModal';

import { getNodeSelectList } from 'features/quotation/quote/utils/quote-utils';
import { useStore } from 'store/store';
import { useQuote } from 'features/quotation/quote/hooks/useQuote';

export const QuoteSellSheet: FC<{ quoteId?: string }> = ({ quoteId }) => {
  const { t } = useTranslation(['quote']);
  const { quote } = useQuote();
  const { formatAsStringAmount } = useCurrency();

  const [selectedNodeId, setSelectedNodeId] = useState<string>(quote.getTree().getRootNodeId());
  const marginModal = useDisclosure();

  const editorRef = useStore((state) => state.quoteRichTextEditorRef);

  const getPlainText = useCallback(
    (v: string | null | undefined) =>
      editorRef?.current?.getPlainText ? (editorRef?.current?.getPlainText(v) ?? null) : null,
    [editorRef],
  );

  const rootLotChildren: ItemProps[] = [];
  getNodeSelectList(
    Array.from(quote.getTree().getRootNode().getChildren().values()),
    quote.getIsAutomaticIndexActivated(),
    rootLotChildren,
    getPlainText,
  );

  useEffect(() => {
    setSelectedNodeId(quote.getTree().getRootNodeId());
    // Only reset rootNodeselected if quoteId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);

  useEffect(() => {
    const node = quote.getTree().getNodeOrNull(selectedNodeId);
    if (!node) {
      setSelectedNodeId(quote.getTree().getRootNodeId());
    }
  }, [quote, selectedNodeId]);

  const drawerSelectedNodeId = useMemo(() => {
    if (!selectedNodeId) {
      return quote.getTree().getRootNodeId();
    }
    if (selectedNodeId && !quote.getTree().getNodeOrNull(selectedNodeId)) {
      if (quote.getTree().getRootNodeId() !== selectedNodeId) {
        return quote.getTree().getRootNodeId();
      }
    }
    return selectedNodeId;
  }, [quote, selectedNodeId]);

  if (selectedNodeId && !quote.getTree().getNodeOrNull(selectedNodeId)) {
    return null;
  }

  return (
    <>
      <VStack spacing={3} align="stretch">
        <SellSheetHeader
          isActive
          title={formatAsStringAmount(CheckType.stringOrNull(quote.getAmountExVat()) ?? '0')}
          subtitle={t('quote:drawer.sale.header')}
          type="sell"
        />
        <DropDownSelect
          isEditable
          items={[
            {
              value: quote.getTree().getRootNodeId(),
              name: t('quote:drawer.sale.selectQuoteSaleSheet'),
              items: rootLotChildren,
            },
          ]}
          onChange={setSelectedNodeId}
          selectedValue={drawerSelectedNodeId}
          itemPlaceholder={t('quote:drawer.sale.selectItemPlaceHolder')}
        />
        {selectedNodeId === quote.getTree().getRootNodeId() ? (
          <QuoteSellSheetGlobalMarginCard handleOpenModal={marginModal.onOpen} />
        ) : (
          <QuoteSellSheetLotMarginCard nodeId={selectedNodeId} handleOpenModal={marginModal.onOpen} />
        )}
        {selectedNodeId === quote.getTree().getRootNodeId() ? (
          <QuoteSellSheetGlobalAmountCard />
        ) : (
          <QuoteSellSheetLotAmountCard nodeId={selectedNodeId} />
        )}
        {selectedNodeId === quote.getTree().getRootNodeId() && <QuoteSellSheetSummaryCard />}
      </VStack>
      <MargeByComponentsTypeModal nodeId={selectedNodeId} isOpen={marginModal.isOpen} onClose={marginModal.onClose} />
    </>
  );
};
