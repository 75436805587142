import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  INDENT_CONTENT_COMMAND,
  KEY_TAB_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical';
import { $isListItemNode } from '@lexical/list';
import { $getNearestBlockElementAncestorOrThrow } from '@lexical/utils';

export const TabIndentationPlugin = (): null => {
  const [editor] = useLexicalComposerContext();

  useEffect(() =>
    editor.registerCommand<KeyboardEvent>(
      KEY_TAB_COMMAND,
      (event) => {
        const selection = $getSelection();

        if (!$isRangeSelection(selection)) {
          return false;
        }

        const { anchor } = selection;
        const { focus } = selection;
        const first = focus.isBefore(anchor) ? focus : anchor;
        const firstNode = first.getNode();
        const firstBlock = $getNearestBlockElementAncestorOrThrow(firstNode);

        if (!$isListItemNode(firstBlock)) {
          return false;
        }

        event.preventDefault();

        return editor.dispatchCommand(event.shiftKey ? OUTDENT_CONTENT_COMMAND : INDENT_CONTENT_COMMAND, undefined);
      },
      COMMAND_PRIORITY_EDITOR,
    ),
  );

  return null;
};
