import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SlashCircleIcon: FC<IconProps> = ({ color = 'red.600', ...rest }) => (
  <Icon viewBox="0 0 16 16" color={color} {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99967 1.5835C4.45301 1.5835 1.58301 4.4535 1.58301 8.00016C1.58301 11.541 4.45301 14.4168 7.99967 14.4168C11.5405 14.4168 14.4163 11.541 14.4163 8.00016C14.4163 4.4535 11.5405 1.5835 7.99967 1.5835ZM4.72134 3.8935C5.61384 3.17016 6.75717 2.74433 7.99384 2.74433C10.893 2.74433 13.2438 5.08933 13.2438 7.99433C13.2438 9.23391 12.8122 10.3685 12.0947 11.2668L4.71551 3.88766L4.72134 3.8935ZM3.89301 4.716C3.16967 5.6085 2.74384 6.75183 2.74384 7.9885C2.74384 10.8877 5.08884 13.2385 7.99384 13.2385C9.23342 13.2385 10.368 12.8068 11.2663 12.0893L3.88717 4.71016L3.89301 4.716Z"
      fill="currentColor"
    />
  </Icon>
);
