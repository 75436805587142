import type { FC } from 'react';
import { DeepTable } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import { useFormContext, useOnChangeValues } from 'graneet-form';

import type { QuoteExportForm } from '../forms/quote-export-edition.wizard';
import { EXPORT_COLUMNS } from '../constants/quote-export.constant';
import { useQuoteExportTreeContext } from '../hooks/useQuoteExportTree';

import { QuoteExportLotCheckbox } from './QuoteExportDeepTableLotLine/QuoteExportLotCheckbox';

export const QuoteExportDeepTableHeader: FC = () => {
  const { t } = useTranslation(['quote', 'margin']);
  const { getInitialTree } = useQuoteExportTreeContext();

  const form = useFormContext<QuoteExportForm>();
  const { exportItems: columns } = useOnChangeValues(form, ['exportItems']);

  if (!columns) return null;

  const rootLotId = getInitialTree().rootNodeId;

  return (
    <DeepTable.Header bg="white" borderTopRadius={4} leftContent={<QuoteExportLotCheckbox lotId={rootLotId} />}>
      {!!columns[EXPORT_COLUMNS.REF_CODE] && <DeepTable.Cell>{t('quote:jobsStep.fields.code')}</DeepTable.Cell>}

      <DeepTable.Cell>{t('quote:jobsStep.fields.description')}</DeepTable.Cell>

      {!!columns[EXPORT_COLUMNS.UNIT] && <DeepTable.Cell right>{t('quote:jobsStep.fields.unit')}</DeepTable.Cell>}

      {!!columns[EXPORT_COLUMNS.QUANTITY] && (
        <DeepTable.Cell right>{t('quote:jobsStep.fields.quantity')}</DeepTable.Cell>
      )}

      {!!columns[EXPORT_COLUMNS.UNIT_DISBURSEMENT_EX_VAT] && (
        <DeepTable.Cell right>{t('quote:fields.dryUnitDisbursementExVAT')}</DeepTable.Cell>
      )}

      {!!columns[EXPORT_COLUMNS.DISBURSEMENT_EX_VAT] && (
        <DeepTable.Cell right>{t('quote:fields.dryTotalDisbursementExVAT')}</DeepTable.Cell>
      )}

      {!!columns[EXPORT_COLUMNS.JOB_MARGIN] && <DeepTable.Cell right>{t('margin:fields.margin')}</DeepTable.Cell>}

      {!!columns[EXPORT_COLUMNS.UNIT_PRICE_EX_VAT] && (
        <DeepTable.Cell right>{t('quote:fields.unitPriceExVAT')}</DeepTable.Cell>
      )}

      {!!columns[EXPORT_COLUMNS.JOB_VAT] && <DeepTable.Cell right>{t('quote:fields.vat')}</DeepTable.Cell>}

      {!!columns[EXPORT_COLUMNS.WORK_FORCE] && <DeepTable.Cell right>{t('quote:fields.workForce')}</DeepTable.Cell>}

      {!!columns[EXPORT_COLUMNS.AMOUNT_EX_VAT] && <DeepTable.Cell right>{t('quote:fields.priceExVAT')}</DeepTable.Cell>}
    </DeepTable.Header>
  );
};
