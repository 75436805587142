import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControl, HStack, VStack, Text } from '@chakra-ui/react';

import { QuotationInput } from '../../../Input/Input';
import { SellsheetCard } from '../Card';
import { Label } from '../../../Label/Label';
import { quotationCardTranslations } from '../translations';

type Address = {
  address: string | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
};

type AddressCardProps = {
  title: string;
  data?: Address;
  possibleAddress?: Address;
  isEditable?: boolean;
  sameAddressLabel?: string;
  onAddressChange?: (address: Address) => void;
};

export const AddressCard: FC<AddressCardProps> = ({
  title,
  data = {
    address: null,
    postalCode: null,
    city: null,
    country: null,
  },
  possibleAddress = {
    address: null,
    postalCode: null,
    city: null,
    country: null,
  },
  sameAddressLabel,
  isEditable = true,
  onAddressChange,
}) => {
  const [addressValue, setAddresValue] = useState<string>(data.address ?? '');
  const [postalCodeValue, setPostalCodeValue] = useState<string>(data.postalCode ?? '');
  const [cityValue, setCityValue] = useState<string>(data.city ?? '');
  const [countryValue, setCountryValue] = useState<string>(data.country ?? '');
  const [sameAddressSelected, setSameAddressSelected] = useState<boolean>(false);

  const isFieldEditable = isEditable && !sameAddressSelected;

  const handleOnBlur = useCallback(
    (key: keyof Address) => (e: ChangeEvent<HTMLInputElement>) => {
      onAddressChange?.({ ...data, [key]: e.target.value });
    },
    [data, onAddressChange],
  );

  const handleSameAddressChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setAddresValue(possibleAddress.address ?? '');
        setPostalCodeValue(possibleAddress.postalCode ?? '');
        setCityValue(possibleAddress.city ?? '');
        setCountryValue(possibleAddress.country ?? '');
        onAddressChange?.(possibleAddress);
      }
      setSameAddressSelected(e.target.checked);
    },
    [onAddressChange, possibleAddress],
  );

  const handleOnchange = useCallback(
    (key: keyof Address) => (e: ChangeEvent<HTMLInputElement>) => {
      switch (key) {
        case 'address':
          setAddresValue(e.target.value);
          break;
        case 'postalCode':
          setPostalCodeValue(e.target.value);
          break;
        case 'city':
          setCityValue(e.target.value);
          break;
        case 'country':
          setCountryValue(e.target.value);
          break;
        default:
          break;
      }
    },
    [],
  );

  useEffect(() => {
    setAddresValue((prev) => (prev.length === 0 ? (data.address ?? '') : prev));
    setPostalCodeValue((prev) => (prev.length === 0 ? (data.postalCode ?? '') : prev));
    setCityValue((prev) => (prev.length === 0 ? (data.city ?? '') : prev));
    setCountryValue((prev) => (prev.length === 0 ? (data.country ?? '') : prev));
  }, [data]);

  return (
    <SellsheetCard title={title}>
      <VStack width="100%" spacing={2} alignItems="flex-start">
        <HStack width="100%" spacing={2}>
          <Checkbox
            disabled={!isEditable}
            isChecked={sameAddressSelected}
            onChange={handleSameAddressChange}
            width="100%"
            overflow="auto"
            colorScheme="greenBrand"
          >
            <Text fontSize="0.813rem">{sameAddressLabel}</Text>
          </Checkbox>
        </HStack>
        <HStack width="100%" spacing={2}>
          <FormControl width="100%">
            <Label>{quotationCardTranslations.address.address}</Label>
            <QuotationInput
              value={addressValue}
              onBlur={handleOnBlur('address')}
              onChange={handleOnchange('address')}
              isDisabled={!isFieldEditable}
            />
          </FormControl>
        </HStack>
        <HStack width="100%" spacing={2}>
          <FormControl width="50%">
            <Label>{quotationCardTranslations.address.postalCode}</Label>
            <QuotationInput
              value={postalCodeValue}
              onBlur={handleOnBlur('postalCode')}
              onChange={handleOnchange('postalCode')}
              isDisabled={!isFieldEditable}
            />
          </FormControl>
          <FormControl width="50%">
            <Label>{quotationCardTranslations.address.city}</Label>
            <QuotationInput
              value={cityValue}
              onBlur={handleOnBlur('city')}
              onChange={handleOnchange('city')}
              isDisabled={!isFieldEditable}
            />
          </FormControl>
        </HStack>
        <HStack width="100%" spacing={2}>
          <FormControl width="100%">
            <Label>{quotationCardTranslations.address.country}</Label>
            <QuotationInput
              value={countryValue}
              onBlur={handleOnBlur('country')}
              onChange={handleOnchange('country')}
              isDisabled={!isFieldEditable}
            />
          </FormControl>
        </HStack>
      </VStack>
    </SellsheetCard>
  );
};
