import type { MutableRefObject } from 'react';
import { createContext } from 'react';

export interface IRichSelectContext<Value extends string | string[] | Record<string, number>> {
  isDisabled: boolean;
  isActive: boolean;
  hasError: boolean;
  isOpen: boolean;
  onOpen(): void;
  onClose(): void;
  onFocus(): void;
  menuSize?: number | string;
  setMenuSize(size: number): void;
  onOptionSelected(option: Value): void;
  buttonRef?: MutableRefObject<HTMLDivElement | null>;
  size: 'sm' | 'md';
  border: boolean;
}

export const RichSelectContext = createContext<IRichSelectContext<any>>({
  isDisabled: false,
  hasError: false,
  isActive: false,
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  onFocus: () => {},
  menuSize: undefined,
  setMenuSize: () => {},
  onOptionSelected: () => {},
  buttonRef: undefined,
  size: 'md',
  border: true,
});
