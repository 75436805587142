import type { FC } from 'react';
import { Date, PriceAdvanced, Table, formatDataOrEmpty } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IQuote, QuotePaginatedResponse } from '@graneet/business-logic';

import { QUOTE_FIELDS } from '../../constants/quotes.constant';
import { QuoteStatusBadge } from '../badges/QuoteStatusBadge';

import { UserAvatars } from 'features/user/components/UserAvatars';
import { TagsBadges } from 'features/common/components/TagsBadges';

const URL_QUOTE = (quote: IQuote) => `/opportunities/quotes/${quote.id}`;

const TEMPLATE_COLUMNS = (showClientColumn: boolean, showAccountManagers: boolean) =>
  [
    'minmax(auto, 11rem)',
    'minmax(11rem, 2fr)',
    '8rem',
    showClientColumn ? 'minmax(8rem, 1fr)' : null,
    '8rem',
    '8rem',
    showAccountManagers ? '5rem' : null,
  ].filter(Boolean) as string[];

interface QuotesTableProps {
  quotes: IQuote[];

  showClientColumn?: boolean;

  showAccountManagers?: boolean;

  hasPagination?: boolean;
}

export const QuotesTable: FC<QuotesTableProps> = ({
  quotes,
  showClientColumn = false,
  showAccountManagers = false,
  hasPagination = false,
}) => {
  const { t } = useTranslation(['quote', 'accountManager']);

  return (
    <Table
      templateColumns={TEMPLATE_COLUMNS(showClientColumn, showAccountManagers)}
      noResultLabel={t('quote:errors.noResult')}
    >
      {hasPagination && (
        <Table.Information<QuotePaginatedResponse>
          countLabel={(count) => t('quote:quote', { count })}
          sums={{
            amountExVAT: {
              label: t('quote:fields.amountExVAT'),
              type: 'currency',
            },
          }}
        />
      )}

      <Table.Header>
        <Table.Cell sortBy={hasPagination ? QUOTE_FIELDS.REF_CODE : undefined}>{t('quote:fields.refCode')}</Table.Cell>

        <Table.Cell sortBy={hasPagination ? QUOTE_FIELDS.NAME : undefined}>{t('quote:fields.name')}</Table.Cell>

        <Table.Cell sortBy={hasPagination ? QUOTE_FIELDS.DATE : undefined}>{t('quote:fields.date')}</Table.Cell>

        {showClientColumn && (
          <Table.Cell sortBy={hasPagination ? QUOTE_FIELDS.CLIENT : undefined}>{t('quote:fields.client')}</Table.Cell>
        )}

        <Table.Cell right sortBy={hasPagination ? QUOTE_FIELDS.AMOUNT_EX_VAT : undefined}>
          {t('quote:fields.amountExVAT')}
        </Table.Cell>

        <Table.Cell center sortBy={hasPagination ? QUOTE_FIELDS.STATUS : undefined}>
          {t('quote:fields.status')}
        </Table.Cell>
        {showAccountManagers && <Table.Cell center>{t('accountManager:title')}</Table.Cell>}
      </Table.Header>

      {(quotes || []).map((quote) => {
        const accountManagers = (quote.quoteAccountManagers || []).map(
          (quoteAccountManager) => quoteAccountManager.user,
        );

        return (
          <Table.Row key={quote.id} to={URL_QUOTE(quote)}>
            <Table.Cell variant="ellipsis">{formatDataOrEmpty(quote.refCode)}</Table.Cell>

            <Table.Cell variant="ellipsis">
              {quote.name}
              <TagsBadges tags={quote.tags} />
            </Table.Cell>

            <Table.Cell>
              <Date>{quote.date}</Date>
            </Table.Cell>

            {showClientColumn && (
              <Table.Cell variant="ellipsis">{formatDataOrEmpty(quote.client?.enterpriseName)}</Table.Cell>
            )}

            <Table.Cell right>
              <PriceAdvanced amount={quote.amountExVAT} />
            </Table.Cell>

            <Table.Cell center>
              <QuoteStatusBadge quote={quote} />
            </Table.Cell>

            {showAccountManagers && (
              <Table.Cell center>
                <UserAvatars users={accountManagers} />
              </Table.Cell>
            )}
          </Table.Row>
        );
      })}
    </Table>
  );
};
