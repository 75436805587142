import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlueCircledPlus, Button, HammerIcon, StepperModal } from '@graneet/lib-ui';
import { Form, useOnChangeValues, useStepForm, useWizardContext } from 'graneet-form';
import type { UseDisclosureReturn } from '@chakra-ui/react';
import { Box, Center, Grid, GridItem, Text } from '@chakra-ui/react';
import { PROJECT_STATUSES } from '@graneet/business-logic';

import type { QuoteAcceptModalWizard } from './types';

import { useAssociableProjectsWithoutPagination } from 'features/project/services/project.api';
import { ProjectField } from 'features/project/components/ProjectField/ProjectField';

interface SelectProjectStepProps {
  onClose: UseDisclosureReturn['onClose'];

  onCreateProject(settledDate: string): void;

  onSelectProject(projectId: number | undefined): void;

  isLoading: boolean;
}

export const SelectProjectStep: FC<SelectProjectStepProps> = ({
  onClose,
  onSelectProject,
  onCreateProject,
  isLoading,
}) => {
  const { getValuesOfStep } = useWizardContext<QuoteAcceptModalWizard>();
  const { t } = useTranslation(['global', 'quote']);

  const [isProjectSelectionSelectOpen, setIsProjectSelectionSelectOpen] = useState(false);

  const handleCreateProject = useCallback(() => {
    onClose();
    const { settledDate } = getValuesOfStep('accept')!;
    onCreateProject(settledDate);
  }, [getValuesOfStep, onClose, onCreateProject]);

  const associableProjects = useAssociableProjectsWithoutPagination({
    status: PROJECT_STATUSES.ON_GOING,
  });

  const { form } = useStepForm<QuoteAcceptModalWizard, 'associate'>();

  /**
   * Keep track selected projectId in the upper store
   */
  const { projectId } = useOnChangeValues(form, ['projectId']);
  useEffect(() => {
    onSelectProject(projectId);
  }, [onSelectProject, projectId]);

  if (isProjectSelectionSelectOpen) {
    return (
      <Box w="100%">
        <Text mb={4}>{t('quote:acceptQuoteProjectModal.associateDescription')}</Text>

        <Form form={form} style={{ width: '100%' }}>
          <ProjectField<any> projects={associableProjects.data.data} name="projectId" width="100%" isRequired />

          <StepperModal.SecondaryButton isDisabled={isLoading} onClick={() => setIsProjectSelectionSelectOpen(false)}>
            {t('global:words.c.back')}
          </StepperModal.SecondaryButton>

          <StepperModal.PrimaryButton isLoading={isLoading}>{t('global:words.c.confirm')}</StepperModal.PrimaryButton>
        </Form>
      </Box>
    );
  }

  return (
    <Box w="100%" h="12rem">
      <Text>{t('quote:acceptQuoteProjectModal.descriptionSecondStep')}</Text>

      <Grid h="6rem" templateRows="repeat(3, 1fr)" templateColumns="repeat(2, 1fr)" mt={5} rowGap={12}>
        <GridItem rowSpan={2}>
          <Center>
            <HammerIcon boxSize={20} backgroundColor="gray.100" />
          </Center>
        </GridItem>

        <GridItem rowSpan={2}>
          <Center>
            <BlueCircledPlus boxSize={20} />
          </Center>
        </GridItem>

        <GridItem>
          <Center>
            <Button onClick={() => setIsProjectSelectionSelectOpen(true)}>
              {t('quote:actions.associateExistingProject')}
            </Button>
          </Center>
        </GridItem>

        <GridItem>
          <Center>
            <Button onClick={handleCreateProject}>{t('quote:actions.createProject')}</Button>
          </Center>
        </GridItem>
      </Grid>
    </Box>
  );
};
