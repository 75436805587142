import { Flex } from '@chakra-ui/react';
import type { FieldValues } from 'graneet-form';
import { Step, useWizard, WizardContext } from 'graneet-form';

import { Stepper } from '../Stepper';
import type { ModalProps } from '../Modal';
import { Modal } from '../Modal';
import { ModalCloseButton } from '../Modal/ModalCloseButton';

import { StepperModalPrimaryButton } from './StepperModalPrimaryButton';
import { StepperModalSecondaryButton } from './StepperModalSecondaryButton';

export type StepperModalProps<WizardValues extends Record<string, FieldValues>> = ModalProps & {
  onFinish?: (wizardValues: WizardValues) => void;
};

const StepperModalComponent = <WizardValues extends Record<string, FieldValues>>({
  onClose,
  isOpen,
  children,
  onFinish = () => {},
  ...props
}: StepperModalProps<WizardValues>) => {
  const wizard = useWizard<WizardValues>(onFinish, onClose);

  return (
    <Modal onClose={onClose} isOpen={isOpen} {...props}>
      <WizardContext.Provider value={wizard}>
        {/* Following negative margin and borderRadius is to match chakra style */}
        <Stepper flexGrow={0.3} internalProps={{ mt: -2, mx: -6, borderTopRadius: '8px' }} />

        <Flex flex={1} mt={2}>
          {children}
        </Flex>
      </WizardContext.Provider>
    </Modal>
  );
};

export const StepperModal = Object.assign(StepperModalComponent, {
  Step,
  PrimaryButton: StepperModalPrimaryButton,
  SecondaryButton: StepperModalSecondaryButton,
  CloseButton: ModalCloseButton,
});
