import type { FC } from 'react';
import { Icon } from '@chakra-ui/icons';
import type { IconProps } from '@chakra-ui/react';

export const SendIcon: FC<IconProps> = ({ color = 'gray.700', ...rest }) => (
  <Icon viewBox="0 0 16 16" color={color} {...rest}>
    <path
      d="M14.1615 1.63333C13.8948 1.53999 13.6482 1.60666 13.5282 1.63999C13.3948 1.67333 13.2348 1.73999 13.0748 1.79999L1.76817 6.20666C1.58817 6.27333 1.41484 6.33999 1.2815 6.40666C1.1615 6.45999 0.921504 6.58666 0.781504 6.84666C0.628171 7.13333 0.628171 7.47999 0.781504 7.76666C0.914837 8.02666 1.1615 8.15333 1.27484 8.20666C1.4015 8.26666 1.57484 8.33333 1.75484 8.40666L4.9015 9.62666C5.13484 9.71333 5.24817 9.75999 5.36817 9.75999C5.46817 9.75999 5.56817 9.73999 5.6615 9.69333C5.7615 9.63999 5.85484 9.55333 6.02817 9.37333L9.25484 6.13999C9.51484 5.87333 9.93484 5.87333 10.1948 6.13999C10.4548 6.39999 10.4548 6.81999 10.1948 7.07999L6.9615 10.3067C6.7815 10.48 6.68817 10.5733 6.6415 10.6733C6.59484 10.7667 6.57484 10.8667 6.57484 10.9667C6.57484 11.08 6.6215 11.2 6.70817 11.4333L7.92817 14.5733C7.99484 14.7467 8.0615 14.92 8.12817 15.0533C8.1815 15.1667 8.30817 15.4067 8.56817 15.5467C8.85484 15.6933 9.2015 15.6933 9.48817 15.5467C9.74817 15.4067 9.87484 15.16 9.92817 15.0467C9.98817 14.9133 10.0548 14.74 10.1282 14.56L14.5348 3.25333C14.5948 3.09333 14.6548 2.93333 14.6948 2.79999C14.7282 2.67333 14.7882 2.42666 14.7015 2.16666C14.6015 1.86666 14.3615 1.63333 14.0682 1.53333L14.1615 1.63333Z"
      fill="currentColor"
    />
  </Icon>
);
