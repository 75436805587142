import type { IStatement, ISubProject } from '@graneet/business-logic';
import { groupBy } from 'lodash-es';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Stack } from '@chakra-ui/react';

import { StatementTable } from './StatementTable';

export interface StatementTableProps {
  statements: IStatement[];

  subProjects: ISubProject[];

  onRowClick(statement: IStatement): void;
}

export const StatementTables: FC<StatementTableProps> = ({ subProjects, statements, onRowClick }) => {
  const statementGroupBySubProject = useMemo(
    () => groupBy(statements, (statement) => statement.subProjectId),
    [statements],
  );

  const statementIds = useMemo(() => {
    const ids = Object.keys(statementGroupBySubProject).sort((a, b) => {
      if (a === 'null') {
        return -1;
      }

      if (b === 'null') {
        return 1;
      }

      return statementGroupBySubProject[a][0].subProjectName!.localeCompare(
        statementGroupBySubProject[b][0].subProjectName!,
      );
    });

    // when ids array is empty, fill it with one value to display table zero state
    if (ids.length === 0) {
      ids.push('null');
    }

    return ids;
  }, [statementGroupBySubProject]);

  // Display subproject name only if there is more than one subproject or if there is only one subproject and some invoice not linked to a subproject (statementId === 'null')
  // Gitlab issue: #4290
  const displaySubProjectName = subProjects.length > 1 || (subProjects.length === 1 && statementIds.includes('null'));

  return (
    <Stack spacing={4}>
      {statementIds.map((statementId, index) => {
        const isFirst = index === 0;

        const subProjectStatements = statementGroupBySubProject[statementId] || [];
        const subProjectName = displaySubProjectName
          ? statementGroupBySubProject?.[statementId]?.[0]?.subProjectName
          : null;

        return (
          <StatementTable
            key={statementId}
            statements={subProjectStatements}
            onRowClick={onRowClick}
            displayHeader={isFirst}
            subProjectName={subProjectName}
          />
        );
      })}
    </Stack>
  );
};
