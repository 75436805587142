import type { FC, ReactElement } from 'react';

import { SimpleAddFileIcon, SimpleAlertIcon, SimpleCloudIcon } from '../../../Icons';
import { useChakraColors } from '../../../../hooks';

export interface DroppableFileIconProps {
  isFileSelected: boolean;

  isInvalid: boolean;

  defaultIcon?: ReactElement;
}

export const DroppableFileIcon: FC<DroppableFileIconProps> = ({ isFileSelected, isInvalid, defaultIcon = null }) => {
  const { gray, blue, red } = useChakraColors({ gray: 'gray.300', blue: 'greenBrand.light', red: 'red.500' });

  if (isInvalid) {
    // Error
    return <SimpleAlertIcon stroke={red} boxSize={14} />;
  }

  if (isFileSelected) {
    // Selected
    return <SimpleAddFileIcon stroke={blue} boxSize={14} />;
  }

  // Default
  return defaultIcon ?? <SimpleCloudIcon stroke={gray} boxSize={16} />;
};
