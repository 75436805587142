import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';
import type { IQuoteSellsheetAmountDTO } from '@graneet/business-logic';

import * as sellSheetApi from '../services/quote-sellsheet.api';

import { useQuoteEditContext } from 'features/quote/hooks/useQuoteEditContext';

export const useQuoteSellSheetAmountUpdate = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { updateDataLocally } = useQuoteEditContext();

  return useCallback(
    async (quoteId: number, dto: IQuoteSellsheetAmountDTO) => {
      if (!dto) {
        toast.error(t('quote:jobsStep.errors.dtoNotCompleted'));
        return;
      }

      const [err, result] = await sellSheetApi.updateQuoteAmountSellSheet(quoteId, dto);

      if (err) {
        toast.error(t('quote:errors.amountUpdatedFailed'));
        return;
      }

      toast.success(t('quote:successes.amountUpdated'));

      updateDataLocally(result);
    },
    [toast, t, updateDataLocally],
  );
};
