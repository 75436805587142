import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Logo } from '@graneet/lib-ui';
import { Form, useForm, useFormStatus } from 'graneet-form';
import { useAuth0 } from '@auth0/auth0-react';
import type { IOnboarding } from '@graneet/business-logic';

import { IsEmailAvailableRule } from '../forms/IsEmailAvailableRule';

import { Rule } from 'features/form/rules/Rule';
import type { OnboardingCredentialsForm } from 'features/onboarding/components/OnboardingCredentialsForm';
import { OnboardingCredentials } from 'features/onboarding/components/OnboardingCredentials';
import { OnboardingPasswordCheckList } from 'features/onboarding/components/OnboardingPasswordCheckList';

interface OnboardingSignInProps {
  onSignIn(values: OnboardingCredentialsForm): Promise<void>;

  onLogIn(token: string): Promise<void>;

  onboarding: IOnboarding;
}

export const OnboardingSignIn: FC<OnboardingSignInProps> = ({ onSignIn, onLogIn, onboarding }) => {
  const { t } = useTranslation(['onboarding']);

  const { loginWithPopup, getAccessTokenSilently } = useAuth0();

  const form = useForm<OnboardingCredentialsForm>();
  const { isValid: isFormValid } = useFormStatus(form);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = useCallback(async () => {
    const formValues = form.getFormValues();

    setIsSubmitting(true);
    await onSignIn({ email: formValues.email!, password: formValues.password! });
    setIsSubmitting(false);
  }, [form, onSignIn]);

  const onConnection = useCallback(async () => {
    await loginWithPopup();
    const token = await getAccessTokenSilently();
    await onLogIn(token);
  }, [getAccessTokenSilently, loginWithPopup, onLogIn]);

  const emailRules = (
    <>
      <IsEmailAvailableRule onboardingId={onboarding.id} />
      <Rule.IsEmail />
      <Rule.IsRequired />
    </>
  );

  return (
    <Form form={form} style={{ width: '100%' }}>
      <Stack w="100%" gap={4}>
        <Flex justifyContent="center">
          <Text fontWeight={400} fontSize="24px" textAlign="center" maxW="35rem" fontFamily="Alliance">
            <Trans t={t} i18nKey="onboarding:slogan2" values={{ name: onboarding.company?.name }}>
              <Logo display="inline-block" verticalAlign="baseline" mb="-5px" ml={1} h="1.5rem" w="auto" />
            </Trans>
          </Text>
        </Flex>

        <OnboardingCredentials emailDisabled={false} emailRules={emailRules} />

        <Flex justifyContent="center">
          <Button w="25rem" onClick={onSubmit} isDisabled={!isFormValid} isLoading={isSubmitting}>
            {t('onboarding:actions.createAccount')}
          </Button>
        </Flex>

        <Flex justifyContent="center">
          <OnboardingPasswordCheckList w="25rem" />
        </Flex>

        <Flex justifyContent="center">
          <Trans t={t} i18nKey="onboarding:logIn">
            <Button ml={2} mt="-0.4rem" onClick={onConnection} variant="outline">
              display_content
            </Button>
          </Trans>
        </Flex>
      </Stack>
    </Form>
  );
};
