import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { WizardLabels } from '@graneet/lib-ui';

export const useWizardLabels = (labels: Partial<WizardLabels> = {}): WizardLabels => {
  const { t } = useTranslation(['global']);
  const { next, previous, quit, save, saving } = labels;

  return useMemo(
    () => ({
      next: next ?? t('global:words.c.next'),
      previous: previous ?? t('global:words.c.previous'),
      quit: quit ?? t('global:words.c.back'),
      save: save ?? t('global:words.c.save'),
      saving: saving ?? '',
    }),
    [t, next, previous, quit, save, saving],
  );
};
