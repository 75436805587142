import type { FC } from 'react';
import { PERSONNA } from '@graneet/business-logic';
import { useTranslation } from 'react-i18next';

import accountant from './accountant.svg';
import administrativeAssistant from './administrative-assistant.svg';
import executive from './executive.svg';
import projectManager from './project-manager.svg';
import quantitySurveyor from './quantity-surveyor.svg';

interface OnboardingPersonnaProps {
  personna: PERSONNA;
}

export const OnboardingPersonna: FC<OnboardingPersonnaProps> = ({ personna }) => {
  const { t } = useTranslation(['onboarding']);

  switch (personna) {
    case PERSONNA.ADMINISTRATIVE_ASSISTANT:
      return <img src={administrativeAssistant} alt={t(`onboarding:personna.${PERSONNA.ADMINISTRATIVE_ASSISTANT}`)} />;
    case PERSONNA.PROJECT_MANAGER:
      return <img src={projectManager} alt={t(`onboarding:personna.${PERSONNA.PROJECT_MANAGER}`)} />;
    case PERSONNA.ACCOUNTANT:
      return <img src={accountant} alt={t(`onboarding:personna.${PERSONNA.ACCOUNTANT}`)} />;
    case PERSONNA.QUANTITY_SURVEYOR:
      return <img src={quantitySurveyor} alt={t(`onboarding:personna.${PERSONNA.QUANTITY_SURVEYOR}`)} />;
    case PERSONNA.EXECUTIVE:
      return <img src={executive} alt={t(`onboarding:personna.${PERSONNA.EXECUTIVE}`)} />;
    default:
      break;
  }
  // TS throw when a personna is not handled
  personna satisfies never;

  return null;
};
