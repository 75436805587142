import { useCallback } from 'react';
import { useToast } from '@graneet/lib-ui';
import { useTranslation } from 'react-i18next';

import { useQuoteEditContext } from '../../quote/hooks/useQuoteEditContext';
import * as jobsApi from '../services/quote-job.api';

/**
 * Insert a job after another one
 */
export const useAddJobInLotAfterJob = () => {
  const toast = useToast();
  const { t } = useTranslation(['quote']);
  const { emitLotUpdate, updateDataLocally, insertJobsAfterJobInLotInRelations, getCurrentQuoteData } =
    useQuoteEditContext();

  return useCallback(
    async (lotId: number, previousJobId?: number, isParentOptional?: boolean, isParentHiddenCost?: boolean) => {
      const { rootLotId } = getCurrentQuoteData();

      if (!rootLotId) {
        return;
      }

      const [err, infos] = await jobsApi.createJobAfterJob({
        lotId,
        previousJobId,
        isParentOptional,
        isParentHiddenCost,
      });

      if (err) {
        toast.error(t('quote:jobsStep.errors.addingJobError'));
        return;
      }

      insertJobsAfterJobInLotInRelations(infos.jobs, lotId, previousJobId);

      // Notify the matching lot component it needs to re-render
      emitLotUpdate(lotId);
      updateDataLocally(infos);
    },
    [insertJobsAfterJobInLotInRelations, emitLotUpdate, updateDataLocally, toast, t, getCurrentQuoteData],
  );
};
