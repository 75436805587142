import { Flex } from '@chakra-ui/react';
import { GraneetBadge } from '@graneet/lib-ui';
import type { CustomCellRendererProps } from '@ag-grid-community/react';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { QuoteComponentsSupplyObject } from '@org/quotation-lib';
import { useTranslation } from 'react-i18next';

import { useComponentTypes } from 'features/component-type/services/component-type.api';

export const ComponentTypeCell: FC<CustomCellRendererProps<QuoteComponentsSupplyObject>> = ({ data }) => {
  const componentTypes = useComponentTypes();
  const { t } = useTranslation(['quote']);

  const itemComponent = useMemo(() => {
    if (!data?.componentType) {
      return null;
    }

    const componentType = componentTypes.data.find(
      (component) => component.id.toString() === data.componentType?.id.toString(),
    );

    return componentType
      ? {
          ...componentType,
          color: componentType.color,
        }
      : null;
  }, [componentTypes.data, data?.componentType]);

  return (
    <Flex height="100%" display="inline-flex" padding={2}>
      {itemComponent ? (
        <GraneetBadge color={itemComponent.color}>{itemComponent.name}</GraneetBadge>
      ) : (
        <GraneetBadge color="white">{t('quote:componentType.undefined')}</GraneetBadge>
      )}
    </Flex>
  );
};
