import type { FC } from 'react';
import type { IProjectSubProjectWithContractsListingDTO, SubProjectExtendedContract } from '@graneet/business-logic';
import { Stack } from '@chakra-ui/react';

import { SubProjectTable } from './SubProjectTable';

import { ContractTable } from 'features/contract/components/ContractTable/ContractTable';

interface SubProjectTablesProps {
  subProjects: IProjectSubProjectWithContractsListingDTO['subProjects'];

  dropDownRender?: FC<{ numberOfContracts: number; contract: SubProjectExtendedContract }>;

  onSubProjectEdit?(subProjectId: string): void;

  displayGoToQuoteLink?: boolean;
}

export const SubProjectTables: FC<SubProjectTablesProps> = ({
  subProjects,
  dropDownRender,
  onSubProjectEdit,
  displayGoToQuoteLink = false,
}) => {
  if (subProjects.length === 0) {
    return null;
  }

  if (subProjects.length === 1) {
    return (
      <ContractTable
        contracts={subProjects[0].contracts}
        dropDownRender={dropDownRender}
        displayGoToQuoteLink={displayGoToQuoteLink}
      />
    );
  }

  return (
    <Stack spacing={4}>
      {subProjects.map((subProject, index) => (
        <SubProjectTable
          key={subProject.id}
          dropDownRender={dropDownRender}
          subProject={subProject}
          displayHeader={index === 0}
          onSubProjectEdit={onSubProjectEdit}
          displayGoToQuoteLink={displayGoToQuoteLink}
        />
      ))}
    </Stack>
  );
};
