import type { IProjectFinancialSummary } from '@graneet/business-logic';
import { PriceAdvanced } from '@graneet/lib-ui';
import type { FC } from 'react';

import { DisplayCellWithIndicator } from './DisplayCellWithIndicator';

export const ProjectTableMarginOnInvoicedAmountsCell: FC<{ data?: IProjectFinancialSummary }> = ({ data }) => (
  <DisplayCellWithIndicator value={Number(data?.marginOnInvoicedAmounts)}>
    <PriceAdvanced amount={Number(data?.marginOnInvoicedAmounts)} />
  </DisplayCellWithIndicator>
);
