import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ButtonColorScheme, PaginatedTableProps } from '@graneet/lib-ui';
import {
  PaginatedTable,
  Badge,
  formatDataOrEmpty,
  Button,
  ListingLayout,
  SimpleStatementIcon,
  generateColorFromString,
} from '@graneet/lib-ui';
import { useHistory } from 'react-router-dom';
import type { IStatement, StatementPaginatedResponse } from '@graneet/business-logic';
import { formatDateOrEmpty, EXPORT_ENTITY, PERMISSION, STATEMENT_TYPES } from '@graneet/business-logic';

import {
  STATEMENT_PAGINATED_STATUSES,
  STATEMENT_PAGINATED_STATUSES_COLORS,
  STATEMENT_PAGINATED_STATUSES_VALUES,
} from '../../constants/statement.constant';
import { useStatements, useStatementsAvailableTags } from '../../services/statement.api';
import { StatementPrice } from '../StatementPrice';
import { getStatementPath, getStatementStatusColor, getStatementStatusLabel } from '../../services/statement.util';
import { StatementBillIndicator } from '../StatementBillIndicator';

import { useDisabledButtonProps } from 'features/role/hooks/useDisabledButtonProps';
import { ExportButton } from 'features/export/components/ExportButton/ExportButton';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';
import { TagsBadges } from 'features/common/components/TagsBadges';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';

const NameCell = ({ data }: { data?: IStatement }) =>
  data ? (
    <>
      {data.name}
      <TagsBadges tags={data.tags} />
    </>
  ) : null;

const AmountExVATCell = ({ data }: { data?: IStatement }) =>
  data ? <StatementPrice statement={data} isCredit={data.type === STATEMENT_TYPES.CREDIT} /> : null;

const StatusCell = ({ data }: { data?: IStatement }) => {
  const { t } = useTranslation(['statement', 'global']);

  return data ? <Badge colorScheme={getStatementStatusColor(data)}>{getStatementStatusLabel(t, data)}</Badge> : null;
};

const BillIndicatorCell = ({ data }: { data?: IStatement }) =>
  data ? <StatementBillIndicator statement={data} /> : null;

export const StatementsListing = () => {
  const history = useHistory();
  const { t } = useTranslation(['statement', 'global']);
  const { updateHeaderTitle } = useHeaderContext();
  const createStatementsButtonDisabledProps = useDisabledButtonProps([PERMISSION.CREATE_STATEMENTS]);

  const tags = useStatementsAvailableTags();
  const statements = useStatements();

  const { createRedirectionWithFilters } = useFiltersQuery();

  const onStatementCreate = useCallback(() => {
    history.push('/sales/statements/invoices/create');
  }, [history]);

  const availableFilterStatuses = useMemo(
    () =>
      (Object.keys(STATEMENT_PAGINATED_STATUSES) as Array<keyof typeof STATEMENT_PAGINATED_STATUSES>).map((status) => ({
        value: STATEMENT_PAGINATED_STATUSES_VALUES[status as STATEMENT_PAGINATED_STATUSES] as string,
        colorScheme: STATEMENT_PAGINATED_STATUSES_COLORS[status as STATEMENT_PAGINATED_STATUSES],
        label: t(`statement:statuses.${status}`),
      })),
    [t],
  );

  const availableFilterTypes = useMemo(
    () =>
      (Object.keys(STATEMENT_TYPES) as Array<keyof typeof STATEMENT_TYPES>)
        .filter((type) => type !== STATEMENT_TYPES.DIRECT_PROGRESS_STATEMENT)
        .map((type) => ({
          value: type,
          colorScheme: 'gray' as ButtonColorScheme,
          label: t(`statement:types.${type}`),
        })),
    [t],
  );

  const selectLabel = useCallback(
    (numberSelectedItems: number) => t('statement:banner.selection', { count: numberSelectedItems }),
    [t],
  );

  const allSelectedLabel = useCallback(
    (totalNumberOfItems?: number): string => t('statement:banner.allSelectedLabel', { count: totalNumberOfItems }),
    [t],
  );

  const availableTags = useMemo(
    () =>
      tags.data.map((tag) => ({
        value: tag,
        label: tag,
        badgeColor: generateColorFromString(tag),
      })),
    [tags],
  );

  const columnDefs = useMemo<PaginatedTableProps<StatementPaginatedResponse>['columnDefs']>(
    () => [
      {
        field: 'invoiceNumber',
        headerName: t('statement:fields.invoiceNumber'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.invoiceNumber),
      },
      {
        field: 'name',
        headerName: t('statement:fields.name'),
        sortable: true,
        cellRenderer: NameCell,
        autoHeight: true,
      },
      {
        field: 'billingDate',
        headerName: t('statement:fields.billingDate'),
        sortable: true,
        valueFormatter: (v) => formatDateOrEmpty(v.data?.billingDate),
      },
      {
        field: 'clientName',
        headerName: t('statement:fields.client'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.clientName),
      },
      {
        field: 'projectName',
        headerName: t('statement:fields.project'),
        sortable: true,
        valueFormatter: (v) => formatDataOrEmpty(v.data?.projectName),
      },
      {
        field: 'type',
        headerName: t('statement:fields.type'),
        sortable: true,
        valueFormatter: (v) => (v.data?.type ? t(`statement:types.${v.data.type}`) : ''),
      },
      {
        field: 'amountExVAT',
        headerName: t('statement:fields.amountExVAT'),
        sortable: true,
        cellRenderer: AmountExVATCell,
      },
      {
        field: 'status',
        headerName: t('statement:fields.status'),
        sortable: true,
        cellRenderer: StatusCell,
      },
      {
        field: 'billStatus',
        headerName: '',
        maxWidth: 80,
        resizable: false,
        suppressMovable: true,
        cellRenderer: BillIndicatorCell,
      },
    ],
    [t],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(t('statement:title'), []);
  }, [t, updateHeaderTitle]);

  return (
    <ListingLayout
      pagination={statements}
      search={{
        placeholder: t('statement:search'),
      }}
      filters={[
        {
          type: 'checkbox',
          name: 'status',
          availableValues: availableFilterStatuses,
          label: t('statement:fields.status'),
        },
        {
          type: 'multi',
          name: 'tags',
          availableValues: availableTags,
          label: t('global:words.tags'),
          noValueMessage: t('global:tags.noLabel'),
          placeholder: t('global:words.c.select'),
        },
        {
          type: 'checkbox',
          name: 'type',
          availableValues: availableFilterTypes,
          label: t('statement:type'),
        },
        {
          type: 'date',
          name: 'billingDate',
          label: t('statement:filters.billingDate'),
        },
      ]}
      actions={
        <Button
          onClick={onStatementCreate}
          colorScheme="blue"
          alignSelf="flex-end"
          {...createStatementsButtonDisabledProps()}
        >
          {t('statement:create')}
        </Button>
      }
      content={
        <PaginatedTable
          gridId="statement"
          columnDefs={columnDefs}
          pagination={statements}
          zeroState={{
            icon: <SimpleStatementIcon boxSize={45} />,
            label: t('statement:noStatement'),
          }}
          emptyState={{
            label: t('statement:noResult'),
          }}
          countLabel={(count) => t('statement:statement', { count })}
          sums={{
            amountExVAT: {
              label: t('statement:fields.amountExVAT'),
              type: 'currency',
            },
          }}
          rowSelection="multiple"
          onRowClicked={(props) => {
            if (props.data) {
              createRedirectionWithFilters(`/sales/statements/${getStatementPath(props.data.type)}/${props.data.id}`)();
            }
          }}
          getRowId={(row) => `${row.data?.id}-${row.data?.type}`}
          selectionComponentProps={{
            allSelectedLabel,
            selectLabel,
            renderActions: (props) => <ExportButton entity={EXPORT_ENTITY.STATEMENT} {...props} />,
          }}
        />
      }
    />
  );
};
