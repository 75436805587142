import type { FC } from 'react';
import { useCallback, useLayoutEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { IContactWithProjectInfos, IGroupedContact, IProjectWithRelations } from '@graneet/business-logic';

import { useDownPayment } from 'features/down-payment/services/down-payment.api';
import { DownPaymentDetail } from 'features/down-payment/components/DownPaymentDetail/DownPaymentDetail';
import { useFiltersQuery } from 'features/common/hooks/useFiltersQuery';
import { useProgressStatementsForSubProjects } from 'features/progress-statement/services/progress-statement.api';
import { useHeaderContext } from 'features/app/contexts/HeaderContext';

interface ViewProjectDownPaymentDetailScreenProps {
  project: IProjectWithRelations;

  projectContacts: IGroupedContact<IContactWithProjectInfos>[];
}

export const ViewProjectDownPaymentDetailScreen: FC<ViewProjectDownPaymentDetailScreenProps> = ({
  project,
  projectContacts,
}) => {
  const { t } = useTranslation(['statement']);
  const { title, breadCrumb, updateHeaderTitle } = useHeaderContext();
  const { pathname } = useLocation();
  const { downPaymentId: downPaymentIdRaw, projectId: projectIdRaw } = useParams<{
    downPaymentId: string;
    projectId: string;
  }>();
  const history = useHistory();
  const { createRedirectionWithSavedFilters } = useFiltersQuery();

  const downPaymentId = Number(downPaymentIdRaw);
  const projectId = Number(projectIdRaw);

  const downPayment = useDownPayment(downPaymentId);
  const progressStatements = useProgressStatementsForSubProjects(downPayment.data.subProject?.id);

  // When the user clicks on the back button, he should return to the correct page
  const goBackToListing = createRedirectionWithSavedFilters(`/projects/${projectId}/statements`, {
    replace: true,
  });

  const goToCancelDownPayment = useCallback(
    (createDraft: boolean) =>
      history.push(`/projects/${projectId}/statements/down-payments/${downPaymentId}/cancel`, {
        cancelledInvoiceNumber: downPayment.data.invoiceNumber,
        createDraft,
      }),
    [downPaymentId, downPayment, history, projectId],
  );

  useLayoutEffect(() => {
    updateHeaderTitle(downPayment.data.name ?? '', [
      ...breadCrumb,
      {
        name: title ?? '',
        link: `/projects/${projectId}`,
      },
      {
        name: t('statement:title'),
        link: `/projects/${projectId}/statements`,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, downPayment.data, updateHeaderTitle, pathname]);

  return (
    <DownPaymentDetail
      downPayment={downPayment.data}
      progressStatements={progressStatements.data.data}
      onCancelConfirmed={goToCancelDownPayment}
      onGoBack={goBackToListing}
      project={project}
      projectContacts={projectContacts}
    />
  );
};
